import {InjectionToken} from '@angular/core';

/** Interface for the injectable Google Material MDC Button options. */
export interface GmatButtonOptions {
  disableGmatStyle: boolean;
}

/**
 * Injectable token that provides options for the Google Material MDC button.
 */
export const GMAT_BUTTON_OPTIONS =
    new InjectionToken<GmatButtonOptions>('GMAT_BUTTON_OPTIONS', {
      providedIn: 'root',
      factory: () => {
        return {disableGmatStyle: false};
      }
    });
