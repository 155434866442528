import {Injectable} from '@angular/core';

import {KeyCommand} from './key_command';
import {PlatformService} from './platform_service';
import {ShortcutAction} from './shortcut_action';
import {SHORTCUT_ACTIONS} from './shortcut_actions';
import {ShortcutCommand} from './shortcut_command';

/**
 * Service to register to register shortcut handlers.
 */
@Injectable({providedIn: 'root'})
export class ShortcutRegistryService {
  private readonly SHORTCUT_COMMANDS: ShortcutCommand[] = [
    {
      action: SHORTCUT_ACTIONS.COBWEB_CONVERSATION_UP,
      keyCommands: [new KeyCommand({key: 'ArrowUp', shiftKey: true})]
    },
    {
      action: SHORTCUT_ACTIONS.COBWEB_CONVERSATION_DOWN,
      keyCommands: [new KeyCommand({key: 'ArrowDown', shiftKey: true})]
    },
    {
      action: SHORTCUT_ACTIONS.COBWEB_CONVERSATION_NEXT,
      pcKeyCommands: [new KeyCommand({key: 'ArrowRight', ctrlKey: true})],
      macKeyCommands: [new KeyCommand({key: 'ArrowRight', metaKey: true})],
    },
    {
      action: SHORTCUT_ACTIONS.COBWEB_CONVERSATION_PREVIOUS,
      pcKeyCommands: [new KeyCommand({key: 'ArrowLeft', ctrlKey: true})],
      macKeyCommands: [new KeyCommand({key: 'ArrowLeft', metaKey: true})],
    },
    {
      action: SHORTCUT_ACTIONS.COBWEB_CONVERSATION_SET_LABEL,
      pcKeyCommands: [new KeyCommand({key: 'Enter', ctrlKey: true})],
      macKeyCommands: [new KeyCommand({key: 'Enter', metaKey: true})],
    },
    {
      action: SHORTCUT_ACTIONS.SNACK_BAR_FOCUS,
      pcKeyCommands: [new KeyCommand({key: 'F6', ctrlKey: true})],
      macKeyCommands: [new KeyCommand({key: 'F6', metaKey: true})]
    },
    {
      action: SHORTCUT_ACTIONS.TEST_CASE_LIST_CONTROLS,
      pcKeyCommands:
          [new KeyCommand({key: 'y', ctrlKey: true, shiftKey: true})],
      macKeyCommands:
          [new KeyCommand({key: 'y', metaKey: true, shiftKey: true})]
    },
    {
      action: SHORTCUT_ACTIONS.VISUAL_BUILDER_EDGES,
      pcKeyCommands:
          [new KeyCommand({key: 'e', ctrlKey: true, shiftKey: true})],
      macKeyCommands:
          [new KeyCommand({key: 'l', metaKey: true, shiftKey: true})]
    }
  ];

  constructor(private readonly platformService: PlatformService) {}

  matchActions(keyCommand: KeyCommand): ShortcutAction[] {
    return this.SHORTCUT_COMMANDS
        .filter(
            command => (command.keyCommands?.some(c => c.equals(keyCommand))) ||
                (this.platformService.isMac &&
                 command.macKeyCommands?.some(c => c.equals(keyCommand))) ||
                (!this.platformService.isMac &&
                 command.pcKeyCommands?.some(c => c.equals(keyCommand))))
        .map(command => command.action);
  }
}
