import {ConversationProfile_, SuggestionFeature_Type} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_ts_api_client';


/** Checks whether a Conversation Profile has a virtual agent configured. */
export function hasVirtualAgent(conversationProfile?: ConversationProfile_) {
  return !!conversationProfile?.automatedAgentConfig?.agent;
}

/** Gets the link to the Edit Conversation Profile page. */
export function getConversationProfileUrl(conversationProfileName: string|null|
                                          undefined) {
  return conversationProfileName ?
      `/${
          conversationProfileName.replace(
              'conversationProfiles', 'conversation-profiles')}/edit` :
      '';
}

/** Gets the simulator URL for a given conversation profile. */
export function getSimulatorUrl(conversationProfileName: string|null|
                                undefined) {
  return conversationProfileName ?
      `/${
          conversationProfileName.replace(
              'conversationProfiles', 'conversation-profiles')}/simulator` :
      '';
}

/**
 * Gets the feature config in a conversation profile of the given suggestion
 * feature type.
 */
export function getFeatureConfig(
    conversationProfile: ConversationProfile_, type: SuggestionFeature_Type) {
  return conversationProfile.humanAgentAssistantConfig
      ?.humanAgentSuggestionConfig?.featureConfigs?.find(
          featureConfig => featureConfig.suggestionFeature?.type === type);
}

/**
 * Returns whether a conversation profile contains a feature of the given type.
 */
export function hasFeatureConfig(
    conversationProfile: ConversationProfile_, type: SuggestionFeature_Type) {
  return Boolean(getFeatureConfig(conversationProfile, type));
}

/** Gets the model name for suggestion feature. */
export function getModelNameForSuggestionFeature(
    conversationProfile: ConversationProfile_,
    featureName: SuggestionFeature_Type) {
  return getFeatureConfig(conversationProfile, featureName)
      ?.conversationModelConfig?.model;
}

/**
 * Gets the suggestion feature types that are configured for a given
 * conversation profile.
 */
export function getSuggestionFeatureTypes(
    conversationProfile: ConversationProfile_|null|undefined) {
  return (conversationProfile?.humanAgentAssistantConfig
              ?.humanAgentSuggestionConfig?.featureConfigs?.map(
                  featureConfig => featureConfig.suggestionFeature?.type) ||
          [])
      .filter((val): val is NonNullable<typeof val> => !!val);
}
