import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {assertString} from 'google3/javascript/typescript/contrib/assert';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {MessageBarLevel, SVG_ICONS} from './message_bar_constants';
import {messageBarFadeOutAnimation} from './message_bar_helpers';


/**
 * Message Bar component.
 *
 * Used for common messaging tasks, such as notifying the user that a failure
 * has occurred with a resource.
 *
 * Currently supports five levels of message severity: 'informational',
 * 'success', 'warning', 'danger-soft', and 'danger'.
 *
 * Each message bar must include a severity level, a message, and can optionally
 * include one primary and one secondary action item. Action items can be either
 * a <message-bar-button> or a <message-bar-link> element.
 *
 * Example usage:
 *
 * <message-bar level="warning"
 *              text="An error has occurred with model training.">
 *    <message-bar-link type="secondary" href="https://test.com">
 *       Learn More
 *    </message-bar-link>
 *    <message-bar-button type="primary" (click)="foo()">
 *       Dismiss
 *    </message-bar-button>
 * </message-bar>
 *
 * Screenshot: https://screenshot.googleplex.com/7NncnvQTdubijSG.png
 */
@Component({
  selector: 'message-bar',
  templateUrl: './message_bar.ng.html',
  styleUrls: ['./message_bar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [messageBarFadeOutAnimation],
})
export class MessageBar implements OnInit {
  /**
   * Message level that is determined based on the severity of the message.
   * Valid values include: 'informational', 'success', 'warning', 'danger-soft',
   * or 'danger'.
   */
  @Input()
  set level(messageBarLevel: MessageBarLevel) {
    this.level$.next(messageBarLevel);
  }

  /** Message to display in the message bar. */
  @Input() readonly message!: string;

  readonly level$ = new ReplaySubject<MessageBarLevel>(1);
  readonly dismissed$ = new BehaviorSubject(false);

  getSvgIcon(level: MessageBarLevel) {
    return SVG_ICONS[level];
  }

  getWrapperClass(level: MessageBarLevel) {
    return {[`message-bar__wrapper-${level}`]: true};
  }

  dismiss() {
    this.dismissed$.next(true);
  }

  ngOnInit() {
    assertString(
        this.message,
        'Please pass a valid message to the Message Bar component.');
  }
}
