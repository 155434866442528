import {AgentDesktop} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/agent_desktop';

/** Default Dark Mode background color. */
const DEFAULT_DARK_MODE_BACKGROUND = '#333';

/**
 * Default dark mode background colors to apply for the supported agent
 * desktops.
 */
const DEFAULT_DARK_MODE_BACKGROUNDS = new Map<AgentDesktop, string>([
  ['LivePerson', '#222954'],
]);

/**
 * Gets the default dark mode background for the given agent desktop.
 */
export const getDefaultDarkModeBackground = (agentDesktop: AgentDesktop) =>
    DEFAULT_DARK_MODE_BACKGROUNDS.get(agentDesktop) ||
    DEFAULT_DARK_MODE_BACKGROUND;
