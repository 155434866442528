const SECOND_IN_MS = 1000;
const MINUTE_IN_MS = SECOND_IN_MS * 60;
const HOUR_IN_MS = MINUTE_IN_MS * 60;

/** Returns the most recent timestamp of two given timestamps. */
export const getMoreRecentTimestamp =
    (timestampA: string|undefined, timestampB: string|undefined) => {
      if (!timestampA && !timestampB) {
        throw new Error(
            'Please pass at least one non-empty timestamp to the getMostRecentTimestamp() function.');
      }

      if (!timestampA) return timestampB!;
      if (!timestampB) return timestampA;

      return new Date(timestampA) > new Date(timestampB) ? timestampA :
                                                           timestampB;
    };

/** Pads a number with a leading zero, if it is a single digit. */
const padTime = (num: number) => String(num).padStart(2, '0');

/**
 * Get string representing the time elapsed from start time to end time in the
 * form of 'mm:ss'.
 */
export const getElapsedTime =
    (startTimeIso: string|null|undefined,
     endTimeIso?: string|null|undefined) => {
      if (!startTimeIso) {
        return '00:00';
      }

      const startTime = new Date(startTimeIso);
      const endTime = endTimeIso ? new Date(endTimeIso) : new Date();

      if (!isValidDate(startTime) || !isValidDate(endTime)) {
        return '00:00';
      }

      const durationInMs = endTime.getTime() - startTime.getTime();

      const hours = Math.floor(durationInMs / HOUR_IN_MS);
      const minutes =
          Math.floor((durationInMs - (hours * HOUR_IN_MS)) / MINUTE_IN_MS);
      const seconds = Math.floor(
          (durationInMs - (hours * HOUR_IN_MS) - (minutes * MINUTE_IN_MS)) /
          SECOND_IN_MS);

      return `${hours ? `${padTime(hours)}:` : ''}${padTime(minutes)}:${
          padTime(seconds)}`;
    };

/** Determines if a value can be converted to a JavaScript Date object. */
export const isValidDate = (val: string|number|Date) => {
  const date = new Date(val);

  return date.toString() !== 'Invalid Date';
};
