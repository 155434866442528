import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {HeadIntentModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/head_intent/head_intent_module';
import {CardFormModule} from 'google3/java/com/google/dialogflow/console/web/common/card_form/card_form_module';
import {LetDirectiveModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/let/let_directive_module';

import {HeadIntents} from './head_intents';

@NgModule({
  imports: [
    CardFormModule,
    CommonModule,
    HeadIntentModule,
    LetDirectiveModule,
    MatIconModule,
  ],
  declarations: [HeadIntents],
  exports: [HeadIntents]
})
export class HeadIntentsModule {
}
