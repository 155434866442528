import {InjectionToken} from '@angular/core';

/** Interface for the injectable Google Material MDC Chips options. */
export interface GmatChipsOptions {
  disableGmatStyle: boolean;
}

/**
 * Injectable token that provides options for the Google Material MDC chips.
 */
export const GMAT_CHIPS_OPTIONS =
    new InjectionToken<GmatChipsOptions>('GMAT_CHIPS_OPTIONS', {
      providedIn: 'root',
      factory: () => {
        return {disableGmatStyle: false};
      }
    });
