import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

/**
 * Directive for declaring scoped variables in templates.
 *
 * When a node is assigned a variable with *xapLet, all descendants of the node
 * can access the value. Works similarly as *ngIf.
 *
 * Usage:
 * ```
 *   <ng-container *xapLet="'something' as aVar">
 *     I want to say {{ aVar }}
 *   </ng-container>
 * ```
 */
@Directive({selector: '[xapLet]'})
export class XapLet<T> {
  private readonly context = {} as XapLetContext<T>;

  @Input()
  set xapLet(newContext: T) {
    // Copy all fields from the input object to context variables so they can be
    // extracted in "let a = b" reassignments.
    const newContextAsObj =
        newContext as unknown as Readonly<ObjectWithUnknowns>;
    const newContextKeys = new Set(getAllKeys(newContextAsObj));

    // Remove properties that are not in the new context.
    if (this.context !== undefined) {
      for (const key of getAllKeys(this.context)
               .filter(key => !newContextKeys.has(key))) {
        delete this.context[key];
      }
    }

    // Update/set properties of new context.
    for (const key of newContextKeys) {
      this.context[key] = newContextAsObj[key];
    }

    this.context.$implicit = newContext;
    this.context.xapLet = newContext;
  }

  constructor(
      templateRef: TemplateRef<XapLetContext<T>>,
      viewContainerRef: ViewContainerRef) {
    viewContainerRef.createEmbeddedView(templateRef, this.context);
  }

  /**
   * Assert the correct type of the expression bound to the `xapLet` input
   * within the template.
   *
   * The presence of this static field is a signal to the Ivy template type
   * check compiler that when the `XapLet` structural directive renders its
   * template, the type of the expression bound to `xapLet` should be narrowed
   * in some way. For `XapLet`, the binding expression itself is used to narrow
   * its type, which allows the strictNullChecks feature of TypeScript to work
   * with `XapLet`.
   *
   * (Copied from `*ngIf`)
   */
  // tslint:disable-next-line:enforce-name-casing
  static ngTemplateGuard_xapLet: 'binding';

  /**
   * Asserts the correct type of the context for the template that `XapLet` will
   * render.
   *
   * The presence of this method is a signal to the Ivy template type-check
   * compiler that the `XapLet` structural directive renders its template with a
   * specific context type.
   *
   * (Copied from `*ngIf`)
   */
  static ngTemplateContextGuard<T>(directive: XapLet<T>, context: unknown):
      context is XapLetContext<T> {
    return true;
  }
}

/** @publicApi */
export type XapLetContext<T> = ObjectWithUnknowns&{
  $implicit: T;
  xapLet: T;
};

/** @publicApi */
export interface ObjectWithUnknowns {
  [key: string]: unknown;
}

function getAllKeys<T extends ObjectWithUnknowns>(obj: T):
    ReadonlyArray<keyof T> {
  if (obj === undefined || obj === null) {
    return [];
  }
  return Object.keys(obj).filter(key => obj.hasOwnProperty(key));
}
