import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {XapDialogLayoutModule} from 'google3/javascript/angular2/components/dialog_layout/dialog_layout_module';
import {GmatChipsModule} from 'google3/javascript/angular2/components/gmat/chips/chips_module';
import {XapInlineDialogModule} from 'google3/javascript/angular2/components/inline_dialog/inline_dialog_module';

import {ResponseMessage} from './response_message';

@NgModule({
  imports: [
    CommonModule,
    GmatChipsModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    XapDialogLayoutModule,
    XapInlineDialogModule,
  ],
  declarations: [ResponseMessage],
  exports: [ResponseMessage]
})
export class ResponseMessageModule {
}
