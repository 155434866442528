import {A11yModule} from '@angular/cdk/a11y';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {XapInlineDialog} from './inline_dialog';
import {XapInlineDialogClick} from './inline_dialog_click';
import {XapInlineDialogContainer} from './inline_dialog_container';
import {XapIconTrigger, XapTextTrigger} from './inline_dialog_trigger';

const EXPORTS = [
  XapInlineDialog,
  XapInlineDialogClick,
  XapIconTrigger,
  XapTextTrigger,
];

const DECLARATIONS = [...EXPORTS, XapInlineDialogContainer];

@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    OverlayModule,
    PortalModule,
  ],
  declarations: DECLARATIONS,
  exports: EXPORTS,
  entryComponents: [XapInlineDialogContainer],
})
export class XapInlineDialogModule {
}
