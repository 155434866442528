import {COMMON_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/icons';
import {dedupeIconList} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/icon_helpers';

/**
 * Material icons used by the Smart Reply UI module.
 *
 * Icons names and versions can be found at
 * https://icons.corp.google.com/?icon.set=Google%20Material
 */
export const SMART_REPLY_ICONS = dedupeIconList([
  ...COMMON_ICONS,
]);
