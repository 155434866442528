import {AUTH_USER} from 'google3/java/com/google/dialogflow/console/web/common/constants/constants';
import {GCS_PUBLIC_URL} from 'google3/java/com/google/dialogflow/console/web/common/gcs_selector/gcs_selector_constants';

/** Generates a Cloud Storage file link using a bucket and object name. */
export function generateGcsFileLink(bucketName: string, objectName: string) {
  return `${GCS_PUBLIC_URL}/${bucketName}/${objectName}${
      AUTH_USER ? `?authuser=${AUTH_USER}` : ''}`;
}

/**
 * Parses a gcs uri string in the format of
 * gs://<bucket>/<directory>/<object> into an object containing the object
 * bucket, full name, and a boolean isDirectory indicating if the object is a
 * directory.
 */
export function parseGcsUri(uriString: string) {
  const uri = uriString.split('gs://')[1];
  const bucket = uri.substring(0, uri.indexOf('/'));
  const isDirectory = uri.endsWith('*');
  let name = uri.substring(uri.indexOf('/') + 1);

  if (isDirectory) {
    name = name === '**/*' ? '/' : name.replace('**/*', '');
  }

  return {
    bucket,
    name,
    isDirectory,
    isFile: !isDirectory,
  };
}

/**
 * Generates a regular expression to match GCS file names with the provided
 * extensions.
 */
export const generateFileExtensionRegex = (fileExtensions: string[]) =>
    new RegExp(
        `${fileExtensions.map(extension => `(\\.${extension}$)`).join('|')}`,
        'i');

/** Determines whether a given URI is a GCS URI. */
export function isGcsUri(uri: string|null|undefined): uri is string {
  return Boolean(uri?.startsWith('gs://'));
}

/** Generate a file link for a GCS URI. */
export function generateGcsFileLinkFromUri(uri: string|null|undefined) {
  if (!isGcsUri(uri)) {
    throw new Error('Invalid GCS URI passed to generateGcsFileLinkFromUri');
  }

  const {name, bucket} = parseGcsUri(uri);

  if (!name || !bucket || name.endsWith('*') || name === '/') {
    throw new Error('Invalid GCS URI passed to generateGcsFileLinkFromUri');
  }

  return generateGcsFileLink(bucket, name);
}
