import {unwrapTrustedResourceUrl} from 'google3/javascript/typescript/safevalues/compat';
import {TrustedResourceUrl} from 'safevalues';
import {safeScriptEl} from 'safevalues/dom';

/**
 * Loads a JavaScript file in the DOM, and returns a Promise that resolves once
 * the script has finished loading.
 */
export const loadScript = async (
    trustedResourceUrl: TrustedResourceUrl,
    {crossOrigin = false, target = document.head}:
        {crossOrigin?: boolean, target?: HTMLElement} = {}) => {
  const url = unwrapTrustedResourceUrl(trustedResourceUrl);
  const existingScript = document.querySelector(`script[src="${url}"]`);

  if (existingScript) {
    return;
  }

  const scriptEl = document.createElement('script');

  if (crossOrigin) {
    scriptEl.setAttribute('crossorigin', 'true');
  }

  safeScriptEl.setSrc(scriptEl, trustedResourceUrl);
  target.appendChild(scriptEl);

  return new Promise(resolve => {
    scriptEl.addEventListener('load', resolve);
  });
};
