import {IconList, MaterialIcon} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/material_icon';

const serializeIcon = (icon: MaterialIcon) => `${icon.name}%${icon.version}`;
const deserializeIcon = (icon: string): MaterialIcon => {
  const [name, version] = icon.split('%');
  return {name, version: Number(version)};
};

/** Dedupes a list of icons. */
export const dedupeIconList =
    (iconList: IconList) => [...new Set(iconList.map(serializeIcon))].map(
        deserializeIcon);
