<ng-template #confidenceScore i18n="Confidence score label" let-smartReplyAnswer
  >{{ smartReplyAnswer.confidence // i18n(ph = "Confidence score") }} confidence
</ng-template>

<ng-template
  #smartReplyDialogContent
  let-smartReplyAnswer="smartReplyAnswer"
  let-showConfidence="showConfidence"
>
  <p *ngIf="showConfidence" class="gmat-subtitle-2">
    <ng-container
      *ngTemplateOutlet="confidenceScore; context: {$implicit: smartReplyAnswer}"
    ></ng-container>
  </p>

  <p
    class="gmat-body-2 smart-reply__tooltip-suggestion"
    i18n="Smart Reply suggestion"
  >
    Suggestion: {{ smartReplyAnswer.reply // i18n(ph = "Smart Reply suggestion")
    }}
  </p>
</ng-template>

<mat-chip-listbox *ngIf="smartReplyAnswers$ | async as smartReplyAnswers">
  <ng-container *ngIf="smartReplyAnswers && !loading">
    <ng-container *ngFor="let smartReplyAnswer of smartReplyAnswers">
      <mat-chip-option
        appearance="hairline-assistive"
        (click)="handleChooseSmartReplyAnswerSuggestion(smartReplyAnswer)"
        (keydown.enter)="handleChooseSmartReplyAnswerSuggestion(smartReplyAnswer)"
        (keydown.space)="handleChooseSmartReplyAnswerSuggestion(smartReplyAnswer)"
        [class.smart-reply__chip--used]="
          handleHasSmartReplyAnswerSuggestionBeenUsed$(smartReplyAnswer) | async
        "
        [matTooltip]="smartReplyAnswer.reply"
        [matTooltipDisabled]="!simpleTooltip"
        [richTooltip]="(simpleTooltip || !richTooltipEnabled) ? undefined : smartReplyRichTooltip"
        [richTooltipAriaLabel]="MSG_SUGGESTION_DETAILS_DIALOG_ARIA_LABEL"
        [isRichTooltipInteractive]="richTooltipEnabled ? false : undefined"
        [xapInlineDialog]="(simpleTooltip || richTooltipEnabled) ? undefined : smartReplyTooltip"
        [dialogLabel]="MSG_SUGGESTION_DETAILS_DIALOG_ARIA_LABEL"
        matTooltipClass="smart-reply-tooltip"
        class="smart-reply__chip smart-reply__chip--smart-reply"
      >
        <span class="gmat-body-2"> {{ smartReplyAnswer.reply }} </span>
        <span class="cdk-visually-hidden"
          ><ng-container
            *ngTemplateOutlet="confidenceScore; context: {$implicit: smartReplyAnswer}"
          ></ng-container
        ></span>
      </mat-chip-option>

      <ng-template #smartReplyRichTooltip>
        <ng-container
          *ngTemplateOutlet="smartReplyDialogContent; context: {smartReplyAnswer: smartReplyAnswer, showConfidence: showConfidence}"
        ></ng-container>
      </ng-template>
      <ng-template #smartReplyTooltip>
        <xap-dialog-layout class="smart-reply__tooltip">
          <xap-dialog-layout-content>
            <ng-container
              *ngTemplateOutlet="smartReplyDialogContent; context: {smartReplyAnswer: smartReplyAnswer, showConfidence: showConfidence}"
            ></ng-container>
          </xap-dialog-layout-content>
        </xap-dialog-layout>
      </ng-template>
    </ng-container>

    <span
      *ngIf="smartReplyAnswers.length === 0"
      class="gmat-body-2 smart-reply__chip--empty"
      i18n="Message shown when no Smart Reply suggestions are available"
    >
      No smart reply suggestions
    </span>
  </ng-container>
</mat-chip-listbox>
