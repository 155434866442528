import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SuggestionFeatureService} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/common/suggestion_feature_service/suggestion_feature_service';
import {DEFAULT_ARTICLE_LINK_CONFIG} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/document_and_faq_constants';
import {getArticleAnswerLink, normalizeArticleContent} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/document_and_faq_helpers';
import {AnswerRecordUpdate, ArticleSelection, SelectArticleEvent} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/document_and_faq_types';
import {AnswerFeedback_CorrectnessLevel, AnswerRecord_, SearchArticleAnswer_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';

/** Renders an Article Search answer result. */
@Component({
  selector: 'article-search-answer',
  templateUrl: './article_search_answer.ng.html',
  styleUrls: ['./article_search_answer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleSearchAnswer {
  @Input() answer?: SearchArticleAnswer_;
  @Input() answerRecord?: AnswerRecord_;
  @Input() showScore = false;
  @Input() articleLinkConfig = DEFAULT_ARTICLE_LINK_CONFIG;

  @Output() readonly onSelectArticle = new EventEmitter<SelectArticleEvent>();
  @Output() readonly onProvideFeedback = new EventEmitter<AnswerRecordUpdate>();

  readonly handleNormalizeArticleContent = normalizeArticleContent;
  readonly getArticleAnswerLink = getArticleAnswerLink;

  get snippets() {
    return this.answer?.snippets || [];
  }

  get hasPositiveFeedback() {
    return this.answerRecord?.answerFeedback?.correctnessLevel ===
        'FULLY_CORRECT';
  }

  get hasNegativeFeedback() {
    return this.answerRecord?.answerFeedback?.correctnessLevel ===
        'NOT_CORRECT';
  }

  constructor(
      private readonly suggestionFeatureService: SuggestionFeatureService,
  ) {}

  handleSelectSearchArticle(event: Event) {
    const selection: ArticleSelection = {
      clickTime: new Date().toISOString(),
      displayTime: this.suggestionFeatureService.getSuggestionDisplayTime(
          this.answer!.answerRecord!),
      answer: this.answer!,
    };
    this.onSelectArticle.emit({event, selection});
  }

  handleProvidePositiveFeedback() {
    this.provideFeedback('FULLY_CORRECT');
  }

  handleProvideNegativeFeedback() {
    this.provideFeedback('NOT_CORRECT');
  }

  handleProvideNeutralFeedback() {
    this.provideFeedback('CORRECTNESS_LEVEL_UNSPECIFIED');
  }

  private provideFeedback(correctnessLevel: AnswerFeedback_CorrectnessLevel) {
    this.onProvideFeedback.emit({
      answerRecordName: this.answer!.answerRecord!,
      correctnessLevel,
    });
  }
}
