<div *ngIf="textResponseMessage" class="text-response-message">
  <h6 class="gmat-headline-6">{{textResponseMessage}}</h6>

  <button
    *ngIf="elementTemplate.showUseSuggestionButton"
    (click)="handleChooseResponseMessage();"
    [disabled]="elementTemplate.disabled"
    aria-label="Use suggestion"
    i18n-aria-label="Button that selects the suggested response"
    matTooltip="Use suggestion"
    color="primary"
    data-test-id="choose-suggestion"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="input"></mat-icon>
  </button>
</div>

<div *ngIf="debugMode && customResponseMessage" class="custom-response-message">
  <h4 class="gmat-caption">Custom response</h4>
  <pre>{{customResponseMessage | json}}</pre>
</div>

<ng-template #tooltip>
  <xap-dialog-layout>
    <xap-dialog-layout-content>
      <p class="gmat-body-2">{{tooltipText}}</p>

      <p
        class="gmat-body-2 response-message__tooltip-suggestion"
        i18n="Shows the suggested response message"
      >
        Suggestion: {{textResponseMessage // i18n(ph = "The suggested response
        message")}}
      </p>
    </xap-dialog-layout-content>
  </xap-dialog-layout>
</ng-template>
