import {AriaDescriber, ConfigurableFocusTrap, ConfigurableFocusTrapFactory} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {ENTER, SPACE} from '@angular/cdk/keycodes';
import {ConnectedPosition, Overlay, OverlayConfig, OverlayRef, OverlaySizeConfig, PositionStrategy} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType, Portal, TemplatePortal} from '@angular/cdk/portal';
import {ComponentRef, Directive, ElementRef, EventEmitter, Injector, Input, NgZone, OnDestroy, OnInit, Output, TemplateRef, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, fromEvent, ReplaySubject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {ANIMATION_TRANSITION_TIME_MS} from './inline_dialog_animation';
import {XAP_INLINE_DIALOG_CONTAINER_OPTIONS, XapInlineDialogContainer} from './inline_dialog_container';

/** @desc Default message to be used on dialog trigger as aria-describedby. */
export const MSG_DEFAULT_TRIGGER_DESCRIPTION =
    goog.getMsg('Press space for more information.');

/**
 * Default set of positions for the dialog. Follows the behavior of a dropdown.
 */
export const DEFAULT_POSITION_LIST: ConnectedPosition[] = [
  // bottom right
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: 8,
  },
  // top right
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetY: -8,
  },
  // top left
  {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetY: -8,
  },
  // bottom left
  {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    offsetY: 8,
  }
];

/**
 * Dimensions (in px) of overlay are coming from the following spec:
 * https://carbon.googleplex.com/reach-ux/pages/tooltips/interaction#c6967632-3226-4273-af66-8ccc61650630
 */
export const DEFAULT_OVERLAY_DIMENSIONS: XapInlineDialogOverlayDimensions = {
  minWidth: 220,
  maxWidth: 420,
  minHeight: 64,
  maxHeight: 420
};

/**
 * Interface for customize dimensions (in px) of overlay.
 */
export interface XapInlineDialogOverlayDimensions {
  minWidth: number;
  maxWidth?: number;
  minHeight: number;
  maxHeight?: number;
}

/**
 * A base attribute directive that shows a popup dialog when the element it is
 * placed on is triggered. This class is extended by XapInlineDialog for mouse
 * hover triggers and by XapInlineDialogClick for mouse click triggers. The
 * input dialog can contain arbitrary markup/components. But it is expected to
 * have at least one focusable element/control. If your content doesn't contain
 * any focusable element/control, consider using `xapCardTooltip` instead.
 *
 * It is highly recommended to use `<xap-dialog-layout>` as the dialog layout so
 * you can get out of the box styles aligning with Reach Card Tooltip spec.
 * Otherwise user is expected to style their input dialog to meet their needs.
 *
 * Reach Tooltip spec:
 * https://carbon.googleplex.com/reach-ux/pages/tooltips/usage
 *
 * Example usage with ng-template:
 *
 * ```
 * <my-awesome-component
 *     [xapInlineDialog]="myAwesomeTemplate"
 *     dialogLabel="Awesome stuff">
 *   ...
 * </my-awesome-component>
 *
 * <ng-template #myAwesomeTemplate>
 *   <xap-dialog-layout>
 *     <xap-dialog-layout-content>
 *       <p>
 *         This will redirect you to google.com
 *       </p>
 *     </xap-dialog-layout-content>
 *     <xap-dialog-layout-actions>
 *       <a mat-button
 *          color="primary"
 *          href="https://www.google.com/">
 *         Click me
 *       </a>
 *     </xap-dialog-layout-actions>
 *   </xap-dialog-layout>
 * </ng-template>
 * ```
 *
 * Example usage with Angular component:
 *
 * ```
 * <my-awesome-trigger
 *     [xapInlineDialog]="MyAwesomeComponentClass"
 *     dialogLabel="Awesome stuff">
 * </my-awesome-trigger>
 * ```
 *
 * In order for xapInlineDialog to work with pure text and icons, we provide
 * triggers for these cases. This gives the user the styles and accessibility
 * on these scenarios for free.
 *
 * Example usage with xap-text-trigger:
 *
 * ```
 * <xap-text-trigger
 *     [xapInlineDialog]="myAwesomeTemplate2"
 *     dialogLabel="Awesome stuff">
 *   Hover to see a dialog
 * </xap-text-trigger>
 *
 * <ng-template #myAwesomeTemplate2>
 * ...
 * </ng-template>
 * ```
 *
 * Example usage with xap-icon-trigger:
 *
 * ```
 * <xap-icon-trigger
 *     [xapInlineDialog]="myAwesomeTemplate3"
 *     aria-label="Help about awesome stuff"
 *     dialogLabel="Awesome stuff help info">
 *   <mat-icon>help_outline</mat-icon>
 * </xap-icon-trigger>
 *
 * <ng-template #myAwesomeTemplate3>
 * ...
 * </ng-template>
 * ```
 *
 * Note: If the dialog background is transparent, you need to include the
 * `xap-inline-dialog-reach-theme` mixin in your App's styles.
 *
 * Accessibility Note: We don't recommend putting `xapInlineDialog` on
 * components that respond to the following keyboard events: enter, spacebar,
 * and escape (e.g. <button>, <a>), because `xapInlineDialog` uses these keys to
 * activate and deactivate the dialog for keyboard users. Using it on those
 * components will not guarantee its keyboard behavior.
 */
@Directive({
  host: {
    // Used for the harness to verify the element is disabled.
    '[attr.data-disabled]': 'disabled',
  }
})
export abstract class XapInlineDialogBase implements OnInit, OnDestroy {
  protected overlayRef?: OverlayRef;

  private portal?: Portal<unknown>;
  private disabledInternal: boolean = false;
  panelClassInternal?: string|string[];

  set dialog(dialog: TemplateRef<unknown>|ComponentType<unknown>|undefined) {
    // Don't set the portal if dialog is null or an empty string in the case
    // where this directive is applied without square brackets.
    if (!dialog) return;

    if (dialog instanceof TemplateRef) {
      this.setPortal(new TemplatePortal(dialog, this.viewContainerRef));
    } else {
      this.setPortal(new ComponentPortal(dialog, this.viewContainerRef));
    }
  }

  /**
   * When set to true, remove the dialog effects and style the trigger like a
   * normal element.
   */
  set disabled(input: boolean) {
    this.disabledInternal = input;
    if (this.disabledInternal) {
      this.closeDialog();
    }
  }

  get disabled() {
    return this.disabledInternal;
  }

  /**
   * Accessibility label for the dialog element.
   * It should describe or act as a heading for the dialog.
   * REQUIRED in order to pass accessibility checks.
   */
  @Input() dialogLabel: string|null = null;

  /**
   * tabindex for the dialog element. This can be set to 0 to force focus when
   * there are no intractable elements in the dialog.
   */
  @Input() dialogTabIndex: number = -1;

  /** Overlay dimensions for the dialog. */
  @Input() overlaySize?: Readonly<OverlaySizeConfig>;

  /**
   * Overlay positions for the dialog. If the list is empty, no change is made
   * to the overlay position.
   */
  @Input() overlayPositions?: ConnectedPosition[];

  /** The dimension of an overlay. */
  @Input()
  overlayDimensions: XapInlineDialogOverlayDimensions =
      DEFAULT_OVERLAY_DIMENSIONS;

  /**
   * Sets aria-describedby on the triggering element. User may provide their own
   * message for desired aria behavior.
   */
  @Input() triggerDescription: string = MSG_DEFAULT_TRIGGER_DESCRIPTION;

  /**
   * Disables the auto focus that is by default automatically triggered on
   * dialog opens.
   */
  @Input()
  set disableAutoFocus(disableAutoFocus: BooleanInput) {
    this.disableAutoFocusInternal = coerceBooleanProperty(disableAutoFocus);
  }
  get disableAutoFocus(): boolean {
    return this.disableAutoFocusInternal;
  }
  private disableAutoFocusInternal: boolean = false;

  /**
   * Sets a panel class on the overlay for the dialog.
   */
  @Input()
  set panelClass(panelClass: string|string[]) {
    if (this.panelClassInternal === panelClass) {
      return;
    }
    if (this.panelClassInternal) {
      this.overlayRef?.removePanelClass(this.panelClassInternal);
    }
    if (panelClass) {
      this.overlayRef?.addPanelClass(panelClass);
    }
    this.panelClassInternal = panelClass;
  }

  /**
   * Emits when the dialog is preparing to open. For mouse interactions, this is
   * triggered when mouse entered the triggering area. For keyboard
   * interactions, this is triggered when the triggering area gets focus.
   */
  @Output() readonly beforeOpened = new EventEmitter<void>();

  /** Emits when the dialog is opened. */
  @Output() readonly opened = new EventEmitter<void>();

  /** Emits when the dialog is closed. */
  @Output() readonly closed = new EventEmitter<void>();

  protected readonly destroyed = new ReplaySubject<void>();

  protected readonly openStatusChange = new BehaviorSubject<boolean>(false);
  protected readonly openings = this.openStatusChange.pipe(
      filter(isOpening => isOpening && !this.disabled));
  private dialogContainerRef: ComponentRef<XapInlineDialogContainer>|undefined;

  private focusTrap?: ConfigurableFocusTrap;

  /**
   * Element that was focused before the dialog was opened. Save this to restore
   * upon close.
   */
  private elementFocusedBeforeDialogWasOpened: HTMLElement|undefined =
      undefined;

  abstract attachMouseEventListeners(element: HTMLElement): void;

  abstract createOverlayConfig(positions: ConnectedPosition[]): OverlayConfig;

  constructor(
      protected readonly ngZone: NgZone,
      protected readonly overlay: Overlay,
      protected readonly elementRef: ElementRef,
      protected readonly viewContainerRef: ViewContainerRef,
      protected readonly document: Document,
      protected readonly focusTrapFactory: ConfigurableFocusTrapFactory,
      protected readonly ariaDescriber: AriaDescriber,
      protected readonly injector: Injector,
  ) {
    this.attachKeyboardOpenEventListeners(elementRef.nativeElement);
    this.attachKeyboardCloseEventListeners(elementRef.nativeElement);
    this.attachKeyboardFocusEventListeners(elementRef.nativeElement);
  }

  ngOnInit(): void {
    this.addAriaAttributes(this.elementRef.nativeElement);

    if (goog.DEBUG && !this.dialogLabel) {
      console.warn(
          'ACCESSIBILITY VIOLATION: xap-inline-dialog dialogLabel input missing');
    }
  }

  ngOnDestroy(): void {
    this.closeDialog();
    this.openStatusChange.complete();
    this.destroyed.next();
    this.destroyed.complete();
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  setPortal(portal: Portal<unknown>) {
    this.portal = portal;
  }

  private addAriaAttributes(triggerElement: HTMLElement): void {
    if (!triggerElement.hasAttribute('aria-haspopup')) {
      triggerElement.setAttribute('aria-haspopup', 'dialog');
    }

    if (!triggerElement.hasAttribute('aria-describedby')) {
      // Add aria-describedby to the triggering element.
      this.ariaDescriber.describe(triggerElement, this.triggerDescription);
    }
  }

  private attachKeyboardOpenEventListeners(element: HTMLElement): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent<KeyboardEvent>(element, 'keydown')
          .pipe(takeUntil(this.destroyed))
          .subscribe((event: KeyboardEvent) => {
            const keyCode = event.keyCode;
            switch (keyCode) {
              case ENTER:
                this.openDialog();
                return;
              case SPACE:
                // Prevent the page from scrolling if the user presses the space
                // bar while the dialog is focused.
                event.preventDefault();
                return;
              default:
                return;
            }
          });

      fromEvent<KeyboardEvent>(element, 'keyup')
          .pipe(takeUntil(this.destroyed))
          .subscribe(({keyCode}: KeyboardEvent) => {
            switch (keyCode) {
              case SPACE:
                this.openDialog();
                return;
              default:
                return;
            }
          });
    });
  }

  abstract attachKeyboardCloseEventListeners(element: HTMLElement): void;

  private attachKeyboardFocusEventListeners(element: HTMLElement): void {
    // Emit the keyboard triggered beforeOpened event when the triggering
    // element gets focus.
    this.ngZone.runOutsideAngular(() => {
      fromEvent<KeyboardEvent>(element, 'focus')
          .pipe(
              filter(() => this.beforeOpened.observers.length > 0),
              takeUntil(this.destroyed),
              )
          .subscribe((event: KeyboardEvent) => {
            this.ngZone.run(() => {
              this.beforeOpened.emit();
            });
          });
    });
  }

  /** Open the dialog with no preconditions. */
  protected openDialog(): void {
    if (this.disabled) return;
    // Don't do anything if the dialog is already open.
    if (this.overlayRef?.hasAttached()) {
      return;
    }

    // Store a local copy of the portal, so that the reference can't be
    // reassigned from underneath while running in the Zone.
    const portal = this.portal;

    // If there's no dialog, don't open a Portal.
    if (portal == null) return;

    this.ngZone.run(() => {
      this.dialogContainerRef = this.createAndAttachDialogContainer();
      this.dialogContainerRef.instance.attach(portal);

      const dialogContent = this.dialogContainerRef.location.nativeElement;

      this.attachMouseEventListeners(dialogContent);
      this.attachKeyboardCloseEventListeners(dialogContent);

      this.trapFocus(dialogContent);

      this.dialogContainerRef.instance.toggleDialogAnimation(true);

      // Only emit the event when there are observers.
      if (this.opened.observers.length) {
        // Allow the animation to finish before emitting opened event.
        setTimeout(() => {
          this.opened.emit();
        }, ANIMATION_TRANSITION_TIME_MS);
      }
    });
  }

  /** Close the dialog with no preconditions. */
  closeDialog(): void {
    // Don't do anything if the dialog is already closed.
    if (!this.overlayRef?.hasAttached()) {
      return;
    }

    this.dialogContainerRef!.instance.toggleDialogAnimation(false);

    // Allow the animation to finish before destroying the container.
    setTimeout(() => {
      this.ngZone.run(() => {
        if (this.overlayRef) {
          this.overlayRef.detach();
        }

        this.cleanupFocusTrap();
        this.restoreFocus();

        this.cleanupDialogContainer();
        this.closed.emit();
      });
    }, ANIMATION_TRANSITION_TIME_MS);
  }

  /** Moves the focus inside the focus trap. */
  private trapFocus(element: HTMLElement) {
    const activeElement = this.document.activeElement;
    const triggerElement = this.elementRef.nativeElement;

    // Do not trap focus if we don't have a focused element, or the focused
    // element is not the trigger element. That is, trap focus only if the user
    // is using keyboard to interact with the dialog.
    if (!activeElement || activeElement !== triggerElement) {
      return;
    }

    // Saves a reference to the element that was focused before the dialog was
    // opened, so we can restore focus to this element after the dialog is
    // closed.
    if (this.document) {
      this.elementFocusedBeforeDialogWasOpened =
          this.document.activeElement as HTMLElement;
    }

    this.focusTrap = this.focusTrapFactory.create(element);
    this.focusTrap.attachAnchors();
    if (!this.disableAutoFocus) {
      this.focusInitialElement();
    }
  }

  /**
   * Focus on the initial element. This is usually done automatically
   * while opening the dialog, but can be manually controlled to defer the focus
   * to deal with cases like that the dialog content is deferred loaded
   * asynchronously.
   */
  focusInitialElement(): void {
    if (!this.focusTrap) return;
    this.focusTrap.focusInitialElementWhenReady();
  }

  private cleanupFocusTrap(): void {
    if (this.focusTrap) {
      this.focusTrap.destroy();
      this.focusTrap = undefined;
    }
  }

  /**
   * Restores focus to the element that was focused before the dialog opened.
   */
  private restoreFocus() {
    const toFocus = this.elementFocusedBeforeDialogWasOpened;

    // Don't do anything if we don't have an element to restore focus, or it
    // cannot be focused.
    if (!toFocus || typeof toFocus.focus !== 'function') {
      return;
    }

    const dialogContent = this.dialogContainerRef!.location.nativeElement;
    const activeElement = this.document.activeElement;

    // Don't do anything if we don't have a focused element, or the focused
    // element is not inside the dialog.
    if (!activeElement || !dialogContent.contains(activeElement)) {
      return;
    }

    toFocus.focus();
    this.elementFocusedBeforeDialogWasOpened = undefined;
  }

  private cleanupDialogContainer(): void {
    if (this.dialogContainerRef) {
      this.dialogContainerRef.destroy();
      this.dialogContainerRef = undefined;
    }
  }

  protected createPositionStrategy(positions: ConnectedPosition[]):
      PositionStrategy {
    return this.overlay.position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(positions)
        .setOrigin(this.elementRef);
  }

  private createAndAttachDialogContainer():
      ComponentRef<XapInlineDialogContainer> {
    const containerInjector = Injector.create({
      parent: this.injector,
      providers: [{
        provide: XAP_INLINE_DIALOG_CONTAINER_OPTIONS,
        useValue: {
          dialogLabel: this.dialogLabel,
          dialogTabIndex: this.dialogTabIndex,
        },
      }],
    });
    const containerPortal =
        new ComponentPortal(XapInlineDialogContainer, null, containerInjector);
    if (this.overlayRef == null) {
      this.overlayRef =
          this.overlay.create(this.createOverlayConfig(DEFAULT_POSITION_LIST));
    }
    if (this.overlaySize) {
      this.overlayRef.updateSize(this.overlaySize);
    }
    if (this.overlayPositions && this.overlayPositions.length > 0) {
      this.overlayRef.updatePositionStrategy(
          this.createPositionStrategy(this.overlayPositions));
    }
    return this.overlayRef.attach<XapInlineDialogContainer>(containerPortal);
  }
}
