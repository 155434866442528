<document-and-faq
  [conversation]="conversation$ | async"
  [suggestions]="suggestions$ | async"
  [features]="featuresArray"
  [searchAnswers]="articleSearchResults$ | async"
  [searchLoadingState]="articleSearchLoadingState$ | async"
  [answerRecords]="answerRecords$ | async"
  [articleLinkConfig]="articleLinkConfig"
  (onSearchArticles)="handleSearchArticles($event)"
  (onSelectArticle)="handleSelectArticle($event)"
  (onProvideFeedback)="handleRegisterAgentFeedback($event)"
></document-and-faq>
