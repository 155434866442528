<h2 class="app-background">
  <span class="header">{{header}}</span>
</h2>

<div
  *ngIf="scrollStream; else noScrollStream"
  [scrollOnEmit]="scrollStream"
  class="content-wrapper app-background"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #noScrollStream>
  <div class="content-wrapper app-background">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
