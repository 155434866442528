import {AfterViewInit, Directive, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, Optional} from '@angular/core';
import {assert} from 'google3/javascript/typescript/contrib/assert';
import {Subject} from 'rxjs';

import {DEFAULT_RICH_TOOLTIP_POSITION, DEFAULT_RICH_TOOLTIP_RENDER_DEBOUNCE_MS, RICH_TOOLTIP_DEFAULT_POSITION, RICH_TOOLTIP_RENDER_DEBOUNCE_MS} from './rich_tooltip_constants';
import {RichTooltipContent} from './rich_tooltip_inputs';
import {RichTooltipManager} from './rich_tooltip_manager';
import {RichTooltipPosition} from './rich_tooltip_position';
import {RichTooltipRef} from './rich_tooltip_ref';


/**
 * A directive that attaches a rich tooltip to a trigger element.
 *
 * This @Directive is used to carry data to RichTooltipManager singleton
 * service which adds event listeners on document level.
 */
@Directive({
  selector: '[richTooltip]',
})
export class RichTooltip implements RichTooltipRef, OnInit, OnDestroy,
                                    OnChanges, AfterViewInit {
  /** The tooltip contents. */
  @Input()
  set richTooltip(value: RichTooltipContent) {
    if (!value) {
      // No tooltip value, ignore this tooltip directive instance.
      this.richTooltipManager.deregisterTooltipForEnabledState(this);
      return;
    }

    // value is truthy, we can assume this node has a valid tooltip.
    this.richTooltipManager.registerTooltipForEnabledState(this);

    this.content = value;
  }
  // TS getter for public richTooltip @Input().
  get richTooltip() {
    return this.content;
  }

  /**
   * This is the data that will be used to instantiate the template.
   */
  @Input() richTooltipTemplateData: {} = {};

  /** Disables the display of the tooltip when true. */
  @Input() richTooltipDisabled = false;

  /** Determines the position of the tooltip with respect to its host. */
  @Input() richTooltipPosition: RichTooltipPosition;

  /**
   * An aria-label set on the tooltip dialog.
   */
  @Input() richTooltipAriaLabel!: string;

  /**
   * Prevent propagation of click and keydown events from the tooltip.
   */
  @Input() preventEventPropagation?: boolean;

  /**
   * If the tooltip is interactive.
   */
  @Input() isRichTooltipInteractive = true;

  /** The debounce to render a tooltip after hovering on the parent element. */
  richTooltipRenderDebounceMs: number;

  // This should be used to render RichTooltipOverlay contents instead of
  // richTooltip @Input().
  content!: RichTooltipContent;

  /** Will be triggered when @Inputs() of the component change. */
  onChange = new Subject<void>();

  /** Will be triggered when the tooltip is shown. */
  onShow = new Subject<void>();

  /** Will be triggered when the tooltip is hidden. */
  onHide = new Subject<void>();

  // When @Directive is constructed, register yourself with singleton service.
  constructor(
      private readonly richTooltipManager: RichTooltipManager,
      readonly elementRef: ElementRef<HTMLElement>,
      @Optional() @Inject(RICH_TOOLTIP_DEFAULT_POSITION) richTooltipPosition:
          RichTooltipPosition|null = DEFAULT_RICH_TOOLTIP_POSITION,
      @Optional() @Inject(RICH_TOOLTIP_RENDER_DEBOUNCE_MS) renderDebounceMs:
          number|null) {
    // Tooltips should not be directly placed on inputs directly, instead they
    // should be placed on help icons instead.
    assert(
        !elementRef.nativeElement.hasAttribute('matinput'),
        'Tooltips should not be placed on input elements directly.');
    this.richTooltipPosition =
        richTooltipPosition || DEFAULT_RICH_TOOLTIP_POSITION;
    this.richTooltipRenderDebounceMs =
        renderDebounceMs ?? DEFAULT_RICH_TOOLTIP_RENDER_DEBOUNCE_MS;
  }

  ngOnInit() {
    // An appropriate ARIA label for the rich tooltip should be provided.
    assert(
        this.richTooltipAriaLabel,
        'Please pass a valid ARIA label for the rich tooltip.');
  }

  ngAfterViewInit() {
    const host = this.elementRef.nativeElement;

    if (this.preventEventPropagation) {
      const EVENTS_TO_STOP_PROPAGATION = ['click', 'keydown'];
      for (const badEvent of EVENTS_TO_STOP_PROPAGATION) {
        host.addEventListener(
            // tslint:disable:no-any Using event.constructor
            badEvent, (event: any) => {
              // We only want to prevent events that would open the tooltip
              if (event instanceof KeyboardEvent) {
                if (event.key !== 'Enter' && event.key !== 'Space') {
                  return;
                }
              }

              // tslint:enable:no-any
              // Prevents the browser from performing a navigation if this
              // component is used inside of an anchor element (discouraged for
              // a11y reasons).
              event.preventDefault();

              event.stopPropagation();
              const docEvent = new event.constructor(event.type, event);
              Object.defineProperty(
                  docEvent, 'target', {writable: false, value: event.target});
              document.dispatchEvent(docEvent);
            });
      }
    }
  }

  // When @Inputs change, we might need to update RichTooltipOverlay
  ngOnChanges() {
    this.onChange.next();
  }

  // Deregister element when @Directive is destroyed.
  ngOnDestroy() {
    this.richTooltipManager.deregisterTooltipForEnabledState(this);
  }
}
