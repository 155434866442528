import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DoBootstrap, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotificationModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_module';
import {SmartReplyModule as BaseSmartReplyModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/smart_reply/smart_reply_module';

import {SmartReply} from './smart_reply';

@NgModule({
  imports: [
    BaseSmartReplyModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    NotificationModule,
  ],
  declarations: [
    SmartReply,
  ],
  exports: [
    SmartReply,
  ],
  entryComponents: [
    SmartReply,
  ]
})
export class SmartReplyModule implements DoBootstrap {
  ngDoBootstrap() {}
}
