import {animate, state, style, transition, trigger} from '@angular/animations';

/**
 * Reveal expand animation
 *
 * Animates from 0px height to *px height and vise versa
 */
export const revealExpand = trigger('revealExpand', [
  state('collapsed, void', style({
          height: '0',
          margin: '0',
          minHeight: '0',
          overflow: 'hidden',
          visibility: 'hidden'
        })),
  state('expanded', style({
          height: '*',
          margin: '*',
          overflow: 'visible',
          visibility: 'visible'
        })),
  transition(
      'expanded <=> collapsed',
      animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  transition(
      'expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);
