import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {WINDOW_INJECTION_TOKEN} from 'google3/java/com/google/dialogflow/console/web/common/window_provider/window_provider';

import {DialogServiceModule} from './dialog_service/dialog_service_module';
import {SimpleSnackBarModule} from './simple_snack_bar/simple_snack_bar_module';
import {SystemMessagingService} from './system_messaging_service';

/** Module for system dialog service */
@NgModule({
  imports: [
    MatDialogModule,
    MatButtonModule,
    DialogServiceModule,
    MatSnackBarModule,
    SimpleSnackBarModule,
  ],
  providers: [
    SystemMessagingService,
    {
      provide: WINDOW_INJECTION_TOKEN,
      useValue: window,
    },
  ],
})
export class SystemMessagingModule {
}
