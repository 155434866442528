import {URL_REGEX_MAP} from 'google3/java/com/google/dialogflow/console/web/common/constants/url_regex_map';

const {
  agent,
  annotations,
  answerRecords,
  conversations,
  conversationProfiles,
  dataItems,
  datasets,
  documents,
  evaluations,
  featureCreationFlows,
  knowledgeBases,
  models,
  participants,
  project,
  projectLocation,
  smartMessagingEntry,
  services
} = URL_REGEX_MAP;

const getName = (url: string = '', regex: RegExp) => {
  const match = url.match(regex) || [];

  return match[0];
};

/** Extract project name from extended name. */
export const getProjectNameFromExtendedName = (name: string) =>
    getName(name, project);

/** Extract project and location name from extended name. */
export const getProjectLocationNameFromExtendedName = (name: string) =>
    getName(name, projectLocation);

/** Extract agent name from extended name. */
export const getAgentNameFromExtendedName = (name: string) =>
    getName(name, agent);

/** Extracts the feature creation flow name from an extended resource name. */
export const getFeatureCreationFlowNameFromExtendedName = (name: string) =>
    getName(name, featureCreationFlows);

/** Extract dataset name from extended name. */
export const getDatasetNameFromExtendedName = (name: string) =>
    getName(name, datasets);

/** Extract annotation name from extended name. */
export const getAnnotationNameFromExtendedName = (name: string) =>
    getName(name, annotations);

/** Extract data item name from extended name. */
export const getDataItemNameFromExtendedName = (name: string) =>
    getName(name, dataItems);

/** Extract model name from extended name. */
export const getModelNameFromExtendedName = (name: string) =>
    getName(name, models);

/** Extract conversation model evaluation name from extended name. */
export const getConversationModelEvaluationNameFromExtendedName =
    (name: string) => getName(name, evaluations);

/** Extract conversation profile name from extended name. */
export const getConversationProfileNameFromExtendedName = (name: string) =>
    getName(name, conversationProfiles);

/** Extract conversation name from extended name. */
export const getConversationNameFromExtendedName = (name: string) =>
    getName(name, conversations);

/** Extract participant name from extended name. */
export const getParticipantNameFromExtendedName = (name: string) =>
    getName(name, participants);

/** Extract knowledge base name from extended name. */
export const getKnowledgeBaseNameFromExtendedName = (name: string) =>
    getName(name, knowledgeBases);

/** Extract document name from extended name. */
export const getDocumentNameFromExtendedName = (name: string) =>
    getName(name, documents);

/** Extract smart messaging entry name from extended name. */
export const getSmartMessagingEntryNameFromExtendedName = (name: string) =>
    getName(name, smartMessagingEntry);

/** Extract answer record name from extended name. */
export const getAnswerRecordNameFromExtendedName = (name: string) =>
    getName(name, answerRecords);

/** Extract service name from extended name. */
export const getServiceNameFromExtendedName = (name: string) =>
    getName(name, services);
