import {Directive, Inject, Input} from '@angular/core';

import {GMAT_CHIPS_OPTIONS, GmatChipsOptions} from './chips_options';

/**
 * The types of appearances that can be applied for a Google Material chip.
 * See the spec for details:
 * https://standards.google/guidelines/google-material/components/chips/
 */
export type GmatChipAppearance =
    'input'|'hairline-filter'|'protected-filter'|'hairline-suggestive'|
    'protected-suggestive'|'hairline-assistive'|'protected-assistive';

/** Directive that applies Google Material styles to the MDC MatChips. */
@Directive({
  selector: `
      mat-chip, mat-basic-chip,
      mat-chip-row, mat-basic-chip-row,
      mat-chip-option, mat-basic-chip-option
  `,
  host: {
    '[class.gmat-mdc-chip]': '!options.disableGmatStyle',
    '[class.gmat-input-chip]': `appearance === 'input'`,
    '[class.gmat-filter-chip]':
        `appearance === 'hairline-filter' || appearance === 'protected-filter'`,
    '[class.gmat-suggestive-chip]':
        `appearance === 'hairline-suggestive' || appearance === 'protected-suggestive'`,
    '[class.gmat-assistive-chip]':
        `appearance === 'hairline-assistive' || appearance === 'protected-assistive'`,
    '[class.gmat-hairline-chip]': 'isAppearanceHairline()',
    '[class.gmat-protected-chip]': 'isAppearanceProtected()',
    '[class.gmat-selectable-chip]': 'isAppearanceSelectable()',
  },
})
export class GmatChips {
  @Input() appearance: undefined|GmatChipAppearance;

  constructor(@Inject(GMAT_CHIPS_OPTIONS) readonly options: GmatChipsOptions) {}

  /** Whether this chip appearance is selectable. */
  isAppearanceSelectable() {
    return this.appearance === 'input' ||
        this.appearance === 'hairline-filter' ||
        this.appearance === 'protected-filter';
  }

  /** Whether this chip appearance uses the hairline variant. */
  isAppearanceHairline() {
    return this.appearance === 'input' ||
        this.appearance === 'hairline-filter' ||
        this.appearance === 'hairline-suggestive' ||
        this.appearance === 'hairline-assistive';
  }

  /** Whether this chip appearance uses the protected variant. */
  isAppearanceProtected() {
    return this.appearance === 'protected-filter' ||
        this.appearance === 'protected-suggestive' ||
        this.appearance === 'protected-assistive';
  }
}
