import {InjectionToken} from '@angular/core';
import {IconList} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/material_icon';

/** A list of Material Icons specific to a given UI module. */
export const UI_MODULE_ICONS = new InjectionToken<IconList>('UI module icons');

/**
 * Injection token to determine whether a UI module is loaded independently, or
 * as a child of the Container component.
 */
export const PARENT_CONTAINER_CONTEXT =
    new InjectionToken<boolean>('module loaded in parent container');
