import {Dictionary} from 'google3/java/com/google/dialogflow/console/web/common/types/common_types';

/**
 * Determines the number of keys on an object.
 */
export const getObjSize = (obj: object) =>
    Object.getOwnPropertyNames(obj).length;

/**
 * Determines whether an object is empty (has no keys).
 *
 * i.e. objIsEmpty({}) === true
 */
export const objIsEmpty = (obj: object) => getObjSize(obj) === 0;

/** Checks whether a given input is an object. */
export const isObject = (val: unknown) =>
    Object.prototype.toString.call(val) === '[object Object]';

/** Deep clone an object. */
export const deepClone = <T>(obj: T) => JSON.parse(JSON.stringify(obj)) as T;

/**
 * Applies a patch to an object, and returns a new object with the patches
 * applied.
 *
 * i.e., if the object is {a: {b: 2, c: 3}}, and the patch is {a: {b: 4}},
 * a new object will be returned with values {a: {b: 4, c: 3}}.
 *
 * @param object The object to patch.
 * @param patch A subset of the original object, with updated values.
 */
export const applyPatch = <O extends {}, P extends {}>(object: O, patch: P) => {
  const patchedObject = JSON.parse(JSON.stringify(object)) as O;

  const pachedObjectPropertyNames =
      new Set(Object.getOwnPropertyNames(patchedObject) as Array<keyof O>);

  for (const key in patchedObject) {
    if (pachedObjectPropertyNames.has(key) && key in patch) {
      const originalValue = patchedObject[key];

      // Casting key as 'keyof P' to be able to index patch. Casting resulting
      // object to 'O[typeof key]' to be able to assign it back to
      // patchedObject[key].
      const patchedValue =
          patch[key as unknown as keyof P] as unknown as O[typeof key];

      // @ts-ignore(go/ts48upgrade) Fix code and remove this comment. Error:
      // TS2322: Type '{} | O[Extract<keyof O, string>]' is not assignable to
      // type 'O[Extract<keyof O, string>]'.
      patchedObject[key] = (isObject(originalValue) && isObject(patchedValue)) ?
          // @ts-ignore(go/ts48upgrade) Fix code and remove this comment. Error:
          // TS2345: Argument of type 'O[Extract<keyof O, string>]' is not
          // assignable to parameter of type '{}'.
          applyPatch(originalValue, patchedValue) :
          patchedValue;
    }
  }

  return patchedObject;
};

/** Checks whether a string input is valid JSON. */
export const isValidJson = (value: string) => {
  try {
    // tslint:disable-next-line:no-unused-expression
    JSON.parse(value) as Dictionary;
    return true;
  } catch (err: unknown) {
    return false;
  }
};
