import {enableProdMode} from '@angular/core';
import {createCustomElement, NgElementConstructor} from '@angular/elements';
import {platformBrowser} from '@angular/platform-browser';
import {initUiModuleEvents} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';

import {Container, UI_MODULES_CONTAINER_ELEMENT_SELECTOR} from './container';
import {ContainerModule} from './container_module';



/**
 * This is the type of the SmartReplyElement used with document.querySelector.
 */
export type ContainerElement = InstanceType<NgElementConstructor<Container>>;

if (COMPILED) {
  enableProdMode();
}

initUiModuleEvents();

platformBrowser().bootstrapModule(ContainerModule).then(({injector}) => {
  const element = createCustomElement(Container, {injector});
  customElements.define(UI_MODULES_CONTAINER_ELEMENT_SELECTOR, element);
});
