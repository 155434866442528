import {tapResponse} from '@ngrx/component-store';
import {DEFAULT_API_ERROR_RESPONSE} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/api_constants';
import {DEFAULT_PARTICIPANT_ROLES} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/participants';
import {getConversationName} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/conversation_helpers';
import {dispatchUiModuleEvent, fromUiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {isApiErrorResponse} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/error_helpers';
import {isLoaded, isLoading} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/loading_state_helpers';
import {DialogflowApiService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/dialogflow_api_service';
import {UiModuleStore} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/store/ui_module_store';
import {AnalyzeContentRequestDetails} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/analyze_content';
import {PatchAnswerRecordPayload} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/answer_record_types';
import {ApiErrorResponse, ErrorSource, UiModuleError} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/api_error';
import {ConnectorConfig, UiModuleConnector} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/connector_types';
import {ActiveConversationSelectedPayload, AnswerRecordRequestedPayload, ArticleSearchRequestedPayload, ConversationInitializationRequestedPayload, ConversationModelRequestedPayload, ConversationProfileRequestedPayload, ListMessagesRequestedPayload, UiModuleEvent, UiModuleEventPayload} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {Dictionary} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/dictionary';
import {PatchPayload} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/http_options';
import {LoadingState} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/loading_state';
import {Participants, ParticipantsLoadingState} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/participants';
import {getConversationNameFromExtendedName} from 'google3/java/com/google/dialogflow/console/web/common/helpers/proto_name_extractors';
import {getConversationIdFromName} from 'google3/java/com/google/dialogflow/console/web/common/helpers/proto_name_id_extractors';
import {AnalyzeContentRequest_, Conversation_, ConversationProfile_, Participant_, Participant_Role} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {catchError, concatMap, distinctUntilChanged, filter, map, mergeMap, switchMap, take, takeUntil, tap, withLatestFrom} from 'rxjs/operators';

interface State {
  conversation: Conversation_|null;
  conversationLoadingState: LoadingState;
  conversationProfile: ConversationProfile_|null;
  conversationProfileLoadingState: LoadingState;
  participants: Participants;
  participantsLoadingState: ParticipantsLoadingState;
  error: UiModuleError|null;
}

const initialState: State = {
  conversation: null,
  conversationLoadingState: 'NOT_LOADING',
  conversationProfile: null,
  conversationProfileLoadingState: 'NOT_LOADING',
  participants: {},
  participantsLoadingState: {},
  error: null
};

/**
 * Dialogflow API connector.
 *
 * Handles all API integrations, including initialization of Dialogflow
 * conversations as well as calling AnalyzeContent to receive Agent Assist
 * suggestions.
 *
 * Also dispatches notifications for UI modules when new AnalyzeContent
 * responses are received.
 */
export class ApiConnector implements UiModuleConnector {
  readonly apiService = new DialogflowApiService(this.config);
  readonly store = new UiModuleStore<State>();

  private readonly participantLoadingState$ =
      (role: Participant_Role, conversationName: string) =>
          this.store.selectForConversation(
              state => state.participantsLoadingState[role])(conversationName);
  private readonly conversationLoadingState$ =
      this.store.selectForConversation(state => state.conversationLoadingState);
  private readonly participants$ =
      this.store.selectForConversation(state => state.participants);
  private readonly activeParticipants$ =
      this.store.selectForActiveConversation(state => state.participants);
  private readonly conversation$ =
      this.store.selectForConversation(state => state.conversation);
  private readonly conversationProfile$ =
      this.store.selectForConversation(state => state.conversationProfile);
  private readonly activeConversation$ =
      this.store.selectForActiveConversation(state => state.conversation);
  private readonly activeConversationName$ = this.activeConversation$.pipe(
      map(conversation => conversation?.name || ''));
  private readonly error$ =
      this.store.selectForConversation(state => state.error);
  private readonly participantsAreLoading$ = this.store.selectForConversation(
      state => Object.values(state.participantsLoadingState).some(isLoading));
  private readonly conversationIsLoading$ = (conversationName: string) =>
      this.conversationLoadingState$(conversationName).pipe(map(isLoading));
  private readonly conversationInitialized$ = (conversationName: string) =>
      combineLatest([
        this.conversationLoadingState$(conversationName),
        this.participantLoadingState$('HUMAN_AGENT', conversationName),
        this.participantLoadingState$('END_USER', conversationName)
      ])
          .pipe(
              map(loadingStates => loadingStates.every(isLoaded)),
              distinctUntilChanged());

  private readonly latestUninitializedAnalyzeContentRequest:
      {[conversationName: string]: AnalyzeContentRequestDetails|
       undefined;} = {};

  private readonly setConversationLoadingState = this.store.updater(
      (state, conversationLoadingState: LoadingState) =>
          ({...state, conversationLoadingState}));

  /**
   * Creates a new participant, and loads the response (or error) in state.
   */
  private readonly createParticipant = this.store.effect(
      (source$:
           Observable<{role: Participant_Role, conversation: Conversation_}>) =>
          source$.pipe(
              concatMap(({role, conversation}) => {
                const conversationName = conversation.name!;

                this.setParticipantLoadingState(
                    role, 'LOADING', conversationName);

                return this.apiService.createParticipant(conversationName, role)
                    .pipe(
                        tapResponse(
                            participant => {
                              this.setParticipant(
                                  participant, conversationName);
                            },
                            error => {
                              this.setParticipantLoadingState(
                                  role, 'ERROR', conversationName);
                              this.setError({
                                error,
                                conversationName,
                                source: 'INITIALIZATION',
                              });
                            }),
                    );
              }),
              ));

  /** Creates a new conversation, and loads the response (or error) in state. */
  private readonly createConversation = this.store.effect(
      (source$: Observable<string>) => source$.pipe(
          concatMap(conversationName => {
            this.setConversationLoadingState('LOADING', conversationName);

            const conversationId = getConversationIdFromName(conversationName);

            return this.apiService.createConversation(conversationId)
                .pipe(
                    tapResponse(
                        conversation => {
                          this.setConversation(conversation);
                        },
                        error => {
                          this.setConversationLoadingState(
                              'ERROR', conversationName);
                          this.setError({
                            error,
                            conversationName,
                            source: 'INITIALIZATION',
                          });
                        }),
                );
          }),
          ));

  /**
   * Creates a new Dialogflow conversation if none exists, or fetches the
   * existing conversation and loads it in state.
   */
  private readonly initializeConversation = this.store.effect(
      (source$: Observable<ConversationInitializationRequestedPayload>) =>
          source$.pipe(switchMap(({conversationName}) => {
            return of(conversationName)
                .pipe(
                    withLatestFrom(
                        this.conversationInitialized$(conversationName),
                        this.conversationIsLoading$(conversationName),
                        this.participantsAreLoading$(conversationName),
                        ),
                    filter(
                        ([
                          ,
                          conversationInitialized,
                          conversationIsLoading,
                          participantsAreLoading,
                        ]) => !conversationInitialized &&
                            !conversationIsLoading && !participantsAreLoading),
                    concatMap(([conversationName]) => {
                      // Checks if a conversation with the given ID exists
                      // before attempting to create a new one.
                      return this.apiService.getConversation(conversationName)
                          .pipe(
                              concatMap(conversation => {
                                if (conversation) {
                                  this.setConversation(conversation);

                                  return this.handleInitializeParticipants$(
                                      conversation);
                                } else {
                                  // No conversation found. Creates a new
                                  // conversation and participants.
                                  return this.handleInitializeConversation$(
                                      conversationName);
                                }
                              }),
                              catchError((error: ApiErrorResponse) => {
                                this.setError({
                                  error,
                                  conversationName,
                                  source: 'INITIALIZATION',
                                });
                                return of(null);
                              }));
                    }),
                    catchError((error: ApiErrorResponse) => {
                      this.setError({
                        error,
                        conversationName: '',
                        source: 'INITIALIZATION',
                      });
                      return of();
                    }),
                );
          })));

  private readonly getConversationProfile = this.store.effect(
      (source$: Observable<ConversationProfileRequestedPayload>) =>
          source$.pipe(
              withLatestFrom(this.activeConversationName$),
              concatMap(([{conversationProfileName}, conversationName]) => {
                return this.getConversationProfile$(
                    conversationName, conversationProfileName);
              })));

  private readonly getConversationModel = this.store.effect(
      (source$: Observable<ConversationModelRequestedPayload>) => source$.pipe(
          withLatestFrom(this.activeConversationName$),
          concatMap(([{modelName}, conversationName]) => {
            return this.getConversationModel$(conversationName, modelName);
          })));

  /**
   * Subscribes to ANALYZE_CONTENT_REQUESTED events. Dispatches signal to create
   * a Dialogflow conversation if none exists, and calls AnalyzeContent to fetch
   * Agent Assist suggestions.
   */
  private readonly analyzeContent = this.store.effect(
      (source$: Observable<
          UiModuleEventPayload[UiModuleEvent.ANALYZE_CONTENT_REQUESTED]>) =>
          source$.pipe(switchMap(analyzeContentRequestDetails => {
            const conversationName = getConversationName(
                analyzeContentRequestDetails.conversationId,
                this.config.conversationProfileName);

            return of(analyzeContentRequestDetails)
                .pipe(
                    withLatestFrom(
                        this.conversationInitialized$(conversationName),
                        this.participants$(conversationName)),
                    tap(([request, initialized]) => {
                      if (!initialized) {
                        this.latestUninitializedAnalyzeContentRequest[conversationName] =
                            request;
                      }
                    }),
                    filter(([, initialized]) => initialized),
                    concatMap(([
                                {participantRole, request, type}, , participants
                              ]) => {
                      const participant = participants[participantRole];

                      if (!participant) {
                        return of();
                      }

                      return this.handleAnalyzeContent$(
                          {participant, request, type});
                    }),
                );
          })));

  /**
   * Subscribes to SMART_REPLY_SELECTED events, and calls SuggestSmartReplies to
   * dispatch Smart Reply follow-up suggestions.
   */
  private readonly suggestSmartReplies = this.store.effect(
      (source$: Observable<string>) => source$.pipe(
          withLatestFrom(this.activeParticipants$),
          filter(([, participants]) => !!participants['HUMAN_AGENT']),
          concatMap(([message, participants]) => {
            const humanAgentParticipantName =
                participants['HUMAN_AGENT']!.name!;
            const conversationName =
                getConversationNameFromExtendedName(humanAgentParticipantName);

            return this.apiService
                .suggestSmartReplies(humanAgentParticipantName, message)
                .pipe(
                    tapResponse(
                        ({smartReplyAnswers}) => {
                          if (smartReplyAnswers) {
                            dispatchUiModuleEvent(
                                UiModuleEvent
                                    .SMART_REPLY_FOLLOW_UP_SUGGESTIONS_RECEIVED,
                                {
                                  detail: {
                                    conversationName,
                                    payload: smartReplyAnswers
                                  }
                                });
                          }
                        },
                        error => {
                          this.setError(
                              {error, conversationName, source: 'SMART_REPLY'});
                        }),
                );
          }),
          ));

  /** Generates a conversation summary. */
  private readonly suggestConversationSummary = this.store.effect(
      (source$: Observable<void>) => source$.pipe(
          withLatestFrom(this.activeConversation$),
          filter(([, conversation]) => !!conversation),
          switchMap(([, conversation]) => {
            const conversationName = conversation!.name!;

            return this.apiService.suggestConversationSummary(conversationName)
                .pipe(
                    tapResponse(
                        response => {
                          dispatchUiModuleEvent(
                              UiModuleEvent.CONVERSATION_SUMMARIZATION_RECEIVED,
                              {detail: {conversationName, payload: response}});
                        },
                        error => {
                          this.setError({
                            error,
                            conversationName,
                            source: 'CONVERSATION_SUMMARIZATION'
                          });
                        }),
                );
          })));

  /**
   * Patches an answer record.
   * Used for tracking edits to a conversation summary.
   */
  private readonly patchAnswerRecord = this.store.effect(
      (source$: Observable<
          UiModuleEventPayload[UiModuleEvent.PATCH_ANSWER_RECORD_REQUESTED]>) =>
          source$.pipe(mergeMap(({
                                  payload: {answerRecord, previousAnswerRecord},
                                  options
                                }) => {
            const conversationName =
                getConversationNameFromExtendedName(answerRecord.name!);

            return this.apiService.patchAnswerRecord(answerRecord, options)
                .pipe(
                    tapResponse(
                        response => {
                          dispatchUiModuleEvent(
                              UiModuleEvent.PATCH_ANSWER_RECORD_RECEIVED,
                              {detail: {conversationName, payload: response}});

                          // Show 'undo' snackbar if negative feedback is
                          // provided.
                          if (answerRecord.answerFeedback?.correctnessLevel ===
                              'NOT_CORRECT') {
                            dispatchUiModuleEvent(
                                UiModuleEvent.SNACKBAR_NOTIFICATION_REQUESTED, {
                                  detail: {
                                    message: 'Feedback received',
                                    actionMessage: 'Undo',
                                    actionHandler: () => {
                                      dispatchUiModuleEvent(
                                          UiModuleEvent
                                              .PATCH_ANSWER_RECORD_REQUESTED,
                                          {
                                            detail: {
                                              payload: {
                                                answerRecord:
                                                    previousAnswerRecord ||
                                                    {
                                                      'name': answerRecord.name,
                                                          'answerFeedback': {
                                                            'correctnessLevel':
                                                                'CORRECTNESS_LEVEL_UNSPECIFIED'
                                                          },
                                                    }
                                              },
                                              options
                                            }
                                          });
                                    },
                                  }
                                });
                          }
                        },
                        error => {
                          this.setError({
                            error,
                            conversationName,
                            source: 'TYPE_UNSPECIFIED',
                            data: {'name': answerRecord.name}
                          });
                        },
                        ),
                );
          })));

  /** Gets an answer record. */
  private readonly getAnswerRecord = this.store.effect(
      (source$: Observable<AnswerRecordRequestedPayload>) => source$.pipe(
          withLatestFrom(this.activeConversationName$),
          mergeMap(([{answerRecordName}, conversationName]) => {
            return this.apiService.getAnswerRecord(answerRecordName)
                .pipe(
                    tapResponse(
                        response => {
                          dispatchUiModuleEvent(
                              UiModuleEvent.ANSWER_RECORD_RECEIVED, {
                                detail: {
                                  conversationName,
                                  payload: response,
                                }
                              });
                        },
                        error => {
                          this.setError({
                            error,
                            conversationName,
                            source: 'TYPE_UNSPECIFIED',
                            data: {'name': answerRecordName}
                          });
                        },
                        ),
                );
          })));

  /** Search articles. */
  private readonly searchArticles = this.store.effect(
      (source$: Observable<ArticleSearchRequestedPayload>) => source$.pipe(
          withLatestFrom(this.activeConversation$),
          filter(([, conversation]) => !!conversation),
          switchMap(([{queryText}, conversation]) => {
            const conversationName = conversation!.name!;

            return this.apiService.searchArticles(conversationName, queryText)
                .pipe(
                    tapResponse(
                        response => {
                          dispatchUiModuleEvent(
                              UiModuleEvent.ARTICLE_SEARCH_RESPONSE_RECEIVED,
                              {detail: {conversationName, payload: response}});
                        },
                        error => {
                          this.setError({
                            error,
                            conversationName,
                            source: 'ARTICLE_SEARCH',
                            data: {'filterQuery': queryText}
                          });
                        }),
                );
          })));

  /** Lists messages for a given conversation. */
  private readonly listMessages = this.store.effect(
      (source$: Observable<ListMessagesRequestedPayload>) =>
          source$.pipe(switchMap(
              ({conversationName}) =>
                  this.apiService.listMessages(conversationName)
                      .pipe(tapResponse(
                          response => {
                            dispatchUiModuleEvent(
                                UiModuleEvent.LIST_MESSAGES_RESPONSE_RECEIVED, {
                                  detail: {conversationName, payload: response}
                                });
                          },
                          error => {
                            this.setError({
                              error,
                              conversationName,
                              source: 'LIST_MESSAGES',
                            });
                          })))));

  private readonly selectConversation = this.store.effect(
      (source$: Observable<ActiveConversationSelectedPayload>) => source$.pipe(
          withLatestFrom(this.store.state$),
          tap(([{conversationName}, state]) => {
            if (!state.byConversation[conversationName]) {
              dispatchUiModuleEvent(
                  UiModuleEvent.CONVERSATION_INITIALIZATION_REQUESTED,
                  {detail: {conversationName}});
            }
          })));

  private initialized = false;

  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(private readonly config: ConnectorConfig) {
    this.store.init(initialState);
  }

  /** Registers all relevant subscriptions. */
  init() {
    if (!this.initialized) {
      this.initialized = true;

      fromUiModuleEvent(UiModuleEvent.ACTIVE_CONVERSATION_SELECTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(payload => {
            this.selectConversation(payload);
          });

      fromUiModuleEvent(UiModuleEvent.CONVERSATION_INITIALIZATION_REQUESTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(payload => {
            this.initializeConversation(payload);
          });

      fromUiModuleEvent(UiModuleEvent.CONVERSATION_PROFILE_REQUESTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(payload => {
            this.getConversationProfile(payload);
          });

      fromUiModuleEvent(UiModuleEvent.CONVERSATION_MODEL_REQUESTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(payload => {
            this.getConversationModel(payload);
          });

      fromUiModuleEvent(UiModuleEvent.ANALYZE_CONTENT_REQUESTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(payload => {
            this.analyzeContent(payload);
          });

      fromUiModuleEvent(UiModuleEvent.LIST_MESSAGES_REQUESTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(payload => {
            this.listMessages(payload);
          });

      fromUiModuleEvent(UiModuleEvent.SMART_REPLY_SELECTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
              ({answer: {reply, answerRecord}, clickTime, displayTime}) => {
                this.suggestSmartReplies(reply!);

                if (answerRecord) {
                  const patchAnswerRecordPayload:
                      PatchPayload<PatchAnswerRecordPayload> = {
                        options: {updateMask: 'answerFeedback'},
                        payload: {
                          answerRecord: {
                            'name': answerRecord,
                            'answerFeedback': {
                              'clickTime': clickTime,
                              'displayTime': displayTime,
                              'clicked': true,
                              'displayed': true,
                              'correctnessLevel': 'FULLY_CORRECT',
                            }
                          }
                        }
                      };

                  dispatchUiModuleEvent(
                      UiModuleEvent.PATCH_ANSWER_RECORD_REQUESTED,
                      {detail: patchAnswerRecordPayload});
                }
              });

      fromUiModuleEvent(UiModuleEvent.CONVERSATION_SUMMARIZATION_REQUESTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.suggestConversationSummary();
          });

      fromUiModuleEvent(UiModuleEvent.PATCH_ANSWER_RECORD_REQUESTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(payload => {
            this.patchAnswerRecord(payload);
          });

      fromUiModuleEvent(UiModuleEvent.ANSWER_RECORD_REQUESTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(payload => {
            this.getAnswerRecord(payload);
          });

      fromUiModuleEvent(UiModuleEvent.ARTICLE_SEARCH_REQUESTED)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(payload => {
            this.searchArticles(payload);
          });

      this.store.conversationRegistered$
          .pipe(
              mergeMap(
                  conversationName =>
                      this.error$(conversationName).pipe(filter(Boolean))),
              takeUntil(this.destroyed$))
          .subscribe(error => {
            // tslint:disable-next-line:no-dict-access-on-struct-type
            if (error.error?.['code'] === 403) {
              error.source = 'AUTHORIZATION';
            }

            dispatchUiModuleEvent(
                UiModuleEvent.DIALOGFLOW_API_ERROR, {detail: error});
          });

      /**
       * Dispatches an event when a Dialogflow conversation and participants
       * have been initialized.
       */
      this.store.conversationRegistered$
          .pipe(
              mergeMap(conversationName => {
                return this.conversationInitialized$(conversationName)
                    .pipe(
                        filter(Boolean),
                        take(1),
                        withLatestFrom(
                            this.conversation$(conversationName),
                            this.participants$(conversationName)),
                    );
              }),
              takeUntil(this.destroyed$))
          .subscribe(([, conversation, participants]) => {
            const conversationName = conversation?.name!;

            if (this.latestUninitializedAnalyzeContentRequest
                    [conversationName]) {
              this.analyzeContent(this.latestUninitializedAnalyzeContentRequest
                                      [conversationName]!);
            }

            dispatchUiModuleEvent(
                UiModuleEvent.CONVERSATION_INITIALIZED,
                {detail: {conversation: conversation!, participants}});
            dispatchUiModuleEvent(
                UiModuleEvent.CONVERSATION_PROFILE_REQUESTED, {
                  detail: {
                    conversationProfileName: conversation!.conversationProfile!
                  }
                });
          });

      this.activeConversationName$
          .pipe(
              switchMap(
                  conversationName =>
                      this.conversationProfile$(conversationName)),
              takeUntil(this.destroyed$))
          .subscribe((conversationProfile) => {
            dispatchUiModuleEvent(
                UiModuleEvent.CONVERSATION_PROFILE_RECEIVED,
                {detail: conversationProfile});
          });
    }
  }

  setAuthToken(authToken: string) {
    this.config.apiConfig.authToken = authToken;
  }

  /** Unsubscribes from all active subscriptions. */
  disconnect() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getConversationProfile$(
      conversationName: string, conversationProfileName?: string|null) {
    if (!conversationProfileName) {
      return of(null);
    }

    this.setConversationProfileLoadingState('LOADING', conversationName);
    return this.apiService.getConversationProfile(conversationProfileName)
        .pipe(tapResponse(
            response => {
              this.setConversationProfile(response, conversationName);
            },
            error => {
              this.setConversationProfileLoadingState(
                  'ERROR', conversationName);
              this.setError({
                conversationName,
                error,
                source: 'GET_CONVERSATION_PROFILE',
              });
            }));
  }

  private getConversationModel$(
      conversationName: string, modelName?: string|null) {
    if (!modelName) {
      return of(null);
    }

    return this.apiService.getConversationModel(modelName).pipe(tapResponse(
        response => {
          dispatchUiModuleEvent(
              UiModuleEvent.CONVERSATION_MODEL_RECEIVED, {detail: response});
        },
        error => {
          this.setError({
            conversationName,
            error,
            source: 'GET_CONVERSATION_MODEL',
          });
        }));
  }

  /**
   * Calls AnalyzeContent with the given request, and dispatches an event to
   * notify web components of the most recent AnalyzeContent response.
   *
   * @param participant - Participant to use.
   * @param request - AnalyzeContentRequest object to use.
   * @param type Optional label to identify the source of the AnalyzeContent
   *     request.
   */
  private handleAnalyzeContent$({participant, request, type}: {
    participant: Participant_,
    request: AnalyzeContentRequest_,
    type?: string,
  }) {
    const conversationName =
        getConversationNameFromExtendedName(participant?.name!);

    return this.apiService.analyzeContent(participant.name!, request)
        .pipe(
            tapResponse(
                response => {
                  dispatchUiModuleEvent(
                      UiModuleEvent.ANALYZE_CONTENT_RESPONSE_RECEIVED, {
                        detail: {
                          conversationName,
                          payload: {
                            type,
                            response,
                            participant,
                            request,
                          }
                        }
                      });
                },
                error => {
                  this.setError({
                    error,
                    conversationName,
                    source: 'ANALYZE_CONTENT',
                    data: {'participant': participant}
                  });
                }),
        );
  }

  /**
   * Creates a new Dialogflow conversation as well as the default participants.
   *
   * @param conversationName Custom conversation name to use.
   */
  private handleInitializeConversation$(conversationName: string) {
    this.createConversation(conversationName);

    return this.conversationLoadingState$(conversationName)
        .pipe(
            filter(isLoaded),
            withLatestFrom(this.conversation$(conversationName)),
            tap(([, conversation]) => {
              this.createDefaultParticipants(conversation!);
            }),
        );
  }

  /**
   * Lists all participants for a given conversation, and creates any missing
   * participants.
   *
   * @param conversation Dialogflow conversation to use.
   */
  private handleInitializeParticipants$(conversation: Conversation_) {
    const conversationName = conversation.name!;

    return this.apiService.listParticipants(conversationName)
        .pipe(
            tapResponse(
                response => {
                  // Creates any missing participants.
                  for (const role of DEFAULT_PARTICIPANT_ROLES) {
                    const participant = response.participants?.find(
                        participant => participant.role === role);

                    if (participant) {
                      this.setParticipant(participant, conversationName);
                    } else {
                      this.createParticipant({role, conversation});
                    }
                  }
                },
                error => {
                  this.setError({
                    error,
                    conversationName,
                    source: 'INITIALIZATION',
                  });
                }),
        );
  }

  /**
   * Creates the default participants for a conversation.
   *
   * @param conversation Dialogflow conversation to use.
   */
  private createDefaultParticipants(conversation: Conversation_) {
    for (const role of DEFAULT_PARTICIPANT_ROLES) {
      this.createParticipant({role, conversation});
    }
  }

  /**
   * Sets a loaded participant in state.
   *
   * @param participant Participant to use.
   */
  private setParticipant(participant: Participant_, conversationName: string) {
    this.store.setStateForConversation(
        conversationName,
        state => ({
          ...state,
          error: null,
          participants:
              {...state.participants, [participant.role!]: participant},
        }));
    this.setParticipantLoadingState(
        participant.role!, 'LOADED', conversationName);
  }

  /**
   * Sets a loading state for a given participant in state.
   *
   * @param role Participant role to use.
   * @param loadingState Loading state to use.
   */
  private setParticipantLoadingState(
      role: Participant_Role, loadingState: LoadingState,
      conversationName: string) {
    this.store.setStateForConversation(
        conversationName,
        state => ({
          ...state,
          participantsLoadingState:
              {...state.participantsLoadingState, [role]: loadingState}
        }));
  }

  /**
   * Sets a loaded conversation in state.
   *
   * @param conversation Conversation to use.
   */
  private setConversation(conversation: Conversation_) {
    const conversationName = conversation.name!;

    this.store.setStateForConversation(
        conversationName, state => ({...state, conversation}));
    this.setConversationLoadingState('LOADED', conversationName);
  }

  private setConversationProfile(
      conversationProfile: ConversationProfile_|null,
      conversationName: string) {
    this.store.setStateForConversation(
        conversationName, state => ({...state, conversationProfile}));
    this.setConversationProfileLoadingState('LOADED', conversationName);
  }

  private setConversationProfileLoadingState(
      loadingState: LoadingState, conversationName: string) {
    this.store.setStateForConversation(
        conversationName,
        state => ({...state, conversationProfileLoadingState: loadingState}));
  }

  private setError({error, conversationName, source, data}: {
    error: unknown,
    conversationName: string,
    source: ErrorSource,
    data?: Dictionary
  }) {
    const apiError =
        isApiErrorResponse(error) ? error : DEFAULT_API_ERROR_RESPONSE;

    this.store.setStateForConversation(
        conversationName, state => ({
                            ...state,
                            error: {
                              source,
                              conversationName,
                              error: apiError.error,
                              data,
                            }
                          }));
  }
}
