import {animate, state, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AnalyzeContentResponse_, ConversationGuidanceModelMetadataStage_, ConversationGuidanceModelMetadataStageGroup_, ConversationModel_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_ts_api_client';
import {LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';
import {HTML5SessionStorage} from 'google3/third_party/javascript/closure/storage/mechanism/html5sessionstorage';

interface VisitedGroupMap {
  [groupName: string]: boolean;
}
interface VisitedStageMap {
  [stageName: string]: boolean;
}

interface SessionStorageVisitedStatus {
  visitedGroupMap: VisitedGroupMap;
  visitedStageMap: VisitedStageMap;
}

/** Prefix for conversation guidance items stored in sessions storage */
const SESSION_STORAGE_GUIDANCE_PREFIX = 'GUIDANCE_';
/** How long to display the congrats notification before automatic dismissal */
export const CONGRATS_NOTIFICATION_DURATION_MILLIS = 5000;

/** Conversation Guidance suggestion feature. */
@Component({
  selector: 'conversation-guidance',
  templateUrl: './conversation_guidance.ng.html',
  styleUrls: ['./conversation_guidance.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger(
        'flyIn',
        [
          state('none', style({transform: 'translateX(0)'})),
          transition(
              'void => right',
              [
                style({transform: 'translateX(100%)'}), animate('300ms ease-in')
              ]),
          transition(
              'void => left',
              [
                style({transform: 'translateX(-100%)'}),
                animate('300ms ease-in')
              ])
        ]),
    trigger(
        'fadeIn',
        [
          transition(
              'void => *',
              [
                style({opacity: 0.25}),
                animate(
                    '125ms ease-in',
                    style({opacity: 1}),
                    )
              ]),
        ]),
  ]
})
export class ConversationGuidance {
  @ViewChild('body', {static: true}) readonly body?: ElementRef;

  @Input()
  get loadingState() {
    return this.innerLoadingState;
  }
  set loadingState(loadingState: LoadingState|null|undefined) {
    this.innerLoadingState = loadingState;
  }

  @Input()
  set conversationModelAndContentResponse(response: {
    conversationName?: string|null,
    conversationModel?: ConversationModel_|null,
    analyzeContentResponse?: AnalyzeContentResponse_|null
  }|null) {
    const {conversationName, conversationModel, analyzeContentResponse} =
        response || {};

    const isNewConversation = this.innerConversationName &&
        this.innerConversationName !== conversationName;
    if (!this.innerConversationName || isNewConversation) {
      this.innerConversationName = conversationName;
      this.resetConversationState();
    }

    const isNewModel = this.innerConversationModel &&
        this.innerConversationModel.name !== conversationModel?.name;
    if (!this.innerConversationModel || isNewModel) {
      this.innerConversationModel = conversationModel;
      this.resetConversationModelState();
    }

    if (analyzeContentResponse) {
      const detectedStage = this.getDetectedStage(analyzeContentResponse);
      this.updateActiveGroupAndStage(detectedStage);
      this.updateVisitedAndSkippedStatus(detectedStage);
      if (this.activeGroupName &&
          this.selectedGroupName !== this.activeGroupName) {
        this.setSelectedGroupName(this.activeGroupName);
      }
    }
  }

  /**
   * List of all the groups associated with the model in the order they should
   * be displayed in.
   */
  groups: ConversationGuidanceModelMetadataStageGroup_[] = [];

  /* Map of all stages associated with the model. */
  stageMap: {
    [stageName: string]: {
      groupName: string,
      stage: ConversationGuidanceModelMetadataStage_,
      selectedGuidance: string
    }|undefined
  } = {};

  /* Map of visited status for each stage. */
  visitedStageMap: {[stageName: string]: boolean;} = {};

  /**
   * Map of all groups associated with the model with their associated order
   * index.
   */
  groupMap: {
    [groupName: string]:
        {group: ConversationGuidanceModelMetadataStageGroup_, index: number}|
    undefined
  } = {};

  /**
   * Map of all groups that have been visited.
   * A group is declared visited when all of its stages have been visited.
   */
  visitedGroupMap: VisitedGroupMap = {};

  /**
   * Map of all groups that have been skipped.
   * A group is declared skipped when at least one of its stages hasn't been
   * visited and a group that occurs at an index greater than it has been
   * visited.
   */
  skippedGroupMap: {[groupName: string]: boolean} = {};

  /**
   * Map of all stages that have been skipped.
   * A stage is declared skipped when a stage that occurs at an index
   * greater than it has been visited.
   */
  skippedStageMap: {[stageName: string]: boolean} = {};

  /**
   * Boolean value that indicates the user has skipped a stage and has not
   * acknowledged that fact.
   */
  hasUnacknowledgedSkippedStage = false;

  /**
   * Boolean value that indicates the user has entered a new stage
   */
  hasUnacknowledgedCongratsNotification = false;

  /* Group detected by the latest analyze response call */
  activeGroupName?: string|null;

  /* Stage detected by the latest analyze response call */
  activeStageName?: string|null;

  /* Group that is displayed in the UI */
  selectedGroupName?: string|null;
  previousSelectedGroupName?: string|null;

  protected skippedStageCount = 0;

  private readonly sessionStorage = new HTML5SessionStorage();

  // tslint:disable-next-line:no-any
  private congratsNotificationTimeout: any;

  private innerConversationName?: string|null;
  private innerConversationModel?: ConversationModel_|null;
  private innerLoadingState?: LoadingState|null = LoadingState.NOT_LOADING;

  protected get transitionDirection() {
    if (!this.previousSelectedGroupName || !this.selectedGroupName) {
      return 'none';
    }
    const selectedIndex = this.groupMap[this.selectedGroupName]?.index || 0;
    const previousIndex =
        this.groupMap[this.previousSelectedGroupName]?.index || 0;
    return selectedIndex > previousIndex ? 'right' : 'left';
  }

  protected get selectedGroup() {
    if (this.selectedGroupName) {
      return this.groupMap[this.selectedGroupName]?.group;
    }
    return null;
  }

  private get conversationName() {
    return this.innerConversationName;
  }

  private get conversationModel() {
    return this.innerConversationModel;
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  /** Selects a random guidance for a given stage. */
  selectRandomGuidance(stage: ConversationGuidanceModelMetadataStage_) {
    if (!stage.stageGuidances) {
      return '';
    }
    const chosenIndex = Math.floor(stage.stageGuidances.length * Math.random());
    return stage.stageGuidances[chosenIndex];
  }

  /**
   * Gets the visited status of groups and stages from session storage, if
   * it is available.
   */
  getSavedVisitedStatus() {
    if (!this.conversationName) return;
    try {
      const storedString = this.sessionStorage.get(
          SESSION_STORAGE_GUIDANCE_PREFIX + this.conversationName);
      const parsedValue = storedString ? JSON.parse(storedString) : undefined;
      if (typeof parsedValue === 'object') {
        return parsedValue as SessionStorageVisitedStatus;
      }
    } catch {
      // Don't interrupt workflow if fetching from storage fails
    }
    return;
  }

  protected acknowledgeSkippedStep() {
    this.hasUnacknowledgedSkippedStage = false;
  }

  protected acknowledgeCongratsNotification() {
    this.clearCongratsNotificationAndTimeout();
  }

  protected setSelectedGroupName(groupName: string|null|undefined) {
    if (groupName) {
      this.previousSelectedGroupName = this.selectedGroupName;
      this.selectedGroupName = groupName;
    }
  }

  private resetConversationModelState() {
    if (!this.conversationModel) return;
    this.groups =
        this.conversationModel.conversationGuidanceModelMetadata?.stageGroups ||
        [];
    // construct the stage and group maps
    this.groups.forEach((group, index) => {
      const groupName = group.displayName || '';
      this.groupMap[groupName] = {group, index};
      group?.stages?.forEach((stage) => {
        const stageName = stage.displayName || '';
        this.stageMap[stageName] = {
          groupName,
          stage,
          selectedGuidance: this.selectRandomGuidance(stage)
        };
      });
    });
    this.previousSelectedGroupName = undefined;
    this.selectedGroupName = this.groups[0]?.displayName;
  }

  private resetConversationState() {
    const {
      visitedGroupMap: storedVisitedGroupMap = {},
      visitedStageMap: storedVisitedStageMap = {}
    } = this.getSavedVisitedStatus() || {};
    this.visitedStageMap = storedVisitedStageMap;
    this.visitedGroupMap = storedVisitedGroupMap;
    this.skippedStageMap = {};
    this.skippedGroupMap = {};
    this.hasUnacknowledgedSkippedStage = false;
    this.activeGroupName = undefined;
    this.activeStageName = undefined;
    this.resetConversationModelState();
  }

  private triggerCongratsNotification() {
    // clear any previous notification timers
    this.clearCongratsNotificationAndTimeout();
    this.hasUnacknowledgedCongratsNotification = true;
    this.congratsNotificationTimeout = setTimeout(() => {
      this.clearCongratsNotificationAndTimeout();
    }, CONGRATS_NOTIFICATION_DURATION_MILLIS);
  }

  private clearCongratsNotificationAndTimeout() {
    if (this.congratsNotificationTimeout) {
      clearTimeout(this.congratsNotificationTimeout);
      this.congratsNotificationTimeout = undefined;
      this.hasUnacknowledgedCongratsNotification = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  /** Updates the active group and stage when a new stage is detected. */
  private updateActiveGroupAndStage(detectedStage: string|null|undefined) {
    if (!detectedStage) return;
    const {groupName} = this.stageMap[detectedStage] || {};
    if (groupName) {
      this.activeGroupName = groupName;
      this.activeStageName = detectedStage;
    }
  }

  /**
   * Updates the visited and skipped statuses of stages and groups when a new
   * stage is detected.
   */
  private updateVisitedAndSkippedStatus(detectedStage: string|null|undefined) {
    if (!detectedStage) return;

    // If this is the user's first time reaching this stage, show a congrats
    // notification.
    if (!this.stageHasBeenVisited(detectedStage)) {
      this.triggerCongratsNotification();
    }

    // Mark stage as visited.
    this.markStageAsVisited(detectedStage);

    // Update the visited status of the top-level stage group.
    this.updateVisitedGroups(detectedStage);

    // Update skipped groups.
    this.updateSkippedGroups();

    // Update skipped stages.
    this.updateSkippedStages(detectedStage);

    // Save visited dict to session storage.
    this.saveVisitedStatus();
  }

  /**
   * Persists the visited status of stages and groups in session storage, so
   * they are not lost on page refresh.
   */
  private saveVisitedStatus() {
    if (!this.conversationName) return;
    const dataToStringify: SessionStorageVisitedStatus = {
      visitedGroupMap: this.visitedGroupMap,
      visitedStageMap: this.visitedStageMap,
    };
    const stringifiedData = JSON.stringify(dataToStringify);
    try {
      this.sessionStorage.set(
          SESSION_STORAGE_GUIDANCE_PREFIX + this.conversationName,
          stringifiedData);
    } catch (e) {
      // Don't interrupt workflow if saving fails
    }
  }

  /** Updates the list of skipped groups once a new stage is detected. */
  private updateSkippedGroups() {
    // Update skipped status by looking through visited groups in reverse.
    // The first group we see that is visited will mean every group after it
    // will be considered skipped if they haven't been visited
    let encounteredVisitedGroup = false;
    for (let i = this.groups.length - 1; i >= 0; i--) {
      const groupName = this.groups[i]?.displayName;
      if (!groupName) {
        continue;
      }

      const groupHasBeenVisited = !!this.visitedGroupMap[groupName];

      if (groupHasBeenVisited) {
        this.skippedGroupMap[groupName] = false;
        encounteredVisitedGroup = true;
      }
      // We have not visited this group yet, but we have encountered a visited
      // group after this one. Consider this group as skipped.
      else if (encounteredVisitedGroup) {
        if (this.skippedGroupMap[groupName] === undefined) {
          // this group is newly skipped
          this.hasUnacknowledgedSkippedStage = true;
        }
        this.skippedGroupMap[groupName] = true;
      }
    }
  }

  private updateSkippedStages(currentStage: string) {
    // Reset skipped stage map, as we only care about skipped stages before
    // the current stage.
    this.skippedStageMap = {};
    const stages = Object.keys(this.stageMap);
    const currentStageIndex = stages.indexOf(currentStage);
    let encounteredVisitedStage = false;
    for (let i = currentStageIndex; i >= 0; i--) {
      const stageName = stages[i];
      const stageHasBeenVisited = !!this.visitedStageMap[stageName];
      if (stageHasBeenVisited) {
        this.skippedStageMap[stageName] = false;
        encounteredVisitedStage = true;
      } else if (encounteredVisitedStage) {
        if (this.skippedStageMap[stageName] === undefined) {
          // this stage is newly skipped
          this.hasUnacknowledgedSkippedStage = true;
        }
        this.skippedStageMap[stageName] = true;
      }
    }
    const updatedSkippedStageCount =
        Object.values(this.skippedStageMap).filter(Boolean).length;
    this.skippedStageCount = updatedSkippedStageCount;
    if (!updatedSkippedStageCount) {
      this.hasUnacknowledgedSkippedStage = false;
    }
  }

  /** Marks the given stage as visited. */
  private markStageAsVisited(stageName: string|null|undefined) {
    if (stageName) {
      this.visitedStageMap[stageName] = true;
    }
  }

  /**
   * Updates the list of visited groups when a new stage is visited. A group is
   * considered visited if every stage within the group has been visited.
   */
  private updateVisitedGroups(stageName: string|null|undefined) {
    const groupName = this.getStageGroupName(stageName);
    const group = this.getStageGroup(stageName);
    if (!groupName || !group) return;

    const groupVisited = (group.stages || [])
                             .every(
                                 stage => stage.displayName &&
                                     this.visitedStageMap[stage.displayName]);

    this.visitedGroupMap[groupName] = groupVisited;
  }

  /** Returns whether a stage has been visited before. */
  private stageHasBeenVisited(stageName: string|null|undefined) {
    return Boolean(this.visitedStageMap[stageName!]);
  }

  /** Gets the name of the top-level group for a given stage. */
  private getStageGroupName(stageName: string|null|undefined) {
    return this.stageMap[stageName!]?.groupName;
  }

  /** Gets the top-level group for a given stage. */
  private getStageGroup(stageName: string|null|undefined) {
    const groupName = this.getStageGroupName(stageName);
    return this.groupMap[groupName!]?.group;
  }

  /** Gets the detected stage name from an AnalyzeContent response. */
  private getDetectedStage(analyzeContentResponse: AnalyzeContentResponse_) {
    const suggestionResult =
        analyzeContentResponse?.humanAgentSuggestionResults?.find(
            suggestionResult =>
                suggestionResult?.suggestConversationGuidancesResponse);

    return suggestionResult?.suggestConversationGuidancesResponse
        ?.conversationGuidanceAnswers?.[0]
        ?.stage;
  }
}
