import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {XapDialogLayout, XapDialogLayoutContent, XapDialogLayoutSection, XapDialogLayoutSubtitle} from './dialog_layout';

const DIRECTIVES = [
  XapDialogLayout,
  XapDialogLayoutContent,
  XapDialogLayoutSubtitle,
  XapDialogLayoutSection,
];

@NgModule({
  imports: [CommonModule],
  declarations: DIRECTIVES,
  exports: DIRECTIVES,
})
export class XapDialogLayoutModule {
}
