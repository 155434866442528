<section
  #body
  [scrollOnEmit]="elementTemplates$"
  class="dialogflow-assist__body"
>
  <ng-container
    *ngLet="{
      activeWorkflowHeadIntent: (activeWorkflowHeadIntent$ | async),
      activeHeadIntentsOriginalAnswerRecordName: (activeHeadIntentsOriginalAnswerRecordName$ | async),
      elementTemplates: (elementTemplates$ | async),
      loadingState: (loadingState$ | async),
      nominatedHeadIntent: (nominatedHeadIntent$ | async),
      humanAgentParticipantName: (humanAgentParticipantName$ | async),
      conversationCompleted: (conversationCompleted$ | async)
    } as data"
  >
    <ng-container
      *ngIf="!data.nominatedHeadIntent && !data.activeWorkflowHeadIntent; else workflow"
    >
      <ng-container *ngIf="data.loadingState === 'LOADED'; else spinner;">
        <head-intents
          (onChooseHeadIntent)="handleChooseHeadIntent($event, data.humanAgentParticipantName)"
          [headIntents]="headIntents$"
          [disabled]="data.conversationCompleted"
          [debugMode]="debugMode"
        ></head-intents>
      </ng-container>
    </ng-container>

    <ng-template #workflow>
      <ng-container
        *ngTemplateOutlet="activeHeadIntent; context: {$implicit: {
        activeWorkflowHeadIntent: data.activeWorkflowHeadIntent,
        nominatedHeadIntent: data.nominatedHeadIntent,
        activeHeadIntentsOriginalAnswerRecordName: data.activeHeadIntentsOriginalAnswerRecordName,
        humanAgentParticipantName: data.humanAgentParticipantName,
        conversationCompleted: data.conversationCompleted
      }}"
      ></ng-container>

      <ng-container *ngIf="data.humanAgentParticipantName">
        <workflow-entry
          *ngFor="let elementTemplateSet of data.elementTemplates; trackBy: trackBy;"
          (onSubmitDialogflowAssistWorkflow)="handleSubmitDialogflowAssistWorkflow($event)"
          (onConfirmNominatedHeadIntent)="handleConfirmNominatedHeadIntent($event)"
          [debugMode]="debugMode"
          [elementTemplateSet]="elementTemplateSet"
          [humanAgentParticipantName]="data.humanAgentParticipantName"
          [endUserParticipantLoadStatus]="endUserParticipantLoadStatus"
        ></workflow-entry>
      </ng-container>
    </ng-template>
  </ng-container>
</section>

<ng-template #spinner>
  <mat-spinner
    aria-label="Head intents loading"
    i18n-aria-label="Spinner to indicate that head intents are loading"
  ></mat-spinner>
</ng-template>

<ng-template #activeHeadIntent let-data>
  <div class="dialogflow-assist__active-head-intent">
    <h3 class="gmat-headline-6">
      {{handleGetHeadIntentDisplayName(data.activeWorkflowHeadIntent ||
      data.nominatedHeadIntent.dialogflowAssistAnswer)}}
    </h3>

    <button
      (click)="$event.stopPropagation(); handleCancelActiveHeadIntent(data.activeHeadIntentsOriginalAnswerRecordName, data.humanAgentParticipantName);"
      [disabled]="data.conversationCompleted"
      aria-label="Cancel current workflow"
      i18n-aria-label="Button that cancels the current workflow"
      matTooltip="Cancel current workflow"
      i18n-matTooltip="Tooltip for a button that cancels the current workflow"
      data-test-id="close-button"
      mat-icon-button
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
</ng-template>
