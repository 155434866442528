import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {HeadIntentsModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/head_intents/head_intents_module';
import {WorkflowEntryModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/workflow_entry/workflow_entry_module';
import {LetDirectiveModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/let/let_directive_module';
import {ScrollOnEmitModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/scroll_on_emit/scroll_on_emit_module';

import {DialogflowAssist} from './dialogflow_assist';

@NgModule({
  imports: [
    CommonModule,
    HeadIntentsModule,
    LetDirectiveModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ScrollOnEmitModule,
    WorkflowEntryModule,
  ],
  declarations: [DialogflowAssist],
  exports: [DialogflowAssist]
})
export class DialogflowAssistModule {
}
