<ng-container
  *ngIf="{
      summary: summary$ | async,
      conversationDetails: conversationDetails$ | async,
      loadingState: loadingState$ | async,
      activeConversationName: activeConversationName$ | async
    } as data"
>
  <ng-container *ngIf="showGenerateSummaryButton === 'true'">
    <button
      *ngIf="data.loadingState !== 'LOADING'"
      (click)="handleGenerateSummary()"
      class="generate-summary gmat-button"
      color="primary"
      mat-button
    >
      <ng-container
        *ngIf="data.loadingState === 'LOADED'; else generateSummary"
      >
        <mat-icon svgIcon="restart_alt"></mat-icon>
        <ng-container i18n="Button that generates a new conversation summary"
          >New summary</ng-container
        >
      </ng-container>
      <ng-template #generateSummary>
        <ng-container i18n="Button that generates a conversation summary"
          >Generate summary</ng-container
        >
      </ng-template>
    </button>
  </ng-container>

  <conversation-summarization
    (onSave)="handleSaveSummary($event, data.conversationDetails, data.activeConversationName)"
    (onEdit)="handleEditSummary($event)"
    [conversation]="conversation$ | async"
    [conversationSummary]="data.summary"
    [conversationSummaryText]="data.summary?.summary?.text"
    [hasSaveHandler]="!!onSaveSummary"
    [loadingState]="data.loadingState"
    [saveLoadingState]="saveLoadingState$ | async"
    [saveErrorMessage]="saveErrorMessage$ | async"
    [saveSuccessMessage]="saveSuccessMessage$ | async"
  ></conversation-summarization>
</ng-container>
