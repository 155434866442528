import {LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';

import {getQueryResultV3FromDialogflowAssistAnswer, isConversationCompleted, isHeadIntent, isSupplementalIntent} from './dialogflow_assist_shared_helpers';
import {generateDialogflowAssistElementTemplateCreatorWithAnswerRecordName, getCurrentPageDisplayNameFromDialogflowAssistAnswer, getDialogflowAssistAnswerContext, getEntryFulfillmentResponseMessagesFromDialogflowAssistAnswer, getFormParametersFromDialogflowAssistAnswer, getHandlerPromptResponseMessagesFromDialogflowAssistAnswer, getParameterPromptResponseMessagesFromFormParameter, getParameterSuggestionsForParameterName, getTextAndCustomResponseMessages, hasIntentSuggestionBeenUsed, hasResponseMessageBeenUsed, haveResponseMessagesBeenUsed, isDialogflowAssistAnswerEndPage, isDialogflowAssistAnswerOlderThanContext} from './dialogflow_assist_template_generator_helpers';
import {DialogflowAssistElementTemplate, DialogflowAssistState, IntentSuggestionElementTemplate} from './dialogflow_assist_types';

/**
 * Generates array of Dialogflow Assist element templates used to construct a
 * Dialogflow Assist form.
 */
export const generateDialogflowAssistElementTemplate = ({
  activeWorkflow,
  conversation,
  loadingState,
  nominatedHeadIntent,
  parameterSuggestions,
  parameters,
  showUseSuggestionButton,
  usedIntentSuggestions,
  usedResponseMessages,
}: DialogflowAssistState): DialogflowAssistElementTemplate[][] => {
  const dialogflowAssistAnswerContext =
      getDialogflowAssistAnswerContext(activeWorkflow);
  const dialogflowAssistElementTemplate: DialogflowAssistElementTemplate[][] =
      [];
  const supplementalElementTemplateList: DialogflowAssistElementTemplate[][] =
      [];
  let dialogflowAssistElementTemplateSubList:
      DialogflowAssistElementTemplate[] = [];

  const isAnswerEndPage =
      isDialogflowAssistAnswerEndPage(dialogflowAssistAnswerContext!);
  const conversationCompleted = isConversationCompleted(conversation);

  /** Special handling for head intent element in flow. */
  if (nominatedHeadIntent) {
    const {
      dialogflowAssistAnswer: {
        answerRecord,
        intentSuggestion,
      },
      formParameters,
      parameters,
    } = nominatedHeadIntent;
    const dialogflowAssistElementTemplateGenerator =
        generateDialogflowAssistElementTemplateCreatorWithAnswerRecordName(
            answerRecord!);
    const disabled = !!activeWorkflow.length || conversationCompleted;

    if (formParameters) {
      for (const formParameter of formParameters) {
        dialogflowAssistElementTemplateSubList.push(
            dialogflowAssistElementTemplateGenerator({
              elementType: 'FORM_PARAMETER_LABEL',
              formParameterLabel: formParameter.displayName!,
            }));

        dialogflowAssistElementTemplateSubList.push(
            dialogflowAssistElementTemplateGenerator({
              disabled,
              elementType: 'FORM_PARAMETER',
              formParameter,
              parameterSuggestions: getParameterSuggestionsForParameterName(
                  parameterSuggestions, formParameter.displayName!),
            }));
      }

      /** If the head intent is the only answer in the workflow. */
      if (!disabled) {
        dialogflowAssistElementTemplate.push([
          ...dialogflowAssistElementTemplateSubList,
          {
            answerRecordName: answerRecord!,
            elementType: 'CONFIRM_HEAD_INTENT_BUTTON',
            intent: intentSuggestion,
            ...(parameters ? {parameters} : {}),
          },
        ]);

        return loadingState === LoadingState.LOADING ?
            [
              ...dialogflowAssistElementTemplate,
              [{
                answerRecordName: '',
                elementType: 'LOADING_SPINNER',
              }],
            ] :
            dialogflowAssistElementTemplate;
      }
      dialogflowAssistElementTemplate.push(
          dialogflowAssistElementTemplateSubList);
    }
  }

  for (const dialogflowAssistAnswer of activeWorkflow) {
    dialogflowAssistElementTemplateSubList = [];

    const {
      answerRecord,
      intentSuggestion,
    } = dialogflowAssistAnswer!;
    const queryResultV3 =
        getQueryResultV3FromDialogflowAssistAnswer(dialogflowAssistAnswer);

    const intent = queryResultV3?.intent;
    const usedResponseMessagesForAnswerRecord =
        usedResponseMessages[answerRecord!];
    const usedIntentSuggestionsForAnswerRecord =
        usedIntentSuggestions[answerRecord!];
    const dialogflowAssistElementTemplateGenerator =
        generateDialogflowAssistElementTemplateCreatorWithAnswerRecordName(
            answerRecord!);

    const intentSuggestionElementTemplateGenerator =
        generateDialogflowAssistElementTemplateCreatorWithAnswerRecordName<
            IntentSuggestionElementTemplate>(answerRecord!);

    if (isSupplementalIntent(intent)) {
      const responseMessages =
          getTextAndCustomResponseMessages(queryResultV3?.responseMessages);
      const hidden = conversationCompleted ||
          haveResponseMessagesBeenUsed(
                         responseMessages, usedResponseMessagesForAnswerRecord);

      if (!hidden) {
        dialogflowAssistElementTemplateSubList.push(
            dialogflowAssistElementTemplateGenerator({
              elementType: 'SUPPLEMENTAL_QUESTION',
              intent: intent!,
            }));

        if (responseMessages) {
          for (const responseMessage of responseMessages) {
            dialogflowAssistElementTemplateSubList.push(
                dialogflowAssistElementTemplateGenerator({
                  elementType: 'SUPPLEMENTAL_RESPONSE',
                  disabled: conversationCompleted,
                  responseMessage,
                  showUseSuggestionButton,
                }));
          }
        }

        dialogflowAssistElementTemplateSubList.push(
            dialogflowAssistElementTemplateGenerator({
              elementType: 'DISMISS_SUPPLEMENTAL',
              disabled: conversationCompleted,
              responseMessages,
            }));

        supplementalElementTemplateList.push(
            dialogflowAssistElementTemplateSubList);
      }

      continue;
    }

    const entryFulfillmentResponseMessages =
        getEntryFulfillmentResponseMessagesFromDialogflowAssistAnswer(
            dialogflowAssistAnswer!);
    const handlerPromptResponseMessages =
        getHandlerPromptResponseMessagesFromDialogflowAssistAnswer(
            dialogflowAssistAnswer!);
    const formParameters =
        getFormParametersFromDialogflowAssistAnswer(dialogflowAssistAnswer!);
    const dialogflowAssistAnswerNoLongerInContext =
        isDialogflowAssistAnswerOlderThanContext(
            dialogflowAssistAnswer!, dialogflowAssistAnswerContext!,
            activeWorkflow);
    const currentPageDisplayName =
        getCurrentPageDisplayNameFromDialogflowAssistAnswer(
            dialogflowAssistAnswer!);
    if (currentPageDisplayName) {
      dialogflowAssistElementTemplateSubList.push(
          dialogflowAssistElementTemplateGenerator({
            elementType: 'TITLE',
            title: currentPageDisplayName,
          }));
    }

    if (handlerPromptResponseMessages) {
      for (const handlerPromptResponseMessage of
               handlerPromptResponseMessages) {
        const handlerPromptResponseMessageHasBeenUsed =
            hasResponseMessageBeenUsed(
                handlerPromptResponseMessage,
                usedResponseMessagesForAnswerRecord);

        dialogflowAssistElementTemplateSubList.push(
            dialogflowAssistElementTemplateGenerator({
              disabled: conversationCompleted ||
                  dialogflowAssistAnswerNoLongerInContext ||
                  handlerPromptResponseMessageHasBeenUsed,
              elementType: 'HANDLER_PROMPT',
              responseMessage: handlerPromptResponseMessage,
              showUseSuggestionButton,
            }));
      }
    }
    if (entryFulfillmentResponseMessages) {
      for (const entryFulfillmentResponseMessage of
               entryFulfillmentResponseMessages) {
        const entryFulfillmentResponseMessageHasBeenUsed =
            hasResponseMessageBeenUsed(
                entryFulfillmentResponseMessage,
                usedResponseMessagesForAnswerRecord);

        dialogflowAssistElementTemplateSubList.push(
            dialogflowAssistElementTemplateGenerator({
              disabled: conversationCompleted ||
                  dialogflowAssistAnswerNoLongerInContext ||
                  entryFulfillmentResponseMessageHasBeenUsed,
              elementType: 'ENTRY_PROMPT',
              responseMessage: entryFulfillmentResponseMessage,
              showUseSuggestionButton,
            }));
      }
    }

    if (formParameters) {
      for (const formParameter of formParameters) {
        const parameterPromptResponseMessages =
            getParameterPromptResponseMessagesFromFormParameter(
                dialogflowAssistAnswer, formParameter.displayName);

        if (parameterPromptResponseMessages) {
          for (const parameterPromptResponseMessage of
                   parameterPromptResponseMessages) {
            const parameterPromptResponseMessageHasBeenUsed =
                hasResponseMessageBeenUsed(
                    parameterPromptResponseMessage,
                    usedResponseMessagesForAnswerRecord);

            dialogflowAssistElementTemplateSubList.push(
                dialogflowAssistElementTemplateGenerator({
                  disabled: conversationCompleted ||
                      dialogflowAssistAnswerNoLongerInContext ||
                      parameterPromptResponseMessageHasBeenUsed,
                  elementType: 'FORM_PARAMETER_PROMPT',
                  responseMessage: parameterPromptResponseMessage,
                  showUseSuggestionButton,
                }));
          }
        }

        dialogflowAssistElementTemplateSubList.push(
            dialogflowAssistElementTemplateGenerator({
              disabled: conversationCompleted ||
                  dialogflowAssistAnswerNoLongerInContext,
              elementType: 'FORM_PARAMETER',
              formParameter,
              parameters,
              parameterSuggestions: getParameterSuggestionsForParameterName(
                  parameterSuggestions, formParameter.displayName!),
            }));
      }
    }

    if (intentSuggestion && !isHeadIntent(intentSuggestion)) {
      const intentSuggestionHasBeenUsed = hasIntentSuggestionBeenUsed(
          intentSuggestion, usedIntentSuggestionsForAnswerRecord);
      const previousDialogflowAssistElementTemplateSubList =
          dialogflowAssistElementTemplate[dialogflowAssistElementTemplate.length - 1] ??
          dialogflowAssistElementTemplateSubList;
      const lastElementOfPreviousDialogflowAssistElementTemplateSubList = previousDialogflowAssistElementTemplateSubList[previousDialogflowAssistElementTemplateSubList.length - 1];

      const intentSuggestionElementTemplate =
          intentSuggestionElementTemplateGenerator({
            disabled: conversationCompleted ||
                dialogflowAssistAnswerNoLongerInContext ||
                intentSuggestionHasBeenUsed,
            elementType: 'INTENT_SUGGESTION',
            intent: intentSuggestion,
          });

      // Insertion index of intent suggestion should be
      // before button if button element type is in sublist.
      const intentSuggestionSubListIndex =
          previousDialogflowAssistElementTemplateSubList.length -
          (lastElementOfPreviousDialogflowAssistElementTemplateSubList
                   ?.elementType.endsWith('_BUTTON') ?
               2 :
               1);

      // If sublist already has intent suggestions, push new intent
      // suggestion into intent suggestions element template.
      // Otherwise, initialize new intent suggestions element template.
      if (previousDialogflowAssistElementTemplateSubList
              [intentSuggestionSubListIndex]
                  ?.elementType === 'INTENT_SUGGESTIONS') {
        previousDialogflowAssistElementTemplateSubList[intentSuggestionSubListIndex]
            .intents!.push(intentSuggestionElementTemplate);
      } else {
        const intentSuggestionsElementTemplate =
            dialogflowAssistElementTemplateGenerator({
              elementType: 'INTENT_SUGGESTIONS',
              intents: [intentSuggestionElementTemplate],
            });
        previousDialogflowAssistElementTemplateSubList.splice(
            intentSuggestionSubListIndex + 1, 0,
            intentSuggestionsElementTemplate);
      }
    }

    if (!dialogflowAssistAnswerNoLongerInContext) {
      if (formParameters && !isAnswerEndPage) {
        const answerRecordName = dialogflowAssistAnswerContext?.answerRecord!;

        dialogflowAssistElementTemplateSubList.push({
          answerRecordName,
          disabled: conversationCompleted,
          elementType: 'NEXT_BUTTON',
        });
      }
    }

    if (dialogflowAssistElementTemplateSubList.length) {
      dialogflowAssistElementTemplate.push(
          dialogflowAssistElementTemplateSubList);
    }
  }

  if (loadingState === LoadingState.LOADING) {
    return [
      ...dialogflowAssistElementTemplate,
      ...supplementalElementTemplateList,
      [{
        answerRecordName: '',
        elementType: 'LOADING_SPINNER',
      }],
    ];
  }

  if (isAnswerEndPage) {
    return [
      ...dialogflowAssistElementTemplate,
      ...supplementalElementTemplateList,
      [{
        answerRecordName: '',
        disabled: conversationCompleted,
        elementType: 'SUBMIT_BUTTON',
      }],
    ];
  }

  return [
    ...dialogflowAssistElementTemplate,
    ...supplementalElementTemplateList,
  ];
};
