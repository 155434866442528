import {animate, style, transition, trigger} from '@angular/animations';
import {DOCUMENT} from '@angular/common';
import {Component, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {NonNullableFormBuilder} from '@angular/forms';
import {SuggestionFeatureStore} from 'google3/java/com/google/dialogflow/console/web/ccai/services/suggestion_feature_store/suggestion_feature_store';
import {SuggestionFeatureService} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/common/suggestion_feature_service/suggestion_feature_service';
import {ArticleSearch} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/article_search/article_search';
import {ScrollOnEmit} from 'google3/java/com/google/dialogflow/console/web/common/directives/scroll_on_emit/scroll_on_emit_directive';
import {getElapsedTime} from 'google3/java/com/google/dialogflow/console/web/common/helpers/date_helpers';
import {AnalyzeContentResponse_, AnswerFeedback_CorrectnessLevel, AnswerRecord_, ArticleAnswer_, Conversation_, SearchArticleAnswer_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';
import {Dictionary} from 'google3/java/com/google/dialogflow/console/web/common/types/common_types';
import {BehaviorSubject, combineLatest, ReplaySubject} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {trySanitizeUrl} from 'safevalues';
import {safeWindow} from 'safevalues/dom';

import {DEFAULT_ARTICLE_LINK_CONFIG} from './document_and_faq_constants';
import {getArticleAnswerLink, hasArticleSearchEnabled, hasArticleSuggestionEnabled, hasFaqEnabled, hasKnowledgeAssistSuggestionEnabled, isArticleAnswer} from './document_and_faq_helpers';
import {AnswerRecordUpdate, ArticleOrFaq, ArticleOrFaqAnswer, ArticleOrFaqAnswers, ArticleOrFaqAnswerWithCreateTime, ArticleSelection, ChosenDocumentOrFaqSuggestion, DocumentOrFaqFeedback, KnowledgeAssistFeature, SelectArticleEvent, SelectedDocumentOrFaqSuggestion} from './document_and_faq_types';


interface State {
  previousSuggestions: ArticleOrFaqAnswers|null;
}

const initialState: State = {
  previousSuggestions: null,
};

/** Document and FAQ suggestion component. */
@Component({
  selector: 'document-and-faq',
  templateUrl: './document_and_faq.ng.html',
  styleUrls: ['./document_and_faq.css'],
  providers: [
    SuggestionFeatureService,
    SuggestionFeatureStore,
  ],
  animations: [
    trigger(
        'fadeIn',
        [
          transition(
              'void => *',
              [
                style({opacity: 0.25}),
                animate(
                    '125ms ease-in',
                    style({opacity: 1}),
                    )
              ]),
        ]),
  ]
})
export class DocumentAndFaq implements OnDestroy {
  readonly suggestions$ = new ReplaySubject<ArticleOrFaqAnswers>(1);
  private innerConversation: Conversation_|null = null;

  @Input()
  get conversation() {
    return this.innerConversation;
  }
  set conversation(conversation: Conversation_|null) {
    if (this.innerConversation) {
      this.resetState();
    }
    this.innerConversation = conversation;
  }

  @Input()
  set suggestions(suggestions: ArticleOrFaqAnswers) {
    const {payload: answers} = suggestions;
    this.suggestions$.next(suggestions);
    this.updateDisplayTimes(answers);
  }

  @Input() answerRecords: Dictionary<AnswerRecord_> = {};
  @Input() chosenSuggestion?: ChosenDocumentOrFaqSuggestion;
  @Input() showConfidence = false;

  /** Knowledge Assist features to render. */
  @Input() features: KnowledgeAssistFeature[] = ['ARTICLE_SUGGESTION', 'FAQ'];

  @Input() searchAnswers: SearchArticleAnswer_[] = [];
  @Input() searchLoadingState = LoadingState.NOT_LOADING;

  @Input() articleLinkConfig = DEFAULT_ARTICLE_LINK_CONFIG;

  @Output()
  readonly onChooseSuggestion =
      new EventEmitter<SelectedDocumentOrFaqSuggestion>();
  @Output() readonly onSelectArticle = new EventEmitter<ArticleSelection>();
  @Output()
  readonly onProvideFeedback = new EventEmitter<DocumentOrFaqFeedback>();
  @Output() readonly onSearchArticles = new EventEmitter<string>();

  @ViewChild(ArticleSearch) readonly articleSearch?: ArticleSearch;
  @ViewChild(ScrollOnEmit) suggestionsContainer?: ScrollOnEmit;

  readonly filterString = this.fb.control('');
  readonly searchActive$ = new BehaviorSubject(false);

  protected readonly additionalSuggestionsArrivedDuringSearch$ =
      new BehaviorSubject(false);

  readonly getArticleAnswerLink = getArticleAnswerLink;
  readonly hasArticleSearchEnabled = hasArticleSearchEnabled;
  readonly hasArticleSuggestionEnabled = hasArticleSuggestionEnabled;
  readonly hasFaqEnabled = hasFaqEnabled;
  readonly hasKnowledgeAssistSuggestionEnabled =
      hasKnowledgeAssistSuggestionEnabled;

  private readonly currentAndPreviousSuggestions$ =
      this.suggestions$.pipe(switchMap(suggestions => {
        return this.suggestionFeatureService.store
            .selectForConversation(state => state.previousSuggestions)(
                suggestions.conversationName)
            .pipe(map(
                previousSuggestions => ({suggestions, previousSuggestions})));
      }));

  private readonly destroyed$ = new ReplaySubject<void>(1);
  private readonly newSuggestionsNotVisible$ = new BehaviorSubject(false);
  private readonly newSuggestionsChipHidden$ = new BehaviorSubject(false);
  private readonly window: Window;

  readonly showNewSuggestionsChip$ =
      combineLatest([
        this.newSuggestionsNotVisible$,
        this.newSuggestionsChipHidden$,
      ])
          .pipe(map(
              ([additionalSuggestionsArrived, newSuggestionsChipHidden]) => {
                return additionalSuggestionsArrived &&
                    !newSuggestionsChipHidden &&
                    !this.suggestionsContainer?.isAutoScrolling;
              }));

  constructor(
      @Inject(DOCUMENT) document: Document,
      private readonly suggestionFeatureService:
          SuggestionFeatureService<State>,
      private readonly fb: NonNullableFormBuilder,
  ) {
    this.window = document.defaultView!;
    suggestionFeatureService.store.init(initialState);

    combineLatest([this.searchActive$, this.currentAndPreviousSuggestions$])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(([
                     searchActive,
                     {suggestions: {payload: answers}, previousSuggestions}
                   ]) => {
          const previousAnswers = previousSuggestions?.payload || [];

          if (!searchActive) {
            this.additionalSuggestionsArrivedDuringSearch$.next(false);
          } else if (answers.length > previousAnswers.length) {
            this.additionalSuggestionsArrivedDuringSearch$.next(true);
          }
        });

    this.currentAndPreviousSuggestions$.pipe(takeUntil(this.destroyed$))
        .subscribe(({suggestions, previousSuggestions}) => {
          const {conversationName, payload: answers} = suggestions;
          const previousAnswers = previousSuggestions?.payload || [];

          if (previousAnswers.length > 0 &&
              answers.length > previousAnswers.length &&
              !this.suggestionsContainer?.isScrolledToBottom) {
            this.newSuggestionsNotVisible$.next(true);
            this.newSuggestionsChipHidden$.next(false);
          }
          this.suggestionFeatureService.store.setStateForConversation(
              conversationName,
              state => ({...state, previousSuggestions: suggestions}));
        });
  }

  get searchLoading() {
    return this.searchLoadingState === LoadingState.LOADING;
  }

  handleGetConfidenceScoreTooltipText(confidenceScore: string) {
    /** @desc Tooltip to show the confidence score for a suggestion. */
    const MSG_CONFIDENCE_SCORE = goog.getMsg(
        'Suggestion confidence: {$confidenceScore}',
        {'confidenceScore': confidenceScore});

    return MSG_CONFIDENCE_SCORE;
  }

  handleSearchArticles(event: string) {
    this.onSearchArticles.next(event);
    this.searchActive$.next(true);
  }

  handleChooseSuggestion(
      analyzeContentResponse: AnalyzeContentResponse_,
      answer: ArticleOrFaqAnswer) {
    this.onChooseSuggestion.emit({analyzeContentResponse, answer});
  }

  handleSelectArticleSuggestion(event: Event, answer: ArticleAnswer_) {
    this.handleSelectArticle({
      event,
      selection: {
        clickTime: new Date().toISOString(),
        displayTime: this.suggestionFeatureService.getSuggestionDisplayTime(
            answer.answerRecord!),
        answer,
      }
    });
  }

  handleSelectArticle({event, selection: {answer, clickTime, displayTime}}:
                          SelectArticleEvent) {
    if (this.articleLinkConfig.target === 'popup') {
      event.preventDefault();
      safeWindow.open(
          this.window,
          trySanitizeUrl(this.getArticleAnswerLink(
              answer, this.articleLinkConfig.linkMetadataKey))!,
          this.articleLinkConfig.target,
          this.articleLinkConfig.popupWindowOptions,
      );
    }

    this.onSelectArticle.emit({answer, clickTime, displayTime});
  }

  handleGetSuggestionType(articleOrFaqAnswer: ArticleOrFaqAnswer):
      ArticleOrFaq {
    return isArticleAnswer(articleOrFaqAnswer) ? 'ARTICLE_SUGGESTION' : 'FAQ';
  }

  handleGetSuggestionIconName(articleOrFaqAnswer: ArticleOrFaqAnswer) {
    return isArticleAnswer(articleOrFaqAnswer) ? 'picture_as_pdf' :
                                                 'question_answer';
  }

  handleGetPrimaryConversationInitTimeOffset(createTime?: string|null) {
    return getElapsedTime(this.conversation?.startTime, createTime);
  }

  handleHasPositiveFeedback(answerRecordName: string) {
    return this.hasCorrectnessLevel(answerRecordName, 'FULLY_CORRECT');
  }

  handleHasNegativeFeedback(answerRecordName: string) {
    return this.hasCorrectnessLevel(answerRecordName, 'NOT_CORRECT');
  }

  /** Provides positive model feedback using the 'thumbs up' button. */
  handleProvidePositiveFeedback(answerRecordName: string) {
    this.handleProvideFeedback(
        {answerRecordName, correctnessLevel: 'FULLY_CORRECT'});
  }

  /** Provides negative model feedback using the 'thumbs down' button. */
  handleProvideNegativeFeedback(answerRecordName: string) {
    this.handleProvideFeedback(
        {answerRecordName, correctnessLevel: 'NOT_CORRECT'});
  }

  /** Provides model feedback using the 'thumbs up'/'thumbs down' button. */
  handleProvideFeedback({answerRecordName, correctnessLevel}:
                            AnswerRecordUpdate) {
    const answerRecord: AnswerRecord_ = {
      'name': answerRecordName,
      'answerFeedback': {'correctnessLevel': correctnessLevel}
    };

    const previousAnswerRecord = this.answerRecords[answerRecordName];
    const updateMask = 'answerFeedback.correctnessLevel';

    this.onProvideFeedback.emit(
        {answerRecord, previousAnswerRecord, updateMask});
  }

  /**
   * Provides neutral model feedback (if clicking the 'thumbs up' button
   * twice).
   */
  handleProvideNeutralFeedback(answerRecordName: string) {
    this.handleProvideFeedback(
        {answerRecordName, correctnessLevel: 'CORRECTNESS_LEVEL_UNSPECIFIED'});
  }

  handleAutoScroll() {
    this.newSuggestionsChipHidden$.next(true);
    const nativeElement = this.suggestionsContainer?.nativeElement;

    if (nativeElement) {
      requestAnimationFrame(() => {
        nativeElement.scrollTo({
          'behavior': 'smooth',
          'left': 0,
          'top': nativeElement.scrollHeight,
        });
      });
    }
  }

  handleScrollChange() {
    if (this.suggestionsContainer?.isScrolledToBottom) {
      this.newSuggestionsNotVisible$.next(false);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private resetState() {
    this.searchActive$.next(false);
    this.filterString.reset();
    this.newSuggestionsNotVisible$.next(false);
    this.newSuggestionsChipHidden$.next(false);
  }

  private hasCorrectnessLevel(
      answerRecordName: string,
      correctnessLevel: AnswerFeedback_CorrectnessLevel) {
    const answerRecord = this.answerRecords[answerRecordName];
    if (!answerRecord) return false;
    return answerRecord.answerFeedback?.correctnessLevel === correctnessLevel;
  }

  private updateDisplayTimes(suggestions: ArticleOrFaqAnswerWithCreateTime[]) {
    const answerRecords =
        suggestions.map(suggestion => suggestion.answer?.answerRecord!)
            .filter(Boolean);
    this.suggestionFeatureService.updateSuggestionDisplayTimes(answerRecords);
  }
}
