import {Clipboard} from '@angular/cdk/clipboard';
import {Directive, Inject, InjectionToken, Input, Optional} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

/** Default configuration for snackbar messages. */
export const DEFAULT_SNACKBAR_CONFIG: MatSnackBarConfig = {
  // How long the copy confirmation would persist, in milliseconds.
  duration: 1000,
};

// TODO(ecoughlan): Remove once TC picks up new translation.
/**
 * @desc Pop-up notification message shown after clicking a button that
 * successfully copies some nearby/related text to the computer's clipboard.
 */
export const MSG_COPY_SUCCESSFUL = goog.getMsg('Copied to clipboard.');

// TODO(ecoughlan): Remove once TC picks up new translation.
/**
 * @desc Pop-up notification message shown after clicking a button that
 * fails to copy some nearby/related text to the computer's clipboard.
 */
export const MSG_FAILED_TO_COPY = goog.getMsg('Failed to copy.');

/**
 * @desc Pop-up notification message shown after clicking a button that
 * successfully copies some nearby/related text to the computer's clipboard.
 */
export const MSG_XAP_COPY_SUCCESSFUL = goog.getMsg('Copied to clipboard.');

/**
 * @desc Pop-up notification message shown after clicking a button that
 * fails to copy some nearby/related text to the computer's clipboard.
 */
export const MSG_XAP_FAILED_TO_COPY = goog.getMsg('Failed to copy.');

/** Injection token for snackbar options. */
export const XAP_CLIPBOARD_SNACKBAR_OPTIONS =
    new InjectionToken<MatSnackBarConfig>('xap-clipboard-snackbar-options');

/**
 * Provides behavior for a button that when clicked copies content into user's
 * clipboard and shows a confirmation on snack bar.
 *
 * This is not a replacement for @angular/cdk/clipboard, but instead an
 * enhancement on top of it, as it uses
 * @angular/material/snack-bar to notify the user on success/failure of
 * clipboard events.
 *
 * Example usage:
 *
 * <button xapCopyToClipboard="Content to be copied">Copy me!</button>
 */
@Directive({
  selector: '[xapCopyToClipboard]',
  host: {
    '(click)': 'doCopy()',
  }
})
export class XapCopyToClipboard {
  /** Content to be copied. */
  // tslint:disable-next-line:no-input-rename
  @Input('xapCopyToClipboard') text = '';

  constructor(
      private readonly clipboard: Clipboard,
      private readonly snackBar: MatSnackBar,
      @Optional() @Inject(XAP_CLIPBOARD_SNACKBAR_OPTIONS)
      private readonly snackBarConfig: MatSnackBarConfig) {}

  doCopy() {
    const config = {...DEFAULT_SNACKBAR_CONFIG, ...this.snackBarConfig};

    if (this.clipboard.copy(this.text)) {
      this.snackBar.open(MSG_COPY_SUCCESSFUL, /* action */ undefined, config);
    } else {
      this.snackBar.open(MSG_FAILED_TO_COPY, /* action */ undefined, config);
    }
  }
}
