/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Internal implementations of TrustedResourceUrl.
 * DO NOT OPENSOURCE
 */


import * as internals from 'google3/third_party/javascript/closure/html/internals';
import {TrustedResourceUrl} from 'google3/third_party/javascript/closure/html/trustedresourceurl';

export {TrustedResourceUrl} from 'google3/third_party/javascript/closure/html/trustedresourceurl';


/**
 * Builds a new `TrustedResourceUrl` from the given string, without
 * enforcing safety guarantees. It may cause side effects by creating a Trusted
 * Types policy. This shouldn't be exposed to application developers, and must
 * only be used as a step towards safe builders or safe constants.
 */
export function createResourceUrl(url: string): TrustedResourceUrl {
  return internals.createTrustedResourceUrl(url);
}

/**
 * Checks if the given value is a `TrustedResourceUrl` instance.
 */
export function isResourceUrl(value: unknown): value is TrustedResourceUrl {
  return value instanceof TrustedResourceUrl;
}

/**
 * Returns the value of the passed `TrustedResourceUrl` object while ensuring it
 * has the correct type.
 *
 * Returns a native `TrustedScriptURL` or a string if Trusted Types are
 * disabled.
 */
export function unwrapResourceUrl(value: TrustedResourceUrl): TrustedScriptURL|
    string {
  return TrustedResourceUrl.unwrapTrustedScriptURL(value);
}
