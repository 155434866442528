import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ParameterFormFieldModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/parameter_form_field/parameter_form_field_module';
import {ResponseMessageModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/response_message/response_message_module';
import {CardFormModule} from 'google3/java/com/google/dialogflow/console/web/common/card_form/card_form_module';
import {LetDirectiveModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/let/let_directive_module';
import {GmatChipsModule} from 'google3/javascript/angular2/components/gmat/mdc_chips/chips_module';

import {WorkflowEntry} from './workflow_entry';

@NgModule({
  imports: [
    CardFormModule,
    CommonModule,
    FormsModule,
    GmatChipsModule,
    LetDirectiveModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ParameterFormFieldModule,
    ResponseMessageModule,
  ],
  declarations: [WorkflowEntry],
  exports: [WorkflowEntry]
})
export class WorkflowEntryModule {
}
