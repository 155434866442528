import {COMMON_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/icons';
import {dedupeIconList} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/icon_helpers';

/**
 * Material icons used by the Conversation Guidance UI module.
 *
 * Icons names and versions can be found at
 * https://icons.corp.google.com/?icon.set=Google%20Material
 */
export const GUIDANCE_ICONS = dedupeIconList([
  ...COMMON_ICONS,
  {name: 'arrow_forward', version: 12},
  {name: 'check_circle', version: 17},
  {name: 'lightbulb', version: 17},
  {name: 'warning', version: 16},
]);
