import {DOCUMENT} from '@angular/common';
import {inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DARK_MODE_BACKGROUND_ELEMENT_CLASS, DARK_MODE_PARENT_CLASS, DARK_MODE_STYLESHEET_CLASS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/dark_mode_constants';

/** Service to initialize and manage dark mode for the application. */
@Injectable()
export class DarkModeService {
  private readonly document = inject(DOCUMENT);
  private readonly renderer!: Renderer2;
  private readonly parentElement = this.document.body;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Initializes a stylesheet that is used for applying the dark mode
   * background color.
   */
  initStyles({backgroundColor}: {backgroundColor: string}) {
    this.renderer.addClass(
        this.parentElement, DARK_MODE_BACKGROUND_ELEMENT_CLASS);

    if (document.querySelector(`style.${DARK_MODE_STYLESHEET_CLASS}`)) {
      return;
    }

    const styleSheet = document.createElement('style');
    this.renderer.addClass(styleSheet, DARK_MODE_STYLESHEET_CLASS);
    this.renderer.appendChild(document.head, styleSheet);
    styleSheet.sheet?.addRule(
        `.${DARK_MODE_PARENT_CLASS}[class].${
            DARK_MODE_BACKGROUND_ELEMENT_CLASS}[class], .${
            DARK_MODE_PARENT_CLASS}[class] .${
            DARK_MODE_BACKGROUND_ELEMENT_CLASS}[class]`,
        `background-color: ${backgroundColor}`);
  }

  set() {
    this.renderer.addClass(this.parentElement, DARK_MODE_PARENT_CLASS);
  }

  unset() {
    this.renderer.removeClass(this.parentElement, DARK_MODE_PARENT_CLASS);
  }
}
