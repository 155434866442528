import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/mdc-button';
import {MatFormFieldModule} from '@angular/material/mdc-form-field';
import {MatInputModule} from '@angular/material/mdc-input';
import {CardFormModule} from 'google3/java/com/google/dialogflow/console/web/common/card_form/card_form_module';
import {LetDirectiveModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/let/let_directive_module';

import {HeadIntent} from './head_intent';

@NgModule({
  imports: [
    CardFormModule,
    CommonModule,
    LetDirectiveModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  declarations: [HeadIntent],
  exports: [HeadIntent]
})
export class HeadIntentModule {
}
