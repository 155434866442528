import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SuggestionFeatureStore} from 'google3/java/com/google/dialogflow/console/web/ccai/services/suggestion_feature_store/suggestion_feature_store';
import {SuggestionFeatureService} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/common/suggestion_feature_service/suggestion_feature_service';
import {DEFAULT_ARTICLE_LINK_CONFIG} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/document_and_faq_constants';
import {AnswerRecordUpdate, KnowledgeAssistFeature, SelectArticleEvent} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/document_and_faq_types';
import {AnswerRecord_, Conversation_, SearchArticleAnswer_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {isResolved, LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';
import {SystemMessagingService} from 'google3/java/com/google/dialogflow/console/web/common/system_messaging_service/system_messaging_service';
import {Dictionary} from 'google3/java/com/google/dialogflow/console/web/common/types/common_types';
import {assertInstanceof} from 'google3/third_party/javascript/closure/asserts/asserts';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, take, takeUntil} from 'rxjs/operators';

/** Article Search component. */
@Component({
  selector: 'article-search',
  templateUrl: './article_search.ng.html',
  styleUrls: ['./article_search.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SuggestionFeatureService,
    SuggestionFeatureStore,
  ]
})
export class ArticleSearch implements OnInit, OnChanges {
  @ViewChild('filterInput', {static: true})
  filterInputEl!: ElementRef<HTMLInputElement>;

  @Input() features: KnowledgeAssistFeature[] = [];
  @Input() answerRecords: Dictionary<AnswerRecord_> = {};
  @Input() articleLinkConfig = DEFAULT_ARTICLE_LINK_CONFIG;
  @Input() filterString!: FormControl<string>;
  @Input() additionalSuggestionsArrivedDuringSearch$!: Observable<boolean>;
  @Input() searchActive$!: Observable<boolean>;

  @Input()
  set conversation(conversation: Conversation_|null) {
    if (this.innerConversation && this.innerConversation !== conversation) {
      this.handleGoBack();
    }
    this.innerConversation = conversation;
  }
  get conversation() {
    return this.innerConversation;
  }

  @Input()
  set answers(answers: SearchArticleAnswer_[]) {
    this.innerAnswers = answers;
    this.updateDisplayTimes();
  }
  get answers() {
    return this.innerAnswers;
  }

  @Input()
  get loadingState() {
    return this.innerLoadingState$.value;
  }
  set loadingState(loadingState: LoadingState) {
    this.innerLoadingState$.next(loadingState);
  }

  @Output() readonly onCloseSearch = new EventEmitter();
  @Output() readonly onSearchArticles = new EventEmitter<string>();
  @Output() readonly onSelectArticle = new EventEmitter<SelectArticleEvent>();
  @Output() readonly onProvideFeedback = new EventEmitter<AnswerRecordUpdate>();

  readonly filter$ = new BehaviorSubject<string>('');

  private readonly destroyed$ = new Subject();
  private readonly innerLoadingState$ =
      new BehaviorSubject(LoadingState.NOT_LOADING);
  private innerAnswers: SearchArticleAnswer_[] = [];
  private innerConversation: Conversation_|null = null;

  readonly searchResultsResolved$ =
      combineLatest([this.innerLoadingState$, this.filter$])
          .pipe(
              map(([loadingState, filter]) => {
                return isResolved(loadingState) && !!filter;
              }),
              distinctUntilChanged(),
          );

  get isLoading() {
    return this.loadingState === LoadingState.LOADING;
  }

  get isResolved() {
    return isResolved(this.loadingState);
  }

  constructor(
      private readonly suggestionFeatureService: SuggestionFeatureService,
      private readonly systemMessagingService: SystemMessagingService,
  ) {}

  clearFilter() {
    this.filterString.setValue('', {emitEvent: false});
  }

  handleGoBack() {
    this.filterString.setValue('');
    this.filter$.next('');
    this.onCloseSearch.emit();
    this.blurFilterInput();
  }

  handleEnterKeypress() {
    this.filter$.next(this.filterString.value);
    this.blurFilterInput();
  }

  ngOnChanges() {
    this.assertRequiredInputs();
  }

  ngOnInit() {
    this.assertRequiredInputs();

    this.additionalSuggestionsArrivedDuringSearch$
        .pipe(filter(Boolean), takeUntil(this.destroyed$))
        .subscribe(() => {
          let message = 'New knowledge assist suggestions available';
          let actionMessage = 'Take me there';

          // Show less text on smaller screen sizes.
          if (window.innerWidth < 400) {
            message = 'New suggestions available';
            actionMessage = 'View';
          }

          const snackbarRef = this.systemMessagingService.showInfoSnackBar(
              message, actionMessage);

          snackbarRef.onAction()
              .pipe(
                  take(1),
                  takeUntil(this.destroyed$),
                  )
              .subscribe(() => {
                this.handleGoBack();
              });
        });

    this.filterString.valueChanges
        .pipe(
            takeUntil(this.destroyed$),
            debounceTime(1000),
            filter(Boolean),
            )
        .subscribe(val => {
          this.filter$.next(val);
        });

    this.filter$
        .pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged(),
            filter(Boolean),
            )
        .subscribe(value => {
          this.onSearchArticles.next(value);
        });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private blurFilterInput() {
    setTimeout(() => {
      this.filterInputEl.nativeElement.blur();
    });
  }

  private updateDisplayTimes() {
    const answerRecords =
        this.answers.map(answer => answer.answerRecord!).filter(Boolean);
    this.suggestionFeatureService.updateSuggestionDisplayTimes(answerRecords);
  }

  private assertRequiredInputs() {
    assertInstanceof(
        this.searchActive$, Observable,
        'Please pass a valid searchActive Observable to the <article-search> component');

    assertInstanceof(
        this.additionalSuggestionsArrivedDuringSearch$, Observable,
        'Please pass a valid additionalSuggestionsArrivedDuringSearch Observable to the <article-search> component');
  }
}
