import {Injectable, OnDestroy} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, take, takeUntil} from 'rxjs/operators';

interface State<T> {
  readonly activeConversationName: string;
  readonly byConversation: {readonly [conversationName: string]: T};
}

interface StateUpdater<T> {
  (state: T): T;
}

type StateOrUpdater<T> = T|StateUpdater<T>;

class InnerStateService<T extends object = {}> extends
    ComponentStore<State<T>> {
  readonly initialState$ = new BehaviorSubject<T|null>(null);
  readonly activeConversationName$ =
      this.select(state => state.activeConversationName);
  readonly conversationRegistered$ = new Subject<string>();

  readonly activeConversationState$ =
      this.activeConversationName$.pipe(switchMap(
          activeConversationName =>
              this.selectStateForConversation(activeConversationName)));

  readonly setActiveConversation = this.updater(
      (state, activeConversationName: string) =>
          ({...state, activeConversationName}));

  private readonly registeredConversations = new Set<string>();

  constructor() {
    super({activeConversationName: '', byConversation: {}});

    this.state$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      const conversationNames =
          Object.getOwnPropertyNames(state.byConversation);

      for (const conversationName of conversationNames) {
        if (!this.registeredConversations.has(conversationName)) {
          this.registeredConversations.add(conversationName);
          this.conversationRegistered$.next(conversationName);
        }
      }
    });
  }

  selectStateForConversation(conversation: string) {
    return this.initialState$.pipe(
        filter(Boolean),
        take(1),
        switchMap(
            initialState => this.select(
                state => state.byConversation[conversation] || initialState)),
    );
  }
}

/**
 * Service that manages conversation-grouped state using NgRx component store.
 */
@Injectable()
export class SuggestionFeatureStore<T extends object> implements OnDestroy {
  protected readonly innerStateService = new InnerStateService<T>();

  /** The currently active conversation name. */
  readonly activeConversationName$ =
      this.innerStateService.activeConversationName$;

  /**
   * The full state object, including conversation states by conversation name.
   */
  readonly state$ = this.innerStateService.state$;

  /**
   * Emits whenever a new conversation has been registered in the store.
   */
  readonly conversationRegistered$ =
      this.innerStateService.conversationRegistered$;

  /**
   * Initializes the store with the initial state value. This must be called
   * before any other store functionality is available.
   */
  init(initialState: T) {
    this.innerStateService.initialState$.next(initialState);
  }

  /** Sets the currently active conversation. */
  setActiveConversation(activeConversationName: string) {
    this.innerStateService.setActiveConversation(activeConversationName);
  }

  /** Selects the state for the currently active conversation. */
  selectForActiveConversation<R>(cb: (state: T) => R): Observable<R> {
    return this.innerStateService.activeConversationState$.pipe(
        map(activeConversationState => cb(activeConversationState)),
        distinctUntilChanged(),
    );
  }

  /** Selects the state for a given conversation. */
  selectForConversation<R>(cb: (state: T) => R) {
    return (conversationName: string): Observable<R> => {
      return this.innerStateService.selectStateForConversation(conversationName)
          .pipe(
              map(state => cb(state)),
              distinctUntilChanged(),
          );
    };
  }

  /** Sets the state for the currently active conversation. */
  setStateForActiveConversation(conversationStateOrFn: StateOrUpdater<T>) {
    this.innerStateService.setState(state => {
      return this.getNewConversationState(
          state.activeConversationName, conversationStateOrFn, state);
    });
  }

  /** Sets the state for a given conversation. */
  setStateForConversation(
      conversationName: string, conversationStateOrFn: StateOrUpdater<T>) {
    this.innerStateService.setState(state => {
      return this.getNewConversationState(
          conversationName, conversationStateOrFn, state);
    });
  }

  /**
   * Returns an updater function to update the state for a given conversation.
   * If no conversation name is provided, it will update the state for the
   * currently active conversation.
   */
  updater<V>(cb: (state: T, val: V) => T) {
    return (val: V, conversationName?: string) => {
      if (conversationName) {
        this.setStateForConversation(conversationName, state => cb(state, val));
      } else {
        this.setStateForActiveConversation(state => cb(state, val));
      }
    };
  }

  /** Registers an effect. */
  effect<T, R>(cb: (source: Observable<T>) => Observable<R>) {
    return this.innerStateService.effect(cb);
  }

  ngOnDestroy() {
    this.innerStateService.ngOnDestroy();
  }

  private getNewConversationState(
      conversationName: string,
      conversationStateOrFn: StateOrUpdater<T>,
      state: State<T>,
      ): State<T> {
    const conversationState = state.byConversation[conversationName] ||
        this.innerStateService.initialState$.value;

    return {
      ...state,
      byConversation: {
        ...state.byConversation,
        [conversationName]: {
          ...conversationState,
          ...(typeof conversationStateOrFn === 'function' ?
                  (conversationStateOrFn as
                   StateUpdater<T>)(conversationState) :
                  conversationStateOrFn),
        },
      },
    };
  }
}
