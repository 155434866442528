import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, UntypedFormArray, ValidatorFn} from '@angular/forms';
import {Store} from '@ngrx/store';
import {generateFileExtensionRegex} from 'google3/java/com/google/dialogflow/console/web/common/helpers/gcs_helpers';
import {getProjectIdFromName} from 'google3/java/com/google/dialogflow/console/web/common/helpers/proto_name_id_extractors';
import {getCheckableUrl} from 'google3/java/com/google/dialogflow/console/web/common/helpers/url_helpers';
import {getSelectedProjectId} from 'google3/java/com/google/dialogflow/console/web/common/store/projects/projects_selectors';
import {Dictionary} from 'google3/java/com/google/dialogflow/console/web/common/types/common_types';
import {JsonValidationError, UrlValidationError, UrlValidationErrors, WhitespaceValidationError} from 'google3/java/com/google/dialogflow/console/web/common/types/validation_error_types';
import {isObject, isValidJson} from 'google3/java/com/google/dialogflow/console/web/common/utils/obj_is_empty';
import {of} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';


interface JsonObjectValidatorParams {
  /**
   * List of valid field types, i.e. ['string', 'boolean']. If not specified,
   * any type for a field is considered valid.
   */
  validFieldTypes?: string[];

  /** Whether the object should be flat (no nested objects). */
  flat?: boolean;
}

// tslint:disable:class-as-namespace

/**
 * CCAI custom FormControl validators.
 *
 * Used a class with static methods to mirror the Validator class provided by
 * Angular.
 *
 * i.e., if adding multiple validators to a FormControl, it will now look like:
 * [Validators.required, CustomValidators.nonWhitespace, ...]
 *
 * Also cannot use a plain object, because linting forces lowerCamelCase for
 * object names.
 */
export class CustomValidators {
  /**
   * Verifies that the value of a FormControl contains non-whitespace
   * characters.
   *
   * i.e., ' ' would be considered an invalid value, while 'a' would not.
   */
  static nonWhitespace: ValidatorFn =
      (control): WhitespaceValidationError|null => {
        const fieldValue = control.value || '';
        const isEmpty = fieldValue.length && fieldValue.trim().length === 0;

        return isEmpty ? {'whitespace': true} : null;
      };

  /** Verifies that the value of a FormControl can be parsed as a date. */
  static date: ValidatorFn = control => {
    const fieldValue = control.value || '';

    if (!fieldValue) {
      return null;
    }

    try {
      // tslint:disable-next-line:no-unused-expression
      new Date(fieldValue);
      return null;
    } catch {
      return {'invalidDate': true};
    }
  };

  /**
   * Verifies that the value of a FormControl contains a valid url
   */
  static isUrl: ValidatorFn = (control): UrlValidationError|null => {
    const fieldValue = control.value || '';

    try {
      // new URL() is just used to throw an exception
      // tslint:disable-next-line:no-unused-expression
      new URL(fieldValue);
      return null;
    } catch {
      return {'isUrl': false};
    }
  };

  /**
   * Validates that the value of a formControl can be parsed as a stringified
   * JSON object.
   */
  static jsonObject({validFieldTypes, flat}: JsonObjectValidatorParams = {}) {
    const validatorFn: ValidatorFn =
        ({value}: AbstractControl): JsonValidationError|null => {
          // Empty field is valid
          if (!value) return null;

          if (!isValidJson(value)) {
            return {'invalidJson': 'Could not parse JSON.'};
          }

          const parsed = JSON.parse(value) as Dictionary<unknown>;

          if (!isObject(parsed)) {
            return {'invalidJson': 'Input must be a valid JSON object.'};
          }

          if (flat && Object.values(parsed).some(value => isObject(value))) {
            return {'invalidJson': 'Cannot include nested fields in input.'};
          }

          if (validFieldTypes) {
            const stack: object[] = [parsed];

            while (stack.length) {
              const obj = stack.pop()!;

              for (const fieldValue of Object.values(obj)) {
                if (isObject(fieldValue)) {
                  stack.push(fieldValue);
                } else {
                  if (!validFieldTypes.includes(typeof fieldValue)) {
                    return {
                      'invalidJson': `Invalid field type. Must be one of: ${
                          validFieldTypes.join(', ')}`
                    };
                  }
                }
              }
            }
          }

          return null;
        };

    return validatorFn;
  }

  private constructor() {}
}

/** Compares two form control values for equality. */
interface ComparatorFn {
  // tslint:disable-next-line:no-any
  (current: any, expected: any): boolean;
}

/**
 * CCAI custom FormArray validators.
 *
 * This class contains a set of common FormArray validator functions that are
 * not included in Angular's default Validators class.
 */
export class CustomFormArrayValidators {
  /**
   * Verifies that a FormArray instance does not contain duplicate controls.
   *
   * @param comparator A function that asserts equality on two form control
   *     values.
   */
  static noDuplicates(comparator: ComparatorFn = (a, b) => a === b) {
    const validatorFn: ValidatorFn = control => {
      if (control instanceof UntypedFormArray) {
        const values: unknown[] = control.value;

        if (values.some(
                current =>
                    values.filter(value => comparator(current, value)).length >
                    1)) {
          return {'hasDuplicates': true};
        }

        return null;
      }

      throw new Error(
          'Can only use noDuplicates validator on valid FormArray instances.');
    };

    return validatorFn;
  }

  /**
   * Verifies that a FormArray instance of GCS URIs only contains given
   * file extensions.
   *
   * @param fileExtensions Allowed file extensions as array of strings
   */
  static allowedFileExtensions(fileExtensions: string[]) {
    const regex = generateFileExtensionRegex(fileExtensions);

    const urlValidationError: UrlValidationErrors = {
      'invalidUrlExtension': `File type must be one of: ${
          fileExtensions.map(extension => extension.toUpperCase()).join(', ')}`
    };


    const validatorFn: ValidatorFn = control =>
        control.value.every((url: string) => regex.test(getCheckableUrl(url))) ?
        null :
        urlValidationError;

    return validatorFn;
  }

  private constructor() {}
}

/**
 * CCAI custom async validators.
 */
@Injectable({providedIn: 'root'})
export class CustomAsyncValidatorsService {
  constructor(private readonly store: Store<{}>) {}

  /**
   * Determines if the given control's value is from the currently selected
   * project.
   */
  readonly sameProject: AsyncValidatorFn = control => {
    if (!control.value || !control.value.includes('/')) {
      return of(null);
    }

    const controlProjectId = getProjectIdFromName(control.value);

    return this.store.select(getSelectedProjectId)
        .pipe(
            filter(Boolean),
            take(1),
            map(projectId => projectId === controlProjectId ?
                    null :
                    {'wrongProject': true}),
        );
  };
}
