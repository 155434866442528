/** Severity levels for the message bar component. */
export const MESSAGE_BAR_LEVELS =
    ['informational', 'success', 'warning', 'danger-soft', 'danger'] as const;

/** Message bar item types. */
export const MESSAGE_BAR_ITEM_TYPES = ['primary', 'secondary'] as const;

/** String union of message bar types. */
export type MessageBarLevel = typeof MESSAGE_BAR_LEVELS[number];

/** String union of message bar types. */
export type MessageBarItemType = typeof MESSAGE_BAR_ITEM_TYPES[number];

/** Map of message bar types to Angular material icons. */
export const SVG_ICONS: Record<MessageBarLevel, string> = {
  'informational': 'info',
  'success': 'check_circle_filled',
  'warning': 'warning',
  'danger-soft': 'error_filled_alt',
  'danger': 'error_filled_alt'
};
