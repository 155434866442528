import {NgModule} from '@angular/core';
import {MatChipsModule} from '@angular/material/mdc-chips';

import {GmatChips} from './chips';

/**
 * Module that provides the MatChipsModule.
 */
@NgModule({
  declarations: [GmatChips],
  exports: [GmatChips, MatChipsModule],
})
export class GmatChipsModule {
}
