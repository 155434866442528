import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {assertInstanceof, assertString} from 'google3/javascript/typescript/contrib/assert';

/**
 * Directive that sets class to element when page is scrolled.
 *
 * @Example
 * <header [scrollingElement]="scrollable" setClassOnScroll="scrolled"></header>
 * <div #scrollable></div>
 *
 * When element #scrollable has been scrolled, the class 'scrolled' will be
 * added to the header element.
 */
@Directive({
  selector: '[setClassOnScroll]',
})
export class SetClassOnScroll implements OnInit, OnDestroy {
  @Input() readonly setClassOnScroll!: string;
  @Input() readonly scrollingElement?: HTMLElement;

  private readonly onScroll =
      (event: Event) => {
        if (event.target) {
          this.hostElementRef.nativeElement.classList.toggle(
              this.setClassOnScroll, (event.target as HTMLElement).scrollTop);
        }
      }

  constructor(private readonly hostElementRef: ElementRef) {}

  ngOnInit() {
    assertString(this.setClassOnScroll);
    assertInstanceof(this.scrollingElement, HTMLElement);

    this.scrollingElement?.addEventListener('scroll', this.onScroll);
  }

  ngOnDestroy() {
    /**
     * Unit tests destroy the input scrolling element prior to ngOnDestroy
     * being fired which required the use of optional chaining to avoid test
     * failures.
     */
    this.scrollingElement?.removeEventListener('scroll', this.onScroll);
  }
}
