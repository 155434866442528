import {NgModule} from '@angular/core';
import {SystemMessagingModule} from 'google3/java/com/google/dialogflow/console/web/common/system_messaging_service/system_messaging_module';
import {WINDOW_INJECTION_TOKEN} from 'google3/java/com/google/dialogflow/console/web/common/window_provider/window_provider';

import {NotificationService} from './notification_service';



@NgModule({
  imports: [SystemMessagingModule],
  providers: [
    {
      provide: WINDOW_INJECTION_TOKEN,
      useValue: window,
    },
    NotificationService
  ],
})
export class NotificationModule {
}
