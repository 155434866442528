import {ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, ScrollDispatcher} from '@angular/cdk/overlay';
import {ElementRef, Injectable} from '@angular/core';

/**
 * Helper class to create connected overlays that stay attached to their
 * elements when user scrolls the viewport. Note that this will work only if
 * all scrollable parent elements have [cdkScrollable] attribute.
 */
@Injectable({providedIn: 'root'})
export class ConnectedOverlayHelper {
  /** Inject the services needed to construct connected overlay configuration */
  constructor(
      private readonly overlay: Overlay,
      private readonly scrollDispatcher: ScrollDispatcher) {}

  /**
   * Given an element, and how we want to connect to it, this function returns
   * overlay configuration that stays attached to the element even when user
   * scrolls the viewport.
   */
  createFlexibleOverlayConfig(
      element: ElementRef, positions: ConnectedPosition[]): OverlayConfig {
    const strategy: FlexibleConnectedPositionStrategy =
        this.createFlexibleConnectedPositionsStrategy(
            element, positions, false);

    strategy.withScrollableContainers(
        this.scrollDispatcher.getAncestorScrollContainers(element));

    const config = new OverlayConfig();
    config.positionStrategy = strategy;
    config.scrollStrategy = this.overlay.scrollStrategies.reposition();
    return config;
  }

  /**
   * Creates a FlexibleConnectedPositionsStrategy given an element and
   * positions, and optionally a withFlexibleDimensions flag. The primary
   * purpose of this method is to ensure that the array of position objects are
   * cast as ConnectedPosition objects, otherwise when property renaming is
   * turned on the keys on the position objects will not match.
   */
  createFlexibleConnectedPositionsStrategy(
      element: ElementRef, positions: ConnectedPosition[],
      withFlexibleDimensions?: boolean): FlexibleConnectedPositionStrategy {
    return this.overlay.position()
        .flexibleConnectedTo(element)
        // Defaults to true if flag not provided because that is the default in
        // angular.
        .withFlexibleDimensions(
            withFlexibleDimensions === undefined ? true :
                                                   withFlexibleDimensions)
        .withPositions(positions);
  }
}
