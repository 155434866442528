import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatRippleModule} from '@angular/material/core';

import {GmatChip, GmatStyleMatChip} from './chips';

const COMPONENTS = [GmatChip, GmatStyleMatChip];

@NgModule({
  imports: [
    CommonModule,
    MatRippleModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class GmatChipsModule {
}
