import {BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal} from '@angular/cdk/portal';
import {AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, EmbeddedViewRef, Inject, InjectionToken, ViewChild, ViewEncapsulation} from '@angular/core';
import {assert} from 'google3/javascript/typescript/contrib/assert';

import {XAP_INLINE_DIALOG_ANIMATIONS} from './inline_dialog_animation';

/** Options that can be provided to XapInlineDialogContainer via DI. */
export interface XapInlineDialogContainerOptions {
  readonly dialogLabel: string|null;
  readonly dialogTabIndex: number;
}

/** Injection token for providing options to XapInlineDialogContainer via DI. */
export const XAP_INLINE_DIALOG_CONTAINER_OPTIONS =
    new InjectionToken<XapInlineDialogContainerOptions>('XAP_IDCO');

/**
 * A wrapper component of `xapInlineDialog` that wraps user-provided dialog
 * content.
 */
@Component({
  selector: 'xap-inline-dialog-container',
  // Ideally we shouldn't need this extra layer of `<div>`; all attributes
  // live on the host property of the component. But Angular doesn't support
  // attribute directive on host property. We need an element to carry
  // cdkFocusInitial so focus trap can work, so we introduced this layer.
  template: `
    <div>
      <ng-template cdkPortalOutlet>
        <a cdkFocusInitial tabindex="0"></a>
      </ng-template>
    </div>`,
  styleUrls: ['./inline_dialog_container.css'],
  animations: [XAP_INLINE_DIALOG_ANIMATIONS.dialogContainer],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'xap-inline-dialog-container gmat-body-2',
    'role': 'dialog',
    '[attr.aria-label]': 'options.dialogLabel',
    '[attr.tabindex]': 'options.dialogTabIndex',
    '[@dialogContainer]': 'animationState',
  },
})
export class XapInlineDialogContainer extends BasePortalOutlet implements
    AfterViewInit {
  /**
   * The portal outlet inside of this container into which the dialog content
   * will be loaded.
   */
  @ViewChild(CdkPortalOutlet, {static: true}) portalOutlet!: CdkPortalOutlet;

  /** State of the dialog animation. */
  animationState: 'hidden'|'visible'|'void' = 'hidden';

  constructor(@Inject(XAP_INLINE_DIALOG_CONTAINER_OPTIONS) readonly options:
                  XapInlineDialogContainerOptions) {
    super();
  }

  ngAfterViewInit() {
    assert(this.portalOutlet, '@ViewChild "portalOutlet" is required');
  }

  /**
   * Attach a ComponentPortal as content to this dialog container.
   * @param portal Portal to be attached as the dialog content.
   */
  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    assert(
        !this.portalOutlet.hasAttached(),
        'Attempting to attach content after content is already attached');

    return this.portalOutlet.attachComponentPortal(portal);
  }

  /**
   * Attach a TemplatePortal as content to this dialog container.
   * @param portal Portal to be attached as the dialog content.
   */
  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    assert(
        !this.portalOutlet.hasAttached(),
        'Attempting to attach content after content is already attached');

    return this.portalOutlet.attachTemplatePortal(portal);
  }

  toggleDialogAnimation(open: boolean) {
    this.animationState = open ? 'visible' : 'hidden';
  }
}
