import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DoBootstrap, NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModuleWrapperModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/container/module_wrapper/module_wrapper_module';
import {GuidanceModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/guidance/guidance_module';
import {KnowledgeAssistModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/knowledge_assist/knowledge_assist_module';
import {SmartReplyModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/smart_reply/smart_reply_module';
import {SummarizationModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/summarization/summarization_module';
import {VirtualAgentAssistModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/virtual_agent_assist/virtual_agent_assist_module';
import {PARENT_CONTAINER_CONTEXT} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/injection_tokens';
import {DarkModeService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/dark_mode/dark_mode_service';
import {NotificationModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_module';
import {MessageBarModule} from 'google3/java/com/google/dialogflow/console/web/common/message_bar/message_bar_module';
import {SystemMessagingModule} from 'google3/java/com/google/dialogflow/console/web/common/system_messaging_service/system_messaging_module';
import {XapClipboardModule} from 'google3/javascript/angular2/components/clipboard/clipboard_module';

import {Container} from './container';



@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    GuidanceModule,
    KnowledgeAssistModule,
    DragDropModule,
    HttpClientModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MessageBarModule,
    NotificationModule,
    ModuleWrapperModule,
    SmartReplyModule,
    SummarizationModule,
    SystemMessagingModule,
    VirtualAgentAssistModule,
    XapClipboardModule,
  ],
  declarations: [
    Container,
  ],
  providers: [
    DarkModeService,
    {provide: PARENT_CONTAINER_CONTEXT, useValue: true},
  ],
  exports: [
    Container,
  ],
  entryComponents: [
    Container,
  ]
})
export class ContainerModule implements DoBootstrap {
  ngDoBootstrap() {}
}
