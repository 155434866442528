import {Component, OnInit} from '@angular/core';
import {SMART_REPLY_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/smart_reply/smart_reply_constants';
import {UI_MODULE_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/injection_tokens';
import {dispatchUiModuleEvent, fromUiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {UiModuleIconService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/icon/ui_module_icon_service';
import {NotificationService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_service';
import {UiModuleStore} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/store/ui_module_store';
import {UiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {dedupeSmartReplySuggestions} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/helpers/participants_helpers';
import {SmartReplyAnswersWithConversationName, SmartReplySelection} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/smart_reply/smart_reply_types';
import {SmartReplyAnswer_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {combineLatest, ReplaySubject} from 'rxjs';
import {map, takeUntil, tap, withLatestFrom} from 'rxjs/operators';

import {getSmartReplyAnswers} from './smart_reply_helpers';



/** Element selector for Smart Reply web component. */
export const SMART_REPLY_ELEMENT_SELECTOR = 'agent-assist-smart-reply';

interface State {
  smartReplyAnswers: SmartReplyAnswer_[];
  smartReplyFollowUpAnswers: SmartReplyAnswer_[];
}

const initialState: State = {
  smartReplyAnswers: [],
  smartReplyFollowUpAnswers: [],
};

/** Smart Reply component. */
@Component({
  selector: SMART_REPLY_ELEMENT_SELECTOR,
  templateUrl: './smart_reply.ng.html',
  styleUrls: ['./smart_reply.scss'],
  providers: [
    {provide: UI_MODULE_ICONS, useValue: SMART_REPLY_ICONS},
    UiModuleStore,
    UiModuleIconService,
  ],
})
export class SmartReply implements OnInit {
  readonly activeConversationName$ = this.store.activeConversationName$;

  private readonly smartReplyAnswers$ =
      this.store.selectForActiveConversation(state => state.smartReplyAnswers);

  private readonly smartReplyFollowUpAnswers$ =
      this.store.selectForActiveConversation(
          state => state.smartReplyFollowUpAnswers);

  readonly combinedSmartReplyAnswers$ =
      combineLatest([
        this.activeConversationName$,
        this.smartReplyAnswers$,
        this.smartReplyFollowUpAnswers$,
      ])
          .pipe(
              tap(() => {
                this.resetTooltip();
              }),
              map(([
                    conversationName,
                    smartReplyAnswers,
                    smartReplyFollowUpAnswers,
                  ]): SmartReplyAnswersWithConversationName => ({
                    conversationName,
                    payload: dedupeSmartReplySuggestions(
                        smartReplyAnswers, smartReplyFollowUpAnswers)
                  })));

  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
      notificationService: NotificationService,
      uiModuleIconService: UiModuleIconService,
      private readonly store: UiModuleStore<State>,
  ) {
    store.init(initialState);
    uiModuleIconService.initModule();
    notificationService.initModule();
  }

  /**
   * Dispatches a custom event on the window to populate the agent desktop
   * chatbox when a Smart Reply chip is selected.
   */
  selectSuggestion(selection: SmartReplySelection) {
    if (selection.answer.reply) {
      dispatchUiModuleEvent(
          UiModuleEvent.SMART_REPLY_SELECTED, {detail: selection});
    }
  }

  /** Removes phantom MAT tooltip container when re-rendering chips. */
  resetTooltip() {
    document.querySelector('mat-tooltip-component')?.remove();
  }

  ngOnInit() {
    fromUiModuleEvent(UiModuleEvent.ANALYZE_CONTENT_RESPONSE_RECEIVED)
        .pipe(
            withLatestFrom(this.smartReplyAnswers$),
            takeUntil(this.destroyed$),
            )
        .subscribe(([
                     {conversationName, payload: {response}},
                     currentSmartReplyAnswers,
                   ]) => {
          const smartReplyAnswers = getSmartReplyAnswers(response);

          if (smartReplyAnswers &&
              (smartReplyAnswers !== currentSmartReplyAnswers)) {
            // New Smart Reply suggestions received. Clear follow-up
            // suggestions.
            this.store.setStateForConversation(
                conversationName, state => ({
                                    ...state,
                                    smartReplyAnswers,
                                    smartReplyFollowUpAnswers: [],
                                  }));
          }
        });

    fromUiModuleEvent(UiModuleEvent.SMART_REPLY_FOLLOW_UP_SUGGESTIONS_RECEIVED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName, payload: suggestions}) => {
          this.store.setStateForConversation(
              conversationName,
              state => ({
                ...state,
                smartReplyFollowUpAnswers:
                    [...state.smartReplyFollowUpAnswers, ...suggestions]
              }));
        });
  }
}
