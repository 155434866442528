import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ScrollOnEmitModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/scroll_on_emit/scroll_on_emit_module';

import {ModuleWrapper} from './module_wrapper';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    ScrollOnEmitModule,
  ],
  declarations: [
    ModuleWrapper,
  ],
  exports: [
    ModuleWrapper,
  ]
})
export class ModuleWrapperModule {
}
