import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {NonNullableFormBuilder} from '@angular/forms';
import DateTimePatterns from 'goog:goog.i18n.DateTimePatterns'; // from //third_party/javascript/closure/i18n:datetimepatterns
import {getElapsedTime} from 'google3/java/com/google/dialogflow/console/web/common/helpers/date_helpers';
import {AnswerRecord_, Conversation_, SuggestConversationSummaryResponse_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';
import {SystemMessagingService} from 'google3/java/com/google/dialogflow/console/web/common/system_messaging_service/system_messaging_service';
import {DateTimeFormat} from 'google3/third_party/javascript/closure/i18n/datetimeformat';

/** Conversation Summarization suggestion feature. */
@Component({
  selector: 'conversation-summarization',
  templateUrl: './conversation_summarization.ng.html',
  styleUrls: ['./conversation_summarization.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationSummarization implements OnChanges {
  @Input() loadingState = LoadingState.NOT_LOADING;
  @Input()
  get conversation() {
    return this.innerConversation;
  }
  set conversation(conversation: Conversation_|null) {
    if (this.innerConversation) {
      this.resetSummarizationState();
    }
    this.innerConversation = conversation;
  }
  @Input() conversationSummary?: SuggestConversationSummaryResponse_|undefined;
  @Input() conversationSummaryText?: string|undefined;
  @Input() hasSaveHandler = false;
  @Input() saveLoadingState = LoadingState.NOT_LOADING;
  @Input() saveErrorMessage = '';
  @Input() saveSuccessMessage = '';

  /** Emits when the conversation summary is edited. */
  @Output() onEdit = new EventEmitter<AnswerRecord_>();

  /**
   * Emits the current summary when the conversation summary is saved. Used by
   * customers to save summary details to their CRM, or other persistence layer.
   */
  @Output() onSave = new EventEmitter<string>();

  readonly form = this.fb.control('');

  editingSummary = false;
  preEditSummary?: string;
  startEditTimestamp: Date|null = null;

  private innerConversation: Conversation_|null = null;

  private readonly dateFormat =
      new DateTimeFormat(DateTimePatterns.MONTH_DAY_YEAR_MEDIUM);

  private readonly timeFormat =
      new DateTimeFormat(DateTimeFormat.Format.FULL_TIME);

  get answerRecordName() {
    return this.conversationSummary?.summary?.answerRecord;
  }

  get currentDate() {
    return this.dateFormat.format(new Date());
  }

  get currentTime() {
    return this.timeFormat.format(new Date());
  }

  get conversationDuration() {
    return getElapsedTime(this.conversation?.startTime);
  }

  get copiedSummaryContent() {
    return `Date: ${this.currentDate}\nTime: ${this.currentTime}\nDuration: ${
        this.conversationDuration}\n\n${this.form.value}`;
  }

  constructor(
      private readonly fb: NonNullableFormBuilder,
      private readonly systemMessagingService: SystemMessagingService,
      private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const conversationSummaryText =
        changes['conversationSummaryText']?.currentValue;
    if (conversationSummaryText != null) {
      this.preEditSummary = conversationSummaryText;
      this.form.patchValue(conversationSummaryText);
    }

    const saveLoadingState = changes['saveLoadingState']?.currentValue;

    if (saveLoadingState === LoadingState.LOADED) {
      this.systemMessagingService.showSuccessSnackBar(this.saveSuccessMessage);
    }
    if (saveLoadingState === LoadingState.ERROR) {
      this.systemMessagingService.showErrorSnackBar(this.saveErrorMessage);
    }
  }

  ngOnInit() {
    this.form.patchValue(this.conversationSummaryText || '');
  }

  handleEditSummary() {
    this.editingSummary = true;
    this.preEditSummary = this.form.value;
    this.startEditTimestamp = new Date();
  }

  handleFinishEditingSummary() {
    this.editingSummary = false;

    if (this.form.value !== this.preEditSummary) {
      const answerRecord: AnswerRecord_ = {
        'name': this.answerRecordName,
        'answerFeedback': {
          'agentAssistantDetailFeedback': {
            'summarizationFeedback': {
              'summaryText': this.form.value,
              'startTimestamp': this.startEditTimestamp?.toISOString(),
              'submitTimestamp': new Date().toISOString(),
            },
          }
        }
      };
      this.onEdit.emit(answerRecord);
    }
  }

  handleSaveSummary() {
    this.onSave.emit(this.form.value);
  }

  handleCancelEditSummary() {
    this.editingSummary = false;
    this.startEditTimestamp = null;
    this.form.patchValue(this.preEditSummary || '');
  }

  private resetSummarizationState() {
    this.editingSummary = false;
    this.startEditTimestamp = null;
    this.cdr.detectChanges();
  }
}
