import {inject, Injectable, InjectFlags} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {PARENT_CONTAINER_CONTEXT, UI_MODULE_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/injection_tokens';
import {trustedResourceUrl} from 'safevalues';

/**
 * UI Module icon service.
 *
 * Handles the loading of Material Icons for both independent modules as well as
 * the parent Container component.
 */
@Injectable()
export class UiModuleIconService {
  private readonly iconList = inject(UI_MODULE_ICONS);
  private readonly parentContainerContext =
      inject(PARENT_CONTAINER_CONTEXT, InjectFlags.Optional);

  constructor(
      private readonly iconRegistry: MatIconRegistry,
  ) {}

  /**
   * Checks whether the UI module is loaded as part of the parent container or
   * as an independent module, and regisers Material icons in the latter case.
   * (If the module is loaded in the parent container context, the parent will
   * have already registered all relevant icons, so no additional registration
   * will be necessary.)
   */
  initModule() {
    if (!this.parentContainerContext) {
      this.registerIcons();
    }
  }

  /** Registers a provided set of icons in the Material icon registry. */
  registerIcons() {
    for (const {name, version} of this.iconList) {
      const trustedUrl =
          trustedResourceUrl`https://fonts.gstatic.com/s/i/googlematerialicons/${
              name}/v${version}/24px.svg`;
      this.iconRegistry.addSvgIcon(name, trustedUrl);
    }
  }
}
