import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {GmatButtonModule} from 'google3/javascript/angular2/components/gmat/mdc_button/button_module';
import {XapLetModule} from 'google3/javascript/angular2/components/let/xap_let_module';

import {MessageBar} from './message_bar';
import {MessageBarButton} from './message_bar_button';
import {MessageBarLink} from './message_bar_link';

/** Message Bar module. */
@NgModule({
  imports: [
    CommonModule,
    GmatButtonModule,
    MatIconModule,
    XapLetModule,
  ],
  declarations: [
    MessageBar,
    MessageBarButton,
    MessageBarLink,
  ],
  exports: [
    MessageBar,
    MessageBarButton,
    MessageBarLink,
  ],
})
export class MessageBarModule {
}
