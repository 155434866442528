import {Directive, ElementRef} from '@angular/core';

/**
 * Directive to focus element when it is initialized.
 * Note that this is similar to cdkFocusInitial, but will
 * work outside of explicit cdk focus traps.
 */
@Directive({selector: '[focusInitial]'})
export class FocusInitial {
  constructor(private readonly element: ElementRef) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.element.nativeElement.focus();

      if (document.activeElement !== this.element.nativeElement) {
        // Element was not focused. This may be because it is not an element
        // that can receive focus, so try adding a tabindex.
        this.element.nativeElement.setAttribute('tabindex', '-1');
        setTimeout(() => {
          this.element.nativeElement.focus();
        });
      }
    });
  }
}
