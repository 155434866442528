import {ApiClientHookFactory} from 'google3/javascript/typescript/contrib/apiclient/core/api_request_hook';
import {ClassMetadata, Serializable, SerializableCtor} from 'google3/javascript/typescript/contrib/apiclient/core/domain_object';
import {ApiClientObjectMap} from 'google3/javascript/typescript/contrib/apiclient/core/generated_types';
import {buildQueryParams} from 'google3/javascript/typescript/contrib/apiclient/core/request_params';
import {ObservableApiClient} from 'google3/javascript/typescript/contrib/apiclient/request_service/observable_api_client';
import {ObservableRequestService} from 'google3/javascript/typescript/contrib/apiclient/request_service/observable_request_service';
import {Observable} from 'rxjs';

export type AgentAssistantFeedback_AnswerRelevance =
    'ANSWER_RELEVANCE_UNSPECIFIED'|'IRRELEVANT'|'RELEVANT';

export interface IAgentAssistantFeedback_AnswerRelevanceEnum {
  readonly ANSWER_RELEVANCE_UNSPECIFIED: AgentAssistantFeedback_AnswerRelevance;
  readonly IRRELEVANT: AgentAssistantFeedback_AnswerRelevance;
  readonly RELEVANT: AgentAssistantFeedback_AnswerRelevance;

  values(): Array<AgentAssistantFeedback_AnswerRelevance>;
}

export const AgentAssistantFeedback_AnswerRelevanceEnum:
    IAgentAssistantFeedback_AnswerRelevanceEnum = {
      ANSWER_RELEVANCE_UNSPECIFIED: <
          AgentAssistantFeedback_AnswerRelevance>'ANSWER_RELEVANCE_UNSPECIFIED',
      IRRELEVANT: <AgentAssistantFeedback_AnswerRelevance>'IRRELEVANT',
      RELEVANT: <AgentAssistantFeedback_AnswerRelevance>'RELEVANT',
      values(): Array<AgentAssistantFeedback_AnswerRelevance> {
        return [
          AgentAssistantFeedback_AnswerRelevanceEnum
              .ANSWER_RELEVANCE_UNSPECIFIED,
          AgentAssistantFeedback_AnswerRelevanceEnum.IRRELEVANT,
          AgentAssistantFeedback_AnswerRelevanceEnum.RELEVANT
        ];
      }
    };

export type AgentAssistantFeedback_DocumentCorrectness =
    'DOCUMENT_CORRECTNESS_UNSPECIFIED'|'INCORRECT'|'CORRECT';

export interface IAgentAssistantFeedback_DocumentCorrectnessEnum {
  readonly DOCUMENT_CORRECTNESS_UNSPECIFIED:
      AgentAssistantFeedback_DocumentCorrectness;
  readonly INCORRECT: AgentAssistantFeedback_DocumentCorrectness;
  readonly CORRECT: AgentAssistantFeedback_DocumentCorrectness;

  values(): Array<AgentAssistantFeedback_DocumentCorrectness>;
}

export const AgentAssistantFeedback_DocumentCorrectnessEnum:
    IAgentAssistantFeedback_DocumentCorrectnessEnum = {
      CORRECT: <AgentAssistantFeedback_DocumentCorrectness>'CORRECT',
      DOCUMENT_CORRECTNESS_UNSPECIFIED: <
          AgentAssistantFeedback_DocumentCorrectness>'DOCUMENT_CORRECTNESS_UNSPECIFIED',
      INCORRECT: <AgentAssistantFeedback_DocumentCorrectness>'INCORRECT',
      values(): Array<AgentAssistantFeedback_DocumentCorrectness> {
        return [
          AgentAssistantFeedback_DocumentCorrectnessEnum
              .DOCUMENT_CORRECTNESS_UNSPECIFIED,
          AgentAssistantFeedback_DocumentCorrectnessEnum.INCORRECT,
          AgentAssistantFeedback_DocumentCorrectnessEnum.CORRECT
        ];
      }
    };

export type AgentAssistantFeedback_DocumentEfficiency =
    'DOCUMENT_EFFICIENCY_UNSPECIFIED'|'INEFFICIENT'|'EFFICIENT';

export interface IAgentAssistantFeedback_DocumentEfficiencyEnum {
  readonly DOCUMENT_EFFICIENCY_UNSPECIFIED:
      AgentAssistantFeedback_DocumentEfficiency;
  readonly INEFFICIENT: AgentAssistantFeedback_DocumentEfficiency;
  readonly EFFICIENT: AgentAssistantFeedback_DocumentEfficiency;

  values(): Array<AgentAssistantFeedback_DocumentEfficiency>;
}

export const AgentAssistantFeedback_DocumentEfficiencyEnum:
    IAgentAssistantFeedback_DocumentEfficiencyEnum = {
      DOCUMENT_EFFICIENCY_UNSPECIFIED: <
          AgentAssistantFeedback_DocumentEfficiency>'DOCUMENT_EFFICIENCY_UNSPECIFIED',
      EFFICIENT: <AgentAssistantFeedback_DocumentEfficiency>'EFFICIENT',
      INEFFICIENT: <AgentAssistantFeedback_DocumentEfficiency>'INEFFICIENT',
      values(): Array<AgentAssistantFeedback_DocumentEfficiency> {
        return [
          AgentAssistantFeedback_DocumentEfficiencyEnum
              .DOCUMENT_EFFICIENCY_UNSPECIFIED,
          AgentAssistantFeedback_DocumentEfficiencyEnum.INEFFICIENT,
          AgentAssistantFeedback_DocumentEfficiencyEnum.EFFICIENT
        ];
      }
    };

export type AgentTier_Tier =
    'TIER_UNSPECIFIED'|'TIER_STANDARD'|'TIER_ENTERPRISE'|'TIER_ENTERPRISE_PLUS';

export interface IAgentTier_TierEnum {
  readonly TIER_UNSPECIFIED: AgentTier_Tier;
  readonly TIER_STANDARD: AgentTier_Tier;
  readonly TIER_ENTERPRISE: AgentTier_Tier;
  readonly TIER_ENTERPRISE_PLUS: AgentTier_Tier;

  values(): Array<AgentTier_Tier>;
}

export const AgentTier_TierEnum: IAgentTier_TierEnum = {
  TIER_ENTERPRISE: <AgentTier_Tier>'TIER_ENTERPRISE',
  TIER_ENTERPRISE_PLUS: <AgentTier_Tier>'TIER_ENTERPRISE_PLUS',
  TIER_STANDARD: <AgentTier_Tier>'TIER_STANDARD',
  TIER_UNSPECIFIED: <AgentTier_Tier>'TIER_UNSPECIFIED',
  values(): Array<AgentTier_Tier> {
    return [
      AgentTier_TierEnum.TIER_UNSPECIFIED, AgentTier_TierEnum.TIER_STANDARD,
      AgentTier_TierEnum.TIER_ENTERPRISE,
      AgentTier_TierEnum.TIER_ENTERPRISE_PLUS
    ];
  }
};

export type Agent_ApiVersion = 'API_VERSION_UNSPECIFIED'|'API_VERSION_V1'|
    'API_VERSION_V2'|'API_VERSION_V2_BETA_1';

export interface IAgent_ApiVersionEnum {
  readonly API_VERSION_UNSPECIFIED: Agent_ApiVersion;
  readonly API_VERSION_V1: Agent_ApiVersion;
  readonly API_VERSION_V2: Agent_ApiVersion;
  readonly API_VERSION_V2_BETA_1: Agent_ApiVersion;

  values(): Array<Agent_ApiVersion>;
}

export const Agent_ApiVersionEnum: IAgent_ApiVersionEnum = {
  API_VERSION_UNSPECIFIED: <Agent_ApiVersion>'API_VERSION_UNSPECIFIED',
  API_VERSION_V1: <Agent_ApiVersion>'API_VERSION_V1',
  API_VERSION_V2: <Agent_ApiVersion>'API_VERSION_V2',
  API_VERSION_V2_BETA_1: <Agent_ApiVersion>'API_VERSION_V2_BETA_1',
  values(): Array<Agent_ApiVersion> {
    return [
      Agent_ApiVersionEnum.API_VERSION_UNSPECIFIED,
      Agent_ApiVersionEnum.API_VERSION_V1, Agent_ApiVersionEnum.API_VERSION_V2,
      Agent_ApiVersionEnum.API_VERSION_V2_BETA_1
    ];
  }
};

export type Agent_MatchMode =
    'MATCH_MODE_UNSPECIFIED'|'MATCH_MODE_HYBRID'|'MATCH_MODE_ML_ONLY';

export interface IAgent_MatchModeEnum {
  readonly MATCH_MODE_UNSPECIFIED: Agent_MatchMode;
  readonly MATCH_MODE_HYBRID: Agent_MatchMode;
  readonly MATCH_MODE_ML_ONLY: Agent_MatchMode;

  values(): Array<Agent_MatchMode>;
}

export const Agent_MatchModeEnum: IAgent_MatchModeEnum = {
  MATCH_MODE_HYBRID: <Agent_MatchMode>'MATCH_MODE_HYBRID',
  MATCH_MODE_ML_ONLY: <Agent_MatchMode>'MATCH_MODE_ML_ONLY',
  MATCH_MODE_UNSPECIFIED: <Agent_MatchMode>'MATCH_MODE_UNSPECIFIED',
  values(): Array<Agent_MatchMode> {
    return [
      Agent_MatchModeEnum.MATCH_MODE_UNSPECIFIED,
      Agent_MatchModeEnum.MATCH_MODE_HYBRID,
      Agent_MatchModeEnum.MATCH_MODE_ML_ONLY
    ];
  }
};

export type Agent_Tier =
    'TIER_UNSPECIFIED'|'TIER_STANDARD'|'TIER_ENTERPRISE'|'TIER_ENTERPRISE_PLUS';

export interface IAgent_TierEnum {
  readonly TIER_UNSPECIFIED: Agent_Tier;
  readonly TIER_STANDARD: Agent_Tier;
  readonly TIER_ENTERPRISE: Agent_Tier;
  readonly TIER_ENTERPRISE_PLUS: Agent_Tier;

  values(): Array<Agent_Tier>;
}

export const Agent_TierEnum: IAgent_TierEnum = {
  TIER_ENTERPRISE: <Agent_Tier>'TIER_ENTERPRISE',
  TIER_ENTERPRISE_PLUS: <Agent_Tier>'TIER_ENTERPRISE_PLUS',
  TIER_STANDARD: <Agent_Tier>'TIER_STANDARD',
  TIER_UNSPECIFIED: <Agent_Tier>'TIER_UNSPECIFIED',
  values(): Array<Agent_Tier> {
    return [
      Agent_TierEnum.TIER_UNSPECIFIED, Agent_TierEnum.TIER_STANDARD,
      Agent_TierEnum.TIER_ENTERPRISE, Agent_TierEnum.TIER_ENTERPRISE_PLUS
    ];
  }
};

export type AnswerFeedback_CorrectnessLevel = 'CORRECTNESS_LEVEL_UNSPECIFIED'|
    'NOT_CORRECT'|'PARTIALLY_CORRECT'|'FULLY_CORRECT';

export interface IAnswerFeedback_CorrectnessLevelEnum {
  readonly CORRECTNESS_LEVEL_UNSPECIFIED: AnswerFeedback_CorrectnessLevel;
  readonly NOT_CORRECT: AnswerFeedback_CorrectnessLevel;
  readonly PARTIALLY_CORRECT: AnswerFeedback_CorrectnessLevel;
  readonly FULLY_CORRECT: AnswerFeedback_CorrectnessLevel;

  values(): Array<AnswerFeedback_CorrectnessLevel>;
}

export const AnswerFeedback_CorrectnessLevelEnum:
    IAnswerFeedback_CorrectnessLevelEnum = {
      CORRECTNESS_LEVEL_UNSPECIFIED:
          <AnswerFeedback_CorrectnessLevel>'CORRECTNESS_LEVEL_UNSPECIFIED',
      FULLY_CORRECT: <AnswerFeedback_CorrectnessLevel>'FULLY_CORRECT',
      NOT_CORRECT: <AnswerFeedback_CorrectnessLevel>'NOT_CORRECT',
      PARTIALLY_CORRECT: <AnswerFeedback_CorrectnessLevel>'PARTIALLY_CORRECT',
      values(): Array<AnswerFeedback_CorrectnessLevel> {
        return [
          AnswerFeedback_CorrectnessLevelEnum.CORRECTNESS_LEVEL_UNSPECIFIED,
          AnswerFeedback_CorrectnessLevelEnum.NOT_CORRECT,
          AnswerFeedback_CorrectnessLevelEnum.PARTIALLY_CORRECT,
          AnswerFeedback_CorrectnessLevelEnum.FULLY_CORRECT
        ];
      }
    };

export type ArticleSuggestionConfig_TriggerModelMode =
    'TRIGGER_MODEL_MODE_UNSPECIFIED'|'TRIGGER_MODEL_MODE_ENABLED'|
    'TRIGGER_MODEL_MODE_DISABLED'|'TRIGGER_MODEL_MODE_END_USER_ONLY';

export interface IArticleSuggestionConfig_TriggerModelModeEnum {
  readonly TRIGGER_MODEL_MODE_UNSPECIFIED:
      ArticleSuggestionConfig_TriggerModelMode;
  readonly TRIGGER_MODEL_MODE_ENABLED: ArticleSuggestionConfig_TriggerModelMode;
  readonly TRIGGER_MODEL_MODE_DISABLED:
      ArticleSuggestionConfig_TriggerModelMode;
  readonly TRIGGER_MODEL_MODE_END_USER_ONLY:
      ArticleSuggestionConfig_TriggerModelMode;

  values(): Array<ArticleSuggestionConfig_TriggerModelMode>;
}

export const ArticleSuggestionConfig_TriggerModelModeEnum:
    IArticleSuggestionConfig_TriggerModelModeEnum = {
      TRIGGER_MODEL_MODE_DISABLED: <
          ArticleSuggestionConfig_TriggerModelMode>'TRIGGER_MODEL_MODE_DISABLED',
      TRIGGER_MODEL_MODE_ENABLED: <
          ArticleSuggestionConfig_TriggerModelMode>'TRIGGER_MODEL_MODE_ENABLED',
      TRIGGER_MODEL_MODE_END_USER_ONLY: <
          ArticleSuggestionConfig_TriggerModelMode>'TRIGGER_MODEL_MODE_END_USER_ONLY',
      TRIGGER_MODEL_MODE_UNSPECIFIED: <
          ArticleSuggestionConfig_TriggerModelMode>'TRIGGER_MODEL_MODE_UNSPECIFIED',
      values(): Array<ArticleSuggestionConfig_TriggerModelMode> {
        return [
          ArticleSuggestionConfig_TriggerModelModeEnum
              .TRIGGER_MODEL_MODE_UNSPECIFIED,
          ArticleSuggestionConfig_TriggerModelModeEnum
              .TRIGGER_MODEL_MODE_ENABLED,
          ArticleSuggestionConfig_TriggerModelModeEnum
              .TRIGGER_MODEL_MODE_DISABLED,
          ArticleSuggestionConfig_TriggerModelModeEnum
              .TRIGGER_MODEL_MODE_END_USER_ONLY
        ];
      }
    };

export type ArticleSuggestionModelMetadata_TrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'CONVERSATION_GUIDANCE_MODEL'|'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface IArticleSuggestionModelMetadata_TrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly SMART_REPLY_BERT_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly KEY_MOMENT_MODEL: ArticleSuggestionModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly CONVERSATION_GUIDANCE_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly CUSTOM_HEAD_INTENT_SUGGESTION_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_V2_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      ArticleSuggestionModelMetadata_TrainingModelType;

  values(): Array<ArticleSuggestionModelMetadata_TrainingModelType>;
}

export const ArticleSuggestionModelMetadata_TrainingModelTypeEnum:
    IArticleSuggestionModelMetadata_TrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      CONVERSATION_GUIDANCE_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'CONVERSATION_GUIDANCE_MODEL',
      CUSTOM_HEAD_INTENT_SUGGESTION_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL:
          <ArticleSuggestionModelMetadata_TrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED: <
          ArticleSuggestionModelMetadata_TrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL: <
          ArticleSuggestionModelMetadata_TrainingModelType>'SUMMARIZATION_V2_MODEL',
      values(): Array<ArticleSuggestionModelMetadata_TrainingModelType> {
        return [
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .MODEL_TYPE_UNSPECIFIED,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_GBT_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_DUAL_ENCODER_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_BERT_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .SMART_COMPOSE_DUAL_ENCODER_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_GENERATIVE_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .SUMMARIZATION_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum.KEY_MOMENT_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .CONVERSATION_GUIDANCE_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .CUSTOM_HEAD_INTENT_SUGGESTION_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .SUMMARIZATION_V2_MODEL,
          ArticleSuggestionModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
        ];
      }
    };

export type AssociateAllowlistInfo_SourceType =
    'SOURCE_TYPE_UNSPECIFIED'|'AUTOMATIC'|'PRETRAINED';

export interface IAssociateAllowlistInfo_SourceTypeEnum {
  readonly SOURCE_TYPE_UNSPECIFIED: AssociateAllowlistInfo_SourceType;
  readonly AUTOMATIC: AssociateAllowlistInfo_SourceType;
  readonly PRETRAINED: AssociateAllowlistInfo_SourceType;

  values(): Array<AssociateAllowlistInfo_SourceType>;
}

export const AssociateAllowlistInfo_SourceTypeEnum:
    IAssociateAllowlistInfo_SourceTypeEnum = {
      AUTOMATIC: <AssociateAllowlistInfo_SourceType>'AUTOMATIC',
      PRETRAINED: <AssociateAllowlistInfo_SourceType>'PRETRAINED',
      SOURCE_TYPE_UNSPECIFIED:
          <AssociateAllowlistInfo_SourceType>'SOURCE_TYPE_UNSPECIFIED',
      values(): Array<AssociateAllowlistInfo_SourceType> {
        return [
          AssociateAllowlistInfo_SourceTypeEnum.SOURCE_TYPE_UNSPECIFIED,
          AssociateAllowlistInfo_SourceTypeEnum.AUTOMATIC,
          AssociateAllowlistInfo_SourceTypeEnum.PRETRAINED
        ];
      }
    };

export type AutoApproveSmartMessagingEntriesMetadata_State =
    'STATE_UNSPECIFIED'|'INITIALIZING'|'RUNNING'|'CANCELLED'|'SUCCEEDED'|
    'FAILED';

export interface IAutoApproveSmartMessagingEntriesMetadata_StateEnum {
  readonly STATE_UNSPECIFIED: AutoApproveSmartMessagingEntriesMetadata_State;
  readonly INITIALIZING: AutoApproveSmartMessagingEntriesMetadata_State;
  readonly RUNNING: AutoApproveSmartMessagingEntriesMetadata_State;
  readonly CANCELLED: AutoApproveSmartMessagingEntriesMetadata_State;
  readonly SUCCEEDED: AutoApproveSmartMessagingEntriesMetadata_State;
  readonly FAILED: AutoApproveSmartMessagingEntriesMetadata_State;

  values(): Array<AutoApproveSmartMessagingEntriesMetadata_State>;
}

export const AutoApproveSmartMessagingEntriesMetadata_StateEnum:
    IAutoApproveSmartMessagingEntriesMetadata_StateEnum = {
      CANCELLED: <AutoApproveSmartMessagingEntriesMetadata_State>'CANCELLED',
      FAILED: <AutoApproveSmartMessagingEntriesMetadata_State>'FAILED',
      INITIALIZING:
          <AutoApproveSmartMessagingEntriesMetadata_State>'INITIALIZING',
      RUNNING: <AutoApproveSmartMessagingEntriesMetadata_State>'RUNNING',
      STATE_UNSPECIFIED:
          <AutoApproveSmartMessagingEntriesMetadata_State>'STATE_UNSPECIFIED',
      SUCCEEDED: <AutoApproveSmartMessagingEntriesMetadata_State>'SUCCEEDED',
      values(): Array<AutoApproveSmartMessagingEntriesMetadata_State> {
        return [
          AutoApproveSmartMessagingEntriesMetadata_StateEnum.STATE_UNSPECIFIED,
          AutoApproveSmartMessagingEntriesMetadata_StateEnum.INITIALIZING,
          AutoApproveSmartMessagingEntriesMetadata_StateEnum.RUNNING,
          AutoApproveSmartMessagingEntriesMetadata_StateEnum.CANCELLED,
          AutoApproveSmartMessagingEntriesMetadata_StateEnum.SUCCEEDED,
          AutoApproveSmartMessagingEntriesMetadata_StateEnum.FAILED
        ];
      }
    };

export type AutomatedAgentReply_AutomatedAgentReplyType =
    'AUTOMATED_AGENT_REPLY_TYPE_UNSPECIFIED'|'PARTIAL'|'FINAL';

export interface IAutomatedAgentReply_AutomatedAgentReplyTypeEnum {
  readonly AUTOMATED_AGENT_REPLY_TYPE_UNSPECIFIED:
      AutomatedAgentReply_AutomatedAgentReplyType;
  readonly PARTIAL: AutomatedAgentReply_AutomatedAgentReplyType;
  readonly FINAL: AutomatedAgentReply_AutomatedAgentReplyType;

  values(): Array<AutomatedAgentReply_AutomatedAgentReplyType>;
}

export const AutomatedAgentReply_AutomatedAgentReplyTypeEnum:
    IAutomatedAgentReply_AutomatedAgentReplyTypeEnum = {
      AUTOMATED_AGENT_REPLY_TYPE_UNSPECIFIED: <
          AutomatedAgentReply_AutomatedAgentReplyType>'AUTOMATED_AGENT_REPLY_TYPE_UNSPECIFIED',
      FINAL: <AutomatedAgentReply_AutomatedAgentReplyType>'FINAL',
      PARTIAL: <AutomatedAgentReply_AutomatedAgentReplyType>'PARTIAL',
      values(): Array<AutomatedAgentReply_AutomatedAgentReplyType> {
        return [
          AutomatedAgentReply_AutomatedAgentReplyTypeEnum
              .AUTOMATED_AGENT_REPLY_TYPE_UNSPECIFIED,
          AutomatedAgentReply_AutomatedAgentReplyTypeEnum.PARTIAL,
          AutomatedAgentReply_AutomatedAgentReplyTypeEnum.FINAL
        ];
      }
    };

export type BatchUpdateIntentsRequest_IntentView =
    'INTENT_VIEW_UNSPECIFIED'|'INTENT_VIEW_FULL';

export interface IBatchUpdateIntentsRequest_IntentViewEnum {
  readonly INTENT_VIEW_UNSPECIFIED: BatchUpdateIntentsRequest_IntentView;
  readonly INTENT_VIEW_FULL: BatchUpdateIntentsRequest_IntentView;

  values(): Array<BatchUpdateIntentsRequest_IntentView>;
}

export const BatchUpdateIntentsRequest_IntentViewEnum:
    IBatchUpdateIntentsRequest_IntentViewEnum = {
      INTENT_VIEW_FULL:
          <BatchUpdateIntentsRequest_IntentView>'INTENT_VIEW_FULL',
      INTENT_VIEW_UNSPECIFIED:
          <BatchUpdateIntentsRequest_IntentView>'INTENT_VIEW_UNSPECIFIED',
      values(): Array<BatchUpdateIntentsRequest_IntentView> {
        return [
          BatchUpdateIntentsRequest_IntentViewEnum.INTENT_VIEW_UNSPECIFIED,
          BatchUpdateIntentsRequest_IntentViewEnum.INTENT_VIEW_FULL
        ];
      }
    };

export type CallCompanionConversationEventTextMessage_SenderRole =
    'SENDER_ROLE_UNSPECIFIED'|'SENDER_ROLE_END_USER'|
    'SENDER_ROLE_VIRTUAL_AGENT';

export interface ICallCompanionConversationEventTextMessage_SenderRoleEnum {
  readonly SENDER_ROLE_UNSPECIFIED:
      CallCompanionConversationEventTextMessage_SenderRole;
  readonly SENDER_ROLE_END_USER:
      CallCompanionConversationEventTextMessage_SenderRole;
  readonly SENDER_ROLE_VIRTUAL_AGENT:
      CallCompanionConversationEventTextMessage_SenderRole;

  values(): Array<CallCompanionConversationEventTextMessage_SenderRole>;
}

export const CallCompanionConversationEventTextMessage_SenderRoleEnum:
    ICallCompanionConversationEventTextMessage_SenderRoleEnum = {
      SENDER_ROLE_END_USER: <
          CallCompanionConversationEventTextMessage_SenderRole>'SENDER_ROLE_END_USER',
      SENDER_ROLE_UNSPECIFIED: <
          CallCompanionConversationEventTextMessage_SenderRole>'SENDER_ROLE_UNSPECIFIED',
      SENDER_ROLE_VIRTUAL_AGENT: <
          CallCompanionConversationEventTextMessage_SenderRole>'SENDER_ROLE_VIRTUAL_AGENT',
      values(): Array<CallCompanionConversationEventTextMessage_SenderRole> {
        return [
          CallCompanionConversationEventTextMessage_SenderRoleEnum
              .SENDER_ROLE_UNSPECIFIED,
          CallCompanionConversationEventTextMessage_SenderRoleEnum
              .SENDER_ROLE_END_USER,
          CallCompanionConversationEventTextMessage_SenderRoleEnum
              .SENDER_ROLE_VIRTUAL_AGENT
        ];
      }
    };

export type ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface IClearSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum {
  readonly ROLE_UNSPECIFIED:
      ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole;
  readonly HUMAN_AGENT:
      ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole;
  readonly AUTOMATED_AGENT:
      ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole;
  readonly END_USER:
      ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole;

  values():
      Array<ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole>;
}

export const ClearSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum:
    IClearSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum = {
      AUTOMATED_AGENT: <
          ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole>'AUTOMATED_AGENT',
      END_USER: <
          ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole>'END_USER',
      HUMAN_AGENT: <
          ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED: <
          ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole>'ROLE_UNSPECIFIED',
      values():
          Array<ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole> {
            return [
              ClearSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum
                  .ROLE_UNSPECIFIED,
              ClearSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum
                  .HUMAN_AGENT,
              ClearSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum
                  .AUTOMATED_AGENT,
              ClearSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum
                  .END_USER
            ];
          }
    };

export type ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType =
    'TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION'|'FAQ'|'SMART_REPLY'|
    'DIALOGFLOW_ASSIST'|'SMART_COMPOSE'|'ISSUE_SMART_COMPOSE'|
    'SPELL_GRAMMAR_CORRECTION'|'CONVERSATION_SUMMARIZATION'|'KEY_MOMENT'|
    'ARTICLE_SEARCH'|'CONVERSATION_GUIDANCE'|'ARTICLE_SUGGESTION_VOICE'|
    'CONVERSATION_SUMMARIZATION_VOICE';

export interface IClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum {
  readonly TYPE_UNSPECIFIED:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly ARTICLE_SUGGESTION:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly FAQ:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly SMART_REPLY:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly DIALOGFLOW_ASSIST:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly SMART_COMPOSE:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly ISSUE_SMART_COMPOSE:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly SPELL_GRAMMAR_CORRECTION:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly CONVERSATION_SUMMARIZATION:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly KEY_MOMENT:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly ARTICLE_SEARCH:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly CONVERSATION_GUIDANCE:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly ARTICLE_SUGGESTION_VOICE:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly CONVERSATION_SUMMARIZATION_VOICE:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;

  values(): Array<
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>;
}

export const ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum:
    IClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum = {
      ARTICLE_SEARCH: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'ARTICLE_SEARCH',
      ARTICLE_SUGGESTION: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'ARTICLE_SUGGESTION',
      ARTICLE_SUGGESTION_VOICE: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'ARTICLE_SUGGESTION_VOICE',
      CONVERSATION_GUIDANCE: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'CONVERSATION_GUIDANCE',
      CONVERSATION_SUMMARIZATION: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'CONVERSATION_SUMMARIZATION',
      CONVERSATION_SUMMARIZATION_VOICE: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'CONVERSATION_SUMMARIZATION_VOICE',
      DIALOGFLOW_ASSIST: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'DIALOGFLOW_ASSIST',
      FAQ: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'FAQ',
      ISSUE_SMART_COMPOSE: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'ISSUE_SMART_COMPOSE',
      KEY_MOMENT: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'KEY_MOMENT',
      SMART_COMPOSE: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'SMART_COMPOSE',
      SMART_REPLY: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'SMART_REPLY',
      SPELL_GRAMMAR_CORRECTION: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'SPELL_GRAMMAR_CORRECTION',
      TYPE_UNSPECIFIED: <
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'TYPE_UNSPECIFIED',
      values(): Array<
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType> {
        return [
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .TYPE_UNSPECIFIED,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .ARTICLE_SUGGESTION,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .FAQ,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .SMART_REPLY,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .DIALOGFLOW_ASSIST,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .SMART_COMPOSE,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .ISSUE_SMART_COMPOSE,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .SPELL_GRAMMAR_CORRECTION,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .CONVERSATION_SUMMARIZATION,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .KEY_MOMENT,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .ARTICLE_SEARCH,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .CONVERSATION_GUIDANCE,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .ARTICLE_SUGGESTION_VOICE,
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .CONVERSATION_SUMMARIZATION_VOICE
        ];
      }
    };

export type ClearSuggestionFeatureConfigRequest_ParticipantRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface IClearSuggestionFeatureConfigRequest_ParticipantRoleEnum {
  readonly ROLE_UNSPECIFIED:
      ClearSuggestionFeatureConfigRequest_ParticipantRole;
  readonly HUMAN_AGENT: ClearSuggestionFeatureConfigRequest_ParticipantRole;
  readonly AUTOMATED_AGENT: ClearSuggestionFeatureConfigRequest_ParticipantRole;
  readonly END_USER: ClearSuggestionFeatureConfigRequest_ParticipantRole;

  values(): Array<ClearSuggestionFeatureConfigRequest_ParticipantRole>;
}

export const ClearSuggestionFeatureConfigRequest_ParticipantRoleEnum:
    IClearSuggestionFeatureConfigRequest_ParticipantRoleEnum = {
      AUTOMATED_AGENT: <
          ClearSuggestionFeatureConfigRequest_ParticipantRole>'AUTOMATED_AGENT',
      END_USER: <ClearSuggestionFeatureConfigRequest_ParticipantRole>'END_USER',
      HUMAN_AGENT:
          <ClearSuggestionFeatureConfigRequest_ParticipantRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED: <
          ClearSuggestionFeatureConfigRequest_ParticipantRole>'ROLE_UNSPECIFIED',
      values(): Array<ClearSuggestionFeatureConfigRequest_ParticipantRole> {
        return [
          ClearSuggestionFeatureConfigRequest_ParticipantRoleEnum
              .ROLE_UNSPECIFIED,
          ClearSuggestionFeatureConfigRequest_ParticipantRoleEnum.HUMAN_AGENT,
          ClearSuggestionFeatureConfigRequest_ParticipantRoleEnum
              .AUTOMATED_AGENT,
          ClearSuggestionFeatureConfigRequest_ParticipantRoleEnum.END_USER
        ];
      }
    };

export type ClearSuggestionFeatureConfigRequest_SuggestionFeatureType =
    'TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION'|'FAQ'|'SMART_REPLY'|
    'DIALOGFLOW_ASSIST'|'SMART_COMPOSE'|'ISSUE_SMART_COMPOSE'|
    'SPELL_GRAMMAR_CORRECTION'|'CONVERSATION_SUMMARIZATION'|'KEY_MOMENT'|
    'ARTICLE_SEARCH'|'CONVERSATION_GUIDANCE'|'ARTICLE_SUGGESTION_VOICE'|
    'CONVERSATION_SUMMARIZATION_VOICE';

export interface IClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum {
  readonly TYPE_UNSPECIFIED:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly ARTICLE_SUGGESTION:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly FAQ: ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly SMART_REPLY:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly DIALOGFLOW_ASSIST:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly SMART_COMPOSE:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly ISSUE_SMART_COMPOSE:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly SPELL_GRAMMAR_CORRECTION:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly CONVERSATION_SUMMARIZATION:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly KEY_MOMENT:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly ARTICLE_SEARCH:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly CONVERSATION_GUIDANCE:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly ARTICLE_SUGGESTION_VOICE:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;
  readonly CONVERSATION_SUMMARIZATION_VOICE:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType;

  values(): Array<ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>;
}

export const ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum:
    IClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum = {
      ARTICLE_SEARCH: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'ARTICLE_SEARCH',
      ARTICLE_SUGGESTION: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'ARTICLE_SUGGESTION',
      ARTICLE_SUGGESTION_VOICE: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'ARTICLE_SUGGESTION_VOICE',
      CONVERSATION_GUIDANCE: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'CONVERSATION_GUIDANCE',
      CONVERSATION_SUMMARIZATION: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'CONVERSATION_SUMMARIZATION',
      CONVERSATION_SUMMARIZATION_VOICE: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'CONVERSATION_SUMMARIZATION_VOICE',
      DIALOGFLOW_ASSIST: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'DIALOGFLOW_ASSIST',
      FAQ: <ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'FAQ',
      ISSUE_SMART_COMPOSE: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'ISSUE_SMART_COMPOSE',
      KEY_MOMENT: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'KEY_MOMENT',
      SMART_COMPOSE: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'SMART_COMPOSE',
      SMART_REPLY: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'SMART_REPLY',
      SPELL_GRAMMAR_CORRECTION: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'SPELL_GRAMMAR_CORRECTION',
      TYPE_UNSPECIFIED: <
          ClearSuggestionFeatureConfigRequest_SuggestionFeatureType>'TYPE_UNSPECIFIED',
      values():
          Array<ClearSuggestionFeatureConfigRequest_SuggestionFeatureType> {
            return [
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .TYPE_UNSPECIFIED,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .ARTICLE_SUGGESTION,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum.FAQ,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .SMART_REPLY,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .DIALOGFLOW_ASSIST,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .SMART_COMPOSE,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .ISSUE_SMART_COMPOSE,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .SPELL_GRAMMAR_CORRECTION,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .CONVERSATION_SUMMARIZATION,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .KEY_MOMENT,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .ARTICLE_SEARCH,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .CONVERSATION_GUIDANCE,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .ARTICLE_SUGGESTION_VOICE,
              ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
                  .CONVERSATION_SUMMARIZATION_VOICE
            ];
          }
    };

export type CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode =
    'OK'|'CANCELLED'|'UNKNOWN'|'INVALID_ARGUMENT'|'DEADLINE_EXCEEDED'|
    'NOT_FOUND'|'ALREADY_EXISTS'|'PERMISSION_DENIED'|'UNAUTHENTICATED'|
    'RESOURCE_EXHAUSTED'|'FAILED_PRECONDITION'|'ABORTED'|'OUT_OF_RANGE'|
    'UNIMPLEMENTED'|'INTERNAL'|'UNAVAILABLE'|'DATA_LOSS';

export interface ICloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum {
  readonly OK:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly CANCELLED:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly UNKNOWN:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly INVALID_ARGUMENT:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly DEADLINE_EXCEEDED:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly NOT_FOUND:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly ALREADY_EXISTS:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly PERMISSION_DENIED:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly UNAUTHENTICATED:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly RESOURCE_EXHAUSTED:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly FAILED_PRECONDITION:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly ABORTED:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly OUT_OF_RANGE:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly UNIMPLEMENTED:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly INTERNAL:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly UNAVAILABLE:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;
  readonly DATA_LOSS:
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode;

  values(): Array<
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>;
}

export const CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum:
    ICloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum = {
      ABORTED: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'ABORTED',
      ALREADY_EXISTS: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'ALREADY_EXISTS',
      CANCELLED: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'CANCELLED',
      DATA_LOSS: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'DATA_LOSS',
      DEADLINE_EXCEEDED: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'DEADLINE_EXCEEDED',
      FAILED_PRECONDITION: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'FAILED_PRECONDITION',
      INTERNAL: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'INTERNAL',
      INVALID_ARGUMENT: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'INVALID_ARGUMENT',
      NOT_FOUND: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'NOT_FOUND',
      OK: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'OK',
      OUT_OF_RANGE: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'OUT_OF_RANGE',
      PERMISSION_DENIED: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'PERMISSION_DENIED',
      RESOURCE_EXHAUSTED: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'RESOURCE_EXHAUSTED',
      UNAUTHENTICATED: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'UNAUTHENTICATED',
      UNAVAILABLE: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'UNAVAILABLE',
      UNIMPLEMENTED: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'UNIMPLEMENTED',
      UNKNOWN: <
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode>'UNKNOWN',
      values(): Array<
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode> {
        return [
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .OK,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .CANCELLED,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .UNKNOWN,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .INVALID_ARGUMENT,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .DEADLINE_EXCEEDED,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .NOT_FOUND,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .ALREADY_EXISTS,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .PERMISSION_DENIED,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .UNAUTHENTICATED,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .RESOURCE_EXHAUSTED,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .FAILED_PRECONDITION,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .ABORTED,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .OUT_OF_RANGE,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .UNIMPLEMENTED,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .INTERNAL,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .UNAVAILABLE,
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
              .DATA_LOSS
        ];
      }
    };

export type CompleteConversationRequest_Source =
    'SOURCE_UNSPECIFIED'|'ONE_PLATFORM_API'|'TELEPHONY';

export interface ICompleteConversationRequest_SourceEnum {
  readonly SOURCE_UNSPECIFIED: CompleteConversationRequest_Source;
  readonly ONE_PLATFORM_API: CompleteConversationRequest_Source;
  readonly TELEPHONY: CompleteConversationRequest_Source;

  values(): Array<CompleteConversationRequest_Source>;
}

export const CompleteConversationRequest_SourceEnum:
    ICompleteConversationRequest_SourceEnum = {
      ONE_PLATFORM_API: <CompleteConversationRequest_Source>'ONE_PLATFORM_API',
      SOURCE_UNSPECIFIED:
          <CompleteConversationRequest_Source>'SOURCE_UNSPECIFIED',
      TELEPHONY: <CompleteConversationRequest_Source>'TELEPHONY',
      values(): Array<CompleteConversationRequest_Source> {
        return [
          CompleteConversationRequest_SourceEnum.SOURCE_UNSPECIFIED,
          CompleteConversationRequest_SourceEnum.ONE_PLATFORM_API,
          CompleteConversationRequest_SourceEnum.TELEPHONY
        ];
      }
    };

export type ConversationEvent_Type = 'TYPE_UNSPECIFIED'|'CONVERSATION_STARTED'|
    'CONVERSATION_FINISHED'|'HUMAN_INTERVENTION_NEEDED'|'NEW_MESSAGE'|
    'UNRECOVERABLE_ERROR'|'CALL_RECORDING_FINISHED';

export interface IConversationEvent_TypeEnum {
  readonly TYPE_UNSPECIFIED: ConversationEvent_Type;
  readonly CONVERSATION_STARTED: ConversationEvent_Type;
  readonly CONVERSATION_FINISHED: ConversationEvent_Type;
  readonly HUMAN_INTERVENTION_NEEDED: ConversationEvent_Type;
  readonly NEW_MESSAGE: ConversationEvent_Type;
  readonly UNRECOVERABLE_ERROR: ConversationEvent_Type;
  readonly CALL_RECORDING_FINISHED: ConversationEvent_Type;

  values(): Array<ConversationEvent_Type>;
}

export const ConversationEvent_TypeEnum: IConversationEvent_TypeEnum = {
  CALL_RECORDING_FINISHED: <ConversationEvent_Type>'CALL_RECORDING_FINISHED',
  CONVERSATION_FINISHED: <ConversationEvent_Type>'CONVERSATION_FINISHED',
  CONVERSATION_STARTED: <ConversationEvent_Type>'CONVERSATION_STARTED',
  HUMAN_INTERVENTION_NEEDED:
      <ConversationEvent_Type>'HUMAN_INTERVENTION_NEEDED',
  NEW_MESSAGE: <ConversationEvent_Type>'NEW_MESSAGE',
  TYPE_UNSPECIFIED: <ConversationEvent_Type>'TYPE_UNSPECIFIED',
  UNRECOVERABLE_ERROR: <ConversationEvent_Type>'UNRECOVERABLE_ERROR',
  values(): Array<ConversationEvent_Type> {
    return [
      ConversationEvent_TypeEnum.TYPE_UNSPECIFIED,
      ConversationEvent_TypeEnum.CONVERSATION_STARTED,
      ConversationEvent_TypeEnum.CONVERSATION_FINISHED,
      ConversationEvent_TypeEnum.HUMAN_INTERVENTION_NEEDED,
      ConversationEvent_TypeEnum.NEW_MESSAGE,
      ConversationEvent_TypeEnum.UNRECOVERABLE_ERROR,
      ConversationEvent_TypeEnum.CALL_RECORDING_FINISHED
    ];
  }
};

export type ConversationGraphGenerationConfigOverviewConfig_Mode =
    'OVERVIEW_MODE_UNSPECIFIED'|'COMMON_SUBSEQUENCES'|'FREQUENCY_STATS'|
    'LINEAR_ORDERING'|'PARTIAL_LINEAR_ORDERING';

export interface IConversationGraphGenerationConfigOverviewConfig_ModeEnum {
  readonly OVERVIEW_MODE_UNSPECIFIED:
      ConversationGraphGenerationConfigOverviewConfig_Mode;
  readonly COMMON_SUBSEQUENCES:
      ConversationGraphGenerationConfigOverviewConfig_Mode;
  readonly FREQUENCY_STATS:
      ConversationGraphGenerationConfigOverviewConfig_Mode;
  readonly LINEAR_ORDERING:
      ConversationGraphGenerationConfigOverviewConfig_Mode;
  readonly PARTIAL_LINEAR_ORDERING:
      ConversationGraphGenerationConfigOverviewConfig_Mode;

  values(): Array<ConversationGraphGenerationConfigOverviewConfig_Mode>;
}

export const ConversationGraphGenerationConfigOverviewConfig_ModeEnum:
    IConversationGraphGenerationConfigOverviewConfig_ModeEnum = {
      COMMON_SUBSEQUENCES: <
          ConversationGraphGenerationConfigOverviewConfig_Mode>'COMMON_SUBSEQUENCES',
      FREQUENCY_STATS: <
          ConversationGraphGenerationConfigOverviewConfig_Mode>'FREQUENCY_STATS',
      LINEAR_ORDERING: <
          ConversationGraphGenerationConfigOverviewConfig_Mode>'LINEAR_ORDERING',
      OVERVIEW_MODE_UNSPECIFIED: <
          ConversationGraphGenerationConfigOverviewConfig_Mode>'OVERVIEW_MODE_UNSPECIFIED',
      PARTIAL_LINEAR_ORDERING: <
          ConversationGraphGenerationConfigOverviewConfig_Mode>'PARTIAL_LINEAR_ORDERING',
      values(): Array<ConversationGraphGenerationConfigOverviewConfig_Mode> {
        return [
          ConversationGraphGenerationConfigOverviewConfig_ModeEnum
              .OVERVIEW_MODE_UNSPECIFIED,
          ConversationGraphGenerationConfigOverviewConfig_ModeEnum
              .COMMON_SUBSEQUENCES,
          ConversationGraphGenerationConfigOverviewConfig_ModeEnum
              .FREQUENCY_STATS,
          ConversationGraphGenerationConfigOverviewConfig_ModeEnum
              .LINEAR_ORDERING,
          ConversationGraphGenerationConfigOverviewConfig_ModeEnum
              .PARTIAL_LINEAR_ORDERING
        ];
      }
    };

export type ConversationGraphGenerationConfig_RoleToKeep =
    'CONVERSATION_ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER'|
    'SYSTEM'|'ROLE_UNKNOWN';

export interface IConversationGraphGenerationConfig_RoleToKeepEnum {
  readonly CONVERSATION_ROLE_UNSPECIFIED:
      ConversationGraphGenerationConfig_RoleToKeep;
  readonly HUMAN_AGENT: ConversationGraphGenerationConfig_RoleToKeep;
  readonly AUTOMATED_AGENT: ConversationGraphGenerationConfig_RoleToKeep;
  readonly END_USER: ConversationGraphGenerationConfig_RoleToKeep;
  readonly SYSTEM: ConversationGraphGenerationConfig_RoleToKeep;
  readonly ROLE_UNKNOWN: ConversationGraphGenerationConfig_RoleToKeep;

  values(): Array<ConversationGraphGenerationConfig_RoleToKeep>;
}

export const ConversationGraphGenerationConfig_RoleToKeepEnum:
    IConversationGraphGenerationConfig_RoleToKeepEnum = {
      AUTOMATED_AGENT:
          <ConversationGraphGenerationConfig_RoleToKeep>'AUTOMATED_AGENT',
      CONVERSATION_ROLE_UNSPECIFIED: <
          ConversationGraphGenerationConfig_RoleToKeep>'CONVERSATION_ROLE_UNSPECIFIED',
      END_USER: <ConversationGraphGenerationConfig_RoleToKeep>'END_USER',
      HUMAN_AGENT: <ConversationGraphGenerationConfig_RoleToKeep>'HUMAN_AGENT',
      ROLE_UNKNOWN:
          <ConversationGraphGenerationConfig_RoleToKeep>'ROLE_UNKNOWN',
      SYSTEM: <ConversationGraphGenerationConfig_RoleToKeep>'SYSTEM',
      values(): Array<ConversationGraphGenerationConfig_RoleToKeep> {
        return [
          ConversationGraphGenerationConfig_RoleToKeepEnum
              .CONVERSATION_ROLE_UNSPECIFIED,
          ConversationGraphGenerationConfig_RoleToKeepEnum.HUMAN_AGENT,
          ConversationGraphGenerationConfig_RoleToKeepEnum.AUTOMATED_AGENT,
          ConversationGraphGenerationConfig_RoleToKeepEnum.END_USER,
          ConversationGraphGenerationConfig_RoleToKeepEnum.SYSTEM,
          ConversationGraphGenerationConfig_RoleToKeepEnum.ROLE_UNKNOWN
        ];
      }
    };

export type ConversationGuidanceModelMetadata_TrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'CONVERSATION_GUIDANCE_MODEL'|'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface IConversationGuidanceModelMetadata_TrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly SMART_REPLY_BERT_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly KEY_MOMENT_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly CONVERSATION_GUIDANCE_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly CUSTOM_HEAD_INTENT_SUGGESTION_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_V2_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      ConversationGuidanceModelMetadata_TrainingModelType;

  values(): Array<ConversationGuidanceModelMetadata_TrainingModelType>;
}

export const ConversationGuidanceModelMetadata_TrainingModelTypeEnum:
    IConversationGuidanceModelMetadata_TrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      CONVERSATION_GUIDANCE_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'CONVERSATION_GUIDANCE_MODEL',
      CUSTOM_HEAD_INTENT_SUGGESTION_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED: <
          ConversationGuidanceModelMetadata_TrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL: <
          ConversationGuidanceModelMetadata_TrainingModelType>'SUMMARIZATION_V2_MODEL',
      values(): Array<ConversationGuidanceModelMetadata_TrainingModelType> {
        return [
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .MODEL_TYPE_UNSPECIFIED,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_GBT_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_DUAL_ENCODER_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_BERT_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .SMART_COMPOSE_DUAL_ENCODER_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_GENERATIVE_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .SUMMARIZATION_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .KEY_MOMENT_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .CONVERSATION_GUIDANCE_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .CUSTOM_HEAD_INTENT_SUGGESTION_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .SUMMARIZATION_V2_MODEL,
          ConversationGuidanceModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
        ];
      }
    };

export type ConversationModelSuggestionList_LinkDatasetType =
    'LINK_DATASET_TYPE_UNSPECIFIED'|'LINK_ARTICLE_SUGGESTION'|
    'LINK_SMART_REPLY_DATASET'|'LINK_SMART_COMPOSE_DATASET'|
    'LINK_ISSUE_MODELING_DATASET';

export interface IConversationModelSuggestionList_LinkDatasetTypeEnum {
  readonly LINK_DATASET_TYPE_UNSPECIFIED:
      ConversationModelSuggestionList_LinkDatasetType;
  readonly LINK_ARTICLE_SUGGESTION:
      ConversationModelSuggestionList_LinkDatasetType;
  readonly LINK_SMART_REPLY_DATASET:
      ConversationModelSuggestionList_LinkDatasetType;
  readonly LINK_SMART_COMPOSE_DATASET:
      ConversationModelSuggestionList_LinkDatasetType;
  readonly LINK_ISSUE_MODELING_DATASET:
      ConversationModelSuggestionList_LinkDatasetType;

  values(): Array<ConversationModelSuggestionList_LinkDatasetType>;
}

export const ConversationModelSuggestionList_LinkDatasetTypeEnum:
    IConversationModelSuggestionList_LinkDatasetTypeEnum = {
      LINK_ARTICLE_SUGGESTION: <
          ConversationModelSuggestionList_LinkDatasetType>'LINK_ARTICLE_SUGGESTION',
      LINK_DATASET_TYPE_UNSPECIFIED: <
          ConversationModelSuggestionList_LinkDatasetType>'LINK_DATASET_TYPE_UNSPECIFIED',
      LINK_ISSUE_MODELING_DATASET: <
          ConversationModelSuggestionList_LinkDatasetType>'LINK_ISSUE_MODELING_DATASET',
      LINK_SMART_COMPOSE_DATASET: <
          ConversationModelSuggestionList_LinkDatasetType>'LINK_SMART_COMPOSE_DATASET',
      LINK_SMART_REPLY_DATASET: <
          ConversationModelSuggestionList_LinkDatasetType>'LINK_SMART_REPLY_DATASET',
      values(): Array<ConversationModelSuggestionList_LinkDatasetType> {
        return [
          ConversationModelSuggestionList_LinkDatasetTypeEnum
              .LINK_DATASET_TYPE_UNSPECIFIED,
          ConversationModelSuggestionList_LinkDatasetTypeEnum
              .LINK_ARTICLE_SUGGESTION,
          ConversationModelSuggestionList_LinkDatasetTypeEnum
              .LINK_SMART_REPLY_DATASET,
          ConversationModelSuggestionList_LinkDatasetTypeEnum
              .LINK_SMART_COMPOSE_DATASET,
          ConversationModelSuggestionList_LinkDatasetTypeEnum
              .LINK_ISSUE_MODELING_DATASET
        ];
      }
    };

export type ConversationModelSuggestionList_ReadinessState =
    'READINESS_STATE_UNSPECIFIED'|'NOT_ENCODED'|'ENCODED'|'SCAM_SERVING_READY';

export interface IConversationModelSuggestionList_ReadinessStateEnum {
  readonly READINESS_STATE_UNSPECIFIED:
      ConversationModelSuggestionList_ReadinessState;
  readonly NOT_ENCODED: ConversationModelSuggestionList_ReadinessState;
  readonly ENCODED: ConversationModelSuggestionList_ReadinessState;
  readonly SCAM_SERVING_READY: ConversationModelSuggestionList_ReadinessState;

  values(): Array<ConversationModelSuggestionList_ReadinessState>;
}

export const ConversationModelSuggestionList_ReadinessStateEnum:
    IConversationModelSuggestionList_ReadinessStateEnum = {
      ENCODED: <ConversationModelSuggestionList_ReadinessState>'ENCODED',
      NOT_ENCODED:
          <ConversationModelSuggestionList_ReadinessState>'NOT_ENCODED',
      READINESS_STATE_UNSPECIFIED: <
          ConversationModelSuggestionList_ReadinessState>'READINESS_STATE_UNSPECIFIED',
      SCAM_SERVING_READY:
          <ConversationModelSuggestionList_ReadinessState>'SCAM_SERVING_READY',
      values(): Array<ConversationModelSuggestionList_ReadinessState> {
        return [
          ConversationModelSuggestionList_ReadinessStateEnum
              .READINESS_STATE_UNSPECIFIED,
          ConversationModelSuggestionList_ReadinessStateEnum.NOT_ENCODED,
          ConversationModelSuggestionList_ReadinessStateEnum.ENCODED,
          ConversationModelSuggestionList_ReadinessStateEnum.SCAM_SERVING_READY
        ];
      }
    };

export type ConversationModel_State =
    'STATE_UNSPECIFIED'|'CREATING'|'UNDEPLOYED'|'DEPLOYING'|'DEPLOYED'|
    'UNDEPLOYING'|'DELETING'|'FAILED'|'PENDING';

export interface IConversationModel_StateEnum {
  readonly STATE_UNSPECIFIED: ConversationModel_State;
  readonly CREATING: ConversationModel_State;
  readonly UNDEPLOYED: ConversationModel_State;
  readonly DEPLOYING: ConversationModel_State;
  readonly DEPLOYED: ConversationModel_State;
  readonly UNDEPLOYING: ConversationModel_State;
  readonly DELETING: ConversationModel_State;
  readonly FAILED: ConversationModel_State;
  readonly PENDING: ConversationModel_State;

  values(): Array<ConversationModel_State>;
}

export const ConversationModel_StateEnum: IConversationModel_StateEnum = {
  CREATING: <ConversationModel_State>'CREATING',
  DELETING: <ConversationModel_State>'DELETING',
  DEPLOYED: <ConversationModel_State>'DEPLOYED',
  DEPLOYING: <ConversationModel_State>'DEPLOYING',
  FAILED: <ConversationModel_State>'FAILED',
  PENDING: <ConversationModel_State>'PENDING',
  STATE_UNSPECIFIED: <ConversationModel_State>'STATE_UNSPECIFIED',
  UNDEPLOYED: <ConversationModel_State>'UNDEPLOYED',
  UNDEPLOYING: <ConversationModel_State>'UNDEPLOYING',
  values(): Array<ConversationModel_State> {
    return [
      ConversationModel_StateEnum.STATE_UNSPECIFIED,
      ConversationModel_StateEnum.CREATING,
      ConversationModel_StateEnum.UNDEPLOYED,
      ConversationModel_StateEnum.DEPLOYING,
      ConversationModel_StateEnum.DEPLOYED,
      ConversationModel_StateEnum.UNDEPLOYING,
      ConversationModel_StateEnum.DELETING, ConversationModel_StateEnum.FAILED,
      ConversationModel_StateEnum.PENDING
    ];
  }
};

export type ConversationParticipant_Role =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER'|'ANY_AGENT';

export interface IConversationParticipant_RoleEnum {
  readonly ROLE_UNSPECIFIED: ConversationParticipant_Role;
  readonly HUMAN_AGENT: ConversationParticipant_Role;
  readonly AUTOMATED_AGENT: ConversationParticipant_Role;
  readonly END_USER: ConversationParticipant_Role;
  readonly ANY_AGENT: ConversationParticipant_Role;

  values(): Array<ConversationParticipant_Role>;
}

export const ConversationParticipant_RoleEnum:
    IConversationParticipant_RoleEnum = {
      ANY_AGENT: <ConversationParticipant_Role>'ANY_AGENT',
      AUTOMATED_AGENT: <ConversationParticipant_Role>'AUTOMATED_AGENT',
      END_USER: <ConversationParticipant_Role>'END_USER',
      HUMAN_AGENT: <ConversationParticipant_Role>'HUMAN_AGENT',
      ROLE_UNSPECIFIED: <ConversationParticipant_Role>'ROLE_UNSPECIFIED',
      values(): Array<ConversationParticipant_Role> {
        return [
          ConversationParticipant_RoleEnum.ROLE_UNSPECIFIED,
          ConversationParticipant_RoleEnum.HUMAN_AGENT,
          ConversationParticipant_RoleEnum.AUTOMATED_AGENT,
          ConversationParticipant_RoleEnum.END_USER,
          ConversationParticipant_RoleEnum.ANY_AGENT
        ];
      }
    };

export type ConversationTelephonyConnectionInfoConversationLinkInfo_Method =
    'METHOD_UNSPECIFIED'|'IMPLICIT_CONVERSATION_CREATION'|'CALL_MATCHER'|
    'PROXY_NUMBER'|'PASSED_CONVERSATION_ID_IN_CALL_INFO_HEADER'|
    'PASSED_CONVERSATION_ID_IN_UUI_HEADER'|
    'PASSED_CONVERSATION_ID_IN_SIPREC_METADATA';

export interface IConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum {
  readonly METHOD_UNSPECIFIED:
      ConversationTelephonyConnectionInfoConversationLinkInfo_Method;
  readonly IMPLICIT_CONVERSATION_CREATION:
      ConversationTelephonyConnectionInfoConversationLinkInfo_Method;
  readonly CALL_MATCHER:
      ConversationTelephonyConnectionInfoConversationLinkInfo_Method;
  readonly PROXY_NUMBER:
      ConversationTelephonyConnectionInfoConversationLinkInfo_Method;
  readonly PASSED_CONVERSATION_ID_IN_CALL_INFO_HEADER:
      ConversationTelephonyConnectionInfoConversationLinkInfo_Method;
  readonly PASSED_CONVERSATION_ID_IN_UUI_HEADER:
      ConversationTelephonyConnectionInfoConversationLinkInfo_Method;
  readonly PASSED_CONVERSATION_ID_IN_SIPREC_METADATA:
      ConversationTelephonyConnectionInfoConversationLinkInfo_Method;

  values():
      Array<ConversationTelephonyConnectionInfoConversationLinkInfo_Method>;
}

export const ConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum:
    IConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum = {
      CALL_MATCHER: <
          ConversationTelephonyConnectionInfoConversationLinkInfo_Method>'CALL_MATCHER',
      IMPLICIT_CONVERSATION_CREATION: <
          ConversationTelephonyConnectionInfoConversationLinkInfo_Method>'IMPLICIT_CONVERSATION_CREATION',
      METHOD_UNSPECIFIED: <
          ConversationTelephonyConnectionInfoConversationLinkInfo_Method>'METHOD_UNSPECIFIED',
      PASSED_CONVERSATION_ID_IN_CALL_INFO_HEADER: <
          ConversationTelephonyConnectionInfoConversationLinkInfo_Method>'PASSED_CONVERSATION_ID_IN_CALL_INFO_HEADER',
      PASSED_CONVERSATION_ID_IN_SIPREC_METADATA: <
          ConversationTelephonyConnectionInfoConversationLinkInfo_Method>'PASSED_CONVERSATION_ID_IN_SIPREC_METADATA',
      PASSED_CONVERSATION_ID_IN_UUI_HEADER: <
          ConversationTelephonyConnectionInfoConversationLinkInfo_Method>'PASSED_CONVERSATION_ID_IN_UUI_HEADER',
      PROXY_NUMBER: <
          ConversationTelephonyConnectionInfoConversationLinkInfo_Method>'PROXY_NUMBER',
      values(): Array<
          ConversationTelephonyConnectionInfoConversationLinkInfo_Method> {
        return [
          ConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum
              .METHOD_UNSPECIFIED,
          ConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum
              .IMPLICIT_CONVERSATION_CREATION,
          ConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum
              .CALL_MATCHER,
          ConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum
              .PROXY_NUMBER,
          ConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum
              .PASSED_CONVERSATION_ID_IN_CALL_INFO_HEADER,
          ConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum
              .PASSED_CONVERSATION_ID_IN_UUI_HEADER,
          ConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum
              .PASSED_CONVERSATION_ID_IN_SIPREC_METADATA
        ];
      }
    };

export type ConversationTelephonyConnectionInfoEndpointInfo_State =
    'ENDPOINT_STATE_UNSPECIFIED'|'ESTABLISHED'|'IN_PROGRESS'|'DROPPED';

export interface IConversationTelephonyConnectionInfoEndpointInfo_StateEnum {
  readonly ENDPOINT_STATE_UNSPECIFIED:
      ConversationTelephonyConnectionInfoEndpointInfo_State;
  readonly ESTABLISHED: ConversationTelephonyConnectionInfoEndpointInfo_State;
  readonly IN_PROGRESS: ConversationTelephonyConnectionInfoEndpointInfo_State;
  readonly DROPPED: ConversationTelephonyConnectionInfoEndpointInfo_State;

  values(): Array<ConversationTelephonyConnectionInfoEndpointInfo_State>;
}

export const ConversationTelephonyConnectionInfoEndpointInfo_StateEnum:
    IConversationTelephonyConnectionInfoEndpointInfo_StateEnum = {
      DROPPED: <ConversationTelephonyConnectionInfoEndpointInfo_State>'DROPPED',
      ENDPOINT_STATE_UNSPECIFIED: <
          ConversationTelephonyConnectionInfoEndpointInfo_State>'ENDPOINT_STATE_UNSPECIFIED',
      ESTABLISHED:
          <ConversationTelephonyConnectionInfoEndpointInfo_State>'ESTABLISHED',
      IN_PROGRESS:
          <ConversationTelephonyConnectionInfoEndpointInfo_State>'IN_PROGRESS',
      values(): Array<ConversationTelephonyConnectionInfoEndpointInfo_State> {
        return [
          ConversationTelephonyConnectionInfoEndpointInfo_StateEnum
              .ENDPOINT_STATE_UNSPECIFIED,
          ConversationTelephonyConnectionInfoEndpointInfo_StateEnum.ESTABLISHED,
          ConversationTelephonyConnectionInfoEndpointInfo_StateEnum.IN_PROGRESS,
          ConversationTelephonyConnectionInfoEndpointInfo_StateEnum.DROPPED
        ];
      }
    };

export type ConversationTelephonyConnectionInfo_CallState =
    'CALL_STATE_UNSPECIFIED'|'ESTABLISHED'|'DECLINED';

export interface IConversationTelephonyConnectionInfo_CallStateEnum {
  readonly CALL_STATE_UNSPECIFIED:
      ConversationTelephonyConnectionInfo_CallState;
  readonly ESTABLISHED: ConversationTelephonyConnectionInfo_CallState;
  readonly DECLINED: ConversationTelephonyConnectionInfo_CallState;

  values(): Array<ConversationTelephonyConnectionInfo_CallState>;
}

export const ConversationTelephonyConnectionInfo_CallStateEnum:
    IConversationTelephonyConnectionInfo_CallStateEnum = {
      CALL_STATE_UNSPECIFIED: <
          ConversationTelephonyConnectionInfo_CallState>'CALL_STATE_UNSPECIFIED',
      DECLINED: <ConversationTelephonyConnectionInfo_CallState>'DECLINED',
      ESTABLISHED: <ConversationTelephonyConnectionInfo_CallState>'ESTABLISHED',
      values(): Array<ConversationTelephonyConnectionInfo_CallState> {
        return [
          ConversationTelephonyConnectionInfo_CallStateEnum
              .CALL_STATE_UNSPECIFIED,
          ConversationTelephonyConnectionInfo_CallStateEnum.ESTABLISHED,
          ConversationTelephonyConnectionInfo_CallStateEnum.DECLINED
        ];
      }
    };

export type ConversationTurn_Role = 'CONVERSATION_ROLE_UNSPECIFIED'|
    'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER'|'SYSTEM'|'ROLE_UNKNOWN';

export interface IConversationTurn_RoleEnum {
  readonly CONVERSATION_ROLE_UNSPECIFIED: ConversationTurn_Role;
  readonly HUMAN_AGENT: ConversationTurn_Role;
  readonly AUTOMATED_AGENT: ConversationTurn_Role;
  readonly END_USER: ConversationTurn_Role;
  readonly SYSTEM: ConversationTurn_Role;
  readonly ROLE_UNKNOWN: ConversationTurn_Role;

  values(): Array<ConversationTurn_Role>;
}

export const ConversationTurn_RoleEnum: IConversationTurn_RoleEnum = {
  AUTOMATED_AGENT: <ConversationTurn_Role>'AUTOMATED_AGENT',
  CONVERSATION_ROLE_UNSPECIFIED:
      <ConversationTurn_Role>'CONVERSATION_ROLE_UNSPECIFIED',
  END_USER: <ConversationTurn_Role>'END_USER',
  HUMAN_AGENT: <ConversationTurn_Role>'HUMAN_AGENT',
  ROLE_UNKNOWN: <ConversationTurn_Role>'ROLE_UNKNOWN',
  SYSTEM: <ConversationTurn_Role>'SYSTEM',
  values(): Array<ConversationTurn_Role> {
    return [
      ConversationTurn_RoleEnum.CONVERSATION_ROLE_UNSPECIFIED,
      ConversationTurn_RoleEnum.HUMAN_AGENT,
      ConversationTurn_RoleEnum.AUTOMATED_AGENT,
      ConversationTurn_RoleEnum.END_USER, ConversationTurn_RoleEnum.SYSTEM,
      ConversationTurn_RoleEnum.ROLE_UNKNOWN
    ];
  }
};

export type Conversation_ConversationStage =
    'CONVERSATION_STAGE_UNSPECIFIED'|'VIRTUAL_AGENT_STAGE'|'HUMAN_ASSIST_STAGE';

export interface IConversation_ConversationStageEnum {
  readonly CONVERSATION_STAGE_UNSPECIFIED: Conversation_ConversationStage;
  readonly VIRTUAL_AGENT_STAGE: Conversation_ConversationStage;
  readonly HUMAN_ASSIST_STAGE: Conversation_ConversationStage;

  values(): Array<Conversation_ConversationStage>;
}

export const Conversation_ConversationStageEnum:
    IConversation_ConversationStageEnum = {
      CONVERSATION_STAGE_UNSPECIFIED:
          <Conversation_ConversationStage>'CONVERSATION_STAGE_UNSPECIFIED',
      HUMAN_ASSIST_STAGE: <Conversation_ConversationStage>'HUMAN_ASSIST_STAGE',
      VIRTUAL_AGENT_STAGE:
          <Conversation_ConversationStage>'VIRTUAL_AGENT_STAGE',
      values(): Array<Conversation_ConversationStage> {
        return [
          Conversation_ConversationStageEnum.CONVERSATION_STAGE_UNSPECIFIED,
          Conversation_ConversationStageEnum.VIRTUAL_AGENT_STAGE,
          Conversation_ConversationStageEnum.HUMAN_ASSIST_STAGE
        ];
      }
    };

export type Conversation_ConversationType =
    'CONVERSATION_TYPE_UNSPECIFIED'|'CHAT'|'VOICE';

export interface IConversation_ConversationTypeEnum {
  readonly CONVERSATION_TYPE_UNSPECIFIED: Conversation_ConversationType;
  readonly CHAT: Conversation_ConversationType;
  readonly VOICE: Conversation_ConversationType;

  values(): Array<Conversation_ConversationType>;
}

export const Conversation_ConversationTypeEnum:
    IConversation_ConversationTypeEnum = {
      CHAT: <Conversation_ConversationType>'CHAT',
      CONVERSATION_TYPE_UNSPECIFIED:
          <Conversation_ConversationType>'CONVERSATION_TYPE_UNSPECIFIED',
      VOICE: <Conversation_ConversationType>'VOICE',
      values(): Array<Conversation_ConversationType> {
        return [
          Conversation_ConversationTypeEnum.CONVERSATION_TYPE_UNSPECIFIED,
          Conversation_ConversationTypeEnum.CHAT,
          Conversation_ConversationTypeEnum.VOICE
        ];
      }
    };

export type Conversation_LifecycleState =
    'LIFECYCLE_STATE_UNSPECIFIED'|'IN_PROGRESS'|'COMPLETED';

export interface IConversation_LifecycleStateEnum {
  readonly LIFECYCLE_STATE_UNSPECIFIED: Conversation_LifecycleState;
  readonly IN_PROGRESS: Conversation_LifecycleState;
  readonly COMPLETED: Conversation_LifecycleState;

  values(): Array<Conversation_LifecycleState>;
}

export const Conversation_LifecycleStateEnum:
    IConversation_LifecycleStateEnum = {
      COMPLETED: <Conversation_LifecycleState>'COMPLETED',
      IN_PROGRESS: <Conversation_LifecycleState>'IN_PROGRESS',
      LIFECYCLE_STATE_UNSPECIFIED:
          <Conversation_LifecycleState>'LIFECYCLE_STATE_UNSPECIFIED',
      values(): Array<Conversation_LifecycleState> {
        return [
          Conversation_LifecycleStateEnum.LIFECYCLE_STATE_UNSPECIFIED,
          Conversation_LifecycleStateEnum.IN_PROGRESS,
          Conversation_LifecycleStateEnum.COMPLETED
        ];
      }
    };

export type Conversation_Source =
    'SOURCE_UNSPECIFIED'|'ONE_PLATFORM_API'|'TELEPHONY';

export interface IConversation_SourceEnum {
  readonly SOURCE_UNSPECIFIED: Conversation_Source;
  readonly ONE_PLATFORM_API: Conversation_Source;
  readonly TELEPHONY: Conversation_Source;

  values(): Array<Conversation_Source>;
}

export const Conversation_SourceEnum: IConversation_SourceEnum = {
  ONE_PLATFORM_API: <Conversation_Source>'ONE_PLATFORM_API',
  SOURCE_UNSPECIFIED: <Conversation_Source>'SOURCE_UNSPECIFIED',
  TELEPHONY: <Conversation_Source>'TELEPHONY',
  values(): Array<Conversation_Source> {
    return [
      Conversation_SourceEnum.SOURCE_UNSPECIFIED,
      Conversation_SourceEnum.ONE_PLATFORM_API,
      Conversation_SourceEnum.TELEPHONY
    ];
  }
};

export type Conversation_SpeechModelVariant =
    'SPEECH_MODEL_VARIANT_UNSPECIFIED'|'USE_BEST_AVAILABLE'|'USE_STANDARD'|
    'USE_ENHANCED';

export interface IConversation_SpeechModelVariantEnum {
  readonly SPEECH_MODEL_VARIANT_UNSPECIFIED: Conversation_SpeechModelVariant;
  readonly USE_BEST_AVAILABLE: Conversation_SpeechModelVariant;
  readonly USE_STANDARD: Conversation_SpeechModelVariant;
  readonly USE_ENHANCED: Conversation_SpeechModelVariant;

  values(): Array<Conversation_SpeechModelVariant>;
}

export const Conversation_SpeechModelVariantEnum:
    IConversation_SpeechModelVariantEnum = {
      SPEECH_MODEL_VARIANT_UNSPECIFIED:
          <Conversation_SpeechModelVariant>'SPEECH_MODEL_VARIANT_UNSPECIFIED',
      USE_BEST_AVAILABLE: <Conversation_SpeechModelVariant>'USE_BEST_AVAILABLE',
      USE_ENHANCED: <Conversation_SpeechModelVariant>'USE_ENHANCED',
      USE_STANDARD: <Conversation_SpeechModelVariant>'USE_STANDARD',
      values(): Array<Conversation_SpeechModelVariant> {
        return [
          Conversation_SpeechModelVariantEnum.SPEECH_MODEL_VARIANT_UNSPECIFIED,
          Conversation_SpeechModelVariantEnum.USE_BEST_AVAILABLE,
          Conversation_SpeechModelVariantEnum.USE_STANDARD,
          Conversation_SpeechModelVariantEnum.USE_ENHANCED
        ];
      }
    };

export type Conversation_TranscribeMode =
    'TRANSCRIBE_MODE_UNSPECIFIED'|'TRANSCRIBE_ON'|'TRANSCRIBE_OFF';

export interface IConversation_TranscribeModeEnum {
  readonly TRANSCRIBE_MODE_UNSPECIFIED: Conversation_TranscribeMode;
  readonly TRANSCRIBE_ON: Conversation_TranscribeMode;
  readonly TRANSCRIBE_OFF: Conversation_TranscribeMode;

  values(): Array<Conversation_TranscribeMode>;
}

export const Conversation_TranscribeModeEnum:
    IConversation_TranscribeModeEnum = {
      TRANSCRIBE_MODE_UNSPECIFIED:
          <Conversation_TranscribeMode>'TRANSCRIBE_MODE_UNSPECIFIED',
      TRANSCRIBE_OFF: <Conversation_TranscribeMode>'TRANSCRIBE_OFF',
      TRANSCRIBE_ON: <Conversation_TranscribeMode>'TRANSCRIBE_ON',
      values(): Array<Conversation_TranscribeMode> {
        return [
          Conversation_TranscribeModeEnum.TRANSCRIBE_MODE_UNSPECIFIED,
          Conversation_TranscribeModeEnum.TRANSCRIBE_ON,
          Conversation_TranscribeModeEnum.TRANSCRIBE_OFF
        ];
      }
    };

export type CreateConversationModelEvaluationOperationMetadata_State =
    'STATE_UNSPECIFIED'|'INITIALIZING'|'RUNNING'|'CANCELLED'|'SUCCEEDED'|
    'FAILED';

export interface ICreateConversationModelEvaluationOperationMetadata_StateEnum {
  readonly STATE_UNSPECIFIED:
      CreateConversationModelEvaluationOperationMetadata_State;
  readonly INITIALIZING:
      CreateConversationModelEvaluationOperationMetadata_State;
  readonly RUNNING: CreateConversationModelEvaluationOperationMetadata_State;
  readonly CANCELLED: CreateConversationModelEvaluationOperationMetadata_State;
  readonly SUCCEEDED: CreateConversationModelEvaluationOperationMetadata_State;
  readonly FAILED: CreateConversationModelEvaluationOperationMetadata_State;

  values(): Array<CreateConversationModelEvaluationOperationMetadata_State>;
}

export const CreateConversationModelEvaluationOperationMetadata_StateEnum:
    ICreateConversationModelEvaluationOperationMetadata_StateEnum = {
      CANCELLED:
          <CreateConversationModelEvaluationOperationMetadata_State>'CANCELLED',
      FAILED:
          <CreateConversationModelEvaluationOperationMetadata_State>'FAILED',
      INITIALIZING: <
          CreateConversationModelEvaluationOperationMetadata_State>'INITIALIZING',
      RUNNING:
          <CreateConversationModelEvaluationOperationMetadata_State>'RUNNING',
      STATE_UNSPECIFIED: <
          CreateConversationModelEvaluationOperationMetadata_State>'STATE_UNSPECIFIED',
      SUCCEEDED:
          <CreateConversationModelEvaluationOperationMetadata_State>'SUCCEEDED',
      values(): Array<
          CreateConversationModelEvaluationOperationMetadata_State> {
        return [
          CreateConversationModelEvaluationOperationMetadata_StateEnum
              .STATE_UNSPECIFIED,
          CreateConversationModelEvaluationOperationMetadata_StateEnum
              .INITIALIZING,
          CreateConversationModelEvaluationOperationMetadata_StateEnum.RUNNING,
          CreateConversationModelEvaluationOperationMetadata_StateEnum
              .CANCELLED,
          CreateConversationModelEvaluationOperationMetadata_StateEnum
              .SUCCEEDED,
          CreateConversationModelEvaluationOperationMetadata_StateEnum.FAILED
        ];
      }
    };

export type CreateConversationModelOperationMetadata_State =
    'STATE_UNSPECIFIED'|'PENDING'|'SUCCEEDED'|'FAILED'|'CANCELLED'|'CANCELLING'|
    'TRAINING';

export interface ICreateConversationModelOperationMetadata_StateEnum {
  readonly STATE_UNSPECIFIED: CreateConversationModelOperationMetadata_State;
  readonly PENDING: CreateConversationModelOperationMetadata_State;
  readonly SUCCEEDED: CreateConversationModelOperationMetadata_State;
  readonly FAILED: CreateConversationModelOperationMetadata_State;
  readonly CANCELLED: CreateConversationModelOperationMetadata_State;
  readonly CANCELLING: CreateConversationModelOperationMetadata_State;
  readonly TRAINING: CreateConversationModelOperationMetadata_State;

  values(): Array<CreateConversationModelOperationMetadata_State>;
}

export const CreateConversationModelOperationMetadata_StateEnum:
    ICreateConversationModelOperationMetadata_StateEnum = {
      CANCELLED: <CreateConversationModelOperationMetadata_State>'CANCELLED',
      CANCELLING: <CreateConversationModelOperationMetadata_State>'CANCELLING',
      FAILED: <CreateConversationModelOperationMetadata_State>'FAILED',
      PENDING: <CreateConversationModelOperationMetadata_State>'PENDING',
      STATE_UNSPECIFIED:
          <CreateConversationModelOperationMetadata_State>'STATE_UNSPECIFIED',
      SUCCEEDED: <CreateConversationModelOperationMetadata_State>'SUCCEEDED',
      TRAINING: <CreateConversationModelOperationMetadata_State>'TRAINING',
      values(): Array<CreateConversationModelOperationMetadata_State> {
        return [
          CreateConversationModelOperationMetadata_StateEnum.STATE_UNSPECIFIED,
          CreateConversationModelOperationMetadata_StateEnum.PENDING,
          CreateConversationModelOperationMetadata_StateEnum.SUCCEEDED,
          CreateConversationModelOperationMetadata_StateEnum.FAILED,
          CreateConversationModelOperationMetadata_StateEnum.CANCELLED,
          CreateConversationModelOperationMetadata_StateEnum.CANCELLING,
          CreateConversationModelOperationMetadata_StateEnum.TRAINING
        ];
      }
    };

export type CustomHeadIntentSuggestionModelMetadata_TrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'CONVERSATION_GUIDANCE_MODEL'|'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface ICustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly SMART_REPLY_BERT_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly KEY_MOMENT_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly CONVERSATION_GUIDANCE_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly CUSTOM_HEAD_INTENT_SUGGESTION_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_V2_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType;

  values(): Array<CustomHeadIntentSuggestionModelMetadata_TrainingModelType>;
}

export const CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum:
    ICustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      CONVERSATION_GUIDANCE_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'CONVERSATION_GUIDANCE_MODEL',
      CUSTOM_HEAD_INTENT_SUGGESTION_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL: <
          CustomHeadIntentSuggestionModelMetadata_TrainingModelType>'SUMMARIZATION_V2_MODEL',
      values():
          Array<CustomHeadIntentSuggestionModelMetadata_TrainingModelType> {
            return [
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .MODEL_TYPE_UNSPECIFIED,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .ARTICLE_SUGGESTION_GBT_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .SMART_REPLY_DUAL_ENCODER_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .SMART_REPLY_BERT_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .SMART_COMPOSE_DUAL_ENCODER_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .SMART_REPLY_GENERATIVE_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .SUMMARIZATION_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .KEY_MOMENT_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .ISSUE_MODEL_DUAL_ENCODER_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .CONVERSATION_GUIDANCE_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .CUSTOM_HEAD_INTENT_SUGGESTION_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .SUMMARIZATION_V2_MODEL,
              CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
                  .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
            ];
          }
    };

export type DocumentClusterSourceInfo_ClusterSourceType =
    'CLUSTER_SOURCE_TYPE_UNSPECIFIED'|'CUSTOMER_PROVIDED'|'EMBEDDING_GROUPED';

export interface IDocumentClusterSourceInfo_ClusterSourceTypeEnum {
  readonly CLUSTER_SOURCE_TYPE_UNSPECIFIED:
      DocumentClusterSourceInfo_ClusterSourceType;
  readonly CUSTOMER_PROVIDED: DocumentClusterSourceInfo_ClusterSourceType;
  readonly EMBEDDING_GROUPED: DocumentClusterSourceInfo_ClusterSourceType;

  values(): Array<DocumentClusterSourceInfo_ClusterSourceType>;
}

export const DocumentClusterSourceInfo_ClusterSourceTypeEnum:
    IDocumentClusterSourceInfo_ClusterSourceTypeEnum = {
      CLUSTER_SOURCE_TYPE_UNSPECIFIED: <
          DocumentClusterSourceInfo_ClusterSourceType>'CLUSTER_SOURCE_TYPE_UNSPECIFIED',
      CUSTOMER_PROVIDED:
          <DocumentClusterSourceInfo_ClusterSourceType>'CUSTOMER_PROVIDED',
      EMBEDDING_GROUPED:
          <DocumentClusterSourceInfo_ClusterSourceType>'EMBEDDING_GROUPED',
      values(): Array<DocumentClusterSourceInfo_ClusterSourceType> {
        return [
          DocumentClusterSourceInfo_ClusterSourceTypeEnum
              .CLUSTER_SOURCE_TYPE_UNSPECIFIED,
          DocumentClusterSourceInfo_ClusterSourceTypeEnum.CUSTOMER_PROVIDED,
          DocumentClusterSourceInfo_ClusterSourceTypeEnum.EMBEDDING_GROUPED
        ];
      }
    };

export type Document_KnowledgeTypes =
    'KNOWLEDGE_TYPE_UNSPECIFIED'|'FAQ'|'EXTRACTIVE_QA'|'ARTICLE_SUGGESTION'|
    'AGENT_FACING_SMART_REPLY'|'SMART_REPLY'|'AGENT_FACING_SMART_COMPOSE'|
    'SMART_COMPOSE'|'CUSTOMER_FACING_SMART_REPLY'|
    'CUSTOMER_FACING_SMART_COMPOSE'|'ISSUE_MODELING'|'ARTICLE_SEARCH';

export interface IDocument_KnowledgeTypesEnum {
  readonly KNOWLEDGE_TYPE_UNSPECIFIED: Document_KnowledgeTypes;
  readonly FAQ: Document_KnowledgeTypes;
  readonly EXTRACTIVE_QA: Document_KnowledgeTypes;
  readonly ARTICLE_SUGGESTION: Document_KnowledgeTypes;
  readonly AGENT_FACING_SMART_REPLY: Document_KnowledgeTypes;
  readonly SMART_REPLY: Document_KnowledgeTypes;
  readonly AGENT_FACING_SMART_COMPOSE: Document_KnowledgeTypes;
  readonly SMART_COMPOSE: Document_KnowledgeTypes;
  readonly CUSTOMER_FACING_SMART_REPLY: Document_KnowledgeTypes;
  readonly CUSTOMER_FACING_SMART_COMPOSE: Document_KnowledgeTypes;
  readonly ISSUE_MODELING: Document_KnowledgeTypes;
  readonly ARTICLE_SEARCH: Document_KnowledgeTypes;

  values(): Array<Document_KnowledgeTypes>;
}

export const Document_KnowledgeTypesEnum: IDocument_KnowledgeTypesEnum = {
  AGENT_FACING_SMART_COMPOSE:
      <Document_KnowledgeTypes>'AGENT_FACING_SMART_COMPOSE',
  AGENT_FACING_SMART_REPLY: <Document_KnowledgeTypes>'AGENT_FACING_SMART_REPLY',
  ARTICLE_SEARCH: <Document_KnowledgeTypes>'ARTICLE_SEARCH',
  ARTICLE_SUGGESTION: <Document_KnowledgeTypes>'ARTICLE_SUGGESTION',
  CUSTOMER_FACING_SMART_COMPOSE:
      <Document_KnowledgeTypes>'CUSTOMER_FACING_SMART_COMPOSE',
  CUSTOMER_FACING_SMART_REPLY:
      <Document_KnowledgeTypes>'CUSTOMER_FACING_SMART_REPLY',
  EXTRACTIVE_QA: <Document_KnowledgeTypes>'EXTRACTIVE_QA',
  FAQ: <Document_KnowledgeTypes>'FAQ',
  ISSUE_MODELING: <Document_KnowledgeTypes>'ISSUE_MODELING',
  KNOWLEDGE_TYPE_UNSPECIFIED:
      <Document_KnowledgeTypes>'KNOWLEDGE_TYPE_UNSPECIFIED',
  SMART_COMPOSE: <Document_KnowledgeTypes>'SMART_COMPOSE',
  SMART_REPLY: <Document_KnowledgeTypes>'SMART_REPLY',
  values(): Array<Document_KnowledgeTypes> {
    return [
      Document_KnowledgeTypesEnum.KNOWLEDGE_TYPE_UNSPECIFIED,
      Document_KnowledgeTypesEnum.FAQ,
      Document_KnowledgeTypesEnum.EXTRACTIVE_QA,
      Document_KnowledgeTypesEnum.ARTICLE_SUGGESTION,
      Document_KnowledgeTypesEnum.AGENT_FACING_SMART_REPLY,
      Document_KnowledgeTypesEnum.SMART_REPLY,
      Document_KnowledgeTypesEnum.AGENT_FACING_SMART_COMPOSE,
      Document_KnowledgeTypesEnum.SMART_COMPOSE,
      Document_KnowledgeTypesEnum.CUSTOMER_FACING_SMART_REPLY,
      Document_KnowledgeTypesEnum.CUSTOMER_FACING_SMART_COMPOSE,
      Document_KnowledgeTypesEnum.ISSUE_MODELING,
      Document_KnowledgeTypesEnum.ARTICLE_SEARCH
    ];
  }
};

export type Document_State =
    'STATE_UNSPECIFIED'|'CREATING'|'ACTIVE'|'UPDATING'|'RELOADING'|'DELETING';

export interface IDocument_StateEnum {
  readonly STATE_UNSPECIFIED: Document_State;
  readonly CREATING: Document_State;
  readonly ACTIVE: Document_State;
  readonly UPDATING: Document_State;
  readonly RELOADING: Document_State;
  readonly DELETING: Document_State;

  values(): Array<Document_State>;
}

export const Document_StateEnum: IDocument_StateEnum = {
  ACTIVE: <Document_State>'ACTIVE',
  CREATING: <Document_State>'CREATING',
  DELETING: <Document_State>'DELETING',
  RELOADING: <Document_State>'RELOADING',
  STATE_UNSPECIFIED: <Document_State>'STATE_UNSPECIFIED',
  UPDATING: <Document_State>'UPDATING',
  values(): Array<Document_State> {
    return [
      Document_StateEnum.STATE_UNSPECIFIED, Document_StateEnum.CREATING,
      Document_StateEnum.ACTIVE, Document_StateEnum.UPDATING,
      Document_StateEnum.RELOADING, Document_StateEnum.DELETING
    ];
  }
};

export type EncodeSuggestionListToConversationModelRequest_LinkDatasetType =
    'LINK_DATASET_TYPE_UNSPECIFIED'|'LINK_ARTICLE_SUGGESTION'|
    'LINK_SMART_REPLY_DATASET'|'LINK_SMART_COMPOSE_DATASET'|
    'LINK_ISSUE_MODELING_DATASET';

export interface IEncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum {
  readonly LINK_DATASET_TYPE_UNSPECIFIED:
      EncodeSuggestionListToConversationModelRequest_LinkDatasetType;
  readonly LINK_ARTICLE_SUGGESTION:
      EncodeSuggestionListToConversationModelRequest_LinkDatasetType;
  readonly LINK_SMART_REPLY_DATASET:
      EncodeSuggestionListToConversationModelRequest_LinkDatasetType;
  readonly LINK_SMART_COMPOSE_DATASET:
      EncodeSuggestionListToConversationModelRequest_LinkDatasetType;
  readonly LINK_ISSUE_MODELING_DATASET:
      EncodeSuggestionListToConversationModelRequest_LinkDatasetType;

  values():
      Array<EncodeSuggestionListToConversationModelRequest_LinkDatasetType>;
}

export const EncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum:
    IEncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum = {
      LINK_ARTICLE_SUGGESTION: <
          EncodeSuggestionListToConversationModelRequest_LinkDatasetType>'LINK_ARTICLE_SUGGESTION',
      LINK_DATASET_TYPE_UNSPECIFIED: <
          EncodeSuggestionListToConversationModelRequest_LinkDatasetType>'LINK_DATASET_TYPE_UNSPECIFIED',
      LINK_ISSUE_MODELING_DATASET: <
          EncodeSuggestionListToConversationModelRequest_LinkDatasetType>'LINK_ISSUE_MODELING_DATASET',
      LINK_SMART_COMPOSE_DATASET: <
          EncodeSuggestionListToConversationModelRequest_LinkDatasetType>'LINK_SMART_COMPOSE_DATASET',
      LINK_SMART_REPLY_DATASET: <
          EncodeSuggestionListToConversationModelRequest_LinkDatasetType>'LINK_SMART_REPLY_DATASET',
      values(): Array<
          EncodeSuggestionListToConversationModelRequest_LinkDatasetType> {
        return [
          EncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum
              .LINK_DATASET_TYPE_UNSPECIFIED,
          EncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum
              .LINK_ARTICLE_SUGGESTION,
          EncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum
              .LINK_SMART_REPLY_DATASET,
          EncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum
              .LINK_SMART_COMPOSE_DATASET,
          EncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum
              .LINK_ISSUE_MODELING_DATASET
        ];
      }
    };

export type EnteringIntent_WebhookState = 'WEBHOOK_STATE_UNSPECIFIED'|
    'WEBHOOK_STATE_ENABLED'|'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING';

export interface IEnteringIntent_WebhookStateEnum {
  readonly WEBHOOK_STATE_UNSPECIFIED: EnteringIntent_WebhookState;
  readonly WEBHOOK_STATE_ENABLED: EnteringIntent_WebhookState;
  readonly WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING: EnteringIntent_WebhookState;

  values(): Array<EnteringIntent_WebhookState>;
}

export const EnteringIntent_WebhookStateEnum:
    IEnteringIntent_WebhookStateEnum = {
      WEBHOOK_STATE_ENABLED:
          <EnteringIntent_WebhookState>'WEBHOOK_STATE_ENABLED',
      WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING:
          <EnteringIntent_WebhookState>'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING',
      WEBHOOK_STATE_UNSPECIFIED:
          <EnteringIntent_WebhookState>'WEBHOOK_STATE_UNSPECIFIED',
      values(): Array<EnteringIntent_WebhookState> {
        return [
          EnteringIntent_WebhookStateEnum.WEBHOOK_STATE_UNSPECIFIED,
          EnteringIntent_WebhookStateEnum.WEBHOOK_STATE_ENABLED,
          EnteringIntent_WebhookStateEnum.WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING
        ];
      }
    };

export type EntityType_AutoExpansionMode =
    'AUTO_EXPANSION_MODE_UNSPECIFIED'|'AUTO_EXPANSION_MODE_DEFAULT';

export interface IEntityType_AutoExpansionModeEnum {
  readonly AUTO_EXPANSION_MODE_UNSPECIFIED: EntityType_AutoExpansionMode;
  readonly AUTO_EXPANSION_MODE_DEFAULT: EntityType_AutoExpansionMode;

  values(): Array<EntityType_AutoExpansionMode>;
}

export const EntityType_AutoExpansionModeEnum:
    IEntityType_AutoExpansionModeEnum = {
      AUTO_EXPANSION_MODE_DEFAULT:
          <EntityType_AutoExpansionMode>'AUTO_EXPANSION_MODE_DEFAULT',
      AUTO_EXPANSION_MODE_UNSPECIFIED:
          <EntityType_AutoExpansionMode>'AUTO_EXPANSION_MODE_UNSPECIFIED',
      values(): Array<EntityType_AutoExpansionMode> {
        return [
          EntityType_AutoExpansionModeEnum.AUTO_EXPANSION_MODE_UNSPECIFIED,
          EntityType_AutoExpansionModeEnum.AUTO_EXPANSION_MODE_DEFAULT
        ];
      }
    };

export type EntityType_Kind =
    'KIND_UNSPECIFIED'|'KIND_MAP'|'KIND_LIST'|'KIND_REGEXP';

export interface IEntityType_KindEnum {
  readonly KIND_UNSPECIFIED: EntityType_Kind;
  readonly KIND_MAP: EntityType_Kind;
  readonly KIND_LIST: EntityType_Kind;
  readonly KIND_REGEXP: EntityType_Kind;

  values(): Array<EntityType_Kind>;
}

export const EntityType_KindEnum: IEntityType_KindEnum = {
  KIND_LIST: <EntityType_Kind>'KIND_LIST',
  KIND_MAP: <EntityType_Kind>'KIND_MAP',
  KIND_REGEXP: <EntityType_Kind>'KIND_REGEXP',
  KIND_UNSPECIFIED: <EntityType_Kind>'KIND_UNSPECIFIED',
  values(): Array<EntityType_Kind> {
    return [
      EntityType_KindEnum.KIND_UNSPECIFIED, EntityType_KindEnum.KIND_MAP,
      EntityType_KindEnum.KIND_LIST, EntityType_KindEnum.KIND_REGEXP
    ];
  }
};

export type Environment_State =
    'STATE_UNSPECIFIED'|'STOPPED'|'LOADING'|'RUNNING';

export interface IEnvironment_StateEnum {
  readonly STATE_UNSPECIFIED: Environment_State;
  readonly STOPPED: Environment_State;
  readonly LOADING: Environment_State;
  readonly RUNNING: Environment_State;

  values(): Array<Environment_State>;
}

export const Environment_StateEnum: IEnvironment_StateEnum = {
  LOADING: <Environment_State>'LOADING',
  RUNNING: <Environment_State>'RUNNING',
  STATE_UNSPECIFIED: <Environment_State>'STATE_UNSPECIFIED',
  STOPPED: <Environment_State>'STOPPED',
  values(): Array<Environment_State> {
    return [
      Environment_StateEnum.STATE_UNSPECIFIED, Environment_StateEnum.STOPPED,
      Environment_StateEnum.LOADING, Environment_StateEnum.RUNNING
    ];
  }
};

export type ExitingIntent_WebhookState = 'WEBHOOK_STATE_UNSPECIFIED'|
    'WEBHOOK_STATE_ENABLED'|'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING';

export interface IExitingIntent_WebhookStateEnum {
  readonly WEBHOOK_STATE_UNSPECIFIED: ExitingIntent_WebhookState;
  readonly WEBHOOK_STATE_ENABLED: ExitingIntent_WebhookState;
  readonly WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING: ExitingIntent_WebhookState;

  values(): Array<ExitingIntent_WebhookState>;
}

export const ExitingIntent_WebhookStateEnum: IExitingIntent_WebhookStateEnum = {
  WEBHOOK_STATE_ENABLED: <ExitingIntent_WebhookState>'WEBHOOK_STATE_ENABLED',
  WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING:
      <ExitingIntent_WebhookState>'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING',
  WEBHOOK_STATE_UNSPECIFIED:
      <ExitingIntent_WebhookState>'WEBHOOK_STATE_UNSPECIFIED',
  values(): Array<ExitingIntent_WebhookState> {
    return [
      ExitingIntent_WebhookStateEnum.WEBHOOK_STATE_UNSPECIFIED,
      ExitingIntent_WebhookStateEnum.WEBHOOK_STATE_ENABLED,
      ExitingIntent_WebhookStateEnum.WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING
    ];
  }
};

export type ExportAgentRequest_ExportAgentFormat =
    'EXPORT_AGENT_FORMAT_TYPE_UNSPECIFIED'|'STANDARD'|'AMAZON_ALEXA';

export interface IExportAgentRequest_ExportAgentFormatEnum {
  readonly EXPORT_AGENT_FORMAT_TYPE_UNSPECIFIED:
      ExportAgentRequest_ExportAgentFormat;
  readonly STANDARD: ExportAgentRequest_ExportAgentFormat;
  readonly AMAZON_ALEXA: ExportAgentRequest_ExportAgentFormat;

  values(): Array<ExportAgentRequest_ExportAgentFormat>;
}

export const ExportAgentRequest_ExportAgentFormatEnum:
    IExportAgentRequest_ExportAgentFormatEnum = {
      AMAZON_ALEXA: <ExportAgentRequest_ExportAgentFormat>'AMAZON_ALEXA',
      EXPORT_AGENT_FORMAT_TYPE_UNSPECIFIED: <
          ExportAgentRequest_ExportAgentFormat>'EXPORT_AGENT_FORMAT_TYPE_UNSPECIFIED',
      STANDARD: <ExportAgentRequest_ExportAgentFormat>'STANDARD',
      values(): Array<ExportAgentRequest_ExportAgentFormat> {
        return [
          ExportAgentRequest_ExportAgentFormatEnum
              .EXPORT_AGENT_FORMAT_TYPE_UNSPECIFIED,
          ExportAgentRequest_ExportAgentFormatEnum.STANDARD,
          ExportAgentRequest_ExportAgentFormatEnum.AMAZON_ALEXA
        ];
      }
    };

export type FaqAnswersConfig_KnowledgeTypes =
    'KNOWLEDGE_TYPE_UNSPECIFIED'|'FAQ'|'EXTRACTIVE_QA'|'ARTICLE_SUGGESTION'|
    'AGENT_FACING_SMART_REPLY'|'SMART_REPLY'|'AGENT_FACING_SMART_COMPOSE'|
    'SMART_COMPOSE'|'CUSTOMER_FACING_SMART_REPLY'|
    'CUSTOMER_FACING_SMART_COMPOSE'|'ISSUE_MODELING'|'ARTICLE_SEARCH';

export interface IFaqAnswersConfig_KnowledgeTypesEnum {
  readonly KNOWLEDGE_TYPE_UNSPECIFIED: FaqAnswersConfig_KnowledgeTypes;
  readonly FAQ: FaqAnswersConfig_KnowledgeTypes;
  readonly EXTRACTIVE_QA: FaqAnswersConfig_KnowledgeTypes;
  readonly ARTICLE_SUGGESTION: FaqAnswersConfig_KnowledgeTypes;
  readonly AGENT_FACING_SMART_REPLY: FaqAnswersConfig_KnowledgeTypes;
  readonly SMART_REPLY: FaqAnswersConfig_KnowledgeTypes;
  readonly AGENT_FACING_SMART_COMPOSE: FaqAnswersConfig_KnowledgeTypes;
  readonly SMART_COMPOSE: FaqAnswersConfig_KnowledgeTypes;
  readonly CUSTOMER_FACING_SMART_REPLY: FaqAnswersConfig_KnowledgeTypes;
  readonly CUSTOMER_FACING_SMART_COMPOSE: FaqAnswersConfig_KnowledgeTypes;
  readonly ISSUE_MODELING: FaqAnswersConfig_KnowledgeTypes;
  readonly ARTICLE_SEARCH: FaqAnswersConfig_KnowledgeTypes;

  values(): Array<FaqAnswersConfig_KnowledgeTypes>;
}

export const FaqAnswersConfig_KnowledgeTypesEnum:
    IFaqAnswersConfig_KnowledgeTypesEnum = {
      AGENT_FACING_SMART_COMPOSE:
          <FaqAnswersConfig_KnowledgeTypes>'AGENT_FACING_SMART_COMPOSE',
      AGENT_FACING_SMART_REPLY:
          <FaqAnswersConfig_KnowledgeTypes>'AGENT_FACING_SMART_REPLY',
      ARTICLE_SEARCH: <FaqAnswersConfig_KnowledgeTypes>'ARTICLE_SEARCH',
      ARTICLE_SUGGESTION: <FaqAnswersConfig_KnowledgeTypes>'ARTICLE_SUGGESTION',
      CUSTOMER_FACING_SMART_COMPOSE:
          <FaqAnswersConfig_KnowledgeTypes>'CUSTOMER_FACING_SMART_COMPOSE',
      CUSTOMER_FACING_SMART_REPLY:
          <FaqAnswersConfig_KnowledgeTypes>'CUSTOMER_FACING_SMART_REPLY',
      EXTRACTIVE_QA: <FaqAnswersConfig_KnowledgeTypes>'EXTRACTIVE_QA',
      FAQ: <FaqAnswersConfig_KnowledgeTypes>'FAQ',
      ISSUE_MODELING: <FaqAnswersConfig_KnowledgeTypes>'ISSUE_MODELING',
      KNOWLEDGE_TYPE_UNSPECIFIED:
          <FaqAnswersConfig_KnowledgeTypes>'KNOWLEDGE_TYPE_UNSPECIFIED',
      SMART_COMPOSE: <FaqAnswersConfig_KnowledgeTypes>'SMART_COMPOSE',
      SMART_REPLY: <FaqAnswersConfig_KnowledgeTypes>'SMART_REPLY',
      values(): Array<FaqAnswersConfig_KnowledgeTypes> {
        return [
          FaqAnswersConfig_KnowledgeTypesEnum.KNOWLEDGE_TYPE_UNSPECIFIED,
          FaqAnswersConfig_KnowledgeTypesEnum.FAQ,
          FaqAnswersConfig_KnowledgeTypesEnum.EXTRACTIVE_QA,
          FaqAnswersConfig_KnowledgeTypesEnum.ARTICLE_SUGGESTION,
          FaqAnswersConfig_KnowledgeTypesEnum.AGENT_FACING_SMART_REPLY,
          FaqAnswersConfig_KnowledgeTypesEnum.SMART_REPLY,
          FaqAnswersConfig_KnowledgeTypesEnum.AGENT_FACING_SMART_COMPOSE,
          FaqAnswersConfig_KnowledgeTypesEnum.SMART_COMPOSE,
          FaqAnswersConfig_KnowledgeTypesEnum.CUSTOMER_FACING_SMART_REPLY,
          FaqAnswersConfig_KnowledgeTypesEnum.CUSTOMER_FACING_SMART_COMPOSE,
          FaqAnswersConfig_KnowledgeTypesEnum.ISSUE_MODELING,
          FaqAnswersConfig_KnowledgeTypesEnum.ARTICLE_SEARCH
        ];
      }
    };

export type FeatureCreationFlowStage_StageType =
    'STAGE_TYPE_UNSPECIFIED'|'TRAINING_DATA_UPLOAD'|'DATASET_CREATION'|
    'DATA_LABELING'|'MODEL_TRAINING'|'MODEL_EVALUATION'|'ALLOWLIST_MANAGEMENT'|
    'KNOWLEDGE_BASE_CREATION'|'CONVERSATION_PROFILE_CONFIG';

export interface IFeatureCreationFlowStage_StageTypeEnum {
  readonly STAGE_TYPE_UNSPECIFIED: FeatureCreationFlowStage_StageType;
  readonly TRAINING_DATA_UPLOAD: FeatureCreationFlowStage_StageType;
  readonly DATASET_CREATION: FeatureCreationFlowStage_StageType;
  readonly DATA_LABELING: FeatureCreationFlowStage_StageType;
  readonly MODEL_TRAINING: FeatureCreationFlowStage_StageType;
  readonly MODEL_EVALUATION: FeatureCreationFlowStage_StageType;
  readonly ALLOWLIST_MANAGEMENT: FeatureCreationFlowStage_StageType;
  readonly KNOWLEDGE_BASE_CREATION: FeatureCreationFlowStage_StageType;
  readonly CONVERSATION_PROFILE_CONFIG: FeatureCreationFlowStage_StageType;

  values(): Array<FeatureCreationFlowStage_StageType>;
}

export const FeatureCreationFlowStage_StageTypeEnum:
    IFeatureCreationFlowStage_StageTypeEnum = {
      ALLOWLIST_MANAGEMENT:
          <FeatureCreationFlowStage_StageType>'ALLOWLIST_MANAGEMENT',
      CONVERSATION_PROFILE_CONFIG:
          <FeatureCreationFlowStage_StageType>'CONVERSATION_PROFILE_CONFIG',
      DATASET_CREATION: <FeatureCreationFlowStage_StageType>'DATASET_CREATION',
      DATA_LABELING: <FeatureCreationFlowStage_StageType>'DATA_LABELING',
      KNOWLEDGE_BASE_CREATION:
          <FeatureCreationFlowStage_StageType>'KNOWLEDGE_BASE_CREATION',
      MODEL_EVALUATION: <FeatureCreationFlowStage_StageType>'MODEL_EVALUATION',
      MODEL_TRAINING: <FeatureCreationFlowStage_StageType>'MODEL_TRAINING',
      STAGE_TYPE_UNSPECIFIED:
          <FeatureCreationFlowStage_StageType>'STAGE_TYPE_UNSPECIFIED',
      TRAINING_DATA_UPLOAD:
          <FeatureCreationFlowStage_StageType>'TRAINING_DATA_UPLOAD',
      values(): Array<FeatureCreationFlowStage_StageType> {
        return [
          FeatureCreationFlowStage_StageTypeEnum.STAGE_TYPE_UNSPECIFIED,
          FeatureCreationFlowStage_StageTypeEnum.TRAINING_DATA_UPLOAD,
          FeatureCreationFlowStage_StageTypeEnum.DATASET_CREATION,
          FeatureCreationFlowStage_StageTypeEnum.DATA_LABELING,
          FeatureCreationFlowStage_StageTypeEnum.MODEL_TRAINING,
          FeatureCreationFlowStage_StageTypeEnum.MODEL_EVALUATION,
          FeatureCreationFlowStage_StageTypeEnum.ALLOWLIST_MANAGEMENT,
          FeatureCreationFlowStage_StageTypeEnum.KNOWLEDGE_BASE_CREATION,
          FeatureCreationFlowStage_StageTypeEnum.CONVERSATION_PROFILE_CONFIG
        ];
      }
    };

export type FeatureCreationFlowStage_State = 'STATE_UNSPECIFIED'|'NOT_STARTED'|
    'IN_PROGRESS'|'COMPLETED'|'WARNING'|'ERROR';

export interface IFeatureCreationFlowStage_StateEnum {
  readonly STATE_UNSPECIFIED: FeatureCreationFlowStage_State;
  readonly NOT_STARTED: FeatureCreationFlowStage_State;
  readonly IN_PROGRESS: FeatureCreationFlowStage_State;
  readonly COMPLETED: FeatureCreationFlowStage_State;
  readonly WARNING: FeatureCreationFlowStage_State;
  readonly ERROR: FeatureCreationFlowStage_State;

  values(): Array<FeatureCreationFlowStage_State>;
}

export const FeatureCreationFlowStage_StateEnum:
    IFeatureCreationFlowStage_StateEnum = {
      COMPLETED: <FeatureCreationFlowStage_State>'COMPLETED',
      ERROR: <FeatureCreationFlowStage_State>'ERROR',
      IN_PROGRESS: <FeatureCreationFlowStage_State>'IN_PROGRESS',
      NOT_STARTED: <FeatureCreationFlowStage_State>'NOT_STARTED',
      STATE_UNSPECIFIED: <FeatureCreationFlowStage_State>'STATE_UNSPECIFIED',
      WARNING: <FeatureCreationFlowStage_State>'WARNING',
      values(): Array<FeatureCreationFlowStage_State> {
        return [
          FeatureCreationFlowStage_StateEnum.STATE_UNSPECIFIED,
          FeatureCreationFlowStage_StateEnum.NOT_STARTED,
          FeatureCreationFlowStage_StateEnum.IN_PROGRESS,
          FeatureCreationFlowStage_StateEnum.COMPLETED,
          FeatureCreationFlowStage_StateEnum.WARNING,
          FeatureCreationFlowStage_StateEnum.ERROR
        ];
      }
    };

export type FeatureCreationFlow_FlowType =
    'FLOW_TYPE_UNSPECIFIED'|'SUMMARIZATION_BASELINE'|'SUMMARIZATION_CUSTOM'|
    'SMART_REPLY'|'ARTICLE_SUGGESTION_BASELINE';

export interface IFeatureCreationFlow_FlowTypeEnum {
  readonly FLOW_TYPE_UNSPECIFIED: FeatureCreationFlow_FlowType;
  readonly SUMMARIZATION_BASELINE: FeatureCreationFlow_FlowType;
  readonly SUMMARIZATION_CUSTOM: FeatureCreationFlow_FlowType;
  readonly SMART_REPLY: FeatureCreationFlow_FlowType;
  readonly ARTICLE_SUGGESTION_BASELINE: FeatureCreationFlow_FlowType;

  values(): Array<FeatureCreationFlow_FlowType>;
}

export const FeatureCreationFlow_FlowTypeEnum:
    IFeatureCreationFlow_FlowTypeEnum = {
      ARTICLE_SUGGESTION_BASELINE:
          <FeatureCreationFlow_FlowType>'ARTICLE_SUGGESTION_BASELINE',
      FLOW_TYPE_UNSPECIFIED:
          <FeatureCreationFlow_FlowType>'FLOW_TYPE_UNSPECIFIED',
      SMART_REPLY: <FeatureCreationFlow_FlowType>'SMART_REPLY',
      SUMMARIZATION_BASELINE:
          <FeatureCreationFlow_FlowType>'SUMMARIZATION_BASELINE',
      SUMMARIZATION_CUSTOM:
          <FeatureCreationFlow_FlowType>'SUMMARIZATION_CUSTOM',
      values(): Array<FeatureCreationFlow_FlowType> {
        return [
          FeatureCreationFlow_FlowTypeEnum.FLOW_TYPE_UNSPECIFIED,
          FeatureCreationFlow_FlowTypeEnum.SUMMARIZATION_BASELINE,
          FeatureCreationFlow_FlowTypeEnum.SUMMARIZATION_CUSTOM,
          FeatureCreationFlow_FlowTypeEnum.SMART_REPLY,
          FeatureCreationFlow_FlowTypeEnum.ARTICLE_SUGGESTION_BASELINE
        ];
      }
    };

export type FeatureCreationFlow_State = 'STATE_UNSPECIFIED'|'NOT_STARTED'|
    'IN_PROGRESS'|'COMPLETED'|'WARNING'|'ERROR';

export interface IFeatureCreationFlow_StateEnum {
  readonly STATE_UNSPECIFIED: FeatureCreationFlow_State;
  readonly NOT_STARTED: FeatureCreationFlow_State;
  readonly IN_PROGRESS: FeatureCreationFlow_State;
  readonly COMPLETED: FeatureCreationFlow_State;
  readonly WARNING: FeatureCreationFlow_State;
  readonly ERROR: FeatureCreationFlow_State;

  values(): Array<FeatureCreationFlow_State>;
}

export const FeatureCreationFlow_StateEnum: IFeatureCreationFlow_StateEnum = {
  COMPLETED: <FeatureCreationFlow_State>'COMPLETED',
  ERROR: <FeatureCreationFlow_State>'ERROR',
  IN_PROGRESS: <FeatureCreationFlow_State>'IN_PROGRESS',
  NOT_STARTED: <FeatureCreationFlow_State>'NOT_STARTED',
  STATE_UNSPECIFIED: <FeatureCreationFlow_State>'STATE_UNSPECIFIED',
  WARNING: <FeatureCreationFlow_State>'WARNING',
  values(): Array<FeatureCreationFlow_State> {
    return [
      FeatureCreationFlow_StateEnum.STATE_UNSPECIFIED,
      FeatureCreationFlow_StateEnum.NOT_STARTED,
      FeatureCreationFlow_StateEnum.IN_PROGRESS,
      FeatureCreationFlow_StateEnum.COMPLETED,
      FeatureCreationFlow_StateEnum.WARNING, FeatureCreationFlow_StateEnum.ERROR
    ];
  }
};

export type FulfillmentFeature_Type = 'TYPE_UNSPECIFIED'|'SMALLTALK';

export interface IFulfillmentFeature_TypeEnum {
  readonly TYPE_UNSPECIFIED: FulfillmentFeature_Type;
  readonly SMALLTALK: FulfillmentFeature_Type;

  values(): Array<FulfillmentFeature_Type>;
}

export const FulfillmentFeature_TypeEnum: IFulfillmentFeature_TypeEnum = {
  SMALLTALK: <FulfillmentFeature_Type>'SMALLTALK',
  TYPE_UNSPECIFIED: <FulfillmentFeature_Type>'TYPE_UNSPECIFIED',
  values(): Array<FulfillmentFeature_Type> {
    return [
      FulfillmentFeature_TypeEnum.TYPE_UNSPECIFIED,
      FulfillmentFeature_TypeEnum.SMALLTALK
    ];
  }
};

export type GenerateDocumentOperationMetadata_State = 'STATE_UNSPECIFIED'|
    'INITIALIZING'|'RUNNING'|'CANCELLED'|'SUCCEEDED'|'FAILED';

export interface IGenerateDocumentOperationMetadata_StateEnum {
  readonly STATE_UNSPECIFIED: GenerateDocumentOperationMetadata_State;
  readonly INITIALIZING: GenerateDocumentOperationMetadata_State;
  readonly RUNNING: GenerateDocumentOperationMetadata_State;
  readonly CANCELLED: GenerateDocumentOperationMetadata_State;
  readonly SUCCEEDED: GenerateDocumentOperationMetadata_State;
  readonly FAILED: GenerateDocumentOperationMetadata_State;

  values(): Array<GenerateDocumentOperationMetadata_State>;
}

export const GenerateDocumentOperationMetadata_StateEnum:
    IGenerateDocumentOperationMetadata_StateEnum = {
      CANCELLED: <GenerateDocumentOperationMetadata_State>'CANCELLED',
      FAILED: <GenerateDocumentOperationMetadata_State>'FAILED',
      INITIALIZING: <GenerateDocumentOperationMetadata_State>'INITIALIZING',
      RUNNING: <GenerateDocumentOperationMetadata_State>'RUNNING',
      STATE_UNSPECIFIED:
          <GenerateDocumentOperationMetadata_State>'STATE_UNSPECIFIED',
      SUCCEEDED: <GenerateDocumentOperationMetadata_State>'SUCCEEDED',
      values(): Array<GenerateDocumentOperationMetadata_State> {
        return [
          GenerateDocumentOperationMetadata_StateEnum.STATE_UNSPECIFIED,
          GenerateDocumentOperationMetadata_StateEnum.INITIALIZING,
          GenerateDocumentOperationMetadata_StateEnum.RUNNING,
          GenerateDocumentOperationMetadata_StateEnum.CANCELLED,
          GenerateDocumentOperationMetadata_StateEnum.SUCCEEDED,
          GenerateDocumentOperationMetadata_StateEnum.FAILED
        ];
      }
    };

export type GenerateDocumentRequest_KnowledgeType =
    'KNOWLEDGE_TYPE_UNSPECIFIED'|'FAQ'|'EXTRACTIVE_QA'|'ARTICLE_SUGGESTION'|
    'AGENT_FACING_SMART_REPLY'|'SMART_REPLY'|'AGENT_FACING_SMART_COMPOSE'|
    'SMART_COMPOSE'|'CUSTOMER_FACING_SMART_REPLY'|
    'CUSTOMER_FACING_SMART_COMPOSE'|'ISSUE_MODELING'|'ARTICLE_SEARCH';

export interface IGenerateDocumentRequest_KnowledgeTypeEnum {
  readonly KNOWLEDGE_TYPE_UNSPECIFIED: GenerateDocumentRequest_KnowledgeType;
  readonly FAQ: GenerateDocumentRequest_KnowledgeType;
  readonly EXTRACTIVE_QA: GenerateDocumentRequest_KnowledgeType;
  readonly ARTICLE_SUGGESTION: GenerateDocumentRequest_KnowledgeType;
  readonly AGENT_FACING_SMART_REPLY: GenerateDocumentRequest_KnowledgeType;
  readonly SMART_REPLY: GenerateDocumentRequest_KnowledgeType;
  readonly AGENT_FACING_SMART_COMPOSE: GenerateDocumentRequest_KnowledgeType;
  readonly SMART_COMPOSE: GenerateDocumentRequest_KnowledgeType;
  readonly CUSTOMER_FACING_SMART_REPLY: GenerateDocumentRequest_KnowledgeType;
  readonly CUSTOMER_FACING_SMART_COMPOSE: GenerateDocumentRequest_KnowledgeType;
  readonly ISSUE_MODELING: GenerateDocumentRequest_KnowledgeType;
  readonly ARTICLE_SEARCH: GenerateDocumentRequest_KnowledgeType;

  values(): Array<GenerateDocumentRequest_KnowledgeType>;
}

export const GenerateDocumentRequest_KnowledgeTypeEnum:
    IGenerateDocumentRequest_KnowledgeTypeEnum = {
      AGENT_FACING_SMART_COMPOSE:
          <GenerateDocumentRequest_KnowledgeType>'AGENT_FACING_SMART_COMPOSE',
      AGENT_FACING_SMART_REPLY:
          <GenerateDocumentRequest_KnowledgeType>'AGENT_FACING_SMART_REPLY',
      ARTICLE_SEARCH: <GenerateDocumentRequest_KnowledgeType>'ARTICLE_SEARCH',
      ARTICLE_SUGGESTION:
          <GenerateDocumentRequest_KnowledgeType>'ARTICLE_SUGGESTION',
      CUSTOMER_FACING_SMART_COMPOSE: <
          GenerateDocumentRequest_KnowledgeType>'CUSTOMER_FACING_SMART_COMPOSE',
      CUSTOMER_FACING_SMART_REPLY:
          <GenerateDocumentRequest_KnowledgeType>'CUSTOMER_FACING_SMART_REPLY',
      EXTRACTIVE_QA: <GenerateDocumentRequest_KnowledgeType>'EXTRACTIVE_QA',
      FAQ: <GenerateDocumentRequest_KnowledgeType>'FAQ',
      ISSUE_MODELING: <GenerateDocumentRequest_KnowledgeType>'ISSUE_MODELING',
      KNOWLEDGE_TYPE_UNSPECIFIED:
          <GenerateDocumentRequest_KnowledgeType>'KNOWLEDGE_TYPE_UNSPECIFIED',
      SMART_COMPOSE: <GenerateDocumentRequest_KnowledgeType>'SMART_COMPOSE',
      SMART_REPLY: <GenerateDocumentRequest_KnowledgeType>'SMART_REPLY',
      values(): Array<GenerateDocumentRequest_KnowledgeType> {
        return [
          GenerateDocumentRequest_KnowledgeTypeEnum.KNOWLEDGE_TYPE_UNSPECIFIED,
          GenerateDocumentRequest_KnowledgeTypeEnum.FAQ,
          GenerateDocumentRequest_KnowledgeTypeEnum.EXTRACTIVE_QA,
          GenerateDocumentRequest_KnowledgeTypeEnum.ARTICLE_SUGGESTION,
          GenerateDocumentRequest_KnowledgeTypeEnum.AGENT_FACING_SMART_REPLY,
          GenerateDocumentRequest_KnowledgeTypeEnum.SMART_REPLY,
          GenerateDocumentRequest_KnowledgeTypeEnum.AGENT_FACING_SMART_COMPOSE,
          GenerateDocumentRequest_KnowledgeTypeEnum.SMART_COMPOSE,
          GenerateDocumentRequest_KnowledgeTypeEnum.CUSTOMER_FACING_SMART_REPLY,
          GenerateDocumentRequest_KnowledgeTypeEnum
              .CUSTOMER_FACING_SMART_COMPOSE,
          GenerateDocumentRequest_KnowledgeTypeEnum.ISSUE_MODELING,
          GenerateDocumentRequest_KnowledgeTypeEnum.ARTICLE_SEARCH
        ];
      }
    };

export type GoogleCloudAuditAuthorizationLoggingOptionsPermissionType =
    'PERMISSION_TYPE_UNSPECIFIED'|'ADMIN_READ'|'ADMIN_WRITE'|'DATA_READ'|
    'DATA_WRITE';

export interface IGoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum {
  readonly PERMISSION_TYPE_UNSPECIFIED:
      GoogleCloudAuditAuthorizationLoggingOptionsPermissionType;
  readonly ADMIN_READ:
      GoogleCloudAuditAuthorizationLoggingOptionsPermissionType;
  readonly ADMIN_WRITE:
      GoogleCloudAuditAuthorizationLoggingOptionsPermissionType;
  readonly DATA_READ: GoogleCloudAuditAuthorizationLoggingOptionsPermissionType;
  readonly DATA_WRITE:
      GoogleCloudAuditAuthorizationLoggingOptionsPermissionType;

  values(): Array<GoogleCloudAuditAuthorizationLoggingOptionsPermissionType>;
}

export const GoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum:
    IGoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum = {
      ADMIN_READ: <
          GoogleCloudAuditAuthorizationLoggingOptionsPermissionType>'ADMIN_READ',
      ADMIN_WRITE: <
          GoogleCloudAuditAuthorizationLoggingOptionsPermissionType>'ADMIN_WRITE',
      DATA_READ: <
          GoogleCloudAuditAuthorizationLoggingOptionsPermissionType>'DATA_READ',
      DATA_WRITE: <
          GoogleCloudAuditAuthorizationLoggingOptionsPermissionType>'DATA_WRITE',
      PERMISSION_TYPE_UNSPECIFIED: <
          GoogleCloudAuditAuthorizationLoggingOptionsPermissionType>'PERMISSION_TYPE_UNSPECIFIED',
      values():
          Array<GoogleCloudAuditAuthorizationLoggingOptionsPermissionType> {
            return [
              GoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum
                  .PERMISSION_TYPE_UNSPECIFIED,
              GoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum
                  .ADMIN_READ,
              GoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum
                  .ADMIN_WRITE,
              GoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum
                  .DATA_READ,
              GoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum
                  .DATA_WRITE
            ];
          }
    };

export type GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSource =
    'SCORE_SOURCE_UNSPECIFIED'|'LLM_INTENT_PREDICTION';

export interface IGoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSourceEnum {
  readonly SCORE_SOURCE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSource;
  readonly LLM_INTENT_PREDICTION:
      GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSource;

  values():
      Array<GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSource>;
}

export const GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSourceEnum:
    IGoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSourceEnum = {
      LLM_INTENT_PREDICTION: <
          GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSource>'LLM_INTENT_PREDICTION',
      SCORE_SOURCE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSource>'SCORE_SOURCE_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSource> {
            return [
              GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSourceEnum
                  .SCORE_SOURCE_UNSPECIFIED,
              GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSourceEnum
                  .LLM_INTENT_PREDICTION
            ];
          }
    };

export type GoogleCloudDialogflowAamV2beta1AamDatasetState =
    'STATE_UNSPECIFIED'|'LOADING'|'ACTIVE'|'ERROR';

export interface IGoogleCloudDialogflowAamV2beta1AamDatasetStateEnum {
  readonly STATE_UNSPECIFIED: GoogleCloudDialogflowAamV2beta1AamDatasetState;
  readonly LOADING: GoogleCloudDialogflowAamV2beta1AamDatasetState;
  readonly ACTIVE: GoogleCloudDialogflowAamV2beta1AamDatasetState;
  readonly ERROR: GoogleCloudDialogflowAamV2beta1AamDatasetState;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamDatasetState>;
}

export const GoogleCloudDialogflowAamV2beta1AamDatasetStateEnum:
    IGoogleCloudDialogflowAamV2beta1AamDatasetStateEnum = {
      ACTIVE: <GoogleCloudDialogflowAamV2beta1AamDatasetState>'ACTIVE',
      ERROR: <GoogleCloudDialogflowAamV2beta1AamDatasetState>'ERROR',
      LOADING: <GoogleCloudDialogflowAamV2beta1AamDatasetState>'LOADING',
      STATE_UNSPECIFIED:
          <GoogleCloudDialogflowAamV2beta1AamDatasetState>'STATE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamDatasetState> {
        return [
          GoogleCloudDialogflowAamV2beta1AamDatasetStateEnum.STATE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamDatasetStateEnum.LOADING,
          GoogleCloudDialogflowAamV2beta1AamDatasetStateEnum.ACTIVE,
          GoogleCloudDialogflowAamV2beta1AamDatasetStateEnum.ERROR
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType =
    'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
    'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|'PARAMETER'|
    'INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum {
  readonly DISCRIMINANT_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType;
  readonly DRIVER:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType;
  readonly INTENT:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType;
  readonly CONTEXTUAL_INTENT:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType;
  readonly AGENT_QUESTION:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType;
  readonly AGENT_ANSWER:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType;
  readonly AGENT_ACTION:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType;
  readonly AGENT_COMMAND:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType;
  readonly PARAMETER:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType;
  readonly INTENT_RESOLUTION:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType;

  values(): Array<
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>;
}

export const GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum:
    IGoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum = {
      AGENT_ACTION: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>'AGENT_ACTION',
      AGENT_ANSWER: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>'AGENT_ANSWER',
      AGENT_COMMAND: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>'AGENT_COMMAND',
      AGENT_QUESTION: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>'AGENT_QUESTION',
      CONTEXTUAL_INTENT: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>'CONTEXTUAL_INTENT',
      DISCRIMINANT_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>'DISCRIMINANT_TYPE_UNSPECIFIED',
      DRIVER: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>'DRIVER',
      INTENT: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>'INTENT',
      INTENT_RESOLUTION: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>'INTENT_RESOLUTION',
      PARAMETER: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType>'PARAMETER',
      values(): Array<
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType> {
        return [
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum
              .DISCRIMINANT_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum
              .DRIVER,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum
              .INTENT,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum
              .CONTEXTUAL_INTENT,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum
              .AGENT_QUESTION,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum
              .AGENT_ANSWER,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum
              .AGENT_ACTION,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum
              .AGENT_COMMAND,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum
              .PARAMETER,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum
              .INTENT_RESOLUTION
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState =
    'DISCRIMINANT_CHANGE_STATE_UNSPECIFIED'|'PENDING_REVIEW'|'APPROVED'|
    'REJECTED';

export interface IGoogleCloudDialogflowAamV2beta1AamDiscriminantChangeStateEnum {
  readonly DISCRIMINANT_CHANGE_STATE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState;
  readonly PENDING_REVIEW:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState;
  readonly APPROVED: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState;
  readonly REJECTED: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState>;
}

export const GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeStateEnum:
    IGoogleCloudDialogflowAamV2beta1AamDiscriminantChangeStateEnum = {
      APPROVED:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState>'APPROVED',
      DISCRIMINANT_CHANGE_STATE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState>'DISCRIMINANT_CHANGE_STATE_UNSPECIFIED',
      PENDING_REVIEW: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState>'PENDING_REVIEW',
      REJECTED:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState>'REJECTED',
      values():
          Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState> {
            return [
              GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeStateEnum
                  .DISCRIMINANT_CHANGE_STATE_UNSPECIFIED,
              GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeStateEnum
                  .PENDING_REVIEW,
              GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeStateEnum
                  .APPROVED,
              GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeStateEnum
                  .REJECTED
            ];
          }
    };

export type GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType =
    'DISCRIMINANT_CHANGE_TYPE_UNSPECIFIED'|'CREATE'|'MERGE'|'SPLIT'|'DELETE'|
    'RENAME'|'BATCH_CREATE';

export interface IGoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum {
  readonly DISCRIMINANT_CHANGE_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType;
  readonly CREATE: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType;
  readonly MERGE: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType;
  readonly SPLIT: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType;
  readonly DELETE: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType;
  readonly RENAME: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType;
  readonly BATCH_CREATE:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType>;
}

export const GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum:
    IGoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum = {
      BATCH_CREATE: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType>'BATCH_CREATE',
      CREATE:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType>'CREATE',
      DELETE:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType>'DELETE',
      DISCRIMINANT_CHANGE_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType>'DISCRIMINANT_CHANGE_TYPE_UNSPECIFIED',
      MERGE: <GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType>'MERGE',
      RENAME:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType>'RENAME',
      SPLIT: <GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType>'SPLIT',
      values(): Array<
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType> {
        return [
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum
              .DISCRIMINANT_CHANGE_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum.CREATE,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum.MERGE,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum.SPLIT,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum.DELETE,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum.RENAME,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum
              .BATCH_CREATE
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus =
    'COMPLETE_STATUS_UNSPECIFIED'|'UNCOMPLETED'|'COMPLETED';

export interface IGoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatusEnum {
  readonly COMPLETE_STATUS_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus;
  readonly UNCOMPLETED:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus;
  readonly COMPLETED:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus>;
}

export const GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatusEnum:
    IGoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatusEnum = {
      COMPLETED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus>'COMPLETED',
      COMPLETE_STATUS_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus>'COMPLETE_STATUS_UNSPECIFIED',
      UNCOMPLETED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus>'UNCOMPLETED',
      values():
          Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus> {
            return [
              GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatusEnum
                  .COMPLETE_STATUS_UNSPECIFIED,
              GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatusEnum
                  .UNCOMPLETED,
              GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatusEnum
                  .COMPLETED
            ];
          }
    };

export type GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus =
    'REVIEW_STATUS_UNSPECIFIED'|'UNREVIEWED'|'REVIEWED';

export interface IGoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatusEnum {
  readonly REVIEW_STATUS_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus;
  readonly UNREVIEWED:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus;
  readonly REVIEWED: GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus>;
}

export const GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatusEnum:
    IGoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatusEnum = {
      REVIEWED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus>'REVIEWED',
      REVIEW_STATUS_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus>'REVIEW_STATUS_UNSPECIFIED',
      UNREVIEWED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus>'UNREVIEWED',
      values():
          Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus> {
            return [
              GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatusEnum
                  .REVIEW_STATUS_UNSPECIFIED,
              GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatusEnum
                  .UNREVIEWED,
              GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatusEnum
                  .REVIEWED
            ];
          }
    };

export type GoogleCloudDialogflowAamV2beta1AamDiscriminantState =
    'STATE_UNSPECIFIED'|'DELETED'|'ACTIVE'|'PENDING'|'RELABELING';

export interface IGoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantState;
  readonly DELETED: GoogleCloudDialogflowAamV2beta1AamDiscriminantState;
  readonly ACTIVE: GoogleCloudDialogflowAamV2beta1AamDiscriminantState;
  readonly PENDING: GoogleCloudDialogflowAamV2beta1AamDiscriminantState;
  readonly RELABELING: GoogleCloudDialogflowAamV2beta1AamDiscriminantState;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantState>;
}

export const GoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum:
    IGoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum = {
      ACTIVE: <GoogleCloudDialogflowAamV2beta1AamDiscriminantState>'ACTIVE',
      DELETED: <GoogleCloudDialogflowAamV2beta1AamDiscriminantState>'DELETED',
      PENDING: <GoogleCloudDialogflowAamV2beta1AamDiscriminantState>'PENDING',
      RELABELING:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantState>'RELABELING',
      STATE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantState>'STATE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantState> {
        return [
          GoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum
              .STATE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum.DELETED,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum.ACTIVE,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum.PENDING,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum.RELABELING
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamDiscriminantType =
    'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
    'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|'PARAMETER'|
    'INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum {
  readonly DISCRIMINANT_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantType;
  readonly DRIVER: GoogleCloudDialogflowAamV2beta1AamDiscriminantType;
  readonly INTENT: GoogleCloudDialogflowAamV2beta1AamDiscriminantType;
  readonly CONTEXTUAL_INTENT:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantType;
  readonly AGENT_QUESTION: GoogleCloudDialogflowAamV2beta1AamDiscriminantType;
  readonly AGENT_ANSWER: GoogleCloudDialogflowAamV2beta1AamDiscriminantType;
  readonly AGENT_ACTION: GoogleCloudDialogflowAamV2beta1AamDiscriminantType;
  readonly AGENT_COMMAND: GoogleCloudDialogflowAamV2beta1AamDiscriminantType;
  readonly PARAMETER: GoogleCloudDialogflowAamV2beta1AamDiscriminantType;
  readonly INTENT_RESOLUTION:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantType;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantType>;
}

export const GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum:
    IGoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum = {
      AGENT_ACTION:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantType>'AGENT_ACTION',
      AGENT_ANSWER:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantType>'AGENT_ANSWER',
      AGENT_COMMAND:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantType>'AGENT_COMMAND',
      AGENT_QUESTION:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantType>'AGENT_QUESTION',
      CONTEXTUAL_INTENT: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantType>'CONTEXTUAL_INTENT',
      DISCRIMINANT_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantType>'DISCRIMINANT_TYPE_UNSPECIFIED',
      DRIVER: <GoogleCloudDialogflowAamV2beta1AamDiscriminantType>'DRIVER',
      INTENT: <GoogleCloudDialogflowAamV2beta1AamDiscriminantType>'INTENT',
      INTENT_RESOLUTION: <
          GoogleCloudDialogflowAamV2beta1AamDiscriminantType>'INTENT_RESOLUTION',
      PARAMETER:
          <GoogleCloudDialogflowAamV2beta1AamDiscriminantType>'PARAMETER',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantType> {
        return [
          GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum
              .DISCRIMINANT_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum.DRIVER,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum.INTENT,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum
              .CONTEXTUAL_INTENT,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum.AGENT_QUESTION,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum.AGENT_ANSWER,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum.AGENT_ACTION,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum.AGENT_COMMAND,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum.PARAMETER,
          GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum
              .INTENT_RESOLUTION
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamLabelLabelSource =
    'LABEL_SOURCE_UNSPECIFIED'|'LABEL_SOURCE_BATCH_LABELING'|
    'LABEL_SOURCE_ACTIVE_LEARNING';

export interface IGoogleCloudDialogflowAamV2beta1AamLabelLabelSourceEnum {
  readonly LABEL_SOURCE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamLabelLabelSource;
  readonly LABEL_SOURCE_BATCH_LABELING:
      GoogleCloudDialogflowAamV2beta1AamLabelLabelSource;
  readonly LABEL_SOURCE_ACTIVE_LEARNING:
      GoogleCloudDialogflowAamV2beta1AamLabelLabelSource;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamLabelLabelSource>;
}

export const GoogleCloudDialogflowAamV2beta1AamLabelLabelSourceEnum:
    IGoogleCloudDialogflowAamV2beta1AamLabelLabelSourceEnum = {
      LABEL_SOURCE_ACTIVE_LEARNING: <
          GoogleCloudDialogflowAamV2beta1AamLabelLabelSource>'LABEL_SOURCE_ACTIVE_LEARNING',
      LABEL_SOURCE_BATCH_LABELING: <
          GoogleCloudDialogflowAamV2beta1AamLabelLabelSource>'LABEL_SOURCE_BATCH_LABELING',
      LABEL_SOURCE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamLabelLabelSource>'LABEL_SOURCE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamLabelLabelSource> {
        return [
          GoogleCloudDialogflowAamV2beta1AamLabelLabelSourceEnum
              .LABEL_SOURCE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamLabelLabelSourceEnum
              .LABEL_SOURCE_BATCH_LABELING,
          GoogleCloudDialogflowAamV2beta1AamLabelLabelSourceEnum
              .LABEL_SOURCE_ACTIVE_LEARNING
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamLabelPositivity =
    'POSITIVITY_UNSPECIFIED'|'POSITIVE'|'NEGATIVE';

export interface IGoogleCloudDialogflowAamV2beta1AamLabelPositivityEnum {
  readonly POSITIVITY_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamLabelPositivity;
  readonly POSITIVE: GoogleCloudDialogflowAamV2beta1AamLabelPositivity;
  readonly NEGATIVE: GoogleCloudDialogflowAamV2beta1AamLabelPositivity;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamLabelPositivity>;
}

export const GoogleCloudDialogflowAamV2beta1AamLabelPositivityEnum:
    IGoogleCloudDialogflowAamV2beta1AamLabelPositivityEnum = {
      NEGATIVE: <GoogleCloudDialogflowAamV2beta1AamLabelPositivity>'NEGATIVE',
      POSITIVE: <GoogleCloudDialogflowAamV2beta1AamLabelPositivity>'POSITIVE',
      POSITIVITY_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamLabelPositivity>'POSITIVITY_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamLabelPositivity> {
        return [
          GoogleCloudDialogflowAamV2beta1AamLabelPositivityEnum
              .POSITIVITY_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamLabelPositivityEnum.POSITIVE,
          GoogleCloudDialogflowAamV2beta1AamLabelPositivityEnum.NEGATIVE
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamLabelReviewState =
    'REVIEW_STATE_UNSPECIFIED'|'REVIEWED'|'NOT_REVIEWED';

export interface IGoogleCloudDialogflowAamV2beta1AamLabelReviewStateEnum {
  readonly REVIEW_STATE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamLabelReviewState;
  readonly REVIEWED: GoogleCloudDialogflowAamV2beta1AamLabelReviewState;
  readonly NOT_REVIEWED: GoogleCloudDialogflowAamV2beta1AamLabelReviewState;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamLabelReviewState>;
}

export const GoogleCloudDialogflowAamV2beta1AamLabelReviewStateEnum:
    IGoogleCloudDialogflowAamV2beta1AamLabelReviewStateEnum = {
      NOT_REVIEWED:
          <GoogleCloudDialogflowAamV2beta1AamLabelReviewState>'NOT_REVIEWED',
      REVIEWED: <GoogleCloudDialogflowAamV2beta1AamLabelReviewState>'REVIEWED',
      REVIEW_STATE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamLabelReviewState>'REVIEW_STATE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamLabelReviewState> {
        return [
          GoogleCloudDialogflowAamV2beta1AamLabelReviewStateEnum
              .REVIEW_STATE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamLabelReviewStateEnum.REVIEWED,
          GoogleCloudDialogflowAamV2beta1AamLabelReviewStateEnum.NOT_REVIEWED
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType =
    'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
    'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|'PARAMETER'|
    'INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum {
  readonly DISCRIMINANT_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType;
  readonly DRIVER:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType;
  readonly INTENT:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType;
  readonly CONTEXTUAL_INTENT:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType;
  readonly AGENT_QUESTION:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType;
  readonly AGENT_ANSWER:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType;
  readonly AGENT_ACTION:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType;
  readonly AGENT_COMMAND:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType;
  readonly PARAMETER:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType;
  readonly INTENT_RESOLUTION:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType;

  values():
      Array<GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>;
}

export const GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum:
    IGoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum = {
      AGENT_ACTION: <
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>'AGENT_ACTION',
      AGENT_ANSWER: <
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>'AGENT_ANSWER',
      AGENT_COMMAND: <
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>'AGENT_COMMAND',
      AGENT_QUESTION: <
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>'AGENT_QUESTION',
      CONTEXTUAL_INTENT: <
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>'CONTEXTUAL_INTENT',
      DISCRIMINANT_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>'DISCRIMINANT_TYPE_UNSPECIFIED',
      DRIVER: <
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>'DRIVER',
      INTENT: <
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>'INTENT',
      INTENT_RESOLUTION: <
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>'INTENT_RESOLUTION',
      PARAMETER: <
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType>'PARAMETER',
      values(): Array<
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType> {
        return [
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
              .DISCRIMINANT_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
              .DRIVER,
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
              .INTENT,
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
              .CONTEXTUAL_INTENT,
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
              .AGENT_QUESTION,
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
              .AGENT_ANSWER,
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
              .AGENT_ACTION,
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
              .AGENT_COMMAND,
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
              .PARAMETER,
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
              .INTENT_RESOLUTION
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamLabelType =
    'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
    'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|'PARAMETER'|
    'INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1AamLabelTypeEnum {
  readonly DISCRIMINANT_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamLabelType;
  readonly DRIVER: GoogleCloudDialogflowAamV2beta1AamLabelType;
  readonly INTENT: GoogleCloudDialogflowAamV2beta1AamLabelType;
  readonly CONTEXTUAL_INTENT: GoogleCloudDialogflowAamV2beta1AamLabelType;
  readonly AGENT_QUESTION: GoogleCloudDialogflowAamV2beta1AamLabelType;
  readonly AGENT_ANSWER: GoogleCloudDialogflowAamV2beta1AamLabelType;
  readonly AGENT_ACTION: GoogleCloudDialogflowAamV2beta1AamLabelType;
  readonly AGENT_COMMAND: GoogleCloudDialogflowAamV2beta1AamLabelType;
  readonly PARAMETER: GoogleCloudDialogflowAamV2beta1AamLabelType;
  readonly INTENT_RESOLUTION: GoogleCloudDialogflowAamV2beta1AamLabelType;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamLabelType>;
}

export const GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum:
    IGoogleCloudDialogflowAamV2beta1AamLabelTypeEnum = {
      AGENT_ACTION: <GoogleCloudDialogflowAamV2beta1AamLabelType>'AGENT_ACTION',
      AGENT_ANSWER: <GoogleCloudDialogflowAamV2beta1AamLabelType>'AGENT_ANSWER',
      AGENT_COMMAND:
          <GoogleCloudDialogflowAamV2beta1AamLabelType>'AGENT_COMMAND',
      AGENT_QUESTION:
          <GoogleCloudDialogflowAamV2beta1AamLabelType>'AGENT_QUESTION',
      CONTEXTUAL_INTENT:
          <GoogleCloudDialogflowAamV2beta1AamLabelType>'CONTEXTUAL_INTENT',
      DISCRIMINANT_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamLabelType>'DISCRIMINANT_TYPE_UNSPECIFIED',
      DRIVER: <GoogleCloudDialogflowAamV2beta1AamLabelType>'DRIVER',
      INTENT: <GoogleCloudDialogflowAamV2beta1AamLabelType>'INTENT',
      INTENT_RESOLUTION:
          <GoogleCloudDialogflowAamV2beta1AamLabelType>'INTENT_RESOLUTION',
      PARAMETER: <GoogleCloudDialogflowAamV2beta1AamLabelType>'PARAMETER',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamLabelType> {
        return [
          GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum
              .DISCRIMINANT_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum.DRIVER,
          GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum.INTENT,
          GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum.CONTEXTUAL_INTENT,
          GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum.AGENT_QUESTION,
          GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum.AGENT_ANSWER,
          GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum.AGENT_ACTION,
          GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum.AGENT_COMMAND,
          GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum.PARAMETER,
          GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum.INTENT_RESOLUTION
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamNoticeState = 'STATE_UNSPECIFIED'|
    'AVAILABLE'|'REVIEWING'|'DISMISSED'|'RESOLVED'|'OBSOLETE';

export interface IGoogleCloudDialogflowAamV2beta1AamNoticeStateEnum {
  readonly STATE_UNSPECIFIED: GoogleCloudDialogflowAamV2beta1AamNoticeState;
  readonly AVAILABLE: GoogleCloudDialogflowAamV2beta1AamNoticeState;
  readonly REVIEWING: GoogleCloudDialogflowAamV2beta1AamNoticeState;
  readonly DISMISSED: GoogleCloudDialogflowAamV2beta1AamNoticeState;
  readonly RESOLVED: GoogleCloudDialogflowAamV2beta1AamNoticeState;
  readonly OBSOLETE: GoogleCloudDialogflowAamV2beta1AamNoticeState;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamNoticeState>;
}

export const GoogleCloudDialogflowAamV2beta1AamNoticeStateEnum:
    IGoogleCloudDialogflowAamV2beta1AamNoticeStateEnum = {
      AVAILABLE: <GoogleCloudDialogflowAamV2beta1AamNoticeState>'AVAILABLE',
      DISMISSED: <GoogleCloudDialogflowAamV2beta1AamNoticeState>'DISMISSED',
      OBSOLETE: <GoogleCloudDialogflowAamV2beta1AamNoticeState>'OBSOLETE',
      RESOLVED: <GoogleCloudDialogflowAamV2beta1AamNoticeState>'RESOLVED',
      REVIEWING: <GoogleCloudDialogflowAamV2beta1AamNoticeState>'REVIEWING',
      STATE_UNSPECIFIED:
          <GoogleCloudDialogflowAamV2beta1AamNoticeState>'STATE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamNoticeState> {
        return [
          GoogleCloudDialogflowAamV2beta1AamNoticeStateEnum.STATE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamNoticeStateEnum.AVAILABLE,
          GoogleCloudDialogflowAamV2beta1AamNoticeStateEnum.REVIEWING,
          GoogleCloudDialogflowAamV2beta1AamNoticeStateEnum.DISMISSED,
          GoogleCloudDialogflowAamV2beta1AamNoticeStateEnum.RESOLVED,
          GoogleCloudDialogflowAamV2beta1AamNoticeStateEnum.OBSOLETE
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamNoticeType =
    'TYPE_UNSPECIFIED'|'SPLIT_RELABEL'|'SIMILAR_TRAINING_PHRASE'|
    'OVERLAPPING_DISCRIMINANTS'|'UTTERANCE_WITH_WRONG_LABEL'|'DELETE_RELABEL'|
    'FLAGGED_LABEL'|'FLAGGED_DISCRIMINANT'|'SPLIT_DISCRIMINANT'|
    'OUTLIER_UTTERANCE_LABEL'|'TOO_COHESIVE_DISCRIMINANT';

export interface IGoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum {
  readonly TYPE_UNSPECIFIED: GoogleCloudDialogflowAamV2beta1AamNoticeType;
  readonly SPLIT_RELABEL: GoogleCloudDialogflowAamV2beta1AamNoticeType;
  readonly SIMILAR_TRAINING_PHRASE:
      GoogleCloudDialogflowAamV2beta1AamNoticeType;
  readonly OVERLAPPING_DISCRIMINANTS:
      GoogleCloudDialogflowAamV2beta1AamNoticeType;
  readonly UTTERANCE_WITH_WRONG_LABEL:
      GoogleCloudDialogflowAamV2beta1AamNoticeType;
  readonly DELETE_RELABEL: GoogleCloudDialogflowAamV2beta1AamNoticeType;
  readonly FLAGGED_LABEL: GoogleCloudDialogflowAamV2beta1AamNoticeType;
  readonly FLAGGED_DISCRIMINANT: GoogleCloudDialogflowAamV2beta1AamNoticeType;
  readonly SPLIT_DISCRIMINANT: GoogleCloudDialogflowAamV2beta1AamNoticeType;
  readonly OUTLIER_UTTERANCE_LABEL:
      GoogleCloudDialogflowAamV2beta1AamNoticeType;
  readonly TOO_COHESIVE_DISCRIMINANT:
      GoogleCloudDialogflowAamV2beta1AamNoticeType;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamNoticeType>;
}

export const GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum:
    IGoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum = {
      DELETE_RELABEL:
          <GoogleCloudDialogflowAamV2beta1AamNoticeType>'DELETE_RELABEL',
      FLAGGED_DISCRIMINANT:
          <GoogleCloudDialogflowAamV2beta1AamNoticeType>'FLAGGED_DISCRIMINANT',
      FLAGGED_LABEL:
          <GoogleCloudDialogflowAamV2beta1AamNoticeType>'FLAGGED_LABEL',
      OUTLIER_UTTERANCE_LABEL: <
          GoogleCloudDialogflowAamV2beta1AamNoticeType>'OUTLIER_UTTERANCE_LABEL',
      OVERLAPPING_DISCRIMINANTS: <
          GoogleCloudDialogflowAamV2beta1AamNoticeType>'OVERLAPPING_DISCRIMINANTS',
      SIMILAR_TRAINING_PHRASE: <
          GoogleCloudDialogflowAamV2beta1AamNoticeType>'SIMILAR_TRAINING_PHRASE',
      SPLIT_DISCRIMINANT:
          <GoogleCloudDialogflowAamV2beta1AamNoticeType>'SPLIT_DISCRIMINANT',
      SPLIT_RELABEL:
          <GoogleCloudDialogflowAamV2beta1AamNoticeType>'SPLIT_RELABEL',
      TOO_COHESIVE_DISCRIMINANT: <
          GoogleCloudDialogflowAamV2beta1AamNoticeType>'TOO_COHESIVE_DISCRIMINANT',
      TYPE_UNSPECIFIED:
          <GoogleCloudDialogflowAamV2beta1AamNoticeType>'TYPE_UNSPECIFIED',
      UTTERANCE_WITH_WRONG_LABEL: <
          GoogleCloudDialogflowAamV2beta1AamNoticeType>'UTTERANCE_WITH_WRONG_LABEL',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamNoticeType> {
        return [
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum.TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum.SPLIT_RELABEL,
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum
              .SIMILAR_TRAINING_PHRASE,
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum
              .OVERLAPPING_DISCRIMINANTS,
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum
              .UTTERANCE_WITH_WRONG_LABEL,
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum.DELETE_RELABEL,
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum.FLAGGED_LABEL,
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum.FLAGGED_DISCRIMINANT,
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum.SPLIT_DISCRIMINANT,
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum
              .OUTLIER_UTTERANCE_LABEL,
          GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum
              .TOO_COHESIVE_DISCRIMINANT
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType =
    'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
    'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|'PARAMETER'|
    'INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum {
  readonly DISCRIMINANT_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType;
  readonly DRIVER: GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType;
  readonly INTENT: GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType;
  readonly CONTEXTUAL_INTENT:
      GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType;
  readonly AGENT_QUESTION:
      GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType;
  readonly AGENT_ANSWER: GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType;
  readonly AGENT_ACTION: GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType;
  readonly AGENT_COMMAND:
      GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType;
  readonly PARAMETER: GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType;
  readonly INTENT_RESOLUTION:
      GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>;
}

export const GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum:
    IGoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum = {
      AGENT_ACTION: <
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>'AGENT_ACTION',
      AGENT_ANSWER: <
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>'AGENT_ANSWER',
      AGENT_COMMAND: <
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>'AGENT_COMMAND',
      AGENT_QUESTION: <
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>'AGENT_QUESTION',
      CONTEXTUAL_INTENT: <
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>'CONTEXTUAL_INTENT',
      DISCRIMINANT_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>'DISCRIMINANT_TYPE_UNSPECIFIED',
      DRIVER: <GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>'DRIVER',
      INTENT: <GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>'INTENT',
      INTENT_RESOLUTION: <
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>'INTENT_RESOLUTION',
      PARAMETER:
          <GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType>'PARAMETER',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType> {
        return [
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum
              .DISCRIMINANT_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum.DRIVER,
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum.INTENT,
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum
              .CONTEXTUAL_INTENT,
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum
              .AGENT_QUESTION,
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum
              .AGENT_ANSWER,
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum
              .AGENT_ACTION,
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum
              .AGENT_COMMAND,
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum.PARAMETER,
          GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum
              .INTENT_RESOLUTION
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod =
    'SAMPLE_METHOD_UNSPECIFIED'|'UNIFORM_SAMPLING'|'BINARY_TOP_SAMPLING'|
    'GENERATED_FROM_NOTICE'|'FLOW_MODELLING'|'GENERATED_FROM_TRANSCRIPT'|
    'CLUSTER_LABELING';

export interface IGoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum {
  readonly SAMPLE_METHOD_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod;
  readonly UNIFORM_SAMPLING: GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod;
  readonly BINARY_TOP_SAMPLING:
      GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod;
  readonly GENERATED_FROM_NOTICE:
      GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod;
  readonly FLOW_MODELLING: GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod;
  readonly GENERATED_FROM_TRANSCRIPT:
      GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod;
  readonly CLUSTER_LABELING: GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod>;
}

export const GoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum:
    IGoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum = {
      BINARY_TOP_SAMPLING: <
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod>'BINARY_TOP_SAMPLING',
      CLUSTER_LABELING: <
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod>'CLUSTER_LABELING',
      FLOW_MODELLING:
          <GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod>'FLOW_MODELLING',
      GENERATED_FROM_NOTICE: <
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod>'GENERATED_FROM_NOTICE',
      GENERATED_FROM_TRANSCRIPT: <
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod>'GENERATED_FROM_TRANSCRIPT',
      SAMPLE_METHOD_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod>'SAMPLE_METHOD_UNSPECIFIED',
      UNIFORM_SAMPLING: <
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod>'UNIFORM_SAMPLING',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod> {
        return [
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum
              .SAMPLE_METHOD_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum
              .UNIFORM_SAMPLING,
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum
              .BINARY_TOP_SAMPLING,
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum
              .GENERATED_FROM_NOTICE,
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum.FLOW_MODELLING,
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum
              .GENERATED_FROM_TRANSCRIPT,
          GoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum
              .CLUSTER_LABELING
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamTaskState =
    'STATE_UNSPECIFIED'|'AVAILABLE'|'IN_PROGRESS'|'COMPLETED';

export interface IGoogleCloudDialogflowAamV2beta1AamTaskStateEnum {
  readonly STATE_UNSPECIFIED: GoogleCloudDialogflowAamV2beta1AamTaskState;
  readonly AVAILABLE: GoogleCloudDialogflowAamV2beta1AamTaskState;
  readonly IN_PROGRESS: GoogleCloudDialogflowAamV2beta1AamTaskState;
  readonly COMPLETED: GoogleCloudDialogflowAamV2beta1AamTaskState;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamTaskState>;
}

export const GoogleCloudDialogflowAamV2beta1AamTaskStateEnum:
    IGoogleCloudDialogflowAamV2beta1AamTaskStateEnum = {
      AVAILABLE: <GoogleCloudDialogflowAamV2beta1AamTaskState>'AVAILABLE',
      COMPLETED: <GoogleCloudDialogflowAamV2beta1AamTaskState>'COMPLETED',
      IN_PROGRESS: <GoogleCloudDialogflowAamV2beta1AamTaskState>'IN_PROGRESS',
      STATE_UNSPECIFIED:
          <GoogleCloudDialogflowAamV2beta1AamTaskState>'STATE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamTaskState> {
        return [
          GoogleCloudDialogflowAamV2beta1AamTaskStateEnum.STATE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamTaskStateEnum.AVAILABLE,
          GoogleCloudDialogflowAamV2beta1AamTaskStateEnum.IN_PROGRESS,
          GoogleCloudDialogflowAamV2beta1AamTaskStateEnum.COMPLETED
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType =
    'CLUSTER_TYPE_UNSPECIFIED'|'CLUSTER_TYPE_INTENT'|
    'CLUSTER_TYPE_INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1AamTopicClusterClusterTypeEnum {
  readonly CLUSTER_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType;
  readonly CLUSTER_TYPE_INTENT:
      GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType;
  readonly CLUSTER_TYPE_INTENT_RESOLUTION:
      GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType>;
}

export const GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterTypeEnum:
    IGoogleCloudDialogflowAamV2beta1AamTopicClusterClusterTypeEnum = {
      CLUSTER_TYPE_INTENT: <
          GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType>'CLUSTER_TYPE_INTENT',
      CLUSTER_TYPE_INTENT_RESOLUTION: <
          GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType>'CLUSTER_TYPE_INTENT_RESOLUTION',
      CLUSTER_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType>'CLUSTER_TYPE_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType> {
            return [
              GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterTypeEnum
                  .CLUSTER_TYPE_UNSPECIFIED,
              GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterTypeEnum
                  .CLUSTER_TYPE_INTENT,
              GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterTypeEnum
                  .CLUSTER_TYPE_INTENT_RESOLUTION
            ];
          }
    };

export type GoogleCloudDialogflowAamV2beta1AamTrainingRunState =
    'STATE_UNSPECIFIED'|'STARTED'|'TRAINING'|'CLASSIFYING_SUCCEEDED'|
    'TRAINING_FAILED'|'TRAINING_FINISHED';

export interface IGoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamTrainingRunState;
  readonly STARTED: GoogleCloudDialogflowAamV2beta1AamTrainingRunState;
  readonly TRAINING: GoogleCloudDialogflowAamV2beta1AamTrainingRunState;
  readonly CLASSIFYING_SUCCEEDED:
      GoogleCloudDialogflowAamV2beta1AamTrainingRunState;
  readonly TRAINING_FAILED: GoogleCloudDialogflowAamV2beta1AamTrainingRunState;
  readonly TRAINING_FINISHED:
      GoogleCloudDialogflowAamV2beta1AamTrainingRunState;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamTrainingRunState>;
}

export const GoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum:
    IGoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum = {
      CLASSIFYING_SUCCEEDED: <
          GoogleCloudDialogflowAamV2beta1AamTrainingRunState>'CLASSIFYING_SUCCEEDED',
      STARTED: <GoogleCloudDialogflowAamV2beta1AamTrainingRunState>'STARTED',
      STATE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamTrainingRunState>'STATE_UNSPECIFIED',
      TRAINING: <GoogleCloudDialogflowAamV2beta1AamTrainingRunState>'TRAINING',
      TRAINING_FAILED:
          <GoogleCloudDialogflowAamV2beta1AamTrainingRunState>'TRAINING_FAILED',
      TRAINING_FINISHED: <
          GoogleCloudDialogflowAamV2beta1AamTrainingRunState>'TRAINING_FINISHED',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamTrainingRunState> {
        return [
          GoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum
              .STATE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum.STARTED,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum.TRAINING,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum
              .CLASSIFYING_SUCCEEDED,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum
              .TRAINING_FAILED,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum
              .TRAINING_FINISHED
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamTrainingRunType =
    'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
    'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|'PARAMETER'|
    'INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum {
  readonly DISCRIMINANT_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamTrainingRunType;
  readonly DRIVER: GoogleCloudDialogflowAamV2beta1AamTrainingRunType;
  readonly INTENT: GoogleCloudDialogflowAamV2beta1AamTrainingRunType;
  readonly CONTEXTUAL_INTENT: GoogleCloudDialogflowAamV2beta1AamTrainingRunType;
  readonly AGENT_QUESTION: GoogleCloudDialogflowAamV2beta1AamTrainingRunType;
  readonly AGENT_ANSWER: GoogleCloudDialogflowAamV2beta1AamTrainingRunType;
  readonly AGENT_ACTION: GoogleCloudDialogflowAamV2beta1AamTrainingRunType;
  readonly AGENT_COMMAND: GoogleCloudDialogflowAamV2beta1AamTrainingRunType;
  readonly PARAMETER: GoogleCloudDialogflowAamV2beta1AamTrainingRunType;
  readonly INTENT_RESOLUTION: GoogleCloudDialogflowAamV2beta1AamTrainingRunType;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamTrainingRunType>;
}

export const GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum:
    IGoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum = {
      AGENT_ACTION:
          <GoogleCloudDialogflowAamV2beta1AamTrainingRunType>'AGENT_ACTION',
      AGENT_ANSWER:
          <GoogleCloudDialogflowAamV2beta1AamTrainingRunType>'AGENT_ANSWER',
      AGENT_COMMAND:
          <GoogleCloudDialogflowAamV2beta1AamTrainingRunType>'AGENT_COMMAND',
      AGENT_QUESTION:
          <GoogleCloudDialogflowAamV2beta1AamTrainingRunType>'AGENT_QUESTION',
      CONTEXTUAL_INTENT: <
          GoogleCloudDialogflowAamV2beta1AamTrainingRunType>'CONTEXTUAL_INTENT',
      DISCRIMINANT_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamTrainingRunType>'DISCRIMINANT_TYPE_UNSPECIFIED',
      DRIVER: <GoogleCloudDialogflowAamV2beta1AamTrainingRunType>'DRIVER',
      INTENT: <GoogleCloudDialogflowAamV2beta1AamTrainingRunType>'INTENT',
      INTENT_RESOLUTION: <
          GoogleCloudDialogflowAamV2beta1AamTrainingRunType>'INTENT_RESOLUTION',
      PARAMETER: <GoogleCloudDialogflowAamV2beta1AamTrainingRunType>'PARAMETER',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamTrainingRunType> {
        return [
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum
              .DISCRIMINANT_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum.DRIVER,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum.INTENT,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum
              .CONTEXTUAL_INTENT,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum.AGENT_QUESTION,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum.AGENT_ANSWER,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum.AGENT_ACTION,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum.AGENT_COMMAND,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum.PARAMETER,
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum
              .INTENT_RESOLUTION
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant =
    'PARTICIPANT_UNSPECIFIED'|'CUSTOMER'|'AGENT'|'VIRTUAL_AGENT';

export interface IGoogleCloudDialogflowAamV2beta1AamUtteranceParticipantEnum {
  readonly PARTICIPANT_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant;
  readonly CUSTOMER: GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant;
  readonly AGENT: GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant;
  readonly VIRTUAL_AGENT:
      GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant;

  values(): Array<GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant>;
}

export const GoogleCloudDialogflowAamV2beta1AamUtteranceParticipantEnum:
    IGoogleCloudDialogflowAamV2beta1AamUtteranceParticipantEnum = {
      AGENT: <GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant>'AGENT',
      CUSTOMER:
          <GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant>'CUSTOMER',
      PARTICIPANT_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant>'PARTICIPANT_UNSPECIFIED',
      VIRTUAL_AGENT: <
          GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant>'VIRTUAL_AGENT',
      values(): Array<GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant> {
        return [
          GoogleCloudDialogflowAamV2beta1AamUtteranceParticipantEnum
              .PARTICIPANT_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AamUtteranceParticipantEnum.CUSTOMER,
          GoogleCloudDialogflowAamV2beta1AamUtteranceParticipantEnum.AGENT,
          GoogleCloudDialogflowAamV2beta1AamUtteranceParticipantEnum
              .VIRTUAL_AGENT
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AddUserRequestRole =
    'AAM_ROLE_UNSPECIFIED'|'AAM_ROLE_ADMIN'|'AAM_ROLE_DIALOG_DESIGNER'|
    'AAM_ROLE_LEAD_DIALOG_DESIGNER'|'AAM_ROLE_CONVERSATIONAL_ARCHITECT'|
    'AAM_ROLE_VIEWER';

export interface IGoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum {
  readonly AAM_ROLE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AddUserRequestRole;
  readonly AAM_ROLE_ADMIN: GoogleCloudDialogflowAamV2beta1AddUserRequestRole;
  readonly AAM_ROLE_DIALOG_DESIGNER:
      GoogleCloudDialogflowAamV2beta1AddUserRequestRole;
  readonly AAM_ROLE_LEAD_DIALOG_DESIGNER:
      GoogleCloudDialogflowAamV2beta1AddUserRequestRole;
  readonly AAM_ROLE_CONVERSATIONAL_ARCHITECT:
      GoogleCloudDialogflowAamV2beta1AddUserRequestRole;
  readonly AAM_ROLE_VIEWER: GoogleCloudDialogflowAamV2beta1AddUserRequestRole;

  values(): Array<GoogleCloudDialogflowAamV2beta1AddUserRequestRole>;
}

export const GoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum:
    IGoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum = {
      AAM_ROLE_ADMIN:
          <GoogleCloudDialogflowAamV2beta1AddUserRequestRole>'AAM_ROLE_ADMIN',
      AAM_ROLE_CONVERSATIONAL_ARCHITECT: <
          GoogleCloudDialogflowAamV2beta1AddUserRequestRole>'AAM_ROLE_CONVERSATIONAL_ARCHITECT',
      AAM_ROLE_DIALOG_DESIGNER: <
          GoogleCloudDialogflowAamV2beta1AddUserRequestRole>'AAM_ROLE_DIALOG_DESIGNER',
      AAM_ROLE_LEAD_DIALOG_DESIGNER: <
          GoogleCloudDialogflowAamV2beta1AddUserRequestRole>'AAM_ROLE_LEAD_DIALOG_DESIGNER',
      AAM_ROLE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AddUserRequestRole>'AAM_ROLE_UNSPECIFIED',
      AAM_ROLE_VIEWER:
          <GoogleCloudDialogflowAamV2beta1AddUserRequestRole>'AAM_ROLE_VIEWER',
      values(): Array<GoogleCloudDialogflowAamV2beta1AddUserRequestRole> {
        return [
          GoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum
              .AAM_ROLE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum.AAM_ROLE_ADMIN,
          GoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum
              .AAM_ROLE_DIALOG_DESIGNER,
          GoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum
              .AAM_ROLE_LEAD_DIALOG_DESIGNER,
          GoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum
              .AAM_ROLE_CONVERSATIONAL_ARCHITECT,
          GoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum.AAM_ROLE_VIEWER
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType =
    'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
    'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|'PARAMETER'|
    'INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum {
  readonly DISCRIMINANT_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType;
  readonly DRIVER:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType;
  readonly INTENT:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType;
  readonly CONTEXTUAL_INTENT:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType;
  readonly AGENT_QUESTION:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType;
  readonly AGENT_ANSWER:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType;
  readonly AGENT_ACTION:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType;
  readonly AGENT_COMMAND:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType;
  readonly PARAMETER:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType;
  readonly INTENT_RESOLUTION:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType;

  values():
      Array<GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>;
}

export const GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum:
    IGoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum = {
      AGENT_ACTION: <
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>'AGENT_ACTION',
      AGENT_ANSWER: <
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>'AGENT_ANSWER',
      AGENT_COMMAND: <
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>'AGENT_COMMAND',
      AGENT_QUESTION: <
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>'AGENT_QUESTION',
      CONTEXTUAL_INTENT: <
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>'CONTEXTUAL_INTENT',
      DISCRIMINANT_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>'DISCRIMINANT_TYPE_UNSPECIFIED',
      DRIVER: <
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>'DRIVER',
      INTENT: <
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>'INTENT',
      INTENT_RESOLUTION: <
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>'INTENT_RESOLUTION',
      PARAMETER: <
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType>'PARAMETER',
      values(): Array<
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType> {
        return [
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
              .DISCRIMINANT_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
              .DRIVER,
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
              .INTENT,
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
              .CONTEXTUAL_INTENT,
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
              .AGENT_QUESTION,
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
              .AGENT_ANSWER,
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
              .AGENT_ACTION,
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
              .AGENT_COMMAND,
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
              .PARAMETER,
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
              .INTENT_RESOLUTION
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap =
    'STATE_UNSPECIFIED'|'COMPLETED'|'PENDING';

export interface IGoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMapEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap;
  readonly COMPLETED:
      GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap;
  readonly PENDING:
      GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap;

  values(): Array<
      GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap>;
}

export const GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMapEnum:
    IGoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMapEnum = {
      COMPLETED: <
          GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap>'COMPLETED',
      PENDING: <
          GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap>'PENDING',
      STATE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap>'STATE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap> {
        return [
          GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMapEnum
              .STATE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMapEnum
              .COMPLETED,
          GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMapEnum
              .PENDING
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType =
    'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
    'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|'PARAMETER'|
    'INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum {
  readonly DISCRIMINANT_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType;
  readonly DRIVER: GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType;
  readonly INTENT: GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType;
  readonly CONTEXTUAL_INTENT:
      GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType;
  readonly AGENT_QUESTION:
      GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType;
  readonly AGENT_ANSWER:
      GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType;
  readonly AGENT_ACTION:
      GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType;
  readonly AGENT_COMMAND:
      GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType;
  readonly PARAMETER: GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType;
  readonly INTENT_RESOLUTION:
      GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType;

  values(): Array<GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>;
}

export const GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum:
    IGoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum = {
      AGENT_ACTION: <
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>'AGENT_ACTION',
      AGENT_ANSWER: <
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>'AGENT_ANSWER',
      AGENT_COMMAND: <
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>'AGENT_COMMAND',
      AGENT_QUESTION: <
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>'AGENT_QUESTION',
      CONTEXTUAL_INTENT: <
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>'CONTEXTUAL_INTENT',
      DISCRIMINANT_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>'DISCRIMINANT_TYPE_UNSPECIFIED',
      DRIVER:
          <GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>'DRIVER',
      INTENT:
          <GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>'INTENT',
      INTENT_RESOLUTION: <
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>'INTENT_RESOLUTION',
      PARAMETER: <
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType>'PARAMETER',
      values(): Array<
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType> {
        return [
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum
              .DISCRIMINANT_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum.DRIVER,
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum.INTENT,
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum
              .CONTEXTUAL_INTENT,
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum
              .AGENT_QUESTION,
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum
              .AGENT_ANSWER,
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum
              .AGENT_ACTION,
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum
              .AGENT_COMMAND,
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum
              .PARAMETER,
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum
              .INTENT_RESOLUTION
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter =
    'PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED'|'CUSTOM'|'TELECOMMUNICATIONS'|
    'FINANCIAL_SERVICES';

export interface IGoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilterEnum {
  readonly PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter;
  readonly CUSTOM:
      GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter;
  readonly TELECOMMUNICATIONS:
      GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter;
  readonly FINANCIAL_SERVICES:
      GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter;

  values(): Array<
      GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter>;
}

export const GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilterEnum:
    IGoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilterEnum = {
      CUSTOM: <
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter>'CUSTOM',
      FINANCIAL_SERVICES: <
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter>'FINANCIAL_SERVICES',
      PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter>'PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED',
      TELECOMMUNICATIONS: <
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter>'TELECOMMUNICATIONS',
      values(): Array<
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter> {
        return [
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilterEnum
              .PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilterEnum
              .CUSTOM,
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilterEnum
              .TELECOMMUNICATIONS,
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilterEnum
              .FINANCIAL_SERVICES
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1NodeGroupingType =
    'NODE_GROUPING_TYPE_UNSPECIFIED'|'GROUPED'|'UNGROUPED';

export interface IGoogleCloudDialogflowAamV2beta1NodeGroupingTypeEnum {
  readonly NODE_GROUPING_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1NodeGroupingType;
  readonly GROUPED: GoogleCloudDialogflowAamV2beta1NodeGroupingType;
  readonly UNGROUPED: GoogleCloudDialogflowAamV2beta1NodeGroupingType;

  values(): Array<GoogleCloudDialogflowAamV2beta1NodeGroupingType>;
}

export const GoogleCloudDialogflowAamV2beta1NodeGroupingTypeEnum:
    IGoogleCloudDialogflowAamV2beta1NodeGroupingTypeEnum = {
      GROUPED: <GoogleCloudDialogflowAamV2beta1NodeGroupingType>'GROUPED',
      NODE_GROUPING_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1NodeGroupingType>'NODE_GROUPING_TYPE_UNSPECIFIED',
      UNGROUPED: <GoogleCloudDialogflowAamV2beta1NodeGroupingType>'UNGROUPED',
      values(): Array<GoogleCloudDialogflowAamV2beta1NodeGroupingType> {
        return [
          GoogleCloudDialogflowAamV2beta1NodeGroupingTypeEnum
              .NODE_GROUPING_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1NodeGroupingTypeEnum.GROUPED,
          GoogleCloudDialogflowAamV2beta1NodeGroupingTypeEnum.UNGROUPED
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1NodeType =
    'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
    'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|'PARAMETER'|
    'INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1NodeTypeEnum {
  readonly DISCRIMINANT_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1NodeType;
  readonly DRIVER: GoogleCloudDialogflowAamV2beta1NodeType;
  readonly INTENT: GoogleCloudDialogflowAamV2beta1NodeType;
  readonly CONTEXTUAL_INTENT: GoogleCloudDialogflowAamV2beta1NodeType;
  readonly AGENT_QUESTION: GoogleCloudDialogflowAamV2beta1NodeType;
  readonly AGENT_ANSWER: GoogleCloudDialogflowAamV2beta1NodeType;
  readonly AGENT_ACTION: GoogleCloudDialogflowAamV2beta1NodeType;
  readonly AGENT_COMMAND: GoogleCloudDialogflowAamV2beta1NodeType;
  readonly PARAMETER: GoogleCloudDialogflowAamV2beta1NodeType;
  readonly INTENT_RESOLUTION: GoogleCloudDialogflowAamV2beta1NodeType;

  values(): Array<GoogleCloudDialogflowAamV2beta1NodeType>;
}

export const GoogleCloudDialogflowAamV2beta1NodeTypeEnum:
    IGoogleCloudDialogflowAamV2beta1NodeTypeEnum = {
      AGENT_ACTION: <GoogleCloudDialogflowAamV2beta1NodeType>'AGENT_ACTION',
      AGENT_ANSWER: <GoogleCloudDialogflowAamV2beta1NodeType>'AGENT_ANSWER',
      AGENT_COMMAND: <GoogleCloudDialogflowAamV2beta1NodeType>'AGENT_COMMAND',
      AGENT_QUESTION: <GoogleCloudDialogflowAamV2beta1NodeType>'AGENT_QUESTION',
      CONTEXTUAL_INTENT:
          <GoogleCloudDialogflowAamV2beta1NodeType>'CONTEXTUAL_INTENT',
      DISCRIMINANT_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1NodeType>'DISCRIMINANT_TYPE_UNSPECIFIED',
      DRIVER: <GoogleCloudDialogflowAamV2beta1NodeType>'DRIVER',
      INTENT: <GoogleCloudDialogflowAamV2beta1NodeType>'INTENT',
      INTENT_RESOLUTION:
          <GoogleCloudDialogflowAamV2beta1NodeType>'INTENT_RESOLUTION',
      PARAMETER: <GoogleCloudDialogflowAamV2beta1NodeType>'PARAMETER',
      values(): Array<GoogleCloudDialogflowAamV2beta1NodeType> {
        return [
          GoogleCloudDialogflowAamV2beta1NodeTypeEnum
              .DISCRIMINANT_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1NodeTypeEnum.DRIVER,
          GoogleCloudDialogflowAamV2beta1NodeTypeEnum.INTENT,
          GoogleCloudDialogflowAamV2beta1NodeTypeEnum.CONTEXTUAL_INTENT,
          GoogleCloudDialogflowAamV2beta1NodeTypeEnum.AGENT_QUESTION,
          GoogleCloudDialogflowAamV2beta1NodeTypeEnum.AGENT_ANSWER,
          GoogleCloudDialogflowAamV2beta1NodeTypeEnum.AGENT_ACTION,
          GoogleCloudDialogflowAamV2beta1NodeTypeEnum.AGENT_COMMAND,
          GoogleCloudDialogflowAamV2beta1NodeTypeEnum.PARAMETER,
          GoogleCloudDialogflowAamV2beta1NodeTypeEnum.INTENT_RESOLUTION
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType =
    'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
    'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|'PARAMETER'|
    'INTENT_RESOLUTION';

export interface IGoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum {
  readonly DISCRIMINANT_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType;
  readonly DRIVER: GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType;
  readonly INTENT: GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType;
  readonly CONTEXTUAL_INTENT:
      GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType;
  readonly AGENT_QUESTION:
      GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType;
  readonly AGENT_ANSWER:
      GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType;
  readonly AGENT_ACTION:
      GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType;
  readonly AGENT_COMMAND:
      GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType;
  readonly PARAMETER: GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType;
  readonly INTENT_RESOLUTION:
      GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType;

  values(): Array<GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>;
}

export const GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum:
    IGoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum = {
      AGENT_ACTION: <
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>'AGENT_ACTION',
      AGENT_ANSWER: <
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>'AGENT_ANSWER',
      AGENT_COMMAND: <
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>'AGENT_COMMAND',
      AGENT_QUESTION: <
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>'AGENT_QUESTION',
      CONTEXTUAL_INTENT: <
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>'CONTEXTUAL_INTENT',
      DISCRIMINANT_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>'DISCRIMINANT_TYPE_UNSPECIFIED',
      DRIVER: <GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>'DRIVER',
      INTENT: <GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>'INTENT',
      INTENT_RESOLUTION: <
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>'INTENT_RESOLUTION',
      PARAMETER:
          <GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType>'PARAMETER',
      values(): Array<GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType> {
        return [
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum
              .DISCRIMINANT_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum.DRIVER,
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum.INTENT,
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum
              .CONTEXTUAL_INTENT,
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum
              .AGENT_QUESTION,
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum
              .AGENT_ANSWER,
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum
              .AGENT_ACTION,
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum
              .AGENT_COMMAND,
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum.PARAMETER,
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum
              .INTENT_RESOLUTION
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType =
    'PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED'|'CUSTOM'|'TELECOMMUNICATIONS'|
    'FINANCIAL_SERVICES';

export interface IGoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardTypeEnum {
  readonly PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType;
  readonly CUSTOM:
      GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType;
  readonly TELECOMMUNICATIONS:
      GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType;
  readonly FINANCIAL_SERVICES:
      GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType;

  values():
      Array<GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType>;
}

export const GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardTypeEnum:
    IGoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardTypeEnum = {
      CUSTOM: <
          GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType>'CUSTOM',
      FINANCIAL_SERVICES: <
          GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType>'FINANCIAL_SERVICES',
      PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType>'PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED',
      TELECOMMUNICATIONS: <
          GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType>'TELECOMMUNICATIONS',
      values():
          Array<GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType> {
            return [
              GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardTypeEnum
                  .PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED,
              GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardTypeEnum
                  .CUSTOM,
              GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardTypeEnum
                  .TELECOMMUNICATIONS,
              GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardTypeEnum
                  .FINANCIAL_SERVICES
            ];
          }
    };

export type GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType =
    'SEARCH_RESOURCE_TYPE_UNSPECIFIED'|'SEARCH_RESOURCE_TYPE_DISPLAY_NAME'|
    'SEARCH_RESOURCE_TYPE_DESCRIPTION'|
    'SEARCH_RESOURCE_TYPE_CANONICAL_UTTERANCE'|'SEARCH_RESOURCE_TYPE_LABEL'|
    'SEARCH_RESOURCE_TYPE_TRANSCRIPT';

export interface IGoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum {
  readonly SEARCH_RESOURCE_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType;
  readonly SEARCH_RESOURCE_TYPE_DISPLAY_NAME:
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType;
  readonly SEARCH_RESOURCE_TYPE_DESCRIPTION:
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType;
  readonly SEARCH_RESOURCE_TYPE_CANONICAL_UTTERANCE:
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType;
  readonly SEARCH_RESOURCE_TYPE_LABEL:
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType;
  readonly SEARCH_RESOURCE_TYPE_TRANSCRIPT:
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType;

  values(): Array<
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType>;
}

export const GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum:
    IGoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum = {
      SEARCH_RESOURCE_TYPE_CANONICAL_UTTERANCE: <
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType>'SEARCH_RESOURCE_TYPE_CANONICAL_UTTERANCE',
      SEARCH_RESOURCE_TYPE_DESCRIPTION: <
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType>'SEARCH_RESOURCE_TYPE_DESCRIPTION',
      SEARCH_RESOURCE_TYPE_DISPLAY_NAME: <
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType>'SEARCH_RESOURCE_TYPE_DISPLAY_NAME',
      SEARCH_RESOURCE_TYPE_LABEL: <
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType>'SEARCH_RESOURCE_TYPE_LABEL',
      SEARCH_RESOURCE_TYPE_TRANSCRIPT: <
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType>'SEARCH_RESOURCE_TYPE_TRANSCRIPT',
      SEARCH_RESOURCE_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType>'SEARCH_RESOURCE_TYPE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType> {
        return [
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum
              .SEARCH_RESOURCE_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum
              .SEARCH_RESOURCE_TYPE_DISPLAY_NAME,
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum
              .SEARCH_RESOURCE_TYPE_DESCRIPTION,
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum
              .SEARCH_RESOURCE_TYPE_CANONICAL_UTTERANCE,
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum
              .SEARCH_RESOURCE_TYPE_LABEL,
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum
              .SEARCH_RESOURCE_TYPE_TRANSCRIPT
        ];
      }
    };

export type GoogleCloudDialogflowAamV2beta1UserInfoRole =
    'AAM_ROLE_UNSPECIFIED'|'AAM_ROLE_ADMIN'|'AAM_ROLE_DIALOG_DESIGNER'|
    'AAM_ROLE_LEAD_DIALOG_DESIGNER'|'AAM_ROLE_CONVERSATIONAL_ARCHITECT'|
    'AAM_ROLE_VIEWER';

export interface IGoogleCloudDialogflowAamV2beta1UserInfoRoleEnum {
  readonly AAM_ROLE_UNSPECIFIED: GoogleCloudDialogflowAamV2beta1UserInfoRole;
  readonly AAM_ROLE_ADMIN: GoogleCloudDialogflowAamV2beta1UserInfoRole;
  readonly AAM_ROLE_DIALOG_DESIGNER:
      GoogleCloudDialogflowAamV2beta1UserInfoRole;
  readonly AAM_ROLE_LEAD_DIALOG_DESIGNER:
      GoogleCloudDialogflowAamV2beta1UserInfoRole;
  readonly AAM_ROLE_CONVERSATIONAL_ARCHITECT:
      GoogleCloudDialogflowAamV2beta1UserInfoRole;
  readonly AAM_ROLE_VIEWER: GoogleCloudDialogflowAamV2beta1UserInfoRole;

  values(): Array<GoogleCloudDialogflowAamV2beta1UserInfoRole>;
}

export const GoogleCloudDialogflowAamV2beta1UserInfoRoleEnum:
    IGoogleCloudDialogflowAamV2beta1UserInfoRoleEnum = {
      AAM_ROLE_ADMIN:
          <GoogleCloudDialogflowAamV2beta1UserInfoRole>'AAM_ROLE_ADMIN',
      AAM_ROLE_CONVERSATIONAL_ARCHITECT: <
          GoogleCloudDialogflowAamV2beta1UserInfoRole>'AAM_ROLE_CONVERSATIONAL_ARCHITECT',
      AAM_ROLE_DIALOG_DESIGNER: <
          GoogleCloudDialogflowAamV2beta1UserInfoRole>'AAM_ROLE_DIALOG_DESIGNER',
      AAM_ROLE_LEAD_DIALOG_DESIGNER: <
          GoogleCloudDialogflowAamV2beta1UserInfoRole>'AAM_ROLE_LEAD_DIALOG_DESIGNER',
      AAM_ROLE_UNSPECIFIED:
          <GoogleCloudDialogflowAamV2beta1UserInfoRole>'AAM_ROLE_UNSPECIFIED',
      AAM_ROLE_VIEWER:
          <GoogleCloudDialogflowAamV2beta1UserInfoRole>'AAM_ROLE_VIEWER',
      values(): Array<GoogleCloudDialogflowAamV2beta1UserInfoRole> {
        return [
          GoogleCloudDialogflowAamV2beta1UserInfoRoleEnum.AAM_ROLE_UNSPECIFIED,
          GoogleCloudDialogflowAamV2beta1UserInfoRoleEnum.AAM_ROLE_ADMIN,
          GoogleCloudDialogflowAamV2beta1UserInfoRoleEnum
              .AAM_ROLE_DIALOG_DESIGNER,
          GoogleCloudDialogflowAamV2beta1UserInfoRoleEnum
              .AAM_ROLE_LEAD_DIALOG_DESIGNER,
          GoogleCloudDialogflowAamV2beta1UserInfoRoleEnum
              .AAM_ROLE_CONVERSATIONAL_ARCHITECT,
          GoogleCloudDialogflowAamV2beta1UserInfoRoleEnum.AAM_ROLE_VIEWER
        ];
      }
    };

export type GoogleCloudDialogflowCxV3ContinuousTestResultResult =
    'AGGREGATED_TEST_RESULT_UNSPECIFIED'|'PASSED'|'FAILED';

export interface IGoogleCloudDialogflowCxV3ContinuousTestResultResultEnum {
  readonly AGGREGATED_TEST_RESULT_UNSPECIFIED:
      GoogleCloudDialogflowCxV3ContinuousTestResultResult;
  readonly PASSED: GoogleCloudDialogflowCxV3ContinuousTestResultResult;
  readonly FAILED: GoogleCloudDialogflowCxV3ContinuousTestResultResult;

  values(): Array<GoogleCloudDialogflowCxV3ContinuousTestResultResult>;
}

export const GoogleCloudDialogflowCxV3ContinuousTestResultResultEnum:
    IGoogleCloudDialogflowCxV3ContinuousTestResultResultEnum = {
      AGGREGATED_TEST_RESULT_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3ContinuousTestResultResult>'AGGREGATED_TEST_RESULT_UNSPECIFIED',
      FAILED: <GoogleCloudDialogflowCxV3ContinuousTestResultResult>'FAILED',
      PASSED: <GoogleCloudDialogflowCxV3ContinuousTestResultResult>'PASSED',
      values(): Array<GoogleCloudDialogflowCxV3ContinuousTestResultResult> {
        return [
          GoogleCloudDialogflowCxV3ContinuousTestResultResultEnum
              .AGGREGATED_TEST_RESULT_UNSPECIFIED,
          GoogleCloudDialogflowCxV3ContinuousTestResultResultEnum.PASSED,
          GoogleCloudDialogflowCxV3ContinuousTestResultResultEnum.FAILED
        ];
      }
    };

export type GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState =
    'STATE_UNSPECIFIED'|'PENDING'|'RUNNING'|'DONE';

export interface IGoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataStateEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState;
  readonly PENDING:
      GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState;
  readonly RUNNING:
      GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState;
  readonly DONE:
      GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState;

  values():
      Array<GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState>;
}

export const GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataStateEnum:
    IGoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataStateEnum = {
      DONE: <
          GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState>'DONE',
      PENDING: <
          GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState>'PENDING',
      RUNNING: <
          GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState>'RUNNING',
      STATE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState>'STATE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState> {
        return [
          GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataStateEnum
              .STATE_UNSPECIFIED,
          GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataStateEnum
              .PENDING,
          GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataStateEnum
              .RUNNING,
          GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataStateEnum
              .DONE
        ];
      }
    };

export type GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding =
    'AUDIO_ENCODING_UNSPECIFIED'|'AUDIO_ENCODING_LINEAR_16'|
    'AUDIO_ENCODING_FLAC'|'AUDIO_ENCODING_MULAW'|'AUDIO_ENCODING_AMR'|
    'AUDIO_ENCODING_AMR_WB'|'AUDIO_ENCODING_OGG_OPUS'|
    'AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE';

export interface IGoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum {
  readonly AUDIO_ENCODING_UNSPECIFIED:
      GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_LINEAR_16:
      GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_FLAC:
      GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_MULAW:
      GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_AMR:
      GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_AMR_WB:
      GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_OGG_OPUS:
      GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE:
      GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding;

  values(): Array<GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding>;
}

export const GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum:
    IGoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum = {
      AUDIO_ENCODING_AMR: <
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding>'AUDIO_ENCODING_AMR',
      AUDIO_ENCODING_AMR_WB: <
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding>'AUDIO_ENCODING_AMR_WB',
      AUDIO_ENCODING_FLAC: <
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding>'AUDIO_ENCODING_FLAC',
      AUDIO_ENCODING_LINEAR_16: <
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding>'AUDIO_ENCODING_LINEAR_16',
      AUDIO_ENCODING_MULAW: <
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding>'AUDIO_ENCODING_MULAW',
      AUDIO_ENCODING_OGG_OPUS: <
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding>'AUDIO_ENCODING_OGG_OPUS',
      AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE: <
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding>'AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE',
      AUDIO_ENCODING_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding>'AUDIO_ENCODING_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding> {
        return [
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum
              .AUDIO_ENCODING_UNSPECIFIED,
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum
              .AUDIO_ENCODING_LINEAR_16,
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum
              .AUDIO_ENCODING_FLAC,
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum
              .AUDIO_ENCODING_MULAW,
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum
              .AUDIO_ENCODING_AMR,
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum
              .AUDIO_ENCODING_AMR_WB,
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum
              .AUDIO_ENCODING_OGG_OPUS,
          GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum
              .AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE
        ];
      }
    };

export type GoogleCloudDialogflowCxV3InputAudioConfigModelVariant =
    'SPEECH_MODEL_VARIANT_UNSPECIFIED'|'USE_BEST_AVAILABLE'|'USE_STANDARD'|
    'USE_ENHANCED';

export interface IGoogleCloudDialogflowCxV3InputAudioConfigModelVariantEnum {
  readonly SPEECH_MODEL_VARIANT_UNSPECIFIED:
      GoogleCloudDialogflowCxV3InputAudioConfigModelVariant;
  readonly USE_BEST_AVAILABLE:
      GoogleCloudDialogflowCxV3InputAudioConfigModelVariant;
  readonly USE_STANDARD: GoogleCloudDialogflowCxV3InputAudioConfigModelVariant;
  readonly USE_ENHANCED: GoogleCloudDialogflowCxV3InputAudioConfigModelVariant;

  values(): Array<GoogleCloudDialogflowCxV3InputAudioConfigModelVariant>;
}

export const GoogleCloudDialogflowCxV3InputAudioConfigModelVariantEnum:
    IGoogleCloudDialogflowCxV3InputAudioConfigModelVariantEnum = {
      SPEECH_MODEL_VARIANT_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3InputAudioConfigModelVariant>'SPEECH_MODEL_VARIANT_UNSPECIFIED',
      USE_BEST_AVAILABLE: <
          GoogleCloudDialogflowCxV3InputAudioConfigModelVariant>'USE_BEST_AVAILABLE',
      USE_ENHANCED:
          <GoogleCloudDialogflowCxV3InputAudioConfigModelVariant>'USE_ENHANCED',
      USE_STANDARD:
          <GoogleCloudDialogflowCxV3InputAudioConfigModelVariant>'USE_STANDARD',
      values(): Array<GoogleCloudDialogflowCxV3InputAudioConfigModelVariant> {
        return [
          GoogleCloudDialogflowCxV3InputAudioConfigModelVariantEnum
              .SPEECH_MODEL_VARIANT_UNSPECIFIED,
          GoogleCloudDialogflowCxV3InputAudioConfigModelVariantEnum
              .USE_BEST_AVAILABLE,
          GoogleCloudDialogflowCxV3InputAudioConfigModelVariantEnum
              .USE_STANDARD,
          GoogleCloudDialogflowCxV3InputAudioConfigModelVariantEnum.USE_ENHANCED
        ];
      }
    };

export type GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState =
    'PARAMETER_STATE_UNSPECIFIED'|'EMPTY'|'INVALID'|'FILLED';

export interface IGoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoStateEnum {
  readonly PARAMETER_STATE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState;
  readonly EMPTY: GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState;
  readonly INVALID: GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState;
  readonly FILLED: GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState;

  values(): Array<GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState>;
}

export const GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoStateEnum:
    IGoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoStateEnum = {
      EMPTY:
          <GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState>'EMPTY',
      FILLED:
          <GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState>'FILLED',
      INVALID: <
          GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState>'INVALID',
      PARAMETER_STATE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState>'PARAMETER_STATE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState> {
        return [
          GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoStateEnum
              .PARAMETER_STATE_UNSPECIFIED,
          GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoStateEnum.EMPTY,
          GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoStateEnum
              .INVALID,
          GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoStateEnum.FILLED
        ];
      }
    };

export type GoogleCloudDialogflowCxV3ResponseMessagePlatform =
    'PLATFORM_UNSPECIFIED'|'LINE'|'FACEBOOK_MESSENGER';

export interface IGoogleCloudDialogflowCxV3ResponseMessagePlatformEnum {
  readonly PLATFORM_UNSPECIFIED:
      GoogleCloudDialogflowCxV3ResponseMessagePlatform;
  readonly LINE: GoogleCloudDialogflowCxV3ResponseMessagePlatform;
  readonly FACEBOOK_MESSENGER: GoogleCloudDialogflowCxV3ResponseMessagePlatform;

  values(): Array<GoogleCloudDialogflowCxV3ResponseMessagePlatform>;
}

export const GoogleCloudDialogflowCxV3ResponseMessagePlatformEnum:
    IGoogleCloudDialogflowCxV3ResponseMessagePlatformEnum = {
      FACEBOOK_MESSENGER: <
          GoogleCloudDialogflowCxV3ResponseMessagePlatform>'FACEBOOK_MESSENGER',
      LINE: <GoogleCloudDialogflowCxV3ResponseMessagePlatform>'LINE',
      PLATFORM_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3ResponseMessagePlatform>'PLATFORM_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowCxV3ResponseMessagePlatform> {
        return [
          GoogleCloudDialogflowCxV3ResponseMessagePlatformEnum
              .PLATFORM_UNSPECIFIED,
          GoogleCloudDialogflowCxV3ResponseMessagePlatformEnum.LINE,
          GoogleCloudDialogflowCxV3ResponseMessagePlatformEnum
              .FACEBOOK_MESSENGER
        ];
      }
    };

export type GoogleCloudDialogflowCxV3ResponseMessageResponseType =
    'RESPONSE_TYPE_UNSPECIFIED'|'ENTRY_PROMPT'|'PARAMETER_PROMPT'|
    'HANDLER_PROMPT';

export interface IGoogleCloudDialogflowCxV3ResponseMessageResponseTypeEnum {
  readonly RESPONSE_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3ResponseMessageResponseType;
  readonly ENTRY_PROMPT: GoogleCloudDialogflowCxV3ResponseMessageResponseType;
  readonly PARAMETER_PROMPT:
      GoogleCloudDialogflowCxV3ResponseMessageResponseType;
  readonly HANDLER_PROMPT: GoogleCloudDialogflowCxV3ResponseMessageResponseType;

  values(): Array<GoogleCloudDialogflowCxV3ResponseMessageResponseType>;
}

export const GoogleCloudDialogflowCxV3ResponseMessageResponseTypeEnum:
    IGoogleCloudDialogflowCxV3ResponseMessageResponseTypeEnum = {
      ENTRY_PROMPT:
          <GoogleCloudDialogflowCxV3ResponseMessageResponseType>'ENTRY_PROMPT',
      HANDLER_PROMPT: <
          GoogleCloudDialogflowCxV3ResponseMessageResponseType>'HANDLER_PROMPT',
      PARAMETER_PROMPT: <
          GoogleCloudDialogflowCxV3ResponseMessageResponseType>'PARAMETER_PROMPT',
      RESPONSE_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3ResponseMessageResponseType>'RESPONSE_TYPE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowCxV3ResponseMessageResponseType> {
        return [
          GoogleCloudDialogflowCxV3ResponseMessageResponseTypeEnum
              .RESPONSE_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowCxV3ResponseMessageResponseTypeEnum.ENTRY_PROMPT,
          GoogleCloudDialogflowCxV3ResponseMessageResponseTypeEnum
              .PARAMETER_PROMPT,
          GoogleCloudDialogflowCxV3ResponseMessageResponseTypeEnum
              .HANDLER_PROMPT
        ];
      }
    };

export type GoogleCloudDialogflowCxV3ResponseMessageSource =
    'SOURCE_UNSPECIFIED'|'VIRTUAL_AGENT'|'WEBHOOK';

export interface IGoogleCloudDialogflowCxV3ResponseMessageSourceEnum {
  readonly SOURCE_UNSPECIFIED: GoogleCloudDialogflowCxV3ResponseMessageSource;
  readonly VIRTUAL_AGENT: GoogleCloudDialogflowCxV3ResponseMessageSource;
  readonly WEBHOOK: GoogleCloudDialogflowCxV3ResponseMessageSource;

  values(): Array<GoogleCloudDialogflowCxV3ResponseMessageSource>;
}

export const GoogleCloudDialogflowCxV3ResponseMessageSourceEnum:
    IGoogleCloudDialogflowCxV3ResponseMessageSourceEnum = {
      SOURCE_UNSPECIFIED:
          <GoogleCloudDialogflowCxV3ResponseMessageSource>'SOURCE_UNSPECIFIED',
      VIRTUAL_AGENT:
          <GoogleCloudDialogflowCxV3ResponseMessageSource>'VIRTUAL_AGENT',
      WEBHOOK: <GoogleCloudDialogflowCxV3ResponseMessageSource>'WEBHOOK',
      values(): Array<GoogleCloudDialogflowCxV3ResponseMessageSource> {
        return [
          GoogleCloudDialogflowCxV3ResponseMessageSourceEnum.SOURCE_UNSPECIFIED,
          GoogleCloudDialogflowCxV3ResponseMessageSourceEnum.VIRTUAL_AGENT,
          GoogleCloudDialogflowCxV3ResponseMessageSourceEnum.WEBHOOK
        ];
      }
    };

export type GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType =
    'SPEAKER_ID_PAGE_TYPE_UNSPECIFIED'|'SPEAKER_ID_PAGE_TYPE_VERIFICATION'|
    'SPEAKER_ID_PAGE_TYPE_ENROLLMENT';

export interface IGoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageTypeEnum {
  readonly SPEAKER_ID_PAGE_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType;
  readonly SPEAKER_ID_PAGE_TYPE_VERIFICATION:
      GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType;
  readonly SPEAKER_ID_PAGE_TYPE_ENROLLMENT:
      GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType;

  values(): Array<GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType>;
}

export const GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageTypeEnum:
    IGoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageTypeEnum = {
      SPEAKER_ID_PAGE_TYPE_ENROLLMENT: <
          GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType>'SPEAKER_ID_PAGE_TYPE_ENROLLMENT',
      SPEAKER_ID_PAGE_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType>'SPEAKER_ID_PAGE_TYPE_UNSPECIFIED',
      SPEAKER_ID_PAGE_TYPE_VERIFICATION: <
          GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType>'SPEAKER_ID_PAGE_TYPE_VERIFICATION',
      values():
          Array<GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType> {
            return [
              GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageTypeEnum
                  .SPEAKER_ID_PAGE_TYPE_UNSPECIFIED,
              GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageTypeEnum
                  .SPEAKER_ID_PAGE_TYPE_VERIFICATION,
              GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageTypeEnum
                  .SPEAKER_ID_PAGE_TYPE_ENROLLMENT
            ];
          }
    };

export type GoogleCloudDialogflowCxV3TestCaseResultTestResult =
    'TEST_RESULT_UNSPECIFIED'|'PASSED'|'FAILED';

export interface IGoogleCloudDialogflowCxV3TestCaseResultTestResultEnum {
  readonly TEST_RESULT_UNSPECIFIED:
      GoogleCloudDialogflowCxV3TestCaseResultTestResult;
  readonly PASSED: GoogleCloudDialogflowCxV3TestCaseResultTestResult;
  readonly FAILED: GoogleCloudDialogflowCxV3TestCaseResultTestResult;

  values(): Array<GoogleCloudDialogflowCxV3TestCaseResultTestResult>;
}

export const GoogleCloudDialogflowCxV3TestCaseResultTestResultEnum:
    IGoogleCloudDialogflowCxV3TestCaseResultTestResultEnum = {
      FAILED: <GoogleCloudDialogflowCxV3TestCaseResultTestResult>'FAILED',
      PASSED: <GoogleCloudDialogflowCxV3TestCaseResultTestResult>'PASSED',
      TEST_RESULT_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3TestCaseResultTestResult>'TEST_RESULT_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowCxV3TestCaseResultTestResult> {
        return [
          GoogleCloudDialogflowCxV3TestCaseResultTestResultEnum
              .TEST_RESULT_UNSPECIFIED,
          GoogleCloudDialogflowCxV3TestCaseResultTestResultEnum.PASSED,
          GoogleCloudDialogflowCxV3TestCaseResultTestResultEnum.FAILED
        ];
      }
    };

export type GoogleCloudDialogflowCxV3TestRunDifferenceType =
    'DIFF_TYPE_UNSPECIFIED'|'INTENT'|'PAGE'|'PARAMETERS'|'UTTERANCE';

export interface IGoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum {
  readonly DIFF_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3TestRunDifferenceType;
  readonly INTENT: GoogleCloudDialogflowCxV3TestRunDifferenceType;
  readonly PAGE: GoogleCloudDialogflowCxV3TestRunDifferenceType;
  readonly PARAMETERS: GoogleCloudDialogflowCxV3TestRunDifferenceType;
  readonly UTTERANCE: GoogleCloudDialogflowCxV3TestRunDifferenceType;

  values(): Array<GoogleCloudDialogflowCxV3TestRunDifferenceType>;
}

export const GoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum:
    IGoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum = {
      DIFF_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3TestRunDifferenceType>'DIFF_TYPE_UNSPECIFIED',
      INTENT: <GoogleCloudDialogflowCxV3TestRunDifferenceType>'INTENT',
      PAGE: <GoogleCloudDialogflowCxV3TestRunDifferenceType>'PAGE',
      PARAMETERS: <GoogleCloudDialogflowCxV3TestRunDifferenceType>'PARAMETERS',
      UTTERANCE: <GoogleCloudDialogflowCxV3TestRunDifferenceType>'UTTERANCE',
      values(): Array<GoogleCloudDialogflowCxV3TestRunDifferenceType> {
        return [
          GoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum
              .DIFF_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum.INTENT,
          GoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum.PAGE,
          GoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum.PARAMETERS,
          GoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum.UTTERANCE
        ];
      }
    };

export type GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior =
    'MERGE_BEHAVIOR_UNSPECIFIED'|'APPEND'|'REPLACE';

export interface IGoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehaviorEnum {
  readonly MERGE_BEHAVIOR_UNSPECIFIED:
      GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior;
  readonly APPEND:
      GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior;
  readonly REPLACE:
      GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior;

  values(): Array<
      GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior>;
}

export const GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehaviorEnum:
    IGoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehaviorEnum = {
      APPEND: <
          GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior>'APPEND',
      MERGE_BEHAVIOR_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior>'MERGE_BEHAVIOR_UNSPECIFIED',
      REPLACE: <
          GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior>'REPLACE',
      values(): Array<
          GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior> {
        return [
          GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehaviorEnum
              .MERGE_BEHAVIOR_UNSPECIFIED,
          GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehaviorEnum
              .APPEND,
          GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehaviorEnum
              .REPLACE
        ];
      }
    };

export type GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult =
    'AGGREGATED_TEST_RESULT_UNSPECIFIED'|'PASSED'|'FAILED';

export interface IGoogleCloudDialogflowCxV3beta1ContinuousTestResultResultEnum {
  readonly AGGREGATED_TEST_RESULT_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult;
  readonly PASSED: GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult;
  readonly FAILED: GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult;

  values(): Array<GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult>;
}

export const GoogleCloudDialogflowCxV3beta1ContinuousTestResultResultEnum:
    IGoogleCloudDialogflowCxV3beta1ContinuousTestResultResultEnum = {
      AGGREGATED_TEST_RESULT_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult>'AGGREGATED_TEST_RESULT_UNSPECIFIED',
      FAILED:
          <GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult>'FAILED',
      PASSED:
          <GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult>'PASSED',
      values(): Array<
          GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult> {
        return [
          GoogleCloudDialogflowCxV3beta1ContinuousTestResultResultEnum
              .AGGREGATED_TEST_RESULT_UNSPECIFIED,
          GoogleCloudDialogflowCxV3beta1ContinuousTestResultResultEnum.PASSED,
          GoogleCloudDialogflowCxV3beta1ContinuousTestResultResultEnum.FAILED
        ];
      }
    };

export type GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState =
    'STATE_UNSPECIFIED'|'PENDING'|'RUNNING'|'DONE';

export interface IGoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataStateEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState;
  readonly PENDING:
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState;
  readonly RUNNING:
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState;
  readonly DONE:
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState;

  values(): Array<
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState>;
}

export const GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataStateEnum:
    IGoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataStateEnum = {
      DONE: <
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState>'DONE',
      PENDING: <
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState>'PENDING',
      RUNNING: <
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState>'RUNNING',
      STATE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState>'STATE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState> {
        return [
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataStateEnum
              .STATE_UNSPECIFIED,
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataStateEnum
              .PENDING,
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataStateEnum
              .RUNNING,
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataStateEnum
              .DONE
        ];
      }
    };

export type GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding =
    'AUDIO_ENCODING_UNSPECIFIED'|'AUDIO_ENCODING_LINEAR_16'|
    'AUDIO_ENCODING_FLAC'|'AUDIO_ENCODING_MULAW'|'AUDIO_ENCODING_AMR'|
    'AUDIO_ENCODING_AMR_WB'|'AUDIO_ENCODING_OGG_OPUS'|
    'AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE';

export interface IGoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum {
  readonly AUDIO_ENCODING_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_LINEAR_16:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_FLAC:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_MULAW:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_AMR:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_AMR_WB:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_OGG_OPUS:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding;

  values(): Array<GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding>;
}

export const GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum:
    IGoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum = {
      AUDIO_ENCODING_AMR: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_AMR',
      AUDIO_ENCODING_AMR_WB: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_AMR_WB',
      AUDIO_ENCODING_FLAC: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_FLAC',
      AUDIO_ENCODING_LINEAR_16: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_LINEAR_16',
      AUDIO_ENCODING_MULAW: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_MULAW',
      AUDIO_ENCODING_OGG_OPUS: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_OGG_OPUS',
      AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE',
      AUDIO_ENCODING_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding> {
            return [
              GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_UNSPECIFIED,
              GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_LINEAR_16,
              GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_FLAC,
              GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_MULAW,
              GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_AMR,
              GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_AMR_WB,
              GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_OGG_OPUS,
              GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE
            ];
          }
    };

export type GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant =
    'SPEECH_MODEL_VARIANT_UNSPECIFIED'|'USE_BEST_AVAILABLE'|'USE_STANDARD'|
    'USE_ENHANCED';

export interface IGoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariantEnum {
  readonly SPEECH_MODEL_VARIANT_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant;
  readonly USE_BEST_AVAILABLE:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant;
  readonly USE_STANDARD:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant;
  readonly USE_ENHANCED:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant;

  values(): Array<GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant>;
}

export const GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariantEnum:
    IGoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariantEnum = {
      SPEECH_MODEL_VARIANT_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant>'SPEECH_MODEL_VARIANT_UNSPECIFIED',
      USE_BEST_AVAILABLE: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant>'USE_BEST_AVAILABLE',
      USE_ENHANCED: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant>'USE_ENHANCED',
      USE_STANDARD: <
          GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant>'USE_STANDARD',
      values():
          Array<GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant> {
            return [
              GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariantEnum
                  .SPEECH_MODEL_VARIANT_UNSPECIFIED,
              GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariantEnum
                  .USE_BEST_AVAILABLE,
              GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariantEnum
                  .USE_STANDARD,
              GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariantEnum
                  .USE_ENHANCED
            ];
          }
    };

export type GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState =
    'PARAMETER_STATE_UNSPECIFIED'|'EMPTY'|'INVALID'|'FILLED';

export interface IGoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoStateEnum {
  readonly PARAMETER_STATE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState;
  readonly EMPTY:
      GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState;
  readonly INVALID:
      GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState;
  readonly FILLED:
      GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState;

  values():
      Array<GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState>;
}

export const GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoStateEnum:
    IGoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoStateEnum = {
      EMPTY: <
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState>'EMPTY',
      FILLED: <
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState>'FILLED',
      INVALID: <
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState>'INVALID',
      PARAMETER_STATE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState>'PARAMETER_STATE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState> {
        return [
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoStateEnum
              .PARAMETER_STATE_UNSPECIFIED,
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoStateEnum
              .EMPTY,
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoStateEnum
              .INVALID,
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoStateEnum
              .FILLED
        ];
      }
    };

export type GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform =
    'PLATFORM_UNSPECIFIED'|'LINE'|'FACEBOOK_MESSENGER';

export interface IGoogleCloudDialogflowCxV3beta1ResponseMessagePlatformEnum {
  readonly PLATFORM_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform;
  readonly LINE: GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform;
  readonly FACEBOOK_MESSENGER:
      GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform;

  values(): Array<GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform>;
}

export const GoogleCloudDialogflowCxV3beta1ResponseMessagePlatformEnum:
    IGoogleCloudDialogflowCxV3beta1ResponseMessagePlatformEnum = {
      FACEBOOK_MESSENGER: <
          GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform>'FACEBOOK_MESSENGER',
      LINE: <GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform>'LINE',
      PLATFORM_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform>'PLATFORM_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform> {
        return [
          GoogleCloudDialogflowCxV3beta1ResponseMessagePlatformEnum
              .PLATFORM_UNSPECIFIED,
          GoogleCloudDialogflowCxV3beta1ResponseMessagePlatformEnum.LINE,
          GoogleCloudDialogflowCxV3beta1ResponseMessagePlatformEnum
              .FACEBOOK_MESSENGER
        ];
      }
    };

export type GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType =
    'RESPONSE_TYPE_UNSPECIFIED'|'ENTRY_PROMPT'|'PARAMETER_PROMPT'|
    'HANDLER_PROMPT';

export interface IGoogleCloudDialogflowCxV3beta1ResponseMessageResponseTypeEnum {
  readonly RESPONSE_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType;
  readonly ENTRY_PROMPT:
      GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType;
  readonly PARAMETER_PROMPT:
      GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType;
  readonly HANDLER_PROMPT:
      GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType;

  values(): Array<GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType>;
}

export const GoogleCloudDialogflowCxV3beta1ResponseMessageResponseTypeEnum:
    IGoogleCloudDialogflowCxV3beta1ResponseMessageResponseTypeEnum = {
      ENTRY_PROMPT: <
          GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType>'ENTRY_PROMPT',
      HANDLER_PROMPT: <
          GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType>'HANDLER_PROMPT',
      PARAMETER_PROMPT: <
          GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType>'PARAMETER_PROMPT',
      RESPONSE_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType>'RESPONSE_TYPE_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType> {
            return [
              GoogleCloudDialogflowCxV3beta1ResponseMessageResponseTypeEnum
                  .RESPONSE_TYPE_UNSPECIFIED,
              GoogleCloudDialogflowCxV3beta1ResponseMessageResponseTypeEnum
                  .ENTRY_PROMPT,
              GoogleCloudDialogflowCxV3beta1ResponseMessageResponseTypeEnum
                  .PARAMETER_PROMPT,
              GoogleCloudDialogflowCxV3beta1ResponseMessageResponseTypeEnum
                  .HANDLER_PROMPT
            ];
          }
    };

export type GoogleCloudDialogflowCxV3beta1ResponseMessageSource =
    'SOURCE_UNSPECIFIED'|'VIRTUAL_AGENT'|'WEBHOOK';

export interface IGoogleCloudDialogflowCxV3beta1ResponseMessageSourceEnum {
  readonly SOURCE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1ResponseMessageSource;
  readonly VIRTUAL_AGENT: GoogleCloudDialogflowCxV3beta1ResponseMessageSource;
  readonly WEBHOOK: GoogleCloudDialogflowCxV3beta1ResponseMessageSource;

  values(): Array<GoogleCloudDialogflowCxV3beta1ResponseMessageSource>;
}

export const GoogleCloudDialogflowCxV3beta1ResponseMessageSourceEnum:
    IGoogleCloudDialogflowCxV3beta1ResponseMessageSourceEnum = {
      SOURCE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1ResponseMessageSource>'SOURCE_UNSPECIFIED',
      VIRTUAL_AGENT:
          <GoogleCloudDialogflowCxV3beta1ResponseMessageSource>'VIRTUAL_AGENT',
      WEBHOOK: <GoogleCloudDialogflowCxV3beta1ResponseMessageSource>'WEBHOOK',
      values(): Array<GoogleCloudDialogflowCxV3beta1ResponseMessageSource> {
        return [
          GoogleCloudDialogflowCxV3beta1ResponseMessageSourceEnum
              .SOURCE_UNSPECIFIED,
          GoogleCloudDialogflowCxV3beta1ResponseMessageSourceEnum.VIRTUAL_AGENT,
          GoogleCloudDialogflowCxV3beta1ResponseMessageSourceEnum.WEBHOOK
        ];
      }
    };

export type GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType =
    'SPEAKER_ID_PAGE_TYPE_UNSPECIFIED'|'SPEAKER_ID_PAGE_TYPE_VERIFICATION'|
    'SPEAKER_ID_PAGE_TYPE_ENROLLMENT';

export interface IGoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageTypeEnum {
  readonly SPEAKER_ID_PAGE_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType;
  readonly SPEAKER_ID_PAGE_TYPE_VERIFICATION:
      GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType;
  readonly SPEAKER_ID_PAGE_TYPE_ENROLLMENT:
      GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType;

  values():
      Array<GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType>;
}

export const GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageTypeEnum:
    IGoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageTypeEnum = {
      SPEAKER_ID_PAGE_TYPE_ENROLLMENT: <
          GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType>'SPEAKER_ID_PAGE_TYPE_ENROLLMENT',
      SPEAKER_ID_PAGE_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType>'SPEAKER_ID_PAGE_TYPE_UNSPECIFIED',
      SPEAKER_ID_PAGE_TYPE_VERIFICATION: <
          GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType>'SPEAKER_ID_PAGE_TYPE_VERIFICATION',
      values(): Array<
          GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType> {
        return [
          GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageTypeEnum
              .SPEAKER_ID_PAGE_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageTypeEnum
              .SPEAKER_ID_PAGE_TYPE_VERIFICATION,
          GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageTypeEnum
              .SPEAKER_ID_PAGE_TYPE_ENROLLMENT
        ];
      }
    };

export type GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult =
    'TEST_RESULT_UNSPECIFIED'|'PASSED'|'FAILED';

export interface IGoogleCloudDialogflowCxV3beta1TestCaseResultTestResultEnum {
  readonly TEST_RESULT_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult;
  readonly PASSED: GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult;
  readonly FAILED: GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult;

  values(): Array<GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult>;
}

export const GoogleCloudDialogflowCxV3beta1TestCaseResultTestResultEnum:
    IGoogleCloudDialogflowCxV3beta1TestCaseResultTestResultEnum = {
      FAILED: <GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult>'FAILED',
      PASSED: <GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult>'PASSED',
      TEST_RESULT_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult>'TEST_RESULT_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult> {
        return [
          GoogleCloudDialogflowCxV3beta1TestCaseResultTestResultEnum
              .TEST_RESULT_UNSPECIFIED,
          GoogleCloudDialogflowCxV3beta1TestCaseResultTestResultEnum.PASSED,
          GoogleCloudDialogflowCxV3beta1TestCaseResultTestResultEnum.FAILED
        ];
      }
    };

export type GoogleCloudDialogflowCxV3beta1TestRunDifferenceType =
    'DIFF_TYPE_UNSPECIFIED'|'INTENT'|'PAGE'|'PARAMETERS'|'UTTERANCE';

export interface IGoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum {
  readonly DIFF_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1TestRunDifferenceType;
  readonly INTENT: GoogleCloudDialogflowCxV3beta1TestRunDifferenceType;
  readonly PAGE: GoogleCloudDialogflowCxV3beta1TestRunDifferenceType;
  readonly PARAMETERS: GoogleCloudDialogflowCxV3beta1TestRunDifferenceType;
  readonly UTTERANCE: GoogleCloudDialogflowCxV3beta1TestRunDifferenceType;

  values(): Array<GoogleCloudDialogflowCxV3beta1TestRunDifferenceType>;
}

export const GoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum:
    IGoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum = {
      DIFF_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1TestRunDifferenceType>'DIFF_TYPE_UNSPECIFIED',
      INTENT: <GoogleCloudDialogflowCxV3beta1TestRunDifferenceType>'INTENT',
      PAGE: <GoogleCloudDialogflowCxV3beta1TestRunDifferenceType>'PAGE',
      PARAMETERS:
          <GoogleCloudDialogflowCxV3beta1TestRunDifferenceType>'PARAMETERS',
      UTTERANCE:
          <GoogleCloudDialogflowCxV3beta1TestRunDifferenceType>'UTTERANCE',
      values(): Array<GoogleCloudDialogflowCxV3beta1TestRunDifferenceType> {
        return [
          GoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum
              .DIFF_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum.INTENT,
          GoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum.PAGE,
          GoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum.PARAMETERS,
          GoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum.UTTERANCE
        ];
      }
    };

export type GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior =
    'MERGE_BEHAVIOR_UNSPECIFIED'|'APPEND'|'REPLACE';

export interface IGoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehaviorEnum {
  readonly MERGE_BEHAVIOR_UNSPECIFIED:
      GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior;
  readonly APPEND:
      GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior;
  readonly REPLACE:
      GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior;

  values(): Array<
      GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior>;
}

export const GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehaviorEnum:
    IGoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehaviorEnum = {
      APPEND: <
          GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior>'APPEND',
      MERGE_BEHAVIOR_UNSPECIFIED: <
          GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior>'MERGE_BEHAVIOR_UNSPECIFIED',
      REPLACE: <
          GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior>'REPLACE',
      values(): Array<
          GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior> {
        return [
          GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehaviorEnum
              .MERGE_BEHAVIOR_UNSPECIFIED,
          GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehaviorEnum
              .APPEND,
          GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehaviorEnum
              .REPLACE
        ];
      }
    };

export type GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface IGoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly SMART_REPLY_BERT_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly SUMMARIZATION_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly KEY_MOMENT_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly SUMMARIZATION_V2_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType;

  values(): Array<
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>;
}

export const GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum:
    IGoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL: <
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType>'SUMMARIZATION_V2_MODEL',
      values(): Array<
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType> {
        return [
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .MODEL_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_GBT_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_BERT_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .SMART_COMPOSE_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_GENERATIVE_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .SUMMARIZATION_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .KEY_MOMENT_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .SUMMARIZATION_V2_MODEL,
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
        ];
      }
    };

export type GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType =
    'SOURCE_TYPE_UNSPECIFIED'|'AUTOMATIC'|'PRETRAINED';

export interface IGoogleCloudDialogflowV2AssociateAllowlistInfoSourceTypeEnum {
  readonly SOURCE_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType;
  readonly AUTOMATIC: GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType;
  readonly PRETRAINED: GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType;

  values(): Array<GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType>;
}

export const GoogleCloudDialogflowV2AssociateAllowlistInfoSourceTypeEnum:
    IGoogleCloudDialogflowV2AssociateAllowlistInfoSourceTypeEnum = {
      AUTOMATIC:
          <GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType>'AUTOMATIC',
      PRETRAINED:
          <GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType>'PRETRAINED',
      SOURCE_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType>'SOURCE_TYPE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType> {
        return [
          GoogleCloudDialogflowV2AssociateAllowlistInfoSourceTypeEnum
              .SOURCE_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV2AssociateAllowlistInfoSourceTypeEnum.AUTOMATIC,
          GoogleCloudDialogflowV2AssociateAllowlistInfoSourceTypeEnum.PRETRAINED
        ];
      }
    };

export type GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface IGoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRoleEnum {
  readonly ROLE_UNSPECIFIED:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole;
  readonly HUMAN_AGENT:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole;
  readonly AUTOMATED_AGENT:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole;
  readonly END_USER:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole;

  values(): Array<
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole>;
}

export const GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRoleEnum:
    IGoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRoleEnum = {
      AUTOMATED_AGENT: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole>'AUTOMATED_AGENT',
      END_USER: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole>'END_USER',
      HUMAN_AGENT: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole>'ROLE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole> {
        return [
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRoleEnum
              .ROLE_UNSPECIFIED,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRoleEnum
              .HUMAN_AGENT,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRoleEnum
              .AUTOMATED_AGENT,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRoleEnum
              .END_USER
        ];
      }
    };

export type GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType =
    'TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION'|'FAQ'|'SMART_REPLY'|
    'DIALOGFLOW_ASSIST'|'SMART_COMPOSE'|'ISSUE_SMART_COMPOSE'|
    'SPELL_GRAMMAR_CORRECTION'|'CONVERSATION_SUMMARIZATION'|'KEY_MOMENT'|
    'ARTICLE_SEARCH'|'CONVERSATION_GUIDANCE'|'ARTICLE_SUGGESTION_VOICE'|
    'CONVERSATION_SUMMARIZATION_VOICE';

export interface IGoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum {
  readonly TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly ARTICLE_SUGGESTION:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly FAQ:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly SMART_REPLY:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly DIALOGFLOW_ASSIST:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly SMART_COMPOSE:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly ISSUE_SMART_COMPOSE:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly SPELL_GRAMMAR_CORRECTION:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly CONVERSATION_SUMMARIZATION:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly KEY_MOMENT:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly ARTICLE_SEARCH:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly CONVERSATION_GUIDANCE:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly ARTICLE_SUGGESTION_VOICE:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly CONVERSATION_SUMMARIZATION_VOICE:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;

  values(): Array<
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>;
}

export const GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum:
    IGoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum = {
      ARTICLE_SEARCH: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'ARTICLE_SEARCH',
      ARTICLE_SUGGESTION: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'ARTICLE_SUGGESTION',
      ARTICLE_SUGGESTION_VOICE: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'ARTICLE_SUGGESTION_VOICE',
      CONVERSATION_GUIDANCE: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'CONVERSATION_GUIDANCE',
      CONVERSATION_SUMMARIZATION: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'CONVERSATION_SUMMARIZATION',
      CONVERSATION_SUMMARIZATION_VOICE: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'CONVERSATION_SUMMARIZATION_VOICE',
      DIALOGFLOW_ASSIST: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'DIALOGFLOW_ASSIST',
      FAQ: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'FAQ',
      ISSUE_SMART_COMPOSE: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'ISSUE_SMART_COMPOSE',
      KEY_MOMENT: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'KEY_MOMENT',
      SMART_COMPOSE: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'SMART_COMPOSE',
      SMART_REPLY: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'SMART_REPLY',
      SPELL_GRAMMAR_CORRECTION: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'SPELL_GRAMMAR_CORRECTION',
      TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'TYPE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType> {
        return [
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .ARTICLE_SUGGESTION,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .FAQ,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .SMART_REPLY,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .DIALOGFLOW_ASSIST,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .SMART_COMPOSE,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .ISSUE_SMART_COMPOSE,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .SPELL_GRAMMAR_CORRECTION,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .CONVERSATION_SUMMARIZATION,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .KEY_MOMENT,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .ARTICLE_SEARCH,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .CONVERSATION_GUIDANCE,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .ARTICLE_SUGGESTION_VOICE,
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .CONVERSATION_SUMMARIZATION_VOICE
        ];
      }
    };

export type GoogleCloudDialogflowV2ConversationEventType =
    'TYPE_UNSPECIFIED'|'CONVERSATION_STARTED'|'CONVERSATION_FINISHED'|
    'HUMAN_INTERVENTION_NEEDED'|'NEW_MESSAGE'|'UNRECOVERABLE_ERROR';

export interface IGoogleCloudDialogflowV2ConversationEventTypeEnum {
  readonly TYPE_UNSPECIFIED: GoogleCloudDialogflowV2ConversationEventType;
  readonly CONVERSATION_STARTED: GoogleCloudDialogflowV2ConversationEventType;
  readonly CONVERSATION_FINISHED: GoogleCloudDialogflowV2ConversationEventType;
  readonly HUMAN_INTERVENTION_NEEDED:
      GoogleCloudDialogflowV2ConversationEventType;
  readonly NEW_MESSAGE: GoogleCloudDialogflowV2ConversationEventType;
  readonly UNRECOVERABLE_ERROR: GoogleCloudDialogflowV2ConversationEventType;

  values(): Array<GoogleCloudDialogflowV2ConversationEventType>;
}

export const GoogleCloudDialogflowV2ConversationEventTypeEnum:
    IGoogleCloudDialogflowV2ConversationEventTypeEnum = {
      CONVERSATION_FINISHED:
          <GoogleCloudDialogflowV2ConversationEventType>'CONVERSATION_FINISHED',
      CONVERSATION_STARTED:
          <GoogleCloudDialogflowV2ConversationEventType>'CONVERSATION_STARTED',
      HUMAN_INTERVENTION_NEEDED: <
          GoogleCloudDialogflowV2ConversationEventType>'HUMAN_INTERVENTION_NEEDED',
      NEW_MESSAGE: <GoogleCloudDialogflowV2ConversationEventType>'NEW_MESSAGE',
      TYPE_UNSPECIFIED:
          <GoogleCloudDialogflowV2ConversationEventType>'TYPE_UNSPECIFIED',
      UNRECOVERABLE_ERROR:
          <GoogleCloudDialogflowV2ConversationEventType>'UNRECOVERABLE_ERROR',
      values(): Array<GoogleCloudDialogflowV2ConversationEventType> {
        return [
          GoogleCloudDialogflowV2ConversationEventTypeEnum.TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV2ConversationEventTypeEnum.CONVERSATION_STARTED,
          GoogleCloudDialogflowV2ConversationEventTypeEnum
              .CONVERSATION_FINISHED,
          GoogleCloudDialogflowV2ConversationEventTypeEnum
              .HUMAN_INTERVENTION_NEEDED,
          GoogleCloudDialogflowV2ConversationEventTypeEnum.NEW_MESSAGE,
          GoogleCloudDialogflowV2ConversationEventTypeEnum.UNRECOVERABLE_ERROR
        ];
      }
    };

export type GoogleCloudDialogflowV2ConversationModelState =
    'STATE_UNSPECIFIED'|'CREATING'|'UNDEPLOYED'|'DEPLOYING'|'DEPLOYED'|
    'UNDEPLOYING'|'DELETING'|'FAILED'|'PENDING';

export interface IGoogleCloudDialogflowV2ConversationModelStateEnum {
  readonly STATE_UNSPECIFIED: GoogleCloudDialogflowV2ConversationModelState;
  readonly CREATING: GoogleCloudDialogflowV2ConversationModelState;
  readonly UNDEPLOYED: GoogleCloudDialogflowV2ConversationModelState;
  readonly DEPLOYING: GoogleCloudDialogflowV2ConversationModelState;
  readonly DEPLOYED: GoogleCloudDialogflowV2ConversationModelState;
  readonly UNDEPLOYING: GoogleCloudDialogflowV2ConversationModelState;
  readonly DELETING: GoogleCloudDialogflowV2ConversationModelState;
  readonly FAILED: GoogleCloudDialogflowV2ConversationModelState;
  readonly PENDING: GoogleCloudDialogflowV2ConversationModelState;

  values(): Array<GoogleCloudDialogflowV2ConversationModelState>;
}

export const GoogleCloudDialogflowV2ConversationModelStateEnum:
    IGoogleCloudDialogflowV2ConversationModelStateEnum = {
      CREATING: <GoogleCloudDialogflowV2ConversationModelState>'CREATING',
      DELETING: <GoogleCloudDialogflowV2ConversationModelState>'DELETING',
      DEPLOYED: <GoogleCloudDialogflowV2ConversationModelState>'DEPLOYED',
      DEPLOYING: <GoogleCloudDialogflowV2ConversationModelState>'DEPLOYING',
      FAILED: <GoogleCloudDialogflowV2ConversationModelState>'FAILED',
      PENDING: <GoogleCloudDialogflowV2ConversationModelState>'PENDING',
      STATE_UNSPECIFIED:
          <GoogleCloudDialogflowV2ConversationModelState>'STATE_UNSPECIFIED',
      UNDEPLOYED: <GoogleCloudDialogflowV2ConversationModelState>'UNDEPLOYED',
      UNDEPLOYING: <GoogleCloudDialogflowV2ConversationModelState>'UNDEPLOYING',
      values(): Array<GoogleCloudDialogflowV2ConversationModelState> {
        return [
          GoogleCloudDialogflowV2ConversationModelStateEnum.STATE_UNSPECIFIED,
          GoogleCloudDialogflowV2ConversationModelStateEnum.CREATING,
          GoogleCloudDialogflowV2ConversationModelStateEnum.UNDEPLOYED,
          GoogleCloudDialogflowV2ConversationModelStateEnum.DEPLOYING,
          GoogleCloudDialogflowV2ConversationModelStateEnum.DEPLOYED,
          GoogleCloudDialogflowV2ConversationModelStateEnum.UNDEPLOYING,
          GoogleCloudDialogflowV2ConversationModelStateEnum.DELETING,
          GoogleCloudDialogflowV2ConversationModelStateEnum.FAILED,
          GoogleCloudDialogflowV2ConversationModelStateEnum.PENDING
        ];
      }
    };

export type GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState =
    'STATE_UNSPECIFIED'|'INITIALIZING'|'RUNNING'|'CANCELLED'|'SUCCEEDED'|
    'FAILED';

export interface IGoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState;
  readonly INITIALIZING:
      GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState;
  readonly RUNNING:
      GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState;
  readonly CANCELLED:
      GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState;
  readonly SUCCEEDED:
      GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState;
  readonly FAILED:
      GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState;

  values(): Array<
      GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState>;
}

export const GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum:
    IGoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum = {
      CANCELLED: <
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState>'CANCELLED',
      FAILED: <
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState>'FAILED',
      INITIALIZING: <
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState>'INITIALIZING',
      RUNNING: <
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState>'RUNNING',
      STATE_UNSPECIFIED: <
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState>'STATE_UNSPECIFIED',
      SUCCEEDED: <
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState>'SUCCEEDED',
      values(): Array<
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState> {
        return [
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum
              .STATE_UNSPECIFIED,
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum
              .INITIALIZING,
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum
              .RUNNING,
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum
              .CANCELLED,
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum
              .SUCCEEDED,
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum
              .FAILED
        ];
      }
    };

export type GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState =
    'STATE_UNSPECIFIED'|'PENDING'|'SUCCEEDED'|'FAILED'|'CANCELLED'|'CANCELLING'|
    'TRAINING';

export interface IGoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState;
  readonly PENDING:
      GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState;
  readonly SUCCEEDED:
      GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState;
  readonly FAILED:
      GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState;
  readonly CANCELLED:
      GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState;
  readonly CANCELLING:
      GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState;
  readonly TRAINING:
      GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState;

  values(): Array<
      GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState>;
}

export const GoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum:
    IGoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum = {
      CANCELLED: <
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState>'CANCELLED',
      CANCELLING: <
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState>'CANCELLING',
      FAILED: <
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState>'FAILED',
      PENDING: <
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState>'PENDING',
      STATE_UNSPECIFIED: <
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState>'STATE_UNSPECIFIED',
      SUCCEEDED: <
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState>'SUCCEEDED',
      TRAINING: <
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState>'TRAINING',
      values(): Array<
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState> {
        return [
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum
              .STATE_UNSPECIFIED,
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum
              .PENDING,
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum
              .SUCCEEDED,
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum
              .FAILED,
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum
              .CANCELLED,
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum
              .CANCELLING,
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum
              .TRAINING
        ];
      }
    };

export type GoogleCloudDialogflowV2EntityTypeAutoExpansionMode =
    'AUTO_EXPANSION_MODE_UNSPECIFIED'|'AUTO_EXPANSION_MODE_DEFAULT';

export interface IGoogleCloudDialogflowV2EntityTypeAutoExpansionModeEnum {
  readonly AUTO_EXPANSION_MODE_UNSPECIFIED:
      GoogleCloudDialogflowV2EntityTypeAutoExpansionMode;
  readonly AUTO_EXPANSION_MODE_DEFAULT:
      GoogleCloudDialogflowV2EntityTypeAutoExpansionMode;

  values(): Array<GoogleCloudDialogflowV2EntityTypeAutoExpansionMode>;
}

export const GoogleCloudDialogflowV2EntityTypeAutoExpansionModeEnum:
    IGoogleCloudDialogflowV2EntityTypeAutoExpansionModeEnum = {
      AUTO_EXPANSION_MODE_DEFAULT: <
          GoogleCloudDialogflowV2EntityTypeAutoExpansionMode>'AUTO_EXPANSION_MODE_DEFAULT',
      AUTO_EXPANSION_MODE_UNSPECIFIED: <
          GoogleCloudDialogflowV2EntityTypeAutoExpansionMode>'AUTO_EXPANSION_MODE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV2EntityTypeAutoExpansionMode> {
        return [
          GoogleCloudDialogflowV2EntityTypeAutoExpansionModeEnum
              .AUTO_EXPANSION_MODE_UNSPECIFIED,
          GoogleCloudDialogflowV2EntityTypeAutoExpansionModeEnum
              .AUTO_EXPANSION_MODE_DEFAULT
        ];
      }
    };

export type GoogleCloudDialogflowV2EntityTypeKind =
    'KIND_UNSPECIFIED'|'KIND_MAP'|'KIND_LIST'|'KIND_REGEXP';

export interface IGoogleCloudDialogflowV2EntityTypeKindEnum {
  readonly KIND_UNSPECIFIED: GoogleCloudDialogflowV2EntityTypeKind;
  readonly KIND_MAP: GoogleCloudDialogflowV2EntityTypeKind;
  readonly KIND_LIST: GoogleCloudDialogflowV2EntityTypeKind;
  readonly KIND_REGEXP: GoogleCloudDialogflowV2EntityTypeKind;

  values(): Array<GoogleCloudDialogflowV2EntityTypeKind>;
}

export const GoogleCloudDialogflowV2EntityTypeKindEnum:
    IGoogleCloudDialogflowV2EntityTypeKindEnum = {
      KIND_LIST: <GoogleCloudDialogflowV2EntityTypeKind>'KIND_LIST',
      KIND_MAP: <GoogleCloudDialogflowV2EntityTypeKind>'KIND_MAP',
      KIND_REGEXP: <GoogleCloudDialogflowV2EntityTypeKind>'KIND_REGEXP',
      KIND_UNSPECIFIED:
          <GoogleCloudDialogflowV2EntityTypeKind>'KIND_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV2EntityTypeKind> {
        return [
          GoogleCloudDialogflowV2EntityTypeKindEnum.KIND_UNSPECIFIED,
          GoogleCloudDialogflowV2EntityTypeKindEnum.KIND_MAP,
          GoogleCloudDialogflowV2EntityTypeKindEnum.KIND_LIST,
          GoogleCloudDialogflowV2EntityTypeKindEnum.KIND_REGEXP
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentDefaultResponsePlatforms =
    'PLATFORM_UNSPECIFIED'|'FACEBOOK'|'SLACK'|'TELEGRAM'|'KIK'|'SKYPE'|'LINE'|
    'VIBER'|'ACTIONS_ON_GOOGLE'|'AMAZON_ALEXA'|'TELEPHONY'|'GOOGLE_HANGOUTS'|
    'GOOGLE_RBM'|'WE_CHAT';

export interface IGoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum {
  readonly PLATFORM_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly FACEBOOK: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly SLACK: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly TELEGRAM: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly KIK: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly SKYPE: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly LINE: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly VIBER: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly ACTIONS_ON_GOOGLE:
      GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly AMAZON_ALEXA: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly TELEPHONY: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly GOOGLE_HANGOUTS:
      GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly GOOGLE_RBM: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;
  readonly WE_CHAT: GoogleCloudDialogflowV2IntentDefaultResponsePlatforms;

  values(): Array<GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>;
}

export const GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum:
    IGoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum = {
      ACTIONS_ON_GOOGLE: <
          GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'ACTIONS_ON_GOOGLE',
      AMAZON_ALEXA:
          <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'AMAZON_ALEXA',
      FACEBOOK:
          <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'FACEBOOK',
      GOOGLE_HANGOUTS: <
          GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'GOOGLE_HANGOUTS',
      GOOGLE_RBM:
          <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'GOOGLE_RBM',
      KIK: <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'KIK',
      LINE: <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'LINE',
      PLATFORM_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'PLATFORM_UNSPECIFIED',
      SKYPE: <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'SKYPE',
      SLACK: <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'SLACK',
      TELEGRAM:
          <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'TELEGRAM',
      TELEPHONY:
          <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'TELEPHONY',
      VIBER: <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'VIBER',
      WE_CHAT: <GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>'WE_CHAT',
      values(): Array<GoogleCloudDialogflowV2IntentDefaultResponsePlatforms> {
        return [
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum
              .PLATFORM_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum.FACEBOOK,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum.SLACK,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum.TELEGRAM,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum.KIK,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum.SKYPE,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum.LINE,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum.VIBER,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum
              .ACTIONS_ON_GOOGLE,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum
              .AMAZON_ALEXA,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum.TELEPHONY,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum
              .GOOGLE_HANGOUTS,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum.GOOGLE_RBM,
          GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum.WE_CHAT
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint =
    'URL_TYPE_HINT_UNSPECIFIED'|'AMP_ACTION'|'AMP_CONTENT';

export interface IGoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHintEnum {
  readonly URL_TYPE_HINT_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint;
  readonly AMP_ACTION:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint;
  readonly AMP_CONTENT:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint;

  values(): Array<
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint>;
}

export const GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHintEnum:
    IGoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHintEnum = {
      AMP_ACTION: <
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint>'AMP_ACTION',
      AMP_CONTENT: <
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint>'AMP_CONTENT',
      URL_TYPE_HINT_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint>'URL_TYPE_HINT_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint> {
        return [
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHintEnum
              .URL_TYPE_HINT_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHintEnum
              .AMP_ACTION,
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHintEnum
              .AMP_CONTENT
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions =
    'IMAGE_DISPLAY_OPTIONS_UNSPECIFIED'|'GRAY'|'WHITE'|'CROPPED'|
    'BLURRED_BACKGROUND';

export interface IGoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum {
  readonly IMAGE_DISPLAY_OPTIONS_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions;
  readonly GRAY:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions;
  readonly WHITE:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions;
  readonly CROPPED:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions;
  readonly BLURRED_BACKGROUND:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions;

  values(): Array<
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions>;
}

export const GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum:
    IGoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum = {
      BLURRED_BACKGROUND: <
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions>'BLURRED_BACKGROUND',
      CROPPED: <
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions>'CROPPED',
      GRAY: <
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions>'GRAY',
      IMAGE_DISPLAY_OPTIONS_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions>'IMAGE_DISPLAY_OPTIONS_UNSPECIFIED',
      WHITE: <
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions>'WHITE',
      values(): Array<
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions> {
        return [
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum
              .IMAGE_DISPLAY_OPTIONS_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum
              .GRAY,
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum
              .WHITE,
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum
              .CROPPED,
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum
              .BLURRED_BACKGROUND
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment =
    'HORIZONTAL_ALIGNMENT_UNSPECIFIED'|'LEADING'|'CENTER'|'TRAILING';

export interface IGoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignmentEnum {
  readonly HORIZONTAL_ALIGNMENT_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment;
  readonly LEADING:
      GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment;
  readonly CENTER:
      GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment;
  readonly TRAILING:
      GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment;

  values(): Array<
      GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment>;
}

export const GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignmentEnum:
    IGoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignmentEnum = {
      CENTER: <
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment>'CENTER',
      HORIZONTAL_ALIGNMENT_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment>'HORIZONTAL_ALIGNMENT_UNSPECIFIED',
      LEADING: <
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment>'LEADING',
      TRAILING: <
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment>'TRAILING',
      values(): Array<
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment> {
        return [
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignmentEnum
              .HORIZONTAL_ALIGNMENT_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignmentEnum
              .LEADING,
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignmentEnum
              .CENTER,
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignmentEnum
              .TRAILING
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentMessageMediaContentMediaType =
    'RESPONSE_MEDIA_TYPE_UNSPECIFIED'|'AUDIO'|'MEDIA_STATUS_ACK';

export interface IGoogleCloudDialogflowV2IntentMessageMediaContentMediaTypeEnum {
  readonly RESPONSE_MEDIA_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentMessageMediaContentMediaType;
  readonly AUDIO: GoogleCloudDialogflowV2IntentMessageMediaContentMediaType;
  readonly MEDIA_STATUS_ACK:
      GoogleCloudDialogflowV2IntentMessageMediaContentMediaType;

  values(): Array<GoogleCloudDialogflowV2IntentMessageMediaContentMediaType>;
}

export const GoogleCloudDialogflowV2IntentMessageMediaContentMediaTypeEnum:
    IGoogleCloudDialogflowV2IntentMessageMediaContentMediaTypeEnum = {
      AUDIO: <GoogleCloudDialogflowV2IntentMessageMediaContentMediaType>'AUDIO',
      MEDIA_STATUS_ACK: <
          GoogleCloudDialogflowV2IntentMessageMediaContentMediaType>'MEDIA_STATUS_ACK',
      RESPONSE_MEDIA_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentMessageMediaContentMediaType>'RESPONSE_MEDIA_TYPE_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowV2IntentMessageMediaContentMediaType> {
            return [
              GoogleCloudDialogflowV2IntentMessageMediaContentMediaTypeEnum
                  .RESPONSE_MEDIA_TYPE_UNSPECIFIED,
              GoogleCloudDialogflowV2IntentMessageMediaContentMediaTypeEnum
                  .AUDIO,
              GoogleCloudDialogflowV2IntentMessageMediaContentMediaTypeEnum
                  .MEDIA_STATUS_ACK
            ];
          }
    };

export type GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode =
    'REPEAT_MODE_UNSPECIFIED'|'OFF'|'ALL';

export interface IGoogleCloudDialogflowV2IntentMessageMediaContentRepeatModeEnum {
  readonly REPEAT_MODE_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode;
  readonly OFF: GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode;
  readonly ALL: GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode;

  values(): Array<GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode>;
}

export const GoogleCloudDialogflowV2IntentMessageMediaContentRepeatModeEnum:
    IGoogleCloudDialogflowV2IntentMessageMediaContentRepeatModeEnum = {
      ALL: <GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode>'ALL',
      OFF: <GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode>'OFF',
      REPEAT_MODE_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode>'REPEAT_MODE_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode> {
            return [
              GoogleCloudDialogflowV2IntentMessageMediaContentRepeatModeEnum
                  .REPEAT_MODE_UNSPECIFIED,
              GoogleCloudDialogflowV2IntentMessageMediaContentRepeatModeEnum
                  .OFF,
              GoogleCloudDialogflowV2IntentMessageMediaContentRepeatModeEnum.ALL
            ];
          }
    };

export type GoogleCloudDialogflowV2IntentMessagePlatform =
    'PLATFORM_UNSPECIFIED'|'FACEBOOK'|'SLACK'|'TELEGRAM'|'KIK'|'SKYPE'|'LINE'|
    'VIBER'|'ACTIONS_ON_GOOGLE'|'AMAZON_ALEXA'|'TELEPHONY'|'GOOGLE_HANGOUTS'|
    'GOOGLE_RBM'|'WE_CHAT';

export interface IGoogleCloudDialogflowV2IntentMessagePlatformEnum {
  readonly PLATFORM_UNSPECIFIED: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly FACEBOOK: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly SLACK: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly TELEGRAM: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly KIK: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly SKYPE: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly LINE: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly VIBER: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly ACTIONS_ON_GOOGLE: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly AMAZON_ALEXA: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly TELEPHONY: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly GOOGLE_HANGOUTS: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly GOOGLE_RBM: GoogleCloudDialogflowV2IntentMessagePlatform;
  readonly WE_CHAT: GoogleCloudDialogflowV2IntentMessagePlatform;

  values(): Array<GoogleCloudDialogflowV2IntentMessagePlatform>;
}

export const GoogleCloudDialogflowV2IntentMessagePlatformEnum:
    IGoogleCloudDialogflowV2IntentMessagePlatformEnum = {
      ACTIONS_ON_GOOGLE:
          <GoogleCloudDialogflowV2IntentMessagePlatform>'ACTIONS_ON_GOOGLE',
      AMAZON_ALEXA:
          <GoogleCloudDialogflowV2IntentMessagePlatform>'AMAZON_ALEXA',
      FACEBOOK: <GoogleCloudDialogflowV2IntentMessagePlatform>'FACEBOOK',
      GOOGLE_HANGOUTS:
          <GoogleCloudDialogflowV2IntentMessagePlatform>'GOOGLE_HANGOUTS',
      GOOGLE_RBM: <GoogleCloudDialogflowV2IntentMessagePlatform>'GOOGLE_RBM',
      KIK: <GoogleCloudDialogflowV2IntentMessagePlatform>'KIK',
      LINE: <GoogleCloudDialogflowV2IntentMessagePlatform>'LINE',
      PLATFORM_UNSPECIFIED:
          <GoogleCloudDialogflowV2IntentMessagePlatform>'PLATFORM_UNSPECIFIED',
      SKYPE: <GoogleCloudDialogflowV2IntentMessagePlatform>'SKYPE',
      SLACK: <GoogleCloudDialogflowV2IntentMessagePlatform>'SLACK',
      TELEGRAM: <GoogleCloudDialogflowV2IntentMessagePlatform>'TELEGRAM',
      TELEPHONY: <GoogleCloudDialogflowV2IntentMessagePlatform>'TELEPHONY',
      VIBER: <GoogleCloudDialogflowV2IntentMessagePlatform>'VIBER',
      WE_CHAT: <GoogleCloudDialogflowV2IntentMessagePlatform>'WE_CHAT',
      values(): Array<GoogleCloudDialogflowV2IntentMessagePlatform> {
        return [
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.PLATFORM_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.FACEBOOK,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.SLACK,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.TELEGRAM,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.KIK,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.SKYPE,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.LINE,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.VIBER,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.ACTIONS_ON_GOOGLE,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.AMAZON_ALEXA,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.TELEPHONY,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.GOOGLE_HANGOUTS,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.GOOGLE_RBM,
          GoogleCloudDialogflowV2IntentMessagePlatformEnum.WE_CHAT
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight =
    'HEIGHT_UNSPECIFIED'|'SHORT'|'MEDIUM'|'TALL';

export interface IGoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeightEnum {
  readonly HEIGHT_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight;
  readonly SHORT:
      GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight;
  readonly MEDIUM:
      GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight;
  readonly TALL:
      GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight;

  values():
      Array<GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight>;
}

export const GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeightEnum:
    IGoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeightEnum = {
      HEIGHT_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight>'HEIGHT_UNSPECIFIED',
      MEDIUM: <
          GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight>'MEDIUM',
      SHORT: <
          GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight>'SHORT',
      TALL: <
          GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight>'TALL',
      values(): Array<
          GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight> {
        return [
          GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeightEnum
              .HEIGHT_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeightEnum
              .SHORT,
          GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeightEnum
              .MEDIUM,
          GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeightEnum
              .TALL
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth =
    'CARD_WIDTH_UNSPECIFIED'|'SMALL'|'MEDIUM';

export interface IGoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidthEnum {
  readonly CARD_WIDTH_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth;
  readonly SMALL: GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth;
  readonly MEDIUM: GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth;

  values(): Array<GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth>;
}

export const GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidthEnum:
    IGoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidthEnum = {
      CARD_WIDTH_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth>'CARD_WIDTH_UNSPECIFIED',
      MEDIUM: <
          GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth>'MEDIUM',
      SMALL:
          <GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth>'SMALL',
      values():
          Array<GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth> {
            return [
              GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidthEnum
                  .CARD_WIDTH_UNSPECIFIED,
              GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidthEnum
                  .SMALL,
              GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidthEnum
                  .MEDIUM
            ];
          }
    };

export type GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation =
    'CARD_ORIENTATION_UNSPECIFIED'|'HORIZONTAL'|'VERTICAL';

export interface IGoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientationEnum {
  readonly CARD_ORIENTATION_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation;
  readonly HORIZONTAL:
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation;
  readonly VERTICAL:
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation;

  values(): Array<
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation>;
}

export const GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientationEnum:
    IGoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientationEnum = {
      CARD_ORIENTATION_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation>'CARD_ORIENTATION_UNSPECIFIED',
      HORIZONTAL: <
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation>'HORIZONTAL',
      VERTICAL: <
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation>'VERTICAL',
      values(): Array<
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation> {
        return [
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientationEnum
              .CARD_ORIENTATION_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientationEnum
              .HORIZONTAL,
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientationEnum
              .VERTICAL
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment =
    'THUMBNAIL_IMAGE_ALIGNMENT_UNSPECIFIED'|'LEFT'|'RIGHT';

export interface IGoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignmentEnum {
  readonly THUMBNAIL_IMAGE_ALIGNMENT_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment;
  readonly LEFT:
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment;
  readonly RIGHT:
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment;

  values(): Array<
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment>;
}

export const GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignmentEnum:
    IGoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignmentEnum = {
      LEFT: <
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment>'LEFT',
      RIGHT: <
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment>'RIGHT',
      THUMBNAIL_IMAGE_ALIGNMENT_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment>'THUMBNAIL_IMAGE_ALIGNMENT_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment> {
        return [
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignmentEnum
              .THUMBNAIL_IMAGE_ALIGNMENT_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignmentEnum
              .LEFT,
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignmentEnum
              .RIGHT
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit =
    'TELEPHONY_DTMF_UNSPECIFIED'|'DTMF_ONE'|'DTMF_TWO'|'DTMF_THREE'|'DTMF_FOUR'|
    'DTMF_FIVE'|'DTMF_SIX'|'DTMF_SEVEN'|'DTMF_EIGHT'|'DTMF_NINE'|'DTMF_ZERO'|
    'DTMF_A'|'DTMF_B'|'DTMF_C'|'DTMF_D'|'DTMF_STAR'|'DTMF_POUND';

export interface IGoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum {
  readonly TELEPHONY_DTMF_UNSPECIFIED:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_ONE:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_TWO:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_THREE:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_FOUR:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_FIVE:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_SIX:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_SEVEN:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_EIGHT:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_NINE:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_ZERO:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_A:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_B:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_C:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_D:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_STAR:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;
  readonly DTMF_POUND:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit;

  values():
      Array<GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>;
}

export const GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum:
    IGoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum = {
      DTMF_A: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_A',
      DTMF_B: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_B',
      DTMF_C: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_C',
      DTMF_D: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_D',
      DTMF_EIGHT: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_EIGHT',
      DTMF_FIVE: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_FIVE',
      DTMF_FOUR: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_FOUR',
      DTMF_NINE: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_NINE',
      DTMF_ONE: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_ONE',
      DTMF_POUND: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_POUND',
      DTMF_SEVEN: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_SEVEN',
      DTMF_SIX: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_SIX',
      DTMF_STAR: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_STAR',
      DTMF_THREE: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_THREE',
      DTMF_TWO: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_TWO',
      DTMF_ZERO: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'DTMF_ZERO',
      TELEPHONY_DTMF_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit>'TELEPHONY_DTMF_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit> {
        return [
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .TELEPHONY_DTMF_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_ONE,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_TWO,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_THREE,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_FOUR,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_FIVE,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_SIX,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_SEVEN,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_EIGHT,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_NINE,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_ZERO,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_A,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_B,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_C,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_D,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_STAR,
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
              .DTMF_POUND
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentTrainingPhraseType =
    'TYPE_UNSPECIFIED'|'EXAMPLE'|'TEMPLATE';

export interface IGoogleCloudDialogflowV2IntentTrainingPhraseTypeEnum {
  readonly TYPE_UNSPECIFIED: GoogleCloudDialogflowV2IntentTrainingPhraseType;
  readonly EXAMPLE: GoogleCloudDialogflowV2IntentTrainingPhraseType;
  readonly TEMPLATE: GoogleCloudDialogflowV2IntentTrainingPhraseType;

  values(): Array<GoogleCloudDialogflowV2IntentTrainingPhraseType>;
}

export const GoogleCloudDialogflowV2IntentTrainingPhraseTypeEnum:
    IGoogleCloudDialogflowV2IntentTrainingPhraseTypeEnum = {
      EXAMPLE: <GoogleCloudDialogflowV2IntentTrainingPhraseType>'EXAMPLE',
      TEMPLATE: <GoogleCloudDialogflowV2IntentTrainingPhraseType>'TEMPLATE',
      TYPE_UNSPECIFIED:
          <GoogleCloudDialogflowV2IntentTrainingPhraseType>'TYPE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV2IntentTrainingPhraseType> {
        return [
          GoogleCloudDialogflowV2IntentTrainingPhraseTypeEnum.TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentTrainingPhraseTypeEnum.EXAMPLE,
          GoogleCloudDialogflowV2IntentTrainingPhraseTypeEnum.TEMPLATE
        ];
      }
    };

export type GoogleCloudDialogflowV2IntentWebhookState =
    'WEBHOOK_STATE_UNSPECIFIED'|'WEBHOOK_STATE_ENABLED'|
    'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING';

export interface IGoogleCloudDialogflowV2IntentWebhookStateEnum {
  readonly WEBHOOK_STATE_UNSPECIFIED: GoogleCloudDialogflowV2IntentWebhookState;
  readonly WEBHOOK_STATE_ENABLED: GoogleCloudDialogflowV2IntentWebhookState;
  readonly WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING:
      GoogleCloudDialogflowV2IntentWebhookState;

  values(): Array<GoogleCloudDialogflowV2IntentWebhookState>;
}

export const GoogleCloudDialogflowV2IntentWebhookStateEnum:
    IGoogleCloudDialogflowV2IntentWebhookStateEnum = {
      WEBHOOK_STATE_ENABLED:
          <GoogleCloudDialogflowV2IntentWebhookState>'WEBHOOK_STATE_ENABLED',
      WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING: <
          GoogleCloudDialogflowV2IntentWebhookState>'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING',
      WEBHOOK_STATE_UNSPECIFIED: <
          GoogleCloudDialogflowV2IntentWebhookState>'WEBHOOK_STATE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV2IntentWebhookState> {
        return [
          GoogleCloudDialogflowV2IntentWebhookStateEnum
              .WEBHOOK_STATE_UNSPECIFIED,
          GoogleCloudDialogflowV2IntentWebhookStateEnum.WEBHOOK_STATE_ENABLED,
          GoogleCloudDialogflowV2IntentWebhookStateEnum
              .WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING
        ];
      }
    };

export type GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface IGoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly SMART_REPLY_BERT_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly SUMMARIZATION_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly KEY_MOMENT_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly SUMMARIZATION_V2_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType;

  values(): Array<GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>;
}

export const GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum:
    IGoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL: <
          GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType>'SUMMARIZATION_V2_MODEL',
      values():
          Array<GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType> {
            return [
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .MODEL_TYPE_UNSPECIFIED,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .ARTICLE_SUGGESTION_GBT_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .SMART_REPLY_DUAL_ENCODER_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .SMART_REPLY_BERT_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .SMART_COMPOSE_DUAL_ENCODER_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .SMART_REPLY_GENERATIVE_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .SUMMARIZATION_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .KEY_MOMENT_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .ISSUE_MODEL_DUAL_ENCODER_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .SUMMARIZATION_V2_MODEL,
              GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
                  .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
            ];
          }
    };

export type GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel =
    'MATCH_CONFIDENCE_LEVEL_UNSPECIFIED'|'LOW'|'MEDIUM'|'HIGH';

export interface IGoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevelEnum {
  readonly MATCH_CONFIDENCE_LEVEL_UNSPECIFIED:
      GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel;
  readonly LOW:
      GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel;
  readonly MEDIUM:
      GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel;
  readonly HIGH:
      GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel;

  values():
      Array<GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel>;
}

export const GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevelEnum:
    IGoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevelEnum = {
      HIGH: <
          GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel>'HIGH',
      LOW: <
          GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel>'LOW',
      MATCH_CONFIDENCE_LEVEL_UNSPECIFIED: <
          GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel>'MATCH_CONFIDENCE_LEVEL_UNSPECIFIED',
      MEDIUM: <
          GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel>'MEDIUM',
      values(): Array<
          GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel> {
        return [
          GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevelEnum
              .MATCH_CONFIDENCE_LEVEL_UNSPECIFIED,
          GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevelEnum
              .LOW,
          GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevelEnum
              .MEDIUM,
          GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevelEnum
              .HIGH
        ];
      }
    };

export type GoogleCloudDialogflowV2KnowledgeOperationMetadataState =
    'STATE_UNSPECIFIED'|'PENDING'|'RUNNING'|'DONE';

export interface IGoogleCloudDialogflowV2KnowledgeOperationMetadataStateEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudDialogflowV2KnowledgeOperationMetadataState;
  readonly PENDING: GoogleCloudDialogflowV2KnowledgeOperationMetadataState;
  readonly RUNNING: GoogleCloudDialogflowV2KnowledgeOperationMetadataState;
  readonly DONE: GoogleCloudDialogflowV2KnowledgeOperationMetadataState;

  values(): Array<GoogleCloudDialogflowV2KnowledgeOperationMetadataState>;
}

export const GoogleCloudDialogflowV2KnowledgeOperationMetadataStateEnum:
    IGoogleCloudDialogflowV2KnowledgeOperationMetadataStateEnum = {
      DONE: <GoogleCloudDialogflowV2KnowledgeOperationMetadataState>'DONE',
      PENDING:
          <GoogleCloudDialogflowV2KnowledgeOperationMetadataState>'PENDING',
      RUNNING:
          <GoogleCloudDialogflowV2KnowledgeOperationMetadataState>'RUNNING',
      STATE_UNSPECIFIED: <
          GoogleCloudDialogflowV2KnowledgeOperationMetadataState>'STATE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV2KnowledgeOperationMetadataState> {
        return [
          GoogleCloudDialogflowV2KnowledgeOperationMetadataStateEnum
              .STATE_UNSPECIFIED,
          GoogleCloudDialogflowV2KnowledgeOperationMetadataStateEnum.PENDING,
          GoogleCloudDialogflowV2KnowledgeOperationMetadataStateEnum.RUNNING,
          GoogleCloudDialogflowV2KnowledgeOperationMetadataStateEnum.DONE
        ];
      }
    };

export type GoogleCloudDialogflowV2MessageParticipantRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface IGoogleCloudDialogflowV2MessageParticipantRoleEnum {
  readonly ROLE_UNSPECIFIED: GoogleCloudDialogflowV2MessageParticipantRole;
  readonly HUMAN_AGENT: GoogleCloudDialogflowV2MessageParticipantRole;
  readonly AUTOMATED_AGENT: GoogleCloudDialogflowV2MessageParticipantRole;
  readonly END_USER: GoogleCloudDialogflowV2MessageParticipantRole;

  values(): Array<GoogleCloudDialogflowV2MessageParticipantRole>;
}

export const GoogleCloudDialogflowV2MessageParticipantRoleEnum:
    IGoogleCloudDialogflowV2MessageParticipantRoleEnum = {
      AUTOMATED_AGENT:
          <GoogleCloudDialogflowV2MessageParticipantRole>'AUTOMATED_AGENT',
      END_USER: <GoogleCloudDialogflowV2MessageParticipantRole>'END_USER',
      HUMAN_AGENT: <GoogleCloudDialogflowV2MessageParticipantRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED:
          <GoogleCloudDialogflowV2MessageParticipantRole>'ROLE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV2MessageParticipantRole> {
        return [
          GoogleCloudDialogflowV2MessageParticipantRoleEnum.ROLE_UNSPECIFIED,
          GoogleCloudDialogflowV2MessageParticipantRoleEnum.HUMAN_AGENT,
          GoogleCloudDialogflowV2MessageParticipantRoleEnum.AUTOMATED_AGENT,
          GoogleCloudDialogflowV2MessageParticipantRoleEnum.END_USER
        ];
      }
    };

export type GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode =
    'ENTITY_OVERRIDE_MODE_UNSPECIFIED'|'ENTITY_OVERRIDE_MODE_OVERRIDE'|
    'ENTITY_OVERRIDE_MODE_SUPPLEMENT';

export interface IGoogleCloudDialogflowV2SessionEntityTypeEntityOverrideModeEnum {
  readonly ENTITY_OVERRIDE_MODE_UNSPECIFIED:
      GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode;
  readonly ENTITY_OVERRIDE_MODE_OVERRIDE:
      GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode;
  readonly ENTITY_OVERRIDE_MODE_SUPPLEMENT:
      GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode;

  values(): Array<GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode>;
}

export const GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideModeEnum:
    IGoogleCloudDialogflowV2SessionEntityTypeEntityOverrideModeEnum = {
      ENTITY_OVERRIDE_MODE_OVERRIDE: <
          GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode>'ENTITY_OVERRIDE_MODE_OVERRIDE',
      ENTITY_OVERRIDE_MODE_SUPPLEMENT: <
          GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode>'ENTITY_OVERRIDE_MODE_SUPPLEMENT',
      ENTITY_OVERRIDE_MODE_UNSPECIFIED: <
          GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode>'ENTITY_OVERRIDE_MODE_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode> {
            return [
              GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideModeEnum
                  .ENTITY_OVERRIDE_MODE_UNSPECIFIED,
              GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideModeEnum
                  .ENTITY_OVERRIDE_MODE_OVERRIDE,
              GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideModeEnum
                  .ENTITY_OVERRIDE_MODE_SUPPLEMENT
            ];
          }
    };

export type GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface IGoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRoleEnum {
  readonly ROLE_UNSPECIFIED:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole;
  readonly HUMAN_AGENT:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole;
  readonly AUTOMATED_AGENT:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole;
  readonly END_USER:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole;

  values(): Array<
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole>;
}

export const GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRoleEnum:
    IGoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRoleEnum = {
      AUTOMATED_AGENT: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole>'AUTOMATED_AGENT',
      END_USER: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole>'END_USER',
      HUMAN_AGENT: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole>'ROLE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole> {
        return [
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRoleEnum
              .ROLE_UNSPECIFIED,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRoleEnum
              .HUMAN_AGENT,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRoleEnum
              .AUTOMATED_AGENT,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRoleEnum
              .END_USER
        ];
      }
    };

export type GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType =
    'TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION'|'FAQ'|'SMART_REPLY'|
    'DIALOGFLOW_ASSIST'|'SMART_COMPOSE'|'ISSUE_SMART_COMPOSE'|
    'SPELL_GRAMMAR_CORRECTION'|'CONVERSATION_SUMMARIZATION'|'KEY_MOMENT'|
    'ARTICLE_SEARCH'|'CONVERSATION_GUIDANCE'|'ARTICLE_SUGGESTION_VOICE'|
    'CONVERSATION_SUMMARIZATION_VOICE';

export interface IGoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum {
  readonly TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly ARTICLE_SUGGESTION:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly FAQ:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly SMART_REPLY:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly DIALOGFLOW_ASSIST:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly SMART_COMPOSE:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly ISSUE_SMART_COMPOSE:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly SPELL_GRAMMAR_CORRECTION:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly CONVERSATION_SUMMARIZATION:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly KEY_MOMENT:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly ARTICLE_SEARCH:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly CONVERSATION_GUIDANCE:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly ARTICLE_SUGGESTION_VOICE:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;
  readonly CONVERSATION_SUMMARIZATION_VOICE:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType;

  values(): Array<
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>;
}

export const GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum:
    IGoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum = {
      ARTICLE_SEARCH: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'ARTICLE_SEARCH',
      ARTICLE_SUGGESTION: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'ARTICLE_SUGGESTION',
      ARTICLE_SUGGESTION_VOICE: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'ARTICLE_SUGGESTION_VOICE',
      CONVERSATION_GUIDANCE: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'CONVERSATION_GUIDANCE',
      CONVERSATION_SUMMARIZATION: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'CONVERSATION_SUMMARIZATION',
      CONVERSATION_SUMMARIZATION_VOICE: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'CONVERSATION_SUMMARIZATION_VOICE',
      DIALOGFLOW_ASSIST: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'DIALOGFLOW_ASSIST',
      FAQ: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'FAQ',
      ISSUE_SMART_COMPOSE: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'ISSUE_SMART_COMPOSE',
      KEY_MOMENT: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'KEY_MOMENT',
      SMART_COMPOSE: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'SMART_COMPOSE',
      SMART_REPLY: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'SMART_REPLY',
      SPELL_GRAMMAR_CORRECTION: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'SPELL_GRAMMAR_CORRECTION',
      TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType>'TYPE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType> {
        return [
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .ARTICLE_SUGGESTION,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .FAQ,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .SMART_REPLY,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .DIALOGFLOW_ASSIST,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .SMART_COMPOSE,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .ISSUE_SMART_COMPOSE,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .SPELL_GRAMMAR_CORRECTION,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .CONVERSATION_SUMMARIZATION,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .KEY_MOMENT,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .ARTICLE_SEARCH,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .CONVERSATION_GUIDANCE,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .ARTICLE_SUGGESTION_VOICE,
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
              .CONVERSATION_SUMMARIZATION_VOICE
        ];
      }
    };

export type GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface IGoogleCloudDialogflowV2SmartComposeModelMetadataTargetRoleEnum {
  readonly ROLE_UNSPECIFIED:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole;
  readonly HUMAN_AGENT:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole;
  readonly AUTOMATED_AGENT:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole;
  readonly END_USER: GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole;

  values(): Array<GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole>;
}

export const GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRoleEnum:
    IGoogleCloudDialogflowV2SmartComposeModelMetadataTargetRoleEnum = {
      AUTOMATED_AGENT: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole>'AUTOMATED_AGENT',
      END_USER: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole>'END_USER',
      HUMAN_AGENT: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole>'ROLE_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole> {
            return [
              GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRoleEnum
                  .ROLE_UNSPECIFIED,
              GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRoleEnum
                  .HUMAN_AGENT,
              GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRoleEnum
                  .AUTOMATED_AGENT,
              GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRoleEnum
                  .END_USER
            ];
          }
    };

export type GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface IGoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly SMART_REPLY_BERT_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly SUMMARIZATION_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly KEY_MOMENT_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly SUMMARIZATION_V2_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType;

  values():
      Array<GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>;
}

export const GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum:
    IGoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL: <
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType>'SUMMARIZATION_V2_MODEL',
      values(): Array<
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType> {
        return [
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .MODEL_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_GBT_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_BERT_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .SMART_COMPOSE_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_GENERATIVE_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .SUMMARIZATION_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .KEY_MOMENT_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .SUMMARIZATION_V2_MODEL,
          GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
        ];
      }
    };

export type GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface IGoogleCloudDialogflowV2SmartReplyModelMetadataTargetRoleEnum {
  readonly ROLE_UNSPECIFIED:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole;
  readonly HUMAN_AGENT:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole;
  readonly AUTOMATED_AGENT:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole;
  readonly END_USER: GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole;

  values(): Array<GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole>;
}

export const GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRoleEnum:
    IGoogleCloudDialogflowV2SmartReplyModelMetadataTargetRoleEnum = {
      AUTOMATED_AGENT: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole>'AUTOMATED_AGENT',
      END_USER:
          <GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole>'END_USER',
      HUMAN_AGENT: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole>'ROLE_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole> {
            return [
              GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRoleEnum
                  .ROLE_UNSPECIFIED,
              GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRoleEnum
                  .HUMAN_AGENT,
              GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRoleEnum
                  .AUTOMATED_AGENT,
              GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRoleEnum
                  .END_USER
            ];
          }
    };

export type GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface IGoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly SMART_REPLY_BERT_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly SUMMARIZATION_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly KEY_MOMENT_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly SUMMARIZATION_V2_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType;

  values():
      Array<GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>;
}

export const GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum:
    IGoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL: <
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType>'SUMMARIZATION_V2_MODEL',
      values(): Array<
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType> {
        return [
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .MODEL_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_GBT_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_BERT_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .SMART_COMPOSE_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_GENERATIVE_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .SUMMARIZATION_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .KEY_MOMENT_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .SUMMARIZATION_V2_MODEL,
          GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
        ];
      }
    };

export type GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface IGoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly SMART_REPLY_BERT_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly SUMMARIZATION_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly KEY_MOMENT_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly SUMMARIZATION_V2_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType;

  values():
      Array<GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>;
}

export const GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum:
    IGoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL: <
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType>'SUMMARIZATION_V2_MODEL',
      values(): Array<
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType> {
        return [
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .MODEL_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_GBT_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_BERT_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .SMART_COMPOSE_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .SMART_REPLY_GENERATIVE_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .SUMMARIZATION_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .KEY_MOMENT_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .SUMMARIZATION_V2_MODEL,
          GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType =
    'OPERATION_TYPE_UNSPECIFIED'|'CREATE_FUNCTION'|'UPDATE_FUNCTION'|
    'DELETE_FUNCTION';

export interface IGoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataTypeEnum {
  readonly OPERATION_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType;
  readonly CREATE_FUNCTION:
      GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType;
  readonly UPDATE_FUNCTION:
      GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType;
  readonly DELETE_FUNCTION:
      GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType;

  values():
      Array<GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType>;
}

export const GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataTypeEnum:
    IGoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataTypeEnum = {
      CREATE_FUNCTION: <
          GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType>'CREATE_FUNCTION',
      DELETE_FUNCTION: <
          GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType>'DELETE_FUNCTION',
      OPERATION_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType>'OPERATION_TYPE_UNSPECIFIED',
      UPDATE_FUNCTION: <
          GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType>'UPDATE_FUNCTION',
      values(): Array<
          GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType> {
        return [
          GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataTypeEnum
              .OPERATION_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataTypeEnum
              .CREATE_FUNCTION,
          GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataTypeEnum
              .UPDATE_FUNCTION,
          GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataTypeEnum
              .DELETE_FUNCTION
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1ContinuousTestResultResult =
    'AGGREGATED_TEST_RESULT_UNSPECIFIED'|'PASSED'|'FAILED';

export interface IGoogleCloudDialogflowV3alpha1ContinuousTestResultResultEnum {
  readonly AGGREGATED_TEST_RESULT_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1ContinuousTestResultResult;
  readonly PASSED: GoogleCloudDialogflowV3alpha1ContinuousTestResultResult;
  readonly FAILED: GoogleCloudDialogflowV3alpha1ContinuousTestResultResult;

  values(): Array<GoogleCloudDialogflowV3alpha1ContinuousTestResultResult>;
}

export const GoogleCloudDialogflowV3alpha1ContinuousTestResultResultEnum:
    IGoogleCloudDialogflowV3alpha1ContinuousTestResultResultEnum = {
      AGGREGATED_TEST_RESULT_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1ContinuousTestResultResult>'AGGREGATED_TEST_RESULT_UNSPECIFIED',
      FAILED: <GoogleCloudDialogflowV3alpha1ContinuousTestResultResult>'FAILED',
      PASSED: <GoogleCloudDialogflowV3alpha1ContinuousTestResultResult>'PASSED',
      values(): Array<GoogleCloudDialogflowV3alpha1ContinuousTestResultResult> {
        return [
          GoogleCloudDialogflowV3alpha1ContinuousTestResultResultEnum
              .AGGREGATED_TEST_RESULT_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1ContinuousTestResultResultEnum.PASSED,
          GoogleCloudDialogflowV3alpha1ContinuousTestResultResultEnum.FAILED
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState =
    'STATE_UNSPECIFIED'|'PENDING'|'RUNNING'|'DONE';

export interface IGoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataStateEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState;
  readonly PENDING:
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState;
  readonly RUNNING:
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState;
  readonly DONE:
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState;

  values(): Array<
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState>;
}

export const GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataStateEnum:
    IGoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataStateEnum = {
      DONE: <
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState>'DONE',
      PENDING: <
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState>'PENDING',
      RUNNING: <
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState>'RUNNING',
      STATE_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState>'STATE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState> {
        return [
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataStateEnum
              .STATE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataStateEnum
              .PENDING,
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataStateEnum
              .RUNNING,
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataStateEnum
              .DONE
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding =
    'AUDIO_ENCODING_UNSPECIFIED'|'AUDIO_ENCODING_LINEAR_16'|
    'AUDIO_ENCODING_FLAC'|'AUDIO_ENCODING_MULAW'|'AUDIO_ENCODING_AMR'|
    'AUDIO_ENCODING_AMR_WB'|'AUDIO_ENCODING_OGG_OPUS'|
    'AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE';

export interface IGoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum {
  readonly AUDIO_ENCODING_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_LINEAR_16:
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_FLAC:
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_MULAW:
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_AMR:
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_AMR_WB:
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_OGG_OPUS:
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding;
  readonly AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE:
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding;

  values(): Array<GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding>;
}

export const GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum:
    IGoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum = {
      AUDIO_ENCODING_AMR: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_AMR',
      AUDIO_ENCODING_AMR_WB: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_AMR_WB',
      AUDIO_ENCODING_FLAC: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_FLAC',
      AUDIO_ENCODING_LINEAR_16: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_LINEAR_16',
      AUDIO_ENCODING_MULAW: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_MULAW',
      AUDIO_ENCODING_OGG_OPUS: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_OGG_OPUS',
      AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE',
      AUDIO_ENCODING_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding>'AUDIO_ENCODING_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding> {
            return [
              GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_UNSPECIFIED,
              GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_LINEAR_16,
              GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_FLAC,
              GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_MULAW,
              GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_AMR,
              GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_AMR_WB,
              GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_OGG_OPUS,
              GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum
                  .AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE
            ];
          }
    };

export type GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant =
    'SPEECH_MODEL_VARIANT_UNSPECIFIED'|'USE_BEST_AVAILABLE'|'USE_STANDARD'|
    'USE_ENHANCED';

export interface IGoogleCloudDialogflowV3alpha1InputAudioConfigModelVariantEnum {
  readonly SPEECH_MODEL_VARIANT_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant;
  readonly USE_BEST_AVAILABLE:
      GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant;
  readonly USE_STANDARD:
      GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant;
  readonly USE_ENHANCED:
      GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant;

  values(): Array<GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant>;
}

export const GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariantEnum:
    IGoogleCloudDialogflowV3alpha1InputAudioConfigModelVariantEnum = {
      SPEECH_MODEL_VARIANT_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant>'SPEECH_MODEL_VARIANT_UNSPECIFIED',
      USE_BEST_AVAILABLE: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant>'USE_BEST_AVAILABLE',
      USE_ENHANCED: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant>'USE_ENHANCED',
      USE_STANDARD: <
          GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant>'USE_STANDARD',
      values():
          Array<GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant> {
            return [
              GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariantEnum
                  .SPEECH_MODEL_VARIANT_UNSPECIFIED,
              GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariantEnum
                  .USE_BEST_AVAILABLE,
              GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariantEnum
                  .USE_STANDARD,
              GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariantEnum
                  .USE_ENHANCED
            ];
          }
    };

export type GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner =
    'PARTNER_UNSPECIFIED'|'SIGNALWIRE'|'VOXIMPLANT'|'GENESYS'|'AVAYA'|
    'AUDIO_CODES'|'TWILIO'|'INTRADO'|'TEST_PARTNER'|'AVAYA_TEST'|
    'AUDIO_CODES_TEST'|'TWILIO_TEST';

export interface IGoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum {
  readonly PARTNER_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly SIGNALWIRE:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly VOXIMPLANT:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly GENESYS:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly AVAYA:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly AUDIO_CODES:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly TWILIO:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly INTRADO:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly TEST_PARTNER:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly AVAYA_TEST:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly AUDIO_CODES_TEST:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;
  readonly TWILIO_TEST:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner;

  values(): Array<
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>;
}

export const GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum:
    IGoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum = {
      AUDIO_CODES: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'AUDIO_CODES',
      AUDIO_CODES_TEST: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'AUDIO_CODES_TEST',
      AVAYA: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'AVAYA',
      AVAYA_TEST: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'AVAYA_TEST',
      GENESYS: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'GENESYS',
      INTRADO: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'INTRADO',
      PARTNER_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'PARTNER_UNSPECIFIED',
      SIGNALWIRE: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'SIGNALWIRE',
      TEST_PARTNER: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'TEST_PARTNER',
      TWILIO: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'TWILIO',
      TWILIO_TEST: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'TWILIO_TEST',
      VOXIMPLANT: <
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner>'VOXIMPLANT',
      values(): Array<
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner> {
        return [
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .PARTNER_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .SIGNALWIRE,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .VOXIMPLANT,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .GENESYS,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .AVAYA,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .AUDIO_CODES,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .TWILIO,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .INTRADO,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .TEST_PARTNER,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .AVAYA_TEST,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .AUDIO_CODES_TEST,
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
              .TWILIO_TEST
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1IntegrationState =
    'STATE_UNSPECIFIED'|'ACTIVE'|'DISABLED'|'CREATING'|'DELETING';

export interface IGoogleCloudDialogflowV3alpha1IntegrationStateEnum {
  readonly STATE_UNSPECIFIED: GoogleCloudDialogflowV3alpha1IntegrationState;
  readonly ACTIVE: GoogleCloudDialogflowV3alpha1IntegrationState;
  readonly DISABLED: GoogleCloudDialogflowV3alpha1IntegrationState;
  readonly CREATING: GoogleCloudDialogflowV3alpha1IntegrationState;
  readonly DELETING: GoogleCloudDialogflowV3alpha1IntegrationState;

  values(): Array<GoogleCloudDialogflowV3alpha1IntegrationState>;
}

export const GoogleCloudDialogflowV3alpha1IntegrationStateEnum:
    IGoogleCloudDialogflowV3alpha1IntegrationStateEnum = {
      ACTIVE: <GoogleCloudDialogflowV3alpha1IntegrationState>'ACTIVE',
      CREATING: <GoogleCloudDialogflowV3alpha1IntegrationState>'CREATING',
      DELETING: <GoogleCloudDialogflowV3alpha1IntegrationState>'DELETING',
      DISABLED: <GoogleCloudDialogflowV3alpha1IntegrationState>'DISABLED',
      STATE_UNSPECIFIED:
          <GoogleCloudDialogflowV3alpha1IntegrationState>'STATE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV3alpha1IntegrationState> {
        return [
          GoogleCloudDialogflowV3alpha1IntegrationStateEnum.STATE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1IntegrationStateEnum.ACTIVE,
          GoogleCloudDialogflowV3alpha1IntegrationStateEnum.DISABLED,
          GoogleCloudDialogflowV3alpha1IntegrationStateEnum.CREATING,
          GoogleCloudDialogflowV3alpha1IntegrationStateEnum.DELETING
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1IntegrationType =
    'INTEGRATION_TYPE_UNSPECIFIED'|'ONE_CLICK'|'DIALOGFLOW_MESSENGER'|'LINE'|
    'FACEBOOK_MESSENGER';

export interface IGoogleCloudDialogflowV3alpha1IntegrationTypeEnum {
  readonly INTEGRATION_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1IntegrationType;
  readonly ONE_CLICK: GoogleCloudDialogflowV3alpha1IntegrationType;
  readonly DIALOGFLOW_MESSENGER: GoogleCloudDialogflowV3alpha1IntegrationType;
  readonly LINE: GoogleCloudDialogflowV3alpha1IntegrationType;
  readonly FACEBOOK_MESSENGER: GoogleCloudDialogflowV3alpha1IntegrationType;

  values(): Array<GoogleCloudDialogflowV3alpha1IntegrationType>;
}

export const GoogleCloudDialogflowV3alpha1IntegrationTypeEnum:
    IGoogleCloudDialogflowV3alpha1IntegrationTypeEnum = {
      DIALOGFLOW_MESSENGER:
          <GoogleCloudDialogflowV3alpha1IntegrationType>'DIALOGFLOW_MESSENGER',
      FACEBOOK_MESSENGER:
          <GoogleCloudDialogflowV3alpha1IntegrationType>'FACEBOOK_MESSENGER',
      INTEGRATION_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1IntegrationType>'INTEGRATION_TYPE_UNSPECIFIED',
      LINE: <GoogleCloudDialogflowV3alpha1IntegrationType>'LINE',
      ONE_CLICK: <GoogleCloudDialogflowV3alpha1IntegrationType>'ONE_CLICK',
      values(): Array<GoogleCloudDialogflowV3alpha1IntegrationType> {
        return [
          GoogleCloudDialogflowV3alpha1IntegrationTypeEnum
              .INTEGRATION_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1IntegrationTypeEnum.ONE_CLICK,
          GoogleCloudDialogflowV3alpha1IntegrationTypeEnum.DIALOGFLOW_MESSENGER,
          GoogleCloudDialogflowV3alpha1IntegrationTypeEnum.LINE,
          GoogleCloudDialogflowV3alpha1IntegrationTypeEnum.FACEBOOK_MESSENGER
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1MatchMatchType =
    'MATCH_TYPE_UNSPECIFIED'|'INTENT'|'DIRECT_INTENT'|'PARAMETER_FILLING'|
    'NO_MATCH'|'NO_INPUT'|'EVENT'|'KNOWLEDGE_CONNECTOR';

export interface IGoogleCloudDialogflowV3alpha1MatchMatchTypeEnum {
  readonly MATCH_TYPE_UNSPECIFIED: GoogleCloudDialogflowV3alpha1MatchMatchType;
  readonly INTENT: GoogleCloudDialogflowV3alpha1MatchMatchType;
  readonly DIRECT_INTENT: GoogleCloudDialogflowV3alpha1MatchMatchType;
  readonly PARAMETER_FILLING: GoogleCloudDialogflowV3alpha1MatchMatchType;
  readonly NO_MATCH: GoogleCloudDialogflowV3alpha1MatchMatchType;
  readonly NO_INPUT: GoogleCloudDialogflowV3alpha1MatchMatchType;
  readonly EVENT: GoogleCloudDialogflowV3alpha1MatchMatchType;
  readonly KNOWLEDGE_CONNECTOR: GoogleCloudDialogflowV3alpha1MatchMatchType;

  values(): Array<GoogleCloudDialogflowV3alpha1MatchMatchType>;
}

export const GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum:
    IGoogleCloudDialogflowV3alpha1MatchMatchTypeEnum = {
      DIRECT_INTENT:
          <GoogleCloudDialogflowV3alpha1MatchMatchType>'DIRECT_INTENT',
      EVENT: <GoogleCloudDialogflowV3alpha1MatchMatchType>'EVENT',
      INTENT: <GoogleCloudDialogflowV3alpha1MatchMatchType>'INTENT',
      KNOWLEDGE_CONNECTOR:
          <GoogleCloudDialogflowV3alpha1MatchMatchType>'KNOWLEDGE_CONNECTOR',
      MATCH_TYPE_UNSPECIFIED:
          <GoogleCloudDialogflowV3alpha1MatchMatchType>'MATCH_TYPE_UNSPECIFIED',
      NO_INPUT: <GoogleCloudDialogflowV3alpha1MatchMatchType>'NO_INPUT',
      NO_MATCH: <GoogleCloudDialogflowV3alpha1MatchMatchType>'NO_MATCH',
      PARAMETER_FILLING:
          <GoogleCloudDialogflowV3alpha1MatchMatchType>'PARAMETER_FILLING',
      values(): Array<GoogleCloudDialogflowV3alpha1MatchMatchType> {
        return [
          GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum
              .MATCH_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum.INTENT,
          GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum.DIRECT_INTENT,
          GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum.PARAMETER_FILLING,
          GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum.NO_MATCH,
          GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum.NO_INPUT,
          GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum.EVENT,
          GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum.KNOWLEDGE_CONNECTOR
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1MatchModelType =
    'MODEL_TYPE_UNSPECIFIED'|'MODEL_TYPE_STANDARD'|'MODEL_TYPE_CUSTOM'|
    'MODEL_TYPE_ADVANCED';

export interface IGoogleCloudDialogflowV3alpha1MatchModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED: GoogleCloudDialogflowV3alpha1MatchModelType;
  readonly MODEL_TYPE_STANDARD: GoogleCloudDialogflowV3alpha1MatchModelType;
  readonly MODEL_TYPE_CUSTOM: GoogleCloudDialogflowV3alpha1MatchModelType;
  readonly MODEL_TYPE_ADVANCED: GoogleCloudDialogflowV3alpha1MatchModelType;

  values(): Array<GoogleCloudDialogflowV3alpha1MatchModelType>;
}

export const GoogleCloudDialogflowV3alpha1MatchModelTypeEnum:
    IGoogleCloudDialogflowV3alpha1MatchModelTypeEnum = {
      MODEL_TYPE_ADVANCED:
          <GoogleCloudDialogflowV3alpha1MatchModelType>'MODEL_TYPE_ADVANCED',
      MODEL_TYPE_CUSTOM:
          <GoogleCloudDialogflowV3alpha1MatchModelType>'MODEL_TYPE_CUSTOM',
      MODEL_TYPE_STANDARD:
          <GoogleCloudDialogflowV3alpha1MatchModelType>'MODEL_TYPE_STANDARD',
      MODEL_TYPE_UNSPECIFIED:
          <GoogleCloudDialogflowV3alpha1MatchModelType>'MODEL_TYPE_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV3alpha1MatchModelType> {
        return [
          GoogleCloudDialogflowV3alpha1MatchModelTypeEnum
              .MODEL_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1MatchModelTypeEnum.MODEL_TYPE_STANDARD,
          GoogleCloudDialogflowV3alpha1MatchModelTypeEnum.MODEL_TYPE_CUSTOM,
          GoogleCloudDialogflowV3alpha1MatchModelTypeEnum.MODEL_TYPE_ADVANCED
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState =
    'PARAMETER_STATE_UNSPECIFIED'|'EMPTY'|'INVALID'|'FILLED';

export interface IGoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoStateEnum {
  readonly PARAMETER_STATE_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState;
  readonly EMPTY:
      GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState;
  readonly INVALID:
      GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState;
  readonly FILLED:
      GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState;

  values():
      Array<GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState>;
}

export const GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoStateEnum:
    IGoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoStateEnum = {
      EMPTY: <
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState>'EMPTY',
      FILLED: <
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState>'FILLED',
      INVALID: <
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState>'INVALID',
      PARAMETER_STATE_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState>'PARAMETER_STATE_UNSPECIFIED',
      values(): Array<
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState> {
        return [
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoStateEnum
              .PARAMETER_STATE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoStateEnum
              .EMPTY,
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoStateEnum
              .INVALID,
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoStateEnum
              .FILLED
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1PageInfoFormInfoState =
    'FORM_STATE_UNSPECIFIED'|'INITIALIZING'|'COLLECTING'|'FINALIZED';

export interface IGoogleCloudDialogflowV3alpha1PageInfoFormInfoStateEnum {
  readonly FORM_STATE_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1PageInfoFormInfoState;
  readonly INITIALIZING: GoogleCloudDialogflowV3alpha1PageInfoFormInfoState;
  readonly COLLECTING: GoogleCloudDialogflowV3alpha1PageInfoFormInfoState;
  readonly FINALIZED: GoogleCloudDialogflowV3alpha1PageInfoFormInfoState;

  values(): Array<GoogleCloudDialogflowV3alpha1PageInfoFormInfoState>;
}

export const GoogleCloudDialogflowV3alpha1PageInfoFormInfoStateEnum:
    IGoogleCloudDialogflowV3alpha1PageInfoFormInfoStateEnum = {
      COLLECTING:
          <GoogleCloudDialogflowV3alpha1PageInfoFormInfoState>'COLLECTING',
      FINALIZED:
          <GoogleCloudDialogflowV3alpha1PageInfoFormInfoState>'FINALIZED',
      FORM_STATE_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoState>'FORM_STATE_UNSPECIFIED',
      INITIALIZING:
          <GoogleCloudDialogflowV3alpha1PageInfoFormInfoState>'INITIALIZING',
      values(): Array<GoogleCloudDialogflowV3alpha1PageInfoFormInfoState> {
        return [
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoStateEnum
              .FORM_STATE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoStateEnum.INITIALIZING,
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoStateEnum.COLLECTING,
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoStateEnum.FINALIZED
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1ResponseMessagePlatform =
    'PLATFORM_UNSPECIFIED'|'LINE'|'FACEBOOK_MESSENGER';

export interface IGoogleCloudDialogflowV3alpha1ResponseMessagePlatformEnum {
  readonly PLATFORM_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1ResponseMessagePlatform;
  readonly LINE: GoogleCloudDialogflowV3alpha1ResponseMessagePlatform;
  readonly FACEBOOK_MESSENGER:
      GoogleCloudDialogflowV3alpha1ResponseMessagePlatform;

  values(): Array<GoogleCloudDialogflowV3alpha1ResponseMessagePlatform>;
}

export const GoogleCloudDialogflowV3alpha1ResponseMessagePlatformEnum:
    IGoogleCloudDialogflowV3alpha1ResponseMessagePlatformEnum = {
      FACEBOOK_MESSENGER: <
          GoogleCloudDialogflowV3alpha1ResponseMessagePlatform>'FACEBOOK_MESSENGER',
      LINE: <GoogleCloudDialogflowV3alpha1ResponseMessagePlatform>'LINE',
      PLATFORM_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1ResponseMessagePlatform>'PLATFORM_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV3alpha1ResponseMessagePlatform> {
        return [
          GoogleCloudDialogflowV3alpha1ResponseMessagePlatformEnum
              .PLATFORM_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1ResponseMessagePlatformEnum.LINE,
          GoogleCloudDialogflowV3alpha1ResponseMessagePlatformEnum
              .FACEBOOK_MESSENGER
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1ResponseMessageResponseType =
    'RESPONSE_TYPE_UNSPECIFIED'|'ENTRY_PROMPT'|'PARAMETER_PROMPT'|
    'HANDLER_PROMPT';

export interface IGoogleCloudDialogflowV3alpha1ResponseMessageResponseTypeEnum {
  readonly RESPONSE_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1ResponseMessageResponseType;
  readonly ENTRY_PROMPT:
      GoogleCloudDialogflowV3alpha1ResponseMessageResponseType;
  readonly PARAMETER_PROMPT:
      GoogleCloudDialogflowV3alpha1ResponseMessageResponseType;
  readonly HANDLER_PROMPT:
      GoogleCloudDialogflowV3alpha1ResponseMessageResponseType;

  values(): Array<GoogleCloudDialogflowV3alpha1ResponseMessageResponseType>;
}

export const GoogleCloudDialogflowV3alpha1ResponseMessageResponseTypeEnum:
    IGoogleCloudDialogflowV3alpha1ResponseMessageResponseTypeEnum = {
      ENTRY_PROMPT: <
          GoogleCloudDialogflowV3alpha1ResponseMessageResponseType>'ENTRY_PROMPT',
      HANDLER_PROMPT: <
          GoogleCloudDialogflowV3alpha1ResponseMessageResponseType>'HANDLER_PROMPT',
      PARAMETER_PROMPT: <
          GoogleCloudDialogflowV3alpha1ResponseMessageResponseType>'PARAMETER_PROMPT',
      RESPONSE_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1ResponseMessageResponseType>'RESPONSE_TYPE_UNSPECIFIED',
      values():
          Array<GoogleCloudDialogflowV3alpha1ResponseMessageResponseType> {
            return [
              GoogleCloudDialogflowV3alpha1ResponseMessageResponseTypeEnum
                  .RESPONSE_TYPE_UNSPECIFIED,
              GoogleCloudDialogflowV3alpha1ResponseMessageResponseTypeEnum
                  .ENTRY_PROMPT,
              GoogleCloudDialogflowV3alpha1ResponseMessageResponseTypeEnum
                  .PARAMETER_PROMPT,
              GoogleCloudDialogflowV3alpha1ResponseMessageResponseTypeEnum
                  .HANDLER_PROMPT
            ];
          }
    };

export type GoogleCloudDialogflowV3alpha1ResponseMessageSource =
    'SOURCE_UNSPECIFIED'|'VIRTUAL_AGENT'|'WEBHOOK';

export interface IGoogleCloudDialogflowV3alpha1ResponseMessageSourceEnum {
  readonly SOURCE_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1ResponseMessageSource;
  readonly VIRTUAL_AGENT: GoogleCloudDialogflowV3alpha1ResponseMessageSource;
  readonly WEBHOOK: GoogleCloudDialogflowV3alpha1ResponseMessageSource;

  values(): Array<GoogleCloudDialogflowV3alpha1ResponseMessageSource>;
}

export const GoogleCloudDialogflowV3alpha1ResponseMessageSourceEnum:
    IGoogleCloudDialogflowV3alpha1ResponseMessageSourceEnum = {
      SOURCE_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1ResponseMessageSource>'SOURCE_UNSPECIFIED',
      VIRTUAL_AGENT:
          <GoogleCloudDialogflowV3alpha1ResponseMessageSource>'VIRTUAL_AGENT',
      WEBHOOK: <GoogleCloudDialogflowV3alpha1ResponseMessageSource>'WEBHOOK',
      values(): Array<GoogleCloudDialogflowV3alpha1ResponseMessageSource> {
        return [
          GoogleCloudDialogflowV3alpha1ResponseMessageSourceEnum
              .SOURCE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1ResponseMessageSourceEnum.VIRTUAL_AGENT,
          GoogleCloudDialogflowV3alpha1ResponseMessageSourceEnum.WEBHOOK
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType =
    'SPEAKER_ID_PAGE_TYPE_UNSPECIFIED'|'SPEAKER_ID_PAGE_TYPE_VERIFICATION'|
    'SPEAKER_ID_PAGE_TYPE_ENROLLMENT';

export interface IGoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageTypeEnum {
  readonly SPEAKER_ID_PAGE_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType;
  readonly SPEAKER_ID_PAGE_TYPE_VERIFICATION:
      GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType;
  readonly SPEAKER_ID_PAGE_TYPE_ENROLLMENT:
      GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType;

  values():
      Array<GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType>;
}

export const GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageTypeEnum:
    IGoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageTypeEnum = {
      SPEAKER_ID_PAGE_TYPE_ENROLLMENT: <
          GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType>'SPEAKER_ID_PAGE_TYPE_ENROLLMENT',
      SPEAKER_ID_PAGE_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType>'SPEAKER_ID_PAGE_TYPE_UNSPECIFIED',
      SPEAKER_ID_PAGE_TYPE_VERIFICATION: <
          GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType>'SPEAKER_ID_PAGE_TYPE_VERIFICATION',
      values(): Array<
          GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType> {
        return [
          GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageTypeEnum
              .SPEAKER_ID_PAGE_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageTypeEnum
              .SPEAKER_ID_PAGE_TYPE_VERIFICATION,
          GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageTypeEnum
              .SPEAKER_ID_PAGE_TYPE_ENROLLMENT
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1TestCaseResultTestResult =
    'TEST_RESULT_UNSPECIFIED'|'PASSED'|'FAILED';

export interface IGoogleCloudDialogflowV3alpha1TestCaseResultTestResultEnum {
  readonly TEST_RESULT_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1TestCaseResultTestResult;
  readonly PASSED: GoogleCloudDialogflowV3alpha1TestCaseResultTestResult;
  readonly FAILED: GoogleCloudDialogflowV3alpha1TestCaseResultTestResult;

  values(): Array<GoogleCloudDialogflowV3alpha1TestCaseResultTestResult>;
}

export const GoogleCloudDialogflowV3alpha1TestCaseResultTestResultEnum:
    IGoogleCloudDialogflowV3alpha1TestCaseResultTestResultEnum = {
      FAILED: <GoogleCloudDialogflowV3alpha1TestCaseResultTestResult>'FAILED',
      PASSED: <GoogleCloudDialogflowV3alpha1TestCaseResultTestResult>'PASSED',
      TEST_RESULT_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1TestCaseResultTestResult>'TEST_RESULT_UNSPECIFIED',
      values(): Array<GoogleCloudDialogflowV3alpha1TestCaseResultTestResult> {
        return [
          GoogleCloudDialogflowV3alpha1TestCaseResultTestResultEnum
              .TEST_RESULT_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1TestCaseResultTestResultEnum.PASSED,
          GoogleCloudDialogflowV3alpha1TestCaseResultTestResultEnum.FAILED
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1TestRunDifferenceType =
    'DIFF_TYPE_UNSPECIFIED'|'INTENT'|'PAGE'|'PARAMETERS'|'UTTERANCE';

export interface IGoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum {
  readonly DIFF_TYPE_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1TestRunDifferenceType;
  readonly INTENT: GoogleCloudDialogflowV3alpha1TestRunDifferenceType;
  readonly PAGE: GoogleCloudDialogflowV3alpha1TestRunDifferenceType;
  readonly PARAMETERS: GoogleCloudDialogflowV3alpha1TestRunDifferenceType;
  readonly UTTERANCE: GoogleCloudDialogflowV3alpha1TestRunDifferenceType;

  values(): Array<GoogleCloudDialogflowV3alpha1TestRunDifferenceType>;
}

export const GoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum:
    IGoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum = {
      DIFF_TYPE_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1TestRunDifferenceType>'DIFF_TYPE_UNSPECIFIED',
      INTENT: <GoogleCloudDialogflowV3alpha1TestRunDifferenceType>'INTENT',
      PAGE: <GoogleCloudDialogflowV3alpha1TestRunDifferenceType>'PAGE',
      PARAMETERS:
          <GoogleCloudDialogflowV3alpha1TestRunDifferenceType>'PARAMETERS',
      UTTERANCE:
          <GoogleCloudDialogflowV3alpha1TestRunDifferenceType>'UTTERANCE',
      values(): Array<GoogleCloudDialogflowV3alpha1TestRunDifferenceType> {
        return [
          GoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum
              .DIFF_TYPE_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum.INTENT,
          GoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum.PAGE,
          GoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum.PARAMETERS,
          GoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum.UTTERANCE
        ];
      }
    };

export type GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior =
    'MERGE_BEHAVIOR_UNSPECIFIED'|'APPEND'|'REPLACE';

export interface IGoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehaviorEnum {
  readonly MERGE_BEHAVIOR_UNSPECIFIED:
      GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior;
  readonly APPEND:
      GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior;
  readonly REPLACE:
      GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior;

  values(): Array<
      GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior>;
}

export const GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehaviorEnum:
    IGoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehaviorEnum = {
      APPEND: <
          GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior>'APPEND',
      MERGE_BEHAVIOR_UNSPECIFIED: <
          GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior>'MERGE_BEHAVIOR_UNSPECIFIED',
      REPLACE: <
          GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior>'REPLACE',
      values(): Array<
          GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior> {
        return [
          GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehaviorEnum
              .MERGE_BEHAVIOR_UNSPECIFIED,
          GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehaviorEnum
              .APPEND,
          GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehaviorEnum
              .REPLACE
        ];
      }
    };

export type GoogleCloudKnowledgeV1alpha1OperationMetadataState =
    'STATE_UNSPECIFIED'|'PENDING'|'RUNNING'|'DONE';

export interface IGoogleCloudKnowledgeV1alpha1OperationMetadataStateEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudKnowledgeV1alpha1OperationMetadataState;
  readonly PENDING: GoogleCloudKnowledgeV1alpha1OperationMetadataState;
  readonly RUNNING: GoogleCloudKnowledgeV1alpha1OperationMetadataState;
  readonly DONE: GoogleCloudKnowledgeV1alpha1OperationMetadataState;

  values(): Array<GoogleCloudKnowledgeV1alpha1OperationMetadataState>;
}

export const GoogleCloudKnowledgeV1alpha1OperationMetadataStateEnum:
    IGoogleCloudKnowledgeV1alpha1OperationMetadataStateEnum = {
      DONE: <GoogleCloudKnowledgeV1alpha1OperationMetadataState>'DONE',
      PENDING: <GoogleCloudKnowledgeV1alpha1OperationMetadataState>'PENDING',
      RUNNING: <GoogleCloudKnowledgeV1alpha1OperationMetadataState>'RUNNING',
      STATE_UNSPECIFIED: <
          GoogleCloudKnowledgeV1alpha1OperationMetadataState>'STATE_UNSPECIFIED',
      values(): Array<GoogleCloudKnowledgeV1alpha1OperationMetadataState> {
        return [
          GoogleCloudKnowledgeV1alpha1OperationMetadataStateEnum
              .STATE_UNSPECIFIED,
          GoogleCloudKnowledgeV1alpha1OperationMetadataStateEnum.PENDING,
          GoogleCloudKnowledgeV1alpha1OperationMetadataStateEnum.RUNNING,
          GoogleCloudKnowledgeV1alpha1OperationMetadataStateEnum.DONE
        ];
      }
    };

export type GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState =
    'STATE_UNSPECIFIED'|'ENABLED'|'DISABLED';

export interface IGoogleCloudKnowledgeV1alpha1QuestionAnswerEntryStateEnum {
  readonly STATE_UNSPECIFIED:
      GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState;
  readonly ENABLED: GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState;
  readonly DISABLED: GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState;

  values(): Array<GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState>;
}

export const GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryStateEnum:
    IGoogleCloudKnowledgeV1alpha1QuestionAnswerEntryStateEnum = {
      DISABLED:
          <GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState>'DISABLED',
      ENABLED: <GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState>'ENABLED',
      STATE_UNSPECIFIED: <
          GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState>'STATE_UNSPECIFIED',
      values(): Array<GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState> {
        return [
          GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryStateEnum
              .STATE_UNSPECIFIED,
          GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryStateEnum.ENABLED,
          GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryStateEnum.DISABLED
        ];
      }
    };

export type GoogleIamV1AuditLogConfigLogType =
    'LOG_TYPE_UNSPECIFIED'|'ADMIN_READ'|'DATA_WRITE'|'DATA_READ';

export interface IGoogleIamV1AuditLogConfigLogTypeEnum {
  readonly LOG_TYPE_UNSPECIFIED: GoogleIamV1AuditLogConfigLogType;
  readonly ADMIN_READ: GoogleIamV1AuditLogConfigLogType;
  readonly DATA_WRITE: GoogleIamV1AuditLogConfigLogType;
  readonly DATA_READ: GoogleIamV1AuditLogConfigLogType;

  values(): Array<GoogleIamV1AuditLogConfigLogType>;
}

export const GoogleIamV1AuditLogConfigLogTypeEnum:
    IGoogleIamV1AuditLogConfigLogTypeEnum = {
      ADMIN_READ: <GoogleIamV1AuditLogConfigLogType>'ADMIN_READ',
      DATA_READ: <GoogleIamV1AuditLogConfigLogType>'DATA_READ',
      DATA_WRITE: <GoogleIamV1AuditLogConfigLogType>'DATA_WRITE',
      LOG_TYPE_UNSPECIFIED:
          <GoogleIamV1AuditLogConfigLogType>'LOG_TYPE_UNSPECIFIED',
      values(): Array<GoogleIamV1AuditLogConfigLogType> {
        return [
          GoogleIamV1AuditLogConfigLogTypeEnum.LOG_TYPE_UNSPECIFIED,
          GoogleIamV1AuditLogConfigLogTypeEnum.ADMIN_READ,
          GoogleIamV1AuditLogConfigLogTypeEnum.DATA_WRITE,
          GoogleIamV1AuditLogConfigLogTypeEnum.DATA_READ
        ];
      }
    };

export type GoogleIamV1ConditionIam =
    'NO_ATTR'|'AUTHORITY'|'ATTRIBUTION'|'SECURITY_REALM'|'APPROVER'|
    'JUSTIFICATION_TYPE'|'CREDENTIALS_TYPE'|'CREDS_ASSERTION';

export interface IGoogleIamV1ConditionIamEnum {
  readonly NO_ATTR: GoogleIamV1ConditionIam;
  readonly AUTHORITY: GoogleIamV1ConditionIam;
  readonly ATTRIBUTION: GoogleIamV1ConditionIam;
  readonly SECURITY_REALM: GoogleIamV1ConditionIam;
  readonly APPROVER: GoogleIamV1ConditionIam;
  readonly JUSTIFICATION_TYPE: GoogleIamV1ConditionIam;
  readonly CREDENTIALS_TYPE: GoogleIamV1ConditionIam;
  readonly CREDS_ASSERTION: GoogleIamV1ConditionIam;

  values(): Array<GoogleIamV1ConditionIam>;
}

export const GoogleIamV1ConditionIamEnum: IGoogleIamV1ConditionIamEnum = {
  APPROVER: <GoogleIamV1ConditionIam>'APPROVER',
  ATTRIBUTION: <GoogleIamV1ConditionIam>'ATTRIBUTION',
  AUTHORITY: <GoogleIamV1ConditionIam>'AUTHORITY',
  CREDENTIALS_TYPE: <GoogleIamV1ConditionIam>'CREDENTIALS_TYPE',
  CREDS_ASSERTION: <GoogleIamV1ConditionIam>'CREDS_ASSERTION',
  JUSTIFICATION_TYPE: <GoogleIamV1ConditionIam>'JUSTIFICATION_TYPE',
  NO_ATTR: <GoogleIamV1ConditionIam>'NO_ATTR',
  SECURITY_REALM: <GoogleIamV1ConditionIam>'SECURITY_REALM',
  values(): Array<GoogleIamV1ConditionIam> {
    return [
      GoogleIamV1ConditionIamEnum.NO_ATTR,
      GoogleIamV1ConditionIamEnum.AUTHORITY,
      GoogleIamV1ConditionIamEnum.ATTRIBUTION,
      GoogleIamV1ConditionIamEnum.SECURITY_REALM,
      GoogleIamV1ConditionIamEnum.APPROVER,
      GoogleIamV1ConditionIamEnum.JUSTIFICATION_TYPE,
      GoogleIamV1ConditionIamEnum.CREDENTIALS_TYPE,
      GoogleIamV1ConditionIamEnum.CREDS_ASSERTION
    ];
  }
};

export type GoogleIamV1ConditionOp =
    'NO_OP'|'EQUALS'|'NOT_EQUALS'|'IN'|'NOT_IN'|'DISCHARGED';

export interface IGoogleIamV1ConditionOpEnum {
  readonly NO_OP: GoogleIamV1ConditionOp;
  readonly EQUALS: GoogleIamV1ConditionOp;
  readonly NOT_EQUALS: GoogleIamV1ConditionOp;
  readonly IN: GoogleIamV1ConditionOp;
  readonly NOT_IN: GoogleIamV1ConditionOp;
  readonly DISCHARGED: GoogleIamV1ConditionOp;

  values(): Array<GoogleIamV1ConditionOp>;
}

export const GoogleIamV1ConditionOpEnum: IGoogleIamV1ConditionOpEnum = {
  DISCHARGED: <GoogleIamV1ConditionOp>'DISCHARGED',
  EQUALS: <GoogleIamV1ConditionOp>'EQUALS',
  IN: <GoogleIamV1ConditionOp>'IN',
  NOT_EQUALS: <GoogleIamV1ConditionOp>'NOT_EQUALS',
  NOT_IN: <GoogleIamV1ConditionOp>'NOT_IN',
  NO_OP: <GoogleIamV1ConditionOp>'NO_OP',
  values(): Array<GoogleIamV1ConditionOp> {
    return [
      GoogleIamV1ConditionOpEnum.NO_OP, GoogleIamV1ConditionOpEnum.EQUALS,
      GoogleIamV1ConditionOpEnum.NOT_EQUALS, GoogleIamV1ConditionOpEnum.IN,
      GoogleIamV1ConditionOpEnum.NOT_IN, GoogleIamV1ConditionOpEnum.DISCHARGED
    ];
  }
};

export type GoogleIamV1ConditionSys = 'NO_ATTR'|'REGION'|'SERVICE'|'NAME'|'IP';

export interface IGoogleIamV1ConditionSysEnum {
  readonly NO_ATTR: GoogleIamV1ConditionSys;
  readonly REGION: GoogleIamV1ConditionSys;
  readonly SERVICE: GoogleIamV1ConditionSys;
  readonly NAME: GoogleIamV1ConditionSys;
  readonly IP: GoogleIamV1ConditionSys;

  values(): Array<GoogleIamV1ConditionSys>;
}

export const GoogleIamV1ConditionSysEnum: IGoogleIamV1ConditionSysEnum = {
  IP: <GoogleIamV1ConditionSys>'IP',
  NAME: <GoogleIamV1ConditionSys>'NAME',
  NO_ATTR: <GoogleIamV1ConditionSys>'NO_ATTR',
  REGION: <GoogleIamV1ConditionSys>'REGION',
  SERVICE: <GoogleIamV1ConditionSys>'SERVICE',
  values(): Array<GoogleIamV1ConditionSys> {
    return [
      GoogleIamV1ConditionSysEnum.NO_ATTR, GoogleIamV1ConditionSysEnum.REGION,
      GoogleIamV1ConditionSysEnum.SERVICE, GoogleIamV1ConditionSysEnum.NAME,
      GoogleIamV1ConditionSysEnum.IP
    ];
  }
};

export type GoogleIamV1LogConfigCloudAuditOptionsLogName =
    'UNSPECIFIED_LOG_NAME'|'ADMIN_ACTIVITY'|'DATA_ACCESS';

export interface IGoogleIamV1LogConfigCloudAuditOptionsLogNameEnum {
  readonly UNSPECIFIED_LOG_NAME: GoogleIamV1LogConfigCloudAuditOptionsLogName;
  readonly ADMIN_ACTIVITY: GoogleIamV1LogConfigCloudAuditOptionsLogName;
  readonly DATA_ACCESS: GoogleIamV1LogConfigCloudAuditOptionsLogName;

  values(): Array<GoogleIamV1LogConfigCloudAuditOptionsLogName>;
}

export const GoogleIamV1LogConfigCloudAuditOptionsLogNameEnum:
    IGoogleIamV1LogConfigCloudAuditOptionsLogNameEnum = {
      ADMIN_ACTIVITY:
          <GoogleIamV1LogConfigCloudAuditOptionsLogName>'ADMIN_ACTIVITY',
      DATA_ACCESS: <GoogleIamV1LogConfigCloudAuditOptionsLogName>'DATA_ACCESS',
      UNSPECIFIED_LOG_NAME:
          <GoogleIamV1LogConfigCloudAuditOptionsLogName>'UNSPECIFIED_LOG_NAME',
      values(): Array<GoogleIamV1LogConfigCloudAuditOptionsLogName> {
        return [
          GoogleIamV1LogConfigCloudAuditOptionsLogNameEnum.UNSPECIFIED_LOG_NAME,
          GoogleIamV1LogConfigCloudAuditOptionsLogNameEnum.ADMIN_ACTIVITY,
          GoogleIamV1LogConfigCloudAuditOptionsLogNameEnum.DATA_ACCESS
        ];
      }
    };

export type GoogleIamV1LogConfigDataAccessOptionsLogMode =
    'LOG_MODE_UNSPECIFIED'|'LOG_FAIL_CLOSED';

export interface IGoogleIamV1LogConfigDataAccessOptionsLogModeEnum {
  readonly LOG_MODE_UNSPECIFIED: GoogleIamV1LogConfigDataAccessOptionsLogMode;
  readonly LOG_FAIL_CLOSED: GoogleIamV1LogConfigDataAccessOptionsLogMode;

  values(): Array<GoogleIamV1LogConfigDataAccessOptionsLogMode>;
}

export const GoogleIamV1LogConfigDataAccessOptionsLogModeEnum:
    IGoogleIamV1LogConfigDataAccessOptionsLogModeEnum = {
      LOG_FAIL_CLOSED:
          <GoogleIamV1LogConfigDataAccessOptionsLogMode>'LOG_FAIL_CLOSED',
      LOG_MODE_UNSPECIFIED:
          <GoogleIamV1LogConfigDataAccessOptionsLogMode>'LOG_MODE_UNSPECIFIED',
      values(): Array<GoogleIamV1LogConfigDataAccessOptionsLogMode> {
        return [
          GoogleIamV1LogConfigDataAccessOptionsLogModeEnum.LOG_MODE_UNSPECIFIED,
          GoogleIamV1LogConfigDataAccessOptionsLogModeEnum.LOG_FAIL_CLOSED
        ];
      }
    };

export type GoogleIamV1RuleAction =
    'NO_ACTION'|'ALLOW'|'ALLOW_WITH_LOG'|'DENY'|'DENY_WITH_LOG'|'LOG';

export interface IGoogleIamV1RuleActionEnum {
  readonly NO_ACTION: GoogleIamV1RuleAction;
  readonly ALLOW: GoogleIamV1RuleAction;
  readonly ALLOW_WITH_LOG: GoogleIamV1RuleAction;
  readonly DENY: GoogleIamV1RuleAction;
  readonly DENY_WITH_LOG: GoogleIamV1RuleAction;
  readonly LOG: GoogleIamV1RuleAction;

  values(): Array<GoogleIamV1RuleAction>;
}

export const GoogleIamV1RuleActionEnum: IGoogleIamV1RuleActionEnum = {
  ALLOW: <GoogleIamV1RuleAction>'ALLOW',
  ALLOW_WITH_LOG: <GoogleIamV1RuleAction>'ALLOW_WITH_LOG',
  DENY: <GoogleIamV1RuleAction>'DENY',
  DENY_WITH_LOG: <GoogleIamV1RuleAction>'DENY_WITH_LOG',
  LOG: <GoogleIamV1RuleAction>'LOG',
  NO_ACTION: <GoogleIamV1RuleAction>'NO_ACTION',
  values(): Array<GoogleIamV1RuleAction> {
    return [
      GoogleIamV1RuleActionEnum.NO_ACTION, GoogleIamV1RuleActionEnum.ALLOW,
      GoogleIamV1RuleActionEnum.ALLOW_WITH_LOG, GoogleIamV1RuleActionEnum.DENY,
      GoogleIamV1RuleActionEnum.DENY_WITH_LOG, GoogleIamV1RuleActionEnum.LOG
    ];
  }
};

export type HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode =
    'TRIGGER_MODEL_MODE_UNSPECIFIED'|'TRIGGER_MODEL_MODE_ENABLED'|
    'TRIGGER_MODEL_MODE_DISABLED'|'TRIGGER_MODEL_MODE_END_USER_ONLY';

export interface IHumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelModeEnum {
  readonly TRIGGER_MODEL_MODE_UNSPECIFIED:
      HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode;
  readonly TRIGGER_MODEL_MODE_ENABLED:
      HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode;
  readonly TRIGGER_MODEL_MODE_DISABLED:
      HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode;
  readonly TRIGGER_MODEL_MODE_END_USER_ONLY:
      HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode;

  values():
      Array<HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode>;
}

export const HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelModeEnum:
    IHumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelModeEnum = {
      TRIGGER_MODEL_MODE_DISABLED: <
          HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode>'TRIGGER_MODEL_MODE_DISABLED',
      TRIGGER_MODEL_MODE_ENABLED: <
          HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode>'TRIGGER_MODEL_MODE_ENABLED',
      TRIGGER_MODEL_MODE_END_USER_ONLY: <
          HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode>'TRIGGER_MODEL_MODE_END_USER_ONLY',
      TRIGGER_MODEL_MODE_UNSPECIFIED: <
          HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode>'TRIGGER_MODEL_MODE_UNSPECIFIED',
      values(): Array<
          HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode> {
        return [
          HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelModeEnum
              .TRIGGER_MODEL_MODE_UNSPECIFIED,
          HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelModeEnum
              .TRIGGER_MODEL_MODE_ENABLED,
          HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelModeEnum
              .TRIGGER_MODEL_MODE_DISABLED,
          HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelModeEnum
              .TRIGGER_MODEL_MODE_END_USER_ONLY
        ];
      }
    };

export type HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes =
    'KNOWLEDGE_TYPE_UNSPECIFIED'|'FAQ'|'EXTRACTIVE_QA'|'ARTICLE_SUGGESTION'|
    'AGENT_FACING_SMART_REPLY'|'SMART_REPLY'|'AGENT_FACING_SMART_COMPOSE'|
    'SMART_COMPOSE'|'CUSTOMER_FACING_SMART_REPLY'|
    'CUSTOMER_FACING_SMART_COMPOSE'|'ISSUE_MODELING'|'ARTICLE_SEARCH';

export interface IHumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum {
  readonly KNOWLEDGE_TYPE_UNSPECIFIED:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly FAQ:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly EXTRACTIVE_QA:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly ARTICLE_SUGGESTION:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly AGENT_FACING_SMART_REPLY:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly SMART_REPLY:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly AGENT_FACING_SMART_COMPOSE:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly SMART_COMPOSE:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly CUSTOMER_FACING_SMART_REPLY:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly CUSTOMER_FACING_SMART_COMPOSE:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly ISSUE_MODELING:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;
  readonly ARTICLE_SEARCH:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes;

  values(): Array<
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>;
}

export const HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum:
    IHumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum = {
      AGENT_FACING_SMART_COMPOSE: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'AGENT_FACING_SMART_COMPOSE',
      AGENT_FACING_SMART_REPLY: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'AGENT_FACING_SMART_REPLY',
      ARTICLE_SEARCH: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'ARTICLE_SEARCH',
      ARTICLE_SUGGESTION: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'ARTICLE_SUGGESTION',
      CUSTOMER_FACING_SMART_COMPOSE: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'CUSTOMER_FACING_SMART_COMPOSE',
      CUSTOMER_FACING_SMART_REPLY: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'CUSTOMER_FACING_SMART_REPLY',
      EXTRACTIVE_QA: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'EXTRACTIVE_QA',
      FAQ: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'FAQ',
      ISSUE_MODELING: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'ISSUE_MODELING',
      KNOWLEDGE_TYPE_UNSPECIFIED: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'KNOWLEDGE_TYPE_UNSPECIFIED',
      SMART_COMPOSE: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'SMART_COMPOSE',
      SMART_REPLY: <
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>'SMART_REPLY',
      values(): Array<
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes> {
        return [
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .KNOWLEDGE_TYPE_UNSPECIFIED,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .FAQ,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .EXTRACTIVE_QA,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .ARTICLE_SUGGESTION,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .AGENT_FACING_SMART_REPLY,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .SMART_REPLY,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .AGENT_FACING_SMART_COMPOSE,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .SMART_COMPOSE,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .CUSTOMER_FACING_SMART_REPLY,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .CUSTOMER_FACING_SMART_COMPOSE,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .ISSUE_MODELING,
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
              .ARTICLE_SEARCH
        ];
      }
    };

export type HumanAgentAssistantEvent_Type = 'TYPE_UNSPECIFIED'|'NEW_SUGGESTION';

export interface IHumanAgentAssistantEvent_TypeEnum {
  readonly TYPE_UNSPECIFIED: HumanAgentAssistantEvent_Type;
  readonly NEW_SUGGESTION: HumanAgentAssistantEvent_Type;

  values(): Array<HumanAgentAssistantEvent_Type>;
}

export const HumanAgentAssistantEvent_TypeEnum:
    IHumanAgentAssistantEvent_TypeEnum = {
      NEW_SUGGESTION: <HumanAgentAssistantEvent_Type>'NEW_SUGGESTION',
      TYPE_UNSPECIFIED: <HumanAgentAssistantEvent_Type>'TYPE_UNSPECIFIED',
      values(): Array<HumanAgentAssistantEvent_Type> {
        return [
          HumanAgentAssistantEvent_TypeEnum.TYPE_UNSPECIFIED,
          HumanAgentAssistantEvent_TypeEnum.NEW_SUGGESTION
        ];
      }
    };

export type ImportAgentRequest_ExportAgentFormat =
    'EXPORT_AGENT_FORMAT_TYPE_UNSPECIFIED'|'STANDARD'|'AMAZON_ALEXA';

export interface IImportAgentRequest_ExportAgentFormatEnum {
  readonly EXPORT_AGENT_FORMAT_TYPE_UNSPECIFIED:
      ImportAgentRequest_ExportAgentFormat;
  readonly STANDARD: ImportAgentRequest_ExportAgentFormat;
  readonly AMAZON_ALEXA: ImportAgentRequest_ExportAgentFormat;

  values(): Array<ImportAgentRequest_ExportAgentFormat>;
}

export const ImportAgentRequest_ExportAgentFormatEnum:
    IImportAgentRequest_ExportAgentFormatEnum = {
      AMAZON_ALEXA: <ImportAgentRequest_ExportAgentFormat>'AMAZON_ALEXA',
      EXPORT_AGENT_FORMAT_TYPE_UNSPECIFIED: <
          ImportAgentRequest_ExportAgentFormat>'EXPORT_AGENT_FORMAT_TYPE_UNSPECIFIED',
      STANDARD: <ImportAgentRequest_ExportAgentFormat>'STANDARD',
      values(): Array<ImportAgentRequest_ExportAgentFormat> {
        return [
          ImportAgentRequest_ExportAgentFormatEnum
              .EXPORT_AGENT_FORMAT_TYPE_UNSPECIFIED,
          ImportAgentRequest_ExportAgentFormatEnum.STANDARD,
          ImportAgentRequest_ExportAgentFormatEnum.AMAZON_ALEXA
        ];
      }
    };

export type ImportDocumentTemplate_KnowledgeTypes =
    'KNOWLEDGE_TYPE_UNSPECIFIED'|'FAQ'|'EXTRACTIVE_QA'|'ARTICLE_SUGGESTION'|
    'AGENT_FACING_SMART_REPLY'|'SMART_REPLY'|'AGENT_FACING_SMART_COMPOSE'|
    'SMART_COMPOSE'|'CUSTOMER_FACING_SMART_REPLY'|
    'CUSTOMER_FACING_SMART_COMPOSE'|'ISSUE_MODELING'|'ARTICLE_SEARCH';

export interface IImportDocumentTemplate_KnowledgeTypesEnum {
  readonly KNOWLEDGE_TYPE_UNSPECIFIED: ImportDocumentTemplate_KnowledgeTypes;
  readonly FAQ: ImportDocumentTemplate_KnowledgeTypes;
  readonly EXTRACTIVE_QA: ImportDocumentTemplate_KnowledgeTypes;
  readonly ARTICLE_SUGGESTION: ImportDocumentTemplate_KnowledgeTypes;
  readonly AGENT_FACING_SMART_REPLY: ImportDocumentTemplate_KnowledgeTypes;
  readonly SMART_REPLY: ImportDocumentTemplate_KnowledgeTypes;
  readonly AGENT_FACING_SMART_COMPOSE: ImportDocumentTemplate_KnowledgeTypes;
  readonly SMART_COMPOSE: ImportDocumentTemplate_KnowledgeTypes;
  readonly CUSTOMER_FACING_SMART_REPLY: ImportDocumentTemplate_KnowledgeTypes;
  readonly CUSTOMER_FACING_SMART_COMPOSE: ImportDocumentTemplate_KnowledgeTypes;
  readonly ISSUE_MODELING: ImportDocumentTemplate_KnowledgeTypes;
  readonly ARTICLE_SEARCH: ImportDocumentTemplate_KnowledgeTypes;

  values(): Array<ImportDocumentTemplate_KnowledgeTypes>;
}

export const ImportDocumentTemplate_KnowledgeTypesEnum:
    IImportDocumentTemplate_KnowledgeTypesEnum = {
      AGENT_FACING_SMART_COMPOSE:
          <ImportDocumentTemplate_KnowledgeTypes>'AGENT_FACING_SMART_COMPOSE',
      AGENT_FACING_SMART_REPLY:
          <ImportDocumentTemplate_KnowledgeTypes>'AGENT_FACING_SMART_REPLY',
      ARTICLE_SEARCH: <ImportDocumentTemplate_KnowledgeTypes>'ARTICLE_SEARCH',
      ARTICLE_SUGGESTION:
          <ImportDocumentTemplate_KnowledgeTypes>'ARTICLE_SUGGESTION',
      CUSTOMER_FACING_SMART_COMPOSE: <
          ImportDocumentTemplate_KnowledgeTypes>'CUSTOMER_FACING_SMART_COMPOSE',
      CUSTOMER_FACING_SMART_REPLY:
          <ImportDocumentTemplate_KnowledgeTypes>'CUSTOMER_FACING_SMART_REPLY',
      EXTRACTIVE_QA: <ImportDocumentTemplate_KnowledgeTypes>'EXTRACTIVE_QA',
      FAQ: <ImportDocumentTemplate_KnowledgeTypes>'FAQ',
      ISSUE_MODELING: <ImportDocumentTemplate_KnowledgeTypes>'ISSUE_MODELING',
      KNOWLEDGE_TYPE_UNSPECIFIED:
          <ImportDocumentTemplate_KnowledgeTypes>'KNOWLEDGE_TYPE_UNSPECIFIED',
      SMART_COMPOSE: <ImportDocumentTemplate_KnowledgeTypes>'SMART_COMPOSE',
      SMART_REPLY: <ImportDocumentTemplate_KnowledgeTypes>'SMART_REPLY',
      values(): Array<ImportDocumentTemplate_KnowledgeTypes> {
        return [
          ImportDocumentTemplate_KnowledgeTypesEnum.KNOWLEDGE_TYPE_UNSPECIFIED,
          ImportDocumentTemplate_KnowledgeTypesEnum.FAQ,
          ImportDocumentTemplate_KnowledgeTypesEnum.EXTRACTIVE_QA,
          ImportDocumentTemplate_KnowledgeTypesEnum.ARTICLE_SUGGESTION,
          ImportDocumentTemplate_KnowledgeTypesEnum.AGENT_FACING_SMART_REPLY,
          ImportDocumentTemplate_KnowledgeTypesEnum.SMART_REPLY,
          ImportDocumentTemplate_KnowledgeTypesEnum.AGENT_FACING_SMART_COMPOSE,
          ImportDocumentTemplate_KnowledgeTypesEnum.SMART_COMPOSE,
          ImportDocumentTemplate_KnowledgeTypesEnum.CUSTOMER_FACING_SMART_REPLY,
          ImportDocumentTemplate_KnowledgeTypesEnum
              .CUSTOMER_FACING_SMART_COMPOSE,
          ImportDocumentTemplate_KnowledgeTypesEnum.ISSUE_MODELING,
          ImportDocumentTemplate_KnowledgeTypesEnum.ARTICLE_SEARCH
        ];
      }
    };

export type InitiateConversationRequest_ConnectionType =
    'CONNECTION_TYPE_UNSPECIFIED'|'PHONE_CALL'|'SMS';

export interface IInitiateConversationRequest_ConnectionTypeEnum {
  readonly CONNECTION_TYPE_UNSPECIFIED:
      InitiateConversationRequest_ConnectionType;
  readonly PHONE_CALL: InitiateConversationRequest_ConnectionType;
  readonly SMS: InitiateConversationRequest_ConnectionType;

  values(): Array<InitiateConversationRequest_ConnectionType>;
}

export const InitiateConversationRequest_ConnectionTypeEnum:
    IInitiateConversationRequest_ConnectionTypeEnum = {
      CONNECTION_TYPE_UNSPECIFIED: <
          InitiateConversationRequest_ConnectionType>'CONNECTION_TYPE_UNSPECIFIED',
      PHONE_CALL: <InitiateConversationRequest_ConnectionType>'PHONE_CALL',
      SMS: <InitiateConversationRequest_ConnectionType>'SMS',
      values(): Array<InitiateConversationRequest_ConnectionType> {
        return [
          InitiateConversationRequest_ConnectionTypeEnum
              .CONNECTION_TYPE_UNSPECIFIED,
          InitiateConversationRequest_ConnectionTypeEnum.PHONE_CALL,
          InitiateConversationRequest_ConnectionTypeEnum.SMS
        ];
      }
    };

export type InputAudioConfig_AudioEncoding = 'AUDIO_ENCODING_UNSPECIFIED'|
    'AUDIO_ENCODING_LINEAR_16'|'AUDIO_ENCODING_FLAC'|'AUDIO_ENCODING_MULAW'|
    'AUDIO_ENCODING_AMR'|'AUDIO_ENCODING_AMR_WB'|'AUDIO_ENCODING_OGG_OPUS'|
    'AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE';

export interface IInputAudioConfig_AudioEncodingEnum {
  readonly AUDIO_ENCODING_UNSPECIFIED: InputAudioConfig_AudioEncoding;
  readonly AUDIO_ENCODING_LINEAR_16: InputAudioConfig_AudioEncoding;
  readonly AUDIO_ENCODING_FLAC: InputAudioConfig_AudioEncoding;
  readonly AUDIO_ENCODING_MULAW: InputAudioConfig_AudioEncoding;
  readonly AUDIO_ENCODING_AMR: InputAudioConfig_AudioEncoding;
  readonly AUDIO_ENCODING_AMR_WB: InputAudioConfig_AudioEncoding;
  readonly AUDIO_ENCODING_OGG_OPUS: InputAudioConfig_AudioEncoding;
  readonly AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE:
      InputAudioConfig_AudioEncoding;

  values(): Array<InputAudioConfig_AudioEncoding>;
}

export const InputAudioConfig_AudioEncodingEnum:
    IInputAudioConfig_AudioEncodingEnum = {
      AUDIO_ENCODING_AMR: <InputAudioConfig_AudioEncoding>'AUDIO_ENCODING_AMR',
      AUDIO_ENCODING_AMR_WB:
          <InputAudioConfig_AudioEncoding>'AUDIO_ENCODING_AMR_WB',
      AUDIO_ENCODING_FLAC:
          <InputAudioConfig_AudioEncoding>'AUDIO_ENCODING_FLAC',
      AUDIO_ENCODING_LINEAR_16:
          <InputAudioConfig_AudioEncoding>'AUDIO_ENCODING_LINEAR_16',
      AUDIO_ENCODING_MULAW:
          <InputAudioConfig_AudioEncoding>'AUDIO_ENCODING_MULAW',
      AUDIO_ENCODING_OGG_OPUS:
          <InputAudioConfig_AudioEncoding>'AUDIO_ENCODING_OGG_OPUS',
      AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE: <
          InputAudioConfig_AudioEncoding>'AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE',
      AUDIO_ENCODING_UNSPECIFIED:
          <InputAudioConfig_AudioEncoding>'AUDIO_ENCODING_UNSPECIFIED',
      values(): Array<InputAudioConfig_AudioEncoding> {
        return [
          InputAudioConfig_AudioEncodingEnum.AUDIO_ENCODING_UNSPECIFIED,
          InputAudioConfig_AudioEncodingEnum.AUDIO_ENCODING_LINEAR_16,
          InputAudioConfig_AudioEncodingEnum.AUDIO_ENCODING_FLAC,
          InputAudioConfig_AudioEncodingEnum.AUDIO_ENCODING_MULAW,
          InputAudioConfig_AudioEncodingEnum.AUDIO_ENCODING_AMR,
          InputAudioConfig_AudioEncodingEnum.AUDIO_ENCODING_AMR_WB,
          InputAudioConfig_AudioEncodingEnum.AUDIO_ENCODING_OGG_OPUS,
          InputAudioConfig_AudioEncodingEnum
              .AUDIO_ENCODING_SPEEX_WITH_HEADER_BYTE
        ];
      }
    };

export type InputAudioConfig_ModelVariant = 'SPEECH_MODEL_VARIANT_UNSPECIFIED'|
    'USE_BEST_AVAILABLE'|'USE_STANDARD'|'USE_ENHANCED';

export interface IInputAudioConfig_ModelVariantEnum {
  readonly SPEECH_MODEL_VARIANT_UNSPECIFIED: InputAudioConfig_ModelVariant;
  readonly USE_BEST_AVAILABLE: InputAudioConfig_ModelVariant;
  readonly USE_STANDARD: InputAudioConfig_ModelVariant;
  readonly USE_ENHANCED: InputAudioConfig_ModelVariant;

  values(): Array<InputAudioConfig_ModelVariant>;
}

export const InputAudioConfig_ModelVariantEnum:
    IInputAudioConfig_ModelVariantEnum = {
      SPEECH_MODEL_VARIANT_UNSPECIFIED:
          <InputAudioConfig_ModelVariant>'SPEECH_MODEL_VARIANT_UNSPECIFIED',
      USE_BEST_AVAILABLE: <InputAudioConfig_ModelVariant>'USE_BEST_AVAILABLE',
      USE_ENHANCED: <InputAudioConfig_ModelVariant>'USE_ENHANCED',
      USE_STANDARD: <InputAudioConfig_ModelVariant>'USE_STANDARD',
      values(): Array<InputAudioConfig_ModelVariant> {
        return [
          InputAudioConfig_ModelVariantEnum.SPEECH_MODEL_VARIANT_UNSPECIFIED,
          InputAudioConfig_ModelVariantEnum.USE_BEST_AVAILABLE,
          InputAudioConfig_ModelVariantEnum.USE_STANDARD,
          InputAudioConfig_ModelVariantEnum.USE_ENHANCED
        ];
      }
    };

export type IntegrationOneClickIntegrationPayload_Partner =
    'PARTNER_UNSPECIFIED'|'SIGNALWIRE'|'VOXIMPLANT'|'GENESYS'|'AVAYA'|
    'AUDIO_CODES'|'TWILIO'|'INTRADO'|'TEST_PARTNER'|'AVAYA_TEST'|
    'AUDIO_CODES_TEST'|'TWILIO_TEST';

export interface IIntegrationOneClickIntegrationPayload_PartnerEnum {
  readonly PARTNER_UNSPECIFIED: IntegrationOneClickIntegrationPayload_Partner;
  readonly SIGNALWIRE: IntegrationOneClickIntegrationPayload_Partner;
  readonly VOXIMPLANT: IntegrationOneClickIntegrationPayload_Partner;
  readonly GENESYS: IntegrationOneClickIntegrationPayload_Partner;
  readonly AVAYA: IntegrationOneClickIntegrationPayload_Partner;
  readonly AUDIO_CODES: IntegrationOneClickIntegrationPayload_Partner;
  readonly TWILIO: IntegrationOneClickIntegrationPayload_Partner;
  readonly INTRADO: IntegrationOneClickIntegrationPayload_Partner;
  readonly TEST_PARTNER: IntegrationOneClickIntegrationPayload_Partner;
  readonly AVAYA_TEST: IntegrationOneClickIntegrationPayload_Partner;
  readonly AUDIO_CODES_TEST: IntegrationOneClickIntegrationPayload_Partner;
  readonly TWILIO_TEST: IntegrationOneClickIntegrationPayload_Partner;

  values(): Array<IntegrationOneClickIntegrationPayload_Partner>;
}

export const IntegrationOneClickIntegrationPayload_PartnerEnum:
    IIntegrationOneClickIntegrationPayload_PartnerEnum = {
      AUDIO_CODES: <IntegrationOneClickIntegrationPayload_Partner>'AUDIO_CODES',
      AUDIO_CODES_TEST:
          <IntegrationOneClickIntegrationPayload_Partner>'AUDIO_CODES_TEST',
      AVAYA: <IntegrationOneClickIntegrationPayload_Partner>'AVAYA',
      AVAYA_TEST: <IntegrationOneClickIntegrationPayload_Partner>'AVAYA_TEST',
      GENESYS: <IntegrationOneClickIntegrationPayload_Partner>'GENESYS',
      INTRADO: <IntegrationOneClickIntegrationPayload_Partner>'INTRADO',
      PARTNER_UNSPECIFIED:
          <IntegrationOneClickIntegrationPayload_Partner>'PARTNER_UNSPECIFIED',
      SIGNALWIRE: <IntegrationOneClickIntegrationPayload_Partner>'SIGNALWIRE',
      TEST_PARTNER:
          <IntegrationOneClickIntegrationPayload_Partner>'TEST_PARTNER',
      TWILIO: <IntegrationOneClickIntegrationPayload_Partner>'TWILIO',
      TWILIO_TEST: <IntegrationOneClickIntegrationPayload_Partner>'TWILIO_TEST',
      VOXIMPLANT: <IntegrationOneClickIntegrationPayload_Partner>'VOXIMPLANT',
      values(): Array<IntegrationOneClickIntegrationPayload_Partner> {
        return [
          IntegrationOneClickIntegrationPayload_PartnerEnum.PARTNER_UNSPECIFIED,
          IntegrationOneClickIntegrationPayload_PartnerEnum.SIGNALWIRE,
          IntegrationOneClickIntegrationPayload_PartnerEnum.VOXIMPLANT,
          IntegrationOneClickIntegrationPayload_PartnerEnum.GENESYS,
          IntegrationOneClickIntegrationPayload_PartnerEnum.AVAYA,
          IntegrationOneClickIntegrationPayload_PartnerEnum.AUDIO_CODES,
          IntegrationOneClickIntegrationPayload_PartnerEnum.TWILIO,
          IntegrationOneClickIntegrationPayload_PartnerEnum.INTRADO,
          IntegrationOneClickIntegrationPayload_PartnerEnum.TEST_PARTNER,
          IntegrationOneClickIntegrationPayload_PartnerEnum.AVAYA_TEST,
          IntegrationOneClickIntegrationPayload_PartnerEnum.AUDIO_CODES_TEST,
          IntegrationOneClickIntegrationPayload_PartnerEnum.TWILIO_TEST
        ];
      }
    };

export type Integration_State =
    'STATE_UNSPECIFIED'|'ACTIVE'|'DISABLED'|'CREATING'|'DELETING';

export interface IIntegration_StateEnum {
  readonly STATE_UNSPECIFIED: Integration_State;
  readonly ACTIVE: Integration_State;
  readonly DISABLED: Integration_State;
  readonly CREATING: Integration_State;
  readonly DELETING: Integration_State;

  values(): Array<Integration_State>;
}

export const Integration_StateEnum: IIntegration_StateEnum = {
  ACTIVE: <Integration_State>'ACTIVE',
  CREATING: <Integration_State>'CREATING',
  DELETING: <Integration_State>'DELETING',
  DISABLED: <Integration_State>'DISABLED',
  STATE_UNSPECIFIED: <Integration_State>'STATE_UNSPECIFIED',
  values(): Array<Integration_State> {
    return [
      Integration_StateEnum.STATE_UNSPECIFIED, Integration_StateEnum.ACTIVE,
      Integration_StateEnum.DISABLED, Integration_StateEnum.CREATING,
      Integration_StateEnum.DELETING
    ];
  }
};

export type IntentConditionalFollowupEventInput_Condition =
    'FOLLOWUP_EVENT_CONDITION_UNSPECIFIED'|'WEBHOOK_TIMEOUT'|'WEBHOOK_ERROR';

export interface IIntentConditionalFollowupEventInput_ConditionEnum {
  readonly FOLLOWUP_EVENT_CONDITION_UNSPECIFIED:
      IntentConditionalFollowupEventInput_Condition;
  readonly WEBHOOK_TIMEOUT: IntentConditionalFollowupEventInput_Condition;
  readonly WEBHOOK_ERROR: IntentConditionalFollowupEventInput_Condition;

  values(): Array<IntentConditionalFollowupEventInput_Condition>;
}

export const IntentConditionalFollowupEventInput_ConditionEnum:
    IIntentConditionalFollowupEventInput_ConditionEnum = {
      FOLLOWUP_EVENT_CONDITION_UNSPECIFIED: <
          IntentConditionalFollowupEventInput_Condition>'FOLLOWUP_EVENT_CONDITION_UNSPECIFIED',
      WEBHOOK_ERROR:
          <IntentConditionalFollowupEventInput_Condition>'WEBHOOK_ERROR',
      WEBHOOK_TIMEOUT:
          <IntentConditionalFollowupEventInput_Condition>'WEBHOOK_TIMEOUT',
      values(): Array<IntentConditionalFollowupEventInput_Condition> {
        return [
          IntentConditionalFollowupEventInput_ConditionEnum
              .FOLLOWUP_EVENT_CONDITION_UNSPECIFIED,
          IntentConditionalFollowupEventInput_ConditionEnum.WEBHOOK_TIMEOUT,
          IntentConditionalFollowupEventInput_ConditionEnum.WEBHOOK_ERROR
        ];
      }
    };

export type IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint =
    'URL_TYPE_HINT_UNSPECIFIED'|'AMP_ACTION'|'AMP_CONTENT';

export interface IIntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHintEnum {
  readonly URL_TYPE_HINT_UNSPECIFIED:
      IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint;
  readonly AMP_ACTION:
      IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint;
  readonly AMP_CONTENT:
      IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint;

  values(): Array<
      IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint>;
}

export const IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHintEnum:
    IIntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHintEnum = {
      AMP_ACTION: <
          IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint>'AMP_ACTION',
      AMP_CONTENT: <
          IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint>'AMP_CONTENT',
      URL_TYPE_HINT_UNSPECIFIED: <
          IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint>'URL_TYPE_HINT_UNSPECIFIED',
      values(): Array<
          IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint> {
        return [
          IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHintEnum
              .URL_TYPE_HINT_UNSPECIFIED,
          IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHintEnum
              .AMP_ACTION,
          IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHintEnum
              .AMP_CONTENT
        ];
      }
    };

export type IntentMessageBrowseCarouselCard_ImageDisplayOptions =
    'IMAGE_DISPLAY_OPTIONS_UNSPECIFIED'|'GRAY'|'WHITE'|'CROPPED'|
    'BLURRED_BACKGROUND';

export interface IIntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum {
  readonly IMAGE_DISPLAY_OPTIONS_UNSPECIFIED:
      IntentMessageBrowseCarouselCard_ImageDisplayOptions;
  readonly GRAY: IntentMessageBrowseCarouselCard_ImageDisplayOptions;
  readonly WHITE: IntentMessageBrowseCarouselCard_ImageDisplayOptions;
  readonly CROPPED: IntentMessageBrowseCarouselCard_ImageDisplayOptions;
  readonly BLURRED_BACKGROUND:
      IntentMessageBrowseCarouselCard_ImageDisplayOptions;

  values(): Array<IntentMessageBrowseCarouselCard_ImageDisplayOptions>;
}

export const IntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum:
    IIntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum = {
      BLURRED_BACKGROUND: <
          IntentMessageBrowseCarouselCard_ImageDisplayOptions>'BLURRED_BACKGROUND',
      CROPPED: <IntentMessageBrowseCarouselCard_ImageDisplayOptions>'CROPPED',
      GRAY: <IntentMessageBrowseCarouselCard_ImageDisplayOptions>'GRAY',
      IMAGE_DISPLAY_OPTIONS_UNSPECIFIED: <
          IntentMessageBrowseCarouselCard_ImageDisplayOptions>'IMAGE_DISPLAY_OPTIONS_UNSPECIFIED',
      WHITE: <IntentMessageBrowseCarouselCard_ImageDisplayOptions>'WHITE',
      values(): Array<IntentMessageBrowseCarouselCard_ImageDisplayOptions> {
        return [
          IntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum
              .IMAGE_DISPLAY_OPTIONS_UNSPECIFIED,
          IntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum.GRAY,
          IntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum.WHITE,
          IntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum.CROPPED,
          IntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum
              .BLURRED_BACKGROUND
        ];
      }
    };

export type IntentMessageColumnProperties_HorizontalAlignment =
    'HORIZONTAL_ALIGNMENT_UNSPECIFIED'|'LEADING'|'CENTER'|'TRAILING';

export interface IIntentMessageColumnProperties_HorizontalAlignmentEnum {
  readonly HORIZONTAL_ALIGNMENT_UNSPECIFIED:
      IntentMessageColumnProperties_HorizontalAlignment;
  readonly LEADING: IntentMessageColumnProperties_HorizontalAlignment;
  readonly CENTER: IntentMessageColumnProperties_HorizontalAlignment;
  readonly TRAILING: IntentMessageColumnProperties_HorizontalAlignment;

  values(): Array<IntentMessageColumnProperties_HorizontalAlignment>;
}

export const IntentMessageColumnProperties_HorizontalAlignmentEnum:
    IIntentMessageColumnProperties_HorizontalAlignmentEnum = {
      CENTER: <IntentMessageColumnProperties_HorizontalAlignment>'CENTER',
      HORIZONTAL_ALIGNMENT_UNSPECIFIED: <
          IntentMessageColumnProperties_HorizontalAlignment>'HORIZONTAL_ALIGNMENT_UNSPECIFIED',
      LEADING: <IntentMessageColumnProperties_HorizontalAlignment>'LEADING',
      TRAILING: <IntentMessageColumnProperties_HorizontalAlignment>'TRAILING',
      values(): Array<IntentMessageColumnProperties_HorizontalAlignment> {
        return [
          IntentMessageColumnProperties_HorizontalAlignmentEnum
              .HORIZONTAL_ALIGNMENT_UNSPECIFIED,
          IntentMessageColumnProperties_HorizontalAlignmentEnum.LEADING,
          IntentMessageColumnProperties_HorizontalAlignmentEnum.CENTER,
          IntentMessageColumnProperties_HorizontalAlignmentEnum.TRAILING
        ];
      }
    };

export type IntentMessageMediaContent_MediaType =
    'RESPONSE_MEDIA_TYPE_UNSPECIFIED'|'AUDIO'|'MEDIA_STATUS_ACK';

export interface IIntentMessageMediaContent_MediaTypeEnum {
  readonly RESPONSE_MEDIA_TYPE_UNSPECIFIED: IntentMessageMediaContent_MediaType;
  readonly AUDIO: IntentMessageMediaContent_MediaType;
  readonly MEDIA_STATUS_ACK: IntentMessageMediaContent_MediaType;

  values(): Array<IntentMessageMediaContent_MediaType>;
}

export const IntentMessageMediaContent_MediaTypeEnum:
    IIntentMessageMediaContent_MediaTypeEnum = {
      AUDIO: <IntentMessageMediaContent_MediaType>'AUDIO',
      MEDIA_STATUS_ACK: <IntentMessageMediaContent_MediaType>'MEDIA_STATUS_ACK',
      RESPONSE_MEDIA_TYPE_UNSPECIFIED: <
          IntentMessageMediaContent_MediaType>'RESPONSE_MEDIA_TYPE_UNSPECIFIED',
      values(): Array<IntentMessageMediaContent_MediaType> {
        return [
          IntentMessageMediaContent_MediaTypeEnum
              .RESPONSE_MEDIA_TYPE_UNSPECIFIED,
          IntentMessageMediaContent_MediaTypeEnum.AUDIO,
          IntentMessageMediaContent_MediaTypeEnum.MEDIA_STATUS_ACK
        ];
      }
    };

export type IntentMessageMediaContent_RepeatMode =
    'REPEAT_MODE_UNSPECIFIED'|'OFF'|'ALL';

export interface IIntentMessageMediaContent_RepeatModeEnum {
  readonly REPEAT_MODE_UNSPECIFIED: IntentMessageMediaContent_RepeatMode;
  readonly OFF: IntentMessageMediaContent_RepeatMode;
  readonly ALL: IntentMessageMediaContent_RepeatMode;

  values(): Array<IntentMessageMediaContent_RepeatMode>;
}

export const IntentMessageMediaContent_RepeatModeEnum:
    IIntentMessageMediaContent_RepeatModeEnum = {
      ALL: <IntentMessageMediaContent_RepeatMode>'ALL',
      OFF: <IntentMessageMediaContent_RepeatMode>'OFF',
      REPEAT_MODE_UNSPECIFIED:
          <IntentMessageMediaContent_RepeatMode>'REPEAT_MODE_UNSPECIFIED',
      values(): Array<IntentMessageMediaContent_RepeatMode> {
        return [
          IntentMessageMediaContent_RepeatModeEnum.REPEAT_MODE_UNSPECIFIED,
          IntentMessageMediaContent_RepeatModeEnum.OFF,
          IntentMessageMediaContent_RepeatModeEnum.ALL
        ];
      }
    };

export type IntentMessageRbmCardContentRbmMedia_Height =
    'HEIGHT_UNSPECIFIED'|'SHORT'|'MEDIUM'|'TALL';

export interface IIntentMessageRbmCardContentRbmMedia_HeightEnum {
  readonly HEIGHT_UNSPECIFIED: IntentMessageRbmCardContentRbmMedia_Height;
  readonly SHORT: IntentMessageRbmCardContentRbmMedia_Height;
  readonly MEDIUM: IntentMessageRbmCardContentRbmMedia_Height;
  readonly TALL: IntentMessageRbmCardContentRbmMedia_Height;

  values(): Array<IntentMessageRbmCardContentRbmMedia_Height>;
}

export const IntentMessageRbmCardContentRbmMedia_HeightEnum:
    IIntentMessageRbmCardContentRbmMedia_HeightEnum = {
      HEIGHT_UNSPECIFIED:
          <IntentMessageRbmCardContentRbmMedia_Height>'HEIGHT_UNSPECIFIED',
      MEDIUM: <IntentMessageRbmCardContentRbmMedia_Height>'MEDIUM',
      SHORT: <IntentMessageRbmCardContentRbmMedia_Height>'SHORT',
      TALL: <IntentMessageRbmCardContentRbmMedia_Height>'TALL',
      values(): Array<IntentMessageRbmCardContentRbmMedia_Height> {
        return [
          IntentMessageRbmCardContentRbmMedia_HeightEnum.HEIGHT_UNSPECIFIED,
          IntentMessageRbmCardContentRbmMedia_HeightEnum.SHORT,
          IntentMessageRbmCardContentRbmMedia_HeightEnum.MEDIUM,
          IntentMessageRbmCardContentRbmMedia_HeightEnum.TALL
        ];
      }
    };

export type IntentMessageRbmCarouselCard_CardWidth =
    'CARD_WIDTH_UNSPECIFIED'|'SMALL'|'MEDIUM';

export interface IIntentMessageRbmCarouselCard_CardWidthEnum {
  readonly CARD_WIDTH_UNSPECIFIED: IntentMessageRbmCarouselCard_CardWidth;
  readonly SMALL: IntentMessageRbmCarouselCard_CardWidth;
  readonly MEDIUM: IntentMessageRbmCarouselCard_CardWidth;

  values(): Array<IntentMessageRbmCarouselCard_CardWidth>;
}

export const IntentMessageRbmCarouselCard_CardWidthEnum:
    IIntentMessageRbmCarouselCard_CardWidthEnum = {
      CARD_WIDTH_UNSPECIFIED:
          <IntentMessageRbmCarouselCard_CardWidth>'CARD_WIDTH_UNSPECIFIED',
      MEDIUM: <IntentMessageRbmCarouselCard_CardWidth>'MEDIUM',
      SMALL: <IntentMessageRbmCarouselCard_CardWidth>'SMALL',
      values(): Array<IntentMessageRbmCarouselCard_CardWidth> {
        return [
          IntentMessageRbmCarouselCard_CardWidthEnum.CARD_WIDTH_UNSPECIFIED,
          IntentMessageRbmCarouselCard_CardWidthEnum.SMALL,
          IntentMessageRbmCarouselCard_CardWidthEnum.MEDIUM
        ];
      }
    };

export type IntentMessageRbmStandaloneCard_CardOrientation =
    'CARD_ORIENTATION_UNSPECIFIED'|'HORIZONTAL'|'VERTICAL';

export interface IIntentMessageRbmStandaloneCard_CardOrientationEnum {
  readonly CARD_ORIENTATION_UNSPECIFIED:
      IntentMessageRbmStandaloneCard_CardOrientation;
  readonly HORIZONTAL: IntentMessageRbmStandaloneCard_CardOrientation;
  readonly VERTICAL: IntentMessageRbmStandaloneCard_CardOrientation;

  values(): Array<IntentMessageRbmStandaloneCard_CardOrientation>;
}

export const IntentMessageRbmStandaloneCard_CardOrientationEnum:
    IIntentMessageRbmStandaloneCard_CardOrientationEnum = {
      CARD_ORIENTATION_UNSPECIFIED: <
          IntentMessageRbmStandaloneCard_CardOrientation>'CARD_ORIENTATION_UNSPECIFIED',
      HORIZONTAL: <IntentMessageRbmStandaloneCard_CardOrientation>'HORIZONTAL',
      VERTICAL: <IntentMessageRbmStandaloneCard_CardOrientation>'VERTICAL',
      values(): Array<IntentMessageRbmStandaloneCard_CardOrientation> {
        return [
          IntentMessageRbmStandaloneCard_CardOrientationEnum
              .CARD_ORIENTATION_UNSPECIFIED,
          IntentMessageRbmStandaloneCard_CardOrientationEnum.HORIZONTAL,
          IntentMessageRbmStandaloneCard_CardOrientationEnum.VERTICAL
        ];
      }
    };

export type IntentMessageRbmStandaloneCard_ThumbnailImageAlignment =
    'THUMBNAIL_IMAGE_ALIGNMENT_UNSPECIFIED'|'LEFT'|'RIGHT';

export interface IIntentMessageRbmStandaloneCard_ThumbnailImageAlignmentEnum {
  readonly THUMBNAIL_IMAGE_ALIGNMENT_UNSPECIFIED:
      IntentMessageRbmStandaloneCard_ThumbnailImageAlignment;
  readonly LEFT: IntentMessageRbmStandaloneCard_ThumbnailImageAlignment;
  readonly RIGHT: IntentMessageRbmStandaloneCard_ThumbnailImageAlignment;

  values(): Array<IntentMessageRbmStandaloneCard_ThumbnailImageAlignment>;
}

export const IntentMessageRbmStandaloneCard_ThumbnailImageAlignmentEnum:
    IIntentMessageRbmStandaloneCard_ThumbnailImageAlignmentEnum = {
      LEFT: <IntentMessageRbmStandaloneCard_ThumbnailImageAlignment>'LEFT',
      RIGHT: <IntentMessageRbmStandaloneCard_ThumbnailImageAlignment>'RIGHT',
      THUMBNAIL_IMAGE_ALIGNMENT_UNSPECIFIED: <
          IntentMessageRbmStandaloneCard_ThumbnailImageAlignment>'THUMBNAIL_IMAGE_ALIGNMENT_UNSPECIFIED',
      values(): Array<IntentMessageRbmStandaloneCard_ThumbnailImageAlignment> {
        return [
          IntentMessageRbmStandaloneCard_ThumbnailImageAlignmentEnum
              .THUMBNAIL_IMAGE_ALIGNMENT_UNSPECIFIED,
          IntentMessageRbmStandaloneCard_ThumbnailImageAlignmentEnum.LEFT,
          IntentMessageRbmStandaloneCard_ThumbnailImageAlignmentEnum.RIGHT
        ];
      }
    };

export type IntentMessageTelephonyReadDtmf_FinishDigit =
    'TELEPHONY_DTMF_UNSPECIFIED'|'DTMF_ONE'|'DTMF_TWO'|'DTMF_THREE'|'DTMF_FOUR'|
    'DTMF_FIVE'|'DTMF_SIX'|'DTMF_SEVEN'|'DTMF_EIGHT'|'DTMF_NINE'|'DTMF_ZERO'|
    'DTMF_A'|'DTMF_B'|'DTMF_C'|'DTMF_D'|'DTMF_STAR'|'DTMF_POUND';

export interface IIntentMessageTelephonyReadDtmf_FinishDigitEnum {
  readonly TELEPHONY_DTMF_UNSPECIFIED:
      IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_ONE: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_TWO: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_THREE: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_FOUR: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_FIVE: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_SIX: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_SEVEN: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_EIGHT: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_NINE: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_ZERO: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_A: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_B: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_C: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_D: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_STAR: IntentMessageTelephonyReadDtmf_FinishDigit;
  readonly DTMF_POUND: IntentMessageTelephonyReadDtmf_FinishDigit;

  values(): Array<IntentMessageTelephonyReadDtmf_FinishDigit>;
}

export const IntentMessageTelephonyReadDtmf_FinishDigitEnum:
    IIntentMessageTelephonyReadDtmf_FinishDigitEnum = {
      DTMF_A: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_A',
      DTMF_B: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_B',
      DTMF_C: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_C',
      DTMF_D: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_D',
      DTMF_EIGHT: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_EIGHT',
      DTMF_FIVE: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_FIVE',
      DTMF_FOUR: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_FOUR',
      DTMF_NINE: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_NINE',
      DTMF_ONE: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_ONE',
      DTMF_POUND: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_POUND',
      DTMF_SEVEN: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_SEVEN',
      DTMF_SIX: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_SIX',
      DTMF_STAR: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_STAR',
      DTMF_THREE: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_THREE',
      DTMF_TWO: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_TWO',
      DTMF_ZERO: <IntentMessageTelephonyReadDtmf_FinishDigit>'DTMF_ZERO',
      TELEPHONY_DTMF_UNSPECIFIED: <
          IntentMessageTelephonyReadDtmf_FinishDigit>'TELEPHONY_DTMF_UNSPECIFIED',
      values(): Array<IntentMessageTelephonyReadDtmf_FinishDigit> {
        return [
          IntentMessageTelephonyReadDtmf_FinishDigitEnum
              .TELEPHONY_DTMF_UNSPECIFIED,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_ONE,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_TWO,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_THREE,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_FOUR,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_FIVE,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_SIX,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_SEVEN,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_EIGHT,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_NINE,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_ZERO,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_A,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_B,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_C,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_D,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_STAR,
          IntentMessageTelephonyReadDtmf_FinishDigitEnum.DTMF_POUND
        ];
      }
    };

export type IntentMessage_Platform = 'PLATFORM_UNSPECIFIED'|'FACEBOOK'|'SLACK'|
    'TELEGRAM'|'KIK'|'SKYPE'|'LINE'|'VIBER'|'ACTIONS_ON_GOOGLE'|'AMAZON_ALEXA'|
    'TELEPHONY'|'GOOGLE_HANGOUTS'|'GOOGLE_RBM'|'WE_CHAT';

export interface IIntentMessage_PlatformEnum {
  readonly PLATFORM_UNSPECIFIED: IntentMessage_Platform;
  readonly FACEBOOK: IntentMessage_Platform;
  readonly SLACK: IntentMessage_Platform;
  readonly TELEGRAM: IntentMessage_Platform;
  readonly KIK: IntentMessage_Platform;
  readonly SKYPE: IntentMessage_Platform;
  readonly LINE: IntentMessage_Platform;
  readonly VIBER: IntentMessage_Platform;
  readonly ACTIONS_ON_GOOGLE: IntentMessage_Platform;
  readonly AMAZON_ALEXA: IntentMessage_Platform;
  readonly TELEPHONY: IntentMessage_Platform;
  readonly GOOGLE_HANGOUTS: IntentMessage_Platform;
  readonly GOOGLE_RBM: IntentMessage_Platform;
  readonly WE_CHAT: IntentMessage_Platform;

  values(): Array<IntentMessage_Platform>;
}

export const IntentMessage_PlatformEnum: IIntentMessage_PlatformEnum = {
  ACTIONS_ON_GOOGLE: <IntentMessage_Platform>'ACTIONS_ON_GOOGLE',
  AMAZON_ALEXA: <IntentMessage_Platform>'AMAZON_ALEXA',
  FACEBOOK: <IntentMessage_Platform>'FACEBOOK',
  GOOGLE_HANGOUTS: <IntentMessage_Platform>'GOOGLE_HANGOUTS',
  GOOGLE_RBM: <IntentMessage_Platform>'GOOGLE_RBM',
  KIK: <IntentMessage_Platform>'KIK',
  LINE: <IntentMessage_Platform>'LINE',
  PLATFORM_UNSPECIFIED: <IntentMessage_Platform>'PLATFORM_UNSPECIFIED',
  SKYPE: <IntentMessage_Platform>'SKYPE',
  SLACK: <IntentMessage_Platform>'SLACK',
  TELEGRAM: <IntentMessage_Platform>'TELEGRAM',
  TELEPHONY: <IntentMessage_Platform>'TELEPHONY',
  VIBER: <IntentMessage_Platform>'VIBER',
  WE_CHAT: <IntentMessage_Platform>'WE_CHAT',
  values(): Array<IntentMessage_Platform> {
    return [
      IntentMessage_PlatformEnum.PLATFORM_UNSPECIFIED,
      IntentMessage_PlatformEnum.FACEBOOK, IntentMessage_PlatformEnum.SLACK,
      IntentMessage_PlatformEnum.TELEGRAM, IntentMessage_PlatformEnum.KIK,
      IntentMessage_PlatformEnum.SKYPE, IntentMessage_PlatformEnum.LINE,
      IntentMessage_PlatformEnum.VIBER,
      IntentMessage_PlatformEnum.ACTIONS_ON_GOOGLE,
      IntentMessage_PlatformEnum.AMAZON_ALEXA,
      IntentMessage_PlatformEnum.TELEPHONY,
      IntentMessage_PlatformEnum.GOOGLE_HANGOUTS,
      IntentMessage_PlatformEnum.GOOGLE_RBM, IntentMessage_PlatformEnum.WE_CHAT
    ];
  }
};

export type IntentTrainingPhrase_Type = 'TYPE_UNSPECIFIED'|'EXAMPLE'|'TEMPLATE';

export interface IIntentTrainingPhrase_TypeEnum {
  readonly TYPE_UNSPECIFIED: IntentTrainingPhrase_Type;
  readonly EXAMPLE: IntentTrainingPhrase_Type;
  readonly TEMPLATE: IntentTrainingPhrase_Type;

  values(): Array<IntentTrainingPhrase_Type>;
}

export const IntentTrainingPhrase_TypeEnum: IIntentTrainingPhrase_TypeEnum = {
  EXAMPLE: <IntentTrainingPhrase_Type>'EXAMPLE',
  TEMPLATE: <IntentTrainingPhrase_Type>'TEMPLATE',
  TYPE_UNSPECIFIED: <IntentTrainingPhrase_Type>'TYPE_UNSPECIFIED',
  values(): Array<IntentTrainingPhrase_Type> {
    return [
      IntentTrainingPhrase_TypeEnum.TYPE_UNSPECIFIED,
      IntentTrainingPhrase_TypeEnum.EXAMPLE,
      IntentTrainingPhrase_TypeEnum.TEMPLATE
    ];
  }
};

export type Intent_DefaultResponsePlatforms = 'PLATFORM_UNSPECIFIED'|'FACEBOOK'|
    'SLACK'|'TELEGRAM'|'KIK'|'SKYPE'|'LINE'|'VIBER'|'ACTIONS_ON_GOOGLE'|
    'AMAZON_ALEXA'|'TELEPHONY'|'GOOGLE_HANGOUTS'|'GOOGLE_RBM'|'WE_CHAT';

export interface IIntent_DefaultResponsePlatformsEnum {
  readonly PLATFORM_UNSPECIFIED: Intent_DefaultResponsePlatforms;
  readonly FACEBOOK: Intent_DefaultResponsePlatforms;
  readonly SLACK: Intent_DefaultResponsePlatforms;
  readonly TELEGRAM: Intent_DefaultResponsePlatforms;
  readonly KIK: Intent_DefaultResponsePlatforms;
  readonly SKYPE: Intent_DefaultResponsePlatforms;
  readonly LINE: Intent_DefaultResponsePlatforms;
  readonly VIBER: Intent_DefaultResponsePlatforms;
  readonly ACTIONS_ON_GOOGLE: Intent_DefaultResponsePlatforms;
  readonly AMAZON_ALEXA: Intent_DefaultResponsePlatforms;
  readonly TELEPHONY: Intent_DefaultResponsePlatforms;
  readonly GOOGLE_HANGOUTS: Intent_DefaultResponsePlatforms;
  readonly GOOGLE_RBM: Intent_DefaultResponsePlatforms;
  readonly WE_CHAT: Intent_DefaultResponsePlatforms;

  values(): Array<Intent_DefaultResponsePlatforms>;
}

export const Intent_DefaultResponsePlatformsEnum:
    IIntent_DefaultResponsePlatformsEnum = {
      ACTIONS_ON_GOOGLE: <Intent_DefaultResponsePlatforms>'ACTIONS_ON_GOOGLE',
      AMAZON_ALEXA: <Intent_DefaultResponsePlatforms>'AMAZON_ALEXA',
      FACEBOOK: <Intent_DefaultResponsePlatforms>'FACEBOOK',
      GOOGLE_HANGOUTS: <Intent_DefaultResponsePlatforms>'GOOGLE_HANGOUTS',
      GOOGLE_RBM: <Intent_DefaultResponsePlatforms>'GOOGLE_RBM',
      KIK: <Intent_DefaultResponsePlatforms>'KIK',
      LINE: <Intent_DefaultResponsePlatforms>'LINE',
      PLATFORM_UNSPECIFIED:
          <Intent_DefaultResponsePlatforms>'PLATFORM_UNSPECIFIED',
      SKYPE: <Intent_DefaultResponsePlatforms>'SKYPE',
      SLACK: <Intent_DefaultResponsePlatforms>'SLACK',
      TELEGRAM: <Intent_DefaultResponsePlatforms>'TELEGRAM',
      TELEPHONY: <Intent_DefaultResponsePlatforms>'TELEPHONY',
      VIBER: <Intent_DefaultResponsePlatforms>'VIBER',
      WE_CHAT: <Intent_DefaultResponsePlatforms>'WE_CHAT',
      values(): Array<Intent_DefaultResponsePlatforms> {
        return [
          Intent_DefaultResponsePlatformsEnum.PLATFORM_UNSPECIFIED,
          Intent_DefaultResponsePlatformsEnum.FACEBOOK,
          Intent_DefaultResponsePlatformsEnum.SLACK,
          Intent_DefaultResponsePlatformsEnum.TELEGRAM,
          Intent_DefaultResponsePlatformsEnum.KIK,
          Intent_DefaultResponsePlatformsEnum.SKYPE,
          Intent_DefaultResponsePlatformsEnum.LINE,
          Intent_DefaultResponsePlatformsEnum.VIBER,
          Intent_DefaultResponsePlatformsEnum.ACTIONS_ON_GOOGLE,
          Intent_DefaultResponsePlatformsEnum.AMAZON_ALEXA,
          Intent_DefaultResponsePlatformsEnum.TELEPHONY,
          Intent_DefaultResponsePlatformsEnum.GOOGLE_HANGOUTS,
          Intent_DefaultResponsePlatformsEnum.GOOGLE_RBM,
          Intent_DefaultResponsePlatformsEnum.WE_CHAT
        ];
      }
    };

export type Intent_WebhookState = 'WEBHOOK_STATE_UNSPECIFIED'|
    'WEBHOOK_STATE_ENABLED'|'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING';

export interface IIntent_WebhookStateEnum {
  readonly WEBHOOK_STATE_UNSPECIFIED: Intent_WebhookState;
  readonly WEBHOOK_STATE_ENABLED: Intent_WebhookState;
  readonly WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING: Intent_WebhookState;

  values(): Array<Intent_WebhookState>;
}

export const Intent_WebhookStateEnum: IIntent_WebhookStateEnum = {
  WEBHOOK_STATE_ENABLED: <Intent_WebhookState>'WEBHOOK_STATE_ENABLED',
  WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING:
      <Intent_WebhookState>'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING',
  WEBHOOK_STATE_UNSPECIFIED: <Intent_WebhookState>'WEBHOOK_STATE_UNSPECIFIED',
  values(): Array<Intent_WebhookState> {
    return [
      Intent_WebhookStateEnum.WEBHOOK_STATE_UNSPECIFIED,
      Intent_WebhookStateEnum.WEBHOOK_STATE_ENABLED,
      Intent_WebhookStateEnum.WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING
    ];
  }
};

export type Interaction_LogType =
    'LOG_TYPE_UNSPECIFIED'|'DEFAULT'|'EVENT'|'SOUND';

export interface IInteraction_LogTypeEnum {
  readonly LOG_TYPE_UNSPECIFIED: Interaction_LogType;
  readonly DEFAULT: Interaction_LogType;
  readonly EVENT: Interaction_LogType;
  readonly SOUND: Interaction_LogType;

  values(): Array<Interaction_LogType>;
}

export const Interaction_LogTypeEnum: IInteraction_LogTypeEnum = {
  DEFAULT: <Interaction_LogType>'DEFAULT',
  EVENT: <Interaction_LogType>'EVENT',
  LOG_TYPE_UNSPECIFIED: <Interaction_LogType>'LOG_TYPE_UNSPECIFIED',
  SOUND: <Interaction_LogType>'SOUND',
  values(): Array<Interaction_LogType> {
    return [
      Interaction_LogTypeEnum.LOG_TYPE_UNSPECIFIED,
      Interaction_LogTypeEnum.DEFAULT, Interaction_LogTypeEnum.EVENT,
      Interaction_LogTypeEnum.SOUND
    ];
  }
};

export type Interaction_LoggingPolicy =
    'LOGGING_POLICY_UNSPECIFIED'|'NORMAL'|'LIMITED';

export interface IInteraction_LoggingPolicyEnum {
  readonly LOGGING_POLICY_UNSPECIFIED: Interaction_LoggingPolicy;
  readonly NORMAL: Interaction_LoggingPolicy;
  readonly LIMITED: Interaction_LoggingPolicy;

  values(): Array<Interaction_LoggingPolicy>;
}

export const Interaction_LoggingPolicyEnum: IInteraction_LoggingPolicyEnum = {
  LIMITED: <Interaction_LoggingPolicy>'LIMITED',
  LOGGING_POLICY_UNSPECIFIED:
      <Interaction_LoggingPolicy>'LOGGING_POLICY_UNSPECIFIED',
  NORMAL: <Interaction_LoggingPolicy>'NORMAL',
  values(): Array<Interaction_LoggingPolicy> {
    return [
      Interaction_LoggingPolicyEnum.LOGGING_POLICY_UNSPECIFIED,
      Interaction_LoggingPolicyEnum.NORMAL,
      Interaction_LoggingPolicyEnum.LIMITED
    ];
  }
};

export type Interaction_TrainingPhrasesPromotionState =
    'TRAINING_PHRASES_PROMOTION_STATE_UNSPECIFIED'|'APPROVED'|'ADD_TO_FALLBACK'|
    'DELETE';

export interface IInteraction_TrainingPhrasesPromotionStateEnum {
  readonly TRAINING_PHRASES_PROMOTION_STATE_UNSPECIFIED:
      Interaction_TrainingPhrasesPromotionState;
  readonly APPROVED: Interaction_TrainingPhrasesPromotionState;
  readonly ADD_TO_FALLBACK: Interaction_TrainingPhrasesPromotionState;
  readonly DELETE: Interaction_TrainingPhrasesPromotionState;

  values(): Array<Interaction_TrainingPhrasesPromotionState>;
}

export const Interaction_TrainingPhrasesPromotionStateEnum:
    IInteraction_TrainingPhrasesPromotionStateEnum = {
      ADD_TO_FALLBACK:
          <Interaction_TrainingPhrasesPromotionState>'ADD_TO_FALLBACK',
      APPROVED: <Interaction_TrainingPhrasesPromotionState>'APPROVED',
      DELETE: <Interaction_TrainingPhrasesPromotionState>'DELETE',
      TRAINING_PHRASES_PROMOTION_STATE_UNSPECIFIED: <
          Interaction_TrainingPhrasesPromotionState>'TRAINING_PHRASES_PROMOTION_STATE_UNSPECIFIED',
      values(): Array<Interaction_TrainingPhrasesPromotionState> {
        return [
          Interaction_TrainingPhrasesPromotionStateEnum
              .TRAINING_PHRASES_PROMOTION_STATE_UNSPECIFIED,
          Interaction_TrainingPhrasesPromotionStateEnum.APPROVED,
          Interaction_TrainingPhrasesPromotionStateEnum.ADD_TO_FALLBACK,
          Interaction_TrainingPhrasesPromotionStateEnum.DELETE
        ];
      }
    };

export type IssueModelMetadata_TrainingModelType = 'MODEL_TYPE_UNSPECIFIED'|
    'ARTICLE_SUGGESTION_GBT_MODEL'|'SMART_REPLY_DUAL_ENCODER_MODEL'|
    'SMART_REPLY_BERT_MODEL'|'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|
    'SMART_COMPOSE_DUAL_ENCODER_MODEL'|'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'CONVERSATION_GUIDANCE_MODEL'|'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface IIssueModelMetadata_TrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED: IssueModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL: IssueModelMetadata_TrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL: IssueModelMetadata_TrainingModelType;
  readonly SMART_REPLY_BERT_MODEL: IssueModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      IssueModelMetadata_TrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      IssueModelMetadata_TrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      IssueModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      IssueModelMetadata_TrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL: IssueModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_MODEL: IssueModelMetadata_TrainingModelType;
  readonly KEY_MOMENT_MODEL: IssueModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL: IssueModelMetadata_TrainingModelType;
  readonly CONVERSATION_GUIDANCE_MODEL: IssueModelMetadata_TrainingModelType;
  readonly CUSTOM_HEAD_INTENT_SUGGESTION_MODEL:
      IssueModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_V2_MODEL: IssueModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      IssueModelMetadata_TrainingModelType;

  values(): Array<IssueModelMetadata_TrainingModelType>;
}

export const IssueModelMetadata_TrainingModelTypeEnum:
    IIssueModelMetadata_TrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          IssueModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL:
          <IssueModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          IssueModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      CONVERSATION_GUIDANCE_MODEL:
          <IssueModelMetadata_TrainingModelType>'CONVERSATION_GUIDANCE_MODEL',
      CUSTOM_HEAD_INTENT_SUGGESTION_MODEL: <
          IssueModelMetadata_TrainingModelType>'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          IssueModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          IssueModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          IssueModelMetadata_TrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL:
          <IssueModelMetadata_TrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED:
          <IssueModelMetadata_TrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          IssueModelMetadata_TrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL:
          <IssueModelMetadata_TrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          IssueModelMetadata_TrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL:
          <IssueModelMetadata_TrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL:
          <IssueModelMetadata_TrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL:
          <IssueModelMetadata_TrainingModelType>'SUMMARIZATION_V2_MODEL',
      values(): Array<IssueModelMetadata_TrainingModelType> {
        return [
          IssueModelMetadata_TrainingModelTypeEnum.MODEL_TYPE_UNSPECIFIED,
          IssueModelMetadata_TrainingModelTypeEnum.ARTICLE_SUGGESTION_GBT_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_DUAL_ENCODER_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum.SMART_REPLY_BERT_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum
              .SMART_COMPOSE_DUAL_ENCODER_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum
              .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum.SMART_REPLY_GENERATIVE_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum.SUMMARIZATION_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum.KEY_MOMENT_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum.CONVERSATION_GUIDANCE_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum
              .CUSTOM_HEAD_INTENT_SUGGESTION_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum.SUMMARIZATION_V2_MODEL,
          IssueModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
        ];
      }
    };

export type IvrNodeSettingsDtmfSettings_FinishDigit =
    'TELEPHONY_DTMF_UNSPECIFIED'|'DTMF_ONE'|'DTMF_TWO'|'DTMF_THREE'|'DTMF_FOUR'|
    'DTMF_FIVE'|'DTMF_SIX'|'DTMF_SEVEN'|'DTMF_EIGHT'|'DTMF_NINE'|'DTMF_ZERO'|
    'DTMF_A'|'DTMF_B'|'DTMF_C'|'DTMF_D'|'DTMF_STAR'|'DTMF_POUND';

export interface IIvrNodeSettingsDtmfSettings_FinishDigitEnum {
  readonly TELEPHONY_DTMF_UNSPECIFIED: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_ONE: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_TWO: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_THREE: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_FOUR: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_FIVE: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_SIX: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_SEVEN: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_EIGHT: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_NINE: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_ZERO: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_A: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_B: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_C: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_D: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_STAR: IvrNodeSettingsDtmfSettings_FinishDigit;
  readonly DTMF_POUND: IvrNodeSettingsDtmfSettings_FinishDigit;

  values(): Array<IvrNodeSettingsDtmfSettings_FinishDigit>;
}

export const IvrNodeSettingsDtmfSettings_FinishDigitEnum:
    IIvrNodeSettingsDtmfSettings_FinishDigitEnum = {
      DTMF_A: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_A',
      DTMF_B: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_B',
      DTMF_C: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_C',
      DTMF_D: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_D',
      DTMF_EIGHT: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_EIGHT',
      DTMF_FIVE: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_FIVE',
      DTMF_FOUR: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_FOUR',
      DTMF_NINE: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_NINE',
      DTMF_ONE: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_ONE',
      DTMF_POUND: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_POUND',
      DTMF_SEVEN: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_SEVEN',
      DTMF_SIX: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_SIX',
      DTMF_STAR: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_STAR',
      DTMF_THREE: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_THREE',
      DTMF_TWO: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_TWO',
      DTMF_ZERO: <IvrNodeSettingsDtmfSettings_FinishDigit>'DTMF_ZERO',
      TELEPHONY_DTMF_UNSPECIFIED:
          <IvrNodeSettingsDtmfSettings_FinishDigit>'TELEPHONY_DTMF_UNSPECIFIED',
      values(): Array<IvrNodeSettingsDtmfSettings_FinishDigit> {
        return [
          IvrNodeSettingsDtmfSettings_FinishDigitEnum
              .TELEPHONY_DTMF_UNSPECIFIED,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_ONE,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_TWO,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_THREE,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_FOUR,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_FIVE,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_SIX,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_SEVEN,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_EIGHT,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_NINE,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_ZERO,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_A,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_B,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_C,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_D,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_STAR,
          IvrNodeSettingsDtmfSettings_FinishDigitEnum.DTMF_POUND
        ];
      }
    };

export type KnowledgeAnswersAnswer_MatchConfidenceLevel =
    'MATCH_CONFIDENCE_LEVEL_UNSPECIFIED'|'LOW'|'MEDIUM'|'HIGH';

export interface IKnowledgeAnswersAnswer_MatchConfidenceLevelEnum {
  readonly MATCH_CONFIDENCE_LEVEL_UNSPECIFIED:
      KnowledgeAnswersAnswer_MatchConfidenceLevel;
  readonly LOW: KnowledgeAnswersAnswer_MatchConfidenceLevel;
  readonly MEDIUM: KnowledgeAnswersAnswer_MatchConfidenceLevel;
  readonly HIGH: KnowledgeAnswersAnswer_MatchConfidenceLevel;

  values(): Array<KnowledgeAnswersAnswer_MatchConfidenceLevel>;
}

export const KnowledgeAnswersAnswer_MatchConfidenceLevelEnum:
    IKnowledgeAnswersAnswer_MatchConfidenceLevelEnum = {
      HIGH: <KnowledgeAnswersAnswer_MatchConfidenceLevel>'HIGH',
      LOW: <KnowledgeAnswersAnswer_MatchConfidenceLevel>'LOW',
      MATCH_CONFIDENCE_LEVEL_UNSPECIFIED: <
          KnowledgeAnswersAnswer_MatchConfidenceLevel>'MATCH_CONFIDENCE_LEVEL_UNSPECIFIED',
      MEDIUM: <KnowledgeAnswersAnswer_MatchConfidenceLevel>'MEDIUM',
      values(): Array<KnowledgeAnswersAnswer_MatchConfidenceLevel> {
        return [
          KnowledgeAnswersAnswer_MatchConfidenceLevelEnum
              .MATCH_CONFIDENCE_LEVEL_UNSPECIFIED,
          KnowledgeAnswersAnswer_MatchConfidenceLevelEnum.LOW,
          KnowledgeAnswersAnswer_MatchConfidenceLevelEnum.MEDIUM,
          KnowledgeAnswersAnswer_MatchConfidenceLevelEnum.HIGH
        ];
      }
    };

export type KnowledgeOperationMetadata_State =
    'STATE_UNSPECIFIED'|'PENDING'|'RUNNING'|'DONE';

export interface IKnowledgeOperationMetadata_StateEnum {
  readonly STATE_UNSPECIFIED: KnowledgeOperationMetadata_State;
  readonly PENDING: KnowledgeOperationMetadata_State;
  readonly RUNNING: KnowledgeOperationMetadata_State;
  readonly DONE: KnowledgeOperationMetadata_State;

  values(): Array<KnowledgeOperationMetadata_State>;
}

export const KnowledgeOperationMetadata_StateEnum:
    IKnowledgeOperationMetadata_StateEnum = {
      DONE: <KnowledgeOperationMetadata_State>'DONE',
      PENDING: <KnowledgeOperationMetadata_State>'PENDING',
      RUNNING: <KnowledgeOperationMetadata_State>'RUNNING',
      STATE_UNSPECIFIED: <KnowledgeOperationMetadata_State>'STATE_UNSPECIFIED',
      values(): Array<KnowledgeOperationMetadata_State> {
        return [
          KnowledgeOperationMetadata_StateEnum.STATE_UNSPECIFIED,
          KnowledgeOperationMetadata_StateEnum.PENDING,
          KnowledgeOperationMetadata_StateEnum.RUNNING,
          KnowledgeOperationMetadata_StateEnum.DONE
        ];
      }
    };

export type LoopingIntent_WebhookState = 'WEBHOOK_STATE_UNSPECIFIED'|
    'WEBHOOK_STATE_ENABLED'|'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING';

export interface ILoopingIntent_WebhookStateEnum {
  readonly WEBHOOK_STATE_UNSPECIFIED: LoopingIntent_WebhookState;
  readonly WEBHOOK_STATE_ENABLED: LoopingIntent_WebhookState;
  readonly WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING: LoopingIntent_WebhookState;

  values(): Array<LoopingIntent_WebhookState>;
}

export const LoopingIntent_WebhookStateEnum: ILoopingIntent_WebhookStateEnum = {
  WEBHOOK_STATE_ENABLED: <LoopingIntent_WebhookState>'WEBHOOK_STATE_ENABLED',
  WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING:
      <LoopingIntent_WebhookState>'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING',
  WEBHOOK_STATE_UNSPECIFIED:
      <LoopingIntent_WebhookState>'WEBHOOK_STATE_UNSPECIFIED',
  values(): Array<LoopingIntent_WebhookState> {
    return [
      LoopingIntent_WebhookStateEnum.WEBHOOK_STATE_UNSPECIFIED,
      LoopingIntent_WebhookStateEnum.WEBHOOK_STATE_ENABLED,
      LoopingIntent_WebhookStateEnum.WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING
    ];
  }
};

export type Match_MatchType =
    'MATCH_TYPE_UNSPECIFIED'|'INTENT'|'PARAMETER_FILLING'|'NO_MATCH';

export interface IMatch_MatchTypeEnum {
  readonly MATCH_TYPE_UNSPECIFIED: Match_MatchType;
  readonly INTENT: Match_MatchType;
  readonly PARAMETER_FILLING: Match_MatchType;
  readonly NO_MATCH: Match_MatchType;

  values(): Array<Match_MatchType>;
}

export const Match_MatchTypeEnum: IMatch_MatchTypeEnum = {
  INTENT: <Match_MatchType>'INTENT',
  MATCH_TYPE_UNSPECIFIED: <Match_MatchType>'MATCH_TYPE_UNSPECIFIED',
  NO_MATCH: <Match_MatchType>'NO_MATCH',
  PARAMETER_FILLING: <Match_MatchType>'PARAMETER_FILLING',
  values(): Array<Match_MatchType> {
    return [
      Match_MatchTypeEnum.MATCH_TYPE_UNSPECIFIED, Match_MatchTypeEnum.INTENT,
      Match_MatchTypeEnum.PARAMETER_FILLING, Match_MatchTypeEnum.NO_MATCH
    ];
  }
};

export type MaxRepromptIntent_WebhookState = 'WEBHOOK_STATE_UNSPECIFIED'|
    'WEBHOOK_STATE_ENABLED'|'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING';

export interface IMaxRepromptIntent_WebhookStateEnum {
  readonly WEBHOOK_STATE_UNSPECIFIED: MaxRepromptIntent_WebhookState;
  readonly WEBHOOK_STATE_ENABLED: MaxRepromptIntent_WebhookState;
  readonly WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING:
      MaxRepromptIntent_WebhookState;

  values(): Array<MaxRepromptIntent_WebhookState>;
}

export const MaxRepromptIntent_WebhookStateEnum:
    IMaxRepromptIntent_WebhookStateEnum = {
      WEBHOOK_STATE_ENABLED:
          <MaxRepromptIntent_WebhookState>'WEBHOOK_STATE_ENABLED',
      WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING: <
          MaxRepromptIntent_WebhookState>'WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING',
      WEBHOOK_STATE_UNSPECIFIED:
          <MaxRepromptIntent_WebhookState>'WEBHOOK_STATE_UNSPECIFIED',
      values(): Array<MaxRepromptIntent_WebhookState> {
        return [
          MaxRepromptIntent_WebhookStateEnum.WEBHOOK_STATE_UNSPECIFIED,
          MaxRepromptIntent_WebhookStateEnum.WEBHOOK_STATE_ENABLED,
          MaxRepromptIntent_WebhookStateEnum
              .WEBHOOK_STATE_ENABLED_FOR_SLOT_FILLING
        ];
      }
    };

export type MessageCluster_Role = 'CONVERSATION_ROLE_UNSPECIFIED'|'HUMAN_AGENT'|
    'AUTOMATED_AGENT'|'END_USER'|'SYSTEM'|'ROLE_UNKNOWN';

export interface IMessageCluster_RoleEnum {
  readonly CONVERSATION_ROLE_UNSPECIFIED: MessageCluster_Role;
  readonly HUMAN_AGENT: MessageCluster_Role;
  readonly AUTOMATED_AGENT: MessageCluster_Role;
  readonly END_USER: MessageCluster_Role;
  readonly SYSTEM: MessageCluster_Role;
  readonly ROLE_UNKNOWN: MessageCluster_Role;

  values(): Array<MessageCluster_Role>;
}

export const MessageCluster_RoleEnum: IMessageCluster_RoleEnum = {
  AUTOMATED_AGENT: <MessageCluster_Role>'AUTOMATED_AGENT',
  CONVERSATION_ROLE_UNSPECIFIED:
      <MessageCluster_Role>'CONVERSATION_ROLE_UNSPECIFIED',
  END_USER: <MessageCluster_Role>'END_USER',
  HUMAN_AGENT: <MessageCluster_Role>'HUMAN_AGENT',
  ROLE_UNKNOWN: <MessageCluster_Role>'ROLE_UNKNOWN',
  SYSTEM: <MessageCluster_Role>'SYSTEM',
  values(): Array<MessageCluster_Role> {
    return [
      MessageCluster_RoleEnum.CONVERSATION_ROLE_UNSPECIFIED,
      MessageCluster_RoleEnum.HUMAN_AGENT,
      MessageCluster_RoleEnum.AUTOMATED_AGENT, MessageCluster_RoleEnum.END_USER,
      MessageCluster_RoleEnum.SYSTEM, MessageCluster_RoleEnum.ROLE_UNKNOWN
    ];
  }
};

export type MessageEdge_Type = 'EDGE_TYPE_UNSPECIFIED'|'INSERT_NODE'|
    'SLOT_FILLING'|'CHOSEN'|'EXPLICIT_FOLLOWUP'|'SEQUENTIAL_RESPONSE'|
    'CANDIDATE'|'MULTI_CANDIDATE'|'HIDDEN'|'LONG_DEPENDENCY';

export interface IMessageEdge_TypeEnum {
  readonly EDGE_TYPE_UNSPECIFIED: MessageEdge_Type;
  readonly INSERT_NODE: MessageEdge_Type;
  readonly SLOT_FILLING: MessageEdge_Type;
  readonly CHOSEN: MessageEdge_Type;
  readonly EXPLICIT_FOLLOWUP: MessageEdge_Type;
  readonly SEQUENTIAL_RESPONSE: MessageEdge_Type;
  readonly CANDIDATE: MessageEdge_Type;
  readonly MULTI_CANDIDATE: MessageEdge_Type;
  readonly HIDDEN: MessageEdge_Type;
  readonly LONG_DEPENDENCY: MessageEdge_Type;

  values(): Array<MessageEdge_Type>;
}

export const MessageEdge_TypeEnum: IMessageEdge_TypeEnum = {
  CANDIDATE: <MessageEdge_Type>'CANDIDATE',
  CHOSEN: <MessageEdge_Type>'CHOSEN',
  EDGE_TYPE_UNSPECIFIED: <MessageEdge_Type>'EDGE_TYPE_UNSPECIFIED',
  EXPLICIT_FOLLOWUP: <MessageEdge_Type>'EXPLICIT_FOLLOWUP',
  HIDDEN: <MessageEdge_Type>'HIDDEN',
  INSERT_NODE: <MessageEdge_Type>'INSERT_NODE',
  LONG_DEPENDENCY: <MessageEdge_Type>'LONG_DEPENDENCY',
  MULTI_CANDIDATE: <MessageEdge_Type>'MULTI_CANDIDATE',
  SEQUENTIAL_RESPONSE: <MessageEdge_Type>'SEQUENTIAL_RESPONSE',
  SLOT_FILLING: <MessageEdge_Type>'SLOT_FILLING',
  values(): Array<MessageEdge_Type> {
    return [
      MessageEdge_TypeEnum.EDGE_TYPE_UNSPECIFIED,
      MessageEdge_TypeEnum.INSERT_NODE, MessageEdge_TypeEnum.SLOT_FILLING,
      MessageEdge_TypeEnum.CHOSEN, MessageEdge_TypeEnum.EXPLICIT_FOLLOWUP,
      MessageEdge_TypeEnum.SEQUENTIAL_RESPONSE, MessageEdge_TypeEnum.CANDIDATE,
      MessageEdge_TypeEnum.MULTI_CANDIDATE, MessageEdge_TypeEnum.HIDDEN,
      MessageEdge_TypeEnum.LONG_DEPENDENCY
    ];
  }
};

export type MessageLabel_Label = 'LABEL_UNSPECIFIED'|'AGENT_INTERNAL_RESPONSE'|
    'AGENT_LEAF_RESPONSE'|'AGENT_SLOT_FILLING_QUESTION'|
    'AGENT_FALLBACK_RESPONSE'|'USER_TRIGGERING_INTENT'|'USER_FOLLOWUP_INTENT'|
    'USER_SLOT_FILLING_ANSWER'|'AGENT_OTHER'|'USER_OTHER'|
    'SOCRATES_KEY_SENTENCE'|'SOCRATES_GREETING'|'HOBBES_POLITE'|'ENTROPY_SPAM'|
    'SUPPORT_UNLABELED'|'SUPPORT_INTRODUCTION'|'SUPPORT_WAIT_REQUEST'|
    'SUPPORT_GENERIC_MESSAGE'|'SUPPORT_DIAGNOSTIC_QUESTION'|
    'SUPPORT_RESOLUTION'|'SUPPORT_CLOSURE'|'SUPPORT_NONSENSE';

export interface IMessageLabel_LabelEnum {
  readonly LABEL_UNSPECIFIED: MessageLabel_Label;
  readonly AGENT_INTERNAL_RESPONSE: MessageLabel_Label;
  readonly AGENT_LEAF_RESPONSE: MessageLabel_Label;
  readonly AGENT_SLOT_FILLING_QUESTION: MessageLabel_Label;
  readonly AGENT_FALLBACK_RESPONSE: MessageLabel_Label;
  readonly USER_TRIGGERING_INTENT: MessageLabel_Label;
  readonly USER_FOLLOWUP_INTENT: MessageLabel_Label;
  readonly USER_SLOT_FILLING_ANSWER: MessageLabel_Label;
  readonly AGENT_OTHER: MessageLabel_Label;
  readonly USER_OTHER: MessageLabel_Label;
  readonly SOCRATES_KEY_SENTENCE: MessageLabel_Label;
  readonly SOCRATES_GREETING: MessageLabel_Label;
  readonly HOBBES_POLITE: MessageLabel_Label;
  readonly ENTROPY_SPAM: MessageLabel_Label;
  readonly SUPPORT_UNLABELED: MessageLabel_Label;
  readonly SUPPORT_INTRODUCTION: MessageLabel_Label;
  readonly SUPPORT_WAIT_REQUEST: MessageLabel_Label;
  readonly SUPPORT_GENERIC_MESSAGE: MessageLabel_Label;
  readonly SUPPORT_DIAGNOSTIC_QUESTION: MessageLabel_Label;
  readonly SUPPORT_RESOLUTION: MessageLabel_Label;
  readonly SUPPORT_CLOSURE: MessageLabel_Label;
  readonly SUPPORT_NONSENSE: MessageLabel_Label;

  values(): Array<MessageLabel_Label>;
}

export const MessageLabel_LabelEnum: IMessageLabel_LabelEnum = {
  AGENT_FALLBACK_RESPONSE: <MessageLabel_Label>'AGENT_FALLBACK_RESPONSE',
  AGENT_INTERNAL_RESPONSE: <MessageLabel_Label>'AGENT_INTERNAL_RESPONSE',
  AGENT_LEAF_RESPONSE: <MessageLabel_Label>'AGENT_LEAF_RESPONSE',
  AGENT_OTHER: <MessageLabel_Label>'AGENT_OTHER',
  AGENT_SLOT_FILLING_QUESTION:
      <MessageLabel_Label>'AGENT_SLOT_FILLING_QUESTION',
  ENTROPY_SPAM: <MessageLabel_Label>'ENTROPY_SPAM',
  HOBBES_POLITE: <MessageLabel_Label>'HOBBES_POLITE',
  LABEL_UNSPECIFIED: <MessageLabel_Label>'LABEL_UNSPECIFIED',
  SOCRATES_GREETING: <MessageLabel_Label>'SOCRATES_GREETING',
  SOCRATES_KEY_SENTENCE: <MessageLabel_Label>'SOCRATES_KEY_SENTENCE',
  SUPPORT_CLOSURE: <MessageLabel_Label>'SUPPORT_CLOSURE',
  SUPPORT_DIAGNOSTIC_QUESTION:
      <MessageLabel_Label>'SUPPORT_DIAGNOSTIC_QUESTION',
  SUPPORT_GENERIC_MESSAGE: <MessageLabel_Label>'SUPPORT_GENERIC_MESSAGE',
  SUPPORT_INTRODUCTION: <MessageLabel_Label>'SUPPORT_INTRODUCTION',
  SUPPORT_NONSENSE: <MessageLabel_Label>'SUPPORT_NONSENSE',
  SUPPORT_RESOLUTION: <MessageLabel_Label>'SUPPORT_RESOLUTION',
  SUPPORT_UNLABELED: <MessageLabel_Label>'SUPPORT_UNLABELED',
  SUPPORT_WAIT_REQUEST: <MessageLabel_Label>'SUPPORT_WAIT_REQUEST',
  USER_FOLLOWUP_INTENT: <MessageLabel_Label>'USER_FOLLOWUP_INTENT',
  USER_OTHER: <MessageLabel_Label>'USER_OTHER',
  USER_SLOT_FILLING_ANSWER: <MessageLabel_Label>'USER_SLOT_FILLING_ANSWER',
  USER_TRIGGERING_INTENT: <MessageLabel_Label>'USER_TRIGGERING_INTENT',
  values(): Array<MessageLabel_Label> {
    return [
      MessageLabel_LabelEnum.LABEL_UNSPECIFIED,
      MessageLabel_LabelEnum.AGENT_INTERNAL_RESPONSE,
      MessageLabel_LabelEnum.AGENT_LEAF_RESPONSE,
      MessageLabel_LabelEnum.AGENT_SLOT_FILLING_QUESTION,
      MessageLabel_LabelEnum.AGENT_FALLBACK_RESPONSE,
      MessageLabel_LabelEnum.USER_TRIGGERING_INTENT,
      MessageLabel_LabelEnum.USER_FOLLOWUP_INTENT,
      MessageLabel_LabelEnum.USER_SLOT_FILLING_ANSWER,
      MessageLabel_LabelEnum.AGENT_OTHER,
      MessageLabel_LabelEnum.USER_OTHER,
      MessageLabel_LabelEnum.SOCRATES_KEY_SENTENCE,
      MessageLabel_LabelEnum.SOCRATES_GREETING,
      MessageLabel_LabelEnum.HOBBES_POLITE,
      MessageLabel_LabelEnum.ENTROPY_SPAM,
      MessageLabel_LabelEnum.SUPPORT_UNLABELED,
      MessageLabel_LabelEnum.SUPPORT_INTRODUCTION,
      MessageLabel_LabelEnum.SUPPORT_WAIT_REQUEST,
      MessageLabel_LabelEnum.SUPPORT_GENERIC_MESSAGE,
      MessageLabel_LabelEnum.SUPPORT_DIAGNOSTIC_QUESTION,
      MessageLabel_LabelEnum.SUPPORT_RESOLUTION,
      MessageLabel_LabelEnum.SUPPORT_CLOSURE,
      MessageLabel_LabelEnum.SUPPORT_NONSENSE
    ];
  }
};

export type MessageNode_ConversationRole = 'CONVERSATION_ROLE_UNSPECIFIED'|
    'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER'|'SYSTEM'|'ROLE_UNKNOWN';

export interface IMessageNode_ConversationRoleEnum {
  readonly CONVERSATION_ROLE_UNSPECIFIED: MessageNode_ConversationRole;
  readonly HUMAN_AGENT: MessageNode_ConversationRole;
  readonly AUTOMATED_AGENT: MessageNode_ConversationRole;
  readonly END_USER: MessageNode_ConversationRole;
  readonly SYSTEM: MessageNode_ConversationRole;
  readonly ROLE_UNKNOWN: MessageNode_ConversationRole;

  values(): Array<MessageNode_ConversationRole>;
}

export const MessageNode_ConversationRoleEnum:
    IMessageNode_ConversationRoleEnum = {
      AUTOMATED_AGENT: <MessageNode_ConversationRole>'AUTOMATED_AGENT',
      CONVERSATION_ROLE_UNSPECIFIED:
          <MessageNode_ConversationRole>'CONVERSATION_ROLE_UNSPECIFIED',
      END_USER: <MessageNode_ConversationRole>'END_USER',
      HUMAN_AGENT: <MessageNode_ConversationRole>'HUMAN_AGENT',
      ROLE_UNKNOWN: <MessageNode_ConversationRole>'ROLE_UNKNOWN',
      SYSTEM: <MessageNode_ConversationRole>'SYSTEM',
      values(): Array<MessageNode_ConversationRole> {
        return [
          MessageNode_ConversationRoleEnum.CONVERSATION_ROLE_UNSPECIFIED,
          MessageNode_ConversationRoleEnum.HUMAN_AGENT,
          MessageNode_ConversationRoleEnum.AUTOMATED_AGENT,
          MessageNode_ConversationRoleEnum.END_USER,
          MessageNode_ConversationRoleEnum.SYSTEM,
          MessageNode_ConversationRoleEnum.ROLE_UNKNOWN
        ];
      }
    };

export type MessageReference_Role = 'CONVERSATION_ROLE_UNSPECIFIED'|
    'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER'|'SYSTEM'|'ROLE_UNKNOWN';

export interface IMessageReference_RoleEnum {
  readonly CONVERSATION_ROLE_UNSPECIFIED: MessageReference_Role;
  readonly HUMAN_AGENT: MessageReference_Role;
  readonly AUTOMATED_AGENT: MessageReference_Role;
  readonly END_USER: MessageReference_Role;
  readonly SYSTEM: MessageReference_Role;
  readonly ROLE_UNKNOWN: MessageReference_Role;

  values(): Array<MessageReference_Role>;
}

export const MessageReference_RoleEnum: IMessageReference_RoleEnum = {
  AUTOMATED_AGENT: <MessageReference_Role>'AUTOMATED_AGENT',
  CONVERSATION_ROLE_UNSPECIFIED:
      <MessageReference_Role>'CONVERSATION_ROLE_UNSPECIFIED',
  END_USER: <MessageReference_Role>'END_USER',
  HUMAN_AGENT: <MessageReference_Role>'HUMAN_AGENT',
  ROLE_UNKNOWN: <MessageReference_Role>'ROLE_UNKNOWN',
  SYSTEM: <MessageReference_Role>'SYSTEM',
  values(): Array<MessageReference_Role> {
    return [
      MessageReference_RoleEnum.CONVERSATION_ROLE_UNSPECIFIED,
      MessageReference_RoleEnum.HUMAN_AGENT,
      MessageReference_RoleEnum.AUTOMATED_AGENT,
      MessageReference_RoleEnum.END_USER, MessageReference_RoleEnum.SYSTEM,
      MessageReference_RoleEnum.ROLE_UNKNOWN
    ];
  }
};

export type Message_ParticipantRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface IMessage_ParticipantRoleEnum {
  readonly ROLE_UNSPECIFIED: Message_ParticipantRole;
  readonly HUMAN_AGENT: Message_ParticipantRole;
  readonly AUTOMATED_AGENT: Message_ParticipantRole;
  readonly END_USER: Message_ParticipantRole;

  values(): Array<Message_ParticipantRole>;
}

export const Message_ParticipantRoleEnum: IMessage_ParticipantRoleEnum = {
  AUTOMATED_AGENT: <Message_ParticipantRole>'AUTOMATED_AGENT',
  END_USER: <Message_ParticipantRole>'END_USER',
  HUMAN_AGENT: <Message_ParticipantRole>'HUMAN_AGENT',
  ROLE_UNSPECIFIED: <Message_ParticipantRole>'ROLE_UNSPECIFIED',
  values(): Array<Message_ParticipantRole> {
    return [
      Message_ParticipantRoleEnum.ROLE_UNSPECIFIED,
      Message_ParticipantRoleEnum.HUMAN_AGENT,
      Message_ParticipantRoleEnum.AUTOMATED_AGENT,
      Message_ParticipantRoleEnum.END_USER
    ];
  }
};

export type ModelEvaluationMetadata_State = 'STATE_UNSPECIFIED'|
    'PARSING_DATASET'|'DETECTING_INTENTS'|'CALCULATING_METRICS'|'SUCCESS';

export interface IModelEvaluationMetadata_StateEnum {
  readonly STATE_UNSPECIFIED: ModelEvaluationMetadata_State;
  readonly PARSING_DATASET: ModelEvaluationMetadata_State;
  readonly DETECTING_INTENTS: ModelEvaluationMetadata_State;
  readonly CALCULATING_METRICS: ModelEvaluationMetadata_State;
  readonly SUCCESS: ModelEvaluationMetadata_State;

  values(): Array<ModelEvaluationMetadata_State>;
}

export const ModelEvaluationMetadata_StateEnum:
    IModelEvaluationMetadata_StateEnum = {
      CALCULATING_METRICS: <ModelEvaluationMetadata_State>'CALCULATING_METRICS',
      DETECTING_INTENTS: <ModelEvaluationMetadata_State>'DETECTING_INTENTS',
      PARSING_DATASET: <ModelEvaluationMetadata_State>'PARSING_DATASET',
      STATE_UNSPECIFIED: <ModelEvaluationMetadata_State>'STATE_UNSPECIFIED',
      SUCCESS: <ModelEvaluationMetadata_State>'SUCCESS',
      values(): Array<ModelEvaluationMetadata_State> {
        return [
          ModelEvaluationMetadata_StateEnum.STATE_UNSPECIFIED,
          ModelEvaluationMetadata_StateEnum.PARSING_DATASET,
          ModelEvaluationMetadata_StateEnum.DETECTING_INTENTS,
          ModelEvaluationMetadata_StateEnum.CALCULATING_METRICS,
          ModelEvaluationMetadata_StateEnum.SUCCESS
        ];
      }
    };

export type NotificationConfig_MessageFormat =
    'MESSAGE_FORMAT_UNSPECIFIED'|'PROTO'|'JSON';

export interface INotificationConfig_MessageFormatEnum {
  readonly MESSAGE_FORMAT_UNSPECIFIED: NotificationConfig_MessageFormat;
  readonly PROTO: NotificationConfig_MessageFormat;
  readonly JSON: NotificationConfig_MessageFormat;

  values(): Array<NotificationConfig_MessageFormat>;
}

export const NotificationConfig_MessageFormatEnum:
    INotificationConfig_MessageFormatEnum = {
      JSON: <NotificationConfig_MessageFormat>'JSON',
      MESSAGE_FORMAT_UNSPECIFIED:
          <NotificationConfig_MessageFormat>'MESSAGE_FORMAT_UNSPECIFIED',
      PROTO: <NotificationConfig_MessageFormat>'PROTO',
      values(): Array<NotificationConfig_MessageFormat> {
        return [
          NotificationConfig_MessageFormatEnum.MESSAGE_FORMAT_UNSPECIFIED,
          NotificationConfig_MessageFormatEnum.PROTO,
          NotificationConfig_MessageFormatEnum.JSON
        ];
      }
    };

export type OutputAudioConfig_AudioEncoding =
    'OUTPUT_AUDIO_ENCODING_UNSPECIFIED'|'OUTPUT_AUDIO_ENCODING_LINEAR_16'|
    'OUTPUT_AUDIO_ENCODING_MP3'|'OUTPUT_AUDIO_ENCODING_MP3_64_KBPS'|
    'OUTPUT_AUDIO_ENCODING_OGG_OPUS'|'OUTPUT_AUDIO_ENCODING_MULAW';

export interface IOutputAudioConfig_AudioEncodingEnum {
  readonly OUTPUT_AUDIO_ENCODING_UNSPECIFIED: OutputAudioConfig_AudioEncoding;
  readonly OUTPUT_AUDIO_ENCODING_LINEAR_16: OutputAudioConfig_AudioEncoding;
  readonly OUTPUT_AUDIO_ENCODING_MP3: OutputAudioConfig_AudioEncoding;
  readonly OUTPUT_AUDIO_ENCODING_MP3_64_KBPS: OutputAudioConfig_AudioEncoding;
  readonly OUTPUT_AUDIO_ENCODING_OGG_OPUS: OutputAudioConfig_AudioEncoding;
  readonly OUTPUT_AUDIO_ENCODING_MULAW: OutputAudioConfig_AudioEncoding;

  values(): Array<OutputAudioConfig_AudioEncoding>;
}

export const OutputAudioConfig_AudioEncodingEnum:
    IOutputAudioConfig_AudioEncodingEnum = {
      OUTPUT_AUDIO_ENCODING_LINEAR_16:
          <OutputAudioConfig_AudioEncoding>'OUTPUT_AUDIO_ENCODING_LINEAR_16',
      OUTPUT_AUDIO_ENCODING_MP3:
          <OutputAudioConfig_AudioEncoding>'OUTPUT_AUDIO_ENCODING_MP3',
      OUTPUT_AUDIO_ENCODING_MP3_64_KBPS:
          <OutputAudioConfig_AudioEncoding>'OUTPUT_AUDIO_ENCODING_MP3_64_KBPS',
      OUTPUT_AUDIO_ENCODING_MULAW:
          <OutputAudioConfig_AudioEncoding>'OUTPUT_AUDIO_ENCODING_MULAW',
      OUTPUT_AUDIO_ENCODING_OGG_OPUS:
          <OutputAudioConfig_AudioEncoding>'OUTPUT_AUDIO_ENCODING_OGG_OPUS',
      OUTPUT_AUDIO_ENCODING_UNSPECIFIED:
          <OutputAudioConfig_AudioEncoding>'OUTPUT_AUDIO_ENCODING_UNSPECIFIED',
      values(): Array<OutputAudioConfig_AudioEncoding> {
        return [
          OutputAudioConfig_AudioEncodingEnum.OUTPUT_AUDIO_ENCODING_UNSPECIFIED,
          OutputAudioConfig_AudioEncodingEnum.OUTPUT_AUDIO_ENCODING_LINEAR_16,
          OutputAudioConfig_AudioEncodingEnum.OUTPUT_AUDIO_ENCODING_MP3,
          OutputAudioConfig_AudioEncodingEnum.OUTPUT_AUDIO_ENCODING_MP3_64_KBPS,
          OutputAudioConfig_AudioEncodingEnum.OUTPUT_AUDIO_ENCODING_OGG_OPUS,
          OutputAudioConfig_AudioEncodingEnum.OUTPUT_AUDIO_ENCODING_MULAW
        ];
      }
    };

export type Participant_Role =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface IParticipant_RoleEnum {
  readonly ROLE_UNSPECIFIED: Participant_Role;
  readonly HUMAN_AGENT: Participant_Role;
  readonly AUTOMATED_AGENT: Participant_Role;
  readonly END_USER: Participant_Role;

  values(): Array<Participant_Role>;
}

export const Participant_RoleEnum: IParticipant_RoleEnum = {
  AUTOMATED_AGENT: <Participant_Role>'AUTOMATED_AGENT',
  END_USER: <Participant_Role>'END_USER',
  HUMAN_AGENT: <Participant_Role>'HUMAN_AGENT',
  ROLE_UNSPECIFIED: <Participant_Role>'ROLE_UNSPECIFIED',
  values(): Array<Participant_Role> {
    return [
      Participant_RoleEnum.ROLE_UNSPECIFIED, Participant_RoleEnum.HUMAN_AGENT,
      Participant_RoleEnum.AUTOMATED_AGENT, Participant_RoleEnum.END_USER
    ];
  }
};

export type PhoneNumberLease_LifecycleState =
    'LIFECYCLE_STATE_UNSPECIFIED'|'ACTIVE'|'UPGRADED'|'EXPIRED';

export interface IPhoneNumberLease_LifecycleStateEnum {
  readonly LIFECYCLE_STATE_UNSPECIFIED: PhoneNumberLease_LifecycleState;
  readonly ACTIVE: PhoneNumberLease_LifecycleState;
  readonly UPGRADED: PhoneNumberLease_LifecycleState;
  readonly EXPIRED: PhoneNumberLease_LifecycleState;

  values(): Array<PhoneNumberLease_LifecycleState>;
}

export const PhoneNumberLease_LifecycleStateEnum:
    IPhoneNumberLease_LifecycleStateEnum = {
      ACTIVE: <PhoneNumberLease_LifecycleState>'ACTIVE',
      EXPIRED: <PhoneNumberLease_LifecycleState>'EXPIRED',
      LIFECYCLE_STATE_UNSPECIFIED:
          <PhoneNumberLease_LifecycleState>'LIFECYCLE_STATE_UNSPECIFIED',
      UPGRADED: <PhoneNumberLease_LifecycleState>'UPGRADED',
      values(): Array<PhoneNumberLease_LifecycleState> {
        return [
          PhoneNumberLease_LifecycleStateEnum.LIFECYCLE_STATE_UNSPECIFIED,
          PhoneNumberLease_LifecycleStateEnum.ACTIVE,
          PhoneNumberLease_LifecycleStateEnum.UPGRADED,
          PhoneNumberLease_LifecycleStateEnum.EXPIRED
        ];
      }
    };

export type PhoneNumberOrder_LifecycleState = 'LIFECYCLE_STATE_UNSPECIFIED'|
    'PENDING'|'IN_PROGRESS'|'COMPLETED'|'CANCELLED';

export interface IPhoneNumberOrder_LifecycleStateEnum {
  readonly LIFECYCLE_STATE_UNSPECIFIED: PhoneNumberOrder_LifecycleState;
  readonly PENDING: PhoneNumberOrder_LifecycleState;
  readonly IN_PROGRESS: PhoneNumberOrder_LifecycleState;
  readonly COMPLETED: PhoneNumberOrder_LifecycleState;
  readonly CANCELLED: PhoneNumberOrder_LifecycleState;

  values(): Array<PhoneNumberOrder_LifecycleState>;
}

export const PhoneNumberOrder_LifecycleStateEnum:
    IPhoneNumberOrder_LifecycleStateEnum = {
      CANCELLED: <PhoneNumberOrder_LifecycleState>'CANCELLED',
      COMPLETED: <PhoneNumberOrder_LifecycleState>'COMPLETED',
      IN_PROGRESS: <PhoneNumberOrder_LifecycleState>'IN_PROGRESS',
      LIFECYCLE_STATE_UNSPECIFIED:
          <PhoneNumberOrder_LifecycleState>'LIFECYCLE_STATE_UNSPECIFIED',
      PENDING: <PhoneNumberOrder_LifecycleState>'PENDING',
      values(): Array<PhoneNumberOrder_LifecycleState> {
        return [
          PhoneNumberOrder_LifecycleStateEnum.LIFECYCLE_STATE_UNSPECIFIED,
          PhoneNumberOrder_LifecycleStateEnum.PENDING,
          PhoneNumberOrder_LifecycleStateEnum.IN_PROGRESS,
          PhoneNumberOrder_LifecycleStateEnum.COMPLETED,
          PhoneNumberOrder_LifecycleStateEnum.CANCELLED
        ];
      }
    };

export type PhoneNumber_LifecycleState =
    'LIFECYCLE_STATE_UNSPECIFIED'|'ACTIVE'|'DELETE_REQUESTED';

export interface IPhoneNumber_LifecycleStateEnum {
  readonly LIFECYCLE_STATE_UNSPECIFIED: PhoneNumber_LifecycleState;
  readonly ACTIVE: PhoneNumber_LifecycleState;
  readonly DELETE_REQUESTED: PhoneNumber_LifecycleState;

  values(): Array<PhoneNumber_LifecycleState>;
}

export const PhoneNumber_LifecycleStateEnum: IPhoneNumber_LifecycleStateEnum = {
  ACTIVE: <PhoneNumber_LifecycleState>'ACTIVE',
  DELETE_REQUESTED: <PhoneNumber_LifecycleState>'DELETE_REQUESTED',
  LIFECYCLE_STATE_UNSPECIFIED:
      <PhoneNumber_LifecycleState>'LIFECYCLE_STATE_UNSPECIFIED',
  values(): Array<PhoneNumber_LifecycleState> {
    return [
      PhoneNumber_LifecycleStateEnum.LIFECYCLE_STATE_UNSPECIFIED,
      PhoneNumber_LifecycleStateEnum.ACTIVE,
      PhoneNumber_LifecycleStateEnum.DELETE_REQUESTED
    ];
  }
};

export type PreviewAogAgentResponse_ApprovalState =
    'AOG_APPROVAL_STATE_UNSPECIFIED'|'UNDER_REVIEW'|'APPROVED'|'DENIED';

export interface IPreviewAogAgentResponse_ApprovalStateEnum {
  readonly AOG_APPROVAL_STATE_UNSPECIFIED:
      PreviewAogAgentResponse_ApprovalState;
  readonly UNDER_REVIEW: PreviewAogAgentResponse_ApprovalState;
  readonly APPROVED: PreviewAogAgentResponse_ApprovalState;
  readonly DENIED: PreviewAogAgentResponse_ApprovalState;

  values(): Array<PreviewAogAgentResponse_ApprovalState>;
}

export const PreviewAogAgentResponse_ApprovalStateEnum:
    IPreviewAogAgentResponse_ApprovalStateEnum = {
      AOG_APPROVAL_STATE_UNSPECIFIED: <
          PreviewAogAgentResponse_ApprovalState>'AOG_APPROVAL_STATE_UNSPECIFIED',
      APPROVED: <PreviewAogAgentResponse_ApprovalState>'APPROVED',
      DENIED: <PreviewAogAgentResponse_ApprovalState>'DENIED',
      UNDER_REVIEW: <PreviewAogAgentResponse_ApprovalState>'UNDER_REVIEW',
      values(): Array<PreviewAogAgentResponse_ApprovalState> {
        return [
          PreviewAogAgentResponse_ApprovalStateEnum
              .AOG_APPROVAL_STATE_UNSPECIFIED,
          PreviewAogAgentResponse_ApprovalStateEnum.UNDER_REVIEW,
          PreviewAogAgentResponse_ApprovalStateEnum.APPROVED,
          PreviewAogAgentResponse_ApprovalStateEnum.DENIED
        ];
      }
    };

export type QuestionAnswerEntry_State =
    'STATE_UNSPECIFIED'|'ENABLED'|'DISABLED';

export interface IQuestionAnswerEntry_StateEnum {
  readonly STATE_UNSPECIFIED: QuestionAnswerEntry_State;
  readonly ENABLED: QuestionAnswerEntry_State;
  readonly DISABLED: QuestionAnswerEntry_State;

  values(): Array<QuestionAnswerEntry_State>;
}

export const QuestionAnswerEntry_StateEnum: IQuestionAnswerEntry_StateEnum = {
  DISABLED: <QuestionAnswerEntry_State>'DISABLED',
  ENABLED: <QuestionAnswerEntry_State>'ENABLED',
  STATE_UNSPECIFIED: <QuestionAnswerEntry_State>'STATE_UNSPECIFIED',
  values(): Array<QuestionAnswerEntry_State> {
    return [
      QuestionAnswerEntry_StateEnum.STATE_UNSPECIFIED,
      QuestionAnswerEntry_StateEnum.ENABLED,
      QuestionAnswerEntry_StateEnum.DISABLED
    ];
  }
};

export type ResearchScamGenericFeatureVectorFeatureType =
    'UNKNOWN'|'INT64'|'FLOAT'|'DOUBLE'|'STRING'|'BINARY';

export interface IResearchScamGenericFeatureVectorFeatureTypeEnum {
  readonly UNKNOWN: ResearchScamGenericFeatureVectorFeatureType;
  readonly INT64: ResearchScamGenericFeatureVectorFeatureType;
  readonly FLOAT: ResearchScamGenericFeatureVectorFeatureType;
  readonly DOUBLE: ResearchScamGenericFeatureVectorFeatureType;
  readonly STRING: ResearchScamGenericFeatureVectorFeatureType;
  readonly BINARY: ResearchScamGenericFeatureVectorFeatureType;

  values(): Array<ResearchScamGenericFeatureVectorFeatureType>;
}

export const ResearchScamGenericFeatureVectorFeatureTypeEnum:
    IResearchScamGenericFeatureVectorFeatureTypeEnum = {
      BINARY: <ResearchScamGenericFeatureVectorFeatureType>'BINARY',
      DOUBLE: <ResearchScamGenericFeatureVectorFeatureType>'DOUBLE',
      FLOAT: <ResearchScamGenericFeatureVectorFeatureType>'FLOAT',
      INT64: <ResearchScamGenericFeatureVectorFeatureType>'INT64',
      STRING: <ResearchScamGenericFeatureVectorFeatureType>'STRING',
      UNKNOWN: <ResearchScamGenericFeatureVectorFeatureType>'UNKNOWN',
      values(): Array<ResearchScamGenericFeatureVectorFeatureType> {
        return [
          ResearchScamGenericFeatureVectorFeatureTypeEnum.UNKNOWN,
          ResearchScamGenericFeatureVectorFeatureTypeEnum.INT64,
          ResearchScamGenericFeatureVectorFeatureTypeEnum.FLOAT,
          ResearchScamGenericFeatureVectorFeatureTypeEnum.DOUBLE,
          ResearchScamGenericFeatureVectorFeatureTypeEnum.STRING,
          ResearchScamGenericFeatureVectorFeatureTypeEnum.BINARY
        ];
      }
    };

export type ResearchScamGenericFeatureVectorNormType =
    'NONE'|'UNITL2NORM'|'STDGAUSSNORM'|'UNITL1NORM';

export interface IResearchScamGenericFeatureVectorNormTypeEnum {
  readonly NONE: ResearchScamGenericFeatureVectorNormType;
  readonly UNITL2NORM: ResearchScamGenericFeatureVectorNormType;
  readonly STDGAUSSNORM: ResearchScamGenericFeatureVectorNormType;
  readonly UNITL1NORM: ResearchScamGenericFeatureVectorNormType;

  values(): Array<ResearchScamGenericFeatureVectorNormType>;
}

export const ResearchScamGenericFeatureVectorNormTypeEnum:
    IResearchScamGenericFeatureVectorNormTypeEnum = {
      NONE: <ResearchScamGenericFeatureVectorNormType>'NONE',
      STDGAUSSNORM: <ResearchScamGenericFeatureVectorNormType>'STDGAUSSNORM',
      UNITL1NORM: <ResearchScamGenericFeatureVectorNormType>'UNITL1NORM',
      UNITL2NORM: <ResearchScamGenericFeatureVectorNormType>'UNITL2NORM',
      values(): Array<ResearchScamGenericFeatureVectorNormType> {
        return [
          ResearchScamGenericFeatureVectorNormTypeEnum.NONE,
          ResearchScamGenericFeatureVectorNormTypeEnum.UNITL2NORM,
          ResearchScamGenericFeatureVectorNormTypeEnum.STDGAUSSNORM,
          ResearchScamGenericFeatureVectorNormTypeEnum.UNITL1NORM
        ];
      }
    };

export type SecuritySettingsAudioExportSettings_AudioFormat =
    'AUDIO_FORMAT_UNSPECIFIED'|'MULAW'|'MP3'|'OGG'|'M4A';

export interface ISecuritySettingsAudioExportSettings_AudioFormatEnum {
  readonly AUDIO_FORMAT_UNSPECIFIED:
      SecuritySettingsAudioExportSettings_AudioFormat;
  readonly MULAW: SecuritySettingsAudioExportSettings_AudioFormat;
  readonly MP3: SecuritySettingsAudioExportSettings_AudioFormat;
  readonly OGG: SecuritySettingsAudioExportSettings_AudioFormat;
  readonly M4A: SecuritySettingsAudioExportSettings_AudioFormat;

  values(): Array<SecuritySettingsAudioExportSettings_AudioFormat>;
}

export const SecuritySettingsAudioExportSettings_AudioFormatEnum:
    ISecuritySettingsAudioExportSettings_AudioFormatEnum = {
      AUDIO_FORMAT_UNSPECIFIED: <
          SecuritySettingsAudioExportSettings_AudioFormat>'AUDIO_FORMAT_UNSPECIFIED',
      M4A: <SecuritySettingsAudioExportSettings_AudioFormat>'M4A',
      MP3: <SecuritySettingsAudioExportSettings_AudioFormat>'MP3',
      MULAW: <SecuritySettingsAudioExportSettings_AudioFormat>'MULAW',
      OGG: <SecuritySettingsAudioExportSettings_AudioFormat>'OGG',
      values(): Array<SecuritySettingsAudioExportSettings_AudioFormat> {
        return [
          SecuritySettingsAudioExportSettings_AudioFormatEnum
              .AUDIO_FORMAT_UNSPECIFIED,
          SecuritySettingsAudioExportSettings_AudioFormatEnum.MULAW,
          SecuritySettingsAudioExportSettings_AudioFormatEnum.MP3,
          SecuritySettingsAudioExportSettings_AudioFormatEnum.OGG,
          SecuritySettingsAudioExportSettings_AudioFormatEnum.M4A
        ];
      }
    };

export type SecuritySettings_PurgeDataTypes =
    'PURGE_DATA_TYPE_UNSPECIFIED'|'DIALOGFLOW_HISTORY';

export interface ISecuritySettings_PurgeDataTypesEnum {
  readonly PURGE_DATA_TYPE_UNSPECIFIED: SecuritySettings_PurgeDataTypes;
  readonly DIALOGFLOW_HISTORY: SecuritySettings_PurgeDataTypes;

  values(): Array<SecuritySettings_PurgeDataTypes>;
}

export const SecuritySettings_PurgeDataTypesEnum:
    ISecuritySettings_PurgeDataTypesEnum = {
      DIALOGFLOW_HISTORY: <SecuritySettings_PurgeDataTypes>'DIALOGFLOW_HISTORY',
      PURGE_DATA_TYPE_UNSPECIFIED:
          <SecuritySettings_PurgeDataTypes>'PURGE_DATA_TYPE_UNSPECIFIED',
      values(): Array<SecuritySettings_PurgeDataTypes> {
        return [
          SecuritySettings_PurgeDataTypesEnum.PURGE_DATA_TYPE_UNSPECIFIED,
          SecuritySettings_PurgeDataTypesEnum.DIALOGFLOW_HISTORY
        ];
      }
    };

export type SecuritySettings_RedactionInfoTypes =
    'REDACTION_INFO_TYPE_UNSPECIFIED'|'USER_QUERY';

export interface ISecuritySettings_RedactionInfoTypesEnum {
  readonly REDACTION_INFO_TYPE_UNSPECIFIED: SecuritySettings_RedactionInfoTypes;
  readonly USER_QUERY: SecuritySettings_RedactionInfoTypes;

  values(): Array<SecuritySettings_RedactionInfoTypes>;
}

export const SecuritySettings_RedactionInfoTypesEnum:
    ISecuritySettings_RedactionInfoTypesEnum = {
      REDACTION_INFO_TYPE_UNSPECIFIED: <
          SecuritySettings_RedactionInfoTypes>'REDACTION_INFO_TYPE_UNSPECIFIED',
      USER_QUERY: <SecuritySettings_RedactionInfoTypes>'USER_QUERY',
      values(): Array<SecuritySettings_RedactionInfoTypes> {
        return [
          SecuritySettings_RedactionInfoTypesEnum
              .REDACTION_INFO_TYPE_UNSPECIFIED,
          SecuritySettings_RedactionInfoTypesEnum.USER_QUERY
        ];
      }
    };

export type SecuritySettings_RedactionScope = 'REDACTION_SCOPE_UNSPECIFIED'|
    'REDACT_PERMANENT_STORAGE'|'REDACT_DISK_STORAGE';

export interface ISecuritySettings_RedactionScopeEnum {
  readonly REDACTION_SCOPE_UNSPECIFIED: SecuritySettings_RedactionScope;
  readonly REDACT_PERMANENT_STORAGE: SecuritySettings_RedactionScope;
  readonly REDACT_DISK_STORAGE: SecuritySettings_RedactionScope;

  values(): Array<SecuritySettings_RedactionScope>;
}

export const SecuritySettings_RedactionScopeEnum:
    ISecuritySettings_RedactionScopeEnum = {
      REDACTION_SCOPE_UNSPECIFIED:
          <SecuritySettings_RedactionScope>'REDACTION_SCOPE_UNSPECIFIED',
      REDACT_DISK_STORAGE:
          <SecuritySettings_RedactionScope>'REDACT_DISK_STORAGE',
      REDACT_PERMANENT_STORAGE:
          <SecuritySettings_RedactionScope>'REDACT_PERMANENT_STORAGE',
      values(): Array<SecuritySettings_RedactionScope> {
        return [
          SecuritySettings_RedactionScopeEnum.REDACTION_SCOPE_UNSPECIFIED,
          SecuritySettings_RedactionScopeEnum.REDACT_PERMANENT_STORAGE,
          SecuritySettings_RedactionScopeEnum.REDACT_DISK_STORAGE
        ];
      }
    };

export type SecuritySettings_RedactionStrategy =
    'REDACTION_STRATEGY_UNSPECIFIED'|'REDACT_WITH_SERVICE'|'REMOVE_USER_QUERY';

export interface ISecuritySettings_RedactionStrategyEnum {
  readonly REDACTION_STRATEGY_UNSPECIFIED: SecuritySettings_RedactionStrategy;
  readonly REDACT_WITH_SERVICE: SecuritySettings_RedactionStrategy;
  readonly REMOVE_USER_QUERY: SecuritySettings_RedactionStrategy;

  values(): Array<SecuritySettings_RedactionStrategy>;
}

export const SecuritySettings_RedactionStrategyEnum:
    ISecuritySettings_RedactionStrategyEnum = {
      REDACTION_STRATEGY_UNSPECIFIED:
          <SecuritySettings_RedactionStrategy>'REDACTION_STRATEGY_UNSPECIFIED',
      REDACT_WITH_SERVICE:
          <SecuritySettings_RedactionStrategy>'REDACT_WITH_SERVICE',
      REMOVE_USER_QUERY:
          <SecuritySettings_RedactionStrategy>'REMOVE_USER_QUERY',
      values(): Array<SecuritySettings_RedactionStrategy> {
        return [
          SecuritySettings_RedactionStrategyEnum.REDACTION_STRATEGY_UNSPECIFIED,
          SecuritySettings_RedactionStrategyEnum.REDACT_WITH_SERVICE,
          SecuritySettings_RedactionStrategyEnum.REMOVE_USER_QUERY
        ];
      }
    };

export type SecuritySettings_RetentionStrategy =
    'RETENTION_STRATEGY_UNSPECIFIED'|'REMOVE_AFTER_CONVERSATION';

export interface ISecuritySettings_RetentionStrategyEnum {
  readonly RETENTION_STRATEGY_UNSPECIFIED: SecuritySettings_RetentionStrategy;
  readonly REMOVE_AFTER_CONVERSATION: SecuritySettings_RetentionStrategy;

  values(): Array<SecuritySettings_RetentionStrategy>;
}

export const SecuritySettings_RetentionStrategyEnum:
    ISecuritySettings_RetentionStrategyEnum = {
      REMOVE_AFTER_CONVERSATION:
          <SecuritySettings_RetentionStrategy>'REMOVE_AFTER_CONVERSATION',
      RETENTION_STRATEGY_UNSPECIFIED:
          <SecuritySettings_RetentionStrategy>'RETENTION_STRATEGY_UNSPECIFIED',
      values(): Array<SecuritySettings_RetentionStrategy> {
        return [
          SecuritySettings_RetentionStrategyEnum.RETENTION_STRATEGY_UNSPECIFIED,
          SecuritySettings_RetentionStrategyEnum.REMOVE_AFTER_CONVERSATION
        ];
      }
    };

export type SessionEntityType_EntityOverrideMode =
    'ENTITY_OVERRIDE_MODE_UNSPECIFIED'|'ENTITY_OVERRIDE_MODE_OVERRIDE'|
    'ENTITY_OVERRIDE_MODE_SUPPLEMENT';

export interface ISessionEntityType_EntityOverrideModeEnum {
  readonly ENTITY_OVERRIDE_MODE_UNSPECIFIED:
      SessionEntityType_EntityOverrideMode;
  readonly ENTITY_OVERRIDE_MODE_OVERRIDE: SessionEntityType_EntityOverrideMode;
  readonly ENTITY_OVERRIDE_MODE_SUPPLEMENT:
      SessionEntityType_EntityOverrideMode;

  values(): Array<SessionEntityType_EntityOverrideMode>;
}

export const SessionEntityType_EntityOverrideModeEnum:
    ISessionEntityType_EntityOverrideModeEnum = {
      ENTITY_OVERRIDE_MODE_OVERRIDE:
          <SessionEntityType_EntityOverrideMode>'ENTITY_OVERRIDE_MODE_OVERRIDE',
      ENTITY_OVERRIDE_MODE_SUPPLEMENT: <
          SessionEntityType_EntityOverrideMode>'ENTITY_OVERRIDE_MODE_SUPPLEMENT',
      ENTITY_OVERRIDE_MODE_UNSPECIFIED: <
          SessionEntityType_EntityOverrideMode>'ENTITY_OVERRIDE_MODE_UNSPECIFIED',
      values(): Array<SessionEntityType_EntityOverrideMode> {
        return [
          SessionEntityType_EntityOverrideModeEnum
              .ENTITY_OVERRIDE_MODE_UNSPECIFIED,
          SessionEntityType_EntityOverrideModeEnum
              .ENTITY_OVERRIDE_MODE_OVERRIDE,
          SessionEntityType_EntityOverrideModeEnum
              .ENTITY_OVERRIDE_MODE_SUPPLEMENT
        ];
      }
    };

export type SetSuggestionFeatureConfigOperationMetadata_ParticipantRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface ISetSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum {
  readonly ROLE_UNSPECIFIED:
      SetSuggestionFeatureConfigOperationMetadata_ParticipantRole;
  readonly HUMAN_AGENT:
      SetSuggestionFeatureConfigOperationMetadata_ParticipantRole;
  readonly AUTOMATED_AGENT:
      SetSuggestionFeatureConfigOperationMetadata_ParticipantRole;
  readonly END_USER:
      SetSuggestionFeatureConfigOperationMetadata_ParticipantRole;

  values(): Array<SetSuggestionFeatureConfigOperationMetadata_ParticipantRole>;
}

export const SetSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum:
    ISetSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum = {
      AUTOMATED_AGENT: <
          SetSuggestionFeatureConfigOperationMetadata_ParticipantRole>'AUTOMATED_AGENT',
      END_USER: <
          SetSuggestionFeatureConfigOperationMetadata_ParticipantRole>'END_USER',
      HUMAN_AGENT: <
          SetSuggestionFeatureConfigOperationMetadata_ParticipantRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED: <
          SetSuggestionFeatureConfigOperationMetadata_ParticipantRole>'ROLE_UNSPECIFIED',
      values():
          Array<SetSuggestionFeatureConfigOperationMetadata_ParticipantRole> {
            return [
              SetSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum
                  .ROLE_UNSPECIFIED,
              SetSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum
                  .HUMAN_AGENT,
              SetSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum
                  .AUTOMATED_AGENT,
              SetSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum
                  .END_USER
            ];
          }
    };

export type SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType =
    'TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION'|'FAQ'|'SMART_REPLY'|
    'DIALOGFLOW_ASSIST'|'SMART_COMPOSE'|'ISSUE_SMART_COMPOSE'|
    'SPELL_GRAMMAR_CORRECTION'|'CONVERSATION_SUMMARIZATION'|'KEY_MOMENT'|
    'ARTICLE_SEARCH'|'CONVERSATION_GUIDANCE'|'ARTICLE_SUGGESTION_VOICE'|
    'CONVERSATION_SUMMARIZATION_VOICE';

export interface ISetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum {
  readonly TYPE_UNSPECIFIED:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly ARTICLE_SUGGESTION:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly FAQ:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly SMART_REPLY:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly DIALOGFLOW_ASSIST:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly SMART_COMPOSE:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly ISSUE_SMART_COMPOSE:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly SPELL_GRAMMAR_CORRECTION:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly CONVERSATION_SUMMARIZATION:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly KEY_MOMENT:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly ARTICLE_SEARCH:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly CONVERSATION_GUIDANCE:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly ARTICLE_SUGGESTION_VOICE:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;
  readonly CONVERSATION_SUMMARIZATION_VOICE:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType;

  values():
      Array<SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>;
}

export const SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum:
    ISetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum = {
      ARTICLE_SEARCH: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'ARTICLE_SEARCH',
      ARTICLE_SUGGESTION: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'ARTICLE_SUGGESTION',
      ARTICLE_SUGGESTION_VOICE: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'ARTICLE_SUGGESTION_VOICE',
      CONVERSATION_GUIDANCE: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'CONVERSATION_GUIDANCE',
      CONVERSATION_SUMMARIZATION: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'CONVERSATION_SUMMARIZATION',
      CONVERSATION_SUMMARIZATION_VOICE: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'CONVERSATION_SUMMARIZATION_VOICE',
      DIALOGFLOW_ASSIST: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'DIALOGFLOW_ASSIST',
      FAQ: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'FAQ',
      ISSUE_SMART_COMPOSE: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'ISSUE_SMART_COMPOSE',
      KEY_MOMENT: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'KEY_MOMENT',
      SMART_COMPOSE: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'SMART_COMPOSE',
      SMART_REPLY: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'SMART_REPLY',
      SPELL_GRAMMAR_CORRECTION: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'SPELL_GRAMMAR_CORRECTION',
      TYPE_UNSPECIFIED: <
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType>'TYPE_UNSPECIFIED',
      values(): Array<
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType> {
        return [
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .TYPE_UNSPECIFIED,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .ARTICLE_SUGGESTION,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .FAQ,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .SMART_REPLY,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .DIALOGFLOW_ASSIST,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .SMART_COMPOSE,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .ISSUE_SMART_COMPOSE,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .SPELL_GRAMMAR_CORRECTION,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .CONVERSATION_SUMMARIZATION,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .KEY_MOMENT,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .ARTICLE_SEARCH,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .CONVERSATION_GUIDANCE,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .ARTICLE_SUGGESTION_VOICE,
          SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
              .CONVERSATION_SUMMARIZATION_VOICE
        ];
      }
    };

export type SetSuggestionFeatureConfigRequest_ParticipantRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface ISetSuggestionFeatureConfigRequest_ParticipantRoleEnum {
  readonly ROLE_UNSPECIFIED: SetSuggestionFeatureConfigRequest_ParticipantRole;
  readonly HUMAN_AGENT: SetSuggestionFeatureConfigRequest_ParticipantRole;
  readonly AUTOMATED_AGENT: SetSuggestionFeatureConfigRequest_ParticipantRole;
  readonly END_USER: SetSuggestionFeatureConfigRequest_ParticipantRole;

  values(): Array<SetSuggestionFeatureConfigRequest_ParticipantRole>;
}

export const SetSuggestionFeatureConfigRequest_ParticipantRoleEnum:
    ISetSuggestionFeatureConfigRequest_ParticipantRoleEnum = {
      AUTOMATED_AGENT:
          <SetSuggestionFeatureConfigRequest_ParticipantRole>'AUTOMATED_AGENT',
      END_USER: <SetSuggestionFeatureConfigRequest_ParticipantRole>'END_USER',
      HUMAN_AGENT:
          <SetSuggestionFeatureConfigRequest_ParticipantRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED:
          <SetSuggestionFeatureConfigRequest_ParticipantRole>'ROLE_UNSPECIFIED',
      values(): Array<SetSuggestionFeatureConfigRequest_ParticipantRole> {
        return [
          SetSuggestionFeatureConfigRequest_ParticipantRoleEnum
              .ROLE_UNSPECIFIED,
          SetSuggestionFeatureConfigRequest_ParticipantRoleEnum.HUMAN_AGENT,
          SetSuggestionFeatureConfigRequest_ParticipantRoleEnum.AUTOMATED_AGENT,
          SetSuggestionFeatureConfigRequest_ParticipantRoleEnum.END_USER
        ];
      }
    };

export type SignupIntegrationRequest_Partner = 'PARTNER_UNSPECIFIED'|
    'SIGNALWIRE'|'VOXIMPLANT'|'GENESYS'|'AVAYA'|'AUDIO_CODES'|'TWILIO'|
    'INTRADO'|'TEST_PARTNER'|'AVAYA_TEST'|'AUDIO_CODES_TEST'|'TWILIO_TEST';

export interface ISignupIntegrationRequest_PartnerEnum {
  readonly PARTNER_UNSPECIFIED: SignupIntegrationRequest_Partner;
  readonly SIGNALWIRE: SignupIntegrationRequest_Partner;
  readonly VOXIMPLANT: SignupIntegrationRequest_Partner;
  readonly GENESYS: SignupIntegrationRequest_Partner;
  readonly AVAYA: SignupIntegrationRequest_Partner;
  readonly AUDIO_CODES: SignupIntegrationRequest_Partner;
  readonly TWILIO: SignupIntegrationRequest_Partner;
  readonly INTRADO: SignupIntegrationRequest_Partner;
  readonly TEST_PARTNER: SignupIntegrationRequest_Partner;
  readonly AVAYA_TEST: SignupIntegrationRequest_Partner;
  readonly AUDIO_CODES_TEST: SignupIntegrationRequest_Partner;
  readonly TWILIO_TEST: SignupIntegrationRequest_Partner;

  values(): Array<SignupIntegrationRequest_Partner>;
}

export const SignupIntegrationRequest_PartnerEnum:
    ISignupIntegrationRequest_PartnerEnum = {
      AUDIO_CODES: <SignupIntegrationRequest_Partner>'AUDIO_CODES',
      AUDIO_CODES_TEST: <SignupIntegrationRequest_Partner>'AUDIO_CODES_TEST',
      AVAYA: <SignupIntegrationRequest_Partner>'AVAYA',
      AVAYA_TEST: <SignupIntegrationRequest_Partner>'AVAYA_TEST',
      GENESYS: <SignupIntegrationRequest_Partner>'GENESYS',
      INTRADO: <SignupIntegrationRequest_Partner>'INTRADO',
      PARTNER_UNSPECIFIED:
          <SignupIntegrationRequest_Partner>'PARTNER_UNSPECIFIED',
      SIGNALWIRE: <SignupIntegrationRequest_Partner>'SIGNALWIRE',
      TEST_PARTNER: <SignupIntegrationRequest_Partner>'TEST_PARTNER',
      TWILIO: <SignupIntegrationRequest_Partner>'TWILIO',
      TWILIO_TEST: <SignupIntegrationRequest_Partner>'TWILIO_TEST',
      VOXIMPLANT: <SignupIntegrationRequest_Partner>'VOXIMPLANT',
      values(): Array<SignupIntegrationRequest_Partner> {
        return [
          SignupIntegrationRequest_PartnerEnum.PARTNER_UNSPECIFIED,
          SignupIntegrationRequest_PartnerEnum.SIGNALWIRE,
          SignupIntegrationRequest_PartnerEnum.VOXIMPLANT,
          SignupIntegrationRequest_PartnerEnum.GENESYS,
          SignupIntegrationRequest_PartnerEnum.AVAYA,
          SignupIntegrationRequest_PartnerEnum.AUDIO_CODES,
          SignupIntegrationRequest_PartnerEnum.TWILIO,
          SignupIntegrationRequest_PartnerEnum.INTRADO,
          SignupIntegrationRequest_PartnerEnum.TEST_PARTNER,
          SignupIntegrationRequest_PartnerEnum.AVAYA_TEST,
          SignupIntegrationRequest_PartnerEnum.AUDIO_CODES_TEST,
          SignupIntegrationRequest_PartnerEnum.TWILIO_TEST
        ];
      }
    };

export type SmartComposeModelMetadata_TargetRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface ISmartComposeModelMetadata_TargetRoleEnum {
  readonly ROLE_UNSPECIFIED: SmartComposeModelMetadata_TargetRole;
  readonly HUMAN_AGENT: SmartComposeModelMetadata_TargetRole;
  readonly AUTOMATED_AGENT: SmartComposeModelMetadata_TargetRole;
  readonly END_USER: SmartComposeModelMetadata_TargetRole;

  values(): Array<SmartComposeModelMetadata_TargetRole>;
}

export const SmartComposeModelMetadata_TargetRoleEnum:
    ISmartComposeModelMetadata_TargetRoleEnum = {
      AUTOMATED_AGENT: <SmartComposeModelMetadata_TargetRole>'AUTOMATED_AGENT',
      END_USER: <SmartComposeModelMetadata_TargetRole>'END_USER',
      HUMAN_AGENT: <SmartComposeModelMetadata_TargetRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED:
          <SmartComposeModelMetadata_TargetRole>'ROLE_UNSPECIFIED',
      values(): Array<SmartComposeModelMetadata_TargetRole> {
        return [
          SmartComposeModelMetadata_TargetRoleEnum.ROLE_UNSPECIFIED,
          SmartComposeModelMetadata_TargetRoleEnum.HUMAN_AGENT,
          SmartComposeModelMetadata_TargetRoleEnum.AUTOMATED_AGENT,
          SmartComposeModelMetadata_TargetRoleEnum.END_USER
        ];
      }
    };

export type SmartComposeModelMetadata_TrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'CONVERSATION_GUIDANCE_MODEL'|'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface ISmartComposeModelMetadata_TrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED: SmartComposeModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      SmartComposeModelMetadata_TrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      SmartComposeModelMetadata_TrainingModelType;
  readonly SMART_REPLY_BERT_MODEL: SmartComposeModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      SmartComposeModelMetadata_TrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      SmartComposeModelMetadata_TrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      SmartComposeModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      SmartComposeModelMetadata_TrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      SmartComposeModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_MODEL: SmartComposeModelMetadata_TrainingModelType;
  readonly KEY_MOMENT_MODEL: SmartComposeModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      SmartComposeModelMetadata_TrainingModelType;
  readonly CONVERSATION_GUIDANCE_MODEL:
      SmartComposeModelMetadata_TrainingModelType;
  readonly CUSTOM_HEAD_INTENT_SUGGESTION_MODEL:
      SmartComposeModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_V2_MODEL: SmartComposeModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      SmartComposeModelMetadata_TrainingModelType;

  values(): Array<SmartComposeModelMetadata_TrainingModelType>;
}

export const SmartComposeModelMetadata_TrainingModelTypeEnum:
    ISmartComposeModelMetadata_TrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      CONVERSATION_GUIDANCE_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'CONVERSATION_GUIDANCE_MODEL',
      CUSTOM_HEAD_INTENT_SUGGESTION_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL:
          <SmartComposeModelMetadata_TrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED:
          <SmartComposeModelMetadata_TrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL:
          <SmartComposeModelMetadata_TrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          SmartComposeModelMetadata_TrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL:
          <SmartComposeModelMetadata_TrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL:
          <SmartComposeModelMetadata_TrainingModelType>'SUMMARIZATION_V2_MODEL',
      values(): Array<SmartComposeModelMetadata_TrainingModelType> {
        return [
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .MODEL_TYPE_UNSPECIFIED,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_GBT_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_DUAL_ENCODER_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_BERT_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .SMART_COMPOSE_DUAL_ENCODER_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_GENERATIVE_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum.SUMMARIZATION_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum.KEY_MOMENT_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .CONVERSATION_GUIDANCE_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .CUSTOM_HEAD_INTENT_SUGGESTION_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .SUMMARIZATION_V2_MODEL,
          SmartComposeModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
        ];
      }
    };

export type SmartMessagingEntryInfo_CreationMethod =
    'CREATION_METHOD_UNSPECIFIED'|'AUTOMATIC'|'MANUAL';

export interface ISmartMessagingEntryInfo_CreationMethodEnum {
  readonly CREATION_METHOD_UNSPECIFIED: SmartMessagingEntryInfo_CreationMethod;
  readonly AUTOMATIC: SmartMessagingEntryInfo_CreationMethod;
  readonly MANUAL: SmartMessagingEntryInfo_CreationMethod;

  values(): Array<SmartMessagingEntryInfo_CreationMethod>;
}

export const SmartMessagingEntryInfo_CreationMethodEnum:
    ISmartMessagingEntryInfo_CreationMethodEnum = {
      AUTOMATIC: <SmartMessagingEntryInfo_CreationMethod>'AUTOMATIC',
      CREATION_METHOD_UNSPECIFIED:
          <SmartMessagingEntryInfo_CreationMethod>'CREATION_METHOD_UNSPECIFIED',
      MANUAL: <SmartMessagingEntryInfo_CreationMethod>'MANUAL',
      values(): Array<SmartMessagingEntryInfo_CreationMethod> {
        return [
          SmartMessagingEntryInfo_CreationMethodEnum
              .CREATION_METHOD_UNSPECIFIED,
          SmartMessagingEntryInfo_CreationMethodEnum.AUTOMATIC,
          SmartMessagingEntryInfo_CreationMethodEnum.MANUAL
        ];
      }
    };

export type SmartMessagingEntry_State =
    'SMART_MESSAGING_ENTRY_STATE_UNSPECIFIED'|'ENABLED'|'DISABLED';

export interface ISmartMessagingEntry_StateEnum {
  readonly SMART_MESSAGING_ENTRY_STATE_UNSPECIFIED: SmartMessagingEntry_State;
  readonly ENABLED: SmartMessagingEntry_State;
  readonly DISABLED: SmartMessagingEntry_State;

  values(): Array<SmartMessagingEntry_State>;
}

export const SmartMessagingEntry_StateEnum: ISmartMessagingEntry_StateEnum = {
  DISABLED: <SmartMessagingEntry_State>'DISABLED',
  ENABLED: <SmartMessagingEntry_State>'ENABLED',
  SMART_MESSAGING_ENTRY_STATE_UNSPECIFIED:
      <SmartMessagingEntry_State>'SMART_MESSAGING_ENTRY_STATE_UNSPECIFIED',
  values(): Array<SmartMessagingEntry_State> {
    return [
      SmartMessagingEntry_StateEnum.SMART_MESSAGING_ENTRY_STATE_UNSPECIFIED,
      SmartMessagingEntry_StateEnum.ENABLED,
      SmartMessagingEntry_StateEnum.DISABLED
    ];
  }
};

export type SmartReplyModelMetadata_TargetRole =
    'ROLE_UNSPECIFIED'|'HUMAN_AGENT'|'AUTOMATED_AGENT'|'END_USER';

export interface ISmartReplyModelMetadata_TargetRoleEnum {
  readonly ROLE_UNSPECIFIED: SmartReplyModelMetadata_TargetRole;
  readonly HUMAN_AGENT: SmartReplyModelMetadata_TargetRole;
  readonly AUTOMATED_AGENT: SmartReplyModelMetadata_TargetRole;
  readonly END_USER: SmartReplyModelMetadata_TargetRole;

  values(): Array<SmartReplyModelMetadata_TargetRole>;
}

export const SmartReplyModelMetadata_TargetRoleEnum:
    ISmartReplyModelMetadata_TargetRoleEnum = {
      AUTOMATED_AGENT: <SmartReplyModelMetadata_TargetRole>'AUTOMATED_AGENT',
      END_USER: <SmartReplyModelMetadata_TargetRole>'END_USER',
      HUMAN_AGENT: <SmartReplyModelMetadata_TargetRole>'HUMAN_AGENT',
      ROLE_UNSPECIFIED: <SmartReplyModelMetadata_TargetRole>'ROLE_UNSPECIFIED',
      values(): Array<SmartReplyModelMetadata_TargetRole> {
        return [
          SmartReplyModelMetadata_TargetRoleEnum.ROLE_UNSPECIFIED,
          SmartReplyModelMetadata_TargetRoleEnum.HUMAN_AGENT,
          SmartReplyModelMetadata_TargetRoleEnum.AUTOMATED_AGENT,
          SmartReplyModelMetadata_TargetRoleEnum.END_USER
        ];
      }
    };

export type SmartReplyModelMetadata_TrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'CONVERSATION_GUIDANCE_MODEL'|'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface ISmartReplyModelMetadata_TrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED: SmartReplyModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      SmartReplyModelMetadata_TrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      SmartReplyModelMetadata_TrainingModelType;
  readonly SMART_REPLY_BERT_MODEL: SmartReplyModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      SmartReplyModelMetadata_TrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      SmartReplyModelMetadata_TrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      SmartReplyModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      SmartReplyModelMetadata_TrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      SmartReplyModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_MODEL: SmartReplyModelMetadata_TrainingModelType;
  readonly KEY_MOMENT_MODEL: SmartReplyModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      SmartReplyModelMetadata_TrainingModelType;
  readonly CONVERSATION_GUIDANCE_MODEL:
      SmartReplyModelMetadata_TrainingModelType;
  readonly CUSTOM_HEAD_INTENT_SUGGESTION_MODEL:
      SmartReplyModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_V2_MODEL: SmartReplyModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      SmartReplyModelMetadata_TrainingModelType;

  values(): Array<SmartReplyModelMetadata_TrainingModelType>;
}

export const SmartReplyModelMetadata_TrainingModelTypeEnum:
    ISmartReplyModelMetadata_TrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      CONVERSATION_GUIDANCE_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'CONVERSATION_GUIDANCE_MODEL',
      CUSTOM_HEAD_INTENT_SUGGESTION_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL:
          <SmartReplyModelMetadata_TrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED:
          <SmartReplyModelMetadata_TrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL:
          <SmartReplyModelMetadata_TrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          SmartReplyModelMetadata_TrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL:
          <SmartReplyModelMetadata_TrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL:
          <SmartReplyModelMetadata_TrainingModelType>'SUMMARIZATION_V2_MODEL',
      values(): Array<SmartReplyModelMetadata_TrainingModelType> {
        return [
          SmartReplyModelMetadata_TrainingModelTypeEnum.MODEL_TYPE_UNSPECIFIED,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_GBT_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_DUAL_ENCODER_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum.SMART_REPLY_BERT_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .SMART_COMPOSE_DUAL_ENCODER_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_GENERATIVE_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum.SUMMARIZATION_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum.KEY_MOMENT_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .CONVERSATION_GUIDANCE_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .CUSTOM_HEAD_INTENT_SUGGESTION_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum.SUMMARIZATION_V2_MODEL,
          SmartReplyModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
        ];
      }
    };

export type Specialist_Role = 'ROLE_UNSPECIFIED'|'WORKER'|'MANAGER';

export interface ISpecialist_RoleEnum {
  readonly ROLE_UNSPECIFIED: Specialist_Role;
  readonly WORKER: Specialist_Role;
  readonly MANAGER: Specialist_Role;

  values(): Array<Specialist_Role>;
}

export const Specialist_RoleEnum: ISpecialist_RoleEnum = {
  MANAGER: <Specialist_Role>'MANAGER',
  ROLE_UNSPECIFIED: <Specialist_Role>'ROLE_UNSPECIFIED',
  WORKER: <Specialist_Role>'WORKER',
  values(): Array<Specialist_Role> {
    return [
      Specialist_RoleEnum.ROLE_UNSPECIFIED, Specialist_RoleEnum.WORKER,
      Specialist_RoleEnum.MANAGER
    ];
  }
};

export type SpeechToTextConfig_SpeechModelVariant =
    'SPEECH_MODEL_VARIANT_UNSPECIFIED'|'USE_BEST_AVAILABLE'|'USE_STANDARD'|
    'USE_ENHANCED';

export interface ISpeechToTextConfig_SpeechModelVariantEnum {
  readonly SPEECH_MODEL_VARIANT_UNSPECIFIED:
      SpeechToTextConfig_SpeechModelVariant;
  readonly USE_BEST_AVAILABLE: SpeechToTextConfig_SpeechModelVariant;
  readonly USE_STANDARD: SpeechToTextConfig_SpeechModelVariant;
  readonly USE_ENHANCED: SpeechToTextConfig_SpeechModelVariant;

  values(): Array<SpeechToTextConfig_SpeechModelVariant>;
}

export const SpeechToTextConfig_SpeechModelVariantEnum:
    ISpeechToTextConfig_SpeechModelVariantEnum = {
      SPEECH_MODEL_VARIANT_UNSPECIFIED: <
          SpeechToTextConfig_SpeechModelVariant>'SPEECH_MODEL_VARIANT_UNSPECIFIED',
      USE_BEST_AVAILABLE:
          <SpeechToTextConfig_SpeechModelVariant>'USE_BEST_AVAILABLE',
      USE_ENHANCED: <SpeechToTextConfig_SpeechModelVariant>'USE_ENHANCED',
      USE_STANDARD: <SpeechToTextConfig_SpeechModelVariant>'USE_STANDARD',
      values(): Array<SpeechToTextConfig_SpeechModelVariant> {
        return [
          SpeechToTextConfig_SpeechModelVariantEnum
              .SPEECH_MODEL_VARIANT_UNSPECIFIED,
          SpeechToTextConfig_SpeechModelVariantEnum.USE_BEST_AVAILABLE,
          SpeechToTextConfig_SpeechModelVariantEnum.USE_STANDARD,
          SpeechToTextConfig_SpeechModelVariantEnum.USE_ENHANCED
        ];
      }
    };

export type SpellGrammarCorrectionAnswer_Type =
    'TYPE_UNSPECIFIED'|'DEFAULT_SPELL_CORRECTION'|'DEFAULT_GRAMMAR_CORRECTION'|
    'CUSTOMIZED_GRAMMAR_CORRECTION';

export interface ISpellGrammarCorrectionAnswer_TypeEnum {
  readonly TYPE_UNSPECIFIED: SpellGrammarCorrectionAnswer_Type;
  readonly DEFAULT_SPELL_CORRECTION: SpellGrammarCorrectionAnswer_Type;
  readonly DEFAULT_GRAMMAR_CORRECTION: SpellGrammarCorrectionAnswer_Type;
  readonly CUSTOMIZED_GRAMMAR_CORRECTION: SpellGrammarCorrectionAnswer_Type;

  values(): Array<SpellGrammarCorrectionAnswer_Type>;
}

export const SpellGrammarCorrectionAnswer_TypeEnum:
    ISpellGrammarCorrectionAnswer_TypeEnum = {
      CUSTOMIZED_GRAMMAR_CORRECTION:
          <SpellGrammarCorrectionAnswer_Type>'CUSTOMIZED_GRAMMAR_CORRECTION',
      DEFAULT_GRAMMAR_CORRECTION:
          <SpellGrammarCorrectionAnswer_Type>'DEFAULT_GRAMMAR_CORRECTION',
      DEFAULT_SPELL_CORRECTION:
          <SpellGrammarCorrectionAnswer_Type>'DEFAULT_SPELL_CORRECTION',
      TYPE_UNSPECIFIED: <SpellGrammarCorrectionAnswer_Type>'TYPE_UNSPECIFIED',
      values(): Array<SpellGrammarCorrectionAnswer_Type> {
        return [
          SpellGrammarCorrectionAnswer_TypeEnum.TYPE_UNSPECIFIED,
          SpellGrammarCorrectionAnswer_TypeEnum.DEFAULT_SPELL_CORRECTION,
          SpellGrammarCorrectionAnswer_TypeEnum.DEFAULT_GRAMMAR_CORRECTION,
          SpellGrammarCorrectionAnswer_TypeEnum.CUSTOMIZED_GRAMMAR_CORRECTION
        ];
      }
    };

export type SubAgent_KnowledgeConnectorOption =
    'KNOWLEDGE_CONNECTOR_OPTION_UNSPECIFIED'|'INCLUDE_KNOWLEDGE_CONNECTOR'|
    'EXCLUDE_KNOWLEDGE_CONNECTOR';

export interface ISubAgent_KnowledgeConnectorOptionEnum {
  readonly KNOWLEDGE_CONNECTOR_OPTION_UNSPECIFIED:
      SubAgent_KnowledgeConnectorOption;
  readonly INCLUDE_KNOWLEDGE_CONNECTOR: SubAgent_KnowledgeConnectorOption;
  readonly EXCLUDE_KNOWLEDGE_CONNECTOR: SubAgent_KnowledgeConnectorOption;

  values(): Array<SubAgent_KnowledgeConnectorOption>;
}

export const SubAgent_KnowledgeConnectorOptionEnum:
    ISubAgent_KnowledgeConnectorOptionEnum = {
      EXCLUDE_KNOWLEDGE_CONNECTOR:
          <SubAgent_KnowledgeConnectorOption>'EXCLUDE_KNOWLEDGE_CONNECTOR',
      INCLUDE_KNOWLEDGE_CONNECTOR:
          <SubAgent_KnowledgeConnectorOption>'INCLUDE_KNOWLEDGE_CONNECTOR',
      KNOWLEDGE_CONNECTOR_OPTION_UNSPECIFIED: <
          SubAgent_KnowledgeConnectorOption>'KNOWLEDGE_CONNECTOR_OPTION_UNSPECIFIED',
      values(): Array<SubAgent_KnowledgeConnectorOption> {
        return [
          SubAgent_KnowledgeConnectorOptionEnum
              .KNOWLEDGE_CONNECTOR_OPTION_UNSPECIFIED,
          SubAgent_KnowledgeConnectorOptionEnum.INCLUDE_KNOWLEDGE_CONNECTOR,
          SubAgent_KnowledgeConnectorOptionEnum.EXCLUDE_KNOWLEDGE_CONNECTOR
        ];
      }
    };

export type SuggestionFeature_Type =
    'TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION'|'FAQ'|'SMART_REPLY'|
    'DIALOGFLOW_ASSIST'|'SMART_COMPOSE'|'ISSUE_SMART_COMPOSE'|
    'SPELL_GRAMMAR_CORRECTION'|'CONVERSATION_SUMMARIZATION'|'KEY_MOMENT'|
    'ARTICLE_SEARCH'|'CONVERSATION_GUIDANCE'|'ARTICLE_SUGGESTION_VOICE'|
    'CONVERSATION_SUMMARIZATION_VOICE';

export interface ISuggestionFeature_TypeEnum {
  readonly TYPE_UNSPECIFIED: SuggestionFeature_Type;
  readonly ARTICLE_SUGGESTION: SuggestionFeature_Type;
  readonly FAQ: SuggestionFeature_Type;
  readonly SMART_REPLY: SuggestionFeature_Type;
  readonly DIALOGFLOW_ASSIST: SuggestionFeature_Type;
  readonly SMART_COMPOSE: SuggestionFeature_Type;
  readonly ISSUE_SMART_COMPOSE: SuggestionFeature_Type;
  readonly SPELL_GRAMMAR_CORRECTION: SuggestionFeature_Type;
  readonly CONVERSATION_SUMMARIZATION: SuggestionFeature_Type;
  readonly KEY_MOMENT: SuggestionFeature_Type;
  readonly ARTICLE_SEARCH: SuggestionFeature_Type;
  readonly CONVERSATION_GUIDANCE: SuggestionFeature_Type;
  readonly ARTICLE_SUGGESTION_VOICE: SuggestionFeature_Type;
  readonly CONVERSATION_SUMMARIZATION_VOICE: SuggestionFeature_Type;

  values(): Array<SuggestionFeature_Type>;
}

export const SuggestionFeature_TypeEnum: ISuggestionFeature_TypeEnum = {
  ARTICLE_SEARCH: <SuggestionFeature_Type>'ARTICLE_SEARCH',
  ARTICLE_SUGGESTION: <SuggestionFeature_Type>'ARTICLE_SUGGESTION',
  ARTICLE_SUGGESTION_VOICE: <SuggestionFeature_Type>'ARTICLE_SUGGESTION_VOICE',
  CONVERSATION_GUIDANCE: <SuggestionFeature_Type>'CONVERSATION_GUIDANCE',
  CONVERSATION_SUMMARIZATION:
      <SuggestionFeature_Type>'CONVERSATION_SUMMARIZATION',
  CONVERSATION_SUMMARIZATION_VOICE:
      <SuggestionFeature_Type>'CONVERSATION_SUMMARIZATION_VOICE',
  DIALOGFLOW_ASSIST: <SuggestionFeature_Type>'DIALOGFLOW_ASSIST',
  FAQ: <SuggestionFeature_Type>'FAQ',
  ISSUE_SMART_COMPOSE: <SuggestionFeature_Type>'ISSUE_SMART_COMPOSE',
  KEY_MOMENT: <SuggestionFeature_Type>'KEY_MOMENT',
  SMART_COMPOSE: <SuggestionFeature_Type>'SMART_COMPOSE',
  SMART_REPLY: <SuggestionFeature_Type>'SMART_REPLY',
  SPELL_GRAMMAR_CORRECTION: <SuggestionFeature_Type>'SPELL_GRAMMAR_CORRECTION',
  TYPE_UNSPECIFIED: <SuggestionFeature_Type>'TYPE_UNSPECIFIED',
  values(): Array<SuggestionFeature_Type> {
    return [
      SuggestionFeature_TypeEnum.TYPE_UNSPECIFIED,
      SuggestionFeature_TypeEnum.ARTICLE_SUGGESTION,
      SuggestionFeature_TypeEnum.FAQ, SuggestionFeature_TypeEnum.SMART_REPLY,
      SuggestionFeature_TypeEnum.DIALOGFLOW_ASSIST,
      SuggestionFeature_TypeEnum.SMART_COMPOSE,
      SuggestionFeature_TypeEnum.ISSUE_SMART_COMPOSE,
      SuggestionFeature_TypeEnum.SPELL_GRAMMAR_CORRECTION,
      SuggestionFeature_TypeEnum.CONVERSATION_SUMMARIZATION,
      SuggestionFeature_TypeEnum.KEY_MOMENT,
      SuggestionFeature_TypeEnum.ARTICLE_SEARCH,
      SuggestionFeature_TypeEnum.CONVERSATION_GUIDANCE,
      SuggestionFeature_TypeEnum.ARTICLE_SUGGESTION_VOICE,
      SuggestionFeature_TypeEnum.CONVERSATION_SUMMARIZATION_VOICE
    ];
  }
};

export type SuggestionInput_Action = 'ACTION_UNSPECIFIED'|'CANCEL'|'REVISE';

export interface ISuggestionInput_ActionEnum {
  readonly ACTION_UNSPECIFIED: SuggestionInput_Action;
  readonly CANCEL: SuggestionInput_Action;
  readonly REVISE: SuggestionInput_Action;

  values(): Array<SuggestionInput_Action>;
}

export const SuggestionInput_ActionEnum: ISuggestionInput_ActionEnum = {
  ACTION_UNSPECIFIED: <SuggestionInput_Action>'ACTION_UNSPECIFIED',
  CANCEL: <SuggestionInput_Action>'CANCEL',
  REVISE: <SuggestionInput_Action>'REVISE',
  values(): Array<SuggestionInput_Action> {
    return [
      SuggestionInput_ActionEnum.ACTION_UNSPECIFIED,
      SuggestionInput_ActionEnum.CANCEL, SuggestionInput_ActionEnum.REVISE
    ];
  }
};

export type SummarizationModelMetadata_TrainingModelType =
    'MODEL_TYPE_UNSPECIFIED'|'ARTICLE_SUGGESTION_GBT_MODEL'|
    'SMART_REPLY_DUAL_ENCODER_MODEL'|'SMART_REPLY_BERT_MODEL'|
    'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL'|'SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL'|
    'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL'|'SMART_REPLY_GENERATIVE_MODEL'|
    'SUMMARIZATION_MODEL'|'KEY_MOMENT_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_MODEL'|
    'CONVERSATION_GUIDANCE_MODEL'|'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL'|
    'SUMMARIZATION_V2_MODEL'|'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL';

export interface ISummarizationModelMetadata_TrainingModelTypeEnum {
  readonly MODEL_TYPE_UNSPECIFIED: SummarizationModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_GBT_MODEL:
      SummarizationModelMetadata_TrainingModelType;
  readonly SMART_REPLY_DUAL_ENCODER_MODEL:
      SummarizationModelMetadata_TrainingModelType;
  readonly SMART_REPLY_BERT_MODEL: SummarizationModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL:
      SummarizationModelMetadata_TrainingModelType;
  readonly SMART_COMPOSE_DUAL_ENCODER_MODEL:
      SummarizationModelMetadata_TrainingModelType;
  readonly ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL:
      SummarizationModelMetadata_TrainingModelType;
  readonly ARTICLE_SUGGESTION_UNSUPERVISED_MODEL:
      SummarizationModelMetadata_TrainingModelType;
  readonly SMART_REPLY_GENERATIVE_MODEL:
      SummarizationModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_MODEL: SummarizationModelMetadata_TrainingModelType;
  readonly KEY_MOMENT_MODEL: SummarizationModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_MODEL:
      SummarizationModelMetadata_TrainingModelType;
  readonly CONVERSATION_GUIDANCE_MODEL:
      SummarizationModelMetadata_TrainingModelType;
  readonly CUSTOM_HEAD_INTENT_SUGGESTION_MODEL:
      SummarizationModelMetadata_TrainingModelType;
  readonly SUMMARIZATION_V2_MODEL: SummarizationModelMetadata_TrainingModelType;
  readonly ISSUE_MODEL_DUAL_ENCODER_V2_MODEL:
      SummarizationModelMetadata_TrainingModelType;

  values(): Array<SummarizationModelMetadata_TrainingModelType>;
}

export const SummarizationModelMetadata_TrainingModelTypeEnum:
    ISummarizationModelMetadata_TrainingModelTypeEnum = {
      ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL',
      ARTICLE_SUGGESTION_GBT_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_GBT_MODEL',
      ARTICLE_SUGGESTION_UNSUPERVISED_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'ARTICLE_SUGGESTION_UNSUPERVISED_MODEL',
      CONVERSATION_GUIDANCE_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'CONVERSATION_GUIDANCE_MODEL',
      CUSTOM_HEAD_INTENT_SUGGESTION_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'CUSTOM_HEAD_INTENT_SUGGESTION_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_MODEL',
      ISSUE_MODEL_DUAL_ENCODER_V2_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'ISSUE_MODEL_DUAL_ENCODER_V2_MODEL',
      ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL',
      KEY_MOMENT_MODEL:
          <SummarizationModelMetadata_TrainingModelType>'KEY_MOMENT_MODEL',
      MODEL_TYPE_UNSPECIFIED: <
          SummarizationModelMetadata_TrainingModelType>'MODEL_TYPE_UNSPECIFIED',
      SMART_COMPOSE_DUAL_ENCODER_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'SMART_COMPOSE_DUAL_ENCODER_MODEL',
      SMART_REPLY_BERT_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'SMART_REPLY_BERT_MODEL',
      SMART_REPLY_DUAL_ENCODER_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'SMART_REPLY_DUAL_ENCODER_MODEL',
      SMART_REPLY_GENERATIVE_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'SMART_REPLY_GENERATIVE_MODEL',
      SUMMARIZATION_MODEL:
          <SummarizationModelMetadata_TrainingModelType>'SUMMARIZATION_MODEL',
      SUMMARIZATION_V2_MODEL: <
          SummarizationModelMetadata_TrainingModelType>'SUMMARIZATION_V2_MODEL',
      values(): Array<SummarizationModelMetadata_TrainingModelType> {
        return [
          SummarizationModelMetadata_TrainingModelTypeEnum
              .MODEL_TYPE_UNSPECIFIED,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_GBT_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_DUAL_ENCODER_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_BERT_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_DUAL_ENCODER_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .SMART_COMPOSE_DUAL_ENCODER_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .ISSUE_SMART_COMPOSE_DUAL_ENCODER_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .ARTICLE_SUGGESTION_UNSUPERVISED_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .SMART_REPLY_GENERATIVE_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum.SUMMARIZATION_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum.KEY_MOMENT_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .CONVERSATION_GUIDANCE_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .CUSTOM_HEAD_INTENT_SUGGESTION_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .SUMMARIZATION_V2_MODEL,
          SummarizationModelMetadata_TrainingModelTypeEnum
              .ISSUE_MODEL_DUAL_ENCODER_V2_MODEL
        ];
      }
    };

export type TelephonyDtmfEvents_DtmfEvents =
    'TELEPHONY_DTMF_UNSPECIFIED'|'DTMF_ONE'|'DTMF_TWO'|'DTMF_THREE'|'DTMF_FOUR'|
    'DTMF_FIVE'|'DTMF_SIX'|'DTMF_SEVEN'|'DTMF_EIGHT'|'DTMF_NINE'|'DTMF_ZERO'|
    'DTMF_A'|'DTMF_B'|'DTMF_C'|'DTMF_D'|'DTMF_STAR'|'DTMF_POUND';

export interface ITelephonyDtmfEvents_DtmfEventsEnum {
  readonly TELEPHONY_DTMF_UNSPECIFIED: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_ONE: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_TWO: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_THREE: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_FOUR: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_FIVE: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_SIX: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_SEVEN: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_EIGHT: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_NINE: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_ZERO: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_A: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_B: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_C: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_D: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_STAR: TelephonyDtmfEvents_DtmfEvents;
  readonly DTMF_POUND: TelephonyDtmfEvents_DtmfEvents;

  values(): Array<TelephonyDtmfEvents_DtmfEvents>;
}

export const TelephonyDtmfEvents_DtmfEventsEnum:
    ITelephonyDtmfEvents_DtmfEventsEnum = {
      DTMF_A: <TelephonyDtmfEvents_DtmfEvents>'DTMF_A',
      DTMF_B: <TelephonyDtmfEvents_DtmfEvents>'DTMF_B',
      DTMF_C: <TelephonyDtmfEvents_DtmfEvents>'DTMF_C',
      DTMF_D: <TelephonyDtmfEvents_DtmfEvents>'DTMF_D',
      DTMF_EIGHT: <TelephonyDtmfEvents_DtmfEvents>'DTMF_EIGHT',
      DTMF_FIVE: <TelephonyDtmfEvents_DtmfEvents>'DTMF_FIVE',
      DTMF_FOUR: <TelephonyDtmfEvents_DtmfEvents>'DTMF_FOUR',
      DTMF_NINE: <TelephonyDtmfEvents_DtmfEvents>'DTMF_NINE',
      DTMF_ONE: <TelephonyDtmfEvents_DtmfEvents>'DTMF_ONE',
      DTMF_POUND: <TelephonyDtmfEvents_DtmfEvents>'DTMF_POUND',
      DTMF_SEVEN: <TelephonyDtmfEvents_DtmfEvents>'DTMF_SEVEN',
      DTMF_SIX: <TelephonyDtmfEvents_DtmfEvents>'DTMF_SIX',
      DTMF_STAR: <TelephonyDtmfEvents_DtmfEvents>'DTMF_STAR',
      DTMF_THREE: <TelephonyDtmfEvents_DtmfEvents>'DTMF_THREE',
      DTMF_TWO: <TelephonyDtmfEvents_DtmfEvents>'DTMF_TWO',
      DTMF_ZERO: <TelephonyDtmfEvents_DtmfEvents>'DTMF_ZERO',
      TELEPHONY_DTMF_UNSPECIFIED:
          <TelephonyDtmfEvents_DtmfEvents>'TELEPHONY_DTMF_UNSPECIFIED',
      values(): Array<TelephonyDtmfEvents_DtmfEvents> {
        return [
          TelephonyDtmfEvents_DtmfEventsEnum.TELEPHONY_DTMF_UNSPECIFIED,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_ONE,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_TWO,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_THREE,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_FOUR,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_FIVE,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_SIX,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_SEVEN,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_EIGHT,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_NINE,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_ZERO,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_A,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_B,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_C,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_D,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_STAR,
          TelephonyDtmfEvents_DtmfEventsEnum.DTMF_POUND
        ];
      }
    };

export type TextToSpeechSettings_OutputAudioEncoding =
    'OUTPUT_AUDIO_ENCODING_UNSPECIFIED'|'OUTPUT_AUDIO_ENCODING_LINEAR_16'|
    'OUTPUT_AUDIO_ENCODING_MP3'|'OUTPUT_AUDIO_ENCODING_MP3_64_KBPS'|
    'OUTPUT_AUDIO_ENCODING_OGG_OPUS'|'OUTPUT_AUDIO_ENCODING_MULAW';

export interface ITextToSpeechSettings_OutputAudioEncodingEnum {
  readonly OUTPUT_AUDIO_ENCODING_UNSPECIFIED:
      TextToSpeechSettings_OutputAudioEncoding;
  readonly OUTPUT_AUDIO_ENCODING_LINEAR_16:
      TextToSpeechSettings_OutputAudioEncoding;
  readonly OUTPUT_AUDIO_ENCODING_MP3: TextToSpeechSettings_OutputAudioEncoding;
  readonly OUTPUT_AUDIO_ENCODING_MP3_64_KBPS:
      TextToSpeechSettings_OutputAudioEncoding;
  readonly OUTPUT_AUDIO_ENCODING_OGG_OPUS:
      TextToSpeechSettings_OutputAudioEncoding;
  readonly OUTPUT_AUDIO_ENCODING_MULAW:
      TextToSpeechSettings_OutputAudioEncoding;

  values(): Array<TextToSpeechSettings_OutputAudioEncoding>;
}

export const TextToSpeechSettings_OutputAudioEncodingEnum:
    ITextToSpeechSettings_OutputAudioEncodingEnum = {
      OUTPUT_AUDIO_ENCODING_LINEAR_16: <
          TextToSpeechSettings_OutputAudioEncoding>'OUTPUT_AUDIO_ENCODING_LINEAR_16',
      OUTPUT_AUDIO_ENCODING_MP3:
          <TextToSpeechSettings_OutputAudioEncoding>'OUTPUT_AUDIO_ENCODING_MP3',
      OUTPUT_AUDIO_ENCODING_MP3_64_KBPS: <
          TextToSpeechSettings_OutputAudioEncoding>'OUTPUT_AUDIO_ENCODING_MP3_64_KBPS',
      OUTPUT_AUDIO_ENCODING_MULAW: <
          TextToSpeechSettings_OutputAudioEncoding>'OUTPUT_AUDIO_ENCODING_MULAW',
      OUTPUT_AUDIO_ENCODING_OGG_OPUS: <
          TextToSpeechSettings_OutputAudioEncoding>'OUTPUT_AUDIO_ENCODING_OGG_OPUS',
      OUTPUT_AUDIO_ENCODING_UNSPECIFIED: <
          TextToSpeechSettings_OutputAudioEncoding>'OUTPUT_AUDIO_ENCODING_UNSPECIFIED',
      values(): Array<TextToSpeechSettings_OutputAudioEncoding> {
        return [
          TextToSpeechSettings_OutputAudioEncodingEnum
              .OUTPUT_AUDIO_ENCODING_UNSPECIFIED,
          TextToSpeechSettings_OutputAudioEncodingEnum
              .OUTPUT_AUDIO_ENCODING_LINEAR_16,
          TextToSpeechSettings_OutputAudioEncodingEnum
              .OUTPUT_AUDIO_ENCODING_MP3,
          TextToSpeechSettings_OutputAudioEncodingEnum
              .OUTPUT_AUDIO_ENCODING_MP3_64_KBPS,
          TextToSpeechSettings_OutputAudioEncodingEnum
              .OUTPUT_AUDIO_ENCODING_OGG_OPUS,
          TextToSpeechSettings_OutputAudioEncodingEnum
              .OUTPUT_AUDIO_ENCODING_MULAW
        ];
      }
    };

export type ValidationError_Severity =
    'SEVERITY_UNSPECIFIED'|'INFO'|'WARNING'|'ERROR'|'CRITICAL';

export interface IValidationError_SeverityEnum {
  readonly SEVERITY_UNSPECIFIED: ValidationError_Severity;
  readonly INFO: ValidationError_Severity;
  readonly WARNING: ValidationError_Severity;
  readonly ERROR: ValidationError_Severity;
  readonly CRITICAL: ValidationError_Severity;

  values(): Array<ValidationError_Severity>;
}

export const ValidationError_SeverityEnum: IValidationError_SeverityEnum = {
  CRITICAL: <ValidationError_Severity>'CRITICAL',
  ERROR: <ValidationError_Severity>'ERROR',
  INFO: <ValidationError_Severity>'INFO',
  SEVERITY_UNSPECIFIED: <ValidationError_Severity>'SEVERITY_UNSPECIFIED',
  WARNING: <ValidationError_Severity>'WARNING',
  values(): Array<ValidationError_Severity> {
    return [
      ValidationError_SeverityEnum.SEVERITY_UNSPECIFIED,
      ValidationError_SeverityEnum.INFO, ValidationError_SeverityEnum.WARNING,
      ValidationError_SeverityEnum.ERROR, ValidationError_SeverityEnum.CRITICAL
    ];
  }
};

export type ValidationError_Type =
    'TYPE_UNSPECIFIED'|'DUPLICATE_ENTITY'|'INVALID_ENTITY_NAME'|
    'INVALID_ENTITY_VALUE'|'INVALID_PARAMETER_NAME'|'INVALID_PARAMETER_VALUE'|
    'INVALID_PARAMETER_TYPE'|'INCONSISTENT_PARAMETER_TYPE'|
    'INVALID_TRAINING_PHRASE'|'SIMILAR_TRAINING_PHRASES'|'INVALID_ANNOTATION'|
    'EMPTY_INTENT'|'SIMILAR_INTENTS'|'TOO_MANY_ML_DISABLED_INTENTS';

export interface IValidationError_TypeEnum {
  readonly TYPE_UNSPECIFIED: ValidationError_Type;
  readonly DUPLICATE_ENTITY: ValidationError_Type;
  readonly INVALID_ENTITY_NAME: ValidationError_Type;
  readonly INVALID_ENTITY_VALUE: ValidationError_Type;
  readonly INVALID_PARAMETER_NAME: ValidationError_Type;
  readonly INVALID_PARAMETER_VALUE: ValidationError_Type;
  readonly INVALID_PARAMETER_TYPE: ValidationError_Type;
  readonly INCONSISTENT_PARAMETER_TYPE: ValidationError_Type;
  readonly INVALID_TRAINING_PHRASE: ValidationError_Type;
  readonly SIMILAR_TRAINING_PHRASES: ValidationError_Type;
  readonly INVALID_ANNOTATION: ValidationError_Type;
  readonly EMPTY_INTENT: ValidationError_Type;
  readonly SIMILAR_INTENTS: ValidationError_Type;
  readonly TOO_MANY_ML_DISABLED_INTENTS: ValidationError_Type;

  values(): Array<ValidationError_Type>;
}

export const ValidationError_TypeEnum: IValidationError_TypeEnum = {
  DUPLICATE_ENTITY: <ValidationError_Type>'DUPLICATE_ENTITY',
  EMPTY_INTENT: <ValidationError_Type>'EMPTY_INTENT',
  INCONSISTENT_PARAMETER_TYPE:
      <ValidationError_Type>'INCONSISTENT_PARAMETER_TYPE',
  INVALID_ANNOTATION: <ValidationError_Type>'INVALID_ANNOTATION',
  INVALID_ENTITY_NAME: <ValidationError_Type>'INVALID_ENTITY_NAME',
  INVALID_ENTITY_VALUE: <ValidationError_Type>'INVALID_ENTITY_VALUE',
  INVALID_PARAMETER_NAME: <ValidationError_Type>'INVALID_PARAMETER_NAME',
  INVALID_PARAMETER_TYPE: <ValidationError_Type>'INVALID_PARAMETER_TYPE',
  INVALID_PARAMETER_VALUE: <ValidationError_Type>'INVALID_PARAMETER_VALUE',
  INVALID_TRAINING_PHRASE: <ValidationError_Type>'INVALID_TRAINING_PHRASE',
  SIMILAR_INTENTS: <ValidationError_Type>'SIMILAR_INTENTS',
  SIMILAR_TRAINING_PHRASES: <ValidationError_Type>'SIMILAR_TRAINING_PHRASES',
  TOO_MANY_ML_DISABLED_INTENTS:
      <ValidationError_Type>'TOO_MANY_ML_DISABLED_INTENTS',
  TYPE_UNSPECIFIED: <ValidationError_Type>'TYPE_UNSPECIFIED',
  values(): Array<ValidationError_Type> {
    return [
      ValidationError_TypeEnum.TYPE_UNSPECIFIED,
      ValidationError_TypeEnum.DUPLICATE_ENTITY,
      ValidationError_TypeEnum.INVALID_ENTITY_NAME,
      ValidationError_TypeEnum.INVALID_ENTITY_VALUE,
      ValidationError_TypeEnum.INVALID_PARAMETER_NAME,
      ValidationError_TypeEnum.INVALID_PARAMETER_VALUE,
      ValidationError_TypeEnum.INVALID_PARAMETER_TYPE,
      ValidationError_TypeEnum.INCONSISTENT_PARAMETER_TYPE,
      ValidationError_TypeEnum.INVALID_TRAINING_PHRASE,
      ValidationError_TypeEnum.SIMILAR_TRAINING_PHRASES,
      ValidationError_TypeEnum.INVALID_ANNOTATION,
      ValidationError_TypeEnum.EMPTY_INTENT,
      ValidationError_TypeEnum.SIMILAR_INTENTS,
      ValidationError_TypeEnum.TOO_MANY_ML_DISABLED_INTENTS
    ];
  }
};

export type Version_Status =
    'VERSION_STATUS_UNSPECIFIED'|'IN_PROGRESS'|'READY'|'FAILED';

export interface IVersion_StatusEnum {
  readonly VERSION_STATUS_UNSPECIFIED: Version_Status;
  readonly IN_PROGRESS: Version_Status;
  readonly READY: Version_Status;
  readonly FAILED: Version_Status;

  values(): Array<Version_Status>;
}

export const Version_StatusEnum: IVersion_StatusEnum = {
  FAILED: <Version_Status>'FAILED',
  IN_PROGRESS: <Version_Status>'IN_PROGRESS',
  READY: <Version_Status>'READY',
  VERSION_STATUS_UNSPECIFIED: <Version_Status>'VERSION_STATUS_UNSPECIFIED',
  values(): Array<Version_Status> {
    return [
      Version_StatusEnum.VERSION_STATUS_UNSPECIFIED,
      Version_StatusEnum.IN_PROGRESS, Version_StatusEnum.READY,
      Version_StatusEnum.FAILED
    ];
  }
};

export type VoiceSelectionParamsCustomVoiceParams_ReportedUsage =
    'REPORTED_USAGE_UNSPECIFIED'|'REALTIME'|'OFFLINE';

export interface IVoiceSelectionParamsCustomVoiceParams_ReportedUsageEnum {
  readonly REPORTED_USAGE_UNSPECIFIED:
      VoiceSelectionParamsCustomVoiceParams_ReportedUsage;
  readonly REALTIME: VoiceSelectionParamsCustomVoiceParams_ReportedUsage;
  readonly OFFLINE: VoiceSelectionParamsCustomVoiceParams_ReportedUsage;

  values(): Array<VoiceSelectionParamsCustomVoiceParams_ReportedUsage>;
}

export const VoiceSelectionParamsCustomVoiceParams_ReportedUsageEnum:
    IVoiceSelectionParamsCustomVoiceParams_ReportedUsageEnum = {
      OFFLINE: <VoiceSelectionParamsCustomVoiceParams_ReportedUsage>'OFFLINE',
      REALTIME: <VoiceSelectionParamsCustomVoiceParams_ReportedUsage>'REALTIME',
      REPORTED_USAGE_UNSPECIFIED: <
          VoiceSelectionParamsCustomVoiceParams_ReportedUsage>'REPORTED_USAGE_UNSPECIFIED',
      values(): Array<VoiceSelectionParamsCustomVoiceParams_ReportedUsage> {
        return [
          VoiceSelectionParamsCustomVoiceParams_ReportedUsageEnum
              .REPORTED_USAGE_UNSPECIFIED,
          VoiceSelectionParamsCustomVoiceParams_ReportedUsageEnum.REALTIME,
          VoiceSelectionParamsCustomVoiceParams_ReportedUsageEnum.OFFLINE
        ];
      }
    };

export type VoiceSelectionParams_SsmlGender =
    'SSML_VOICE_GENDER_UNSPECIFIED'|'SSML_VOICE_GENDER_MALE'|
    'SSML_VOICE_GENDER_FEMALE'|'SSML_VOICE_GENDER_NEUTRAL';

export interface IVoiceSelectionParams_SsmlGenderEnum {
  readonly SSML_VOICE_GENDER_UNSPECIFIED: VoiceSelectionParams_SsmlGender;
  readonly SSML_VOICE_GENDER_MALE: VoiceSelectionParams_SsmlGender;
  readonly SSML_VOICE_GENDER_FEMALE: VoiceSelectionParams_SsmlGender;
  readonly SSML_VOICE_GENDER_NEUTRAL: VoiceSelectionParams_SsmlGender;

  values(): Array<VoiceSelectionParams_SsmlGender>;
}

export const VoiceSelectionParams_SsmlGenderEnum:
    IVoiceSelectionParams_SsmlGenderEnum = {
      SSML_VOICE_GENDER_FEMALE:
          <VoiceSelectionParams_SsmlGender>'SSML_VOICE_GENDER_FEMALE',
      SSML_VOICE_GENDER_MALE:
          <VoiceSelectionParams_SsmlGender>'SSML_VOICE_GENDER_MALE',
      SSML_VOICE_GENDER_NEUTRAL:
          <VoiceSelectionParams_SsmlGender>'SSML_VOICE_GENDER_NEUTRAL',
      SSML_VOICE_GENDER_UNSPECIFIED:
          <VoiceSelectionParams_SsmlGender>'SSML_VOICE_GENDER_UNSPECIFIED',
      values(): Array<VoiceSelectionParams_SsmlGender> {
        return [
          VoiceSelectionParams_SsmlGenderEnum.SSML_VOICE_GENDER_UNSPECIFIED,
          VoiceSelectionParams_SsmlGenderEnum.SSML_VOICE_GENDER_MALE,
          VoiceSelectionParams_SsmlGenderEnum.SSML_VOICE_GENDER_FEMALE,
          VoiceSelectionParams_SsmlGenderEnum.SSML_VOICE_GENDER_NEUTRAL
        ];
      }
    };

export interface ActivateConversationRequest_Parameters {}
export class ActivateConversationRequest_ extends Serializable {
  constructor(parameters: ActivateConversationRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<ActivateConversationRequest_> {
    return ActivateConversationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface AddConversationPhoneNumberRequest_Parameters {}
export class AddConversationPhoneNumberRequest_ extends Serializable {
  constructor(parameters: AddConversationPhoneNumberRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<AddConversationPhoneNumberRequest_> {
    return AddConversationPhoneNumberRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface AddSpecialistsToPoolOperationMetadata_Parameters {
  specialistPool?: string|null;
  partialFailures?: Array<GoogleRpcStatus>|null;
  createTime?: string|null;
}
export class AddSpecialistsToPoolOperationMetadata_ extends Serializable {
  constructor(
      parameters: AddSpecialistsToPoolOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'specialistPool',
        (parameters.specialistPool == null) ? (null) :
                                              (parameters.specialistPool));
    this.Serializable$set(
        'partialFailures',
        (parameters.partialFailures == null) ? (null) :
                                               (parameters.partialFailures));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Timestamp when operation was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get partialFailures(): Array<GoogleRpcStatus>|null {
    return (
        (this.Serializable$has('partialFailures')) ?
            (this.Serializable$get('partialFailures')) :
            (null));
  }

  /**
   * Output only. Partial failures encountered. E.g. some specialists fail to be
   * added. Status details field will contain standard Google Cloud error
   * details.
   */
  set partialFailures(value: Array<GoogleRpcStatus>|null) {
    this.Serializable$set('partialFailures', value);
  }

  get specialistPool(): string|null {
    return (
        (this.Serializable$has('specialistPool')) ?
            (this.Serializable$get('specialistPool')) :
            (null));
  }

  /**
   * Output only. SpecialistPool resource name. Format:
   * `projects//locations//specialistPools/`
   */
  set specialistPool(value: string|null) {
    this.Serializable$set('specialistPool', value);
  }

  getConstructor(): SerializableCtor<AddSpecialistsToPoolOperationMetadata_> {
    return AddSpecialistsToPoolOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'partialFailures': GoogleRpcStatus},
      keys: ['createTime', 'partialFailures', 'specialistPool']
    };
  }
}

export interface AddSpecialistsToPoolOperationResponse_Parameters {
  totalCount?: number|null;
  addedCount?: number|null;
}
export class AddSpecialistsToPoolOperationResponse_ extends Serializable {
  constructor(
      parameters: AddSpecialistsToPoolOperationResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'totalCount',
        (parameters.totalCount == null) ? (null) : (parameters.totalCount));
    this.Serializable$set(
        'addedCount',
        (parameters.addedCount == null) ? (null) : (parameters.addedCount));
  }

  get addedCount(): number|null {
    return (
        (this.Serializable$has('addedCount')) ?
            (this.Serializable$get('addedCount')) :
            (null));
  }

  /**
   * Output only. Number of specialists added successfully.
   */
  set addedCount(value: number|null) {
    this.Serializable$set('addedCount', value);
  }

  get totalCount(): number|null {
    return (
        (this.Serializable$has('totalCount')) ?
            (this.Serializable$get('totalCount')) :
            (null));
  }

  /**
   * Output only. Total number of specialists to add.
   */
  set totalCount(value: number|null) {
    this.Serializable$set('totalCount', value);
  }

  getConstructor(): SerializableCtor<AddSpecialistsToPoolOperationResponse_> {
    return AddSpecialistsToPoolOperationResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['addedCount', 'totalCount']};
  }
}

export interface AddSpecialistsToPoolRequest_Parameters {
  specialists?: Array<Specialist_>|null;
}
export class AddSpecialistsToPoolRequest_ extends Serializable {
  constructor(parameters: AddSpecialistsToPoolRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'specialists',
        (parameters.specialists == null) ? (null) : (parameters.specialists));
  }

  get specialists(): Array<Specialist_>|null {
    return (
        (this.Serializable$has('specialists')) ?
            (this.Serializable$get('specialists')) :
            (null));
  }

  /**
   * The list of specialists to be added to the pool. Specialists needs to be
   * under the same project as the specialist pool.
   */
  set specialists(value: Array<Specialist_>|null) {
    this.Serializable$set('specialists', value);
  }

  getConstructor(): SerializableCtor<AddSpecialistsToPoolRequest_> {
    return AddSpecialistsToPoolRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'specialists': Specialist_}, keys: ['specialists']};
  }
}

export interface AgentAssistantFeedbackSummarizationFeedback_Parameters {
  startTimestamp?: string|null;
  submitTimestamp?: string|null;
  summaryText?: string|null;
}
export class AgentAssistantFeedbackSummarizationFeedback_ extends Serializable {
  constructor(
      parameters: AgentAssistantFeedbackSummarizationFeedback_Parameters = {}) {
    super();
    this.Serializable$set(
        'startTimestamp',
        (parameters.startTimestamp == null) ? (null) :
                                              (parameters.startTimestamp));
    this.Serializable$set(
        'submitTimestamp',
        (parameters.submitTimestamp == null) ? (null) :
                                               (parameters.submitTimestamp));
    this.Serializable$set(
        'summaryText',
        (parameters.summaryText == null) ? (null) : (parameters.summaryText));
  }

  get startTimestamp(): string|null {
    return (
        (this.Serializable$has('startTimestamp')) ?
            (this.Serializable$get('startTimestamp')) :
            (null));
  }

  /**
   * Timestamp when composing of the summary starts.
   */
  set startTimestamp(value: string|null) {
    this.Serializable$set('startTimestamp', value);
  }

  get submitTimestamp(): string|null {
    return (
        (this.Serializable$has('submitTimestamp')) ?
            (this.Serializable$get('submitTimestamp')) :
            (null));
  }

  /**
   * Timestamp when the summary was submitted.
   */
  set submitTimestamp(value: string|null) {
    this.Serializable$set('submitTimestamp', value);
  }

  get summaryText(): string|null {
    return (
        (this.Serializable$has('summaryText')) ?
            (this.Serializable$get('summaryText')) :
            (null));
  }

  /**
   * Text of actual submitted summary.
   */
  set summaryText(value: string|null) {
    this.Serializable$set('summaryText', value);
  }

  getConstructor():
      SerializableCtor<AgentAssistantFeedbackSummarizationFeedback_> {
    return AgentAssistantFeedbackSummarizationFeedback_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['startTimestamp', 'submitTimestamp', 'summaryText']};
  }
}

export interface AgentAssistantFeedback_Parameters {
  answerRelevance?: AgentAssistantFeedback_AnswerRelevance|null;
  documentCorrectness?: AgentAssistantFeedback_DocumentCorrectness|null;
  documentEfficiency?: AgentAssistantFeedback_DocumentEfficiency|null;
  summarizationFeedback?: AgentAssistantFeedbackSummarizationFeedback_|null;
}
export class AgentAssistantFeedback_ extends Serializable {
  constructor(parameters: AgentAssistantFeedback_Parameters = {}) {
    super();
    this.Serializable$set(
        'answerRelevance',
        (parameters.answerRelevance == null) ? (null) :
                                               (parameters.answerRelevance));
    this.Serializable$set(
        'documentCorrectness',
        (parameters.documentCorrectness == null) ?
            (null) :
            (parameters.documentCorrectness));
    this.Serializable$set(
        'documentEfficiency',
        (parameters.documentEfficiency == null) ?
            (null) :
            (parameters.documentEfficiency));
    this.Serializable$set(
        'summarizationFeedback',
        (parameters.summarizationFeedback == null) ?
            (null) :
            (parameters.summarizationFeedback));
  }

  static get AnswerRelevance(): IAgentAssistantFeedback_AnswerRelevanceEnum {
    return AgentAssistantFeedback_AnswerRelevanceEnum;
  }

  static get DocumentCorrectness():
      IAgentAssistantFeedback_DocumentCorrectnessEnum {
    return AgentAssistantFeedback_DocumentCorrectnessEnum;
  }

  static get DocumentEfficiency():
      IAgentAssistantFeedback_DocumentEfficiencyEnum {
    return AgentAssistantFeedback_DocumentEfficiencyEnum;
  }

  get answerRelevance(): AgentAssistantFeedback_AnswerRelevance|null {
    return (
        (this.Serializable$has('answerRelevance')) ?
            (this.Serializable$get('answerRelevance')) :
            (null));
  }

  /**
   * Optional. Whether or not the suggested answer is relevant. For example: *
   * Query: \"Can I change my mailing address?\" * Suggested document says:
   * \"Items must be returned/exchanged within 60 days of the purchase date.\" *
   * answer_relevance: AnswerRelevance.IRRELEVANT
   */
  set answerRelevance(value: AgentAssistantFeedback_AnswerRelevance|null) {
    this.Serializable$set('answerRelevance', value);
  }

  get documentCorrectness(): AgentAssistantFeedback_DocumentCorrectness|null {
    return (
        (this.Serializable$has('documentCorrectness')) ?
            (this.Serializable$get('documentCorrectness')) :
            (null));
  }

  /**
   * Optional. Whether or not the information in the document is correct. For
   * example: * Query: \"Can I return the package in 2 days once received?\" *
   * Suggested document says: \"Items must be returned/exchanged within 60 days
   * of the purchase date.\" * Ground truth: \"No return or exchange is
   * allowed.\" * [document_correctness]: INCORRECT
   */
  set documentCorrectness(value: AgentAssistantFeedback_DocumentCorrectness|
                          null) {
    this.Serializable$set('documentCorrectness', value);
  }

  get documentEfficiency(): AgentAssistantFeedback_DocumentEfficiency|null {
    return (
        (this.Serializable$has('documentEfficiency')) ?
            (this.Serializable$get('documentEfficiency')) :
            (null));
  }

  /**
   * Optional. Whether or not the suggested document is efficient. For example,
   * if the document is poorly written, hard to understand, hard to use or too
   * long to find useful information, document_efficiency is
   * DocumentEfficiency.INEFFICIENT.
   */
  set documentEfficiency(value: AgentAssistantFeedback_DocumentEfficiency|
                         null) {
    this.Serializable$set('documentEfficiency', value);
  }

  get summarizationFeedback(): AgentAssistantFeedbackSummarizationFeedback_
      |null {
    return (
        (this.Serializable$has('summarizationFeedback')) ?
            (this.Serializable$get('summarizationFeedback')) :
            (null));
  }

  /**
   * Feedback for conversation summarization.
   */
  set summarizationFeedback(value: AgentAssistantFeedbackSummarizationFeedback_|
                            null) {
    this.Serializable$set('summarizationFeedback', value);
  }

  getConstructor(): SerializableCtor<AgentAssistantFeedback_> {
    return AgentAssistantFeedback_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'answerRelevance': AgentAssistantFeedback_AnswerRelevanceEnum,
        'documentCorrectness': AgentAssistantFeedback_DocumentCorrectnessEnum,
        'documentEfficiency': AgentAssistantFeedback_DocumentEfficiencyEnum
      },
      keys: [
        'answerRelevance', 'documentCorrectness', 'documentEfficiency',
        'summarizationFeedback'
      ],
      objects: {
        'summarizationFeedback': AgentAssistantFeedbackSummarizationFeedback_
      }
    };
  }
}

export interface AgentAssistantRecord_Parameters {
  articleSuggestionAnswer?: ArticleAnswer_|null;
  faqAnswer?: FaqAnswer_|null;
  dialogflowAssistAnswer?: DialogflowAssistAnswer_|null;
}
export class AgentAssistantRecord_ extends Serializable {
  constructor(parameters: AgentAssistantRecord_Parameters = {}) {
    super();
    this.Serializable$set(
        'articleSuggestionAnswer',
        (parameters.articleSuggestionAnswer == null) ?
            (null) :
            (parameters.articleSuggestionAnswer));
    this.Serializable$set(
        'faqAnswer',
        (parameters.faqAnswer == null) ? (null) : (parameters.faqAnswer));
    this.Serializable$set(
        'dialogflowAssistAnswer',
        (parameters.dialogflowAssistAnswer == null) ?
            (null) :
            (parameters.dialogflowAssistAnswer));
  }

  get articleSuggestionAnswer(): ArticleAnswer_|null {
    return (
        (this.Serializable$has('articleSuggestionAnswer')) ?
            (this.Serializable$get('articleSuggestionAnswer')) :
            (null));
  }

  /**
   * Output only. The article suggestion answer.
   */
  set articleSuggestionAnswer(value: ArticleAnswer_|null) {
    this.Serializable$set('articleSuggestionAnswer', value);
  }

  get dialogflowAssistAnswer(): DialogflowAssistAnswer_|null {
    return (
        (this.Serializable$has('dialogflowAssistAnswer')) ?
            (this.Serializable$get('dialogflowAssistAnswer')) :
            (null));
  }

  /**
   * Output only. Dialogflow assist answer.
   */
  set dialogflowAssistAnswer(value: DialogflowAssistAnswer_|null) {
    this.Serializable$set('dialogflowAssistAnswer', value);
  }

  get faqAnswer(): FaqAnswer_|null {
    return (
        (this.Serializable$has('faqAnswer')) ?
            (this.Serializable$get('faqAnswer')) :
            (null));
  }

  /**
   * Output only. The FAQ answer.
   */
  set faqAnswer(value: FaqAnswer_|null) {
    this.Serializable$set('faqAnswer', value);
  }

  getConstructor(): SerializableCtor<AgentAssistantRecord_> {
    return AgentAssistantRecord_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['articleSuggestionAnswer', 'dialogflowAssistAnswer', 'faqAnswer'],
      objects: {
        'articleSuggestionAnswer': ArticleAnswer_,
        'dialogflowAssistAnswer': DialogflowAssistAnswer_,
        'faqAnswer': FaqAnswer_
      }
    };
  }
}

export interface AgentPromptResource_Parameters {
  parameterName?: string|null;
}
export class AgentPromptResource_ extends Serializable {
  constructor(parameters: AgentPromptResource_Parameters = {}) {
    super();
    this.Serializable$set(
        'parameterName',
        (parameters.parameterName == null) ? (null) :
                                             (parameters.parameterName));
  }

  get parameterName(): string|null {
    return (
        (this.Serializable$has('parameterName')) ?
            (this.Serializable$get('parameterName')) :
            (null));
  }

  /**
   * Resource name of the parameter. Format:
   * `projects//locations//agent/intents//parameters/`
   */
  set parameterName(value: string|null) {
    this.Serializable$set('parameterName', value);
  }

  getConstructor(): SerializableCtor<AgentPromptResource_> {
    return AgentPromptResource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['parameterName']};
  }
}

export interface AgentResponseResource_Parameters {
  intentName?: string|null;
}
export class AgentResponseResource_ extends Serializable {
  constructor(parameters: AgentResponseResource_Parameters = {}) {
    super();
    this.Serializable$set(
        'intentName',
        (parameters.intentName == null) ? (null) : (parameters.intentName));
  }

  get intentName(): string|null {
    return (
        (this.Serializable$has('intentName')) ?
            (this.Serializable$get('intentName')) :
            (null));
  }

  /**
   * Resource name of the associated intent.
   */
  set intentName(value: string|null) {
    this.Serializable$set('intentName', value);
  }

  getConstructor(): SerializableCtor<AgentResponseResource_> {
    return AgentResponseResource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intentName']};
  }
}

export interface AgentSettingsConsoleNotifications_Parameters {
  id?: string|null;
  messageBody?: string|null;
  backgroundColor?: string|null;
  fontSize?: string|null;
  textColor?: string|null;
}
export class AgentSettingsConsoleNotifications_ extends Serializable {
  constructor(parameters: AgentSettingsConsoleNotifications_Parameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'messageBody',
        (parameters.messageBody == null) ? (null) : (parameters.messageBody));
    this.Serializable$set(
        'backgroundColor',
        (parameters.backgroundColor == null) ? (null) :
                                               (parameters.backgroundColor));
    this.Serializable$set(
        'fontSize',
        (parameters.fontSize == null) ? (null) : (parameters.fontSize));
    this.Serializable$set(
        'textColor',
        (parameters.textColor == null) ? (null) : (parameters.textColor));
  }

  get backgroundColor(): string|null {
    return (
        (this.Serializable$has('backgroundColor')) ?
            (this.Serializable$get('backgroundColor')) :
            (null));
  }

  /**
   * Notification background color.
   */
  set backgroundColor(value: string|null) {
    this.Serializable$set('backgroundColor', value);
  }

  get fontSize(): string|null {
    return (
        (this.Serializable$has('fontSize')) ?
            (this.Serializable$get('fontSize')) :
            (null));
  }

  /**
   * Notification font size.
   */
  set fontSize(value: string|null) {
    this.Serializable$set('fontSize', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Notification identifier. Console will use this notification to track if
   * user dismissed notification or not.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get messageBody(): string|null {
    return (
        (this.Serializable$has('messageBody')) ?
            (this.Serializable$get('messageBody')) :
            (null));
  }

  /**
   * Notification text body.
   */
  set messageBody(value: string|null) {
    this.Serializable$set('messageBody', value);
  }

  get textColor(): string|null {
    return (
        (this.Serializable$has('textColor')) ?
            (this.Serializable$get('textColor')) :
            (null));
  }

  /**
   * Notification text color.
   */
  set textColor(value: string|null) {
    this.Serializable$set('textColor', value);
  }

  getConstructor(): SerializableCtor<AgentSettingsConsoleNotifications_> {
    return AgentSettingsConsoleNotifications_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['backgroundColor', 'fontSize', 'id', 'messageBody', 'textColor']
    };
  }
}

export interface AgentSettingsTypeaheadDataTypeaheadEntityType_Parameters {
  synonyms?: Array<string>|null;
  displayName?: string|null;
  deprecated?: boolean|null;
}
export class AgentSettingsTypeaheadDataTypeaheadEntityType_ extends
    Serializable {
  constructor(
      parameters:
          AgentSettingsTypeaheadDataTypeaheadEntityType_Parameters = {}) {
    super();
    this.Serializable$set(
        'synonyms',
        (parameters.synonyms == null) ? (null) : (parameters.synonyms));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'deprecated',
        (parameters.deprecated == null) ? (null) : (parameters.deprecated));
  }

  get deprecated(): boolean|null {
    return (
        (this.Serializable$has('deprecated')) ?
            (this.Serializable$get('deprecated')) :
            (null));
  }

  /**
   * Indicates if entity type is deprecated. Entity will not be shown in the
   * console but will not produce error if used in agent.
   */
  set deprecated(value: boolean|null) {
    this.Serializable$set('deprecated', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Entity type display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get synonyms(): Array<string>|null {
    return (
        (this.Serializable$has('synonyms')) ?
            (this.Serializable$get('synonyms')) :
            (null));
  }

  /**
   * List of entity type synonyms. It's not the same synonyms that could be
   * found in regular entiy type, it's specific synonyms for typeahead, e.g.:
   * town for @sys.geo-city.
   */
  set synonyms(value: Array<string>|null) {
    this.Serializable$set('synonyms', value);
  }

  getConstructor():
      SerializableCtor<AgentSettingsTypeaheadDataTypeaheadEntityType_> {
    return AgentSettingsTypeaheadDataTypeaheadEntityType_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['deprecated', 'displayName', 'synonyms']};
  }
}

export interface AgentSettingsTypeaheadData_Parameters {
  contextDisplayNames?: Array<string>|null;
  intentActions?: Array<string>|null;
  extendableEntityTypes?: Array<string>|null;
  entityTypes?: Array<AgentSettingsTypeaheadDataTypeaheadEntityType_>|null;
}
export class AgentSettingsTypeaheadData_ extends Serializable {
  constructor(parameters: AgentSettingsTypeaheadData_Parameters = {}) {
    super();
    this.Serializable$set(
        'contextDisplayNames',
        (parameters.contextDisplayNames == null) ?
            (null) :
            (parameters.contextDisplayNames));
    this.Serializable$set(
        'intentActions',
        (parameters.intentActions == null) ? (null) :
                                             (parameters.intentActions));
    this.Serializable$set(
        'extendableEntityTypes',
        (parameters.extendableEntityTypes == null) ?
            (null) :
            (parameters.extendableEntityTypes));
    this.Serializable$set(
        'entityTypes',
        (parameters.entityTypes == null) ? (null) : (parameters.entityTypes));
  }

  get contextDisplayNames(): Array<string>|null {
    return (
        (this.Serializable$has('contextDisplayNames')) ?
            (this.Serializable$get('contextDisplayNames')) :
            (null));
  }

  /**
   * List of input and output contexts display names.
   */
  set contextDisplayNames(value: Array<string>|null) {
    this.Serializable$set('contextDisplayNames', value);
  }

  get entityTypes():
      Array<AgentSettingsTypeaheadDataTypeaheadEntityType_>|null {
    return (
        (this.Serializable$has('entityTypes')) ?
            (this.Serializable$get('entityTypes')) :
            (null));
  }

  /**
   * Output only. List of entity type names and synonyms.
   */
  set entityTypes(value: Array<AgentSettingsTypeaheadDataTypeaheadEntityType_>|
                  null) {
    this.Serializable$set('entityTypes', value);
  }

  get extendableEntityTypes(): Array<string>|null {
    return (
        (this.Serializable$has('extendableEntityTypes')) ?
            (this.Serializable$get('extendableEntityTypes')) :
            (null));
  }

  /**
   * List of extendable entity types display names.
   */
  set extendableEntityTypes(value: Array<string>|null) {
    this.Serializable$set('extendableEntityTypes', value);
  }

  get intentActions(): Array<string>|null {
    return (
        (this.Serializable$has('intentActions')) ?
            (this.Serializable$get('intentActions')) :
            (null));
  }

  /**
   * List of actions names.
   */
  set intentActions(value: Array<string>|null) {
    this.Serializable$set('intentActions', value);
  }

  getConstructor(): SerializableCtor<AgentSettingsTypeaheadData_> {
    return AgentSettingsTypeaheadData_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'entityTypes': AgentSettingsTypeaheadDataTypeaheadEntityType_},
      keys: [
        'contextDisplayNames', 'entityTypes', 'extendableEntityTypes',
        'intentActions'
      ]
    };
  }
}

export interface AgentSettings_Parameters {
  enableSpeechLogging?: boolean|null;
  enableSpeechRequestContext?: boolean|null;
  enableCustomSpeechModels?: boolean|null;
  endpointerSensitivity?: number|null;
  textToSpeechSettings?: TextToSpeechSettings_|null;
  customSpeechModelInfos?: DialogflowProtoPersistenceCustomSpeechModelInfos|
      null;
  enableAgentWideTts?: boolean|null;
  enableAgentWideKnowledgeConnector?: boolean|null;
  agentProjectNumber?: string|null;
  subAgents?: Array<SubAgent_>|null;
  consoleNotifications?: Array<AgentSettingsConsoleNotifications_>|null;
  legacyRoles?: Array<string>|null;
  typeaheadData?: AgentSettingsTypeaheadData_|null;
  agentLimits?: ApiClientObjectMap<string>|null;
  knowledgeBaseNames?: Array<string>|null;
  knowledgeConfidenceAdjustment?: number|null;
  enableStackdriverLogging?: boolean|null;
  enableValidation?: boolean|null;
  enableSpellCorrection?: boolean|null;
  disableAutomaticTraining?: boolean|null;
  enableSentimentAnalysis?: boolean|null;
  enableSmalltalk?: boolean|null;
  enableInteractionLogging?: boolean|null;
}
export class AgentSettings_ extends Serializable {
  constructor(parameters: AgentSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'enableSpeechLogging',
        (parameters.enableSpeechLogging == null) ?
            (null) :
            (parameters.enableSpeechLogging));
    this.Serializable$set(
        'enableSpeechRequestContext',
        (parameters.enableSpeechRequestContext == null) ?
            (null) :
            (parameters.enableSpeechRequestContext));
    this.Serializable$set(
        'enableCustomSpeechModels',
        (parameters.enableCustomSpeechModels == null) ?
            (null) :
            (parameters.enableCustomSpeechModels));
    this.Serializable$set(
        'endpointerSensitivity',
        (parameters.endpointerSensitivity == null) ?
            (null) :
            (parameters.endpointerSensitivity));
    this.Serializable$set(
        'textToSpeechSettings',
        (parameters.textToSpeechSettings == null) ?
            (null) :
            (parameters.textToSpeechSettings));
    this.Serializable$set(
        'customSpeechModelInfos',
        (parameters.customSpeechModelInfos == null) ?
            (null) :
            (parameters.customSpeechModelInfos));
    this.Serializable$set(
        'enableAgentWideTts',
        (parameters.enableAgentWideTts == null) ?
            (null) :
            (parameters.enableAgentWideTts));
    this.Serializable$set(
        'enableAgentWideKnowledgeConnector',
        (parameters.enableAgentWideKnowledgeConnector == null) ?
            (null) :
            (parameters.enableAgentWideKnowledgeConnector));
    this.Serializable$set(
        'agentProjectNumber',
        (parameters.agentProjectNumber == null) ?
            (null) :
            (parameters.agentProjectNumber));
    this.Serializable$set(
        'subAgents',
        (parameters.subAgents == null) ? (null) : (parameters.subAgents));
    this.Serializable$set(
        'consoleNotifications',
        (parameters.consoleNotifications == null) ?
            (null) :
            (parameters.consoleNotifications));
    this.Serializable$set(
        'legacyRoles',
        (parameters.legacyRoles == null) ? (null) : (parameters.legacyRoles));
    this.Serializable$set(
        'typeaheadData',
        (parameters.typeaheadData == null) ? (null) :
                                             (parameters.typeaheadData));
    this.Serializable$set(
        'agentLimits',
        (parameters.agentLimits == null) ? (null) : (parameters.agentLimits));
    this.Serializable$set(
        'knowledgeBaseNames',
        (parameters.knowledgeBaseNames == null) ?
            (null) :
            (parameters.knowledgeBaseNames));
    this.Serializable$set(
        'knowledgeConfidenceAdjustment',
        (parameters.knowledgeConfidenceAdjustment == null) ?
            (null) :
            (parameters.knowledgeConfidenceAdjustment));
    this.Serializable$set(
        'enableStackdriverLogging',
        (parameters.enableStackdriverLogging == null) ?
            (null) :
            (parameters.enableStackdriverLogging));
    this.Serializable$set(
        'enableValidation',
        (parameters.enableValidation == null) ? (null) :
                                                (parameters.enableValidation));
    this.Serializable$set(
        'enableSpellCorrection',
        (parameters.enableSpellCorrection == null) ?
            (null) :
            (parameters.enableSpellCorrection));
    this.Serializable$set(
        'disableAutomaticTraining',
        (parameters.disableAutomaticTraining == null) ?
            (null) :
            (parameters.disableAutomaticTraining));
    this.Serializable$set(
        'enableSentimentAnalysis',
        (parameters.enableSentimentAnalysis == null) ?
            (null) :
            (parameters.enableSentimentAnalysis));
    this.Serializable$set(
        'enableSmalltalk',
        (parameters.enableSmalltalk == null) ? (null) :
                                               (parameters.enableSmalltalk));
    this.Serializable$set(
        'enableInteractionLogging',
        (parameters.enableInteractionLogging == null) ?
            (null) :
            (parameters.enableInteractionLogging));
  }

  get agentLimits(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('agentLimits')) ?
            (this.Serializable$get('agentLimits')) :
            (null));
  }

  /**
   * Output only. Agent limits.
   */
  set agentLimits(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('agentLimits', value);
  }

  get agentProjectNumber(): string|null {
    return (
        (this.Serializable$has('agentProjectNumber')) ?
            (this.Serializable$get('agentProjectNumber')) :
            (null));
  }

  /**
   * Output only. The cloud project number associated with the agent. Beam
   * server need this project number to fill in tags and
   * data_logs_project_number_override in Speech requests.
   */
  set agentProjectNumber(value: string|null) {
    this.Serializable$set('agentProjectNumber', value);
  }

  get consoleNotifications(): Array<AgentSettingsConsoleNotifications_>|null {
    return (
        (this.Serializable$has('consoleNotifications')) ?
            (this.Serializable$get('consoleNotifications')) :
            (null));
  }

  /**
   * Output only. List of notifications to be shown in Dialogflow Console top
   * bar.
   */
  set consoleNotifications(value: Array<AgentSettingsConsoleNotifications_>|
                           null) {
    this.Serializable$set('consoleNotifications', value);
  }

  get customSpeechModelInfos(): DialogflowProtoPersistenceCustomSpeechModelInfos
      |null {
    return (
        (this.Serializable$has('customSpeechModelInfos')) ?
            (this.Serializable$get('customSpeechModelInfos')) :
            (null));
  }

  /**
   * Information related to custom agent speech models.
   */
  set customSpeechModelInfos(
      value: DialogflowProtoPersistenceCustomSpeechModelInfos|null) {
    this.Serializable$set('customSpeechModelInfos', value);
  }

  get disableAutomaticTraining(): boolean|null {
    return (
        (this.Serializable$has('disableAutomaticTraining')) ?
            (this.Serializable$get('disableAutomaticTraining')) :
            (null));
  }

  /**
   * Optional. Indicates if automatic agent retraining is disabled.
   */
  set disableAutomaticTraining(value: boolean|null) {
    this.Serializable$set('disableAutomaticTraining', value);
  }

  get enableAgentWideKnowledgeConnector(): boolean|null {
    return (
        (this.Serializable$has('enableAgentWideKnowledgeConnector')) ?
            (this.Serializable$get('enableAgentWideKnowledgeConnector')) :
            (null));
  }

  /**
   * Output only. Whether Knowledge Connector is enabled in this agent. Not real
   * field, represents presence of knowledge_base_names.
   */
  set enableAgentWideKnowledgeConnector(value: boolean|null) {
    this.Serializable$set('enableAgentWideKnowledgeConnector', value);
  }

  get enableAgentWideTts(): boolean|null {
    return (
        (this.Serializable$has('enableAgentWideTts')) ?
            (this.Serializable$get('enableAgentWideTts')) :
            (null));
  }

  /**
   * Output only.
   */
  set enableAgentWideTts(value: boolean|null) {
    this.Serializable$set('enableAgentWideTts', value);
  }

  get enableCustomSpeechModels(): boolean|null {
    return (
        (this.Serializable$has('enableCustomSpeechModels')) ?
            (this.Serializable$get('enableCustomSpeechModels')) :
            (null));
  }

  /**
   * Whether custom speech models are enabled.
   */
  set enableCustomSpeechModels(value: boolean|null) {
    this.Serializable$set('enableCustomSpeechModels', value);
  }

  get enableInteractionLogging(): boolean|null {
    return (
        (this.Serializable$has('enableInteractionLogging')) ?
            (this.Serializable$get('enableInteractionLogging')) :
            (null));
  }

  /**
   * Optional. Indicates if interaction logging is enabled.
   */
  set enableInteractionLogging(value: boolean|null) {
    this.Serializable$set('enableInteractionLogging', value);
  }

  get enableSentimentAnalysis(): boolean|null {
    return (
        (this.Serializable$has('enableSentimentAnalysis')) ?
            (this.Serializable$get('enableSentimentAnalysis')) :
            (null));
  }

  /**
   * Optional. Indicates if sentiment analysis is enabled.
   */
  set enableSentimentAnalysis(value: boolean|null) {
    this.Serializable$set('enableSentimentAnalysis', value);
  }

  get enableSmalltalk(): boolean|null {
    return (
        (this.Serializable$has('enableSmalltalk')) ?
            (this.Serializable$get('enableSmalltalk')) :
            (null));
  }

  /**
   * Optional. Indicates if smalltalk is enabled.
   */
  set enableSmalltalk(value: boolean|null) {
    this.Serializable$set('enableSmalltalk', value);
  }

  get enableSpeechLogging(): boolean|null {
    return (
        (this.Serializable$has('enableSpeechLogging')) ?
            (this.Serializable$get('enableSpeechLogging')) :
            (null));
  }

  /**
   * Whether speech data logging is enabled. See
   * https://cloud.google.com/speech-to-text/docs/data-logging for more
   * information.
   */
  set enableSpeechLogging(value: boolean|null) {
    this.Serializable$set('enableSpeechLogging', value);
  }

  get enableSpeechRequestContext(): boolean|null {
    return (
        (this.Serializable$has('enableSpeechRequestContext')) ?
            (this.Serializable$get('enableSpeechRequestContext')) :
            (null));
  }

  /**
   * Whether to use speech request context for speech recognition.
   */
  set enableSpeechRequestContext(value: boolean|null) {
    this.Serializable$set('enableSpeechRequestContext', value);
  }

  get enableSpellCorrection(): boolean|null {
    return (
        (this.Serializable$has('enableSpellCorrection')) ?
            (this.Serializable$get('enableSpellCorrection')) :
            (null));
  }

  /**
   * Optional. Indicates if automatic spell correction is enabled.
   */
  set enableSpellCorrection(value: boolean|null) {
    this.Serializable$set('enableSpellCorrection', value);
  }

  get enableStackdriverLogging(): boolean|null {
    return (
        (this.Serializable$has('enableStackdriverLogging')) ?
            (this.Serializable$get('enableStackdriverLogging')) :
            (null));
  }

  /**
   * Optional. Indicates if stackdriver logging is enabled for the agent.
   */
  set enableStackdriverLogging(value: boolean|null) {
    this.Serializable$set('enableStackdriverLogging', value);
  }

  get enableValidation(): boolean|null {
    return (
        (this.Serializable$has('enableValidation')) ?
            (this.Serializable$get('enableValidation')) :
            (null));
  }

  /**
   * Optional. Indicates if automatic agent validation is enabled.
   */
  set enableValidation(value: boolean|null) {
    this.Serializable$set('enableValidation', value);
  }

  get endpointerSensitivity(): number|null {
    return (
        (this.Serializable$has('endpointerSensitivity')) ?
            (this.Serializable$get('endpointerSensitivity')) :
            (null));
  }

  /**
   * Optional. Value between 0 and 100 indicating endpointer sensitivity. The
   * higher this value, the shorter the wait before closing the microphone.
   */
  set endpointerSensitivity(value: number|null) {
    this.Serializable$set('endpointerSensitivity', value);
  }

  get knowledgeBaseNames(): Array<string>|null {
    return (
        (this.Serializable$has('knowledgeBaseNames')) ?
            (this.Serializable$get('knowledgeBaseNames')) :
            (null));
  }

  /**
   * Optional. KnowledgeBases to get alternative results from. If set in the
   * request, the request-level names take precedence. If not set on either the
   * agent or the request, Knowledge Connectors query is not performed. Format:
   * `projects//locations//knowledgeBases/`.
   */
  set knowledgeBaseNames(value: Array<string>|null) {
    this.Serializable$set('knowledgeBaseNames', value);
  }

  get knowledgeConfidenceAdjustment(): number|null {
    return (
        (this.Serializable$has('knowledgeConfidenceAdjustment')) ?
            (this.Serializable$get('knowledgeConfidenceAdjustment')) :
            (null));
  }

  /**
   * Optional. A value (positive or negative) added to the confidence score
   * returned by the Knowledge Service response. This adjusted score is then
   * used for ranking against the normal DetectIntent response. If not set, an
   * internal default value is used.
   */
  set knowledgeConfidenceAdjustment(value: number|null) {
    this.Serializable$set('knowledgeConfidenceAdjustment', value);
  }

  get legacyRoles(): Array<string>|null {
    return (
        (this.Serializable$has('legacyRoles')) ?
            (this.Serializable$get('legacyRoles')) :
            (null));
  }

  /**
   * Output only. List of legacy Dialogflow roles.
   */
  set legacyRoles(value: Array<string>|null) {
    this.Serializable$set('legacyRoles', value);
  }

  get subAgents(): Array<SubAgent_>|null {
    return (
        (this.Serializable$has('subAgents')) ?
            (this.Serializable$get('subAgents')) :
            (null));
  }

  /**
   * Output only. An internal field to cache sub agents of a mega agent in the
   * FE server, for IAM check. It's not updatable through AgentSettings APIs.
   */
  set subAgents(value: Array<SubAgent_>|null) {
    this.Serializable$set('subAgents', value);
  }

  get textToSpeechSettings(): TextToSpeechSettings_|null {
    return (
        (this.Serializable$has('textToSpeechSettings')) ?
            (this.Serializable$get('textToSpeechSettings')) :
            (null));
  }

  /**
   * Optional. Text to speech settings for this environment.
   */
  set textToSpeechSettings(value: TextToSpeechSettings_|null) {
    this.Serializable$set('textToSpeechSettings', value);
  }

  get typeaheadData(): AgentSettingsTypeaheadData_|null {
    return (
        (this.Serializable$has('typeaheadData')) ?
            (this.Serializable$get('typeaheadData')) :
            (null));
  }

  /**
   * Output only. Data needed for typeahead help in the console.
   */
  set typeaheadData(value: AgentSettingsTypeaheadData_|null) {
    this.Serializable$set('typeaheadData', value);
  }

  getConstructor(): SerializableCtor<AgentSettings_> {
    return AgentSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'consoleNotifications': AgentSettingsConsoleNotifications_,
        'subAgents': SubAgent_
      },
      keys: [
        'agentLimits',
        'agentProjectNumber',
        'consoleNotifications',
        'customSpeechModelInfos',
        'disableAutomaticTraining',
        'enableAgentWideKnowledgeConnector',
        'enableAgentWideTts',
        'enableCustomSpeechModels',
        'enableInteractionLogging',
        'enableSentimentAnalysis',
        'enableSmalltalk',
        'enableSpeechLogging',
        'enableSpeechRequestContext',
        'enableSpellCorrection',
        'enableStackdriverLogging',
        'enableValidation',
        'endpointerSensitivity',
        'knowledgeBaseNames',
        'knowledgeConfidenceAdjustment',
        'legacyRoles',
        'subAgents',
        'textToSpeechSettings',
        'typeaheadData'
      ],
      objectMaps: {
        'agentLimits': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'customSpeechModelInfos':
            DialogflowProtoPersistenceCustomSpeechModelInfos,
        'textToSpeechSettings': TextToSpeechSettings_,
        'typeaheadData': AgentSettingsTypeaheadData_
      }
    };
  }
}

export interface AgentTier_Parameters {
  parent?: string|null;
  tier?: AgentTier_Tier|null;
}
export class AgentTier_ extends Serializable {
  constructor(parameters: AgentTier_Parameters = {}) {
    super();
    this.Serializable$set(
        'parent', (parameters.parent == null) ? (null) : (parameters.parent));
    this.Serializable$set(
        'tier', (parameters.tier == null) ? (null) : (parameters.tier));
  }

  static get Tier(): IAgentTier_TierEnum {
    return AgentTier_TierEnum;
  }

  get parent(): string|null {
    return (
        (this.Serializable$has('parent')) ? (this.Serializable$get('parent')) :
                                            (null));
  }

  /**
   * Required. The project of this agent tier. Format: `projects/`.
   */
  set parent(value: string|null) {
    this.Serializable$set('parent', value);
  }

  get tier(): AgentTier_Tier|null {
    return (
        (this.Serializable$has('tier')) ? (this.Serializable$get('tier')) :
                                          (null));
  }

  /**
   * Required. The agent tier.
   */
  set tier(value: AgentTier_Tier|null) {
    this.Serializable$set('tier', value);
  }

  getConstructor(): SerializableCtor<AgentTier_> {
    return AgentTier_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {enums: {'tier': AgentTier_TierEnum}, keys: ['parent', 'tier']};
  }
}

export interface AgentVisibility_Parameters {
  labels?: Array<string>|null;
}
export class AgentVisibility_ extends Serializable {
  constructor(parameters: AgentVisibility_Parameters = {}) {
    super();
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
  }

  get labels(): Array<string>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * The list of visibility labels applied to the agent's cloud project.
   */
  set labels(value: Array<string>|null) {
    this.Serializable$set('labels', value);
  }

  getConstructor(): SerializableCtor<AgentVisibility_> {
    return AgentVisibility_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['labels']};
  }
}

export interface Agent_Parameters {
  parent?: string|null;
  displayName?: string|null;
  defaultLanguageCode?: string|null;
  supportedLanguageCodes?: Array<string>|null;
  timeZone?: string|null;
  description?: string|null;
  avatarUri?: string|null;
  enableLogging?: boolean|null;
  matchMode?: Agent_MatchMode|null;
  classificationThreshold?: number|null;
  subAgents?: Array<SubAgent_>|null;
  megaAgentSettings?: MegaAgentSettings_|null;
  projectNumber?: string|null;
  apiVersion?: Agent_ApiVersion|null;
  tier?: Agent_Tier|null;
  agentId?: string|null;
  testExperimentEnabled?: boolean|null;
}
export class Agent_ extends Serializable {
  constructor(parameters: Agent_Parameters = {}) {
    super();
    this.Serializable$set(
        'parent', (parameters.parent == null) ? (null) : (parameters.parent));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'defaultLanguageCode',
        (parameters.defaultLanguageCode == null) ?
            (null) :
            (parameters.defaultLanguageCode));
    this.Serializable$set(
        'supportedLanguageCodes',
        (parameters.supportedLanguageCodes == null) ?
            (null) :
            (parameters.supportedLanguageCodes));
    this.Serializable$set(
        'timeZone',
        (parameters.timeZone == null) ? (null) : (parameters.timeZone));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'avatarUri',
        (parameters.avatarUri == null) ? (null) : (parameters.avatarUri));
    this.Serializable$set(
        'enableLogging',
        (parameters.enableLogging == null) ? (null) :
                                             (parameters.enableLogging));
    this.Serializable$set(
        'matchMode',
        (parameters.matchMode == null) ? (null) : (parameters.matchMode));
    this.Serializable$set(
        'classificationThreshold',
        (parameters.classificationThreshold == null) ?
            (null) :
            (parameters.classificationThreshold));
    this.Serializable$set(
        'subAgents',
        (parameters.subAgents == null) ? (null) : (parameters.subAgents));
    this.Serializable$set(
        'megaAgentSettings',
        (parameters.megaAgentSettings == null) ?
            (null) :
            (parameters.megaAgentSettings));
    this.Serializable$set(
        'projectNumber',
        (parameters.projectNumber == null) ? (null) :
                                             (parameters.projectNumber));
    this.Serializable$set(
        'apiVersion',
        (parameters.apiVersion == null) ? (null) : (parameters.apiVersion));
    this.Serializable$set(
        'tier', (parameters.tier == null) ? (null) : (parameters.tier));
    this.Serializable$set(
        'agentId',
        (parameters.agentId == null) ? (null) : (parameters.agentId));
    this.Serializable$set(
        'testExperimentEnabled',
        (parameters.testExperimentEnabled == null) ?
            (null) :
            (parameters.testExperimentEnabled));
  }

  static get ApiVersion(): IAgent_ApiVersionEnum {
    return Agent_ApiVersionEnum;
  }

  static get MatchMode(): IAgent_MatchModeEnum {
    return Agent_MatchModeEnum;
  }

  static get Tier(): IAgent_TierEnum {
    return Agent_TierEnum;
  }

  get agentId(): string|null {
    return (
        (this.Serializable$has('agentId')) ?
            (this.Serializable$get('agentId')) :
            (null));
  }

  /**
   * Output only. Internal agent id, used for UI compatibility purposes.
   */
  set agentId(value: string|null) {
    this.Serializable$set('agentId', value);
  }

  get apiVersion(): Agent_ApiVersion|null {
    return (
        (this.Serializable$has('apiVersion')) ?
            (this.Serializable$get('apiVersion')) :
            (null));
  }

  /**
   * Optional. API version displayed in Dialogflow console. If not specified, V2
   * API is assumed. Clients are free to query different service endpoints for
   * different API versions. However, bots connectors and webhook calls will
   * follow the specified API version.
   */
  set apiVersion(value: Agent_ApiVersion|null) {
    this.Serializable$set('apiVersion', value);
  }

  get avatarUri(): string|null {
    return (
        (this.Serializable$has('avatarUri')) ?
            (this.Serializable$get('avatarUri')) :
            (null));
  }

  /**
   * Optional. The URI of the agent's avatar. Avatars are used throughout the
   * Dialogflow console and in the self-hosted [Web
   * Demo](https://cloud.google.com/dialogflow/docs/integrations/web-demo)
   * integration.
   */
  set avatarUri(value: string|null) {
    this.Serializable$set('avatarUri', value);
  }

  get classificationThreshold(): number|null {
    return (
        (this.Serializable$has('classificationThreshold')) ?
            (this.Serializable$get('classificationThreshold')) :
            (null));
  }

  /**
   * Optional. To filter out false positive results and still get variety in
   * matched natural language inputs for your agent, you can tune the machine
   * learning classification threshold. If the returned score value is less than
   * the threshold value, then a fallback intent will be triggered or, if there
   * are no fallback intents defined, no intent will be triggered. The score
   * values range from 0.0 (completely uncertain) to 1.0 (completely certain).
   * If set to 0.0, the default of 0.3 is used.
   */
  set classificationThreshold(value: number|null) {
    this.Serializable$set('classificationThreshold', value);
  }

  get defaultLanguageCode(): string|null {
    return (
        (this.Serializable$has('defaultLanguageCode')) ?
            (this.Serializable$get('defaultLanguageCode')) :
            (null));
  }

  /**
   * Required. The default language of the agent as a language tag. See
   * [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes. This field cannot be set by
   * the `Update` method.
   */
  set defaultLanguageCode(value: string|null) {
    this.Serializable$set('defaultLanguageCode', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. The description of this agent. The maximum length is 500
   * characters. If exceeded, the request is rejected.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The name of this agent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get enableLogging(): boolean|null {
    return (
        (this.Serializable$has('enableLogging')) ?
            (this.Serializable$get('enableLogging')) :
            (null));
  }

  /**
   * Optional. Determines whether this agent should log conversation queries.
   */
  set enableLogging(value: boolean|null) {
    this.Serializable$set('enableLogging', value);
  }

  get matchMode(): Agent_MatchMode|null {
    return (
        (this.Serializable$has('matchMode')) ?
            (this.Serializable$get('matchMode')) :
            (null));
  }

  /**
   * Optional. Determines how intents are detected from user queries.
   */
  set matchMode(value: Agent_MatchMode|null) {
    this.Serializable$set('matchMode', value);
  }

  get megaAgentSettings(): MegaAgentSettings_|null {
    return (
        (this.Serializable$has('megaAgentSettings')) ?
            (this.Serializable$get('megaAgentSettings')) :
            (null));
  }

  /**
   * Optional. Presence of `mega_agent_settings` indicates this agent is a
   * mega-agent. Note: mega-agent should be used as a simple container for
   * sub-agents. Mega-agent's intents are not used for intent matching.
   */
  set megaAgentSettings(value: MegaAgentSettings_|null) {
    this.Serializable$set('megaAgentSettings', value);
  }

  get parent(): string|null {
    return (
        (this.Serializable$has('parent')) ? (this.Serializable$get('parent')) :
                                            (null));
  }

  /**
   * Required. The project of this agent. Format: `projects/` or
   * `projects//locations/`
   */
  set parent(value: string|null) {
    this.Serializable$set('parent', value);
  }

  get projectNumber(): string|null {
    return (
        (this.Serializable$has('projectNumber')) ?
            (this.Serializable$get('projectNumber')) :
            (null));
  }

  /**
   * The project number associated with the project id in `parent`. Internally
   * used to pass the project number to DF backend.
   */
  set projectNumber(value: string|null) {
    this.Serializable$set('projectNumber', value);
  }

  get subAgents(): Array<SubAgent_>|null {
    return (
        (this.Serializable$has('subAgents')) ?
            (this.Serializable$get('subAgents')) :
            (null));
  }

  /**
   * Optional. Presence of `sub_agents` indicates this agent is a mega-agent.
   * Note: mega-agent should be used as a simple container for sub-agents.
   * Mega-agent's intents are not used for intent matching.
   */
  set subAgents(value: Array<SubAgent_>|null) {
    this.Serializable$set('subAgents', value);
  }

  get supportedLanguageCodes(): Array<string>|null {
    return (
        (this.Serializable$has('supportedLanguageCodes')) ?
            (this.Serializable$get('supportedLanguageCodes')) :
            (null));
  }

  /**
   * Optional. The list of all languages supported by this agent (except for the
   * `default_language_code`).
   */
  set supportedLanguageCodes(value: Array<string>|null) {
    this.Serializable$set('supportedLanguageCodes', value);
  }

  get testExperimentEnabled(): boolean|null {
    return (
        (this.Serializable$has('testExperimentEnabled')) ?
            (this.Serializable$get('testExperimentEnabled')) :
            (null));
  }

  /**
   * Internal field for testing fast experiment disabling. Will be true if the
   * underlying test experiment is enabled.
   */
  set testExperimentEnabled(value: boolean|null) {
    this.Serializable$set('testExperimentEnabled', value);
  }

  get tier(): Agent_Tier|null {
    return (
        (this.Serializable$has('tier')) ? (this.Serializable$get('tier')) :
                                          (null));
  }

  /**
   * Optional. The agent tier. If not specified, TIER_STANDARD is assumed.
   */
  set tier(value: Agent_Tier|null) {
    this.Serializable$set('tier', value);
  }

  get timeZone(): string|null {
    return (
        (this.Serializable$has('timeZone')) ?
            (this.Serializable$get('timeZone')) :
            (null));
  }

  /**
   * Required. The time zone of this agent from the [time zone
   * database](https://www.iana.org/time-zones), e.g., America/New_York,
   * Europe/Paris.
   */
  set timeZone(value: string|null) {
    this.Serializable$set('timeZone', value);
  }

  getConstructor(): SerializableCtor<Agent_> {
    return Agent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'subAgents': SubAgent_},
      enums: {
        'apiVersion': Agent_ApiVersionEnum,
        'matchMode': Agent_MatchModeEnum,
        'tier': Agent_TierEnum
      },
      keys: [
        'agentId', 'apiVersion', 'avatarUri', 'classificationThreshold',
        'defaultLanguageCode', 'description', 'displayName', 'enableLogging',
        'matchMode', 'megaAgentSettings', 'parent', 'projectNumber',
        'subAgents', 'supportedLanguageCodes', 'testExperimentEnabled', 'tier',
        'timeZone'
      ],
      objects: {'megaAgentSettings': MegaAgentSettings_}
    };
  }
}

export interface AnalyzeContentRequest_Parameters {
  text?: InputText_|null;
  audio?: InputAudio_|null;
  textInput?: TextInput_|null;
  audioInput?: AudioInput_|null;
  eventInput?: EventInput_|null;
  suggestionInput?: SuggestionInput_|null;
  intentInput?: IntentInput_|null;
  replyAudioConfig?: OutputAudioConfig_|null;
  queryParams?: QueryParameters_|null;
  assistQueryParams?: AssistQueryParameters_|null;
  cxParameters?: ApiClientObjectMap<any>|null;
  cxParameterScope?: string|null;
  cxCurrentPage?: string|null;
  messageSendTime?: string|null;
  requestId?: string|null;
  obfuscatedExternalUserId?: string|null;
  speechToTextInfo?: MessageSpeechToTextInfo_|null;
}
export class AnalyzeContentRequest_ extends Serializable {
  constructor(parameters: AnalyzeContentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
    this.Serializable$set(
        'textInput',
        (parameters.textInput == null) ? (null) : (parameters.textInput));
    this.Serializable$set(
        'audioInput',
        (parameters.audioInput == null) ? (null) : (parameters.audioInput));
    this.Serializable$set(
        'eventInput',
        (parameters.eventInput == null) ? (null) : (parameters.eventInput));
    this.Serializable$set(
        'suggestionInput',
        (parameters.suggestionInput == null) ? (null) :
                                               (parameters.suggestionInput));
    this.Serializable$set(
        'intentInput',
        (parameters.intentInput == null) ? (null) : (parameters.intentInput));
    this.Serializable$set(
        'replyAudioConfig',
        (parameters.replyAudioConfig == null) ? (null) :
                                                (parameters.replyAudioConfig));
    this.Serializable$set(
        'queryParams',
        (parameters.queryParams == null) ? (null) : (parameters.queryParams));
    this.Serializable$set(
        'assistQueryParams',
        (parameters.assistQueryParams == null) ?
            (null) :
            (parameters.assistQueryParams));
    this.Serializable$set(
        'cxParameters',
        (parameters.cxParameters == null) ? (null) : (parameters.cxParameters));
    this.Serializable$set(
        'cxParameterScope',
        (parameters.cxParameterScope == null) ? (null) :
                                                (parameters.cxParameterScope));
    this.Serializable$set(
        'cxCurrentPage',
        (parameters.cxCurrentPage == null) ? (null) :
                                             (parameters.cxCurrentPage));
    this.Serializable$set(
        'messageSendTime',
        (parameters.messageSendTime == null) ? (null) :
                                               (parameters.messageSendTime));
    this.Serializable$set(
        'requestId',
        (parameters.requestId == null) ? (null) : (parameters.requestId));
    this.Serializable$set(
        'obfuscatedExternalUserId',
        (parameters.obfuscatedExternalUserId == null) ?
            (null) :
            (parameters.obfuscatedExternalUserId));
    this.Serializable$set(
        'speechToTextInfo',
        (parameters.speechToTextInfo == null) ? (null) :
                                                (parameters.speechToTextInfo));
  }

  get assistQueryParams(): AssistQueryParameters_|null {
    return (
        (this.Serializable$has('assistQueryParams')) ?
            (this.Serializable$get('assistQueryParams')) :
            (null));
  }

  /**
   * Parameters for a human assist query.
   */
  set assistQueryParams(value: AssistQueryParameters_|null) {
    this.Serializable$set('assistQueryParams', value);
  }

  get audio(): InputAudio_|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * The natural language speech audio to be processed.
   */
  set audio(value: InputAudio_|null) {
    this.Serializable$set('audio', value);
  }

  get audioInput(): AudioInput_|null {
    return (
        (this.Serializable$has('audioInput')) ?
            (this.Serializable$get('audioInput')) :
            (null));
  }

  /**
   * The natural language speech audio to be processed.
   */
  set audioInput(value: AudioInput_|null) {
    this.Serializable$set('audioInput', value);
  }

  get cxCurrentPage(): string|null {
    return (
        (this.Serializable$has('cxCurrentPage')) ?
            (this.Serializable$get('cxCurrentPage')) :
            (null));
  }

  /**
   * The unique identifier of the CX page to override the `current_page` in the
   * session. Format: `projects//locations//agents//flows//pages/`. If
   * `cx_current_page` is specified, the previous state of the session will be
   * ignored by Dialogflow CX, including the previous page and the previous
   * session parameters. In most cases, `cx_current_page` and `cx_parameters`
   * should be configured together to direct a session to a specific state.
   * Note: this field should only be used if you are connecting to a Dialogflow
   * CX agent.
   */
  set cxCurrentPage(value: string|null) {
    this.Serializable$set('cxCurrentPage', value);
  }

  get cxParameterScope(): string|null {
    return (
        (this.Serializable$has('cxParameterScope')) ?
            (this.Serializable$get('cxParameterScope')) :
            (null));
  }

  /**
   * Scope for the CX parameters. If not specified, parameters will be treated
   * as session parameters. Note: this field should only be used if you are
   * connecting to a Dialogflow CX agent.
   */
  set cxParameterScope(value: string|null) {
    this.Serializable$set('cxParameterScope', value);
  }

  get cxParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('cxParameters')) ?
            (this.Serializable$get('cxParameters')) :
            (null));
  }

  /**
   * Additional parameters to be put into Dialogflow CX session parameters. To
   * remove a parameter from the session, clients should explicitly set the
   * parameter value to null. Note: this field should only be used if you are
   * connecting to a Dialogflow CX agent.
   */
  set cxParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('cxParameters', value);
  }

  get eventInput(): EventInput_|null {
    return (
        (this.Serializable$has('eventInput')) ?
            (this.Serializable$get('eventInput')) :
            (null));
  }

  /**
   * An input event to send to Dialogflow.
   */
  set eventInput(value: EventInput_|null) {
    this.Serializable$set('eventInput', value);
  }

  get intentInput(): IntentInput_|null {
    return (
        (this.Serializable$has('intentInput')) ?
            (this.Serializable$get('intentInput')) :
            (null));
  }

  /**
   * The intent to be triggered on V3 agent.
   */
  set intentInput(value: IntentInput_|null) {
    this.Serializable$set('intentInput', value);
  }

  get messageSendTime(): string|null {
    return (
        (this.Serializable$has('messageSendTime')) ?
            (this.Serializable$get('messageSendTime')) :
            (null));
  }

  /**
   * Optional. The send time of the message from end user or human agent's
   * perspective. It is used for identifying the same message under one
   * participant. Given two messages under the same participant: - If send time
   * are different regardless of whether the content of the messages are exactly
   * the same, the conversation will regard them as two distinct messages sent
   * by the participant. - If send time is the same regardless of whether the
   * content of the messages are exactly the same, the conversation will regard
   * them as same message, and ignore the message received later. If the value
   * is not provided, a new request will always be regarded as a new message
   * without any de-duplication.
   */
  set messageSendTime(value: string|null) {
    this.Serializable$set('messageSendTime', value);
  }

  get obfuscatedExternalUserId(): string|null {
    return (
        (this.Serializable$has('obfuscatedExternalUserId')) ?
            (this.Serializable$get('obfuscatedExternalUserId')) :
            (null));
  }

  /**
   * Obfuscated user id that should be associated with the participant. See
   * Participant for details.
   */
  set obfuscatedExternalUserId(value: string|null) {
    this.Serializable$set('obfuscatedExternalUserId', value);
  }

  get queryParams(): QueryParameters_|null {
    return (
        (this.Serializable$has('queryParams')) ?
            (this.Serializable$get('queryParams')) :
            (null));
  }

  /**
   * Parameters for a Dialogflow virtual-agent query.
   */
  set queryParams(value: QueryParameters_|null) {
    this.Serializable$set('queryParams', value);
  }

  get replyAudioConfig(): OutputAudioConfig_|null {
    return (
        (this.Serializable$has('replyAudioConfig')) ?
            (this.Serializable$get('replyAudioConfig')) :
            (null));
  }

  /**
   * Speech synthesis configuration. The speech synthesis settings for a virtual
   * agent that may be configured for the associated conversation profile are
   * not used when calling AnalyzeContent. If this configuration is not
   * supplied, speech synthesis is disabled.
   */
  set replyAudioConfig(value: OutputAudioConfig_|null) {
    this.Serializable$set('replyAudioConfig', value);
  }

  get requestId(): string|null {
    return (
        (this.Serializable$has('requestId')) ?
            (this.Serializable$get('requestId')) :
            (null));
  }

  /**
   * A unique identifier for this request. Restricted to 36 ASCII characters. A
   * random UUID is recommended. This request is only idempotent if a
   * `request_id` is provided.
   */
  set requestId(value: string|null) {
    this.Serializable$set('requestId', value);
  }

  get speechToTextInfo(): MessageSpeechToTextInfo_|null {
    return (
        (this.Serializable$has('speechToTextInfo')) ?
            (this.Serializable$get('speechToTextInfo')) :
            (null));
  }

  /**
   * Additional speech to text info populated from telehony server. This field
   * should only be used by telehony server.
   */
  set speechToTextInfo(value: MessageSpeechToTextInfo_|null) {
    this.Serializable$set('speechToTextInfo', value);
  }

  get suggestionInput(): SuggestionInput_|null {
    return (
        (this.Serializable$has('suggestionInput')) ?
            (this.Serializable$get('suggestionInput')) :
            (null));
  }

  /**
   * An input representing the selection of a suggestion.
   */
  set suggestionInput(value: SuggestionInput_|null) {
    this.Serializable$set('suggestionInput', value);
  }

  get text(): InputText_|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The natural language text to be processed.
   */
  set text(value: InputText_|null) {
    this.Serializable$set('text', value);
  }

  get textInput(): TextInput_|null {
    return (
        (this.Serializable$has('textInput')) ?
            (this.Serializable$get('textInput')) :
            (null));
  }

  /**
   * The natural language text to be processed.
   */
  set textInput(value: TextInput_|null) {
    this.Serializable$set('textInput', value);
  }

  getConstructor(): SerializableCtor<AnalyzeContentRequest_> {
    return AnalyzeContentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'assistQueryParams', 'audio', 'audioInput', 'cxCurrentPage',
        'cxParameterScope', 'cxParameters', 'eventInput', 'intentInput',
        'messageSendTime', 'obfuscatedExternalUserId', 'queryParams',
        'replyAudioConfig', 'requestId', 'speechToTextInfo', 'suggestionInput',
        'text', 'textInput'
      ],
      objectMaps: {
        'cxParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'assistQueryParams': AssistQueryParameters_,
        'audio': InputAudio_,
        'audioInput': AudioInput_,
        'eventInput': EventInput_,
        'intentInput': IntentInput_,
        'queryParams': QueryParameters_,
        'replyAudioConfig': OutputAudioConfig_,
        'speechToTextInfo': MessageSpeechToTextInfo_,
        'suggestionInput': SuggestionInput_,
        'text': InputText_,
        'textInput': TextInput_
      }
    };
  }
}

export interface AnalyzeContentResponse_Parameters {
  replyText?: string|null;
  replyAudio?: OutputAudio_|null;
  automatedAgentReply?: AutomatedAgentReply_|null;
  message?: Message_|null;
  humanAgentSuggestionResults?: Array<SuggestionResult_>|null;
  endUserSuggestionResults?: Array<SuggestionResult_>|null;
  recordedAudioUri?: string|null;
  dtmfParameters?: DtmfParameters_|null;
}
export class AnalyzeContentResponse_ extends Serializable {
  constructor(parameters: AnalyzeContentResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'replyText',
        (parameters.replyText == null) ? (null) : (parameters.replyText));
    this.Serializable$set(
        'replyAudio',
        (parameters.replyAudio == null) ? (null) : (parameters.replyAudio));
    this.Serializable$set(
        'automatedAgentReply',
        (parameters.automatedAgentReply == null) ?
            (null) :
            (parameters.automatedAgentReply));
    this.Serializable$set(
        'message',
        (parameters.message == null) ? (null) : (parameters.message));
    this.Serializable$set(
        'humanAgentSuggestionResults',
        (parameters.humanAgentSuggestionResults == null) ?
            (null) :
            (parameters.humanAgentSuggestionResults));
    this.Serializable$set(
        'endUserSuggestionResults',
        (parameters.endUserSuggestionResults == null) ?
            (null) :
            (parameters.endUserSuggestionResults));
    this.Serializable$set(
        'recordedAudioUri',
        (parameters.recordedAudioUri == null) ? (null) :
                                                (parameters.recordedAudioUri));
    this.Serializable$set(
        'dtmfParameters',
        (parameters.dtmfParameters == null) ? (null) :
                                              (parameters.dtmfParameters));
  }

  get automatedAgentReply(): AutomatedAgentReply_|null {
    return (
        (this.Serializable$has('automatedAgentReply')) ?
            (this.Serializable$get('automatedAgentReply')) :
            (null));
  }

  /**
   * Optional. Only set if a Dialogflow automated agent has responded. Note
   * that: AutomatedAgentReply.detect_intent_response.output_audio and
   * AutomatedAgentReply.detect_intent_response.output_audio_config are always
   * empty, use reply_audio instead.
   */
  set automatedAgentReply(value: AutomatedAgentReply_|null) {
    this.Serializable$set('automatedAgentReply', value);
  }

  get dtmfParameters(): DtmfParameters_|null {
    return (
        (this.Serializable$has('dtmfParameters')) ?
            (this.Serializable$get('dtmfParameters')) :
            (null));
  }

  /**
   * Indicates the parameters of DTMF.
   */
  set dtmfParameters(value: DtmfParameters_|null) {
    this.Serializable$set('dtmfParameters', value);
  }

  get endUserSuggestionResults(): Array<SuggestionResult_>|null {
    return (
        (this.Serializable$has('endUserSuggestionResults')) ?
            (this.Serializable$get('endUserSuggestionResults')) :
            (null));
  }

  /**
   * The suggestions for end user. The order is the same as
   * HumanAgentAssistantConfig.SuggestionConfig.feature_configs of
   * HumanAgentAssistantConfig.end_user_suggestion_config. Same as
   * human_agent_suggestion_results, any failure of Agent Assist features will
   * not lead to the overall failure of an AnalyzeContent API call. Instead, the
   * features will fail silently with the error field set in the corresponding
   * SuggestionResult.
   */
  set endUserSuggestionResults(value: Array<SuggestionResult_>|null) {
    this.Serializable$set('endUserSuggestionResults', value);
  }

  get humanAgentSuggestionResults(): Array<SuggestionResult_>|null {
    return (
        (this.Serializable$has('humanAgentSuggestionResults')) ?
            (this.Serializable$get('humanAgentSuggestionResults')) :
            (null));
  }

  /**
   * The suggestions for most recent human agent. The order is the same as
   * HumanAgentAssistantConfig.SuggestionConfig.feature_configs of
   * HumanAgentAssistantConfig.human_agent_suggestion_config. Note that any
   * failure of Agent Assist features will not lead to the overall failure of an
   * AnalyzeContent API call. Instead, the features will fail silently with the
   * error field set in the corresponding SuggestionResult.
   */
  set humanAgentSuggestionResults(value: Array<SuggestionResult_>|null) {
    this.Serializable$set('humanAgentSuggestionResults', value);
  }

  get message(): Message_|null {
    return (
        (this.Serializable$has('message')) ?
            (this.Serializable$get('message')) :
            (null));
  }

  /**
   * Output only. Message analyzed by CCAI.
   */
  set message(value: Message_|null) {
    this.Serializable$set('message', value);
  }

  get recordedAudioUri(): string|null {
    return (
        (this.Serializable$has('recordedAudioUri')) ?
            (this.Serializable$get('recordedAudioUri')) :
            (null));
  }

  /**
   * The Cloud Storage path used for audio storage if audio export is enabled in
   * the security setting. For example: gs://bucket_name/object_name.
   */
  set recordedAudioUri(value: string|null) {
    this.Serializable$set('recordedAudioUri', value);
  }

  get replyAudio(): OutputAudio_|null {
    return (
        (this.Serializable$has('replyAudio')) ?
            (this.Serializable$get('replyAudio')) :
            (null));
  }

  /**
   * Optional. The audio data bytes encoded as specified in the request. This
   * field is set if: - `reply_audio_config` was specified in the request, or -
   * The automated agent responded with audio to play to the user. In such case,
   * `reply_audio.config` contains settings used to synthesize the speech. In
   * some scenarios, multiple output audio fields may be present in the response
   * structure. In these cases, only the top-most-level audio output has
   * content.
   */
  set replyAudio(value: OutputAudio_|null) {
    this.Serializable$set('replyAudio', value);
  }

  get replyText(): string|null {
    return (
        (this.Serializable$has('replyText')) ?
            (this.Serializable$get('replyText')) :
            (null));
  }

  /**
   * Output only. The output text content. This field is set if the automated
   * agent responded with text to show to the user.
   */
  set replyText(value: string|null) {
    this.Serializable$set('replyText', value);
  }

  getConstructor(): SerializableCtor<AnalyzeContentResponse_> {
    return AnalyzeContentResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'endUserSuggestionResults': SuggestionResult_,
        'humanAgentSuggestionResults': SuggestionResult_
      },
      keys: [
        'automatedAgentReply', 'dtmfParameters', 'endUserSuggestionResults',
        'humanAgentSuggestionResults', 'message', 'recordedAudioUri',
        'replyAudio', 'replyText'
      ],
      objects: {
        'automatedAgentReply': AutomatedAgentReply_,
        'dtmfParameters': DtmfParameters_,
        'message': Message_,
        'replyAudio': OutputAudio_
      }
    };
  }
}

export interface AnnotateTrainingPhraseRequest_Parameters {
  trainingPhrase?: string|null;
  annotatedTrainingPhrases?: Array<IntentTrainingPhrase_>|null;
  parameters?: Array<IntentParameter_>|null;
  languageCode?: string|null;
}
export class AnnotateTrainingPhraseRequest_ extends Serializable {
  constructor(parameters: AnnotateTrainingPhraseRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'trainingPhrase',
        (parameters.trainingPhrase == null) ? (null) :
                                              (parameters.trainingPhrase));
    this.Serializable$set(
        'annotatedTrainingPhrases',
        (parameters.annotatedTrainingPhrases == null) ?
            (null) :
            (parameters.annotatedTrainingPhrases));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get annotatedTrainingPhrases(): Array<IntentTrainingPhrase_>|null {
    return (
        (this.Serializable$has('annotatedTrainingPhrases')) ?
            (this.Serializable$get('annotatedTrainingPhrases')) :
            (null));
  }

  /**
   * The collection of training phrases that have been annotated, used to solve
   * conflicts between annotations and to generate proper parameter names.
   */
  set annotatedTrainingPhrases(value: Array<IntentTrainingPhrase_>|null) {
    this.Serializable$set('annotatedTrainingPhrases', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The language used to access language-specific data. If not
   * specified, the agent's default language is used. For more information, see
   * [Multilingual intent and entity
   * data](https://cloud.google.com/dialogflow/docs/agents-multilingual#intent-entity).
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get parameters(): Array<IntentParameter_>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of parameters associated with the intent.
   */
  set parameters(value: Array<IntentParameter_>|null) {
    this.Serializable$set('parameters', value);
  }

  get trainingPhrase(): string|null {
    return (
        (this.Serializable$has('trainingPhrase')) ?
            (this.Serializable$get('trainingPhrase')) :
            (null));
  }

  /**
   * Required. Training phrase text to annotate.
   */
  set trainingPhrase(value: string|null) {
    this.Serializable$set('trainingPhrase', value);
  }

  getConstructor(): SerializableCtor<AnnotateTrainingPhraseRequest_> {
    return AnnotateTrainingPhraseRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'annotatedTrainingPhrases': IntentTrainingPhrase_,
        'parameters': IntentParameter_
      },
      keys: [
        'annotatedTrainingPhrases', 'languageCode', 'parameters',
        'trainingPhrase'
      ]
    };
  }
}

export interface AnnotateTrainingPhraseResponse_Parameters {
  trainingPhrase?: string|null;
  annotatedTrainingPhrase?: IntentTrainingPhrase_|null;
  parameters?: Array<IntentParameter_>|null;
}
export class AnnotateTrainingPhraseResponse_ extends Serializable {
  constructor(parameters: AnnotateTrainingPhraseResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'trainingPhrase',
        (parameters.trainingPhrase == null) ? (null) :
                                              (parameters.trainingPhrase));
    this.Serializable$set(
        'annotatedTrainingPhrase',
        (parameters.annotatedTrainingPhrase == null) ?
            (null) :
            (parameters.annotatedTrainingPhrase));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get annotatedTrainingPhrase(): IntentTrainingPhrase_|null {
    return (
        (this.Serializable$has('annotatedTrainingPhrase')) ?
            (this.Serializable$get('annotatedTrainingPhrase')) :
            (null));
  }

  /**
   * The annotated training phrase.
   */
  set annotatedTrainingPhrase(value: IntentTrainingPhrase_|null) {
    this.Serializable$set('annotatedTrainingPhrase', value);
  }

  get parameters(): Array<IntentParameter_>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Additional collection of parameters that needed to be added to the intent.
   */
  set parameters(value: Array<IntentParameter_>|null) {
    this.Serializable$set('parameters', value);
  }

  get trainingPhrase(): string|null {
    return (
        (this.Serializable$has('trainingPhrase')) ?
            (this.Serializable$get('trainingPhrase')) :
            (null));
  }

  /**
   * The original training phrase text.
   */
  set trainingPhrase(value: string|null) {
    this.Serializable$set('trainingPhrase', value);
  }

  getConstructor(): SerializableCtor<AnnotateTrainingPhraseResponse_> {
    return AnnotateTrainingPhraseResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parameters': IntentParameter_},
      keys: ['annotatedTrainingPhrase', 'parameters', 'trainingPhrase'],
      objects: {'annotatedTrainingPhrase': IntentTrainingPhrase_}
    };
  }
}

export interface AnnotatedConversationDataset_Parameters {
  name?: string|null;
  displayName?: string|null;
  description?: string|null;
  createTime?: string|null;
  exampleCount?: string|null;
  completedExampleCount?: string|null;
  questionTypeName?: string|null;
}
export class AnnotatedConversationDataset_ extends Serializable {
  constructor(parameters: AnnotatedConversationDataset_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'exampleCount',
        (parameters.exampleCount == null) ? (null) : (parameters.exampleCount));
    this.Serializable$set(
        'completedExampleCount',
        (parameters.completedExampleCount == null) ?
            (null) :
            (parameters.completedExampleCount));
    this.Serializable$set(
        'questionTypeName',
        (parameters.questionTypeName == null) ? (null) :
                                                (parameters.questionTypeName));
  }

  get completedExampleCount(): string|null {
    return (
        (this.Serializable$has('completedExampleCount')) ?
            (this.Serializable$get('completedExampleCount')) :
            (null));
  }

  /**
   * Output only. Number of examples that have annotations in the annotated
   * conversation dataset.
   */
  set completedExampleCount(value: string|null) {
    this.Serializable$set('completedExampleCount', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Creation time of this annotated conversation dataset.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. The description of the annotated conversation dataset. Maximum of
   * 10000 bytes.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The display name of the annotated conversation dataset. It's
   * specified when user starts an annotation task. Maximum of 64 bytes.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get exampleCount(): string|null {
    return (
        (this.Serializable$has('exampleCount')) ?
            (this.Serializable$get('exampleCount')) :
            (null));
  }

  /**
   * Output only. Number of examples in the annotated conversation dataset.
   */
  set exampleCount(value: string|null) {
    this.Serializable$set('exampleCount', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. AnnotatedConversationDataset resource name. Format:
   * `projects//conversationDatasets//annotatedConversationDatasets/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get questionTypeName(): string|null {
    return (
        (this.Serializable$has('questionTypeName')) ?
            (this.Serializable$get('questionTypeName')) :
            (null));
  }

  /**
   * Output only. Question type name that identifies a labeling task. A question
   * is a single task that a worker answers. A question type is set of related
   * questions. Each question belongs to a particular question type. It can be
   * used in CrowdCompute UI to filter and manage labeling tasks.
   */
  set questionTypeName(value: string|null) {
    this.Serializable$set('questionTypeName', value);
  }

  getConstructor(): SerializableCtor<AnnotatedConversationDataset_> {
    return AnnotatedConversationDataset_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'completedExampleCount', 'createTime', 'description', 'displayName',
        'exampleCount', 'name', 'questionTypeName'
      ]
    };
  }
}

export interface AnnotatedMessagePart_Parameters {
  text?: string|null;
  entityType?: string|null;
  formattedValue?: any|null;
}
export class AnnotatedMessagePart_ extends Serializable {
  constructor(parameters: AnnotatedMessagePart_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
    this.Serializable$set(
        'formattedValue',
        (parameters.formattedValue == null) ? (null) :
                                              (parameters.formattedValue));
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * Optional. The [Dialogflow system entity
   * type](https://cloud.google.com/dialogflow/docs/reference/system-entities)
   * of this message part. If this is empty, Dialogflow could not annotate the
   * phrase part with a system entity.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get formattedValue(): any|null {
    return (
        (this.Serializable$has('formattedValue')) ?
            (this.Serializable$get('formattedValue')) :
            (null));
  }

  /**
   * Optional. The [Dialogflow system entity formatted value
   * ](https://cloud.google.com/dialogflow/docs/reference/system-entities) of
   * this message part. For example for a system entity of type
   * `@sys.unit-currency`, this may contain: { \"amount\": 5, \"currency\":
   * \"USD\" }
   */
  set formattedValue(value: any|null) {
    this.Serializable$set('formattedValue', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. A part of a message possibly annotated with an entity.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<AnnotatedMessagePart_> {
    return AnnotatedMessagePart_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['entityType', 'formattedValue', 'text']};
  }
}

export interface AnnotationTaskConfig_Parameters {
  displayName?: string|null;
  description?: string|null;
  instructionUri?: string|null;
  replicaCount?: number|null;
  annotatorAccounts?: Array<string>|null;
  specialistPools?: Array<string>|null;
  userEmailAddress?: string|null;
  articleSuggestionConfig?: ArticleSuggestionConfig_|null;
  knowledgeBaseQuerySource?:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_|
      null;
}
export class AnnotationTaskConfig_ extends Serializable {
  constructor(parameters: AnnotationTaskConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'instructionUri',
        (parameters.instructionUri == null) ? (null) :
                                              (parameters.instructionUri));
    this.Serializable$set(
        'replicaCount',
        (parameters.replicaCount == null) ? (null) : (parameters.replicaCount));
    this.Serializable$set(
        'annotatorAccounts',
        (parameters.annotatorAccounts == null) ?
            (null) :
            (parameters.annotatorAccounts));
    this.Serializable$set(
        'specialistPools',
        (parameters.specialistPools == null) ? (null) :
                                               (parameters.specialistPools));
    this.Serializable$set(
        'userEmailAddress',
        (parameters.userEmailAddress == null) ? (null) :
                                                (parameters.userEmailAddress));
    this.Serializable$set(
        'articleSuggestionConfig',
        (parameters.articleSuggestionConfig == null) ?
            (null) :
            (parameters.articleSuggestionConfig));
    this.Serializable$set(
        'knowledgeBaseQuerySource',
        (parameters.knowledgeBaseQuerySource == null) ?
            (null) :
            (parameters.knowledgeBaseQuerySource));
  }

  get annotatorAccounts(): Array<string>|null {
    return (
        (this.Serializable$has('annotatorAccounts')) ?
            (this.Serializable$get('annotatorAccounts')) :
            (null));
  }

  /**
   * Optional. List of G Suite accounts for annotators. The format should be an
   * Email.
   */
  set annotatorAccounts(value: Array<string>|null) {
    this.Serializable$set('annotatorAccounts', value);
  }

  get articleSuggestionConfig(): ArticleSuggestionConfig_|null {
    return (
        (this.Serializable$has('articleSuggestionConfig')) ?
            (this.Serializable$get('articleSuggestionConfig')) :
            (null));
  }

  /**
   * Deprecated. Specifies where and how to get default conversation suggestions
   * that will be annotated.
   */
  set articleSuggestionConfig(value: ArticleSuggestionConfig_|null) {
    this.Serializable$set('articleSuggestionConfig', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. The description of the conversation annotation task. Maximum of
   * 10000 bytes.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The display name of the conversation annotation task. It's
   * specified when user starts an annotation task. Maximum of 64 bytes.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get instructionUri(): string|null {
    return (
        (this.Serializable$has('instructionUri')) ?
            (this.Serializable$get('instructionUri')) :
            (null));
  }

  /**
   * Optional. HTTP URI containing instructions for the annotation.
   */
  set instructionUri(value: string|null) {
    this.Serializable$set('instructionUri', value);
  }

  get knowledgeBaseQuerySource():
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_
      |null {
    return (
        (this.Serializable$has('knowledgeBaseQuerySource')) ?
            (this.Serializable$get('knowledgeBaseQuerySource')) :
            (null));
  }

  /**
   * Specifies the source of knowledge base to get articles from.
   */
  set knowledgeBaseQuerySource(
      value:
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_|
      null) {
    this.Serializable$set('knowledgeBaseQuerySource', value);
  }

  get replicaCount(): number|null {
    return (
        (this.Serializable$has('replicaCount')) ?
            (this.Serializable$get('replicaCount')) :
            (null));
  }

  /**
   * Required. Replications used for annotation, which means how many raters a
   * question may be sent to for final decision.
   */
  set replicaCount(value: number|null) {
    this.Serializable$set('replicaCount', value);
  }

  get specialistPools(): Array<string>|null {
    return (
        (this.Serializable$has('specialistPools')) ?
            (this.Serializable$get('specialistPools')) :
            (null));
  }

  /**
   * Optional. Resource names of the specialist pools that handle the labeling
   * task. Resource name format: `projects//specialistPools/`
   */
  set specialistPools(value: Array<string>|null) {
    this.Serializable$set('specialistPools', value);
  }

  get userEmailAddress(): string|null {
    return (
        (this.Serializable$has('userEmailAddress')) ?
            (this.Serializable$get('userEmailAddress')) :
            (null));
  }

  /**
   * Optional. Email of the user who should be notified by email when events
   * occur, such as the creation of a labeling Task, the occurrence of errors
   * during the human labeling process, or the completion of a labeling task. If
   * empty no notification will be sent.
   */
  set userEmailAddress(value: string|null) {
    this.Serializable$set('userEmailAddress', value);
  }

  getConstructor(): SerializableCtor<AnnotationTaskConfig_> {
    return AnnotationTaskConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'annotatorAccounts', 'articleSuggestionConfig', 'description',
        'displayName', 'instructionUri', 'knowledgeBaseQuerySource',
        'replicaCount', 'specialistPools', 'userEmailAddress'
      ],
      objects: {
        'articleSuggestionConfig': ArticleSuggestionConfig_,
        'knowledgeBaseQuerySource':
            HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_
      }
    };
  }
}

export interface AnswerFeedback_Parameters {
  correctnessLevel?: AnswerFeedback_CorrectnessLevel|null;
  agentAssistantDetailFeedback?: AgentAssistantFeedback_|null;
  clicked?: boolean|null;
  clickTime?: string|null;
  displayed?: boolean|null;
  displayTime?: string|null;
}
export class AnswerFeedback_ extends Serializable {
  constructor(parameters: AnswerFeedback_Parameters = {}) {
    super();
    this.Serializable$set(
        'correctnessLevel',
        (parameters.correctnessLevel == null) ? (null) :
                                                (parameters.correctnessLevel));
    this.Serializable$set(
        'agentAssistantDetailFeedback',
        (parameters.agentAssistantDetailFeedback == null) ?
            (null) :
            (parameters.agentAssistantDetailFeedback));
    this.Serializable$set(
        'clicked',
        (parameters.clicked == null) ? (null) : (parameters.clicked));
    this.Serializable$set(
        'clickTime',
        (parameters.clickTime == null) ? (null) : (parameters.clickTime));
    this.Serializable$set(
        'displayed',
        (parameters.displayed == null) ? (null) : (parameters.displayed));
    this.Serializable$set(
        'displayTime',
        (parameters.displayTime == null) ? (null) : (parameters.displayTime));
  }

  static get CorrectnessLevel(): IAnswerFeedback_CorrectnessLevelEnum {
    return AnswerFeedback_CorrectnessLevelEnum;
  }

  get agentAssistantDetailFeedback(): AgentAssistantFeedback_|null {
    return (
        (this.Serializable$has('agentAssistantDetailFeedback')) ?
            (this.Serializable$get('agentAssistantDetailFeedback')) :
            (null));
  }

  /**
   * Optional. Detail feedback of agent assistant suggestions.
   */
  set agentAssistantDetailFeedback(value: AgentAssistantFeedback_|null) {
    this.Serializable$set('agentAssistantDetailFeedback', value);
  }

  get clickTime(): string|null {
    return (
        (this.Serializable$has('clickTime')) ?
            (this.Serializable$get('clickTime')) :
            (null));
  }

  /**
   * Time when the answer/item was clicked.
   */
  set clickTime(value: string|null) {
    this.Serializable$set('clickTime', value);
  }

  get clicked(): boolean|null {
    return (
        (this.Serializable$has('clicked')) ?
            (this.Serializable$get('clicked')) :
            (null));
  }

  /**
   * Indicates whether the answer/item was clicked by the human agent or not.
   * Default to false.
   */
  set clicked(value: boolean|null) {
    this.Serializable$set('clicked', value);
  }

  get correctnessLevel(): AnswerFeedback_CorrectnessLevel|null {
    return (
        (this.Serializable$has('correctnessLevel')) ?
            (this.Serializable$get('correctnessLevel')) :
            (null));
  }

  /**
   * The correctness level of the specific answer.
   */
  set correctnessLevel(value: AnswerFeedback_CorrectnessLevel|null) {
    this.Serializable$set('correctnessLevel', value);
  }

  get displayTime(): string|null {
    return (
        (this.Serializable$has('displayTime')) ?
            (this.Serializable$get('displayTime')) :
            (null));
  }

  /**
   * Time when the answer/item was displayed.
   */
  set displayTime(value: string|null) {
    this.Serializable$set('displayTime', value);
  }

  get displayed(): boolean|null {
    return (
        (this.Serializable$has('displayed')) ?
            (this.Serializable$get('displayed')) :
            (null));
  }

  /**
   * Indicates whether the answer/item was displayed to the human agent in the
   * agent desktop UI. Default to false.
   */
  set displayed(value: boolean|null) {
    this.Serializable$set('displayed', value);
  }

  getConstructor(): SerializableCtor<AnswerFeedback_> {
    return AnswerFeedback_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'correctnessLevel': AnswerFeedback_CorrectnessLevelEnum},
      keys: [
        'agentAssistantDetailFeedback', 'clickTime', 'clicked',
        'correctnessLevel', 'displayTime', 'displayed'
      ],
      objects: {'agentAssistantDetailFeedback': AgentAssistantFeedback_}
    };
  }
}

export interface AnswerRecord_Parameters {
  name?: string|null;
  expireTime?: string|null;
  answerFeedback?: AnswerFeedback_|null;
  agentAssistantRecord?: AgentAssistantRecord_|null;
}
export class AnswerRecord_ extends Serializable {
  constructor(parameters: AnswerRecord_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'expireTime',
        (parameters.expireTime == null) ? (null) : (parameters.expireTime));
    this.Serializable$set(
        'answerFeedback',
        (parameters.answerFeedback == null) ? (null) :
                                              (parameters.answerFeedback));
    this.Serializable$set(
        'agentAssistantRecord',
        (parameters.agentAssistantRecord == null) ?
            (null) :
            (parameters.agentAssistantRecord));
  }

  get agentAssistantRecord(): AgentAssistantRecord_|null {
    return (
        (this.Serializable$has('agentAssistantRecord')) ?
            (this.Serializable$get('agentAssistantRecord')) :
            (null));
  }

  /**
   * Output only. The record for human agent assistant.
   */
  set agentAssistantRecord(value: AgentAssistantRecord_|null) {
    this.Serializable$set('agentAssistantRecord', value);
  }

  get answerFeedback(): AnswerFeedback_|null {
    return (
        (this.Serializable$has('answerFeedback')) ?
            (this.Serializable$get('answerFeedback')) :
            (null));
  }

  /**
   * Optional. The AnswerFeedback for this record. You can set this with
   * AnswerRecords.UpdateAnswerRecord in order to give us feedback about this
   * answer.
   */
  set answerFeedback(value: AnswerFeedback_|null) {
    this.Serializable$set('answerFeedback', value);
  }

  get expireTime(): string|null {
    return (
        (this.Serializable$has('expireTime')) ?
            (this.Serializable$get('expireTime')) :
            (null));
  }

  /**
   * Output only. The time that this answer record expires. By default this will
   * be 14 days after the answer was generated. If the customer provides a
   * answer_feedback`, the answer log won't expire and `expire_time` will be
   * unset.
   */
  set expireTime(value: string|null) {
    this.Serializable$set('expireTime', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of this answer record. Required for
   * AnswerRecords.UpdateAnswerRecord method. Format:
   * `projects//locations//answerRecords/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<AnswerRecord_> {
    return AnswerRecord_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['agentAssistantRecord', 'answerFeedback', 'expireTime', 'name'],
      objects: {
        'agentAssistantRecord': AgentAssistantRecord_,
        'answerFeedback': AnswerFeedback_
      }
    };
  }
}

export interface AogCompatibilityRequest_Parameters {}
export class AogCompatibilityRequest_ extends Serializable {
  constructor(parameters: AogCompatibilityRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<AogCompatibilityRequest_> {
    return AogCompatibilityRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface AogCompatibility_Parameters {
  exists?: boolean|null;
  minVersion?: number|null;
  aogV2?: boolean|null;
}
export class AogCompatibility_ extends Serializable {
  constructor(parameters: AogCompatibility_Parameters = {}) {
    super();
    this.Serializable$set(
        'exists', (parameters.exists == null) ? (null) : (parameters.exists));
    this.Serializable$set(
        'minVersion',
        (parameters.minVersion == null) ? (null) : (parameters.minVersion));
    this.Serializable$set(
        'aogV2', (parameters.aogV2 == null) ? (null) : (parameters.aogV2));
  }

  get aogV2(): boolean|null {
    return (
        (this.Serializable$has('aogV2')) ? (this.Serializable$get('aogV2')) :
                                           (null));
  }

  /**
   * Indicates if this project is AoG V2.
   */
  set aogV2(value: boolean|null) {
    this.Serializable$set('aogV2', value);
  }

  get exists(): boolean|null {
    return (
        (this.Serializable$has('exists')) ? (this.Serializable$get('exists')) :
                                            (null));
  }

  /**
   * Show if project exists.
   */
  set exists(value: boolean|null) {
    this.Serializable$set('exists', value);
  }

  get minVersion(): number|null {
    return (
        (this.Serializable$has('minVersion')) ?
            (this.Serializable$get('minVersion')) :
            (null));
  }

  /**
   * Indicate minimal AoG version number.
   */
  set minVersion(value: number|null) {
    this.Serializable$set('minVersion', value);
  }

  getConstructor(): SerializableCtor<AogCompatibility_> {
    return AogCompatibility_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['aogV2', 'exists', 'minVersion']};
  }
}

export interface AogIntentDefinitionLocalizedDocumentationParameter_Parameters {
  name?: string|null;
  description?: string|null;
}
export class AogIntentDefinitionLocalizedDocumentationParameter_ extends
    Serializable {
  constructor(
      parameters:
          AogIntentDefinitionLocalizedDocumentationParameter_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Localized description of the parameter.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Unique name for the parameter.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor():
      SerializableCtor<AogIntentDefinitionLocalizedDocumentationParameter_> {
    return AogIntentDefinitionLocalizedDocumentationParameter_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['description', 'name']};
  }
}

export interface AogIntentDefinitionLocalizedDocumentation_Parameters {
  locale?: string|null;
  displayName?: string|null;
  description?: string|null;
  parameters?: Array<AogIntentDefinitionLocalizedDocumentationParameter_>|null;
  exampleQueries?: Array<string>|null;
}
export class AogIntentDefinitionLocalizedDocumentation_ extends Serializable {
  constructor(
      parameters: AogIntentDefinitionLocalizedDocumentation_Parameters = {}) {
    super();
    this.Serializable$set(
        'locale', (parameters.locale == null) ? (null) : (parameters.locale));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'exampleQueries',
        (parameters.exampleQueries == null) ? (null) :
                                              (parameters.exampleQueries));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Localized description for an intent. For custom intents this will be the
   * intent name.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Localized display name for a built-in intent. For custom intents this will
   * be the intent name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get exampleQueries(): Array<string>|null {
    return (
        (this.Serializable$has('exampleQueries')) ?
            (this.Serializable$get('exampleQueries')) :
            (null));
  }

  /**
   * Example queries. This will be empty for custom intents.
   */
  set exampleQueries(value: Array<string>|null) {
    this.Serializable$set('exampleQueries', value);
  }

  get locale(): string|null {
    return (
        (this.Serializable$has('locale')) ? (this.Serializable$get('locale')) :
                                            (null));
  }

  /**
   * Locale for for this intent documentation. The locale is defined using a
   * BCP-47 language code.
   */
  set locale(value: string|null) {
    this.Serializable$set('locale', value);
  }

  get parameters():
      Array<AogIntentDefinitionLocalizedDocumentationParameter_>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Localized documentation for the parameters.
   */
  set parameters(value:
                     Array<AogIntentDefinitionLocalizedDocumentationParameter_>|
                 null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor():
      SerializableCtor<AogIntentDefinitionLocalizedDocumentation_> {
    return AogIntentDefinitionLocalizedDocumentation_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'parameters': AogIntentDefinitionLocalizedDocumentationParameter_},
      keys: [
        'description', 'displayName', 'exampleQueries', 'locale', 'parameters'
      ]
    };
  }
}

export interface AogIntentDefinitionParameter_Parameters {
  name?: string|null;
  messageType?: string|null;
  exampleValues?: Array<string>|null;
}
export class AogIntentDefinitionParameter_ extends Serializable {
  constructor(parameters: AogIntentDefinitionParameter_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'messageType',
        (parameters.messageType == null) ? (null) : (parameters.messageType));
    this.Serializable$set(
        'exampleValues',
        (parameters.exampleValues == null) ? (null) :
                                             (parameters.exampleValues));
  }

  get exampleValues(): Array<string>|null {
    return (
        (this.Serializable$has('exampleValues')) ?
            (this.Serializable$get('exampleValues')) :
            (null));
  }

  /**
   * Example JSON values of the parameter.
   */
  set exampleValues(value: Array<string>|null) {
    this.Serializable$set('exampleValues', value);
  }

  get messageType(): string|null {
    return (
        (this.Serializable$has('messageType')) ?
            (this.Serializable$get('messageType')) :
            (null));
  }

  /**
   * The type for this parameter as a schema.org or Google APIs type.
   */
  set messageType(value: string|null) {
    this.Serializable$set('messageType', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The name of the parameter. This is the same name from the localized
   * Parameter.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<AogIntentDefinitionParameter_> {
    return AogIntentDefinitionParameter_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['exampleValues', 'messageType', 'name']};
  }
}

export interface AogIntentDefinition_Parameters {
  name?: string|null;
  intent?: string|null;
  releaseDate?: string|null;
  parameters?: Array<AogIntentDefinitionParameter_>|null;
  localizedDocumentation?: Array<AogIntentDefinitionLocalizedDocumentation_>|
      null;
  category?: string|null;
  capabilities?: Array<string>|null;
}
export class AogIntentDefinition_ extends Serializable {
  constructor(parameters: AogIntentDefinition_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'releaseDate',
        (parameters.releaseDate == null) ? (null) : (parameters.releaseDate));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'localizedDocumentation',
        (parameters.localizedDocumentation == null) ?
            (null) :
            (parameters.localizedDocumentation));
    this.Serializable$set(
        'category',
        (parameters.category == null) ? (null) : (parameters.category));
    this.Serializable$set(
        'capabilities',
        (parameters.capabilities == null) ? (null) : (parameters.capabilities));
  }

  get capabilities(): Array<string>|null {
    return (
        (this.Serializable$has('capabilities')) ?
            (this.Serializable$get('capabilities')) :
            (null));
  }

  /**
   * Capabilities for this intent definition.
   */
  set capabilities(value: Array<string>|null) {
    this.Serializable$set('capabilities', value);
  }

  get category(): string|null {
    return (
        (this.Serializable$has('category')) ?
            (this.Serializable$get('category')) :
            (null));
  }

  /**
   * A descriptive name for category of the intent. This will be empty for
   * custom intents.
   */
  set category(value: string|null) {
    this.Serializable$set('category', value);
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The name of the intent that this is describing.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  get localizedDocumentation():
      Array<AogIntentDefinitionLocalizedDocumentation_>|null {
    return (
        (this.Serializable$has('localizedDocumentation')) ?
            (this.Serializable$get('localizedDocumentation')) :
            (null));
  }

  /**
   * List of localized documentation for this intent.
   */
  set localizedDocumentation(
      value: Array<AogIntentDefinitionLocalizedDocumentation_>|null) {
    this.Serializable$set('localizedDocumentation', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Unique identifier for the IntentDefinition. Intent definition names are of
   * the form: agents/{}/versions/{version}/intentDefinitions/{} The value of
   * version can be a published agent version number, \"draft\", \"preview\",
   * \"android_preview\" or \"none\". When version is \"android_preview\" then
   * project_id is ignored.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameters(): Array<AogIntentDefinitionParameter_>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Parameter definitions of the Intent.
   */
  set parameters(value: Array<AogIntentDefinitionParameter_>|null) {
    this.Serializable$set('parameters', value);
  }

  get releaseDate(): string|null {
    return (
        (this.Serializable$has('releaseDate')) ?
            (this.Serializable$get('releaseDate')) :
            (null));
  }

  /**
   * Release date in the form MM-YYYY. This will be empty for custom intents.
   */
  set releaseDate(value: string|null) {
    this.Serializable$set('releaseDate', value);
  }

  getConstructor(): SerializableCtor<AogIntentDefinition_> {
    return AogIntentDefinition_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'localizedDocumentation': AogIntentDefinitionLocalizedDocumentation_,
        'parameters': AogIntentDefinitionParameter_
      },
      keys: [
        'capabilities', 'category', 'intent', 'localizedDocumentation', 'name',
        'parameters', 'releaseDate'
      ]
    };
  }
}

export interface ArticleAnswer_Parameters {
  title?: string|null;
  uri?: string|null;
  snippets?: Array<string>|null;
  confidence?: number|null;
  metadata?: ApiClientObjectMap<string>|null;
  answerRecord?: string|null;
}
export class ArticleAnswer_ extends Serializable {
  constructor(parameters: ArticleAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'snippets',
        (parameters.snippets == null) ? (null) : (parameters.snippets));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * Article match confidence. The system's confidence score that this article
   * is a good match for this conversation, as a value from 0.0 (completely
   * uncertain) to 1.0 (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get metadata(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * A map that contains metadata about the answer and the document from which
   * it originates.
   */
  set metadata(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('metadata', value);
  }

  get snippets(): Array<string>|null {
    return (
        (this.Serializable$has('snippets')) ?
            (this.Serializable$get('snippets')) :
            (null));
  }

  /**
   * Output only. Article snippets.
   */
  set snippets(value: Array<string>|null) {
    this.Serializable$set('snippets', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * The article title.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * The article URI.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<ArticleAnswer_> {
    return ArticleAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'answerRecord', 'confidence', 'metadata', 'snippets', 'title', 'uri'
      ],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface ArticleSuggestionConfig_Parameters {
  knowledgeBaseName?: string|null;
  knowledgeBases?: Array<string>|null;
  queryTermCount?: number|null;
  recentSentencesCount?: number|null;
  maxResults?: number|null;
  queryBuilder?: string|null;
  salientTermExtractor?: string|null;
  minSalientTermScore?: number|null;
  additionalQueryPostprocessors?: Array<string>|null;
  maxKnowledgeSearchResults?: number|null;
  triggerModelMode?: ArticleSuggestionConfig_TriggerModelMode|null;
  model?: string|null;
  confidenceThreshold?: number|null;
}
export class ArticleSuggestionConfig_ extends Serializable {
  constructor(parameters: ArticleSuggestionConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'knowledgeBaseName',
        (parameters.knowledgeBaseName == null) ?
            (null) :
            (parameters.knowledgeBaseName));
    this.Serializable$set(
        'knowledgeBases',
        (parameters.knowledgeBases == null) ? (null) :
                                              (parameters.knowledgeBases));
    this.Serializable$set(
        'queryTermCount',
        (parameters.queryTermCount == null) ? (null) :
                                              (parameters.queryTermCount));
    this.Serializable$set(
        'recentSentencesCount',
        (parameters.recentSentencesCount == null) ?
            (null) :
            (parameters.recentSentencesCount));
    this.Serializable$set(
        'maxResults',
        (parameters.maxResults == null) ? (null) : (parameters.maxResults));
    this.Serializable$set(
        'queryBuilder',
        (parameters.queryBuilder == null) ? (null) : (parameters.queryBuilder));
    this.Serializable$set(
        'salientTermExtractor',
        (parameters.salientTermExtractor == null) ?
            (null) :
            (parameters.salientTermExtractor));
    this.Serializable$set(
        'minSalientTermScore',
        (parameters.minSalientTermScore == null) ?
            (null) :
            (parameters.minSalientTermScore));
    this.Serializable$set(
        'additionalQueryPostprocessors',
        (parameters.additionalQueryPostprocessors == null) ?
            (null) :
            (parameters.additionalQueryPostprocessors));
    this.Serializable$set(
        'maxKnowledgeSearchResults',
        (parameters.maxKnowledgeSearchResults == null) ?
            (null) :
            (parameters.maxKnowledgeSearchResults));
    this.Serializable$set(
        'triggerModelMode',
        (parameters.triggerModelMode == null) ? (null) :
                                                (parameters.triggerModelMode));
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'confidenceThreshold',
        (parameters.confidenceThreshold == null) ?
            (null) :
            (parameters.confidenceThreshold));
  }

  static get TriggerModelMode(): IArticleSuggestionConfig_TriggerModelModeEnum {
    return ArticleSuggestionConfig_TriggerModelModeEnum;
  }

  get additionalQueryPostprocessors(): Array<string>|null {
    return (
        (this.Serializable$has('additionalQueryPostprocessors')) ?
            (this.Serializable$get('additionalQueryPostprocessors')) :
            (null));
  }

  /**
   * Optional. Additional query postprocessors to run.
   */
  set additionalQueryPostprocessors(value: Array<string>|null) {
    this.Serializable$set('additionalQueryPostprocessors', value);
  }

  get confidenceThreshold(): number|null {
    return (
        (this.Serializable$has('confidenceThreshold')) ?
            (this.Serializable$get('confidenceThreshold')) :
            (null));
  }

  /**
   * Optional. Confidence threshold to filter out low score article suggestions.
   * If not set, filter out suggestions with score equal to 0.
   */
  set confidenceThreshold(value: number|null) {
    this.Serializable$set('confidenceThreshold', value);
  }

  get knowledgeBaseName(): string|null {
    return (
        (this.Serializable$has('knowledgeBaseName')) ?
            (this.Serializable$get('knowledgeBaseName')) :
            (null));
  }

  /**
   * Required. Settings for knowledge base, Format: `projects//knowledgeBases/`.
   */
  set knowledgeBaseName(value: string|null) {
    this.Serializable$set('knowledgeBaseName', value);
  }

  get knowledgeBases(): Array<string>|null {
    return (
        (this.Serializable$has('knowledgeBases')) ?
            (this.Serializable$get('knowledgeBases')) :
            (null));
  }

  /**
   * Settings for knowledge bases, Format: `projects//knowledgeBases/`. If both
   * `knowledge_base_name` and `knowledge_bases` are set, we will merge two
   * fields.
   */
  set knowledgeBases(value: Array<string>|null) {
    this.Serializable$set('knowledgeBases', value);
  }

  get maxKnowledgeSearchResults(): number|null {
    return (
        (this.Serializable$has('maxKnowledgeSearchResults')) ?
            (this.Serializable$get('maxKnowledgeSearchResults')) :
            (null));
  }

  /**
   * Optional. Maximum number of search results from knowledge service. If
   * unset, defaults to 30.
   */
  set maxKnowledgeSearchResults(value: number|null) {
    this.Serializable$set('maxKnowledgeSearchResults', value);
  }

  get maxResults(): number|null {
    return (
        (this.Serializable$has('maxResults')) ?
            (this.Serializable$get('maxResults')) :
            (null));
  }

  /**
   * Optional. Maximum number of results to return. If unset, defaults to 10.
   */
  set maxResults(value: number|null) {
    this.Serializable$set('maxResults', value);
  }

  get minSalientTermScore(): number|null {
    return (
        (this.Serializable$has('minSalientTermScore')) ?
            (this.Serializable$get('minSalientTermScore')) :
            (null));
  }

  /**
   * Optional. Override the min salient term score in extractor.
   */
  set minSalientTermScore(value: number|null) {
    this.Serializable$set('minSalientTermScore', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Optional. Custom conversation model name. Format:
   * `projects//conversationModels/`.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  get queryBuilder(): string|null {
    return (
        (this.Serializable$has('queryBuilder')) ?
            (this.Serializable$get('queryBuilder')) :
            (null));
  }

  /**
   * Optional. Query builder to build query.
   */
  set queryBuilder(value: string|null) {
    this.Serializable$set('queryBuilder', value);
  }

  get queryTermCount(): number|null {
    return (
        (this.Serializable$has('queryTermCount')) ?
            (this.Serializable$get('queryTermCount')) :
            (null));
  }

  /**
   * Optional. Number of keywords to extract from the conversation.
   */
  set queryTermCount(value: number|null) {
    this.Serializable$set('queryTermCount', value);
  }

  get recentSentencesCount(): number|null {
    return (
        (this.Serializable$has('recentSentencesCount')) ?
            (this.Serializable$get('recentSentencesCount')) :
            (null));
  }

  /**
   * Optional. Number of recent sentences to use for keyword extraction.
   */
  set recentSentencesCount(value: number|null) {
    this.Serializable$set('recentSentencesCount', value);
  }

  get salientTermExtractor(): string|null {
    return (
        (this.Serializable$has('salientTermExtractor')) ?
            (this.Serializable$get('salientTermExtractor')) :
            (null));
  }

  /**
   * Optional. SalientTermExtractor.
   */
  set salientTermExtractor(value: string|null) {
    this.Serializable$set('salientTermExtractor', value);
  }

  get triggerModelMode(): ArticleSuggestionConfig_TriggerModelMode|null {
    return (
        (this.Serializable$has('triggerModelMode')) ?
            (this.Serializable$get('triggerModelMode')) :
            (null));
  }

  /**
   * Deprecated. Will only take effect in `legacy` workflow. In new workflow, it
   * is moved to
   * HumanAgentAssistantConfig.SuggestionFeatureConfig.trigger_model_mode. See
   * HumanAgentAssistantConfig.name for more details. Optional. Trigger Model is
   * a feature that predicts whether human agent assistant needs to provide
   * suggestions on current message with the context of current conversation.
   * User can choose whether to enable this feature or not by setting up this
   * field. If TriggerModelMode is enabled, human agent assistant will only
   * generate suggestions when the score from the model prediction result
   * exceeds certain threshold. If the TriggerModelMode is disabled, human agent
   * assistant will try to generate suggestions for every message. If it is set
   * to TRIGGER_MODEL_MODE_UNSPECIFIED, it will be automatically set to
   * TRIGGER_MODEL_MODE_ENABLED during the human agent assistant creation. Note
   * that for previously created human agent assistants before this feature
   * launch, the default value of this flag is disabled so their behavior won't
   * change unless updating this field using UpdateHumanAgentAssistant.
   */
  set triggerModelMode(value: ArticleSuggestionConfig_TriggerModelMode|null) {
    this.Serializable$set('triggerModelMode', value);
  }

  getConstructor(): SerializableCtor<ArticleSuggestionConfig_> {
    return ArticleSuggestionConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'triggerModelMode': ArticleSuggestionConfig_TriggerModelModeEnum},
      keys: [
        'additionalQueryPostprocessors', 'confidenceThreshold',
        'knowledgeBaseName', 'knowledgeBases', 'maxKnowledgeSearchResults',
        'maxResults', 'minSalientTermScore', 'model', 'queryBuilder',
        'queryTermCount', 'recentSentencesCount', 'salientTermExtractor',
        'triggerModelMode'
      ]
    };
  }
}

export interface ArticleSuggestionMetricsTopNMetric_Parameters {
  n?: number|null;
  ndcg?: number|null;
  accuracy?: number|null;
}
export class ArticleSuggestionMetricsTopNMetric_ extends Serializable {
  constructor(parameters: ArticleSuggestionMetricsTopNMetric_Parameters = {}) {
    super();
    this.Serializable$set(
        'n', (parameters.n == null) ? (null) : (parameters.n));
    this.Serializable$set(
        'ndcg', (parameters.ndcg == null) ? (null) : (parameters.ndcg));
    this.Serializable$set(
        'accuracy',
        (parameters.accuracy == null) ? (null) : (parameters.accuracy));
  }

  get accuracy(): number|null {
    return (
        (this.Serializable$has('accuracy')) ?
            (this.Serializable$get('accuracy')) :
            (null));
  }

  /**
   * Defined as `number of times top n results has at least one relevant result`
   * divided by `number of queries with at least one suggested article`.
   */
  set accuracy(value: number|null) {
    this.Serializable$set('accuracy', value);
  }

  get n(): number|null {
    return (
        (this.Serializable$has('n')) ? (this.Serializable$get('n')) : (null));
  }

  /**
   * Number of retrieved articles. For example, when `n` is 3, this evaluation
   * contains metrics for when Dialogflow retrieves 3 articles with the model.
   */
  set n(value: number|null) {
    this.Serializable$set('n', value);
  }

  get ndcg(): number|null {
    return (
        (this.Serializable$has('ndcg')) ? (this.Serializable$get('ndcg')) :
                                          (null));
  }

  /**
   * Normalized discounted cumulative gain score.
   * https://en.wikipedia.org/wiki/Discounted_cumulative_gain#Normalized_DCG
   */
  set ndcg(value: number|null) {
    this.Serializable$set('ndcg', value);
  }

  getConstructor(): SerializableCtor<ArticleSuggestionMetricsTopNMetric_> {
    return ArticleSuggestionMetricsTopNMetric_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['accuracy', 'n', 'ndcg']};
  }
}

export interface ArticleSuggestionMetrics_Parameters {
  topNMetrics?: Array<ArticleSuggestionMetricsTopNMetric_>|null;
}
export class ArticleSuggestionMetrics_ extends Serializable {
  constructor(parameters: ArticleSuggestionMetrics_Parameters = {}) {
    super();
    this.Serializable$set(
        'topNMetrics',
        (parameters.topNMetrics == null) ? (null) : (parameters.topNMetrics));
  }

  get topNMetrics(): Array<ArticleSuggestionMetricsTopNMetric_>|null {
    return (
        (this.Serializable$has('topNMetrics')) ?
            (this.Serializable$get('topNMetrics')) :
            (null));
  }

  /**
   * Metrics of top n article suggestions, sorted by TopNMetric.n.
   */
  set topNMetrics(value: Array<ArticleSuggestionMetricsTopNMetric_>|null) {
    this.Serializable$set('topNMetrics', value);
  }

  getConstructor(): SerializableCtor<ArticleSuggestionMetrics_> {
    return ArticleSuggestionMetrics_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'topNMetrics': ArticleSuggestionMetricsTopNMetric_},
      keys: ['topNMetrics']
    };
  }
}

export interface ArticleSuggestionModelMetadata_Parameters {
  modelType?: string|null;
  internalTrainingFlags?: string|null;
  trainingModelType?: ArticleSuggestionModelMetadata_TrainingModelType|null;
}
export class ArticleSuggestionModelMetadata_ extends Serializable {
  constructor(parameters: ArticleSuggestionModelMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'modelType',
        (parameters.modelType == null) ? (null) : (parameters.modelType));
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
  }

  static get TrainingModelType():
      IArticleSuggestionModelMetadata_TrainingModelTypeEnum {
    return ArticleSuggestionModelMetadata_TrainingModelTypeEnum;
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  /**
   * Internal testing only. The text format proto of InternalTrainingFlags
   * defined in cloud/ai/contactcenter/knowledge/proto/conversation_model.proto.
   */
  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get modelType(): string|null {
    return (
        (this.Serializable$has('modelType')) ?
            (this.Serializable$get('modelType')) :
            (null));
  }

  /**
   * Optional. DEPRECATED: Prefer defining and accessing the model type using
   * training_model_type. Type of the article suggestion model. The available
   * values are: * `article-suggestion-gbt-1` - (default) Article Suggestion Gbt
   * model. * `article-suggestion-unsupervised-1` - Article Suggestion
   * Unsupervised Model.
   */
  set modelType(value: string|null) {
    this.Serializable$set('modelType', value);
  }

  get trainingModelType(): ArticleSuggestionModelMetadata_TrainingModelType
      |null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Optional. Type of the article suggestion model. If not provided, model_type
   * is used.
   */
  set trainingModelType(value: ArticleSuggestionModelMetadata_TrainingModelType|
                        null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor(): SerializableCtor<ArticleSuggestionModelMetadata_> {
    return ArticleSuggestionModelMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'trainingModelType':
            ArticleSuggestionModelMetadata_TrainingModelTypeEnum
      },
      keys: ['internalTrainingFlags', 'modelType', 'trainingModelType']
    };
  }
}

export interface AssistQueryParameters_Parameters {
  documentsMetadataFilters?: ApiClientObjectMap<string>|null;
}
export class AssistQueryParameters_ extends Serializable {
  constructor(parameters: AssistQueryParameters_Parameters = {}) {
    super();
    this.Serializable$set(
        'documentsMetadataFilters',
        (parameters.documentsMetadataFilters == null) ?
            (null) :
            (parameters.documentsMetadataFilters));
  }

  get documentsMetadataFilters(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('documentsMetadataFilters')) ?
            (this.Serializable$get('documentsMetadataFilters')) :
            (null));
  }

  /**
   * Key-value filters on the metadata of documents returned by article
   * suggestion. If specified, article suggestion only returns suggested
   * documents that match all filters in their Document.metadata. Multiple
   * values for a metadata key should be concatenated by comma. For example,
   * filters to match all documents that have 'US' or 'CA' in their market
   * metadata values and 'agent' in their user metadata values will be ```
   * documents_metadata_filters { key: \"market\" value: \"US,CA\" }
   * documents_metadata_filters { key: \"user\" value: \"agent\" } ```
   */
  set documentsMetadataFilters(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('documentsMetadataFilters', value);
  }

  getConstructor(): SerializableCtor<AssistQueryParameters_> {
    return AssistQueryParameters_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['documentsMetadataFilters'],
      objectMaps: {
        'documentsMetadataFilters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface AssociateAllowlistInfo_Parameters {
  document?: string|null;
  sourceType?: AssociateAllowlistInfo_SourceType|null;
}
export class AssociateAllowlistInfo_ extends Serializable {
  constructor(parameters: AssociateAllowlistInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'sourceType',
        (parameters.sourceType == null) ? (null) : (parameters.sourceType));
  }

  static get SourceType(): IAssociateAllowlistInfo_SourceTypeEnum {
    return AssociateAllowlistInfo_SourceTypeEnum;
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The resource name of the associated allowlist. Format:
   * 'projects//knowledgeBases//documents/'
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  get sourceType(): AssociateAllowlistInfo_SourceType|null {
    return (
        (this.Serializable$has('sourceType')) ?
            (this.Serializable$get('sourceType')) :
            (null));
  }

  /**
   * Source of the associated allowlist document.
   */
  set sourceType(value: AssociateAllowlistInfo_SourceType|null) {
    this.Serializable$set('sourceType', value);
  }

  getConstructor(): SerializableCtor<AssociateAllowlistInfo_> {
    return AssociateAllowlistInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'sourceType': AssociateAllowlistInfo_SourceTypeEnum},
      keys: ['document', 'sourceType']
    };
  }
}

export interface AudioInput_Parameters {
  config?: InputAudioConfig_|null;
  audio?: string|null;
}
export class AudioInput_ extends Serializable {
  constructor(parameters: AudioInput_Parameters = {}) {
    super();
    this.Serializable$set(
        'config', (parameters.config == null) ? (null) : (parameters.config));
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
  }

  get audio(): string|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * Required. The natural language speech audio to be processed. A single
   * request can contain up to 1 minute of speech audio data. The transcribed
   * text cannot contain more than 256 bytes for virtual agent interactions.
   */
  set audio(value: string|null) {
    this.Serializable$set('audio', value);
  }

  get config(): InputAudioConfig_|null {
    return (
        (this.Serializable$has('config')) ? (this.Serializable$get('config')) :
                                            (null));
  }

  /**
   * Required. Instructs the speech recognizer how to process the speech audio.
   */
  set config(value: InputAudioConfig_|null) {
    this.Serializable$set('config', value);
  }

  getConstructor(): SerializableCtor<AudioInput_> {
    return AudioInput_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audio', 'config'], objects: {'config': InputAudioConfig_}};
  }
}

export interface AutoApproveSmartMessagingEntriesMetadata_Parameters {
  document?: string|null;
  createTime?: string|null;
  state?: AutoApproveSmartMessagingEntriesMetadata_State|null;
}
export class AutoApproveSmartMessagingEntriesMetadata_ extends Serializable {
  constructor(
      parameters: AutoApproveSmartMessagingEntriesMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
  }

  static get State(): IAutoApproveSmartMessagingEntriesMetadata_StateEnum {
    return AutoApproveSmartMessagingEntriesMetadata_StateEnum;
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when AutoApproveSmartMessagingEntries data request was created.
   * The time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The resource name of the document to apply auto approvement. Format:
   * `projects//locations//knowledgeBases//documents/`
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  get state(): AutoApproveSmartMessagingEntriesMetadata_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Output only. The current state of this operation.
   */
  set state(value: AutoApproveSmartMessagingEntriesMetadata_State|null) {
    this.Serializable$set('state', value);
  }

  getConstructor():
      SerializableCtor<AutoApproveSmartMessagingEntriesMetadata_> {
    return AutoApproveSmartMessagingEntriesMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': AutoApproveSmartMessagingEntriesMetadata_StateEnum},
      keys: ['createTime', 'document', 'state']
    };
  }
}

export interface AutoApproveSmartMessagingEntriesRequest_Parameters {
  reviewedDocuments?: Array<string>|null;
}
export class AutoApproveSmartMessagingEntriesRequest_ extends Serializable {
  constructor(
      parameters: AutoApproveSmartMessagingEntriesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'reviewedDocuments',
        (parameters.reviewedDocuments == null) ?
            (null) :
            (parameters.reviewedDocuments));
  }

  get reviewedDocuments(): Array<string>|null {
    return (
        (this.Serializable$has('reviewedDocuments')) ?
            (this.Serializable$get('reviewedDocuments')) :
            (null));
  }

  /**
   * Required. The name of the already reviewed smart messaging allowlist
   * documents. Format: `projects//locations//knowledgeBases//documents/`.
   */
  set reviewedDocuments(value: Array<string>|null) {
    this.Serializable$set('reviewedDocuments', value);
  }

  getConstructor(): SerializableCtor<AutoApproveSmartMessagingEntriesRequest_> {
    return AutoApproveSmartMessagingEntriesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['reviewedDocuments']};
  }
}

export interface AutoApproveSmartMessagingEntriesResponse_Parameters {
  enabledCount?: number|null;
  disabledCount?: number|null;
  unreviewedCount?: number|null;
}
export class AutoApproveSmartMessagingEntriesResponse_ extends Serializable {
  constructor(
      parameters: AutoApproveSmartMessagingEntriesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'enabledCount',
        (parameters.enabledCount == null) ? (null) : (parameters.enabledCount));
    this.Serializable$set(
        'disabledCount',
        (parameters.disabledCount == null) ? (null) :
                                             (parameters.disabledCount));
    this.Serializable$set(
        'unreviewedCount',
        (parameters.unreviewedCount == null) ? (null) :
                                               (parameters.unreviewedCount));
  }

  get disabledCount(): number|null {
    return (
        (this.Serializable$has('disabledCount')) ?
            (this.Serializable$get('disabledCount')) :
            (null));
  }

  /**
   * Number of smart messaging entries disabled.
   */
  set disabledCount(value: number|null) {
    this.Serializable$set('disabledCount', value);
  }

  get enabledCount(): number|null {
    return (
        (this.Serializable$has('enabledCount')) ?
            (this.Serializable$get('enabledCount')) :
            (null));
  }

  /**
   * Number of smart messaging entries enabled.
   */
  set enabledCount(value: number|null) {
    this.Serializable$set('enabledCount', value);
  }

  get unreviewedCount(): number|null {
    return (
        (this.Serializable$has('unreviewedCount')) ?
            (this.Serializable$get('unreviewedCount')) :
            (null));
  }

  /**
   * Number of smart messaging entries unreviewed.
   */
  set unreviewedCount(value: number|null) {
    this.Serializable$set('unreviewedCount', value);
  }

  getConstructor():
      SerializableCtor<AutoApproveSmartMessagingEntriesResponse_> {
    return AutoApproveSmartMessagingEntriesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['disabledCount', 'enabledCount', 'unreviewedCount']};
  }
}

export interface AutomatedAgentConfigRouterConfigTrafficSplitConfig_Parameters {
  trafficV3Ratio?: number|null;
}
export class AutomatedAgentConfigRouterConfigTrafficSplitConfig_ extends
    Serializable {
  constructor(
      parameters:
          AutomatedAgentConfigRouterConfigTrafficSplitConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'trafficV3Ratio',
        (parameters.trafficV3Ratio == null) ? (null) :
                                              (parameters.trafficV3Ratio));
  }

  get trafficV3Ratio(): number|null {
    return (
        (this.Serializable$has('trafficV3Ratio')) ?
            (this.Serializable$get('trafficV3Ratio')) :
            (null));
  }

  /**
   * The ratio[0, 1] of the traffic that will go to the V3 agent. 1.0 by
   * default.
   */
  set trafficV3Ratio(value: number|null) {
    this.Serializable$set('trafficV3Ratio', value);
  }

  getConstructor():
      SerializableCtor<AutomatedAgentConfigRouterConfigTrafficSplitConfig_> {
    return AutomatedAgentConfigRouterConfigTrafficSplitConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['trafficV3Ratio']};
  }
}

export interface AutomatedAgentConfigRouterConfig_Parameters {
  agentV2?: string|null;
  agentV3?: string|null;
  trafficSplitConfig?:
      ApiClientObjectMap<AutomatedAgentConfigRouterConfigTrafficSplitConfig_>|
      null;
}
export class AutomatedAgentConfigRouterConfig_ extends Serializable {
  constructor(parameters: AutomatedAgentConfigRouterConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'agentV2',
        (parameters.agentV2 == null) ? (null) : (parameters.agentV2));
    this.Serializable$set(
        'agentV3',
        (parameters.agentV3 == null) ? (null) : (parameters.agentV3));
    this.Serializable$set(
        'trafficSplitConfig',
        (parameters.trafficSplitConfig == null) ?
            (null) :
            (parameters.trafficSplitConfig));
  }

  get agentV2(): string|null {
    return (
        (this.Serializable$has('agentV2')) ?
            (this.Serializable$get('agentV2')) :
            (null));
  }

  /**
   * ID of the Dialogflow v2 agent environment for Dialogflow Router to use. If
   * both agent_v2 and agent_v3 are set, Dialogflow will invoke router to route
   * the query to the corresponding V2 or V3 agent. Format:
   * `projects//locations//agent/environments/` If environment is not specified,
   * the default `draft` environment is used. Refer to
   * [DetectIntentRequest](/dialogflow/docs/reference/rpc/google.cloud.dialogflow.v2beta1#google.cloud.dialogflow.v2beta1.DetectIntentRequest)
   * for more details.
   */
  set agentV2(value: string|null) {
    this.Serializable$set('agentV2', value);
  }

  get agentV3(): string|null {
    return (
        (this.Serializable$has('agentV3')) ?
            (this.Serializable$get('agentV3')) :
            (null));
  }

  /**
   * ID of the Dialogflow v3 agent environment for Dialogflow Router to use. If
   * both agent_v2 and agent_v3 are set, Dialogflow will invoke router to route
   * the query to the corresponding V2 or V3 agent. Format:
   * `projects//locations//locations//agents//environments/`. If environment is
   * not specified, the default `draft` environment is used. Refer to
   * [DetectIntentRequest
   * V3](/dialogflow/docs/reference/rpc/google.cloud.dialogflow.v3alpha1#google.cloud.dialogflow.v3alpha1.DetectIntentRequest)
   * for more details. You'll need both dialogflow.sessions.detectIntent and
   * dialogflow.participants.analyzeContent to make calls to Dialogflow router.
   */
  set agentV3(value: string|null) {
    this.Serializable$set('agentV3', value);
  }

  get trafficSplitConfig():
      ApiClientObjectMap<AutomatedAgentConfigRouterConfigTrafficSplitConfig_>|
      null {
    return (
        (this.Serializable$has('trafficSplitConfig')) ?
            (this.Serializable$get('trafficSplitConfig')) :
            (null));
  }

  /**
   * The traffic split configuration to control how much traffic should go to
   * the V3 agent. This configuration only applies when both agent_v2 and
   * agent_v3 match a query. If only a single agent matches a query, Dialogflow
   * sends the query directly to the respective agent. The key of the map is V3
   * head intent ID. Format: `projects//locations//locations//agents//intents/`.
   */
  set trafficSplitConfig(value: ApiClientObjectMap<
                         AutomatedAgentConfigRouterConfigTrafficSplitConfig_>|
                         null) {
    this.Serializable$set('trafficSplitConfig', value);
  }

  getConstructor(): SerializableCtor<AutomatedAgentConfigRouterConfig_> {
    return AutomatedAgentConfigRouterConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['agentV2', 'agentV3', 'trafficSplitConfig'],
      objectMaps: {
        'trafficSplitConfig': {
          ctor: AutomatedAgentConfigRouterConfigTrafficSplitConfig_,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface AutomatedAgentConfig_Parameters {
  agent?: string|null;
  routerConfig?: AutomatedAgentConfigRouterConfig_|null;
}
export class AutomatedAgentConfig_ extends Serializable {
  constructor(parameters: AutomatedAgentConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'agent', (parameters.agent == null) ? (null) : (parameters.agent));
    this.Serializable$set(
        'routerConfig',
        (parameters.routerConfig == null) ? (null) : (parameters.routerConfig));
  }

  get agent(): string|null {
    return (
        (this.Serializable$has('agent')) ? (this.Serializable$get('agent')) :
                                           (null));
  }

  /**
   * Required. ID of the Dialogflow agent environment to use. This project needs
   * to either be the same project as the conversation or you need to grant
   * `service-@gcp-sa-dialogflow.iam.gserviceaccount.com` the `Dialogflow API
   * Service Agent` role in this project. - For ES agents, use format:
   * `projects//locations//agent/environments/`. If environment is not
   * specified, the default `draft` environment is used. Refer to
   * [DetectIntentRequest](/dialogflow/docs/reference/rpc/google.cloud.dialogflow.v2beta1#google.cloud.dialogflow.v2beta1.DetectIntentRequest)
   * for more details. - For CX agents, use format
   * `projects//locations//agents//environments/`. If environment is not
   * specified, the default `draft` environment is used.
   */
  set agent(value: string|null) {
    this.Serializable$set('agent', value);
  }

  get routerConfig(): AutomatedAgentConfigRouterConfig_|null {
    return (
        (this.Serializable$has('routerConfig')) ?
            (this.Serializable$get('routerConfig')) :
            (null));
  }

  /**
   * The configurations of the Dialogflow router. The router will be invoked if
   * this field is set.
   */
  set routerConfig(value: AutomatedAgentConfigRouterConfig_|null) {
    this.Serializable$set('routerConfig', value);
  }

  getConstructor(): SerializableCtor<AutomatedAgentConfig_> {
    return AutomatedAgentConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['agent', 'routerConfig'],
      objects: {'routerConfig': AutomatedAgentConfigRouterConfig_}
    };
  }
}

export interface AutomatedAgentReply_Parameters {
  detectIntentResponse?: DetectIntentResponse_|null;
  detectIntentResponseV3?: ApiClientObjectMap<any>|null;
  responseMessages?: Array<ResponseMessage_>|null;
  intent?: string|null;
  event?: string|null;
  matchConfidence?: number|null;
  parameters?: ApiClientObjectMap<any>|null;
  cxSessionParameters?: ApiClientObjectMap<any>|null;
  automatedAgentReplyType?: AutomatedAgentReply_AutomatedAgentReplyType|null;
  allowCancellation?: boolean|null;
  cxCurrentPage?: string|null;
}
export class AutomatedAgentReply_ extends Serializable {
  constructor(parameters: AutomatedAgentReply_Parameters = {}) {
    super();
    this.Serializable$set(
        'detectIntentResponse',
        (parameters.detectIntentResponse == null) ?
            (null) :
            (parameters.detectIntentResponse));
    this.Serializable$set(
        'detectIntentResponseV3',
        (parameters.detectIntentResponseV3 == null) ?
            (null) :
            (parameters.detectIntentResponseV3));
    this.Serializable$set(
        'responseMessages',
        (parameters.responseMessages == null) ? (null) :
                                                (parameters.responseMessages));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
    this.Serializable$set(
        'matchConfidence',
        (parameters.matchConfidence == null) ? (null) :
                                               (parameters.matchConfidence));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'cxSessionParameters',
        (parameters.cxSessionParameters == null) ?
            (null) :
            (parameters.cxSessionParameters));
    this.Serializable$set(
        'automatedAgentReplyType',
        (parameters.automatedAgentReplyType == null) ?
            (null) :
            (parameters.automatedAgentReplyType));
    this.Serializable$set(
        'allowCancellation',
        (parameters.allowCancellation == null) ?
            (null) :
            (parameters.allowCancellation));
    this.Serializable$set(
        'cxCurrentPage',
        (parameters.cxCurrentPage == null) ? (null) :
                                             (parameters.cxCurrentPage));
  }

  static get AutomatedAgentReplyType():
      IAutomatedAgentReply_AutomatedAgentReplyTypeEnum {
    return AutomatedAgentReply_AutomatedAgentReplyTypeEnum;
  }

  get allowCancellation(): boolean|null {
    return (
        (this.Serializable$has('allowCancellation')) ?
            (this.Serializable$get('allowCancellation')) :
            (null));
  }

  /**
   * Indicates whether the partial automated agent reply is interruptible when a
   * later reply message arrives. e.g. if the agent specified some music as
   * partial response, it can be cancelled.
   */
  set allowCancellation(value: boolean|null) {
    this.Serializable$set('allowCancellation', value);
  }

  get automatedAgentReplyType(): AutomatedAgentReply_AutomatedAgentReplyType
      |null {
    return (
        (this.Serializable$has('automatedAgentReplyType')) ?
            (this.Serializable$get('automatedAgentReplyType')) :
            (null));
  }

  /**
   * AutomatedAgentReply type.
   */
  set automatedAgentReplyType(value:
                                  AutomatedAgentReply_AutomatedAgentReplyType|
                              null) {
    this.Serializable$set('automatedAgentReplyType', value);
  }

  get cxCurrentPage(): string|null {
    return (
        (this.Serializable$has('cxCurrentPage')) ?
            (this.Serializable$get('cxCurrentPage')) :
            (null));
  }

  /**
   * The unique identifier of the current Dialogflow CX conversation page.
   * Format: `projects//locations//agents//flows//pages/`.
   */
  set cxCurrentPage(value: string|null) {
    this.Serializable$set('cxCurrentPage', value);
  }

  get cxSessionParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('cxSessionParameters')) ?
            (this.Serializable$get('cxSessionParameters')) :
            (null));
  }

  /**
   * The collection of current Dialogflow CX agent session parameters at the
   * time of this response. Deprecated: Use `parameters` instead.
   */
  set cxSessionParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('cxSessionParameters', value);
  }

  get detectIntentResponse(): DetectIntentResponse_|null {
    return (
        (this.Serializable$has('detectIntentResponse')) ?
            (this.Serializable$get('detectIntentResponse')) :
            (null));
  }

  /**
   * Response of the Dialogflow Sessions.DetectIntent call.
   */
  set detectIntentResponse(value: DetectIntentResponse_|null) {
    this.Serializable$set('detectIntentResponse', value);
  }

  get detectIntentResponseV3(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('detectIntentResponseV3')) ?
            (this.Serializable$get('detectIntentResponseV3')) :
            (null));
  }

  /**
   * Response from Dialogflow v3.
   */
  set detectIntentResponseV3(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('detectIntentResponseV3', value);
  }

  get event(): string|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * Event name if an event is triggered for the query.
   */
  set event(value: string|null) {
    this.Serializable$set('event', value);
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * Name of the intent if an intent is matched for the query. For a V2 query,
   * the value format is `projects//locations/ /agent/intents/`. For a V3 query,
   * the value format is `projects//locations/ /agents//intents/`.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  get matchConfidence(): number|null {
    return (
        (this.Serializable$has('matchConfidence')) ?
            (this.Serializable$get('matchConfidence')) :
            (null));
  }

  /**
   * The confidence of the match. Values range from 0.0 (completely uncertain)
   * to 1.0 (completely certain). This value is for informational purpose only
   * and is only used to help match the best intent within the classification
   * threshold. This value may change for the same end-user expression at any
   * time due to a model retraining or change in implementation.
   */
  set matchConfidence(value: number|null) {
    this.Serializable$set('matchConfidence', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of current parameters at the time of this response.
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  get responseMessages(): Array<ResponseMessage_>|null {
    return (
        (this.Serializable$has('responseMessages')) ?
            (this.Serializable$get('responseMessages')) :
            (null));
  }

  /**
   * Response messages from the automated agent.
   */
  set responseMessages(value: Array<ResponseMessage_>|null) {
    this.Serializable$set('responseMessages', value);
  }

  getConstructor(): SerializableCtor<AutomatedAgentReply_> {
    return AutomatedAgentReply_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'responseMessages': ResponseMessage_},
      enums: {
        'automatedAgentReplyType':
            AutomatedAgentReply_AutomatedAgentReplyTypeEnum
      },
      keys: [
        'allowCancellation', 'automatedAgentReplyType', 'cxCurrentPage',
        'cxSessionParameters', 'detectIntentResponse', 'detectIntentResponseV3',
        'event', 'intent', 'matchConfidence', 'parameters', 'responseMessages'
      ],
      objectMaps: {
        'cxSessionParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'detectIntentResponseV3': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'detectIntentResponse': DetectIntentResponse_}
    };
  }
}

export interface BargeInConfig_Parameters {
  noBargeInDuration?: string|null;
  totalDuration?: string|null;
}
export class BargeInConfig_ extends Serializable {
  constructor(parameters: BargeInConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'noBargeInDuration',
        (parameters.noBargeInDuration == null) ?
            (null) :
            (parameters.noBargeInDuration));
    this.Serializable$set(
        'totalDuration',
        (parameters.totalDuration == null) ? (null) :
                                             (parameters.totalDuration));
  }

  get noBargeInDuration(): string|null {
    return (
        (this.Serializable$has('noBargeInDuration')) ?
            (this.Serializable$get('noBargeInDuration')) :
            (null));
  }

  /**
   * Duration that is not eligible for barge-in at the beginning of the input
   * audio.
   */
  set noBargeInDuration(value: string|null) {
    this.Serializable$set('noBargeInDuration', value);
  }

  get totalDuration(): string|null {
    return (
        (this.Serializable$has('totalDuration')) ?
            (this.Serializable$get('totalDuration')) :
            (null));
  }

  /**
   * Total duration for the playback at the beginning of the input audio.
   */
  set totalDuration(value: string|null) {
    this.Serializable$set('totalDuration', value);
  }

  getConstructor(): SerializableCtor<BargeInConfig_> {
    return BargeInConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['noBargeInDuration', 'totalDuration']};
  }
}

export interface BatchCopyEntityTypesRequest_Parameters {
  targetAgent?: string|null;
  entityTypeNames?: Array<string>|null;
  languageCode?: string|null;
  copyReferencedEntityTypes?: boolean|null;
  overwriteEntityTypes?: boolean|null;
}
export class BatchCopyEntityTypesRequest_ extends Serializable {
  constructor(parameters: BatchCopyEntityTypesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'targetAgent',
        (parameters.targetAgent == null) ? (null) : (parameters.targetAgent));
    this.Serializable$set(
        'entityTypeNames',
        (parameters.entityTypeNames == null) ? (null) :
                                               (parameters.entityTypeNames));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'copyReferencedEntityTypes',
        (parameters.copyReferencedEntityTypes == null) ?
            (null) :
            (parameters.copyReferencedEntityTypes));
    this.Serializable$set(
        'overwriteEntityTypes',
        (parameters.overwriteEntityTypes == null) ?
            (null) :
            (parameters.overwriteEntityTypes));
  }

  get copyReferencedEntityTypes(): boolean|null {
    return (
        (this.Serializable$has('copyReferencedEntityTypes')) ?
            (this.Serializable$get('copyReferencedEntityTypes')) :
            (null));
  }

  /**
   * Whether to copy referenced entity types to target agent.
   */
  set copyReferencedEntityTypes(value: boolean|null) {
    this.Serializable$set('copyReferencedEntityTypes', value);
  }

  get entityTypeNames(): Array<string>|null {
    return (
        (this.Serializable$has('entityTypeNames')) ?
            (this.Serializable$get('entityTypeNames')) :
            (null));
  }

  /**
   * Required. The collection of entity types to copy.
   */
  set entityTypeNames(value: Array<string>|null) {
    this.Serializable$set('entityTypeNames', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language for entity types. If not specified, the agent's default
   * language is used.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get overwriteEntityTypes(): boolean|null {
    return (
        (this.Serializable$has('overwriteEntityTypes')) ?
            (this.Serializable$get('overwriteEntityTypes')) :
            (null));
  }

  /**
   * Whether to overwrite entity types.
   */
  set overwriteEntityTypes(value: boolean|null) {
    this.Serializable$set('overwriteEntityTypes', value);
  }

  get targetAgent(): string|null {
    return (
        (this.Serializable$has('targetAgent')) ?
            (this.Serializable$get('targetAgent')) :
            (null));
  }

  /**
   * Required. The name of agent that all entity types are moved to, will check
   * dialogflow.entityTypes.create permission for target agent. Format:
   * `projects//locations//agent`.
   */
  set targetAgent(value: string|null) {
    this.Serializable$set('targetAgent', value);
  }

  getConstructor(): SerializableCtor<BatchCopyEntityTypesRequest_> {
    return BatchCopyEntityTypesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'copyReferencedEntityTypes', 'entityTypeNames', 'languageCode',
        'overwriteEntityTypes', 'targetAgent'
      ]
    };
  }
}

export interface BatchCopyIntentsRequest_Parameters {
  targetAgent?: string|null;
  intents?: Array<Intent_>|null;
  languageCode?: string|null;
  copyEntities?: boolean|null;
  overwriteEntities?: boolean|null;
  overwriteIntents?: boolean|null;
}
export class BatchCopyIntentsRequest_ extends Serializable {
  constructor(parameters: BatchCopyIntentsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'targetAgent',
        (parameters.targetAgent == null) ? (null) : (parameters.targetAgent));
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'copyEntities',
        (parameters.copyEntities == null) ? (null) : (parameters.copyEntities));
    this.Serializable$set(
        'overwriteEntities',
        (parameters.overwriteEntities == null) ?
            (null) :
            (parameters.overwriteEntities));
    this.Serializable$set(
        'overwriteIntents',
        (parameters.overwriteIntents == null) ? (null) :
                                                (parameters.overwriteIntents));
  }

  get copyEntities(): boolean|null {
    return (
        (this.Serializable$has('copyEntities')) ?
            (this.Serializable$get('copyEntities')) :
            (null));
  }

  /**
   * Whether to copy entities referenced by intents to target agent.
   */
  set copyEntities(value: boolean|null) {
    this.Serializable$set('copyEntities', value);
  }

  get intents(): Array<Intent_>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * Required. The collection of intents to copy. Only intent `name` must be
   * filled in.
   */
  set intents(value: Array<Intent_>|null) {
    this.Serializable$set('intents', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language for intents. If not specified, the agent's default language is
   * used.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get overwriteEntities(): boolean|null {
    return (
        (this.Serializable$has('overwriteEntities')) ?
            (this.Serializable$get('overwriteEntities')) :
            (null));
  }

  /**
   * Whether to overwrite entities.
   */
  set overwriteEntities(value: boolean|null) {
    this.Serializable$set('overwriteEntities', value);
  }

  get overwriteIntents(): boolean|null {
    return (
        (this.Serializable$has('overwriteIntents')) ?
            (this.Serializable$get('overwriteIntents')) :
            (null));
  }

  /**
   * Whether to overwrite intents.
   */
  set overwriteIntents(value: boolean|null) {
    this.Serializable$set('overwriteIntents', value);
  }

  get targetAgent(): string|null {
    return (
        (this.Serializable$has('targetAgent')) ?
            (this.Serializable$get('targetAgent')) :
            (null));
  }

  /**
   * Required. The name of agent that all intents are copied to, will check
   * dialogflow.intents.create permission for new intents and
   * dialogflow.intents.update permission for intents that are being
   * overwritten. Format: `projects//locations//agent`.
   */
  set targetAgent(value: string|null) {
    this.Serializable$set('targetAgent', value);
  }

  getConstructor(): SerializableCtor<BatchCopyIntentsRequest_> {
    return BatchCopyIntentsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'intents': Intent_},
      keys: [
        'copyEntities', 'intents', 'languageCode', 'overwriteEntities',
        'overwriteIntents', 'targetAgent'
      ]
    };
  }
}

export interface BatchCreateEntitiesRequest_Parameters {
  entities?: Array<EntityTypeEntity_>|null;
  languageCode?: string|null;
}
export class BatchCreateEntitiesRequest_ extends Serializable {
  constructor(parameters: BatchCreateEntitiesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'entities',
        (parameters.entities == null) ? (null) : (parameters.entities));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get entities(): Array<EntityTypeEntity_>|null {
    return (
        (this.Serializable$has('entities')) ?
            (this.Serializable$get('entities')) :
            (null));
  }

  /**
   * Required. The entities to create.
   */
  set entities(value: Array<EntityTypeEntity_>|null) {
    this.Serializable$set('entities', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The language used to access language-specific data. If not
   * specified, the agent's default language is used. For more information, see
   * [Multilingual intent and entity
   * data](https://cloud.google.com/dialogflow/docs/agents-multilingual#intent-entity).
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  getConstructor(): SerializableCtor<BatchCreateEntitiesRequest_> {
    return BatchCreateEntitiesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'entities': EntityTypeEntity_},
      keys: ['entities', 'languageCode']
    };
  }
}

export interface BatchCreateMessagesRequest_Parameters {
  requests?: Array<CreateMessageRequest_>|null;
}
export class BatchCreateMessagesRequest_ extends Serializable {
  constructor(parameters: BatchCreateMessagesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'requests',
        (parameters.requests == null) ? (null) : (parameters.requests));
  }

  get requests(): Array<CreateMessageRequest_>|null {
    return (
        (this.Serializable$has('requests')) ?
            (this.Serializable$get('requests')) :
            (null));
  }

  /**
   * Required. A maximum of 1000 Messages can be created in a batch.
   * CreateMessageRequest.message.send_time is required. All created messages
   * will have identical Message.create_time.
   */
  set requests(value: Array<CreateMessageRequest_>|null) {
    this.Serializable$set('requests', value);
  }

  getConstructor(): SerializableCtor<BatchCreateMessagesRequest_> {
    return BatchCreateMessagesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'requests': CreateMessageRequest_}, keys: ['requests']};
  }
}

export interface BatchCreateMessagesResponse_Parameters {
  messages?: Array<Message_>|null;
}
export class BatchCreateMessagesResponse_ extends Serializable {
  constructor(parameters: BatchCreateMessagesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
  }

  get messages(): Array<Message_>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * Messages created.
   */
  set messages(value: Array<Message_>|null) {
    this.Serializable$set('messages', value);
  }

  getConstructor(): SerializableCtor<BatchCreateMessagesResponse_> {
    return BatchCreateMessagesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'messages': Message_}, keys: ['messages']};
  }
}

export interface BatchCreateParticipantsRequest_Parameters {
  requests?: Array<CreateParticipantRequest_>|null;
}
export class BatchCreateParticipantsRequest_ extends Serializable {
  constructor(parameters: BatchCreateParticipantsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'requests',
        (parameters.requests == null) ? (null) : (parameters.requests));
  }

  get requests(): Array<CreateParticipantRequest_>|null {
    return (
        (this.Serializable$has('requests')) ?
            (this.Serializable$get('requests')) :
            (null));
  }

  /**
   * Required. The request message specifying the participants to create. A
   * maximum of 1000 participants can be created in a batch.
   */
  set requests(value: Array<CreateParticipantRequest_>|null) {
    this.Serializable$set('requests', value);
  }

  getConstructor(): SerializableCtor<BatchCreateParticipantsRequest_> {
    return BatchCreateParticipantsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'requests': CreateParticipantRequest_},
      keys: ['requests']
    };
  }
}

export interface BatchCreateParticipantsResponse_Parameters {
  participants?: Array<Participant_>|null;
}
export class BatchCreateParticipantsResponse_ extends Serializable {
  constructor(parameters: BatchCreateParticipantsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'participants',
        (parameters.participants == null) ? (null) : (parameters.participants));
  }

  get participants(): Array<Participant_>|null {
    return (
        (this.Serializable$has('participants')) ?
            (this.Serializable$get('participants')) :
            (null));
  }

  /**
   * The created participants.
   */
  set participants(value: Array<Participant_>|null) {
    this.Serializable$set('participants', value);
  }

  getConstructor(): SerializableCtor<BatchCreateParticipantsResponse_> {
    return BatchCreateParticipantsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'participants': Participant_}, keys: ['participants']};
  }
}

export interface BatchDeleteEntitiesRequest_Parameters {
  entityValues?: Array<string>|null;
  languageCode?: string|null;
}
export class BatchDeleteEntitiesRequest_ extends Serializable {
  constructor(parameters: BatchDeleteEntitiesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'entityValues',
        (parameters.entityValues == null) ? (null) : (parameters.entityValues));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get entityValues(): Array<string>|null {
    return (
        (this.Serializable$has('entityValues')) ?
            (this.Serializable$get('entityValues')) :
            (null));
  }

  /**
   * Required. The reference `values` of the entities to delete. Note that these
   * are not fully-qualified names, i.e. they don't start with `projects/`.
   */
  set entityValues(value: Array<string>|null) {
    this.Serializable$set('entityValues', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The language used to access language-specific data. If not
   * specified, the agent's default language is used. For more information, see
   * [Multilingual intent and entity
   * data](https://cloud.google.com/dialogflow/docs/agents-multilingual#intent-entity).
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  getConstructor(): SerializableCtor<BatchDeleteEntitiesRequest_> {
    return BatchDeleteEntitiesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['entityValues', 'languageCode']};
  }
}

export interface BatchDeleteEntityTypesRequest_Parameters {
  entityTypeNames?: Array<string>|null;
}
export class BatchDeleteEntityTypesRequest_ extends Serializable {
  constructor(parameters: BatchDeleteEntityTypesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'entityTypeNames',
        (parameters.entityTypeNames == null) ? (null) :
                                               (parameters.entityTypeNames));
  }

  get entityTypeNames(): Array<string>|null {
    return (
        (this.Serializable$has('entityTypeNames')) ?
            (this.Serializable$get('entityTypeNames')) :
            (null));
  }

  /**
   * Required. The names entity types to delete. All names must point to the
   * same agent as `parent`.
   */
  set entityTypeNames(value: Array<string>|null) {
    this.Serializable$set('entityTypeNames', value);
  }

  getConstructor(): SerializableCtor<BatchDeleteEntityTypesRequest_> {
    return BatchDeleteEntityTypesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['entityTypeNames']};
  }
}

export interface BatchDeleteIntentsRequest_Parameters {
  intents?: Array<Intent_>|null;
}
export class BatchDeleteIntentsRequest_ extends Serializable {
  constructor(parameters: BatchDeleteIntentsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
  }

  get intents(): Array<Intent_>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * Required. The collection of intents to delete. Only intent `name` must be
   * filled in.
   */
  set intents(value: Array<Intent_>|null) {
    this.Serializable$set('intents', value);
  }

  getConstructor(): SerializableCtor<BatchDeleteIntentsRequest_> {
    return BatchDeleteIntentsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'intents': Intent_}, keys: ['intents']};
  }
}

export interface BatchDeleteIssueModelingEntriesRequest_Parameters {
  names?: Array<string>|null;
}
export class BatchDeleteIssueModelingEntriesRequest_ extends Serializable {
  constructor(
      parameters: BatchDeleteIssueModelingEntriesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'names', (parameters.names == null) ? (null) : (parameters.names));
  }

  get names(): Array<string>|null {
    return (
        (this.Serializable$has('names')) ? (this.Serializable$get('names')) :
                                           (null));
  }

  /**
   * Required. The name of the issue modeling entries to delete. Format:
   * \"projects/{project_id}/knowledgeBases/{knowledge_base_id}/documents/{document_id}/
   * issueModelingEntries/{issue_modeling_entry_id}\"
   */
  set names(value: Array<string>|null) {
    this.Serializable$set('names', value);
  }

  getConstructor(): SerializableCtor<BatchDeleteIssueModelingEntriesRequest_> {
    return BatchDeleteIssueModelingEntriesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['names']};
  }
}

export interface BatchDeleteSmartMessagingEntriesOperationMetadata_Parameters {
  document?: string|null;
  createTime?: string|null;
}
export class BatchDeleteSmartMessagingEntriesOperationMetadata_ extends
    Serializable {
  constructor(
      parameters:
          BatchDeleteSmartMessagingEntriesOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * The time when the operation was submitted.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The resource name of the document. Format:
   * `projects//knowledgeBases//documents/`
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  getConstructor():
      SerializableCtor<BatchDeleteSmartMessagingEntriesOperationMetadata_> {
    return BatchDeleteSmartMessagingEntriesOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['createTime', 'document']};
  }
}

export interface BatchDeleteSmartMessagingEntriesRequest_Parameters {
  smartMessagingEntries?: Array<string>|null;
  requestId?: string|null;
}
export class BatchDeleteSmartMessagingEntriesRequest_ extends Serializable {
  constructor(
      parameters: BatchDeleteSmartMessagingEntriesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'smartMessagingEntries',
        (parameters.smartMessagingEntries == null) ?
            (null) :
            (parameters.smartMessagingEntries));
    this.Serializable$set(
        'requestId',
        (parameters.requestId == null) ? (null) : (parameters.requestId));
  }

  get requestId(): string|null {
    return (
        (this.Serializable$has('requestId')) ?
            (this.Serializable$get('requestId')) :
            (null));
  }

  /**
   * Optional. A unique string id used for detecting duplicated requests.
   */
  set requestId(value: string|null) {
    this.Serializable$set('requestId', value);
  }

  get smartMessagingEntries(): Array<string>|null {
    return (
        (this.Serializable$has('smartMessagingEntries')) ?
            (this.Serializable$get('smartMessagingEntries')) :
            (null));
  }

  /**
   * Required. Batch delete is applied to a list of Smart Messaging Entries.
   */
  set smartMessagingEntries(value: Array<string>|null) {
    this.Serializable$set('smartMessagingEntries', value);
  }

  getConstructor(): SerializableCtor<BatchDeleteSmartMessagingEntriesRequest_> {
    return BatchDeleteSmartMessagingEntriesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['requestId', 'smartMessagingEntries']};
  }
}

export interface BatchMoveEntityTypesRequest_Parameters {
  targetAgent?: string|null;
  entityTypeNames?: Array<string>|null;
  languageCode?: string|null;
  copyReferencedEntityTypes?: boolean|null;
  overwriteEntityTypes?: boolean|null;
}
export class BatchMoveEntityTypesRequest_ extends Serializable {
  constructor(parameters: BatchMoveEntityTypesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'targetAgent',
        (parameters.targetAgent == null) ? (null) : (parameters.targetAgent));
    this.Serializable$set(
        'entityTypeNames',
        (parameters.entityTypeNames == null) ? (null) :
                                               (parameters.entityTypeNames));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'copyReferencedEntityTypes',
        (parameters.copyReferencedEntityTypes == null) ?
            (null) :
            (parameters.copyReferencedEntityTypes));
    this.Serializable$set(
        'overwriteEntityTypes',
        (parameters.overwriteEntityTypes == null) ?
            (null) :
            (parameters.overwriteEntityTypes));
  }

  get copyReferencedEntityTypes(): boolean|null {
    return (
        (this.Serializable$has('copyReferencedEntityTypes')) ?
            (this.Serializable$get('copyReferencedEntityTypes')) :
            (null));
  }

  /**
   * Whether to copy referenced entity types to target agent.
   */
  set copyReferencedEntityTypes(value: boolean|null) {
    this.Serializable$set('copyReferencedEntityTypes', value);
  }

  get entityTypeNames(): Array<string>|null {
    return (
        (this.Serializable$has('entityTypeNames')) ?
            (this.Serializable$get('entityTypeNames')) :
            (null));
  }

  /**
   * Required. The collection of entity types to move.
   */
  set entityTypeNames(value: Array<string>|null) {
    this.Serializable$set('entityTypeNames', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language for entity types. If not specified, the agent's default
   * language is used.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get overwriteEntityTypes(): boolean|null {
    return (
        (this.Serializable$has('overwriteEntityTypes')) ?
            (this.Serializable$get('overwriteEntityTypes')) :
            (null));
  }

  /**
   * Whether to overwrite entity types.
   */
  set overwriteEntityTypes(value: boolean|null) {
    this.Serializable$set('overwriteEntityTypes', value);
  }

  get targetAgent(): string|null {
    return (
        (this.Serializable$has('targetAgent')) ?
            (this.Serializable$get('targetAgent')) :
            (null));
  }

  /**
   * Required. The name of agent that all entity types are moved to, will check
   * dialogflow.entityTypes.create permission for target agent. Format:
   * `projects//locations//agent`.
   */
  set targetAgent(value: string|null) {
    this.Serializable$set('targetAgent', value);
  }

  getConstructor(): SerializableCtor<BatchMoveEntityTypesRequest_> {
    return BatchMoveEntityTypesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'copyReferencedEntityTypes', 'entityTypeNames', 'languageCode',
        'overwriteEntityTypes', 'targetAgent'
      ]
    };
  }
}

export interface BatchMoveIntentsRequest_Parameters {
  targetAgent?: string|null;
  intents?: Array<Intent_>|null;
  languageCode?: string|null;
  copyEntities?: boolean|null;
  overwriteEntities?: boolean|null;
  overwriteIntents?: boolean|null;
}
export class BatchMoveIntentsRequest_ extends Serializable {
  constructor(parameters: BatchMoveIntentsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'targetAgent',
        (parameters.targetAgent == null) ? (null) : (parameters.targetAgent));
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'copyEntities',
        (parameters.copyEntities == null) ? (null) : (parameters.copyEntities));
    this.Serializable$set(
        'overwriteEntities',
        (parameters.overwriteEntities == null) ?
            (null) :
            (parameters.overwriteEntities));
    this.Serializable$set(
        'overwriteIntents',
        (parameters.overwriteIntents == null) ? (null) :
                                                (parameters.overwriteIntents));
  }

  get copyEntities(): boolean|null {
    return (
        (this.Serializable$has('copyEntities')) ?
            (this.Serializable$get('copyEntities')) :
            (null));
  }

  /**
   * Whether to copy entities referenced by intents to target agent.
   */
  set copyEntities(value: boolean|null) {
    this.Serializable$set('copyEntities', value);
  }

  get intents(): Array<Intent_>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * Required. The collection of intents to move. Only intent `name` must be
   * filled in.
   */
  set intents(value: Array<Intent_>|null) {
    this.Serializable$set('intents', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language for intents. If not specified, the agent's default language is
   * used.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get overwriteEntities(): boolean|null {
    return (
        (this.Serializable$has('overwriteEntities')) ?
            (this.Serializable$get('overwriteEntities')) :
            (null));
  }

  /**
   * Whether to overwrite entities.
   */
  set overwriteEntities(value: boolean|null) {
    this.Serializable$set('overwriteEntities', value);
  }

  get overwriteIntents(): boolean|null {
    return (
        (this.Serializable$has('overwriteIntents')) ?
            (this.Serializable$get('overwriteIntents')) :
            (null));
  }

  /**
   * Whether to overwrite intents.
   */
  set overwriteIntents(value: boolean|null) {
    this.Serializable$set('overwriteIntents', value);
  }

  get targetAgent(): string|null {
    return (
        (this.Serializable$has('targetAgent')) ?
            (this.Serializable$get('targetAgent')) :
            (null));
  }

  /**
   * Required. The name of agent that all intents are moved to, will check
   * dialogflow.intents.create permission for new intents and
   * dialogflow.intents.update permission for intents that are being
   * overwritten. Format: `projects//locations//agent`.
   */
  set targetAgent(value: string|null) {
    this.Serializable$set('targetAgent', value);
  }

  getConstructor(): SerializableCtor<BatchMoveIntentsRequest_> {
    return BatchMoveIntentsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'intents': Intent_},
      keys: [
        'copyEntities', 'intents', 'languageCode', 'overwriteEntities',
        'overwriteIntents', 'targetAgent'
      ]
    };
  }
}

export interface BatchUpdateEntitiesRequest_Parameters {
  entities?: Array<EntityTypeEntity_>|null;
  languageCode?: string|null;
  updateMask?: string|null;
}
export class BatchUpdateEntitiesRequest_ extends Serializable {
  constructor(parameters: BatchUpdateEntitiesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'entities',
        (parameters.entities == null) ? (null) : (parameters.entities));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'updateMask',
        (parameters.updateMask == null) ? (null) : (parameters.updateMask));
  }

  get entities(): Array<EntityTypeEntity_>|null {
    return (
        (this.Serializable$has('entities')) ?
            (this.Serializable$get('entities')) :
            (null));
  }

  /**
   * Required. The entities to update or create.
   */
  set entities(value: Array<EntityTypeEntity_>|null) {
    this.Serializable$set('entities', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The language used to access language-specific data. If not
   * specified, the agent's default language is used. For more information, see
   * [Multilingual intent and entity
   * data](https://cloud.google.com/dialogflow/docs/agents-multilingual#intent-entity).
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get updateMask(): string|null {
    return (
        (this.Serializable$has('updateMask')) ?
            (this.Serializable$get('updateMask')) :
            (null));
  }

  /**
   * Optional. The mask to control which fields get updated.
   */
  set updateMask(value: string|null) {
    this.Serializable$set('updateMask', value);
  }

  getConstructor(): SerializableCtor<BatchUpdateEntitiesRequest_> {
    return BatchUpdateEntitiesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'entities': EntityTypeEntity_},
      keys: ['entities', 'languageCode', 'updateMask']
    };
  }
}

export interface BatchUpdateEntityTypesRequest_Parameters {
  entityTypeBatchUri?: string|null;
  entityTypeBatchInline?: EntityTypeBatch_|null;
  entityTypeBatchSerialized?: EntityTypeBatchSerialized_|null;
  languageCode?: string|null;
  updateMask?: string|null;
}
export class BatchUpdateEntityTypesRequest_ extends Serializable {
  constructor(parameters: BatchUpdateEntityTypesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'entityTypeBatchUri',
        (parameters.entityTypeBatchUri == null) ?
            (null) :
            (parameters.entityTypeBatchUri));
    this.Serializable$set(
        'entityTypeBatchInline',
        (parameters.entityTypeBatchInline == null) ?
            (null) :
            (parameters.entityTypeBatchInline));
    this.Serializable$set(
        'entityTypeBatchSerialized',
        (parameters.entityTypeBatchSerialized == null) ?
            (null) :
            (parameters.entityTypeBatchSerialized));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'updateMask',
        (parameters.updateMask == null) ? (null) : (parameters.updateMask));
  }

  get entityTypeBatchInline(): EntityTypeBatch_|null {
    return (
        (this.Serializable$has('entityTypeBatchInline')) ?
            (this.Serializable$get('entityTypeBatchInline')) :
            (null));
  }

  /**
   * The collection of entity types to update or create.
   */
  set entityTypeBatchInline(value: EntityTypeBatch_|null) {
    this.Serializable$set('entityTypeBatchInline', value);
  }

  get entityTypeBatchSerialized(): EntityTypeBatchSerialized_|null {
    return (
        (this.Serializable$has('entityTypeBatchSerialized')) ?
            (this.Serializable$get('entityTypeBatchSerialized')) :
            (null));
  }

  /**
   * Serialized entity types in proto or JSON format.
   */
  set entityTypeBatchSerialized(value: EntityTypeBatchSerialized_|null) {
    this.Serializable$set('entityTypeBatchSerialized', value);
  }

  get entityTypeBatchUri(): string|null {
    return (
        (this.Serializable$has('entityTypeBatchUri')) ?
            (this.Serializable$get('entityTypeBatchUri')) :
            (null));
  }

  /**
   * The URI to a Google Cloud Storage file containing entity types to update or
   * create. The file format can either be a serialized proto (of EntityBatch
   * type) or a JSON object. Note: The URI must start with \"gs://\".
   */
  set entityTypeBatchUri(value: string|null) {
    this.Serializable$set('entityTypeBatchUri', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The language used to access language-specific data. If not
   * specified, the agent's default language is used. For more information, see
   * [Multilingual intent and entity
   * data](https://cloud.google.com/dialogflow/docs/agents-multilingual#intent-entity).
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get updateMask(): string|null {
    return (
        (this.Serializable$has('updateMask')) ?
            (this.Serializable$get('updateMask')) :
            (null));
  }

  /**
   * Optional. The mask to control which fields get updated.
   */
  set updateMask(value: string|null) {
    this.Serializable$set('updateMask', value);
  }

  getConstructor(): SerializableCtor<BatchUpdateEntityTypesRequest_> {
    return BatchUpdateEntityTypesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'entityTypeBatchInline', 'entityTypeBatchSerialized',
        'entityTypeBatchUri', 'languageCode', 'updateMask'
      ],
      objects: {
        'entityTypeBatchInline': EntityTypeBatch_,
        'entityTypeBatchSerialized': EntityTypeBatchSerialized_
      }
    };
  }
}

export interface BatchUpdateEntityTypesResponse_Parameters {
  entityTypes?: Array<EntityType_>|null;
}
export class BatchUpdateEntityTypesResponse_ extends Serializable {
  constructor(parameters: BatchUpdateEntityTypesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'entityTypes',
        (parameters.entityTypes == null) ? (null) : (parameters.entityTypes));
  }

  get entityTypes(): Array<EntityType_>|null {
    return (
        (this.Serializable$has('entityTypes')) ?
            (this.Serializable$get('entityTypes')) :
            (null));
  }

  /**
   * The collection of updated or created entity types.
   */
  set entityTypes(value: Array<EntityType_>|null) {
    this.Serializable$set('entityTypes', value);
  }

  getConstructor(): SerializableCtor<BatchUpdateEntityTypesResponse_> {
    return BatchUpdateEntityTypesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'entityTypes': EntityType_}, keys: ['entityTypes']};
  }
}

export interface BatchUpdateIntentsRequest_Parameters {
  intentBatchUri?: string|null;
  intentBatchInline?: IntentBatch_|null;
  intentBatchSerialized?: IntentBatchSerialized_|null;
  languageCode?: string|null;
  updateMask?: string|null;
  intentView?: BatchUpdateIntentsRequest_IntentView|null;
}
export class BatchUpdateIntentsRequest_ extends Serializable {
  constructor(parameters: BatchUpdateIntentsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'intentBatchUri',
        (parameters.intentBatchUri == null) ? (null) :
                                              (parameters.intentBatchUri));
    this.Serializable$set(
        'intentBatchInline',
        (parameters.intentBatchInline == null) ?
            (null) :
            (parameters.intentBatchInline));
    this.Serializable$set(
        'intentBatchSerialized',
        (parameters.intentBatchSerialized == null) ?
            (null) :
            (parameters.intentBatchSerialized));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'updateMask',
        (parameters.updateMask == null) ? (null) : (parameters.updateMask));
    this.Serializable$set(
        'intentView',
        (parameters.intentView == null) ? (null) : (parameters.intentView));
  }

  static get IntentView(): IBatchUpdateIntentsRequest_IntentViewEnum {
    return BatchUpdateIntentsRequest_IntentViewEnum;
  }

  get intentBatchInline(): IntentBatch_|null {
    return (
        (this.Serializable$has('intentBatchInline')) ?
            (this.Serializable$get('intentBatchInline')) :
            (null));
  }

  /**
   * The collection of intents to update or create.
   */
  set intentBatchInline(value: IntentBatch_|null) {
    this.Serializable$set('intentBatchInline', value);
  }

  get intentBatchSerialized(): IntentBatchSerialized_|null {
    return (
        (this.Serializable$has('intentBatchSerialized')) ?
            (this.Serializable$get('intentBatchSerialized')) :
            (null));
  }

  /**
   * Serialized intents in proto or JSON format.
   */
  set intentBatchSerialized(value: IntentBatchSerialized_|null) {
    this.Serializable$set('intentBatchSerialized', value);
  }

  get intentBatchUri(): string|null {
    return (
        (this.Serializable$has('intentBatchUri')) ?
            (this.Serializable$get('intentBatchUri')) :
            (null));
  }

  /**
   * The URI to a Google Cloud Storage file containing intents to update or
   * create. The file format can either be a serialized proto (of IntentBatch
   * type) or JSON object. Note: The URI must start with \"gs://\".
   */
  set intentBatchUri(value: string|null) {
    this.Serializable$set('intentBatchUri', value);
  }

  get intentView(): BatchUpdateIntentsRequest_IntentView|null {
    return (
        (this.Serializable$has('intentView')) ?
            (this.Serializable$get('intentView')) :
            (null));
  }

  /**
   * Optional. The resource view to apply to the returned intent.
   */
  set intentView(value: BatchUpdateIntentsRequest_IntentView|null) {
    this.Serializable$set('intentView', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The language used to access language-specific data. If not
   * specified, the agent's default language is used. For more information, see
   * [Multilingual intent and entity
   * data](https://cloud.google.com/dialogflow/docs/agents-multilingual#intent-entity).
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get updateMask(): string|null {
    return (
        (this.Serializable$has('updateMask')) ?
            (this.Serializable$get('updateMask')) :
            (null));
  }

  /**
   * Optional. The mask to control which fields get updated.
   */
  set updateMask(value: string|null) {
    this.Serializable$set('updateMask', value);
  }

  getConstructor(): SerializableCtor<BatchUpdateIntentsRequest_> {
    return BatchUpdateIntentsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'intentView': BatchUpdateIntentsRequest_IntentViewEnum},
      keys: [
        'intentBatchInline', 'intentBatchSerialized', 'intentBatchUri',
        'intentView', 'languageCode', 'updateMask'
      ],
      objects: {
        'intentBatchInline': IntentBatch_,
        'intentBatchSerialized': IntentBatchSerialized_
      }
    };
  }
}

export interface BatchUpdateIntentsResponse_Parameters {
  intents?: Array<Intent_>|null;
}
export class BatchUpdateIntentsResponse_ extends Serializable {
  constructor(parameters: BatchUpdateIntentsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
  }

  get intents(): Array<Intent_>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * The collection of updated or created intents.
   */
  set intents(value: Array<Intent_>|null) {
    this.Serializable$set('intents', value);
  }

  getConstructor(): SerializableCtor<BatchUpdateIntentsResponse_> {
    return BatchUpdateIntentsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'intents': Intent_}, keys: ['intents']};
  }
}

export interface BatchUpdateSmartMessagingEntriesOperationMetadata_Parameters {
  document?: string|null;
  createTime?: string|null;
}
export class BatchUpdateSmartMessagingEntriesOperationMetadata_ extends
    Serializable {
  constructor(
      parameters:
          BatchUpdateSmartMessagingEntriesOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * The time when the operation was submitted.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The resource name of the document. Format:
   * `projects//knowledgeBases//documents/`
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  getConstructor():
      SerializableCtor<BatchUpdateSmartMessagingEntriesOperationMetadata_> {
    return BatchUpdateSmartMessagingEntriesOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['createTime', 'document']};
  }
}

export interface BatchUpdateSmartMessagingEntriesRequest_Parameters {
  updateSmartMessagingEntryRequest?: Array<UpdateSmartMessagingEntryRequest_>|
      null;
  requestId?: string|null;
}
export class BatchUpdateSmartMessagingEntriesRequest_ extends Serializable {
  constructor(
      parameters: BatchUpdateSmartMessagingEntriesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'updateSmartMessagingEntryRequest',
        (parameters.updateSmartMessagingEntryRequest == null) ?
            (null) :
            (parameters.updateSmartMessagingEntryRequest));
    this.Serializable$set(
        'requestId',
        (parameters.requestId == null) ? (null) : (parameters.requestId));
  }

  get requestId(): string|null {
    return (
        (this.Serializable$has('requestId')) ?
            (this.Serializable$get('requestId')) :
            (null));
  }

  /**
   * Optional. A unique string id used for detecting duplicated requests.
   */
  set requestId(value: string|null) {
    this.Serializable$set('requestId', value);
  }

  get updateSmartMessagingEntryRequest():
      Array<UpdateSmartMessagingEntryRequest_>|null {
    return (
        (this.Serializable$has('updateSmartMessagingEntryRequest')) ?
            (this.Serializable$get('updateSmartMessagingEntryRequest')) :
            (null));
  }

  /**
   * Required. Specifies what Smart Messaging Entries are subject to update, and
   * what state should they be after the update.
   */
  set updateSmartMessagingEntryRequest(
      value: Array<UpdateSmartMessagingEntryRequest_>|null) {
    this.Serializable$set('updateSmartMessagingEntryRequest', value);
  }

  getConstructor(): SerializableCtor<BatchUpdateSmartMessagingEntriesRequest_> {
    return BatchUpdateSmartMessagingEntriesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'updateSmartMessagingEntryRequest': UpdateSmartMessagingEntryRequest_
      },
      keys: ['requestId', 'updateSmartMessagingEntryRequest']
    };
  }
}

export interface BatchUpdateSmartMessagingEntriesResponse_Parameters {
  smartMessagingEntries?: Array<SmartMessagingEntry_>|null;
}
export class BatchUpdateSmartMessagingEntriesResponse_ extends Serializable {
  constructor(
      parameters: BatchUpdateSmartMessagingEntriesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'smartMessagingEntries',
        (parameters.smartMessagingEntries == null) ?
            (null) :
            (parameters.smartMessagingEntries));
  }

  get smartMessagingEntries(): Array<SmartMessagingEntry_>|null {
    return (
        (this.Serializable$has('smartMessagingEntries')) ?
            (this.Serializable$get('smartMessagingEntries')) :
            (null));
  }

  /**
   * List of updated smart message entries.
   */
  set smartMessagingEntries(value: Array<SmartMessagingEntry_>|null) {
    this.Serializable$set('smartMessagingEntries', value);
  }

  getConstructor():
      SerializableCtor<BatchUpdateSmartMessagingEntriesResponse_> {
    return BatchUpdateSmartMessagingEntriesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'smartMessagingEntries': SmartMessagingEntry_},
      keys: ['smartMessagingEntries']
    };
  }
}

export interface CalculateSemanticMatchRequest_Parameters {
  queries?: Array<string>|null;
  text?: string|null;
  transcriptChunks?: TranscriptChunks_|null;
  semanticMatchContext?: SemanticMatchContext_|null;
}
export class CalculateSemanticMatchRequest_ extends Serializable {
  constructor(parameters: CalculateSemanticMatchRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'queries',
        (parameters.queries == null) ? (null) : (parameters.queries));
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'transcriptChunks',
        (parameters.transcriptChunks == null) ? (null) :
                                                (parameters.transcriptChunks));
    this.Serializable$set(
        'semanticMatchContext',
        (parameters.semanticMatchContext == null) ?
            (null) :
            (parameters.semanticMatchContext));
  }

  get queries(): Array<string>|null {
    return (
        (this.Serializable$has('queries')) ?
            (this.Serializable$get('queries')) :
            (null));
  }

  /**
   * Required. The queries for which we want to calculate the semantic match.
   */
  set queries(value: Array<string>|null) {
    this.Serializable$set('queries', value);
  }

  get semanticMatchContext(): SemanticMatchContext_|null {
    return (
        (this.Serializable$has('semanticMatchContext')) ?
            (this.Serializable$get('semanticMatchContext')) :
            (null));
  }

  /**
   * Required. SemanticMatchContext specific information such as model,
   * language_code, etc.
   */
  set semanticMatchContext(value: SemanticMatchContext_|null) {
    this.Serializable$set('semanticMatchContext', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The text that can be a short phrase, a sentence and etc.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  get transcriptChunks(): TranscriptChunks_|null {
    return (
        (this.Serializable$has('transcriptChunks')) ?
            (this.Serializable$get('transcriptChunks')) :
            (null));
  }

  /**
   * All the transcript chunks to be matched with the queries. The key and the
   * value refer to the index and text of the transcript chunk, respectively.
   */
  set transcriptChunks(value: TranscriptChunks_|null) {
    this.Serializable$set('transcriptChunks', value);
  }

  getConstructor(): SerializableCtor<CalculateSemanticMatchRequest_> {
    return CalculateSemanticMatchRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['queries', 'semanticMatchContext', 'text', 'transcriptChunks'],
      objects: {
        'semanticMatchContext': SemanticMatchContext_,
        'transcriptChunks': TranscriptChunks_
      }
    };
  }
}

export interface CalculateSemanticMatchResponse_Parameters {
  similaritiesPerQuery?: ApiClientObjectMap<number>|null;
  similaritiesPerChunkPerQuery?: ApiClientObjectMap<SimilaritiesByChunk_>|null;
}
export class CalculateSemanticMatchResponse_ extends Serializable {
  constructor(parameters: CalculateSemanticMatchResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'similaritiesPerQuery',
        (parameters.similaritiesPerQuery == null) ?
            (null) :
            (parameters.similaritiesPerQuery));
    this.Serializable$set(
        'similaritiesPerChunkPerQuery',
        (parameters.similaritiesPerChunkPerQuery == null) ?
            (null) :
            (parameters.similaritiesPerChunkPerQuery));
  }

  get similaritiesPerChunkPerQuery():
      ApiClientObjectMap<SimilaritiesByChunk_>|null {
    return (
        (this.Serializable$has('similaritiesPerChunkPerQuery')) ?
            (this.Serializable$get('similaritiesPerChunkPerQuery')) :
            (null));
  }

  /**
   * Returns the similarities calculated per chunk and query.
   */
  set similaritiesPerChunkPerQuery(value:
                                       ApiClientObjectMap<SimilaritiesByChunk_>|
                                   null) {
    this.Serializable$set('similaritiesPerChunkPerQuery', value);
  }

  get similaritiesPerQuery(): ApiClientObjectMap<number>|null {
    return (
        (this.Serializable$has('similaritiesPerQuery')) ?
            (this.Serializable$get('similaritiesPerQuery')) :
            (null));
  }

  /**
   * Returns the similarity calculated per query for the provided text. Values
   * range from -1.0 to 1.0 while keys are full queries.
   */
  set similaritiesPerQuery(value: ApiClientObjectMap<number>|null) {
    this.Serializable$set('similaritiesPerQuery', value);
  }

  getConstructor(): SerializableCtor<CalculateSemanticMatchResponse_> {
    return CalculateSemanticMatchResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['similaritiesPerChunkPerQuery', 'similaritiesPerQuery'],
      objectMaps: {
        'similaritiesPerChunkPerQuery': {
          ctor: SimilaritiesByChunk_,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        },
        'similaritiesPerQuery': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface CallCompanionConversationEventConversationEnded_Parameters {}
export class CallCompanionConversationEventConversationEnded_ extends
    Serializable {
  constructor(
      parameters:
          CallCompanionConversationEventConversationEnded_Parameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<CallCompanionConversationEventConversationEnded_> {
    return CallCompanionConversationEventConversationEnded_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface CallCompanionConversationEventConversationStarted_Parameters {}
export class CallCompanionConversationEventConversationStarted_ extends
    Serializable {
  constructor(
      parameters:
          CallCompanionConversationEventConversationStarted_Parameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<CallCompanionConversationEventConversationStarted_> {
    return CallCompanionConversationEventConversationStarted_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface CallCompanionConversationEventImageMessage_Parameters {
  imageUri?: string|null;
  altText?: string|null;
  width?: string|null;
  height?: string|null;
}
export class CallCompanionConversationEventImageMessage_ extends Serializable {
  constructor(
      parameters: CallCompanionConversationEventImageMessage_Parameters = {}) {
    super();
    this.Serializable$set(
        'imageUri',
        (parameters.imageUri == null) ? (null) : (parameters.imageUri));
    this.Serializable$set(
        'altText',
        (parameters.altText == null) ? (null) : (parameters.altText));
    this.Serializable$set(
        'width', (parameters.width == null) ? (null) : (parameters.width));
    this.Serializable$set(
        'height', (parameters.height == null) ? (null) : (parameters.height));
  }

  get altText(): string|null {
    return (
        (this.Serializable$has('altText')) ?
            (this.Serializable$get('altText')) :
            (null));
  }

  /**
   * Image alternative text.
   */
  set altText(value: string|null) {
    this.Serializable$set('altText', value);
  }

  get height(): string|null {
    return (
        (this.Serializable$has('height')) ? (this.Serializable$get('height')) :
                                            (null));
  }

  /**
   * Image height (px).
   */
  set height(value: string|null) {
    this.Serializable$set('height', value);
  }

  get imageUri(): string|null {
    return (
        (this.Serializable$has('imageUri')) ?
            (this.Serializable$get('imageUri')) :
            (null));
  }

  /**
   * Image URI.
   */
  set imageUri(value: string|null) {
    this.Serializable$set('imageUri', value);
  }

  get width(): string|null {
    return (
        (this.Serializable$has('width')) ? (this.Serializable$get('width')) :
                                           (null));
  }

  /**
   * Image width (px).
   */
  set width(value: string|null) {
    this.Serializable$set('width', value);
  }

  getConstructor():
      SerializableCtor<CallCompanionConversationEventImageMessage_> {
    return CallCompanionConversationEventImageMessage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['altText', 'height', 'imageUri', 'width']};
  }
}

export interface CallCompanionConversationEventInputFormMessage_Parameters {}
export class CallCompanionConversationEventInputFormMessage_ extends
    Serializable {
  constructor(
      parameters:
          CallCompanionConversationEventInputFormMessage_Parameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<CallCompanionConversationEventInputFormMessage_> {
    return CallCompanionConversationEventInputFormMessage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface CallCompanionConversationEventResponseOption_Parameters {
  userInput?: CallCompanionUserInput_|null;
}
export class CallCompanionConversationEventResponseOption_ extends
    Serializable {
  constructor(
      parameters:
          CallCompanionConversationEventResponseOption_Parameters = {}) {
    super();
    this.Serializable$set(
        'userInput',
        (parameters.userInput == null) ? (null) : (parameters.userInput));
  }

  get userInput(): CallCompanionUserInput_|null {
    return (
        (this.Serializable$has('userInput')) ?
            (this.Serializable$get('userInput')) :
            (null));
  }

  /**
   * User response that Call Companion sends to the agent when user chooses this
   * response option.
   */
  set userInput(value: CallCompanionUserInput_|null) {
    this.Serializable$set('userInput', value);
  }

  getConstructor():
      SerializableCtor<CallCompanionConversationEventResponseOption_> {
    return CallCompanionConversationEventResponseOption_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['userInput'],
      objects: {'userInput': CallCompanionUserInput_}
    };
  }
}

export interface CallCompanionConversationEventResponseOptionsMessage_Parameters {
  responseOptions?: Array<CallCompanionConversationEventResponseOption_>|null;
}
export class CallCompanionConversationEventResponseOptionsMessage_ extends
    Serializable {
  constructor(
      parameters:
          CallCompanionConversationEventResponseOptionsMessage_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'responseOptions',
        (parameters.responseOptions == null) ? (null) :
                                               (parameters.responseOptions));
  }

  get responseOptions():
      Array<CallCompanionConversationEventResponseOption_>|null {
    return (
        (this.Serializable$has('responseOptions')) ?
            (this.Serializable$get('responseOptions')) :
            (null));
  }

  /**
   * Response option.
   */
  set responseOptions(value:
                          Array<CallCompanionConversationEventResponseOption_>|
                      null) {
    this.Serializable$set('responseOptions', value);
  }

  getConstructor():
      SerializableCtor<CallCompanionConversationEventResponseOptionsMessage_> {
    return CallCompanionConversationEventResponseOptionsMessage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'responseOptions': CallCompanionConversationEventResponseOption_},
      keys: ['responseOptions']
    };
  }
}

export interface CallCompanionConversationEventTextMessage_Parameters {
  text?: string|null;
  senderRole?: CallCompanionConversationEventTextMessage_SenderRole|null;
}
export class CallCompanionConversationEventTextMessage_ extends Serializable {
  constructor(
      parameters: CallCompanionConversationEventTextMessage_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'senderRole',
        (parameters.senderRole == null) ? (null) : (parameters.senderRole));
  }

  static get SenderRole():
      ICallCompanionConversationEventTextMessage_SenderRoleEnum {
    return CallCompanionConversationEventTextMessage_SenderRoleEnum;
  }

  get senderRole(): CallCompanionConversationEventTextMessage_SenderRole|null {
    return (
        (this.Serializable$has('senderRole')) ?
            (this.Serializable$get('senderRole')) :
            (null));
  }

  /**
   * Sender role.
   */
  set senderRole(value: CallCompanionConversationEventTextMessage_SenderRole|
                 null) {
    this.Serializable$set('senderRole', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Message text.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<CallCompanionConversationEventTextMessage_> {
    return CallCompanionConversationEventTextMessage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'senderRole': CallCompanionConversationEventTextMessage_SenderRoleEnum
      },
      keys: ['senderRole', 'text']
    };
  }
}

export interface CallCompanionConversationEvent_Parameters {
  createTime?: string|null;
  conversationStarted?: CallCompanionConversationEventConversationStarted_|null;
  conversationEnded?: CallCompanionConversationEventConversationEnded_|null;
  message?: CallCompanionConversationEventTextMessage_|null;
  image?: CallCompanionConversationEventImageMessage_|null;
  responseOptions?: CallCompanionConversationEventResponseOptionsMessage_|null;
  inputForm?: CallCompanionConversationEventInputFormMessage_|null;
}
export class CallCompanionConversationEvent_ extends Serializable {
  constructor(parameters: CallCompanionConversationEvent_Parameters = {}) {
    super();
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'conversationStarted',
        (parameters.conversationStarted == null) ?
            (null) :
            (parameters.conversationStarted));
    this.Serializable$set(
        'conversationEnded',
        (parameters.conversationEnded == null) ?
            (null) :
            (parameters.conversationEnded));
    this.Serializable$set(
        'message',
        (parameters.message == null) ? (null) : (parameters.message));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
    this.Serializable$set(
        'responseOptions',
        (parameters.responseOptions == null) ? (null) :
                                               (parameters.responseOptions));
    this.Serializable$set(
        'inputForm',
        (parameters.inputForm == null) ? (null) : (parameters.inputForm));
  }

  get conversationEnded(): CallCompanionConversationEventConversationEnded_
      |null {
    return (
        (this.Serializable$has('conversationEnded')) ?
            (this.Serializable$get('conversationEnded')) :
            (null));
  }

  /**
   * Conversation ended.
   */
  set conversationEnded(value: CallCompanionConversationEventConversationEnded_|
                        null) {
    this.Serializable$set('conversationEnded', value);
  }

  get conversationStarted(): CallCompanionConversationEventConversationStarted_
      |null {
    return (
        (this.Serializable$has('conversationStarted')) ?
            (this.Serializable$get('conversationStarted')) :
            (null));
  }

  /**
   * Conversation started.
   */
  set conversationStarted(
      value: CallCompanionConversationEventConversationStarted_|null) {
    this.Serializable$set('conversationStarted', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when event was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get image(): CallCompanionConversationEventImageMessage_|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Image message. Only virtual agent can send images.
   */
  set image(value: CallCompanionConversationEventImageMessage_|null) {
    this.Serializable$set('image', value);
  }

  get inputForm(): CallCompanionConversationEventInputFormMessage_|null {
    return (
        (this.Serializable$has('inputForm')) ?
            (this.Serializable$get('inputForm')) :
            (null));
  }

  /**
   * Input form specification for the user response. Virtual agent can define
   * user input format via this message.
   */
  set inputForm(value: CallCompanionConversationEventInputFormMessage_|null) {
    this.Serializable$set('inputForm', value);
  }

  get message(): CallCompanionConversationEventTextMessage_|null {
    return (
        (this.Serializable$has('message')) ?
            (this.Serializable$get('message')) :
            (null));
  }

  /**
   * Text message sent by end user or virtual agent.
   */
  set message(value: CallCompanionConversationEventTextMessage_|null) {
    this.Serializable$set('message', value);
  }

  get responseOptions(): CallCompanionConversationEventResponseOptionsMessage_
      |null {
    return (
        (this.Serializable$has('responseOptions')) ?
            (this.Serializable$get('responseOptions')) :
            (null));
  }

  /**
   * Response options. Virtual agent can specify sample responses. They will be
   * rendered as buttons.
   */
  set responseOptions(value:
                          CallCompanionConversationEventResponseOptionsMessage_|
                      null) {
    this.Serializable$set('responseOptions', value);
  }

  getConstructor(): SerializableCtor<CallCompanionConversationEvent_> {
    return CallCompanionConversationEvent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'conversationEnded', 'conversationStarted', 'createTime', 'image',
        'inputForm', 'message', 'responseOptions'
      ],
      objects: {
        'conversationEnded': CallCompanionConversationEventConversationEnded_,
        'conversationStarted':
            CallCompanionConversationEventConversationStarted_,
        'image': CallCompanionConversationEventImageMessage_,
        'inputForm': CallCompanionConversationEventInputFormMessage_,
        'message': CallCompanionConversationEventTextMessage_,
        'responseOptions': CallCompanionConversationEventResponseOptionsMessage_
      }
    };
  }
}

export interface CallCompanionUserInput_Parameters {
  text?: string|null;
}
export class CallCompanionUserInput_ extends Serializable {
  constructor(parameters: CallCompanionUserInput_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Text query to inject.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<CallCompanionUserInput_> {
    return CallCompanionUserInput_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['text']};
  }
}

export interface CallMatcherCustomHeaders_Parameters {
  ciscoGuid?: string|null;
}
export class CallMatcherCustomHeaders_ extends Serializable {
  constructor(parameters: CallMatcherCustomHeaders_Parameters = {}) {
    super();
    this.Serializable$set(
        'ciscoGuid',
        (parameters.ciscoGuid == null) ? (null) : (parameters.ciscoGuid));
  }

  get ciscoGuid(): string|null {
    return (
        (this.Serializable$has('ciscoGuid')) ?
            (this.Serializable$get('ciscoGuid')) :
            (null));
  }

  /**
   * Cisco's proprietary `Cisco-Guid` header.
   */
  set ciscoGuid(value: string|null) {
    this.Serializable$set('ciscoGuid', value);
  }

  getConstructor(): SerializableCtor<CallMatcherCustomHeaders_> {
    return CallMatcherCustomHeaders_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['ciscoGuid']};
  }
}

export interface CallMatcher_Parameters {
  name?: string|null;
  toHeader?: string|null;
  fromHeader?: string|null;
  callIdHeader?: string|null;
  customHeaders?: CallMatcherCustomHeaders_|null;
}
export class CallMatcher_ extends Serializable {
  constructor(parameters: CallMatcher_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'toHeader',
        (parameters.toHeader == null) ? (null) : (parameters.toHeader));
    this.Serializable$set(
        'fromHeader',
        (parameters.fromHeader == null) ? (null) : (parameters.fromHeader));
    this.Serializable$set(
        'callIdHeader',
        (parameters.callIdHeader == null) ? (null) : (parameters.callIdHeader));
    this.Serializable$set(
        'customHeaders',
        (parameters.customHeaders == null) ? (null) :
                                             (parameters.customHeaders));
  }

  get callIdHeader(): string|null {
    return (
        (this.Serializable$has('callIdHeader')) ?
            (this.Serializable$get('callIdHeader')) :
            (null));
  }

  /**
   * Value of the [`Call-ID`
   * header](https://tools.ietf.org/html/rfc3261#section-8.1.1.4) to match. If
   * empty or unspecified, we don't match to the [`Call-ID`
   * header](https://tools.ietf.org/html/rfc3261#section-8.1.1.4).
   */
  set callIdHeader(value: string|null) {
    this.Serializable$set('callIdHeader', value);
  }

  get customHeaders(): CallMatcherCustomHeaders_|null {
    return (
        (this.Serializable$has('customHeaders')) ?
            (this.Serializable$get('customHeaders')) :
            (null));
  }

  /**
   * Custom SIP headers that must match.
   */
  set customHeaders(value: CallMatcherCustomHeaders_|null) {
    this.Serializable$set('customHeaders', value);
  }

  get fromHeader(): string|null {
    return (
        (this.Serializable$has('fromHeader')) ?
            (this.Serializable$get('fromHeader')) :
            (null));
  }

  /**
   * Value of the [`From`
   * header](https://tools.ietf.org/html/rfc3261#section-8.1.1.3) to match. If
   * empty or unspecified, we don't match to the [`From`
   * header](https://tools.ietf.org/html/rfc3261#section-8.1.1.3).
   */
  set fromHeader(value: string|null) {
    this.Serializable$set('fromHeader', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this call matcher. Format:
   * `projects//locations//conversations//callMatchers/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get toHeader(): string|null {
    return (
        (this.Serializable$has('toHeader')) ?
            (this.Serializable$get('toHeader')) :
            (null));
  }

  /**
   * Value of the [`To`
   * header](https://tools.ietf.org/html/rfc3261#section-8.1.1.2) to match. If
   * empty or unspecified, we don't match to the [`To`
   * header](https://tools.ietf.org/html/rfc3261#section-8.1.1.2).
   */
  set toHeader(value: string|null) {
    this.Serializable$set('toHeader', value);
  }

  getConstructor(): SerializableCtor<CallMatcher_> {
    return CallMatcher_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['callIdHeader', 'customHeaders', 'fromHeader', 'name', 'toHeader'],
      objects: {'customHeaders': CallMatcherCustomHeaders_}
    };
  }
}

export interface CancelPhoneNumberOrderRequest_Parameters {}
export class CancelPhoneNumberOrderRequest_ extends Serializable {
  constructor(parameters: CancelPhoneNumberOrderRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<CancelPhoneNumberOrderRequest_> {
    return CancelPhoneNumberOrderRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface CheckConversationModelSuggestionListRequest_Parameters {
  document?: string|null;
  knowledgeBase?: string|null;
}
export class CheckConversationModelSuggestionListRequest_ extends Serializable {
  constructor(
      parameters: CheckConversationModelSuggestionListRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'knowledgeBase',
        (parameters.knowledgeBase == null) ? (null) :
                                             (parameters.knowledgeBase));
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * Set when the suggestion list is a document. Format:
   * `projects//locations//knowledgeBases//documents/`.
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  get knowledgeBase(): string|null {
    return (
        (this.Serializable$has('knowledgeBase')) ?
            (this.Serializable$get('knowledgeBase')) :
            (null));
  }

  /**
   * Set when suggestion list is a knowledge base. Format:
   * `projects//locations//knowledgeBases/`.
   */
  set knowledgeBase(value: string|null) {
    this.Serializable$set('knowledgeBase', value);
  }

  getConstructor():
      SerializableCtor<CheckConversationModelSuggestionListRequest_> {
    return CheckConversationModelSuggestionListRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['document', 'knowledgeBase']};
  }
}

export interface ClearSuggestionFeatureConfigOperationMetadata_Parameters {
  conversationProfile?: string|null;
  participantRole?:
      ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole|null;
  suggestionFeatureType?:
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType|null;
  createTime?: string|null;
}
export class ClearSuggestionFeatureConfigOperationMetadata_ extends
    Serializable {
  constructor(
      parameters:
          ClearSuggestionFeatureConfigOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationProfile',
        (parameters.conversationProfile == null) ?
            (null) :
            (parameters.conversationProfile));
    this.Serializable$set(
        'participantRole',
        (parameters.participantRole == null) ? (null) :
                                               (parameters.participantRole));
    this.Serializable$set(
        'suggestionFeatureType',
        (parameters.suggestionFeatureType == null) ?
            (null) :
            (parameters.suggestionFeatureType));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  static get ParticipantRole():
      IClearSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum {
    return ClearSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum;
  }

  static get SuggestionFeatureType():
      IClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum {
    return ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum;
  }

  get conversationProfile(): string|null {
    return (
        (this.Serializable$has('conversationProfile')) ?
            (this.Serializable$get('conversationProfile')) :
            (null));
  }

  /**
   * The resource name of the conversation profile. Format:
   * `projects//locations//conversationProfiles/`
   */
  set conversationProfile(value: string|null) {
    this.Serializable$set('conversationProfile', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp whe the request was created. The time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get participantRole():
      ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole|null {
    return (
        (this.Serializable$has('participantRole')) ?
            (this.Serializable$get('participantRole')) :
            (null));
  }

  /**
   * Required. The participant role to remove the suggestion feature config.
   * Only HUMAN_AGENT or END_USER can be used.
   */
  set participantRole(
      value: ClearSuggestionFeatureConfigOperationMetadata_ParticipantRole|
      null) {
    this.Serializable$set('participantRole', value);
  }

  get suggestionFeatureType():
      ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType|null {
    return (
        (this.Serializable$has('suggestionFeatureType')) ?
            (this.Serializable$get('suggestionFeatureType')) :
            (null));
  }

  /**
   * Required. The type of the suggestion feature to remove.
   */
  set suggestionFeatureType(
      value:
          ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType|
      null) {
    this.Serializable$set('suggestionFeatureType', value);
  }

  getConstructor():
      SerializableCtor<ClearSuggestionFeatureConfigOperationMetadata_> {
    return ClearSuggestionFeatureConfigOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'participantRole':
            ClearSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum,
        'suggestionFeatureType':
            ClearSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
      },
      keys: [
        'conversationProfile', 'createTime', 'participantRole',
        'suggestionFeatureType'
      ]
    };
  }
}

export interface ClearSuggestionFeatureConfigRequest_Parameters {
  participantRole?: ClearSuggestionFeatureConfigRequest_ParticipantRole|null;
  suggestionFeatureType?:
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType|null;
}
export class ClearSuggestionFeatureConfigRequest_ extends Serializable {
  constructor(parameters: ClearSuggestionFeatureConfigRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'participantRole',
        (parameters.participantRole == null) ? (null) :
                                               (parameters.participantRole));
    this.Serializable$set(
        'suggestionFeatureType',
        (parameters.suggestionFeatureType == null) ?
            (null) :
            (parameters.suggestionFeatureType));
  }

  static get ParticipantRole():
      IClearSuggestionFeatureConfigRequest_ParticipantRoleEnum {
    return ClearSuggestionFeatureConfigRequest_ParticipantRoleEnum;
  }

  static get SuggestionFeatureType():
      IClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum {
    return ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum;
  }

  get participantRole(): ClearSuggestionFeatureConfigRequest_ParticipantRole
      |null {
    return (
        (this.Serializable$has('participantRole')) ?
            (this.Serializable$get('participantRole')) :
            (null));
  }

  /**
   * Required. The participant role to remove the suggestion feature config.
   * Only HUMAN_AGENT or END_USER can be used.
   */
  set participantRole(value:
                          ClearSuggestionFeatureConfigRequest_ParticipantRole|
                      null) {
    this.Serializable$set('participantRole', value);
  }

  get suggestionFeatureType():
      ClearSuggestionFeatureConfigRequest_SuggestionFeatureType|null {
    return (
        (this.Serializable$has('suggestionFeatureType')) ?
            (this.Serializable$get('suggestionFeatureType')) :
            (null));
  }

  /**
   * Required. The type of the suggestion feature to remove.
   */
  set suggestionFeatureType(
      value: ClearSuggestionFeatureConfigRequest_SuggestionFeatureType|null) {
    this.Serializable$set('suggestionFeatureType', value);
  }

  getConstructor(): SerializableCtor<ClearSuggestionFeatureConfigRequest_> {
    return ClearSuggestionFeatureConfigRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'participantRole':
            ClearSuggestionFeatureConfigRequest_ParticipantRoleEnum,
        'suggestionFeatureType':
            ClearSuggestionFeatureConfigRequest_SuggestionFeatureTypeEnum
      },
      keys: ['participantRole', 'suggestionFeatureType']
    };
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentdefinitionActionParameters {
  name?: string|null;
  if?: string|null;
  doAll?: Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction>|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentdefinitionActionParameters =
              {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'if', (parameters.if == null) ? (null) : (parameters.if));
    this.Serializable$set(
        'doAll', (parameters.doAll == null) ? (null) : (parameters.doAll));
  }

  get doAll():
      Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction>|null {
    return (
        (this.Serializable$has('doAll')) ? (this.Serializable$get('doAll')) :
                                           (null));
  }

  /**
   * Referencing a named action: An Action object with only a \"name\" field set
   * is a reference to a named Action defined elsewhere in the Bot Definition.
   * action { name: \"handle_bill_increase\" } do_all [ # This is a reference to
   * the Action defined above. action { name: \"handle_bill_increase\" } ]
   * Defining an anonymous action: An Action object without a \"name\" field is
   * an anonymous action defined inline. do_all [ action { do_all: { action {
   * name: \"handle_bill_increase\" } } } ] Referencing an utterance: Utterance
   * objects are not of type Action (obviously). However, they can be referenced
   * by name in a do_all list. utterance { name: \"acknowledge_bill_increase\"
   * content: \"I see that your bill increased last month.\" } action { name:
   * \"handle_bill_increase\" do_all { # This is a reference to the Utterance
   * defined above. action { name: \"acknowledge_bill_increase\" } # This is a
   * reference to an Action (or maybe an Utterance) defined # elsewhere.
   * action { name: \"bill_increase_steps\" } } }
   */
  set doAll(value:
                Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction>|
            null) {
    this.Serializable$set('doAll', value);
  }

  get if(): string|null {
    return (
        (this.Serializable$has('if')) ? (this.Serializable$get('if')) : (null));
  }

  /**
   * A Common Expression Language (go/cel) condition that must be true for the
   * action to execute.
   */
  set if(value: string|null) {
    this.Serializable$set('if', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * User-readable name for the action. Optional - if not specified for an
   * Action instance inside a do_all list, it is treated as an anonymous action
   * object.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction> {
    return CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'doAll': CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction},
      keys: ['doAll', 'if', 'name']
    };
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentdefinitionParameterParameters {
  name?: string|null;
  defaultPrompt?: string|null;
  type?: string|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentdefinitionParameter extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentdefinitionParameterParameters =
              {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'defaultPrompt',
        (parameters.defaultPrompt == null) ? (null) :
                                             (parameters.defaultPrompt));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
  }

  get defaultPrompt(): string|null {
    return (
        (this.Serializable$has('defaultPrompt')) ?
            (this.Serializable$get('defaultPrompt')) :
            (null));
  }

  /**
   * Used to prompt the parameter's value from the user whenever required.
   */
  set defaultPrompt(value: string|null) {
    this.Serializable$set('defaultPrompt', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * User-readable name for the parameter.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get type(): string|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Name of the ParameterType of this parameter.
   */
  set type(value: string|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentdefinitionParameter> {
    return CloudAiDialogflowProtoModelSynopsisAgentdefinitionParameter;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['defaultPrompt', 'name', 'type']};
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentdefinitionPolicyParameters {
  intent?: string|null;
  resolutionAction?: CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction|
      null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentdefinitionPolicy extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentdefinitionPolicyParameters =
              {}) {
    super();
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'resolutionAction',
        (parameters.resolutionAction == null) ? (null) :
                                                (parameters.resolutionAction));
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * Name of the intent to handle.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  get resolutionAction():
      CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction|null {
    return (
        (this.Serializable$has('resolutionAction')) ?
            (this.Serializable$get('resolutionAction')) :
            (null));
  }

  /**
   * Action that can handle this intent.
   */
  set resolutionAction(
      value: CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction|null) {
    this.Serializable$set('resolutionAction', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentdefinitionPolicy> {
    return CloudAiDialogflowProtoModelSynopsisAgentdefinitionPolicy;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['intent', 'resolutionAction'],
      objects: {
        'resolutionAction':
            CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction
      }
    };
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentdefinitionUtteranceParameters {
  name?: string|null;
  content?: string|null;
  expectResponse?: boolean|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentdefinitionUtterance extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentdefinitionUtteranceParameters =
              {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
    this.Serializable$set(
        'expectResponse',
        (parameters.expectResponse == null) ? (null) :
                                              (parameters.expectResponse));
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * The content of the utterance itself. Evaluated as a go/cel expression.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get expectResponse(): boolean|null {
    return (
        (this.Serializable$has('expectResponse')) ?
            (this.Serializable$get('expectResponse')) :
            (null));
  }

  /**
   * Setting to true explicitly tells the agent to wait for a response after
   * saying this.
   */
  set expectResponse(value: boolean|null) {
    this.Serializable$set('expectResponse', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * User-readable name for the utterance.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentdefinitionUtterance> {
    return CloudAiDialogflowProtoModelSynopsisAgentdefinitionUtterance;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['content', 'expectResponse', 'name']};
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentdefinitionWebhookParameters {
  displayName?: string|null;
  timeout?: number|null;
  uri?: string|null;
  tag?: string|null;
  latencyMaskingPhrase?: string|null;
  requiredInputParameters?: Array<string>|null;
  expectedOutputParameters?: Array<string>|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentdefinitionWebhook extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentdefinitionWebhookParameters =
              {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'timeout',
        (parameters.timeout == null) ? (null) : (parameters.timeout));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'tag', (parameters.tag == null) ? (null) : (parameters.tag));
    this.Serializable$set(
        'latencyMaskingPhrase',
        (parameters.latencyMaskingPhrase == null) ?
            (null) :
            (parameters.latencyMaskingPhrase));
    this.Serializable$set(
        'requiredInputParameters',
        (parameters.requiredInputParameters == null) ?
            (null) :
            (parameters.requiredInputParameters));
    this.Serializable$set(
        'expectedOutputParameters',
        (parameters.expectedOutputParameters == null) ?
            (null) :
            (parameters.expectedOutputParameters));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Human-readable name for the webhook.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get expectedOutputParameters(): Array<string>|null {
    return (
        (this.Serializable$has('expectedOutputParameters')) ?
            (this.Serializable$get('expectedOutputParameters')) :
            (null));
  }

  /**
   * Names of parameters that the webhook returns.
   */
  set expectedOutputParameters(value: Array<string>|null) {
    this.Serializable$set('expectedOutputParameters', value);
  }

  get latencyMaskingPhrase(): string|null {
    return (
        (this.Serializable$has('latencyMaskingPhrase')) ?
            (this.Serializable$get('latencyMaskingPhrase')) :
            (null));
  }

  /**
   * What to say to the user when executing the webhook.
   */
  set latencyMaskingPhrase(value: string|null) {
    this.Serializable$set('latencyMaskingPhrase', value);
  }

  get requiredInputParameters(): Array<string>|null {
    return (
        (this.Serializable$has('requiredInputParameters')) ?
            (this.Serializable$get('requiredInputParameters')) :
            (null));
  }

  /**
   * Names of parameters that the webhook call requires.
   */
  set requiredInputParameters(value: Array<string>|null) {
    this.Serializable$set('requiredInputParameters', value);
  }

  get tag(): string|null {
    return (
        (this.Serializable$has('tag')) ? (this.Serializable$get('tag')) :
                                         (null));
  }

  /**
   * Tag for the webhook. Currently used in CX.
   */
  set tag(value: string|null) {
    this.Serializable$set('tag', value);
  }

  get timeout(): number|null {
    return (
        (this.Serializable$has('timeout')) ?
            (this.Serializable$get('timeout')) :
            (null));
  }

  /**
   * Timeout for the webhook call.
   */
  set timeout(value: number|null) {
    this.Serializable$set('timeout', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * URI to call the wehook.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentdefinitionWebhook> {
    return CloudAiDialogflowProtoModelSynopsisAgentdefinitionWebhook;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'displayName', 'expectedOutputParameters', 'latencyMaskingPhrase',
        'requiredInputParameters', 'tag', 'timeout', 'uri'
      ]
    };
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaActionParameters {
  displayName?: string|null;
  utterance?: CloudAiDialogflowProtoModelSynopsisAgentschemaUtterance|null;
  webhook?: CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook|null;
  inputParameters?:
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameter>|null;
  expectedOutputParameters?:
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameter>|null;
  inputPrecondition?: string|null;
  stateUpdates?:
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaStateUpdate>|null;
  explicitlyExpectsResponse?: boolean|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentschemaAction extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaActionParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'utterance',
        (parameters.utterance == null) ? (null) : (parameters.utterance));
    this.Serializable$set(
        'webhook',
        (parameters.webhook == null) ? (null) : (parameters.webhook));
    this.Serializable$set(
        'inputParameters',
        (parameters.inputParameters == null) ? (null) :
                                               (parameters.inputParameters));
    this.Serializable$set(
        'expectedOutputParameters',
        (parameters.expectedOutputParameters == null) ?
            (null) :
            (parameters.expectedOutputParameters));
    this.Serializable$set(
        'inputPrecondition',
        (parameters.inputPrecondition == null) ?
            (null) :
            (parameters.inputPrecondition));
    this.Serializable$set(
        'stateUpdates',
        (parameters.stateUpdates == null) ? (null) : (parameters.stateUpdates));
    this.Serializable$set(
        'explicitlyExpectsResponse',
        (parameters.explicitlyExpectsResponse == null) ?
            (null) :
            (parameters.explicitlyExpectsResponse));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Human readable name for the action.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get expectedOutputParameters():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameter>|null {
    return (
        (this.Serializable$has('expectedOutputParameters')) ?
            (this.Serializable$get('expectedOutputParameters')) :
            (null));
  }

  /**
   * List of parameters expected to be output by the action. Includes parameters
   * collected by prompting the user, calling webhooks, or from state_updates.
   */
  set expectedOutputParameters(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameter>|
      null) {
    this.Serializable$set('expectedOutputParameters', value);
  }

  get explicitlyExpectsResponse(): boolean|null {
    return (
        (this.Serializable$has('explicitlyExpectsResponse')) ?
            (this.Serializable$get('explicitlyExpectsResponse')) :
            (null));
  }

  /**
   * The DM infers whether an utterance expects a response or not. Set this true
   * to force the DM to wait.
   */
  set explicitlyExpectsResponse(value: boolean|null) {
    this.Serializable$set('explicitlyExpectsResponse', value);
  }

  get inputParameters():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameter>|null {
    return (
        (this.Serializable$has('inputParameters')) ?
            (this.Serializable$get('inputParameters')) :
            (null));
  }

  /**
   * List of parameters used in the action. Includes parameters used in
   * webhooks, and referenced in utterances.
   */
  set inputParameters(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameter>|
      null) {
    this.Serializable$set('inputParameters', value);
  }

  get inputPrecondition(): string|null {
    return (
        (this.Serializable$has('inputPrecondition')) ?
            (this.Serializable$get('inputPrecondition')) :
            (null));
  }

  /**
   * Python-formatted expression that must evaluate to true for the action to
   * run.
   */
  set inputPrecondition(value: string|null) {
    this.Serializable$set('inputPrecondition', value);
  }

  get stateUpdates():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaStateUpdate>|null {
    return (
        (this.Serializable$has('stateUpdates')) ?
            (this.Serializable$get('stateUpdates')) :
            (null));
  }

  /**
   * List of parameter values to be updated after the action completes.
   */
  set stateUpdates(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaStateUpdate>|
      null) {
    this.Serializable$set('stateUpdates', value);
  }

  get utterance(): CloudAiDialogflowProtoModelSynopsisAgentschemaUtterance
      |null {
    return (
        (this.Serializable$has('utterance')) ?
            (this.Serializable$get('utterance')) :
            (null));
  }

  /**
   * An utterance the bot says in the action.
   */
  set utterance(value: CloudAiDialogflowProtoModelSynopsisAgentschemaUtterance|
                null) {
    this.Serializable$set('utterance', value);
  }

  get webhook(): CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook|null {
    return (
        (this.Serializable$has('webhook')) ?
            (this.Serializable$get('webhook')) :
            (null));
  }

  /**
   * A webhook the bot calls as part of the action.
   */
  set webhook(value: CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook|
              null) {
    this.Serializable$set('webhook', value);
  }

  getConstructor():
      SerializableCtor<CloudAiDialogflowProtoModelSynopsisAgentschemaAction> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'expectedOutputParameters':
            CloudAiDialogflowProtoModelSynopsisAgentschemaParameter,
        'inputParameters':
            CloudAiDialogflowProtoModelSynopsisAgentschemaParameter,
        'stateUpdates':
            CloudAiDialogflowProtoModelSynopsisAgentschemaStateUpdate
      },
      keys: [
        'displayName', 'expectedOutputParameters', 'explicitlyExpectsResponse',
        'inputParameters', 'inputPrecondition', 'stateUpdates', 'utterance',
        'webhook'
      ],
      objects: {
        'utterance': CloudAiDialogflowProtoModelSynopsisAgentschemaUtterance,
        'webhook': CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook
      }
    };
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaExecutionLogicParameters {
  webhookName?: string|null;
}
export class
    CloudAiDialogflowProtoModelSynopsisAgentschemaExecutionLogic extends
        Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaExecutionLogicParameters =
              {}) {
    super();
    this.Serializable$set(
        'webhookName',
        (parameters.webhookName == null) ? (null) : (parameters.webhookName));
  }

  get webhookName(): string|null {
    return (
        (this.Serializable$has('webhookName')) ?
            (this.Serializable$get('webhookName')) :
            (null));
  }

  /**
   * Name of the webhook schema object
   */
  set webhookName(value: string|null) {
    this.Serializable$set('webhookName', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentschemaExecutionLogic> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaExecutionLogic;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['webhookName']};
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaIntentParameters {
  displayName?: string|null;
  trainingPhrases?: Array<string>|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentschemaIntent extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaIntentParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'trainingPhrases',
        (parameters.trainingPhrases == null) ? (null) :
                                               (parameters.trainingPhrases));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Human-readable name for the intent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get trainingPhrases(): Array<string>|null {
    return (
        (this.Serializable$has('trainingPhrases')) ?
            (this.Serializable$get('trainingPhrases')) :
            (null));
  }

  /**
   * List of training phrases for the intent.
   */
  set trainingPhrases(value: Array<string>|null) {
    this.Serializable$set('trainingPhrases', value);
  }

  getConstructor():
      SerializableCtor<CloudAiDialogflowProtoModelSynopsisAgentschemaIntent> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaIntent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'trainingPhrases']};
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaParameterParameters {
  displayName?: string|null;
  parameterType?: CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType|
      null;
  value?: any|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentschemaParameter extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaParameterParameters =
              {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'parameterType',
        (parameters.parameterType == null) ? (null) :
                                             (parameters.parameterType));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Human-readable name for the parameter.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get parameterType():
      CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType|null {
    return (
        (this.Serializable$has('parameterType')) ?
            (this.Serializable$get('parameterType')) :
            (null));
  }

  /**
   * Data type of the parameter.
   */
  set parameterType(
      value: CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType|null) {
    this.Serializable$set('parameterType', value);
  }

  get value(): any|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Default value of the parameter.
   */
  set value(value: any|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentschemaParameter> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaParameter;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['displayName', 'parameterType', 'value'],
      objects: {
        'parameterType':
            CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType
      }
    };
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTrainingExampleParameters {
  value?: any|null;
  trainingPhrases?: Array<string>|null;
}
export class
    CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTrainingExample extends
        Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTrainingExampleParameters =
              {}) {
    super();
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
    this.Serializable$set(
        'trainingPhrases',
        (parameters.trainingPhrases == null) ? (null) :
                                               (parameters.trainingPhrases));
  }

  get trainingPhrases(): Array<string>|null {
    return (
        (this.Serializable$has('trainingPhrases')) ?
            (this.Serializable$get('trainingPhrases')) :
            (null));
  }

  /**
   * Synonyms for the value.
   */
  set trainingPhrases(value: Array<string>|null) {
    this.Serializable$set('trainingPhrases', value);
  }

  get value(): any|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Value that training_phrases correspond to.
   */
  set value(value: any|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTrainingExample> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTrainingExample;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['trainingPhrases', 'value']};
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTypeParameters {
  displayName?: string|null;
  trainingData?: Array<
      CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTrainingExample>|
      null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTypeParameters =
              {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'trainingData',
        (parameters.trainingData == null) ? (null) : (parameters.trainingData));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Human-readable name for the parameter type.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get trainingData(): Array<
      CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTrainingExample>|
      null {
    return (
        (this.Serializable$has('trainingData')) ?
            (this.Serializable$get('trainingData')) :
            (null));
  }

  /**
   * Training data for the parameter.
   */
  set trainingData(
      value: Array<
          CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTrainingExample>|
      null) {
    this.Serializable$set('trainingData', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'trainingData':
            CloudAiDialogflowProtoModelSynopsisAgentschemaParameterTrainingExample
      },
      keys: ['displayName', 'trainingData']
    };
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaPolicyParameters {
  displayName?: string|null;
  intent?: CloudAiDialogflowProtoModelSynopsisAgentschemaIntent|null;
  resolutionActions?:
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaAction>|null;
  executionLogic?: CloudAiDialogflowProtoModelSynopsisAgentschemaExecutionLogic|
      null;
  inputParameterNames?: Array<string>|null;
  possibleTargetActionNames?: Array<string>|null;
  possibleTargetPolicyNames?: Array<string>|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentschemaPolicy extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaPolicyParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'resolutionActions',
        (parameters.resolutionActions == null) ?
            (null) :
            (parameters.resolutionActions));
    this.Serializable$set(
        'executionLogic',
        (parameters.executionLogic == null) ? (null) :
                                              (parameters.executionLogic));
    this.Serializable$set(
        'inputParameterNames',
        (parameters.inputParameterNames == null) ?
            (null) :
            (parameters.inputParameterNames));
    this.Serializable$set(
        'possibleTargetActionNames',
        (parameters.possibleTargetActionNames == null) ?
            (null) :
            (parameters.possibleTargetActionNames));
    this.Serializable$set(
        'possibleTargetPolicyNames',
        (parameters.possibleTargetPolicyNames == null) ?
            (null) :
            (parameters.possibleTargetPolicyNames));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Human-readable name for the policy.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get executionLogic():
      CloudAiDialogflowProtoModelSynopsisAgentschemaExecutionLogic|null {
    return (
        (this.Serializable$has('executionLogic')) ?
            (this.Serializable$get('executionLogic')) :
            (null));
  }

  /**
   * Defines the logic that implements the policy. See go/sip-006
   */
  set executionLogic(
      value: CloudAiDialogflowProtoModelSynopsisAgentschemaExecutionLogic|
      null) {
    this.Serializable$set('executionLogic', value);
  }

  get inputParameterNames(): Array<string>|null {
    return (
        (this.Serializable$has('inputParameterNames')) ?
            (this.Serializable$get('inputParameterNames')) :
            (null));
  }

  /**
   * Input parameters that the policy depends on to make decisions. Parameter
   * values reflect the state at the time the policy is evaluated. The DM will
   * not automatically schedule actions to resolve these parameters (that's the
   * policy's responsibility).
   */
  set inputParameterNames(value: Array<string>|null) {
    this.Serializable$set('inputParameterNames', value);
  }

  get intent(): CloudAiDialogflowProtoModelSynopsisAgentschemaIntent|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * Intent that the policy's resolution actions are meant to solve.
   */
  set intent(value: CloudAiDialogflowProtoModelSynopsisAgentschemaIntent|null) {
    this.Serializable$set('intent', value);
  }

  get possibleTargetActionNames(): Array<string>|null {
    return (
        (this.Serializable$has('possibleTargetActionNames')) ?
            (this.Serializable$get('possibleTargetActionNames')) :
            (null));
  }

  /**
   * Possible target Actions the policy will return
   */
  set possibleTargetActionNames(value: Array<string>|null) {
    this.Serializable$set('possibleTargetActionNames', value);
  }

  get possibleTargetPolicyNames(): Array<string>|null {
    return (
        (this.Serializable$has('possibleTargetPolicyNames')) ?
            (this.Serializable$get('possibleTargetPolicyNames')) :
            (null));
  }

  /**
   * Possible target Policies the policy will return
   */
  set possibleTargetPolicyNames(value: Array<string>|null) {
    this.Serializable$set('possibleTargetPolicyNames', value);
  }

  get resolutionActions():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaAction>|null {
    return (
        (this.Serializable$has('resolutionActions')) ?
            (this.Serializable$get('resolutionActions')) :
            (null));
  }

  /**
   * Deprecated due to go/sip-006. Use execution_logic instead.
   */
  set resolutionActions(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaAction>|null) {
    this.Serializable$set('resolutionActions', value);
  }

  getConstructor():
      SerializableCtor<CloudAiDialogflowProtoModelSynopsisAgentschemaPolicy> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaPolicy;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'resolutionActions':
            CloudAiDialogflowProtoModelSynopsisAgentschemaAction
      },
      keys: [
        'displayName', 'executionLogic', 'inputParameterNames', 'intent',
        'possibleTargetActionNames', 'possibleTargetPolicyNames',
        'resolutionActions'
      ],
      objects: {
        'executionLogic':
            CloudAiDialogflowProtoModelSynopsisAgentschemaExecutionLogic,
        'intent': CloudAiDialogflowProtoModelSynopsisAgentschemaIntent
      }
    };
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaSchemaParameters {
  actions?: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaAction>|null;
  policies?: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaPolicy>|null;
  parameters?: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameter>|
      null;
  webhooks?: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook>|null;
  intents?: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaIntent>|null;
  parameterTypes?:
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType>|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentschemaSchema extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaSchemaParameters = {}) {
    super();
    this.Serializable$set(
        'actions',
        (parameters.actions == null) ? (null) : (parameters.actions));
    this.Serializable$set(
        'policies',
        (parameters.policies == null) ? (null) : (parameters.policies));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'webhooks',
        (parameters.webhooks == null) ? (null) : (parameters.webhooks));
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
    this.Serializable$set(
        'parameterTypes',
        (parameters.parameterTypes == null) ? (null) :
                                              (parameters.parameterTypes));
  }

  get actions():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaAction>|null {
    return (
        (this.Serializable$has('actions')) ?
            (this.Serializable$get('actions')) :
            (null));
  }

  /**
   * List of all actions in the schema.
   */
  set actions(value:
                  Array<CloudAiDialogflowProtoModelSynopsisAgentschemaAction>|
              null) {
    this.Serializable$set('actions', value);
  }

  get intents():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaIntent>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * List of all intents in the schema.
   */
  set intents(value:
                  Array<CloudAiDialogflowProtoModelSynopsisAgentschemaIntent>|
              null) {
    this.Serializable$set('intents', value);
  }

  get parameterTypes():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType>|null {
    return (
        (this.Serializable$has('parameterTypes')) ?
            (this.Serializable$get('parameterTypes')) :
            (null));
  }

  /**
   * List of all parameter types in the schema.
   */
  set parameterTypes(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType>|
      null) {
    this.Serializable$set('parameterTypes', value);
  }

  get parameters():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * List of all parameters in the schema.
   */
  set parameters(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameter>|
      null) {
    this.Serializable$set('parameters', value);
  }

  get policies():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaPolicy>|null {
    return (
        (this.Serializable$has('policies')) ?
            (this.Serializable$get('policies')) :
            (null));
  }

  /**
   * List of all policies in the schema.
   */
  set policies(value:
                   Array<CloudAiDialogflowProtoModelSynopsisAgentschemaPolicy>|
               null) {
    this.Serializable$set('policies', value);
  }

  get webhooks():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook>|null {
    return (
        (this.Serializable$has('webhooks')) ?
            (this.Serializable$get('webhooks')) :
            (null));
  }

  /**
   * List of all webhooks in the schema.
   */
  set webhooks(value:
                   Array<CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook>|
               null) {
    this.Serializable$set('webhooks', value);
  }

  getConstructor():
      SerializableCtor<CloudAiDialogflowProtoModelSynopsisAgentschemaSchema> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaSchema;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'actions': CloudAiDialogflowProtoModelSynopsisAgentschemaAction,
        'intents': CloudAiDialogflowProtoModelSynopsisAgentschemaIntent,
        'parameterTypes':
            CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType,
        'parameters': CloudAiDialogflowProtoModelSynopsisAgentschemaParameter,
        'policies': CloudAiDialogflowProtoModelSynopsisAgentschemaPolicy,
        'webhooks': CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook
      },
      keys: [
        'actions', 'intents', 'parameterTypes', 'parameters', 'policies',
        'webhooks'
      ]
    };
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaStateUpdateParameters {
  parameter?: CloudAiDialogflowProtoModelSynopsisAgentschemaParameter|null;
  value?: any|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentschemaStateUpdate extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaStateUpdateParameters =
              {}) {
    super();
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
  }

  get parameter(): CloudAiDialogflowProtoModelSynopsisAgentschemaParameter
      |null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * Parameter to update.
   */
  set parameter(value: CloudAiDialogflowProtoModelSynopsisAgentschemaParameter|
                null) {
    this.Serializable$set('parameter', value);
  }

  get value(): any|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * New value for the parameter.
   */
  set value(value: any|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentschemaStateUpdate> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaStateUpdate;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['parameter', 'value'],
      objects:
          {'parameter': CloudAiDialogflowProtoModelSynopsisAgentschemaParameter}
    };
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaUtteranceParameters {
  content?: string|null;
  timeout?: number|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentschemaUtterance extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaUtteranceParameters =
              {}) {
    super();
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
    this.Serializable$set(
        'timeout',
        (parameters.timeout == null) ? (null) : (parameters.timeout));
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Human-readable string to output to the user.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get timeout(): number|null {
    return (
        (this.Serializable$has('timeout')) ?
            (this.Serializable$get('timeout')) :
            (null));
  }

  /**
   * Time to wait for user input.
   */
  set timeout(value: number|null) {
    this.Serializable$set('timeout', value);
  }

  getConstructor(): SerializableCtor<
      CloudAiDialogflowProtoModelSynopsisAgentschemaUtterance> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaUtterance;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['content', 'timeout']};
  }
}

export interface CloudAiDialogflowProtoModelSynopsisAgentschemaWebhookParameters {
  displayName?: string|null;
  timeout?: number|null;
  uri?: string|null;
  tag?: string|null;
}
export class CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook extends
    Serializable {
  constructor(
      parameters:
          CloudAiDialogflowProtoModelSynopsisAgentschemaWebhookParameters =
              {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'timeout',
        (parameters.timeout == null) ? (null) : (parameters.timeout));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'tag', (parameters.tag == null) ? (null) : (parameters.tag));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Human-readable name for the webhook.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get tag(): string|null {
    return (
        (this.Serializable$has('tag')) ? (this.Serializable$get('tag')) :
                                         (null));
  }

  /**
   * Tag for the webhook. Currently used in CX.
   */
  set tag(value: string|null) {
    this.Serializable$set('tag', value);
  }

  get timeout(): number|null {
    return (
        (this.Serializable$has('timeout')) ?
            (this.Serializable$get('timeout')) :
            (null));
  }

  /**
   * Timeout for the webhook call.
   */
  set timeout(value: number|null) {
    this.Serializable$set('timeout', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * URI to call the wehook.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor():
      SerializableCtor<CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook> {
    return CloudAiDialogflowProtoModelSynopsisAgentschemaWebhook;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'tag', 'timeout', 'uri']};
  }
}

export interface CloudConversationHourlyAgentAnalyticsParameters {
  key?: CloudConversationHourlyAgentAnalyticsKey|null;
  value?: CloudConversationHourlyAgentAnalyticsValue|null;
}
export class CloudConversationHourlyAgentAnalytics extends Serializable {
  constructor(
      parameters: CloudConversationHourlyAgentAnalyticsParameters = {}) {
    super();
    this.Serializable$set(
        'key', (parameters.key == null) ? (null) : (parameters.key));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
  }

  get key(): CloudConversationHourlyAgentAnalyticsKey|null {
    return (
        (this.Serializable$has('key')) ? (this.Serializable$get('key')) :
                                         (null));
  }

  /**
   * LINT.ThenChange(//depot/google3/cloud/ml/api/conversation/schema/analytics.sdl:key)
   * Key for this analytics entry.
   */
  set key(value: CloudConversationHourlyAgentAnalyticsKey|null) {
    this.Serializable$set('key', value);
  }

  get value(): CloudConversationHourlyAgentAnalyticsValue|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Value for this analytics entry.
   */
  set value(value: CloudConversationHourlyAgentAnalyticsValue|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<CloudConversationHourlyAgentAnalytics> {
    return CloudConversationHourlyAgentAnalytics;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['key', 'value'],
      objects: {
        'key': CloudConversationHourlyAgentAnalyticsKey,
        'value': CloudConversationHourlyAgentAnalyticsValue
      }
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsKeyParameters {
  agentProjectNumber?: string|null;
  agentSubprojectId?: string|null;
  agentRegion?: string|null;
  agentVersionId?: string|null;
  hour?: string|null;
}
export class CloudConversationHourlyAgentAnalyticsKey extends Serializable {
  constructor(
      parameters: CloudConversationHourlyAgentAnalyticsKeyParameters = {}) {
    super();
    this.Serializable$set(
        'agentProjectNumber',
        (parameters.agentProjectNumber == null) ?
            (null) :
            (parameters.agentProjectNumber));
    this.Serializable$set(
        'agentSubprojectId',
        (parameters.agentSubprojectId == null) ?
            (null) :
            (parameters.agentSubprojectId));
    this.Serializable$set(
        'agentRegion',
        (parameters.agentRegion == null) ? (null) : (parameters.agentRegion));
    this.Serializable$set(
        'agentVersionId',
        (parameters.agentVersionId == null) ? (null) :
                                              (parameters.agentVersionId));
    this.Serializable$set(
        'hour', (parameters.hour == null) ? (null) : (parameters.hour));
  }

  get agentProjectNumber(): string|null {
    return (
        (this.Serializable$has('agentProjectNumber')) ?
            (this.Serializable$get('agentProjectNumber')) :
            (null));
  }

  /**
   * Project number of the called agent (not the consumer).
   */
  set agentProjectNumber(value: string|null) {
    this.Serializable$set('agentProjectNumber', value);
  }

  get agentRegion(): string|null {
    return (
        (this.Serializable$has('agentRegion')) ?
            (this.Serializable$get('agentRegion')) :
            (null));
  }

  /**
   * Region of the interactions, e.g. 'europe-west1' or '' for the default
   * ('us') region.
   */
  set agentRegion(value: string|null) {
    this.Serializable$set('agentRegion', value);
  }

  get agentSubprojectId(): string|null {
    return (
        (this.Serializable$has('agentSubprojectId')) ?
            (this.Serializable$get('agentSubprojectId')) :
            (null));
  }

  /**
   * For V2, this field is always empty. For V3, this is used as agent_id
   * although it is named as agent_subproject_id. Used to identify the agent
   * under the same project.
   */
  set agentSubprojectId(value: string|null) {
    this.Serializable$set('agentSubprojectId', value);
  }

  get agentVersionId(): string|null {
    return (
        (this.Serializable$has('agentVersionId')) ?
            (this.Serializable$get('agentVersionId')) :
            (null));
  }

  /**
   * In V2, version id of the called agent. Draft has version 0 and -1 is a
   * special \"version\" used for stats accumulated over all versions. In V3,
   * not being used and always -1.
   */
  set agentVersionId(value: string|null) {
    this.Serializable$set('agentVersionId', value);
  }

  get hour(): string|null {
    return (
        (this.Serializable$has('hour')) ? (this.Serializable$get('hour')) :
                                          (null));
  }

  /**
   * Hour of this analytics result.
   */
  set hour(value: string|null) {
    this.Serializable$set('hour', value);
  }

  getConstructor(): SerializableCtor<CloudConversationHourlyAgentAnalyticsKey> {
    return CloudConversationHourlyAgentAnalyticsKey;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'agentProjectNumber', 'agentRegion', 'agentSubprojectId',
        'agentVersionId', 'hour'
      ]
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueParameters {
  interactionsByStatusCode?:
      Array<CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCode>|
      null;
  average?: CloudConversationHourlyAgentAnalyticsValueAverages|null;
  success?: CloudConversationHourlyAgentAnalyticsValueCounts|null;
  successByLanguage?:
      Array<CloudConversationHourlyAgentAnalyticsValueByLanguage>|null;
  successByIntegration?:
      Array<CloudConversationHourlyAgentAnalyticsValueByIntegration>|null;
  successBySpeech?: Array<CloudConversationHourlyAgentAnalyticsValueBySpeech>|
      null;
  successByKnowledge?:
      Array<CloudConversationHourlyAgentAnalyticsValueByKnowledge>|null;
  successBySentiment?:
      Array<CloudConversationHourlyAgentAnalyticsValueBySentiment>|null;
  successByWebhook?: Array<CloudConversationHourlyAgentAnalyticsValueByWebhook>|
      null;
  successByIntent?: Array<CloudConversationHourlyAgentAnalyticsValueByIntent>|
      null;
  statsByPage?: Array<CloudConversationHourlyAgentAnalyticsValueByPage>|null;
  statsByFlow?: Array<CloudConversationHourlyAgentAnalyticsValueByFlow>|null;
  sessionPathsRoot?: CloudConversationHourlyAgentAnalyticsValueSessionPathNode|
      null;
  skippedSessionPaths?: string|null;
  pageSessionPathsRoot?:
      CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode|null;
  skippedPageSessionPaths?: string|null;
}
export class CloudConversationHourlyAgentAnalyticsValue extends Serializable {
  constructor(
      parameters: CloudConversationHourlyAgentAnalyticsValueParameters = {}) {
    super();
    this.Serializable$set(
        'interactionsByStatusCode',
        (parameters.interactionsByStatusCode == null) ?
            (null) :
            (parameters.interactionsByStatusCode));
    this.Serializable$set(
        'average',
        (parameters.average == null) ? (null) : (parameters.average));
    this.Serializable$set(
        'success',
        (parameters.success == null) ? (null) : (parameters.success));
    this.Serializable$set(
        'successByLanguage',
        (parameters.successByLanguage == null) ?
            (null) :
            (parameters.successByLanguage));
    this.Serializable$set(
        'successByIntegration',
        (parameters.successByIntegration == null) ?
            (null) :
            (parameters.successByIntegration));
    this.Serializable$set(
        'successBySpeech',
        (parameters.successBySpeech == null) ? (null) :
                                               (parameters.successBySpeech));
    this.Serializable$set(
        'successByKnowledge',
        (parameters.successByKnowledge == null) ?
            (null) :
            (parameters.successByKnowledge));
    this.Serializable$set(
        'successBySentiment',
        (parameters.successBySentiment == null) ?
            (null) :
            (parameters.successBySentiment));
    this.Serializable$set(
        'successByWebhook',
        (parameters.successByWebhook == null) ? (null) :
                                                (parameters.successByWebhook));
    this.Serializable$set(
        'successByIntent',
        (parameters.successByIntent == null) ? (null) :
                                               (parameters.successByIntent));
    this.Serializable$set(
        'statsByPage',
        (parameters.statsByPage == null) ? (null) : (parameters.statsByPage));
    this.Serializable$set(
        'statsByFlow',
        (parameters.statsByFlow == null) ? (null) : (parameters.statsByFlow));
    this.Serializable$set(
        'sessionPathsRoot',
        (parameters.sessionPathsRoot == null) ? (null) :
                                                (parameters.sessionPathsRoot));
    this.Serializable$set(
        'skippedSessionPaths',
        (parameters.skippedSessionPaths == null) ?
            (null) :
            (parameters.skippedSessionPaths));
    this.Serializable$set(
        'pageSessionPathsRoot',
        (parameters.pageSessionPathsRoot == null) ?
            (null) :
            (parameters.pageSessionPathsRoot));
    this.Serializable$set(
        'skippedPageSessionPaths',
        (parameters.skippedPageSessionPaths == null) ?
            (null) :
            (parameters.skippedPageSessionPaths));
  }

  get average(): CloudConversationHourlyAgentAnalyticsValueAverages|null {
    return (
        (this.Serializable$has('average')) ?
            (this.Serializable$get('average')) :
            (null));
  }

  /**
   * For V2, this field is always empty. For V3, the averaged stats for a
   * particular hour.
   */
  set average(value: CloudConversationHourlyAgentAnalyticsValueAverages|null) {
    this.Serializable$set('average', value);
  }

  get interactionsByStatusCode():
      Array<CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCode>|
      null {
    return (
        (this.Serializable$has('interactionsByStatusCode')) ?
            (this.Serializable$get('interactionsByStatusCode')) :
            (null));
  }

  /**
   * Counts of interactions by their V2 status code. This should be consistent
   * with the \"Consumed API\" metrics in Stackdriver.
   */
  set interactionsByStatusCode(
      value: Array<
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCode>|
      null) {
    this.Serializable$set('interactionsByStatusCode', value);
  }

  get pageSessionPathsRoot():
      CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode|null {
    return (
        (this.Serializable$has('pageSessionPathsRoot')) ?
            (this.Serializable$get('pageSessionPathsRoot')) :
            (null));
  }

  /**
   * Root of the tree of most common page paths. V3 only and always empty for
   * V2.
   */
  set pageSessionPathsRoot(
      value: CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode|
      null) {
    this.Serializable$set('pageSessionPathsRoot', value);
  }

  get sessionPathsRoot():
      CloudConversationHourlyAgentAnalyticsValueSessionPathNode|null {
    return (
        (this.Serializable$has('sessionPathsRoot')) ?
            (this.Serializable$get('sessionPathsRoot')) :
            (null));
  }

  /**
   * Root of the tree of most common session paths. V2 only and always empty for
   * V3.
   */
  set sessionPathsRoot(
      value: CloudConversationHourlyAgentAnalyticsValueSessionPathNode|null) {
    this.Serializable$set('sessionPathsRoot', value);
  }

  get skippedPageSessionPaths(): string|null {
    return (
        (this.Serializable$has('skippedPageSessionPaths')) ?
            (this.Serializable$get('skippedPageSessionPaths')) :
            (null));
  }

  /**
   * Number of skipped less common page paths. V3 only and always empty for V2.
   */
  set skippedPageSessionPaths(value: string|null) {
    this.Serializable$set('skippedPageSessionPaths', value);
  }

  get skippedSessionPaths(): string|null {
    return (
        (this.Serializable$has('skippedSessionPaths')) ?
            (this.Serializable$get('skippedSessionPaths')) :
            (null));
  }

  /**
   * Number of skipped less common intent paths. V2 only and always empty for
   * V3.
   */
  set skippedSessionPaths(value: string|null) {
    this.Serializable$set('skippedSessionPaths', value);
  }

  get statsByFlow():
      Array<CloudConversationHourlyAgentAnalyticsValueByFlow>|null {
    return (
        (this.Serializable$has('statsByFlow')) ?
            (this.Serializable$get('statsByFlow')) :
            (null));
  }

  /**
   * Stats sliced by flow. V3 only and always empty in V2.
   */
  set statsByFlow(value:
                      Array<CloudConversationHourlyAgentAnalyticsValueByFlow>|
                  null) {
    this.Serializable$set('statsByFlow', value);
  }

  get statsByPage():
      Array<CloudConversationHourlyAgentAnalyticsValueByPage>|null {
    return (
        (this.Serializable$has('statsByPage')) ?
            (this.Serializable$get('statsByPage')) :
            (null));
  }

  /**
   * Stats sliced by page. V3 only and always empty in V2.
   */
  set statsByPage(value:
                      Array<CloudConversationHourlyAgentAnalyticsValueByPage>|
                  null) {
    this.Serializable$set('statsByPage', value);
  }

  get success(): CloudConversationHourlyAgentAnalyticsValueCounts|null {
    return (
        (this.Serializable$has('success')) ?
            (this.Serializable$get('success')) :
            (null));
  }

  /**
   * Total counts for successful interactions.
   */
  set success(value: CloudConversationHourlyAgentAnalyticsValueCounts|null) {
    this.Serializable$set('success', value);
  }

  get successByIntegration():
      Array<CloudConversationHourlyAgentAnalyticsValueByIntegration>|null {
    return (
        (this.Serializable$has('successByIntegration')) ?
            (this.Serializable$get('successByIntegration')) :
            (null));
  }

  /**
   * Counts sliced by integration.
   */
  set successByIntegration(
      value: Array<CloudConversationHourlyAgentAnalyticsValueByIntegration>|
      null) {
    this.Serializable$set('successByIntegration', value);
  }

  get successByIntent():
      Array<CloudConversationHourlyAgentAnalyticsValueByIntent>|null {
    return (
        (this.Serializable$has('successByIntent')) ?
            (this.Serializable$get('successByIntent')) :
            (null));
  }

  /**
   * Counts sliced by intent.
   */
  set successByIntent(
      value: Array<CloudConversationHourlyAgentAnalyticsValueByIntent>|null) {
    this.Serializable$set('successByIntent', value);
  }

  get successByKnowledge():
      Array<CloudConversationHourlyAgentAnalyticsValueByKnowledge>|null {
    return (
        (this.Serializable$has('successByKnowledge')) ?
            (this.Serializable$get('successByKnowledge')) :
            (null));
  }

  /**
   * Counts sliced by knowledge connectors usage.
   */
  set successByKnowledge(
      value: Array<CloudConversationHourlyAgentAnalyticsValueByKnowledge>|
      null) {
    this.Serializable$set('successByKnowledge', value);
  }

  get successByLanguage():
      Array<CloudConversationHourlyAgentAnalyticsValueByLanguage>|null {
    return (
        (this.Serializable$has('successByLanguage')) ?
            (this.Serializable$get('successByLanguage')) :
            (null));
  }

  /**
   * Counts sliced by query language.
   */
  set successByLanguage(
      value: Array<CloudConversationHourlyAgentAnalyticsValueByLanguage>|null) {
    this.Serializable$set('successByLanguage', value);
  }

  get successBySentiment():
      Array<CloudConversationHourlyAgentAnalyticsValueBySentiment>|null {
    return (
        (this.Serializable$has('successBySentiment')) ?
            (this.Serializable$get('successBySentiment')) :
            (null));
  }

  /**
   * Counts sliced by sentiment usage.
   */
  set successBySentiment(
      value: Array<CloudConversationHourlyAgentAnalyticsValueBySentiment>|
      null) {
    this.Serializable$set('successBySentiment', value);
  }

  get successBySpeech():
      Array<CloudConversationHourlyAgentAnalyticsValueBySpeech>|null {
    return (
        (this.Serializable$has('successBySpeech')) ?
            (this.Serializable$get('successBySpeech')) :
            (null));
  }

  /**
   * Counts sliced by STT and TTS usage.
   */
  set successBySpeech(
      value: Array<CloudConversationHourlyAgentAnalyticsValueBySpeech>|null) {
    this.Serializable$set('successBySpeech', value);
  }

  get successByWebhook():
      Array<CloudConversationHourlyAgentAnalyticsValueByWebhook>|null {
    return (
        (this.Serializable$has('successByWebhook')) ?
            (this.Serializable$get('successByWebhook')) :
            (null));
  }

  /**
   * Counts sliced by webhook usage.
   */
  set successByWebhook(
      value: Array<CloudConversationHourlyAgentAnalyticsValueByWebhook>|null) {
    this.Serializable$set('successByWebhook', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValue> {
    return CloudConversationHourlyAgentAnalyticsValue;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'interactionsByStatusCode':
            CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCode,
        'statsByFlow': CloudConversationHourlyAgentAnalyticsValueByFlow,
        'statsByPage': CloudConversationHourlyAgentAnalyticsValueByPage,
        'successByIntegration':
            CloudConversationHourlyAgentAnalyticsValueByIntegration,
        'successByIntent': CloudConversationHourlyAgentAnalyticsValueByIntent,
        'successByKnowledge':
            CloudConversationHourlyAgentAnalyticsValueByKnowledge,
        'successByLanguage':
            CloudConversationHourlyAgentAnalyticsValueByLanguage,
        'successBySentiment':
            CloudConversationHourlyAgentAnalyticsValueBySentiment,
        'successBySpeech': CloudConversationHourlyAgentAnalyticsValueBySpeech,
        'successByWebhook': CloudConversationHourlyAgentAnalyticsValueByWebhook
      },
      keys: [
        'average', 'interactionsByStatusCode', 'pageSessionPathsRoot',
        'sessionPathsRoot', 'skippedPageSessionPaths', 'skippedSessionPaths',
        'statsByFlow', 'statsByPage', 'success', 'successByIntegration',
        'successByIntent', 'successByKnowledge', 'successByLanguage',
        'successBySentiment', 'successBySpeech', 'successByWebhook'
      ],
      objects: {
        'average': CloudConversationHourlyAgentAnalyticsValueAverages,
        'pageSessionPathsRoot':
            CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode,
        'sessionPathsRoot':
            CloudConversationHourlyAgentAnalyticsValueSessionPathNode,
        'success': CloudConversationHourlyAgentAnalyticsValueCounts
      }
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueAveragesParameters {
  audioDuration?: string|null;
}
export class CloudConversationHourlyAgentAnalyticsValueAverages extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueAveragesParameters = {}) {
    super();
    this.Serializable$set(
        'audioDuration',
        (parameters.audioDuration == null) ? (null) :
                                             (parameters.audioDuration));
  }

  get audioDuration(): string|null {
    return (
        (this.Serializable$has('audioDuration')) ?
            (this.Serializable$get('audioDuration')) :
            (null));
  }

  /**
   * Average duration of the audio sessions in an hour.
   */
  set audioDuration(value: string|null) {
    this.Serializable$set('audioDuration', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValueAverages> {
    return CloudConversationHourlyAgentAnalyticsValueAverages;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audioDuration']};
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueByFlowParameters {
  flowId?: string|null;
  counts?: CloudConversationHourlyAgentAnalyticsValueCounts|null;
}
export class CloudConversationHourlyAgentAnalyticsValueByFlow extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueByFlowParameters = {}) {
    super();
    this.Serializable$set(
        'flowId', (parameters.flowId == null) ? (null) : (parameters.flowId));
    this.Serializable$set(
        'counts', (parameters.counts == null) ? (null) : (parameters.counts));
  }

  get counts(): CloudConversationHourlyAgentAnalyticsValueCounts|null {
    return (
        (this.Serializable$has('counts')) ? (this.Serializable$get('counts')) :
                                            (null));
  }

  /**
   * Counts for interactions received when the conversation is on this flow.
   */
  set counts(value: CloudConversationHourlyAgentAnalyticsValueCounts|null) {
    this.Serializable$set('counts', value);
  }

  get flowId(): string|null {
    return (
        (this.Serializable$has('flowId')) ? (this.Serializable$get('flowId')) :
                                            (null));
  }

  /**
   * Id of the flow.
   */
  set flowId(value: string|null) {
    this.Serializable$set('flowId', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValueByFlow> {
    return CloudConversationHourlyAgentAnalyticsValueByFlow;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['counts', 'flowId'],
      objects: {'counts': CloudConversationHourlyAgentAnalyticsValueCounts}
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueByIntegrationParameters {
  integration?: string|null;
  counts?: CloudConversationHourlyAgentAnalyticsValueCounts|null;
}
export class CloudConversationHourlyAgentAnalyticsValueByIntegration extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueByIntegrationParameters =
              {}) {
    super();
    this.Serializable$set(
        'integration',
        (parameters.integration == null) ? (null) : (parameters.integration));
    this.Serializable$set(
        'counts', (parameters.counts == null) ? (null) : (parameters.counts));
  }

  get counts(): CloudConversationHourlyAgentAnalyticsValueCounts|null {
    return (
        (this.Serializable$has('counts')) ? (this.Serializable$get('counts')) :
                                            (null));
  }

  /**
   * WARNING: In this slice session counts can't be summed because one session
   * may have different integrations.
   */
  set counts(value: CloudConversationHourlyAgentAnalyticsValueCounts|null) {
    this.Serializable$set('counts', value);
  }

  get integration(): string|null {
    return (
        (this.Serializable$has('integration')) ?
            (this.Serializable$get('integration')) :
            (null));
  }

  /**
   * Integration name converted to lowercase, e.g. 'facebook'. If the call went
   * to the API directly or the integration is unknown, this is 'api'.
   */
  set integration(value: string|null) {
    this.Serializable$set('integration', value);
  }

  getConstructor(): SerializableCtor<
      CloudConversationHourlyAgentAnalyticsValueByIntegration> {
    return CloudConversationHourlyAgentAnalyticsValueByIntegration;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['counts', 'integration'],
      objects: {'counts': CloudConversationHourlyAgentAnalyticsValueCounts}
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueByIntentParameters {
  displayName?: string|null;
  isFallbackOrNoMatch?: boolean|null;
  counts?: CloudConversationHourlyAgentAnalyticsValueCounts|null;
  firstInSessionAndHour?: string|null;
  lastInSessionAndHour?: string|null;
  nextIntentDisplayNamesInSessionAndHour?:
      ApiClientObjectMap<CloudConversationHourlyAgentAnalyticsValueCounts>|null;
  intentId?: string|null;
}
export class CloudConversationHourlyAgentAnalyticsValueByIntent extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueByIntentParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'isFallbackOrNoMatch',
        (parameters.isFallbackOrNoMatch == null) ?
            (null) :
            (parameters.isFallbackOrNoMatch));
    this.Serializable$set(
        'counts', (parameters.counts == null) ? (null) : (parameters.counts));
    this.Serializable$set(
        'firstInSessionAndHour',
        (parameters.firstInSessionAndHour == null) ?
            (null) :
            (parameters.firstInSessionAndHour));
    this.Serializable$set(
        'lastInSessionAndHour',
        (parameters.lastInSessionAndHour == null) ?
            (null) :
            (parameters.lastInSessionAndHour));
    this.Serializable$set(
        'nextIntentDisplayNamesInSessionAndHour',
        (parameters.nextIntentDisplayNamesInSessionAndHour == null) ?
            (null) :
            (parameters.nextIntentDisplayNamesInSessionAndHour));
    this.Serializable$set(
        'intentId',
        (parameters.intentId == null) ? (null) : (parameters.intentId));
  }

  get counts(): CloudConversationHourlyAgentAnalyticsValueCounts|null {
    return (
        (this.Serializable$has('counts')) ? (this.Serializable$get('counts')) :
                                            (null));
  }

  /**
   * Counts for interactions matched to this intent.
   */
  set counts(value: CloudConversationHourlyAgentAnalyticsValueCounts|null) {
    this.Serializable$set('counts', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Display name of the matched intent truncated to 64 bytes. If an interaction
   * wasn't matched to any intent, this will be an empty string.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get firstInSessionAndHour(): string|null {
    return (
        (this.Serializable$has('firstInSessionAndHour')) ?
            (this.Serializable$get('firstInSessionAndHour')) :
            (null));
  }

  /**
   * Number of times the first interaction in a session and hour matched to this
   * intent.
   */
  set firstInSessionAndHour(value: string|null) {
    this.Serializable$set('firstInSessionAndHour', value);
  }

  get intentId(): string|null {
    return (
        (this.Serializable$has('intentId')) ?
            (this.Serializable$get('intentId')) :
            (null));
  }

  /**
   * Id of the matched intent. If an interaction wasn't matched to any intent,
   * this will be an empty string.
   */
  set intentId(value: string|null) {
    this.Serializable$set('intentId', value);
  }

  get isFallbackOrNoMatch(): boolean|null {
    return (
        (this.Serializable$has('isFallbackOrNoMatch')) ?
            (this.Serializable$get('isFallbackOrNoMatch')) :
            (null));
  }

  /**
   * Whether the given \"intent\" is a fallback intent or \"no match\" intent
   * (display_name empty) at least once in the given hour.
   */
  set isFallbackOrNoMatch(value: boolean|null) {
    this.Serializable$set('isFallbackOrNoMatch', value);
  }

  get lastInSessionAndHour(): string|null {
    return (
        (this.Serializable$has('lastInSessionAndHour')) ?
            (this.Serializable$get('lastInSessionAndHour')) :
            (null));
  }

  /**
   * Number of times the last interaction in a session and hour matched to this
   * intent.
   */
  set lastInSessionAndHour(value: string|null) {
    this.Serializable$set('lastInSessionAndHour', value);
  }

  get nextIntentDisplayNamesInSessionAndHour():
      ApiClientObjectMap<CloudConversationHourlyAgentAnalyticsValueCounts>|
      null {
    return (
        (this.Serializable$has('nextIntentDisplayNamesInSessionAndHour')) ?
            (this.Serializable$get('nextIntentDisplayNamesInSessionAndHour')) :
            (null));
  }

  /**
   * Counts for the intents that follow this intent in a session and hour by
   * their display names.
   */
  set nextIntentDisplayNamesInSessionAndHour(
      value:
          ApiClientObjectMap<CloudConversationHourlyAgentAnalyticsValueCounts>|
      null) {
    this.Serializable$set('nextIntentDisplayNamesInSessionAndHour', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValueByIntent> {
    return CloudConversationHourlyAgentAnalyticsValueByIntent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'counts', 'displayName', 'firstInSessionAndHour', 'intentId',
        'isFallbackOrNoMatch', 'lastInSessionAndHour',
        'nextIntentDisplayNamesInSessionAndHour'
      ],
      objectMaps: {
        'nextIntentDisplayNamesInSessionAndHour': {
          ctor: CloudConversationHourlyAgentAnalyticsValueCounts,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      },
      objects: {'counts': CloudConversationHourlyAgentAnalyticsValueCounts}
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueByKnowledgeParameters {
  used?: boolean|null;
  hasResult?: boolean|null;
  isPrimaryResult?: boolean|null;
  counts?: CloudConversationHourlyAgentAnalyticsValueCounts|null;
}
export class CloudConversationHourlyAgentAnalyticsValueByKnowledge extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueByKnowledgeParameters =
              {}) {
    super();
    this.Serializable$set(
        'used', (parameters.used == null) ? (null) : (parameters.used));
    this.Serializable$set(
        'hasResult',
        (parameters.hasResult == null) ? (null) : (parameters.hasResult));
    this.Serializable$set(
        'isPrimaryResult',
        (parameters.isPrimaryResult == null) ? (null) :
                                               (parameters.isPrimaryResult));
    this.Serializable$set(
        'counts', (parameters.counts == null) ? (null) : (parameters.counts));
  }

  get counts(): CloudConversationHourlyAgentAnalyticsValueCounts|null {
    return (
        (this.Serializable$has('counts')) ? (this.Serializable$get('counts')) :
                                            (null));
  }

  /**
   * Counts.
   */
  set counts(value: CloudConversationHourlyAgentAnalyticsValueCounts|null) {
    this.Serializable$set('counts', value);
  }

  get hasResult(): boolean|null {
    return (
        (this.Serializable$has('hasResult')) ?
            (this.Serializable$get('hasResult')) :
            (null));
  }

  /**
   * Did knowledge connectors return a result?
   */
  set hasResult(value: boolean|null) {
    this.Serializable$set('hasResult', value);
  }

  get isPrimaryResult(): boolean|null {
    return (
        (this.Serializable$has('isPrimaryResult')) ?
            (this.Serializable$get('isPrimaryResult')) :
            (null));
  }

  /**
   * Were the knowledge connector results returned as primary results to the
   * user?
   */
  set isPrimaryResult(value: boolean|null) {
    this.Serializable$set('isPrimaryResult', value);
  }

  get used(): boolean|null {
    return (
        (this.Serializable$has('used')) ? (this.Serializable$get('used')) :
                                          (null));
  }

  /**
   * Were knowledge connectors used?
   */
  set used(value: boolean|null) {
    this.Serializable$set('used', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValueByKnowledge> {
    return CloudConversationHourlyAgentAnalyticsValueByKnowledge;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['counts', 'hasResult', 'isPrimaryResult', 'used'],
      objects: {'counts': CloudConversationHourlyAgentAnalyticsValueCounts}
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueByLanguageParameters {
  languageCode?: string|null;
  counts?: CloudConversationHourlyAgentAnalyticsValueCounts|null;
}
export class CloudConversationHourlyAgentAnalyticsValueByLanguage extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueByLanguageParameters = {}) {
    super();
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'counts', (parameters.counts == null) ? (null) : (parameters.counts));
  }

  get counts(): CloudConversationHourlyAgentAnalyticsValueCounts|null {
    return (
        (this.Serializable$has('counts')) ? (this.Serializable$get('counts')) :
                                            (null));
  }

  /**
   * WARNING: In this slice session counts can't be summed because one session
   * may have different languages.
   */
  set counts(value: CloudConversationHourlyAgentAnalyticsValueCounts|null) {
    this.Serializable$set('counts', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Agent language code converted to lowercase and it may or may not contain
   * the locale. For example, for 'EN-US' this will be 'en' if the agent has
   * language 'en' but no locale for 'en-US'.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValueByLanguage> {
    return CloudConversationHourlyAgentAnalyticsValueByLanguage;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['counts', 'languageCode'],
      objects: {'counts': CloudConversationHourlyAgentAnalyticsValueCounts}
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueByPageParameters {
  pageId?: string|null;
  pageDisplayName?: string|null;
  flowId?: string|null;
  flowDisplayName?: string|null;
  noMatchCount?: string|null;
  counts?: CloudConversationHourlyAgentAnalyticsValueCounts|null;
  existSessionsCounts?: string|null;
}
export class CloudConversationHourlyAgentAnalyticsValueByPage extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueByPageParameters = {}) {
    super();
    this.Serializable$set(
        'pageId', (parameters.pageId == null) ? (null) : (parameters.pageId));
    this.Serializable$set(
        'pageDisplayName',
        (parameters.pageDisplayName == null) ? (null) :
                                               (parameters.pageDisplayName));
    this.Serializable$set(
        'flowId', (parameters.flowId == null) ? (null) : (parameters.flowId));
    this.Serializable$set(
        'flowDisplayName',
        (parameters.flowDisplayName == null) ? (null) :
                                               (parameters.flowDisplayName));
    this.Serializable$set(
        'noMatchCount',
        (parameters.noMatchCount == null) ? (null) : (parameters.noMatchCount));
    this.Serializable$set(
        'counts', (parameters.counts == null) ? (null) : (parameters.counts));
    this.Serializable$set(
        'existSessionsCounts',
        (parameters.existSessionsCounts == null) ?
            (null) :
            (parameters.existSessionsCounts));
  }

  get counts(): CloudConversationHourlyAgentAnalyticsValueCounts|null {
    return (
        (this.Serializable$has('counts')) ? (this.Serializable$get('counts')) :
                                            (null));
  }

  /**
   * Counts for interactions received when the conversation is on this page.
   */
  set counts(value: CloudConversationHourlyAgentAnalyticsValueCounts|null) {
    this.Serializable$set('counts', value);
  }

  get existSessionsCounts(): string|null {
    return (
        (this.Serializable$has('existSessionsCounts')) ?
            (this.Serializable$get('existSessionsCounts')) :
            (null));
  }

  /**
   * Note: Typo, this field should be exit_sessions_counts. Number of sessions
   * exits at this page. The session exits if it's latest appearance in the
   * current hour is the current page, and doesn't appear in next hour in any
   * page.
   */
  set existSessionsCounts(value: string|null) {
    this.Serializable$set('existSessionsCounts', value);
  }

  get flowDisplayName(): string|null {
    return (
        (this.Serializable$has('flowDisplayName')) ?
            (this.Serializable$get('flowDisplayName')) :
            (null));
  }

  /**
   * Display name of the flow that the page belongs to. If page_id is empty,
   * this will also be empty. Deprecated. We should rely on the flow id instead.
   */
  set flowDisplayName(value: string|null) {
    this.Serializable$set('flowDisplayName', value);
  }

  get flowId(): string|null {
    return (
        (this.Serializable$has('flowId')) ? (this.Serializable$get('flowId')) :
                                            (null));
  }

  /**
   * Id of the flow that the page belongs to. If page_id is empty, this will
   * also be empty.
   */
  set flowId(value: string|null) {
    this.Serializable$set('flowId', value);
  }

  get noMatchCount(): string|null {
    return (
        (this.Serializable$has('noMatchCount')) ?
            (this.Serializable$get('noMatchCount')) :
            (null));
  }

  /**
   * Number of interactions whose match type is no_match, while the conversation
   * is on this page.
   * http://google3/google/cloud/dialogflow/v3alpha1/session.proto?l=667&rcl=343434681
   */
  set noMatchCount(value: string|null) {
    this.Serializable$set('noMatchCount', value);
  }

  get pageDisplayName(): string|null {
    return (
        (this.Serializable$has('pageDisplayName')) ?
            (this.Serializable$get('pageDisplayName')) :
            (null));
  }

  /**
   * Display name of the matched page truncated to 64 types. If page_id is
   * empty, this will also be empty. Deprecated. We should rely on the page id
   * instead.
   */
  set pageDisplayName(value: string|null) {
    this.Serializable$set('pageDisplayName', value);
  }

  get pageId(): string|null {
    return (
        (this.Serializable$has('pageId')) ? (this.Serializable$get('pageId')) :
                                            (null));
  }

  /**
   * Id of the matched page. If an interaction wasn't matched to any intent and
   * thus wasn't matched to any page, this will be an empty string.
   */
  set pageId(value: string|null) {
    this.Serializable$set('pageId', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValueByPage> {
    return CloudConversationHourlyAgentAnalyticsValueByPage;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'counts', 'existSessionsCounts', 'flowDisplayName', 'flowId',
        'noMatchCount', 'pageDisplayName', 'pageId'
      ],
      objects: {'counts': CloudConversationHourlyAgentAnalyticsValueCounts}
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueBySentimentParameters {
  querySentiment?: boolean|null;
  conversationSentiment?: boolean|null;
  counts?: CloudConversationHourlyAgentAnalyticsValueCounts|null;
}
export class CloudConversationHourlyAgentAnalyticsValueBySentiment extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueBySentimentParameters =
              {}) {
    super();
    this.Serializable$set(
        'querySentiment',
        (parameters.querySentiment == null) ? (null) :
                                              (parameters.querySentiment));
    this.Serializable$set(
        'conversationSentiment',
        (parameters.conversationSentiment == null) ?
            (null) :
            (parameters.conversationSentiment));
    this.Serializable$set(
        'counts', (parameters.counts == null) ? (null) : (parameters.counts));
  }

  get conversationSentiment(): boolean|null {
    return (
        (this.Serializable$has('conversationSentiment')) ?
            (this.Serializable$get('conversationSentiment')) :
            (null));
  }

  /**
   * Was conversation analyzed for sentiment?
   */
  set conversationSentiment(value: boolean|null) {
    this.Serializable$set('conversationSentiment', value);
  }

  get counts(): CloudConversationHourlyAgentAnalyticsValueCounts|null {
    return (
        (this.Serializable$has('counts')) ? (this.Serializable$get('counts')) :
                                            (null));
  }

  /**
   * Counts.
   */
  set counts(value: CloudConversationHourlyAgentAnalyticsValueCounts|null) {
    this.Serializable$set('counts', value);
  }

  get querySentiment(): boolean|null {
    return (
        (this.Serializable$has('querySentiment')) ?
            (this.Serializable$get('querySentiment')) :
            (null));
  }

  /**
   * Was query analyzed for sentiment?
   */
  set querySentiment(value: boolean|null) {
    this.Serializable$set('querySentiment', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValueBySentiment> {
    return CloudConversationHourlyAgentAnalyticsValueBySentiment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['conversationSentiment', 'counts', 'querySentiment'],
      objects: {'counts': CloudConversationHourlyAgentAnalyticsValueCounts}
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueBySpeechParameters {
  speechToText?: boolean|null;
  textToSpeech?: boolean|null;
  counts?: CloudConversationHourlyAgentAnalyticsValueCounts|null;
}
export class CloudConversationHourlyAgentAnalyticsValueBySpeech extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueBySpeechParameters = {}) {
    super();
    this.Serializable$set(
        'speechToText',
        (parameters.speechToText == null) ? (null) : (parameters.speechToText));
    this.Serializable$set(
        'textToSpeech',
        (parameters.textToSpeech == null) ? (null) : (parameters.textToSpeech));
    this.Serializable$set(
        'counts', (parameters.counts == null) ? (null) : (parameters.counts));
  }

  get counts(): CloudConversationHourlyAgentAnalyticsValueCounts|null {
    return (
        (this.Serializable$has('counts')) ? (this.Serializable$get('counts')) :
                                            (null));
  }

  /**
   * Counts.
   */
  set counts(value: CloudConversationHourlyAgentAnalyticsValueCounts|null) {
    this.Serializable$set('counts', value);
  }

  get speechToText(): boolean|null {
    return (
        (this.Serializable$has('speechToText')) ?
            (this.Serializable$get('speechToText')) :
            (null));
  }

  /**
   * Was speech recognition attempted?
   */
  set speechToText(value: boolean|null) {
    this.Serializable$set('speechToText', value);
  }

  get textToSpeech(): boolean|null {
    return (
        (this.Serializable$has('textToSpeech')) ?
            (this.Serializable$get('textToSpeech')) :
            (null));
  }

  /**
   * Was text synthesized to speech?
   */
  set textToSpeech(value: boolean|null) {
    this.Serializable$set('textToSpeech', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValueBySpeech> {
    return CloudConversationHourlyAgentAnalyticsValueBySpeech;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['counts', 'speechToText', 'textToSpeech'],
      objects: {'counts': CloudConversationHourlyAgentAnalyticsValueCounts}
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueByWebhookParameters {
  called?: boolean|null;
  counts?: CloudConversationHourlyAgentAnalyticsValueCounts|null;
}
export class CloudConversationHourlyAgentAnalyticsValueByWebhook extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueByWebhookParameters = {}) {
    super();
    this.Serializable$set(
        'called', (parameters.called == null) ? (null) : (parameters.called));
    this.Serializable$set(
        'counts', (parameters.counts == null) ? (null) : (parameters.counts));
  }

  get called(): boolean|null {
    return (
        (this.Serializable$has('called')) ? (this.Serializable$get('called')) :
                                            (null));
  }

  /**
   * Was a webhook called?
   */
  set called(value: boolean|null) {
    this.Serializable$set('called', value);
  }

  get counts(): CloudConversationHourlyAgentAnalyticsValueCounts|null {
    return (
        (this.Serializable$has('counts')) ? (this.Serializable$get('counts')) :
                                            (null));
  }

  /**
   * Counts.
   */
  set counts(value: CloudConversationHourlyAgentAnalyticsValueCounts|null) {
    this.Serializable$set('counts', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValueByWebhook> {
    return CloudConversationHourlyAgentAnalyticsValueByWebhook;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['called', 'counts'],
      objects: {'counts': CloudConversationHourlyAgentAnalyticsValueCounts}
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueCountsParameters {
  interactions?: string|null;
  sessions?: string|null;
}
export class CloudConversationHourlyAgentAnalyticsValueCounts extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueCountsParameters = {}) {
    super();
    this.Serializable$set(
        'interactions',
        (parameters.interactions == null) ? (null) : (parameters.interactions));
    this.Serializable$set(
        'sessions',
        (parameters.sessions == null) ? (null) : (parameters.sessions));
  }

  get interactions(): string|null {
    return (
        (this.Serializable$has('interactions')) ?
            (this.Serializable$get('interactions')) :
            (null));
  }

  /**
   * Number of interactions.
   */
  set interactions(value: string|null) {
    this.Serializable$set('interactions', value);
  }

  get sessions(): string|null {
    return (
        (this.Serializable$has('sessions')) ?
            (this.Serializable$get('sessions')) :
            (null));
  }

  /**
   * Number of sessions.
   */
  set sessions(value: string|null) {
    this.Serializable$set('sessions', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyAgentAnalyticsValueCounts> {
    return CloudConversationHourlyAgentAnalyticsValueCounts;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['interactions', 'sessions']};
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeParameters {
  code?: CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode|
      null;
  interactions?: string|null;
}
export class
    CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCode extends
        Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeParameters =
              {}) {
    super();
    this.Serializable$set(
        'code', (parameters.code == null) ? (null) : (parameters.code));
    this.Serializable$set(
        'interactions',
        (parameters.interactions == null) ? (null) : (parameters.interactions));
  }

  static get Code():
      ICloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum {
    return CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum;
  }

  get code():
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode
      |null {
    return (
        (this.Serializable$has('code')) ? (this.Serializable$get('code')) :
                                          (null));
  }

  /**
   * Status code.
   */
  set code(
      value:
          CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCode|
      null) {
    this.Serializable$set('code', value);
  }

  get interactions(): string|null {
    return (
        (this.Serializable$has('interactions')) ?
            (this.Serializable$get('interactions')) :
            (null));
  }

  /**
   * Number of interactions.
   */
  set interactions(value: string|null) {
    this.Serializable$set('interactions', value);
  }

  getConstructor(): SerializableCtor<
      CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCode> {
    return CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCode;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'code':
            CloudConversationHourlyAgentAnalyticsValueInteractionsByStatusCodeCodeEnum
      },
      keys: ['code', 'interactions']
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValuePageSessionPathNodeParameters {
  pageId?: string|null;
  pageDisplayName?: string|null;
  flowId?: string|null;
  flowDisplayName?: string|null;
  sessions?: string|null;
  finishedSessions?: string|null;
  childNodes?:
      Array<CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode>|null;
}
export class
    CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode extends
        Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValuePageSessionPathNodeParameters =
              {}) {
    super();
    this.Serializable$set(
        'pageId', (parameters.pageId == null) ? (null) : (parameters.pageId));
    this.Serializable$set(
        'pageDisplayName',
        (parameters.pageDisplayName == null) ? (null) :
                                               (parameters.pageDisplayName));
    this.Serializable$set(
        'flowId', (parameters.flowId == null) ? (null) : (parameters.flowId));
    this.Serializable$set(
        'flowDisplayName',
        (parameters.flowDisplayName == null) ? (null) :
                                               (parameters.flowDisplayName));
    this.Serializable$set(
        'sessions',
        (parameters.sessions == null) ? (null) : (parameters.sessions));
    this.Serializable$set(
        'finishedSessions',
        (parameters.finishedSessions == null) ? (null) :
                                                (parameters.finishedSessions));
    this.Serializable$set(
        'childNodes',
        (parameters.childNodes == null) ? (null) : (parameters.childNodes));
  }

  get childNodes():
      Array<CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode>|
      null {
    return (
        (this.Serializable$has('childNodes')) ?
            (this.Serializable$get('childNodes')) :
            (null));
  }

  /**
   * Child nodes for paths that continue after this node.
   */
  set childNodes(
      value:
          Array<CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode>|
      null) {
    this.Serializable$set('childNodes', value);
  }

  get finishedSessions(): string|null {
    return (
        (this.Serializable$has('finishedSessions')) ?
            (this.Serializable$get('finishedSessions')) :
            (null));
  }

  /**
   * Number of sessions that end in this node.
   */
  set finishedSessions(value: string|null) {
    this.Serializable$set('finishedSessions', value);
  }

  get flowDisplayName(): string|null {
    return (
        (this.Serializable$has('flowDisplayName')) ?
            (this.Serializable$get('flowDisplayName')) :
            (null));
  }

  /**
   * Display name of the flow that the page belongs to.
   */
  set flowDisplayName(value: string|null) {
    this.Serializable$set('flowDisplayName', value);
  }

  get flowId(): string|null {
    return (
        (this.Serializable$has('flowId')) ? (this.Serializable$get('flowId')) :
                                            (null));
  }

  /**
   * Id of the flow.
   */
  set flowId(value: string|null) {
    this.Serializable$set('flowId', value);
  }

  get pageDisplayName(): string|null {
    return (
        (this.Serializable$has('pageDisplayName')) ?
            (this.Serializable$get('pageDisplayName')) :
            (null));
  }

  /**
   * Display name of the page.
   */
  set pageDisplayName(value: string|null) {
    this.Serializable$set('pageDisplayName', value);
  }

  get pageId(): string|null {
    return (
        (this.Serializable$has('pageId')) ? (this.Serializable$get('pageId')) :
                                            (null));
  }

  /**
   * Id of the page.
   */
  set pageId(value: string|null) {
    this.Serializable$set('pageId', value);
  }

  get sessions(): string|null {
    return (
        (this.Serializable$has('sessions')) ?
            (this.Serializable$get('sessions')) :
            (null));
  }

  /**
   * Number of sessions that go through this node.
   */
  set sessions(value: string|null) {
    this.Serializable$set('sessions', value);
  }

  getConstructor(): SerializableCtor<
      CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode> {
    return CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'childNodes':
            CloudConversationHourlyAgentAnalyticsValuePageSessionPathNode
      },
      keys: [
        'childNodes', 'finishedSessions', 'flowDisplayName', 'flowId',
        'pageDisplayName', 'pageId', 'sessions'
      ]
    };
  }
}

export interface CloudConversationHourlyAgentAnalyticsValueSessionPathNodeParameters {
  intentDisplayName?: string|null;
  sessions?: string|null;
  finishedSessions?: string|null;
  childNodes?: Array<CloudConversationHourlyAgentAnalyticsValueSessionPathNode>|
      null;
}
export class CloudConversationHourlyAgentAnalyticsValueSessionPathNode extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyAgentAnalyticsValueSessionPathNodeParameters =
              {}) {
    super();
    this.Serializable$set(
        'intentDisplayName',
        (parameters.intentDisplayName == null) ?
            (null) :
            (parameters.intentDisplayName));
    this.Serializable$set(
        'sessions',
        (parameters.sessions == null) ? (null) : (parameters.sessions));
    this.Serializable$set(
        'finishedSessions',
        (parameters.finishedSessions == null) ? (null) :
                                                (parameters.finishedSessions));
    this.Serializable$set(
        'childNodes',
        (parameters.childNodes == null) ? (null) : (parameters.childNodes));
  }

  get childNodes():
      Array<CloudConversationHourlyAgentAnalyticsValueSessionPathNode>|null {
    return (
        (this.Serializable$has('childNodes')) ?
            (this.Serializable$get('childNodes')) :
            (null));
  }

  /**
   * Child nodes for paths that continue after this node.
   */
  set childNodes(
      value: Array<CloudConversationHourlyAgentAnalyticsValueSessionPathNode>|
      null) {
    this.Serializable$set('childNodes', value);
  }

  get finishedSessions(): string|null {
    return (
        (this.Serializable$has('finishedSessions')) ?
            (this.Serializable$get('finishedSessions')) :
            (null));
  }

  /**
   * Number of sessions that end in this node.
   */
  set finishedSessions(value: string|null) {
    this.Serializable$set('finishedSessions', value);
  }

  get intentDisplayName(): string|null {
    return (
        (this.Serializable$has('intentDisplayName')) ?
            (this.Serializable$get('intentDisplayName')) :
            (null));
  }

  /**
   * Intent display name of the current node.
   */
  set intentDisplayName(value: string|null) {
    this.Serializable$set('intentDisplayName', value);
  }

  get sessions(): string|null {
    return (
        (this.Serializable$has('sessions')) ?
            (this.Serializable$get('sessions')) :
            (null));
  }

  /**
   * Number of sessions that go through this node.
   */
  set sessions(value: string|null) {
    this.Serializable$set('sessions', value);
  }

  getConstructor(): SerializableCtor<
      CloudConversationHourlyAgentAnalyticsValueSessionPathNode> {
    return CloudConversationHourlyAgentAnalyticsValueSessionPathNode;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'childNodes': CloudConversationHourlyAgentAnalyticsValueSessionPathNode
      },
      keys: ['childNodes', 'finishedSessions', 'intentDisplayName', 'sessions']
    };
  }
}

export interface CloudConversationHourlyConversationAnalyticsParameters {
  key?: CloudConversationHourlyConversationAnalyticsKey|null;
  value?: CloudConversationHourlyConversationAnalyticsValue|null;
}
export class CloudConversationHourlyConversationAnalytics extends Serializable {
  constructor(
      parameters: CloudConversationHourlyConversationAnalyticsParameters = {}) {
    super();
    this.Serializable$set(
        'key', (parameters.key == null) ? (null) : (parameters.key));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
  }

  get key(): CloudConversationHourlyConversationAnalyticsKey|null {
    return (
        (this.Serializable$has('key')) ? (this.Serializable$get('key')) :
                                         (null));
  }

  /**
   * LINT.ThenChange(//depot/google3/cloud/ml/api/conversation/schema/analytics.sdl:conversation_key)
   * Key of this conversation analytics entry.
   */
  set key(value: CloudConversationHourlyConversationAnalyticsKey|null) {
    this.Serializable$set('key', value);
  }

  get value(): CloudConversationHourlyConversationAnalyticsValue|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Value of this conversation analytics entry.
   */
  set value(value: CloudConversationHourlyConversationAnalyticsValue|null) {
    this.Serializable$set('value', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyConversationAnalytics> {
    return CloudConversationHourlyConversationAnalytics;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['key', 'value'],
      objects: {
        'key': CloudConversationHourlyConversationAnalyticsKey,
        'value': CloudConversationHourlyConversationAnalyticsValue
      }
    };
  }
}

export interface CloudConversationHourlyConversationAnalyticsKeyParameters {
  projectNumber?: string|null;
  region?: string|null;
  conversationProfileId?: string|null;
  openHour?: string|null;
}
export class CloudConversationHourlyConversationAnalyticsKey extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyConversationAnalyticsKeyParameters = {}) {
    super();
    this.Serializable$set(
        'projectNumber',
        (parameters.projectNumber == null) ? (null) :
                                             (parameters.projectNumber));
    this.Serializable$set(
        'region', (parameters.region == null) ? (null) : (parameters.region));
    this.Serializable$set(
        'conversationProfileId',
        (parameters.conversationProfileId == null) ?
            (null) :
            (parameters.conversationProfileId));
    this.Serializable$set(
        'openHour',
        (parameters.openHour == null) ? (null) : (parameters.openHour));
  }

  get conversationProfileId(): string|null {
    return (
        (this.Serializable$has('conversationProfileId')) ?
            (this.Serializable$get('conversationProfileId')) :
            (null));
  }

  /**
   * Unique id of the conversation profile for the conversation. If this is
   * empty, it indicates that the row contains aggregated stats for all
   * conversation profiles.
   */
  set conversationProfileId(value: string|null) {
    this.Serializable$set('conversationProfileId', value);
  }

  get openHour(): string|null {
    return (
        (this.Serializable$has('openHour')) ?
            (this.Serializable$get('openHour')) :
            (null));
  }

  /**
   * Open time of the conversations truncated to hour in UTC.
   */
  set openHour(value: string|null) {
    this.Serializable$set('openHour', value);
  }

  get projectNumber(): string|null {
    return (
        (this.Serializable$has('projectNumber')) ?
            (this.Serializable$get('projectNumber')) :
            (null));
  }

  /**
   * Cloud project number of the conversations. Note that a CCAI conversation
   * may call DF agents in multiple projects. This is not the project number of
   * any of the agents in the conversation.
   */
  set projectNumber(value: string|null) {
    this.Serializable$set('projectNumber', value);
  }

  get region(): string|null {
    return (
        (this.Serializable$has('region')) ? (this.Serializable$get('region')) :
                                            (null));
  }

  /**
   * Region of the conversation. For the global region this will be empty.
   */
  set region(value: string|null) {
    this.Serializable$set('region', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyConversationAnalyticsKey> {
    return CloudConversationHourlyConversationAnalyticsKey;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['conversationProfileId', 'openHour', 'projectNumber', 'region']
    };
  }
}

export interface CloudConversationHourlyConversationAnalyticsValueParameters {
  count?: string|null;
  countAutomatedAgent?: string|null;
  countContained?: string|null;
  countContainedWithoutUserReturned?: string|null;
  countAbandoned?: string|null;
  countLiveAgentHandoff?: string|null;
}
export class CloudConversationHourlyConversationAnalyticsValue extends
    Serializable {
  constructor(
      parameters:
          CloudConversationHourlyConversationAnalyticsValueParameters = {}) {
    super();
    this.Serializable$set(
        'count', (parameters.count == null) ? (null) : (parameters.count));
    this.Serializable$set(
        'countAutomatedAgent',
        (parameters.countAutomatedAgent == null) ?
            (null) :
            (parameters.countAutomatedAgent));
    this.Serializable$set(
        'countContained',
        (parameters.countContained == null) ? (null) :
                                              (parameters.countContained));
    this.Serializable$set(
        'countContainedWithoutUserReturned',
        (parameters.countContainedWithoutUserReturned == null) ?
            (null) :
            (parameters.countContainedWithoutUserReturned));
    this.Serializable$set(
        'countAbandoned',
        (parameters.countAbandoned == null) ? (null) :
                                              (parameters.countAbandoned));
    this.Serializable$set(
        'countLiveAgentHandoff',
        (parameters.countLiveAgentHandoff == null) ?
            (null) :
            (parameters.countLiveAgentHandoff));
  }

  get count(): string|null {
    return (
        (this.Serializable$has('count')) ? (this.Serializable$get('count')) :
                                           (null));
  }

  /**
   * Number of conversations.
   */
  set count(value: string|null) {
    this.Serializable$set('count', value);
  }

  get countAbandoned(): string|null {
    return (
        (this.Serializable$has('countAbandoned')) ?
            (this.Serializable$get('countAbandoned')) :
            (null));
  }

  /**
   * Number of abandoned conversations. Only automated agent conversations can
   * be counted as abandoned.
   */
  set countAbandoned(value: string|null) {
    this.Serializable$set('countAbandoned', value);
  }

  get countAutomatedAgent(): string|null {
    return (
        (this.Serializable$has('countAutomatedAgent')) ?
            (this.Serializable$get('countAutomatedAgent')) :
            (null));
  }

  /**
   * Number of conversations with an automated agent interaction.
   */
  set countAutomatedAgent(value: string|null) {
    this.Serializable$set('countAutomatedAgent', value);
  }

  get countContained(): string|null {
    return (
        (this.Serializable$has('countContained')) ?
            (this.Serializable$get('countContained')) :
            (null));
  }

  /**
   * Number of contained conversations. Only automated agent conversations can
   * be counted as contained.
   */
  set countContained(value: string|null) {
    this.Serializable$set('countContained', value);
  }

  get countContainedWithoutUserReturned(): string|null {
    return (
        (this.Serializable$has('countContainedWithoutUserReturned')) ?
            (this.Serializable$get('countContainedWithoutUserReturned')) :
            (null));
  }

  /**
   * Number of contained conversations if you disregard the criterion that user
   * shouldn't return within 24h. Only automated agent conversations can be
   * counted as contained.
   */
  set countContainedWithoutUserReturned(value: string|null) {
    this.Serializable$set('countContainedWithoutUserReturned', value);
  }

  get countLiveAgentHandoff(): string|null {
    return (
        (this.Serializable$has('countLiveAgentHandoff')) ?
            (this.Serializable$get('countLiveAgentHandoff')) :
            (null));
  }

  /**
   * Number of conversations handed off to a live agent. Only automated agent
   * conversations can be handed off to a live agent.
   */
  set countLiveAgentHandoff(value: string|null) {
    this.Serializable$set('countLiveAgentHandoff', value);
  }

  getConstructor():
      SerializableCtor<CloudConversationHourlyConversationAnalyticsValue> {
    return CloudConversationHourlyConversationAnalyticsValue;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'count', 'countAbandoned', 'countAutomatedAgent', 'countContained',
        'countContainedWithoutUserReturned', 'countLiveAgentHandoff'
      ]
    };
  }
}

export interface CloudConversationNamedFeatureVectorParameters {
  modelName?: string|null;
  modelVersion?: string|null;
  gfv?: ResearchScamGenericFeatureVector|null;
}
export class CloudConversationNamedFeatureVector extends Serializable {
  constructor(parameters: CloudConversationNamedFeatureVectorParameters = {}) {
    super();
    this.Serializable$set(
        'modelName',
        (parameters.modelName == null) ? (null) : (parameters.modelName));
    this.Serializable$set(
        'modelVersion',
        (parameters.modelVersion == null) ? (null) : (parameters.modelVersion));
    this.Serializable$set(
        'gfv', (parameters.gfv == null) ? (null) : (parameters.gfv));
  }

  get gfv(): ResearchScamGenericFeatureVector|null {
    return (
        (this.Serializable$has('gfv')) ? (this.Serializable$get('gfv')) :
                                         (null));
  }

  /**
   * Required. The feature vector itself.
   */
  set gfv(value: ResearchScamGenericFeatureVector|null) {
    this.Serializable$set('gfv', value);
  }

  get modelName(): string|null {
    return (
        (this.Serializable$has('modelName')) ?
            (this.Serializable$get('modelName')) :
            (null));
  }

  /**
   * Required. The name of the model that generated the feature vector.
   */
  set modelName(value: string|null) {
    this.Serializable$set('modelName', value);
  }

  get modelVersion(): string|null {
    return (
        (this.Serializable$has('modelVersion')) ?
            (this.Serializable$get('modelVersion')) :
            (null));
  }

  /**
   * Optional. The version of the model.
   */
  set modelVersion(value: string|null) {
    this.Serializable$set('modelVersion', value);
  }

  getConstructor(): SerializableCtor<CloudConversationNamedFeatureVector> {
    return CloudConversationNamedFeatureVector;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['gfv', 'modelName', 'modelVersion'],
      objects: {'gfv': ResearchScamGenericFeatureVector}
    };
  }
}

export interface ClusterSmartMessagingEntriesRequest_Parameters {
  conversationModel?: string|null;
}
export class ClusterSmartMessagingEntriesRequest_ extends Serializable {
  constructor(parameters: ClusterSmartMessagingEntriesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * Required. The name of the conversation model. Embedding of the candidate
   * document generated with the model are to be used for computing the semantic
   * similarity of the candidate entries for clustering. Format:
   * `projects//locations//conversationModels/`.
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  getConstructor(): SerializableCtor<ClusterSmartMessagingEntriesRequest_> {
    return ClusterSmartMessagingEntriesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationModel']};
  }
}

export interface ClusteredMessageMessageStats_Parameters {
  score?: number|null;
  pagerankScore?: number|null;
  expanderScore?: number|null;
  centroidSimilarityScore?: number|null;
}
export class ClusteredMessageMessageStats_ extends Serializable {
  constructor(parameters: ClusteredMessageMessageStats_Parameters = {}) {
    super();
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'pagerankScore',
        (parameters.pagerankScore == null) ? (null) :
                                             (parameters.pagerankScore));
    this.Serializable$set(
        'expanderScore',
        (parameters.expanderScore == null) ? (null) :
                                             (parameters.expanderScore));
    this.Serializable$set(
        'centroidSimilarityScore',
        (parameters.centroidSimilarityScore == null) ?
            (null) :
            (parameters.centroidSimilarityScore));
  }

  get centroidSimilarityScore(): number|null {
    return (
        (this.Serializable$has('centroidSimilarityScore')) ?
            (this.Serializable$get('centroidSimilarityScore')) :
            (null));
  }

  /**
   * Internal. Message weight scored by centroid similarity in the clustering
   * result. Range in [0, 1).
   */
  set centroidSimilarityScore(value: number|null) {
    this.Serializable$set('centroidSimilarityScore', value);
  }

  get expanderScore(): number|null {
    return (
        (this.Serializable$has('expanderScore')) ?
            (this.Serializable$get('expanderScore')) :
            (null));
  }

  /**
   * Internal. Message weight scored by Expander. Range in [0, 1). Measures the
   * difference between the largest and second largest weight of labels
   * propagated by expander (assign cluster ids as labels). Larger difference
   * means more confidence that the message belong to the cluster with the
   * largest weight.
   */
  set expanderScore(value: number|null) {
    this.Serializable$set('expanderScore', value);
  }

  get pagerankScore(): number|null {
    return (
        (this.Serializable$has('pagerankScore')) ?
            (this.Serializable$get('pagerankScore')) :
            (null));
  }

  /**
   * Internal. Message weight scored by Pagerank. Range in [0, 1).
   */
  set pagerankScore(value: number|null) {
    this.Serializable$set('pagerankScore', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Score of the message in the cluster.
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<ClusteredMessageMessageStats_> {
    return ClusteredMessageMessageStats_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys:
          ['centroidSimilarityScore', 'expanderScore', 'pagerankScore', 'score']
    };
  }
}

export interface ClusteredMessage_Parameters {
  id?: string|null;
  clusterId?: string|null;
  expanderClusterId?: string|null;
  hierarchicalClusterIds?: Array<string>|null;
  stats?: ClusteredMessageMessageStats_|null;
  normalizedContent?: string|null;
  messageReferences?: Array<MessageReference_>|null;
  messageLabels?: MessageLabels_|null;
  resource?: DialogflowMessageLevelResource_|null;
}
export class ClusteredMessage_ extends Serializable {
  constructor(parameters: ClusteredMessage_Parameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'clusterId',
        (parameters.clusterId == null) ? (null) : (parameters.clusterId));
    this.Serializable$set(
        'expanderClusterId',
        (parameters.expanderClusterId == null) ?
            (null) :
            (parameters.expanderClusterId));
    this.Serializable$set(
        'hierarchicalClusterIds',
        (parameters.hierarchicalClusterIds == null) ?
            (null) :
            (parameters.hierarchicalClusterIds));
    this.Serializable$set(
        'stats', (parameters.stats == null) ? (null) : (parameters.stats));
    this.Serializable$set(
        'normalizedContent',
        (parameters.normalizedContent == null) ?
            (null) :
            (parameters.normalizedContent));
    this.Serializable$set(
        'messageReferences',
        (parameters.messageReferences == null) ?
            (null) :
            (parameters.messageReferences));
    this.Serializable$set(
        'messageLabels',
        (parameters.messageLabels == null) ? (null) :
                                             (parameters.messageLabels));
    this.Serializable$set(
        'resource',
        (parameters.resource == null) ? (null) : (parameters.resource));
  }

  get clusterId(): string|null {
    return (
        (this.Serializable$has('clusterId')) ?
            (this.Serializable$get('clusterId')) :
            (null));
  }

  /**
   * Cluster id that the message belongs to.
   */
  set clusterId(value: string|null) {
    this.Serializable$set('clusterId', value);
  }

  get expanderClusterId(): string|null {
    return (
        (this.Serializable$has('expanderClusterId')) ?
            (this.Serializable$get('expanderClusterId')) :
            (null));
  }

  /**
   * Internal. The cluster id after expander cluster propagation.
   */
  set expanderClusterId(value: string|null) {
    this.Serializable$set('expanderClusterId', value);
  }

  get hierarchicalClusterIds(): Array<string>|null {
    return (
        (this.Serializable$has('hierarchicalClusterIds')) ?
            (this.Serializable$get('hierarchicalClusterIds')) :
            (null));
  }

  /**
   * Internal. Hierarchical cluster ids. Corresponding to the cluster at level 0
   * to `compression_rounds` (inclusive) in affinity clustering.
   */
  set hierarchicalClusterIds(value: Array<string>|null) {
    this.Serializable$set('hierarchicalClusterIds', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Id of the clustered message.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get messageLabels(): MessageLabels_|null {
    return (
        (this.Serializable$has('messageLabels')) ?
            (this.Serializable$get('messageLabels')) :
            (null));
  }

  /**
   * A list of labels applied to the normalized message content.
   */
  set messageLabels(value: MessageLabels_|null) {
    this.Serializable$set('messageLabels', value);
  }

  get messageReferences(): Array<MessageReference_>|null {
    return (
        (this.Serializable$has('messageReferences')) ?
            (this.Serializable$get('messageReferences')) :
            (null));
  }

  /**
   * A list of references to raw messages.
   */
  set messageReferences(value: Array<MessageReference_>|null) {
    this.Serializable$set('messageReferences', value);
  }

  get normalizedContent(): string|null {
    return (
        (this.Serializable$has('normalizedContent')) ?
            (this.Serializable$get('normalizedContent')) :
            (null));
  }

  /**
   * The message content after normalization.
   */
  set normalizedContent(value: string|null) {
    this.Serializable$set('normalizedContent', value);
  }

  get resource(): DialogflowMessageLevelResource_|null {
    return (
        (this.Serializable$has('resource')) ?
            (this.Serializable$get('resource')) :
            (null));
  }

  /**
   * Created dialogflow resource from this message. Only present as part of
   * `MessageNode`.
   */
  set resource(value: DialogflowMessageLevelResource_|null) {
    this.Serializable$set('resource', value);
  }

  get stats(): ClusteredMessageMessageStats_|null {
    return (
        (this.Serializable$has('stats')) ? (this.Serializable$get('stats')) :
                                           (null));
  }

  /**
   * Numerical results for the message.
   */
  set stats(value: ClusteredMessageMessageStats_|null) {
    this.Serializable$set('stats', value);
  }

  getConstructor(): SerializableCtor<ClusteredMessage_> {
    return ClusteredMessage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'messageReferences': MessageReference_},
      keys: [
        'clusterId', 'expanderClusterId', 'hierarchicalClusterIds', 'id',
        'messageLabels', 'messageReferences', 'normalizedContent', 'resource',
        'stats'
      ],
      objects: {
        'messageLabels': MessageLabels_,
        'resource': DialogflowMessageLevelResource_,
        'stats': ClusteredMessageMessageStats_
      }
    };
  }
}

export interface CompileSuggestionRequest_Parameters {
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class CompileSuggestionRequest_ extends Serializable {
  constructor(parameters: CompileSuggestionRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Optional. Max number of messages prior to and including [latest_message] to
   * use as context when compiling the suggestion. If zero or less than zero, 20
   * is used.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * Optional. The name of the latest conversation message to compile suggestion
   * for. If empty, it will be the latest message of the conversation. Format:
   * `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<CompileSuggestionRequest_> {
    return CompileSuggestionRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['contextSize', 'latestMessage']};
  }
}

export interface CompileSuggestionResponse_Parameters {
  suggestion?: Suggestion_|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class CompileSuggestionResponse_ extends Serializable {
  constructor(parameters: CompileSuggestionResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'suggestion',
        (parameters.suggestion == null) ? (null) : (parameters.suggestion));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including latest_message to compile the
   * suggestion. It may be smaller than the
   * CompileSuggestionRequest.context_size field in the request if there aren't
   * that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used to compile suggestion for.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  get suggestion(): Suggestion_|null {
    return (
        (this.Serializable$has('suggestion')) ?
            (this.Serializable$get('suggestion')) :
            (null));
  }

  /**
   * The compiled suggestion.
   */
  set suggestion(value: Suggestion_|null) {
    this.Serializable$set('suggestion', value);
  }

  getConstructor(): SerializableCtor<CompileSuggestionResponse_> {
    return CompileSuggestionResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['contextSize', 'latestMessage', 'suggestion'],
      objects: {'suggestion': Suggestion_}
    };
  }
}

export interface CompileSuggestionsRequest_Parameters {
  messages?: Array<Message_>|null;
}
export class CompileSuggestionsRequest_ extends Serializable {
  constructor(parameters: CompileSuggestionsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
  }

  get messages(): Array<Message_>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * Required. List of messages in a conversation in chronological order.
   */
  set messages(value: Array<Message_>|null) {
    this.Serializable$set('messages', value);
  }

  getConstructor(): SerializableCtor<CompileSuggestionsRequest_> {
    return CompileSuggestionsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'messages': Message_}, keys: ['messages']};
  }
}

export interface CompileSuggestionsResponse_Parameters {
  suggestions?: Array<Suggestion_>|null;
}
export class CompileSuggestionsResponse_ extends Serializable {
  constructor(parameters: CompileSuggestionsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'suggestions',
        (parameters.suggestions == null) ? (null) : (parameters.suggestions));
  }

  get suggestions(): Array<Suggestion_>|null {
    return (
        (this.Serializable$has('suggestions')) ?
            (this.Serializable$get('suggestions')) :
            (null));
  }

  /**
   * Required.
   */
  set suggestions(value: Array<Suggestion_>|null) {
    this.Serializable$set('suggestions', value);
  }

  getConstructor(): SerializableCtor<CompileSuggestionsResponse_> {
    return CompileSuggestionsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'suggestions': Suggestion_}, keys: ['suggestions']};
  }
}

export interface CompleteConversationRequest_Parameters {
  source?: CompleteConversationRequest_Source|null;
}
export class CompleteConversationRequest_ extends Serializable {
  constructor(parameters: CompleteConversationRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
  }

  static get Source(): ICompleteConversationRequest_SourceEnum {
    return CompleteConversationRequest_SourceEnum;
  }

  get source(): CompleteConversationRequest_Source|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * The type of the source which created the conversation.
   */
  set source(value: CompleteConversationRequest_Source|null) {
    this.Serializable$set('source', value);
  }

  getConstructor(): SerializableCtor<CompleteConversationRequest_> {
    return CompleteConversationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'source': CompleteConversationRequest_SourceEnum},
      keys: ['source']
    };
  }
}

export interface ConfusionMatrixInfoConfusionMatrixRowConfusionMatrixRowEntry_Parameters {
  predictedIntent?: string|null;
  queries?: Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|null;
}
export class
    ConfusionMatrixInfoConfusionMatrixRowConfusionMatrixRowEntry_ extends
        Serializable {
  constructor(
      parameters:
          ConfusionMatrixInfoConfusionMatrixRowConfusionMatrixRowEntry_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'predictedIntent',
        (parameters.predictedIntent == null) ? (null) :
                                               (parameters.predictedIntent));
    this.Serializable$set(
        'queries',
        (parameters.queries == null) ? (null) : (parameters.queries));
  }

  get predictedIntent(): string|null {
    return (
        (this.Serializable$has('predictedIntent')) ?
            (this.Serializable$get('predictedIntent')) :
            (null));
  }

  /**
   * Display name of the intent predicted by the model.
   */
  set predictedIntent(value: string|null) {
    this.Serializable$set('predictedIntent', value);
  }

  get queries(): Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|null {
    return (
        (this.Serializable$has('queries')) ?
            (this.Serializable$get('queries')) :
            (null));
  }

  /**
   * Contains all sample query info that are mispredicted by NLU model.
   */
  set queries(value: Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|
              null) {
    this.Serializable$set('queries', value);
  }

  getConstructor(): SerializableCtor<
      ConfusionMatrixInfoConfusionMatrixRowConfusionMatrixRowEntry_> {
    return ConfusionMatrixInfoConfusionMatrixRowConfusionMatrixRowEntry_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'queries': ConfusionMatrixInfoConfusionMatrixRowQueryInfo_},
      keys: ['predictedIntent', 'queries']
    };
  }
}

export interface ConfusionMatrixInfoConfusionMatrixRowQueryInfo_Parameters {
  sampleQuery?: ModelEvaluationQuery_|null;
  expectedParameters?: ApiClientObjectMap<any>|null;
  predictedParameters?: ApiClientObjectMap<any>|null;
}
export class ConfusionMatrixInfoConfusionMatrixRowQueryInfo_ extends
    Serializable {
  constructor(
      parameters:
          ConfusionMatrixInfoConfusionMatrixRowQueryInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'sampleQuery',
        (parameters.sampleQuery == null) ? (null) : (parameters.sampleQuery));
    this.Serializable$set(
        'expectedParameters',
        (parameters.expectedParameters == null) ?
            (null) :
            (parameters.expectedParameters));
    this.Serializable$set(
        'predictedParameters',
        (parameters.predictedParameters == null) ?
            (null) :
            (parameters.predictedParameters));
  }

  get expectedParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('expectedParameters')) ?
            (this.Serializable$get('expectedParameters')) :
            (null));
  }

  /**
   * The parameters expected to be extracted.
   */
  set expectedParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('expectedParameters', value);
  }

  get predictedParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('predictedParameters')) ?
            (this.Serializable$get('predictedParameters')) :
            (null));
  }

  /**
   * The parameters that the model extracted.
   */
  set predictedParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('predictedParameters', value);
  }

  get sampleQuery(): ModelEvaluationQuery_|null {
    return (
        (this.Serializable$has('sampleQuery')) ?
            (this.Serializable$get('sampleQuery')) :
            (null));
  }

  /**
   * The original sample query.
   */
  set sampleQuery(value: ModelEvaluationQuery_|null) {
    this.Serializable$set('sampleQuery', value);
  }

  getConstructor():
      SerializableCtor<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_> {
    return ConfusionMatrixInfoConfusionMatrixRowQueryInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['expectedParameters', 'predictedParameters', 'sampleQuery'],
      objectMaps: {
        'expectedParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'predictedParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'sampleQuery': ModelEvaluationQuery_}
    };
  }
}

export interface ConfusionMatrixInfoConfusionMatrixRow_Parameters {
  expectedIntent?: string|null;
  confusionMatrixRowEntries?:
      Array<ConfusionMatrixInfoConfusionMatrixRowConfusionMatrixRowEntry_>|null;
  exactMatchQueries?: Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|
      null;
  partialMatchQueries?: Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|
      null;
  fallbackQueries?: Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|null;
}
export class ConfusionMatrixInfoConfusionMatrixRow_ extends Serializable {
  constructor(
      parameters: ConfusionMatrixInfoConfusionMatrixRow_Parameters = {}) {
    super();
    this.Serializable$set(
        'expectedIntent',
        (parameters.expectedIntent == null) ? (null) :
                                              (parameters.expectedIntent));
    this.Serializable$set(
        'confusionMatrixRowEntries',
        (parameters.confusionMatrixRowEntries == null) ?
            (null) :
            (parameters.confusionMatrixRowEntries));
    this.Serializable$set(
        'exactMatchQueries',
        (parameters.exactMatchQueries == null) ?
            (null) :
            (parameters.exactMatchQueries));
    this.Serializable$set(
        'partialMatchQueries',
        (parameters.partialMatchQueries == null) ?
            (null) :
            (parameters.partialMatchQueries));
    this.Serializable$set(
        'fallbackQueries',
        (parameters.fallbackQueries == null) ? (null) :
                                               (parameters.fallbackQueries));
  }

  get confusionMatrixRowEntries():
      Array<ConfusionMatrixInfoConfusionMatrixRowConfusionMatrixRowEntry_>|
      null {
    return (
        (this.Serializable$has('confusionMatrixRowEntries')) ?
            (this.Serializable$get('confusionMatrixRowEntries')) :
            (null));
  }

  /**
   * Contains all column entries for mispredicted intents in the confusion
   * matrix row.
   */
  set confusionMatrixRowEntries(
      value:
          Array<ConfusionMatrixInfoConfusionMatrixRowConfusionMatrixRowEntry_>|
      null) {
    this.Serializable$set('confusionMatrixRowEntries', value);
  }

  get exactMatchQueries():
      Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|null {
    return (
        (this.Serializable$has('exactMatchQueries')) ?
            (this.Serializable$get('exactMatchQueries')) :
            (null));
  }

  /**
   * The collection of query info that are matched to the expected intent and
   * all parameters are correctly extracted.
   */
  set exactMatchQueries(
      value: Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|null) {
    this.Serializable$set('exactMatchQueries', value);
  }

  get expectedIntent(): string|null {
    return (
        (this.Serializable$has('expectedIntent')) ?
            (this.Serializable$get('expectedIntent')) :
            (null));
  }

  /**
   * Display name of the expected intent.
   */
  set expectedIntent(value: string|null) {
    this.Serializable$set('expectedIntent', value);
  }

  get fallbackQueries():
      Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|null {
    return (
        (this.Serializable$has('fallbackQueries')) ?
            (this.Serializable$get('fallbackQueries')) :
            (null));
  }

  /**
   * The collection of query info that are matched to a fallback intent.
   */
  set fallbackQueries(
      value: Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|null) {
    this.Serializable$set('fallbackQueries', value);
  }

  get partialMatchQueries():
      Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|null {
    return (
        (this.Serializable$has('partialMatchQueries')) ?
            (this.Serializable$get('partialMatchQueries')) :
            (null));
  }

  /**
   * The collection of query info that are matched to the expected intent, but
   * some parameters are incorrectly extracted.
   */
  set partialMatchQueries(
      value: Array<ConfusionMatrixInfoConfusionMatrixRowQueryInfo_>|null) {
    this.Serializable$set('partialMatchQueries', value);
  }

  getConstructor(): SerializableCtor<ConfusionMatrixInfoConfusionMatrixRow_> {
    return ConfusionMatrixInfoConfusionMatrixRow_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'confusionMatrixRowEntries':
            ConfusionMatrixInfoConfusionMatrixRowConfusionMatrixRowEntry_,
        'exactMatchQueries': ConfusionMatrixInfoConfusionMatrixRowQueryInfo_,
        'fallbackQueries': ConfusionMatrixInfoConfusionMatrixRowQueryInfo_,
        'partialMatchQueries': ConfusionMatrixInfoConfusionMatrixRowQueryInfo_
      },
      keys: [
        'confusionMatrixRowEntries', 'exactMatchQueries', 'expectedIntent',
        'fallbackQueries', 'partialMatchQueries'
      ]
    };
  }
}

export interface ConfusionMatrixInfo_Parameters {
  confusionMatrixRows?: Array<ConfusionMatrixInfoConfusionMatrixRow_>|null;
}
export class ConfusionMatrixInfo_ extends Serializable {
  constructor(parameters: ConfusionMatrixInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'confusionMatrixRows',
        (parameters.confusionMatrixRows == null) ?
            (null) :
            (parameters.confusionMatrixRows));
  }

  get confusionMatrixRows():
      Array<ConfusionMatrixInfoConfusionMatrixRow_>|null {
    return (
        (this.Serializable$has('confusionMatrixRows')) ?
            (this.Serializable$get('confusionMatrixRows')) :
            (null));
  }

  /**
   * The collection of confusion matrix rows. Note: The number of rows equals to
   * the number of unique intents appeared in dataset, including both predicted
   * and expected intents.
   */
  set confusionMatrixRows(value: Array<ConfusionMatrixInfoConfusionMatrixRow_>|
                          null) {
    this.Serializable$set('confusionMatrixRows', value);
  }

  getConstructor(): SerializableCtor<ConfusionMatrixInfo_> {
    return ConfusionMatrixInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'confusionMatrixRows': ConfusionMatrixInfoConfusionMatrixRow_},
      keys: ['confusionMatrixRows']
    };
  }
}

export interface ContextResource_Parameters {
  contextName?: string|null;
}
export class ContextResource_ extends Serializable {
  constructor(parameters: ContextResource_Parameters = {}) {
    super();
    this.Serializable$set(
        'contextName',
        (parameters.contextName == null) ? (null) : (parameters.contextName));
  }

  get contextName(): string|null {
    return (
        (this.Serializable$has('contextName')) ?
            (this.Serializable$get('contextName')) :
            (null));
  }

  /**
   * Resource name of the context. Format:
   * `projects//locations//agent/sessions/-/contexts/`.
   */
  set contextName(value: string|null) {
    this.Serializable$set('contextName', value);
  }

  getConstructor(): SerializableCtor<ContextResource_> {
    return ContextResource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['contextName']};
  }
}

export interface Context_Parameters {
  name?: string|null;
  lifespanCount?: number|null;
  parameters?: ApiClientObjectMap<any>|null;
}
export class Context_ extends Serializable {
  constructor(parameters: Context_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'lifespanCount',
        (parameters.lifespanCount == null) ? (null) :
                                             (parameters.lifespanCount));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get lifespanCount(): number|null {
    return (
        (this.Serializable$has('lifespanCount')) ?
            (this.Serializable$get('lifespanCount')) :
            (null));
  }

  /**
   * Optional. The number of conversational query requests after which the
   * context expires. The default is `0`. If set to `0`, the context expires
   * immediately. Contexts expire automatically after 20 minutes if there are no
   * matching queries.
   */
  set lifespanCount(value: number|null) {
    this.Serializable$set('lifespanCount', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of the context. Supported formats: -
   * `projects//agent/sessions//contexts/`, -
   * `projects//locations//agent/sessions//contexts/`, -
   * `projects//agent/environments//users//sessions//contexts/`, -
   * `projects//locations//agent/environments//users//sessions//contexts/`, The
   * `Context ID` is always converted to lowercase, may only contain characters
   * in a-zA-Z0-9_-% and may be at most 250 bytes long. If `Environment ID` is
   * not specified, we assume default 'draft' environment. If `User ID` is not
   * specified, we assume default '-' user. The following context names are
   * reserved for internal use by Dialogflow. You should not use these contexts
   * or create contexts with these names: * `__system_counters__` *
   * `*_id_dialog_context` * `*_dialog_params_size`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional. The collection of parameters associated with this context.
   * Depending on your protocol or client library language, this is a map,
   * associative array, symbol table, dictionary, or JSON object composed of a
   * collection of (MapKey, MapValue) pairs: - MapKey type: string - MapKey
   * value: parameter name - MapValue type: - If parameter's entity type is a
   * composite entity: map - Else: depending on parameter value type, could be
   * one of string, number, boolean, null, list or map - MapValue value: - If
   * parameter's entity type is a composite entity: map from composite entity
   * property names to property values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<Context_> {
    return Context_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['lifespanCount', 'name', 'parameters'],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface ConversationAnnotation_Parameters {
  name?: string|null;
  annotation?: string|null;
}
export class ConversationAnnotation_ extends Serializable {
  constructor(parameters: ConversationAnnotation_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'annotation',
        (parameters.annotation == null) ? (null) : (parameters.annotation));
  }

  get annotation(): string|null {
    return (
        (this.Serializable$has('annotation')) ?
            (this.Serializable$get('annotation')) :
            (null));
  }

  /**
   * Conversation annotation. Deserialize to ccai.storage.Annotation. Not use
   * Any since the proto is an internal proto.
   */
  set annotation(value: string|null) {
    this.Serializable$set('annotation', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * ConversationAnnotation resource name. Format:
   * `projects//locations//conversationDatasets//conversationDataItems//conversationAnnotations/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<ConversationAnnotation_> {
    return ConversationAnnotation_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['annotation', 'name']};
  }
}

export interface ConversationDataItem_Parameters {
  name?: string|null;
  dataItem?: string|null;
}
export class ConversationDataItem_ extends Serializable {
  constructor(parameters: ConversationDataItem_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'dataItem',
        (parameters.dataItem == null) ? (null) : (parameters.dataItem));
  }

  get dataItem(): string|null {
    return (
        (this.Serializable$has('dataItem')) ?
            (this.Serializable$get('dataItem')) :
            (null));
  }

  /**
   * Conversation data item. Deserialize to ccai.storage.DataItem. Not use Any
   * since the proto is an internal proto.
   */
  set dataItem(value: string|null) {
    this.Serializable$set('dataItem', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * ConversationDataItem resource name. Format:
   * `projects//locations//conversationDatasets//conversationDataItems/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<ConversationDataItem_> {
    return ConversationDataItem_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['dataItem', 'name']};
  }
}

export interface ConversationData_Parameters {
  name?: string|null;
  id?: string|null;
  turns?: Array<ConversationTurn_>|null;
}
export class ConversationData_ extends Serializable {
  constructor(parameters: ConversationData_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'turns', (parameters.turns == null) ? (null) : (parameters.turns));
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Internal. Id of the conversation.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Resource name of the conversation data. Format:
   * `projects//locations//datasets//conversations/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get turns(): Array<ConversationTurn_>|null {
    return (
        (this.Serializable$has('turns')) ? (this.Serializable$get('turns')) :
                                           (null));
  }

  /**
   * The entries in the conversation.
   */
  set turns(value: Array<ConversationTurn_>|null) {
    this.Serializable$set('turns', value);
  }

  getConstructor(): SerializableCtor<ConversationData_> {
    return ConversationData_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'turns': ConversationTurn_},
      keys: ['id', 'name', 'turns']
    };
  }
}

export interface ConversationDataset_Parameters {
  name?: string|null;
  displayName?: string|null;
  description?: string|null;
  createTime?: string|null;
  inputConfig?: InputConfig_|null;
  conversationInfo?: ConversationInfo_|null;
  conversationCount?: string|null;
}
export class ConversationDataset_ extends Serializable {
  constructor(parameters: ConversationDataset_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'inputConfig',
        (parameters.inputConfig == null) ? (null) : (parameters.inputConfig));
    this.Serializable$set(
        'conversationInfo',
        (parameters.conversationInfo == null) ? (null) :
                                                (parameters.conversationInfo));
    this.Serializable$set(
        'conversationCount',
        (parameters.conversationCount == null) ?
            (null) :
            (parameters.conversationCount));
  }

  get conversationCount(): string|null {
    return (
        (this.Serializable$has('conversationCount')) ?
            (this.Serializable$get('conversationCount')) :
            (null));
  }

  /**
   * Output only. The number of conversations this conversation dataset
   * contains.
   */
  set conversationCount(value: string|null) {
    this.Serializable$set('conversationCount', value);
  }

  get conversationInfo(): ConversationInfo_|null {
    return (
        (this.Serializable$has('conversationInfo')) ?
            (this.Serializable$get('conversationInfo')) :
            (null));
  }

  /**
   * Output only. Metadata set during conversation data import.
   */
  set conversationInfo(value: ConversationInfo_|null) {
    this.Serializable$set('conversationInfo', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Creation time of this dataset.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. The description of the dataset. Maximum of 10000 bytes.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The display name of the dataset. Maximum of 64 bytes.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get inputConfig(): InputConfig_|null {
    return (
        (this.Serializable$has('inputConfig')) ?
            (this.Serializable$get('inputConfig')) :
            (null));
  }

  /**
   * Output only. Input configurations set during conversation data import.
   */
  set inputConfig(value: InputConfig_|null) {
    this.Serializable$set('inputConfig', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. ConversationDataset resource name. Format:
   * `projects//conversationDatasets/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<ConversationDataset_> {
    return ConversationDataset_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'conversationCount', 'conversationInfo', 'createTime', 'description',
        'displayName', 'inputConfig', 'name'
      ],
      objects:
          {'conversationInfo': ConversationInfo_, 'inputConfig': InputConfig_}
    };
  }
}

export interface ConversationEvent_Parameters {
  conversation?: string|null;
  type?: ConversationEvent_Type|null;
  errorStatus?: GoogleRpcStatus|null;
  newMessagePayload?: Message_|null;
}
export class ConversationEvent_ extends Serializable {
  constructor(parameters: ConversationEvent_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversation',
        (parameters.conversation == null) ? (null) : (parameters.conversation));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'errorStatus',
        (parameters.errorStatus == null) ? (null) : (parameters.errorStatus));
    this.Serializable$set(
        'newMessagePayload',
        (parameters.newMessagePayload == null) ?
            (null) :
            (parameters.newMessagePayload));
  }

  static get Type(): IConversationEvent_TypeEnum {
    return ConversationEvent_TypeEnum;
  }

  get conversation(): string|null {
    return (
        (this.Serializable$has('conversation')) ?
            (this.Serializable$get('conversation')) :
            (null));
  }

  /**
   * Required. The unique identifier of the conversation this notification
   * refers to. Format: `projects//conversations/`.
   */
  set conversation(value: string|null) {
    this.Serializable$set('conversation', value);
  }

  get errorStatus(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('errorStatus')) ?
            (this.Serializable$get('errorStatus')) :
            (null));
  }

  /**
   * Optional. More detailed information about an error. Only set for type
   * UNRECOVERABLE_ERROR_IN_PHONE_CALL.
   */
  set errorStatus(value: GoogleRpcStatus|null) {
    this.Serializable$set('errorStatus', value);
  }

  get newMessagePayload(): Message_|null {
    return (
        (this.Serializable$has('newMessagePayload')) ?
            (this.Serializable$get('newMessagePayload')) :
            (null));
  }

  /**
   * Payload of NEW_MESSAGE event.
   */
  set newMessagePayload(value: Message_|null) {
    this.Serializable$set('newMessagePayload', value);
  }

  get type(): ConversationEvent_Type|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Required. The type of the event that this notification refers to.
   */
  set type(value: ConversationEvent_Type|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<ConversationEvent_> {
    return ConversationEvent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'type': ConversationEvent_TypeEnum},
      keys: ['conversation', 'errorStatus', 'newMessagePayload', 'type'],
      objects: {'errorStatus': GoogleRpcStatus, 'newMessagePayload': Message_}
    };
  }
}

export interface ConversationGraphGenerationConfigOverviewConfig_Parameters {
  visibleNodesCount?: number|null;
  minVisibleEdgeCount?: number|null;
  mode?: ConversationGraphGenerationConfigOverviewConfig_Mode|null;
}
export class ConversationGraphGenerationConfigOverviewConfig_ extends
    Serializable {
  constructor(
      parameters:
          ConversationGraphGenerationConfigOverviewConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'visibleNodesCount',
        (parameters.visibleNodesCount == null) ?
            (null) :
            (parameters.visibleNodesCount));
    this.Serializable$set(
        'minVisibleEdgeCount',
        (parameters.minVisibleEdgeCount == null) ?
            (null) :
            (parameters.minVisibleEdgeCount));
    this.Serializable$set(
        'mode', (parameters.mode == null) ? (null) : (parameters.mode));
  }

  static get Mode(): IConversationGraphGenerationConfigOverviewConfig_ModeEnum {
    return ConversationGraphGenerationConfigOverviewConfig_ModeEnum;
  }

  get minVisibleEdgeCount(): number|null {
    return (
        (this.Serializable$has('minVisibleEdgeCount')) ?
            (this.Serializable$get('minVisibleEdgeCount')) :
            (null));
  }

  /**
   * Minimum number of edge cout to be visible initially.
   */
  set minVisibleEdgeCount(value: number|null) {
    this.Serializable$set('minVisibleEdgeCount', value);
  }

  get mode(): ConversationGraphGenerationConfigOverviewConfig_Mode|null {
    return (
        (this.Serializable$has('mode')) ? (this.Serializable$get('mode')) :
                                          (null));
  }

  /**
   * Mode (algorighm to use) for edge generation in the overview flow.
   */
  set mode(value: ConversationGraphGenerationConfigOverviewConfig_Mode|null) {
    this.Serializable$set('mode', value);
  }

  get visibleNodesCount(): number|null {
    return (
        (this.Serializable$has('visibleNodesCount')) ?
            (this.Serializable$get('visibleNodesCount')) :
            (null));
  }

  /**
   * Number of nodes visible initially on the graph.
   */
  set visibleNodesCount(value: number|null) {
    this.Serializable$set('visibleNodesCount', value);
  }

  getConstructor():
      SerializableCtor<ConversationGraphGenerationConfigOverviewConfig_> {
    return ConversationGraphGenerationConfigOverviewConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'mode': ConversationGraphGenerationConfigOverviewConfig_ModeEnum},
      keys: ['minVisibleEdgeCount', 'mode', 'visibleNodesCount']
    };
  }
}

export interface ConversationGraphGenerationConfigTurnByTurnConfig_Parameters {
  contextWindowSize?: number|null;
  nestMessages?: boolean|null;
  allowSameRole?: boolean|null;
  allowCurrentRole?: boolean|null;
}
export class ConversationGraphGenerationConfigTurnByTurnConfig_ extends
    Serializable {
  constructor(
      parameters:
          ConversationGraphGenerationConfigTurnByTurnConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'contextWindowSize',
        (parameters.contextWindowSize == null) ?
            (null) :
            (parameters.contextWindowSize));
    this.Serializable$set(
        'nestMessages',
        (parameters.nestMessages == null) ? (null) : (parameters.nestMessages));
    this.Serializable$set(
        'allowSameRole',
        (parameters.allowSameRole == null) ? (null) :
                                             (parameters.allowSameRole));
    this.Serializable$set(
        'allowCurrentRole',
        (parameters.allowCurrentRole == null) ? (null) :
                                                (parameters.allowCurrentRole));
  }

  get allowCurrentRole(): boolean|null {
    return (
        (this.Serializable$has('allowCurrentRole')) ?
            (this.Serializable$get('allowCurrentRole')) :
            (null));
  }

  /**
   * Whether allow edges connecting consecutive messages from the same
   * conversation role.
   */
  set allowCurrentRole(value: boolean|null) {
    this.Serializable$set('allowCurrentRole', value);
  }

  get allowSameRole(): boolean|null {
    return (
        (this.Serializable$has('allowSameRole')) ?
            (this.Serializable$get('allowSameRole')) :
            (null));
  }

  /**
   * Whether allow edges connecting messages from the same conversation role.
   */
  set allowSameRole(value: boolean|null) {
    this.Serializable$set('allowSameRole', value);
  }

  get contextWindowSize(): number|null {
    return (
        (this.Serializable$has('contextWindowSize')) ?
            (this.Serializable$get('contextWindowSize')) :
            (null));
  }

  /**
   * Window size for adding an edge between message nodes from the conversation.
   */
  set contextWindowSize(value: number|null) {
    this.Serializable$set('contextWindowSize', value);
  }

  get nestMessages(): boolean|null {
    return (
        (this.Serializable$has('nestMessages')) ?
            (this.Serializable$get('nestMessages')) :
            (null));
  }

  /**
   * When calculating context window length, whether count multiple messages
   * from the same conversation role as length 1.
   */
  set nestMessages(value: boolean|null) {
    this.Serializable$set('nestMessages', value);
  }

  getConstructor():
      SerializableCtor<ConversationGraphGenerationConfigTurnByTurnConfig_> {
    return ConversationGraphGenerationConfigTurnByTurnConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'allowCurrentRole', 'allowSameRole', 'contextWindowSize', 'nestMessages'
      ]
    };
  }
}

export interface ConversationGraphGenerationConfig_Parameters {
  turnByTurnConfig?: ConversationGraphGenerationConfigTurnByTurnConfig_|null;
  overviewConfig?: ConversationGraphGenerationConfigOverviewConfig_|null;
  roleToKeep?: ConversationGraphGenerationConfig_RoleToKeep|null;
  entropyThreshold?: number|null;
  labelsWhitelist?: MessageLabels_|null;
  addStartNode?: boolean|null;
  addEndNode?: boolean|null;
}
export class ConversationGraphGenerationConfig_ extends Serializable {
  constructor(parameters: ConversationGraphGenerationConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'turnByTurnConfig',
        (parameters.turnByTurnConfig == null) ? (null) :
                                                (parameters.turnByTurnConfig));
    this.Serializable$set(
        'overviewConfig',
        (parameters.overviewConfig == null) ? (null) :
                                              (parameters.overviewConfig));
    this.Serializable$set(
        'roleToKeep',
        (parameters.roleToKeep == null) ? (null) : (parameters.roleToKeep));
    this.Serializable$set(
        'entropyThreshold',
        (parameters.entropyThreshold == null) ? (null) :
                                                (parameters.entropyThreshold));
    this.Serializable$set(
        'labelsWhitelist',
        (parameters.labelsWhitelist == null) ? (null) :
                                               (parameters.labelsWhitelist));
    this.Serializable$set(
        'addStartNode',
        (parameters.addStartNode == null) ? (null) : (parameters.addStartNode));
    this.Serializable$set(
        'addEndNode',
        (parameters.addEndNode == null) ? (null) : (parameters.addEndNode));
  }

  static get RoleToKeep(): IConversationGraphGenerationConfig_RoleToKeepEnum {
    return ConversationGraphGenerationConfig_RoleToKeepEnum;
  }

  get addEndNode(): boolean|null {
    return (
        (this.Serializable$has('addEndNode')) ?
            (this.Serializable$get('addEndNode')) :
            (null));
  }

  /**
   * Whether add a dummy end node in the graph to indicate where the
   * conversation ends.
   */
  set addEndNode(value: boolean|null) {
    this.Serializable$set('addEndNode', value);
  }

  get addStartNode(): boolean|null {
    return (
        (this.Serializable$has('addStartNode')) ?
            (this.Serializable$get('addStartNode')) :
            (null));
  }

  /**
   * Whether add a dummy start node in the graph to indicate where the
   * conversation starts.
   */
  set addStartNode(value: boolean|null) {
    this.Serializable$set('addStartNode', value);
  }

  get entropyThreshold(): number|null {
    return (
        (this.Serializable$has('entropyThreshold')) ?
            (this.Serializable$get('entropyThreshold')) :
            (null));
  }

  /**
   * If specified, messages with entropy score above the threshold will be
   * filtered out.
   */
  set entropyThreshold(value: number|null) {
    this.Serializable$set('entropyThreshold', value);
  }

  get labelsWhitelist(): MessageLabels_|null {
    return (
        (this.Serializable$has('labelsWhitelist')) ?
            (this.Serializable$get('labelsWhitelist')) :
            (null));
  }

  /**
   * If specified, only the nodes with the given labels (and the triggering
   * node) will be kept in the graph.
   */
  set labelsWhitelist(value: MessageLabels_|null) {
    this.Serializable$set('labelsWhitelist', value);
  }

  get overviewConfig(): ConversationGraphGenerationConfigOverviewConfig_|null {
    return (
        (this.Serializable$has('overviewConfig')) ?
            (this.Serializable$get('overviewConfig')) :
            (null));
  }

  /**
   * Config for the overview portion of the graph.
   */
  set overviewConfig(value: ConversationGraphGenerationConfigOverviewConfig_|
                     null) {
    this.Serializable$set('overviewConfig', value);
  }

  get roleToKeep(): ConversationGraphGenerationConfig_RoleToKeep|null {
    return (
        (this.Serializable$has('roleToKeep')) ?
            (this.Serializable$get('roleToKeep')) :
            (null));
  }

  /**
   * If specified, only keep the given role in the edges.
   */
  set roleToKeep(value: ConversationGraphGenerationConfig_RoleToKeep|null) {
    this.Serializable$set('roleToKeep', value);
  }

  get turnByTurnConfig(): ConversationGraphGenerationConfigTurnByTurnConfig_
      |null {
    return (
        (this.Serializable$has('turnByTurnConfig')) ?
            (this.Serializable$get('turnByTurnConfig')) :
            (null));
  }

  /**
   * Config for the turn by turn portion of the graph.
   */
  set turnByTurnConfig(value:
                           ConversationGraphGenerationConfigTurnByTurnConfig_|
                       null) {
    this.Serializable$set('turnByTurnConfig', value);
  }

  getConstructor(): SerializableCtor<ConversationGraphGenerationConfig_> {
    return ConversationGraphGenerationConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'roleToKeep': ConversationGraphGenerationConfig_RoleToKeepEnum},
      keys: [
        'addEndNode', 'addStartNode', 'entropyThreshold', 'labelsWhitelist',
        'overviewConfig', 'roleToKeep', 'turnByTurnConfig'
      ],
      objects: {
        'labelsWhitelist': MessageLabels_,
        'overviewConfig': ConversationGraphGenerationConfigOverviewConfig_,
        'turnByTurnConfig': ConversationGraphGenerationConfigTurnByTurnConfig_
      }
    };
  }
}

export interface ConversationGraph_Parameters {
  name?: string|null;
  nodes?: Array<MessageNode_>|null;
  edges?: Array<MessageEdge_>|null;
}
export class ConversationGraph_ extends Serializable {
  constructor(parameters: ConversationGraph_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'nodes', (parameters.nodes == null) ? (null) : (parameters.nodes));
    this.Serializable$set(
        'edges', (parameters.edges == null) ? (null) : (parameters.edges));
  }

  get edges(): Array<MessageEdge_>|null {
    return (
        (this.Serializable$has('edges')) ? (this.Serializable$get('edges')) :
                                           (null));
  }

  /**
   * A list of edges between message nodes in the graph.
   */
  set edges(value: Array<MessageEdge_>|null) {
    this.Serializable$set('edges', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The resource name of the graph.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get nodes(): Array<MessageNode_>|null {
    return (
        (this.Serializable$has('nodes')) ? (this.Serializable$get('nodes')) :
                                           (null));
  }

  /**
   * A list of message nodes (clusters) in the graph.
   */
  set nodes(value: Array<MessageNode_>|null) {
    this.Serializable$set('nodes', value);
  }

  getConstructor(): SerializableCtor<ConversationGraph_> {
    return ConversationGraph_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'edges': MessageEdge_, 'nodes': MessageNode_},
      keys: ['edges', 'name', 'nodes']
    };
  }
}

export interface ConversationGuidanceAnswer_Parameters {
  stage?: string|null;
  stageGuidances?: Array<string>|null;
  answerRecord?: string|null;
}
export class ConversationGuidanceAnswer_ extends Serializable {
  constructor(parameters: ConversationGuidanceAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'stage', (parameters.stage == null) ? (null) : (parameters.stage));
    this.Serializable$set(
        'stageGuidances',
        (parameters.stageGuidances == null) ? (null) :
                                              (parameters.stageGuidances));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get stage(): string|null {
    return (
        (this.Serializable$has('stage')) ? (this.Serializable$get('stage')) :
                                           (null));
  }

  /**
   * The predicted stage. This field represents the name of the stage.
   */
  set stage(value: string|null) {
    this.Serializable$set('stage', value);
  }

  get stageGuidances(): Array<string>|null {
    return (
        (this.Serializable$has('stageGuidances')) ?
            (this.Serializable$get('stageGuidances')) :
            (null));
  }

  /**
   * The stage guidances. Stage guidance contains a few sentences that tell the
   * agents what they should do in current stage. One stage may have several sub
   * stage guidances.
   */
  set stageGuidances(value: Array<string>|null) {
    this.Serializable$set('stageGuidances', value);
  }

  getConstructor(): SerializableCtor<ConversationGuidanceAnswer_> {
    return ConversationGuidanceAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['answerRecord', 'stage', 'stageGuidances']};
  }
}

export interface ConversationGuidanceModelMetadataStageGroup_Parameters {
  displayName?: string|null;
  stages?: Array<ConversationGuidanceModelMetadataStage_>|null;
}
export class ConversationGuidanceModelMetadataStageGroup_ extends Serializable {
  constructor(
      parameters: ConversationGuidanceModelMetadataStageGroup_Parameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'stages', (parameters.stages == null) ? (null) : (parameters.stages));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Human readable name of stage group.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get stages(): Array<ConversationGuidanceModelMetadataStage_>|null {
    return (
        (this.Serializable$has('stages')) ? (this.Serializable$get('stages')) :
                                            (null));
  }

  /**
   * The stages in this stage group.
   */
  set stages(value: Array<ConversationGuidanceModelMetadataStage_>|null) {
    this.Serializable$set('stages', value);
  }

  getConstructor():
      SerializableCtor<ConversationGuidanceModelMetadataStageGroup_> {
    return ConversationGuidanceModelMetadataStageGroup_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'stages': ConversationGuidanceModelMetadataStage_},
      keys: ['displayName', 'stages']
    };
  }
}

export interface ConversationGuidanceModelMetadataStage_Parameters {
  displayName?: string|null;
  stageGuidances?: Array<string>|null;
  stageTransitionHint?: string|null;
}
export class ConversationGuidanceModelMetadataStage_ extends Serializable {
  constructor(
      parameters: ConversationGuidanceModelMetadataStage_Parameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'stageGuidances',
        (parameters.stageGuidances == null) ? (null) :
                                              (parameters.stageGuidances));
    this.Serializable$set(
        'stageTransitionHint',
        (parameters.stageTransitionHint == null) ?
            (null) :
            (parameters.stageTransitionHint));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * The human readable name of this stage.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get stageGuidances(): Array<string>|null {
    return (
        (this.Serializable$has('stageGuidances')) ?
            (this.Serializable$get('stageGuidances')) :
            (null));
  }

  /**
   * The stage guidances. Stage guidance contains a few sentences that tell the
   * agents what they should do in the current stage. One stage may have several
   * sub stage guidances.
   */
  set stageGuidances(value: Array<string>|null) {
    this.Serializable$set('stageGuidances', value);
  }

  get stageTransitionHint(): string|null {
    return (
        (this.Serializable$has('stageTransitionHint')) ?
            (this.Serializable$get('stageTransitionHint')) :
            (null));
  }

  /**
   * The hint for transition to next stage.
   */
  set stageTransitionHint(value: string|null) {
    this.Serializable$set('stageTransitionHint', value);
  }

  getConstructor(): SerializableCtor<ConversationGuidanceModelMetadataStage_> {
    return ConversationGuidanceModelMetadataStage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'stageGuidances', 'stageTransitionHint']};
  }
}

export interface ConversationGuidanceModelMetadata_Parameters {
  trainingModelType?: ConversationGuidanceModelMetadata_TrainingModelType|null;
  stageGroups?: Array<ConversationGuidanceModelMetadataStageGroup_>|null;
}
export class ConversationGuidanceModelMetadata_ extends Serializable {
  constructor(parameters: ConversationGuidanceModelMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
    this.Serializable$set(
        'stageGroups',
        (parameters.stageGroups == null) ? (null) : (parameters.stageGroups));
  }

  static get TrainingModelType():
      IConversationGuidanceModelMetadata_TrainingModelTypeEnum {
    return ConversationGuidanceModelMetadata_TrainingModelTypeEnum;
  }

  get stageGroups(): Array<ConversationGuidanceModelMetadataStageGroup_>|null {
    return (
        (this.Serializable$has('stageGroups')) ?
            (this.Serializable$get('stageGroups')) :
            (null));
  }

  /**
   * Ordered list of all stage groups.
   */
  set stageGroups(value: Array<ConversationGuidanceModelMetadataStageGroup_>|
                  null) {
    this.Serializable$set('stageGroups', value);
  }

  get trainingModelType(): ConversationGuidanceModelMetadata_TrainingModelType
      |null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Type of the conversation guidance model.
   */
  set trainingModelType(value:
                            ConversationGuidanceModelMetadata_TrainingModelType|
                        null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor(): SerializableCtor<ConversationGuidanceModelMetadata_> {
    return ConversationGuidanceModelMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'stageGroups': ConversationGuidanceModelMetadataStageGroup_},
      enums: {
        'trainingModelType':
            ConversationGuidanceModelMetadata_TrainingModelTypeEnum
      },
      keys: ['stageGroups', 'trainingModelType']
    };
  }
}

export interface ConversationInfo_Parameters {
  languageCode?: string|null;
  regionCode?: string|null;
  conversationStartTime?: string|null;
  customFields?: ApiClientObjectMap<any>|null;
}
export class ConversationInfo_ extends Serializable {
  constructor(parameters: ConversationInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'regionCode',
        (parameters.regionCode == null) ? (null) : (parameters.regionCode));
    this.Serializable$set(
        'conversationStartTime',
        (parameters.conversationStartTime == null) ?
            (null) :
            (parameters.conversationStartTime));
    this.Serializable$set(
        'customFields',
        (parameters.customFields == null) ? (null) : (parameters.customFields));
  }

  get conversationStartTime(): string|null {
    return (
        (this.Serializable$has('conversationStartTime')) ?
            (this.Serializable$get('conversationStartTime')) :
            (null));
  }

  /**
   * Optional. The timestamp that user sets to represent what time range this
   * dataset belongs to.
   */
  set conversationStartTime(value: string|null) {
    this.Serializable$set('conversationStartTime', value);
  }

  get customFields(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('customFields')) ?
            (this.Serializable$get('customFields')) :
            (null));
  }

  /**
   * Optional. The field reserved for customers to provide their customized
   * metadata. For example, agent id, contact center id, and topic tag, etc.
   * These metadata will be used for filtering conversation dataset.
   */
  set customFields(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('customFields', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The language code of the conversation. See
   * https://cloud.google.com/apis/design/standard_fields
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get regionCode(): string|null {
    return (
        (this.Serializable$has('regionCode')) ?
            (this.Serializable$get('regionCode')) :
            (null));
  }

  /**
   * Optional. Deprecated. The region code of the conversation. See
   * https://cloud.google.com/apis/design/standard_fields Deprecated in Q3 2021.
   * Reason: the field is useless and ambiguous regarding regionalization
   * support.
   */
  set regionCode(value: string|null) {
    this.Serializable$set('regionCode', value);
  }

  getConstructor(): SerializableCtor<ConversationInfo_> {
    return ConversationInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'conversationStartTime', 'customFields', 'languageCode', 'regionCode'
      ],
      objectMaps: {
        'customFields': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface ConversationModelEvaluation_Parameters {
  name?: string|null;
  displayName?: string|null;
  evaluationConfig?: EvaluationConfig_|null;
  createTime?: string|null;
  articleSuggestionMetrics?: ArticleSuggestionMetrics_|null;
  smartReplyMetrics?: SmartReplyMetrics_|null;
  smartComposeMetrics?: SmartComposeMetrics_|null;
  summarizationMetrics?: SummarizationMetrics_|null;
  rawHumanEvalTemplateCsv?: string|null;
}
export class ConversationModelEvaluation_ extends Serializable {
  constructor(parameters: ConversationModelEvaluation_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'evaluationConfig',
        (parameters.evaluationConfig == null) ? (null) :
                                                (parameters.evaluationConfig));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'articleSuggestionMetrics',
        (parameters.articleSuggestionMetrics == null) ?
            (null) :
            (parameters.articleSuggestionMetrics));
    this.Serializable$set(
        'smartReplyMetrics',
        (parameters.smartReplyMetrics == null) ?
            (null) :
            (parameters.smartReplyMetrics));
    this.Serializable$set(
        'smartComposeMetrics',
        (parameters.smartComposeMetrics == null) ?
            (null) :
            (parameters.smartComposeMetrics));
    this.Serializable$set(
        'summarizationMetrics',
        (parameters.summarizationMetrics == null) ?
            (null) :
            (parameters.summarizationMetrics));
    this.Serializable$set(
        'rawHumanEvalTemplateCsv',
        (parameters.rawHumanEvalTemplateCsv == null) ?
            (null) :
            (parameters.rawHumanEvalTemplateCsv));
  }

  get articleSuggestionMetrics(): ArticleSuggestionMetrics_|null {
    return (
        (this.Serializable$has('articleSuggestionMetrics')) ?
            (this.Serializable$get('articleSuggestionMetrics')) :
            (null));
  }

  /**
   * Output only. Only available when model is for article suggestion.
   */
  set articleSuggestionMetrics(value: ArticleSuggestionMetrics_|null) {
    this.Serializable$set('articleSuggestionMetrics', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Creation time of this model.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Optional. The display name of the model evaluation. At most 64 bytes long.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get evaluationConfig(): EvaluationConfig_|null {
    return (
        (this.Serializable$has('evaluationConfig')) ?
            (this.Serializable$get('evaluationConfig')) :
            (null));
  }

  /**
   * Optional. The configuration of the evaluation task.
   */
  set evaluationConfig(value: EvaluationConfig_|null) {
    this.Serializable$set('evaluationConfig', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The resource name of the evaluation. Format:
   * `projects//conversationModels//evaluations/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get rawHumanEvalTemplateCsv(): string|null {
    return (
        (this.Serializable$has('rawHumanEvalTemplateCsv')) ?
            (this.Serializable$get('rawHumanEvalTemplateCsv')) :
            (null));
  }

  /**
   * Output only. Human eval template in csv format. It tooks real-world
   * conversations provided through input dataset, generates example suggestions
   * for customer to verify quality of the model. For Smart Reply, the generated
   * csv file contains columns of Context, (Suggestions,Q1,Q2)*3, Actual reply.
   * Context contains at most 10 latest messages in the conversation prior to
   * the current suggestion. Q1: \"Would you send it as the next message of
   * agent?\" Evaluated based on whether the suggest is appropriate to be sent
   * by agent in current context. Q2: \"Does the suggestion move the
   * conversation closer to resolution?\" Evaluated based on whether the
   * suggestion provide solutions, or answers customer's question or collect
   * information from customer to resolve the customer's issue. Actual reply
   * column contains the actual agent reply sent in the context.
   */
  set rawHumanEvalTemplateCsv(value: string|null) {
    this.Serializable$set('rawHumanEvalTemplateCsv', value);
  }

  get smartComposeMetrics(): SmartComposeMetrics_|null {
    return (
        (this.Serializable$has('smartComposeMetrics')) ?
            (this.Serializable$get('smartComposeMetrics')) :
            (null));
  }

  /**
   * Output only. Only available when model is for smart compose.
   */
  set smartComposeMetrics(value: SmartComposeMetrics_|null) {
    this.Serializable$set('smartComposeMetrics', value);
  }

  get smartReplyMetrics(): SmartReplyMetrics_|null {
    return (
        (this.Serializable$has('smartReplyMetrics')) ?
            (this.Serializable$get('smartReplyMetrics')) :
            (null));
  }

  /**
   * Output only. Only available when model is for smart reply.
   */
  set smartReplyMetrics(value: SmartReplyMetrics_|null) {
    this.Serializable$set('smartReplyMetrics', value);
  }

  get summarizationMetrics(): SummarizationMetrics_|null {
    return (
        (this.Serializable$has('summarizationMetrics')) ?
            (this.Serializable$get('summarizationMetrics')) :
            (null));
  }

  /**
   * Output only. Only available when model is for summarization.
   */
  set summarizationMetrics(value: SummarizationMetrics_|null) {
    this.Serializable$set('summarizationMetrics', value);
  }

  getConstructor(): SerializableCtor<ConversationModelEvaluation_> {
    return ConversationModelEvaluation_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'articleSuggestionMetrics', 'createTime', 'displayName',
        'evaluationConfig', 'name', 'rawHumanEvalTemplateCsv',
        'smartComposeMetrics', 'smartReplyMetrics', 'summarizationMetrics'
      ],
      objects: {
        'articleSuggestionMetrics': ArticleSuggestionMetrics_,
        'evaluationConfig': EvaluationConfig_,
        'smartComposeMetrics': SmartComposeMetrics_,
        'smartReplyMetrics': SmartReplyMetrics_,
        'summarizationMetrics': SummarizationMetrics_
      }
    };
  }
}

export interface ConversationModelSuggestionList_Parameters {
  model?: string|null;
  document?: string|null;
  knowledgeBase?: string|null;
  linkDatasetType?: ConversationModelSuggestionList_LinkDatasetType|null;
  createTime?: string|null;
  encodeDocumentOperation?: string|null;
  encodeKnowledgeBaseOperation?: string|null;
  smartReplyDiversificationThreshold?: number|null;
  readinessState?: ConversationModelSuggestionList_ReadinessState|null;
}
export class ConversationModelSuggestionList_ extends Serializable {
  constructor(parameters: ConversationModelSuggestionList_Parameters = {}) {
    super();
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'knowledgeBase',
        (parameters.knowledgeBase == null) ? (null) :
                                             (parameters.knowledgeBase));
    this.Serializable$set(
        'linkDatasetType',
        (parameters.linkDatasetType == null) ? (null) :
                                               (parameters.linkDatasetType));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'encodeDocumentOperation',
        (parameters.encodeDocumentOperation == null) ?
            (null) :
            (parameters.encodeDocumentOperation));
    this.Serializable$set(
        'encodeKnowledgeBaseOperation',
        (parameters.encodeKnowledgeBaseOperation == null) ?
            (null) :
            (parameters.encodeKnowledgeBaseOperation));
    this.Serializable$set(
        'smartReplyDiversificationThreshold',
        (parameters.smartReplyDiversificationThreshold == null) ?
            (null) :
            (parameters.smartReplyDiversificationThreshold));
    this.Serializable$set(
        'readinessState',
        (parameters.readinessState == null) ? (null) :
                                              (parameters.readinessState));
  }

  static get LinkDatasetType():
      IConversationModelSuggestionList_LinkDatasetTypeEnum {
    return ConversationModelSuggestionList_LinkDatasetTypeEnum;
  }

  static get ReadinessState():
      IConversationModelSuggestionList_ReadinessStateEnum {
    return ConversationModelSuggestionList_ReadinessStateEnum;
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Creation time of this link.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * For Smart Reply and Issue Modeling, the suggestion list is a document.
   * Format: `projects//locations//knowledgeBases//documents/`.
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  get encodeDocumentOperation(): string|null {
    return (
        (this.Serializable$has('encodeDocumentOperation')) ?
            (this.Serializable$get('encodeDocumentOperation')) :
            (null));
  }

  /**
   * The long-running operation name for the task to encode document in the
   * knowledge service.
   */
  set encodeDocumentOperation(value: string|null) {
    this.Serializable$set('encodeDocumentOperation', value);
  }

  get encodeKnowledgeBaseOperation(): string|null {
    return (
        (this.Serializable$has('encodeKnowledgeBaseOperation')) ?
            (this.Serializable$get('encodeKnowledgeBaseOperation')) :
            (null));
  }

  /**
   * The long-running operation name for the task to encode knowledge base in
   * the knowledge service.
   */
  set encodeKnowledgeBaseOperation(value: string|null) {
    this.Serializable$set('encodeKnowledgeBaseOperation', value);
  }

  get knowledgeBase(): string|null {
    return (
        (this.Serializable$has('knowledgeBase')) ?
            (this.Serializable$get('knowledgeBase')) :
            (null));
  }

  /**
   * For Article suggestion, the suggestion list is a document. Format:
   * `projects//locations//knowledgeBases/`.
   */
  set knowledgeBase(value: string|null) {
    this.Serializable$set('knowledgeBase', value);
  }

  get linkDatasetType(): ConversationModelSuggestionList_LinkDatasetType|null {
    return (
        (this.Serializable$has('linkDatasetType')) ?
            (this.Serializable$get('linkDatasetType')) :
            (null));
  }

  /**
   * Dataset type for the suggestion link.
   */
  set linkDatasetType(value: ConversationModelSuggestionList_LinkDatasetType|
                      null) {
    this.Serializable$set('linkDatasetType', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * The conversation model the link is attached. Format:
   * `projects//locations//conversationModels/`.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  get readinessState(): ConversationModelSuggestionList_ReadinessState|null {
    return (
        (this.Serializable$has('readinessState')) ?
            (this.Serializable$get('readinessState')) :
            (null));
  }

  /**
   * State of the link for serving.
   */
  set readinessState(value: ConversationModelSuggestionList_ReadinessState|
                     null) {
    this.Serializable$set('readinessState', value);
  }

  get smartReplyDiversificationThreshold(): number|null {
    return (
        (this.Serializable$has('smartReplyDiversificationThreshold')) ?
            (this.Serializable$get('smartReplyDiversificationThreshold')) :
            (null));
  }

  /**
   * The threshold used for smart reply diversification. It is 0.0 by default
   * which means pre-defined diversification threshold is used.
   */
  set smartReplyDiversificationThreshold(value: number|null) {
    this.Serializable$set('smartReplyDiversificationThreshold', value);
  }

  getConstructor(): SerializableCtor<ConversationModelSuggestionList_> {
    return ConversationModelSuggestionList_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'linkDatasetType': ConversationModelSuggestionList_LinkDatasetTypeEnum,
        'readinessState': ConversationModelSuggestionList_ReadinessStateEnum
      },
      keys: [
        'createTime', 'document', 'encodeDocumentOperation',
        'encodeKnowledgeBaseOperation', 'knowledgeBase', 'linkDatasetType',
        'model', 'readinessState', 'smartReplyDiversificationThreshold'
      ]
    };
  }
}

export interface ConversationModel_Parameters {
  name?: string|null;
  displayName?: string|null;
  createTime?: string|null;
  datasets?: Array<InputDataset_>|null;
  state?: ConversationModel_State|null;
  languageCode?: string|null;
  articleSuggestionModelMetadata?: ArticleSuggestionModelMetadata_|null;
  smartReplyModelMetadata?: SmartReplyModelMetadata_|null;
  smartComposeModelMetadata?: SmartComposeModelMetadata_|null;
  summarizationModelMetadata?: SummarizationModelMetadata_|null;
  keyMomentModelMetadata?: KeyMomentModelMetadata_|null;
  issueModelMetadata?: IssueModelMetadata_|null;
  conversationGuidanceModelMetadata?: ConversationGuidanceModelMetadata_|null;
  customHeadIntentSuggestionModelMetadata?:
      CustomHeadIntentSuggestionModelMetadata_|null;
  groupName?: string|null;
}
export class ConversationModel_ extends Serializable {
  constructor(parameters: ConversationModel_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'datasets',
        (parameters.datasets == null) ? (null) : (parameters.datasets));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'articleSuggestionModelMetadata',
        (parameters.articleSuggestionModelMetadata == null) ?
            (null) :
            (parameters.articleSuggestionModelMetadata));
    this.Serializable$set(
        'smartReplyModelMetadata',
        (parameters.smartReplyModelMetadata == null) ?
            (null) :
            (parameters.smartReplyModelMetadata));
    this.Serializable$set(
        'smartComposeModelMetadata',
        (parameters.smartComposeModelMetadata == null) ?
            (null) :
            (parameters.smartComposeModelMetadata));
    this.Serializable$set(
        'summarizationModelMetadata',
        (parameters.summarizationModelMetadata == null) ?
            (null) :
            (parameters.summarizationModelMetadata));
    this.Serializable$set(
        'keyMomentModelMetadata',
        (parameters.keyMomentModelMetadata == null) ?
            (null) :
            (parameters.keyMomentModelMetadata));
    this.Serializable$set(
        'issueModelMetadata',
        (parameters.issueModelMetadata == null) ?
            (null) :
            (parameters.issueModelMetadata));
    this.Serializable$set(
        'conversationGuidanceModelMetadata',
        (parameters.conversationGuidanceModelMetadata == null) ?
            (null) :
            (parameters.conversationGuidanceModelMetadata));
    this.Serializable$set(
        'customHeadIntentSuggestionModelMetadata',
        (parameters.customHeadIntentSuggestionModelMetadata == null) ?
            (null) :
            (parameters.customHeadIntentSuggestionModelMetadata));
    this.Serializable$set(
        'groupName',
        (parameters.groupName == null) ? (null) : (parameters.groupName));
  }

  static get State(): IConversationModel_StateEnum {
    return ConversationModel_StateEnum;
  }

  get articleSuggestionModelMetadata(): ArticleSuggestionModelMetadata_|null {
    return (
        (this.Serializable$has('articleSuggestionModelMetadata')) ?
            (this.Serializable$get('articleSuggestionModelMetadata')) :
            (null));
  }

  /**
   * Metadata for article suggestion models.
   */
  set articleSuggestionModelMetadata(value: ArticleSuggestionModelMetadata_|
                                     null) {
    this.Serializable$set('articleSuggestionModelMetadata', value);
  }

  get conversationGuidanceModelMetadata(): ConversationGuidanceModelMetadata_
      |null {
    return (
        (this.Serializable$has('conversationGuidanceModelMetadata')) ?
            (this.Serializable$get('conversationGuidanceModelMetadata')) :
            (null));
  }

  /**
   * Metadata for conversation guidance models.
   */
  set conversationGuidanceModelMetadata(value:
                                            ConversationGuidanceModelMetadata_|
                                        null) {
    this.Serializable$set('conversationGuidanceModelMetadata', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Creation time of this model.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get customHeadIntentSuggestionModelMetadata():
      CustomHeadIntentSuggestionModelMetadata_|null {
    return (
        (this.Serializable$has('customHeadIntentSuggestionModelMetadata')) ?
            (this.Serializable$get('customHeadIntentSuggestionModelMetadata')) :
            (null));
  }

  /**
   * Metadata for custom head intent suggestion models.
   */
  set customHeadIntentSuggestionModelMetadata(
      value: CustomHeadIntentSuggestionModelMetadata_|null) {
    this.Serializable$set('customHeadIntentSuggestionModelMetadata', value);
  }

  get datasets(): Array<InputDataset_>|null {
    return (
        (this.Serializable$has('datasets')) ?
            (this.Serializable$get('datasets')) :
            (null));
  }

  /**
   * Required. Datasets used to create model.
   */
  set datasets(value: Array<InputDataset_>|null) {
    this.Serializable$set('datasets', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The display name of the model. At most 64 bytes long.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get groupName(): string|null {
    return (
        (this.Serializable$has('groupName')) ?
            (this.Serializable$get('groupName')) :
            (null));
  }

  /**
   * Optional. Name of the group which this model belongs to. This is used
   * internally for grouping conversation models in agent assist console.
   */
  set groupName(value: string|null) {
    this.Serializable$set('groupName', value);
  }

  get issueModelMetadata(): IssueModelMetadata_|null {
    return (
        (this.Serializable$has('issueModelMetadata')) ?
            (this.Serializable$get('issueModelMetadata')) :
            (null));
  }

  /**
   * Metadata for issue models.
   */
  set issueModelMetadata(value: IssueModelMetadata_|null) {
    this.Serializable$set('issueModelMetadata', value);
  }

  get keyMomentModelMetadata(): KeyMomentModelMetadata_|null {
    return (
        (this.Serializable$has('keyMomentModelMetadata')) ?
            (this.Serializable$get('keyMomentModelMetadata')) :
            (null));
  }

  /**
   * Metadata for key moment models.
   */
  set keyMomentModelMetadata(value: KeyMomentModelMetadata_|null) {
    this.Serializable$set('keyMomentModelMetadata', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Language code for the conversation model. If not specified, the language is
   * en-US. Language at ConversationModel should be set for all non en-us
   * languages. This should be a
   * [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt) language tag.
   * Example: \"en-US\".
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * ConversationModel resource name. Format: `projects//conversationModels/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get smartComposeModelMetadata(): SmartComposeModelMetadata_|null {
    return (
        (this.Serializable$has('smartComposeModelMetadata')) ?
            (this.Serializable$get('smartComposeModelMetadata')) :
            (null));
  }

  /**
   * Metadata for smart compose models.
   */
  set smartComposeModelMetadata(value: SmartComposeModelMetadata_|null) {
    this.Serializable$set('smartComposeModelMetadata', value);
  }

  get smartReplyModelMetadata(): SmartReplyModelMetadata_|null {
    return (
        (this.Serializable$has('smartReplyModelMetadata')) ?
            (this.Serializable$get('smartReplyModelMetadata')) :
            (null));
  }

  /**
   * Metadata for smart reply models.
   */
  set smartReplyModelMetadata(value: SmartReplyModelMetadata_|null) {
    this.Serializable$set('smartReplyModelMetadata', value);
  }

  get state(): ConversationModel_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Output only. State of the model. A model can only serve prediction requests
   * after it gets deployed.
   */
  set state(value: ConversationModel_State|null) {
    this.Serializable$set('state', value);
  }

  get summarizationModelMetadata(): SummarizationModelMetadata_|null {
    return (
        (this.Serializable$has('summarizationModelMetadata')) ?
            (this.Serializable$get('summarizationModelMetadata')) :
            (null));
  }

  /**
   * Metadata for summarization models.
   */
  set summarizationModelMetadata(value: SummarizationModelMetadata_|null) {
    this.Serializable$set('summarizationModelMetadata', value);
  }

  getConstructor(): SerializableCtor<ConversationModel_> {
    return ConversationModel_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'datasets': InputDataset_},
      enums: {'state': ConversationModel_StateEnum},
      keys: [
        'articleSuggestionModelMetadata', 'conversationGuidanceModelMetadata',
        'createTime', 'customHeadIntentSuggestionModelMetadata', 'datasets',
        'displayName', 'groupName', 'issueModelMetadata',
        'keyMomentModelMetadata', 'languageCode', 'name',
        'smartComposeModelMetadata', 'smartReplyModelMetadata', 'state',
        'summarizationModelMetadata'
      ],
      objects: {
        'articleSuggestionModelMetadata': ArticleSuggestionModelMetadata_,
        'conversationGuidanceModelMetadata': ConversationGuidanceModelMetadata_,
        'customHeadIntentSuggestionModelMetadata':
            CustomHeadIntentSuggestionModelMetadata_,
        'issueModelMetadata': IssueModelMetadata_,
        'keyMomentModelMetadata': KeyMomentModelMetadata_,
        'smartComposeModelMetadata': SmartComposeModelMetadata_,
        'smartReplyModelMetadata': SmartReplyModelMetadata_,
        'summarizationModelMetadata': SummarizationModelMetadata_
      }
    };
  }
}

export interface ConversationParticipant_Parameters {
  dialogflowParticipantName?: string|null;
  userId?: string|null;
  obfuscatedExternalUserId?: string|null;
  role?: ConversationParticipant_Role|null;
}
export class ConversationParticipant_ extends Serializable {
  constructor(parameters: ConversationParticipant_Parameters = {}) {
    super();
    this.Serializable$set(
        'dialogflowParticipantName',
        (parameters.dialogflowParticipantName == null) ?
            (null) :
            (parameters.dialogflowParticipantName));
    this.Serializable$set(
        'userId', (parameters.userId == null) ? (null) : (parameters.userId));
    this.Serializable$set(
        'obfuscatedExternalUserId',
        (parameters.obfuscatedExternalUserId == null) ?
            (null) :
            (parameters.obfuscatedExternalUserId));
    this.Serializable$set(
        'role', (parameters.role == null) ? (null) : (parameters.role));
  }

  static get Role(): IConversationParticipant_RoleEnum {
    return ConversationParticipant_RoleEnum;
  }

  get dialogflowParticipantName(): string|null {
    return (
        (this.Serializable$has('dialogflowParticipantName')) ?
            (this.Serializable$get('dialogflowParticipantName')) :
            (null));
  }

  /**
   * The name of the participant provided by Dialogflow. Format:
   * projects/{project}/locations/{location}/conversations/{conversation}/participants/{participant}
   */
  set dialogflowParticipantName(value: string|null) {
    this.Serializable$set('dialogflowParticipantName', value);
  }

  get obfuscatedExternalUserId(): string|null {
    return (
        (this.Serializable$has('obfuscatedExternalUserId')) ?
            (this.Serializable$get('obfuscatedExternalUserId')) :
            (null));
  }

  /**
   * Obfuscated user ID from Dialogflow.
   */
  set obfuscatedExternalUserId(value: string|null) {
    this.Serializable$set('obfuscatedExternalUserId', value);
  }

  get role(): ConversationParticipant_Role|null {
    return (
        (this.Serializable$has('role')) ? (this.Serializable$get('role')) :
                                          (null));
  }

  /**
   * The role of the participant.
   */
  set role(value: ConversationParticipant_Role|null) {
    this.Serializable$set('role', value);
  }

  get userId(): string|null {
    return (
        (this.Serializable$has('userId')) ? (this.Serializable$get('userId')) :
                                            (null));
  }

  /**
   * A user-specified ID representing the participant.
   */
  set userId(value: string|null) {
    this.Serializable$set('userId', value);
  }

  getConstructor(): SerializableCtor<ConversationParticipant_> {
    return ConversationParticipant_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'role': ConversationParticipant_RoleEnum},
      keys: [
        'dialogflowParticipantName', 'obfuscatedExternalUserId', 'role',
        'userId'
      ]
    };
  }
}

export interface ConversationPhoneNumber_Parameters {
  countryCode?: number|null;
  phoneNumber?: string|null;
}
export class ConversationPhoneNumber_ extends Serializable {
  constructor(parameters: ConversationPhoneNumber_Parameters = {}) {
    super();
    this.Serializable$set(
        'countryCode',
        (parameters.countryCode == null) ? (null) : (parameters.countryCode));
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
  }

  get countryCode(): number|null {
    return (
        (this.Serializable$has('countryCode')) ?
            (this.Serializable$get('countryCode')) :
            (null));
  }

  /**
   * Output only. Desired country code for the phone number.
   */
  set countryCode(value: number|null) {
    this.Serializable$set('countryCode', value);
  }

  get phoneNumber(): string|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Output only. The phone number to connect to this conversation.
   */
  set phoneNumber(value: string|null) {
    this.Serializable$set('phoneNumber', value);
  }

  getConstructor(): SerializableCtor<ConversationPhoneNumber_> {
    return ConversationPhoneNumber_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['countryCode', 'phoneNumber']};
  }
}

export interface ConversationProfile_Parameters {
  name?: string|null;
  displayName?: string|null;
  createTime?: string|null;
  updateTime?: string|null;
  automatedAgentConfig?: AutomatedAgentConfig_|null;
  humanAgentAssistantConfig?: HumanAgentAssistantConfig_|null;
  humanAgentHandoffConfig?: HumanAgentHandoffConfig_|null;
  notificationConfig?: NotificationConfig_|null;
  loggingConfig?: LoggingConfig_|null;
  newMessageEventNotificationConfig?: NotificationConfig_|null;
  sttConfig?: SpeechToTextConfig_|null;
  languageCode?: string|null;
  telephonySttConfig?: TelephonySttConfig_|null;
  sipConfig?: SipConfig_|null;
  timeZone?: string|null;
  securitySettings?: string|null;
  projectNumber?: string|null;
  ttsConfig?: SynthesizeSpeechConfig_|null;
}
export class ConversationProfile_ extends Serializable {
  constructor(parameters: ConversationProfile_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'automatedAgentConfig',
        (parameters.automatedAgentConfig == null) ?
            (null) :
            (parameters.automatedAgentConfig));
    this.Serializable$set(
        'humanAgentAssistantConfig',
        (parameters.humanAgentAssistantConfig == null) ?
            (null) :
            (parameters.humanAgentAssistantConfig));
    this.Serializable$set(
        'humanAgentHandoffConfig',
        (parameters.humanAgentHandoffConfig == null) ?
            (null) :
            (parameters.humanAgentHandoffConfig));
    this.Serializable$set(
        'notificationConfig',
        (parameters.notificationConfig == null) ?
            (null) :
            (parameters.notificationConfig));
    this.Serializable$set(
        'loggingConfig',
        (parameters.loggingConfig == null) ? (null) :
                                             (parameters.loggingConfig));
    this.Serializable$set(
        'newMessageEventNotificationConfig',
        (parameters.newMessageEventNotificationConfig == null) ?
            (null) :
            (parameters.newMessageEventNotificationConfig));
    this.Serializable$set(
        'sttConfig',
        (parameters.sttConfig == null) ? (null) : (parameters.sttConfig));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'telephonySttConfig',
        (parameters.telephonySttConfig == null) ?
            (null) :
            (parameters.telephonySttConfig));
    this.Serializable$set(
        'sipConfig',
        (parameters.sipConfig == null) ? (null) : (parameters.sipConfig));
    this.Serializable$set(
        'timeZone',
        (parameters.timeZone == null) ? (null) : (parameters.timeZone));
    this.Serializable$set(
        'securitySettings',
        (parameters.securitySettings == null) ? (null) :
                                                (parameters.securitySettings));
    this.Serializable$set(
        'projectNumber',
        (parameters.projectNumber == null) ? (null) :
                                             (parameters.projectNumber));
    this.Serializable$set(
        'ttsConfig',
        (parameters.ttsConfig == null) ? (null) : (parameters.ttsConfig));
  }

  get automatedAgentConfig(): AutomatedAgentConfig_|null {
    return (
        (this.Serializable$has('automatedAgentConfig')) ?
            (this.Serializable$get('automatedAgentConfig')) :
            (null));
  }

  /**
   * Configuration for an automated agent to use with this profile.
   */
  set automatedAgentConfig(value: AutomatedAgentConfig_|null) {
    this.Serializable$set('automatedAgentConfig', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Create time of the conversation profile.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. Human readable name for this profile. Max length 1024 bytes.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get humanAgentAssistantConfig(): HumanAgentAssistantConfig_|null {
    return (
        (this.Serializable$has('humanAgentAssistantConfig')) ?
            (this.Serializable$get('humanAgentAssistantConfig')) :
            (null));
  }

  /**
   * Configuration for agent assistance to use with this profile.
   */
  set humanAgentAssistantConfig(value: HumanAgentAssistantConfig_|null) {
    this.Serializable$set('humanAgentAssistantConfig', value);
  }

  get humanAgentHandoffConfig(): HumanAgentHandoffConfig_|null {
    return (
        (this.Serializable$has('humanAgentHandoffConfig')) ?
            (this.Serializable$get('humanAgentHandoffConfig')) :
            (null));
  }

  /**
   * Configuration for connecting to a live agent. Currently, this feature is
   * not general available, please contact Google to get access.
   */
  set humanAgentHandoffConfig(value: HumanAgentHandoffConfig_|null) {
    this.Serializable$set('humanAgentHandoffConfig', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Language code for the conversation profile. If not specified, the language
   * is en-US. Language at ConversationProfile should be set for all non en-us
   * languages. This should be a
   * [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt) language tag.
   * Example: \"en-US\".
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get loggingConfig(): LoggingConfig_|null {
    return (
        (this.Serializable$has('loggingConfig')) ?
            (this.Serializable$get('loggingConfig')) :
            (null));
  }

  /**
   * Configuration for logging conversation lifecycle events.
   */
  set loggingConfig(value: LoggingConfig_|null) {
    this.Serializable$set('loggingConfig', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of this conversation profile. Format:
   * `projects//locations//conversationProfiles/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get newMessageEventNotificationConfig(): NotificationConfig_|null {
    return (
        (this.Serializable$has('newMessageEventNotificationConfig')) ?
            (this.Serializable$get('newMessageEventNotificationConfig')) :
            (null));
  }

  /**
   * Configuration for publishing new message events. Event will be sent in
   * format of ConversationEvent
   */
  set newMessageEventNotificationConfig(value: NotificationConfig_|null) {
    this.Serializable$set('newMessageEventNotificationConfig', value);
  }

  get notificationConfig(): NotificationConfig_|null {
    return (
        (this.Serializable$has('notificationConfig')) ?
            (this.Serializable$get('notificationConfig')) :
            (null));
  }

  /**
   * Configuration for publishing conversation lifecycle events.
   */
  set notificationConfig(value: NotificationConfig_|null) {
    this.Serializable$set('notificationConfig', value);
  }

  get projectNumber(): string|null {
    return (
        (this.Serializable$has('projectNumber')) ?
            (this.Serializable$get('projectNumber')) :
            (null));
  }

  /**
   * Output only. The project number associated with the project id in `name`.
   * Internally used to read the project number.
   */
  set projectNumber(value: string|null) {
    this.Serializable$set('projectNumber', value);
  }

  get securitySettings(): string|null {
    return (
        (this.Serializable$has('securitySettings')) ?
            (this.Serializable$get('securitySettings')) :
            (null));
  }

  /**
   * Name of the CX SecuritySettings reference for the agent. Format:
   * `projects//locations//securitySettings/`.
   */
  set securitySettings(value: string|null) {
    this.Serializable$set('securitySettings', value);
  }

  get sipConfig(): SipConfig_|null {
    return (
        (this.Serializable$has('sipConfig')) ?
            (this.Serializable$get('sipConfig')) :
            (null));
  }

  /**
   * Configuration for SIP connections.
   */
  set sipConfig(value: SipConfig_|null) {
    this.Serializable$set('sipConfig', value);
  }

  get sttConfig(): SpeechToTextConfig_|null {
    return (
        (this.Serializable$has('sttConfig')) ?
            (this.Serializable$get('sttConfig')) :
            (null));
  }

  /**
   * Settings for speech transcription.
   */
  set sttConfig(value: SpeechToTextConfig_|null) {
    this.Serializable$set('sttConfig', value);
  }

  get telephonySttConfig(): TelephonySttConfig_|null {
    return (
        (this.Serializable$has('telephonySttConfig')) ?
            (this.Serializable$get('telephonySttConfig')) :
            (null));
  }

  /**
   * Configuration for Speech-to-Text transcription in telephony.
   */
  set telephonySttConfig(value: TelephonySttConfig_|null) {
    this.Serializable$set('telephonySttConfig', value);
  }

  get timeZone(): string|null {
    return (
        (this.Serializable$has('timeZone')) ?
            (this.Serializable$get('timeZone')) :
            (null));
  }

  /**
   * The time zone of this conversational profile from the [time zone
   * database](https://www.iana.org/time-zones), e.g., America/New_York,
   * Europe/Paris. Defaults to America/New_York.
   */
  set timeZone(value: string|null) {
    this.Serializable$set('timeZone', value);
  }

  get ttsConfig(): SynthesizeSpeechConfig_|null {
    return (
        (this.Serializable$has('ttsConfig')) ?
            (this.Serializable$get('ttsConfig')) :
            (null));
  }

  /**
   * Configuration for Text-to-Speech synthesization.
   */
  set ttsConfig(value: SynthesizeSpeechConfig_|null) {
    this.Serializable$set('ttsConfig', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. Update time of the conversation profile.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<ConversationProfile_> {
    return ConversationProfile_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'automatedAgentConfig', 'createTime', 'displayName',
        'humanAgentAssistantConfig', 'humanAgentHandoffConfig', 'languageCode',
        'loggingConfig', 'name', 'newMessageEventNotificationConfig',
        'notificationConfig', 'projectNumber', 'securitySettings', 'sipConfig',
        'sttConfig', 'telephonySttConfig', 'timeZone', 'ttsConfig', 'updateTime'
      ],
      objects: {
        'automatedAgentConfig': AutomatedAgentConfig_,
        'humanAgentAssistantConfig': HumanAgentAssistantConfig_,
        'humanAgentHandoffConfig': HumanAgentHandoffConfig_,
        'loggingConfig': LoggingConfig_,
        'newMessageEventNotificationConfig': NotificationConfig_,
        'notificationConfig': NotificationConfig_,
        'sipConfig': SipConfig_,
        'sttConfig': SpeechToTextConfig_,
        'telephonySttConfig': TelephonySttConfig_,
        'ttsConfig': SynthesizeSpeechConfig_
      }
    };
  }
}

export interface ConversationSimulatorConfigSimulatorVoiceConfig_Parameters {
  humanAgentPhoneNumber?: string|null;
}
export class ConversationSimulatorConfigSimulatorVoiceConfig_ extends
    Serializable {
  constructor(
      parameters:
          ConversationSimulatorConfigSimulatorVoiceConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'humanAgentPhoneNumber',
        (parameters.humanAgentPhoneNumber == null) ?
            (null) :
            (parameters.humanAgentPhoneNumber));
  }

  get humanAgentPhoneNumber(): string|null {
    return (
        (this.Serializable$has('humanAgentPhoneNumber')) ?
            (this.Serializable$get('humanAgentPhoneNumber')) :
            (null));
  }

  /**
   * Agent phone number to connect to using Telephony.
   */
  set humanAgentPhoneNumber(value: string|null) {
    this.Serializable$set('humanAgentPhoneNumber', value);
  }

  getConstructor():
      SerializableCtor<ConversationSimulatorConfigSimulatorVoiceConfig_> {
    return ConversationSimulatorConfigSimulatorVoiceConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['humanAgentPhoneNumber']};
  }
}

export interface ConversationSimulatorConfig_Parameters {
  simulatorVoiceConfig?: ConversationSimulatorConfigSimulatorVoiceConfig_|null;
}
export class ConversationSimulatorConfig_ extends Serializable {
  constructor(parameters: ConversationSimulatorConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'simulatorVoiceConfig',
        (parameters.simulatorVoiceConfig == null) ?
            (null) :
            (parameters.simulatorVoiceConfig));
  }

  get simulatorVoiceConfig(): ConversationSimulatorConfigSimulatorVoiceConfig_
      |null {
    return (
        (this.Serializable$has('simulatorVoiceConfig')) ?
            (this.Serializable$get('simulatorVoiceConfig')) :
            (null));
  }

  /**
   * Used for Agent Assist simulator voice conversations.
   */
  set simulatorVoiceConfig(value:
                               ConversationSimulatorConfigSimulatorVoiceConfig_|
                           null) {
    this.Serializable$set('simulatorVoiceConfig', value);
  }

  getConstructor(): SerializableCtor<ConversationSimulatorConfig_> {
    return ConversationSimulatorConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['simulatorVoiceConfig'],
      objects: {
        'simulatorVoiceConfig': ConversationSimulatorConfigSimulatorVoiceConfig_
      }
    };
  }
}

export interface ConversationSummary_Parameters {
  text?: string|null;
  textSections?: ApiClientObjectMap<string>|null;
}
export class ConversationSummary_ extends Serializable {
  constructor(parameters: ConversationSummary_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'textSections',
        (parameters.textSections == null) ? (null) : (parameters.textSections));
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The summary content that is concatenated into one string.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  get textSections(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('textSections')) ?
            (this.Serializable$get('textSections')) :
            (null));
  }

  /**
   * The summary content that is divided into sections. The key is the section's
   * name and the value is the section's content. There is no specific format
   * for the key or value.
   */
  set textSections(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('textSections', value);
  }

  getConstructor(): SerializableCtor<ConversationSummary_> {
    return ConversationSummary_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['text', 'textSections'],
      objectMaps: {
        'textSections': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface ConversationTelephonyConfig_Parameters {
  enableTelephonyIntegration?: boolean|null;
  originatingCountryCode?: number|null;
  ephemeralPhoneNumber?: string|null;
}
export class ConversationTelephonyConfig_ extends Serializable {
  constructor(parameters: ConversationTelephonyConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'enableTelephonyIntegration',
        (parameters.enableTelephonyIntegration == null) ?
            (null) :
            (parameters.enableTelephonyIntegration));
    this.Serializable$set(
        'originatingCountryCode',
        (parameters.originatingCountryCode == null) ?
            (null) :
            (parameters.originatingCountryCode));
    this.Serializable$set(
        'ephemeralPhoneNumber',
        (parameters.ephemeralPhoneNumber == null) ?
            (null) :
            (parameters.ephemeralPhoneNumber));
  }

  get enableTelephonyIntegration(): boolean|null {
    return (
        (this.Serializable$has('enableTelephonyIntegration')) ?
            (this.Serializable$get('enableTelephonyIntegration')) :
            (null));
  }

  /**
   * Required. Whether to enable telephony integration. If true, an ephemeral
   * number will be supplied.
   */
  set enableTelephonyIntegration(value: boolean|null) {
    this.Serializable$set('enableTelephonyIntegration', value);
  }

  get ephemeralPhoneNumber(): string|null {
    return (
        (this.Serializable$has('ephemeralPhoneNumber')) ?
            (this.Serializable$get('ephemeralPhoneNumber')) :
            (null));
  }

  /**
   * Output only. Read-only. The ephemeral phone number supplied by the API for
   * this conversation to connect to.
   */
  set ephemeralPhoneNumber(value: string|null) {
    this.Serializable$set('ephemeralPhoneNumber', value);
  }

  get originatingCountryCode(): number|null {
    return (
        (this.Serializable$has('originatingCountryCode')) ?
            (this.Serializable$get('originatingCountryCode')) :
            (null));
  }

  /**
   * Required. Desired country code for the ephemeral number.
   */
  set originatingCountryCode(value: number|null) {
    this.Serializable$set('originatingCountryCode', value);
  }

  getConstructor(): SerializableCtor<ConversationTelephonyConfig_> {
    return ConversationTelephonyConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'enableTelephonyIntegration', 'ephemeralPhoneNumber',
        'originatingCountryCode'
      ]
    };
  }
}

export interface ConversationTelephonyConnectionInfoCallId_Parameters {
  value?: string|null;
  regionId?: number|null;
}
export class ConversationTelephonyConnectionInfoCallId_ extends Serializable {
  constructor(
      parameters: ConversationTelephonyConnectionInfoCallId_Parameters = {}) {
    super();
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
    this.Serializable$set(
        'regionId',
        (parameters.regionId == null) ? (null) : (parameters.regionId));
  }

  get regionId(): number|null {
    return (
        (this.Serializable$has('regionId')) ?
            (this.Serializable$get('regionId')) :
            (null));
  }

  /**
   * Region id identifying where the call is stored in. Please note that this is
   * not Cloud region, but the region used by storage.
   */
  set regionId(value: number|null) {
    this.Serializable$set('regionId', value);
  }

  get value(): string|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * An opaque, globally unique id for a Call. Guaranteed to be unique, even
   * after the call has ended.
   */
  set value(value: string|null) {
    this.Serializable$set('value', value);
  }

  getConstructor():
      SerializableCtor<ConversationTelephonyConnectionInfoCallId_> {
    return ConversationTelephonyConnectionInfoCallId_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['regionId', 'value']};
  }
}

export interface ConversationTelephonyConnectionInfoConversationLinkInfo_Parameters {
  method?: ConversationTelephonyConnectionInfoConversationLinkInfo_Method|null;
  triggeredCallMatchers?: Array<string>|null;
  proxyNumber?: string|null;
}
export class ConversationTelephonyConnectionInfoConversationLinkInfo_ extends
    Serializable {
  constructor(
      parameters:
          ConversationTelephonyConnectionInfoConversationLinkInfo_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'method', (parameters.method == null) ? (null) : (parameters.method));
    this.Serializable$set(
        'triggeredCallMatchers',
        (parameters.triggeredCallMatchers == null) ?
            (null) :
            (parameters.triggeredCallMatchers));
    this.Serializable$set(
        'proxyNumber',
        (parameters.proxyNumber == null) ? (null) : (parameters.proxyNumber));
  }

  static get Method():
      IConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum {
    return ConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum;
  }

  get method(): ConversationTelephonyConnectionInfoConversationLinkInfo_Method
      |null {
    return (
        (this.Serializable$has('method')) ? (this.Serializable$get('method')) :
                                            (null));
  }

  /**
   * How the conversation was linked to a phone call.
   */
  set method(value:
                 ConversationTelephonyConnectionInfoConversationLinkInfo_Method|
             null) {
    this.Serializable$set('method', value);
  }

  get proxyNumber(): string|null {
    return (
        (this.Serializable$has('proxyNumber')) ?
            (this.Serializable$get('proxyNumber')) :
            (null));
  }

  /**
   * E.164 format proxy number used to match this call to a conversation.
   */
  set proxyNumber(value: string|null) {
    this.Serializable$set('proxyNumber', value);
  }

  get triggeredCallMatchers(): Array<string>|null {
    return (
        (this.Serializable$has('triggeredCallMatchers')) ?
            (this.Serializable$get('triggeredCallMatchers')) :
            (null));
  }

  /**
   * The content of any call matchers that matched this call.
   */
  set triggeredCallMatchers(value: Array<string>|null) {
    this.Serializable$set('triggeredCallMatchers', value);
  }

  getConstructor(): SerializableCtor<
      ConversationTelephonyConnectionInfoConversationLinkInfo_> {
    return ConversationTelephonyConnectionInfoConversationLinkInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'method':
            ConversationTelephonyConnectionInfoConversationLinkInfo_MethodEnum
      },
      keys: ['method', 'proxyNumber', 'triggeredCallMatchers']
    };
  }
}

export interface ConversationTelephonyConnectionInfoEndpointInfo_Parameters {
  state?: ConversationTelephonyConnectionInfoEndpointInfo_State|null;
}
export class ConversationTelephonyConnectionInfoEndpointInfo_ extends
    Serializable {
  constructor(
      parameters:
          ConversationTelephonyConnectionInfoEndpointInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
  }

  static get State():
      IConversationTelephonyConnectionInfoEndpointInfo_StateEnum {
    return ConversationTelephonyConnectionInfoEndpointInfo_StateEnum;
  }

  get state(): ConversationTelephonyConnectionInfoEndpointInfo_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * The state of the endpoint.
   */
  set state(value: ConversationTelephonyConnectionInfoEndpointInfo_State|null) {
    this.Serializable$set('state', value);
  }

  getConstructor():
      SerializableCtor<ConversationTelephonyConnectionInfoEndpointInfo_> {
    return ConversationTelephonyConnectionInfoEndpointInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums:
          {'state': ConversationTelephonyConnectionInfoEndpointInfo_StateEnum},
      keys: ['state']
    };
  }
}

export interface ConversationTelephonyConnectionInfo_Parameters {
  callId?: ConversationTelephonyConnectionInfoCallId_|null;
  dialedNumber?: string|null;
  conversationLinkInfo?:
      ConversationTelephonyConnectionInfoConversationLinkInfo_|null;
  callState?: ConversationTelephonyConnectionInfo_CallState|null;
  sdp?: string|null;
  endpointInfos?:
      ApiClientObjectMap<ConversationTelephonyConnectionInfoEndpointInfo_>|null;
}
export class ConversationTelephonyConnectionInfo_ extends Serializable {
  constructor(parameters: ConversationTelephonyConnectionInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'callId', (parameters.callId == null) ? (null) : (parameters.callId));
    this.Serializable$set(
        'dialedNumber',
        (parameters.dialedNumber == null) ? (null) : (parameters.dialedNumber));
    this.Serializable$set(
        'conversationLinkInfo',
        (parameters.conversationLinkInfo == null) ?
            (null) :
            (parameters.conversationLinkInfo));
    this.Serializable$set(
        'callState',
        (parameters.callState == null) ? (null) : (parameters.callState));
    this.Serializable$set(
        'sdp', (parameters.sdp == null) ? (null) : (parameters.sdp));
    this.Serializable$set(
        'endpointInfos',
        (parameters.endpointInfos == null) ? (null) :
                                             (parameters.endpointInfos));
  }

  static get CallState(): IConversationTelephonyConnectionInfo_CallStateEnum {
    return ConversationTelephonyConnectionInfo_CallStateEnum;
  }

  get callId(): ConversationTelephonyConnectionInfoCallId_|null {
    return (
        (this.Serializable$has('callId')) ? (this.Serializable$get('callId')) :
                                            (null));
  }

  /**
   * The ID of the call.
   */
  set callId(value: ConversationTelephonyConnectionInfoCallId_|null) {
    this.Serializable$set('callId', value);
  }

  get callState(): ConversationTelephonyConnectionInfo_CallState|null {
    return (
        (this.Serializable$has('callState')) ?
            (this.Serializable$get('callState')) :
            (null));
  }

  /**
   * The state of the phone call.
   */
  set callState(value: ConversationTelephonyConnectionInfo_CallState|null) {
    this.Serializable$set('callState', value);
  }

  get conversationLinkInfo():
      ConversationTelephonyConnectionInfoConversationLinkInfo_|null {
    return (
        (this.Serializable$has('conversationLinkInfo')) ?
            (this.Serializable$get('conversationLinkInfo')) :
            (null));
  }

  /**
   * How the call was linked to the conversation.
   */
  set conversationLinkInfo(
      value: ConversationTelephonyConnectionInfoConversationLinkInfo_|null) {
    this.Serializable$set('conversationLinkInfo', value);
  }

  get dialedNumber(): string|null {
    return (
        (this.Serializable$has('dialedNumber')) ?
            (this.Serializable$get('dialedNumber')) :
            (null));
  }

  /**
   * The number dialed to connect this call E.164 format.
   */
  set dialedNumber(value: string|null) {
    this.Serializable$set('dialedNumber', value);
  }

  get endpointInfos():
      ApiClientObjectMap<ConversationTelephonyConnectionInfoEndpointInfo_>|
      null {
    return (
        (this.Serializable$has('endpointInfos')) ?
            (this.Serializable$get('endpointInfos')) :
            (null));
  }

  /**
   * Map from endpoint ID to its info.
   */
  set endpointInfos(
      value:
          ApiClientObjectMap<ConversationTelephonyConnectionInfoEndpointInfo_>|
      null) {
    this.Serializable$set('endpointInfos', value);
  }

  get sdp(): string|null {
    return (
        (this.Serializable$has('sdp')) ? (this.Serializable$get('sdp')) :
                                         (null));
  }

  /**
   * SDP of the call. It's initially the SDP answer to the endpoint, but maybe
   * later updated for the purpose of making the link active, etc.
   */
  set sdp(value: string|null) {
    this.Serializable$set('sdp', value);
  }

  getConstructor(): SerializableCtor<ConversationTelephonyConnectionInfo_> {
    return ConversationTelephonyConnectionInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'callState': ConversationTelephonyConnectionInfo_CallStateEnum},
      keys: [
        'callId', 'callState', 'conversationLinkInfo', 'dialedNumber',
        'endpointInfos', 'sdp'
      ],
      objectMaps: {
        'endpointInfos': {
          ctor: ConversationTelephonyConnectionInfoEndpointInfo_,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      },
      objects: {
        'callId': ConversationTelephonyConnectionInfoCallId_,
        'conversationLinkInfo':
            ConversationTelephonyConnectionInfoConversationLinkInfo_
      }
    };
  }
}

export interface ConversationTurn_Parameters {
  text?: string|null;
  role?: ConversationTurn_Role|null;
  participantId?: string|null;
  startTime?: string|null;
}
export class ConversationTurn_ extends Serializable {
  constructor(parameters: ConversationTurn_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'role', (parameters.role == null) ? (null) : (parameters.role));
    this.Serializable$set(
        'participantId',
        (parameters.participantId == null) ? (null) :
                                             (parameters.participantId));
    this.Serializable$set(
        'startTime',
        (parameters.startTime == null) ? (null) : (parameters.startTime));
  }

  static get Role(): IConversationTurn_RoleEnum {
    return ConversationTurn_RoleEnum;
  }

  get participantId(): string|null {
    return (
        (this.Serializable$has('participantId')) ?
            (this.Serializable$get('participantId')) :
            (null));
  }

  /**
   * Optional. An unique string to identify the user.
   */
  set participantId(value: string|null) {
    this.Serializable$set('participantId', value);
  }

  get role(): ConversationTurn_Role|null {
    return (
        (this.Serializable$has('role')) ? (this.Serializable$get('role')) :
                                          (null));
  }

  /**
   * The role of the participant that generate the entry.
   */
  set role(value: ConversationTurn_Role|null) {
    this.Serializable$set('role', value);
  }

  get startTime(): string|null {
    return (
        (this.Serializable$has('startTime')) ?
            (this.Serializable$get('startTime')) :
            (null));
  }

  /**
   * The start time of the turn. For text messages, it just means the time of
   * the turn being sent to the system.
   */
  set startTime(value: string|null) {
    this.Serializable$set('startTime', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The text content of the entry.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<ConversationTurn_> {
    return ConversationTurn_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'role': ConversationTurn_RoleEnum},
      keys: ['participantId', 'role', 'startTime', 'text']
    };
  }
}

export interface Conversation_Parameters {
  name?: string|null;
  lifecycleState?: Conversation_LifecycleState|null;
  conversationProfile?: string|null;
  phoneNumber?: ConversationPhoneNumber_|null;
  conversationStage?: Conversation_ConversationStage|null;
  startTime?: string|null;
  endTime?: string|null;
  simulatorConfig?: ConversationSimulatorConfig_|null;
  transcribeMode?: Conversation_TranscribeMode|null;
  telephonyConnectionInfo?: ConversationTelephonyConnectionInfo_|null;
  telephonyConfig?: ConversationTelephonyConfig_|null;
  source?: Conversation_Source|null;
  conversationType?: Conversation_ConversationType|null;
  speechModelVariant?: Conversation_SpeechModelVariant|null;
  initialConversationProfile?: ConversationProfile_|null;
  projectNumber?: string|null;
}
export class Conversation_ extends Serializable {
  constructor(parameters: Conversation_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'lifecycleState',
        (parameters.lifecycleState == null) ? (null) :
                                              (parameters.lifecycleState));
    this.Serializable$set(
        'conversationProfile',
        (parameters.conversationProfile == null) ?
            (null) :
            (parameters.conversationProfile));
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
    this.Serializable$set(
        'conversationStage',
        (parameters.conversationStage == null) ?
            (null) :
            (parameters.conversationStage));
    this.Serializable$set(
        'startTime',
        (parameters.startTime == null) ? (null) : (parameters.startTime));
    this.Serializable$set(
        'endTime',
        (parameters.endTime == null) ? (null) : (parameters.endTime));
    this.Serializable$set(
        'simulatorConfig',
        (parameters.simulatorConfig == null) ? (null) :
                                               (parameters.simulatorConfig));
    this.Serializable$set(
        'transcribeMode',
        (parameters.transcribeMode == null) ? (null) :
                                              (parameters.transcribeMode));
    this.Serializable$set(
        'telephonyConnectionInfo',
        (parameters.telephonyConnectionInfo == null) ?
            (null) :
            (parameters.telephonyConnectionInfo));
    this.Serializable$set(
        'telephonyConfig',
        (parameters.telephonyConfig == null) ? (null) :
                                               (parameters.telephonyConfig));
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'conversationType',
        (parameters.conversationType == null) ? (null) :
                                                (parameters.conversationType));
    this.Serializable$set(
        'speechModelVariant',
        (parameters.speechModelVariant == null) ?
            (null) :
            (parameters.speechModelVariant));
    this.Serializable$set(
        'initialConversationProfile',
        (parameters.initialConversationProfile == null) ?
            (null) :
            (parameters.initialConversationProfile));
    this.Serializable$set(
        'projectNumber',
        (parameters.projectNumber == null) ? (null) :
                                             (parameters.projectNumber));
  }

  static get ConversationStage(): IConversation_ConversationStageEnum {
    return Conversation_ConversationStageEnum;
  }

  static get ConversationType(): IConversation_ConversationTypeEnum {
    return Conversation_ConversationTypeEnum;
  }

  static get LifecycleState(): IConversation_LifecycleStateEnum {
    return Conversation_LifecycleStateEnum;
  }

  static get Source(): IConversation_SourceEnum {
    return Conversation_SourceEnum;
  }

  static get SpeechModelVariant(): IConversation_SpeechModelVariantEnum {
    return Conversation_SpeechModelVariantEnum;
  }

  static get TranscribeMode(): IConversation_TranscribeModeEnum {
    return Conversation_TranscribeModeEnum;
  }

  get conversationProfile(): string|null {
    return (
        (this.Serializable$has('conversationProfile')) ?
            (this.Serializable$get('conversationProfile')) :
            (null));
  }

  /**
   * Required. The Conversation Profile to be used to configure this
   * Conversation. This field cannot be updated. Format:
   * `projects//locations//conversationProfiles/`.
   */
  set conversationProfile(value: string|null) {
    this.Serializable$set('conversationProfile', value);
  }

  get conversationStage(): Conversation_ConversationStage|null {
    return (
        (this.Serializable$has('conversationStage')) ?
            (this.Serializable$get('conversationStage')) :
            (null));
  }

  /**
   * The stage of a conversation. It indicates whether the virtual agent or a
   * human agent is handling the conversation. If the conversation is created
   * with the conversation profile that has Dialogflow config set, defaults to
   * ConversationStage.VIRTUAL_AGENT_STAGE; Otherwise, defaults to
   * ConversationStage.HUMAN_ASSIST_STAGE. If the conversation is created with
   * the conversation profile that has Dialogflow config set but explicitly sets
   * conversation_stage to ConversationStage.HUMAN_ASSIST_STAGE, it skips
   * ConversationStage.VIRTUAL_AGENT_STAGE stage and directly goes to
   * ConversationStage.HUMAN_ASSIST_STAGE.
   */
  set conversationStage(value: Conversation_ConversationStage|null) {
    this.Serializable$set('conversationStage', value);
  }

  get conversationType(): Conversation_ConversationType|null {
    return (
        (this.Serializable$has('conversationType')) ?
            (this.Serializable$get('conversationType')) :
            (null));
  }

  /**
   * The type of the conversation. If not set, treated it as voice by default.
   */
  set conversationType(value: Conversation_ConversationType|null) {
    this.Serializable$set('conversationType', value);
  }

  get endTime(): string|null {
    return (
        (this.Serializable$has('endTime')) ?
            (this.Serializable$get('endTime')) :
            (null));
  }

  /**
   * Output only. The time the conversation was finished.
   */
  set endTime(value: string|null) {
    this.Serializable$set('endTime', value);
  }

  get initialConversationProfile(): ConversationProfile_|null {
    return (
        (this.Serializable$has('initialConversationProfile')) ?
            (this.Serializable$get('initialConversationProfile')) :
            (null));
  }

  /**
   * Output only. The initial conversation profile to be used to configure this
   * conversation, which is a copy of the session profile config read at session
   * creation time.
   */
  set initialConversationProfile(value: ConversationProfile_|null) {
    this.Serializable$set('initialConversationProfile', value);
  }

  get lifecycleState(): Conversation_LifecycleState|null {
    return (
        (this.Serializable$has('lifecycleState')) ?
            (this.Serializable$get('lifecycleState')) :
            (null));
  }

  /**
   * Output only. The current state of the Conversation.
   */
  set lifecycleState(value: Conversation_LifecycleState|null) {
    this.Serializable$set('lifecycleState', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this conversation. Format:
   * `projects//locations//conversations/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get phoneNumber(): ConversationPhoneNumber_|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Output only. Required if the conversation is to be connected over
   * telephony.
   */
  set phoneNumber(value: ConversationPhoneNumber_|null) {
    this.Serializable$set('phoneNumber', value);
  }

  get projectNumber(): string|null {
    return (
        (this.Serializable$has('projectNumber')) ?
            (this.Serializable$get('projectNumber')) :
            (null));
  }

  /**
   * Output only. The project number associated with the project id in `name`.
   * Internally used to pass the project number to DF servers.
   */
  set projectNumber(value: string|null) {
    this.Serializable$set('projectNumber', value);
  }

  get simulatorConfig(): ConversationSimulatorConfig_|null {
    return (
        (this.Serializable$has('simulatorConfig')) ?
            (this.Serializable$get('simulatorConfig')) :
            (null));
  }

  /**
   * Test for the presence of this field to check whether a Conversation
   * originated from the Agent Assist simulator.
   */
  set simulatorConfig(value: ConversationSimulatorConfig_|null) {
    this.Serializable$set('simulatorConfig', value);
  }

  get source(): Conversation_Source|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * The type of the source which created the conversation.
   */
  set source(value: Conversation_Source|null) {
    this.Serializable$set('source', value);
  }

  get speechModelVariant(): Conversation_SpeechModelVariant|null {
    return (
        (this.Serializable$has('speechModelVariant')) ?
            (this.Serializable$get('speechModelVariant')) :
            (null));
  }

  /**
   * The speech model used in STT.
   */
  set speechModelVariant(value: Conversation_SpeechModelVariant|null) {
    this.Serializable$set('speechModelVariant', value);
  }

  get startTime(): string|null {
    return (
        (this.Serializable$has('startTime')) ?
            (this.Serializable$get('startTime')) :
            (null));
  }

  /**
   * Output only. The time the conversation was started.
   */
  set startTime(value: string|null) {
    this.Serializable$set('startTime', value);
  }

  get telephonyConfig(): ConversationTelephonyConfig_|null {
    return (
        (this.Serializable$has('telephonyConfig')) ?
            (this.Serializable$get('telephonyConfig')) :
            (null));
  }

  /**
   * Optional. Required if the conversation is to be connected over telephony.
   */
  set telephonyConfig(value: ConversationTelephonyConfig_|null) {
    this.Serializable$set('telephonyConfig', value);
  }

  get telephonyConnectionInfo(): ConversationTelephonyConnectionInfo_|null {
    return (
        (this.Serializable$has('telephonyConnectionInfo')) ?
            (this.Serializable$get('telephonyConnectionInfo')) :
            (null));
  }

  /**
   * The telephony connection information.
   */
  set telephonyConnectionInfo(value: ConversationTelephonyConnectionInfo_|
                              null) {
    this.Serializable$set('telephonyConnectionInfo', value);
  }

  get transcribeMode(): Conversation_TranscribeMode|null {
    return (
        (this.Serializable$has('transcribeMode')) ?
            (this.Serializable$get('transcribeMode')) :
            (null));
  }

  /**
   * The transcribe mode of a conversation.
   */
  set transcribeMode(value: Conversation_TranscribeMode|null) {
    this.Serializable$set('transcribeMode', value);
  }

  getConstructor(): SerializableCtor<Conversation_> {
    return Conversation_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'conversationStage': Conversation_ConversationStageEnum,
        'conversationType': Conversation_ConversationTypeEnum,
        'lifecycleState': Conversation_LifecycleStateEnum,
        'source': Conversation_SourceEnum,
        'speechModelVariant': Conversation_SpeechModelVariantEnum,
        'transcribeMode': Conversation_TranscribeModeEnum
      },
      keys: [
        'conversationProfile', 'conversationStage', 'conversationType',
        'endTime', 'initialConversationProfile', 'lifecycleState', 'name',
        'phoneNumber', 'projectNumber', 'simulatorConfig', 'source',
        'speechModelVariant', 'startTime', 'telephonyConfig',
        'telephonyConnectionInfo', 'transcribeMode'
      ],
      objects: {
        'initialConversationProfile': ConversationProfile_,
        'phoneNumber': ConversationPhoneNumber_,
        'simulatorConfig': ConversationSimulatorConfig_,
        'telephonyConfig': ConversationTelephonyConfig_,
        'telephonyConnectionInfo': ConversationTelephonyConnectionInfo_
      }
    };
  }
}

export interface CopyConversationModelOperationMetadata_Parameters {
  conversationModel?: string|null;
  genericMetadata?: GenericOperationMetadata_|null;
}
export class CopyConversationModelOperationMetadata_ extends Serializable {
  constructor(
      parameters: CopyConversationModelOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//locations//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get genericMetadata(): GenericOperationMetadata_|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * Basic information shared by all operation metadata.
   */
  set genericMetadata(value: GenericOperationMetadata_|null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<CopyConversationModelOperationMetadata_> {
    return CopyConversationModelOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['conversationModel', 'genericMetadata'],
      objects: {'genericMetadata': GenericOperationMetadata_}
    };
  }
}

export interface CopyConversationModelRequest_Parameters {
  sourceConversationModel?: string|null;
}
export class CopyConversationModelRequest_ extends Serializable {
  constructor(parameters: CopyConversationModelRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'sourceConversationModel',
        (parameters.sourceConversationModel == null) ?
            (null) :
            (parameters.sourceConversationModel));
  }

  get sourceConversationModel(): string|null {
    return (
        (this.Serializable$has('sourceConversationModel')) ?
            (this.Serializable$get('sourceConversationModel')) :
            (null));
  }

  /**
   * Required. The conversation model to copy. Format:
   * `projects//locations//conversationModels/`
   */
  set sourceConversationModel(value: string|null) {
    this.Serializable$set('sourceConversationModel', value);
  }

  getConstructor(): SerializableCtor<CopyConversationModelRequest_> {
    return CopyConversationModelRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['sourceConversationModel']};
  }
}

export interface CreateCallMatcherRequest_Parameters {
  callMatcher?: CallMatcher_|null;
}
export class CreateCallMatcherRequest_ extends Serializable {
  constructor(parameters: CreateCallMatcherRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'callMatcher',
        (parameters.callMatcher == null) ? (null) : (parameters.callMatcher));
  }

  get callMatcher(): CallMatcher_|null {
    return (
        (this.Serializable$has('callMatcher')) ?
            (this.Serializable$get('callMatcher')) :
            (null));
  }

  /**
   * Required. The call matcher to create.
   */
  set callMatcher(value: CallMatcher_|null) {
    this.Serializable$set('callMatcher', value);
  }

  getConstructor(): SerializableCtor<CreateCallMatcherRequest_> {
    return CreateCallMatcherRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['callMatcher'], objects: {'callMatcher': CallMatcher_}};
  }
}

export interface CreateConversationDatasetOperationMetadata_Parameters {
  conversationDataset?: string|null;
}
export class CreateConversationDatasetOperationMetadata_ extends Serializable {
  constructor(
      parameters: CreateConversationDatasetOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationDataset',
        (parameters.conversationDataset == null) ?
            (null) :
            (parameters.conversationDataset));
  }

  get conversationDataset(): string|null {
    return (
        (this.Serializable$has('conversationDataset')) ?
            (this.Serializable$get('conversationDataset')) :
            (null));
  }

  /**
   * The resource name of the conversation dataset that will be created. Format:
   * `projects//locations//conversationDatasets/`
   */
  set conversationDataset(value: string|null) {
    this.Serializable$set('conversationDataset', value);
  }

  getConstructor():
      SerializableCtor<CreateConversationDatasetOperationMetadata_> {
    return CreateConversationDatasetOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationDataset']};
  }
}

export interface CreateConversationModelEvaluationOperationMetadata_Parameters {
  conversationModelEvaluation?: string|null;
  conversationModel?: string|null;
  state?: CreateConversationModelEvaluationOperationMetadata_State|null;
  createTime?: string|null;
}
export class CreateConversationModelEvaluationOperationMetadata_ extends
    Serializable {
  constructor(
      parameters:
          CreateConversationModelEvaluationOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationModelEvaluation',
        (parameters.conversationModelEvaluation == null) ?
            (null) :
            (parameters.conversationModelEvaluation));
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  static get State():
      ICreateConversationModelEvaluationOperationMetadata_StateEnum {
    return CreateConversationModelEvaluationOperationMetadata_StateEnum;
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//locations//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get conversationModelEvaluation(): string|null {
    return (
        (this.Serializable$has('conversationModelEvaluation')) ?
            (this.Serializable$get('conversationModelEvaluation')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//locations//conversationModels//evaluations/`
   */
  set conversationModelEvaluation(value: string|null) {
    this.Serializable$set('conversationModelEvaluation', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when the request to create conversation model was submitted. The
   * time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get state(): CreateConversationModelEvaluationOperationMetadata_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * State of CreateConversationModel operation.
   */
  set state(value: CreateConversationModelEvaluationOperationMetadata_State|
            null) {
    this.Serializable$set('state', value);
  }

  getConstructor():
      SerializableCtor<CreateConversationModelEvaluationOperationMetadata_> {
    return CreateConversationModelEvaluationOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state': CreateConversationModelEvaluationOperationMetadata_StateEnum
      },
      keys: [
        'conversationModel', 'conversationModelEvaluation', 'createTime',
        'state'
      ]
    };
  }
}

export interface CreateConversationModelEvaluationRequest_Parameters {
  conversationModelEvaluation?: ConversationModelEvaluation_|null;
}
export class CreateConversationModelEvaluationRequest_ extends Serializable {
  constructor(
      parameters: CreateConversationModelEvaluationRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationModelEvaluation',
        (parameters.conversationModelEvaluation == null) ?
            (null) :
            (parameters.conversationModelEvaluation));
  }

  get conversationModelEvaluation(): ConversationModelEvaluation_|null {
    return (
        (this.Serializable$has('conversationModelEvaluation')) ?
            (this.Serializable$get('conversationModelEvaluation')) :
            (null));
  }

  /**
   * Required. The conversation model evaluation to be created.
   */
  set conversationModelEvaluation(value: ConversationModelEvaluation_|null) {
    this.Serializable$set('conversationModelEvaluation', value);
  }

  getConstructor():
      SerializableCtor<CreateConversationModelEvaluationRequest_> {
    return CreateConversationModelEvaluationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['conversationModelEvaluation'],
      objects: {'conversationModelEvaluation': ConversationModelEvaluation_}
    };
  }
}

export interface CreateConversationModelOperationMetadata_Parameters {
  conversationModel?: string|null;
  state?: CreateConversationModelOperationMetadata_State|null;
  createTime?: string|null;
  queuePosition?: number|null;
  genericMetadata?: GenericOperationMetadata_|null;
  summarizationModelTrainingMetadata?: SummarizationModelTrainingMetadata_|null;
}
export class CreateConversationModelOperationMetadata_ extends Serializable {
  constructor(
      parameters: CreateConversationModelOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'queuePosition',
        (parameters.queuePosition == null) ? (null) :
                                             (parameters.queuePosition));
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
    this.Serializable$set(
        'summarizationModelTrainingMetadata',
        (parameters.summarizationModelTrainingMetadata == null) ?
            (null) :
            (parameters.summarizationModelTrainingMetadata));
  }

  static get State(): ICreateConversationModelOperationMetadata_StateEnum {
    return CreateConversationModelOperationMetadata_StateEnum;
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when the request to create conversation model was submitted. The
   * time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get genericMetadata(): GenericOperationMetadata_|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * Basic information shared by all operation metadata.
   */
  set genericMetadata(value: GenericOperationMetadata_|null) {
    this.Serializable$set('genericMetadata', value);
  }

  get queuePosition(): number|null {
    return (
        (this.Serializable$has('queuePosition')) ?
            (this.Serializable$get('queuePosition')) :
            (null));
  }

  /**
   * The PENDING job position in queue for acquiring resources(gpu, tpu) to
   * start job. Position starts from 0.
   */
  set queuePosition(value: number|null) {
    this.Serializable$set('queuePosition', value);
  }

  get state(): CreateConversationModelOperationMetadata_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * State of CreateConversationModel operation.
   */
  set state(value: CreateConversationModelOperationMetadata_State|null) {
    this.Serializable$set('state', value);
  }

  get summarizationModelTrainingMetadata(): SummarizationModelTrainingMetadata_
      |null {
    return (
        (this.Serializable$has('summarizationModelTrainingMetadata')) ?
            (this.Serializable$get('summarizationModelTrainingMetadata')) :
            (null));
  }

  /**
   * Information generated during summarization model training process.
   */
  set summarizationModelTrainingMetadata(
      value: SummarizationModelTrainingMetadata_|null) {
    this.Serializable$set('summarizationModelTrainingMetadata', value);
  }

  getConstructor():
      SerializableCtor<CreateConversationModelOperationMetadata_> {
    return CreateConversationModelOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': CreateConversationModelOperationMetadata_StateEnum},
      keys: [
        'conversationModel', 'createTime', 'genericMetadata', 'queuePosition',
        'state', 'summarizationModelTrainingMetadata'
      ],
      objects: {
        'genericMetadata': GenericOperationMetadata_,
        'summarizationModelTrainingMetadata':
            SummarizationModelTrainingMetadata_
      }
    };
  }
}

export interface CreateMessageNodeRequest_Parameters {
  node?: MessageNode_|null;
}
export class CreateMessageNodeRequest_ extends Serializable {
  constructor(parameters: CreateMessageNodeRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'node', (parameters.node == null) ? (null) : (parameters.node));
  }

  get node(): MessageNode_|null {
    return (
        (this.Serializable$has('node')) ? (this.Serializable$get('node')) :
                                          (null));
  }

  /**
   * Required. The message node to create.
   */
  set node(value: MessageNode_|null) {
    this.Serializable$set('node', value);
  }

  getConstructor(): SerializableCtor<CreateMessageNodeRequest_> {
    return CreateMessageNodeRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['node'], objects: {'node': MessageNode_}};
  }
}

export interface CreateMessageRequest_Parameters {
  parent?: string|null;
  message?: Message_|null;
}
export class CreateMessageRequest_ extends Serializable {
  constructor(parameters: CreateMessageRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'parent', (parameters.parent == null) ? (null) : (parameters.parent));
    this.Serializable$set(
        'message',
        (parameters.message == null) ? (null) : (parameters.message));
  }

  get message(): Message_|null {
    return (
        (this.Serializable$has('message')) ?
            (this.Serializable$get('message')) :
            (null));
  }

  /**
   * Required. The message to create. Message.participant is required.
   */
  set message(value: Message_|null) {
    this.Serializable$set('message', value);
  }

  get parent(): string|null {
    return (
        (this.Serializable$has('parent')) ? (this.Serializable$get('parent')) :
                                            (null));
  }

  /**
   * Required. Resource identifier of the conversation to create message.
   * Format: `projects//locations//conversations/`.
   */
  set parent(value: string|null) {
    this.Serializable$set('parent', value);
  }

  getConstructor(): SerializableCtor<CreateMessageRequest_> {
    return CreateMessageRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['message', 'parent'], objects: {'message': Message_}};
  }
}

export interface CreateModelEvaluationDatasetRequest_Parameters {
  numQueries?: number|null;
  languageCode?: string|null;
}
export class CreateModelEvaluationDatasetRequest_ extends Serializable {
  constructor(parameters: CreateModelEvaluationDatasetRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'numQueries',
        (parameters.numQueries == null) ? (null) : (parameters.numQueries));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of sample queries to be extracted.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get numQueries(): number|null {
    return (
        (this.Serializable$has('numQueries')) ?
            (this.Serializable$get('numQueries')) :
            (null));
  }

  /**
   * Required. The number of sample queries to be extracted.
   */
  set numQueries(value: number|null) {
    this.Serializable$set('numQueries', value);
  }

  getConstructor(): SerializableCtor<CreateModelEvaluationDatasetRequest_> {
    return CreateModelEvaluationDatasetRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['languageCode', 'numQueries']};
  }
}

export interface CreateParticipantRequest_Parameters {
  parent?: string|null;
  participant?: Participant_|null;
}
export class CreateParticipantRequest_ extends Serializable {
  constructor(parameters: CreateParticipantRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'parent', (parameters.parent == null) ? (null) : (parameters.parent));
    this.Serializable$set(
        'participant',
        (parameters.participant == null) ? (null) : (parameters.participant));
  }

  get parent(): string|null {
    return (
        (this.Serializable$has('parent')) ? (this.Serializable$get('parent')) :
                                            (null));
  }

  /**
   * Required. Resource identifier of the conversation adding the participant.
   * Format: `projects//locations//conversations/`.
   */
  set parent(value: string|null) {
    this.Serializable$set('parent', value);
  }

  get participant(): Participant_|null {
    return (
        (this.Serializable$has('participant')) ?
            (this.Serializable$get('participant')) :
            (null));
  }

  /**
   * Required. The participant to create.
   */
  set participant(value: Participant_|null) {
    this.Serializable$set('participant', value);
  }

  getConstructor(): SerializableCtor<CreateParticipantRequest_> {
    return CreateParticipantRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['parent', 'participant'],
      objects: {'participant': Participant_}
    };
  }
}

export interface CreatePhoneNumberLeaseRequest_Parameters {
  phoneNumberLease?: PhoneNumberLease_|null;
}
export class CreatePhoneNumberLeaseRequest_ extends Serializable {
  constructor(parameters: CreatePhoneNumberLeaseRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'phoneNumberLease',
        (parameters.phoneNumberLease == null) ? (null) :
                                                (parameters.phoneNumberLease));
  }

  get phoneNumberLease(): PhoneNumberLease_|null {
    return (
        (this.Serializable$has('phoneNumberLease')) ?
            (this.Serializable$get('phoneNumberLease')) :
            (null));
  }

  /**
   * Required. The lease to create.
   */
  set phoneNumberLease(value: PhoneNumberLease_|null) {
    this.Serializable$set('phoneNumberLease', value);
  }

  getConstructor(): SerializableCtor<CreatePhoneNumberLeaseRequest_> {
    return CreatePhoneNumberLeaseRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['phoneNumberLease'],
      objects: {'phoneNumberLease': PhoneNumberLease_}
    };
  }
}

export interface CreateSessionConversationRequest_Parameters {
  sessionConversation?: SessionConversation_|null;
  conversationLogs?: Array<string>|null;
  zippedConversationLogFile?: string|null;
  languageCode?: string|null;
}
export class CreateSessionConversationRequest_ extends Serializable {
  constructor(parameters: CreateSessionConversationRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'sessionConversation',
        (parameters.sessionConversation == null) ?
            (null) :
            (parameters.sessionConversation));
    this.Serializable$set(
        'conversationLogs',
        (parameters.conversationLogs == null) ? (null) :
                                                (parameters.conversationLogs));
    this.Serializable$set(
        'zippedConversationLogFile',
        (parameters.zippedConversationLogFile == null) ?
            (null) :
            (parameters.zippedConversationLogFile));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get conversationLogs(): Array<string>|null {
    return (
        (this.Serializable$has('conversationLogs')) ?
            (this.Serializable$get('conversationLogs')) :
            (null));
  }

  /**
   * Conversation log content in a txt file to be uploaded. The request will
   * either contain this field or zipped_conversation_log_file.
   */
  set conversationLogs(value: Array<string>|null) {
    this.Serializable$set('conversationLogs', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. Specifying a language of created conversation
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get sessionConversation(): SessionConversation_|null {
    return (
        (this.Serializable$has('sessionConversation')) ?
            (this.Serializable$get('sessionConversation')) :
            (null));
  }

  /**
   * The session conversation to create.
   */
  set sessionConversation(value: SessionConversation_|null) {
    this.Serializable$set('sessionConversation', value);
  }

  get zippedConversationLogFile(): string|null {
    return (
        (this.Serializable$has('zippedConversationLogFile')) ?
            (this.Serializable$get('zippedConversationLogFile')) :
            (null));
  }

  /**
   * Conversation logs in a zip file to be processed and uploaded. The request
   * will either contain this field or conversation_logs.
   */
  set zippedConversationLogFile(value: string|null) {
    this.Serializable$set('zippedConversationLogFile', value);
  }

  getConstructor(): SerializableCtor<CreateSessionConversationRequest_> {
    return CreateSessionConversationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'conversationLogs', 'languageCode', 'sessionConversation',
        'zippedConversationLogFile'
      ],
      objects: {'sessionConversation': SessionConversation_}
    };
  }
}

export interface CustomHeadIntentSuggestionModelMetadataDialogflowIntentSuggestion_Parameters {
  displayName?: string|null;
  intentV3?: string|null;
  disabled?: boolean|null;
}
export class
    CustomHeadIntentSuggestionModelMetadataDialogflowIntentSuggestion_ extends
        Serializable {
  constructor(
      parameters:
          CustomHeadIntentSuggestionModelMetadataDialogflowIntentSuggestion_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'intentV3',
        (parameters.intentV3 == null) ? (null) : (parameters.intentV3));
    this.Serializable$set(
        'disabled',
        (parameters.disabled == null) ? (null) : (parameters.disabled));
  }

  get disabled(): boolean|null {
    return (
        (this.Serializable$has('disabled')) ?
            (this.Serializable$get('disabled')) :
            (null));
  }

  /**
   * Indicates whether the head intent label should be disabled. If the value is
   * true, ignore the predicted head intent label and return empty response.
   */
  set disabled(value: boolean|null) {
    this.Serializable$set('disabled', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * The display name of the intent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get intentV3(): string|null {
    return (
        (this.Serializable$has('intentV3')) ?
            (this.Serializable$get('intentV3')) :
            (null));
  }

  /**
   * The unique identifier of this intent (for v3). Format:
   * `projects//locations//locations//agents//intents/`.
   */
  set intentV3(value: string|null) {
    this.Serializable$set('intentV3', value);
  }

  getConstructor(): SerializableCtor<
      CustomHeadIntentSuggestionModelMetadataDialogflowIntentSuggestion_> {
    return CustomHeadIntentSuggestionModelMetadataDialogflowIntentSuggestion_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['disabled', 'displayName', 'intentV3']};
  }
}

export interface CustomHeadIntentSuggestionModelMetadata_Parameters {
  trainingModelType?: CustomHeadIntentSuggestionModelMetadata_TrainingModelType|
      null;
  headIntentMap?: ApiClientObjectMap<
      CustomHeadIntentSuggestionModelMetadataDialogflowIntentSuggestion_>|null;
  internalTrainingFlags?: string|null;
}
export class CustomHeadIntentSuggestionModelMetadata_ extends Serializable {
  constructor(
      parameters: CustomHeadIntentSuggestionModelMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
    this.Serializable$set(
        'headIntentMap',
        (parameters.headIntentMap == null) ? (null) :
                                             (parameters.headIntentMap));
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
  }

  static get TrainingModelType():
      ICustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum {
    return CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum;
  }

  get headIntentMap(): ApiClientObjectMap<
      CustomHeadIntentSuggestionModelMetadataDialogflowIntentSuggestion_>|null {
    return (
        (this.Serializable$has('headIntentMap')) ?
            (this.Serializable$get('headIntentMap')) :
            (null));
  }

  /**
   * Head intent map whose key is head intent label, and value is corresponding
   * intent suggestion.
   */
  set headIntentMap(
      value: ApiClientObjectMap<
          CustomHeadIntentSuggestionModelMetadataDialogflowIntentSuggestion_>|
      null) {
    this.Serializable$set('headIntentMap', value);
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get trainingModelType():
      CustomHeadIntentSuggestionModelMetadata_TrainingModelType|null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Type of the Custom Head Intent Suggestion model.
   */
  set trainingModelType(
      value: CustomHeadIntentSuggestionModelMetadata_TrainingModelType|null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor(): SerializableCtor<CustomHeadIntentSuggestionModelMetadata_> {
    return CustomHeadIntentSuggestionModelMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'trainingModelType':
            CustomHeadIntentSuggestionModelMetadata_TrainingModelTypeEnum
      },
      keys: ['headIntentMap', 'internalTrainingFlags', 'trainingModelType'],
      objectMaps: {
        'headIntentMap': {
          ctor:
              CustomHeadIntentSuggestionModelMetadataDialogflowIntentSuggestion_,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface DatasetInputConfig_Parameters {
  gcsSources?: GcsSources_|null;
}
export class DatasetInputConfig_ extends Serializable {
  constructor(parameters: DatasetInputConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'gcsSources',
        (parameters.gcsSources == null) ? (null) : (parameters.gcsSources));
  }

  get gcsSources(): GcsSources_|null {
    return (
        (this.Serializable$has('gcsSources')) ?
            (this.Serializable$get('gcsSources')) :
            (null));
  }

  /**
   * The Cloud Storage location for the input content.
   */
  set gcsSources(value: GcsSources_|null) {
    this.Serializable$set('gcsSources', value);
  }

  getConstructor(): SerializableCtor<DatasetInputConfig_> {
    return DatasetInputConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['gcsSources'], objects: {'gcsSources': GcsSources_}};
  }
}

export interface Dataset_Parameters {
  name?: string|null;
  displayName?: string|null;
  inputConfig?: DatasetInputConfig_|null;
}
export class Dataset_ extends Serializable {
  constructor(parameters: Dataset_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'inputConfig',
        (parameters.inputConfig == null) ? (null) : (parameters.inputConfig));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human readable name of the dataset. The name can be up to 32
   * characters long and can consist only of ASCII Latin letters A-Z and a-z,
   * underscores (_), and ASCII digits 0-9.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get inputConfig(): DatasetInputConfig_|null {
    return (
        (this.Serializable$has('inputConfig')) ?
            (this.Serializable$get('inputConfig')) :
            (null));
  }

  /**
   * Input configuration of the dataset.
   */
  set inputConfig(value: DatasetInputConfig_|null) {
    this.Serializable$set('inputConfig', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The resource name of the dataset. Format:
   * `projects/{project_id}/locations/{location_id}/datasets/{dataset_id}`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<Dataset_> {
    return Dataset_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['displayName', 'inputConfig', 'name'],
      objects: {'inputConfig': DatasetInputConfig_}
    };
  }
}

export interface DeactivateConversationRequest_Parameters {}
export class DeactivateConversationRequest_ extends Serializable {
  constructor(parameters: DeactivateConversationRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<DeactivateConversationRequest_> {
    return DeactivateConversationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface DeleteConversationAnnotationOperationMetadata_Parameters {}
export class DeleteConversationAnnotationOperationMetadata_ extends
    Serializable {
  constructor(
      parameters:
          DeleteConversationAnnotationOperationMetadata_Parameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<DeleteConversationAnnotationOperationMetadata_> {
    return DeleteConversationAnnotationOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface DeleteConversationDatasetOperationMetadata_Parameters {}
export class DeleteConversationDatasetOperationMetadata_ extends Serializable {
  constructor(
      parameters: DeleteConversationDatasetOperationMetadata_Parameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<DeleteConversationDatasetOperationMetadata_> {
    return DeleteConversationDatasetOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface DeleteConversationModelOperationMetadata_Parameters {
  conversationModel?: string|null;
  createTime?: string|null;
}
export class DeleteConversationModelOperationMetadata_ extends Serializable {
  constructor(
      parameters: DeleteConversationModelOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when delete conversation model request was created. The time is
   * measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  getConstructor():
      SerializableCtor<DeleteConversationModelOperationMetadata_> {
    return DeleteConversationModelOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationModel', 'createTime']};
  }
}

export interface DeleteLegacyConversationDatasetRequest_Parameters {}
export class DeleteLegacyConversationDatasetRequest_ extends Serializable {
  constructor(
      parameters: DeleteLegacyConversationDatasetRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<DeleteLegacyConversationDatasetRequest_> {
    return DeleteLegacyConversationDatasetRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface DeployConversationModelOperationMetadata_Parameters {
  conversationModel?: string|null;
  createTime?: string|null;
}
export class DeployConversationModelOperationMetadata_ extends Serializable {
  constructor(
      parameters: DeployConversationModelOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when request to deploy conversation model was submitted. The time
   * is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  getConstructor():
      SerializableCtor<DeployConversationModelOperationMetadata_> {
    return DeployConversationModelOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationModel', 'createTime']};
  }
}

export interface DeployConversationModelRequest_Parameters {}
export class DeployConversationModelRequest_ extends Serializable {
  constructor(parameters: DeployConversationModelRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<DeployConversationModelRequest_> {
    return DeployConversationModelRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface DetectIntentRequest_Parameters {
  queryParams?: QueryParameters_|null;
  queryInput?: QueryInput_|null;
  outputAudioConfig?: OutputAudioConfig_|null;
  outputAudioConfigMask?: string|null;
  inputAudio?: string|null;
  disableLogging?: boolean|null;
  experimentFlags?: ApiClientObjectMap<string>|null;
}
export class DetectIntentRequest_ extends Serializable {
  constructor(parameters: DetectIntentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'queryParams',
        (parameters.queryParams == null) ? (null) : (parameters.queryParams));
    this.Serializable$set(
        'queryInput',
        (parameters.queryInput == null) ? (null) : (parameters.queryInput));
    this.Serializable$set(
        'outputAudioConfig',
        (parameters.outputAudioConfig == null) ?
            (null) :
            (parameters.outputAudioConfig));
    this.Serializable$set(
        'outputAudioConfigMask',
        (parameters.outputAudioConfigMask == null) ?
            (null) :
            (parameters.outputAudioConfigMask));
    this.Serializable$set(
        'inputAudio',
        (parameters.inputAudio == null) ? (null) : (parameters.inputAudio));
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
    this.Serializable$set(
        'experimentFlags',
        (parameters.experimentFlags == null) ? (null) :
                                               (parameters.experimentFlags));
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * Whether logging at all backends of user input and agent responses should be
   * disabled. This will prevent TextMessage, Dialogflow History, Stackdriver
   * logging, Speech logging etc. from being stored onto DB. NOTE: This field is
   * only for internal usage. It's used for passing arguments of API calls from
   * upstream components to downstream ones. It will not be exposed in any way.
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  get experimentFlags(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('experimentFlags')) ?
            (this.Serializable$get('experimentFlags')) :
            (null));
  }

  /**
   * Frontend experiment flags to pass to the runtime.
   */
  set experimentFlags(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('experimentFlags', value);
  }

  get inputAudio(): string|null {
    return (
        (this.Serializable$has('inputAudio')) ?
            (this.Serializable$get('inputAudio')) :
            (null));
  }

  /**
   * The natural language speech audio to be processed. This field should be
   * populated iff `query_input` is set to an input audio config. A single
   * request can contain up to 1 minute of speech audio data.
   */
  set inputAudio(value: string|null) {
    this.Serializable$set('inputAudio', value);
  }

  get outputAudioConfig(): OutputAudioConfig_|null {
    return (
        (this.Serializable$has('outputAudioConfig')) ?
            (this.Serializable$get('outputAudioConfig')) :
            (null));
  }

  /**
   * Instructs the speech synthesizer how to generate the output audio. If this
   * field is not set and agent-level speech synthesizer is not configured, no
   * output audio is generated.
   */
  set outputAudioConfig(value: OutputAudioConfig_|null) {
    this.Serializable$set('outputAudioConfig', value);
  }

  get outputAudioConfigMask(): string|null {
    return (
        (this.Serializable$has('outputAudioConfigMask')) ?
            (this.Serializable$get('outputAudioConfigMask')) :
            (null));
  }

  /**
   * Mask for output_audio_config indicating which settings in this
   * request-level config should override speech synthesizer settings defined at
   * agent-level. If unspecified or empty, output_audio_config replaces the
   * agent-level config in its entirety.
   */
  set outputAudioConfigMask(value: string|null) {
    this.Serializable$set('outputAudioConfigMask', value);
  }

  get queryInput(): QueryInput_|null {
    return (
        (this.Serializable$has('queryInput')) ?
            (this.Serializable$get('queryInput')) :
            (null));
  }

  /**
   * Required. The input specification. It can be set to: 1. an audio config
   * which instructs the speech recognizer how to process the speech audio, 2. a
   * conversational query in the form of text, or 3. an event that specifies
   * which intent to trigger.
   */
  set queryInput(value: QueryInput_|null) {
    this.Serializable$set('queryInput', value);
  }

  get queryParams(): QueryParameters_|null {
    return (
        (this.Serializable$has('queryParams')) ?
            (this.Serializable$get('queryParams')) :
            (null));
  }

  /**
   * The parameters of this query.
   */
  set queryParams(value: QueryParameters_|null) {
    this.Serializable$set('queryParams', value);
  }

  getConstructor(): SerializableCtor<DetectIntentRequest_> {
    return DetectIntentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'disableLogging', 'experimentFlags', 'inputAudio', 'outputAudioConfig',
        'outputAudioConfigMask', 'queryInput', 'queryParams'
      ],
      objectMaps: {
        'experimentFlags': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'outputAudioConfig': OutputAudioConfig_,
        'queryInput': QueryInput_,
        'queryParams': QueryParameters_
      }
    };
  }
}

export interface DetectIntentResponse_Parameters {
  responseId?: string|null;
  queryResult?: QueryResult_|null;
  alternativeQueryResults?: Array<QueryResult_>|null;
  webhookStatus?: GoogleRpcStatus|null;
  outputAudio?: string|null;
  outputAudioConfig?: OutputAudioConfig_|null;
  agentId?: string|null;
  agentSettings?: AgentSettings_|null;
}
export class DetectIntentResponse_ extends Serializable {
  constructor(parameters: DetectIntentResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'responseId',
        (parameters.responseId == null) ? (null) : (parameters.responseId));
    this.Serializable$set(
        'queryResult',
        (parameters.queryResult == null) ? (null) : (parameters.queryResult));
    this.Serializable$set(
        'alternativeQueryResults',
        (parameters.alternativeQueryResults == null) ?
            (null) :
            (parameters.alternativeQueryResults));
    this.Serializable$set(
        'webhookStatus',
        (parameters.webhookStatus == null) ? (null) :
                                             (parameters.webhookStatus));
    this.Serializable$set(
        'outputAudio',
        (parameters.outputAudio == null) ? (null) : (parameters.outputAudio));
    this.Serializable$set(
        'outputAudioConfig',
        (parameters.outputAudioConfig == null) ?
            (null) :
            (parameters.outputAudioConfig));
    this.Serializable$set(
        'agentId',
        (parameters.agentId == null) ? (null) : (parameters.agentId));
    this.Serializable$set(
        'agentSettings',
        (parameters.agentSettings == null) ? (null) :
                                             (parameters.agentSettings));
  }

  get agentId(): string|null {
    return (
        (this.Serializable$has('agentId')) ?
            (this.Serializable$get('agentId')) :
            (null));
  }

  /**
   * Internal field used in Dialogflow frontend to look up data in Dialogflow
   * Spanner, as Spanner tables are keyed by agent ID instead of cloud project
   * ID.
   */
  set agentId(value: string|null) {
    this.Serializable$set('agentId', value);
  }

  get agentSettings(): AgentSettings_|null {
    return (
        (this.Serializable$has('agentSettings')) ?
            (this.Serializable$get('agentSettings')) :
            (null));
  }

  /**
   * Contains some info about the agent this request is directed to. Agent
   * settings are used, inter alia, to inform quota and billing logic. This
   * field is used only internally to exchange information between the API
   * backend and the API frontend.
   */
  set agentSettings(value: AgentSettings_|null) {
    this.Serializable$set('agentSettings', value);
  }

  get alternativeQueryResults(): Array<QueryResult_>|null {
    return (
        (this.Serializable$has('alternativeQueryResults')) ?
            (this.Serializable$get('alternativeQueryResults')) :
            (null));
  }

  /**
   * If Knowledge Connectors are enabled, there could be more than one result
   * returned for a given query or event, and this field will contain all
   * results except for the top one, which is captured in query_result. The
   * alternative results are ordered by decreasing
   * `QueryResult.intent_detection_confidence`. If Knowledge Connectors are
   * disabled, this field will be empty until multiple responses for regular
   * intents are supported, at which point those additional results will be
   * surfaced here.
   */
  set alternativeQueryResults(value: Array<QueryResult_>|null) {
    this.Serializable$set('alternativeQueryResults', value);
  }

  get outputAudio(): string|null {
    return (
        (this.Serializable$has('outputAudio')) ?
            (this.Serializable$get('outputAudio')) :
            (null));
  }

  /**
   * The audio data bytes encoded as specified in the request. Note: The output
   * audio is generated based on the values of default platform text responses
   * found in the `query_result.fulfillment_messages` field. If multiple default
   * text responses exist, they will be concatenated when generating audio. If
   * no default platform text responses exist, the generated audio content will
   * be empty. In some scenarios, multiple output audio fields may be present in
   * the response structure. In these cases, only the top-most-level audio
   * output has content.
   */
  set outputAudio(value: string|null) {
    this.Serializable$set('outputAudio', value);
  }

  get outputAudioConfig(): OutputAudioConfig_|null {
    return (
        (this.Serializable$has('outputAudioConfig')) ?
            (this.Serializable$get('outputAudioConfig')) :
            (null));
  }

  /**
   * The config used by the speech synthesizer to generate the output audio.
   */
  set outputAudioConfig(value: OutputAudioConfig_|null) {
    this.Serializable$set('outputAudioConfig', value);
  }

  get queryResult(): QueryResult_|null {
    return (
        (this.Serializable$has('queryResult')) ?
            (this.Serializable$get('queryResult')) :
            (null));
  }

  /**
   * The selected results of the conversational query or event processing. See
   * `alternative_query_results` for additional potential results.
   */
  set queryResult(value: QueryResult_|null) {
    this.Serializable$set('queryResult', value);
  }

  get responseId(): string|null {
    return (
        (this.Serializable$has('responseId')) ?
            (this.Serializable$get('responseId')) :
            (null));
  }

  /**
   * The unique identifier of the response. It can be used to locate a response
   * in the training example set or for reporting issues.
   */
  set responseId(value: string|null) {
    this.Serializable$set('responseId', value);
  }

  get webhookStatus(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('webhookStatus')) ?
            (this.Serializable$get('webhookStatus')) :
            (null));
  }

  /**
   * Specifies the status of the webhook request.
   */
  set webhookStatus(value: GoogleRpcStatus|null) {
    this.Serializable$set('webhookStatus', value);
  }

  getConstructor(): SerializableCtor<DetectIntentResponse_> {
    return DetectIntentResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'alternativeQueryResults': QueryResult_},
      keys: [
        'agentId', 'agentSettings', 'alternativeQueryResults', 'outputAudio',
        'outputAudioConfig', 'queryResult', 'responseId', 'webhookStatus'
      ],
      objects: {
        'agentSettings': AgentSettings_,
        'outputAudioConfig': OutputAudioConfig_,
        'queryResult': QueryResult_,
        'webhookStatus': GoogleRpcStatus
      }
    };
  }
}

export interface DialogflowAssistAnswer_Parameters {
  queryResult?: QueryResult_|null;
  queryResultV3?: ApiClientObjectMap<any>|null;
  intentSuggestion?: IntentSuggestion_|null;
  answerRecord?: string|null;
  hasFollowupInteractions?: boolean|null;
  workflow?: string|null;
  headIntentLabel?: string|null;
}
export class DialogflowAssistAnswer_ extends Serializable {
  constructor(parameters: DialogflowAssistAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'queryResult',
        (parameters.queryResult == null) ? (null) : (parameters.queryResult));
    this.Serializable$set(
        'queryResultV3',
        (parameters.queryResultV3 == null) ? (null) :
                                             (parameters.queryResultV3));
    this.Serializable$set(
        'intentSuggestion',
        (parameters.intentSuggestion == null) ? (null) :
                                                (parameters.intentSuggestion));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
    this.Serializable$set(
        'hasFollowupInteractions',
        (parameters.hasFollowupInteractions == null) ?
            (null) :
            (parameters.hasFollowupInteractions));
    this.Serializable$set(
        'workflow',
        (parameters.workflow == null) ? (null) : (parameters.workflow));
    this.Serializable$set(
        'headIntentLabel',
        (parameters.headIntentLabel == null) ? (null) :
                                               (parameters.headIntentLabel));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get hasFollowupInteractions(): boolean|null {
    return (
        (this.Serializable$has('hasFollowupInteractions')) ?
            (this.Serializable$get('hasFollowupInteractions')) :
            (null));
  }

  /**
   * True if selecting the suggestion would lead to follow-up interactions.
   */
  set hasFollowupInteractions(value: boolean|null) {
    this.Serializable$set('hasFollowupInteractions', value);
  }

  get headIntentLabel(): string|null {
    return (
        (this.Serializable$has('headIntentLabel')) ?
            (this.Serializable$get('headIntentLabel')) :
            (null));
  }

  /**
   * Workflow head intent label name.
   */
  set headIntentLabel(value: string|null) {
    this.Serializable$set('headIntentLabel', value);
  }

  get intentSuggestion(): IntentSuggestion_|null {
    return (
        (this.Serializable$has('intentSuggestion')) ?
            (this.Serializable$get('intentSuggestion')) :
            (null));
  }

  /**
   * An intent suggestion generated from conversation.
   */
  set intentSuggestion(value: IntentSuggestion_|null) {
    this.Serializable$set('intentSuggestion', value);
  }

  get queryResult(): QueryResult_|null {
    return (
        (this.Serializable$has('queryResult')) ?
            (this.Serializable$get('queryResult')) :
            (null));
  }

  /**
   * Result from v2 agent.
   */
  set queryResult(value: QueryResult_|null) {
    this.Serializable$set('queryResult', value);
  }

  get queryResultV3(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('queryResultV3')) ?
            (this.Serializable$get('queryResultV3')) :
            (null));
  }

  /**
   * Result from v3 agent.
   */
  set queryResultV3(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('queryResultV3', value);
  }

  get workflow(): string|null {
    return (
        (this.Serializable$has('workflow')) ?
            (this.Serializable$get('workflow')) :
            (null));
  }

  /**
   * Workflow name
   */
  set workflow(value: string|null) {
    this.Serializable$set('workflow', value);
  }

  getConstructor(): SerializableCtor<DialogflowAssistAnswer_> {
    return DialogflowAssistAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'answerRecord', 'hasFollowupInteractions', 'headIntentLabel',
        'intentSuggestion', 'queryResult', 'queryResultV3', 'workflow'
      ],
      objectMaps: {
        'queryResultV3': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects:
          {'intentSuggestion': IntentSuggestion_, 'queryResult': QueryResult_}
    };
  }
}

export interface DialogflowAssistConfig_Parameters {
  agent?: string|null;
  maxResultCount?: number|null;
}
export class DialogflowAssistConfig_ extends Serializable {
  constructor(parameters: DialogflowAssistConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'agent', (parameters.agent == null) ? (null) : (parameters.agent));
    this.Serializable$set(
        'maxResultCount',
        (parameters.maxResultCount == null) ? (null) :
                                              (parameters.maxResultCount));
  }

  get agent(): string|null {
    return (
        (this.Serializable$has('agent')) ? (this.Serializable$get('agent')) :
                                           (null));
  }

  /**
   * Required. Name of a Dialogflow virtual agent. Format: `projects//agent`. It
   * may be extended to include Agent ID when multiple agents are allowed in the
   * same Dialogflow project.
   */
  set agent(value: string|null) {
    this.Serializable$set('agent', value);
  }

  get maxResultCount(): number|null {
    return (
        (this.Serializable$has('maxResultCount')) ?
            (this.Serializable$get('maxResultCount')) :
            (null));
  }

  /**
   * Optional. Maximum number of Dialogflow assist suggestions to return. If
   * unspecified or zero, defaults to 1.
   */
  set maxResultCount(value: number|null) {
    this.Serializable$set('maxResultCount', value);
  }

  getConstructor(): SerializableCtor<DialogflowAssistConfig_> {
    return DialogflowAssistConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agent', 'maxResultCount']};
  }
}

export interface DialogflowMessageLevelResource_Parameters {
  trainingPhraseResource?: UserIntentTrainingPhraseResource_|null;
}
export class DialogflowMessageLevelResource_ extends Serializable {
  constructor(parameters: DialogflowMessageLevelResource_Parameters = {}) {
    super();
    this.Serializable$set(
        'trainingPhraseResource',
        (parameters.trainingPhraseResource == null) ?
            (null) :
            (parameters.trainingPhraseResource));
  }

  get trainingPhraseResource(): UserIntentTrainingPhraseResource_|null {
    return (
        (this.Serializable$has('trainingPhraseResource')) ?
            (this.Serializable$get('trainingPhraseResource')) :
            (null));
  }

  /**
   * One of training phrase in the intent.
   */
  set trainingPhraseResource(value: UserIntentTrainingPhraseResource_|null) {
    this.Serializable$set('trainingPhraseResource', value);
  }

  getConstructor(): SerializableCtor<DialogflowMessageLevelResource_> {
    return DialogflowMessageLevelResource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['trainingPhraseResource'],
      objects: {'trainingPhraseResource': UserIntentTrainingPhraseResource_}
    };
  }
}

export interface DialogflowProtoPersistenceCustomSpeechModelInfosParameters {
  languageToModelInfo?: ApiClientObjectMap<
      DialogflowProtoPersistenceCustomSpeechModelInfosModelInfo>|null;
}
export class DialogflowProtoPersistenceCustomSpeechModelInfos extends
    Serializable {
  constructor(
      parameters:
          DialogflowProtoPersistenceCustomSpeechModelInfosParameters = {}) {
    super();
    this.Serializable$set(
        'languageToModelInfo',
        (parameters.languageToModelInfo == null) ?
            (null) :
            (parameters.languageToModelInfo));
  }

  get languageToModelInfo(): ApiClientObjectMap<
      DialogflowProtoPersistenceCustomSpeechModelInfosModelInfo>|null {
    return (
        (this.Serializable$has('languageToModelInfo')) ?
            (this.Serializable$get('languageToModelInfo')) :
            (null));
  }

  /**
   * Speech language to ModelInfo map.
   */
  set languageToModelInfo(
      value: ApiClientObjectMap<
          DialogflowProtoPersistenceCustomSpeechModelInfosModelInfo>|null) {
    this.Serializable$set('languageToModelInfo', value);
  }

  getConstructor():
      SerializableCtor<DialogflowProtoPersistenceCustomSpeechModelInfos> {
    return DialogflowProtoPersistenceCustomSpeechModelInfos;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['languageToModelInfo'],
      objectMaps: {
        'languageToModelInfo': {
          ctor: DialogflowProtoPersistenceCustomSpeechModelInfosModelInfo,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface DialogflowProtoPersistenceCustomSpeechModelInfosModelInfoParameters {
  name?: string|null;
  contextId?: string|null;
  clientId?: string|null;
  createTime?: string|null;
}
export class DialogflowProtoPersistenceCustomSpeechModelInfosModelInfo extends
    Serializable {
  constructor(
      parameters:
          DialogflowProtoPersistenceCustomSpeechModelInfosModelInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'contextId',
        (parameters.contextId == null) ? (null) : (parameters.contextId));
    this.Serializable$set(
        'clientId',
        (parameters.clientId == null) ? (null) : (parameters.clientId));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get clientId(): string|null {
    return (
        (this.Serializable$has('clientId')) ?
            (this.Serializable$get('clientId')) :
            (null));
  }

  /**
   * The Client Id used by S3.
   */
  set clientId(value: string|null) {
    this.Serializable$set('clientId', value);
  }

  get contextId(): string|null {
    return (
        (this.Serializable$has('contextId')) ?
            (this.Serializable$get('contextId')) :
            (null));
  }

  /**
   * The Context Id used by S3.
   */
  set contextId(value: string|null) {
    this.Serializable$set('contextId', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when this model was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Resource name of the model. Format:
   * \"projects/{project_id}/locations/{location_id}/models/{model_id}\"
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<
      DialogflowProtoPersistenceCustomSpeechModelInfosModelInfo> {
    return DialogflowProtoPersistenceCustomSpeechModelInfosModelInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['clientId', 'contextId', 'createTime', 'name']};
  }
}

export interface DialogflowResource_Parameters {
  intentResource?: UserIntentResource_|null;
  responseResource?: AgentResponseResource_|null;
  promptResource?: AgentPromptResource_|null;
  contextResource?: ContextResource_|null;
}
export class DialogflowResource_ extends Serializable {
  constructor(parameters: DialogflowResource_Parameters = {}) {
    super();
    this.Serializable$set(
        'intentResource',
        (parameters.intentResource == null) ? (null) :
                                              (parameters.intentResource));
    this.Serializable$set(
        'responseResource',
        (parameters.responseResource == null) ? (null) :
                                                (parameters.responseResource));
    this.Serializable$set(
        'promptResource',
        (parameters.promptResource == null) ? (null) :
                                              (parameters.promptResource));
    this.Serializable$set(
        'contextResource',
        (parameters.contextResource == null) ? (null) :
                                               (parameters.contextResource));
  }

  get contextResource(): ContextResource_|null {
    return (
        (this.Serializable$has('contextResource')) ?
            (this.Serializable$get('contextResource')) :
            (null));
  }

  /**
   * Context resource.
   */
  set contextResource(value: ContextResource_|null) {
    this.Serializable$set('contextResource', value);
  }

  get intentResource(): UserIntentResource_|null {
    return (
        (this.Serializable$has('intentResource')) ?
            (this.Serializable$get('intentResource')) :
            (null));
  }

  /**
   * Intent (training phrases) resource.
   */
  set intentResource(value: UserIntentResource_|null) {
    this.Serializable$set('intentResource', value);
  }

  get promptResource(): AgentPromptResource_|null {
    return (
        (this.Serializable$has('promptResource')) ?
            (this.Serializable$get('promptResource')) :
            (null));
  }

  /**
   * Intent parameter (prompt) resource.
   */
  set promptResource(value: AgentPromptResource_|null) {
    this.Serializable$set('promptResource', value);
  }

  get responseResource(): AgentResponseResource_|null {
    return (
        (this.Serializable$has('responseResource')) ?
            (this.Serializable$get('responseResource')) :
            (null));
  }

  /**
   * Intent response resource.
   */
  set responseResource(value: AgentResponseResource_|null) {
    this.Serializable$set('responseResource', value);
  }

  getConstructor(): SerializableCtor<DialogflowResource_> {
    return DialogflowResource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'contextResource', 'intentResource', 'promptResource',
        'responseResource'
      ],
      objects: {
        'contextResource': ContextResource_,
        'intentResource': UserIntentResource_,
        'promptResource': AgentPromptResource_,
        'responseResource': AgentResponseResource_
      }
    };
  }
}

export interface DisableSmartMessagingEntriesOperationMetadata_Parameters {
  document?: string|null;
  createTime?: string|null;
}
export class DisableSmartMessagingEntriesOperationMetadata_ extends
    Serializable {
  constructor(
      parameters:
          DisableSmartMessagingEntriesOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * The time when the operation was submitted.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The resource name of the document. Format:
   * `projects//knowledgeBases//documents/`
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  getConstructor():
      SerializableCtor<DisableSmartMessagingEntriesOperationMetadata_> {
    return DisableSmartMessagingEntriesOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['createTime', 'document']};
  }
}

export interface DisableSmartMessagingEntriesRequest_Parameters {
  filter?: string|null;
}
export class DisableSmartMessagingEntriesRequest_ extends Serializable {
  constructor(parameters: DisableSmartMessagingEntriesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'filter', (parameters.filter == null) ? (null) : (parameters.filter));
  }

  get filter(): string|null {
    return (
        (this.Serializable$has('filter')) ? (this.Serializable$get('filter')) :
                                            (null));
  }

  /**
   * Required. Disable is applied to a set of message entries that match the
   * specified filter expression. The expression is case insensitive and has the
   * following syntax: [AND ] ... The following fields are supported: * raw_text
   * * occurrence_count * state * custom_tag The following operators are
   * supported: * equality (=, !=) and inequality (<, <=, >, >=) operators
   * depends on the underlying type of the left-hand field Examples: *
   * \"raw_text=str\" matches the Smart Messaging Entry with the exact raw_text
   * \"str\". * \"raw_text=str*\" matches any Smart Messaging Entry with a
   * raw_text that contains \"str\". * \"occurrence_count=10\" matches Smart
   * Messaging Entries with the exact occurrence_count of 10. *
   * \"occurrence_count>10\" matches Smart Messaging Entries with an
   * occurrence_count of greater than 10. * \"state=enabled\" matches any Smart
   * Messaging Entries with a state of \"enabled\". * \"state=enabled AND
   * raw_text=*str* AND custom_tag=P0\" matches any Smart Messaging Entries with
   * a state of \"enabled\", custom_tag of \"P0\" and a raw_text that contains
   * \"str\". For more information about filtering, see [API
   * Filtering](https://aip.dev/160).
   */
  set filter(value: string|null) {
    this.Serializable$set('filter', value);
  }

  getConstructor(): SerializableCtor<DisableSmartMessagingEntriesRequest_> {
    return DisableSmartMessagingEntriesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['filter']};
  }
}

export interface DisableSmartMessagingEntriesResponse_Parameters {
  disabledCount?: number|null;
}
export class DisableSmartMessagingEntriesResponse_ extends Serializable {
  constructor(
      parameters: DisableSmartMessagingEntriesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'disabledCount',
        (parameters.disabledCount == null) ? (null) :
                                             (parameters.disabledCount));
  }

  get disabledCount(): number|null {
    return (
        (this.Serializable$has('disabledCount')) ?
            (this.Serializable$get('disabledCount')) :
            (null));
  }

  /**
   * Number of smart messaging entries disabled.
   */
  set disabledCount(value: number|null) {
    this.Serializable$set('disabledCount', value);
  }

  getConstructor(): SerializableCtor<DisableSmartMessagingEntriesResponse_> {
    return DisableSmartMessagingEntriesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['disabledCount']};
  }
}

export interface DocumentClusterSourceInfo_Parameters {
  clusterSourceType?: DocumentClusterSourceInfo_ClusterSourceType|null;
  conversationModel?: string|null;
  createTime?: string|null;
}
export class DocumentClusterSourceInfo_ extends Serializable {
  constructor(parameters: DocumentClusterSourceInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'clusterSourceType',
        (parameters.clusterSourceType == null) ?
            (null) :
            (parameters.clusterSourceType));
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  static get ClusterSourceType():
      IDocumentClusterSourceInfo_ClusterSourceTypeEnum {
    return DocumentClusterSourceInfo_ClusterSourceTypeEnum;
  }

  get clusterSourceType(): DocumentClusterSourceInfo_ClusterSourceType|null {
    return (
        (this.Serializable$has('clusterSourceType')) ?
            (this.Serializable$get('clusterSourceType')) :
            (null));
  }

  /**
   * The type of the cluster info source.
   */
  set clusterSourceType(value: DocumentClusterSourceInfo_ClusterSourceType|
                        null) {
    this.Serializable$set('clusterSourceType', value);
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The name of the conversation model that are used to generate the
   * embeddings, if the clustering is based groupping embeddings.
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * The time when cluster info of the document is added.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  getConstructor(): SerializableCtor<DocumentClusterSourceInfo_> {
    return DocumentClusterSourceInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'clusterSourceType': DocumentClusterSourceInfo_ClusterSourceTypeEnum
      },
      keys: ['clusterSourceType', 'conversationModel', 'createTime']
    };
  }
}

export interface DocumentReloadStatus_Parameters {
  time?: string|null;
  status?: GoogleRpcStatus|null;
}
export class DocumentReloadStatus_ extends Serializable {
  constructor(parameters: DocumentReloadStatus_Parameters = {}) {
    super();
    this.Serializable$set(
        'time', (parameters.time == null) ? (null) : (parameters.time));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * Output only. The status of a reload attempt or the initial load.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  get time(): string|null {
    return (
        (this.Serializable$has('time')) ? (this.Serializable$get('time')) :
                                          (null));
  }

  /**
   * Output only. The time of a reload attempt. This reload may have been
   * triggered automatically or manually and may not have succeeded.
   */
  set time(value: string|null) {
    this.Serializable$set('time', value);
  }

  getConstructor(): SerializableCtor<DocumentReloadStatus_> {
    return DocumentReloadStatus_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['status', 'time'], objects: {'status': GoogleRpcStatus}};
  }
}

export interface Document_Parameters {
  name?: string|null;
  displayName?: string|null;
  mimeType?: string|null;
  knowledgeTypes?: Array<Document_KnowledgeTypes>|null;
  contentUri?: string|null;
  content?: string|null;
  rawContent?: string|null;
  enableAutoReload?: boolean|null;
  latestReloadStatus?: DocumentReloadStatus_|null;
  metadata?: ApiClientObjectMap<string>|null;
  state?: Document_State|null;
  clusterSourceInfo?: DocumentClusterSourceInfo_|null;
}
export class Document_ extends Serializable {
  constructor(parameters: Document_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'mimeType',
        (parameters.mimeType == null) ? (null) : (parameters.mimeType));
    this.Serializable$set(
        'knowledgeTypes',
        (parameters.knowledgeTypes == null) ? (null) :
                                              (parameters.knowledgeTypes));
    this.Serializable$set(
        'contentUri',
        (parameters.contentUri == null) ? (null) : (parameters.contentUri));
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
    this.Serializable$set(
        'rawContent',
        (parameters.rawContent == null) ? (null) : (parameters.rawContent));
    this.Serializable$set(
        'enableAutoReload',
        (parameters.enableAutoReload == null) ? (null) :
                                                (parameters.enableAutoReload));
    this.Serializable$set(
        'latestReloadStatus',
        (parameters.latestReloadStatus == null) ?
            (null) :
            (parameters.latestReloadStatus));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'clusterSourceInfo',
        (parameters.clusterSourceInfo == null) ?
            (null) :
            (parameters.clusterSourceInfo));
  }

  static get KnowledgeTypes(): IDocument_KnowledgeTypesEnum {
    return Document_KnowledgeTypesEnum;
  }

  static get State(): IDocument_StateEnum {
    return Document_StateEnum;
  }

  get clusterSourceInfo(): DocumentClusterSourceInfo_|null {
    return (
        (this.Serializable$has('clusterSourceInfo')) ?
            (this.Serializable$get('clusterSourceInfo')) :
            (null));
  }

  /**
   * Output only. The information of the source of the cluster info if ever
   * exists for the document.
   */
  set clusterSourceInfo(value: DocumentClusterSourceInfo_|null) {
    this.Serializable$set('clusterSourceInfo', value);
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * The raw content of the document. This field is only permitted for
   * EXTRACTIVE_QA and FAQ knowledge types. Note: This field is in the process
   * of being deprecated, please use raw_content instead.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get contentUri(): string|null {
    return (
        (this.Serializable$has('contentUri')) ?
            (this.Serializable$get('contentUri')) :
            (null));
  }

  /**
   * The URI where the file content is located. For documents stored in Google
   * Cloud Storage, these URIs must have the form `gs:///`. NOTE: External URLs
   * must correspond to public webpages, i.e., they must be indexed by Google
   * Search. In particular, URLs for showing documents in Google Cloud Storage
   * (i.e. the URL in your browser) are not supported. Instead use the `gs://`
   * format URI described above.
   */
  set contentUri(value: string|null) {
    this.Serializable$set('contentUri', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The display name of the document. The name must be 1024 bytes or
   * less; otherwise, the creation request fails.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get enableAutoReload(): boolean|null {
    return (
        (this.Serializable$has('enableAutoReload')) ?
            (this.Serializable$get('enableAutoReload')) :
            (null));
  }

  /**
   * Optional. If true, we try to automatically reload the document every day
   * (at a time picked by the system). If false or unspecified, we don't try to
   * automatically reload the document. Currently you can only enable automatic
   * reload for documents sourced from a public url, see `source` field for the
   * source types. Reload status can be tracked in `latest_reload_status`. If a
   * reload fails, we will keep the document unchanged. If a reload fails with
   * internal errors, the system will try to reload the document on the next
   * day. If a reload fails with non-retriable errors (e.g. PERMISSION_DENIED),
   * the system will not try to reload the document anymore. You need to
   * manually reload the document successfully by calling `ReloadDocument` and
   * clear the errors.
   */
  set enableAutoReload(value: boolean|null) {
    this.Serializable$set('enableAutoReload', value);
  }

  get knowledgeTypes(): Array<Document_KnowledgeTypes>|null {
    return (
        (this.Serializable$has('knowledgeTypes')) ?
            (this.Serializable$get('knowledgeTypes')) :
            (null));
  }

  /**
   * Required. The knowledge type of document content.
   */
  set knowledgeTypes(value: Array<Document_KnowledgeTypes>|null) {
    this.Serializable$set('knowledgeTypes', value);
  }

  get latestReloadStatus(): DocumentReloadStatus_|null {
    return (
        (this.Serializable$has('latestReloadStatus')) ?
            (this.Serializable$get('latestReloadStatus')) :
            (null));
  }

  /**
   * Output only. The time and status of the latest reload. This reload may have
   * been triggered automatically or manually and may not have succeeded.
   */
  set latestReloadStatus(value: DocumentReloadStatus_|null) {
    this.Serializable$set('latestReloadStatus', value);
  }

  get metadata(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Optional. Metadata for the document. The metadata supports arbitrary
   * key-value pairs. Suggested use cases include storing a document's title, an
   * external URL distinct from the document's content_uri, etc. The max size of
   * a `key` or a `value` of the metadata is 1024 bytes.
   */
  set metadata(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('metadata', value);
  }

  get mimeType(): string|null {
    return (
        (this.Serializable$has('mimeType')) ?
            (this.Serializable$get('mimeType')) :
            (null));
  }

  /**
   * Required. The MIME type of this document.
   */
  set mimeType(value: string|null) {
    this.Serializable$set('mimeType', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Optional. The document resource name. The name must be empty when creating
   * a document. Format: `projects//locations//knowledgeBases//documents/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get rawContent(): string|null {
    return (
        (this.Serializable$has('rawContent')) ?
            (this.Serializable$get('rawContent')) :
            (null));
  }

  /**
   * The raw content of the document. This field is only permitted for
   * EXTRACTIVE_QA and FAQ knowledge types.
   */
  set rawContent(value: string|null) {
    this.Serializable$set('rawContent', value);
  }

  get state(): Document_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Output only. The current state of the document.
   */
  set state(value: Document_State|null) {
    this.Serializable$set('state', value);
  }

  getConstructor(): SerializableCtor<Document_> {
    return Document_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'knowledgeTypes': Document_KnowledgeTypesEnum,
        'state': Document_StateEnum
      },
      keys: [
        'clusterSourceInfo', 'content', 'contentUri', 'displayName',
        'enableAutoReload', 'knowledgeTypes', 'latestReloadStatus', 'metadata',
        'mimeType', 'name', 'rawContent', 'state'
      ],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'clusterSourceInfo': DocumentClusterSourceInfo_,
        'latestReloadStatus': DocumentReloadStatus_
      }
    };
  }
}

export interface DtmfParameters_Parameters {
  acceptsDtmfInput?: boolean|null;
}
export class DtmfParameters_ extends Serializable {
  constructor(parameters: DtmfParameters_Parameters = {}) {
    super();
    this.Serializable$set(
        'acceptsDtmfInput',
        (parameters.acceptsDtmfInput == null) ? (null) :
                                                (parameters.acceptsDtmfInput));
  }

  get acceptsDtmfInput(): boolean|null {
    return (
        (this.Serializable$has('acceptsDtmfInput')) ?
            (this.Serializable$get('acceptsDtmfInput')) :
            (null));
  }

  /**
   * Indicates whether DTMF input can be handled in the next request.
   */
  set acceptsDtmfInput(value: boolean|null) {
    this.Serializable$set('acceptsDtmfInput', value);
  }

  getConstructor(): SerializableCtor<DtmfParameters_> {
    return DtmfParameters_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['acceptsDtmfInput']};
  }
}

export interface EnableSmartMessagingEntriesOperationMetadata_Parameters {
  document?: string|null;
  createTime?: string|null;
}
export class EnableSmartMessagingEntriesOperationMetadata_ extends
    Serializable {
  constructor(
      parameters:
          EnableSmartMessagingEntriesOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * The time when the operation was submitted.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The resource name of the document. Format:
   * `projects//knowledgeBases//documents/`
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  getConstructor():
      SerializableCtor<EnableSmartMessagingEntriesOperationMetadata_> {
    return EnableSmartMessagingEntriesOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['createTime', 'document']};
  }
}

export interface EnableSmartMessagingEntriesRequest_Parameters {
  filter?: string|null;
}
export class EnableSmartMessagingEntriesRequest_ extends Serializable {
  constructor(parameters: EnableSmartMessagingEntriesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'filter', (parameters.filter == null) ? (null) : (parameters.filter));
  }

  get filter(): string|null {
    return (
        (this.Serializable$has('filter')) ? (this.Serializable$get('filter')) :
                                            (null));
  }

  /**
   * Required. Enable is applied to a set of message entries that match the
   * specified filter expression. The expression is case insensitive and has the
   * following syntax: [AND ] ... The following fields are supported: * raw_text
   * * occurrence_count * state * custom_tag The following operators are
   * supported: * equality (=, !=) and inequality (<, <=, >, >=) operators
   * depends on the underlying type of the left-hand field Examples: *
   * \"raw_text=str*\" matches the Smart Messaging Entry with the exact raw_text
   * \"str\". * \"raw_text=str*\" matches any Smart Messaging Entry with a
   * raw_text that contains \"str\". * \"occurrence_count=10\" matches Smart
   * Messaging Entries with the exact occurrence_count of 10. *
   * \"occurrence_count>10\" matches Smart Messaging Entries with an
   * occurrence_count of greater than 10. * \"state=enabled\" matches any Smart
   * Messaging Entries with a state of \"enabled\". * \"state=enabled AND
   * raw_text=*str* AND custom_tag=P0\" matches any Smart Messaging Entries with
   * a state of \"enabled\", custom_tag of \"P0\" and a raw_text that contains
   * \"str\". For more information about filtering, see [API
   * Filtering](https://aip.dev/160).
   */
  set filter(value: string|null) {
    this.Serializable$set('filter', value);
  }

  getConstructor(): SerializableCtor<EnableSmartMessagingEntriesRequest_> {
    return EnableSmartMessagingEntriesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['filter']};
  }
}

export interface EnableSmartMessagingEntriesResponse_Parameters {
  enabledCount?: number|null;
}
export class EnableSmartMessagingEntriesResponse_ extends Serializable {
  constructor(parameters: EnableSmartMessagingEntriesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'enabledCount',
        (parameters.enabledCount == null) ? (null) : (parameters.enabledCount));
  }

  get enabledCount(): number|null {
    return (
        (this.Serializable$has('enabledCount')) ?
            (this.Serializable$get('enabledCount')) :
            (null));
  }

  /**
   * Number of smart messaging entries enabled.
   */
  set enabledCount(value: number|null) {
    this.Serializable$set('enabledCount', value);
  }

  getConstructor(): SerializableCtor<EnableSmartMessagingEntriesResponse_> {
    return EnableSmartMessagingEntriesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enabledCount']};
  }
}

export interface EncodeSuggestionListToConversationModelRequest_Parameters {
  document?: string|null;
  knowledgeBase?: string|null;
  linkDatasetType?:
      EncodeSuggestionListToConversationModelRequest_LinkDatasetType|null;
}
export class EncodeSuggestionListToConversationModelRequest_ extends
    Serializable {
  constructor(
      parameters:
          EncodeSuggestionListToConversationModelRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'knowledgeBase',
        (parameters.knowledgeBase == null) ? (null) :
                                             (parameters.knowledgeBase));
    this.Serializable$set(
        'linkDatasetType',
        (parameters.linkDatasetType == null) ? (null) :
                                               (parameters.linkDatasetType));
  }

  static get LinkDatasetType():
      IEncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum {
    return EncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum;
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * Set when the suggestion list is a document. Format:
   * `projects//locations//knowledgeBases//documents/`.
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  get knowledgeBase(): string|null {
    return (
        (this.Serializable$has('knowledgeBase')) ?
            (this.Serializable$get('knowledgeBase')) :
            (null));
  }

  /**
   * Set when suggestion list is a knowledge base. Format:
   * `projects//locations//knowledgeBases/`.
   */
  set knowledgeBase(value: string|null) {
    this.Serializable$set('knowledgeBase', value);
  }

  get linkDatasetType():
      EncodeSuggestionListToConversationModelRequest_LinkDatasetType|null {
    return (
        (this.Serializable$has('linkDatasetType')) ?
            (this.Serializable$get('linkDatasetType')) :
            (null));
  }

  /**
   * Required. Dataset type for the suggestion list resource.
   */
  set linkDatasetType(
      value: EncodeSuggestionListToConversationModelRequest_LinkDatasetType|
      null) {
    this.Serializable$set('linkDatasetType', value);
  }

  getConstructor():
      SerializableCtor<EncodeSuggestionListToConversationModelRequest_> {
    return EncodeSuggestionListToConversationModelRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'linkDatasetType':
            EncodeSuggestionListToConversationModelRequest_LinkDatasetTypeEnum
      },
      keys: ['document', 'knowledgeBase', 'linkDatasetType']
    };
  }
}

export interface EnteringIntent_Parameters {
  id?: string|null;
  inputContextNames?: Array<string>|null;
  outputContexts?: Array<Context_>|null;
  messages?: Array<IntentMessage_>|null;
  webhookState?: EnteringIntent_WebhookState|null;
  allowBargeIn?: boolean|null;
  action?: string|null;
  condition?: string|null;
  nextNode?: string|null;
  conditionalMessages?: Array<IntentConditionalMessage_>|null;
}
export class EnteringIntent_ extends Serializable {
  constructor(parameters: EnteringIntent_Parameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'inputContextNames',
        (parameters.inputContextNames == null) ?
            (null) :
            (parameters.inputContextNames));
    this.Serializable$set(
        'outputContexts',
        (parameters.outputContexts == null) ? (null) :
                                              (parameters.outputContexts));
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'webhookState',
        (parameters.webhookState == null) ? (null) : (parameters.webhookState));
    this.Serializable$set(
        'allowBargeIn',
        (parameters.allowBargeIn == null) ? (null) : (parameters.allowBargeIn));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'nextNode',
        (parameters.nextNode == null) ? (null) : (parameters.nextNode));
    this.Serializable$set(
        'conditionalMessages',
        (parameters.conditionalMessages == null) ?
            (null) :
            (parameters.conditionalMessages));
  }

  static get WebhookState(): IEnteringIntent_WebhookStateEnum {
    return EnteringIntent_WebhookStateEnum;
  }

  get action(): string|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Optional. The name of the action associated with the intent. Note: The
   * action name must not contain whitespaces.
   */
  set action(value: string|null) {
    this.Serializable$set('action', value);
  }

  get allowBargeIn(): boolean|null {
    return (
        (this.Serializable$has('allowBargeIn')) ?
            (this.Serializable$get('allowBargeIn')) :
            (null));
  }

  /**
   * Optional. Whether barge-in is allowed for this intent's responses. If no
   * value is present, we use the setting defined at node level.
   */
  set allowBargeIn(value: boolean|null) {
    this.Serializable$set('allowBargeIn', value);
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * Optional. The condition used to activate the intent for being matched. This
   * is a boolean logical expression. If it is true, the intent is activated. It
   * can refer to context parameters in the form of
   * `#my-context-name.params.my-param-name`. See the [conditions
   * reference](https://cloud.google.com/dialogflow/priv/docs/v3/condition). By
   * default, the condition is true and the intent is always activated.
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get conditionalMessages(): Array<IntentConditionalMessage_>|null {
    return (
        (this.Serializable$has('conditionalMessages')) ?
            (this.Serializable$get('conditionalMessages')) :
            (null));
  }

  /**
   * Optional. Represents a list of conditional messages. Each case in the
   * message is associated with a condition. During query time, Dialogflow will
   * never populate conditional_messages. Instead, Dialogflow will append all
   * messages with conditions that are evaluated to true to messages.
   */
  set conditionalMessages(value: Array<IntentConditionalMessage_>|null) {
    this.Serializable$set('conditionalMessages', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Required. ID of the intent. Must be unique among all entering intents. Used
   * to compose intent display name.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get inputContextNames(): Array<string>|null {
    return (
        (this.Serializable$has('inputContextNames')) ?
            (this.Serializable$get('inputContextNames')) :
            (null));
  }

  /**
   * Optional. List of contexts that must be present in order to trigger this
   * intent.
   */
  set inputContextNames(value: Array<string>|null) {
    this.Serializable$set('inputContextNames', value);
  }

  get messages(): Array<IntentMessage_>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * Optional. List of response messages to prompt if this intent is matched.
   */
  set messages(value: Array<IntentMessage_>|null) {
    this.Serializable$set('messages', value);
  }

  get nextNode(): string|null {
    return (
        (this.Serializable$has('nextNode')) ?
            (this.Serializable$get('nextNode')) :
            (null));
  }

  /**
   * Optional. The next node to go to immediately when this `EnteringIntent` is
   * used. If empty, do not go to any node.
   */
  set nextNode(value: string|null) {
    this.Serializable$set('nextNode', value);
  }

  get outputContexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('outputContexts')) ?
            (this.Serializable$get('outputContexts')) :
            (null));
  }

  /**
   * Optional. List of contexts to output if this intent is matched.
   */
  set outputContexts(value: Array<Context_>|null) {
    this.Serializable$set('outputContexts', value);
  }

  get webhookState(): EnteringIntent_WebhookState|null {
    return (
        (this.Serializable$has('webhookState')) ?
            (this.Serializable$get('webhookState')) :
            (null));
  }

  /**
   * Optional. Whether webhook should be called if this intent is matched.
   */
  set webhookState(value: EnteringIntent_WebhookState|null) {
    this.Serializable$set('webhookState', value);
  }

  getConstructor(): SerializableCtor<EnteringIntent_> {
    return EnteringIntent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'conditionalMessages': IntentConditionalMessage_,
        'messages': IntentMessage_,
        'outputContexts': Context_
      },
      enums: {'webhookState': EnteringIntent_WebhookStateEnum},
      keys: [
        'action', 'allowBargeIn', 'condition', 'conditionalMessages', 'id',
        'inputContextNames', 'messages', 'nextNode', 'outputContexts',
        'webhookState'
      ]
    };
  }
}

export interface EntityTypeBatchSerialized_Parameters {
  entityTypesSerialized?: string|null;
  encoding?: string|null;
}
export class EntityTypeBatchSerialized_ extends Serializable {
  constructor(parameters: EntityTypeBatchSerialized_Parameters = {}) {
    super();
    this.Serializable$set(
        'entityTypesSerialized',
        (parameters.entityTypesSerialized == null) ?
            (null) :
            (parameters.entityTypesSerialized));
    this.Serializable$set(
        'encoding',
        (parameters.encoding == null) ? (null) : (parameters.encoding));
  }

  get encoding(): string|null {
    return (
        (this.Serializable$has('encoding')) ?
            (this.Serializable$get('encoding')) :
            (null));
  }

  /**
   * Optional. If in JSON format, character encoding of the serialized entity
   * types in bytes.
   */
  set encoding(value: string|null) {
    this.Serializable$set('encoding', value);
  }

  get entityTypesSerialized(): string|null {
    return (
        (this.Serializable$has('entityTypesSerialized')) ?
            (this.Serializable$get('entityTypesSerialized')) :
            (null));
  }

  /**
   * Serialized entity types in bytes.
   */
  set entityTypesSerialized(value: string|null) {
    this.Serializable$set('entityTypesSerialized', value);
  }

  getConstructor(): SerializableCtor<EntityTypeBatchSerialized_> {
    return EntityTypeBatchSerialized_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['encoding', 'entityTypesSerialized']};
  }
}

export interface EntityTypeBatch_Parameters {
  entityTypes?: Array<EntityType_>|null;
}
export class EntityTypeBatch_ extends Serializable {
  constructor(parameters: EntityTypeBatch_Parameters = {}) {
    super();
    this.Serializable$set(
        'entityTypes',
        (parameters.entityTypes == null) ? (null) : (parameters.entityTypes));
  }

  get entityTypes(): Array<EntityType_>|null {
    return (
        (this.Serializable$has('entityTypes')) ?
            (this.Serializable$get('entityTypes')) :
            (null));
  }

  /**
   * A collection of entity types.
   */
  set entityTypes(value: Array<EntityType_>|null) {
    this.Serializable$set('entityTypes', value);
  }

  getConstructor(): SerializableCtor<EntityTypeBatch_> {
    return EntityTypeBatch_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'entityTypes': EntityType_}, keys: ['entityTypes']};
  }
}

export interface EntityTypeEntity_Parameters {
  value?: string|null;
  synonyms?: Array<string>|null;
}
export class EntityTypeEntity_ extends Serializable {
  constructor(parameters: EntityTypeEntity_Parameters = {}) {
    super();
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
    this.Serializable$set(
        'synonyms',
        (parameters.synonyms == null) ? (null) : (parameters.synonyms));
  }

  get synonyms(): Array<string>|null {
    return (
        (this.Serializable$has('synonyms')) ?
            (this.Serializable$get('synonyms')) :
            (null));
  }

  /**
   * Required. A collection of value synonyms. For example, if the entity type
   * is *vegetable*, and `value` is *scallions*, a synonym could be *green
   * onions*. For `KIND_LIST` entity types: * This collection must contain
   * exactly one synonym equal to `value`.
   */
  set synonyms(value: Array<string>|null) {
    this.Serializable$set('synonyms', value);
  }

  get value(): string|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Required. The primary value associated with this entity entry. For example,
   * if the entity type is *vegetable*, the value could be *scallions*. For
   * `KIND_MAP` entity types: * A reference value to be used in place of
   * synonyms. For `KIND_LIST` entity types: * A string that can contain
   * references to other entity types (with or without aliases).
   */
  set value(value: string|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<EntityTypeEntity_> {
    return EntityTypeEntity_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['synonyms', 'value']};
  }
}

export interface EntityType_Parameters {
  name?: string|null;
  displayName?: string|null;
  kind?: EntityType_Kind|null;
  autoExpansionMode?: EntityType_AutoExpansionMode|null;
  disallowModificationsBySessionEntityTypes?: boolean|null;
  entities?: Array<EntityTypeEntity_>|null;
  enableFuzzyExtraction?: boolean|null;
}
export class EntityType_ extends Serializable {
  constructor(parameters: EntityType_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'kind', (parameters.kind == null) ? (null) : (parameters.kind));
    this.Serializable$set(
        'autoExpansionMode',
        (parameters.autoExpansionMode == null) ?
            (null) :
            (parameters.autoExpansionMode));
    this.Serializable$set(
        'disallowModificationsBySessionEntityTypes',
        (parameters.disallowModificationsBySessionEntityTypes == null) ?
            (null) :
            (parameters.disallowModificationsBySessionEntityTypes));
    this.Serializable$set(
        'entities',
        (parameters.entities == null) ? (null) : (parameters.entities));
    this.Serializable$set(
        'enableFuzzyExtraction',
        (parameters.enableFuzzyExtraction == null) ?
            (null) :
            (parameters.enableFuzzyExtraction));
  }

  static get AutoExpansionMode(): IEntityType_AutoExpansionModeEnum {
    return EntityType_AutoExpansionModeEnum;
  }

  static get Kind(): IEntityType_KindEnum {
    return EntityType_KindEnum;
  }

  get autoExpansionMode(): EntityType_AutoExpansionMode|null {
    return (
        (this.Serializable$has('autoExpansionMode')) ?
            (this.Serializable$get('autoExpansionMode')) :
            (null));
  }

  /**
   * Optional. Indicates whether the entity type can be automatically expanded.
   */
  set autoExpansionMode(value: EntityType_AutoExpansionMode|null) {
    this.Serializable$set('autoExpansionMode', value);
  }

  get disallowModificationsBySessionEntityTypes(): boolean|null {
    return (
        (this.Serializable$has('disallowModificationsBySessionEntityTypes')) ?
            (this.Serializable$get(
                'disallowModificationsBySessionEntityTypes')) :
            (null));
  }

  /**
   * Required. Indicates whether the custom entity type can be extended or
   * overridden by session entity types.
   */
  set disallowModificationsBySessionEntityTypes(value: boolean|null) {
    this.Serializable$set('disallowModificationsBySessionEntityTypes', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The name of the entity type.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get enableFuzzyExtraction(): boolean|null {
    return (
        (this.Serializable$has('enableFuzzyExtraction')) ?
            (this.Serializable$get('enableFuzzyExtraction')) :
            (null));
  }

  /**
   * Optional. Enables fuzzy entity extraction during classification.
   */
  set enableFuzzyExtraction(value: boolean|null) {
    this.Serializable$set('enableFuzzyExtraction', value);
  }

  get entities(): Array<EntityTypeEntity_>|null {
    return (
        (this.Serializable$has('entities')) ?
            (this.Serializable$get('entities')) :
            (null));
  }

  /**
   * Optional. The collection of entity entries associated with the entity type.
   */
  set entities(value: Array<EntityTypeEntity_>|null) {
    this.Serializable$set('entities', value);
  }

  get kind(): EntityType_Kind|null {
    return (
        (this.Serializable$has('kind')) ? (this.Serializable$get('kind')) :
                                          (null));
  }

  /**
   * Required. Indicates the kind of entity type.
   */
  set kind(value: EntityType_Kind|null) {
    this.Serializable$set('kind', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the entity type. Required for
   * EntityTypes.UpdateEntityType and EntityTypes.BatchUpdateEntityTypes
   * methods. Supported formats: - `projects//agent/entityTypes/` -
   * `projects//locations//agent/entityTypes/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<EntityType_> {
    return EntityType_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'entities': EntityTypeEntity_},
      enums: {
        'autoExpansionMode': EntityType_AutoExpansionModeEnum,
        'kind': EntityType_KindEnum
      },
      keys: [
        'autoExpansionMode', 'disallowModificationsBySessionEntityTypes',
        'displayName', 'enableFuzzyExtraction', 'entities', 'kind', 'name'
      ]
    };
  }
}

export interface EnvironmentHistoryEntry_Parameters {
  agentVersion?: string|null;
  description?: string|null;
  createTime?: string|null;
}
export class EnvironmentHistoryEntry_ extends Serializable {
  constructor(parameters: EnvironmentHistoryEntry_Parameters = {}) {
    super();
    this.Serializable$set(
        'agentVersion',
        (parameters.agentVersion == null) ? (null) : (parameters.agentVersion));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get agentVersion(): string|null {
    return (
        (this.Serializable$has('agentVersion')) ?
            (this.Serializable$get('agentVersion')) :
            (null));
  }

  /**
   * The agent version loaded into this environment history entry.
   */
  set agentVersion(value: string|null) {
    this.Serializable$set('agentVersion', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * The creation time of this environment history entry.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * The developer-provided description for this environment history entry.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  getConstructor(): SerializableCtor<EnvironmentHistoryEntry_> {
    return EnvironmentHistoryEntry_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agentVersion', 'createTime', 'description']};
  }
}

export interface EnvironmentHistory_Parameters {
  parent?: string|null;
  entries?: Array<EnvironmentHistoryEntry_>|null;
  nextPageToken?: string|null;
}
export class EnvironmentHistory_ extends Serializable {
  constructor(parameters: EnvironmentHistory_Parameters = {}) {
    super();
    this.Serializable$set(
        'parent', (parameters.parent == null) ? (null) : (parameters.parent));
    this.Serializable$set(
        'entries',
        (parameters.entries == null) ? (null) : (parameters.entries));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get entries(): Array<EnvironmentHistoryEntry_>|null {
    return (
        (this.Serializable$has('entries')) ?
            (this.Serializable$get('entries')) :
            (null));
  }

  /**
   * Output only. The list of agent environments. There will be a maximum number
   * of items returned based on the page_size field in the request.
   */
  set entries(value: Array<EnvironmentHistoryEntry_>|null) {
    this.Serializable$set('entries', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Output only. Token to retrieve the next page of results, or empty if there
   * are no more results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get parent(): string|null {
    return (
        (this.Serializable$has('parent')) ? (this.Serializable$get('parent')) :
                                            (null));
  }

  /**
   * Output only. The name of the environment this history is for. Supported
   * formats: - `projects//agent/environments/` -
   * `projects//locations//agent/environments/`
   */
  set parent(value: string|null) {
    this.Serializable$set('parent', value);
  }

  getConstructor(): SerializableCtor<EnvironmentHistory_> {
    return EnvironmentHistory_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'entries': EnvironmentHistoryEntry_},
      keys: ['entries', 'nextPageToken', 'parent']
    };
  }
}

export interface Environment_Parameters {
  name?: string|null;
  description?: string|null;
  agentVersion?: string|null;
  state?: Environment_State|null;
  updateTime?: string|null;
  webhookSettings?: WebhookSettings_|null;
  textToSpeechSettings?: TextToSpeechSettings_|null;
  fulfillment?: Fulfillment_|null;
}
export class Environment_ extends Serializable {
  constructor(parameters: Environment_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'agentVersion',
        (parameters.agentVersion == null) ? (null) : (parameters.agentVersion));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'webhookSettings',
        (parameters.webhookSettings == null) ? (null) :
                                               (parameters.webhookSettings));
    this.Serializable$set(
        'textToSpeechSettings',
        (parameters.textToSpeechSettings == null) ?
            (null) :
            (parameters.textToSpeechSettings));
    this.Serializable$set(
        'fulfillment',
        (parameters.fulfillment == null) ? (null) : (parameters.fulfillment));
  }

  static get State(): IEnvironment_StateEnum {
    return Environment_StateEnum;
  }

  get agentVersion(): string|null {
    return (
        (this.Serializable$has('agentVersion')) ?
            (this.Serializable$get('agentVersion')) :
            (null));
  }

  /**
   * Optional. The agent version loaded into this environment. Supported
   * formats: - `projects//agent/versions/` -
   * `projects//locations//agent/versions/`
   */
  set agentVersion(value: string|null) {
    this.Serializable$set('agentVersion', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. The developer-provided description for this environment. The
   * maximum length is 500 characters. If exceeded, the request is rejected.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get fulfillment(): Fulfillment_|null {
    return (
        (this.Serializable$has('fulfillment')) ?
            (this.Serializable$get('fulfillment')) :
            (null));
  }

  /**
   * Optional. The fulfillment settings to use for this environment.
   */
  set fulfillment(value: Fulfillment_|null) {
    this.Serializable$set('fulfillment', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this agent environment. Supported
   * formats: - `projects//agent/environments/` -
   * `projects//locations//agent/environments/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get state(): Environment_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Output only. The state of this environment. This field is read-only, i.e.,
   * it cannot be set by create and update methods.
   */
  set state(value: Environment_State|null) {
    this.Serializable$set('state', value);
  }

  get textToSpeechSettings(): TextToSpeechSettings_|null {
    return (
        (this.Serializable$has('textToSpeechSettings')) ?
            (this.Serializable$get('textToSpeechSettings')) :
            (null));
  }

  /**
   * Optional. Text to speech settings for this environment.
   */
  set textToSpeechSettings(value: TextToSpeechSettings_|null) {
    this.Serializable$set('textToSpeechSettings', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. The last update time of this environment. This field is
   * read-only, i.e., it cannot be set by create and update methods.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  get webhookSettings(): WebhookSettings_|null {
    return (
        (this.Serializable$has('webhookSettings')) ?
            (this.Serializable$get('webhookSettings')) :
            (null));
  }

  /**
   * Optional. Webhook settings for this environment.
   */
  set webhookSettings(value: WebhookSettings_|null) {
    this.Serializable$set('webhookSettings', value);
  }

  getConstructor(): SerializableCtor<Environment_> {
    return Environment_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': Environment_StateEnum},
      keys: [
        'agentVersion', 'description', 'fulfillment', 'name', 'state',
        'textToSpeechSettings', 'updateTime', 'webhookSettings'
      ],
      objects: {
        'fulfillment': Fulfillment_,
        'textToSpeechSettings': TextToSpeechSettings_,
        'webhookSettings': WebhookSettings_
      }
    };
  }
}

export interface EvaluateModelRequest_Parameters {
  environment?: string|null;
  languageCode?: string|null;
  gcsSources?: GcsSources_|null;
}
export class EvaluateModelRequest_ extends Serializable {
  constructor(parameters: EvaluateModelRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'gcsSources',
        (parameters.gcsSources == null) ? (null) : (parameters.gcsSources));
  }

  get environment(): string|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * Optional. The ID of the environment that model evaluation will be performed
   * on. If environment is not specified, we assume default 'draft' environment.
   */
  set environment(value: string|null) {
    this.Serializable$set('environment', value);
  }

  get gcsSources(): GcsSources_|null {
    return (
        (this.Serializable$has('gcsSources')) ?
            (this.Serializable$get('gcsSources')) :
            (null));
  }

  /**
   * Required. The Cloud Storage location for the input dataset.
   */
  set gcsSources(value: GcsSources_|null) {
    this.Serializable$set('gcsSources', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of the input dataset.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  getConstructor(): SerializableCtor<EvaluateModelRequest_> {
    return EvaluateModelRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['environment', 'gcsSources', 'languageCode'],
      objects: {'gcsSources': GcsSources_}
    };
  }
}

export interface EvaluationConfigHumanEvalConfig_Parameters {
  humanEvalTemplateSampleSize?: number|null;
  humanEvalTemplateSampleSeed?: number|null;
}
export class EvaluationConfigHumanEvalConfig_ extends Serializable {
  constructor(parameters: EvaluationConfigHumanEvalConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'humanEvalTemplateSampleSize',
        (parameters.humanEvalTemplateSampleSize == null) ?
            (null) :
            (parameters.humanEvalTemplateSampleSize));
    this.Serializable$set(
        'humanEvalTemplateSampleSeed',
        (parameters.humanEvalTemplateSampleSeed == null) ?
            (null) :
            (parameters.humanEvalTemplateSampleSeed));
  }

  get humanEvalTemplateSampleSeed(): number|null {
    return (
        (this.Serializable$has('humanEvalTemplateSampleSeed')) ?
            (this.Serializable$get('humanEvalTemplateSampleSeed')) :
            (null));
  }

  /**
   * Optional. The sample seed used to generate human eval template entries.
   */
  set humanEvalTemplateSampleSeed(value: number|null) {
    this.Serializable$set('humanEvalTemplateSampleSeed', value);
  }

  get humanEvalTemplateSampleSize(): number|null {
    return (
        (this.Serializable$has('humanEvalTemplateSampleSize')) ?
            (this.Serializable$get('humanEvalTemplateSampleSize')) :
            (null));
  }

  /**
   * Optional. The size of human eval template samples. Each sample consist of a
   * conversation context with ground truth and a set of smart reply suggestions
   * that suggested based on the conversation context. Range [10, 100]
   * inclusive, if dataset contains less than sample size conversations, full
   * dataset will be used.
   */
  set humanEvalTemplateSampleSize(value: number|null) {
    this.Serializable$set('humanEvalTemplateSampleSize', value);
  }

  getConstructor(): SerializableCtor<EvaluationConfigHumanEvalConfig_> {
    return EvaluationConfigHumanEvalConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['humanEvalTemplateSampleSeed', 'humanEvalTemplateSampleSize']
    };
  }
}

export interface EvaluationConfigSmartComposeConfig_Parameters {
  allowlistDocument?: string|null;
  maxResultCount?: number|null;
}
export class EvaluationConfigSmartComposeConfig_ extends Serializable {
  constructor(parameters: EvaluationConfigSmartComposeConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'allowlistDocument',
        (parameters.allowlistDocument == null) ?
            (null) :
            (parameters.allowlistDocument));
    this.Serializable$set(
        'maxResultCount',
        (parameters.maxResultCount == null) ? (null) :
                                              (parameters.maxResultCount));
  }

  get allowlistDocument(): string|null {
    return (
        (this.Serializable$has('allowlistDocument')) ?
            (this.Serializable$get('allowlistDocument')) :
            (null));
  }

  /**
   * The allowlist document resource name. Format:
   * `projects//knowledgeBases//documents/`. Only used for smart compose model.
   */
  set allowlistDocument(value: string|null) {
    this.Serializable$set('allowlistDocument', value);
  }

  get maxResultCount(): number|null {
    return (
        (this.Serializable$has('maxResultCount')) ?
            (this.Serializable$get('maxResultCount')) :
            (null));
  }

  /**
   * Required. The model to be evaluated can return multiple results with
   * confidence score on each query. These results will be sorted by the
   * descending order of the scores and we only keep the first max_result_count
   * results as the final results to evaluate.
   */
  set maxResultCount(value: number|null) {
    this.Serializable$set('maxResultCount', value);
  }

  getConstructor(): SerializableCtor<EvaluationConfigSmartComposeConfig_> {
    return EvaluationConfigSmartComposeConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowlistDocument', 'maxResultCount']};
  }
}

export interface EvaluationConfigSmartReplyConfig_Parameters {
  allowlistDocument?: string|null;
  maxResultCount?: number|null;
}
export class EvaluationConfigSmartReplyConfig_ extends Serializable {
  constructor(parameters: EvaluationConfigSmartReplyConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'allowlistDocument',
        (parameters.allowlistDocument == null) ?
            (null) :
            (parameters.allowlistDocument));
    this.Serializable$set(
        'maxResultCount',
        (parameters.maxResultCount == null) ? (null) :
                                              (parameters.maxResultCount));
  }

  get allowlistDocument(): string|null {
    return (
        (this.Serializable$has('allowlistDocument')) ?
            (this.Serializable$get('allowlistDocument')) :
            (null));
  }

  /**
   * The allowlist document resource name. Format:
   * `projects//knowledgeBases//documents/`. Only used for smart reply model.
   */
  set allowlistDocument(value: string|null) {
    this.Serializable$set('allowlistDocument', value);
  }

  get maxResultCount(): number|null {
    return (
        (this.Serializable$has('maxResultCount')) ?
            (this.Serializable$get('maxResultCount')) :
            (null));
  }

  /**
   * Required. The model to be evaluated can return multiple results with
   * confidence score on each query. These results will be sorted by the
   * descending order of the scores and we only keep the first max_result_count
   * results as the final results to evaluate.
   */
  set maxResultCount(value: number|null) {
    this.Serializable$set('maxResultCount', value);
  }

  getConstructor(): SerializableCtor<EvaluationConfigSmartReplyConfig_> {
    return EvaluationConfigSmartReplyConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowlistDocument', 'maxResultCount']};
  }
}

export interface EvaluationConfig_Parameters {
  datasets?: Array<InputDataset_>|null;
  smartReplyConfig?: EvaluationConfigSmartReplyConfig_|null;
  smartComposeConfig?: EvaluationConfigSmartComposeConfig_|null;
  humanEvalConfig?: EvaluationConfigHumanEvalConfig_|null;
}
export class EvaluationConfig_ extends Serializable {
  constructor(parameters: EvaluationConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'datasets',
        (parameters.datasets == null) ? (null) : (parameters.datasets));
    this.Serializable$set(
        'smartReplyConfig',
        (parameters.smartReplyConfig == null) ? (null) :
                                                (parameters.smartReplyConfig));
    this.Serializable$set(
        'smartComposeConfig',
        (parameters.smartComposeConfig == null) ?
            (null) :
            (parameters.smartComposeConfig));
    this.Serializable$set(
        'humanEvalConfig',
        (parameters.humanEvalConfig == null) ? (null) :
                                               (parameters.humanEvalConfig));
  }

  get datasets(): Array<InputDataset_>|null {
    return (
        (this.Serializable$has('datasets')) ?
            (this.Serializable$get('datasets')) :
            (null));
  }

  /**
   * Required. Datasets used for evaluation.
   */
  set datasets(value: Array<InputDataset_>|null) {
    this.Serializable$set('datasets', value);
  }

  get humanEvalConfig(): EvaluationConfigHumanEvalConfig_|null {
    return (
        (this.Serializable$has('humanEvalConfig')) ?
            (this.Serializable$get('humanEvalConfig')) :
            (null));
  }

  /**
   * Configations regarding human evaluation template generation.
   */
  set humanEvalConfig(value: EvaluationConfigHumanEvalConfig_|null) {
    this.Serializable$set('humanEvalConfig', value);
  }

  get smartComposeConfig(): EvaluationConfigSmartComposeConfig_|null {
    return (
        (this.Serializable$has('smartComposeConfig')) ?
            (this.Serializable$get('smartComposeConfig')) :
            (null));
  }

  /**
   * Configuration for smart compose model evalution.
   */
  set smartComposeConfig(value: EvaluationConfigSmartComposeConfig_|null) {
    this.Serializable$set('smartComposeConfig', value);
  }

  get smartReplyConfig(): EvaluationConfigSmartReplyConfig_|null {
    return (
        (this.Serializable$has('smartReplyConfig')) ?
            (this.Serializable$get('smartReplyConfig')) :
            (null));
  }

  /**
   * Configuration for smart reply model evalution.
   */
  set smartReplyConfig(value: EvaluationConfigSmartReplyConfig_|null) {
    this.Serializable$set('smartReplyConfig', value);
  }

  getConstructor(): SerializableCtor<EvaluationConfig_> {
    return EvaluationConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'datasets': InputDataset_},
      keys: [
        'datasets', 'humanEvalConfig', 'smartComposeConfig', 'smartReplyConfig'
      ],
      objects: {
        'humanEvalConfig': EvaluationConfigHumanEvalConfig_,
        'smartComposeConfig': EvaluationConfigSmartComposeConfig_,
        'smartReplyConfig': EvaluationConfigSmartReplyConfig_
      }
    };
  }
}

export interface EventInput_Parameters {
  name?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
  languageCode?: string|null;
}
export class EventInput_ extends Serializable {
  constructor(parameters: EventInput_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of this query. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes. Note that queries in the
   * same session do not necessarily need to specify the same language. This
   * field is ignored when used in the context of a
   * WebhookResponse.followup_event_input field, because the language was
   * already defined in the originating detect intent request.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of the event.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of parameters associated with the event. Depending on your
   * protocol or client library language, this is a map, associative array,
   * symbol table, dictionary, or JSON object composed of a collection of
   * (MapKey, MapValue) pairs: - MapKey type: string - MapKey value: parameter
   * name - MapValue type: - If parameter's entity type is a composite entity:
   * map - Else: depending on parameter value type, could be one of string,
   * number, boolean, null, list or map - MapValue value: - If parameter's
   * entity type is a composite entity: map from composite entity property names
   * to property values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<EventInput_> {
    return EventInput_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['languageCode', 'name', 'parameters'],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface ExitingIntent_Parameters {
  id?: string|null;
  nextNode?: string|null;
  trainingPhrases?: Array<IntentTrainingPhrase_>|null;
  parameters?: Array<IntentParameter_>|null;
  outputContexts?: Array<Context_>|null;
  webhookState?: ExitingIntent_WebhookState|null;
  inputContextNames?: Array<string>|null;
  events?: Array<string>|null;
  action?: string|null;
  condition?: string|null;
}
export class ExitingIntent_ extends Serializable {
  constructor(parameters: ExitingIntent_Parameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'nextNode',
        (parameters.nextNode == null) ? (null) : (parameters.nextNode));
    this.Serializable$set(
        'trainingPhrases',
        (parameters.trainingPhrases == null) ? (null) :
                                               (parameters.trainingPhrases));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'outputContexts',
        (parameters.outputContexts == null) ? (null) :
                                              (parameters.outputContexts));
    this.Serializable$set(
        'webhookState',
        (parameters.webhookState == null) ? (null) : (parameters.webhookState));
    this.Serializable$set(
        'inputContextNames',
        (parameters.inputContextNames == null) ?
            (null) :
            (parameters.inputContextNames));
    this.Serializable$set(
        'events', (parameters.events == null) ? (null) : (parameters.events));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
  }

  static get WebhookState(): IExitingIntent_WebhookStateEnum {
    return ExitingIntent_WebhookStateEnum;
  }

  get action(): string|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Optional. The name of the action associated with the intent. Note: The
   * action name must not contain whitespaces.
   */
  set action(value: string|null) {
    this.Serializable$set('action', value);
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * Optional. The condition used to activate the intent for being matched. This
   * is a boolean logical expression. If it is true, the intent is activated. It
   * can refer to context parameters in the form of
   * `#my-context-name.params.my-param-name`. See the [conditions
   * reference](https://cloud.google.com/dialogflow/priv/docs/v3/condition). By
   * default, the condition is true and the intent is always activated.
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get events(): Array<string>|null {
    return (
        (this.Serializable$has('events')) ? (this.Serializable$get('events')) :
                                            (null));
  }

  /**
   * Optional. The collection of event names that trigger the intent. This can
   * be any string that represents an event name.
   */
  set events(value: Array<string>|null) {
    this.Serializable$set('events', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Required. ID of the intent. Must be unique among all exiting intents.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get inputContextNames(): Array<string>|null {
    return (
        (this.Serializable$has('inputContextNames')) ?
            (this.Serializable$get('inputContextNames')) :
            (null));
  }

  /**
   * Optional. List of contexts that must be present in order to trigger this
   * intent.
   */
  set inputContextNames(value: Array<string>|null) {
    this.Serializable$set('inputContextNames', value);
  }

  get nextNode(): string|null {
    return (
        (this.Serializable$has('nextNode')) ?
            (this.Serializable$get('nextNode')) :
            (null));
  }

  /**
   * Required. The next node or event to transition to when the intent is
   * matched. Format: * When transitioning to a node:
   * `projects//agent/ivrNodes/`. * When transitioning to an event: ``, e.g.
   * `WELCOME`.
   */
  set nextNode(value: string|null) {
    this.Serializable$set('nextNode', value);
  }

  get outputContexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('outputContexts')) ?
            (this.Serializable$get('outputContexts')) :
            (null));
  }

  /**
   * Optional. List of contexts to output if this intent is matched.
   */
  set outputContexts(value: Array<Context_>|null) {
    this.Serializable$set('outputContexts', value);
  }

  get parameters(): Array<IntentParameter_>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional. The collection of parameters associated with the intent.
   */
  set parameters(value: Array<IntentParameter_>|null) {
    this.Serializable$set('parameters', value);
  }

  get trainingPhrases(): Array<IntentTrainingPhrase_>|null {
    return (
        (this.Serializable$has('trainingPhrases')) ?
            (this.Serializable$get('trainingPhrases')) :
            (null));
  }

  /**
   * Required. List of training phrases that can be used to match this intent.
   */
  set trainingPhrases(value: Array<IntentTrainingPhrase_>|null) {
    this.Serializable$set('trainingPhrases', value);
  }

  get webhookState(): ExitingIntent_WebhookState|null {
    return (
        (this.Serializable$has('webhookState')) ?
            (this.Serializable$get('webhookState')) :
            (null));
  }

  /**
   * Optional. Whether webhook should be called if this intent is matched.
   */
  set webhookState(value: ExitingIntent_WebhookState|null) {
    this.Serializable$set('webhookState', value);
  }

  getConstructor(): SerializableCtor<ExitingIntent_> {
    return ExitingIntent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'outputContexts': Context_,
        'parameters': IntentParameter_,
        'trainingPhrases': IntentTrainingPhrase_
      },
      enums: {'webhookState': ExitingIntent_WebhookStateEnum},
      keys: [
        'action', 'condition', 'events', 'id', 'inputContextNames', 'nextNode',
        'outputContexts', 'parameters', 'trainingPhrases', 'webhookState'
      ]
    };
  }
}

export interface ExpanderEmotionPredictionEmotionPredictionParameters {
  classId?: string|null;
  classLabel?: string|null;
  score?: number|null;
}
export class ExpanderEmotionPredictionEmotionPrediction extends Serializable {
  constructor(
      parameters: ExpanderEmotionPredictionEmotionPredictionParameters = {}) {
    super();
    this.Serializable$set(
        'classId',
        (parameters.classId == null) ? (null) : (parameters.classId));
    this.Serializable$set(
        'classLabel',
        (parameters.classLabel == null) ? (null) : (parameters.classLabel));
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
  }

  get classId(): string|null {
    return (
        (this.Serializable$has('classId')) ?
            (this.Serializable$get('classId')) :
            (null));
  }

  /**
   * ID of the predicted class (which references a loaded vocabulary).
   */
  set classId(value: string|null) {
    this.Serializable$set('classId', value);
  }

  get classLabel(): string|null {
    return (
        (this.Serializable$has('classLabel')) ?
            (this.Serializable$get('classLabel')) :
            (null));
  }

  /**
   * Class label in string format.
   */
  set classLabel(value: string|null) {
    this.Serializable$set('classLabel', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Prediction score.
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor():
      SerializableCtor<ExpanderEmotionPredictionEmotionPrediction> {
    return ExpanderEmotionPredictionEmotionPrediction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['classId', 'classLabel', 'score']};
  }
}

export interface ExpanderEmotionPredictionEmotionPredictionResultsParameters {
  emotion?: Array<ExpanderEmotionPredictionEmotionPrediction>|null;
  emoji?: Array<ExpanderEmotionPredictionEmotionPrediction>|null;
  sticker?: Array<ExpanderEmotionPredictionEmotionPrediction>|null;
}
export class ExpanderEmotionPredictionEmotionPredictionResults extends
    Serializable {
  constructor(
      parameters:
          ExpanderEmotionPredictionEmotionPredictionResultsParameters = {}) {
    super();
    this.Serializable$set(
        'emotion',
        (parameters.emotion == null) ? (null) : (parameters.emotion));
    this.Serializable$set(
        'emoji', (parameters.emoji == null) ? (null) : (parameters.emoji));
    this.Serializable$set(
        'sticker',
        (parameters.sticker == null) ? (null) : (parameters.sticker));
  }

  get emoji(): Array<ExpanderEmotionPredictionEmotionPrediction>|null {
    return (
        (this.Serializable$has('emoji')) ? (this.Serializable$get('emoji')) :
                                           (null));
  }

  /**
   * Predictions represented by emojis.
   */
  set emoji(value: Array<ExpanderEmotionPredictionEmotionPrediction>|null) {
    this.Serializable$set('emoji', value);
  }

  get emotion(): Array<ExpanderEmotionPredictionEmotionPrediction>|null {
    return (
        (this.Serializable$has('emotion')) ?
            (this.Serializable$get('emotion')) :
            (null));
  }

  /**
   * Predictions represented by emotion taxonomy.
   */
  set emotion(value: Array<ExpanderEmotionPredictionEmotionPrediction>|null) {
    this.Serializable$set('emotion', value);
  }

  get sticker(): Array<ExpanderEmotionPredictionEmotionPrediction>|null {
    return (
        (this.Serializable$has('sticker')) ?
            (this.Serializable$get('sticker')) :
            (null));
  }

  /**
   * Predictions represented by stickers.
   */
  set sticker(value: Array<ExpanderEmotionPredictionEmotionPrediction>|null) {
    this.Serializable$set('sticker', value);
  }

  getConstructor():
      SerializableCtor<ExpanderEmotionPredictionEmotionPredictionResults> {
    return ExpanderEmotionPredictionEmotionPredictionResults;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'emoji': ExpanderEmotionPredictionEmotionPrediction,
        'emotion': ExpanderEmotionPredictionEmotionPrediction,
        'sticker': ExpanderEmotionPredictionEmotionPrediction
      },
      keys: ['emoji', 'emotion', 'sticker']
    };
  }
}

export interface ExportAgentRequest_Parameters {
  agentUri?: string|null;
  exportAgentFormat?: ExportAgentRequest_ExportAgentFormat|null;
}
export class ExportAgentRequest_ extends Serializable {
  constructor(parameters: ExportAgentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'agentUri',
        (parameters.agentUri == null) ? (null) : (parameters.agentUri));
    this.Serializable$set(
        'exportAgentFormat',
        (parameters.exportAgentFormat == null) ?
            (null) :
            (parameters.exportAgentFormat));
  }

  static get ExportAgentFormat(): IExportAgentRequest_ExportAgentFormatEnum {
    return ExportAgentRequest_ExportAgentFormatEnum;
  }

  get agentUri(): string|null {
    return (
        (this.Serializable$has('agentUri')) ?
            (this.Serializable$get('agentUri')) :
            (null));
  }

  /**
   * Optional. The [Google Cloud
   * Storage](https://cloud.google.com/storage/docs/) URI to export the agent
   * to. The format of this URI must be `gs:///`. If left unspecified, the
   * serialized agent is returned inline. Dialogflow performs a write operation
   * for the Cloud Storage object on the caller's behalf, so your request
   * authentication must have write permissions for the object. For more
   * information, see [Dialogflow access
   * control](https://cloud.google.com/dialogflow/cx/docs/concept/access-control#storage).
   */
  set agentUri(value: string|null) {
    this.Serializable$set('agentUri', value);
  }

  get exportAgentFormat(): ExportAgentRequest_ExportAgentFormat|null {
    return (
        (this.Serializable$has('exportAgentFormat')) ?
            (this.Serializable$get('exportAgentFormat')) :
            (null));
  }

  /**
   * Optional. Specifies the format for agent export. If left unspecified,
   * ExportAgentFormatType.STANDARD will be used.
   */
  set exportAgentFormat(value: ExportAgentRequest_ExportAgentFormat|null) {
    this.Serializable$set('exportAgentFormat', value);
  }

  getConstructor(): SerializableCtor<ExportAgentRequest_> {
    return ExportAgentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'exportAgentFormat': ExportAgentRequest_ExportAgentFormatEnum},
      keys: ['agentUri', 'exportAgentFormat']
    };
  }
}

export interface ExportAgentResponse_Parameters {
  agentUri?: string|null;
  agentContent?: string|null;
}
export class ExportAgentResponse_ extends Serializable {
  constructor(parameters: ExportAgentResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'agentUri',
        (parameters.agentUri == null) ? (null) : (parameters.agentUri));
    this.Serializable$set(
        'agentContent',
        (parameters.agentContent == null) ? (null) : (parameters.agentContent));
  }

  get agentContent(): string|null {
    return (
        (this.Serializable$has('agentContent')) ?
            (this.Serializable$get('agentContent')) :
            (null));
  }

  /**
   * Zip compressed raw byte content for agent.
   */
  set agentContent(value: string|null) {
    this.Serializable$set('agentContent', value);
  }

  get agentUri(): string|null {
    return (
        (this.Serializable$has('agentUri')) ?
            (this.Serializable$get('agentUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported agent. This field is populated
   * only if `agent_uri` is specified in `ExportAgentRequest`.
   */
  set agentUri(value: string|null) {
    this.Serializable$set('agentUri', value);
  }

  getConstructor(): SerializableCtor<ExportAgentResponse_> {
    return ExportAgentResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agentContent', 'agentUri']};
  }
}

export interface ExportDocumentRequest_Parameters {
  gcsDestination?: GcsDestination_|null;
  exportFullContent?: boolean|null;
  smartMessagingPartialUpdate?: boolean|null;
}
export class ExportDocumentRequest_ extends Serializable {
  constructor(parameters: ExportDocumentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'gcsDestination',
        (parameters.gcsDestination == null) ? (null) :
                                              (parameters.gcsDestination));
    this.Serializable$set(
        'exportFullContent',
        (parameters.exportFullContent == null) ?
            (null) :
            (parameters.exportFullContent));
    this.Serializable$set(
        'smartMessagingPartialUpdate',
        (parameters.smartMessagingPartialUpdate == null) ?
            (null) :
            (parameters.smartMessagingPartialUpdate));
  }

  get exportFullContent(): boolean|null {
    return (
        (this.Serializable$has('exportFullContent')) ?
            (this.Serializable$get('exportFullContent')) :
            (null));
  }

  /**
   * When enabled, export the full content of the document including empirical
   * probability.
   */
  set exportFullContent(value: boolean|null) {
    this.Serializable$set('exportFullContent', value);
  }

  get gcsDestination(): GcsDestination_|null {
    return (
        (this.Serializable$has('gcsDestination')) ?
            (this.Serializable$get('gcsDestination')) :
            (null));
  }

  /**
   * Cloud Storage file path to export the document.
   */
  set gcsDestination(value: GcsDestination_|null) {
    this.Serializable$set('gcsDestination', value);
  }

  get smartMessagingPartialUpdate(): boolean|null {
    return (
        (this.Serializable$has('smartMessagingPartialUpdate')) ?
            (this.Serializable$get('smartMessagingPartialUpdate')) :
            (null));
  }

  /**
   * When enabled, export the smart messaging allowlist document for partial
   * update.
   */
  set smartMessagingPartialUpdate(value: boolean|null) {
    this.Serializable$set('smartMessagingPartialUpdate', value);
  }

  getConstructor(): SerializableCtor<ExportDocumentRequest_> {
    return ExportDocumentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'exportFullContent', 'gcsDestination', 'smartMessagingPartialUpdate'
      ],
      objects: {'gcsDestination': GcsDestination_}
    };
  }
}

export interface ExportMessageNodeRequest_Parameters {
  dialogflowResource?: DialogflowResource_|null;
}
export class ExportMessageNodeRequest_ extends Serializable {
  constructor(parameters: ExportMessageNodeRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'dialogflowResource',
        (parameters.dialogflowResource == null) ?
            (null) :
            (parameters.dialogflowResource));
  }

  get dialogflowResource(): DialogflowResource_|null {
    return (
        (this.Serializable$has('dialogflowResource')) ?
            (this.Serializable$get('dialogflowResource')) :
            (null));
  }

  /**
   * Required. The dialogflow_resource to export.
   */
  set dialogflowResource(value: DialogflowResource_|null) {
    this.Serializable$set('dialogflowResource', value);
  }

  getConstructor(): SerializableCtor<ExportMessageNodeRequest_> {
    return ExportMessageNodeRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['dialogflowResource'],
      objects: {'dialogflowResource': DialogflowResource_}
    };
  }
}

export interface ExportMessageNodeResponse_Parameters {
  dialogflowResource?: DialogflowResource_|null;
}
export class ExportMessageNodeResponse_ extends Serializable {
  constructor(parameters: ExportMessageNodeResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'dialogflowResource',
        (parameters.dialogflowResource == null) ?
            (null) :
            (parameters.dialogflowResource));
  }

  get dialogflowResource(): DialogflowResource_|null {
    return (
        (this.Serializable$has('dialogflowResource')) ?
            (this.Serializable$get('dialogflowResource')) :
            (null));
  }

  /**
   * The exported dialogflow resource.
   */
  set dialogflowResource(value: DialogflowResource_|null) {
    this.Serializable$set('dialogflowResource', value);
  }

  getConstructor(): SerializableCtor<ExportMessageNodeResponse_> {
    return ExportMessageNodeResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['dialogflowResource'],
      objects: {'dialogflowResource': DialogflowResource_}
    };
  }
}

export interface ExportMessagesResponseExportFailure_Parameters {
  conversation?: string|null;
  status?: GoogleRpcStatus|null;
}
export class ExportMessagesResponseExportFailure_ extends Serializable {
  constructor(parameters: ExportMessagesResponseExportFailure_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversation',
        (parameters.conversation == null) ? (null) : (parameters.conversation));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
  }

  get conversation(): string|null {
    return (
        (this.Serializable$has('conversation')) ?
            (this.Serializable$get('conversation')) :
            (null));
  }

  /**
   * The resource name of the conversation that failed to export. Format:
   * `projects//locations//conversations/`
   */
  set conversation(value: string|null) {
    this.Serializable$set('conversation', value);
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * The status indicating why the export failed.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  getConstructor(): SerializableCtor<ExportMessagesResponseExportFailure_> {
    return ExportMessagesResponseExportFailure_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['conversation', 'status'],
      objects: {'status': GoogleRpcStatus}
    };
  }
}

export interface ExportMessagesResponse_Parameters {
  exportedConversationsCount?: number|null;
  exportFailures?: Array<ExportMessagesResponseExportFailure_>|null;
}
export class ExportMessagesResponse_ extends Serializable {
  constructor(parameters: ExportMessagesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'exportedConversationsCount',
        (parameters.exportedConversationsCount == null) ?
            (null) :
            (parameters.exportedConversationsCount));
    this.Serializable$set(
        'exportFailures',
        (parameters.exportFailures == null) ? (null) :
                                              (parameters.exportFailures));
  }

  get exportFailures(): Array<ExportMessagesResponseExportFailure_>|null {
    return (
        (this.Serializable$has('exportFailures')) ?
            (this.Serializable$get('exportFailures')) :
            (null));
  }

  /**
   * The conversations that failed to export.
   */
  set exportFailures(value: Array<ExportMessagesResponseExportFailure_>|null) {
    this.Serializable$set('exportFailures', value);
  }

  get exportedConversationsCount(): number|null {
    return (
        (this.Serializable$has('exportedConversationsCount')) ?
            (this.Serializable$get('exportedConversationsCount')) :
            (null));
  }

  /**
   * The number of conversations successfully written to the destination. Since
   * the caller specified a high-level filter in the `ExportMessagesRequest`,
   * they may not necessarily know beforehand how many conversations will be
   * exported.
   */
  set exportedConversationsCount(value: number|null) {
    this.Serializable$set('exportedConversationsCount', value);
  }

  getConstructor(): SerializableCtor<ExportMessagesResponse_> {
    return ExportMessagesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'exportFailures': ExportMessagesResponseExportFailure_},
      keys: ['exportFailures', 'exportedConversationsCount']
    };
  }
}

export interface ExportOperationMetadata_Parameters {
  exportedGcsDestination?: GcsDestination_|null;
}
export class ExportOperationMetadata_ extends Serializable {
  constructor(parameters: ExportOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'exportedGcsDestination',
        (parameters.exportedGcsDestination == null) ?
            (null) :
            (parameters.exportedGcsDestination));
  }

  get exportedGcsDestination(): GcsDestination_|null {
    return (
        (this.Serializable$has('exportedGcsDestination')) ?
            (this.Serializable$get('exportedGcsDestination')) :
            (null));
  }

  /**
   * Cloud Storage file path of the exported data.
   */
  set exportedGcsDestination(value: GcsDestination_|null) {
    this.Serializable$set('exportedGcsDestination', value);
  }

  getConstructor(): SerializableCtor<ExportOperationMetadata_> {
    return ExportOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['exportedGcsDestination'],
      objects: {'exportedGcsDestination': GcsDestination_}
    };
  }
}

export interface FaqAnswer_Parameters {
  answer?: string|null;
  confidence?: number|null;
  question?: string|null;
  source?: string|null;
  metadata?: ApiClientObjectMap<string>|null;
  answerRecord?: string|null;
}
export class FaqAnswer_ extends Serializable {
  constructor(parameters: FaqAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'answer', (parameters.answer == null) ? (null) : (parameters.answer));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'question',
        (parameters.question == null) ? (null) : (parameters.question));
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answer(): string|null {
    return (
        (this.Serializable$has('answer')) ? (this.Serializable$get('answer')) :
                                            (null));
  }

  /**
   * The piece of text from the `source` knowledge base document.
   */
  set answer(value: string|null) {
    this.Serializable$set('answer', value);
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The system's confidence score that this Knowledge answer is a good match
   * for this conversational query, range from 0.0 (completely uncertain) to 1.0
   * (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get metadata(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * A map that contains metadata about the answer and the document from which
   * it originates.
   */
  set metadata(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('metadata', value);
  }

  get question(): string|null {
    return (
        (this.Serializable$has('question')) ?
            (this.Serializable$get('question')) :
            (null));
  }

  /**
   * The corresponding FAQ question.
   */
  set question(value: string|null) {
    this.Serializable$set('question', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * Indicates which Knowledge Document this answer was extracted from. Format:
   * `projects//locations//agent/knowledgeBases//documents/`.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  getConstructor(): SerializableCtor<FaqAnswer_> {
    return FaqAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'answer', 'answerRecord', 'confidence', 'metadata', 'question', 'source'
      ],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface FaqAnswersConfig_Parameters {
  knowledgeBaseName?: string|null;
  knowledgeBases?: Array<string>|null;
  maxResults?: number|null;
  knowledgeTypes?: Array<FaqAnswersConfig_KnowledgeTypes>|null;
  model?: string|null;
}
export class FaqAnswersConfig_ extends Serializable {
  constructor(parameters: FaqAnswersConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'knowledgeBaseName',
        (parameters.knowledgeBaseName == null) ?
            (null) :
            (parameters.knowledgeBaseName));
    this.Serializable$set(
        'knowledgeBases',
        (parameters.knowledgeBases == null) ? (null) :
                                              (parameters.knowledgeBases));
    this.Serializable$set(
        'maxResults',
        (parameters.maxResults == null) ? (null) : (parameters.maxResults));
    this.Serializable$set(
        'knowledgeTypes',
        (parameters.knowledgeTypes == null) ? (null) :
                                              (parameters.knowledgeTypes));
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
  }

  static get KnowledgeTypes(): IFaqAnswersConfig_KnowledgeTypesEnum {
    return FaqAnswersConfig_KnowledgeTypesEnum;
  }

  get knowledgeBaseName(): string|null {
    return (
        (this.Serializable$has('knowledgeBaseName')) ?
            (this.Serializable$get('knowledgeBaseName')) :
            (null));
  }

  /**
   * Required. Settings for knowledge base, Format: `projects//knowledgeBases/`.
   */
  set knowledgeBaseName(value: string|null) {
    this.Serializable$set('knowledgeBaseName', value);
  }

  get knowledgeBases(): Array<string>|null {
    return (
        (this.Serializable$has('knowledgeBases')) ?
            (this.Serializable$get('knowledgeBases')) :
            (null));
  }

  /**
   * Settings for knowledge bases, Format: `projects//knowledgeBases/`. If both
   * `knowledge_base_name` and `knowledge_bases` are set, we will merge two
   * fields.
   */
  set knowledgeBases(value: Array<string>|null) {
    this.Serializable$set('knowledgeBases', value);
  }

  get knowledgeTypes(): Array<FaqAnswersConfig_KnowledgeTypes>|null {
    return (
        (this.Serializable$has('knowledgeTypes')) ?
            (this.Serializable$get('knowledgeTypes')) :
            (null));
  }

  /**
   * This field is no longer has any effect. We will only return FAQ doc in faq
   * suggestion.
   */
  set knowledgeTypes(value: Array<FaqAnswersConfig_KnowledgeTypes>|null) {
    this.Serializable$set('knowledgeTypes', value);
  }

  get maxResults(): number|null {
    return (
        (this.Serializable$has('maxResults')) ?
            (this.Serializable$get('maxResults')) :
            (null));
  }

  /**
   * Optional. Maximum number of results to return. If unset, defaults to 10.
   */
  set maxResults(value: number|null) {
    this.Serializable$set('maxResults', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Optional. Custom conversation model name. Format:
   * `projects//conversationModels/`.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  getConstructor(): SerializableCtor<FaqAnswersConfig_> {
    return FaqAnswersConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'knowledgeTypes': FaqAnswersConfig_KnowledgeTypesEnum},
      keys: [
        'knowledgeBaseName', 'knowledgeBases', 'knowledgeTypes', 'maxResults',
        'model'
      ]
    };
  }
}

export interface FeatureCreationFlowArticleSuggestionDetails_Parameters {
  knowledgeBases?: Array<string>|null;
}
export class FeatureCreationFlowArticleSuggestionDetails_ extends Serializable {
  constructor(
      parameters: FeatureCreationFlowArticleSuggestionDetails_Parameters = {}) {
    super();
    this.Serializable$set(
        'knowledgeBases',
        (parameters.knowledgeBases == null) ? (null) :
                                              (parameters.knowledgeBases));
  }

  get knowledgeBases(): Array<string>|null {
    return (
        (this.Serializable$has('knowledgeBases')) ?
            (this.Serializable$get('knowledgeBases')) :
            (null));
  }

  /**
   * Resource names of the associated knowledge bases.
   */
  set knowledgeBases(value: Array<string>|null) {
    this.Serializable$set('knowledgeBases', value);
  }

  getConstructor():
      SerializableCtor<FeatureCreationFlowArticleSuggestionDetails_> {
    return FeatureCreationFlowArticleSuggestionDetails_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['knowledgeBases']};
  }
}

export interface FeatureCreationFlowCustomConversationModelDetails_Parameters {
  cloudStorageDataSource?: string|null;
  datasets?: Array<string>|null;
  model?: string|null;
}
export class FeatureCreationFlowCustomConversationModelDetails_ extends
    Serializable {
  constructor(
      parameters:
          FeatureCreationFlowCustomConversationModelDetails_Parameters = {}) {
    super();
    this.Serializable$set(
        'cloudStorageDataSource',
        (parameters.cloudStorageDataSource == null) ?
            (null) :
            (parameters.cloudStorageDataSource));
    this.Serializable$set(
        'datasets',
        (parameters.datasets == null) ? (null) : (parameters.datasets));
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
  }

  get cloudStorageDataSource(): string|null {
    return (
        (this.Serializable$has('cloudStorageDataSource')) ?
            (this.Serializable$get('cloudStorageDataSource')) :
            (null));
  }

  /**
   * Cloud Storage file path for the conversation data.
   */
  set cloudStorageDataSource(value: string|null) {
    this.Serializable$set('cloudStorageDataSource', value);
  }

  get datasets(): Array<string>|null {
    return (
        (this.Serializable$has('datasets')) ?
            (this.Serializable$get('datasets')) :
            (null));
  }

  /**
   * Resource names of any associated datasets. Format:
   * `projects//locations/conversationDatasets/`.
   */
  set datasets(value: Array<string>|null) {
    this.Serializable$set('datasets', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Resource name of the associated model.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  getConstructor():
      SerializableCtor<FeatureCreationFlowCustomConversationModelDetails_> {
    return FeatureCreationFlowCustomConversationModelDetails_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['cloudStorageDataSource', 'datasets', 'model']};
  }
}

export interface FeatureCreationFlowSmartReplyDetails_Parameters {
  customConversationModelDetails?:
      FeatureCreationFlowCustomConversationModelDetails_|null;
}
export class FeatureCreationFlowSmartReplyDetails_ extends Serializable {
  constructor(
      parameters: FeatureCreationFlowSmartReplyDetails_Parameters = {}) {
    super();
    this.Serializable$set(
        'customConversationModelDetails',
        (parameters.customConversationModelDetails == null) ?
            (null) :
            (parameters.customConversationModelDetails));
  }

  get customConversationModelDetails():
      FeatureCreationFlowCustomConversationModelDetails_|null {
    return (
        (this.Serializable$has('customConversationModelDetails')) ?
            (this.Serializable$get('customConversationModelDetails')) :
            (null));
  }

  /**
   * Custom conversation model details.
   */
  set customConversationModelDetails(
      value: FeatureCreationFlowCustomConversationModelDetails_|null) {
    this.Serializable$set('customConversationModelDetails', value);
  }

  getConstructor(): SerializableCtor<FeatureCreationFlowSmartReplyDetails_> {
    return FeatureCreationFlowSmartReplyDetails_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['customConversationModelDetails'],
      objects: {
        'customConversationModelDetails':
            FeatureCreationFlowCustomConversationModelDetails_
      }
    };
  }
}

export interface FeatureCreationFlowStage_Parameters {
  stageType?: FeatureCreationFlowStage_StageType|null;
  state?: FeatureCreationFlowStage_State|null;
  stateDetail?: string|null;
  createTime?: string|null;
  updateTime?: string|null;
}
export class FeatureCreationFlowStage_ extends Serializable {
  constructor(parameters: FeatureCreationFlowStage_Parameters = {}) {
    super();
    this.Serializable$set(
        'stageType',
        (parameters.stageType == null) ? (null) : (parameters.stageType));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'stateDetail',
        (parameters.stateDetail == null) ? (null) : (parameters.stateDetail));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
  }

  static get StageType(): IFeatureCreationFlowStage_StageTypeEnum {
    return FeatureCreationFlowStage_StageTypeEnum;
  }

  static get State(): IFeatureCreationFlowStage_StateEnum {
    return FeatureCreationFlowStage_StateEnum;
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The time at which the stage was started.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get stageType(): FeatureCreationFlowStage_StageType|null {
    return (
        (this.Serializable$has('stageType')) ?
            (this.Serializable$get('stageType')) :
            (null));
  }

  /**
   * The type of the stage.
   */
  set stageType(value: FeatureCreationFlowStage_StageType|null) {
    this.Serializable$set('stageType', value);
  }

  get state(): FeatureCreationFlowStage_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * The current state of the stage.
   */
  set state(value: FeatureCreationFlowStage_State|null) {
    this.Serializable$set('state', value);
  }

  get stateDetail(): string|null {
    return (
        (this.Serializable$has('stateDetail')) ?
            (this.Serializable$get('stateDetail')) :
            (null));
  }

  /**
   * If the flow is in a warning or error state, the details of the error. This
   * will determine what help text to show in the UI. Example:
   * \"poor_evaluation_results\".
   */
  set stateDetail(value: string|null) {
    this.Serializable$set('stateDetail', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. The time at which the stage was last updated.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<FeatureCreationFlowStage_> {
    return FeatureCreationFlowStage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'stageType': FeatureCreationFlowStage_StageTypeEnum,
        'state': FeatureCreationFlowStage_StateEnum
      },
      keys: ['createTime', 'stageType', 'state', 'stateDetail', 'updateTime']
    };
  }
}

export interface FeatureCreationFlowSummarizationDetails_Parameters {
  conversationTranscriptsPrelabeled?: boolean|null;
  customConversationModelDetails?:
      FeatureCreationFlowCustomConversationModelDetails_|null;
}
export class FeatureCreationFlowSummarizationDetails_ extends Serializable {
  constructor(
      parameters: FeatureCreationFlowSummarizationDetails_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationTranscriptsPrelabeled',
        (parameters.conversationTranscriptsPrelabeled == null) ?
            (null) :
            (parameters.conversationTranscriptsPrelabeled));
    this.Serializable$set(
        'customConversationModelDetails',
        (parameters.customConversationModelDetails == null) ?
            (null) :
            (parameters.customConversationModelDetails));
  }

  get conversationTranscriptsPrelabeled(): boolean|null {
    return (
        (this.Serializable$has('conversationTranscriptsPrelabeled')) ?
            (this.Serializable$get('conversationTranscriptsPrelabeled')) :
            (null));
  }

  /**
   * Whether the uploaded conversation transcripts have already been
   * pre-labeled.
   */
  set conversationTranscriptsPrelabeled(value: boolean|null) {
    this.Serializable$set('conversationTranscriptsPrelabeled', value);
  }

  get customConversationModelDetails():
      FeatureCreationFlowCustomConversationModelDetails_|null {
    return (
        (this.Serializable$has('customConversationModelDetails')) ?
            (this.Serializable$get('customConversationModelDetails')) :
            (null));
  }

  /**
   * Custom conversation model details, if one is used.
   */
  set customConversationModelDetails(
      value: FeatureCreationFlowCustomConversationModelDetails_|null) {
    this.Serializable$set('customConversationModelDetails', value);
  }

  getConstructor(): SerializableCtor<FeatureCreationFlowSummarizationDetails_> {
    return FeatureCreationFlowSummarizationDetails_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'conversationTranscriptsPrelabeled', 'customConversationModelDetails'
      ],
      objects: {
        'customConversationModelDetails':
            FeatureCreationFlowCustomConversationModelDetails_
      }
    };
  }
}

export interface FeatureCreationFlow_Parameters {
  name?: string|null;
  etag?: string|null;
  displayName?: string|null;
  languageCode?: string|null;
  createTime?: string|null;
  updateTime?: string|null;
  flowType?: FeatureCreationFlow_FlowType|null;
  flowVersion?: string|null;
  conversationProfile?: string|null;
  state?: FeatureCreationFlow_State|null;
  stages?: Array<FeatureCreationFlowStage_>|null;
  smartReplyDetails?: FeatureCreationFlowSmartReplyDetails_|null;
  articleSuggestionDetails?: FeatureCreationFlowArticleSuggestionDetails_|null;
  summarizationDetails?: FeatureCreationFlowSummarizationDetails_|null;
}
export class FeatureCreationFlow_ extends Serializable {
  constructor(parameters: FeatureCreationFlow_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'etag', (parameters.etag == null) ? (null) : (parameters.etag));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'flowType',
        (parameters.flowType == null) ? (null) : (parameters.flowType));
    this.Serializable$set(
        'flowVersion',
        (parameters.flowVersion == null) ? (null) : (parameters.flowVersion));
    this.Serializable$set(
        'conversationProfile',
        (parameters.conversationProfile == null) ?
            (null) :
            (parameters.conversationProfile));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'stages', (parameters.stages == null) ? (null) : (parameters.stages));
    this.Serializable$set(
        'smartReplyDetails',
        (parameters.smartReplyDetails == null) ?
            (null) :
            (parameters.smartReplyDetails));
    this.Serializable$set(
        'articleSuggestionDetails',
        (parameters.articleSuggestionDetails == null) ?
            (null) :
            (parameters.articleSuggestionDetails));
    this.Serializable$set(
        'summarizationDetails',
        (parameters.summarizationDetails == null) ?
            (null) :
            (parameters.summarizationDetails));
  }

  static get FlowType(): IFeatureCreationFlow_FlowTypeEnum {
    return FeatureCreationFlow_FlowTypeEnum;
  }

  static get State(): IFeatureCreationFlow_StateEnum {
    return FeatureCreationFlow_StateEnum;
  }

  get articleSuggestionDetails(): FeatureCreationFlowArticleSuggestionDetails_
      |null {
    return (
        (this.Serializable$has('articleSuggestionDetails')) ?
            (this.Serializable$get('articleSuggestionDetails')) :
            (null));
  }

  /**
   * Article suggestion feature details.
   */
  set articleSuggestionDetails(value:
                                   FeatureCreationFlowArticleSuggestionDetails_|
                               null) {
    this.Serializable$set('articleSuggestionDetails', value);
  }

  get conversationProfile(): string|null {
    return (
        (this.Serializable$has('conversationProfile')) ?
            (this.Serializable$get('conversationProfile')) :
            (null));
  }

  /**
   * The conversation profile the feature is attached to. Example format:
   * projects//locations//conversationProfiles/
   */
  set conversationProfile(value: string|null) {
    this.Serializable$set('conversationProfile', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Creation time of the flow.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. Display name for the flow. Max length 1024 bytes.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get etag(): string|null {
    return (
        (this.Serializable$has('etag')) ? (this.Serializable$get('etag')) :
                                          (null));
  }

  /**
   * This checksum is computed by the server based on the value of other fields,
   * and may be sent on update and delete requests to ensure the client has an
   * up-to-date value before proceeding.
   */
  set etag(value: string|null) {
    this.Serializable$set('etag', value);
  }

  get flowType(): FeatureCreationFlow_FlowType|null {
    return (
        (this.Serializable$has('flowType')) ?
            (this.Serializable$get('flowType')) :
            (null));
  }

  /**
   * The type of flow.
   */
  set flowType(value: FeatureCreationFlow_FlowType|null) {
    this.Serializable$set('flowType', value);
  }

  get flowVersion(): string|null {
    return (
        (this.Serializable$has('flowVersion')) ?
            (this.Serializable$get('flowVersion')) :
            (null));
  }

  /**
   * The version number of the flow. It should follow a . version format, i.e.
   * \"1.2\". This field is needed as the steps to create an agent assist
   * feature can change over time, so ongoing flows should be associated with a
   * specific version of a feature creation process.
   */
  set flowVersion(value: string|null) {
    this.Serializable$set('flowVersion', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Language code for the flow and its associated resources. If not specified,
   * the language is en-US. Language should be set for all non en-us languages.
   * This should be a [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt)
   * language tag. Supported language codes:
   * https://cloud.google.com/dialogflow/es/docs/reference/language. Example:
   * \"en-US\".
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. Resource name for the flow.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get smartReplyDetails(): FeatureCreationFlowSmartReplyDetails_|null {
    return (
        (this.Serializable$has('smartReplyDetails')) ?
            (this.Serializable$get('smartReplyDetails')) :
            (null));
  }

  /**
   * Smart reply feature details.
   */
  set smartReplyDetails(value: FeatureCreationFlowSmartReplyDetails_|null) {
    this.Serializable$set('smartReplyDetails', value);
  }

  get stages(): Array<FeatureCreationFlowStage_>|null {
    return (
        (this.Serializable$has('stages')) ? (this.Serializable$get('stages')) :
                                            (null));
  }

  /**
   * The list of stages the user has visited, and their associated state.
   */
  set stages(value: Array<FeatureCreationFlowStage_>|null) {
    this.Serializable$set('stages', value);
  }

  get state(): FeatureCreationFlow_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * The state of the overall flow.
   */
  set state(value: FeatureCreationFlow_State|null) {
    this.Serializable$set('state', value);
  }

  get summarizationDetails(): FeatureCreationFlowSummarizationDetails_|null {
    return (
        (this.Serializable$has('summarizationDetails')) ?
            (this.Serializable$get('summarizationDetails')) :
            (null));
  }

  /**
   * Summarization feature details.
   */
  set summarizationDetails(value: FeatureCreationFlowSummarizationDetails_|
                           null) {
    this.Serializable$set('summarizationDetails', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. Last update time of the flow.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<FeatureCreationFlow_> {
    return FeatureCreationFlow_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'stages': FeatureCreationFlowStage_},
      enums: {
        'flowType': FeatureCreationFlow_FlowTypeEnum,
        'state': FeatureCreationFlow_StateEnum
      },
      keys: [
        'articleSuggestionDetails', 'conversationProfile', 'createTime',
        'displayName', 'etag', 'flowType', 'flowVersion', 'languageCode',
        'name', 'smartReplyDetails', 'stages', 'state', 'summarizationDetails',
        'updateTime'
      ],
      objects: {
        'articleSuggestionDetails':
            FeatureCreationFlowArticleSuggestionDetails_,
        'smartReplyDetails': FeatureCreationFlowSmartReplyDetails_,
        'summarizationDetails': FeatureCreationFlowSummarizationDetails_
      }
    };
  }
}

export interface FinalizeIntegrationRequest_Parameters {
  setupNonce?: string|null;
  authCode?: string|null;
  token?: string|null;
}
export class FinalizeIntegrationRequest_ extends Serializable {
  constructor(parameters: FinalizeIntegrationRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'setupNonce',
        (parameters.setupNonce == null) ? (null) : (parameters.setupNonce));
    this.Serializable$set(
        'authCode',
        (parameters.authCode == null) ? (null) : (parameters.authCode));
    this.Serializable$set(
        'token', (parameters.token == null) ? (null) : (parameters.token));
  }

  get authCode(): string|null {
    return (
        (this.Serializable$has('authCode')) ?
            (this.Serializable$get('authCode')) :
            (null));
  }

  /**
   * Optional. Auth code from partner's OAuth if the partner implements an
   * OAuth-based integration.
   */
  set authCode(value: string|null) {
    this.Serializable$set('authCode', value);
  }

  get setupNonce(): string|null {
    return (
        (this.Serializable$has('setupNonce')) ?
            (this.Serializable$get('setupNonce')) :
            (null));
  }

  /**
   * Required. The nonce generated previously in StartOneClickIntegration
   */
  set setupNonce(value: string|null) {
    this.Serializable$set('setupNonce', value);
  }

  get token(): string|null {
    return (
        (this.Serializable$has('token')) ? (this.Serializable$get('token')) :
                                           (null));
  }

  /**
   * Optional. Token issued by the partner to represent the link
   */
  set token(value: string|null) {
    this.Serializable$set('token', value);
  }

  getConstructor(): SerializableCtor<FinalizeIntegrationRequest_> {
    return FinalizeIntegrationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['authCode', 'setupNonce', 'token']};
  }
}

export interface FulfillIntentRequest_Parameters {
  matchIntentRequest?: MatchIntentRequest_|null;
  match?: Match_|null;
  outputAudioConfig?: OutputAudioConfig_|null;
  outputAudioConfigMask?: string|null;
}
export class FulfillIntentRequest_ extends Serializable {
  constructor(parameters: FulfillIntentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'matchIntentRequest',
        (parameters.matchIntentRequest == null) ?
            (null) :
            (parameters.matchIntentRequest));
    this.Serializable$set(
        'match', (parameters.match == null) ? (null) : (parameters.match));
    this.Serializable$set(
        'outputAudioConfig',
        (parameters.outputAudioConfig == null) ?
            (null) :
            (parameters.outputAudioConfig));
    this.Serializable$set(
        'outputAudioConfigMask',
        (parameters.outputAudioConfigMask == null) ?
            (null) :
            (parameters.outputAudioConfigMask));
  }

  get match(): Match_|null {
    return (
        (this.Serializable$has('match')) ? (this.Serializable$get('match')) :
                                           (null));
  }

  /**
   * Required. The matched intent to fulfill.
   */
  set match(value: Match_|null) {
    this.Serializable$set('match', value);
  }

  get matchIntentRequest(): MatchIntentRequest_|null {
    return (
        (this.Serializable$has('matchIntentRequest')) ?
            (this.Serializable$get('matchIntentRequest')) :
            (null));
  }

  /**
   * Required. Must be same as the corresponding MatchIntent request, otherwise
   * the behavior is undefined.
   */
  set matchIntentRequest(value: MatchIntentRequest_|null) {
    this.Serializable$set('matchIntentRequest', value);
  }

  get outputAudioConfig(): OutputAudioConfig_|null {
    return (
        (this.Serializable$has('outputAudioConfig')) ?
            (this.Serializable$get('outputAudioConfig')) :
            (null));
  }

  /**
   * Instructs the speech synthesizer how to generate output audio. If this
   * field is not set and agent-level speech synthesizer is not configured, no
   * output audio is generated.
   */
  set outputAudioConfig(value: OutputAudioConfig_|null) {
    this.Serializable$set('outputAudioConfig', value);
  }

  get outputAudioConfigMask(): string|null {
    return (
        (this.Serializable$has('outputAudioConfigMask')) ?
            (this.Serializable$get('outputAudioConfigMask')) :
            (null));
  }

  /**
   * Mask for output_audio_config indicating which settings in this
   * request-level config should override speech synthesizer settings defined at
   * agent-level. If unspecified or empty, output_audio_config replaces the
   * agent-level config in its entirety.
   */
  set outputAudioConfigMask(value: string|null) {
    this.Serializable$set('outputAudioConfigMask', value);
  }

  getConstructor(): SerializableCtor<FulfillIntentRequest_> {
    return FulfillIntentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'match', 'matchIntentRequest', 'outputAudioConfig',
        'outputAudioConfigMask'
      ],
      objects: {
        'match': Match_,
        'matchIntentRequest': MatchIntentRequest_,
        'outputAudioConfig': OutputAudioConfig_
      }
    };
  }
}

export interface FulfillmentFeature_Parameters {
  type?: FulfillmentFeature_Type|null;
}
export class FulfillmentFeature_ extends Serializable {
  constructor(parameters: FulfillmentFeature_Parameters = {}) {
    super();
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
  }

  static get Type(): IFulfillmentFeature_TypeEnum {
    return FulfillmentFeature_TypeEnum;
  }

  get type(): FulfillmentFeature_Type|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * The type of the feature that enabled for fulfillment.
   */
  set type(value: FulfillmentFeature_Type|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<FulfillmentFeature_> {
    return FulfillmentFeature_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {enums: {'type': FulfillmentFeature_TypeEnum}, keys: ['type']};
  }
}

export interface FulfillmentGenericWebService_Parameters {
  uri?: string|null;
  username?: string|null;
  password?: string|null;
  requestHeaders?: ApiClientObjectMap<string>|null;
  isCloudFunction?: boolean|null;
  cloudFunctionService?: boolean|null;
}
export class FulfillmentGenericWebService_ extends Serializable {
  constructor(parameters: FulfillmentGenericWebService_Parameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'username',
        (parameters.username == null) ? (null) : (parameters.username));
    this.Serializable$set(
        'password',
        (parameters.password == null) ? (null) : (parameters.password));
    this.Serializable$set(
        'requestHeaders',
        (parameters.requestHeaders == null) ? (null) :
                                              (parameters.requestHeaders));
    this.Serializable$set(
        'isCloudFunction',
        (parameters.isCloudFunction == null) ? (null) :
                                               (parameters.isCloudFunction));
    this.Serializable$set(
        'cloudFunctionService',
        (parameters.cloudFunctionService == null) ?
            (null) :
            (parameters.cloudFunctionService));
  }

  get cloudFunctionService(): boolean|null {
    return (
        (this.Serializable$has('cloudFunctionService')) ?
            (this.Serializable$get('cloudFunctionService')) :
            (null));
  }

  /**
   * Indicates if the generic web service was created through Cloud Functions
   * integration. Defaults to false. The new internal field should replace the
   * deprecated is_cloud_function.
   */
  set cloudFunctionService(value: boolean|null) {
    this.Serializable$set('cloudFunctionService', value);
  }

  get isCloudFunction(): boolean|null {
    return (
        (this.Serializable$has('isCloudFunction')) ?
            (this.Serializable$get('isCloudFunction')) :
            (null));
  }

  /**
   * Optional. Indicates if generic web service is created through Cloud
   * Functions integration. Defaults to false. is_cloud_function is deprecated.
   * Cloud functions can be configured by its uri as a regular web service now.
   */
  set isCloudFunction(value: boolean|null) {
    this.Serializable$set('isCloudFunction', value);
  }

  get password(): string|null {
    return (
        (this.Serializable$has('password')) ?
            (this.Serializable$get('password')) :
            (null));
  }

  /**
   * The password for HTTP Basic authentication.
   */
  set password(value: string|null) {
    this.Serializable$set('password', value);
  }

  get requestHeaders(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('requestHeaders')) ?
            (this.Serializable$get('requestHeaders')) :
            (null));
  }

  /**
   * The HTTP request headers to send together with fulfillment requests.
   */
  set requestHeaders(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('requestHeaders', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The fulfillment URI for receiving POST requests. It must use
   * https protocol.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  get username(): string|null {
    return (
        (this.Serializable$has('username')) ?
            (this.Serializable$get('username')) :
            (null));
  }

  /**
   * The user name for HTTP Basic authentication.
   */
  set username(value: string|null) {
    this.Serializable$set('username', value);
  }

  getConstructor(): SerializableCtor<FulfillmentGenericWebService_> {
    return FulfillmentGenericWebService_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'cloudFunctionService', 'isCloudFunction', 'password', 'requestHeaders',
        'uri', 'username'
      ],
      objectMaps: {
        'requestHeaders': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface FulfillmentResult_Parameters {
  eventName?: string|null;
  fulfillmentResponse?: WebhookResponse_|null;
  fulfillmentStatus?: GoogleRpcStatus|null;
}
export class FulfillmentResult_ extends Serializable {
  constructor(parameters: FulfillmentResult_Parameters = {}) {
    super();
    this.Serializable$set(
        'eventName',
        (parameters.eventName == null) ? (null) : (parameters.eventName));
    this.Serializable$set(
        'fulfillmentResponse',
        (parameters.fulfillmentResponse == null) ?
            (null) :
            (parameters.fulfillmentResponse));
    this.Serializable$set(
        'fulfillmentStatus',
        (parameters.fulfillmentStatus == null) ?
            (null) :
            (parameters.fulfillmentStatus));
  }

  get eventName(): string|null {
    return (
        (this.Serializable$has('eventName')) ?
            (this.Serializable$get('eventName')) :
            (null));
  }

  /**
   * Required. The name of the event that the async fulfillment is associated.
   * The format of full event name is: ASYNC_FULFILLMENT_POLL_.
   */
  set eventName(value: string|null) {
    this.Serializable$set('eventName', value);
  }

  get fulfillmentResponse(): WebhookResponse_|null {
    return (
        (this.Serializable$has('fulfillmentResponse')) ?
            (this.Serializable$get('fulfillmentResponse')) :
            (null));
  }

  /**
   * Optional. The response message for a fulfillment call. If
   * fulfillment_status is not OK, the fulfillment may not be populated.
   */
  set fulfillmentResponse(value: WebhookResponse_|null) {
    this.Serializable$set('fulfillmentResponse', value);
  }

  get fulfillmentStatus(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('fulfillmentStatus')) ?
            (this.Serializable$get('fulfillmentStatus')) :
            (null));
  }

  /**
   * Required. The status of the fulfillment.
   */
  set fulfillmentStatus(value: GoogleRpcStatus|null) {
    this.Serializable$set('fulfillmentStatus', value);
  }

  getConstructor(): SerializableCtor<FulfillmentResult_> {
    return FulfillmentResult_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['eventName', 'fulfillmentResponse', 'fulfillmentStatus'],
      objects: {
        'fulfillmentResponse': WebhookResponse_,
        'fulfillmentStatus': GoogleRpcStatus
      }
    };
  }
}

export interface Fulfillment_Parameters {
  name?: string|null;
  displayName?: string|null;
  genericWebService?: FulfillmentGenericWebService_|null;
  enabled?: boolean|null;
  features?: Array<FulfillmentFeature_>|null;
}
export class Fulfillment_ extends Serializable {
  constructor(parameters: Fulfillment_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'genericWebService',
        (parameters.genericWebService == null) ?
            (null) :
            (parameters.genericWebService));
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'features',
        (parameters.features == null) ? (null) : (parameters.features));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * The human-readable name of the fulfillment, unique within the agent. This
   * field is not used for Fulfillment in an Environment.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * Whether fulfillment is enabled.
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get features(): Array<FulfillmentFeature_>|null {
    return (
        (this.Serializable$has('features')) ?
            (this.Serializable$get('features')) :
            (null));
  }

  /**
   * The field defines whether the fulfillment is enabled for certain features.
   */
  set features(value: Array<FulfillmentFeature_>|null) {
    this.Serializable$set('features', value);
  }

  get genericWebService(): FulfillmentGenericWebService_|null {
    return (
        (this.Serializable$has('genericWebService')) ?
            (this.Serializable$get('genericWebService')) :
            (null));
  }

  /**
   * Configuration for a generic web service.
   */
  set genericWebService(value: FulfillmentGenericWebService_|null) {
    this.Serializable$set('genericWebService', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of the fulfillment. Supported formats: -
   * `projects//agent/fulfillment` - `projects//locations//agent/fulfillment`
   * This field is not used for Fulfillment in an Environment.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<Fulfillment_> {
    return Fulfillment_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'features': FulfillmentFeature_},
      keys: ['displayName', 'enabled', 'features', 'genericWebService', 'name'],
      objects: {'genericWebService': FulfillmentGenericWebService_}
    };
  }
}

export interface GcsDestination_Parameters {
  uri?: string|null;
}
export class GcsDestination_ extends Serializable {
  constructor(parameters: GcsDestination_Parameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The Google Cloud Storage URIs for the output. A URI is of the
   * form: gs://bucket/object-prefix-or-name Whether a prefix or name is used
   * depends on the use case. The requesting user must have \"write-permission\"
   * to the bucket.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<GcsDestination_> {
    return GcsDestination_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface GcsSource_Parameters {
  uri?: string|null;
}
export class GcsSource_ extends Serializable {
  constructor(parameters: GcsSource_Parameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The Google Cloud Storage URIs for the inputs. A URI is of the
   * form: gs://bucket/object-prefix-or-name Whether a prefix or name is used
   * depends on the use case.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<GcsSource_> {
    return GcsSource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface GcsSources_Parameters {
  uris?: Array<string>|null;
}
export class GcsSources_ extends Serializable {
  constructor(parameters: GcsSources_Parameters = {}) {
    super();
    this.Serializable$set(
        'uris', (parameters.uris == null) ? (null) : (parameters.uris));
  }

  get uris(): Array<string>|null {
    return (
        (this.Serializable$has('uris')) ? (this.Serializable$get('uris')) :
                                          (null));
  }

  /**
   * Required. Google Cloud Storage URIs for the inputs. A URI is of the form:
   * gs://bucket/object-prefix-or-name Whether a prefix or name is used depends
   * on the use case.
   */
  set uris(value: Array<string>|null) {
    this.Serializable$set('uris', value);
  }

  getConstructor(): SerializableCtor<GcsSources_> {
    return GcsSources_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uris']};
  }
}

export interface GenerateConversationSummaryRequestMessagePayload_Parameters {
  messages?: Array<Message_>|null;
}
export class GenerateConversationSummaryRequestMessagePayload_ extends
    Serializable {
  constructor(
      parameters:
          GenerateConversationSummaryRequestMessagePayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
  }

  get messages(): Array<Message_>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The conversation messages. The message order must match the chronological
   * order of the messages exchanged during the course of the conversation so
   * that the returned summary can be as accurate as possible.
   */
  set messages(value: Array<Message_>|null) {
    this.Serializable$set('messages', value);
  }

  getConstructor():
      SerializableCtor<GenerateConversationSummaryRequestMessagePayload_> {
    return GenerateConversationSummaryRequestMessagePayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'messages': Message_}, keys: ['messages']};
  }
}

export interface GenerateConversationSummaryRequest_Parameters {
  messagePayload?: GenerateConversationSummaryRequestMessagePayload_|null;
}
export class GenerateConversationSummaryRequest_ extends Serializable {
  constructor(parameters: GenerateConversationSummaryRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'messagePayload',
        (parameters.messagePayload == null) ? (null) :
                                              (parameters.messagePayload));
  }

  get messagePayload(): GenerateConversationSummaryRequestMessagePayload_|null {
    return (
        (this.Serializable$has('messagePayload')) ?
            (this.Serializable$get('messagePayload')) :
            (null));
  }

  /**
   * The message payload.
   */
  set messagePayload(value: GenerateConversationSummaryRequestMessagePayload_|
                     null) {
    this.Serializable$set('messagePayload', value);
  }

  getConstructor(): SerializableCtor<GenerateConversationSummaryRequest_> {
    return GenerateConversationSummaryRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['messagePayload'],
      objects:
          {'messagePayload': GenerateConversationSummaryRequestMessagePayload_}
    };
  }
}

export interface GenerateConversationSummaryResponse_Parameters {
  summary?: ConversationSummary_|null;
}
export class GenerateConversationSummaryResponse_ extends Serializable {
  constructor(parameters: GenerateConversationSummaryResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'summary',
        (parameters.summary == null) ? (null) : (parameters.summary));
  }

  get summary(): ConversationSummary_|null {
    return (
        (this.Serializable$has('summary')) ?
            (this.Serializable$get('summary')) :
            (null));
  }

  /**
   * The conversation summary.
   */
  set summary(value: ConversationSummary_|null) {
    this.Serializable$set('summary', value);
  }

  getConstructor(): SerializableCtor<GenerateConversationSummaryResponse_> {
    return GenerateConversationSummaryResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['summary'], objects: {'summary': ConversationSummary_}};
  }
}

export interface GenerateDocumentOperationMetadata_Parameters {
  state?: GenerateDocumentOperationMetadata_State|null;
  createTime?: string|null;
  sourceConversationDatasets?: Array<string>|null;
  document?: string|null;
}
export class GenerateDocumentOperationMetadata_ extends Serializable {
  constructor(parameters: GenerateDocumentOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'sourceConversationDatasets',
        (parameters.sourceConversationDatasets == null) ?
            (null) :
            (parameters.sourceConversationDatasets));
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
  }

  static get State(): IGenerateDocumentOperationMetadata_StateEnum {
    return GenerateDocumentOperationMetadata_StateEnum;
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * The time when the operation was submitted.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The name of the document to retrieve. Format:
   * `projects//knowledgeBases//documents/`.
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  get sourceConversationDatasets(): Array<string>|null {
    return (
        (this.Serializable$has('sourceConversationDatasets')) ?
            (this.Serializable$get('sourceConversationDatasets')) :
            (null));
  }

  /**
   * The source conversation datasets used to generate the allowlist.
   */
  set sourceConversationDatasets(value: Array<string>|null) {
    this.Serializable$set('sourceConversationDatasets', value);
  }

  get state(): GenerateDocumentOperationMetadata_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * State of generating document operation.
   */
  set state(value: GenerateDocumentOperationMetadata_State|null) {
    this.Serializable$set('state', value);
  }

  getConstructor(): SerializableCtor<GenerateDocumentOperationMetadata_> {
    return GenerateDocumentOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': GenerateDocumentOperationMetadata_StateEnum},
      keys: ['createTime', 'document', 'sourceConversationDatasets', 'state']
    };
  }
}

export interface GenerateDocumentRequest_Parameters {
  trainingDatasets?: InputDatasets_|null;
  knowledgeType?: GenerateDocumentRequest_KnowledgeType|null;
  displayName?: string|null;
}
export class GenerateDocumentRequest_ extends Serializable {
  constructor(parameters: GenerateDocumentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'trainingDatasets',
        (parameters.trainingDatasets == null) ? (null) :
                                                (parameters.trainingDatasets));
    this.Serializable$set(
        'knowledgeType',
        (parameters.knowledgeType == null) ? (null) :
                                             (parameters.knowledgeType));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
  }

  static get KnowledgeType(): IGenerateDocumentRequest_KnowledgeTypeEnum {
    return GenerateDocumentRequest_KnowledgeTypeEnum;
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The display name of the document. The name must be 1024 bytes or
   * less; otherwise, the generation request fails.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get knowledgeType(): GenerateDocumentRequest_KnowledgeType|null {
    return (
        (this.Serializable$has('knowledgeType')) ?
            (this.Serializable$get('knowledgeType')) :
            (null));
  }

  /**
   * The knowledge type of the smart messaging document. Currently this field
   * should only be SMART_REPLY, in the future we will also add knowledge_type
   * SMART_COMPOSE.
   */
  set knowledgeType(value: GenerateDocumentRequest_KnowledgeType|null) {
    this.Serializable$set('knowledgeType', value);
  }

  get trainingDatasets(): InputDatasets_|null {
    return (
        (this.Serializable$has('trainingDatasets')) ?
            (this.Serializable$get('trainingDatasets')) :
            (null));
  }

  /**
   * The conversation datasets to be used for generating document.
   */
  set trainingDatasets(value: InputDatasets_|null) {
    this.Serializable$set('trainingDatasets', value);
  }

  getConstructor(): SerializableCtor<GenerateDocumentRequest_> {
    return GenerateDocumentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'knowledgeType': GenerateDocumentRequest_KnowledgeTypeEnum},
      keys: ['displayName', 'knowledgeType', 'trainingDatasets'],
      objects: {'trainingDatasets': InputDatasets_}
    };
  }
}

export interface GenericOperationMetadata_Parameters {
  detailedState?: string|null;
}
export class GenericOperationMetadata_ extends Serializable {
  constructor(parameters: GenericOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'detailedState',
        (parameters.detailedState == null) ? (null) :
                                             (parameters.detailedState));
  }

  get detailedState(): string|null {
    return (
        (this.Serializable$has('detailedState')) ?
            (this.Serializable$get('detailedState')) :
            (null));
  }

  /**
   * Detailed job state used by UI to render details for user. For example
   * during TRAINING state, we will have three detailed state. Pre-processing,
   * Model Training, Post-processing.
   */
  set detailedState(value: string|null) {
    this.Serializable$set('detailedState', value);
  }

  getConstructor(): SerializableCtor<GenericOperationMetadata_> {
    return GenericOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['detailedState']};
  }
}

export interface GetSupportedLanguagesResponseSupportedLanguage_Parameters {
  fullLangName?: string|null;
  fullLangNameEn?: string|null;
  rootLangName?: string|null;
}
export class GetSupportedLanguagesResponseSupportedLanguage_ extends
    Serializable {
  constructor(
      parameters:
          GetSupportedLanguagesResponseSupportedLanguage_Parameters = {}) {
    super();
    this.Serializable$set(
        'fullLangName',
        (parameters.fullLangName == null) ? (null) : (parameters.fullLangName));
    this.Serializable$set(
        'fullLangNameEn',
        (parameters.fullLangNameEn == null) ? (null) :
                                              (parameters.fullLangNameEn));
    this.Serializable$set(
        'rootLangName',
        (parameters.rootLangName == null) ? (null) : (parameters.rootLangName));
  }

  get fullLangName(): string|null {
    return (
        (this.Serializable$has('fullLangName')) ?
            (this.Serializable$get('fullLangName')) :
            (null));
  }

  /**
   * Full language name in native language.
   */
  set fullLangName(value: string|null) {
    this.Serializable$set('fullLangName', value);
  }

  get fullLangNameEn(): string|null {
    return (
        (this.Serializable$has('fullLangNameEn')) ?
            (this.Serializable$get('fullLangNameEn')) :
            (null));
  }

  /**
   * Full language name in English.
   */
  set fullLangNameEn(value: string|null) {
    this.Serializable$set('fullLangNameEn', value);
  }

  get rootLangName(): string|null {
    return (
        (this.Serializable$has('rootLangName')) ?
            (this.Serializable$get('rootLangName')) :
            (null));
  }

  /**
   * Optional. Reference to the root language for this one.
   */
  set rootLangName(value: string|null) {
    this.Serializable$set('rootLangName', value);
  }

  getConstructor():
      SerializableCtor<GetSupportedLanguagesResponseSupportedLanguage_> {
    return GetSupportedLanguagesResponseSupportedLanguage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['fullLangName', 'fullLangNameEn', 'rootLangName']};
  }
}

export interface GetSupportedLanguagesResponse_Parameters {
  supportedLanguages?:
      ApiClientObjectMap<GetSupportedLanguagesResponseSupportedLanguage_>|null;
}
export class GetSupportedLanguagesResponse_ extends Serializable {
  constructor(parameters: GetSupportedLanguagesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'supportedLanguages',
        (parameters.supportedLanguages == null) ?
            (null) :
            (parameters.supportedLanguages));
  }

  get supportedLanguages():
      ApiClientObjectMap<GetSupportedLanguagesResponseSupportedLanguage_>|null {
    return (
        (this.Serializable$has('supportedLanguages')) ?
            (this.Serializable$get('supportedLanguages')) :
            (null));
  }

  /**
   * Map of supported languages where key is the short machine-readable name of
   * the locale.
   */
  set supportedLanguages(
      value:
          ApiClientObjectMap<GetSupportedLanguagesResponseSupportedLanguage_>|
      null) {
    this.Serializable$set('supportedLanguages', value);
  }

  getConstructor(): SerializableCtor<GetSupportedLanguagesResponse_> {
    return GetSupportedLanguagesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['supportedLanguages'],
      objectMaps: {
        'supportedLanguages': {
          ctor: GetSupportedLanguagesResponseSupportedLanguage_,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface GetUnassignedProjectsResponse_Parameters {
  projects?: ApiClientObjectMap<Project_>|null;
}
export class GetUnassignedProjectsResponse_ extends Serializable {
  constructor(parameters: GetUnassignedProjectsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'projects',
        (parameters.projects == null) ? (null) : (parameters.projects));
  }

  get projects(): ApiClientObjectMap<Project_>|null {
    return (
        (this.Serializable$has('projects')) ?
            (this.Serializable$get('projects')) :
            (null));
  }

  /**
   * K - Cloud project name, V - Cloud project description.
   */
  set projects(value: ApiClientObjectMap<Project_>|null) {
    this.Serializable$set('projects', value);
  }

  getConstructor(): SerializableCtor<GetUnassignedProjectsResponse_> {
    return GetUnassignedProjectsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['projects'],
      objectMaps: {
        'projects': {
          ctor: Project_,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudAuditAuthorizationLoggingOptionsParameters {
  permissionType?: GoogleCloudAuditAuthorizationLoggingOptionsPermissionType|
      null;
}
export class GoogleCloudAuditAuthorizationLoggingOptions extends Serializable {
  constructor(
      parameters: GoogleCloudAuditAuthorizationLoggingOptionsParameters = {}) {
    super();
    this.Serializable$set(
        'permissionType',
        (parameters.permissionType == null) ? (null) :
                                              (parameters.permissionType));
  }

  static get PermissionType():
      IGoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum {
    return GoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum;
  }

  get permissionType():
      GoogleCloudAuditAuthorizationLoggingOptionsPermissionType|null {
    return (
        (this.Serializable$has('permissionType')) ?
            (this.Serializable$get('permissionType')) :
            (null));
  }

  /**
   * The type of the permission that was checked.
   */
  set permissionType(
      value: GoogleCloudAuditAuthorizationLoggingOptionsPermissionType|null) {
    this.Serializable$set('permissionType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudAuditAuthorizationLoggingOptions> {
    return GoogleCloudAuditAuthorizationLoggingOptions;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'permissionType':
            GoogleCloudAuditAuthorizationLoggingOptionsPermissionTypeEnum
      },
      keys: ['permissionType']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamClusterUtteranceParameters {
  transcript?: string|null;
  utterance?: string|null;
  utteranceContent?: string|null;
  intent?: string|null;
  intentLabel?: GoogleCloudDialogflowAamV2beta1AamLabel|null;
  utteranceScore?: number|null;
  scoreSource?: GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSource|
      null;
  assignedLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
  skipped?: boolean|null;
}
export class GoogleCloudDialogflowAamV2beta1AamClusterUtterance extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamClusterUtteranceParameters = {}) {
    super();
    this.Serializable$set(
        'transcript',
        (parameters.transcript == null) ? (null) : (parameters.transcript));
    this.Serializable$set(
        'utterance',
        (parameters.utterance == null) ? (null) : (parameters.utterance));
    this.Serializable$set(
        'utteranceContent',
        (parameters.utteranceContent == null) ? (null) :
                                                (parameters.utteranceContent));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'intentLabel',
        (parameters.intentLabel == null) ? (null) : (parameters.intentLabel));
    this.Serializable$set(
        'utteranceScore',
        (parameters.utteranceScore == null) ? (null) :
                                              (parameters.utteranceScore));
    this.Serializable$set(
        'scoreSource',
        (parameters.scoreSource == null) ? (null) : (parameters.scoreSource));
    this.Serializable$set(
        'assignedLabels',
        (parameters.assignedLabels == null) ? (null) :
                                              (parameters.assignedLabels));
    this.Serializable$set(
        'skipped',
        (parameters.skipped == null) ? (null) : (parameters.skipped));
  }

  static get ScoreSource():
      IGoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSourceEnum {
    return GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSourceEnum;
  }

  get assignedLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('assignedLabels')) ?
            (this.Serializable$get('assignedLabels')) :
            (null));
  }

  /**
   * Optional. The collection of assigned labels.
   */
  set assignedLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|
                     null) {
    this.Serializable$set('assignedLabels', value);
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The name of the intent that the resolution utterance resolves. Format:
   * `projects//locations//aamDiscriminants/`. Only populated if the utterance
   * is a resolution utterance.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  get intentLabel(): GoogleCloudDialogflowAamV2beta1AamLabel|null {
    return (
        (this.Serializable$has('intentLabel')) ?
            (this.Serializable$get('intentLabel')) :
            (null));
  }

  /**
   * Optional. The labeled intent of the utterance, if one exist. Deprecated and
   * merged into assigned_labels.
   */
  set intentLabel(value: GoogleCloudDialogflowAamV2beta1AamLabel|null) {
    this.Serializable$set('intentLabel', value);
  }

  get scoreSource():
      GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSource|null {
    return (
        (this.Serializable$has('scoreSource')) ?
            (this.Serializable$get('scoreSource')) :
            (null));
  }

  /**
   * Optional. The source that produces the utterance score.
   */
  set scoreSource(
      value: GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSource|
      null) {
    this.Serializable$set('scoreSource', value);
  }

  get skipped(): boolean|null {
    return (
        (this.Serializable$has('skipped')) ?
            (this.Serializable$get('skipped')) :
            (null));
  }

  /**
   * Required. Utterance is skipped from the cluster.
   */
  set skipped(value: boolean|null) {
    this.Serializable$set('skipped', value);
  }

  get transcript(): string|null {
    return (
        (this.Serializable$has('transcript')) ?
            (this.Serializable$get('transcript')) :
            (null));
  }

  /**
   * Required. The name of the transcript that contains the utterance. Format:
   * `projects//locations//aamDataset/ /aamTranscripts/`.
   */
  set transcript(value: string|null) {
    this.Serializable$set('transcript', value);
  }

  get utterance(): string|null {
    return (
        (this.Serializable$has('utterance')) ?
            (this.Serializable$get('utterance')) :
            (null));
  }

  /**
   * Required. The name of the utterance. Format:
   * `projects//locations//aamDataset/ /aamTranscripts//aamUtterances/ `.
   */
  set utterance(value: string|null) {
    this.Serializable$set('utterance', value);
  }

  get utteranceContent(): string|null {
    return (
        (this.Serializable$has('utteranceContent')) ?
            (this.Serializable$get('utteranceContent')) :
            (null));
  }

  /**
   * The content of the utterance.
   */
  set utteranceContent(value: string|null) {
    this.Serializable$set('utteranceContent', value);
  }

  get utteranceScore(): number|null {
    return (
        (this.Serializable$has('utteranceScore')) ?
            (this.Serializable$get('utteranceScore')) :
            (null));
  }

  /**
   * Optional. The score from the score_source for the utterance inside this
   * cluster. The score can be utterance related or cluster-utterance membership
   * related.
   */
  set utteranceScore(value: number|null) {
    this.Serializable$set('utteranceScore', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamClusterUtterance> {
    return GoogleCloudDialogflowAamV2beta1AamClusterUtterance;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'assignedLabels': GoogleCloudDialogflowAamV2beta1AamLabel},
      enums: {
        'scoreSource':
            GoogleCloudDialogflowAamV2beta1AamClusterUtteranceScoreSourceEnum
      },
      keys: [
        'assignedLabels', 'intent', 'intentLabel', 'scoreSource', 'skipped',
        'transcript', 'utterance', 'utteranceContent', 'utteranceScore'
      ],
      objects: {'intentLabel': GoogleCloudDialogflowAamV2beta1AamLabel}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamDatasetParameters {
  name?: string|null;
  displayName?: string|null;
  description?: string|null;
  languageCode?: string|null;
  labels?: Array<string>|null;
  transcriptCount?: number|null;
  state?: GoogleCloudDialogflowAamV2beta1AamDatasetState|null;
  sourceGcsUri?: string|null;
  ingestionRun?: string|null;
  synthetic?: boolean|null;
}
export class GoogleCloudDialogflowAamV2beta1AamDataset extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1AamDatasetParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
    this.Serializable$set(
        'transcriptCount',
        (parameters.transcriptCount == null) ? (null) :
                                               (parameters.transcriptCount));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'sourceGcsUri',
        (parameters.sourceGcsUri == null) ? (null) : (parameters.sourceGcsUri));
    this.Serializable$set(
        'ingestionRun',
        (parameters.ingestionRun == null) ? (null) : (parameters.ingestionRun));
    this.Serializable$set(
        'synthetic',
        (parameters.synthetic == null) ? (null) : (parameters.synthetic));
  }

  static get State(): IGoogleCloudDialogflowAamV2beta1AamDatasetStateEnum {
    return GoogleCloudDialogflowAamV2beta1AamDatasetStateEnum;
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Description for the dataset.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Display name for the dataset.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get ingestionRun(): string|null {
    return (
        (this.Serializable$has('ingestionRun')) ?
            (this.Serializable$get('ingestionRun')) :
            (null));
  }

  /**
   * Output only. Resource name of the ingestion run for this dataset. Format:
   * `projects//locations//ingestionRuns/`.
   */
  set ingestionRun(value: string|null) {
    this.Serializable$set('ingestionRun', value);
  }

  get labels(): Array<string>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * List of labels that the caller specifies. It can be used to add additional
   * labels to a dataset.
   */
  set labels(value: Array<string>|null) {
    this.Serializable$set('labels', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Language code for transcripts in this dataset.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the aam_dataset which points to a customer
   * aam_dataset. Format: `projects//locations//aamDatasets/ `.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get sourceGcsUri(): string|null {
    return (
        (this.Serializable$has('sourceGcsUri')) ?
            (this.Serializable$get('sourceGcsUri')) :
            (null));
  }

  /**
   * Google Cloud Storage URI of the dataset source file.
   */
  set sourceGcsUri(value: string|null) {
    this.Serializable$set('sourceGcsUri', value);
  }

  get state(): GoogleCloudDialogflowAamV2beta1AamDatasetState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * The state of the dataset.
   */
  set state(value: GoogleCloudDialogflowAamV2beta1AamDatasetState|null) {
    this.Serializable$set('state', value);
  }

  get synthetic(): boolean|null {
    return (
        (this.Serializable$has('synthetic')) ?
            (this.Serializable$get('synthetic')) :
            (null));
  }

  /**
   * Indicates whether this dataset consists of synthetic transcripts. Synthetic
   * transcripts don't represent any real end-to-end conversations and simply
   * serve as containers for utterances.
   */
  set synthetic(value: boolean|null) {
    this.Serializable$set('synthetic', value);
  }

  get transcriptCount(): number|null {
    return (
        (this.Serializable$has('transcriptCount')) ?
            (this.Serializable$get('transcriptCount')) :
            (null));
  }

  /**
   * Output only. Number of transcripts in the dataset.
   */
  set transcriptCount(value: number|null) {
    this.Serializable$set('transcriptCount', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamDataset> {
    return GoogleCloudDialogflowAamV2beta1AamDataset;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': GoogleCloudDialogflowAamV2beta1AamDatasetStateEnum},
      keys: [
        'description', 'displayName', 'ingestionRun', 'labels', 'languageCode',
        'name', 'sourceGcsUri', 'state', 'synthetic', 'transcriptCount'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamDiscriminantParameters {
  name?: string|null;
  displayName?: string|null;
  type?: GoogleCloudDialogflowAamV2beta1AamDiscriminantType|null;
  parentAamDiscriminant?: string|null;
  state?: GoogleCloudDialogflowAamV2beta1AamDiscriminantState|null;
  description?: string|null;
  canonicalUtterance?: string|null;
  botAnswer?: string|null;
  childDiscriminantCount?: number|null;
  labelCount?: number|null;
  reviewedLabelCount?: number|null;
  projectNumber?: string|null;
  splitSourceDiscriminant?: string|null;
  parameterInfo?: GoogleCloudDialogflowV3alpha1IntentParameter|null;
  reviewStatus?: GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus|
      null;
  completeStatus?: GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus|
      null;
  exportedDialogflowAgents?: Array<string>|null;
  flowLabeledTranscriptsCount?: number|null;
  prebuiltEmbeddingResourceIds?: Array<string>|null;
}
export class GoogleCloudDialogflowAamV2beta1AamDiscriminant extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamDiscriminantParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'parentAamDiscriminant',
        (parameters.parentAamDiscriminant == null) ?
            (null) :
            (parameters.parentAamDiscriminant));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'canonicalUtterance',
        (parameters.canonicalUtterance == null) ?
            (null) :
            (parameters.canonicalUtterance));
    this.Serializable$set(
        'botAnswer',
        (parameters.botAnswer == null) ? (null) : (parameters.botAnswer));
    this.Serializable$set(
        'childDiscriminantCount',
        (parameters.childDiscriminantCount == null) ?
            (null) :
            (parameters.childDiscriminantCount));
    this.Serializable$set(
        'labelCount',
        (parameters.labelCount == null) ? (null) : (parameters.labelCount));
    this.Serializable$set(
        'reviewedLabelCount',
        (parameters.reviewedLabelCount == null) ?
            (null) :
            (parameters.reviewedLabelCount));
    this.Serializable$set(
        'projectNumber',
        (parameters.projectNumber == null) ? (null) :
                                             (parameters.projectNumber));
    this.Serializable$set(
        'splitSourceDiscriminant',
        (parameters.splitSourceDiscriminant == null) ?
            (null) :
            (parameters.splitSourceDiscriminant));
    this.Serializable$set(
        'parameterInfo',
        (parameters.parameterInfo == null) ? (null) :
                                             (parameters.parameterInfo));
    this.Serializable$set(
        'reviewStatus',
        (parameters.reviewStatus == null) ? (null) : (parameters.reviewStatus));
    this.Serializable$set(
        'completeStatus',
        (parameters.completeStatus == null) ? (null) :
                                              (parameters.completeStatus));
    this.Serializable$set(
        'exportedDialogflowAgents',
        (parameters.exportedDialogflowAgents == null) ?
            (null) :
            (parameters.exportedDialogflowAgents));
    this.Serializable$set(
        'flowLabeledTranscriptsCount',
        (parameters.flowLabeledTranscriptsCount == null) ?
            (null) :
            (parameters.flowLabeledTranscriptsCount));
    this.Serializable$set(
        'prebuiltEmbeddingResourceIds',
        (parameters.prebuiltEmbeddingResourceIds == null) ?
            (null) :
            (parameters.prebuiltEmbeddingResourceIds));
  }

  static get CompleteStatus():
      IGoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatusEnum {
    return GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatusEnum;
  }

  static get ReviewStatus():
      IGoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatusEnum {
    return GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatusEnum;
  }

  static get State(): IGoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum {
    return GoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum;
  }

  static get Type(): IGoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum {
    return GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum;
  }

  get botAnswer(): string|null {
    return (
        (this.Serializable$has('botAnswer')) ?
            (this.Serializable$get('botAnswer')) :
            (null));
  }

  /**
   * If the aam_discriminant is a Modeling Customer Question this string should
   * represent what the bot would respond with.
   */
  set botAnswer(value: string|null) {
    this.Serializable$set('botAnswer', value);
  }

  get canonicalUtterance(): string|null {
    return (
        (this.Serializable$has('canonicalUtterance')) ?
            (this.Serializable$get('canonicalUtterance')) :
            (null));
  }

  /**
   * An utterance that exemplifies the aam_discriminants semantic quality
   */
  set canonicalUtterance(value: string|null) {
    this.Serializable$set('canonicalUtterance', value);
  }

  get childDiscriminantCount(): number|null {
    return (
        (this.Serializable$has('childDiscriminantCount')) ?
            (this.Serializable$get('childDiscriminantCount')) :
            (null));
  }

  /**
   * Output only. The count of intent or contextual intent children that this
   * discriminant has. We only show intents and contextual intents child count
   * in taxonomy page. We ignore other types of discriminant (params, agent
   * answer, ...). This field is populated only for ListAamDiscriminants
   * responses. https://screenshot.googleplex.com/3YgrBP5NPQpd5Qh
   */
  set childDiscriminantCount(value: number|null) {
    this.Serializable$set('childDiscriminantCount', value);
  }

  get completeStatus():
      GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus|null {
    return (
        (this.Serializable$has('completeStatus')) ?
            (this.Serializable$get('completeStatus')) :
            (null));
  }

  /**
   * Output only. The complete status of the aam_discriminant.
   */
  set completeStatus(
      value: GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatus|
      null) {
    this.Serializable$set('completeStatus', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * The description of the aam_discriminant. The maximum length is 500
   * characters. If exceeded, the request is rejected.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the aam_discriminant.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get exportedDialogflowAgents(): Array<string>|null {
    return (
        (this.Serializable$has('exportedDialogflowAgents')) ?
            (this.Serializable$get('exportedDialogflowAgents')) :
            (null));
  }

  /**
   * Output only. List of dialogflow agents that this discriminant have been
   * exported to. Format: `projects//locations//agents/`.
   */
  set exportedDialogflowAgents(value: Array<string>|null) {
    this.Serializable$set('exportedDialogflowAgents', value);
  }

  get flowLabeledTranscriptsCount(): number|null {
    return (
        (this.Serializable$has('flowLabeledTranscriptsCount')) ?
            (this.Serializable$get('flowLabeledTranscriptsCount')) :
            (null));
  }

  /**
   * For an INTENT type discriminant, number of transcripts having this as a
   * head intent AND at least one flow discriminant type (parameter, agent
   * question, agent answer, agent action, agent command) label.
   */
  set flowLabeledTranscriptsCount(value: number|null) {
    this.Serializable$set('flowLabeledTranscriptsCount', value);
  }

  get labelCount(): number|null {
    return (
        (this.Serializable$has('labelCount')) ?
            (this.Serializable$get('labelCount')) :
            (null));
  }

  /**
   * Output only. The count of labels that this discriminant has. This field is
   * only populated when building ListAamDiscriminants responses.
   */
  set labelCount(value: number|null) {
    this.Serializable$set('labelCount', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the aam_discriminant. Format:
   * `projects//locations//aamDiscriminants/ `.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameterInfo(): GoogleCloudDialogflowV3alpha1IntentParameter|null {
    return (
        (this.Serializable$has('parameterInfo')) ?
            (this.Serializable$get('parameterInfo')) :
            (null));
  }

  /**
   * This field is only set when the type is PARAMETER. Holds additional
   * metadata that is specific to discriminants of type PARAMETER. E.g. entity
   * type, parameter value, parameter name.
   */
  set parameterInfo(value: GoogleCloudDialogflowV3alpha1IntentParameter|null) {
    this.Serializable$set('parameterInfo', value);
  }

  get parentAamDiscriminant(): string|null {
    return (
        (this.Serializable$has('parentAamDiscriminant')) ?
            (this.Serializable$get('parentAamDiscriminant')) :
            (null));
  }

  /**
   * Output only. The full resource name of the discriminant's parent, which is
   * also a discriminant. A DRIVER has no parent. An INTENT has a DRIVER parent.
   * A CONTEXTUAL_INTENT has an INTENT parent. Format:
   * projects//locations//aamDiscriminants/
   */
  set parentAamDiscriminant(value: string|null) {
    this.Serializable$set('parentAamDiscriminant', value);
  }

  get prebuiltEmbeddingResourceIds(): Array<string>|null {
    return (
        (this.Serializable$has('prebuiltEmbeddingResourceIds')) ?
            (this.Serializable$get('prebuiltEmbeddingResourceIds')) :
            (null));
  }

  /**
   * A list containing the resource ids of canonical utterances, which are used
   * to fetch the corresponding embeddings for label recommendation purpose.
   */
  set prebuiltEmbeddingResourceIds(value: Array<string>|null) {
    this.Serializable$set('prebuiltEmbeddingResourceIds', value);
  }

  get projectNumber(): string|null {
    return (
        (this.Serializable$has('projectNumber')) ?
            (this.Serializable$get('projectNumber')) :
            (null));
  }

  /**
   * The project number associated with the project of the agent. Internally
   * used to pass the project number to the DF backend for Wipeout and other
   * purposes.
   */
  set projectNumber(value: string|null) {
    this.Serializable$set('projectNumber', value);
  }

  get reviewStatus(): GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus
      |null {
    return (
        (this.Serializable$has('reviewStatus')) ?
            (this.Serializable$get('reviewStatus')) :
            (null));
  }

  /**
   * Output only. The review status of the aam_discriminant.
   */
  set reviewStatus(
      value: GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatus|null) {
    this.Serializable$set('reviewStatus', value);
  }

  get reviewedLabelCount(): number|null {
    return (
        (this.Serializable$has('reviewedLabelCount')) ?
            (this.Serializable$get('reviewedLabelCount')) :
            (null));
  }

  /**
   * Output only. The count of reviewed labels that this discriminant has. This
   * field is only populated when building ListAamDiscriminants responses.
   */
  set reviewedLabelCount(value: number|null) {
    this.Serializable$set('reviewedLabelCount', value);
  }

  get splitSourceDiscriminant(): string|null {
    return (
        (this.Serializable$has('splitSourceDiscriminant')) ?
            (this.Serializable$get('splitSourceDiscriminant')) :
            (null));
  }

  /**
   * Output only. If this discriminant is created from splitting a discriminant,
   * then this field will be the full resource name of the original discriminant
   * that has been split. which is also a discriminant. Format:
   * projects//locations//aamDiscriminants/
   */
  set splitSourceDiscriminant(value: string|null) {
    this.Serializable$set('splitSourceDiscriminant', value);
  }

  get state(): GoogleCloudDialogflowAamV2beta1AamDiscriminantState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Output only. The state of the aam_discriminant
   */
  set state(value: GoogleCloudDialogflowAamV2beta1AamDiscriminantState|null) {
    this.Serializable$set('state', value);
  }

  get type(): GoogleCloudDialogflowAamV2beta1AamDiscriminantType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Required. Discriminant type
   */
  set type(value: GoogleCloudDialogflowAamV2beta1AamDiscriminantType|null) {
    this.Serializable$set('type', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamDiscriminant> {
    return GoogleCloudDialogflowAamV2beta1AamDiscriminant;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'completeStatus':
            GoogleCloudDialogflowAamV2beta1AamDiscriminantCompleteStatusEnum,
        'reviewStatus':
            GoogleCloudDialogflowAamV2beta1AamDiscriminantReviewStatusEnum,
        'state': GoogleCloudDialogflowAamV2beta1AamDiscriminantStateEnum,
        'type': GoogleCloudDialogflowAamV2beta1AamDiscriminantTypeEnum
      },
      keys: [
        'botAnswer', 'canonicalUtterance', 'childDiscriminantCount',
        'completeStatus', 'description', 'displayName',
        'exportedDialogflowAgents', 'flowLabeledTranscriptsCount', 'labelCount',
        'name', 'parameterInfo', 'parentAamDiscriminant',
        'prebuiltEmbeddingResourceIds', 'projectNumber', 'reviewStatus',
        'reviewedLabelCount', 'splitSourceDiscriminant', 'state', 'type'
      ],
      objects: {'parameterInfo': GoogleCloudDialogflowV3alpha1IntentParameter}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeParameters {
  name?: string|null;
  state?: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState|null;
  type?: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType|null;
  creationTime?: string|null;
  discriminantType?:
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType|null;
  userEmail?: string|null;
  userRole?: string|null;
  displayMessage?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1AamDiscriminantChange extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'creationTime',
        (parameters.creationTime == null) ? (null) : (parameters.creationTime));
    this.Serializable$set(
        'discriminantType',
        (parameters.discriminantType == null) ? (null) :
                                                (parameters.discriminantType));
    this.Serializable$set(
        'userEmail',
        (parameters.userEmail == null) ? (null) : (parameters.userEmail));
    this.Serializable$set(
        'userRole',
        (parameters.userRole == null) ? (null) : (parameters.userRole));
    this.Serializable$set(
        'displayMessage',
        (parameters.displayMessage == null) ? (null) :
                                              (parameters.displayMessage));
  }

  static get DiscriminantType():
      IGoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum {
    return GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum;
  }

  static get State():
      IGoogleCloudDialogflowAamV2beta1AamDiscriminantChangeStateEnum {
    return GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeStateEnum;
  }

  static get Type():
      IGoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum {
    return GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum;
  }

  get creationTime(): string|null {
    return (
        (this.Serializable$has('creationTime')) ?
            (this.Serializable$get('creationTime')) :
            (null));
  }

  /**
   * When the change was created.
   */
  set creationTime(value: string|null) {
    this.Serializable$set('creationTime', value);
  }

  get discriminantType():
      GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType
      |null {
    return (
        (this.Serializable$has('discriminantType')) ?
            (this.Serializable$get('discriminantType')) :
            (null));
  }

  /**
   * The type of discriminant modified in the change. Changes cannot span
   * multiple types (e.g., a user cannot merge an Intent into a Driver).
   */
  set discriminantType(
      value:
          GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantType|
      null) {
    this.Serializable$set('discriminantType', value);
  }

  get displayMessage(): string|null {
    return (
        (this.Serializable$has('displayMessage')) ?
            (this.Serializable$get('displayMessage')) :
            (null));
  }

  /**
   * A message describing the details of the change, including the
   * discriminant's previous and current value (when appropriate). Any notes
   * provided when the change was created will appear here as well.
   */
  set displayMessage(value: string|null) {
    this.Serializable$set('displayMessage', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the aam_discriminant_change. Format:
   * `projects//locations//aamDiscriminantChanges/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get state(): GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * The DiscriminantChangeState.
   */
  set state(value: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeState|
            null) {
    this.Serializable$set('state', value);
  }

  get type(): GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * The DiscriminantChangeType.
   */
  set type(value: GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeType|
           null) {
    this.Serializable$set('type', value);
  }

  get userEmail(): string|null {
    return (
        (this.Serializable$has('userEmail')) ?
            (this.Serializable$get('userEmail')) :
            (null));
  }

  /**
   * The email address of the user who made the change.
   */
  set userEmail(value: string|null) {
    this.Serializable$set('userEmail', value);
  }

  get userRole(): string|null {
    return (
        (this.Serializable$has('userRole')) ?
            (this.Serializable$get('userRole')) :
            (null));
  }

  /**
   * The IAM role of the user who made the change, e.g. \"Conversational
   * Architect\".
   */
  set userRole(value: string|null) {
    this.Serializable$set('userRole', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamDiscriminantChange> {
    return GoogleCloudDialogflowAamV2beta1AamDiscriminantChange;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'discriminantType':
            GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeDiscriminantTypeEnum,
        'state': GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeStateEnum,
        'type': GoogleCloudDialogflowAamV2beta1AamDiscriminantChangeTypeEnum
      },
      keys: [
        'creationTime', 'discriminantType', 'displayMessage', 'name', 'state',
        'type', 'userEmail', 'userRole'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamLabelParameters {
  name?: string|null;
  type?: GoogleCloudDialogflowAamV2beta1AamLabelType|null;
  discriminant?: string|null;
  transcript?: string|null;
  utterance?: string|null;
  labelerId?: string|null;
  createTime?: string|null;
  updateTime?: string|null;
  sharded?: boolean|null;
  shardNumber?: number|null;
  isPositive?: boolean|null;
  positivity?: GoogleCloudDialogflowAamV2beta1AamLabelPositivity|null;
  relatedLabel?: string|null;
  temporaryLabel?: string|null;
  span?: GoogleCloudDialogflowAamV2beta1LabelSpan|null;
  reviewerId?: string|null;
  lastReviewTime?: string|null;
  reviewState?: GoogleCloudDialogflowAamV2beta1AamLabelReviewState|null;
  utteranceContent?: string|null;
  noRelatedLabel?: boolean|null;
  labelSource?: GoogleCloudDialogflowAamV2beta1AamLabelLabelSource|null;
}
export class GoogleCloudDialogflowAamV2beta1AamLabel extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1AamLabelParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
    this.Serializable$set(
        'transcript',
        (parameters.transcript == null) ? (null) : (parameters.transcript));
    this.Serializable$set(
        'utterance',
        (parameters.utterance == null) ? (null) : (parameters.utterance));
    this.Serializable$set(
        'labelerId',
        (parameters.labelerId == null) ? (null) : (parameters.labelerId));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'sharded',
        (parameters.sharded == null) ? (null) : (parameters.sharded));
    this.Serializable$set(
        'shardNumber',
        (parameters.shardNumber == null) ? (null) : (parameters.shardNumber));
    this.Serializable$set(
        'isPositive',
        (parameters.isPositive == null) ? (null) : (parameters.isPositive));
    this.Serializable$set(
        'positivity',
        (parameters.positivity == null) ? (null) : (parameters.positivity));
    this.Serializable$set(
        'relatedLabel',
        (parameters.relatedLabel == null) ? (null) : (parameters.relatedLabel));
    this.Serializable$set(
        'temporaryLabel',
        (parameters.temporaryLabel == null) ? (null) :
                                              (parameters.temporaryLabel));
    this.Serializable$set(
        'span', (parameters.span == null) ? (null) : (parameters.span));
    this.Serializable$set(
        'reviewerId',
        (parameters.reviewerId == null) ? (null) : (parameters.reviewerId));
    this.Serializable$set(
        'lastReviewTime',
        (parameters.lastReviewTime == null) ? (null) :
                                              (parameters.lastReviewTime));
    this.Serializable$set(
        'reviewState',
        (parameters.reviewState == null) ? (null) : (parameters.reviewState));
    this.Serializable$set(
        'utteranceContent',
        (parameters.utteranceContent == null) ? (null) :
                                                (parameters.utteranceContent));
    this.Serializable$set(
        'noRelatedLabel',
        (parameters.noRelatedLabel == null) ? (null) :
                                              (parameters.noRelatedLabel));
    this.Serializable$set(
        'labelSource',
        (parameters.labelSource == null) ? (null) : (parameters.labelSource));
  }

  static get LabelSource():
      IGoogleCloudDialogflowAamV2beta1AamLabelLabelSourceEnum {
    return GoogleCloudDialogflowAamV2beta1AamLabelLabelSourceEnum;
  }

  static get Positivity():
      IGoogleCloudDialogflowAamV2beta1AamLabelPositivityEnum {
    return GoogleCloudDialogflowAamV2beta1AamLabelPositivityEnum;
  }

  static get ReviewState():
      IGoogleCloudDialogflowAamV2beta1AamLabelReviewStateEnum {
    return GoogleCloudDialogflowAamV2beta1AamLabelReviewStateEnum;
  }

  static get Type(): IGoogleCloudDialogflowAamV2beta1AamLabelTypeEnum {
    return GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum;
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * The creation time of the aam_label.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get discriminant(): string|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * Required. The name of the discriminant that is being labeled. Format:
   * `projects//locations//aamDiscriminants/ `.
   */
  set discriminant(value: string|null) {
    this.Serializable$set('discriminant', value);
  }

  get isPositive(): boolean|null {
    return (
        (this.Serializable$has('isPositive')) ?
            (this.Serializable$get('isPositive')) :
            (null));
  }

  /**
   * If the label is a positive label.
   */
  set isPositive(value: boolean|null) {
    this.Serializable$set('isPositive', value);
  }

  get labelSource(): GoogleCloudDialogflowAamV2beta1AamLabelLabelSource|null {
    return (
        (this.Serializable$has('labelSource')) ?
            (this.Serializable$get('labelSource')) :
            (null));
  }

  /**
   * The source for the label.
   */
  set labelSource(value: GoogleCloudDialogflowAamV2beta1AamLabelLabelSource|
                  null) {
    this.Serializable$set('labelSource', value);
  }

  get labelerId(): string|null {
    return (
        (this.Serializable$has('labelerId')) ?
            (this.Serializable$get('labelerId')) :
            (null));
  }

  /**
   * The Obfuscated GAIA ID of the person who created the aam_label.
   */
  set labelerId(value: string|null) {
    this.Serializable$set('labelerId', value);
  }

  get lastReviewTime(): string|null {
    return (
        (this.Serializable$has('lastReviewTime')) ?
            (this.Serializable$get('lastReviewTime')) :
            (null));
  }

  /**
   * The last reviewed time of the aam_label.
   */
  set lastReviewTime(value: string|null) {
    this.Serializable$set('lastReviewTime', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the aam_label. Format:
   * `projects//locations//aamLabels/ `.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get noRelatedLabel(): boolean|null {
    return (
        (this.Serializable$has('noRelatedLabel')) ?
            (this.Serializable$get('noRelatedLabel')) :
            (null));
  }

  /**
   * Only used for flow labeling. This is a signal to indicate it has no related
   * labels. Default is false, which means the label could have a related label.
   * Can be manually set to true by users to explicitly indicate the label has
   * no related label.
   */
  set noRelatedLabel(value: boolean|null) {
    this.Serializable$set('noRelatedLabel', value);
  }

  get positivity(): GoogleCloudDialogflowAamV2beta1AamLabelPositivity|null {
    return (
        (this.Serializable$has('positivity')) ?
            (this.Serializable$get('positivity')) :
            (null));
  }

  /**
   * Whether the label is positive.
   */
  set positivity(value: GoogleCloudDialogflowAamV2beta1AamLabelPositivity|
                 null) {
    this.Serializable$set('positivity', value);
  }

  get relatedLabel(): string|null {
    return (
        (this.Serializable$has('relatedLabel')) ?
            (this.Serializable$get('relatedLabel')) :
            (null));
  }

  /**
   * Only used for flow labeling. The full resource name of an aam_label that is
   * related to this label. For example, an AGENT_ANSWER label may be related to
   * an INTENT label because the agent utterance provides an answer to the
   * intent posed by the customer. Format: `projects//locations//aamLabels/ `.
   */
  set relatedLabel(value: string|null) {
    this.Serializable$set('relatedLabel', value);
  }

  get reviewState(): GoogleCloudDialogflowAamV2beta1AamLabelReviewState|null {
    return (
        (this.Serializable$has('reviewState')) ?
            (this.Serializable$get('reviewState')) :
            (null));
  }

  /**
   * Whether the label has been reviewed.
   */
  set reviewState(value: GoogleCloudDialogflowAamV2beta1AamLabelReviewState|
                  null) {
    this.Serializable$set('reviewState', value);
  }

  get reviewerId(): string|null {
    return (
        (this.Serializable$has('reviewerId')) ?
            (this.Serializable$get('reviewerId')) :
            (null));
  }

  /**
   * The ID of the person who last reviewed aam_label.
   */
  set reviewerId(value: string|null) {
    this.Serializable$set('reviewerId', value);
  }

  get shardNumber(): number|null {
    return (
        (this.Serializable$has('shardNumber')) ?
            (this.Serializable$get('shardNumber')) :
            (null));
  }

  /**
   * The ID of the shard that this aam_label belongs to.
   */
  set shardNumber(value: number|null) {
    this.Serializable$set('shardNumber', value);
  }

  get sharded(): boolean|null {
    return (
        (this.Serializable$has('sharded')) ?
            (this.Serializable$get('sharded')) :
            (null));
  }

  /**
   * Whether the label would be sharded
   */
  set sharded(value: boolean|null) {
    this.Serializable$set('sharded', value);
  }

  get span(): GoogleCloudDialogflowAamV2beta1LabelSpan|null {
    return (
        (this.Serializable$has('span')) ? (this.Serializable$get('span')) :
                                          (null));
  }

  /**
   * Start and end index within the utterance that this discriminant label
   * spans.
   */
  set span(value: GoogleCloudDialogflowAamV2beta1LabelSpan|null) {
    this.Serializable$set('span', value);
  }

  get temporaryLabel(): string|null {
    return (
        (this.Serializable$has('temporaryLabel')) ?
            (this.Serializable$get('temporaryLabel')) :
            (null));
  }

  /**
   * Only for flow labeling. Unfortunately, none of a transcript's utterances
   * have an underlying label id until CompleteAamTaskRequest is submitted,
   * making it difficult to specify the \"related_label\". The workaround: * The
   * console will populate the \"temporary_label\" for each label included in
   * CompleteAamTaskRequest. Format: `projects//locations//aamLabels/`. * When
   * CompleteAamTaskRequest is sent, the \"related_label\" field must refer to a
   * \"temporary_label\" of one of the other labels provided in the request to
   * be valid. * The server's CompleteAamTaskHandler will replace the
   * \"related_label\" fields for all labels with the real label ids it creates.
   */
  set temporaryLabel(value: string|null) {
    this.Serializable$set('temporaryLabel', value);
  }

  get transcript(): string|null {
    return (
        (this.Serializable$has('transcript')) ?
            (this.Serializable$get('transcript')) :
            (null));
  }

  /**
   * Required. The name of the of the transcript that is being labeled. Format:
   * `projects//locations//aamDataset/ /aamTranscripts/`.
   */
  set transcript(value: string|null) {
    this.Serializable$set('transcript', value);
  }

  get type(): GoogleCloudDialogflowAamV2beta1AamLabelType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * The aam_label type of this utterance aam_label task.
   */
  set type(value: GoogleCloudDialogflowAamV2beta1AamLabelType|null) {
    this.Serializable$set('type', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * The last update time of the aam_label.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  get utterance(): string|null {
    return (
        (this.Serializable$has('utterance')) ?
            (this.Serializable$get('utterance')) :
            (null));
  }

  /**
   * Required. The name of the utterance that is being labeled. Format:
   * `projects//locations//aamDataset/ /aamTranscripts//aamUtterances/ `.
   */
  set utterance(value: string|null) {
    this.Serializable$set('utterance', value);
  }

  get utteranceContent(): string|null {
    return (
        (this.Serializable$has('utteranceContent')) ?
            (this.Serializable$get('utteranceContent')) :
            (null));
  }

  /**
   * The content of the labeled utterance.
   */
  set utteranceContent(value: string|null) {
    this.Serializable$set('utteranceContent', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowAamV2beta1AamLabel> {
    return GoogleCloudDialogflowAamV2beta1AamLabel;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'labelSource': GoogleCloudDialogflowAamV2beta1AamLabelLabelSourceEnum,
        'positivity': GoogleCloudDialogflowAamV2beta1AamLabelPositivityEnum,
        'reviewState': GoogleCloudDialogflowAamV2beta1AamLabelReviewStateEnum,
        'type': GoogleCloudDialogflowAamV2beta1AamLabelTypeEnum
      },
      keys: [
        'createTime',      'discriminant',   'isPositive',  'labelSource',
        'labelerId',       'lastReviewTime', 'name',        'noRelatedLabel',
        'positivity',      'relatedLabel',   'reviewState', 'reviewerId',
        'shardNumber',     'sharded',        'span',        'temporaryLabel',
        'transcript',      'type',           'updateTime',  'utterance',
        'utteranceContent'
      ],
      objects: {'span': GoogleCloudDialogflowAamV2beta1LabelSpan}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamLabelSuggestionParameters {
  name?: string|null;
  utterance?: string|null;
  discriminant?: string|null;
  discriminantType?:
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType|null;
}
export class GoogleCloudDialogflowAamV2beta1AamLabelSuggestion extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamLabelSuggestionParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'utterance',
        (parameters.utterance == null) ? (null) : (parameters.utterance));
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
    this.Serializable$set(
        'discriminantType',
        (parameters.discriminantType == null) ? (null) :
                                                (parameters.discriminantType));
  }

  static get DiscriminantType():
      IGoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum {
    return GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum;
  }

  get discriminant(): string|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * Required. The name of the of the task that is being labeled. The
   * discriminant ID of the label suggestion.
   */
  set discriminant(value: string|null) {
    this.Serializable$set('discriminant', value);
  }

  get discriminantType():
      GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType|null {
    return (
        (this.Serializable$has('discriminantType')) ?
            (this.Serializable$get('discriminantType')) :
            (null));
  }

  /**
   * The type of the discriminant.
   */
  set discriminantType(
      value: GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantType|
      null) {
    this.Serializable$set('discriminantType', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the AamLabelSuggestion. Format:
   * `projects//locations//aamLabelSuggestions/ `.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get utterance(): string|null {
    return (
        (this.Serializable$has('utterance')) ?
            (this.Serializable$get('utterance')) :
            (null));
  }

  /**
   * Required. The name of the of the utterance the label suggestion is attached
   * to.
   */
  set utterance(value: string|null) {
    this.Serializable$set('utterance', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamLabelSuggestion> {
    return GoogleCloudDialogflowAamV2beta1AamLabelSuggestion;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'discriminantType':
            GoogleCloudDialogflowAamV2beta1AamLabelSuggestionDiscriminantTypeEnum
      },
      keys: ['discriminant', 'discriminantType', 'name', 'utterance']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamLabelUtterancePairParameters {
  aamLabel?: GoogleCloudDialogflowAamV2beta1AamLabel|null;
  aamUtterance?: GoogleCloudDialogflowAamV2beta1AamUtterance|null;
}
export class GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamLabelUtterancePairParameters = {}) {
    super();
    this.Serializable$set(
        'aamLabel',
        (parameters.aamLabel == null) ? (null) : (parameters.aamLabel));
    this.Serializable$set(
        'aamUtterance',
        (parameters.aamUtterance == null) ? (null) : (parameters.aamUtterance));
  }

  get aamLabel(): GoogleCloudDialogflowAamV2beta1AamLabel|null {
    return (
        (this.Serializable$has('aamLabel')) ?
            (this.Serializable$get('aamLabel')) :
            (null));
  }

  /**
   * The label.
   */
  set aamLabel(value: GoogleCloudDialogflowAamV2beta1AamLabel|null) {
    this.Serializable$set('aamLabel', value);
  }

  get aamUtterance(): GoogleCloudDialogflowAamV2beta1AamUtterance|null {
    return (
        (this.Serializable$has('aamUtterance')) ?
            (this.Serializable$get('aamUtterance')) :
            (null));
  }

  /**
   * The utterance.
   */
  set aamUtterance(value: GoogleCloudDialogflowAamV2beta1AamUtterance|null) {
    this.Serializable$set('aamUtterance', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair> {
    return GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['aamLabel', 'aamUtterance'],
      objects: {
        'aamLabel': GoogleCloudDialogflowAamV2beta1AamLabel,
        'aamUtterance': GoogleCloudDialogflowAamV2beta1AamUtterance
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamLabeledTranscriptParameters {
  name?: string|null;
  labelsAndUtterances?:
      Array<GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair>|null;
}
export class GoogleCloudDialogflowAamV2beta1AamLabeledTranscript extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamLabeledTranscriptParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'labelsAndUtterances',
        (parameters.labelsAndUtterances == null) ?
            (null) :
            (parameters.labelsAndUtterances));
  }

  get labelsAndUtterances():
      Array<GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair>|null {
    return (
        (this.Serializable$has('labelsAndUtterances')) ?
            (this.Serializable$get('labelsAndUtterances')) :
            (null));
  }

  /**
   * List of the labeled utterances in the transcript.
   */
  set labelsAndUtterances(
      value: Array<GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair>|null) {
    this.Serializable$set('labelsAndUtterances', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the aam_labeled_transcript. Format:
   * `projects//locations//aamDataset/ /aamLabeledTranscripts/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamLabeledTranscript> {
    return GoogleCloudDialogflowAamV2beta1AamLabeledTranscript;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'labelsAndUtterances':
            GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair
      },
      keys: ['labelsAndUtterances', 'name']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeParameters {
  name?: string|null;
  type?: GoogleCloudDialogflowAamV2beta1AamNoticeType|null;
  state?: GoogleCloudDialogflowAamV2beta1AamNoticeState|null;
  dismissible?: boolean|null;
  relabelingSplitAction?:
      GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingSplitAction|null;
  confusionMatrixAction?:
      GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixAction|null;
  relabelingDeleteAction?:
      GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingDeleteAction|null;
  splitRelabelMetadata?:
      GoogleCloudDialogflowAamV2beta1AamNoticeSplitRelabelMetadata|null;
  confusionMatrixMetadata?:
      GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixMetadata|null;
  deleteRelabelMetadata?:
      GoogleCloudDialogflowAamV2beta1AamNoticeDeleteRelabelMetadata|null;
  flagMetadata?: GoogleCloudDialogflowAamV2beta1AamNoticeFlagMetadata|null;
  granularityWarningMetadata?:
      GoogleCloudDialogflowAamV2beta1AamNoticeGranularityWarningMetadata|null;
  overlappingDiscriminantPairMetadata?:
      GoogleCloudDialogflowAamV2beta1AamNoticeOverlappingDiscriminantPairMetadata|
      null;
  outlierUtteranceMetadata?:
      GoogleCloudDialogflowAamV2beta1AamNoticeOutlierUtteranceMetadata|null;
  creatorEmail?: string|null;
  createTime?: string|null;
  updateTime?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1AamNotice extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1AamNoticeParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'dismissible',
        (parameters.dismissible == null) ? (null) : (parameters.dismissible));
    this.Serializable$set(
        'relabelingSplitAction',
        (parameters.relabelingSplitAction == null) ?
            (null) :
            (parameters.relabelingSplitAction));
    this.Serializable$set(
        'confusionMatrixAction',
        (parameters.confusionMatrixAction == null) ?
            (null) :
            (parameters.confusionMatrixAction));
    this.Serializable$set(
        'relabelingDeleteAction',
        (parameters.relabelingDeleteAction == null) ?
            (null) :
            (parameters.relabelingDeleteAction));
    this.Serializable$set(
        'splitRelabelMetadata',
        (parameters.splitRelabelMetadata == null) ?
            (null) :
            (parameters.splitRelabelMetadata));
    this.Serializable$set(
        'confusionMatrixMetadata',
        (parameters.confusionMatrixMetadata == null) ?
            (null) :
            (parameters.confusionMatrixMetadata));
    this.Serializable$set(
        'deleteRelabelMetadata',
        (parameters.deleteRelabelMetadata == null) ?
            (null) :
            (parameters.deleteRelabelMetadata));
    this.Serializable$set(
        'flagMetadata',
        (parameters.flagMetadata == null) ? (null) : (parameters.flagMetadata));
    this.Serializable$set(
        'granularityWarningMetadata',
        (parameters.granularityWarningMetadata == null) ?
            (null) :
            (parameters.granularityWarningMetadata));
    this.Serializable$set(
        'overlappingDiscriminantPairMetadata',
        (parameters.overlappingDiscriminantPairMetadata == null) ?
            (null) :
            (parameters.overlappingDiscriminantPairMetadata));
    this.Serializable$set(
        'outlierUtteranceMetadata',
        (parameters.outlierUtteranceMetadata == null) ?
            (null) :
            (parameters.outlierUtteranceMetadata));
    this.Serializable$set(
        'creatorEmail',
        (parameters.creatorEmail == null) ? (null) : (parameters.creatorEmail));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
  }

  static get State(): IGoogleCloudDialogflowAamV2beta1AamNoticeStateEnum {
    return GoogleCloudDialogflowAamV2beta1AamNoticeStateEnum;
  }

  static get Type(): IGoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum {
    return GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum;
  }

  get confusionMatrixAction():
      GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixAction|null {
    return (
        (this.Serializable$has('confusionMatrixAction')) ?
            (this.Serializable$get('confusionMatrixAction')) :
            (null));
  }

  /**
   * List of metadata for confusion matrix actions.
   */
  set confusionMatrixAction(
      value: GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixAction|
      null) {
    this.Serializable$set('confusionMatrixAction', value);
  }

  get confusionMatrixMetadata():
      GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixMetadata|null {
    return (
        (this.Serializable$has('confusionMatrixMetadata')) ?
            (this.Serializable$get('confusionMatrixMetadata')) :
            (null));
  }

  /**
   * List of metadata for confusion matrix actions.
   */
  set confusionMatrixMetadata(
      value: GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixMetadata|
      null) {
    this.Serializable$set('confusionMatrixMetadata', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The last create time of the notice.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get creatorEmail(): string|null {
    return (
        (this.Serializable$has('creatorEmail')) ?
            (this.Serializable$get('creatorEmail')) :
            (null));
  }

  /**
   * The email address of the user who creates the notice. This field will only
   * be populated for human-created notices, e.g. flags.
   */
  set creatorEmail(value: string|null) {
    this.Serializable$set('creatorEmail', value);
  }

  get deleteRelabelMetadata():
      GoogleCloudDialogflowAamV2beta1AamNoticeDeleteRelabelMetadata|null {
    return (
        (this.Serializable$has('deleteRelabelMetadata')) ?
            (this.Serializable$get('deleteRelabelMetadata')) :
            (null));
  }

  /**
   * Action metadata for relabeling utterances after deleting a discriminant
   */
  set deleteRelabelMetadata(
      value: GoogleCloudDialogflowAamV2beta1AamNoticeDeleteRelabelMetadata|
      null) {
    this.Serializable$set('deleteRelabelMetadata', value);
  }

  get dismissible(): boolean|null {
    return (
        (this.Serializable$has('dismissible')) ?
            (this.Serializable$get('dismissible')) :
            (null));
  }

  /**
   * Whether the user can dismiss this notice. If dismissible is true, a cancel
   * button should be displayed which the user can click to dismiss the notice.
   * If dismissible is false, the notice can only be dismissed by completing
   * actions.
   */
  set dismissible(value: boolean|null) {
    this.Serializable$set('dismissible', value);
  }

  get flagMetadata(): GoogleCloudDialogflowAamV2beta1AamNoticeFlagMetadata
      |null {
    return (
        (this.Serializable$has('flagMetadata')) ?
            (this.Serializable$get('flagMetadata')) :
            (null));
  }

  /**
   * Action metadata for a flagged discriminant or label
   */
  set flagMetadata(value: GoogleCloudDialogflowAamV2beta1AamNoticeFlagMetadata|
                   null) {
    this.Serializable$set('flagMetadata', value);
  }

  get granularityWarningMetadata():
      GoogleCloudDialogflowAamV2beta1AamNoticeGranularityWarningMetadata|null {
    return (
        (this.Serializable$has('granularityWarningMetadata')) ?
            (this.Serializable$get('granularityWarningMetadata')) :
            (null));
  }

  /**
   * Metadata for granularity related warning type.
   */
  set granularityWarningMetadata(
      value: GoogleCloudDialogflowAamV2beta1AamNoticeGranularityWarningMetadata|
      null) {
    this.Serializable$set('granularityWarningMetadata', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of the aam_notice. Format:
   * `projects//locations//aamNotices/ `.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get outlierUtteranceMetadata():
      GoogleCloudDialogflowAamV2beta1AamNoticeOutlierUtteranceMetadata|null {
    return (
        (this.Serializable$has('outlierUtteranceMetadata')) ?
            (this.Serializable$get('outlierUtteranceMetadata')) :
            (null));
  }

  /**
   * Metadata for outlier utterance.
   */
  set outlierUtteranceMetadata(
      value: GoogleCloudDialogflowAamV2beta1AamNoticeOutlierUtteranceMetadata|
      null) {
    this.Serializable$set('outlierUtteranceMetadata', value);
  }

  get overlappingDiscriminantPairMetadata():
      GoogleCloudDialogflowAamV2beta1AamNoticeOverlappingDiscriminantPairMetadata
      |null {
    return (
        (this.Serializable$has('overlappingDiscriminantPairMetadata')) ?
            (this.Serializable$get('overlappingDiscriminantPairMetadata')) :
            (null));
  }

  /**
   * Metadata for overlapped discriminants.
   */
  set overlappingDiscriminantPairMetadata(
      value:
          GoogleCloudDialogflowAamV2beta1AamNoticeOverlappingDiscriminantPairMetadata|
      null) {
    this.Serializable$set('overlappingDiscriminantPairMetadata', value);
  }

  get relabelingDeleteAction():
      GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingDeleteAction|null {
    return (
        (this.Serializable$has('relabelingDeleteAction')) ?
            (this.Serializable$get('relabelingDeleteAction')) :
            (null));
  }

  /**
   * Action metadata for relabeling utterances after deleting a discriminant
   */
  set relabelingDeleteAction(
      value: GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingDeleteAction|
      null) {
    this.Serializable$set('relabelingDeleteAction', value);
  }

  get relabelingSplitAction():
      GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingSplitAction|null {
    return (
        (this.Serializable$has('relabelingSplitAction')) ?
            (this.Serializable$get('relabelingSplitAction')) :
            (null));
  }

  /**
   * Metadata for generating relabeling action after a discriminant split.
   */
  set relabelingSplitAction(
      value: GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingSplitAction|
      null) {
    this.Serializable$set('relabelingSplitAction', value);
  }

  get splitRelabelMetadata():
      GoogleCloudDialogflowAamV2beta1AamNoticeSplitRelabelMetadata|null {
    return (
        (this.Serializable$has('splitRelabelMetadata')) ?
            (this.Serializable$get('splitRelabelMetadata')) :
            (null));
  }

  /**
   * Metadata for generating relabeling action after a discriminant split.
   */
  set splitRelabelMetadata(
      value: GoogleCloudDialogflowAamV2beta1AamNoticeSplitRelabelMetadata|
      null) {
    this.Serializable$set('splitRelabelMetadata', value);
  }

  get state(): GoogleCloudDialogflowAamV2beta1AamNoticeState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * The state of the notice.
   */
  set state(value: GoogleCloudDialogflowAamV2beta1AamNoticeState|null) {
    this.Serializable$set('state', value);
  }

  get type(): GoogleCloudDialogflowAamV2beta1AamNoticeType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * The type of the notice.
   */
  set type(value: GoogleCloudDialogflowAamV2beta1AamNoticeType|null) {
    this.Serializable$set('type', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. The last update time of the notice.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowAamV2beta1AamNotice> {
    return GoogleCloudDialogflowAamV2beta1AamNotice;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state': GoogleCloudDialogflowAamV2beta1AamNoticeStateEnum,
        'type': GoogleCloudDialogflowAamV2beta1AamNoticeTypeEnum
      },
      keys: [
        'confusionMatrixAction', 'confusionMatrixMetadata', 'createTime',
        'creatorEmail', 'deleteRelabelMetadata', 'dismissible', 'flagMetadata',
        'granularityWarningMetadata', 'name', 'outlierUtteranceMetadata',
        'overlappingDiscriminantPairMetadata', 'relabelingDeleteAction',
        'relabelingSplitAction', 'splitRelabelMetadata', 'state', 'type',
        'updateTime'
      ],
      objects: {
        'confusionMatrixAction':
            GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixAction,
        'confusionMatrixMetadata':
            GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixMetadata,
        'deleteRelabelMetadata':
            GoogleCloudDialogflowAamV2beta1AamNoticeDeleteRelabelMetadata,
        'flagMetadata': GoogleCloudDialogflowAamV2beta1AamNoticeFlagMetadata,
        'granularityWarningMetadata':
            GoogleCloudDialogflowAamV2beta1AamNoticeGranularityWarningMetadata,
        'outlierUtteranceMetadata':
            GoogleCloudDialogflowAamV2beta1AamNoticeOutlierUtteranceMetadata,
        'overlappingDiscriminantPairMetadata':
            GoogleCloudDialogflowAamV2beta1AamNoticeOverlappingDiscriminantPairMetadata,
        'relabelingDeleteAction':
            GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingDeleteAction,
        'relabelingSplitAction':
            GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingSplitAction,
        'splitRelabelMetadata':
            GoogleCloudDialogflowAamV2beta1AamNoticeSplitRelabelMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixActionParameters {
  labeledDiscriminant?: string|null;
  predictedDiscriminant?: string|null;
  score?: number|null;
  label?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixAction extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixActionParameters =
              {}) {
    super();
    this.Serializable$set(
        'labeledDiscriminant',
        (parameters.labeledDiscriminant == null) ?
            (null) :
            (parameters.labeledDiscriminant));
    this.Serializable$set(
        'predictedDiscriminant',
        (parameters.predictedDiscriminant == null) ?
            (null) :
            (parameters.predictedDiscriminant));
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'label', (parameters.label == null) ? (null) : (parameters.label));
  }

  get label(): string|null {
    return (
        (this.Serializable$has('label')) ? (this.Serializable$get('label')) :
                                           (null));
  }

  /**
   * The resource name of the label that has the wrong discriminant. Format:
   * `projects/{project}/locations/{location}/aamLabels/{aam_label}`.
   */
  set label(value: string|null) {
    this.Serializable$set('label', value);
  }

  get labeledDiscriminant(): string|null {
    return (
        (this.Serializable$has('labeledDiscriminant')) ?
            (this.Serializable$get('labeledDiscriminant')) :
            (null));
  }

  /**
   * The resource name of the discriminant being labelled. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set labeledDiscriminant(value: string|null) {
    this.Serializable$set('labeledDiscriminant', value);
  }

  get predictedDiscriminant(): string|null {
    return (
        (this.Serializable$has('predictedDiscriminant')) ?
            (this.Serializable$get('predictedDiscriminant')) :
            (null));
  }

  /**
   * The resource name of the discriminant being predicted. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set predictedDiscriminant(value: string|null) {
    this.Serializable$set('predictedDiscriminant', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Score of the predicted discriminant id.
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixAction> {
    return GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['label', 'labeledDiscriminant', 'predictedDiscriminant', 'score']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixMetadataParameters {
  labeledDiscriminant?: string|null;
  predictedDiscriminant?: string|null;
  confidence?: number|null;
  label?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'labeledDiscriminant',
        (parameters.labeledDiscriminant == null) ?
            (null) :
            (parameters.labeledDiscriminant));
    this.Serializable$set(
        'predictedDiscriminant',
        (parameters.predictedDiscriminant == null) ?
            (null) :
            (parameters.predictedDiscriminant));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'label', (parameters.label == null) ? (null) : (parameters.label));
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * Confidence of the predicted discriminant id.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get label(): string|null {
    return (
        (this.Serializable$has('label')) ? (this.Serializable$get('label')) :
                                           (null));
  }

  /**
   * The resource name of the label that has the wrong discriminant. Format:
   * `projects/{project}/locations/{location}/aamLabels/{aam_label}`.
   */
  set label(value: string|null) {
    this.Serializable$set('label', value);
  }

  get labeledDiscriminant(): string|null {
    return (
        (this.Serializable$has('labeledDiscriminant')) ?
            (this.Serializable$get('labeledDiscriminant')) :
            (null));
  }

  /**
   * The resource name of the discriminant being labelled. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set labeledDiscriminant(value: string|null) {
    this.Serializable$set('labeledDiscriminant', value);
  }

  get predictedDiscriminant(): string|null {
    return (
        (this.Serializable$has('predictedDiscriminant')) ?
            (this.Serializable$get('predictedDiscriminant')) :
            (null));
  }

  /**
   * The resource name of the discriminant being predicted. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set predictedDiscriminant(value: string|null) {
    this.Serializable$set('predictedDiscriminant', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixMetadata> {
    return GoogleCloudDialogflowAamV2beta1AamNoticeConfusionMatrixMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'confidence', 'label', 'labeledDiscriminant', 'predictedDiscriminant'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeDeleteRelabelMetadataParameters {
  discriminant?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1AamNoticeDeleteRelabelMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamNoticeDeleteRelabelMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
  }

  get discriminant(): string|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * The resource name of the discriminant being deleted. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set discriminant(value: string|null) {
    this.Serializable$set('discriminant', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1AamNoticeDeleteRelabelMetadata> {
    return GoogleCloudDialogflowAamV2beta1AamNoticeDeleteRelabelMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['discriminant']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeFlagMetadataParameters {
  comment?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1AamNoticeFlagMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamNoticeFlagMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'comment',
        (parameters.comment == null) ? (null) : (parameters.comment));
  }

  get comment(): string|null {
    return (
        (this.Serializable$has('comment')) ?
            (this.Serializable$get('comment')) :
            (null));
  }

  /**
   * The comment left by the reviewer.
   */
  set comment(value: string|null) {
    this.Serializable$set('comment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamNoticeFlagMetadata> {
    return GoogleCloudDialogflowAamV2beta1AamNoticeFlagMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['comment']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeGranularityWarningMetadataParameters {
  discriminant?: string|null;
  positiveLabelCount?: number|null;
  cohesionScore?: number|null;
  resourceIds?: Array<string>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1AamNoticeGranularityWarningMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamNoticeGranularityWarningMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
    this.Serializable$set(
        'positiveLabelCount',
        (parameters.positiveLabelCount == null) ?
            (null) :
            (parameters.positiveLabelCount));
    this.Serializable$set(
        'cohesionScore',
        (parameters.cohesionScore == null) ? (null) :
                                             (parameters.cohesionScore));
    this.Serializable$set(
        'resourceIds',
        (parameters.resourceIds == null) ? (null) : (parameters.resourceIds));
  }

  get cohesionScore(): number|null {
    return (
        (this.Serializable$has('cohesionScore')) ?
            (this.Serializable$get('cohesionScore')) :
            (null));
  }

  /**
   * The cohesion score of the discriminant when the warning is created.
   */
  set cohesionScore(value: number|null) {
    this.Serializable$set('cohesionScore', value);
  }

  get discriminant(): string|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * The resource name of the discriminant which receives the warning. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set discriminant(value: string|null) {
    this.Serializable$set('discriminant', value);
  }

  get positiveLabelCount(): number|null {
    return (
        (this.Serializable$has('positiveLabelCount')) ?
            (this.Serializable$get('positiveLabelCount')) :
            (null));
  }

  /**
   * The number of positive labels.
   */
  set positiveLabelCount(value: number|null) {
    this.Serializable$set('positiveLabelCount', value);
  }

  get resourceIds(): Array<string>|null {
    return (
        (this.Serializable$has('resourceIds')) ?
            (this.Serializable$get('resourceIds')) :
            (null));
  }

  /**
   * The resource ids of the discriminant when the warning is created.
   */
  set resourceIds(value: Array<string>|null) {
    this.Serializable$set('resourceIds', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1AamNoticeGranularityWarningMetadata> {
    return GoogleCloudDialogflowAamV2beta1AamNoticeGranularityWarningMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys:
          ['cohesionScore', 'discriminant', 'positiveLabelCount', 'resourceIds']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeOutlierUtteranceMetadataParameters {
  label?: string|null;
  discriminant?: string|null;
  distance?: number|null;
  cohesionScore?: number|null;
}
export class
    GoogleCloudDialogflowAamV2beta1AamNoticeOutlierUtteranceMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamNoticeOutlierUtteranceMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'label', (parameters.label == null) ? (null) : (parameters.label));
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
    this.Serializable$set(
        'distance',
        (parameters.distance == null) ? (null) : (parameters.distance));
    this.Serializable$set(
        'cohesionScore',
        (parameters.cohesionScore == null) ? (null) :
                                             (parameters.cohesionScore));
  }

  get cohesionScore(): number|null {
    return (
        (this.Serializable$has('cohesionScore')) ?
            (this.Serializable$get('cohesionScore')) :
            (null));
  }

  /**
   * The cohesion score of the discriminant when the warning is created.
   */
  set cohesionScore(value: number|null) {
    this.Serializable$set('cohesionScore', value);
  }

  get discriminant(): string|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * The resource name of the discriminant which contains the outlier utterance.
   * Format: `projects//locations//aamDiscriminants/`.
   */
  set discriminant(value: string|null) {
    this.Serializable$set('discriminant', value);
  }

  get distance(): number|null {
    return (
        (this.Serializable$has('distance')) ?
            (this.Serializable$get('distance')) :
            (null));
  }

  /**
   * The distance between the outlier utterance and the discriminant's center of
   * mass when the warning is created.
   */
  set distance(value: number|null) {
    this.Serializable$set('distance', value);
  }

  get label(): string|null {
    return (
        (this.Serializable$has('label')) ? (this.Serializable$get('label')) :
                                           (null));
  }

  /**
   * The label resource name of the outlier utterance. Format:
   * `projects/{project}/locations/{location}/aamLabels/{aam_label}`.
   */
  set label(value: string|null) {
    this.Serializable$set('label', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1AamNoticeOutlierUtteranceMetadata> {
    return GoogleCloudDialogflowAamV2beta1AamNoticeOutlierUtteranceMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['cohesionScore', 'discriminant', 'distance', 'label']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeOverlappingDiscriminantPairMetadataParameters {
  firstDiscriminant?: string|null;
  secondDiscriminant?: string|null;
  distance?: number|null;
  firstResourceIds?: Array<string>|null;
  secondResourceIds?: Array<string>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1AamNoticeOverlappingDiscriminantPairMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamNoticeOverlappingDiscriminantPairMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'firstDiscriminant',
        (parameters.firstDiscriminant == null) ?
            (null) :
            (parameters.firstDiscriminant));
    this.Serializable$set(
        'secondDiscriminant',
        (parameters.secondDiscriminant == null) ?
            (null) :
            (parameters.secondDiscriminant));
    this.Serializable$set(
        'distance',
        (parameters.distance == null) ? (null) : (parameters.distance));
    this.Serializable$set(
        'firstResourceIds',
        (parameters.firstResourceIds == null) ? (null) :
                                                (parameters.firstResourceIds));
    this.Serializable$set(
        'secondResourceIds',
        (parameters.secondResourceIds == null) ?
            (null) :
            (parameters.secondResourceIds));
  }

  get distance(): number|null {
    return (
        (this.Serializable$has('distance')) ?
            (this.Serializable$get('distance')) :
            (null));
  }

  /**
   * The distance between their center of mass when the warning is created.
   */
  set distance(value: number|null) {
    this.Serializable$set('distance', value);
  }

  get firstDiscriminant(): string|null {
    return (
        (this.Serializable$has('firstDiscriminant')) ?
            (this.Serializable$get('firstDiscriminant')) :
            (null));
  }

  /**
   * The resource name of the first discriminant. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set firstDiscriminant(value: string|null) {
    this.Serializable$set('firstDiscriminant', value);
  }

  get firstResourceIds(): Array<string>|null {
    return (
        (this.Serializable$has('firstResourceIds')) ?
            (this.Serializable$get('firstResourceIds')) :
            (null));
  }

  /**
   * The resource ids of the first discriminant when the warning is created.
   */
  set firstResourceIds(value: Array<string>|null) {
    this.Serializable$set('firstResourceIds', value);
  }

  get secondDiscriminant(): string|null {
    return (
        (this.Serializable$has('secondDiscriminant')) ?
            (this.Serializable$get('secondDiscriminant')) :
            (null));
  }

  /**
   * The resource name of the second discriminant. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set secondDiscriminant(value: string|null) {
    this.Serializable$set('secondDiscriminant', value);
  }

  get secondResourceIds(): Array<string>|null {
    return (
        (this.Serializable$has('secondResourceIds')) ?
            (this.Serializable$get('secondResourceIds')) :
            (null));
  }

  /**
   * The resource ids of the second discriminant when the warning is created.
   */
  set secondResourceIds(value: Array<string>|null) {
    this.Serializable$set('secondResourceIds', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1AamNoticeOverlappingDiscriminantPairMetadata> {
    return GoogleCloudDialogflowAamV2beta1AamNoticeOverlappingDiscriminantPairMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'distance', 'firstDiscriminant', 'firstResourceIds',
        'secondDiscriminant', 'secondResourceIds'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingDeleteActionParameters {
  discriminant?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingDeleteAction extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingDeleteActionParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
  }

  get discriminant(): string|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * The resource name of the discriminant being deleted. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set discriminant(value: string|null) {
    this.Serializable$set('discriminant', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingDeleteAction> {
    return GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingDeleteAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['discriminant']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingSplitActionParameters {
  oldDiscriminant?: string|null;
  newDiscriminants?: Array<string>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingSplitAction extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingSplitActionParameters =
              {}) {
    super();
    this.Serializable$set(
        'oldDiscriminant',
        (parameters.oldDiscriminant == null) ? (null) :
                                               (parameters.oldDiscriminant));
    this.Serializable$set(
        'newDiscriminants',
        (parameters.newDiscriminants == null) ? (null) :
                                                (parameters.newDiscriminants));
  }

  get newDiscriminants(): Array<string>|null {
    return (
        (this.Serializable$has('newDiscriminants')) ?
            (this.Serializable$get('newDiscriminants')) :
            (null));
  }

  /**
   * The resource names of the new discriminants after the split. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set newDiscriminants(value: Array<string>|null) {
    this.Serializable$set('newDiscriminants', value);
  }

  get oldDiscriminant(): string|null {
    return (
        (this.Serializable$has('oldDiscriminant')) ?
            (this.Serializable$get('oldDiscriminant')) :
            (null));
  }

  /**
   * The resource name of the discriminant being split. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set oldDiscriminant(value: string|null) {
    this.Serializable$set('oldDiscriminant', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingSplitAction> {
    return GoogleCloudDialogflowAamV2beta1AamNoticeRelabelingSplitAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['newDiscriminants', 'oldDiscriminant']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamNoticeSplitRelabelMetadataParameters {
  oldDiscriminant?: string|null;
  newDiscriminants?: Array<string>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1AamNoticeSplitRelabelMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamNoticeSplitRelabelMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'oldDiscriminant',
        (parameters.oldDiscriminant == null) ? (null) :
                                               (parameters.oldDiscriminant));
    this.Serializable$set(
        'newDiscriminants',
        (parameters.newDiscriminants == null) ? (null) :
                                                (parameters.newDiscriminants));
  }

  get newDiscriminants(): Array<string>|null {
    return (
        (this.Serializable$has('newDiscriminants')) ?
            (this.Serializable$get('newDiscriminants')) :
            (null));
  }

  /**
   * The resource names of the new discriminants after the split. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set newDiscriminants(value: Array<string>|null) {
    this.Serializable$set('newDiscriminants', value);
  }

  get oldDiscriminant(): string|null {
    return (
        (this.Serializable$has('oldDiscriminant')) ?
            (this.Serializable$get('oldDiscriminant')) :
            (null));
  }

  /**
   * The resource name of the discriminant being split. Format:
   * `projects//locations//aamDiscriminants/`.
   */
  set oldDiscriminant(value: string|null) {
    this.Serializable$set('oldDiscriminant', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1AamNoticeSplitRelabelMetadata> {
    return GoogleCloudDialogflowAamV2beta1AamNoticeSplitRelabelMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['newDiscriminants', 'oldDiscriminant']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamProjectParameters {
  name?: string|null;
  cloudSqlSettings?:
      GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettings|null;
  gcsBucket?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1AamProject extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1AamProjectParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'cloudSqlSettings',
        (parameters.cloudSqlSettings == null) ? (null) :
                                                (parameters.cloudSqlSettings));
    this.Serializable$set(
        'gcsBucket',
        (parameters.gcsBucket == null) ? (null) : (parameters.gcsBucket));
  }

  get cloudSqlSettings():
      GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettings|null {
    return (
        (this.Serializable$has('cloudSqlSettings')) ?
            (this.Serializable$get('cloudSqlSettings')) :
            (null));
  }

  /**
   * Required. The Cloud SQL settings of the project. Empty when the project is
   * being created.
   */
  set cloudSqlSettings(
      value: GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettings|
      null) {
    this.Serializable$set('cloudSqlSettings', value);
  }

  get gcsBucket(): string|null {
    return (
        (this.Serializable$has('gcsBucket')) ?
            (this.Serializable$get('gcsBucket')) :
            (null));
  }

  /**
   * Required. Name of the Google Cloud Storage bucket of the project. Empty
   * when the project is being created.
   */
  set gcsBucket(value: string|null) {
    this.Serializable$set('gcsBucket', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Resource name of the AAM project Format:
   * `projects//locations//aamProjects/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamProject> {
    return GoogleCloudDialogflowAamV2beta1AamProject;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['cloudSqlSettings', 'gcsBucket', 'name'],
      objects: {
        'cloudSqlSettings':
            GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamTaskParameters {
  name?: string|null;
  sampleMethod?: GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod|null;
  noticeId?: string|null;
  discriminantType?: GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType|
      null;
  discriminantId?: string|null;
  state?: GoogleCloudDialogflowAamV2beta1AamTaskState|null;
  transcript?: string|null;
  labellerId?: string|null;
  createTime?: string|null;
  completeTime?: string|null;
  updateTime?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1AamTask extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1AamTaskParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'sampleMethod',
        (parameters.sampleMethod == null) ? (null) : (parameters.sampleMethod));
    this.Serializable$set(
        'noticeId',
        (parameters.noticeId == null) ? (null) : (parameters.noticeId));
    this.Serializable$set(
        'discriminantType',
        (parameters.discriminantType == null) ? (null) :
                                                (parameters.discriminantType));
    this.Serializable$set(
        'discriminantId',
        (parameters.discriminantId == null) ? (null) :
                                              (parameters.discriminantId));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'transcript',
        (parameters.transcript == null) ? (null) : (parameters.transcript));
    this.Serializable$set(
        'labellerId',
        (parameters.labellerId == null) ? (null) : (parameters.labellerId));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'completeTime',
        (parameters.completeTime == null) ? (null) : (parameters.completeTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
  }

  static get DiscriminantType():
      IGoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum {
    return GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum;
  }

  static get SampleMethod():
      IGoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum {
    return GoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum;
  }

  static get State(): IGoogleCloudDialogflowAamV2beta1AamTaskStateEnum {
    return GoogleCloudDialogflowAamV2beta1AamTaskStateEnum;
  }

  get completeTime(): string|null {
    return (
        (this.Serializable$has('completeTime')) ?
            (this.Serializable$get('completeTime')) :
            (null));
  }

  /**
   * When the task is submitted.
   */
  set completeTime(value: string|null) {
    this.Serializable$set('completeTime', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * When the task is created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get discriminantId(): string|null {
    return (
        (this.Serializable$has('discriminantId')) ?
            (this.Serializable$get('discriminantId')) :
            (null));
  }

  /**
   * The discriminant id the task is generated for.
   */
  set discriminantId(value: string|null) {
    this.Serializable$set('discriminantId', value);
  }

  get discriminantType(): GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType
      |null {
    return (
        (this.Serializable$has('discriminantType')) ?
            (this.Serializable$get('discriminantType')) :
            (null));
  }

  /**
   * The discriminant type the task is generated for.
   */
  set discriminantType(
      value: GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantType|null) {
    this.Serializable$set('discriminantType', value);
  }

  get labellerId(): string|null {
    return (
        (this.Serializable$has('labellerId')) ?
            (this.Serializable$get('labellerId')) :
            (null));
  }

  /**
   * The obfuscated Gaia ID of the task labeler
   */
  set labellerId(value: string|null) {
    this.Serializable$set('labellerId', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the aam_task. Format:
   * `projects//locations//aamTasks/ `.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get noticeId(): string|null {
    return (
        (this.Serializable$has('noticeId')) ?
            (this.Serializable$get('noticeId')) :
            (null));
  }

  set noticeId(value: string|null) {
    this.Serializable$set('noticeId', value);
  }

  get sampleMethod(): GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod|null {
    return (
        (this.Serializable$has('sampleMethod')) ?
            (this.Serializable$get('sampleMethod')) :
            (null));
  }

  /**
   * The method used to sample the transcript.
   */
  set sampleMethod(value: GoogleCloudDialogflowAamV2beta1AamTaskSampleMethod|
                   null) {
    this.Serializable$set('sampleMethod', value);
  }

  get state(): GoogleCloudDialogflowAamV2beta1AamTaskState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * The lifecycle state the task is in.
   */
  set state(value: GoogleCloudDialogflowAamV2beta1AamTaskState|null) {
    this.Serializable$set('state', value);
  }

  get transcript(): string|null {
    return (
        (this.Serializable$has('transcript')) ?
            (this.Serializable$get('transcript')) :
            (null));
  }

  /**
   * Required. The name of the of the transcript that is being labeled.
   */
  set transcript(value: string|null) {
    this.Serializable$set('transcript', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * When the task is last modified.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowAamV2beta1AamTask> {
    return GoogleCloudDialogflowAamV2beta1AamTask;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'discriminantType':
            GoogleCloudDialogflowAamV2beta1AamTaskDiscriminantTypeEnum,
        'sampleMethod': GoogleCloudDialogflowAamV2beta1AamTaskSampleMethodEnum,
        'state': GoogleCloudDialogflowAamV2beta1AamTaskStateEnum
      },
      keys: [
        'completeTime', 'createTime', 'discriminantId', 'discriminantType',
        'labellerId', 'name', 'noticeId', 'sampleMethod', 'state', 'transcript',
        'updateTime'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamTopicClusterParameters {
  name?: string|null;
  displayName?: string|null;
  utteranceCount?: number|null;
  discriminants?: Array<string>|null;
  createTime?: string|null;
  updateTime?: string|null;
  deleteTime?: string|null;
  etag?: string|null;
  reconciling?: boolean|null;
  annotations?: ApiClientObjectMap<string>|null;
  uid?: string|null;
  predictIntent?: string|null;
  intentPredictionScore?: number|null;
  clusterPurity?: number|null;
  labelCount?: number|null;
  clusterType?: GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType|null;
  sampleUtteranceContent?: string|null;
  skippedUtteranceCount?: number|null;
}
export class GoogleCloudDialogflowAamV2beta1AamTopicCluster extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamTopicClusterParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'utteranceCount',
        (parameters.utteranceCount == null) ? (null) :
                                              (parameters.utteranceCount));
    this.Serializable$set(
        'discriminants',
        (parameters.discriminants == null) ? (null) :
                                             (parameters.discriminants));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'deleteTime',
        (parameters.deleteTime == null) ? (null) : (parameters.deleteTime));
    this.Serializable$set(
        'etag', (parameters.etag == null) ? (null) : (parameters.etag));
    this.Serializable$set(
        'reconciling',
        (parameters.reconciling == null) ? (null) : (parameters.reconciling));
    this.Serializable$set(
        'annotations',
        (parameters.annotations == null) ? (null) : (parameters.annotations));
    this.Serializable$set(
        'uid', (parameters.uid == null) ? (null) : (parameters.uid));
    this.Serializable$set(
        'predictIntent',
        (parameters.predictIntent == null) ? (null) :
                                             (parameters.predictIntent));
    this.Serializable$set(
        'intentPredictionScore',
        (parameters.intentPredictionScore == null) ?
            (null) :
            (parameters.intentPredictionScore));
    this.Serializable$set(
        'clusterPurity',
        (parameters.clusterPurity == null) ? (null) :
                                             (parameters.clusterPurity));
    this.Serializable$set(
        'labelCount',
        (parameters.labelCount == null) ? (null) : (parameters.labelCount));
    this.Serializable$set(
        'clusterType',
        (parameters.clusterType == null) ? (null) : (parameters.clusterType));
    this.Serializable$set(
        'sampleUtteranceContent',
        (parameters.sampleUtteranceContent == null) ?
            (null) :
            (parameters.sampleUtteranceContent));
    this.Serializable$set(
        'skippedUtteranceCount',
        (parameters.skippedUtteranceCount == null) ?
            (null) :
            (parameters.skippedUtteranceCount));
  }

  static get ClusterType():
      IGoogleCloudDialogflowAamV2beta1AamTopicClusterClusterTypeEnum {
    return GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterTypeEnum;
  }

  get annotations(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('annotations')) ?
            (this.Serializable$get('annotations')) :
            (null));
  }

  /**
   * Optional. Annotations on the cluster. See
   * https://google.aip.dev/128#annotations
   */
  set annotations(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('annotations', value);
  }

  get clusterPurity(): number|null {
    return (
        (this.Serializable$has('clusterPurity')) ?
            (this.Serializable$get('clusterPurity')) :
            (null));
  }

  /**
   * Optional. The cluster purity.
   */
  set clusterPurity(value: number|null) {
    this.Serializable$set('clusterPurity', value);
  }

  get clusterType(): GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType
      |null {
    return (
        (this.Serializable$has('clusterType')) ?
            (this.Serializable$get('clusterType')) :
            (null));
  }

  /**
   * Optional. The type of the cluster.
   */
  set clusterType(value:
                      GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterType|
                  null) {
    this.Serializable$set('clusterType', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Creation time.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get deleteTime(): string|null {
    return (
        (this.Serializable$has('deleteTime')) ?
            (this.Serializable$get('deleteTime')) :
            (null));
  }

  /**
   * Output only. The time at which this resource was requested for deletion.
   */
  set deleteTime(value: string|null) {
    this.Serializable$set('deleteTime', value);
  }

  get discriminants(): Array<string>|null {
    return (
        (this.Serializable$has('discriminants')) ?
            (this.Serializable$get('discriminants')) :
            (null));
  }

  /**
   * Output only. Top list of full resource names of relevant discriminants
   * labelled for the topic cluster. Format:
   * projects//locations//aamDiscriminants/
   */
  set discriminants(value: Array<string>|null) {
    this.Serializable$set('discriminants', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the aam_topic_cluster.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get etag(): string|null {
    return (
        (this.Serializable$has('etag')) ? (this.Serializable$get('etag')) :
                                          (null));
  }

  /**
   * Output only. Etag of the cluster, may be sent on update requests to ensure
   * the client has an up-to-date value before proceeding.
   */
  set etag(value: string|null) {
    this.Serializable$set('etag', value);
  }

  get intentPredictionScore(): number|null {
    return (
        (this.Serializable$has('intentPredictionScore')) ?
            (this.Serializable$get('intentPredictionScore')) :
            (null));
  }

  /**
   * Optional. The score for predicted intent.
   */
  set intentPredictionScore(value: number|null) {
    this.Serializable$set('intentPredictionScore', value);
  }

  get labelCount(): number|null {
    return (
        (this.Serializable$has('labelCount')) ?
            (this.Serializable$get('labelCount')) :
            (null));
  }

  /**
   * Required. Output only. Total utterance count with relevant labels.
   */
  set labelCount(value: number|null) {
    this.Serializable$set('labelCount', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of the aam_topic_cluster. Format:
   * `projects//locations//aamTopicClusters/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get predictIntent(): string|null {
    return (
        (this.Serializable$has('predictIntent')) ?
            (this.Serializable$get('predictIntent')) :
            (null));
  }

  /**
   * Optional. The predicted intent class.
   */
  set predictIntent(value: string|null) {
    this.Serializable$set('predictIntent', value);
  }

  get reconciling(): boolean|null {
    return (
        (this.Serializable$has('reconciling')) ?
            (this.Serializable$get('reconciling')) :
            (null));
  }

  /**
   * Output only. Whether, or not, the cluster is in the process of being
   * updated.
   */
  set reconciling(value: boolean|null) {
    this.Serializable$set('reconciling', value);
  }

  get sampleUtteranceContent(): string|null {
    return (
        (this.Serializable$has('sampleUtteranceContent')) ?
            (this.Serializable$get('sampleUtteranceContent')) :
            (null));
  }

  /**
   * Optional. The content of a sample utterance from the cluster.
   */
  set sampleUtteranceContent(value: string|null) {
    this.Serializable$set('sampleUtteranceContent', value);
  }

  get skippedUtteranceCount(): number|null {
    return (
        (this.Serializable$has('skippedUtteranceCount')) ?
            (this.Serializable$get('skippedUtteranceCount')) :
            (null));
  }

  /**
   * Required. Output only. Total skipped utterance count.
   */
  set skippedUtteranceCount(value: number|null) {
    this.Serializable$set('skippedUtteranceCount', value);
  }

  get uid(): string|null {
    return (
        (this.Serializable$has('uid')) ? (this.Serializable$get('uid')) :
                                         (null));
  }

  /**
   * Output only. A system generated unique ID (in UUID4 format) for the
   * cluster.
   */
  set uid(value: string|null) {
    this.Serializable$set('uid', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. The most recent time this resource was modified.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  get utteranceCount(): number|null {
    return (
        (this.Serializable$has('utteranceCount')) ?
            (this.Serializable$get('utteranceCount')) :
            (null));
  }

  /**
   * Required. Output only. The number of utterances that belong to the cluster.
   * This is saved as cluster_size in cloudSQL.
   */
  set utteranceCount(value: number|null) {
    this.Serializable$set('utteranceCount', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamTopicCluster> {
    return GoogleCloudDialogflowAamV2beta1AamTopicCluster;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'clusterType':
            GoogleCloudDialogflowAamV2beta1AamTopicClusterClusterTypeEnum
      },
      keys: [
        'annotations', 'clusterPurity', 'clusterType', 'createTime',
        'deleteTime', 'discriminants', 'displayName', 'etag',
        'intentPredictionScore', 'labelCount', 'name', 'predictIntent',
        'reconciling', 'sampleUtteranceContent', 'skippedUtteranceCount', 'uid',
        'updateTime', 'utteranceCount'
      ],
      objectMaps: {
        'annotations': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamTrainingRunParameters {
  name?: string|null;
  state?: GoogleCloudDialogflowAamV2beta1AamTrainingRunState|null;
  discriminant?: string|null;
  type?: GoogleCloudDialogflowAamV2beta1AamTrainingRunType|null;
  metadata?: GoogleCloudDialogflowAamV2beta1AamTrainingRunTrainingMetadata|null;
  createTime?: string|null;
  updateTime?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1AamTrainingRun extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamTrainingRunParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
  }

  static get State(): IGoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum {
    return GoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum;
  }

  static get Type(): IGoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum {
    return GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum;
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Required. The creation time of the training_run.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get discriminant(): string|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * Required. The discriminant to find more examples of and add to the training
   * run.
   */
  set discriminant(value: string|null) {
    this.Serializable$set('discriminant', value);
  }

  get metadata(): GoogleCloudDialogflowAamV2beta1AamTrainingRunTrainingMetadata
      |null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Metadata related to the training phase of the training run.
   */
  set metadata(
      value: GoogleCloudDialogflowAamV2beta1AamTrainingRunTrainingMetadata|
      null) {
    this.Serializable$set('metadata', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of the aam_training_run. Format:
   * `projects//locations//ammTrainingRuns/ `.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get state(): GoogleCloudDialogflowAamV2beta1AamTrainingRunState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * State is the current status of training for a single model.
   */
  set state(value: GoogleCloudDialogflowAamV2beta1AamTrainingRunState|null) {
    this.Serializable$set('state', value);
  }

  get type(): GoogleCloudDialogflowAamV2beta1AamTrainingRunType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Required. The intent type
   */
  set type(value: GoogleCloudDialogflowAamV2beta1AamTrainingRunType|null) {
    this.Serializable$set('type', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * The last update time of the training_run.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamTrainingRun> {
    return GoogleCloudDialogflowAamV2beta1AamTrainingRun;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state': GoogleCloudDialogflowAamV2beta1AamTrainingRunStateEnum,
        'type': GoogleCloudDialogflowAamV2beta1AamTrainingRunTypeEnum
      },
      keys: [
        'createTime', 'discriminant', 'metadata', 'name', 'state', 'type',
        'updateTime'
      ],
      objects: {
        'metadata':
            GoogleCloudDialogflowAamV2beta1AamTrainingRunTrainingMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamTrainingRunTrainingMetadataParameters {
  discriminantMetrics?:
      Array<GoogleCloudDialogflowAamV2beta1DiscriminantMetrics>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1AamTrainingRunTrainingMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AamTrainingRunTrainingMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminantMetrics',
        (parameters.discriminantMetrics == null) ?
            (null) :
            (parameters.discriminantMetrics));
  }

  get discriminantMetrics():
      Array<GoogleCloudDialogflowAamV2beta1DiscriminantMetrics>|null {
    return (
        (this.Serializable$has('discriminantMetrics')) ?
            (this.Serializable$get('discriminantMetrics')) :
            (null));
  }

  /**
   * DiscriminantMetrics contains metrics for a single discriminant, treated as
   * a binary classifier.
   */
  set discriminantMetrics(
      value: Array<GoogleCloudDialogflowAamV2beta1DiscriminantMetrics>|null) {
    this.Serializable$set('discriminantMetrics', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1AamTrainingRunTrainingMetadata> {
    return GoogleCloudDialogflowAamV2beta1AamTrainingRunTrainingMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'discriminantMetrics':
            GoogleCloudDialogflowAamV2beta1DiscriminantMetrics
      },
      keys: ['discriminantMetrics']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamTranscriptParameters {
  name?: string|null;
  aamUtterance?: Array<GoogleCloudDialogflowAamV2beta1AamUtterance>|null;
  synthetic?: boolean|null;
}
export class GoogleCloudDialogflowAamV2beta1AamTranscript extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1AamTranscriptParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'aamUtterance',
        (parameters.aamUtterance == null) ? (null) : (parameters.aamUtterance));
    this.Serializable$set(
        'synthetic',
        (parameters.synthetic == null) ? (null) : (parameters.synthetic));
  }

  get aamUtterance(): Array<GoogleCloudDialogflowAamV2beta1AamUtterance>|null {
    return (
        (this.Serializable$has('aamUtterance')) ?
            (this.Serializable$get('aamUtterance')) :
            (null));
  }

  /**
   * Output only. All aam_utterances in this transcript.
   */
  set aamUtterance(value: Array<GoogleCloudDialogflowAamV2beta1AamUtterance>|
                   null) {
    this.Serializable$set('aamUtterance', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the aam_transcript. Format:
   * `projects//locations//aamDataset/ /aamTranscripts/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get synthetic(): boolean|null {
    return (
        (this.Serializable$has('synthetic')) ?
            (this.Serializable$get('synthetic')) :
            (null));
  }

  /**
   * Indicates whether this transcript is synthetically created. Synthetic
   * transcripts don't represent any real end-to-end conversations and simply
   * serve as containers for utterances.
   */
  set synthetic(value: boolean|null) {
    this.Serializable$set('synthetic', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamTranscript> {
    return GoogleCloudDialogflowAamV2beta1AamTranscript;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'aamUtterance': GoogleCloudDialogflowAamV2beta1AamUtterance},
      keys: ['aamUtterance', 'name', 'synthetic']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AamUtteranceParameters {
  name?: string|null;
  participant?: GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant|null;
  position?: number|null;
  content?: string|null;
  createTime?: string|null;
  intentScore?: number|null;
}
export class GoogleCloudDialogflowAamV2beta1AamUtterance extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1AamUtteranceParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'participant',
        (parameters.participant == null) ? (null) : (parameters.participant));
    this.Serializable$set(
        'position',
        (parameters.position == null) ? (null) : (parameters.position));
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'intentScore',
        (parameters.intentScore == null) ? (null) : (parameters.intentScore));
  }

  static get Participant():
      IGoogleCloudDialogflowAamV2beta1AamUtteranceParticipantEnum {
    return GoogleCloudDialogflowAamV2beta1AamUtteranceParticipantEnum;
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Output only. AamUtterance's content
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Optional. Optional timestamp for the specific aam_utterance recorded.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get intentScore(): number|null {
    return (
        (this.Serializable$has('intentScore')) ?
            (this.Serializable$get('intentScore')) :
            (null));
  }

  /**
   * Optional. Optional: likelihood of containing intent in the specific
   * utterance.
   */
  set intentScore(value: number|null) {
    this.Serializable$set('intentScore', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Immutable. The unique identifier of the AamUtterance. Format:
   * `projects//locations//aamDataset/ /aamTranscripts//aamUtterances/ `.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get participant(): GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant
      |null {
    return (
        (this.Serializable$has('participant')) ?
            (this.Serializable$get('participant')) :
            (null));
  }

  /**
   * Required. The party who made the utterance
   */
  set participant(value: GoogleCloudDialogflowAamV2beta1AamUtteranceParticipant|
                  null) {
    this.Serializable$set('participant', value);
  }

  get position(): number|null {
    return (
        (this.Serializable$has('position')) ?
            (this.Serializable$get('position')) :
            (null));
  }

  /**
   * Output only. Position is the index of the aam_utterance within a specific
   * aamTranscript
   */
  set position(value: number|null) {
    this.Serializable$set('position', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AamUtterance> {
    return GoogleCloudDialogflowAamV2beta1AamUtterance;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'participant':
            GoogleCloudDialogflowAamV2beta1AamUtteranceParticipantEnum
      },
      keys: [
        'content', 'createTime', 'intentScore', 'name', 'participant',
        'position'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AddUserRequestParameters {
  email?: string|null;
  role?: GoogleCloudDialogflowAamV2beta1AddUserRequestRole|null;
  policy?: GoogleIamV1Policy|null;
}
export class GoogleCloudDialogflowAamV2beta1AddUserRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AddUserRequestParameters = {}) {
    super();
    this.Serializable$set(
        'email', (parameters.email == null) ? (null) : (parameters.email));
    this.Serializable$set(
        'role', (parameters.role == null) ? (null) : (parameters.role));
    this.Serializable$set(
        'policy', (parameters.policy == null) ? (null) : (parameters.policy));
  }

  static get Role(): IGoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum {
    return GoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum;
  }

  get email(): string|null {
    return (
        (this.Serializable$has('email')) ? (this.Serializable$get('email')) :
                                           (null));
  }

  /**
   * Email of the user AAM wants to add.
   */
  set email(value: string|null) {
    this.Serializable$set('email', value);
  }

  get policy(): GoogleIamV1Policy|null {
    return (
        (this.Serializable$has('policy')) ? (this.Serializable$get('policy')) :
                                            (null));
  }

  /**
   * The complete policy applied to the project in the parent. The size of the
   * policy is limited to a few 10s of KB. This is not filled by the UI console.
   */
  set policy(value: GoogleIamV1Policy|null) {
    this.Serializable$set('policy', value);
  }

  get role(): GoogleCloudDialogflowAamV2beta1AddUserRequestRole|null {
    return (
        (this.Serializable$has('role')) ? (this.Serializable$get('role')) :
                                          (null));
  }

  /**
   * New user's AAM role
   */
  set role(value: GoogleCloudDialogflowAamV2beta1AddUserRequestRole|null) {
    this.Serializable$set('role', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AddUserRequest> {
    return GoogleCloudDialogflowAamV2beta1AddUserRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'role': GoogleCloudDialogflowAamV2beta1AddUserRequestRoleEnum},
      keys: ['email', 'policy', 'role'],
      objects: {'policy': GoogleIamV1Policy}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1AddUserResponseParameters {
  policy?: GoogleIamV1Policy|null;
}
export class GoogleCloudDialogflowAamV2beta1AddUserResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AddUserResponseParameters = {}) {
    super();
    this.Serializable$set(
        'policy', (parameters.policy == null) ? (null) : (parameters.policy));
  }

  get policy(): GoogleIamV1Policy|null {
    return (
        (this.Serializable$has('policy')) ? (this.Serializable$get('policy')) :
                                            (null));
  }

  /**
   * The updated IAM policy
   */
  set policy(value: GoogleIamV1Policy|null) {
    this.Serializable$set('policy', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AddUserResponse> {
    return GoogleCloudDialogflowAamV2beta1AddUserResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['policy'], objects: {'policy': GoogleIamV1Policy}};
  }
}

export interface GoogleCloudDialogflowAamV2beta1AdminToolMetricsParameters {
  discriminantId?: string|null;
  displayName?: string|null;
  discriminantType?:
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType|null;
  mlPredictionCounts?: number|null;
  labeledTranscripts?: number|null;
  labeledPhrases?: number|null;
  f1Score?: number|null;
}
export class GoogleCloudDialogflowAamV2beta1AdminToolMetrics extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1AdminToolMetricsParameters = {}) {
    super();
    this.Serializable$set(
        'discriminantId',
        (parameters.discriminantId == null) ? (null) :
                                              (parameters.discriminantId));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'discriminantType',
        (parameters.discriminantType == null) ? (null) :
                                                (parameters.discriminantType));
    this.Serializable$set(
        'mlPredictionCounts',
        (parameters.mlPredictionCounts == null) ?
            (null) :
            (parameters.mlPredictionCounts));
    this.Serializable$set(
        'labeledTranscripts',
        (parameters.labeledTranscripts == null) ?
            (null) :
            (parameters.labeledTranscripts));
    this.Serializable$set(
        'labeledPhrases',
        (parameters.labeledPhrases == null) ? (null) :
                                              (parameters.labeledPhrases));
    this.Serializable$set(
        'f1Score',
        (parameters.f1Score == null) ? (null) : (parameters.f1Score));
  }

  static get DiscriminantType():
      IGoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum {
    return GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum;
  }

  get discriminantId(): string|null {
    return (
        (this.Serializable$has('discriminantId')) ?
            (this.Serializable$get('discriminantId')) :
            (null));
  }

  /**
   * A unique id for a discriminant.
   */
  set discriminantId(value: string|null) {
    this.Serializable$set('discriminantId', value);
  }

  get discriminantType():
      GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType|null {
    return (
        (this.Serializable$has('discriminantType')) ?
            (this.Serializable$get('discriminantType')) :
            (null));
  }

  /**
   * Discriminant type of the underlying discriminant
   */
  set discriminantType(
      value: GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantType|
      null) {
    this.Serializable$set('discriminantType', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Human readable name for a discriminant. e.g. for driver type, it's
   * \"Billing\", \"Account\"; for intent type, it's \"bill_incorrect\"; for
   * contextual intent type, it's \"explain_charges\" etc.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get f1Score(): number|null {
    return (
        (this.Serializable$has('f1Score')) ?
            (this.Serializable$get('f1Score')) :
            (null));
  }

  /**
   * f1_score associated with the discriminant
   */
  set f1Score(value: number|null) {
    this.Serializable$set('f1Score', value);
  }

  get labeledPhrases(): number|null {
    return (
        (this.Serializable$has('labeledPhrases')) ?
            (this.Serializable$get('labeledPhrases')) :
            (null));
  }

  /**
   * Number of the labeled phrases
   */
  set labeledPhrases(value: number|null) {
    this.Serializable$set('labeledPhrases', value);
  }

  get labeledTranscripts(): number|null {
    return (
        (this.Serializable$has('labeledTranscripts')) ?
            (this.Serializable$get('labeledTranscripts')) :
            (null));
  }

  /**
   * Number of the labeled transcripts
   */
  set labeledTranscripts(value: number|null) {
    this.Serializable$set('labeledTranscripts', value);
  }

  get mlPredictionCounts(): number|null {
    return (
        (this.Serializable$has('mlPredictionCounts')) ?
            (this.Serializable$get('mlPredictionCounts')) :
            (null));
  }

  /**
   * Metrics to be rendered in the reports
   */
  set mlPredictionCounts(value: number|null) {
    this.Serializable$set('mlPredictionCounts', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1AdminToolMetrics> {
    return GoogleCloudDialogflowAamV2beta1AdminToolMetrics;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'discriminantType':
            GoogleCloudDialogflowAamV2beta1AdminToolMetricsDiscriminantTypeEnum
      },
      keys: [
        'discriminantId', 'discriminantType', 'displayName', 'f1Score',
        'labeledPhrases', 'labeledTranscripts', 'mlPredictionCounts'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeRequestParameters {
}
export class
    GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeRequest extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeRequestParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeRequest> {
    return GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeResponseParameters {
  discriminantChange?: GoogleCloudDialogflowAamV2beta1AamDiscriminantChange|
      null;
}
export class
    GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminantChange',
        (parameters.discriminantChange == null) ?
            (null) :
            (parameters.discriminantChange));
  }

  get discriminantChange(): GoogleCloudDialogflowAamV2beta1AamDiscriminantChange
      |null {
    return (
        (this.Serializable$has('discriminantChange')) ?
            (this.Serializable$get('discriminantChange')) :
            (null));
  }

  /**
   * The approved discriminant change.
   */
  set discriminantChange(
      value: GoogleCloudDialogflowAamV2beta1AamDiscriminantChange|null) {
    this.Serializable$set('discriminantChange', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeResponse> {
    return GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['discriminantChange'],
      objects: {
        'discriminantChange':
            GoogleCloudDialogflowAamV2beta1AamDiscriminantChange
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsRequestParameters {
  requests?: Array<GoogleCloudDialogflowAamV2beta1CreateAamDiscriminantRequest>|
      null;
}
export class
    GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsRequest extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'requests',
        (parameters.requests == null) ? (null) : (parameters.requests));
  }

  get requests():
      Array<GoogleCloudDialogflowAamV2beta1CreateAamDiscriminantRequest>|null {
    return (
        (this.Serializable$has('requests')) ?
            (this.Serializable$get('requests')) :
            (null));
  }

  /**
   * Required. The request message specifying the resources to create. A maximum
   * of 1000 discriminants can be created in a batch.
   */
  set requests(
      value: Array<GoogleCloudDialogflowAamV2beta1CreateAamDiscriminantRequest>|
      null) {
    this.Serializable$set('requests', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsRequest> {
    return GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'requests': GoogleCloudDialogflowAamV2beta1CreateAamDiscriminantRequest
      },
      keys: ['requests']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsResponseParameters {
  discriminants?: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminants',
        (parameters.discriminants == null) ? (null) :
                                             (parameters.discriminants));
  }

  get discriminants():
      Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null {
    return (
        (this.Serializable$has('discriminants')) ?
            (this.Serializable$get('discriminants')) :
            (null));
  }

  /**
   * Discriminants created.
   */
  set discriminants(value:
                        Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|
                    null) {
    this.Serializable$set('discriminants', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsResponse> {
    return GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'discriminants': GoogleCloudDialogflowAamV2beta1AamDiscriminant},
      keys: ['discriminants']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1BatchDeleteAamLabelsRequestParameters {
  names?: Array<string>|null;
}
export class GoogleCloudDialogflowAamV2beta1BatchDeleteAamLabelsRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1BatchDeleteAamLabelsRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'names', (parameters.names == null) ? (null) : (parameters.names));
  }

  get names(): Array<string>|null {
    return (
        (this.Serializable$has('names')) ? (this.Serializable$get('names')) :
                                           (null));
  }

  /**
   * Required. Format: `projects//locations//aamLabels/ `.
   */
  set names(value: Array<string>|null) {
    this.Serializable$set('names', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1BatchDeleteAamLabelsRequest> {
    return GoogleCloudDialogflowAamV2beta1BatchDeleteAamLabelsRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['names']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1BatchExportIntentsRequestParameters {
  agent?: string|null;
  discriminantNames?: Array<string>|null;
  exportChildren?: boolean|null;
}
export class GoogleCloudDialogflowAamV2beta1BatchExportIntentsRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1BatchExportIntentsRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'agent', (parameters.agent == null) ? (null) : (parameters.agent));
    this.Serializable$set(
        'discriminantNames',
        (parameters.discriminantNames == null) ?
            (null) :
            (parameters.discriminantNames));
    this.Serializable$set(
        'exportChildren',
        (parameters.exportChildren == null) ? (null) :
                                              (parameters.exportChildren));
  }

  get agent(): string|null {
    return (
        (this.Serializable$has('agent')) ? (this.Serializable$get('agent')) :
                                           (null));
  }

  /**
   * Required. Dialogflow agent resource.
   */
  set agent(value: string|null) {
    this.Serializable$set('agent', value);
  }

  get discriminantNames(): Array<string>|null {
    return (
        (this.Serializable$has('discriminantNames')) ?
            (this.Serializable$get('discriminantNames')) :
            (null));
  }

  /**
   * Required. A list of discriminant ids to be exported. Format:
   * `projects//locations/ /aamDiscriminants/ `.
   */
  set discriminantNames(value: Array<string>|null) {
    this.Serializable$set('discriminantNames', value);
  }

  get exportChildren(): boolean|null {
    return (
        (this.Serializable$has('exportChildren')) ?
            (this.Serializable$get('exportChildren')) :
            (null));
  }

  /**
   * Whether to export head intent with its children
   */
  set exportChildren(value: boolean|null) {
    this.Serializable$set('exportChildren', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1BatchExportIntentsRequest> {
    return GoogleCloudDialogflowAamV2beta1BatchExportIntentsRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agent', 'discriminantNames', 'exportChildren']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseParameters {
  intentStatusMap?: ApiClientObjectMap<
      GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap>|
      null;
}
export class GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'intentStatusMap',
        (parameters.intentStatusMap == null) ? (null) :
                                               (parameters.intentStatusMap));
  }

  static get IntentStatusMap():
      IGoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMapEnum {
    return GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMapEnum;
  }

  get intentStatusMap(): ApiClientObjectMap<
      GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap>|
      null {
    return (
        (this.Serializable$has('intentStatusMap')) ?
            (this.Serializable$get('intentStatusMap')) :
            (null));
  }

  /**
   * A map of intent's discriminant names to its exporting status.
   */
  set intentStatusMap(
      value: ApiClientObjectMap<
          GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMap>|
      null) {
    this.Serializable$set('intentStatusMap', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponse> {
    return GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'intentStatusMap':
            GoogleCloudDialogflowAamV2beta1BatchExportIntentsResponseIntentStatusMapEnum
      },
      keys: ['intentStatusMap'],
      objectMaps: {
        'intentStatusMap': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsRequestParameters {
  names?: Array<string>|null;
}
export class GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'names', (parameters.names == null) ? (null) : (parameters.names));
  }

  get names(): Array<string>|null {
    return (
        (this.Serializable$has('names')) ? (this.Serializable$get('names')) :
                                           (null));
  }

  /**
   * Required. Format: `projects//locations//aamLabels/ `.
   */
  set names(value: Array<string>|null) {
    this.Serializable$set('names', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsRequest> {
    return GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['names']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsResponseParameters {
  labels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
}
export class GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
  }

  get labels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * The labels that matched the requested label names.
   */
  set labels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null) {
    this.Serializable$set('labels', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsResponse> {
    return GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'labels': GoogleCloudDialogflowAamV2beta1AamLabel},
      keys: ['labels']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsRequestParameters {
  aamLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
  updateMask?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamLabels',
        (parameters.aamLabels == null) ? (null) : (parameters.aamLabels));
    this.Serializable$set(
        'updateMask',
        (parameters.updateMask == null) ? (null) : (parameters.updateMask));
  }

  get aamLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('aamLabels')) ?
            (this.Serializable$get('aamLabels')) :
            (null));
  }

  /**
   * Required. The list of labels to upsert.
   */
  set aamLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null) {
    this.Serializable$set('aamLabels', value);
  }

  get updateMask(): string|null {
    return (
        (this.Serializable$has('updateMask')) ?
            (this.Serializable$get('updateMask')) :
            (null));
  }

  /**
   * Optional. The mask to control which fields get updated. This mask does not
   * apply to inserted labels.
   */
  set updateMask(value: string|null) {
    this.Serializable$set('updateMask', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsRequest> {
    return GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'aamLabels': GoogleCloudDialogflowAamV2beta1AamLabel},
      keys: ['aamLabels', 'updateMask']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsResponseParameters {
  aamLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
}
export class GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamLabels',
        (parameters.aamLabels == null) ? (null) : (parameters.aamLabels));
  }

  get aamLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('aamLabels')) ?
            (this.Serializable$get('aamLabels')) :
            (null));
  }

  /**
   * The collection of updated or created aam_labels.
   */
  set aamLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null) {
    this.Serializable$set('aamLabels', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsResponse> {
    return GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'aamLabels': GoogleCloudDialogflowAamV2beta1AamLabel},
      keys: ['aamLabels']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateRequestParameters {
  insertLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
  deleteLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
  labelerGaiaId?: string|null;
  pageUtteranceCount?: number|null;
  labeledUtteranceCount?: number|null;
  skipUtterances?: Array<string>|null;
}
export class GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'insertLabels',
        (parameters.insertLabels == null) ? (null) : (parameters.insertLabels));
    this.Serializable$set(
        'deleteLabels',
        (parameters.deleteLabels == null) ? (null) : (parameters.deleteLabels));
    this.Serializable$set(
        'labelerGaiaId',
        (parameters.labelerGaiaId == null) ? (null) :
                                             (parameters.labelerGaiaId));
    this.Serializable$set(
        'pageUtteranceCount',
        (parameters.pageUtteranceCount == null) ?
            (null) :
            (parameters.pageUtteranceCount));
    this.Serializable$set(
        'labeledUtteranceCount',
        (parameters.labeledUtteranceCount == null) ?
            (null) :
            (parameters.labeledUtteranceCount));
    this.Serializable$set(
        'skipUtterances',
        (parameters.skipUtterances == null) ? (null) :
                                              (parameters.skipUtterances));
  }

  get deleteLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('deleteLabels')) ?
            (this.Serializable$get('deleteLabels')) :
            (null));
  }

  /**
   * Optional. The list of labels to be deleted
   */
  set deleteLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null) {
    this.Serializable$set('deleteLabels', value);
  }

  get insertLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('insertLabels')) ?
            (this.Serializable$get('insertLabels')) :
            (null));
  }

  /**
   * Optional. The list of labels to be inserted in the table
   */
  set insertLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null) {
    this.Serializable$set('insertLabels', value);
  }

  get labeledUtteranceCount(): number|null {
    return (
        (this.Serializable$has('labeledUtteranceCount')) ?
            (this.Serializable$get('labeledUtteranceCount')) :
            (null));
  }

  /**
   * Optional. The number of already labeled utterances on the page at the time
   * the request was sent. Provided by UI for metrics.
   */
  set labeledUtteranceCount(value: number|null) {
    this.Serializable$set('labeledUtteranceCount', value);
  }

  get labelerGaiaId(): string|null {
    return (
        (this.Serializable$has('labelerGaiaId')) ?
            (this.Serializable$get('labelerGaiaId')) :
            (null));
  }

  /**
   * The labeler's GAIA ID, this is filled by Dialogflow frontend not by the UI
   */
  set labelerGaiaId(value: string|null) {
    this.Serializable$set('labelerGaiaId', value);
  }

  get pageUtteranceCount(): number|null {
    return (
        (this.Serializable$has('pageUtteranceCount')) ?
            (this.Serializable$get('pageUtteranceCount')) :
            (null));
  }

  /**
   * Optional. The size of the page displayed to the user. Provided by UI for
   * metrics.
   */
  set pageUtteranceCount(value: number|null) {
    this.Serializable$set('pageUtteranceCount', value);
  }

  get skipUtterances(): Array<string>|null {
    return (
        (this.Serializable$has('skipUtterances')) ?
            (this.Serializable$get('skipUtterances')) :
            (null));
  }

  /**
   * Required. Resource name of utterances that user skips.
   */
  set skipUtterances(value: Array<string>|null) {
    this.Serializable$set('skipUtterances', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateRequest> {
    return GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'deleteLabels': GoogleCloudDialogflowAamV2beta1AamLabel,
        'insertLabels': GoogleCloudDialogflowAamV2beta1AamLabel
      },
      keys: [
        'deleteLabels', 'insertLabels', 'labeledUtteranceCount',
        'labelerGaiaId', 'pageUtteranceCount', 'skipUtterances'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateResponseParameters {
  resultLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
  skipUtterances?: Array<string>|null;
}
export class GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'resultLabels',
        (parameters.resultLabels == null) ? (null) : (parameters.resultLabels));
    this.Serializable$set(
        'skipUtterances',
        (parameters.skipUtterances == null) ? (null) :
                                              (parameters.skipUtterances));
  }

  get resultLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('resultLabels')) ?
            (this.Serializable$get('resultLabels')) :
            (null));
  }

  /**
   * The list of resulting labels reflecting final utterance labels.
   */
  set resultLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null) {
    this.Serializable$set('resultLabels', value);
  }

  get skipUtterances(): Array<string>|null {
    return (
        (this.Serializable$has('skipUtterances')) ?
            (this.Serializable$get('skipUtterances')) :
            (null));
  }

  /**
   * Resource name of utterances that user skips.
   */
  set skipUtterances(value: Array<string>|null) {
    this.Serializable$set('skipUtterances', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateResponse> {
    return GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'resultLabels': GoogleCloudDialogflowAamV2beta1AamLabel},
      keys: ['resultLabels', 'skipUtterances']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestParameters {
  filePathAndContent?: Array<
      GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestFilePathAndContent>|
      null;
}
export class
    GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequest extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'filePathAndContent',
        (parameters.filePathAndContent == null) ?
            (null) :
            (parameters.filePathAndContent));
  }

  get filePathAndContent(): Array<
      GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestFilePathAndContent>|
      null {
    return (
        (this.Serializable$has('filePathAndContent')) ?
            (this.Serializable$get('filePathAndContent')) :
            (null));
  }

  /**
   * List of filepaths and their contents.
   */
  set filePathAndContent(
      value: Array<
          GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestFilePathAndContent>|
      null) {
    this.Serializable$set('filePathAndContent', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequest> {
    return GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'filePathAndContent':
            GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestFilePathAndContent
      },
      keys: ['filePathAndContent']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestFilePathAndContentParameters {
  filePath?: string|null;
  content?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestFilePathAndContent extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestFilePathAndContentParameters =
              {}) {
    super();
    this.Serializable$set(
        'filePath',
        (parameters.filePath == null) ? (null) : (parameters.filePath));
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Required. Textproto content of the file.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get filePath(): string|null {
    return (
        (this.Serializable$has('filePath')) ?
            (this.Serializable$get('filePath')) :
            (null));
  }

  /**
   * Required. Path of the textproto file.
   */
  set filePath(value: string|null) {
    this.Serializable$set('filePath', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestFilePathAndContent> {
    return GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequestFilePathAndContent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['content', 'filePath']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseParameters {
  errors?: Array<
      GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseCompileAgentDefinitionError>|
      null;
  schema?: CloudAiDialogflowProtoModelSynopsisAgentschemaSchema|null;
  schemaTextProto?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'errors', (parameters.errors == null) ? (null) : (parameters.errors));
    this.Serializable$set(
        'schema', (parameters.schema == null) ? (null) : (parameters.schema));
    this.Serializable$set(
        'schemaTextProto',
        (parameters.schemaTextProto == null) ? (null) :
                                               (parameters.schemaTextProto));
  }

  get errors(): Array<
      GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseCompileAgentDefinitionError>|
      null {
    return (
        (this.Serializable$has('errors')) ? (this.Serializable$get('errors')) :
                                            (null));
  }

  /**
   * List of compilation errors in the input definition.
   */
  set errors(
      value: Array<
          GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseCompileAgentDefinitionError>|
      null) {
    this.Serializable$set('errors', value);
  }

  get schema(): CloudAiDialogflowProtoModelSynopsisAgentschemaSchema|null {
    return (
        (this.Serializable$has('schema')) ? (this.Serializable$get('schema')) :
                                            (null));
  }

  /**
   * Compiled Synopsis schema. It should only be used if the `errors` field is
   * empty.
   */
  set schema(value: CloudAiDialogflowProtoModelSynopsisAgentschemaSchema|null) {
    this.Serializable$set('schema', value);
  }

  get schemaTextProto(): string|null {
    return (
        (this.Serializable$has('schemaTextProto')) ?
            (this.Serializable$get('schemaTextProto')) :
            (null));
  }

  /**
   * Compiled Synopsis schema in textproto format.
   */
  set schemaTextProto(value: string|null) {
    this.Serializable$set('schemaTextProto', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponse> {
    return GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'errors':
            GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseCompileAgentDefinitionError
      },
      keys: ['errors', 'schema', 'schemaTextProto'],
      objects: {'schema': CloudAiDialogflowProtoModelSynopsisAgentschemaSchema}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseCompileAgentDefinitionErrorParameters {
  filePath?: string|null;
  lineNumber?: string|null;
  errorMessage?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseCompileAgentDefinitionError extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseCompileAgentDefinitionErrorParameters =
              {}) {
    super();
    this.Serializable$set(
        'filePath',
        (parameters.filePath == null) ? (null) : (parameters.filePath));
    this.Serializable$set(
        'lineNumber',
        (parameters.lineNumber == null) ? (null) : (parameters.lineNumber));
    this.Serializable$set(
        'errorMessage',
        (parameters.errorMessage == null) ? (null) : (parameters.errorMessage));
  }

  get errorMessage(): string|null {
    return (
        (this.Serializable$has('errorMessage')) ?
            (this.Serializable$get('errorMessage')) :
            (null));
  }

  /**
   * Required. A textual description of the compilation error.
   */
  set errorMessage(value: string|null) {
    this.Serializable$set('errorMessage', value);
  }

  get filePath(): string|null {
    return (
        (this.Serializable$has('filePath')) ?
            (this.Serializable$get('filePath')) :
            (null));
  }

  /**
   * Required. File path of the textproto file.
   */
  set filePath(value: string|null) {
    this.Serializable$set('filePath', value);
  }

  get lineNumber(): string|null {
    return (
        (this.Serializable$has('lineNumber')) ?
            (this.Serializable$get('lineNumber')) :
            (null));
  }

  /**
   * Optional. Line on which the error occurred.
   */
  set lineNumber(value: string|null) {
    this.Serializable$set('lineNumber', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseCompileAgentDefinitionError> {
    return GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponseCompileAgentDefinitionError;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['errorMessage', 'filePath', 'lineNumber']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1CompleteAamTaskRequestParameters {
  insertLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
  deleteLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
  labelerGaiaId?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1CompleteAamTaskRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1CompleteAamTaskRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'insertLabels',
        (parameters.insertLabels == null) ? (null) : (parameters.insertLabels));
    this.Serializable$set(
        'deleteLabels',
        (parameters.deleteLabels == null) ? (null) : (parameters.deleteLabels));
    this.Serializable$set(
        'labelerGaiaId',
        (parameters.labelerGaiaId == null) ? (null) :
                                             (parameters.labelerGaiaId));
  }

  get deleteLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('deleteLabels')) ?
            (this.Serializable$get('deleteLabels')) :
            (null));
  }

  /**
   * The list of labels to be deleted
   */
  set deleteLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null) {
    this.Serializable$set('deleteLabels', value);
  }

  get insertLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('insertLabels')) ?
            (this.Serializable$get('insertLabels')) :
            (null));
  }

  /**
   * The list of labels to be inserted in the table
   */
  set insertLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null) {
    this.Serializable$set('insertLabels', value);
  }

  get labelerGaiaId(): string|null {
    return (
        (this.Serializable$has('labelerGaiaId')) ?
            (this.Serializable$get('labelerGaiaId')) :
            (null));
  }

  /**
   * The labeler's GAIA ID, this is filled by Dialogflow frontend not by the UI
   */
  set labelerGaiaId(value: string|null) {
    this.Serializable$set('labelerGaiaId', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1CompleteAamTaskRequest> {
    return GoogleCloudDialogflowAamV2beta1CompleteAamTaskRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'deleteLabels': GoogleCloudDialogflowAamV2beta1AamLabel,
        'insertLabels': GoogleCloudDialogflowAamV2beta1AamLabel
      },
      keys: ['deleteLabels', 'insertLabels', 'labelerGaiaId']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1CompositeAamLabelParameters {
  aamLabel?: GoogleCloudDialogflowAamV2beta1AamLabel|null;
  aamUtterance?: GoogleCloudDialogflowAamV2beta1AamUtterance|null;
  aamNotices?: Array<GoogleCloudDialogflowAamV2beta1AamNotice>|null;
}
export class GoogleCloudDialogflowAamV2beta1CompositeAamLabel extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1CompositeAamLabelParameters = {}) {
    super();
    this.Serializable$set(
        'aamLabel',
        (parameters.aamLabel == null) ? (null) : (parameters.aamLabel));
    this.Serializable$set(
        'aamUtterance',
        (parameters.aamUtterance == null) ? (null) : (parameters.aamUtterance));
    this.Serializable$set(
        'aamNotices',
        (parameters.aamNotices == null) ? (null) : (parameters.aamNotices));
  }

  get aamLabel(): GoogleCloudDialogflowAamV2beta1AamLabel|null {
    return (
        (this.Serializable$has('aamLabel')) ?
            (this.Serializable$get('aamLabel')) :
            (null));
  }

  /**
   * The label.
   */
  set aamLabel(value: GoogleCloudDialogflowAamV2beta1AamLabel|null) {
    this.Serializable$set('aamLabel', value);
  }

  get aamNotices(): Array<GoogleCloudDialogflowAamV2beta1AamNotice>|null {
    return (
        (this.Serializable$has('aamNotices')) ?
            (this.Serializable$get('aamNotices')) :
            (null));
  }

  /**
   * The notices that the label has.
   */
  set aamNotices(value: Array<GoogleCloudDialogflowAamV2beta1AamNotice>|null) {
    this.Serializable$set('aamNotices', value);
  }

  get aamUtterance(): GoogleCloudDialogflowAamV2beta1AamUtterance|null {
    return (
        (this.Serializable$has('aamUtterance')) ?
            (this.Serializable$get('aamUtterance')) :
            (null));
  }

  /**
   * The utterance.
   */
  set aamUtterance(value: GoogleCloudDialogflowAamV2beta1AamUtterance|null) {
    this.Serializable$set('aamUtterance', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1CompositeAamLabel> {
    return GoogleCloudDialogflowAamV2beta1CompositeAamLabel;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'aamNotices': GoogleCloudDialogflowAamV2beta1AamNotice},
      keys: ['aamLabel', 'aamNotices', 'aamUtterance'],
      objects: {
        'aamLabel': GoogleCloudDialogflowAamV2beta1AamLabel,
        'aamUtterance': GoogleCloudDialogflowAamV2beta1AamUtterance
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1CreateAamDiscriminantRequestParameters {
  parent?: string|null;
  aamDiscriminant?: GoogleCloudDialogflowAamV2beta1AamDiscriminant|null;
  userGaiaId?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1CreateAamDiscriminantRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1CreateAamDiscriminantRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'parent', (parameters.parent == null) ? (null) : (parameters.parent));
    this.Serializable$set(
        'aamDiscriminant',
        (parameters.aamDiscriminant == null) ? (null) :
                                               (parameters.aamDiscriminant));
    this.Serializable$set(
        'userGaiaId',
        (parameters.userGaiaId == null) ? (null) : (parameters.userGaiaId));
  }

  get aamDiscriminant(): GoogleCloudDialogflowAamV2beta1AamDiscriminant|null {
    return (
        (this.Serializable$has('aamDiscriminant')) ?
            (this.Serializable$get('aamDiscriminant')) :
            (null));
  }

  /**
   * Required. The aam_discriminant to create.
   */
  set aamDiscriminant(value: GoogleCloudDialogflowAamV2beta1AamDiscriminant|
                      null) {
    this.Serializable$set('aamDiscriminant', value);
  }

  get parent(): string|null {
    return (
        (this.Serializable$has('parent')) ? (this.Serializable$get('parent')) :
                                            (null));
  }

  /**
   * Required. Create an aam_discriminant for the project with . Format:
   * `projects//locations/`.
   */
  set parent(value: string|null) {
    this.Serializable$set('parent', value);
  }

  get userGaiaId(): string|null {
    return (
        (this.Serializable$has('userGaiaId')) ?
            (this.Serializable$get('userGaiaId')) :
            (null));
  }

  /**
   * GAIA ID of the user who made this request. This is filled by Dialogflow
   * frontend not by the UI.
   */
  set userGaiaId(value: string|null) {
    this.Serializable$set('userGaiaId', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1CreateAamDiscriminantRequest> {
    return GoogleCloudDialogflowAamV2beta1CreateAamDiscriminantRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['aamDiscriminant', 'parent', 'userGaiaId'],
      objects:
          {'aamDiscriminant': GoogleCloudDialogflowAamV2beta1AamDiscriminant}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1DiscriminantMetricsParameters {
  discriminant?: string|null;
  summary?: GoogleCloudDialogflowAamV2beta1Metrics|null;
  precisionRecallCurve?: Array<GoogleCloudDialogflowAamV2beta1Metrics>|null;
}
export class GoogleCloudDialogflowAamV2beta1DiscriminantMetrics extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1DiscriminantMetricsParameters = {}) {
    super();
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
    this.Serializable$set(
        'summary',
        (parameters.summary == null) ? (null) : (parameters.summary));
    this.Serializable$set(
        'precisionRecallCurve',
        (parameters.precisionRecallCurve == null) ?
            (null) :
            (parameters.precisionRecallCurve));
  }

  get discriminant(): string|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * Output only. Discrimant's id Format:
   * `projects//locations//aamDiscriminants/ `.
   */
  set discriminant(value: string|null) {
    this.Serializable$set('discriminant', value);
  }

  get precisionRecallCurve():
      Array<GoogleCloudDialogflowAamV2beta1Metrics>|null {
    return (
        (this.Serializable$has('precisionRecallCurve')) ?
            (this.Serializable$get('precisionRecallCurve')) :
            (null));
  }

  /**
   * Training run's precision recall curve is saved here.
   */
  set precisionRecallCurve(value: Array<GoogleCloudDialogflowAamV2beta1Metrics>|
                           null) {
    this.Serializable$set('precisionRecallCurve', value);
  }

  get summary(): GoogleCloudDialogflowAamV2beta1Metrics|null {
    return (
        (this.Serializable$has('summary')) ?
            (this.Serializable$get('summary')) :
            (null));
  }

  /**
   * A metrics instance that contains summary stats for a given discriminant.
   * This represents overall precsision/recall (i.e. not at a threshold).
   */
  set summary(value: GoogleCloudDialogflowAamV2beta1Metrics|null) {
    this.Serializable$set('summary', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1DiscriminantMetrics> {
    return GoogleCloudDialogflowAamV2beta1DiscriminantMetrics;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'precisionRecallCurve': GoogleCloudDialogflowAamV2beta1Metrics},
      keys: ['discriminant', 'precisionRecallCurve', 'summary'],
      objects: {'summary': GoogleCloudDialogflowAamV2beta1Metrics}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1DisplayPrebuiltTaxonomyImportCardsResponseParameters {
  prebuiltTaxonomyImportCards?:
      Array<GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCard>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1DisplayPrebuiltTaxonomyImportCardsResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1DisplayPrebuiltTaxonomyImportCardsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'prebuiltTaxonomyImportCards',
        (parameters.prebuiltTaxonomyImportCards == null) ?
            (null) :
            (parameters.prebuiltTaxonomyImportCards));
  }

  get prebuiltTaxonomyImportCards():
      Array<GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCard>|null {
    return (
        (this.Serializable$has('prebuiltTaxonomyImportCards')) ?
            (this.Serializable$get('prebuiltTaxonomyImportCards')) :
            (null));
  }

  /**
   * List of Prebuilt Taxonomy Import Cards needed by the UI.
   */
  set prebuiltTaxonomyImportCards(
      value: Array<GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCard>|
      null) {
    this.Serializable$set('prebuiltTaxonomyImportCards', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1DisplayPrebuiltTaxonomyImportCardsResponse> {
    return GoogleCloudDialogflowAamV2beta1DisplayPrebuiltTaxonomyImportCardsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'prebuiltTaxonomyImportCards':
            GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCard
      },
      keys: ['prebuiltTaxonomyImportCards']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1DisplayStatsResponseParameters {
  metricsUpdateTime?: string|null;
  transcriptCount?: number|null;
  utteranceCount?: number|null;
  labelCount?: number|null;
  labelUpdateTime?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1DisplayStatsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1DisplayStatsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'metricsUpdateTime',
        (parameters.metricsUpdateTime == null) ?
            (null) :
            (parameters.metricsUpdateTime));
    this.Serializable$set(
        'transcriptCount',
        (parameters.transcriptCount == null) ? (null) :
                                               (parameters.transcriptCount));
    this.Serializable$set(
        'utteranceCount',
        (parameters.utteranceCount == null) ? (null) :
                                              (parameters.utteranceCount));
    this.Serializable$set(
        'labelCount',
        (parameters.labelCount == null) ? (null) : (parameters.labelCount));
    this.Serializable$set(
        'labelUpdateTime',
        (parameters.labelUpdateTime == null) ? (null) :
                                               (parameters.labelUpdateTime));
  }

  get labelCount(): number|null {
    return (
        (this.Serializable$has('labelCount')) ?
            (this.Serializable$get('labelCount')) :
            (null));
  }

  /**
   * Total number of labels (labeled phrases) in the database.
   */
  set labelCount(value: number|null) {
    this.Serializable$set('labelCount', value);
  }

  get labelUpdateTime(): string|null {
    return (
        (this.Serializable$has('labelUpdateTime')) ?
            (this.Serializable$get('labelUpdateTime')) :
            (null));
  }

  /**
   * Timestamp when the last label was updated.
   */
  set labelUpdateTime(value: string|null) {
    this.Serializable$set('labelUpdateTime', value);
  }

  get metricsUpdateTime(): string|null {
    return (
        (this.Serializable$has('metricsUpdateTime')) ?
            (this.Serializable$get('metricsUpdateTime')) :
            (null));
  }

  /**
   * Timestamp when the metrics are lasted updated.
   */
  set metricsUpdateTime(value: string|null) {
    this.Serializable$set('metricsUpdateTime', value);
  }

  get transcriptCount(): number|null {
    return (
        (this.Serializable$has('transcriptCount')) ?
            (this.Serializable$get('transcriptCount')) :
            (null));
  }

  /**
   * Total number of transcripts in the database.
   */
  set transcriptCount(value: number|null) {
    this.Serializable$set('transcriptCount', value);
  }

  get utteranceCount(): number|null {
    return (
        (this.Serializable$has('utteranceCount')) ?
            (this.Serializable$get('utteranceCount')) :
            (null));
  }

  /**
   * Total number of utterances in the database.
   */
  set utteranceCount(value: number|null) {
    this.Serializable$set('utteranceCount', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1DisplayStatsResponse> {
    return GoogleCloudDialogflowAamV2beta1DisplayStatsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'labelCount', 'labelUpdateTime', 'metricsUpdateTime', 'transcriptCount',
        'utteranceCount'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1DisplayTaxonomyProgressResponseParameters {
  reviewedDiscriminantCount?: number|null;
  reviewedDiscriminantLastThirtyDays?: number|null;
  totalDiscriminantCount?: number|null;
}
export class
    GoogleCloudDialogflowAamV2beta1DisplayTaxonomyProgressResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1DisplayTaxonomyProgressResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'reviewedDiscriminantCount',
        (parameters.reviewedDiscriminantCount == null) ?
            (null) :
            (parameters.reviewedDiscriminantCount));
    this.Serializable$set(
        'reviewedDiscriminantLastThirtyDays',
        (parameters.reviewedDiscriminantLastThirtyDays == null) ?
            (null) :
            (parameters.reviewedDiscriminantLastThirtyDays));
    this.Serializable$set(
        'totalDiscriminantCount',
        (parameters.totalDiscriminantCount == null) ?
            (null) :
            (parameters.totalDiscriminantCount));
  }

  get reviewedDiscriminantCount(): number|null {
    return (
        (this.Serializable$has('reviewedDiscriminantCount')) ?
            (this.Serializable$get('reviewedDiscriminantCount')) :
            (null));
  }

  /**
   * Number of reviewed discriminant.
   */
  set reviewedDiscriminantCount(value: number|null) {
    this.Serializable$set('reviewedDiscriminantCount', value);
  }

  get reviewedDiscriminantLastThirtyDays(): number|null {
    return (
        (this.Serializable$has('reviewedDiscriminantLastThirtyDays')) ?
            (this.Serializable$get('reviewedDiscriminantLastThirtyDays')) :
            (null));
  }

  /**
   * Number of discriminant that have the earliest reviewed time within last 30
   * days.
   */
  set reviewedDiscriminantLastThirtyDays(value: number|null) {
    this.Serializable$set('reviewedDiscriminantLastThirtyDays', value);
  }

  get totalDiscriminantCount(): number|null {
    return (
        (this.Serializable$has('totalDiscriminantCount')) ?
            (this.Serializable$get('totalDiscriminantCount')) :
            (null));
  }

  /**
   * Total number of discriminant in the taxonomy space.
   */
  set totalDiscriminantCount(value: number|null) {
    this.Serializable$set('totalDiscriminantCount', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1DisplayTaxonomyProgressResponse> {
    return GoogleCloudDialogflowAamV2beta1DisplayTaxonomyProgressResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'reviewedDiscriminantCount', 'reviewedDiscriminantLastThirtyDays',
        'totalDiscriminantCount'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1DisplayUsersRequestParameters {
  policy?: GoogleIamV1Policy|null;
}
export class GoogleCloudDialogflowAamV2beta1DisplayUsersRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1DisplayUsersRequestParameters = {}) {
    super();
    this.Serializable$set(
        'policy', (parameters.policy == null) ? (null) : (parameters.policy));
  }

  get policy(): GoogleIamV1Policy|null {
    return (
        (this.Serializable$has('policy')) ? (this.Serializable$get('policy')) :
                                            (null));
  }

  /**
   * The complete policy applied to the project in the parent. The size of the
   * policy is limited to a few 10s of KB. This is not filled by the UI console.
   */
  set policy(value: GoogleIamV1Policy|null) {
    this.Serializable$set('policy', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1DisplayUsersRequest> {
    return GoogleCloudDialogflowAamV2beta1DisplayUsersRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['policy'], objects: {'policy': GoogleIamV1Policy}};
  }
}

export interface GoogleCloudDialogflowAamV2beta1DisplayUsersResponseParameters {
  userInfos?: Array<GoogleCloudDialogflowAamV2beta1UserInfo>|null;
}
export class GoogleCloudDialogflowAamV2beta1DisplayUsersResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1DisplayUsersResponseParameters = {}) {
    super();
    this.Serializable$set(
        'userInfos',
        (parameters.userInfos == null) ? (null) : (parameters.userInfos));
  }

  get userInfos(): Array<GoogleCloudDialogflowAamV2beta1UserInfo>|null {
    return (
        (this.Serializable$has('userInfos')) ?
            (this.Serializable$get('userInfos')) :
            (null));
  }

  /**
   * List of User Info needed by the UI
   */
  set userInfos(value: Array<GoogleCloudDialogflowAamV2beta1UserInfo>|null) {
    this.Serializable$set('userInfos', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1DisplayUsersResponse> {
    return GoogleCloudDialogflowAamV2beta1DisplayUsersResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'userInfos': GoogleCloudDialogflowAamV2beta1UserInfo},
      keys: ['userInfos']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ExportAdminToolMetricsCsvReportResponseParameters {
  csvResponseBody?: string|null;
  filename?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1ExportAdminToolMetricsCsvReportResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ExportAdminToolMetricsCsvReportResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'csvResponseBody',
        (parameters.csvResponseBody == null) ? (null) :
                                               (parameters.csvResponseBody));
    this.Serializable$set(
        'filename',
        (parameters.filename == null) ? (null) : (parameters.filename));
  }

  get csvResponseBody(): string|null {
    return (
        (this.Serializable$has('csvResponseBody')) ?
            (this.Serializable$get('csvResponseBody')) :
            (null));
  }

  /**
   * byteString containing the content of the CSV file
   */
  set csvResponseBody(value: string|null) {
    this.Serializable$set('csvResponseBody', value);
  }

  get filename(): string|null {
    return (
        (this.Serializable$has('filename')) ?
            (this.Serializable$get('filename')) :
            (null));
  }

  /**
   * Format: `projects//locations//adminToolMetricsCSV_YYYYMMDDTHHmmZ.csv`
   */
  set filename(value: string|null) {
    this.Serializable$set('filename', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ExportAdminToolMetricsCsvReportResponse> {
    return GoogleCloudDialogflowAamV2beta1ExportAdminToolMetricsCsvReportResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['csvResponseBody', 'filename']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1ExportIntentRequestParameters {
  name?: string|null;
  exportChildren?: boolean|null;
}
export class GoogleCloudDialogflowAamV2beta1ExportIntentRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ExportIntentRequestParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'exportChildren',
        (parameters.exportChildren == null) ? (null) :
                                              (parameters.exportChildren));
  }

  get exportChildren(): boolean|null {
    return (
        (this.Serializable$has('exportChildren')) ?
            (this.Serializable$get('exportChildren')) :
            (null));
  }

  /**
   * Whether to export head intent with its children
   */
  set exportChildren(value: boolean|null) {
    this.Serializable$set('exportChildren', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The selected discriminant name to be exported. Format:
   * `projects//locations/ /aamDiscriminants/ `.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1ExportIntentRequest> {
    return GoogleCloudDialogflowAamV2beta1ExportIntentRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['exportChildren', 'name']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1ExportIntentResponseParameters {
  intentIds?: Array<string>|null;
}
export class GoogleCloudDialogflowAamV2beta1ExportIntentResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ExportIntentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'intentIds',
        (parameters.intentIds == null) ? (null) : (parameters.intentIds));
  }

  get intentIds(): Array<string>|null {
    return (
        (this.Serializable$has('intentIds')) ?
            (this.Serializable$get('intentIds')) :
            (null));
  }

  /**
   * The list of intent ids that have been exported to Dialogflow.
   */
  set intentIds(value: Array<string>|null) {
    this.Serializable$set('intentIds', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1ExportIntentResponse> {
    return GoogleCloudDialogflowAamV2beta1ExportIntentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intentIds']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1ExportSadlCompilationRequestParameters {
  compiledSynopsisSchema?: string|null;
  parent?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1ExportSadlCompilationRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ExportSadlCompilationRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'compiledSynopsisSchema',
        (parameters.compiledSynopsisSchema == null) ?
            (null) :
            (parameters.compiledSynopsisSchema));
    this.Serializable$set(
        'parent', (parameters.parent == null) ? (null) : (parameters.parent));
  }

  get compiledSynopsisSchema(): string|null {
    return (
        (this.Serializable$has('compiledSynopsisSchema')) ?
            (this.Serializable$get('compiledSynopsisSchema')) :
            (null));
  }

  /**
   * The textproto representation of a Synopsis schema message.
   * google3/cloud/ai/dialogflow/proto/model/synopsis/agentschema/schema.proto
   */
  set compiledSynopsisSchema(value: string|null) {
    this.Serializable$set('compiledSynopsisSchema', value);
  }

  get parent(): string|null {
    return (
        (this.Serializable$has('parent')) ? (this.Serializable$get('parent')) :
                                            (null));
  }

  /**
   * Required. The project and location that contains the use case to be
   * exported. Format: `projects//locations/`.
   */
  set parent(value: string|null) {
    this.Serializable$set('parent', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ExportSadlCompilationRequest> {
    return GoogleCloudDialogflowAamV2beta1ExportSadlCompilationRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['compiledSynopsisSchema', 'parent']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1ExportSadlCompilationResponseParameters {
}
export class
    GoogleCloudDialogflowAamV2beta1ExportSadlCompilationResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ExportSadlCompilationResponseParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ExportSadlCompilationResponse> {
    return GoogleCloudDialogflowAamV2beta1ExportSadlCompilationResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowAamV2beta1ExportUseCaseRequestParameters {
  useCase?: GoogleCloudDialogflowAamV2beta1UseCase|null;
  parent?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1ExportUseCaseRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ExportUseCaseRequestParameters = {}) {
    super();
    this.Serializable$set(
        'useCase',
        (parameters.useCase == null) ? (null) : (parameters.useCase));
    this.Serializable$set(
        'parent', (parameters.parent == null) ? (null) : (parameters.parent));
  }

  get parent(): string|null {
    return (
        (this.Serializable$has('parent')) ? (this.Serializable$get('parent')) :
                                            (null));
  }

  /**
   * Required. The project and location that contains the use case to be
   * exported. Format: `projects//locations/`.
   */
  set parent(value: string|null) {
    this.Serializable$set('parent', value);
  }

  get useCase(): GoogleCloudDialogflowAamV2beta1UseCase|null {
    return (
        (this.Serializable$has('useCase')) ?
            (this.Serializable$get('useCase')) :
            (null));
  }

  /**
   * The use case proto to create and export.
   */
  set useCase(value: GoogleCloudDialogflowAamV2beta1UseCase|null) {
    this.Serializable$set('useCase', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1ExportUseCaseRequest> {
    return GoogleCloudDialogflowAamV2beta1ExportUseCaseRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['parent', 'useCase'],
      objects: {'useCase': GoogleCloudDialogflowAamV2beta1UseCase}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ExportUseCaseResponseParameters {
}
export class GoogleCloudDialogflowAamV2beta1ExportUseCaseResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ExportUseCaseResponseParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1ExportUseCaseResponse> {
    return GoogleCloudDialogflowAamV2beta1ExportUseCaseResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowAamV2beta1FetchClusterLabelsResponseParameters {
  labels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
  nextPageToken?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1FetchClusterLabelsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1FetchClusterLabelsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get labels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * List of utterances belong to the clusters. Max 500 utterances.
   */
  set labels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null) {
    this.Serializable$set('labels', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * The next_page_token value returned from a previous list request, or empty
   * if there are no more results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1FetchClusterLabelsResponse> {
    return GoogleCloudDialogflowAamV2beta1FetchClusterLabelsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'labels': GoogleCloudDialogflowAamV2beta1AamLabel},
      keys: ['labels', 'nextPageToken']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1FetchTopicClusterDetailsResponseParameters {
  clusterUtterances?: Array<GoogleCloudDialogflowAamV2beta1AamClusterUtterance>|
      null;
  nextPageToken?: string|null;
  topicCluster?: GoogleCloudDialogflowAamV2beta1AamTopicCluster|null;
}
export class
    GoogleCloudDialogflowAamV2beta1FetchTopicClusterDetailsResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1FetchTopicClusterDetailsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'clusterUtterances',
        (parameters.clusterUtterances == null) ?
            (null) :
            (parameters.clusterUtterances));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
    this.Serializable$set(
        'topicCluster',
        (parameters.topicCluster == null) ? (null) : (parameters.topicCluster));
  }

  get clusterUtterances():
      Array<GoogleCloudDialogflowAamV2beta1AamClusterUtterance>|null {
    return (
        (this.Serializable$has('clusterUtterances')) ?
            (this.Serializable$get('clusterUtterances')) :
            (null));
  }

  /**
   * List of utterances belong to the clusters.
   */
  set clusterUtterances(
      value: Array<GoogleCloudDialogflowAamV2beta1AamClusterUtterance>|null) {
    this.Serializable$set('clusterUtterances', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * The next_page_token value returned from a previous list request, or empty
   * if there are no more result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get topicCluster(): GoogleCloudDialogflowAamV2beta1AamTopicCluster|null {
    return (
        (this.Serializable$has('topicCluster')) ?
            (this.Serializable$get('topicCluster')) :
            (null));
  }

  /**
   * The topic cluster for which cluster details were retrieved for.
   */
  set topicCluster(value: GoogleCloudDialogflowAamV2beta1AamTopicCluster|null) {
    this.Serializable$set('topicCluster', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1FetchTopicClusterDetailsResponse> {
    return GoogleCloudDialogflowAamV2beta1FetchTopicClusterDetailsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'clusterUtterances': GoogleCloudDialogflowAamV2beta1AamClusterUtterance
      },
      keys: ['clusterUtterances', 'nextPageToken', 'topicCluster'],
      objects: {'topicCluster': GoogleCloudDialogflowAamV2beta1AamTopicCluster}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1FetchUserRequestParameters {
  email?: string|null;
  policy?: GoogleIamV1Policy|null;
}
export class GoogleCloudDialogflowAamV2beta1FetchUserRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1FetchUserRequestParameters = {}) {
    super();
    this.Serializable$set(
        'email', (parameters.email == null) ? (null) : (parameters.email));
    this.Serializable$set(
        'policy', (parameters.policy == null) ? (null) : (parameters.policy));
  }

  get email(): string|null {
    return (
        (this.Serializable$has('email')) ? (this.Serializable$get('email')) :
                                           (null));
  }

  /**
   * The email of user
   */
  set email(value: string|null) {
    this.Serializable$set('email', value);
  }

  get policy(): GoogleIamV1Policy|null {
    return (
        (this.Serializable$has('policy')) ? (this.Serializable$get('policy')) :
                                            (null));
  }

  /**
   * The complete policy applied to the project in the parent. The size of the
   * policy is limited to a few 10s of KB. This is not filled by the UI console.
   */
  set policy(value: GoogleIamV1Policy|null) {
    this.Serializable$set('policy', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1FetchUserRequest> {
    return GoogleCloudDialogflowAamV2beta1FetchUserRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['email', 'policy'], objects: {'policy': GoogleIamV1Policy}};
  }
}

export interface GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskRequestParameters {
  discriminant?: string|null;
  labellerId?: string|null;
  labelerGaiaId?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
    this.Serializable$set(
        'labellerId',
        (parameters.labellerId == null) ? (null) : (parameters.labellerId));
    this.Serializable$set(
        'labelerGaiaId',
        (parameters.labelerGaiaId == null) ? (null) :
                                             (parameters.labelerGaiaId));
  }

  get discriminant(): string|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * Required. The discriminant resource name for tasks generation. It needs to
   * be of type intent.
   */
  set discriminant(value: string|null) {
    this.Serializable$set('discriminant', value);
  }

  get labelerGaiaId(): string|null {
    return (
        (this.Serializable$has('labelerGaiaId')) ?
            (this.Serializable$get('labelerGaiaId')) :
            (null));
  }

  /**
   * Optional. The labeler's GAIA ID, this is filled by Dialogflow frontend not
   * by the UI
   */
  set labelerGaiaId(value: string|null) {
    this.Serializable$set('labelerGaiaId', value);
  }

  get labellerId(): string|null {
    return (
        (this.Serializable$has('labellerId')) ?
            (this.Serializable$get('labellerId')) :
            (null));
  }

  /**
   * Optional. Indicates who is labelling this task. This should be popuplated
   * by FE C++ server. This field should be ignored by Console typescript code.
   */
  set labellerId(value: string|null) {
    this.Serializable$set('labellerId', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskRequest> {
    return GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['discriminant', 'labelerGaiaId', 'labellerId']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskResponseParameters {
  flowTask?: GoogleCloudDialogflowAamV2beta1AamTask|null;
  flowTranscript?: GoogleCloudDialogflowAamV2beta1AamTranscript|null;
  existingLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
  labelSuggestions?: Array<GoogleCloudDialogflowAamV2beta1AamLabelSuggestion>|
      null;
  completedTaskCount?: number|null;
  estimatedTaskCount?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'flowTask',
        (parameters.flowTask == null) ? (null) : (parameters.flowTask));
    this.Serializable$set(
        'flowTranscript',
        (parameters.flowTranscript == null) ? (null) :
                                              (parameters.flowTranscript));
    this.Serializable$set(
        'existingLabels',
        (parameters.existingLabels == null) ? (null) :
                                              (parameters.existingLabels));
    this.Serializable$set(
        'labelSuggestions',
        (parameters.labelSuggestions == null) ? (null) :
                                                (parameters.labelSuggestions));
    this.Serializable$set(
        'completedTaskCount',
        (parameters.completedTaskCount == null) ?
            (null) :
            (parameters.completedTaskCount));
    this.Serializable$set(
        'estimatedTaskCount',
        (parameters.estimatedTaskCount == null) ?
            (null) :
            (parameters.estimatedTaskCount));
  }

  get completedTaskCount(): number|null {
    return (
        (this.Serializable$has('completedTaskCount')) ?
            (this.Serializable$get('completedTaskCount')) :
            (null));
  }

  /**
   * The number of already completed flow task for the given intent.
   */
  set completedTaskCount(value: number|null) {
    this.Serializable$set('completedTaskCount', value);
  }

  get estimatedTaskCount(): string|null {
    return (
        (this.Serializable$has('estimatedTaskCount')) ?
            (this.Serializable$get('estimatedTaskCount')) :
            (null));
  }

  /**
   * The number of estimated transcripts that has the given head intent.
   */
  set estimatedTaskCount(value: string|null) {
    this.Serializable$set('estimatedTaskCount', value);
  }

  get existingLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('existingLabels')) ?
            (this.Serializable$get('existingLabels')) :
            (null));
  }

  /**
   * The list of AamLabel resources that belong to the sampled_transcript.
   */
  set existingLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|
                     null) {
    this.Serializable$set('existingLabels', value);
  }

  get flowTask(): GoogleCloudDialogflowAamV2beta1AamTask|null {
    return (
        (this.Serializable$has('flowTask')) ?
            (this.Serializable$get('flowTask')) :
            (null));
  }

  /**
   * The created AamTask resource.
   */
  set flowTask(value: GoogleCloudDialogflowAamV2beta1AamTask|null) {
    this.Serializable$set('flowTask', value);
  }

  get flowTranscript(): GoogleCloudDialogflowAamV2beta1AamTranscript|null {
    return (
        (this.Serializable$has('flowTranscript')) ?
            (this.Serializable$get('flowTranscript')) :
            (null));
  }

  /**
   * The AamTranscript resource that's fetched and attached to sampled_task.
   */
  set flowTranscript(value: GoogleCloudDialogflowAamV2beta1AamTranscript|null) {
    this.Serializable$set('flowTranscript', value);
  }

  get labelSuggestions():
      Array<GoogleCloudDialogflowAamV2beta1AamLabelSuggestion>|null {
    return (
        (this.Serializable$has('labelSuggestions')) ?
            (this.Serializable$get('labelSuggestions')) :
            (null));
  }

  /**
   * The list of AamLabelSuggestion resources.
   */
  set labelSuggestions(
      value: Array<GoogleCloudDialogflowAamV2beta1AamLabelSuggestion>|null) {
    this.Serializable$set('labelSuggestions', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskResponse> {
    return GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'existingLabels': GoogleCloudDialogflowAamV2beta1AamLabel,
        'labelSuggestions': GoogleCloudDialogflowAamV2beta1AamLabelSuggestion
      },
      keys: [
        'completedTaskCount', 'estimatedTaskCount', 'existingLabels',
        'flowTask', 'flowTranscript', 'labelSuggestions'
      ],
      objects: {
        'flowTask': GoogleCloudDialogflowAamV2beta1AamTask,
        'flowTranscript': GoogleCloudDialogflowAamV2beta1AamTranscript
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskRequestParameters {
  notice?: string|null;
  labelerGaiaId?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'notice', (parameters.notice == null) ? (null) : (parameters.notice));
    this.Serializable$set(
        'labelerGaiaId',
        (parameters.labelerGaiaId == null) ? (null) :
                                             (parameters.labelerGaiaId));
  }

  get labelerGaiaId(): string|null {
    return (
        (this.Serializable$has('labelerGaiaId')) ?
            (this.Serializable$get('labelerGaiaId')) :
            (null));
  }

  /**
   * The labeler's GAIA ID, this is filled by Dialogflow frontend not by the UI
   */
  set labelerGaiaId(value: string|null) {
    this.Serializable$set('labelerGaiaId', value);
  }

  get notice(): string|null {
    return (
        (this.Serializable$has('notice')) ? (this.Serializable$get('notice')) :
                                            (null));
  }

  /**
   * The notice aam_resource this review task is generated for.
   */
  set notice(value: string|null) {
    this.Serializable$set('notice', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskRequest> {
    return GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['labelerGaiaId', 'notice']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskResponseParameters {
  reviewTask?: GoogleCloudDialogflowAamV2beta1AamTask|null;
  reviewTranscript?: GoogleCloudDialogflowAamV2beta1AamTranscript|null;
  remainingTasks?: number|null;
  compositeLabels?: Array<GoogleCloudDialogflowAamV2beta1CompositeAamLabel>|
      null;
}
export class
    GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'reviewTask',
        (parameters.reviewTask == null) ? (null) : (parameters.reviewTask));
    this.Serializable$set(
        'reviewTranscript',
        (parameters.reviewTranscript == null) ? (null) :
                                                (parameters.reviewTranscript));
    this.Serializable$set(
        'remainingTasks',
        (parameters.remainingTasks == null) ? (null) :
                                              (parameters.remainingTasks));
    this.Serializable$set(
        'compositeLabels',
        (parameters.compositeLabels == null) ? (null) :
                                               (parameters.compositeLabels));
  }

  get compositeLabels():
      Array<GoogleCloudDialogflowAamV2beta1CompositeAamLabel>|null {
    return (
        (this.Serializable$has('compositeLabels')) ?
            (this.Serializable$get('compositeLabels')) :
            (null));
  }

  /**
   * The list of composite AamLabel.
   */
  set compositeLabels(
      value: Array<GoogleCloudDialogflowAamV2beta1CompositeAamLabel>|null) {
    this.Serializable$set('compositeLabels', value);
  }

  get remainingTasks(): number|null {
    return (
        (this.Serializable$has('remainingTasks')) ?
            (this.Serializable$get('remainingTasks')) :
            (null));
  }

  /**
   * The number of remaining unfinished tasks (including the one in this
   * response) for this notice.
   */
  set remainingTasks(value: number|null) {
    this.Serializable$set('remainingTasks', value);
  }

  get reviewTask(): GoogleCloudDialogflowAamV2beta1AamTask|null {
    return (
        (this.Serializable$has('reviewTask')) ?
            (this.Serializable$get('reviewTask')) :
            (null));
  }

  /**
   * The created AamTask resource.
   */
  set reviewTask(value: GoogleCloudDialogflowAamV2beta1AamTask|null) {
    this.Serializable$set('reviewTask', value);
  }

  get reviewTranscript(): GoogleCloudDialogflowAamV2beta1AamTranscript|null {
    return (
        (this.Serializable$has('reviewTranscript')) ?
            (this.Serializable$get('reviewTranscript')) :
            (null));
  }

  /**
   * The AamTranscript resource that's fetched and attached to sampled_task.
   */
  set reviewTranscript(value: GoogleCloudDialogflowAamV2beta1AamTranscript|
                       null) {
    this.Serializable$set('reviewTranscript', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskResponse> {
    return GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'compositeLabels': GoogleCloudDialogflowAamV2beta1CompositeAamLabel},
      keys: [
        'compositeLabels', 'remainingTasks', 'reviewTask', 'reviewTranscript'
      ],
      objects: {
        'reviewTask': GoogleCloudDialogflowAamV2beta1AamTask,
        'reviewTranscript': GoogleCloudDialogflowAamV2beta1AamTranscript
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestParameters {
  type?: GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType|null;
  discriminantId?: string|null;
  labellerId?: string|null;
  labelerGaiaId?: string|null;
  dataset?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'discriminantId',
        (parameters.discriminantId == null) ? (null) :
                                              (parameters.discriminantId));
    this.Serializable$set(
        'labellerId',
        (parameters.labellerId == null) ? (null) : (parameters.labellerId));
    this.Serializable$set(
        'labelerGaiaId',
        (parameters.labelerGaiaId == null) ? (null) :
                                             (parameters.labelerGaiaId));
    this.Serializable$set(
        'dataset',
        (parameters.dataset == null) ? (null) : (parameters.dataset));
  }

  static get Type():
      IGoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum {
    return GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum;
  }

  get dataset(): string|null {
    return (
        (this.Serializable$has('dataset')) ?
            (this.Serializable$get('dataset')) :
            (null));
  }

  /**
   * Optional. The unique identifier of the aam_dataset for which the tasks
   * needs to be generated. Format: `projects//locations//aamDatasets/ `. the
   * field has been added as optional so that it is backward compatible.
   */
  set dataset(value: string|null) {
    this.Serializable$set('dataset', value);
  }

  get discriminantId(): string|null {
    return (
        (this.Serializable$has('discriminantId')) ?
            (this.Serializable$get('discriminantId')) :
            (null));
  }

  /**
   * Optional. The optional discriminant ID to generate a task for.
   */
  set discriminantId(value: string|null) {
    this.Serializable$set('discriminantId', value);
  }

  get labelerGaiaId(): string|null {
    return (
        (this.Serializable$has('labelerGaiaId')) ?
            (this.Serializable$get('labelerGaiaId')) :
            (null));
  }

  /**
   * Optional. The labeler's GAIA ID, this is filled by Dialogflow frontend not
   * by the UI
   */
  set labelerGaiaId(value: string|null) {
    this.Serializable$set('labelerGaiaId', value);
  }

  get labellerId(): string|null {
    return (
        (this.Serializable$has('labellerId')) ?
            (this.Serializable$get('labellerId')) :
            (null));
  }

  /**
   * Optional. Indicates who is labelling this task. This should be popuplated
   * by FE C++ server. This field should be ignored by Console typescript code.
   */
  set labellerId(value: string|null) {
    this.Serializable$set('labellerId', value);
  }

  get type(): GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Required. The required discriminant type to generate an aam_task for.
   */
  set type(value: GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestType|
           null) {
    this.Serializable$set('type', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequest> {
    return GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'type': GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequestTypeEnum
      },
      keys: ['dataset', 'discriminantId', 'labelerGaiaId', 'labellerId', 'type']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1GenerateAamTaskResponseParameters {
  sampledTask?: GoogleCloudDialogflowAamV2beta1AamTask|null;
  sampledTranscript?: GoogleCloudDialogflowAamV2beta1AamTranscript|null;
  existingLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
  labelSuggestions?: Array<GoogleCloudDialogflowAamV2beta1AamLabelSuggestion>|
      null;
}
export class GoogleCloudDialogflowAamV2beta1GenerateAamTaskResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1GenerateAamTaskResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'sampledTask',
        (parameters.sampledTask == null) ? (null) : (parameters.sampledTask));
    this.Serializable$set(
        'sampledTranscript',
        (parameters.sampledTranscript == null) ?
            (null) :
            (parameters.sampledTranscript));
    this.Serializable$set(
        'existingLabels',
        (parameters.existingLabels == null) ? (null) :
                                              (parameters.existingLabels));
    this.Serializable$set(
        'labelSuggestions',
        (parameters.labelSuggestions == null) ? (null) :
                                                (parameters.labelSuggestions));
  }

  get existingLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('existingLabels')) ?
            (this.Serializable$get('existingLabels')) :
            (null));
  }

  /**
   * The list of AamLabel resources that belong to the sampled_transcript.
   */
  set existingLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|
                     null) {
    this.Serializable$set('existingLabels', value);
  }

  get labelSuggestions():
      Array<GoogleCloudDialogflowAamV2beta1AamLabelSuggestion>|null {
    return (
        (this.Serializable$has('labelSuggestions')) ?
            (this.Serializable$get('labelSuggestions')) :
            (null));
  }

  /**
   * The list of AamLabelSuggestion resources.
   */
  set labelSuggestions(
      value: Array<GoogleCloudDialogflowAamV2beta1AamLabelSuggestion>|null) {
    this.Serializable$set('labelSuggestions', value);
  }

  get sampledTask(): GoogleCloudDialogflowAamV2beta1AamTask|null {
    return (
        (this.Serializable$has('sampledTask')) ?
            (this.Serializable$get('sampledTask')) :
            (null));
  }

  /**
   * The created AamTask resource.
   */
  set sampledTask(value: GoogleCloudDialogflowAamV2beta1AamTask|null) {
    this.Serializable$set('sampledTask', value);
  }

  get sampledTranscript(): GoogleCloudDialogflowAamV2beta1AamTranscript|null {
    return (
        (this.Serializable$has('sampledTranscript')) ?
            (this.Serializable$get('sampledTranscript')) :
            (null));
  }

  /**
   * The AamTranscript resource that's fetched and attached to sampled_task.
   */
  set sampledTranscript(value: GoogleCloudDialogflowAamV2beta1AamTranscript|
                        null) {
    this.Serializable$set('sampledTranscript', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1GenerateAamTaskResponse> {
    return GoogleCloudDialogflowAamV2beta1GenerateAamTaskResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'existingLabels': GoogleCloudDialogflowAamV2beta1AamLabel,
        'labelSuggestions': GoogleCloudDialogflowAamV2beta1AamLabelSuggestion
      },
      keys: [
        'existingLabels', 'labelSuggestions', 'sampledTask', 'sampledTranscript'
      ],
      objects: {
        'sampledTask': GoogleCloudDialogflowAamV2beta1AamTask,
        'sampledTranscript': GoogleCloudDialogflowAamV2beta1AamTranscript
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskRequestParameters {
  transcript?: string|null;
  labellerId?: string|null;
  labelerGaiaId?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskRequest extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'transcript',
        (parameters.transcript == null) ? (null) : (parameters.transcript));
    this.Serializable$set(
        'labellerId',
        (parameters.labellerId == null) ? (null) : (parameters.labellerId));
    this.Serializable$set(
        'labelerGaiaId',
        (parameters.labelerGaiaId == null) ? (null) :
                                             (parameters.labelerGaiaId));
  }

  get labelerGaiaId(): string|null {
    return (
        (this.Serializable$has('labelerGaiaId')) ?
            (this.Serializable$get('labelerGaiaId')) :
            (null));
  }

  /**
   * Optional. The labeler's GAIA ID, this is filled by Dialogflow frontend not
   * by the UI
   */
  set labelerGaiaId(value: string|null) {
    this.Serializable$set('labelerGaiaId', value);
  }

  get labellerId(): string|null {
    return (
        (this.Serializable$has('labellerId')) ?
            (this.Serializable$get('labellerId')) :
            (null));
  }

  /**
   * Optional. Indicates who is labelling this task. This should be popuplated
   * by FE C++ server. This field should be ignored by Console typescript code.
   */
  set labellerId(value: string|null) {
    this.Serializable$set('labellerId', value);
  }

  get transcript(): string|null {
    return (
        (this.Serializable$has('transcript')) ?
            (this.Serializable$get('transcript')) :
            (null));
  }

  /**
   * Required. The name of the of the transcript that is being labeled.
   */
  set transcript(value: string|null) {
    this.Serializable$set('transcript', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskRequest> {
    return GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['labelerGaiaId', 'labellerId', 'transcript']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskResponseParameters {
  transcriptTask?: GoogleCloudDialogflowAamV2beta1AamTask|null;
  transcript?: GoogleCloudDialogflowAamV2beta1AamTranscript|null;
  compositeLabels?: Array<GoogleCloudDialogflowAamV2beta1CompositeAamLabel>|
      null;
}
export class
    GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'transcriptTask',
        (parameters.transcriptTask == null) ? (null) :
                                              (parameters.transcriptTask));
    this.Serializable$set(
        'transcript',
        (parameters.transcript == null) ? (null) : (parameters.transcript));
    this.Serializable$set(
        'compositeLabels',
        (parameters.compositeLabels == null) ? (null) :
                                               (parameters.compositeLabels));
  }

  get compositeLabels():
      Array<GoogleCloudDialogflowAamV2beta1CompositeAamLabel>|null {
    return (
        (this.Serializable$has('compositeLabels')) ?
            (this.Serializable$get('compositeLabels')) :
            (null));
  }

  /**
   * The list of composite AamLabel that belong to the transcript.
   */
  set compositeLabels(
      value: Array<GoogleCloudDialogflowAamV2beta1CompositeAamLabel>|null) {
    this.Serializable$set('compositeLabels', value);
  }

  get transcript(): GoogleCloudDialogflowAamV2beta1AamTranscript|null {
    return (
        (this.Serializable$has('transcript')) ?
            (this.Serializable$get('transcript')) :
            (null));
  }

  /**
   * The AamTranscript resource that's fetched and attached to sampled_task.
   */
  set transcript(value: GoogleCloudDialogflowAamV2beta1AamTranscript|null) {
    this.Serializable$set('transcript', value);
  }

  get transcriptTask(): GoogleCloudDialogflowAamV2beta1AamTask|null {
    return (
        (this.Serializable$has('transcriptTask')) ?
            (this.Serializable$get('transcriptTask')) :
            (null));
  }

  /**
   * The created AamTask resource.
   */
  set transcriptTask(value: GoogleCloudDialogflowAamV2beta1AamTask|null) {
    this.Serializable$set('transcriptTask', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskResponse> {
    return GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'compositeLabels': GoogleCloudDialogflowAamV2beta1CompositeAamLabel},
      keys: ['compositeLabels', 'transcript', 'transcriptTask'],
      objects: {
        'transcript': GoogleCloudDialogflowAamV2beta1AamTranscript,
        'transcriptTask': GoogleCloudDialogflowAamV2beta1AamTask
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionRequestParameters {
  intents?: Array<string>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionRequest extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
  }

  get intents(): Array<string>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * List of intent ids. Use flow labels from transcripts with these intents to
   * generate the agent definition for the intents.
   */
  set intents(value: Array<string>|null) {
    this.Serializable$set('intents', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionRequest> {
    return GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intents']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionResponseParameters {
  actions?: Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction>|
      null;
  parameters?:
      Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionParameter>|null;
  intents?: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaIntent>|null;
  policies?: Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionPolicy>|
      null;
  utterances?:
      Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionUtterance>|null;
  webhooks?: Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionWebhook>|
      null;
  parameterTypes?:
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'actions',
        (parameters.actions == null) ? (null) : (parameters.actions));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
    this.Serializable$set(
        'policies',
        (parameters.policies == null) ? (null) : (parameters.policies));
    this.Serializable$set(
        'utterances',
        (parameters.utterances == null) ? (null) : (parameters.utterances));
    this.Serializable$set(
        'webhooks',
        (parameters.webhooks == null) ? (null) : (parameters.webhooks));
    this.Serializable$set(
        'parameterTypes',
        (parameters.parameterTypes == null) ? (null) :
                                              (parameters.parameterTypes));
  }

  get actions():
      Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction>|null {
    return (
        (this.Serializable$has('actions')) ?
            (this.Serializable$get('actions')) :
            (null));
  }

  /**
   * SADL actions generated from labels.
   */
  set actions(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction>|
      null) {
    this.Serializable$set('actions', value);
  }

  get intents():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaIntent>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * Synopsis intents generated from labels.
   */
  set intents(value:
                  Array<CloudAiDialogflowProtoModelSynopsisAgentschemaIntent>|
              null) {
    this.Serializable$set('intents', value);
  }

  get parameterTypes():
      Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType>|null {
    return (
        (this.Serializable$has('parameterTypes')) ?
            (this.Serializable$get('parameterTypes')) :
            (null));
  }

  /**
   * SADL Parameter Types generated from parameters.
   */
  set parameterTypes(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType>|
      null) {
    this.Serializable$set('parameterTypes', value);
  }

  get parameters():
      Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * SADL parameters generated from labels.
   */
  set parameters(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionParameter>|
      null) {
    this.Serializable$set('parameters', value);
  }

  get policies():
      Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionPolicy>|null {
    return (
        (this.Serializable$has('policies')) ?
            (this.Serializable$get('policies')) :
            (null));
  }

  /**
   * SADL policies generated from labels.
   */
  set policies(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionPolicy>|
      null) {
    this.Serializable$set('policies', value);
  }

  get utterances():
      Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionUtterance>|null {
    return (
        (this.Serializable$has('utterances')) ?
            (this.Serializable$get('utterances')) :
            (null));
  }

  /**
   * SADL utterances generated from labels.
   */
  set utterances(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionUtterance>|
      null) {
    this.Serializable$set('utterances', value);
  }

  get webhooks():
      Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionWebhook>|null {
    return (
        (this.Serializable$has('webhooks')) ?
            (this.Serializable$get('webhooks')) :
            (null));
  }

  /**
   * SADL webhooks generated from labels.
   */
  set webhooks(
      value: Array<CloudAiDialogflowProtoModelSynopsisAgentdefinitionWebhook>|
      null) {
    this.Serializable$set('webhooks', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionResponse> {
    return GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'actions': CloudAiDialogflowProtoModelSynopsisAgentdefinitionAction,
        'intents': CloudAiDialogflowProtoModelSynopsisAgentschemaIntent,
        'parameterTypes':
            CloudAiDialogflowProtoModelSynopsisAgentschemaParameterType,
        'parameters':
            CloudAiDialogflowProtoModelSynopsisAgentdefinitionParameter,
        'policies': CloudAiDialogflowProtoModelSynopsisAgentdefinitionPolicy,
        'utterances':
            CloudAiDialogflowProtoModelSynopsisAgentdefinitionUtterance,
        'webhooks': CloudAiDialogflowProtoModelSynopsisAgentdefinitionWebhook
      },
      keys: [
        'actions', 'intents', 'parameterTypes', 'parameters', 'policies',
        'utterances', 'webhooks'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestParameters {
  userGaiaId?: string|null;
  driverFilter?: Array<string>|null;
  typeFilter?:
      GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter|
      null;
}
export class
    GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequest extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'userGaiaId',
        (parameters.userGaiaId == null) ? (null) : (parameters.userGaiaId));
    this.Serializable$set(
        'driverFilter',
        (parameters.driverFilter == null) ? (null) : (parameters.driverFilter));
    this.Serializable$set(
        'typeFilter',
        (parameters.typeFilter == null) ? (null) : (parameters.typeFilter));
  }

  static get TypeFilter():
      IGoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilterEnum {
    return GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilterEnum;
  }

  get driverFilter(): Array<string>|null {
    return (
        (this.Serializable$has('driverFilter')) ?
            (this.Serializable$get('driverFilter')) :
            (null));
  }

  /**
   * Optional. If specified, then import only drivers with the given name and
   * intents with those drivers as parent.
   */
  set driverFilter(value: Array<string>|null) {
    this.Serializable$set('driverFilter', value);
  }

  get typeFilter():
      GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter
      |null {
    return (
        (this.Serializable$has('typeFilter')) ?
            (this.Serializable$get('typeFilter')) :
            (null));
  }

  /**
   * Required. Type of the vertical to import.
   */
  set typeFilter(
      value:
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilter|
      null) {
    this.Serializable$set('typeFilter', value);
  }

  get userGaiaId(): string|null {
    return (
        (this.Serializable$has('userGaiaId')) ?
            (this.Serializable$get('userGaiaId')) :
            (null));
  }

  /**
   * GAIA ID of the user who made this request. This is filled by Dialogflow
   * frontend not by the UI.
   */
  set userGaiaId(value: string|null) {
    this.Serializable$set('userGaiaId', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequest> {
    return GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'typeFilter':
            GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequestTypeFilterEnum
      },
      keys: ['driverFilter', 'typeFilter', 'userGaiaId']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyResponseParameters {
  discriminants?: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminants',
        (parameters.discriminants == null) ? (null) :
                                             (parameters.discriminants));
  }

  get discriminants():
      Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null {
    return (
        (this.Serializable$has('discriminants')) ?
            (this.Serializable$get('discriminants')) :
            (null));
  }

  /**
   * Discriminants created.
   */
  set discriminants(value:
                        Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|
                    null) {
    this.Serializable$set('discriminants', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyResponse> {
    return GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'discriminants': GoogleCloudDialogflowAamV2beta1AamDiscriminant},
      keys: ['discriminants']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1LabelSpanParameters {
  start?: number|null;
  end?: number|null;
}
export class GoogleCloudDialogflowAamV2beta1LabelSpan extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1LabelSpanParameters = {}) {
    super();
    this.Serializable$set(
        'start', (parameters.start == null) ? (null) : (parameters.start));
    this.Serializable$set(
        'end', (parameters.end == null) ? (null) : (parameters.end));
  }

  get end(): number|null {
    return (
        (this.Serializable$has('end')) ? (this.Serializable$get('end')) :
                                         (null));
  }

  /**
   * represents the end index of the label span on utterance (exclusive).
   */
  set end(value: number|null) {
    this.Serializable$set('end', value);
  }

  get start(): number|null {
    return (
        (this.Serializable$has('start')) ? (this.Serializable$get('start')) :
                                           (null));
  }

  /**
   * represents the start index of the label span on utterance.
   */
  set start(value: number|null) {
    this.Serializable$set('start', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowAamV2beta1LabelSpan> {
    return GoogleCloudDialogflowAamV2beta1LabelSpan;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['end', 'start']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1LinkParameters {
  sourceNodeId?: string|null;
  targetNodeId?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1Link extends Serializable {
  constructor(parameters: GoogleCloudDialogflowAamV2beta1LinkParameters = {}) {
    super();
    this.Serializable$set(
        'sourceNodeId',
        (parameters.sourceNodeId == null) ? (null) : (parameters.sourceNodeId));
    this.Serializable$set(
        'targetNodeId',
        (parameters.targetNodeId == null) ? (null) : (parameters.targetNodeId));
  }

  get sourceNodeId(): string|null {
    return (
        (this.Serializable$has('sourceNodeId')) ?
            (this.Serializable$get('sourceNodeId')) :
            (null));
  }

  /**
   * Required. Id of the node the link originates from.
   */
  set sourceNodeId(value: string|null) {
    this.Serializable$set('sourceNodeId', value);
  }

  get targetNodeId(): string|null {
    return (
        (this.Serializable$has('targetNodeId')) ?
            (this.Serializable$get('targetNodeId')) :
            (null));
  }

  /**
   * Required. Id of the node the link points to.
   */
  set targetNodeId(value: string|null) {
    this.Serializable$set('targetNodeId', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowAamV2beta1Link> {
    return GoogleCloudDialogflowAamV2beta1Link;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['sourceNodeId', 'targetNodeId']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAamDatasetsResponseParameters {
  aamDatasets?: Array<GoogleCloudDialogflowAamV2beta1AamDataset>|null;
  nextPageToken?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1ListAamDatasetsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAamDatasetsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamDatasets',
        (parameters.aamDatasets == null) ? (null) : (parameters.aamDatasets));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get aamDatasets(): Array<GoogleCloudDialogflowAamV2beta1AamDataset>|null {
    return (
        (this.Serializable$has('aamDatasets')) ?
            (this.Serializable$get('aamDatasets')) :
            (null));
  }

  /**
   * The list of flows. There will be a maximum number of items returned based
   * on the page_size field in the request.
   */
  set aamDatasets(value: Array<GoogleCloudDialogflowAamV2beta1AamDataset>|
                  null) {
    this.Serializable$set('aamDatasets', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1ListAamDatasetsResponse> {
    return GoogleCloudDialogflowAamV2beta1ListAamDatasetsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'aamDatasets': GoogleCloudDialogflowAamV2beta1AamDataset},
      keys: ['aamDatasets', 'nextPageToken']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAamDiscriminantChangesResponseParameters {
  aamDiscriminantChanges?:
      Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantChange>|null;
  nextPageToken?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1ListAamDiscriminantChangesResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAamDiscriminantChangesResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamDiscriminantChanges',
        (parameters.aamDiscriminantChanges == null) ?
            (null) :
            (parameters.aamDiscriminantChanges));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get aamDiscriminantChanges():
      Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantChange>|null {
    return (
        (this.Serializable$has('aamDiscriminantChanges')) ?
            (this.Serializable$get('aamDiscriminantChanges')) :
            (null));
  }

  /**
   * The list of changes, ordered by time descending. There will be a maximum
   * number of items returned based on the page_size field in the request.
   */
  set aamDiscriminantChanges(
      value: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminantChange>|null) {
    this.Serializable$set('aamDiscriminantChanges', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListAamDiscriminantChangesResponse> {
    return GoogleCloudDialogflowAamV2beta1ListAamDiscriminantChangesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'aamDiscriminantChanges':
            GoogleCloudDialogflowAamV2beta1AamDiscriminantChange
      },
      keys: ['aamDiscriminantChanges', 'nextPageToken']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAamDiscriminantsResponseParameters {
  aamDiscriminants?: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null;
  nextPageToken?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1ListAamDiscriminantsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAamDiscriminantsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamDiscriminants',
        (parameters.aamDiscriminants == null) ? (null) :
                                                (parameters.aamDiscriminants));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get aamDiscriminants():
      Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null {
    return (
        (this.Serializable$has('aamDiscriminants')) ?
            (this.Serializable$get('aamDiscriminants')) :
            (null));
  }

  /**
   * The list of flows. There will be a maximum number of items returned based
   * on the page_size field in the request.
   */
  set aamDiscriminants(
      value: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null) {
    this.Serializable$set('aamDiscriminants', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListAamDiscriminantsResponse> {
    return GoogleCloudDialogflowAamV2beta1ListAamDiscriminantsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'aamDiscriminants': GoogleCloudDialogflowAamV2beta1AamDiscriminant},
      keys: ['aamDiscriminants', 'nextPageToken']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAamLabelsResponseParameters {
  aamLabelUtterancePairs?:
      Array<GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair>|null;
  compositeAamLabels?: Array<GoogleCloudDialogflowAamV2beta1CompositeAamLabel>|
      null;
}
export class GoogleCloudDialogflowAamV2beta1ListAamLabelsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAamLabelsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'aamLabelUtterancePairs',
        (parameters.aamLabelUtterancePairs == null) ?
            (null) :
            (parameters.aamLabelUtterancePairs));
    this.Serializable$set(
        'compositeAamLabels',
        (parameters.compositeAamLabels == null) ?
            (null) :
            (parameters.compositeAamLabels));
  }

  get aamLabelUtterancePairs():
      Array<GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair>|null {
    return (
        (this.Serializable$has('aamLabelUtterancePairs')) ?
            (this.Serializable$get('aamLabelUtterancePairs')) :
            (null));
  }

  /**
   * The list of flows. There will be a maximum number of items returned based
   * on the page_size field in the request. Deprecated. Use composite_aam_label.
   */
  set aamLabelUtterancePairs(
      value: Array<GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair>|null) {
    this.Serializable$set('aamLabelUtterancePairs', value);
  }

  get compositeAamLabels():
      Array<GoogleCloudDialogflowAamV2beta1CompositeAamLabel>|null {
    return (
        (this.Serializable$has('compositeAamLabels')) ?
            (this.Serializable$get('compositeAamLabels')) :
            (null));
  }

  /**
   * The list of labels with their metadata. The metadata are there to help
   * building labels in the UI.
   */
  set compositeAamLabels(
      value: Array<GoogleCloudDialogflowAamV2beta1CompositeAamLabel>|null) {
    this.Serializable$set('compositeAamLabels', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1ListAamLabelsResponse> {
    return GoogleCloudDialogflowAamV2beta1ListAamLabelsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'aamLabelUtterancePairs':
            GoogleCloudDialogflowAamV2beta1AamLabelUtterancePair,
        'compositeAamLabels': GoogleCloudDialogflowAamV2beta1CompositeAamLabel
      },
      keys: ['aamLabelUtterancePairs', 'compositeAamLabels']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAamNoticesResponseParameters {
  aamNotices?: Array<GoogleCloudDialogflowAamV2beta1AamNotice>|null;
  nextPageToken?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1ListAamNoticesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAamNoticesResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamNotices',
        (parameters.aamNotices == null) ? (null) : (parameters.aamNotices));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get aamNotices(): Array<GoogleCloudDialogflowAamV2beta1AamNotice>|null {
    return (
        (this.Serializable$has('aamNotices')) ?
            (this.Serializable$get('aamNotices')) :
            (null));
  }

  /**
   * The list of notices. There will be a maximum number of items returned based
   * on the page_size field in the request.
   */
  set aamNotices(value: Array<GoogleCloudDialogflowAamV2beta1AamNotice>|null) {
    this.Serializable$set('aamNotices', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1ListAamNoticesResponse> {
    return GoogleCloudDialogflowAamV2beta1ListAamNoticesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'aamNotices': GoogleCloudDialogflowAamV2beta1AamNotice},
      keys: ['aamNotices', 'nextPageToken']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAamTopicClustersResponseParameters {
  aamTopicClusters?: Array<GoogleCloudDialogflowAamV2beta1AamTopicCluster>|null;
  nextPageToken?: string|null;
  totalSize?: number|null;
}
export class GoogleCloudDialogflowAamV2beta1ListAamTopicClustersResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAamTopicClustersResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamTopicClusters',
        (parameters.aamTopicClusters == null) ? (null) :
                                                (parameters.aamTopicClusters));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
    this.Serializable$set(
        'totalSize',
        (parameters.totalSize == null) ? (null) : (parameters.totalSize));
  }

  get aamTopicClusters():
      Array<GoogleCloudDialogflowAamV2beta1AamTopicCluster>|null {
    return (
        (this.Serializable$has('aamTopicClusters')) ?
            (this.Serializable$get('aamTopicClusters')) :
            (null));
  }

  /**
   * The list of topic clusters. There will be a maximum number of items
   * returned based on the page_size field in the request.
   */
  set aamTopicClusters(
      value: Array<GoogleCloudDialogflowAamV2beta1AamTopicCluster>|null) {
    this.Serializable$set('aamTopicClusters', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * The next_page_token value returned from a previous list request, or empty
   * if there are no more result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get totalSize(): number|null {
    return (
        (this.Serializable$has('totalSize')) ?
            (this.Serializable$get('totalSize')) :
            (null));
  }

  /**
   * Total number of topic clusters.
   */
  set totalSize(value: number|null) {
    this.Serializable$set('totalSize', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListAamTopicClustersResponse> {
    return GoogleCloudDialogflowAamV2beta1ListAamTopicClustersResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'aamTopicClusters': GoogleCloudDialogflowAamV2beta1AamTopicCluster},
      keys: ['aamTopicClusters', 'nextPageToken', 'totalSize']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAamTrainingRunsResponseParameters {
  aamTrainingRuns?: Array<GoogleCloudDialogflowAamV2beta1AamTrainingRun>|null;
  nextPageToken?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1ListAamTrainingRunsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAamTrainingRunsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamTrainingRuns',
        (parameters.aamTrainingRuns == null) ? (null) :
                                               (parameters.aamTrainingRuns));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get aamTrainingRuns():
      Array<GoogleCloudDialogflowAamV2beta1AamTrainingRun>|null {
    return (
        (this.Serializable$has('aamTrainingRuns')) ?
            (this.Serializable$get('aamTrainingRuns')) :
            (null));
  }

  /**
   * The list of flows. There will be a maximum number of items returned based
   * on the page_size field in the request.
   */
  set aamTrainingRuns(value:
                          Array<GoogleCloudDialogflowAamV2beta1AamTrainingRun>|
                      null) {
    this.Serializable$set('aamTrainingRuns', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListAamTrainingRunsResponse> {
    return GoogleCloudDialogflowAamV2beta1ListAamTrainingRunsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'aamTrainingRuns': GoogleCloudDialogflowAamV2beta1AamTrainingRun},
      keys: ['aamTrainingRuns', 'nextPageToken']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAamTranscriptsResponseParameters {
  aamTranscripts?: Array<GoogleCloudDialogflowAamV2beta1AamTranscript>|null;
  nextPageToken?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1ListAamTranscriptsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAamTranscriptsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamTranscripts',
        (parameters.aamTranscripts == null) ? (null) :
                                              (parameters.aamTranscripts));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get aamTranscripts():
      Array<GoogleCloudDialogflowAamV2beta1AamTranscript>|null {
    return (
        (this.Serializable$has('aamTranscripts')) ?
            (this.Serializable$get('aamTranscripts')) :
            (null));
  }

  /**
   * The list of flows. There will be a maximum number of items returned based
   * on the page_size field in the request.
   */
  set aamTranscripts(value: Array<GoogleCloudDialogflowAamV2beta1AamTranscript>|
                     null) {
    this.Serializable$set('aamTranscripts', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListAamTranscriptsResponse> {
    return GoogleCloudDialogflowAamV2beta1ListAamTranscriptsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'aamTranscripts': GoogleCloudDialogflowAamV2beta1AamTranscript},
      keys: ['aamTranscripts', 'nextPageToken']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAamUtterancesResponseParameters {
  aamUtterances?: Array<GoogleCloudDialogflowAamV2beta1AamUtterance>|null;
  nextPageToken?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1ListAamUtterancesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAamUtterancesResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamUtterances',
        (parameters.aamUtterances == null) ? (null) :
                                             (parameters.aamUtterances));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get aamUtterances(): Array<GoogleCloudDialogflowAamV2beta1AamUtterance>|null {
    return (
        (this.Serializable$has('aamUtterances')) ?
            (this.Serializable$get('aamUtterances')) :
            (null));
  }

  /**
   * The list of flows. There will be a maximum number of items returned based
   * on the page_size field in the request.
   */
  set aamUtterances(value: Array<GoogleCloudDialogflowAamV2beta1AamUtterance>|
                    null) {
    this.Serializable$set('aamUtterances', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListAamUtterancesResponse> {
    return GoogleCloudDialogflowAamV2beta1ListAamUtterancesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'aamUtterances': GoogleCloudDialogflowAamV2beta1AamUtterance},
      keys: ['aamUtterances', 'nextPageToken']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParameters {
  adminToolMetrics?: Array<GoogleCloudDialogflowAamV2beta1AdminToolMetrics>|
      null;
  discriminantPairs?: Array<
      GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParentChildDiscriminantPair>|
      null;
  nextPageToken?: string|null;
  lastUpdateTime?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'adminToolMetrics',
        (parameters.adminToolMetrics == null) ? (null) :
                                                (parameters.adminToolMetrics));
    this.Serializable$set(
        'discriminantPairs',
        (parameters.discriminantPairs == null) ?
            (null) :
            (parameters.discriminantPairs));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
    this.Serializable$set(
        'lastUpdateTime',
        (parameters.lastUpdateTime == null) ? (null) :
                                              (parameters.lastUpdateTime));
  }

  get adminToolMetrics():
      Array<GoogleCloudDialogflowAamV2beta1AdminToolMetrics>|null {
    return (
        (this.Serializable$has('adminToolMetrics')) ?
            (this.Serializable$get('adminToolMetrics')) :
            (null));
  }

  /**
   * The list of flows. There will be a maximum number of items returned based
   * on the page_size field in the request.
   */
  set adminToolMetrics(
      value: Array<GoogleCloudDialogflowAamV2beta1AdminToolMetrics>|null) {
    this.Serializable$set('adminToolMetrics', value);
  }

  get discriminantPairs(): Array<
      GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParentChildDiscriminantPair>|
      null {
    return (
        (this.Serializable$has('discriminantPairs')) ?
            (this.Serializable$get('discriminantPairs')) :
            (null));
  }

  /**
   * A list of pair of parent and child discriminant id.
   */
  set discriminantPairs(
      value: Array<
          GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParentChildDiscriminantPair>|
      null) {
    this.Serializable$set('discriminantPairs', value);
  }

  get lastUpdateTime(): string|null {
    return (
        (this.Serializable$has('lastUpdateTime')) ?
            (this.Serializable$get('lastUpdateTime')) :
            (null));
  }

  /**
   * Deprecated. Use DisplayStatsResponse.metrics_update_time instead. The time
   * when the metrics are lasted updated.
   */
  set lastUpdateTime(value: string|null) {
    this.Serializable$set('lastUpdateTime', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponse> {
    return GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'adminToolMetrics': GoogleCloudDialogflowAamV2beta1AdminToolMetrics,
        'discriminantPairs':
            GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParentChildDiscriminantPair
      },
      keys: [
        'adminToolMetrics', 'discriminantPairs', 'lastUpdateTime',
        'nextPageToken'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParentChildDiscriminantPairParameters {
  parentDiscriminantId?: string|null;
  childDiscriminantId?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParentChildDiscriminantPair extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParentChildDiscriminantPairParameters =
              {}) {
    super();
    this.Serializable$set(
        'parentDiscriminantId',
        (parameters.parentDiscriminantId == null) ?
            (null) :
            (parameters.parentDiscriminantId));
    this.Serializable$set(
        'childDiscriminantId',
        (parameters.childDiscriminantId == null) ?
            (null) :
            (parameters.childDiscriminantId));
  }

  get childDiscriminantId(): string|null {
    return (
        (this.Serializable$has('childDiscriminantId')) ?
            (this.Serializable$get('childDiscriminantId')) :
            (null));
  }

  /**
   * The child of the discriminant.
   */
  set childDiscriminantId(value: string|null) {
    this.Serializable$set('childDiscriminantId', value);
  }

  get parentDiscriminantId(): string|null {
    return (
        (this.Serializable$has('parentDiscriminantId')) ?
            (this.Serializable$get('parentDiscriminantId')) :
            (null));
  }

  /**
   * The parent of the discriminant. Can be null since DRIVER discriminants do
   * not have this.
   */
  set parentDiscriminantId(value: string|null) {
    this.Serializable$set('parentDiscriminantId', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParentChildDiscriminantPair> {
    return GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponseParentChildDiscriminantPair;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['childDiscriminantId', 'parentDiscriminantId']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseParameters {
  aamDiscriminants?: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null;
  nextPageToken?: string|null;
  metadata?: ApiClientObjectMap<
      GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseDiscriminantMetadata>|
      null;
}
export class
    GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'aamDiscriminants',
        (parameters.aamDiscriminants == null) ? (null) :
                                                (parameters.aamDiscriminants));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
  }

  get aamDiscriminants():
      Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null {
    return (
        (this.Serializable$has('aamDiscriminants')) ?
            (this.Serializable$get('aamDiscriminants')) :
            (null));
  }

  /**
   * The list of flows. There will be a maximum number of items returned based
   * on the page_size field in the request.
   */
  set aamDiscriminants(
      value: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null) {
    this.Serializable$set('aamDiscriminants', value);
  }

  get metadata(): ApiClientObjectMap<
      GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseDiscriminantMetadata>|
      null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Mapping aam_discriminant.name to its metadata
   */
  set metadata(
      value: ApiClientObjectMap<
          GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseDiscriminantMetadata>|
      null) {
    this.Serializable$set('metadata', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponse> {
    return GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'aamDiscriminants': GoogleCloudDialogflowAamV2beta1AamDiscriminant},
      keys: ['aamDiscriminants', 'metadata', 'nextPageToken'],
      objectMaps: {
        'metadata': {
          ctor:
              GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseDiscriminantMetadata,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseDiscriminantMetadataParameters {
  notices?: Array<GoogleCloudDialogflowAamV2beta1AamNotice>|null;
  confusionNoticesCount?: number|null;
  f1Score?: number|null;
}
export class
    GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseDiscriminantMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseDiscriminantMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'notices',
        (parameters.notices == null) ? (null) : (parameters.notices));
    this.Serializable$set(
        'confusionNoticesCount',
        (parameters.confusionNoticesCount == null) ?
            (null) :
            (parameters.confusionNoticesCount));
    this.Serializable$set(
        'f1Score',
        (parameters.f1Score == null) ? (null) : (parameters.f1Score));
  }

  get confusionNoticesCount(): number|null {
    return (
        (this.Serializable$has('confusionNoticesCount')) ?
            (this.Serializable$get('confusionNoticesCount')) :
            (null));
  }

  /**
   * The number of confusion notices (type UTTERANCE_WITH_WRONG_LABEL).
   */
  set confusionNoticesCount(value: number|null) {
    this.Serializable$set('confusionNoticesCount', value);
  }

  get f1Score(): number|null {
    return (
        (this.Serializable$has('f1Score')) ?
            (this.Serializable$get('f1Score')) :
            (null));
  }

  /**
   * The F1 score of the discriminant. Only discriminants with more than 20
   * labels have F1 score. This field will be 0 if the discriminant does not
   * have an F1 score.
   */
  set f1Score(value: number|null) {
    this.Serializable$set('f1Score', value);
  }

  get notices(): Array<GoogleCloudDialogflowAamV2beta1AamNotice>|null {
    return (
        (this.Serializable$has('notices')) ?
            (this.Serializable$get('notices')) :
            (null));
  }

  /**
   * The notices that the discriminant has links to.
   */
  set notices(value: Array<GoogleCloudDialogflowAamV2beta1AamNotice>|null) {
    this.Serializable$set('notices', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseDiscriminantMetadata> {
    return GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponseDiscriminantMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'notices': GoogleCloudDialogflowAamV2beta1AamNotice},
      keys: ['confusionNoticesCount', 'f1Score', 'notices']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1ListFlowLabeledAamTranscriptsResponseParameters {
  labeledTranscripts?:
      Array<GoogleCloudDialogflowAamV2beta1AamLabeledTranscript>|null;
  totalLabeledTranscriptsCount?: number|null;
  nextPageToken?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1ListFlowLabeledAamTranscriptsResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1ListFlowLabeledAamTranscriptsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'labeledTranscripts',
        (parameters.labeledTranscripts == null) ?
            (null) :
            (parameters.labeledTranscripts));
    this.Serializable$set(
        'totalLabeledTranscriptsCount',
        (parameters.totalLabeledTranscriptsCount == null) ?
            (null) :
            (parameters.totalLabeledTranscriptsCount));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get labeledTranscripts():
      Array<GoogleCloudDialogflowAamV2beta1AamLabeledTranscript>|null {
    return (
        (this.Serializable$has('labeledTranscripts')) ?
            (this.Serializable$get('labeledTranscripts')) :
            (null));
  }

  /**
   * The list of labeled transcripts belonging to an intent.
   */
  set labeledTranscripts(
      value: Array<GoogleCloudDialogflowAamV2beta1AamLabeledTranscript>|null) {
    this.Serializable$set('labeledTranscripts', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * result in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get totalLabeledTranscriptsCount(): number|null {
    return (
        (this.Serializable$has('totalLabeledTranscriptsCount')) ?
            (this.Serializable$get('totalLabeledTranscriptsCount')) :
            (null));
  }

  /**
   * Total number of transcripts labeled with the head intent specified in the
   * request.
   */
  set totalLabeledTranscriptsCount(value: number|null) {
    this.Serializable$set('totalLabeledTranscriptsCount', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1ListFlowLabeledAamTranscriptsResponse> {
    return GoogleCloudDialogflowAamV2beta1ListFlowLabeledAamTranscriptsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'labeledTranscripts':
            GoogleCloudDialogflowAamV2beta1AamLabeledTranscript
      },
      keys: [
        'labeledTranscripts', 'nextPageToken', 'totalLabeledTranscriptsCount'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedRequestParameters {
  labels?: Array<string>|null;
  labelerGaiaId?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
    this.Serializable$set(
        'labelerGaiaId',
        (parameters.labelerGaiaId == null) ? (null) :
                                             (parameters.labelerGaiaId));
  }

  get labelerGaiaId(): string|null {
    return (
        (this.Serializable$has('labelerGaiaId')) ?
            (this.Serializable$get('labelerGaiaId')) :
            (null));
  }

  /**
   * Optional. The labeler's GAIA ID, this is filled by Dialogflow frontend not
   * by the UI
   */
  set labelerGaiaId(value: string|null) {
    this.Serializable$set('labelerGaiaId', value);
  }

  get labels(): Array<string>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * Required. Format: `projects//locations//aam_label/ `.
   */
  set labels(value: Array<string>|null) {
    this.Serializable$set('labels', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedRequest> {
    return GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['labelerGaiaId', 'labels']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedResponseParameters {
  reviewedLabels?: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null;
}
export class GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'reviewedLabels',
        (parameters.reviewedLabels == null) ? (null) :
                                              (parameters.reviewedLabels));
  }

  get reviewedLabels(): Array<GoogleCloudDialogflowAamV2beta1AamLabel>|null {
    return (
        (this.Serializable$has('reviewedLabels')) ?
            (this.Serializable$get('reviewedLabels')) :
            (null));
  }

  /**
   * The labels that have been reviewed.
   */
  set reviewedLabels(value: Array<GoogleCloudDialogflowAamV2beta1AamLabel>|
                     null) {
    this.Serializable$set('reviewedLabels', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedResponse> {
    return GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'reviewedLabels': GoogleCloudDialogflowAamV2beta1AamLabel},
      keys: ['reviewedLabels']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantRequestParameters {
  sourceDiscriminants?: Array<string>|null;
  description?: string|null;
  displayName?: string|null;
  userGaiaId?: string|null;
  notes?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'sourceDiscriminants',
        (parameters.sourceDiscriminants == null) ?
            (null) :
            (parameters.sourceDiscriminants));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'userGaiaId',
        (parameters.userGaiaId == null) ? (null) : (parameters.userGaiaId));
    this.Serializable$set(
        'notes', (parameters.notes == null) ? (null) : (parameters.notes));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * The user-provided description of the target_discriminant. This information
   * is shown to AAM users to help them understand meaning of the discriminant
   * and whether a potential training phrase is applicable.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * The user-provided display name of the target_discriminant. Users may change
   * the displayname if they've changed the meaning of the target_discriminant
   * with the merge.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get notes(): string|null {
    return (
        (this.Serializable$has('notes')) ? (this.Serializable$get('notes')) :
                                           (null));
  }

  /**
   * Optional. The notes created by the user when the change was created. Notes
   * are limited to 1000 characters.
   */
  set notes(value: string|null) {
    this.Serializable$set('notes', value);
  }

  get sourceDiscriminants(): Array<string>|null {
    return (
        (this.Serializable$has('sourceDiscriminants')) ?
            (this.Serializable$get('sourceDiscriminants')) :
            (null));
  }

  /**
   * Required. The discriminants that will be combined into the
   * target_discriminant. These discriminants must have the same type as the
   * target_discriminant. After the merge, the source_discriminants will be
   * removed and their labels will be assigned to the target_discriminant.
   * Format of each discriminant: `projects//locations//aamDiscriminants/ `.
   */
  set sourceDiscriminants(value: Array<string>|null) {
    this.Serializable$set('sourceDiscriminants', value);
  }

  get userGaiaId(): string|null {
    return (
        (this.Serializable$has('userGaiaId')) ?
            (this.Serializable$get('userGaiaId')) :
            (null));
  }

  /**
   * GAIA ID of the user who made this request. This is filled by Dialogflow
   * frontend not by the UI.
   */
  set userGaiaId(value: string|null) {
    this.Serializable$set('userGaiaId', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantRequest> {
    return GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'description', 'displayName', 'notes', 'sourceDiscriminants',
        'userGaiaId'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantResponseParameters {
  oldDiscriminants?: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null;
  mergedDiscriminant?: GoogleCloudDialogflowAamV2beta1AamDiscriminant|null;
}
export class GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'oldDiscriminants',
        (parameters.oldDiscriminants == null) ? (null) :
                                                (parameters.oldDiscriminants));
    this.Serializable$set(
        'mergedDiscriminant',
        (parameters.mergedDiscriminant == null) ?
            (null) :
            (parameters.mergedDiscriminant));
  }

  get mergedDiscriminant(): GoogleCloudDialogflowAamV2beta1AamDiscriminant
      |null {
    return (
        (this.Serializable$has('mergedDiscriminant')) ?
            (this.Serializable$get('mergedDiscriminant')) :
            (null));
  }

  /**
   * The discriminant that the source discriminants were merged into.
   */
  set mergedDiscriminant(value: GoogleCloudDialogflowAamV2beta1AamDiscriminant|
                         null) {
    this.Serializable$set('mergedDiscriminant', value);
  }

  get oldDiscriminants():
      Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null {
    return (
        (this.Serializable$has('oldDiscriminants')) ?
            (this.Serializable$get('oldDiscriminants')) :
            (null));
  }

  /**
   * The source discriminants that were merged into the target discriminant.
   */
  set oldDiscriminants(
      value: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null) {
    this.Serializable$set('oldDiscriminants', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantResponse> {
    return GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'oldDiscriminants': GoogleCloudDialogflowAamV2beta1AamDiscriminant},
      keys: ['mergedDiscriminant', 'oldDiscriminants'],
      objects:
          {'mergedDiscriminant': GoogleCloudDialogflowAamV2beta1AamDiscriminant}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1MetricsParameters {
  precision?: number|null;
  recall?: number|null;
  positiveLabelCount?: number|null;
  threshold?: number|null;
}
export class GoogleCloudDialogflowAamV2beta1Metrics extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1MetricsParameters = {}) {
    super();
    this.Serializable$set(
        'precision',
        (parameters.precision == null) ? (null) : (parameters.precision));
    this.Serializable$set(
        'recall', (parameters.recall == null) ? (null) : (parameters.recall));
    this.Serializable$set(
        'positiveLabelCount',
        (parameters.positiveLabelCount == null) ?
            (null) :
            (parameters.positiveLabelCount));
    this.Serializable$set(
        'threshold',
        (parameters.threshold == null) ? (null) : (parameters.threshold));
  }

  get positiveLabelCount(): number|null {
    return (
        (this.Serializable$has('positiveLabelCount')) ?
            (this.Serializable$get('positiveLabelCount')) :
            (null));
  }

  /**
   * The number of positive label in the eval set.
   */
  set positiveLabelCount(value: number|null) {
    this.Serializable$set('positiveLabelCount', value);
  }

  get precision(): number|null {
    return (
        (this.Serializable$has('precision')) ?
            (this.Serializable$get('precision')) :
            (null));
  }

  /**
   * precision metric
   */
  set precision(value: number|null) {
    this.Serializable$set('precision', value);
  }

  get recall(): number|null {
    return (
        (this.Serializable$has('recall')) ? (this.Serializable$get('recall')) :
                                            (null));
  }

  /**
   * Recall metric
   */
  set recall(value: number|null) {
    this.Serializable$set('recall', value);
  }

  get threshold(): number|null {
    return (
        (this.Serializable$has('threshold')) ?
            (this.Serializable$get('threshold')) :
            (null));
  }

  /**
   * The confidence threshold at which this metric was calculated. Set to -1 if
   * threshold is meaningless, e.g. if the classifier returns the max scoring
   * label as the prediction.
   */
  set threshold(value: number|null) {
    this.Serializable$set('threshold', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowAamV2beta1Metrics> {
    return GoogleCloudDialogflowAamV2beta1Metrics;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['positiveLabelCount', 'precision', 'recall', 'threshold']};
  }
}

export interface GoogleCloudDialogflowAamV2beta1ModeledFlowParameters {
  intents?: Array<string>|null;
  nodes?: Array<GoogleCloudDialogflowAamV2beta1Node>|null;
  links?: Array<GoogleCloudDialogflowAamV2beta1Link>|null;
}
export class GoogleCloudDialogflowAamV2beta1ModeledFlow extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1ModeledFlowParameters = {}) {
    super();
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
    this.Serializable$set(
        'nodes', (parameters.nodes == null) ? (null) : (parameters.nodes));
    this.Serializable$set(
        'links', (parameters.links == null) ? (null) : (parameters.links));
  }

  get intents(): Array<string>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * Required. List of intents for which the flow is modeled.
   */
  set intents(value: Array<string>|null) {
    this.Serializable$set('intents', value);
  }

  get links(): Array<GoogleCloudDialogflowAamV2beta1Link>|null {
    return (
        (this.Serializable$has('links')) ? (this.Serializable$get('links')) :
                                           (null));
  }

  /**
   * List of links that make up the modeled flow.
   */
  set links(value: Array<GoogleCloudDialogflowAamV2beta1Link>|null) {
    this.Serializable$set('links', value);
  }

  get nodes(): Array<GoogleCloudDialogflowAamV2beta1Node>|null {
    return (
        (this.Serializable$has('nodes')) ? (this.Serializable$get('nodes')) :
                                           (null));
  }

  /**
   * List of nodes that make up the modeled flow.
   */
  set nodes(value: Array<GoogleCloudDialogflowAamV2beta1Node>|null) {
    this.Serializable$set('nodes', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1ModeledFlow> {
    return GoogleCloudDialogflowAamV2beta1ModeledFlow;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'links': GoogleCloudDialogflowAamV2beta1Link,
        'nodes': GoogleCloudDialogflowAamV2beta1Node
      },
      keys: ['intents', 'links', 'nodes']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1NodeParameters {
  groupingType?: GoogleCloudDialogflowAamV2beta1NodeGroupingType|null;
  discriminant?: string|null;
  type?: GoogleCloudDialogflowAamV2beta1NodeType|null;
  displayName?: string|null;
  parameters?: Array<GoogleCloudDialogflowV3alpha1IntentParameter>|null;
  nodeId?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1Node extends Serializable {
  constructor(parameters: GoogleCloudDialogflowAamV2beta1NodeParameters = {}) {
    super();
    this.Serializable$set(
        'groupingType',
        (parameters.groupingType == null) ? (null) : (parameters.groupingType));
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'nodeId', (parameters.nodeId == null) ? (null) : (parameters.nodeId));
  }

  static get GroupingType():
      IGoogleCloudDialogflowAamV2beta1NodeGroupingTypeEnum {
    return GoogleCloudDialogflowAamV2beta1NodeGroupingTypeEnum;
  }

  static get Type(): IGoogleCloudDialogflowAamV2beta1NodeTypeEnum {
    return GoogleCloudDialogflowAamV2beta1NodeTypeEnum;
  }

  get discriminant(): string|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * Required. If the node represents a single discriminant, the name of that
   * discriminant.
   */
  set discriminant(value: string|null) {
    this.Serializable$set('discriminant', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The title of the discriminant.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get groupingType(): GoogleCloudDialogflowAamV2beta1NodeGroupingType|null {
    return (
        (this.Serializable$has('groupingType')) ?
            (this.Serializable$get('groupingType')) :
            (null));
  }

  /**
   * Whether the node is a grouped node, collecting multiple parameters, or an
   * ungrouped node, containing only a single discriminant.
   */
  set groupingType(value: GoogleCloudDialogflowAamV2beta1NodeGroupingType|
                   null) {
    this.Serializable$set('groupingType', value);
  }

  get nodeId(): string|null {
    return (
        (this.Serializable$has('nodeId')) ? (this.Serializable$get('nodeId')) :
                                            (null));
  }

  /**
   * Required. Unique identifier for the node. Made from the discriminant ids or
   * parameter ids that make up the node.
   */
  set nodeId(value: string|null) {
    this.Serializable$set('nodeId', value);
  }

  get parameters(): Array<GoogleCloudDialogflowV3alpha1IntentParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * If the node group type is GROUPED, the list of parameters the node
   * contains.
   */
  set parameters(value: Array<GoogleCloudDialogflowV3alpha1IntentParameter>|
                 null) {
    this.Serializable$set('parameters', value);
  }

  get type(): GoogleCloudDialogflowAamV2beta1NodeType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * If the node represents a single discriminant, the type of that
   * discriminant.
   */
  set type(value: GoogleCloudDialogflowAamV2beta1NodeType|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowAamV2beta1Node> {
    return GoogleCloudDialogflowAamV2beta1Node;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parameters': GoogleCloudDialogflowV3alpha1IntentParameter},
      enums: {
        'groupingType': GoogleCloudDialogflowAamV2beta1NodeGroupingTypeEnum,
        'type': GoogleCloudDialogflowAamV2beta1NodeTypeEnum
      },
      keys: [
        'discriminant', 'displayName', 'groupingType', 'nodeId', 'parameters',
        'type'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantParameters {
  displayName?: string|null;
  type?: GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType|null;
  description?: string|null;
  parentAamDiscriminant?: string|null;
  canonicalUtterance?: string|null;
  childDiscriminantCount?: number|null;
  relevantScore?: number|null;
}
export class GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminant extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'parentAamDiscriminant',
        (parameters.parentAamDiscriminant == null) ?
            (null) :
            (parameters.parentAamDiscriminant));
    this.Serializable$set(
        'canonicalUtterance',
        (parameters.canonicalUtterance == null) ?
            (null) :
            (parameters.canonicalUtterance));
    this.Serializable$set(
        'childDiscriminantCount',
        (parameters.childDiscriminantCount == null) ?
            (null) :
            (parameters.childDiscriminantCount));
    this.Serializable$set(
        'relevantScore',
        (parameters.relevantScore == null) ? (null) :
                                             (parameters.relevantScore));
  }

  static get Type():
      IGoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum {
    return GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum;
  }

  get canonicalUtterance(): string|null {
    return (
        (this.Serializable$has('canonicalUtterance')) ?
            (this.Serializable$get('canonicalUtterance')) :
            (null));
  }

  /**
   * An utterance that exemplifies the aam_discriminants semantic quality
   */
  set canonicalUtterance(value: string|null) {
    this.Serializable$set('canonicalUtterance', value);
  }

  get childDiscriminantCount(): number|null {
    return (
        (this.Serializable$has('childDiscriminantCount')) ?
            (this.Serializable$get('childDiscriminantCount')) :
            (null));
  }

  /**
   * The count of intent children that this discriminant has.
   */
  set childDiscriminantCount(value: number|null) {
    this.Serializable$set('childDiscriminantCount', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * The description of the aam_discriminant.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * The human-readable name of the aam_discriminant.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get parentAamDiscriminant(): string|null {
    return (
        (this.Serializable$has('parentAamDiscriminant')) ?
            (this.Serializable$get('parentAamDiscriminant')) :
            (null));
  }

  /**
   * parent_aam_discriminant is the driver display_name.
   */
  set parentAamDiscriminant(value: string|null) {
    this.Serializable$set('parentAamDiscriminant', value);
  }

  get relevantScore(): number|null {
    return (
        (this.Serializable$has('relevantScore')) ?
            (this.Serializable$get('relevantScore')) :
            (null));
  }

  /**
   * Percentage of transcripts that contains utterance that is predicted to
   * label to this discriminant.
   */
  set relevantScore(value: number|null) {
    this.Serializable$set('relevantScore', value);
  }

  get type(): GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Discriminant type
   */
  set type(value: GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantType|
           null) {
    this.Serializable$set('type', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminant> {
    return GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminant;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums:
          {'type': GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminantTypeEnum},
      keys: [
        'canonicalUtterance', 'childDiscriminantCount', 'description',
        'displayName', 'parentAamDiscriminant', 'relevantScore', 'type'
      ]
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardParameters {
  title?: string|null;
  icon?: string|null;
  description?: string|null;
  uri?: string|null;
  type?: GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType|null;
}
export class GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCard extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardParameters =
              {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'icon', (parameters.icon == null) ? (null) : (parameters.icon));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
  }

  static get Type():
      IGoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardTypeEnum {
    return GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardTypeEnum;
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Description of the import card.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get icon(): string|null {
    return (
        (this.Serializable$has('icon')) ? (this.Serializable$get('icon')) :
                                          (null));
  }

  /**
   * Icon image source if the import card.
   */
  set icon(value: string|null) {
    this.Serializable$set('icon', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Human readable title of the import card.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  get type(): GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType
      |null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Type of the import card vertical.
   */
  set type(value: GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardType|
           null) {
    this.Serializable$set('type', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Url path of the import card.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCard> {
    return GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCard;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'type':
            GoogleCloudDialogflowAamV2beta1PrebuiltTaxonomyImportCardTypeEnum
      },
      keys: ['description', 'icon', 'title', 'type', 'uri']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1PreviewPrebuiltTaxonomyResponseParameters {
  discriminants?: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null;
  prebuiltDiscriminants?:
      Array<GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminant>|null;
}
export class
    GoogleCloudDialogflowAamV2beta1PreviewPrebuiltTaxonomyResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1PreviewPrebuiltTaxonomyResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminants',
        (parameters.discriminants == null) ? (null) :
                                             (parameters.discriminants));
    this.Serializable$set(
        'prebuiltDiscriminants',
        (parameters.prebuiltDiscriminants == null) ?
            (null) :
            (parameters.prebuiltDiscriminants));
  }

  get discriminants():
      Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null {
    return (
        (this.Serializable$has('discriminants')) ?
            (this.Serializable$get('discriminants')) :
            (null));
  }

  /**
   * Deprecated. Use prebuilt_discriminants instead.
   */
  set discriminants(value:
                        Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|
                    null) {
    this.Serializable$set('discriminants', value);
  }

  get prebuiltDiscriminants():
      Array<GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminant>|null {
    return (
        (this.Serializable$has('prebuiltDiscriminants')) ?
            (this.Serializable$get('prebuiltDiscriminants')) :
            (null));
  }

  /**
   * Prebuilt taxonomy discriminants.
   */
  set prebuiltDiscriminants(
      value: Array<GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminant>|null) {
    this.Serializable$set('prebuiltDiscriminants', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1PreviewPrebuiltTaxonomyResponse> {
    return GoogleCloudDialogflowAamV2beta1PreviewPrebuiltTaxonomyResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'discriminants': GoogleCloudDialogflowAamV2beta1AamDiscriminant,
        'prebuiltDiscriminants':
            GoogleCloudDialogflowAamV2beta1PrebuiltDiscriminant
      },
      keys: ['discriminants', 'prebuiltDiscriminants']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeRequestParameters {
}
export class
    GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeRequest extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeRequestParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeRequest> {
    return GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeResponseParameters {
  discriminantChange?: GoogleCloudDialogflowAamV2beta1AamDiscriminantChange|
      null;
}
export class
    GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminantChange',
        (parameters.discriminantChange == null) ?
            (null) :
            (parameters.discriminantChange));
  }

  get discriminantChange(): GoogleCloudDialogflowAamV2beta1AamDiscriminantChange
      |null {
    return (
        (this.Serializable$has('discriminantChange')) ?
            (this.Serializable$get('discriminantChange')) :
            (null));
  }

  /**
   * The rejected discriminant change.
   */
  set discriminantChange(
      value: GoogleCloudDialogflowAamV2beta1AamDiscriminantChange|null) {
    this.Serializable$set('discriminantChange', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeResponse> {
    return GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['discriminantChange'],
      objects: {
        'discriminantChange':
            GoogleCloudDialogflowAamV2beta1AamDiscriminantChange
      }
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1RemoveUserRequestParameters {
  email?: string|null;
  policy?: GoogleIamV1Policy|null;
}
export class GoogleCloudDialogflowAamV2beta1RemoveUserRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1RemoveUserRequestParameters = {}) {
    super();
    this.Serializable$set(
        'email', (parameters.email == null) ? (null) : (parameters.email));
    this.Serializable$set(
        'policy', (parameters.policy == null) ? (null) : (parameters.policy));
  }

  get email(): string|null {
    return (
        (this.Serializable$has('email')) ? (this.Serializable$get('email')) :
                                           (null));
  }

  /**
   * Email of the user AAM wants to add.
   */
  set email(value: string|null) {
    this.Serializable$set('email', value);
  }

  get policy(): GoogleIamV1Policy|null {
    return (
        (this.Serializable$has('policy')) ? (this.Serializable$get('policy')) :
                                            (null));
  }

  /**
   * The complete policy applied to the project in the parent. The size of the
   * policy is limited to a few 10s of KB. This is not filled by the UI console.
   */
  set policy(value: GoogleIamV1Policy|null) {
    this.Serializable$set('policy', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1RemoveUserRequest> {
    return GoogleCloudDialogflowAamV2beta1RemoveUserRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['email', 'policy'], objects: {'policy': GoogleIamV1Policy}};
  }
}

export interface GoogleCloudDialogflowAamV2beta1RemoveUserResponseParameters {
  policy?: GoogleIamV1Policy|null;
}
export class GoogleCloudDialogflowAamV2beta1RemoveUserResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1RemoveUserResponseParameters = {}) {
    super();
    this.Serializable$set(
        'policy', (parameters.policy == null) ? (null) : (parameters.policy));
  }

  get policy(): GoogleIamV1Policy|null {
    return (
        (this.Serializable$has('policy')) ? (this.Serializable$get('policy')) :
                                            (null));
  }

  /**
   * The updated IAM policy
   */
  set policy(value: GoogleIamV1Policy|null) {
    this.Serializable$set('policy', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowAamV2beta1RemoveUserResponse> {
    return GoogleCloudDialogflowAamV2beta1RemoveUserResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['policy'], objects: {'policy': GoogleIamV1Policy}};
  }
}

export interface GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseParameters {
  matchedData?:
      Array<GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedData>|
      null;
}
export class GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'matchedData',
        (parameters.matchedData == null) ? (null) : (parameters.matchedData));
  }

  get matchedData():
      Array<GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedData>|
      null {
    return (
        (this.Serializable$has('matchedData')) ?
            (this.Serializable$get('matchedData')) :
            (null));
  }

  /**
   * A list of matched search result.
   */
  set matchedData(
      value: Array<
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedData>|
      null) {
    this.Serializable$set('matchedData', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponse> {
    return GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'matchedData':
            GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedData
      },
      keys: ['matchedData']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataParameters {
  discriminant?: GoogleCloudDialogflowAamV2beta1AamDiscriminant|null;
  transcriptId?: string|null;
  transcript?: string|null;
  matchedResources?: Array<
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResource>|
      null;
}
export class
    GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedData extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataParameters =
              {}) {
    super();
    this.Serializable$set(
        'discriminant',
        (parameters.discriminant == null) ? (null) : (parameters.discriminant));
    this.Serializable$set(
        'transcriptId',
        (parameters.transcriptId == null) ? (null) : (parameters.transcriptId));
    this.Serializable$set(
        'transcript',
        (parameters.transcript == null) ? (null) : (parameters.transcript));
    this.Serializable$set(
        'matchedResources',
        (parameters.matchedResources == null) ? (null) :
                                                (parameters.matchedResources));
  }

  get discriminant(): GoogleCloudDialogflowAamV2beta1AamDiscriminant|null {
    return (
        (this.Serializable$has('discriminant')) ?
            (this.Serializable$get('discriminant')) :
            (null));
  }

  /**
   * A discriminant whose display name, or description, or canonical example, or
   * training phrases match the query.
   */
  set discriminant(value: GoogleCloudDialogflowAamV2beta1AamDiscriminant|null) {
    this.Serializable$set('discriminant', value);
  }

  get matchedResources(): Array<
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResource>|
      null {
    return (
        (this.Serializable$has('matchedResources')) ?
            (this.Serializable$get('matchedResources')) :
            (null));
  }

  /**
   * A list of matched resources of the discriminant.
   */
  set matchedResources(
      value: Array<
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResource>|
      null) {
    this.Serializable$set('matchedResources', value);
  }

  get transcript(): string|null {
    return (
        (this.Serializable$has('transcript')) ?
            (this.Serializable$get('transcript')) :
            (null));
  }

  /**
   * A transcript name whose utterances match the query. Format:
   * `projects//locations//aamDatasets/ /aamTranscripts/`.
   */
  set transcript(value: string|null) {
    this.Serializable$set('transcript', value);
  }

  get transcriptId(): string|null {
    return (
        (this.Serializable$has('transcriptId')) ?
            (this.Serializable$get('transcriptId')) :
            (null));
  }

  /**
   * A transcript id whose utterances match the query.
   */
  set transcriptId(value: string|null) {
    this.Serializable$set('transcriptId', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedData> {
    return GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedData;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'matchedResources':
            GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResource
      },
      keys: ['discriminant', 'matchedResources', 'transcript', 'transcriptId'],
      objects: {'discriminant': GoogleCloudDialogflowAamV2beta1AamDiscriminant}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceParameters {
  resourceType?:
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType|
      null;
  snippet?: string|null;
}
export class
    GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResource extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceParameters =
              {}) {
    super();
    this.Serializable$set(
        'resourceType',
        (parameters.resourceType == null) ? (null) : (parameters.resourceType));
    this.Serializable$set(
        'snippet',
        (parameters.snippet == null) ? (null) : (parameters.snippet));
  }

  static get ResourceType():
      IGoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum {
    return GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum;
  }

  get resourceType():
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType
      |null {
    return (
        (this.Serializable$has('resourceType')) ?
            (this.Serializable$get('resourceType')) :
            (null));
  }

  /**
   * The resource type.
   */
  set resourceType(
      value:
          GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceType|
      null) {
    this.Serializable$set('resourceType', value);
  }

  get snippet(): string|null {
    return (
        (this.Serializable$has('snippet')) ?
            (this.Serializable$get('snippet')) :
            (null));
  }

  /**
   * The snippet of this resource.
   */
  set snippet(value: string|null) {
    this.Serializable$set('snippet', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResource> {
    return GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResource;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'resourceType':
            GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponseMatchedDataMatchedResourceResourceTypeEnum
      },
      keys: ['resourceType', 'snippet']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantRequestParameters {
  newDiscriminants?: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null;
  userGaiaId?: string|null;
  notes?: string|null;
}
export class GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'newDiscriminants',
        (parameters.newDiscriminants == null) ? (null) :
                                                (parameters.newDiscriminants));
    this.Serializable$set(
        'userGaiaId',
        (parameters.userGaiaId == null) ? (null) : (parameters.userGaiaId));
    this.Serializable$set(
        'notes', (parameters.notes == null) ? (null) : (parameters.notes));
  }

  get newDiscriminants():
      Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null {
    return (
        (this.Serializable$has('newDiscriminants')) ?
            (this.Serializable$get('newDiscriminants')) :
            (null));
  }

  /**
   * Required. The resulting discriminants. They should have the same
   * AamDiscriminantType as the source discriminant.
   */
  set newDiscriminants(
      value: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null) {
    this.Serializable$set('newDiscriminants', value);
  }

  get notes(): string|null {
    return (
        (this.Serializable$has('notes')) ? (this.Serializable$get('notes')) :
                                           (null));
  }

  /**
   * Optional. The notes created by the user when the change was created. Notes
   * are limited to 1000 characters.
   */
  set notes(value: string|null) {
    this.Serializable$set('notes', value);
  }

  get userGaiaId(): string|null {
    return (
        (this.Serializable$has('userGaiaId')) ?
            (this.Serializable$get('userGaiaId')) :
            (null));
  }

  /**
   * GAIA ID of the user who made this request. This is filled by Dialogflow
   * frontend not by the UI.
   */
  set userGaiaId(value: string|null) {
    this.Serializable$set('userGaiaId', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantRequest> {
    return GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'newDiscriminants': GoogleCloudDialogflowAamV2beta1AamDiscriminant},
      keys: ['newDiscriminants', 'notes', 'userGaiaId']
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantResponseParameters {
  oldDiscriminant?: GoogleCloudDialogflowAamV2beta1AamDiscriminant|null;
  newDiscriminants?: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null;
}
export class GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'oldDiscriminant',
        (parameters.oldDiscriminant == null) ? (null) :
                                               (parameters.oldDiscriminant));
    this.Serializable$set(
        'newDiscriminants',
        (parameters.newDiscriminants == null) ? (null) :
                                                (parameters.newDiscriminants));
  }

  get newDiscriminants():
      Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null {
    return (
        (this.Serializable$has('newDiscriminants')) ?
            (this.Serializable$get('newDiscriminants')) :
            (null));
  }

  /**
   * The list of newly created(split) discriminants.
   */
  set newDiscriminants(
      value: Array<GoogleCloudDialogflowAamV2beta1AamDiscriminant>|null) {
    this.Serializable$set('newDiscriminants', value);
  }

  get oldDiscriminant(): GoogleCloudDialogflowAamV2beta1AamDiscriminant|null {
    return (
        (this.Serializable$has('oldDiscriminant')) ?
            (this.Serializable$get('oldDiscriminant')) :
            (null));
  }

  /**
   * The discriminant that is undergoing a split, contains updated information.
   * Caller should replace the old one discriminant with this one.
   */
  set oldDiscriminant(value: GoogleCloudDialogflowAamV2beta1AamDiscriminant|
                      null) {
    this.Serializable$set('oldDiscriminant', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantResponse> {
    return GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'newDiscriminants': GoogleCloudDialogflowAamV2beta1AamDiscriminant},
      keys: ['newDiscriminants', 'oldDiscriminant'],
      objects:
          {'oldDiscriminant': GoogleCloudDialogflowAamV2beta1AamDiscriminant}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1StartClusteringJobResponseParameters {
}
export class GoogleCloudDialogflowAamV2beta1StartClusteringJobResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowAamV2beta1StartClusteringJobResponseParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowAamV2beta1StartClusteringJobResponse> {
    return GoogleCloudDialogflowAamV2beta1StartClusteringJobResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowAamV2beta1UseCaseParameters {
  displayName?: string|null;
  flow?: GoogleCloudDialogflowAamV2beta1ModeledFlow|null;
}
export class GoogleCloudDialogflowAamV2beta1UseCase extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1UseCaseParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. User-readable name for the use case.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get flow(): GoogleCloudDialogflowAamV2beta1ModeledFlow|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * Modeled flow for the use case, consisting of the list of intents that the
   * use case is made up of, and the nodes and links that make up the modeled
   * flow.
   */
  set flow(value: GoogleCloudDialogflowAamV2beta1ModeledFlow|null) {
    this.Serializable$set('flow', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowAamV2beta1UseCase> {
    return GoogleCloudDialogflowAamV2beta1UseCase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['displayName', 'flow'],
      objects: {'flow': GoogleCloudDialogflowAamV2beta1ModeledFlow}
    };
  }
}

export interface GoogleCloudDialogflowAamV2beta1UserInfoParameters {
  obfuscatedGaiaId?: string|null;
  totalLabelCount?: number|null;
  name?: string|null;
  email?: string|null;
  role?: GoogleCloudDialogflowAamV2beta1UserInfoRole|null;
}
export class GoogleCloudDialogflowAamV2beta1UserInfo extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowAamV2beta1UserInfoParameters = {}) {
    super();
    this.Serializable$set(
        'obfuscatedGaiaId',
        (parameters.obfuscatedGaiaId == null) ? (null) :
                                                (parameters.obfuscatedGaiaId));
    this.Serializable$set(
        'totalLabelCount',
        (parameters.totalLabelCount == null) ? (null) :
                                               (parameters.totalLabelCount));
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'email', (parameters.email == null) ? (null) : (parameters.email));
    this.Serializable$set(
        'role', (parameters.role == null) ? (null) : (parameters.role));
  }

  static get Role(): IGoogleCloudDialogflowAamV2beta1UserInfoRoleEnum {
    return GoogleCloudDialogflowAamV2beta1UserInfoRoleEnum;
  }

  get email(): string|null {
    return (
        (this.Serializable$has('email')) ? (this.Serializable$get('email')) :
                                           (null));
  }

  /**
   * Email of the user
   */
  set email(value: string|null) {
    this.Serializable$set('email', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Full name of the user
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get obfuscatedGaiaId(): string|null {
    return (
        (this.Serializable$has('obfuscatedGaiaId')) ?
            (this.Serializable$get('obfuscatedGaiaId')) :
            (null));
  }

  /**
   * Obfuscated User GAIA ID
   */
  set obfuscatedGaiaId(value: string|null) {
    this.Serializable$set('obfuscatedGaiaId', value);
  }

  get role(): GoogleCloudDialogflowAamV2beta1UserInfoRole|null {
    return (
        (this.Serializable$has('role')) ? (this.Serializable$get('role')) :
                                          (null));
  }

  /**
   * AAM specific Role of the user. One user should only have one AAM role.
   */
  set role(value: GoogleCloudDialogflowAamV2beta1UserInfoRole|null) {
    this.Serializable$set('role', value);
  }

  get totalLabelCount(): number|null {
    return (
        (this.Serializable$has('totalLabelCount')) ?
            (this.Serializable$get('totalLabelCount')) :
            (null));
  }

  /**
   * Total number of labels the user classified
   */
  set totalLabelCount(value: number|null) {
    this.Serializable$set('totalLabelCount', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowAamV2beta1UserInfo> {
    return GoogleCloudDialogflowAamV2beta1UserInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'role': GoogleCloudDialogflowAamV2beta1UserInfoRoleEnum},
      keys: ['email', 'name', 'obfuscatedGaiaId', 'role', 'totalLabelCount']
    };
  }
}

export interface GoogleCloudDialogflowCxV3AdvancedSettingsParameters {
  enabled?: boolean|null;
  audioExportGcsDestination?: GoogleCloudDialogflowCxV3GcsDestination|null;
  speechSettings?: GoogleCloudDialogflowCxV3AdvancedSettingsSpeechSettings|null;
  playbackInterruptionSettings?:
      GoogleCloudDialogflowCxV3PlaybackInterruptionSettings|null;
  dtmfSettings?: GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings|null;
  loggingSettings?: GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings|
      null;
  requestLoggingSettings?:
      GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings|null;
  dtmfSettingsList?:
      Array<GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings>|null;
}
export class GoogleCloudDialogflowCxV3AdvancedSettings extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3AdvancedSettingsParameters = {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'audioExportGcsDestination',
        (parameters.audioExportGcsDestination == null) ?
            (null) :
            (parameters.audioExportGcsDestination));
    this.Serializable$set(
        'speechSettings',
        (parameters.speechSettings == null) ? (null) :
                                              (parameters.speechSettings));
    this.Serializable$set(
        'playbackInterruptionSettings',
        (parameters.playbackInterruptionSettings == null) ?
            (null) :
            (parameters.playbackInterruptionSettings));
    this.Serializable$set(
        'dtmfSettings',
        (parameters.dtmfSettings == null) ? (null) : (parameters.dtmfSettings));
    this.Serializable$set(
        'loggingSettings',
        (parameters.loggingSettings == null) ? (null) :
                                               (parameters.loggingSettings));
    this.Serializable$set(
        'requestLoggingSettings',
        (parameters.requestLoggingSettings == null) ?
            (null) :
            (parameters.requestLoggingSettings));
    this.Serializable$set(
        'dtmfSettingsList',
        (parameters.dtmfSettingsList == null) ? (null) :
                                                (parameters.dtmfSettingsList));
  }

  get audioExportGcsDestination(): GoogleCloudDialogflowCxV3GcsDestination
      |null {
    return (
        (this.Serializable$has('audioExportGcsDestination')) ?
            (this.Serializable$get('audioExportGcsDestination')) :
            (null));
  }

  /**
   * If present, incoming audio is exported by Dialogflow to the configured
   * Google Cloud Storage destination. Exposed at the following levels: - Agent
   * level - Flow level
   */
  set audioExportGcsDestination(value: GoogleCloudDialogflowCxV3GcsDestination|
                                null) {
    this.Serializable$set('audioExportGcsDestination', value);
  }

  get dtmfSettings(): GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings
      |null {
    return (
        (this.Serializable$has('dtmfSettings')) ?
            (this.Serializable$get('dtmfSettings')) :
            (null));
  }

  /**
   * Settings for DTMF. Exposed at the following levels: - Agent level - Flow
   * level - Page level - Parameter level.
   */
  set dtmfSettings(value: GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings|
                   null) {
    this.Serializable$set('dtmfSettings', value);
  }

  get dtmfSettingsList():
      Array<GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings>|null {
    return (
        (this.Serializable$has('dtmfSettingsList')) ?
            (this.Serializable$get('dtmfSettingsList')) :
            (null));
  }

  /**
   * List of DtmfSettings emitted from different levels of agent. Each
   * DtmfSettings in the list has higher priority than the ones before it in the
   * list, with the last one having the highest priority.
   */
  set dtmfSettingsList(
      value: Array<GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings>|
      null) {
    this.Serializable$set('dtmfSettingsList', value);
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * Whether advanced settings should be enabled. Exposed at the following
   * levels: - Agent level - Flow level
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get loggingSettings():
      GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings|null {
    return (
        (this.Serializable$has('loggingSettings')) ?
            (this.Serializable$get('loggingSettings')) :
            (null));
  }

  /**
   * Settings for logging. Settings for Dialogflow History, Contact Center
   * messages, StackDriver logs, and speech logging. Exposed at the following
   * levels: - Agent level.
   */
  set loggingSettings(
      value: GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings|null) {
    this.Serializable$set('loggingSettings', value);
  }

  get playbackInterruptionSettings():
      GoogleCloudDialogflowCxV3PlaybackInterruptionSettings|null {
    return (
        (this.Serializable$has('playbackInterruptionSettings')) ?
            (this.Serializable$get('playbackInterruptionSettings')) :
            (null));
  }

  /**
   * Whether to allow interruption on the playback of a message. Exposed at the
   * following levels: - Agent level - Flow level - Fulfillment level
   */
  set playbackInterruptionSettings(
      value: GoogleCloudDialogflowCxV3PlaybackInterruptionSettings|null) {
    this.Serializable$set('playbackInterruptionSettings', value);
  }

  get requestLoggingSettings():
      GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings|null {
    return (
        (this.Serializable$has('requestLoggingSettings')) ?
            (this.Serializable$get('requestLoggingSettings')) :
            (null));
  }

  /**
   * Output only. Indicates whether to log DetectIntentRequest for Dialogflow
   * Interaction. This field will only be filled during runtime. The storage
   * service depends on this settings to decide whether to log the request. -
   * Note: Whether to log DetectIntentResponse is controlled by
   * `logging_settings`[] above.
   */
  set requestLoggingSettings(
      value: GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings|null) {
    this.Serializable$set('requestLoggingSettings', value);
  }

  get speechSettings(): GoogleCloudDialogflowCxV3AdvancedSettingsSpeechSettings
      |null {
    return (
        (this.Serializable$has('speechSettings')) ?
            (this.Serializable$get('speechSettings')) :
            (null));
  }

  /**
   * Settings for speech to text detection. Exposed at the following levels: -
   * Agent level - Flow level - Page level - Parameter level
   */
  set speechSettings(
      value: GoogleCloudDialogflowCxV3AdvancedSettingsSpeechSettings|null) {
    this.Serializable$set('speechSettings', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3AdvancedSettings> {
    return GoogleCloudDialogflowCxV3AdvancedSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'dtmfSettingsList':
            GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings
      },
      keys: [
        'audioExportGcsDestination', 'dtmfSettings', 'dtmfSettingsList',
        'enabled', 'loggingSettings', 'playbackInterruptionSettings',
        'requestLoggingSettings', 'speechSettings'
      ],
      objects: {
        'audioExportGcsDestination': GoogleCloudDialogflowCxV3GcsDestination,
        'dtmfSettings': GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings,
        'loggingSettings':
            GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings,
        'playbackInterruptionSettings':
            GoogleCloudDialogflowCxV3PlaybackInterruptionSettings,
        'requestLoggingSettings':
            GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings,
        'speechSettings':
            GoogleCloudDialogflowCxV3AdvancedSettingsSpeechSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettingsParameters {
  enabled?: boolean|null;
  maxDigits?: number|null;
  finishDigit?: string|null;
}
export class GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'maxDigits',
        (parameters.maxDigits == null) ? (null) : (parameters.maxDigits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * If true, incoming audio is processed for DTMF (dual tone multi frequency)
   * events. For example, if the caller presses a button on their telephone
   * keypad and DTMF processing is enabled, Dialogflow will detect the event
   * (e.g. a \"3\" was pressed) in the incoming audio and pass the event to the
   * bot to drive business logic (e.g. when 3 is pressed, return the account
   * balance).
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get finishDigit(): string|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * The digit that terminates a DTMF digit sequence.
   */
  set finishDigit(value: string|null) {
    this.Serializable$set('finishDigit', value);
  }

  get maxDigits(): number|null {
    return (
        (this.Serializable$has('maxDigits')) ?
            (this.Serializable$get('maxDigits')) :
            (null));
  }

  /**
   * Max length of DTMF digits.
   */
  set maxDigits(value: number|null) {
    this.Serializable$set('maxDigits', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings> {
    return GoogleCloudDialogflowCxV3AdvancedSettingsDtmfSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enabled', 'finishDigit', 'maxDigits']};
  }
}

export interface GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettingsParameters {
  disableLogging?: boolean|null;
  enableStackdriverLogging?: boolean|null;
  enableInteractionLogging?: boolean|null;
}
export class GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
    this.Serializable$set(
        'enableStackdriverLogging',
        (parameters.enableStackdriverLogging == null) ?
            (null) :
            (parameters.enableStackdriverLogging));
    this.Serializable$set(
        'enableInteractionLogging',
        (parameters.enableInteractionLogging == null) ?
            (null) :
            (parameters.enableInteractionLogging));
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * If true, DF Interaction logging is currently disabled.
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  get enableInteractionLogging(): boolean|null {
    return (
        (this.Serializable$has('enableInteractionLogging')) ?
            (this.Serializable$get('enableInteractionLogging')) :
            (null));
  }

  /**
   * If true, DF Interaction logging is currently enabled.
   */
  set enableInteractionLogging(value: boolean|null) {
    this.Serializable$set('enableInteractionLogging', value);
  }

  get enableStackdriverLogging(): boolean|null {
    return (
        (this.Serializable$has('enableStackdriverLogging')) ?
            (this.Serializable$get('enableStackdriverLogging')) :
            (null));
  }

  /**
   * If true, StackDriver logging is currently enabled.
   */
  set enableStackdriverLogging(value: boolean|null) {
    this.Serializable$set('enableStackdriverLogging', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings> {
    return GoogleCloudDialogflowCxV3AdvancedSettingsLoggingSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'disableLogging', 'enableInteractionLogging', 'enableStackdriverLogging'
      ]
    };
  }
}

export interface GoogleCloudDialogflowCxV3AdvancedSettingsSpeechSettingsParameters {
  endpointerSensitivity?: number|null;
  noSpeechTimeout?: string|null;
  useTimeoutBasedEndpointing?: boolean|null;
}
export class GoogleCloudDialogflowCxV3AdvancedSettingsSpeechSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3AdvancedSettingsSpeechSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'endpointerSensitivity',
        (parameters.endpointerSensitivity == null) ?
            (null) :
            (parameters.endpointerSensitivity));
    this.Serializable$set(
        'noSpeechTimeout',
        (parameters.noSpeechTimeout == null) ? (null) :
                                               (parameters.noSpeechTimeout));
    this.Serializable$set(
        'useTimeoutBasedEndpointing',
        (parameters.useTimeoutBasedEndpointing == null) ?
            (null) :
            (parameters.useTimeoutBasedEndpointing));
  }

  get endpointerSensitivity(): number|null {
    return (
        (this.Serializable$has('endpointerSensitivity')) ?
            (this.Serializable$get('endpointerSensitivity')) :
            (null));
  }

  /**
   * Sensitivity of the speech model that detects the end of speech. Scale from
   * 0 to 100.
   */
  set endpointerSensitivity(value: number|null) {
    this.Serializable$set('endpointerSensitivity', value);
  }

  get noSpeechTimeout(): string|null {
    return (
        (this.Serializable$has('noSpeechTimeout')) ?
            (this.Serializable$get('noSpeechTimeout')) :
            (null));
  }

  /**
   * Timeout before detecting no speech.
   */
  set noSpeechTimeout(value: string|null) {
    this.Serializable$set('noSpeechTimeout', value);
  }

  get useTimeoutBasedEndpointing(): boolean|null {
    return (
        (this.Serializable$has('useTimeoutBasedEndpointing')) ?
            (this.Serializable$get('useTimeoutBasedEndpointing')) :
            (null));
  }

  /**
   * Use timeout based endpointing, interpreting endpointer sensitivy as seconds
   * of timeout value.
   */
  set useTimeoutBasedEndpointing(value: boolean|null) {
    this.Serializable$set('useTimeoutBasedEndpointing', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3AdvancedSettingsSpeechSettings> {
    return GoogleCloudDialogflowCxV3AdvancedSettingsSpeechSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'endpointerSensitivity', 'noSpeechTimeout', 'useTimeoutBasedEndpointing'
      ]
    };
  }
}

export interface GoogleCloudDialogflowCxV3AudioInputParameters {
  config?: GoogleCloudDialogflowCxV3InputAudioConfig|null;
  audio?: string|null;
}
export class GoogleCloudDialogflowCxV3AudioInput extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3AudioInputParameters = {}) {
    super();
    this.Serializable$set(
        'config', (parameters.config == null) ? (null) : (parameters.config));
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
  }

  get audio(): string|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * The natural language speech audio to be processed. A single request can
   * contain up to 1 minute of speech audio data. The transcribed text cannot
   * contain more than 256 bytes. For non-streaming audio detect intent, both
   * `config` and `audio` must be provided. For streaming audio detect intent,
   * `config` must be provided in the first request and `audio` must be provided
   * in all following requests.
   */
  set audio(value: string|null) {
    this.Serializable$set('audio', value);
  }

  get config(): GoogleCloudDialogflowCxV3InputAudioConfig|null {
    return (
        (this.Serializable$has('config')) ? (this.Serializable$get('config')) :
                                            (null));
  }

  /**
   * Required. Instructs the speech recognizer how to process the speech audio.
   */
  set config(value: GoogleCloudDialogflowCxV3InputAudioConfig|null) {
    this.Serializable$set('config', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3AudioInput> {
    return GoogleCloudDialogflowCxV3AudioInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['audio', 'config'],
      objects: {'config': GoogleCloudDialogflowCxV3InputAudioConfig}
    };
  }
}

export interface GoogleCloudDialogflowCxV3BargeInConfigParameters {
  noBargeInDuration?: string|null;
  totalDuration?: string|null;
}
export class GoogleCloudDialogflowCxV3BargeInConfig extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3BargeInConfigParameters = {}) {
    super();
    this.Serializable$set(
        'noBargeInDuration',
        (parameters.noBargeInDuration == null) ?
            (null) :
            (parameters.noBargeInDuration));
    this.Serializable$set(
        'totalDuration',
        (parameters.totalDuration == null) ? (null) :
                                             (parameters.totalDuration));
  }

  get noBargeInDuration(): string|null {
    return (
        (this.Serializable$has('noBargeInDuration')) ?
            (this.Serializable$get('noBargeInDuration')) :
            (null));
  }

  /**
   * Duration that is not eligible for barge-in at the beginning of the input
   * audio.
   */
  set noBargeInDuration(value: string|null) {
    this.Serializable$set('noBargeInDuration', value);
  }

  get totalDuration(): string|null {
    return (
        (this.Serializable$has('totalDuration')) ?
            (this.Serializable$get('totalDuration')) :
            (null));
  }

  /**
   * Total duration for the playback at the beginning of the input audio.
   */
  set totalDuration(value: string|null) {
    this.Serializable$set('totalDuration', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3BargeInConfig> {
    return GoogleCloudDialogflowCxV3BargeInConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['noBargeInDuration', 'totalDuration']};
  }
}

export interface GoogleCloudDialogflowCxV3BatchRunTestCasesMetadataParameters {
  errors?: Array<GoogleCloudDialogflowCxV3TestError>|null;
}
export class GoogleCloudDialogflowCxV3BatchRunTestCasesMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3BatchRunTestCasesMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'errors', (parameters.errors == null) ? (null) : (parameters.errors));
  }

  get errors(): Array<GoogleCloudDialogflowCxV3TestError>|null {
    return (
        (this.Serializable$has('errors')) ? (this.Serializable$get('errors')) :
                                            (null));
  }

  /**
   * The test errors.
   */
  set errors(value: Array<GoogleCloudDialogflowCxV3TestError>|null) {
    this.Serializable$set('errors', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3BatchRunTestCasesMetadata> {
    return GoogleCloudDialogflowCxV3BatchRunTestCasesMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'errors': GoogleCloudDialogflowCxV3TestError},
      keys: ['errors']
    };
  }
}

export interface GoogleCloudDialogflowCxV3BatchRunTestCasesResponseParameters {
  results?: Array<GoogleCloudDialogflowCxV3TestCaseResult>|null;
}
export class GoogleCloudDialogflowCxV3BatchRunTestCasesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3BatchRunTestCasesResponseParameters = {}) {
    super();
    this.Serializable$set(
        'results',
        (parameters.results == null) ? (null) : (parameters.results));
  }

  get results(): Array<GoogleCloudDialogflowCxV3TestCaseResult>|null {
    return (
        (this.Serializable$has('results')) ?
            (this.Serializable$get('results')) :
            (null));
  }

  /**
   * The test case results. The detailed conversation turns are empty in this
   * response.
   */
  set results(value: Array<GoogleCloudDialogflowCxV3TestCaseResult>|null) {
    this.Serializable$set('results', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3BatchRunTestCasesResponse> {
    return GoogleCloudDialogflowCxV3BatchRunTestCasesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'results': GoogleCloudDialogflowCxV3TestCaseResult},
      keys: ['results']
    };
  }
}

export interface GoogleCloudDialogflowCxV3CloneAgentResponseParameters {
  name?: string|null;
}
export class GoogleCloudDialogflowCxV3CloneAgentResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3CloneAgentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The cloned agent.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3CloneAgentResponse> {
    return GoogleCloudDialogflowCxV3CloneAgentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['name']};
  }
}

export interface GoogleCloudDialogflowCxV3ContinuousTestResultParameters {
  name?: string|null;
  result?: GoogleCloudDialogflowCxV3ContinuousTestResultResult|null;
  testCaseResults?: Array<string>|null;
  runTime?: string|null;
}
export class GoogleCloudDialogflowCxV3ContinuousTestResult extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ContinuousTestResultParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'result', (parameters.result == null) ? (null) : (parameters.result));
    this.Serializable$set(
        'testCaseResults',
        (parameters.testCaseResults == null) ? (null) :
                                               (parameters.testCaseResults));
    this.Serializable$set(
        'runTime',
        (parameters.runTime == null) ? (null) : (parameters.runTime));
  }

  static get Result():
      IGoogleCloudDialogflowCxV3ContinuousTestResultResultEnum {
    return GoogleCloudDialogflowCxV3ContinuousTestResultResultEnum;
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The resource name for the continuous test result. Format:
   * `projects//locations//agents//environments//continuousTestResults/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get result(): GoogleCloudDialogflowCxV3ContinuousTestResultResult|null {
    return (
        (this.Serializable$has('result')) ? (this.Serializable$get('result')) :
                                            (null));
  }

  /**
   * The result of this continuous test run, i.e. whether all the tests in this
   * continuous test run pass or not.
   */
  set result(value: GoogleCloudDialogflowCxV3ContinuousTestResultResult|null) {
    this.Serializable$set('result', value);
  }

  get runTime(): string|null {
    return (
        (this.Serializable$has('runTime')) ?
            (this.Serializable$get('runTime')) :
            (null));
  }

  /**
   * Time when the continuous testing run starts.
   */
  set runTime(value: string|null) {
    this.Serializable$set('runTime', value);
  }

  get testCaseResults(): Array<string>|null {
    return (
        (this.Serializable$has('testCaseResults')) ?
            (this.Serializable$get('testCaseResults')) :
            (null));
  }

  /**
   * A list of individual test case results names in this continuous test run.
   */
  set testCaseResults(value: Array<string>|null) {
    this.Serializable$set('testCaseResults', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ContinuousTestResult> {
    return GoogleCloudDialogflowCxV3ContinuousTestResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums:
          {'result': GoogleCloudDialogflowCxV3ContinuousTestResultResultEnum},
      keys: ['name', 'result', 'runTime', 'testCaseResults']
    };
  }
}

export interface GoogleCloudDialogflowCxV3ConversationTurnParameters {
  userInput?: GoogleCloudDialogflowCxV3ConversationTurnUserInput|null;
  virtualAgentOutput?:
      GoogleCloudDialogflowCxV3ConversationTurnVirtualAgentOutput|null;
}
export class GoogleCloudDialogflowCxV3ConversationTurn extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3ConversationTurnParameters = {}) {
    super();
    this.Serializable$set(
        'userInput',
        (parameters.userInput == null) ? (null) : (parameters.userInput));
    this.Serializable$set(
        'virtualAgentOutput',
        (parameters.virtualAgentOutput == null) ?
            (null) :
            (parameters.virtualAgentOutput));
  }

  get userInput(): GoogleCloudDialogflowCxV3ConversationTurnUserInput|null {
    return (
        (this.Serializable$has('userInput')) ?
            (this.Serializable$get('userInput')) :
            (null));
  }

  /**
   * The user input.
   */
  set userInput(value: GoogleCloudDialogflowCxV3ConversationTurnUserInput|
                null) {
    this.Serializable$set('userInput', value);
  }

  get virtualAgentOutput():
      GoogleCloudDialogflowCxV3ConversationTurnVirtualAgentOutput|null {
    return (
        (this.Serializable$has('virtualAgentOutput')) ?
            (this.Serializable$get('virtualAgentOutput')) :
            (null));
  }

  /**
   * The virtual agent output.
   */
  set virtualAgentOutput(
      value: GoogleCloudDialogflowCxV3ConversationTurnVirtualAgentOutput|null) {
    this.Serializable$set('virtualAgentOutput', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ConversationTurn> {
    return GoogleCloudDialogflowCxV3ConversationTurn;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['userInput', 'virtualAgentOutput'],
      objects: {
        'userInput': GoogleCloudDialogflowCxV3ConversationTurnUserInput,
        'virtualAgentOutput':
            GoogleCloudDialogflowCxV3ConversationTurnVirtualAgentOutput
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3ConversationTurnUserInputParameters {
  input?: GoogleCloudDialogflowCxV3QueryInput|null;
  injectedParameters?: ApiClientObjectMap<any>|null;
  parameterScope?: string|null;
  isWebhookEnabled?: boolean|null;
  enableSentimentAnalysis?: boolean|null;
}
export class GoogleCloudDialogflowCxV3ConversationTurnUserInput extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ConversationTurnUserInputParameters = {}) {
    super();
    this.Serializable$set(
        'input', (parameters.input == null) ? (null) : (parameters.input));
    this.Serializable$set(
        'injectedParameters',
        (parameters.injectedParameters == null) ?
            (null) :
            (parameters.injectedParameters));
    this.Serializable$set(
        'parameterScope',
        (parameters.parameterScope == null) ? (null) :
                                              (parameters.parameterScope));
    this.Serializable$set(
        'isWebhookEnabled',
        (parameters.isWebhookEnabled == null) ? (null) :
                                                (parameters.isWebhookEnabled));
    this.Serializable$set(
        'enableSentimentAnalysis',
        (parameters.enableSentimentAnalysis == null) ?
            (null) :
            (parameters.enableSentimentAnalysis));
  }

  get enableSentimentAnalysis(): boolean|null {
    return (
        (this.Serializable$has('enableSentimentAnalysis')) ?
            (this.Serializable$get('enableSentimentAnalysis')) :
            (null));
  }

  /**
   * Whether sentiment analysis is enabled.
   */
  set enableSentimentAnalysis(value: boolean|null) {
    this.Serializable$set('enableSentimentAnalysis', value);
  }

  get injectedParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('injectedParameters')) ?
            (this.Serializable$get('injectedParameters')) :
            (null));
  }

  /**
   * Parameters that need to be injected into the conversation during intent
   * detection.
   */
  set injectedParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('injectedParameters', value);
  }

  get input(): GoogleCloudDialogflowCxV3QueryInput|null {
    return (
        (this.Serializable$has('input')) ? (this.Serializable$get('input')) :
                                           (null));
  }

  /**
   * Supports text input, event input, dtmf input in the test case.
   */
  set input(value: GoogleCloudDialogflowCxV3QueryInput|null) {
    this.Serializable$set('input', value);
  }

  get isWebhookEnabled(): boolean|null {
    return (
        (this.Serializable$has('isWebhookEnabled')) ?
            (this.Serializable$get('isWebhookEnabled')) :
            (null));
  }

  /**
   * If webhooks should be allowed to trigger in response to the user utterance.
   * Often if parameters are injected, webhooks should not be enabled.
   */
  set isWebhookEnabled(value: boolean|null) {
    this.Serializable$set('isWebhookEnabled', value);
  }

  get parameterScope(): string|null {
    return (
        (this.Serializable$has('parameterScope')) ?
            (this.Serializable$get('parameterScope')) :
            (null));
  }

  /**
   * Scope for the parameters. If not specified, parameters will be treated as
   * session parameters. It is a customized free form text, e.g. 'event',
   * 'test'.
   */
  set parameterScope(value: string|null) {
    this.Serializable$set('parameterScope', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ConversationTurnUserInput> {
    return GoogleCloudDialogflowCxV3ConversationTurnUserInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'enableSentimentAnalysis', 'injectedParameters', 'input',
        'isWebhookEnabled', 'parameterScope'
      ],
      objectMaps: {
        'injectedParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'input': GoogleCloudDialogflowCxV3QueryInput}
    };
  }
}

export interface GoogleCloudDialogflowCxV3ConversationTurnVirtualAgentOutputParameters {
  sessionParameters?: ApiClientObjectMap<any>|null;
  differences?: Array<GoogleCloudDialogflowCxV3TestRunDifference>|null;
  diagnosticInfo?: ApiClientObjectMap<any>|null;
  triggeredIntent?: GoogleCloudDialogflowCxV3Intent|null;
  currentPage?: GoogleCloudDialogflowCxV3Page|null;
  textResponses?: Array<GoogleCloudDialogflowCxV3ResponseMessageText>|null;
  status?: GoogleRpcStatus|null;
}
export class GoogleCloudDialogflowCxV3ConversationTurnVirtualAgentOutput extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ConversationTurnVirtualAgentOutputParameters =
              {}) {
    super();
    this.Serializable$set(
        'sessionParameters',
        (parameters.sessionParameters == null) ?
            (null) :
            (parameters.sessionParameters));
    this.Serializable$set(
        'differences',
        (parameters.differences == null) ? (null) : (parameters.differences));
    this.Serializable$set(
        'diagnosticInfo',
        (parameters.diagnosticInfo == null) ? (null) :
                                              (parameters.diagnosticInfo));
    this.Serializable$set(
        'triggeredIntent',
        (parameters.triggeredIntent == null) ? (null) :
                                               (parameters.triggeredIntent));
    this.Serializable$set(
        'currentPage',
        (parameters.currentPage == null) ? (null) : (parameters.currentPage));
    this.Serializable$set(
        'textResponses',
        (parameters.textResponses == null) ? (null) :
                                             (parameters.textResponses));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
  }

  get currentPage(): GoogleCloudDialogflowCxV3Page|null {
    return (
        (this.Serializable$has('currentPage')) ?
            (this.Serializable$get('currentPage')) :
            (null));
  }

  /**
   * The Page on which the utterance was spoken. Only name and displayName will
   * be set.
   */
  set currentPage(value: GoogleCloudDialogflowCxV3Page|null) {
    this.Serializable$set('currentPage', value);
  }

  get diagnosticInfo(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('diagnosticInfo')) ?
            (this.Serializable$get('diagnosticInfo')) :
            (null));
  }

  /**
   * Required. Input only. The diagnostic info output for the turn. Required to
   * calculate the testing coverage.
   */
  set diagnosticInfo(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('diagnosticInfo', value);
  }

  get differences(): Array<GoogleCloudDialogflowCxV3TestRunDifference>|null {
    return (
        (this.Serializable$has('differences')) ?
            (this.Serializable$get('differences')) :
            (null));
  }

  /**
   * Output only. If this is part of a result conversation turn, the list of
   * differences between the original run and the replay for this output, if
   * any.
   */
  set differences(value: Array<GoogleCloudDialogflowCxV3TestRunDifference>|
                  null) {
    this.Serializable$set('differences', value);
  }

  get sessionParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('sessionParameters')) ?
            (this.Serializable$get('sessionParameters')) :
            (null));
  }

  /**
   * The session parameters available to the bot at this point.
   */
  set sessionParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('sessionParameters', value);
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * Response error from the agent in the test result. If set, other output is
   * empty.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  get textResponses():
      Array<GoogleCloudDialogflowCxV3ResponseMessageText>|null {
    return (
        (this.Serializable$has('textResponses')) ?
            (this.Serializable$get('textResponses')) :
            (null));
  }

  /**
   * The text responses from the agent for the turn.
   */
  set textResponses(value: Array<GoogleCloudDialogflowCxV3ResponseMessageText>|
                    null) {
    this.Serializable$set('textResponses', value);
  }

  get triggeredIntent(): GoogleCloudDialogflowCxV3Intent|null {
    return (
        (this.Serializable$has('triggeredIntent')) ?
            (this.Serializable$get('triggeredIntent')) :
            (null));
  }

  /**
   * The Intent that triggered the response. Only name and displayName will be
   * set.
   */
  set triggeredIntent(value: GoogleCloudDialogflowCxV3Intent|null) {
    this.Serializable$set('triggeredIntent', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3ConversationTurnVirtualAgentOutput> {
    return GoogleCloudDialogflowCxV3ConversationTurnVirtualAgentOutput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'differences': GoogleCloudDialogflowCxV3TestRunDifference,
        'textResponses': GoogleCloudDialogflowCxV3ResponseMessageText
      },
      keys: [
        'currentPage', 'diagnosticInfo', 'differences', 'sessionParameters',
        'status', 'textResponses', 'triggeredIntent'
      ],
      objectMaps: {
        'diagnosticInfo': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'sessionParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'currentPage': GoogleCloudDialogflowCxV3Page,
        'status': GoogleRpcStatus,
        'triggeredIntent': GoogleCloudDialogflowCxV3Intent
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3CreateDocumentOperationMetadataParameters {
  genericMetadata?: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|
      null;
}
export class GoogleCloudDialogflowCxV3CreateDocumentOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3CreateDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3CreateDocumentOperationMetadata> {
    return GoogleCloudDialogflowCxV3CreateDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3CreateLocationSettingsMetadataParameters {
}
export class GoogleCloudDialogflowCxV3CreateLocationSettingsMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3CreateLocationSettingsMetadataParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3CreateLocationSettingsMetadata> {
    return GoogleCloudDialogflowCxV3CreateLocationSettingsMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3CreateVersionOperationMetadataParameters {
  version?: string|null;
}
export class GoogleCloudDialogflowCxV3CreateVersionOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3CreateVersionOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
  }

  get version(): string|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Name of the created version. Format:
   * `projects//locations//agents//flows//versions/`.
   */
  set version(value: string|null) {
    this.Serializable$set('version', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3CreateVersionOperationMetadata> {
    return GoogleCloudDialogflowCxV3CreateVersionOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['version']};
  }
}

export interface GoogleCloudDialogflowCxV3DeleteDocumentOperationMetadataParameters {
  genericMetadata?: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|
      null;
}
export class GoogleCloudDialogflowCxV3DeleteDocumentOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3DeleteDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3DeleteDocumentOperationMetadata> {
    return GoogleCloudDialogflowCxV3DeleteDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3DeployFlowMetadataParameters {
  testErrors?: Array<GoogleCloudDialogflowCxV3TestError>|null;
}
export class GoogleCloudDialogflowCxV3DeployFlowMetadata extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3DeployFlowMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'testErrors',
        (parameters.testErrors == null) ? (null) : (parameters.testErrors));
  }

  get testErrors(): Array<GoogleCloudDialogflowCxV3TestError>|null {
    return (
        (this.Serializable$has('testErrors')) ?
            (this.Serializable$get('testErrors')) :
            (null));
  }

  /**
   * Errors of running deployment tests.
   */
  set testErrors(value: Array<GoogleCloudDialogflowCxV3TestError>|null) {
    this.Serializable$set('testErrors', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3DeployFlowMetadata> {
    return GoogleCloudDialogflowCxV3DeployFlowMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'testErrors': GoogleCloudDialogflowCxV3TestError},
      keys: ['testErrors']
    };
  }
}

export interface GoogleCloudDialogflowCxV3DeployFlowResponseParameters {
  environment?: GoogleCloudDialogflowCxV3Environment|null;
  deployment?: string|null;
}
export class GoogleCloudDialogflowCxV3DeployFlowResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3DeployFlowResponseParameters = {}) {
    super();
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
    this.Serializable$set(
        'deployment',
        (parameters.deployment == null) ? (null) : (parameters.deployment));
  }

  get deployment(): string|null {
    return (
        (this.Serializable$has('deployment')) ?
            (this.Serializable$get('deployment')) :
            (null));
  }

  /**
   * The name of the flow version Deployment. Format:
   * `projects//locations//agents// environments//deployments/`.
   */
  set deployment(value: string|null) {
    this.Serializable$set('deployment', value);
  }

  get environment(): GoogleCloudDialogflowCxV3Environment|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * The updated environment where the flow is deployed.
   */
  set environment(value: GoogleCloudDialogflowCxV3Environment|null) {
    this.Serializable$set('environment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3DeployFlowResponse> {
    return GoogleCloudDialogflowCxV3DeployFlowResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['deployment', 'environment'],
      objects: {'environment': GoogleCloudDialogflowCxV3Environment}
    };
  }
}

export interface GoogleCloudDialogflowCxV3DtmfInputParameters {
  digits?: string|null;
  finishDigit?: string|null;
  transformed?: boolean|null;
}
export class GoogleCloudDialogflowCxV3DtmfInput extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3DtmfInputParameters = {}) {
    super();
    this.Serializable$set(
        'digits', (parameters.digits == null) ? (null) : (parameters.digits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
    this.Serializable$set(
        'transformed',
        (parameters.transformed == null) ? (null) : (parameters.transformed));
  }

  get digits(): string|null {
    return (
        (this.Serializable$has('digits')) ? (this.Serializable$get('digits')) :
                                            (null));
  }

  /**
   * The dtmf digits.
   */
  set digits(value: string|null) {
    this.Serializable$set('digits', value);
  }

  get finishDigit(): string|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * The finish digit (if any).
   */
  set finishDigit(value: string|null) {
    this.Serializable$set('finishDigit', value);
  }

  get transformed(): boolean|null {
    return (
        (this.Serializable$has('transformed')) ?
            (this.Serializable$get('transformed')) :
            (null));
  }

  /**
   * Whether the DTMF digits has already been transformed into the pattern of
   * the entity type associated with the parameter to fill.
   */
  set transformed(value: boolean|null) {
    this.Serializable$set('transformed', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3DtmfInput> {
    return GoogleCloudDialogflowCxV3DtmfInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['digits', 'finishDigit', 'transformed']};
  }
}

export interface GoogleCloudDialogflowCxV3EntityConflictDiffParameters {
  displayName?: string|null;
  originalEntityId?: string|null;
  importedEntityId?: string|null;
}
export class GoogleCloudDialogflowCxV3EntityConflictDiff extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3EntityConflictDiffParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'originalEntityId',
        (parameters.originalEntityId == null) ? (null) :
                                                (parameters.originalEntityId));
    this.Serializable$set(
        'importedEntityId',
        (parameters.importedEntityId == null) ? (null) :
                                                (parameters.importedEntityId));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Conflicting display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get importedEntityId(): string|null {
    return (
        (this.Serializable$has('importedEntityId')) ?
            (this.Serializable$get('importedEntityId')) :
            (null));
  }

  /**
   * The name of the conflicting entity type in the content to be imported. This
   * is a unique identifier. Format:
   * `projects//locations//agents//entityTypes/`.
   */
  set importedEntityId(value: string|null) {
    this.Serializable$set('importedEntityId', value);
  }

  get originalEntityId(): string|null {
    return (
        (this.Serializable$has('originalEntityId')) ?
            (this.Serializable$get('originalEntityId')) :
            (null));
  }

  /**
   * The name of the conflicting entity type in the current agent. This is a
   * unique identifier. Format: `projects//locations//agents//entityTypes/`.
   */
  set originalEntityId(value: string|null) {
    this.Serializable$set('originalEntityId', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3EntityConflictDiff> {
    return GoogleCloudDialogflowCxV3EntityConflictDiff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'importedEntityId', 'originalEntityId']};
  }
}

export interface GoogleCloudDialogflowCxV3EnvironmentParameters {
  name?: string|null;
  displayName?: string|null;
  description?: string|null;
  versionTrafficAllocation?: ApiClientObjectMap<number>|null;
  versionConfigs?: Array<GoogleCloudDialogflowCxV3EnvironmentVersionConfig>|
      null;
  updateTime?: string|null;
  testCasesConfig?: GoogleCloudDialogflowCxV3EnvironmentTestCasesConfig|null;
  etag?: string|null;
  deploymentConfigs?:
      Array<GoogleCloudDialogflowCxV3EnvironmentDeploymentConfig>|null;
  webhookConfig?: GoogleCloudDialogflowCxV3EnvironmentWebhookConfig|null;
}
export class GoogleCloudDialogflowCxV3Environment extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3EnvironmentParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'versionTrafficAllocation',
        (parameters.versionTrafficAllocation == null) ?
            (null) :
            (parameters.versionTrafficAllocation));
    this.Serializable$set(
        'versionConfigs',
        (parameters.versionConfigs == null) ? (null) :
                                              (parameters.versionConfigs));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'testCasesConfig',
        (parameters.testCasesConfig == null) ? (null) :
                                               (parameters.testCasesConfig));
    this.Serializable$set(
        'etag', (parameters.etag == null) ? (null) : (parameters.etag));
    this.Serializable$set(
        'deploymentConfigs',
        (parameters.deploymentConfigs == null) ?
            (null) :
            (parameters.deploymentConfigs));
    this.Serializable$set(
        'webhookConfig',
        (parameters.webhookConfig == null) ? (null) :
                                             (parameters.webhookConfig));
  }

  get deploymentConfigs():
      Array<GoogleCloudDialogflowCxV3EnvironmentDeploymentConfig>|null {
    return (
        (this.Serializable$has('deploymentConfigs')) ?
            (this.Serializable$get('deploymentConfigs')) :
            (null));
  }

  /**
   * The deployment configurations for the flows in this environment.
   */
  set deploymentConfigs(
      value: Array<GoogleCloudDialogflowCxV3EnvironmentDeploymentConfig>|null) {
    this.Serializable$set('deploymentConfigs', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * The human-readable description of the environment. The maximum length is
   * 500 characters. If exceeded, the request is rejected.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the environment (unique in an agent).
   * Limit of 64 characters.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get etag(): string|null {
    return (
        (this.Serializable$has('etag')) ? (this.Serializable$get('etag')) :
                                          (null));
  }

  /**
   * Etag of the environment, may be sent on update requests to ensure the
   * client has an up-to-date value before proceeding.
   */
  set etag(value: string|null) {
    this.Serializable$set('etag', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The name of the environment. Format:
   * `projects//locations//agents//environments/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get testCasesConfig(): GoogleCloudDialogflowCxV3EnvironmentTestCasesConfig
      |null {
    return (
        (this.Serializable$has('testCasesConfig')) ?
            (this.Serializable$get('testCasesConfig')) :
            (null));
  }

  /**
   * The test cases config for continuous tests of this environment.
   */
  set testCasesConfig(value:
                          GoogleCloudDialogflowCxV3EnvironmentTestCasesConfig|
                      null) {
    this.Serializable$set('testCasesConfig', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. Update time of this environment.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  get versionConfigs():
      Array<GoogleCloudDialogflowCxV3EnvironmentVersionConfig>|null {
    return (
        (this.Serializable$has('versionConfigs')) ?
            (this.Serializable$get('versionConfigs')) :
            (null));
  }

  /**
   * Required. A list of configurations for flow versions. You should include
   * version configs for all flows that are reachable from `Start Flow` in the
   * agent. Otherwise, an error will be returned.
   */
  set versionConfigs(
      value: Array<GoogleCloudDialogflowCxV3EnvironmentVersionConfig>|null) {
    this.Serializable$set('versionConfigs', value);
  }

  get versionTrafficAllocation(): ApiClientObjectMap<number>|null {
    return (
        (this.Serializable$has('versionTrafficAllocation')) ?
            (this.Serializable$get('versionTrafficAllocation')) :
            (null));
  }

  /**
   * A map to represent the traffic allocation for each version in this
   * environment. The key of the map is the resource name of the version, in the
   * format of `projects//locations//agents//flows//versions/`. The value of the
   * map is the traffic allocation ratio for this version (from 0.0 to 1.0). For
   * every flow in an agent, the total traffic allocation should add up to 1.0.
   */
  set versionTrafficAllocation(value: ApiClientObjectMap<number>|null) {
    this.Serializable$set('versionTrafficAllocation', value);
  }

  get webhookConfig(): GoogleCloudDialogflowCxV3EnvironmentWebhookConfig|null {
    return (
        (this.Serializable$has('webhookConfig')) ?
            (this.Serializable$get('webhookConfig')) :
            (null));
  }

  /**
   * The webhook configuration for this environment.
   */
  set webhookConfig(value: GoogleCloudDialogflowCxV3EnvironmentWebhookConfig|
                    null) {
    this.Serializable$set('webhookConfig', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3Environment> {
    return GoogleCloudDialogflowCxV3Environment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'deploymentConfigs':
            GoogleCloudDialogflowCxV3EnvironmentDeploymentConfig,
        'versionConfigs': GoogleCloudDialogflowCxV3EnvironmentVersionConfig
      },
      keys: [
        'deploymentConfigs', 'description', 'displayName', 'etag', 'name',
        'testCasesConfig', 'updateTime', 'versionConfigs',
        'versionTrafficAllocation', 'webhookConfig'
      ],
      objectMaps: {
        'versionTrafficAllocation': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'testCasesConfig': GoogleCloudDialogflowCxV3EnvironmentTestCasesConfig,
        'webhookConfig': GoogleCloudDialogflowCxV3EnvironmentWebhookConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3EnvironmentDeploymentConfigParameters {
  flow?: string|null;
  deploymentTests?: Array<string>|null;
  updateTime?: string|null;
}
export class GoogleCloudDialogflowCxV3EnvironmentDeploymentConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3EnvironmentDeploymentConfigParameters = {}) {
    super();
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
    this.Serializable$set(
        'deploymentTests',
        (parameters.deploymentTests == null) ? (null) :
                                               (parameters.deploymentTests));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
  }

  get deploymentTests(): Array<string>|null {
    return (
        (this.Serializable$has('deploymentTests')) ?
            (this.Serializable$get('deploymentTests')) :
            (null));
  }

  /**
   * A list of test cases to run before deployment. The test cases should belong
   * to the same agent as the environment.
   */
  set deploymentTests(value: Array<string>|null) {
    this.Serializable$set('deploymentTests', value);
  }

  get flow(): string|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * The name of flow to deploy. Format: `projects//locations//agents//flows/`.
   */
  set flow(value: string|null) {
    this.Serializable$set('flow', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. The last update time of deployment configuration.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3EnvironmentDeploymentConfig> {
    return GoogleCloudDialogflowCxV3EnvironmentDeploymentConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['deploymentTests', 'flow', 'updateTime']};
  }
}

export interface GoogleCloudDialogflowCxV3EnvironmentTestCasesConfigParameters {
  testCases?: Array<string>|null;
  enableContinuousRun?: boolean|null;
  enablePredeploymentRun?: boolean|null;
}
export class GoogleCloudDialogflowCxV3EnvironmentTestCasesConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3EnvironmentTestCasesConfigParameters = {}) {
    super();
    this.Serializable$set(
        'testCases',
        (parameters.testCases == null) ? (null) : (parameters.testCases));
    this.Serializable$set(
        'enableContinuousRun',
        (parameters.enableContinuousRun == null) ?
            (null) :
            (parameters.enableContinuousRun));
    this.Serializable$set(
        'enablePredeploymentRun',
        (parameters.enablePredeploymentRun == null) ?
            (null) :
            (parameters.enablePredeploymentRun));
  }

  get enableContinuousRun(): boolean|null {
    return (
        (this.Serializable$has('enableContinuousRun')) ?
            (this.Serializable$get('enableContinuousRun')) :
            (null));
  }

  /**
   * Whether to run test cases in TestCasesConfig.test_cases periodically.
   * Default false. If set to true, run once a day.
   */
  set enableContinuousRun(value: boolean|null) {
    this.Serializable$set('enableContinuousRun', value);
  }

  get enablePredeploymentRun(): boolean|null {
    return (
        (this.Serializable$has('enablePredeploymentRun')) ?
            (this.Serializable$get('enablePredeploymentRun')) :
            (null));
  }

  /**
   * Whether to run test cases in TestCasesConfig.test_cases before deploying a
   * flow version to the environment. Default false.
   */
  set enablePredeploymentRun(value: boolean|null) {
    this.Serializable$set('enablePredeploymentRun', value);
  }

  get testCases(): Array<string>|null {
    return (
        (this.Serializable$has('testCases')) ?
            (this.Serializable$get('testCases')) :
            (null));
  }

  /**
   * A list of test case names to run. They should be under the same agent.
   * Format of each test case name: `projects//locations/ /agents//testCases/`
   */
  set testCases(value: Array<string>|null) {
    this.Serializable$set('testCases', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3EnvironmentTestCasesConfig> {
    return GoogleCloudDialogflowCxV3EnvironmentTestCasesConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['enableContinuousRun', 'enablePredeploymentRun', 'testCases']
    };
  }
}

export interface GoogleCloudDialogflowCxV3EnvironmentVersionConfigParameters {
  version?: string|null;
  trafficAllocation?: number|null;
  staleFlowInfo?:
      GoogleCloudDialogflowCxV3EnvironmentVersionConfigStaleFlowInfo|null;
}
export class GoogleCloudDialogflowCxV3EnvironmentVersionConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3EnvironmentVersionConfigParameters = {}) {
    super();
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
    this.Serializable$set(
        'trafficAllocation',
        (parameters.trafficAllocation == null) ?
            (null) :
            (parameters.trafficAllocation));
    this.Serializable$set(
        'staleFlowInfo',
        (parameters.staleFlowInfo == null) ? (null) :
                                             (parameters.staleFlowInfo));
  }

  get staleFlowInfo():
      GoogleCloudDialogflowCxV3EnvironmentVersionConfigStaleFlowInfo|null {
    return (
        (this.Serializable$has('staleFlowInfo')) ?
            (this.Serializable$get('staleFlowInfo')) :
            (null));
  }

  /**
   * Only exists if this version belongs to a \"stale\" flow.
   */
  set staleFlowInfo(
      value: GoogleCloudDialogflowCxV3EnvironmentVersionConfigStaleFlowInfo|
      null) {
    this.Serializable$set('staleFlowInfo', value);
  }

  get trafficAllocation(): number|null {
    return (
        (this.Serializable$has('trafficAllocation')) ?
            (this.Serializable$get('trafficAllocation')) :
            (null));
  }

  /**
   * Percentage of the traffic which should be routed to the particular version
   * of the flow. Default to 1.0 if not specified, which means 100% of the
   * traffic routes to the flow will use this version. Note: INVALID_ARGUMENT
   * error will be thrown if traffic allocation for a single flow doesn't sum up
   * to 1.0.
   */
  set trafficAllocation(value: number|null) {
    this.Serializable$set('trafficAllocation', value);
  }

  get version(): string|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Required. Format: projects//locations//agents//flows//versions/.
   */
  set version(value: string|null) {
    this.Serializable$set('version', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3EnvironmentVersionConfig> {
    return GoogleCloudDialogflowCxV3EnvironmentVersionConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['staleFlowInfo', 'trafficAllocation', 'version'],
      objects: {
        'staleFlowInfo':
            GoogleCloudDialogflowCxV3EnvironmentVersionConfigStaleFlowInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3EnvironmentVersionConfigStaleFlowInfoParameters {
  flowDisplayName?: string|null;
  versionDisplayName?: string|null;
}
export class
    GoogleCloudDialogflowCxV3EnvironmentVersionConfigStaleFlowInfo extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3EnvironmentVersionConfigStaleFlowInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'flowDisplayName',
        (parameters.flowDisplayName == null) ? (null) :
                                               (parameters.flowDisplayName));
    this.Serializable$set(
        'versionDisplayName',
        (parameters.versionDisplayName == null) ?
            (null) :
            (parameters.versionDisplayName));
  }

  get flowDisplayName(): string|null {
    return (
        (this.Serializable$has('flowDisplayName')) ?
            (this.Serializable$get('flowDisplayName')) :
            (null));
  }

  /**
   * The display name of the flow.
   */
  set flowDisplayName(value: string|null) {
    this.Serializable$set('flowDisplayName', value);
  }

  get versionDisplayName(): string|null {
    return (
        (this.Serializable$has('versionDisplayName')) ?
            (this.Serializable$get('versionDisplayName')) :
            (null));
  }

  /**
   * The display name of the referenced flow version in the environment.
   */
  set versionDisplayName(value: string|null) {
    this.Serializable$set('versionDisplayName', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3EnvironmentVersionConfigStaleFlowInfo> {
    return GoogleCloudDialogflowCxV3EnvironmentVersionConfigStaleFlowInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flowDisplayName', 'versionDisplayName']};
  }
}

export interface GoogleCloudDialogflowCxV3EnvironmentWebhookConfigParameters {
  webhookOverrides?: Array<GoogleCloudDialogflowCxV3Webhook>|null;
}
export class GoogleCloudDialogflowCxV3EnvironmentWebhookConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3EnvironmentWebhookConfigParameters = {}) {
    super();
    this.Serializable$set(
        'webhookOverrides',
        (parameters.webhookOverrides == null) ? (null) :
                                                (parameters.webhookOverrides));
  }

  get webhookOverrides(): Array<GoogleCloudDialogflowCxV3Webhook>|null {
    return (
        (this.Serializable$has('webhookOverrides')) ?
            (this.Serializable$get('webhookOverrides')) :
            (null));
  }

  /**
   * The list of webhooks to override for the agent environment. The webhook
   * must exist in the agent. You can override fields in `generic_web_service`
   * and `service_directory`.
   */
  set webhookOverrides(value: Array<GoogleCloudDialogflowCxV3Webhook>|null) {
    this.Serializable$set('webhookOverrides', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3EnvironmentWebhookConfig> {
    return GoogleCloudDialogflowCxV3EnvironmentWebhookConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'webhookOverrides': GoogleCloudDialogflowCxV3Webhook},
      keys: ['webhookOverrides']
    };
  }
}

export interface GoogleCloudDialogflowCxV3EventHandlerParameters {
  name?: string|null;
  event?: string|null;
  condition?: string|null;
  triggerFulfillment?: GoogleCloudDialogflowCxV3Fulfillment|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowCxV3EventHandler extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3EventHandlerParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'triggerFulfillment',
        (parameters.triggerFulfillment == null) ?
            (null) :
            (parameters.triggerFulfillment));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition to evaluate against form parameters, session parameters or
   * the `error` object. See the [conditions
   * reference](https://cloud.google.com/dialogflow/cx/docs/reference/condition).
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get event(): string|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * Required. The name of the event to handle.
   */
  set event(value: string|null) {
    this.Serializable$set('event', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this event handler.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  get triggerFulfillment(): GoogleCloudDialogflowCxV3Fulfillment|null {
    return (
        (this.Serializable$has('triggerFulfillment')) ?
            (this.Serializable$get('triggerFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to call when the event occurs. Handling webhook errors with
   * a fulfillment enabled with webhook could cause infinite loop. It is invalid
   * to specify such fulfillment for a handler handling webhooks.
   */
  set triggerFulfillment(value: GoogleCloudDialogflowCxV3Fulfillment|null) {
    this.Serializable$set('triggerFulfillment', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3EventHandler> {
    return GoogleCloudDialogflowCxV3EventHandler;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'condition', 'event', 'name', 'targetFlow', 'targetPage',
        'triggerFulfillment'
      ],
      objects: {'triggerFulfillment': GoogleCloudDialogflowCxV3Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowCxV3EventInputParameters {
  event?: string|null;
}
export class GoogleCloudDialogflowCxV3EventInput extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3EventInputParameters = {}) {
    super();
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
  }

  get event(): string|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * Name of the event.
   */
  set event(value: string|null) {
    this.Serializable$set('event', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3EventInput> {
    return GoogleCloudDialogflowCxV3EventInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['event']};
  }
}

export interface GoogleCloudDialogflowCxV3ExportAgentResponseParameters {
  agentUri?: string|null;
  agentContent?: string|null;
}
export class GoogleCloudDialogflowCxV3ExportAgentResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3ExportAgentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'agentUri',
        (parameters.agentUri == null) ? (null) : (parameters.agentUri));
    this.Serializable$set(
        'agentContent',
        (parameters.agentContent == null) ? (null) : (parameters.agentContent));
  }

  get agentContent(): string|null {
    return (
        (this.Serializable$has('agentContent')) ?
            (this.Serializable$get('agentContent')) :
            (null));
  }

  /**
   * Uncompressed raw byte content for agent.
   */
  set agentContent(value: string|null) {
    this.Serializable$set('agentContent', value);
  }

  get agentUri(): string|null {
    return (
        (this.Serializable$has('agentUri')) ?
            (this.Serializable$get('agentUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported agent. This field is populated
   * only if `agent_uri` is specified in ExportAgentRequest.
   */
  set agentUri(value: string|null) {
    this.Serializable$set('agentUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ExportAgentResponse> {
    return GoogleCloudDialogflowCxV3ExportAgentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agentContent', 'agentUri']};
  }
}

export interface GoogleCloudDialogflowCxV3ExportFlowResponseParameters {
  flowUri?: string|null;
  flowContent?: string|null;
}
export class GoogleCloudDialogflowCxV3ExportFlowResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3ExportFlowResponseParameters = {}) {
    super();
    this.Serializable$set(
        'flowUri',
        (parameters.flowUri == null) ? (null) : (parameters.flowUri));
    this.Serializable$set(
        'flowContent',
        (parameters.flowContent == null) ? (null) : (parameters.flowContent));
  }

  get flowContent(): string|null {
    return (
        (this.Serializable$has('flowContent')) ?
            (this.Serializable$get('flowContent')) :
            (null));
  }

  /**
   * Uncompressed raw byte content for flow.
   */
  set flowContent(value: string|null) {
    this.Serializable$set('flowContent', value);
  }

  get flowUri(): string|null {
    return (
        (this.Serializable$has('flowUri')) ?
            (this.Serializable$get('flowUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported flow. This field is populated
   * only if `flow_uri` is specified in ExportFlowRequest.
   */
  set flowUri(value: string|null) {
    this.Serializable$set('flowUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ExportFlowResponse> {
    return GoogleCloudDialogflowCxV3ExportFlowResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flowContent', 'flowUri']};
  }
}

export interface GoogleCloudDialogflowCxV3ExportIntentsMetadataParameters {}
export class GoogleCloudDialogflowCxV3ExportIntentsMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ExportIntentsMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ExportIntentsMetadata> {
    return GoogleCloudDialogflowCxV3ExportIntentsMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3ExportIntentsResponseParameters {
  intentsUri?: string|null;
  intentsContent?: GoogleCloudDialogflowCxV3InlineDestination|null;
}
export class GoogleCloudDialogflowCxV3ExportIntentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ExportIntentsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'intentsUri',
        (parameters.intentsUri == null) ? (null) : (parameters.intentsUri));
    this.Serializable$set(
        'intentsContent',
        (parameters.intentsContent == null) ? (null) :
                                              (parameters.intentsContent));
  }

  get intentsContent(): GoogleCloudDialogflowCxV3InlineDestination|null {
    return (
        (this.Serializable$has('intentsContent')) ?
            (this.Serializable$get('intentsContent')) :
            (null));
  }

  /**
   * Uncompressed byte content for intents. This field is populated only if
   * `intents_content_inline` is set to true in ExportIntentsRequest.
   */
  set intentsContent(value: GoogleCloudDialogflowCxV3InlineDestination|null) {
    this.Serializable$set('intentsContent', value);
  }

  get intentsUri(): string|null {
    return (
        (this.Serializable$has('intentsUri')) ?
            (this.Serializable$get('intentsUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported intents. This field is populated
   * only if `intents_uri` is specified in ExportIntentsRequest.
   */
  set intentsUri(value: string|null) {
    this.Serializable$set('intentsUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ExportIntentsResponse> {
    return GoogleCloudDialogflowCxV3ExportIntentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['intentsContent', 'intentsUri'],
      objects: {'intentsContent': GoogleCloudDialogflowCxV3InlineDestination}
    };
  }
}

export interface GoogleCloudDialogflowCxV3ExportTestCasesMetadataParameters {}
export class GoogleCloudDialogflowCxV3ExportTestCasesMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ExportTestCasesMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ExportTestCasesMetadata> {
    return GoogleCloudDialogflowCxV3ExportTestCasesMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3ExportTestCasesResponseParameters {
  gcsUri?: string|null;
  content?: string|null;
}
export class GoogleCloudDialogflowCxV3ExportTestCasesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ExportTestCasesResponseParameters = {}) {
    super();
    this.Serializable$set(
        'gcsUri', (parameters.gcsUri == null) ? (null) : (parameters.gcsUri));
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Uncompressed raw byte content for test cases.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get gcsUri(): string|null {
    return (
        (this.Serializable$has('gcsUri')) ? (this.Serializable$get('gcsUri')) :
                                            (null));
  }

  /**
   * The URI to a file containing the exported test cases. This field is
   * populated only if `gcs_uri` is specified in ExportTestCasesRequest.
   */
  set gcsUri(value: string|null) {
    this.Serializable$set('gcsUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ExportTestCasesResponse> {
    return GoogleCloudDialogflowCxV3ExportTestCasesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['content', 'gcsUri']};
  }
}

export interface GoogleCloudDialogflowCxV3FlowConflictReportParameters {
  intentDiffs?: Array<GoogleCloudDialogflowCxV3IntentConflictDiff>|null;
  entityTypesDiffs?: Array<GoogleCloudDialogflowCxV3EntityConflictDiff>|null;
  webhookDiffs?: Array<GoogleCloudDialogflowCxV3WebhookConflictDiff>|null;
}
export class GoogleCloudDialogflowCxV3FlowConflictReport extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3FlowConflictReportParameters = {}) {
    super();
    this.Serializable$set(
        'intentDiffs',
        (parameters.intentDiffs == null) ? (null) : (parameters.intentDiffs));
    this.Serializable$set(
        'entityTypesDiffs',
        (parameters.entityTypesDiffs == null) ? (null) :
                                                (parameters.entityTypesDiffs));
    this.Serializable$set(
        'webhookDiffs',
        (parameters.webhookDiffs == null) ? (null) : (parameters.webhookDiffs));
  }

  get entityTypesDiffs():
      Array<GoogleCloudDialogflowCxV3EntityConflictDiff>|null {
    return (
        (this.Serializable$has('entityTypesDiffs')) ?
            (this.Serializable$get('entityTypesDiffs')) :
            (null));
  }

  /**
   * The diffs for Entity Types with display name conflicts.
   */
  set entityTypesDiffs(value:
                           Array<GoogleCloudDialogflowCxV3EntityConflictDiff>|
                       null) {
    this.Serializable$set('entityTypesDiffs', value);
  }

  get intentDiffs(): Array<GoogleCloudDialogflowCxV3IntentConflictDiff>|null {
    return (
        (this.Serializable$has('intentDiffs')) ?
            (this.Serializable$get('intentDiffs')) :
            (null));
  }

  /**
   * The diffs for Intents with display name conflicts.
   */
  set intentDiffs(value: Array<GoogleCloudDialogflowCxV3IntentConflictDiff>|
                  null) {
    this.Serializable$set('intentDiffs', value);
  }

  get webhookDiffs(): Array<GoogleCloudDialogflowCxV3WebhookConflictDiff>|null {
    return (
        (this.Serializable$has('webhookDiffs')) ?
            (this.Serializable$get('webhookDiffs')) :
            (null));
  }

  /**
   * The diffs for Webhooks with display name conflicts.
   */
  set webhookDiffs(value: Array<GoogleCloudDialogflowCxV3WebhookConflictDiff>|
                   null) {
    this.Serializable$set('webhookDiffs', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3FlowConflictReport> {
    return GoogleCloudDialogflowCxV3FlowConflictReport;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'entityTypesDiffs': GoogleCloudDialogflowCxV3EntityConflictDiff,
        'intentDiffs': GoogleCloudDialogflowCxV3IntentConflictDiff,
        'webhookDiffs': GoogleCloudDialogflowCxV3WebhookConflictDiff
      },
      keys: ['entityTypesDiffs', 'intentDiffs', 'webhookDiffs']
    };
  }
}

export interface GoogleCloudDialogflowCxV3FormParameters {
  parameters?: Array<GoogleCloudDialogflowCxV3FormParameter>|null;
}
export class GoogleCloudDialogflowCxV3Form extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3FormParameters = {}) {
    super();
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get parameters(): Array<GoogleCloudDialogflowCxV3FormParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Parameters to collect from the user.
   */
  set parameters(value: Array<GoogleCloudDialogflowCxV3FormParameter>|null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3Form> {
    return GoogleCloudDialogflowCxV3Form;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parameters': GoogleCloudDialogflowCxV3FormParameter},
      keys: ['parameters']
    };
  }
}

export interface GoogleCloudDialogflowCxV3FormParameterParameters {
  displayName?: string|null;
  required?: boolean|null;
  entityType?: string|null;
  isList?: boolean|null;
  fillBehavior?: GoogleCloudDialogflowCxV3FormParameterFillBehavior|null;
  defaultValue?: any|null;
  redact?: boolean|null;
  advancedSettings?: GoogleCloudDialogflowCxV3AdvancedSettings|null;
}
export class GoogleCloudDialogflowCxV3FormParameter extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3FormParameterParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'required',
        (parameters.required == null) ? (null) : (parameters.required));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
    this.Serializable$set(
        'isList', (parameters.isList == null) ? (null) : (parameters.isList));
    this.Serializable$set(
        'fillBehavior',
        (parameters.fillBehavior == null) ? (null) : (parameters.fillBehavior));
    this.Serializable$set(
        'defaultValue',
        (parameters.defaultValue == null) ? (null) : (parameters.defaultValue));
    this.Serializable$set(
        'redact', (parameters.redact == null) ? (null) : (parameters.redact));
    this.Serializable$set(
        'advancedSettings',
        (parameters.advancedSettings == null) ? (null) :
                                                (parameters.advancedSettings));
  }

  get advancedSettings(): GoogleCloudDialogflowCxV3AdvancedSettings|null {
    return (
        (this.Serializable$has('advancedSettings')) ?
            (this.Serializable$get('advancedSettings')) :
            (null));
  }

  /**
   * Hierarchical advanced settings for this parameter. The settings exposed at
   * the lower level overrides the settings exposed at the higher level.
   */
  set advancedSettings(value: GoogleCloudDialogflowCxV3AdvancedSettings|null) {
    this.Serializable$set('advancedSettings', value);
  }

  get defaultValue(): any|null {
    return (
        (this.Serializable$has('defaultValue')) ?
            (this.Serializable$get('defaultValue')) :
            (null));
  }

  /**
   * The default value of an optional parameter. If the parameter is required,
   * the default value will be ignored.
   */
  set defaultValue(value: any|null) {
    this.Serializable$set('defaultValue', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the parameter, unique within the form.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * Required. The entity type of the parameter. Format:
   * `projects/-/locations/-/agents/-/entityTypes/` for system entity types (for
   * example, `projects/-/locations/-/agents/-/entityTypes/sys.date`), or
   * `projects//locations//agents//entityTypes/` for developer entity types.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get fillBehavior(): GoogleCloudDialogflowCxV3FormParameterFillBehavior|null {
    return (
        (this.Serializable$has('fillBehavior')) ?
            (this.Serializable$get('fillBehavior')) :
            (null));
  }

  /**
   * Required. Defines fill behavior for the parameter.
   */
  set fillBehavior(value: GoogleCloudDialogflowCxV3FormParameterFillBehavior|
                   null) {
    this.Serializable$set('fillBehavior', value);
  }

  get isList(): boolean|null {
    return (
        (this.Serializable$has('isList')) ? (this.Serializable$get('isList')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter represents a list of values.
   */
  set isList(value: boolean|null) {
    this.Serializable$set('isList', value);
  }

  get redact(): boolean|null {
    return (
        (this.Serializable$has('redact')) ? (this.Serializable$get('redact')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter content should be redacted in log. If
   * redaction is enabled, the parameter content will be replaced by parameter
   * name during logging. Note: the parameter content is subject to redaction if
   * either parameter level redaction or entity type level redaction is enabled.
   */
  set redact(value: boolean|null) {
    this.Serializable$set('redact', value);
  }

  get required(): boolean|null {
    return (
        (this.Serializable$has('required')) ?
            (this.Serializable$get('required')) :
            (null));
  }

  /**
   * Indicates whether the parameter is required. Optional parameters will not
   * trigger prompts; however, they are filled if the user specifies them.
   * Required parameters must be filled before form filling concludes.
   */
  set required(value: boolean|null) {
    this.Serializable$set('required', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3FormParameter> {
    return GoogleCloudDialogflowCxV3FormParameter;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'advancedSettings', 'defaultValue', 'displayName', 'entityType',
        'fillBehavior', 'isList', 'redact', 'required'
      ],
      objects: {
        'advancedSettings': GoogleCloudDialogflowCxV3AdvancedSettings,
        'fillBehavior': GoogleCloudDialogflowCxV3FormParameterFillBehavior
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3FormParameterFillBehaviorParameters {
  initialPromptFulfillment?: GoogleCloudDialogflowCxV3Fulfillment|null;
  repromptEventHandlers?: Array<GoogleCloudDialogflowCxV3EventHandler>|null;
}
export class GoogleCloudDialogflowCxV3FormParameterFillBehavior extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3FormParameterFillBehaviorParameters = {}) {
    super();
    this.Serializable$set(
        'initialPromptFulfillment',
        (parameters.initialPromptFulfillment == null) ?
            (null) :
            (parameters.initialPromptFulfillment));
    this.Serializable$set(
        'repromptEventHandlers',
        (parameters.repromptEventHandlers == null) ?
            (null) :
            (parameters.repromptEventHandlers));
  }

  get initialPromptFulfillment(): GoogleCloudDialogflowCxV3Fulfillment|null {
    return (
        (this.Serializable$has('initialPromptFulfillment')) ?
            (this.Serializable$get('initialPromptFulfillment')) :
            (null));
  }

  /**
   * Required. The fulfillment to provide the initial prompt that the agent can
   * present to the user in order to fill the parameter.
   */
  set initialPromptFulfillment(value: GoogleCloudDialogflowCxV3Fulfillment|
                               null) {
    this.Serializable$set('initialPromptFulfillment', value);
  }

  get repromptEventHandlers():
      Array<GoogleCloudDialogflowCxV3EventHandler>|null {
    return (
        (this.Serializable$has('repromptEventHandlers')) ?
            (this.Serializable$get('repromptEventHandlers')) :
            (null));
  }

  /**
   * The handlers for parameter-level events, used to provide reprompt for the
   * parameter or transition to a different page/flow. The supported events are:
   * * `sys.no-match-`, where N can be from 1 to 6 * `sys.no-match-default` *
   * `sys.no-input-`, where N can be from 1 to 6 * `sys.no-input-default` *
   * `sys.invalid-parameter` `initial_prompt_fulfillment` provides the first
   * prompt for the parameter. If the user's response does not fill the
   * parameter, a no-match/no-input event will be triggered, and the fulfillment
   * associated with the `sys.no-match-1`/`sys.no-input-1` handler (if defined)
   * will be called to provide a prompt. The `sys.no-match-2`/`sys.no-input-2`
   * handler (if defined) will respond to the next no-match/no-input event, and
   * so on. A `sys.no-match-default` or `sys.no-input-default` handler will be
   * used to handle all following no-match/no-input events after all numbered
   * no-match/no-input handlers for the parameter are consumed. A
   * `sys.invalid-parameter` handler can be defined to handle the case where the
   * parameter values have been `invalidated` by webhook. For example, if the
   * user's response fill the parameter, however the parameter was invalidated
   * by webhook, the fulfillment associated with the `sys.invalid-parameter`
   * handler (if defined) will be called to provide a prompt. If the event
   * handler for the corresponding event can't be found on the parameter,
   * `initial_prompt_fulfillment` will be re-prompted.
   */
  set repromptEventHandlers(value: Array<GoogleCloudDialogflowCxV3EventHandler>|
                            null) {
    this.Serializable$set('repromptEventHandlers', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3FormParameterFillBehavior> {
    return GoogleCloudDialogflowCxV3FormParameterFillBehavior;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'repromptEventHandlers': GoogleCloudDialogflowCxV3EventHandler},
      keys: ['initialPromptFulfillment', 'repromptEventHandlers'],
      objects:
          {'initialPromptFulfillment': GoogleCloudDialogflowCxV3Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowCxV3FulfillmentParameters {
  messages?: Array<GoogleCloudDialogflowCxV3ResponseMessage>|null;
  webhook?: string|null;
  returnPartialResponses?: boolean|null;
  tag?: string|null;
  setParameterActions?:
      Array<GoogleCloudDialogflowCxV3FulfillmentSetParameterAction>|null;
  conditionalCases?:
      Array<GoogleCloudDialogflowCxV3FulfillmentConditionalCases>|null;
  playbackInterruptionSettings?:
      GoogleCloudDialogflowCxV3PlaybackInterruptionSettings|null;
  advancedSettings?: GoogleCloudDialogflowCxV3AdvancedSettings|null;
  webhookInputParameters?: Array<GoogleCloudDialogflowCxV3ParameterDeclaration>|
      null;
  webhookOutputParameters?:
      Array<GoogleCloudDialogflowCxV3ParameterDeclaration>|null;
  webhookSettings?: GoogleCloudDialogflowCxV3WebhookSettings|null;
}
export class GoogleCloudDialogflowCxV3Fulfillment extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3FulfillmentParameters = {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'webhook',
        (parameters.webhook == null) ? (null) : (parameters.webhook));
    this.Serializable$set(
        'returnPartialResponses',
        (parameters.returnPartialResponses == null) ?
            (null) :
            (parameters.returnPartialResponses));
    this.Serializable$set(
        'tag', (parameters.tag == null) ? (null) : (parameters.tag));
    this.Serializable$set(
        'setParameterActions',
        (parameters.setParameterActions == null) ?
            (null) :
            (parameters.setParameterActions));
    this.Serializable$set(
        'conditionalCases',
        (parameters.conditionalCases == null) ? (null) :
                                                (parameters.conditionalCases));
    this.Serializable$set(
        'playbackInterruptionSettings',
        (parameters.playbackInterruptionSettings == null) ?
            (null) :
            (parameters.playbackInterruptionSettings));
    this.Serializable$set(
        'advancedSettings',
        (parameters.advancedSettings == null) ? (null) :
                                                (parameters.advancedSettings));
    this.Serializable$set(
        'webhookInputParameters',
        (parameters.webhookInputParameters == null) ?
            (null) :
            (parameters.webhookInputParameters));
    this.Serializable$set(
        'webhookOutputParameters',
        (parameters.webhookOutputParameters == null) ?
            (null) :
            (parameters.webhookOutputParameters));
    this.Serializable$set(
        'webhookSettings',
        (parameters.webhookSettings == null) ? (null) :
                                               (parameters.webhookSettings));
  }

  get advancedSettings(): GoogleCloudDialogflowCxV3AdvancedSettings|null {
    return (
        (this.Serializable$has('advancedSettings')) ?
            (this.Serializable$get('advancedSettings')) :
            (null));
  }

  /**
   * Hierarchical advanced settings for this fulfillment. The settings exposed
   * at the lower level overrides the settings exposed at the higher level.
   */
  set advancedSettings(value: GoogleCloudDialogflowCxV3AdvancedSettings|null) {
    this.Serializable$set('advancedSettings', value);
  }

  get conditionalCases():
      Array<GoogleCloudDialogflowCxV3FulfillmentConditionalCases>|null {
    return (
        (this.Serializable$has('conditionalCases')) ?
            (this.Serializable$get('conditionalCases')) :
            (null));
  }

  /**
   * Conditional cases for this fulfillment.
   */
  set conditionalCases(
      value: Array<GoogleCloudDialogflowCxV3FulfillmentConditionalCases>|null) {
    this.Serializable$set('conditionalCases', value);
  }

  get messages(): Array<GoogleCloudDialogflowCxV3ResponseMessage>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The list of rich message responses to present to the user.
   */
  set messages(value: Array<GoogleCloudDialogflowCxV3ResponseMessage>|null) {
    this.Serializable$set('messages', value);
  }

  get playbackInterruptionSettings():
      GoogleCloudDialogflowCxV3PlaybackInterruptionSettings|null {
    return (
        (this.Serializable$has('playbackInterruptionSettings')) ?
            (this.Serializable$get('playbackInterruptionSettings')) :
            (null));
  }

  /**
   * Whether to allow interruption on the playback of a message. If empty, flow
   * level settings will be used.
   */
  set playbackInterruptionSettings(
      value: GoogleCloudDialogflowCxV3PlaybackInterruptionSettings|null) {
    this.Serializable$set('playbackInterruptionSettings', value);
  }

  get returnPartialResponses(): boolean|null {
    return (
        (this.Serializable$has('returnPartialResponses')) ?
            (this.Serializable$get('returnPartialResponses')) :
            (null));
  }

  /**
   * Whether Dialogflow should return currently queued fulfillment response
   * messages in streaming APIs. If a webhook is specified, it happens before
   * Dialogflow invokes webhook. Warning: 1) This flag only affects streaming
   * API. Responses are still queued and returned once in non-streaming API. 2)
   * The flag can be enabled in any fulfillment but only the first 3 partial
   * responses will be returned. You may only want to apply it to fulfillments
   * that have slow webhooks.
   */
  set returnPartialResponses(value: boolean|null) {
    this.Serializable$set('returnPartialResponses', value);
  }

  get setParameterActions():
      Array<GoogleCloudDialogflowCxV3FulfillmentSetParameterAction>|null {
    return (
        (this.Serializable$has('setParameterActions')) ?
            (this.Serializable$get('setParameterActions')) :
            (null));
  }

  /**
   * Set parameter values before executing the webhook.
   */
  set setParameterActions(
      value: Array<GoogleCloudDialogflowCxV3FulfillmentSetParameterAction>|
      null) {
    this.Serializable$set('setParameterActions', value);
  }

  get tag(): string|null {
    return (
        (this.Serializable$has('tag')) ? (this.Serializable$get('tag')) :
                                         (null));
  }

  /**
   * The value of this field will be populated in the WebhookRequest
   * `fulfillmentInfo.tag` field by Dialogflow when the associated webhook is
   * called. The tag is typically used by the webhook service to identify which
   * fulfillment is being called, but it could be used for other purposes. This
   * field is required if `webhook` is specified.
   */
  set tag(value: string|null) {
    this.Serializable$set('tag', value);
  }

  get webhook(): string|null {
    return (
        (this.Serializable$has('webhook')) ?
            (this.Serializable$get('webhook')) :
            (null));
  }

  /**
   * The webhook to call. Format: `projects//locations//agents//webhooks/`.
   */
  set webhook(value: string|null) {
    this.Serializable$set('webhook', value);
  }

  get webhookInputParameters():
      Array<GoogleCloudDialogflowCxV3ParameterDeclaration>|null {
    return (
        (this.Serializable$has('webhookInputParameters')) ?
            (this.Serializable$get('webhookInputParameters')) :
            (null));
  }

  /**
   * Defines what parameters can be sent to the webhook. These should be a
   * subset of the parameter names defined in the agent. If enforcement is true,
   * only session parameters that are declared here can be sent to the webhook.
   */
  set webhookInputParameters(
      value: Array<GoogleCloudDialogflowCxV3ParameterDeclaration>|null) {
    this.Serializable$set('webhookInputParameters', value);
  }

  get webhookOutputParameters():
      Array<GoogleCloudDialogflowCxV3ParameterDeclaration>|null {
    return (
        (this.Serializable$has('webhookOutputParameters')) ?
            (this.Serializable$get('webhookOutputParameters')) :
            (null));
  }

  /**
   * Defines what parameters can be sent by the webhook via
   * `session_info.parameters`. These should be a subset of the parameter names
   * defined in the agent. If enforcement is true, only parameters defined here
   * can be modifed by the webhook.
   */
  set webhookOutputParameters(
      value: Array<GoogleCloudDialogflowCxV3ParameterDeclaration>|null) {
    this.Serializable$set('webhookOutputParameters', value);
  }

  get webhookSettings(): GoogleCloudDialogflowCxV3WebhookSettings|null {
    return (
        (this.Serializable$has('webhookSettings')) ?
            (this.Serializable$get('webhookSettings')) :
            (null));
  }

  /**
   * Hierarchical webhook settings for this fulfillment. The settings exposed at
   * the lower level overrides the settings exposed at the higher level.
   */
  set webhookSettings(value: GoogleCloudDialogflowCxV3WebhookSettings|null) {
    this.Serializable$set('webhookSettings', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3Fulfillment> {
    return GoogleCloudDialogflowCxV3Fulfillment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'conditionalCases':
            GoogleCloudDialogflowCxV3FulfillmentConditionalCases,
        'messages': GoogleCloudDialogflowCxV3ResponseMessage,
        'setParameterActions':
            GoogleCloudDialogflowCxV3FulfillmentSetParameterAction,
        'webhookInputParameters': GoogleCloudDialogflowCxV3ParameterDeclaration,
        'webhookOutputParameters': GoogleCloudDialogflowCxV3ParameterDeclaration
      },
      keys: [
        'advancedSettings', 'conditionalCases', 'messages',
        'playbackInterruptionSettings', 'returnPartialResponses',
        'setParameterActions', 'tag', 'webhook', 'webhookInputParameters',
        'webhookOutputParameters', 'webhookSettings'
      ],
      objects: {
        'advancedSettings': GoogleCloudDialogflowCxV3AdvancedSettings,
        'playbackInterruptionSettings':
            GoogleCloudDialogflowCxV3PlaybackInterruptionSettings,
        'webhookSettings': GoogleCloudDialogflowCxV3WebhookSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3FulfillmentConditionalCasesParameters {
  cases?: Array<GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCase>|null;
}
export class GoogleCloudDialogflowCxV3FulfillmentConditionalCases extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3FulfillmentConditionalCasesParameters = {}) {
    super();
    this.Serializable$set(
        'cases', (parameters.cases == null) ? (null) : (parameters.cases));
  }

  get cases():
      Array<GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCase>|null {
    return (
        (this.Serializable$has('cases')) ? (this.Serializable$get('cases')) :
                                           (null));
  }

  /**
   * A list of cascading if-else conditions.
   */
  set cases(value:
                Array<GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCase>|
            null) {
    this.Serializable$set('cases', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3FulfillmentConditionalCases> {
    return GoogleCloudDialogflowCxV3FulfillmentConditionalCases;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'cases': GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCase},
      keys: ['cases']
    };
  }
}

export interface GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseParameters {
  condition?: string|null;
  caseContent?: Array<
      GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseCaseContent>|null;
}
export class GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCase extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseParameters =
              {}) {
    super();
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'caseContent',
        (parameters.caseContent == null) ? (null) : (parameters.caseContent));
  }

  get caseContent(): Array<
      GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseCaseContent>|
      null {
    return (
        (this.Serializable$has('caseContent')) ?
            (this.Serializable$get('caseContent')) :
            (null));
  }

  /**
   * A list of case content.
   */
  set caseContent(
      value: Array<
          GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseCaseContent>|
      null) {
    this.Serializable$set('caseContent', value);
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition to activate and select this case. Empty means the condition
   * is always true. The condition is evaluated against form parameters or
   * session parameters. See the [conditions
   * reference](https://cloud.google.com/dialogflow/cx/docs/reference/condition).
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCase> {
    return GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'caseContent':
            GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseCaseContent
      },
      keys: ['caseContent', 'condition']
    };
  }
}

export interface GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseCaseContentParameters {
  message?: GoogleCloudDialogflowCxV3ResponseMessage|null;
  additionalCases?: GoogleCloudDialogflowCxV3FulfillmentConditionalCases|null;
}
export class
    GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseCaseContent extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseCaseContentParameters =
              {}) {
    super();
    this.Serializable$set(
        'message',
        (parameters.message == null) ? (null) : (parameters.message));
    this.Serializable$set(
        'additionalCases',
        (parameters.additionalCases == null) ? (null) :
                                               (parameters.additionalCases));
  }

  get additionalCases(): GoogleCloudDialogflowCxV3FulfillmentConditionalCases
      |null {
    return (
        (this.Serializable$has('additionalCases')) ?
            (this.Serializable$get('additionalCases')) :
            (null));
  }

  /**
   * Additional cases to be evaluated.
   */
  set additionalCases(value:
                          GoogleCloudDialogflowCxV3FulfillmentConditionalCases|
                      null) {
    this.Serializable$set('additionalCases', value);
  }

  get message(): GoogleCloudDialogflowCxV3ResponseMessage|null {
    return (
        (this.Serializable$has('message')) ?
            (this.Serializable$get('message')) :
            (null));
  }

  /**
   * Returned message.
   */
  set message(value: GoogleCloudDialogflowCxV3ResponseMessage|null) {
    this.Serializable$set('message', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseCaseContent> {
    return GoogleCloudDialogflowCxV3FulfillmentConditionalCasesCaseCaseContent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['additionalCases', 'message'],
      objects: {
        'additionalCases': GoogleCloudDialogflowCxV3FulfillmentConditionalCases,
        'message': GoogleCloudDialogflowCxV3ResponseMessage
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3FulfillmentSetParameterActionParameters {
  parameter?: string|null;
  value?: any|null;
}
export class GoogleCloudDialogflowCxV3FulfillmentSetParameterAction extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3FulfillmentSetParameterActionParameters =
              {}) {
    super();
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * Display name of the parameter.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  get value(): any|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * The new value of the parameter. A null value clears the parameter.
   */
  set value(value: any|null) {
    this.Serializable$set('value', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3FulfillmentSetParameterAction> {
    return GoogleCloudDialogflowCxV3FulfillmentSetParameterAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['parameter', 'value']};
  }
}

export interface GoogleCloudDialogflowCxV3GcsDestinationParameters {
  uri?: string|null;
}
export class GoogleCloudDialogflowCxV3GcsDestination extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3GcsDestinationParameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The Google Cloud Storage URI for the exported objects. A URI is
   * of the form: gs://bucket/object-name-or-prefix Whether a full object name,
   * or just a prefix, its usage depends on the Dialogflow operation.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3GcsDestination> {
    return GoogleCloudDialogflowCxV3GcsDestination;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface GoogleCloudDialogflowCxV3GenerateImportConflictReportMetadataParameters {
}
export class
    GoogleCloudDialogflowCxV3GenerateImportConflictReportMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3GenerateImportConflictReportMetadataParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3GenerateImportConflictReportMetadata> {
    return GoogleCloudDialogflowCxV3GenerateImportConflictReportMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3GenerateImportConflictReportResponseParameters {
  flowConflictReport?: GoogleCloudDialogflowCxV3FlowConflictReport|null;
}
export class
    GoogleCloudDialogflowCxV3GenerateImportConflictReportResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3GenerateImportConflictReportResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'flowConflictReport',
        (parameters.flowConflictReport == null) ?
            (null) :
            (parameters.flowConflictReport));
  }

  get flowConflictReport(): GoogleCloudDialogflowCxV3FlowConflictReport|null {
    return (
        (this.Serializable$has('flowConflictReport')) ?
            (this.Serializable$get('flowConflictReport')) :
            (null));
  }

  /**
   * Conflict report for flow imports. Returned when
   * [GenerateImportConflictReportRequest.resource_import_request] is
   * import_flow_request.
   */
  set flowConflictReport(value: GoogleCloudDialogflowCxV3FlowConflictReport|
                         null) {
    this.Serializable$set('flowConflictReport', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3GenerateImportConflictReportResponse> {
    return GoogleCloudDialogflowCxV3GenerateImportConflictReportResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['flowConflictReport'],
      objects:
          {'flowConflictReport': GoogleCloudDialogflowCxV3FlowConflictReport}
    };
  }
}

export interface GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataParameters {
  state?: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState|null;
}
export class GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
  }

  static get State():
      IGoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataStateEnum {
    return GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataStateEnum;
  }

  get state(): GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState
      |null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Required. Output only. The current state of this operation.
   */
  set state(value:
                GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataState|
            null) {
    this.Serializable$set('state', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata> {
    return GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state':
            GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadataStateEnum
      },
      keys: ['state']
    };
  }
}

export interface GoogleCloudDialogflowCxV3ImportAgentResponseParameters {
  agent?: string|null;
}
export class GoogleCloudDialogflowCxV3ImportAgentResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3ImportAgentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'agent', (parameters.agent == null) ? (null) : (parameters.agent));
  }

  get agent(): string|null {
    return (
        (this.Serializable$has('agent')) ? (this.Serializable$get('agent')) :
                                           (null));
  }

  /**
   * The unique identifier of the new agent. Format:
   * `projects//locations//agents/`.
   */
  set agent(value: string|null) {
    this.Serializable$set('agent', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ImportAgentResponse> {
    return GoogleCloudDialogflowCxV3ImportAgentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agent']};
  }
}

export interface GoogleCloudDialogflowCxV3ImportDocumentsOperationMetadataParameters {
  genericMetadata?: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|
      null;
}
export class GoogleCloudDialogflowCxV3ImportDocumentsOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ImportDocumentsOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3ImportDocumentsOperationMetadata> {
    return GoogleCloudDialogflowCxV3ImportDocumentsOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3ImportDocumentsResponseParameters {
  warnings?: Array<GoogleRpcStatus>|null;
}
export class GoogleCloudDialogflowCxV3ImportDocumentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ImportDocumentsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'warnings',
        (parameters.warnings == null) ? (null) : (parameters.warnings));
  }

  get warnings(): Array<GoogleRpcStatus>|null {
    return (
        (this.Serializable$has('warnings')) ?
            (this.Serializable$get('warnings')) :
            (null));
  }

  /**
   * Includes details about skipped documents or any other warnings.
   */
  set warnings(value: Array<GoogleRpcStatus>|null) {
    this.Serializable$set('warnings', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ImportDocumentsResponse> {
    return GoogleCloudDialogflowCxV3ImportDocumentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'warnings': GoogleRpcStatus}, keys: ['warnings']};
  }
}

export interface GoogleCloudDialogflowCxV3ImportFlowResponseParameters {
  flow?: string|null;
}
export class GoogleCloudDialogflowCxV3ImportFlowResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3ImportFlowResponseParameters = {}) {
    super();
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
  }

  get flow(): string|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * The unique identifier of the new flow. Format:
   * `projects//locations//agents//flows/`.
   */
  set flow(value: string|null) {
    this.Serializable$set('flow', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ImportFlowResponse> {
    return GoogleCloudDialogflowCxV3ImportFlowResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flow']};
  }
}

export interface GoogleCloudDialogflowCxV3ImportIntentsMetadataParameters {}
export class GoogleCloudDialogflowCxV3ImportIntentsMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ImportIntentsMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ImportIntentsMetadata> {
    return GoogleCloudDialogflowCxV3ImportIntentsMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3ImportIntentsResponseParameters {
  intents?: Array<string>|null;
}
export class GoogleCloudDialogflowCxV3ImportIntentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ImportIntentsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
  }

  get intents(): Array<string>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * The unique identifier of the imported intents. Format:
   * `projects//locations//agents//intents/`.
   */
  set intents(value: Array<string>|null) {
    this.Serializable$set('intents', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ImportIntentsResponse> {
    return GoogleCloudDialogflowCxV3ImportIntentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intents']};
  }
}

export interface GoogleCloudDialogflowCxV3ImportTestCasesMetadataParameters {
  errors?: Array<GoogleCloudDialogflowCxV3TestCaseError>|null;
}
export class GoogleCloudDialogflowCxV3ImportTestCasesMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ImportTestCasesMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'errors', (parameters.errors == null) ? (null) : (parameters.errors));
  }

  get errors(): Array<GoogleCloudDialogflowCxV3TestCaseError>|null {
    return (
        (this.Serializable$has('errors')) ? (this.Serializable$get('errors')) :
                                            (null));
  }

  /**
   * Errors for failed test cases.
   */
  set errors(value: Array<GoogleCloudDialogflowCxV3TestCaseError>|null) {
    this.Serializable$set('errors', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ImportTestCasesMetadata> {
    return GoogleCloudDialogflowCxV3ImportTestCasesMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'errors': GoogleCloudDialogflowCxV3TestCaseError},
      keys: ['errors']
    };
  }
}

export interface GoogleCloudDialogflowCxV3ImportTestCasesResponseParameters {
  names?: Array<string>|null;
}
export class GoogleCloudDialogflowCxV3ImportTestCasesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ImportTestCasesResponseParameters = {}) {
    super();
    this.Serializable$set(
        'names', (parameters.names == null) ? (null) : (parameters.names));
  }

  get names(): Array<string>|null {
    return (
        (this.Serializable$has('names')) ? (this.Serializable$get('names')) :
                                           (null));
  }

  /**
   * The unique identifiers of the new test cases. Format:
   * `projects//locations//agents//testCases/`.
   */
  set names(value: Array<string>|null) {
    this.Serializable$set('names', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ImportTestCasesResponse> {
    return GoogleCloudDialogflowCxV3ImportTestCasesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['names']};
  }
}

export interface GoogleCloudDialogflowCxV3InlineDestinationParameters {
  content?: string|null;
}
export class GoogleCloudDialogflowCxV3InlineDestination extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3InlineDestinationParameters = {}) {
    super();
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Output only. The uncompressed byte content for the objects. Only populated
   * in responses.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3InlineDestination> {
    return GoogleCloudDialogflowCxV3InlineDestination;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['content']};
  }
}

export interface GoogleCloudDialogflowCxV3InputAudioConfigParameters {
  audioEncoding?: GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding|null;
  sampleRateHertz?: number|null;
  languageCode?: string|null;
  enableWordInfo?: boolean|null;
  audioChannelCount?: number|null;
  phraseHints?: Array<string>|null;
  speechContexts?: Array<GoogleCloudDialogflowCxV3SpeechContext>|null;
  model?: string|null;
  modelVariant?: GoogleCloudDialogflowCxV3InputAudioConfigModelVariant|null;
  singleUtterance?: boolean|null;
  disableNoSpeechRecognizedEvent?: boolean|null;
  bargeInConfig?: GoogleCloudDialogflowCxV3BargeInConfig|null;
  defaultNoSpeechTimeout?: string|null;
  alternativeLanguageCodes?: Array<string>|null;
  phraseSet?: Array<string>|null;
  audioStreamInfo?: GoogleCloudDialogflowCxV3InputAudioConfigAudioStreamInfo|
      null;
}
export class GoogleCloudDialogflowCxV3InputAudioConfig extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3InputAudioConfigParameters = {}) {
    super();
    this.Serializable$set(
        'audioEncoding',
        (parameters.audioEncoding == null) ? (null) :
                                             (parameters.audioEncoding));
    this.Serializable$set(
        'sampleRateHertz',
        (parameters.sampleRateHertz == null) ? (null) :
                                               (parameters.sampleRateHertz));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'enableWordInfo',
        (parameters.enableWordInfo == null) ? (null) :
                                              (parameters.enableWordInfo));
    this.Serializable$set(
        'audioChannelCount',
        (parameters.audioChannelCount == null) ?
            (null) :
            (parameters.audioChannelCount));
    this.Serializable$set(
        'phraseHints',
        (parameters.phraseHints == null) ? (null) : (parameters.phraseHints));
    this.Serializable$set(
        'speechContexts',
        (parameters.speechContexts == null) ? (null) :
                                              (parameters.speechContexts));
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'modelVariant',
        (parameters.modelVariant == null) ? (null) : (parameters.modelVariant));
    this.Serializable$set(
        'singleUtterance',
        (parameters.singleUtterance == null) ? (null) :
                                               (parameters.singleUtterance));
    this.Serializable$set(
        'disableNoSpeechRecognizedEvent',
        (parameters.disableNoSpeechRecognizedEvent == null) ?
            (null) :
            (parameters.disableNoSpeechRecognizedEvent));
    this.Serializable$set(
        'bargeInConfig',
        (parameters.bargeInConfig == null) ? (null) :
                                             (parameters.bargeInConfig));
    this.Serializable$set(
        'defaultNoSpeechTimeout',
        (parameters.defaultNoSpeechTimeout == null) ?
            (null) :
            (parameters.defaultNoSpeechTimeout));
    this.Serializable$set(
        'alternativeLanguageCodes',
        (parameters.alternativeLanguageCodes == null) ?
            (null) :
            (parameters.alternativeLanguageCodes));
    this.Serializable$set(
        'phraseSet',
        (parameters.phraseSet == null) ? (null) : (parameters.phraseSet));
    this.Serializable$set(
        'audioStreamInfo',
        (parameters.audioStreamInfo == null) ? (null) :
                                               (parameters.audioStreamInfo));
  }

  static get AudioEncoding():
      IGoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum {
    return GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum;
  }

  static get ModelVariant():
      IGoogleCloudDialogflowCxV3InputAudioConfigModelVariantEnum {
    return GoogleCloudDialogflowCxV3InputAudioConfigModelVariantEnum;
  }

  get alternativeLanguageCodes(): Array<string>|null {
    return (
        (this.Serializable$has('alternativeLanguageCodes')) ?
            (this.Serializable$get('alternativeLanguageCodes')) :
            (null));
  }

  /**
   * A list of up to 3 additional
   * [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt) language tags,
   * listing possible alternative languages of the supplied audio. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes. If alternative languages
   * are listed, recognition result will contain recognition in the most likely
   * language detected including the main language_code. The recognition result
   * will include the language tag of the language detected in the audio. Extra
   * language codes will be ignored.
   */
  set alternativeLanguageCodes(value: Array<string>|null) {
    this.Serializable$set('alternativeLanguageCodes', value);
  }

  get audioChannelCount(): number|null {
    return (
        (this.Serializable$has('audioChannelCount')) ?
            (this.Serializable$get('audioChannelCount')) :
            (null));
  }

  /**
   * Optional. The number of channels in the input audio data. Valid values for
   * LINEAR16 and FLAC are `1`-`8`. Valid values for MULAW, AMR and AMR_WB are
   * only `1`. If `0` or omitted, defaults to one channel (mono). NOTE:
   * Currently, we perform recognition only on channel 0 and ignore all other
   * channels.
   */
  set audioChannelCount(value: number|null) {
    this.Serializable$set('audioChannelCount', value);
  }

  get audioEncoding(): GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding
      |null {
    return (
        (this.Serializable$has('audioEncoding')) ?
            (this.Serializable$get('audioEncoding')) :
            (null));
  }

  /**
   * Required. Audio encoding of the audio content to process.
   */
  set audioEncoding(value:
                        GoogleCloudDialogflowCxV3InputAudioConfigAudioEncoding|
                    null) {
    this.Serializable$set('audioEncoding', value);
  }

  get audioStreamInfo():
      GoogleCloudDialogflowCxV3InputAudioConfigAudioStreamInfo|null {
    return (
        (this.Serializable$has('audioStreamInfo')) ?
            (this.Serializable$get('audioStreamInfo')) :
            (null));
  }

  /**
   * Output only. Info on the audio stream.
   */
  set audioStreamInfo(
      value: GoogleCloudDialogflowCxV3InputAudioConfigAudioStreamInfo|null) {
    this.Serializable$set('audioStreamInfo', value);
  }

  get bargeInConfig(): GoogleCloudDialogflowCxV3BargeInConfig|null {
    return (
        (this.Serializable$has('bargeInConfig')) ?
            (this.Serializable$get('bargeInConfig')) :
            (null));
  }

  /**
   * Configuration of barge-in behavior during the streaming of input audio.
   */
  set bargeInConfig(value: GoogleCloudDialogflowCxV3BargeInConfig|null) {
    this.Serializable$set('bargeInConfig', value);
  }

  get defaultNoSpeechTimeout(): string|null {
    return (
        (this.Serializable$has('defaultNoSpeechTimeout')) ?
            (this.Serializable$get('defaultNoSpeechTimeout')) :
            (null));
  }

  /**
   * If set, use this no-speech timeout when the agent does not provide a
   * no-speech timeout itself.
   */
  set defaultNoSpeechTimeout(value: string|null) {
    this.Serializable$set('defaultNoSpeechTimeout', value);
  }

  get disableNoSpeechRecognizedEvent(): boolean|null {
    return (
        (this.Serializable$has('disableNoSpeechRecognizedEvent')) ?
            (this.Serializable$get('disableNoSpeechRecognizedEvent')) :
            (null));
  }

  /**
   * Only used in Participants.AnalyzeContent and
   * Participants.StreamingAnalyzeContent. If `false` and recognition doesn't
   * return any result, trigger `NO_SPEECH_RECOGNIZED` event to Dialogflow
   * agent.
   */
  set disableNoSpeechRecognizedEvent(value: boolean|null) {
    this.Serializable$set('disableNoSpeechRecognizedEvent', value);
  }

  get enableWordInfo(): boolean|null {
    return (
        (this.Serializable$has('enableWordInfo')) ?
            (this.Serializable$get('enableWordInfo')) :
            (null));
  }

  /**
   * Optional. If `true`, Dialogflow returns SpeechWordInfo in
   * StreamingRecognitionResult with information about the recognized speech
   * words, e.g. start and end time offsets. If false or unspecified, Speech
   * doesn't return any word-level information.
   */
  set enableWordInfo(value: boolean|null) {
    this.Serializable$set('enableWordInfo', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Only populated internally in Dialogflow API server to ease speech
   * implementation. Copied from `query_input.language_code`.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Optional. Which Speech model to select for the given request. Select the
   * model best suited to your domain to get best results. If a model is not
   * explicitly specified, then we auto-select a model based on the parameters
   * in the InputAudioConfig. If enhanced speech model is enabled for the agent
   * and an enhanced version of the specified model for the language does not
   * exist, then the speech is recognized using the standard version of the
   * specified model. Refer to [Cloud Speech API
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#select-model)
   * for more details.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  get modelVariant(): GoogleCloudDialogflowCxV3InputAudioConfigModelVariant
      |null {
    return (
        (this.Serializable$has('modelVariant')) ?
            (this.Serializable$get('modelVariant')) :
            (null));
  }

  /**
   * Optional. Which variant of the Speech model to use.
   */
  set modelVariant(value: GoogleCloudDialogflowCxV3InputAudioConfigModelVariant|
                   null) {
    this.Serializable$set('modelVariant', value);
  }

  get phraseHints(): Array<string>|null {
    return (
        (this.Serializable$has('phraseHints')) ?
            (this.Serializable$get('phraseHints')) :
            (null));
  }

  /**
   * Optional. A list of strings containing words and phrases that the speech
   * recognizer should recognize with higher likelihood. See [the Cloud Speech
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#phrase-hints)
   * for more details.
   */
  set phraseHints(value: Array<string>|null) {
    this.Serializable$set('phraseHints', value);
  }

  get phraseSet(): Array<string>|null {
    return (
        (this.Serializable$has('phraseSet')) ?
            (this.Serializable$get('phraseSet')) :
            (null));
  }

  /**
   * A collection of phrase set resources to use for speech adaptation.
   */
  set phraseSet(value: Array<string>|null) {
    this.Serializable$set('phraseSet', value);
  }

  get sampleRateHertz(): number|null {
    return (
        (this.Serializable$has('sampleRateHertz')) ?
            (this.Serializable$get('sampleRateHertz')) :
            (null));
  }

  /**
   * Sample rate (in Hertz) of the audio content sent in the query. Refer to
   * [Cloud Speech API
   * documentation](https://cloud.google.com/speech-to-text/docs/basics) for
   * more details.
   */
  set sampleRateHertz(value: number|null) {
    this.Serializable$set('sampleRateHertz', value);
  }

  get singleUtterance(): boolean|null {
    return (
        (this.Serializable$has('singleUtterance')) ?
            (this.Serializable$get('singleUtterance')) :
            (null));
  }

  /**
   * Optional. If `false` (default), recognition does not cease until the client
   * closes the stream. If `true`, the recognizer will detect a single spoken
   * utterance in input audio. Recognition ceases when it detects the audio's
   * voice has stopped or paused. In this case, once a detected intent is
   * received, the client should close the stream and start a new request with a
   * new stream as needed. Note: This setting is relevant only for streaming
   * methods.
   */
  set singleUtterance(value: boolean|null) {
    this.Serializable$set('singleUtterance', value);
  }

  get speechContexts(): Array<GoogleCloudDialogflowCxV3SpeechContext>|null {
    return (
        (this.Serializable$has('speechContexts')) ?
            (this.Serializable$get('speechContexts')) :
            (null));
  }

  /**
   * Optional. Context information to assist speech recognition. See [the Cloud
   * Speech
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#phrase-hints)
   * for more details.
   */
  set speechContexts(value: Array<GoogleCloudDialogflowCxV3SpeechContext>|
                     null) {
    this.Serializable$set('speechContexts', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3InputAudioConfig> {
    return GoogleCloudDialogflowCxV3InputAudioConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'speechContexts': GoogleCloudDialogflowCxV3SpeechContext},
      enums: {
        'audioEncoding':
            GoogleCloudDialogflowCxV3InputAudioConfigAudioEncodingEnum,
        'modelVariant':
            GoogleCloudDialogflowCxV3InputAudioConfigModelVariantEnum
      },
      keys: [
        'alternativeLanguageCodes', 'audioChannelCount', 'audioEncoding',
        'audioStreamInfo', 'bargeInConfig', 'defaultNoSpeechTimeout',
        'disableNoSpeechRecognizedEvent', 'enableWordInfo', 'languageCode',
        'model', 'modelVariant', 'phraseHints', 'phraseSet', 'sampleRateHertz',
        'singleUtterance', 'speechContexts'
      ],
      objects: {
        'audioStreamInfo':
            GoogleCloudDialogflowCxV3InputAudioConfigAudioStreamInfo,
        'bargeInConfig': GoogleCloudDialogflowCxV3BargeInConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3InputAudioConfigAudioStreamInfoParameters {
  startTime?: string|null;
  detectionStartOffset?: string|null;
}
export class GoogleCloudDialogflowCxV3InputAudioConfigAudioStreamInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3InputAudioConfigAudioStreamInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'startTime',
        (parameters.startTime == null) ? (null) : (parameters.startTime));
    this.Serializable$set(
        'detectionStartOffset',
        (parameters.detectionStartOffset == null) ?
            (null) :
            (parameters.detectionStartOffset));
  }

  get detectionStartOffset(): string|null {
    return (
        (this.Serializable$has('detectionStartOffset')) ?
            (this.Serializable$get('detectionStartOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio stream that corresponds
   * to the start of the speech detection.
   */
  set detectionStartOffset(value: string|null) {
    this.Serializable$set('detectionStartOffset', value);
  }

  get startTime(): string|null {
    return (
        (this.Serializable$has('startTime')) ?
            (this.Serializable$get('startTime')) :
            (null));
  }

  /**
   * Absolute start time of the stream. It can be gRPC stream or telephony
   * stream.
   */
  set startTime(value: string|null) {
    this.Serializable$set('startTime', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3InputAudioConfigAudioStreamInfo> {
    return GoogleCloudDialogflowCxV3InputAudioConfigAudioStreamInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['detectionStartOffset', 'startTime']};
  }
}

export interface GoogleCloudDialogflowCxV3IntentParameters {
  name?: string|null;
  displayName?: string|null;
  trainingPhrases?: Array<GoogleCloudDialogflowCxV3IntentTrainingPhrase>|null;
  parameters?: Array<GoogleCloudDialogflowCxV3IntentParameter>|null;
  asrCorrections?: Array<GoogleCloudDialogflowCxV3IntentAsrCorrection>|null;
  priority?: number|null;
  isFallback?: boolean|null;
  labels?: ApiClientObjectMap<string>|null;
  description?: string|null;
  createTime?: string|null;
  updateTime?: string|null;
  clarifyingPhrase?: string|null;
}
export class GoogleCloudDialogflowCxV3Intent extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3IntentParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'trainingPhrases',
        (parameters.trainingPhrases == null) ? (null) :
                                               (parameters.trainingPhrases));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'asrCorrections',
        (parameters.asrCorrections == null) ? (null) :
                                              (parameters.asrCorrections));
    this.Serializable$set(
        'priority',
        (parameters.priority == null) ? (null) : (parameters.priority));
    this.Serializable$set(
        'isFallback',
        (parameters.isFallback == null) ? (null) : (parameters.isFallback));
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'clarifyingPhrase',
        (parameters.clarifyingPhrase == null) ? (null) :
                                                (parameters.clarifyingPhrase));
  }

  get asrCorrections():
      Array<GoogleCloudDialogflowCxV3IntentAsrCorrection>|null {
    return (
        (this.Serializable$has('asrCorrections')) ?
            (this.Serializable$get('asrCorrections')) :
            (null));
  }

  /**
   * The collection of ASR correction rules provided by developers for the
   * intent. Each rule is a phrase with ASR errors and its corrected
   * counterpart, e.g., error_phrase: \"i raven\" -> correction_phrase: \"my
   * payment\".
   */
  set asrCorrections(value: Array<GoogleCloudDialogflowCxV3IntentAsrCorrection>|
                     null) {
    this.Serializable$set('asrCorrections', value);
  }

  get clarifyingPhrase(): string|null {
    return (
        (this.Serializable$has('clarifyingPhrase')) ?
            (this.Serializable$get('clarifyingPhrase')) :
            (null));
  }

  /**
   * Human understandable phrase that can be used to clarify how to trigger this
   * intent. This field can be used in agent responses to disambiguate amongst
   * different intents. Maximum character limit: 140 characters.
   */
  set clarifyingPhrase(value: string|null) {
    this.Serializable$set('clarifyingPhrase', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The time the intent was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Human readable description for better understanding an intent like its
   * scope, content, result etc. Maximum character limit: 140 characters.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the intent, unique within the agent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get isFallback(): boolean|null {
    return (
        (this.Serializable$has('isFallback')) ?
            (this.Serializable$get('isFallback')) :
            (null));
  }

  /**
   * Indicates whether this is a fallback intent. Currently only default
   * fallback intent is allowed in the agent, which is added upon agent
   * creation. Adding training phrases to fallback intent is useful in the case
   * of requests that are mistakenly matched, since training phrases assigned to
   * fallback intents act as negative examples that triggers no-match event.
   */
  set isFallback(value: boolean|null) {
    this.Serializable$set('isFallback', value);
  }

  get labels(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * The key/value metadata to label an intent. Labels can contain lowercase
   * letters, digits and the symbols '-' and '_'. International characters are
   * allowed, including letters from unicase alphabets. Keys must start with a
   * letter. Keys and values can be no longer than 63 characters and no more
   * than 128 bytes. Prefix \"sys-\" is reserved for Dialogflow defined labels.
   * Currently allowed Dialogflow defined labels include: * sys-head *
   * sys-contextual The above labels do not require value. \"sys-head\" means
   * the intent is a head intent. \"sys.contextual\" means the intent is a
   * contextual intent.
   */
  set labels(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('labels', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the intent. Required for the Intents.UpdateIntent
   * method. Intents.CreateIntent populates the name automatically. Format:
   * `projects//locations//agents//intents/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameters(): Array<GoogleCloudDialogflowCxV3IntentParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of parameters associated with the intent.
   */
  set parameters(value: Array<GoogleCloudDialogflowCxV3IntentParameter>|null) {
    this.Serializable$set('parameters', value);
  }

  get priority(): number|null {
    return (
        (this.Serializable$has('priority')) ?
            (this.Serializable$get('priority')) :
            (null));
  }

  /**
   * The priority of this intent. Higher numbers represent higher priorities. -
   * If the supplied value is unspecified or 0, the service translates the value
   * to 500,000, which corresponds to the `Normal` priority in the console. - If
   * the supplied value is negative, the intent is ignored in runtime detect
   * intent requests.
   */
  set priority(value: number|null) {
    this.Serializable$set('priority', value);
  }

  get trainingPhrases():
      Array<GoogleCloudDialogflowCxV3IntentTrainingPhrase>|null {
    return (
        (this.Serializable$has('trainingPhrases')) ?
            (this.Serializable$get('trainingPhrases')) :
            (null));
  }

  /**
   * The collection of training phrases the agent is trained on to identify the
   * intent.
   */
  set trainingPhrases(value:
                          Array<GoogleCloudDialogflowCxV3IntentTrainingPhrase>|
                      null) {
    this.Serializable$set('trainingPhrases', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. Last time the intent was modified.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3Intent> {
    return GoogleCloudDialogflowCxV3Intent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'asrCorrections': GoogleCloudDialogflowCxV3IntentAsrCorrection,
        'parameters': GoogleCloudDialogflowCxV3IntentParameter,
        'trainingPhrases': GoogleCloudDialogflowCxV3IntentTrainingPhrase
      },
      keys: [
        'asrCorrections', 'clarifyingPhrase', 'createTime', 'description',
        'displayName', 'isFallback', 'labels', 'name', 'parameters', 'priority',
        'trainingPhrases', 'updateTime'
      ],
      objectMaps: {
        'labels': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3IntentAsrCorrectionParameters {
  errorPhrase?: string|null;
  correctionPhrase?: string|null;
}
export class GoogleCloudDialogflowCxV3IntentAsrCorrection extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3IntentAsrCorrectionParameters = {}) {
    super();
    this.Serializable$set(
        'errorPhrase',
        (parameters.errorPhrase == null) ? (null) : (parameters.errorPhrase));
    this.Serializable$set(
        'correctionPhrase',
        (parameters.correctionPhrase == null) ? (null) :
                                                (parameters.correctionPhrase));
  }

  get correctionPhrase(): string|null {
    return (
        (this.Serializable$has('correctionPhrase')) ?
            (this.Serializable$get('correctionPhrase')) :
            (null));
  }

  /**
   * The phrase with ASR errors corrected.
   */
  set correctionPhrase(value: string|null) {
    this.Serializable$set('correctionPhrase', value);
  }

  get errorPhrase(): string|null {
    return (
        (this.Serializable$has('errorPhrase')) ?
            (this.Serializable$get('errorPhrase')) :
            (null));
  }

  /**
   * The phrase with one or more ASR errors.
   */
  set errorPhrase(value: string|null) {
    this.Serializable$set('errorPhrase', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3IntentAsrCorrection> {
    return GoogleCloudDialogflowCxV3IntentAsrCorrection;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['correctionPhrase', 'errorPhrase']};
  }
}

export interface GoogleCloudDialogflowCxV3IntentConflictDiffParameters {
  displayName?: string|null;
  originalIntentId?: string|null;
  importedIntentId?: string|null;
}
export class GoogleCloudDialogflowCxV3IntentConflictDiff extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3IntentConflictDiffParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'originalIntentId',
        (parameters.originalIntentId == null) ? (null) :
                                                (parameters.originalIntentId));
    this.Serializable$set(
        'importedIntentId',
        (parameters.importedIntentId == null) ? (null) :
                                                (parameters.importedIntentId));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Conflicting display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get importedIntentId(): string|null {
    return (
        (this.Serializable$has('importedIntentId')) ?
            (this.Serializable$get('importedIntentId')) :
            (null));
  }

  /**
   * The name of the conflicting intent in the content to be imported. This is a
   * unique identifier. Format: `projects//locations//agents//intents/`.
   */
  set importedIntentId(value: string|null) {
    this.Serializable$set('importedIntentId', value);
  }

  get originalIntentId(): string|null {
    return (
        (this.Serializable$has('originalIntentId')) ?
            (this.Serializable$get('originalIntentId')) :
            (null));
  }

  /**
   * The name of the conflicting intent in the current agent. This is a unique
   * identifier. Format: `projects//locations//agents//intents/`.
   */
  set originalIntentId(value: string|null) {
    this.Serializable$set('originalIntentId', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3IntentConflictDiff> {
    return GoogleCloudDialogflowCxV3IntentConflictDiff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'importedIntentId', 'originalIntentId']};
  }
}

export interface GoogleCloudDialogflowCxV3IntentInputParameters {
  intent?: string|null;
}
export class GoogleCloudDialogflowCxV3IntentInput extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3IntentInputParameters = {}) {
    super();
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * Required. The unique identifier of the intent. Format:
   * `projects//locations//agents//intents/`.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3IntentInput> {
    return GoogleCloudDialogflowCxV3IntentInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intent']};
  }
}

export interface GoogleCloudDialogflowCxV3IntentParameterParameters {
  id?: string|null;
  entityType?: string|null;
  isList?: boolean|null;
  redact?: boolean|null;
}
export class GoogleCloudDialogflowCxV3IntentParameter extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3IntentParameterParameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
    this.Serializable$set(
        'isList', (parameters.isList == null) ? (null) : (parameters.isList));
    this.Serializable$set(
        'redact', (parameters.redact == null) ? (null) : (parameters.redact));
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * Required. The entity type of the parameter. Format:
   * `projects/-/locations/-/agents/-/entityTypes/` for system entity types (for
   * example, `projects/-/locations/-/agents/-/entityTypes/sys.date`), or
   * `projects//locations//agents//entityTypes/` for developer entity types.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Required. The unique identifier of the parameter. This field is used by
   * training phrases to annotate their parts.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get isList(): boolean|null {
    return (
        (this.Serializable$has('isList')) ? (this.Serializable$get('isList')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter represents a list of values.
   */
  set isList(value: boolean|null) {
    this.Serializable$set('isList', value);
  }

  get redact(): boolean|null {
    return (
        (this.Serializable$has('redact')) ? (this.Serializable$get('redact')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter content should be redacted in log. If
   * redaction is enabled, the parameter content will be replaced by parameter
   * name during logging. Note: the parameter content is subject to redaction if
   * either parameter level redaction or entity type level redaction is enabled.
   */
  set redact(value: boolean|null) {
    this.Serializable$set('redact', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3IntentParameter> {
    return GoogleCloudDialogflowCxV3IntentParameter;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['entityType', 'id', 'isList', 'redact']};
  }
}

export interface GoogleCloudDialogflowCxV3IntentTrainingPhraseParameters {
  id?: string|null;
  parts?: Array<GoogleCloudDialogflowCxV3IntentTrainingPhrasePart>|null;
  repeatCount?: number|null;
  unassessed?: boolean|null;
}
export class GoogleCloudDialogflowCxV3IntentTrainingPhrase extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3IntentTrainingPhraseParameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'parts', (parameters.parts == null) ? (null) : (parameters.parts));
    this.Serializable$set(
        'repeatCount',
        (parameters.repeatCount == null) ? (null) : (parameters.repeatCount));
    this.Serializable$set(
        'unassessed',
        (parameters.unassessed == null) ? (null) : (parameters.unassessed));
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Output only. The unique identifier of the training phrase.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get parts(): Array<GoogleCloudDialogflowCxV3IntentTrainingPhrasePart>|null {
    return (
        (this.Serializable$has('parts')) ? (this.Serializable$get('parts')) :
                                           (null));
  }

  /**
   * Required. The ordered list of training phrase parts. The parts are
   * concatenated in order to form the training phrase. Note: The API does not
   * automatically annotate training phrases like the Dialogflow Console does.
   * Note: Do not forget to include whitespace at part boundaries, so the
   * training phrase is well formatted when the parts are concatenated. If the
   * training phrase does not need to be annotated with parameters, you just
   * need a single part with only the Part.text field set. If you want to
   * annotate the training phrase, you must create multiple parts, where the
   * fields of each part are populated in one of two ways: - `Part.text` is set
   * to a part of the phrase that has no parameters. - `Part.text` is set to a
   * part of the phrase that you want to annotate, and the `parameter_id` field
   * is set.
   */
  set parts(value: Array<GoogleCloudDialogflowCxV3IntentTrainingPhrasePart>|
            null) {
    this.Serializable$set('parts', value);
  }

  get repeatCount(): number|null {
    return (
        (this.Serializable$has('repeatCount')) ?
            (this.Serializable$get('repeatCount')) :
            (null));
  }

  /**
   * Indicates how many times this example was added to the intent.
   */
  set repeatCount(value: number|null) {
    this.Serializable$set('repeatCount', value);
  }

  get unassessed(): boolean|null {
    return (
        (this.Serializable$has('unassessed')) ?
            (this.Serializable$get('unassessed')) :
            (null));
  }

  /**
   * Indicates whether this training phrase has not been assessed yet.
   */
  set unassessed(value: boolean|null) {
    this.Serializable$set('unassessed', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3IntentTrainingPhrase> {
    return GoogleCloudDialogflowCxV3IntentTrainingPhrase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parts': GoogleCloudDialogflowCxV3IntentTrainingPhrasePart},
      keys: ['id', 'parts', 'repeatCount', 'unassessed']
    };
  }
}

export interface GoogleCloudDialogflowCxV3IntentTrainingPhrasePartParameters {
  text?: string|null;
  parameterId?: string|null;
  auto?: boolean|null;
}
export class GoogleCloudDialogflowCxV3IntentTrainingPhrasePart extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3IntentTrainingPhrasePartParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'parameterId',
        (parameters.parameterId == null) ? (null) : (parameters.parameterId));
    this.Serializable$set(
        'auto', (parameters.auto == null) ? (null) : (parameters.auto));
  }

  get auto(): boolean|null {
    return (
        (this.Serializable$has('auto')) ? (this.Serializable$get('auto')) :
                                          (null));
  }

  /**
   * Indicates whether the text was auto-annotated. This field is only set to
   * true when the Dialogflow Console auto-annotates the part.
   */
  set auto(value: boolean|null) {
    this.Serializable$set('auto', value);
  }

  get parameterId(): string|null {
    return (
        (this.Serializable$has('parameterId')) ?
            (this.Serializable$get('parameterId')) :
            (null));
  }

  /**
   * The parameter used to annotate this part of the training phrase. This field
   * is required for annotated parts of the training phrase.
   */
  set parameterId(value: string|null) {
    this.Serializable$set('parameterId', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. The text for this part.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3IntentTrainingPhrasePart> {
    return GoogleCloudDialogflowCxV3IntentTrainingPhrasePart;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['auto', 'parameterId', 'text']};
  }
}

export interface GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsParameters {
  audioExportGcsDestination?: GoogleCloudDialogflowCxV3GcsDestination|null;
  disableLogging?: boolean|null;
  dtmfSettings?:
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsDtmfSettings|
      null;
  speechSettings?:
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsSpeechSettings|
      null;
  loggingSettings?:
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsLoggingSettings|
      null;
}
export class GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'audioExportGcsDestination',
        (parameters.audioExportGcsDestination == null) ?
            (null) :
            (parameters.audioExportGcsDestination));
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
    this.Serializable$set(
        'dtmfSettings',
        (parameters.dtmfSettings == null) ? (null) : (parameters.dtmfSettings));
    this.Serializable$set(
        'speechSettings',
        (parameters.speechSettings == null) ? (null) :
                                              (parameters.speechSettings));
    this.Serializable$set(
        'loggingSettings',
        (parameters.loggingSettings == null) ? (null) :
                                               (parameters.loggingSettings));
  }

  get audioExportGcsDestination(): GoogleCloudDialogflowCxV3GcsDestination
      |null {
    return (
        (this.Serializable$has('audioExportGcsDestination')) ?
            (this.Serializable$get('audioExportGcsDestination')) :
            (null));
  }

  /**
   * If present, incoming audio is exported by Dialogflow to the configured
   * Google Cloud Storage destination.
   */
  set audioExportGcsDestination(value: GoogleCloudDialogflowCxV3GcsDestination|
                                null) {
    this.Serializable$set('audioExportGcsDestination', value);
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * If true, logging is currently disabled. This setting overrides the agent-
   * level settings for Dialogflow History, Contact Center messages, StackDriver
   * logs, and speech logging. Usually, this is configured just for specific
   * pages in the agent (e.g. to temporarily disable logging for a sensitive
   * operation).
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  get dtmfSettings():
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsDtmfSettings
      |null {
    return (
        (this.Serializable$has('dtmfSettings')) ?
            (this.Serializable$get('dtmfSettings')) :
            (null));
  }

  /**
   * Settings for DTMF.
   */
  set dtmfSettings(
      value:
          GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsDtmfSettings|
      null) {
    this.Serializable$set('dtmfSettings', value);
  }

  get loggingSettings():
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsLoggingSettings
      |null {
    return (
        (this.Serializable$has('loggingSettings')) ?
            (this.Serializable$get('loggingSettings')) :
            (null));
  }

  /**
   * Settings for logging.
   */
  set loggingSettings(
      value:
          GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsLoggingSettings|
      null) {
    this.Serializable$set('loggingSettings', value);
  }

  get speechSettings():
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsSpeechSettings
      |null {
    return (
        (this.Serializable$has('speechSettings')) ?
            (this.Serializable$get('speechSettings')) :
            (null));
  }

  /**
   * Settings for speech to text detection.
   */
  set speechSettings(
      value:
          GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsSpeechSettings|
      null) {
    this.Serializable$set('speechSettings', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettings> {
    return GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'audioExportGcsDestination', 'disableLogging', 'dtmfSettings',
        'loggingSettings', 'speechSettings'
      ],
      objects: {
        'audioExportGcsDestination': GoogleCloudDialogflowCxV3GcsDestination,
        'dtmfSettings':
            GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsDtmfSettings,
        'loggingSettings':
            GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsLoggingSettings,
        'speechSettings':
            GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsSpeechSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsDtmfSettingsParameters {
  enabled?: boolean|null;
  parameter?: string|null;
  maxDigits?: number|null;
  finishDigit?: string|null;
}
export class
    GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsDtmfSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsDtmfSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
    this.Serializable$set(
        'maxDigits',
        (parameters.maxDigits == null) ? (null) : (parameters.maxDigits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * If true, incoming audio is processed for DTMF (dual tone multi frequency)
   * events. For example, if the caller presses a button on their telephone
   * keypad and DTMF processing is enabled, Dialogflow will detect the event
   * (e.g. a \"3\" was pressed) in the incoming audio and pass the event to the
   * bot to drive business logic (e.g. when 3 is pressed, return the account
   * balance).
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get finishDigit(): string|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * The digit that terminates a DTMF digit sequence.
   */
  set finishDigit(value: string|null) {
    this.Serializable$set('finishDigit', value);
  }

  get maxDigits(): number|null {
    return (
        (this.Serializable$has('maxDigits')) ?
            (this.Serializable$get('maxDigits')) :
            (null));
  }

  /**
   * Max length of DTMF digits.
   */
  set maxDigits(value: number|null) {
    this.Serializable$set('maxDigits', value);
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * If not empty, the transcribed DTMF digits will be set into the value of the
   * specified slot parameter.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsDtmfSettings> {
    return GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsDtmfSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enabled', 'finishDigit', 'maxDigits', 'parameter']};
  }
}

export interface GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsLoggingSettingsParameters {
  disableLogging?: boolean|null;
}
export class
    GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsLoggingSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsLoggingSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * If true, logging is currently disabled. This setting overrides the agent-
   * level settings for Dialogflow History, Contact Center messages, StackDriver
   * logs, and speech logging. Usually, this is configured just for specific
   * pages in the agent (e.g. to temporarily disable logging for a sensitive
   * operation).
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsLoggingSettings> {
    return GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsLoggingSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['disableLogging']};
  }
}

export interface GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsSpeechSettingsParameters {
  endpointerSensitivity?: number|null;
  noSpeechTimeout?: string|null;
}
export class
    GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsSpeechSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsSpeechSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'endpointerSensitivity',
        (parameters.endpointerSensitivity == null) ?
            (null) :
            (parameters.endpointerSensitivity));
    this.Serializable$set(
        'noSpeechTimeout',
        (parameters.noSpeechTimeout == null) ? (null) :
                                               (parameters.noSpeechTimeout));
  }

  get endpointerSensitivity(): number|null {
    return (
        (this.Serializable$has('endpointerSensitivity')) ?
            (this.Serializable$get('endpointerSensitivity')) :
            (null));
  }

  /**
   * Sensitivity of the speech model that detects the end of speech. Scale from
   * 0 to 100.
   */
  set endpointerSensitivity(value: number|null) {
    this.Serializable$set('endpointerSensitivity', value);
  }

  get noSpeechTimeout(): string|null {
    return (
        (this.Serializable$has('noSpeechTimeout')) ?
            (this.Serializable$get('noSpeechTimeout')) :
            (null));
  }

  /**
   * Timeout before detecting no speech.
   */
  set noSpeechTimeout(value: string|null) {
    this.Serializable$set('noSpeechTimeout', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsSpeechSettings> {
    return GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettingsSpeechSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['endpointerSensitivity', 'noSpeechTimeout']};
  }
}

export interface GoogleCloudDialogflowCxV3KnowledgeConnectorSettingsParameters {
  enabled?: boolean|null;
  knowledgeBaseIds?: Array<string>|null;
  triggerFulfillment?: GoogleCloudDialogflowCxV3Fulfillment|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowCxV3KnowledgeConnectorSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3KnowledgeConnectorSettingsParameters = {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'knowledgeBaseIds',
        (parameters.knowledgeBaseIds == null) ? (null) :
                                                (parameters.knowledgeBaseIds));
    this.Serializable$set(
        'triggerFulfillment',
        (parameters.triggerFulfillment == null) ?
            (null) :
            (parameters.triggerFulfillment));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * Whether Knowledge Connector is enabled for this page. Users might want to
   * keep configurations the same (which Knowledge Bases are attached to the
   * page, how fulfillment should work, etc.), but temporarily disable Knowledge
   * Connector functionality.
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get knowledgeBaseIds(): Array<string>|null {
    return (
        (this.Serializable$has('knowledgeBaseIds')) ?
            (this.Serializable$get('knowledgeBaseIds')) :
            (null));
  }

  /**
   * The list of knowledge bases attached to this page. The entries are the full
   * names of Knowledge Bases. Format: `projects//locations//knowledgeBases/`
   */
  set knowledgeBaseIds(value: Array<string>|null) {
    this.Serializable$set('knowledgeBaseIds', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  get triggerFulfillment(): GoogleCloudDialogflowCxV3Fulfillment|null {
    return (
        (this.Serializable$has('triggerFulfillment')) ?
            (this.Serializable$get('triggerFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to be triggered, in case the Knowledge Base answers are the
   * winners of this conversation turn. The fulfillment should make use of the
   * request scoped parameter `$request.knowledge.answer`, containing up to the
   * 5 highest confidence answers, as well as `$request.knowledge.question`,
   * containing the corresponding questions.
   */
  set triggerFulfillment(value: GoogleCloudDialogflowCxV3Fulfillment|null) {
    this.Serializable$set('triggerFulfillment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3KnowledgeConnectorSettings> {
    return GoogleCloudDialogflowCxV3KnowledgeConnectorSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'enabled', 'knowledgeBaseIds', 'targetFlow', 'targetPage',
        'triggerFulfillment'
      ],
      objects: {'triggerFulfillment': GoogleCloudDialogflowCxV3Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowCxV3PageParameters {
  name?: string|null;
  displayName?: string|null;
  entryFulfillment?: GoogleCloudDialogflowCxV3Fulfillment|null;
  form?: GoogleCloudDialogflowCxV3Form|null;
  transitionRouteGroups?: Array<string>|null;
  transitionRoutes?: Array<GoogleCloudDialogflowCxV3TransitionRoute>|null;
  eventHandlers?: Array<GoogleCloudDialogflowCxV3EventHandler>|null;
  advancedSettings?: GoogleCloudDialogflowCxV3AdvancedSettings|null;
  speakerIdSettings?: GoogleCloudDialogflowCxV3SpeakerIdSettings|null;
  knowledgeConnectorSettings?:
      GoogleCloudDialogflowCxV3KnowledgeConnectorSettings|null;
}
export class GoogleCloudDialogflowCxV3Page extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3PageParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'entryFulfillment',
        (parameters.entryFulfillment == null) ? (null) :
                                                (parameters.entryFulfillment));
    this.Serializable$set(
        'form', (parameters.form == null) ? (null) : (parameters.form));
    this.Serializable$set(
        'transitionRouteGroups',
        (parameters.transitionRouteGroups == null) ?
            (null) :
            (parameters.transitionRouteGroups));
    this.Serializable$set(
        'transitionRoutes',
        (parameters.transitionRoutes == null) ? (null) :
                                                (parameters.transitionRoutes));
    this.Serializable$set(
        'eventHandlers',
        (parameters.eventHandlers == null) ? (null) :
                                             (parameters.eventHandlers));
    this.Serializable$set(
        'advancedSettings',
        (parameters.advancedSettings == null) ? (null) :
                                                (parameters.advancedSettings));
    this.Serializable$set(
        'speakerIdSettings',
        (parameters.speakerIdSettings == null) ?
            (null) :
            (parameters.speakerIdSettings));
    this.Serializable$set(
        'knowledgeConnectorSettings',
        (parameters.knowledgeConnectorSettings == null) ?
            (null) :
            (parameters.knowledgeConnectorSettings));
  }

  get advancedSettings(): GoogleCloudDialogflowCxV3AdvancedSettings|null {
    return (
        (this.Serializable$has('advancedSettings')) ?
            (this.Serializable$get('advancedSettings')) :
            (null));
  }

  /**
   * Hierarchical advanced settings for this page. The settings exposed at the
   * lower level overrides the settings exposed at the higher level.
   */
  set advancedSettings(value: GoogleCloudDialogflowCxV3AdvancedSettings|null) {
    this.Serializable$set('advancedSettings', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the page, unique within the flow.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get entryFulfillment(): GoogleCloudDialogflowCxV3Fulfillment|null {
    return (
        (this.Serializable$has('entryFulfillment')) ?
            (this.Serializable$get('entryFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to call when the session is entering the page.
   */
  set entryFulfillment(value: GoogleCloudDialogflowCxV3Fulfillment|null) {
    this.Serializable$set('entryFulfillment', value);
  }

  get eventHandlers(): Array<GoogleCloudDialogflowCxV3EventHandler>|null {
    return (
        (this.Serializable$has('eventHandlers')) ?
            (this.Serializable$get('eventHandlers')) :
            (null));
  }

  /**
   * Handlers associated with the page to handle events such as webhook errors,
   * no match or no input.
   */
  set eventHandlers(value: Array<GoogleCloudDialogflowCxV3EventHandler>|null) {
    this.Serializable$set('eventHandlers', value);
  }

  get form(): GoogleCloudDialogflowCxV3Form|null {
    return (
        (this.Serializable$has('form')) ? (this.Serializable$get('form')) :
                                          (null));
  }

  /**
   * The form associated with the page, used for collecting parameters relevant
   * to the page.
   */
  set form(value: GoogleCloudDialogflowCxV3Form|null) {
    this.Serializable$set('form', value);
  }

  get knowledgeConnectorSettings():
      GoogleCloudDialogflowCxV3KnowledgeConnectorSettings|null {
    return (
        (this.Serializable$has('knowledgeConnectorSettings')) ?
            (this.Serializable$get('knowledgeConnectorSettings')) :
            (null));
  }

  /**
   * Should only be set in case this is Page is connected to Knowledge Bases.
   */
  set knowledgeConnectorSettings(
      value: GoogleCloudDialogflowCxV3KnowledgeConnectorSettings|null) {
    this.Serializable$set('knowledgeConnectorSettings', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the page. Required for the Pages.UpdatePage
   * method. Pages.CreatePage populates the name automatically. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get speakerIdSettings(): GoogleCloudDialogflowCxV3SpeakerIdSettings|null {
    return (
        (this.Serializable$has('speakerIdSettings')) ?
            (this.Serializable$get('speakerIdSettings')) :
            (null));
  }

  /**
   * The Speaker ID settings for this page.
   */
  set speakerIdSettings(value: GoogleCloudDialogflowCxV3SpeakerIdSettings|
                        null) {
    this.Serializable$set('speakerIdSettings', value);
  }

  get transitionRouteGroups(): Array<string>|null {
    return (
        (this.Serializable$has('transitionRouteGroups')) ?
            (this.Serializable$get('transitionRouteGroups')) :
            (null));
  }

  /**
   * Ordered list of `TransitionRouteGroups` associated with the page.
   * Transition route groups must be unique within a page. * If multiple
   * transition routes within a page scope refer to the same intent, then the
   * precedence order is: page's transition route -> page's transition route
   * group -> flow's transition routes. * If multiple transition route groups
   * within a page contain the same intent, then the first group in the ordered
   * list takes precedence.
   * Format:`projects//locations//agents//flows//transitionRouteGroups/`.
   */
  set transitionRouteGroups(value: Array<string>|null) {
    this.Serializable$set('transitionRouteGroups', value);
  }

  get transitionRoutes(): Array<GoogleCloudDialogflowCxV3TransitionRoute>|null {
    return (
        (this.Serializable$has('transitionRoutes')) ?
            (this.Serializable$get('transitionRoutes')) :
            (null));
  }

  /**
   * A list of transitions for the transition rules of this page. They route the
   * conversation to another page in the same flow, or another flow. When we are
   * in a certain page, the TransitionRoutes are evalauted in the following
   * order: * TransitionRoutes defined in the page with intent specified. *
   * TransitionRoutes defined in the transition route groups with intent
   * specified. * TransitionRoutes defined in flow with intent specified. *
   * TransitionRoutes defined in the transition route groups with intent
   * specified. * TransitionRoutes defined in the page with only condition
   * specified. * TransitionRoutes defined in the transition route groups with
   * only condition specified.
   */
  set transitionRoutes(value: Array<GoogleCloudDialogflowCxV3TransitionRoute>|
                       null) {
    this.Serializable$set('transitionRoutes', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3Page> {
    return GoogleCloudDialogflowCxV3Page;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'eventHandlers': GoogleCloudDialogflowCxV3EventHandler,
        'transitionRoutes': GoogleCloudDialogflowCxV3TransitionRoute
      },
      keys: [
        'advancedSettings', 'displayName', 'entryFulfillment', 'eventHandlers',
        'form', 'knowledgeConnectorSettings', 'name', 'speakerIdSettings',
        'transitionRouteGroups', 'transitionRoutes'
      ],
      objects: {
        'advancedSettings': GoogleCloudDialogflowCxV3AdvancedSettings,
        'entryFulfillment': GoogleCloudDialogflowCxV3Fulfillment,
        'form': GoogleCloudDialogflowCxV3Form,
        'knowledgeConnectorSettings':
            GoogleCloudDialogflowCxV3KnowledgeConnectorSettings,
        'speakerIdSettings': GoogleCloudDialogflowCxV3SpeakerIdSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3PageInfoParameters {
  currentPage?: string|null;
  displayName?: string|null;
  formInfo?: GoogleCloudDialogflowCxV3PageInfoFormInfo|null;
}
export class GoogleCloudDialogflowCxV3PageInfo extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3PageInfoParameters = {}) {
    super();
    this.Serializable$set(
        'currentPage',
        (parameters.currentPage == null) ? (null) : (parameters.currentPage));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'formInfo',
        (parameters.formInfo == null) ? (null) : (parameters.formInfo));
  }

  get currentPage(): string|null {
    return (
        (this.Serializable$has('currentPage')) ?
            (this.Serializable$get('currentPage')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Ignored for WebhookResponse. The unique
   * identifier of the current page. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set currentPage(value: string|null) {
    this.Serializable$set('currentPage', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Ignored for WebhookResponse. The display
   * name of the current page.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get formInfo(): GoogleCloudDialogflowCxV3PageInfoFormInfo|null {
    return (
        (this.Serializable$has('formInfo')) ?
            (this.Serializable$get('formInfo')) :
            (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. Information about the
   * form.
   */
  set formInfo(value: GoogleCloudDialogflowCxV3PageInfoFormInfo|null) {
    this.Serializable$set('formInfo', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3PageInfo> {
    return GoogleCloudDialogflowCxV3PageInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['currentPage', 'displayName', 'formInfo'],
      objects: {'formInfo': GoogleCloudDialogflowCxV3PageInfoFormInfo}
    };
  }
}

export interface GoogleCloudDialogflowCxV3PageInfoFormInfoParameters {
  parameterInfo?: Array<GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfo>|
      null;
}
export class GoogleCloudDialogflowCxV3PageInfoFormInfo extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3PageInfoFormInfoParameters = {}) {
    super();
    this.Serializable$set(
        'parameterInfo',
        (parameters.parameterInfo == null) ? (null) :
                                             (parameters.parameterInfo));
  }

  get parameterInfo():
      Array<GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfo>|null {
    return (
        (this.Serializable$has('parameterInfo')) ?
            (this.Serializable$get('parameterInfo')) :
            (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. The parameters
   * contained in the form. Note that the webhook cannot add or remove any form
   * parameter.
   */
  set parameterInfo(
      value: Array<GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfo>|
      null) {
    this.Serializable$set('parameterInfo', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3PageInfoFormInfo> {
    return GoogleCloudDialogflowCxV3PageInfoFormInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'parameterInfo': GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfo
      },
      keys: ['parameterInfo']
    };
  }
}

export interface GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoParameters {
  displayName?: string|null;
  required?: boolean|null;
  state?: GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState|null;
  value?: any|null;
  justCollected?: boolean|null;
}
export class GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'required',
        (parameters.required == null) ? (null) : (parameters.required));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
    this.Serializable$set(
        'justCollected',
        (parameters.justCollected == null) ? (null) :
                                             (parameters.justCollected));
  }

  static get State():
      IGoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoStateEnum {
    return GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoStateEnum;
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Required for WebhookResponse. The
   * human-readable name of the parameter, unique within the form. This field
   * cannot be modified by the webhook.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get justCollected(): boolean|null {
    return (
        (this.Serializable$has('justCollected')) ?
            (this.Serializable$get('justCollected')) :
            (null));
  }

  /**
   * Optional for WebhookRequest. Ignored for WebhookResponse. Indicates if the
   * parameter value was just collected on the last conversation turn.
   */
  set justCollected(value: boolean|null) {
    this.Serializable$set('justCollected', value);
  }

  get required(): boolean|null {
    return (
        (this.Serializable$has('required')) ?
            (this.Serializable$get('required')) :
            (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. Indicates whether the
   * parameter is required. Optional parameters will not trigger prompts;
   * however, they are filled if the user specifies them. Required parameters
   * must be filled before form filling concludes.
   */
  set required(value: boolean|null) {
    this.Serializable$set('required', value);
  }

  get state(): GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState
      |null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Always present for WebhookRequest. Required for WebhookResponse. The state
   * of the parameter. This field can be set to INVALID by the webhook to
   * invalidate the parameter; other values set by the webhook will be ignored.
   */
  set state(value: GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoState|
            null) {
    this.Serializable$set('state', value);
  }

  get value(): any|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. The value of the
   * parameter. This field can be set by the webhook to change the parameter
   * value.
   */
  set value(value: any|null) {
    this.Serializable$set('value', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfo> {
    return GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state': GoogleCloudDialogflowCxV3PageInfoFormInfoParameterInfoStateEnum
      },
      keys: ['displayName', 'justCollected', 'required', 'state', 'value']
    };
  }
}

export interface GoogleCloudDialogflowCxV3ParameterDeclarationParameters {
  parameter?: string|null;
}
export class GoogleCloudDialogflowCxV3ParameterDeclaration extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ParameterDeclarationParameters = {}) {
    super();
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * The name of the session parameter. Can contain characters [A-Z], [a-z],
   * [0-9], ., -, _.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ParameterDeclaration> {
    return GoogleCloudDialogflowCxV3ParameterDeclaration;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['parameter']};
  }
}

export interface GoogleCloudDialogflowCxV3PlaybackInterruptionSettingsParameters {
  allowPlaybackInterruption?: boolean|null;
  allowCancellation?: boolean|null;
}
export class GoogleCloudDialogflowCxV3PlaybackInterruptionSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3PlaybackInterruptionSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
    this.Serializable$set(
        'allowCancellation',
        (parameters.allowCancellation == null) ?
            (null) :
            (parameters.allowCancellation));
  }

  get allowCancellation(): boolean|null {
    return (
        (this.Serializable$has('allowCancellation')) ?
            (this.Serializable$get('allowCancellation')) :
            (null));
  }

  /**
   * Whether the partial response message should be cancelled when a later
   * response arrives. e.g. if the agent returns some music as partial response,
   * you may consider configuring it as cancellable.
   */
  set allowCancellation(value: boolean|null) {
    this.Serializable$set('allowCancellation', value);
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Whether interruption on the playback of a message is allowed.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3PlaybackInterruptionSettings> {
    return GoogleCloudDialogflowCxV3PlaybackInterruptionSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowCancellation', 'allowPlaybackInterruption']};
  }
}

export interface GoogleCloudDialogflowCxV3QueryInputParameters {
  text?: GoogleCloudDialogflowCxV3TextInput|null;
  intent?: GoogleCloudDialogflowCxV3IntentInput|null;
  audio?: GoogleCloudDialogflowCxV3AudioInput|null;
  event?: GoogleCloudDialogflowCxV3EventInput|null;
  dtmf?: GoogleCloudDialogflowCxV3DtmfInput|null;
  languageCode?: string|null;
}
export class GoogleCloudDialogflowCxV3QueryInput extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3QueryInputParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
    this.Serializable$set(
        'dtmf', (parameters.dtmf == null) ? (null) : (parameters.dtmf));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get audio(): GoogleCloudDialogflowCxV3AudioInput|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * The natural language speech audio to be processed.
   */
  set audio(value: GoogleCloudDialogflowCxV3AudioInput|null) {
    this.Serializable$set('audio', value);
  }

  get dtmf(): GoogleCloudDialogflowCxV3DtmfInput|null {
    return (
        (this.Serializable$has('dtmf')) ? (this.Serializable$get('dtmf')) :
                                          (null));
  }

  /**
   * The DTMF event to be handled.
   */
  set dtmf(value: GoogleCloudDialogflowCxV3DtmfInput|null) {
    this.Serializable$set('dtmf', value);
  }

  get event(): GoogleCloudDialogflowCxV3EventInput|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * The event to be triggered.
   */
  set event(value: GoogleCloudDialogflowCxV3EventInput|null) {
    this.Serializable$set('event', value);
  }

  get intent(): GoogleCloudDialogflowCxV3IntentInput|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The intent to be triggered.
   */
  set intent(value: GoogleCloudDialogflowCxV3IntentInput|null) {
    this.Serializable$set('intent', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of the input. See [Language
   * Support](https://cloud.google.com/dialogflow/cx/docs/reference/language)
   * for a list of the currently supported language codes. Note that queries in
   * the same session do not necessarily need to specify the same language.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get text(): GoogleCloudDialogflowCxV3TextInput|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The natural language text to be processed.
   */
  set text(value: GoogleCloudDialogflowCxV3TextInput|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3QueryInput> {
    return GoogleCloudDialogflowCxV3QueryInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['audio', 'dtmf', 'event', 'intent', 'languageCode', 'text'],
      objects: {
        'audio': GoogleCloudDialogflowCxV3AudioInput,
        'dtmf': GoogleCloudDialogflowCxV3DtmfInput,
        'event': GoogleCloudDialogflowCxV3EventInput,
        'intent': GoogleCloudDialogflowCxV3IntentInput,
        'text': GoogleCloudDialogflowCxV3TextInput
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3ReloadDocumentOperationMetadataParameters {
  genericMetadata?: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|
      null;
}
export class GoogleCloudDialogflowCxV3ReloadDocumentOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ReloadDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3ReloadDocumentOperationMetadata> {
    return GoogleCloudDialogflowCxV3ReloadDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessageParameters {
  text?: GoogleCloudDialogflowCxV3ResponseMessageText|null;
  payload?: ApiClientObjectMap<any>|null;
  conversationSuccess?:
      GoogleCloudDialogflowCxV3ResponseMessageConversationSuccess|null;
  avayaPlayAudio?: GoogleCloudDialogflowCxV3ResponseMessageAvayaPlayAudio|null;
  interactiveVoiceResponseSettings?:
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettings|null;
  outputAudioText?: GoogleCloudDialogflowCxV3ResponseMessageOutputAudioText|
      null;
  liveAgentHandoff?: GoogleCloudDialogflowCxV3ResponseMessageLiveAgentHandoff|
      null;
  endInteraction?: GoogleCloudDialogflowCxV3ResponseMessageEndInteraction|null;
  playAudio?: GoogleCloudDialogflowCxV3ResponseMessagePlayAudio|null;
  mixedAudio?: GoogleCloudDialogflowCxV3ResponseMessageMixedAudio|null;
  telephonyTransferCall?:
      GoogleCloudDialogflowCxV3ResponseMessageTelephonyTransferCall|null;
  platform?: GoogleCloudDialogflowCxV3ResponseMessagePlatform|null;
  responseType?: GoogleCloudDialogflowCxV3ResponseMessageResponseType|null;
  parameter?: string|null;
  source?: GoogleCloudDialogflowCxV3ResponseMessageSource|null;
  channel?: string|null;
}
export class GoogleCloudDialogflowCxV3ResponseMessage extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3ResponseMessageParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'conversationSuccess',
        (parameters.conversationSuccess == null) ?
            (null) :
            (parameters.conversationSuccess));
    this.Serializable$set(
        'avayaPlayAudio',
        (parameters.avayaPlayAudio == null) ? (null) :
                                              (parameters.avayaPlayAudio));
    this.Serializable$set(
        'interactiveVoiceResponseSettings',
        (parameters.interactiveVoiceResponseSettings == null) ?
            (null) :
            (parameters.interactiveVoiceResponseSettings));
    this.Serializable$set(
        'outputAudioText',
        (parameters.outputAudioText == null) ? (null) :
                                               (parameters.outputAudioText));
    this.Serializable$set(
        'liveAgentHandoff',
        (parameters.liveAgentHandoff == null) ? (null) :
                                                (parameters.liveAgentHandoff));
    this.Serializable$set(
        'endInteraction',
        (parameters.endInteraction == null) ? (null) :
                                              (parameters.endInteraction));
    this.Serializable$set(
        'playAudio',
        (parameters.playAudio == null) ? (null) : (parameters.playAudio));
    this.Serializable$set(
        'mixedAudio',
        (parameters.mixedAudio == null) ? (null) : (parameters.mixedAudio));
    this.Serializable$set(
        'telephonyTransferCall',
        (parameters.telephonyTransferCall == null) ?
            (null) :
            (parameters.telephonyTransferCall));
    this.Serializable$set(
        'platform',
        (parameters.platform == null) ? (null) : (parameters.platform));
    this.Serializable$set(
        'responseType',
        (parameters.responseType == null) ? (null) : (parameters.responseType));
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'channel',
        (parameters.channel == null) ? (null) : (parameters.channel));
  }

  static get Platform(): IGoogleCloudDialogflowCxV3ResponseMessagePlatformEnum {
    return GoogleCloudDialogflowCxV3ResponseMessagePlatformEnum;
  }

  static get ResponseType():
      IGoogleCloudDialogflowCxV3ResponseMessageResponseTypeEnum {
    return GoogleCloudDialogflowCxV3ResponseMessageResponseTypeEnum;
  }

  static get Source(): IGoogleCloudDialogflowCxV3ResponseMessageSourceEnum {
    return GoogleCloudDialogflowCxV3ResponseMessageSourceEnum;
  }

  get avayaPlayAudio(): GoogleCloudDialogflowCxV3ResponseMessageAvayaPlayAudio
      |null {
    return (
        (this.Serializable$has('avayaPlayAudio')) ?
            (this.Serializable$get('avayaPlayAudio')) :
            (null));
  }

  /**
   * Plays audio from a file in Avaya Browser.
   */
  set avayaPlayAudio(value:
                         GoogleCloudDialogflowCxV3ResponseMessageAvayaPlayAudio|
                     null) {
    this.Serializable$set('avayaPlayAudio', value);
  }

  get channel(): string|null {
    return (
        (this.Serializable$has('channel')) ?
            (this.Serializable$get('channel')) :
            (null));
  }

  /**
   * The channel which the response is associated with. Clients can specify the
   * channel via QueryParameters.channel, and only associated channel response
   * will be returned.
   */
  set channel(value: string|null) {
    this.Serializable$set('channel', value);
  }

  get conversationSuccess():
      GoogleCloudDialogflowCxV3ResponseMessageConversationSuccess|null {
    return (
        (this.Serializable$has('conversationSuccess')) ?
            (this.Serializable$get('conversationSuccess')) :
            (null));
  }

  /**
   * Indicates that the conversation succeeded.
   */
  set conversationSuccess(
      value: GoogleCloudDialogflowCxV3ResponseMessageConversationSuccess|null) {
    this.Serializable$set('conversationSuccess', value);
  }

  get endInteraction(): GoogleCloudDialogflowCxV3ResponseMessageEndInteraction
      |null {
    return (
        (this.Serializable$has('endInteraction')) ?
            (this.Serializable$get('endInteraction')) :
            (null));
  }

  /**
   * Output only. A signal that indicates the interaction with the Dialogflow
   * agent has ended. This message is generated by Dialogflow only when the
   * conversation reaches `END_SESSION` page. It is not supposed to be defined
   * by the user. It's guaranteed that there is at most one such message in each
   * response.
   */
  set endInteraction(value:
                         GoogleCloudDialogflowCxV3ResponseMessageEndInteraction|
                     null) {
    this.Serializable$set('endInteraction', value);
  }

  get interactiveVoiceResponseSettings():
      GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettings|null {
    return (
        (this.Serializable$has('interactiveVoiceResponseSettings')) ?
            (this.Serializable$get('interactiveVoiceResponseSettings')) :
            (null));
  }

  /**
   * Returns the current values for the IVR (interactive voice response)
   * settings. Even though the operations configured by these settings are
   * performed by Dialogflow, the client may need to perform special logic at
   * the moment. For example, if Dialogflow exports audio to Google Cloud
   * Storage, then the client may need to wait for the resulting object to
   * appear in the bucket before proceeding.
   */
  set interactiveVoiceResponseSettings(
      value: GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettings|null) {
    this.Serializable$set('interactiveVoiceResponseSettings', value);
  }

  get liveAgentHandoff():
      GoogleCloudDialogflowCxV3ResponseMessageLiveAgentHandoff|null {
    return (
        (this.Serializable$has('liveAgentHandoff')) ?
            (this.Serializable$get('liveAgentHandoff')) :
            (null));
  }

  /**
   * Hands off conversation to a human agent.
   */
  set liveAgentHandoff(
      value: GoogleCloudDialogflowCxV3ResponseMessageLiveAgentHandoff|null) {
    this.Serializable$set('liveAgentHandoff', value);
  }

  get mixedAudio(): GoogleCloudDialogflowCxV3ResponseMessageMixedAudio|null {
    return (
        (this.Serializable$has('mixedAudio')) ?
            (this.Serializable$get('mixedAudio')) :
            (null));
  }

  /**
   * Output only. An audio response message composed of both the synthesized
   * Dialogflow agent responses and responses defined via play_audio. This
   * message is generated by Dialogflow only and not supposed to be defined by
   * the user.
   */
  set mixedAudio(value: GoogleCloudDialogflowCxV3ResponseMessageMixedAudio|
                 null) {
    this.Serializable$set('mixedAudio', value);
  }

  get outputAudioText(): GoogleCloudDialogflowCxV3ResponseMessageOutputAudioText
      |null {
    return (
        (this.Serializable$has('outputAudioText')) ?
            (this.Serializable$get('outputAudioText')) :
            (null));
  }

  /**
   * A text or ssml response that is preferentially used for TTS output audio
   * synthesis, as described in the comment on the ResponseMessage message.
   */
  set outputAudioText(
      value: GoogleCloudDialogflowCxV3ResponseMessageOutputAudioText|null) {
    this.Serializable$set('outputAudioText', value);
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * Display name of the associated form parameter. Only populated for
   * `PARAMETER_PROMPT`.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Returns a response containing a custom, platform-specific payload.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get platform(): GoogleCloudDialogflowCxV3ResponseMessagePlatform|null {
    return (
        (this.Serializable$has('platform')) ?
            (this.Serializable$get('platform')) :
            (null));
  }

  /**
   * The platform that the response message is intended for.
   */
  set platform(value: GoogleCloudDialogflowCxV3ResponseMessagePlatform|null) {
    this.Serializable$set('platform', value);
  }

  get playAudio(): GoogleCloudDialogflowCxV3ResponseMessagePlayAudio|null {
    return (
        (this.Serializable$has('playAudio')) ?
            (this.Serializable$get('playAudio')) :
            (null));
  }

  /**
   * Signal that the client should play an audio clip hosted at a
   * client-specific URI. Dialogflow uses this to construct mixed_audio.
   * However, Dialogflow itself does not try to read or process the URI in any
   * way.
   */
  set playAudio(value: GoogleCloudDialogflowCxV3ResponseMessagePlayAudio|null) {
    this.Serializable$set('playAudio', value);
  }

  get responseType(): GoogleCloudDialogflowCxV3ResponseMessageResponseType
      |null {
    return (
        (this.Serializable$has('responseType')) ?
            (this.Serializable$get('responseType')) :
            (null));
  }

  /**
   * Response type.
   */
  set responseType(value: GoogleCloudDialogflowCxV3ResponseMessageResponseType|
                   null) {
    this.Serializable$set('responseType', value);
  }

  get source(): GoogleCloudDialogflowCxV3ResponseMessageSource|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * The source by which this response was generated.
   */
  set source(value: GoogleCloudDialogflowCxV3ResponseMessageSource|null) {
    this.Serializable$set('source', value);
  }

  get telephonyTransferCall():
      GoogleCloudDialogflowCxV3ResponseMessageTelephonyTransferCall|null {
    return (
        (this.Serializable$has('telephonyTransferCall')) ?
            (this.Serializable$get('telephonyTransferCall')) :
            (null));
  }

  /**
   * A signal that the client should transfer the phone call connected to this
   * agent to a third-party endpoint.
   */
  set telephonyTransferCall(
      value: GoogleCloudDialogflowCxV3ResponseMessageTelephonyTransferCall|
      null) {
    this.Serializable$set('telephonyTransferCall', value);
  }

  get text(): GoogleCloudDialogflowCxV3ResponseMessageText|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Returns a text response.
   */
  set text(value: GoogleCloudDialogflowCxV3ResponseMessageText|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3ResponseMessage> {
    return GoogleCloudDialogflowCxV3ResponseMessage;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'platform': GoogleCloudDialogflowCxV3ResponseMessagePlatformEnum,
        'responseType':
            GoogleCloudDialogflowCxV3ResponseMessageResponseTypeEnum,
        'source': GoogleCloudDialogflowCxV3ResponseMessageSourceEnum
      },
      keys: [
        'avayaPlayAudio', 'channel', 'conversationSuccess', 'endInteraction',
        'interactiveVoiceResponseSettings', 'liveAgentHandoff', 'mixedAudio',
        'outputAudioText', 'parameter', 'payload', 'platform', 'playAudio',
        'responseType', 'source', 'telephonyTransferCall', 'text'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'avayaPlayAudio':
            GoogleCloudDialogflowCxV3ResponseMessageAvayaPlayAudio,
        'conversationSuccess':
            GoogleCloudDialogflowCxV3ResponseMessageConversationSuccess,
        'endInteraction':
            GoogleCloudDialogflowCxV3ResponseMessageEndInteraction,
        'interactiveVoiceResponseSettings':
            GoogleCloudDialogflowCxV3InteractiveVoiceResponseSettings,
        'liveAgentHandoff':
            GoogleCloudDialogflowCxV3ResponseMessageLiveAgentHandoff,
        'mixedAudio': GoogleCloudDialogflowCxV3ResponseMessageMixedAudio,
        'outputAudioText':
            GoogleCloudDialogflowCxV3ResponseMessageOutputAudioText,
        'playAudio': GoogleCloudDialogflowCxV3ResponseMessagePlayAudio,
        'telephonyTransferCall':
            GoogleCloudDialogflowCxV3ResponseMessageTelephonyTransferCall,
        'text': GoogleCloudDialogflowCxV3ResponseMessageText
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessageAvayaPlayAudioParameters {
  audioUri?: string|null;
}
export class GoogleCloudDialogflowCxV3ResponseMessageAvayaPlayAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ResponseMessageAvayaPlayAudioParameters =
              {}) {
    super();
    this.Serializable$set(
        'audioUri',
        (parameters.audioUri == null) ? (null) : (parameters.audioUri));
  }

  get audioUri(): string|null {
    return (
        (this.Serializable$has('audioUri')) ?
            (this.Serializable$get('audioUri')) :
            (null));
  }

  /**
   * Required. Audio URI for Avaya Browser to play the audio.
   */
  set audioUri(value: string|null) {
    this.Serializable$set('audioUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ResponseMessageAvayaPlayAudio> {
    return GoogleCloudDialogflowCxV3ResponseMessageAvayaPlayAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audioUri']};
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessageConversationSuccessParameters {
  metadata?: ApiClientObjectMap<any>|null;
}
export class GoogleCloudDialogflowCxV3ResponseMessageConversationSuccess extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ResponseMessageConversationSuccessParameters =
              {}) {
    super();
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
  }

  get metadata(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Custom metadata. Dialogflow doesn't impose any structure on this.
   */
  set metadata(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('metadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3ResponseMessageConversationSuccess> {
    return GoogleCloudDialogflowCxV3ResponseMessageConversationSuccess;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['metadata'],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessageEndInteractionParameters {
}
export class GoogleCloudDialogflowCxV3ResponseMessageEndInteraction extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ResponseMessageEndInteractionParameters =
              {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ResponseMessageEndInteraction> {
    return GoogleCloudDialogflowCxV3ResponseMessageEndInteraction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessageLiveAgentHandoffParameters {
  metadata?: ApiClientObjectMap<any>|null;
}
export class GoogleCloudDialogflowCxV3ResponseMessageLiveAgentHandoff extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ResponseMessageLiveAgentHandoffParameters =
              {}) {
    super();
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
  }

  get metadata(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Custom metadata for your handoff procedure. Dialogflow doesn't impose any
   * structure on this.
   */
  set metadata(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('metadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3ResponseMessageLiveAgentHandoff> {
    return GoogleCloudDialogflowCxV3ResponseMessageLiveAgentHandoff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['metadata'],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessageMixedAudioParameters {
  segments?: Array<GoogleCloudDialogflowCxV3ResponseMessageMixedAudioSegment>|
      null;
}
export class GoogleCloudDialogflowCxV3ResponseMessageMixedAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ResponseMessageMixedAudioParameters = {}) {
    super();
    this.Serializable$set(
        'segments',
        (parameters.segments == null) ? (null) : (parameters.segments));
  }

  get segments():
      Array<GoogleCloudDialogflowCxV3ResponseMessageMixedAudioSegment>|null {
    return (
        (this.Serializable$has('segments')) ?
            (this.Serializable$get('segments')) :
            (null));
  }

  /**
   * Segments this audio response is composed of.
   */
  set segments(
      value: Array<GoogleCloudDialogflowCxV3ResponseMessageMixedAudioSegment>|
      null) {
    this.Serializable$set('segments', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ResponseMessageMixedAudio> {
    return GoogleCloudDialogflowCxV3ResponseMessageMixedAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'segments': GoogleCloudDialogflowCxV3ResponseMessageMixedAudioSegment
      },
      keys: ['segments']
    };
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessageMixedAudioSegmentParameters {
  audio?: string|null;
  uri?: string|null;
  allowPlaybackInterruption?: boolean|null;
}
export class GoogleCloudDialogflowCxV3ResponseMessageMixedAudioSegment extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ResponseMessageMixedAudioSegmentParameters =
              {}) {
    super();
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this segment can be interrupted by the
   * end user's speech and the client should then start the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get audio(): string|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * Raw audio synthesized from the Dialogflow agent's response using the output
   * config specified in the request.
   */
  set audio(value: string|null) {
    this.Serializable$set('audio', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Client-specific URI that points to an audio clip accessible to the client.
   * Dialogflow does not impose any validation on it.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3ResponseMessageMixedAudioSegment> {
    return GoogleCloudDialogflowCxV3ResponseMessageMixedAudioSegment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'audio', 'uri']};
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessageOutputAudioTextParameters {
  text?: string|null;
  ssml?: string|null;
  allowPlaybackInterruption?: boolean|null;
}
export class GoogleCloudDialogflowCxV3ResponseMessageOutputAudioText extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ResponseMessageOutputAudioTextParameters =
              {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'ssml', (parameters.ssml == null) ? (null) : (parameters.ssml));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this message can be interrupted by the
   * end user's speech and the client can then starts the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get ssml(): string|null {
    return (
        (this.Serializable$has('ssml')) ? (this.Serializable$get('ssml')) :
                                          (null));
  }

  /**
   * The SSML text to be synthesized. For more information, see
   * [SSML](/speech/text-to-speech/docs/ssml).
   */
  set ssml(value: string|null) {
    this.Serializable$set('ssml', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The raw text to be synthesized.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3ResponseMessageOutputAudioText> {
    return GoogleCloudDialogflowCxV3ResponseMessageOutputAudioText;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'ssml', 'text']};
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessagePlayAudioParameters {
  audioUri?: string|null;
  allowPlaybackInterruption?: boolean|null;
}
export class GoogleCloudDialogflowCxV3ResponseMessagePlayAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ResponseMessagePlayAudioParameters = {}) {
    super();
    this.Serializable$set(
        'audioUri',
        (parameters.audioUri == null) ? (null) : (parameters.audioUri));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this message can be interrupted by the
   * end user's speech and the client can then starts the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get audioUri(): string|null {
    return (
        (this.Serializable$has('audioUri')) ?
            (this.Serializable$get('audioUri')) :
            (null));
  }

  /**
   * Required. URI of the audio clip. Dialogflow does not impose any validation
   * on this value. It is specific to the client that reads it.
   */
  set audioUri(value: string|null) {
    this.Serializable$set('audioUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ResponseMessagePlayAudio> {
    return GoogleCloudDialogflowCxV3ResponseMessagePlayAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'audioUri']};
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessageTelephonyTransferCallParameters {
  phoneNumber?: string|null;
  sipUri?: string|null;
}
export class
    GoogleCloudDialogflowCxV3ResponseMessageTelephonyTransferCall extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3ResponseMessageTelephonyTransferCallParameters =
              {}) {
    super();
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
    this.Serializable$set(
        'sipUri', (parameters.sipUri == null) ? (null) : (parameters.sipUri));
  }

  get phoneNumber(): string|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Transfer the call to a phone number in [E.164
   * format](https://en.wikipedia.org/wiki/E.164).
   */
  set phoneNumber(value: string|null) {
    this.Serializable$set('phoneNumber', value);
  }

  get sipUri(): string|null {
    return (
        (this.Serializable$has('sipUri')) ? (this.Serializable$get('sipUri')) :
                                            (null));
  }

  /**
   * Transfer the call to a SIP endpoint.
   */
  set sipUri(value: string|null) {
    this.Serializable$set('sipUri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3ResponseMessageTelephonyTransferCall> {
    return GoogleCloudDialogflowCxV3ResponseMessageTelephonyTransferCall;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['phoneNumber', 'sipUri']};
  }
}

export interface GoogleCloudDialogflowCxV3ResponseMessageTextParameters {
  text?: Array<string>|null;
  allowPlaybackInterruption?: boolean|null;
  redactedText?: Array<string>|null;
}
export class GoogleCloudDialogflowCxV3ResponseMessageText extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3ResponseMessageTextParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
    this.Serializable$set(
        'redactedText',
        (parameters.redactedText == null) ? (null) : (parameters.redactedText));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this message can be interrupted by the
   * end user's speech and the client can then starts the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get redactedText(): Array<string>|null {
    return (
        (this.Serializable$has('redactedText')) ?
            (this.Serializable$get('redactedText')) :
            (null));
  }

  /**
   * A collection of parameter redacted text response. This field contains the
   * same text as Text.text but the parameters will be replaced with
   * $parameter_name_redacted. The Text.redacted_text will be the same as
   * Text.text if no parameters or no parameters to be redacted in text. This is
   * an internal field and will only be used to replace [Text.text] for logging.
   * For example: { \"text\": [ \"Hello\", \"My SSN is 0000\", ],
   * \"redacted_text\": [ \"Hello\", \"My SSN is $ssn_redacted\", ] }
   */
  set redactedText(value: Array<string>|null) {
    this.Serializable$set('redactedText', value);
  }

  get text(): Array<string>|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. A collection of text responses.
   */
  set text(value: Array<string>|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3ResponseMessageText> {
    return GoogleCloudDialogflowCxV3ResponseMessageText;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'redactedText', 'text']};
  }
}

export interface GoogleCloudDialogflowCxV3RunContinuousTestMetadataParameters {
  errors?: Array<GoogleCloudDialogflowCxV3TestError>|null;
}
export class GoogleCloudDialogflowCxV3RunContinuousTestMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3RunContinuousTestMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'errors', (parameters.errors == null) ? (null) : (parameters.errors));
  }

  get errors(): Array<GoogleCloudDialogflowCxV3TestError>|null {
    return (
        (this.Serializable$has('errors')) ? (this.Serializable$get('errors')) :
                                            (null));
  }

  /**
   * The test errors.
   */
  set errors(value: Array<GoogleCloudDialogflowCxV3TestError>|null) {
    this.Serializable$set('errors', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3RunContinuousTestMetadata> {
    return GoogleCloudDialogflowCxV3RunContinuousTestMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'errors': GoogleCloudDialogflowCxV3TestError},
      keys: ['errors']
    };
  }
}

export interface GoogleCloudDialogflowCxV3RunContinuousTestResponseParameters {
  continuousTestResult?: GoogleCloudDialogflowCxV3ContinuousTestResult|null;
}
export class GoogleCloudDialogflowCxV3RunContinuousTestResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3RunContinuousTestResponseParameters = {}) {
    super();
    this.Serializable$set(
        'continuousTestResult',
        (parameters.continuousTestResult == null) ?
            (null) :
            (parameters.continuousTestResult));
  }

  get continuousTestResult(): GoogleCloudDialogflowCxV3ContinuousTestResult
      |null {
    return (
        (this.Serializable$has('continuousTestResult')) ?
            (this.Serializable$get('continuousTestResult')) :
            (null));
  }

  /**
   * The result for a continuous test run.
   */
  set continuousTestResult(value: GoogleCloudDialogflowCxV3ContinuousTestResult|
                           null) {
    this.Serializable$set('continuousTestResult', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3RunContinuousTestResponse> {
    return GoogleCloudDialogflowCxV3RunContinuousTestResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['continuousTestResult'],
      objects: {
        'continuousTestResult': GoogleCloudDialogflowCxV3ContinuousTestResult
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3RunTestCaseMetadataParameters {}
export class GoogleCloudDialogflowCxV3RunTestCaseMetadata extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3RunTestCaseMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3RunTestCaseMetadata> {
    return GoogleCloudDialogflowCxV3RunTestCaseMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3RunTestCaseResponseParameters {
  result?: GoogleCloudDialogflowCxV3TestCaseResult|null;
}
export class GoogleCloudDialogflowCxV3RunTestCaseResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3RunTestCaseResponseParameters = {}) {
    super();
    this.Serializable$set(
        'result', (parameters.result == null) ? (null) : (parameters.result));
  }

  get result(): GoogleCloudDialogflowCxV3TestCaseResult|null {
    return (
        (this.Serializable$has('result')) ? (this.Serializable$get('result')) :
                                            (null));
  }

  /**
   * The result.
   */
  set result(value: GoogleCloudDialogflowCxV3TestCaseResult|null) {
    this.Serializable$set('result', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3RunTestCaseResponse> {
    return GoogleCloudDialogflowCxV3RunTestCaseResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['result'],
      objects: {'result': GoogleCloudDialogflowCxV3TestCaseResult}
    };
  }
}

export interface GoogleCloudDialogflowCxV3SessionInfoParameters {
  session?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
}
export class GoogleCloudDialogflowCxV3SessionInfo extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3SessionInfoParameters = {}) {
    super();
    this.Serializable$set(
        'session',
        (parameters.session == null) ? (null) : (parameters.session));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional for WebhookRequest. Optional for WebhookResponse. All parameters
   * collected from forms and intents during the session. Parameters can be
   * created, updated, or removed by the webhook. To remove a parameter from the
   * session, the webhook should explicitly set the parameter value to null in
   * WebhookResponse. The map is keyed by parameters' display names.
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  get session(): string|null {
    return (
        (this.Serializable$has('session')) ?
            (this.Serializable$get('session')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Ignored for WebhookResponse. The unique
   * identifier of the session. This field can be used by the webhook to
   * identify a session. Format: `projects//locations//agents//sessions/` or
   * `projects//locations//agents//environments//sessions/` if environment is
   * specified.
   */
  set session(value: string|null) {
    this.Serializable$set('session', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3SessionInfo> {
    return GoogleCloudDialogflowCxV3SessionInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['parameters', 'session'],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3SpeakerIdSettingsParameters {
  speakerIdPageType?:
      GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType|null;
  phrases?: Array<GoogleCloudDialogflowCxV3SpeakerIdSettingsPhrases>|null;
  disablePassiveVerification?: boolean|null;
  disableAdaptation?: boolean|null;
}
export class GoogleCloudDialogflowCxV3SpeakerIdSettings extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3SpeakerIdSettingsParameters = {}) {
    super();
    this.Serializable$set(
        'speakerIdPageType',
        (parameters.speakerIdPageType == null) ?
            (null) :
            (parameters.speakerIdPageType));
    this.Serializable$set(
        'phrases',
        (parameters.phrases == null) ? (null) : (parameters.phrases));
    this.Serializable$set(
        'disablePassiveVerification',
        (parameters.disablePassiveVerification == null) ?
            (null) :
            (parameters.disablePassiveVerification));
    this.Serializable$set(
        'disableAdaptation',
        (parameters.disableAdaptation == null) ?
            (null) :
            (parameters.disableAdaptation));
  }

  static get SpeakerIdPageType():
      IGoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageTypeEnum {
    return GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageTypeEnum;
  }

  get disableAdaptation(): boolean|null {
    return (
        (this.Serializable$has('disableAdaptation')) ?
            (this.Serializable$get('disableAdaptation')) :
            (null));
  }

  /**
   * Whether the audio for Speaker ID verification may be stored for model
   * adaptation. If not set or set to false, and audio for verification is a
   * strong match to the existing speaker profile for the matching speaker, the
   * audio may be stored in order to further improve the speaker profile. If set
   * to true, the audio in the request will not be stored. Only set for pages
   * that are of type SPEAKER_ID_PAGE_TYPE_VERIFICATION or do not disable
   * passive verification.
   */
  set disableAdaptation(value: boolean|null) {
    this.Serializable$set('disableAdaptation', value);
  }

  get disablePassiveVerification(): boolean|null {
    return (
        (this.Serializable$has('disablePassiveVerification')) ?
            (this.Serializable$get('disablePassiveVerification')) :
            (null));
  }

  /**
   * Whether to disable passive verification for conversations on this page.
   * Only set for pages of type SPEAKER_ID_PAGE_TYPE_UNSPECIFIED.
   */
  set disablePassiveVerification(value: boolean|null) {
    this.Serializable$set('disablePassiveVerification', value);
  }

  get phrases(): Array<GoogleCloudDialogflowCxV3SpeakerIdSettingsPhrases>|null {
    return (
        (this.Serializable$has('phrases')) ?
            (this.Serializable$get('phrases')) :
            (null));
  }

  /**
   * Phrases for Speaker ID enrollment and verification.
   */
  set phrases(value: Array<GoogleCloudDialogflowCxV3SpeakerIdSettingsPhrases>|
              null) {
    this.Serializable$set('phrases', value);
  }

  get speakerIdPageType():
      GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType|null {
    return (
        (this.Serializable$has('speakerIdPageType')) ?
            (this.Serializable$get('speakerIdPageType')) :
            (null));
  }

  /**
   * Type of the Speaker ID-related page.
   */
  set speakerIdPageType(
      value: GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageType|null) {
    this.Serializable$set('speakerIdPageType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3SpeakerIdSettings> {
    return GoogleCloudDialogflowCxV3SpeakerIdSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'phrases': GoogleCloudDialogflowCxV3SpeakerIdSettingsPhrases},
      enums: {
        'speakerIdPageType':
            GoogleCloudDialogflowCxV3SpeakerIdSettingsSpeakerIdPageTypeEnum
      },
      keys: [
        'disableAdaptation', 'disablePassiveVerification', 'phrases',
        'speakerIdPageType'
      ]
    };
  }
}

export interface GoogleCloudDialogflowCxV3SpeakerIdSettingsPhrasesParameters {
  enrollmentPhrases?: Array<string>|null;
  verificationPhrases?: Array<string>|null;
  languageCode?: string|null;
}
export class GoogleCloudDialogflowCxV3SpeakerIdSettingsPhrases extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3SpeakerIdSettingsPhrasesParameters = {}) {
    super();
    this.Serializable$set(
        'enrollmentPhrases',
        (parameters.enrollmentPhrases == null) ?
            (null) :
            (parameters.enrollmentPhrases));
    this.Serializable$set(
        'verificationPhrases',
        (parameters.verificationPhrases == null) ?
            (null) :
            (parameters.verificationPhrases));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get enrollmentPhrases(): Array<string>|null {
    return (
        (this.Serializable$has('enrollmentPhrases')) ?
            (this.Serializable$get('enrollmentPhrases')) :
            (null));
  }

  /**
   * Phrases that the user need to repeat for Speaker ID enrollment. For each
   * round of phrase enrollment, Dialogflow would choose one phrase from the
   * list by random. If unspecified or if all provided phrases have already been
   * used in a single session, Dialogflow would generate phrases automatically.
   */
  set enrollmentPhrases(value: Array<string>|null) {
    this.Serializable$set('enrollmentPhrases', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language of the phrases. In normal cases, we don't need to populate
   * `language_code` as only phrases of the requested language are returned.
   * However we need to store the phrases of all languages for YAML exported
   * agent.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get verificationPhrases(): Array<string>|null {
    return (
        (this.Serializable$has('verificationPhrases')) ?
            (this.Serializable$get('verificationPhrases')) :
            (null));
  }

  /**
   * Phrases that users need to repeat for Speaker ID verification. During
   * verification, Dialogflow would choose one phrase from the list by random.
   * If unspecified, Dialogflow would generate a phrase automatically.
   */
  set verificationPhrases(value: Array<string>|null) {
    this.Serializable$set('verificationPhrases', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3SpeakerIdSettingsPhrases> {
    return GoogleCloudDialogflowCxV3SpeakerIdSettingsPhrases;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enrollmentPhrases', 'languageCode', 'verificationPhrases']};
  }
}

export interface GoogleCloudDialogflowCxV3SpeechContextParameters {
  phrases?: Array<string>|null;
  boost?: number|null;
}
export class GoogleCloudDialogflowCxV3SpeechContext extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3SpeechContextParameters = {}) {
    super();
    this.Serializable$set(
        'phrases',
        (parameters.phrases == null) ? (null) : (parameters.phrases));
    this.Serializable$set(
        'boost', (parameters.boost == null) ? (null) : (parameters.boost));
  }

  get boost(): number|null {
    return (
        (this.Serializable$has('boost')) ? (this.Serializable$get('boost')) :
                                           (null));
  }

  /**
   * Optional. Boost for this context compared to other contexts: * If the boost
   * is positive, Dialogflow will increase the probability that the phrases in
   * this context are recognized over similar sounding phrases. * If the boost
   * is unspecified or non-positive, Dialogflow will not apply any boost.
   * Dialogflow recommends that you use boosts in the range (0, 20] and that you
   * find a value that fits your use case with binary search.
   */
  set boost(value: number|null) {
    this.Serializable$set('boost', value);
  }

  get phrases(): Array<string>|null {
    return (
        (this.Serializable$has('phrases')) ?
            (this.Serializable$get('phrases')) :
            (null));
  }

  /**
   * Optional. A list of strings containing words and phrases that the speech
   * recognizer should recognize with higher likelihood. This list can be used
   * to: * improve accuracy for words and phrases you expect the user to say,
   * e.g. typical commands for your Dialogflow agent * add additional words to
   * the speech recognizer vocabulary * ... See the [Cloud Speech
   * documentation](https://cloud.google.com/speech-to-text/quotas) for usage
   * limits.
   */
  set phrases(value: Array<string>|null) {
    this.Serializable$set('phrases', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3SpeechContext> {
    return GoogleCloudDialogflowCxV3SpeechContext;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['boost', 'phrases']};
  }
}

export interface GoogleCloudDialogflowCxV3TestCaseParameters {
  name?: string|null;
  tags?: Array<string>|null;
  displayName?: string|null;
  notes?: string|null;
  testConfig?: GoogleCloudDialogflowCxV3TestConfig|null;
  testCaseConversationTurns?: Array<GoogleCloudDialogflowCxV3ConversationTurn>|
      null;
  ignore?: boolean|null;
  creationTime?: string|null;
  lastTestResult?: GoogleCloudDialogflowCxV3TestCaseResult|null;
}
export class GoogleCloudDialogflowCxV3TestCase extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3TestCaseParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'tags', (parameters.tags == null) ? (null) : (parameters.tags));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'notes', (parameters.notes == null) ? (null) : (parameters.notes));
    this.Serializable$set(
        'testConfig',
        (parameters.testConfig == null) ? (null) : (parameters.testConfig));
    this.Serializable$set(
        'testCaseConversationTurns',
        (parameters.testCaseConversationTurns == null) ?
            (null) :
            (parameters.testCaseConversationTurns));
    this.Serializable$set(
        'ignore', (parameters.ignore == null) ? (null) : (parameters.ignore));
    this.Serializable$set(
        'creationTime',
        (parameters.creationTime == null) ? (null) : (parameters.creationTime));
    this.Serializable$set(
        'lastTestResult',
        (parameters.lastTestResult == null) ? (null) :
                                              (parameters.lastTestResult));
  }

  get creationTime(): string|null {
    return (
        (this.Serializable$has('creationTime')) ?
            (this.Serializable$get('creationTime')) :
            (null));
  }

  /**
   * Output only. When the test was created.
   */
  set creationTime(value: string|null) {
    this.Serializable$set('creationTime', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the test case, unique within the
   * agent. Limit of 200 characters.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get ignore(): boolean|null {
    return (
        (this.Serializable$has('ignore')) ? (this.Serializable$get('ignore')) :
                                            (null));
  }

  /**
   * Default is false. If set true, the test case will be excluded from run
   * requests. This flag is used for the console UI.
   */
  set ignore(value: boolean|null) {
    this.Serializable$set('ignore', value);
  }

  get lastTestResult(): GoogleCloudDialogflowCxV3TestCaseResult|null {
    return (
        (this.Serializable$has('lastTestResult')) ?
            (this.Serializable$get('lastTestResult')) :
            (null));
  }

  /**
   * The latest test result.
   */
  set lastTestResult(value: GoogleCloudDialogflowCxV3TestCaseResult|null) {
    this.Serializable$set('lastTestResult', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the test case. TestCases.CreateTestCase will
   * populate the name automatically. Otherwise use format:
   * `projects//locations//agents/ /testCases/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get notes(): string|null {
    return (
        (this.Serializable$has('notes')) ? (this.Serializable$get('notes')) :
                                           (null));
  }

  /**
   * Additional freeform notes about the test case. Limit of 400 characters.
   */
  set notes(value: string|null) {
    this.Serializable$set('notes', value);
  }

  get tags(): Array<string>|null {
    return (
        (this.Serializable$has('tags')) ? (this.Serializable$get('tags')) :
                                          (null));
  }

  /**
   * Tags are short descriptions that users may apply to test cases for
   * organizational and filtering purposes. Each tag should start with \"#\" and
   * has a limit of 30 characters.
   */
  set tags(value: Array<string>|null) {
    this.Serializable$set('tags', value);
  }

  get testCaseConversationTurns():
      Array<GoogleCloudDialogflowCxV3ConversationTurn>|null {
    return (
        (this.Serializable$has('testCaseConversationTurns')) ?
            (this.Serializable$get('testCaseConversationTurns')) :
            (null));
  }

  /**
   * The conversation turns uttered when the test case was created, in
   * chronological order. These include the canonical set of agent utterances
   * that should occur when the agent is working properly.
   */
  set testCaseConversationTurns(
      value: Array<GoogleCloudDialogflowCxV3ConversationTurn>|null) {
    this.Serializable$set('testCaseConversationTurns', value);
  }

  get testConfig(): GoogleCloudDialogflowCxV3TestConfig|null {
    return (
        (this.Serializable$has('testConfig')) ?
            (this.Serializable$get('testConfig')) :
            (null));
  }

  /**
   * Config for the test case.
   */
  set testConfig(value: GoogleCloudDialogflowCxV3TestConfig|null) {
    this.Serializable$set('testConfig', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3TestCase> {
    return GoogleCloudDialogflowCxV3TestCase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'testCaseConversationTurns': GoogleCloudDialogflowCxV3ConversationTurn
      },
      keys: [
        'creationTime', 'displayName', 'ignore', 'lastTestResult', 'name',
        'notes', 'tags', 'testCaseConversationTurns', 'testConfig'
      ],
      objects: {
        'lastTestResult': GoogleCloudDialogflowCxV3TestCaseResult,
        'testConfig': GoogleCloudDialogflowCxV3TestConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3TestCaseErrorParameters {
  testCase?: GoogleCloudDialogflowCxV3TestCase|null;
  status?: GoogleRpcStatus|null;
}
export class GoogleCloudDialogflowCxV3TestCaseError extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3TestCaseErrorParameters = {}) {
    super();
    this.Serializable$set(
        'testCase',
        (parameters.testCase == null) ? (null) : (parameters.testCase));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * The status associated with the test case.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  get testCase(): GoogleCloudDialogflowCxV3TestCase|null {
    return (
        (this.Serializable$has('testCase')) ?
            (this.Serializable$get('testCase')) :
            (null));
  }

  /**
   * The test case.
   */
  set testCase(value: GoogleCloudDialogflowCxV3TestCase|null) {
    this.Serializable$set('testCase', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3TestCaseError> {
    return GoogleCloudDialogflowCxV3TestCaseError;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['status', 'testCase'],
      objects: {
        'status': GoogleRpcStatus,
        'testCase': GoogleCloudDialogflowCxV3TestCase
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3TestCaseResultParameters {
  name?: string|null;
  environment?: string|null;
  conversationTurns?: Array<GoogleCloudDialogflowCxV3ConversationTurn>|null;
  testResult?: GoogleCloudDialogflowCxV3TestCaseResultTestResult|null;
  testTime?: string|null;
}
export class GoogleCloudDialogflowCxV3TestCaseResult extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3TestCaseResultParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
    this.Serializable$set(
        'conversationTurns',
        (parameters.conversationTurns == null) ?
            (null) :
            (parameters.conversationTurns));
    this.Serializable$set(
        'testResult',
        (parameters.testResult == null) ? (null) : (parameters.testResult));
    this.Serializable$set(
        'testTime',
        (parameters.testTime == null) ? (null) : (parameters.testTime));
  }

  static get TestResult():
      IGoogleCloudDialogflowCxV3TestCaseResultTestResultEnum {
    return GoogleCloudDialogflowCxV3TestCaseResultTestResultEnum;
  }

  get conversationTurns():
      Array<GoogleCloudDialogflowCxV3ConversationTurn>|null {
    return (
        (this.Serializable$has('conversationTurns')) ?
            (this.Serializable$get('conversationTurns')) :
            (null));
  }

  /**
   * The conversation turns uttered during the test case replay in chronological
   * order.
   */
  set conversationTurns(value: Array<GoogleCloudDialogflowCxV3ConversationTurn>|
                        null) {
    this.Serializable$set('conversationTurns', value);
  }

  get environment(): string|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * Environment where the test was run. If not set, it indicates the draft
   * environment.
   */
  set environment(value: string|null) {
    this.Serializable$set('environment', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The resource name for the test case result. Format:
   * `projects//locations//agents//testCases/ /results/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get testResult(): GoogleCloudDialogflowCxV3TestCaseResultTestResult|null {
    return (
        (this.Serializable$has('testResult')) ?
            (this.Serializable$get('testResult')) :
            (null));
  }

  /**
   * Whether the test case passed in the agent environment.
   */
  set testResult(value: GoogleCloudDialogflowCxV3TestCaseResultTestResult|
                 null) {
    this.Serializable$set('testResult', value);
  }

  get testTime(): string|null {
    return (
        (this.Serializable$has('testTime')) ?
            (this.Serializable$get('testTime')) :
            (null));
  }

  /**
   * The time that the test was run.
   */
  set testTime(value: string|null) {
    this.Serializable$set('testTime', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3TestCaseResult> {
    return GoogleCloudDialogflowCxV3TestCaseResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'conversationTurns': GoogleCloudDialogflowCxV3ConversationTurn},
      enums:
          {'testResult': GoogleCloudDialogflowCxV3TestCaseResultTestResultEnum},
      keys:
          ['conversationTurns', 'environment', 'name', 'testResult', 'testTime']
    };
  }
}

export interface GoogleCloudDialogflowCxV3TestConfigParameters {
  trackingParameters?: Array<string>|null;
  flow?: string|null;
  page?: string|null;
}
export class GoogleCloudDialogflowCxV3TestConfig extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3TestConfigParameters = {}) {
    super();
    this.Serializable$set(
        'trackingParameters',
        (parameters.trackingParameters == null) ?
            (null) :
            (parameters.trackingParameters));
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
    this.Serializable$set(
        'page', (parameters.page == null) ? (null) : (parameters.page));
  }

  get flow(): string|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * Flow name to start the test case with. Format:
   * `projects//locations//agents//flows/`. Only one of `flow` and `page` should
   * be set to indicate the starting point of the test case. If both are set,
   * `page` takes precedence over `flow`. If neither is set, the test case will
   * start with start page on the default start flow.
   */
  set flow(value: string|null) {
    this.Serializable$set('flow', value);
  }

  get page(): string|null {
    return (
        (this.Serializable$has('page')) ? (this.Serializable$get('page')) :
                                          (null));
  }

  /**
   * The page to start the test case with. Format:
   * `projects//locations//agents//flows//pages/`. Only one of `flow` and `page`
   * should be set to indicate the starting point of the test case. If both are
   * set, `page` takes precedence over `flow`. If neither is set, the test case
   * will start with start page on the default start flow.
   */
  set page(value: string|null) {
    this.Serializable$set('page', value);
  }

  get trackingParameters(): Array<string>|null {
    return (
        (this.Serializable$has('trackingParameters')) ?
            (this.Serializable$get('trackingParameters')) :
            (null));
  }

  /**
   * Session parameters to be compared when calculating differences.
   */
  set trackingParameters(value: Array<string>|null) {
    this.Serializable$set('trackingParameters', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3TestConfig> {
    return GoogleCloudDialogflowCxV3TestConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flow', 'page', 'trackingParameters']};
  }
}

export interface GoogleCloudDialogflowCxV3TestErrorParameters {
  testCase?: string|null;
  status?: GoogleRpcStatus|null;
  testTime?: string|null;
}
export class GoogleCloudDialogflowCxV3TestError extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3TestErrorParameters = {}) {
    super();
    this.Serializable$set(
        'testCase',
        (parameters.testCase == null) ? (null) : (parameters.testCase));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
    this.Serializable$set(
        'testTime',
        (parameters.testTime == null) ? (null) : (parameters.testTime));
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * The status associated with the test.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  get testCase(): string|null {
    return (
        (this.Serializable$has('testCase')) ?
            (this.Serializable$get('testCase')) :
            (null));
  }

  /**
   * The test case resource name.
   */
  set testCase(value: string|null) {
    this.Serializable$set('testCase', value);
  }

  get testTime(): string|null {
    return (
        (this.Serializable$has('testTime')) ?
            (this.Serializable$get('testTime')) :
            (null));
  }

  /**
   * The timestamp when the test was completed.
   */
  set testTime(value: string|null) {
    this.Serializable$set('testTime', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3TestError> {
    return GoogleCloudDialogflowCxV3TestError;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['status', 'testCase', 'testTime'],
      objects: {'status': GoogleRpcStatus}
    };
  }
}

export interface GoogleCloudDialogflowCxV3TestRunDifferenceParameters {
  type?: GoogleCloudDialogflowCxV3TestRunDifferenceType|null;
  description?: string|null;
}
export class GoogleCloudDialogflowCxV3TestRunDifference extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3TestRunDifferenceParameters = {}) {
    super();
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
  }

  static get Type(): IGoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum {
    return GoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum;
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * A description of the diff, showing the actual output vs expected output.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get type(): GoogleCloudDialogflowCxV3TestRunDifferenceType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * The type of diff.
   */
  set type(value: GoogleCloudDialogflowCxV3TestRunDifferenceType|null) {
    this.Serializable$set('type', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3TestRunDifference> {
    return GoogleCloudDialogflowCxV3TestRunDifference;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'type': GoogleCloudDialogflowCxV3TestRunDifferenceTypeEnum},
      keys: ['description', 'type']
    };
  }
}

export interface GoogleCloudDialogflowCxV3TextInputParameters {
  text?: string|null;
}
export class GoogleCloudDialogflowCxV3TextInput extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3TextInputParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. The UTF-8 encoded natural language text to be processed. Text
   * length must not exceed 256 characters.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3TextInput> {
    return GoogleCloudDialogflowCxV3TextInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['text']};
  }
}

export interface GoogleCloudDialogflowCxV3TransitionRouteParameters {
  name?: string|null;
  intent?: string|null;
  condition?: string|null;
  triggerFulfillment?: GoogleCloudDialogflowCxV3Fulfillment|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowCxV3TransitionRoute extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3TransitionRouteParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'triggerFulfillment',
        (parameters.triggerFulfillment == null) ?
            (null) :
            (parameters.triggerFulfillment));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition to evaluate against form parameters or session parameters.
   * See the [conditions
   * reference](https://cloud.google.com/dialogflow/cx/docs/reference/condition).
   * At least one of `intent` or `condition` must be specified. When both
   * `intent` and `condition` are specified, the transition can only happen when
   * both are fulfilled.
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The unique identifier of an Intent. Format:
   * `projects//locations//agents//intents/`. Indicates that the transition can
   * only happen when the given intent is matched. At least one of `intent` or
   * `condition` must be specified. When both `intent` and `condition` are
   * specified, the transition can only happen when both are fulfilled.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this transition route.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  get triggerFulfillment(): GoogleCloudDialogflowCxV3Fulfillment|null {
    return (
        (this.Serializable$has('triggerFulfillment')) ?
            (this.Serializable$get('triggerFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to call when the condition is satisfied. At least one of
   * `trigger_fulfillment` and `target` must be specified. When both are
   * defined, `trigger_fulfillment` is executed first.
   */
  set triggerFulfillment(value: GoogleCloudDialogflowCxV3Fulfillment|null) {
    this.Serializable$set('triggerFulfillment', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3TransitionRoute> {
    return GoogleCloudDialogflowCxV3TransitionRoute;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'condition', 'intent', 'name', 'targetFlow', 'targetPage',
        'triggerFulfillment'
      ],
      objects: {'triggerFulfillment': GoogleCloudDialogflowCxV3Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowCxV3UpdateDocumentOperationMetadataParameters {
  genericMetadata?: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|
      null;
}
export class GoogleCloudDialogflowCxV3UpdateDocumentOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3UpdateDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata|null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3UpdateDocumentOperationMetadata> {
    return GoogleCloudDialogflowCxV3UpdateDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowCxV3GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3WebhookParameters {
  name?: string|null;
  displayName?: string|null;
  cloudFunction?: GoogleCloudDialogflowCxV3WebhookCloudFunction|null;
  genericWebService?: GoogleCloudDialogflowCxV3WebhookGenericWebService|null;
  serviceDirectory?: GoogleCloudDialogflowCxV3WebhookServiceDirectoryConfig|
      null;
  timeout?: string|null;
  disabled?: boolean|null;
}
export class GoogleCloudDialogflowCxV3Webhook extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3WebhookParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'cloudFunction',
        (parameters.cloudFunction == null) ? (null) :
                                             (parameters.cloudFunction));
    this.Serializable$set(
        'genericWebService',
        (parameters.genericWebService == null) ?
            (null) :
            (parameters.genericWebService));
    this.Serializable$set(
        'serviceDirectory',
        (parameters.serviceDirectory == null) ? (null) :
                                                (parameters.serviceDirectory));
    this.Serializable$set(
        'timeout',
        (parameters.timeout == null) ? (null) : (parameters.timeout));
    this.Serializable$set(
        'disabled',
        (parameters.disabled == null) ? (null) : (parameters.disabled));
  }

  get cloudFunction(): GoogleCloudDialogflowCxV3WebhookCloudFunction|null {
    return (
        (this.Serializable$has('cloudFunction')) ?
            (this.Serializable$get('cloudFunction')) :
            (null));
  }

  /**
   * Configuration for a [Cloud Function](https://cloud.google.com/functions/).
   */
  set cloudFunction(value: GoogleCloudDialogflowCxV3WebhookCloudFunction|null) {
    this.Serializable$set('cloudFunction', value);
  }

  get disabled(): boolean|null {
    return (
        (this.Serializable$has('disabled')) ?
            (this.Serializable$get('disabled')) :
            (null));
  }

  /**
   * Indicates whether the webhook is disabled.
   */
  set disabled(value: boolean|null) {
    this.Serializable$set('disabled', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the webhook, unique within the agent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get genericWebService(): GoogleCloudDialogflowCxV3WebhookGenericWebService
      |null {
    return (
        (this.Serializable$has('genericWebService')) ?
            (this.Serializable$get('genericWebService')) :
            (null));
  }

  /**
   * Configuration for a generic web service.
   */
  set genericWebService(value:
                            GoogleCloudDialogflowCxV3WebhookGenericWebService|
                        null) {
    this.Serializable$set('genericWebService', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the webhook. Required for the
   * Webhooks.UpdateWebhook method. Webhooks.CreateWebhook populates the name
   * automatically. Format: `projects//locations//agents//webhooks/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get serviceDirectory(): GoogleCloudDialogflowCxV3WebhookServiceDirectoryConfig
      |null {
    return (
        (this.Serializable$has('serviceDirectory')) ?
            (this.Serializable$get('serviceDirectory')) :
            (null));
  }

  /**
   * Configuration for a [Service
   * Directory](https://cloud.google.com/service-directory) service.
   */
  set serviceDirectory(
      value: GoogleCloudDialogflowCxV3WebhookServiceDirectoryConfig|null) {
    this.Serializable$set('serviceDirectory', value);
  }

  get timeout(): string|null {
    return (
        (this.Serializable$has('timeout')) ?
            (this.Serializable$get('timeout')) :
            (null));
  }

  /**
   * Webhook execution timeout. Execution is considered failed if Dialogflow
   * doesn't receive a response from webhook at the end of the timeout period.
   * Defaults to 5 seconds, maximum allowed timeout is 30 seconds.
   */
  set timeout(value: string|null) {
    this.Serializable$set('timeout', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3Webhook> {
    return GoogleCloudDialogflowCxV3Webhook;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'cloudFunction', 'disabled', 'displayName', 'genericWebService', 'name',
        'serviceDirectory', 'timeout'
      ],
      objects: {
        'cloudFunction': GoogleCloudDialogflowCxV3WebhookCloudFunction,
        'genericWebService': GoogleCloudDialogflowCxV3WebhookGenericWebService,
        'serviceDirectory':
            GoogleCloudDialogflowCxV3WebhookServiceDirectoryConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3WebhookCloudFunctionParameters {
  uri?: string|null;
  requestHeaders?: ApiClientObjectMap<string>|null;
}
export class GoogleCloudDialogflowCxV3WebhookCloudFunction extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3WebhookCloudFunctionParameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'requestHeaders',
        (parameters.requestHeaders == null) ? (null) :
                                              (parameters.requestHeaders));
  }

  get requestHeaders(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('requestHeaders')) ?
            (this.Serializable$get('requestHeaders')) :
            (null));
  }

  /**
   * The HTTP request headers to send together with webhook requests.
   */
  set requestHeaders(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('requestHeaders', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The URI for the cloud function.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3WebhookCloudFunction> {
    return GoogleCloudDialogflowCxV3WebhookCloudFunction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['requestHeaders', 'uri'],
      objectMaps: {
        'requestHeaders': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3WebhookConflictDiffParameters {
  displayName?: string|null;
  originalWebhookId?: string|null;
  importedWebhookId?: string|null;
}
export class GoogleCloudDialogflowCxV3WebhookConflictDiff extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3WebhookConflictDiffParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'originalWebhookId',
        (parameters.originalWebhookId == null) ?
            (null) :
            (parameters.originalWebhookId));
    this.Serializable$set(
        'importedWebhookId',
        (parameters.importedWebhookId == null) ?
            (null) :
            (parameters.importedWebhookId));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Conflicting display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get importedWebhookId(): string|null {
    return (
        (this.Serializable$has('importedWebhookId')) ?
            (this.Serializable$get('importedWebhookId')) :
            (null));
  }

  /**
   * The name of the conflicting webhook in the content to be imported. This is
   * a unique identifier. Format: `projects//locations//agents//webhooks/`.
   */
  set importedWebhookId(value: string|null) {
    this.Serializable$set('importedWebhookId', value);
  }

  get originalWebhookId(): string|null {
    return (
        (this.Serializable$has('originalWebhookId')) ?
            (this.Serializable$get('originalWebhookId')) :
            (null));
  }

  /**
   * The name of the conflicting webhook in the current agent. This is a unique
   * identifier. Format: `projects//locations//agents//webhooks/`.
   */
  set originalWebhookId(value: string|null) {
    this.Serializable$set('originalWebhookId', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3WebhookConflictDiff> {
    return GoogleCloudDialogflowCxV3WebhookConflictDiff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'importedWebhookId', 'originalWebhookId']};
  }
}

export interface GoogleCloudDialogflowCxV3WebhookGenericWebServiceParameters {
  uri?: string|null;
  username?: string|null;
  password?: string|null;
  requestHeaders?: ApiClientObjectMap<string>|null;
  allowedCaCerts?: Array<string>|null;
}
export class GoogleCloudDialogflowCxV3WebhookGenericWebService extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3WebhookGenericWebServiceParameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'username',
        (parameters.username == null) ? (null) : (parameters.username));
    this.Serializable$set(
        'password',
        (parameters.password == null) ? (null) : (parameters.password));
    this.Serializable$set(
        'requestHeaders',
        (parameters.requestHeaders == null) ? (null) :
                                              (parameters.requestHeaders));
    this.Serializable$set(
        'allowedCaCerts',
        (parameters.allowedCaCerts == null) ? (null) :
                                              (parameters.allowedCaCerts));
  }

  get allowedCaCerts(): Array<string>|null {
    return (
        (this.Serializable$has('allowedCaCerts')) ?
            (this.Serializable$get('allowedCaCerts')) :
            (null));
  }

  /**
   * Optional. Specifies a list of allowed custom CA certificates (in DER
   * format) for HTTPS verification. This overrides the default SSL trust store.
   * If this is empty or unspecified, Dialogflow will use Google's default trust
   * store to verify certificates. N.B. Make sure the HTTPS server certificates
   * are signed with \"subject alt name\". For instance a certificate can be
   * self-signed using the following command, ``` openssl x509 -req -days 200
   * -in example.com.csr \ -signkey example.com.key \ -out example.com.crt \
   * -extfile <(printf \"\nsubjectAltName='DNS:www.example.com'\") ```
   */
  set allowedCaCerts(value: Array<string>|null) {
    this.Serializable$set('allowedCaCerts', value);
  }

  get password(): string|null {
    return (
        (this.Serializable$has('password')) ?
            (this.Serializable$get('password')) :
            (null));
  }

  /**
   * The password for HTTP Basic authentication.
   */
  set password(value: string|null) {
    this.Serializable$set('password', value);
  }

  get requestHeaders(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('requestHeaders')) ?
            (this.Serializable$get('requestHeaders')) :
            (null));
  }

  /**
   * The HTTP request headers to send together with webhook requests.
   */
  set requestHeaders(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('requestHeaders', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The webhook URI for receiving POST requests. It must use https
   * protocol.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  get username(): string|null {
    return (
        (this.Serializable$has('username')) ?
            (this.Serializable$get('username')) :
            (null));
  }

  /**
   * The user name for HTTP Basic authentication.
   */
  set username(value: string|null) {
    this.Serializable$set('username', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3WebhookGenericWebService> {
    return GoogleCloudDialogflowCxV3WebhookGenericWebService;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['allowedCaCerts', 'password', 'requestHeaders', 'uri', 'username'],
      objectMaps: {
        'requestHeaders': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3WebhookRequestParameters {
  detectIntentResponseId?: string|null;
  text?: string|null;
  triggerIntent?: string|null;
  transcript?: string|null;
  triggerEvent?: string|null;
  languageCode?: string|null;
  fulfillmentInfo?: GoogleCloudDialogflowCxV3WebhookRequestFulfillmentInfo|null;
  intentInfo?: GoogleCloudDialogflowCxV3WebhookRequestIntentInfo|null;
  pageInfo?: GoogleCloudDialogflowCxV3PageInfo|null;
  sessionInfo?: GoogleCloudDialogflowCxV3SessionInfo|null;
  messages?: Array<GoogleCloudDialogflowCxV3ResponseMessage>|null;
  payload?: ApiClientObjectMap<any>|null;
  sentimentAnalysisResult?:
      GoogleCloudDialogflowCxV3WebhookRequestSentimentAnalysisResult|null;
}
export class GoogleCloudDialogflowCxV3WebhookRequest extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3WebhookRequestParameters = {}) {
    super();
    this.Serializable$set(
        'detectIntentResponseId',
        (parameters.detectIntentResponseId == null) ?
            (null) :
            (parameters.detectIntentResponseId));
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'triggerIntent',
        (parameters.triggerIntent == null) ? (null) :
                                             (parameters.triggerIntent));
    this.Serializable$set(
        'transcript',
        (parameters.transcript == null) ? (null) : (parameters.transcript));
    this.Serializable$set(
        'triggerEvent',
        (parameters.triggerEvent == null) ? (null) : (parameters.triggerEvent));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'fulfillmentInfo',
        (parameters.fulfillmentInfo == null) ? (null) :
                                               (parameters.fulfillmentInfo));
    this.Serializable$set(
        'intentInfo',
        (parameters.intentInfo == null) ? (null) : (parameters.intentInfo));
    this.Serializable$set(
        'pageInfo',
        (parameters.pageInfo == null) ? (null) : (parameters.pageInfo));
    this.Serializable$set(
        'sessionInfo',
        (parameters.sessionInfo == null) ? (null) : (parameters.sessionInfo));
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'sentimentAnalysisResult',
        (parameters.sentimentAnalysisResult == null) ?
            (null) :
            (parameters.sentimentAnalysisResult));
  }

  get detectIntentResponseId(): string|null {
    return (
        (this.Serializable$has('detectIntentResponseId')) ?
            (this.Serializable$get('detectIntentResponseId')) :
            (null));
  }

  /**
   * Always present. The unique identifier of the DetectIntentResponse that will
   * be returned to the API caller.
   */
  set detectIntentResponseId(value: string|null) {
    this.Serializable$set('detectIntentResponseId', value);
  }

  get fulfillmentInfo(): GoogleCloudDialogflowCxV3WebhookRequestFulfillmentInfo
      |null {
    return (
        (this.Serializable$has('fulfillmentInfo')) ?
            (this.Serializable$get('fulfillmentInfo')) :
            (null));
  }

  /**
   * Always present. Information about the fulfillment that triggered this
   * webhook call.
   */
  set fulfillmentInfo(
      value: GoogleCloudDialogflowCxV3WebhookRequestFulfillmentInfo|null) {
    this.Serializable$set('fulfillmentInfo', value);
  }

  get intentInfo(): GoogleCloudDialogflowCxV3WebhookRequestIntentInfo|null {
    return (
        (this.Serializable$has('intentInfo')) ?
            (this.Serializable$get('intentInfo')) :
            (null));
  }

  /**
   * Information about the last matched intent.
   */
  set intentInfo(value: GoogleCloudDialogflowCxV3WebhookRequestIntentInfo|
                 null) {
    this.Serializable$set('intentInfo', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language code specified in the original request.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get messages(): Array<GoogleCloudDialogflowCxV3ResponseMessage>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The list of rich message responses to present to the user. Webhook can
   * choose to append or replace this list in
   * WebhookResponse.fulfillment_response;
   */
  set messages(value: Array<GoogleCloudDialogflowCxV3ResponseMessage>|null) {
    this.Serializable$set('messages', value);
  }

  get pageInfo(): GoogleCloudDialogflowCxV3PageInfo|null {
    return (
        (this.Serializable$has('pageInfo')) ?
            (this.Serializable$get('pageInfo')) :
            (null));
  }

  /**
   * Information about page status.
   */
  set pageInfo(value: GoogleCloudDialogflowCxV3PageInfo|null) {
    this.Serializable$set('pageInfo', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Custom data set in QueryParameters.payload.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get sentimentAnalysisResult():
      GoogleCloudDialogflowCxV3WebhookRequestSentimentAnalysisResult|null {
    return (
        (this.Serializable$has('sentimentAnalysisResult')) ?
            (this.Serializable$get('sentimentAnalysisResult')) :
            (null));
  }

  /**
   * The sentiment analysis result of the current user request. The field is
   * filled when sentiment analysis is configured to be enabled for the request.
   */
  set sentimentAnalysisResult(
      value: GoogleCloudDialogflowCxV3WebhookRequestSentimentAnalysisResult|
      null) {
    this.Serializable$set('sentimentAnalysisResult', value);
  }

  get sessionInfo(): GoogleCloudDialogflowCxV3SessionInfo|null {
    return (
        (this.Serializable$has('sessionInfo')) ?
            (this.Serializable$get('sessionInfo')) :
            (null));
  }

  /**
   * Information about session status.
   */
  set sessionInfo(value: GoogleCloudDialogflowCxV3SessionInfo|null) {
    this.Serializable$set('sessionInfo', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * If natural language text was provided as input, this field will contain a
   * copy of the text.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  get transcript(): string|null {
    return (
        (this.Serializable$has('transcript')) ?
            (this.Serializable$get('transcript')) :
            (null));
  }

  /**
   * If natural language speech audio was provided as input, this field will
   * contain the transcript for the audio.
   */
  set transcript(value: string|null) {
    this.Serializable$set('transcript', value);
  }

  get triggerEvent(): string|null {
    return (
        (this.Serializable$has('triggerEvent')) ?
            (this.Serializable$get('triggerEvent')) :
            (null));
  }

  /**
   * If an event was provided as input, this field will contain the name of the
   * event.
   */
  set triggerEvent(value: string|null) {
    this.Serializable$set('triggerEvent', value);
  }

  get triggerIntent(): string|null {
    return (
        (this.Serializable$has('triggerIntent')) ?
            (this.Serializable$get('triggerIntent')) :
            (null));
  }

  /**
   * If an intent was provided as input, this field will contain a copy of the
   * intent identifier. Format: `projects//locations//agents//intents/`.
   */
  set triggerIntent(value: string|null) {
    this.Serializable$set('triggerIntent', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3WebhookRequest> {
    return GoogleCloudDialogflowCxV3WebhookRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'messages': GoogleCloudDialogflowCxV3ResponseMessage},
      keys: [
        'detectIntentResponseId', 'fulfillmentInfo', 'intentInfo',
        'languageCode', 'messages', 'pageInfo', 'payload',
        'sentimentAnalysisResult', 'sessionInfo', 'text', 'transcript',
        'triggerEvent', 'triggerIntent'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'fulfillmentInfo':
            GoogleCloudDialogflowCxV3WebhookRequestFulfillmentInfo,
        'intentInfo': GoogleCloudDialogflowCxV3WebhookRequestIntentInfo,
        'pageInfo': GoogleCloudDialogflowCxV3PageInfo,
        'sentimentAnalysisResult':
            GoogleCloudDialogflowCxV3WebhookRequestSentimentAnalysisResult,
        'sessionInfo': GoogleCloudDialogflowCxV3SessionInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3WebhookRequestFulfillmentInfoParameters {
  tag?: string|null;
}
export class GoogleCloudDialogflowCxV3WebhookRequestFulfillmentInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3WebhookRequestFulfillmentInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'tag', (parameters.tag == null) ? (null) : (parameters.tag));
  }

  get tag(): string|null {
    return (
        (this.Serializable$has('tag')) ? (this.Serializable$get('tag')) :
                                         (null));
  }

  /**
   * Always present. The value of the Fulfillment.tag field will be populated in
   * this field by Dialogflow when the associated webhook is called. The tag is
   * typically used by the webhook service to identify which fulfillment is
   * being called, but it could be used for other purposes.
   */
  set tag(value: string|null) {
    this.Serializable$set('tag', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3WebhookRequestFulfillmentInfo> {
    return GoogleCloudDialogflowCxV3WebhookRequestFulfillmentInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['tag']};
  }
}

export interface GoogleCloudDialogflowCxV3WebhookRequestIntentInfoParameters {
  lastMatchedIntent?: string|null;
  displayName?: string|null;
  parameters?: ApiClientObjectMap<
      GoogleCloudDialogflowCxV3WebhookRequestIntentInfoIntentParameterValue>|
      null;
  confidence?: number|null;
}
export class GoogleCloudDialogflowCxV3WebhookRequestIntentInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3WebhookRequestIntentInfoParameters = {}) {
    super();
    this.Serializable$set(
        'lastMatchedIntent',
        (parameters.lastMatchedIntent == null) ?
            (null) :
            (parameters.lastMatchedIntent));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The confidence of the matched intent. Values range from 0.0 (completely
   * uncertain) to 1.0 (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Always present. The display name of the last matched intent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get lastMatchedIntent(): string|null {
    return (
        (this.Serializable$has('lastMatchedIntent')) ?
            (this.Serializable$get('lastMatchedIntent')) :
            (null));
  }

  /**
   * Always present. The unique identifier of the last matched intent. Format:
   * `projects//locations//agents//intents/`.
   */
  set lastMatchedIntent(value: string|null) {
    this.Serializable$set('lastMatchedIntent', value);
  }

  get parameters(): ApiClientObjectMap<
      GoogleCloudDialogflowCxV3WebhookRequestIntentInfoIntentParameterValue>|
      null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Parameters identified as a result of intent matching. This is a map of the
   * name of the identified parameter to the value of the parameter identified
   * from the user's utterance. All parameters defined in the matched intent
   * that are identified will be surfaced here.
   */
  set parameters(
      value: ApiClientObjectMap<
          GoogleCloudDialogflowCxV3WebhookRequestIntentInfoIntentParameterValue>|
      null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3WebhookRequestIntentInfo> {
    return GoogleCloudDialogflowCxV3WebhookRequestIntentInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['confidence', 'displayName', 'lastMatchedIntent', 'parameters'],
      objectMaps: {
        'parameters': {
          ctor:
              GoogleCloudDialogflowCxV3WebhookRequestIntentInfoIntentParameterValue,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3WebhookRequestIntentInfoIntentParameterValueParameters {
  originalValue?: string|null;
  resolvedValue?: any|null;
}
export class
    GoogleCloudDialogflowCxV3WebhookRequestIntentInfoIntentParameterValue extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3WebhookRequestIntentInfoIntentParameterValueParameters =
              {}) {
    super();
    this.Serializable$set(
        'originalValue',
        (parameters.originalValue == null) ? (null) :
                                             (parameters.originalValue));
    this.Serializable$set(
        'resolvedValue',
        (parameters.resolvedValue == null) ? (null) :
                                             (parameters.resolvedValue));
  }

  get originalValue(): string|null {
    return (
        (this.Serializable$has('originalValue')) ?
            (this.Serializable$get('originalValue')) :
            (null));
  }

  /**
   * Always present. Original text value extracted from user utterance.
   */
  set originalValue(value: string|null) {
    this.Serializable$set('originalValue', value);
  }

  get resolvedValue(): any|null {
    return (
        (this.Serializable$has('resolvedValue')) ?
            (this.Serializable$get('resolvedValue')) :
            (null));
  }

  /**
   * Always present. Structured value for the parameter extracted from user
   * utterance.
   */
  set resolvedValue(value: any|null) {
    this.Serializable$set('resolvedValue', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3WebhookRequestIntentInfoIntentParameterValue> {
    return GoogleCloudDialogflowCxV3WebhookRequestIntentInfoIntentParameterValue;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['originalValue', 'resolvedValue']};
  }
}

export interface GoogleCloudDialogflowCxV3WebhookRequestSentimentAnalysisResultParameters {
  score?: number|null;
  magnitude?: number|null;
}
export class
    GoogleCloudDialogflowCxV3WebhookRequestSentimentAnalysisResult extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3WebhookRequestSentimentAnalysisResultParameters =
              {}) {
    super();
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'magnitude',
        (parameters.magnitude == null) ? (null) : (parameters.magnitude));
  }

  get magnitude(): number|null {
    return (
        (this.Serializable$has('magnitude')) ?
            (this.Serializable$get('magnitude')) :
            (null));
  }

  /**
   * A non-negative number in the [0, +inf) range, which represents the absolute
   * magnitude of sentiment, regardless of score (positive or negative).
   */
  set magnitude(value: number|null) {
    this.Serializable$set('magnitude', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Sentiment score between -1.0 (negative sentiment) and 1.0 (positive
   * sentiment).
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3WebhookRequestSentimentAnalysisResult> {
    return GoogleCloudDialogflowCxV3WebhookRequestSentimentAnalysisResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['magnitude', 'score']};
  }
}

export interface GoogleCloudDialogflowCxV3WebhookResponseParameters {
  fulfillmentResponse?:
      GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponse|null;
  pageInfo?: GoogleCloudDialogflowCxV3PageInfo|null;
  sessionInfo?: GoogleCloudDialogflowCxV3SessionInfo|null;
  payload?: ApiClientObjectMap<any>|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowCxV3WebhookResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3WebhookResponseParameters = {}) {
    super();
    this.Serializable$set(
        'fulfillmentResponse',
        (parameters.fulfillmentResponse == null) ?
            (null) :
            (parameters.fulfillmentResponse));
    this.Serializable$set(
        'pageInfo',
        (parameters.pageInfo == null) ? (null) : (parameters.pageInfo));
    this.Serializable$set(
        'sessionInfo',
        (parameters.sessionInfo == null) ? (null) : (parameters.sessionInfo));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get fulfillmentResponse():
      GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponse|null {
    return (
        (this.Serializable$has('fulfillmentResponse')) ?
            (this.Serializable$get('fulfillmentResponse')) :
            (null));
  }

  /**
   * The fulfillment response to send to the user. This field can be omitted by
   * the webhook if it does not intend to send any response to the user.
   */
  set fulfillmentResponse(
      value: GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponse|null) {
    this.Serializable$set('fulfillmentResponse', value);
  }

  get pageInfo(): GoogleCloudDialogflowCxV3PageInfo|null {
    return (
        (this.Serializable$has('pageInfo')) ?
            (this.Serializable$get('pageInfo')) :
            (null));
  }

  /**
   * Information about page status. This field can be omitted by the webhook if
   * it does not intend to modify page status.
   */
  set pageInfo(value: GoogleCloudDialogflowCxV3PageInfo|null) {
    this.Serializable$set('pageInfo', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Value to append directly to QueryResult.webhook_payloads.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get sessionInfo(): GoogleCloudDialogflowCxV3SessionInfo|null {
    return (
        (this.Serializable$has('sessionInfo')) ?
            (this.Serializable$get('sessionInfo')) :
            (null));
  }

  /**
   * Information about session status. This field can be omitted by the webhook
   * if it does not intend to modify session status.
   */
  set sessionInfo(value: GoogleCloudDialogflowCxV3SessionInfo|null) {
    this.Serializable$set('sessionInfo', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3WebhookResponse> {
    return GoogleCloudDialogflowCxV3WebhookResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'fulfillmentResponse', 'pageInfo', 'payload', 'sessionInfo',
        'targetFlow', 'targetPage'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'fulfillmentResponse':
            GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponse,
        'pageInfo': GoogleCloudDialogflowCxV3PageInfo,
        'sessionInfo': GoogleCloudDialogflowCxV3SessionInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseParameters {
  messages?: Array<GoogleCloudDialogflowCxV3ResponseMessage>|null;
  mergeBehavior?:
      GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior|
      null;
}
export class GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'mergeBehavior',
        (parameters.mergeBehavior == null) ? (null) :
                                             (parameters.mergeBehavior));
  }

  static get MergeBehavior():
      IGoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehaviorEnum {
    return GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehaviorEnum;
  }

  get mergeBehavior():
      GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior
      |null {
    return (
        (this.Serializable$has('mergeBehavior')) ?
            (this.Serializable$get('mergeBehavior')) :
            (null));
  }

  /**
   * Merge behavior for `messages`.
   */
  set mergeBehavior(
      value:
          GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehavior|
      null) {
    this.Serializable$set('mergeBehavior', value);
  }

  get messages(): Array<GoogleCloudDialogflowCxV3ResponseMessage>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The list of rich message responses to present to the user.
   */
  set messages(value: Array<GoogleCloudDialogflowCxV3ResponseMessage>|null) {
    this.Serializable$set('messages', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponse> {
    return GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'messages': GoogleCloudDialogflowCxV3ResponseMessage},
      enums: {
        'mergeBehavior':
            GoogleCloudDialogflowCxV3WebhookResponseFulfillmentResponseMergeBehaviorEnum
      },
      keys: ['mergeBehavior', 'messages']
    };
  }
}

export interface GoogleCloudDialogflowCxV3WebhookServiceDirectoryConfigParameters {
  service?: string|null;
  genericWebService?: GoogleCloudDialogflowCxV3WebhookGenericWebService|null;
}
export class GoogleCloudDialogflowCxV3WebhookServiceDirectoryConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3WebhookServiceDirectoryConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'service',
        (parameters.service == null) ? (null) : (parameters.service));
    this.Serializable$set(
        'genericWebService',
        (parameters.genericWebService == null) ?
            (null) :
            (parameters.genericWebService));
  }

  get genericWebService(): GoogleCloudDialogflowCxV3WebhookGenericWebService
      |null {
    return (
        (this.Serializable$has('genericWebService')) ?
            (this.Serializable$get('genericWebService')) :
            (null));
  }

  /**
   * Generic Service configuration of this webhook.
   */
  set genericWebService(value:
                            GoogleCloudDialogflowCxV3WebhookGenericWebService|
                        null) {
    this.Serializable$set('genericWebService', value);
  }

  get service(): string|null {
    return (
        (this.Serializable$has('service')) ?
            (this.Serializable$get('service')) :
            (null));
  }

  /**
   * Required. The name of [Service
   * Directory](https://cloud.google.com/service-directory) service. Format:
   * `projects//locations//namespaces//services/`. `Location ID` of the service
   * directory must be the same as the location of the agent.
   */
  set service(value: string|null) {
    this.Serializable$set('service', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3WebhookServiceDirectoryConfig> {
    return GoogleCloudDialogflowCxV3WebhookServiceDirectoryConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericWebService', 'service'],
      objects: {
        'genericWebService': GoogleCloudDialogflowCxV3WebhookGenericWebService
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3WebhookSettingsParameters {
  disableTransition?: boolean|null;
  disableGenerateAgentResponse?: boolean|null;
  enforceParameterDefinitions?: boolean|null;
}
export class GoogleCloudDialogflowCxV3WebhookSettings extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3WebhookSettingsParameters = {}) {
    super();
    this.Serializable$set(
        'disableTransition',
        (parameters.disableTransition == null) ?
            (null) :
            (parameters.disableTransition));
    this.Serializable$set(
        'disableGenerateAgentResponse',
        (parameters.disableGenerateAgentResponse == null) ?
            (null) :
            (parameters.disableGenerateAgentResponse));
    this.Serializable$set(
        'enforceParameterDefinitions',
        (parameters.enforceParameterDefinitions == null) ?
            (null) :
            (parameters.enforceParameterDefinitions));
  }

  get disableGenerateAgentResponse(): boolean|null {
    return (
        (this.Serializable$has('disableGenerateAgentResponse')) ?
            (this.Serializable$get('disableGenerateAgentResponse')) :
            (null));
  }

  /**
   * Whether the webhook can generate agent responses. If this flag is true,
   * `fulfillment_response` will be ignored in the WebhookResponse.
   */
  set disableGenerateAgentResponse(value: boolean|null) {
    this.Serializable$set('disableGenerateAgentResponse', value);
  }

  get disableTransition(): boolean|null {
    return (
        (this.Serializable$has('disableTransition')) ?
            (this.Serializable$get('disableTransition')) :
            (null));
  }

  /**
   * Whether a webhook can transit the conversation to another page or flow. If
   * this flag is true, `target_page` and `target_flow` are ignored in the
   * WebhookResponse.
   */
  set disableTransition(value: boolean|null) {
    this.Serializable$set('disableTransition', value);
  }

  get enforceParameterDefinitions(): boolean|null {
    return (
        (this.Serializable$has('enforceParameterDefinitions')) ?
            (this.Serializable$get('enforceParameterDefinitions')) :
            (null));
  }

  /**
   * Whether parameter definitions will be enforced: 1. Only parameters in the
   * input parameters can be sent to the webhook. 2. Only parameters in the
   * output parameters can be modified by the webhook. 3. Parameter types will
   * be checked and only set if they match the type. Parameter types are defined
   * in the agent.
   */
  set enforceParameterDefinitions(value: boolean|null) {
    this.Serializable$set('enforceParameterDefinitions', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3WebhookSettings> {
    return GoogleCloudDialogflowCxV3WebhookSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'disableGenerateAgentResponse', 'disableTransition',
        'enforceParameterDefinitions'
      ]
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1AdvancedSettingsParameters {
  enabled?: boolean|null;
  audioExportGcsDestination?: GoogleCloudDialogflowCxV3beta1GcsDestination|null;
  speechSettings?: GoogleCloudDialogflowCxV3beta1AdvancedSettingsSpeechSettings|
      null;
  playbackInterruptionSettings?:
      GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings|null;
  dtmfSettings?: GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings|
      null;
  loggingSettings?:
      GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings|null;
  requestLoggingSettings?:
      GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings|null;
  dtmfSettingsList?:
      Array<GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings>|null;
}
export class GoogleCloudDialogflowCxV3beta1AdvancedSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1AdvancedSettingsParameters = {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'audioExportGcsDestination',
        (parameters.audioExportGcsDestination == null) ?
            (null) :
            (parameters.audioExportGcsDestination));
    this.Serializable$set(
        'speechSettings',
        (parameters.speechSettings == null) ? (null) :
                                              (parameters.speechSettings));
    this.Serializable$set(
        'playbackInterruptionSettings',
        (parameters.playbackInterruptionSettings == null) ?
            (null) :
            (parameters.playbackInterruptionSettings));
    this.Serializable$set(
        'dtmfSettings',
        (parameters.dtmfSettings == null) ? (null) : (parameters.dtmfSettings));
    this.Serializable$set(
        'loggingSettings',
        (parameters.loggingSettings == null) ? (null) :
                                               (parameters.loggingSettings));
    this.Serializable$set(
        'requestLoggingSettings',
        (parameters.requestLoggingSettings == null) ?
            (null) :
            (parameters.requestLoggingSettings));
    this.Serializable$set(
        'dtmfSettingsList',
        (parameters.dtmfSettingsList == null) ? (null) :
                                                (parameters.dtmfSettingsList));
  }

  get audioExportGcsDestination(): GoogleCloudDialogflowCxV3beta1GcsDestination
      |null {
    return (
        (this.Serializable$has('audioExportGcsDestination')) ?
            (this.Serializable$get('audioExportGcsDestination')) :
            (null));
  }

  /**
   * If present, incoming audio is exported by Dialogflow to the configured
   * Google Cloud Storage destination. Exposed at the following levels: - Agent
   * level - Flow level
   */
  set audioExportGcsDestination(
      value: GoogleCloudDialogflowCxV3beta1GcsDestination|null) {
    this.Serializable$set('audioExportGcsDestination', value);
  }

  get dtmfSettings(): GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings
      |null {
    return (
        (this.Serializable$has('dtmfSettings')) ?
            (this.Serializable$get('dtmfSettings')) :
            (null));
  }

  /**
   * Settings for DTMF. Exposed at the following levels: - Agent level - Flow
   * level - Page level - Parameter level.
   */
  set dtmfSettings(
      value: GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings|null) {
    this.Serializable$set('dtmfSettings', value);
  }

  get dtmfSettingsList():
      Array<GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings>|null {
    return (
        (this.Serializable$has('dtmfSettingsList')) ?
            (this.Serializable$get('dtmfSettingsList')) :
            (null));
  }

  /**
   * List of DtmfSettings emitted from different levels of agent. Each
   * DtmfSettings in the list has higher priority than the ones before it in the
   * list, with the last one having the highest priority.
   */
  set dtmfSettingsList(
      value: Array<GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings>|
      null) {
    this.Serializable$set('dtmfSettingsList', value);
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * Whether advanced settings should be enabled. Exposed at the following
   * levels: - Agent level - Flow level
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get loggingSettings():
      GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings|null {
    return (
        (this.Serializable$has('loggingSettings')) ?
            (this.Serializable$get('loggingSettings')) :
            (null));
  }

  /**
   * Settings for logging. Settings for Dialogflow History, Contact Center
   * messages, StackDriver logs, and speech logging. Exposed at the following
   * levels: - Agent level.
   */
  set loggingSettings(
      value: GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings|
      null) {
    this.Serializable$set('loggingSettings', value);
  }

  get playbackInterruptionSettings():
      GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings|null {
    return (
        (this.Serializable$has('playbackInterruptionSettings')) ?
            (this.Serializable$get('playbackInterruptionSettings')) :
            (null));
  }

  /**
   * Whether to allow interruption on the playback of a message. Exposed at the
   * following levels: - Agent level - Flow level - Fulfillment level
   */
  set playbackInterruptionSettings(
      value: GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings|null) {
    this.Serializable$set('playbackInterruptionSettings', value);
  }

  get requestLoggingSettings():
      GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings|null {
    return (
        (this.Serializable$has('requestLoggingSettings')) ?
            (this.Serializable$get('requestLoggingSettings')) :
            (null));
  }

  /**
   * Output only. Indicates whether to log DetectIntentRequest for Dialogflow
   * Interaction. This field will only be filled during runtime. The storage
   * service depends on this settings to decide whether to log the request. -
   * Note: Whether to log DetectIntentResponse is controlled by
   * `logging_settings`[] above.
   */
  set requestLoggingSettings(
      value: GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings|
      null) {
    this.Serializable$set('requestLoggingSettings', value);
  }

  get speechSettings():
      GoogleCloudDialogflowCxV3beta1AdvancedSettingsSpeechSettings|null {
    return (
        (this.Serializable$has('speechSettings')) ?
            (this.Serializable$get('speechSettings')) :
            (null));
  }

  /**
   * Settings for speech to text detection. Exposed at the following levels: -
   * Agent level - Flow level - Page level - Parameter level
   */
  set speechSettings(
      value: GoogleCloudDialogflowCxV3beta1AdvancedSettingsSpeechSettings|
      null) {
    this.Serializable$set('speechSettings', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1AdvancedSettings> {
    return GoogleCloudDialogflowCxV3beta1AdvancedSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'dtmfSettingsList':
            GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings
      },
      keys: [
        'audioExportGcsDestination', 'dtmfSettings', 'dtmfSettingsList',
        'enabled', 'loggingSettings', 'playbackInterruptionSettings',
        'requestLoggingSettings', 'speechSettings'
      ],
      objects: {
        'audioExportGcsDestination':
            GoogleCloudDialogflowCxV3beta1GcsDestination,
        'dtmfSettings':
            GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings,
        'loggingSettings':
            GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings,
        'playbackInterruptionSettings':
            GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings,
        'requestLoggingSettings':
            GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings,
        'speechSettings':
            GoogleCloudDialogflowCxV3beta1AdvancedSettingsSpeechSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettingsParameters {
  enabled?: boolean|null;
  maxDigits?: number|null;
  finishDigit?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'maxDigits',
        (parameters.maxDigits == null) ? (null) : (parameters.maxDigits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * If true, incoming audio is processed for DTMF (dual tone multi frequency)
   * events. For example, if the caller presses a button on their telephone
   * keypad and DTMF processing is enabled, Dialogflow will detect the event
   * (e.g. a \"3\" was pressed) in the incoming audio and pass the event to the
   * bot to drive business logic (e.g. when 3 is pressed, return the account
   * balance).
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get finishDigit(): string|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * The digit that terminates a DTMF digit sequence.
   */
  set finishDigit(value: string|null) {
    this.Serializable$set('finishDigit', value);
  }

  get maxDigits(): number|null {
    return (
        (this.Serializable$has('maxDigits')) ?
            (this.Serializable$get('maxDigits')) :
            (null));
  }

  /**
   * Max length of DTMF digits.
   */
  set maxDigits(value: number|null) {
    this.Serializable$set('maxDigits', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings> {
    return GoogleCloudDialogflowCxV3beta1AdvancedSettingsDtmfSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enabled', 'finishDigit', 'maxDigits']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettingsParameters {
  disableLogging?: boolean|null;
  enableStackdriverLogging?: boolean|null;
  enableInteractionLogging?: boolean|null;
}
export class
    GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
    this.Serializable$set(
        'enableStackdriverLogging',
        (parameters.enableStackdriverLogging == null) ?
            (null) :
            (parameters.enableStackdriverLogging));
    this.Serializable$set(
        'enableInteractionLogging',
        (parameters.enableInteractionLogging == null) ?
            (null) :
            (parameters.enableInteractionLogging));
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * If true, DF Interaction logging is currently disabled.
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  get enableInteractionLogging(): boolean|null {
    return (
        (this.Serializable$has('enableInteractionLogging')) ?
            (this.Serializable$get('enableInteractionLogging')) :
            (null));
  }

  /**
   * If true, DF Interaction logging is currently enabled.
   */
  set enableInteractionLogging(value: boolean|null) {
    this.Serializable$set('enableInteractionLogging', value);
  }

  get enableStackdriverLogging(): boolean|null {
    return (
        (this.Serializable$has('enableStackdriverLogging')) ?
            (this.Serializable$get('enableStackdriverLogging')) :
            (null));
  }

  /**
   * If true, StackDriver logging is currently enabled.
   */
  set enableStackdriverLogging(value: boolean|null) {
    this.Serializable$set('enableStackdriverLogging', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings> {
    return GoogleCloudDialogflowCxV3beta1AdvancedSettingsLoggingSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'disableLogging', 'enableInteractionLogging', 'enableStackdriverLogging'
      ]
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1AdvancedSettingsSpeechSettingsParameters {
  endpointerSensitivity?: number|null;
  noSpeechTimeout?: string|null;
  useTimeoutBasedEndpointing?: boolean|null;
}
export class
    GoogleCloudDialogflowCxV3beta1AdvancedSettingsSpeechSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1AdvancedSettingsSpeechSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'endpointerSensitivity',
        (parameters.endpointerSensitivity == null) ?
            (null) :
            (parameters.endpointerSensitivity));
    this.Serializable$set(
        'noSpeechTimeout',
        (parameters.noSpeechTimeout == null) ? (null) :
                                               (parameters.noSpeechTimeout));
    this.Serializable$set(
        'useTimeoutBasedEndpointing',
        (parameters.useTimeoutBasedEndpointing == null) ?
            (null) :
            (parameters.useTimeoutBasedEndpointing));
  }

  get endpointerSensitivity(): number|null {
    return (
        (this.Serializable$has('endpointerSensitivity')) ?
            (this.Serializable$get('endpointerSensitivity')) :
            (null));
  }

  /**
   * Sensitivity of the speech model that detects the end of speech. Scale from
   * 0 to 100.
   */
  set endpointerSensitivity(value: number|null) {
    this.Serializable$set('endpointerSensitivity', value);
  }

  get noSpeechTimeout(): string|null {
    return (
        (this.Serializable$has('noSpeechTimeout')) ?
            (this.Serializable$get('noSpeechTimeout')) :
            (null));
  }

  /**
   * Timeout before detecting no speech.
   */
  set noSpeechTimeout(value: string|null) {
    this.Serializable$set('noSpeechTimeout', value);
  }

  get useTimeoutBasedEndpointing(): boolean|null {
    return (
        (this.Serializable$has('useTimeoutBasedEndpointing')) ?
            (this.Serializable$get('useTimeoutBasedEndpointing')) :
            (null));
  }

  /**
   * Use timeout based endpointing, interpreting endpointer sensitivy as seconds
   * of timeout value.
   */
  set useTimeoutBasedEndpointing(value: boolean|null) {
    this.Serializable$set('useTimeoutBasedEndpointing', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1AdvancedSettingsSpeechSettings> {
    return GoogleCloudDialogflowCxV3beta1AdvancedSettingsSpeechSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'endpointerSensitivity', 'noSpeechTimeout', 'useTimeoutBasedEndpointing'
      ]
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1AudioInputParameters {
  config?: GoogleCloudDialogflowCxV3beta1InputAudioConfig|null;
  audio?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1AudioInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1AudioInputParameters = {}) {
    super();
    this.Serializable$set(
        'config', (parameters.config == null) ? (null) : (parameters.config));
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
  }

  get audio(): string|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * The natural language speech audio to be processed. A single request can
   * contain up to 1 minute of speech audio data. The transcribed text cannot
   * contain more than 256 bytes. For non-streaming audio detect intent, both
   * `config` and `audio` must be provided. For streaming audio detect intent,
   * `config` must be provided in the first request and `audio` must be provided
   * in all following requests.
   */
  set audio(value: string|null) {
    this.Serializable$set('audio', value);
  }

  get config(): GoogleCloudDialogflowCxV3beta1InputAudioConfig|null {
    return (
        (this.Serializable$has('config')) ? (this.Serializable$get('config')) :
                                            (null));
  }

  /**
   * Required. Instructs the speech recognizer how to process the speech audio.
   */
  set config(value: GoogleCloudDialogflowCxV3beta1InputAudioConfig|null) {
    this.Serializable$set('config', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1AudioInput> {
    return GoogleCloudDialogflowCxV3beta1AudioInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['audio', 'config'],
      objects: {'config': GoogleCloudDialogflowCxV3beta1InputAudioConfig}
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1BargeInConfigParameters {
  noBargeInDuration?: string|null;
  totalDuration?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1BargeInConfig extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1BargeInConfigParameters = {}) {
    super();
    this.Serializable$set(
        'noBargeInDuration',
        (parameters.noBargeInDuration == null) ?
            (null) :
            (parameters.noBargeInDuration));
    this.Serializable$set(
        'totalDuration',
        (parameters.totalDuration == null) ? (null) :
                                             (parameters.totalDuration));
  }

  get noBargeInDuration(): string|null {
    return (
        (this.Serializable$has('noBargeInDuration')) ?
            (this.Serializable$get('noBargeInDuration')) :
            (null));
  }

  /**
   * Duration that is not eligible for barge-in at the beginning of the input
   * audio.
   */
  set noBargeInDuration(value: string|null) {
    this.Serializable$set('noBargeInDuration', value);
  }

  get totalDuration(): string|null {
    return (
        (this.Serializable$has('totalDuration')) ?
            (this.Serializable$get('totalDuration')) :
            (null));
  }

  /**
   * Total duration for the playback at the beginning of the input audio.
   */
  set totalDuration(value: string|null) {
    this.Serializable$set('totalDuration', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1BargeInConfig> {
    return GoogleCloudDialogflowCxV3beta1BargeInConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['noBargeInDuration', 'totalDuration']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1BatchRunTestCasesMetadataParameters {
  errors?: Array<GoogleCloudDialogflowCxV3beta1TestError>|null;
}
export class GoogleCloudDialogflowCxV3beta1BatchRunTestCasesMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1BatchRunTestCasesMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'errors', (parameters.errors == null) ? (null) : (parameters.errors));
  }

  get errors(): Array<GoogleCloudDialogflowCxV3beta1TestError>|null {
    return (
        (this.Serializable$has('errors')) ? (this.Serializable$get('errors')) :
                                            (null));
  }

  /**
   * The test errors.
   */
  set errors(value: Array<GoogleCloudDialogflowCxV3beta1TestError>|null) {
    this.Serializable$set('errors', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1BatchRunTestCasesMetadata> {
    return GoogleCloudDialogflowCxV3beta1BatchRunTestCasesMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'errors': GoogleCloudDialogflowCxV3beta1TestError},
      keys: ['errors']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1BatchRunTestCasesResponseParameters {
  results?: Array<GoogleCloudDialogflowCxV3beta1TestCaseResult>|null;
}
export class GoogleCloudDialogflowCxV3beta1BatchRunTestCasesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1BatchRunTestCasesResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'results',
        (parameters.results == null) ? (null) : (parameters.results));
  }

  get results(): Array<GoogleCloudDialogflowCxV3beta1TestCaseResult>|null {
    return (
        (this.Serializable$has('results')) ?
            (this.Serializable$get('results')) :
            (null));
  }

  /**
   * The test case results. The detailed conversation turns are empty in this
   * response.
   */
  set results(value: Array<GoogleCloudDialogflowCxV3beta1TestCaseResult>|null) {
    this.Serializable$set('results', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1BatchRunTestCasesResponse> {
    return GoogleCloudDialogflowCxV3beta1BatchRunTestCasesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'results': GoogleCloudDialogflowCxV3beta1TestCaseResult},
      keys: ['results']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1CloneAgentResponseParameters {
  name?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1CloneAgentResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1CloneAgentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The cloned agent.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1CloneAgentResponse> {
    return GoogleCloudDialogflowCxV3beta1CloneAgentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['name']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ContinuousTestResultParameters {
  name?: string|null;
  result?: GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult|null;
  testCaseResults?: Array<string>|null;
  runTime?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1ContinuousTestResult extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ContinuousTestResultParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'result', (parameters.result == null) ? (null) : (parameters.result));
    this.Serializable$set(
        'testCaseResults',
        (parameters.testCaseResults == null) ? (null) :
                                               (parameters.testCaseResults));
    this.Serializable$set(
        'runTime',
        (parameters.runTime == null) ? (null) : (parameters.runTime));
  }

  static get Result():
      IGoogleCloudDialogflowCxV3beta1ContinuousTestResultResultEnum {
    return GoogleCloudDialogflowCxV3beta1ContinuousTestResultResultEnum;
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The resource name for the continuous test result. Format:
   * `projects//locations//agents//environments//continuousTestResults/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get result(): GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult|null {
    return (
        (this.Serializable$has('result')) ? (this.Serializable$get('result')) :
                                            (null));
  }

  /**
   * The result of this continuous test run, i.e. whether all the tests in this
   * continuous test run pass or not.
   */
  set result(value: GoogleCloudDialogflowCxV3beta1ContinuousTestResultResult|
             null) {
    this.Serializable$set('result', value);
  }

  get runTime(): string|null {
    return (
        (this.Serializable$has('runTime')) ?
            (this.Serializable$get('runTime')) :
            (null));
  }

  /**
   * Time when the continuous testing run starts.
   */
  set runTime(value: string|null) {
    this.Serializable$set('runTime', value);
  }

  get testCaseResults(): Array<string>|null {
    return (
        (this.Serializable$has('testCaseResults')) ?
            (this.Serializable$get('testCaseResults')) :
            (null));
  }

  /**
   * A list of individual test case results names in this continuous test run.
   */
  set testCaseResults(value: Array<string>|null) {
    this.Serializable$set('testCaseResults', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ContinuousTestResult> {
    return GoogleCloudDialogflowCxV3beta1ContinuousTestResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'result': GoogleCloudDialogflowCxV3beta1ContinuousTestResultResultEnum
      },
      keys: ['name', 'result', 'runTime', 'testCaseResults']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ConversationTurnParameters {
  userInput?: GoogleCloudDialogflowCxV3beta1ConversationTurnUserInput|null;
  virtualAgentOutput?:
      GoogleCloudDialogflowCxV3beta1ConversationTurnVirtualAgentOutput|null;
}
export class GoogleCloudDialogflowCxV3beta1ConversationTurn extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ConversationTurnParameters = {}) {
    super();
    this.Serializable$set(
        'userInput',
        (parameters.userInput == null) ? (null) : (parameters.userInput));
    this.Serializable$set(
        'virtualAgentOutput',
        (parameters.virtualAgentOutput == null) ?
            (null) :
            (parameters.virtualAgentOutput));
  }

  get userInput(): GoogleCloudDialogflowCxV3beta1ConversationTurnUserInput
      |null {
    return (
        (this.Serializable$has('userInput')) ?
            (this.Serializable$get('userInput')) :
            (null));
  }

  /**
   * The user input.
   */
  set userInput(value: GoogleCloudDialogflowCxV3beta1ConversationTurnUserInput|
                null) {
    this.Serializable$set('userInput', value);
  }

  get virtualAgentOutput():
      GoogleCloudDialogflowCxV3beta1ConversationTurnVirtualAgentOutput|null {
    return (
        (this.Serializable$has('virtualAgentOutput')) ?
            (this.Serializable$get('virtualAgentOutput')) :
            (null));
  }

  /**
   * The virtual agent output.
   */
  set virtualAgentOutput(
      value: GoogleCloudDialogflowCxV3beta1ConversationTurnVirtualAgentOutput|
      null) {
    this.Serializable$set('virtualAgentOutput', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ConversationTurn> {
    return GoogleCloudDialogflowCxV3beta1ConversationTurn;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['userInput', 'virtualAgentOutput'],
      objects: {
        'userInput': GoogleCloudDialogflowCxV3beta1ConversationTurnUserInput,
        'virtualAgentOutput':
            GoogleCloudDialogflowCxV3beta1ConversationTurnVirtualAgentOutput
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ConversationTurnUserInputParameters {
  input?: GoogleCloudDialogflowCxV3beta1QueryInput|null;
  injectedParameters?: ApiClientObjectMap<any>|null;
  parameterScope?: string|null;
  isWebhookEnabled?: boolean|null;
  enableSentimentAnalysis?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1ConversationTurnUserInput extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ConversationTurnUserInputParameters =
              {}) {
    super();
    this.Serializable$set(
        'input', (parameters.input == null) ? (null) : (parameters.input));
    this.Serializable$set(
        'injectedParameters',
        (parameters.injectedParameters == null) ?
            (null) :
            (parameters.injectedParameters));
    this.Serializable$set(
        'parameterScope',
        (parameters.parameterScope == null) ? (null) :
                                              (parameters.parameterScope));
    this.Serializable$set(
        'isWebhookEnabled',
        (parameters.isWebhookEnabled == null) ? (null) :
                                                (parameters.isWebhookEnabled));
    this.Serializable$set(
        'enableSentimentAnalysis',
        (parameters.enableSentimentAnalysis == null) ?
            (null) :
            (parameters.enableSentimentAnalysis));
  }

  get enableSentimentAnalysis(): boolean|null {
    return (
        (this.Serializable$has('enableSentimentAnalysis')) ?
            (this.Serializable$get('enableSentimentAnalysis')) :
            (null));
  }

  /**
   * Whether sentiment analysis is enabled.
   */
  set enableSentimentAnalysis(value: boolean|null) {
    this.Serializable$set('enableSentimentAnalysis', value);
  }

  get injectedParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('injectedParameters')) ?
            (this.Serializable$get('injectedParameters')) :
            (null));
  }

  /**
   * Parameters that need to be injected into the conversation during intent
   * detection.
   */
  set injectedParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('injectedParameters', value);
  }

  get input(): GoogleCloudDialogflowCxV3beta1QueryInput|null {
    return (
        (this.Serializable$has('input')) ? (this.Serializable$get('input')) :
                                           (null));
  }

  /**
   * Supports text input, event input, dtmf input in the test case.
   */
  set input(value: GoogleCloudDialogflowCxV3beta1QueryInput|null) {
    this.Serializable$set('input', value);
  }

  get isWebhookEnabled(): boolean|null {
    return (
        (this.Serializable$has('isWebhookEnabled')) ?
            (this.Serializable$get('isWebhookEnabled')) :
            (null));
  }

  /**
   * If webhooks should be allowed to trigger in response to the user utterance.
   * Often if parameters are injected, webhooks should not be enabled.
   */
  set isWebhookEnabled(value: boolean|null) {
    this.Serializable$set('isWebhookEnabled', value);
  }

  get parameterScope(): string|null {
    return (
        (this.Serializable$has('parameterScope')) ?
            (this.Serializable$get('parameterScope')) :
            (null));
  }

  /**
   * Scope for the parameters. If not specified, parameters will be treated as
   * session parameters. It is a customized free form text, e.g. 'event',
   * 'test'.
   */
  set parameterScope(value: string|null) {
    this.Serializable$set('parameterScope', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ConversationTurnUserInput> {
    return GoogleCloudDialogflowCxV3beta1ConversationTurnUserInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'enableSentimentAnalysis', 'injectedParameters', 'input',
        'isWebhookEnabled', 'parameterScope'
      ],
      objectMaps: {
        'injectedParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'input': GoogleCloudDialogflowCxV3beta1QueryInput}
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ConversationTurnVirtualAgentOutputParameters {
  sessionParameters?: ApiClientObjectMap<any>|null;
  differences?: Array<GoogleCloudDialogflowCxV3beta1TestRunDifference>|null;
  diagnosticInfo?: ApiClientObjectMap<any>|null;
  triggeredIntent?: GoogleCloudDialogflowCxV3beta1Intent|null;
  currentPage?: GoogleCloudDialogflowCxV3beta1Page|null;
  textResponses?: Array<GoogleCloudDialogflowCxV3beta1ResponseMessageText>|null;
  status?: GoogleRpcStatus|null;
}
export class
    GoogleCloudDialogflowCxV3beta1ConversationTurnVirtualAgentOutput extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ConversationTurnVirtualAgentOutputParameters =
              {}) {
    super();
    this.Serializable$set(
        'sessionParameters',
        (parameters.sessionParameters == null) ?
            (null) :
            (parameters.sessionParameters));
    this.Serializable$set(
        'differences',
        (parameters.differences == null) ? (null) : (parameters.differences));
    this.Serializable$set(
        'diagnosticInfo',
        (parameters.diagnosticInfo == null) ? (null) :
                                              (parameters.diagnosticInfo));
    this.Serializable$set(
        'triggeredIntent',
        (parameters.triggeredIntent == null) ? (null) :
                                               (parameters.triggeredIntent));
    this.Serializable$set(
        'currentPage',
        (parameters.currentPage == null) ? (null) : (parameters.currentPage));
    this.Serializable$set(
        'textResponses',
        (parameters.textResponses == null) ? (null) :
                                             (parameters.textResponses));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
  }

  get currentPage(): GoogleCloudDialogflowCxV3beta1Page|null {
    return (
        (this.Serializable$has('currentPage')) ?
            (this.Serializable$get('currentPage')) :
            (null));
  }

  /**
   * The Page on which the utterance was spoken. Only name and displayName will
   * be set.
   */
  set currentPage(value: GoogleCloudDialogflowCxV3beta1Page|null) {
    this.Serializable$set('currentPage', value);
  }

  get diagnosticInfo(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('diagnosticInfo')) ?
            (this.Serializable$get('diagnosticInfo')) :
            (null));
  }

  /**
   * Required. Input only. The diagnostic info output for the turn. Required to
   * calculate the testing coverage.
   */
  set diagnosticInfo(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('diagnosticInfo', value);
  }

  get differences():
      Array<GoogleCloudDialogflowCxV3beta1TestRunDifference>|null {
    return (
        (this.Serializable$has('differences')) ?
            (this.Serializable$get('differences')) :
            (null));
  }

  /**
   * Output only. If this is part of a result conversation turn, the list of
   * differences between the original run and the replay for this output, if
   * any.
   */
  set differences(value: Array<GoogleCloudDialogflowCxV3beta1TestRunDifference>|
                  null) {
    this.Serializable$set('differences', value);
  }

  get sessionParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('sessionParameters')) ?
            (this.Serializable$get('sessionParameters')) :
            (null));
  }

  /**
   * The session parameters available to the bot at this point.
   */
  set sessionParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('sessionParameters', value);
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * Response error from the agent in the test result. If set, other output is
   * empty.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  get textResponses():
      Array<GoogleCloudDialogflowCxV3beta1ResponseMessageText>|null {
    return (
        (this.Serializable$has('textResponses')) ?
            (this.Serializable$get('textResponses')) :
            (null));
  }

  /**
   * The text responses from the agent for the turn.
   */
  set textResponses(
      value: Array<GoogleCloudDialogflowCxV3beta1ResponseMessageText>|null) {
    this.Serializable$set('textResponses', value);
  }

  get triggeredIntent(): GoogleCloudDialogflowCxV3beta1Intent|null {
    return (
        (this.Serializable$has('triggeredIntent')) ?
            (this.Serializable$get('triggeredIntent')) :
            (null));
  }

  /**
   * The Intent that triggered the response. Only name and displayName will be
   * set.
   */
  set triggeredIntent(value: GoogleCloudDialogflowCxV3beta1Intent|null) {
    this.Serializable$set('triggeredIntent', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ConversationTurnVirtualAgentOutput> {
    return GoogleCloudDialogflowCxV3beta1ConversationTurnVirtualAgentOutput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'differences': GoogleCloudDialogflowCxV3beta1TestRunDifference,
        'textResponses': GoogleCloudDialogflowCxV3beta1ResponseMessageText
      },
      keys: [
        'currentPage', 'diagnosticInfo', 'differences', 'sessionParameters',
        'status', 'textResponses', 'triggeredIntent'
      ],
      objectMaps: {
        'diagnosticInfo': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'sessionParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'currentPage': GoogleCloudDialogflowCxV3beta1Page,
        'status': GoogleRpcStatus,
        'triggeredIntent': GoogleCloudDialogflowCxV3beta1Intent
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1CreateDocumentOperationMetadataParameters {
  genericMetadata?:
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|null;
}
export class
    GoogleCloudDialogflowCxV3beta1CreateDocumentOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1CreateDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|
      null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1CreateDocumentOperationMetadata> {
    return GoogleCloudDialogflowCxV3beta1CreateDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1CreateLocationSettingsMetadataParameters {
}
export class
    GoogleCloudDialogflowCxV3beta1CreateLocationSettingsMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1CreateLocationSettingsMetadataParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1CreateLocationSettingsMetadata> {
    return GoogleCloudDialogflowCxV3beta1CreateLocationSettingsMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3beta1CreateVersionOperationMetadataParameters {
  version?: string|null;
}
export class
    GoogleCloudDialogflowCxV3beta1CreateVersionOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1CreateVersionOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
  }

  get version(): string|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Name of the created version. Format:
   * `projects//locations//agents//flows//versions/`.
   */
  set version(value: string|null) {
    this.Serializable$set('version', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1CreateVersionOperationMetadata> {
    return GoogleCloudDialogflowCxV3beta1CreateVersionOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['version']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1DeleteDocumentOperationMetadataParameters {
  genericMetadata?:
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|null;
}
export class
    GoogleCloudDialogflowCxV3beta1DeleteDocumentOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1DeleteDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|
      null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1DeleteDocumentOperationMetadata> {
    return GoogleCloudDialogflowCxV3beta1DeleteDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1DeployFlowMetadataParameters {
  testErrors?: Array<GoogleCloudDialogflowCxV3beta1TestError>|null;
}
export class GoogleCloudDialogflowCxV3beta1DeployFlowMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1DeployFlowMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'testErrors',
        (parameters.testErrors == null) ? (null) : (parameters.testErrors));
  }

  get testErrors(): Array<GoogleCloudDialogflowCxV3beta1TestError>|null {
    return (
        (this.Serializable$has('testErrors')) ?
            (this.Serializable$get('testErrors')) :
            (null));
  }

  /**
   * Errors of running deployment tests.
   */
  set testErrors(value: Array<GoogleCloudDialogflowCxV3beta1TestError>|null) {
    this.Serializable$set('testErrors', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1DeployFlowMetadata> {
    return GoogleCloudDialogflowCxV3beta1DeployFlowMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'testErrors': GoogleCloudDialogflowCxV3beta1TestError},
      keys: ['testErrors']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1DeployFlowResponseParameters {
  environment?: GoogleCloudDialogflowCxV3beta1Environment|null;
  deployment?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1DeployFlowResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1DeployFlowResponseParameters = {}) {
    super();
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
    this.Serializable$set(
        'deployment',
        (parameters.deployment == null) ? (null) : (parameters.deployment));
  }

  get deployment(): string|null {
    return (
        (this.Serializable$has('deployment')) ?
            (this.Serializable$get('deployment')) :
            (null));
  }

  /**
   * The name of the flow version deployment. Format:
   * `projects//locations//agents// environments//deployments/`.
   */
  set deployment(value: string|null) {
    this.Serializable$set('deployment', value);
  }

  get environment(): GoogleCloudDialogflowCxV3beta1Environment|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * The updated environment where the flow is deployed.
   */
  set environment(value: GoogleCloudDialogflowCxV3beta1Environment|null) {
    this.Serializable$set('environment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1DeployFlowResponse> {
    return GoogleCloudDialogflowCxV3beta1DeployFlowResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['deployment', 'environment'],
      objects: {'environment': GoogleCloudDialogflowCxV3beta1Environment}
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1DtmfInputParameters {
  digits?: string|null;
  finishDigit?: string|null;
  transformed?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1DtmfInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1DtmfInputParameters = {}) {
    super();
    this.Serializable$set(
        'digits', (parameters.digits == null) ? (null) : (parameters.digits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
    this.Serializable$set(
        'transformed',
        (parameters.transformed == null) ? (null) : (parameters.transformed));
  }

  get digits(): string|null {
    return (
        (this.Serializable$has('digits')) ? (this.Serializable$get('digits')) :
                                            (null));
  }

  /**
   * The dtmf digits.
   */
  set digits(value: string|null) {
    this.Serializable$set('digits', value);
  }

  get finishDigit(): string|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * The finish digit (if any).
   */
  set finishDigit(value: string|null) {
    this.Serializable$set('finishDigit', value);
  }

  get transformed(): boolean|null {
    return (
        (this.Serializable$has('transformed')) ?
            (this.Serializable$get('transformed')) :
            (null));
  }

  /**
   * Whether the DTMF digits has already been transformed into the pattern of
   * the entity type associated with the parameter to fill.
   */
  set transformed(value: boolean|null) {
    this.Serializable$set('transformed', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1DtmfInput> {
    return GoogleCloudDialogflowCxV3beta1DtmfInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['digits', 'finishDigit', 'transformed']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1EntityConflictDiffParameters {
  displayName?: string|null;
  originalEntityId?: string|null;
  importedEntityId?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1EntityConflictDiff extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1EntityConflictDiffParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'originalEntityId',
        (parameters.originalEntityId == null) ? (null) :
                                                (parameters.originalEntityId));
    this.Serializable$set(
        'importedEntityId',
        (parameters.importedEntityId == null) ? (null) :
                                                (parameters.importedEntityId));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Conflicting display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get importedEntityId(): string|null {
    return (
        (this.Serializable$has('importedEntityId')) ?
            (this.Serializable$get('importedEntityId')) :
            (null));
  }

  /**
   * The name of the conflicting entity type in the content to be imported. This
   * is a unique identifier. Format:
   * `projects//locations//agents//entityTypes/`.
   */
  set importedEntityId(value: string|null) {
    this.Serializable$set('importedEntityId', value);
  }

  get originalEntityId(): string|null {
    return (
        (this.Serializable$has('originalEntityId')) ?
            (this.Serializable$get('originalEntityId')) :
            (null));
  }

  /**
   * The name of the conflicting entity type in the current agent. This is a
   * unique identifier. Format: `projects//locations//agents//entityTypes/`.
   */
  set originalEntityId(value: string|null) {
    this.Serializable$set('originalEntityId', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1EntityConflictDiff> {
    return GoogleCloudDialogflowCxV3beta1EntityConflictDiff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'importedEntityId', 'originalEntityId']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1EnvironmentParameters {
  name?: string|null;
  displayName?: string|null;
  description?: string|null;
  versionTrafficAllocation?: ApiClientObjectMap<number>|null;
  versionConfigs?:
      Array<GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfig>|null;
  updateTime?: string|null;
  testCasesConfig?: GoogleCloudDialogflowCxV3beta1EnvironmentTestCasesConfig|
      null;
  etag?: string|null;
  deploymentConfigs?:
      Array<GoogleCloudDialogflowCxV3beta1EnvironmentDeploymentConfig>|null;
  webhookConfig?: GoogleCloudDialogflowCxV3beta1EnvironmentWebhookConfig|null;
}
export class GoogleCloudDialogflowCxV3beta1Environment extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1EnvironmentParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'versionTrafficAllocation',
        (parameters.versionTrafficAllocation == null) ?
            (null) :
            (parameters.versionTrafficAllocation));
    this.Serializable$set(
        'versionConfigs',
        (parameters.versionConfigs == null) ? (null) :
                                              (parameters.versionConfigs));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'testCasesConfig',
        (parameters.testCasesConfig == null) ? (null) :
                                               (parameters.testCasesConfig));
    this.Serializable$set(
        'etag', (parameters.etag == null) ? (null) : (parameters.etag));
    this.Serializable$set(
        'deploymentConfigs',
        (parameters.deploymentConfigs == null) ?
            (null) :
            (parameters.deploymentConfigs));
    this.Serializable$set(
        'webhookConfig',
        (parameters.webhookConfig == null) ? (null) :
                                             (parameters.webhookConfig));
  }

  get deploymentConfigs():
      Array<GoogleCloudDialogflowCxV3beta1EnvironmentDeploymentConfig>|null {
    return (
        (this.Serializable$has('deploymentConfigs')) ?
            (this.Serializable$get('deploymentConfigs')) :
            (null));
  }

  /**
   * The deployment configurations for the flows in this environment.
   */
  set deploymentConfigs(
      value: Array<GoogleCloudDialogflowCxV3beta1EnvironmentDeploymentConfig>|
      null) {
    this.Serializable$set('deploymentConfigs', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * The human-readable description of the environment. The maximum length is
   * 500 characters. If exceeded, the request is rejected.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the environment (unique in an agent).
   * Limit of 64 characters.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get etag(): string|null {
    return (
        (this.Serializable$has('etag')) ? (this.Serializable$get('etag')) :
                                          (null));
  }

  /**
   * Etag of the environment, may be sent on update requests to ensure the
   * client has an up-to-date value before proceeding.
   */
  set etag(value: string|null) {
    this.Serializable$set('etag', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The name of the environment. Format:
   * `projects//locations//agents//environments/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get testCasesConfig():
      GoogleCloudDialogflowCxV3beta1EnvironmentTestCasesConfig|null {
    return (
        (this.Serializable$has('testCasesConfig')) ?
            (this.Serializable$get('testCasesConfig')) :
            (null));
  }

  /**
   * The test cases config for continuous tests of this environment.
   */
  set testCasesConfig(
      value: GoogleCloudDialogflowCxV3beta1EnvironmentTestCasesConfig|null) {
    this.Serializable$set('testCasesConfig', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. Update time of this environment.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  get versionConfigs():
      Array<GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfig>|null {
    return (
        (this.Serializable$has('versionConfigs')) ?
            (this.Serializable$get('versionConfigs')) :
            (null));
  }

  /**
   * Required. A list of configurations for flow versions. You should include
   * version configs for all flows that are reachable from `Start Flow` in the
   * agent. Otherwise, an error will be returned.
   */
  set versionConfigs(
      value: Array<GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfig>|
      null) {
    this.Serializable$set('versionConfigs', value);
  }

  get versionTrafficAllocation(): ApiClientObjectMap<number>|null {
    return (
        (this.Serializable$has('versionTrafficAllocation')) ?
            (this.Serializable$get('versionTrafficAllocation')) :
            (null));
  }

  /**
   * A map to represent the traffic allocation for each version in this
   * environment. The key of the map is the resource name of the version, in the
   * format of `projects//locations//agents//flows//versions/`. The value of the
   * map is the traffic allocation ratio for this version (from 0.0 to 1.0). For
   * every flow in an agent, the total traffic allocation should add up to 1.0.
   */
  set versionTrafficAllocation(value: ApiClientObjectMap<number>|null) {
    this.Serializable$set('versionTrafficAllocation', value);
  }

  get webhookConfig(): GoogleCloudDialogflowCxV3beta1EnvironmentWebhookConfig
      |null {
    return (
        (this.Serializable$has('webhookConfig')) ?
            (this.Serializable$get('webhookConfig')) :
            (null));
  }

  /**
   * The webhook configuration for this environment.
   */
  set webhookConfig(value:
                        GoogleCloudDialogflowCxV3beta1EnvironmentWebhookConfig|
                    null) {
    this.Serializable$set('webhookConfig', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1Environment> {
    return GoogleCloudDialogflowCxV3beta1Environment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'deploymentConfigs':
            GoogleCloudDialogflowCxV3beta1EnvironmentDeploymentConfig,
        'versionConfigs': GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfig
      },
      keys: [
        'deploymentConfigs', 'description', 'displayName', 'etag', 'name',
        'testCasesConfig', 'updateTime', 'versionConfigs',
        'versionTrafficAllocation', 'webhookConfig'
      ],
      objectMaps: {
        'versionTrafficAllocation': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'testCasesConfig':
            GoogleCloudDialogflowCxV3beta1EnvironmentTestCasesConfig,
        'webhookConfig': GoogleCloudDialogflowCxV3beta1EnvironmentWebhookConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1EnvironmentDeploymentConfigParameters {
  flow?: string|null;
  deploymentTests?: Array<string>|null;
  updateTime?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1EnvironmentDeploymentConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1EnvironmentDeploymentConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
    this.Serializable$set(
        'deploymentTests',
        (parameters.deploymentTests == null) ? (null) :
                                               (parameters.deploymentTests));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
  }

  get deploymentTests(): Array<string>|null {
    return (
        (this.Serializable$has('deploymentTests')) ?
            (this.Serializable$get('deploymentTests')) :
            (null));
  }

  /**
   * A list of test cases to run before deployment. The test cases should belong
   * to the same agent as the environment.
   */
  set deploymentTests(value: Array<string>|null) {
    this.Serializable$set('deploymentTests', value);
  }

  get flow(): string|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * The name of flow to deploy. Format: `projects//locations//agents//flows/`.
   */
  set flow(value: string|null) {
    this.Serializable$set('flow', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. The last update time of deployment configuration.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1EnvironmentDeploymentConfig> {
    return GoogleCloudDialogflowCxV3beta1EnvironmentDeploymentConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['deploymentTests', 'flow', 'updateTime']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1EnvironmentTestCasesConfigParameters {
  testCases?: Array<string>|null;
  enableContinuousRun?: boolean|null;
  enablePredeploymentRun?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1EnvironmentTestCasesConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1EnvironmentTestCasesConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'testCases',
        (parameters.testCases == null) ? (null) : (parameters.testCases));
    this.Serializable$set(
        'enableContinuousRun',
        (parameters.enableContinuousRun == null) ?
            (null) :
            (parameters.enableContinuousRun));
    this.Serializable$set(
        'enablePredeploymentRun',
        (parameters.enablePredeploymentRun == null) ?
            (null) :
            (parameters.enablePredeploymentRun));
  }

  get enableContinuousRun(): boolean|null {
    return (
        (this.Serializable$has('enableContinuousRun')) ?
            (this.Serializable$get('enableContinuousRun')) :
            (null));
  }

  /**
   * Whether to run test cases in TestCasesConfig.test_cases periodically.
   * Default false. If set to true, run once a day.
   */
  set enableContinuousRun(value: boolean|null) {
    this.Serializable$set('enableContinuousRun', value);
  }

  get enablePredeploymentRun(): boolean|null {
    return (
        (this.Serializable$has('enablePredeploymentRun')) ?
            (this.Serializable$get('enablePredeploymentRun')) :
            (null));
  }

  /**
   * Whether to run test cases in TestCasesConfig.test_cases before deploying a
   * flow version to the environment. Default false.
   */
  set enablePredeploymentRun(value: boolean|null) {
    this.Serializable$set('enablePredeploymentRun', value);
  }

  get testCases(): Array<string>|null {
    return (
        (this.Serializable$has('testCases')) ?
            (this.Serializable$get('testCases')) :
            (null));
  }

  /**
   * A list of test case names to run. They should be under the same agent.
   * Format of each test case name: `projects//locations/ /agents//testCases/`
   */
  set testCases(value: Array<string>|null) {
    this.Serializable$set('testCases', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1EnvironmentTestCasesConfig> {
    return GoogleCloudDialogflowCxV3beta1EnvironmentTestCasesConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['enableContinuousRun', 'enablePredeploymentRun', 'testCases']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigParameters {
  version?: string|null;
  trafficAllocation?: number|null;
  staleFlowInfo?:
      GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigStaleFlowInfo|null;
}
export class GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
    this.Serializable$set(
        'trafficAllocation',
        (parameters.trafficAllocation == null) ?
            (null) :
            (parameters.trafficAllocation));
    this.Serializable$set(
        'staleFlowInfo',
        (parameters.staleFlowInfo == null) ? (null) :
                                             (parameters.staleFlowInfo));
  }

  get staleFlowInfo():
      GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigStaleFlowInfo|null {
    return (
        (this.Serializable$has('staleFlowInfo')) ?
            (this.Serializable$get('staleFlowInfo')) :
            (null));
  }

  /**
   * Only exists if this version belongs to a \"stale\" flow.
   */
  set staleFlowInfo(
      value:
          GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigStaleFlowInfo|
      null) {
    this.Serializable$set('staleFlowInfo', value);
  }

  get trafficAllocation(): number|null {
    return (
        (this.Serializable$has('trafficAllocation')) ?
            (this.Serializable$get('trafficAllocation')) :
            (null));
  }

  /**
   * Percentage of the traffic which should be routed to the particular version
   * of the flow. Default to 1.0 if not specified, which means 100% of the
   * traffic routes to the flow will use this version. Note: INVALID_ARGUMENT
   * error will be thrown if traffic allocation for a single flow doesn't sum up
   * to 1.0.
   */
  set trafficAllocation(value: number|null) {
    this.Serializable$set('trafficAllocation', value);
  }

  get version(): string|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Required. Format: projects//locations//agents//flows//versions/.
   */
  set version(value: string|null) {
    this.Serializable$set('version', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfig> {
    return GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['staleFlowInfo', 'trafficAllocation', 'version'],
      objects: {
        'staleFlowInfo':
            GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigStaleFlowInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigStaleFlowInfoParameters {
  flowDisplayName?: string|null;
  versionDisplayName?: string|null;
}
export class
    GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigStaleFlowInfo extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigStaleFlowInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'flowDisplayName',
        (parameters.flowDisplayName == null) ? (null) :
                                               (parameters.flowDisplayName));
    this.Serializable$set(
        'versionDisplayName',
        (parameters.versionDisplayName == null) ?
            (null) :
            (parameters.versionDisplayName));
  }

  get flowDisplayName(): string|null {
    return (
        (this.Serializable$has('flowDisplayName')) ?
            (this.Serializable$get('flowDisplayName')) :
            (null));
  }

  /**
   * The display name of the flow.
   */
  set flowDisplayName(value: string|null) {
    this.Serializable$set('flowDisplayName', value);
  }

  get versionDisplayName(): string|null {
    return (
        (this.Serializable$has('versionDisplayName')) ?
            (this.Serializable$get('versionDisplayName')) :
            (null));
  }

  /**
   * The display name of the referenced flow version in the environment.
   */
  set versionDisplayName(value: string|null) {
    this.Serializable$set('versionDisplayName', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigStaleFlowInfo> {
    return GoogleCloudDialogflowCxV3beta1EnvironmentVersionConfigStaleFlowInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flowDisplayName', 'versionDisplayName']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1EnvironmentWebhookConfigParameters {
  webhookOverrides?: Array<GoogleCloudDialogflowCxV3beta1Webhook>|null;
}
export class GoogleCloudDialogflowCxV3beta1EnvironmentWebhookConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1EnvironmentWebhookConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'webhookOverrides',
        (parameters.webhookOverrides == null) ? (null) :
                                                (parameters.webhookOverrides));
  }

  get webhookOverrides(): Array<GoogleCloudDialogflowCxV3beta1Webhook>|null {
    return (
        (this.Serializable$has('webhookOverrides')) ?
            (this.Serializable$get('webhookOverrides')) :
            (null));
  }

  /**
   * The list of webhooks to override for the agent environment. The webhook
   * must exist in the agent. You can override fields in `generic_web_service`
   * and `service_directory`.
   */
  set webhookOverrides(value: Array<GoogleCloudDialogflowCxV3beta1Webhook>|
                       null) {
    this.Serializable$set('webhookOverrides', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1EnvironmentWebhookConfig> {
    return GoogleCloudDialogflowCxV3beta1EnvironmentWebhookConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'webhookOverrides': GoogleCloudDialogflowCxV3beta1Webhook},
      keys: ['webhookOverrides']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1EventHandlerParameters {
  name?: string|null;
  event?: string|null;
  condition?: string|null;
  triggerFulfillment?: GoogleCloudDialogflowCxV3beta1Fulfillment|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1EventHandler extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1EventHandlerParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'triggerFulfillment',
        (parameters.triggerFulfillment == null) ?
            (null) :
            (parameters.triggerFulfillment));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition to evaluate against form parameters, session parameters or
   * the `error` object. See the [conditions
   * reference](https://cloud.google.com/dialogflow/cx/docs/reference/condition).
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get event(): string|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * Required. The name of the event to handle.
   */
  set event(value: string|null) {
    this.Serializable$set('event', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this event handler.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  get triggerFulfillment(): GoogleCloudDialogflowCxV3beta1Fulfillment|null {
    return (
        (this.Serializable$has('triggerFulfillment')) ?
            (this.Serializable$get('triggerFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to call when the event occurs. Handling webhook errors with
   * a fulfillment enabled with webhook could cause infinite loop. It is invalid
   * to specify such fulfillment for a handler handling webhooks.
   */
  set triggerFulfillment(value: GoogleCloudDialogflowCxV3beta1Fulfillment|
                         null) {
    this.Serializable$set('triggerFulfillment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1EventHandler> {
    return GoogleCloudDialogflowCxV3beta1EventHandler;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'condition', 'event', 'name', 'targetFlow', 'targetPage',
        'triggerFulfillment'
      ],
      objects: {'triggerFulfillment': GoogleCloudDialogflowCxV3beta1Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1EventInputParameters {
  event?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1EventInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1EventInputParameters = {}) {
    super();
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
  }

  get event(): string|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * Name of the event.
   */
  set event(value: string|null) {
    this.Serializable$set('event', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1EventInput> {
    return GoogleCloudDialogflowCxV3beta1EventInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['event']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ExportAgentResponseParameters {
  agentUri?: string|null;
  agentContent?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1ExportAgentResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ExportAgentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'agentUri',
        (parameters.agentUri == null) ? (null) : (parameters.agentUri));
    this.Serializable$set(
        'agentContent',
        (parameters.agentContent == null) ? (null) : (parameters.agentContent));
  }

  get agentContent(): string|null {
    return (
        (this.Serializable$has('agentContent')) ?
            (this.Serializable$get('agentContent')) :
            (null));
  }

  /**
   * Uncompressed raw byte content for agent.
   */
  set agentContent(value: string|null) {
    this.Serializable$set('agentContent', value);
  }

  get agentUri(): string|null {
    return (
        (this.Serializable$has('agentUri')) ?
            (this.Serializable$get('agentUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported agent. This field is populated
   * only if `agent_uri` is specified in ExportAgentRequest.
   */
  set agentUri(value: string|null) {
    this.Serializable$set('agentUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ExportAgentResponse> {
    return GoogleCloudDialogflowCxV3beta1ExportAgentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agentContent', 'agentUri']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ExportFlowResponseParameters {
  flowUri?: string|null;
  flowContent?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1ExportFlowResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ExportFlowResponseParameters = {}) {
    super();
    this.Serializable$set(
        'flowUri',
        (parameters.flowUri == null) ? (null) : (parameters.flowUri));
    this.Serializable$set(
        'flowContent',
        (parameters.flowContent == null) ? (null) : (parameters.flowContent));
  }

  get flowContent(): string|null {
    return (
        (this.Serializable$has('flowContent')) ?
            (this.Serializable$get('flowContent')) :
            (null));
  }

  /**
   * Uncompressed raw byte content for flow.
   */
  set flowContent(value: string|null) {
    this.Serializable$set('flowContent', value);
  }

  get flowUri(): string|null {
    return (
        (this.Serializable$has('flowUri')) ?
            (this.Serializable$get('flowUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported flow. This field is populated
   * only if `flow_uri` is specified in ExportFlowRequest.
   */
  set flowUri(value: string|null) {
    this.Serializable$set('flowUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ExportFlowResponse> {
    return GoogleCloudDialogflowCxV3beta1ExportFlowResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flowContent', 'flowUri']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ExportIntentsMetadataParameters {
}
export class GoogleCloudDialogflowCxV3beta1ExportIntentsMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ExportIntentsMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ExportIntentsMetadata> {
    return GoogleCloudDialogflowCxV3beta1ExportIntentsMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ExportIntentsResponseParameters {
  intentsUri?: string|null;
  intentsContent?: GoogleCloudDialogflowCxV3beta1InlineDestination|null;
}
export class GoogleCloudDialogflowCxV3beta1ExportIntentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ExportIntentsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'intentsUri',
        (parameters.intentsUri == null) ? (null) : (parameters.intentsUri));
    this.Serializable$set(
        'intentsContent',
        (parameters.intentsContent == null) ? (null) :
                                              (parameters.intentsContent));
  }

  get intentsContent(): GoogleCloudDialogflowCxV3beta1InlineDestination|null {
    return (
        (this.Serializable$has('intentsContent')) ?
            (this.Serializable$get('intentsContent')) :
            (null));
  }

  /**
   * Uncompressed byte content for intents. This field is populated only if
   * `intents_content_inline` is set to true in ExportIntentsRequest.
   */
  set intentsContent(value: GoogleCloudDialogflowCxV3beta1InlineDestination|
                     null) {
    this.Serializable$set('intentsContent', value);
  }

  get intentsUri(): string|null {
    return (
        (this.Serializable$has('intentsUri')) ?
            (this.Serializable$get('intentsUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported intents. This field is populated
   * only if `intents_uri` is specified in ExportIntentsRequest.
   */
  set intentsUri(value: string|null) {
    this.Serializable$set('intentsUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ExportIntentsResponse> {
    return GoogleCloudDialogflowCxV3beta1ExportIntentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['intentsContent', 'intentsUri'],
      objects:
          {'intentsContent': GoogleCloudDialogflowCxV3beta1InlineDestination}
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ExportTestCasesMetadataParameters {
}
export class GoogleCloudDialogflowCxV3beta1ExportTestCasesMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ExportTestCasesMetadataParameters =
              {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ExportTestCasesMetadata> {
    return GoogleCloudDialogflowCxV3beta1ExportTestCasesMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ExportTestCasesResponseParameters {
  gcsUri?: string|null;
  content?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1ExportTestCasesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ExportTestCasesResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'gcsUri', (parameters.gcsUri == null) ? (null) : (parameters.gcsUri));
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Uncompressed raw byte content for test cases.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get gcsUri(): string|null {
    return (
        (this.Serializable$has('gcsUri')) ? (this.Serializable$get('gcsUri')) :
                                            (null));
  }

  /**
   * The URI to a file containing the exported test cases. This field is
   * populated only if `gcs_uri` is specified in ExportTestCasesRequest.
   */
  set gcsUri(value: string|null) {
    this.Serializable$set('gcsUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ExportTestCasesResponse> {
    return GoogleCloudDialogflowCxV3beta1ExportTestCasesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['content', 'gcsUri']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1FlowConflictReportParameters {
  intentDiffs?: Array<GoogleCloudDialogflowCxV3beta1IntentConflictDiff>|null;
  entityTypesDiffs?: Array<GoogleCloudDialogflowCxV3beta1EntityConflictDiff>|
      null;
  webhookDiffs?: Array<GoogleCloudDialogflowCxV3beta1WebhookConflictDiff>|null;
}
export class GoogleCloudDialogflowCxV3beta1FlowConflictReport extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1FlowConflictReportParameters = {}) {
    super();
    this.Serializable$set(
        'intentDiffs',
        (parameters.intentDiffs == null) ? (null) : (parameters.intentDiffs));
    this.Serializable$set(
        'entityTypesDiffs',
        (parameters.entityTypesDiffs == null) ? (null) :
                                                (parameters.entityTypesDiffs));
    this.Serializable$set(
        'webhookDiffs',
        (parameters.webhookDiffs == null) ? (null) : (parameters.webhookDiffs));
  }

  get entityTypesDiffs():
      Array<GoogleCloudDialogflowCxV3beta1EntityConflictDiff>|null {
    return (
        (this.Serializable$has('entityTypesDiffs')) ?
            (this.Serializable$get('entityTypesDiffs')) :
            (null));
  }

  /**
   * The diffs for Entity Types with display name conflicts.
   */
  set entityTypesDiffs(
      value: Array<GoogleCloudDialogflowCxV3beta1EntityConflictDiff>|null) {
    this.Serializable$set('entityTypesDiffs', value);
  }

  get intentDiffs():
      Array<GoogleCloudDialogflowCxV3beta1IntentConflictDiff>|null {
    return (
        (this.Serializable$has('intentDiffs')) ?
            (this.Serializable$get('intentDiffs')) :
            (null));
  }

  /**
   * The diffs for Intents with display name conflicts.
   */
  set intentDiffs(value:
                      Array<GoogleCloudDialogflowCxV3beta1IntentConflictDiff>|
                  null) {
    this.Serializable$set('intentDiffs', value);
  }

  get webhookDiffs():
      Array<GoogleCloudDialogflowCxV3beta1WebhookConflictDiff>|null {
    return (
        (this.Serializable$has('webhookDiffs')) ?
            (this.Serializable$get('webhookDiffs')) :
            (null));
  }

  /**
   * The diffs for Webhooks with display name conflicts.
   */
  set webhookDiffs(value:
                       Array<GoogleCloudDialogflowCxV3beta1WebhookConflictDiff>|
                   null) {
    this.Serializable$set('webhookDiffs', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1FlowConflictReport> {
    return GoogleCloudDialogflowCxV3beta1FlowConflictReport;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'entityTypesDiffs': GoogleCloudDialogflowCxV3beta1EntityConflictDiff,
        'intentDiffs': GoogleCloudDialogflowCxV3beta1IntentConflictDiff,
        'webhookDiffs': GoogleCloudDialogflowCxV3beta1WebhookConflictDiff
      },
      keys: ['entityTypesDiffs', 'intentDiffs', 'webhookDiffs']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1FormParameters {
  parameters?: Array<GoogleCloudDialogflowCxV3beta1FormParameter>|null;
}
export class GoogleCloudDialogflowCxV3beta1Form extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3beta1FormParameters = {}) {
    super();
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get parameters(): Array<GoogleCloudDialogflowCxV3beta1FormParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Parameters to collect from the user.
   */
  set parameters(value: Array<GoogleCloudDialogflowCxV3beta1FormParameter>|
                 null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1Form> {
    return GoogleCloudDialogflowCxV3beta1Form;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parameters': GoogleCloudDialogflowCxV3beta1FormParameter},
      keys: ['parameters']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1FormParameterParameters {
  displayName?: string|null;
  required?: boolean|null;
  entityType?: string|null;
  isList?: boolean|null;
  fillBehavior?: GoogleCloudDialogflowCxV3beta1FormParameterFillBehavior|null;
  defaultValue?: any|null;
  redact?: boolean|null;
  advancedSettings?: GoogleCloudDialogflowCxV3beta1AdvancedSettings|null;
}
export class GoogleCloudDialogflowCxV3beta1FormParameter extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1FormParameterParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'required',
        (parameters.required == null) ? (null) : (parameters.required));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
    this.Serializable$set(
        'isList', (parameters.isList == null) ? (null) : (parameters.isList));
    this.Serializable$set(
        'fillBehavior',
        (parameters.fillBehavior == null) ? (null) : (parameters.fillBehavior));
    this.Serializable$set(
        'defaultValue',
        (parameters.defaultValue == null) ? (null) : (parameters.defaultValue));
    this.Serializable$set(
        'redact', (parameters.redact == null) ? (null) : (parameters.redact));
    this.Serializable$set(
        'advancedSettings',
        (parameters.advancedSettings == null) ? (null) :
                                                (parameters.advancedSettings));
  }

  get advancedSettings(): GoogleCloudDialogflowCxV3beta1AdvancedSettings|null {
    return (
        (this.Serializable$has('advancedSettings')) ?
            (this.Serializable$get('advancedSettings')) :
            (null));
  }

  /**
   * Hierarchical advanced settings for this parameter. The settings exposed at
   * the lower level overrides the settings exposed at the higher level.
   */
  set advancedSettings(value: GoogleCloudDialogflowCxV3beta1AdvancedSettings|
                       null) {
    this.Serializable$set('advancedSettings', value);
  }

  get defaultValue(): any|null {
    return (
        (this.Serializable$has('defaultValue')) ?
            (this.Serializable$get('defaultValue')) :
            (null));
  }

  /**
   * The default value of an optional parameter. If the parameter is required,
   * the default value will be ignored.
   */
  set defaultValue(value: any|null) {
    this.Serializable$set('defaultValue', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the parameter, unique within the form.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * Required. The entity type of the parameter. Format:
   * `projects/-/locations/-/agents/-/entityTypes/` for system entity types (for
   * example, `projects/-/locations/-/agents/-/entityTypes/sys.date`), or
   * `projects//locations//agents//entityTypes/` for developer entity types.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get fillBehavior(): GoogleCloudDialogflowCxV3beta1FormParameterFillBehavior
      |null {
    return (
        (this.Serializable$has('fillBehavior')) ?
            (this.Serializable$get('fillBehavior')) :
            (null));
  }

  /**
   * Required. Defines fill behavior for the parameter.
   */
  set fillBehavior(value:
                       GoogleCloudDialogflowCxV3beta1FormParameterFillBehavior|
                   null) {
    this.Serializable$set('fillBehavior', value);
  }

  get isList(): boolean|null {
    return (
        (this.Serializable$has('isList')) ? (this.Serializable$get('isList')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter represents a list of values.
   */
  set isList(value: boolean|null) {
    this.Serializable$set('isList', value);
  }

  get redact(): boolean|null {
    return (
        (this.Serializable$has('redact')) ? (this.Serializable$get('redact')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter content should be redacted in log. If
   * redaction is enabled, the parameter content will be replaced by parameter
   * name during logging. Note: the parameter content is subject to redaction if
   * either parameter level redaction or entity type level redaction is enabled.
   */
  set redact(value: boolean|null) {
    this.Serializable$set('redact', value);
  }

  get required(): boolean|null {
    return (
        (this.Serializable$has('required')) ?
            (this.Serializable$get('required')) :
            (null));
  }

  /**
   * Indicates whether the parameter is required. Optional parameters will not
   * trigger prompts; however, they are filled if the user specifies them.
   * Required parameters must be filled before form filling concludes.
   */
  set required(value: boolean|null) {
    this.Serializable$set('required', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1FormParameter> {
    return GoogleCloudDialogflowCxV3beta1FormParameter;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'advancedSettings', 'defaultValue', 'displayName', 'entityType',
        'fillBehavior', 'isList', 'redact', 'required'
      ],
      objects: {
        'advancedSettings': GoogleCloudDialogflowCxV3beta1AdvancedSettings,
        'fillBehavior': GoogleCloudDialogflowCxV3beta1FormParameterFillBehavior
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1FormParameterFillBehaviorParameters {
  initialPromptFulfillment?: GoogleCloudDialogflowCxV3beta1Fulfillment|null;
  repromptEventHandlers?: Array<GoogleCloudDialogflowCxV3beta1EventHandler>|
      null;
}
export class GoogleCloudDialogflowCxV3beta1FormParameterFillBehavior extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1FormParameterFillBehaviorParameters =
              {}) {
    super();
    this.Serializable$set(
        'initialPromptFulfillment',
        (parameters.initialPromptFulfillment == null) ?
            (null) :
            (parameters.initialPromptFulfillment));
    this.Serializable$set(
        'repromptEventHandlers',
        (parameters.repromptEventHandlers == null) ?
            (null) :
            (parameters.repromptEventHandlers));
  }

  get initialPromptFulfillment(): GoogleCloudDialogflowCxV3beta1Fulfillment
      |null {
    return (
        (this.Serializable$has('initialPromptFulfillment')) ?
            (this.Serializable$get('initialPromptFulfillment')) :
            (null));
  }

  /**
   * Required. The fulfillment to provide the initial prompt that the agent can
   * present to the user in order to fill the parameter.
   */
  set initialPromptFulfillment(value: GoogleCloudDialogflowCxV3beta1Fulfillment|
                               null) {
    this.Serializable$set('initialPromptFulfillment', value);
  }

  get repromptEventHandlers():
      Array<GoogleCloudDialogflowCxV3beta1EventHandler>|null {
    return (
        (this.Serializable$has('repromptEventHandlers')) ?
            (this.Serializable$get('repromptEventHandlers')) :
            (null));
  }

  /**
   * The handlers for parameter-level events, used to provide reprompt for the
   * parameter or transition to a different page/flow. The supported events are:
   * * `sys.no-match-`, where N can be from 1 to 6 * `sys.no-match-default` *
   * `sys.no-input-`, where N can be from 1 to 6 * `sys.no-input-default` *
   * `sys.invalid-parameter` `initial_prompt_fulfillment` provides the first
   * prompt for the parameter. If the user's response does not fill the
   * parameter, a no-match/no-input event will be triggered, and the fulfillment
   * associated with the `sys.no-match-1`/`sys.no-input-1` handler (if defined)
   * will be called to provide a prompt. The `sys.no-match-2`/`sys.no-input-2`
   * handler (if defined) will respond to the next no-match/no-input event, and
   * so on. A `sys.no-match-default` or `sys.no-input-default` handler will be
   * used to handle all following no-match/no-input events after all numbered
   * no-match/no-input handlers for the parameter are consumed. A
   * `sys.invalid-parameter` handler can be defined to handle the case where the
   * parameter values have been `invalidated` by webhook. For example, if the
   * user's response fill the parameter, however the parameter was invalidated
   * by webhook, the fulfillment associated with the `sys.invalid-parameter`
   * handler (if defined) will be called to provide a prompt. If the event
   * handler for the corresponding event can't be found on the parameter,
   * `initial_prompt_fulfillment` will be re-prompted.
   */
  set repromptEventHandlers(
      value: Array<GoogleCloudDialogflowCxV3beta1EventHandler>|null) {
    this.Serializable$set('repromptEventHandlers', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1FormParameterFillBehavior> {
    return GoogleCloudDialogflowCxV3beta1FormParameterFillBehavior;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'repromptEventHandlers': GoogleCloudDialogflowCxV3beta1EventHandler},
      keys: ['initialPromptFulfillment', 'repromptEventHandlers'],
      objects: {
        'initialPromptFulfillment': GoogleCloudDialogflowCxV3beta1Fulfillment
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1FulfillmentParameters {
  messages?: Array<GoogleCloudDialogflowCxV3beta1ResponseMessage>|null;
  webhook?: string|null;
  returnPartialResponses?: boolean|null;
  tag?: string|null;
  setParameterActions?:
      Array<GoogleCloudDialogflowCxV3beta1FulfillmentSetParameterAction>|null;
  conditionalCases?:
      Array<GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases>|null;
  playbackInterruptionSettings?:
      GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings|null;
  advancedSettings?: GoogleCloudDialogflowCxV3beta1AdvancedSettings|null;
  webhookInputParameters?:
      Array<GoogleCloudDialogflowCxV3beta1ParameterDeclaration>|null;
  webhookOutputParameters?:
      Array<GoogleCloudDialogflowCxV3beta1ParameterDeclaration>|null;
  webhookSettings?: GoogleCloudDialogflowCxV3beta1WebhookSettings|null;
}
export class GoogleCloudDialogflowCxV3beta1Fulfillment extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1FulfillmentParameters = {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'webhook',
        (parameters.webhook == null) ? (null) : (parameters.webhook));
    this.Serializable$set(
        'returnPartialResponses',
        (parameters.returnPartialResponses == null) ?
            (null) :
            (parameters.returnPartialResponses));
    this.Serializable$set(
        'tag', (parameters.tag == null) ? (null) : (parameters.tag));
    this.Serializable$set(
        'setParameterActions',
        (parameters.setParameterActions == null) ?
            (null) :
            (parameters.setParameterActions));
    this.Serializable$set(
        'conditionalCases',
        (parameters.conditionalCases == null) ? (null) :
                                                (parameters.conditionalCases));
    this.Serializable$set(
        'playbackInterruptionSettings',
        (parameters.playbackInterruptionSettings == null) ?
            (null) :
            (parameters.playbackInterruptionSettings));
    this.Serializable$set(
        'advancedSettings',
        (parameters.advancedSettings == null) ? (null) :
                                                (parameters.advancedSettings));
    this.Serializable$set(
        'webhookInputParameters',
        (parameters.webhookInputParameters == null) ?
            (null) :
            (parameters.webhookInputParameters));
    this.Serializable$set(
        'webhookOutputParameters',
        (parameters.webhookOutputParameters == null) ?
            (null) :
            (parameters.webhookOutputParameters));
    this.Serializable$set(
        'webhookSettings',
        (parameters.webhookSettings == null) ? (null) :
                                               (parameters.webhookSettings));
  }

  get advancedSettings(): GoogleCloudDialogflowCxV3beta1AdvancedSettings|null {
    return (
        (this.Serializable$has('advancedSettings')) ?
            (this.Serializable$get('advancedSettings')) :
            (null));
  }

  /**
   * Hierarchical advanced settings for this fulfillment. The settings exposed
   * at the lower level overrides the settings exposed at the higher level.
   */
  set advancedSettings(value: GoogleCloudDialogflowCxV3beta1AdvancedSettings|
                       null) {
    this.Serializable$set('advancedSettings', value);
  }

  get conditionalCases():
      Array<GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases>|null {
    return (
        (this.Serializable$has('conditionalCases')) ?
            (this.Serializable$get('conditionalCases')) :
            (null));
  }

  /**
   * Conditional cases for this fulfillment.
   */
  set conditionalCases(
      value: Array<GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases>|
      null) {
    this.Serializable$set('conditionalCases', value);
  }

  get messages(): Array<GoogleCloudDialogflowCxV3beta1ResponseMessage>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The list of rich message responses to present to the user.
   */
  set messages(value: Array<GoogleCloudDialogflowCxV3beta1ResponseMessage>|
               null) {
    this.Serializable$set('messages', value);
  }

  get playbackInterruptionSettings():
      GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings|null {
    return (
        (this.Serializable$has('playbackInterruptionSettings')) ?
            (this.Serializable$get('playbackInterruptionSettings')) :
            (null));
  }

  /**
   * Whether to allow interruption on the playback of a message. If empty, flow
   * level settings will be used.
   */
  set playbackInterruptionSettings(
      value: GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings|null) {
    this.Serializable$set('playbackInterruptionSettings', value);
  }

  get returnPartialResponses(): boolean|null {
    return (
        (this.Serializable$has('returnPartialResponses')) ?
            (this.Serializable$get('returnPartialResponses')) :
            (null));
  }

  /**
   * Whether Dialogflow should return currently queued fulfillment response
   * messages in streaming APIs. If a webhook is specified, it happens before
   * Dialogflow invokes webhook. Warning: 1) This flag only affects streaming
   * API. Responses are still queued and returned once in non-streaming API. 2)
   * The flag can be enabled in any fulfillment but only the first 3 partial
   * responses will be returned. You may only want to apply it to fulfillments
   * that have slow webhooks.
   */
  set returnPartialResponses(value: boolean|null) {
    this.Serializable$set('returnPartialResponses', value);
  }

  get setParameterActions():
      Array<GoogleCloudDialogflowCxV3beta1FulfillmentSetParameterAction>|null {
    return (
        (this.Serializable$has('setParameterActions')) ?
            (this.Serializable$get('setParameterActions')) :
            (null));
  }

  /**
   * Set parameter values before executing the webhook.
   */
  set setParameterActions(
      value: Array<GoogleCloudDialogflowCxV3beta1FulfillmentSetParameterAction>|
      null) {
    this.Serializable$set('setParameterActions', value);
  }

  get tag(): string|null {
    return (
        (this.Serializable$has('tag')) ? (this.Serializable$get('tag')) :
                                         (null));
  }

  /**
   * The value of this field will be populated in the WebhookRequest
   * `fulfillmentInfo.tag` field by Dialogflow when the associated webhook is
   * called. The tag is typically used by the webhook service to identify which
   * fulfillment is being called, but it could be used for other purposes. This
   * field is required if `webhook` is specified.
   */
  set tag(value: string|null) {
    this.Serializable$set('tag', value);
  }

  get webhook(): string|null {
    return (
        (this.Serializable$has('webhook')) ?
            (this.Serializable$get('webhook')) :
            (null));
  }

  /**
   * The webhook to call. Format: `projects//locations//agents//webhooks/`.
   */
  set webhook(value: string|null) {
    this.Serializable$set('webhook', value);
  }

  get webhookInputParameters():
      Array<GoogleCloudDialogflowCxV3beta1ParameterDeclaration>|null {
    return (
        (this.Serializable$has('webhookInputParameters')) ?
            (this.Serializable$get('webhookInputParameters')) :
            (null));
  }

  /**
   * Defines what parameters can be sent to the webhook. These should be a
   * subset of the parameter names defined in the agent. If enforcement is true,
   * only session parameters that are declared here can be sent to the webhook.
   */
  set webhookInputParameters(
      value: Array<GoogleCloudDialogflowCxV3beta1ParameterDeclaration>|null) {
    this.Serializable$set('webhookInputParameters', value);
  }

  get webhookOutputParameters():
      Array<GoogleCloudDialogflowCxV3beta1ParameterDeclaration>|null {
    return (
        (this.Serializable$has('webhookOutputParameters')) ?
            (this.Serializable$get('webhookOutputParameters')) :
            (null));
  }

  /**
   * Defines what parameters can be sent by the webhook via
   * `session_info.parameters`. These should be a subset of the parameter names
   * defined in the agent. If enforcement is true, only parameters defined here
   * can be modifed by the webhook.
   */
  set webhookOutputParameters(
      value: Array<GoogleCloudDialogflowCxV3beta1ParameterDeclaration>|null) {
    this.Serializable$set('webhookOutputParameters', value);
  }

  get webhookSettings(): GoogleCloudDialogflowCxV3beta1WebhookSettings|null {
    return (
        (this.Serializable$has('webhookSettings')) ?
            (this.Serializable$get('webhookSettings')) :
            (null));
  }

  /**
   * Hierarchical webhook settings for this fulfillment. The settings exposed at
   * the lower level overrides the settings exposed at the higher level.
   */
  set webhookSettings(value: GoogleCloudDialogflowCxV3beta1WebhookSettings|
                      null) {
    this.Serializable$set('webhookSettings', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1Fulfillment> {
    return GoogleCloudDialogflowCxV3beta1Fulfillment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'conditionalCases':
            GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases,
        'messages': GoogleCloudDialogflowCxV3beta1ResponseMessage,
        'setParameterActions':
            GoogleCloudDialogflowCxV3beta1FulfillmentSetParameterAction,
        'webhookInputParameters':
            GoogleCloudDialogflowCxV3beta1ParameterDeclaration,
        'webhookOutputParameters':
            GoogleCloudDialogflowCxV3beta1ParameterDeclaration
      },
      keys: [
        'advancedSettings', 'conditionalCases', 'messages',
        'playbackInterruptionSettings', 'returnPartialResponses',
        'setParameterActions', 'tag', 'webhook', 'webhookInputParameters',
        'webhookOutputParameters', 'webhookSettings'
      ],
      objects: {
        'advancedSettings': GoogleCloudDialogflowCxV3beta1AdvancedSettings,
        'playbackInterruptionSettings':
            GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings,
        'webhookSettings': GoogleCloudDialogflowCxV3beta1WebhookSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesParameters {
  cases?: Array<GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCase>|
      null;
}
export class GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesParameters =
              {}) {
    super();
    this.Serializable$set(
        'cases', (parameters.cases == null) ? (null) : (parameters.cases));
  }

  get cases():
      Array<GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCase>|
      null {
    return (
        (this.Serializable$has('cases')) ? (this.Serializable$get('cases')) :
                                           (null));
  }

  /**
   * A list of cascading if-else conditions.
   */
  set cases(
      value:
          Array<GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCase>|
      null) {
    this.Serializable$set('cases', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases> {
    return GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'cases': GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCase
      },
      keys: ['cases']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseParameters {
  condition?: string|null;
  caseContent?: Array<
      GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseCaseContent>|
      null;
}
export class
    GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCase extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseParameters =
              {}) {
    super();
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'caseContent',
        (parameters.caseContent == null) ? (null) : (parameters.caseContent));
  }

  get caseContent(): Array<
      GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseCaseContent>|
      null {
    return (
        (this.Serializable$has('caseContent')) ?
            (this.Serializable$get('caseContent')) :
            (null));
  }

  /**
   * A list of case content.
   */
  set caseContent(
      value: Array<
          GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseCaseContent>|
      null) {
    this.Serializable$set('caseContent', value);
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition to activate and select this case. Empty means the condition
   * is always true. The condition is evaluated against form parameters or
   * session parameters. See the [conditions
   * reference](https://cloud.google.com/dialogflow/cx/docs/reference/condition).
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCase> {
    return GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'caseContent':
            GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseCaseContent
      },
      keys: ['caseContent', 'condition']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseCaseContentParameters {
  message?: GoogleCloudDialogflowCxV3beta1ResponseMessage|null;
  additionalCases?: GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases|
      null;
}
export class
    GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseCaseContent extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseCaseContentParameters =
              {}) {
    super();
    this.Serializable$set(
        'message',
        (parameters.message == null) ? (null) : (parameters.message));
    this.Serializable$set(
        'additionalCases',
        (parameters.additionalCases == null) ? (null) :
                                               (parameters.additionalCases));
  }

  get additionalCases():
      GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases|null {
    return (
        (this.Serializable$has('additionalCases')) ?
            (this.Serializable$get('additionalCases')) :
            (null));
  }

  /**
   * Additional cases to be evaluated.
   */
  set additionalCases(
      value: GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases|null) {
    this.Serializable$set('additionalCases', value);
  }

  get message(): GoogleCloudDialogflowCxV3beta1ResponseMessage|null {
    return (
        (this.Serializable$has('message')) ?
            (this.Serializable$get('message')) :
            (null));
  }

  /**
   * Returned message.
   */
  set message(value: GoogleCloudDialogflowCxV3beta1ResponseMessage|null) {
    this.Serializable$set('message', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseCaseContent> {
    return GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCasesCaseCaseContent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['additionalCases', 'message'],
      objects: {
        'additionalCases':
            GoogleCloudDialogflowCxV3beta1FulfillmentConditionalCases,
        'message': GoogleCloudDialogflowCxV3beta1ResponseMessage
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1FulfillmentSetParameterActionParameters {
  parameter?: string|null;
  value?: any|null;
}
export class GoogleCloudDialogflowCxV3beta1FulfillmentSetParameterAction extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1FulfillmentSetParameterActionParameters =
              {}) {
    super();
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * Display name of the parameter.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  get value(): any|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * The new value of the parameter. A null value clears the parameter.
   */
  set value(value: any|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1FulfillmentSetParameterAction> {
    return GoogleCloudDialogflowCxV3beta1FulfillmentSetParameterAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['parameter', 'value']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1GcsDestinationParameters {
  uri?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1GcsDestination extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1GcsDestinationParameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The Google Cloud Storage URI for the exported objects. A URI is
   * of the form: gs://bucket/object-name-or-prefix Whether a full object name,
   * or just a prefix, its usage depends on the Dialogflow operation.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1GcsDestination> {
    return GoogleCloudDialogflowCxV3beta1GcsDestination;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1GenerateImportConflictReportMetadataParameters {
}
export class
    GoogleCloudDialogflowCxV3beta1GenerateImportConflictReportMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1GenerateImportConflictReportMetadataParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1GenerateImportConflictReportMetadata> {
    return GoogleCloudDialogflowCxV3beta1GenerateImportConflictReportMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3beta1GenerateImportConflictReportResponseParameters {
  flowConflictReport?: GoogleCloudDialogflowCxV3beta1FlowConflictReport|null;
}
export class
    GoogleCloudDialogflowCxV3beta1GenerateImportConflictReportResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1GenerateImportConflictReportResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'flowConflictReport',
        (parameters.flowConflictReport == null) ?
            (null) :
            (parameters.flowConflictReport));
  }

  get flowConflictReport(): GoogleCloudDialogflowCxV3beta1FlowConflictReport
      |null {
    return (
        (this.Serializable$has('flowConflictReport')) ?
            (this.Serializable$get('flowConflictReport')) :
            (null));
  }

  /**
   * Conflict report for flow imports. Returned when
   * [GenerateImportConflictReportRequest.resource_import_request] is
   * import_flow_request.
   */
  set flowConflictReport(value:
                             GoogleCloudDialogflowCxV3beta1FlowConflictReport|
                         null) {
    this.Serializable$set('flowConflictReport', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1GenerateImportConflictReportResponse> {
    return GoogleCloudDialogflowCxV3beta1GenerateImportConflictReportResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['flowConflictReport'],
      objects: {
        'flowConflictReport': GoogleCloudDialogflowCxV3beta1FlowConflictReport
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataParameters {
  state?: GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState|
      null;
}
export class
    GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
  }

  static get State():
      IGoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataStateEnum {
    return GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataStateEnum;
  }

  get state():
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState
      |null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Required. Output only. The current state of this operation.
   */
  set state(
      value:
          GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataState|
      null) {
    this.Serializable$set('state', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata> {
    return GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state':
            GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadataStateEnum
      },
      keys: ['state']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ImportAgentResponseParameters {
  agent?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1ImportAgentResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ImportAgentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'agent', (parameters.agent == null) ? (null) : (parameters.agent));
  }

  get agent(): string|null {
    return (
        (this.Serializable$has('agent')) ? (this.Serializable$get('agent')) :
                                           (null));
  }

  /**
   * The unique identifier of the new agent. Format:
   * `projects//locations//agents/`.
   */
  set agent(value: string|null) {
    this.Serializable$set('agent', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ImportAgentResponse> {
    return GoogleCloudDialogflowCxV3beta1ImportAgentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agent']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ImportDocumentsOperationMetadataParameters {
  genericMetadata?:
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|null;
}
export class
    GoogleCloudDialogflowCxV3beta1ImportDocumentsOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ImportDocumentsOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|
      null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ImportDocumentsOperationMetadata> {
    return GoogleCloudDialogflowCxV3beta1ImportDocumentsOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ImportDocumentsResponseParameters {
  warnings?: Array<GoogleRpcStatus>|null;
}
export class GoogleCloudDialogflowCxV3beta1ImportDocumentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ImportDocumentsResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'warnings',
        (parameters.warnings == null) ? (null) : (parameters.warnings));
  }

  get warnings(): Array<GoogleRpcStatus>|null {
    return (
        (this.Serializable$has('warnings')) ?
            (this.Serializable$get('warnings')) :
            (null));
  }

  /**
   * Includes details about skipped documents or any other warnings.
   */
  set warnings(value: Array<GoogleRpcStatus>|null) {
    this.Serializable$set('warnings', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ImportDocumentsResponse> {
    return GoogleCloudDialogflowCxV3beta1ImportDocumentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'warnings': GoogleRpcStatus}, keys: ['warnings']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ImportFlowResponseParameters {
  flow?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1ImportFlowResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ImportFlowResponseParameters = {}) {
    super();
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
  }

  get flow(): string|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * The unique identifier of the new flow. Format:
   * `projects//locations//agents//flows/`.
   */
  set flow(value: string|null) {
    this.Serializable$set('flow', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ImportFlowResponse> {
    return GoogleCloudDialogflowCxV3beta1ImportFlowResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flow']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ImportIntentsMetadataParameters {
}
export class GoogleCloudDialogflowCxV3beta1ImportIntentsMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ImportIntentsMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ImportIntentsMetadata> {
    return GoogleCloudDialogflowCxV3beta1ImportIntentsMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ImportIntentsResponseParameters {
  intents?: Array<string>|null;
}
export class GoogleCloudDialogflowCxV3beta1ImportIntentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ImportIntentsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
  }

  get intents(): Array<string>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * The unique identifier of the imported intents. Format:
   * `projects//locations//agents//intents/`.
   */
  set intents(value: Array<string>|null) {
    this.Serializable$set('intents', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ImportIntentsResponse> {
    return GoogleCloudDialogflowCxV3beta1ImportIntentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intents']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ImportTestCasesMetadataParameters {
  errors?: Array<GoogleCloudDialogflowCxV3beta1TestCaseError>|null;
}
export class GoogleCloudDialogflowCxV3beta1ImportTestCasesMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ImportTestCasesMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'errors', (parameters.errors == null) ? (null) : (parameters.errors));
  }

  get errors(): Array<GoogleCloudDialogflowCxV3beta1TestCaseError>|null {
    return (
        (this.Serializable$has('errors')) ? (this.Serializable$get('errors')) :
                                            (null));
  }

  /**
   * Errors for failed test cases.
   */
  set errors(value: Array<GoogleCloudDialogflowCxV3beta1TestCaseError>|null) {
    this.Serializable$set('errors', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ImportTestCasesMetadata> {
    return GoogleCloudDialogflowCxV3beta1ImportTestCasesMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'errors': GoogleCloudDialogflowCxV3beta1TestCaseError},
      keys: ['errors']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ImportTestCasesResponseParameters {
  names?: Array<string>|null;
}
export class GoogleCloudDialogflowCxV3beta1ImportTestCasesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ImportTestCasesResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'names', (parameters.names == null) ? (null) : (parameters.names));
  }

  get names(): Array<string>|null {
    return (
        (this.Serializable$has('names')) ? (this.Serializable$get('names')) :
                                           (null));
  }

  /**
   * The unique identifiers of the new test cases. Format:
   * `projects//locations//agents//testCases/`.
   */
  set names(value: Array<string>|null) {
    this.Serializable$set('names', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ImportTestCasesResponse> {
    return GoogleCloudDialogflowCxV3beta1ImportTestCasesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['names']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1InlineDestinationParameters {
  content?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1InlineDestination extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1InlineDestinationParameters = {}) {
    super();
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Output only. The uncompressed byte content for the objects. Only populated
   * in responses.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1InlineDestination> {
    return GoogleCloudDialogflowCxV3beta1InlineDestination;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['content']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1InputAudioConfigParameters {
  audioEncoding?: GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding|
      null;
  sampleRateHertz?: number|null;
  languageCode?: string|null;
  enableWordInfo?: boolean|null;
  audioChannelCount?: number|null;
  phraseHints?: Array<string>|null;
  speechContexts?: Array<GoogleCloudDialogflowCxV3beta1SpeechContext>|null;
  model?: string|null;
  modelVariant?: GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant|
      null;
  singleUtterance?: boolean|null;
  disableNoSpeechRecognizedEvent?: boolean|null;
  bargeInConfig?: GoogleCloudDialogflowCxV3beta1BargeInConfig|null;
  defaultNoSpeechTimeout?: string|null;
  alternativeLanguageCodes?: Array<string>|null;
  phraseSet?: Array<string>|null;
  audioStreamInfo?:
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioStreamInfo|null;
}
export class GoogleCloudDialogflowCxV3beta1InputAudioConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1InputAudioConfigParameters = {}) {
    super();
    this.Serializable$set(
        'audioEncoding',
        (parameters.audioEncoding == null) ? (null) :
                                             (parameters.audioEncoding));
    this.Serializable$set(
        'sampleRateHertz',
        (parameters.sampleRateHertz == null) ? (null) :
                                               (parameters.sampleRateHertz));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'enableWordInfo',
        (parameters.enableWordInfo == null) ? (null) :
                                              (parameters.enableWordInfo));
    this.Serializable$set(
        'audioChannelCount',
        (parameters.audioChannelCount == null) ?
            (null) :
            (parameters.audioChannelCount));
    this.Serializable$set(
        'phraseHints',
        (parameters.phraseHints == null) ? (null) : (parameters.phraseHints));
    this.Serializable$set(
        'speechContexts',
        (parameters.speechContexts == null) ? (null) :
                                              (parameters.speechContexts));
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'modelVariant',
        (parameters.modelVariant == null) ? (null) : (parameters.modelVariant));
    this.Serializable$set(
        'singleUtterance',
        (parameters.singleUtterance == null) ? (null) :
                                               (parameters.singleUtterance));
    this.Serializable$set(
        'disableNoSpeechRecognizedEvent',
        (parameters.disableNoSpeechRecognizedEvent == null) ?
            (null) :
            (parameters.disableNoSpeechRecognizedEvent));
    this.Serializable$set(
        'bargeInConfig',
        (parameters.bargeInConfig == null) ? (null) :
                                             (parameters.bargeInConfig));
    this.Serializable$set(
        'defaultNoSpeechTimeout',
        (parameters.defaultNoSpeechTimeout == null) ?
            (null) :
            (parameters.defaultNoSpeechTimeout));
    this.Serializable$set(
        'alternativeLanguageCodes',
        (parameters.alternativeLanguageCodes == null) ?
            (null) :
            (parameters.alternativeLanguageCodes));
    this.Serializable$set(
        'phraseSet',
        (parameters.phraseSet == null) ? (null) : (parameters.phraseSet));
    this.Serializable$set(
        'audioStreamInfo',
        (parameters.audioStreamInfo == null) ? (null) :
                                               (parameters.audioStreamInfo));
  }

  static get AudioEncoding():
      IGoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum {
    return GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum;
  }

  static get ModelVariant():
      IGoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariantEnum {
    return GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariantEnum;
  }

  get alternativeLanguageCodes(): Array<string>|null {
    return (
        (this.Serializable$has('alternativeLanguageCodes')) ?
            (this.Serializable$get('alternativeLanguageCodes')) :
            (null));
  }

  /**
   * A list of up to 3 additional
   * [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt) language tags,
   * listing possible alternative languages of the supplied audio. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes. If alternative languages
   * are listed, recognition result will contain recognition in the most likely
   * language detected including the main language_code. The recognition result
   * will include the language tag of the language detected in the audio. Extra
   * language codes will be ignored.
   */
  set alternativeLanguageCodes(value: Array<string>|null) {
    this.Serializable$set('alternativeLanguageCodes', value);
  }

  get audioChannelCount(): number|null {
    return (
        (this.Serializable$has('audioChannelCount')) ?
            (this.Serializable$get('audioChannelCount')) :
            (null));
  }

  /**
   * Optional. The number of channels in the input audio data. Valid values for
   * LINEAR16 and FLAC are `1`-`8`. Valid values for MULAW, AMR and AMR_WB are
   * only `1`. If `0` or omitted, defaults to one channel (mono). NOTE:
   * Currently, we perform recognition only on channel 0 and ignore all other
   * channels.
   */
  set audioChannelCount(value: number|null) {
    this.Serializable$set('audioChannelCount', value);
  }

  get audioEncoding():
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding|null {
    return (
        (this.Serializable$has('audioEncoding')) ?
            (this.Serializable$get('audioEncoding')) :
            (null));
  }

  /**
   * Required. Audio encoding of the audio content to process.
   */
  set audioEncoding(
      value: GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncoding|null) {
    this.Serializable$set('audioEncoding', value);
  }

  get audioStreamInfo():
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioStreamInfo|null {
    return (
        (this.Serializable$has('audioStreamInfo')) ?
            (this.Serializable$get('audioStreamInfo')) :
            (null));
  }

  /**
   * Output only. Info on the audio stream.
   */
  set audioStreamInfo(
      value: GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioStreamInfo|
      null) {
    this.Serializable$set('audioStreamInfo', value);
  }

  get bargeInConfig(): GoogleCloudDialogflowCxV3beta1BargeInConfig|null {
    return (
        (this.Serializable$has('bargeInConfig')) ?
            (this.Serializable$get('bargeInConfig')) :
            (null));
  }

  /**
   * Configuration of barge-in behavior during the streaming of input audio.
   */
  set bargeInConfig(value: GoogleCloudDialogflowCxV3beta1BargeInConfig|null) {
    this.Serializable$set('bargeInConfig', value);
  }

  get defaultNoSpeechTimeout(): string|null {
    return (
        (this.Serializable$has('defaultNoSpeechTimeout')) ?
            (this.Serializable$get('defaultNoSpeechTimeout')) :
            (null));
  }

  /**
   * If set, use this no-speech timeout when the agent does not provide a
   * no-speech timeout itself.
   */
  set defaultNoSpeechTimeout(value: string|null) {
    this.Serializable$set('defaultNoSpeechTimeout', value);
  }

  get disableNoSpeechRecognizedEvent(): boolean|null {
    return (
        (this.Serializable$has('disableNoSpeechRecognizedEvent')) ?
            (this.Serializable$get('disableNoSpeechRecognizedEvent')) :
            (null));
  }

  /**
   * Only used in Participants.AnalyzeContent and
   * Participants.StreamingAnalyzeContent. If `false` and recognition doesn't
   * return any result, trigger `NO_SPEECH_RECOGNIZED` event to Dialogflow
   * agent.
   */
  set disableNoSpeechRecognizedEvent(value: boolean|null) {
    this.Serializable$set('disableNoSpeechRecognizedEvent', value);
  }

  get enableWordInfo(): boolean|null {
    return (
        (this.Serializable$has('enableWordInfo')) ?
            (this.Serializable$get('enableWordInfo')) :
            (null));
  }

  /**
   * Optional. If `true`, Dialogflow returns SpeechWordInfo in
   * StreamingRecognitionResult with information about the recognized speech
   * words, e.g. start and end time offsets. If false or unspecified, Speech
   * doesn't return any word-level information.
   */
  set enableWordInfo(value: boolean|null) {
    this.Serializable$set('enableWordInfo', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Only populated internally in Dialogflow API server to ease speech
   * implementation. Copied from `query_input.language_code`.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Optional. Which Speech model to select for the given request. Select the
   * model best suited to your domain to get best results. If a model is not
   * explicitly specified, then we auto-select a model based on the parameters
   * in the InputAudioConfig. If enhanced speech model is enabled for the agent
   * and an enhanced version of the specified model for the language does not
   * exist, then the speech is recognized using the standard version of the
   * specified model. Refer to [Cloud Speech API
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#select-model)
   * for more details.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  get modelVariant(): GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant
      |null {
    return (
        (this.Serializable$has('modelVariant')) ?
            (this.Serializable$get('modelVariant')) :
            (null));
  }

  /**
   * Optional. Which variant of the Speech model to use.
   */
  set modelVariant(
      value: GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariant|null) {
    this.Serializable$set('modelVariant', value);
  }

  get phraseHints(): Array<string>|null {
    return (
        (this.Serializable$has('phraseHints')) ?
            (this.Serializable$get('phraseHints')) :
            (null));
  }

  /**
   * Optional. A list of strings containing words and phrases that the speech
   * recognizer should recognize with higher likelihood. See [the Cloud Speech
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#phrase-hints)
   * for more details.
   */
  set phraseHints(value: Array<string>|null) {
    this.Serializable$set('phraseHints', value);
  }

  get phraseSet(): Array<string>|null {
    return (
        (this.Serializable$has('phraseSet')) ?
            (this.Serializable$get('phraseSet')) :
            (null));
  }

  /**
   * A collection of phrase set resources to use for speech adaptation.
   */
  set phraseSet(value: Array<string>|null) {
    this.Serializable$set('phraseSet', value);
  }

  get sampleRateHertz(): number|null {
    return (
        (this.Serializable$has('sampleRateHertz')) ?
            (this.Serializable$get('sampleRateHertz')) :
            (null));
  }

  /**
   * Sample rate (in Hertz) of the audio content sent in the query. Refer to
   * [Cloud Speech API
   * documentation](https://cloud.google.com/speech-to-text/docs/basics) for
   * more details.
   */
  set sampleRateHertz(value: number|null) {
    this.Serializable$set('sampleRateHertz', value);
  }

  get singleUtterance(): boolean|null {
    return (
        (this.Serializable$has('singleUtterance')) ?
            (this.Serializable$get('singleUtterance')) :
            (null));
  }

  /**
   * Optional. If `false` (default), recognition does not cease until the client
   * closes the stream. If `true`, the recognizer will detect a single spoken
   * utterance in input audio. Recognition ceases when it detects the audio's
   * voice has stopped or paused. In this case, once a detected intent is
   * received, the client should close the stream and start a new request with a
   * new stream as needed. Note: This setting is relevant only for streaming
   * methods.
   */
  set singleUtterance(value: boolean|null) {
    this.Serializable$set('singleUtterance', value);
  }

  get speechContexts():
      Array<GoogleCloudDialogflowCxV3beta1SpeechContext>|null {
    return (
        (this.Serializable$has('speechContexts')) ?
            (this.Serializable$get('speechContexts')) :
            (null));
  }

  /**
   * Optional. Context information to assist speech recognition. See [the Cloud
   * Speech
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#phrase-hints)
   * for more details.
   */
  set speechContexts(value: Array<GoogleCloudDialogflowCxV3beta1SpeechContext>|
                     null) {
    this.Serializable$set('speechContexts', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1InputAudioConfig> {
    return GoogleCloudDialogflowCxV3beta1InputAudioConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'speechContexts': GoogleCloudDialogflowCxV3beta1SpeechContext},
      enums: {
        'audioEncoding':
            GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioEncodingEnum,
        'modelVariant':
            GoogleCloudDialogflowCxV3beta1InputAudioConfigModelVariantEnum
      },
      keys: [
        'alternativeLanguageCodes', 'audioChannelCount', 'audioEncoding',
        'audioStreamInfo', 'bargeInConfig', 'defaultNoSpeechTimeout',
        'disableNoSpeechRecognizedEvent', 'enableWordInfo', 'languageCode',
        'model', 'modelVariant', 'phraseHints', 'phraseSet', 'sampleRateHertz',
        'singleUtterance', 'speechContexts'
      ],
      objects: {
        'audioStreamInfo':
            GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioStreamInfo,
        'bargeInConfig': GoogleCloudDialogflowCxV3beta1BargeInConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioStreamInfoParameters {
  startTime?: string|null;
  detectionStartOffset?: string|null;
}
export class
    GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioStreamInfo extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioStreamInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'startTime',
        (parameters.startTime == null) ? (null) : (parameters.startTime));
    this.Serializable$set(
        'detectionStartOffset',
        (parameters.detectionStartOffset == null) ?
            (null) :
            (parameters.detectionStartOffset));
  }

  get detectionStartOffset(): string|null {
    return (
        (this.Serializable$has('detectionStartOffset')) ?
            (this.Serializable$get('detectionStartOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio stream that corresponds
   * to the start of the speech detection.
   */
  set detectionStartOffset(value: string|null) {
    this.Serializable$set('detectionStartOffset', value);
  }

  get startTime(): string|null {
    return (
        (this.Serializable$has('startTime')) ?
            (this.Serializable$get('startTime')) :
            (null));
  }

  /**
   * Absolute start time of the stream. It can be gRPC stream or telephony
   * stream.
   */
  set startTime(value: string|null) {
    this.Serializable$set('startTime', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioStreamInfo> {
    return GoogleCloudDialogflowCxV3beta1InputAudioConfigAudioStreamInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['detectionStartOffset', 'startTime']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1IntentParameters {
  name?: string|null;
  displayName?: string|null;
  trainingPhrases?: Array<GoogleCloudDialogflowCxV3beta1IntentTrainingPhrase>|
      null;
  parameters?: Array<GoogleCloudDialogflowCxV3beta1IntentParameter>|null;
  asrCorrections?: Array<GoogleCloudDialogflowCxV3beta1IntentAsrCorrection>|
      null;
  priority?: number|null;
  isFallback?: boolean|null;
  labels?: ApiClientObjectMap<string>|null;
  description?: string|null;
  createTime?: string|null;
  updateTime?: string|null;
  clarifyingPhrase?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1Intent extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3beta1IntentParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'trainingPhrases',
        (parameters.trainingPhrases == null) ? (null) :
                                               (parameters.trainingPhrases));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'asrCorrections',
        (parameters.asrCorrections == null) ? (null) :
                                              (parameters.asrCorrections));
    this.Serializable$set(
        'priority',
        (parameters.priority == null) ? (null) : (parameters.priority));
    this.Serializable$set(
        'isFallback',
        (parameters.isFallback == null) ? (null) : (parameters.isFallback));
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'clarifyingPhrase',
        (parameters.clarifyingPhrase == null) ? (null) :
                                                (parameters.clarifyingPhrase));
  }

  get asrCorrections():
      Array<GoogleCloudDialogflowCxV3beta1IntentAsrCorrection>|null {
    return (
        (this.Serializable$has('asrCorrections')) ?
            (this.Serializable$get('asrCorrections')) :
            (null));
  }

  /**
   * The collection of ASR correction rules provided by developers for the
   * intent. Each rule is a phrase with ASR errors and its corrected
   * counterpart, e.g., error_phrase: \"i raven\" -> correction_phrase: \"my
   * payment\".
   */
  set asrCorrections(
      value: Array<GoogleCloudDialogflowCxV3beta1IntentAsrCorrection>|null) {
    this.Serializable$set('asrCorrections', value);
  }

  get clarifyingPhrase(): string|null {
    return (
        (this.Serializable$has('clarifyingPhrase')) ?
            (this.Serializable$get('clarifyingPhrase')) :
            (null));
  }

  /**
   * Human understandable phrase that can be used to clarify how to trigger this
   * intent. This field can be used in agent responses to disambiguate amongst
   * different intents. Maximum character limit: 140 characters.
   */
  set clarifyingPhrase(value: string|null) {
    this.Serializable$set('clarifyingPhrase', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The time the intent was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Human readable description for better understanding an intent like its
   * scope, content, result etc. Maximum character limit: 140 characters.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the intent, unique within the agent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get isFallback(): boolean|null {
    return (
        (this.Serializable$has('isFallback')) ?
            (this.Serializable$get('isFallback')) :
            (null));
  }

  /**
   * Indicates whether this is a fallback intent. Currently only default
   * fallback intent is allowed in the agent, which is added upon agent
   * creation. Adding training phrases to fallback intent is useful in the case
   * of requests that are mistakenly matched, since training phrases assigned to
   * fallback intents act as negative examples that triggers no-match event.
   */
  set isFallback(value: boolean|null) {
    this.Serializable$set('isFallback', value);
  }

  get labels(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * The key/value metadata to label an intent. Labels can contain lowercase
   * letters, digits and the symbols '-' and '_'. International characters are
   * allowed, including letters from unicase alphabets. Keys must start with a
   * letter. Keys and values can be no longer than 63 characters and no more
   * than 128 bytes. Prefix \"sys-\" is reserved for Dialogflow defined labels.
   * Currently allowed Dialogflow defined labels include: * sys-head *
   * sys-contextual The above labels do not require value. \"sys-head\" means
   * the intent is a head intent. \"sys-contextual\" means the intent is a
   * contextual intent.
   */
  set labels(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('labels', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the intent. Required for the Intents.UpdateIntent
   * method. Intents.CreateIntent populates the name automatically. Format:
   * `projects//locations//agents//intents/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameters(): Array<GoogleCloudDialogflowCxV3beta1IntentParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of parameters associated with the intent.
   */
  set parameters(value: Array<GoogleCloudDialogflowCxV3beta1IntentParameter>|
                 null) {
    this.Serializable$set('parameters', value);
  }

  get priority(): number|null {
    return (
        (this.Serializable$has('priority')) ?
            (this.Serializable$get('priority')) :
            (null));
  }

  /**
   * The priority of this intent. Higher numbers represent higher priorities. -
   * If the supplied value is unspecified or 0, the service translates the value
   * to 500,000, which corresponds to the `Normal` priority in the console. - If
   * the supplied value is negative, the intent is ignored in runtime detect
   * intent requests.
   */
  set priority(value: number|null) {
    this.Serializable$set('priority', value);
  }

  get trainingPhrases():
      Array<GoogleCloudDialogflowCxV3beta1IntentTrainingPhrase>|null {
    return (
        (this.Serializable$has('trainingPhrases')) ?
            (this.Serializable$get('trainingPhrases')) :
            (null));
  }

  /**
   * The collection of training phrases the agent is trained on to identify the
   * intent.
   */
  set trainingPhrases(
      value: Array<GoogleCloudDialogflowCxV3beta1IntentTrainingPhrase>|null) {
    this.Serializable$set('trainingPhrases', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. Last time the intent was modified.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1Intent> {
    return GoogleCloudDialogflowCxV3beta1Intent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'asrCorrections': GoogleCloudDialogflowCxV3beta1IntentAsrCorrection,
        'parameters': GoogleCloudDialogflowCxV3beta1IntentParameter,
        'trainingPhrases': GoogleCloudDialogflowCxV3beta1IntentTrainingPhrase
      },
      keys: [
        'asrCorrections', 'clarifyingPhrase', 'createTime', 'description',
        'displayName', 'isFallback', 'labels', 'name', 'parameters', 'priority',
        'trainingPhrases', 'updateTime'
      ],
      objectMaps: {
        'labels': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1IntentAsrCorrectionParameters {
  errorPhrase?: string|null;
  correctionPhrase?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1IntentAsrCorrection extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1IntentAsrCorrectionParameters = {}) {
    super();
    this.Serializable$set(
        'errorPhrase',
        (parameters.errorPhrase == null) ? (null) : (parameters.errorPhrase));
    this.Serializable$set(
        'correctionPhrase',
        (parameters.correctionPhrase == null) ? (null) :
                                                (parameters.correctionPhrase));
  }

  get correctionPhrase(): string|null {
    return (
        (this.Serializable$has('correctionPhrase')) ?
            (this.Serializable$get('correctionPhrase')) :
            (null));
  }

  /**
   * The phrase with ASR errors corrected.
   */
  set correctionPhrase(value: string|null) {
    this.Serializable$set('correctionPhrase', value);
  }

  get errorPhrase(): string|null {
    return (
        (this.Serializable$has('errorPhrase')) ?
            (this.Serializable$get('errorPhrase')) :
            (null));
  }

  /**
   * The phrase with one or more ASR errors.
   */
  set errorPhrase(value: string|null) {
    this.Serializable$set('errorPhrase', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1IntentAsrCorrection> {
    return GoogleCloudDialogflowCxV3beta1IntentAsrCorrection;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['correctionPhrase', 'errorPhrase']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1IntentConflictDiffParameters {
  displayName?: string|null;
  originalIntentId?: string|null;
  importedIntentId?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1IntentConflictDiff extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1IntentConflictDiffParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'originalIntentId',
        (parameters.originalIntentId == null) ? (null) :
                                                (parameters.originalIntentId));
    this.Serializable$set(
        'importedIntentId',
        (parameters.importedIntentId == null) ? (null) :
                                                (parameters.importedIntentId));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Conflicting display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get importedIntentId(): string|null {
    return (
        (this.Serializable$has('importedIntentId')) ?
            (this.Serializable$get('importedIntentId')) :
            (null));
  }

  /**
   * The name of the conflicting intent in the content to be imported. This is a
   * unique identifier. Format: `projects//locations//agents//intents/`.
   */
  set importedIntentId(value: string|null) {
    this.Serializable$set('importedIntentId', value);
  }

  get originalIntentId(): string|null {
    return (
        (this.Serializable$has('originalIntentId')) ?
            (this.Serializable$get('originalIntentId')) :
            (null));
  }

  /**
   * The name of the conflicting intent in the current agent. This is a unique
   * identifier. Format: `projects//locations//agents//intents/`.
   */
  set originalIntentId(value: string|null) {
    this.Serializable$set('originalIntentId', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1IntentConflictDiff> {
    return GoogleCloudDialogflowCxV3beta1IntentConflictDiff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'importedIntentId', 'originalIntentId']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1IntentInputParameters {
  intent?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1IntentInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1IntentInputParameters = {}) {
    super();
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * Required. The unique identifier of the intent. Format:
   * `projects//locations//agents//intents/`.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1IntentInput> {
    return GoogleCloudDialogflowCxV3beta1IntentInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intent']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1IntentParameterParameters {
  id?: string|null;
  entityType?: string|null;
  isList?: boolean|null;
  redact?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1IntentParameter extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1IntentParameterParameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
    this.Serializable$set(
        'isList', (parameters.isList == null) ? (null) : (parameters.isList));
    this.Serializable$set(
        'redact', (parameters.redact == null) ? (null) : (parameters.redact));
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * Required. The entity type of the parameter. Format:
   * `projects/-/locations/-/agents/-/entityTypes/` for system entity types (for
   * example, `projects/-/locations/-/agents/-/entityTypes/sys.date`), or
   * `projects//locations//agents//entityTypes/` for developer entity types.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Required. The unique identifier of the parameter. This field is used by
   * training phrases to annotate their parts.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get isList(): boolean|null {
    return (
        (this.Serializable$has('isList')) ? (this.Serializable$get('isList')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter represents a list of values.
   */
  set isList(value: boolean|null) {
    this.Serializable$set('isList', value);
  }

  get redact(): boolean|null {
    return (
        (this.Serializable$has('redact')) ? (this.Serializable$get('redact')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter content should be redacted in log. If
   * redaction is enabled, the parameter content will be replaced by parameter
   * name during logging. Note: the parameter content is subject to redaction if
   * either parameter level redaction or entity type level redaction is enabled.
   */
  set redact(value: boolean|null) {
    this.Serializable$set('redact', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1IntentParameter> {
    return GoogleCloudDialogflowCxV3beta1IntentParameter;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['entityType', 'id', 'isList', 'redact']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1IntentTrainingPhraseParameters {
  id?: string|null;
  parts?: Array<GoogleCloudDialogflowCxV3beta1IntentTrainingPhrasePart>|null;
  repeatCount?: number|null;
  unassessed?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1IntentTrainingPhrase extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1IntentTrainingPhraseParameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'parts', (parameters.parts == null) ? (null) : (parameters.parts));
    this.Serializable$set(
        'repeatCount',
        (parameters.repeatCount == null) ? (null) : (parameters.repeatCount));
    this.Serializable$set(
        'unassessed',
        (parameters.unassessed == null) ? (null) : (parameters.unassessed));
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Output only. The unique identifier of the training phrase.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get parts():
      Array<GoogleCloudDialogflowCxV3beta1IntentTrainingPhrasePart>|null {
    return (
        (this.Serializable$has('parts')) ? (this.Serializable$get('parts')) :
                                           (null));
  }

  /**
   * Required. The ordered list of training phrase parts. The parts are
   * concatenated in order to form the training phrase. Note: The API does not
   * automatically annotate training phrases like the Dialogflow Console does.
   * Note: Do not forget to include whitespace at part boundaries, so the
   * training phrase is well formatted when the parts are concatenated. If the
   * training phrase does not need to be annotated with parameters, you just
   * need a single part with only the Part.text field set. If you want to
   * annotate the training phrase, you must create multiple parts, where the
   * fields of each part are populated in one of two ways: - `Part.text` is set
   * to a part of the phrase that has no parameters. - `Part.text` is set to a
   * part of the phrase that you want to annotate, and the `parameter_id` field
   * is set.
   */
  set parts(value:
                Array<GoogleCloudDialogflowCxV3beta1IntentTrainingPhrasePart>|
            null) {
    this.Serializable$set('parts', value);
  }

  get repeatCount(): number|null {
    return (
        (this.Serializable$has('repeatCount')) ?
            (this.Serializable$get('repeatCount')) :
            (null));
  }

  /**
   * Indicates how many times this example was added to the intent.
   */
  set repeatCount(value: number|null) {
    this.Serializable$set('repeatCount', value);
  }

  get unassessed(): boolean|null {
    return (
        (this.Serializable$has('unassessed')) ?
            (this.Serializable$get('unassessed')) :
            (null));
  }

  /**
   * Indicates whether this training phrase has not been assessed yet.
   */
  set unassessed(value: boolean|null) {
    this.Serializable$set('unassessed', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1IntentTrainingPhrase> {
    return GoogleCloudDialogflowCxV3beta1IntentTrainingPhrase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parts': GoogleCloudDialogflowCxV3beta1IntentTrainingPhrasePart},
      keys: ['id', 'parts', 'repeatCount', 'unassessed']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1IntentTrainingPhrasePartParameters {
  text?: string|null;
  parameterId?: string|null;
  auto?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1IntentTrainingPhrasePart extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1IntentTrainingPhrasePartParameters =
              {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'parameterId',
        (parameters.parameterId == null) ? (null) : (parameters.parameterId));
    this.Serializable$set(
        'auto', (parameters.auto == null) ? (null) : (parameters.auto));
  }

  get auto(): boolean|null {
    return (
        (this.Serializable$has('auto')) ? (this.Serializable$get('auto')) :
                                          (null));
  }

  /**
   * Indicates whether the text was auto-annotated. This field is only set to
   * true when the Dialogflow Console auto-annotates the part.
   */
  set auto(value: boolean|null) {
    this.Serializable$set('auto', value);
  }

  get parameterId(): string|null {
    return (
        (this.Serializable$has('parameterId')) ?
            (this.Serializable$get('parameterId')) :
            (null));
  }

  /**
   * The parameter used to annotate this part of the training phrase. This field
   * is required for annotated parts of the training phrase.
   */
  set parameterId(value: string|null) {
    this.Serializable$set('parameterId', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. The text for this part.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1IntentTrainingPhrasePart> {
    return GoogleCloudDialogflowCxV3beta1IntentTrainingPhrasePart;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['auto', 'parameterId', 'text']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsParameters {
  audioExportGcsDestination?: GoogleCloudDialogflowCxV3beta1GcsDestination|null;
  disableLogging?: boolean|null;
  dtmfSettings?:
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsDtmfSettings|
      null;
  speechSettings?:
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsSpeechSettings|
      null;
  loggingSettings?:
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsLoggingSettings|
      null;
}
export class
    GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'audioExportGcsDestination',
        (parameters.audioExportGcsDestination == null) ?
            (null) :
            (parameters.audioExportGcsDestination));
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
    this.Serializable$set(
        'dtmfSettings',
        (parameters.dtmfSettings == null) ? (null) : (parameters.dtmfSettings));
    this.Serializable$set(
        'speechSettings',
        (parameters.speechSettings == null) ? (null) :
                                              (parameters.speechSettings));
    this.Serializable$set(
        'loggingSettings',
        (parameters.loggingSettings == null) ? (null) :
                                               (parameters.loggingSettings));
  }

  get audioExportGcsDestination(): GoogleCloudDialogflowCxV3beta1GcsDestination
      |null {
    return (
        (this.Serializable$has('audioExportGcsDestination')) ?
            (this.Serializable$get('audioExportGcsDestination')) :
            (null));
  }

  /**
   * If present, incoming audio is exported by Dialogflow to the configured
   * Google Cloud Storage destination.
   */
  set audioExportGcsDestination(
      value: GoogleCloudDialogflowCxV3beta1GcsDestination|null) {
    this.Serializable$set('audioExportGcsDestination', value);
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * If true, logging is currently disabled. This setting overrides the agent-
   * level settings for Dialogflow History, Contact Center messages, StackDriver
   * logs, and speech logging. Usually, this is configured just for specific
   * pages in the agent (e.g. to temporarily disable logging for a sensitive
   * operation).
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  get dtmfSettings():
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsDtmfSettings
      |null {
    return (
        (this.Serializable$has('dtmfSettings')) ?
            (this.Serializable$get('dtmfSettings')) :
            (null));
  }

  /**
   * Settings for DTMF.
   */
  set dtmfSettings(
      value:
          GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsDtmfSettings|
      null) {
    this.Serializable$set('dtmfSettings', value);
  }

  get loggingSettings():
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsLoggingSettings
      |null {
    return (
        (this.Serializable$has('loggingSettings')) ?
            (this.Serializable$get('loggingSettings')) :
            (null));
  }

  /**
   * Settings for logging.
   */
  set loggingSettings(
      value:
          GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsLoggingSettings|
      null) {
    this.Serializable$set('loggingSettings', value);
  }

  get speechSettings():
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsSpeechSettings
      |null {
    return (
        (this.Serializable$has('speechSettings')) ?
            (this.Serializable$get('speechSettings')) :
            (null));
  }

  /**
   * Settings for speech to text detection.
   */
  set speechSettings(
      value:
          GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsSpeechSettings|
      null) {
    this.Serializable$set('speechSettings', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettings> {
    return GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'audioExportGcsDestination', 'disableLogging', 'dtmfSettings',
        'loggingSettings', 'speechSettings'
      ],
      objects: {
        'audioExportGcsDestination':
            GoogleCloudDialogflowCxV3beta1GcsDestination,
        'dtmfSettings':
            GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsDtmfSettings,
        'loggingSettings':
            GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsLoggingSettings,
        'speechSettings':
            GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsSpeechSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsDtmfSettingsParameters {
  enabled?: boolean|null;
  parameter?: string|null;
  maxDigits?: number|null;
  finishDigit?: string|null;
}
export class
    GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsDtmfSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsDtmfSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
    this.Serializable$set(
        'maxDigits',
        (parameters.maxDigits == null) ? (null) : (parameters.maxDigits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * If true, incoming audio is processed for DTMF (dual tone multi frequency)
   * events. For example, if the caller presses a button on their telephone
   * keypad and DTMF processing is enabled, Dialogflow will detect the event
   * (e.g. a \"3\" was pressed) in the incoming audio and pass the event to the
   * bot to drive business logic (e.g. when 3 is pressed, return the account
   * balance).
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get finishDigit(): string|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * The digit that terminates a DTMF digit sequence.
   */
  set finishDigit(value: string|null) {
    this.Serializable$set('finishDigit', value);
  }

  get maxDigits(): number|null {
    return (
        (this.Serializable$has('maxDigits')) ?
            (this.Serializable$get('maxDigits')) :
            (null));
  }

  /**
   * Max length of DTMF digits.
   */
  set maxDigits(value: number|null) {
    this.Serializable$set('maxDigits', value);
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * If not empty, the transcribed DTMF digits will be set into the value of the
   * specified slot parameter.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsDtmfSettings> {
    return GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsDtmfSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enabled', 'finishDigit', 'maxDigits', 'parameter']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsLoggingSettingsParameters {
  disableLogging?: boolean|null;
}
export class
    GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsLoggingSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsLoggingSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * If true, logging is currently disabled. This setting overrides the agent-
   * level settings for Dialogflow History, Contact Center messages, StackDriver
   * logs, and speech logging. Usually, this is configured just for specific
   * pages in the agent (e.g. to temporarily disable logging for a sensitive
   * operation).
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsLoggingSettings> {
    return GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsLoggingSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['disableLogging']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsSpeechSettingsParameters {
  endpointerSensitivity?: number|null;
  noSpeechTimeout?: string|null;
}
export class
    GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsSpeechSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsSpeechSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'endpointerSensitivity',
        (parameters.endpointerSensitivity == null) ?
            (null) :
            (parameters.endpointerSensitivity));
    this.Serializable$set(
        'noSpeechTimeout',
        (parameters.noSpeechTimeout == null) ? (null) :
                                               (parameters.noSpeechTimeout));
  }

  get endpointerSensitivity(): number|null {
    return (
        (this.Serializable$has('endpointerSensitivity')) ?
            (this.Serializable$get('endpointerSensitivity')) :
            (null));
  }

  /**
   * Sensitivity of the speech model that detects the end of speech. Scale from
   * 0 to 100.
   */
  set endpointerSensitivity(value: number|null) {
    this.Serializable$set('endpointerSensitivity', value);
  }

  get noSpeechTimeout(): string|null {
    return (
        (this.Serializable$has('noSpeechTimeout')) ?
            (this.Serializable$get('noSpeechTimeout')) :
            (null));
  }

  /**
   * Timeout before detecting no speech.
   */
  set noSpeechTimeout(value: string|null) {
    this.Serializable$set('noSpeechTimeout', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsSpeechSettings> {
    return GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettingsSpeechSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['endpointerSensitivity', 'noSpeechTimeout']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1KnowledgeConnectorSettingsParameters {
  enabled?: boolean|null;
  knowledgeBaseIds?: Array<string>|null;
  triggerFulfillment?: GoogleCloudDialogflowCxV3beta1Fulfillment|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1KnowledgeConnectorSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1KnowledgeConnectorSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'knowledgeBaseIds',
        (parameters.knowledgeBaseIds == null) ? (null) :
                                                (parameters.knowledgeBaseIds));
    this.Serializable$set(
        'triggerFulfillment',
        (parameters.triggerFulfillment == null) ?
            (null) :
            (parameters.triggerFulfillment));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * Whether Knowledge Connector is enabled for this page. Users might want to
   * keep configurations the same (which Knowledge Bases are attached to the
   * page, how fulfillment should work, etc.), but temporarily disable Knowledge
   * Connector functionality.
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get knowledgeBaseIds(): Array<string>|null {
    return (
        (this.Serializable$has('knowledgeBaseIds')) ?
            (this.Serializable$get('knowledgeBaseIds')) :
            (null));
  }

  /**
   * The list of knowledge bases attached to this page. The entries are the full
   * names of Knowledge Bases. Format: `projects//locations//knowledgeBases/`
   */
  set knowledgeBaseIds(value: Array<string>|null) {
    this.Serializable$set('knowledgeBaseIds', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  get triggerFulfillment(): GoogleCloudDialogflowCxV3beta1Fulfillment|null {
    return (
        (this.Serializable$has('triggerFulfillment')) ?
            (this.Serializable$get('triggerFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to be triggered, in case the Knowledge Base answers are the
   * winners of this conversation turn. The fulfillment should make use of the
   * request scoped parameter `$request.knowledge.answer`, containing up to the
   * 5 highest confidence answers, as well as `$request.knowledge.question`,
   * containing the corresponding questions.
   */
  set triggerFulfillment(value: GoogleCloudDialogflowCxV3beta1Fulfillment|
                         null) {
    this.Serializable$set('triggerFulfillment', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1KnowledgeConnectorSettings> {
    return GoogleCloudDialogflowCxV3beta1KnowledgeConnectorSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'enabled', 'knowledgeBaseIds', 'targetFlow', 'targetPage',
        'triggerFulfillment'
      ],
      objects: {'triggerFulfillment': GoogleCloudDialogflowCxV3beta1Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1PageParameters {
  name?: string|null;
  displayName?: string|null;
  entryFulfillment?: GoogleCloudDialogflowCxV3beta1Fulfillment|null;
  form?: GoogleCloudDialogflowCxV3beta1Form|null;
  transitionRouteGroups?: Array<string>|null;
  transitionRoutes?: Array<GoogleCloudDialogflowCxV3beta1TransitionRoute>|null;
  eventHandlers?: Array<GoogleCloudDialogflowCxV3beta1EventHandler>|null;
  advancedSettings?: GoogleCloudDialogflowCxV3beta1AdvancedSettings|null;
  speakerIdSettings?: GoogleCloudDialogflowCxV3beta1SpeakerIdSettings|null;
  knowledgeConnectorSettings?:
      GoogleCloudDialogflowCxV3beta1KnowledgeConnectorSettings|null;
}
export class GoogleCloudDialogflowCxV3beta1Page extends Serializable {
  constructor(parameters: GoogleCloudDialogflowCxV3beta1PageParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'entryFulfillment',
        (parameters.entryFulfillment == null) ? (null) :
                                                (parameters.entryFulfillment));
    this.Serializable$set(
        'form', (parameters.form == null) ? (null) : (parameters.form));
    this.Serializable$set(
        'transitionRouteGroups',
        (parameters.transitionRouteGroups == null) ?
            (null) :
            (parameters.transitionRouteGroups));
    this.Serializable$set(
        'transitionRoutes',
        (parameters.transitionRoutes == null) ? (null) :
                                                (parameters.transitionRoutes));
    this.Serializable$set(
        'eventHandlers',
        (parameters.eventHandlers == null) ? (null) :
                                             (parameters.eventHandlers));
    this.Serializable$set(
        'advancedSettings',
        (parameters.advancedSettings == null) ? (null) :
                                                (parameters.advancedSettings));
    this.Serializable$set(
        'speakerIdSettings',
        (parameters.speakerIdSettings == null) ?
            (null) :
            (parameters.speakerIdSettings));
    this.Serializable$set(
        'knowledgeConnectorSettings',
        (parameters.knowledgeConnectorSettings == null) ?
            (null) :
            (parameters.knowledgeConnectorSettings));
  }

  get advancedSettings(): GoogleCloudDialogflowCxV3beta1AdvancedSettings|null {
    return (
        (this.Serializable$has('advancedSettings')) ?
            (this.Serializable$get('advancedSettings')) :
            (null));
  }

  /**
   * Hierarchical advanced settings for this page. The settings exposed at the
   * lower level overrides the settings exposed at the higher level.
   */
  set advancedSettings(value: GoogleCloudDialogflowCxV3beta1AdvancedSettings|
                       null) {
    this.Serializable$set('advancedSettings', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the page, unique within the flow.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get entryFulfillment(): GoogleCloudDialogflowCxV3beta1Fulfillment|null {
    return (
        (this.Serializable$has('entryFulfillment')) ?
            (this.Serializable$get('entryFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to call when the session is entering the page.
   */
  set entryFulfillment(value: GoogleCloudDialogflowCxV3beta1Fulfillment|null) {
    this.Serializable$set('entryFulfillment', value);
  }

  get eventHandlers(): Array<GoogleCloudDialogflowCxV3beta1EventHandler>|null {
    return (
        (this.Serializable$has('eventHandlers')) ?
            (this.Serializable$get('eventHandlers')) :
            (null));
  }

  /**
   * Handlers associated with the page to handle events such as webhook errors,
   * no match or no input.
   */
  set eventHandlers(value: Array<GoogleCloudDialogflowCxV3beta1EventHandler>|
                    null) {
    this.Serializable$set('eventHandlers', value);
  }

  get form(): GoogleCloudDialogflowCxV3beta1Form|null {
    return (
        (this.Serializable$has('form')) ? (this.Serializable$get('form')) :
                                          (null));
  }

  /**
   * The form associated with the page, used for collecting parameters relevant
   * to the page.
   */
  set form(value: GoogleCloudDialogflowCxV3beta1Form|null) {
    this.Serializable$set('form', value);
  }

  get knowledgeConnectorSettings():
      GoogleCloudDialogflowCxV3beta1KnowledgeConnectorSettings|null {
    return (
        (this.Serializable$has('knowledgeConnectorSettings')) ?
            (this.Serializable$get('knowledgeConnectorSettings')) :
            (null));
  }

  /**
   * Should only be set in case this is Page is connected to Knowledge Bases.
   */
  set knowledgeConnectorSettings(
      value: GoogleCloudDialogflowCxV3beta1KnowledgeConnectorSettings|null) {
    this.Serializable$set('knowledgeConnectorSettings', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the page. Required for the Pages.UpdatePage
   * method. Pages.CreatePage populates the name automatically. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get speakerIdSettings(): GoogleCloudDialogflowCxV3beta1SpeakerIdSettings
      |null {
    return (
        (this.Serializable$has('speakerIdSettings')) ?
            (this.Serializable$get('speakerIdSettings')) :
            (null));
  }

  /**
   * The Speaker ID settings for this page.
   */
  set speakerIdSettings(value: GoogleCloudDialogflowCxV3beta1SpeakerIdSettings|
                        null) {
    this.Serializable$set('speakerIdSettings', value);
  }

  get transitionRouteGroups(): Array<string>|null {
    return (
        (this.Serializable$has('transitionRouteGroups')) ?
            (this.Serializable$get('transitionRouteGroups')) :
            (null));
  }

  /**
   * Ordered list of `TransitionRouteGroups` associated with the page.
   * Transition route groups must be unique within a page. * If multiple
   * transition routes within a page scope refer to the same intent, then the
   * precedence order is: page's transition route -> page's transition route
   * group -> flow's transition routes. * If multiple transition route groups
   * within a page contain the same intent, then the first group in the ordered
   * list takes precedence.
   * Format:`projects//locations//agents//flows//transitionRouteGroups/`.
   */
  set transitionRouteGroups(value: Array<string>|null) {
    this.Serializable$set('transitionRouteGroups', value);
  }

  get transitionRoutes():
      Array<GoogleCloudDialogflowCxV3beta1TransitionRoute>|null {
    return (
        (this.Serializable$has('transitionRoutes')) ?
            (this.Serializable$get('transitionRoutes')) :
            (null));
  }

  /**
   * A list of transitions for the transition rules of this page. They route the
   * conversation to another page in the same flow, or another flow. When we are
   * in a certain page, the TransitionRoutes are evalauted in the following
   * order: * TransitionRoutes defined in the page with intent specified. *
   * TransitionRoutes defined in the transition route groups with intent
   * specified. * TransitionRoutes defined in flow with intent specified. *
   * TransitionRoutes defined in the transition route groups with intent
   * specified. * TransitionRoutes defined in the page with only condition
   * specified. * TransitionRoutes defined in the transition route groups with
   * only condition specified.
   */
  set transitionRoutes(value:
                           Array<GoogleCloudDialogflowCxV3beta1TransitionRoute>|
                       null) {
    this.Serializable$set('transitionRoutes', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1Page> {
    return GoogleCloudDialogflowCxV3beta1Page;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'eventHandlers': GoogleCloudDialogflowCxV3beta1EventHandler,
        'transitionRoutes': GoogleCloudDialogflowCxV3beta1TransitionRoute
      },
      keys: [
        'advancedSettings', 'displayName', 'entryFulfillment', 'eventHandlers',
        'form', 'knowledgeConnectorSettings', 'name', 'speakerIdSettings',
        'transitionRouteGroups', 'transitionRoutes'
      ],
      objects: {
        'advancedSettings': GoogleCloudDialogflowCxV3beta1AdvancedSettings,
        'entryFulfillment': GoogleCloudDialogflowCxV3beta1Fulfillment,
        'form': GoogleCloudDialogflowCxV3beta1Form,
        'knowledgeConnectorSettings':
            GoogleCloudDialogflowCxV3beta1KnowledgeConnectorSettings,
        'speakerIdSettings': GoogleCloudDialogflowCxV3beta1SpeakerIdSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1PageInfoParameters {
  currentPage?: string|null;
  displayName?: string|null;
  formInfo?: GoogleCloudDialogflowCxV3beta1PageInfoFormInfo|null;
}
export class GoogleCloudDialogflowCxV3beta1PageInfo extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1PageInfoParameters = {}) {
    super();
    this.Serializable$set(
        'currentPage',
        (parameters.currentPage == null) ? (null) : (parameters.currentPage));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'formInfo',
        (parameters.formInfo == null) ? (null) : (parameters.formInfo));
  }

  get currentPage(): string|null {
    return (
        (this.Serializable$has('currentPage')) ?
            (this.Serializable$get('currentPage')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Ignored for WebhookResponse. The unique
   * identifier of the current page. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set currentPage(value: string|null) {
    this.Serializable$set('currentPage', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Ignored for WebhookResponse. The display
   * name of the current page.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get formInfo(): GoogleCloudDialogflowCxV3beta1PageInfoFormInfo|null {
    return (
        (this.Serializable$has('formInfo')) ?
            (this.Serializable$get('formInfo')) :
            (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. Information about the
   * form.
   */
  set formInfo(value: GoogleCloudDialogflowCxV3beta1PageInfoFormInfo|null) {
    this.Serializable$set('formInfo', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1PageInfo> {
    return GoogleCloudDialogflowCxV3beta1PageInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['currentPage', 'displayName', 'formInfo'],
      objects: {'formInfo': GoogleCloudDialogflowCxV3beta1PageInfoFormInfo}
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameters {
  parameterInfo?:
      Array<GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfo>|null;
}
export class GoogleCloudDialogflowCxV3beta1PageInfoFormInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameters = {}) {
    super();
    this.Serializable$set(
        'parameterInfo',
        (parameters.parameterInfo == null) ? (null) :
                                             (parameters.parameterInfo));
  }

  get parameterInfo():
      Array<GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfo>|null {
    return (
        (this.Serializable$has('parameterInfo')) ?
            (this.Serializable$get('parameterInfo')) :
            (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. The parameters
   * contained in the form. Note that the webhook cannot add or remove any form
   * parameter.
   */
  set parameterInfo(
      value: Array<GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfo>|
      null) {
    this.Serializable$set('parameterInfo', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1PageInfoFormInfo> {
    return GoogleCloudDialogflowCxV3beta1PageInfoFormInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'parameterInfo':
            GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfo
      },
      keys: ['parameterInfo']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoParameters {
  displayName?: string|null;
  required?: boolean|null;
  state?: GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState|null;
  value?: any|null;
  justCollected?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'required',
        (parameters.required == null) ? (null) : (parameters.required));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
    this.Serializable$set(
        'justCollected',
        (parameters.justCollected == null) ? (null) :
                                             (parameters.justCollected));
  }

  static get State():
      IGoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoStateEnum {
    return GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoStateEnum;
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Required for WebhookResponse. The
   * human-readable name of the parameter, unique within the form. This field
   * cannot be modified by the webhook.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get justCollected(): boolean|null {
    return (
        (this.Serializable$has('justCollected')) ?
            (this.Serializable$get('justCollected')) :
            (null));
  }

  /**
   * Optional for WebhookRequest. Ignored for WebhookResponse. Indicates if the
   * parameter value was just collected on the last conversation turn.
   */
  set justCollected(value: boolean|null) {
    this.Serializable$set('justCollected', value);
  }

  get required(): boolean|null {
    return (
        (this.Serializable$has('required')) ?
            (this.Serializable$get('required')) :
            (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. Indicates whether the
   * parameter is required. Optional parameters will not trigger prompts;
   * however, they are filled if the user specifies them. Required parameters
   * must be filled before form filling concludes.
   */
  set required(value: boolean|null) {
    this.Serializable$set('required', value);
  }

  get state(): GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState
      |null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Always present for WebhookRequest. Required for WebhookResponse. The state
   * of the parameter. This field can be set to INVALID by the webhook to
   * invalidate the parameter; other values set by the webhook will be ignored.
   */
  set state(
      value: GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoState|
      null) {
    this.Serializable$set('state', value);
  }

  get value(): any|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. The value of the
   * parameter. This field can be set by the webhook to change the parameter
   * value.
   */
  set value(value: any|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfo> {
    return GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state':
            GoogleCloudDialogflowCxV3beta1PageInfoFormInfoParameterInfoStateEnum
      },
      keys: ['displayName', 'justCollected', 'required', 'state', 'value']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ParameterDeclarationParameters {
  parameter?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1ParameterDeclaration extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ParameterDeclarationParameters = {}) {
    super();
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * The name of the session parameter. Can contain characters [A-Z], [a-z],
   * [0-9], ., -, _.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ParameterDeclaration> {
    return GoogleCloudDialogflowCxV3beta1ParameterDeclaration;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['parameter']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettingsParameters {
  allowPlaybackInterruption?: boolean|null;
  allowCancellation?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
    this.Serializable$set(
        'allowCancellation',
        (parameters.allowCancellation == null) ?
            (null) :
            (parameters.allowCancellation));
  }

  get allowCancellation(): boolean|null {
    return (
        (this.Serializable$has('allowCancellation')) ?
            (this.Serializable$get('allowCancellation')) :
            (null));
  }

  /**
   * Whether the partial response message should be cancelled when a later
   * response arrives. e.g. if the agent returns some music as partial response,
   * you may consider configuring it as cancellable.
   */
  set allowCancellation(value: boolean|null) {
    this.Serializable$set('allowCancellation', value);
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Whether interruption on the playback of a message is allowed.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings> {
    return GoogleCloudDialogflowCxV3beta1PlaybackInterruptionSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowCancellation', 'allowPlaybackInterruption']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1QueryInputParameters {
  text?: GoogleCloudDialogflowCxV3beta1TextInput|null;
  intent?: GoogleCloudDialogflowCxV3beta1IntentInput|null;
  audio?: GoogleCloudDialogflowCxV3beta1AudioInput|null;
  event?: GoogleCloudDialogflowCxV3beta1EventInput|null;
  dtmf?: GoogleCloudDialogflowCxV3beta1DtmfInput|null;
  languageCode?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1QueryInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1QueryInputParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
    this.Serializable$set(
        'dtmf', (parameters.dtmf == null) ? (null) : (parameters.dtmf));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get audio(): GoogleCloudDialogflowCxV3beta1AudioInput|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * The natural language speech audio to be processed.
   */
  set audio(value: GoogleCloudDialogflowCxV3beta1AudioInput|null) {
    this.Serializable$set('audio', value);
  }

  get dtmf(): GoogleCloudDialogflowCxV3beta1DtmfInput|null {
    return (
        (this.Serializable$has('dtmf')) ? (this.Serializable$get('dtmf')) :
                                          (null));
  }

  /**
   * The DTMF event to be handled.
   */
  set dtmf(value: GoogleCloudDialogflowCxV3beta1DtmfInput|null) {
    this.Serializable$set('dtmf', value);
  }

  get event(): GoogleCloudDialogflowCxV3beta1EventInput|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * The event to be triggered.
   */
  set event(value: GoogleCloudDialogflowCxV3beta1EventInput|null) {
    this.Serializable$set('event', value);
  }

  get intent(): GoogleCloudDialogflowCxV3beta1IntentInput|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The intent to be triggered.
   */
  set intent(value: GoogleCloudDialogflowCxV3beta1IntentInput|null) {
    this.Serializable$set('intent', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of the input. See [Language
   * Support](https://cloud.google.com/dialogflow/cx/docs/reference/language)
   * for a list of the currently supported language codes. Note that queries in
   * the same session do not necessarily need to specify the same language.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get text(): GoogleCloudDialogflowCxV3beta1TextInput|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The natural language text to be processed.
   */
  set text(value: GoogleCloudDialogflowCxV3beta1TextInput|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1QueryInput> {
    return GoogleCloudDialogflowCxV3beta1QueryInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['audio', 'dtmf', 'event', 'intent', 'languageCode', 'text'],
      objects: {
        'audio': GoogleCloudDialogflowCxV3beta1AudioInput,
        'dtmf': GoogleCloudDialogflowCxV3beta1DtmfInput,
        'event': GoogleCloudDialogflowCxV3beta1EventInput,
        'intent': GoogleCloudDialogflowCxV3beta1IntentInput,
        'text': GoogleCloudDialogflowCxV3beta1TextInput
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ReloadDocumentOperationMetadataParameters {
  genericMetadata?:
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|null;
}
export class
    GoogleCloudDialogflowCxV3beta1ReloadDocumentOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ReloadDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|
      null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ReloadDocumentOperationMetadata> {
    return GoogleCloudDialogflowCxV3beta1ReloadDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessageParameters {
  text?: GoogleCloudDialogflowCxV3beta1ResponseMessageText|null;
  payload?: ApiClientObjectMap<any>|null;
  conversationSuccess?:
      GoogleCloudDialogflowCxV3beta1ResponseMessageConversationSuccess|null;
  avayaPlayAudio?: GoogleCloudDialogflowCxV3beta1ResponseMessageAvayaPlayAudio|
      null;
  interactiveVoiceResponseSettings?:
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettings|null;
  outputAudioText?:
      GoogleCloudDialogflowCxV3beta1ResponseMessageOutputAudioText|null;
  liveAgentHandoff?:
      GoogleCloudDialogflowCxV3beta1ResponseMessageLiveAgentHandoff|null;
  endInteraction?: GoogleCloudDialogflowCxV3beta1ResponseMessageEndInteraction|
      null;
  playAudio?: GoogleCloudDialogflowCxV3beta1ResponseMessagePlayAudio|null;
  mixedAudio?: GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudio|null;
  telephonyTransferCall?:
      GoogleCloudDialogflowCxV3beta1ResponseMessageTelephonyTransferCall|null;
  platform?: GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform|null;
  responseType?: GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType|null;
  parameter?: string|null;
  source?: GoogleCloudDialogflowCxV3beta1ResponseMessageSource|null;
  channel?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1ResponseMessage extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessageParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'conversationSuccess',
        (parameters.conversationSuccess == null) ?
            (null) :
            (parameters.conversationSuccess));
    this.Serializable$set(
        'avayaPlayAudio',
        (parameters.avayaPlayAudio == null) ? (null) :
                                              (parameters.avayaPlayAudio));
    this.Serializable$set(
        'interactiveVoiceResponseSettings',
        (parameters.interactiveVoiceResponseSettings == null) ?
            (null) :
            (parameters.interactiveVoiceResponseSettings));
    this.Serializable$set(
        'outputAudioText',
        (parameters.outputAudioText == null) ? (null) :
                                               (parameters.outputAudioText));
    this.Serializable$set(
        'liveAgentHandoff',
        (parameters.liveAgentHandoff == null) ? (null) :
                                                (parameters.liveAgentHandoff));
    this.Serializable$set(
        'endInteraction',
        (parameters.endInteraction == null) ? (null) :
                                              (parameters.endInteraction));
    this.Serializable$set(
        'playAudio',
        (parameters.playAudio == null) ? (null) : (parameters.playAudio));
    this.Serializable$set(
        'mixedAudio',
        (parameters.mixedAudio == null) ? (null) : (parameters.mixedAudio));
    this.Serializable$set(
        'telephonyTransferCall',
        (parameters.telephonyTransferCall == null) ?
            (null) :
            (parameters.telephonyTransferCall));
    this.Serializable$set(
        'platform',
        (parameters.platform == null) ? (null) : (parameters.platform));
    this.Serializable$set(
        'responseType',
        (parameters.responseType == null) ? (null) : (parameters.responseType));
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'channel',
        (parameters.channel == null) ? (null) : (parameters.channel));
  }

  static get Platform():
      IGoogleCloudDialogflowCxV3beta1ResponseMessagePlatformEnum {
    return GoogleCloudDialogflowCxV3beta1ResponseMessagePlatformEnum;
  }

  static get ResponseType():
      IGoogleCloudDialogflowCxV3beta1ResponseMessageResponseTypeEnum {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageResponseTypeEnum;
  }

  static get Source():
      IGoogleCloudDialogflowCxV3beta1ResponseMessageSourceEnum {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageSourceEnum;
  }

  get avayaPlayAudio():
      GoogleCloudDialogflowCxV3beta1ResponseMessageAvayaPlayAudio|null {
    return (
        (this.Serializable$has('avayaPlayAudio')) ?
            (this.Serializable$get('avayaPlayAudio')) :
            (null));
  }

  /**
   * Plays audio from a file in Avaya Browser.
   */
  set avayaPlayAudio(
      value: GoogleCloudDialogflowCxV3beta1ResponseMessageAvayaPlayAudio|null) {
    this.Serializable$set('avayaPlayAudio', value);
  }

  get channel(): string|null {
    return (
        (this.Serializable$has('channel')) ?
            (this.Serializable$get('channel')) :
            (null));
  }

  /**
   * The channel which the response is associated with. Clients can specify the
   * channel via QueryParameters.channel, and only associated channel response
   * will be returned.
   */
  set channel(value: string|null) {
    this.Serializable$set('channel', value);
  }

  get conversationSuccess():
      GoogleCloudDialogflowCxV3beta1ResponseMessageConversationSuccess|null {
    return (
        (this.Serializable$has('conversationSuccess')) ?
            (this.Serializable$get('conversationSuccess')) :
            (null));
  }

  /**
   * Indicates that the conversation succeeded.
   */
  set conversationSuccess(
      value: GoogleCloudDialogflowCxV3beta1ResponseMessageConversationSuccess|
      null) {
    this.Serializable$set('conversationSuccess', value);
  }

  get endInteraction():
      GoogleCloudDialogflowCxV3beta1ResponseMessageEndInteraction|null {
    return (
        (this.Serializable$has('endInteraction')) ?
            (this.Serializable$get('endInteraction')) :
            (null));
  }

  /**
   * Output only. A signal that indicates the interaction with the Dialogflow
   * agent has ended. This message is generated by Dialogflow only when the
   * conversation reaches `END_SESSION` page. It is not supposed to be defined
   * by the user. It's guaranteed that there is at most one such message in each
   * response.
   */
  set endInteraction(
      value: GoogleCloudDialogflowCxV3beta1ResponseMessageEndInteraction|null) {
    this.Serializable$set('endInteraction', value);
  }

  get interactiveVoiceResponseSettings():
      GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettings|null {
    return (
        (this.Serializable$has('interactiveVoiceResponseSettings')) ?
            (this.Serializable$get('interactiveVoiceResponseSettings')) :
            (null));
  }

  /**
   * Returns the current values for the IVR (interactive voice response)
   * settings. Even though the operations configured by these settings are
   * performed by Dialogflow, the client may need to perform special logic at
   * the moment. For example, if Dialogflow exports audio to Google Cloud
   * Storage, then the client may need to wait for the resulting object to
   * appear in the bucket before proceeding.
   */
  set interactiveVoiceResponseSettings(
      value: GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettings|
      null) {
    this.Serializable$set('interactiveVoiceResponseSettings', value);
  }

  get liveAgentHandoff():
      GoogleCloudDialogflowCxV3beta1ResponseMessageLiveAgentHandoff|null {
    return (
        (this.Serializable$has('liveAgentHandoff')) ?
            (this.Serializable$get('liveAgentHandoff')) :
            (null));
  }

  /**
   * Hands off conversation to a human agent.
   */
  set liveAgentHandoff(
      value: GoogleCloudDialogflowCxV3beta1ResponseMessageLiveAgentHandoff|
      null) {
    this.Serializable$set('liveAgentHandoff', value);
  }

  get mixedAudio(): GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudio
      |null {
    return (
        (this.Serializable$has('mixedAudio')) ?
            (this.Serializable$get('mixedAudio')) :
            (null));
  }

  /**
   * Output only. An audio response message composed of both the synthesized
   * Dialogflow agent responses and responses defined via play_audio. This
   * message is generated by Dialogflow only and not supposed to be defined by
   * the user.
   */
  set mixedAudio(value: GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudio|
                 null) {
    this.Serializable$set('mixedAudio', value);
  }

  get outputAudioText():
      GoogleCloudDialogflowCxV3beta1ResponseMessageOutputAudioText|null {
    return (
        (this.Serializable$has('outputAudioText')) ?
            (this.Serializable$get('outputAudioText')) :
            (null));
  }

  /**
   * A text or ssml response that is preferentially used for TTS output audio
   * synthesis, as described in the comment on the ResponseMessage message.
   */
  set outputAudioText(
      value: GoogleCloudDialogflowCxV3beta1ResponseMessageOutputAudioText|
      null) {
    this.Serializable$set('outputAudioText', value);
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * Display name of the associated form parameter. Only populated for
   * `PARAMETER_PROMPT`.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Returns a response containing a custom, platform-specific payload.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get platform(): GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform|null {
    return (
        (this.Serializable$has('platform')) ?
            (this.Serializable$get('platform')) :
            (null));
  }

  /**
   * The platform that the response message is intended for.
   */
  set platform(value: GoogleCloudDialogflowCxV3beta1ResponseMessagePlatform|
               null) {
    this.Serializable$set('platform', value);
  }

  get playAudio(): GoogleCloudDialogflowCxV3beta1ResponseMessagePlayAudio|null {
    return (
        (this.Serializable$has('playAudio')) ?
            (this.Serializable$get('playAudio')) :
            (null));
  }

  /**
   * Signal that the client should play an audio clip hosted at a
   * client-specific URI. Dialogflow uses this to construct mixed_audio.
   * However, Dialogflow itself does not try to read or process the URI in any
   * way.
   */
  set playAudio(value: GoogleCloudDialogflowCxV3beta1ResponseMessagePlayAudio|
                null) {
    this.Serializable$set('playAudio', value);
  }

  get responseType(): GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType
      |null {
    return (
        (this.Serializable$has('responseType')) ?
            (this.Serializable$get('responseType')) :
            (null));
  }

  /**
   * Response type.
   */
  set responseType(
      value: GoogleCloudDialogflowCxV3beta1ResponseMessageResponseType|null) {
    this.Serializable$set('responseType', value);
  }

  get source(): GoogleCloudDialogflowCxV3beta1ResponseMessageSource|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * The source by which this response was generated.
   */
  set source(value: GoogleCloudDialogflowCxV3beta1ResponseMessageSource|null) {
    this.Serializable$set('source', value);
  }

  get telephonyTransferCall():
      GoogleCloudDialogflowCxV3beta1ResponseMessageTelephonyTransferCall|null {
    return (
        (this.Serializable$has('telephonyTransferCall')) ?
            (this.Serializable$get('telephonyTransferCall')) :
            (null));
  }

  /**
   * A signal that the client should transfer the phone call connected to this
   * agent to a third-party endpoint.
   */
  set telephonyTransferCall(
      value: GoogleCloudDialogflowCxV3beta1ResponseMessageTelephonyTransferCall|
      null) {
    this.Serializable$set('telephonyTransferCall', value);
  }

  get text(): GoogleCloudDialogflowCxV3beta1ResponseMessageText|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Returns a text response.
   */
  set text(value: GoogleCloudDialogflowCxV3beta1ResponseMessageText|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ResponseMessage> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessage;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'platform': GoogleCloudDialogflowCxV3beta1ResponseMessagePlatformEnum,
        'responseType':
            GoogleCloudDialogflowCxV3beta1ResponseMessageResponseTypeEnum,
        'source': GoogleCloudDialogflowCxV3beta1ResponseMessageSourceEnum
      },
      keys: [
        'avayaPlayAudio', 'channel', 'conversationSuccess', 'endInteraction',
        'interactiveVoiceResponseSettings', 'liveAgentHandoff', 'mixedAudio',
        'outputAudioText', 'parameter', 'payload', 'platform', 'playAudio',
        'responseType', 'source', 'telephonyTransferCall', 'text'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'avayaPlayAudio':
            GoogleCloudDialogflowCxV3beta1ResponseMessageAvayaPlayAudio,
        'conversationSuccess':
            GoogleCloudDialogflowCxV3beta1ResponseMessageConversationSuccess,
        'endInteraction':
            GoogleCloudDialogflowCxV3beta1ResponseMessageEndInteraction,
        'interactiveVoiceResponseSettings':
            GoogleCloudDialogflowCxV3beta1InteractiveVoiceResponseSettings,
        'liveAgentHandoff':
            GoogleCloudDialogflowCxV3beta1ResponseMessageLiveAgentHandoff,
        'mixedAudio': GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudio,
        'outputAudioText':
            GoogleCloudDialogflowCxV3beta1ResponseMessageOutputAudioText,
        'playAudio': GoogleCloudDialogflowCxV3beta1ResponseMessagePlayAudio,
        'telephonyTransferCall':
            GoogleCloudDialogflowCxV3beta1ResponseMessageTelephonyTransferCall,
        'text': GoogleCloudDialogflowCxV3beta1ResponseMessageText
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessageAvayaPlayAudioParameters {
  audioUri?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1ResponseMessageAvayaPlayAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessageAvayaPlayAudioParameters =
              {}) {
    super();
    this.Serializable$set(
        'audioUri',
        (parameters.audioUri == null) ? (null) : (parameters.audioUri));
  }

  get audioUri(): string|null {
    return (
        (this.Serializable$has('audioUri')) ?
            (this.Serializable$get('audioUri')) :
            (null));
  }

  /**
   * Required. Audio URI for Avaya Browser to play the audio.
   */
  set audioUri(value: string|null) {
    this.Serializable$set('audioUri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ResponseMessageAvayaPlayAudio> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageAvayaPlayAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audioUri']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessageConversationSuccessParameters {
  metadata?: ApiClientObjectMap<any>|null;
}
export class
    GoogleCloudDialogflowCxV3beta1ResponseMessageConversationSuccess extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessageConversationSuccessParameters =
              {}) {
    super();
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
  }

  get metadata(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Custom metadata. Dialogflow doesn't impose any structure on this.
   */
  set metadata(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('metadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ResponseMessageConversationSuccess> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageConversationSuccess;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['metadata'],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessageEndInteractionParameters {
}
export class GoogleCloudDialogflowCxV3beta1ResponseMessageEndInteraction extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessageEndInteractionParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ResponseMessageEndInteraction> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageEndInteraction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessageLiveAgentHandoffParameters {
  metadata?: ApiClientObjectMap<any>|null;
}
export class
    GoogleCloudDialogflowCxV3beta1ResponseMessageLiveAgentHandoff extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessageLiveAgentHandoffParameters =
              {}) {
    super();
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
  }

  get metadata(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Custom metadata for your handoff procedure. Dialogflow doesn't impose any
   * structure on this.
   */
  set metadata(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('metadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ResponseMessageLiveAgentHandoff> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageLiveAgentHandoff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['metadata'],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioParameters {
  segments?:
      Array<GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioSegment>|
      null;
}
export class GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioParameters =
              {}) {
    super();
    this.Serializable$set(
        'segments',
        (parameters.segments == null) ? (null) : (parameters.segments));
  }

  get segments():
      Array<GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioSegment>|
      null {
    return (
        (this.Serializable$has('segments')) ?
            (this.Serializable$get('segments')) :
            (null));
  }

  /**
   * Segments this audio response is composed of.
   */
  set segments(
      value:
          Array<GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioSegment>|
      null) {
    this.Serializable$set('segments', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudio> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'segments':
            GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioSegment
      },
      keys: ['segments']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioSegmentParameters {
  audio?: string|null;
  uri?: string|null;
  allowPlaybackInterruption?: boolean|null;
}
export class
    GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioSegment extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioSegmentParameters =
              {}) {
    super();
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this segment can be interrupted by the
   * end user's speech and the client should then start the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get audio(): string|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * Raw audio synthesized from the Dialogflow agent's response using the output
   * config specified in the request.
   */
  set audio(value: string|null) {
    this.Serializable$set('audio', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Client-specific URI that points to an audio clip accessible to the client.
   * Dialogflow does not impose any validation on it.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioSegment> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageMixedAudioSegment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'audio', 'uri']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessageOutputAudioTextParameters {
  text?: string|null;
  ssml?: string|null;
  allowPlaybackInterruption?: boolean|null;
}
export class
    GoogleCloudDialogflowCxV3beta1ResponseMessageOutputAudioText extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessageOutputAudioTextParameters =
              {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'ssml', (parameters.ssml == null) ? (null) : (parameters.ssml));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this message can be interrupted by the
   * end user's speech and the client can then starts the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get ssml(): string|null {
    return (
        (this.Serializable$has('ssml')) ? (this.Serializable$get('ssml')) :
                                          (null));
  }

  /**
   * The SSML text to be synthesized. For more information, see
   * [SSML](/speech/text-to-speech/docs/ssml).
   */
  set ssml(value: string|null) {
    this.Serializable$set('ssml', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The raw text to be synthesized.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ResponseMessageOutputAudioText> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageOutputAudioText;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'ssml', 'text']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessagePlayAudioParameters {
  audioUri?: string|null;
  allowPlaybackInterruption?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1ResponseMessagePlayAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessagePlayAudioParameters =
              {}) {
    super();
    this.Serializable$set(
        'audioUri',
        (parameters.audioUri == null) ? (null) : (parameters.audioUri));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this message can be interrupted by the
   * end user's speech and the client can then starts the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get audioUri(): string|null {
    return (
        (this.Serializable$has('audioUri')) ?
            (this.Serializable$get('audioUri')) :
            (null));
  }

  /**
   * Required. URI of the audio clip. Dialogflow does not impose any validation
   * on this value. It is specific to the client that reads it.
   */
  set audioUri(value: string|null) {
    this.Serializable$set('audioUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ResponseMessagePlayAudio> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessagePlayAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'audioUri']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessageTelephonyTransferCallParameters {
  phoneNumber?: string|null;
  sipUri?: string|null;
}
export class
    GoogleCloudDialogflowCxV3beta1ResponseMessageTelephonyTransferCall extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessageTelephonyTransferCallParameters =
              {}) {
    super();
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
    this.Serializable$set(
        'sipUri', (parameters.sipUri == null) ? (null) : (parameters.sipUri));
  }

  get phoneNumber(): string|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Transfer the call to a phone number in [E.164
   * format](https://en.wikipedia.org/wiki/E.164).
   */
  set phoneNumber(value: string|null) {
    this.Serializable$set('phoneNumber', value);
  }

  get sipUri(): string|null {
    return (
        (this.Serializable$has('sipUri')) ? (this.Serializable$get('sipUri')) :
                                            (null));
  }

  /**
   * Transfer the call to a SIP endpoint.
   */
  set sipUri(value: string|null) {
    this.Serializable$set('sipUri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1ResponseMessageTelephonyTransferCall> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageTelephonyTransferCall;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['phoneNumber', 'sipUri']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1ResponseMessageTextParameters {
  text?: Array<string>|null;
  allowPlaybackInterruption?: boolean|null;
  redactedText?: Array<string>|null;
}
export class GoogleCloudDialogflowCxV3beta1ResponseMessageText extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1ResponseMessageTextParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
    this.Serializable$set(
        'redactedText',
        (parameters.redactedText == null) ? (null) : (parameters.redactedText));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this message can be interrupted by the
   * end user's speech and the client can then starts the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get redactedText(): Array<string>|null {
    return (
        (this.Serializable$has('redactedText')) ?
            (this.Serializable$get('redactedText')) :
            (null));
  }

  /**
   * A collection of parameter redacted text response. This field contains the
   * same text as Text.text but the parameters will be replaced with
   * $parameter_name_redacted. The Text.redacted_text will be the same as
   * Text.text if no parameters or no parameters to be redacted in text. This is
   * an internal field and will only be used to replace [Text.text] for logging.
   * For example: { \"text\": [ \"Hello\", \"My SSN is 0000\", ],
   * \"redacted_text\": [ \"Hello\", \"My SSN is $ssn_redacted\", ] }
   */
  set redactedText(value: Array<string>|null) {
    this.Serializable$set('redactedText', value);
  }

  get text(): Array<string>|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. A collection of text responses.
   */
  set text(value: Array<string>|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1ResponseMessageText> {
    return GoogleCloudDialogflowCxV3beta1ResponseMessageText;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'redactedText', 'text']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1RunContinuousTestMetadataParameters {
  errors?: Array<GoogleCloudDialogflowCxV3beta1TestError>|null;
}
export class GoogleCloudDialogflowCxV3beta1RunContinuousTestMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1RunContinuousTestMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'errors', (parameters.errors == null) ? (null) : (parameters.errors));
  }

  get errors(): Array<GoogleCloudDialogflowCxV3beta1TestError>|null {
    return (
        (this.Serializable$has('errors')) ? (this.Serializable$get('errors')) :
                                            (null));
  }

  /**
   * The test errors.
   */
  set errors(value: Array<GoogleCloudDialogflowCxV3beta1TestError>|null) {
    this.Serializable$set('errors', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1RunContinuousTestMetadata> {
    return GoogleCloudDialogflowCxV3beta1RunContinuousTestMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'errors': GoogleCloudDialogflowCxV3beta1TestError},
      keys: ['errors']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1RunContinuousTestResponseParameters {
  continuousTestResult?: GoogleCloudDialogflowCxV3beta1ContinuousTestResult|
      null;
}
export class GoogleCloudDialogflowCxV3beta1RunContinuousTestResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1RunContinuousTestResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'continuousTestResult',
        (parameters.continuousTestResult == null) ?
            (null) :
            (parameters.continuousTestResult));
  }

  get continuousTestResult(): GoogleCloudDialogflowCxV3beta1ContinuousTestResult
      |null {
    return (
        (this.Serializable$has('continuousTestResult')) ?
            (this.Serializable$get('continuousTestResult')) :
            (null));
  }

  /**
   * The result for a continuous test run.
   */
  set continuousTestResult(
      value: GoogleCloudDialogflowCxV3beta1ContinuousTestResult|null) {
    this.Serializable$set('continuousTestResult', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1RunContinuousTestResponse> {
    return GoogleCloudDialogflowCxV3beta1RunContinuousTestResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['continuousTestResult'],
      objects: {
        'continuousTestResult':
            GoogleCloudDialogflowCxV3beta1ContinuousTestResult
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1RunTestCaseMetadataParameters {}
export class GoogleCloudDialogflowCxV3beta1RunTestCaseMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1RunTestCaseMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1RunTestCaseMetadata> {
    return GoogleCloudDialogflowCxV3beta1RunTestCaseMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowCxV3beta1RunTestCaseResponseParameters {
  result?: GoogleCloudDialogflowCxV3beta1TestCaseResult|null;
}
export class GoogleCloudDialogflowCxV3beta1RunTestCaseResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1RunTestCaseResponseParameters = {}) {
    super();
    this.Serializable$set(
        'result', (parameters.result == null) ? (null) : (parameters.result));
  }

  get result(): GoogleCloudDialogflowCxV3beta1TestCaseResult|null {
    return (
        (this.Serializable$has('result')) ? (this.Serializable$get('result')) :
                                            (null));
  }

  /**
   * The result.
   */
  set result(value: GoogleCloudDialogflowCxV3beta1TestCaseResult|null) {
    this.Serializable$set('result', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1RunTestCaseResponse> {
    return GoogleCloudDialogflowCxV3beta1RunTestCaseResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['result'],
      objects: {'result': GoogleCloudDialogflowCxV3beta1TestCaseResult}
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1SessionInfoParameters {
  session?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
}
export class GoogleCloudDialogflowCxV3beta1SessionInfo extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1SessionInfoParameters = {}) {
    super();
    this.Serializable$set(
        'session',
        (parameters.session == null) ? (null) : (parameters.session));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional for WebhookRequest. Optional for WebhookResponse. All parameters
   * collected from forms and intents during the session. Parameters can be
   * created, updated, or removed by the webhook. To remove a parameter from the
   * session, the webhook should explicitly set the parameter value to null in
   * WebhookResponse. The map is keyed by parameters' display names.
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  get session(): string|null {
    return (
        (this.Serializable$has('session')) ?
            (this.Serializable$get('session')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Ignored for WebhookResponse. The unique
   * identifier of the session. This field can be used by the webhook to
   * identify a session. Format: `projects//locations//agents//sessions/` or
   * `projects//locations//agents//environments//sessions/` if environment is
   * specified.
   */
  set session(value: string|null) {
    this.Serializable$set('session', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1SessionInfo> {
    return GoogleCloudDialogflowCxV3beta1SessionInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['parameters', 'session'],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsParameters {
  speakerIdPageType?:
      GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType|null;
  phrases?: Array<GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsPhrases>|null;
  disablePassiveVerification?: boolean|null;
  disableAdaptation?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1SpeakerIdSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsParameters = {}) {
    super();
    this.Serializable$set(
        'speakerIdPageType',
        (parameters.speakerIdPageType == null) ?
            (null) :
            (parameters.speakerIdPageType));
    this.Serializable$set(
        'phrases',
        (parameters.phrases == null) ? (null) : (parameters.phrases));
    this.Serializable$set(
        'disablePassiveVerification',
        (parameters.disablePassiveVerification == null) ?
            (null) :
            (parameters.disablePassiveVerification));
    this.Serializable$set(
        'disableAdaptation',
        (parameters.disableAdaptation == null) ?
            (null) :
            (parameters.disableAdaptation));
  }

  static get SpeakerIdPageType():
      IGoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageTypeEnum {
    return GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageTypeEnum;
  }

  get disableAdaptation(): boolean|null {
    return (
        (this.Serializable$has('disableAdaptation')) ?
            (this.Serializable$get('disableAdaptation')) :
            (null));
  }

  /**
   * Whether the audio for Speaker ID verification may be stored for model
   * adaptation. If not set or set to false, and audio for verification is a
   * strong match to the existing speaker profile for the matching speaker, the
   * audio may be stored in order to further improve the speaker profile. If set
   * to true, the audio in the request will not be stored. Only set for pages
   * that are of type SPEAKER_ID_PAGE_TYPE_VERIFICATION or do not disable
   * passive verification.
   */
  set disableAdaptation(value: boolean|null) {
    this.Serializable$set('disableAdaptation', value);
  }

  get disablePassiveVerification(): boolean|null {
    return (
        (this.Serializable$has('disablePassiveVerification')) ?
            (this.Serializable$get('disablePassiveVerification')) :
            (null));
  }

  /**
   * Whether to disable passive verification for conversations on this page.
   * Only set for pages of type SPEAKER_ID_PAGE_TYPE_UNSPECIFIED.
   */
  set disablePassiveVerification(value: boolean|null) {
    this.Serializable$set('disablePassiveVerification', value);
  }

  get phrases():
      Array<GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsPhrases>|null {
    return (
        (this.Serializable$has('phrases')) ?
            (this.Serializable$get('phrases')) :
            (null));
  }

  /**
   * Phrases for Speaker ID enrollment and verification.
   */
  set phrases(value:
                  Array<GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsPhrases>|
              null) {
    this.Serializable$set('phrases', value);
  }

  get speakerIdPageType():
      GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType|null {
    return (
        (this.Serializable$has('speakerIdPageType')) ?
            (this.Serializable$get('speakerIdPageType')) :
            (null));
  }

  /**
   * Type of the Speaker ID-related page.
   */
  set speakerIdPageType(
      value: GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageType|
      null) {
    this.Serializable$set('speakerIdPageType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1SpeakerIdSettings> {
    return GoogleCloudDialogflowCxV3beta1SpeakerIdSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'phrases': GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsPhrases},
      enums: {
        'speakerIdPageType':
            GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsSpeakerIdPageTypeEnum
      },
      keys: [
        'disableAdaptation', 'disablePassiveVerification', 'phrases',
        'speakerIdPageType'
      ]
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsPhrasesParameters {
  enrollmentPhrases?: Array<string>|null;
  verificationPhrases?: Array<string>|null;
  languageCode?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsPhrases extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsPhrasesParameters =
              {}) {
    super();
    this.Serializable$set(
        'enrollmentPhrases',
        (parameters.enrollmentPhrases == null) ?
            (null) :
            (parameters.enrollmentPhrases));
    this.Serializable$set(
        'verificationPhrases',
        (parameters.verificationPhrases == null) ?
            (null) :
            (parameters.verificationPhrases));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get enrollmentPhrases(): Array<string>|null {
    return (
        (this.Serializable$has('enrollmentPhrases')) ?
            (this.Serializable$get('enrollmentPhrases')) :
            (null));
  }

  /**
   * Phrases that the user need to repeat for Speaker ID enrollment. For each
   * round of phrase enrollment, Dialogflow would choose one phrase from the
   * list by random. If unspecified or if all provided phrases have already been
   * used in a single session, Dialogflow would generate phrases automatically.
   */
  set enrollmentPhrases(value: Array<string>|null) {
    this.Serializable$set('enrollmentPhrases', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language of the phrases. In normal cases, we don't need to populate
   * `language_code` as only phrases of the requested language are returned.
   * However we need to store the phrases of all languages for YAML exported
   * agent.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get verificationPhrases(): Array<string>|null {
    return (
        (this.Serializable$has('verificationPhrases')) ?
            (this.Serializable$get('verificationPhrases')) :
            (null));
  }

  /**
   * Phrases that users need to repeat for Speaker ID verification. During
   * verification, Dialogflow would choose one phrase from the list by random.
   * If unspecified, Dialogflow would generate a phrase automatically.
   */
  set verificationPhrases(value: Array<string>|null) {
    this.Serializable$set('verificationPhrases', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsPhrases> {
    return GoogleCloudDialogflowCxV3beta1SpeakerIdSettingsPhrases;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enrollmentPhrases', 'languageCode', 'verificationPhrases']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1SpeechContextParameters {
  phrases?: Array<string>|null;
  boost?: number|null;
}
export class GoogleCloudDialogflowCxV3beta1SpeechContext extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1SpeechContextParameters = {}) {
    super();
    this.Serializable$set(
        'phrases',
        (parameters.phrases == null) ? (null) : (parameters.phrases));
    this.Serializable$set(
        'boost', (parameters.boost == null) ? (null) : (parameters.boost));
  }

  get boost(): number|null {
    return (
        (this.Serializable$has('boost')) ? (this.Serializable$get('boost')) :
                                           (null));
  }

  /**
   * Optional. Boost for this context compared to other contexts: * If the boost
   * is positive, Dialogflow will increase the probability that the phrases in
   * this context are recognized over similar sounding phrases. * If the boost
   * is unspecified or non-positive, Dialogflow will not apply any boost.
   * Dialogflow recommends that you use boosts in the range (0, 20] and that you
   * find a value that fits your use case with binary search.
   */
  set boost(value: number|null) {
    this.Serializable$set('boost', value);
  }

  get phrases(): Array<string>|null {
    return (
        (this.Serializable$has('phrases')) ?
            (this.Serializable$get('phrases')) :
            (null));
  }

  /**
   * Optional. A list of strings containing words and phrases that the speech
   * recognizer should recognize with higher likelihood. This list can be used
   * to: * improve accuracy for words and phrases you expect the user to say,
   * e.g. typical commands for your Dialogflow agent * add additional words to
   * the speech recognizer vocabulary * ... See the [Cloud Speech
   * documentation](https://cloud.google.com/speech-to-text/quotas) for usage
   * limits.
   */
  set phrases(value: Array<string>|null) {
    this.Serializable$set('phrases', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1SpeechContext> {
    return GoogleCloudDialogflowCxV3beta1SpeechContext;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['boost', 'phrases']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1TestCaseParameters {
  name?: string|null;
  tags?: Array<string>|null;
  displayName?: string|null;
  notes?: string|null;
  testConfig?: GoogleCloudDialogflowCxV3beta1TestConfig|null;
  testCaseConversationTurns?:
      Array<GoogleCloudDialogflowCxV3beta1ConversationTurn>|null;
  ignore?: boolean|null;
  creationTime?: string|null;
  lastTestResult?: GoogleCloudDialogflowCxV3beta1TestCaseResult|null;
}
export class GoogleCloudDialogflowCxV3beta1TestCase extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1TestCaseParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'tags', (parameters.tags == null) ? (null) : (parameters.tags));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'notes', (parameters.notes == null) ? (null) : (parameters.notes));
    this.Serializable$set(
        'testConfig',
        (parameters.testConfig == null) ? (null) : (parameters.testConfig));
    this.Serializable$set(
        'testCaseConversationTurns',
        (parameters.testCaseConversationTurns == null) ?
            (null) :
            (parameters.testCaseConversationTurns));
    this.Serializable$set(
        'ignore', (parameters.ignore == null) ? (null) : (parameters.ignore));
    this.Serializable$set(
        'creationTime',
        (parameters.creationTime == null) ? (null) : (parameters.creationTime));
    this.Serializable$set(
        'lastTestResult',
        (parameters.lastTestResult == null) ? (null) :
                                              (parameters.lastTestResult));
  }

  get creationTime(): string|null {
    return (
        (this.Serializable$has('creationTime')) ?
            (this.Serializable$get('creationTime')) :
            (null));
  }

  /**
   * Output only. When the test was created.
   */
  set creationTime(value: string|null) {
    this.Serializable$set('creationTime', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the test case, unique within the
   * agent. Limit of 200 characters.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get ignore(): boolean|null {
    return (
        (this.Serializable$has('ignore')) ? (this.Serializable$get('ignore')) :
                                            (null));
  }

  /**
   * Default is false. If set true, the test case will be excluded from run
   * requests. This flag is used for the console UI.
   */
  set ignore(value: boolean|null) {
    this.Serializable$set('ignore', value);
  }

  get lastTestResult(): GoogleCloudDialogflowCxV3beta1TestCaseResult|null {
    return (
        (this.Serializable$has('lastTestResult')) ?
            (this.Serializable$get('lastTestResult')) :
            (null));
  }

  /**
   * The latest test result.
   */
  set lastTestResult(value: GoogleCloudDialogflowCxV3beta1TestCaseResult|null) {
    this.Serializable$set('lastTestResult', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the test case. TestCases.CreateTestCase will
   * populate the name automatically. Otherwise use format:
   * `projects//locations//agents/ /testCases/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get notes(): string|null {
    return (
        (this.Serializable$has('notes')) ? (this.Serializable$get('notes')) :
                                           (null));
  }

  /**
   * Additional freeform notes about the test case. Limit of 400 characters.
   */
  set notes(value: string|null) {
    this.Serializable$set('notes', value);
  }

  get tags(): Array<string>|null {
    return (
        (this.Serializable$has('tags')) ? (this.Serializable$get('tags')) :
                                          (null));
  }

  /**
   * Tags are short descriptions that users may apply to test cases for
   * organizational and filtering purposes. Each tag should start with \"#\" and
   * has a limit of 30 characters.
   */
  set tags(value: Array<string>|null) {
    this.Serializable$set('tags', value);
  }

  get testCaseConversationTurns():
      Array<GoogleCloudDialogflowCxV3beta1ConversationTurn>|null {
    return (
        (this.Serializable$has('testCaseConversationTurns')) ?
            (this.Serializable$get('testCaseConversationTurns')) :
            (null));
  }

  /**
   * The conversation turns uttered when the test case was created, in
   * chronological order. These include the canonical set of agent utterances
   * that should occur when the agent is working properly.
   */
  set testCaseConversationTurns(
      value: Array<GoogleCloudDialogflowCxV3beta1ConversationTurn>|null) {
    this.Serializable$set('testCaseConversationTurns', value);
  }

  get testConfig(): GoogleCloudDialogflowCxV3beta1TestConfig|null {
    return (
        (this.Serializable$has('testConfig')) ?
            (this.Serializable$get('testConfig')) :
            (null));
  }

  /**
   * Config for the test case.
   */
  set testConfig(value: GoogleCloudDialogflowCxV3beta1TestConfig|null) {
    this.Serializable$set('testConfig', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1TestCase> {
    return GoogleCloudDialogflowCxV3beta1TestCase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'testCaseConversationTurns':
            GoogleCloudDialogflowCxV3beta1ConversationTurn
      },
      keys: [
        'creationTime', 'displayName', 'ignore', 'lastTestResult', 'name',
        'notes', 'tags', 'testCaseConversationTurns', 'testConfig'
      ],
      objects: {
        'lastTestResult': GoogleCloudDialogflowCxV3beta1TestCaseResult,
        'testConfig': GoogleCloudDialogflowCxV3beta1TestConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1TestCaseErrorParameters {
  testCase?: GoogleCloudDialogflowCxV3beta1TestCase|null;
  status?: GoogleRpcStatus|null;
}
export class GoogleCloudDialogflowCxV3beta1TestCaseError extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1TestCaseErrorParameters = {}) {
    super();
    this.Serializable$set(
        'testCase',
        (parameters.testCase == null) ? (null) : (parameters.testCase));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * The status associated with the test case.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  get testCase(): GoogleCloudDialogflowCxV3beta1TestCase|null {
    return (
        (this.Serializable$has('testCase')) ?
            (this.Serializable$get('testCase')) :
            (null));
  }

  /**
   * The test case.
   */
  set testCase(value: GoogleCloudDialogflowCxV3beta1TestCase|null) {
    this.Serializable$set('testCase', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1TestCaseError> {
    return GoogleCloudDialogflowCxV3beta1TestCaseError;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['status', 'testCase'],
      objects: {
        'status': GoogleRpcStatus,
        'testCase': GoogleCloudDialogflowCxV3beta1TestCase
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1TestCaseResultParameters {
  name?: string|null;
  environment?: string|null;
  conversationTurns?: Array<GoogleCloudDialogflowCxV3beta1ConversationTurn>|
      null;
  testResult?: GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult|null;
  testTime?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1TestCaseResult extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1TestCaseResultParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
    this.Serializable$set(
        'conversationTurns',
        (parameters.conversationTurns == null) ?
            (null) :
            (parameters.conversationTurns));
    this.Serializable$set(
        'testResult',
        (parameters.testResult == null) ? (null) : (parameters.testResult));
    this.Serializable$set(
        'testTime',
        (parameters.testTime == null) ? (null) : (parameters.testTime));
  }

  static get TestResult():
      IGoogleCloudDialogflowCxV3beta1TestCaseResultTestResultEnum {
    return GoogleCloudDialogflowCxV3beta1TestCaseResultTestResultEnum;
  }

  get conversationTurns():
      Array<GoogleCloudDialogflowCxV3beta1ConversationTurn>|null {
    return (
        (this.Serializable$has('conversationTurns')) ?
            (this.Serializable$get('conversationTurns')) :
            (null));
  }

  /**
   * The conversation turns uttered during the test case replay in chronological
   * order.
   */
  set conversationTurns(
      value: Array<GoogleCloudDialogflowCxV3beta1ConversationTurn>|null) {
    this.Serializable$set('conversationTurns', value);
  }

  get environment(): string|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * Environment where the test was run. If not set, it indicates the draft
   * environment.
   */
  set environment(value: string|null) {
    this.Serializable$set('environment', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The resource name for the test case result. Format:
   * `projects//locations//agents//testCases/ /results/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get testResult(): GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult
      |null {
    return (
        (this.Serializable$has('testResult')) ?
            (this.Serializable$get('testResult')) :
            (null));
  }

  /**
   * Whether the test case passed in the agent environment.
   */
  set testResult(value: GoogleCloudDialogflowCxV3beta1TestCaseResultTestResult|
                 null) {
    this.Serializable$set('testResult', value);
  }

  get testTime(): string|null {
    return (
        (this.Serializable$has('testTime')) ?
            (this.Serializable$get('testTime')) :
            (null));
  }

  /**
   * The time that the test was run.
   */
  set testTime(value: string|null) {
    this.Serializable$set('testTime', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1TestCaseResult> {
    return GoogleCloudDialogflowCxV3beta1TestCaseResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'conversationTurns': GoogleCloudDialogflowCxV3beta1ConversationTurn},
      enums: {
        'testResult': GoogleCloudDialogflowCxV3beta1TestCaseResultTestResultEnum
      },
      keys:
          ['conversationTurns', 'environment', 'name', 'testResult', 'testTime']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1TestConfigParameters {
  trackingParameters?: Array<string>|null;
  flow?: string|null;
  page?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1TestConfig extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1TestConfigParameters = {}) {
    super();
    this.Serializable$set(
        'trackingParameters',
        (parameters.trackingParameters == null) ?
            (null) :
            (parameters.trackingParameters));
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
    this.Serializable$set(
        'page', (parameters.page == null) ? (null) : (parameters.page));
  }

  get flow(): string|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * Flow name to start the test case with. Format:
   * `projects//locations//agents//flows/`. Only one of `flow` and `page` should
   * be set to indicate the starting point of the test case. If both are set,
   * `page` takes precedence over `flow`. If neither is set, the test case will
   * start with start page on the default start flow.
   */
  set flow(value: string|null) {
    this.Serializable$set('flow', value);
  }

  get page(): string|null {
    return (
        (this.Serializable$has('page')) ? (this.Serializable$get('page')) :
                                          (null));
  }

  /**
   * The page to start the test case with. Format:
   * `projects//locations//agents//flows//pages/`. Only one of `flow` and `page`
   * should be set to indicate the starting point of the test case. If both are
   * set, `page` takes precedence over `flow`. If neither is set, the test case
   * will start with start page on the default start flow.
   */
  set page(value: string|null) {
    this.Serializable$set('page', value);
  }

  get trackingParameters(): Array<string>|null {
    return (
        (this.Serializable$has('trackingParameters')) ?
            (this.Serializable$get('trackingParameters')) :
            (null));
  }

  /**
   * Session parameters to be compared when calculating differences.
   */
  set trackingParameters(value: Array<string>|null) {
    this.Serializable$set('trackingParameters', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1TestConfig> {
    return GoogleCloudDialogflowCxV3beta1TestConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flow', 'page', 'trackingParameters']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1TestErrorParameters {
  testCase?: string|null;
  status?: GoogleRpcStatus|null;
  testTime?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1TestError extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1TestErrorParameters = {}) {
    super();
    this.Serializable$set(
        'testCase',
        (parameters.testCase == null) ? (null) : (parameters.testCase));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
    this.Serializable$set(
        'testTime',
        (parameters.testTime == null) ? (null) : (parameters.testTime));
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * The status associated with the test.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  get testCase(): string|null {
    return (
        (this.Serializable$has('testCase')) ?
            (this.Serializable$get('testCase')) :
            (null));
  }

  /**
   * The test case resource name.
   */
  set testCase(value: string|null) {
    this.Serializable$set('testCase', value);
  }

  get testTime(): string|null {
    return (
        (this.Serializable$has('testTime')) ?
            (this.Serializable$get('testTime')) :
            (null));
  }

  /**
   * The timestamp when the test was completed.
   */
  set testTime(value: string|null) {
    this.Serializable$set('testTime', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1TestError> {
    return GoogleCloudDialogflowCxV3beta1TestError;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['status', 'testCase', 'testTime'],
      objects: {'status': GoogleRpcStatus}
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1TestRunDifferenceParameters {
  type?: GoogleCloudDialogflowCxV3beta1TestRunDifferenceType|null;
  description?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1TestRunDifference extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1TestRunDifferenceParameters = {}) {
    super();
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
  }

  static get Type(): IGoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum {
    return GoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum;
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * A description of the diff, showing the actual output vs expected output.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get type(): GoogleCloudDialogflowCxV3beta1TestRunDifferenceType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * The type of diff.
   */
  set type(value: GoogleCloudDialogflowCxV3beta1TestRunDifferenceType|null) {
    this.Serializable$set('type', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1TestRunDifference> {
    return GoogleCloudDialogflowCxV3beta1TestRunDifference;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'type': GoogleCloudDialogflowCxV3beta1TestRunDifferenceTypeEnum},
      keys: ['description', 'type']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1TextInputParameters {
  text?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1TextInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1TextInputParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. The UTF-8 encoded natural language text to be processed. Text
   * length must not exceed 256 characters.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1TextInput> {
    return GoogleCloudDialogflowCxV3beta1TextInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['text']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1TransitionRouteParameters {
  name?: string|null;
  intent?: string|null;
  condition?: string|null;
  triggerFulfillment?: GoogleCloudDialogflowCxV3beta1Fulfillment|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1TransitionRoute extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1TransitionRouteParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'triggerFulfillment',
        (parameters.triggerFulfillment == null) ?
            (null) :
            (parameters.triggerFulfillment));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition to evaluate against form parameters or session parameters.
   * See the [conditions
   * reference](https://cloud.google.com/dialogflow/cx/docs/reference/condition).
   * At least one of `intent` or `condition` must be specified. When both
   * `intent` and `condition` are specified, the transition can only happen when
   * both are fulfilled.
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The unique identifier of an Intent. Format:
   * `projects//locations//agents//intents/`. Indicates that the transition can
   * only happen when the given intent is matched. At least one of `intent` or
   * `condition` must be specified. When both `intent` and `condition` are
   * specified, the transition can only happen when both are fulfilled.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this transition route.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  get triggerFulfillment(): GoogleCloudDialogflowCxV3beta1Fulfillment|null {
    return (
        (this.Serializable$has('triggerFulfillment')) ?
            (this.Serializable$get('triggerFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to call when the condition is satisfied. At least one of
   * `trigger_fulfillment` and `target` must be specified. When both are
   * defined, `trigger_fulfillment` is executed first.
   */
  set triggerFulfillment(value: GoogleCloudDialogflowCxV3beta1Fulfillment|
                         null) {
    this.Serializable$set('triggerFulfillment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1TransitionRoute> {
    return GoogleCloudDialogflowCxV3beta1TransitionRoute;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'condition', 'intent', 'name', 'targetFlow', 'targetPage',
        'triggerFulfillment'
      ],
      objects: {'triggerFulfillment': GoogleCloudDialogflowCxV3beta1Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1UpdateDocumentOperationMetadataParameters {
  genericMetadata?:
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|null;
}
export class
    GoogleCloudDialogflowCxV3beta1UpdateDocumentOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1UpdateDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata|
      null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1UpdateDocumentOperationMetadata> {
    return GoogleCloudDialogflowCxV3beta1UpdateDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowCxV3beta1GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookParameters {
  name?: string|null;
  displayName?: string|null;
  cloudFunction?: GoogleCloudDialogflowCxV3beta1WebhookCloudFunction|null;
  genericWebService?: GoogleCloudDialogflowCxV3beta1WebhookGenericWebService|
      null;
  serviceDirectory?:
      GoogleCloudDialogflowCxV3beta1WebhookServiceDirectoryConfig|null;
  timeout?: string|null;
  disabled?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1Webhook extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1WebhookParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'cloudFunction',
        (parameters.cloudFunction == null) ? (null) :
                                             (parameters.cloudFunction));
    this.Serializable$set(
        'genericWebService',
        (parameters.genericWebService == null) ?
            (null) :
            (parameters.genericWebService));
    this.Serializable$set(
        'serviceDirectory',
        (parameters.serviceDirectory == null) ? (null) :
                                                (parameters.serviceDirectory));
    this.Serializable$set(
        'timeout',
        (parameters.timeout == null) ? (null) : (parameters.timeout));
    this.Serializable$set(
        'disabled',
        (parameters.disabled == null) ? (null) : (parameters.disabled));
  }

  get cloudFunction(): GoogleCloudDialogflowCxV3beta1WebhookCloudFunction|null {
    return (
        (this.Serializable$has('cloudFunction')) ?
            (this.Serializable$get('cloudFunction')) :
            (null));
  }

  /**
   * Configuration for a [Cloud Function](https://cloud.google.com/functions/).
   */
  set cloudFunction(value: GoogleCloudDialogflowCxV3beta1WebhookCloudFunction|
                    null) {
    this.Serializable$set('cloudFunction', value);
  }

  get disabled(): boolean|null {
    return (
        (this.Serializable$has('disabled')) ?
            (this.Serializable$get('disabled')) :
            (null));
  }

  /**
   * Indicates whether the webhook is disabled.
   */
  set disabled(value: boolean|null) {
    this.Serializable$set('disabled', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the webhook, unique within the agent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get genericWebService():
      GoogleCloudDialogflowCxV3beta1WebhookGenericWebService|null {
    return (
        (this.Serializable$has('genericWebService')) ?
            (this.Serializable$get('genericWebService')) :
            (null));
  }

  /**
   * Configuration for a generic web service.
   */
  set genericWebService(
      value: GoogleCloudDialogflowCxV3beta1WebhookGenericWebService|null) {
    this.Serializable$set('genericWebService', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the webhook. Required for the
   * Webhooks.UpdateWebhook method. Webhooks.CreateWebhook populates the name
   * automatically. Format: `projects//locations//agents//webhooks/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get serviceDirectory():
      GoogleCloudDialogflowCxV3beta1WebhookServiceDirectoryConfig|null {
    return (
        (this.Serializable$has('serviceDirectory')) ?
            (this.Serializable$get('serviceDirectory')) :
            (null));
  }

  /**
   * Configuration for a [Service
   * Directory](https://cloud.google.com/service-directory) service.
   */
  set serviceDirectory(
      value: GoogleCloudDialogflowCxV3beta1WebhookServiceDirectoryConfig|null) {
    this.Serializable$set('serviceDirectory', value);
  }

  get timeout(): string|null {
    return (
        (this.Serializable$has('timeout')) ?
            (this.Serializable$get('timeout')) :
            (null));
  }

  /**
   * Webhook execution timeout. Execution is considered failed if Dialogflow
   * doesn't receive a response from webhook at the end of the timeout period.
   * Defaults to 5 seconds, maximum allowed timeout is 30 seconds.
   */
  set timeout(value: string|null) {
    this.Serializable$set('timeout', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowCxV3beta1Webhook> {
    return GoogleCloudDialogflowCxV3beta1Webhook;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'cloudFunction', 'disabled', 'displayName', 'genericWebService', 'name',
        'serviceDirectory', 'timeout'
      ],
      objects: {
        'cloudFunction': GoogleCloudDialogflowCxV3beta1WebhookCloudFunction,
        'genericWebService':
            GoogleCloudDialogflowCxV3beta1WebhookGenericWebService,
        'serviceDirectory':
            GoogleCloudDialogflowCxV3beta1WebhookServiceDirectoryConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookCloudFunctionParameters {
  uri?: string|null;
  requestHeaders?: ApiClientObjectMap<string>|null;
}
export class GoogleCloudDialogflowCxV3beta1WebhookCloudFunction extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookCloudFunctionParameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'requestHeaders',
        (parameters.requestHeaders == null) ? (null) :
                                              (parameters.requestHeaders));
  }

  get requestHeaders(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('requestHeaders')) ?
            (this.Serializable$get('requestHeaders')) :
            (null));
  }

  /**
   * The HTTP request headers to send together with webhook requests.
   */
  set requestHeaders(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('requestHeaders', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The URI for the cloud function.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1WebhookCloudFunction> {
    return GoogleCloudDialogflowCxV3beta1WebhookCloudFunction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['requestHeaders', 'uri'],
      objectMaps: {
        'requestHeaders': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookConflictDiffParameters {
  displayName?: string|null;
  originalWebhookId?: string|null;
  importedWebhookId?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1WebhookConflictDiff extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookConflictDiffParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'originalWebhookId',
        (parameters.originalWebhookId == null) ?
            (null) :
            (parameters.originalWebhookId));
    this.Serializable$set(
        'importedWebhookId',
        (parameters.importedWebhookId == null) ?
            (null) :
            (parameters.importedWebhookId));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Conflicting display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get importedWebhookId(): string|null {
    return (
        (this.Serializable$has('importedWebhookId')) ?
            (this.Serializable$get('importedWebhookId')) :
            (null));
  }

  /**
   * The name of the conflicting webhook in the content to be imported. This is
   * a unique identifier. Format: `projects//locations//agents//webhooks/`.
   */
  set importedWebhookId(value: string|null) {
    this.Serializable$set('importedWebhookId', value);
  }

  get originalWebhookId(): string|null {
    return (
        (this.Serializable$has('originalWebhookId')) ?
            (this.Serializable$get('originalWebhookId')) :
            (null));
  }

  /**
   * The name of the conflicting webhook in the current agent. This is a unique
   * identifier. Format: `projects//locations//agents//webhooks/`.
   */
  set originalWebhookId(value: string|null) {
    this.Serializable$set('originalWebhookId', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1WebhookConflictDiff> {
    return GoogleCloudDialogflowCxV3beta1WebhookConflictDiff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'importedWebhookId', 'originalWebhookId']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookGenericWebServiceParameters {
  uri?: string|null;
  username?: string|null;
  password?: string|null;
  requestHeaders?: ApiClientObjectMap<string>|null;
  allowedCaCerts?: Array<string>|null;
}
export class GoogleCloudDialogflowCxV3beta1WebhookGenericWebService extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookGenericWebServiceParameters =
              {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'username',
        (parameters.username == null) ? (null) : (parameters.username));
    this.Serializable$set(
        'password',
        (parameters.password == null) ? (null) : (parameters.password));
    this.Serializable$set(
        'requestHeaders',
        (parameters.requestHeaders == null) ? (null) :
                                              (parameters.requestHeaders));
    this.Serializable$set(
        'allowedCaCerts',
        (parameters.allowedCaCerts == null) ? (null) :
                                              (parameters.allowedCaCerts));
  }

  get allowedCaCerts(): Array<string>|null {
    return (
        (this.Serializable$has('allowedCaCerts')) ?
            (this.Serializable$get('allowedCaCerts')) :
            (null));
  }

  /**
   * Optional. Specifies a list of allowed custom CA certificates (in DER
   * format) for HTTPS verification. This overrides the default SSL trust store.
   * If this is empty or unspecified, Dialogflow will use Google's default trust
   * store to verify certificates. N.B. Make sure the HTTPS server certificates
   * are signed with \"subject alt name\". For instance a certificate can be
   * self-signed using the following command, ``` openssl x509 -req -days 200
   * -in example.com.csr \ -signkey example.com.key \ -out example.com.crt \
   * -extfile <(printf \"\nsubjectAltName='DNS:www.example.com'\") ```
   */
  set allowedCaCerts(value: Array<string>|null) {
    this.Serializable$set('allowedCaCerts', value);
  }

  get password(): string|null {
    return (
        (this.Serializable$has('password')) ?
            (this.Serializable$get('password')) :
            (null));
  }

  /**
   * The password for HTTP Basic authentication.
   */
  set password(value: string|null) {
    this.Serializable$set('password', value);
  }

  get requestHeaders(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('requestHeaders')) ?
            (this.Serializable$get('requestHeaders')) :
            (null));
  }

  /**
   * The HTTP request headers to send together with webhook requests.
   */
  set requestHeaders(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('requestHeaders', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The webhook URI for receiving POST requests. It must use https
   * protocol.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  get username(): string|null {
    return (
        (this.Serializable$has('username')) ?
            (this.Serializable$get('username')) :
            (null));
  }

  /**
   * The user name for HTTP Basic authentication.
   */
  set username(value: string|null) {
    this.Serializable$set('username', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1WebhookGenericWebService> {
    return GoogleCloudDialogflowCxV3beta1WebhookGenericWebService;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['allowedCaCerts', 'password', 'requestHeaders', 'uri', 'username'],
      objectMaps: {
        'requestHeaders': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookRequestParameters {
  detectIntentResponseId?: string|null;
  text?: string|null;
  triggerIntent?: string|null;
  transcript?: string|null;
  triggerEvent?: string|null;
  languageCode?: string|null;
  fulfillmentInfo?: GoogleCloudDialogflowCxV3beta1WebhookRequestFulfillmentInfo|
      null;
  intentInfo?: GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfo|null;
  pageInfo?: GoogleCloudDialogflowCxV3beta1PageInfo|null;
  sessionInfo?: GoogleCloudDialogflowCxV3beta1SessionInfo|null;
  messages?: Array<GoogleCloudDialogflowCxV3beta1ResponseMessage>|null;
  payload?: ApiClientObjectMap<any>|null;
  sentimentAnalysisResult?:
      GoogleCloudDialogflowCxV3beta1WebhookRequestSentimentAnalysisResult|null;
}
export class GoogleCloudDialogflowCxV3beta1WebhookRequest extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowCxV3beta1WebhookRequestParameters = {}) {
    super();
    this.Serializable$set(
        'detectIntentResponseId',
        (parameters.detectIntentResponseId == null) ?
            (null) :
            (parameters.detectIntentResponseId));
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'triggerIntent',
        (parameters.triggerIntent == null) ? (null) :
                                             (parameters.triggerIntent));
    this.Serializable$set(
        'transcript',
        (parameters.transcript == null) ? (null) : (parameters.transcript));
    this.Serializable$set(
        'triggerEvent',
        (parameters.triggerEvent == null) ? (null) : (parameters.triggerEvent));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'fulfillmentInfo',
        (parameters.fulfillmentInfo == null) ? (null) :
                                               (parameters.fulfillmentInfo));
    this.Serializable$set(
        'intentInfo',
        (parameters.intentInfo == null) ? (null) : (parameters.intentInfo));
    this.Serializable$set(
        'pageInfo',
        (parameters.pageInfo == null) ? (null) : (parameters.pageInfo));
    this.Serializable$set(
        'sessionInfo',
        (parameters.sessionInfo == null) ? (null) : (parameters.sessionInfo));
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'sentimentAnalysisResult',
        (parameters.sentimentAnalysisResult == null) ?
            (null) :
            (parameters.sentimentAnalysisResult));
  }

  get detectIntentResponseId(): string|null {
    return (
        (this.Serializable$has('detectIntentResponseId')) ?
            (this.Serializable$get('detectIntentResponseId')) :
            (null));
  }

  /**
   * Always present. The unique identifier of the DetectIntentResponse that will
   * be returned to the API caller.
   */
  set detectIntentResponseId(value: string|null) {
    this.Serializable$set('detectIntentResponseId', value);
  }

  get fulfillmentInfo():
      GoogleCloudDialogflowCxV3beta1WebhookRequestFulfillmentInfo|null {
    return (
        (this.Serializable$has('fulfillmentInfo')) ?
            (this.Serializable$get('fulfillmentInfo')) :
            (null));
  }

  /**
   * Always present. Information about the fulfillment that triggered this
   * webhook call.
   */
  set fulfillmentInfo(
      value: GoogleCloudDialogflowCxV3beta1WebhookRequestFulfillmentInfo|null) {
    this.Serializable$set('fulfillmentInfo', value);
  }

  get intentInfo(): GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfo
      |null {
    return (
        (this.Serializable$has('intentInfo')) ?
            (this.Serializable$get('intentInfo')) :
            (null));
  }

  /**
   * Information about the last matched intent.
   */
  set intentInfo(value: GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfo|
                 null) {
    this.Serializable$set('intentInfo', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language code specified in the original request.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get messages(): Array<GoogleCloudDialogflowCxV3beta1ResponseMessage>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The list of rich message responses to present to the user. Webhook can
   * choose to append or replace this list in
   * WebhookResponse.fulfillment_response;
   */
  set messages(value: Array<GoogleCloudDialogflowCxV3beta1ResponseMessage>|
               null) {
    this.Serializable$set('messages', value);
  }

  get pageInfo(): GoogleCloudDialogflowCxV3beta1PageInfo|null {
    return (
        (this.Serializable$has('pageInfo')) ?
            (this.Serializable$get('pageInfo')) :
            (null));
  }

  /**
   * Information about page status.
   */
  set pageInfo(value: GoogleCloudDialogflowCxV3beta1PageInfo|null) {
    this.Serializable$set('pageInfo', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Custom data set in QueryParameters.payload.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get sentimentAnalysisResult():
      GoogleCloudDialogflowCxV3beta1WebhookRequestSentimentAnalysisResult|null {
    return (
        (this.Serializable$has('sentimentAnalysisResult')) ?
            (this.Serializable$get('sentimentAnalysisResult')) :
            (null));
  }

  /**
   * The sentiment analysis result of the current user request. The field is
   * filled when sentiment analysis is configured to be enabled for the request.
   */
  set sentimentAnalysisResult(
      value:
          GoogleCloudDialogflowCxV3beta1WebhookRequestSentimentAnalysisResult|
      null) {
    this.Serializable$set('sentimentAnalysisResult', value);
  }

  get sessionInfo(): GoogleCloudDialogflowCxV3beta1SessionInfo|null {
    return (
        (this.Serializable$has('sessionInfo')) ?
            (this.Serializable$get('sessionInfo')) :
            (null));
  }

  /**
   * Information about session status.
   */
  set sessionInfo(value: GoogleCloudDialogflowCxV3beta1SessionInfo|null) {
    this.Serializable$set('sessionInfo', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * If natural language text was provided as input, this field will contain a
   * copy of the text.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  get transcript(): string|null {
    return (
        (this.Serializable$has('transcript')) ?
            (this.Serializable$get('transcript')) :
            (null));
  }

  /**
   * If natural language speech audio was provided as input, this field will
   * contain the transcript for the audio.
   */
  set transcript(value: string|null) {
    this.Serializable$set('transcript', value);
  }

  get triggerEvent(): string|null {
    return (
        (this.Serializable$has('triggerEvent')) ?
            (this.Serializable$get('triggerEvent')) :
            (null));
  }

  /**
   * If an event was provided as input, this field will contain the name of the
   * event.
   */
  set triggerEvent(value: string|null) {
    this.Serializable$set('triggerEvent', value);
  }

  get triggerIntent(): string|null {
    return (
        (this.Serializable$has('triggerIntent')) ?
            (this.Serializable$get('triggerIntent')) :
            (null));
  }

  /**
   * If an intent was provided as input, this field will contain a copy of the
   * intent identifier. Format: `projects//locations//agents//intents/`.
   */
  set triggerIntent(value: string|null) {
    this.Serializable$set('triggerIntent', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1WebhookRequest> {
    return GoogleCloudDialogflowCxV3beta1WebhookRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'messages': GoogleCloudDialogflowCxV3beta1ResponseMessage},
      keys: [
        'detectIntentResponseId', 'fulfillmentInfo', 'intentInfo',
        'languageCode', 'messages', 'pageInfo', 'payload',
        'sentimentAnalysisResult', 'sessionInfo', 'text', 'transcript',
        'triggerEvent', 'triggerIntent'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'fulfillmentInfo':
            GoogleCloudDialogflowCxV3beta1WebhookRequestFulfillmentInfo,
        'intentInfo': GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfo,
        'pageInfo': GoogleCloudDialogflowCxV3beta1PageInfo,
        'sentimentAnalysisResult':
            GoogleCloudDialogflowCxV3beta1WebhookRequestSentimentAnalysisResult,
        'sessionInfo': GoogleCloudDialogflowCxV3beta1SessionInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookRequestFulfillmentInfoParameters {
  tag?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1WebhookRequestFulfillmentInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookRequestFulfillmentInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'tag', (parameters.tag == null) ? (null) : (parameters.tag));
  }

  get tag(): string|null {
    return (
        (this.Serializable$has('tag')) ? (this.Serializable$get('tag')) :
                                         (null));
  }

  /**
   * Always present. The value of the Fulfillment.tag field will be populated in
   * this field by Dialogflow when the associated webhook is called. The tag is
   * typically used by the webhook service to identify which fulfillment is
   * being called, but it could be used for other purposes.
   */
  set tag(value: string|null) {
    this.Serializable$set('tag', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1WebhookRequestFulfillmentInfo> {
    return GoogleCloudDialogflowCxV3beta1WebhookRequestFulfillmentInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['tag']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoParameters {
  lastMatchedIntent?: string|null;
  displayName?: string|null;
  parameters?: ApiClientObjectMap<
      GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoIntentParameterValue>|
      null;
  confidence?: number|null;
}
export class GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'lastMatchedIntent',
        (parameters.lastMatchedIntent == null) ?
            (null) :
            (parameters.lastMatchedIntent));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The confidence of the matched intent. Values range from 0.0 (completely
   * uncertain) to 1.0 (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Always present. The display name of the last matched intent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get lastMatchedIntent(): string|null {
    return (
        (this.Serializable$has('lastMatchedIntent')) ?
            (this.Serializable$get('lastMatchedIntent')) :
            (null));
  }

  /**
   * Always present. The unique identifier of the last matched intent. Format:
   * `projects//locations//agents//intents/`.
   */
  set lastMatchedIntent(value: string|null) {
    this.Serializable$set('lastMatchedIntent', value);
  }

  get parameters(): ApiClientObjectMap<
      GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoIntentParameterValue>|
      null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Parameters identified as a result of intent matching. This is a map of the
   * name of the identified parameter to the value of the parameter identified
   * from the user's utterance. All parameters defined in the matched intent
   * that are identified will be surfaced here.
   */
  set parameters(
      value: ApiClientObjectMap<
          GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoIntentParameterValue>|
      null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfo> {
    return GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['confidence', 'displayName', 'lastMatchedIntent', 'parameters'],
      objectMaps: {
        'parameters': {
          ctor:
              GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoIntentParameterValue,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoIntentParameterValueParameters {
  originalValue?: string|null;
  resolvedValue?: any|null;
}
export class
    GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoIntentParameterValue extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoIntentParameterValueParameters =
              {}) {
    super();
    this.Serializable$set(
        'originalValue',
        (parameters.originalValue == null) ? (null) :
                                             (parameters.originalValue));
    this.Serializable$set(
        'resolvedValue',
        (parameters.resolvedValue == null) ? (null) :
                                             (parameters.resolvedValue));
  }

  get originalValue(): string|null {
    return (
        (this.Serializable$has('originalValue')) ?
            (this.Serializable$get('originalValue')) :
            (null));
  }

  /**
   * Always present. Original text value extracted from user utterance.
   */
  set originalValue(value: string|null) {
    this.Serializable$set('originalValue', value);
  }

  get resolvedValue(): any|null {
    return (
        (this.Serializable$has('resolvedValue')) ?
            (this.Serializable$get('resolvedValue')) :
            (null));
  }

  /**
   * Always present. Structured value for the parameter extracted from user
   * utterance.
   */
  set resolvedValue(value: any|null) {
    this.Serializable$set('resolvedValue', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoIntentParameterValue> {
    return GoogleCloudDialogflowCxV3beta1WebhookRequestIntentInfoIntentParameterValue;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['originalValue', 'resolvedValue']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookRequestSentimentAnalysisResultParameters {
  score?: number|null;
  magnitude?: number|null;
}
export class
    GoogleCloudDialogflowCxV3beta1WebhookRequestSentimentAnalysisResult extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookRequestSentimentAnalysisResultParameters =
              {}) {
    super();
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'magnitude',
        (parameters.magnitude == null) ? (null) : (parameters.magnitude));
  }

  get magnitude(): number|null {
    return (
        (this.Serializable$has('magnitude')) ?
            (this.Serializable$get('magnitude')) :
            (null));
  }

  /**
   * A non-negative number in the [0, +inf) range, which represents the absolute
   * magnitude of sentiment, regardless of score (positive or negative).
   */
  set magnitude(value: number|null) {
    this.Serializable$set('magnitude', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Sentiment score between -1.0 (negative sentiment) and 1.0 (positive
   * sentiment).
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1WebhookRequestSentimentAnalysisResult> {
    return GoogleCloudDialogflowCxV3beta1WebhookRequestSentimentAnalysisResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['magnitude', 'score']};
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookResponseParameters {
  fulfillmentResponse?:
      GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponse|null;
  pageInfo?: GoogleCloudDialogflowCxV3beta1PageInfo|null;
  sessionInfo?: GoogleCloudDialogflowCxV3beta1SessionInfo|null;
  payload?: ApiClientObjectMap<any>|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowCxV3beta1WebhookResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookResponseParameters = {}) {
    super();
    this.Serializable$set(
        'fulfillmentResponse',
        (parameters.fulfillmentResponse == null) ?
            (null) :
            (parameters.fulfillmentResponse));
    this.Serializable$set(
        'pageInfo',
        (parameters.pageInfo == null) ? (null) : (parameters.pageInfo));
    this.Serializable$set(
        'sessionInfo',
        (parameters.sessionInfo == null) ? (null) : (parameters.sessionInfo));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get fulfillmentResponse():
      GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponse|null {
    return (
        (this.Serializable$has('fulfillmentResponse')) ?
            (this.Serializable$get('fulfillmentResponse')) :
            (null));
  }

  /**
   * The fulfillment response to send to the user. This field can be omitted by
   * the webhook if it does not intend to send any response to the user.
   */
  set fulfillmentResponse(
      value: GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponse|
      null) {
    this.Serializable$set('fulfillmentResponse', value);
  }

  get pageInfo(): GoogleCloudDialogflowCxV3beta1PageInfo|null {
    return (
        (this.Serializable$has('pageInfo')) ?
            (this.Serializable$get('pageInfo')) :
            (null));
  }

  /**
   * Information about page status. This field can be omitted by the webhook if
   * it does not intend to modify page status.
   */
  set pageInfo(value: GoogleCloudDialogflowCxV3beta1PageInfo|null) {
    this.Serializable$set('pageInfo', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Value to append directly to QueryResult.webhook_payloads.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get sessionInfo(): GoogleCloudDialogflowCxV3beta1SessionInfo|null {
    return (
        (this.Serializable$has('sessionInfo')) ?
            (this.Serializable$get('sessionInfo')) :
            (null));
  }

  /**
   * Information about session status. This field can be omitted by the webhook
   * if it does not intend to modify session status.
   */
  set sessionInfo(value: GoogleCloudDialogflowCxV3beta1SessionInfo|null) {
    this.Serializable$set('sessionInfo', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1WebhookResponse> {
    return GoogleCloudDialogflowCxV3beta1WebhookResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'fulfillmentResponse', 'pageInfo', 'payload', 'sessionInfo',
        'targetFlow', 'targetPage'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'fulfillmentResponse':
            GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponse,
        'pageInfo': GoogleCloudDialogflowCxV3beta1PageInfo,
        'sessionInfo': GoogleCloudDialogflowCxV3beta1SessionInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseParameters {
  messages?: Array<GoogleCloudDialogflowCxV3beta1ResponseMessage>|null;
  mergeBehavior?:
      GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior|
      null;
}
export class
    GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'mergeBehavior',
        (parameters.mergeBehavior == null) ? (null) :
                                             (parameters.mergeBehavior));
  }

  static get MergeBehavior():
      IGoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehaviorEnum {
    return GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehaviorEnum;
  }

  get mergeBehavior():
      GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior
      |null {
    return (
        (this.Serializable$has('mergeBehavior')) ?
            (this.Serializable$get('mergeBehavior')) :
            (null));
  }

  /**
   * Merge behavior for `messages`.
   */
  set mergeBehavior(
      value:
          GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehavior|
      null) {
    this.Serializable$set('mergeBehavior', value);
  }

  get messages(): Array<GoogleCloudDialogflowCxV3beta1ResponseMessage>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The list of rich message responses to present to the user.
   */
  set messages(value: Array<GoogleCloudDialogflowCxV3beta1ResponseMessage>|
               null) {
    this.Serializable$set('messages', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponse> {
    return GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'messages': GoogleCloudDialogflowCxV3beta1ResponseMessage},
      enums: {
        'mergeBehavior':
            GoogleCloudDialogflowCxV3beta1WebhookResponseFulfillmentResponseMergeBehaviorEnum
      },
      keys: ['mergeBehavior', 'messages']
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookServiceDirectoryConfigParameters {
  service?: string|null;
  genericWebService?: GoogleCloudDialogflowCxV3beta1WebhookGenericWebService|
      null;
}
export class GoogleCloudDialogflowCxV3beta1WebhookServiceDirectoryConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookServiceDirectoryConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'service',
        (parameters.service == null) ? (null) : (parameters.service));
    this.Serializable$set(
        'genericWebService',
        (parameters.genericWebService == null) ?
            (null) :
            (parameters.genericWebService));
  }

  get genericWebService():
      GoogleCloudDialogflowCxV3beta1WebhookGenericWebService|null {
    return (
        (this.Serializable$has('genericWebService')) ?
            (this.Serializable$get('genericWebService')) :
            (null));
  }

  /**
   * Generic Service configuration of this webhook.
   */
  set genericWebService(
      value: GoogleCloudDialogflowCxV3beta1WebhookGenericWebService|null) {
    this.Serializable$set('genericWebService', value);
  }

  get service(): string|null {
    return (
        (this.Serializable$has('service')) ?
            (this.Serializable$get('service')) :
            (null));
  }

  /**
   * Required. The name of [Service
   * Directory](https://cloud.google.com/service-directory) service. Format:
   * `projects//locations//namespaces//services/`. `Location ID` of the service
   * directory must be the same as the location of the agent.
   */
  set service(value: string|null) {
    this.Serializable$set('service', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowCxV3beta1WebhookServiceDirectoryConfig> {
    return GoogleCloudDialogflowCxV3beta1WebhookServiceDirectoryConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericWebService', 'service'],
      objects: {
        'genericWebService':
            GoogleCloudDialogflowCxV3beta1WebhookGenericWebService
      }
    };
  }
}

export interface GoogleCloudDialogflowCxV3beta1WebhookSettingsParameters {
  disableTransition?: boolean|null;
  disableGenerateAgentResponse?: boolean|null;
  enforceParameterDefinitions?: boolean|null;
}
export class GoogleCloudDialogflowCxV3beta1WebhookSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowCxV3beta1WebhookSettingsParameters = {}) {
    super();
    this.Serializable$set(
        'disableTransition',
        (parameters.disableTransition == null) ?
            (null) :
            (parameters.disableTransition));
    this.Serializable$set(
        'disableGenerateAgentResponse',
        (parameters.disableGenerateAgentResponse == null) ?
            (null) :
            (parameters.disableGenerateAgentResponse));
    this.Serializable$set(
        'enforceParameterDefinitions',
        (parameters.enforceParameterDefinitions == null) ?
            (null) :
            (parameters.enforceParameterDefinitions));
  }

  get disableGenerateAgentResponse(): boolean|null {
    return (
        (this.Serializable$has('disableGenerateAgentResponse')) ?
            (this.Serializable$get('disableGenerateAgentResponse')) :
            (null));
  }

  /**
   * Whether the webhook can generate agent responses. If this flag is true,
   * `fulfillment_response` will be ignored in the WebhookResponse.
   */
  set disableGenerateAgentResponse(value: boolean|null) {
    this.Serializable$set('disableGenerateAgentResponse', value);
  }

  get disableTransition(): boolean|null {
    return (
        (this.Serializable$has('disableTransition')) ?
            (this.Serializable$get('disableTransition')) :
            (null));
  }

  /**
   * Whether a webhook can transit the conversation to another page or flow. If
   * this flag is true, `target_page` and `target_flow` are ignored in the
   * WebhookResponse.
   */
  set disableTransition(value: boolean|null) {
    this.Serializable$set('disableTransition', value);
  }

  get enforceParameterDefinitions(): boolean|null {
    return (
        (this.Serializable$has('enforceParameterDefinitions')) ?
            (this.Serializable$get('enforceParameterDefinitions')) :
            (null));
  }

  /**
   * Whether parameter definitions will be enforced: 1. Only parameters in the
   * input parameters can be sent to the webhook. 2. Only parameters in the
   * output parameters can be modified by the webhook. 3. Parameter types will
   * be checked and only set if they match the type. Parameter types are defined
   * in the agent.
   */
  set enforceParameterDefinitions(value: boolean|null) {
    this.Serializable$set('enforceParameterDefinitions', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowCxV3beta1WebhookSettings> {
    return GoogleCloudDialogflowCxV3beta1WebhookSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'disableGenerateAgentResponse', 'disableTransition',
        'enforceParameterDefinitions'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV2AnnotatedMessagePartParameters {
  text?: string|null;
  entityType?: string|null;
  formattedValue?: any|null;
}
export class GoogleCloudDialogflowV2AnnotatedMessagePart extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2AnnotatedMessagePartParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
    this.Serializable$set(
        'formattedValue',
        (parameters.formattedValue == null) ? (null) :
                                              (parameters.formattedValue));
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * The [Dialogflow system entity
   * type](https://cloud.google.com/dialogflow/docs/reference/system-entities)
   * of this message part. If this is empty, Dialogflow could not annotate the
   * phrase part with a system entity.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get formattedValue(): any|null {
    return (
        (this.Serializable$has('formattedValue')) ?
            (this.Serializable$get('formattedValue')) :
            (null));
  }

  /**
   * The [Dialogflow system entity formatted value
   * ](https://cloud.google.com/dialogflow/docs/reference/system-entities) of
   * this message part. For example for a system entity of type
   * `@sys.unit-currency`, this may contain: { \"amount\": 5, \"currency\":
   * \"USD\" }
   */
  set formattedValue(value: any|null) {
    this.Serializable$set('formattedValue', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * A part of a message possibly annotated with an entity.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2AnnotatedMessagePart> {
    return GoogleCloudDialogflowV2AnnotatedMessagePart;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['entityType', 'formattedValue', 'text']};
  }
}

export interface GoogleCloudDialogflowV2ArticleAnswerParameters {
  title?: string|null;
  uri?: string|null;
  snippets?: Array<string>|null;
  confidence?: number|null;
  metadata?: ApiClientObjectMap<string>|null;
  answerRecord?: string|null;
}
export class GoogleCloudDialogflowV2ArticleAnswer extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2ArticleAnswerParameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'snippets',
        (parameters.snippets == null) ? (null) : (parameters.snippets));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * Article match confidence. The system's confidence score that this article
   * is a good match for this conversation, as a value from 0.0 (completely
   * uncertain) to 1.0 (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get metadata(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * A map that contains metadata about the answer and the document from which
   * it originates.
   */
  set metadata(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('metadata', value);
  }

  get snippets(): Array<string>|null {
    return (
        (this.Serializable$has('snippets')) ?
            (this.Serializable$get('snippets')) :
            (null));
  }

  /**
   * Article snippets.
   */
  set snippets(value: Array<string>|null) {
    this.Serializable$set('snippets', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * The article title.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * The article URI.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2ArticleAnswer> {
    return GoogleCloudDialogflowV2ArticleAnswer;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'answerRecord', 'confidence', 'metadata', 'snippets', 'title', 'uri'
      ],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV2ArticleSuggestionModelMetadataParameters {
  internalTrainingFlags?: string|null;
  trainingModelType?:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType|
      null;
}
export class GoogleCloudDialogflowV2ArticleSuggestionModelMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
  }

  static get TrainingModelType():
      IGoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum {
    return GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum;
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  /**
   * Internal testing only. The text format proto of InternalTrainingFlags
   * defined in cloud/ai/contactcenter/knowledge/proto/conversation_model.proto.
   */
  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get trainingModelType():
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType
      |null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Optional. Type of the article suggestion model. If not provided, model_type
   * is used.
   */
  set trainingModelType(
      value:
          GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelType|
      null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2ArticleSuggestionModelMetadata> {
    return GoogleCloudDialogflowV2ArticleSuggestionModelMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'trainingModelType':
            GoogleCloudDialogflowV2ArticleSuggestionModelMetadataTrainingModelTypeEnum
      },
      keys: ['internalTrainingFlags', 'trainingModelType']
    };
  }
}

export interface GoogleCloudDialogflowV2AssociateAllowlistInfoParameters {
  document?: string|null;
  sourceType?: GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType|null;
}
export class GoogleCloudDialogflowV2AssociateAllowlistInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2AssociateAllowlistInfoParameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'sourceType',
        (parameters.sourceType == null) ? (null) : (parameters.sourceType));
  }

  static get SourceType():
      IGoogleCloudDialogflowV2AssociateAllowlistInfoSourceTypeEnum {
    return GoogleCloudDialogflowV2AssociateAllowlistInfoSourceTypeEnum;
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The resource name of the associated allowlist. Format:
   * 'projects//knowledgeBases//documents/'
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  get sourceType(): GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType
      |null {
    return (
        (this.Serializable$has('sourceType')) ?
            (this.Serializable$get('sourceType')) :
            (null));
  }

  /**
   * Source of the associated allowlist document.
   */
  set sourceType(value: GoogleCloudDialogflowV2AssociateAllowlistInfoSourceType|
                 null) {
    this.Serializable$set('sourceType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2AssociateAllowlistInfo> {
    return GoogleCloudDialogflowV2AssociateAllowlistInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'sourceType':
            GoogleCloudDialogflowV2AssociateAllowlistInfoSourceTypeEnum
      },
      keys: ['document', 'sourceType']
    };
  }
}

export interface GoogleCloudDialogflowV2BatchUpdateEntityTypesResponseParameters {
  entityTypes?: Array<GoogleCloudDialogflowV2EntityType>|null;
}
export class GoogleCloudDialogflowV2BatchUpdateEntityTypesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2BatchUpdateEntityTypesResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'entityTypes',
        (parameters.entityTypes == null) ? (null) : (parameters.entityTypes));
  }

  get entityTypes(): Array<GoogleCloudDialogflowV2EntityType>|null {
    return (
        (this.Serializable$has('entityTypes')) ?
            (this.Serializable$get('entityTypes')) :
            (null));
  }

  /**
   * The collection of updated or created entity types.
   */
  set entityTypes(value: Array<GoogleCloudDialogflowV2EntityType>|null) {
    this.Serializable$set('entityTypes', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2BatchUpdateEntityTypesResponse> {
    return GoogleCloudDialogflowV2BatchUpdateEntityTypesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'entityTypes': GoogleCloudDialogflowV2EntityType},
      keys: ['entityTypes']
    };
  }
}

export interface GoogleCloudDialogflowV2BatchUpdateIntentsResponseParameters {
  intents?: Array<GoogleCloudDialogflowV2Intent>|null;
}
export class GoogleCloudDialogflowV2BatchUpdateIntentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2BatchUpdateIntentsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
  }

  get intents(): Array<GoogleCloudDialogflowV2Intent>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * The collection of updated or created intents.
   */
  set intents(value: Array<GoogleCloudDialogflowV2Intent>|null) {
    this.Serializable$set('intents', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2BatchUpdateIntentsResponse> {
    return GoogleCloudDialogflowV2BatchUpdateIntentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'intents': GoogleCloudDialogflowV2Intent},
      keys: ['intents']
    };
  }
}

export interface GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParameters {
  conversationProfile?: string|null;
  participantRole?:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole|
      null;
  suggestionFeatureType?:
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType|
      null;
  createTime?: string|null;
}
export class
    GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'conversationProfile',
        (parameters.conversationProfile == null) ?
            (null) :
            (parameters.conversationProfile));
    this.Serializable$set(
        'participantRole',
        (parameters.participantRole == null) ? (null) :
                                               (parameters.participantRole));
    this.Serializable$set(
        'suggestionFeatureType',
        (parameters.suggestionFeatureType == null) ?
            (null) :
            (parameters.suggestionFeatureType));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  static get ParticipantRole():
      IGoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRoleEnum {
    return GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRoleEnum;
  }

  static get SuggestionFeatureType():
      IGoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum {
    return GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum;
  }

  get conversationProfile(): string|null {
    return (
        (this.Serializable$has('conversationProfile')) ?
            (this.Serializable$get('conversationProfile')) :
            (null));
  }

  /**
   * The resource name of the conversation profile. Format:
   * `projects//locations//conversationProfiles/`
   */
  set conversationProfile(value: string|null) {
    this.Serializable$set('conversationProfile', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp whe the request was created. The time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get participantRole():
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole
      |null {
    return (
        (this.Serializable$has('participantRole')) ?
            (this.Serializable$get('participantRole')) :
            (null));
  }

  /**
   * Required. The participant role to remove the suggestion feature config.
   * Only HUMAN_AGENT or END_USER can be used.
   */
  set participantRole(
      value:
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRole|
      null) {
    this.Serializable$set('participantRole', value);
  }

  get suggestionFeatureType():
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType
      |null {
    return (
        (this.Serializable$has('suggestionFeatureType')) ?
            (this.Serializable$get('suggestionFeatureType')) :
            (null));
  }

  /**
   * Required. The type of the suggestion feature to remove.
   */
  set suggestionFeatureType(
      value:
          GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureType|
      null) {
    this.Serializable$set('suggestionFeatureType', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadata> {
    return GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'participantRole':
            GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataParticipantRoleEnum,
        'suggestionFeatureType':
            GoogleCloudDialogflowV2ClearSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
      },
      keys: [
        'conversationProfile', 'createTime', 'participantRole',
        'suggestionFeatureType'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV2ContextParameters {
  name?: string|null;
  lifespanCount?: number|null;
  parameters?: ApiClientObjectMap<any>|null;
}
export class GoogleCloudDialogflowV2Context extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2ContextParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'lifespanCount',
        (parameters.lifespanCount == null) ? (null) :
                                             (parameters.lifespanCount));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get lifespanCount(): number|null {
    return (
        (this.Serializable$has('lifespanCount')) ?
            (this.Serializable$get('lifespanCount')) :
            (null));
  }

  /**
   * Optional. The number of conversational query requests after which the
   * context expires. The default is `0`. If set to `0`, the context expires
   * immediately. Contexts expire automatically after 20 minutes if there are no
   * matching queries.
   */
  set lifespanCount(value: number|null) {
    this.Serializable$set('lifespanCount', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of the context. Format:
   * `projects//agent/sessions//contexts/`, or
   * `projects//agent/environments//users//sessions//contexts/`. The `Context
   * ID` is always converted to lowercase, may only contain characters in
   * a-zA-Z0-9_-% and may be at most 250 bytes long. If `Environment ID` is not
   * specified, we assume default 'draft' environment. If `User ID` is not
   * specified, we assume default '-' user. The following context names are
   * reserved for internal use by Dialogflow. You should not use these contexts
   * or create contexts with these names: * `__system_counters__` *
   * `*_id_dialog_context` * `*_dialog_params_size`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional. The collection of parameters associated with this context.
   * Depending on your protocol or client library language, this is a map,
   * associative array, symbol table, dictionary, or JSON object composed of a
   * collection of (MapKey, MapValue) pairs: - MapKey type: string - MapKey
   * value: parameter name - MapValue type: - If parameter's entity type is a
   * composite entity: map - Else: depending on parameter value type, could be
   * one of string, number, boolean, null, list or map - MapValue value: - If
   * parameter's entity type is a composite entity: map from composite entity
   * property names to property values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2Context> {
    return GoogleCloudDialogflowV2Context;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['lifespanCount', 'name', 'parameters'],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV2ConversationEventParameters {
  conversation?: string|null;
  type?: GoogleCloudDialogflowV2ConversationEventType|null;
  errorStatus?: GoogleRpcStatus|null;
  newMessagePayload?: GoogleCloudDialogflowV2Message|null;
}
export class GoogleCloudDialogflowV2ConversationEvent extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2ConversationEventParameters = {}) {
    super();
    this.Serializable$set(
        'conversation',
        (parameters.conversation == null) ? (null) : (parameters.conversation));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'errorStatus',
        (parameters.errorStatus == null) ? (null) : (parameters.errorStatus));
    this.Serializable$set(
        'newMessagePayload',
        (parameters.newMessagePayload == null) ?
            (null) :
            (parameters.newMessagePayload));
  }

  static get Type(): IGoogleCloudDialogflowV2ConversationEventTypeEnum {
    return GoogleCloudDialogflowV2ConversationEventTypeEnum;
  }

  get conversation(): string|null {
    return (
        (this.Serializable$has('conversation')) ?
            (this.Serializable$get('conversation')) :
            (null));
  }

  /**
   * The unique identifier of the conversation this notification refers to.
   * Format: `projects//conversations/`.
   */
  set conversation(value: string|null) {
    this.Serializable$set('conversation', value);
  }

  get errorStatus(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('errorStatus')) ?
            (this.Serializable$get('errorStatus')) :
            (null));
  }

  /**
   * More detailed information about an error. Only set for type
   * UNRECOVERABLE_ERROR_IN_PHONE_CALL.
   */
  set errorStatus(value: GoogleRpcStatus|null) {
    this.Serializable$set('errorStatus', value);
  }

  get newMessagePayload(): GoogleCloudDialogflowV2Message|null {
    return (
        (this.Serializable$has('newMessagePayload')) ?
            (this.Serializable$get('newMessagePayload')) :
            (null));
  }

  /**
   * Payload of NEW_MESSAGE event.
   */
  set newMessagePayload(value: GoogleCloudDialogflowV2Message|null) {
    this.Serializable$set('newMessagePayload', value);
  }

  get type(): GoogleCloudDialogflowV2ConversationEventType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * The type of the event that this notification refers to.
   */
  set type(value: GoogleCloudDialogflowV2ConversationEventType|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2ConversationEvent> {
    return GoogleCloudDialogflowV2ConversationEvent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'type': GoogleCloudDialogflowV2ConversationEventTypeEnum},
      keys: ['conversation', 'errorStatus', 'newMessagePayload', 'type'],
      objects: {
        'errorStatus': GoogleRpcStatus,
        'newMessagePayload': GoogleCloudDialogflowV2Message
      }
    };
  }
}

export interface GoogleCloudDialogflowV2ConversationModelParameters {
  name?: string|null;
  displayName?: string|null;
  createTime?: string|null;
  datasets?: Array<GoogleCloudDialogflowV2InputDataset>|null;
  state?: GoogleCloudDialogflowV2ConversationModelState|null;
  languageCode?: string|null;
  articleSuggestionModelMetadata?:
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadata|null;
  smartReplyModelMetadata?: GoogleCloudDialogflowV2SmartReplyModelMetadata|null;
  smartComposeModelMetadata?: GoogleCloudDialogflowV2SmartComposeModelMetadata|
      null;
  summarizationModelMetadata?:
      GoogleCloudDialogflowV2SummarizationModelMetadata|null;
  keyMomentModelMetadata?: GoogleCloudDialogflowV2KeyMomentModelMetadata|null;
  issueModelMetadata?: GoogleCloudDialogflowV2IssueModelMetadata|null;
  groupName?: string|null;
}
export class GoogleCloudDialogflowV2ConversationModel extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2ConversationModelParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'datasets',
        (parameters.datasets == null) ? (null) : (parameters.datasets));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'articleSuggestionModelMetadata',
        (parameters.articleSuggestionModelMetadata == null) ?
            (null) :
            (parameters.articleSuggestionModelMetadata));
    this.Serializable$set(
        'smartReplyModelMetadata',
        (parameters.smartReplyModelMetadata == null) ?
            (null) :
            (parameters.smartReplyModelMetadata));
    this.Serializable$set(
        'smartComposeModelMetadata',
        (parameters.smartComposeModelMetadata == null) ?
            (null) :
            (parameters.smartComposeModelMetadata));
    this.Serializable$set(
        'summarizationModelMetadata',
        (parameters.summarizationModelMetadata == null) ?
            (null) :
            (parameters.summarizationModelMetadata));
    this.Serializable$set(
        'keyMomentModelMetadata',
        (parameters.keyMomentModelMetadata == null) ?
            (null) :
            (parameters.keyMomentModelMetadata));
    this.Serializable$set(
        'issueModelMetadata',
        (parameters.issueModelMetadata == null) ?
            (null) :
            (parameters.issueModelMetadata));
    this.Serializable$set(
        'groupName',
        (parameters.groupName == null) ? (null) : (parameters.groupName));
  }

  static get State(): IGoogleCloudDialogflowV2ConversationModelStateEnum {
    return GoogleCloudDialogflowV2ConversationModelStateEnum;
  }

  get articleSuggestionModelMetadata():
      GoogleCloudDialogflowV2ArticleSuggestionModelMetadata|null {
    return (
        (this.Serializable$has('articleSuggestionModelMetadata')) ?
            (this.Serializable$get('articleSuggestionModelMetadata')) :
            (null));
  }

  /**
   * Metadata for article suggestion models.
   */
  set articleSuggestionModelMetadata(
      value: GoogleCloudDialogflowV2ArticleSuggestionModelMetadata|null) {
    this.Serializable$set('articleSuggestionModelMetadata', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Creation time of this model.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get datasets(): Array<GoogleCloudDialogflowV2InputDataset>|null {
    return (
        (this.Serializable$has('datasets')) ?
            (this.Serializable$get('datasets')) :
            (null));
  }

  /**
   * Required. Datasets used to create model.
   */
  set datasets(value: Array<GoogleCloudDialogflowV2InputDataset>|null) {
    this.Serializable$set('datasets', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The display name of the model. At most 64 bytes long.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get groupName(): string|null {
    return (
        (this.Serializable$has('groupName')) ?
            (this.Serializable$get('groupName')) :
            (null));
  }

  /**
   * Optional. Name of the group which this model belongs to. This is used
   * internally for grouping conversation models in agent assist console.
   */
  set groupName(value: string|null) {
    this.Serializable$set('groupName', value);
  }

  get issueModelMetadata(): GoogleCloudDialogflowV2IssueModelMetadata|null {
    return (
        (this.Serializable$has('issueModelMetadata')) ?
            (this.Serializable$get('issueModelMetadata')) :
            (null));
  }

  /**
   * Metadata for issue models.
   */
  set issueModelMetadata(value: GoogleCloudDialogflowV2IssueModelMetadata|
                         null) {
    this.Serializable$set('issueModelMetadata', value);
  }

  get keyMomentModelMetadata(): GoogleCloudDialogflowV2KeyMomentModelMetadata
      |null {
    return (
        (this.Serializable$has('keyMomentModelMetadata')) ?
            (this.Serializable$get('keyMomentModelMetadata')) :
            (null));
  }

  /**
   * Metadata for key moment models.
   */
  set keyMomentModelMetadata(value:
                                 GoogleCloudDialogflowV2KeyMomentModelMetadata|
                             null) {
    this.Serializable$set('keyMomentModelMetadata', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Language code for the conversation model. If not specified, the language is
   * en-US. Language at ConversationModel should be set for all non en-us
   * languages. This should be a
   * [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt) language tag.
   * Example: \"en-US\".
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * ConversationModel resource name. Format: `projects//conversationModels/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get smartComposeModelMetadata():
      GoogleCloudDialogflowV2SmartComposeModelMetadata|null {
    return (
        (this.Serializable$has('smartComposeModelMetadata')) ?
            (this.Serializable$get('smartComposeModelMetadata')) :
            (null));
  }

  /**
   * Metadata for smart compose models.
   */
  set smartComposeModelMetadata(
      value: GoogleCloudDialogflowV2SmartComposeModelMetadata|null) {
    this.Serializable$set('smartComposeModelMetadata', value);
  }

  get smartReplyModelMetadata(): GoogleCloudDialogflowV2SmartReplyModelMetadata
      |null {
    return (
        (this.Serializable$has('smartReplyModelMetadata')) ?
            (this.Serializable$get('smartReplyModelMetadata')) :
            (null));
  }

  /**
   * Metadata for smart reply models.
   */
  set smartReplyModelMetadata(
      value: GoogleCloudDialogflowV2SmartReplyModelMetadata|null) {
    this.Serializable$set('smartReplyModelMetadata', value);
  }

  get state(): GoogleCloudDialogflowV2ConversationModelState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Output only. State of the model. A model can only serve prediction requests
   * after it gets deployed.
   */
  set state(value: GoogleCloudDialogflowV2ConversationModelState|null) {
    this.Serializable$set('state', value);
  }

  get summarizationModelMetadata():
      GoogleCloudDialogflowV2SummarizationModelMetadata|null {
    return (
        (this.Serializable$has('summarizationModelMetadata')) ?
            (this.Serializable$get('summarizationModelMetadata')) :
            (null));
  }

  /**
   * Metadata for summarization models.
   */
  set summarizationModelMetadata(
      value: GoogleCloudDialogflowV2SummarizationModelMetadata|null) {
    this.Serializable$set('summarizationModelMetadata', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2ConversationModel> {
    return GoogleCloudDialogflowV2ConversationModel;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'datasets': GoogleCloudDialogflowV2InputDataset},
      enums: {'state': GoogleCloudDialogflowV2ConversationModelStateEnum},
      keys: [
        'articleSuggestionModelMetadata', 'createTime', 'datasets',
        'displayName', 'groupName', 'issueModelMetadata',
        'keyMomentModelMetadata', 'languageCode', 'name',
        'smartComposeModelMetadata', 'smartReplyModelMetadata', 'state',
        'summarizationModelMetadata'
      ],
      objects: {
        'articleSuggestionModelMetadata':
            GoogleCloudDialogflowV2ArticleSuggestionModelMetadata,
        'issueModelMetadata': GoogleCloudDialogflowV2IssueModelMetadata,
        'keyMomentModelMetadata': GoogleCloudDialogflowV2KeyMomentModelMetadata,
        'smartComposeModelMetadata':
            GoogleCloudDialogflowV2SmartComposeModelMetadata,
        'smartReplyModelMetadata':
            GoogleCloudDialogflowV2SmartReplyModelMetadata,
        'summarizationModelMetadata':
            GoogleCloudDialogflowV2SummarizationModelMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowV2CreateConversationDatasetOperationMetadataParameters {
  conversationDataset?: string|null;
}
export class
    GoogleCloudDialogflowV2CreateConversationDatasetOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2CreateConversationDatasetOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'conversationDataset',
        (parameters.conversationDataset == null) ?
            (null) :
            (parameters.conversationDataset));
  }

  get conversationDataset(): string|null {
    return (
        (this.Serializable$has('conversationDataset')) ?
            (this.Serializable$get('conversationDataset')) :
            (null));
  }

  /**
   * The resource name of the conversation dataset that will be created. Format:
   * `projects//locations//conversationDatasets/`
   */
  set conversationDataset(value: string|null) {
    this.Serializable$set('conversationDataset', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2CreateConversationDatasetOperationMetadata> {
    return GoogleCloudDialogflowV2CreateConversationDatasetOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationDataset']};
  }
}

export interface GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataParameters {
  conversationModelEvaluation?: string|null;
  conversationModel?: string|null;
  state?:
      GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState|
      null;
  createTime?: string|null;
}
export class
    GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'conversationModelEvaluation',
        (parameters.conversationModelEvaluation == null) ?
            (null) :
            (parameters.conversationModelEvaluation));
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  static get State():
      IGoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum {
    return GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum;
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//locations//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get conversationModelEvaluation(): string|null {
    return (
        (this.Serializable$has('conversationModelEvaluation')) ?
            (this.Serializable$get('conversationModelEvaluation')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//locations//conversationModels//evaluations/`
   */
  set conversationModelEvaluation(value: string|null) {
    this.Serializable$set('conversationModelEvaluation', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when the request to create conversation model was submitted. The
   * time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get state():
      GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState
      |null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * State of CreateConversationModel operation.
   */
  set state(
      value:
          GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataState|
      null) {
    this.Serializable$set('state', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadata> {
    return GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state':
            GoogleCloudDialogflowV2CreateConversationModelEvaluationOperationMetadataStateEnum
      },
      keys: [
        'conversationModel', 'conversationModelEvaluation', 'createTime',
        'state'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV2CreateConversationModelOperationMetadataParameters {
  conversationModel?: string|null;
  state?: GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState|
      null;
  createTime?: string|null;
  queuePosition?: number|null;
  genericMetadata?: GoogleCloudDialogflowV2GenericOperationMetadata|null;
  summarizationModelTrainingMetadata?:
      GoogleCloudDialogflowV2SummarizationModelTrainingMetadata|null;
}
export class
    GoogleCloudDialogflowV2CreateConversationModelOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'queuePosition',
        (parameters.queuePosition == null) ? (null) :
                                             (parameters.queuePosition));
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
    this.Serializable$set(
        'summarizationModelTrainingMetadata',
        (parameters.summarizationModelTrainingMetadata == null) ?
            (null) :
            (parameters.summarizationModelTrainingMetadata));
  }

  static get State():
      IGoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum {
    return GoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum;
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when the request to create conversation model is submitted. The
   * time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get genericMetadata(): GoogleCloudDialogflowV2GenericOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * Basic information shared by all operation metadata.
   */
  set genericMetadata(value: GoogleCloudDialogflowV2GenericOperationMetadata|
                      null) {
    this.Serializable$set('genericMetadata', value);
  }

  get queuePosition(): number|null {
    return (
        (this.Serializable$has('queuePosition')) ?
            (this.Serializable$get('queuePosition')) :
            (null));
  }

  /**
   * The PENDING job position in queue for acquiring resources(gpu, tpu) to
   * start job. Position starts from 0.
   */
  set queuePosition(value: number|null) {
    this.Serializable$set('queuePosition', value);
  }

  get state():
      GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState
      |null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * State of CreateConversationModel operation.
   */
  set state(
      value:
          GoogleCloudDialogflowV2CreateConversationModelOperationMetadataState|
      null) {
    this.Serializable$set('state', value);
  }

  get summarizationModelTrainingMetadata():
      GoogleCloudDialogflowV2SummarizationModelTrainingMetadata|null {
    return (
        (this.Serializable$has('summarizationModelTrainingMetadata')) ?
            (this.Serializable$get('summarizationModelTrainingMetadata')) :
            (null));
  }

  /**
   * Information generated during summarization model training process.
   */
  set summarizationModelTrainingMetadata(
      value: GoogleCloudDialogflowV2SummarizationModelTrainingMetadata|null) {
    this.Serializable$set('summarizationModelTrainingMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2CreateConversationModelOperationMetadata> {
    return GoogleCloudDialogflowV2CreateConversationModelOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state':
            GoogleCloudDialogflowV2CreateConversationModelOperationMetadataStateEnum
      },
      keys: [
        'conversationModel', 'createTime', 'genericMetadata', 'queuePosition',
        'state', 'summarizationModelTrainingMetadata'
      ],
      objects: {
        'genericMetadata': GoogleCloudDialogflowV2GenericOperationMetadata,
        'summarizationModelTrainingMetadata':
            GoogleCloudDialogflowV2SummarizationModelTrainingMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowV2DeleteConversationAnnotationOperationMetadataParameters {
}
export class
    GoogleCloudDialogflowV2DeleteConversationAnnotationOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2DeleteConversationAnnotationOperationMetadataParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2DeleteConversationAnnotationOperationMetadata> {
    return GoogleCloudDialogflowV2DeleteConversationAnnotationOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV2DeleteConversationDatasetOperationMetadataParameters {
}
export class
    GoogleCloudDialogflowV2DeleteConversationDatasetOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2DeleteConversationDatasetOperationMetadataParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2DeleteConversationDatasetOperationMetadata> {
    return GoogleCloudDialogflowV2DeleteConversationDatasetOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV2DeleteConversationModelOperationMetadataParameters {
  conversationModel?: string|null;
  createTime?: string|null;
}
export class
    GoogleCloudDialogflowV2DeleteConversationModelOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2DeleteConversationModelOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when delete conversation model request was created. The time is
   * measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2DeleteConversationModelOperationMetadata> {
    return GoogleCloudDialogflowV2DeleteConversationModelOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationModel', 'createTime']};
  }
}

export interface GoogleCloudDialogflowV2DeployConversationModelOperationMetadataParameters {
  conversationModel?: string|null;
  createTime?: string|null;
}
export class
    GoogleCloudDialogflowV2DeployConversationModelOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2DeployConversationModelOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when request to deploy conversation model was submitted. The time
   * is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2DeployConversationModelOperationMetadata> {
    return GoogleCloudDialogflowV2DeployConversationModelOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationModel', 'createTime']};
  }
}

export interface GoogleCloudDialogflowV2EntityTypeParameters {
  name?: string|null;
  displayName?: string|null;
  kind?: GoogleCloudDialogflowV2EntityTypeKind|null;
  autoExpansionMode?: GoogleCloudDialogflowV2EntityTypeAutoExpansionMode|null;
  disallowModificationsBySessionEntityTypes?: boolean|null;
  entities?: Array<GoogleCloudDialogflowV2EntityTypeEntity>|null;
  enableFuzzyExtraction?: boolean|null;
}
export class GoogleCloudDialogflowV2EntityType extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2EntityTypeParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'kind', (parameters.kind == null) ? (null) : (parameters.kind));
    this.Serializable$set(
        'autoExpansionMode',
        (parameters.autoExpansionMode == null) ?
            (null) :
            (parameters.autoExpansionMode));
    this.Serializable$set(
        'disallowModificationsBySessionEntityTypes',
        (parameters.disallowModificationsBySessionEntityTypes == null) ?
            (null) :
            (parameters.disallowModificationsBySessionEntityTypes));
    this.Serializable$set(
        'entities',
        (parameters.entities == null) ? (null) : (parameters.entities));
    this.Serializable$set(
        'enableFuzzyExtraction',
        (parameters.enableFuzzyExtraction == null) ?
            (null) :
            (parameters.enableFuzzyExtraction));
  }

  static get AutoExpansionMode():
      IGoogleCloudDialogflowV2EntityTypeAutoExpansionModeEnum {
    return GoogleCloudDialogflowV2EntityTypeAutoExpansionModeEnum;
  }

  static get Kind(): IGoogleCloudDialogflowV2EntityTypeKindEnum {
    return GoogleCloudDialogflowV2EntityTypeKindEnum;
  }

  get autoExpansionMode(): GoogleCloudDialogflowV2EntityTypeAutoExpansionMode
      |null {
    return (
        (this.Serializable$has('autoExpansionMode')) ?
            (this.Serializable$get('autoExpansionMode')) :
            (null));
  }

  /**
   * Optional. Indicates whether the entity type can be automatically expanded.
   */
  set autoExpansionMode(value:
                            GoogleCloudDialogflowV2EntityTypeAutoExpansionMode|
                        null) {
    this.Serializable$set('autoExpansionMode', value);
  }

  get disallowModificationsBySessionEntityTypes(): boolean|null {
    return (
        (this.Serializable$has('disallowModificationsBySessionEntityTypes')) ?
            (this.Serializable$get(
                'disallowModificationsBySessionEntityTypes')) :
            (null));
  }

  /**
   * Required. Indicates whether the custom entity type can be extended or
   * overridden by session entity types.
   */
  set disallowModificationsBySessionEntityTypes(value: boolean|null) {
    this.Serializable$set('disallowModificationsBySessionEntityTypes', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The name of the entity type.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get enableFuzzyExtraction(): boolean|null {
    return (
        (this.Serializable$has('enableFuzzyExtraction')) ?
            (this.Serializable$get('enableFuzzyExtraction')) :
            (null));
  }

  /**
   * Optional. Enables fuzzy entity extraction during classification.
   */
  set enableFuzzyExtraction(value: boolean|null) {
    this.Serializable$set('enableFuzzyExtraction', value);
  }

  get entities(): Array<GoogleCloudDialogflowV2EntityTypeEntity>|null {
    return (
        (this.Serializable$has('entities')) ?
            (this.Serializable$get('entities')) :
            (null));
  }

  /**
   * Optional. The collection of entity entries associated with the entity type.
   */
  set entities(value: Array<GoogleCloudDialogflowV2EntityTypeEntity>|null) {
    this.Serializable$set('entities', value);
  }

  get kind(): GoogleCloudDialogflowV2EntityTypeKind|null {
    return (
        (this.Serializable$has('kind')) ? (this.Serializable$get('kind')) :
                                          (null));
  }

  /**
   * Required. Indicates the kind of entity type.
   */
  set kind(value: GoogleCloudDialogflowV2EntityTypeKind|null) {
    this.Serializable$set('kind', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the entity type. Required for
   * EntityTypes.UpdateEntityType and EntityTypes.BatchUpdateEntityTypes
   * methods. Format: `projects//agent/entityTypes/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2EntityType> {
    return GoogleCloudDialogflowV2EntityType;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'entities': GoogleCloudDialogflowV2EntityTypeEntity},
      enums: {
        'autoExpansionMode':
            GoogleCloudDialogflowV2EntityTypeAutoExpansionModeEnum,
        'kind': GoogleCloudDialogflowV2EntityTypeKindEnum
      },
      keys: [
        'autoExpansionMode', 'disallowModificationsBySessionEntityTypes',
        'displayName', 'enableFuzzyExtraction', 'entities', 'kind', 'name'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV2EntityTypeEntityParameters {
  value?: string|null;
  synonyms?: Array<string>|null;
}
export class GoogleCloudDialogflowV2EntityTypeEntity extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2EntityTypeEntityParameters = {}) {
    super();
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
    this.Serializable$set(
        'synonyms',
        (parameters.synonyms == null) ? (null) : (parameters.synonyms));
  }

  get synonyms(): Array<string>|null {
    return (
        (this.Serializable$has('synonyms')) ?
            (this.Serializable$get('synonyms')) :
            (null));
  }

  /**
   * Required. A collection of value synonyms. For example, if the entity type
   * is *vegetable*, and `value` is *scallions*, a synonym could be *green
   * onions*. For `KIND_LIST` entity types: * This collection must contain
   * exactly one synonym equal to `value`.
   */
  set synonyms(value: Array<string>|null) {
    this.Serializable$set('synonyms', value);
  }

  get value(): string|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Required. The primary value associated with this entity entry. For example,
   * if the entity type is *vegetable*, the value could be *scallions*. For
   * `KIND_MAP` entity types: * A reference value to be used in place of
   * synonyms. For `KIND_LIST` entity types: * A string that can contain
   * references to other entity types (with or without aliases).
   */
  set value(value: string|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2EntityTypeEntity> {
    return GoogleCloudDialogflowV2EntityTypeEntity;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['synonyms', 'value']};
  }
}

export interface GoogleCloudDialogflowV2EventInputParameters {
  name?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
  languageCode?: string|null;
}
export class GoogleCloudDialogflowV2EventInput extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2EventInputParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of this query. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes. Note that queries in the
   * same session do not necessarily need to specify the same language. This
   * field is ignored when used in the context of a
   * WebhookResponse.followup_event_input field, because the language was
   * already defined in the originating detect intent request.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of the event.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of parameters associated with the event. Depending on your
   * protocol or client library language, this is a map, associative array,
   * symbol table, dictionary, or JSON object composed of a collection of
   * (MapKey, MapValue) pairs: - MapKey type: string - MapKey value: parameter
   * name - MapValue type: - If parameter's entity type is a composite entity:
   * map - Else: depending on parameter value type, could be one of string,
   * number, boolean, null, list or map - MapValue value: - If parameter's
   * entity type is a composite entity: map from composite entity property names
   * to property values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2EventInput> {
    return GoogleCloudDialogflowV2EventInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['languageCode', 'name', 'parameters'],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV2ExportAgentResponseParameters {
  agentUri?: string|null;
  agentContent?: string|null;
}
export class GoogleCloudDialogflowV2ExportAgentResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2ExportAgentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'agentUri',
        (parameters.agentUri == null) ? (null) : (parameters.agentUri));
    this.Serializable$set(
        'agentContent',
        (parameters.agentContent == null) ? (null) : (parameters.agentContent));
  }

  get agentContent(): string|null {
    return (
        (this.Serializable$has('agentContent')) ?
            (this.Serializable$get('agentContent')) :
            (null));
  }

  /**
   * Zip compressed raw byte content for agent.
   */
  set agentContent(value: string|null) {
    this.Serializable$set('agentContent', value);
  }

  get agentUri(): string|null {
    return (
        (this.Serializable$has('agentUri')) ?
            (this.Serializable$get('agentUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported agent. This field is populated
   * only if `agent_uri` is specified in `ExportAgentRequest`.
   */
  set agentUri(value: string|null) {
    this.Serializable$set('agentUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2ExportAgentResponse> {
    return GoogleCloudDialogflowV2ExportAgentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agentContent', 'agentUri']};
  }
}

export interface GoogleCloudDialogflowV2ExportOperationMetadataParameters {
  exportedGcsDestination?: GoogleCloudDialogflowV2GcsDestination|null;
}
export class GoogleCloudDialogflowV2ExportOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2ExportOperationMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'exportedGcsDestination',
        (parameters.exportedGcsDestination == null) ?
            (null) :
            (parameters.exportedGcsDestination));
  }

  get exportedGcsDestination(): GoogleCloudDialogflowV2GcsDestination|null {
    return (
        (this.Serializable$has('exportedGcsDestination')) ?
            (this.Serializable$get('exportedGcsDestination')) :
            (null));
  }

  /**
   * Cloud Storage file path of the exported data.
   */
  set exportedGcsDestination(value: GoogleCloudDialogflowV2GcsDestination|
                             null) {
    this.Serializable$set('exportedGcsDestination', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2ExportOperationMetadata> {
    return GoogleCloudDialogflowV2ExportOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['exportedGcsDestination'],
      objects: {'exportedGcsDestination': GoogleCloudDialogflowV2GcsDestination}
    };
  }
}

export interface GoogleCloudDialogflowV2FaqAnswerParameters {
  answer?: string|null;
  confidence?: number|null;
  question?: string|null;
  source?: string|null;
  metadata?: ApiClientObjectMap<string>|null;
  answerRecord?: string|null;
}
export class GoogleCloudDialogflowV2FaqAnswer extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2FaqAnswerParameters = {}) {
    super();
    this.Serializable$set(
        'answer', (parameters.answer == null) ? (null) : (parameters.answer));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'question',
        (parameters.question == null) ? (null) : (parameters.question));
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answer(): string|null {
    return (
        (this.Serializable$has('answer')) ? (this.Serializable$get('answer')) :
                                            (null));
  }

  /**
   * The piece of text from the `source` knowledge base document.
   */
  set answer(value: string|null) {
    this.Serializable$set('answer', value);
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The system's confidence score that this Knowledge answer is a good match
   * for this conversational query, range from 0.0 (completely uncertain) to 1.0
   * (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get metadata(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * A map that contains metadata about the answer and the document from which
   * it originates.
   */
  set metadata(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('metadata', value);
  }

  get question(): string|null {
    return (
        (this.Serializable$has('question')) ?
            (this.Serializable$get('question')) :
            (null));
  }

  /**
   * The corresponding FAQ question.
   */
  set question(value: string|null) {
    this.Serializable$set('question', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * Indicates which Knowledge Document this answer was extracted from. Format:
   * `projects//locations//agent/knowledgeBases//documents/`.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2FaqAnswer> {
    return GoogleCloudDialogflowV2FaqAnswer;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'answer', 'answerRecord', 'confidence', 'metadata', 'question', 'source'
      ],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV2GcsDestinationParameters {
  uri?: string|null;
}
export class GoogleCloudDialogflowV2GcsDestination extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2GcsDestinationParameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * The Google Cloud Storage URIs for the output. A URI is of the form:
   * gs://bucket/object-prefix-or-name Whether a prefix or name is used depends
   * on the use case. The requesting user must have \"write-permission\" to the
   * bucket.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2GcsDestination> {
    return GoogleCloudDialogflowV2GcsDestination;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface GoogleCloudDialogflowV2GenericOperationMetadataParameters {
  detailedState?: string|null;
}
export class GoogleCloudDialogflowV2GenericOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2GenericOperationMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'detailedState',
        (parameters.detailedState == null) ? (null) :
                                             (parameters.detailedState));
  }

  get detailedState(): string|null {
    return (
        (this.Serializable$has('detailedState')) ?
            (this.Serializable$get('detailedState')) :
            (null));
  }

  /**
   * Detailed job state used by UI to render details for user. For example
   * during TRAINING state, we will have three detailed state. Pre-processing,
   * Model Training, Post-processing.
   */
  set detailedState(value: string|null) {
    this.Serializable$set('detailedState', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2GenericOperationMetadata> {
    return GoogleCloudDialogflowV2GenericOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['detailedState']};
  }
}

export interface GoogleCloudDialogflowV2HumanAgentAssistantEventParameters {
  conversation?: string|null;
  participant?: string|null;
  suggestionResults?: Array<GoogleCloudDialogflowV2SuggestionResult>|null;
}
export class GoogleCloudDialogflowV2HumanAgentAssistantEvent extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2HumanAgentAssistantEventParameters = {}) {
    super();
    this.Serializable$set(
        'conversation',
        (parameters.conversation == null) ? (null) : (parameters.conversation));
    this.Serializable$set(
        'participant',
        (parameters.participant == null) ? (null) : (parameters.participant));
    this.Serializable$set(
        'suggestionResults',
        (parameters.suggestionResults == null) ?
            (null) :
            (parameters.suggestionResults));
  }

  get conversation(): string|null {
    return (
        (this.Serializable$has('conversation')) ?
            (this.Serializable$get('conversation')) :
            (null));
  }

  /**
   * The conversation this notification refers to. Format:
   * `projects//conversations/`.
   */
  set conversation(value: string|null) {
    this.Serializable$set('conversation', value);
  }

  get participant(): string|null {
    return (
        (this.Serializable$has('participant')) ?
            (this.Serializable$get('participant')) :
            (null));
  }

  /**
   * The participant that the suggestion is compiled for. Format:
   * `projects//conversations//participants/`. It will not be set in legacy
   * workflow.
   */
  set participant(value: string|null) {
    this.Serializable$set('participant', value);
  }

  get suggestionResults(): Array<GoogleCloudDialogflowV2SuggestionResult>|null {
    return (
        (this.Serializable$has('suggestionResults')) ?
            (this.Serializable$get('suggestionResults')) :
            (null));
  }

  /**
   * The suggestion results payload that this notification refers to.
   */
  set suggestionResults(value: Array<GoogleCloudDialogflowV2SuggestionResult>|
                        null) {
    this.Serializable$set('suggestionResults', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2HumanAgentAssistantEvent> {
    return GoogleCloudDialogflowV2HumanAgentAssistantEvent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'suggestionResults': GoogleCloudDialogflowV2SuggestionResult},
      keys: ['conversation', 'participant', 'suggestionResults']
    };
  }
}

export interface GoogleCloudDialogflowV2ImportConversationDataOperationMetadataParameters {
  conversationDataset?: string|null;
  partialFailures?: Array<GoogleRpcStatus>|null;
  createTime?: string|null;
  endTime?: string|null;
}
export class
    GoogleCloudDialogflowV2ImportConversationDataOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2ImportConversationDataOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'conversationDataset',
        (parameters.conversationDataset == null) ?
            (null) :
            (parameters.conversationDataset));
    this.Serializable$set(
        'partialFailures',
        (parameters.partialFailures == null) ? (null) :
                                               (parameters.partialFailures));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'endTime',
        (parameters.endTime == null) ? (null) : (parameters.endTime));
  }

  get conversationDataset(): string|null {
    return (
        (this.Serializable$has('conversationDataset')) ?
            (this.Serializable$get('conversationDataset')) :
            (null));
  }

  /**
   * The resource name of the imported conversation dataset. Format:
   * `projects//locations//conversationDatasets/`
   */
  set conversationDataset(value: string|null) {
    this.Serializable$set('conversationDataset', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when import conversation data request was created. The time is
   * measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get endTime(): string|null {
    return (
        (this.Serializable$has('endTime')) ?
            (this.Serializable$get('endTime')) :
            (null));
  }

  /**
   * Timestamp when import conversation data job finished. The time is measured
   * on server side.
   */
  set endTime(value: string|null) {
    this.Serializable$set('endTime', value);
  }

  get partialFailures(): Array<GoogleRpcStatus>|null {
    return (
        (this.Serializable$has('partialFailures')) ?
            (this.Serializable$get('partialFailures')) :
            (null));
  }

  /**
   * Partial failures are failures that don't fail the whole long running
   * operation, e.g. single files that couldn't be read.
   */
  set partialFailures(value: Array<GoogleRpcStatus>|null) {
    this.Serializable$set('partialFailures', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2ImportConversationDataOperationMetadata> {
    return GoogleCloudDialogflowV2ImportConversationDataOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'partialFailures': GoogleRpcStatus},
      keys: ['conversationDataset', 'createTime', 'endTime', 'partialFailures']
    };
  }
}

export interface GoogleCloudDialogflowV2ImportConversationDataOperationResponseParameters {
  conversationDataset?: string|null;
  importCount?: number|null;
}
export class
    GoogleCloudDialogflowV2ImportConversationDataOperationResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2ImportConversationDataOperationResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'conversationDataset',
        (parameters.conversationDataset == null) ?
            (null) :
            (parameters.conversationDataset));
    this.Serializable$set(
        'importCount',
        (parameters.importCount == null) ? (null) : (parameters.importCount));
  }

  get conversationDataset(): string|null {
    return (
        (this.Serializable$has('conversationDataset')) ?
            (this.Serializable$get('conversationDataset')) :
            (null));
  }

  /**
   * The resource name of the imported conversation dataset. Format:
   * `projects//locations//conversationDatasets/`
   */
  set conversationDataset(value: string|null) {
    this.Serializable$set('conversationDataset', value);
  }

  get importCount(): number|null {
    return (
        (this.Serializable$has('importCount')) ?
            (this.Serializable$get('importCount')) :
            (null));
  }

  /**
   * Number of conversations imported successfully.
   */
  set importCount(value: number|null) {
    this.Serializable$set('importCount', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2ImportConversationDataOperationResponse> {
    return GoogleCloudDialogflowV2ImportConversationDataOperationResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationDataset', 'importCount']};
  }
}

export interface GoogleCloudDialogflowV2ImportDocumentsResponseParameters {
  warnings?: Array<GoogleRpcStatus>|null;
}
export class GoogleCloudDialogflowV2ImportDocumentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2ImportDocumentsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'warnings',
        (parameters.warnings == null) ? (null) : (parameters.warnings));
  }

  get warnings(): Array<GoogleRpcStatus>|null {
    return (
        (this.Serializable$has('warnings')) ?
            (this.Serializable$get('warnings')) :
            (null));
  }

  /**
   * Includes details about skipped documents or any other warnings.
   */
  set warnings(value: Array<GoogleRpcStatus>|null) {
    this.Serializable$set('warnings', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2ImportDocumentsResponse> {
    return GoogleCloudDialogflowV2ImportDocumentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'warnings': GoogleRpcStatus}, keys: ['warnings']};
  }
}

export interface GoogleCloudDialogflowV2InitiateConversationMetadataParameters {
}
export class GoogleCloudDialogflowV2InitiateConversationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2InitiateConversationMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2InitiateConversationMetadata> {
    return GoogleCloudDialogflowV2InitiateConversationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV2InitiateConversationResponseParameters {
  conversation?: string|null;
}
export class GoogleCloudDialogflowV2InitiateConversationResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2InitiateConversationResponseParameters = {}) {
    super();
    this.Serializable$set(
        'conversation',
        (parameters.conversation == null) ? (null) : (parameters.conversation));
  }

  get conversation(): string|null {
    return (
        (this.Serializable$has('conversation')) ?
            (this.Serializable$get('conversation')) :
            (null));
  }

  /**
   * Name of the conversation created.
   */
  set conversation(value: string|null) {
    this.Serializable$set('conversation', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2InitiateConversationResponse> {
    return GoogleCloudDialogflowV2InitiateConversationResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversation']};
  }
}

export interface GoogleCloudDialogflowV2InputDatasetParameters {
  dataset?: string|null;
  dataFilePattern?: string|null;
  filter?: string|null;
}
export class GoogleCloudDialogflowV2InputDataset extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2InputDatasetParameters = {}) {
    super();
    this.Serializable$set(
        'dataset',
        (parameters.dataset == null) ? (null) : (parameters.dataset));
    this.Serializable$set(
        'dataFilePattern',
        (parameters.dataFilePattern == null) ? (null) :
                                               (parameters.dataFilePattern));
    this.Serializable$set(
        'filter', (parameters.filter == null) ? (null) : (parameters.filter));
  }

  get dataFilePattern(): string|null {
    return (
        (this.Serializable$has('dataFilePattern')) ?
            (this.Serializable$get('dataFilePattern')) :
            (null));
  }

  /**
   * Optional. File(s) that contain raw training data from internal file system.
   * Each file should be in cloud.ml.MLRecord recordio format, and contains
   * ml_data.conversation_data. Usage instruction, go/ccai-train-from-file.
   */
  set dataFilePattern(value: string|null) {
    this.Serializable$set('dataFilePattern', value);
  }

  get dataset(): string|null {
    return (
        (this.Serializable$has('dataset')) ?
            (this.Serializable$get('dataset')) :
            (null));
  }

  /**
   * Required. ConversationDataset resource name. Format:
   * `projects//locations//conversationDatasets/`
   */
  set dataset(value: string|null) {
    this.Serializable$set('dataset', value);
  }

  get filter(): string|null {
    return (
        (this.Serializable$has('filter')) ? (this.Serializable$get('filter')) :
                                            (null));
  }

  /**
   * Optional. Filter on annotations under the dataset when annotations are
   * needed for training. If unspecified, use all annotations under the
   * specified dataset. Currently predicts on
   * `annotated_conversation_dataset_id`. For example:
   * `annotated_conversation_dataset_id = [annotated conversation dataset id]`
   * For more information about filtering, see [API
   * Filtering](https://aip.dev/160).
   */
  set filter(value: string|null) {
    this.Serializable$set('filter', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2InputDataset> {
    return GoogleCloudDialogflowV2InputDataset;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['dataFilePattern', 'dataset', 'filter']};
  }
}

export interface GoogleCloudDialogflowV2IntentParameters {
  name?: string|null;
  displayName?: string|null;
  webhookState?: GoogleCloudDialogflowV2IntentWebhookState|null;
  priority?: number|null;
  isFallback?: boolean|null;
  mlDisabled?: boolean|null;
  liveAgentHandoff?: boolean|null;
  endInteraction?: boolean|null;
  inputContextNames?: Array<string>|null;
  events?: Array<string>|null;
  trainingPhrases?: Array<GoogleCloudDialogflowV2IntentTrainingPhrase>|null;
  action?: string|null;
  outputContexts?: Array<GoogleCloudDialogflowV2Context>|null;
  resetContexts?: boolean|null;
  parameters?: Array<GoogleCloudDialogflowV2IntentParameter>|null;
  messages?: Array<GoogleCloudDialogflowV2IntentMessage>|null;
  defaultResponsePlatforms?:
      Array<GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>|null;
  rootFollowupIntentName?: string|null;
  parentFollowupIntentName?: string|null;
  followupIntentInfo?: Array<GoogleCloudDialogflowV2IntentFollowupIntentInfo>|
      null;
  followupEventInput?: GoogleCloudDialogflowV2IntentFollowupEventInput|null;
}
export class GoogleCloudDialogflowV2Intent extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2IntentParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'webhookState',
        (parameters.webhookState == null) ? (null) : (parameters.webhookState));
    this.Serializable$set(
        'priority',
        (parameters.priority == null) ? (null) : (parameters.priority));
    this.Serializable$set(
        'isFallback',
        (parameters.isFallback == null) ? (null) : (parameters.isFallback));
    this.Serializable$set(
        'mlDisabled',
        (parameters.mlDisabled == null) ? (null) : (parameters.mlDisabled));
    this.Serializable$set(
        'liveAgentHandoff',
        (parameters.liveAgentHandoff == null) ? (null) :
                                                (parameters.liveAgentHandoff));
    this.Serializable$set(
        'endInteraction',
        (parameters.endInteraction == null) ? (null) :
                                              (parameters.endInteraction));
    this.Serializable$set(
        'inputContextNames',
        (parameters.inputContextNames == null) ?
            (null) :
            (parameters.inputContextNames));
    this.Serializable$set(
        'events', (parameters.events == null) ? (null) : (parameters.events));
    this.Serializable$set(
        'trainingPhrases',
        (parameters.trainingPhrases == null) ? (null) :
                                               (parameters.trainingPhrases));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'outputContexts',
        (parameters.outputContexts == null) ? (null) :
                                              (parameters.outputContexts));
    this.Serializable$set(
        'resetContexts',
        (parameters.resetContexts == null) ? (null) :
                                             (parameters.resetContexts));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'defaultResponsePlatforms',
        (parameters.defaultResponsePlatforms == null) ?
            (null) :
            (parameters.defaultResponsePlatforms));
    this.Serializable$set(
        'rootFollowupIntentName',
        (parameters.rootFollowupIntentName == null) ?
            (null) :
            (parameters.rootFollowupIntentName));
    this.Serializable$set(
        'parentFollowupIntentName',
        (parameters.parentFollowupIntentName == null) ?
            (null) :
            (parameters.parentFollowupIntentName));
    this.Serializable$set(
        'followupIntentInfo',
        (parameters.followupIntentInfo == null) ?
            (null) :
            (parameters.followupIntentInfo));
    this.Serializable$set(
        'followupEventInput',
        (parameters.followupEventInput == null) ?
            (null) :
            (parameters.followupEventInput));
  }

  static get DefaultResponsePlatforms():
      IGoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum {
    return GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum;
  }

  static get WebhookState(): IGoogleCloudDialogflowV2IntentWebhookStateEnum {
    return GoogleCloudDialogflowV2IntentWebhookStateEnum;
  }

  get action(): string|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Optional. The name of the action associated with the intent. Note: The
   * action name must not contain whitespaces.
   */
  set action(value: string|null) {
    this.Serializable$set('action', value);
  }

  get defaultResponsePlatforms():
      Array<GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>|null {
    return (
        (this.Serializable$has('defaultResponsePlatforms')) ?
            (this.Serializable$get('defaultResponsePlatforms')) :
            (null));
  }

  /**
   * Optional. The list of platforms for which the first responses will be
   * copied from the messages in PLATFORM_UNSPECIFIED (i.e. default platform).
   */
  set defaultResponsePlatforms(
      value: Array<GoogleCloudDialogflowV2IntentDefaultResponsePlatforms>|
      null) {
    this.Serializable$set('defaultResponsePlatforms', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The name of this intent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get endInteraction(): boolean|null {
    return (
        (this.Serializable$has('endInteraction')) ?
            (this.Serializable$get('endInteraction')) :
            (null));
  }

  /**
   * Optional. Indicates that this intent ends an interaction. Some integrations
   * (e.g., Actions on Google or Dialogflow phone gateway) use this information
   * to close interaction with an end user. Default is false.
   */
  set endInteraction(value: boolean|null) {
    this.Serializable$set('endInteraction', value);
  }

  get events(): Array<string>|null {
    return (
        (this.Serializable$has('events')) ? (this.Serializable$get('events')) :
                                            (null));
  }

  /**
   * Optional. The collection of event names that trigger the intent. If the
   * collection of input contexts is not empty, all of the contexts must be
   * present in the active user session for an event to trigger this intent.
   * Event names are limited to 150 characters.
   */
  set events(value: Array<string>|null) {
    this.Serializable$set('events', value);
  }

  get followupEventInput(): GoogleCloudDialogflowV2IntentFollowupEventInput
      |null {
    return (
        (this.Serializable$has('followupEventInput')) ?
            (this.Serializable$get('followupEventInput')) :
            (null));
  }

  /**
   * Optional. Makes the platform immediately invoke another `DetectIntent` call
   * internally with the specified event as input.
   */
  set followupEventInput(value: GoogleCloudDialogflowV2IntentFollowupEventInput|
                         null) {
    this.Serializable$set('followupEventInput', value);
  }

  get followupIntentInfo():
      Array<GoogleCloudDialogflowV2IntentFollowupIntentInfo>|null {
    return (
        (this.Serializable$has('followupIntentInfo')) ?
            (this.Serializable$get('followupIntentInfo')) :
            (null));
  }

  /**
   * Output only. Read-only. Information about all followup intents that have
   * this intent as a direct or indirect parent. We populate this field only in
   * the output.
   */
  set followupIntentInfo(
      value: Array<GoogleCloudDialogflowV2IntentFollowupIntentInfo>|null) {
    this.Serializable$set('followupIntentInfo', value);
  }

  get inputContextNames(): Array<string>|null {
    return (
        (this.Serializable$has('inputContextNames')) ?
            (this.Serializable$get('inputContextNames')) :
            (null));
  }

  /**
   * Optional. The list of context names required for this intent to be
   * triggered. Format: `projects//agent/sessions/-/contexts/`.
   */
  set inputContextNames(value: Array<string>|null) {
    this.Serializable$set('inputContextNames', value);
  }

  get isFallback(): boolean|null {
    return (
        (this.Serializable$has('isFallback')) ?
            (this.Serializable$get('isFallback')) :
            (null));
  }

  /**
   * Optional. Indicates whether this is a fallback intent.
   */
  set isFallback(value: boolean|null) {
    this.Serializable$set('isFallback', value);
  }

  get liveAgentHandoff(): boolean|null {
    return (
        (this.Serializable$has('liveAgentHandoff')) ?
            (this.Serializable$get('liveAgentHandoff')) :
            (null));
  }

  /**
   * Optional. Indicates that a live agent should be brought in to handle the
   * interaction with the user. In most cases, when you set this flag to true,
   * you would also want to set end_interaction to true as well. Default is
   * false.
   */
  set liveAgentHandoff(value: boolean|null) {
    this.Serializable$set('liveAgentHandoff', value);
  }

  get messages(): Array<GoogleCloudDialogflowV2IntentMessage>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * Optional. The collection of rich messages corresponding to the `Response`
   * field in the Dialogflow console.
   */
  set messages(value: Array<GoogleCloudDialogflowV2IntentMessage>|null) {
    this.Serializable$set('messages', value);
  }

  get mlDisabled(): boolean|null {
    return (
        (this.Serializable$has('mlDisabled')) ?
            (this.Serializable$get('mlDisabled')) :
            (null));
  }

  /**
   * Optional. Indicates whether Machine Learning is disabled for the intent.
   * Note: If `ml_disabled` setting is set to true, then this intent is not
   * taken into account during inference in `ML ONLY` match mode. Also,
   * auto-markup in the UI is turned off.
   */
  set mlDisabled(value: boolean|null) {
    this.Serializable$set('mlDisabled', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Optional. The unique identifier of this intent. Required for
   * Intents.UpdateIntent and Intents.BatchUpdateIntents methods. Format:
   * `projects//agent/intents/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get outputContexts(): Array<GoogleCloudDialogflowV2Context>|null {
    return (
        (this.Serializable$has('outputContexts')) ?
            (this.Serializable$get('outputContexts')) :
            (null));
  }

  /**
   * Optional. The collection of contexts that are activated when the intent is
   * matched. Context messages in this collection should not set the parameters
   * field. Setting the `lifespan_count` to 0 will reset the context when the
   * intent is matched. Format: `projects//agent/sessions/-/contexts/`.
   */
  set outputContexts(value: Array<GoogleCloudDialogflowV2Context>|null) {
    this.Serializable$set('outputContexts', value);
  }

  get parameters(): Array<GoogleCloudDialogflowV2IntentParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional. The collection of parameters associated with the intent.
   */
  set parameters(value: Array<GoogleCloudDialogflowV2IntentParameter>|null) {
    this.Serializable$set('parameters', value);
  }

  get parentFollowupIntentName(): string|null {
    return (
        (this.Serializable$has('parentFollowupIntentName')) ?
            (this.Serializable$get('parentFollowupIntentName')) :
            (null));
  }

  /**
   * Read-only after creation. The unique identifier of the parent intent in the
   * chain of followup intents. You can set this field when creating an intent,
   * for example with CreateIntent or BatchUpdateIntents, in order to make this
   * intent a followup intent. It identifies the parent followup intent. Format:
   * `projects//agent/intents/`.
   */
  set parentFollowupIntentName(value: string|null) {
    this.Serializable$set('parentFollowupIntentName', value);
  }

  get priority(): number|null {
    return (
        (this.Serializable$has('priority')) ?
            (this.Serializable$get('priority')) :
            (null));
  }

  /**
   * Optional. The priority of this intent. Higher numbers represent higher
   * priorities. - If the supplied value is unspecified or 0, the service
   * translates the value to 500,000, which corresponds to the `Normal` priority
   * in the console. - If the supplied value is negative, the intent is ignored
   * in runtime detect intent requests.
   */
  set priority(value: number|null) {
    this.Serializable$set('priority', value);
  }

  get resetContexts(): boolean|null {
    return (
        (this.Serializable$has('resetContexts')) ?
            (this.Serializable$get('resetContexts')) :
            (null));
  }

  /**
   * Optional. Indicates whether to delete all contexts in the current session
   * when this intent is matched.
   */
  set resetContexts(value: boolean|null) {
    this.Serializable$set('resetContexts', value);
  }

  get rootFollowupIntentName(): string|null {
    return (
        (this.Serializable$has('rootFollowupIntentName')) ?
            (this.Serializable$get('rootFollowupIntentName')) :
            (null));
  }

  /**
   * Output only. Read-only. The unique identifier of the root intent in the
   * chain of followup intents. It identifies the correct followup intents chain
   * for this intent. We populate this field only in the output. Format:
   * `projects//agent/intents/`.
   */
  set rootFollowupIntentName(value: string|null) {
    this.Serializable$set('rootFollowupIntentName', value);
  }

  get trainingPhrases():
      Array<GoogleCloudDialogflowV2IntentTrainingPhrase>|null {
    return (
        (this.Serializable$has('trainingPhrases')) ?
            (this.Serializable$get('trainingPhrases')) :
            (null));
  }

  /**
   * Optional. The collection of examples that the agent is trained on.
   */
  set trainingPhrases(value: Array<GoogleCloudDialogflowV2IntentTrainingPhrase>|
                      null) {
    this.Serializable$set('trainingPhrases', value);
  }

  get webhookState(): GoogleCloudDialogflowV2IntentWebhookState|null {
    return (
        (this.Serializable$has('webhookState')) ?
            (this.Serializable$get('webhookState')) :
            (null));
  }

  /**
   * Optional. Indicates whether webhooks are enabled for the intent.
   */
  set webhookState(value: GoogleCloudDialogflowV2IntentWebhookState|null) {
    this.Serializable$set('webhookState', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2Intent> {
    return GoogleCloudDialogflowV2Intent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'followupIntentInfo': GoogleCloudDialogflowV2IntentFollowupIntentInfo,
        'messages': GoogleCloudDialogflowV2IntentMessage,
        'outputContexts': GoogleCloudDialogflowV2Context,
        'parameters': GoogleCloudDialogflowV2IntentParameter,
        'trainingPhrases': GoogleCloudDialogflowV2IntentTrainingPhrase
      },
      enums: {
        'defaultResponsePlatforms':
            GoogleCloudDialogflowV2IntentDefaultResponsePlatformsEnum,
        'webhookState': GoogleCloudDialogflowV2IntentWebhookStateEnum
      },
      keys: [
        'action',
        'defaultResponsePlatforms',
        'displayName',
        'endInteraction',
        'events',
        'followupEventInput',
        'followupIntentInfo',
        'inputContextNames',
        'isFallback',
        'liveAgentHandoff',
        'messages',
        'mlDisabled',
        'name',
        'outputContexts',
        'parameters',
        'parentFollowupIntentName',
        'priority',
        'resetContexts',
        'rootFollowupIntentName',
        'trainingPhrases',
        'webhookState'
      ],
      objects: {
        'followupEventInput': GoogleCloudDialogflowV2IntentFollowupEventInput
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IntentFollowupEventInputParameters {
  name?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
}
export class GoogleCloudDialogflowV2IntentFollowupEventInput extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentFollowupEventInputParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of the event. It's not a qualified resource
   * and thus can be an arbitrary string.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional. The collection of parameters associated with the event. Depending
   * on your protocol or client library language, this is a map, associative
   * array, symbol table, dictionary, or JSON object composed of a collection of
   * (MapKey, MapValue) pairs: - MapKey type: string - MapKey value: parameter
   * name - MapValue type: - If parameter's entity type is a composite entity:
   * map - Else: depending on parameter value type, could be one of string,
   * number, boolean, null, list or map - MapValue value: - If parameter's
   * entity type is a composite entity: map from composite entity property names
   * to property values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentFollowupEventInput> {
    return GoogleCloudDialogflowV2IntentFollowupEventInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['name', 'parameters'],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IntentFollowupIntentInfoParameters {
  followupIntentName?: string|null;
  parentFollowupIntentName?: string|null;
}
export class GoogleCloudDialogflowV2IntentFollowupIntentInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentFollowupIntentInfoParameters = {}) {
    super();
    this.Serializable$set(
        'followupIntentName',
        (parameters.followupIntentName == null) ?
            (null) :
            (parameters.followupIntentName));
    this.Serializable$set(
        'parentFollowupIntentName',
        (parameters.parentFollowupIntentName == null) ?
            (null) :
            (parameters.parentFollowupIntentName));
  }

  get followupIntentName(): string|null {
    return (
        (this.Serializable$has('followupIntentName')) ?
            (this.Serializable$get('followupIntentName')) :
            (null));
  }

  /**
   * The unique identifier of the followup intent. Format:
   * `projects//agent/intents/`.
   */
  set followupIntentName(value: string|null) {
    this.Serializable$set('followupIntentName', value);
  }

  get parentFollowupIntentName(): string|null {
    return (
        (this.Serializable$has('parentFollowupIntentName')) ?
            (this.Serializable$get('parentFollowupIntentName')) :
            (null));
  }

  /**
   * The unique identifier of the followup intent's parent. Format:
   * `projects//agent/intents/`.
   */
  set parentFollowupIntentName(value: string|null) {
    this.Serializable$set('parentFollowupIntentName', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentFollowupIntentInfo> {
    return GoogleCloudDialogflowV2IntentFollowupIntentInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['followupIntentName', 'parentFollowupIntentName']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageParameters {
  text?: GoogleCloudDialogflowV2IntentMessageText|null;
  image?: GoogleCloudDialogflowV2IntentMessageImage|null;
  quickReplies?: GoogleCloudDialogflowV2IntentMessageQuickReplies|null;
  card?: GoogleCloudDialogflowV2IntentMessageCard|null;
  payload?: ApiClientObjectMap<any>|null;
  simpleResponses?: GoogleCloudDialogflowV2IntentMessageSimpleResponses|null;
  basicCard?: GoogleCloudDialogflowV2IntentMessageBasicCard|null;
  suggestions?: GoogleCloudDialogflowV2IntentMessageSuggestions|null;
  linkOutSuggestion?: GoogleCloudDialogflowV2IntentMessageLinkOutSuggestion|
      null;
  listSelect?: GoogleCloudDialogflowV2IntentMessageListSelect|null;
  carouselSelect?: GoogleCloudDialogflowV2IntentMessageCarouselSelect|null;
  telephonyPlayAudio?: GoogleCloudDialogflowV2IntentMessageTelephonyPlayAudio|
      null;
  telephonySynthesizeSpeech?:
      GoogleCloudDialogflowV2IntentMessageTelephonySynthesizeSpeech|null;
  telephonyTransferCall?:
      GoogleCloudDialogflowV2IntentMessageTelephonyTransferCall|null;
  telephonyTerminateCall?:
      GoogleCloudDialogflowV2IntentMessageTelephonyTerminateCall|null;
  telephonyReadDtmf?: GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmf|
      null;
  telephonyAudio?: GoogleCloudDialogflowV2IntentMessageTelephonyAudio|null;
  avayaPlayAudio?: GoogleCloudDialogflowV2IntentMessageAvayaPlayAudio|null;
  rbmText?: GoogleCloudDialogflowV2IntentMessageRbmText|null;
  rbmStandaloneRichCard?: GoogleCloudDialogflowV2IntentMessageRbmStandaloneCard|
      null;
  rbmCarouselRichCard?: GoogleCloudDialogflowV2IntentMessageRbmCarouselCard|
      null;
  browseCarouselCard?: GoogleCloudDialogflowV2IntentMessageBrowseCarouselCard|
      null;
  tableCard?: GoogleCloudDialogflowV2IntentMessageTableCard|null;
  mediaContent?: GoogleCloudDialogflowV2IntentMessageMediaContent|null;
  platform?: GoogleCloudDialogflowV2IntentMessagePlatform|null;
}
export class GoogleCloudDialogflowV2IntentMessage extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2IntentMessageParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
    this.Serializable$set(
        'quickReplies',
        (parameters.quickReplies == null) ? (null) : (parameters.quickReplies));
    this.Serializable$set(
        'card', (parameters.card == null) ? (null) : (parameters.card));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'simpleResponses',
        (parameters.simpleResponses == null) ? (null) :
                                               (parameters.simpleResponses));
    this.Serializable$set(
        'basicCard',
        (parameters.basicCard == null) ? (null) : (parameters.basicCard));
    this.Serializable$set(
        'suggestions',
        (parameters.suggestions == null) ? (null) : (parameters.suggestions));
    this.Serializable$set(
        'linkOutSuggestion',
        (parameters.linkOutSuggestion == null) ?
            (null) :
            (parameters.linkOutSuggestion));
    this.Serializable$set(
        'listSelect',
        (parameters.listSelect == null) ? (null) : (parameters.listSelect));
    this.Serializable$set(
        'carouselSelect',
        (parameters.carouselSelect == null) ? (null) :
                                              (parameters.carouselSelect));
    this.Serializable$set(
        'telephonyPlayAudio',
        (parameters.telephonyPlayAudio == null) ?
            (null) :
            (parameters.telephonyPlayAudio));
    this.Serializable$set(
        'telephonySynthesizeSpeech',
        (parameters.telephonySynthesizeSpeech == null) ?
            (null) :
            (parameters.telephonySynthesizeSpeech));
    this.Serializable$set(
        'telephonyTransferCall',
        (parameters.telephonyTransferCall == null) ?
            (null) :
            (parameters.telephonyTransferCall));
    this.Serializable$set(
        'telephonyTerminateCall',
        (parameters.telephonyTerminateCall == null) ?
            (null) :
            (parameters.telephonyTerminateCall));
    this.Serializable$set(
        'telephonyReadDtmf',
        (parameters.telephonyReadDtmf == null) ?
            (null) :
            (parameters.telephonyReadDtmf));
    this.Serializable$set(
        'telephonyAudio',
        (parameters.telephonyAudio == null) ? (null) :
                                              (parameters.telephonyAudio));
    this.Serializable$set(
        'avayaPlayAudio',
        (parameters.avayaPlayAudio == null) ? (null) :
                                              (parameters.avayaPlayAudio));
    this.Serializable$set(
        'rbmText',
        (parameters.rbmText == null) ? (null) : (parameters.rbmText));
    this.Serializable$set(
        'rbmStandaloneRichCard',
        (parameters.rbmStandaloneRichCard == null) ?
            (null) :
            (parameters.rbmStandaloneRichCard));
    this.Serializable$set(
        'rbmCarouselRichCard',
        (parameters.rbmCarouselRichCard == null) ?
            (null) :
            (parameters.rbmCarouselRichCard));
    this.Serializable$set(
        'browseCarouselCard',
        (parameters.browseCarouselCard == null) ?
            (null) :
            (parameters.browseCarouselCard));
    this.Serializable$set(
        'tableCard',
        (parameters.tableCard == null) ? (null) : (parameters.tableCard));
    this.Serializable$set(
        'mediaContent',
        (parameters.mediaContent == null) ? (null) : (parameters.mediaContent));
    this.Serializable$set(
        'platform',
        (parameters.platform == null) ? (null) : (parameters.platform));
  }

  static get Platform(): IGoogleCloudDialogflowV2IntentMessagePlatformEnum {
    return GoogleCloudDialogflowV2IntentMessagePlatformEnum;
  }

  get avayaPlayAudio(): GoogleCloudDialogflowV2IntentMessageAvayaPlayAudio
      |null {
    return (
        (this.Serializable$has('avayaPlayAudio')) ?
            (this.Serializable$get('avayaPlayAudio')) :
            (null));
  }

  /**
   * Plays audio from a file in Avaya Browser.
   */
  set avayaPlayAudio(value: GoogleCloudDialogflowV2IntentMessageAvayaPlayAudio|
                     null) {
    this.Serializable$set('avayaPlayAudio', value);
  }

  get basicCard(): GoogleCloudDialogflowV2IntentMessageBasicCard|null {
    return (
        (this.Serializable$has('basicCard')) ?
            (this.Serializable$get('basicCard')) :
            (null));
  }

  /**
   * The basic card response for Actions on Google.
   */
  set basicCard(value: GoogleCloudDialogflowV2IntentMessageBasicCard|null) {
    this.Serializable$set('basicCard', value);
  }

  get browseCarouselCard():
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCard|null {
    return (
        (this.Serializable$has('browseCarouselCard')) ?
            (this.Serializable$get('browseCarouselCard')) :
            (null));
  }

  /**
   * Browse carousel card for Actions on Google.
   */
  set browseCarouselCard(
      value: GoogleCloudDialogflowV2IntentMessageBrowseCarouselCard|null) {
    this.Serializable$set('browseCarouselCard', value);
  }

  get card(): GoogleCloudDialogflowV2IntentMessageCard|null {
    return (
        (this.Serializable$has('card')) ? (this.Serializable$get('card')) :
                                          (null));
  }

  /**
   * The card response.
   */
  set card(value: GoogleCloudDialogflowV2IntentMessageCard|null) {
    this.Serializable$set('card', value);
  }

  get carouselSelect(): GoogleCloudDialogflowV2IntentMessageCarouselSelect
      |null {
    return (
        (this.Serializable$has('carouselSelect')) ?
            (this.Serializable$get('carouselSelect')) :
            (null));
  }

  /**
   * The carousel card response for Actions on Google.
   */
  set carouselSelect(value: GoogleCloudDialogflowV2IntentMessageCarouselSelect|
                     null) {
    this.Serializable$set('carouselSelect', value);
  }

  get image(): GoogleCloudDialogflowV2IntentMessageImage|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * The image response.
   */
  set image(value: GoogleCloudDialogflowV2IntentMessageImage|null) {
    this.Serializable$set('image', value);
  }

  get linkOutSuggestion(): GoogleCloudDialogflowV2IntentMessageLinkOutSuggestion
      |null {
    return (
        (this.Serializable$has('linkOutSuggestion')) ?
            (this.Serializable$get('linkOutSuggestion')) :
            (null));
  }

  /**
   * The link out suggestion chip for Actions on Google.
   */
  set linkOutSuggestion(
      value: GoogleCloudDialogflowV2IntentMessageLinkOutSuggestion|null) {
    this.Serializable$set('linkOutSuggestion', value);
  }

  get listSelect(): GoogleCloudDialogflowV2IntentMessageListSelect|null {
    return (
        (this.Serializable$has('listSelect')) ?
            (this.Serializable$get('listSelect')) :
            (null));
  }

  /**
   * The list card response for Actions on Google.
   */
  set listSelect(value: GoogleCloudDialogflowV2IntentMessageListSelect|null) {
    this.Serializable$set('listSelect', value);
  }

  get mediaContent(): GoogleCloudDialogflowV2IntentMessageMediaContent|null {
    return (
        (this.Serializable$has('mediaContent')) ?
            (this.Serializable$get('mediaContent')) :
            (null));
  }

  /**
   * The media content card for Actions on Google.
   */
  set mediaContent(value: GoogleCloudDialogflowV2IntentMessageMediaContent|
                   null) {
    this.Serializable$set('mediaContent', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * A custom platform-specific response.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get platform(): GoogleCloudDialogflowV2IntentMessagePlatform|null {
    return (
        (this.Serializable$has('platform')) ?
            (this.Serializable$get('platform')) :
            (null));
  }

  /**
   * Optional. The platform that this message is intended for.
   */
  set platform(value: GoogleCloudDialogflowV2IntentMessagePlatform|null) {
    this.Serializable$set('platform', value);
  }

  get quickReplies(): GoogleCloudDialogflowV2IntentMessageQuickReplies|null {
    return (
        (this.Serializable$has('quickReplies')) ?
            (this.Serializable$get('quickReplies')) :
            (null));
  }

  /**
   * The quick replies response.
   */
  set quickReplies(value: GoogleCloudDialogflowV2IntentMessageQuickReplies|
                   null) {
    this.Serializable$set('quickReplies', value);
  }

  get rbmCarouselRichCard(): GoogleCloudDialogflowV2IntentMessageRbmCarouselCard
      |null {
    return (
        (this.Serializable$has('rbmCarouselRichCard')) ?
            (this.Serializable$get('rbmCarouselRichCard')) :
            (null));
  }

  /**
   * Rich Business Messaging (RBM) carousel rich card response.
   */
  set rbmCarouselRichCard(
      value: GoogleCloudDialogflowV2IntentMessageRbmCarouselCard|null) {
    this.Serializable$set('rbmCarouselRichCard', value);
  }

  get rbmStandaloneRichCard():
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCard|null {
    return (
        (this.Serializable$has('rbmStandaloneRichCard')) ?
            (this.Serializable$get('rbmStandaloneRichCard')) :
            (null));
  }

  /**
   * Standalone Rich Business Messaging (RBM) rich card response.
   */
  set rbmStandaloneRichCard(
      value: GoogleCloudDialogflowV2IntentMessageRbmStandaloneCard|null) {
    this.Serializable$set('rbmStandaloneRichCard', value);
  }

  get rbmText(): GoogleCloudDialogflowV2IntentMessageRbmText|null {
    return (
        (this.Serializable$has('rbmText')) ?
            (this.Serializable$get('rbmText')) :
            (null));
  }

  /**
   * Rich Business Messaging (RBM) text response. RBM allows businesses to send
   * enriched and branded versions of SMS. See
   * https://jibe.google.com/business-messaging.
   */
  set rbmText(value: GoogleCloudDialogflowV2IntentMessageRbmText|null) {
    this.Serializable$set('rbmText', value);
  }

  get simpleResponses(): GoogleCloudDialogflowV2IntentMessageSimpleResponses
      |null {
    return (
        (this.Serializable$has('simpleResponses')) ?
            (this.Serializable$get('simpleResponses')) :
            (null));
  }

  /**
   * The voice and text-only responses for Actions on Google.
   */
  set simpleResponses(value:
                          GoogleCloudDialogflowV2IntentMessageSimpleResponses|
                      null) {
    this.Serializable$set('simpleResponses', value);
  }

  get suggestions(): GoogleCloudDialogflowV2IntentMessageSuggestions|null {
    return (
        (this.Serializable$has('suggestions')) ?
            (this.Serializable$get('suggestions')) :
            (null));
  }

  /**
   * The suggestion chips for Actions on Google.
   */
  set suggestions(value: GoogleCloudDialogflowV2IntentMessageSuggestions|null) {
    this.Serializable$set('suggestions', value);
  }

  get tableCard(): GoogleCloudDialogflowV2IntentMessageTableCard|null {
    return (
        (this.Serializable$has('tableCard')) ?
            (this.Serializable$get('tableCard')) :
            (null));
  }

  /**
   * Table card for Actions on Google.
   */
  set tableCard(value: GoogleCloudDialogflowV2IntentMessageTableCard|null) {
    this.Serializable$set('tableCard', value);
  }

  get telephonyAudio(): GoogleCloudDialogflowV2IntentMessageTelephonyAudio
      |null {
    return (
        (this.Serializable$has('telephonyAudio')) ?
            (this.Serializable$get('telephonyAudio')) :
            (null));
  }

  /**
   * Audio to be played back by phone gateway.
   */
  set telephonyAudio(value: GoogleCloudDialogflowV2IntentMessageTelephonyAudio|
                     null) {
    this.Serializable$set('telephonyAudio', value);
  }

  get telephonyPlayAudio():
      GoogleCloudDialogflowV2IntentMessageTelephonyPlayAudio|null {
    return (
        (this.Serializable$has('telephonyPlayAudio')) ?
            (this.Serializable$get('telephonyPlayAudio')) :
            (null));
  }

  /**
   * Plays audio from a file in Telephony Gateway.
   */
  set telephonyPlayAudio(
      value: GoogleCloudDialogflowV2IntentMessageTelephonyPlayAudio|null) {
    this.Serializable$set('telephonyPlayAudio', value);
  }

  get telephonyReadDtmf(): GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmf
      |null {
    return (
        (this.Serializable$has('telephonyReadDtmf')) ?
            (this.Serializable$get('telephonyReadDtmf')) :
            (null));
  }

  /**
   * Reads DTMF digits in Telephony Gateway.
   */
  set telephonyReadDtmf(
      value: GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmf|null) {
    this.Serializable$set('telephonyReadDtmf', value);
  }

  get telephonySynthesizeSpeech():
      GoogleCloudDialogflowV2IntentMessageTelephonySynthesizeSpeech|null {
    return (
        (this.Serializable$has('telephonySynthesizeSpeech')) ?
            (this.Serializable$get('telephonySynthesizeSpeech')) :
            (null));
  }

  /**
   * Synthesizes speech in Telephony Gateway.
   */
  set telephonySynthesizeSpeech(
      value: GoogleCloudDialogflowV2IntentMessageTelephonySynthesizeSpeech|
      null) {
    this.Serializable$set('telephonySynthesizeSpeech', value);
  }

  get telephonyTerminateCall():
      GoogleCloudDialogflowV2IntentMessageTelephonyTerminateCall|null {
    return (
        (this.Serializable$has('telephonyTerminateCall')) ?
            (this.Serializable$get('telephonyTerminateCall')) :
            (null));
  }

  /**
   * Terminates the call in Telephony Gateway.
   */
  set telephonyTerminateCall(
      value: GoogleCloudDialogflowV2IntentMessageTelephonyTerminateCall|null) {
    this.Serializable$set('telephonyTerminateCall', value);
  }

  get telephonyTransferCall():
      GoogleCloudDialogflowV2IntentMessageTelephonyTransferCall|null {
    return (
        (this.Serializable$has('telephonyTransferCall')) ?
            (this.Serializable$get('telephonyTransferCall')) :
            (null));
  }

  /**
   * Transfers the call in Telephony Gateway.
   */
  set telephonyTransferCall(
      value: GoogleCloudDialogflowV2IntentMessageTelephonyTransferCall|null) {
    this.Serializable$set('telephonyTransferCall', value);
  }

  get text(): GoogleCloudDialogflowV2IntentMessageText|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The text response.
   */
  set text(value: GoogleCloudDialogflowV2IntentMessageText|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2IntentMessage> {
    return GoogleCloudDialogflowV2IntentMessage;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'platform': GoogleCloudDialogflowV2IntentMessagePlatformEnum},
      keys: [
        'avayaPlayAudio',
        'basicCard',
        'browseCarouselCard',
        'card',
        'carouselSelect',
        'image',
        'linkOutSuggestion',
        'listSelect',
        'mediaContent',
        'payload',
        'platform',
        'quickReplies',
        'rbmCarouselRichCard',
        'rbmStandaloneRichCard',
        'rbmText',
        'simpleResponses',
        'suggestions',
        'tableCard',
        'telephonyAudio',
        'telephonyPlayAudio',
        'telephonyReadDtmf',
        'telephonySynthesizeSpeech',
        'telephonyTerminateCall',
        'telephonyTransferCall',
        'text'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'avayaPlayAudio': GoogleCloudDialogflowV2IntentMessageAvayaPlayAudio,
        'basicCard': GoogleCloudDialogflowV2IntentMessageBasicCard,
        'browseCarouselCard':
            GoogleCloudDialogflowV2IntentMessageBrowseCarouselCard,
        'card': GoogleCloudDialogflowV2IntentMessageCard,
        'carouselSelect': GoogleCloudDialogflowV2IntentMessageCarouselSelect,
        'image': GoogleCloudDialogflowV2IntentMessageImage,
        'linkOutSuggestion':
            GoogleCloudDialogflowV2IntentMessageLinkOutSuggestion,
        'listSelect': GoogleCloudDialogflowV2IntentMessageListSelect,
        'mediaContent': GoogleCloudDialogflowV2IntentMessageMediaContent,
        'quickReplies': GoogleCloudDialogflowV2IntentMessageQuickReplies,
        'rbmCarouselRichCard':
            GoogleCloudDialogflowV2IntentMessageRbmCarouselCard,
        'rbmStandaloneRichCard':
            GoogleCloudDialogflowV2IntentMessageRbmStandaloneCard,
        'rbmText': GoogleCloudDialogflowV2IntentMessageRbmText,
        'simpleResponses': GoogleCloudDialogflowV2IntentMessageSimpleResponses,
        'suggestions': GoogleCloudDialogflowV2IntentMessageSuggestions,
        'tableCard': GoogleCloudDialogflowV2IntentMessageTableCard,
        'telephonyAudio': GoogleCloudDialogflowV2IntentMessageTelephonyAudio,
        'telephonyPlayAudio':
            GoogleCloudDialogflowV2IntentMessageTelephonyPlayAudio,
        'telephonyReadDtmf':
            GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmf,
        'telephonySynthesizeSpeech':
            GoogleCloudDialogflowV2IntentMessageTelephonySynthesizeSpeech,
        'telephonyTerminateCall':
            GoogleCloudDialogflowV2IntentMessageTelephonyTerminateCall,
        'telephonyTransferCall':
            GoogleCloudDialogflowV2IntentMessageTelephonyTransferCall,
        'text': GoogleCloudDialogflowV2IntentMessageText
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageAvayaPlayAudioParameters {
  audioUri?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageAvayaPlayAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageAvayaPlayAudioParameters = {}) {
    super();
    this.Serializable$set(
        'audioUri',
        (parameters.audioUri == null) ? (null) : (parameters.audioUri));
  }

  get audioUri(): string|null {
    return (
        (this.Serializable$has('audioUri')) ?
            (this.Serializable$get('audioUri')) :
            (null));
  }

  /**
   * Required. Audio URI for Avaya Browser to play the audio.
   */
  set audioUri(value: string|null) {
    this.Serializable$set('audioUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageAvayaPlayAudio> {
    return GoogleCloudDialogflowV2IntentMessageAvayaPlayAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audioUri']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageBasicCardParameters {
  title?: string|null;
  subtitle?: string|null;
  formattedText?: string|null;
  image?: GoogleCloudDialogflowV2IntentMessageImage|null;
  buttons?: Array<GoogleCloudDialogflowV2IntentMessageBasicCardButton>|null;
}
export class GoogleCloudDialogflowV2IntentMessageBasicCard extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageBasicCardParameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'subtitle',
        (parameters.subtitle == null) ? (null) : (parameters.subtitle));
    this.Serializable$set(
        'formattedText',
        (parameters.formattedText == null) ? (null) :
                                             (parameters.formattedText));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
    this.Serializable$set(
        'buttons',
        (parameters.buttons == null) ? (null) : (parameters.buttons));
  }

  get buttons():
      Array<GoogleCloudDialogflowV2IntentMessageBasicCardButton>|null {
    return (
        (this.Serializable$has('buttons')) ?
            (this.Serializable$get('buttons')) :
            (null));
  }

  /**
   * Optional. The collection of card buttons.
   */
  set buttons(value: Array<GoogleCloudDialogflowV2IntentMessageBasicCardButton>|
              null) {
    this.Serializable$set('buttons', value);
  }

  get formattedText(): string|null {
    return (
        (this.Serializable$has('formattedText')) ?
            (this.Serializable$get('formattedText')) :
            (null));
  }

  /**
   * Required, unless image is present. The body text of the card.
   */
  set formattedText(value: string|null) {
    this.Serializable$set('formattedText', value);
  }

  get image(): GoogleCloudDialogflowV2IntentMessageImage|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Optional. The image for the card.
   */
  set image(value: GoogleCloudDialogflowV2IntentMessageImage|null) {
    this.Serializable$set('image', value);
  }

  get subtitle(): string|null {
    return (
        (this.Serializable$has('subtitle')) ?
            (this.Serializable$get('subtitle')) :
            (null));
  }

  /**
   * Optional. The subtitle of the card.
   */
  set subtitle(value: string|null) {
    this.Serializable$set('subtitle', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. The title of the card.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageBasicCard> {
    return GoogleCloudDialogflowV2IntentMessageBasicCard;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'buttons': GoogleCloudDialogflowV2IntentMessageBasicCardButton},
      keys: ['buttons', 'formattedText', 'image', 'subtitle', 'title'],
      objects: {'image': GoogleCloudDialogflowV2IntentMessageImage}
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageBasicCardButtonParameters {
  title?: string|null;
  openUriAction?:
      GoogleCloudDialogflowV2IntentMessageBasicCardButtonOpenUriAction|null;
}
export class GoogleCloudDialogflowV2IntentMessageBasicCardButton extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageBasicCardButtonParameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'openUriAction',
        (parameters.openUriAction == null) ? (null) :
                                             (parameters.openUriAction));
  }

  get openUriAction():
      GoogleCloudDialogflowV2IntentMessageBasicCardButtonOpenUriAction|null {
    return (
        (this.Serializable$has('openUriAction')) ?
            (this.Serializable$get('openUriAction')) :
            (null));
  }

  /**
   * Required. Action to take when a user taps on the button.
   */
  set openUriAction(
      value: GoogleCloudDialogflowV2IntentMessageBasicCardButtonOpenUriAction|
      null) {
    this.Serializable$set('openUriAction', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. The title of the button.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageBasicCardButton> {
    return GoogleCloudDialogflowV2IntentMessageBasicCardButton;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['openUriAction', 'title'],
      objects: {
        'openUriAction':
            GoogleCloudDialogflowV2IntentMessageBasicCardButtonOpenUriAction
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageBasicCardButtonOpenUriActionParameters {
  uri?: string|null;
}
export class
    GoogleCloudDialogflowV2IntentMessageBasicCardButtonOpenUriAction extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageBasicCardButtonOpenUriActionParameters =
              {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The HTTP or HTTPS scheme URI.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageBasicCardButtonOpenUriAction> {
    return GoogleCloudDialogflowV2IntentMessageBasicCardButtonOpenUriAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardParameters {
  items?: Array<
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItem>|
      null;
  imageDisplayOptions?:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions|
      null;
}
export class GoogleCloudDialogflowV2IntentMessageBrowseCarouselCard extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardParameters =
              {}) {
    super();
    this.Serializable$set(
        'items', (parameters.items == null) ? (null) : (parameters.items));
    this.Serializable$set(
        'imageDisplayOptions',
        (parameters.imageDisplayOptions == null) ?
            (null) :
            (parameters.imageDisplayOptions));
  }

  static get ImageDisplayOptions():
      IGoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum {
    return GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum;
  }

  get imageDisplayOptions():
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions
      |null {
    return (
        (this.Serializable$has('imageDisplayOptions')) ?
            (this.Serializable$get('imageDisplayOptions')) :
            (null));
  }

  /**
   * Optional. Settings for displaying the image. Applies to every image in
   * items.
   */
  set imageDisplayOptions(
      value:
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptions|
      null) {
    this.Serializable$set('imageDisplayOptions', value);
  }

  get items(): Array<
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItem>|
      null {
    return (
        (this.Serializable$has('items')) ? (this.Serializable$get('items')) :
                                           (null));
  }

  /**
   * Required. List of items in the Browse Carousel Card. Minimum of two items,
   * maximum of ten.
   */
  set items(
      value: Array<
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItem>|
      null) {
    this.Serializable$set('items', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageBrowseCarouselCard> {
    return GoogleCloudDialogflowV2IntentMessageBrowseCarouselCard;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'items':
            GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItem
      },
      enums: {
        'imageDisplayOptions':
            GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardImageDisplayOptionsEnum
      },
      keys: ['imageDisplayOptions', 'items']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemParameters {
  openUriAction?:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction|
      null;
  title?: string|null;
  description?: string|null;
  image?: GoogleCloudDialogflowV2IntentMessageImage|null;
  footer?: string|null;
}
export class
    GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItem extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemParameters =
              {}) {
    super();
    this.Serializable$set(
        'openUriAction',
        (parameters.openUriAction == null) ? (null) :
                                             (parameters.openUriAction));
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
    this.Serializable$set(
        'footer', (parameters.footer == null) ? (null) : (parameters.footer));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. Description of the carousel item. Maximum of four lines of text.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get footer(): string|null {
    return (
        (this.Serializable$has('footer')) ? (this.Serializable$get('footer')) :
                                            (null));
  }

  /**
   * Optional. Text that appears at the bottom of the Browse Carousel Card.
   * Maximum of one line of text.
   */
  set footer(value: string|null) {
    this.Serializable$set('footer', value);
  }

  get image(): GoogleCloudDialogflowV2IntentMessageImage|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Optional. Hero image for the carousel item.
   */
  set image(value: GoogleCloudDialogflowV2IntentMessageImage|null) {
    this.Serializable$set('image', value);
  }

  get openUriAction():
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction
      |null {
    return (
        (this.Serializable$has('openUriAction')) ?
            (this.Serializable$get('openUriAction')) :
            (null));
  }

  /**
   * Required. Action to present to the user.
   */
  set openUriAction(
      value:
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction|
      null) {
    this.Serializable$set('openUriAction', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. Title of the carousel item. Maximum of two lines of text.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItem> {
    return GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItem;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['description', 'footer', 'image', 'openUriAction', 'title'],
      objects: {
        'image': GoogleCloudDialogflowV2IntentMessageImage,
        'openUriAction':
            GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionParameters {
  url?: string|null;
  urlTypeHint?:
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint|
      null;
}
export class
    GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionParameters =
              {}) {
    super();
    this.Serializable$set(
        'url', (parameters.url == null) ? (null) : (parameters.url));
    this.Serializable$set(
        'urlTypeHint',
        (parameters.urlTypeHint == null) ? (null) : (parameters.urlTypeHint));
  }

  static get UrlTypeHint():
      IGoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHintEnum {
    return GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHintEnum;
  }

  get url(): string|null {
    return (
        (this.Serializable$has('url')) ? (this.Serializable$get('url')) :
                                         (null));
  }

  /**
   * Required. URL
   */
  set url(value: string|null) {
    this.Serializable$set('url', value);
  }

  get urlTypeHint():
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint
      |null {
    return (
        (this.Serializable$has('urlTypeHint')) ?
            (this.Serializable$get('urlTypeHint')) :
            (null));
  }

  /**
   * Optional. Specifies the type of viewer that is used when opening the URL.
   * Defaults to opening via web browser.
   */
  set urlTypeHint(
      value:
          GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHint|
      null) {
    this.Serializable$set('urlTypeHint', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction> {
    return GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'urlTypeHint':
            GoogleCloudDialogflowV2IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlActionUrlTypeHintEnum
      },
      keys: ['url', 'urlTypeHint']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageCardParameters {
  title?: string|null;
  subtitle?: string|null;
  imageUri?: string|null;
  buttons?: Array<GoogleCloudDialogflowV2IntentMessageCardButton>|null;
}
export class GoogleCloudDialogflowV2IntentMessageCard extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2IntentMessageCardParameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'subtitle',
        (parameters.subtitle == null) ? (null) : (parameters.subtitle));
    this.Serializable$set(
        'imageUri',
        (parameters.imageUri == null) ? (null) : (parameters.imageUri));
    this.Serializable$set(
        'buttons',
        (parameters.buttons == null) ? (null) : (parameters.buttons));
  }

  get buttons(): Array<GoogleCloudDialogflowV2IntentMessageCardButton>|null {
    return (
        (this.Serializable$has('buttons')) ?
            (this.Serializable$get('buttons')) :
            (null));
  }

  /**
   * Optional. The collection of card buttons.
   */
  set buttons(value: Array<GoogleCloudDialogflowV2IntentMessageCardButton>|
              null) {
    this.Serializable$set('buttons', value);
  }

  get imageUri(): string|null {
    return (
        (this.Serializable$has('imageUri')) ?
            (this.Serializable$get('imageUri')) :
            (null));
  }

  /**
   * Optional. The public URI to an image file for the card.
   */
  set imageUri(value: string|null) {
    this.Serializable$set('imageUri', value);
  }

  get subtitle(): string|null {
    return (
        (this.Serializable$has('subtitle')) ?
            (this.Serializable$get('subtitle')) :
            (null));
  }

  /**
   * Optional. The subtitle of the card.
   */
  set subtitle(value: string|null) {
    this.Serializable$set('subtitle', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. The title of the card.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2IntentMessageCard> {
    return GoogleCloudDialogflowV2IntentMessageCard;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'buttons': GoogleCloudDialogflowV2IntentMessageCardButton},
      keys: ['buttons', 'imageUri', 'subtitle', 'title']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageCardButtonParameters {
  text?: string|null;
  postback?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageCardButton extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageCardButtonParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'postback',
        (parameters.postback == null) ? (null) : (parameters.postback));
  }

  get postback(): string|null {
    return (
        (this.Serializable$has('postback')) ?
            (this.Serializable$get('postback')) :
            (null));
  }

  /**
   * Optional. The text to send back to the Dialogflow API or a URI to open.
   */
  set postback(value: string|null) {
    this.Serializable$set('postback', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Optional. The text to show on the button.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageCardButton> {
    return GoogleCloudDialogflowV2IntentMessageCardButton;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['postback', 'text']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageCarouselSelectParameters {
  items?: Array<GoogleCloudDialogflowV2IntentMessageCarouselSelectItem>|null;
}
export class GoogleCloudDialogflowV2IntentMessageCarouselSelect extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageCarouselSelectParameters = {}) {
    super();
    this.Serializable$set(
        'items', (parameters.items == null) ? (null) : (parameters.items));
  }

  get items():
      Array<GoogleCloudDialogflowV2IntentMessageCarouselSelectItem>|null {
    return (
        (this.Serializable$has('items')) ? (this.Serializable$get('items')) :
                                           (null));
  }

  /**
   * Required. Carousel items.
   */
  set items(value:
                Array<GoogleCloudDialogflowV2IntentMessageCarouselSelectItem>|
            null) {
    this.Serializable$set('items', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageCarouselSelect> {
    return GoogleCloudDialogflowV2IntentMessageCarouselSelect;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'items': GoogleCloudDialogflowV2IntentMessageCarouselSelectItem},
      keys: ['items']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageCarouselSelectItemParameters {
  info?: GoogleCloudDialogflowV2IntentMessageSelectItemInfo|null;
  title?: string|null;
  description?: string|null;
  image?: GoogleCloudDialogflowV2IntentMessageImage|null;
}
export class GoogleCloudDialogflowV2IntentMessageCarouselSelectItem extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageCarouselSelectItemParameters =
              {}) {
    super();
    this.Serializable$set(
        'info', (parameters.info == null) ? (null) : (parameters.info));
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. The body text of the card.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get image(): GoogleCloudDialogflowV2IntentMessageImage|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Optional. The image to display.
   */
  set image(value: GoogleCloudDialogflowV2IntentMessageImage|null) {
    this.Serializable$set('image', value);
  }

  get info(): GoogleCloudDialogflowV2IntentMessageSelectItemInfo|null {
    return (
        (this.Serializable$has('info')) ? (this.Serializable$get('info')) :
                                          (null));
  }

  /**
   * Required. Additional info about the option item.
   */
  set info(value: GoogleCloudDialogflowV2IntentMessageSelectItemInfo|null) {
    this.Serializable$set('info', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. Title of the carousel item.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageCarouselSelectItem> {
    return GoogleCloudDialogflowV2IntentMessageCarouselSelectItem;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['description', 'image', 'info', 'title'],
      objects: {
        'image': GoogleCloudDialogflowV2IntentMessageImage,
        'info': GoogleCloudDialogflowV2IntentMessageSelectItemInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageColumnPropertiesParameters {
  header?: string|null;
  horizontalAlignment?:
      GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment|
      null;
}
export class GoogleCloudDialogflowV2IntentMessageColumnProperties extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesParameters = {}) {
    super();
    this.Serializable$set(
        'header', (parameters.header == null) ? (null) : (parameters.header));
    this.Serializable$set(
        'horizontalAlignment',
        (parameters.horizontalAlignment == null) ?
            (null) :
            (parameters.horizontalAlignment));
  }

  static get HorizontalAlignment():
      IGoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignmentEnum {
    return GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignmentEnum;
  }

  get header(): string|null {
    return (
        (this.Serializable$has('header')) ? (this.Serializable$get('header')) :
                                            (null));
  }

  /**
   * Required. Column heading.
   */
  set header(value: string|null) {
    this.Serializable$set('header', value);
  }

  get horizontalAlignment():
      GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment
      |null {
    return (
        (this.Serializable$has('horizontalAlignment')) ?
            (this.Serializable$get('horizontalAlignment')) :
            (null));
  }

  /**
   * Optional. Defines text alignment for all cells in this column.
   */
  set horizontalAlignment(
      value:
          GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignment|
      null) {
    this.Serializable$set('horizontalAlignment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageColumnProperties> {
    return GoogleCloudDialogflowV2IntentMessageColumnProperties;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'horizontalAlignment':
            GoogleCloudDialogflowV2IntentMessageColumnPropertiesHorizontalAlignmentEnum
      },
      keys: ['header', 'horizontalAlignment']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageImageParameters {
  imageUri?: string|null;
  accessibilityText?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageImage extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2IntentMessageImageParameters = {}) {
    super();
    this.Serializable$set(
        'imageUri',
        (parameters.imageUri == null) ? (null) : (parameters.imageUri));
    this.Serializable$set(
        'accessibilityText',
        (parameters.accessibilityText == null) ?
            (null) :
            (parameters.accessibilityText));
  }

  get accessibilityText(): string|null {
    return (
        (this.Serializable$has('accessibilityText')) ?
            (this.Serializable$get('accessibilityText')) :
            (null));
  }

  /**
   * Optional. A text description of the image to be used for accessibility,
   * e.g., screen readers.
   */
  set accessibilityText(value: string|null) {
    this.Serializable$set('accessibilityText', value);
  }

  get imageUri(): string|null {
    return (
        (this.Serializable$has('imageUri')) ?
            (this.Serializable$get('imageUri')) :
            (null));
  }

  /**
   * Optional. The public URI to an image file.
   */
  set imageUri(value: string|null) {
    this.Serializable$set('imageUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageImage> {
    return GoogleCloudDialogflowV2IntentMessageImage;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['accessibilityText', 'imageUri']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageLinkOutSuggestionParameters {
  destinationName?: string|null;
  uri?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageLinkOutSuggestion extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageLinkOutSuggestionParameters =
              {}) {
    super();
    this.Serializable$set(
        'destinationName',
        (parameters.destinationName == null) ? (null) :
                                               (parameters.destinationName));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get destinationName(): string|null {
    return (
        (this.Serializable$has('destinationName')) ?
            (this.Serializable$get('destinationName')) :
            (null));
  }

  /**
   * Required. The name of the app or site this chip is linking to.
   */
  set destinationName(value: string|null) {
    this.Serializable$set('destinationName', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The URI of the app or site to open when the user taps the
   * suggestion chip.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageLinkOutSuggestion> {
    return GoogleCloudDialogflowV2IntentMessageLinkOutSuggestion;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['destinationName', 'uri']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageListSelectParameters {
  title?: string|null;
  items?: Array<GoogleCloudDialogflowV2IntentMessageListSelectItem>|null;
  subtitle?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageListSelect extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageListSelectParameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'items', (parameters.items == null) ? (null) : (parameters.items));
    this.Serializable$set(
        'subtitle',
        (parameters.subtitle == null) ? (null) : (parameters.subtitle));
  }

  get items(): Array<GoogleCloudDialogflowV2IntentMessageListSelectItem>|null {
    return (
        (this.Serializable$has('items')) ? (this.Serializable$get('items')) :
                                           (null));
  }

  /**
   * Required. List items.
   */
  set items(value: Array<GoogleCloudDialogflowV2IntentMessageListSelectItem>|
            null) {
    this.Serializable$set('items', value);
  }

  get subtitle(): string|null {
    return (
        (this.Serializable$has('subtitle')) ?
            (this.Serializable$get('subtitle')) :
            (null));
  }

  /**
   * Optional. Subtitle of the list.
   */
  set subtitle(value: string|null) {
    this.Serializable$set('subtitle', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. The overall title of the list.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageListSelect> {
    return GoogleCloudDialogflowV2IntentMessageListSelect;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'items': GoogleCloudDialogflowV2IntentMessageListSelectItem},
      keys: ['items', 'subtitle', 'title']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageListSelectItemParameters {
  info?: GoogleCloudDialogflowV2IntentMessageSelectItemInfo|null;
  title?: string|null;
  description?: string|null;
  image?: GoogleCloudDialogflowV2IntentMessageImage|null;
}
export class GoogleCloudDialogflowV2IntentMessageListSelectItem extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageListSelectItemParameters = {}) {
    super();
    this.Serializable$set(
        'info', (parameters.info == null) ? (null) : (parameters.info));
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. The main text describing the item.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get image(): GoogleCloudDialogflowV2IntentMessageImage|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Optional. The image to display.
   */
  set image(value: GoogleCloudDialogflowV2IntentMessageImage|null) {
    this.Serializable$set('image', value);
  }

  get info(): GoogleCloudDialogflowV2IntentMessageSelectItemInfo|null {
    return (
        (this.Serializable$has('info')) ? (this.Serializable$get('info')) :
                                          (null));
  }

  /**
   * Required. Additional information about this option.
   */
  set info(value: GoogleCloudDialogflowV2IntentMessageSelectItemInfo|null) {
    this.Serializable$set('info', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. The title of the list item.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageListSelectItem> {
    return GoogleCloudDialogflowV2IntentMessageListSelectItem;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['description', 'image', 'info', 'title'],
      objects: {
        'image': GoogleCloudDialogflowV2IntentMessageImage,
        'info': GoogleCloudDialogflowV2IntentMessageSelectItemInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageMediaContentParameters {
  mediaType?: GoogleCloudDialogflowV2IntentMessageMediaContentMediaType|null;
  mediaObjects?: Array<
      GoogleCloudDialogflowV2IntentMessageMediaContentResponseMediaObject>|null;
  repeatMode?: GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode|null;
  firstMediaObjectIndex?: number|null;
}
export class GoogleCloudDialogflowV2IntentMessageMediaContent extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageMediaContentParameters = {}) {
    super();
    this.Serializable$set(
        'mediaType',
        (parameters.mediaType == null) ? (null) : (parameters.mediaType));
    this.Serializable$set(
        'mediaObjects',
        (parameters.mediaObjects == null) ? (null) : (parameters.mediaObjects));
    this.Serializable$set(
        'repeatMode',
        (parameters.repeatMode == null) ? (null) : (parameters.repeatMode));
    this.Serializable$set(
        'firstMediaObjectIndex',
        (parameters.firstMediaObjectIndex == null) ?
            (null) :
            (parameters.firstMediaObjectIndex));
  }

  static get MediaType():
      IGoogleCloudDialogflowV2IntentMessageMediaContentMediaTypeEnum {
    return GoogleCloudDialogflowV2IntentMessageMediaContentMediaTypeEnum;
  }

  static get RepeatMode():
      IGoogleCloudDialogflowV2IntentMessageMediaContentRepeatModeEnum {
    return GoogleCloudDialogflowV2IntentMessageMediaContentRepeatModeEnum;
  }

  get firstMediaObjectIndex(): number|null {
    return (
        (this.Serializable$has('firstMediaObjectIndex')) ?
            (this.Serializable$get('firstMediaObjectIndex')) :
            (null));
  }

  /**
   * Optional. 0-based index of the first ResponseMediaObject in media_objects
   * to play. If unspecified, zero or out-of-bounds, playback starts at the
   * first media object.
   */
  set firstMediaObjectIndex(value: number|null) {
    this.Serializable$set('firstMediaObjectIndex', value);
  }

  get mediaObjects(): Array<
      GoogleCloudDialogflowV2IntentMessageMediaContentResponseMediaObject>|
      null {
    return (
        (this.Serializable$has('mediaObjects')) ?
            (this.Serializable$get('mediaObjects')) :
            (null));
  }

  /**
   * Required. List of media objects.
   */
  set mediaObjects(
      value: Array<
          GoogleCloudDialogflowV2IntentMessageMediaContentResponseMediaObject>|
      null) {
    this.Serializable$set('mediaObjects', value);
  }

  get mediaType(): GoogleCloudDialogflowV2IntentMessageMediaContentMediaType
      |null {
    return (
        (this.Serializable$has('mediaType')) ?
            (this.Serializable$get('mediaType')) :
            (null));
  }

  /**
   * Optional. What type of media is the content (ie \"audio\").
   */
  set mediaType(value:
                    GoogleCloudDialogflowV2IntentMessageMediaContentMediaType|
                null) {
    this.Serializable$set('mediaType', value);
  }

  get repeatMode(): GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode
      |null {
    return (
        (this.Serializable$has('repeatMode')) ?
            (this.Serializable$get('repeatMode')) :
            (null));
  }

  /**
   * Optional. Repeat mode for the list of Media Objects.
   */
  set repeatMode(value:
                     GoogleCloudDialogflowV2IntentMessageMediaContentRepeatMode|
                 null) {
    this.Serializable$set('repeatMode', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageMediaContent> {
    return GoogleCloudDialogflowV2IntentMessageMediaContent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'mediaObjects':
            GoogleCloudDialogflowV2IntentMessageMediaContentResponseMediaObject
      },
      enums: {
        'mediaType':
            GoogleCloudDialogflowV2IntentMessageMediaContentMediaTypeEnum,
        'repeatMode':
            GoogleCloudDialogflowV2IntentMessageMediaContentRepeatModeEnum
      },
      keys: ['firstMediaObjectIndex', 'mediaObjects', 'mediaType', 'repeatMode']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageMediaContentResponseMediaObjectParameters {
  name?: string|null;
  description?: string|null;
  largeImage?: GoogleCloudDialogflowV2IntentMessageImage|null;
  icon?: GoogleCloudDialogflowV2IntentMessageImage|null;
  contentUrl?: string|null;
}
export class
    GoogleCloudDialogflowV2IntentMessageMediaContentResponseMediaObject extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageMediaContentResponseMediaObjectParameters =
              {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'largeImage',
        (parameters.largeImage == null) ? (null) : (parameters.largeImage));
    this.Serializable$set(
        'icon', (parameters.icon == null) ? (null) : (parameters.icon));
    this.Serializable$set(
        'contentUrl',
        (parameters.contentUrl == null) ? (null) : (parameters.contentUrl));
  }

  get contentUrl(): string|null {
    return (
        (this.Serializable$has('contentUrl')) ?
            (this.Serializable$get('contentUrl')) :
            (null));
  }

  /**
   * Required. Url where the media is stored.
   */
  set contentUrl(value: string|null) {
    this.Serializable$set('contentUrl', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. Description of media card.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get icon(): GoogleCloudDialogflowV2IntentMessageImage|null {
    return (
        (this.Serializable$has('icon')) ? (this.Serializable$get('icon')) :
                                          (null));
  }

  /**
   * Optional. Icon to display above media content.
   */
  set icon(value: GoogleCloudDialogflowV2IntentMessageImage|null) {
    this.Serializable$set('icon', value);
  }

  get largeImage(): GoogleCloudDialogflowV2IntentMessageImage|null {
    return (
        (this.Serializable$has('largeImage')) ?
            (this.Serializable$get('largeImage')) :
            (null));
  }

  /**
   * Optional. Image to display above media content.
   */
  set largeImage(value: GoogleCloudDialogflowV2IntentMessageImage|null) {
    this.Serializable$set('largeImage', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. Name of media card.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageMediaContentResponseMediaObject> {
    return GoogleCloudDialogflowV2IntentMessageMediaContentResponseMediaObject;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['contentUrl', 'description', 'icon', 'largeImage', 'name'],
      objects: {
        'icon': GoogleCloudDialogflowV2IntentMessageImage,
        'largeImage': GoogleCloudDialogflowV2IntentMessageImage
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageQuickRepliesParameters {
  title?: string|null;
  quickReplies?: Array<string>|null;
}
export class GoogleCloudDialogflowV2IntentMessageQuickReplies extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageQuickRepliesParameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'quickReplies',
        (parameters.quickReplies == null) ? (null) : (parameters.quickReplies));
  }

  get quickReplies(): Array<string>|null {
    return (
        (this.Serializable$has('quickReplies')) ?
            (this.Serializable$get('quickReplies')) :
            (null));
  }

  /**
   * Optional. The collection of quick replies.
   */
  set quickReplies(value: Array<string>|null) {
    this.Serializable$set('quickReplies', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. The title of the collection of quick replies.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageQuickReplies> {
    return GoogleCloudDialogflowV2IntentMessageQuickReplies;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['quickReplies', 'title']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmCardContentParameters {
  title?: string|null;
  description?: string|null;
  media?: GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMedia|null;
  suggestions?: Array<GoogleCloudDialogflowV2IntentMessageRbmSuggestion>|null;
}
export class GoogleCloudDialogflowV2IntentMessageRbmCardContent extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageRbmCardContentParameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'media', (parameters.media == null) ? (null) : (parameters.media));
    this.Serializable$set(
        'suggestions',
        (parameters.suggestions == null) ? (null) : (parameters.suggestions));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. Description of the card (at most 2000 bytes). At least one of the
   * title, description or media must be set.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get media(): GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMedia|null {
    return (
        (this.Serializable$has('media')) ? (this.Serializable$get('media')) :
                                           (null));
  }

  /**
   * Optional. However at least one of the title, description or media must be
   * set. Media (image, GIF or a video) to include in the card.
   */
  set media(value: GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMedia|
            null) {
    this.Serializable$set('media', value);
  }

  get suggestions():
      Array<GoogleCloudDialogflowV2IntentMessageRbmSuggestion>|null {
    return (
        (this.Serializable$has('suggestions')) ?
            (this.Serializable$get('suggestions')) :
            (null));
  }

  /**
   * Optional. List of suggestions to include in the card.
   */
  set suggestions(value:
                      Array<GoogleCloudDialogflowV2IntentMessageRbmSuggestion>|
                  null) {
    this.Serializable$set('suggestions', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. Title of the card (at most 200 bytes). At least one of the title,
   * description or media must be set.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageRbmCardContent> {
    return GoogleCloudDialogflowV2IntentMessageRbmCardContent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'suggestions': GoogleCloudDialogflowV2IntentMessageRbmSuggestion},
      keys: ['description', 'media', 'suggestions', 'title'],
      objects:
          {'media': GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMedia}
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaParameters {
  fileUri?: string|null;
  thumbnailUri?: string|null;
  height?: GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight|
      null;
}
export class GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMedia extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaParameters =
              {}) {
    super();
    this.Serializable$set(
        'fileUri',
        (parameters.fileUri == null) ? (null) : (parameters.fileUri));
    this.Serializable$set(
        'thumbnailUri',
        (parameters.thumbnailUri == null) ? (null) : (parameters.thumbnailUri));
    this.Serializable$set(
        'height', (parameters.height == null) ? (null) : (parameters.height));
  }

  static get Height():
      IGoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeightEnum {
    return GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeightEnum;
  }

  get fileUri(): string|null {
    return (
        (this.Serializable$has('fileUri')) ?
            (this.Serializable$get('fileUri')) :
            (null));
  }

  /**
   * Required. Publicly reachable URI of the file. The RBM platform determines
   * the MIME type of the file from the content-type field in the HTTP headers
   * when the platform fetches the file. The content-type field must be present
   * and accurate in the HTTP response from the URL.
   */
  set fileUri(value: string|null) {
    this.Serializable$set('fileUri', value);
  }

  get height(): GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight
      |null {
    return (
        (this.Serializable$has('height')) ? (this.Serializable$get('height')) :
                                            (null));
  }

  /**
   * Required for cards with vertical orientation. The height of the media
   * within a rich card with a vertical layout. For a standalone card with
   * horizontal layout, height is not customizable, and this field is ignored.
   */
  set height(
      value: GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeight|
      null) {
    this.Serializable$set('height', value);
  }

  get thumbnailUri(): string|null {
    return (
        (this.Serializable$has('thumbnailUri')) ?
            (this.Serializable$get('thumbnailUri')) :
            (null));
  }

  /**
   * Optional. Publicly reachable URI of the thumbnail.If you don't provide a
   * thumbnail URI, the RBM platform displays a blank placeholder thumbnail
   * until the user's device downloads the file. Depending on the user's
   * setting, the file may not download automatically and may require the user
   * to tap a download button.
   */
  set thumbnailUri(value: string|null) {
    this.Serializable$set('thumbnailUri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMedia> {
    return GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMedia;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'height':
            GoogleCloudDialogflowV2IntentMessageRbmCardContentRbmMediaHeightEnum
      },
      keys: ['fileUri', 'height', 'thumbnailUri']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmCarouselCardParameters {
  cardWidth?: GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth|null;
  cardContents?: Array<GoogleCloudDialogflowV2IntentMessageRbmCardContent>|null;
}
export class GoogleCloudDialogflowV2IntentMessageRbmCarouselCard extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageRbmCarouselCardParameters = {}) {
    super();
    this.Serializable$set(
        'cardWidth',
        (parameters.cardWidth == null) ? (null) : (parameters.cardWidth));
    this.Serializable$set(
        'cardContents',
        (parameters.cardContents == null) ? (null) : (parameters.cardContents));
  }

  static get CardWidth():
      IGoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidthEnum {
    return GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidthEnum;
  }

  get cardContents():
      Array<GoogleCloudDialogflowV2IntentMessageRbmCardContent>|null {
    return (
        (this.Serializable$has('cardContents')) ?
            (this.Serializable$get('cardContents')) :
            (null));
  }

  /**
   * Required. The cards in the carousel. A carousel must have at least 2 cards
   * and at most 10.
   */
  set cardContents(
      value: Array<GoogleCloudDialogflowV2IntentMessageRbmCardContent>|null) {
    this.Serializable$set('cardContents', value);
  }

  get cardWidth(): GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth
      |null {
    return (
        (this.Serializable$has('cardWidth')) ?
            (this.Serializable$get('cardWidth')) :
            (null));
  }

  /**
   * Required. The width of the cards in the carousel.
   */
  set cardWidth(
      value: GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidth|
      null) {
    this.Serializable$set('cardWidth', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageRbmCarouselCard> {
    return GoogleCloudDialogflowV2IntentMessageRbmCarouselCard;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'cardContents': GoogleCloudDialogflowV2IntentMessageRbmCardContent},
      enums: {
        'cardWidth':
            GoogleCloudDialogflowV2IntentMessageRbmCarouselCardCardWidthEnum
      },
      keys: ['cardContents', 'cardWidth']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardParameters {
  cardOrientation?:
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation|null;
  thumbnailImageAlignment?:
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment|
      null;
  cardContent?: GoogleCloudDialogflowV2IntentMessageRbmCardContent|null;
}
export class GoogleCloudDialogflowV2IntentMessageRbmStandaloneCard extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardParameters =
              {}) {
    super();
    this.Serializable$set(
        'cardOrientation',
        (parameters.cardOrientation == null) ? (null) :
                                               (parameters.cardOrientation));
    this.Serializable$set(
        'thumbnailImageAlignment',
        (parameters.thumbnailImageAlignment == null) ?
            (null) :
            (parameters.thumbnailImageAlignment));
    this.Serializable$set(
        'cardContent',
        (parameters.cardContent == null) ? (null) : (parameters.cardContent));
  }

  static get CardOrientation():
      IGoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientationEnum {
    return GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientationEnum;
  }

  static get ThumbnailImageAlignment():
      IGoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignmentEnum {
    return GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignmentEnum;
  }

  get cardContent(): GoogleCloudDialogflowV2IntentMessageRbmCardContent|null {
    return (
        (this.Serializable$has('cardContent')) ?
            (this.Serializable$get('cardContent')) :
            (null));
  }

  /**
   * Required. Card content.
   */
  set cardContent(value: GoogleCloudDialogflowV2IntentMessageRbmCardContent|
                  null) {
    this.Serializable$set('cardContent', value);
  }

  get cardOrientation():
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation
      |null {
    return (
        (this.Serializable$has('cardOrientation')) ?
            (this.Serializable$get('cardOrientation')) :
            (null));
  }

  /**
   * Required. Orientation of the card.
   */
  set cardOrientation(
      value:
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientation|
      null) {
    this.Serializable$set('cardOrientation', value);
  }

  get thumbnailImageAlignment():
      GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment
      |null {
    return (
        (this.Serializable$has('thumbnailImageAlignment')) ?
            (this.Serializable$get('thumbnailImageAlignment')) :
            (null));
  }

  /**
   * Required if orientation is horizontal. Image preview alignment for
   * standalone cards with horizontal layout.
   */
  set thumbnailImageAlignment(
      value:
          GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignment|
      null) {
    this.Serializable$set('thumbnailImageAlignment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageRbmStandaloneCard> {
    return GoogleCloudDialogflowV2IntentMessageRbmStandaloneCard;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'cardOrientation':
            GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardCardOrientationEnum,
        'thumbnailImageAlignment':
            GoogleCloudDialogflowV2IntentMessageRbmStandaloneCardThumbnailImageAlignmentEnum
      },
      keys: ['cardContent', 'cardOrientation', 'thumbnailImageAlignment'],
      objects:
          {'cardContent': GoogleCloudDialogflowV2IntentMessageRbmCardContent}
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionParameters {
  text?: string|null;
  postbackData?: string|null;
  dial?:
      GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionDial|
      null;
  openUrl?:
      GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri|
      null;
  shareLocation?:
      GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation|
      null;
}
export class GoogleCloudDialogflowV2IntentMessageRbmSuggestedAction extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionParameters =
              {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'postbackData',
        (parameters.postbackData == null) ? (null) : (parameters.postbackData));
    this.Serializable$set(
        'dial', (parameters.dial == null) ? (null) : (parameters.dial));
    this.Serializable$set(
        'openUrl',
        (parameters.openUrl == null) ? (null) : (parameters.openUrl));
    this.Serializable$set(
        'shareLocation',
        (parameters.shareLocation == null) ? (null) :
                                             (parameters.shareLocation));
  }

  get dial():
      GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionDial
      |null {
    return (
        (this.Serializable$has('dial')) ? (this.Serializable$get('dial')) :
                                          (null));
  }

  /**
   * Suggested client side action: Dial a phone number
   */
  set dial(
      value:
          GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionDial|
      null) {
    this.Serializable$set('dial', value);
  }

  get openUrl():
      GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri
      |null {
    return (
        (this.Serializable$has('openUrl')) ?
            (this.Serializable$get('openUrl')) :
            (null));
  }

  /**
   * Suggested client side action: Open a URI on device
   */
  set openUrl(
      value:
          GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri|
      null) {
    this.Serializable$set('openUrl', value);
  }

  get postbackData(): string|null {
    return (
        (this.Serializable$has('postbackData')) ?
            (this.Serializable$get('postbackData')) :
            (null));
  }

  /**
   * Opaque payload that the Dialogflow receives in a user event when the user
   * taps the suggested action. This data will be also forwarded to webhook to
   * allow performing custom business logic.
   */
  set postbackData(value: string|null) {
    this.Serializable$set('postbackData', value);
  }

  get shareLocation():
      GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation
      |null {
    return (
        (this.Serializable$has('shareLocation')) ?
            (this.Serializable$get('shareLocation')) :
            (null));
  }

  /**
   * Suggested client side action: Share user location
   */
  set shareLocation(
      value:
          GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation|
      null) {
    this.Serializable$set('shareLocation', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Text to display alongside the action.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageRbmSuggestedAction> {
    return GoogleCloudDialogflowV2IntentMessageRbmSuggestedAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['dial', 'openUrl', 'postbackData', 'shareLocation', 'text'],
      objects: {
        'dial':
            GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionDial,
        'openUrl':
            GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri,
        'shareLocation':
            GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionDialParameters {
  phoneNumber?: string|null;
}
export class
    GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionDial extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionDialParameters =
              {}) {
    super();
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
  }

  get phoneNumber(): string|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Required. The phone number to fill in the default dialer app. This field
   * should be in [E.164](https://en.wikipedia.org/wiki/E.164) format. An
   * example of a correctly formatted phone number: +15556767888.
   */
  set phoneNumber(value: string|null) {
    this.Serializable$set('phoneNumber', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionDial> {
    return GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionDial;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['phoneNumber']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUriParameters {
  uri?: string|null;
}
export class
    GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUriParameters =
              {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The uri to open on the user device
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri> {
    return GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocationParameters {
}
export class
    GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocationParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation> {
    return GoogleCloudDialogflowV2IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmSuggestedReplyParameters {
  text?: string|null;
  postbackData?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageRbmSuggestedReply extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageRbmSuggestedReplyParameters =
              {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'postbackData',
        (parameters.postbackData == null) ? (null) : (parameters.postbackData));
  }

  get postbackData(): string|null {
    return (
        (this.Serializable$has('postbackData')) ?
            (this.Serializable$get('postbackData')) :
            (null));
  }

  /**
   * Opaque payload that the Dialogflow receives in a user event when the user
   * taps the suggested reply. This data will be also forwarded to webhook to
   * allow performing custom business logic.
   */
  set postbackData(value: string|null) {
    this.Serializable$set('postbackData', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Suggested reply text.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageRbmSuggestedReply> {
    return GoogleCloudDialogflowV2IntentMessageRbmSuggestedReply;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['postbackData', 'text']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmSuggestionParameters {
  reply?: GoogleCloudDialogflowV2IntentMessageRbmSuggestedReply|null;
  action?: GoogleCloudDialogflowV2IntentMessageRbmSuggestedAction|null;
}
export class GoogleCloudDialogflowV2IntentMessageRbmSuggestion extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageRbmSuggestionParameters = {}) {
    super();
    this.Serializable$set(
        'reply', (parameters.reply == null) ? (null) : (parameters.reply));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
  }

  get action(): GoogleCloudDialogflowV2IntentMessageRbmSuggestedAction|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Predefined client side actions that user can choose
   */
  set action(value: GoogleCloudDialogflowV2IntentMessageRbmSuggestedAction|
             null) {
    this.Serializable$set('action', value);
  }

  get reply(): GoogleCloudDialogflowV2IntentMessageRbmSuggestedReply|null {
    return (
        (this.Serializable$has('reply')) ? (this.Serializable$get('reply')) :
                                           (null));
  }

  /**
   * Predefined replies for user to select instead of typing
   */
  set reply(value: GoogleCloudDialogflowV2IntentMessageRbmSuggestedReply|null) {
    this.Serializable$set('reply', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageRbmSuggestion> {
    return GoogleCloudDialogflowV2IntentMessageRbmSuggestion;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['action', 'reply'],
      objects: {
        'action': GoogleCloudDialogflowV2IntentMessageRbmSuggestedAction,
        'reply': GoogleCloudDialogflowV2IntentMessageRbmSuggestedReply
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageRbmTextParameters {
  text?: string|null;
  rbmSuggestion?: Array<GoogleCloudDialogflowV2IntentMessageRbmSuggestion>|null;
}
export class GoogleCloudDialogflowV2IntentMessageRbmText extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2IntentMessageRbmTextParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'rbmSuggestion',
        (parameters.rbmSuggestion == null) ? (null) :
                                             (parameters.rbmSuggestion));
  }

  get rbmSuggestion():
      Array<GoogleCloudDialogflowV2IntentMessageRbmSuggestion>|null {
    return (
        (this.Serializable$has('rbmSuggestion')) ?
            (this.Serializable$get('rbmSuggestion')) :
            (null));
  }

  /**
   * Optional. One or more suggestions to show to the user.
   */
  set rbmSuggestion(
      value: Array<GoogleCloudDialogflowV2IntentMessageRbmSuggestion>|null) {
    this.Serializable$set('rbmSuggestion', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. Text sent and displayed to the user.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageRbmText> {
    return GoogleCloudDialogflowV2IntentMessageRbmText;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'rbmSuggestion': GoogleCloudDialogflowV2IntentMessageRbmSuggestion},
      keys: ['rbmSuggestion', 'text']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageSelectItemInfoParameters {
  key?: string|null;
  synonyms?: Array<string>|null;
}
export class GoogleCloudDialogflowV2IntentMessageSelectItemInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageSelectItemInfoParameters = {}) {
    super();
    this.Serializable$set(
        'key', (parameters.key == null) ? (null) : (parameters.key));
    this.Serializable$set(
        'synonyms',
        (parameters.synonyms == null) ? (null) : (parameters.synonyms));
  }

  get key(): string|null {
    return (
        (this.Serializable$has('key')) ? (this.Serializable$get('key')) :
                                         (null));
  }

  /**
   * Required. A unique key that will be sent back to the agent if this response
   * is given.
   */
  set key(value: string|null) {
    this.Serializable$set('key', value);
  }

  get synonyms(): Array<string>|null {
    return (
        (this.Serializable$has('synonyms')) ?
            (this.Serializable$get('synonyms')) :
            (null));
  }

  /**
   * Optional. A list of synonyms that can also be used to trigger this item in
   * dialog.
   */
  set synonyms(value: Array<string>|null) {
    this.Serializable$set('synonyms', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageSelectItemInfo> {
    return GoogleCloudDialogflowV2IntentMessageSelectItemInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['key', 'synonyms']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageSimpleResponseParameters {
  textToSpeech?: string|null;
  ssml?: string|null;
  displayText?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageSimpleResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageSimpleResponseParameters = {}) {
    super();
    this.Serializable$set(
        'textToSpeech',
        (parameters.textToSpeech == null) ? (null) : (parameters.textToSpeech));
    this.Serializable$set(
        'ssml', (parameters.ssml == null) ? (null) : (parameters.ssml));
    this.Serializable$set(
        'displayText',
        (parameters.displayText == null) ? (null) : (parameters.displayText));
  }

  get displayText(): string|null {
    return (
        (this.Serializable$has('displayText')) ?
            (this.Serializable$get('displayText')) :
            (null));
  }

  /**
   * Optional. The text to display.
   */
  set displayText(value: string|null) {
    this.Serializable$set('displayText', value);
  }

  get ssml(): string|null {
    return (
        (this.Serializable$has('ssml')) ? (this.Serializable$get('ssml')) :
                                          (null));
  }

  /**
   * One of text_to_speech or ssml must be provided. Structured spoken response
   * to the user in the SSML format. Mutually exclusive with text_to_speech.
   */
  set ssml(value: string|null) {
    this.Serializable$set('ssml', value);
  }

  get textToSpeech(): string|null {
    return (
        (this.Serializable$has('textToSpeech')) ?
            (this.Serializable$get('textToSpeech')) :
            (null));
  }

  /**
   * One of text_to_speech or ssml must be provided. The plain text of the
   * speech output. Mutually exclusive with ssml.
   */
  set textToSpeech(value: string|null) {
    this.Serializable$set('textToSpeech', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageSimpleResponse> {
    return GoogleCloudDialogflowV2IntentMessageSimpleResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayText', 'ssml', 'textToSpeech']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageSimpleResponsesParameters {
  simpleResponses?: Array<GoogleCloudDialogflowV2IntentMessageSimpleResponse>|
      null;
}
export class GoogleCloudDialogflowV2IntentMessageSimpleResponses extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageSimpleResponsesParameters = {}) {
    super();
    this.Serializable$set(
        'simpleResponses',
        (parameters.simpleResponses == null) ? (null) :
                                               (parameters.simpleResponses));
  }

  get simpleResponses():
      Array<GoogleCloudDialogflowV2IntentMessageSimpleResponse>|null {
    return (
        (this.Serializable$has('simpleResponses')) ?
            (this.Serializable$get('simpleResponses')) :
            (null));
  }

  /**
   * Required. The list of simple responses.
   */
  set simpleResponses(
      value: Array<GoogleCloudDialogflowV2IntentMessageSimpleResponse>|null) {
    this.Serializable$set('simpleResponses', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageSimpleResponses> {
    return GoogleCloudDialogflowV2IntentMessageSimpleResponses;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'simpleResponses': GoogleCloudDialogflowV2IntentMessageSimpleResponse
      },
      keys: ['simpleResponses']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageSuggestionParameters {
  title?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageSuggestion extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageSuggestionParameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. The text shown the in the suggestion chip.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageSuggestion> {
    return GoogleCloudDialogflowV2IntentMessageSuggestion;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['title']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageSuggestionsParameters {
  suggestions?: Array<GoogleCloudDialogflowV2IntentMessageSuggestion>|null;
}
export class GoogleCloudDialogflowV2IntentMessageSuggestions extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageSuggestionsParameters = {}) {
    super();
    this.Serializable$set(
        'suggestions',
        (parameters.suggestions == null) ? (null) : (parameters.suggestions));
  }

  get suggestions():
      Array<GoogleCloudDialogflowV2IntentMessageSuggestion>|null {
    return (
        (this.Serializable$has('suggestions')) ?
            (this.Serializable$get('suggestions')) :
            (null));
  }

  /**
   * Required. The list of suggested replies.
   */
  set suggestions(value: Array<GoogleCloudDialogflowV2IntentMessageSuggestion>|
                  null) {
    this.Serializable$set('suggestions', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageSuggestions> {
    return GoogleCloudDialogflowV2IntentMessageSuggestions;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'suggestions': GoogleCloudDialogflowV2IntentMessageSuggestion},
      keys: ['suggestions']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageTableCardParameters {
  title?: string|null;
  subtitle?: string|null;
  image?: GoogleCloudDialogflowV2IntentMessageImage|null;
  columnProperties?:
      Array<GoogleCloudDialogflowV2IntentMessageColumnProperties>|null;
  rows?: Array<GoogleCloudDialogflowV2IntentMessageTableCardRow>|null;
  buttons?: Array<GoogleCloudDialogflowV2IntentMessageBasicCardButton>|null;
}
export class GoogleCloudDialogflowV2IntentMessageTableCard extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageTableCardParameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'subtitle',
        (parameters.subtitle == null) ? (null) : (parameters.subtitle));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
    this.Serializable$set(
        'columnProperties',
        (parameters.columnProperties == null) ? (null) :
                                                (parameters.columnProperties));
    this.Serializable$set(
        'rows', (parameters.rows == null) ? (null) : (parameters.rows));
    this.Serializable$set(
        'buttons',
        (parameters.buttons == null) ? (null) : (parameters.buttons));
  }

  get buttons():
      Array<GoogleCloudDialogflowV2IntentMessageBasicCardButton>|null {
    return (
        (this.Serializable$has('buttons')) ?
            (this.Serializable$get('buttons')) :
            (null));
  }

  /**
   * Optional. List of buttons for the card.
   */
  set buttons(value: Array<GoogleCloudDialogflowV2IntentMessageBasicCardButton>|
              null) {
    this.Serializable$set('buttons', value);
  }

  get columnProperties():
      Array<GoogleCloudDialogflowV2IntentMessageColumnProperties>|null {
    return (
        (this.Serializable$has('columnProperties')) ?
            (this.Serializable$get('columnProperties')) :
            (null));
  }

  /**
   * Optional. Display properties for the columns in this table.
   */
  set columnProperties(
      value: Array<GoogleCloudDialogflowV2IntentMessageColumnProperties>|null) {
    this.Serializable$set('columnProperties', value);
  }

  get image(): GoogleCloudDialogflowV2IntentMessageImage|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Optional. Image which should be displayed on the card.
   */
  set image(value: GoogleCloudDialogflowV2IntentMessageImage|null) {
    this.Serializable$set('image', value);
  }

  get rows(): Array<GoogleCloudDialogflowV2IntentMessageTableCardRow>|null {
    return (
        (this.Serializable$has('rows')) ? (this.Serializable$get('rows')) :
                                          (null));
  }

  /**
   * Optional. Rows in this table of data.
   */
  set rows(value: Array<GoogleCloudDialogflowV2IntentMessageTableCardRow>|
           null) {
    this.Serializable$set('rows', value);
  }

  get subtitle(): string|null {
    return (
        (this.Serializable$has('subtitle')) ?
            (this.Serializable$get('subtitle')) :
            (null));
  }

  /**
   * Optional. Subtitle to the title.
   */
  set subtitle(value: string|null) {
    this.Serializable$set('subtitle', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. Title of the card.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageTableCard> {
    return GoogleCloudDialogflowV2IntentMessageTableCard;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'buttons': GoogleCloudDialogflowV2IntentMessageBasicCardButton,
        'columnProperties':
            GoogleCloudDialogflowV2IntentMessageColumnProperties,
        'rows': GoogleCloudDialogflowV2IntentMessageTableCardRow
      },
      keys:
          ['buttons', 'columnProperties', 'image', 'rows', 'subtitle', 'title'],
      objects: {'image': GoogleCloudDialogflowV2IntentMessageImage}
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageTableCardCellParameters {
  text?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageTableCardCell extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageTableCardCellParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. Text in this cell.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageTableCardCell> {
    return GoogleCloudDialogflowV2IntentMessageTableCardCell;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['text']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageTableCardRowParameters {
  cells?: Array<GoogleCloudDialogflowV2IntentMessageTableCardCell>|null;
  dividerAfter?: boolean|null;
}
export class GoogleCloudDialogflowV2IntentMessageTableCardRow extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageTableCardRowParameters = {}) {
    super();
    this.Serializable$set(
        'cells', (parameters.cells == null) ? (null) : (parameters.cells));
    this.Serializable$set(
        'dividerAfter',
        (parameters.dividerAfter == null) ? (null) : (parameters.dividerAfter));
  }

  get cells(): Array<GoogleCloudDialogflowV2IntentMessageTableCardCell>|null {
    return (
        (this.Serializable$has('cells')) ? (this.Serializable$get('cells')) :
                                           (null));
  }

  /**
   * Optional. List of cells that make up this row.
   */
  set cells(value: Array<GoogleCloudDialogflowV2IntentMessageTableCardCell>|
            null) {
    this.Serializable$set('cells', value);
  }

  get dividerAfter(): boolean|null {
    return (
        (this.Serializable$has('dividerAfter')) ?
            (this.Serializable$get('dividerAfter')) :
            (null));
  }

  /**
   * Optional. Whether to add a visual divider after this row.
   */
  set dividerAfter(value: boolean|null) {
    this.Serializable$set('dividerAfter', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageTableCardRow> {
    return GoogleCloudDialogflowV2IntentMessageTableCardRow;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'cells': GoogleCloudDialogflowV2IntentMessageTableCardCell},
      keys: ['cells', 'dividerAfter']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageTelephonyAudioParameters {
  segments?: Array<string>|null;
}
export class GoogleCloudDialogflowV2IntentMessageTelephonyAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageTelephonyAudioParameters = {}) {
    super();
    this.Serializable$set(
        'segments',
        (parameters.segments == null) ? (null) : (parameters.segments));
  }

  get segments(): Array<string>|null {
    return (
        (this.Serializable$has('segments')) ?
            (this.Serializable$get('segments')) :
            (null));
  }

  /**
   * Segments of audio.
   */
  set segments(value: Array<string>|null) {
    this.Serializable$set('segments', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageTelephonyAudio> {
    return GoogleCloudDialogflowV2IntentMessageTelephonyAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['segments']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageTelephonyPlayAudioParameters {
  audioUri?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageTelephonyPlayAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageTelephonyPlayAudioParameters =
              {}) {
    super();
    this.Serializable$set(
        'audioUri',
        (parameters.audioUri == null) ? (null) : (parameters.audioUri));
  }

  get audioUri(): string|null {
    return (
        (this.Serializable$has('audioUri')) ?
            (this.Serializable$get('audioUri')) :
            (null));
  }

  /**
   * Required. URI to a Google Cloud Storage object containing the audio to
   * play, e.g., \"gs://bucket/object\". The object must contain a single
   * channel (mono) of linear PCM audio (2 bytes / sample) at 8kHz. This object
   * must be readable by the
   * `service-@gcp-sa-dialogflow.iam.gserviceaccount.com` service account where
   * is the number of the Telephony Gateway project (usually the same as the
   * Dialogflow agent project). If the Google Cloud Storage bucket is in the
   * Telephony Gateway project, this permission is added by default when
   * enabling the Dialogflow V2 API. For audio from other sources, consider
   * using the `TelephonySynthesizeSpeech` message with SSML.
   */
  set audioUri(value: string|null) {
    this.Serializable$set('audioUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageTelephonyPlayAudio> {
    return GoogleCloudDialogflowV2IntentMessageTelephonyPlayAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audioUri']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfParameters {
  maxDuration?: string|null;
  maxDigits?: number|null;
  finishDigit?:
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit|null;
  listenToSpeech?: boolean|null;
}
export class GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmf extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfParameters =
              {}) {
    super();
    this.Serializable$set(
        'maxDuration',
        (parameters.maxDuration == null) ? (null) : (parameters.maxDuration));
    this.Serializable$set(
        'maxDigits',
        (parameters.maxDigits == null) ? (null) : (parameters.maxDigits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
    this.Serializable$set(
        'listenToSpeech',
        (parameters.listenToSpeech == null) ? (null) :
                                              (parameters.listenToSpeech));
  }

  static get FinishDigit():
      IGoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum {
    return GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum;
  }

  get finishDigit():
      GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * Optional. DTMF digit that finishes DTMF collection even if `max_duration`
   * hasn't passed. If unspecified, we don't finish on any specific digit. The
   * digits in the event will **not** contain the finishing digit.
   */
  set finishDigit(
      value: GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigit|
      null) {
    this.Serializable$set('finishDigit', value);
  }

  get listenToSpeech(): boolean|null {
    return (
        (this.Serializable$has('listenToSpeech')) ?
            (this.Serializable$get('listenToSpeech')) :
            (null));
  }

  /**
   * Optional. Whether to continue listening to speech while reading DTMF
   * digits. If unspecified or false, we don't listen to speech. If `true` and
   * we recognize a Speech utterance before reading DTMF finishes, we respond to
   * this utterance and discard DTMF results.
   */
  set listenToSpeech(value: boolean|null) {
    this.Serializable$set('listenToSpeech', value);
  }

  get maxDigits(): number|null {
    return (
        (this.Serializable$has('maxDigits')) ?
            (this.Serializable$get('maxDigits')) :
            (null));
  }

  /**
   * Optional. Maximum number of DTMF digits to read. When we get `max_digits`
   * DTMF digits, we stop even if `max_duration` hasn't passed. If unspecified
   * or nonpositive, we don't limit the number of digits.
   */
  set maxDigits(value: number|null) {
    this.Serializable$set('maxDigits', value);
  }

  get maxDuration(): string|null {
    return (
        (this.Serializable$has('maxDuration')) ?
            (this.Serializable$get('maxDuration')) :
            (null));
  }

  /**
   * Required. Maximum duration to read DTMF digits for. If this duration is
   * below 5s, we use 5s. If this duration is above 30s, we use 30s.
   */
  set maxDuration(value: string|null) {
    this.Serializable$set('maxDuration', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmf> {
    return GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmf;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'finishDigit':
            GoogleCloudDialogflowV2IntentMessageTelephonyReadDtmfFinishDigitEnum
      },
      keys: ['finishDigit', 'listenToSpeech', 'maxDigits', 'maxDuration']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentMessageTelephonySynthesizeSpeechParameters {
  text?: string|null;
  ssml?: string|null;
}
export class
    GoogleCloudDialogflowV2IntentMessageTelephonySynthesizeSpeech extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageTelephonySynthesizeSpeechParameters =
              {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'ssml', (parameters.ssml == null) ? (null) : (parameters.ssml));
  }

  get ssml(): string|null {
    return (
        (this.Serializable$has('ssml')) ? (this.Serializable$get('ssml')) :
                                          (null));
  }

  /**
   * The SSML to be synthesized. For more information, see
   * [SSML](https://developers.google.com/actions/reference/ssml).
   */
  set ssml(value: string|null) {
    this.Serializable$set('ssml', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The raw text to be synthesized.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageTelephonySynthesizeSpeech> {
    return GoogleCloudDialogflowV2IntentMessageTelephonySynthesizeSpeech;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['ssml', 'text']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageTelephonyTerminateCallParameters {
}
export class GoogleCloudDialogflowV2IntentMessageTelephonyTerminateCall extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageTelephonyTerminateCallParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageTelephonyTerminateCall> {
    return GoogleCloudDialogflowV2IntentMessageTelephonyTerminateCall;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageTelephonyTransferCallParameters {
  phoneNumber?: string|null;
}
export class GoogleCloudDialogflowV2IntentMessageTelephonyTransferCall extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentMessageTelephonyTransferCallParameters =
              {}) {
    super();
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
  }

  get phoneNumber(): string|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Required. The phone number to transfer the call to in [E.164
   * format](https://en.wikipedia.org/wiki/E.164). We currently only allow
   * transferring to US numbers (+1xxxyyyzzzz).
   */
  set phoneNumber(value: string|null) {
    this.Serializable$set('phoneNumber', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IntentMessageTelephonyTransferCall> {
    return GoogleCloudDialogflowV2IntentMessageTelephonyTransferCall;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['phoneNumber']};
  }
}

export interface GoogleCloudDialogflowV2IntentMessageTextParameters {
  text?: Array<string>|null;
}
export class GoogleCloudDialogflowV2IntentMessageText extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2IntentMessageTextParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
  }

  get text(): Array<string>|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Optional. The collection of the agent's responses.
   */
  set text(value: Array<string>|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2IntentMessageText> {
    return GoogleCloudDialogflowV2IntentMessageText;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['text']};
  }
}

export interface GoogleCloudDialogflowV2IntentParameterParameters {
  name?: string|null;
  displayName?: string|null;
  value?: string|null;
  defaultValue?: string|null;
  entityTypeDisplayName?: string|null;
  mandatory?: boolean|null;
  prompts?: Array<string>|null;
  promptMessages?: Array<GoogleCloudDialogflowV2IntentMessage>|null;
  noMatchPromptMessages?: Array<GoogleCloudDialogflowV2IntentMessage>|null;
  noInputPromptMessages?: Array<GoogleCloudDialogflowV2IntentMessage>|null;
  isList?: boolean|null;
  outputDialogContexts?: Array<GoogleCloudDialogflowV2Context>|null;
  repromptsCount?: number|null;
}
export class GoogleCloudDialogflowV2IntentParameter extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2IntentParameterParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
    this.Serializable$set(
        'defaultValue',
        (parameters.defaultValue == null) ? (null) : (parameters.defaultValue));
    this.Serializable$set(
        'entityTypeDisplayName',
        (parameters.entityTypeDisplayName == null) ?
            (null) :
            (parameters.entityTypeDisplayName));
    this.Serializable$set(
        'mandatory',
        (parameters.mandatory == null) ? (null) : (parameters.mandatory));
    this.Serializable$set(
        'prompts',
        (parameters.prompts == null) ? (null) : (parameters.prompts));
    this.Serializable$set(
        'promptMessages',
        (parameters.promptMessages == null) ? (null) :
                                              (parameters.promptMessages));
    this.Serializable$set(
        'noMatchPromptMessages',
        (parameters.noMatchPromptMessages == null) ?
            (null) :
            (parameters.noMatchPromptMessages));
    this.Serializable$set(
        'noInputPromptMessages',
        (parameters.noInputPromptMessages == null) ?
            (null) :
            (parameters.noInputPromptMessages));
    this.Serializable$set(
        'isList', (parameters.isList == null) ? (null) : (parameters.isList));
    this.Serializable$set(
        'outputDialogContexts',
        (parameters.outputDialogContexts == null) ?
            (null) :
            (parameters.outputDialogContexts));
    this.Serializable$set(
        'repromptsCount',
        (parameters.repromptsCount == null) ? (null) :
                                              (parameters.repromptsCount));
  }

  get defaultValue(): string|null {
    return (
        (this.Serializable$has('defaultValue')) ?
            (this.Serializable$get('defaultValue')) :
            (null));
  }

  /**
   * Optional. The default value to use when the `value` yields an empty result.
   * Default values can be extracted from contexts by using the following
   * syntax: `#context_name.parameter_name`.
   */
  set defaultValue(value: string|null) {
    this.Serializable$set('defaultValue', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The name of the parameter.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get entityTypeDisplayName(): string|null {
    return (
        (this.Serializable$has('entityTypeDisplayName')) ?
            (this.Serializable$get('entityTypeDisplayName')) :
            (null));
  }

  /**
   * Optional. The name of the entity type, prefixed with `@`, that describes
   * values of the parameter. If the parameter is required, this must be
   * provided.
   */
  set entityTypeDisplayName(value: string|null) {
    this.Serializable$set('entityTypeDisplayName', value);
  }

  get isList(): boolean|null {
    return (
        (this.Serializable$has('isList')) ? (this.Serializable$get('isList')) :
                                            (null));
  }

  /**
   * Optional. Indicates whether the parameter represents a list of values.
   */
  set isList(value: boolean|null) {
    this.Serializable$set('isList', value);
  }

  get mandatory(): boolean|null {
    return (
        (this.Serializable$has('mandatory')) ?
            (this.Serializable$get('mandatory')) :
            (null));
  }

  /**
   * Optional. Indicates whether the parameter is required. That is, whether the
   * intent cannot be completed without collecting the parameter value.
   */
  set mandatory(value: boolean|null) {
    this.Serializable$set('mandatory', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of this parameter.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get noInputPromptMessages():
      Array<GoogleCloudDialogflowV2IntentMessage>|null {
    return (
        (this.Serializable$has('noInputPromptMessages')) ?
            (this.Serializable$get('noInputPromptMessages')) :
            (null));
  }

  /**
   * Optional. The collection of rich message prompts that the agent can present
   * to the user when the user didn't answer the previous agent prompt question.
   */
  set noInputPromptMessages(value: Array<GoogleCloudDialogflowV2IntentMessage>|
                            null) {
    this.Serializable$set('noInputPromptMessages', value);
  }

  get noMatchPromptMessages():
      Array<GoogleCloudDialogflowV2IntentMessage>|null {
    return (
        (this.Serializable$has('noMatchPromptMessages')) ?
            (this.Serializable$get('noMatchPromptMessages')) :
            (null));
  }

  /**
   * Optional. The collection of rich message prompts that the agent can present
   * to the user when the user's previous answer didn't match the agent prompt
   * question.
   */
  set noMatchPromptMessages(value: Array<GoogleCloudDialogflowV2IntentMessage>|
                            null) {
    this.Serializable$set('noMatchPromptMessages', value);
  }

  get outputDialogContexts(): Array<GoogleCloudDialogflowV2Context>|null {
    return (
        (this.Serializable$has('outputDialogContexts')) ?
            (this.Serializable$get('outputDialogContexts')) :
            (null));
  }

  /**
   * Optional. The collection of contexts that are activated during collection
   * of this parameter (slot-filling). By default the lifespan in context should
   * be 1.
   */
  set outputDialogContexts(value: Array<GoogleCloudDialogflowV2Context>|null) {
    this.Serializable$set('outputDialogContexts', value);
  }

  get promptMessages(): Array<GoogleCloudDialogflowV2IntentMessage>|null {
    return (
        (this.Serializable$has('promptMessages')) ?
            (this.Serializable$get('promptMessages')) :
            (null));
  }

  /**
   * Optional. The collection of rich message prompts that the agent can present
   * to the user in order to collect a value for the parameter.
   */
  set promptMessages(value: Array<GoogleCloudDialogflowV2IntentMessage>|null) {
    this.Serializable$set('promptMessages', value);
  }

  get prompts(): Array<string>|null {
    return (
        (this.Serializable$has('prompts')) ?
            (this.Serializable$get('prompts')) :
            (null));
  }

  /**
   * Optional. The collection of prompts that the agent can present to the user
   * in order to collect a value for the parameter.
   */
  set prompts(value: Array<string>|null) {
    this.Serializable$set('prompts', value);
  }

  get repromptsCount(): number|null {
    return (
        (this.Serializable$has('repromptsCount')) ?
            (this.Serializable$get('repromptsCount')) :
            (null));
  }

  /**
   * Optional. Number of reprompts for mandatory parameters. System will go to
   * 'no match' if parameter was not collected in specified number of reprompts.
   * Defaults to '0' that is effectively 'unlimited'.
   */
  set repromptsCount(value: number|null) {
    this.Serializable$set('repromptsCount', value);
  }

  get value(): string|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Optional. The definition of the parameter value. It can be: - a constant
   * string, - a parameter value defined as `$parameter_name`, - an original
   * parameter value defined as `$parameter_name.original`, - a parameter value
   * from some context defined as `#context_name.parameter_name`.
   */
  set value(value: string|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2IntentParameter> {
    return GoogleCloudDialogflowV2IntentParameter;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'noInputPromptMessages': GoogleCloudDialogflowV2IntentMessage,
        'noMatchPromptMessages': GoogleCloudDialogflowV2IntentMessage,
        'outputDialogContexts': GoogleCloudDialogflowV2Context,
        'promptMessages': GoogleCloudDialogflowV2IntentMessage
      },
      keys: [
        'defaultValue', 'displayName', 'entityTypeDisplayName', 'isList',
        'mandatory', 'name', 'noInputPromptMessages', 'noMatchPromptMessages',
        'outputDialogContexts', 'promptMessages', 'prompts', 'repromptsCount',
        'value'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV2IntentTrainingPhraseParameters {
  name?: string|null;
  type?: GoogleCloudDialogflowV2IntentTrainingPhraseType|null;
  parts?: Array<GoogleCloudDialogflowV2IntentTrainingPhrasePart>|null;
  timesAddedCount?: number|null;
}
export class GoogleCloudDialogflowV2IntentTrainingPhrase extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2IntentTrainingPhraseParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'parts', (parameters.parts == null) ? (null) : (parameters.parts));
    this.Serializable$set(
        'timesAddedCount',
        (parameters.timesAddedCount == null) ? (null) :
                                               (parameters.timesAddedCount));
  }

  static get Type(): IGoogleCloudDialogflowV2IntentTrainingPhraseTypeEnum {
    return GoogleCloudDialogflowV2IntentTrainingPhraseTypeEnum;
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this training phrase.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parts(): Array<GoogleCloudDialogflowV2IntentTrainingPhrasePart>|null {
    return (
        (this.Serializable$has('parts')) ? (this.Serializable$get('parts')) :
                                           (null));
  }

  /**
   * Required. The ordered list of training phrase parts. The parts are
   * concatenated in order to form the training phrase. Note: The API does not
   * automatically annotate training phrases like the Dialogflow Console does.
   * Note: Do not forget to include whitespace at part boundaries, so the
   * training phrase is well formatted when the parts are concatenated. If the
   * training phrase does not need to be annotated with parameters, you just
   * need a single part with only the Part.text field set. If you want to
   * annotate the training phrase, you must create multiple parts, where the
   * fields of each part are populated in one of two ways: - `Part.text` is set
   * to a part of the phrase that has no parameters. - `Part.text` is set to a
   * part of the phrase that you want to annotate, and the `entity_type`,
   * `alias`, and `user_defined` fields are all set.
   */
  set parts(value: Array<GoogleCloudDialogflowV2IntentTrainingPhrasePart>|
            null) {
    this.Serializable$set('parts', value);
  }

  get timesAddedCount(): number|null {
    return (
        (this.Serializable$has('timesAddedCount')) ?
            (this.Serializable$get('timesAddedCount')) :
            (null));
  }

  /**
   * Optional. Indicates how many times this example was added to the intent.
   * Each time a developer adds an existing sample by editing an intent or
   * training, this counter is increased.
   */
  set timesAddedCount(value: number|null) {
    this.Serializable$set('timesAddedCount', value);
  }

  get type(): GoogleCloudDialogflowV2IntentTrainingPhraseType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Required. The type of the training phrase.
   */
  set type(value: GoogleCloudDialogflowV2IntentTrainingPhraseType|null) {
    this.Serializable$set('type', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentTrainingPhrase> {
    return GoogleCloudDialogflowV2IntentTrainingPhrase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parts': GoogleCloudDialogflowV2IntentTrainingPhrasePart},
      enums: {'type': GoogleCloudDialogflowV2IntentTrainingPhraseTypeEnum},
      keys: ['name', 'parts', 'timesAddedCount', 'type']
    };
  }
}

export interface GoogleCloudDialogflowV2IntentTrainingPhrasePartParameters {
  text?: string|null;
  entityType?: string|null;
  alias?: string|null;
  userDefined?: boolean|null;
}
export class GoogleCloudDialogflowV2IntentTrainingPhrasePart extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IntentTrainingPhrasePartParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
    this.Serializable$set(
        'alias', (parameters.alias == null) ? (null) : (parameters.alias));
    this.Serializable$set(
        'userDefined',
        (parameters.userDefined == null) ? (null) : (parameters.userDefined));
  }

  get alias(): string|null {
    return (
        (this.Serializable$has('alias')) ? (this.Serializable$get('alias')) :
                                           (null));
  }

  /**
   * Optional. The parameter name for the value extracted from the annotated
   * part of the example. This field is required for annotated parts of the
   * training phrase.
   */
  set alias(value: string|null) {
    this.Serializable$set('alias', value);
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * Optional. The entity type name prefixed with `@`. This field is required
   * for annotated parts of the training phrase.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. The text for this part.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  get userDefined(): boolean|null {
    return (
        (this.Serializable$has('userDefined')) ?
            (this.Serializable$get('userDefined')) :
            (null));
  }

  /**
   * Optional. Indicates whether the text was manually annotated. This field is
   * set to true when the Dialogflow Console is used to manually annotate the
   * part. When creating an annotated part with the API, you must set this to
   * true.
   */
  set userDefined(value: boolean|null) {
    this.Serializable$set('userDefined', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IntentTrainingPhrasePart> {
    return GoogleCloudDialogflowV2IntentTrainingPhrasePart;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['alias', 'entityType', 'text', 'userDefined']};
  }
}

export interface GoogleCloudDialogflowV2IssueModelMetadataParameters {
  associatedCandidatesInfo?:
      GoogleCloudDialogflowV2IssueModelMetadataAssociateCandidatesInfo|null;
  internalTrainingFlags?: string|null;
  trainingModelType?:
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType|null;
}
export class GoogleCloudDialogflowV2IssueModelMetadata extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2IssueModelMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'associatedCandidatesInfo',
        (parameters.associatedCandidatesInfo == null) ?
            (null) :
            (parameters.associatedCandidatesInfo));
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
  }

  static get TrainingModelType():
      IGoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum {
    return GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum;
  }

  get associatedCandidatesInfo():
      GoogleCloudDialogflowV2IssueModelMetadataAssociateCandidatesInfo|null {
    return (
        (this.Serializable$has('associatedCandidatesInfo')) ?
            (this.Serializable$get('associatedCandidatesInfo')) :
            (null));
  }

  /**
   * The information of the allowlist associated with the conversation model.
   * This field is internal and only to be accessed by the web console.
   */
  set associatedCandidatesInfo(
      value: GoogleCloudDialogflowV2IssueModelMetadataAssociateCandidatesInfo|
      null) {
    this.Serializable$set('associatedCandidatesInfo', value);
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  /**
   * Internal testing only. The text format proto of InternalTrainingFlags
   * defined in cloud/ai/contactcenter/knowledge/proto/conversation_model.proto.
   */
  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get trainingModelType():
      GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType|null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Optional. Type of the issue model. If not provided, model_type is used.
   */
  set trainingModelType(
      value: GoogleCloudDialogflowV2IssueModelMetadataTrainingModelType|null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2IssueModelMetadata> {
    return GoogleCloudDialogflowV2IssueModelMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'trainingModelType':
            GoogleCloudDialogflowV2IssueModelMetadataTrainingModelTypeEnum
      },
      keys: [
        'associatedCandidatesInfo', 'internalTrainingFlags', 'trainingModelType'
      ],
      objects: {
        'associatedCandidatesInfo':
            GoogleCloudDialogflowV2IssueModelMetadataAssociateCandidatesInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowV2IssueModelMetadataAssociateCandidatesInfoParameters {
  document?: string|null;
}
export class
    GoogleCloudDialogflowV2IssueModelMetadataAssociateCandidatesInfo extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2IssueModelMetadataAssociateCandidatesInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The resource name of the associated candidate list. All the candidates are
   * stored within one document. Format: 'projects//knowledgeBases//documents/'
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2IssueModelMetadataAssociateCandidatesInfo> {
    return GoogleCloudDialogflowV2IssueModelMetadataAssociateCandidatesInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['document']};
  }
}

export interface GoogleCloudDialogflowV2KeyMomentModelMetadataParameters {
  modelType?: string|null;
  internalTrainingFlags?: string|null;
}
export class GoogleCloudDialogflowV2KeyMomentModelMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2KeyMomentModelMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'modelType',
        (parameters.modelType == null) ? (null) : (parameters.modelType));
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get modelType(): string|null {
    return (
        (this.Serializable$has('modelType')) ?
            (this.Serializable$get('modelType')) :
            (null));
  }

  /**
   * Type of the smart reply model. The available values are: *
   * `key-moment-model-1` - (default)
   */
  set modelType(value: string|null) {
    this.Serializable$set('modelType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2KeyMomentModelMetadata> {
    return GoogleCloudDialogflowV2KeyMomentModelMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['internalTrainingFlags', 'modelType']};
  }
}

export interface GoogleCloudDialogflowV2KnowledgeAnswersParameters {
  answers?: Array<GoogleCloudDialogflowV2KnowledgeAnswersAnswer>|null;
}
export class GoogleCloudDialogflowV2KnowledgeAnswers extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2KnowledgeAnswersParameters = {}) {
    super();
    this.Serializable$set(
        'answers',
        (parameters.answers == null) ? (null) : (parameters.answers));
  }

  get answers(): Array<GoogleCloudDialogflowV2KnowledgeAnswersAnswer>|null {
    return (
        (this.Serializable$has('answers')) ?
            (this.Serializable$get('answers')) :
            (null));
  }

  /**
   * A list of answers from Knowledge Connector.
   */
  set answers(value: Array<GoogleCloudDialogflowV2KnowledgeAnswersAnswer>|
              null) {
    this.Serializable$set('answers', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2KnowledgeAnswers> {
    return GoogleCloudDialogflowV2KnowledgeAnswers;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'answers': GoogleCloudDialogflowV2KnowledgeAnswersAnswer},
      keys: ['answers']
    };
  }
}

export interface GoogleCloudDialogflowV2KnowledgeAnswersAnswerParameters {
  source?: string|null;
  faqQuestion?: string|null;
  answer?: string|null;
  matchConfidenceLevel?:
      GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel|null;
  matchConfidence?: number|null;
}
export class GoogleCloudDialogflowV2KnowledgeAnswersAnswer extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2KnowledgeAnswersAnswerParameters = {}) {
    super();
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'faqQuestion',
        (parameters.faqQuestion == null) ? (null) : (parameters.faqQuestion));
    this.Serializable$set(
        'answer', (parameters.answer == null) ? (null) : (parameters.answer));
    this.Serializable$set(
        'matchConfidenceLevel',
        (parameters.matchConfidenceLevel == null) ?
            (null) :
            (parameters.matchConfidenceLevel));
    this.Serializable$set(
        'matchConfidence',
        (parameters.matchConfidence == null) ? (null) :
                                               (parameters.matchConfidence));
  }

  static get MatchConfidenceLevel():
      IGoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevelEnum {
    return GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevelEnum;
  }

  get answer(): string|null {
    return (
        (this.Serializable$has('answer')) ? (this.Serializable$get('answer')) :
                                            (null));
  }

  /**
   * The piece of text from the `source` knowledge base document that answers
   * this conversational query.
   */
  set answer(value: string|null) {
    this.Serializable$set('answer', value);
  }

  get faqQuestion(): string|null {
    return (
        (this.Serializable$has('faqQuestion')) ?
            (this.Serializable$get('faqQuestion')) :
            (null));
  }

  /**
   * The corresponding FAQ question if the answer was extracted from a FAQ
   * Document, empty otherwise.
   */
  set faqQuestion(value: string|null) {
    this.Serializable$set('faqQuestion', value);
  }

  get matchConfidence(): number|null {
    return (
        (this.Serializable$has('matchConfidence')) ?
            (this.Serializable$get('matchConfidence')) :
            (null));
  }

  /**
   * The system's confidence score that this Knowledge answer is a good match
   * for this conversational query. The range is from 0.0 (completely uncertain)
   * to 1.0 (completely certain). Note: The confidence score is likely to vary
   * somewhat (possibly even for identical requests), as the underlying model is
   * under constant improvement. It may be deprecated in the future. We
   * recommend using `match_confidence_level` which should be generally more
   * stable.
   */
  set matchConfidence(value: number|null) {
    this.Serializable$set('matchConfidence', value);
  }

  get matchConfidenceLevel():
      GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel|null {
    return (
        (this.Serializable$has('matchConfidenceLevel')) ?
            (this.Serializable$get('matchConfidenceLevel')) :
            (null));
  }

  /**
   * The system's confidence level that this knowledge answer is a good match
   * for this conversational query. NOTE: The confidence level for a given ``
   * pair may change without notice, as it depends on models that are constantly
   * being improved. However, it will change less frequently than the confidence
   * score below, and should be preferred for referencing the quality of an
   * answer.
   */
  set matchConfidenceLevel(
      value: GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevel|
      null) {
    this.Serializable$set('matchConfidenceLevel', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * Indicates which Knowledge Document this answer was extracted from. Format:
   * `projects//knowledgeBases//documents/`.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2KnowledgeAnswersAnswer> {
    return GoogleCloudDialogflowV2KnowledgeAnswersAnswer;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'matchConfidenceLevel':
            GoogleCloudDialogflowV2KnowledgeAnswersAnswerMatchConfidenceLevelEnum
      },
      keys: [
        'answer', 'faqQuestion', 'matchConfidence', 'matchConfidenceLevel',
        'source'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV2KnowledgeOperationMetadataParameters {
  state?: GoogleCloudDialogflowV2KnowledgeOperationMetadataState|null;
  knowledgeBase?: string|null;
  exportOperationMetadata?: GoogleCloudDialogflowV2ExportOperationMetadata|null;
}
export class GoogleCloudDialogflowV2KnowledgeOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2KnowledgeOperationMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'knowledgeBase',
        (parameters.knowledgeBase == null) ? (null) :
                                             (parameters.knowledgeBase));
    this.Serializable$set(
        'exportOperationMetadata',
        (parameters.exportOperationMetadata == null) ?
            (null) :
            (parameters.exportOperationMetadata));
  }

  static get State():
      IGoogleCloudDialogflowV2KnowledgeOperationMetadataStateEnum {
    return GoogleCloudDialogflowV2KnowledgeOperationMetadataStateEnum;
  }

  get exportOperationMetadata(): GoogleCloudDialogflowV2ExportOperationMetadata
      |null {
    return (
        (this.Serializable$has('exportOperationMetadata')) ?
            (this.Serializable$get('exportOperationMetadata')) :
            (null));
  }

  /**
   * Metadata for the Export Data Operation such as the destination of export.
   */
  set exportOperationMetadata(
      value: GoogleCloudDialogflowV2ExportOperationMetadata|null) {
    this.Serializable$set('exportOperationMetadata', value);
  }

  get knowledgeBase(): string|null {
    return (
        (this.Serializable$has('knowledgeBase')) ?
            (this.Serializable$get('knowledgeBase')) :
            (null));
  }

  /**
   * The name of the knowledge base interacted with during the operation.
   */
  set knowledgeBase(value: string|null) {
    this.Serializable$set('knowledgeBase', value);
  }

  get state(): GoogleCloudDialogflowV2KnowledgeOperationMetadataState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Output only. The current state of this operation.
   */
  set state(value: GoogleCloudDialogflowV2KnowledgeOperationMetadataState|
            null) {
    this.Serializable$set('state', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2KnowledgeOperationMetadata> {
    return GoogleCloudDialogflowV2KnowledgeOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums:
          {'state': GoogleCloudDialogflowV2KnowledgeOperationMetadataStateEnum},
      keys: ['exportOperationMetadata', 'knowledgeBase', 'state'],
      objects: {
        'exportOperationMetadata':
            GoogleCloudDialogflowV2ExportOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowV2MessageParameters {
  name?: string|null;
  content?: string|null;
  languageCode?: string|null;
  participant?: string|null;
  participantRole?: GoogleCloudDialogflowV2MessageParticipantRole|null;
  createTime?: string|null;
  sendTime?: string|null;
  messageAnnotation?: GoogleCloudDialogflowV2MessageAnnotation|null;
  sentimentAnalysis?: GoogleCloudDialogflowV2SentimentAnalysisResult|null;
  speechToTextInfo?: GoogleCloudDialogflowV2MessageSpeechToTextInfo|null;
}
export class GoogleCloudDialogflowV2Message extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2MessageParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'participant',
        (parameters.participant == null) ? (null) : (parameters.participant));
    this.Serializable$set(
        'participantRole',
        (parameters.participantRole == null) ? (null) :
                                               (parameters.participantRole));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'sendTime',
        (parameters.sendTime == null) ? (null) : (parameters.sendTime));
    this.Serializable$set(
        'messageAnnotation',
        (parameters.messageAnnotation == null) ?
            (null) :
            (parameters.messageAnnotation));
    this.Serializable$set(
        'sentimentAnalysis',
        (parameters.sentimentAnalysis == null) ?
            (null) :
            (parameters.sentimentAnalysis));
    this.Serializable$set(
        'speechToTextInfo',
        (parameters.speechToTextInfo == null) ? (null) :
                                                (parameters.speechToTextInfo));
  }

  static get ParticipantRole():
      IGoogleCloudDialogflowV2MessageParticipantRoleEnum {
    return GoogleCloudDialogflowV2MessageParticipantRoleEnum;
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Required. The message content.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The time when the message was created in Contact Center AI.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The message language. This should be a
   * [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt) language tag.
   * Example: \"en-US\".
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get messageAnnotation(): GoogleCloudDialogflowV2MessageAnnotation|null {
    return (
        (this.Serializable$has('messageAnnotation')) ?
            (this.Serializable$get('messageAnnotation')) :
            (null));
  }

  /**
   * Output only. The annotation for the message.
   */
  set messageAnnotation(value: GoogleCloudDialogflowV2MessageAnnotation|null) {
    this.Serializable$set('messageAnnotation', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Optional. The unique identifier of the message. Format:
   * `projects//locations//conversations//messages/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get participant(): string|null {
    return (
        (this.Serializable$has('participant')) ?
            (this.Serializable$get('participant')) :
            (null));
  }

  /**
   * Output only. The participant that sends this message.
   */
  set participant(value: string|null) {
    this.Serializable$set('participant', value);
  }

  get participantRole(): GoogleCloudDialogflowV2MessageParticipantRole|null {
    return (
        (this.Serializable$has('participantRole')) ?
            (this.Serializable$get('participantRole')) :
            (null));
  }

  /**
   * Output only. The role of the participant.
   */
  set participantRole(value: GoogleCloudDialogflowV2MessageParticipantRole|
                      null) {
    this.Serializable$set('participantRole', value);
  }

  get sendTime(): string|null {
    return (
        (this.Serializable$has('sendTime')) ?
            (this.Serializable$get('sendTime')) :
            (null));
  }

  /**
   * Optional. The time when the message was sent.
   */
  set sendTime(value: string|null) {
    this.Serializable$set('sendTime', value);
  }

  get sentimentAnalysis(): GoogleCloudDialogflowV2SentimentAnalysisResult|null {
    return (
        (this.Serializable$has('sentimentAnalysis')) ?
            (this.Serializable$get('sentimentAnalysis')) :
            (null));
  }

  /**
   * Output only. The sentiment analysis result for the message.
   */
  set sentimentAnalysis(value: GoogleCloudDialogflowV2SentimentAnalysisResult|
                        null) {
    this.Serializable$set('sentimentAnalysis', value);
  }

  get speechToTextInfo(): GoogleCloudDialogflowV2MessageSpeechToTextInfo|null {
    return (
        (this.Serializable$has('speechToTextInfo')) ?
            (this.Serializable$get('speechToTextInfo')) :
            (null));
  }

  /**
   * Output only. Speech to text information of this message.
   */
  set speechToTextInfo(value: GoogleCloudDialogflowV2MessageSpeechToTextInfo|
                       null) {
    this.Serializable$set('speechToTextInfo', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2Message> {
    return GoogleCloudDialogflowV2Message;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'participantRole': GoogleCloudDialogflowV2MessageParticipantRoleEnum
      },
      keys: [
        'content', 'createTime', 'languageCode', 'messageAnnotation', 'name',
        'participant', 'participantRole', 'sendTime', 'sentimentAnalysis',
        'speechToTextInfo'
      ],
      objects: {
        'messageAnnotation': GoogleCloudDialogflowV2MessageAnnotation,
        'sentimentAnalysis': GoogleCloudDialogflowV2SentimentAnalysisResult,
        'speechToTextInfo': GoogleCloudDialogflowV2MessageSpeechToTextInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowV2MessageAnnotationParameters {
  parts?: Array<GoogleCloudDialogflowV2AnnotatedMessagePart>|null;
  containEntities?: boolean|null;
}
export class GoogleCloudDialogflowV2MessageAnnotation extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2MessageAnnotationParameters = {}) {
    super();
    this.Serializable$set(
        'parts', (parameters.parts == null) ? (null) : (parameters.parts));
    this.Serializable$set(
        'containEntities',
        (parameters.containEntities == null) ? (null) :
                                               (parameters.containEntities));
  }

  get containEntities(): boolean|null {
    return (
        (this.Serializable$has('containEntities')) ?
            (this.Serializable$get('containEntities')) :
            (null));
  }

  /**
   * Indicates whether the text message contains entities.
   */
  set containEntities(value: boolean|null) {
    this.Serializable$set('containEntities', value);
  }

  get parts(): Array<GoogleCloudDialogflowV2AnnotatedMessagePart>|null {
    return (
        (this.Serializable$has('parts')) ? (this.Serializable$get('parts')) :
                                           (null));
  }

  /**
   * The collection of annotated message parts ordered by their position in the
   * message. You can recover the annotated message by concatenating
   * [AnnotatedMessagePart.text].
   */
  set parts(value: Array<GoogleCloudDialogflowV2AnnotatedMessagePart>|null) {
    this.Serializable$set('parts', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2MessageAnnotation> {
    return GoogleCloudDialogflowV2MessageAnnotation;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parts': GoogleCloudDialogflowV2AnnotatedMessagePart},
      keys: ['containEntities', 'parts']
    };
  }
}

export interface GoogleCloudDialogflowV2MessageSpeechToTextInfoParameters {
  streamStartTime?: string|null;
  utteranceStartOffset?: string|null;
  utteranceEndOffset?: string|null;
  confidence?: number|null;
  speechWordInfo?: Array<GoogleCloudDialogflowV2SpeechWordInfo>|null;
  recordedAudioGcsUri?: GoogleCloudDialogflowV2GcsDestination|null;
}
export class GoogleCloudDialogflowV2MessageSpeechToTextInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2MessageSpeechToTextInfoParameters = {}) {
    super();
    this.Serializable$set(
        'streamStartTime',
        (parameters.streamStartTime == null) ? (null) :
                                               (parameters.streamStartTime));
    this.Serializable$set(
        'utteranceStartOffset',
        (parameters.utteranceStartOffset == null) ?
            (null) :
            (parameters.utteranceStartOffset));
    this.Serializable$set(
        'utteranceEndOffset',
        (parameters.utteranceEndOffset == null) ?
            (null) :
            (parameters.utteranceEndOffset));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'speechWordInfo',
        (parameters.speechWordInfo == null) ? (null) :
                                              (parameters.speechWordInfo));
    this.Serializable$set(
        'recordedAudioGcsUri',
        (parameters.recordedAudioGcsUri == null) ?
            (null) :
            (parameters.recordedAudioGcsUri));
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The Speech confidence between 0.0 and 1.0 for the current portion of audio.
   * A higher number indicates an estimated greater likelihood that the
   * recognized words are correct. The default of 0.0 is a sentinel value
   * indicating that confidence was not set.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get recordedAudioGcsUri(): GoogleCloudDialogflowV2GcsDestination|null {
    return (
        (this.Serializable$has('recordedAudioGcsUri')) ?
            (this.Serializable$get('recordedAudioGcsUri')) :
            (null));
  }

  /**
   * The Cloud Storage path used for response audio storage if audio export is
   * enabled in the SecuritySettings.
   */
  set recordedAudioGcsUri(value: GoogleCloudDialogflowV2GcsDestination|null) {
    this.Serializable$set('recordedAudioGcsUri', value);
  }

  get speechWordInfo(): Array<GoogleCloudDialogflowV2SpeechWordInfo>|null {
    return (
        (this.Serializable$has('speechWordInfo')) ?
            (this.Serializable$get('speechWordInfo')) :
            (null));
  }

  /**
   * Word-specific information for the words recognized by Speech in transcript.
   * Populated if and only if `message_type` = `TRANSCRIPT` and
   * [InputAudioConfig.enable_word_info] is set. SpeechWordInfo.start_offset and
   * SpeechWordInfo.end_offset are relative to current utterance.
   */
  set speechWordInfo(value: Array<GoogleCloudDialogflowV2SpeechWordInfo>|null) {
    this.Serializable$set('speechWordInfo', value);
  }

  get streamStartTime(): string|null {
    return (
        (this.Serializable$has('streamStartTime')) ?
            (this.Serializable$get('streamStartTime')) :
            (null));
  }

  /**
   * Absolute start time of the stream. It can be gRPC stream or telephony
   * stream.
   */
  set streamStartTime(value: string|null) {
    this.Serializable$set('streamStartTime', value);
  }

  get utteranceEndOffset(): string|null {
    return (
        (this.Serializable$has('utteranceEndOffset')) ?
            (this.Serializable$get('utteranceEndOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio stream that corresponds
   * to the end of the utterance.
   */
  set utteranceEndOffset(value: string|null) {
    this.Serializable$set('utteranceEndOffset', value);
  }

  get utteranceStartOffset(): string|null {
    return (
        (this.Serializable$has('utteranceStartOffset')) ?
            (this.Serializable$get('utteranceStartOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio stream that corresponds
   * to the start of the utterance.
   */
  set utteranceStartOffset(value: string|null) {
    this.Serializable$set('utteranceStartOffset', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2MessageSpeechToTextInfo> {
    return GoogleCloudDialogflowV2MessageSpeechToTextInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'speechWordInfo': GoogleCloudDialogflowV2SpeechWordInfo},
      keys: [
        'confidence', 'recordedAudioGcsUri', 'speechWordInfo',
        'streamStartTime', 'utteranceEndOffset', 'utteranceStartOffset'
      ],
      objects: {'recordedAudioGcsUri': GoogleCloudDialogflowV2GcsDestination}
    };
  }
}

export interface GoogleCloudDialogflowV2OriginalDetectIntentRequestParameters {
  source?: string|null;
  version?: string|null;
  payload?: ApiClientObjectMap<any>|null;
}
export class GoogleCloudDialogflowV2OriginalDetectIntentRequest extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2OriginalDetectIntentRequestParameters = {}) {
    super();
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Optional. This field is set to the value of the `QueryParameters.payload`
   * field passed in the request. Some integrations that query a Dialogflow
   * agent may provide additional information in the payload. In particular, for
   * the Dialogflow Phone Gateway integration, this field has the form: {
   * \"telephony\": { \"caller_id\": \"+18558363987\" } } Note: The caller ID
   * field (`caller_id`) will be redacted for Trial Edition agents and populated
   * with the caller ID in [E.164 format](https://en.wikipedia.org/wiki/E.164)
   * for Essentials Edition agents.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * The source of this request, e.g., `google`, `facebook`, `slack`. It is set
   * by Dialogflow-owned servers.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  get version(): string|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Optional. The version of the protocol used for this request. This field is
   * AoG-specific.
   */
  set version(value: string|null) {
    this.Serializable$set('version', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2OriginalDetectIntentRequest> {
    return GoogleCloudDialogflowV2OriginalDetectIntentRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['payload', 'source', 'version'],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV2QueryResultParameters {
  queryText?: string|null;
  languageCode?: string|null;
  speechRecognitionConfidence?: number|null;
  action?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
  allRequiredParamsPresent?: boolean|null;
  cancelsSlotFilling?: boolean|null;
  fulfillmentText?: string|null;
  fulfillmentMessages?: Array<GoogleCloudDialogflowV2IntentMessage>|null;
  webhookSource?: string|null;
  webhookPayload?: ApiClientObjectMap<any>|null;
  outputContexts?: Array<GoogleCloudDialogflowV2Context>|null;
  intent?: GoogleCloudDialogflowV2Intent|null;
  intentDetectionConfidence?: number|null;
  diagnosticInfo?: ApiClientObjectMap<any>|null;
  sentimentAnalysisResult?: GoogleCloudDialogflowV2SentimentAnalysisResult|null;
  knowledgeAnswers?: GoogleCloudDialogflowV2KnowledgeAnswers|null;
  recordedAudioUri?: string|null;
  recordedResponseAudioExportGcs?: GoogleCloudDialogflowV2GcsDestination|null;
}
export class GoogleCloudDialogflowV2QueryResult extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2QueryResultParameters = {}) {
    super();
    this.Serializable$set(
        'queryText',
        (parameters.queryText == null) ? (null) : (parameters.queryText));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'speechRecognitionConfidence',
        (parameters.speechRecognitionConfidence == null) ?
            (null) :
            (parameters.speechRecognitionConfidence));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'allRequiredParamsPresent',
        (parameters.allRequiredParamsPresent == null) ?
            (null) :
            (parameters.allRequiredParamsPresent));
    this.Serializable$set(
        'cancelsSlotFilling',
        (parameters.cancelsSlotFilling == null) ?
            (null) :
            (parameters.cancelsSlotFilling));
    this.Serializable$set(
        'fulfillmentText',
        (parameters.fulfillmentText == null) ? (null) :
                                               (parameters.fulfillmentText));
    this.Serializable$set(
        'fulfillmentMessages',
        (parameters.fulfillmentMessages == null) ?
            (null) :
            (parameters.fulfillmentMessages));
    this.Serializable$set(
        'webhookSource',
        (parameters.webhookSource == null) ? (null) :
                                             (parameters.webhookSource));
    this.Serializable$set(
        'webhookPayload',
        (parameters.webhookPayload == null) ? (null) :
                                              (parameters.webhookPayload));
    this.Serializable$set(
        'outputContexts',
        (parameters.outputContexts == null) ? (null) :
                                              (parameters.outputContexts));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'intentDetectionConfidence',
        (parameters.intentDetectionConfidence == null) ?
            (null) :
            (parameters.intentDetectionConfidence));
    this.Serializable$set(
        'diagnosticInfo',
        (parameters.diagnosticInfo == null) ? (null) :
                                              (parameters.diagnosticInfo));
    this.Serializable$set(
        'sentimentAnalysisResult',
        (parameters.sentimentAnalysisResult == null) ?
            (null) :
            (parameters.sentimentAnalysisResult));
    this.Serializable$set(
        'knowledgeAnswers',
        (parameters.knowledgeAnswers == null) ? (null) :
                                                (parameters.knowledgeAnswers));
    this.Serializable$set(
        'recordedAudioUri',
        (parameters.recordedAudioUri == null) ? (null) :
                                                (parameters.recordedAudioUri));
    this.Serializable$set(
        'recordedResponseAudioExportGcs',
        (parameters.recordedResponseAudioExportGcs == null) ?
            (null) :
            (parameters.recordedResponseAudioExportGcs));
  }

  get action(): string|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * The action name from the matched intent.
   */
  set action(value: string|null) {
    this.Serializable$set('action', value);
  }

  get allRequiredParamsPresent(): boolean|null {
    return (
        (this.Serializable$has('allRequiredParamsPresent')) ?
            (this.Serializable$get('allRequiredParamsPresent')) :
            (null));
  }

  /**
   * This field is set to: - `false` if the matched intent has required
   * parameters and not all of the required parameter values have been
   * collected. - `true` if all required parameter values have been collected,
   * or if the matched intent doesn't contain any required parameters.
   */
  set allRequiredParamsPresent(value: boolean|null) {
    this.Serializable$set('allRequiredParamsPresent', value);
  }

  get cancelsSlotFilling(): boolean|null {
    return (
        (this.Serializable$has('cancelsSlotFilling')) ?
            (this.Serializable$get('cancelsSlotFilling')) :
            (null));
  }

  /**
   * Indicates whether the conversational query triggers a cancellation for slot
   * filling. For more information, see the [cancel slot filling
   * documentation](https://cloud.google.com/dialogflow/es/docs/intents-actions-parameters#cancel).
   */
  set cancelsSlotFilling(value: boolean|null) {
    this.Serializable$set('cancelsSlotFilling', value);
  }

  get diagnosticInfo(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('diagnosticInfo')) ?
            (this.Serializable$get('diagnosticInfo')) :
            (null));
  }

  /**
   * Free-form diagnostic information for the associated detect intent request.
   * The fields of this data can change without notice, so you should not write
   * code that depends on its structure. The data may contain: - webhook call
   * latency - webhook errors
   */
  set diagnosticInfo(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('diagnosticInfo', value);
  }

  get fulfillmentMessages(): Array<GoogleCloudDialogflowV2IntentMessage>|null {
    return (
        (this.Serializable$has('fulfillmentMessages')) ?
            (this.Serializable$get('fulfillmentMessages')) :
            (null));
  }

  /**
   * The collection of rich messages to present to the user.
   */
  set fulfillmentMessages(value: Array<GoogleCloudDialogflowV2IntentMessage>|
                          null) {
    this.Serializable$set('fulfillmentMessages', value);
  }

  get fulfillmentText(): string|null {
    return (
        (this.Serializable$has('fulfillmentText')) ?
            (this.Serializable$get('fulfillmentText')) :
            (null));
  }

  /**
   * The text to be pronounced to the user or shown on the screen. Note: This is
   * a legacy field, `fulfillment_messages` should be preferred.
   */
  set fulfillmentText(value: string|null) {
    this.Serializable$set('fulfillmentText', value);
  }

  get intent(): GoogleCloudDialogflowV2Intent|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The intent that matched the conversational query. Some, not all fields are
   * filled in this message, including but not limited to: `name`,
   * `display_name`, `end_interaction` and `is_fallback`.
   */
  set intent(value: GoogleCloudDialogflowV2Intent|null) {
    this.Serializable$set('intent', value);
  }

  get intentDetectionConfidence(): number|null {
    return (
        (this.Serializable$has('intentDetectionConfidence')) ?
            (this.Serializable$get('intentDetectionConfidence')) :
            (null));
  }

  /**
   * The intent detection confidence. Values range from 0.0 (completely
   * uncertain) to 1.0 (completely certain). This value is for informational
   * purpose only and is only used to help match the best intent within the
   * classification threshold. This value may change for the same end-user
   * expression at any time due to a model retraining or change in
   * implementation. If there are `multiple knowledge_answers` messages, this
   * value is set to the greatest `knowledgeAnswers.match_confidence` value in
   * the list.
   */
  set intentDetectionConfidence(value: number|null) {
    this.Serializable$set('intentDetectionConfidence', value);
  }

  get knowledgeAnswers(): GoogleCloudDialogflowV2KnowledgeAnswers|null {
    return (
        (this.Serializable$has('knowledgeAnswers')) ?
            (this.Serializable$get('knowledgeAnswers')) :
            (null));
  }

  /**
   * The result from Knowledge Connector (if any), ordered by decreasing
   * `KnowledgeAnswers.match_confidence`.
   */
  set knowledgeAnswers(value: GoogleCloudDialogflowV2KnowledgeAnswers|null) {
    this.Serializable$set('knowledgeAnswers', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language that was triggered during intent detection. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get outputContexts(): Array<GoogleCloudDialogflowV2Context>|null {
    return (
        (this.Serializable$has('outputContexts')) ?
            (this.Serializable$get('outputContexts')) :
            (null));
  }

  /**
   * The collection of output contexts. If applicable,
   * `output_contexts.parameters` contains entries with name `.original`
   * containing the original parameter values before the query.
   */
  set outputContexts(value: Array<GoogleCloudDialogflowV2Context>|null) {
    this.Serializable$set('outputContexts', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of extracted parameters. Depending on your protocol or
   * client library language, this is a map, associative array, symbol table,
   * dictionary, or JSON object composed of a collection of (MapKey, MapValue)
   * pairs: - MapKey type: string - MapKey value: parameter name - MapValue
   * type: - If parameter's entity type is a composite entity: map - Else:
   * depending on parameter value type, could be one of string, number, boolean,
   * null, list or map - MapValue value: - If parameter's entity type is a
   * composite entity: map from composite entity property names to property
   * values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  get queryText(): string|null {
    return (
        (this.Serializable$has('queryText')) ?
            (this.Serializable$get('queryText')) :
            (null));
  }

  /**
   * The original conversational query text: - If natural language text was
   * provided as input, `query_text` contains a copy of the input. - If natural
   * language speech audio was provided as input, `query_text` contains the
   * speech recognition result. If speech recognizer produced multiple
   * alternatives, a particular one is picked. - If automatic spell correction
   * is enabled, `query_text` will contain the corrected user input.
   */
  set queryText(value: string|null) {
    this.Serializable$set('queryText', value);
  }

  get recordedAudioUri(): string|null {
    return (
        (this.Serializable$has('recordedAudioUri')) ?
            (this.Serializable$get('recordedAudioUri')) :
            (null));
  }

  /**
   * The Cloud Storage path used for audio storage if audio export is enabled in
   * the SecuritySettings.
   */
  set recordedAudioUri(value: string|null) {
    this.Serializable$set('recordedAudioUri', value);
  }

  get recordedResponseAudioExportGcs(): GoogleCloudDialogflowV2GcsDestination
      |null {
    return (
        (this.Serializable$has('recordedResponseAudioExportGcs')) ?
            (this.Serializable$get('recordedResponseAudioExportGcs')) :
            (null));
  }

  /**
   * The Cloud Storage path used for response audio storage if audio export is
   * enabled in the SecuritySettings.
   */
  set recordedResponseAudioExportGcs(value:
                                         GoogleCloudDialogflowV2GcsDestination|
                                     null) {
    this.Serializable$set('recordedResponseAudioExportGcs', value);
  }

  get sentimentAnalysisResult(): GoogleCloudDialogflowV2SentimentAnalysisResult
      |null {
    return (
        (this.Serializable$has('sentimentAnalysisResult')) ?
            (this.Serializable$get('sentimentAnalysisResult')) :
            (null));
  }

  /**
   * The sentiment analysis result, which depends on the
   * `sentiment_analysis_request_config` specified in the request.
   */
  set sentimentAnalysisResult(
      value: GoogleCloudDialogflowV2SentimentAnalysisResult|null) {
    this.Serializable$set('sentimentAnalysisResult', value);
  }

  get speechRecognitionConfidence(): number|null {
    return (
        (this.Serializable$has('speechRecognitionConfidence')) ?
            (this.Serializable$get('speechRecognitionConfidence')) :
            (null));
  }

  /**
   * The Speech recognition confidence between 0.0 and 1.0. A higher number
   * indicates an estimated greater likelihood that the recognized words are
   * correct. The default of 0.0 is a sentinel value indicating that confidence
   * was not set. This field is not guaranteed to be accurate or set. In
   * particular this field isn't set for StreamingDetectIntent since the
   * streaming endpoint has separate confidence estimates per portion of the
   * audio in StreamingRecognitionResult.
   */
  set speechRecognitionConfidence(value: number|null) {
    this.Serializable$set('speechRecognitionConfidence', value);
  }

  get webhookPayload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('webhookPayload')) ?
            (this.Serializable$get('webhookPayload')) :
            (null));
  }

  /**
   * If the query was fulfilled by a webhook call, this field is set to the
   * value of the `payload` field returned in the webhook response.
   */
  set webhookPayload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('webhookPayload', value);
  }

  get webhookSource(): string|null {
    return (
        (this.Serializable$has('webhookSource')) ?
            (this.Serializable$get('webhookSource')) :
            (null));
  }

  /**
   * If the query was fulfilled by a webhook call, this field is set to the
   * value of the `source` field returned in the webhook response.
   */
  set webhookSource(value: string|null) {
    this.Serializable$set('webhookSource', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2QueryResult> {
    return GoogleCloudDialogflowV2QueryResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'fulfillmentMessages': GoogleCloudDialogflowV2IntentMessage,
        'outputContexts': GoogleCloudDialogflowV2Context
      },
      keys: [
        'action', 'allRequiredParamsPresent', 'cancelsSlotFilling',
        'diagnosticInfo', 'fulfillmentMessages', 'fulfillmentText', 'intent',
        'intentDetectionConfidence', 'knowledgeAnswers', 'languageCode',
        'outputContexts', 'parameters', 'queryText', 'recordedAudioUri',
        'recordedResponseAudioExportGcs', 'sentimentAnalysisResult',
        'speechRecognitionConfidence', 'webhookPayload', 'webhookSource'
      ],
      objectMaps: {
        'diagnosticInfo': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'webhookPayload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'intent': GoogleCloudDialogflowV2Intent,
        'knowledgeAnswers': GoogleCloudDialogflowV2KnowledgeAnswers,
        'recordedResponseAudioExportGcs': GoogleCloudDialogflowV2GcsDestination,
        'sentimentAnalysisResult':
            GoogleCloudDialogflowV2SentimentAnalysisResult
      }
    };
  }
}

export interface GoogleCloudDialogflowV2SentimentParameters {
  score?: number|null;
  magnitude?: number|null;
}
export class GoogleCloudDialogflowV2Sentiment extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV2SentimentParameters = {}) {
    super();
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'magnitude',
        (parameters.magnitude == null) ? (null) : (parameters.magnitude));
  }

  get magnitude(): number|null {
    return (
        (this.Serializable$has('magnitude')) ?
            (this.Serializable$get('magnitude')) :
            (null));
  }

  /**
   * A non-negative number in the [0, +inf) range, which represents the absolute
   * magnitude of sentiment, regardless of score (positive or negative).
   */
  set magnitude(value: number|null) {
    this.Serializable$set('magnitude', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Sentiment score between -1.0 (negative sentiment) and 1.0 (positive
   * sentiment).
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2Sentiment> {
    return GoogleCloudDialogflowV2Sentiment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['magnitude', 'score']};
  }
}

export interface GoogleCloudDialogflowV2SentimentAnalysisResultParameters {
  queryTextSentiment?: GoogleCloudDialogflowV2Sentiment|null;
  conversationTextSentiment?: GoogleCloudDialogflowV2Sentiment|null;
}
export class GoogleCloudDialogflowV2SentimentAnalysisResult extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2SentimentAnalysisResultParameters = {}) {
    super();
    this.Serializable$set(
        'queryTextSentiment',
        (parameters.queryTextSentiment == null) ?
            (null) :
            (parameters.queryTextSentiment));
    this.Serializable$set(
        'conversationTextSentiment',
        (parameters.conversationTextSentiment == null) ?
            (null) :
            (parameters.conversationTextSentiment));
  }

  get conversationTextSentiment(): GoogleCloudDialogflowV2Sentiment|null {
    return (
        (this.Serializable$has('conversationTextSentiment')) ?
            (this.Serializable$get('conversationTextSentiment')) :
            (null));
  }

  /**
   * The sentiment analysis result for the conversation. See the documentation
   * for `analyze_conversation_text_sentiment` for the definition of
   * conversation.
   */
  set conversationTextSentiment(value: GoogleCloudDialogflowV2Sentiment|null) {
    this.Serializable$set('conversationTextSentiment', value);
  }

  get queryTextSentiment(): GoogleCloudDialogflowV2Sentiment|null {
    return (
        (this.Serializable$has('queryTextSentiment')) ?
            (this.Serializable$get('queryTextSentiment')) :
            (null));
  }

  /**
   * The sentiment analysis result for `query_text`.
   */
  set queryTextSentiment(value: GoogleCloudDialogflowV2Sentiment|null) {
    this.Serializable$set('queryTextSentiment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2SentimentAnalysisResult> {
    return GoogleCloudDialogflowV2SentimentAnalysisResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['conversationTextSentiment', 'queryTextSentiment'],
      objects: {
        'conversationTextSentiment': GoogleCloudDialogflowV2Sentiment,
        'queryTextSentiment': GoogleCloudDialogflowV2Sentiment
      }
    };
  }
}

export interface GoogleCloudDialogflowV2SessionEntityTypeParameters {
  name?: string|null;
  entityOverrideMode?:
      GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode|null;
  entities?: Array<GoogleCloudDialogflowV2EntityTypeEntity>|null;
}
export class GoogleCloudDialogflowV2SessionEntityType extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2SessionEntityTypeParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'entityOverrideMode',
        (parameters.entityOverrideMode == null) ?
            (null) :
            (parameters.entityOverrideMode));
    this.Serializable$set(
        'entities',
        (parameters.entities == null) ? (null) : (parameters.entities));
  }

  static get EntityOverrideMode():
      IGoogleCloudDialogflowV2SessionEntityTypeEntityOverrideModeEnum {
    return GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideModeEnum;
  }

  get entities(): Array<GoogleCloudDialogflowV2EntityTypeEntity>|null {
    return (
        (this.Serializable$has('entities')) ?
            (this.Serializable$get('entities')) :
            (null));
  }

  /**
   * Required. The collection of entities associated with this session entity
   * type.
   */
  set entities(value: Array<GoogleCloudDialogflowV2EntityTypeEntity>|null) {
    this.Serializable$set('entities', value);
  }

  get entityOverrideMode():
      GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode|null {
    return (
        (this.Serializable$has('entityOverrideMode')) ?
            (this.Serializable$get('entityOverrideMode')) :
            (null));
  }

  /**
   * Required. Indicates whether the additional data should override or
   * supplement the custom entity type definition.
   */
  set entityOverrideMode(
      value: GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideMode|null) {
    this.Serializable$set('entityOverrideMode', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of this session entity type. Format:
   * `projects//agent/sessions//entityTypes/`, or
   * `projects//agent/environments//users//sessions//entityTypes/`. If
   * `Environment ID` is not specified, we assume default 'draft' environment.
   * If `User ID` is not specified, we assume default '-' user. `` must be the
   * display name of an existing entity type in the same agent that will be
   * overridden or supplemented.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2SessionEntityType> {
    return GoogleCloudDialogflowV2SessionEntityType;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'entities': GoogleCloudDialogflowV2EntityTypeEntity},
      enums: {
        'entityOverrideMode':
            GoogleCloudDialogflowV2SessionEntityTypeEntityOverrideModeEnum
      },
      keys: ['entities', 'entityOverrideMode', 'name']
    };
  }
}

export interface GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParameters {
  conversationProfile?: string|null;
  participantRole?:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole|
      null;
  suggestionFeatureType?:
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType|
      null;
  createTime?: string|null;
}
export class
    GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'conversationProfile',
        (parameters.conversationProfile == null) ?
            (null) :
            (parameters.conversationProfile));
    this.Serializable$set(
        'participantRole',
        (parameters.participantRole == null) ? (null) :
                                               (parameters.participantRole));
    this.Serializable$set(
        'suggestionFeatureType',
        (parameters.suggestionFeatureType == null) ?
            (null) :
            (parameters.suggestionFeatureType));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  static get ParticipantRole():
      IGoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRoleEnum {
    return GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRoleEnum;
  }

  static get SuggestionFeatureType():
      IGoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum {
    return GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum;
  }

  get conversationProfile(): string|null {
    return (
        (this.Serializable$has('conversationProfile')) ?
            (this.Serializable$get('conversationProfile')) :
            (null));
  }

  /**
   * The resource name of the conversation profile. Format:
   * `projects//locations//conversationProfiles/`
   */
  set conversationProfile(value: string|null) {
    this.Serializable$set('conversationProfile', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp whe the request was created. The time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get participantRole():
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole
      |null {
    return (
        (this.Serializable$has('participantRole')) ?
            (this.Serializable$get('participantRole')) :
            (null));
  }

  /**
   * Required. The participant role to add or update the suggestion feature
   * config. Only HUMAN_AGENT or END_USER can be used.
   */
  set participantRole(
      value:
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRole|
      null) {
    this.Serializable$set('participantRole', value);
  }

  get suggestionFeatureType():
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType
      |null {
    return (
        (this.Serializable$has('suggestionFeatureType')) ?
            (this.Serializable$get('suggestionFeatureType')) :
            (null));
  }

  /**
   * Required. The type of the suggestion feature to add or update.
   */
  set suggestionFeatureType(
      value:
          GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureType|
      null) {
    this.Serializable$set('suggestionFeatureType', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadata> {
    return GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'participantRole':
            GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataParticipantRoleEnum,
        'suggestionFeatureType':
            GoogleCloudDialogflowV2SetSuggestionFeatureConfigOperationMetadataSuggestionFeatureTypeEnum
      },
      keys: [
        'conversationProfile', 'createTime', 'participantRole',
        'suggestionFeatureType'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV2SmartComposeModelMetadataParameters {
  internalTrainingFlags?: string|null;
  targetRole?: GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole|null;
  associatedAllowlistInfo?: GoogleCloudDialogflowV2AssociateAllowlistInfo|null;
  trainingModelType?:
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType|null;
}
export class GoogleCloudDialogflowV2SmartComposeModelMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2SmartComposeModelMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
    this.Serializable$set(
        'targetRole',
        (parameters.targetRole == null) ? (null) : (parameters.targetRole));
    this.Serializable$set(
        'associatedAllowlistInfo',
        (parameters.associatedAllowlistInfo == null) ?
            (null) :
            (parameters.associatedAllowlistInfo));
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
  }

  static get TargetRole():
      IGoogleCloudDialogflowV2SmartComposeModelMetadataTargetRoleEnum {
    return GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRoleEnum;
  }

  static get TrainingModelType():
      IGoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum {
    return GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum;
  }

  get associatedAllowlistInfo(): GoogleCloudDialogflowV2AssociateAllowlistInfo
      |null {
    return (
        (this.Serializable$has('associatedAllowlistInfo')) ?
            (this.Serializable$get('associatedAllowlistInfo')) :
            (null));
  }

  /**
   * Optional. The information of the allowlist associated with the conversation
   * model. This field is internal and only to be accessed by the web console.
   */
  set associatedAllowlistInfo(value:
                                  GoogleCloudDialogflowV2AssociateAllowlistInfo|
                              null) {
    this.Serializable$set('associatedAllowlistInfo', value);
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  /**
   * Optional. Internal testing only. The text format proto of
   * InternalTrainingFlags defined in
   * cloud/ai/contactcenter/knowledge/proto/conversation_model.proto.
   */
  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get targetRole(): GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole
      |null {
    return (
        (this.Serializable$has('targetRole')) ?
            (this.Serializable$get('targetRole')) :
            (null));
  }

  /**
   * Optional. The role of the targeted user that the smart compose language
   * model is trained for. If not provided, 'HUMAN_AGENT' is used.
   */
  set targetRole(value:
                     GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRole|
                 null) {
    this.Serializable$set('targetRole', value);
  }

  get trainingModelType():
      GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType|null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Optional. Type of the smart compose model. If not provided, model_type is
   * used.
   */
  set trainingModelType(
      value: GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelType|
      null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2SmartComposeModelMetadata> {
    return GoogleCloudDialogflowV2SmartComposeModelMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'targetRole':
            GoogleCloudDialogflowV2SmartComposeModelMetadataTargetRoleEnum,
        'trainingModelType':
            GoogleCloudDialogflowV2SmartComposeModelMetadataTrainingModelTypeEnum
      },
      keys: [
        'associatedAllowlistInfo', 'internalTrainingFlags', 'targetRole',
        'trainingModelType'
      ],
      objects: {
        'associatedAllowlistInfo': GoogleCloudDialogflowV2AssociateAllowlistInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowV2SmartReplyAnswerParameters {
  reply?: string|null;
  confidence?: number|null;
  personalized?: boolean|null;
  answerRecord?: string|null;
}
export class GoogleCloudDialogflowV2SmartReplyAnswer extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2SmartReplyAnswerParameters = {}) {
    super();
    this.Serializable$set(
        'reply', (parameters.reply == null) ? (null) : (parameters.reply));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'personalized',
        (parameters.personalized == null) ? (null) : (parameters.personalized));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * Smart reply confidence. The system's confidence score that this reply is a
   * good match for this conversation, as a value from 0.0 (completely
   * uncertain) to 1.0 (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get personalized(): boolean|null {
    return (
        (this.Serializable$has('personalized')) ?
            (this.Serializable$get('personalized')) :
            (null));
  }

  /**
   * Whether the ranking of the smart reply answer is boosted by personalization
   * using the external agent id.
   */
  set personalized(value: boolean|null) {
    this.Serializable$set('personalized', value);
  }

  get reply(): string|null {
    return (
        (this.Serializable$has('reply')) ? (this.Serializable$get('reply')) :
                                           (null));
  }

  /**
   * The content of the reply.
   */
  set reply(value: string|null) {
    this.Serializable$set('reply', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2SmartReplyAnswer> {
    return GoogleCloudDialogflowV2SmartReplyAnswer;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['answerRecord', 'confidence', 'personalized', 'reply']};
  }
}

export interface GoogleCloudDialogflowV2SmartReplyModelMetadataParameters {
  internalTrainingFlags?: string|null;
  targetRole?: GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole|null;
  associatedAllowlistInfo?: GoogleCloudDialogflowV2AssociateAllowlistInfo|null;
  trainingModelType?:
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType|null;
}
export class GoogleCloudDialogflowV2SmartReplyModelMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2SmartReplyModelMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
    this.Serializable$set(
        'targetRole',
        (parameters.targetRole == null) ? (null) : (parameters.targetRole));
    this.Serializable$set(
        'associatedAllowlistInfo',
        (parameters.associatedAllowlistInfo == null) ?
            (null) :
            (parameters.associatedAllowlistInfo));
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
  }

  static get TargetRole():
      IGoogleCloudDialogflowV2SmartReplyModelMetadataTargetRoleEnum {
    return GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRoleEnum;
  }

  static get TrainingModelType():
      IGoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum {
    return GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum;
  }

  get associatedAllowlistInfo(): GoogleCloudDialogflowV2AssociateAllowlistInfo
      |null {
    return (
        (this.Serializable$has('associatedAllowlistInfo')) ?
            (this.Serializable$get('associatedAllowlistInfo')) :
            (null));
  }

  /**
   * Optional. The information of the allowlist associated with the conversation
   * model. This field is internal and only to be accessed by the web console.
   */
  set associatedAllowlistInfo(value:
                                  GoogleCloudDialogflowV2AssociateAllowlistInfo|
                              null) {
    this.Serializable$set('associatedAllowlistInfo', value);
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  /**
   * Internal testing only. The text format proto of InternalTrainingFlags
   * defined in cloud/ai/contactcenter/knowledge/proto/conversation_model.proto.
   */
  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get targetRole(): GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole
      |null {
    return (
        (this.Serializable$has('targetRole')) ?
            (this.Serializable$get('targetRole')) :
            (null));
  }

  /**
   * The role of the targeted user that the smart reply language model is
   * trained for. If not provided, 'HUMAN_AGENT' is used.
   */
  set targetRole(value:
                     GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRole|
                 null) {
    this.Serializable$set('targetRole', value);
  }

  get trainingModelType():
      GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType|null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Optional. Type of the smart reply model. If not provided, model_type is
   * used.
   */
  set trainingModelType(
      value: GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelType|
      null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2SmartReplyModelMetadata> {
    return GoogleCloudDialogflowV2SmartReplyModelMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'targetRole':
            GoogleCloudDialogflowV2SmartReplyModelMetadataTargetRoleEnum,
        'trainingModelType':
            GoogleCloudDialogflowV2SmartReplyModelMetadataTrainingModelTypeEnum
      },
      keys: [
        'associatedAllowlistInfo', 'internalTrainingFlags', 'targetRole',
        'trainingModelType'
      ],
      objects: {
        'associatedAllowlistInfo': GoogleCloudDialogflowV2AssociateAllowlistInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowV2SpeechWordInfoParameters {
  word?: string|null;
  startOffset?: string|null;
  endOffset?: string|null;
  confidence?: number|null;
}
export class GoogleCloudDialogflowV2SpeechWordInfo extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2SpeechWordInfoParameters = {}) {
    super();
    this.Serializable$set(
        'word', (parameters.word == null) ? (null) : (parameters.word));
    this.Serializable$set(
        'startOffset',
        (parameters.startOffset == null) ? (null) : (parameters.startOffset));
    this.Serializable$set(
        'endOffset',
        (parameters.endOffset == null) ? (null) : (parameters.endOffset));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The Speech confidence between 0.0 and 1.0 for this word. A higher number
   * indicates an estimated greater likelihood that the recognized word is
   * correct. The default of 0.0 is a sentinel value indicating that confidence
   * was not set. This field is not guaranteed to be fully stable over time for
   * the same audio input. Users should also not rely on it to always be
   * provided.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get endOffset(): string|null {
    return (
        (this.Serializable$has('endOffset')) ?
            (this.Serializable$get('endOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio that corresponds to the
   * end of the spoken word. This is an experimental feature and the accuracy of
   * the time offset can vary.
   */
  set endOffset(value: string|null) {
    this.Serializable$set('endOffset', value);
  }

  get startOffset(): string|null {
    return (
        (this.Serializable$has('startOffset')) ?
            (this.Serializable$get('startOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio that corresponds to the
   * start of the spoken word. This is an experimental feature and the accuracy
   * of the time offset can vary.
   */
  set startOffset(value: string|null) {
    this.Serializable$set('startOffset', value);
  }

  get word(): string|null {
    return (
        (this.Serializable$has('word')) ? (this.Serializable$get('word')) :
                                          (null));
  }

  /**
   * The word this info is for.
   */
  set word(value: string|null) {
    this.Serializable$set('word', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2SpeechWordInfo> {
    return GoogleCloudDialogflowV2SpeechWordInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['confidence', 'endOffset', 'startOffset', 'word']};
  }
}

export interface GoogleCloudDialogflowV2SuggestArticlesResponseParameters {
  articleAnswers?: Array<GoogleCloudDialogflowV2ArticleAnswer>|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class GoogleCloudDialogflowV2SuggestArticlesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2SuggestArticlesResponseParameters = {}) {
    super();
    this.Serializable$set(
        'articleAnswers',
        (parameters.articleAnswers == null) ? (null) :
                                              (parameters.articleAnswers));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get articleAnswers(): Array<GoogleCloudDialogflowV2ArticleAnswer>|null {
    return (
        (this.Serializable$has('articleAnswers')) ?
            (this.Serializable$get('articleAnswers')) :
            (null));
  }

  /**
   * Articles ordered by score in descending order.
   */
  set articleAnswers(value: Array<GoogleCloudDialogflowV2ArticleAnswer>|null) {
    this.Serializable$set('articleAnswers', value);
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including latest_message to compile the
   * suggestion. It may be smaller than the SuggestArticlesRequest.context_size
   * field in the request if there aren't that many messages in the
   * conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used to compile suggestion for.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2SuggestArticlesResponse> {
    return GoogleCloudDialogflowV2SuggestArticlesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'articleAnswers': GoogleCloudDialogflowV2ArticleAnswer},
      keys: ['articleAnswers', 'contextSize', 'latestMessage']
    };
  }
}

export interface GoogleCloudDialogflowV2SuggestFaqAnswersResponseParameters {
  faqAnswers?: Array<GoogleCloudDialogflowV2FaqAnswer>|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class GoogleCloudDialogflowV2SuggestFaqAnswersResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2SuggestFaqAnswersResponseParameters = {}) {
    super();
    this.Serializable$set(
        'faqAnswers',
        (parameters.faqAnswers == null) ? (null) : (parameters.faqAnswers));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including latest_message to compile the
   * suggestion. It may be smaller than the
   * SuggestFaqAnswersRequest.context_size field in the request if there aren't
   * that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get faqAnswers(): Array<GoogleCloudDialogflowV2FaqAnswer>|null {
    return (
        (this.Serializable$has('faqAnswers')) ?
            (this.Serializable$get('faqAnswers')) :
            (null));
  }

  /**
   * Answers extracted from FAQ documents.
   */
  set faqAnswers(value: Array<GoogleCloudDialogflowV2FaqAnswer>|null) {
    this.Serializable$set('faqAnswers', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used to compile suggestion for.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2SuggestFaqAnswersResponse> {
    return GoogleCloudDialogflowV2SuggestFaqAnswersResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'faqAnswers': GoogleCloudDialogflowV2FaqAnswer},
      keys: ['contextSize', 'faqAnswers', 'latestMessage']
    };
  }
}

export interface GoogleCloudDialogflowV2SuggestSmartRepliesResponseParameters {
  smartReplyAnswers?: Array<GoogleCloudDialogflowV2SmartReplyAnswer>|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class GoogleCloudDialogflowV2SuggestSmartRepliesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2SuggestSmartRepliesResponseParameters = {}) {
    super();
    this.Serializable$set(
        'smartReplyAnswers',
        (parameters.smartReplyAnswers == null) ?
            (null) :
            (parameters.smartReplyAnswers));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including latest_message to compile the
   * suggestion. It may be smaller than the
   * SuggestSmartRepliesRequest.context_size field in the request if there
   * aren't that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used to compile suggestion for.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  get smartReplyAnswers(): Array<GoogleCloudDialogflowV2SmartReplyAnswer>|null {
    return (
        (this.Serializable$has('smartReplyAnswers')) ?
            (this.Serializable$get('smartReplyAnswers')) :
            (null));
  }

  /**
   * Output only. Multiple reply options provided by smart reply service. The
   * order is based on the rank of the model prediction. The maximum number of
   * the returned replies is set in SmartReplyConfig.
   */
  set smartReplyAnswers(value: Array<GoogleCloudDialogflowV2SmartReplyAnswer>|
                        null) {
    this.Serializable$set('smartReplyAnswers', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2SuggestSmartRepliesResponse> {
    return GoogleCloudDialogflowV2SuggestSmartRepliesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'smartReplyAnswers': GoogleCloudDialogflowV2SmartReplyAnswer},
      keys: ['contextSize', 'latestMessage', 'smartReplyAnswers']
    };
  }
}

export interface GoogleCloudDialogflowV2SuggestionResultParameters {
  error?: GoogleRpcStatus|null;
  suggestArticlesResponse?: GoogleCloudDialogflowV2SuggestArticlesResponse|null;
  suggestFaqAnswersResponse?: GoogleCloudDialogflowV2SuggestFaqAnswersResponse|
      null;
  suggestSmartRepliesResponse?:
      GoogleCloudDialogflowV2SuggestSmartRepliesResponse|null;
}
export class GoogleCloudDialogflowV2SuggestionResult extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2SuggestionResultParameters = {}) {
    super();
    this.Serializable$set(
        'error', (parameters.error == null) ? (null) : (parameters.error));
    this.Serializable$set(
        'suggestArticlesResponse',
        (parameters.suggestArticlesResponse == null) ?
            (null) :
            (parameters.suggestArticlesResponse));
    this.Serializable$set(
        'suggestFaqAnswersResponse',
        (parameters.suggestFaqAnswersResponse == null) ?
            (null) :
            (parameters.suggestFaqAnswersResponse));
    this.Serializable$set(
        'suggestSmartRepliesResponse',
        (parameters.suggestSmartRepliesResponse == null) ?
            (null) :
            (parameters.suggestSmartRepliesResponse));
  }

  get error(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('error')) ? (this.Serializable$get('error')) :
                                           (null));
  }

  /**
   * Error status if the request failed.
   */
  set error(value: GoogleRpcStatus|null) {
    this.Serializable$set('error', value);
  }

  get suggestArticlesResponse(): GoogleCloudDialogflowV2SuggestArticlesResponse
      |null {
    return (
        (this.Serializable$has('suggestArticlesResponse')) ?
            (this.Serializable$get('suggestArticlesResponse')) :
            (null));
  }

  /**
   * SuggestArticlesResponse if request is for ARTICLE_SUGGESTION.
   */
  set suggestArticlesResponse(
      value: GoogleCloudDialogflowV2SuggestArticlesResponse|null) {
    this.Serializable$set('suggestArticlesResponse', value);
  }

  get suggestFaqAnswersResponse():
      GoogleCloudDialogflowV2SuggestFaqAnswersResponse|null {
    return (
        (this.Serializable$has('suggestFaqAnswersResponse')) ?
            (this.Serializable$get('suggestFaqAnswersResponse')) :
            (null));
  }

  /**
   * SuggestFaqAnswersResponse if request is for FAQ_ANSWER.
   */
  set suggestFaqAnswersResponse(
      value: GoogleCloudDialogflowV2SuggestFaqAnswersResponse|null) {
    this.Serializable$set('suggestFaqAnswersResponse', value);
  }

  get suggestSmartRepliesResponse():
      GoogleCloudDialogflowV2SuggestSmartRepliesResponse|null {
    return (
        (this.Serializable$has('suggestSmartRepliesResponse')) ?
            (this.Serializable$get('suggestSmartRepliesResponse')) :
            (null));
  }

  /**
   * SuggestSmartRepliesResponse if request is for SMART_REPLY.
   */
  set suggestSmartRepliesResponse(
      value: GoogleCloudDialogflowV2SuggestSmartRepliesResponse|null) {
    this.Serializable$set('suggestSmartRepliesResponse', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2SuggestionResult> {
    return GoogleCloudDialogflowV2SuggestionResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'error', 'suggestArticlesResponse', 'suggestFaqAnswersResponse',
        'suggestSmartRepliesResponse'
      ],
      objects: {
        'error': GoogleRpcStatus,
        'suggestArticlesResponse':
            GoogleCloudDialogflowV2SuggestArticlesResponse,
        'suggestFaqAnswersResponse':
            GoogleCloudDialogflowV2SuggestFaqAnswersResponse,
        'suggestSmartRepliesResponse':
            GoogleCloudDialogflowV2SuggestSmartRepliesResponse
      }
    };
  }
}

export interface GoogleCloudDialogflowV2SummarizationModelMetadataParameters {
  internalTrainingFlags?: string|null;
  trainingModelType?:
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType|null;
}
export class GoogleCloudDialogflowV2SummarizationModelMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2SummarizationModelMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
  }

  static get TrainingModelType():
      IGoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum {
    return GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum;
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get trainingModelType():
      GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType|null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Optional. Type of the summarizationsummarization model. If not provided,
   * model_type is used.
   */
  set trainingModelType(
      value: GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelType|
      null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV2SummarizationModelMetadata> {
    return GoogleCloudDialogflowV2SummarizationModelMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'trainingModelType':
            GoogleCloudDialogflowV2SummarizationModelMetadataTrainingModelTypeEnum
      },
      keys: ['internalTrainingFlags', 'trainingModelType']
    };
  }
}

export interface GoogleCloudDialogflowV2SummarizationModelTrainingMetadataParameters {
  inputTrainingConversationCount?: string|null;
  inputTrainingSummaryCount?: string|null;
  convLengthDebugMessage?: string|null;
  convContentDebugMessage?: string|null;
}
export class GoogleCloudDialogflowV2SummarizationModelTrainingMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2SummarizationModelTrainingMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'inputTrainingConversationCount',
        (parameters.inputTrainingConversationCount == null) ?
            (null) :
            (parameters.inputTrainingConversationCount));
    this.Serializable$set(
        'inputTrainingSummaryCount',
        (parameters.inputTrainingSummaryCount == null) ?
            (null) :
            (parameters.inputTrainingSummaryCount));
    this.Serializable$set(
        'convLengthDebugMessage',
        (parameters.convLengthDebugMessage == null) ?
            (null) :
            (parameters.convLengthDebugMessage));
    this.Serializable$set(
        'convContentDebugMessage',
        (parameters.convContentDebugMessage == null) ?
            (null) :
            (parameters.convContentDebugMessage));
  }

  get convContentDebugMessage(): string|null {
    return (
        (this.Serializable$has('convContentDebugMessage')) ?
            (this.Serializable$get('convContentDebugMessage')) :
            (null));
  }

  /**
   * Debug message for summary content check.
   */
  set convContentDebugMessage(value: string|null) {
    this.Serializable$set('convContentDebugMessage', value);
  }

  get convLengthDebugMessage(): string|null {
    return (
        (this.Serializable$has('convLengthDebugMessage')) ?
            (this.Serializable$get('convLengthDebugMessage')) :
            (null));
  }

  /**
   * Debug message for conversation/summary length check.
   */
  set convLengthDebugMessage(value: string|null) {
    this.Serializable$set('convLengthDebugMessage', value);
  }

  get inputTrainingConversationCount(): string|null {
    return (
        (this.Serializable$has('inputTrainingConversationCount')) ?
            (this.Serializable$get('inputTrainingConversationCount')) :
            (null));
  }

  /**
   * ------- Summarization model training data validation result. Number of
   * training conversation before preprocessing.
   */
  set inputTrainingConversationCount(value: string|null) {
    this.Serializable$set('inputTrainingConversationCount', value);
  }

  get inputTrainingSummaryCount(): string|null {
    return (
        (this.Serializable$has('inputTrainingSummaryCount')) ?
            (this.Serializable$get('inputTrainingSummaryCount')) :
            (null));
  }

  /**
   * Number of training summary before preprocessing.
   */
  set inputTrainingSummaryCount(value: string|null) {
    this.Serializable$set('inputTrainingSummaryCount', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2SummarizationModelTrainingMetadata> {
    return GoogleCloudDialogflowV2SummarizationModelTrainingMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'convContentDebugMessage', 'convLengthDebugMessage',
        'inputTrainingConversationCount', 'inputTrainingSummaryCount'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV2UndeployConversationModelOperationMetadataParameters {
  conversationModel?: string|null;
  createTime?: string|null;
}
export class
    GoogleCloudDialogflowV2UndeployConversationModelOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2UndeployConversationModelOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when the request to undeploy conversation model was submitted.
   * The time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2UndeployConversationModelOperationMetadata> {
    return GoogleCloudDialogflowV2UndeployConversationModelOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationModel', 'createTime']};
  }
}

export interface GoogleCloudDialogflowV2WebhookRequestParameters {
  session?: string|null;
  responseId?: string|null;
  queryResult?: GoogleCloudDialogflowV2QueryResult|null;
  alternativeQueryResults?: Array<GoogleCloudDialogflowV2QueryResult>|null;
  originalDetectIntentRequest?:
      GoogleCloudDialogflowV2OriginalDetectIntentRequest|null;
}
export class GoogleCloudDialogflowV2WebhookRequest extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2WebhookRequestParameters = {}) {
    super();
    this.Serializable$set(
        'session',
        (parameters.session == null) ? (null) : (parameters.session));
    this.Serializable$set(
        'responseId',
        (parameters.responseId == null) ? (null) : (parameters.responseId));
    this.Serializable$set(
        'queryResult',
        (parameters.queryResult == null) ? (null) : (parameters.queryResult));
    this.Serializable$set(
        'alternativeQueryResults',
        (parameters.alternativeQueryResults == null) ?
            (null) :
            (parameters.alternativeQueryResults));
    this.Serializable$set(
        'originalDetectIntentRequest',
        (parameters.originalDetectIntentRequest == null) ?
            (null) :
            (parameters.originalDetectIntentRequest));
  }

  get alternativeQueryResults():
      Array<GoogleCloudDialogflowV2QueryResult>|null {
    return (
        (this.Serializable$has('alternativeQueryResults')) ?
            (this.Serializable$get('alternativeQueryResults')) :
            (null));
  }

  /**
   * Alternative query results from KnowledgeService.
   */
  set alternativeQueryResults(value: Array<GoogleCloudDialogflowV2QueryResult>|
                              null) {
    this.Serializable$set('alternativeQueryResults', value);
  }

  get originalDetectIntentRequest():
      GoogleCloudDialogflowV2OriginalDetectIntentRequest|null {
    return (
        (this.Serializable$has('originalDetectIntentRequest')) ?
            (this.Serializable$get('originalDetectIntentRequest')) :
            (null));
  }

  /**
   * Optional. The contents of the original request that was passed to
   * `[Streaming]DetectIntent` call.
   */
  set originalDetectIntentRequest(
      value: GoogleCloudDialogflowV2OriginalDetectIntentRequest|null) {
    this.Serializable$set('originalDetectIntentRequest', value);
  }

  get queryResult(): GoogleCloudDialogflowV2QueryResult|null {
    return (
        (this.Serializable$has('queryResult')) ?
            (this.Serializable$get('queryResult')) :
            (null));
  }

  /**
   * The result of the conversational query or event processing. Contains the
   * same value as `[Streaming]DetectIntentResponse.query_result`.
   */
  set queryResult(value: GoogleCloudDialogflowV2QueryResult|null) {
    this.Serializable$set('queryResult', value);
  }

  get responseId(): string|null {
    return (
        (this.Serializable$has('responseId')) ?
            (this.Serializable$get('responseId')) :
            (null));
  }

  /**
   * The unique identifier of the response. Contains the same value as
   * `[Streaming]DetectIntentResponse.response_id`.
   */
  set responseId(value: string|null) {
    this.Serializable$set('responseId', value);
  }

  get session(): string|null {
    return (
        (this.Serializable$has('session')) ?
            (this.Serializable$get('session')) :
            (null));
  }

  /**
   * The unique identifier of detectIntent request session. Can be used to
   * identify end-user inside webhook implementation. Format:
   * `projects//agent/sessions/`, or
   * `projects//agent/environments//users//sessions/`.
   */
  set session(value: string|null) {
    this.Serializable$set('session', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2WebhookRequest> {
    return GoogleCloudDialogflowV2WebhookRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'alternativeQueryResults': GoogleCloudDialogflowV2QueryResult},
      keys: [
        'alternativeQueryResults', 'originalDetectIntentRequest', 'queryResult',
        'responseId', 'session'
      ],
      objects: {
        'originalDetectIntentRequest':
            GoogleCloudDialogflowV2OriginalDetectIntentRequest,
        'queryResult': GoogleCloudDialogflowV2QueryResult
      }
    };
  }
}

export interface GoogleCloudDialogflowV2WebhookResponseParameters {
  fulfillmentText?: string|null;
  fulfillmentMessages?: Array<GoogleCloudDialogflowV2IntentMessage>|null;
  source?: string|null;
  payload?: ApiClientObjectMap<any>|null;
  outputContexts?: Array<GoogleCloudDialogflowV2Context>|null;
  followupEventInput?: GoogleCloudDialogflowV2EventInput|null;
  liveAgentHandoff?: boolean|null;
  endInteraction?: boolean|null;
  metadata?: GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadata|null;
  sessionEntityTypes?: Array<GoogleCloudDialogflowV2SessionEntityType>|null;
}
export class GoogleCloudDialogflowV2WebhookResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV2WebhookResponseParameters = {}) {
    super();
    this.Serializable$set(
        'fulfillmentText',
        (parameters.fulfillmentText == null) ? (null) :
                                               (parameters.fulfillmentText));
    this.Serializable$set(
        'fulfillmentMessages',
        (parameters.fulfillmentMessages == null) ?
            (null) :
            (parameters.fulfillmentMessages));
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'outputContexts',
        (parameters.outputContexts == null) ? (null) :
                                              (parameters.outputContexts));
    this.Serializable$set(
        'followupEventInput',
        (parameters.followupEventInput == null) ?
            (null) :
            (parameters.followupEventInput));
    this.Serializable$set(
        'liveAgentHandoff',
        (parameters.liveAgentHandoff == null) ? (null) :
                                                (parameters.liveAgentHandoff));
    this.Serializable$set(
        'endInteraction',
        (parameters.endInteraction == null) ? (null) :
                                              (parameters.endInteraction));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'sessionEntityTypes',
        (parameters.sessionEntityTypes == null) ?
            (null) :
            (parameters.sessionEntityTypes));
  }

  get endInteraction(): boolean|null {
    return (
        (this.Serializable$has('endInteraction')) ?
            (this.Serializable$get('endInteraction')) :
            (null));
  }

  /**
   * Optional. Indicates that this intent ends an interaction. Some integrations
   * (e.g., Actions on Google or Dialogflow phone gateway) use this information
   * to close interaction with an end user. Default is false.
   */
  set endInteraction(value: boolean|null) {
    this.Serializable$set('endInteraction', value);
  }

  get followupEventInput(): GoogleCloudDialogflowV2EventInput|null {
    return (
        (this.Serializable$has('followupEventInput')) ?
            (this.Serializable$get('followupEventInput')) :
            (null));
  }

  /**
   * Optional. Invokes the supplied events. When this field is set, Dialogflow
   * ignores the `fulfillment_text`, `fulfillment_messages`, and `payload`
   * fields.
   */
  set followupEventInput(value: GoogleCloudDialogflowV2EventInput|null) {
    this.Serializable$set('followupEventInput', value);
  }

  get fulfillmentMessages(): Array<GoogleCloudDialogflowV2IntentMessage>|null {
    return (
        (this.Serializable$has('fulfillmentMessages')) ?
            (this.Serializable$get('fulfillmentMessages')) :
            (null));
  }

  /**
   * Optional. The rich response messages intended for the end-user. When
   * provided, Dialogflow uses this field to populate
   * QueryResult.fulfillment_messages sent to the integration or API caller.
   */
  set fulfillmentMessages(value: Array<GoogleCloudDialogflowV2IntentMessage>|
                          null) {
    this.Serializable$set('fulfillmentMessages', value);
  }

  get fulfillmentText(): string|null {
    return (
        (this.Serializable$has('fulfillmentText')) ?
            (this.Serializable$get('fulfillmentText')) :
            (null));
  }

  /**
   * Optional. The text response message intended for the end-user. It is
   * recommended to use `fulfillment_messages.text.text[0]` instead. When
   * provided, Dialogflow uses this field to populate
   * QueryResult.fulfillment_text sent to the integration or API caller.
   */
  set fulfillmentText(value: string|null) {
    this.Serializable$set('fulfillmentText', value);
  }

  get liveAgentHandoff(): boolean|null {
    return (
        (this.Serializable$has('liveAgentHandoff')) ?
            (this.Serializable$get('liveAgentHandoff')) :
            (null));
  }

  /**
   * Optional. Indicates that a live agent should be brought in to handle the
   * interaction with the user. In most cases, when you set this flag to true,
   * you would also want to set end_interaction to true as well. Default is
   * false.
   */
  set liveAgentHandoff(value: boolean|null) {
    this.Serializable$set('liveAgentHandoff', value);
  }

  get metadata(): GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadata
      |null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Optional. Extra metadata information about the webhook response.
   */
  set metadata(
      value: GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadata|
      null) {
    this.Serializable$set('metadata', value);
  }

  get outputContexts(): Array<GoogleCloudDialogflowV2Context>|null {
    return (
        (this.Serializable$has('outputContexts')) ?
            (this.Serializable$get('outputContexts')) :
            (null));
  }

  /**
   * Optional. The collection of output contexts that will overwrite currently
   * active contexts for the session and reset their lifespans. When provided,
   * Dialogflow uses this field to populate QueryResult.output_contexts sent to
   * the integration or API caller.
   */
  set outputContexts(value: Array<GoogleCloudDialogflowV2Context>|null) {
    this.Serializable$set('outputContexts', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Optional. This field can be used to pass custom data from your webhook to
   * the integration or API caller. Arbitrary JSON objects are supported. When
   * provided, Dialogflow uses this field to populate
   * QueryResult.webhook_payload sent to the integration or API caller. This
   * field is also used by the [Google Assistant
   * integration](https://cloud.google.com/dialogflow/docs/integrations/aog) for
   * rich response messages. See the format definition at [Google Assistant
   * Dialogflow webhook
   * format](https://developers.google.com/assistant/actions/build/json/dialogflow-webhook-json)
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get sessionEntityTypes():
      Array<GoogleCloudDialogflowV2SessionEntityType>|null {
    return (
        (this.Serializable$has('sessionEntityTypes')) ?
            (this.Serializable$get('sessionEntityTypes')) :
            (null));
  }

  /**
   * Optional. Additional session entity types to replace or extend developer
   * entity types with. The entity synonyms apply to all languages and persist
   * for the session. Setting this data from a webhook overwrites the session
   * entity types that have been set using `detectIntent`,
   * `streamingDetectIntent` or SessionEntityType management methods.
   */
  set sessionEntityTypes(value: Array<GoogleCloudDialogflowV2SessionEntityType>|
                         null) {
    this.Serializable$set('sessionEntityTypes', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * Optional. A custom field used to identify the webhook source. Arbitrary
   * strings are supported. When provided, Dialogflow uses this field to
   * populate QueryResult.webhook_source sent to the integration or API caller.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV2WebhookResponse> {
    return GoogleCloudDialogflowV2WebhookResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'fulfillmentMessages': GoogleCloudDialogflowV2IntentMessage,
        'outputContexts': GoogleCloudDialogflowV2Context,
        'sessionEntityTypes': GoogleCloudDialogflowV2SessionEntityType
      },
      keys: [
        'endInteraction', 'followupEventInput', 'fulfillmentMessages',
        'fulfillmentText', 'liveAgentHandoff', 'metadata', 'outputContexts',
        'payload', 'sessionEntityTypes', 'source'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'followupEventInput': GoogleCloudDialogflowV2EventInput,
        'metadata':
            GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataParameters {
  googleLibrary?:
      GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataGoogleLibraryInfo|
      null;
}
export class
    GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'googleLibrary',
        (parameters.googleLibrary == null) ? (null) :
                                             (parameters.googleLibrary));
  }

  get googleLibrary():
      GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataGoogleLibraryInfo
      |null {
    return (
        (this.Serializable$has('googleLibrary')) ?
            (this.Serializable$get('googleLibrary')) :
            (null));
  }

  /**
   * Optional. Fulfillment library that was used to generate webhook response.
   */
  set googleLibrary(
      value:
          GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataGoogleLibraryInfo|
      null) {
    this.Serializable$set('googleLibrary', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadata> {
    return GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['googleLibrary'],
      objects: {
        'googleLibrary':
            GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataGoogleLibraryInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataGoogleLibraryInfoParameters {
  name?: string|null;
  language?: string|null;
  version?: string|null;
}
export class
    GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataGoogleLibraryInfo extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataGoogleLibraryInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'language',
        (parameters.language == null) ? (null) : (parameters.language));
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
  }

  get language(): string|null {
    return (
        (this.Serializable$has('language')) ?
            (this.Serializable$get('language')) :
            (null));
  }

  /**
   * Programming language of Google library used by developer for fulfillment.
   */
  set language(value: string|null) {
    this.Serializable$set('language', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Name of Google library used by developer for fulfillment. Example values
   * include `dialogflow` for the Dialogflow fulfillment library and `actions`
   * for Actions on Google libraries.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get version(): string|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Version of Google library used by developer for fulfillment.
   */
  set version(value: string|null) {
    this.Serializable$set('version', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataGoogleLibraryInfo> {
    return GoogleCloudDialogflowV2WebhookResponseWebhookResponseMetadataGoogleLibraryInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['language', 'name', 'version']};
  }
}

export interface GoogleCloudDialogflowV3alpha1AdvancedSettingsParameters {
  enabled?: boolean|null;
  audioExportGcsDestination?: GoogleCloudDialogflowV3alpha1GcsDestination|null;
  speechSettings?: GoogleCloudDialogflowV3alpha1AdvancedSettingsSpeechSettings|
      null;
  playbackInterruptionSettings?:
      GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings|null;
  dtmfSettings?: GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings|null;
  loggingSettings?:
      GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings|null;
  requestLoggingSettings?:
      GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings|null;
  dtmfSettingsList?:
      Array<GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings>|null;
}
export class GoogleCloudDialogflowV3alpha1AdvancedSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1AdvancedSettingsParameters = {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'audioExportGcsDestination',
        (parameters.audioExportGcsDestination == null) ?
            (null) :
            (parameters.audioExportGcsDestination));
    this.Serializable$set(
        'speechSettings',
        (parameters.speechSettings == null) ? (null) :
                                              (parameters.speechSettings));
    this.Serializable$set(
        'playbackInterruptionSettings',
        (parameters.playbackInterruptionSettings == null) ?
            (null) :
            (parameters.playbackInterruptionSettings));
    this.Serializable$set(
        'dtmfSettings',
        (parameters.dtmfSettings == null) ? (null) : (parameters.dtmfSettings));
    this.Serializable$set(
        'loggingSettings',
        (parameters.loggingSettings == null) ? (null) :
                                               (parameters.loggingSettings));
    this.Serializable$set(
        'requestLoggingSettings',
        (parameters.requestLoggingSettings == null) ?
            (null) :
            (parameters.requestLoggingSettings));
    this.Serializable$set(
        'dtmfSettingsList',
        (parameters.dtmfSettingsList == null) ? (null) :
                                                (parameters.dtmfSettingsList));
  }

  get audioExportGcsDestination(): GoogleCloudDialogflowV3alpha1GcsDestination
      |null {
    return (
        (this.Serializable$has('audioExportGcsDestination')) ?
            (this.Serializable$get('audioExportGcsDestination')) :
            (null));
  }

  /**
   * If present, incoming audio is exported by Dialogflow to the configured
   * Google Cloud Storage destination. Exposed at the following levels: - Agent
   * level - Flow level
   */
  set audioExportGcsDestination(value:
                                    GoogleCloudDialogflowV3alpha1GcsDestination|
                                null) {
    this.Serializable$set('audioExportGcsDestination', value);
  }

  get dtmfSettings(): GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings
      |null {
    return (
        (this.Serializable$has('dtmfSettings')) ?
            (this.Serializable$get('dtmfSettings')) :
            (null));
  }

  /**
   * Settings for DTMF. Exposed at the following levels: - Agent level - Flow
   * level - Page level - Parameter level.
   */
  set dtmfSettings(
      value: GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings|null) {
    this.Serializable$set('dtmfSettings', value);
  }

  get dtmfSettingsList():
      Array<GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings>|null {
    return (
        (this.Serializable$has('dtmfSettingsList')) ?
            (this.Serializable$get('dtmfSettingsList')) :
            (null));
  }

  /**
   * List of DtmfSettings emitted from different levels of agent. Each
   * DtmfSettings in the list has higher priority than the ones before it in the
   * list, with the last one having the highest priority.
   */
  set dtmfSettingsList(
      value: Array<GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings>|
      null) {
    this.Serializable$set('dtmfSettingsList', value);
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * Whether advanced settings should be enabled. Exposed at the following
   * levels: - Agent level - Flow level
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get loggingSettings():
      GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings|null {
    return (
        (this.Serializable$has('loggingSettings')) ?
            (this.Serializable$get('loggingSettings')) :
            (null));
  }

  /**
   * Settings for logging. Settings for Dialogflow History, Contact Center
   * messages, StackDriver logs, and speech logging. Exposed at the following
   * levels: - Agent level.
   */
  set loggingSettings(
      value: GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings|
      null) {
    this.Serializable$set('loggingSettings', value);
  }

  get playbackInterruptionSettings():
      GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings|null {
    return (
        (this.Serializable$has('playbackInterruptionSettings')) ?
            (this.Serializable$get('playbackInterruptionSettings')) :
            (null));
  }

  /**
   * Whether to allow interruption on the playback of a message. Exposed at the
   * following levels: - Agent level - Flow level - Fulfillment level
   */
  set playbackInterruptionSettings(
      value: GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings|null) {
    this.Serializable$set('playbackInterruptionSettings', value);
  }

  get requestLoggingSettings():
      GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings|null {
    return (
        (this.Serializable$has('requestLoggingSettings')) ?
            (this.Serializable$get('requestLoggingSettings')) :
            (null));
  }

  /**
   * Output only. Indicates whether to log DetectIntentRequest for Dialogflow
   * Interaction. This field will only be filled during runtime. The storage
   * service depends on this settings to decide whether to log the request. -
   * Note: Whether to log DetectIntentResponse is controlled by
   * `logging_settings`[] above.
   */
  set requestLoggingSettings(
      value: GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings|
      null) {
    this.Serializable$set('requestLoggingSettings', value);
  }

  get speechSettings():
      GoogleCloudDialogflowV3alpha1AdvancedSettingsSpeechSettings|null {
    return (
        (this.Serializable$has('speechSettings')) ?
            (this.Serializable$get('speechSettings')) :
            (null));
  }

  /**
   * Settings for speech to text detection. Exposed at the following levels: -
   * Agent level - Flow level - Page level - Parameter level
   */
  set speechSettings(
      value: GoogleCloudDialogflowV3alpha1AdvancedSettingsSpeechSettings|null) {
    this.Serializable$set('speechSettings', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1AdvancedSettings> {
    return GoogleCloudDialogflowV3alpha1AdvancedSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'dtmfSettingsList':
            GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings
      },
      keys: [
        'audioExportGcsDestination', 'dtmfSettings', 'dtmfSettingsList',
        'enabled', 'loggingSettings', 'playbackInterruptionSettings',
        'requestLoggingSettings', 'speechSettings'
      ],
      objects: {
        'audioExportGcsDestination':
            GoogleCloudDialogflowV3alpha1GcsDestination,
        'dtmfSettings':
            GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings,
        'loggingSettings':
            GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings,
        'playbackInterruptionSettings':
            GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings,
        'requestLoggingSettings':
            GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings,
        'speechSettings':
            GoogleCloudDialogflowV3alpha1AdvancedSettingsSpeechSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsParameters {
  enabled?: boolean|null;
  maxDigits?: number|null;
  finishDigit?: string|null;
  customRule?:
      GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPattern|null;
  useSystemEntityRule?: boolean|null;
  interdigitTimeoutDuration?: string|null;
  endpointingTimeoutDuration?: string|null;
  entityType?: string|null;
}
export class GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'maxDigits',
        (parameters.maxDigits == null) ? (null) : (parameters.maxDigits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
    this.Serializable$set(
        'customRule',
        (parameters.customRule == null) ? (null) : (parameters.customRule));
    this.Serializable$set(
        'useSystemEntityRule',
        (parameters.useSystemEntityRule == null) ?
            (null) :
            (parameters.useSystemEntityRule));
    this.Serializable$set(
        'interdigitTimeoutDuration',
        (parameters.interdigitTimeoutDuration == null) ?
            (null) :
            (parameters.interdigitTimeoutDuration));
    this.Serializable$set(
        'endpointingTimeoutDuration',
        (parameters.endpointingTimeoutDuration == null) ?
            (null) :
            (parameters.endpointingTimeoutDuration));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
  }

  get customRule():
      GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPattern
      |null {
    return (
        (this.Serializable$has('customRule')) ?
            (this.Serializable$get('customRule')) :
            (null));
  }

  /**
   * User defines the rule for dtmf transformation.
   */
  set customRule(
      value:
          GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPattern|
      null) {
    this.Serializable$set('customRule', value);
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * If true, incoming audio is processed for DTMF (dual tone multi frequency)
   * events. For example, if the caller presses a button on their telephone
   * keypad and DTMF processing is enabled, Dialogflow will detect the event
   * (e.g. a \"3\" was pressed) in the incoming audio and pass the event to the
   * bot to drive business logic (e.g. when 3 is pressed, return the account
   * balance).
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get endpointingTimeoutDuration(): string|null {
    return (
        (this.Serializable$has('endpointingTimeoutDuration')) ?
            (this.Serializable$get('endpointingTimeoutDuration')) :
            (null));
  }

  /**
   * Endpoint timeout setting for matching dtmf input to regex.
   */
  set endpointingTimeoutDuration(value: string|null) {
    this.Serializable$set('endpointingTimeoutDuration', value);
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * Entity type of the parameter to be filled by the DTMF. Available only at
   * runtime by deriving from the form filling info.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get finishDigit(): string|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * The digit that terminates a DTMF digit sequence.
   */
  set finishDigit(value: string|null) {
    this.Serializable$set('finishDigit', value);
  }

  get interdigitTimeoutDuration(): string|null {
    return (
        (this.Serializable$has('interdigitTimeoutDuration')) ?
            (this.Serializable$get('interdigitTimeoutDuration')) :
            (null));
  }

  /**
   * Interdigit timeout setting for matching dtmf input to regex.
   */
  set interdigitTimeoutDuration(value: string|null) {
    this.Serializable$set('interdigitTimeoutDuration', value);
  }

  get maxDigits(): number|null {
    return (
        (this.Serializable$has('maxDigits')) ?
            (this.Serializable$get('maxDigits')) :
            (null));
  }

  /**
   * Max length of DTMF digits.
   */
  set maxDigits(value: number|null) {
    this.Serializable$set('maxDigits', value);
  }

  get useSystemEntityRule(): boolean|null {
    return (
        (this.Serializable$has('useSystemEntityRule')) ?
            (this.Serializable$get('useSystemEntityRule')) :
            (null));
  }

  /**
   * When true, system defines transformation rule based on the parameter entity
   * type
   */
  set useSystemEntityRule(value: boolean|null) {
    this.Serializable$set('useSystemEntityRule', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings> {
    return GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'customRule', 'enabled', 'endpointingTimeoutDuration', 'entityType',
        'finishDigit', 'interdigitTimeoutDuration', 'maxDigits',
        'useSystemEntityRule'
      ],
      objects: {
        'customRule':
            GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPattern
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternParameters {
  regex?: string|null;
  transformations?: Array<
      GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternTransformation>|
      null;
  prefixRegex?: string|null;
}
export class
    GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPattern extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternParameters =
              {}) {
    super();
    this.Serializable$set(
        'regex', (parameters.regex == null) ? (null) : (parameters.regex));
    this.Serializable$set(
        'transformations',
        (parameters.transformations == null) ? (null) :
                                               (parameters.transformations));
    this.Serializable$set(
        'prefixRegex',
        (parameters.prefixRegex == null) ? (null) : (parameters.prefixRegex));
  }

  get prefixRegex(): string|null {
    return (
        (this.Serializable$has('prefixRegex')) ?
            (this.Serializable$get('prefixRegex')) :
            (null));
  }

  /**
   * The regex pattern that DTMF input matches partially as a prefix. Ex. regex
   * = (123)(123)* prefix_regex = (123)(1|12|123)* input = \"1231\" ->
   * prefix_regex is matched but regex is not, waits for more input input =
   * \"123123\" -> both regex and prefix_regex are matched, use endpointing time
   * duration to wait for more user input, then end as a match input = \"214\"
   * -> instantly ends as no match
   */
  set prefixRegex(value: string|null) {
    this.Serializable$set('prefixRegex', value);
  }

  get regex(): string|null {
    return (
        (this.Serializable$has('regex')) ? (this.Serializable$get('regex')) :
                                           (null));
  }

  /**
   * The regex pattern that DTMF input should match.
   */
  set regex(value: string|null) {
    this.Serializable$set('regex', value);
  }

  get transformations(): Array<
      GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternTransformation>|
      null {
    return (
        (this.Serializable$has('transformations')) ?
            (this.Serializable$get('transformations')) :
            (null));
  }

  /**
   * regex matching and transformation rules for DTMF inputs.
   */
  set transformations(
      value: Array<
          GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternTransformation>|
      null) {
    this.Serializable$set('transformations', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPattern> {
    return GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPattern;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'transformations':
            GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternTransformation
      },
      keys: ['prefixRegex', 'regex', 'transformations']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternTransformationParameters {
  pattern?: string|null;
  rewriteRule?: string|null;
}
export class
    GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternTransformation extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternTransformationParameters =
              {}) {
    super();
    this.Serializable$set(
        'pattern',
        (parameters.pattern == null) ? (null) : (parameters.pattern));
    this.Serializable$set(
        'rewriteRule',
        (parameters.rewriteRule == null) ? (null) : (parameters.rewriteRule));
  }

  get pattern(): string|null {
    return (
        (this.Serializable$has('pattern')) ?
            (this.Serializable$get('pattern')) :
            (null));
  }

  /**
   * The regex pattern to be matched.
   */
  set pattern(value: string|null) {
    this.Serializable$set('pattern', value);
  }

  get rewriteRule(): string|null {
    return (
        (this.Serializable$has('rewriteRule')) ?
            (this.Serializable$get('rewriteRule')) :
            (null));
  }

  /**
   * The transformation rule to replace/ rewrite the input.
   */
  set rewriteRule(value: string|null) {
    this.Serializable$set('rewriteRule', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternTransformation> {
    return GoogleCloudDialogflowV3alpha1AdvancedSettingsDtmfSettingsDtmfPatternTransformation;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['pattern', 'rewriteRule']};
  }
}

export interface GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettingsParameters {
  disableLogging?: boolean|null;
  enableStackdriverLogging?: boolean|null;
  enableInteractionLogging?: boolean|null;
}
export class
    GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
    this.Serializable$set(
        'enableStackdriverLogging',
        (parameters.enableStackdriverLogging == null) ?
            (null) :
            (parameters.enableStackdriverLogging));
    this.Serializable$set(
        'enableInteractionLogging',
        (parameters.enableInteractionLogging == null) ?
            (null) :
            (parameters.enableInteractionLogging));
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * If true, DF Interaction logging is currently disabled.
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  get enableInteractionLogging(): boolean|null {
    return (
        (this.Serializable$has('enableInteractionLogging')) ?
            (this.Serializable$get('enableInteractionLogging')) :
            (null));
  }

  /**
   * If true, DF Interaction logging is currently enabled.
   */
  set enableInteractionLogging(value: boolean|null) {
    this.Serializable$set('enableInteractionLogging', value);
  }

  get enableStackdriverLogging(): boolean|null {
    return (
        (this.Serializable$has('enableStackdriverLogging')) ?
            (this.Serializable$get('enableStackdriverLogging')) :
            (null));
  }

  /**
   * If true, StackDriver logging is currently enabled.
   */
  set enableStackdriverLogging(value: boolean|null) {
    this.Serializable$set('enableStackdriverLogging', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings> {
    return GoogleCloudDialogflowV3alpha1AdvancedSettingsLoggingSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'disableLogging', 'enableInteractionLogging', 'enableStackdriverLogging'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1AdvancedSettingsSpeechSettingsParameters {
  endpointerSensitivity?: number|null;
  noSpeechTimeout?: string|null;
  useTimeoutBasedEndpointing?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1AdvancedSettingsSpeechSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1AdvancedSettingsSpeechSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'endpointerSensitivity',
        (parameters.endpointerSensitivity == null) ?
            (null) :
            (parameters.endpointerSensitivity));
    this.Serializable$set(
        'noSpeechTimeout',
        (parameters.noSpeechTimeout == null) ? (null) :
                                               (parameters.noSpeechTimeout));
    this.Serializable$set(
        'useTimeoutBasedEndpointing',
        (parameters.useTimeoutBasedEndpointing == null) ?
            (null) :
            (parameters.useTimeoutBasedEndpointing));
  }

  get endpointerSensitivity(): number|null {
    return (
        (this.Serializable$has('endpointerSensitivity')) ?
            (this.Serializable$get('endpointerSensitivity')) :
            (null));
  }

  /**
   * Sensitivity of the speech model that detects the end of speech. Scale from
   * 0 to 100.
   */
  set endpointerSensitivity(value: number|null) {
    this.Serializable$set('endpointerSensitivity', value);
  }

  get noSpeechTimeout(): string|null {
    return (
        (this.Serializable$has('noSpeechTimeout')) ?
            (this.Serializable$get('noSpeechTimeout')) :
            (null));
  }

  /**
   * Timeout before detecting no speech.
   */
  set noSpeechTimeout(value: string|null) {
    this.Serializable$set('noSpeechTimeout', value);
  }

  get useTimeoutBasedEndpointing(): boolean|null {
    return (
        (this.Serializable$has('useTimeoutBasedEndpointing')) ?
            (this.Serializable$get('useTimeoutBasedEndpointing')) :
            (null));
  }

  /**
   * Use timeout based endpointing, interpreting endpointer sensitivy as seconds
   * of timeout value.
   */
  set useTimeoutBasedEndpointing(value: boolean|null) {
    this.Serializable$set('useTimeoutBasedEndpointing', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1AdvancedSettingsSpeechSettings> {
    return GoogleCloudDialogflowV3alpha1AdvancedSettingsSpeechSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'endpointerSensitivity', 'noSpeechTimeout', 'useTimeoutBasedEndpointing'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1AudioInputParameters {
  config?: GoogleCloudDialogflowV3alpha1InputAudioConfig|null;
  audio?: string|null;
}
export class GoogleCloudDialogflowV3alpha1AudioInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1AudioInputParameters = {}) {
    super();
    this.Serializable$set(
        'config', (parameters.config == null) ? (null) : (parameters.config));
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
  }

  get audio(): string|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * The natural language speech audio to be processed. A single request can
   * contain up to 1 minute of speech audio data. The transcribed text cannot
   * contain more than 256 bytes. For non-streaming audio detect intent, both
   * `config` and `audio` must be provided. For streaming audio detect intent,
   * `config` must be provided in the first request and `audio` must be provided
   * in all following requests.
   */
  set audio(value: string|null) {
    this.Serializable$set('audio', value);
  }

  get config(): GoogleCloudDialogflowV3alpha1InputAudioConfig|null {
    return (
        (this.Serializable$has('config')) ? (this.Serializable$get('config')) :
                                            (null));
  }

  /**
   * Required. Instructs the speech recognizer how to process the speech audio.
   */
  set config(value: GoogleCloudDialogflowV3alpha1InputAudioConfig|null) {
    this.Serializable$set('config', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1AudioInput> {
    return GoogleCloudDialogflowV3alpha1AudioInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['audio', 'config'],
      objects: {'config': GoogleCloudDialogflowV3alpha1InputAudioConfig}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1BargeInConfigParameters {
  noBargeInDuration?: string|null;
  totalDuration?: string|null;
}
export class GoogleCloudDialogflowV3alpha1BargeInConfig extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1BargeInConfigParameters = {}) {
    super();
    this.Serializable$set(
        'noBargeInDuration',
        (parameters.noBargeInDuration == null) ?
            (null) :
            (parameters.noBargeInDuration));
    this.Serializable$set(
        'totalDuration',
        (parameters.totalDuration == null) ? (null) :
                                             (parameters.totalDuration));
  }

  get noBargeInDuration(): string|null {
    return (
        (this.Serializable$has('noBargeInDuration')) ?
            (this.Serializable$get('noBargeInDuration')) :
            (null));
  }

  /**
   * Duration that is not eligible for barge-in at the beginning of the input
   * audio.
   */
  set noBargeInDuration(value: string|null) {
    this.Serializable$set('noBargeInDuration', value);
  }

  get totalDuration(): string|null {
    return (
        (this.Serializable$has('totalDuration')) ?
            (this.Serializable$get('totalDuration')) :
            (null));
  }

  /**
   * Total duration for the playback at the beginning of the input audio.
   */
  set totalDuration(value: string|null) {
    this.Serializable$set('totalDuration', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1BargeInConfig> {
    return GoogleCloudDialogflowV3alpha1BargeInConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['noBargeInDuration', 'totalDuration']};
  }
}

export interface GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesMetadataParameters {
}
export class
    GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesMetadataParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesMetadata> {
    return GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseParameters {
  annotations?: Array<
      GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseTrainingPhraseAnnotation>|
      null;
  parameters?: Array<GoogleCloudDialogflowV3alpha1IntentParameter>|null;
}
export class
    GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'annotations',
        (parameters.annotations == null) ? (null) : (parameters.annotations));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get annotations(): Array<
      GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseTrainingPhraseAnnotation>|
      null {
    return (
        (this.Serializable$has('annotations')) ?
            (this.Serializable$get('annotations')) :
            (null));
  }

  /**
   * The annotations of the training phrases.
   */
  set annotations(
      value: Array<
          GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseTrainingPhraseAnnotation>|
      null) {
    this.Serializable$set('annotations', value);
  }

  get parameters(): Array<GoogleCloudDialogflowV3alpha1IntentParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Additional collection of parameters that needed to be added to the intent.
   */
  set parameters(value: Array<GoogleCloudDialogflowV3alpha1IntentParameter>|
                 null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponse> {
    return GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'annotations':
            GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseTrainingPhraseAnnotation,
        'parameters': GoogleCloudDialogflowV3alpha1IntentParameter
      },
      keys: ['annotations', 'parameters']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseTrainingPhraseAnnotationParameters {
  trainingPhrase?: string|null;
  annotatedTrainingPhrase?: GoogleCloudDialogflowV3alpha1IntentTrainingPhrase|
      null;
}
export class
    GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseTrainingPhraseAnnotation extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseTrainingPhraseAnnotationParameters =
              {}) {
    super();
    this.Serializable$set(
        'trainingPhrase',
        (parameters.trainingPhrase == null) ? (null) :
                                              (parameters.trainingPhrase));
    this.Serializable$set(
        'annotatedTrainingPhrase',
        (parameters.annotatedTrainingPhrase == null) ?
            (null) :
            (parameters.annotatedTrainingPhrase));
  }

  get annotatedTrainingPhrase():
      GoogleCloudDialogflowV3alpha1IntentTrainingPhrase|null {
    return (
        (this.Serializable$has('annotatedTrainingPhrase')) ?
            (this.Serializable$get('annotatedTrainingPhrase')) :
            (null));
  }

  /**
   * The annonated training phrase.
   */
  set annotatedTrainingPhrase(
      value: GoogleCloudDialogflowV3alpha1IntentTrainingPhrase|null) {
    this.Serializable$set('annotatedTrainingPhrase', value);
  }

  get trainingPhrase(): string|null {
    return (
        (this.Serializable$has('trainingPhrase')) ?
            (this.Serializable$get('trainingPhrase')) :
            (null));
  }

  /**
   * The original training phrase text.
   */
  set trainingPhrase(value: string|null) {
    this.Serializable$set('trainingPhrase', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseTrainingPhraseAnnotation> {
    return GoogleCloudDialogflowV3alpha1BatchAnnotateTrainingPhrasesResponseTrainingPhraseAnnotation;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['annotatedTrainingPhrase', 'trainingPhrase'],
      objects: {
        'annotatedTrainingPhrase':
            GoogleCloudDialogflowV3alpha1IntentTrainingPhrase
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1BatchRunTestCasesMetadataParameters {
  errors?: Array<GoogleCloudDialogflowV3alpha1TestError>|null;
}
export class GoogleCloudDialogflowV3alpha1BatchRunTestCasesMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1BatchRunTestCasesMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'errors', (parameters.errors == null) ? (null) : (parameters.errors));
  }

  get errors(): Array<GoogleCloudDialogflowV3alpha1TestError>|null {
    return (
        (this.Serializable$has('errors')) ? (this.Serializable$get('errors')) :
                                            (null));
  }

  /**
   * The test errors.
   */
  set errors(value: Array<GoogleCloudDialogflowV3alpha1TestError>|null) {
    this.Serializable$set('errors', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1BatchRunTestCasesMetadata> {
    return GoogleCloudDialogflowV3alpha1BatchRunTestCasesMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'errors': GoogleCloudDialogflowV3alpha1TestError},
      keys: ['errors']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1BatchRunTestCasesResponseParameters {
  results?: Array<GoogleCloudDialogflowV3alpha1TestCaseResult>|null;
}
export class GoogleCloudDialogflowV3alpha1BatchRunTestCasesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1BatchRunTestCasesResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'results',
        (parameters.results == null) ? (null) : (parameters.results));
  }

  get results(): Array<GoogleCloudDialogflowV3alpha1TestCaseResult>|null {
    return (
        (this.Serializable$has('results')) ?
            (this.Serializable$get('results')) :
            (null));
  }

  /**
   * The test case results. The detailed conversation turns are empty in this
   * response.
   */
  set results(value: Array<GoogleCloudDialogflowV3alpha1TestCaseResult>|null) {
    this.Serializable$set('results', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1BatchRunTestCasesResponse> {
    return GoogleCloudDialogflowV3alpha1BatchRunTestCasesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'results': GoogleCloudDialogflowV3alpha1TestCaseResult},
      keys: ['results']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1BigQueryDestinationParameters {
  projectId?: string|null;
  dataset?: string|null;
  table?: string|null;
}
export class GoogleCloudDialogflowV3alpha1BigQueryDestination extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1BigQueryDestinationParameters = {}) {
    super();
    this.Serializable$set(
        'projectId',
        (parameters.projectId == null) ? (null) : (parameters.projectId));
    this.Serializable$set(
        'dataset',
        (parameters.dataset == null) ? (null) : (parameters.dataset));
    this.Serializable$set(
        'table', (parameters.table == null) ? (null) : (parameters.table));
  }

  get dataset(): string|null {
    return (
        (this.Serializable$has('dataset')) ?
            (this.Serializable$get('dataset')) :
            (null));
  }

  /**
   * Required. The BigQuery dataset name that the data will be exported to.
   */
  set dataset(value: string|null) {
    this.Serializable$set('dataset', value);
  }

  get projectId(): string|null {
    return (
        (this.Serializable$has('projectId')) ?
            (this.Serializable$get('projectId')) :
            (null));
  }

  /**
   * The project id that the data will be exported to. The project id of Cobweb
   * dataset will be used as default if no project id is specified.
   */
  set projectId(value: string|null) {
    this.Serializable$set('projectId', value);
  }

  get table(): string|null {
    return (
        (this.Serializable$has('table')) ? (this.Serializable$get('table')) :
                                           (null));
  }

  /**
   * Required. The BigQuery table name that the data will be exported to.
   */
  set table(value: string|null) {
    this.Serializable$set('table', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1BigQueryDestination> {
    return GoogleCloudDialogflowV3alpha1BigQueryDestination;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['dataset', 'projectId', 'table']};
  }
}

export interface GoogleCloudDialogflowV3alpha1CloneAgentResponseParameters {
  name?: string|null;
}
export class GoogleCloudDialogflowV3alpha1CloneAgentResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1CloneAgentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The cloned agent.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1CloneAgentResponse> {
    return GoogleCloudDialogflowV3alpha1CloneAgentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['name']};
  }
}

export interface GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataParameters {
  target?: string|null;
  type?: GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType|null;
}
export class GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'target', (parameters.target == null) ? (null) : (parameters.target));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
  }

  static get Type():
      IGoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataTypeEnum {
    return GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataTypeEnum;
  }

  get target(): string|null {
    return (
        (this.Serializable$has('target')) ? (this.Serializable$get('target')) :
                                            (null));
  }

  /**
   * Target of the operation Format: `projects//locations//functions/`
   */
  set target(value: string|null) {
    this.Serializable$set('target', value);
  }

  get type(): GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType
      |null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Type of operation.
   */
  set type(value:
               GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataType|
           null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadata> {
    return GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'type':
            GoogleCloudDialogflowV3alpha1CloudFunctionOperationMetadataTypeEnum
      },
      keys: ['target', 'type']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ContinuousTestResultParameters {
  name?: string|null;
  result?: GoogleCloudDialogflowV3alpha1ContinuousTestResultResult|null;
  testCaseResults?: Array<string>|null;
  runTime?: string|null;
}
export class GoogleCloudDialogflowV3alpha1ContinuousTestResult extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ContinuousTestResultParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'result', (parameters.result == null) ? (null) : (parameters.result));
    this.Serializable$set(
        'testCaseResults',
        (parameters.testCaseResults == null) ? (null) :
                                               (parameters.testCaseResults));
    this.Serializable$set(
        'runTime',
        (parameters.runTime == null) ? (null) : (parameters.runTime));
  }

  static get Result():
      IGoogleCloudDialogflowV3alpha1ContinuousTestResultResultEnum {
    return GoogleCloudDialogflowV3alpha1ContinuousTestResultResultEnum;
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The resource name for the continuous test result. Format:
   * `projects//locations//agents//environments//continuousTestResults/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get result(): GoogleCloudDialogflowV3alpha1ContinuousTestResultResult|null {
    return (
        (this.Serializable$has('result')) ? (this.Serializable$get('result')) :
                                            (null));
  }

  /**
   * The result of this continuous test run, i.e. whether all the tests in this
   * continuous test run pass or not.
   */
  set result(value: GoogleCloudDialogflowV3alpha1ContinuousTestResultResult|
             null) {
    this.Serializable$set('result', value);
  }

  get runTime(): string|null {
    return (
        (this.Serializable$has('runTime')) ?
            (this.Serializable$get('runTime')) :
            (null));
  }

  /**
   * Time when the continuous testing run starts.
   */
  set runTime(value: string|null) {
    this.Serializable$set('runTime', value);
  }

  get testCaseResults(): Array<string>|null {
    return (
        (this.Serializable$has('testCaseResults')) ?
            (this.Serializable$get('testCaseResults')) :
            (null));
  }

  /**
   * A list of individual test case results names in this continuous test run.
   */
  set testCaseResults(value: Array<string>|null) {
    this.Serializable$set('testCaseResults', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ContinuousTestResult> {
    return GoogleCloudDialogflowV3alpha1ContinuousTestResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'result': GoogleCloudDialogflowV3alpha1ContinuousTestResultResultEnum
      },
      keys: ['name', 'result', 'runTime', 'testCaseResults']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ConversationTurnParameters {
  userInput?: GoogleCloudDialogflowV3alpha1ConversationTurnUserInput|null;
  virtualAgentOutput?:
      GoogleCloudDialogflowV3alpha1ConversationTurnVirtualAgentOutput|null;
  expectations?: GoogleCloudDialogflowV3alpha1ConversationTurnExpectations|null;
}
export class GoogleCloudDialogflowV3alpha1ConversationTurn extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ConversationTurnParameters = {}) {
    super();
    this.Serializable$set(
        'userInput',
        (parameters.userInput == null) ? (null) : (parameters.userInput));
    this.Serializable$set(
        'virtualAgentOutput',
        (parameters.virtualAgentOutput == null) ?
            (null) :
            (parameters.virtualAgentOutput));
    this.Serializable$set(
        'expectations',
        (parameters.expectations == null) ? (null) : (parameters.expectations));
  }

  get expectations(): GoogleCloudDialogflowV3alpha1ConversationTurnExpectations
      |null {
    return (
        (this.Serializable$has('expectations')) ?
            (this.Serializable$get('expectations')) :
            (null));
  }

  /**
   * The expectations of this turn.
   */
  set expectations(
      value: GoogleCloudDialogflowV3alpha1ConversationTurnExpectations|null) {
    this.Serializable$set('expectations', value);
  }

  get userInput(): GoogleCloudDialogflowV3alpha1ConversationTurnUserInput|null {
    return (
        (this.Serializable$has('userInput')) ?
            (this.Serializable$get('userInput')) :
            (null));
  }

  /**
   * The user input.
   */
  set userInput(value: GoogleCloudDialogflowV3alpha1ConversationTurnUserInput|
                null) {
    this.Serializable$set('userInput', value);
  }

  get virtualAgentOutput():
      GoogleCloudDialogflowV3alpha1ConversationTurnVirtualAgentOutput|null {
    return (
        (this.Serializable$has('virtualAgentOutput')) ?
            (this.Serializable$get('virtualAgentOutput')) :
            (null));
  }

  /**
   * The virtual agent output.
   */
  set virtualAgentOutput(
      value: GoogleCloudDialogflowV3alpha1ConversationTurnVirtualAgentOutput|
      null) {
    this.Serializable$set('virtualAgentOutput', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ConversationTurn> {
    return GoogleCloudDialogflowV3alpha1ConversationTurn;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['expectations', 'userInput', 'virtualAgentOutput'],
      objects: {
        'expectations':
            GoogleCloudDialogflowV3alpha1ConversationTurnExpectations,
        'userInput': GoogleCloudDialogflowV3alpha1ConversationTurnUserInput,
        'virtualAgentOutput':
            GoogleCloudDialogflowV3alpha1ConversationTurnVirtualAgentOutput
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ConversationTurnExpectationsParameters {
  expectedIntent?: GoogleCloudDialogflowV3alpha1Intent|null;
  expectedPage?: GoogleCloudDialogflowV3alpha1Page|null;
  expectedSessionParameters?: ApiClientObjectMap<any>|null;
  expectedTextResponses?: string|null;
  expectedTextResponsesRegex?: string|null;
}
export class GoogleCloudDialogflowV3alpha1ConversationTurnExpectations extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ConversationTurnExpectationsParameters =
              {}) {
    super();
    this.Serializable$set(
        'expectedIntent',
        (parameters.expectedIntent == null) ? (null) :
                                              (parameters.expectedIntent));
    this.Serializable$set(
        'expectedPage',
        (parameters.expectedPage == null) ? (null) : (parameters.expectedPage));
    this.Serializable$set(
        'expectedSessionParameters',
        (parameters.expectedSessionParameters == null) ?
            (null) :
            (parameters.expectedSessionParameters));
    this.Serializable$set(
        'expectedTextResponses',
        (parameters.expectedTextResponses == null) ?
            (null) :
            (parameters.expectedTextResponses));
    this.Serializable$set(
        'expectedTextResponsesRegex',
        (parameters.expectedTextResponsesRegex == null) ?
            (null) :
            (parameters.expectedTextResponsesRegex));
  }

  get expectedIntent(): GoogleCloudDialogflowV3alpha1Intent|null {
    return (
        (this.Serializable$has('expectedIntent')) ?
            (this.Serializable$get('expectedIntent')) :
            (null));
  }

  /**
   * The desired [Intent] that is triggered by end user's input. If this value
   * is set, then the test case will verify if the Intent returned by the
   * Virtual Agent match the set value. For the expected_intent, user can set
   * name or display_name or both. If any of name or display_name is set, they
   * will be verified against the returned Intent's name or display_name.
   */
  set expectedIntent(value: GoogleCloudDialogflowV3alpha1Intent|null) {
    this.Serializable$set('expectedIntent', value);
  }

  get expectedPage(): GoogleCloudDialogflowV3alpha1Page|null {
    return (
        (this.Serializable$has('expectedPage')) ?
            (this.Serializable$get('expectedPage')) :
            (null));
  }

  /**
   * The [Page] on which the utterance was spoken. If this value is set, then
   * the test case will verify if the Page returned by the Virtual Agent match
   * the set value. For expected_page, user can set name or display_name or
   * both. If any of name or display_name is set, they will be verified against
   * the returned Intent's name or display_name.
   */
  set expectedPage(value: GoogleCloudDialogflowV3alpha1Page|null) {
    this.Serializable$set('expectedPage', value);
  }

  get expectedSessionParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('expectedSessionParameters')) ?
            (this.Serializable$get('expectedSessionParameters')) :
            (null));
  }

  /**
   * The session parameters available to the bot at this point. If this value is
   * set, then the test case will verify if the session_parameters returned by
   * the Virtual Agent match the set value.
   */
  set expectedSessionParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('expectedSessionParameters', value);
  }

  get expectedTextResponses(): string|null {
    return (
        (this.Serializable$has('expectedTextResponses')) ?
            (this.Serializable$get('expectedTextResponses')) :
            (null));
  }

  /**
   * The text responses from the agent for the turn and will be used to verify
   * the text in the response.
   */
  set expectedTextResponses(value: string|null) {
    this.Serializable$set('expectedTextResponses', value);
  }

  get expectedTextResponsesRegex(): string|null {
    return (
        (this.Serializable$has('expectedTextResponsesRegex')) ?
            (this.Serializable$get('expectedTextResponsesRegex')) :
            (null));
  }

  /**
   * The Regex that will be used to verify the responses from the agent for the
   * turn.
   */
  set expectedTextResponsesRegex(value: string|null) {
    this.Serializable$set('expectedTextResponsesRegex', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ConversationTurnExpectations> {
    return GoogleCloudDialogflowV3alpha1ConversationTurnExpectations;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'expectedIntent', 'expectedPage', 'expectedSessionParameters',
        'expectedTextResponses', 'expectedTextResponsesRegex'
      ],
      objectMaps: {
        'expectedSessionParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'expectedIntent': GoogleCloudDialogflowV3alpha1Intent,
        'expectedPage': GoogleCloudDialogflowV3alpha1Page
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ConversationTurnUserInputParameters {
  input?: GoogleCloudDialogflowV3alpha1QueryInput|null;
  injectedParameters?: ApiClientObjectMap<any>|null;
  parameterScope?: string|null;
  isWebhookEnabled?: boolean|null;
  enableSentimentAnalysis?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1ConversationTurnUserInput extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ConversationTurnUserInputParameters =
              {}) {
    super();
    this.Serializable$set(
        'input', (parameters.input == null) ? (null) : (parameters.input));
    this.Serializable$set(
        'injectedParameters',
        (parameters.injectedParameters == null) ?
            (null) :
            (parameters.injectedParameters));
    this.Serializable$set(
        'parameterScope',
        (parameters.parameterScope == null) ? (null) :
                                              (parameters.parameterScope));
    this.Serializable$set(
        'isWebhookEnabled',
        (parameters.isWebhookEnabled == null) ? (null) :
                                                (parameters.isWebhookEnabled));
    this.Serializable$set(
        'enableSentimentAnalysis',
        (parameters.enableSentimentAnalysis == null) ?
            (null) :
            (parameters.enableSentimentAnalysis));
  }

  get enableSentimentAnalysis(): boolean|null {
    return (
        (this.Serializable$has('enableSentimentAnalysis')) ?
            (this.Serializable$get('enableSentimentAnalysis')) :
            (null));
  }

  /**
   * Whether sentiment analysis is enabled.
   */
  set enableSentimentAnalysis(value: boolean|null) {
    this.Serializable$set('enableSentimentAnalysis', value);
  }

  get injectedParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('injectedParameters')) ?
            (this.Serializable$get('injectedParameters')) :
            (null));
  }

  /**
   * Parameters that need to be injected into the conversation during intent
   * detection.
   */
  set injectedParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('injectedParameters', value);
  }

  get input(): GoogleCloudDialogflowV3alpha1QueryInput|null {
    return (
        (this.Serializable$has('input')) ? (this.Serializable$get('input')) :
                                           (null));
  }

  /**
   * Supports text input, event input, dtmf input in the test case.
   */
  set input(value: GoogleCloudDialogflowV3alpha1QueryInput|null) {
    this.Serializable$set('input', value);
  }

  get isWebhookEnabled(): boolean|null {
    return (
        (this.Serializable$has('isWebhookEnabled')) ?
            (this.Serializable$get('isWebhookEnabled')) :
            (null));
  }

  /**
   * If webhooks should be allowed to trigger in response to the user utterance.
   * Often if parameters are injected, webhooks should not be enabled.
   */
  set isWebhookEnabled(value: boolean|null) {
    this.Serializable$set('isWebhookEnabled', value);
  }

  get parameterScope(): string|null {
    return (
        (this.Serializable$has('parameterScope')) ?
            (this.Serializable$get('parameterScope')) :
            (null));
  }

  /**
   * Scope for the parameters. If not specified, parameters will be treated as
   * session parameters. It is a customized free form text, e.g. 'event',
   * 'test'.
   */
  set parameterScope(value: string|null) {
    this.Serializable$set('parameterScope', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ConversationTurnUserInput> {
    return GoogleCloudDialogflowV3alpha1ConversationTurnUserInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'enableSentimentAnalysis', 'injectedParameters', 'input',
        'isWebhookEnabled', 'parameterScope'
      ],
      objectMaps: {
        'injectedParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'input': GoogleCloudDialogflowV3alpha1QueryInput}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ConversationTurnVirtualAgentOutputParameters {
  sessionParameters?: ApiClientObjectMap<any>|null;
  differences?: Array<GoogleCloudDialogflowV3alpha1TestRunDifference>|null;
  diagnosticInfo?: ApiClientObjectMap<any>|null;
  triggeredIntent?: GoogleCloudDialogflowV3alpha1Intent|null;
  currentPage?: GoogleCloudDialogflowV3alpha1Page|null;
  textResponses?: Array<GoogleCloudDialogflowV3alpha1ResponseMessageText>|null;
  status?: GoogleRpcStatus|null;
}
export class
    GoogleCloudDialogflowV3alpha1ConversationTurnVirtualAgentOutput extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ConversationTurnVirtualAgentOutputParameters =
              {}) {
    super();
    this.Serializable$set(
        'sessionParameters',
        (parameters.sessionParameters == null) ?
            (null) :
            (parameters.sessionParameters));
    this.Serializable$set(
        'differences',
        (parameters.differences == null) ? (null) : (parameters.differences));
    this.Serializable$set(
        'diagnosticInfo',
        (parameters.diagnosticInfo == null) ? (null) :
                                              (parameters.diagnosticInfo));
    this.Serializable$set(
        'triggeredIntent',
        (parameters.triggeredIntent == null) ? (null) :
                                               (parameters.triggeredIntent));
    this.Serializable$set(
        'currentPage',
        (parameters.currentPage == null) ? (null) : (parameters.currentPage));
    this.Serializable$set(
        'textResponses',
        (parameters.textResponses == null) ? (null) :
                                             (parameters.textResponses));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
  }

  get currentPage(): GoogleCloudDialogflowV3alpha1Page|null {
    return (
        (this.Serializable$has('currentPage')) ?
            (this.Serializable$get('currentPage')) :
            (null));
  }

  /**
   * The Page on which the utterance was spoken. Only name and displayName will
   * be set.
   */
  set currentPage(value: GoogleCloudDialogflowV3alpha1Page|null) {
    this.Serializable$set('currentPage', value);
  }

  get diagnosticInfo(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('diagnosticInfo')) ?
            (this.Serializable$get('diagnosticInfo')) :
            (null));
  }

  /**
   * Required. Input only. The diagnostic info outputted for the turn. Required
   * to calculate the testing coverage.
   */
  set diagnosticInfo(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('diagnosticInfo', value);
  }

  get differences():
      Array<GoogleCloudDialogflowV3alpha1TestRunDifference>|null {
    return (
        (this.Serializable$has('differences')) ?
            (this.Serializable$get('differences')) :
            (null));
  }

  /**
   * Output only. If this is part of a replayed conversation turn, the list of
   * differences between the original run and the replay for this output, if
   * any.
   */
  set differences(value: Array<GoogleCloudDialogflowV3alpha1TestRunDifference>|
                  null) {
    this.Serializable$set('differences', value);
  }

  get sessionParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('sessionParameters')) ?
            (this.Serializable$get('sessionParameters')) :
            (null));
  }

  /**
   * The session parameters available to the bot at this point.
   */
  set sessionParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('sessionParameters', value);
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * Response error from the agent in the test result. If set, other output is
   * empty.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  get textResponses():
      Array<GoogleCloudDialogflowV3alpha1ResponseMessageText>|null {
    return (
        (this.Serializable$has('textResponses')) ?
            (this.Serializable$get('textResponses')) :
            (null));
  }

  /**
   * The text responses from the agent for the turn.
   */
  set textResponses(value:
                        Array<GoogleCloudDialogflowV3alpha1ResponseMessageText>|
                    null) {
    this.Serializable$set('textResponses', value);
  }

  get triggeredIntent(): GoogleCloudDialogflowV3alpha1Intent|null {
    return (
        (this.Serializable$has('triggeredIntent')) ?
            (this.Serializable$get('triggeredIntent')) :
            (null));
  }

  /**
   * The Intent that triggered the response. Only name and displayName will be
   * set.
   */
  set triggeredIntent(value: GoogleCloudDialogflowV3alpha1Intent|null) {
    this.Serializable$set('triggeredIntent', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ConversationTurnVirtualAgentOutput> {
    return GoogleCloudDialogflowV3alpha1ConversationTurnVirtualAgentOutput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'differences': GoogleCloudDialogflowV3alpha1TestRunDifference,
        'textResponses': GoogleCloudDialogflowV3alpha1ResponseMessageText
      },
      keys: [
        'currentPage', 'diagnosticInfo', 'differences', 'sessionParameters',
        'status', 'textResponses', 'triggeredIntent'
      ],
      objectMaps: {
        'diagnosticInfo': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'sessionParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'currentPage': GoogleCloudDialogflowV3alpha1Page,
        'status': GoogleRpcStatus,
        'triggeredIntent': GoogleCloudDialogflowV3alpha1Intent
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1CreateDocumentOperationMetadataParameters {
  genericMetadata?:
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|null;
}
export class
    GoogleCloudDialogflowV3alpha1CreateDocumentOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1CreateDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|
      null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1CreateDocumentOperationMetadata> {
    return GoogleCloudDialogflowV3alpha1CreateDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1CreateLocationSettingsMetadataParameters {
}
export class GoogleCloudDialogflowV3alpha1CreateLocationSettingsMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1CreateLocationSettingsMetadataParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1CreateLocationSettingsMetadata> {
    return GoogleCloudDialogflowV3alpha1CreateLocationSettingsMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1CreateVersionOperationMetadataParameters {
  version?: string|null;
}
export class GoogleCloudDialogflowV3alpha1CreateVersionOperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1CreateVersionOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
  }

  get version(): string|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Name of the created version. Format:
   * `projects//locations//agents//flows//versions/`.
   */
  set version(value: string|null) {
    this.Serializable$set('version', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1CreateVersionOperationMetadata> {
    return GoogleCloudDialogflowV3alpha1CreateVersionOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['version']};
  }
}

export interface GoogleCloudDialogflowV3alpha1DeleteDocumentOperationMetadataParameters {
  genericMetadata?:
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|null;
}
export class
    GoogleCloudDialogflowV3alpha1DeleteDocumentOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1DeleteDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|
      null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1DeleteDocumentOperationMetadata> {
    return GoogleCloudDialogflowV3alpha1DeleteDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1DeployFlowMetadataParameters {
  testErrors?: Array<GoogleCloudDialogflowV3alpha1TestError>|null;
}
export class GoogleCloudDialogflowV3alpha1DeployFlowMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1DeployFlowMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'testErrors',
        (parameters.testErrors == null) ? (null) : (parameters.testErrors));
  }

  get testErrors(): Array<GoogleCloudDialogflowV3alpha1TestError>|null {
    return (
        (this.Serializable$has('testErrors')) ?
            (this.Serializable$get('testErrors')) :
            (null));
  }

  /**
   * Errors of running deployment tests.
   */
  set testErrors(value: Array<GoogleCloudDialogflowV3alpha1TestError>|null) {
    this.Serializable$set('testErrors', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1DeployFlowMetadata> {
    return GoogleCloudDialogflowV3alpha1DeployFlowMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'testErrors': GoogleCloudDialogflowV3alpha1TestError},
      keys: ['testErrors']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1DeployFlowResponseParameters {
  environment?: GoogleCloudDialogflowV3alpha1Environment|null;
  deployment?: string|null;
}
export class GoogleCloudDialogflowV3alpha1DeployFlowResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1DeployFlowResponseParameters = {}) {
    super();
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
    this.Serializable$set(
        'deployment',
        (parameters.deployment == null) ? (null) : (parameters.deployment));
  }

  get deployment(): string|null {
    return (
        (this.Serializable$has('deployment')) ?
            (this.Serializable$get('deployment')) :
            (null));
  }

  /**
   * The name of the flow version deployment. Format:
   * `projects//locations//agents// environments//deployments/`.
   */
  set deployment(value: string|null) {
    this.Serializable$set('deployment', value);
  }

  get environment(): GoogleCloudDialogflowV3alpha1Environment|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * The updated environment where the flow is deployed.
   */
  set environment(value: GoogleCloudDialogflowV3alpha1Environment|null) {
    this.Serializable$set('environment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1DeployFlowResponse> {
    return GoogleCloudDialogflowV3alpha1DeployFlowResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['deployment', 'environment'],
      objects: {'environment': GoogleCloudDialogflowV3alpha1Environment}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1DtmfInputParameters {
  digits?: string|null;
  finishDigit?: string|null;
  transformed?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1DtmfInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1DtmfInputParameters = {}) {
    super();
    this.Serializable$set(
        'digits', (parameters.digits == null) ? (null) : (parameters.digits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
    this.Serializable$set(
        'transformed',
        (parameters.transformed == null) ? (null) : (parameters.transformed));
  }

  get digits(): string|null {
    return (
        (this.Serializable$has('digits')) ? (this.Serializable$get('digits')) :
                                            (null));
  }

  /**
   * The dtmf digits.
   */
  set digits(value: string|null) {
    this.Serializable$set('digits', value);
  }

  get finishDigit(): string|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * The finish digit (if any).
   */
  set finishDigit(value: string|null) {
    this.Serializable$set('finishDigit', value);
  }

  get transformed(): boolean|null {
    return (
        (this.Serializable$has('transformed')) ?
            (this.Serializable$get('transformed')) :
            (null));
  }

  /**
   * Whether the DTMF digits has already been transformed into the pattern of
   * the entity type associated with the parameter to fill.
   */
  set transformed(value: boolean|null) {
    this.Serializable$set('transformed', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1DtmfInput> {
    return GoogleCloudDialogflowV3alpha1DtmfInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['digits', 'finishDigit', 'transformed']};
  }
}

export interface GoogleCloudDialogflowV3alpha1EntityConflictDiffParameters {
  displayName?: string|null;
  originalEntityId?: string|null;
  importedEntityId?: string|null;
}
export class GoogleCloudDialogflowV3alpha1EntityConflictDiff extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1EntityConflictDiffParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'originalEntityId',
        (parameters.originalEntityId == null) ? (null) :
                                                (parameters.originalEntityId));
    this.Serializable$set(
        'importedEntityId',
        (parameters.importedEntityId == null) ? (null) :
                                                (parameters.importedEntityId));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Conflicting display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get importedEntityId(): string|null {
    return (
        (this.Serializable$has('importedEntityId')) ?
            (this.Serializable$get('importedEntityId')) :
            (null));
  }

  /**
   * The name of the conflicting entity type in the content to be imported. This
   * is a unique identifier. Format:
   * `projects//locations//agents//entityTypes/`.
   */
  set importedEntityId(value: string|null) {
    this.Serializable$set('importedEntityId', value);
  }

  get originalEntityId(): string|null {
    return (
        (this.Serializable$has('originalEntityId')) ?
            (this.Serializable$get('originalEntityId')) :
            (null));
  }

  /**
   * The name of the conflicting entity type in the current agent. This is a
   * unique identifier. Format: `projects//locations//agents//entityTypes/`.
   */
  set originalEntityId(value: string|null) {
    this.Serializable$set('originalEntityId', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1EntityConflictDiff> {
    return GoogleCloudDialogflowV3alpha1EntityConflictDiff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'importedEntityId', 'originalEntityId']};
  }
}

export interface GoogleCloudDialogflowV3alpha1EnvironmentParameters {
  name?: string|null;
  displayName?: string|null;
  description?: string|null;
  versionTrafficAllocation?: ApiClientObjectMap<number>|null;
  versionConfigs?: Array<GoogleCloudDialogflowV3alpha1EnvironmentVersionConfig>|
      null;
  updateTime?: string|null;
  testCasesConfig?: GoogleCloudDialogflowV3alpha1EnvironmentTestCasesConfig|
      null;
  deploymentConfigs?:
      Array<GoogleCloudDialogflowV3alpha1EnvironmentDeploymentConfig>|null;
  etag?: string|null;
  webhookConfig?: GoogleCloudDialogflowV3alpha1EnvironmentWebhookConfig|null;
}
export class GoogleCloudDialogflowV3alpha1Environment extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1EnvironmentParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'versionTrafficAllocation',
        (parameters.versionTrafficAllocation == null) ?
            (null) :
            (parameters.versionTrafficAllocation));
    this.Serializable$set(
        'versionConfigs',
        (parameters.versionConfigs == null) ? (null) :
                                              (parameters.versionConfigs));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'testCasesConfig',
        (parameters.testCasesConfig == null) ? (null) :
                                               (parameters.testCasesConfig));
    this.Serializable$set(
        'deploymentConfigs',
        (parameters.deploymentConfigs == null) ?
            (null) :
            (parameters.deploymentConfigs));
    this.Serializable$set(
        'etag', (parameters.etag == null) ? (null) : (parameters.etag));
    this.Serializable$set(
        'webhookConfig',
        (parameters.webhookConfig == null) ? (null) :
                                             (parameters.webhookConfig));
  }

  get deploymentConfigs():
      Array<GoogleCloudDialogflowV3alpha1EnvironmentDeploymentConfig>|null {
    return (
        (this.Serializable$has('deploymentConfigs')) ?
            (this.Serializable$get('deploymentConfigs')) :
            (null));
  }

  /**
   * The deployment configurations for the flows in this environment.
   */
  set deploymentConfigs(
      value: Array<GoogleCloudDialogflowV3alpha1EnvironmentDeploymentConfig>|
      null) {
    this.Serializable$set('deploymentConfigs', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * The human-readable description of the environment. The maximum length is
   * 500 characters. If exceeded, the request is rejected.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the environment (unique in an agent).
   * Limit of 64 characters.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get etag(): string|null {
    return (
        (this.Serializable$has('etag')) ? (this.Serializable$get('etag')) :
                                          (null));
  }

  /**
   * Etag of the environment, may be sent on update requests to ensure the
   * client has an up-to-date value before proceeding.
   */
  set etag(value: string|null) {
    this.Serializable$set('etag', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The name of the environment. Format:
   * `projects//locations//agents//environments/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get testCasesConfig(): GoogleCloudDialogflowV3alpha1EnvironmentTestCasesConfig
      |null {
    return (
        (this.Serializable$has('testCasesConfig')) ?
            (this.Serializable$get('testCasesConfig')) :
            (null));
  }

  /**
   * The test cases config for continuous tests of this environment.
   */
  set testCasesConfig(
      value: GoogleCloudDialogflowV3alpha1EnvironmentTestCasesConfig|null) {
    this.Serializable$set('testCasesConfig', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. Update time of this environment.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  get versionConfigs():
      Array<GoogleCloudDialogflowV3alpha1EnvironmentVersionConfig>|null {
    return (
        (this.Serializable$has('versionConfigs')) ?
            (this.Serializable$get('versionConfigs')) :
            (null));
  }

  /**
   * Required. A list of configurations for flow versions. You should include
   * version configs for all flows that are reachable from `Start Flow` in the
   * agent. Otherwise, an error will be returned.
   */
  set versionConfigs(
      value: Array<GoogleCloudDialogflowV3alpha1EnvironmentVersionConfig>|
      null) {
    this.Serializable$set('versionConfigs', value);
  }

  get versionTrafficAllocation(): ApiClientObjectMap<number>|null {
    return (
        (this.Serializable$has('versionTrafficAllocation')) ?
            (this.Serializable$get('versionTrafficAllocation')) :
            (null));
  }

  /**
   * A map to represent the traffic allocation for each version in this
   * environment. The key of the map is the resource name of the version, in the
   * format of `projects//locations//agents//flows//versions/`. The value of the
   * map is the traffic allocation ratio for this version (from 0.0 to 1.0). For
   * every flow in an agent, the total traffic allocation should add up to 1.0.
   */
  set versionTrafficAllocation(value: ApiClientObjectMap<number>|null) {
    this.Serializable$set('versionTrafficAllocation', value);
  }

  get webhookConfig(): GoogleCloudDialogflowV3alpha1EnvironmentWebhookConfig
      |null {
    return (
        (this.Serializable$has('webhookConfig')) ?
            (this.Serializable$get('webhookConfig')) :
            (null));
  }

  /**
   * The webhook configuration for this environment.
   */
  set webhookConfig(value:
                        GoogleCloudDialogflowV3alpha1EnvironmentWebhookConfig|
                    null) {
    this.Serializable$set('webhookConfig', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1Environment> {
    return GoogleCloudDialogflowV3alpha1Environment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'deploymentConfigs':
            GoogleCloudDialogflowV3alpha1EnvironmentDeploymentConfig,
        'versionConfigs': GoogleCloudDialogflowV3alpha1EnvironmentVersionConfig
      },
      keys: [
        'deploymentConfigs', 'description', 'displayName', 'etag', 'name',
        'testCasesConfig', 'updateTime', 'versionConfigs',
        'versionTrafficAllocation', 'webhookConfig'
      ],
      objectMaps: {
        'versionTrafficAllocation': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'testCasesConfig':
            GoogleCloudDialogflowV3alpha1EnvironmentTestCasesConfig,
        'webhookConfig': GoogleCloudDialogflowV3alpha1EnvironmentWebhookConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1EnvironmentDeploymentConfigParameters {
  flow?: string|null;
  deploymentTests?: Array<string>|null;
  updateTime?: string|null;
}
export class GoogleCloudDialogflowV3alpha1EnvironmentDeploymentConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1EnvironmentDeploymentConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
    this.Serializable$set(
        'deploymentTests',
        (parameters.deploymentTests == null) ? (null) :
                                               (parameters.deploymentTests));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
  }

  get deploymentTests(): Array<string>|null {
    return (
        (this.Serializable$has('deploymentTests')) ?
            (this.Serializable$get('deploymentTests')) :
            (null));
  }

  /**
   * A list of test cases to run before deployment. The test cases should belong
   * to the same agent as the environment.
   */
  set deploymentTests(value: Array<string>|null) {
    this.Serializable$set('deploymentTests', value);
  }

  get flow(): string|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * The name of flow to deploy. Format: `projects//locations//agents//flows/`.
   */
  set flow(value: string|null) {
    this.Serializable$set('flow', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. The last update time of deployment configuration.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1EnvironmentDeploymentConfig> {
    return GoogleCloudDialogflowV3alpha1EnvironmentDeploymentConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['deploymentTests', 'flow', 'updateTime']};
  }
}

export interface GoogleCloudDialogflowV3alpha1EnvironmentTestCasesConfigParameters {
  testCases?: Array<string>|null;
  enableContinuousRun?: boolean|null;
  enablePredeploymentRun?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1EnvironmentTestCasesConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1EnvironmentTestCasesConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'testCases',
        (parameters.testCases == null) ? (null) : (parameters.testCases));
    this.Serializable$set(
        'enableContinuousRun',
        (parameters.enableContinuousRun == null) ?
            (null) :
            (parameters.enableContinuousRun));
    this.Serializable$set(
        'enablePredeploymentRun',
        (parameters.enablePredeploymentRun == null) ?
            (null) :
            (parameters.enablePredeploymentRun));
  }

  get enableContinuousRun(): boolean|null {
    return (
        (this.Serializable$has('enableContinuousRun')) ?
            (this.Serializable$get('enableContinuousRun')) :
            (null));
  }

  /**
   * Whether to run test cases in TestCasesConfig.test_cases periodically.
   * Default false. If set to true, run once a day.
   */
  set enableContinuousRun(value: boolean|null) {
    this.Serializable$set('enableContinuousRun', value);
  }

  get enablePredeploymentRun(): boolean|null {
    return (
        (this.Serializable$has('enablePredeploymentRun')) ?
            (this.Serializable$get('enablePredeploymentRun')) :
            (null));
  }

  /**
   * Whether to run test cases in TestCasesConfig.test_cases before deploying a
   * flow version to the environment. Default false.
   */
  set enablePredeploymentRun(value: boolean|null) {
    this.Serializable$set('enablePredeploymentRun', value);
  }

  get testCases(): Array<string>|null {
    return (
        (this.Serializable$has('testCases')) ?
            (this.Serializable$get('testCases')) :
            (null));
  }

  /**
   * A list of test case names to run. They should be under the same agent.
   * Format of each test case name: `projects//locations/ /agents//testCases/`
   */
  set testCases(value: Array<string>|null) {
    this.Serializable$set('testCases', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1EnvironmentTestCasesConfig> {
    return GoogleCloudDialogflowV3alpha1EnvironmentTestCasesConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['enableContinuousRun', 'enablePredeploymentRun', 'testCases']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigParameters {
  version?: string|null;
  trafficAllocation?: number|null;
  staleFlowInfo?:
      GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigStaleFlowInfo|null;
}
export class GoogleCloudDialogflowV3alpha1EnvironmentVersionConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
    this.Serializable$set(
        'trafficAllocation',
        (parameters.trafficAllocation == null) ?
            (null) :
            (parameters.trafficAllocation));
    this.Serializable$set(
        'staleFlowInfo',
        (parameters.staleFlowInfo == null) ? (null) :
                                             (parameters.staleFlowInfo));
  }

  get staleFlowInfo():
      GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigStaleFlowInfo|null {
    return (
        (this.Serializable$has('staleFlowInfo')) ?
            (this.Serializable$get('staleFlowInfo')) :
            (null));
  }

  /**
   * Only exists if this version belongs to a \"stale\" flow.
   */
  set staleFlowInfo(
      value: GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigStaleFlowInfo|
      null) {
    this.Serializable$set('staleFlowInfo', value);
  }

  get trafficAllocation(): number|null {
    return (
        (this.Serializable$has('trafficAllocation')) ?
            (this.Serializable$get('trafficAllocation')) :
            (null));
  }

  /**
   * Percentage of the traffic which should be routed to the particular version
   * of the flow. Default to 1.0 if not specified, which means 100% of the
   * traffic routes to the flow will use this version. Note: INVALID_ARGUMENT
   * error will be thrown if traffic allocation for a single flow doesn't sum up
   * to 1.0.
   */
  set trafficAllocation(value: number|null) {
    this.Serializable$set('trafficAllocation', value);
  }

  get version(): string|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Required. Format: projects//locations//agents//flows//versions/.
   */
  set version(value: string|null) {
    this.Serializable$set('version', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1EnvironmentVersionConfig> {
    return GoogleCloudDialogflowV3alpha1EnvironmentVersionConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['staleFlowInfo', 'trafficAllocation', 'version'],
      objects: {
        'staleFlowInfo':
            GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigStaleFlowInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigStaleFlowInfoParameters {
  flowDisplayName?: string|null;
  versionDisplayName?: string|null;
}
export class
    GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigStaleFlowInfo extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigStaleFlowInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'flowDisplayName',
        (parameters.flowDisplayName == null) ? (null) :
                                               (parameters.flowDisplayName));
    this.Serializable$set(
        'versionDisplayName',
        (parameters.versionDisplayName == null) ?
            (null) :
            (parameters.versionDisplayName));
  }

  get flowDisplayName(): string|null {
    return (
        (this.Serializable$has('flowDisplayName')) ?
            (this.Serializable$get('flowDisplayName')) :
            (null));
  }

  /**
   * The display name of the flow.
   */
  set flowDisplayName(value: string|null) {
    this.Serializable$set('flowDisplayName', value);
  }

  get versionDisplayName(): string|null {
    return (
        (this.Serializable$has('versionDisplayName')) ?
            (this.Serializable$get('versionDisplayName')) :
            (null));
  }

  /**
   * The display name of the referenced flow version in the environment.
   */
  set versionDisplayName(value: string|null) {
    this.Serializable$set('versionDisplayName', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigStaleFlowInfo> {
    return GoogleCloudDialogflowV3alpha1EnvironmentVersionConfigStaleFlowInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flowDisplayName', 'versionDisplayName']};
  }
}

export interface GoogleCloudDialogflowV3alpha1EnvironmentWebhookConfigParameters {
  webhookOverrides?: Array<GoogleCloudDialogflowV3alpha1Webhook>|null;
}
export class GoogleCloudDialogflowV3alpha1EnvironmentWebhookConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1EnvironmentWebhookConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'webhookOverrides',
        (parameters.webhookOverrides == null) ? (null) :
                                                (parameters.webhookOverrides));
  }

  get webhookOverrides(): Array<GoogleCloudDialogflowV3alpha1Webhook>|null {
    return (
        (this.Serializable$has('webhookOverrides')) ?
            (this.Serializable$get('webhookOverrides')) :
            (null));
  }

  /**
   * The list of webhooks to override for the agent environment. The webhook
   * must exist in the agent. You can override fields in `generic_web_service`
   * and `service_directory`.
   */
  set webhookOverrides(value: Array<GoogleCloudDialogflowV3alpha1Webhook>|
                       null) {
    this.Serializable$set('webhookOverrides', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1EnvironmentWebhookConfig> {
    return GoogleCloudDialogflowV3alpha1EnvironmentWebhookConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'webhookOverrides': GoogleCloudDialogflowV3alpha1Webhook},
      keys: ['webhookOverrides']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1EventHandlerParameters {
  name?: string|null;
  event?: string|null;
  condition?: string|null;
  triggerFulfillment?: GoogleCloudDialogflowV3alpha1Fulfillment|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowV3alpha1EventHandler extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1EventHandlerParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'triggerFulfillment',
        (parameters.triggerFulfillment == null) ?
            (null) :
            (parameters.triggerFulfillment));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition to evaluate against form parameters, session parameters or
   * the `error` object. See the [conditions
   * reference](https://cloud.google.com/dialogflow/priv/docs/v3/condition).
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get event(): string|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * Required. The name of the event to handle.
   */
  set event(value: string|null) {
    this.Serializable$set('event', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this event handler.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  get triggerFulfillment(): GoogleCloudDialogflowV3alpha1Fulfillment|null {
    return (
        (this.Serializable$has('triggerFulfillment')) ?
            (this.Serializable$get('triggerFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to call when the event occurs. Handling webhook errors with
   * a fulfillment enabled with webhook could cause infinite loop. It is invalid
   * to specify such fulfillment for a handler handling webhooks.
   */
  set triggerFulfillment(value: GoogleCloudDialogflowV3alpha1Fulfillment|null) {
    this.Serializable$set('triggerFulfillment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1EventHandler> {
    return GoogleCloudDialogflowV3alpha1EventHandler;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'condition', 'event', 'name', 'targetFlow', 'targetPage',
        'triggerFulfillment'
      ],
      objects: {'triggerFulfillment': GoogleCloudDialogflowV3alpha1Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1EventInputParameters {
  event?: string|null;
}
export class GoogleCloudDialogflowV3alpha1EventInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1EventInputParameters = {}) {
    super();
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
  }

  get event(): string|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * Name of the event.
   */
  set event(value: string|null) {
    this.Serializable$set('event', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1EventInput> {
    return GoogleCloudDialogflowV3alpha1EventInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['event']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ExportAgentResponseParameters {
  agentUri?: string|null;
  agentContent?: string|null;
  commitSha?: string|null;
}
export class GoogleCloudDialogflowV3alpha1ExportAgentResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ExportAgentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'agentUri',
        (parameters.agentUri == null) ? (null) : (parameters.agentUri));
    this.Serializable$set(
        'agentContent',
        (parameters.agentContent == null) ? (null) : (parameters.agentContent));
    this.Serializable$set(
        'commitSha',
        (parameters.commitSha == null) ? (null) : (parameters.commitSha));
  }

  get agentContent(): string|null {
    return (
        (this.Serializable$has('agentContent')) ?
            (this.Serializable$get('agentContent')) :
            (null));
  }

  /**
   * Uncompressed raw byte content for agent.
   */
  set agentContent(value: string|null) {
    this.Serializable$set('agentContent', value);
  }

  get agentUri(): string|null {
    return (
        (this.Serializable$has('agentUri')) ?
            (this.Serializable$get('agentUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported agent. This field is populated
   * only if `agent_uri` is specified in ExportAgentRequest.
   */
  set agentUri(value: string|null) {
    this.Serializable$set('agentUri', value);
  }

  get commitSha(): string|null {
    return (
        (this.Serializable$has('commitSha')) ?
            (this.Serializable$get('commitSha')) :
            (null));
  }

  /**
   * Commit SHA of the git commit
   */
  set commitSha(value: string|null) {
    this.Serializable$set('commitSha', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ExportAgentResponse> {
    return GoogleCloudDialogflowV3alpha1ExportAgentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agentContent', 'agentUri', 'commitSha']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ExportCobwebDatasetMetadataParameters {
  cobwebDataset?: string|null;
  bigQueryDestination?: GoogleCloudDialogflowV3alpha1BigQueryDestination|null;
}
export class GoogleCloudDialogflowV3alpha1ExportCobwebDatasetMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ExportCobwebDatasetMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'cobwebDataset',
        (parameters.cobwebDataset == null) ? (null) :
                                             (parameters.cobwebDataset));
    this.Serializable$set(
        'bigQueryDestination',
        (parameters.bigQueryDestination == null) ?
            (null) :
            (parameters.bigQueryDestination));
  }

  get bigQueryDestination(): GoogleCloudDialogflowV3alpha1BigQueryDestination
      |null {
    return (
        (this.Serializable$has('bigQueryDestination')) ?
            (this.Serializable$get('bigQueryDestination')) :
            (null));
  }

  /**
   * The BigQuery destination of the exported Cobweb dataset.
   */
  set bigQueryDestination(value:
                              GoogleCloudDialogflowV3alpha1BigQueryDestination|
                          null) {
    this.Serializable$set('bigQueryDestination', value);
  }

  get cobwebDataset(): string|null {
    return (
        (this.Serializable$has('cobwebDataset')) ?
            (this.Serializable$get('cobwebDataset')) :
            (null));
  }

  /**
   * The name of the exported Cobweb dataset. Format:
   * `projects//locations//cobwebDatasets/`.
   */
  set cobwebDataset(value: string|null) {
    this.Serializable$set('cobwebDataset', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ExportCobwebDatasetMetadata> {
    return GoogleCloudDialogflowV3alpha1ExportCobwebDatasetMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['bigQueryDestination', 'cobwebDataset'],
      objects: {
        'bigQueryDestination': GoogleCloudDialogflowV3alpha1BigQueryDestination
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ExportCobwebDatasetResponseParameters {
}
export class GoogleCloudDialogflowV3alpha1ExportCobwebDatasetResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ExportCobwebDatasetResponseParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ExportCobwebDatasetResponse> {
    return GoogleCloudDialogflowV3alpha1ExportCobwebDatasetResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1ExportConversationGraphResponseParameters {
  gcsDestination?: GoogleCloudDialogflowV3alpha1GcsDestination|null;
  conversationGraphData?: string|null;
}
export class
    GoogleCloudDialogflowV3alpha1ExportConversationGraphResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ExportConversationGraphResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'gcsDestination',
        (parameters.gcsDestination == null) ? (null) :
                                              (parameters.gcsDestination));
    this.Serializable$set(
        'conversationGraphData',
        (parameters.conversationGraphData == null) ?
            (null) :
            (parameters.conversationGraphData));
  }

  get conversationGraphData(): string|null {
    return (
        (this.Serializable$has('conversationGraphData')) ?
            (this.Serializable$get('conversationGraphData')) :
            (null));
  }

  /**
   * Uncompressed raw byte content for conversation graph.
   */
  set conversationGraphData(value: string|null) {
    this.Serializable$set('conversationGraphData', value);
  }

  get gcsDestination(): GoogleCloudDialogflowV3alpha1GcsDestination|null {
    return (
        (this.Serializable$has('gcsDestination')) ?
            (this.Serializable$get('gcsDestination')) :
            (null));
  }

  /**
   * The Cloud Storage path used to export the file. This field is populated
   * only if `gcs_destination` is specified in ExportConversationGraphRequest.
   */
  set gcsDestination(value: GoogleCloudDialogflowV3alpha1GcsDestination|null) {
    this.Serializable$set('gcsDestination', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ExportConversationGraphResponse> {
    return GoogleCloudDialogflowV3alpha1ExportConversationGraphResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['conversationGraphData', 'gcsDestination'],
      objects: {'gcsDestination': GoogleCloudDialogflowV3alpha1GcsDestination}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ExportFlowResponseParameters {
  flowUri?: string|null;
  flowContent?: string|null;
}
export class GoogleCloudDialogflowV3alpha1ExportFlowResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ExportFlowResponseParameters = {}) {
    super();
    this.Serializable$set(
        'flowUri',
        (parameters.flowUri == null) ? (null) : (parameters.flowUri));
    this.Serializable$set(
        'flowContent',
        (parameters.flowContent == null) ? (null) : (parameters.flowContent));
  }

  get flowContent(): string|null {
    return (
        (this.Serializable$has('flowContent')) ?
            (this.Serializable$get('flowContent')) :
            (null));
  }

  /**
   * Uncompressed raw byte content for flow.
   */
  set flowContent(value: string|null) {
    this.Serializable$set('flowContent', value);
  }

  get flowUri(): string|null {
    return (
        (this.Serializable$has('flowUri')) ?
            (this.Serializable$get('flowUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported flow. This field is populated
   * only if `flow_uri` is specified in ExportFlowRequest.
   */
  set flowUri(value: string|null) {
    this.Serializable$set('flowUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ExportFlowResponse> {
    return GoogleCloudDialogflowV3alpha1ExportFlowResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flowContent', 'flowUri']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ExportIntentsMetadataParameters {}
export class GoogleCloudDialogflowV3alpha1ExportIntentsMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ExportIntentsMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ExportIntentsMetadata> {
    return GoogleCloudDialogflowV3alpha1ExportIntentsMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1ExportIntentsResponseParameters {
  intentsUri?: string|null;
  intentsContent?: GoogleCloudDialogflowV3alpha1InlineDestination|null;
}
export class GoogleCloudDialogflowV3alpha1ExportIntentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ExportIntentsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'intentsUri',
        (parameters.intentsUri == null) ? (null) : (parameters.intentsUri));
    this.Serializable$set(
        'intentsContent',
        (parameters.intentsContent == null) ? (null) :
                                              (parameters.intentsContent));
  }

  get intentsContent(): GoogleCloudDialogflowV3alpha1InlineDestination|null {
    return (
        (this.Serializable$has('intentsContent')) ?
            (this.Serializable$get('intentsContent')) :
            (null));
  }

  /**
   * Uncompressed byte content for intents. This field is populated only if
   * `intents_content_inline` is set to true in ExportIntentsRequest.
   */
  set intentsContent(value: GoogleCloudDialogflowV3alpha1InlineDestination|
                     null) {
    this.Serializable$set('intentsContent', value);
  }

  get intentsUri(): string|null {
    return (
        (this.Serializable$has('intentsUri')) ?
            (this.Serializable$get('intentsUri')) :
            (null));
  }

  /**
   * The URI to a file containing the exported intents. This field is populated
   * only if `intents_uri` is specified in ExportIntentsRequest.
   */
  set intentsUri(value: string|null) {
    this.Serializable$set('intentsUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ExportIntentsResponse> {
    return GoogleCloudDialogflowV3alpha1ExportIntentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['intentsContent', 'intentsUri'],
      objects:
          {'intentsContent': GoogleCloudDialogflowV3alpha1InlineDestination}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ExportTestCasesMetadataParameters {
}
export class GoogleCloudDialogflowV3alpha1ExportTestCasesMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ExportTestCasesMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ExportTestCasesMetadata> {
    return GoogleCloudDialogflowV3alpha1ExportTestCasesMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1ExportTestCasesResponseParameters {
  gcsUri?: string|null;
  content?: string|null;
}
export class GoogleCloudDialogflowV3alpha1ExportTestCasesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ExportTestCasesResponseParameters = {}) {
    super();
    this.Serializable$set(
        'gcsUri', (parameters.gcsUri == null) ? (null) : (parameters.gcsUri));
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Uncompressed raw byte content for test cases.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get gcsUri(): string|null {
    return (
        (this.Serializable$has('gcsUri')) ? (this.Serializable$get('gcsUri')) :
                                            (null));
  }

  /**
   * The URI to a file containing the exported test cases. This field is
   * populated only if `gcs_uri` is specified in ExportTestCasesRequest.
   */
  set gcsUri(value: string|null) {
    this.Serializable$set('gcsUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ExportTestCasesResponse> {
    return GoogleCloudDialogflowV3alpha1ExportTestCasesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['content', 'gcsUri']};
  }
}

export interface GoogleCloudDialogflowV3alpha1FinalTranscriptParameters {
  alternatives?: Array<string>|null;
}
export class GoogleCloudDialogflowV3alpha1FinalTranscript extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1FinalTranscriptParameters = {}) {
    super();
    this.Serializable$set(
        'alternatives',
        (parameters.alternatives == null) ? (null) : (parameters.alternatives));
  }

  get alternatives(): Array<string>|null {
    return (
        (this.Serializable$has('alternatives')) ?
            (this.Serializable$get('alternatives')) :
            (null));
  }

  /**
   * One or more recognition hypotheses, up to the maximum specified in
   * `max_alternatives`, ordered by speech accuracy.
   */
  set alternatives(value: Array<string>|null) {
    this.Serializable$set('alternatives', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1FinalTranscript> {
    return GoogleCloudDialogflowV3alpha1FinalTranscript;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['alternatives']};
  }
}

export interface GoogleCloudDialogflowV3alpha1FlowConflictReportParameters {
  intentDiffs?: Array<GoogleCloudDialogflowV3alpha1IntentConflictDiff>|null;
  entityTypesDiffs?: Array<GoogleCloudDialogflowV3alpha1EntityConflictDiff>|
      null;
  webhookDiffs?: Array<GoogleCloudDialogflowV3alpha1WebhookConflictDiff>|null;
}
export class GoogleCloudDialogflowV3alpha1FlowConflictReport extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1FlowConflictReportParameters = {}) {
    super();
    this.Serializable$set(
        'intentDiffs',
        (parameters.intentDiffs == null) ? (null) : (parameters.intentDiffs));
    this.Serializable$set(
        'entityTypesDiffs',
        (parameters.entityTypesDiffs == null) ? (null) :
                                                (parameters.entityTypesDiffs));
    this.Serializable$set(
        'webhookDiffs',
        (parameters.webhookDiffs == null) ? (null) : (parameters.webhookDiffs));
  }

  get entityTypesDiffs():
      Array<GoogleCloudDialogflowV3alpha1EntityConflictDiff>|null {
    return (
        (this.Serializable$has('entityTypesDiffs')) ?
            (this.Serializable$get('entityTypesDiffs')) :
            (null));
  }

  /**
   * The diffs for Entity Types with display name conflicts.
   */
  set entityTypesDiffs(
      value: Array<GoogleCloudDialogflowV3alpha1EntityConflictDiff>|null) {
    this.Serializable$set('entityTypesDiffs', value);
  }

  get intentDiffs():
      Array<GoogleCloudDialogflowV3alpha1IntentConflictDiff>|null {
    return (
        (this.Serializable$has('intentDiffs')) ?
            (this.Serializable$get('intentDiffs')) :
            (null));
  }

  /**
   * The diffs for Intents with display name conflicts.
   */
  set intentDiffs(value: Array<GoogleCloudDialogflowV3alpha1IntentConflictDiff>|
                  null) {
    this.Serializable$set('intentDiffs', value);
  }

  get webhookDiffs():
      Array<GoogleCloudDialogflowV3alpha1WebhookConflictDiff>|null {
    return (
        (this.Serializable$has('webhookDiffs')) ?
            (this.Serializable$get('webhookDiffs')) :
            (null));
  }

  /**
   * The diffs for Webhooks with display name conflicts.
   */
  set webhookDiffs(value:
                       Array<GoogleCloudDialogflowV3alpha1WebhookConflictDiff>|
                   null) {
    this.Serializable$set('webhookDiffs', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1FlowConflictReport> {
    return GoogleCloudDialogflowV3alpha1FlowConflictReport;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'entityTypesDiffs': GoogleCloudDialogflowV3alpha1EntityConflictDiff,
        'intentDiffs': GoogleCloudDialogflowV3alpha1IntentConflictDiff,
        'webhookDiffs': GoogleCloudDialogflowV3alpha1WebhookConflictDiff
      },
      keys: ['entityTypesDiffs', 'intentDiffs', 'webhookDiffs']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1FormParameters {
  parameters?: Array<GoogleCloudDialogflowV3alpha1FormParameter>|null;
}
export class GoogleCloudDialogflowV3alpha1Form extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV3alpha1FormParameters = {}) {
    super();
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get parameters(): Array<GoogleCloudDialogflowV3alpha1FormParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Parameters to collect from the user.
   */
  set parameters(value: Array<GoogleCloudDialogflowV3alpha1FormParameter>|
                 null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1Form> {
    return GoogleCloudDialogflowV3alpha1Form;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parameters': GoogleCloudDialogflowV3alpha1FormParameter},
      keys: ['parameters']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1FormParameterParameters {
  displayName?: string|null;
  required?: boolean|null;
  entityType?: string|null;
  isList?: boolean|null;
  fillBehavior?: GoogleCloudDialogflowV3alpha1FormParameterFillBehavior|null;
  defaultValue?: any|null;
  redact?: boolean|null;
  advancedSettings?: GoogleCloudDialogflowV3alpha1AdvancedSettings|null;
}
export class GoogleCloudDialogflowV3alpha1FormParameter extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1FormParameterParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'required',
        (parameters.required == null) ? (null) : (parameters.required));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
    this.Serializable$set(
        'isList', (parameters.isList == null) ? (null) : (parameters.isList));
    this.Serializable$set(
        'fillBehavior',
        (parameters.fillBehavior == null) ? (null) : (parameters.fillBehavior));
    this.Serializable$set(
        'defaultValue',
        (parameters.defaultValue == null) ? (null) : (parameters.defaultValue));
    this.Serializable$set(
        'redact', (parameters.redact == null) ? (null) : (parameters.redact));
    this.Serializable$set(
        'advancedSettings',
        (parameters.advancedSettings == null) ? (null) :
                                                (parameters.advancedSettings));
  }

  get advancedSettings(): GoogleCloudDialogflowV3alpha1AdvancedSettings|null {
    return (
        (this.Serializable$has('advancedSettings')) ?
            (this.Serializable$get('advancedSettings')) :
            (null));
  }

  /**
   * Hierarchical advanced settings for this parameter. The settings exposed at
   * the lower level overrides the settings exposed at the higher level.
   */
  set advancedSettings(value: GoogleCloudDialogflowV3alpha1AdvancedSettings|
                       null) {
    this.Serializable$set('advancedSettings', value);
  }

  get defaultValue(): any|null {
    return (
        (this.Serializable$has('defaultValue')) ?
            (this.Serializable$get('defaultValue')) :
            (null));
  }

  /**
   * The default value of an optional parameter. If the parameter is required,
   * the default value will be ignored.
   */
  set defaultValue(value: any|null) {
    this.Serializable$set('defaultValue', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the parameter, unique within the form.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * Required. The entity type of the parameter. Format:
   * `projects/-/locations/-/agents/-/entityTypes/` for system entity types (for
   * example, `projects/-/locations/-/agents/-/entityTypes/sys.date`), or
   * `projects//locations//agents//entityTypes/` for developer entity types.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get fillBehavior(): GoogleCloudDialogflowV3alpha1FormParameterFillBehavior
      |null {
    return (
        (this.Serializable$has('fillBehavior')) ?
            (this.Serializable$get('fillBehavior')) :
            (null));
  }

  /**
   * Required. Defines fill behavior for the parameter.
   */
  set fillBehavior(value:
                       GoogleCloudDialogflowV3alpha1FormParameterFillBehavior|
                   null) {
    this.Serializable$set('fillBehavior', value);
  }

  get isList(): boolean|null {
    return (
        (this.Serializable$has('isList')) ? (this.Serializable$get('isList')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter represents a list of values.
   */
  set isList(value: boolean|null) {
    this.Serializable$set('isList', value);
  }

  get redact(): boolean|null {
    return (
        (this.Serializable$has('redact')) ? (this.Serializable$get('redact')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter content should be redacted in log. If
   * redaction is enabled, the parameter content will be replaced by parameter
   * name during logging. Note: the parameter content is subject to redaction if
   * either parameter level redaction or entity type level redaction is enabled.
   */
  set redact(value: boolean|null) {
    this.Serializable$set('redact', value);
  }

  get required(): boolean|null {
    return (
        (this.Serializable$has('required')) ?
            (this.Serializable$get('required')) :
            (null));
  }

  /**
   * Indicates whether the parameter is required. Optional parameters will not
   * trigger prompts; however, they are filled if the user specifies them.
   * Required parameters must be filled before form filling concludes.
   */
  set required(value: boolean|null) {
    this.Serializable$set('required', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1FormParameter> {
    return GoogleCloudDialogflowV3alpha1FormParameter;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'advancedSettings', 'defaultValue', 'displayName', 'entityType',
        'fillBehavior', 'isList', 'redact', 'required'
      ],
      objects: {
        'advancedSettings': GoogleCloudDialogflowV3alpha1AdvancedSettings,
        'fillBehavior': GoogleCloudDialogflowV3alpha1FormParameterFillBehavior
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1FormParameterFillBehaviorParameters {
  initialPromptFulfillment?: GoogleCloudDialogflowV3alpha1Fulfillment|null;
  repromptEventHandlers?: Array<GoogleCloudDialogflowV3alpha1EventHandler>|null;
}
export class GoogleCloudDialogflowV3alpha1FormParameterFillBehavior extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1FormParameterFillBehaviorParameters =
              {}) {
    super();
    this.Serializable$set(
        'initialPromptFulfillment',
        (parameters.initialPromptFulfillment == null) ?
            (null) :
            (parameters.initialPromptFulfillment));
    this.Serializable$set(
        'repromptEventHandlers',
        (parameters.repromptEventHandlers == null) ?
            (null) :
            (parameters.repromptEventHandlers));
  }

  get initialPromptFulfillment(): GoogleCloudDialogflowV3alpha1Fulfillment
      |null {
    return (
        (this.Serializable$has('initialPromptFulfillment')) ?
            (this.Serializable$get('initialPromptFulfillment')) :
            (null));
  }

  /**
   * Required. The fulfillment to provide the initial prompt that the agent can
   * present to the user in order to fill the parameter.
   */
  set initialPromptFulfillment(value: GoogleCloudDialogflowV3alpha1Fulfillment|
                               null) {
    this.Serializable$set('initialPromptFulfillment', value);
  }

  get repromptEventHandlers():
      Array<GoogleCloudDialogflowV3alpha1EventHandler>|null {
    return (
        (this.Serializable$has('repromptEventHandlers')) ?
            (this.Serializable$get('repromptEventHandlers')) :
            (null));
  }

  /**
   * The handlers for parameter-level events, used to provide reprompt for the
   * parameter or transition to a different page/flow. The supported events are:
   * * `sys.no-match-`, where N can be from 1 to 6 * `sys.no-match-default` *
   * `sys.no-input-`, where N can be from 1 to 6 * `sys.no-input-default` *
   * `sys.invalid-parameter` `initial_prompt_fulfillment` provides the first
   * prompt for the parameter. If the user's response does not fill the
   * parameter, a no-match/no-input event will be triggered, and the fulfillment
   * associated with the `sys.no-match-1`/`sys.no-input-1` handler (if defined)
   * will be called to provide a prompt. The `sys.no-match-2`/`sys.no-input-2`
   * handler (if defined) will respond to the next no-match/no-input event, and
   * so on. A `sys.no-match-default` or `sys.no-input-default` handler will be
   * used to handle all following no-match/no-input events after all numbered
   * no-match/no-input handlers for the parameter are consumed. A
   * `sys.invalid-parameter` handler can be defined to handle the case where the
   * parameter values have been `invalidated` by webhook. For example, if the
   * user's response fill the parameter, however the parameter was invalidated
   * by webhook, the fulfillment associated with the `sys.invalid-parameter`
   * handler (if defined) will be called to provide a prompt. If the event
   * handler for the corresponding event can't be found on the parameter,
   * `initial_prompt_fulfillment` will be re-prompted.
   */
  set repromptEventHandlers(
      value: Array<GoogleCloudDialogflowV3alpha1EventHandler>|null) {
    this.Serializable$set('repromptEventHandlers', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1FormParameterFillBehavior> {
    return GoogleCloudDialogflowV3alpha1FormParameterFillBehavior;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'repromptEventHandlers': GoogleCloudDialogflowV3alpha1EventHandler},
      keys: ['initialPromptFulfillment', 'repromptEventHandlers'],
      objects:
          {'initialPromptFulfillment': GoogleCloudDialogflowV3alpha1Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1FulfillmentParameters {
  messages?: Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|null;
  webhook?: string|null;
  returnPartialResponses?: boolean|null;
  tag?: string|null;
  setParameterActions?:
      Array<GoogleCloudDialogflowV3alpha1FulfillmentSetParameterAction>|null;
  conditionalCases?:
      Array<GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases>|null;
  playbackInterruptionSettings?:
      GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings|null;
  advancedSettings?: GoogleCloudDialogflowV3alpha1AdvancedSettings|null;
  webhookInputParameters?:
      Array<GoogleCloudDialogflowV3alpha1ParameterDeclaration>|null;
  webhookOutputParameters?:
      Array<GoogleCloudDialogflowV3alpha1ParameterDeclaration>|null;
  webhookSettings?: GoogleCloudDialogflowV3alpha1WebhookSettings|null;
}
export class GoogleCloudDialogflowV3alpha1Fulfillment extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1FulfillmentParameters = {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'webhook',
        (parameters.webhook == null) ? (null) : (parameters.webhook));
    this.Serializable$set(
        'returnPartialResponses',
        (parameters.returnPartialResponses == null) ?
            (null) :
            (parameters.returnPartialResponses));
    this.Serializable$set(
        'tag', (parameters.tag == null) ? (null) : (parameters.tag));
    this.Serializable$set(
        'setParameterActions',
        (parameters.setParameterActions == null) ?
            (null) :
            (parameters.setParameterActions));
    this.Serializable$set(
        'conditionalCases',
        (parameters.conditionalCases == null) ? (null) :
                                                (parameters.conditionalCases));
    this.Serializable$set(
        'playbackInterruptionSettings',
        (parameters.playbackInterruptionSettings == null) ?
            (null) :
            (parameters.playbackInterruptionSettings));
    this.Serializable$set(
        'advancedSettings',
        (parameters.advancedSettings == null) ? (null) :
                                                (parameters.advancedSettings));
    this.Serializable$set(
        'webhookInputParameters',
        (parameters.webhookInputParameters == null) ?
            (null) :
            (parameters.webhookInputParameters));
    this.Serializable$set(
        'webhookOutputParameters',
        (parameters.webhookOutputParameters == null) ?
            (null) :
            (parameters.webhookOutputParameters));
    this.Serializable$set(
        'webhookSettings',
        (parameters.webhookSettings == null) ? (null) :
                                               (parameters.webhookSettings));
  }

  get advancedSettings(): GoogleCloudDialogflowV3alpha1AdvancedSettings|null {
    return (
        (this.Serializable$has('advancedSettings')) ?
            (this.Serializable$get('advancedSettings')) :
            (null));
  }

  /**
   * Hierarchical advanced settings for this fulfillment. The settings exposed
   * at the lower level overrides the settings exposed at the higher level.
   */
  set advancedSettings(value: GoogleCloudDialogflowV3alpha1AdvancedSettings|
                       null) {
    this.Serializable$set('advancedSettings', value);
  }

  get conditionalCases():
      Array<GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases>|null {
    return (
        (this.Serializable$has('conditionalCases')) ?
            (this.Serializable$get('conditionalCases')) :
            (null));
  }

  /**
   * Conditional cases for this fulfillment.
   */
  set conditionalCases(
      value: Array<GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases>|
      null) {
    this.Serializable$set('conditionalCases', value);
  }

  get messages(): Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The list of rich message responses to present to the user.
   */
  set messages(value: Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|
               null) {
    this.Serializable$set('messages', value);
  }

  get playbackInterruptionSettings():
      GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings|null {
    return (
        (this.Serializable$has('playbackInterruptionSettings')) ?
            (this.Serializable$get('playbackInterruptionSettings')) :
            (null));
  }

  /**
   * Whether to allow interruption on the playback of a message. If empty, flow
   * level settings will be used.
   */
  set playbackInterruptionSettings(
      value: GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings|null) {
    this.Serializable$set('playbackInterruptionSettings', value);
  }

  get returnPartialResponses(): boolean|null {
    return (
        (this.Serializable$has('returnPartialResponses')) ?
            (this.Serializable$get('returnPartialResponses')) :
            (null));
  }

  /**
   * Whether Dialogflow should return currently queued fulfillment response
   * messages in streaming APIs. If a webhook is specified, it happens before
   * Dialogflow invokes webhook. Warning: 1) This flag only affects streaming
   * API. Responses are still queued and returned once in non-streaming API. 2)
   * The flag can be enabled in any fulfillment but only the first 3 partial
   * responses will be returned. You may only want to apply it to fulfillments
   * that have slow webhooks.
   */
  set returnPartialResponses(value: boolean|null) {
    this.Serializable$set('returnPartialResponses', value);
  }

  get setParameterActions():
      Array<GoogleCloudDialogflowV3alpha1FulfillmentSetParameterAction>|null {
    return (
        (this.Serializable$has('setParameterActions')) ?
            (this.Serializable$get('setParameterActions')) :
            (null));
  }

  /**
   * Set parameter values before executing the webhook.
   */
  set setParameterActions(
      value: Array<GoogleCloudDialogflowV3alpha1FulfillmentSetParameterAction>|
      null) {
    this.Serializable$set('setParameterActions', value);
  }

  get tag(): string|null {
    return (
        (this.Serializable$has('tag')) ? (this.Serializable$get('tag')) :
                                         (null));
  }

  /**
   * The value of this field will be populated in the WebhookRequest
   * `fulfillmentInfo.tag` field by Dialogflow when the associated webhook is
   * called. The tag is typically used by the webhook service to identify which
   * fulfillment is being called, but it could be used for other purposes. This
   * field is required if `webhook` is specified.
   */
  set tag(value: string|null) {
    this.Serializable$set('tag', value);
  }

  get webhook(): string|null {
    return (
        (this.Serializable$has('webhook')) ?
            (this.Serializable$get('webhook')) :
            (null));
  }

  /**
   * The webhook to call. Format: `projects//locations//agents//webhooks/`.
   */
  set webhook(value: string|null) {
    this.Serializable$set('webhook', value);
  }

  get webhookInputParameters():
      Array<GoogleCloudDialogflowV3alpha1ParameterDeclaration>|null {
    return (
        (this.Serializable$has('webhookInputParameters')) ?
            (this.Serializable$get('webhookInputParameters')) :
            (null));
  }

  /**
   * Defines what parameters can be sent to the webhook. These should be a
   * subset of the parameter names defined in the agent. If enforcement is true,
   * only session parameters that are declared here can be sent to the webhook.
   */
  set webhookInputParameters(
      value: Array<GoogleCloudDialogflowV3alpha1ParameterDeclaration>|null) {
    this.Serializable$set('webhookInputParameters', value);
  }

  get webhookOutputParameters():
      Array<GoogleCloudDialogflowV3alpha1ParameterDeclaration>|null {
    return (
        (this.Serializable$has('webhookOutputParameters')) ?
            (this.Serializable$get('webhookOutputParameters')) :
            (null));
  }

  /**
   * Defines what parameters can be sent by the webhook via
   * `session_info.parameters`. These should be a subset of the parameter names
   * defined in the agent. If enforcement is true, only parameters defined here
   * can be modifed by the webhook.
   */
  set webhookOutputParameters(
      value: Array<GoogleCloudDialogflowV3alpha1ParameterDeclaration>|null) {
    this.Serializable$set('webhookOutputParameters', value);
  }

  get webhookSettings(): GoogleCloudDialogflowV3alpha1WebhookSettings|null {
    return (
        (this.Serializable$has('webhookSettings')) ?
            (this.Serializable$get('webhookSettings')) :
            (null));
  }

  /**
   * Hierarchical webhook settings for this fulfillment. The settings exposed at
   * the lower level overrides the settings exposed at the higher level.
   */
  set webhookSettings(value: GoogleCloudDialogflowV3alpha1WebhookSettings|
                      null) {
    this.Serializable$set('webhookSettings', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1Fulfillment> {
    return GoogleCloudDialogflowV3alpha1Fulfillment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'conditionalCases':
            GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases,
        'messages': GoogleCloudDialogflowV3alpha1ResponseMessage,
        'setParameterActions':
            GoogleCloudDialogflowV3alpha1FulfillmentSetParameterAction,
        'webhookInputParameters':
            GoogleCloudDialogflowV3alpha1ParameterDeclaration,
        'webhookOutputParameters':
            GoogleCloudDialogflowV3alpha1ParameterDeclaration
      },
      keys: [
        'advancedSettings', 'conditionalCases', 'messages',
        'playbackInterruptionSettings', 'returnPartialResponses',
        'setParameterActions', 'tag', 'webhook', 'webhookInputParameters',
        'webhookOutputParameters', 'webhookSettings'
      ],
      objects: {
        'advancedSettings': GoogleCloudDialogflowV3alpha1AdvancedSettings,
        'playbackInterruptionSettings':
            GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings,
        'webhookSettings': GoogleCloudDialogflowV3alpha1WebhookSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesParameters {
  cases?: Array<GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCase>|
      null;
}
export class GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesParameters =
              {}) {
    super();
    this.Serializable$set(
        'cases', (parameters.cases == null) ? (null) : (parameters.cases));
  }

  get cases():
      Array<GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCase>|null {
    return (
        (this.Serializable$has('cases')) ? (this.Serializable$get('cases')) :
                                           (null));
  }

  /**
   * A list of cascading if-else conditions.
   */
  set cases(
      value:
          Array<GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCase>|
      null) {
    this.Serializable$set('cases', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases> {
    return GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'cases': GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCase
      },
      keys: ['cases']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseParameters {
  condition?: string|null;
  caseContent?: Array<
      GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseCaseContent>|
      null;
}
export class
    GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCase extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseParameters =
              {}) {
    super();
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'caseContent',
        (parameters.caseContent == null) ? (null) : (parameters.caseContent));
  }

  get caseContent(): Array<
      GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseCaseContent>|
      null {
    return (
        (this.Serializable$has('caseContent')) ?
            (this.Serializable$get('caseContent')) :
            (null));
  }

  /**
   * A list of case content.
   */
  set caseContent(
      value: Array<
          GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseCaseContent>|
      null) {
    this.Serializable$set('caseContent', value);
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition to activate and select this case. Empty means the condition
   * is always true. The condition is evaluated against form parameters or
   * session parameters. See the [conditions
   * reference](https://cloud.google.com/dialogflow/priv/docs/v3/condition).
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCase> {
    return GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'caseContent':
            GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseCaseContent
      },
      keys: ['caseContent', 'condition']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseCaseContentParameters {
  message?: GoogleCloudDialogflowV3alpha1ResponseMessage|null;
  additionalCases?: GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases|
      null;
}
export class
    GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseCaseContent extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseCaseContentParameters =
              {}) {
    super();
    this.Serializable$set(
        'message',
        (parameters.message == null) ? (null) : (parameters.message));
    this.Serializable$set(
        'additionalCases',
        (parameters.additionalCases == null) ? (null) :
                                               (parameters.additionalCases));
  }

  get additionalCases():
      GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases|null {
    return (
        (this.Serializable$has('additionalCases')) ?
            (this.Serializable$get('additionalCases')) :
            (null));
  }

  /**
   * Additional cases to be evaluated.
   */
  set additionalCases(
      value: GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases|null) {
    this.Serializable$set('additionalCases', value);
  }

  get message(): GoogleCloudDialogflowV3alpha1ResponseMessage|null {
    return (
        (this.Serializable$has('message')) ?
            (this.Serializable$get('message')) :
            (null));
  }

  /**
   * Returned message.
   */
  set message(value: GoogleCloudDialogflowV3alpha1ResponseMessage|null) {
    this.Serializable$set('message', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseCaseContent> {
    return GoogleCloudDialogflowV3alpha1FulfillmentConditionalCasesCaseCaseContent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['additionalCases', 'message'],
      objects: {
        'additionalCases':
            GoogleCloudDialogflowV3alpha1FulfillmentConditionalCases,
        'message': GoogleCloudDialogflowV3alpha1ResponseMessage
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1FulfillmentSetParameterActionParameters {
  parameter?: string|null;
  value?: any|null;
}
export class GoogleCloudDialogflowV3alpha1FulfillmentSetParameterAction extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1FulfillmentSetParameterActionParameters =
              {}) {
    super();
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * Display name of the parameter.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  get value(): any|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * The new value of the parameter. A null value clears the parameter.
   */
  set value(value: any|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1FulfillmentSetParameterAction> {
    return GoogleCloudDialogflowV3alpha1FulfillmentSetParameterAction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['parameter', 'value']};
  }
}

export interface GoogleCloudDialogflowV3alpha1GcsDestinationParameters {
  uri?: string|null;
}
export class GoogleCloudDialogflowV3alpha1GcsDestination extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1GcsDestinationParameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The Google Cloud Storage URI for the exported objects. A URI is
   * of the form: gs://bucket/object-name-or-prefix Whether a full object name,
   * or just a prefix, its usage depends on the Dialogflow operation.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1GcsDestination> {
    return GoogleCloudDialogflowV3alpha1GcsDestination;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface GoogleCloudDialogflowV3alpha1GenerateImportConflictReportMetadataParameters {
}
export class
    GoogleCloudDialogflowV3alpha1GenerateImportConflictReportMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1GenerateImportConflictReportMetadataParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1GenerateImportConflictReportMetadata> {
    return GoogleCloudDialogflowV3alpha1GenerateImportConflictReportMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1GenerateImportConflictReportResponseParameters {
  flowConflictReport?: GoogleCloudDialogflowV3alpha1FlowConflictReport|null;
}
export class
    GoogleCloudDialogflowV3alpha1GenerateImportConflictReportResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1GenerateImportConflictReportResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'flowConflictReport',
        (parameters.flowConflictReport == null) ?
            (null) :
            (parameters.flowConflictReport));
  }

  get flowConflictReport(): GoogleCloudDialogflowV3alpha1FlowConflictReport
      |null {
    return (
        (this.Serializable$has('flowConflictReport')) ?
            (this.Serializable$get('flowConflictReport')) :
            (null));
  }

  /**
   * Conflict report for flow imports. Returned when
   * [GenerateImportConflictReportRequest.resource_import_request] is
   * import_flow_request.
   */
  set flowConflictReport(value: GoogleCloudDialogflowV3alpha1FlowConflictReport|
                         null) {
    this.Serializable$set('flowConflictReport', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1GenerateImportConflictReportResponse> {
    return GoogleCloudDialogflowV3alpha1GenerateImportConflictReportResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['flowConflictReport'],
      objects: {
        'flowConflictReport': GoogleCloudDialogflowV3alpha1FlowConflictReport
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataParameters {
  state?: GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState|
      null;
}
export class
    GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
  }

  static get State():
      IGoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataStateEnum {
    return GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataStateEnum;
  }

  get state():
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Required. Output only. The current state of this operation.
   */
  set state(
      value:
          GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataState|
      null) {
    this.Serializable$set('state', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata> {
    return GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state':
            GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadataStateEnum
      },
      keys: ['state']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ImportAgentResponseParameters {
  agent?: string|null;
}
export class GoogleCloudDialogflowV3alpha1ImportAgentResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ImportAgentResponseParameters = {}) {
    super();
    this.Serializable$set(
        'agent', (parameters.agent == null) ? (null) : (parameters.agent));
  }

  get agent(): string|null {
    return (
        (this.Serializable$has('agent')) ? (this.Serializable$get('agent')) :
                                           (null));
  }

  /**
   * The unique identifier of the new agent. Format:
   * `projects//locations//agents/`.
   */
  set agent(value: string|null) {
    this.Serializable$set('agent', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ImportAgentResponse> {
    return GoogleCloudDialogflowV3alpha1ImportAgentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agent']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ImportDocumentsOperationMetadataParameters {
  genericMetadata?:
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|null;
}
export class
    GoogleCloudDialogflowV3alpha1ImportDocumentsOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ImportDocumentsOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|
      null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ImportDocumentsOperationMetadata> {
    return GoogleCloudDialogflowV3alpha1ImportDocumentsOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ImportDocumentsResponseParameters {
  warnings?: Array<GoogleRpcStatus>|null;
}
export class GoogleCloudDialogflowV3alpha1ImportDocumentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ImportDocumentsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'warnings',
        (parameters.warnings == null) ? (null) : (parameters.warnings));
  }

  get warnings(): Array<GoogleRpcStatus>|null {
    return (
        (this.Serializable$has('warnings')) ?
            (this.Serializable$get('warnings')) :
            (null));
  }

  /**
   * Includes details about skipped documents or any other warnings.
   */
  set warnings(value: Array<GoogleRpcStatus>|null) {
    this.Serializable$set('warnings', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ImportDocumentsResponse> {
    return GoogleCloudDialogflowV3alpha1ImportDocumentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'warnings': GoogleRpcStatus}, keys: ['warnings']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ImportFlowResponseParameters {
  flow?: string|null;
}
export class GoogleCloudDialogflowV3alpha1ImportFlowResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ImportFlowResponseParameters = {}) {
    super();
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
  }

  get flow(): string|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * The unique identifier of the new flow. Format:
   * `projects//locations//agents//flows/`.
   */
  set flow(value: string|null) {
    this.Serializable$set('flow', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ImportFlowResponse> {
    return GoogleCloudDialogflowV3alpha1ImportFlowResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flow']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ImportIntentsMetadataParameters {}
export class GoogleCloudDialogflowV3alpha1ImportIntentsMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ImportIntentsMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ImportIntentsMetadata> {
    return GoogleCloudDialogflowV3alpha1ImportIntentsMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1ImportIntentsResponseParameters {
  intents?: Array<string>|null;
}
export class GoogleCloudDialogflowV3alpha1ImportIntentsResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ImportIntentsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
  }

  get intents(): Array<string>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * The unique identifier of the imported intents. Format:
   * `projects//locations//agents//intents/`.
   */
  set intents(value: Array<string>|null) {
    this.Serializable$set('intents', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ImportIntentsResponse> {
    return GoogleCloudDialogflowV3alpha1ImportIntentsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intents']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ImportTestCasesMetadataParameters {
  errors?: Array<GoogleCloudDialogflowV3alpha1TestCaseError>|null;
}
export class GoogleCloudDialogflowV3alpha1ImportTestCasesMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ImportTestCasesMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'errors', (parameters.errors == null) ? (null) : (parameters.errors));
  }

  get errors(): Array<GoogleCloudDialogflowV3alpha1TestCaseError>|null {
    return (
        (this.Serializable$has('errors')) ? (this.Serializable$get('errors')) :
                                            (null));
  }

  /**
   * Errors for failed test cases.
   */
  set errors(value: Array<GoogleCloudDialogflowV3alpha1TestCaseError>|null) {
    this.Serializable$set('errors', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ImportTestCasesMetadata> {
    return GoogleCloudDialogflowV3alpha1ImportTestCasesMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'errors': GoogleCloudDialogflowV3alpha1TestCaseError},
      keys: ['errors']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ImportTestCasesResponseParameters {
  names?: Array<string>|null;
}
export class GoogleCloudDialogflowV3alpha1ImportTestCasesResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ImportTestCasesResponseParameters = {}) {
    super();
    this.Serializable$set(
        'names', (parameters.names == null) ? (null) : (parameters.names));
  }

  get names(): Array<string>|null {
    return (
        (this.Serializable$has('names')) ? (this.Serializable$get('names')) :
                                           (null));
  }

  /**
   * The unique identifiers of the new test cases. Format:
   * `projects//locations//agents//testCases/`.
   */
  set names(value: Array<string>|null) {
    this.Serializable$set('names', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ImportTestCasesResponse> {
    return GoogleCloudDialogflowV3alpha1ImportTestCasesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['names']};
  }
}

export interface GoogleCloudDialogflowV3alpha1InlineDestinationParameters {
  content?: string|null;
}
export class GoogleCloudDialogflowV3alpha1InlineDestination extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1InlineDestinationParameters = {}) {
    super();
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Output only. The uncompressed byte content for the objects. Only populated
   * in responses.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1InlineDestination> {
    return GoogleCloudDialogflowV3alpha1InlineDestination;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['content']};
  }
}

export interface GoogleCloudDialogflowV3alpha1InputAudioConfigParameters {
  audioEncoding?: GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding|
      null;
  sampleRateHertz?: number|null;
  languageCode?: string|null;
  enableWordInfo?: boolean|null;
  audioChannelCount?: number|null;
  phraseHints?: Array<string>|null;
  speechContexts?: Array<GoogleCloudDialogflowV3alpha1SpeechContext>|null;
  model?: string|null;
  modelVariant?: GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant|null;
  singleUtterance?: boolean|null;
  disableNoSpeechRecognizedEvent?: boolean|null;
  bargeInConfig?: GoogleCloudDialogflowV3alpha1BargeInConfig|null;
  enableSpeechLogging?: boolean|null;
  enableAutomaticPunctuation?: boolean|null;
  defaultNoSpeechTimeout?: string|null;
  alternativeLanguageCodes?: Array<string>|null;
  phraseSet?: Array<string>|null;
  audioStreamInfo?:
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioStreamInfo|null;
}
export class GoogleCloudDialogflowV3alpha1InputAudioConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1InputAudioConfigParameters = {}) {
    super();
    this.Serializable$set(
        'audioEncoding',
        (parameters.audioEncoding == null) ? (null) :
                                             (parameters.audioEncoding));
    this.Serializable$set(
        'sampleRateHertz',
        (parameters.sampleRateHertz == null) ? (null) :
                                               (parameters.sampleRateHertz));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'enableWordInfo',
        (parameters.enableWordInfo == null) ? (null) :
                                              (parameters.enableWordInfo));
    this.Serializable$set(
        'audioChannelCount',
        (parameters.audioChannelCount == null) ?
            (null) :
            (parameters.audioChannelCount));
    this.Serializable$set(
        'phraseHints',
        (parameters.phraseHints == null) ? (null) : (parameters.phraseHints));
    this.Serializable$set(
        'speechContexts',
        (parameters.speechContexts == null) ? (null) :
                                              (parameters.speechContexts));
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'modelVariant',
        (parameters.modelVariant == null) ? (null) : (parameters.modelVariant));
    this.Serializable$set(
        'singleUtterance',
        (parameters.singleUtterance == null) ? (null) :
                                               (parameters.singleUtterance));
    this.Serializable$set(
        'disableNoSpeechRecognizedEvent',
        (parameters.disableNoSpeechRecognizedEvent == null) ?
            (null) :
            (parameters.disableNoSpeechRecognizedEvent));
    this.Serializable$set(
        'bargeInConfig',
        (parameters.bargeInConfig == null) ? (null) :
                                             (parameters.bargeInConfig));
    this.Serializable$set(
        'enableSpeechLogging',
        (parameters.enableSpeechLogging == null) ?
            (null) :
            (parameters.enableSpeechLogging));
    this.Serializable$set(
        'enableAutomaticPunctuation',
        (parameters.enableAutomaticPunctuation == null) ?
            (null) :
            (parameters.enableAutomaticPunctuation));
    this.Serializable$set(
        'defaultNoSpeechTimeout',
        (parameters.defaultNoSpeechTimeout == null) ?
            (null) :
            (parameters.defaultNoSpeechTimeout));
    this.Serializable$set(
        'alternativeLanguageCodes',
        (parameters.alternativeLanguageCodes == null) ?
            (null) :
            (parameters.alternativeLanguageCodes));
    this.Serializable$set(
        'phraseSet',
        (parameters.phraseSet == null) ? (null) : (parameters.phraseSet));
    this.Serializable$set(
        'audioStreamInfo',
        (parameters.audioStreamInfo == null) ? (null) :
                                               (parameters.audioStreamInfo));
  }

  static get AudioEncoding():
      IGoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum {
    return GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum;
  }

  static get ModelVariant():
      IGoogleCloudDialogflowV3alpha1InputAudioConfigModelVariantEnum {
    return GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariantEnum;
  }

  get alternativeLanguageCodes(): Array<string>|null {
    return (
        (this.Serializable$has('alternativeLanguageCodes')) ?
            (this.Serializable$get('alternativeLanguageCodes')) :
            (null));
  }

  /**
   * A list of up to 3 additional
   * [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt) language tags,
   * listing possible alternative languages of the supplied audio. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes. If alternative languages
   * are listed, recognition result will contain recognition in the most likely
   * language detected including the main language_code. The recognition result
   * will include the language tag of the language detected in the audio. Extra
   * language codes will be ignored.
   */
  set alternativeLanguageCodes(value: Array<string>|null) {
    this.Serializable$set('alternativeLanguageCodes', value);
  }

  get audioChannelCount(): number|null {
    return (
        (this.Serializable$has('audioChannelCount')) ?
            (this.Serializable$get('audioChannelCount')) :
            (null));
  }

  /**
   * Optional. The number of channels in the input audio data. Valid values for
   * LINEAR16 and FLAC are `1`-`8`. Valid values for MULAW, AMR and AMR_WB are
   * only `1`. If `0` or omitted, defaults to one channel (mono). NOTE:
   * Currently, we perform recognition only on channel 0 and ignore all other
   * channels.
   */
  set audioChannelCount(value: number|null) {
    this.Serializable$set('audioChannelCount', value);
  }

  get audioEncoding():
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding|null {
    return (
        (this.Serializable$has('audioEncoding')) ?
            (this.Serializable$get('audioEncoding')) :
            (null));
  }

  /**
   * Required. Audio encoding of the audio content to process.
   */
  set audioEncoding(
      value: GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncoding|null) {
    this.Serializable$set('audioEncoding', value);
  }

  get audioStreamInfo():
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioStreamInfo|null {
    return (
        (this.Serializable$has('audioStreamInfo')) ?
            (this.Serializable$get('audioStreamInfo')) :
            (null));
  }

  /**
   * Output only. Info on the audio stream.
   */
  set audioStreamInfo(
      value: GoogleCloudDialogflowV3alpha1InputAudioConfigAudioStreamInfo|
      null) {
    this.Serializable$set('audioStreamInfo', value);
  }

  get bargeInConfig(): GoogleCloudDialogflowV3alpha1BargeInConfig|null {
    return (
        (this.Serializable$has('bargeInConfig')) ?
            (this.Serializable$get('bargeInConfig')) :
            (null));
  }

  /**
   * Configuration of barge-in behavior during the streaming of input audio.
   */
  set bargeInConfig(value: GoogleCloudDialogflowV3alpha1BargeInConfig|null) {
    this.Serializable$set('bargeInConfig', value);
  }

  get defaultNoSpeechTimeout(): string|null {
    return (
        (this.Serializable$has('defaultNoSpeechTimeout')) ?
            (this.Serializable$get('defaultNoSpeechTimeout')) :
            (null));
  }

  /**
   * If set, use this no-speech timeout when the agent does not provide a
   * no-speech timeout itself.
   */
  set defaultNoSpeechTimeout(value: string|null) {
    this.Serializable$set('defaultNoSpeechTimeout', value);
  }

  get disableNoSpeechRecognizedEvent(): boolean|null {
    return (
        (this.Serializable$has('disableNoSpeechRecognizedEvent')) ?
            (this.Serializable$get('disableNoSpeechRecognizedEvent')) :
            (null));
  }

  /**
   * Only used in Participants.AnalyzeContent and
   * Participants.StreamingAnalyzeContent. If `false` and recognition doesn't
   * return any result, trigger `NO_SPEECH_RECOGNIZED` event to Dialogflow
   * agent.
   */
  set disableNoSpeechRecognizedEvent(value: boolean|null) {
    this.Serializable$set('disableNoSpeechRecognizedEvent', value);
  }

  get enableAutomaticPunctuation(): boolean|null {
    return (
        (this.Serializable$has('enableAutomaticPunctuation')) ?
            (this.Serializable$get('enableAutomaticPunctuation')) :
            (null));
  }

  /**
   * Enable automatic punctuation option at the speech backend.
   */
  set enableAutomaticPunctuation(value: boolean|null) {
    this.Serializable$set('enableAutomaticPunctuation', value);
  }

  get enableSpeechLogging(): boolean|null {
    return (
        (this.Serializable$has('enableSpeechLogging')) ?
            (this.Serializable$get('enableSpeechLogging')) :
            (null));
  }

  /**
   * Enable logging at the speech backend.
   */
  set enableSpeechLogging(value: boolean|null) {
    this.Serializable$set('enableSpeechLogging', value);
  }

  get enableWordInfo(): boolean|null {
    return (
        (this.Serializable$has('enableWordInfo')) ?
            (this.Serializable$get('enableWordInfo')) :
            (null));
  }

  /**
   * Optional. If `true`, Dialogflow returns SpeechWordInfo in
   * StreamingRecognitionResult with information about the recognized speech
   * words, e.g. start and end time offsets. If false or unspecified, Speech
   * doesn't return any word-level information.
   */
  set enableWordInfo(value: boolean|null) {
    this.Serializable$set('enableWordInfo', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Only populated internally in Dialogflow API server to ease speech
   * implementation. Copied from `query_input.language_code`.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Optional. Which Speech model to select for the given request. Select the
   * model best suited to your domain to get best results. If a model is not
   * explicitly specified, then we auto-select a model based on the parameters
   * in the InputAudioConfig. If enhanced speech model is enabled for the agent
   * and an enhanced version of the specified model for the language does not
   * exist, then the speech is recognized using the standard version of the
   * specified model. Refer to [Cloud Speech API
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#select-model)
   * for more details.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  get modelVariant(): GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant
      |null {
    return (
        (this.Serializable$has('modelVariant')) ?
            (this.Serializable$get('modelVariant')) :
            (null));
  }

  /**
   * Optional. Which variant of the Speech model to use.
   */
  set modelVariant(
      value: GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariant|null) {
    this.Serializable$set('modelVariant', value);
  }

  get phraseHints(): Array<string>|null {
    return (
        (this.Serializable$has('phraseHints')) ?
            (this.Serializable$get('phraseHints')) :
            (null));
  }

  /**
   * Optional. A list of strings containing words and phrases that the speech
   * recognizer should recognize with higher likelihood. See [the Cloud Speech
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#phrase-hints)
   * for more details.
   */
  set phraseHints(value: Array<string>|null) {
    this.Serializable$set('phraseHints', value);
  }

  get phraseSet(): Array<string>|null {
    return (
        (this.Serializable$has('phraseSet')) ?
            (this.Serializable$get('phraseSet')) :
            (null));
  }

  /**
   * A collection of phrase set resources to use for speech adaptation.
   */
  set phraseSet(value: Array<string>|null) {
    this.Serializable$set('phraseSet', value);
  }

  get sampleRateHertz(): number|null {
    return (
        (this.Serializable$has('sampleRateHertz')) ?
            (this.Serializable$get('sampleRateHertz')) :
            (null));
  }

  /**
   * Sample rate (in Hertz) of the audio content sent in the query. Refer to
   * [Cloud Speech API
   * documentation](https://cloud.google.com/speech-to-text/docs/basics) for
   * more details.
   */
  set sampleRateHertz(value: number|null) {
    this.Serializable$set('sampleRateHertz', value);
  }

  get singleUtterance(): boolean|null {
    return (
        (this.Serializable$has('singleUtterance')) ?
            (this.Serializable$get('singleUtterance')) :
            (null));
  }

  /**
   * Optional. If `false` (default), recognition does not cease until the client
   * closes the stream. If `true`, the recognizer will detect a single spoken
   * utterance in input audio. Recognition ceases when it detects the audio's
   * voice has stopped or paused. In this case, once a detected intent is
   * received, the client should close the stream and start a new request with a
   * new stream as needed. Note: This setting is relevant only for streaming
   * methods.
   */
  set singleUtterance(value: boolean|null) {
    this.Serializable$set('singleUtterance', value);
  }

  get speechContexts(): Array<GoogleCloudDialogflowV3alpha1SpeechContext>|null {
    return (
        (this.Serializable$has('speechContexts')) ?
            (this.Serializable$get('speechContexts')) :
            (null));
  }

  /**
   * Optional. Context information to assist speech recognition. See [the Cloud
   * Speech
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#phrase-hints)
   * for more details.
   */
  set speechContexts(value: Array<GoogleCloudDialogflowV3alpha1SpeechContext>|
                     null) {
    this.Serializable$set('speechContexts', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1InputAudioConfig> {
    return GoogleCloudDialogflowV3alpha1InputAudioConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'speechContexts': GoogleCloudDialogflowV3alpha1SpeechContext},
      enums: {
        'audioEncoding':
            GoogleCloudDialogflowV3alpha1InputAudioConfigAudioEncodingEnum,
        'modelVariant':
            GoogleCloudDialogflowV3alpha1InputAudioConfigModelVariantEnum
      },
      keys: [
        'alternativeLanguageCodes', 'audioChannelCount', 'audioEncoding',
        'audioStreamInfo', 'bargeInConfig', 'defaultNoSpeechTimeout',
        'disableNoSpeechRecognizedEvent', 'enableAutomaticPunctuation',
        'enableSpeechLogging', 'enableWordInfo', 'languageCode', 'model',
        'modelVariant', 'phraseHints', 'phraseSet', 'sampleRateHertz',
        'singleUtterance', 'speechContexts'
      ],
      objects: {
        'audioStreamInfo':
            GoogleCloudDialogflowV3alpha1InputAudioConfigAudioStreamInfo,
        'bargeInConfig': GoogleCloudDialogflowV3alpha1BargeInConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1InputAudioConfigAudioStreamInfoParameters {
  startTime?: string|null;
  detectionStartOffset?: string|null;
}
export class
    GoogleCloudDialogflowV3alpha1InputAudioConfigAudioStreamInfo extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1InputAudioConfigAudioStreamInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'startTime',
        (parameters.startTime == null) ? (null) : (parameters.startTime));
    this.Serializable$set(
        'detectionStartOffset',
        (parameters.detectionStartOffset == null) ?
            (null) :
            (parameters.detectionStartOffset));
  }

  get detectionStartOffset(): string|null {
    return (
        (this.Serializable$has('detectionStartOffset')) ?
            (this.Serializable$get('detectionStartOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio stream that corresponds
   * to the start of the speech detection.
   */
  set detectionStartOffset(value: string|null) {
    this.Serializable$set('detectionStartOffset', value);
  }

  get startTime(): string|null {
    return (
        (this.Serializable$has('startTime')) ?
            (this.Serializable$get('startTime')) :
            (null));
  }

  /**
   * Absolute start time of the stream. It can be gRPC stream or telephony
   * stream.
   */
  set startTime(value: string|null) {
    this.Serializable$set('startTime', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1InputAudioConfigAudioStreamInfo> {
    return GoogleCloudDialogflowV3alpha1InputAudioConfigAudioStreamInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['detectionStartOffset', 'startTime']};
  }
}

export interface GoogleCloudDialogflowV3alpha1IntegrationParameters {
  name?: string|null;
  state?: GoogleCloudDialogflowV3alpha1IntegrationState|null;
  languageCode?: string|null;
  environment?: string|null;
  type?: GoogleCloudDialogflowV3alpha1IntegrationType|null;
  oneClickIntegrationPayload?:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayload|null;
  dialogflowMessengerPayload?:
      GoogleCloudDialogflowV3alpha1IntegrationDialogflowMessengerPayload|null;
  linePayload?: GoogleCloudDialogflowV3alpha1IntegrationLinePayload|null;
  facebookMessengerPayload?:
      GoogleCloudDialogflowV3alpha1IntegrationFacebookMessengerPayload|null;
}
export class GoogleCloudDialogflowV3alpha1Integration extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1IntegrationParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'oneClickIntegrationPayload',
        (parameters.oneClickIntegrationPayload == null) ?
            (null) :
            (parameters.oneClickIntegrationPayload));
    this.Serializable$set(
        'dialogflowMessengerPayload',
        (parameters.dialogflowMessengerPayload == null) ?
            (null) :
            (parameters.dialogflowMessengerPayload));
    this.Serializable$set(
        'linePayload',
        (parameters.linePayload == null) ? (null) : (parameters.linePayload));
    this.Serializable$set(
        'facebookMessengerPayload',
        (parameters.facebookMessengerPayload == null) ?
            (null) :
            (parameters.facebookMessengerPayload));
  }

  static get State(): IGoogleCloudDialogflowV3alpha1IntegrationStateEnum {
    return GoogleCloudDialogflowV3alpha1IntegrationStateEnum;
  }

  static get Type(): IGoogleCloudDialogflowV3alpha1IntegrationTypeEnum {
    return GoogleCloudDialogflowV3alpha1IntegrationTypeEnum;
  }

  get dialogflowMessengerPayload():
      GoogleCloudDialogflowV3alpha1IntegrationDialogflowMessengerPayload|null {
    return (
        (this.Serializable$has('dialogflowMessengerPayload')) ?
            (this.Serializable$get('dialogflowMessengerPayload')) :
            (null));
  }

  /**
   * Optional. The payload for Dialogflow Messenger
   */
  set dialogflowMessengerPayload(
      value: GoogleCloudDialogflowV3alpha1IntegrationDialogflowMessengerPayload|
      null) {
    this.Serializable$set('dialogflowMessengerPayload', value);
  }

  get environment(): string|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * Optional. The name of the Dialogflow environment to serve integration from.
   * If not specified, 'draft' environment is used. Format:
   * `projects//locations//agents//environments/`.
   */
  set environment(value: string|null) {
    this.Serializable$set('environment', value);
  }

  get facebookMessengerPayload():
      GoogleCloudDialogflowV3alpha1IntegrationFacebookMessengerPayload|null {
    return (
        (this.Serializable$has('facebookMessengerPayload')) ?
            (this.Serializable$get('facebookMessengerPayload')) :
            (null));
  }

  /**
   * Optional. The payload for Facebook Messenger.
   */
  set facebookMessengerPayload(
      value: GoogleCloudDialogflowV3alpha1IntegrationFacebookMessengerPayload|
      null) {
    this.Serializable$set('facebookMessengerPayload', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The language to serve integration with. If not specified, the
   * agent's default language is used. [Many
   * languages](https://cloud.google.com/dialogflow/docs/reference/language) are
   * supported. Note: languages must be enabled in the agent before they can be
   * used.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get linePayload(): GoogleCloudDialogflowV3alpha1IntegrationLinePayload|null {
    return (
        (this.Serializable$has('linePayload')) ?
            (this.Serializable$get('linePayload')) :
            (null));
  }

  /**
   * Optional. The payload for Line.
   */
  set linePayload(value: GoogleCloudDialogflowV3alpha1IntegrationLinePayload|
                  null) {
    this.Serializable$set('linePayload', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. Integration name. Format: `projects//locations//agents/{Agent
   * ID}/integrations/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get oneClickIntegrationPayload():
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayload|null {
    return (
        (this.Serializable$has('oneClickIntegrationPayload')) ?
            (this.Serializable$get('oneClickIntegrationPayload')) :
            (null));
  }

  /**
   * Optional. The payload for one-click integrations.
   */
  set oneClickIntegrationPayload(
      value: GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayload|
      null) {
    this.Serializable$set('oneClickIntegrationPayload', value);
  }

  get state(): GoogleCloudDialogflowV3alpha1IntegrationState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Optional. Specifies status of the integration.
   */
  set state(value: GoogleCloudDialogflowV3alpha1IntegrationState|null) {
    this.Serializable$set('state', value);
  }

  get type(): GoogleCloudDialogflowV3alpha1IntegrationType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  set type(value: GoogleCloudDialogflowV3alpha1IntegrationType|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1Integration> {
    return GoogleCloudDialogflowV3alpha1Integration;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'state': GoogleCloudDialogflowV3alpha1IntegrationStateEnum,
        'type': GoogleCloudDialogflowV3alpha1IntegrationTypeEnum
      },
      keys: [
        'dialogflowMessengerPayload', 'environment', 'facebookMessengerPayload',
        'languageCode', 'linePayload', 'name', 'oneClickIntegrationPayload',
        'state', 'type'
      ],
      objects: {
        'dialogflowMessengerPayload':
            GoogleCloudDialogflowV3alpha1IntegrationDialogflowMessengerPayload,
        'facebookMessengerPayload':
            GoogleCloudDialogflowV3alpha1IntegrationFacebookMessengerPayload,
        'linePayload': GoogleCloudDialogflowV3alpha1IntegrationLinePayload,
        'oneClickIntegrationPayload':
            GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayload
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1IntegrationDialogflowMessengerPayloadParameters {
}
export class
    GoogleCloudDialogflowV3alpha1IntegrationDialogflowMessengerPayload extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1IntegrationDialogflowMessengerPayloadParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1IntegrationDialogflowMessengerPayload> {
    return GoogleCloudDialogflowV3alpha1IntegrationDialogflowMessengerPayload;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1IntegrationFacebookMessengerPayloadParameters {
  appSecret?: string|null;
  verifyToken?: string|null;
  pageAccessToken?: string|null;
  enableGetStartedButton?: boolean|null;
}
export class
    GoogleCloudDialogflowV3alpha1IntegrationFacebookMessengerPayload extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1IntegrationFacebookMessengerPayloadParameters =
              {}) {
    super();
    this.Serializable$set(
        'appSecret',
        (parameters.appSecret == null) ? (null) : (parameters.appSecret));
    this.Serializable$set(
        'verifyToken',
        (parameters.verifyToken == null) ? (null) : (parameters.verifyToken));
    this.Serializable$set(
        'pageAccessToken',
        (parameters.pageAccessToken == null) ? (null) :
                                               (parameters.pageAccessToken));
    this.Serializable$set(
        'enableGetStartedButton',
        (parameters.enableGetStartedButton == null) ?
            (null) :
            (parameters.enableGetStartedButton));
  }

  get appSecret(): string|null {
    return (
        (this.Serializable$has('appSecret')) ?
            (this.Serializable$get('appSecret')) :
            (null));
  }

  /**
   * Required. The secret used for request validation. Please see: *
   * https://developers.facebook.com/docs/development/create-an-app/app-dashboard/basic-settings#app-secret
   * * https://developers.facebook.com/docs/messenger-platform/webhook#security.
   */
  set appSecret(value: string|null) {
    this.Serializable$set('appSecret', value);
  }

  get enableGetStartedButton(): boolean|null {
    return (
        (this.Serializable$has('enableGetStartedButton')) ?
            (this.Serializable$get('enableGetStartedButton')) :
            (null));
  }

  /**
   * Indicates whether the bot should configure the [Get Started
   * Button](https://developers.facebook.com/docs/messenger-platform/reference/messenger-profile-api/get-started-button)
   * for customers. The button will be configured in a way that, a
   * FACEBOOK_WELCOME event will be posted back to Dialogflow when it is tapped.
   */
  set enableGetStartedButton(value: boolean|null) {
    this.Serializable$set('enableGetStartedButton', value);
  }

  get pageAccessToken(): string|null {
    return (
        (this.Serializable$has('pageAccessToken')) ?
            (this.Serializable$get('pageAccessToken')) :
            (null));
  }

  /**
   * Required. The page access token used to call Facebook API. Please see
   * https://developers.facebook.com/docs/facebook-login/access-tokens/#pagetokens.
   */
  set pageAccessToken(value: string|null) {
    this.Serializable$set('pageAccessToken', value);
  }

  get verifyToken(): string|null {
    return (
        (this.Serializable$has('verifyToken')) ?
            (this.Serializable$get('verifyToken')) :
            (null));
  }

  /**
   * Required. The verify token used in initial webhook setup verification.
   * Please see
   * https://developers.facebook.com/docs/messenger-platform/webhook#setup.
   */
  set verifyToken(value: string|null) {
    this.Serializable$set('verifyToken', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1IntegrationFacebookMessengerPayload> {
    return GoogleCloudDialogflowV3alpha1IntegrationFacebookMessengerPayload;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'appSecret', 'enableGetStartedButton', 'pageAccessToken', 'verifyToken'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1IntegrationLinePayloadParameters {
  channelSecret?: string|null;
  channelId?: string|null;
  channelAccessToken?: string|null;
}
export class GoogleCloudDialogflowV3alpha1IntegrationLinePayload extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1IntegrationLinePayloadParameters = {}) {
    super();
    this.Serializable$set(
        'channelSecret',
        (parameters.channelSecret == null) ? (null) :
                                             (parameters.channelSecret));
    this.Serializable$set(
        'channelId',
        (parameters.channelId == null) ? (null) : (parameters.channelId));
    this.Serializable$set(
        'channelAccessToken',
        (parameters.channelAccessToken == null) ?
            (null) :
            (parameters.channelAccessToken));
  }

  get channelAccessToken(): string|null {
    return (
        (this.Serializable$has('channelAccessToken')) ?
            (this.Serializable$get('channelAccessToken')) :
            (null));
  }

  /**
   * Required. The channel access token used to call Line Messaging API. Please
   * see
   * https://developers.line.biz/en/docs/messaging-api/channel-access-tokens/.
   */
  set channelAccessToken(value: string|null) {
    this.Serializable$set('channelAccessToken', value);
  }

  get channelId(): string|null {
    return (
        (this.Serializable$has('channelId')) ?
            (this.Serializable$get('channelId')) :
            (null));
  }

  /**
   * Required. The channel id used for sending Line message. Please see
   * https://developers.line.biz/en/docs/messaging-api/getting-started/#using-console.
   */
  set channelId(value: string|null) {
    this.Serializable$set('channelId', value);
  }

  get channelSecret(): string|null {
    return (
        (this.Serializable$has('channelSecret')) ?
            (this.Serializable$get('channelSecret')) :
            (null));
  }

  /**
   * Required. The channel secret used for request validation. Please see
   * https://developers.line.biz/en/reference/messaging-api/#signature-validation.
   */
  set channelSecret(value: string|null) {
    this.Serializable$set('channelSecret', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1IntegrationLinePayload> {
    return GoogleCloudDialogflowV3alpha1IntegrationLinePayload;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['channelAccessToken', 'channelId', 'channelSecret']};
  }
}

export interface GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadParameters {
  partner?:
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner|
      null;
  token?: string|null;
  setupNonce?: string|null;
  conversationProfile?: string|null;
  serviceAccount?: string|null;
  displayName?: string|null;
}
export class
    GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayload extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadParameters =
              {}) {
    super();
    this.Serializable$set(
        'partner',
        (parameters.partner == null) ? (null) : (parameters.partner));
    this.Serializable$set(
        'token', (parameters.token == null) ? (null) : (parameters.token));
    this.Serializable$set(
        'setupNonce',
        (parameters.setupNonce == null) ? (null) : (parameters.setupNonce));
    this.Serializable$set(
        'conversationProfile',
        (parameters.conversationProfile == null) ?
            (null) :
            (parameters.conversationProfile));
    this.Serializable$set(
        'serviceAccount',
        (parameters.serviceAccount == null) ? (null) :
                                              (parameters.serviceAccount));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
  }

  static get Partner():
      IGoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum {
    return GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum;
  }

  get conversationProfile(): string|null {
    return (
        (this.Serializable$has('conversationProfile')) ?
            (this.Serializable$get('conversationProfile')) :
            (null));
  }

  /**
   * Output only. Name of the conversation profile used by this integration.
   */
  set conversationProfile(value: string|null) {
    this.Serializable$set('conversationProfile', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Optional. User specified display name for this integration
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get partner():
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner
      |null {
    return (
        (this.Serializable$has('partner')) ?
            (this.Serializable$get('partner')) :
            (null));
  }

  /**
   * Output only. The partner to which this integration is bound.
   */
  set partner(
      value:
          GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartner|
      null) {
    this.Serializable$set('partner', value);
  }

  get serviceAccount(): string|null {
    return (
        (this.Serializable$has('serviceAccount')) ?
            (this.Serializable$get('serviceAccount')) :
            (null));
  }

  /**
   * Output only. Email of the service account used by the partner to access the
   * agent.
   */
  set serviceAccount(value: string|null) {
    this.Serializable$set('serviceAccount', value);
  }

  get setupNonce(): string|null {
    return (
        (this.Serializable$has('setupNonce')) ?
            (this.Serializable$get('setupNonce')) :
            (null));
  }

  /**
   * Output only. The one-time secure nonce that is used in an OAuth or
   * OAuth-like flow.
   */
  set setupNonce(value: string|null) {
    this.Serializable$set('setupNonce', value);
  }

  get token(): string|null {
    return (
        (this.Serializable$has('token')) ? (this.Serializable$get('token')) :
                                           (null));
  }

  /**
   * Output only. The token issued by the partner to represent the link.
   */
  set token(value: string|null) {
    this.Serializable$set('token', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayload> {
    return GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayload;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'partner':
            GoogleCloudDialogflowV3alpha1IntegrationOneClickIntegrationPayloadPartnerEnum
      },
      keys: [
        'conversationProfile', 'displayName', 'partner', 'serviceAccount',
        'setupNonce', 'token'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1IntentParameters {
  name?: string|null;
  displayName?: string|null;
  trainingPhrases?: Array<GoogleCloudDialogflowV3alpha1IntentTrainingPhrase>|
      null;
  parameters?: Array<GoogleCloudDialogflowV3alpha1IntentParameter>|null;
  asrCorrections?: Array<GoogleCloudDialogflowV3alpha1IntentAsrCorrection>|null;
  priority?: number|null;
  isFallback?: boolean|null;
  labels?: ApiClientObjectMap<string>|null;
  description?: string|null;
  etag?: string|null;
  createTime?: string|null;
  updateTime?: string|null;
  clarifyingPhrase?: string|null;
}
export class GoogleCloudDialogflowV3alpha1Intent extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV3alpha1IntentParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'trainingPhrases',
        (parameters.trainingPhrases == null) ? (null) :
                                               (parameters.trainingPhrases));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'asrCorrections',
        (parameters.asrCorrections == null) ? (null) :
                                              (parameters.asrCorrections));
    this.Serializable$set(
        'priority',
        (parameters.priority == null) ? (null) : (parameters.priority));
    this.Serializable$set(
        'isFallback',
        (parameters.isFallback == null) ? (null) : (parameters.isFallback));
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'etag', (parameters.etag == null) ? (null) : (parameters.etag));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'clarifyingPhrase',
        (parameters.clarifyingPhrase == null) ? (null) :
                                                (parameters.clarifyingPhrase));
  }

  get asrCorrections():
      Array<GoogleCloudDialogflowV3alpha1IntentAsrCorrection>|null {
    return (
        (this.Serializable$has('asrCorrections')) ?
            (this.Serializable$get('asrCorrections')) :
            (null));
  }

  /**
   * The collection of ASR correction rules provided by developers for the
   * intent. Each rule is a phrase with ASR errors and its corrected
   * counterpart, e.g., error_phrase: \"i raven\" -> correction_phrase: \"my
   * payment\".
   */
  set asrCorrections(
      value: Array<GoogleCloudDialogflowV3alpha1IntentAsrCorrection>|null) {
    this.Serializable$set('asrCorrections', value);
  }

  get clarifyingPhrase(): string|null {
    return (
        (this.Serializable$has('clarifyingPhrase')) ?
            (this.Serializable$get('clarifyingPhrase')) :
            (null));
  }

  /**
   * Human understandable phrase that can be used to clarify how to trigger this
   * intent. This field can be used in agent responses to disambiguate amongst
   * different intents. Maximum character limit: 140 characters.
   */
  set clarifyingPhrase(value: string|null) {
    this.Serializable$set('clarifyingPhrase', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The time the intent was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Human readable description for better understanding an intent like its
   * scope, content, result etc. Maximum character limit: 140 characters.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the intent, unique within the agent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get etag(): string|null {
    return (
        (this.Serializable$has('etag')) ? (this.Serializable$get('etag')) :
                                          (null));
  }

  /**
   * Etag of the intent, may be sent on update requests to ensure the client has
   * an up-to-date value before proceeding.
   */
  set etag(value: string|null) {
    this.Serializable$set('etag', value);
  }

  get isFallback(): boolean|null {
    return (
        (this.Serializable$has('isFallback')) ?
            (this.Serializable$get('isFallback')) :
            (null));
  }

  /**
   * Indicates whether this is a fallback intent. Currently only default
   * fallback intent is allowed in the agent, which is added upon agent
   * creation. Adding training phrases to fallback intent is useful in the case
   * of requests that are mistakenly matched, since training phrases assigned to
   * fallback intents act as negative examples that triggers no-match event.
   */
  set isFallback(value: boolean|null) {
    this.Serializable$set('isFallback', value);
  }

  get labels(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * The key/value metadata to label an intent. Labels can contain lowercase
   * letters, digits and the symbols '-' and '_'. International characters are
   * allowed, including letters from unicase alphabets. Keys must start with a
   * letter. Keys and values can be no longer than 63 characters and no more
   * than 128 bytes. Prefix \"sys-\" is reserved for Dialogflow defined labels.
   * Currently allowed Dialogflow defined labels include: * sys-head *
   * sys-contextual The above labels do not require value. \"sys-head\" means
   * the intent is a head intent. \"sys-contextual\" means the intent is a
   * contextual intent.
   */
  set labels(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('labels', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the intent. Required for the Intents.UpdateIntent
   * method. Intents.CreateIntent populates the name automatically. Format:
   * `projects//locations//agents//intents/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameters(): Array<GoogleCloudDialogflowV3alpha1IntentParameter>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of parameters associated with the intent.
   */
  set parameters(value: Array<GoogleCloudDialogflowV3alpha1IntentParameter>|
                 null) {
    this.Serializable$set('parameters', value);
  }

  get priority(): number|null {
    return (
        (this.Serializable$has('priority')) ?
            (this.Serializable$get('priority')) :
            (null));
  }

  /**
   * The priority of this intent. Higher numbers represent higher priorities. -
   * If the supplied value is unspecified or 0, the service translates the value
   * to 500,000, which corresponds to the `Normal` priority in the console. - If
   * the supplied value is negative, the intent is ignored in runtime detect
   * intent requests.
   */
  set priority(value: number|null) {
    this.Serializable$set('priority', value);
  }

  get trainingPhrases():
      Array<GoogleCloudDialogflowV3alpha1IntentTrainingPhrase>|null {
    return (
        (this.Serializable$has('trainingPhrases')) ?
            (this.Serializable$get('trainingPhrases')) :
            (null));
  }

  /**
   * The collection of training phrases the agent is trained on to identify the
   * intent.
   */
  set trainingPhrases(
      value: Array<GoogleCloudDialogflowV3alpha1IntentTrainingPhrase>|null) {
    this.Serializable$set('trainingPhrases', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. Last time the intent was modified.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1Intent> {
    return GoogleCloudDialogflowV3alpha1Intent;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'asrCorrections': GoogleCloudDialogflowV3alpha1IntentAsrCorrection,
        'parameters': GoogleCloudDialogflowV3alpha1IntentParameter,
        'trainingPhrases': GoogleCloudDialogflowV3alpha1IntentTrainingPhrase
      },
      keys: [
        'asrCorrections', 'clarifyingPhrase', 'createTime', 'description',
        'displayName', 'etag', 'isFallback', 'labels', 'name', 'parameters',
        'priority', 'trainingPhrases', 'updateTime'
      ],
      objectMaps: {
        'labels': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1IntentAsrCorrectionParameters {
  errorPhrase?: string|null;
  correctionPhrase?: string|null;
}
export class GoogleCloudDialogflowV3alpha1IntentAsrCorrection extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1IntentAsrCorrectionParameters = {}) {
    super();
    this.Serializable$set(
        'errorPhrase',
        (parameters.errorPhrase == null) ? (null) : (parameters.errorPhrase));
    this.Serializable$set(
        'correctionPhrase',
        (parameters.correctionPhrase == null) ? (null) :
                                                (parameters.correctionPhrase));
  }

  get correctionPhrase(): string|null {
    return (
        (this.Serializable$has('correctionPhrase')) ?
            (this.Serializable$get('correctionPhrase')) :
            (null));
  }

  /**
   * The phrase with ASR errors corrected.
   */
  set correctionPhrase(value: string|null) {
    this.Serializable$set('correctionPhrase', value);
  }

  get errorPhrase(): string|null {
    return (
        (this.Serializable$has('errorPhrase')) ?
            (this.Serializable$get('errorPhrase')) :
            (null));
  }

  /**
   * The phrase with one or more ASR errors.
   */
  set errorPhrase(value: string|null) {
    this.Serializable$set('errorPhrase', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1IntentAsrCorrection> {
    return GoogleCloudDialogflowV3alpha1IntentAsrCorrection;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['correctionPhrase', 'errorPhrase']};
  }
}

export interface GoogleCloudDialogflowV3alpha1IntentConflictDiffParameters {
  displayName?: string|null;
  originalIntentId?: string|null;
  importedIntentId?: string|null;
}
export class GoogleCloudDialogflowV3alpha1IntentConflictDiff extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1IntentConflictDiffParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'originalIntentId',
        (parameters.originalIntentId == null) ? (null) :
                                                (parameters.originalIntentId));
    this.Serializable$set(
        'importedIntentId',
        (parameters.importedIntentId == null) ? (null) :
                                                (parameters.importedIntentId));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Conflicting display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get importedIntentId(): string|null {
    return (
        (this.Serializable$has('importedIntentId')) ?
            (this.Serializable$get('importedIntentId')) :
            (null));
  }

  /**
   * The name of the conflicting intent in the content to be imported. This is a
   * unique identifier. Format: `projects//locations//agents//intents/`.
   */
  set importedIntentId(value: string|null) {
    this.Serializable$set('importedIntentId', value);
  }

  get originalIntentId(): string|null {
    return (
        (this.Serializable$has('originalIntentId')) ?
            (this.Serializable$get('originalIntentId')) :
            (null));
  }

  /**
   * The name of the conflicting intent in the current agent. This is a unique
   * identifier. Format: `projects//locations//agents//intents/`.
   */
  set originalIntentId(value: string|null) {
    this.Serializable$set('originalIntentId', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1IntentConflictDiff> {
    return GoogleCloudDialogflowV3alpha1IntentConflictDiff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'importedIntentId', 'originalIntentId']};
  }
}

export interface GoogleCloudDialogflowV3alpha1IntentInputParameters {
  intent?: string|null;
}
export class GoogleCloudDialogflowV3alpha1IntentInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1IntentInputParameters = {}) {
    super();
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * Required. The unique identifier of the intent. Format:
   * `projects//locations//agents//intents/`.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1IntentInput> {
    return GoogleCloudDialogflowV3alpha1IntentInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intent']};
  }
}

export interface GoogleCloudDialogflowV3alpha1IntentParameterParameters {
  id?: string|null;
  entityType?: string|null;
  isList?: boolean|null;
  redact?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1IntentParameter extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1IntentParameterParameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
    this.Serializable$set(
        'isList', (parameters.isList == null) ? (null) : (parameters.isList));
    this.Serializable$set(
        'redact', (parameters.redact == null) ? (null) : (parameters.redact));
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * Required. The entity type of the parameter. Format:
   * `projects/-/locations/-/agents/-/entityTypes/` for system entity types (for
   * example, `projects/-/locations/-/agents/-/entityTypes/sys.date`), or
   * `projects//locations//agents//entityTypes/` for developer entity types.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Required. The unique identifier of the parameter. This field is used by
   * training phrases to annotate their parts.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get isList(): boolean|null {
    return (
        (this.Serializable$has('isList')) ? (this.Serializable$get('isList')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter represents a list of values.
   */
  set isList(value: boolean|null) {
    this.Serializable$set('isList', value);
  }

  get redact(): boolean|null {
    return (
        (this.Serializable$has('redact')) ? (this.Serializable$get('redact')) :
                                            (null));
  }

  /**
   * Indicates whether the parameter content should be redacted in log. If
   * redaction is enabled, the parameter content will be replaced by parameter
   * name during logging. Note: the parameter content is subject to redaction if
   * either parameter level redaction or entity type level redaction is enabled.
   */
  set redact(value: boolean|null) {
    this.Serializable$set('redact', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1IntentParameter> {
    return GoogleCloudDialogflowV3alpha1IntentParameter;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['entityType', 'id', 'isList', 'redact']};
  }
}

export interface GoogleCloudDialogflowV3alpha1IntentTrainingPhraseParameters {
  id?: string|null;
  parts?: Array<GoogleCloudDialogflowV3alpha1IntentTrainingPhrasePart>|null;
  repeatCount?: number|null;
  languageCode?: string|null;
  unassessed?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1IntentTrainingPhrase extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1IntentTrainingPhraseParameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'parts', (parameters.parts == null) ? (null) : (parameters.parts));
    this.Serializable$set(
        'repeatCount',
        (parameters.repeatCount == null) ? (null) : (parameters.repeatCount));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'unassessed',
        (parameters.unassessed == null) ? (null) : (parameters.unassessed));
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Output only. The unique identifier of the training phrase.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language of the training phrase. In normal cases, we don't need to
   * populate `language_code` as only training phrases of the requested language
   * are returned. However we need to store the training phrases of all
   * languages for YAML exported agent.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get parts():
      Array<GoogleCloudDialogflowV3alpha1IntentTrainingPhrasePart>|null {
    return (
        (this.Serializable$has('parts')) ? (this.Serializable$get('parts')) :
                                           (null));
  }

  /**
   * Required. The ordered list of training phrase parts. The parts are
   * concatenated in order to form the training phrase. Note: The API does not
   * automatically annotate training phrases like the Dialogflow Console does.
   * Note: Do not forget to include whitespace at part boundaries, so the
   * training phrase is well formatted when the parts are concatenated. If the
   * training phrase does not need to be annotated with parameters, you just
   * need a single part with only the Part.text field set. If you want to
   * annotate the training phrase, you must create multiple parts, where the
   * fields of each part are populated in one of two ways: - `Part.text` is set
   * to a part of the phrase that has no parameters. - `Part.text` is set to a
   * part of the phrase that you want to annotate, and the `parameter_id` field
   * is set.
   */
  set parts(value: Array<GoogleCloudDialogflowV3alpha1IntentTrainingPhrasePart>|
            null) {
    this.Serializable$set('parts', value);
  }

  get repeatCount(): number|null {
    return (
        (this.Serializable$has('repeatCount')) ?
            (this.Serializable$get('repeatCount')) :
            (null));
  }

  /**
   * Indicates how many times this example was added to the intent.
   */
  set repeatCount(value: number|null) {
    this.Serializable$set('repeatCount', value);
  }

  get unassessed(): boolean|null {
    return (
        (this.Serializable$has('unassessed')) ?
            (this.Serializable$get('unassessed')) :
            (null));
  }

  /**
   * Indicates whether this training phrase has not been assessed yet.
   */
  set unassessed(value: boolean|null) {
    this.Serializable$set('unassessed', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1IntentTrainingPhrase> {
    return GoogleCloudDialogflowV3alpha1IntentTrainingPhrase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parts': GoogleCloudDialogflowV3alpha1IntentTrainingPhrasePart},
      keys: ['id', 'languageCode', 'parts', 'repeatCount', 'unassessed']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1IntentTrainingPhrasePartParameters {
  text?: string|null;
  parameterId?: string|null;
  auto?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1IntentTrainingPhrasePart extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1IntentTrainingPhrasePartParameters =
              {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'parameterId',
        (parameters.parameterId == null) ? (null) : (parameters.parameterId));
    this.Serializable$set(
        'auto', (parameters.auto == null) ? (null) : (parameters.auto));
  }

  get auto(): boolean|null {
    return (
        (this.Serializable$has('auto')) ? (this.Serializable$get('auto')) :
                                          (null));
  }

  /**
   * Indicates whether the text was auto-annotated. This field is only set to
   * true when the Dialogflow Console auto-annotates the part.
   */
  set auto(value: boolean|null) {
    this.Serializable$set('auto', value);
  }

  get parameterId(): string|null {
    return (
        (this.Serializable$has('parameterId')) ?
            (this.Serializable$get('parameterId')) :
            (null));
  }

  /**
   * The parameter used to annotate this part of the training phrase. This field
   * is required for annotated parts of the training phrase.
   */
  set parameterId(value: string|null) {
    this.Serializable$set('parameterId', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. The text for this part.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1IntentTrainingPhrasePart> {
    return GoogleCloudDialogflowV3alpha1IntentTrainingPhrasePart;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['auto', 'parameterId', 'text']};
  }
}

export interface GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsParameters {
  audioExportGcsDestination?: GoogleCloudDialogflowV3alpha1GcsDestination|null;
  disableLogging?: boolean|null;
  dtmfSettings?:
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsDtmfSettings|
      null;
  speechSettings?:
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsSpeechSettings|
      null;
  loggingSettings?:
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsLoggingSettings|
      null;
}
export class
    GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'audioExportGcsDestination',
        (parameters.audioExportGcsDestination == null) ?
            (null) :
            (parameters.audioExportGcsDestination));
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
    this.Serializable$set(
        'dtmfSettings',
        (parameters.dtmfSettings == null) ? (null) : (parameters.dtmfSettings));
    this.Serializable$set(
        'speechSettings',
        (parameters.speechSettings == null) ? (null) :
                                              (parameters.speechSettings));
    this.Serializable$set(
        'loggingSettings',
        (parameters.loggingSettings == null) ? (null) :
                                               (parameters.loggingSettings));
  }

  get audioExportGcsDestination(): GoogleCloudDialogflowV3alpha1GcsDestination
      |null {
    return (
        (this.Serializable$has('audioExportGcsDestination')) ?
            (this.Serializable$get('audioExportGcsDestination')) :
            (null));
  }

  /**
   * If present, incoming audio is exported by Dialogflow to the configured
   * Google Cloud Storage destination.
   */
  set audioExportGcsDestination(value:
                                    GoogleCloudDialogflowV3alpha1GcsDestination|
                                null) {
    this.Serializable$set('audioExportGcsDestination', value);
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * If true, logging is currently disabled. This setting overrides the agent-
   * level settings for Dialogflow History, Contact Center messages, StackDriver
   * logs, and speech logging. Usually, this is configured just for specific
   * pages in the agent (e.g. to temporarily disable logging for a sensitive
   * operation).
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  get dtmfSettings():
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsDtmfSettings
      |null {
    return (
        (this.Serializable$has('dtmfSettings')) ?
            (this.Serializable$get('dtmfSettings')) :
            (null));
  }

  /**
   * Settings for DTMF.
   */
  set dtmfSettings(
      value:
          GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsDtmfSettings|
      null) {
    this.Serializable$set('dtmfSettings', value);
  }

  get loggingSettings():
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsLoggingSettings
      |null {
    return (
        (this.Serializable$has('loggingSettings')) ?
            (this.Serializable$get('loggingSettings')) :
            (null));
  }

  /**
   * Settings for logging.
   */
  set loggingSettings(
      value:
          GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsLoggingSettings|
      null) {
    this.Serializable$set('loggingSettings', value);
  }

  get speechSettings():
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsSpeechSettings
      |null {
    return (
        (this.Serializable$has('speechSettings')) ?
            (this.Serializable$get('speechSettings')) :
            (null));
  }

  /**
   * Settings for speech to text detection.
   */
  set speechSettings(
      value:
          GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsSpeechSettings|
      null) {
    this.Serializable$set('speechSettings', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettings> {
    return GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'audioExportGcsDestination', 'disableLogging', 'dtmfSettings',
        'loggingSettings', 'speechSettings'
      ],
      objects: {
        'audioExportGcsDestination':
            GoogleCloudDialogflowV3alpha1GcsDestination,
        'dtmfSettings':
            GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsDtmfSettings,
        'loggingSettings':
            GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsLoggingSettings,
        'speechSettings':
            GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsSpeechSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsDtmfSettingsParameters {
  enabled?: boolean|null;
  parameter?: string|null;
  maxDigits?: number|null;
  finishDigit?: string|null;
}
export class
    GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsDtmfSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsDtmfSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
    this.Serializable$set(
        'maxDigits',
        (parameters.maxDigits == null) ? (null) : (parameters.maxDigits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * If true, incoming audio is processed for DTMF (dual tone multi frequency)
   * events. For example, if the caller presses a button on their telephone
   * keypad and DTMF processing is enabled, Dialogflow will detect the event
   * (e.g. a \"3\" was pressed) in the incoming audio and pass the event to the
   * bot to drive business logic (e.g. when 3 is pressed, return the account
   * balance).
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get finishDigit(): string|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * The digit that terminates a DTMF digit sequence.
   */
  set finishDigit(value: string|null) {
    this.Serializable$set('finishDigit', value);
  }

  get maxDigits(): number|null {
    return (
        (this.Serializable$has('maxDigits')) ?
            (this.Serializable$get('maxDigits')) :
            (null));
  }

  /**
   * Max length of DTMF digits.
   */
  set maxDigits(value: number|null) {
    this.Serializable$set('maxDigits', value);
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * If not empty, the transcribed DTMF digits will be set into the value of the
   * specified slot parameter.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsDtmfSettings> {
    return GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsDtmfSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enabled', 'finishDigit', 'maxDigits', 'parameter']};
  }
}

export interface GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsLoggingSettingsParameters {
  disableLogging?: boolean|null;
}
export class
    GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsLoggingSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsLoggingSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * If true, logging is currently disabled. This setting overrides the agent-
   * level settings for Dialogflow History, Contact Center messages, StackDriver
   * logs, and speech logging. Usually, this is configured just for specific
   * pages in the agent (e.g. to temporarily disable logging for a sensitive
   * operation).
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsLoggingSettings> {
    return GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsLoggingSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['disableLogging']};
  }
}

export interface GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsSpeechSettingsParameters {
  endpointerSensitivity?: number|null;
  noSpeechTimeout?: string|null;
  useTimeoutBasedEndpointing?: boolean|null;
}
export class
    GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsSpeechSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsSpeechSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'endpointerSensitivity',
        (parameters.endpointerSensitivity == null) ?
            (null) :
            (parameters.endpointerSensitivity));
    this.Serializable$set(
        'noSpeechTimeout',
        (parameters.noSpeechTimeout == null) ? (null) :
                                               (parameters.noSpeechTimeout));
    this.Serializable$set(
        'useTimeoutBasedEndpointing',
        (parameters.useTimeoutBasedEndpointing == null) ?
            (null) :
            (parameters.useTimeoutBasedEndpointing));
  }

  get endpointerSensitivity(): number|null {
    return (
        (this.Serializable$has('endpointerSensitivity')) ?
            (this.Serializable$get('endpointerSensitivity')) :
            (null));
  }

  /**
   * Sensitivity of the speech model that detects the end of speech. Scale from
   * 0 to 100.
   */
  set endpointerSensitivity(value: number|null) {
    this.Serializable$set('endpointerSensitivity', value);
  }

  get noSpeechTimeout(): string|null {
    return (
        (this.Serializable$has('noSpeechTimeout')) ?
            (this.Serializable$get('noSpeechTimeout')) :
            (null));
  }

  /**
   * Timeout before detecting no speech.
   */
  set noSpeechTimeout(value: string|null) {
    this.Serializable$set('noSpeechTimeout', value);
  }

  get useTimeoutBasedEndpointing(): boolean|null {
    return (
        (this.Serializable$has('useTimeoutBasedEndpointing')) ?
            (this.Serializable$get('useTimeoutBasedEndpointing')) :
            (null));
  }

  /**
   * Use timeout based endpointing, interpreting endpointer sensitivy as seconds
   * of timeout value.
   */
  set useTimeoutBasedEndpointing(value: boolean|null) {
    this.Serializable$set('useTimeoutBasedEndpointing', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsSpeechSettings> {
    return GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettingsSpeechSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'endpointerSensitivity', 'noSpeechTimeout', 'useTimeoutBasedEndpointing'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1KnowledgeConnectorAnswerParameters {
  source?: string|null;
  faqQuestion?: string|null;
  answer?: string|null;
  matchConfidence?: number|null;
}
export class GoogleCloudDialogflowV3alpha1KnowledgeConnectorAnswer extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1KnowledgeConnectorAnswerParameters =
              {}) {
    super();
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'faqQuestion',
        (parameters.faqQuestion == null) ? (null) : (parameters.faqQuestion));
    this.Serializable$set(
        'answer', (parameters.answer == null) ? (null) : (parameters.answer));
    this.Serializable$set(
        'matchConfidence',
        (parameters.matchConfidence == null) ? (null) :
                                               (parameters.matchConfidence));
  }

  get answer(): string|null {
    return (
        (this.Serializable$has('answer')) ? (this.Serializable$get('answer')) :
                                            (null));
  }

  /**
   * The piece of text from the `source` knowledge base document that answers
   * this conversational query.
   */
  set answer(value: string|null) {
    this.Serializable$set('answer', value);
  }

  get faqQuestion(): string|null {
    return (
        (this.Serializable$has('faqQuestion')) ?
            (this.Serializable$get('faqQuestion')) :
            (null));
  }

  /**
   * The corresponding FAQ question if the answer was extracted from a FAQ
   * Document, empty otherwise.
   */
  set faqQuestion(value: string|null) {
    this.Serializable$set('faqQuestion', value);
  }

  get matchConfidence(): number|null {
    return (
        (this.Serializable$has('matchConfidence')) ?
            (this.Serializable$get('matchConfidence')) :
            (null));
  }

  /**
   * The system's confidence score that this Knowledge answer is a good match
   * for this conversational query. The range is from 0.0 (completely uncertain)
   * to 1.0 (completely certain).
   */
  set matchConfidence(value: number|null) {
    this.Serializable$set('matchConfidence', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * Indicates which Knowledge Document this answer was extracted from. Format:
   * `projects//knowledgeBases//documents/`.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1KnowledgeConnectorAnswer> {
    return GoogleCloudDialogflowV3alpha1KnowledgeConnectorAnswer;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['answer', 'faqQuestion', 'matchConfidence', 'source']};
  }
}

export interface GoogleCloudDialogflowV3alpha1KnowledgeConnectorSettingsParameters {
  enabled?: boolean|null;
  knowledgeBaseIds?: Array<string>|null;
  triggerFulfillment?: GoogleCloudDialogflowV3alpha1Fulfillment|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowV3alpha1KnowledgeConnectorSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1KnowledgeConnectorSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'enabled',
        (parameters.enabled == null) ? (null) : (parameters.enabled));
    this.Serializable$set(
        'knowledgeBaseIds',
        (parameters.knowledgeBaseIds == null) ? (null) :
                                                (parameters.knowledgeBaseIds));
    this.Serializable$set(
        'triggerFulfillment',
        (parameters.triggerFulfillment == null) ?
            (null) :
            (parameters.triggerFulfillment));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get enabled(): boolean|null {
    return (
        (this.Serializable$has('enabled')) ?
            (this.Serializable$get('enabled')) :
            (null));
  }

  /**
   * Whether Knowledge Connector is enabled for this page. Users might want to
   * keep configurations the same (which Knowledge Bases are attached to the
   * page, how fulfillment should work, etc.), but temporarily disable Knowledge
   * Connector functionality.
   */
  set enabled(value: boolean|null) {
    this.Serializable$set('enabled', value);
  }

  get knowledgeBaseIds(): Array<string>|null {
    return (
        (this.Serializable$has('knowledgeBaseIds')) ?
            (this.Serializable$get('knowledgeBaseIds')) :
            (null));
  }

  /**
   * The list of knowledge bases attached to this page. The entries are the full
   * names of Knowledge Bases. Format: `projects//locations//knowledgeBases/`
   */
  set knowledgeBaseIds(value: Array<string>|null) {
    this.Serializable$set('knowledgeBaseIds', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  get triggerFulfillment(): GoogleCloudDialogflowV3alpha1Fulfillment|null {
    return (
        (this.Serializable$has('triggerFulfillment')) ?
            (this.Serializable$get('triggerFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to be triggered, in case the Knowledge Base answers are the
   * winners of this conversation turn. The fulfillment should make use of the
   * request scoped parameter `$request.knowledge.answer`, containing up to the
   * 5 highest confidence answers, as well as `$request.knowledge.question`,
   * containing the corresponding questions.
   */
  set triggerFulfillment(value: GoogleCloudDialogflowV3alpha1Fulfillment|null) {
    this.Serializable$set('triggerFulfillment', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1KnowledgeConnectorSettings> {
    return GoogleCloudDialogflowV3alpha1KnowledgeConnectorSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'enabled', 'knowledgeBaseIds', 'targetFlow', 'targetPage',
        'triggerFulfillment'
      ],
      objects: {'triggerFulfillment': GoogleCloudDialogflowV3alpha1Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsParameters {
  primaryInstance?:
      GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsInstance|
      null;
}
export class
    GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettings extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'primaryInstance',
        (parameters.primaryInstance == null) ? (null) :
                                               (parameters.primaryInstance));
  }

  get primaryInstance():
      GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsInstance
      |null {
    return (
        (this.Serializable$has('primaryInstance')) ?
            (this.Serializable$get('primaryInstance')) :
            (null));
  }

  /**
   * Primary instance settings.
   */
  set primaryInstance(
      value:
          GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsInstance|
      null) {
    this.Serializable$set('primaryInstance', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettings> {
    return GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['primaryInstance'],
      objects: {
        'primaryInstance':
            GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsInstance
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsInstanceParameters {
  name?: string|null;
  username?: string|null;
}
export class
    GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsInstance extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsInstanceParameters =
              {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'username',
        (parameters.username == null) ? (null) : (parameters.username));
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Format: `::`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get username(): string|null {
    return (
        (this.Serializable$has('username')) ?
            (this.Serializable$get('username')) :
            (null));
  }

  /**
   * Username of MySQL database.
   */
  set username(value: string|null) {
    this.Serializable$set('username', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsInstance> {
    return GoogleCloudDialogflowV3alpha1LocationSettingsCloudSqlSettingsInstance;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['name', 'username']};
  }
}

export interface GoogleCloudDialogflowV3alpha1MatchParameters {
  intent?: GoogleCloudDialogflowV3alpha1Intent|null;
  event?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
  parametersOriginalValues?: ApiClientObjectMap<any>|null;
  resolvedInput?: string|null;
  matchType?: GoogleCloudDialogflowV3alpha1MatchMatchType|null;
  confidence?: number|null;
  modelType?: GoogleCloudDialogflowV3alpha1MatchModelType|null;
  knowledgeConnectorAnswer?:
      Array<GoogleCloudDialogflowV3alpha1KnowledgeConnectorAnswer>|null;
}
export class GoogleCloudDialogflowV3alpha1Match extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV3alpha1MatchParameters = {}) {
    super();
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'parametersOriginalValues',
        (parameters.parametersOriginalValues == null) ?
            (null) :
            (parameters.parametersOriginalValues));
    this.Serializable$set(
        'resolvedInput',
        (parameters.resolvedInput == null) ? (null) :
                                             (parameters.resolvedInput));
    this.Serializable$set(
        'matchType',
        (parameters.matchType == null) ? (null) : (parameters.matchType));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'modelType',
        (parameters.modelType == null) ? (null) : (parameters.modelType));
    this.Serializable$set(
        'knowledgeConnectorAnswer',
        (parameters.knowledgeConnectorAnswer == null) ?
            (null) :
            (parameters.knowledgeConnectorAnswer));
  }

  static get MatchType(): IGoogleCloudDialogflowV3alpha1MatchMatchTypeEnum {
    return GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum;
  }

  static get ModelType(): IGoogleCloudDialogflowV3alpha1MatchModelTypeEnum {
    return GoogleCloudDialogflowV3alpha1MatchModelTypeEnum;
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The confidence of this match. Values range from 0.0 (completely uncertain)
   * to 1.0 (completely certain). This value is for informational purpose only
   * and is only used to help match the best intent within the classification
   * threshold. This value may change for the same end-user expression at any
   * time due to a model retraining or change in implementation.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get event(): string|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * The event that matched the query. Filled for `EVENT`, `NO_MATCH` and
   * `NO_INPUT` match types.
   */
  set event(value: string|null) {
    this.Serializable$set('event', value);
  }

  get intent(): GoogleCloudDialogflowV3alpha1Intent|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The Intent that matched the query. Some, not all fields are filled in this
   * message, including but not limited to: `name` and `display_name`. Only
   * filled for `INTENT` match type.
   */
  set intent(value: GoogleCloudDialogflowV3alpha1Intent|null) {
    this.Serializable$set('intent', value);
  }

  get knowledgeConnectorAnswer():
      Array<GoogleCloudDialogflowV3alpha1KnowledgeConnectorAnswer>|null {
    return (
        (this.Serializable$has('knowledgeConnectorAnswer')) ?
            (this.Serializable$get('knowledgeConnectorAnswer')) :
            (null));
  }

  /**
   * The Knowledge Connector answers, in case this match is of that type.
   */
  set knowledgeConnectorAnswer(
      value: Array<GoogleCloudDialogflowV3alpha1KnowledgeConnectorAnswer>|
      null) {
    this.Serializable$set('knowledgeConnectorAnswer', value);
  }

  get matchType(): GoogleCloudDialogflowV3alpha1MatchMatchType|null {
    return (
        (this.Serializable$has('matchType')) ?
            (this.Serializable$get('matchType')) :
            (null));
  }

  /**
   * Type of this Match.
   */
  set matchType(value: GoogleCloudDialogflowV3alpha1MatchMatchType|null) {
    this.Serializable$set('matchType', value);
  }

  get modelType(): GoogleCloudDialogflowV3alpha1MatchModelType|null {
    return (
        (this.Serializable$has('modelType')) ?
            (this.Serializable$get('modelType')) :
            (null));
  }

  /**
   * Indicate which type of NLU model was queried, internal for billing purpose.
   */
  set modelType(value: GoogleCloudDialogflowV3alpha1MatchModelType|null) {
    this.Serializable$set('modelType', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of parameters extracted from the query. Depending on your
   * protocol or client library language, this is a map, associative array,
   * symbol table, dictionary, or JSON object composed of a collection of
   * (MapKey, MapValue) pairs: - MapKey type: string - MapKey value: parameter
   * name - MapValue type: - If parameter's entity type is a composite entity:
   * map - Else: depending on parameter value type, could be one of string,
   * number, boolean, null, list or map - MapValue value: - If parameter's
   * entity type is a composite entity: map from composite entity property names
   * to property values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  get parametersOriginalValues(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parametersOriginalValues')) ?
            (this.Serializable$get('parametersOriginalValues')) :
            (null));
  }

  /**
   * The collection of parameters with original values extracted from the query.
   * Similar to parameters, except that parameters carries the resolved values
   * for parameters according to the entity types, while this field carries the
   * raw values in the text query. Internal for text input parameters redaction
   * only.
   */
  set parametersOriginalValues(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parametersOriginalValues', value);
  }

  get resolvedInput(): string|null {
    return (
        (this.Serializable$has('resolvedInput')) ?
            (this.Serializable$get('resolvedInput')) :
            (null));
  }

  /**
   * Final text input which was matched during MatchIntent. This value can be
   * different from original input sent in request because of spelling
   * correction or other processing.
   */
  set resolvedInput(value: string|null) {
    this.Serializable$set('resolvedInput', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1Match> {
    return GoogleCloudDialogflowV3alpha1Match;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'knowledgeConnectorAnswer':
            GoogleCloudDialogflowV3alpha1KnowledgeConnectorAnswer
      },
      enums: {
        'matchType': GoogleCloudDialogflowV3alpha1MatchMatchTypeEnum,
        'modelType': GoogleCloudDialogflowV3alpha1MatchModelTypeEnum
      },
      keys: [
        'confidence', 'event', 'intent', 'knowledgeConnectorAnswer',
        'matchType', 'modelType', 'parameters', 'parametersOriginalValues',
        'resolvedInput'
      ],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'parametersOriginalValues': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'intent': GoogleCloudDialogflowV3alpha1Intent}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1MatchClarificationParameters {
  matches?: Array<GoogleCloudDialogflowV3alpha1Match>|null;
}
export class GoogleCloudDialogflowV3alpha1MatchClarification extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1MatchClarificationParameters = {}) {
    super();
    this.Serializable$set(
        'matches',
        (parameters.matches == null) ? (null) : (parameters.matches));
  }

  get matches(): Array<GoogleCloudDialogflowV3alpha1Match>|null {
    return (
        (this.Serializable$has('matches')) ?
            (this.Serializable$get('matches')) :
            (null));
  }

  /**
   * A list of matched intents that need to be disambiguated by either the
   * end-user or the webhook.
   */
  set matches(value: Array<GoogleCloudDialogflowV3alpha1Match>|null) {
    this.Serializable$set('matches', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1MatchClarification> {
    return GoogleCloudDialogflowV3alpha1MatchClarification;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'matches': GoogleCloudDialogflowV3alpha1Match},
      keys: ['matches']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1PageParameters {
  name?: string|null;
  displayName?: string|null;
  entryFulfillment?: GoogleCloudDialogflowV3alpha1Fulfillment|null;
  form?: GoogleCloudDialogflowV3alpha1Form|null;
  transitionRouteGroups?: Array<string>|null;
  transitionRoutes?: Array<GoogleCloudDialogflowV3alpha1TransitionRoute>|null;
  eventHandlers?: Array<GoogleCloudDialogflowV3alpha1EventHandler>|null;
  advancedSettings?: GoogleCloudDialogflowV3alpha1AdvancedSettings|null;
  speakerIdSettings?: GoogleCloudDialogflowV3alpha1SpeakerIdSettings|null;
  etag?: string|null;
  flow?: string|null;
  knowledgeConnectorSettings?:
      GoogleCloudDialogflowV3alpha1KnowledgeConnectorSettings|null;
}
export class GoogleCloudDialogflowV3alpha1Page extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV3alpha1PageParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'entryFulfillment',
        (parameters.entryFulfillment == null) ? (null) :
                                                (parameters.entryFulfillment));
    this.Serializable$set(
        'form', (parameters.form == null) ? (null) : (parameters.form));
    this.Serializable$set(
        'transitionRouteGroups',
        (parameters.transitionRouteGroups == null) ?
            (null) :
            (parameters.transitionRouteGroups));
    this.Serializable$set(
        'transitionRoutes',
        (parameters.transitionRoutes == null) ? (null) :
                                                (parameters.transitionRoutes));
    this.Serializable$set(
        'eventHandlers',
        (parameters.eventHandlers == null) ? (null) :
                                             (parameters.eventHandlers));
    this.Serializable$set(
        'advancedSettings',
        (parameters.advancedSettings == null) ? (null) :
                                                (parameters.advancedSettings));
    this.Serializable$set(
        'speakerIdSettings',
        (parameters.speakerIdSettings == null) ?
            (null) :
            (parameters.speakerIdSettings));
    this.Serializable$set(
        'etag', (parameters.etag == null) ? (null) : (parameters.etag));
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
    this.Serializable$set(
        'knowledgeConnectorSettings',
        (parameters.knowledgeConnectorSettings == null) ?
            (null) :
            (parameters.knowledgeConnectorSettings));
  }

  get advancedSettings(): GoogleCloudDialogflowV3alpha1AdvancedSettings|null {
    return (
        (this.Serializable$has('advancedSettings')) ?
            (this.Serializable$get('advancedSettings')) :
            (null));
  }

  /**
   * Hierarchical advanced settings for this page. The settings exposed at the
   * lower level overrides the settings exposed at the higher level.
   */
  set advancedSettings(value: GoogleCloudDialogflowV3alpha1AdvancedSettings|
                       null) {
    this.Serializable$set('advancedSettings', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the page, unique within the flow.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get entryFulfillment(): GoogleCloudDialogflowV3alpha1Fulfillment|null {
    return (
        (this.Serializable$has('entryFulfillment')) ?
            (this.Serializable$get('entryFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to call when the session is entering the page.
   */
  set entryFulfillment(value: GoogleCloudDialogflowV3alpha1Fulfillment|null) {
    this.Serializable$set('entryFulfillment', value);
  }

  get etag(): string|null {
    return (
        (this.Serializable$has('etag')) ? (this.Serializable$get('etag')) :
                                          (null));
  }

  /**
   * Etag of the page, may be sent on update requests to ensure the client has
   * an up-to-date value before proceeding.
   */
  set etag(value: string|null) {
    this.Serializable$set('etag', value);
  }

  get eventHandlers(): Array<GoogleCloudDialogflowV3alpha1EventHandler>|null {
    return (
        (this.Serializable$has('eventHandlers')) ?
            (this.Serializable$get('eventHandlers')) :
            (null));
  }

  /**
   * Handlers associated with the page to handle events such as webhook errors,
   * no match or no input.
   */
  set eventHandlers(value: Array<GoogleCloudDialogflowV3alpha1EventHandler>|
                    null) {
    this.Serializable$set('eventHandlers', value);
  }

  get flow(): string|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * Output only. Only populated for agent export and import to keep flow name
   * along with page name, so that they can be converted to display name and
   * retain all the info.
   */
  set flow(value: string|null) {
    this.Serializable$set('flow', value);
  }

  get form(): GoogleCloudDialogflowV3alpha1Form|null {
    return (
        (this.Serializable$has('form')) ? (this.Serializable$get('form')) :
                                          (null));
  }

  /**
   * The form associated with the page, used for collecting parameters relevant
   * to the page.
   */
  set form(value: GoogleCloudDialogflowV3alpha1Form|null) {
    this.Serializable$set('form', value);
  }

  get knowledgeConnectorSettings():
      GoogleCloudDialogflowV3alpha1KnowledgeConnectorSettings|null {
    return (
        (this.Serializable$has('knowledgeConnectorSettings')) ?
            (this.Serializable$get('knowledgeConnectorSettings')) :
            (null));
  }

  /**
   * Should only be set in case this is Page is connected to Knowledge Bases.
   */
  set knowledgeConnectorSettings(
      value: GoogleCloudDialogflowV3alpha1KnowledgeConnectorSettings|null) {
    this.Serializable$set('knowledgeConnectorSettings', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the page. Required for the Pages.UpdatePage
   * method. Pages.CreatePage populates the name automatically. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get speakerIdSettings(): GoogleCloudDialogflowV3alpha1SpeakerIdSettings|null {
    return (
        (this.Serializable$has('speakerIdSettings')) ?
            (this.Serializable$get('speakerIdSettings')) :
            (null));
  }

  /**
   * The Speaker ID settings for this page.
   */
  set speakerIdSettings(value: GoogleCloudDialogflowV3alpha1SpeakerIdSettings|
                        null) {
    this.Serializable$set('speakerIdSettings', value);
  }

  get transitionRouteGroups(): Array<string>|null {
    return (
        (this.Serializable$has('transitionRouteGroups')) ?
            (this.Serializable$get('transitionRouteGroups')) :
            (null));
  }

  /**
   * Ordered list of `TransitionRouteGroups` associated with the page.
   * Transition route groups must be unique within a page. * If multiple
   * transition routes within a page scope refer to the same intent, then the
   * precedence order is: page's transition route -> page's transition route
   * group -> flow's transition routes. * If multiple transition route groups
   * within a page contain the same intent, then the first group in the ordered
   * list takes precedence.
   * Format:`projects//locations//agents//flows//transitionRouteGroups/`.
   */
  set transitionRouteGroups(value: Array<string>|null) {
    this.Serializable$set('transitionRouteGroups', value);
  }

  get transitionRoutes():
      Array<GoogleCloudDialogflowV3alpha1TransitionRoute>|null {
    return (
        (this.Serializable$has('transitionRoutes')) ?
            (this.Serializable$get('transitionRoutes')) :
            (null));
  }

  /**
   * A list of transitions for the transition rules of this page. They route the
   * conversation to another page in the same flow, or another flow. When we are
   * in a certain page, the TransitionRoutes are evalauted in the following
   * order: * TransitionRoutes defined in the page with intent specified. *
   * TransitionRoutes defined in the transition route groups with intent
   * specified. * TransitionRoutes defined in flow with intent specified. *
   * TransitionRoutes defined in the transition route groups with intent
   * specified. * TransitionRoutes defined in the page with only condition
   * specified. * TransitionRoutes defined in the transition route groups with
   * only condition specified.
   */
  set transitionRoutes(value:
                           Array<GoogleCloudDialogflowV3alpha1TransitionRoute>|
                       null) {
    this.Serializable$set('transitionRoutes', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1Page> {
    return GoogleCloudDialogflowV3alpha1Page;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'eventHandlers': GoogleCloudDialogflowV3alpha1EventHandler,
        'transitionRoutes': GoogleCloudDialogflowV3alpha1TransitionRoute
      },
      keys: [
        'advancedSettings', 'displayName', 'entryFulfillment', 'etag',
        'eventHandlers', 'flow', 'form', 'knowledgeConnectorSettings', 'name',
        'speakerIdSettings', 'transitionRouteGroups', 'transitionRoutes'
      ],
      objects: {
        'advancedSettings': GoogleCloudDialogflowV3alpha1AdvancedSettings,
        'entryFulfillment': GoogleCloudDialogflowV3alpha1Fulfillment,
        'form': GoogleCloudDialogflowV3alpha1Form,
        'knowledgeConnectorSettings':
            GoogleCloudDialogflowV3alpha1KnowledgeConnectorSettings,
        'speakerIdSettings': GoogleCloudDialogflowV3alpha1SpeakerIdSettings
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1PageInfoParameters {
  currentPage?: string|null;
  displayName?: string|null;
  nextPage?: string|null;
  formInfo?: GoogleCloudDialogflowV3alpha1PageInfoFormInfo|null;
}
export class GoogleCloudDialogflowV3alpha1PageInfo extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1PageInfoParameters = {}) {
    super();
    this.Serializable$set(
        'currentPage',
        (parameters.currentPage == null) ? (null) : (parameters.currentPage));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'nextPage',
        (parameters.nextPage == null) ? (null) : (parameters.nextPage));
    this.Serializable$set(
        'formInfo',
        (parameters.formInfo == null) ? (null) : (parameters.formInfo));
  }

  get currentPage(): string|null {
    return (
        (this.Serializable$has('currentPage')) ?
            (this.Serializable$get('currentPage')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Ignored for WebhookResponse. The unique
   * identifier of the current page. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set currentPage(value: string|null) {
    this.Serializable$set('currentPage', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Ignored for WebhookResponse. The display
   * name of the current page.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get formInfo(): GoogleCloudDialogflowV3alpha1PageInfoFormInfo|null {
    return (
        (this.Serializable$has('formInfo')) ?
            (this.Serializable$get('formInfo')) :
            (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. Information about the
   * form.
   */
  set formInfo(value: GoogleCloudDialogflowV3alpha1PageInfoFormInfo|null) {
    this.Serializable$set('formInfo', value);
  }

  get nextPage(): string|null {
    return (
        (this.Serializable$has('nextPage')) ?
            (this.Serializable$get('nextPage')) :
            (null));
  }

  /**
   * Deprecated. Please use WebhookResponse.target_page or
   * WebhookResponse.target_flow instead. Optional for WebhookResponse. The
   * unique identifier of the next page. This field can be set by the webhook to
   * immediately transition to a page different from `current_page`. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set nextPage(value: string|null) {
    this.Serializable$set('nextPage', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1PageInfo> {
    return GoogleCloudDialogflowV3alpha1PageInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['currentPage', 'displayName', 'formInfo', 'nextPage'],
      objects: {'formInfo': GoogleCloudDialogflowV3alpha1PageInfoFormInfo}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameters {
  state?: GoogleCloudDialogflowV3alpha1PageInfoFormInfoState|null;
  parameterInfo?:
      Array<GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfo>|null;
}
export class GoogleCloudDialogflowV3alpha1PageInfoFormInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameters = {}) {
    super();
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'parameterInfo',
        (parameters.parameterInfo == null) ? (null) :
                                             (parameters.parameterInfo));
  }

  static get State(): IGoogleCloudDialogflowV3alpha1PageInfoFormInfoStateEnum {
    return GoogleCloudDialogflowV3alpha1PageInfoFormInfoStateEnum;
  }

  get parameterInfo():
      Array<GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfo>|null {
    return (
        (this.Serializable$has('parameterInfo')) ?
            (this.Serializable$get('parameterInfo')) :
            (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. The parameters
   * contained in the form. Note that the webhook cannot add or remove any form
   * parameter.
   */
  set parameterInfo(
      value: Array<GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfo>|
      null) {
    this.Serializable$set('parameterInfo', value);
  }

  get state(): GoogleCloudDialogflowV3alpha1PageInfoFormInfoState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Always present for WebhookRequest. Ignored for WebhookResponse. The current
   * state of the form.
   */
  set state(value: GoogleCloudDialogflowV3alpha1PageInfoFormInfoState|null) {
    this.Serializable$set('state', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1PageInfoFormInfo> {
    return GoogleCloudDialogflowV3alpha1PageInfoFormInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'parameterInfo':
            GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfo
      },
      enums: {'state': GoogleCloudDialogflowV3alpha1PageInfoFormInfoStateEnum},
      keys: ['parameterInfo', 'state']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoParameters {
  displayName?: string|null;
  required?: boolean|null;
  state?: GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState|null;
  value?: any|null;
  justCollected?: boolean|null;
  prompt?: Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|null;
}
export class GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'required',
        (parameters.required == null) ? (null) : (parameters.required));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
    this.Serializable$set(
        'justCollected',
        (parameters.justCollected == null) ? (null) :
                                             (parameters.justCollected));
    this.Serializable$set(
        'prompt', (parameters.prompt == null) ? (null) : (parameters.prompt));
  }

  static get State():
      IGoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoStateEnum {
    return GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoStateEnum;
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Required for WebhookResponse. The
   * human-readable name of the parameter, unique within the form. This field
   * cannot be modified by the webhook.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get justCollected(): boolean|null {
    return (
        (this.Serializable$has('justCollected')) ?
            (this.Serializable$get('justCollected')) :
            (null));
  }

  /**
   * Optional for WebhookRequest. Ignored for WebhookResponse. Indicates if the
   * parameter value was just collected on the last conversation turn.
   */
  set justCollected(value: boolean|null) {
    this.Serializable$set('justCollected', value);
  }

  get prompt(): Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|null {
    return (
        (this.Serializable$has('prompt')) ? (this.Serializable$get('prompt')) :
                                            (null));
  }

  /**
   * Deprecated. This field has no effect. Not set for WebhookRequest. Optional
   * for WebhookResponse. The prompt to send to the user to fill a required form
   * parameter. This field can be set by the webhook. If set, this field
   * overrides the prompt defined for the form parameter.
   */
  set prompt(value: Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|null) {
    this.Serializable$set('prompt', value);
  }

  get required(): boolean|null {
    return (
        (this.Serializable$has('required')) ?
            (this.Serializable$get('required')) :
            (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. Indicates whether the
   * parameter is required. Optional parameters will not trigger prompts;
   * however, they are filled if the user specifies them. Required parameters
   * must be filled before form filling concludes.
   */
  set required(value: boolean|null) {
    this.Serializable$set('required', value);
  }

  get state(): GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState
      |null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Always present for WebhookRequest. Required for WebhookResponse. The state
   * of the parameter. This field can be set to INVALID by the webhook to
   * invalidate the parameter; other values set by the webhook will be ignored.
   */
  set state(value:
                GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoState|
            null) {
    this.Serializable$set('state', value);
  }

  get value(): any|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Optional for both WebhookRequest and WebhookResponse. The value of the
   * parameter. This field can be set by the webhook to change the parameter
   * value.
   */
  set value(value: any|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfo> {
    return GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'prompt': GoogleCloudDialogflowV3alpha1ResponseMessage},
      enums: {
        'state':
            GoogleCloudDialogflowV3alpha1PageInfoFormInfoParameterInfoStateEnum
      },
      keys: [
        'displayName', 'justCollected', 'prompt', 'required', 'state', 'value'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ParameterDeclarationParameters {
  parameter?: string|null;
}
export class GoogleCloudDialogflowV3alpha1ParameterDeclaration extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ParameterDeclarationParameters = {}) {
    super();
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * The name of the session parameter. Can contain characters [A-Z], [a-z],
   * [0-9], ., -, _.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ParameterDeclaration> {
    return GoogleCloudDialogflowV3alpha1ParameterDeclaration;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['parameter']};
  }
}

export interface GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettingsParameters {
  allowPlaybackInterruption?: boolean|null;
  allowCancellation?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettingsParameters =
              {}) {
    super();
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
    this.Serializable$set(
        'allowCancellation',
        (parameters.allowCancellation == null) ?
            (null) :
            (parameters.allowCancellation));
  }

  get allowCancellation(): boolean|null {
    return (
        (this.Serializable$has('allowCancellation')) ?
            (this.Serializable$get('allowCancellation')) :
            (null));
  }

  /**
   * Whether the partial response message should be cancelled when a later
   * response arrives. e.g. if the agent returns some music as partial response,
   * you may consider configuring it as cancellable.
   */
  set allowCancellation(value: boolean|null) {
    this.Serializable$set('allowCancellation', value);
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Whether interruption on the playback of a message is allowed.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings> {
    return GoogleCloudDialogflowV3alpha1PlaybackInterruptionSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowCancellation', 'allowPlaybackInterruption']};
  }
}

export interface GoogleCloudDialogflowV3alpha1QueryInputParameters {
  text?: GoogleCloudDialogflowV3alpha1TextInput|null;
  intent?: GoogleCloudDialogflowV3alpha1IntentInput|null;
  audio?: GoogleCloudDialogflowV3alpha1AudioInput|null;
  event?: GoogleCloudDialogflowV3alpha1EventInput|null;
  dtmf?: GoogleCloudDialogflowV3alpha1DtmfInput|null;
  speechTranscripts?: GoogleCloudDialogflowV3alpha1TranscriptInput|null;
  languageCode?: string|null;
}
export class GoogleCloudDialogflowV3alpha1QueryInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1QueryInputParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
    this.Serializable$set(
        'dtmf', (parameters.dtmf == null) ? (null) : (parameters.dtmf));
    this.Serializable$set(
        'speechTranscripts',
        (parameters.speechTranscripts == null) ?
            (null) :
            (parameters.speechTranscripts));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get audio(): GoogleCloudDialogflowV3alpha1AudioInput|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * The natural language speech audio to be processed.
   */
  set audio(value: GoogleCloudDialogflowV3alpha1AudioInput|null) {
    this.Serializable$set('audio', value);
  }

  get dtmf(): GoogleCloudDialogflowV3alpha1DtmfInput|null {
    return (
        (this.Serializable$has('dtmf')) ? (this.Serializable$get('dtmf')) :
                                          (null));
  }

  /**
   * The DTMF event to be handled.
   */
  set dtmf(value: GoogleCloudDialogflowV3alpha1DtmfInput|null) {
    this.Serializable$set('dtmf', value);
  }

  get event(): GoogleCloudDialogflowV3alpha1EventInput|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * The event to be triggered.
   */
  set event(value: GoogleCloudDialogflowV3alpha1EventInput|null) {
    this.Serializable$set('event', value);
  }

  get intent(): GoogleCloudDialogflowV3alpha1IntentInput|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The intent to be triggered.
   */
  set intent(value: GoogleCloudDialogflowV3alpha1IntentInput|null) {
    this.Serializable$set('intent', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of the input. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes. Note that queries in the
   * same session do not necessarily need to specify the same language.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get speechTranscripts(): GoogleCloudDialogflowV3alpha1TranscriptInput|null {
    return (
        (this.Serializable$has('speechTranscripts')) ?
            (this.Serializable$get('speechTranscripts')) :
            (null));
  }

  set speechTranscripts(value: GoogleCloudDialogflowV3alpha1TranscriptInput|
                        null) {
    this.Serializable$set('speechTranscripts', value);
  }

  get text(): GoogleCloudDialogflowV3alpha1TextInput|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The natural language text to be processed.
   */
  set text(value: GoogleCloudDialogflowV3alpha1TextInput|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1QueryInput> {
    return GoogleCloudDialogflowV3alpha1QueryInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'audio', 'dtmf', 'event', 'intent', 'languageCode', 'speechTranscripts',
        'text'
      ],
      objects: {
        'audio': GoogleCloudDialogflowV3alpha1AudioInput,
        'dtmf': GoogleCloudDialogflowV3alpha1DtmfInput,
        'event': GoogleCloudDialogflowV3alpha1EventInput,
        'intent': GoogleCloudDialogflowV3alpha1IntentInput,
        'speechTranscripts': GoogleCloudDialogflowV3alpha1TranscriptInput,
        'text': GoogleCloudDialogflowV3alpha1TextInput
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ReloadDocumentOperationMetadataParameters {
  genericMetadata?:
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|null;
}
export class
    GoogleCloudDialogflowV3alpha1ReloadDocumentOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ReloadDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|
      null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ReloadDocumentOperationMetadata> {
    return GoogleCloudDialogflowV3alpha1ReloadDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessageParameters {
  text?: GoogleCloudDialogflowV3alpha1ResponseMessageText|null;
  payload?: ApiClientObjectMap<any>|null;
  conversationSuccess?:
      GoogleCloudDialogflowV3alpha1ResponseMessageConversationSuccess|null;
  avayaPlayAudio?: GoogleCloudDialogflowV3alpha1ResponseMessageAvayaPlayAudio|
      null;
  interactiveVoiceResponseSettings?:
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettings|null;
  outputAudioText?: GoogleCloudDialogflowV3alpha1ResponseMessageOutputAudioText|
      null;
  liveAgentHandoff?:
      GoogleCloudDialogflowV3alpha1ResponseMessageLiveAgentHandoff|null;
  endInteraction?: GoogleCloudDialogflowV3alpha1ResponseMessageEndInteraction|
      null;
  playAudio?: GoogleCloudDialogflowV3alpha1ResponseMessagePlayAudio|null;
  mixedAudio?: GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudio|null;
  telephonyTransferCall?:
      GoogleCloudDialogflowV3alpha1ResponseMessageTelephonyTransferCall|null;
  platform?: GoogleCloudDialogflowV3alpha1ResponseMessagePlatform|null;
  responseType?: GoogleCloudDialogflowV3alpha1ResponseMessageResponseType|null;
  parameter?: string|null;
  source?: GoogleCloudDialogflowV3alpha1ResponseMessageSource|null;
  languageCode?: string|null;
  endOfPartialResponse?: boolean|null;
  channel?: string|null;
}
export class GoogleCloudDialogflowV3alpha1ResponseMessage extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1ResponseMessageParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'conversationSuccess',
        (parameters.conversationSuccess == null) ?
            (null) :
            (parameters.conversationSuccess));
    this.Serializable$set(
        'avayaPlayAudio',
        (parameters.avayaPlayAudio == null) ? (null) :
                                              (parameters.avayaPlayAudio));
    this.Serializable$set(
        'interactiveVoiceResponseSettings',
        (parameters.interactiveVoiceResponseSettings == null) ?
            (null) :
            (parameters.interactiveVoiceResponseSettings));
    this.Serializable$set(
        'outputAudioText',
        (parameters.outputAudioText == null) ? (null) :
                                               (parameters.outputAudioText));
    this.Serializable$set(
        'liveAgentHandoff',
        (parameters.liveAgentHandoff == null) ? (null) :
                                                (parameters.liveAgentHandoff));
    this.Serializable$set(
        'endInteraction',
        (parameters.endInteraction == null) ? (null) :
                                              (parameters.endInteraction));
    this.Serializable$set(
        'playAudio',
        (parameters.playAudio == null) ? (null) : (parameters.playAudio));
    this.Serializable$set(
        'mixedAudio',
        (parameters.mixedAudio == null) ? (null) : (parameters.mixedAudio));
    this.Serializable$set(
        'telephonyTransferCall',
        (parameters.telephonyTransferCall == null) ?
            (null) :
            (parameters.telephonyTransferCall));
    this.Serializable$set(
        'platform',
        (parameters.platform == null) ? (null) : (parameters.platform));
    this.Serializable$set(
        'responseType',
        (parameters.responseType == null) ? (null) : (parameters.responseType));
    this.Serializable$set(
        'parameter',
        (parameters.parameter == null) ? (null) : (parameters.parameter));
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'endOfPartialResponse',
        (parameters.endOfPartialResponse == null) ?
            (null) :
            (parameters.endOfPartialResponse));
    this.Serializable$set(
        'channel',
        (parameters.channel == null) ? (null) : (parameters.channel));
  }

  static get Platform():
      IGoogleCloudDialogflowV3alpha1ResponseMessagePlatformEnum {
    return GoogleCloudDialogflowV3alpha1ResponseMessagePlatformEnum;
  }

  static get ResponseType():
      IGoogleCloudDialogflowV3alpha1ResponseMessageResponseTypeEnum {
    return GoogleCloudDialogflowV3alpha1ResponseMessageResponseTypeEnum;
  }

  static get Source(): IGoogleCloudDialogflowV3alpha1ResponseMessageSourceEnum {
    return GoogleCloudDialogflowV3alpha1ResponseMessageSourceEnum;
  }

  get avayaPlayAudio():
      GoogleCloudDialogflowV3alpha1ResponseMessageAvayaPlayAudio|null {
    return (
        (this.Serializable$has('avayaPlayAudio')) ?
            (this.Serializable$get('avayaPlayAudio')) :
            (null));
  }

  /**
   * Plays audio from a file in Avaya Browser.
   */
  set avayaPlayAudio(
      value: GoogleCloudDialogflowV3alpha1ResponseMessageAvayaPlayAudio|null) {
    this.Serializable$set('avayaPlayAudio', value);
  }

  get channel(): string|null {
    return (
        (this.Serializable$has('channel')) ?
            (this.Serializable$get('channel')) :
            (null));
  }

  /**
   * The channel which the response is associated with. Clients can specify the
   * channel via QueryParameters.channel, and only associated channel response
   * will be returned.
   */
  set channel(value: string|null) {
    this.Serializable$set('channel', value);
  }

  get conversationSuccess():
      GoogleCloudDialogflowV3alpha1ResponseMessageConversationSuccess|null {
    return (
        (this.Serializable$has('conversationSuccess')) ?
            (this.Serializable$get('conversationSuccess')) :
            (null));
  }

  /**
   * Indicates that the conversation succeeded.
   */
  set conversationSuccess(
      value: GoogleCloudDialogflowV3alpha1ResponseMessageConversationSuccess|
      null) {
    this.Serializable$set('conversationSuccess', value);
  }

  get endInteraction():
      GoogleCloudDialogflowV3alpha1ResponseMessageEndInteraction|null {
    return (
        (this.Serializable$has('endInteraction')) ?
            (this.Serializable$get('endInteraction')) :
            (null));
  }

  /**
   * Output only. A signal that indicates the interaction with the Dialogflow
   * agent has ended. This message is generated by Dialogflow only when the
   * conversation reaches `END_SESSION` page. It is not supposed to be defined
   * by the user. It's guaranteed that there is at most one such message in each
   * response.
   */
  set endInteraction(
      value: GoogleCloudDialogflowV3alpha1ResponseMessageEndInteraction|null) {
    this.Serializable$set('endInteraction', value);
  }

  get endOfPartialResponse(): boolean|null {
    return (
        (this.Serializable$has('endOfPartialResponse')) ?
            (this.Serializable$get('endOfPartialResponse')) :
            (null));
  }

  /**
   * Indicates the last message of a partial response. Only used by Dialogflow
   * console to simulate streaming responses.
   */
  set endOfPartialResponse(value: boolean|null) {
    this.Serializable$set('endOfPartialResponse', value);
  }

  get interactiveVoiceResponseSettings():
      GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettings|null {
    return (
        (this.Serializable$has('interactiveVoiceResponseSettings')) ?
            (this.Serializable$get('interactiveVoiceResponseSettings')) :
            (null));
  }

  /**
   * Returns the current values for the IVR (interactive voice response)
   * settings. Even though the operations configured by these settings are
   * performed by Dialogflow, the client may need to perform special logic at
   * the moment. For example, if Dialogflow exports audio to Google Cloud
   * Storage, then the client may need to wait for the resulting object to
   * appear in the bucket before proceeding.
   */
  set interactiveVoiceResponseSettings(
      value: GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettings|
      null) {
    this.Serializable$set('interactiveVoiceResponseSettings', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language of the response message. In normal cases, we don't need to
   * populate `language_code` as only response messages of the requested
   * language are returned. However we need to store the response messages of
   * all languages for changelog, so that we can revert back the entire
   * resource.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get liveAgentHandoff():
      GoogleCloudDialogflowV3alpha1ResponseMessageLiveAgentHandoff|null {
    return (
        (this.Serializable$has('liveAgentHandoff')) ?
            (this.Serializable$get('liveAgentHandoff')) :
            (null));
  }

  /**
   * Hands off conversation to a human agent.
   */
  set liveAgentHandoff(
      value: GoogleCloudDialogflowV3alpha1ResponseMessageLiveAgentHandoff|
      null) {
    this.Serializable$set('liveAgentHandoff', value);
  }

  get mixedAudio(): GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudio
      |null {
    return (
        (this.Serializable$has('mixedAudio')) ?
            (this.Serializable$get('mixedAudio')) :
            (null));
  }

  /**
   * Output only. An audio response message composed of both the synthesized
   * Dialogflow agent responses and responses defined via play_audio. This
   * message is generated by Dialogflow only and not supposed to be defined by
   * the user.
   */
  set mixedAudio(value: GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudio|
                 null) {
    this.Serializable$set('mixedAudio', value);
  }

  get outputAudioText():
      GoogleCloudDialogflowV3alpha1ResponseMessageOutputAudioText|null {
    return (
        (this.Serializable$has('outputAudioText')) ?
            (this.Serializable$get('outputAudioText')) :
            (null));
  }

  /**
   * A text or ssml response that is preferentially used for TTS output audio
   * synthesis, as described in the comment on the ResponseMessage message.
   */
  set outputAudioText(
      value: GoogleCloudDialogflowV3alpha1ResponseMessageOutputAudioText|null) {
    this.Serializable$set('outputAudioText', value);
  }

  get parameter(): string|null {
    return (
        (this.Serializable$has('parameter')) ?
            (this.Serializable$get('parameter')) :
            (null));
  }

  /**
   * Display name of the associated form parameter. Only populated for
   * `PARAMETER_PROMPT`.
   */
  set parameter(value: string|null) {
    this.Serializable$set('parameter', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Returns a response containing a custom, platform-specific payload.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get platform(): GoogleCloudDialogflowV3alpha1ResponseMessagePlatform|null {
    return (
        (this.Serializable$has('platform')) ?
            (this.Serializable$get('platform')) :
            (null));
  }

  /**
   * The platform that the response message is intended for.
   */
  set platform(value: GoogleCloudDialogflowV3alpha1ResponseMessagePlatform|
               null) {
    this.Serializable$set('platform', value);
  }

  get playAudio(): GoogleCloudDialogflowV3alpha1ResponseMessagePlayAudio|null {
    return (
        (this.Serializable$has('playAudio')) ?
            (this.Serializable$get('playAudio')) :
            (null));
  }

  /**
   * Signal that the client should play an audio clip hosted at a
   * client-specific URI. Dialogflow uses this to construct mixed_audio.
   * However, Dialogflow itself does not try to read or process the URI in any
   * way.
   */
  set playAudio(value: GoogleCloudDialogflowV3alpha1ResponseMessagePlayAudio|
                null) {
    this.Serializable$set('playAudio', value);
  }

  get responseType(): GoogleCloudDialogflowV3alpha1ResponseMessageResponseType
      |null {
    return (
        (this.Serializable$has('responseType')) ?
            (this.Serializable$get('responseType')) :
            (null));
  }

  /**
   * Response type.
   */
  set responseType(value:
                       GoogleCloudDialogflowV3alpha1ResponseMessageResponseType|
                   null) {
    this.Serializable$set('responseType', value);
  }

  get source(): GoogleCloudDialogflowV3alpha1ResponseMessageSource|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * The source by which this response was generated.
   */
  set source(value: GoogleCloudDialogflowV3alpha1ResponseMessageSource|null) {
    this.Serializable$set('source', value);
  }

  get telephonyTransferCall():
      GoogleCloudDialogflowV3alpha1ResponseMessageTelephonyTransferCall|null {
    return (
        (this.Serializable$has('telephonyTransferCall')) ?
            (this.Serializable$get('telephonyTransferCall')) :
            (null));
  }

  /**
   * A signal that the client should transfer the phone call connected to this
   * agent to a third-party endpoint.
   */
  set telephonyTransferCall(
      value: GoogleCloudDialogflowV3alpha1ResponseMessageTelephonyTransferCall|
      null) {
    this.Serializable$set('telephonyTransferCall', value);
  }

  get text(): GoogleCloudDialogflowV3alpha1ResponseMessageText|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Returns a text response.
   */
  set text(value: GoogleCloudDialogflowV3alpha1ResponseMessageText|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ResponseMessage> {
    return GoogleCloudDialogflowV3alpha1ResponseMessage;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'platform': GoogleCloudDialogflowV3alpha1ResponseMessagePlatformEnum,
        'responseType':
            GoogleCloudDialogflowV3alpha1ResponseMessageResponseTypeEnum,
        'source': GoogleCloudDialogflowV3alpha1ResponseMessageSourceEnum
      },
      keys: [
        'avayaPlayAudio', 'channel', 'conversationSuccess', 'endInteraction',
        'endOfPartialResponse', 'interactiveVoiceResponseSettings',
        'languageCode', 'liveAgentHandoff', 'mixedAudio', 'outputAudioText',
        'parameter', 'payload', 'platform', 'playAudio', 'responseType',
        'source', 'telephonyTransferCall', 'text'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'avayaPlayAudio':
            GoogleCloudDialogflowV3alpha1ResponseMessageAvayaPlayAudio,
        'conversationSuccess':
            GoogleCloudDialogflowV3alpha1ResponseMessageConversationSuccess,
        'endInteraction':
            GoogleCloudDialogflowV3alpha1ResponseMessageEndInteraction,
        'interactiveVoiceResponseSettings':
            GoogleCloudDialogflowV3alpha1InteractiveVoiceResponseSettings,
        'liveAgentHandoff':
            GoogleCloudDialogflowV3alpha1ResponseMessageLiveAgentHandoff,
        'mixedAudio': GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudio,
        'outputAudioText':
            GoogleCloudDialogflowV3alpha1ResponseMessageOutputAudioText,
        'playAudio': GoogleCloudDialogflowV3alpha1ResponseMessagePlayAudio,
        'telephonyTransferCall':
            GoogleCloudDialogflowV3alpha1ResponseMessageTelephonyTransferCall,
        'text': GoogleCloudDialogflowV3alpha1ResponseMessageText
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessageAvayaPlayAudioParameters {
  audioUri?: string|null;
}
export class GoogleCloudDialogflowV3alpha1ResponseMessageAvayaPlayAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ResponseMessageAvayaPlayAudioParameters =
              {}) {
    super();
    this.Serializable$set(
        'audioUri',
        (parameters.audioUri == null) ? (null) : (parameters.audioUri));
  }

  get audioUri(): string|null {
    return (
        (this.Serializable$has('audioUri')) ?
            (this.Serializable$get('audioUri')) :
            (null));
  }

  /**
   * Required. Audio URI for Avaya Browser to play the audio.
   */
  set audioUri(value: string|null) {
    this.Serializable$set('audioUri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ResponseMessageAvayaPlayAudio> {
    return GoogleCloudDialogflowV3alpha1ResponseMessageAvayaPlayAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audioUri']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessageConversationSuccessParameters {
  metadata?: ApiClientObjectMap<any>|null;
}
export class
    GoogleCloudDialogflowV3alpha1ResponseMessageConversationSuccess extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ResponseMessageConversationSuccessParameters =
              {}) {
    super();
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
  }

  get metadata(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Custom metadata. Dialogflow doesn't impose any structure on this.
   */
  set metadata(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('metadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ResponseMessageConversationSuccess> {
    return GoogleCloudDialogflowV3alpha1ResponseMessageConversationSuccess;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['metadata'],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessageEndInteractionParameters {
}
export class GoogleCloudDialogflowV3alpha1ResponseMessageEndInteraction extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ResponseMessageEndInteractionParameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ResponseMessageEndInteraction> {
    return GoogleCloudDialogflowV3alpha1ResponseMessageEndInteraction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessageLiveAgentHandoffParameters {
  metadata?: ApiClientObjectMap<any>|null;
}
export class
    GoogleCloudDialogflowV3alpha1ResponseMessageLiveAgentHandoff extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ResponseMessageLiveAgentHandoffParameters =
              {}) {
    super();
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
  }

  get metadata(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Custom metadata for your handoff procedure. Dialogflow doesn't impose any
   * structure on this.
   */
  set metadata(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('metadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ResponseMessageLiveAgentHandoff> {
    return GoogleCloudDialogflowV3alpha1ResponseMessageLiveAgentHandoff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['metadata'],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioParameters {
  segments?:
      Array<GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioSegment>|null;
}
export class GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioParameters =
              {}) {
    super();
    this.Serializable$set(
        'segments',
        (parameters.segments == null) ? (null) : (parameters.segments));
  }

  get segments():
      Array<GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioSegment>|
      null {
    return (
        (this.Serializable$has('segments')) ?
            (this.Serializable$get('segments')) :
            (null));
  }

  /**
   * Segments this audio response is composed of.
   */
  set segments(
      value:
          Array<GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioSegment>|
      null) {
    this.Serializable$set('segments', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudio> {
    return GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'segments':
            GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioSegment
      },
      keys: ['segments']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioSegmentParameters {
  audio?: string|null;
  uri?: string|null;
  allowPlaybackInterruption?: boolean|null;
}
export class
    GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioSegment extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioSegmentParameters =
              {}) {
    super();
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this segment can be interrupted by the
   * end user's speech and the client should then start the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get audio(): string|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * Raw audio synthesized from the Dialogflow agent's response using the output
   * config specified in the request.
   */
  set audio(value: string|null) {
    this.Serializable$set('audio', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Client-specific URI that points to an audio clip accessible to the client.
   * Dialogflow does not impose any validation on it.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioSegment> {
    return GoogleCloudDialogflowV3alpha1ResponseMessageMixedAudioSegment;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'audio', 'uri']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessageOutputAudioTextParameters {
  text?: string|null;
  ssml?: string|null;
  allowPlaybackInterruption?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1ResponseMessageOutputAudioText extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ResponseMessageOutputAudioTextParameters =
              {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'ssml', (parameters.ssml == null) ? (null) : (parameters.ssml));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this message can be interrupted by the
   * end user's speech and the client can then starts the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get ssml(): string|null {
    return (
        (this.Serializable$has('ssml')) ? (this.Serializable$get('ssml')) :
                                          (null));
  }

  /**
   * The SSML text to be synthesized. For more information, see
   * [SSML](/speech/text-to-speech/docs/ssml).
   */
  set ssml(value: string|null) {
    this.Serializable$set('ssml', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The raw text to be synthesized.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ResponseMessageOutputAudioText> {
    return GoogleCloudDialogflowV3alpha1ResponseMessageOutputAudioText;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'ssml', 'text']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessagePlayAudioParameters {
  audioUri?: string|null;
  allowPlaybackInterruption?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1ResponseMessagePlayAudio extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ResponseMessagePlayAudioParameters =
              {}) {
    super();
    this.Serializable$set(
        'audioUri',
        (parameters.audioUri == null) ? (null) : (parameters.audioUri));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this message can be interrupted by the
   * end user's speech and the client can then starts the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get audioUri(): string|null {
    return (
        (this.Serializable$has('audioUri')) ?
            (this.Serializable$get('audioUri')) :
            (null));
  }

  /**
   * Required. URI of the audio clip. Dialogflow does not impose any validation
   * on this value. It is specific to the client that reads it.
   */
  set audioUri(value: string|null) {
    this.Serializable$set('audioUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ResponseMessagePlayAudio> {
    return GoogleCloudDialogflowV3alpha1ResponseMessagePlayAudio;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'audioUri']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessageTelephonyTransferCallParameters {
  phoneNumber?: string|null;
  sipUri?: string|null;
}
export class
    GoogleCloudDialogflowV3alpha1ResponseMessageTelephonyTransferCall extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ResponseMessageTelephonyTransferCallParameters =
              {}) {
    super();
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
    this.Serializable$set(
        'sipUri', (parameters.sipUri == null) ? (null) : (parameters.sipUri));
  }

  get phoneNumber(): string|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Transfer the call to a phone number in [E.164
   * format](https://en.wikipedia.org/wiki/E.164).
   */
  set phoneNumber(value: string|null) {
    this.Serializable$set('phoneNumber', value);
  }

  get sipUri(): string|null {
    return (
        (this.Serializable$has('sipUri')) ? (this.Serializable$get('sipUri')) :
                                            (null));
  }

  /**
   * Transfer the call to a SIP endpoint.
   */
  set sipUri(value: string|null) {
    this.Serializable$set('sipUri', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1ResponseMessageTelephonyTransferCall> {
    return GoogleCloudDialogflowV3alpha1ResponseMessageTelephonyTransferCall;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['phoneNumber', 'sipUri']};
  }
}

export interface GoogleCloudDialogflowV3alpha1ResponseMessageTextParameters {
  text?: Array<string>|null;
  allowPlaybackInterruption?: boolean|null;
  redactedText?: Array<string>|null;
}
export class GoogleCloudDialogflowV3alpha1ResponseMessageText extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1ResponseMessageTextParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
    this.Serializable$set(
        'redactedText',
        (parameters.redactedText == null) ? (null) : (parameters.redactedText));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Output only. Whether the playback of this message can be interrupted by the
   * end user's speech and the client can then starts the next Dialogflow
   * request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get redactedText(): Array<string>|null {
    return (
        (this.Serializable$has('redactedText')) ?
            (this.Serializable$get('redactedText')) :
            (null));
  }

  /**
   * A collection of parameter redacted text response. This field contains the
   * same text as Text.text but the parameters will be replaced with
   * $parameter_name_redacted. The Text.redacted_text will be the same as
   * Text.text if no parameters or no parameters to be redacted in text. This is
   * an internal field and will only be used to replace [Text.text] for logging.
   * For example: { \"text\": [ \"Hello\", \"My SSN is 0000\", ],
   * \"redacted_text\": [ \"Hello\", \"My SSN is $ssn_redacted\", ] }
   */
  set redactedText(value: Array<string>|null) {
    this.Serializable$set('redactedText', value);
  }

  get text(): Array<string>|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. A collection of text responses.
   */
  set text(value: Array<string>|null) {
    this.Serializable$set('text', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1ResponseMessageText> {
    return GoogleCloudDialogflowV3alpha1ResponseMessageText;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'redactedText', 'text']};
  }
}

export interface GoogleCloudDialogflowV3alpha1RestoreBackupResponseParameters {}
export class GoogleCloudDialogflowV3alpha1RestoreBackupResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1RestoreBackupResponseParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1RestoreBackupResponse> {
    return GoogleCloudDialogflowV3alpha1RestoreBackupResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1RunContinuousTestMetadataParameters {
  errors?: Array<GoogleCloudDialogflowV3alpha1TestError>|null;
}
export class GoogleCloudDialogflowV3alpha1RunContinuousTestMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1RunContinuousTestMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'errors', (parameters.errors == null) ? (null) : (parameters.errors));
  }

  get errors(): Array<GoogleCloudDialogflowV3alpha1TestError>|null {
    return (
        (this.Serializable$has('errors')) ? (this.Serializable$get('errors')) :
                                            (null));
  }

  /**
   * The test errors.
   */
  set errors(value: Array<GoogleCloudDialogflowV3alpha1TestError>|null) {
    this.Serializable$set('errors', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1RunContinuousTestMetadata> {
    return GoogleCloudDialogflowV3alpha1RunContinuousTestMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'errors': GoogleCloudDialogflowV3alpha1TestError},
      keys: ['errors']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1RunContinuousTestResponseParameters {
  continuousTestResult?: GoogleCloudDialogflowV3alpha1ContinuousTestResult|null;
}
export class GoogleCloudDialogflowV3alpha1RunContinuousTestResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1RunContinuousTestResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'continuousTestResult',
        (parameters.continuousTestResult == null) ?
            (null) :
            (parameters.continuousTestResult));
  }

  get continuousTestResult(): GoogleCloudDialogflowV3alpha1ContinuousTestResult
      |null {
    return (
        (this.Serializable$has('continuousTestResult')) ?
            (this.Serializable$get('continuousTestResult')) :
            (null));
  }

  /**
   * The result for a continuous test run.
   */
  set continuousTestResult(
      value: GoogleCloudDialogflowV3alpha1ContinuousTestResult|null) {
    this.Serializable$set('continuousTestResult', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1RunContinuousTestResponse> {
    return GoogleCloudDialogflowV3alpha1RunContinuousTestResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['continuousTestResult'],
      objects: {
        'continuousTestResult':
            GoogleCloudDialogflowV3alpha1ContinuousTestResult
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1RunTestCaseMetadataParameters {}
export class GoogleCloudDialogflowV3alpha1RunTestCaseMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1RunTestCaseMetadataParameters = {}) {
    super();
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1RunTestCaseMetadata> {
    return GoogleCloudDialogflowV3alpha1RunTestCaseMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleCloudDialogflowV3alpha1RunTestCaseResponseParameters {
  result?: GoogleCloudDialogflowV3alpha1TestCaseResult|null;
}
export class GoogleCloudDialogflowV3alpha1RunTestCaseResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1RunTestCaseResponseParameters = {}) {
    super();
    this.Serializable$set(
        'result', (parameters.result == null) ? (null) : (parameters.result));
  }

  get result(): GoogleCloudDialogflowV3alpha1TestCaseResult|null {
    return (
        (this.Serializable$has('result')) ? (this.Serializable$get('result')) :
                                            (null));
  }

  /**
   * The result.
   */
  set result(value: GoogleCloudDialogflowV3alpha1TestCaseResult|null) {
    this.Serializable$set('result', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1RunTestCaseResponse> {
    return GoogleCloudDialogflowV3alpha1RunTestCaseResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['result'],
      objects: {'result': GoogleCloudDialogflowV3alpha1TestCaseResult}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1SessionInfoParameters {
  session?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
}
export class GoogleCloudDialogflowV3alpha1SessionInfo extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1SessionInfoParameters = {}) {
    super();
    this.Serializable$set(
        'session',
        (parameters.session == null) ? (null) : (parameters.session));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional for WebhookRequest. Optional for WebhookResponse. All parameters
   * collected from forms and intents during the session. Parameters can be
   * created, updated, or removed by the webhook. To remove a parameter from the
   * session, the webhook should explicitly set the parameter value to null in
   * WebhookResponse. The map is keyed by parameters' display names.
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  get session(): string|null {
    return (
        (this.Serializable$has('session')) ?
            (this.Serializable$get('session')) :
            (null));
  }

  /**
   * Always present for WebhookRequest. Ignored for WebhookResponse. The unique
   * identifier of the session. This field can be used by the webhook to
   * identify a session. Format: `projects//locations//agents//sessions/` or
   * `projects//locations//agents//environments//sessions/` if environment is
   * specified.
   */
  set session(value: string|null) {
    this.Serializable$set('session', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1SessionInfo> {
    return GoogleCloudDialogflowV3alpha1SessionInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['parameters', 'session'],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1SignupIntegrationMetadataParameters {
  payload?: ApiClientObjectMap<any>|null;
}
export class GoogleCloudDialogflowV3alpha1SignupIntegrationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1SignupIntegrationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Payload of metadata.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1SignupIntegrationMetadata> {
    return GoogleCloudDialogflowV3alpha1SignupIntegrationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['payload'],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1SignupIntegrationResponseParameters {
  redirectUri?: string|null;
  integration?: GoogleCloudDialogflowV3alpha1Integration|null;
}
export class GoogleCloudDialogflowV3alpha1SignupIntegrationResponse extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1SignupIntegrationResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'redirectUri',
        (parameters.redirectUri == null) ? (null) : (parameters.redirectUri));
    this.Serializable$set(
        'integration',
        (parameters.integration == null) ? (null) : (parameters.integration));
  }

  get integration(): GoogleCloudDialogflowV3alpha1Integration|null {
    return (
        (this.Serializable$has('integration')) ?
            (this.Serializable$get('integration')) :
            (null));
  }

  /**
   * Integration created for the signup flow.
   */
  set integration(value: GoogleCloudDialogflowV3alpha1Integration|null) {
    this.Serializable$set('integration', value);
  }

  get redirectUri(): string|null {
    return (
        (this.Serializable$has('redirectUri')) ?
            (this.Serializable$get('redirectUri')) :
            (null));
  }

  /**
   * The URI to direct to for the signup flow.
   */
  set redirectUri(value: string|null) {
    this.Serializable$set('redirectUri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1SignupIntegrationResponse> {
    return GoogleCloudDialogflowV3alpha1SignupIntegrationResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['integration', 'redirectUri'],
      objects: {'integration': GoogleCloudDialogflowV3alpha1Integration}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1SpeakerIdSettingsParameters {
  speakerIdPageType?:
      GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType|null;
  phrases?: Array<GoogleCloudDialogflowV3alpha1SpeakerIdSettingsPhrases>|null;
  disablePassiveVerification?: boolean|null;
  disableAdaptation?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1SpeakerIdSettings extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1SpeakerIdSettingsParameters = {}) {
    super();
    this.Serializable$set(
        'speakerIdPageType',
        (parameters.speakerIdPageType == null) ?
            (null) :
            (parameters.speakerIdPageType));
    this.Serializable$set(
        'phrases',
        (parameters.phrases == null) ? (null) : (parameters.phrases));
    this.Serializable$set(
        'disablePassiveVerification',
        (parameters.disablePassiveVerification == null) ?
            (null) :
            (parameters.disablePassiveVerification));
    this.Serializable$set(
        'disableAdaptation',
        (parameters.disableAdaptation == null) ?
            (null) :
            (parameters.disableAdaptation));
  }

  static get SpeakerIdPageType():
      IGoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageTypeEnum {
    return GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageTypeEnum;
  }

  get disableAdaptation(): boolean|null {
    return (
        (this.Serializable$has('disableAdaptation')) ?
            (this.Serializable$get('disableAdaptation')) :
            (null));
  }

  /**
   * Whether the audio for Speaker ID verification may be stored for model
   * adaptation. If not set or set to false, and audio for verification is a
   * strong match to the existing speaker profile for the matching speaker, the
   * audio may be stored in order to further improve the speaker profile. If set
   * to true, the audio in the request will not be stored. Only set for pages of
   * type SPEAKER_ID_PAGE_TYPE_VERIFICATION or do not disable passive
   * verification.
   */
  set disableAdaptation(value: boolean|null) {
    this.Serializable$set('disableAdaptation', value);
  }

  get disablePassiveVerification(): boolean|null {
    return (
        (this.Serializable$has('disablePassiveVerification')) ?
            (this.Serializable$get('disablePassiveVerification')) :
            (null));
  }

  /**
   * Whether to disable passive verification for conversations on this page.
   * Only set for pages that are of type SPEAKER_ID_PAGE_TYPE_UNSPECIFIED.
   */
  set disablePassiveVerification(value: boolean|null) {
    this.Serializable$set('disablePassiveVerification', value);
  }

  get phrases():
      Array<GoogleCloudDialogflowV3alpha1SpeakerIdSettingsPhrases>|null {
    return (
        (this.Serializable$has('phrases')) ?
            (this.Serializable$get('phrases')) :
            (null));
  }

  /**
   * Phrases for Speaker ID enrollment and verification.
   */
  set phrases(value:
                  Array<GoogleCloudDialogflowV3alpha1SpeakerIdSettingsPhrases>|
              null) {
    this.Serializable$set('phrases', value);
  }

  get speakerIdPageType():
      GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType|null {
    return (
        (this.Serializable$has('speakerIdPageType')) ?
            (this.Serializable$get('speakerIdPageType')) :
            (null));
  }

  /**
   * Type of the Speaker ID-related page.
   */
  set speakerIdPageType(
      value: GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageType|
      null) {
    this.Serializable$set('speakerIdPageType', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1SpeakerIdSettings> {
    return GoogleCloudDialogflowV3alpha1SpeakerIdSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'phrases': GoogleCloudDialogflowV3alpha1SpeakerIdSettingsPhrases},
      enums: {
        'speakerIdPageType':
            GoogleCloudDialogflowV3alpha1SpeakerIdSettingsSpeakerIdPageTypeEnum
      },
      keys: [
        'disableAdaptation', 'disablePassiveVerification', 'phrases',
        'speakerIdPageType'
      ]
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1SpeakerIdSettingsPhrasesParameters {
  enrollmentPhrases?: Array<string>|null;
  verificationPhrases?: Array<string>|null;
  languageCode?: string|null;
}
export class GoogleCloudDialogflowV3alpha1SpeakerIdSettingsPhrases extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1SpeakerIdSettingsPhrasesParameters =
              {}) {
    super();
    this.Serializable$set(
        'enrollmentPhrases',
        (parameters.enrollmentPhrases == null) ?
            (null) :
            (parameters.enrollmentPhrases));
    this.Serializable$set(
        'verificationPhrases',
        (parameters.verificationPhrases == null) ?
            (null) :
            (parameters.verificationPhrases));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get enrollmentPhrases(): Array<string>|null {
    return (
        (this.Serializable$has('enrollmentPhrases')) ?
            (this.Serializable$get('enrollmentPhrases')) :
            (null));
  }

  /**
   * Phrases that the user need to repeat for Speaker ID enrollment. For each
   * round of phrase enrollment, Dialogflow would choose one phrase from the
   * list by random. If unspecified or if all provided phrases have already been
   * used in a single session, Dialogflow would generate phrases automatically.
   */
  set enrollmentPhrases(value: Array<string>|null) {
    this.Serializable$set('enrollmentPhrases', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language of the phrases. In normal cases, we don't need to populate
   * `language_code` as only phrases of the requested language are returned.
   * However we need to store the phrases of all languages for YAML exported
   * agent.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get verificationPhrases(): Array<string>|null {
    return (
        (this.Serializable$has('verificationPhrases')) ?
            (this.Serializable$get('verificationPhrases')) :
            (null));
  }

  /**
   * Phrases that users need to repeat for Speaker ID verification. During
   * verification, Dialogflow would choose one phrase from the list by random.
   * If unspecified, Dialogflow would generate a phrase automatically.
   */
  set verificationPhrases(value: Array<string>|null) {
    this.Serializable$set('verificationPhrases', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1SpeakerIdSettingsPhrases> {
    return GoogleCloudDialogflowV3alpha1SpeakerIdSettingsPhrases;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enrollmentPhrases', 'languageCode', 'verificationPhrases']};
  }
}

export interface GoogleCloudDialogflowV3alpha1SpeechContextParameters {
  phrases?: Array<string>|null;
  boost?: number|null;
}
export class GoogleCloudDialogflowV3alpha1SpeechContext extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1SpeechContextParameters = {}) {
    super();
    this.Serializable$set(
        'phrases',
        (parameters.phrases == null) ? (null) : (parameters.phrases));
    this.Serializable$set(
        'boost', (parameters.boost == null) ? (null) : (parameters.boost));
  }

  get boost(): number|null {
    return (
        (this.Serializable$has('boost')) ? (this.Serializable$get('boost')) :
                                           (null));
  }

  /**
   * Optional. Boost for this context compared to other contexts: * If the boost
   * is positive, Dialogflow will increase the probability that the phrases in
   * this context are recognized over similar sounding phrases. * If the boost
   * is unspecified or non-positive, Dialogflow will not apply any boost.
   * Dialogflow recommends that you use boosts in the range (0, 20] and that you
   * find a value that fits your use case with binary search.
   */
  set boost(value: number|null) {
    this.Serializable$set('boost', value);
  }

  get phrases(): Array<string>|null {
    return (
        (this.Serializable$has('phrases')) ?
            (this.Serializable$get('phrases')) :
            (null));
  }

  /**
   * Optional. A list of strings containing words and phrases that the speech
   * recognizer should recognize with higher likelihood. This list can be used
   * to: * improve accuracy for words and phrases you expect the user to say,
   * e.g. typical commands for your Dialogflow agent * add additional words to
   * the speech recognizer vocabulary * ... See the [Cloud Speech
   * documentation](https://cloud.google.com/speech-to-text/quotas) for usage
   * limits.
   */
  set phrases(value: Array<string>|null) {
    this.Serializable$set('phrases', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1SpeechContext> {
    return GoogleCloudDialogflowV3alpha1SpeechContext;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['boost', 'phrases']};
  }
}

export interface GoogleCloudDialogflowV3alpha1TestCaseParameters {
  name?: string|null;
  tags?: Array<string>|null;
  displayName?: string|null;
  notes?: string|null;
  testConfig?: GoogleCloudDialogflowV3alpha1TestConfig|null;
  testCaseConversationTurns?:
      Array<GoogleCloudDialogflowV3alpha1ConversationTurn>|null;
  ignore?: boolean|null;
  creationTime?: string|null;
  lastTestResult?: GoogleCloudDialogflowV3alpha1TestCaseResult|null;
}
export class GoogleCloudDialogflowV3alpha1TestCase extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1TestCaseParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'tags', (parameters.tags == null) ? (null) : (parameters.tags));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'notes', (parameters.notes == null) ? (null) : (parameters.notes));
    this.Serializable$set(
        'testConfig',
        (parameters.testConfig == null) ? (null) : (parameters.testConfig));
    this.Serializable$set(
        'testCaseConversationTurns',
        (parameters.testCaseConversationTurns == null) ?
            (null) :
            (parameters.testCaseConversationTurns));
    this.Serializable$set(
        'ignore', (parameters.ignore == null) ? (null) : (parameters.ignore));
    this.Serializable$set(
        'creationTime',
        (parameters.creationTime == null) ? (null) : (parameters.creationTime));
    this.Serializable$set(
        'lastTestResult',
        (parameters.lastTestResult == null) ? (null) :
                                              (parameters.lastTestResult));
  }

  get creationTime(): string|null {
    return (
        (this.Serializable$has('creationTime')) ?
            (this.Serializable$get('creationTime')) :
            (null));
  }

  /**
   * Output only. When the test was created.
   */
  set creationTime(value: string|null) {
    this.Serializable$set('creationTime', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the test case, unique within the
   * agent. Limit of 200 characters.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get ignore(): boolean|null {
    return (
        (this.Serializable$has('ignore')) ? (this.Serializable$get('ignore')) :
                                            (null));
  }

  /**
   * Default is false. If set true, the test case will be excluded from run
   * requests. This flag is used for the console UI.
   */
  set ignore(value: boolean|null) {
    this.Serializable$set('ignore', value);
  }

  get lastTestResult(): GoogleCloudDialogflowV3alpha1TestCaseResult|null {
    return (
        (this.Serializable$has('lastTestResult')) ?
            (this.Serializable$get('lastTestResult')) :
            (null));
  }

  /**
   * Output only. The latest test result.
   */
  set lastTestResult(value: GoogleCloudDialogflowV3alpha1TestCaseResult|null) {
    this.Serializable$set('lastTestResult', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the test case. TestCases.CreateTestCase will
   * populate the name automatically. Otherwise use format:
   * `projects//locations//agents/ /testCases/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get notes(): string|null {
    return (
        (this.Serializable$has('notes')) ? (this.Serializable$get('notes')) :
                                           (null));
  }

  /**
   * Additional freeform notes about the test case. Limit of 400 characters.
   */
  set notes(value: string|null) {
    this.Serializable$set('notes', value);
  }

  get tags(): Array<string>|null {
    return (
        (this.Serializable$has('tags')) ? (this.Serializable$get('tags')) :
                                          (null));
  }

  /**
   * Tags are short descriptions that users may apply to test cases for
   * organizational and filtering purposes. Each tag should start with \"#\" and
   * has a limit of 30 characters.
   */
  set tags(value: Array<string>|null) {
    this.Serializable$set('tags', value);
  }

  get testCaseConversationTurns():
      Array<GoogleCloudDialogflowV3alpha1ConversationTurn>|null {
    return (
        (this.Serializable$has('testCaseConversationTurns')) ?
            (this.Serializable$get('testCaseConversationTurns')) :
            (null));
  }

  /**
   * The conversation turns uttered when the test case was created, in
   * chronological order. These include the canonical set of agent utterances
   * that should occur when the agent is working properly. There is limit of 20
   * conversation turns allowed per test case.
   */
  set testCaseConversationTurns(
      value: Array<GoogleCloudDialogflowV3alpha1ConversationTurn>|null) {
    this.Serializable$set('testCaseConversationTurns', value);
  }

  get testConfig(): GoogleCloudDialogflowV3alpha1TestConfig|null {
    return (
        (this.Serializable$has('testConfig')) ?
            (this.Serializable$get('testConfig')) :
            (null));
  }

  /**
   * Config for the test case.
   */
  set testConfig(value: GoogleCloudDialogflowV3alpha1TestConfig|null) {
    this.Serializable$set('testConfig', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1TestCase> {
    return GoogleCloudDialogflowV3alpha1TestCase;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'testCaseConversationTurns':
            GoogleCloudDialogflowV3alpha1ConversationTurn
      },
      keys: [
        'creationTime', 'displayName', 'ignore', 'lastTestResult', 'name',
        'notes', 'tags', 'testCaseConversationTurns', 'testConfig'
      ],
      objects: {
        'lastTestResult': GoogleCloudDialogflowV3alpha1TestCaseResult,
        'testConfig': GoogleCloudDialogflowV3alpha1TestConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1TestCaseErrorParameters {
  testCase?: GoogleCloudDialogflowV3alpha1TestCase|null;
  status?: GoogleRpcStatus|null;
}
export class GoogleCloudDialogflowV3alpha1TestCaseError extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1TestCaseErrorParameters = {}) {
    super();
    this.Serializable$set(
        'testCase',
        (parameters.testCase == null) ? (null) : (parameters.testCase));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * The status associated with the test case.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  get testCase(): GoogleCloudDialogflowV3alpha1TestCase|null {
    return (
        (this.Serializable$has('testCase')) ?
            (this.Serializable$get('testCase')) :
            (null));
  }

  /**
   * The test case.
   */
  set testCase(value: GoogleCloudDialogflowV3alpha1TestCase|null) {
    this.Serializable$set('testCase', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1TestCaseError> {
    return GoogleCloudDialogflowV3alpha1TestCaseError;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['status', 'testCase'],
      objects: {
        'status': GoogleRpcStatus,
        'testCase': GoogleCloudDialogflowV3alpha1TestCase
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1TestCaseResultParameters {
  name?: string|null;
  environment?: string|null;
  conversationTurns?: Array<GoogleCloudDialogflowV3alpha1ConversationTurn>|null;
  testResult?: GoogleCloudDialogflowV3alpha1TestCaseResultTestResult|null;
  testTime?: string|null;
}
export class GoogleCloudDialogflowV3alpha1TestCaseResult extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1TestCaseResultParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
    this.Serializable$set(
        'conversationTurns',
        (parameters.conversationTurns == null) ?
            (null) :
            (parameters.conversationTurns));
    this.Serializable$set(
        'testResult',
        (parameters.testResult == null) ? (null) : (parameters.testResult));
    this.Serializable$set(
        'testTime',
        (parameters.testTime == null) ? (null) : (parameters.testTime));
  }

  static get TestResult():
      IGoogleCloudDialogflowV3alpha1TestCaseResultTestResultEnum {
    return GoogleCloudDialogflowV3alpha1TestCaseResultTestResultEnum;
  }

  get conversationTurns():
      Array<GoogleCloudDialogflowV3alpha1ConversationTurn>|null {
    return (
        (this.Serializable$has('conversationTurns')) ?
            (this.Serializable$get('conversationTurns')) :
            (null));
  }

  /**
   * The conversation turns uttered during the test case replay in chronological
   * order.
   */
  set conversationTurns(
      value: Array<GoogleCloudDialogflowV3alpha1ConversationTurn>|null) {
    this.Serializable$set('conversationTurns', value);
  }

  get environment(): string|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * Environment where the test was run. If not set, it indicates the draft
   * environment.
   */
  set environment(value: string|null) {
    this.Serializable$set('environment', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The resource name for the test case result. Format:
   * `projects//locations//agents//testCases/ /results/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get testResult(): GoogleCloudDialogflowV3alpha1TestCaseResultTestResult|null {
    return (
        (this.Serializable$has('testResult')) ?
            (this.Serializable$get('testResult')) :
            (null));
  }

  /**
   * Whether the test case passed in the agent environment.
   */
  set testResult(value: GoogleCloudDialogflowV3alpha1TestCaseResultTestResult|
                 null) {
    this.Serializable$set('testResult', value);
  }

  get testTime(): string|null {
    return (
        (this.Serializable$has('testTime')) ?
            (this.Serializable$get('testTime')) :
            (null));
  }

  /**
   * The time that the test was run.
   */
  set testTime(value: string|null) {
    this.Serializable$set('testTime', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1TestCaseResult> {
    return GoogleCloudDialogflowV3alpha1TestCaseResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'conversationTurns': GoogleCloudDialogflowV3alpha1ConversationTurn},
      enums: {
        'testResult': GoogleCloudDialogflowV3alpha1TestCaseResultTestResultEnum
      },
      keys:
          ['conversationTurns', 'environment', 'name', 'testResult', 'testTime']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1TestConfigParameters {
  trackingParameters?: Array<string>|null;
  flow?: string|null;
  page?: string|null;
}
export class GoogleCloudDialogflowV3alpha1TestConfig extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1TestConfigParameters = {}) {
    super();
    this.Serializable$set(
        'trackingParameters',
        (parameters.trackingParameters == null) ?
            (null) :
            (parameters.trackingParameters));
    this.Serializable$set(
        'flow', (parameters.flow == null) ? (null) : (parameters.flow));
    this.Serializable$set(
        'page', (parameters.page == null) ? (null) : (parameters.page));
  }

  get flow(): string|null {
    return (
        (this.Serializable$has('flow')) ? (this.Serializable$get('flow')) :
                                          (null));
  }

  /**
   * Flow name to start the test case with. Format:
   * `projects//locations//agents//flows/`. Only one of `flow` and `page` should
   * be set to indicate the starting point of the test case. If both are set,
   * `page` takes precedence over `flow`. If neither is set, the test case will
   * start with start page on the default start flow.
   */
  set flow(value: string|null) {
    this.Serializable$set('flow', value);
  }

  get page(): string|null {
    return (
        (this.Serializable$has('page')) ? (this.Serializable$get('page')) :
                                          (null));
  }

  /**
   * The page to start the test case with. Format:
   * `projects//locations//agents//flows//pages/`. Only one of `flow` and `page`
   * should be set to indicate the starting point of the test case. If both are
   * set, `page` takes precedence over `flow`. If neither is set, the test case
   * will start with start page on the default start flow.
   */
  set page(value: string|null) {
    this.Serializable$set('page', value);
  }

  get trackingParameters(): Array<string>|null {
    return (
        (this.Serializable$has('trackingParameters')) ?
            (this.Serializable$get('trackingParameters')) :
            (null));
  }

  /**
   * Session parameters to be compared when calculating differences.
   */
  set trackingParameters(value: Array<string>|null) {
    this.Serializable$set('trackingParameters', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1TestConfig> {
    return GoogleCloudDialogflowV3alpha1TestConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['flow', 'page', 'trackingParameters']};
  }
}

export interface GoogleCloudDialogflowV3alpha1TestErrorParameters {
  testCase?: string|null;
  status?: GoogleRpcStatus|null;
  testTime?: string|null;
}
export class GoogleCloudDialogflowV3alpha1TestError extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1TestErrorParameters = {}) {
    super();
    this.Serializable$set(
        'testCase',
        (parameters.testCase == null) ? (null) : (parameters.testCase));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
    this.Serializable$set(
        'testTime',
        (parameters.testTime == null) ? (null) : (parameters.testTime));
  }

  get status(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * The status associated with the test.
   */
  set status(value: GoogleRpcStatus|null) {
    this.Serializable$set('status', value);
  }

  get testCase(): string|null {
    return (
        (this.Serializable$has('testCase')) ?
            (this.Serializable$get('testCase')) :
            (null));
  }

  /**
   * The test case resource name.
   */
  set testCase(value: string|null) {
    this.Serializable$set('testCase', value);
  }

  get testTime(): string|null {
    return (
        (this.Serializable$has('testTime')) ?
            (this.Serializable$get('testTime')) :
            (null));
  }

  /**
   * The timestamp when the test was completed.
   */
  set testTime(value: string|null) {
    this.Serializable$set('testTime', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1TestError> {
    return GoogleCloudDialogflowV3alpha1TestError;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['status', 'testCase', 'testTime'],
      objects: {'status': GoogleRpcStatus}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1TestRunDifferenceParameters {
  type?: GoogleCloudDialogflowV3alpha1TestRunDifferenceType|null;
  description?: string|null;
}
export class GoogleCloudDialogflowV3alpha1TestRunDifference extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1TestRunDifferenceParameters = {}) {
    super();
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
  }

  static get Type(): IGoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum {
    return GoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum;
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * A description of the diff, showing the actual output vs expected output.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get type(): GoogleCloudDialogflowV3alpha1TestRunDifferenceType|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * The type of diff.
   */
  set type(value: GoogleCloudDialogflowV3alpha1TestRunDifferenceType|null) {
    this.Serializable$set('type', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1TestRunDifference> {
    return GoogleCloudDialogflowV3alpha1TestRunDifference;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'type': GoogleCloudDialogflowV3alpha1TestRunDifferenceTypeEnum},
      keys: ['description', 'type']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1TextInputParameters {
  text?: string|null;
  isAudioInput?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1TextInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1TextInputParameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'isAudioInput',
        (parameters.isAudioInput == null) ? (null) : (parameters.isAudioInput));
  }

  get isAudioInput(): boolean|null {
    return (
        (this.Serializable$has('isAudioInput')) ?
            (this.Serializable$get('isAudioInput')) :
            (null));
  }

  /**
   * Whether the text is transcribed from audio input.
   */
  set isAudioInput(value: boolean|null) {
    this.Serializable$set('isAudioInput', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. The UTF-8 encoded natural language text to be processed. Text
   * length must not exceed 256 characters.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1TextInput> {
    return GoogleCloudDialogflowV3alpha1TextInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['isAudioInput', 'text']};
  }
}

export interface GoogleCloudDialogflowV3alpha1TranscriptInputParameters {
  finalTranscripts?: Array<GoogleCloudDialogflowV3alpha1FinalTranscript>|null;
}
export class GoogleCloudDialogflowV3alpha1TranscriptInput extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1TranscriptInputParameters = {}) {
    super();
    this.Serializable$set(
        'finalTranscripts',
        (parameters.finalTranscripts == null) ? (null) :
                                                (parameters.finalTranscripts));
  }

  get finalTranscripts():
      Array<GoogleCloudDialogflowV3alpha1FinalTranscript>|null {
    return (
        (this.Serializable$has('finalTranscripts')) ?
            (this.Serializable$get('finalTranscripts')) :
            (null));
  }

  /**
   * One or more settled speech transcripts that correspond to consecutive
   * portions of the audio currently being processed.
   */
  set finalTranscripts(value:
                           Array<GoogleCloudDialogflowV3alpha1FinalTranscript>|
                       null) {
    this.Serializable$set('finalTranscripts', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1TranscriptInput> {
    return GoogleCloudDialogflowV3alpha1TranscriptInput;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'finalTranscripts': GoogleCloudDialogflowV3alpha1FinalTranscript},
      keys: ['finalTranscripts']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1TransitionRouteParameters {
  name?: string|null;
  intent?: string|null;
  condition?: string|null;
  triggerFulfillment?: GoogleCloudDialogflowV3alpha1Fulfillment|null;
  targetPage?: string|null;
  targetFlow?: string|null;
}
export class GoogleCloudDialogflowV3alpha1TransitionRoute extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1TransitionRouteParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'triggerFulfillment',
        (parameters.triggerFulfillment == null) ?
            (null) :
            (parameters.triggerFulfillment));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition to evaluate against form parameters or session parameters.
   * See the [conditions
   * reference](https://cloud.google.com/dialogflow/priv/docs/v3/condition). At
   * least one of `intent` or `condition` must be specified. When both `intent`
   * and `condition` are specified, the transition can only happen when both are
   * fulfilled.
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The unique identifier of an Intent. Format:
   * `projects//locations//agents//intents/`. Indicates that the transition can
   * only happen when the given intent is matched. At least one of `intent` or
   * `condition` must be specified. When both `intent` and `condition` are
   * specified, the transition can only happen when both are fulfilled.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this transition route.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  get triggerFulfillment(): GoogleCloudDialogflowV3alpha1Fulfillment|null {
    return (
        (this.Serializable$has('triggerFulfillment')) ?
            (this.Serializable$get('triggerFulfillment')) :
            (null));
  }

  /**
   * The fulfillment to call when the condition is satisfied. At least one of
   * `trigger_fulfillment` and `target` must be specified. When both are
   * defined, `trigger_fulfillment` is executed first.
   */
  set triggerFulfillment(value: GoogleCloudDialogflowV3alpha1Fulfillment|null) {
    this.Serializable$set('triggerFulfillment', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1TransitionRoute> {
    return GoogleCloudDialogflowV3alpha1TransitionRoute;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'condition', 'intent', 'name', 'targetFlow', 'targetPage',
        'triggerFulfillment'
      ],
      objects: {'triggerFulfillment': GoogleCloudDialogflowV3alpha1Fulfillment}
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1UpdateDocumentOperationMetadataParameters {
  genericMetadata?:
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|null;
}
export class
    GoogleCloudDialogflowV3alpha1UpdateDocumentOperationMetadata extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1UpdateDocumentOperationMetadataParameters =
              {}) {
    super();
    this.Serializable$set(
        'genericMetadata',
        (parameters.genericMetadata == null) ? (null) :
                                               (parameters.genericMetadata));
  }

  get genericMetadata():
      GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|null {
    return (
        (this.Serializable$has('genericMetadata')) ?
            (this.Serializable$get('genericMetadata')) :
            (null));
  }

  /**
   * The generic information of the operation.
   */
  set genericMetadata(
      value: GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata|
      null) {
    this.Serializable$set('genericMetadata', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1UpdateDocumentOperationMetadata> {
    return GoogleCloudDialogflowV3alpha1UpdateDocumentOperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericMetadata'],
      objects: {
        'genericMetadata':
            GoogleCloudDialogflowV3alpha1GenericKnowledgeOperationMetadata
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookParameters {
  name?: string|null;
  displayName?: string|null;
  cloudFunction?: GoogleCloudDialogflowV3alpha1WebhookCloudFunction|null;
  genericWebService?: GoogleCloudDialogflowV3alpha1WebhookGenericWebService|
      null;
  serviceDirectory?: GoogleCloudDialogflowV3alpha1WebhookServiceDirectoryConfig|
      null;
  timeout?: string|null;
  disabled?: boolean|null;
  etag?: string|null;
}
export class GoogleCloudDialogflowV3alpha1Webhook extends Serializable {
  constructor(parameters: GoogleCloudDialogflowV3alpha1WebhookParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'cloudFunction',
        (parameters.cloudFunction == null) ? (null) :
                                             (parameters.cloudFunction));
    this.Serializable$set(
        'genericWebService',
        (parameters.genericWebService == null) ?
            (null) :
            (parameters.genericWebService));
    this.Serializable$set(
        'serviceDirectory',
        (parameters.serviceDirectory == null) ? (null) :
                                                (parameters.serviceDirectory));
    this.Serializable$set(
        'timeout',
        (parameters.timeout == null) ? (null) : (parameters.timeout));
    this.Serializable$set(
        'disabled',
        (parameters.disabled == null) ? (null) : (parameters.disabled));
    this.Serializable$set(
        'etag', (parameters.etag == null) ? (null) : (parameters.etag));
  }

  get cloudFunction(): GoogleCloudDialogflowV3alpha1WebhookCloudFunction|null {
    return (
        (this.Serializable$has('cloudFunction')) ?
            (this.Serializable$get('cloudFunction')) :
            (null));
  }

  /**
   * Configuration for a [Cloud Function](https://cloud.google.com/functions/).
   */
  set cloudFunction(value: GoogleCloudDialogflowV3alpha1WebhookCloudFunction|
                    null) {
    this.Serializable$set('cloudFunction', value);
  }

  get disabled(): boolean|null {
    return (
        (this.Serializable$has('disabled')) ?
            (this.Serializable$get('disabled')) :
            (null));
  }

  /**
   * Indicates whether the webhook is disabled.
   */
  set disabled(value: boolean|null) {
    this.Serializable$set('disabled', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The human-readable name of the webhook, unique within the agent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get etag(): string|null {
    return (
        (this.Serializable$has('etag')) ? (this.Serializable$get('etag')) :
                                          (null));
  }

  /**
   * Etag of the webhook, may be sent on update requests to ensure the client
   * has an up-to-date value before proceeding.
   */
  set etag(value: string|null) {
    this.Serializable$set('etag', value);
  }

  get genericWebService(): GoogleCloudDialogflowV3alpha1WebhookGenericWebService
      |null {
    return (
        (this.Serializable$has('genericWebService')) ?
            (this.Serializable$get('genericWebService')) :
            (null));
  }

  /**
   * Configuration for a generic web service.
   */
  set genericWebService(
      value: GoogleCloudDialogflowV3alpha1WebhookGenericWebService|null) {
    this.Serializable$set('genericWebService', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of the webhook. Required for the
   * Webhooks.UpdateWebhook method. Webhooks.CreateWebhook populates the name
   * automatically. Format: `projects//locations//agents//webhooks/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get serviceDirectory():
      GoogleCloudDialogflowV3alpha1WebhookServiceDirectoryConfig|null {
    return (
        (this.Serializable$has('serviceDirectory')) ?
            (this.Serializable$get('serviceDirectory')) :
            (null));
  }

  /**
   * Configuration for a [Service
   * Directory](https://cloud.google.com/service-directory) service.
   */
  set serviceDirectory(
      value: GoogleCloudDialogflowV3alpha1WebhookServiceDirectoryConfig|null) {
    this.Serializable$set('serviceDirectory', value);
  }

  get timeout(): string|null {
    return (
        (this.Serializable$has('timeout')) ?
            (this.Serializable$get('timeout')) :
            (null));
  }

  /**
   * Webhook execution timeout. Execution is considered failed if Dialogflow
   * doesn't receive a response from webhook at the end of the timeout period.
   * Defaults to 5 seconds, maximum allowed timeout is 30 seconds.
   */
  set timeout(value: string|null) {
    this.Serializable$set('timeout', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudDialogflowV3alpha1Webhook> {
    return GoogleCloudDialogflowV3alpha1Webhook;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'cloudFunction', 'disabled', 'displayName', 'etag', 'genericWebService',
        'name', 'serviceDirectory', 'timeout'
      ],
      objects: {
        'cloudFunction': GoogleCloudDialogflowV3alpha1WebhookCloudFunction,
        'genericWebService':
            GoogleCloudDialogflowV3alpha1WebhookGenericWebService,
        'serviceDirectory':
            GoogleCloudDialogflowV3alpha1WebhookServiceDirectoryConfig
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookCloudFunctionParameters {
  uri?: string|null;
  requestHeaders?: ApiClientObjectMap<string>|null;
}
export class GoogleCloudDialogflowV3alpha1WebhookCloudFunction extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1WebhookCloudFunctionParameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'requestHeaders',
        (parameters.requestHeaders == null) ? (null) :
                                              (parameters.requestHeaders));
  }

  get requestHeaders(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('requestHeaders')) ?
            (this.Serializable$get('requestHeaders')) :
            (null));
  }

  /**
   * The HTTP request headers to send together with webhook requests.
   */
  set requestHeaders(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('requestHeaders', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The URI for the cloud function.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1WebhookCloudFunction> {
    return GoogleCloudDialogflowV3alpha1WebhookCloudFunction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['requestHeaders', 'uri'],
      objectMaps: {
        'requestHeaders': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookConflictDiffParameters {
  displayName?: string|null;
  originalWebhookId?: string|null;
  importedWebhookId?: string|null;
}
export class GoogleCloudDialogflowV3alpha1WebhookConflictDiff extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1WebhookConflictDiffParameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'originalWebhookId',
        (parameters.originalWebhookId == null) ?
            (null) :
            (parameters.originalWebhookId));
    this.Serializable$set(
        'importedWebhookId',
        (parameters.importedWebhookId == null) ?
            (null) :
            (parameters.importedWebhookId));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Conflicting display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get importedWebhookId(): string|null {
    return (
        (this.Serializable$has('importedWebhookId')) ?
            (this.Serializable$get('importedWebhookId')) :
            (null));
  }

  /**
   * The name of the conflicting webhook in the content to be imported. This is
   * a unique identifier. Format: `projects//locations//agents//webhooks/`.
   */
  set importedWebhookId(value: string|null) {
    this.Serializable$set('importedWebhookId', value);
  }

  get originalWebhookId(): string|null {
    return (
        (this.Serializable$has('originalWebhookId')) ?
            (this.Serializable$get('originalWebhookId')) :
            (null));
  }

  /**
   * The name of the conflicting webhook in the current agent. This is a unique
   * identifier. Format: `projects//locations//agents//webhooks/`.
   */
  set originalWebhookId(value: string|null) {
    this.Serializable$set('originalWebhookId', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1WebhookConflictDiff> {
    return GoogleCloudDialogflowV3alpha1WebhookConflictDiff;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'importedWebhookId', 'originalWebhookId']};
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookGenericWebServiceParameters {
  uri?: string|null;
  username?: string|null;
  password?: string|null;
  requestHeaders?: ApiClientObjectMap<string>|null;
  allowedCaCerts?: Array<string>|null;
}
export class GoogleCloudDialogflowV3alpha1WebhookGenericWebService extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1WebhookGenericWebServiceParameters =
              {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'username',
        (parameters.username == null) ? (null) : (parameters.username));
    this.Serializable$set(
        'password',
        (parameters.password == null) ? (null) : (parameters.password));
    this.Serializable$set(
        'requestHeaders',
        (parameters.requestHeaders == null) ? (null) :
                                              (parameters.requestHeaders));
    this.Serializable$set(
        'allowedCaCerts',
        (parameters.allowedCaCerts == null) ? (null) :
                                              (parameters.allowedCaCerts));
  }

  get allowedCaCerts(): Array<string>|null {
    return (
        (this.Serializable$has('allowedCaCerts')) ?
            (this.Serializable$get('allowedCaCerts')) :
            (null));
  }

  /**
   * Optional. Specifies a list of allowed custom CA certificates (in DER
   * format) for HTTPS verification. This overrides the default SSL trust store.
   * If this is empty or unspecified, Dialogflow will use Google's default trust
   * store to verify certificates. N.B. Make sure the HTTPS server certificates
   * are signed with \"subject alt name\". For instance a certificate can be
   * self-signed using the following command, openssl x509 -req -days 200 -in
   * example.com.csr \ -signkey example.com.key \ -out example.com.crt \
   * -extfile <(printf \"\nsubjectAltName='DNS:www.example.com'\")
   */
  set allowedCaCerts(value: Array<string>|null) {
    this.Serializable$set('allowedCaCerts', value);
  }

  get password(): string|null {
    return (
        (this.Serializable$has('password')) ?
            (this.Serializable$get('password')) :
            (null));
  }

  /**
   * The password for HTTP Basic authentication.
   */
  set password(value: string|null) {
    this.Serializable$set('password', value);
  }

  get requestHeaders(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('requestHeaders')) ?
            (this.Serializable$get('requestHeaders')) :
            (null));
  }

  /**
   * The HTTP request headers to send together with webhook requests.
   */
  set requestHeaders(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('requestHeaders', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The webhook URI for receiving POST requests. It must use https
   * protocol.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  get username(): string|null {
    return (
        (this.Serializable$has('username')) ?
            (this.Serializable$get('username')) :
            (null));
  }

  /**
   * The user name for HTTP Basic authentication.
   */
  set username(value: string|null) {
    this.Serializable$set('username', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1WebhookGenericWebService> {
    return GoogleCloudDialogflowV3alpha1WebhookGenericWebService;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['allowedCaCerts', 'password', 'requestHeaders', 'uri', 'username'],
      objectMaps: {
        'requestHeaders': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookRequestParameters {
  detectIntentResponseId?: string|null;
  text?: string|null;
  triggerIntent?: string|null;
  transcript?: string|null;
  triggerEvent?: string|null;
  languageCode?: string|null;
  fulfillmentInfo?: GoogleCloudDialogflowV3alpha1WebhookRequestFulfillmentInfo|
      null;
  intentInfo?: GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfo|null;
  pageInfo?: GoogleCloudDialogflowV3alpha1PageInfo|null;
  sessionInfo?: GoogleCloudDialogflowV3alpha1SessionInfo|null;
  messages?: Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|null;
  payload?: ApiClientObjectMap<any>|null;
  sentimentAnalysisResult?:
      GoogleCloudDialogflowV3alpha1WebhookRequestSentimentAnalysisResult|null;
  matchClarification?: GoogleCloudDialogflowV3alpha1MatchClarification|null;
}
export class GoogleCloudDialogflowV3alpha1WebhookRequest extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1WebhookRequestParameters = {}) {
    super();
    this.Serializable$set(
        'detectIntentResponseId',
        (parameters.detectIntentResponseId == null) ?
            (null) :
            (parameters.detectIntentResponseId));
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'triggerIntent',
        (parameters.triggerIntent == null) ? (null) :
                                             (parameters.triggerIntent));
    this.Serializable$set(
        'transcript',
        (parameters.transcript == null) ? (null) : (parameters.transcript));
    this.Serializable$set(
        'triggerEvent',
        (parameters.triggerEvent == null) ? (null) : (parameters.triggerEvent));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'fulfillmentInfo',
        (parameters.fulfillmentInfo == null) ? (null) :
                                               (parameters.fulfillmentInfo));
    this.Serializable$set(
        'intentInfo',
        (parameters.intentInfo == null) ? (null) : (parameters.intentInfo));
    this.Serializable$set(
        'pageInfo',
        (parameters.pageInfo == null) ? (null) : (parameters.pageInfo));
    this.Serializable$set(
        'sessionInfo',
        (parameters.sessionInfo == null) ? (null) : (parameters.sessionInfo));
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'sentimentAnalysisResult',
        (parameters.sentimentAnalysisResult == null) ?
            (null) :
            (parameters.sentimentAnalysisResult));
    this.Serializable$set(
        'matchClarification',
        (parameters.matchClarification == null) ?
            (null) :
            (parameters.matchClarification));
  }

  get detectIntentResponseId(): string|null {
    return (
        (this.Serializable$has('detectIntentResponseId')) ?
            (this.Serializable$get('detectIntentResponseId')) :
            (null));
  }

  /**
   * Always present. The unique identifier of the DetectIntentResponse that will
   * be returned to the API caller.
   */
  set detectIntentResponseId(value: string|null) {
    this.Serializable$set('detectIntentResponseId', value);
  }

  get fulfillmentInfo():
      GoogleCloudDialogflowV3alpha1WebhookRequestFulfillmentInfo|null {
    return (
        (this.Serializable$has('fulfillmentInfo')) ?
            (this.Serializable$get('fulfillmentInfo')) :
            (null));
  }

  /**
   * Always present. Information about the fulfillment that triggered this
   * webhook call.
   */
  set fulfillmentInfo(
      value: GoogleCloudDialogflowV3alpha1WebhookRequestFulfillmentInfo|null) {
    this.Serializable$set('fulfillmentInfo', value);
  }

  get intentInfo(): GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfo|null {
    return (
        (this.Serializable$has('intentInfo')) ?
            (this.Serializable$get('intentInfo')) :
            (null));
  }

  /**
   * Information about the last matched intent.
   */
  set intentInfo(value: GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfo|
                 null) {
    this.Serializable$set('intentInfo', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language code specified in the original request.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get matchClarification(): GoogleCloudDialogflowV3alpha1MatchClarification
      |null {
    return (
        (this.Serializable$has('matchClarification')) ?
            (this.Serializable$get('matchClarification')) :
            (null));
  }

  /**
   * Represents that clarification is needed from the end user.
   */
  set matchClarification(value: GoogleCloudDialogflowV3alpha1MatchClarification|
                         null) {
    this.Serializable$set('matchClarification', value);
  }

  get messages(): Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The list of rich message responses to present to the user. Webhook can
   * choose to append or replace this list in
   * WebhookResponse.fulfillment_response;
   */
  set messages(value: Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|
               null) {
    this.Serializable$set('messages', value);
  }

  get pageInfo(): GoogleCloudDialogflowV3alpha1PageInfo|null {
    return (
        (this.Serializable$has('pageInfo')) ?
            (this.Serializable$get('pageInfo')) :
            (null));
  }

  /**
   * Information about page status.
   */
  set pageInfo(value: GoogleCloudDialogflowV3alpha1PageInfo|null) {
    this.Serializable$set('pageInfo', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Custom data set in QueryParameters.payload.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get sentimentAnalysisResult():
      GoogleCloudDialogflowV3alpha1WebhookRequestSentimentAnalysisResult|null {
    return (
        (this.Serializable$has('sentimentAnalysisResult')) ?
            (this.Serializable$get('sentimentAnalysisResult')) :
            (null));
  }

  /**
   * The sentiment analysis result of the current user request. The field is
   * filled when sentiment analysis is configured to be enabled for the request.
   */
  set sentimentAnalysisResult(
      value: GoogleCloudDialogflowV3alpha1WebhookRequestSentimentAnalysisResult|
      null) {
    this.Serializable$set('sentimentAnalysisResult', value);
  }

  get sessionInfo(): GoogleCloudDialogflowV3alpha1SessionInfo|null {
    return (
        (this.Serializable$has('sessionInfo')) ?
            (this.Serializable$get('sessionInfo')) :
            (null));
  }

  /**
   * Information about session status.
   */
  set sessionInfo(value: GoogleCloudDialogflowV3alpha1SessionInfo|null) {
    this.Serializable$set('sessionInfo', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * If natural language text was provided as input, this field will contain a
   * copy of the text.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  get transcript(): string|null {
    return (
        (this.Serializable$has('transcript')) ?
            (this.Serializable$get('transcript')) :
            (null));
  }

  /**
   * If natural language speech audio was provided as input, this field will
   * contain the transcript for the audio.
   */
  set transcript(value: string|null) {
    this.Serializable$set('transcript', value);
  }

  get triggerEvent(): string|null {
    return (
        (this.Serializable$has('triggerEvent')) ?
            (this.Serializable$get('triggerEvent')) :
            (null));
  }

  /**
   * If an event was provided as input, this field will contain the name of the
   * event.
   */
  set triggerEvent(value: string|null) {
    this.Serializable$set('triggerEvent', value);
  }

  get triggerIntent(): string|null {
    return (
        (this.Serializable$has('triggerIntent')) ?
            (this.Serializable$get('triggerIntent')) :
            (null));
  }

  /**
   * If an intent was provided as input, this field will contain a copy of the
   * intent identifier. Format: `projects//locations//agents//intents/`.
   */
  set triggerIntent(value: string|null) {
    this.Serializable$set('triggerIntent', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1WebhookRequest> {
    return GoogleCloudDialogflowV3alpha1WebhookRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'messages': GoogleCloudDialogflowV3alpha1ResponseMessage},
      keys: [
        'detectIntentResponseId', 'fulfillmentInfo', 'intentInfo',
        'languageCode', 'matchClarification', 'messages', 'pageInfo', 'payload',
        'sentimentAnalysisResult', 'sessionInfo', 'text', 'transcript',
        'triggerEvent', 'triggerIntent'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'fulfillmentInfo':
            GoogleCloudDialogflowV3alpha1WebhookRequestFulfillmentInfo,
        'intentInfo': GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfo,
        'matchClarification': GoogleCloudDialogflowV3alpha1MatchClarification,
        'pageInfo': GoogleCloudDialogflowV3alpha1PageInfo,
        'sentimentAnalysisResult':
            GoogleCloudDialogflowV3alpha1WebhookRequestSentimentAnalysisResult,
        'sessionInfo': GoogleCloudDialogflowV3alpha1SessionInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookRequestFulfillmentInfoParameters {
  tag?: string|null;
}
export class GoogleCloudDialogflowV3alpha1WebhookRequestFulfillmentInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1WebhookRequestFulfillmentInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'tag', (parameters.tag == null) ? (null) : (parameters.tag));
  }

  get tag(): string|null {
    return (
        (this.Serializable$has('tag')) ? (this.Serializable$get('tag')) :
                                         (null));
  }

  /**
   * Always present. The value of the Fulfillment.tag field will be populated in
   * this field by Dialogflow when the associated webhook is called. The tag is
   * typically used by the webhook service to identify which fulfillment is
   * being called, but it could be used for other purposes.
   */
  set tag(value: string|null) {
    this.Serializable$set('tag', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1WebhookRequestFulfillmentInfo> {
    return GoogleCloudDialogflowV3alpha1WebhookRequestFulfillmentInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['tag']};
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoParameters {
  lastMatchedIntent?: string|null;
  displayName?: string|null;
  parameters?: ApiClientObjectMap<
      GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoIntentParameterValue>|
      null;
  confidence?: number|null;
}
export class GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfo extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoParameters =
              {}) {
    super();
    this.Serializable$set(
        'lastMatchedIntent',
        (parameters.lastMatchedIntent == null) ?
            (null) :
            (parameters.lastMatchedIntent));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The confidence of the matched intent. Values range from 0.0 (completely
   * uncertain) to 1.0 (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Always present. The display name of the last matched intent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get lastMatchedIntent(): string|null {
    return (
        (this.Serializable$has('lastMatchedIntent')) ?
            (this.Serializable$get('lastMatchedIntent')) :
            (null));
  }

  /**
   * Always present. The unique identifier of the last matched intent. Format:
   * `projects//locations//agents//intents/`.
   */
  set lastMatchedIntent(value: string|null) {
    this.Serializable$set('lastMatchedIntent', value);
  }

  get parameters(): ApiClientObjectMap<
      GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoIntentParameterValue>|
      null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Parameters identified as a result of intent matching. This is a map of the
   * name of the identified parameter to the value of the parameter identified
   * from the user's utterance. All parameters defined in the matched intent
   * that are identified will be surfaced here.
   */
  set parameters(
      value: ApiClientObjectMap<
          GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoIntentParameterValue>|
      null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfo> {
    return GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfo;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['confidence', 'displayName', 'lastMatchedIntent', 'parameters'],
      objectMaps: {
        'parameters': {
          ctor:
              GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoIntentParameterValue,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoIntentParameterValueParameters {
  originalValue?: string|null;
  resolvedValue?: any|null;
}
export class
    GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoIntentParameterValue extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoIntentParameterValueParameters =
              {}) {
    super();
    this.Serializable$set(
        'originalValue',
        (parameters.originalValue == null) ? (null) :
                                             (parameters.originalValue));
    this.Serializable$set(
        'resolvedValue',
        (parameters.resolvedValue == null) ? (null) :
                                             (parameters.resolvedValue));
  }

  get originalValue(): string|null {
    return (
        (this.Serializable$has('originalValue')) ?
            (this.Serializable$get('originalValue')) :
            (null));
  }

  /**
   * Always present. Original text value extracted from user utterance.
   */
  set originalValue(value: string|null) {
    this.Serializable$set('originalValue', value);
  }

  get resolvedValue(): any|null {
    return (
        (this.Serializable$has('resolvedValue')) ?
            (this.Serializable$get('resolvedValue')) :
            (null));
  }

  /**
   * Always present. Structured value for the parameter extracted from user
   * utterance.
   */
  set resolvedValue(value: any|null) {
    this.Serializable$set('resolvedValue', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoIntentParameterValue> {
    return GoogleCloudDialogflowV3alpha1WebhookRequestIntentInfoIntentParameterValue;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['originalValue', 'resolvedValue']};
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookRequestSentimentAnalysisResultParameters {
  score?: number|null;
  magnitude?: number|null;
}
export class
    GoogleCloudDialogflowV3alpha1WebhookRequestSentimentAnalysisResult extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1WebhookRequestSentimentAnalysisResultParameters =
              {}) {
    super();
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'magnitude',
        (parameters.magnitude == null) ? (null) : (parameters.magnitude));
  }

  get magnitude(): number|null {
    return (
        (this.Serializable$has('magnitude')) ?
            (this.Serializable$get('magnitude')) :
            (null));
  }

  /**
   * A non-negative number in the [0, +inf) range, which represents the absolute
   * magnitude of sentiment, regardless of score (positive or negative).
   */
  set magnitude(value: number|null) {
    this.Serializable$set('magnitude', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Sentiment score between -1.0 (negative sentiment) and 1.0 (positive
   * sentiment).
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1WebhookRequestSentimentAnalysisResult> {
    return GoogleCloudDialogflowV3alpha1WebhookRequestSentimentAnalysisResult;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['magnitude', 'score']};
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookResponseParameters {
  fulfillmentResponse?:
      GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponse|null;
  pageInfo?: GoogleCloudDialogflowV3alpha1PageInfo|null;
  sessionInfo?: GoogleCloudDialogflowV3alpha1SessionInfo|null;
  payload?: ApiClientObjectMap<any>|null;
  targetPage?: string|null;
  targetFlow?: string|null;
  match?: GoogleCloudDialogflowV3alpha1Match|null;
}
export class GoogleCloudDialogflowV3alpha1WebhookResponse extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1WebhookResponseParameters = {}) {
    super();
    this.Serializable$set(
        'fulfillmentResponse',
        (parameters.fulfillmentResponse == null) ?
            (null) :
            (parameters.fulfillmentResponse));
    this.Serializable$set(
        'pageInfo',
        (parameters.pageInfo == null) ? (null) : (parameters.pageInfo));
    this.Serializable$set(
        'sessionInfo',
        (parameters.sessionInfo == null) ? (null) : (parameters.sessionInfo));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'targetPage',
        (parameters.targetPage == null) ? (null) : (parameters.targetPage));
    this.Serializable$set(
        'targetFlow',
        (parameters.targetFlow == null) ? (null) : (parameters.targetFlow));
    this.Serializable$set(
        'match', (parameters.match == null) ? (null) : (parameters.match));
  }

  get fulfillmentResponse():
      GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponse|null {
    return (
        (this.Serializable$has('fulfillmentResponse')) ?
            (this.Serializable$get('fulfillmentResponse')) :
            (null));
  }

  /**
   * The fulfillment response to send to the user. This field can be omitted by
   * the webhook if it does not intend to send any response to the user.
   */
  set fulfillmentResponse(
      value: GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponse|
      null) {
    this.Serializable$set('fulfillmentResponse', value);
  }

  get match(): GoogleCloudDialogflowV3alpha1Match|null {
    return (
        (this.Serializable$has('match')) ? (this.Serializable$get('match')) :
                                           (null));
  }

  /**
   * Optional. Used by the webhook to disambiguate matched intents. If provided,
   * Dialogflow will use the disambiguated intent to continue evaluating [state
   * handlers](https://cloud.google.com/dialogflow/cx/docs/concept/handler).
   */
  set match(value: GoogleCloudDialogflowV3alpha1Match|null) {
    this.Serializable$set('match', value);
  }

  get pageInfo(): GoogleCloudDialogflowV3alpha1PageInfo|null {
    return (
        (this.Serializable$has('pageInfo')) ?
            (this.Serializable$get('pageInfo')) :
            (null));
  }

  /**
   * Information about page status. This field can be omitted by the webhook if
   * it does not intend to modify page status.
   */
  set pageInfo(value: GoogleCloudDialogflowV3alpha1PageInfo|null) {
    this.Serializable$set('pageInfo', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Value to append directly to QueryResult.webhook_payloads.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get sessionInfo(): GoogleCloudDialogflowV3alpha1SessionInfo|null {
    return (
        (this.Serializable$has('sessionInfo')) ?
            (this.Serializable$get('sessionInfo')) :
            (null));
  }

  /**
   * Information about session status. This field can be omitted by the webhook
   * if it does not intend to modify session status.
   */
  set sessionInfo(value: GoogleCloudDialogflowV3alpha1SessionInfo|null) {
    this.Serializable$set('sessionInfo', value);
  }

  get targetFlow(): string|null {
    return (
        (this.Serializable$has('targetFlow')) ?
            (this.Serializable$get('targetFlow')) :
            (null));
  }

  /**
   * The target flow to transition to. Format:
   * `projects//locations//agents//flows/`.
   */
  set targetFlow(value: string|null) {
    this.Serializable$set('targetFlow', value);
  }

  get targetPage(): string|null {
    return (
        (this.Serializable$has('targetPage')) ?
            (this.Serializable$get('targetPage')) :
            (null));
  }

  /**
   * The target page to transition to. Format:
   * `projects//locations//agents//flows//pages/`.
   */
  set targetPage(value: string|null) {
    this.Serializable$set('targetPage', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1WebhookResponse> {
    return GoogleCloudDialogflowV3alpha1WebhookResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'fulfillmentResponse', 'match', 'pageInfo', 'payload', 'sessionInfo',
        'targetFlow', 'targetPage'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'fulfillmentResponse':
            GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponse,
        'match': GoogleCloudDialogflowV3alpha1Match,
        'pageInfo': GoogleCloudDialogflowV3alpha1PageInfo,
        'sessionInfo': GoogleCloudDialogflowV3alpha1SessionInfo
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseParameters {
  messages?: Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|null;
  mergeBehavior?:
      GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior|
      null;
}
export class
    GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'mergeBehavior',
        (parameters.mergeBehavior == null) ? (null) :
                                             (parameters.mergeBehavior));
  }

  static get MergeBehavior():
      IGoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehaviorEnum {
    return GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehaviorEnum;
  }

  get mergeBehavior():
      GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior
      |null {
    return (
        (this.Serializable$has('mergeBehavior')) ?
            (this.Serializable$get('mergeBehavior')) :
            (null));
  }

  /**
   * Merge behavior for `messages`.
   */
  set mergeBehavior(
      value:
          GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehavior|
      null) {
    this.Serializable$set('mergeBehavior', value);
  }

  get messages(): Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The list of rich message responses to present to the user.
   */
  set messages(value: Array<GoogleCloudDialogflowV3alpha1ResponseMessage>|
               null) {
    this.Serializable$set('messages', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponse> {
    return GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'messages': GoogleCloudDialogflowV3alpha1ResponseMessage},
      enums: {
        'mergeBehavior':
            GoogleCloudDialogflowV3alpha1WebhookResponseFulfillmentResponseMergeBehaviorEnum
      },
      keys: ['mergeBehavior', 'messages']
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookServiceDirectoryConfigParameters {
  service?: string|null;
  genericWebService?: GoogleCloudDialogflowV3alpha1WebhookGenericWebService|
      null;
}
export class GoogleCloudDialogflowV3alpha1WebhookServiceDirectoryConfig extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudDialogflowV3alpha1WebhookServiceDirectoryConfigParameters =
              {}) {
    super();
    this.Serializable$set(
        'service',
        (parameters.service == null) ? (null) : (parameters.service));
    this.Serializable$set(
        'genericWebService',
        (parameters.genericWebService == null) ?
            (null) :
            (parameters.genericWebService));
  }

  get genericWebService(): GoogleCloudDialogflowV3alpha1WebhookGenericWebService
      |null {
    return (
        (this.Serializable$has('genericWebService')) ?
            (this.Serializable$get('genericWebService')) :
            (null));
  }

  /**
   * Generic Service configuration of this webhook.
   */
  set genericWebService(
      value: GoogleCloudDialogflowV3alpha1WebhookGenericWebService|null) {
    this.Serializable$set('genericWebService', value);
  }

  get service(): string|null {
    return (
        (this.Serializable$has('service')) ?
            (this.Serializable$get('service')) :
            (null));
  }

  /**
   * Required. The name of [Service
   * Directory](https://cloud.google.com/service-directory) service. Format:
   * `projects//locations//namespaces//services/`. `Location ID` of the service
   * directory must be the same as the location of the agent.
   */
  set service(value: string|null) {
    this.Serializable$set('service', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudDialogflowV3alpha1WebhookServiceDirectoryConfig> {
    return GoogleCloudDialogflowV3alpha1WebhookServiceDirectoryConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['genericWebService', 'service'],
      objects: {
        'genericWebService':
            GoogleCloudDialogflowV3alpha1WebhookGenericWebService
      }
    };
  }
}

export interface GoogleCloudDialogflowV3alpha1WebhookSettingsParameters {
  disableTransition?: boolean|null;
  disableGenerateAgentResponse?: boolean|null;
  enforceParameterDefinitions?: boolean|null;
}
export class GoogleCloudDialogflowV3alpha1WebhookSettings extends Serializable {
  constructor(
      parameters: GoogleCloudDialogflowV3alpha1WebhookSettingsParameters = {}) {
    super();
    this.Serializable$set(
        'disableTransition',
        (parameters.disableTransition == null) ?
            (null) :
            (parameters.disableTransition));
    this.Serializable$set(
        'disableGenerateAgentResponse',
        (parameters.disableGenerateAgentResponse == null) ?
            (null) :
            (parameters.disableGenerateAgentResponse));
    this.Serializable$set(
        'enforceParameterDefinitions',
        (parameters.enforceParameterDefinitions == null) ?
            (null) :
            (parameters.enforceParameterDefinitions));
  }

  get disableGenerateAgentResponse(): boolean|null {
    return (
        (this.Serializable$has('disableGenerateAgentResponse')) ?
            (this.Serializable$get('disableGenerateAgentResponse')) :
            (null));
  }

  /**
   * Whether the webhook can generate agent responses. If this flag is true,
   * `fulfillment_response` will be ignored in the WebhookResponse.
   */
  set disableGenerateAgentResponse(value: boolean|null) {
    this.Serializable$set('disableGenerateAgentResponse', value);
  }

  get disableTransition(): boolean|null {
    return (
        (this.Serializable$has('disableTransition')) ?
            (this.Serializable$get('disableTransition')) :
            (null));
  }

  /**
   * Whether a webhook can transit the conversation to another page or flow. If
   * this flag is true, `target_page` and `target_flow` are ignored in the
   * WebhookResponse.
   */
  set disableTransition(value: boolean|null) {
    this.Serializable$set('disableTransition', value);
  }

  get enforceParameterDefinitions(): boolean|null {
    return (
        (this.Serializable$has('enforceParameterDefinitions')) ?
            (this.Serializable$get('enforceParameterDefinitions')) :
            (null));
  }

  /**
   * Whether parameter definitions will be enforced: 1. Only parameters in the
   * input parameters can be sent to the webhook. 2. Only parameters in the
   * output parameters can be modified by the webhook. 3. Parameter types will
   * be checked and only set if they match the type. Parameter types are defined
   * in the agent.
   */
  set enforceParameterDefinitions(value: boolean|null) {
    this.Serializable$set('enforceParameterDefinitions', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudDialogflowV3alpha1WebhookSettings> {
    return GoogleCloudDialogflowV3alpha1WebhookSettings;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'disableGenerateAgentResponse', 'disableTransition',
        'enforceParameterDefinitions'
      ]
    };
  }
}

export interface GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesRequestParameters {
  questionAnswerEntries?:
      Array<GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry>|null;
  updateMask?: string|null;
}
export class
    GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesRequest extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesRequestParameters =
              {}) {
    super();
    this.Serializable$set(
        'questionAnswerEntries',
        (parameters.questionAnswerEntries == null) ?
            (null) :
            (parameters.questionAnswerEntries));
    this.Serializable$set(
        'updateMask',
        (parameters.updateMask == null) ? (null) : (parameters.updateMask));
  }

  get questionAnswerEntries():
      Array<GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry>|null {
    return (
        (this.Serializable$has('questionAnswerEntries')) ?
            (this.Serializable$get('questionAnswerEntries')) :
            (null));
  }

  /**
   * Required. The list of question-answer-entries to update.
   */
  set questionAnswerEntries(
      value: Array<GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry>|null) {
    this.Serializable$set('questionAnswerEntries', value);
  }

  get updateMask(): string|null {
    return (
        (this.Serializable$has('updateMask')) ?
            (this.Serializable$get('updateMask')) :
            (null));
  }

  /**
   * Optional. The mask for question-answer-entries to control which fields get
   * updated.
   */
  set updateMask(value: string|null) {
    this.Serializable$set('updateMask', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesRequest> {
    return GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesRequest;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'questionAnswerEntries': GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry
      },
      keys: ['questionAnswerEntries', 'updateMask']
    };
  }
}

export interface GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesResponseParameters {
  questionAnswerEntries?:
      Array<GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry>|null;
}
export class
    GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesResponse extends
        Serializable {
  constructor(
      parameters:
          GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesResponseParameters =
              {}) {
    super();
    this.Serializable$set(
        'questionAnswerEntries',
        (parameters.questionAnswerEntries == null) ?
            (null) :
            (parameters.questionAnswerEntries));
  }

  get questionAnswerEntries():
      Array<GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry>|null {
    return (
        (this.Serializable$has('questionAnswerEntries')) ?
            (this.Serializable$get('questionAnswerEntries')) :
            (null));
  }

  /**
   * The list of question-answer-entries.
   */
  set questionAnswerEntries(
      value: Array<GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry>|null) {
    this.Serializable$set('questionAnswerEntries', value);
  }

  getConstructor(): SerializableCtor<
      GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesResponse> {
    return GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'questionAnswerEntries': GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry
      },
      keys: ['questionAnswerEntries']
    };
  }
}

export interface GoogleCloudKnowledgeV1alpha1OperationMetadataParameters {
  state?: GoogleCloudKnowledgeV1alpha1OperationMetadataState|null;
  message?: Array<string>|null;
  errorCode?: Array<number>|null;
}
export class GoogleCloudKnowledgeV1alpha1OperationMetadata extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudKnowledgeV1alpha1OperationMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'message',
        (parameters.message == null) ? (null) : (parameters.message));
    this.Serializable$set(
        'errorCode',
        (parameters.errorCode == null) ? (null) : (parameters.errorCode));
  }

  static get State(): IGoogleCloudKnowledgeV1alpha1OperationMetadataStateEnum {
    return GoogleCloudKnowledgeV1alpha1OperationMetadataStateEnum;
  }

  get errorCode(): Array<number>|null {
    return (
        (this.Serializable$has('errorCode')) ?
            (this.Serializable$get('errorCode')) :
            (null));
  }

  /**
   * The error codes from Manifold endpoints' last failures.
   */
  set errorCode(value: Array<number>|null) {
    this.Serializable$set('errorCode', value);
  }

  get message(): Array<string>|null {
    return (
        (this.Serializable$has('message')) ?
            (this.Serializable$get('message')) :
            (null));
  }

  /**
   * The failure messages from Manifold endpoints' last failures.
   */
  set message(value: Array<string>|null) {
    this.Serializable$set('message', value);
  }

  get state(): GoogleCloudKnowledgeV1alpha1OperationMetadataState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Required. The current state of this operation.
   */
  set state(value: GoogleCloudKnowledgeV1alpha1OperationMetadataState|null) {
    this.Serializable$set('state', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudKnowledgeV1alpha1OperationMetadata> {
    return GoogleCloudKnowledgeV1alpha1OperationMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': GoogleCloudKnowledgeV1alpha1OperationMetadataStateEnum},
      keys: ['errorCode', 'message', 'state']
    };
  }
}

export interface GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryParameters {
  name?: string|null;
  question?: string|null;
  answer?: string|null;
  state?: GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState|null;
}
export class GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry extends
    Serializable {
  constructor(
      parameters:
          GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'question',
        (parameters.question == null) ? (null) : (parameters.question));
    this.Serializable$set(
        'answer', (parameters.answer == null) ? (null) : (parameters.answer));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
  }

  static get State():
      IGoogleCloudKnowledgeV1alpha1QuestionAnswerEntryStateEnum {
    return GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryStateEnum;
  }

  get answer(): string|null {
    return (
        (this.Serializable$has('answer')) ? (this.Serializable$get('answer')) :
                                            (null));
  }

  /**
   * Output only. Answer text.
   */
  set answer(value: string|null) {
    this.Serializable$set('answer', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The name of the question-answer-entry.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get question(): string|null {
    return (
        (this.Serializable$has('question')) ?
            (this.Serializable$get('question')) :
            (null));
  }

  /**
   * Output only. The question for the answer.
   */
  set question(value: string|null) {
    this.Serializable$set('question', value);
  }

  get state(): GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Required. The state of the question-answer-entry.
   */
  set state(value: GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryState|null) {
    this.Serializable$set('state', value);
  }

  getConstructor():
      SerializableCtor<GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry> {
    return GoogleCloudKnowledgeV1alpha1QuestionAnswerEntry;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums:
          {'state': GoogleCloudKnowledgeV1alpha1QuestionAnswerEntryStateEnum},
      keys: ['answer', 'name', 'question', 'state']
    };
  }
}

export interface GoogleCloudLocationListLocationsResponseParameters {
  locations?: Array<GoogleCloudLocationLocation>|null;
  nextPageToken?: string|null;
}
export class GoogleCloudLocationListLocationsResponse extends Serializable {
  constructor(
      parameters: GoogleCloudLocationListLocationsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'locations',
        (parameters.locations == null) ? (null) : (parameters.locations));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get locations(): Array<GoogleCloudLocationLocation>|null {
    return (
        (this.Serializable$has('locations')) ?
            (this.Serializable$get('locations')) :
            (null));
  }

  /**
   * A list of locations that matches the specified filter in the request.
   */
  set locations(value: Array<GoogleCloudLocationLocation>|null) {
    this.Serializable$set('locations', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * The standard List next-page token.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudLocationListLocationsResponse> {
    return GoogleCloudLocationListLocationsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'locations': GoogleCloudLocationLocation},
      keys: ['locations', 'nextPageToken']
    };
  }
}

export interface GoogleCloudLocationLocationParameters {
  name?: string|null;
  locationId?: string|null;
  displayName?: string|null;
  labels?: ApiClientObjectMap<string>|null;
  metadata?: ApiClientObjectMap<any>|null;
}
export class GoogleCloudLocationLocation extends Serializable {
  constructor(parameters: GoogleCloudLocationLocationParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'locationId',
        (parameters.locationId == null) ? (null) : (parameters.locationId));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * The friendly name for this location, typically a nearby city name. For
   * example, \"Tokyo\".
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get labels(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * Cross-service attributes for the location. For
   * example {\"cloud.googleapis.com/region\": \"us-east1\"}
   */
  set labels(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('labels', value);
  }

  get locationId(): string|null {
    return (
        (this.Serializable$has('locationId')) ?
            (this.Serializable$get('locationId')) :
            (null));
  }

  /**
   * The canonical id for this location. For example: `\"us-east1\"`.
   */
  set locationId(value: string|null) {
    this.Serializable$set('locationId', value);
  }

  get metadata(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Service-specific metadata. For example the available capacity at the given
   * location.
   */
  set metadata(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('metadata', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Resource name for the location, which may vary between implementations. For
   * example: `\"projects/example-project/locations/us-east1\"`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<GoogleCloudLocationLocation> {
    return GoogleCloudLocationLocation;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['displayName', 'labels', 'locationId', 'metadata', 'name'],
      objectMaps: {
        'labels': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleIamV1AuditConfigParameters {
  service?: string|null;
  auditLogConfigs?: Array<GoogleIamV1AuditLogConfig>|null;
}
export class GoogleIamV1AuditConfig extends Serializable {
  constructor(parameters: GoogleIamV1AuditConfigParameters = {}) {
    super();
    this.Serializable$set(
        'service',
        (parameters.service == null) ? (null) : (parameters.service));
    this.Serializable$set(
        'auditLogConfigs',
        (parameters.auditLogConfigs == null) ? (null) :
                                               (parameters.auditLogConfigs));
  }

  get auditLogConfigs(): Array<GoogleIamV1AuditLogConfig>|null {
    return (
        (this.Serializable$has('auditLogConfigs')) ?
            (this.Serializable$get('auditLogConfigs')) :
            (null));
  }

  /**
   * The configuration for logging of each type of permission.
   */
  set auditLogConfigs(value: Array<GoogleIamV1AuditLogConfig>|null) {
    this.Serializable$set('auditLogConfigs', value);
  }

  get service(): string|null {
    return (
        (this.Serializable$has('service')) ?
            (this.Serializable$get('service')) :
            (null));
  }

  /**
   * Specifies a service that will be enabled for audit logging. For example,
   * `storage.googleapis.com`, `cloudsql.googleapis.com`. `allServices` is a
   * special value that covers all services.
   */
  set service(value: string|null) {
    this.Serializable$set('service', value);
  }

  getConstructor(): SerializableCtor<GoogleIamV1AuditConfig> {
    return GoogleIamV1AuditConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'auditLogConfigs': GoogleIamV1AuditLogConfig},
      keys: ['auditLogConfigs', 'service']
    };
  }
}

export interface GoogleIamV1AuditLogConfigParameters {
  logType?: GoogleIamV1AuditLogConfigLogType|null;
  exemptedMembers?: Array<string>|null;
  ignoreChildExemptions?: boolean|null;
}
export class GoogleIamV1AuditLogConfig extends Serializable {
  constructor(parameters: GoogleIamV1AuditLogConfigParameters = {}) {
    super();
    this.Serializable$set(
        'logType',
        (parameters.logType == null) ? (null) : (parameters.logType));
    this.Serializable$set(
        'exemptedMembers',
        (parameters.exemptedMembers == null) ? (null) :
                                               (parameters.exemptedMembers));
    this.Serializable$set(
        'ignoreChildExemptions',
        (parameters.ignoreChildExemptions == null) ?
            (null) :
            (parameters.ignoreChildExemptions));
  }

  static get LogType(): IGoogleIamV1AuditLogConfigLogTypeEnum {
    return GoogleIamV1AuditLogConfigLogTypeEnum;
  }

  get exemptedMembers(): Array<string>|null {
    return (
        (this.Serializable$has('exemptedMembers')) ?
            (this.Serializable$get('exemptedMembers')) :
            (null));
  }

  /**
   * Specifies the identities that do not cause logging for this type of
   * permission. Follows the same format of Binding.members.
   */
  set exemptedMembers(value: Array<string>|null) {
    this.Serializable$set('exemptedMembers', value);
  }

  get ignoreChildExemptions(): boolean|null {
    return (
        (this.Serializable$has('ignoreChildExemptions')) ?
            (this.Serializable$get('ignoreChildExemptions')) :
            (null));
  }

  set ignoreChildExemptions(value: boolean|null) {
    this.Serializable$set('ignoreChildExemptions', value);
  }

  get logType(): GoogleIamV1AuditLogConfigLogType|null {
    return (
        (this.Serializable$has('logType')) ?
            (this.Serializable$get('logType')) :
            (null));
  }

  /**
   * The log type that this config enables.
   */
  set logType(value: GoogleIamV1AuditLogConfigLogType|null) {
    this.Serializable$set('logType', value);
  }

  getConstructor(): SerializableCtor<GoogleIamV1AuditLogConfig> {
    return GoogleIamV1AuditLogConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'logType': GoogleIamV1AuditLogConfigLogTypeEnum},
      keys: ['exemptedMembers', 'ignoreChildExemptions', 'logType']
    };
  }
}

export interface GoogleIamV1BindingParameters {
  role?: string|null;
  members?: Array<string>|null;
  condition?: GoogleTypeExpr|null;
  bindingId?: string|null;
}
export class GoogleIamV1Binding extends Serializable {
  constructor(parameters: GoogleIamV1BindingParameters = {}) {
    super();
    this.Serializable$set(
        'role', (parameters.role == null) ? (null) : (parameters.role));
    this.Serializable$set(
        'members',
        (parameters.members == null) ? (null) : (parameters.members));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'bindingId',
        (parameters.bindingId == null) ? (null) : (parameters.bindingId));
  }

  get bindingId(): string|null {
    return (
        (this.Serializable$has('bindingId')) ?
            (this.Serializable$get('bindingId')) :
            (null));
  }

  set bindingId(value: string|null) {
    this.Serializable$set('bindingId', value);
  }

  get condition(): GoogleTypeExpr|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition that is associated with this binding. If the condition
   * evaluates to `true`, then this binding applies to the current request. If
   * the condition evaluates to `false`, then this binding does not apply to the
   * current request. However, a different role binding might grant the same
   * role to one or more of the principals in this binding. To learn which
   * resources support conditions in their IAM policies, see the [IAM
   * documentation](https://cloud.google.com/iam/help/conditions/resource-policies).
   */
  set condition(value: GoogleTypeExpr|null) {
    this.Serializable$set('condition', value);
  }

  get members(): Array<string>|null {
    return (
        (this.Serializable$has('members')) ?
            (this.Serializable$get('members')) :
            (null));
  }

  /**
   * Specifies the principals requesting access for a Google Cloud resource.
   * `members` can have the following values: * `allUsers`: A special identifier
   * that represents anyone who is on the internet; with or without a Google
   * account. * `allAuthenticatedUsers`: A special identifier that represents
   * anyone who is authenticated with a Google account or a service account.
   * Does not include identities that come from external identity providers
   * (IdPs) through identity federation. * `user:{emailid}`: An email address
   * that represents a specific Google account. For example, `alice@example.com`
   * . * `serviceAccount:{emailid}`: An email address that represents a Google
   * service account. For example, `my-other-app@appspot.gserviceaccount.com`. *
   * `serviceAccount:{projectid}.svc.id.goog[{namespace}/{kubernetes-sa}]`: An
   * identifier for a [Kubernetes service
   * account](https://cloud.google.com/kubernetes-engine/docs/how-to/kubernetes-service-accounts).
   * For example, `my-project.svc.id.goog[my-namespace/my-kubernetes-sa]`. *
   * `group:{emailid}`: An email address that represents a Google group. For
   * example, `admins@example.com`. * `deleted:user:{emailid}?uid={uniqueid}`:
   * An email address (plus unique identifier) representing a user that has been
   * recently deleted. For example,
   * `alice@example.com?uid=123456789012345678901`. If the user is recovered,
   * this value reverts to `user:{emailid}` and the recovered user retains the
   * role in the binding. * `deleted:serviceAccount:{emailid}?uid={uniqueid}`:
   * An email address (plus unique identifier) representing a service account
   * that has been recently deleted. For example,
   * `my-other-app@appspot.gserviceaccount.com?uid=123456789012345678901`. If
   * the service account is undeleted, this value reverts to
   * `serviceAccount:{emailid}` and the undeleted service account retains the
   * role in the binding. * `deleted:group:{emailid}?uid={uniqueid}`: An email
   * address (plus unique identifier) representing a Google group that has been
   * recently deleted. For example,
   * `admins@example.com?uid=123456789012345678901`. If the group is recovered,
   * this value reverts to `group:{emailid}` and the recovered group retains the
   * role in the binding. * `domain:{domain}`: The G Suite domain (primary) that
   * represents all the users of that domain. For example, `google.com` or
   * `example.com`.
   */
  set members(value: Array<string>|null) {
    this.Serializable$set('members', value);
  }

  get role(): string|null {
    return (
        (this.Serializable$has('role')) ? (this.Serializable$get('role')) :
                                          (null));
  }

  /**
   * Role that is assigned to the list of `members`, or principals. For example,
   * `roles/viewer`, `roles/editor`, or `roles/owner`.
   */
  set role(value: string|null) {
    this.Serializable$set('role', value);
  }

  getConstructor(): SerializableCtor<GoogleIamV1Binding> {
    return GoogleIamV1Binding;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['bindingId', 'condition', 'members', 'role'],
      objects: {'condition': GoogleTypeExpr}
    };
  }
}

export interface GoogleIamV1ConditionParameters {
  iam?: GoogleIamV1ConditionIam|null;
  sys?: GoogleIamV1ConditionSys|null;
  svc?: string|null;
  op?: GoogleIamV1ConditionOp|null;
  values?: Array<string>|null;
}
export class GoogleIamV1Condition extends Serializable {
  constructor(parameters: GoogleIamV1ConditionParameters = {}) {
    super();
    this.Serializable$set(
        'iam', (parameters.iam == null) ? (null) : (parameters.iam));
    this.Serializable$set(
        'sys', (parameters.sys == null) ? (null) : (parameters.sys));
    this.Serializable$set(
        'svc', (parameters.svc == null) ? (null) : (parameters.svc));
    this.Serializable$set(
        'op', (parameters.op == null) ? (null) : (parameters.op));
    this.Serializable$set(
        'values', (parameters.values == null) ? (null) : (parameters.values));
  }

  static get Iam(): IGoogleIamV1ConditionIamEnum {
    return GoogleIamV1ConditionIamEnum;
  }

  static get Op(): IGoogleIamV1ConditionOpEnum {
    return GoogleIamV1ConditionOpEnum;
  }

  static get Sys(): IGoogleIamV1ConditionSysEnum {
    return GoogleIamV1ConditionSysEnum;
  }

  get iam(): GoogleIamV1ConditionIam|null {
    return (
        (this.Serializable$has('iam')) ? (this.Serializable$get('iam')) :
                                         (null));
  }

  /**
   * Trusted attributes supplied by the IAM system.
   */
  set iam(value: GoogleIamV1ConditionIam|null) {
    this.Serializable$set('iam', value);
  }

  get op(): GoogleIamV1ConditionOp|null {
    return (
        (this.Serializable$has('op')) ? (this.Serializable$get('op')) : (null));
  }

  /**
   * An operator to apply the subject with.
   */
  set op(value: GoogleIamV1ConditionOp|null) {
    this.Serializable$set('op', value);
  }

  get svc(): string|null {
    return (
        (this.Serializable$has('svc')) ? (this.Serializable$get('svc')) :
                                         (null));
  }

  /**
   * Trusted attributes discharged by the service.
   */
  set svc(value: string|null) {
    this.Serializable$set('svc', value);
  }

  get sys(): GoogleIamV1ConditionSys|null {
    return (
        (this.Serializable$has('sys')) ? (this.Serializable$get('sys')) :
                                         (null));
  }

  /**
   * Trusted attributes supplied by any service that owns resources and uses the
   * IAM system for access control.
   */
  set sys(value: GoogleIamV1ConditionSys|null) {
    this.Serializable$set('sys', value);
  }

  get values(): Array<string>|null {
    return (
        (this.Serializable$has('values')) ? (this.Serializable$get('values')) :
                                            (null));
  }

  /**
   * The objects of the condition.
   */
  set values(value: Array<string>|null) {
    this.Serializable$set('values', value);
  }

  getConstructor(): SerializableCtor<GoogleIamV1Condition> {
    return GoogleIamV1Condition;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'iam': GoogleIamV1ConditionIamEnum,
        'op': GoogleIamV1ConditionOpEnum,
        'sys': GoogleIamV1ConditionSysEnum
      },
      keys: ['iam', 'op', 'svc', 'sys', 'values']
    };
  }
}

export interface GoogleIamV1LogConfigParameters {
  counter?: GoogleIamV1LogConfigCounterOptions|null;
  dataAccess?: GoogleIamV1LogConfigDataAccessOptions|null;
  cloudAudit?: GoogleIamV1LogConfigCloudAuditOptions|null;
}
export class GoogleIamV1LogConfig extends Serializable {
  constructor(parameters: GoogleIamV1LogConfigParameters = {}) {
    super();
    this.Serializable$set(
        'counter',
        (parameters.counter == null) ? (null) : (parameters.counter));
    this.Serializable$set(
        'dataAccess',
        (parameters.dataAccess == null) ? (null) : (parameters.dataAccess));
    this.Serializable$set(
        'cloudAudit',
        (parameters.cloudAudit == null) ? (null) : (parameters.cloudAudit));
  }

  get cloudAudit(): GoogleIamV1LogConfigCloudAuditOptions|null {
    return (
        (this.Serializable$has('cloudAudit')) ?
            (this.Serializable$get('cloudAudit')) :
            (null));
  }

  /**
   * Cloud audit options.
   */
  set cloudAudit(value: GoogleIamV1LogConfigCloudAuditOptions|null) {
    this.Serializable$set('cloudAudit', value);
  }

  get counter(): GoogleIamV1LogConfigCounterOptions|null {
    return (
        (this.Serializable$has('counter')) ?
            (this.Serializable$get('counter')) :
            (null));
  }

  /**
   * Counter options.
   */
  set counter(value: GoogleIamV1LogConfigCounterOptions|null) {
    this.Serializable$set('counter', value);
  }

  get dataAccess(): GoogleIamV1LogConfigDataAccessOptions|null {
    return (
        (this.Serializable$has('dataAccess')) ?
            (this.Serializable$get('dataAccess')) :
            (null));
  }

  /**
   * Data access options.
   */
  set dataAccess(value: GoogleIamV1LogConfigDataAccessOptions|null) {
    this.Serializable$set('dataAccess', value);
  }

  getConstructor(): SerializableCtor<GoogleIamV1LogConfig> {
    return GoogleIamV1LogConfig;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['cloudAudit', 'counter', 'dataAccess'],
      objects: {
        'cloudAudit': GoogleIamV1LogConfigCloudAuditOptions,
        'counter': GoogleIamV1LogConfigCounterOptions,
        'dataAccess': GoogleIamV1LogConfigDataAccessOptions
      }
    };
  }
}

export interface GoogleIamV1LogConfigCloudAuditOptionsParameters {
  logName?: GoogleIamV1LogConfigCloudAuditOptionsLogName|null;
  authorizationLoggingOptions?: GoogleCloudAuditAuthorizationLoggingOptions|
      null;
}
export class GoogleIamV1LogConfigCloudAuditOptions extends Serializable {
  constructor(
      parameters: GoogleIamV1LogConfigCloudAuditOptionsParameters = {}) {
    super();
    this.Serializable$set(
        'logName',
        (parameters.logName == null) ? (null) : (parameters.logName));
    this.Serializable$set(
        'authorizationLoggingOptions',
        (parameters.authorizationLoggingOptions == null) ?
            (null) :
            (parameters.authorizationLoggingOptions));
  }

  static get LogName(): IGoogleIamV1LogConfigCloudAuditOptionsLogNameEnum {
    return GoogleIamV1LogConfigCloudAuditOptionsLogNameEnum;
  }

  get authorizationLoggingOptions(): GoogleCloudAuditAuthorizationLoggingOptions
      |null {
    return (
        (this.Serializable$has('authorizationLoggingOptions')) ?
            (this.Serializable$get('authorizationLoggingOptions')) :
            (null));
  }

  /**
   * Information used by the Cloud Audit Logging pipeline.
   */
  set authorizationLoggingOptions(
      value: GoogleCloudAuditAuthorizationLoggingOptions|null) {
    this.Serializable$set('authorizationLoggingOptions', value);
  }

  get logName(): GoogleIamV1LogConfigCloudAuditOptionsLogName|null {
    return (
        (this.Serializable$has('logName')) ?
            (this.Serializable$get('logName')) :
            (null));
  }

  /**
   * The log_name to populate in the Cloud Audit Record.
   */
  set logName(value: GoogleIamV1LogConfigCloudAuditOptionsLogName|null) {
    this.Serializable$set('logName', value);
  }

  getConstructor(): SerializableCtor<GoogleIamV1LogConfigCloudAuditOptions> {
    return GoogleIamV1LogConfigCloudAuditOptions;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'logName': GoogleIamV1LogConfigCloudAuditOptionsLogNameEnum},
      keys: ['authorizationLoggingOptions', 'logName'],
      objects: {
        'authorizationLoggingOptions':
            GoogleCloudAuditAuthorizationLoggingOptions
      }
    };
  }
}

export interface GoogleIamV1LogConfigCounterOptionsParameters {
  metric?: string|null;
  field?: string|null;
  customFields?: Array<GoogleIamV1LogConfigCounterOptionsCustomField>|null;
}
export class GoogleIamV1LogConfigCounterOptions extends Serializable {
  constructor(parameters: GoogleIamV1LogConfigCounterOptionsParameters = {}) {
    super();
    this.Serializable$set(
        'metric', (parameters.metric == null) ? (null) : (parameters.metric));
    this.Serializable$set(
        'field', (parameters.field == null) ? (null) : (parameters.field));
    this.Serializable$set(
        'customFields',
        (parameters.customFields == null) ? (null) : (parameters.customFields));
  }

  get customFields():
      Array<GoogleIamV1LogConfigCounterOptionsCustomField>|null {
    return (
        (this.Serializable$has('customFields')) ?
            (this.Serializable$get('customFields')) :
            (null));
  }

  /**
   * Custom fields.
   */
  set customFields(value: Array<GoogleIamV1LogConfigCounterOptionsCustomField>|
                   null) {
    this.Serializable$set('customFields', value);
  }

  get field(): string|null {
    return (
        (this.Serializable$has('field')) ? (this.Serializable$get('field')) :
                                           (null));
  }

  /**
   * The field value to attribute.
   */
  set field(value: string|null) {
    this.Serializable$set('field', value);
  }

  get metric(): string|null {
    return (
        (this.Serializable$has('metric')) ? (this.Serializable$get('metric')) :
                                            (null));
  }

  /**
   * The metric to update.
   */
  set metric(value: string|null) {
    this.Serializable$set('metric', value);
  }

  getConstructor(): SerializableCtor<GoogleIamV1LogConfigCounterOptions> {
    return GoogleIamV1LogConfigCounterOptions;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'customFields': GoogleIamV1LogConfigCounterOptionsCustomField},
      keys: ['customFields', 'field', 'metric']
    };
  }
}

export interface GoogleIamV1LogConfigCounterOptionsCustomFieldParameters {
  name?: string|null;
  value?: string|null;
}
export class GoogleIamV1LogConfigCounterOptionsCustomField extends
    Serializable {
  constructor(
      parameters:
          GoogleIamV1LogConfigCounterOptionsCustomFieldParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Name is the field name.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get value(): string|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Value is the field value. It is important that in contrast to the
   * CounterOptions.field, the value here is a constant that is not derived from
   * the IAMContext.
   */
  set value(value: string|null) {
    this.Serializable$set('value', value);
  }

  getConstructor():
      SerializableCtor<GoogleIamV1LogConfigCounterOptionsCustomField> {
    return GoogleIamV1LogConfigCounterOptionsCustomField;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['name', 'value']};
  }
}

export interface GoogleIamV1LogConfigDataAccessOptionsParameters {
  logMode?: GoogleIamV1LogConfigDataAccessOptionsLogMode|null;
}
export class GoogleIamV1LogConfigDataAccessOptions extends Serializable {
  constructor(
      parameters: GoogleIamV1LogConfigDataAccessOptionsParameters = {}) {
    super();
    this.Serializable$set(
        'logMode',
        (parameters.logMode == null) ? (null) : (parameters.logMode));
  }

  static get LogMode(): IGoogleIamV1LogConfigDataAccessOptionsLogModeEnum {
    return GoogleIamV1LogConfigDataAccessOptionsLogModeEnum;
  }

  get logMode(): GoogleIamV1LogConfigDataAccessOptionsLogMode|null {
    return (
        (this.Serializable$has('logMode')) ?
            (this.Serializable$get('logMode')) :
            (null));
  }

  set logMode(value: GoogleIamV1LogConfigDataAccessOptionsLogMode|null) {
    this.Serializable$set('logMode', value);
  }

  getConstructor(): SerializableCtor<GoogleIamV1LogConfigDataAccessOptions> {
    return GoogleIamV1LogConfigDataAccessOptions;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'logMode': GoogleIamV1LogConfigDataAccessOptionsLogModeEnum},
      keys: ['logMode']
    };
  }
}

export interface GoogleIamV1PolicyParameters {
  version?: number|null;
  bindings?: Array<GoogleIamV1Binding>|null;
  auditConfigs?: Array<GoogleIamV1AuditConfig>|null;
  rules?: Array<GoogleIamV1Rule>|null;
  etag?: string|null;
}
export class GoogleIamV1Policy extends Serializable {
  constructor(parameters: GoogleIamV1PolicyParameters = {}) {
    super();
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
    this.Serializable$set(
        'bindings',
        (parameters.bindings == null) ? (null) : (parameters.bindings));
    this.Serializable$set(
        'auditConfigs',
        (parameters.auditConfigs == null) ? (null) : (parameters.auditConfigs));
    this.Serializable$set(
        'rules', (parameters.rules == null) ? (null) : (parameters.rules));
    this.Serializable$set(
        'etag', (parameters.etag == null) ? (null) : (parameters.etag));
  }

  get auditConfigs(): Array<GoogleIamV1AuditConfig>|null {
    return (
        (this.Serializable$has('auditConfigs')) ?
            (this.Serializable$get('auditConfigs')) :
            (null));
  }

  /**
   * Specifies cloud audit logging configuration for this policy.
   */
  set auditConfigs(value: Array<GoogleIamV1AuditConfig>|null) {
    this.Serializable$set('auditConfigs', value);
  }

  get bindings(): Array<GoogleIamV1Binding>|null {
    return (
        (this.Serializable$has('bindings')) ?
            (this.Serializable$get('bindings')) :
            (null));
  }

  /**
   * Associates a list of `members`, or principals, with a `role`. Optionally,
   * may specify a `condition` that determines how and when the `bindings` are
   * applied. Each of the `bindings` must contain at least one principal. The
   * `bindings` in a `Policy` can refer to up to 1,500 principals; up to 250 of
   * these principals can be Google groups. Each occurrence of a principal
   * counts towards these limits. For example, if the `bindings` grant 50
   * different roles to `user:alice@example.com`, and not to any other
   * principal, then you can add another 1,450 principals to the `bindings` in
   * the `Policy`.
   */
  set bindings(value: Array<GoogleIamV1Binding>|null) {
    this.Serializable$set('bindings', value);
  }

  get etag(): string|null {
    return (
        (this.Serializable$has('etag')) ? (this.Serializable$get('etag')) :
                                          (null));
  }

  /**
   * `etag` is used for optimistic concurrency control as a way to help prevent
   * simultaneous updates of a policy from overwriting each other. It is
   * strongly suggested that systems make use of the `etag` in the
   * read-modify-write cycle to perform policy updates in order to avoid race
   * conditions: An `etag` is returned in the response to `getIamPolicy`, and
   * systems are expected to put that etag in the request to `setIamPolicy` to
   * ensure that their change will be applied to the same version of the policy.
   * **Important:** If you use IAM Conditions, you must include the `etag` field
   * whenever you call `setIamPolicy`. If you omit this field, then IAM allows
   * you to overwrite a version `3` policy with a version `1` policy, and all of
   * the conditions in the version `3` policy are lost.
   */
  set etag(value: string|null) {
    this.Serializable$set('etag', value);
  }

  get rules(): Array<GoogleIamV1Rule>|null {
    return (
        (this.Serializable$has('rules')) ? (this.Serializable$get('rules')) :
                                           (null));
  }

  /**
   * If more than one rule is specified, the rules are applied in the following
   * manner: - All matching LOG rules are always applied. - If any
   * DENY/DENY_WITH_LOG rule matches, permission is denied. Logging will be
   * applied if one or more matching rule requires logging. - Otherwise, if any
   * ALLOW/ALLOW_WITH_LOG rule matches, permission is granted. Logging will be
   * applied if one or more matching rule requires logging. - Otherwise, if no
   * rule applies, permission is denied.
   */
  set rules(value: Array<GoogleIamV1Rule>|null) {
    this.Serializable$set('rules', value);
  }

  get version(): number|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Specifies the format of the policy. Valid values are `0`, `1`, and `3`.
   * Requests that specify an invalid value are rejected. Any operation that
   * affects conditional role bindings must specify version `3`. This
   * requirement applies to the following operations: * Getting a policy that
   * includes a conditional role binding * Adding a conditional role binding to
   * a policy * Changing a conditional role binding in a policy * Removing any
   * role binding, with or without a condition, from a policy that includes
   * conditions **Important:** If you use IAM Conditions, you must include the
   * `etag` field whenever you call `setIamPolicy`. If you omit this field, then
   * IAM allows you to overwrite a version `3` policy with a version `1` policy,
   * and all of the conditions in the version `3` policy are lost. If a policy
   * does not include any conditions, operations on that policy may specify any
   * valid version or leave the field unset. To learn which resources support
   * conditions in their IAM policies, see the [IAM
   * documentation](https://cloud.google.com/iam/help/conditions/resource-policies).
   */
  set version(value: number|null) {
    this.Serializable$set('version', value);
  }

  getConstructor(): SerializableCtor<GoogleIamV1Policy> {
    return GoogleIamV1Policy;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'auditConfigs': GoogleIamV1AuditConfig,
        'bindings': GoogleIamV1Binding,
        'rules': GoogleIamV1Rule
      },
      keys: ['auditConfigs', 'bindings', 'etag', 'rules', 'version']
    };
  }
}

export interface GoogleIamV1RuleParameters {
  description?: string|null;
  permissions?: Array<string>|null;
  action?: GoogleIamV1RuleAction|null;
  in ?: Array<string>|null;
  notIn?: Array<string>|null;
  conditions?: Array<GoogleIamV1Condition>|null;
  logConfig?: Array<GoogleIamV1LogConfig>|null;
}
export class GoogleIamV1Rule extends Serializable {
  constructor(parameters: GoogleIamV1RuleParameters = {}) {
    super();
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'permissions',
        (parameters.permissions == null) ? (null) : (parameters.permissions));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'in', (parameters.in == null) ? (null) : (parameters.in));
    this.Serializable$set(
        'notIn', (parameters.notIn == null) ? (null) : (parameters.notIn));
    this.Serializable$set(
        'conditions',
        (parameters.conditions == null) ? (null) : (parameters.conditions));
    this.Serializable$set(
        'logConfig',
        (parameters.logConfig == null) ? (null) : (parameters.logConfig));
  }

  static get Action(): IGoogleIamV1RuleActionEnum {
    return GoogleIamV1RuleActionEnum;
  }

  get action(): GoogleIamV1RuleAction|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Required
   */
  set action(value: GoogleIamV1RuleAction|null) {
    this.Serializable$set('action', value);
  }

  get conditions(): Array<GoogleIamV1Condition>|null {
    return (
        (this.Serializable$has('conditions')) ?
            (this.Serializable$get('conditions')) :
            (null));
  }

  /**
   * Additional restrictions that must be met. All conditions must pass for the
   * rule to match.
   */
  set conditions(value: Array<GoogleIamV1Condition>|null) {
    this.Serializable$set('conditions', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Human-readable description of the rule.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get in(): Array<string>|null {
    return (
        (this.Serializable$has('in')) ? (this.Serializable$get('in')) : (null));
  }

  /**
   * If one or more 'in' clauses are specified, the rule matches if the
   * PRINCIPAL/AUTHORITY_SELECTOR is in at least one of these entries.
   */
  set in(value: Array<string>|null) {
    this.Serializable$set('in', value);
  }

  get logConfig(): Array<GoogleIamV1LogConfig>|null {
    return (
        (this.Serializable$has('logConfig')) ?
            (this.Serializable$get('logConfig')) :
            (null));
  }

  /**
   * The config returned to callers of CheckPolicy for any entries that match
   * the LOG action.
   */
  set logConfig(value: Array<GoogleIamV1LogConfig>|null) {
    this.Serializable$set('logConfig', value);
  }

  get notIn(): Array<string>|null {
    return (
        (this.Serializable$has('notIn')) ? (this.Serializable$get('notIn')) :
                                           (null));
  }

  /**
   * If one or more 'not_in' clauses are specified, the rule matches if the
   * PRINCIPAL/AUTHORITY_SELECTOR is in none of the entries. The format for in
   * and not_in entries can be found at in the Local IAM documentation (see
   * go/local-iam#features).
   */
  set notIn(value: Array<string>|null) {
    this.Serializable$set('notIn', value);
  }

  get permissions(): Array<string>|null {
    return (
        (this.Serializable$has('permissions')) ?
            (this.Serializable$get('permissions')) :
            (null));
  }

  /**
   * A permission is a string of form '..' (e.g., 'storage.buckets.list'). A
   * value of '*' matches all permissions, and a verb part of '*' (e.g.,
   * 'storage.buckets.*') matches all verbs.
   */
  set permissions(value: Array<string>|null) {
    this.Serializable$set('permissions', value);
  }

  getConstructor(): SerializableCtor<GoogleIamV1Rule> {
    return GoogleIamV1Rule;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'conditions': GoogleIamV1Condition,
        'logConfig': GoogleIamV1LogConfig
      },
      enums: {'action': GoogleIamV1RuleActionEnum},
      keys: [
        'action', 'conditions', 'description', 'in', 'logConfig', 'notIn',
        'permissions'
      ]
    };
  }
}

export interface GoogleLongrunningListOperationsResponseParameters {
  operations?: Array<GoogleLongrunningOperation>|null;
  nextPageToken?: string|null;
}
export class GoogleLongrunningListOperationsResponse extends Serializable {
  constructor(
      parameters: GoogleLongrunningListOperationsResponseParameters = {}) {
    super();
    this.Serializable$set(
        'operations',
        (parameters.operations == null) ? (null) : (parameters.operations));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * The standard List next-page token.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get operations(): Array<GoogleLongrunningOperation>|null {
    return (
        (this.Serializable$has('operations')) ?
            (this.Serializable$get('operations')) :
            (null));
  }

  /**
   * A list of operations that matches the specified filter in the request.
   */
  set operations(value: Array<GoogleLongrunningOperation>|null) {
    this.Serializable$set('operations', value);
  }

  getConstructor(): SerializableCtor<GoogleLongrunningListOperationsResponse> {
    return GoogleLongrunningListOperationsResponse;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'operations': GoogleLongrunningOperation},
      keys: ['nextPageToken', 'operations']
    };
  }
}

export interface GoogleLongrunningOperationParameters {
  name?: string|null;
  metadata?: ApiClientObjectMap<any>|null;
  done?: boolean|null;
  error?: GoogleRpcStatus|null;
  response?: ApiClientObjectMap<any>|null;
}
export class GoogleLongrunningOperation extends Serializable {
  constructor(parameters: GoogleLongrunningOperationParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'done', (parameters.done == null) ? (null) : (parameters.done));
    this.Serializable$set(
        'error', (parameters.error == null) ? (null) : (parameters.error));
    this.Serializable$set(
        'response',
        (parameters.response == null) ? (null) : (parameters.response));
  }

  get done(): boolean|null {
    return (
        (this.Serializable$has('done')) ? (this.Serializable$get('done')) :
                                          (null));
  }

  /**
   * If the value is `false`, it means the operation is still in progress. If
   * `true`, the operation is completed, and either `error` or `response` is
   * available.
   */
  set done(value: boolean|null) {
    this.Serializable$set('done', value);
  }

  get error(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('error')) ? (this.Serializable$get('error')) :
                                           (null));
  }

  /**
   * The error result of the operation in case of failure or cancellation.
   */
  set error(value: GoogleRpcStatus|null) {
    this.Serializable$set('error', value);
  }

  get metadata(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Service-specific metadata associated with the operation. It typically
   * contains progress information and common metadata such as create time. Some
   * services might not provide such metadata. Any method that returns a
   * long-running operation should document the metadata type, if any.
   */
  set metadata(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('metadata', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The server-assigned name, which is only unique within the same service that
   * originally returns it. If you use the default HTTP mapping, the `name`
   * should be a resource name ending with `operations/{unique_id}`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get response(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('response')) ?
            (this.Serializable$get('response')) :
            (null));
  }

  /**
   * The normal response of the operation in case of success. If the original
   * method returns no data on success, such as `Delete`, the response is
   * `google.protobuf.Empty`. If the original method is standard
   * `Get`/`Create`/`Update`, the response should be the resource. For other
   * methods, the response should have the type `XxxResponse`, where `Xxx` is
   * the original method name. For example, if the original method name is
   * `TakeSnapshot()`, the inferred response type is `TakeSnapshotResponse`.
   */
  set response(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('response', value);
  }

  getConstructor(): SerializableCtor<GoogleLongrunningOperation> {
    return GoogleLongrunningOperation;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['done', 'error', 'metadata', 'name', 'response'],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'response': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'error': GoogleRpcStatus}
    };
  }
}

export interface GoogleProtobufEmptyParameters {}
export class GoogleProtobufEmpty extends Serializable {
  constructor(parameters: GoogleProtobufEmptyParameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<GoogleProtobufEmpty> {
    return GoogleProtobufEmpty;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface GoogleRpcStatusParameters {
  code?: number|null;
  message?: string|null;
  details?: Array<ApiClientObjectMap<any>>|null;
}
export class GoogleRpcStatus extends Serializable {
  constructor(parameters: GoogleRpcStatusParameters = {}) {
    super();
    this.Serializable$set(
        'code', (parameters.code == null) ? (null) : (parameters.code));
    this.Serializable$set(
        'message',
        (parameters.message == null) ? (null) : (parameters.message));
    this.Serializable$set(
        'details',
        (parameters.details == null) ? (null) : (parameters.details));
  }

  get code(): number|null {
    return (
        (this.Serializable$has('code')) ? (this.Serializable$get('code')) :
                                          (null));
  }

  /**
   * The status code, which should be an enum value of google.rpc.Code.
   */
  set code(value: number|null) {
    this.Serializable$set('code', value);
  }

  get details(): Array<ApiClientObjectMap<any>>|null {
    return (
        (this.Serializable$has('details')) ?
            (this.Serializable$get('details')) :
            (null));
  }

  /**
   * A list of messages that carry the error details. There is a common set of
   * message types for APIs to use.
   */
  set details(value: Array<ApiClientObjectMap<any>>|null) {
    this.Serializable$set('details', value);
  }

  get message(): string|null {
    return (
        (this.Serializable$has('message')) ?
            (this.Serializable$get('message')) :
            (null));
  }

  /**
   * A developer-facing error message, which should be in English. Any
   * user-facing error message should be localized and sent in the
   * google.rpc.Status.details field, or localized by the client.
   */
  set message(value: string|null) {
    this.Serializable$set('message', value);
  }

  getConstructor(): SerializableCtor<GoogleRpcStatus> {
    return GoogleRpcStatus;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['code', 'details', 'message'],
      objectMaps: {
        'details': {
          ctor: null,
          isPropertyArray: true,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface GoogleTypeExprParameters {
  expression?: string|null;
  title?: string|null;
  description?: string|null;
  location?: string|null;
}
export class GoogleTypeExpr extends Serializable {
  constructor(parameters: GoogleTypeExprParameters = {}) {
    super();
    this.Serializable$set(
        'expression',
        (parameters.expression == null) ? (null) : (parameters.expression));
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'location',
        (parameters.location == null) ? (null) : (parameters.location));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. Description of the expression. This is a longer text which
   * describes the expression, e.g. when hovered over it in a UI.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get expression(): string|null {
    return (
        (this.Serializable$has('expression')) ?
            (this.Serializable$get('expression')) :
            (null));
  }

  /**
   * Textual representation of an expression in Common Expression Language
   * syntax.
   */
  set expression(value: string|null) {
    this.Serializable$set('expression', value);
  }

  get location(): string|null {
    return (
        (this.Serializable$has('location')) ?
            (this.Serializable$get('location')) :
            (null));
  }

  /**
   * Optional. String indicating the location of the expression for error
   * reporting, e.g. a file name and a position in the file.
   */
  set location(value: string|null) {
    this.Serializable$set('location', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. Title for the expression, i.e. a short string describing its
   * purpose. This can be used e.g. in UIs which allow to enter the expression.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<GoogleTypeExpr> {
    return GoogleTypeExpr;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['description', 'expression', 'location', 'title']};
  }
}

export interface GoogleTypeLatLngParameters {
  latitude?: number|null;
  longitude?: number|null;
}
export class GoogleTypeLatLng extends Serializable {
  constructor(parameters: GoogleTypeLatLngParameters = {}) {
    super();
    this.Serializable$set(
        'latitude',
        (parameters.latitude == null) ? (null) : (parameters.latitude));
    this.Serializable$set(
        'longitude',
        (parameters.longitude == null) ? (null) : (parameters.longitude));
  }

  get latitude(): number|null {
    return (
        (this.Serializable$has('latitude')) ?
            (this.Serializable$get('latitude')) :
            (null));
  }

  /**
   * The latitude in degrees. It must be in the range [-90.0, +90.0].
   */
  set latitude(value: number|null) {
    this.Serializable$set('latitude', value);
  }

  get longitude(): number|null {
    return (
        (this.Serializable$has('longitude')) ?
            (this.Serializable$get('longitude')) :
            (null));
  }

  /**
   * The longitude in degrees. It must be in the range [-180.0, +180.0].
   */
  set longitude(value: number|null) {
    this.Serializable$set('longitude', value);
  }

  getConstructor(): SerializableCtor<GoogleTypeLatLng> {
    return GoogleTypeLatLng;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['latitude', 'longitude']};
  }
}

export interface HumanAgentAssistantConfigConversationModelConfig_Parameters {
  model?: string|null;
  baselineModelVersion?: string|null;
}
export class HumanAgentAssistantConfigConversationModelConfig_ extends
    Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigConversationModelConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'baselineModelVersion',
        (parameters.baselineModelVersion == null) ?
            (null) :
            (parameters.baselineModelVersion));
  }

  get baselineModelVersion(): string|null {
    return (
        (this.Serializable$has('baselineModelVersion')) ?
            (this.Serializable$get('baselineModelVersion')) :
            (null));
  }

  /**
   * Version of current baseline model. It will be ignored if model is set.
   * Valid versions are: Article Suggestion baseline model: - 0.9 - 1.0
   * (default) Summarization baseline model: - 1.0
   */
  set baselineModelVersion(value: string|null) {
    this.Serializable$set('baselineModelVersion', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Conversation model resource name. Format: `projects//conversationModels/`.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  getConstructor():
      SerializableCtor<HumanAgentAssistantConfigConversationModelConfig_> {
    return HumanAgentAssistantConfigConversationModelConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['baselineModelVersion', 'model']};
  }
}

export interface HumanAgentAssistantConfigConversationProcessConfig_Parameters {
  queryTermCount?: number|null;
  recentSentencesCount?: number|null;
  queryBuilder?: string|null;
  salientTermExtractor?: string|null;
  minSalientTermScore?: number|null;
  additionalQueryPostprocessors?: Array<string>|null;
}
export class HumanAgentAssistantConfigConversationProcessConfig_ extends
    Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigConversationProcessConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'queryTermCount',
        (parameters.queryTermCount == null) ? (null) :
                                              (parameters.queryTermCount));
    this.Serializable$set(
        'recentSentencesCount',
        (parameters.recentSentencesCount == null) ?
            (null) :
            (parameters.recentSentencesCount));
    this.Serializable$set(
        'queryBuilder',
        (parameters.queryBuilder == null) ? (null) : (parameters.queryBuilder));
    this.Serializable$set(
        'salientTermExtractor',
        (parameters.salientTermExtractor == null) ?
            (null) :
            (parameters.salientTermExtractor));
    this.Serializable$set(
        'minSalientTermScore',
        (parameters.minSalientTermScore == null) ?
            (null) :
            (parameters.minSalientTermScore));
    this.Serializable$set(
        'additionalQueryPostprocessors',
        (parameters.additionalQueryPostprocessors == null) ?
            (null) :
            (parameters.additionalQueryPostprocessors));
  }

  get additionalQueryPostprocessors(): Array<string>|null {
    return (
        (this.Serializable$has('additionalQueryPostprocessors')) ?
            (this.Serializable$get('additionalQueryPostprocessors')) :
            (null));
  }

  /**
   * Deprecated. Additional query postprocessors to run. Available options are:
   * 'idf_to_weight', 'set_all_optional'.
   */
  set additionalQueryPostprocessors(value: Array<string>|null) {
    this.Serializable$set('additionalQueryPostprocessors', value);
  }

  get minSalientTermScore(): number|null {
    return (
        (this.Serializable$has('minSalientTermScore')) ?
            (this.Serializable$get('minSalientTermScore')) :
            (null));
  }

  /**
   * Deprecated. Override the min salient term score in extractor.
   */
  set minSalientTermScore(value: number|null) {
    this.Serializable$set('minSalientTermScore', value);
  }

  get queryBuilder(): string|null {
    return (
        (this.Serializable$has('queryBuilder')) ?
            (this.Serializable$get('queryBuilder')) :
            (null));
  }

  /**
   * Deprecated. Query builder to build query. Available options are:
   * 'simple_raw_space', 'demo'.
   */
  set queryBuilder(value: string|null) {
    this.Serializable$set('queryBuilder', value);
  }

  get queryTermCount(): number|null {
    return (
        (this.Serializable$has('queryTermCount')) ?
            (this.Serializable$get('queryTermCount')) :
            (null));
  }

  /**
   * Deprecated. Number of keywords to extract from the conversation.
   */
  set queryTermCount(value: number|null) {
    this.Serializable$set('queryTermCount', value);
  }

  get recentSentencesCount(): number|null {
    return (
        (this.Serializable$has('recentSentencesCount')) ?
            (this.Serializable$get('recentSentencesCount')) :
            (null));
  }

  /**
   * Number of recent non-small-talk sentences to use as context for article and
   * FAQ suggestion
   */
  set recentSentencesCount(value: number|null) {
    this.Serializable$set('recentSentencesCount', value);
  }

  get salientTermExtractor(): string|null {
    return (
        (this.Serializable$has('salientTermExtractor')) ?
            (this.Serializable$get('salientTermExtractor')) :
            (null));
  }

  /**
   * Deprecated. SalientTermExtractor. Available options are: 'default',
   * 'candidateBavsed'.
   */
  set salientTermExtractor(value: string|null) {
    this.Serializable$set('salientTermExtractor', value);
  }

  getConstructor():
      SerializableCtor<HumanAgentAssistantConfigConversationProcessConfig_> {
    return HumanAgentAssistantConfigConversationProcessConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'additionalQueryPostprocessors', 'minSalientTermScore', 'queryBuilder',
        'queryTermCount', 'recentSentencesCount', 'salientTermExtractor'
      ]
    };
  }
}

export interface HumanAgentAssistantConfigMessageAnalysisConfig_Parameters {
  enableEntityExtraction?: boolean|null;
  enableSentimentAnalysis?: boolean|null;
}
export class HumanAgentAssistantConfigMessageAnalysisConfig_ extends
    Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigMessageAnalysisConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'enableEntityExtraction',
        (parameters.enableEntityExtraction == null) ?
            (null) :
            (parameters.enableEntityExtraction));
    this.Serializable$set(
        'enableSentimentAnalysis',
        (parameters.enableSentimentAnalysis == null) ?
            (null) :
            (parameters.enableSentimentAnalysis));
  }

  get enableEntityExtraction(): boolean|null {
    return (
        (this.Serializable$has('enableEntityExtraction')) ?
            (this.Serializable$get('enableEntityExtraction')) :
            (null));
  }

  /**
   * Enable entity extraction in conversation messages on [agent assist
   * stage](https://cloud.google.com/dialogflow/priv/docs/contact-center/basics#stages).
   * If unspecified, defaults to false. Currently, this feature is not general
   * available, please contact Google to get access.
   */
  set enableEntityExtraction(value: boolean|null) {
    this.Serializable$set('enableEntityExtraction', value);
  }

  get enableSentimentAnalysis(): boolean|null {
    return (
        (this.Serializable$has('enableSentimentAnalysis')) ?
            (this.Serializable$get('enableSentimentAnalysis')) :
            (null));
  }

  /**
   * Enable sentiment analysis in conversation messages on [agent assist
   * stage](https://cloud.google.com/dialogflow/priv/docs/contact-center/basics#stages).
   * If unspecified, defaults to false. Sentiment analysis inspects user input
   * and identifies the prevailing subjective opinion, especially to determine a
   * user's attitude as positive, negative, or neutral:
   * https://cloud.google.com/natural-language/docs/basics#sentiment_analysis
   * For Participants.StreamingAnalyzeContent method, result will be in
   * StreamingAnalyzeContentResponse.message.SentimentAnalysisResult. For
   * Participants.AnalyzeContent method, result will be in
   * AnalyzeContentResponse.message.SentimentAnalysisResult For
   * Conversations.ListMessages method, result will be in
   * ListMessagesResponse.messages.SentimentAnalysisResult If Pub/Sub
   * notification is configured, result will be in
   * ConversationEvent.new_message_payload.SentimentAnalysisResult.
   */
  set enableSentimentAnalysis(value: boolean|null) {
    this.Serializable$set('enableSentimentAnalysis', value);
  }

  getConstructor():
      SerializableCtor<HumanAgentAssistantConfigMessageAnalysisConfig_> {
    return HumanAgentAssistantConfigMessageAnalysisConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enableEntityExtraction', 'enableSentimentAnalysis']};
  }
}

export interface HumanAgentAssistantConfigSuggestionConfig_Parameters {
  humanAgentAssistant?: string|null;
  featureConfigs?: Array<HumanAgentAssistantConfigSuggestionFeatureConfig_>|
      null;
  groupSuggestionResponses?: boolean|null;
}
export class HumanAgentAssistantConfigSuggestionConfig_ extends Serializable {
  constructor(
      parameters: HumanAgentAssistantConfigSuggestionConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'humanAgentAssistant',
        (parameters.humanAgentAssistant == null) ?
            (null) :
            (parameters.humanAgentAssistant));
    this.Serializable$set(
        'featureConfigs',
        (parameters.featureConfigs == null) ? (null) :
                                              (parameters.featureConfigs));
    this.Serializable$set(
        'groupSuggestionResponses',
        (parameters.groupSuggestionResponses == null) ?
            (null) :
            (parameters.groupSuggestionResponses));
  }

  get featureConfigs():
      Array<HumanAgentAssistantConfigSuggestionFeatureConfig_>|null {
    return (
        (this.Serializable$has('featureConfigs')) ?
            (this.Serializable$get('featureConfigs')) :
            (null));
  }

  /**
   * Configuration of different suggestion features. One feature can have only
   * one config.
   */
  set featureConfigs(
      value: Array<HumanAgentAssistantConfigSuggestionFeatureConfig_>|null) {
    this.Serializable$set('featureConfigs', value);
  }

  get groupSuggestionResponses(): boolean|null {
    return (
        (this.Serializable$has('groupSuggestionResponses')) ?
            (this.Serializable$get('groupSuggestionResponses')) :
            (null));
  }

  /**
   * If `group_suggestion_responses` is false, and there are multiple
   * `feature_configs` in `event based suggestion` or StreamingAnalyzeContent,
   * we will try to deliver suggestions to customers as soon as we get new
   * suggestion. Different type of suggestions based on the same context will be
   * in separate Pub/Sub event or `StreamingAnalyzeContentResponse`. If
   * `group_suggestion_responses` set to true. All the suggestions to the same
   * participant based on the same context will be grouped into a single Pub/Sub
   * event or StreamingAnalyzeContentResponse.
   */
  set groupSuggestionResponses(value: boolean|null) {
    this.Serializable$set('groupSuggestionResponses', value);
  }

  get humanAgentAssistant(): string|null {
    return (
        (this.Serializable$has('humanAgentAssistant')) ?
            (this.Serializable$get('humanAgentAssistant')) :
            (null));
  }

  /**
   * Deprecated: use configs in SuggestionFeatureConfig instead, if both are
   * provided, config in `SuggestionFeatureConfig` will override settings in
   * `human_agent_assistant`. Resource name of the human agent assistant to use.
   * Format: `projects//locations//humanAgentAssistants/`.
   */
  set humanAgentAssistant(value: string|null) {
    this.Serializable$set('humanAgentAssistant', value);
  }

  getConstructor():
      SerializableCtor<HumanAgentAssistantConfigSuggestionConfig_> {
    return HumanAgentAssistantConfigSuggestionConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'featureConfigs': HumanAgentAssistantConfigSuggestionFeatureConfig_},
      keys:
          ['featureConfigs', 'groupSuggestionResponses', 'humanAgentAssistant']
    };
  }
}

export interface HumanAgentAssistantConfigSuggestionFeatureConfig_Parameters {
  suggestionFeature?: SuggestionFeature_|null;
  enableInlineSuggestion?: boolean|null;
  enableEventBasedSuggestion?: boolean|null;
  enablePersonalization?: boolean|null;
  disableToxicityCheckingOnSummary?: boolean|null;
  triggerModelMode?:
      HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode|null;
  suggestionTriggerSettings?:
      HumanAgentAssistantConfigSuggestionTriggerSettings_|null;
  queryConfig?: HumanAgentAssistantConfigSuggestionQueryConfig_|null;
  conversationModelConfig?: HumanAgentAssistantConfigConversationModelConfig_|
      null;
  conversationProcessConfig?:
      HumanAgentAssistantConfigConversationProcessConfig_|null;
  internalConfig?: HumanAgentAssistantConfigSuggestionInternalConfig_|null;
}
export class HumanAgentAssistantConfigSuggestionFeatureConfig_ extends
    Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigSuggestionFeatureConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'suggestionFeature',
        (parameters.suggestionFeature == null) ?
            (null) :
            (parameters.suggestionFeature));
    this.Serializable$set(
        'enableInlineSuggestion',
        (parameters.enableInlineSuggestion == null) ?
            (null) :
            (parameters.enableInlineSuggestion));
    this.Serializable$set(
        'enableEventBasedSuggestion',
        (parameters.enableEventBasedSuggestion == null) ?
            (null) :
            (parameters.enableEventBasedSuggestion));
    this.Serializable$set(
        'enablePersonalization',
        (parameters.enablePersonalization == null) ?
            (null) :
            (parameters.enablePersonalization));
    this.Serializable$set(
        'disableToxicityCheckingOnSummary',
        (parameters.disableToxicityCheckingOnSummary == null) ?
            (null) :
            (parameters.disableToxicityCheckingOnSummary));
    this.Serializable$set(
        'triggerModelMode',
        (parameters.triggerModelMode == null) ? (null) :
                                                (parameters.triggerModelMode));
    this.Serializable$set(
        'suggestionTriggerSettings',
        (parameters.suggestionTriggerSettings == null) ?
            (null) :
            (parameters.suggestionTriggerSettings));
    this.Serializable$set(
        'queryConfig',
        (parameters.queryConfig == null) ? (null) : (parameters.queryConfig));
    this.Serializable$set(
        'conversationModelConfig',
        (parameters.conversationModelConfig == null) ?
            (null) :
            (parameters.conversationModelConfig));
    this.Serializable$set(
        'conversationProcessConfig',
        (parameters.conversationProcessConfig == null) ?
            (null) :
            (parameters.conversationProcessConfig));
    this.Serializable$set(
        'internalConfig',
        (parameters.internalConfig == null) ? (null) :
                                              (parameters.internalConfig));
  }

  static get TriggerModelMode():
      IHumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelModeEnum {
    return HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelModeEnum;
  }

  get conversationModelConfig():
      HumanAgentAssistantConfigConversationModelConfig_|null {
    return (
        (this.Serializable$has('conversationModelConfig')) ?
            (this.Serializable$get('conversationModelConfig')) :
            (null));
  }

  /**
   * Configs of custom conversation model.
   */
  set conversationModelConfig(
      value: HumanAgentAssistantConfigConversationModelConfig_|null) {
    this.Serializable$set('conversationModelConfig', value);
  }

  get conversationProcessConfig():
      HumanAgentAssistantConfigConversationProcessConfig_|null {
    return (
        (this.Serializable$has('conversationProcessConfig')) ?
            (this.Serializable$get('conversationProcessConfig')) :
            (null));
  }

  /**
   * Configs for processing conversation.
   */
  set conversationProcessConfig(
      value: HumanAgentAssistantConfigConversationProcessConfig_|null) {
    this.Serializable$set('conversationProcessConfig', value);
  }

  get disableToxicityCheckingOnSummary(): boolean|null {
    return (
        (this.Serializable$has('disableToxicityCheckingOnSummary')) ?
            (this.Serializable$get('disableToxicityCheckingOnSummary')) :
            (null));
  }

  /**
   * Skip toxicity checking for generated conversation summaries. Supported
   * features: CONVERSATION_SUMMARIZATION, CONVERSATION_SUMMARIZATION_VOICE.
   */
  set disableToxicityCheckingOnSummary(value: boolean|null) {
    this.Serializable$set('disableToxicityCheckingOnSummary', value);
  }

  get enableEventBasedSuggestion(): boolean|null {
    return (
        (this.Serializable$has('enableEventBasedSuggestion')) ?
            (this.Serializable$get('enableEventBasedSuggestion')) :
            (null));
  }

  /**
   * Automatically iterates all participants and tries to compile suggestions.
   * Supported features: ARTICLE_SUGGESTION, FAQ, DIALOGFLOW_ASSIST.
   */
  set enableEventBasedSuggestion(value: boolean|null) {
    this.Serializable$set('enableEventBasedSuggestion', value);
  }

  get enableInlineSuggestion(): boolean|null {
    return (
        (this.Serializable$has('enableInlineSuggestion')) ?
            (this.Serializable$get('enableInlineSuggestion')) :
            (null));
  }

  /**
   * Deprecated: Inline suggestion is always enabled for supported features,
   * including ARTICLE_SUGGESTION, FAQ, SMART_REPLY, DIALOGFLOW_ASSIST. Tries to
   * compile suggestions for matched participants in AnalyzeContentRequest and
   * put the suggestions in AnalyzeContentResponse if enabled.
   */
  set enableInlineSuggestion(value: boolean|null) {
    this.Serializable$set('enableInlineSuggestion', value);
  }

  get enablePersonalization(): boolean|null {
    return (
        (this.Serializable$has('enablePersonalization')) ?
            (this.Serializable$get('enablePersonalization')) :
            (null));
  }

  /**
   * Use the user profile generated from previous chat logs and perform
   * personalization on suggestions. Supported features: SMART_REPLY.
   */
  set enablePersonalization(value: boolean|null) {
    this.Serializable$set('enablePersonalization', value);
  }

  get internalConfig(): HumanAgentAssistantConfigSuggestionInternalConfig_
      |null {
    return (
        (this.Serializable$has('internalConfig')) ?
            (this.Serializable$get('internalConfig')) :
            (null));
  }

  /**
   * Other internal only configs.
   */
  set internalConfig(value: HumanAgentAssistantConfigSuggestionInternalConfig_|
                     null) {
    this.Serializable$set('internalConfig', value);
  }

  get queryConfig(): HumanAgentAssistantConfigSuggestionQueryConfig_|null {
    return (
        (this.Serializable$has('queryConfig')) ?
            (this.Serializable$get('queryConfig')) :
            (null));
  }

  /**
   * Configs of query.
   */
  set queryConfig(value: HumanAgentAssistantConfigSuggestionQueryConfig_|null) {
    this.Serializable$set('queryConfig', value);
  }

  get suggestionFeature(): SuggestionFeature_|null {
    return (
        (this.Serializable$has('suggestionFeature')) ?
            (this.Serializable$get('suggestionFeature')) :
            (null));
  }

  /**
   * The suggestion feature.
   */
  set suggestionFeature(value: SuggestionFeature_|null) {
    this.Serializable$set('suggestionFeature', value);
  }

  get suggestionTriggerSettings():
      HumanAgentAssistantConfigSuggestionTriggerSettings_|null {
    return (
        (this.Serializable$has('suggestionTriggerSettings')) ?
            (this.Serializable$get('suggestionTriggerSettings')) :
            (null));
  }

  /**
   * Settings of suggestion trigger. Currently, only ARTICLE_SUGGESTION, FAQ,
   * and DIALOGFLOW_ASSIST will use this field.
   */
  set suggestionTriggerSettings(
      value: HumanAgentAssistantConfigSuggestionTriggerSettings_|null) {
    this.Serializable$set('suggestionTriggerSettings', value);
  }

  get triggerModelMode():
      HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode|null {
    return (
        (this.Serializable$has('triggerModelMode')) ?
            (this.Serializable$get('triggerModelMode')) :
            (null));
  }

  /**
   * Deprecated, use suggestion_trigger_settings instead. Trigger Model is a
   * feature that predicts whether human agent assistant needs to provide
   * suggestions on the current message with the context of the current
   * conversation. User can choose whether to enable this feature or not by
   * setting this field. If TriggerModelMode is enabled, human agent assistant
   * will only generate suggestions when the score from the model prediction
   * result exceeds a certain threshold. If the TriggerModelMode is disabled,
   * human agent assistant will try to generate suggestions for every message.
   * If it is set to TRIGGER_MODEL_MODE_UNSPECIFIED, it will default to the
   * behavior of TRIGGER_MODEL_MODE_ENABLED. Currently, only article suggestion
   * will use this field and it will only take effect in event based suggestion.
   */
  set triggerModelMode(
      value: HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelMode|
      null) {
    this.Serializable$set('triggerModelMode', value);
  }

  getConstructor():
      SerializableCtor<HumanAgentAssistantConfigSuggestionFeatureConfig_> {
    return HumanAgentAssistantConfigSuggestionFeatureConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'triggerModelMode':
            HumanAgentAssistantConfigSuggestionFeatureConfig_TriggerModelModeEnum
      },
      keys: [
        'conversationModelConfig', 'conversationProcessConfig',
        'disableToxicityCheckingOnSummary', 'enableEventBasedSuggestion',
        'enableInlineSuggestion', 'enablePersonalization', 'internalConfig',
        'queryConfig', 'suggestionFeature', 'suggestionTriggerSettings',
        'triggerModelMode'
      ],
      objects: {
        'conversationModelConfig':
            HumanAgentAssistantConfigConversationModelConfig_,
        'conversationProcessConfig':
            HumanAgentAssistantConfigConversationProcessConfig_,
        'internalConfig': HumanAgentAssistantConfigSuggestionInternalConfig_,
        'queryConfig': HumanAgentAssistantConfigSuggestionQueryConfig_,
        'suggestionFeature': SuggestionFeature_,
        'suggestionTriggerSettings':
            HumanAgentAssistantConfigSuggestionTriggerSettings_
      }
    };
  }
}

export interface HumanAgentAssistantConfigSuggestionInternalConfig_Parameters {
  settings?: ApiClientObjectMap<string>|null;
  maxKnowledgeSearchResults?: number|null;
}
export class HumanAgentAssistantConfigSuggestionInternalConfig_ extends
    Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigSuggestionInternalConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'settings',
        (parameters.settings == null) ? (null) : (parameters.settings));
    this.Serializable$set(
        'maxKnowledgeSearchResults',
        (parameters.maxKnowledgeSearchResults == null) ?
            (null) :
            (parameters.maxKnowledgeSearchResults));
  }

  get maxKnowledgeSearchResults(): number|null {
    return (
        (this.Serializable$has('maxKnowledgeSearchResults')) ?
            (this.Serializable$get('maxKnowledgeSearchResults')) :
            (null));
  }

  /**
   * Maximum number of st search results from knowledge service. If unset,
   * defaults to 30.
   */
  set maxKnowledgeSearchResults(value: number|null) {
    this.Serializable$set('maxKnowledgeSearchResults', value);
  }

  get settings(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('settings')) ?
            (this.Serializable$get('settings')) :
            (null));
  }

  /**
   * Custom internal settings.
   */
  set settings(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('settings', value);
  }

  getConstructor():
      SerializableCtor<HumanAgentAssistantConfigSuggestionInternalConfig_> {
    return HumanAgentAssistantConfigSuggestionInternalConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['maxKnowledgeSearchResults', 'settings'],
      objectMaps: {
        'settings': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface HumanAgentAssistantConfigSuggestionQueryConfigContextFilterSettings_Parameters {
  dropHandoffMessages?: boolean|null;
  dropVirtualAgentMessages?: boolean|null;
  dropIvrMessages?: boolean|null;
}
export class
    HumanAgentAssistantConfigSuggestionQueryConfigContextFilterSettings_ extends
        Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigSuggestionQueryConfigContextFilterSettings_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'dropHandoffMessages',
        (parameters.dropHandoffMessages == null) ?
            (null) :
            (parameters.dropHandoffMessages));
    this.Serializable$set(
        'dropVirtualAgentMessages',
        (parameters.dropVirtualAgentMessages == null) ?
            (null) :
            (parameters.dropVirtualAgentMessages));
    this.Serializable$set(
        'dropIvrMessages',
        (parameters.dropIvrMessages == null) ? (null) :
                                               (parameters.dropIvrMessages));
  }

  get dropHandoffMessages(): boolean|null {
    return (
        (this.Serializable$has('dropHandoffMessages')) ?
            (this.Serializable$get('dropHandoffMessages')) :
            (null));
  }

  /**
   * If set to true, the last message from virtual agent (hand off message) and
   * the message before it (trigger message of hand off) are dropped.
   */
  set dropHandoffMessages(value: boolean|null) {
    this.Serializable$set('dropHandoffMessages', value);
  }

  get dropIvrMessages(): boolean|null {
    return (
        (this.Serializable$has('dropIvrMessages')) ?
            (this.Serializable$get('dropIvrMessages')) :
            (null));
  }

  /**
   * If set to true, all messages from ivr stage are dropped.
   */
  set dropIvrMessages(value: boolean|null) {
    this.Serializable$set('dropIvrMessages', value);
  }

  get dropVirtualAgentMessages(): boolean|null {
    return (
        (this.Serializable$has('dropVirtualAgentMessages')) ?
            (this.Serializable$get('dropVirtualAgentMessages')) :
            (null));
  }

  /**
   * If set to true, all messages from virtual agent are dropped.
   */
  set dropVirtualAgentMessages(value: boolean|null) {
    this.Serializable$set('dropVirtualAgentMessages', value);
  }

  getConstructor(): SerializableCtor<
      HumanAgentAssistantConfigSuggestionQueryConfigContextFilterSettings_> {
    return HumanAgentAssistantConfigSuggestionQueryConfigContextFilterSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys:
          ['dropHandoffMessages', 'dropIvrMessages', 'dropVirtualAgentMessages']
    };
  }
}

export interface HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySourceHumanAgentSideConfig_Parameters {
  agent?: string|null;
}
export class
    HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySourceHumanAgentSideConfig_ extends
        Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySourceHumanAgentSideConfig_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'agent', (parameters.agent == null) ? (null) : (parameters.agent));
  }

  get agent(): string|null {
    return (
        (this.Serializable$has('agent')) ? (this.Serializable$get('agent')) :
                                           (null));
  }

  /**
   * Optional. The name of a dialogflow virtual agent used for intent detection
   * and suggestion triggered by human agent. Format:
   * `projects//locations//agent`.
   */
  set agent(value: string|null) {
    this.Serializable$set('agent', value);
  }

  getConstructor(): SerializableCtor<
      HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySourceHumanAgentSideConfig_> {
    return HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySourceHumanAgentSideConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agent']};
  }
}

export interface HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySource_Parameters {
  agent?: string|null;
  routerConfig?: AutomatedAgentConfigRouterConfig_|null;
  humanAgentSideConfig?:
      HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySourceHumanAgentSideConfig_|
      null;
  workflow?: string|null;
}
export class
    HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySource_ extends
        Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySource_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'agent', (parameters.agent == null) ? (null) : (parameters.agent));
    this.Serializable$set(
        'routerConfig',
        (parameters.routerConfig == null) ? (null) : (parameters.routerConfig));
    this.Serializable$set(
        'humanAgentSideConfig',
        (parameters.humanAgentSideConfig == null) ?
            (null) :
            (parameters.humanAgentSideConfig));
    this.Serializable$set(
        'workflow',
        (parameters.workflow == null) ? (null) : (parameters.workflow));
  }

  get agent(): string|null {
    return (
        (this.Serializable$has('agent')) ? (this.Serializable$get('agent')) :
                                           (null));
  }

  /**
   * Required. The name of a dialogflow virtual agent used for end user side
   * intent detection and suggestion. Format: `projects//locations//agent`. When
   * multiple agents are allowed in the same Dialogflow project.
   */
  set agent(value: string|null) {
    this.Serializable$set('agent', value);
  }

  get humanAgentSideConfig():
      HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySourceHumanAgentSideConfig_
      |null {
    return (
        (this.Serializable$has('humanAgentSideConfig')) ?
            (this.Serializable$get('humanAgentSideConfig')) :
            (null));
  }

  /**
   * The Dialogflow assist configuration for human agent.
   */
  set humanAgentSideConfig(
      value:
          HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySourceHumanAgentSideConfig_|
      null) {
    this.Serializable$set('humanAgentSideConfig', value);
  }

  get routerConfig(): AutomatedAgentConfigRouterConfig_|null {
    return (
        (this.Serializable$has('routerConfig')) ?
            (this.Serializable$get('routerConfig')) :
            (null));
  }

  /**
   * The configuration of the Dialogflow router for Dialogflow assist. The
   * router will be invoked (and override the agent setting above) if this field
   * is set.
   */
  set routerConfig(value: AutomatedAgentConfigRouterConfig_|null) {
    this.Serializable$set('routerConfig', value);
  }

  get workflow(): string|null {
    return (
        (this.Serializable$has('workflow')) ?
            (this.Serializable$get('workflow')) :
            (null));
  }

  /**
   * Workflow name
   */
  set workflow(value: string|null) {
    this.Serializable$set('workflow', value);
  }

  getConstructor(): SerializableCtor<
      HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySource_> {
    return HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['agent', 'humanAgentSideConfig', 'routerConfig', 'workflow'],
      objects: {
        'humanAgentSideConfig':
            HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySourceHumanAgentSideConfig_,
        'routerConfig': AutomatedAgentConfigRouterConfig_
      }
    };
  }
}

export interface HumanAgentAssistantConfigSuggestionQueryConfigDocumentQuerySource_Parameters {
  documents?: Array<string>|null;
}
export class
    HumanAgentAssistantConfigSuggestionQueryConfigDocumentQuerySource_ extends
        Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigSuggestionQueryConfigDocumentQuerySource_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'documents',
        (parameters.documents == null) ? (null) : (parameters.documents));
  }

  get documents(): Array<string>|null {
    return (
        (this.Serializable$has('documents')) ?
            (this.Serializable$get('documents')) :
            (null));
  }

  /**
   * Required. Knowledge documents to query from. Format:
   * `projects//locations//knowledgeBases//documents/`. Currently, only one
   * document is supported.
   */
  set documents(value: Array<string>|null) {
    this.Serializable$set('documents', value);
  }

  getConstructor(): SerializableCtor<
      HumanAgentAssistantConfigSuggestionQueryConfigDocumentQuerySource_> {
    return HumanAgentAssistantConfigSuggestionQueryConfigDocumentQuerySource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['documents']};
  }
}

export interface HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_Parameters {
  knowledgeBases?: Array<string>|null;
  knowledgeTypes?: Array<
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>|
      null;
}
export class
    HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_ extends
        Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'knowledgeBases',
        (parameters.knowledgeBases == null) ? (null) :
                                              (parameters.knowledgeBases));
    this.Serializable$set(
        'knowledgeTypes',
        (parameters.knowledgeTypes == null) ? (null) :
                                              (parameters.knowledgeTypes));
  }

  static get KnowledgeTypes():
      IHumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum {
    return HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum;
  }

  get knowledgeBases(): Array<string>|null {
    return (
        (this.Serializable$has('knowledgeBases')) ?
            (this.Serializable$get('knowledgeBases')) :
            (null));
  }

  /**
   * Required. Knowledge bases to query. Format:
   * `projects//locations//knowledgeBases/`. Currently, only one knowledge base
   * is supported.
   */
  set knowledgeBases(value: Array<string>|null) {
    this.Serializable$set('knowledgeBases', value);
  }

  get knowledgeTypes(): Array<
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>|
      null {
    return (
        (this.Serializable$has('knowledgeTypes')) ?
            (this.Serializable$get('knowledgeTypes')) :
            (null));
  }

  /**
   * Deprecated, the field is no longer has any effect. We will only return FAQ
   * doc in faq suggestion and return ARTICLE_SUGGESTION doc in article
   * suggestion.
   */
  set knowledgeTypes(
      value: Array<
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypes>|
      null) {
    this.Serializable$set('knowledgeTypes', value);
  }

  getConstructor(): SerializableCtor<
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_> {
    return HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'knowledgeTypes':
            HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_KnowledgeTypesEnum
      },
      keys: ['knowledgeBases', 'knowledgeTypes']
    };
  }
}

export interface HumanAgentAssistantConfigSuggestionQueryConfig_Parameters {
  knowledgeBaseQuerySource?:
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_|
      null;
  documentQuerySource?:
      HumanAgentAssistantConfigSuggestionQueryConfigDocumentQuerySource_|null;
  dialogflowQuerySource?:
      HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySource_|null;
  maxResults?: number|null;
  confidenceThreshold?: number|null;
  useSuggestionContext?: boolean|null;
  contextFilterSettings?:
      HumanAgentAssistantConfigSuggestionQueryConfigContextFilterSettings_|null;
}
export class HumanAgentAssistantConfigSuggestionQueryConfig_ extends
    Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigSuggestionQueryConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'knowledgeBaseQuerySource',
        (parameters.knowledgeBaseQuerySource == null) ?
            (null) :
            (parameters.knowledgeBaseQuerySource));
    this.Serializable$set(
        'documentQuerySource',
        (parameters.documentQuerySource == null) ?
            (null) :
            (parameters.documentQuerySource));
    this.Serializable$set(
        'dialogflowQuerySource',
        (parameters.dialogflowQuerySource == null) ?
            (null) :
            (parameters.dialogflowQuerySource));
    this.Serializable$set(
        'maxResults',
        (parameters.maxResults == null) ? (null) : (parameters.maxResults));
    this.Serializable$set(
        'confidenceThreshold',
        (parameters.confidenceThreshold == null) ?
            (null) :
            (parameters.confidenceThreshold));
    this.Serializable$set(
        'useSuggestionContext',
        (parameters.useSuggestionContext == null) ?
            (null) :
            (parameters.useSuggestionContext));
    this.Serializable$set(
        'contextFilterSettings',
        (parameters.contextFilterSettings == null) ?
            (null) :
            (parameters.contextFilterSettings));
  }

  get confidenceThreshold(): number|null {
    return (
        (this.Serializable$has('confidenceThreshold')) ?
            (this.Serializable$get('confidenceThreshold')) :
            (null));
  }

  /**
   * Confidence threshold of query result. Agent Assist gives each suggestion a
   * score in the range [0.0, 1.0], based on the relevance between the
   * suggestion and the current conversation context. A score of 0.0 has no
   * relevance, while a score of 1.0 has high relevance. Only suggestions with a
   * score greater than or equal to the value of this field are included in the
   * results. For a baseline model (the default), the recommended value is in
   * the range [0.05, 0.1]. For a custom model, there is no recommended value.
   * Tune this value by starting from a very low value and slowly increasing
   * until you have desired results. If this field is not set, it is default to
   * 0.0, which means that all suggestions are returned. Supported features:
   * ARTICLE_SUGGESTION, FAQ, SMART_REPLY, SMART_COMPOSE.
   */
  set confidenceThreshold(value: number|null) {
    this.Serializable$set('confidenceThreshold', value);
  }

  get contextFilterSettings():
      HumanAgentAssistantConfigSuggestionQueryConfigContextFilterSettings_
      |null {
    return (
        (this.Serializable$has('contextFilterSettings')) ?
            (this.Serializable$get('contextFilterSettings')) :
            (null));
  }

  /**
   * Determines how recent conversation context is filtered when generating
   * suggestions. If unspecified, no messages will be dropped.
   */
  set contextFilterSettings(
      value:
          HumanAgentAssistantConfigSuggestionQueryConfigContextFilterSettings_|
      null) {
    this.Serializable$set('contextFilterSettings', value);
  }

  get dialogflowQuerySource():
      HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySource_
      |null {
    return (
        (this.Serializable$has('dialogflowQuerySource')) ?
            (this.Serializable$get('dialogflowQuerySource')) :
            (null));
  }

  /**
   * Query from Dialogflow agent. It is used by DIALOGFLOW_ASSIST.
   */
  set dialogflowQuerySource(
      value:
          HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySource_|
      null) {
    this.Serializable$set('dialogflowQuerySource', value);
  }

  get documentQuerySource():
      HumanAgentAssistantConfigSuggestionQueryConfigDocumentQuerySource_|null {
    return (
        (this.Serializable$has('documentQuerySource')) ?
            (this.Serializable$get('documentQuerySource')) :
            (null));
  }

  /**
   * Query from knowledge base document. It is used by: SMART_REPLY,
   * SMART_COMPOSE.
   */
  set documentQuerySource(
      value: HumanAgentAssistantConfigSuggestionQueryConfigDocumentQuerySource_|
      null) {
    this.Serializable$set('documentQuerySource', value);
  }

  get knowledgeBaseQuerySource():
      HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_
      |null {
    return (
        (this.Serializable$has('knowledgeBaseQuerySource')) ?
            (this.Serializable$get('knowledgeBaseQuerySource')) :
            (null));
  }

  /**
   * Query from knowledgebase. It is used by: ARTICLE_SUGGESTION, FAQ.
   */
  set knowledgeBaseQuerySource(
      value:
          HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_|
      null) {
    this.Serializable$set('knowledgeBaseQuerySource', value);
  }

  get maxResults(): number|null {
    return (
        (this.Serializable$has('maxResults')) ?
            (this.Serializable$get('maxResults')) :
            (null));
  }

  /**
   * Maximum number of results to return. Currently, if unset, defaults to 10.
   * And the max number is 20.
   */
  set maxResults(value: number|null) {
    this.Serializable$set('maxResults', value);
  }

  get useSuggestionContext(): boolean|null {
    return (
        (this.Serializable$has('useSuggestionContext')) ?
            (this.Serializable$get('useSuggestionContext')) :
            (null));
  }

  /**
   * [DEPRECATED] Please use context_filter_settings instead. The flag to enable
   * suggestion_context feature during suggestion time. If
   * `use_suggestion_context` is false, suggestions will be based on unfiltered
   * conversation history, including messages between customer and virtual
   * agent. If `use_suggestion_context` is true, suggestions will be only based
   * on customer messages except the last message that triggers hand-off (e.g.
   * \"I want to talk to human agent.\") when handing off to human agent.
   * Following suggestions generated during conversation between customer and
   * human agent will not include conversation history between customer and
   * virtual agent.
   */
  set useSuggestionContext(value: boolean|null) {
    this.Serializable$set('useSuggestionContext', value);
  }

  getConstructor():
      SerializableCtor<HumanAgentAssistantConfigSuggestionQueryConfig_> {
    return HumanAgentAssistantConfigSuggestionQueryConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'confidenceThreshold', 'contextFilterSettings', 'dialogflowQuerySource',
        'documentQuerySource', 'knowledgeBaseQuerySource', 'maxResults',
        'useSuggestionContext'
      ],
      objects: {
        'contextFilterSettings':
            HumanAgentAssistantConfigSuggestionQueryConfigContextFilterSettings_,
        'dialogflowQuerySource':
            HumanAgentAssistantConfigSuggestionQueryConfigDialogflowQuerySource_,
        'documentQuerySource':
            HumanAgentAssistantConfigSuggestionQueryConfigDocumentQuerySource_,
        'knowledgeBaseQuerySource':
            HumanAgentAssistantConfigSuggestionQueryConfigKnowledgeBaseQuerySource_
      }
    };
  }
}

export interface HumanAgentAssistantConfigSuggestionTriggerSettings_Parameters {
  noSmallTalk?: boolean|null;
  onlyEndUser?: boolean|null;
}
export class HumanAgentAssistantConfigSuggestionTriggerSettings_ extends
    Serializable {
  constructor(
      parameters:
          HumanAgentAssistantConfigSuggestionTriggerSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'noSmallTalk',
        (parameters.noSmallTalk == null) ? (null) : (parameters.noSmallTalk));
    this.Serializable$set(
        'onlyEndUser',
        (parameters.onlyEndUser == null) ? (null) : (parameters.onlyEndUser));
  }

  get noSmallTalk(): boolean|null {
    return (
        (this.Serializable$has('noSmallTalk')) ?
            (this.Serializable$get('noSmallTalk')) :
            (null));
  }

  /**
   * Do not trigger if last utterance is small talk.
   */
  set noSmallTalk(value: boolean|null) {
    this.Serializable$set('noSmallTalk', value);
  }

  get onlyEndUser(): boolean|null {
    return (
        (this.Serializable$has('onlyEndUser')) ?
            (this.Serializable$get('onlyEndUser')) :
            (null));
  }

  /**
   * Only trigger suggestion if participant role of last utterance is END_USER.
   */
  set onlyEndUser(value: boolean|null) {
    this.Serializable$set('onlyEndUser', value);
  }

  getConstructor():
      SerializableCtor<HumanAgentAssistantConfigSuggestionTriggerSettings_> {
    return HumanAgentAssistantConfigSuggestionTriggerSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['noSmallTalk', 'onlyEndUser']};
  }
}

export interface HumanAgentAssistantConfig_Parameters {
  name?: string|null;
  notificationConfig?: NotificationConfig_|null;
  humanAgentSuggestionConfig?: HumanAgentAssistantConfigSuggestionConfig_|null;
  endUserSuggestionConfig?: HumanAgentAssistantConfigSuggestionConfig_|null;
  messageAnalysisConfig?: HumanAgentAssistantConfigMessageAnalysisConfig_|null;
}
export class HumanAgentAssistantConfig_ extends Serializable {
  constructor(parameters: HumanAgentAssistantConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'notificationConfig',
        (parameters.notificationConfig == null) ?
            (null) :
            (parameters.notificationConfig));
    this.Serializable$set(
        'humanAgentSuggestionConfig',
        (parameters.humanAgentSuggestionConfig == null) ?
            (null) :
            (parameters.humanAgentSuggestionConfig));
    this.Serializable$set(
        'endUserSuggestionConfig',
        (parameters.endUserSuggestionConfig == null) ?
            (null) :
            (parameters.endUserSuggestionConfig));
    this.Serializable$set(
        'messageAnalysisConfig',
        (parameters.messageAnalysisConfig == null) ?
            (null) :
            (parameters.messageAnalysisConfig));
  }

  get endUserSuggestionConfig(): HumanAgentAssistantConfigSuggestionConfig_
      |null {
    return (
        (this.Serializable$has('endUserSuggestionConfig')) ?
            (this.Serializable$get('endUserSuggestionConfig')) :
            (null));
  }

  /**
   * Configuration for agent assistance of end user participant. Currently, this
   * feature is not general available, please contact Google to get access.
   */
  set endUserSuggestionConfig(value: HumanAgentAssistantConfigSuggestionConfig_|
                              null) {
    this.Serializable$set('endUserSuggestionConfig', value);
  }

  get humanAgentSuggestionConfig(): HumanAgentAssistantConfigSuggestionConfig_
      |null {
    return (
        (this.Serializable$has('humanAgentSuggestionConfig')) ?
            (this.Serializable$get('humanAgentSuggestionConfig')) :
            (null));
  }

  /**
   * Configuration for agent assistance of human agent participant.
   */
  set humanAgentSuggestionConfig(value:
                                     HumanAgentAssistantConfigSuggestionConfig_|
                                 null) {
    this.Serializable$set('humanAgentSuggestionConfig', value);
  }

  get messageAnalysisConfig(): HumanAgentAssistantConfigMessageAnalysisConfig_
      |null {
    return (
        (this.Serializable$has('messageAnalysisConfig')) ?
            (this.Serializable$get('messageAnalysisConfig')) :
            (null));
  }

  /**
   * Configuration for message analysis.
   */
  set messageAnalysisConfig(value:
                                HumanAgentAssistantConfigMessageAnalysisConfig_|
                            null) {
    this.Serializable$set('messageAnalysisConfig', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Deprecated, use human_agent_suggestion_config and
   * end_user_suggestion_config instead. Resource name of the agent assistant to
   * use. Format: `projects//humanAgentAssistants/`. Legacy event based
   * workflow: There are two ways to get automatic generated suggestion, 1. have
   * notification_config configured and call Participants.ListSuggestions to
   * fetch latest suggestion. 2. call Participants.ListSuggestions periodically
   * to fetch suggestion updates. `ArticleSuggestion` and `Faq` are returned
   * together if configured. We will always try to compile `ArticleSuggestion`
   * and `Faq` suggestion if they are configured in HumanAgentAssistant. Changes
   * in new event based workflow: 1. Each suggestion contains exactly one
   * suggestion type. 2. Suggestion of different type is in pubsub event,
   * nothing will be returned in the deprecated Participants.ListSuggestions
   * API. Migration: If human_agent_suggestion_config or
   * end_user_suggestion_config is present, name will be ignored, and we will
   * use the new workflow. Otherwise, we will continue to use the old workflow.
   * If human_agent_suggestion_config or end_user_suggestion_config is not
   * present, name will be used.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get notificationConfig(): NotificationConfig_|null {
    return (
        (this.Serializable$has('notificationConfig')) ?
            (this.Serializable$get('notificationConfig')) :
            (null));
  }

  /**
   * Pub/Sub topic on which to publish new agent assistant events.
   */
  set notificationConfig(value: NotificationConfig_|null) {
    this.Serializable$set('notificationConfig', value);
  }

  getConstructor(): SerializableCtor<HumanAgentAssistantConfig_> {
    return HumanAgentAssistantConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'endUserSuggestionConfig', 'humanAgentSuggestionConfig',
        'messageAnalysisConfig', 'name', 'notificationConfig'
      ],
      objects: {
        'endUserSuggestionConfig': HumanAgentAssistantConfigSuggestionConfig_,
        'humanAgentSuggestionConfig':
            HumanAgentAssistantConfigSuggestionConfig_,
        'messageAnalysisConfig':
            HumanAgentAssistantConfigMessageAnalysisConfig_,
        'notificationConfig': NotificationConfig_
      }
    };
  }
}

export interface HumanAgentAssistantEvent_Parameters {
  conversation?: string|null;
  type?: HumanAgentAssistantEvent_Type|null;
  participant?: string|null;
  originatingParticipant?: string|null;
  suggestionResult?: SuggestionResult_|null;
  suggestionResults?: Array<SuggestionResult_>|null;
}
export class HumanAgentAssistantEvent_ extends Serializable {
  constructor(parameters: HumanAgentAssistantEvent_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversation',
        (parameters.conversation == null) ? (null) : (parameters.conversation));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'participant',
        (parameters.participant == null) ? (null) : (parameters.participant));
    this.Serializable$set(
        'originatingParticipant',
        (parameters.originatingParticipant == null) ?
            (null) :
            (parameters.originatingParticipant));
    this.Serializable$set(
        'suggestionResult',
        (parameters.suggestionResult == null) ? (null) :
                                                (parameters.suggestionResult));
    this.Serializable$set(
        'suggestionResults',
        (parameters.suggestionResults == null) ?
            (null) :
            (parameters.suggestionResults));
  }

  static get Type(): IHumanAgentAssistantEvent_TypeEnum {
    return HumanAgentAssistantEvent_TypeEnum;
  }

  get conversation(): string|null {
    return (
        (this.Serializable$has('conversation')) ?
            (this.Serializable$get('conversation')) :
            (null));
  }

  /**
   * The conversation this notification refers to. Format:
   * `projects//conversations/`.
   */
  set conversation(value: string|null) {
    this.Serializable$set('conversation', value);
  }

  get originatingParticipant(): string|null {
    return (
        (this.Serializable$has('originatingParticipant')) ?
            (this.Serializable$get('originatingParticipant')) :
            (null));
  }

  /**
   * The participant that the suggestion is compiled from. Format:
   * `projects//conversations//participants/`.
   */
  set originatingParticipant(value: string|null) {
    this.Serializable$set('originatingParticipant', value);
  }

  get participant(): string|null {
    return (
        (this.Serializable$has('participant')) ?
            (this.Serializable$get('participant')) :
            (null));
  }

  /**
   * The participant that the suggestion is compiled for. And This field is used
   * to call Participants.ListSuggestions API. Format:
   * `projects//conversations//participants/`. It will not be set in legacy
   * workflow. HumanAgentAssistantConfig.name for more information.
   */
  set participant(value: string|null) {
    this.Serializable$set('participant', value);
  }

  get suggestionResult(): SuggestionResult_|null {
    return (
        (this.Serializable$has('suggestionResult')) ?
            (this.Serializable$get('suggestionResult')) :
            (null));
  }

  /**
   * The suggestion result payload that this notification refers to. It will not
   * be set in legacy workflow. For more information, please refer to
   * HumanAgentAssistantConfig.name.
   */
  set suggestionResult(value: SuggestionResult_|null) {
    this.Serializable$set('suggestionResult', value);
  }

  get suggestionResults(): Array<SuggestionResult_>|null {
    return (
        (this.Serializable$has('suggestionResults')) ?
            (this.Serializable$get('suggestionResults')) :
            (null));
  }

  /**
   * The suggestion results payload that this notification refers to. It will
   * only be set when
   * HumanAgentAssistantConfig.SuggestionConfig.group_suggestion_responses sets
   * to true.
   */
  set suggestionResults(value: Array<SuggestionResult_>|null) {
    this.Serializable$set('suggestionResults', value);
  }

  get type(): HumanAgentAssistantEvent_Type|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Deprecated. The type of the event that this notification refers to.
   */
  set type(value: HumanAgentAssistantEvent_Type|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<HumanAgentAssistantEvent_> {
    return HumanAgentAssistantEvent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'suggestionResults': SuggestionResult_},
      enums: {'type': HumanAgentAssistantEvent_TypeEnum},
      keys: [
        'conversation', 'originatingParticipant', 'participant',
        'suggestionResult', 'suggestionResults', 'type'
      ],
      objects: {'suggestionResult': SuggestionResult_}
    };
  }
}

export interface HumanAgentAssistantInternalConfig_Parameters {
  experimentalMode?: boolean|null;
}
export class HumanAgentAssistantInternalConfig_ extends Serializable {
  constructor(parameters: HumanAgentAssistantInternalConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'experimentalMode',
        (parameters.experimentalMode == null) ? (null) :
                                                (parameters.experimentalMode));
  }

  get experimentalMode(): boolean|null {
    return (
        (this.Serializable$has('experimentalMode')) ?
            (this.Serializable$get('experimentalMode')) :
            (null));
  }

  /**
   * Uses experiment internal configs.
   */
  set experimentalMode(value: boolean|null) {
    this.Serializable$set('experimentalMode', value);
  }

  getConstructor(): SerializableCtor<HumanAgentAssistantInternalConfig_> {
    return HumanAgentAssistantInternalConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['experimentalMode']};
  }
}

export interface HumanAgentAssistant_Parameters {
  name?: string|null;
  displayName?: string|null;
  articleSuggestionConfig?: ArticleSuggestionConfig_|null;
  faqAnswersConfig?: FaqAnswersConfig_|null;
  smartReplyConfig?: SmartReplyConfig_|null;
  smartComposeConfig?: SmartComposeConfig_|null;
  dialogflowAssistConfig?: DialogflowAssistConfig_|null;
  internalConfig?: HumanAgentAssistantInternalConfig_|null;
}
export class HumanAgentAssistant_ extends Serializable {
  constructor(parameters: HumanAgentAssistant_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'articleSuggestionConfig',
        (parameters.articleSuggestionConfig == null) ?
            (null) :
            (parameters.articleSuggestionConfig));
    this.Serializable$set(
        'faqAnswersConfig',
        (parameters.faqAnswersConfig == null) ? (null) :
                                                (parameters.faqAnswersConfig));
    this.Serializable$set(
        'smartReplyConfig',
        (parameters.smartReplyConfig == null) ? (null) :
                                                (parameters.smartReplyConfig));
    this.Serializable$set(
        'smartComposeConfig',
        (parameters.smartComposeConfig == null) ?
            (null) :
            (parameters.smartComposeConfig));
    this.Serializable$set(
        'dialogflowAssistConfig',
        (parameters.dialogflowAssistConfig == null) ?
            (null) :
            (parameters.dialogflowAssistConfig));
    this.Serializable$set(
        'internalConfig',
        (parameters.internalConfig == null) ? (null) :
                                              (parameters.internalConfig));
  }

  get articleSuggestionConfig(): ArticleSuggestionConfig_|null {
    return (
        (this.Serializable$has('articleSuggestionConfig')) ?
            (this.Serializable$get('articleSuggestionConfig')) :
            (null));
  }

  /**
   * Optional. Settings for article suggestion.
   */
  set articleSuggestionConfig(value: ArticleSuggestionConfig_|null) {
    this.Serializable$set('articleSuggestionConfig', value);
  }

  get dialogflowAssistConfig(): DialogflowAssistConfig_|null {
    return (
        (this.Serializable$has('dialogflowAssistConfig')) ?
            (this.Serializable$get('dialogflowAssistConfig')) :
            (null));
  }

  /**
   * Optional. Settings for Dialogflow assist service.
   */
  set dialogflowAssistConfig(value: DialogflowAssistConfig_|null) {
    this.Serializable$set('dialogflowAssistConfig', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Optional. Human readable name. Max length 1024 bytes.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get faqAnswersConfig(): FaqAnswersConfig_|null {
    return (
        (this.Serializable$has('faqAnswersConfig')) ?
            (this.Serializable$get('faqAnswersConfig')) :
            (null));
  }

  /**
   * Optional. Settings for knowledge service.
   */
  set faqAnswersConfig(value: FaqAnswersConfig_|null) {
    this.Serializable$set('faqAnswersConfig', value);
  }

  get internalConfig(): HumanAgentAssistantInternalConfig_|null {
    return (
        (this.Serializable$has('internalConfig')) ?
            (this.Serializable$get('internalConfig')) :
            (null));
  }

  /**
   * Optional. Internal config.
   */
  set internalConfig(value: HumanAgentAssistantInternalConfig_|null) {
    this.Serializable$set('internalConfig', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of human agent assistant. Required for
   * HumanAgentAssistants.UpdateHumanAgentAssistant method. Format:
   * `projects//humanAgentAssistants/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get smartComposeConfig(): SmartComposeConfig_|null {
    return (
        (this.Serializable$has('smartComposeConfig')) ?
            (this.Serializable$get('smartComposeConfig')) :
            (null));
  }

  /**
   * Optional. Settings for smart compose service.
   */
  set smartComposeConfig(value: SmartComposeConfig_|null) {
    this.Serializable$set('smartComposeConfig', value);
  }

  get smartReplyConfig(): SmartReplyConfig_|null {
    return (
        (this.Serializable$has('smartReplyConfig')) ?
            (this.Serializable$get('smartReplyConfig')) :
            (null));
  }

  /**
   * Optional. Settings for smart reply service.
   */
  set smartReplyConfig(value: SmartReplyConfig_|null) {
    this.Serializable$set('smartReplyConfig', value);
  }

  getConstructor(): SerializableCtor<HumanAgentAssistant_> {
    return HumanAgentAssistant_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'articleSuggestionConfig', 'dialogflowAssistConfig', 'displayName',
        'faqAnswersConfig', 'internalConfig', 'name', 'smartComposeConfig',
        'smartReplyConfig'
      ],
      objects: {
        'articleSuggestionConfig': ArticleSuggestionConfig_,
        'dialogflowAssistConfig': DialogflowAssistConfig_,
        'faqAnswersConfig': FaqAnswersConfig_,
        'internalConfig': HumanAgentAssistantInternalConfig_,
        'smartComposeConfig': SmartComposeConfig_,
        'smartReplyConfig': SmartReplyConfig_
      }
    };
  }
}

export interface HumanAgentHandoffConfigLivePersonConfig_Parameters {
  accountNumber?: string|null;
}
export class HumanAgentHandoffConfigLivePersonConfig_ extends Serializable {
  constructor(
      parameters: HumanAgentHandoffConfigLivePersonConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'accountNumber',
        (parameters.accountNumber == null) ? (null) :
                                             (parameters.accountNumber));
  }

  get accountNumber(): string|null {
    return (
        (this.Serializable$has('accountNumber')) ?
            (this.Serializable$get('accountNumber')) :
            (null));
  }

  /**
   * Required. Account number of the LivePerson account to connect. This is the
   * account number you input at the login page.
   */
  set accountNumber(value: string|null) {
    this.Serializable$set('accountNumber', value);
  }

  getConstructor(): SerializableCtor<HumanAgentHandoffConfigLivePersonConfig_> {
    return HumanAgentHandoffConfigLivePersonConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['accountNumber']};
  }
}

export interface HumanAgentHandoffConfigSalesforceLiveAgentConfig_Parameters {
  organizationId?: string|null;
  deploymentId?: string|null;
  buttonId?: string|null;
  endpointDomain?: string|null;
}
export class HumanAgentHandoffConfigSalesforceLiveAgentConfig_ extends
    Serializable {
  constructor(
      parameters:
          HumanAgentHandoffConfigSalesforceLiveAgentConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'organizationId',
        (parameters.organizationId == null) ? (null) :
                                              (parameters.organizationId));
    this.Serializable$set(
        'deploymentId',
        (parameters.deploymentId == null) ? (null) : (parameters.deploymentId));
    this.Serializable$set(
        'buttonId',
        (parameters.buttonId == null) ? (null) : (parameters.buttonId));
    this.Serializable$set(
        'endpointDomain',
        (parameters.endpointDomain == null) ? (null) :
                                              (parameters.endpointDomain));
  }

  get buttonId(): string|null {
    return (
        (this.Serializable$has('buttonId')) ?
            (this.Serializable$get('buttonId')) :
            (null));
  }

  /**
   * Required. Live Agent chat button ID.
   */
  set buttonId(value: string|null) {
    this.Serializable$set('buttonId', value);
  }

  get deploymentId(): string|null {
    return (
        (this.Serializable$has('deploymentId')) ?
            (this.Serializable$get('deploymentId')) :
            (null));
  }

  /**
   * Required. Live Agent deployment ID.
   */
  set deploymentId(value: string|null) {
    this.Serializable$set('deploymentId', value);
  }

  get endpointDomain(): string|null {
    return (
        (this.Serializable$has('endpointDomain')) ?
            (this.Serializable$get('endpointDomain')) :
            (null));
  }

  /**
   * Required. Domain of the Live Agent endpoint for this agent. You can find
   * the endpoint URL in the `Live Agent settings` page. For example if URL has
   * the form https://d.la4-c2-phx.salesforceliveagent.com/..., you should fill
   * in d.la4-c2-phx.salesforceliveagent.com.
   */
  set endpointDomain(value: string|null) {
    this.Serializable$set('endpointDomain', value);
  }

  get organizationId(): string|null {
    return (
        (this.Serializable$has('organizationId')) ?
            (this.Serializable$get('organizationId')) :
            (null));
  }

  /**
   * Required. The organization ID of the Salesforce account.
   */
  set organizationId(value: string|null) {
    this.Serializable$set('organizationId', value);
  }

  getConstructor():
      SerializableCtor<HumanAgentHandoffConfigSalesforceLiveAgentConfig_> {
    return HumanAgentHandoffConfigSalesforceLiveAgentConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['buttonId', 'deploymentId', 'endpointDomain', 'organizationId']
    };
  }
}

export interface HumanAgentHandoffConfig_Parameters {
  livePersonConfig?: HumanAgentHandoffConfigLivePersonConfig_|null;
  salesforceLiveAgentConfig?: HumanAgentHandoffConfigSalesforceLiveAgentConfig_|
      null;
}
export class HumanAgentHandoffConfig_ extends Serializable {
  constructor(parameters: HumanAgentHandoffConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'livePersonConfig',
        (parameters.livePersonConfig == null) ? (null) :
                                                (parameters.livePersonConfig));
    this.Serializable$set(
        'salesforceLiveAgentConfig',
        (parameters.salesforceLiveAgentConfig == null) ?
            (null) :
            (parameters.salesforceLiveAgentConfig));
  }

  get livePersonConfig(): HumanAgentHandoffConfigLivePersonConfig_|null {
    return (
        (this.Serializable$has('livePersonConfig')) ?
            (this.Serializable$get('livePersonConfig')) :
            (null));
  }

  /**
   * Uses LivePerson (https://www.liveperson.com).
   */
  set livePersonConfig(value: HumanAgentHandoffConfigLivePersonConfig_|null) {
    this.Serializable$set('livePersonConfig', value);
  }

  get salesforceLiveAgentConfig():
      HumanAgentHandoffConfigSalesforceLiveAgentConfig_|null {
    return (
        (this.Serializable$has('salesforceLiveAgentConfig')) ?
            (this.Serializable$get('salesforceLiveAgentConfig')) :
            (null));
  }

  /**
   * Uses Salesforce Live Agent.
   */
  set salesforceLiveAgentConfig(
      value: HumanAgentHandoffConfigSalesforceLiveAgentConfig_|null) {
    this.Serializable$set('salesforceLiveAgentConfig', value);
  }

  getConstructor(): SerializableCtor<HumanAgentHandoffConfig_> {
    return HumanAgentHandoffConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['livePersonConfig', 'salesforceLiveAgentConfig'],
      objects: {
        'livePersonConfig': HumanAgentHandoffConfigLivePersonConfig_,
        'salesforceLiveAgentConfig':
            HumanAgentHandoffConfigSalesforceLiveAgentConfig_
      }
    };
  }
}

export interface ImportAgentRequest_Parameters {
  agentUri?: string|null;
  agentContent?: string|null;
  exportAgentFormat?: ImportAgentRequest_ExportAgentFormat|null;
}
export class ImportAgentRequest_ extends Serializable {
  constructor(parameters: ImportAgentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'agentUri',
        (parameters.agentUri == null) ? (null) : (parameters.agentUri));
    this.Serializable$set(
        'agentContent',
        (parameters.agentContent == null) ? (null) : (parameters.agentContent));
    this.Serializable$set(
        'exportAgentFormat',
        (parameters.exportAgentFormat == null) ?
            (null) :
            (parameters.exportAgentFormat));
  }

  static get ExportAgentFormat(): IImportAgentRequest_ExportAgentFormatEnum {
    return ImportAgentRequest_ExportAgentFormatEnum;
  }

  get agentContent(): string|null {
    return (
        (this.Serializable$has('agentContent')) ?
            (this.Serializable$get('agentContent')) :
            (null));
  }

  /**
   * Zip compressed raw byte content for agent.
   */
  set agentContent(value: string|null) {
    this.Serializable$set('agentContent', value);
  }

  get agentUri(): string|null {
    return (
        (this.Serializable$has('agentUri')) ?
            (this.Serializable$get('agentUri')) :
            (null));
  }

  /**
   * The URI to a Google Cloud Storage file containing the agent to import.
   * Note: The URI must start with \"gs://\". Dialogflow performs a read
   * operation for the Cloud Storage object on the caller's behalf, so your
   * request authentication must have read permissions for the object. For more
   * information, see [Dialogflow access
   * control](https://cloud.google.com/dialogflow/cx/docs/concept/access-control#storage).
   */
  set agentUri(value: string|null) {
    this.Serializable$set('agentUri', value);
  }

  get exportAgentFormat(): ImportAgentRequest_ExportAgentFormat|null {
    return (
        (this.Serializable$has('exportAgentFormat')) ?
            (this.Serializable$get('exportAgentFormat')) :
            (null));
  }

  /**
   * Optional. Specifies the export format for agent that is being imported. If
   * left unspecified, EXPORT_AGENT_FORMAT_TYPE_STANDARD will be used.
   */
  set exportAgentFormat(value: ImportAgentRequest_ExportAgentFormat|null) {
    this.Serializable$set('exportAgentFormat', value);
  }

  getConstructor(): SerializableCtor<ImportAgentRequest_> {
    return ImportAgentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'exportAgentFormat': ImportAgentRequest_ExportAgentFormatEnum},
      keys: ['agentContent', 'agentUri', 'exportAgentFormat']
    };
  }
}

export interface ImportConversationDataOperationMetadata_Parameters {
  conversationDataset?: string|null;
  partialFailures?: Array<GoogleRpcStatus>|null;
  createTime?: string|null;
  endTime?: string|null;
}
export class ImportConversationDataOperationMetadata_ extends Serializable {
  constructor(
      parameters: ImportConversationDataOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationDataset',
        (parameters.conversationDataset == null) ?
            (null) :
            (parameters.conversationDataset));
    this.Serializable$set(
        'partialFailures',
        (parameters.partialFailures == null) ? (null) :
                                               (parameters.partialFailures));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'endTime',
        (parameters.endTime == null) ? (null) : (parameters.endTime));
  }

  get conversationDataset(): string|null {
    return (
        (this.Serializable$has('conversationDataset')) ?
            (this.Serializable$get('conversationDataset')) :
            (null));
  }

  /**
   * The resource name of the imported conversation dataset. Format:
   * `projects//conversationDatasets/`
   */
  set conversationDataset(value: string|null) {
    this.Serializable$set('conversationDataset', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when import conversation data request was created. The time is
   * measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get endTime(): string|null {
    return (
        (this.Serializable$has('endTime')) ?
            (this.Serializable$get('endTime')) :
            (null));
  }

  /**
   * Timestamp when import conversation data job finished. The time is measured
   * on server side.
   */
  set endTime(value: string|null) {
    this.Serializable$set('endTime', value);
  }

  get partialFailures(): Array<GoogleRpcStatus>|null {
    return (
        (this.Serializable$has('partialFailures')) ?
            (this.Serializable$get('partialFailures')) :
            (null));
  }

  /**
   * Partial failures are failures that don't fail the whole long running
   * operation, e.g. single files that couldn't be read.
   */
  set partialFailures(value: Array<GoogleRpcStatus>|null) {
    this.Serializable$set('partialFailures', value);
  }

  getConstructor(): SerializableCtor<ImportConversationDataOperationMetadata_> {
    return ImportConversationDataOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'partialFailures': GoogleRpcStatus},
      keys: ['conversationDataset', 'createTime', 'endTime', 'partialFailures']
    };
  }
}

export interface ImportConversationDataOperationResponse_Parameters {
  conversationDataset?: string|null;
  requestCount?: number|null;
  importCount?: number|null;
}
export class ImportConversationDataOperationResponse_ extends Serializable {
  constructor(
      parameters: ImportConversationDataOperationResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationDataset',
        (parameters.conversationDataset == null) ?
            (null) :
            (parameters.conversationDataset));
    this.Serializable$set(
        'requestCount',
        (parameters.requestCount == null) ? (null) : (parameters.requestCount));
    this.Serializable$set(
        'importCount',
        (parameters.importCount == null) ? (null) : (parameters.importCount));
  }

  get conversationDataset(): string|null {
    return (
        (this.Serializable$has('conversationDataset')) ?
            (this.Serializable$get('conversationDataset')) :
            (null));
  }

  /**
   * The resource name of the imported conversation dataset. Format:
   * `projects//conversationDatasets/`
   */
  set conversationDataset(value: string|null) {
    this.Serializable$set('conversationDataset', value);
  }

  get importCount(): number|null {
    return (
        (this.Serializable$has('importCount')) ?
            (this.Serializable$get('importCount')) :
            (null));
  }

  /**
   * Number of conversations imported successfully.
   */
  set importCount(value: number|null) {
    this.Serializable$set('importCount', value);
  }

  get requestCount(): number|null {
    return (
        (this.Serializable$has('requestCount')) ?
            (this.Serializable$get('requestCount')) :
            (null));
  }

  /**
   * Number of conversations requested to import.
   */
  set requestCount(value: number|null) {
    this.Serializable$set('requestCount', value);
  }

  getConstructor(): SerializableCtor<ImportConversationDataOperationResponse_> {
    return ImportConversationDataOperationResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationDataset', 'importCount', 'requestCount']};
  }
}

export interface ImportConversationDataRequest_Parameters {
  inputConfig?: InputConfig_|null;
  conversationInfo?: ConversationInfo_|null;
}
export class ImportConversationDataRequest_ extends Serializable {
  constructor(parameters: ImportConversationDataRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'inputConfig',
        (parameters.inputConfig == null) ? (null) : (parameters.inputConfig));
    this.Serializable$set(
        'conversationInfo',
        (parameters.conversationInfo == null) ? (null) :
                                                (parameters.conversationInfo));
  }

  get conversationInfo(): ConversationInfo_|null {
    return (
        (this.Serializable$has('conversationInfo')) ?
            (this.Serializable$get('conversationInfo')) :
            (null));
  }

  /**
   * Optional. Conversation info contains extra information on location, time,
   * and customized metadata for the batch of raw conversation files to be
   * imported.
   */
  set conversationInfo(value: ConversationInfo_|null) {
    this.Serializable$set('conversationInfo', value);
  }

  get inputConfig(): InputConfig_|null {
    return (
        (this.Serializable$has('inputConfig')) ?
            (this.Serializable$get('inputConfig')) :
            (null));
  }

  /**
   * Required. Configurations that contains information of raw file location,
   * raw file type, etc.
   */
  set inputConfig(value: InputConfig_|null) {
    this.Serializable$set('inputConfig', value);
  }

  getConstructor(): SerializableCtor<ImportConversationDataRequest_> {
    return ImportConversationDataRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['conversationInfo', 'inputConfig'],
      objects:
          {'conversationInfo': ConversationInfo_, 'inputConfig': InputConfig_}
    };
  }
}

export interface ImportDocumentTemplate_Parameters {
  mimeType?: string|null;
  knowledgeTypes?: Array<ImportDocumentTemplate_KnowledgeTypes>|null;
  metadata?: ApiClientObjectMap<string>|null;
}
export class ImportDocumentTemplate_ extends Serializable {
  constructor(parameters: ImportDocumentTemplate_Parameters = {}) {
    super();
    this.Serializable$set(
        'mimeType',
        (parameters.mimeType == null) ? (null) : (parameters.mimeType));
    this.Serializable$set(
        'knowledgeTypes',
        (parameters.knowledgeTypes == null) ? (null) :
                                              (parameters.knowledgeTypes));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
  }

  static get KnowledgeTypes(): IImportDocumentTemplate_KnowledgeTypesEnum {
    return ImportDocumentTemplate_KnowledgeTypesEnum;
  }

  get knowledgeTypes(): Array<ImportDocumentTemplate_KnowledgeTypes>|null {
    return (
        (this.Serializable$has('knowledgeTypes')) ?
            (this.Serializable$get('knowledgeTypes')) :
            (null));
  }

  /**
   * Required. The knowledge type of document content.
   */
  set knowledgeTypes(value: Array<ImportDocumentTemplate_KnowledgeTypes>|null) {
    this.Serializable$set('knowledgeTypes', value);
  }

  get metadata(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Metadata for the document. The metadata supports arbitrary key-value pairs.
   * Suggested use cases include storing a document's title, an external URL
   * distinct from the document's content_uri, etc. The max size of a `key` or a
   * `value` of the metadata is 1024 bytes.
   */
  set metadata(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('metadata', value);
  }

  get mimeType(): string|null {
    return (
        (this.Serializable$has('mimeType')) ?
            (this.Serializable$get('mimeType')) :
            (null));
  }

  /**
   * Required. The MIME type of the document.
   */
  set mimeType(value: string|null) {
    this.Serializable$set('mimeType', value);
  }

  getConstructor(): SerializableCtor<ImportDocumentTemplate_> {
    return ImportDocumentTemplate_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'knowledgeTypes': ImportDocumentTemplate_KnowledgeTypesEnum},
      keys: ['knowledgeTypes', 'metadata', 'mimeType'],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface ImportDocumentsRequest_Parameters {
  gcsSource?: GcsSources_|null;
  documentTemplate?: ImportDocumentTemplate_|null;
  importGcsCustomMetadata?: boolean|null;
}
export class ImportDocumentsRequest_ extends Serializable {
  constructor(parameters: ImportDocumentsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'gcsSource',
        (parameters.gcsSource == null) ? (null) : (parameters.gcsSource));
    this.Serializable$set(
        'documentTemplate',
        (parameters.documentTemplate == null) ? (null) :
                                                (parameters.documentTemplate));
    this.Serializable$set(
        'importGcsCustomMetadata',
        (parameters.importGcsCustomMetadata == null) ?
            (null) :
            (parameters.importGcsCustomMetadata));
  }

  get documentTemplate(): ImportDocumentTemplate_|null {
    return (
        (this.Serializable$has('documentTemplate')) ?
            (this.Serializable$get('documentTemplate')) :
            (null));
  }

  /**
   * Required. Document template used for importing all the documents.
   */
  set documentTemplate(value: ImportDocumentTemplate_|null) {
    this.Serializable$set('documentTemplate', value);
  }

  get gcsSource(): GcsSources_|null {
    return (
        (this.Serializable$has('gcsSource')) ?
            (this.Serializable$get('gcsSource')) :
            (null));
  }

  /**
   * The Google Cloud Storage location for the documents. The path can include a
   * wildcard. These URIs may have the forms `gs:///`. `gs:////*.`.
   */
  set gcsSource(value: GcsSources_|null) {
    this.Serializable$set('gcsSource', value);
  }

  get importGcsCustomMetadata(): boolean|null {
    return (
        (this.Serializable$has('importGcsCustomMetadata')) ?
            (this.Serializable$get('importGcsCustomMetadata')) :
            (null));
  }

  /**
   * Whether to import custom metadata from Google Cloud Storage. Only valid
   * when the document source is Google Cloud Storage URI.
   */
  set importGcsCustomMetadata(value: boolean|null) {
    this.Serializable$set('importGcsCustomMetadata', value);
  }

  getConstructor(): SerializableCtor<ImportDocumentsRequest_> {
    return ImportDocumentsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['documentTemplate', 'gcsSource', 'importGcsCustomMetadata'],
      objects: {
        'documentTemplate': ImportDocumentTemplate_,
        'gcsSource': GcsSources_
      }
    };
  }
}

export interface ImportDocumentsResponse_Parameters {
  warnings?: Array<GoogleRpcStatus>|null;
}
export class ImportDocumentsResponse_ extends Serializable {
  constructor(parameters: ImportDocumentsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'warnings',
        (parameters.warnings == null) ? (null) : (parameters.warnings));
  }

  get warnings(): Array<GoogleRpcStatus>|null {
    return (
        (this.Serializable$has('warnings')) ?
            (this.Serializable$get('warnings')) :
            (null));
  }

  /**
   * Includes details about skipped documents or any other warnings.
   */
  set warnings(value: Array<GoogleRpcStatus>|null) {
    this.Serializable$set('warnings', value);
  }

  getConstructor(): SerializableCtor<ImportDocumentsResponse_> {
    return ImportDocumentsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'warnings': GoogleRpcStatus}, keys: ['warnings']};
  }
}

export interface InitiateConversationMetadata_Parameters {}
export class InitiateConversationMetadata_ extends Serializable {
  constructor(parameters: InitiateConversationMetadata_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<InitiateConversationMetadata_> {
    return InitiateConversationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface InitiateConversationRequest_Parameters {
  recipientPhoneNumber?: string|null;
  connectionType?: InitiateConversationRequest_ConnectionType|null;
}
export class InitiateConversationRequest_ extends Serializable {
  constructor(parameters: InitiateConversationRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'recipientPhoneNumber',
        (parameters.recipientPhoneNumber == null) ?
            (null) :
            (parameters.recipientPhoneNumber));
    this.Serializable$set(
        'connectionType',
        (parameters.connectionType == null) ? (null) :
                                              (parameters.connectionType));
  }

  static get ConnectionType(): IInitiateConversationRequest_ConnectionTypeEnum {
    return InitiateConversationRequest_ConnectionTypeEnum;
  }

  get connectionType(): InitiateConversationRequest_ConnectionType|null {
    return (
        (this.Serializable$has('connectionType')) ?
            (this.Serializable$get('connectionType')) :
            (null));
  }

  /**
   * What type of conversation to establish.
   */
  set connectionType(value: InitiateConversationRequest_ConnectionType|null) {
    this.Serializable$set('connectionType', value);
  }

  get recipientPhoneNumber(): string|null {
    return (
        (this.Serializable$has('recipientPhoneNumber')) ?
            (this.Serializable$get('recipientPhoneNumber')) :
            (null));
  }

  /**
   * The target phone number to connect to for the conversation. Formatted in
   * [E.164](https://en.wikipedia.org/wiki/E.164). An example of a correctly
   * formatted phone number: +15556767888.
   */
  set recipientPhoneNumber(value: string|null) {
    this.Serializable$set('recipientPhoneNumber', value);
  }

  getConstructor(): SerializableCtor<InitiateConversationRequest_> {
    return InitiateConversationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'connectionType': InitiateConversationRequest_ConnectionTypeEnum},
      keys: ['connectionType', 'recipientPhoneNumber']
    };
  }
}

export interface InitiateConversationResponse_Parameters {
  conversation?: string|null;
}
export class InitiateConversationResponse_ extends Serializable {
  constructor(parameters: InitiateConversationResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversation',
        (parameters.conversation == null) ? (null) : (parameters.conversation));
  }

  get conversation(): string|null {
    return (
        (this.Serializable$has('conversation')) ?
            (this.Serializable$get('conversation')) :
            (null));
  }

  /**
   * Name of the conversation created.
   */
  set conversation(value: string|null) {
    this.Serializable$set('conversation', value);
  }

  getConstructor(): SerializableCtor<InitiateConversationResponse_> {
    return InitiateConversationResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversation']};
  }
}

export interface InjectCallCompanionUserInputRequest_Parameters {
  userInput?: CallCompanionUserInput_|null;
}
export class InjectCallCompanionUserInputRequest_ extends Serializable {
  constructor(parameters: InjectCallCompanionUserInputRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'userInput',
        (parameters.userInput == null) ? (null) : (parameters.userInput));
  }

  get userInput(): CallCompanionUserInput_|null {
    return (
        (this.Serializable$has('userInput')) ?
            (this.Serializable$get('userInput')) :
            (null));
  }

  /**
   * Required. User input to inject.
   */
  set userInput(value: CallCompanionUserInput_|null) {
    this.Serializable$set('userInput', value);
  }

  getConstructor(): SerializableCtor<InjectCallCompanionUserInputRequest_> {
    return InjectCallCompanionUserInputRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['userInput'],
      objects: {'userInput': CallCompanionUserInput_}
    };
  }
}

export interface InjectCallCompanionUserInputResponse_Parameters {}
export class InjectCallCompanionUserInputResponse_ extends Serializable {
  constructor(
      parameters: InjectCallCompanionUserInputResponse_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<InjectCallCompanionUserInputResponse_> {
    return InjectCallCompanionUserInputResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface InputAudioConfigAudioStreamInfo_Parameters {
  startTime?: string|null;
  detectionStartOffset?: string|null;
}
export class InputAudioConfigAudioStreamInfo_ extends Serializable {
  constructor(parameters: InputAudioConfigAudioStreamInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'startTime',
        (parameters.startTime == null) ? (null) : (parameters.startTime));
    this.Serializable$set(
        'detectionStartOffset',
        (parameters.detectionStartOffset == null) ?
            (null) :
            (parameters.detectionStartOffset));
  }

  get detectionStartOffset(): string|null {
    return (
        (this.Serializable$has('detectionStartOffset')) ?
            (this.Serializable$get('detectionStartOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio stream that corresponds
   * to the start of the speech detection.
   */
  set detectionStartOffset(value: string|null) {
    this.Serializable$set('detectionStartOffset', value);
  }

  get startTime(): string|null {
    return (
        (this.Serializable$has('startTime')) ?
            (this.Serializable$get('startTime')) :
            (null));
  }

  /**
   * Absolute start time of the stream. It can be gRPC stream or telephony
   * stream.
   */
  set startTime(value: string|null) {
    this.Serializable$set('startTime', value);
  }

  getConstructor(): SerializableCtor<InputAudioConfigAudioStreamInfo_> {
    return InputAudioConfigAudioStreamInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['detectionStartOffset', 'startTime']};
  }
}

export interface InputAudioConfig_Parameters {
  audioEncoding?: InputAudioConfig_AudioEncoding|null;
  sampleRateHertz?: number|null;
  languageCode?: string|null;
  enableWordInfo?: boolean|null;
  audioChannelCount?: number|null;
  phraseHints?: Array<string>|null;
  speechContexts?: Array<SpeechContext_>|null;
  model?: string|null;
  modelVariant?: InputAudioConfig_ModelVariant|null;
  singleUtterance?: boolean|null;
  disableNoSpeechRecognizedEvent?: boolean|null;
  bargeInConfig?: BargeInConfig_|null;
  enableSpeechLogging?: boolean|null;
  enableAutomaticPunctuation?: boolean|null;
  defaultNoSpeechTimeout?: string|null;
  alternativeLanguageCodes?: Array<string>|null;
  phraseSet?: Array<string>|null;
  audioStreamInfo?: InputAudioConfigAudioStreamInfo_|null;
}
export class InputAudioConfig_ extends Serializable {
  constructor(parameters: InputAudioConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'audioEncoding',
        (parameters.audioEncoding == null) ? (null) :
                                             (parameters.audioEncoding));
    this.Serializable$set(
        'sampleRateHertz',
        (parameters.sampleRateHertz == null) ? (null) :
                                               (parameters.sampleRateHertz));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'enableWordInfo',
        (parameters.enableWordInfo == null) ? (null) :
                                              (parameters.enableWordInfo));
    this.Serializable$set(
        'audioChannelCount',
        (parameters.audioChannelCount == null) ?
            (null) :
            (parameters.audioChannelCount));
    this.Serializable$set(
        'phraseHints',
        (parameters.phraseHints == null) ? (null) : (parameters.phraseHints));
    this.Serializable$set(
        'speechContexts',
        (parameters.speechContexts == null) ? (null) :
                                              (parameters.speechContexts));
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'modelVariant',
        (parameters.modelVariant == null) ? (null) : (parameters.modelVariant));
    this.Serializable$set(
        'singleUtterance',
        (parameters.singleUtterance == null) ? (null) :
                                               (parameters.singleUtterance));
    this.Serializable$set(
        'disableNoSpeechRecognizedEvent',
        (parameters.disableNoSpeechRecognizedEvent == null) ?
            (null) :
            (parameters.disableNoSpeechRecognizedEvent));
    this.Serializable$set(
        'bargeInConfig',
        (parameters.bargeInConfig == null) ? (null) :
                                             (parameters.bargeInConfig));
    this.Serializable$set(
        'enableSpeechLogging',
        (parameters.enableSpeechLogging == null) ?
            (null) :
            (parameters.enableSpeechLogging));
    this.Serializable$set(
        'enableAutomaticPunctuation',
        (parameters.enableAutomaticPunctuation == null) ?
            (null) :
            (parameters.enableAutomaticPunctuation));
    this.Serializable$set(
        'defaultNoSpeechTimeout',
        (parameters.defaultNoSpeechTimeout == null) ?
            (null) :
            (parameters.defaultNoSpeechTimeout));
    this.Serializable$set(
        'alternativeLanguageCodes',
        (parameters.alternativeLanguageCodes == null) ?
            (null) :
            (parameters.alternativeLanguageCodes));
    this.Serializable$set(
        'phraseSet',
        (parameters.phraseSet == null) ? (null) : (parameters.phraseSet));
    this.Serializable$set(
        'audioStreamInfo',
        (parameters.audioStreamInfo == null) ? (null) :
                                               (parameters.audioStreamInfo));
  }

  static get AudioEncoding(): IInputAudioConfig_AudioEncodingEnum {
    return InputAudioConfig_AudioEncodingEnum;
  }

  static get ModelVariant(): IInputAudioConfig_ModelVariantEnum {
    return InputAudioConfig_ModelVariantEnum;
  }

  get alternativeLanguageCodes(): Array<string>|null {
    return (
        (this.Serializable$has('alternativeLanguageCodes')) ?
            (this.Serializable$get('alternativeLanguageCodes')) :
            (null));
  }

  /**
   * A list of up to 3 additional
   * [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt) language tags,
   * listing possible alternative languages of the supplied audio. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes. If alternative languages
   * are listed, recognition result will contain recognition in the most likely
   * language detected including the main language_code. The recognition result
   * will include the language tag of the language detected in the audio. Extra
   * language codes will be ignored.
   */
  set alternativeLanguageCodes(value: Array<string>|null) {
    this.Serializable$set('alternativeLanguageCodes', value);
  }

  get audioChannelCount(): number|null {
    return (
        (this.Serializable$has('audioChannelCount')) ?
            (this.Serializable$get('audioChannelCount')) :
            (null));
  }

  /**
   * The number of channels in the input audio data. Valid values for LINEAR16
   * and FLAC are `1`-`8`. Valid values for MULAW, AMR and AMR_WB are only `1`.
   * If `0` or omitted, defaults to one channel (mono). NOTE: Currently, we
   * perform recognition only on channel 0 and ignore all other channels.
   */
  set audioChannelCount(value: number|null) {
    this.Serializable$set('audioChannelCount', value);
  }

  get audioEncoding(): InputAudioConfig_AudioEncoding|null {
    return (
        (this.Serializable$has('audioEncoding')) ?
            (this.Serializable$get('audioEncoding')) :
            (null));
  }

  /**
   * Required. Audio encoding of the audio content to process.
   */
  set audioEncoding(value: InputAudioConfig_AudioEncoding|null) {
    this.Serializable$set('audioEncoding', value);
  }

  get audioStreamInfo(): InputAudioConfigAudioStreamInfo_|null {
    return (
        (this.Serializable$has('audioStreamInfo')) ?
            (this.Serializable$get('audioStreamInfo')) :
            (null));
  }

  /**
   * Output only. Info on the audio stream.
   */
  set audioStreamInfo(value: InputAudioConfigAudioStreamInfo_|null) {
    this.Serializable$set('audioStreamInfo', value);
  }

  get bargeInConfig(): BargeInConfig_|null {
    return (
        (this.Serializable$has('bargeInConfig')) ?
            (this.Serializable$get('bargeInConfig')) :
            (null));
  }

  /**
   * Configuration of barge-in behavior during the streaming of input audio.
   */
  set bargeInConfig(value: BargeInConfig_|null) {
    this.Serializable$set('bargeInConfig', value);
  }

  get defaultNoSpeechTimeout(): string|null {
    return (
        (this.Serializable$has('defaultNoSpeechTimeout')) ?
            (this.Serializable$get('defaultNoSpeechTimeout')) :
            (null));
  }

  /**
   * If set, use this no-speech timeout when the agent does not provide a
   * no-speech timeout itself.
   */
  set defaultNoSpeechTimeout(value: string|null) {
    this.Serializable$set('defaultNoSpeechTimeout', value);
  }

  get disableNoSpeechRecognizedEvent(): boolean|null {
    return (
        (this.Serializable$has('disableNoSpeechRecognizedEvent')) ?
            (this.Serializable$get('disableNoSpeechRecognizedEvent')) :
            (null));
  }

  /**
   * Only used in Participants.AnalyzeContent and
   * Participants.StreamingAnalyzeContent. If `false` and recognition doesn't
   * return any result, trigger `NO_SPEECH_RECOGNIZED` event to Dialogflow
   * agent.
   */
  set disableNoSpeechRecognizedEvent(value: boolean|null) {
    this.Serializable$set('disableNoSpeechRecognizedEvent', value);
  }

  get enableAutomaticPunctuation(): boolean|null {
    return (
        (this.Serializable$has('enableAutomaticPunctuation')) ?
            (this.Serializable$get('enableAutomaticPunctuation')) :
            (null));
  }

  /**
   * Enable automatic punctuation option at the speech backend.
   */
  set enableAutomaticPunctuation(value: boolean|null) {
    this.Serializable$set('enableAutomaticPunctuation', value);
  }

  get enableSpeechLogging(): boolean|null {
    return (
        (this.Serializable$has('enableSpeechLogging')) ?
            (this.Serializable$get('enableSpeechLogging')) :
            (null));
  }

  /**
   * Enable logging at the speech backend.
   */
  set enableSpeechLogging(value: boolean|null) {
    this.Serializable$set('enableSpeechLogging', value);
  }

  get enableWordInfo(): boolean|null {
    return (
        (this.Serializable$has('enableWordInfo')) ?
            (this.Serializable$get('enableWordInfo')) :
            (null));
  }

  /**
   * If `true`, Dialogflow returns SpeechWordInfo in StreamingRecognitionResult
   * with information about the recognized speech words, e.g. start and end time
   * offsets. If false or unspecified, Speech doesn't return any word-level
   * information.
   */
  set enableWordInfo(value: boolean|null) {
    this.Serializable$set('enableWordInfo', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of the supplied audio. Dialogflow does not do
   * translations. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes. Note that queries in the
   * same session do not necessarily need to specify the same language.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Which Speech model to select for the given request. Select the model best
   * suited to your domain to get best results. If a model is not explicitly
   * specified, then we auto-select a model based on the parameters in the
   * InputAudioConfig. If enhanced speech model is enabled for the agent and an
   * enhanced version of the specified model for the language does not exist,
   * then the speech is recognized using the standard version of the specified
   * model. Refer to [Cloud Speech API
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#select-model)
   * for more details.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  get modelVariant(): InputAudioConfig_ModelVariant|null {
    return (
        (this.Serializable$has('modelVariant')) ?
            (this.Serializable$get('modelVariant')) :
            (null));
  }

  /**
   * Which variant of the Speech model to use.
   */
  set modelVariant(value: InputAudioConfig_ModelVariant|null) {
    this.Serializable$set('modelVariant', value);
  }

  get phraseHints(): Array<string>|null {
    return (
        (this.Serializable$has('phraseHints')) ?
            (this.Serializable$get('phraseHints')) :
            (null));
  }

  /**
   * A list of strings containing words and phrases that the speech recognizer
   * should recognize with higher likelihood. See [the Cloud Speech
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#phrase-hints)
   * for more details. This field is deprecated. Please use [speech_contexts]()
   * instead. If you specify both [phrase_hints]() and [speech_contexts](),
   * Dialogflow will treat the [phrase_hints]() as a single additional
   * [SpeechContext]().
   */
  set phraseHints(value: Array<string>|null) {
    this.Serializable$set('phraseHints', value);
  }

  get phraseSet(): Array<string>|null {
    return (
        (this.Serializable$has('phraseSet')) ?
            (this.Serializable$get('phraseSet')) :
            (null));
  }

  /**
   * A collection of phrase set resources to use for speech adaptation.
   */
  set phraseSet(value: Array<string>|null) {
    this.Serializable$set('phraseSet', value);
  }

  get sampleRateHertz(): number|null {
    return (
        (this.Serializable$has('sampleRateHertz')) ?
            (this.Serializable$get('sampleRateHertz')) :
            (null));
  }

  /**
   * Required. Sample rate (in Hertz) of the audio content sent in the query.
   * Refer to [Cloud Speech API
   * documentation](https://cloud.google.com/speech-to-text/docs/basics) for
   * more details.
   */
  set sampleRateHertz(value: number|null) {
    this.Serializable$set('sampleRateHertz', value);
  }

  get singleUtterance(): boolean|null {
    return (
        (this.Serializable$has('singleUtterance')) ?
            (this.Serializable$get('singleUtterance')) :
            (null));
  }

  /**
   * If `false` (default), recognition does not cease until the client closes
   * the stream. If `true`, the recognizer will detect a single spoken utterance
   * in input audio. Recognition ceases when it detects the audio's voice has
   * stopped or paused. In this case, once a detected intent is received, the
   * client should close the stream and start a new request with a new stream as
   * needed. Note: This setting is relevant only for streaming methods. Note:
   * When specified, InputAudioConfig.single_utterance takes precedence over
   * StreamingDetectIntentRequest.single_utterance.
   */
  set singleUtterance(value: boolean|null) {
    this.Serializable$set('singleUtterance', value);
  }

  get speechContexts(): Array<SpeechContext_>|null {
    return (
        (this.Serializable$has('speechContexts')) ?
            (this.Serializable$get('speechContexts')) :
            (null));
  }

  /**
   * Context information to assist speech recognition. See [the Cloud Speech
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#phrase-hints)
   * for more details.
   */
  set speechContexts(value: Array<SpeechContext_>|null) {
    this.Serializable$set('speechContexts', value);
  }

  getConstructor(): SerializableCtor<InputAudioConfig_> {
    return InputAudioConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'speechContexts': SpeechContext_},
      enums: {
        'audioEncoding': InputAudioConfig_AudioEncodingEnum,
        'modelVariant': InputAudioConfig_ModelVariantEnum
      },
      keys: [
        'alternativeLanguageCodes', 'audioChannelCount', 'audioEncoding',
        'audioStreamInfo', 'bargeInConfig', 'defaultNoSpeechTimeout',
        'disableNoSpeechRecognizedEvent', 'enableAutomaticPunctuation',
        'enableSpeechLogging', 'enableWordInfo', 'languageCode', 'model',
        'modelVariant', 'phraseHints', 'phraseSet', 'sampleRateHertz',
        'singleUtterance', 'speechContexts'
      ],
      objects: {
        'audioStreamInfo': InputAudioConfigAudioStreamInfo_,
        'bargeInConfig': BargeInConfig_
      }
    };
  }
}

export interface InputAudio_Parameters {
  config?: InputAudioConfig_|null;
  audio?: string|null;
}
export class InputAudio_ extends Serializable {
  constructor(parameters: InputAudio_Parameters = {}) {
    super();
    this.Serializable$set(
        'config', (parameters.config == null) ? (null) : (parameters.config));
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
  }

  get audio(): string|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * Required. The natural language speech audio to be processed. A single
   * request can contain up to 1 minute of speech audio data. The transcribed
   * text cannot contain more than 256 bytes.
   */
  set audio(value: string|null) {
    this.Serializable$set('audio', value);
  }

  get config(): InputAudioConfig_|null {
    return (
        (this.Serializable$has('config')) ? (this.Serializable$get('config')) :
                                            (null));
  }

  /**
   * Required. Instructs the speech recognizer how to process the speech audio.
   */
  set config(value: InputAudioConfig_|null) {
    this.Serializable$set('config', value);
  }

  getConstructor(): SerializableCtor<InputAudio_> {
    return InputAudio_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audio', 'config'], objects: {'config': InputAudioConfig_}};
  }
}

export interface InputConfig_Parameters {
  gcsSource?: GcsSources_|null;
  securitySettings?: string|null;
}
export class InputConfig_ extends Serializable {
  constructor(parameters: InputConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'gcsSource',
        (parameters.gcsSource == null) ? (null) : (parameters.gcsSource));
    this.Serializable$set(
        'securitySettings',
        (parameters.securitySettings == null) ? (null) :
                                                (parameters.securitySettings));
  }

  get gcsSource(): GcsSources_|null {
    return (
        (this.Serializable$has('gcsSource')) ?
            (this.Serializable$get('gcsSource')) :
            (null));
  }

  /**
   * The Cloud Storage URI has the form gs://my_bucket//agent*.json. Wildcards
   * are allowed and will be expanded into all matched files.
   */
  set gcsSource(value: GcsSources_|null) {
    this.Serializable$set('gcsSource', value);
  }

  get securitySettings(): string|null {
    return (
        (this.Serializable$has('securitySettings')) ?
            (this.Serializable$get('securitySettings')) :
            (null));
  }

  /**
   * Name of the CX SecuritySettings reference which contains the redaction
   * settings/template that will be used on this dataset. Note that changes in
   * security settings will only be applied on new data. Previously imported
   * data will not be affected. Format:
   * `projects//locations//securitySettings/`.
   */
  set securitySettings(value: string|null) {
    this.Serializable$set('securitySettings', value);
  }

  getConstructor(): SerializableCtor<InputConfig_> {
    return InputConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['gcsSource', 'securitySettings'],
      objects: {'gcsSource': GcsSources_}
    };
  }
}

export interface InputDataset_Parameters {
  dataset?: string|null;
  dataFilePattern?: string|null;
  filter?: string|null;
}
export class InputDataset_ extends Serializable {
  constructor(parameters: InputDataset_Parameters = {}) {
    super();
    this.Serializable$set(
        'dataset',
        (parameters.dataset == null) ? (null) : (parameters.dataset));
    this.Serializable$set(
        'dataFilePattern',
        (parameters.dataFilePattern == null) ? (null) :
                                               (parameters.dataFilePattern));
    this.Serializable$set(
        'filter', (parameters.filter == null) ? (null) : (parameters.filter));
  }

  get dataFilePattern(): string|null {
    return (
        (this.Serializable$has('dataFilePattern')) ?
            (this.Serializable$get('dataFilePattern')) :
            (null));
  }

  /**
   * Optional. File(s) that contain raw training data from internal file system.
   * Each file should be in cloud.ml.MLRecord recordio format, and contains
   * ml_data.conversation_data. Usage instruction, go/ccai-train-from-file.
   */
  set dataFilePattern(value: string|null) {
    this.Serializable$set('dataFilePattern', value);
  }

  get dataset(): string|null {
    return (
        (this.Serializable$has('dataset')) ?
            (this.Serializable$get('dataset')) :
            (null));
  }

  /**
   * Required. ConversationDataset resource name. Format:
   * `projects//conversationDatasets/` or
   * `projects//conversationDatasets//annotatedConversationDatasets/`
   */
  set dataset(value: string|null) {
    this.Serializable$set('dataset', value);
  }

  get filter(): string|null {
    return (
        (this.Serializable$has('filter')) ? (this.Serializable$get('filter')) :
                                            (null));
  }

  /**
   * Optional. Filter on annotations under the dataset when annotations are
   * needed for training. If unspecified, use all annotations under the
   * specified dataset. Currently predicts on
   * `annotated_conversation_dataset_id`. For example:
   * `annotated_conversation_dataset_id = [annotated conversation dataset id]`
   * For more information about filtering, see [API
   * Filtering](https://aip.dev/160).
   */
  set filter(value: string|null) {
    this.Serializable$set('filter', value);
  }

  getConstructor(): SerializableCtor<InputDataset_> {
    return InputDataset_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['dataFilePattern', 'dataset', 'filter']};
  }
}

export interface InputDatasets_Parameters {
  dataset?: Array<string>|null;
}
export class InputDatasets_ extends Serializable {
  constructor(parameters: InputDatasets_Parameters = {}) {
    super();
    this.Serializable$set(
        'dataset',
        (parameters.dataset == null) ? (null) : (parameters.dataset));
  }

  get dataset(): Array<string>|null {
    return (
        (this.Serializable$has('dataset')) ?
            (this.Serializable$get('dataset')) :
            (null));
  }

  /**
   * Required. ConversationDataset resource name. Format:
   * `projects//locations//conversationDatasets/`
   */
  set dataset(value: Array<string>|null) {
    this.Serializable$set('dataset', value);
  }

  getConstructor(): SerializableCtor<InputDatasets_> {
    return InputDatasets_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['dataset']};
  }
}

export interface InputText_Parameters {
  text?: string|null;
  languageCode?: string|null;
}
export class InputText_ extends Serializable {
  constructor(parameters: InputText_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of this conversational query. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. The UTF-8 encoded natural language text to be processed. Text
   * length must not exceed 256 bytes.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<InputText_> {
    return InputText_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['languageCode', 'text']};
  }
}

export interface IntegrationAogPayloadDefinedStartIntent_Parameters {
  intentId?: string|null;
  signin?: boolean|null;
}
export class IntegrationAogPayloadDefinedStartIntent_ extends Serializable {
  constructor(
      parameters: IntegrationAogPayloadDefinedStartIntent_Parameters = {}) {
    super();
    this.Serializable$set(
        'intentId',
        (parameters.intentId == null) ? (null) : (parameters.intentId));
    this.Serializable$set(
        'signin', (parameters.signin == null) ? (null) : (parameters.signin));
  }

  get intentId(): string|null {
    return (
        (this.Serializable$has('intentId')) ?
            (this.Serializable$get('intentId')) :
            (null));
  }

  /**
   * Intent ID that should be defined as implicit trigger.
   */
  set intentId(value: string|null) {
    this.Serializable$set('intentId', value);
  }

  get signin(): boolean|null {
    return (
        (this.Serializable$has('signin')) ? (this.Serializable$get('signin')) :
                                            (null));
  }

  /**
   * Same as welcome_intent_signin but for implicit triggers.
   */
  set signin(value: boolean|null) {
    this.Serializable$set('signin', value);
  }

  getConstructor(): SerializableCtor<IntegrationAogPayloadDefinedStartIntent_> {
    return IntegrationAogPayloadDefinedStartIntent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intentId', 'signin']};
  }
}

export interface IntegrationAogPayload_Parameters {
  welcomeIntentSignin?: boolean|null;
  startIntents?: Array<IntegrationAogPayloadDefinedStartIntent_>|null;
  autoPreviewEnabled?: boolean|null;
  deviceAgent?: boolean|null;
}
export class IntegrationAogPayload_ extends Serializable {
  constructor(parameters: IntegrationAogPayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'welcomeIntentSignin',
        (parameters.welcomeIntentSignin == null) ?
            (null) :
            (parameters.welcomeIntentSignin));
    this.Serializable$set(
        'startIntents',
        (parameters.startIntents == null) ? (null) : (parameters.startIntents));
    this.Serializable$set(
        'autoPreviewEnabled',
        (parameters.autoPreviewEnabled == null) ?
            (null) :
            (parameters.autoPreviewEnabled));
    this.Serializable$set(
        'deviceAgent',
        (parameters.deviceAgent == null) ? (null) : (parameters.deviceAgent));
  }

  get autoPreviewEnabled(): boolean|null {
    return (
        (this.Serializable$has('autoPreviewEnabled')) ?
            (this.Serializable$get('autoPreviewEnabled')) :
            (null));
  }

  /**
   * Flag which tells DF console UI to send AP preview on every agent save.
   */
  set autoPreviewEnabled(value: boolean|null) {
    this.Serializable$set('autoPreviewEnabled', value);
  }

  get deviceAgent(): boolean|null {
    return (
        (this.Serializable$has('deviceAgent')) ?
            (this.Serializable$get('deviceAgent')) :
            (null));
  }

  /**
   * Flag which marks this agent as a 'Device Agent' (AoG feature).
   */
  set deviceAgent(value: boolean|null) {
    this.Serializable$set('deviceAgent', value);
  }

  get startIntents(): Array<IntegrationAogPayloadDefinedStartIntent_>|null {
    return (
        (this.Serializable$has('startIntents')) ?
            (this.Serializable$get('startIntents')) :
            (null));
  }

  /**
   * List of implicit trigger intents, add to top level Actions in AP.
   */
  set startIntents(value: Array<IntegrationAogPayloadDefinedStartIntent_>|
                   null) {
    this.Serializable$set('startIntents', value);
  }

  get welcomeIntentSignin(): boolean|null {
    return (
        (this.Serializable$has('welcomeIntentSignin')) ?
            (this.Serializable$get('welcomeIntentSignin')) :
            (null));
  }

  /**
   * When true, sets \"sign-in\" to true in welcome intent when creating action
   * package.
   */
  set welcomeIntentSignin(value: boolean|null) {
    this.Serializable$set('welcomeIntentSignin', value);
  }

  getConstructor(): SerializableCtor<IntegrationAogPayload_> {
    return IntegrationAogPayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'startIntents': IntegrationAogPayloadDefinedStartIntent_},
      keys: [
        'autoPreviewEnabled', 'deviceAgent', 'startIntents',
        'welcomeIntentSignin'
      ]
    };
  }
}

export interface IntegrationDialogflowMessengerPayload_Parameters {}
export class IntegrationDialogflowMessengerPayload_ extends Serializable {
  constructor(
      parameters: IntegrationDialogflowMessengerPayload_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<IntegrationDialogflowMessengerPayload_> {
    return IntegrationDialogflowMessengerPayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface IntegrationFacebookPayload_Parameters {
  pageAccessToken?: string|null;
  verifyToken?: string|null;
  hasWelcomeEvent?: boolean|null;
}
export class IntegrationFacebookPayload_ extends Serializable {
  constructor(parameters: IntegrationFacebookPayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'pageAccessToken',
        (parameters.pageAccessToken == null) ? (null) :
                                               (parameters.pageAccessToken));
    this.Serializable$set(
        'verifyToken',
        (parameters.verifyToken == null) ? (null) : (parameters.verifyToken));
    this.Serializable$set(
        'hasWelcomeEvent',
        (parameters.hasWelcomeEvent == null) ? (null) :
                                               (parameters.hasWelcomeEvent));
  }

  get hasWelcomeEvent(): boolean|null {
    return (
        (this.Serializable$has('hasWelcomeEvent')) ?
            (this.Serializable$get('hasWelcomeEvent')) :
            (null));
  }

  /**
   * Indicates if Facebook integration has welcome intent or not.
   */
  set hasWelcomeEvent(value: boolean|null) {
    this.Serializable$set('hasWelcomeEvent', value);
  }

  get pageAccessToken(): string|null {
    return (
        (this.Serializable$has('pageAccessToken')) ?
            (this.Serializable$get('pageAccessToken')) :
            (null));
  }

  /**
   * Page access token for Facebook messenger bot.
   */
  set pageAccessToken(value: string|null) {
    this.Serializable$set('pageAccessToken', value);
  }

  get verifyToken(): string|null {
    return (
        (this.Serializable$has('verifyToken')) ?
            (this.Serializable$get('verifyToken')) :
            (null));
  }

  /**
   * Verify token for Facebook messenger bot.
   */
  set verifyToken(value: string|null) {
    this.Serializable$set('verifyToken', value);
  }

  getConstructor(): SerializableCtor<IntegrationFacebookPayload_> {
    return IntegrationFacebookPayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['hasWelcomeEvent', 'pageAccessToken', 'verifyToken']};
  }
}

export interface IntegrationHangoutsPayload_Parameters {
  displayName?: string|null;
  avatarUri?: string|null;
  developerName?: string|null;
  description?: string|null;
  isVisibleToOwnDomain?: boolean|null;
  visibleEmails?: Array<string>|null;
}
export class IntegrationHangoutsPayload_ extends Serializable {
  constructor(parameters: IntegrationHangoutsPayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'avatarUri',
        (parameters.avatarUri == null) ? (null) : (parameters.avatarUri));
    this.Serializable$set(
        'developerName',
        (parameters.developerName == null) ? (null) :
                                             (parameters.developerName));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'isVisibleToOwnDomain',
        (parameters.isVisibleToOwnDomain == null) ?
            (null) :
            (parameters.isVisibleToOwnDomain));
    this.Serializable$set(
        'visibleEmails',
        (parameters.visibleEmails == null) ? (null) :
                                             (parameters.visibleEmails));
  }

  get avatarUri(): string|null {
    return (
        (this.Serializable$has('avatarUri')) ?
            (this.Serializable$get('avatarUri')) :
            (null));
  }

  /**
   * Display Avatar uri.
   */
  set avatarUri(value: string|null) {
    this.Serializable$set('avatarUri', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Short description of bot capabilities.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get developerName(): string|null {
    return (
        (this.Serializable$has('developerName')) ?
            (this.Serializable$get('developerName')) :
            (null));
  }

  /**
   * Developer name that will be shown in autocomplete.
   */
  set developerName(value: string|null) {
    this.Serializable$set('developerName', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Display name.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get isVisibleToOwnDomain(): boolean|null {
    return (
        (this.Serializable$has('isVisibleToOwnDomain')) ?
            (this.Serializable$get('isVisibleToOwnDomain')) :
            (null));
  }

  /**
   * Indicates if bot visible to everyone in developer's domain.
   */
  set isVisibleToOwnDomain(value: boolean|null) {
    this.Serializable$set('isVisibleToOwnDomain', value);
  }

  get visibleEmails(): Array<string>|null {
    return (
        (this.Serializable$has('visibleEmails')) ?
            (this.Serializable$get('visibleEmails')) :
            (null));
  }

  /**
   * The collection of emails that bot will be visible to.
   */
  set visibleEmails(value: Array<string>|null) {
    this.Serializable$set('visibleEmails', value);
  }

  getConstructor(): SerializableCtor<IntegrationHangoutsPayload_> {
    return IntegrationHangoutsPayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'avatarUri', 'description', 'developerName', 'displayName',
        'isVisibleToOwnDomain', 'visibleEmails'
      ]
    };
  }
}

export interface IntegrationLinePayload_Parameters {
  channelSecret?: string|null;
  channelId?: string|null;
  channelAccessToken?: string|null;
}
export class IntegrationLinePayload_ extends Serializable {
  constructor(parameters: IntegrationLinePayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'channelSecret',
        (parameters.channelSecret == null) ? (null) :
                                             (parameters.channelSecret));
    this.Serializable$set(
        'channelId',
        (parameters.channelId == null) ? (null) : (parameters.channelId));
    this.Serializable$set(
        'channelAccessToken',
        (parameters.channelAccessToken == null) ?
            (null) :
            (parameters.channelAccessToken));
  }

  get channelAccessToken(): string|null {
    return (
        (this.Serializable$has('channelAccessToken')) ?
            (this.Serializable$get('channelAccessToken')) :
            (null));
  }

  /**
   * Channel access token.
   * https://developers.line.biz/en/docs/messaging-api/building-bot/
   */
  set channelAccessToken(value: string|null) {
    this.Serializable$set('channelAccessToken', value);
  }

  get channelId(): string|null {
    return (
        (this.Serializable$has('channelId')) ?
            (this.Serializable$get('channelId')) :
            (null));
  }

  /**
   * Channel ID. https://developers.line.biz/en/docs/messaging-api/building-bot/
   */
  set channelId(value: string|null) {
    this.Serializable$set('channelId', value);
  }

  get channelSecret(): string|null {
    return (
        (this.Serializable$has('channelSecret')) ?
            (this.Serializable$get('channelSecret')) :
            (null));
  }

  /**
   * Channel secret.
   * https://developers.line.biz/en/docs/messaging-api/building-bot/
   */
  set channelSecret(value: string|null) {
    this.Serializable$set('channelSecret', value);
  }

  getConstructor(): SerializableCtor<IntegrationLinePayload_> {
    return IntegrationLinePayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['channelAccessToken', 'channelId', 'channelSecret']};
  }
}

export interface IntegrationOneClickIntegrationPayload_Parameters {
  partner?: IntegrationOneClickIntegrationPayload_Partner|null;
  token?: string|null;
  setupNonce?: string|null;
  conversationProfile?: string|null;
  displayName?: string|null;
}
export class IntegrationOneClickIntegrationPayload_ extends Serializable {
  constructor(
      parameters: IntegrationOneClickIntegrationPayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'partner',
        (parameters.partner == null) ? (null) : (parameters.partner));
    this.Serializable$set(
        'token', (parameters.token == null) ? (null) : (parameters.token));
    this.Serializable$set(
        'setupNonce',
        (parameters.setupNonce == null) ? (null) : (parameters.setupNonce));
    this.Serializable$set(
        'conversationProfile',
        (parameters.conversationProfile == null) ?
            (null) :
            (parameters.conversationProfile));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
  }

  static get Partner(): IIntegrationOneClickIntegrationPayload_PartnerEnum {
    return IntegrationOneClickIntegrationPayload_PartnerEnum;
  }

  get conversationProfile(): string|null {
    return (
        (this.Serializable$has('conversationProfile')) ?
            (this.Serializable$get('conversationProfile')) :
            (null));
  }

  /**
   * Output only. Name of the conversation profile used by this integration.
   */
  set conversationProfile(value: string|null) {
    this.Serializable$set('conversationProfile', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Optional. User specified display name for this integration
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get partner(): IntegrationOneClickIntegrationPayload_Partner|null {
    return (
        (this.Serializable$has('partner')) ?
            (this.Serializable$get('partner')) :
            (null));
  }

  /**
   * Output only. The partner to which this integration is bound.
   */
  set partner(value: IntegrationOneClickIntegrationPayload_Partner|null) {
    this.Serializable$set('partner', value);
  }

  get setupNonce(): string|null {
    return (
        (this.Serializable$has('setupNonce')) ?
            (this.Serializable$get('setupNonce')) :
            (null));
  }

  /**
   * Output only. The one-time secure nonce that is used in an OAuth or
   * OAuth-like flow.
   */
  set setupNonce(value: string|null) {
    this.Serializable$set('setupNonce', value);
  }

  get token(): string|null {
    return (
        (this.Serializable$has('token')) ? (this.Serializable$get('token')) :
                                           (null));
  }

  /**
   * Output only. The token issued by the partner to represent the link.
   */
  set token(value: string|null) {
    this.Serializable$set('token', value);
  }

  getConstructor(): SerializableCtor<IntegrationOneClickIntegrationPayload_> {
    return IntegrationOneClickIntegrationPayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'partner': IntegrationOneClickIntegrationPayload_PartnerEnum},
      keys: [
        'conversationProfile', 'displayName', 'partner', 'setupNonce', 'token'
      ]
    };
  }
}

export interface IntegrationSlackPayload_Parameters {
  clientId?: string|null;
  clientSecret?: string|null;
  verificationToken?: string|null;
  ambient?: boolean|null;
  successPage?: string|null;
  signedSecret?: string|null;
}
export class IntegrationSlackPayload_ extends Serializable {
  constructor(parameters: IntegrationSlackPayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'clientId',
        (parameters.clientId == null) ? (null) : (parameters.clientId));
    this.Serializable$set(
        'clientSecret',
        (parameters.clientSecret == null) ? (null) : (parameters.clientSecret));
    this.Serializable$set(
        'verificationToken',
        (parameters.verificationToken == null) ?
            (null) :
            (parameters.verificationToken));
    this.Serializable$set(
        'ambient',
        (parameters.ambient == null) ? (null) : (parameters.ambient));
    this.Serializable$set(
        'successPage',
        (parameters.successPage == null) ? (null) : (parameters.successPage));
    this.Serializable$set(
        'signedSecret',
        (parameters.signedSecret == null) ? (null) : (parameters.signedSecret));
  }

  get ambient(): boolean|null {
    return (
        (this.Serializable$has('ambient')) ?
            (this.Serializable$get('ambient')) :
            (null));
  }

  /**
   * When true, bot will try to answer to all messages in group chat.
   */
  set ambient(value: boolean|null) {
    this.Serializable$set('ambient', value);
  }

  get clientId(): string|null {
    return (
        (this.Serializable$has('clientId')) ?
            (this.Serializable$get('clientId')) :
            (null));
  }

  /**
   * The client ID issued when you created your app on Slack. See
   * https://api.slack.com/docs/oauth#bots.
   */
  set clientId(value: string|null) {
    this.Serializable$set('clientId', value);
  }

  get clientSecret(): string|null {
    return (
        (this.Serializable$has('clientSecret')) ?
            (this.Serializable$get('clientSecret')) :
            (null));
  }

  /**
   * The client secret issued when you created your app on Slack. See
   * https://api.slack.com/docs/oauth#bots.
   */
  set clientSecret(value: string|null) {
    this.Serializable$set('clientSecret', value);
  }

  get signedSecret(): string|null {
    return (
        (this.Serializable$has('signedSecret')) ?
            (this.Serializable$get('signedSecret')) :
            (null));
  }

  /**
   * The key used to verify Slack requests. See
   * https://api.slack.com/authentication/verifying-requests-from-slack.
   */
  set signedSecret(value: string|null) {
    this.Serializable$set('signedSecret', value);
  }

  get successPage(): string|null {
    return (
        (this.Serializable$has('successPage')) ?
            (this.Serializable$get('successPage')) :
            (null));
  }

  /**
   * The URL to redirect back to when setting up OAuth.
   */
  set successPage(value: string|null) {
    this.Serializable$set('successPage', value);
  }

  get verificationToken(): string|null {
    return (
        (this.Serializable$has('verificationToken')) ?
            (this.Serializable$get('verificationToken')) :
            (null));
  }

  /**
   * The verification token which is used to verify that the request is coming
   * from Slack on behalf of you application. See
   * https://api.slack.com/events-api#request_url_configuration__amp__verification.
   */
  set verificationToken(value: string|null) {
    this.Serializable$set('verificationToken', value);
  }

  getConstructor(): SerializableCtor<IntegrationSlackPayload_> {
    return IntegrationSlackPayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'ambient', 'clientId', 'clientSecret', 'signedSecret', 'successPage',
        'verificationToken'
      ]
    };
  }
}

export interface IntegrationSlackTestPayload_Parameters {
  code?: string|null;
  redirectUri?: string|null;
}
export class IntegrationSlackTestPayload_ extends Serializable {
  constructor(parameters: IntegrationSlackTestPayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'code', (parameters.code == null) ? (null) : (parameters.code));
    this.Serializable$set(
        'redirectUri',
        (parameters.redirectUri == null) ? (null) : (parameters.redirectUri));
  }

  get code(): string|null {
    return (
        (this.Serializable$has('code')) ? (this.Serializable$get('code')) :
                                          (null));
  }

  /**
   * A temporary authorization code in a code to fetch Slack access token. See
   * \"Step 2- Users are redirected to your server with a verification code\"
   * at: https://api.slack.com/docs/oauth#bots
   */
  set code(value: string|null) {
    this.Serializable$set('code', value);
  }

  get redirectUri(): string|null {
    return (
        (this.Serializable$has('redirectUri')) ?
            (this.Serializable$get('redirectUri')) :
            (null));
  }

  /**
   * Slack will redirect back to your specified redirect_uri after Slack
   * authorization step.
   */
  set redirectUri(value: string|null) {
    this.Serializable$set('redirectUri', value);
  }

  getConstructor(): SerializableCtor<IntegrationSlackTestPayload_> {
    return IntegrationSlackTestPayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['code', 'redirectUri']};
  }
}

export interface IntegrationTelegramPayload_Parameters {
  token?: string|null;
}
export class IntegrationTelegramPayload_ extends Serializable {
  constructor(parameters: IntegrationTelegramPayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'token', (parameters.token == null) ? (null) : (parameters.token));
  }

  get token(): string|null {
    return (
        (this.Serializable$has('token')) ? (this.Serializable$get('token')) :
                                           (null));
  }

  /**
   * Required. Telegram API access token.
   */
  set token(value: string|null) {
    this.Serializable$set('token', value);
  }

  getConstructor(): SerializableCtor<IntegrationTelegramPayload_> {
    return IntegrationTelegramPayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['token']};
  }
}

export interface IntegrationWorkplacePayload_Parameters {
  hasWelcomeEvent?: boolean|null;
}
export class IntegrationWorkplacePayload_ extends Serializable {
  constructor(parameters: IntegrationWorkplacePayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'hasWelcomeEvent',
        (parameters.hasWelcomeEvent == null) ? (null) :
                                               (parameters.hasWelcomeEvent));
  }

  get hasWelcomeEvent(): boolean|null {
    return (
        (this.Serializable$has('hasWelcomeEvent')) ?
            (this.Serializable$get('hasWelcomeEvent')) :
            (null));
  }

  /**
   * Indicates if Workplace integration has welcome intent or not. Currently
   * Workplace doesn't support welcome event, so this field is NOT being used.
   */
  set hasWelcomeEvent(value: boolean|null) {
    this.Serializable$set('hasWelcomeEvent', value);
  }

  getConstructor(): SerializableCtor<IntegrationWorkplacePayload_> {
    return IntegrationWorkplacePayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['hasWelcomeEvent']};
  }
}

export interface Integration_Parameters {
  name?: string|null;
  state?: Integration_State|null;
  languageCode?: string|null;
  environment?: string|null;
  webhookUri?: string|null;
  dialogflowMessengerPayload?: IntegrationDialogflowMessengerPayload_|null;
  facebookPayload?: IntegrationFacebookPayload_|null;
  hangoutsPayload?: IntegrationHangoutsPayload_|null;
  linePayload?: IntegrationLinePayload_|null;
  slackPayload?: IntegrationSlackPayload_|null;
  slackTestPayload?: IntegrationSlackTestPayload_|null;
  telegramPayload?: IntegrationTelegramPayload_|null;
  oneClickIntegrationPayload?: IntegrationOneClickIntegrationPayload_|null;
  workplacePayload?: IntegrationWorkplacePayload_|null;
  aogPayload?: IntegrationAogPayload_|null;
}
export class Integration_ extends Serializable {
  constructor(parameters: Integration_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
    this.Serializable$set(
        'webhookUri',
        (parameters.webhookUri == null) ? (null) : (parameters.webhookUri));
    this.Serializable$set(
        'dialogflowMessengerPayload',
        (parameters.dialogflowMessengerPayload == null) ?
            (null) :
            (parameters.dialogflowMessengerPayload));
    this.Serializable$set(
        'facebookPayload',
        (parameters.facebookPayload == null) ? (null) :
                                               (parameters.facebookPayload));
    this.Serializable$set(
        'hangoutsPayload',
        (parameters.hangoutsPayload == null) ? (null) :
                                               (parameters.hangoutsPayload));
    this.Serializable$set(
        'linePayload',
        (parameters.linePayload == null) ? (null) : (parameters.linePayload));
    this.Serializable$set(
        'slackPayload',
        (parameters.slackPayload == null) ? (null) : (parameters.slackPayload));
    this.Serializable$set(
        'slackTestPayload',
        (parameters.slackTestPayload == null) ? (null) :
                                                (parameters.slackTestPayload));
    this.Serializable$set(
        'telegramPayload',
        (parameters.telegramPayload == null) ? (null) :
                                               (parameters.telegramPayload));
    this.Serializable$set(
        'oneClickIntegrationPayload',
        (parameters.oneClickIntegrationPayload == null) ?
            (null) :
            (parameters.oneClickIntegrationPayload));
    this.Serializable$set(
        'workplacePayload',
        (parameters.workplacePayload == null) ? (null) :
                                                (parameters.workplacePayload));
    this.Serializable$set(
        'aogPayload',
        (parameters.aogPayload == null) ? (null) : (parameters.aogPayload));
  }

  static get State(): IIntegration_StateEnum {
    return Integration_StateEnum;
  }

  get aogPayload(): IntegrationAogPayload_|null {
    return (
        (this.Serializable$has('aogPayload')) ?
            (this.Serializable$get('aogPayload')) :
            (null));
  }

  /**
   * Optional. The payload for the AoG integration.
   */
  set aogPayload(value: IntegrationAogPayload_|null) {
    this.Serializable$set('aogPayload', value);
  }

  get dialogflowMessengerPayload(): IntegrationDialogflowMessengerPayload_
      |null {
    return (
        (this.Serializable$has('dialogflowMessengerPayload')) ?
            (this.Serializable$get('dialogflowMessengerPayload')) :
            (null));
  }

  /**
   * Optional. The payload for the Dialogflow messenger integration.
   */
  set dialogflowMessengerPayload(value: IntegrationDialogflowMessengerPayload_|
                                 null) {
    this.Serializable$set('dialogflowMessengerPayload', value);
  }

  get environment(): string|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * Optional. The name of the Dialogflow environment to serve integration from.
   * If not specified, 'draft' environment is used. Format: -
   * `projects//agent/environments/` -
   * `projects//locations//agent/environments/`
   */
  set environment(value: string|null) {
    this.Serializable$set('environment', value);
  }

  get facebookPayload(): IntegrationFacebookPayload_|null {
    return (
        (this.Serializable$has('facebookPayload')) ?
            (this.Serializable$get('facebookPayload')) :
            (null));
  }

  /**
   * Optional. The payload for the Facebook integration.
   */
  set facebookPayload(value: IntegrationFacebookPayload_|null) {
    this.Serializable$set('facebookPayload', value);
  }

  get hangoutsPayload(): IntegrationHangoutsPayload_|null {
    return (
        (this.Serializable$has('hangoutsPayload')) ?
            (this.Serializable$get('hangoutsPayload')) :
            (null));
  }

  /**
   * Optional. The payload for the Hangouts integration.
   */
  set hangoutsPayload(value: IntegrationHangoutsPayload_|null) {
    this.Serializable$set('hangoutsPayload', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The language to serve integration with. If not specified, the
   * agent's default language is used. [Many
   * languages](https://cloud.google.com/dialogflow/docs/reference/language) are
   * supported. Note: languages must be enabled in the agent before they can be
   * used.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get linePayload(): IntegrationLinePayload_|null {
    return (
        (this.Serializable$has('linePayload')) ?
            (this.Serializable$get('linePayload')) :
            (null));
  }

  /**
   * Optional. The payload for the Line integration.
   */
  set linePayload(value: IntegrationLinePayload_|null) {
    this.Serializable$set('linePayload', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. Integration name. Format:
   * `projects//locations//agent/integrations/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get oneClickIntegrationPayload(): IntegrationOneClickIntegrationPayload_
      |null {
    return (
        (this.Serializable$has('oneClickIntegrationPayload')) ?
            (this.Serializable$get('oneClickIntegrationPayload')) :
            (null));
  }

  /**
   * Optional. The payload for one-click integrations.
   */
  set oneClickIntegrationPayload(value: IntegrationOneClickIntegrationPayload_|
                                 null) {
    this.Serializable$set('oneClickIntegrationPayload', value);
  }

  get slackPayload(): IntegrationSlackPayload_|null {
    return (
        (this.Serializable$has('slackPayload')) ?
            (this.Serializable$get('slackPayload')) :
            (null));
  }

  /**
   * Optional. The payload for the Slack integration.
   */
  set slackPayload(value: IntegrationSlackPayload_|null) {
    this.Serializable$set('slackPayload', value);
  }

  get slackTestPayload(): IntegrationSlackTestPayload_|null {
    return (
        (this.Serializable$has('slackTestPayload')) ?
            (this.Serializable$get('slackTestPayload')) :
            (null));
  }

  /**
   * Optional. The payload for the Slack test bot integration.
   */
  set slackTestPayload(value: IntegrationSlackTestPayload_|null) {
    this.Serializable$set('slackTestPayload', value);
  }

  get state(): Integration_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Output only. Specifies status of the integration.
   */
  set state(value: Integration_State|null) {
    this.Serializable$set('state', value);
  }

  get telegramPayload(): IntegrationTelegramPayload_|null {
    return (
        (this.Serializable$has('telegramPayload')) ?
            (this.Serializable$get('telegramPayload')) :
            (null));
  }

  /**
   * Optional. The payload for the Telegram integration.
   */
  set telegramPayload(value: IntegrationTelegramPayload_|null) {
    this.Serializable$set('telegramPayload', value);
  }

  get webhookUri(): string|null {
    return (
        (this.Serializable$has('webhookUri')) ?
            (this.Serializable$get('webhookUri')) :
            (null));
  }

  /**
   * Optional. Webhook uri provided by integrated third-party platform.
   */
  set webhookUri(value: string|null) {
    this.Serializable$set('webhookUri', value);
  }

  get workplacePayload(): IntegrationWorkplacePayload_|null {
    return (
        (this.Serializable$has('workplacePayload')) ?
            (this.Serializable$get('workplacePayload')) :
            (null));
  }

  /**
   * Optional. The payload for the Workplace integration.
   */
  set workplacePayload(value: IntegrationWorkplacePayload_|null) {
    this.Serializable$set('workplacePayload', value);
  }

  getConstructor(): SerializableCtor<Integration_> {
    return Integration_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': Integration_StateEnum},
      keys: [
        'aogPayload', 'dialogflowMessengerPayload', 'environment',
        'facebookPayload', 'hangoutsPayload', 'languageCode', 'linePayload',
        'name', 'oneClickIntegrationPayload', 'slackPayload',
        'slackTestPayload', 'state', 'telegramPayload', 'webhookUri',
        'workplacePayload'
      ],
      objects: {
        'aogPayload': IntegrationAogPayload_,
        'dialogflowMessengerPayload': IntegrationDialogflowMessengerPayload_,
        'facebookPayload': IntegrationFacebookPayload_,
        'hangoutsPayload': IntegrationHangoutsPayload_,
        'linePayload': IntegrationLinePayload_,
        'oneClickIntegrationPayload': IntegrationOneClickIntegrationPayload_,
        'slackPayload': IntegrationSlackPayload_,
        'slackTestPayload': IntegrationSlackTestPayload_,
        'telegramPayload': IntegrationTelegramPayload_,
        'workplacePayload': IntegrationWorkplacePayload_
      }
    };
  }
}

export interface IntentBatchSerialized_Parameters {
  intentsSerialized?: string|null;
  encoding?: string|null;
}
export class IntentBatchSerialized_ extends Serializable {
  constructor(parameters: IntentBatchSerialized_Parameters = {}) {
    super();
    this.Serializable$set(
        'intentsSerialized',
        (parameters.intentsSerialized == null) ?
            (null) :
            (parameters.intentsSerialized));
    this.Serializable$set(
        'encoding',
        (parameters.encoding == null) ? (null) : (parameters.encoding));
  }

  get encoding(): string|null {
    return (
        (this.Serializable$has('encoding')) ?
            (this.Serializable$get('encoding')) :
            (null));
  }

  /**
   * Optional. If in JSON format, character encoding of the serialized intents
   * in bytes.
   */
  set encoding(value: string|null) {
    this.Serializable$set('encoding', value);
  }

  get intentsSerialized(): string|null {
    return (
        (this.Serializable$has('intentsSerialized')) ?
            (this.Serializable$get('intentsSerialized')) :
            (null));
  }

  /**
   * Serialized intents in bytes.
   */
  set intentsSerialized(value: string|null) {
    this.Serializable$set('intentsSerialized', value);
  }

  getConstructor(): SerializableCtor<IntentBatchSerialized_> {
    return IntentBatchSerialized_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['encoding', 'intentsSerialized']};
  }
}

export interface IntentBatch_Parameters {
  intents?: Array<Intent_>|null;
}
export class IntentBatch_ extends Serializable {
  constructor(parameters: IntentBatch_Parameters = {}) {
    super();
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
  }

  get intents(): Array<Intent_>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * A collection of intents.
   */
  set intents(value: Array<Intent_>|null) {
    this.Serializable$set('intents', value);
  }

  getConstructor(): SerializableCtor<IntentBatch_> {
    return IntentBatch_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'intents': Intent_}, keys: ['intents']};
  }
}

export interface IntentConditionalFollowupEventInput_Parameters {
  condition?: IntentConditionalFollowupEventInput_Condition|null;
  eventInput?: IntentFollowupEventInput_|null;
}
export class IntentConditionalFollowupEventInput_ extends Serializable {
  constructor(parameters: IntentConditionalFollowupEventInput_Parameters = {}) {
    super();
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'eventInput',
        (parameters.eventInput == null) ? (null) : (parameters.eventInput));
  }

  static get Condition(): IIntentConditionalFollowupEventInput_ConditionEnum {
    return IntentConditionalFollowupEventInput_ConditionEnum;
  }

  get condition(): IntentConditionalFollowupEventInput_Condition|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * The condition that activates the event input.
   */
  set condition(value: IntentConditionalFollowupEventInput_Condition|null) {
    this.Serializable$set('condition', value);
  }

  get eventInput(): IntentFollowupEventInput_|null {
    return (
        (this.Serializable$has('eventInput')) ?
            (this.Serializable$get('eventInput')) :
            (null));
  }

  /**
   * The event input to trigger if the condition is activated.
   */
  set eventInput(value: IntentFollowupEventInput_|null) {
    this.Serializable$set('eventInput', value);
  }

  getConstructor(): SerializableCtor<IntentConditionalFollowupEventInput_> {
    return IntentConditionalFollowupEventInput_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'condition': IntentConditionalFollowupEventInput_ConditionEnum},
      keys: ['condition', 'eventInput'],
      objects: {'eventInput': IntentFollowupEventInput_}
    };
  }
}

export interface IntentConditionalMessageCase_Parameters {
  condition?: string|null;
  messages?: Array<IntentMessage_>|null;
  conditionalMessages?: Array<IntentConditionalMessage_>|null;
}
export class IntentConditionalMessageCase_ extends Serializable {
  constructor(parameters: IntentConditionalMessageCase_Parameters = {}) {
    super();
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'conditionalMessages',
        (parameters.conditionalMessages == null) ?
            (null) :
            (parameters.conditionalMessages));
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * Required. The condition used to activate the case. This is a boolean
   * logical expression. If it is true, the response is selected. It can refer
   * to context parameters in the form of
   * `#my-context-name.params.my-param-name`. Note that we refer to the context
   * parameters after webhook and output contexts are processed. See the
   * [conditions
   * reference](https://cloud.google.com/dialogflow/priv/docs/v3/condition). For
   * the last case, it may be empty, which enables a fallback condition within
   * this message.
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get conditionalMessages(): Array<IntentConditionalMessage_>|null {
    return (
        (this.Serializable$has('conditionalMessages')) ?
            (this.Serializable$get('conditionalMessages')) :
            (null));
  }

  /**
   * Optional. List of conditional messages to prompt for this case. Dialogflow
   * recursively evaluates conditional messages in this list if the case is
   * activated. Messages appended by this list will follow the ones defined in
   * `messages` field. We impose a limit on the recursion: the total number of
   * `ConditionalMessage` instances at all recursion levels must be less than
   * 100 to avoid creating an intent that is too big.
   */
  set conditionalMessages(value: Array<IntentConditionalMessage_>|null) {
    this.Serializable$set('conditionalMessages', value);
  }

  get messages(): Array<IntentMessage_>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * Optional. List of messages to prompt for this case.
   */
  set messages(value: Array<IntentMessage_>|null) {
    this.Serializable$set('messages', value);
  }

  getConstructor(): SerializableCtor<IntentConditionalMessageCase_> {
    return IntentConditionalMessageCase_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'conditionalMessages': IntentConditionalMessage_,
        'messages': IntentMessage_
      },
      keys: ['condition', 'conditionalMessages', 'messages']
    };
  }
}

export interface IntentConditionalMessage_Parameters {
  cases?: Array<IntentConditionalMessageCase_>|null;
}
export class IntentConditionalMessage_ extends Serializable {
  constructor(parameters: IntentConditionalMessage_Parameters = {}) {
    super();
    this.Serializable$set(
        'cases', (parameters.cases == null) ? (null) : (parameters.cases));
  }

  get cases(): Array<IntentConditionalMessageCase_>|null {
    return (
        (this.Serializable$has('cases')) ? (this.Serializable$get('cases')) :
                                           (null));
  }

  /**
   * List of mutually exclusive cases. The cases are checked in order from
   * beginning to end and the first one has an enabled condition is activated.
   * Thus, at one time, at most one of the cases is activated.
   */
  set cases(value: Array<IntentConditionalMessageCase_>|null) {
    this.Serializable$set('cases', value);
  }

  getConstructor(): SerializableCtor<IntentConditionalMessage_> {
    return IntentConditionalMessage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'cases': IntentConditionalMessageCase_}, keys: ['cases']};
  }
}

export interface IntentFollowupEventInput_Parameters {
  name?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
}
export class IntentFollowupEventInput_ extends Serializable {
  constructor(parameters: IntentFollowupEventInput_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of the event.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional. The collection of parameters associated with the event. Depending
   * on your protocol or client library language, this is a map, associative
   * array, symbol table, dictionary, or JSON object composed of a collection of
   * (MapKey, MapValue) pairs: - MapKey type: string - MapKey value: parameter
   * name - MapValue type: - If parameter's entity type is a composite entity:
   * map - Else: depending on parameter value type, could be one of string,
   * number, boolean, null, list or map - MapValue value: - If parameter's
   * entity type is a composite entity: map from composite entity property names
   * to property values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<IntentFollowupEventInput_> {
    return IntentFollowupEventInput_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['name', 'parameters'],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface IntentFollowupIntentInfo_Parameters {
  followupIntentName?: string|null;
  parentFollowupIntentName?: string|null;
}
export class IntentFollowupIntentInfo_ extends Serializable {
  constructor(parameters: IntentFollowupIntentInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'followupIntentName',
        (parameters.followupIntentName == null) ?
            (null) :
            (parameters.followupIntentName));
    this.Serializable$set(
        'parentFollowupIntentName',
        (parameters.parentFollowupIntentName == null) ?
            (null) :
            (parameters.parentFollowupIntentName));
  }

  get followupIntentName(): string|null {
    return (
        (this.Serializable$has('followupIntentName')) ?
            (this.Serializable$get('followupIntentName')) :
            (null));
  }

  /**
   * The unique identifier of the followup intent. Format:
   * `projects//agent/intents/`.
   */
  set followupIntentName(value: string|null) {
    this.Serializable$set('followupIntentName', value);
  }

  get parentFollowupIntentName(): string|null {
    return (
        (this.Serializable$has('parentFollowupIntentName')) ?
            (this.Serializable$get('parentFollowupIntentName')) :
            (null));
  }

  /**
   * The unique identifier of the followup intent's parent. Format:
   * `projects//agent/intents/`.
   */
  set parentFollowupIntentName(value: string|null) {
    this.Serializable$set('parentFollowupIntentName', value);
  }

  getConstructor(): SerializableCtor<IntentFollowupIntentInfo_> {
    return IntentFollowupIntentInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['followupIntentName', 'parentFollowupIntentName']};
  }
}

export interface IntentInput_Parameters {
  intent?: string|null;
  languageCode?: string|null;
}
export class IntentInput_ extends Serializable {
  constructor(parameters: IntentInput_Parameters = {}) {
    super();
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * Required. The unique identifier of the intent in V3 agent. Format:
   * `projects//locations//locations//agents//intents/`.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of this conversational query. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  getConstructor(): SerializableCtor<IntentInput_> {
    return IntentInput_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intent', 'languageCode']};
  }
}

export interface IntentMessageAvayaPlayAudio_Parameters {
  audioUri?: string|null;
}
export class IntentMessageAvayaPlayAudio_ extends Serializable {
  constructor(parameters: IntentMessageAvayaPlayAudio_Parameters = {}) {
    super();
    this.Serializable$set(
        'audioUri',
        (parameters.audioUri == null) ? (null) : (parameters.audioUri));
  }

  get audioUri(): string|null {
    return (
        (this.Serializable$has('audioUri')) ?
            (this.Serializable$get('audioUri')) :
            (null));
  }

  /**
   * Required. Audio URI for Avaya Browser to play the audio.
   */
  set audioUri(value: string|null) {
    this.Serializable$set('audioUri', value);
  }

  getConstructor(): SerializableCtor<IntentMessageAvayaPlayAudio_> {
    return IntentMessageAvayaPlayAudio_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audioUri']};
  }
}

export interface IntentMessageBasicCardButtonOpenUriAction_Parameters {
  uri?: string|null;
}
export class IntentMessageBasicCardButtonOpenUriAction_ extends Serializable {
  constructor(
      parameters: IntentMessageBasicCardButtonOpenUriAction_Parameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The HTTP or HTTPS scheme URI.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor():
      SerializableCtor<IntentMessageBasicCardButtonOpenUriAction_> {
    return IntentMessageBasicCardButtonOpenUriAction_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface IntentMessageBasicCardButton_Parameters {
  title?: string|null;
  openUriAction?: IntentMessageBasicCardButtonOpenUriAction_|null;
}
export class IntentMessageBasicCardButton_ extends Serializable {
  constructor(parameters: IntentMessageBasicCardButton_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'openUriAction',
        (parameters.openUriAction == null) ? (null) :
                                             (parameters.openUriAction));
  }

  get openUriAction(): IntentMessageBasicCardButtonOpenUriAction_|null {
    return (
        (this.Serializable$has('openUriAction')) ?
            (this.Serializable$get('openUriAction')) :
            (null));
  }

  /**
   * Required. Action to take when a user taps on the button.
   */
  set openUriAction(value: IntentMessageBasicCardButtonOpenUriAction_|null) {
    this.Serializable$set('openUriAction', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. The title of the button.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<IntentMessageBasicCardButton_> {
    return IntentMessageBasicCardButton_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['openUriAction', 'title'],
      objects: {'openUriAction': IntentMessageBasicCardButtonOpenUriAction_}
    };
  }
}

export interface IntentMessageBasicCard_Parameters {
  title?: string|null;
  subtitle?: string|null;
  formattedText?: string|null;
  image?: IntentMessageImage_|null;
  buttons?: Array<IntentMessageBasicCardButton_>|null;
}
export class IntentMessageBasicCard_ extends Serializable {
  constructor(parameters: IntentMessageBasicCard_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'subtitle',
        (parameters.subtitle == null) ? (null) : (parameters.subtitle));
    this.Serializable$set(
        'formattedText',
        (parameters.formattedText == null) ? (null) :
                                             (parameters.formattedText));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
    this.Serializable$set(
        'buttons',
        (parameters.buttons == null) ? (null) : (parameters.buttons));
  }

  get buttons(): Array<IntentMessageBasicCardButton_>|null {
    return (
        (this.Serializable$has('buttons')) ?
            (this.Serializable$get('buttons')) :
            (null));
  }

  /**
   * Optional. The collection of card buttons.
   */
  set buttons(value: Array<IntentMessageBasicCardButton_>|null) {
    this.Serializable$set('buttons', value);
  }

  get formattedText(): string|null {
    return (
        (this.Serializable$has('formattedText')) ?
            (this.Serializable$get('formattedText')) :
            (null));
  }

  /**
   * Required, unless image is present. The body text of the card.
   */
  set formattedText(value: string|null) {
    this.Serializable$set('formattedText', value);
  }

  get image(): IntentMessageImage_|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Optional. The image for the card.
   */
  set image(value: IntentMessageImage_|null) {
    this.Serializable$set('image', value);
  }

  get subtitle(): string|null {
    return (
        (this.Serializable$has('subtitle')) ?
            (this.Serializable$get('subtitle')) :
            (null));
  }

  /**
   * Optional. The subtitle of the card.
   */
  set subtitle(value: string|null) {
    this.Serializable$set('subtitle', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. The title of the card.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<IntentMessageBasicCard_> {
    return IntentMessageBasicCard_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'buttons': IntentMessageBasicCardButton_},
      keys: ['buttons', 'formattedText', 'image', 'subtitle', 'title'],
      objects: {'image': IntentMessageImage_}
    };
  }
}

export interface IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_Parameters {
  url?: string|null;
  urlTypeHint?:
      IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint|
      null;
}
export class
    IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_ extends
        Serializable {
  constructor(
      parameters:
          IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'url', (parameters.url == null) ? (null) : (parameters.url));
    this.Serializable$set(
        'urlTypeHint',
        (parameters.urlTypeHint == null) ? (null) : (parameters.urlTypeHint));
  }

  static get UrlTypeHint():
      IIntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHintEnum {
    return IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHintEnum;
  }

  get url(): string|null {
    return (
        (this.Serializable$has('url')) ? (this.Serializable$get('url')) :
                                         (null));
  }

  /**
   * Required. URL
   */
  set url(value: string|null) {
    this.Serializable$set('url', value);
  }

  get urlTypeHint():
      IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint
      |null {
    return (
        (this.Serializable$has('urlTypeHint')) ?
            (this.Serializable$get('urlTypeHint')) :
            (null));
  }

  /**
   * Optional. Specifies the type of viewer that is used when opening the URL.
   * Defaults to opening via web browser.
   */
  set urlTypeHint(
      value:
          IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHint|
      null) {
    this.Serializable$set('urlTypeHint', value);
  }

  getConstructor(): SerializableCtor<
      IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_> {
    return IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'urlTypeHint':
            IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_UrlTypeHintEnum
      },
      keys: ['url', 'urlTypeHint']
    };
  }
}

export interface IntentMessageBrowseCarouselCardBrowseCarouselCardItem_Parameters {
  openUriAction?:
      IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_|null;
  title?: string|null;
  description?: string|null;
  image?: IntentMessageImage_|null;
  footer?: string|null;
}
export class IntentMessageBrowseCarouselCardBrowseCarouselCardItem_ extends
    Serializable {
  constructor(
      parameters:
          IntentMessageBrowseCarouselCardBrowseCarouselCardItem_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'openUriAction',
        (parameters.openUriAction == null) ? (null) :
                                             (parameters.openUriAction));
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
    this.Serializable$set(
        'footer', (parameters.footer == null) ? (null) : (parameters.footer));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. Description of the carousel item. Maximum of four lines of text.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get footer(): string|null {
    return (
        (this.Serializable$has('footer')) ? (this.Serializable$get('footer')) :
                                            (null));
  }

  /**
   * Optional. Text that appears at the bottom of the Browse Carousel Card.
   * Maximum of one line of text.
   */
  set footer(value: string|null) {
    this.Serializable$set('footer', value);
  }

  get image(): IntentMessageImage_|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Optional. Hero image for the carousel item.
   */
  set image(value: IntentMessageImage_|null) {
    this.Serializable$set('image', value);
  }

  get openUriAction():
      IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_|null {
    return (
        (this.Serializable$has('openUriAction')) ?
            (this.Serializable$get('openUriAction')) :
            (null));
  }

  /**
   * Required. Action to present to the user.
   */
  set openUriAction(
      value:
          IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_|
      null) {
    this.Serializable$set('openUriAction', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. Title of the carousel item. Maximum of two lines of text.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor():
      SerializableCtor<IntentMessageBrowseCarouselCardBrowseCarouselCardItem_> {
    return IntentMessageBrowseCarouselCardBrowseCarouselCardItem_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['description', 'footer', 'image', 'openUriAction', 'title'],
      objects: {
        'image': IntentMessageImage_,
        'openUriAction':
            IntentMessageBrowseCarouselCardBrowseCarouselCardItemOpenUrlAction_
      }
    };
  }
}

export interface IntentMessageBrowseCarouselCard_Parameters {
  items?: Array<IntentMessageBrowseCarouselCardBrowseCarouselCardItem_>|null;
  imageDisplayOptions?: IntentMessageBrowseCarouselCard_ImageDisplayOptions|
      null;
}
export class IntentMessageBrowseCarouselCard_ extends Serializable {
  constructor(parameters: IntentMessageBrowseCarouselCard_Parameters = {}) {
    super();
    this.Serializable$set(
        'items', (parameters.items == null) ? (null) : (parameters.items));
    this.Serializable$set(
        'imageDisplayOptions',
        (parameters.imageDisplayOptions == null) ?
            (null) :
            (parameters.imageDisplayOptions));
  }

  static get ImageDisplayOptions():
      IIntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum {
    return IntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum;
  }

  get imageDisplayOptions(): IntentMessageBrowseCarouselCard_ImageDisplayOptions
      |null {
    return (
        (this.Serializable$has('imageDisplayOptions')) ?
            (this.Serializable$get('imageDisplayOptions')) :
            (null));
  }

  /**
   * Optional. Settings for displaying the image. Applies to every image in
   * items.
   */
  set imageDisplayOptions(
      value: IntentMessageBrowseCarouselCard_ImageDisplayOptions|null) {
    this.Serializable$set('imageDisplayOptions', value);
  }

  get items():
      Array<IntentMessageBrowseCarouselCardBrowseCarouselCardItem_>|null {
    return (
        (this.Serializable$has('items')) ? (this.Serializable$get('items')) :
                                           (null));
  }

  /**
   * Required. List of items in the Browse Carousel Card. Minimum of two items,
   * maximum of ten.
   */
  set items(value:
                Array<IntentMessageBrowseCarouselCardBrowseCarouselCardItem_>|
            null) {
    this.Serializable$set('items', value);
  }

  getConstructor(): SerializableCtor<IntentMessageBrowseCarouselCard_> {
    return IntentMessageBrowseCarouselCard_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'items': IntentMessageBrowseCarouselCardBrowseCarouselCardItem_},
      enums: {
        'imageDisplayOptions':
            IntentMessageBrowseCarouselCard_ImageDisplayOptionsEnum
      },
      keys: ['imageDisplayOptions', 'items']
    };
  }
}

export interface IntentMessageCardButton_Parameters {
  text?: string|null;
  postback?: string|null;
}
export class IntentMessageCardButton_ extends Serializable {
  constructor(parameters: IntentMessageCardButton_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'postback',
        (parameters.postback == null) ? (null) : (parameters.postback));
  }

  get postback(): string|null {
    return (
        (this.Serializable$has('postback')) ?
            (this.Serializable$get('postback')) :
            (null));
  }

  /**
   * Optional. The text to send back to the Dialogflow API or a URI to open.
   */
  set postback(value: string|null) {
    this.Serializable$set('postback', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Optional. The text to show on the button.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<IntentMessageCardButton_> {
    return IntentMessageCardButton_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['postback', 'text']};
  }
}

export interface IntentMessageCard_Parameters {
  title?: string|null;
  subtitle?: string|null;
  imageUri?: string|null;
  buttons?: Array<IntentMessageCardButton_>|null;
}
export class IntentMessageCard_ extends Serializable {
  constructor(parameters: IntentMessageCard_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'subtitle',
        (parameters.subtitle == null) ? (null) : (parameters.subtitle));
    this.Serializable$set(
        'imageUri',
        (parameters.imageUri == null) ? (null) : (parameters.imageUri));
    this.Serializable$set(
        'buttons',
        (parameters.buttons == null) ? (null) : (parameters.buttons));
  }

  get buttons(): Array<IntentMessageCardButton_>|null {
    return (
        (this.Serializable$has('buttons')) ?
            (this.Serializable$get('buttons')) :
            (null));
  }

  /**
   * Optional. The collection of card buttons.
   */
  set buttons(value: Array<IntentMessageCardButton_>|null) {
    this.Serializable$set('buttons', value);
  }

  get imageUri(): string|null {
    return (
        (this.Serializable$has('imageUri')) ?
            (this.Serializable$get('imageUri')) :
            (null));
  }

  /**
   * Optional. The public URI to an image file for the card.
   */
  set imageUri(value: string|null) {
    this.Serializable$set('imageUri', value);
  }

  get subtitle(): string|null {
    return (
        (this.Serializable$has('subtitle')) ?
            (this.Serializable$get('subtitle')) :
            (null));
  }

  /**
   * Optional. The subtitle of the card.
   */
  set subtitle(value: string|null) {
    this.Serializable$set('subtitle', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. The title of the card.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<IntentMessageCard_> {
    return IntentMessageCard_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'buttons': IntentMessageCardButton_},
      keys: ['buttons', 'imageUri', 'subtitle', 'title']
    };
  }
}

export interface IntentMessageCarouselSelectItem_Parameters {
  info?: IntentMessageSelectItemInfo_|null;
  title?: string|null;
  description?: string|null;
  image?: IntentMessageImage_|null;
}
export class IntentMessageCarouselSelectItem_ extends Serializable {
  constructor(parameters: IntentMessageCarouselSelectItem_Parameters = {}) {
    super();
    this.Serializable$set(
        'info', (parameters.info == null) ? (null) : (parameters.info));
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. The body text of the card.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get image(): IntentMessageImage_|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Optional. The image to display.
   */
  set image(value: IntentMessageImage_|null) {
    this.Serializable$set('image', value);
  }

  get info(): IntentMessageSelectItemInfo_|null {
    return (
        (this.Serializable$has('info')) ? (this.Serializable$get('info')) :
                                          (null));
  }

  /**
   * Required. Additional info about the option item.
   */
  set info(value: IntentMessageSelectItemInfo_|null) {
    this.Serializable$set('info', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. Title of the carousel item.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<IntentMessageCarouselSelectItem_> {
    return IntentMessageCarouselSelectItem_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['description', 'image', 'info', 'title'],
      objects:
          {'image': IntentMessageImage_, 'info': IntentMessageSelectItemInfo_}
    };
  }
}

export interface IntentMessageCarouselSelect_Parameters {
  items?: Array<IntentMessageCarouselSelectItem_>|null;
}
export class IntentMessageCarouselSelect_ extends Serializable {
  constructor(parameters: IntentMessageCarouselSelect_Parameters = {}) {
    super();
    this.Serializable$set(
        'items', (parameters.items == null) ? (null) : (parameters.items));
  }

  get items(): Array<IntentMessageCarouselSelectItem_>|null {
    return (
        (this.Serializable$has('items')) ? (this.Serializable$get('items')) :
                                           (null));
  }

  /**
   * Required. Carousel items.
   */
  set items(value: Array<IntentMessageCarouselSelectItem_>|null) {
    this.Serializable$set('items', value);
  }

  getConstructor(): SerializableCtor<IntentMessageCarouselSelect_> {
    return IntentMessageCarouselSelect_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'items': IntentMessageCarouselSelectItem_},
      keys: ['items']
    };
  }
}

export interface IntentMessageColumnProperties_Parameters {
  header?: string|null;
  horizontalAlignment?: IntentMessageColumnProperties_HorizontalAlignment|null;
}
export class IntentMessageColumnProperties_ extends Serializable {
  constructor(parameters: IntentMessageColumnProperties_Parameters = {}) {
    super();
    this.Serializable$set(
        'header', (parameters.header == null) ? (null) : (parameters.header));
    this.Serializable$set(
        'horizontalAlignment',
        (parameters.horizontalAlignment == null) ?
            (null) :
            (parameters.horizontalAlignment));
  }

  static get HorizontalAlignment():
      IIntentMessageColumnProperties_HorizontalAlignmentEnum {
    return IntentMessageColumnProperties_HorizontalAlignmentEnum;
  }

  get header(): string|null {
    return (
        (this.Serializable$has('header')) ? (this.Serializable$get('header')) :
                                            (null));
  }

  /**
   * Required. Column heading.
   */
  set header(value: string|null) {
    this.Serializable$set('header', value);
  }

  get horizontalAlignment(): IntentMessageColumnProperties_HorizontalAlignment
      |null {
    return (
        (this.Serializable$has('horizontalAlignment')) ?
            (this.Serializable$get('horizontalAlignment')) :
            (null));
  }

  /**
   * Optional. Defines text alignment for all cells in this column.
   */
  set horizontalAlignment(value:
                              IntentMessageColumnProperties_HorizontalAlignment|
                          null) {
    this.Serializable$set('horizontalAlignment', value);
  }

  getConstructor(): SerializableCtor<IntentMessageColumnProperties_> {
    return IntentMessageColumnProperties_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'horizontalAlignment':
            IntentMessageColumnProperties_HorizontalAlignmentEnum
      },
      keys: ['header', 'horizontalAlignment']
    };
  }
}

export interface IntentMessageImage_Parameters {
  imageUri?: string|null;
  accessibilityText?: string|null;
}
export class IntentMessageImage_ extends Serializable {
  constructor(parameters: IntentMessageImage_Parameters = {}) {
    super();
    this.Serializable$set(
        'imageUri',
        (parameters.imageUri == null) ? (null) : (parameters.imageUri));
    this.Serializable$set(
        'accessibilityText',
        (parameters.accessibilityText == null) ?
            (null) :
            (parameters.accessibilityText));
  }

  get accessibilityText(): string|null {
    return (
        (this.Serializable$has('accessibilityText')) ?
            (this.Serializable$get('accessibilityText')) :
            (null));
  }

  /**
   * A text description of the image to be used for accessibility, e.g., screen
   * readers. Required if image_uri is set for CarouselSelect.
   */
  set accessibilityText(value: string|null) {
    this.Serializable$set('accessibilityText', value);
  }

  get imageUri(): string|null {
    return (
        (this.Serializable$has('imageUri')) ?
            (this.Serializable$get('imageUri')) :
            (null));
  }

  /**
   * Optional. The public URI to an image file.
   */
  set imageUri(value: string|null) {
    this.Serializable$set('imageUri', value);
  }

  getConstructor(): SerializableCtor<IntentMessageImage_> {
    return IntentMessageImage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['accessibilityText', 'imageUri']};
  }
}

export interface IntentMessageLinkOutSuggestion_Parameters {
  destinationName?: string|null;
  uri?: string|null;
}
export class IntentMessageLinkOutSuggestion_ extends Serializable {
  constructor(parameters: IntentMessageLinkOutSuggestion_Parameters = {}) {
    super();
    this.Serializable$set(
        'destinationName',
        (parameters.destinationName == null) ? (null) :
                                               (parameters.destinationName));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get destinationName(): string|null {
    return (
        (this.Serializable$has('destinationName')) ?
            (this.Serializable$get('destinationName')) :
            (null));
  }

  /**
   * Required. The name of the app or site this chip is linking to.
   */
  set destinationName(value: string|null) {
    this.Serializable$set('destinationName', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The URI of the app or site to open when the user taps the
   * suggestion chip.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<IntentMessageLinkOutSuggestion_> {
    return IntentMessageLinkOutSuggestion_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['destinationName', 'uri']};
  }
}

export interface IntentMessageListSelectItem_Parameters {
  info?: IntentMessageSelectItemInfo_|null;
  title?: string|null;
  description?: string|null;
  image?: IntentMessageImage_|null;
}
export class IntentMessageListSelectItem_ extends Serializable {
  constructor(parameters: IntentMessageListSelectItem_Parameters = {}) {
    super();
    this.Serializable$set(
        'info', (parameters.info == null) ? (null) : (parameters.info));
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. The main text describing the item.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get image(): IntentMessageImage_|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Optional. The image to display.
   */
  set image(value: IntentMessageImage_|null) {
    this.Serializable$set('image', value);
  }

  get info(): IntentMessageSelectItemInfo_|null {
    return (
        (this.Serializable$has('info')) ? (this.Serializable$get('info')) :
                                          (null));
  }

  /**
   * Required. Additional information about this option.
   */
  set info(value: IntentMessageSelectItemInfo_|null) {
    this.Serializable$set('info', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. The title of the list item.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<IntentMessageListSelectItem_> {
    return IntentMessageListSelectItem_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['description', 'image', 'info', 'title'],
      objects:
          {'image': IntentMessageImage_, 'info': IntentMessageSelectItemInfo_}
    };
  }
}

export interface IntentMessageListSelect_Parameters {
  title?: string|null;
  items?: Array<IntentMessageListSelectItem_>|null;
  subtitle?: string|null;
}
export class IntentMessageListSelect_ extends Serializable {
  constructor(parameters: IntentMessageListSelect_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'items', (parameters.items == null) ? (null) : (parameters.items));
    this.Serializable$set(
        'subtitle',
        (parameters.subtitle == null) ? (null) : (parameters.subtitle));
  }

  get items(): Array<IntentMessageListSelectItem_>|null {
    return (
        (this.Serializable$has('items')) ? (this.Serializable$get('items')) :
                                           (null));
  }

  /**
   * Required. List items.
   */
  set items(value: Array<IntentMessageListSelectItem_>|null) {
    this.Serializable$set('items', value);
  }

  get subtitle(): string|null {
    return (
        (this.Serializable$has('subtitle')) ?
            (this.Serializable$get('subtitle')) :
            (null));
  }

  /**
   * Optional. Subtitle of the list.
   */
  set subtitle(value: string|null) {
    this.Serializable$set('subtitle', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. The overall title of the list.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<IntentMessageListSelect_> {
    return IntentMessageListSelect_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'items': IntentMessageListSelectItem_},
      keys: ['items', 'subtitle', 'title']
    };
  }
}

export interface IntentMessageMediaContentResponseMediaObject_Parameters {
  name?: string|null;
  description?: string|null;
  largeImage?: IntentMessageImage_|null;
  icon?: IntentMessageImage_|null;
  contentUrl?: string|null;
}
export class IntentMessageMediaContentResponseMediaObject_ extends
    Serializable {
  constructor(
      parameters:
          IntentMessageMediaContentResponseMediaObject_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'largeImage',
        (parameters.largeImage == null) ? (null) : (parameters.largeImage));
    this.Serializable$set(
        'icon', (parameters.icon == null) ? (null) : (parameters.icon));
    this.Serializable$set(
        'contentUrl',
        (parameters.contentUrl == null) ? (null) : (parameters.contentUrl));
  }

  get contentUrl(): string|null {
    return (
        (this.Serializable$has('contentUrl')) ?
            (this.Serializable$get('contentUrl')) :
            (null));
  }

  /**
   * Required. Url where the media is stored.
   */
  set contentUrl(value: string|null) {
    this.Serializable$set('contentUrl', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. Description of media card.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get icon(): IntentMessageImage_|null {
    return (
        (this.Serializable$has('icon')) ? (this.Serializable$get('icon')) :
                                          (null));
  }

  /**
   * Optional. Icon to display above media content.
   */
  set icon(value: IntentMessageImage_|null) {
    this.Serializable$set('icon', value);
  }

  get largeImage(): IntentMessageImage_|null {
    return (
        (this.Serializable$has('largeImage')) ?
            (this.Serializable$get('largeImage')) :
            (null));
  }

  /**
   * Optional. Image to display above media content.
   */
  set largeImage(value: IntentMessageImage_|null) {
    this.Serializable$set('largeImage', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. Name of media card.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor():
      SerializableCtor<IntentMessageMediaContentResponseMediaObject_> {
    return IntentMessageMediaContentResponseMediaObject_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['contentUrl', 'description', 'icon', 'largeImage', 'name'],
      objects: {'icon': IntentMessageImage_, 'largeImage': IntentMessageImage_}
    };
  }
}

export interface IntentMessageMediaContent_Parameters {
  mediaType?: IntentMessageMediaContent_MediaType|null;
  mediaObjects?: Array<IntentMessageMediaContentResponseMediaObject_>|null;
  repeatMode?: IntentMessageMediaContent_RepeatMode|null;
  firstMediaObjectIndex?: number|null;
}
export class IntentMessageMediaContent_ extends Serializable {
  constructor(parameters: IntentMessageMediaContent_Parameters = {}) {
    super();
    this.Serializable$set(
        'mediaType',
        (parameters.mediaType == null) ? (null) : (parameters.mediaType));
    this.Serializable$set(
        'mediaObjects',
        (parameters.mediaObjects == null) ? (null) : (parameters.mediaObjects));
    this.Serializable$set(
        'repeatMode',
        (parameters.repeatMode == null) ? (null) : (parameters.repeatMode));
    this.Serializable$set(
        'firstMediaObjectIndex',
        (parameters.firstMediaObjectIndex == null) ?
            (null) :
            (parameters.firstMediaObjectIndex));
  }

  static get MediaType(): IIntentMessageMediaContent_MediaTypeEnum {
    return IntentMessageMediaContent_MediaTypeEnum;
  }

  static get RepeatMode(): IIntentMessageMediaContent_RepeatModeEnum {
    return IntentMessageMediaContent_RepeatModeEnum;
  }

  get firstMediaObjectIndex(): number|null {
    return (
        (this.Serializable$has('firstMediaObjectIndex')) ?
            (this.Serializable$get('firstMediaObjectIndex')) :
            (null));
  }

  /**
   * Optional. 0-based index of the first ResponseMediaObject in media_objects
   * to play. If unspecified, zero or out-of-bounds, playback starts at the
   * first media object.
   */
  set firstMediaObjectIndex(value: number|null) {
    this.Serializable$set('firstMediaObjectIndex', value);
  }

  get mediaObjects():
      Array<IntentMessageMediaContentResponseMediaObject_>|null {
    return (
        (this.Serializable$has('mediaObjects')) ?
            (this.Serializable$get('mediaObjects')) :
            (null));
  }

  /**
   * Required. List of media objects.
   */
  set mediaObjects(value: Array<IntentMessageMediaContentResponseMediaObject_>|
                   null) {
    this.Serializable$set('mediaObjects', value);
  }

  get mediaType(): IntentMessageMediaContent_MediaType|null {
    return (
        (this.Serializable$has('mediaType')) ?
            (this.Serializable$get('mediaType')) :
            (null));
  }

  /**
   * Optional. What type of media is the content (ie \"audio\").
   */
  set mediaType(value: IntentMessageMediaContent_MediaType|null) {
    this.Serializable$set('mediaType', value);
  }

  get repeatMode(): IntentMessageMediaContent_RepeatMode|null {
    return (
        (this.Serializable$has('repeatMode')) ?
            (this.Serializable$get('repeatMode')) :
            (null));
  }

  /**
   * Optional. Repeat mode for the list of Media Objects.
   */
  set repeatMode(value: IntentMessageMediaContent_RepeatMode|null) {
    this.Serializable$set('repeatMode', value);
  }

  getConstructor(): SerializableCtor<IntentMessageMediaContent_> {
    return IntentMessageMediaContent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'mediaObjects': IntentMessageMediaContentResponseMediaObject_},
      enums: {
        'mediaType': IntentMessageMediaContent_MediaTypeEnum,
        'repeatMode': IntentMessageMediaContent_RepeatModeEnum
      },
      keys: ['firstMediaObjectIndex', 'mediaObjects', 'mediaType', 'repeatMode']
    };
  }
}

export interface IntentMessageQuickReplies_Parameters {
  title?: string|null;
  quickReplies?: Array<string>|null;
}
export class IntentMessageQuickReplies_ extends Serializable {
  constructor(parameters: IntentMessageQuickReplies_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'quickReplies',
        (parameters.quickReplies == null) ? (null) : (parameters.quickReplies));
  }

  get quickReplies(): Array<string>|null {
    return (
        (this.Serializable$has('quickReplies')) ?
            (this.Serializable$get('quickReplies')) :
            (null));
  }

  /**
   * Optional. The collection of quick replies.
   */
  set quickReplies(value: Array<string>|null) {
    this.Serializable$set('quickReplies', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. The title of the collection of quick replies.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<IntentMessageQuickReplies_> {
    return IntentMessageQuickReplies_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['quickReplies', 'title']};
  }
}

export interface IntentMessageRbmCardContentRbmMedia_Parameters {
  fileUri?: string|null;
  thumbnailUri?: string|null;
  height?: IntentMessageRbmCardContentRbmMedia_Height|null;
}
export class IntentMessageRbmCardContentRbmMedia_ extends Serializable {
  constructor(parameters: IntentMessageRbmCardContentRbmMedia_Parameters = {}) {
    super();
    this.Serializable$set(
        'fileUri',
        (parameters.fileUri == null) ? (null) : (parameters.fileUri));
    this.Serializable$set(
        'thumbnailUri',
        (parameters.thumbnailUri == null) ? (null) : (parameters.thumbnailUri));
    this.Serializable$set(
        'height', (parameters.height == null) ? (null) : (parameters.height));
  }

  static get Height(): IIntentMessageRbmCardContentRbmMedia_HeightEnum {
    return IntentMessageRbmCardContentRbmMedia_HeightEnum;
  }

  get fileUri(): string|null {
    return (
        (this.Serializable$has('fileUri')) ?
            (this.Serializable$get('fileUri')) :
            (null));
  }

  /**
   * Required. Publicly reachable URI of the file. The RBM platform determines
   * the MIME type of the file from the content-type field in the HTTP headers
   * when the platform fetches the file. The content-type field must be present
   * and accurate in the HTTP response from the URL.
   */
  set fileUri(value: string|null) {
    this.Serializable$set('fileUri', value);
  }

  get height(): IntentMessageRbmCardContentRbmMedia_Height|null {
    return (
        (this.Serializable$has('height')) ? (this.Serializable$get('height')) :
                                            (null));
  }

  /**
   * Required for cards with vertical orientation. The height of the media
   * within a rich card with a vertical layout. For a standalone card with
   * horizontal layout, height is not customizable, and this field is ignored.
   */
  set height(value: IntentMessageRbmCardContentRbmMedia_Height|null) {
    this.Serializable$set('height', value);
  }

  get thumbnailUri(): string|null {
    return (
        (this.Serializable$has('thumbnailUri')) ?
            (this.Serializable$get('thumbnailUri')) :
            (null));
  }

  /**
   * Optional. Publicly reachable URI of the thumbnail.If you don't provide a
   * thumbnail URI, the RBM platform displays a blank placeholder thumbnail
   * until the user's device downloads the file. Depending on the user's
   * setting, the file may not download automatically and may require the user
   * to tap a download button.
   */
  set thumbnailUri(value: string|null) {
    this.Serializable$set('thumbnailUri', value);
  }

  getConstructor(): SerializableCtor<IntentMessageRbmCardContentRbmMedia_> {
    return IntentMessageRbmCardContentRbmMedia_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'height': IntentMessageRbmCardContentRbmMedia_HeightEnum},
      keys: ['fileUri', 'height', 'thumbnailUri']
    };
  }
}

export interface IntentMessageRbmCardContent_Parameters {
  title?: string|null;
  description?: string|null;
  media?: IntentMessageRbmCardContentRbmMedia_|null;
  suggestions?: Array<IntentMessageRbmSuggestion_>|null;
}
export class IntentMessageRbmCardContent_ extends Serializable {
  constructor(parameters: IntentMessageRbmCardContent_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'media', (parameters.media == null) ? (null) : (parameters.media));
    this.Serializable$set(
        'suggestions',
        (parameters.suggestions == null) ? (null) : (parameters.suggestions));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. Description of the card (at most 2000 bytes). At least one of the
   * title, description or media must be set.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get media(): IntentMessageRbmCardContentRbmMedia_|null {
    return (
        (this.Serializable$has('media')) ? (this.Serializable$get('media')) :
                                           (null));
  }

  /**
   * Optional. However at least one of the title, description or media must be
   * set. Media (image, GIF or a video) to include in the card.
   */
  set media(value: IntentMessageRbmCardContentRbmMedia_|null) {
    this.Serializable$set('media', value);
  }

  get suggestions(): Array<IntentMessageRbmSuggestion_>|null {
    return (
        (this.Serializable$has('suggestions')) ?
            (this.Serializable$get('suggestions')) :
            (null));
  }

  /**
   * Optional. List of suggestions to include in the card.
   */
  set suggestions(value: Array<IntentMessageRbmSuggestion_>|null) {
    this.Serializable$set('suggestions', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Optional. Title of the card (at most 200 bytes). At least one of the title,
   * description or media must be set.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<IntentMessageRbmCardContent_> {
    return IntentMessageRbmCardContent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'suggestions': IntentMessageRbmSuggestion_},
      keys: ['description', 'media', 'suggestions', 'title'],
      objects: {'media': IntentMessageRbmCardContentRbmMedia_}
    };
  }
}

export interface IntentMessageRbmCarouselCard_Parameters {
  cardWidth?: IntentMessageRbmCarouselCard_CardWidth|null;
  cardContents?: Array<IntentMessageRbmCardContent_>|null;
}
export class IntentMessageRbmCarouselCard_ extends Serializable {
  constructor(parameters: IntentMessageRbmCarouselCard_Parameters = {}) {
    super();
    this.Serializable$set(
        'cardWidth',
        (parameters.cardWidth == null) ? (null) : (parameters.cardWidth));
    this.Serializable$set(
        'cardContents',
        (parameters.cardContents == null) ? (null) : (parameters.cardContents));
  }

  static get CardWidth(): IIntentMessageRbmCarouselCard_CardWidthEnum {
    return IntentMessageRbmCarouselCard_CardWidthEnum;
  }

  get cardContents(): Array<IntentMessageRbmCardContent_>|null {
    return (
        (this.Serializable$has('cardContents')) ?
            (this.Serializable$get('cardContents')) :
            (null));
  }

  /**
   * Required. The cards in the carousel. A carousel must have at least 2 cards
   * and at most 10.
   */
  set cardContents(value: Array<IntentMessageRbmCardContent_>|null) {
    this.Serializable$set('cardContents', value);
  }

  get cardWidth(): IntentMessageRbmCarouselCard_CardWidth|null {
    return (
        (this.Serializable$has('cardWidth')) ?
            (this.Serializable$get('cardWidth')) :
            (null));
  }

  /**
   * Required. The width of the cards in the carousel.
   */
  set cardWidth(value: IntentMessageRbmCarouselCard_CardWidth|null) {
    this.Serializable$set('cardWidth', value);
  }

  getConstructor(): SerializableCtor<IntentMessageRbmCarouselCard_> {
    return IntentMessageRbmCarouselCard_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'cardContents': IntentMessageRbmCardContent_},
      enums: {'cardWidth': IntentMessageRbmCarouselCard_CardWidthEnum},
      keys: ['cardContents', 'cardWidth']
    };
  }
}

export interface IntentMessageRbmStandaloneCard_Parameters {
  cardOrientation?: IntentMessageRbmStandaloneCard_CardOrientation|null;
  thumbnailImageAlignment?:
      IntentMessageRbmStandaloneCard_ThumbnailImageAlignment|null;
  cardContent?: IntentMessageRbmCardContent_|null;
}
export class IntentMessageRbmStandaloneCard_ extends Serializable {
  constructor(parameters: IntentMessageRbmStandaloneCard_Parameters = {}) {
    super();
    this.Serializable$set(
        'cardOrientation',
        (parameters.cardOrientation == null) ? (null) :
                                               (parameters.cardOrientation));
    this.Serializable$set(
        'thumbnailImageAlignment',
        (parameters.thumbnailImageAlignment == null) ?
            (null) :
            (parameters.thumbnailImageAlignment));
    this.Serializable$set(
        'cardContent',
        (parameters.cardContent == null) ? (null) : (parameters.cardContent));
  }

  static get CardOrientation():
      IIntentMessageRbmStandaloneCard_CardOrientationEnum {
    return IntentMessageRbmStandaloneCard_CardOrientationEnum;
  }

  static get ThumbnailImageAlignment():
      IIntentMessageRbmStandaloneCard_ThumbnailImageAlignmentEnum {
    return IntentMessageRbmStandaloneCard_ThumbnailImageAlignmentEnum;
  }

  get cardContent(): IntentMessageRbmCardContent_|null {
    return (
        (this.Serializable$has('cardContent')) ?
            (this.Serializable$get('cardContent')) :
            (null));
  }

  /**
   * Required. Card content.
   */
  set cardContent(value: IntentMessageRbmCardContent_|null) {
    this.Serializable$set('cardContent', value);
  }

  get cardOrientation(): IntentMessageRbmStandaloneCard_CardOrientation|null {
    return (
        (this.Serializable$has('cardOrientation')) ?
            (this.Serializable$get('cardOrientation')) :
            (null));
  }

  /**
   * Required. Orientation of the card.
   */
  set cardOrientation(value: IntentMessageRbmStandaloneCard_CardOrientation|
                      null) {
    this.Serializable$set('cardOrientation', value);
  }

  get thumbnailImageAlignment():
      IntentMessageRbmStandaloneCard_ThumbnailImageAlignment|null {
    return (
        (this.Serializable$has('thumbnailImageAlignment')) ?
            (this.Serializable$get('thumbnailImageAlignment')) :
            (null));
  }

  /**
   * Required if orientation is horizontal. Image preview alignment for
   * standalone cards with horizontal layout.
   */
  set thumbnailImageAlignment(
      value: IntentMessageRbmStandaloneCard_ThumbnailImageAlignment|null) {
    this.Serializable$set('thumbnailImageAlignment', value);
  }

  getConstructor(): SerializableCtor<IntentMessageRbmStandaloneCard_> {
    return IntentMessageRbmStandaloneCard_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'cardOrientation': IntentMessageRbmStandaloneCard_CardOrientationEnum,
        'thumbnailImageAlignment':
            IntentMessageRbmStandaloneCard_ThumbnailImageAlignmentEnum
      },
      keys: ['cardContent', 'cardOrientation', 'thumbnailImageAlignment'],
      objects: {'cardContent': IntentMessageRbmCardContent_}
    };
  }
}

export interface IntentMessageRbmSuggestedActionRbmSuggestedActionDial_Parameters {
  phoneNumber?: string|null;
}
export class IntentMessageRbmSuggestedActionRbmSuggestedActionDial_ extends
    Serializable {
  constructor(
      parameters:
          IntentMessageRbmSuggestedActionRbmSuggestedActionDial_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
  }

  get phoneNumber(): string|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Required. The phone number to fill in the default dialer app. This field
   * should be in [E.164](https://en.wikipedia.org/wiki/E.164) format. An
   * example of a correctly formatted phone number: +15556767888.
   */
  set phoneNumber(value: string|null) {
    this.Serializable$set('phoneNumber', value);
  }

  getConstructor():
      SerializableCtor<IntentMessageRbmSuggestedActionRbmSuggestedActionDial_> {
    return IntentMessageRbmSuggestedActionRbmSuggestedActionDial_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['phoneNumber']};
  }
}

export interface IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri_Parameters {
  uri?: string|null;
}
export class IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri_ extends
    Serializable {
  constructor(
      parameters:
          IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Required. The uri to open on the user device
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<
      IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri_> {
    return IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation_Parameters {
}
export class
    IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation_ extends
        Serializable {
  constructor(
      parameters:
          IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation_Parameters =
              {}) {
    super();
  }

  getConstructor(): SerializableCtor<
      IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation_> {
    return IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface IntentMessageRbmSuggestedAction_Parameters {
  text?: string|null;
  postbackData?: string|null;
  dial?: IntentMessageRbmSuggestedActionRbmSuggestedActionDial_|null;
  openUrl?: IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri_|null;
  shareLocation?:
      IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation_|null;
}
export class IntentMessageRbmSuggestedAction_ extends Serializable {
  constructor(parameters: IntentMessageRbmSuggestedAction_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'postbackData',
        (parameters.postbackData == null) ? (null) : (parameters.postbackData));
    this.Serializable$set(
        'dial', (parameters.dial == null) ? (null) : (parameters.dial));
    this.Serializable$set(
        'openUrl',
        (parameters.openUrl == null) ? (null) : (parameters.openUrl));
    this.Serializable$set(
        'shareLocation',
        (parameters.shareLocation == null) ? (null) :
                                             (parameters.shareLocation));
  }

  get dial(): IntentMessageRbmSuggestedActionRbmSuggestedActionDial_|null {
    return (
        (this.Serializable$has('dial')) ? (this.Serializable$get('dial')) :
                                          (null));
  }

  /**
   * Suggested client side action: Dial a phone number
   */
  set dial(value: IntentMessageRbmSuggestedActionRbmSuggestedActionDial_|null) {
    this.Serializable$set('dial', value);
  }

  get openUrl(): IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri_
      |null {
    return (
        (this.Serializable$has('openUrl')) ?
            (this.Serializable$get('openUrl')) :
            (null));
  }

  /**
   * Suggested client side action: Open a URI on device
   */
  set openUrl(value: IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri_|
              null) {
    this.Serializable$set('openUrl', value);
  }

  get postbackData(): string|null {
    return (
        (this.Serializable$has('postbackData')) ?
            (this.Serializable$get('postbackData')) :
            (null));
  }

  /**
   * Opaque payload that the Dialogflow receives in a user event when the user
   * taps the suggested action. This data will be also forwarded to webhook to
   * allow performing custom business logic.
   */
  set postbackData(value: string|null) {
    this.Serializable$set('postbackData', value);
  }

  get shareLocation():
      IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation_|null {
    return (
        (this.Serializable$has('shareLocation')) ?
            (this.Serializable$get('shareLocation')) :
            (null));
  }

  /**
   * Suggested client side action: Share user location
   */
  set shareLocation(
      value: IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation_|
      null) {
    this.Serializable$set('shareLocation', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Text to display alongside the action.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<IntentMessageRbmSuggestedAction_> {
    return IntentMessageRbmSuggestedAction_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['dial', 'openUrl', 'postbackData', 'shareLocation', 'text'],
      objects: {
        'dial': IntentMessageRbmSuggestedActionRbmSuggestedActionDial_,
        'openUrl': IntentMessageRbmSuggestedActionRbmSuggestedActionOpenUri_,
        'shareLocation':
            IntentMessageRbmSuggestedActionRbmSuggestedActionShareLocation_
      }
    };
  }
}

export interface IntentMessageRbmSuggestedReply_Parameters {
  text?: string|null;
  postbackData?: string|null;
}
export class IntentMessageRbmSuggestedReply_ extends Serializable {
  constructor(parameters: IntentMessageRbmSuggestedReply_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'postbackData',
        (parameters.postbackData == null) ? (null) : (parameters.postbackData));
  }

  get postbackData(): string|null {
    return (
        (this.Serializable$has('postbackData')) ?
            (this.Serializable$get('postbackData')) :
            (null));
  }

  /**
   * Opaque payload that the Dialogflow receives in a user event when the user
   * taps the suggested reply. This data will be also forwarded to webhook to
   * allow performing custom business logic.
   */
  set postbackData(value: string|null) {
    this.Serializable$set('postbackData', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Suggested reply text.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<IntentMessageRbmSuggestedReply_> {
    return IntentMessageRbmSuggestedReply_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['postbackData', 'text']};
  }
}

export interface IntentMessageRbmSuggestion_Parameters {
  reply?: IntentMessageRbmSuggestedReply_|null;
  action?: IntentMessageRbmSuggestedAction_|null;
}
export class IntentMessageRbmSuggestion_ extends Serializable {
  constructor(parameters: IntentMessageRbmSuggestion_Parameters = {}) {
    super();
    this.Serializable$set(
        'reply', (parameters.reply == null) ? (null) : (parameters.reply));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
  }

  get action(): IntentMessageRbmSuggestedAction_|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Predefined client side actions that user can choose
   */
  set action(value: IntentMessageRbmSuggestedAction_|null) {
    this.Serializable$set('action', value);
  }

  get reply(): IntentMessageRbmSuggestedReply_|null {
    return (
        (this.Serializable$has('reply')) ? (this.Serializable$get('reply')) :
                                           (null));
  }

  /**
   * Predefined replies for user to select instead of typing
   */
  set reply(value: IntentMessageRbmSuggestedReply_|null) {
    this.Serializable$set('reply', value);
  }

  getConstructor(): SerializableCtor<IntentMessageRbmSuggestion_> {
    return IntentMessageRbmSuggestion_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['action', 'reply'],
      objects: {
        'action': IntentMessageRbmSuggestedAction_,
        'reply': IntentMessageRbmSuggestedReply_
      }
    };
  }
}

export interface IntentMessageRbmText_Parameters {
  text?: string|null;
  rbmSuggestion?: Array<IntentMessageRbmSuggestion_>|null;
}
export class IntentMessageRbmText_ extends Serializable {
  constructor(parameters: IntentMessageRbmText_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'rbmSuggestion',
        (parameters.rbmSuggestion == null) ? (null) :
                                             (parameters.rbmSuggestion));
  }

  get rbmSuggestion(): Array<IntentMessageRbmSuggestion_>|null {
    return (
        (this.Serializable$has('rbmSuggestion')) ?
            (this.Serializable$get('rbmSuggestion')) :
            (null));
  }

  /**
   * Optional. One or more suggestions to show to the user.
   */
  set rbmSuggestion(value: Array<IntentMessageRbmSuggestion_>|null) {
    this.Serializable$set('rbmSuggestion', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. Text sent and displayed to the user.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<IntentMessageRbmText_> {
    return IntentMessageRbmText_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'rbmSuggestion': IntentMessageRbmSuggestion_},
      keys: ['rbmSuggestion', 'text']
    };
  }
}

export interface IntentMessageSelectItemInfo_Parameters {
  key?: string|null;
  synonyms?: Array<string>|null;
}
export class IntentMessageSelectItemInfo_ extends Serializable {
  constructor(parameters: IntentMessageSelectItemInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'key', (parameters.key == null) ? (null) : (parameters.key));
    this.Serializable$set(
        'synonyms',
        (parameters.synonyms == null) ? (null) : (parameters.synonyms));
  }

  get key(): string|null {
    return (
        (this.Serializable$has('key')) ? (this.Serializable$get('key')) :
                                         (null));
  }

  /**
   * Required. A unique key that will be sent back to the agent if this response
   * is given.
   */
  set key(value: string|null) {
    this.Serializable$set('key', value);
  }

  get synonyms(): Array<string>|null {
    return (
        (this.Serializable$has('synonyms')) ?
            (this.Serializable$get('synonyms')) :
            (null));
  }

  /**
   * Optional. A list of synonyms that can also be used to trigger this item in
   * dialog.
   */
  set synonyms(value: Array<string>|null) {
    this.Serializable$set('synonyms', value);
  }

  getConstructor(): SerializableCtor<IntentMessageSelectItemInfo_> {
    return IntentMessageSelectItemInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['key', 'synonyms']};
  }
}

export interface IntentMessageSimpleResponse_Parameters {
  textToSpeech?: string|null;
  ssml?: string|null;
  displayText?: string|null;
}
export class IntentMessageSimpleResponse_ extends Serializable {
  constructor(parameters: IntentMessageSimpleResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'textToSpeech',
        (parameters.textToSpeech == null) ? (null) : (parameters.textToSpeech));
    this.Serializable$set(
        'ssml', (parameters.ssml == null) ? (null) : (parameters.ssml));
    this.Serializable$set(
        'displayText',
        (parameters.displayText == null) ? (null) : (parameters.displayText));
  }

  get displayText(): string|null {
    return (
        (this.Serializable$has('displayText')) ?
            (this.Serializable$get('displayText')) :
            (null));
  }

  /**
   * Optional. The text to display.
   */
  set displayText(value: string|null) {
    this.Serializable$set('displayText', value);
  }

  get ssml(): string|null {
    return (
        (this.Serializable$has('ssml')) ? (this.Serializable$get('ssml')) :
                                          (null));
  }

  /**
   * One of text_to_speech or ssml must be provided. Structured spoken response
   * to the user in the SSML format. Mutually exclusive with text_to_speech.
   */
  set ssml(value: string|null) {
    this.Serializable$set('ssml', value);
  }

  get textToSpeech(): string|null {
    return (
        (this.Serializable$has('textToSpeech')) ?
            (this.Serializable$get('textToSpeech')) :
            (null));
  }

  /**
   * One of text_to_speech or ssml must be provided. The plain text of the
   * speech output. Mutually exclusive with ssml.
   */
  set textToSpeech(value: string|null) {
    this.Serializable$set('textToSpeech', value);
  }

  getConstructor(): SerializableCtor<IntentMessageSimpleResponse_> {
    return IntentMessageSimpleResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayText', 'ssml', 'textToSpeech']};
  }
}

export interface IntentMessageSimpleResponses_Parameters {
  simpleResponses?: Array<IntentMessageSimpleResponse_>|null;
}
export class IntentMessageSimpleResponses_ extends Serializable {
  constructor(parameters: IntentMessageSimpleResponses_Parameters = {}) {
    super();
    this.Serializable$set(
        'simpleResponses',
        (parameters.simpleResponses == null) ? (null) :
                                               (parameters.simpleResponses));
  }

  get simpleResponses(): Array<IntentMessageSimpleResponse_>|null {
    return (
        (this.Serializable$has('simpleResponses')) ?
            (this.Serializable$get('simpleResponses')) :
            (null));
  }

  /**
   * Required. The list of simple responses.
   */
  set simpleResponses(value: Array<IntentMessageSimpleResponse_>|null) {
    this.Serializable$set('simpleResponses', value);
  }

  getConstructor(): SerializableCtor<IntentMessageSimpleResponses_> {
    return IntentMessageSimpleResponses_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'simpleResponses': IntentMessageSimpleResponse_},
      keys: ['simpleResponses']
    };
  }
}

export interface IntentMessageSuggestion_Parameters {
  title?: string|null;
}
export class IntentMessageSuggestion_ extends Serializable {
  constructor(parameters: IntentMessageSuggestion_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. The text shown the in the suggestion chip.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<IntentMessageSuggestion_> {
    return IntentMessageSuggestion_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['title']};
  }
}

export interface IntentMessageSuggestions_Parameters {
  suggestions?: Array<IntentMessageSuggestion_>|null;
}
export class IntentMessageSuggestions_ extends Serializable {
  constructor(parameters: IntentMessageSuggestions_Parameters = {}) {
    super();
    this.Serializable$set(
        'suggestions',
        (parameters.suggestions == null) ? (null) : (parameters.suggestions));
  }

  get suggestions(): Array<IntentMessageSuggestion_>|null {
    return (
        (this.Serializable$has('suggestions')) ?
            (this.Serializable$get('suggestions')) :
            (null));
  }

  /**
   * Required. The list of suggested replies.
   */
  set suggestions(value: Array<IntentMessageSuggestion_>|null) {
    this.Serializable$set('suggestions', value);
  }

  getConstructor(): SerializableCtor<IntentMessageSuggestions_> {
    return IntentMessageSuggestions_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'suggestions': IntentMessageSuggestion_},
      keys: ['suggestions']
    };
  }
}

export interface IntentMessageTableCardCell_Parameters {
  text?: string|null;
}
export class IntentMessageTableCardCell_ extends Serializable {
  constructor(parameters: IntentMessageTableCardCell_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. Text in this cell.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<IntentMessageTableCardCell_> {
    return IntentMessageTableCardCell_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['text']};
  }
}

export interface IntentMessageTableCardRow_Parameters {
  cells?: Array<IntentMessageTableCardCell_>|null;
  dividerAfter?: boolean|null;
}
export class IntentMessageTableCardRow_ extends Serializable {
  constructor(parameters: IntentMessageTableCardRow_Parameters = {}) {
    super();
    this.Serializable$set(
        'cells', (parameters.cells == null) ? (null) : (parameters.cells));
    this.Serializable$set(
        'dividerAfter',
        (parameters.dividerAfter == null) ? (null) : (parameters.dividerAfter));
  }

  get cells(): Array<IntentMessageTableCardCell_>|null {
    return (
        (this.Serializable$has('cells')) ? (this.Serializable$get('cells')) :
                                           (null));
  }

  /**
   * Optional. List of cells that make up this row.
   */
  set cells(value: Array<IntentMessageTableCardCell_>|null) {
    this.Serializable$set('cells', value);
  }

  get dividerAfter(): boolean|null {
    return (
        (this.Serializable$has('dividerAfter')) ?
            (this.Serializable$get('dividerAfter')) :
            (null));
  }

  /**
   * Optional. Whether to add a visual divider after this row.
   */
  set dividerAfter(value: boolean|null) {
    this.Serializable$set('dividerAfter', value);
  }

  getConstructor(): SerializableCtor<IntentMessageTableCardRow_> {
    return IntentMessageTableCardRow_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'cells': IntentMessageTableCardCell_},
      keys: ['cells', 'dividerAfter']
    };
  }
}

export interface IntentMessageTableCard_Parameters {
  title?: string|null;
  subtitle?: string|null;
  image?: IntentMessageImage_|null;
  columnProperties?: Array<IntentMessageColumnProperties_>|null;
  rows?: Array<IntentMessageTableCardRow_>|null;
  buttons?: Array<IntentMessageBasicCardButton_>|null;
}
export class IntentMessageTableCard_ extends Serializable {
  constructor(parameters: IntentMessageTableCard_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'subtitle',
        (parameters.subtitle == null) ? (null) : (parameters.subtitle));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
    this.Serializable$set(
        'columnProperties',
        (parameters.columnProperties == null) ? (null) :
                                                (parameters.columnProperties));
    this.Serializable$set(
        'rows', (parameters.rows == null) ? (null) : (parameters.rows));
    this.Serializable$set(
        'buttons',
        (parameters.buttons == null) ? (null) : (parameters.buttons));
  }

  get buttons(): Array<IntentMessageBasicCardButton_>|null {
    return (
        (this.Serializable$has('buttons')) ?
            (this.Serializable$get('buttons')) :
            (null));
  }

  /**
   * Optional. List of buttons for the card.
   */
  set buttons(value: Array<IntentMessageBasicCardButton_>|null) {
    this.Serializable$set('buttons', value);
  }

  get columnProperties(): Array<IntentMessageColumnProperties_>|null {
    return (
        (this.Serializable$has('columnProperties')) ?
            (this.Serializable$get('columnProperties')) :
            (null));
  }

  /**
   * Optional. Display properties for the columns in this table.
   */
  set columnProperties(value: Array<IntentMessageColumnProperties_>|null) {
    this.Serializable$set('columnProperties', value);
  }

  get image(): IntentMessageImage_|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Optional. Image which should be displayed on the card.
   */
  set image(value: IntentMessageImage_|null) {
    this.Serializable$set('image', value);
  }

  get rows(): Array<IntentMessageTableCardRow_>|null {
    return (
        (this.Serializable$has('rows')) ? (this.Serializable$get('rows')) :
                                          (null));
  }

  /**
   * Optional. Rows in this table of data.
   */
  set rows(value: Array<IntentMessageTableCardRow_>|null) {
    this.Serializable$set('rows', value);
  }

  get subtitle(): string|null {
    return (
        (this.Serializable$has('subtitle')) ?
            (this.Serializable$get('subtitle')) :
            (null));
  }

  /**
   * Optional. Subtitle to the title.
   */
  set subtitle(value: string|null) {
    this.Serializable$set('subtitle', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Required. Title of the card.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  getConstructor(): SerializableCtor<IntentMessageTableCard_> {
    return IntentMessageTableCard_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'buttons': IntentMessageBasicCardButton_,
        'columnProperties': IntentMessageColumnProperties_,
        'rows': IntentMessageTableCardRow_
      },
      keys:
          ['buttons', 'columnProperties', 'image', 'rows', 'subtitle', 'title'],
      objects: {'image': IntentMessageImage_}
    };
  }
}

export interface IntentMessageTelephonyAudio_Parameters {
  segments?: Array<string>|null;
}
export class IntentMessageTelephonyAudio_ extends Serializable {
  constructor(parameters: IntentMessageTelephonyAudio_Parameters = {}) {
    super();
    this.Serializable$set(
        'segments',
        (parameters.segments == null) ? (null) : (parameters.segments));
  }

  get segments(): Array<string>|null {
    return (
        (this.Serializable$has('segments')) ?
            (this.Serializable$get('segments')) :
            (null));
  }

  /**
   * Segments of audio.
   */
  set segments(value: Array<string>|null) {
    this.Serializable$set('segments', value);
  }

  getConstructor(): SerializableCtor<IntentMessageTelephonyAudio_> {
    return IntentMessageTelephonyAudio_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['segments']};
  }
}

export interface IntentMessageTelephonyPlayAudio_Parameters {
  audioUri?: string|null;
}
export class IntentMessageTelephonyPlayAudio_ extends Serializable {
  constructor(parameters: IntentMessageTelephonyPlayAudio_Parameters = {}) {
    super();
    this.Serializable$set(
        'audioUri',
        (parameters.audioUri == null) ? (null) : (parameters.audioUri));
  }

  get audioUri(): string|null {
    return (
        (this.Serializable$has('audioUri')) ?
            (this.Serializable$get('audioUri')) :
            (null));
  }

  /**
   * Required. URI to a Google Cloud Storage object containing the audio to
   * play, e.g., \"gs://bucket/object\". The object must contain a single
   * channel (mono) of linear PCM audio (2 bytes / sample) at 8kHz. This object
   * must be readable by the
   * `service-@gcp-sa-dialogflow.iam.gserviceaccount.com` service account where
   * is the number of the Telephony Gateway project (usually the same as the
   * Dialogflow agent project). If the Google Cloud Storage bucket is in the
   * Telephony Gateway project, this permission is added by default when
   * enabling the Dialogflow V2 API. For audio from other sources, consider
   * using the `TelephonySynthesizeSpeech` message with SSML.
   */
  set audioUri(value: string|null) {
    this.Serializable$set('audioUri', value);
  }

  getConstructor(): SerializableCtor<IntentMessageTelephonyPlayAudio_> {
    return IntentMessageTelephonyPlayAudio_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audioUri']};
  }
}

export interface IntentMessageTelephonyReadDtmf_Parameters {
  maxDuration?: string|null;
  maxDigits?: number|null;
  finishDigit?: IntentMessageTelephonyReadDtmf_FinishDigit|null;
  listenToSpeech?: boolean|null;
}
export class IntentMessageTelephonyReadDtmf_ extends Serializable {
  constructor(parameters: IntentMessageTelephonyReadDtmf_Parameters = {}) {
    super();
    this.Serializable$set(
        'maxDuration',
        (parameters.maxDuration == null) ? (null) : (parameters.maxDuration));
    this.Serializable$set(
        'maxDigits',
        (parameters.maxDigits == null) ? (null) : (parameters.maxDigits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
    this.Serializable$set(
        'listenToSpeech',
        (parameters.listenToSpeech == null) ? (null) :
                                              (parameters.listenToSpeech));
  }

  static get FinishDigit(): IIntentMessageTelephonyReadDtmf_FinishDigitEnum {
    return IntentMessageTelephonyReadDtmf_FinishDigitEnum;
  }

  get finishDigit(): IntentMessageTelephonyReadDtmf_FinishDigit|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * Optional. DTMF digit that finishes DTMF collection even if `max_duration`
   * hasn't passed. If unspecified, we don't finish on any specific digit. The
   * digits in the event will **not** contain the finishing digit.
   */
  set finishDigit(value: IntentMessageTelephonyReadDtmf_FinishDigit|null) {
    this.Serializable$set('finishDigit', value);
  }

  get listenToSpeech(): boolean|null {
    return (
        (this.Serializable$has('listenToSpeech')) ?
            (this.Serializable$get('listenToSpeech')) :
            (null));
  }

  /**
   * Optional. Whether to continue listening to speech while reading DTMF
   * digits. If unspecified or false, we don't listen to speech. If `true` and
   * we recognize a Speech utterance before reading DTMF finishes, we respond to
   * this utterance and discard DTMF results.
   */
  set listenToSpeech(value: boolean|null) {
    this.Serializable$set('listenToSpeech', value);
  }

  get maxDigits(): number|null {
    return (
        (this.Serializable$has('maxDigits')) ?
            (this.Serializable$get('maxDigits')) :
            (null));
  }

  /**
   * Optional. Maximum number of DTMF digits to read. When we get `max_digits`
   * DTMF digits, we stop even if `max_duration` hasn't passed. If unspecified
   * or nonpositive, we don't limit the number of digits.
   */
  set maxDigits(value: number|null) {
    this.Serializable$set('maxDigits', value);
  }

  get maxDuration(): string|null {
    return (
        (this.Serializable$has('maxDuration')) ?
            (this.Serializable$get('maxDuration')) :
            (null));
  }

  /**
   * Required. Maximum duration to read DTMF digits for. If this duration is
   * below 5s, we use 5s. If this duration is above 30s, we use 30s.
   */
  set maxDuration(value: string|null) {
    this.Serializable$set('maxDuration', value);
  }

  getConstructor(): SerializableCtor<IntentMessageTelephonyReadDtmf_> {
    return IntentMessageTelephonyReadDtmf_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'finishDigit': IntentMessageTelephonyReadDtmf_FinishDigitEnum},
      keys: ['finishDigit', 'listenToSpeech', 'maxDigits', 'maxDuration']
    };
  }
}

export interface IntentMessageTelephonySynthesizeSpeech_Parameters {
  text?: string|null;
  ssml?: string|null;
}
export class IntentMessageTelephonySynthesizeSpeech_ extends Serializable {
  constructor(
      parameters: IntentMessageTelephonySynthesizeSpeech_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'ssml', (parameters.ssml == null) ? (null) : (parameters.ssml));
  }

  get ssml(): string|null {
    return (
        (this.Serializable$has('ssml')) ? (this.Serializable$get('ssml')) :
                                          (null));
  }

  /**
   * The SSML to be synthesized. For more information, see
   * [SSML](https://developers.google.com/actions/reference/ssml).
   */
  set ssml(value: string|null) {
    this.Serializable$set('ssml', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The raw text to be synthesized.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<IntentMessageTelephonySynthesizeSpeech_> {
    return IntentMessageTelephonySynthesizeSpeech_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['ssml', 'text']};
  }
}

export interface IntentMessageTelephonyTerminateCall_Parameters {}
export class IntentMessageTelephonyTerminateCall_ extends Serializable {
  constructor(parameters: IntentMessageTelephonyTerminateCall_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<IntentMessageTelephonyTerminateCall_> {
    return IntentMessageTelephonyTerminateCall_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface IntentMessageTelephonyTransferCall_Parameters {
  phoneNumber?: string|null;
}
export class IntentMessageTelephonyTransferCall_ extends Serializable {
  constructor(parameters: IntentMessageTelephonyTransferCall_Parameters = {}) {
    super();
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
  }

  get phoneNumber(): string|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Required. The phone number to transfer the call to in [E.164
   * format](https://en.wikipedia.org/wiki/E.164). We currently only allow
   * transferring to US numbers (+1xxxyyyzzzz).
   */
  set phoneNumber(value: string|null) {
    this.Serializable$set('phoneNumber', value);
  }

  getConstructor(): SerializableCtor<IntentMessageTelephonyTransferCall_> {
    return IntentMessageTelephonyTransferCall_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['phoneNumber']};
  }
}

export interface IntentMessageText_Parameters {
  text?: Array<string>|null;
}
export class IntentMessageText_ extends Serializable {
  constructor(parameters: IntentMessageText_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
  }

  get text(): Array<string>|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Optional. The collection of the agent's responses.
   */
  set text(value: Array<string>|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<IntentMessageText_> {
    return IntentMessageText_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['text']};
  }
}

export interface IntentMessage_Parameters {
  text?: IntentMessageText_|null;
  image?: IntentMessageImage_|null;
  quickReplies?: IntentMessageQuickReplies_|null;
  card?: IntentMessageCard_|null;
  payload?: ApiClientObjectMap<any>|null;
  simpleResponses?: IntentMessageSimpleResponses_|null;
  basicCard?: IntentMessageBasicCard_|null;
  suggestions?: IntentMessageSuggestions_|null;
  linkOutSuggestion?: IntentMessageLinkOutSuggestion_|null;
  listSelect?: IntentMessageListSelect_|null;
  carouselSelect?: IntentMessageCarouselSelect_|null;
  telephonyPlayAudio?: IntentMessageTelephonyPlayAudio_|null;
  telephonySynthesizeSpeech?: IntentMessageTelephonySynthesizeSpeech_|null;
  telephonyTransferCall?: IntentMessageTelephonyTransferCall_|null;
  telephonyTerminateCall?: IntentMessageTelephonyTerminateCall_|null;
  telephonyReadDtmf?: IntentMessageTelephonyReadDtmf_|null;
  telephonyAudio?: IntentMessageTelephonyAudio_|null;
  avayaPlayAudio?: IntentMessageAvayaPlayAudio_|null;
  rbmText?: IntentMessageRbmText_|null;
  rbmStandaloneRichCard?: IntentMessageRbmStandaloneCard_|null;
  rbmCarouselRichCard?: IntentMessageRbmCarouselCard_|null;
  browseCarouselCard?: IntentMessageBrowseCarouselCard_|null;
  tableCard?: IntentMessageTableCard_|null;
  mediaContent?: IntentMessageMediaContent_|null;
  condition?: string|null;
  platform?: IntentMessage_Platform|null;
}
export class IntentMessage_ extends Serializable {
  constructor(parameters: IntentMessage_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'image', (parameters.image == null) ? (null) : (parameters.image));
    this.Serializable$set(
        'quickReplies',
        (parameters.quickReplies == null) ? (null) : (parameters.quickReplies));
    this.Serializable$set(
        'card', (parameters.card == null) ? (null) : (parameters.card));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'simpleResponses',
        (parameters.simpleResponses == null) ? (null) :
                                               (parameters.simpleResponses));
    this.Serializable$set(
        'basicCard',
        (parameters.basicCard == null) ? (null) : (parameters.basicCard));
    this.Serializable$set(
        'suggestions',
        (parameters.suggestions == null) ? (null) : (parameters.suggestions));
    this.Serializable$set(
        'linkOutSuggestion',
        (parameters.linkOutSuggestion == null) ?
            (null) :
            (parameters.linkOutSuggestion));
    this.Serializable$set(
        'listSelect',
        (parameters.listSelect == null) ? (null) : (parameters.listSelect));
    this.Serializable$set(
        'carouselSelect',
        (parameters.carouselSelect == null) ? (null) :
                                              (parameters.carouselSelect));
    this.Serializable$set(
        'telephonyPlayAudio',
        (parameters.telephonyPlayAudio == null) ?
            (null) :
            (parameters.telephonyPlayAudio));
    this.Serializable$set(
        'telephonySynthesizeSpeech',
        (parameters.telephonySynthesizeSpeech == null) ?
            (null) :
            (parameters.telephonySynthesizeSpeech));
    this.Serializable$set(
        'telephonyTransferCall',
        (parameters.telephonyTransferCall == null) ?
            (null) :
            (parameters.telephonyTransferCall));
    this.Serializable$set(
        'telephonyTerminateCall',
        (parameters.telephonyTerminateCall == null) ?
            (null) :
            (parameters.telephonyTerminateCall));
    this.Serializable$set(
        'telephonyReadDtmf',
        (parameters.telephonyReadDtmf == null) ?
            (null) :
            (parameters.telephonyReadDtmf));
    this.Serializable$set(
        'telephonyAudio',
        (parameters.telephonyAudio == null) ? (null) :
                                              (parameters.telephonyAudio));
    this.Serializable$set(
        'avayaPlayAudio',
        (parameters.avayaPlayAudio == null) ? (null) :
                                              (parameters.avayaPlayAudio));
    this.Serializable$set(
        'rbmText',
        (parameters.rbmText == null) ? (null) : (parameters.rbmText));
    this.Serializable$set(
        'rbmStandaloneRichCard',
        (parameters.rbmStandaloneRichCard == null) ?
            (null) :
            (parameters.rbmStandaloneRichCard));
    this.Serializable$set(
        'rbmCarouselRichCard',
        (parameters.rbmCarouselRichCard == null) ?
            (null) :
            (parameters.rbmCarouselRichCard));
    this.Serializable$set(
        'browseCarouselCard',
        (parameters.browseCarouselCard == null) ?
            (null) :
            (parameters.browseCarouselCard));
    this.Serializable$set(
        'tableCard',
        (parameters.tableCard == null) ? (null) : (parameters.tableCard));
    this.Serializable$set(
        'mediaContent',
        (parameters.mediaContent == null) ? (null) : (parameters.mediaContent));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'platform',
        (parameters.platform == null) ? (null) : (parameters.platform));
  }

  static get Platform(): IIntentMessage_PlatformEnum {
    return IntentMessage_PlatformEnum;
  }

  get avayaPlayAudio(): IntentMessageAvayaPlayAudio_|null {
    return (
        (this.Serializable$has('avayaPlayAudio')) ?
            (this.Serializable$get('avayaPlayAudio')) :
            (null));
  }

  /**
   * Plays audio from a file in Avaya Browser.
   */
  set avayaPlayAudio(value: IntentMessageAvayaPlayAudio_|null) {
    this.Serializable$set('avayaPlayAudio', value);
  }

  get basicCard(): IntentMessageBasicCard_|null {
    return (
        (this.Serializable$has('basicCard')) ?
            (this.Serializable$get('basicCard')) :
            (null));
  }

  /**
   * Displays a basic card for Actions on Google.
   */
  set basicCard(value: IntentMessageBasicCard_|null) {
    this.Serializable$set('basicCard', value);
  }

  get browseCarouselCard(): IntentMessageBrowseCarouselCard_|null {
    return (
        (this.Serializable$has('browseCarouselCard')) ?
            (this.Serializable$get('browseCarouselCard')) :
            (null));
  }

  /**
   * Browse carousel card for Actions on Google.
   */
  set browseCarouselCard(value: IntentMessageBrowseCarouselCard_|null) {
    this.Serializable$set('browseCarouselCard', value);
  }

  get card(): IntentMessageCard_|null {
    return (
        (this.Serializable$has('card')) ? (this.Serializable$get('card')) :
                                          (null));
  }

  /**
   * Displays a card.
   */
  set card(value: IntentMessageCard_|null) {
    this.Serializable$set('card', value);
  }

  get carouselSelect(): IntentMessageCarouselSelect_|null {
    return (
        (this.Serializable$has('carouselSelect')) ?
            (this.Serializable$get('carouselSelect')) :
            (null));
  }

  /**
   * Displays a carousel card for Actions on Google.
   */
  set carouselSelect(value: IntentMessageCarouselSelect_|null) {
    this.Serializable$set('carouselSelect', value);
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * Optional. The condition used to activate the message. This is a boolean
   * logical expression. If it is true, the message is selected. It can refer to
   * context parameters in the form of `#my-context-name.params.my-param-name`.
   * See the [conditions
   * reference](https://cloud.google.com/dialogflow/priv/docs/v3/condition). By
   * default, the condition is true and the message is activated.
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get image(): IntentMessageImage_|null {
    return (
        (this.Serializable$has('image')) ? (this.Serializable$get('image')) :
                                           (null));
  }

  /**
   * Displays an image.
   */
  set image(value: IntentMessageImage_|null) {
    this.Serializable$set('image', value);
  }

  get linkOutSuggestion(): IntentMessageLinkOutSuggestion_|null {
    return (
        (this.Serializable$has('linkOutSuggestion')) ?
            (this.Serializable$get('linkOutSuggestion')) :
            (null));
  }

  /**
   * Displays a link out suggestion chip for Actions on Google.
   */
  set linkOutSuggestion(value: IntentMessageLinkOutSuggestion_|null) {
    this.Serializable$set('linkOutSuggestion', value);
  }

  get listSelect(): IntentMessageListSelect_|null {
    return (
        (this.Serializable$has('listSelect')) ?
            (this.Serializable$get('listSelect')) :
            (null));
  }

  /**
   * Displays a list card for Actions on Google.
   */
  set listSelect(value: IntentMessageListSelect_|null) {
    this.Serializable$set('listSelect', value);
  }

  get mediaContent(): IntentMessageMediaContent_|null {
    return (
        (this.Serializable$has('mediaContent')) ?
            (this.Serializable$get('mediaContent')) :
            (null));
  }

  /**
   * The media content card for Actions on Google.
   */
  set mediaContent(value: IntentMessageMediaContent_|null) {
    this.Serializable$set('mediaContent', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * A custom platform-specific response.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get platform(): IntentMessage_Platform|null {
    return (
        (this.Serializable$has('platform')) ?
            (this.Serializable$get('platform')) :
            (null));
  }

  /**
   * Optional. The platform that this message is intended for.
   */
  set platform(value: IntentMessage_Platform|null) {
    this.Serializable$set('platform', value);
  }

  get quickReplies(): IntentMessageQuickReplies_|null {
    return (
        (this.Serializable$has('quickReplies')) ?
            (this.Serializable$get('quickReplies')) :
            (null));
  }

  /**
   * Displays quick replies.
   */
  set quickReplies(value: IntentMessageQuickReplies_|null) {
    this.Serializable$set('quickReplies', value);
  }

  get rbmCarouselRichCard(): IntentMessageRbmCarouselCard_|null {
    return (
        (this.Serializable$has('rbmCarouselRichCard')) ?
            (this.Serializable$get('rbmCarouselRichCard')) :
            (null));
  }

  /**
   * Rich Business Messaging (RBM) carousel rich card response.
   */
  set rbmCarouselRichCard(value: IntentMessageRbmCarouselCard_|null) {
    this.Serializable$set('rbmCarouselRichCard', value);
  }

  get rbmStandaloneRichCard(): IntentMessageRbmStandaloneCard_|null {
    return (
        (this.Serializable$has('rbmStandaloneRichCard')) ?
            (this.Serializable$get('rbmStandaloneRichCard')) :
            (null));
  }

  /**
   * Standalone Rich Business Messaging (RBM) rich card response.
   */
  set rbmStandaloneRichCard(value: IntentMessageRbmStandaloneCard_|null) {
    this.Serializable$set('rbmStandaloneRichCard', value);
  }

  get rbmText(): IntentMessageRbmText_|null {
    return (
        (this.Serializable$has('rbmText')) ?
            (this.Serializable$get('rbmText')) :
            (null));
  }

  /**
   * Rich Business Messaging (RBM) text response. RBM allows businesses to send
   * enriched and branded versions of SMS. See
   * https://jibe.google.com/business-messaging.
   */
  set rbmText(value: IntentMessageRbmText_|null) {
    this.Serializable$set('rbmText', value);
  }

  get simpleResponses(): IntentMessageSimpleResponses_|null {
    return (
        (this.Serializable$has('simpleResponses')) ?
            (this.Serializable$get('simpleResponses')) :
            (null));
  }

  /**
   * Returns a voice or text-only response for Actions on Google.
   */
  set simpleResponses(value: IntentMessageSimpleResponses_|null) {
    this.Serializable$set('simpleResponses', value);
  }

  get suggestions(): IntentMessageSuggestions_|null {
    return (
        (this.Serializable$has('suggestions')) ?
            (this.Serializable$get('suggestions')) :
            (null));
  }

  /**
   * Displays suggestion chips for Actions on Google.
   */
  set suggestions(value: IntentMessageSuggestions_|null) {
    this.Serializable$set('suggestions', value);
  }

  get tableCard(): IntentMessageTableCard_|null {
    return (
        (this.Serializable$has('tableCard')) ?
            (this.Serializable$get('tableCard')) :
            (null));
  }

  /**
   * Table card for Actions on Google.
   */
  set tableCard(value: IntentMessageTableCard_|null) {
    this.Serializable$set('tableCard', value);
  }

  get telephonyAudio(): IntentMessageTelephonyAudio_|null {
    return (
        (this.Serializable$has('telephonyAudio')) ?
            (this.Serializable$get('telephonyAudio')) :
            (null));
  }

  /**
   * Audio to be played back by phone gateway.
   */
  set telephonyAudio(value: IntentMessageTelephonyAudio_|null) {
    this.Serializable$set('telephonyAudio', value);
  }

  get telephonyPlayAudio(): IntentMessageTelephonyPlayAudio_|null {
    return (
        (this.Serializable$has('telephonyPlayAudio')) ?
            (this.Serializable$get('telephonyPlayAudio')) :
            (null));
  }

  /**
   * Plays audio from a file in Telephony Gateway.
   */
  set telephonyPlayAudio(value: IntentMessageTelephonyPlayAudio_|null) {
    this.Serializable$set('telephonyPlayAudio', value);
  }

  get telephonyReadDtmf(): IntentMessageTelephonyReadDtmf_|null {
    return (
        (this.Serializable$has('telephonyReadDtmf')) ?
            (this.Serializable$get('telephonyReadDtmf')) :
            (null));
  }

  /**
   * Reads DTMF digits in Telephony Gateway.
   */
  set telephonyReadDtmf(value: IntentMessageTelephonyReadDtmf_|null) {
    this.Serializable$set('telephonyReadDtmf', value);
  }

  get telephonySynthesizeSpeech(): IntentMessageTelephonySynthesizeSpeech_
      |null {
    return (
        (this.Serializable$has('telephonySynthesizeSpeech')) ?
            (this.Serializable$get('telephonySynthesizeSpeech')) :
            (null));
  }

  /**
   * Synthesizes speech in Telephony Gateway.
   */
  set telephonySynthesizeSpeech(value: IntentMessageTelephonySynthesizeSpeech_|
                                null) {
    this.Serializable$set('telephonySynthesizeSpeech', value);
  }

  get telephonyTerminateCall(): IntentMessageTelephonyTerminateCall_|null {
    return (
        (this.Serializable$has('telephonyTerminateCall')) ?
            (this.Serializable$get('telephonyTerminateCall')) :
            (null));
  }

  /**
   * Terminates the call in Telephony Gateway.
   */
  set telephonyTerminateCall(value: IntentMessageTelephonyTerminateCall_|null) {
    this.Serializable$set('telephonyTerminateCall', value);
  }

  get telephonyTransferCall(): IntentMessageTelephonyTransferCall_|null {
    return (
        (this.Serializable$has('telephonyTransferCall')) ?
            (this.Serializable$get('telephonyTransferCall')) :
            (null));
  }

  /**
   * Transfers the call in Telephony Gateway.
   */
  set telephonyTransferCall(value: IntentMessageTelephonyTransferCall_|null) {
    this.Serializable$set('telephonyTransferCall', value);
  }

  get text(): IntentMessageText_|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Returns a text response.
   */
  set text(value: IntentMessageText_|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<IntentMessage_> {
    return IntentMessage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'platform': IntentMessage_PlatformEnum},
      keys: [
        'avayaPlayAudio',
        'basicCard',
        'browseCarouselCard',
        'card',
        'carouselSelect',
        'condition',
        'image',
        'linkOutSuggestion',
        'listSelect',
        'mediaContent',
        'payload',
        'platform',
        'quickReplies',
        'rbmCarouselRichCard',
        'rbmStandaloneRichCard',
        'rbmText',
        'simpleResponses',
        'suggestions',
        'tableCard',
        'telephonyAudio',
        'telephonyPlayAudio',
        'telephonyReadDtmf',
        'telephonySynthesizeSpeech',
        'telephonyTerminateCall',
        'telephonyTransferCall',
        'text'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'avayaPlayAudio': IntentMessageAvayaPlayAudio_,
        'basicCard': IntentMessageBasicCard_,
        'browseCarouselCard': IntentMessageBrowseCarouselCard_,
        'card': IntentMessageCard_,
        'carouselSelect': IntentMessageCarouselSelect_,
        'image': IntentMessageImage_,
        'linkOutSuggestion': IntentMessageLinkOutSuggestion_,
        'listSelect': IntentMessageListSelect_,
        'mediaContent': IntentMessageMediaContent_,
        'quickReplies': IntentMessageQuickReplies_,
        'rbmCarouselRichCard': IntentMessageRbmCarouselCard_,
        'rbmStandaloneRichCard': IntentMessageRbmStandaloneCard_,
        'rbmText': IntentMessageRbmText_,
        'simpleResponses': IntentMessageSimpleResponses_,
        'suggestions': IntentMessageSuggestions_,
        'tableCard': IntentMessageTableCard_,
        'telephonyAudio': IntentMessageTelephonyAudio_,
        'telephonyPlayAudio': IntentMessageTelephonyPlayAudio_,
        'telephonyReadDtmf': IntentMessageTelephonyReadDtmf_,
        'telephonySynthesizeSpeech': IntentMessageTelephonySynthesizeSpeech_,
        'telephonyTerminateCall': IntentMessageTelephonyTerminateCall_,
        'telephonyTransferCall': IntentMessageTelephonyTransferCall_,
        'text': IntentMessageText_
      }
    };
  }
}

export interface IntentParameter_Parameters {
  name?: string|null;
  displayName?: string|null;
  value?: string|null;
  defaultValue?: string|null;
  entityTypeDisplayName?: string|null;
  mandatory?: boolean|null;
  prompts?: Array<string>|null;
  promptMessages?: Array<IntentMessage_>|null;
  noMatchPromptMessages?: Array<IntentMessage_>|null;
  noInputPromptMessages?: Array<IntentMessage_>|null;
  isList?: boolean|null;
  outputDialogContexts?: Array<Context_>|null;
  repromptsCount?: number|null;
}
export class IntentParameter_ extends Serializable {
  constructor(parameters: IntentParameter_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
    this.Serializable$set(
        'defaultValue',
        (parameters.defaultValue == null) ? (null) : (parameters.defaultValue));
    this.Serializable$set(
        'entityTypeDisplayName',
        (parameters.entityTypeDisplayName == null) ?
            (null) :
            (parameters.entityTypeDisplayName));
    this.Serializable$set(
        'mandatory',
        (parameters.mandatory == null) ? (null) : (parameters.mandatory));
    this.Serializable$set(
        'prompts',
        (parameters.prompts == null) ? (null) : (parameters.prompts));
    this.Serializable$set(
        'promptMessages',
        (parameters.promptMessages == null) ? (null) :
                                              (parameters.promptMessages));
    this.Serializable$set(
        'noMatchPromptMessages',
        (parameters.noMatchPromptMessages == null) ?
            (null) :
            (parameters.noMatchPromptMessages));
    this.Serializable$set(
        'noInputPromptMessages',
        (parameters.noInputPromptMessages == null) ?
            (null) :
            (parameters.noInputPromptMessages));
    this.Serializable$set(
        'isList', (parameters.isList == null) ? (null) : (parameters.isList));
    this.Serializable$set(
        'outputDialogContexts',
        (parameters.outputDialogContexts == null) ?
            (null) :
            (parameters.outputDialogContexts));
    this.Serializable$set(
        'repromptsCount',
        (parameters.repromptsCount == null) ? (null) :
                                              (parameters.repromptsCount));
  }

  get defaultValue(): string|null {
    return (
        (this.Serializable$has('defaultValue')) ?
            (this.Serializable$get('defaultValue')) :
            (null));
  }

  /**
   * Optional. The default value to use when the `value` yields an empty result.
   * Default values can be extracted from contexts by using the following
   * syntax: `#context_name.parameter_name`.
   */
  set defaultValue(value: string|null) {
    this.Serializable$set('defaultValue', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The name of the parameter.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get entityTypeDisplayName(): string|null {
    return (
        (this.Serializable$has('entityTypeDisplayName')) ?
            (this.Serializable$get('entityTypeDisplayName')) :
            (null));
  }

  /**
   * Optional. The name of the entity type, prefixed with `@`, that describes
   * values of the parameter. If the parameter is required, this must be
   * provided.
   */
  set entityTypeDisplayName(value: string|null) {
    this.Serializable$set('entityTypeDisplayName', value);
  }

  get isList(): boolean|null {
    return (
        (this.Serializable$has('isList')) ? (this.Serializable$get('isList')) :
                                            (null));
  }

  /**
   * Optional. Indicates whether the parameter represents a list of values.
   */
  set isList(value: boolean|null) {
    this.Serializable$set('isList', value);
  }

  get mandatory(): boolean|null {
    return (
        (this.Serializable$has('mandatory')) ?
            (this.Serializable$get('mandatory')) :
            (null));
  }

  /**
   * Optional. Indicates whether the parameter is required. That is, whether the
   * intent cannot be completed without collecting the parameter value.
   */
  set mandatory(value: boolean|null) {
    this.Serializable$set('mandatory', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of this parameter.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get noInputPromptMessages(): Array<IntentMessage_>|null {
    return (
        (this.Serializable$has('noInputPromptMessages')) ?
            (this.Serializable$get('noInputPromptMessages')) :
            (null));
  }

  /**
   * Optional. The collection of rich message prompts that the agent can present
   * to the user when the user didn't answer the previous agent prompt question.
   */
  set noInputPromptMessages(value: Array<IntentMessage_>|null) {
    this.Serializable$set('noInputPromptMessages', value);
  }

  get noMatchPromptMessages(): Array<IntentMessage_>|null {
    return (
        (this.Serializable$has('noMatchPromptMessages')) ?
            (this.Serializable$get('noMatchPromptMessages')) :
            (null));
  }

  /**
   * Optional. The collection of rich message prompts that the agent can present
   * to the user when the user's previous answer didn't match the agent prompt
   * question.
   */
  set noMatchPromptMessages(value: Array<IntentMessage_>|null) {
    this.Serializable$set('noMatchPromptMessages', value);
  }

  get outputDialogContexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('outputDialogContexts')) ?
            (this.Serializable$get('outputDialogContexts')) :
            (null));
  }

  /**
   * Optional. The collection of contexts that are activated during collection
   * of this parameter (slot-filling). By default the lifespan in context should
   * be 1.
   */
  set outputDialogContexts(value: Array<Context_>|null) {
    this.Serializable$set('outputDialogContexts', value);
  }

  get promptMessages(): Array<IntentMessage_>|null {
    return (
        (this.Serializable$has('promptMessages')) ?
            (this.Serializable$get('promptMessages')) :
            (null));
  }

  /**
   * Optional. The collection of rich message prompts that the agent can present
   * to the user in order to collect a value for the parameter.
   */
  set promptMessages(value: Array<IntentMessage_>|null) {
    this.Serializable$set('promptMessages', value);
  }

  get prompts(): Array<string>|null {
    return (
        (this.Serializable$has('prompts')) ?
            (this.Serializable$get('prompts')) :
            (null));
  }

  /**
   * Optional. The collection of prompts that the agent can present to the user
   * in order to collect a value for the parameter.
   */
  set prompts(value: Array<string>|null) {
    this.Serializable$set('prompts', value);
  }

  get repromptsCount(): number|null {
    return (
        (this.Serializable$has('repromptsCount')) ?
            (this.Serializable$get('repromptsCount')) :
            (null));
  }

  /**
   * Optional. Number of reprompts for mandatory parameters. System will go to
   * 'no match' if parameter was not collected in specified number of reprompts.
   * Defaults to '0' that is effectively 'unlimited'.
   */
  set repromptsCount(value: number|null) {
    this.Serializable$set('repromptsCount', value);
  }

  get value(): string|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Optional. The definition of the parameter value. It can be: - a constant
   * string, - a parameter value defined as `$parameter_name`, - an original
   * parameter value defined as `$parameter_name.original`, - a parameter value
   * from some context defined as `#context_name.parameter_name`.
   */
  set value(value: string|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<IntentParameter_> {
    return IntentParameter_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'noInputPromptMessages': IntentMessage_,
        'noMatchPromptMessages': IntentMessage_,
        'outputDialogContexts': Context_,
        'promptMessages': IntentMessage_
      },
      keys: [
        'defaultValue', 'displayName', 'entityTypeDisplayName', 'isList',
        'mandatory', 'name', 'noInputPromptMessages', 'noMatchPromptMessages',
        'outputDialogContexts', 'promptMessages', 'prompts', 'repromptsCount',
        'value'
      ]
    };
  }
}

export interface IntentSuggestion_Parameters {
  displayName?: string|null;
  intentV2?: string|null;
  intentV3?: string|null;
  labels?: ApiClientObjectMap<string>|null;
  description?: string|null;
  confidence?: number|null;
}
export class IntentSuggestion_ extends Serializable {
  constructor(parameters: IntentSuggestion_Parameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'intentV2',
        (parameters.intentV2 == null) ? (null) : (parameters.intentV2));
    this.Serializable$set(
        'intentV3',
        (parameters.intentV3 == null) ? (null) : (parameters.intentV3));
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The confidence of this match. Values range from 0.0 (completely uncertain)
   * to 1.0 (completely certain). This value is internal only and for
   * informational purpose only and is only used to help match the best intent
   * within the classification threshold. This value may change for the same
   * end-user expression at any time due to a model retraining or change in
   * implementation.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Human readable description for better understanding an intent like its
   * scope, content, result etc. Maximum character limit: 140 characters.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * The display name of the intent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get intentV2(): string|null {
    return (
        (this.Serializable$has('intentV2')) ?
            (this.Serializable$get('intentV2')) :
            (null));
  }

  /**
   * The unique identifier of this intent. Format:
   * `projects//locations//agent/intents/`.
   */
  set intentV2(value: string|null) {
    this.Serializable$set('intentV2', value);
  }

  get intentV3(): string|null {
    return (
        (this.Serializable$has('intentV3')) ?
            (this.Serializable$get('intentV3')) :
            (null));
  }

  /**
   * The unique identifier of this intent (for v3). Format:
   * `projects//locations//locations//agents//intents/`.
   */
  set intentV3(value: string|null) {
    this.Serializable$set('intentV3', value);
  }

  get labels(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * The key/value metadata to label an intent. Labels can contain lowercase
   * letters, digits and the symbols '-' and '_'. International characters are
   * allowed, including letters from unicase alphabets. Keys must start with a
   * letter. Keys and values can be no longer than 63 characters and no more
   * than 128 bytes. Prefix \"sys-\" is reserved for Dialogflow defined labels.
   * Currently allowed Dialogflow defined labels include: * sys-head *
   * sys-contextual The above labels do not require value. \"sys-head\" means
   * the intent is a head intent. \"sys-contextual\" means the intent is a
   * contextual intent.
   */
  set labels(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('labels', value);
  }

  getConstructor(): SerializableCtor<IntentSuggestion_> {
    return IntentSuggestion_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'confidence', 'description', 'displayName', 'intentV2', 'intentV3',
        'labels'
      ],
      objectMaps: {
        'labels': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface IntentTrainingPhrasePart_Parameters {
  text?: string|null;
  entityType?: string|null;
  alias?: string|null;
  userDefined?: boolean|null;
}
export class IntentTrainingPhrasePart_ extends Serializable {
  constructor(parameters: IntentTrainingPhrasePart_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'entityType',
        (parameters.entityType == null) ? (null) : (parameters.entityType));
    this.Serializable$set(
        'alias', (parameters.alias == null) ? (null) : (parameters.alias));
    this.Serializable$set(
        'userDefined',
        (parameters.userDefined == null) ? (null) : (parameters.userDefined));
  }

  get alias(): string|null {
    return (
        (this.Serializable$has('alias')) ? (this.Serializable$get('alias')) :
                                           (null));
  }

  /**
   * Optional. The parameter name for the value extracted from the annotated
   * part of the example. This field is required for annotated parts of the
   * training phrase.
   */
  set alias(value: string|null) {
    this.Serializable$set('alias', value);
  }

  get entityType(): string|null {
    return (
        (this.Serializable$has('entityType')) ?
            (this.Serializable$get('entityType')) :
            (null));
  }

  /**
   * Optional. The entity type name prefixed with `@`. This field is required
   * for annotated parts of the training phrase.
   */
  set entityType(value: string|null) {
    this.Serializable$set('entityType', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. The text for this part.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  get userDefined(): boolean|null {
    return (
        (this.Serializable$has('userDefined')) ?
            (this.Serializable$get('userDefined')) :
            (null));
  }

  /**
   * Optional. Indicates whether the text was manually annotated. This field is
   * set to true when the Dialogflow Console is used to manually annotate the
   * part. When creating an annotated part with the API, you must set this to
   * true.
   */
  set userDefined(value: boolean|null) {
    this.Serializable$set('userDefined', value);
  }

  getConstructor(): SerializableCtor<IntentTrainingPhrasePart_> {
    return IntentTrainingPhrasePart_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['alias', 'entityType', 'text', 'userDefined']};
  }
}

export interface IntentTrainingPhrase_Parameters {
  name?: string|null;
  type?: IntentTrainingPhrase_Type|null;
  parts?: Array<IntentTrainingPhrasePart_>|null;
  timesAddedCount?: number|null;
}
export class IntentTrainingPhrase_ extends Serializable {
  constructor(parameters: IntentTrainingPhrase_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'parts', (parameters.parts == null) ? (null) : (parameters.parts));
    this.Serializable$set(
        'timesAddedCount',
        (parameters.timesAddedCount == null) ? (null) :
                                               (parameters.timesAddedCount));
  }

  static get Type(): IIntentTrainingPhrase_TypeEnum {
    return IntentTrainingPhrase_TypeEnum;
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this training phrase.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parts(): Array<IntentTrainingPhrasePart_>|null {
    return (
        (this.Serializable$has('parts')) ? (this.Serializable$get('parts')) :
                                           (null));
  }

  /**
   * Required. The ordered list of training phrase parts. The parts are
   * concatenated in order to form the training phrase. Note: The API does not
   * automatically annotate training phrases like the Dialogflow Console does.
   * Note: Do not forget to include whitespace at part boundaries, so the
   * training phrase is well formatted when the parts are concatenated. If the
   * training phrase does not need to be annotated with parameters, you just
   * need a single part with only the Part.text field set. If you want to
   * annotate the training phrase, you must create multiple parts, where the
   * fields of each part are populated in one of two ways: - `Part.text` is set
   * to a part of the phrase that has no parameters. - `Part.text` is set to a
   * part of the phrase that you want to annotate, and the `entity_type`,
   * `alias`, and `user_defined` fields are all set.
   */
  set parts(value: Array<IntentTrainingPhrasePart_>|null) {
    this.Serializable$set('parts', value);
  }

  get timesAddedCount(): number|null {
    return (
        (this.Serializable$has('timesAddedCount')) ?
            (this.Serializable$get('timesAddedCount')) :
            (null));
  }

  /**
   * Optional. Indicates how many times this example was added to the intent.
   * Each time a developer adds an existing sample by editing an intent or
   * training, this counter is increased.
   */
  set timesAddedCount(value: number|null) {
    this.Serializable$set('timesAddedCount', value);
  }

  get type(): IntentTrainingPhrase_Type|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Required. The type of the training phrase.
   */
  set type(value: IntentTrainingPhrase_Type|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<IntentTrainingPhrase_> {
    return IntentTrainingPhrase_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parts': IntentTrainingPhrasePart_},
      enums: {'type': IntentTrainingPhrase_TypeEnum},
      keys: ['name', 'parts', 'timesAddedCount', 'type']
    };
  }
}

export interface Intent_Parameters {
  name?: string|null;
  displayName?: string|null;
  webhookState?: Intent_WebhookState|null;
  priority?: number|null;
  isFallback?: boolean|null;
  mlEnabled?: boolean|null;
  mlDisabled?: boolean|null;
  liveAgentHandoff?: boolean|null;
  endInteraction?: boolean|null;
  inputContextNames?: Array<string>|null;
  events?: Array<string>|null;
  trainingPhrases?: Array<IntentTrainingPhrase_>|null;
  action?: string|null;
  outputContexts?: Array<Context_>|null;
  resetContexts?: boolean|null;
  parameters?: Array<IntentParameter_>|null;
  messages?: Array<IntentMessage_>|null;
  conditionalMessages?: Array<IntentConditionalMessage_>|null;
  defaultResponsePlatforms?: Array<Intent_DefaultResponsePlatforms>|null;
  rootFollowupIntentName?: string|null;
  parentFollowupIntentName?: string|null;
  followupIntentInfo?: Array<IntentFollowupIntentInfo_>|null;
  isKnowledgeIntent?: boolean|null;
  followupEventInput?: IntentFollowupEventInput_|null;
  maxAsyncFulfillmentDuration?: string|null;
  condition?: string|null;
  conditionalFollowupEventInputs?: Array<IntentConditionalFollowupEventInput_>|
      null;
}
export class Intent_ extends Serializable {
  constructor(parameters: Intent_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'webhookState',
        (parameters.webhookState == null) ? (null) : (parameters.webhookState));
    this.Serializable$set(
        'priority',
        (parameters.priority == null) ? (null) : (parameters.priority));
    this.Serializable$set(
        'isFallback',
        (parameters.isFallback == null) ? (null) : (parameters.isFallback));
    this.Serializable$set(
        'mlEnabled',
        (parameters.mlEnabled == null) ? (null) : (parameters.mlEnabled));
    this.Serializable$set(
        'mlDisabled',
        (parameters.mlDisabled == null) ? (null) : (parameters.mlDisabled));
    this.Serializable$set(
        'liveAgentHandoff',
        (parameters.liveAgentHandoff == null) ? (null) :
                                                (parameters.liveAgentHandoff));
    this.Serializable$set(
        'endInteraction',
        (parameters.endInteraction == null) ? (null) :
                                              (parameters.endInteraction));
    this.Serializable$set(
        'inputContextNames',
        (parameters.inputContextNames == null) ?
            (null) :
            (parameters.inputContextNames));
    this.Serializable$set(
        'events', (parameters.events == null) ? (null) : (parameters.events));
    this.Serializable$set(
        'trainingPhrases',
        (parameters.trainingPhrases == null) ? (null) :
                                               (parameters.trainingPhrases));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'outputContexts',
        (parameters.outputContexts == null) ? (null) :
                                              (parameters.outputContexts));
    this.Serializable$set(
        'resetContexts',
        (parameters.resetContexts == null) ? (null) :
                                             (parameters.resetContexts));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'conditionalMessages',
        (parameters.conditionalMessages == null) ?
            (null) :
            (parameters.conditionalMessages));
    this.Serializable$set(
        'defaultResponsePlatforms',
        (parameters.defaultResponsePlatforms == null) ?
            (null) :
            (parameters.defaultResponsePlatforms));
    this.Serializable$set(
        'rootFollowupIntentName',
        (parameters.rootFollowupIntentName == null) ?
            (null) :
            (parameters.rootFollowupIntentName));
    this.Serializable$set(
        'parentFollowupIntentName',
        (parameters.parentFollowupIntentName == null) ?
            (null) :
            (parameters.parentFollowupIntentName));
    this.Serializable$set(
        'followupIntentInfo',
        (parameters.followupIntentInfo == null) ?
            (null) :
            (parameters.followupIntentInfo));
    this.Serializable$set(
        'isKnowledgeIntent',
        (parameters.isKnowledgeIntent == null) ?
            (null) :
            (parameters.isKnowledgeIntent));
    this.Serializable$set(
        'followupEventInput',
        (parameters.followupEventInput == null) ?
            (null) :
            (parameters.followupEventInput));
    this.Serializable$set(
        'maxAsyncFulfillmentDuration',
        (parameters.maxAsyncFulfillmentDuration == null) ?
            (null) :
            (parameters.maxAsyncFulfillmentDuration));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'conditionalFollowupEventInputs',
        (parameters.conditionalFollowupEventInputs == null) ?
            (null) :
            (parameters.conditionalFollowupEventInputs));
  }

  static get DefaultResponsePlatforms(): IIntent_DefaultResponsePlatformsEnum {
    return Intent_DefaultResponsePlatformsEnum;
  }

  static get WebhookState(): IIntent_WebhookStateEnum {
    return Intent_WebhookStateEnum;
  }

  get action(): string|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Optional. The name of the action associated with the intent. Note: The
   * action name must not contain whitespaces.
   */
  set action(value: string|null) {
    this.Serializable$set('action', value);
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * Optional. The condition used to activate the intent for being matched. This
   * is a boolean logical expression. If it is true, the intent is activated. It
   * can refer to context parameters in the form of
   * `#my-context-name.params.my-param-name`. See the [conditions
   * reference](https://cloud.google.com/dialogflow/priv/docs/v3/condition). By
   * default, the condition is true and the intent is always activated.
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get conditionalFollowupEventInputs():
      Array<IntentConditionalFollowupEventInput_>|null {
    return (
        (this.Serializable$has('conditionalFollowupEventInputs')) ?
            (this.Serializable$get('conditionalFollowupEventInputs')) :
            (null));
  }

  /**
   * Optional. The list of conditional followup event inputs.
   */
  set conditionalFollowupEventInputs(
      value: Array<IntentConditionalFollowupEventInput_>|null) {
    this.Serializable$set('conditionalFollowupEventInputs', value);
  }

  get conditionalMessages(): Array<IntentConditionalMessage_>|null {
    return (
        (this.Serializable$has('conditionalMessages')) ?
            (this.Serializable$get('conditionalMessages')) :
            (null));
  }

  /**
   * Represents a list of conditional messages. Each case in the message is
   * associated with a condition. During query time, Dialogflow will never
   * populate conditional_messages. Instead, Dialogflow will append all messages
   * with conditions that are evaluated to true to messages.
   */
  set conditionalMessages(value: Array<IntentConditionalMessage_>|null) {
    this.Serializable$set('conditionalMessages', value);
  }

  get defaultResponsePlatforms(): Array<Intent_DefaultResponsePlatforms>|null {
    return (
        (this.Serializable$has('defaultResponsePlatforms')) ?
            (this.Serializable$get('defaultResponsePlatforms')) :
            (null));
  }

  /**
   * Optional. The list of platforms for which the first responses will be
   * copied from the messages in PLATFORM_UNSPECIFIED (i.e. default platform).
   */
  set defaultResponsePlatforms(value: Array<Intent_DefaultResponsePlatforms>|
                               null) {
    this.Serializable$set('defaultResponsePlatforms', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The name of this intent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get endInteraction(): boolean|null {
    return (
        (this.Serializable$has('endInteraction')) ?
            (this.Serializable$get('endInteraction')) :
            (null));
  }

  /**
   * Optional. Indicates that this intent ends an interaction. Some integrations
   * (e.g., Actions on Google or Dialogflow phone gateway) use this information
   * to close interaction with an end user. Default is false.
   */
  set endInteraction(value: boolean|null) {
    this.Serializable$set('endInteraction', value);
  }

  get events(): Array<string>|null {
    return (
        (this.Serializable$has('events')) ? (this.Serializable$get('events')) :
                                            (null));
  }

  /**
   * Optional. The collection of event names that trigger the intent. If the
   * collection of input contexts is not empty, all of the contexts must be
   * present in the active user session for an event to trigger this intent.
   * Event names are limited to 150 characters.
   */
  set events(value: Array<string>|null) {
    this.Serializable$set('events', value);
  }

  get followupEventInput(): IntentFollowupEventInput_|null {
    return (
        (this.Serializable$has('followupEventInput')) ?
            (this.Serializable$get('followupEventInput')) :
            (null));
  }

  /**
   * Optional. Makes the platform immediately invoke another `DetectIntent` call
   * internally with the specified event as input.
   */
  set followupEventInput(value: IntentFollowupEventInput_|null) {
    this.Serializable$set('followupEventInput', value);
  }

  get followupIntentInfo(): Array<IntentFollowupIntentInfo_>|null {
    return (
        (this.Serializable$has('followupIntentInfo')) ?
            (this.Serializable$get('followupIntentInfo')) :
            (null));
  }

  /**
   * Output only. Information about all followup intents that have this intent
   * as a direct or indirect parent. We populate this field only in the output.
   */
  set followupIntentInfo(value: Array<IntentFollowupIntentInfo_>|null) {
    this.Serializable$set('followupIntentInfo', value);
  }

  get inputContextNames(): Array<string>|null {
    return (
        (this.Serializable$has('inputContextNames')) ?
            (this.Serializable$get('inputContextNames')) :
            (null));
  }

  /**
   * Optional. The list of context names required for this intent to be
   * triggered. Formats: - `projects//agent/sessions/-/contexts/` -
   * `projects//locations//agent/sessions/-/contexts/`
   */
  set inputContextNames(value: Array<string>|null) {
    this.Serializable$set('inputContextNames', value);
  }

  get isFallback(): boolean|null {
    return (
        (this.Serializable$has('isFallback')) ?
            (this.Serializable$get('isFallback')) :
            (null));
  }

  /**
   * Optional. Indicates whether this is a fallback intent.
   */
  set isFallback(value: boolean|null) {
    this.Serializable$set('isFallback', value);
  }

  get isKnowledgeIntent(): boolean|null {
    return (
        (this.Serializable$has('isKnowledgeIntent')) ?
            (this.Serializable$get('isKnowledgeIntent')) :
            (null));
  }

  /**
   * Indicates whether this is a knowledge intent, only used by DF FE calling DF
   * gRPC BE.
   */
  set isKnowledgeIntent(value: boolean|null) {
    this.Serializable$set('isKnowledgeIntent', value);
  }

  get liveAgentHandoff(): boolean|null {
    return (
        (this.Serializable$has('liveAgentHandoff')) ?
            (this.Serializable$get('liveAgentHandoff')) :
            (null));
  }

  /**
   * Optional. Indicates that a live agent should be brought in to handle the
   * interaction with the user. In most cases, when you set this flag to true,
   * you would also want to set end_interaction to true as well. Default is
   * false.
   */
  set liveAgentHandoff(value: boolean|null) {
    this.Serializable$set('liveAgentHandoff', value);
  }

  get maxAsyncFulfillmentDuration(): string|null {
    return (
        (this.Serializable$has('maxAsyncFulfillmentDuration')) ?
            (this.Serializable$get('maxAsyncFulfillmentDuration')) :
            (null));
  }

  /**
   * Optional. The maximum duration to wait for an asynchronous fulfillment to
   * send/push the response to Dialogflow through
   * AsynchronousFulfillment.PushFulfillmentResult since the time asynchronous
   * fulfillment was invoked.
   */
  set maxAsyncFulfillmentDuration(value: string|null) {
    this.Serializable$set('maxAsyncFulfillmentDuration', value);
  }

  get messages(): Array<IntentMessage_>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * Optional. The collection of rich messages corresponding to the `Response`
   * field in the Dialogflow console.
   */
  set messages(value: Array<IntentMessage_>|null) {
    this.Serializable$set('messages', value);
  }

  get mlDisabled(): boolean|null {
    return (
        (this.Serializable$has('mlDisabled')) ?
            (this.Serializable$get('mlDisabled')) :
            (null));
  }

  /**
   * Optional. Indicates whether Machine Learning is disabled for the intent.
   * Note: If `ml_disabled` setting is set to true, then this intent is not
   * taken into account during inference in `ML ONLY` match mode. Also,
   * auto-markup in the UI is turned off.
   */
  set mlDisabled(value: boolean|null) {
    this.Serializable$set('mlDisabled', value);
  }

  get mlEnabled(): boolean|null {
    return (
        (this.Serializable$has('mlEnabled')) ?
            (this.Serializable$get('mlEnabled')) :
            (null));
  }

  /**
   * Optional. Indicates whether Machine Learning is enabled for the intent.
   * Note: If `ml_enabled` setting is set to false, then this intent is not
   * taken into account during inference in `ML ONLY` match mode. Also,
   * auto-markup in the UI is turned off. DEPRECATED! Please use `ml_disabled`
   * field instead. NOTE: If both `ml_enabled` and `ml_disabled` are either not
   * set or false, then the default value is determined as follows: - Before
   * April 15th, 2018 the default is: ml_enabled = false / ml_disabled = true. -
   * After April 15th, 2018 the default is: ml_enabled = true / ml_disabled =
   * false.
   */
  set mlEnabled(value: boolean|null) {
    this.Serializable$set('mlEnabled', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Optional. The unique identifier of this intent. Required for
   * Intents.UpdateIntent and Intents.BatchUpdateIntents methods. Supported
   * formats: - `projects//agent/intents/` -
   * `projects//locations//agent/intents/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get outputContexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('outputContexts')) ?
            (this.Serializable$get('outputContexts')) :
            (null));
  }

  /**
   * Optional. The collection of contexts that are activated when the intent is
   * matched. Context messages in this collection should not set the parameters
   * field. Setting the `lifespan_count` to 0 will reset the context when the
   * intent is matched. Format: `projects//agent/sessions/-/contexts/`.
   */
  set outputContexts(value: Array<Context_>|null) {
    this.Serializable$set('outputContexts', value);
  }

  get parameters(): Array<IntentParameter_>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional. The collection of parameters associated with the intent.
   */
  set parameters(value: Array<IntentParameter_>|null) {
    this.Serializable$set('parameters', value);
  }

  get parentFollowupIntentName(): string|null {
    return (
        (this.Serializable$has('parentFollowupIntentName')) ?
            (this.Serializable$get('parentFollowupIntentName')) :
            (null));
  }

  /**
   * Optional. The unique identifier of the parent intent in the chain of
   * followup intents. You can set this field when creating an intent, for
   * example with CreateIntent or BatchUpdateIntents, in order to make this
   * intent a followup intent. It identifies the parent followup intent. Format:
   * `projects//agent/intents/`.
   */
  set parentFollowupIntentName(value: string|null) {
    this.Serializable$set('parentFollowupIntentName', value);
  }

  get priority(): number|null {
    return (
        (this.Serializable$has('priority')) ?
            (this.Serializable$get('priority')) :
            (null));
  }

  /**
   * Optional. The priority of this intent. Higher numbers represent higher
   * priorities. - If the supplied value is unspecified or 0, the service
   * translates the value to 500,000, which corresponds to the `Normal` priority
   * in the console. - If the supplied value is negative, the intent is ignored
   * in runtime detect intent requests.
   */
  set priority(value: number|null) {
    this.Serializable$set('priority', value);
  }

  get resetContexts(): boolean|null {
    return (
        (this.Serializable$has('resetContexts')) ?
            (this.Serializable$get('resetContexts')) :
            (null));
  }

  /**
   * Optional. Indicates whether to delete all contexts in the current session
   * when this intent is matched.
   */
  set resetContexts(value: boolean|null) {
    this.Serializable$set('resetContexts', value);
  }

  get rootFollowupIntentName(): string|null {
    return (
        (this.Serializable$has('rootFollowupIntentName')) ?
            (this.Serializable$get('rootFollowupIntentName')) :
            (null));
  }

  /**
   * Output only. The unique identifier of the root intent in the chain of
   * followup intents. It identifies the correct followup intents chain for this
   * intent. Format: `projects//agent/intents/`.
   */
  set rootFollowupIntentName(value: string|null) {
    this.Serializable$set('rootFollowupIntentName', value);
  }

  get trainingPhrases(): Array<IntentTrainingPhrase_>|null {
    return (
        (this.Serializable$has('trainingPhrases')) ?
            (this.Serializable$get('trainingPhrases')) :
            (null));
  }

  /**
   * Optional. The collection of examples that the agent is trained on.
   */
  set trainingPhrases(value: Array<IntentTrainingPhrase_>|null) {
    this.Serializable$set('trainingPhrases', value);
  }

  get webhookState(): Intent_WebhookState|null {
    return (
        (this.Serializable$has('webhookState')) ?
            (this.Serializable$get('webhookState')) :
            (null));
  }

  /**
   * Optional. Indicates whether webhooks are enabled for the intent.
   */
  set webhookState(value: Intent_WebhookState|null) {
    this.Serializable$set('webhookState', value);
  }

  getConstructor(): SerializableCtor<Intent_> {
    return Intent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'conditionalFollowupEventInputs': IntentConditionalFollowupEventInput_,
        'conditionalMessages': IntentConditionalMessage_,
        'followupIntentInfo': IntentFollowupIntentInfo_,
        'messages': IntentMessage_,
        'outputContexts': Context_,
        'parameters': IntentParameter_,
        'trainingPhrases': IntentTrainingPhrase_
      },
      enums: {
        'defaultResponsePlatforms': Intent_DefaultResponsePlatformsEnum,
        'webhookState': Intent_WebhookStateEnum
      },
      keys: [
        'action',
        'condition',
        'conditionalFollowupEventInputs',
        'conditionalMessages',
        'defaultResponsePlatforms',
        'displayName',
        'endInteraction',
        'events',
        'followupEventInput',
        'followupIntentInfo',
        'inputContextNames',
        'isFallback',
        'isKnowledgeIntent',
        'liveAgentHandoff',
        'maxAsyncFulfillmentDuration',
        'messages',
        'mlDisabled',
        'mlEnabled',
        'name',
        'outputContexts',
        'parameters',
        'parentFollowupIntentName',
        'priority',
        'resetContexts',
        'rootFollowupIntentName',
        'trainingPhrases',
        'webhookState'
      ],
      objects: {'followupEventInput': IntentFollowupEventInput_}
    };
  }
}

export interface InteractionExecutionSequence_Parameters {
  source?: string|null;
  intentId?: string|null;
  intentName?: string|null;
  action?: string|null;
  webhookUsed?: boolean|null;
  webhookTriggeringEvent?: string|null;
  webhookResponseTime?: number|null;
  error?: string|null;
  intentTriggeringEvent?: string|null;
  agentId?: string|null;
}
export class InteractionExecutionSequence_ extends Serializable {
  constructor(parameters: InteractionExecutionSequence_Parameters = {}) {
    super();
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'intentId',
        (parameters.intentId == null) ? (null) : (parameters.intentId));
    this.Serializable$set(
        'intentName',
        (parameters.intentName == null) ? (null) : (parameters.intentName));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'webhookUsed',
        (parameters.webhookUsed == null) ? (null) : (parameters.webhookUsed));
    this.Serializable$set(
        'webhookTriggeringEvent',
        (parameters.webhookTriggeringEvent == null) ?
            (null) :
            (parameters.webhookTriggeringEvent));
    this.Serializable$set(
        'webhookResponseTime',
        (parameters.webhookResponseTime == null) ?
            (null) :
            (parameters.webhookResponseTime));
    this.Serializable$set(
        'error', (parameters.error == null) ? (null) : (parameters.error));
    this.Serializable$set(
        'intentTriggeringEvent',
        (parameters.intentTriggeringEvent == null) ?
            (null) :
            (parameters.intentTriggeringEvent));
    this.Serializable$set(
        'agentId',
        (parameters.agentId == null) ? (null) : (parameters.agentId));
  }

  get action(): string|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Action triggered.
   */
  set action(value: string|null) {
    this.Serializable$set('action', value);
  }

  get agentId(): string|null {
    return (
        (this.Serializable$has('agentId')) ?
            (this.Serializable$get('agentId')) :
            (null));
  }

  /**
   * Agent id of this sequence.
   */
  set agentId(value: string|null) {
    this.Serializable$set('agentId', value);
  }

  get error(): string|null {
    return (
        (this.Serializable$has('error')) ? (this.Serializable$get('error')) :
                                           (null));
  }

  /**
   * Webhook error.
   */
  set error(value: string|null) {
    this.Serializable$set('error', value);
  }

  get intentId(): string|null {
    return (
        (this.Serializable$has('intentId')) ?
            (this.Serializable$get('intentId')) :
            (null));
  }

  /**
   * Intent id (if matched)
   */
  set intentId(value: string|null) {
    this.Serializable$set('intentId', value);
  }

  get intentName(): string|null {
    return (
        (this.Serializable$has('intentName')) ?
            (this.Serializable$get('intentName')) :
            (null));
  }

  /**
   * Intent name (if matched)
   */
  set intentName(value: string|null) {
    this.Serializable$set('intentName', value);
  }

  get intentTriggeringEvent(): string|null {
    return (
        (this.Serializable$has('intentTriggeringEvent')) ?
            (this.Serializable$get('intentTriggeringEvent')) :
            (null));
  }

  /**
   * Intent_triggering_event is ignored when webhook_triggering_event is
   * present.
   */
  set intentTriggeringEvent(value: string|null) {
    this.Serializable$set('intentTriggeringEvent', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * Source of the request
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  get webhookResponseTime(): number|null {
    return (
        (this.Serializable$has('webhookResponseTime')) ?
            (this.Serializable$get('webhookResponseTime')) :
            (null));
  }

  /**
   * Time used for webhook call.
   */
  set webhookResponseTime(value: number|null) {
    this.Serializable$set('webhookResponseTime', value);
  }

  get webhookTriggeringEvent(): string|null {
    return (
        (this.Serializable$has('webhookTriggeringEvent')) ?
            (this.Serializable$get('webhookTriggeringEvent')) :
            (null));
  }

  /**
   * The event triggered by webhook (if any).
   */
  set webhookTriggeringEvent(value: string|null) {
    this.Serializable$set('webhookTriggeringEvent', value);
  }

  get webhookUsed(): boolean|null {
    return (
        (this.Serializable$has('webhookUsed')) ?
            (this.Serializable$get('webhookUsed')) :
            (null));
  }

  /**
   * Whether webhook is called.
   */
  set webhookUsed(value: boolean|null) {
    this.Serializable$set('webhookUsed', value);
  }

  getConstructor(): SerializableCtor<InteractionExecutionSequence_> {
    return InteractionExecutionSequence_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'action', 'agentId', 'error', 'intentId', 'intentName',
        'intentTriggeringEvent', 'source', 'webhookResponseTime',
        'webhookTriggeringEvent', 'webhookUsed'
      ]
    };
  }
}

export interface InteractionFollowupEventInfo_Parameters {
  eventName?: string|null;
  executionSequence?: Array<InteractionExecutionSequence_>|null;
}
export class InteractionFollowupEventInfo_ extends Serializable {
  constructor(parameters: InteractionFollowupEventInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'eventName',
        (parameters.eventName == null) ? (null) : (parameters.eventName));
    this.Serializable$set(
        'executionSequence',
        (parameters.executionSequence == null) ?
            (null) :
            (parameters.executionSequence));
  }

  get eventName(): string|null {
    return (
        (this.Serializable$has('eventName')) ?
            (this.Serializable$get('eventName')) :
            (null));
  }

  /**
   * The follow-up event returned from webhook.
   */
  set eventName(value: string|null) {
    this.Serializable$set('eventName', value);
  }

  get executionSequence(): Array<InteractionExecutionSequence_>|null {
    return (
        (this.Serializable$has('executionSequence')) ?
            (this.Serializable$get('executionSequence')) :
            (null));
  }

  /**
   * The collection of execution sequences of serving a query request.
   */
  set executionSequence(value: Array<InteractionExecutionSequence_>|null) {
    this.Serializable$set('executionSequence', value);
  }

  getConstructor(): SerializableCtor<InteractionFollowupEventInfo_> {
    return InteractionFollowupEventInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'executionSequence': InteractionExecutionSequence_},
      keys: ['eventName', 'executionSequence']
    };
  }
}

export interface Interaction_Parameters {
  name?: string|null;
  agentVersion?: string|null;
  inputContexts?: Array<Context_>|null;
  logType?: Interaction_LogType|null;
  responseTime?: string|null;
  deleteTime?: string|null;
  originalRequestSource?: string|null;
  webhookUsed?: boolean|null;
  webhookForSlotFillingUsed?: boolean|null;
  webhookFailed?: boolean|null;
  conversationResponseJson?: string|null;
  aogResponse?: ApiClientObjectMap<any>|null;
  loggingPolicy?: Interaction_LoggingPolicy|null;
  v2Response?: DetectIntentResponse_|null;
  trainingPhrasesPromotionState?: Interaction_TrainingPhrasesPromotionState|
      null;
  intent?: string|null;
  trainingPhrases?: Array<IntentTrainingPhrase_>|null;
  followupEventInfo?: InteractionFollowupEventInfo_|null;
}
export class Interaction_ extends Serializable {
  constructor(parameters: Interaction_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'agentVersion',
        (parameters.agentVersion == null) ? (null) : (parameters.agentVersion));
    this.Serializable$set(
        'inputContexts',
        (parameters.inputContexts == null) ? (null) :
                                             (parameters.inputContexts));
    this.Serializable$set(
        'logType',
        (parameters.logType == null) ? (null) : (parameters.logType));
    this.Serializable$set(
        'responseTime',
        (parameters.responseTime == null) ? (null) : (parameters.responseTime));
    this.Serializable$set(
        'deleteTime',
        (parameters.deleteTime == null) ? (null) : (parameters.deleteTime));
    this.Serializable$set(
        'originalRequestSource',
        (parameters.originalRequestSource == null) ?
            (null) :
            (parameters.originalRequestSource));
    this.Serializable$set(
        'webhookUsed',
        (parameters.webhookUsed == null) ? (null) : (parameters.webhookUsed));
    this.Serializable$set(
        'webhookForSlotFillingUsed',
        (parameters.webhookForSlotFillingUsed == null) ?
            (null) :
            (parameters.webhookForSlotFillingUsed));
    this.Serializable$set(
        'webhookFailed',
        (parameters.webhookFailed == null) ? (null) :
                                             (parameters.webhookFailed));
    this.Serializable$set(
        'conversationResponseJson',
        (parameters.conversationResponseJson == null) ?
            (null) :
            (parameters.conversationResponseJson));
    this.Serializable$set(
        'aogResponse',
        (parameters.aogResponse == null) ? (null) : (parameters.aogResponse));
    this.Serializable$set(
        'loggingPolicy',
        (parameters.loggingPolicy == null) ? (null) :
                                             (parameters.loggingPolicy));
    this.Serializable$set(
        'v2Response',
        (parameters.v2Response == null) ? (null) : (parameters.v2Response));
    this.Serializable$set(
        'trainingPhrasesPromotionState',
        (parameters.trainingPhrasesPromotionState == null) ?
            (null) :
            (parameters.trainingPhrasesPromotionState));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'trainingPhrases',
        (parameters.trainingPhrases == null) ? (null) :
                                               (parameters.trainingPhrases));
    this.Serializable$set(
        'followupEventInfo',
        (parameters.followupEventInfo == null) ?
            (null) :
            (parameters.followupEventInfo));
  }

  static get LogType(): IInteraction_LogTypeEnum {
    return Interaction_LogTypeEnum;
  }

  static get LoggingPolicy(): IInteraction_LoggingPolicyEnum {
    return Interaction_LoggingPolicyEnum;
  }

  static get TrainingPhrasesPromotionState():
      IInteraction_TrainingPhrasesPromotionStateEnum {
    return Interaction_TrainingPhrasesPromotionStateEnum;
  }

  get agentVersion(): string|null {
    return (
        (this.Serializable$has('agentVersion')) ?
            (this.Serializable$get('agentVersion')) :
            (null));
  }

  /**
   * The agent version resource name. Format: `projects//agent/versions/`
   */
  set agentVersion(value: string|null) {
    this.Serializable$set('agentVersion', value);
  }

  get aogResponse(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('aogResponse')) ?
            (this.Serializable$get('aogResponse')) :
            (null));
  }

  /**
   * Output only. The AOG API response, represented as protobuf.Any.
   */
  set aogResponse(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('aogResponse', value);
  }

  get conversationResponseJson(): string|null {
    return (
        (this.Serializable$has('conversationResponseJson')) ?
            (this.Serializable$get('conversationResponseJson')) :
            (null));
  }

  /**
   * A json string representation of the internal conversation response.
   */
  set conversationResponseJson(value: string|null) {
    this.Serializable$set('conversationResponseJson', value);
  }

  get deleteTime(): string|null {
    return (
        (this.Serializable$has('deleteTime')) ?
            (this.Serializable$get('deleteTime')) :
            (null));
  }

  /**
   * If deleted, the deletion time.
   */
  set deleteTime(value: string|null) {
    this.Serializable$set('deleteTime', value);
  }

  get followupEventInfo(): InteractionFollowupEventInfo_|null {
    return (
        (this.Serializable$has('followupEventInfo')) ?
            (this.Serializable$get('followupEventInfo')) :
            (null));
  }

  /**
   * Contains relevant info about follow-up event.
   */
  set followupEventInfo(value: InteractionFollowupEventInfo_|null) {
    this.Serializable$set('followupEventInfo', value);
  }

  get inputContexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('inputContexts')) ?
            (this.Serializable$get('inputContexts')) :
            (null));
  }

  /**
   * The input contexts recorded for this interaction.
   */
  set inputContexts(value: Array<Context_>|null) {
    this.Serializable$set('inputContexts', value);
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * Resource name for the intent (https://linter.aip.dev/122/name-suffix).
   * Format: `projects//agent/intents/` or
   * `projects//locations//agent/intents/`. See go/df-training-api-migration.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  get logType(): Interaction_LogType|null {
    return (
        (this.Serializable$has('logType')) ?
            (this.Serializable$get('logType')) :
            (null));
  }

  /**
   * The log type for the interaction.
   */
  set logType(value: Interaction_LogType|null) {
    this.Serializable$set('logType', value);
  }

  get loggingPolicy(): Interaction_LoggingPolicy|null {
    return (
        (this.Serializable$has('loggingPolicy')) ?
            (this.Serializable$get('loggingPolicy')) :
            (null));
  }

  /**
   * The logging policy for this interaction.
   */
  set loggingPolicy(value: Interaction_LoggingPolicy|null) {
    this.Serializable$set('loggingPolicy', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The resource name for this interaction. Format:
   * `projects//agent/environments//\ sessions//conversations/\ interactions/`.
   * Note that the environment is explicitly specified, since `Session
   * Conversation ID` by itself is not unique across environments.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get originalRequestSource(): string|null {
    return (
        (this.Serializable$has('originalRequestSource')) ?
            (this.Serializable$get('originalRequestSource')) :
            (null));
  }

  /**
   * The original source of this request.
   */
  set originalRequestSource(value: string|null) {
    this.Serializable$set('originalRequestSource', value);
  }

  get responseTime(): string|null {
    return (
        (this.Serializable$has('responseTime')) ?
            (this.Serializable$get('responseTime')) :
            (null));
  }

  /**
   * The response time.
   */
  set responseTime(value: string|null) {
    this.Serializable$set('responseTime', value);
  }

  get trainingPhrases(): Array<IntentTrainingPhrase_>|null {
    return (
        (this.Serializable$has('trainingPhrases')) ?
            (this.Serializable$get('trainingPhrases')) :
            (null));
  }

  /**
   * The training phrases to be evaluated. Please note that we allow multiple
   * training phrases. See go/df-training-api-migration.
   */
  set trainingPhrases(value: Array<IntentTrainingPhrase_>|null) {
    this.Serializable$set('trainingPhrases', value);
  }

  get trainingPhrasesPromotionState(): Interaction_TrainingPhrasesPromotionState
      |null {
    return (
        (this.Serializable$has('trainingPhrasesPromotionState')) ?
            (this.Serializable$get('trainingPhrasesPromotionState')) :
            (null));
  }

  /**
   * An indication on what promotion operation is applied on the query. See
   * go/df-training-api-migration.
   */
  set trainingPhrasesPromotionState(
      value: Interaction_TrainingPhrasesPromotionState|null) {
    this.Serializable$set('trainingPhrasesPromotionState', value);
  }

  get v2Response(): DetectIntentResponse_|null {
    return (
        (this.Serializable$has('v2Response')) ?
            (this.Serializable$get('v2Response')) :
            (null));
  }

  /**
   * The V2 API response.
   */
  set v2Response(value: DetectIntentResponse_|null) {
    this.Serializable$set('v2Response', value);
  }

  get webhookFailed(): boolean|null {
    return (
        (this.Serializable$has('webhookFailed')) ?
            (this.Serializable$get('webhookFailed')) :
            (null));
  }

  /**
   * Whether the interaction invoked at least 1 webhook and at least 1 webhook
   * failed.
   */
  set webhookFailed(value: boolean|null) {
    this.Serializable$set('webhookFailed', value);
  }

  get webhookForSlotFillingUsed(): boolean|null {
    return (
        (this.Serializable$has('webhookForSlotFillingUsed')) ?
            (this.Serializable$get('webhookForSlotFillingUsed')) :
            (null));
  }

  /**
   * Whether webhook was used for slot-filling.
   */
  set webhookForSlotFillingUsed(value: boolean|null) {
    this.Serializable$set('webhookForSlotFillingUsed', value);
  }

  get webhookUsed(): boolean|null {
    return (
        (this.Serializable$has('webhookUsed')) ?
            (this.Serializable$get('webhookUsed')) :
            (null));
  }

  /**
   * Whether webhook was used to fulfill a not-slot-filling interaction.
   */
  set webhookUsed(value: boolean|null) {
    this.Serializable$set('webhookUsed', value);
  }

  getConstructor(): SerializableCtor<Interaction_> {
    return Interaction_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'inputContexts': Context_, 'trainingPhrases': IntentTrainingPhrase_},
      enums: {
        'logType': Interaction_LogTypeEnum,
        'loggingPolicy': Interaction_LoggingPolicyEnum,
        'trainingPhrasesPromotionState':
            Interaction_TrainingPhrasesPromotionStateEnum
      },
      keys: [
        'agentVersion', 'aogResponse', 'conversationResponseJson', 'deleteTime',
        'followupEventInfo', 'inputContexts', 'intent', 'logType',
        'loggingPolicy', 'name', 'originalRequestSource', 'responseTime',
        'trainingPhrases', 'trainingPhrasesPromotionState', 'v2Response',
        'webhookFailed', 'webhookForSlotFillingUsed', 'webhookUsed'
      ],
      objectMaps: {
        'aogResponse': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'followupEventInfo': InteractionFollowupEventInfo_,
        'v2Response': DetectIntentResponse_
      }
    };
  }
}

export interface IssueModelMetadataAssociateCandidatesInfo_Parameters {
  document?: string|null;
}
export class IssueModelMetadataAssociateCandidatesInfo_ extends Serializable {
  constructor(
      parameters: IssueModelMetadataAssociateCandidatesInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The resource name of the associated candidate list. All the candidates are
   * stored within one document. Format: 'projects//knowledgeBases//documents/'
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  getConstructor():
      SerializableCtor<IssueModelMetadataAssociateCandidatesInfo_> {
    return IssueModelMetadataAssociateCandidatesInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['document']};
  }
}

export interface IssueModelMetadata_Parameters {
  associatedCandidatesInfo?: IssueModelMetadataAssociateCandidatesInfo_|null;
  internalTrainingFlags?: string|null;
  trainingModelType?: IssueModelMetadata_TrainingModelType|null;
}
export class IssueModelMetadata_ extends Serializable {
  constructor(parameters: IssueModelMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'associatedCandidatesInfo',
        (parameters.associatedCandidatesInfo == null) ?
            (null) :
            (parameters.associatedCandidatesInfo));
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
  }

  static get TrainingModelType(): IIssueModelMetadata_TrainingModelTypeEnum {
    return IssueModelMetadata_TrainingModelTypeEnum;
  }

  get associatedCandidatesInfo(): IssueModelMetadataAssociateCandidatesInfo_
      |null {
    return (
        (this.Serializable$has('associatedCandidatesInfo')) ?
            (this.Serializable$get('associatedCandidatesInfo')) :
            (null));
  }

  /**
   * The information of the allowlist associated with the conversation model.
   * This field is internal and only to be accessed by the web console.
   */
  set associatedCandidatesInfo(value:
                                   IssueModelMetadataAssociateCandidatesInfo_|
                               null) {
    this.Serializable$set('associatedCandidatesInfo', value);
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  /**
   * Internal testing only. The text format proto of InternalTrainingFlags
   * defined in cloud/ai/contactcenter/knowledge/proto/conversation_model.proto.
   */
  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get trainingModelType(): IssueModelMetadata_TrainingModelType|null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Optional. Type of the issue model. If not provided, model_type is used.
   */
  set trainingModelType(value: IssueModelMetadata_TrainingModelType|null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor(): SerializableCtor<IssueModelMetadata_> {
    return IssueModelMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'trainingModelType': IssueModelMetadata_TrainingModelTypeEnum},
      keys: [
        'associatedCandidatesInfo', 'internalTrainingFlags', 'trainingModelType'
      ],
      objects: {
        'associatedCandidatesInfo': IssueModelMetadataAssociateCandidatesInfo_
      }
    };
  }
}

export interface IssueModelingEntryIssueClusterInfo_Parameters {
  id?: string|null;
  description?: string|null;
  score?: number|null;
}
export class IssueModelingEntryIssueClusterInfo_ extends Serializable {
  constructor(parameters: IssueModelingEntryIssueClusterInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * A descriptive message of the cluster.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Id of the issue modeling candidate cluster.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Matching likelihood of the current message to the cluster. Bounded by [0,
   * 1].
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<IssueModelingEntryIssueClusterInfo_> {
    return IssueModelingEntryIssueClusterInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['description', 'id', 'score']};
  }
}

export interface IssueModelingEntry_Parameters {
  name?: string|null;
  rawText?: string|null;
  insightsConversationId?: string|null;
  issueClusterInfo?: IssueModelingEntryIssueClusterInfo_|null;
}
export class IssueModelingEntry_ extends Serializable {
  constructor(parameters: IssueModelingEntry_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'rawText',
        (parameters.rawText == null) ? (null) : (parameters.rawText));
    this.Serializable$set(
        'insightsConversationId',
        (parameters.insightsConversationId == null) ?
            (null) :
            (parameters.insightsConversationId));
    this.Serializable$set(
        'issueClusterInfo',
        (parameters.issueClusterInfo == null) ? (null) :
                                                (parameters.issueClusterInfo));
  }

  get insightsConversationId(): string|null {
    return (
        (this.Serializable$has('insightsConversationId')) ?
            (this.Serializable$get('insightsConversationId')) :
            (null));
  }

  /**
   * The Insights's conversation id that the entry message is extract from.
   */
  set insightsConversationId(value: string|null) {
    this.Serializable$set('insightsConversationId', value);
  }

  get issueClusterInfo(): IssueModelingEntryIssueClusterInfo_|null {
    return (
        (this.Serializable$has('issueClusterInfo')) ?
            (this.Serializable$get('issueClusterInfo')) :
            (null));
  }

  /**
   * Information about the cluster the issue modeling entry is assigned to.
   */
  set issueClusterInfo(value: IssueModelingEntryIssueClusterInfo_|null) {
    this.Serializable$set('issueClusterInfo', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The IssueModelingEntry resource name. Format:
   * \"projects/{project_id}/locations/{location_id}/knowledgeBases {knowledge_base_id}/documents/{document_id}/issueModelingEntries/ {issue_modeling_entry_id}\"
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get rawText(): string|null {
    return (
        (this.Serializable$has('rawText')) ?
            (this.Serializable$get('rawText')) :
            (null));
  }

  /**
   * The raw text of the message entry.
   */
  set rawText(value: string|null) {
    this.Serializable$set('rawText', value);
  }

  getConstructor(): SerializableCtor<IssueModelingEntry_> {
    return IssueModelingEntry_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['insightsConversationId', 'issueClusterInfo', 'name', 'rawText'],
      objects: {'issueClusterInfo': IssueModelingEntryIssueClusterInfo_}
    };
  }
}

export interface Issue_Parameters {
  name?: string|null;
  displayName?: string|null;
  windowInfo?: Array<WindowInfo_>|null;
  score?: number|null;
}
export class Issue_ extends Serializable {
  constructor(parameters: Issue_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'windowInfo',
        (parameters.windowInfo == null) ? (null) : (parameters.windowInfo));
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Display name for this issue.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Resource for issue.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Score indicating the relevance of this cluster to the input conversation.
   * currently bounded to [0,1]
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  get windowInfo(): Array<WindowInfo_>|null {
    return (
        (this.Serializable$has('windowInfo')) ?
            (this.Serializable$get('windowInfo')) :
            (null));
  }

  /**
   * Windows which have contributed to the issue above.
   */
  set windowInfo(value: Array<WindowInfo_>|null) {
    this.Serializable$set('windowInfo', value);
  }

  getConstructor(): SerializableCtor<Issue_> {
    return Issue_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'windowInfo': WindowInfo_},
      keys: ['displayName', 'name', 'score', 'windowInfo']
    };
  }
}

export interface IvrNodeSettingsDtmfSettings_Parameters {
  maxDigits?: number|null;
  finishDigit?: IvrNodeSettingsDtmfSettings_FinishDigit|null;
  enableDtmf?: boolean|null;
}
export class IvrNodeSettingsDtmfSettings_ extends Serializable {
  constructor(parameters: IvrNodeSettingsDtmfSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'maxDigits',
        (parameters.maxDigits == null) ? (null) : (parameters.maxDigits));
    this.Serializable$set(
        'finishDigit',
        (parameters.finishDigit == null) ? (null) : (parameters.finishDigit));
    this.Serializable$set(
        'enableDtmf',
        (parameters.enableDtmf == null) ? (null) : (parameters.enableDtmf));
  }

  static get FinishDigit(): IIvrNodeSettingsDtmfSettings_FinishDigitEnum {
    return IvrNodeSettingsDtmfSettings_FinishDigitEnum;
  }

  get enableDtmf(): boolean|null {
    return (
        (this.Serializable$has('enableDtmf')) ?
            (this.Serializable$get('enableDtmf')) :
            (null));
  }

  /**
   * Optional. Whether to enable DTMF for this IVR node.
   */
  set enableDtmf(value: boolean|null) {
    this.Serializable$set('enableDtmf', value);
  }

  get finishDigit(): IvrNodeSettingsDtmfSettings_FinishDigit|null {
    return (
        (this.Serializable$has('finishDigit')) ?
            (this.Serializable$get('finishDigit')) :
            (null));
  }

  /**
   * Optional. The digit that inidicates the end of DTMF input.
   */
  set finishDigit(value: IvrNodeSettingsDtmfSettings_FinishDigit|null) {
    this.Serializable$set('finishDigit', value);
  }

  get maxDigits(): number|null {
    return (
        (this.Serializable$has('maxDigits')) ?
            (this.Serializable$get('maxDigits')) :
            (null));
  }

  /**
   * Optional. Maximum number of digits allowed for DTMF input.
   */
  set maxDigits(value: number|null) {
    this.Serializable$set('maxDigits', value);
  }

  getConstructor(): SerializableCtor<IvrNodeSettingsDtmfSettings_> {
    return IvrNodeSettingsDtmfSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'finishDigit': IvrNodeSettingsDtmfSettings_FinishDigitEnum},
      keys: ['enableDtmf', 'finishDigit', 'maxDigits']
    };
  }
}

export interface IvrNodeSettingsLoggingSettings_Parameters {
  disableLogging?: boolean|null;
}
export class IvrNodeSettingsLoggingSettings_ extends Serializable {
  constructor(parameters: IvrNodeSettingsLoggingSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * Optional. Whether logging should be disabled at the current IVR Node.
   * Currently logging includes Dialogflow History, Contact Center messages
   * StackDriver logs as well as speech logging.
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  getConstructor(): SerializableCtor<IvrNodeSettingsLoggingSettings_> {
    return IvrNodeSettingsLoggingSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['disableLogging']};
  }
}

export interface IvrNodeSettings_Parameters {
  allowBargeIn?: boolean|null;
  noInputTimeout?: string|null;
  speechCompleteTimeout?: string|null;
  maxRepromptCount?: number|null;
  dtmfSettings?: IvrNodeSettingsDtmfSettings_|null;
  loggingSettings?: IvrNodeSettingsLoggingSettings_|null;
  conditionalFollowupEventInputs?: Array<IntentConditionalFollowupEventInput_>|
      null;
  exportUri?: string|null;
}
export class IvrNodeSettings_ extends Serializable {
  constructor(parameters: IvrNodeSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'allowBargeIn',
        (parameters.allowBargeIn == null) ? (null) : (parameters.allowBargeIn));
    this.Serializable$set(
        'noInputTimeout',
        (parameters.noInputTimeout == null) ? (null) :
                                              (parameters.noInputTimeout));
    this.Serializable$set(
        'speechCompleteTimeout',
        (parameters.speechCompleteTimeout == null) ?
            (null) :
            (parameters.speechCompleteTimeout));
    this.Serializable$set(
        'maxRepromptCount',
        (parameters.maxRepromptCount == null) ? (null) :
                                                (parameters.maxRepromptCount));
    this.Serializable$set(
        'dtmfSettings',
        (parameters.dtmfSettings == null) ? (null) : (parameters.dtmfSettings));
    this.Serializable$set(
        'loggingSettings',
        (parameters.loggingSettings == null) ? (null) :
                                               (parameters.loggingSettings));
    this.Serializable$set(
        'conditionalFollowupEventInputs',
        (parameters.conditionalFollowupEventInputs == null) ?
            (null) :
            (parameters.conditionalFollowupEventInputs));
    this.Serializable$set(
        'exportUri',
        (parameters.exportUri == null) ? (null) : (parameters.exportUri));
  }

  get allowBargeIn(): boolean|null {
    return (
        (this.Serializable$has('allowBargeIn')) ?
            (this.Serializable$get('allowBargeIn')) :
            (null));
  }

  /**
   * Optional. Whether barge-in is allowed if an intent at entering layer or
   * looping layer is matched. Each intent may override this setting in its own
   * `allow_barge_in` field.
   */
  set allowBargeIn(value: boolean|null) {
    this.Serializable$set('allowBargeIn', value);
  }

  get conditionalFollowupEventInputs():
      Array<IntentConditionalFollowupEventInput_>|null {
    return (
        (this.Serializable$has('conditionalFollowupEventInputs')) ?
            (this.Serializable$get('conditionalFollowupEventInputs')) :
            (null));
  }

  /**
   * Optional. The list of conditional followup event inputs.
   */
  set conditionalFollowupEventInputs(
      value: Array<IntentConditionalFollowupEventInput_>|null) {
    this.Serializable$set('conditionalFollowupEventInputs', value);
  }

  get dtmfSettings(): IvrNodeSettingsDtmfSettings_|null {
    return (
        (this.Serializable$has('dtmfSettings')) ?
            (this.Serializable$get('dtmfSettings')) :
            (null));
  }

  /**
   * Optional. Settings for DTMF input.
   */
  set dtmfSettings(value: IvrNodeSettingsDtmfSettings_|null) {
    this.Serializable$set('dtmfSettings', value);
  }

  get exportUri(): string|null {
    return (
        (this.Serializable$has('exportUri')) ?
            (this.Serializable$get('exportUri')) :
            (null));
  }

  /**
   * Optional. If not empty, export audio to the specified URI when the node is
   * entered via this intent.
   */
  set exportUri(value: string|null) {
    this.Serializable$set('exportUri', value);
  }

  get loggingSettings(): IvrNodeSettingsLoggingSettings_|null {
    return (
        (this.Serializable$has('loggingSettings')) ?
            (this.Serializable$get('loggingSettings')) :
            (null));
  }

  /**
   * Optional. Settings for logging.
   */
  set loggingSettings(value: IvrNodeSettingsLoggingSettings_|null) {
    this.Serializable$set('loggingSettings', value);
  }

  get maxRepromptCount(): number|null {
    return (
        (this.Serializable$has('maxRepromptCount')) ?
            (this.Serializable$get('maxRepromptCount')) :
            (null));
  }

  /**
   * Optional. Defines the number of no input or no match cases to be triggered
   * before we trigger the max reprompt case. Default is 5 if unspecified or 0.
   */
  set maxRepromptCount(value: number|null) {
    this.Serializable$set('maxRepromptCount', value);
  }

  get noInputTimeout(): string|null {
    return (
        (this.Serializable$has('noInputTimeout')) ?
            (this.Serializable$get('noInputTimeout')) :
            (null));
  }

  /**
   * Optional. Defines the length of time of speech silence after we start
   * detecting speech that triggers NO_INPUT event. In this case, we consider
   * the user to not have said anything. Default is using Avaya's preset value.
   * Fraction below one second is dropped.
   */
  set noInputTimeout(value: string|null) {
    this.Serializable$set('noInputTimeout', value);
  }

  get speechCompleteTimeout(): string|null {
    return (
        (this.Serializable$has('speechCompleteTimeout')) ?
            (this.Serializable$get('speechCompleteTimeout')) :
            (null));
  }

  /**
   * Optional. Minimum length of silence after user has started that triggers
   * end of speech in Avaya. If unspecified, Dialogflow uses Avaya's default
   * values. Fraction below one second is dropped.
   */
  set speechCompleteTimeout(value: string|null) {
    this.Serializable$set('speechCompleteTimeout', value);
  }

  getConstructor(): SerializableCtor<IvrNodeSettings_> {
    return IvrNodeSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'conditionalFollowupEventInputs': IntentConditionalFollowupEventInput_
      },
      keys: [
        'allowBargeIn', 'conditionalFollowupEventInputs', 'dtmfSettings',
        'exportUri', 'loggingSettings', 'maxRepromptCount', 'noInputTimeout',
        'speechCompleteTimeout'
      ],
      objects: {
        'dtmfSettings': IvrNodeSettingsDtmfSettings_,
        'loggingSettings': IvrNodeSettingsLoggingSettings_
      }
    };
  }
}

export interface IvrNode_Parameters {
  name?: string|null;
  enteringIntents?: Array<EnteringIntent_>|null;
  exitingIntents?: Array<ExitingIntent_>|null;
  noInputIntents?: Array<LoopingIntent_>|null;
  noMatchIntents?: Array<LoopingIntent_>|null;
  maxRepromptIntent?: MaxRepromptIntent_|null;
  settings?: IvrNodeSettings_|null;
}
export class IvrNode_ extends Serializable {
  constructor(parameters: IvrNode_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'enteringIntents',
        (parameters.enteringIntents == null) ? (null) :
                                               (parameters.enteringIntents));
    this.Serializable$set(
        'exitingIntents',
        (parameters.exitingIntents == null) ? (null) :
                                              (parameters.exitingIntents));
    this.Serializable$set(
        'noInputIntents',
        (parameters.noInputIntents == null) ? (null) :
                                              (parameters.noInputIntents));
    this.Serializable$set(
        'noMatchIntents',
        (parameters.noMatchIntents == null) ? (null) :
                                              (parameters.noMatchIntents));
    this.Serializable$set(
        'maxRepromptIntent',
        (parameters.maxRepromptIntent == null) ?
            (null) :
            (parameters.maxRepromptIntent));
    this.Serializable$set(
        'settings',
        (parameters.settings == null) ? (null) : (parameters.settings));
  }

  get enteringIntents(): Array<EnteringIntent_>|null {
    return (
        (this.Serializable$has('enteringIntents')) ?
            (this.Serializable$get('enteringIntents')) :
            (null));
  }

  /**
   * Optional. List of intents at the entering layer.
   */
  set enteringIntents(value: Array<EnteringIntent_>|null) {
    this.Serializable$set('enteringIntents', value);
  }

  get exitingIntents(): Array<ExitingIntent_>|null {
    return (
        (this.Serializable$has('exitingIntents')) ?
            (this.Serializable$get('exitingIntents')) :
            (null));
  }

  /**
   * Optional. List of intents at the exiting layer.
   */
  set exitingIntents(value: Array<ExitingIntent_>|null) {
    this.Serializable$set('exitingIntents', value);
  }

  get maxRepromptIntent(): MaxRepromptIntent_|null {
    return (
        (this.Serializable$has('maxRepromptIntent')) ?
            (this.Serializable$get('maxRepromptIntent')) :
            (null));
  }

  /**
   * Optional. Defines the intent for max reprompt case.
   */
  set maxRepromptIntent(value: MaxRepromptIntent_|null) {
    this.Serializable$set('maxRepromptIntent', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. Name of the IVR node.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get noInputIntents(): Array<LoopingIntent_>|null {
    return (
        (this.Serializable$has('noInputIntents')) ?
            (this.Serializable$get('noInputIntents')) :
            (null));
  }

  /**
   * Optional. Defines the intents for no input case.
   */
  set noInputIntents(value: Array<LoopingIntent_>|null) {
    this.Serializable$set('noInputIntents', value);
  }

  get noMatchIntents(): Array<LoopingIntent_>|null {
    return (
        (this.Serializable$has('noMatchIntents')) ?
            (this.Serializable$get('noMatchIntents')) :
            (null));
  }

  /**
   * Optional. Defines the intents for no match case.
   */
  set noMatchIntents(value: Array<LoopingIntent_>|null) {
    this.Serializable$set('noMatchIntents', value);
  }

  get settings(): IvrNodeSettings_|null {
    return (
        (this.Serializable$has('settings')) ?
            (this.Serializable$get('settings')) :
            (null));
  }

  /**
   * Optional. Defines node level settings.
   */
  set settings(value: IvrNodeSettings_|null) {
    this.Serializable$set('settings', value);
  }

  getConstructor(): SerializableCtor<IvrNode_> {
    return IvrNode_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'enteringIntents': EnteringIntent_,
        'exitingIntents': ExitingIntent_,
        'noInputIntents': LoopingIntent_,
        'noMatchIntents': LoopingIntent_
      },
      keys: [
        'enteringIntents', 'exitingIntents', 'maxRepromptIntent', 'name',
        'noInputIntents', 'noMatchIntents', 'settings'
      ],
      objects: {
        'maxRepromptIntent': MaxRepromptIntent_,
        'settings': IvrNodeSettings_
      }
    };
  }
}

export interface KeyMomentModelMetadata_Parameters {
  modelType?: string|null;
  internalTrainingFlags?: string|null;
}
export class KeyMomentModelMetadata_ extends Serializable {
  constructor(parameters: KeyMomentModelMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'modelType',
        (parameters.modelType == null) ? (null) : (parameters.modelType));
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get modelType(): string|null {
    return (
        (this.Serializable$has('modelType')) ?
            (this.Serializable$get('modelType')) :
            (null));
  }

  /**
   * Type of the smart reply model. The available values are: *
   * `key-moment-model-1` - (default)
   */
  set modelType(value: string|null) {
    this.Serializable$set('modelType', value);
  }

  getConstructor(): SerializableCtor<KeyMomentModelMetadata_> {
    return KeyMomentModelMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['internalTrainingFlags', 'modelType']};
  }
}

export interface KnowledgeAnswersAnswer_Parameters {
  source?: string|null;
  faqQuestion?: string|null;
  answer?: string|null;
  matchConfidenceLevel?: KnowledgeAnswersAnswer_MatchConfidenceLevel|null;
  matchConfidence?: number|null;
}
export class KnowledgeAnswersAnswer_ extends Serializable {
  constructor(parameters: KnowledgeAnswersAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'faqQuestion',
        (parameters.faqQuestion == null) ? (null) : (parameters.faqQuestion));
    this.Serializable$set(
        'answer', (parameters.answer == null) ? (null) : (parameters.answer));
    this.Serializable$set(
        'matchConfidenceLevel',
        (parameters.matchConfidenceLevel == null) ?
            (null) :
            (parameters.matchConfidenceLevel));
    this.Serializable$set(
        'matchConfidence',
        (parameters.matchConfidence == null) ? (null) :
                                               (parameters.matchConfidence));
  }

  static get MatchConfidenceLevel():
      IKnowledgeAnswersAnswer_MatchConfidenceLevelEnum {
    return KnowledgeAnswersAnswer_MatchConfidenceLevelEnum;
  }

  get answer(): string|null {
    return (
        (this.Serializable$has('answer')) ? (this.Serializable$get('answer')) :
                                            (null));
  }

  /**
   * The piece of text from the `source` knowledge base document that answers
   * this conversational query.
   */
  set answer(value: string|null) {
    this.Serializable$set('answer', value);
  }

  get faqQuestion(): string|null {
    return (
        (this.Serializable$has('faqQuestion')) ?
            (this.Serializable$get('faqQuestion')) :
            (null));
  }

  /**
   * The corresponding FAQ question if the answer was extracted from a FAQ
   * Document, empty otherwise.
   */
  set faqQuestion(value: string|null) {
    this.Serializable$set('faqQuestion', value);
  }

  get matchConfidence(): number|null {
    return (
        (this.Serializable$has('matchConfidence')) ?
            (this.Serializable$get('matchConfidence')) :
            (null));
  }

  /**
   * The system's confidence score that this Knowledge answer is a good match
   * for this conversational query. The range is from 0.0 (completely uncertain)
   * to 1.0 (completely certain). Note: The confidence score is likely to vary
   * somewhat (possibly even for identical requests), as the underlying model is
   * under constant improvement. It may be deprecated in the future. We
   * recommend using `match_confidence_level` which should be generally more
   * stable.
   */
  set matchConfidence(value: number|null) {
    this.Serializable$set('matchConfidence', value);
  }

  get matchConfidenceLevel(): KnowledgeAnswersAnswer_MatchConfidenceLevel|null {
    return (
        (this.Serializable$has('matchConfidenceLevel')) ?
            (this.Serializable$get('matchConfidenceLevel')) :
            (null));
  }

  /**
   * The system's confidence level that this knowledge answer is a good match
   * for this conversational query. NOTE: The confidence level for a given ``
   * pair may change without notice, as it depends on models that are constantly
   * being improved. However, it will change less frequently than the confidence
   * score below, and should be preferred for referencing the quality of an
   * answer.
   */
  set matchConfidenceLevel(value: KnowledgeAnswersAnswer_MatchConfidenceLevel|
                           null) {
    this.Serializable$set('matchConfidenceLevel', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * Indicates which Knowledge Document this answer was extracted from. Format:
   * `projects//knowledgeBases//documents/`.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  getConstructor(): SerializableCtor<KnowledgeAnswersAnswer_> {
    return KnowledgeAnswersAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'matchConfidenceLevel': KnowledgeAnswersAnswer_MatchConfidenceLevelEnum
      },
      keys: [
        'answer', 'faqQuestion', 'matchConfidence', 'matchConfidenceLevel',
        'source'
      ]
    };
  }
}

export interface KnowledgeAnswers_Parameters {
  answers?: Array<KnowledgeAnswersAnswer_>|null;
}
export class KnowledgeAnswers_ extends Serializable {
  constructor(parameters: KnowledgeAnswers_Parameters = {}) {
    super();
    this.Serializable$set(
        'answers',
        (parameters.answers == null) ? (null) : (parameters.answers));
  }

  get answers(): Array<KnowledgeAnswersAnswer_>|null {
    return (
        (this.Serializable$has('answers')) ?
            (this.Serializable$get('answers')) :
            (null));
  }

  /**
   * A list of answers from Knowledge Connector.
   */
  set answers(value: Array<KnowledgeAnswersAnswer_>|null) {
    this.Serializable$set('answers', value);
  }

  getConstructor(): SerializableCtor<KnowledgeAnswers_> {
    return KnowledgeAnswers_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'answers': KnowledgeAnswersAnswer_}, keys: ['answers']};
  }
}

export interface KnowledgeBase_Parameters {
  name?: string|null;
  displayName?: string|null;
  languageCode?: string|null;
}
export class KnowledgeBase_ extends Serializable {
  constructor(parameters: KnowledgeBase_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. The display name of the knowledge base. The name must be 1024
   * bytes or less; otherwise, the creation request fails.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Language which represents the KnowledgeBase. When the KnowledgeBase is
   * created/updated, this is populated for all non en-us languages. If not
   * populated, the default language en-us applies.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The knowledge base resource name. The name must be empty when creating a
   * knowledge base. Format: `projects//locations//knowledgeBases/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<KnowledgeBase_> {
    return KnowledgeBase_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['displayName', 'languageCode', 'name']};
  }
}

export interface KnowledgeOperationMetadata_Parameters {
  state?: KnowledgeOperationMetadata_State|null;
  knowledgeBase?: string|null;
  exportOperationMetadata?: ExportOperationMetadata_|null;
}
export class KnowledgeOperationMetadata_ extends Serializable {
  constructor(parameters: KnowledgeOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'knowledgeBase',
        (parameters.knowledgeBase == null) ? (null) :
                                             (parameters.knowledgeBase));
    this.Serializable$set(
        'exportOperationMetadata',
        (parameters.exportOperationMetadata == null) ?
            (null) :
            (parameters.exportOperationMetadata));
  }

  static get State(): IKnowledgeOperationMetadata_StateEnum {
    return KnowledgeOperationMetadata_StateEnum;
  }

  get exportOperationMetadata(): ExportOperationMetadata_|null {
    return (
        (this.Serializable$has('exportOperationMetadata')) ?
            (this.Serializable$get('exportOperationMetadata')) :
            (null));
  }

  /**
   * Metadata for the Export Data Operation such as the destination of export.
   */
  set exportOperationMetadata(value: ExportOperationMetadata_|null) {
    this.Serializable$set('exportOperationMetadata', value);
  }

  get knowledgeBase(): string|null {
    return (
        (this.Serializable$has('knowledgeBase')) ?
            (this.Serializable$get('knowledgeBase')) :
            (null));
  }

  /**
   * The name of the knowledge base interacted with during the operation.
   */
  set knowledgeBase(value: string|null) {
    this.Serializable$set('knowledgeBase', value);
  }

  get state(): KnowledgeOperationMetadata_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Required. Output only. The current state of this operation.
   */
  set state(value: KnowledgeOperationMetadata_State|null) {
    this.Serializable$set('state', value);
  }

  getConstructor(): SerializableCtor<KnowledgeOperationMetadata_> {
    return KnowledgeOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': KnowledgeOperationMetadata_StateEnum},
      keys: ['exportOperationMetadata', 'knowledgeBase', 'state'],
      objects: {'exportOperationMetadata': ExportOperationMetadata_}
    };
  }
}

export interface LabelConversationOperationMetadata_Parameters {
  conversationDataset?: string|null;
  annotatedConversationDataset?: string|null;
  questionType?: string|null;
  progressPercent?: number|null;
  partialFailures?: Array<GoogleRpcStatus>|null;
  createTime?: string|null;
  annotationTaskConfig?: AnnotationTaskConfig_|null;
}
export class LabelConversationOperationMetadata_ extends Serializable {
  constructor(parameters: LabelConversationOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationDataset',
        (parameters.conversationDataset == null) ?
            (null) :
            (parameters.conversationDataset));
    this.Serializable$set(
        'annotatedConversationDataset',
        (parameters.annotatedConversationDataset == null) ?
            (null) :
            (parameters.annotatedConversationDataset));
    this.Serializable$set(
        'questionType',
        (parameters.questionType == null) ? (null) : (parameters.questionType));
    this.Serializable$set(
        'progressPercent',
        (parameters.progressPercent == null) ? (null) :
                                               (parameters.progressPercent));
    this.Serializable$set(
        'partialFailures',
        (parameters.partialFailures == null) ? (null) :
                                               (parameters.partialFailures));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'annotationTaskConfig',
        (parameters.annotationTaskConfig == null) ?
            (null) :
            (parameters.annotationTaskConfig));
  }

  get annotatedConversationDataset(): string|null {
    return (
        (this.Serializable$has('annotatedConversationDataset')) ?
            (this.Serializable$get('annotatedConversationDataset')) :
            (null));
  }

  /**
   * Output only. Annotated conversation dataset generated. Format:
   * `projects//conversationDatasets//annotatedConversationDatasets/`
   */
  set annotatedConversationDataset(value: string|null) {
    this.Serializable$set('annotatedConversationDataset', value);
  }

  get annotationTaskConfig(): AnnotationTaskConfig_|null {
    return (
        (this.Serializable$has('annotationTaskConfig')) ?
            (this.Serializable$get('annotationTaskConfig')) :
            (null));
  }

  /**
   * Config for label conversation annotation task.
   */
  set annotationTaskConfig(value: AnnotationTaskConfig_|null) {
    this.Serializable$set('annotationTaskConfig', value);
  }

  get conversationDataset(): string|null {
    return (
        (this.Serializable$has('conversationDataset')) ?
            (this.Serializable$get('conversationDataset')) :
            (null));
  }

  /**
   * Conversation dataset to be labelled. Format:
   * `projects//conversationDatasets/`
   */
  set conversationDataset(value: string|null) {
    this.Serializable$set('conversationDataset', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when labeling request was created. The time is measured on server
   * side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get partialFailures(): Array<GoogleRpcStatus>|null {
    return (
        (this.Serializable$has('partialFailures')) ?
            (this.Serializable$get('partialFailures')) :
            (null));
  }

  /**
   * Partial failures encountered. E.g. single files that couldn't be read.
   * Status details field will contain standard Google Cloud error details.
   */
  set partialFailures(value: Array<GoogleRpcStatus>|null) {
    this.Serializable$set('partialFailures', value);
  }

  get progressPercent(): number|null {
    return (
        (this.Serializable$has('progressPercent')) ?
            (this.Serializable$get('progressPercent')) :
            (null));
  }

  /**
   * Progress of label operation. Range: [0, 100].
   */
  set progressPercent(value: number|null) {
    this.Serializable$set('progressPercent', value);
  }

  get questionType(): string|null {
    return (
        (this.Serializable$has('questionType')) ?
            (this.Serializable$get('questionType')) :
            (null));
  }

  /**
   * Output only. Question type name from CrowdCompute. This is for internal use
   * only.
   */
  set questionType(value: string|null) {
    this.Serializable$set('questionType', value);
  }

  getConstructor(): SerializableCtor<LabelConversationOperationMetadata_> {
    return LabelConversationOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'partialFailures': GoogleRpcStatus},
      keys: [
        'annotatedConversationDataset', 'annotationTaskConfig',
        'conversationDataset', 'createTime', 'partialFailures',
        'progressPercent', 'questionType'
      ],
      objects: {'annotationTaskConfig': AnnotationTaskConfig_}
    };
  }
}

export interface LabelConversationRequest_Parameters {
  annotationTaskConfig?: AnnotationTaskConfig_|null;
}
export class LabelConversationRequest_ extends Serializable {
  constructor(parameters: LabelConversationRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'annotationTaskConfig',
        (parameters.annotationTaskConfig == null) ?
            (null) :
            (parameters.annotationTaskConfig));
  }

  get annotationTaskConfig(): AnnotationTaskConfig_|null {
    return (
        (this.Serializable$has('annotationTaskConfig')) ?
            (this.Serializable$get('annotationTaskConfig')) :
            (null));
  }

  /**
   * Required. Configs to create and start a labeling task.
   */
  set annotationTaskConfig(value: AnnotationTaskConfig_|null) {
    this.Serializable$set('annotationTaskConfig', value);
  }

  getConstructor(): SerializableCtor<LabelConversationRequest_> {
    return LabelConversationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['annotationTaskConfig'],
      objects: {'annotationTaskConfig': AnnotationTaskConfig_}
    };
  }
}

export interface LabelConversationResponse_Parameters {
  annotatedConversationDataset?: AnnotatedConversationDataset_|null;
}
export class LabelConversationResponse_ extends Serializable {
  constructor(parameters: LabelConversationResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'annotatedConversationDataset',
        (parameters.annotatedConversationDataset == null) ?
            (null) :
            (parameters.annotatedConversationDataset));
  }

  get annotatedConversationDataset(): AnnotatedConversationDataset_|null {
    return (
        (this.Serializable$has('annotatedConversationDataset')) ?
            (this.Serializable$get('annotatedConversationDataset')) :
            (null));
  }

  /**
   * New annotated conversation dataset created by the labeling task.
   */
  set annotatedConversationDataset(value: AnnotatedConversationDataset_|null) {
    this.Serializable$set('annotatedConversationDataset', value);
  }

  getConstructor(): SerializableCtor<LabelConversationResponse_> {
    return LabelConversationResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['annotatedConversationDataset'],
      objects: {'annotatedConversationDataset': AnnotatedConversationDataset_}
    };
  }
}

export interface ListAnalyticsResponseAccumulatedIntentMetrics_Parameters {
  successByIntent?: Array<CloudConversationHourlyAgentAnalyticsValueByIntent>|
      null;
  sessionPathsRoot?: CloudConversationHourlyAgentAnalyticsValueSessionPathNode|
      null;
  skippedSessionPaths?: string|null;
}
export class ListAnalyticsResponseAccumulatedIntentMetrics_ extends
    Serializable {
  constructor(
      parameters:
          ListAnalyticsResponseAccumulatedIntentMetrics_Parameters = {}) {
    super();
    this.Serializable$set(
        'successByIntent',
        (parameters.successByIntent == null) ? (null) :
                                               (parameters.successByIntent));
    this.Serializable$set(
        'sessionPathsRoot',
        (parameters.sessionPathsRoot == null) ? (null) :
                                                (parameters.sessionPathsRoot));
    this.Serializable$set(
        'skippedSessionPaths',
        (parameters.skippedSessionPaths == null) ?
            (null) :
            (parameters.skippedSessionPaths));
  }

  get sessionPathsRoot():
      CloudConversationHourlyAgentAnalyticsValueSessionPathNode|null {
    return (
        (this.Serializable$has('sessionPathsRoot')) ?
            (this.Serializable$get('sessionPathsRoot')) :
            (null));
  }

  /**
   * Root of the tree of most common session paths.
   */
  set sessionPathsRoot(
      value: CloudConversationHourlyAgentAnalyticsValueSessionPathNode|null) {
    this.Serializable$set('sessionPathsRoot', value);
  }

  get skippedSessionPaths(): string|null {
    return (
        (this.Serializable$has('skippedSessionPaths')) ?
            (this.Serializable$get('skippedSessionPaths')) :
            (null));
  }

  /**
   * Number of skipped less common intent paths.
   */
  set skippedSessionPaths(value: string|null) {
    this.Serializable$set('skippedSessionPaths', value);
  }

  get successByIntent():
      Array<CloudConversationHourlyAgentAnalyticsValueByIntent>|null {
    return (
        (this.Serializable$has('successByIntent')) ?
            (this.Serializable$get('successByIntent')) :
            (null));
  }

  /**
   * Counts sliced by intent and next intent.
   */
  set successByIntent(
      value: Array<CloudConversationHourlyAgentAnalyticsValueByIntent>|null) {
    this.Serializable$set('successByIntent', value);
  }

  getConstructor():
      SerializableCtor<ListAnalyticsResponseAccumulatedIntentMetrics_> {
    return ListAnalyticsResponseAccumulatedIntentMetrics_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'successByIntent': CloudConversationHourlyAgentAnalyticsValueByIntent
      },
      keys: ['sessionPathsRoot', 'skippedSessionPaths', 'successByIntent'],
      objects: {
        'sessionPathsRoot':
            CloudConversationHourlyAgentAnalyticsValueSessionPathNode
      }
    };
  }
}

export interface ListAnalyticsResponse_Parameters {
  analytics?: Array<CloudConversationHourlyAgentAnalytics>|null;
  conversationAnalytics?: Array<CloudConversationHourlyConversationAnalytics>|
      null;
  accumulatedIntentAnalytics?: ListAnalyticsResponseAccumulatedIntentMetrics_|
      null;
  intentFlowStatus?: GoogleRpcStatus|null;
  nextPageToken?: string|null;
}
export class ListAnalyticsResponse_ extends Serializable {
  constructor(parameters: ListAnalyticsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'analytics',
        (parameters.analytics == null) ? (null) : (parameters.analytics));
    this.Serializable$set(
        'conversationAnalytics',
        (parameters.conversationAnalytics == null) ?
            (null) :
            (parameters.conversationAnalytics));
    this.Serializable$set(
        'accumulatedIntentAnalytics',
        (parameters.accumulatedIntentAnalytics == null) ?
            (null) :
            (parameters.accumulatedIntentAnalytics));
    this.Serializable$set(
        'intentFlowStatus',
        (parameters.intentFlowStatus == null) ? (null) :
                                                (parameters.intentFlowStatus));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get accumulatedIntentAnalytics():
      ListAnalyticsResponseAccumulatedIntentMetrics_|null {
    return (
        (this.Serializable$has('accumulatedIntentAnalytics')) ?
            (this.Serializable$get('accumulatedIntentAnalytics')) :
            (null));
  }

  /**
   * Accumulated per-intent analytics that are populated when
   * [ListAnalyticsRequest.accumulation_mode] is `ACCUMULATE_INTENT_METRICS`.
   */
  set accumulatedIntentAnalytics(
      value: ListAnalyticsResponseAccumulatedIntentMetrics_|null) {
    this.Serializable$set('accumulatedIntentAnalytics', value);
  }

  get analytics(): Array<CloudConversationHourlyAgentAnalytics>|null {
    return (
        (this.Serializable$has('analytics')) ?
            (this.Serializable$get('analytics')) :
            (null));
  }

  /**
   * Hourly virtual agent analytics. Note that we will only have analytics for
   * every hour in UTC and there may be gaps. The size of this is bounded by
   * [ListAnalyticsRequest.page_size].
   */
  set analytics(value: Array<CloudConversationHourlyAgentAnalytics>|null) {
    this.Serializable$set('analytics', value);
  }

  get conversationAnalytics():
      Array<CloudConversationHourlyConversationAnalytics>|null {
    return (
        (this.Serializable$has('conversationAnalytics')) ?
            (this.Serializable$get('conversationAnalytics')) :
            (null));
  }

  /**
   * Hourly virtual agent analytics. Note that we will only have analytics for
   * every hour in UTC and there may be gaps. The size of this is bounded by
   * [ListAnalyticsRequest.page_size].
   */
  set conversationAnalytics(
      value: Array<CloudConversationHourlyConversationAnalytics>|null) {
    this.Serializable$set('conversationAnalytics', value);
  }

  get intentFlowStatus(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('intentFlowStatus')) ?
            (this.Serializable$get('intentFlowStatus')) :
            (null));
  }

  /**
   * If the intent flow (that is,
   * success_by_intent.next_intent_display_names_in_session_and_hour) couldn't
   * be returned, this field contains the code.
   */
  set intentFlowStatus(value: GoogleRpcStatus|null) {
    this.Serializable$set('intentFlowStatus', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListAnalyticsResponse_> {
    return ListAnalyticsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'analytics': CloudConversationHourlyAgentAnalytics,
        'conversationAnalytics': CloudConversationHourlyConversationAnalytics
      },
      keys: [
        'accumulatedIntentAnalytics', 'analytics', 'conversationAnalytics',
        'intentFlowStatus', 'nextPageToken'
      ],
      objects: {
        'accumulatedIntentAnalytics':
            ListAnalyticsResponseAccumulatedIntentMetrics_,
        'intentFlowStatus': GoogleRpcStatus
      }
    };
  }
}

export interface ListAnnotatedConversationDatasetsResponse_Parameters {
  annotatedConversationDatasets?: Array<AnnotatedConversationDataset_>|null;
  nextPageToken?: string|null;
}
export class ListAnnotatedConversationDatasetsResponse_ extends Serializable {
  constructor(
      parameters: ListAnnotatedConversationDatasetsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'annotatedConversationDatasets',
        (parameters.annotatedConversationDatasets == null) ?
            (null) :
            (parameters.annotatedConversationDatasets));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get annotatedConversationDatasets():
      Array<AnnotatedConversationDataset_>|null {
    return (
        (this.Serializable$has('annotatedConversationDatasets')) ?
            (this.Serializable$get('annotatedConversationDatasets')) :
            (null));
  }

  /**
   * The list of annotated conversation datasets to return.
   */
  set annotatedConversationDatasets(value: Array<AnnotatedConversationDataset_>|
                                    null) {
    this.Serializable$set('annotatedConversationDatasets', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor():
      SerializableCtor<ListAnnotatedConversationDatasetsResponse_> {
    return ListAnnotatedConversationDatasetsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'annotatedConversationDatasets': AnnotatedConversationDataset_},
      keys: ['annotatedConversationDatasets', 'nextPageToken']
    };
  }
}

export interface ListAnswerRecordsResponse_Parameters {
  answerRecords?: Array<AnswerRecord_>|null;
  nextPageToken?: string|null;
}
export class ListAnswerRecordsResponse_ extends Serializable {
  constructor(parameters: ListAnswerRecordsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'answerRecords',
        (parameters.answerRecords == null) ? (null) :
                                             (parameters.answerRecords));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get answerRecords(): Array<AnswerRecord_>|null {
    return (
        (this.Serializable$has('answerRecords')) ?
            (this.Serializable$get('answerRecords')) :
            (null));
  }

  /**
   * The list of answer records.
   */
  set answerRecords(value: Array<AnswerRecord_>|null) {
    this.Serializable$set('answerRecords', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * A token to retrieve next page of results. Or empty if there are no more
   * results. Pass this value in the ListAnswerRecordsRequest.page_token field
   * in the subsequent call to `ListAnswerRecords` method to retrieve the next
   * page of results.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListAnswerRecordsResponse_> {
    return ListAnswerRecordsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'answerRecords': AnswerRecord_},
      keys: ['answerRecords', 'nextPageToken']
    };
  }
}

export interface ListAvailableAreaCodesResponse_Parameters {
  areaCodes?: Array<string>|null;
}
export class ListAvailableAreaCodesResponse_ extends Serializable {
  constructor(parameters: ListAvailableAreaCodesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'areaCodes',
        (parameters.areaCodes == null) ? (null) : (parameters.areaCodes));
  }

  get areaCodes(): Array<string>|null {
    return (
        (this.Serializable$has('areaCodes')) ?
            (this.Serializable$get('areaCodes')) :
            (null));
  }

  /**
   * The list of available area codes.
   */
  set areaCodes(value: Array<string>|null) {
    this.Serializable$set('areaCodes', value);
  }

  getConstructor(): SerializableCtor<ListAvailableAreaCodesResponse_> {
    return ListAvailableAreaCodesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['areaCodes']};
  }
}

export interface ListCallMatchersResponse_Parameters {
  callMatchers?: Array<CallMatcher_>|null;
  nextPageToken?: string|null;
}
export class ListCallMatchersResponse_ extends Serializable {
  constructor(parameters: ListCallMatchersResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'callMatchers',
        (parameters.callMatchers == null) ? (null) : (parameters.callMatchers));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get callMatchers(): Array<CallMatcher_>|null {
    return (
        (this.Serializable$has('callMatchers')) ?
            (this.Serializable$get('callMatchers')) :
            (null));
  }

  /**
   * The list of call matchers. There is a maximum number of items returned
   * based on the page_size field in the request.
   */
  set callMatchers(value: Array<CallMatcher_>|null) {
    this.Serializable$set('callMatchers', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListCallMatchersResponse_> {
    return ListCallMatchersResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'callMatchers': CallMatcher_},
      keys: ['callMatchers', 'nextPageToken']
    };
  }
}

export interface ListClusteredMessagesResponse_Parameters {
  messages?: Array<ClusteredMessage_>|null;
  nextPageToken?: string|null;
}
export class ListClusteredMessagesResponse_ extends Serializable {
  constructor(parameters: ListClusteredMessagesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get messages(): Array<ClusteredMessage_>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * The list of messages.
   */
  set messages(value: Array<ClusteredMessage_>|null) {
    this.Serializable$set('messages', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListClusteredMessagesResponse_> {
    return ListClusteredMessagesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'messages': ClusteredMessage_},
      keys: ['messages', 'nextPageToken']
    };
  }
}

export interface ListContextsResponse_Parameters {
  contexts?: Array<Context_>|null;
  nextPageToken?: string|null;
}
export class ListContextsResponse_ extends Serializable {
  constructor(parameters: ListContextsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'contexts',
        (parameters.contexts == null) ? (null) : (parameters.contexts));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get contexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('contexts')) ?
            (this.Serializable$get('contexts')) :
            (null));
  }

  /**
   * The list of contexts. There will be a maximum number of items returned
   * based on the page_size field in the request.
   */
  set contexts(value: Array<Context_>|null) {
    this.Serializable$set('contexts', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListContextsResponse_> {
    return ListContextsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'contexts': Context_},
      keys: ['contexts', 'nextPageToken']
    };
  }
}

export interface ListConversationAnnotationsResponse_Parameters {
  conversationAnnotations?: Array<ConversationAnnotation_>|null;
  nextPageToken?: string|null;
}
export class ListConversationAnnotationsResponse_ extends Serializable {
  constructor(parameters: ListConversationAnnotationsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationAnnotations',
        (parameters.conversationAnnotations == null) ?
            (null) :
            (parameters.conversationAnnotations));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get conversationAnnotations(): Array<ConversationAnnotation_>|null {
    return (
        (this.Serializable$has('conversationAnnotations')) ?
            (this.Serializable$get('conversationAnnotations')) :
            (null));
  }

  /**
   * The list of conversation annotations to return.
   */
  set conversationAnnotations(value: Array<ConversationAnnotation_>|null) {
    this.Serializable$set('conversationAnnotations', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListConversationAnnotationsResponse_> {
    return ListConversationAnnotationsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'conversationAnnotations': ConversationAnnotation_},
      keys: ['conversationAnnotations', 'nextPageToken']
    };
  }
}

export interface ListConversationDataItemsResponse_Parameters {
  conversationDataItems?: Array<ConversationDataItem_>|null;
  nextPageToken?: string|null;
}
export class ListConversationDataItemsResponse_ extends Serializable {
  constructor(parameters: ListConversationDataItemsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationDataItems',
        (parameters.conversationDataItems == null) ?
            (null) :
            (parameters.conversationDataItems));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get conversationDataItems(): Array<ConversationDataItem_>|null {
    return (
        (this.Serializable$has('conversationDataItems')) ?
            (this.Serializable$get('conversationDataItems')) :
            (null));
  }

  /**
   * The list of conversation data items to return.
   */
  set conversationDataItems(value: Array<ConversationDataItem_>|null) {
    this.Serializable$set('conversationDataItems', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListConversationDataItemsResponse_> {
    return ListConversationDataItemsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'conversationDataItems': ConversationDataItem_},
      keys: ['conversationDataItems', 'nextPageToken']
    };
  }
}

export interface ListConversationDatasetsResponse_Parameters {
  conversationDatasets?: Array<ConversationDataset_>|null;
  nextPageToken?: string|null;
}
export class ListConversationDatasetsResponse_ extends Serializable {
  constructor(parameters: ListConversationDatasetsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationDatasets',
        (parameters.conversationDatasets == null) ?
            (null) :
            (parameters.conversationDatasets));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get conversationDatasets(): Array<ConversationDataset_>|null {
    return (
        (this.Serializable$has('conversationDatasets')) ?
            (this.Serializable$get('conversationDatasets')) :
            (null));
  }

  /**
   * The list of datasets to return.
   */
  set conversationDatasets(value: Array<ConversationDataset_>|null) {
    this.Serializable$set('conversationDatasets', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListConversationDatasetsResponse_> {
    return ListConversationDatasetsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'conversationDatasets': ConversationDataset_},
      keys: ['conversationDatasets', 'nextPageToken']
    };
  }
}

export interface ListConversationModelEvaluationsResponse_Parameters {
  conversationModelEvaluations?: Array<ConversationModelEvaluation_>|null;
  nextPageToken?: string|null;
}
export class ListConversationModelEvaluationsResponse_ extends Serializable {
  constructor(
      parameters: ListConversationModelEvaluationsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationModelEvaluations',
        (parameters.conversationModelEvaluations == null) ?
            (null) :
            (parameters.conversationModelEvaluations));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get conversationModelEvaluations(): Array<ConversationModelEvaluation_>|null {
    return (
        (this.Serializable$has('conversationModelEvaluations')) ?
            (this.Serializable$get('conversationModelEvaluations')) :
            (null));
  }

  /**
   * The list of evaluations to return.
   */
  set conversationModelEvaluations(value: Array<ConversationModelEvaluation_>|
                                   null) {
    this.Serializable$set('conversationModelEvaluations', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor():
      SerializableCtor<ListConversationModelEvaluationsResponse_> {
    return ListConversationModelEvaluationsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'conversationModelEvaluations': ConversationModelEvaluation_},
      keys: ['conversationModelEvaluations', 'nextPageToken']
    };
  }
}

export interface ListConversationModelsResponse_Parameters {
  conversationModels?: Array<ConversationModel_>|null;
  nextPageToken?: string|null;
}
export class ListConversationModelsResponse_ extends Serializable {
  constructor(parameters: ListConversationModelsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationModels',
        (parameters.conversationModels == null) ?
            (null) :
            (parameters.conversationModels));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get conversationModels(): Array<ConversationModel_>|null {
    return (
        (this.Serializable$has('conversationModels')) ?
            (this.Serializable$get('conversationModels')) :
            (null));
  }

  /**
   * The list of models to return.
   */
  set conversationModels(value: Array<ConversationModel_>|null) {
    this.Serializable$set('conversationModels', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListConversationModelsResponse_> {
    return ListConversationModelsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'conversationModels': ConversationModel_},
      keys: ['conversationModels', 'nextPageToken']
    };
  }
}

export interface ListConversationProfilesResponse_Parameters {
  conversationProfiles?: Array<ConversationProfile_>|null;
  nextPageToken?: string|null;
}
export class ListConversationProfilesResponse_ extends Serializable {
  constructor(parameters: ListConversationProfilesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationProfiles',
        (parameters.conversationProfiles == null) ?
            (null) :
            (parameters.conversationProfiles));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get conversationProfiles(): Array<ConversationProfile_>|null {
    return (
        (this.Serializable$has('conversationProfiles')) ?
            (this.Serializable$get('conversationProfiles')) :
            (null));
  }

  /**
   * The list of project conversation profiles. There is a maximum number of
   * items returned based on the page_size field in the request.
   */
  set conversationProfiles(value: Array<ConversationProfile_>|null) {
    this.Serializable$set('conversationProfiles', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListConversationProfilesResponse_> {
    return ListConversationProfilesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'conversationProfiles': ConversationProfile_},
      keys: ['conversationProfiles', 'nextPageToken']
    };
  }
}

export interface ListConversationsResponse_Parameters {
  conversations?: Array<Conversation_>|null;
  nextPageToken?: string|null;
}
export class ListConversationsResponse_ extends Serializable {
  constructor(parameters: ListConversationsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversations',
        (parameters.conversations == null) ? (null) :
                                             (parameters.conversations));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get conversations(): Array<Conversation_>|null {
    return (
        (this.Serializable$has('conversations')) ?
            (this.Serializable$get('conversations')) :
            (null));
  }

  /**
   * The list of conversations. There will be a maximum number of items returned
   * based on the page_size field in the request.
   */
  set conversations(value: Array<Conversation_>|null) {
    this.Serializable$set('conversations', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListConversationsResponse_> {
    return ListConversationsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'conversations': Conversation_},
      keys: ['conversations', 'nextPageToken']
    };
  }
}

export interface ListDatasetsResponse_Parameters {
  datasets?: Array<Dataset_>|null;
  nextPageToken?: string|null;
}
export class ListDatasetsResponse_ extends Serializable {
  constructor(parameters: ListDatasetsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'datasets',
        (parameters.datasets == null) ? (null) : (parameters.datasets));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get datasets(): Array<Dataset_>|null {
    return (
        (this.Serializable$has('datasets')) ?
            (this.Serializable$get('datasets')) :
            (null));
  }

  /**
   * The list of datasets.
   */
  set datasets(value: Array<Dataset_>|null) {
    this.Serializable$set('datasets', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListDatasetsResponse_> {
    return ListDatasetsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'datasets': Dataset_},
      keys: ['datasets', 'nextPageToken']
    };
  }
}

export interface ListDocumentsResponse_Parameters {
  documents?: Array<Document_>|null;
  nextPageToken?: string|null;
}
export class ListDocumentsResponse_ extends Serializable {
  constructor(parameters: ListDocumentsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'documents',
        (parameters.documents == null) ? (null) : (parameters.documents));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get documents(): Array<Document_>|null {
    return (
        (this.Serializable$has('documents')) ?
            (this.Serializable$get('documents')) :
            (null));
  }

  /**
   * The list of documents.
   */
  set documents(value: Array<Document_>|null) {
    this.Serializable$set('documents', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListDocumentsResponse_> {
    return ListDocumentsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'documents': Document_},
      keys: ['documents', 'nextPageToken']
    };
  }
}

export interface ListEntityTypesResponse_Parameters {
  entityTypes?: Array<EntityType_>|null;
  nextPageToken?: string|null;
}
export class ListEntityTypesResponse_ extends Serializable {
  constructor(parameters: ListEntityTypesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'entityTypes',
        (parameters.entityTypes == null) ? (null) : (parameters.entityTypes));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get entityTypes(): Array<EntityType_>|null {
    return (
        (this.Serializable$has('entityTypes')) ?
            (this.Serializable$get('entityTypes')) :
            (null));
  }

  /**
   * The list of agent entity types. There will be a maximum number of items
   * returned based on the page_size field in the request.
   */
  set entityTypes(value: Array<EntityType_>|null) {
    this.Serializable$set('entityTypes', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListEntityTypesResponse_> {
    return ListEntityTypesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'entityTypes': EntityType_},
      keys: ['entityTypes', 'nextPageToken']
    };
  }
}

export interface ListEnvironmentsResponse_Parameters {
  environments?: Array<Environment_>|null;
  nextPageToken?: string|null;
}
export class ListEnvironmentsResponse_ extends Serializable {
  constructor(parameters: ListEnvironmentsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'environments',
        (parameters.environments == null) ? (null) : (parameters.environments));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get environments(): Array<Environment_>|null {
    return (
        (this.Serializable$has('environments')) ?
            (this.Serializable$get('environments')) :
            (null));
  }

  /**
   * The list of agent environments. There will be a maximum number of items
   * returned based on the page_size field in the request.
   */
  set environments(value: Array<Environment_>|null) {
    this.Serializable$set('environments', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListEnvironmentsResponse_> {
    return ListEnvironmentsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'environments': Environment_},
      keys: ['environments', 'nextPageToken']
    };
  }
}

export interface ListFeatureCreationFlowsResponse_Parameters {
  featureCreationFlows?: Array<FeatureCreationFlow_>|null;
  nextPageToken?: string|null;
}
export class ListFeatureCreationFlowsResponse_ extends Serializable {
  constructor(parameters: ListFeatureCreationFlowsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'featureCreationFlows',
        (parameters.featureCreationFlows == null) ?
            (null) :
            (parameters.featureCreationFlows));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get featureCreationFlows(): Array<FeatureCreationFlow_>|null {
    return (
        (this.Serializable$has('featureCreationFlows')) ?
            (this.Serializable$get('featureCreationFlows')) :
            (null));
  }

  /**
   * The list of models to return.
   */
  set featureCreationFlows(value: Array<FeatureCreationFlow_>|null) {
    this.Serializable$set('featureCreationFlows', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListFeatureCreationFlowsResponse_> {
    return ListFeatureCreationFlowsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'featureCreationFlows': FeatureCreationFlow_},
      keys: ['featureCreationFlows', 'nextPageToken']
    };
  }
}

export interface ListHumanAgentAssistantsResponse_Parameters {
  humanAgentAssistants?: Array<HumanAgentAssistant_>|null;
  nextPageToken?: string|null;
}
export class ListHumanAgentAssistantsResponse_ extends Serializable {
  constructor(parameters: ListHumanAgentAssistantsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'humanAgentAssistants',
        (parameters.humanAgentAssistants == null) ?
            (null) :
            (parameters.humanAgentAssistants));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get humanAgentAssistants(): Array<HumanAgentAssistant_>|null {
    return (
        (this.Serializable$has('humanAgentAssistants')) ?
            (this.Serializable$get('humanAgentAssistants')) :
            (null));
  }

  /**
   * The list of project agent assistants. There is a maximum number of items
   * returned based on the page_size field in the request.
   */
  set humanAgentAssistants(value: Array<HumanAgentAssistant_>|null) {
    this.Serializable$set('humanAgentAssistants', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListHumanAgentAssistantsResponse_> {
    return ListHumanAgentAssistantsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'humanAgentAssistants': HumanAgentAssistant_},
      keys: ['humanAgentAssistants', 'nextPageToken']
    };
  }
}

export interface ListIntegrationsResponse_Parameters {
  integrations?: Array<Integration_>|null;
  nextPageToken?: string|null;
}
export class ListIntegrationsResponse_ extends Serializable {
  constructor(parameters: ListIntegrationsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'integrations',
        (parameters.integrations == null) ? (null) : (parameters.integrations));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get integrations(): Array<Integration_>|null {
    return (
        (this.Serializable$has('integrations')) ?
            (this.Serializable$get('integrations')) :
            (null));
  }

  /**
   * Collection of integrations. Note: Only \"name\", \"integration_state\",
   * \"language\" and \"environment_name\" fields will be populated in the
   * ListIntegrationsResponse.
   */
  set integrations(value: Array<Integration_>|null) {
    this.Serializable$set('integrations', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListIntegrationsResponse_> {
    return ListIntegrationsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'integrations': Integration_},
      keys: ['integrations', 'nextPageToken']
    };
  }
}

export interface ListIntentDefinitionsResponse_Parameters {
  intentDefinitions?: Array<AogIntentDefinition_>|null;
  nextPageToken?: string|null;
}
export class ListIntentDefinitionsResponse_ extends Serializable {
  constructor(parameters: ListIntentDefinitionsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'intentDefinitions',
        (parameters.intentDefinitions == null) ?
            (null) :
            (parameters.intentDefinitions));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get intentDefinitions(): Array<AogIntentDefinition_>|null {
    return (
        (this.Serializable$has('intentDefinitions')) ?
            (this.Serializable$get('intentDefinitions')) :
            (null));
  }

  /**
   * List of intent definition objects.
   */
  set intentDefinitions(value: Array<AogIntentDefinition_>|null) {
    this.Serializable$set('intentDefinitions', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListIntentDefinitionsResponse_> {
    return ListIntentDefinitionsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'intentDefinitions': AogIntentDefinition_},
      keys: ['intentDefinitions', 'nextPageToken']
    };
  }
}

export interface ListIntentsResponse_Parameters {
  intents?: Array<Intent_>|null;
  nextPageToken?: string|null;
}
export class ListIntentsResponse_ extends Serializable {
  constructor(parameters: ListIntentsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'intents',
        (parameters.intents == null) ? (null) : (parameters.intents));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get intents(): Array<Intent_>|null {
    return (
        (this.Serializable$has('intents')) ?
            (this.Serializable$get('intents')) :
            (null));
  }

  /**
   * The list of agent intents. There will be a maximum number of items returned
   * based on the page_size field in the request.
   */
  set intents(value: Array<Intent_>|null) {
    this.Serializable$set('intents', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListIntentsResponse_> {
    return ListIntentsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'intents': Intent_}, keys: ['intents', 'nextPageToken']};
  }
}

export interface ListInteractionsResponse_Parameters {
  interactions?: Array<Interaction_>|null;
  nextPageToken?: string|null;
}
export class ListInteractionsResponse_ extends Serializable {
  constructor(parameters: ListInteractionsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'interactions',
        (parameters.interactions == null) ? (null) : (parameters.interactions));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get interactions(): Array<Interaction_>|null {
    return (
        (this.Serializable$has('interactions')) ?
            (this.Serializable$get('interactions')) :
            (null));
  }

  /**
   * The returned interactions.
   */
  set interactions(value: Array<Interaction_>|null) {
    this.Serializable$set('interactions', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * The token for fetching the next page of results.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListInteractionsResponse_> {
    return ListInteractionsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'interactions': Interaction_},
      keys: ['interactions', 'nextPageToken']
    };
  }
}

export interface ListIssueModelingEntriesResponse_Parameters {
  issueModelingEntries?: Array<IssueModelingEntry_>|null;
  nextPageToken?: string|null;
}
export class ListIssueModelingEntriesResponse_ extends Serializable {
  constructor(parameters: ListIssueModelingEntriesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'issueModelingEntries',
        (parameters.issueModelingEntries == null) ?
            (null) :
            (parameters.issueModelingEntries));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get issueModelingEntries(): Array<IssueModelingEntry_>|null {
    return (
        (this.Serializable$has('issueModelingEntries')) ?
            (this.Serializable$get('issueModelingEntries')) :
            (null));
  }

  /**
   * The list of issue modeling entries.
   */
  set issueModelingEntries(value: Array<IssueModelingEntry_>|null) {
    this.Serializable$set('issueModelingEntries', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * A token to retrieve next page of results. Pass this value in the
   * ListIssueModelingEntriesRequest.page_token filed in the subsequent call to
   * 'ListIssueModelingEntriesRequest' method to retrieve the next page of
   * results.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListIssueModelingEntriesResponse_> {
    return ListIssueModelingEntriesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'issueModelingEntries': IssueModelingEntry_},
      keys: ['issueModelingEntries', 'nextPageToken']
    };
  }
}

export interface ListIvrNodesResponse_Parameters {
  nodes?: Array<IvrNode_>|null;
  nextPageToken?: string|null;
}
export class ListIvrNodesResponse_ extends Serializable {
  constructor(parameters: ListIvrNodesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'nodes', (parameters.nodes == null) ? (null) : (parameters.nodes));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get nodes(): Array<IvrNode_>|null {
    return (
        (this.Serializable$has('nodes')) ? (this.Serializable$get('nodes')) :
                                           (null));
  }

  /**
   * The list of IVR nodes.
   */
  set nodes(value: Array<IvrNode_>|null) {
    this.Serializable$set('nodes', value);
  }

  getConstructor(): SerializableCtor<ListIvrNodesResponse_> {
    return ListIvrNodesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'nodes': IvrNode_}, keys: ['nextPageToken', 'nodes']};
  }
}

export interface ListKnowledgeBasesResponse_Parameters {
  knowledgeBases?: Array<KnowledgeBase_>|null;
  nextPageToken?: string|null;
}
export class ListKnowledgeBasesResponse_ extends Serializable {
  constructor(parameters: ListKnowledgeBasesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'knowledgeBases',
        (parameters.knowledgeBases == null) ? (null) :
                                              (parameters.knowledgeBases));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get knowledgeBases(): Array<KnowledgeBase_>|null {
    return (
        (this.Serializable$has('knowledgeBases')) ?
            (this.Serializable$get('knowledgeBases')) :
            (null));
  }

  /**
   * The list of knowledge bases.
   */
  set knowledgeBases(value: Array<KnowledgeBase_>|null) {
    this.Serializable$set('knowledgeBases', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListKnowledgeBasesResponse_> {
    return ListKnowledgeBasesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'knowledgeBases': KnowledgeBase_},
      keys: ['knowledgeBases', 'nextPageToken']
    };
  }
}

export interface ListMessageClustersResponse_Parameters {
  messageClusters?: Array<MessageCluster_>|null;
  nextPageToken?: string|null;
}
export class ListMessageClustersResponse_ extends Serializable {
  constructor(parameters: ListMessageClustersResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'messageClusters',
        (parameters.messageClusters == null) ? (null) :
                                               (parameters.messageClusters));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get messageClusters(): Array<MessageCluster_>|null {
    return (
        (this.Serializable$has('messageClusters')) ?
            (this.Serializable$get('messageClusters')) :
            (null));
  }

  /**
   * The list of message clusters. Only top clustered messages are populated.
   */
  set messageClusters(value: Array<MessageCluster_>|null) {
    this.Serializable$set('messageClusters', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListMessageClustersResponse_> {
    return ListMessageClustersResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'messageClusters': MessageCluster_},
      keys: ['messageClusters', 'nextPageToken']
    };
  }
}

export interface ListMessageEdgesResponse_Parameters {
  edges?: Array<MessageEdge_>|null;
  nextPageToken?: string|null;
}
export class ListMessageEdgesResponse_ extends Serializable {
  constructor(parameters: ListMessageEdgesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'edges', (parameters.edges == null) ? (null) : (parameters.edges));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get edges(): Array<MessageEdge_>|null {
    return (
        (this.Serializable$has('edges')) ? (this.Serializable$get('edges')) :
                                           (null));
  }

  /**
   * The list of message edges.
   */
  set edges(value: Array<MessageEdge_>|null) {
    this.Serializable$set('edges', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListMessageEdgesResponse_> {
    return ListMessageEdgesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'edges': MessageEdge_}, keys: ['edges', 'nextPageToken']};
  }
}

export interface ListMessageNodesResponse_Parameters {
  nodes?: Array<MessageNode_>|null;
  nextPageToken?: string|null;
}
export class ListMessageNodesResponse_ extends Serializable {
  constructor(parameters: ListMessageNodesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'nodes', (parameters.nodes == null) ? (null) : (parameters.nodes));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get nodes(): Array<MessageNode_>|null {
    return (
        (this.Serializable$has('nodes')) ? (this.Serializable$get('nodes')) :
                                           (null));
  }

  /**
   * The list of message nodes.
   */
  set nodes(value: Array<MessageNode_>|null) {
    this.Serializable$set('nodes', value);
  }

  getConstructor(): SerializableCtor<ListMessageNodesResponse_> {
    return ListMessageNodesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'nodes': MessageNode_}, keys: ['nextPageToken', 'nodes']};
  }
}

export interface ListMessagesResponse_Parameters {
  messages?: Array<Message_>|null;
  nextPageToken?: string|null;
}
export class ListMessagesResponse_ extends Serializable {
  constructor(parameters: ListMessagesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get messages(): Array<Message_>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * Required. The list of messages. There will be a maximum number of items
   * returned based on the page_size field in the request. `messages` is sorted
   * by `create_time` in descending order.
   */
  set messages(value: Array<Message_>|null) {
    this.Serializable$set('messages', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Optional. Token to retrieve the next page of results, or empty if there are
   * no more results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListMessagesResponse_> {
    return ListMessagesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'messages': Message_},
      keys: ['messages', 'nextPageToken']
    };
  }
}

export interface ListParticipantsResponse_Parameters {
  participants?: Array<Participant_>|null;
  nextPageToken?: string|null;
}
export class ListParticipantsResponse_ extends Serializable {
  constructor(parameters: ListParticipantsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'participants',
        (parameters.participants == null) ? (null) : (parameters.participants));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get participants(): Array<Participant_>|null {
    return (
        (this.Serializable$has('participants')) ?
            (this.Serializable$get('participants')) :
            (null));
  }

  /**
   * The list of participants. There is a maximum number of items returned based
   * on the page_size field in the request.
   */
  set participants(value: Array<Participant_>|null) {
    this.Serializable$set('participants', value);
  }

  getConstructor(): SerializableCtor<ListParticipantsResponse_> {
    return ListParticipantsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'participants': Participant_},
      keys: ['nextPageToken', 'participants']
    };
  }
}

export interface ListPastCallCompanionEventsResponse_Parameters {
  callCompanionConversationEvents?: Array<CallCompanionConversationEvent_>|null;
  nextPageToken?: string|null;
}
export class ListPastCallCompanionEventsResponse_ extends Serializable {
  constructor(parameters: ListPastCallCompanionEventsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'callCompanionConversationEvents',
        (parameters.callCompanionConversationEvents == null) ?
            (null) :
            (parameters.callCompanionConversationEvents));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get callCompanionConversationEvents():
      Array<CallCompanionConversationEvent_>|null {
    return (
        (this.Serializable$has('callCompanionConversationEvents')) ?
            (this.Serializable$get('callCompanionConversationEvents')) :
            (null));
  }

  /**
   * List of events in the conversation transcript.
   */
  set callCompanionConversationEvents(
      value: Array<CallCompanionConversationEvent_>|null) {
    this.Serializable$set('callCompanionConversationEvents', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<ListPastCallCompanionEventsResponse_> {
    return ListPastCallCompanionEventsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'callCompanionConversationEvents': CallCompanionConversationEvent_},
      keys: ['callCompanionConversationEvents', 'nextPageToken']
    };
  }
}

export interface ListPhoneNumberOrdersResponse_Parameters {
  phoneNumberOrders?: Array<PhoneNumberOrder_>|null;
  nextPageToken?: string|null;
}
export class ListPhoneNumberOrdersResponse_ extends Serializable {
  constructor(parameters: ListPhoneNumberOrdersResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'phoneNumberOrders',
        (parameters.phoneNumberOrders == null) ?
            (null) :
            (parameters.phoneNumberOrders));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get phoneNumberOrders(): Array<PhoneNumberOrder_>|null {
    return (
        (this.Serializable$has('phoneNumberOrders')) ?
            (this.Serializable$get('phoneNumberOrders')) :
            (null));
  }

  /**
   * The list of orders. There is a maximum number of items returned based on
   * the page_size field in the request.
   */
  set phoneNumberOrders(value: Array<PhoneNumberOrder_>|null) {
    this.Serializable$set('phoneNumberOrders', value);
  }

  getConstructor(): SerializableCtor<ListPhoneNumberOrdersResponse_> {
    return ListPhoneNumberOrdersResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'phoneNumberOrders': PhoneNumberOrder_},
      keys: ['nextPageToken', 'phoneNumberOrders']
    };
  }
}

export interface ListPhoneNumbersResponse_Parameters {
  phoneNumbers?: Array<PhoneNumber_>|null;
  nextPageToken?: string|null;
}
export class ListPhoneNumbersResponse_ extends Serializable {
  constructor(parameters: ListPhoneNumbersResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'phoneNumbers',
        (parameters.phoneNumbers == null) ? (null) : (parameters.phoneNumbers));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get phoneNumbers(): Array<PhoneNumber_>|null {
    return (
        (this.Serializable$has('phoneNumbers')) ?
            (this.Serializable$get('phoneNumbers')) :
            (null));
  }

  /**
   * The list of `PhoneNumber` resources. There is a maximum number of items
   * returned based on the page_size field in the request.
   */
  set phoneNumbers(value: Array<PhoneNumber_>|null) {
    this.Serializable$set('phoneNumbers', value);
  }

  getConstructor(): SerializableCtor<ListPhoneNumbersResponse_> {
    return ListPhoneNumbersResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'phoneNumbers': PhoneNumber_},
      keys: ['nextPageToken', 'phoneNumbers']
    };
  }
}

export interface ListQuestionAnswerEntriesResponse_Parameters {
  questionAnswerEntries?: Array<QuestionAnswerEntry_>|null;
  nextPageToken?: string|null;
}
export class ListQuestionAnswerEntriesResponse_ extends Serializable {
  constructor(parameters: ListQuestionAnswerEntriesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'questionAnswerEntries',
        (parameters.questionAnswerEntries == null) ?
            (null) :
            (parameters.questionAnswerEntries));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * A token to retrieve next page of results. Pass this value in the
   * ListQuestionAnswerEntriesRequest.page_token field in the subsequent call to
   * `ListQuestionAnswerEntriesRequest` method to retrieve the next page of
   * results.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get questionAnswerEntries(): Array<QuestionAnswerEntry_>|null {
    return (
        (this.Serializable$has('questionAnswerEntries')) ?
            (this.Serializable$get('questionAnswerEntries')) :
            (null));
  }

  /**
   * The list of question-answer-entries.
   */
  set questionAnswerEntries(value: Array<QuestionAnswerEntry_>|null) {
    this.Serializable$set('questionAnswerEntries', value);
  }

  getConstructor(): SerializableCtor<ListQuestionAnswerEntriesResponse_> {
    return ListQuestionAnswerEntriesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'questionAnswerEntries': QuestionAnswerEntry_},
      keys: ['nextPageToken', 'questionAnswerEntries']
    };
  }
}

export interface ListSessionEntityTypesResponse_Parameters {
  sessionEntityTypes?: Array<SessionEntityType_>|null;
  nextPageToken?: string|null;
}
export class ListSessionEntityTypesResponse_ extends Serializable {
  constructor(parameters: ListSessionEntityTypesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'sessionEntityTypes',
        (parameters.sessionEntityTypes == null) ?
            (null) :
            (parameters.sessionEntityTypes));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get sessionEntityTypes(): Array<SessionEntityType_>|null {
    return (
        (this.Serializable$has('sessionEntityTypes')) ?
            (this.Serializable$get('sessionEntityTypes')) :
            (null));
  }

  /**
   * The list of session entity types. There will be a maximum number of items
   * returned based on the page_size field in the request.
   */
  set sessionEntityTypes(value: Array<SessionEntityType_>|null) {
    this.Serializable$set('sessionEntityTypes', value);
  }

  getConstructor(): SerializableCtor<ListSessionEntityTypesResponse_> {
    return ListSessionEntityTypesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'sessionEntityTypes': SessionEntityType_},
      keys: ['nextPageToken', 'sessionEntityTypes']
    };
  }
}

export interface ListSmartMessagingEntriesResponse_Parameters {
  smartMessagingEntries?: Array<SmartMessagingEntry_>|null;
  totalSize?: number|null;
  nextPageToken?: string|null;
}
export class ListSmartMessagingEntriesResponse_ extends Serializable {
  constructor(parameters: ListSmartMessagingEntriesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'smartMessagingEntries',
        (parameters.smartMessagingEntries == null) ?
            (null) :
            (parameters.smartMessagingEntries));
    this.Serializable$set(
        'totalSize',
        (parameters.totalSize == null) ? (null) : (parameters.totalSize));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * A token to retrieve next page of results. Pass this value in the
   * ListSmartMessagingEntriesRequest.page_token field in the subsequent call to
   * [Documents.ListSmartMessagingEntries] method to retrieve the next page of
   * results.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get smartMessagingEntries(): Array<SmartMessagingEntry_>|null {
    return (
        (this.Serializable$has('smartMessagingEntries')) ?
            (this.Serializable$get('smartMessagingEntries')) :
            (null));
  }

  /**
   * List of smart message entries.
   */
  set smartMessagingEntries(value: Array<SmartMessagingEntry_>|null) {
    this.Serializable$set('smartMessagingEntries', value);
  }

  get totalSize(): number|null {
    return (
        (this.Serializable$has('totalSize')) ?
            (this.Serializable$get('totalSize')) :
            (null));
  }

  /**
   * Total count of messaging entries in the list irrespective of pagination.
   */
  set totalSize(value: number|null) {
    this.Serializable$set('totalSize', value);
  }

  getConstructor(): SerializableCtor<ListSmartMessagingEntriesResponse_> {
    return ListSmartMessagingEntriesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'smartMessagingEntries': SmartMessagingEntry_},
      keys: ['nextPageToken', 'smartMessagingEntries', 'totalSize']
    };
  }
}

export interface ListSpecialistPoolsResponse_Parameters {
  specialistPools?: Array<SpecialistPool_>|null;
  nextPageToken?: string|null;
}
export class ListSpecialistPoolsResponse_ extends Serializable {
  constructor(parameters: ListSpecialistPoolsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'specialistPools',
        (parameters.specialistPools == null) ? (null) :
                                               (parameters.specialistPools));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get specialistPools(): Array<SpecialistPool_>|null {
    return (
        (this.Serializable$has('specialistPools')) ?
            (this.Serializable$get('specialistPools')) :
            (null));
  }

  /**
   * The list of specialist pools to return.
   */
  set specialistPools(value: Array<SpecialistPool_>|null) {
    this.Serializable$set('specialistPools', value);
  }

  getConstructor(): SerializableCtor<ListSpecialistPoolsResponse_> {
    return ListSpecialistPoolsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'specialistPools': SpecialistPool_},
      keys: ['nextPageToken', 'specialistPools']
    };
  }
}

export interface ListSuggestionsResponse_Parameters {
  suggestions?: Array<Suggestion_>|null;
  nextPageToken?: string|null;
}
export class ListSuggestionsResponse_ extends Serializable {
  constructor(parameters: ListSuggestionsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'suggestions',
        (parameters.suggestions == null) ? (null) : (parameters.suggestions));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Optional. Token to retrieve the next page of results or empty if there are
   * no more results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get suggestions(): Array<Suggestion_>|null {
    return (
        (this.Serializable$has('suggestions')) ?
            (this.Serializable$get('suggestions')) :
            (null));
  }

  /**
   * Required. The list of suggestions. There will be a maximum number of items
   * returned based on the page_size field in the request. `suggestions` is
   * sorted by `create_time` in descending order.
   */
  set suggestions(value: Array<Suggestion_>|null) {
    this.Serializable$set('suggestions', value);
  }

  getConstructor(): SerializableCtor<ListSuggestionsResponse_> {
    return ListSuggestionsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'suggestions': Suggestion_},
      keys: ['nextPageToken', 'suggestions']
    };
  }
}

export interface ListTopicsResponse_Parameters {
  topics?: Array<Topic_>|null;
}
export class ListTopicsResponse_ extends Serializable {
  constructor(parameters: ListTopicsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'topics', (parameters.topics == null) ? (null) : (parameters.topics));
  }

  get topics(): Array<Topic_>|null {
    return (
        (this.Serializable$has('topics')) ? (this.Serializable$get('topics')) :
                                            (null));
  }

  /**
   * The list of topics.
   */
  set topics(value: Array<Topic_>|null) {
    this.Serializable$set('topics', value);
  }

  getConstructor(): SerializableCtor<ListTopicsResponse_> {
    return ListTopicsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'topics': Topic_}, keys: ['topics']};
  }
}

export interface ListVersionsResponse_Parameters {
  versions?: Array<Version_>|null;
  nextPageToken?: string|null;
}
export class ListVersionsResponse_ extends Serializable {
  constructor(parameters: ListVersionsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'versions',
        (parameters.versions == null) ? (null) : (parameters.versions));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get versions(): Array<Version_>|null {
    return (
        (this.Serializable$has('versions')) ?
            (this.Serializable$get('versions')) :
            (null));
  }

  /**
   * The list of agent versions. There will be a maximum number of items
   * returned based on the page_size field in the request.
   */
  set versions(value: Array<Version_>|null) {
    this.Serializable$set('versions', value);
  }

  getConstructor(): SerializableCtor<ListVersionsResponse_> {
    return ListVersionsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'versions': Version_},
      keys: ['nextPageToken', 'versions']
    };
  }
}

export interface LoggingConfig_Parameters {
  enableStackdriverLogging?: boolean|null;
}
export class LoggingConfig_ extends Serializable {
  constructor(parameters: LoggingConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'enableStackdriverLogging',
        (parameters.enableStackdriverLogging == null) ?
            (null) :
            (parameters.enableStackdriverLogging));
  }

  get enableStackdriverLogging(): boolean|null {
    return (
        (this.Serializable$has('enableStackdriverLogging')) ?
            (this.Serializable$get('enableStackdriverLogging')) :
            (null));
  }

  /**
   * Whether to log conversation events like CONVERSATION_STARTED to Stackdriver
   * in the conversation project as JSON format ConversationEvent protos.
   */
  set enableStackdriverLogging(value: boolean|null) {
    this.Serializable$set('enableStackdriverLogging', value);
  }

  getConstructor(): SerializableCtor<LoggingConfig_> {
    return LoggingConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enableStackdriverLogging']};
  }
}

export interface LoopingIntent_Parameters {
  messages?: Array<IntentMessage_>|null;
  allowBargeIn?: boolean|null;
  webhookState?: LoopingIntent_WebhookState|null;
  inputContextNames?: Array<string>|null;
  id?: string|null;
  action?: string|null;
  condition?: string|null;
  conditionalMessages?: Array<IntentConditionalMessage_>|null;
}
export class LoopingIntent_ extends Serializable {
  constructor(parameters: LoopingIntent_Parameters = {}) {
    super();
    this.Serializable$set(
        'messages',
        (parameters.messages == null) ? (null) : (parameters.messages));
    this.Serializable$set(
        'allowBargeIn',
        (parameters.allowBargeIn == null) ? (null) : (parameters.allowBargeIn));
    this.Serializable$set(
        'webhookState',
        (parameters.webhookState == null) ? (null) : (parameters.webhookState));
    this.Serializable$set(
        'inputContextNames',
        (parameters.inputContextNames == null) ?
            (null) :
            (parameters.inputContextNames));
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'condition',
        (parameters.condition == null) ? (null) : (parameters.condition));
    this.Serializable$set(
        'conditionalMessages',
        (parameters.conditionalMessages == null) ?
            (null) :
            (parameters.conditionalMessages));
  }

  static get WebhookState(): ILoopingIntent_WebhookStateEnum {
    return LoopingIntent_WebhookStateEnum;
  }

  get action(): string|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Optional. The name of the action associated with the intent. Note: The
   * action name must not contain whitespaces.
   */
  set action(value: string|null) {
    this.Serializable$set('action', value);
  }

  get allowBargeIn(): boolean|null {
    return (
        (this.Serializable$has('allowBargeIn')) ?
            (this.Serializable$get('allowBargeIn')) :
            (null));
  }

  /**
   * Optional. Whether barge-in is allowed for this intent's responses. If no
   * value is present, we use the setting defined at node level.
   */
  set allowBargeIn(value: boolean|null) {
    this.Serializable$set('allowBargeIn', value);
  }

  get condition(): string|null {
    return (
        (this.Serializable$has('condition')) ?
            (this.Serializable$get('condition')) :
            (null));
  }

  /**
   * Optional. The condition used to activate the intent for being matched. This
   * is a boolean logical expression. If it is true, the intent is activated. It
   * can refer to context parameters in the form of
   * `#my-context-name.params.my-param-name`. See the [conditions
   * reference](https://cloud.google.com/dialogflow/priv/docs/v3/condition). By
   * default, the condition is true and the intent is always activated.
   */
  set condition(value: string|null) {
    this.Serializable$set('condition', value);
  }

  get conditionalMessages(): Array<IntentConditionalMessage_>|null {
    return (
        (this.Serializable$has('conditionalMessages')) ?
            (this.Serializable$get('conditionalMessages')) :
            (null));
  }

  /**
   * Optional. Represents a list of conditional messages. Each case in the
   * message is associated with a condition. During query time, Dialogflow will
   * never populate conditional_messages. Instead, Dialogflow will append all
   * messages with conditions that are evaluated to true to messages.
   */
  set conditionalMessages(value: Array<IntentConditionalMessage_>|null) {
    this.Serializable$set('conditionalMessages', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Required. ID of the intent. Must be unique among all intents of the same
   * category.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get inputContextNames(): Array<string>|null {
    return (
        (this.Serializable$has('inputContextNames')) ?
            (this.Serializable$get('inputContextNames')) :
            (null));
  }

  /**
   * Optional. List of contexts that must be present in order to trigger this
   * intent.
   */
  set inputContextNames(value: Array<string>|null) {
    this.Serializable$set('inputContextNames', value);
  }

  get messages(): Array<IntentMessage_>|null {
    return (
        (this.Serializable$has('messages')) ?
            (this.Serializable$get('messages')) :
            (null));
  }

  /**
   * Required. List of response messages to prompt if the intent is matched.
   */
  set messages(value: Array<IntentMessage_>|null) {
    this.Serializable$set('messages', value);
  }

  get webhookState(): LoopingIntent_WebhookState|null {
    return (
        (this.Serializable$has('webhookState')) ?
            (this.Serializable$get('webhookState')) :
            (null));
  }

  /**
   * Optional. Whether webhook should be called if this intent is matched.
   */
  set webhookState(value: LoopingIntent_WebhookState|null) {
    this.Serializable$set('webhookState', value);
  }

  getConstructor(): SerializableCtor<LoopingIntent_> {
    return LoopingIntent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'conditionalMessages': IntentConditionalMessage_,
        'messages': IntentMessage_
      },
      enums: {'webhookState': LoopingIntent_WebhookStateEnum},
      keys: [
        'action', 'allowBargeIn', 'condition', 'conditionalMessages', 'id',
        'inputContextNames', 'messages', 'webhookState'
      ]
    };
  }
}

export interface MatchEntityTypeRequest_Parameters {
  tokens?: Array<string>|null;
}
export class MatchEntityTypeRequest_ extends Serializable {
  constructor(parameters: MatchEntityTypeRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'tokens', (parameters.tokens == null) ? (null) : (parameters.tokens));
  }

  get tokens(): Array<string>|null {
    return (
        (this.Serializable$has('tokens')) ? (this.Serializable$get('tokens')) :
                                            (null));
  }

  /**
   * list of tokens to match against specified system entity type.
   */
  set tokens(value: Array<string>|null) {
    this.Serializable$set('tokens', value);
  }

  getConstructor(): SerializableCtor<MatchEntityTypeRequest_> {
    return MatchEntityTypeRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['tokens']};
  }
}

export interface MatchEntityTypeResponse_Parameters {
  matchedTokensMap?: ApiClientObjectMap<boolean>|null;
}
export class MatchEntityTypeResponse_ extends Serializable {
  constructor(parameters: MatchEntityTypeResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'matchedTokensMap',
        (parameters.matchedTokensMap == null) ? (null) :
                                                (parameters.matchedTokensMap));
  }

  get matchedTokensMap(): ApiClientObjectMap<boolean>|null {
    return (
        (this.Serializable$has('matchedTokensMap')) ?
            (this.Serializable$get('matchedTokensMap')) :
            (null));
  }

  /**
   * Map of matched and non-matched tokens.
   */
  set matchedTokensMap(value: ApiClientObjectMap<boolean>|null) {
    this.Serializable$set('matchedTokensMap', value);
  }

  getConstructor(): SerializableCtor<MatchEntityTypeResponse_> {
    return MatchEntityTypeResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['matchedTokensMap'],
      objectMaps: {
        'matchedTokensMap': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface MatchIntentRequest_Parameters {
  queryParams?: QueryParameters_|null;
  queryInput?: QueryInput_|null;
  inputAudio?: string|null;
  disableLogging?: boolean|null;
  experimentFlags?: ApiClientObjectMap<string>|null;
}
export class MatchIntentRequest_ extends Serializable {
  constructor(parameters: MatchIntentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'queryParams',
        (parameters.queryParams == null) ? (null) : (parameters.queryParams));
    this.Serializable$set(
        'queryInput',
        (parameters.queryInput == null) ? (null) : (parameters.queryInput));
    this.Serializable$set(
        'inputAudio',
        (parameters.inputAudio == null) ? (null) : (parameters.inputAudio));
    this.Serializable$set(
        'disableLogging',
        (parameters.disableLogging == null) ? (null) :
                                              (parameters.disableLogging));
    this.Serializable$set(
        'experimentFlags',
        (parameters.experimentFlags == null) ? (null) :
                                               (parameters.experimentFlags));
  }

  get disableLogging(): boolean|null {
    return (
        (this.Serializable$has('disableLogging')) ?
            (this.Serializable$get('disableLogging')) :
            (null));
  }

  /**
   * Whether logging at all backends of user input and agent responses should be
   * disabled. This will prevent TextMessage, Dialogflow History, Stackdriver
   * logging, Speech logging etc. from being stored onto DB. NOTE: This field is
   * only for internal usage. It's used for passing arguments of API calls from
   * upstream components to downstream ones. It will not be exposed in any way.
   */
  set disableLogging(value: boolean|null) {
    this.Serializable$set('disableLogging', value);
  }

  get experimentFlags(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('experimentFlags')) ?
            (this.Serializable$get('experimentFlags')) :
            (null));
  }

  /**
   * Frontend experiment flags to pass to the runtime.
   */
  set experimentFlags(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('experimentFlags', value);
  }

  get inputAudio(): string|null {
    return (
        (this.Serializable$has('inputAudio')) ?
            (this.Serializable$get('inputAudio')) :
            (null));
  }

  /**
   * The natural language speech audio to be processed. This field should be
   * populated iff `query_input` is set to an input audio config. A single
   * request can contain up to 1 minute of speech audio data.
   */
  set inputAudio(value: string|null) {
    this.Serializable$set('inputAudio', value);
  }

  get queryInput(): QueryInput_|null {
    return (
        (this.Serializable$has('queryInput')) ?
            (this.Serializable$get('queryInput')) :
            (null));
  }

  /**
   * Required. The input specification. It can be set to: 1. an audio config
   * which instructs the speech recognizer how to process the speech audio, 2. a
   * conversational query in the form of text, or 3. an event that specifies
   * which intent to trigger.
   */
  set queryInput(value: QueryInput_|null) {
    this.Serializable$set('queryInput', value);
  }

  get queryParams(): QueryParameters_|null {
    return (
        (this.Serializable$has('queryParams')) ?
            (this.Serializable$get('queryParams')) :
            (null));
  }

  /**
   * The parameters of this query.
   */
  set queryParams(value: QueryParameters_|null) {
    this.Serializable$set('queryParams', value);
  }

  getConstructor(): SerializableCtor<MatchIntentRequest_> {
    return MatchIntentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'disableLogging', 'experimentFlags', 'inputAudio', 'queryInput',
        'queryParams'
      ],
      objectMaps: {
        'experimentFlags': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'queryInput': QueryInput_, 'queryParams': QueryParameters_}
    };
  }
}

export interface MatchIntentResponse_Parameters {
  queryText?: string|null;
  matches?: Array<Match_>|null;
}
export class MatchIntentResponse_ extends Serializable {
  constructor(parameters: MatchIntentResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'queryText',
        (parameters.queryText == null) ? (null) : (parameters.queryText));
    this.Serializable$set(
        'matches',
        (parameters.matches == null) ? (null) : (parameters.matches));
  }

  get matches(): Array<Match_>|null {
    return (
        (this.Serializable$has('matches')) ?
            (this.Serializable$get('matches')) :
            (null));
  }

  /**
   * Match results, if more than one, ordered descendingly by the confidence we
   * have that the particular intent matches the query.
   */
  set matches(value: Array<Match_>|null) {
    this.Serializable$set('matches', value);
  }

  get queryText(): string|null {
    return (
        (this.Serializable$has('queryText')) ?
            (this.Serializable$get('queryText')) :
            (null));
  }

  /**
   * The original conversational query text: - If natural language text was
   * provided as input, `query_text` contains a copy of the input. - If natural
   * language speech audio was provided as input, `query_text` contains the
   * speech recognition result. If speech recognizer produced multiple
   * alternatives, a particular one is picked. - If automatic spell correction
   * is enabled, `query_text` will contain the corrected user input.
   */
  set queryText(value: string|null) {
    this.Serializable$set('queryText', value);
  }

  getConstructor(): SerializableCtor<MatchIntentResponse_> {
    return MatchIntentResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'matches': Match_}, keys: ['matches', 'queryText']};
  }
}

export interface Match_Parameters {
  intent?: Intent_|null;
  parameters?: ApiClientObjectMap<any>|null;
  knowledgeAnswers?: KnowledgeAnswers_|null;
  sentimentAnalysisResult?: SentimentAnalysisResult_|null;
  resolvedInput?: string|null;
  matchType?: Match_MatchType|null;
}
export class Match_ extends Serializable {
  constructor(parameters: Match_Parameters = {}) {
    super();
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'knowledgeAnswers',
        (parameters.knowledgeAnswers == null) ? (null) :
                                                (parameters.knowledgeAnswers));
    this.Serializable$set(
        'sentimentAnalysisResult',
        (parameters.sentimentAnalysisResult == null) ?
            (null) :
            (parameters.sentimentAnalysisResult));
    this.Serializable$set(
        'resolvedInput',
        (parameters.resolvedInput == null) ? (null) :
                                             (parameters.resolvedInput));
    this.Serializable$set(
        'matchType',
        (parameters.matchType == null) ? (null) : (parameters.matchType));
  }

  static get MatchType(): IMatch_MatchTypeEnum {
    return Match_MatchTypeEnum;
  }

  get intent(): Intent_|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The intent that matched the conversational query. Some, not all fields are
   * filled in this message, including but not limited to: name, display_name,
   * end_interaction and is_fallback.
   */
  set intent(value: Intent_|null) {
    this.Serializable$set('intent', value);
  }

  get knowledgeAnswers(): KnowledgeAnswers_|null {
    return (
        (this.Serializable$has('knowledgeAnswers')) ?
            (this.Serializable$get('knowledgeAnswers')) :
            (null));
  }

  /**
   * The knowledge answers that matched the conversational query. Empty when
   * regular intent matched.
   */
  set knowledgeAnswers(value: KnowledgeAnswers_|null) {
    this.Serializable$set('knowledgeAnswers', value);
  }

  get matchType(): Match_MatchType|null {
    return (
        (this.Serializable$has('matchType')) ?
            (this.Serializable$get('matchType')) :
            (null));
  }

  /**
   * Type of this Match. This field is used by V2/V3 router internally to
   * determine if the request should go through V2 or V3.
   */
  set matchType(value: Match_MatchType|null) {
    this.Serializable$set('matchType', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of parameters extracted from the query. Depending on your
   * protocol or client library language, this is a map, associative array,
   * symbol table, dictionary, or JSON object composed of a collection of
   * (MapKey, MapValue) pairs: - MapKey type: string - MapKey value: parameter
   * name - MapValue type: - If parameter's entity type is a composite entity:
   * map - Else: depending on parameter value type, could be one of string,
   * number, boolean, null, list or map - MapValue value: - If parameter's
   * entity type is a composite entity: map from composite entity property names
   * to property values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  get resolvedInput(): string|null {
    return (
        (this.Serializable$has('resolvedInput')) ?
            (this.Serializable$get('resolvedInput')) :
            (null));
  }

  /**
   * Final text input which was matched during MatchIntent. This value can be
   * different from original input sent in request because of spelling
   * correction or other processing.
   */
  set resolvedInput(value: string|null) {
    this.Serializable$set('resolvedInput', value);
  }

  get sentimentAnalysisResult(): SentimentAnalysisResult_|null {
    return (
        (this.Serializable$has('sentimentAnalysisResult')) ?
            (this.Serializable$get('sentimentAnalysisResult')) :
            (null));
  }

  /**
   * The sentiment analysis result, which depends on the
   * `sentiment_analysis_request_config` specified in the request.
   */
  set sentimentAnalysisResult(value: SentimentAnalysisResult_|null) {
    this.Serializable$set('sentimentAnalysisResult', value);
  }

  getConstructor(): SerializableCtor<Match_> {
    return Match_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'matchType': Match_MatchTypeEnum},
      keys: [
        'intent', 'knowledgeAnswers', 'matchType', 'parameters',
        'resolvedInput', 'sentimentAnalysisResult'
      ],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'intent': Intent_,
        'knowledgeAnswers': KnowledgeAnswers_,
        'sentimentAnalysisResult': SentimentAnalysisResult_
      }
    };
  }
}

export interface MaxRepromptIntent_Parameters {
  nextNode?: string|null;
  outputContexts?: Array<Context_>|null;
  webhookState?: MaxRepromptIntent_WebhookState|null;
  action?: string|null;
}
export class MaxRepromptIntent_ extends Serializable {
  constructor(parameters: MaxRepromptIntent_Parameters = {}) {
    super();
    this.Serializable$set(
        'nextNode',
        (parameters.nextNode == null) ? (null) : (parameters.nextNode));
    this.Serializable$set(
        'outputContexts',
        (parameters.outputContexts == null) ? (null) :
                                              (parameters.outputContexts));
    this.Serializable$set(
        'webhookState',
        (parameters.webhookState == null) ? (null) : (parameters.webhookState));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
  }

  static get WebhookState(): IMaxRepromptIntent_WebhookStateEnum {
    return MaxRepromptIntent_WebhookStateEnum;
  }

  get action(): string|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Optional. The name of the action associated with the intent. Note: The
   * action name must not contain whitespaces.
   */
  set action(value: string|null) {
    this.Serializable$set('action', value);
  }

  get nextNode(): string|null {
    return (
        (this.Serializable$has('nextNode')) ?
            (this.Serializable$get('nextNode')) :
            (null));
  }

  /**
   * Required. The next node or event to transition to when the intent is
   * matched. Format: * When transitioning to a node:
   * `projects//agent/ivrNodes/`. * When transitioning to an event: ``, e.g.
   * `WELCOME`.
   */
  set nextNode(value: string|null) {
    this.Serializable$set('nextNode', value);
  }

  get outputContexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('outputContexts')) ?
            (this.Serializable$get('outputContexts')) :
            (null));
  }

  /**
   * Optional. List of contexts to output if this intent is matched.
   */
  set outputContexts(value: Array<Context_>|null) {
    this.Serializable$set('outputContexts', value);
  }

  get webhookState(): MaxRepromptIntent_WebhookState|null {
    return (
        (this.Serializable$has('webhookState')) ?
            (this.Serializable$get('webhookState')) :
            (null));
  }

  /**
   * Optional. Whether webhook should be called if this intent is matched.
   */
  set webhookState(value: MaxRepromptIntent_WebhookState|null) {
    this.Serializable$set('webhookState', value);
  }

  getConstructor(): SerializableCtor<MaxRepromptIntent_> {
    return MaxRepromptIntent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'outputContexts': Context_},
      enums: {'webhookState': MaxRepromptIntent_WebhookStateEnum},
      keys: ['action', 'nextNode', 'outputContexts', 'webhookState']
    };
  }
}

export interface MegaAgentSettings_Parameters {
  agents?: Array<SubAgent_>|null;
}
export class MegaAgentSettings_ extends Serializable {
  constructor(parameters: MegaAgentSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'agents', (parameters.agents == null) ? (null) : (parameters.agents));
  }

  get agents(): Array<SubAgent_>|null {
    return (
        (this.Serializable$has('agents')) ? (this.Serializable$get('agents')) :
                                            (null));
  }

  /**
   * Optional. The list of sub-agents attached to a mega-agent.
   */
  set agents(value: Array<SubAgent_>|null) {
    this.Serializable$set('agents', value);
  }

  getConstructor(): SerializableCtor<MegaAgentSettings_> {
    return MegaAgentSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'agents': SubAgent_}, keys: ['agents']};
  }
}

export interface MessageAnnotation_Parameters {
  parts?: Array<AnnotatedMessagePart_>|null;
  containEntities?: boolean|null;
}
export class MessageAnnotation_ extends Serializable {
  constructor(parameters: MessageAnnotation_Parameters = {}) {
    super();
    this.Serializable$set(
        'parts', (parameters.parts == null) ? (null) : (parameters.parts));
    this.Serializable$set(
        'containEntities',
        (parameters.containEntities == null) ? (null) :
                                               (parameters.containEntities));
  }

  get containEntities(): boolean|null {
    return (
        (this.Serializable$has('containEntities')) ?
            (this.Serializable$get('containEntities')) :
            (null));
  }

  /**
   * Required. Indicates whether the text message contains entities.
   */
  set containEntities(value: boolean|null) {
    this.Serializable$set('containEntities', value);
  }

  get parts(): Array<AnnotatedMessagePart_>|null {
    return (
        (this.Serializable$has('parts')) ? (this.Serializable$get('parts')) :
                                           (null));
  }

  /**
   * Optional. The collection of annotated message parts ordered by their
   * position in the message. You can recover the annotated message by
   * concatenating [AnnotatedMessagePart.text].
   */
  set parts(value: Array<AnnotatedMessagePart_>|null) {
    this.Serializable$set('parts', value);
  }

  getConstructor(): SerializableCtor<MessageAnnotation_> {
    return MessageAnnotation_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'parts': AnnotatedMessagePart_},
      keys: ['containEntities', 'parts']
    };
  }
}

export interface MessageClusterClusterStats_Parameters {
  score?: number|null;
  uniqueNormalizedMessageCount?: string|null;
  rawMessageCount?: string|null;
}
export class MessageClusterClusterStats_ extends Serializable {
  constructor(parameters: MessageClusterClusterStats_Parameters = {}) {
    super();
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'uniqueNormalizedMessageCount',
        (parameters.uniqueNormalizedMessageCount == null) ?
            (null) :
            (parameters.uniqueNormalizedMessageCount));
    this.Serializable$set(
        'rawMessageCount',
        (parameters.rawMessageCount == null) ? (null) :
                                               (parameters.rawMessageCount));
  }

  get rawMessageCount(): string|null {
    return (
        (this.Serializable$has('rawMessageCount')) ?
            (this.Serializable$get('rawMessageCount')) :
            (null));
  }

  /**
   * The number of raw messages in the cluster.
   */
  set rawMessageCount(value: string|null) {
    this.Serializable$set('rawMessageCount', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * The score of the cluster.
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  get uniqueNormalizedMessageCount(): string|null {
    return (
        (this.Serializable$has('uniqueNormalizedMessageCount')) ?
            (this.Serializable$get('uniqueNormalizedMessageCount')) :
            (null));
  }

  /**
   * The number of unique normalized messages in the cluster.
   */
  set uniqueNormalizedMessageCount(value: string|null) {
    this.Serializable$set('uniqueNormalizedMessageCount', value);
  }

  getConstructor(): SerializableCtor<MessageClusterClusterStats_> {
    return MessageClusterClusterStats_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['rawMessageCount', 'score', 'uniqueNormalizedMessageCount']};
  }
}

export interface MessageCluster_Parameters {
  name?: string|null;
  id?: string|null;
  label?: string|null;
  role?: MessageCluster_Role|null;
  stats?: MessageClusterClusterStats_|null;
  clusteredMessages?: Array<ClusteredMessage_>|null;
  clusteredMessagesNextPageToken?: string|null;
  messageLabels?: MessageLabels_|null;
}
export class MessageCluster_ extends Serializable {
  constructor(parameters: MessageCluster_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'label', (parameters.label == null) ? (null) : (parameters.label));
    this.Serializable$set(
        'role', (parameters.role == null) ? (null) : (parameters.role));
    this.Serializable$set(
        'stats', (parameters.stats == null) ? (null) : (parameters.stats));
    this.Serializable$set(
        'clusteredMessages',
        (parameters.clusteredMessages == null) ?
            (null) :
            (parameters.clusteredMessages));
    this.Serializable$set(
        'clusteredMessagesNextPageToken',
        (parameters.clusteredMessagesNextPageToken == null) ?
            (null) :
            (parameters.clusteredMessagesNextPageToken));
    this.Serializable$set(
        'messageLabels',
        (parameters.messageLabels == null) ? (null) :
                                             (parameters.messageLabels));
  }

  static get Role(): IMessageCluster_RoleEnum {
    return MessageCluster_RoleEnum;
  }

  get clusteredMessages(): Array<ClusteredMessage_>|null {
    return (
        (this.Serializable$has('clusteredMessages')) ?
            (this.Serializable$get('clusteredMessages')) :
            (null));
  }

  /**
   * The messages in the cluster. Only the top 100 messages will be present.
   */
  set clusteredMessages(value: Array<ClusteredMessage_>|null) {
    this.Serializable$set('clusteredMessages', value);
  }

  get clusteredMessagesNextPageToken(): string|null {
    return (
        (this.Serializable$has('clusteredMessagesNextPageToken')) ?
            (this.Serializable$get('clusteredMessagesNextPageToken')) :
            (null));
  }

  /**
   * The next page token to get more clustered messages.
   */
  set clusteredMessagesNextPageToken(value: string|null) {
    this.Serializable$set('clusteredMessagesNextPageToken', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Internal. Id of the cluster.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get label(): string|null {
    return (
        (this.Serializable$has('label')) ? (this.Serializable$get('label')) :
                                           (null));
  }

  /**
   * A human-readable string representing the cluster.
   */
  set label(value: string|null) {
    this.Serializable$set('label', value);
  }

  get messageLabels(): MessageLabels_|null {
    return (
        (this.Serializable$has('messageLabels')) ?
            (this.Serializable$get('messageLabels')) :
            (null));
  }

  /**
   * A list of labels applied to the cluster.
   */
  set messageLabels(value: MessageLabels_|null) {
    this.Serializable$set('messageLabels', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Resource name of the cluster.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get role(): MessageCluster_Role|null {
    return (
        (this.Serializable$has('role')) ? (this.Serializable$get('role')) :
                                          (null));
  }

  /**
   * The conversational role (e.g. HUMAN_AGENT, END_USER) of the messages in the
   * cluster.
   */
  set role(value: MessageCluster_Role|null) {
    this.Serializable$set('role', value);
  }

  get stats(): MessageClusterClusterStats_|null {
    return (
        (this.Serializable$has('stats')) ? (this.Serializable$get('stats')) :
                                           (null));
  }

  /**
   * Numerical results for the cluster.
   */
  set stats(value: MessageClusterClusterStats_|null) {
    this.Serializable$set('stats', value);
  }

  getConstructor(): SerializableCtor<MessageCluster_> {
    return MessageCluster_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'clusteredMessages': ClusteredMessage_},
      enums: {'role': MessageCluster_RoleEnum},
      keys: [
        'clusteredMessages', 'clusteredMessagesNextPageToken', 'id', 'label',
        'messageLabels', 'name', 'role', 'stats'
      ],
      objects: {
        'messageLabels': MessageLabels_,
        'stats': MessageClusterClusterStats_
      }
    };
  }
}

export interface MessageEdgeStats_Parameters {
  sourceScore?: number|null;
  targetScore?: number|null;
  count?: number|null;
  nextMessageScore?: number|null;
}
export class MessageEdgeStats_ extends Serializable {
  constructor(parameters: MessageEdgeStats_Parameters = {}) {
    super();
    this.Serializable$set(
        'sourceScore',
        (parameters.sourceScore == null) ? (null) : (parameters.sourceScore));
    this.Serializable$set(
        'targetScore',
        (parameters.targetScore == null) ? (null) : (parameters.targetScore));
    this.Serializable$set(
        'count', (parameters.count == null) ? (null) : (parameters.count));
    this.Serializable$set(
        'nextMessageScore',
        (parameters.nextMessageScore == null) ? (null) :
                                                (parameters.nextMessageScore));
  }

  get count(): number|null {
    return (
        (this.Serializable$has('count')) ? (this.Serializable$get('count')) :
                                           (null));
  }

  /**
   * Count of the occurrence of the edge.
   */
  set count(value: number|null) {
    this.Serializable$set('count', value);
  }

  get nextMessageScore(): number|null {
    return (
        (this.Serializable$has('nextMessageScore')) ?
            (this.Serializable$get('nextMessageScore')) :
            (null));
  }

  /**
   * Likelihood of the edge based on conversational model.
   */
  set nextMessageScore(value: number|null) {
    this.Serializable$set('nextMessageScore', value);
  }

  get sourceScore(): number|null {
    return (
        (this.Serializable$has('sourceScore')) ?
            (this.Serializable$get('sourceScore')) :
            (null));
  }

  /**
   * Probability of the state transition from the source node.
   */
  set sourceScore(value: number|null) {
    this.Serializable$set('sourceScore', value);
  }

  get targetScore(): number|null {
    return (
        (this.Serializable$has('targetScore')) ?
            (this.Serializable$get('targetScore')) :
            (null));
  }

  /**
   * Probability of the state transition to the target node.
   */
  set targetScore(value: number|null) {
    this.Serializable$set('targetScore', value);
  }

  getConstructor(): SerializableCtor<MessageEdgeStats_> {
    return MessageEdgeStats_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['count', 'nextMessageScore', 'sourceScore', 'targetScore']};
  }
}

export interface MessageEdge_Parameters {
  sourceNodeId?: string|null;
  targetNodeId?: string|null;
  type?: MessageEdge_Type|null;
  stats?: MessageEdgeStats_|null;
  dialogflowResource?: DialogflowResource_|null;
  conversationId?: string|null;
  sourceNode?: MessageNode_|null;
  targetNode?: MessageNode_|null;
  visible?: boolean|null;
}
export class MessageEdge_ extends Serializable {
  constructor(parameters: MessageEdge_Parameters = {}) {
    super();
    this.Serializable$set(
        'sourceNodeId',
        (parameters.sourceNodeId == null) ? (null) : (parameters.sourceNodeId));
    this.Serializable$set(
        'targetNodeId',
        (parameters.targetNodeId == null) ? (null) : (parameters.targetNodeId));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'stats', (parameters.stats == null) ? (null) : (parameters.stats));
    this.Serializable$set(
        'dialogflowResource',
        (parameters.dialogflowResource == null) ?
            (null) :
            (parameters.dialogflowResource));
    this.Serializable$set(
        'conversationId',
        (parameters.conversationId == null) ? (null) :
                                              (parameters.conversationId));
    this.Serializable$set(
        'sourceNode',
        (parameters.sourceNode == null) ? (null) : (parameters.sourceNode));
    this.Serializable$set(
        'targetNode',
        (parameters.targetNode == null) ? (null) : (parameters.targetNode));
    this.Serializable$set(
        'visible',
        (parameters.visible == null) ? (null) : (parameters.visible));
  }

  static get Type(): IMessageEdge_TypeEnum {
    return MessageEdge_TypeEnum;
  }

  get conversationId(): string|null {
    return (
        (this.Serializable$has('conversationId')) ?
            (this.Serializable$get('conversationId')) :
            (null));
  }

  /**
   * Internal. The conversation that generates the edge.
   */
  set conversationId(value: string|null) {
    this.Serializable$set('conversationId', value);
  }

  get dialogflowResource(): DialogflowResource_|null {
    return (
        (this.Serializable$has('dialogflowResource')) ?
            (this.Serializable$get('dialogflowResource')) :
            (null));
  }

  /**
   * Output only. Created dialogflow resource from this edge. Can only be
   * context.
   */
  set dialogflowResource(value: DialogflowResource_|null) {
    this.Serializable$set('dialogflowResource', value);
  }

  get sourceNode(): MessageNode_|null {
    return (
        (this.Serializable$has('sourceNode')) ?
            (this.Serializable$get('sourceNode')) :
            (null));
  }

  /**
   * Source node of the edge.
   */
  set sourceNode(value: MessageNode_|null) {
    this.Serializable$set('sourceNode', value);
  }

  get sourceNodeId(): string|null {
    return (
        (this.Serializable$has('sourceNodeId')) ?
            (this.Serializable$get('sourceNodeId')) :
            (null));
  }

  /**
   * Id of the source node.
   */
  set sourceNodeId(value: string|null) {
    this.Serializable$set('sourceNodeId', value);
  }

  get stats(): MessageEdgeStats_|null {
    return (
        (this.Serializable$has('stats')) ? (this.Serializable$get('stats')) :
                                           (null));
  }

  /**
   * Output only. Stats of the edge in the graph.
   */
  set stats(value: MessageEdgeStats_|null) {
    this.Serializable$set('stats', value);
  }

  get targetNode(): MessageNode_|null {
    return (
        (this.Serializable$has('targetNode')) ?
            (this.Serializable$get('targetNode')) :
            (null));
  }

  /**
   * Target node of the edge.
   */
  set targetNode(value: MessageNode_|null) {
    this.Serializable$set('targetNode', value);
  }

  get targetNodeId(): string|null {
    return (
        (this.Serializable$has('targetNodeId')) ?
            (this.Serializable$get('targetNodeId')) :
            (null));
  }

  /**
   * Id of the target node.
   */
  set targetNodeId(value: string|null) {
    this.Serializable$set('targetNodeId', value);
  }

  get type(): MessageEdge_Type|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Type of the edge.
   */
  set type(value: MessageEdge_Type|null) {
    this.Serializable$set('type', value);
  }

  get visible(): boolean|null {
    return (
        (this.Serializable$has('visible')) ?
            (this.Serializable$get('visible')) :
            (null));
  }

  /**
   * Whether the edge is visible on the UI.
   */
  set visible(value: boolean|null) {
    this.Serializable$set('visible', value);
  }

  getConstructor(): SerializableCtor<MessageEdge_> {
    return MessageEdge_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'type': MessageEdge_TypeEnum},
      keys: [
        'conversationId', 'dialogflowResource', 'sourceNode', 'sourceNodeId',
        'stats', 'targetNode', 'targetNodeId', 'type', 'visible'
      ],
      objects: {
        'dialogflowResource': DialogflowResource_,
        'sourceNode': MessageNode_,
        'stats': MessageEdgeStats_,
        'targetNode': MessageNode_
      }
    };
  }
}

export interface MessageLabel_Parameters {
  label?: MessageLabel_Label|null;
  score?: number|null;
}
export class MessageLabel_ extends Serializable {
  constructor(parameters: MessageLabel_Parameters = {}) {
    super();
    this.Serializable$set(
        'label', (parameters.label == null) ? (null) : (parameters.label));
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
  }

  static get Label(): IMessageLabel_LabelEnum {
    return MessageLabel_LabelEnum;
  }

  get label(): MessageLabel_Label|null {
    return (
        (this.Serializable$has('label')) ? (this.Serializable$get('label')) :
                                           (null));
  }

  /**
   * Message label.
   */
  set label(value: MessageLabel_Label|null) {
    this.Serializable$set('label', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * The confidence score of the label. Ranges between [0, 1].
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<MessageLabel_> {
    return MessageLabel_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {enums: {'label': MessageLabel_LabelEnum}, keys: ['label', 'score']};
  }
}

export interface MessageLabels_Parameters {
  labels?: Array<MessageLabel_>|null;
  directEmotions?: ExpanderEmotionPredictionEmotionPredictionResults|null;
  inducedEmotions?: ExpanderEmotionPredictionEmotionPredictionResults|null;
}
export class MessageLabels_ extends Serializable {
  constructor(parameters: MessageLabels_Parameters = {}) {
    super();
    this.Serializable$set(
        'labels', (parameters.labels == null) ? (null) : (parameters.labels));
    this.Serializable$set(
        'directEmotions',
        (parameters.directEmotions == null) ? (null) :
                                              (parameters.directEmotions));
    this.Serializable$set(
        'inducedEmotions',
        (parameters.inducedEmotions == null) ? (null) :
                                               (parameters.inducedEmotions));
  }

  get directEmotions(): ExpanderEmotionPredictionEmotionPredictionResults|null {
    return (
        (this.Serializable$has('directEmotions')) ?
            (this.Serializable$get('directEmotions')) :
            (null));
  }

  /**
   * Internal. Direct emotion predictions (emotion is induced in the writer).
   */
  set directEmotions(value: ExpanderEmotionPredictionEmotionPredictionResults|
                     null) {
    this.Serializable$set('directEmotions', value);
  }

  get inducedEmotions(): ExpanderEmotionPredictionEmotionPredictionResults
      |null {
    return (
        (this.Serializable$has('inducedEmotions')) ?
            (this.Serializable$get('inducedEmotions')) :
            (null));
  }

  /**
   * Internal. induced emotion predictions (emotion is induced in the reader).
   */
  set inducedEmotions(value: ExpanderEmotionPredictionEmotionPredictionResults|
                      null) {
    this.Serializable$set('inducedEmotions', value);
  }

  get labels(): Array<MessageLabel_>|null {
    return (
        (this.Serializable$has('labels')) ? (this.Serializable$get('labels')) :
                                            (null));
  }

  /**
   * A list of labels.
   */
  set labels(value: Array<MessageLabel_>|null) {
    this.Serializable$set('labels', value);
  }

  getConstructor(): SerializableCtor<MessageLabels_> {
    return MessageLabels_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'labels': MessageLabel_},
      keys: ['directEmotions', 'inducedEmotions', 'labels'],
      objects: {
        'directEmotions': ExpanderEmotionPredictionEmotionPredictionResults,
        'inducedEmotions': ExpanderEmotionPredictionEmotionPredictionResults
      }
    };
  }
}

export interface MessageNodeStats_Parameters {
  sourceCount?: number|null;
  targetCount?: number|null;
  targetEntropy?: number|null;
  sourceEntropy?: number|null;
  position?: number|null;
}
export class MessageNodeStats_ extends Serializable {
  constructor(parameters: MessageNodeStats_Parameters = {}) {
    super();
    this.Serializable$set(
        'sourceCount',
        (parameters.sourceCount == null) ? (null) : (parameters.sourceCount));
    this.Serializable$set(
        'targetCount',
        (parameters.targetCount == null) ? (null) : (parameters.targetCount));
    this.Serializable$set(
        'targetEntropy',
        (parameters.targetEntropy == null) ? (null) :
                                             (parameters.targetEntropy));
    this.Serializable$set(
        'sourceEntropy',
        (parameters.sourceEntropy == null) ? (null) :
                                             (parameters.sourceEntropy));
    this.Serializable$set(
        'position',
        (parameters.position == null) ? (null) : (parameters.position));
  }

  get position(): number|null {
    return (
        (this.Serializable$has('position')) ?
            (this.Serializable$get('position')) :
            (null));
  }

  /**
   * Average percentile position of the message in the conversation, range in
   * [0, 1].
   */
  set position(value: number|null) {
    this.Serializable$set('position', value);
  }

  get sourceCount(): number|null {
    return (
        (this.Serializable$has('sourceCount')) ?
            (this.Serializable$get('sourceCount')) :
            (null));
  }

  /**
   * Count of the node being source node.
   */
  set sourceCount(value: number|null) {
    this.Serializable$set('sourceCount', value);
  }

  get sourceEntropy(): number|null {
    return (
        (this.Serializable$has('sourceEntropy')) ?
            (this.Serializable$get('sourceEntropy')) :
            (null));
  }

  /**
   * Entropy of the node -> children transitions.
   */
  set sourceEntropy(value: number|null) {
    this.Serializable$set('sourceEntropy', value);
  }

  get targetCount(): number|null {
    return (
        (this.Serializable$has('targetCount')) ?
            (this.Serializable$get('targetCount')) :
            (null));
  }

  /**
   * Count of the node being target node.
   */
  set targetCount(value: number|null) {
    this.Serializable$set('targetCount', value);
  }

  get targetEntropy(): number|null {
    return (
        (this.Serializable$has('targetEntropy')) ?
            (this.Serializable$get('targetEntropy')) :
            (null));
  }

  /**
   * Entropy of the parents -> node transitions.
   */
  set targetEntropy(value: number|null) {
    this.Serializable$set('targetEntropy', value);
  }

  getConstructor(): SerializableCtor<MessageNodeStats_> {
    return MessageNodeStats_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'position', 'sourceCount', 'sourceEntropy', 'targetCount',
        'targetEntropy'
      ]
    };
  }
}

export interface MessageNode_Parameters {
  name?: string|null;
  id?: string|null;
  displayName?: string|null;
  cluster?: MessageCluster_|null;
  conversationRole?: MessageNode_ConversationRole|null;
  manualLabel?: MessageLabel_|null;
  autoLabel?: MessageLabel_|null;
  isGlobal?: boolean|null;
  triggeringNodeId?: string|null;
  stats?: MessageNodeStats_|null;
  dialogflowResource?: DialogflowResource_|null;
  contextEmbedding?: CloudConversationNamedFeatureVector|null;
  labelEmbedding?: CloudConversationNamedFeatureVector|null;
  visible?: boolean|null;
}
export class MessageNode_ extends Serializable {
  constructor(parameters: MessageNode_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'cluster',
        (parameters.cluster == null) ? (null) : (parameters.cluster));
    this.Serializable$set(
        'conversationRole',
        (parameters.conversationRole == null) ? (null) :
                                                (parameters.conversationRole));
    this.Serializable$set(
        'manualLabel',
        (parameters.manualLabel == null) ? (null) : (parameters.manualLabel));
    this.Serializable$set(
        'autoLabel',
        (parameters.autoLabel == null) ? (null) : (parameters.autoLabel));
    this.Serializable$set(
        'isGlobal',
        (parameters.isGlobal == null) ? (null) : (parameters.isGlobal));
    this.Serializable$set(
        'triggeringNodeId',
        (parameters.triggeringNodeId == null) ? (null) :
                                                (parameters.triggeringNodeId));
    this.Serializable$set(
        'stats', (parameters.stats == null) ? (null) : (parameters.stats));
    this.Serializable$set(
        'dialogflowResource',
        (parameters.dialogflowResource == null) ?
            (null) :
            (parameters.dialogflowResource));
    this.Serializable$set(
        'contextEmbedding',
        (parameters.contextEmbedding == null) ? (null) :
                                                (parameters.contextEmbedding));
    this.Serializable$set(
        'labelEmbedding',
        (parameters.labelEmbedding == null) ? (null) :
                                              (parameters.labelEmbedding));
    this.Serializable$set(
        'visible',
        (parameters.visible == null) ? (null) : (parameters.visible));
  }

  static get ConversationRole(): IMessageNode_ConversationRoleEnum {
    return MessageNode_ConversationRoleEnum;
  }

  get autoLabel(): MessageLabel_|null {
    return (
        (this.Serializable$has('autoLabel')) ?
            (this.Serializable$get('autoLabel')) :
            (null));
  }

  /**
   * Automatically inferred Dialogflow specific label.
   */
  set autoLabel(value: MessageLabel_|null) {
    this.Serializable$set('autoLabel', value);
  }

  get cluster(): MessageCluster_|null {
    return (
        (this.Serializable$has('cluster')) ?
            (this.Serializable$get('cluster')) :
            (null));
  }

  /**
   * The messages in the node.
   */
  set cluster(value: MessageCluster_|null) {
    this.Serializable$set('cluster', value);
  }

  get contextEmbedding(): CloudConversationNamedFeatureVector|null {
    return (
        (this.Serializable$has('contextEmbedding')) ?
            (this.Serializable$get('contextEmbedding')) :
            (null));
  }

  /**
   * Internal. Average context embedding of the messages.
   */
  set contextEmbedding(value: CloudConversationNamedFeatureVector|null) {
    this.Serializable$set('contextEmbedding', value);
  }

  get conversationRole(): MessageNode_ConversationRole|null {
    return (
        (this.Serializable$has('conversationRole')) ?
            (this.Serializable$get('conversationRole')) :
            (null));
  }

  /**
   * The role in the conversation (agent, customer).
   */
  set conversationRole(value: MessageNode_ConversationRole|null) {
    this.Serializable$set('conversationRole', value);
  }

  get dialogflowResource(): DialogflowResource_|null {
    return (
        (this.Serializable$has('dialogflowResource')) ?
            (this.Serializable$get('dialogflowResource')) :
            (null));
  }

  /**
   * Output only. Created dialogflow resource from this node. The resource
   * cannot be a ContextResource.
   */
  set dialogflowResource(value: DialogflowResource_|null) {
    this.Serializable$set('dialogflowResource', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * The human-friendly name of the node.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Internal. The id of the node.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get isGlobal(): boolean|null {
    return (
        (this.Serializable$has('isGlobal')) ?
            (this.Serializable$get('isGlobal')) :
            (null));
  }

  /**
   * Whether the node is shared globally, instead of only staying within one
   * specific triggering node's graph view.
   */
  set isGlobal(value: boolean|null) {
    this.Serializable$set('isGlobal', value);
  }

  get labelEmbedding(): CloudConversationNamedFeatureVector|null {
    return (
        (this.Serializable$has('labelEmbedding')) ?
            (this.Serializable$get('labelEmbedding')) :
            (null));
  }

  /**
   * Internal. Average label embedding of the messages.
   */
  set labelEmbedding(value: CloudConversationNamedFeatureVector|null) {
    this.Serializable$set('labelEmbedding', value);
  }

  get manualLabel(): MessageLabel_|null {
    return (
        (this.Serializable$has('manualLabel')) ?
            (this.Serializable$get('manualLabel')) :
            (null));
  }

  /**
   * Manually annotated Dialogflow specific label of the node, empty if the node
   * is automatically generated.
   */
  set manualLabel(value: MessageLabel_|null) {
    this.Serializable$set('manualLabel', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The resource name of the message node.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get stats(): MessageNodeStats_|null {
    return (
        (this.Serializable$has('stats')) ? (this.Serializable$get('stats')) :
                                           (null));
  }

  /**
   * Output only. Stats of the node in the graph.
   */
  set stats(value: MessageNodeStats_|null) {
    this.Serializable$set('stats', value);
  }

  get triggeringNodeId(): string|null {
    return (
        (this.Serializable$has('triggeringNodeId')) ?
            (this.Serializable$get('triggeringNodeId')) :
            (null));
  }

  /**
   * The triggering node view that this node is for. Should not be specified
   * when `is_global` is true.
   */
  set triggeringNodeId(value: string|null) {
    this.Serializable$set('triggeringNodeId', value);
  }

  get visible(): boolean|null {
    return (
        (this.Serializable$has('visible')) ?
            (this.Serializable$get('visible')) :
            (null));
  }

  /**
   * Whether the node is visible on the UI.
   */
  set visible(value: boolean|null) {
    this.Serializable$set('visible', value);
  }

  getConstructor(): SerializableCtor<MessageNode_> {
    return MessageNode_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'conversationRole': MessageNode_ConversationRoleEnum},
      keys: [
        'autoLabel', 'cluster', 'contextEmbedding', 'conversationRole',
        'dialogflowResource', 'displayName', 'id', 'isGlobal', 'labelEmbedding',
        'manualLabel', 'name', 'stats', 'triggeringNodeId', 'visible'
      ],
      objects: {
        'autoLabel': MessageLabel_,
        'cluster': MessageCluster_,
        'contextEmbedding': CloudConversationNamedFeatureVector,
        'dialogflowResource': DialogflowResource_,
        'labelEmbedding': CloudConversationNamedFeatureVector,
        'manualLabel': MessageLabel_,
        'stats': MessageNodeStats_
      }
    };
  }
}

export interface MessageReference_Parameters {
  encodedMessageReferenceId?: string|null;
  conversationId?: string|null;
  turnId?: number|null;
  start?: number|null;
  end?: number|null;
  messageLabels?: MessageLabels_|null;
  contextEmbedding?: CloudConversationNamedFeatureVector|null;
  labelEmbedding?: CloudConversationNamedFeatureVector|null;
  nodeId?: string|null;
  clusterId?: string|null;
  role?: MessageReference_Role|null;
}
export class MessageReference_ extends Serializable {
  constructor(parameters: MessageReference_Parameters = {}) {
    super();
    this.Serializable$set(
        'encodedMessageReferenceId',
        (parameters.encodedMessageReferenceId == null) ?
            (null) :
            (parameters.encodedMessageReferenceId));
    this.Serializable$set(
        'conversationId',
        (parameters.conversationId == null) ? (null) :
                                              (parameters.conversationId));
    this.Serializable$set(
        'turnId', (parameters.turnId == null) ? (null) : (parameters.turnId));
    this.Serializable$set(
        'start', (parameters.start == null) ? (null) : (parameters.start));
    this.Serializable$set(
        'end', (parameters.end == null) ? (null) : (parameters.end));
    this.Serializable$set(
        'messageLabels',
        (parameters.messageLabels == null) ? (null) :
                                             (parameters.messageLabels));
    this.Serializable$set(
        'contextEmbedding',
        (parameters.contextEmbedding == null) ? (null) :
                                                (parameters.contextEmbedding));
    this.Serializable$set(
        'labelEmbedding',
        (parameters.labelEmbedding == null) ? (null) :
                                              (parameters.labelEmbedding));
    this.Serializable$set(
        'nodeId', (parameters.nodeId == null) ? (null) : (parameters.nodeId));
    this.Serializable$set(
        'clusterId',
        (parameters.clusterId == null) ? (null) : (parameters.clusterId));
    this.Serializable$set(
        'role', (parameters.role == null) ? (null) : (parameters.role));
  }

  static get Role(): IMessageReference_RoleEnum {
    return MessageReference_RoleEnum;
  }

  get clusterId(): string|null {
    return (
        (this.Serializable$has('clusterId')) ?
            (this.Serializable$get('clusterId')) :
            (null));
  }

  /**
   * Internal. The cluster id that the message reference belongs to.
   */
  set clusterId(value: string|null) {
    this.Serializable$set('clusterId', value);
  }

  get contextEmbedding(): CloudConversationNamedFeatureVector|null {
    return (
        (this.Serializable$has('contextEmbedding')) ?
            (this.Serializable$get('contextEmbedding')) :
            (null));
  }

  /**
   * Internal. Context embedding of the message in the conversation.
   */
  set contextEmbedding(value: CloudConversationNamedFeatureVector|null) {
    this.Serializable$set('contextEmbedding', value);
  }

  get conversationId(): string|null {
    return (
        (this.Serializable$has('conversationId')) ?
            (this.Serializable$get('conversationId')) :
            (null));
  }

  /**
   * Id of the conversation that the message belongs to.
   */
  set conversationId(value: string|null) {
    this.Serializable$set('conversationId', value);
  }

  get encodedMessageReferenceId(): string|null {
    return (
        (this.Serializable$has('encodedMessageReferenceId')) ?
            (this.Serializable$get('encodedMessageReferenceId')) :
            (null));
  }

  /**
   * Internal. The encoded string of this MessageReference, excluding
   * conversation_id.
   */
  set encodedMessageReferenceId(value: string|null) {
    this.Serializable$set('encodedMessageReferenceId', value);
  }

  get end(): number|null {
    return (
        (this.Serializable$has('end')) ? (this.Serializable$get('end')) :
                                         (null));
  }

  /**
   * End position (exclusive) of the message in the entry of the conversation.
   */
  set end(value: number|null) {
    this.Serializable$set('end', value);
  }

  get labelEmbedding(): CloudConversationNamedFeatureVector|null {
    return (
        (this.Serializable$has('labelEmbedding')) ?
            (this.Serializable$get('labelEmbedding')) :
            (null));
  }

  /**
   * Internal. Label embedding of the message in the conversation.
   */
  set labelEmbedding(value: CloudConversationNamedFeatureVector|null) {
    this.Serializable$set('labelEmbedding', value);
  }

  get messageLabels(): MessageLabels_|null {
    return (
        (this.Serializable$has('messageLabels')) ?
            (this.Serializable$get('messageLabels')) :
            (null));
  }

  /**
   * A list of labels applied to the message in the conversation.
   */
  set messageLabels(value: MessageLabels_|null) {
    this.Serializable$set('messageLabels', value);
  }

  get nodeId(): string|null {
    return (
        (this.Serializable$has('nodeId')) ? (this.Serializable$get('nodeId')) :
                                            (null));
  }

  /**
   * Internal. The node id that the message reference belongs to.
   */
  set nodeId(value: string|null) {
    this.Serializable$set('nodeId', value);
  }

  get role(): MessageReference_Role|null {
    return (
        (this.Serializable$has('role')) ? (this.Serializable$get('role')) :
                                          (null));
  }

  /**
   * Internal. The conversational role (e.g. HUMAN_AGENT, END_USER) of the
   * message.
   */
  set role(value: MessageReference_Role|null) {
    this.Serializable$set('role', value);
  }

  get start(): number|null {
    return (
        (this.Serializable$has('start')) ? (this.Serializable$get('start')) :
                                           (null));
  }

  /**
   * Start position (inclusive) of the message in the entry of the conversation.
   * The ranges are measured in bytes and strings are always encoded in UTF-8.
   */
  set start(value: number|null) {
    this.Serializable$set('start', value);
  }

  get turnId(): number|null {
    return (
        (this.Serializable$has('turnId')) ? (this.Serializable$get('turnId')) :
                                            (null));
  }

  /**
   * Turn id of the entry that the message belongs to. Starts with 0.
   */
  set turnId(value: number|null) {
    this.Serializable$set('turnId', value);
  }

  getConstructor(): SerializableCtor<MessageReference_> {
    return MessageReference_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'role': MessageReference_RoleEnum},
      keys: [
        'clusterId', 'contextEmbedding', 'conversationId',
        'encodedMessageReferenceId', 'end', 'labelEmbedding', 'messageLabels',
        'nodeId', 'role', 'start', 'turnId'
      ],
      objects: {
        'contextEmbedding': CloudConversationNamedFeatureVector,
        'labelEmbedding': CloudConversationNamedFeatureVector,
        'messageLabels': MessageLabels_
      }
    };
  }
}

export interface MessageSpeechToTextInfo_Parameters {
  streamStartTime?: string|null;
  utteranceStartOffset?: string|null;
  utteranceEndOffset?: string|null;
  confidence?: number|null;
  speechWordInfo?: Array<SpeechWordInfo_>|null;
  recordedAudioGcsUri?: GcsDestination_|null;
}
export class MessageSpeechToTextInfo_ extends Serializable {
  constructor(parameters: MessageSpeechToTextInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'streamStartTime',
        (parameters.streamStartTime == null) ? (null) :
                                               (parameters.streamStartTime));
    this.Serializable$set(
        'utteranceStartOffset',
        (parameters.utteranceStartOffset == null) ?
            (null) :
            (parameters.utteranceStartOffset));
    this.Serializable$set(
        'utteranceEndOffset',
        (parameters.utteranceEndOffset == null) ?
            (null) :
            (parameters.utteranceEndOffset));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'speechWordInfo',
        (parameters.speechWordInfo == null) ? (null) :
                                              (parameters.speechWordInfo));
    this.Serializable$set(
        'recordedAudioGcsUri',
        (parameters.recordedAudioGcsUri == null) ?
            (null) :
            (parameters.recordedAudioGcsUri));
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The Speech confidence between 0.0 and 1.0 for the current portion of audio.
   * A higher number indicates an estimated greater likelihood that the
   * recognized words are correct. The default of 0.0 is a sentinel value
   * indicating that confidence was not set.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get recordedAudioGcsUri(): GcsDestination_|null {
    return (
        (this.Serializable$has('recordedAudioGcsUri')) ?
            (this.Serializable$get('recordedAudioGcsUri')) :
            (null));
  }

  /**
   * The Cloud Storage path used for response audio storage if audio export is
   * enabled in the SecuritySettings.
   */
  set recordedAudioGcsUri(value: GcsDestination_|null) {
    this.Serializable$set('recordedAudioGcsUri', value);
  }

  get speechWordInfo(): Array<SpeechWordInfo_>|null {
    return (
        (this.Serializable$has('speechWordInfo')) ?
            (this.Serializable$get('speechWordInfo')) :
            (null));
  }

  /**
   * Word-specific information for the words recognized by Speech in transcript.
   * Populated if and only if `message_type` = `TRANSCRIPT` and
   * [InputAudioConfig.enable_word_info] is set. SpeechWordInfo.start_offset and
   * SpeechWordInfo.end_offset are relative to current utterance.
   */
  set speechWordInfo(value: Array<SpeechWordInfo_>|null) {
    this.Serializable$set('speechWordInfo', value);
  }

  get streamStartTime(): string|null {
    return (
        (this.Serializable$has('streamStartTime')) ?
            (this.Serializable$get('streamStartTime')) :
            (null));
  }

  /**
   * Absolute start time of the stream. It can be gRPC stream or telephony
   * stream.
   */
  set streamStartTime(value: string|null) {
    this.Serializable$set('streamStartTime', value);
  }

  get utteranceEndOffset(): string|null {
    return (
        (this.Serializable$has('utteranceEndOffset')) ?
            (this.Serializable$get('utteranceEndOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio stream that corresponds
   * to the end of the utterance.
   */
  set utteranceEndOffset(value: string|null) {
    this.Serializable$set('utteranceEndOffset', value);
  }

  get utteranceStartOffset(): string|null {
    return (
        (this.Serializable$has('utteranceStartOffset')) ?
            (this.Serializable$get('utteranceStartOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio stream that corresponds
   * to the start of the utterance.
   */
  set utteranceStartOffset(value: string|null) {
    this.Serializable$set('utteranceStartOffset', value);
  }

  getConstructor(): SerializableCtor<MessageSpeechToTextInfo_> {
    return MessageSpeechToTextInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'speechWordInfo': SpeechWordInfo_},
      keys: [
        'confidence', 'recordedAudioGcsUri', 'speechWordInfo',
        'streamStartTime', 'utteranceEndOffset', 'utteranceStartOffset'
      ],
      objects: {'recordedAudioGcsUri': GcsDestination_}
    };
  }
}

export interface Message_Parameters {
  name?: string|null;
  content?: string|null;
  responseMessages?: Array<ResponseMessage_>|null;
  languageCode?: string|null;
  participant?: string|null;
  participantRole?: Message_ParticipantRole|null;
  createTime?: string|null;
  sendTime?: string|null;
  messageAnnotation?: MessageAnnotation_|null;
  sentimentAnalysis?: SentimentAnalysisResult_|null;
  speechToTextInfo?: MessageSpeechToTextInfo_|null;
  automatedAgentParameters?: ApiClientObjectMap<any>|null;
}
export class Message_ extends Serializable {
  constructor(parameters: Message_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
    this.Serializable$set(
        'responseMessages',
        (parameters.responseMessages == null) ? (null) :
                                                (parameters.responseMessages));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'participant',
        (parameters.participant == null) ? (null) : (parameters.participant));
    this.Serializable$set(
        'participantRole',
        (parameters.participantRole == null) ? (null) :
                                               (parameters.participantRole));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'sendTime',
        (parameters.sendTime == null) ? (null) : (parameters.sendTime));
    this.Serializable$set(
        'messageAnnotation',
        (parameters.messageAnnotation == null) ?
            (null) :
            (parameters.messageAnnotation));
    this.Serializable$set(
        'sentimentAnalysis',
        (parameters.sentimentAnalysis == null) ?
            (null) :
            (parameters.sentimentAnalysis));
    this.Serializable$set(
        'speechToTextInfo',
        (parameters.speechToTextInfo == null) ? (null) :
                                                (parameters.speechToTextInfo));
    this.Serializable$set(
        'automatedAgentParameters',
        (parameters.automatedAgentParameters == null) ?
            (null) :
            (parameters.automatedAgentParameters));
  }

  static get ParticipantRole(): IMessage_ParticipantRoleEnum {
    return Message_ParticipantRoleEnum;
  }

  get automatedAgentParameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('automatedAgentParameters')) ?
            (this.Serializable$get('automatedAgentParameters')) :
            (null));
  }

  /**
   * Output only. Collected session parameters.
   */
  set automatedAgentParameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('automatedAgentParameters', value);
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Required. The message content.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The time when the message was created in Contact Center AI.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. The message language. This should be a
   * [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt) language tag.
   * Example: \"en-US\".
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get messageAnnotation(): MessageAnnotation_|null {
    return (
        (this.Serializable$has('messageAnnotation')) ?
            (this.Serializable$get('messageAnnotation')) :
            (null));
  }

  /**
   * Output only. The annotation for the message.
   */
  set messageAnnotation(value: MessageAnnotation_|null) {
    this.Serializable$set('messageAnnotation', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Optional. The unique identifier of the message. Format:
   * `projects//locations//conversations//messages/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get participant(): string|null {
    return (
        (this.Serializable$has('participant')) ?
            (this.Serializable$get('participant')) :
            (null));
  }

  /**
   * Output only. The participant that sends this message.
   */
  set participant(value: string|null) {
    this.Serializable$set('participant', value);
  }

  get participantRole(): Message_ParticipantRole|null {
    return (
        (this.Serializable$has('participantRole')) ?
            (this.Serializable$get('participantRole')) :
            (null));
  }

  /**
   * Output only. The role of the participant.
   */
  set participantRole(value: Message_ParticipantRole|null) {
    this.Serializable$set('participantRole', value);
  }

  get responseMessages(): Array<ResponseMessage_>|null {
    return (
        (this.Serializable$has('responseMessages')) ?
            (this.Serializable$get('responseMessages')) :
            (null));
  }

  /**
   * Optional. Automated agent responses.
   */
  set responseMessages(value: Array<ResponseMessage_>|null) {
    this.Serializable$set('responseMessages', value);
  }

  get sendTime(): string|null {
    return (
        (this.Serializable$has('sendTime')) ?
            (this.Serializable$get('sendTime')) :
            (null));
  }

  /**
   * Optional. The time when the message was sent.
   */
  set sendTime(value: string|null) {
    this.Serializable$set('sendTime', value);
  }

  get sentimentAnalysis(): SentimentAnalysisResult_|null {
    return (
        (this.Serializable$has('sentimentAnalysis')) ?
            (this.Serializable$get('sentimentAnalysis')) :
            (null));
  }

  /**
   * Output only. The sentiment analysis result for the message.
   */
  set sentimentAnalysis(value: SentimentAnalysisResult_|null) {
    this.Serializable$set('sentimentAnalysis', value);
  }

  get speechToTextInfo(): MessageSpeechToTextInfo_|null {
    return (
        (this.Serializable$has('speechToTextInfo')) ?
            (this.Serializable$get('speechToTextInfo')) :
            (null));
  }

  /**
   * Output only. Speech to text information of this message.
   */
  set speechToTextInfo(value: MessageSpeechToTextInfo_|null) {
    this.Serializable$set('speechToTextInfo', value);
  }

  getConstructor(): SerializableCtor<Message_> {
    return Message_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'responseMessages': ResponseMessage_},
      enums: {'participantRole': Message_ParticipantRoleEnum},
      keys: [
        'automatedAgentParameters', 'content', 'createTime', 'languageCode',
        'messageAnnotation', 'name', 'participant', 'participantRole',
        'responseMessages', 'sendTime', 'sentimentAnalysis', 'speechToTextInfo'
      ],
      objectMaps: {
        'automatedAgentParameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'messageAnnotation': MessageAnnotation_,
        'sentimentAnalysis': SentimentAnalysisResult_,
        'speechToTextInfo': MessageSpeechToTextInfo_
      }
    };
  }
}

export interface ModelEvaluationDatasetSession_Parameters {
  samples?: Array<ModelEvaluationSample_>|null;
}
export class ModelEvaluationDatasetSession_ extends Serializable {
  constructor(parameters: ModelEvaluationDatasetSession_Parameters = {}) {
    super();
    this.Serializable$set(
        'samples',
        (parameters.samples == null) ? (null) : (parameters.samples));
  }

  get samples(): Array<ModelEvaluationSample_>|null {
    return (
        (this.Serializable$has('samples')) ?
            (this.Serializable$get('samples')) :
            (null));
  }

  /**
   * The collection of labelled sample queries.
   */
  set samples(value: Array<ModelEvaluationSample_>|null) {
    this.Serializable$set('samples', value);
  }

  getConstructor(): SerializableCtor<ModelEvaluationDatasetSession_> {
    return ModelEvaluationDatasetSession_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'samples': ModelEvaluationSample_}, keys: ['samples']};
  }
}

export interface ModelEvaluationDataset_Parameters {
  sessions?: Array<ModelEvaluationDatasetSession_>|null;
}
export class ModelEvaluationDataset_ extends Serializable {
  constructor(parameters: ModelEvaluationDataset_Parameters = {}) {
    super();
    this.Serializable$set(
        'sessions',
        (parameters.sessions == null) ? (null) : (parameters.sessions));
  }

  get sessions(): Array<ModelEvaluationDatasetSession_>|null {
    return (
        (this.Serializable$has('sessions')) ?
            (this.Serializable$get('sessions')) :
            (null));
  }

  /**
   * The collection of sessions in the dataset.
   */
  set sessions(value: Array<ModelEvaluationDatasetSession_>|null) {
    this.Serializable$set('sessions', value);
  }

  getConstructor(): SerializableCtor<ModelEvaluationDataset_> {
    return ModelEvaluationDataset_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'sessions': ModelEvaluationDatasetSession_},
      keys: ['sessions']
    };
  }
}

export interface ModelEvaluationLabel_Parameters {
  intent?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
}
export class ModelEvaluationLabel_ extends Serializable {
  constructor(parameters: ModelEvaluationLabel_Parameters = {}) {
    super();
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get intent(): string|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * Required. The display name of the expected intent.
   */
  set intent(value: string|null) {
    this.Serializable$set('intent', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Optional. The collection of parameters expected to be extracted by NLU
   * model. Depending on your protocol or client library language, this is a
   * map, associative array, symbol table, dictionary, or JSON object composed
   * of a collection of (MapKey, MapValue) pairs: - MapKey type: string - MapKey
   * value: parameter name - MapValue type: - If parameter's entity type is a
   * composite entity: map - Else: depending on parameter value type, could be
   * one of string, number, boolean, null, list or map - MapValue value: - If
   * parameter's entity type is a composite entity: map from composite entity
   * property names to property values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<ModelEvaluationLabel_> {
    return ModelEvaluationLabel_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['intent', 'parameters'],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface ModelEvaluationMetadata_Parameters {
  state?: ModelEvaluationMetadata_State|null;
  totalSamples?: number|null;
  processedSamples?: number|null;
}
export class ModelEvaluationMetadata_ extends Serializable {
  constructor(parameters: ModelEvaluationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'totalSamples',
        (parameters.totalSamples == null) ? (null) : (parameters.totalSamples));
    this.Serializable$set(
        'processedSamples',
        (parameters.processedSamples == null) ? (null) :
                                                (parameters.processedSamples));
  }

  static get State(): IModelEvaluationMetadata_StateEnum {
    return ModelEvaluationMetadata_StateEnum;
  }

  get processedSamples(): number|null {
    return (
        (this.Serializable$has('processedSamples')) ?
            (this.Serializable$get('processedSamples')) :
            (null));
  }

  /**
   * Number of samples that have been processed.
   */
  set processedSamples(value: number|null) {
    this.Serializable$set('processedSamples', value);
  }

  get state(): ModelEvaluationMetadata_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * The current state of the operation.
   */
  set state(value: ModelEvaluationMetadata_State|null) {
    this.Serializable$set('state', value);
  }

  get totalSamples(): number|null {
    return (
        (this.Serializable$has('totalSamples')) ?
            (this.Serializable$get('totalSamples')) :
            (null));
  }

  /**
   * Total number of samples in the dataset.
   */
  set totalSamples(value: number|null) {
    this.Serializable$set('totalSamples', value);
  }

  getConstructor(): SerializableCtor<ModelEvaluationMetadata_> {
    return ModelEvaluationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': ModelEvaluationMetadata_StateEnum},
      keys: ['processedSamples', 'state', 'totalSamples']
    };
  }
}

export interface ModelEvaluationQuery_Parameters {
  queryText?: string|null;
  inputContexts?: Array<string>|null;
}
export class ModelEvaluationQuery_ extends Serializable {
  constructor(parameters: ModelEvaluationQuery_Parameters = {}) {
    super();
    this.Serializable$set(
        'queryText',
        (parameters.queryText == null) ? (null) : (parameters.queryText));
    this.Serializable$set(
        'inputContexts',
        (parameters.inputContexts == null) ? (null) :
                                             (parameters.inputContexts));
  }

  get inputContexts(): Array<string>|null {
    return (
        (this.Serializable$has('inputContexts')) ?
            (this.Serializable$get('inputContexts')) :
            (null));
  }

  /**
   * Optional. The list of input contexts for the query.
   */
  set inputContexts(value: Array<string>|null) {
    this.Serializable$set('inputContexts', value);
  }

  get queryText(): string|null {
    return (
        (this.Serializable$has('queryText')) ?
            (this.Serializable$get('queryText')) :
            (null));
  }

  /**
   * Required. The UTF-8 encoded natural language text of the query to be
   * processed.
   */
  set queryText(value: string|null) {
    this.Serializable$set('queryText', value);
  }

  getConstructor(): SerializableCtor<ModelEvaluationQuery_> {
    return ModelEvaluationQuery_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['inputContexts', 'queryText']};
  }
}

export interface ModelEvaluationResultF1Score_Parameters {
  precisionScore?: number|null;
  recallScore?: number|null;
}
export class ModelEvaluationResultF1Score_ extends Serializable {
  constructor(parameters: ModelEvaluationResultF1Score_Parameters = {}) {
    super();
    this.Serializable$set(
        'precisionScore',
        (parameters.precisionScore == null) ? (null) :
                                              (parameters.precisionScore));
    this.Serializable$set(
        'recallScore',
        (parameters.recallScore == null) ? (null) : (parameters.recallScore));
  }

  get precisionScore(): number|null {
    return (
        (this.Serializable$has('precisionScore')) ?
            (this.Serializable$get('precisionScore')) :
            (null));
  }

  /**
   * The precision score of the dataset. Calculated as : (# of true positives /
   * (# of true positives + # of false positives))
   */
  set precisionScore(value: number|null) {
    this.Serializable$set('precisionScore', value);
  }

  get recallScore(): number|null {
    return (
        (this.Serializable$has('recallScore')) ?
            (this.Serializable$get('recallScore')) :
            (null));
  }

  /**
   * The recall score of the dataset. Calculated as : (# of true positives / (#
   * of true positives + # of false negatives))
   */
  set recallScore(value: number|null) {
    this.Serializable$set('recallScore', value);
  }

  getConstructor(): SerializableCtor<ModelEvaluationResultF1Score_> {
    return ModelEvaluationResultF1Score_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['precisionScore', 'recallScore']};
  }
}

export interface ModelEvaluationResult_Parameters {
  totalQueries?: number|null;
  exactAccuracy?: number|null;
  partialAccuracy?: number|null;
  exactF1Score?: ModelEvaluationResultF1Score_|null;
  partialF1Score?: ModelEvaluationResultF1Score_|null;
  confusionMatrixInfo?: ConfusionMatrixInfo_|null;
  failedQueries?: Array<ModelEvaluationSingleRunResult_>|null;
}
export class ModelEvaluationResult_ extends Serializable {
  constructor(parameters: ModelEvaluationResult_Parameters = {}) {
    super();
    this.Serializable$set(
        'totalQueries',
        (parameters.totalQueries == null) ? (null) : (parameters.totalQueries));
    this.Serializable$set(
        'exactAccuracy',
        (parameters.exactAccuracy == null) ? (null) :
                                             (parameters.exactAccuracy));
    this.Serializable$set(
        'partialAccuracy',
        (parameters.partialAccuracy == null) ? (null) :
                                               (parameters.partialAccuracy));
    this.Serializable$set(
        'exactF1Score',
        (parameters.exactF1Score == null) ? (null) : (parameters.exactF1Score));
    this.Serializable$set(
        'partialF1Score',
        (parameters.partialF1Score == null) ? (null) :
                                              (parameters.partialF1Score));
    this.Serializable$set(
        'confusionMatrixInfo',
        (parameters.confusionMatrixInfo == null) ?
            (null) :
            (parameters.confusionMatrixInfo));
    this.Serializable$set(
        'failedQueries',
        (parameters.failedQueries == null) ? (null) :
                                             (parameters.failedQueries));
  }

  get confusionMatrixInfo(): ConfusionMatrixInfo_|null {
    return (
        (this.Serializable$has('confusionMatrixInfo')) ?
            (this.Serializable$get('confusionMatrixInfo')) :
            (null));
  }

  /**
   * Confusion matrix info.
   */
  set confusionMatrixInfo(value: ConfusionMatrixInfo_|null) {
    this.Serializable$set('confusionMatrixInfo', value);
  }

  get exactAccuracy(): number|null {
    return (
        (this.Serializable$has('exactAccuracy')) ?
            (this.Serializable$get('exactAccuracy')) :
            (null));
  }

  /**
   * The aggregated accuracy score for exact match.
   */
  set exactAccuracy(value: number|null) {
    this.Serializable$set('exactAccuracy', value);
  }

  get exactF1Score(): ModelEvaluationResultF1Score_|null {
    return (
        (this.Serializable$has('exactF1Score')) ?
            (this.Serializable$get('exactF1Score')) :
            (null));
  }

  /**
   * The aggregated F1 score for exact match.
   */
  set exactF1Score(value: ModelEvaluationResultF1Score_|null) {
    this.Serializable$set('exactF1Score', value);
  }

  get failedQueries(): Array<ModelEvaluationSingleRunResult_>|null {
    return (
        (this.Serializable$has('failedQueries')) ?
            (this.Serializable$get('failedQueries')) :
            (null));
  }

  /**
   * The collection of failed queries.
   */
  set failedQueries(value: Array<ModelEvaluationSingleRunResult_>|null) {
    this.Serializable$set('failedQueries', value);
  }

  get partialAccuracy(): number|null {
    return (
        (this.Serializable$has('partialAccuracy')) ?
            (this.Serializable$get('partialAccuracy')) :
            (null));
  }

  /**
   * The aggregated accuracy score for partial match.
   */
  set partialAccuracy(value: number|null) {
    this.Serializable$set('partialAccuracy', value);
  }

  get partialF1Score(): ModelEvaluationResultF1Score_|null {
    return (
        (this.Serializable$has('partialF1Score')) ?
            (this.Serializable$get('partialF1Score')) :
            (null));
  }

  /**
   * The aggregated F1 score for partial match.
   */
  set partialF1Score(value: ModelEvaluationResultF1Score_|null) {
    this.Serializable$set('partialF1Score', value);
  }

  get totalQueries(): number|null {
    return (
        (this.Serializable$has('totalQueries')) ?
            (this.Serializable$get('totalQueries')) :
            (null));
  }

  /**
   * The total number of queries in the dataset.
   */
  set totalQueries(value: number|null) {
    this.Serializable$set('totalQueries', value);
  }

  getConstructor(): SerializableCtor<ModelEvaluationResult_> {
    return ModelEvaluationResult_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'failedQueries': ModelEvaluationSingleRunResult_},
      keys: [
        'confusionMatrixInfo', 'exactAccuracy', 'exactF1Score', 'failedQueries',
        'partialAccuracy', 'partialF1Score', 'totalQueries'
      ],
      objects: {
        'confusionMatrixInfo': ConfusionMatrixInfo_,
        'exactF1Score': ModelEvaluationResultF1Score_,
        'partialF1Score': ModelEvaluationResultF1Score_
      }
    };
  }
}

export interface ModelEvaluationSample_Parameters {
  sampleQuery?: ModelEvaluationQuery_|null;
  label?: ModelEvaluationLabel_|null;
}
export class ModelEvaluationSample_ extends Serializable {
  constructor(parameters: ModelEvaluationSample_Parameters = {}) {
    super();
    this.Serializable$set(
        'sampleQuery',
        (parameters.sampleQuery == null) ? (null) : (parameters.sampleQuery));
    this.Serializable$set(
        'label', (parameters.label == null) ? (null) : (parameters.label));
  }

  get label(): ModelEvaluationLabel_|null {
    return (
        (this.Serializable$has('label')) ? (this.Serializable$get('label')) :
                                           (null));
  }

  /**
   * Required. Sample query label.
   */
  set label(value: ModelEvaluationLabel_|null) {
    this.Serializable$set('label', value);
  }

  get sampleQuery(): ModelEvaluationQuery_|null {
    return (
        (this.Serializable$has('sampleQuery')) ?
            (this.Serializable$get('sampleQuery')) :
            (null));
  }

  /**
   * Required. Sample query info.
   */
  set sampleQuery(value: ModelEvaluationQuery_|null) {
    this.Serializable$set('sampleQuery', value);
  }

  getConstructor(): SerializableCtor<ModelEvaluationSample_> {
    return ModelEvaluationSample_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['label', 'sampleQuery'],
      objects:
          {'label': ModelEvaluationLabel_, 'sampleQuery': ModelEvaluationQuery_}
    };
  }
}

export interface ModelEvaluationSingleRunResult_Parameters {
  sample?: ModelEvaluationSample_|null;
  predictedLabel?: ModelEvaluationLabel_|null;
  error?: GoogleRpcStatus|null;
  fallbackMatched?: boolean|null;
}
export class ModelEvaluationSingleRunResult_ extends Serializable {
  constructor(parameters: ModelEvaluationSingleRunResult_Parameters = {}) {
    super();
    this.Serializable$set(
        'sample', (parameters.sample == null) ? (null) : (parameters.sample));
    this.Serializable$set(
        'predictedLabel',
        (parameters.predictedLabel == null) ? (null) :
                                              (parameters.predictedLabel));
    this.Serializable$set(
        'error', (parameters.error == null) ? (null) : (parameters.error));
    this.Serializable$set(
        'fallbackMatched',
        (parameters.fallbackMatched == null) ? (null) :
                                               (parameters.fallbackMatched));
  }

  get error(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('error')) ? (this.Serializable$get('error')) :
                                           (null));
  }

  /**
   * The error status if the query fails.
   */
  set error(value: GoogleRpcStatus|null) {
    this.Serializable$set('error', value);
  }

  get fallbackMatched(): boolean|null {
    return (
        (this.Serializable$has('fallbackMatched')) ?
            (this.Serializable$get('fallbackMatched')) :
            (null));
  }

  /**
   * Indicates whether the query is matched to a fallback intent.
   */
  set fallbackMatched(value: boolean|null) {
    this.Serializable$set('fallbackMatched', value);
  }

  get predictedLabel(): ModelEvaluationLabel_|null {
    return (
        (this.Serializable$has('predictedLabel')) ?
            (this.Serializable$get('predictedLabel')) :
            (null));
  }

  /**
   * The predicted label for the sample query.
   */
  set predictedLabel(value: ModelEvaluationLabel_|null) {
    this.Serializable$set('predictedLabel', value);
  }

  get sample(): ModelEvaluationSample_|null {
    return (
        (this.Serializable$has('sample')) ? (this.Serializable$get('sample')) :
                                            (null));
  }

  /**
   * The original labelled sample query.
   */
  set sample(value: ModelEvaluationSample_|null) {
    this.Serializable$set('sample', value);
  }

  getConstructor(): SerializableCtor<ModelEvaluationSingleRunResult_> {
    return ModelEvaluationSingleRunResult_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['error', 'fallbackMatched', 'predictedLabel', 'sample'],
      objects: {
        'error': GoogleRpcStatus,
        'predictedLabel': ModelEvaluationLabel_,
        'sample': ModelEvaluationSample_
      }
    };
  }
}

export interface NotificationConfig_Parameters {
  topic?: string|null;
  messageFormat?: NotificationConfig_MessageFormat|null;
}
export class NotificationConfig_ extends Serializable {
  constructor(parameters: NotificationConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'topic', (parameters.topic == null) ? (null) : (parameters.topic));
    this.Serializable$set(
        'messageFormat',
        (parameters.messageFormat == null) ? (null) :
                                             (parameters.messageFormat));
  }

  static get MessageFormat(): INotificationConfig_MessageFormatEnum {
    return NotificationConfig_MessageFormatEnum;
  }

  get messageFormat(): NotificationConfig_MessageFormat|null {
    return (
        (this.Serializable$has('messageFormat')) ?
            (this.Serializable$get('messageFormat')) :
            (null));
  }

  /**
   * Format of message.
   */
  set messageFormat(value: NotificationConfig_MessageFormat|null) {
    this.Serializable$set('messageFormat', value);
  }

  get topic(): string|null {
    return (
        (this.Serializable$has('topic')) ? (this.Serializable$get('topic')) :
                                           (null));
  }

  /**
   * Name of the Pub/Sub topic to publish conversation events like
   * CONVERSATION_STARTED as serialized ConversationEvent protos. For telephony
   * integration to receive notification, make sure either this topic is in the
   * same project as the conversation or you grant
   * `service-@gcp-sa-dialogflow.iam.gserviceaccount.com` the `Dialogflow
   * Service Agent` role in the topic project. For chat integration to receive
   * notification, make sure API caller has been granted the `Dialogflow Service
   * Agent` role for the topic. Format: `projects//locations//topics/`.
   */
  set topic(value: string|null) {
    this.Serializable$set('topic', value);
  }

  getConstructor(): SerializableCtor<NotificationConfig_> {
    return NotificationConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'messageFormat': NotificationConfig_MessageFormatEnum},
      keys: ['messageFormat', 'topic']
    };
  }
}

export interface OriginalDetectIntentRequest_Parameters {
  source?: string|null;
  version?: string|null;
  payload?: ApiClientObjectMap<any>|null;
}
export class OriginalDetectIntentRequest_ extends Serializable {
  constructor(parameters: OriginalDetectIntentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Optional. This field is set to the value of the `QueryParameters.payload`
   * field passed in the request. Some integrations that query a Dialogflow
   * agent may provide additional information in the payload. In particular, for
   * the Dialogflow Phone Gateway integration, this field has the form: {
   * \"telephony\": { \"caller_id\": \"+18558363987\" } } Note: The caller ID
   * field (`caller_id`) will be redacted for Trial Edition agents and populated
   * with the caller ID in [E.164 format](https://en.wikipedia.org/wiki/E.164)
   * for Essentials Edition agents.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * The source of this request, e.g., `google`, `facebook`, `slack`. It is set
   * by Dialogflow-owned servers.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  get version(): string|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Optional. The version of the protocol used for this request. This field is
   * AoG-specific.
   */
  set version(value: string|null) {
    this.Serializable$set('version', value);
  }

  getConstructor(): SerializableCtor<OriginalDetectIntentRequest_> {
    return OriginalDetectIntentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['payload', 'source', 'version'],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface OutputAudioConfig_Parameters {
  audioEncoding?: OutputAudioConfig_AudioEncoding|null;
  sampleRateHertz?: number|null;
  synthesizeSpeechConfig?: SynthesizeSpeechConfig_|null;
  mask?: string|null;
  useAgentSettings?: boolean|null;
  readGcsAudio?: boolean|null;
}
export class OutputAudioConfig_ extends Serializable {
  constructor(parameters: OutputAudioConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'audioEncoding',
        (parameters.audioEncoding == null) ? (null) :
                                             (parameters.audioEncoding));
    this.Serializable$set(
        'sampleRateHertz',
        (parameters.sampleRateHertz == null) ? (null) :
                                               (parameters.sampleRateHertz));
    this.Serializable$set(
        'synthesizeSpeechConfig',
        (parameters.synthesizeSpeechConfig == null) ?
            (null) :
            (parameters.synthesizeSpeechConfig));
    this.Serializable$set(
        'mask', (parameters.mask == null) ? (null) : (parameters.mask));
    this.Serializable$set(
        'useAgentSettings',
        (parameters.useAgentSettings == null) ? (null) :
                                                (parameters.useAgentSettings));
    this.Serializable$set(
        'readGcsAudio',
        (parameters.readGcsAudio == null) ? (null) : (parameters.readGcsAudio));
  }

  static get AudioEncoding(): IOutputAudioConfig_AudioEncodingEnum {
    return OutputAudioConfig_AudioEncodingEnum;
  }

  get audioEncoding(): OutputAudioConfig_AudioEncoding|null {
    return (
        (this.Serializable$has('audioEncoding')) ?
            (this.Serializable$get('audioEncoding')) :
            (null));
  }

  /**
   * Required. Audio encoding of the synthesized audio content.
   */
  set audioEncoding(value: OutputAudioConfig_AudioEncoding|null) {
    this.Serializable$set('audioEncoding', value);
  }

  get mask(): string|null {
    return (
        (this.Serializable$has('mask')) ? (this.Serializable$get('mask')) :
                                          (null));
  }

  /**
   * Override mask for this whole OutputAudioConfig internally copied from the
   * DetectIntent/StreamingDetectIntent/FulfillIntent request.
   */
  set mask(value: string|null) {
    this.Serializable$set('mask', value);
  }

  get readGcsAudio(): boolean|null {
    return (
        (this.Serializable$has('readGcsAudio')) ?
            (this.Serializable$get('readGcsAudio')) :
            (null));
  }

  /**
   * Read PlayAudio prompt stored in Cloud Storage. Used by phone gateway to
   * speed up MixedAudio response by reading audio while doing TTS synthesizing.
   */
  set readGcsAudio(value: boolean|null) {
    this.Serializable$set('readGcsAudio', value);
  }

  get sampleRateHertz(): number|null {
    return (
        (this.Serializable$has('sampleRateHertz')) ?
            (this.Serializable$get('sampleRateHertz')) :
            (null));
  }

  /**
   * The synthesis sample rate (in hertz) for this audio. If not provided, then
   * the synthesizer will use the default sample rate based on the audio
   * encoding. If this is different from the voice's natural sample rate, then
   * the synthesizer will honor this request by converting to the desired sample
   * rate (which might result in worse audio quality).
   */
  set sampleRateHertz(value: number|null) {
    this.Serializable$set('sampleRateHertz', value);
  }

  get synthesizeSpeechConfig(): SynthesizeSpeechConfig_|null {
    return (
        (this.Serializable$has('synthesizeSpeechConfig')) ?
            (this.Serializable$get('synthesizeSpeechConfig')) :
            (null));
  }

  /**
   * Configuration of how speech should be synthesized.
   */
  set synthesizeSpeechConfig(value: SynthesizeSpeechConfig_|null) {
    this.Serializable$set('synthesizeSpeechConfig', value);
  }

  get useAgentSettings(): boolean|null {
    return (
        (this.Serializable$has('useAgentSettings')) ?
            (this.Serializable$get('useAgentSettings')) :
            (null));
  }

  /**
   * Use output audio config from the Dialogflow agent if there is one,
   * overriding any configuration specified in this message. Used by phone
   * gateway to enforce the ES agent behavior.
   */
  set useAgentSettings(value: boolean|null) {
    this.Serializable$set('useAgentSettings', value);
  }

  getConstructor(): SerializableCtor<OutputAudioConfig_> {
    return OutputAudioConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'audioEncoding': OutputAudioConfig_AudioEncodingEnum},
      keys: [
        'audioEncoding', 'mask', 'readGcsAudio', 'sampleRateHertz',
        'synthesizeSpeechConfig', 'useAgentSettings'
      ],
      objects: {'synthesizeSpeechConfig': SynthesizeSpeechConfig_}
    };
  }
}

export interface OutputAudio_Parameters {
  config?: OutputAudioConfig_|null;
  audio?: string|null;
}
export class OutputAudio_ extends Serializable {
  constructor(parameters: OutputAudio_Parameters = {}) {
    super();
    this.Serializable$set(
        'config', (parameters.config == null) ? (null) : (parameters.config));
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
  }

  get audio(): string|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * Required. The natural language speech audio.
   */
  set audio(value: string|null) {
    this.Serializable$set('audio', value);
  }

  get config(): OutputAudioConfig_|null {
    return (
        (this.Serializable$has('config')) ? (this.Serializable$get('config')) :
                                            (null));
  }

  /**
   * Required. Instructs the speech synthesizer how to generate the speech
   * audio.
   */
  set config(value: OutputAudioConfig_|null) {
    this.Serializable$set('config', value);
  }

  getConstructor(): SerializableCtor<OutputAudio_> {
    return OutputAudio_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['audio', 'config'], objects: {'config': OutputAudioConfig_}};
  }
}

export interface ParameterSuggestionsCandidateList_Parameters {
  candidates?: Array<ParameterSuggestionsCandidate_>|null;
}
export class ParameterSuggestionsCandidateList_ extends Serializable {
  constructor(parameters: ParameterSuggestionsCandidateList_Parameters = {}) {
    super();
    this.Serializable$set(
        'candidates',
        (parameters.candidates == null) ? (null) : (parameters.candidates));
  }

  get candidates(): Array<ParameterSuggestionsCandidate_>|null {
    return (
        (this.Serializable$has('candidates')) ?
            (this.Serializable$get('candidates')) :
            (null));
  }

  /**
   * Represents candidates of a parameter.
   */
  set candidates(value: Array<ParameterSuggestionsCandidate_>|null) {
    this.Serializable$set('candidates', value);
  }

  getConstructor(): SerializableCtor<ParameterSuggestionsCandidateList_> {
    return ParameterSuggestionsCandidateList_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'candidates': ParameterSuggestionsCandidate_},
      keys: ['candidates']
    };
  }
}

export interface ParameterSuggestionsCandidate_Parameters {
  value?: any|null;
  textMessage?: string|null;
  answerRecord?: string|null;
  suggestedText?: string|null;
}
export class ParameterSuggestionsCandidate_ extends Serializable {
  constructor(parameters: ParameterSuggestionsCandidate_Parameters = {}) {
    super();
    this.Serializable$set(
        'value', (parameters.value == null) ? (null) : (parameters.value));
    this.Serializable$set(
        'textMessage',
        (parameters.textMessage == null) ? (null) : (parameters.textMessage));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
    this.Serializable$set(
        'suggestedText',
        (parameters.suggestedText == null) ? (null) :
                                             (parameters.suggestedText));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get suggestedText(): string|null {
    return (
        (this.Serializable$has('suggestedText')) ?
            (this.Serializable$get('suggestedText')) :
            (null));
  }

  /**
   * Suggestion text corresponding to the candidate value. Submitting the text
   * (as the \"value\" of the form parameter) will result in detection of the
   * candidate value.
   */
  set suggestedText(value: string|null) {
    this.Serializable$set('suggestedText', value);
  }

  get textMessage(): string|null {
    return (
        (this.Serializable$has('textMessage')) ?
            (this.Serializable$get('textMessage')) :
            (null));
  }

  /**
   * The message from which the candidate was extracted.
   */
  set textMessage(value: string|null) {
    this.Serializable$set('textMessage', value);
  }

  get value(): any|null {
    return (
        (this.Serializable$has('value')) ? (this.Serializable$get('value')) :
                                           (null));
  }

  /**
   * Candidate value for the parameter extracted from conversation.
   */
  set value(value: any|null) {
    this.Serializable$set('value', value);
  }

  getConstructor(): SerializableCtor<ParameterSuggestionsCandidate_> {
    return ParameterSuggestionsCandidate_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['answerRecord', 'suggestedText', 'textMessage', 'value']};
  }
}

export interface ParameterSuggestions_Parameters {
  parameterCandidates?: ApiClientObjectMap<ParameterSuggestionsCandidateList_>|
      null;
}
export class ParameterSuggestions_ extends Serializable {
  constructor(parameters: ParameterSuggestions_Parameters = {}) {
    super();
    this.Serializable$set(
        'parameterCandidates',
        (parameters.parameterCandidates == null) ?
            (null) :
            (parameters.parameterCandidates));
  }

  get parameterCandidates():
      ApiClientObjectMap<ParameterSuggestionsCandidateList_>|null {
    return (
        (this.Serializable$has('parameterCandidates')) ?
            (this.Serializable$get('parameterCandidates')) :
            (null));
  }

  /**
   * A mapping from a parameter's name to its candidates. If more than one
   * candidate exists, they are ranked in descending order of the detection
   * confidence.
   */
  set parameterCandidates(
      value: ApiClientObjectMap<ParameterSuggestionsCandidateList_>|null) {
    this.Serializable$set('parameterCandidates', value);
  }

  getConstructor(): SerializableCtor<ParameterSuggestions_> {
    return ParameterSuggestions_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['parameterCandidates'],
      objectMaps: {
        'parameterCandidates': {
          ctor: ParameterSuggestionsCandidateList_,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface Participant_Parameters {
  name?: string|null;
  role?: Participant_Role|null;
  sipRecordingMediaLabel?: string|null;
  obfuscatedExternalUserId?: string|null;
  documentsMetadataFilters?: ApiClientObjectMap<string>|null;
}
export class Participant_ extends Serializable {
  constructor(parameters: Participant_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'role', (parameters.role == null) ? (null) : (parameters.role));
    this.Serializable$set(
        'sipRecordingMediaLabel',
        (parameters.sipRecordingMediaLabel == null) ?
            (null) :
            (parameters.sipRecordingMediaLabel));
    this.Serializable$set(
        'obfuscatedExternalUserId',
        (parameters.obfuscatedExternalUserId == null) ?
            (null) :
            (parameters.obfuscatedExternalUserId));
    this.Serializable$set(
        'documentsMetadataFilters',
        (parameters.documentsMetadataFilters == null) ?
            (null) :
            (parameters.documentsMetadataFilters));
  }

  static get Role(): IParticipant_RoleEnum {
    return Participant_RoleEnum;
  }

  get documentsMetadataFilters(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('documentsMetadataFilters')) ?
            (this.Serializable$get('documentsMetadataFilters')) :
            (null));
  }

  /**
   * Optional. Key-value filters on the metadata of documents returned by
   * article suggestion. If specified, article suggestion only returns suggested
   * documents that match all filters in their Document.metadata. Multiple
   * values for a metadata key should be concatenated by comma. For example,
   * filters to match all documents that have 'US' or 'CA' in their market
   * metadata values and 'agent' in their user metadata values will be ```
   * documents_metadata_filters { key: \"market\" value: \"US,CA\" }
   * documents_metadata_filters { key: \"user\" value: \"agent\" } ```
   */
  set documentsMetadataFilters(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('documentsMetadataFilters', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Optional. The unique identifier of this participant. Format:
   * `projects//locations//conversations//participants/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get obfuscatedExternalUserId(): string|null {
    return (
        (this.Serializable$has('obfuscatedExternalUserId')) ?
            (this.Serializable$get('obfuscatedExternalUserId')) :
            (null));
  }

  /**
   * Optional. Obfuscated user id that should be associated with the created
   * participant. You can specify a user id as follows: 1. If you set this field
   * in CreateParticipantRequest or UpdateParticipantRequest, Dialogflow adds
   * the obfuscated user id with the participant. 2. If you set this field in
   * AnalyzeContent or StreamingAnalyzeContent, Dialogflow will update
   * Participant.obfuscated_external_user_id. Dialogflow uses this user id for
   * following purposes: 1) Billing and measurement. If user with the same
   * obfuscated_external_user_id is created in a later conversation, dialogflow
   * will know it's the same user. 2) Agent assist suggestion personalization.
   * For example, Dialogflow can use it to provide personalized smart reply
   * suggestions for this user. Note: * Please never pass raw user ids to
   * Dialogflow. Always obfuscate your user id first. * Dialogflow only accepts
   * a UTF-8 encoded string, e.g., a hex digest of a hash function like SHA-512.
   * * The length of the user id must be <= 256 characters.
   */
  set obfuscatedExternalUserId(value: string|null) {
    this.Serializable$set('obfuscatedExternalUserId', value);
  }

  get role(): Participant_Role|null {
    return (
        (this.Serializable$has('role')) ? (this.Serializable$get('role')) :
                                          (null));
  }

  /**
   * Immutable. The role this participant plays in the conversation. This field
   * must be set during participant creation and is then immutable.
   */
  set role(value: Participant_Role|null) {
    this.Serializable$set('role', value);
  }

  get sipRecordingMediaLabel(): string|null {
    return (
        (this.Serializable$has('sipRecordingMediaLabel')) ?
            (this.Serializable$get('sipRecordingMediaLabel')) :
            (null));
  }

  /**
   * Optional. Label applied to streams representing this participant in SIPREC
   * XML metadata and SDP. This is used to assign transcriptions from that media
   * stream to this participant. This field can be updated.
   */
  set sipRecordingMediaLabel(value: string|null) {
    this.Serializable$set('sipRecordingMediaLabel', value);
  }

  getConstructor(): SerializableCtor<Participant_> {
    return Participant_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'role': Participant_RoleEnum},
      keys: [
        'documentsMetadataFilters', 'name', 'obfuscatedExternalUserId', 'role',
        'sipRecordingMediaLabel'
      ],
      objectMaps: {
        'documentsMetadataFilters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface PhoneNumberLease_Parameters {
  name?: string|null;
  phoneNumberSpec?: PhoneNumberSpec_|null;
  leaseDuration?: string|null;
  lifecycleState?: PhoneNumberLease_LifecycleState|null;
  phoneNumbers?: ApiClientObjectMap<string>|null;
  phoneNumberOrder?: string|null;
  createTime?: string|null;
  expireTime?: string|null;
}
export class PhoneNumberLease_ extends Serializable {
  constructor(parameters: PhoneNumberLease_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'phoneNumberSpec',
        (parameters.phoneNumberSpec == null) ? (null) :
                                               (parameters.phoneNumberSpec));
    this.Serializable$set(
        'leaseDuration',
        (parameters.leaseDuration == null) ? (null) :
                                             (parameters.leaseDuration));
    this.Serializable$set(
        'lifecycleState',
        (parameters.lifecycleState == null) ? (null) :
                                              (parameters.lifecycleState));
    this.Serializable$set(
        'phoneNumbers',
        (parameters.phoneNumbers == null) ? (null) : (parameters.phoneNumbers));
    this.Serializable$set(
        'phoneNumberOrder',
        (parameters.phoneNumberOrder == null) ? (null) :
                                                (parameters.phoneNumberOrder));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'expireTime',
        (parameters.expireTime == null) ? (null) : (parameters.expireTime));
  }

  static get LifecycleState(): IPhoneNumberLease_LifecycleStateEnum {
    return PhoneNumberLease_LifecycleStateEnum;
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The time this lease was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get expireTime(): string|null {
    return (
        (this.Serializable$has('expireTime')) ?
            (this.Serializable$get('expireTime')) :
            (null));
  }

  /**
   * Output only. The time this lease will expire.
   */
  set expireTime(value: string|null) {
    this.Serializable$set('expireTime', value);
  }

  get leaseDuration(): string|null {
    return (
        (this.Serializable$has('leaseDuration')) ?
            (this.Serializable$get('leaseDuration')) :
            (null));
  }

  /**
   * Optional. How long the lease should last. Defaults to 1m. Must be less than
   * 1h.
   */
  set leaseDuration(value: string|null) {
    this.Serializable$set('leaseDuration', value);
  }

  get lifecycleState(): PhoneNumberLease_LifecycleState|null {
    return (
        (this.Serializable$has('lifecycleState')) ?
            (this.Serializable$get('lifecycleState')) :
            (null));
  }

  /**
   * Output only. The current status of the lease.
   */
  set lifecycleState(value: PhoneNumberLease_LifecycleState|null) {
    this.Serializable$set('lifecycleState', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Requied. The unique identifier of this lease. Format:
   * `projects//phoneNumberLeases/`. Format:
   * `projects//locations//phoneNumberLeases/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get phoneNumberOrder(): string|null {
    return (
        (this.Serializable$has('phoneNumberOrder')) ?
            (this.Serializable$get('phoneNumberOrder')) :
            (null));
  }

  /**
   * Output only. The name of the order that this lease got upgraded to. Format:
   * `projects//phoneNumberOrders/`. Format:
   * `projects//locations//phoneNumberOrders/`.
   */
  set phoneNumberOrder(value: string|null) {
    this.Serializable$set('phoneNumberOrder', value);
  }

  get phoneNumberSpec(): PhoneNumberSpec_|null {
    return (
        (this.Serializable$has('phoneNumberSpec')) ?
            (this.Serializable$get('phoneNumberSpec')) :
            (null));
  }

  /**
   * Required. Definition of what is being leased.
   */
  set phoneNumberSpec(value: PhoneNumberSpec_|null) {
    this.Serializable$set('phoneNumberSpec', value);
  }

  get phoneNumbers(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('phoneNumbers')) ?
            (this.Serializable$get('phoneNumbers')) :
            (null));
  }

  /**
   * Output only. A map of leased numbers, keyed by their resource name. Key
   * format: `projects//phoneNumbers/`. Value format: E.164 phone number.
   */
  set phoneNumbers(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('phoneNumbers', value);
  }

  getConstructor(): SerializableCtor<PhoneNumberLease_> {
    return PhoneNumberLease_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'lifecycleState': PhoneNumberLease_LifecycleStateEnum},
      keys: [
        'createTime', 'expireTime', 'leaseDuration', 'lifecycleState', 'name',
        'phoneNumberOrder', 'phoneNumberSpec', 'phoneNumbers'
      ],
      objectMaps: {
        'phoneNumbers': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'phoneNumberSpec': PhoneNumberSpec_}
    };
  }
}

export interface PhoneNumberOrder_Parameters {
  name?: string|null;
  phoneNumberSpec?: PhoneNumberSpec_|null;
  description?: string|null;
  lifecycleState?: PhoneNumberOrder_LifecycleState|null;
  phoneNumbers?: ApiClientObjectMap<string>|null;
  createTime?: string|null;
  updateTime?: string|null;
}
export class PhoneNumberOrder_ extends Serializable {
  constructor(parameters: PhoneNumberOrder_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'phoneNumberSpec',
        (parameters.phoneNumberSpec == null) ? (null) :
                                               (parameters.phoneNumberSpec));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'lifecycleState',
        (parameters.lifecycleState == null) ? (null) :
                                              (parameters.lifecycleState));
    this.Serializable$set(
        'phoneNumbers',
        (parameters.phoneNumbers == null) ? (null) : (parameters.phoneNumbers));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
  }

  static get LifecycleState(): IPhoneNumberOrder_LifecycleStateEnum {
    return PhoneNumberOrder_LifecycleStateEnum;
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The time this order was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. A description of the order, limit is 1024 bytes.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get lifecycleState(): PhoneNumberOrder_LifecycleState|null {
    return (
        (this.Serializable$has('lifecycleState')) ?
            (this.Serializable$get('lifecycleState')) :
            (null));
  }

  /**
   * Output only. The current status of the order.
   */
  set lifecycleState(value: PhoneNumberOrder_LifecycleState|null) {
    this.Serializable$set('lifecycleState', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Optional. The unique identifier of this order. Required for
   * PhoneNumberOrders.UpdatePhoneNumberOrder method. Format:
   * `projects//phoneNumberOrders/`. Format:
   * `projects//locations//phoneNumberOrders/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get phoneNumberSpec(): PhoneNumberSpec_|null {
    return (
        (this.Serializable$has('phoneNumberSpec')) ?
            (this.Serializable$get('phoneNumberSpec')) :
            (null));
  }

  /**
   * Order is for new numbers.
   */
  set phoneNumberSpec(value: PhoneNumberSpec_|null) {
    this.Serializable$set('phoneNumberSpec', value);
  }

  get phoneNumbers(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('phoneNumbers')) ?
            (this.Serializable$get('phoneNumbers')) :
            (null));
  }

  /**
   * Output only. A map of ordered numbers filled so far, keyed by their
   * resource name. Key format: `projects//phoneNumbers/`. Value format: E.164
   * phone number. Output only.
   */
  set phoneNumbers(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('phoneNumbers', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. The time this order was last updated.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<PhoneNumberOrder_> {
    return PhoneNumberOrder_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'lifecycleState': PhoneNumberOrder_LifecycleStateEnum},
      keys: [
        'createTime', 'description', 'lifecycleState', 'name',
        'phoneNumberSpec', 'phoneNumbers', 'updateTime'
      ],
      objectMaps: {
        'phoneNumbers': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'phoneNumberSpec': PhoneNumberSpec_}
    };
  }
}

export interface PhoneNumberSpec_Parameters {
  countryCode?: number|null;
  preferredAreaCodes?: Array<string>|null;
  count?: number|null;
}
export class PhoneNumberSpec_ extends Serializable {
  constructor(parameters: PhoneNumberSpec_Parameters = {}) {
    super();
    this.Serializable$set(
        'countryCode',
        (parameters.countryCode == null) ? (null) : (parameters.countryCode));
    this.Serializable$set(
        'preferredAreaCodes',
        (parameters.preferredAreaCodes == null) ?
            (null) :
            (parameters.preferredAreaCodes));
    this.Serializable$set(
        'count', (parameters.count == null) ? (null) : (parameters.count));
  }

  get count(): number|null {
    return (
        (this.Serializable$has('count')) ? (this.Serializable$get('count')) :
                                           (null));
  }

  /**
   * Required. Total numbers requested, between 1 and 10 inclusive.
   */
  set count(value: number|null) {
    this.Serializable$set('count', value);
  }

  get countryCode(): number|null {
    return (
        (this.Serializable$has('countryCode')) ?
            (this.Serializable$get('countryCode')) :
            (null));
  }

  /**
   * Required. ITU country calling code for the requested numbers. Defaults to 1
   * (US) until the service is available in other regions.
   */
  set countryCode(value: number|null) {
    this.Serializable$set('countryCode', value);
  }

  get preferredAreaCodes(): Array<string>|null {
    return (
        (this.Serializable$has('preferredAreaCodes')) ?
            (this.Serializable$get('preferredAreaCodes')) :
            (null));
  }

  /**
   * Optional. Area codes to use. An empty list means 'any code'. Each value is
   * treated as equally preferred. Each entry has a limit of 10 bytes. \"area
   * code\" corresponds to \"National Destination Code\" described in
   * [E.164](https://en.wikipedia.org/wiki/E.164) standard.
   */
  set preferredAreaCodes(value: Array<string>|null) {
    this.Serializable$set('preferredAreaCodes', value);
  }

  getConstructor(): SerializableCtor<PhoneNumberSpec_> {
    return PhoneNumberSpec_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['count', 'countryCode', 'preferredAreaCodes']};
  }
}

export interface PhoneNumber_Parameters {
  name?: string|null;
  phoneNumber?: string|null;
  conversationProfile?: string|null;
  lifecycleState?: PhoneNumber_LifecycleState|null;
  callForwardingPhoneNumber?: string|null;
  callForwardingSip?: SipEndpoint_|null;
}
export class PhoneNumber_ extends Serializable {
  constructor(parameters: PhoneNumber_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
    this.Serializable$set(
        'conversationProfile',
        (parameters.conversationProfile == null) ?
            (null) :
            (parameters.conversationProfile));
    this.Serializable$set(
        'lifecycleState',
        (parameters.lifecycleState == null) ? (null) :
                                              (parameters.lifecycleState));
    this.Serializable$set(
        'callForwardingPhoneNumber',
        (parameters.callForwardingPhoneNumber == null) ?
            (null) :
            (parameters.callForwardingPhoneNumber));
    this.Serializable$set(
        'callForwardingSip',
        (parameters.callForwardingSip == null) ?
            (null) :
            (parameters.callForwardingSip));
  }

  static get LifecycleState(): IPhoneNumber_LifecycleStateEnum {
    return PhoneNumber_LifecycleStateEnum;
  }

  get callForwardingPhoneNumber(): string|null {
    return (
        (this.Serializable$has('callForwardingPhoneNumber')) ?
            (this.Serializable$get('callForwardingPhoneNumber')) :
            (null));
  }

  /**
   * Optional. Forwards the call to a phone number. Formatted in
   * [E.164](https://en.wikipedia.org/wiki/E.164).
   */
  set callForwardingPhoneNumber(value: string|null) {
    this.Serializable$set('callForwardingPhoneNumber', value);
  }

  get callForwardingSip(): SipEndpoint_|null {
    return (
        (this.Serializable$has('callForwardingSip')) ?
            (this.Serializable$get('callForwardingSip')) :
            (null));
  }

  /**
   * Optional. Forwards to a SIP endpoint.
   */
  set callForwardingSip(value: SipEndpoint_|null) {
    this.Serializable$set('callForwardingSip', value);
  }

  get conversationProfile(): string|null {
    return (
        (this.Serializable$has('conversationProfile')) ?
            (this.Serializable$get('conversationProfile')) :
            (null));
  }

  /**
   * Optional. The conversation profile calls to this `PhoneNumber` should use.
   * The project ID here should be the same as the one in name. Format:
   * `projects//conversationProfiles/`. Format:
   * `projects//locations//conversationProfiles/`.
   */
  set conversationProfile(value: string|null) {
    this.Serializable$set('conversationProfile', value);
  }

  get lifecycleState(): PhoneNumber_LifecycleState|null {
    return (
        (this.Serializable$has('lifecycleState')) ?
            (this.Serializable$get('lifecycleState')) :
            (null));
  }

  /**
   * Output only. The state of the `PhoneNumber`. Defaults to `ACTIVE`.
   * `PhoneNumber` objects set to `DELETE_REQUESTED` always decline incoming
   * calls and can be removed completely within 30 days.
   */
  set lifecycleState(value: PhoneNumber_LifecycleState|null) {
    this.Serializable$set('lifecycleState', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Optional. The unique identifier of this phone number. Required for
   * PhoneNumbers.UpdatePhoneNumber method. Format: `projects//phoneNumbers/`.
   * Format: `projects//locations//phoneNumbers/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get phoneNumber(): string|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Output only. Phone number in [E.164](https://en.wikipedia.org/wiki/E.164)
   * format. An example of a correctly formatted phone number: +15556767888.
   */
  set phoneNumber(value: string|null) {
    this.Serializable$set('phoneNumber', value);
  }

  getConstructor(): SerializableCtor<PhoneNumber_> {
    return PhoneNumber_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'lifecycleState': PhoneNumber_LifecycleStateEnum},
      keys: [
        'callForwardingPhoneNumber', 'callForwardingSip', 'conversationProfile',
        'lifecycleState', 'name', 'phoneNumber'
      ],
      objects: {'callForwardingSip': SipEndpoint_}
    };
  }
}

export interface PreviewActionPackageRequest_Parameters {}
export class PreviewActionPackageRequest_ extends Serializable {
  constructor(parameters: PreviewActionPackageRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<PreviewActionPackageRequest_> {
    return PreviewActionPackageRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface PreviewActionPackageResponse_Parameters {}
export class PreviewActionPackageResponse_ extends Serializable {
  constructor(parameters: PreviewActionPackageResponse_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<PreviewActionPackageResponse_> {
    return PreviewActionPackageResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface PreviewAogAgentRequest_Parameters {}
export class PreviewAogAgentRequest_ extends Serializable {
  constructor(parameters: PreviewAogAgentRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<PreviewAogAgentRequest_> {
    return PreviewAogAgentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface PreviewAogAgentResponse_Parameters {
  displayName?: string|null;
  createTime?: string|null;
  updateTime?: string|null;
  approvalState?: PreviewAogAgentResponse_ApprovalState|null;
}
export class PreviewAogAgentResponse_ extends Serializable {
  constructor(parameters: PreviewAogAgentResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'approvalState',
        (parameters.approvalState == null) ? (null) :
                                             (parameters.approvalState));
  }

  static get ApprovalState(): IPreviewAogAgentResponse_ApprovalStateEnum {
    return PreviewAogAgentResponse_ApprovalStateEnum;
  }

  get approvalState(): PreviewAogAgentResponse_ApprovalState|null {
    return (
        (this.Serializable$has('approvalState')) ?
            (this.Serializable$get('approvalState')) :
            (null));
  }

  /**
   * Approval state.
   */
  set approvalState(value: PreviewAogAgentResponse_ApprovalState|null) {
    this.Serializable$set('approvalState', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Creation time.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Name of the AoG agent.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Update time.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<PreviewAogAgentResponse_> {
    return PreviewAogAgentResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'approvalState': PreviewAogAgentResponse_ApprovalStateEnum},
      keys: ['approvalState', 'createTime', 'displayName', 'updateTime']
    };
  }
}

export interface Project_Parameters {
  projectName?: string|null;
}
export class Project_ extends Serializable {
  constructor(parameters: Project_Parameters = {}) {
    super();
    this.Serializable$set(
        'projectName',
        (parameters.projectName == null) ? (null) : (parameters.projectName));
  }

  get projectName(): string|null {
    return (
        (this.Serializable$has('projectName')) ?
            (this.Serializable$get('projectName')) :
            (null));
  }

  /**
   * Cloud project name.
   */
  set projectName(value: string|null) {
    this.Serializable$set('projectName', value);
  }

  getConstructor(): SerializableCtor<Project_> {
    return Project_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['projectName']};
  }
}

export interface QueryContext_Parameters {
  document?: string|null;
  neighborCount?: number|null;
  turnsPerWindowCount?: number|null;
  scoreThreshold?: number|null;
  maxIssueCount?: number|null;
  slidingWindowStride?: number|null;
  minCharactersPerMessage?: number|null;
  topNWindowCount?: number|null;
  customerMessageOnly?: boolean|null;
  firstNTurnsOnly?: number|null;
}
export class QueryContext_ extends Serializable {
  constructor(parameters: QueryContext_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'neighborCount',
        (parameters.neighborCount == null) ? (null) :
                                             (parameters.neighborCount));
    this.Serializable$set(
        'turnsPerWindowCount',
        (parameters.turnsPerWindowCount == null) ?
            (null) :
            (parameters.turnsPerWindowCount));
    this.Serializable$set(
        'scoreThreshold',
        (parameters.scoreThreshold == null) ? (null) :
                                              (parameters.scoreThreshold));
    this.Serializable$set(
        'maxIssueCount',
        (parameters.maxIssueCount == null) ? (null) :
                                             (parameters.maxIssueCount));
    this.Serializable$set(
        'slidingWindowStride',
        (parameters.slidingWindowStride == null) ?
            (null) :
            (parameters.slidingWindowStride));
    this.Serializable$set(
        'minCharactersPerMessage',
        (parameters.minCharactersPerMessage == null) ?
            (null) :
            (parameters.minCharactersPerMessage));
    this.Serializable$set(
        'topNWindowCount',
        (parameters.topNWindowCount == null) ? (null) :
                                               (parameters.topNWindowCount));
    this.Serializable$set(
        'customerMessageOnly',
        (parameters.customerMessageOnly == null) ?
            (null) :
            (parameters.customerMessageOnly));
    this.Serializable$set(
        'firstNTurnsOnly',
        (parameters.firstNTurnsOnly == null) ? (null) :
                                               (parameters.firstNTurnsOnly));
  }

  get customerMessageOnly(): boolean|null {
    return (
        (this.Serializable$has('customerMessageOnly')) ?
            (this.Serializable$get('customerMessageOnly')) :
            (null));
  }

  /**
   * If true only use customer messages. If false use customer and agent
   * messages.
   */
  set customerMessageOnly(value: boolean|null) {
    this.Serializable$set('customerMessageOnly', value);
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * The document resource name that we want to query against. We will search
   * against all document indexes within the provided document. Format is
   * projects//knowledgeBases// documents/.
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  get firstNTurnsOnly(): number|null {
    return (
        (this.Serializable$has('firstNTurnsOnly')) ?
            (this.Serializable$get('firstNTurnsOnly')) :
            (null));
  }

  /**
   * This is to reduce/limit QPS. Default value is 50.
   */
  set firstNTurnsOnly(value: number|null) {
    this.Serializable$set('firstNTurnsOnly', value);
  }

  get maxIssueCount(): number|null {
    return (
        (this.Serializable$has('maxIssueCount')) ?
            (this.Serializable$get('maxIssueCount')) :
            (null));
  }

  /**
   * The maximum number of issues to extract. Default value is 1.
   */
  set maxIssueCount(value: number|null) {
    this.Serializable$set('maxIssueCount', value);
  }

  get minCharactersPerMessage(): number|null {
    return (
        (this.Serializable$has('minCharactersPerMessage')) ?
            (this.Serializable$get('minCharactersPerMessage')) :
            (null));
  }

  /**
   * Filter out windows with total character count of message less than min
   * size.
   */
  set minCharactersPerMessage(value: number|null) {
    this.Serializable$set('minCharactersPerMessage', value);
  }

  get neighborCount(): number|null {
    return (
        (this.Serializable$has('neighborCount')) ?
            (this.Serializable$get('neighborCount')) :
            (null));
  }

  /**
   * Number of nearest neighbors to be retrieved. Used as k in
   * k-nearest-neighbors(kNN) search. Default value is 3.
   */
  set neighborCount(value: number|null) {
    this.Serializable$set('neighborCount', value);
  }

  get scoreThreshold(): number|null {
    return (
        (this.Serializable$has('scoreThreshold')) ?
            (this.Serializable$get('scoreThreshold')) :
            (null));
  }

  /**
   * Cutoff threshold for an issue to be qualified. range is [0, 1] Default
   * value is 0.75.
   */
  set scoreThreshold(value: number|null) {
    this.Serializable$set('scoreThreshold', value);
  }

  get slidingWindowStride(): number|null {
    return (
        (this.Serializable$has('slidingWindowStride')) ?
            (this.Serializable$get('slidingWindowStride')) :
            (null));
  }

  /**
   * The stride of the sliding window. The stride unit is turn.
   */
  set slidingWindowStride(value: number|null) {
    this.Serializable$set('slidingWindowStride', value);
  }

  get topNWindowCount(): number|null {
    return (
        (this.Serializable$has('topNWindowCount')) ?
            (this.Serializable$get('topNWindowCount')) :
            (null));
  }

  /**
   * The average score of the top-n windows of a specific topic is used as the
   * assignment score for that topic, so that any topic assignment score is in
   * [0, 1] and topic assignment is not too sensitive to one error. Default
   * value is 2.
   */
  set topNWindowCount(value: number|null) {
    this.Serializable$set('topNWindowCount', value);
  }

  get turnsPerWindowCount(): number|null {
    return (
        (this.Serializable$has('turnsPerWindowCount')) ?
            (this.Serializable$get('turnsPerWindowCount')) :
            (null));
  }

  /**
   * Number of turns in a sliding window in inference time.
   */
  set turnsPerWindowCount(value: number|null) {
    this.Serializable$set('turnsPerWindowCount', value);
  }

  getConstructor(): SerializableCtor<QueryContext_> {
    return QueryContext_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'customerMessageOnly', 'document', 'firstNTurnsOnly', 'maxIssueCount',
        'minCharactersPerMessage', 'neighborCount', 'scoreThreshold',
        'slidingWindowStride', 'topNWindowCount', 'turnsPerWindowCount'
      ]
    };
  }
}

export interface QueryInput_Parameters {
  audioConfig?: InputAudioConfig_|null;
  text?: TextInput_|null;
  event?: EventInput_|null;
  dtmf?: TelephonyDtmfEvents_|null;
}
export class QueryInput_ extends Serializable {
  constructor(parameters: QueryInput_Parameters = {}) {
    super();
    this.Serializable$set(
        'audioConfig',
        (parameters.audioConfig == null) ? (null) : (parameters.audioConfig));
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'event', (parameters.event == null) ? (null) : (parameters.event));
    this.Serializable$set(
        'dtmf', (parameters.dtmf == null) ? (null) : (parameters.dtmf));
  }

  get audioConfig(): InputAudioConfig_|null {
    return (
        (this.Serializable$has('audioConfig')) ?
            (this.Serializable$get('audioConfig')) :
            (null));
  }

  /**
   * Instructs the speech recognizer how to process the speech audio.
   */
  set audioConfig(value: InputAudioConfig_|null) {
    this.Serializable$set('audioConfig', value);
  }

  get dtmf(): TelephonyDtmfEvents_|null {
    return (
        (this.Serializable$has('dtmf')) ? (this.Serializable$get('dtmf')) :
                                          (null));
  }

  /**
   * The DTMF digits used to invoke intent and fill in parameter value.
   */
  set dtmf(value: TelephonyDtmfEvents_|null) {
    this.Serializable$set('dtmf', value);
  }

  get event(): EventInput_|null {
    return (
        (this.Serializable$has('event')) ? (this.Serializable$get('event')) :
                                           (null));
  }

  /**
   * The event to be processed.
   */
  set event(value: EventInput_|null) {
    this.Serializable$set('event', value);
  }

  get text(): TextInput_|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The natural language text to be processed.
   */
  set text(value: TextInput_|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<QueryInput_> {
    return QueryInput_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['audioConfig', 'dtmf', 'event', 'text'],
      objects: {
        'audioConfig': InputAudioConfig_,
        'dtmf': TelephonyDtmfEvents_,
        'event': EventInput_,
        'text': TextInput_
      }
    };
  }
}

export interface QueryParameters_Parameters {
  timeZone?: string|null;
  geoLocation?: GoogleTypeLatLng|null;
  contexts?: Array<Context_>|null;
  resetContexts?: boolean|null;
  sessionEntityTypes?: Array<SessionEntityType_>|null;
  payload?: ApiClientObjectMap<any>|null;
  source?: string|null;
  knowledgeBaseNames?: Array<string>|null;
  sentimentAnalysisRequestConfig?: SentimentAnalysisRequestConfig_|null;
  clientIp?: string|null;
  subAgents?: Array<SubAgent_>|null;
  webhookHeaders?: ApiClientObjectMap<string>|null;
  enableResourceProjectOverride?: boolean|null;
  disableTts?: boolean|null;
}
export class QueryParameters_ extends Serializable {
  constructor(parameters: QueryParameters_Parameters = {}) {
    super();
    this.Serializable$set(
        'timeZone',
        (parameters.timeZone == null) ? (null) : (parameters.timeZone));
    this.Serializable$set(
        'geoLocation',
        (parameters.geoLocation == null) ? (null) : (parameters.geoLocation));
    this.Serializable$set(
        'contexts',
        (parameters.contexts == null) ? (null) : (parameters.contexts));
    this.Serializable$set(
        'resetContexts',
        (parameters.resetContexts == null) ? (null) :
                                             (parameters.resetContexts));
    this.Serializable$set(
        'sessionEntityTypes',
        (parameters.sessionEntityTypes == null) ?
            (null) :
            (parameters.sessionEntityTypes));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'knowledgeBaseNames',
        (parameters.knowledgeBaseNames == null) ?
            (null) :
            (parameters.knowledgeBaseNames));
    this.Serializable$set(
        'sentimentAnalysisRequestConfig',
        (parameters.sentimentAnalysisRequestConfig == null) ?
            (null) :
            (parameters.sentimentAnalysisRequestConfig));
    this.Serializable$set(
        'clientIp',
        (parameters.clientIp == null) ? (null) : (parameters.clientIp));
    this.Serializable$set(
        'subAgents',
        (parameters.subAgents == null) ? (null) : (parameters.subAgents));
    this.Serializable$set(
        'webhookHeaders',
        (parameters.webhookHeaders == null) ? (null) :
                                              (parameters.webhookHeaders));
    this.Serializable$set(
        'enableResourceProjectOverride',
        (parameters.enableResourceProjectOverride == null) ?
            (null) :
            (parameters.enableResourceProjectOverride));
    this.Serializable$set(
        'disableTts',
        (parameters.disableTts == null) ? (null) : (parameters.disableTts));
  }

  get clientIp(): string|null {
    return (
        (this.Serializable$has('clientIp')) ?
            (this.Serializable$get('clientIp')) :
            (null));
  }

  /**
   * IP address (V4 or V6) of the client calling the API. We get the IP from ESF
   * and pass it to the backend in this internal field for allowlisted projects.
   * The backend will log it in the Stackdriver logs. Note that if the API was
   * called from an integration or other internal service (like Google
   * Assistant), this may be the IP of the internal service on Borg or on Google
   * Cloud.
   */
  set clientIp(value: string|null) {
    this.Serializable$set('clientIp', value);
  }

  get contexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('contexts')) ?
            (this.Serializable$get('contexts')) :
            (null));
  }

  /**
   * The collection of contexts to be activated before this query is executed.
   */
  set contexts(value: Array<Context_>|null) {
    this.Serializable$set('contexts', value);
  }

  get disableTts(): boolean|null {
    return (
        (this.Serializable$has('disableTts')) ?
            (this.Serializable$get('disableTts')) :
            (null));
  }

  /**
   * Whether to disable TTS entirely even if TTS is enabled at agent-level.
   */
  set disableTts(value: boolean|null) {
    this.Serializable$set('disableTts', value);
  }

  get enableResourceProjectOverride(): boolean|null {
    return (
        (this.Serializable$has('enableResourceProjectOverride')) ?
            (this.Serializable$get('enableResourceProjectOverride')) :
            (null));
  }

  /**
   * Whether to charge the query to resource project instead of consumer
   * project.
   */
  set enableResourceProjectOverride(value: boolean|null) {
    this.Serializable$set('enableResourceProjectOverride', value);
  }

  get geoLocation(): GoogleTypeLatLng|null {
    return (
        (this.Serializable$has('geoLocation')) ?
            (this.Serializable$get('geoLocation')) :
            (null));
  }

  /**
   * The geo location of this conversational query.
   */
  set geoLocation(value: GoogleTypeLatLng|null) {
    this.Serializable$set('geoLocation', value);
  }

  get knowledgeBaseNames(): Array<string>|null {
    return (
        (this.Serializable$has('knowledgeBaseNames')) ?
            (this.Serializable$get('knowledgeBaseNames')) :
            (null));
  }

  /**
   * KnowledgeBases to get alternative results from. If not set, the
   * KnowledgeBases enabled in the agent (through UI) will be used. Format:
   * `projects//knowledgeBases/`.
   */
  set knowledgeBaseNames(value: Array<string>|null) {
    this.Serializable$set('knowledgeBaseNames', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * This field can be used to pass custom data to your webhook. Arbitrary JSON
   * objects are supported. If supplied, the value is used to populate the
   * `WebhookRequest.original_detect_intent_request.payload` field sent to your
   * webhook.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get resetContexts(): boolean|null {
    return (
        (this.Serializable$has('resetContexts')) ?
            (this.Serializable$get('resetContexts')) :
            (null));
  }

  /**
   * Specifies whether to delete all contexts in the current session before the
   * new ones are activated.
   */
  set resetContexts(value: boolean|null) {
    this.Serializable$set('resetContexts', value);
  }

  get sentimentAnalysisRequestConfig(): SentimentAnalysisRequestConfig_|null {
    return (
        (this.Serializable$has('sentimentAnalysisRequestConfig')) ?
            (this.Serializable$get('sentimentAnalysisRequestConfig')) :
            (null));
  }

  /**
   * Configures the type of sentiment analysis to perform. If not provided,
   * sentiment analysis is not performed. Note: Sentiment Analysis is only
   * currently available for Essentials Edition agents.
   */
  set sentimentAnalysisRequestConfig(value: SentimentAnalysisRequestConfig_|
                                     null) {
    this.Serializable$set('sentimentAnalysisRequestConfig', value);
  }

  get sessionEntityTypes(): Array<SessionEntityType_>|null {
    return (
        (this.Serializable$has('sessionEntityTypes')) ?
            (this.Serializable$get('sessionEntityTypes')) :
            (null));
  }

  /**
   * Additional session entity types to replace or extend developer entity types
   * with. The entity synonyms apply to all languages and persist for the
   * session of this query.
   */
  set sessionEntityTypes(value: Array<SessionEntityType_>|null) {
    this.Serializable$set('sessionEntityTypes', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * The new field is populated only by Bots Proxy and AoG Proxy. It is used by
   * Webhook logic to populate the corresponding field in webhook request.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  get subAgents(): Array<SubAgent_>|null {
    return (
        (this.Serializable$has('subAgents')) ?
            (this.Serializable$get('subAgents')) :
            (null));
  }

  /**
   * For mega agent query, directly specify which sub agents to query. If any
   * specified sub agent is not linked to the mega agent, an error will be
   * returned. If empty, Dialogflow will decide which sub agents to query. If
   * specified for a non-mega-agent query, will be silently ignored.
   */
  set subAgents(value: Array<SubAgent_>|null) {
    this.Serializable$set('subAgents', value);
  }

  get timeZone(): string|null {
    return (
        (this.Serializable$has('timeZone')) ?
            (this.Serializable$get('timeZone')) :
            (null));
  }

  /**
   * The time zone of this conversational query from the [time zone
   * database](https://www.iana.org/time-zones), e.g., America/New_York,
   * Europe/Paris. If not provided, the time zone specified in agent settings is
   * used.
   */
  set timeZone(value: string|null) {
    this.Serializable$set('timeZone', value);
  }

  get webhookHeaders(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('webhookHeaders')) ?
            (this.Serializable$get('webhookHeaders')) :
            (null));
  }

  /**
   * This field can be used to pass HTTP headers for a webhook call. These
   * headers will be sent to webhook along with the headers that have been
   * configured through Dialogflow web console. The headers defined within this
   * field will overwrite the headers configured through Dialogflow console if
   * there is a conflict. Header names are case-insensitive. Google's specified
   * headers are not allowed. Including: \"Host\", \"Content-Length\",
   * \"Connection\", \"From\", \"User-Agent\", \"Accept-Encoding\",
   * \"If-Modified-Since\", \"If-None-Match\", \"X-Forwarded-For\", etc.
   */
  set webhookHeaders(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('webhookHeaders', value);
  }

  getConstructor(): SerializableCtor<QueryParameters_> {
    return QueryParameters_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'contexts': Context_,
        'sessionEntityTypes': SessionEntityType_,
        'subAgents': SubAgent_
      },
      keys: [
        'clientIp', 'contexts', 'disableTts', 'enableResourceProjectOverride',
        'geoLocation', 'knowledgeBaseNames', 'payload', 'resetContexts',
        'sentimentAnalysisRequestConfig', 'sessionEntityTypes', 'source',
        'subAgents', 'timeZone', 'webhookHeaders'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'webhookHeaders': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'geoLocation': GoogleTypeLatLng,
        'sentimentAnalysisRequestConfig': SentimentAnalysisRequestConfig_
      }
    };
  }
}

export interface QueryResult_Parameters {
  queryText?: string|null;
  languageCode?: string|null;
  speechRecognitionConfidence?: number|null;
  action?: string|null;
  parameters?: ApiClientObjectMap<any>|null;
  allRequiredParamsPresent?: boolean|null;
  cancelsSlotFilling?: boolean|null;
  fulfillmentText?: string|null;
  fulfillmentMessages?: Array<IntentMessage_>|null;
  webhookSource?: string|null;
  webhookPayload?: ApiClientObjectMap<any>|null;
  outputContexts?: Array<Context_>|null;
  intent?: Intent_|null;
  intentDetectionConfidence?: number|null;
  diagnosticInfo?: ApiClientObjectMap<any>|null;
  sentimentAnalysisResult?: SentimentAnalysisResult_|null;
  knowledgeAnswers?: KnowledgeAnswers_|null;
  recordedAudioUri?: string|null;
  recordedResponseAudioExportGcs?: GcsDestination_|null;
}
export class QueryResult_ extends Serializable {
  constructor(parameters: QueryResult_Parameters = {}) {
    super();
    this.Serializable$set(
        'queryText',
        (parameters.queryText == null) ? (null) : (parameters.queryText));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'speechRecognitionConfidence',
        (parameters.speechRecognitionConfidence == null) ?
            (null) :
            (parameters.speechRecognitionConfidence));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'allRequiredParamsPresent',
        (parameters.allRequiredParamsPresent == null) ?
            (null) :
            (parameters.allRequiredParamsPresent));
    this.Serializable$set(
        'cancelsSlotFilling',
        (parameters.cancelsSlotFilling == null) ?
            (null) :
            (parameters.cancelsSlotFilling));
    this.Serializable$set(
        'fulfillmentText',
        (parameters.fulfillmentText == null) ? (null) :
                                               (parameters.fulfillmentText));
    this.Serializable$set(
        'fulfillmentMessages',
        (parameters.fulfillmentMessages == null) ?
            (null) :
            (parameters.fulfillmentMessages));
    this.Serializable$set(
        'webhookSource',
        (parameters.webhookSource == null) ? (null) :
                                             (parameters.webhookSource));
    this.Serializable$set(
        'webhookPayload',
        (parameters.webhookPayload == null) ? (null) :
                                              (parameters.webhookPayload));
    this.Serializable$set(
        'outputContexts',
        (parameters.outputContexts == null) ? (null) :
                                              (parameters.outputContexts));
    this.Serializable$set(
        'intent', (parameters.intent == null) ? (null) : (parameters.intent));
    this.Serializable$set(
        'intentDetectionConfidence',
        (parameters.intentDetectionConfidence == null) ?
            (null) :
            (parameters.intentDetectionConfidence));
    this.Serializable$set(
        'diagnosticInfo',
        (parameters.diagnosticInfo == null) ? (null) :
                                              (parameters.diagnosticInfo));
    this.Serializable$set(
        'sentimentAnalysisResult',
        (parameters.sentimentAnalysisResult == null) ?
            (null) :
            (parameters.sentimentAnalysisResult));
    this.Serializable$set(
        'knowledgeAnswers',
        (parameters.knowledgeAnswers == null) ? (null) :
                                                (parameters.knowledgeAnswers));
    this.Serializable$set(
        'recordedAudioUri',
        (parameters.recordedAudioUri == null) ? (null) :
                                                (parameters.recordedAudioUri));
    this.Serializable$set(
        'recordedResponseAudioExportGcs',
        (parameters.recordedResponseAudioExportGcs == null) ?
            (null) :
            (parameters.recordedResponseAudioExportGcs));
  }

  get action(): string|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * The action name from the matched intent.
   */
  set action(value: string|null) {
    this.Serializable$set('action', value);
  }

  get allRequiredParamsPresent(): boolean|null {
    return (
        (this.Serializable$has('allRequiredParamsPresent')) ?
            (this.Serializable$get('allRequiredParamsPresent')) :
            (null));
  }

  /**
   * This field is set to: - `false` if the matched intent has required
   * parameters and not all of the required parameter values have been
   * collected. - `true` if all required parameter values have been collected,
   * or if the matched intent doesn't contain any required parameters.
   */
  set allRequiredParamsPresent(value: boolean|null) {
    this.Serializable$set('allRequiredParamsPresent', value);
  }

  get cancelsSlotFilling(): boolean|null {
    return (
        (this.Serializable$has('cancelsSlotFilling')) ?
            (this.Serializable$get('cancelsSlotFilling')) :
            (null));
  }

  /**
   * Indicates whether the conversational query triggers a cancellation for slot
   * filling. For more information, see the [cancel slot filling
   * documentation](https://cloud.google.com/dialogflow/es/docs/intents-actions-parameters#cancel).
   */
  set cancelsSlotFilling(value: boolean|null) {
    this.Serializable$set('cancelsSlotFilling', value);
  }

  get diagnosticInfo(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('diagnosticInfo')) ?
            (this.Serializable$get('diagnosticInfo')) :
            (null));
  }

  /**
   * Free-form diagnostic information for the associated detect intent request.
   * The fields of this data can change without notice, so you should not write
   * code that depends on its structure. The data may contain: - webhook call
   * latency - webhook errors
   */
  set diagnosticInfo(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('diagnosticInfo', value);
  }

  get fulfillmentMessages(): Array<IntentMessage_>|null {
    return (
        (this.Serializable$has('fulfillmentMessages')) ?
            (this.Serializable$get('fulfillmentMessages')) :
            (null));
  }

  /**
   * The collection of rich messages to present to the user.
   */
  set fulfillmentMessages(value: Array<IntentMessage_>|null) {
    this.Serializable$set('fulfillmentMessages', value);
  }

  get fulfillmentText(): string|null {
    return (
        (this.Serializable$has('fulfillmentText')) ?
            (this.Serializable$get('fulfillmentText')) :
            (null));
  }

  /**
   * The text to be pronounced to the user or shown on the screen. Note: This is
   * a legacy field, `fulfillment_messages` should be preferred.
   */
  set fulfillmentText(value: string|null) {
    this.Serializable$set('fulfillmentText', value);
  }

  get intent(): Intent_|null {
    return (
        (this.Serializable$has('intent')) ? (this.Serializable$get('intent')) :
                                            (null));
  }

  /**
   * The intent that matched the conversational query. Some, not all fields are
   * filled in this message, including but not limited to: `name`,
   * `display_name`, `end_interaction` and `is_fallback`.
   */
  set intent(value: Intent_|null) {
    this.Serializable$set('intent', value);
  }

  get intentDetectionConfidence(): number|null {
    return (
        (this.Serializable$has('intentDetectionConfidence')) ?
            (this.Serializable$get('intentDetectionConfidence')) :
            (null));
  }

  /**
   * The intent detection confidence. Values range from 0.0 (completely
   * uncertain) to 1.0 (completely certain). This value is for informational
   * purpose only and is only used to help match the best intent within the
   * classification threshold. This value may change for the same end-user
   * expression at any time due to a model retraining or change in
   * implementation. If there are `multiple knowledge_answers` messages, this
   * value is set to the greatest `knowledgeAnswers.match_confidence` value in
   * the list.
   */
  set intentDetectionConfidence(value: number|null) {
    this.Serializable$set('intentDetectionConfidence', value);
  }

  get knowledgeAnswers(): KnowledgeAnswers_|null {
    return (
        (this.Serializable$has('knowledgeAnswers')) ?
            (this.Serializable$get('knowledgeAnswers')) :
            (null));
  }

  /**
   * The result from Knowledge Connector (if any), ordered by decreasing
   * `KnowledgeAnswers.match_confidence`.
   */
  set knowledgeAnswers(value: KnowledgeAnswers_|null) {
    this.Serializable$set('knowledgeAnswers', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language that was triggered during intent detection. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get outputContexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('outputContexts')) ?
            (this.Serializable$get('outputContexts')) :
            (null));
  }

  /**
   * The collection of output contexts. If applicable,
   * `output_contexts.parameters` contains entries with name `.original`
   * containing the original parameter values before the query.
   */
  set outputContexts(value: Array<Context_>|null) {
    this.Serializable$set('outputContexts', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * The collection of extracted parameters. Depending on your protocol or
   * client library language, this is a map, associative array, symbol table,
   * dictionary, or JSON object composed of a collection of (MapKey, MapValue)
   * pairs: - MapKey type: string - MapKey value: parameter name - MapValue
   * type: - If parameter's entity type is a composite entity: map - Else:
   * depending on parameter value type, could be one of string, number, boolean,
   * null, list or map - MapValue value: - If parameter's entity type is a
   * composite entity: map from composite entity property names to property
   * values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  get queryText(): string|null {
    return (
        (this.Serializable$has('queryText')) ?
            (this.Serializable$get('queryText')) :
            (null));
  }

  /**
   * The original conversational query text: - If natural language text was
   * provided as input, `query_text` contains a copy of the input. - If natural
   * language speech audio was provided as input, `query_text` contains the
   * speech recognition result. If speech recognizer produced multiple
   * alternatives, a particular one is picked. - If automatic spell correction
   * is enabled, `query_text` will contain the corrected user input.
   */
  set queryText(value: string|null) {
    this.Serializable$set('queryText', value);
  }

  get recordedAudioUri(): string|null {
    return (
        (this.Serializable$has('recordedAudioUri')) ?
            (this.Serializable$get('recordedAudioUri')) :
            (null));
  }

  /**
   * The Cloud Storage path used for audio storage if audio export is enabled in
   * the SecuritySettings.
   */
  set recordedAudioUri(value: string|null) {
    this.Serializable$set('recordedAudioUri', value);
  }

  get recordedResponseAudioExportGcs(): GcsDestination_|null {
    return (
        (this.Serializable$has('recordedResponseAudioExportGcs')) ?
            (this.Serializable$get('recordedResponseAudioExportGcs')) :
            (null));
  }

  /**
   * The Cloud Storage path used for response audio storage if audio export is
   * enabled in the SecuritySettings.
   */
  set recordedResponseAudioExportGcs(value: GcsDestination_|null) {
    this.Serializable$set('recordedResponseAudioExportGcs', value);
  }

  get sentimentAnalysisResult(): SentimentAnalysisResult_|null {
    return (
        (this.Serializable$has('sentimentAnalysisResult')) ?
            (this.Serializable$get('sentimentAnalysisResult')) :
            (null));
  }

  /**
   * The sentiment analysis result, which depends on the
   * `sentiment_analysis_request_config` specified in the request.
   */
  set sentimentAnalysisResult(value: SentimentAnalysisResult_|null) {
    this.Serializable$set('sentimentAnalysisResult', value);
  }

  get speechRecognitionConfidence(): number|null {
    return (
        (this.Serializable$has('speechRecognitionConfidence')) ?
            (this.Serializable$get('speechRecognitionConfidence')) :
            (null));
  }

  /**
   * The Speech recognition confidence between 0.0 and 1.0. A higher number
   * indicates an estimated greater likelihood that the recognized words are
   * correct. The default of 0.0 is a sentinel value indicating that confidence
   * was not set. This field is not guaranteed to be accurate or set. In
   * particular this field isn't set for StreamingDetectIntent since the
   * streaming endpoint has separate confidence estimates per portion of the
   * audio in StreamingRecognitionResult.
   */
  set speechRecognitionConfidence(value: number|null) {
    this.Serializable$set('speechRecognitionConfidence', value);
  }

  get webhookPayload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('webhookPayload')) ?
            (this.Serializable$get('webhookPayload')) :
            (null));
  }

  /**
   * If the query was fulfilled by a webhook call, this field is set to the
   * value of the `payload` field returned in the webhook response.
   */
  set webhookPayload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('webhookPayload', value);
  }

  get webhookSource(): string|null {
    return (
        (this.Serializable$has('webhookSource')) ?
            (this.Serializable$get('webhookSource')) :
            (null));
  }

  /**
   * If the query was fulfilled by a webhook call, this field is set to the
   * value of the `source` field returned in the webhook response.
   */
  set webhookSource(value: string|null) {
    this.Serializable$set('webhookSource', value);
  }

  getConstructor(): SerializableCtor<QueryResult_> {
    return QueryResult_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'fulfillmentMessages': IntentMessage_, 'outputContexts': Context_},
      keys: [
        'action', 'allRequiredParamsPresent', 'cancelsSlotFilling',
        'diagnosticInfo', 'fulfillmentMessages', 'fulfillmentText', 'intent',
        'intentDetectionConfidence', 'knowledgeAnswers', 'languageCode',
        'outputContexts', 'parameters', 'queryText', 'recordedAudioUri',
        'recordedResponseAudioExportGcs', 'sentimentAnalysisResult',
        'speechRecognitionConfidence', 'webhookPayload', 'webhookSource'
      ],
      objectMaps: {
        'diagnosticInfo': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        },
        'webhookPayload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'intent': Intent_,
        'knowledgeAnswers': KnowledgeAnswers_,
        'recordedResponseAudioExportGcs': GcsDestination_,
        'sentimentAnalysisResult': SentimentAnalysisResult_
      }
    };
  }
}

export interface QuestionAnswerEntry_Parameters {
  name?: string|null;
  question?: string|null;
  answer?: string|null;
  state?: QuestionAnswerEntry_State|null;
}
export class QuestionAnswerEntry_ extends Serializable {
  constructor(parameters: QuestionAnswerEntry_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'question',
        (parameters.question == null) ? (null) : (parameters.question));
    this.Serializable$set(
        'answer', (parameters.answer == null) ? (null) : (parameters.answer));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
  }

  static get State(): IQuestionAnswerEntry_StateEnum {
    return QuestionAnswerEntry_StateEnum;
  }

  get answer(): string|null {
    return (
        (this.Serializable$has('answer')) ? (this.Serializable$get('answer')) :
                                            (null));
  }

  /**
   * Output only. Answer text.
   */
  set answer(value: string|null) {
    this.Serializable$set('answer', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The name of the question-answer-entrie. Format:
   * `projects//locations//knowledgeBases//documents//questionAnswerEntries/
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get question(): string|null {
    return (
        (this.Serializable$has('question')) ?
            (this.Serializable$get('question')) :
            (null));
  }

  /**
   * Output only. The question for the answer.
   */
  set question(value: string|null) {
    this.Serializable$set('question', value);
  }

  get state(): QuestionAnswerEntry_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Required. The state of the question-answer-entry.
   */
  set state(value: QuestionAnswerEntry_State|null) {
    this.Serializable$set('state', value);
  }

  getConstructor(): SerializableCtor<QuestionAnswerEntry_> {
    return QuestionAnswerEntry_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': QuestionAnswerEntry_StateEnum},
      keys: ['answer', 'name', 'question', 'state']
    };
  }
}

export interface ReloadDocumentRequest_Parameters {
  gcsSource?: GcsSource_|null;
  importGcsCustomMetadata?: boolean|null;
  smartMessagingPartialUpdate?: boolean|null;
}
export class ReloadDocumentRequest_ extends Serializable {
  constructor(parameters: ReloadDocumentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'gcsSource',
        (parameters.gcsSource == null) ? (null) : (parameters.gcsSource));
    this.Serializable$set(
        'importGcsCustomMetadata',
        (parameters.importGcsCustomMetadata == null) ?
            (null) :
            (parameters.importGcsCustomMetadata));
    this.Serializable$set(
        'smartMessagingPartialUpdate',
        (parameters.smartMessagingPartialUpdate == null) ?
            (null) :
            (parameters.smartMessagingPartialUpdate));
  }

  get gcsSource(): GcsSource_|null {
    return (
        (this.Serializable$has('gcsSource')) ?
            (this.Serializable$get('gcsSource')) :
            (null));
  }

  /**
   * The path for a Cloud Storage source file for reloading document content. If
   * not provided, the Document's existing source will be reloaded.
   */
  set gcsSource(value: GcsSource_|null) {
    this.Serializable$set('gcsSource', value);
  }

  get importGcsCustomMetadata(): boolean|null {
    return (
        (this.Serializable$has('importGcsCustomMetadata')) ?
            (this.Serializable$get('importGcsCustomMetadata')) :
            (null));
  }

  /**
   * Whether to import custom metadata from Google Cloud Storage. Only valid
   * when the document source is Google Cloud Storage URI.
   */
  set importGcsCustomMetadata(value: boolean|null) {
    this.Serializable$set('importGcsCustomMetadata', value);
  }

  get smartMessagingPartialUpdate(): boolean|null {
    return (
        (this.Serializable$has('smartMessagingPartialUpdate')) ?
            (this.Serializable$get('smartMessagingPartialUpdate')) :
            (null));
  }

  /**
   * When enabled, the reload request is to apply partial update to the smart
   * messaging allowlist.
   */
  set smartMessagingPartialUpdate(value: boolean|null) {
    this.Serializable$set('smartMessagingPartialUpdate', value);
  }

  getConstructor(): SerializableCtor<ReloadDocumentRequest_> {
    return ReloadDocumentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'gcsSource', 'importGcsCustomMetadata', 'smartMessagingPartialUpdate'
      ],
      objects: {'gcsSource': GcsSource_}
    };
  }
}

export interface RemoveIntegrationRequest_Parameters {
  setupNonce?: string|null;
  authCode?: string|null;
}
export class RemoveIntegrationRequest_ extends Serializable {
  constructor(parameters: RemoveIntegrationRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'setupNonce',
        (parameters.setupNonce == null) ? (null) : (parameters.setupNonce));
    this.Serializable$set(
        'authCode',
        (parameters.authCode == null) ? (null) : (parameters.authCode));
  }

  get authCode(): string|null {
    return (
        (this.Serializable$has('authCode')) ?
            (this.Serializable$get('authCode')) :
            (null));
  }

  /**
   * Optional. Auth code from partner's OAuth if the partner implements an
   * OAuth-based integration.
   */
  set authCode(value: string|null) {
    this.Serializable$set('authCode', value);
  }

  get setupNonce(): string|null {
    return (
        (this.Serializable$has('setupNonce')) ?
            (this.Serializable$get('setupNonce')) :
            (null));
  }

  /**
   * Required. The one-time nonce used in signup / revoke flow
   */
  set setupNonce(value: string|null) {
    this.Serializable$set('setupNonce', value);
  }

  getConstructor(): SerializableCtor<RemoveIntegrationRequest_> {
    return RemoveIntegrationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['authCode', 'setupNonce']};
  }
}

export interface RemoveSpecialistsFromPoolOperationMetadata_Parameters {
  specialistPool?: string|null;
  partialFailures?: Array<GoogleRpcStatus>|null;
  createTime?: string|null;
}
export class RemoveSpecialistsFromPoolOperationMetadata_ extends Serializable {
  constructor(
      parameters: RemoveSpecialistsFromPoolOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'specialistPool',
        (parameters.specialistPool == null) ? (null) :
                                              (parameters.specialistPool));
    this.Serializable$set(
        'partialFailures',
        (parameters.partialFailures == null) ? (null) :
                                               (parameters.partialFailures));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Timestamp when operation was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get partialFailures(): Array<GoogleRpcStatus>|null {
    return (
        (this.Serializable$has('partialFailures')) ?
            (this.Serializable$get('partialFailures')) :
            (null));
  }

  /**
   * Output only. Partial failures encountered. E.g. some specialists fail to be
   * removed. Status details field will contain standard GCP error details.
   */
  set partialFailures(value: Array<GoogleRpcStatus>|null) {
    this.Serializable$set('partialFailures', value);
  }

  get specialistPool(): string|null {
    return (
        (this.Serializable$has('specialistPool')) ?
            (this.Serializable$get('specialistPool')) :
            (null));
  }

  /**
   * Output only. SpecialistPool resource name. Format:
   * `projects//locations//specialistPools/`
   */
  set specialistPool(value: string|null) {
    this.Serializable$set('specialistPool', value);
  }

  getConstructor():
      SerializableCtor<RemoveSpecialistsFromPoolOperationMetadata_> {
    return RemoveSpecialistsFromPoolOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'partialFailures': GoogleRpcStatus},
      keys: ['createTime', 'partialFailures', 'specialistPool']
    };
  }
}

export interface RemoveSpecialistsFromPoolOperationResponse_Parameters {
  totalCount?: number|null;
  removedCount?: number|null;
}
export class RemoveSpecialistsFromPoolOperationResponse_ extends Serializable {
  constructor(
      parameters: RemoveSpecialistsFromPoolOperationResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'totalCount',
        (parameters.totalCount == null) ? (null) : (parameters.totalCount));
    this.Serializable$set(
        'removedCount',
        (parameters.removedCount == null) ? (null) : (parameters.removedCount));
  }

  get removedCount(): number|null {
    return (
        (this.Serializable$has('removedCount')) ?
            (this.Serializable$get('removedCount')) :
            (null));
  }

  /**
   * Output only. Number of specialists removed successfully.
   */
  set removedCount(value: number|null) {
    this.Serializable$set('removedCount', value);
  }

  get totalCount(): number|null {
    return (
        (this.Serializable$has('totalCount')) ?
            (this.Serializable$get('totalCount')) :
            (null));
  }

  /**
   * Output only. Total number of specialists to remove.
   */
  set totalCount(value: number|null) {
    this.Serializable$set('totalCount', value);
  }

  getConstructor():
      SerializableCtor<RemoveSpecialistsFromPoolOperationResponse_> {
    return RemoveSpecialistsFromPoolOperationResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['removedCount', 'totalCount']};
  }
}

export interface RemoveSpecialistsFromPoolRequest_Parameters {
  specialists?: Array<Specialist_>|null;
}
export class RemoveSpecialistsFromPoolRequest_ extends Serializable {
  constructor(parameters: RemoveSpecialistsFromPoolRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'specialists',
        (parameters.specialists == null) ? (null) : (parameters.specialists));
  }

  get specialists(): Array<Specialist_>|null {
    return (
        (this.Serializable$has('specialists')) ?
            (this.Serializable$get('specialists')) :
            (null));
  }

  /**
   * The specialists need to be removed. Please specify email_address for the
   * specialist; To remove specialists with specific role (WORKER/MANAGER),
   * please specify the role field. If the role is unspecified, we delete all
   * roles for given specialist.
   */
  set specialists(value: Array<Specialist_>|null) {
    this.Serializable$set('specialists', value);
  }

  getConstructor(): SerializableCtor<RemoveSpecialistsFromPoolRequest_> {
    return RemoveSpecialistsFromPoolRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'specialists': Specialist_}, keys: ['specialists']};
  }
}

export interface ResearchScamCoscamConjunctionParameters {
  disjunctionId?: Array<string>|null;
  isPositive?: Array<boolean>|null;
}
export class ResearchScamCoscamConjunction extends Serializable {
  constructor(parameters: ResearchScamCoscamConjunctionParameters = {}) {
    super();
    this.Serializable$set(
        'disjunctionId',
        (parameters.disjunctionId == null) ? (null) :
                                             (parameters.disjunctionId));
    this.Serializable$set(
        'isPositive',
        (parameters.isPositive == null) ? (null) : (parameters.isPositive));
  }

  get disjunctionId(): Array<string>|null {
    return (
        (this.Serializable$has('disjunctionId')) ?
            (this.Serializable$get('disjunctionId')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set disjunctionId(value: Array<string>|null) {
    this.Serializable$set('disjunctionId', value);
  }

  get isPositive(): Array<boolean>|null {
    return (
        (this.Serializable$has('isPositive')) ?
            (this.Serializable$get('isPositive')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set isPositive(value: Array<boolean>|null) {
    this.Serializable$set('isPositive', value);
  }

  getConstructor(): SerializableCtor<ResearchScamCoscamConjunction> {
    return ResearchScamCoscamConjunction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['disjunctionId', 'isPositive']};
  }
}

export interface ResearchScamCoscamDisjunctionParameters {
  key?: string|null;
  tokenGroups?: Array<ResearchScamCoscamTokenGroup>|null;
}
export class ResearchScamCoscamDisjunction extends Serializable {
  constructor(parameters: ResearchScamCoscamDisjunctionParameters = {}) {
    super();
    this.Serializable$set(
        'key', (parameters.key == null) ? (null) : (parameters.key));
    this.Serializable$set(
        'tokenGroups',
        (parameters.tokenGroups == null) ? (null) : (parameters.tokenGroups));
  }

  get key(): string|null {
    return (
        (this.Serializable$has('key')) ? (this.Serializable$get('key')) :
                                         (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set key(value: string|null) {
    this.Serializable$set('key', value);
  }

  get tokenGroups(): Array<ResearchScamCoscamTokenGroup>|null {
    return (
        (this.Serializable$has('tokenGroups')) ?
            (this.Serializable$get('tokenGroups')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set tokenGroups(value: Array<ResearchScamCoscamTokenGroup>|null) {
    this.Serializable$set('tokenGroups', value);
  }

  getConstructor(): SerializableCtor<ResearchScamCoscamDisjunction> {
    return ResearchScamCoscamDisjunction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'tokenGroups': ResearchScamCoscamTokenGroup},
      keys: ['key', 'tokenGroups']
    };
  }
}

export interface ResearchScamCoscamEasyConjunctionParameters {
  disjunctions?: Array<ResearchScamCoscamEasyDisjunction>|null;
}
export class ResearchScamCoscamEasyConjunction extends Serializable {
  constructor(parameters: ResearchScamCoscamEasyConjunctionParameters = {}) {
    super();
    this.Serializable$set(
        'disjunctions',
        (parameters.disjunctions == null) ? (null) : (parameters.disjunctions));
  }

  get disjunctions(): Array<ResearchScamCoscamEasyDisjunction>|null {
    return (
        (this.Serializable$has('disjunctions')) ?
            (this.Serializable$get('disjunctions')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set disjunctions(value: Array<ResearchScamCoscamEasyDisjunction>|null) {
    this.Serializable$set('disjunctions', value);
  }

  getConstructor(): SerializableCtor<ResearchScamCoscamEasyConjunction> {
    return ResearchScamCoscamEasyConjunction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'disjunctions': ResearchScamCoscamEasyDisjunction},
      keys: ['disjunctions']
    };
  }
}

export interface ResearchScamCoscamEasyDisjunctionParameters {
  isPositive?: boolean|null;
  tokenGroups?: Array<ResearchScamCoscamTokenGroup>|null;
}
export class ResearchScamCoscamEasyDisjunction extends Serializable {
  constructor(parameters: ResearchScamCoscamEasyDisjunctionParameters = {}) {
    super();
    this.Serializable$set(
        'isPositive',
        (parameters.isPositive == null) ? (null) : (parameters.isPositive));
    this.Serializable$set(
        'tokenGroups',
        (parameters.tokenGroups == null) ? (null) : (parameters.tokenGroups));
  }

  get isPositive(): boolean|null {
    return (
        (this.Serializable$has('isPositive')) ?
            (this.Serializable$get('isPositive')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set isPositive(value: boolean|null) {
    this.Serializable$set('isPositive', value);
  }

  get tokenGroups(): Array<ResearchScamCoscamTokenGroup>|null {
    return (
        (this.Serializable$has('tokenGroups')) ?
            (this.Serializable$get('tokenGroups')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set tokenGroups(value: Array<ResearchScamCoscamTokenGroup>|null) {
    this.Serializable$set('tokenGroups', value);
  }

  getConstructor(): SerializableCtor<ResearchScamCoscamEasyDisjunction> {
    return ResearchScamCoscamEasyDisjunction;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'tokenGroups': ResearchScamCoscamTokenGroup},
      keys: ['isPositive', 'tokenGroups']
    };
  }
}

export interface ResearchScamCoscamEasyRestrictDefinitionParameters {
  conjunctions?: Array<ResearchScamCoscamEasyConjunction>|null;
}
export class ResearchScamCoscamEasyRestrictDefinition extends Serializable {
  constructor(
      parameters: ResearchScamCoscamEasyRestrictDefinitionParameters = {}) {
    super();
    this.Serializable$set(
        'conjunctions',
        (parameters.conjunctions == null) ? (null) : (parameters.conjunctions));
  }

  get conjunctions(): Array<ResearchScamCoscamEasyConjunction>|null {
    return (
        (this.Serializable$has('conjunctions')) ?
            (this.Serializable$get('conjunctions')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set conjunctions(value: Array<ResearchScamCoscamEasyConjunction>|null) {
    this.Serializable$set('conjunctions', value);
  }

  getConstructor(): SerializableCtor<ResearchScamCoscamEasyRestrictDefinition> {
    return ResearchScamCoscamEasyRestrictDefinition;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'conjunctions': ResearchScamCoscamEasyConjunction},
      keys: ['conjunctions']
    };
  }
}

export interface ResearchScamCoscamRestrictDefinitionParameters {
  subsKey?: string|null;
  disjunctions?: Array<ResearchScamCoscamDisjunction>|null;
  conjunctions?: Array<ResearchScamCoscamConjunction>|null;
}
export class ResearchScamCoscamRestrictDefinition extends Serializable {
  constructor(parameters: ResearchScamCoscamRestrictDefinitionParameters = {}) {
    super();
    this.Serializable$set(
        'subsKey',
        (parameters.subsKey == null) ? (null) : (parameters.subsKey));
    this.Serializable$set(
        'disjunctions',
        (parameters.disjunctions == null) ? (null) : (parameters.disjunctions));
    this.Serializable$set(
        'conjunctions',
        (parameters.conjunctions == null) ? (null) : (parameters.conjunctions));
  }

  get conjunctions(): Array<ResearchScamCoscamConjunction>|null {
    return (
        (this.Serializable$has('conjunctions')) ?
            (this.Serializable$get('conjunctions')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set conjunctions(value: Array<ResearchScamCoscamConjunction>|null) {
    this.Serializable$set('conjunctions', value);
  }

  get disjunctions(): Array<ResearchScamCoscamDisjunction>|null {
    return (
        (this.Serializable$has('disjunctions')) ?
            (this.Serializable$get('disjunctions')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set disjunctions(value: Array<ResearchScamCoscamDisjunction>|null) {
    this.Serializable$set('disjunctions', value);
  }

  get subsKey(): string|null {
    return (
        (this.Serializable$has('subsKey')) ?
            (this.Serializable$get('subsKey')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set subsKey(value: string|null) {
    this.Serializable$set('subsKey', value);
  }

  getConstructor(): SerializableCtor<ResearchScamCoscamRestrictDefinition> {
    return ResearchScamCoscamRestrictDefinition;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'conjunctions': ResearchScamCoscamConjunction,
        'disjunctions': ResearchScamCoscamDisjunction
      },
      keys: ['conjunctions', 'disjunctions', 'subsKey']
    };
  }
}

export interface ResearchScamCoscamRestrictTokensV2Parameters {
  tokenGroups?: Array<ResearchScamCoscamTokenGroup>|null;
}
export class ResearchScamCoscamRestrictTokensV2 extends Serializable {
  constructor(parameters: ResearchScamCoscamRestrictTokensV2Parameters = {}) {
    super();
    this.Serializable$set(
        'tokenGroups',
        (parameters.tokenGroups == null) ? (null) : (parameters.tokenGroups));
  }

  get tokenGroups(): Array<ResearchScamCoscamTokenGroup>|null {
    return (
        (this.Serializable$has('tokenGroups')) ?
            (this.Serializable$get('tokenGroups')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set tokenGroups(value: Array<ResearchScamCoscamTokenGroup>|null) {
    this.Serializable$set('tokenGroups', value);
  }

  getConstructor(): SerializableCtor<ResearchScamCoscamRestrictTokensV2> {
    return ResearchScamCoscamRestrictTokensV2;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'tokenGroups': ResearchScamCoscamTokenGroup},
      keys: ['tokenGroups']
    };
  }
}

export interface ResearchScamCoscamTokenGroupParameters {
  name?: string|null;
  tokens?: Array<string>|null;
  debugTokenStrings?: Array<string>|null;
}
export class ResearchScamCoscamTokenGroup extends Serializable {
  constructor(parameters: ResearchScamCoscamTokenGroupParameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'tokens', (parameters.tokens == null) ? (null) : (parameters.tokens));
    this.Serializable$set(
        'debugTokenStrings',
        (parameters.debugTokenStrings == null) ?
            (null) :
            (parameters.debugTokenStrings));
  }

  get debugTokenStrings(): Array<string>|null {
    return (
        (this.Serializable$has('debugTokenStrings')) ?
            (this.Serializable$get('debugTokenStrings')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set debugTokenStrings(value: Array<string>|null) {
    this.Serializable$set('debugTokenStrings', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get tokens(): Array<string>|null {
    return (
        (this.Serializable$has('tokens')) ? (this.Serializable$get('tokens')) :
                                            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set tokens(value: Array<string>|null) {
    this.Serializable$set('tokens', value);
  }

  getConstructor(): SerializableCtor<ResearchScamCoscamTokenGroup> {
    return ResearchScamCoscamTokenGroup;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['debugTokenStrings', 'name', 'tokens']};
  }
}

export interface ResearchScamGenericFeatureVectorParameters {
  featureType?: ResearchScamGenericFeatureVectorFeatureType|null;
  dataIdStr?: string|null;
  featureValueFloat?: Array<number>|null;
  featureValueDouble?: Array<number>|null;
  featureValueInt64?: Array<string>|null;
  featureValueString?: string|null;
  featureIndex?: Array<string>|null;
  normType?: ResearchScamGenericFeatureVectorNormType|null;
  classLabel?: string|null;
  crowding?: ResearchScamGenericFeatureVectorCrowding|null;
  restrictTokens?: ResearchScamGenericFeatureVectorRestrictTokens|null;
  fixedPointMetadata?: ResearchScamGenericFeatureVectorFixedPointMetadata|null;
  userinfo?: string|null;
  expirationTimestamp?: string|null;
  queryMetadata?: ResearchScamQueryMetadata|null;
  featureDim?: string|null;
  weight?: number|null;
  tokens?: Array<number>|null;
}
export class ResearchScamGenericFeatureVector extends Serializable {
  constructor(parameters: ResearchScamGenericFeatureVectorParameters = {}) {
    super();
    this.Serializable$set(
        'featureType',
        (parameters.featureType == null) ? (null) : (parameters.featureType));
    this.Serializable$set(
        'dataIdStr',
        (parameters.dataIdStr == null) ? (null) : (parameters.dataIdStr));
    this.Serializable$set(
        'featureValueFloat',
        (parameters.featureValueFloat == null) ?
            (null) :
            (parameters.featureValueFloat));
    this.Serializable$set(
        'featureValueDouble',
        (parameters.featureValueDouble == null) ?
            (null) :
            (parameters.featureValueDouble));
    this.Serializable$set(
        'featureValueInt64',
        (parameters.featureValueInt64 == null) ?
            (null) :
            (parameters.featureValueInt64));
    this.Serializable$set(
        'featureValueString',
        (parameters.featureValueString == null) ?
            (null) :
            (parameters.featureValueString));
    this.Serializable$set(
        'featureIndex',
        (parameters.featureIndex == null) ? (null) : (parameters.featureIndex));
    this.Serializable$set(
        'normType',
        (parameters.normType == null) ? (null) : (parameters.normType));
    this.Serializable$set(
        'classLabel',
        (parameters.classLabel == null) ? (null) : (parameters.classLabel));
    this.Serializable$set(
        'crowding',
        (parameters.crowding == null) ? (null) : (parameters.crowding));
    this.Serializable$set(
        'restrictTokens',
        (parameters.restrictTokens == null) ? (null) :
                                              (parameters.restrictTokens));
    this.Serializable$set(
        'fixedPointMetadata',
        (parameters.fixedPointMetadata == null) ?
            (null) :
            (parameters.fixedPointMetadata));
    this.Serializable$set(
        'userinfo',
        (parameters.userinfo == null) ? (null) : (parameters.userinfo));
    this.Serializable$set(
        'expirationTimestamp',
        (parameters.expirationTimestamp == null) ?
            (null) :
            (parameters.expirationTimestamp));
    this.Serializable$set(
        'queryMetadata',
        (parameters.queryMetadata == null) ? (null) :
                                             (parameters.queryMetadata));
    this.Serializable$set(
        'featureDim',
        (parameters.featureDim == null) ? (null) : (parameters.featureDim));
    this.Serializable$set(
        'weight', (parameters.weight == null) ? (null) : (parameters.weight));
    this.Serializable$set(
        'tokens', (parameters.tokens == null) ? (null) : (parameters.tokens));
  }

  static get FeatureType(): IResearchScamGenericFeatureVectorFeatureTypeEnum {
    return ResearchScamGenericFeatureVectorFeatureTypeEnum;
  }

  static get NormType(): IResearchScamGenericFeatureVectorNormTypeEnum {
    return ResearchScamGenericFeatureVectorNormTypeEnum;
  }

  get classLabel(): string|null {
    return (
        (this.Serializable$has('classLabel')) ?
            (this.Serializable$get('classLabel')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set classLabel(value: string|null) {
    this.Serializable$set('classLabel', value);
  }

  get crowding(): ResearchScamGenericFeatureVectorCrowding|null {
    return (
        (this.Serializable$has('crowding')) ?
            (this.Serializable$get('crowding')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set crowding(value: ResearchScamGenericFeatureVectorCrowding|null) {
    this.Serializable$set('crowding', value);
  }

  get dataIdStr(): string|null {
    return (
        (this.Serializable$has('dataIdStr')) ?
            (this.Serializable$get('dataIdStr')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set dataIdStr(value: string|null) {
    this.Serializable$set('dataIdStr', value);
  }

  get expirationTimestamp(): string|null {
    return (
        (this.Serializable$has('expirationTimestamp')) ?
            (this.Serializable$get('expirationTimestamp')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set expirationTimestamp(value: string|null) {
    this.Serializable$set('expirationTimestamp', value);
  }

  get featureDim(): string|null {
    return (
        (this.Serializable$has('featureDim')) ?
            (this.Serializable$get('featureDim')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set featureDim(value: string|null) {
    this.Serializable$set('featureDim', value);
  }

  get featureIndex(): Array<string>|null {
    return (
        (this.Serializable$has('featureIndex')) ?
            (this.Serializable$get('featureIndex')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set featureIndex(value: Array<string>|null) {
    this.Serializable$set('featureIndex', value);
  }

  get featureType(): ResearchScamGenericFeatureVectorFeatureType|null {
    return (
        (this.Serializable$has('featureType')) ?
            (this.Serializable$get('featureType')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set featureType(value: ResearchScamGenericFeatureVectorFeatureType|null) {
    this.Serializable$set('featureType', value);
  }

  get featureValueDouble(): Array<number>|null {
    return (
        (this.Serializable$has('featureValueDouble')) ?
            (this.Serializable$get('featureValueDouble')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set featureValueDouble(value: Array<number>|null) {
    this.Serializable$set('featureValueDouble', value);
  }

  get featureValueFloat(): Array<number>|null {
    return (
        (this.Serializable$has('featureValueFloat')) ?
            (this.Serializable$get('featureValueFloat')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set featureValueFloat(value: Array<number>|null) {
    this.Serializable$set('featureValueFloat', value);
  }

  get featureValueInt64(): Array<string>|null {
    return (
        (this.Serializable$has('featureValueInt64')) ?
            (this.Serializable$get('featureValueInt64')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set featureValueInt64(value: Array<string>|null) {
    this.Serializable$set('featureValueInt64', value);
  }

  get featureValueString(): string|null {
    return (
        (this.Serializable$has('featureValueString')) ?
            (this.Serializable$get('featureValueString')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set featureValueString(value: string|null) {
    this.Serializable$set('featureValueString', value);
  }

  get fixedPointMetadata(): ResearchScamGenericFeatureVectorFixedPointMetadata
      |null {
    return (
        (this.Serializable$has('fixedPointMetadata')) ?
            (this.Serializable$get('fixedPointMetadata')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set fixedPointMetadata(value:
                             ResearchScamGenericFeatureVectorFixedPointMetadata|
                         null) {
    this.Serializable$set('fixedPointMetadata', value);
  }

  get normType(): ResearchScamGenericFeatureVectorNormType|null {
    return (
        (this.Serializable$has('normType')) ?
            (this.Serializable$get('normType')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set normType(value: ResearchScamGenericFeatureVectorNormType|null) {
    this.Serializable$set('normType', value);
  }

  get queryMetadata(): ResearchScamQueryMetadata|null {
    return (
        (this.Serializable$has('queryMetadata')) ?
            (this.Serializable$get('queryMetadata')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set queryMetadata(value: ResearchScamQueryMetadata|null) {
    this.Serializable$set('queryMetadata', value);
  }

  get restrictTokens(): ResearchScamGenericFeatureVectorRestrictTokens|null {
    return (
        (this.Serializable$has('restrictTokens')) ?
            (this.Serializable$get('restrictTokens')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set restrictTokens(value: ResearchScamGenericFeatureVectorRestrictTokens|
                     null) {
    this.Serializable$set('restrictTokens', value);
  }

  get tokens(): Array<number>|null {
    return (
        (this.Serializable$has('tokens')) ? (this.Serializable$get('tokens')) :
                                            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set tokens(value: Array<number>|null) {
    this.Serializable$set('tokens', value);
  }

  get userinfo(): string|null {
    return (
        (this.Serializable$has('userinfo')) ?
            (this.Serializable$get('userinfo')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set userinfo(value: string|null) {
    this.Serializable$set('userinfo', value);
  }

  get weight(): number|null {
    return (
        (this.Serializable$has('weight')) ? (this.Serializable$get('weight')) :
                                            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set weight(value: number|null) {
    this.Serializable$set('weight', value);
  }

  getConstructor(): SerializableCtor<ResearchScamGenericFeatureVector> {
    return ResearchScamGenericFeatureVector;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'featureType': ResearchScamGenericFeatureVectorFeatureTypeEnum,
        'normType': ResearchScamGenericFeatureVectorNormTypeEnum
      },
      keys: [
        'classLabel', 'crowding', 'dataIdStr', 'expirationTimestamp',
        'featureDim', 'featureIndex', 'featureType', 'featureValueDouble',
        'featureValueFloat', 'featureValueInt64', 'featureValueString',
        'fixedPointMetadata', 'normType', 'queryMetadata', 'restrictTokens',
        'tokens', 'userinfo', 'weight'
      ],
      objects: {
        'crowding': ResearchScamGenericFeatureVectorCrowding,
        'fixedPointMetadata':
            ResearchScamGenericFeatureVectorFixedPointMetadata,
        'queryMetadata': ResearchScamQueryMetadata,
        'restrictTokens': ResearchScamGenericFeatureVectorRestrictTokens
      }
    };
  }
}

export interface ResearchScamGenericFeatureVectorCrowdingParameters {
  crowdingAttribute?: string|null;
}
export class ResearchScamGenericFeatureVectorCrowding extends Serializable {
  constructor(
      parameters: ResearchScamGenericFeatureVectorCrowdingParameters = {}) {
    super();
    this.Serializable$set(
        'crowdingAttribute',
        (parameters.crowdingAttribute == null) ?
            (null) :
            (parameters.crowdingAttribute));
  }

  get crowdingAttribute(): string|null {
    return (
        (this.Serializable$has('crowdingAttribute')) ?
            (this.Serializable$get('crowdingAttribute')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set crowdingAttribute(value: string|null) {
    this.Serializable$set('crowdingAttribute', value);
  }

  getConstructor(): SerializableCtor<ResearchScamGenericFeatureVectorCrowding> {
    return ResearchScamGenericFeatureVectorCrowding;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['crowdingAttribute']};
  }
}

export interface ResearchScamGenericFeatureVectorFixedPointMetadataParameters {
  squaredL2Norm?: number|null;
}
export class ResearchScamGenericFeatureVectorFixedPointMetadata extends
    Serializable {
  constructor(
      parameters:
          ResearchScamGenericFeatureVectorFixedPointMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'squaredL2Norm',
        (parameters.squaredL2Norm == null) ? (null) :
                                             (parameters.squaredL2Norm));
  }

  get squaredL2Norm(): number|null {
    return (
        (this.Serializable$has('squaredL2Norm')) ?
            (this.Serializable$get('squaredL2Norm')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set squaredL2Norm(value: number|null) {
    this.Serializable$set('squaredL2Norm', value);
  }

  getConstructor():
      SerializableCtor<ResearchScamGenericFeatureVectorFixedPointMetadata> {
    return ResearchScamGenericFeatureVectorFixedPointMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['squaredL2Norm']};
  }
}

export interface ResearchScamGenericFeatureVectorRestrictTokensParameters {
  v3?: ResearchScamV3Restrict|null;
  tokenMembership?: Array<string>|null;
  whitelistToken?: Array<string>|null;
  blacklistToken?: Array<string>|null;
  easyDefinition?: ResearchScamCoscamEasyRestrictDefinition|null;
  tokens?: ResearchScamCoscamRestrictTokensV2|null;
  definition?: ResearchScamCoscamRestrictDefinition|null;
  v3CompatibleNamespace?: string|null;
}
export class ResearchScamGenericFeatureVectorRestrictTokens extends
    Serializable {
  constructor(
      parameters:
          ResearchScamGenericFeatureVectorRestrictTokensParameters = {}) {
    super();
    this.Serializable$set(
        'v3', (parameters.v3 == null) ? (null) : (parameters.v3));
    this.Serializable$set(
        'tokenMembership',
        (parameters.tokenMembership == null) ? (null) :
                                               (parameters.tokenMembership));
    this.Serializable$set(
        'whitelistToken',
        (parameters.whitelistToken == null) ? (null) :
                                              (parameters.whitelistToken));
    this.Serializable$set(
        'blacklistToken',
        (parameters.blacklistToken == null) ? (null) :
                                              (parameters.blacklistToken));
    this.Serializable$set(
        'easyDefinition',
        (parameters.easyDefinition == null) ? (null) :
                                              (parameters.easyDefinition));
    this.Serializable$set(
        'tokens', (parameters.tokens == null) ? (null) : (parameters.tokens));
    this.Serializable$set(
        'definition',
        (parameters.definition == null) ? (null) : (parameters.definition));
    this.Serializable$set(
        'v3CompatibleNamespace',
        (parameters.v3CompatibleNamespace == null) ?
            (null) :
            (parameters.v3CompatibleNamespace));
  }

  get blacklistToken(): Array<string>|null {
    return (
        (this.Serializable$has('blacklistToken')) ?
            (this.Serializable$get('blacklistToken')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set blacklistToken(value: Array<string>|null) {
    this.Serializable$set('blacklistToken', value);
  }

  get definition(): ResearchScamCoscamRestrictDefinition|null {
    return (
        (this.Serializable$has('definition')) ?
            (this.Serializable$get('definition')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set definition(value: ResearchScamCoscamRestrictDefinition|null) {
    this.Serializable$set('definition', value);
  }

  get easyDefinition(): ResearchScamCoscamEasyRestrictDefinition|null {
    return (
        (this.Serializable$has('easyDefinition')) ?
            (this.Serializable$get('easyDefinition')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set easyDefinition(value: ResearchScamCoscamEasyRestrictDefinition|null) {
    this.Serializable$set('easyDefinition', value);
  }

  get tokenMembership(): Array<string>|null {
    return (
        (this.Serializable$has('tokenMembership')) ?
            (this.Serializable$get('tokenMembership')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set tokenMembership(value: Array<string>|null) {
    this.Serializable$set('tokenMembership', value);
  }

  get tokens(): ResearchScamCoscamRestrictTokensV2|null {
    return (
        (this.Serializable$has('tokens')) ? (this.Serializable$get('tokens')) :
                                            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set tokens(value: ResearchScamCoscamRestrictTokensV2|null) {
    this.Serializable$set('tokens', value);
  }

  get v3(): ResearchScamV3Restrict|null {
    return (
        (this.Serializable$has('v3')) ? (this.Serializable$get('v3')) : (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set v3(value: ResearchScamV3Restrict|null) {
    this.Serializable$set('v3', value);
  }

  get v3CompatibleNamespace(): string|null {
    return (
        (this.Serializable$has('v3CompatibleNamespace')) ?
            (this.Serializable$get('v3CompatibleNamespace')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set v3CompatibleNamespace(value: string|null) {
    this.Serializable$set('v3CompatibleNamespace', value);
  }

  get whitelistToken(): Array<string>|null {
    return (
        (this.Serializable$has('whitelistToken')) ?
            (this.Serializable$get('whitelistToken')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set whitelistToken(value: Array<string>|null) {
    this.Serializable$set('whitelistToken', value);
  }

  getConstructor():
      SerializableCtor<ResearchScamGenericFeatureVectorRestrictTokens> {
    return ResearchScamGenericFeatureVectorRestrictTokens;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'blacklistToken', 'definition', 'easyDefinition', 'tokenMembership',
        'tokens', 'v3', 'v3CompatibleNamespace', 'whitelistToken'
      ],
      objects: {
        'definition': ResearchScamCoscamRestrictDefinition,
        'easyDefinition': ResearchScamCoscamEasyRestrictDefinition,
        'tokens': ResearchScamCoscamRestrictTokensV2,
        'v3': ResearchScamV3Restrict
      }
    };
  }
}

export interface ResearchScamNeighborSelectionOverrideParameters {
  numNeighbors?: number|null;
  epsilonDistance?: number|null;
  approxNumNeighbors?: number|null;
  approxEpsilonDistance?: number|null;
  numSingleShardNeighbors?: number|null;
  perCrowdingAttributeNumNeighbors?: number|null;
  perCrowdingAttributePreReorderingNumNeighbors?: number|null;
  treeXHybridLeavesSearchedOverride?: number|null;
}
export class ResearchScamNeighborSelectionOverride extends Serializable {
  constructor(
      parameters: ResearchScamNeighborSelectionOverrideParameters = {}) {
    super();
    this.Serializable$set(
        'numNeighbors',
        (parameters.numNeighbors == null) ? (null) : (parameters.numNeighbors));
    this.Serializable$set(
        'epsilonDistance',
        (parameters.epsilonDistance == null) ? (null) :
                                               (parameters.epsilonDistance));
    this.Serializable$set(
        'approxNumNeighbors',
        (parameters.approxNumNeighbors == null) ?
            (null) :
            (parameters.approxNumNeighbors));
    this.Serializable$set(
        'approxEpsilonDistance',
        (parameters.approxEpsilonDistance == null) ?
            (null) :
            (parameters.approxEpsilonDistance));
    this.Serializable$set(
        'numSingleShardNeighbors',
        (parameters.numSingleShardNeighbors == null) ?
            (null) :
            (parameters.numSingleShardNeighbors));
    this.Serializable$set(
        'perCrowdingAttributeNumNeighbors',
        (parameters.perCrowdingAttributeNumNeighbors == null) ?
            (null) :
            (parameters.perCrowdingAttributeNumNeighbors));
    this.Serializable$set(
        'perCrowdingAttributePreReorderingNumNeighbors',
        (parameters.perCrowdingAttributePreReorderingNumNeighbors == null) ?
            (null) :
            (parameters.perCrowdingAttributePreReorderingNumNeighbors));
    this.Serializable$set(
        'treeXHybridLeavesSearchedOverride',
        (parameters.treeXHybridLeavesSearchedOverride == null) ?
            (null) :
            (parameters.treeXHybridLeavesSearchedOverride));
  }

  get approxEpsilonDistance(): number|null {
    return (
        (this.Serializable$has('approxEpsilonDistance')) ?
            (this.Serializable$get('approxEpsilonDistance')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set approxEpsilonDistance(value: number|null) {
    this.Serializable$set('approxEpsilonDistance', value);
  }

  get approxNumNeighbors(): number|null {
    return (
        (this.Serializable$has('approxNumNeighbors')) ?
            (this.Serializable$get('approxNumNeighbors')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set approxNumNeighbors(value: number|null) {
    this.Serializable$set('approxNumNeighbors', value);
  }

  get epsilonDistance(): number|null {
    return (
        (this.Serializable$has('epsilonDistance')) ?
            (this.Serializable$get('epsilonDistance')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set epsilonDistance(value: number|null) {
    this.Serializable$set('epsilonDistance', value);
  }

  get numNeighbors(): number|null {
    return (
        (this.Serializable$has('numNeighbors')) ?
            (this.Serializable$get('numNeighbors')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set numNeighbors(value: number|null) {
    this.Serializable$set('numNeighbors', value);
  }

  get numSingleShardNeighbors(): number|null {
    return (
        (this.Serializable$has('numSingleShardNeighbors')) ?
            (this.Serializable$get('numSingleShardNeighbors')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set numSingleShardNeighbors(value: number|null) {
    this.Serializable$set('numSingleShardNeighbors', value);
  }

  get perCrowdingAttributeNumNeighbors(): number|null {
    return (
        (this.Serializable$has('perCrowdingAttributeNumNeighbors')) ?
            (this.Serializable$get('perCrowdingAttributeNumNeighbors')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set perCrowdingAttributeNumNeighbors(value: number|null) {
    this.Serializable$set('perCrowdingAttributeNumNeighbors', value);
  }

  get perCrowdingAttributePreReorderingNumNeighbors(): number|null {
    return (
        (this.Serializable$has(
            'perCrowdingAttributePreReorderingNumNeighbors')) ?
            (this.Serializable$get(
                'perCrowdingAttributePreReorderingNumNeighbors')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set perCrowdingAttributePreReorderingNumNeighbors(value: number|null) {
    this.Serializable$set(
        'perCrowdingAttributePreReorderingNumNeighbors', value);
  }

  get treeXHybridLeavesSearchedOverride(): number|null {
    return (
        (this.Serializable$has('treeXHybridLeavesSearchedOverride')) ?
            (this.Serializable$get('treeXHybridLeavesSearchedOverride')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set treeXHybridLeavesSearchedOverride(value: number|null) {
    this.Serializable$set('treeXHybridLeavesSearchedOverride', value);
  }

  getConstructor(): SerializableCtor<ResearchScamNeighborSelectionOverride> {
    return ResearchScamNeighborSelectionOverride;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'approxEpsilonDistance', 'approxNumNeighbors', 'epsilonDistance',
        'numNeighbors', 'numSingleShardNeighbors',
        'perCrowdingAttributeNumNeighbors',
        'perCrowdingAttributePreReorderingNumNeighbors',
        'treeXHybridLeavesSearchedOverride'
      ]
    };
  }
}

export interface ResearchScamQueryMetadataParameters {
  neighborSelectionOverride?: ResearchScamNeighborSelectionOverride|null;
}
export class ResearchScamQueryMetadata extends Serializable {
  constructor(parameters: ResearchScamQueryMetadataParameters = {}) {
    super();
    this.Serializable$set(
        'neighborSelectionOverride',
        (parameters.neighborSelectionOverride == null) ?
            (null) :
            (parameters.neighborSelectionOverride));
  }

  get neighborSelectionOverride(): ResearchScamNeighborSelectionOverride|null {
    return (
        (this.Serializable$has('neighborSelectionOverride')) ?
            (this.Serializable$get('neighborSelectionOverride')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set neighborSelectionOverride(value: ResearchScamNeighborSelectionOverride|
                                null) {
    this.Serializable$set('neighborSelectionOverride', value);
  }

  getConstructor(): SerializableCtor<ResearchScamQueryMetadata> {
    return ResearchScamQueryMetadata;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['neighborSelectionOverride'],
      objects:
          {'neighborSelectionOverride': ResearchScamNeighborSelectionOverride}
    };
  }
}

export interface ResearchScamTokenNamespaceParameters {
  namespace?: string|null;
  stringTokens?: Array<string>|null;
  uint64Tokens?: Array<string>|null;
  stringBlacklistTokens?: Array<string>|null;
  uint64BlacklistTokens?: Array<string>|null;
}
export class ResearchScamTokenNamespace extends Serializable {
  constructor(parameters: ResearchScamTokenNamespaceParameters = {}) {
    super();
    this.Serializable$set(
        'namespace',
        (parameters.namespace == null) ? (null) : (parameters.namespace));
    this.Serializable$set(
        'stringTokens',
        (parameters.stringTokens == null) ? (null) : (parameters.stringTokens));
    this.Serializable$set(
        'uint64Tokens',
        (parameters.uint64Tokens == null) ? (null) : (parameters.uint64Tokens));
    this.Serializable$set(
        'stringBlacklistTokens',
        (parameters.stringBlacklistTokens == null) ?
            (null) :
            (parameters.stringBlacklistTokens));
    this.Serializable$set(
        'uint64BlacklistTokens',
        (parameters.uint64BlacklistTokens == null) ?
            (null) :
            (parameters.uint64BlacklistTokens));
  }

  get namespace(): string|null {
    return (
        (this.Serializable$has('namespace')) ?
            (this.Serializable$get('namespace')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set namespace(value: string|null) {
    this.Serializable$set('namespace', value);
  }

  get stringBlacklistTokens(): Array<string>|null {
    return (
        (this.Serializable$has('stringBlacklistTokens')) ?
            (this.Serializable$get('stringBlacklistTokens')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set stringBlacklistTokens(value: Array<string>|null) {
    this.Serializable$set('stringBlacklistTokens', value);
  }

  get stringTokens(): Array<string>|null {
    return (
        (this.Serializable$has('stringTokens')) ?
            (this.Serializable$get('stringTokens')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set stringTokens(value: Array<string>|null) {
    this.Serializable$set('stringTokens', value);
  }

  get uint64BlacklistTokens(): Array<string>|null {
    return (
        (this.Serializable$has('uint64BlacklistTokens')) ?
            (this.Serializable$get('uint64BlacklistTokens')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set uint64BlacklistTokens(value: Array<string>|null) {
    this.Serializable$set('uint64BlacklistTokens', value);
  }

  get uint64Tokens(): Array<string>|null {
    return (
        (this.Serializable$has('uint64Tokens')) ?
            (this.Serializable$get('uint64Tokens')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set uint64Tokens(value: Array<string>|null) {
    this.Serializable$set('uint64Tokens', value);
  }

  getConstructor(): SerializableCtor<ResearchScamTokenNamespace> {
    return ResearchScamTokenNamespace;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'namespace', 'stringBlacklistTokens', 'stringTokens',
        'uint64BlacklistTokens', 'uint64Tokens'
      ]
    };
  }
}

export interface ResearchScamV3RestrictParameters {
  namespaces?: Array<ResearchScamTokenNamespace>|null;
}
export class ResearchScamV3Restrict extends Serializable {
  constructor(parameters: ResearchScamV3RestrictParameters = {}) {
    super();
    this.Serializable$set(
        'namespaces',
        (parameters.namespaces == null) ? (null) : (parameters.namespaces));
  }

  get namespaces(): Array<ResearchScamTokenNamespace>|null {
    return (
        (this.Serializable$has('namespaces')) ?
            (this.Serializable$get('namespaces')) :
            (null));
  }

  /**
   * # Protos in the research_scam package are used for internal processing
   * only, they are not # part of the public API.
   */
  set namespaces(value: Array<ResearchScamTokenNamespace>|null) {
    this.Serializable$set('namespaces', value);
  }

  getConstructor(): SerializableCtor<ResearchScamV3Restrict> {
    return ResearchScamV3Restrict;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'namespaces': ResearchScamTokenNamespace},
      keys: ['namespaces']
    };
  }
}

export interface ResponseMessageEndInteraction_Parameters {}
export class ResponseMessageEndInteraction_ extends Serializable {
  constructor(parameters: ResponseMessageEndInteraction_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<ResponseMessageEndInteraction_> {
    return ResponseMessageEndInteraction_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface ResponseMessageLiveAgentHandoff_Parameters {
  metadata?: ApiClientObjectMap<any>|null;
}
export class ResponseMessageLiveAgentHandoff_ extends Serializable {
  constructor(parameters: ResponseMessageLiveAgentHandoff_Parameters = {}) {
    super();
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
  }

  get metadata(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Custom metadata for your handoff procedure. Dialogflow doesn't impose any
   * structure on this.
   */
  set metadata(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('metadata', value);
  }

  getConstructor(): SerializableCtor<ResponseMessageLiveAgentHandoff_> {
    return ResponseMessageLiveAgentHandoff_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['metadata'],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface ResponseMessageMixedAudioSegment_Parameters {
  audio?: string|null;
  uri?: string|null;
  allowPlaybackInterruption?: boolean|null;
}
export class ResponseMessageMixedAudioSegment_ extends Serializable {
  constructor(parameters: ResponseMessageMixedAudioSegment_Parameters = {}) {
    super();
    this.Serializable$set(
        'audio', (parameters.audio == null) ? (null) : (parameters.audio));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'allowPlaybackInterruption',
        (parameters.allowPlaybackInterruption == null) ?
            (null) :
            (parameters.allowPlaybackInterruption));
  }

  get allowPlaybackInterruption(): boolean|null {
    return (
        (this.Serializable$has('allowPlaybackInterruption')) ?
            (this.Serializable$get('allowPlaybackInterruption')) :
            (null));
  }

  /**
   * Whether the playback of this segment can be interrupted by the end user's
   * speech and the client should then start the next Dialogflow request.
   */
  set allowPlaybackInterruption(value: boolean|null) {
    this.Serializable$set('allowPlaybackInterruption', value);
  }

  get audio(): string|null {
    return (
        (this.Serializable$has('audio')) ? (this.Serializable$get('audio')) :
                                           (null));
  }

  /**
   * Raw audio synthesized from the Dialogflow agent's response using the output
   * config specified in the request.
   */
  set audio(value: string|null) {
    this.Serializable$set('audio', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Client-specific URI that points to an audio clip accessible to the client.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<ResponseMessageMixedAudioSegment_> {
    return ResponseMessageMixedAudioSegment_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['allowPlaybackInterruption', 'audio', 'uri']};
  }
}

export interface ResponseMessageMixedAudio_Parameters {
  segments?: Array<ResponseMessageMixedAudioSegment_>|null;
}
export class ResponseMessageMixedAudio_ extends Serializable {
  constructor(parameters: ResponseMessageMixedAudio_Parameters = {}) {
    super();
    this.Serializable$set(
        'segments',
        (parameters.segments == null) ? (null) : (parameters.segments));
  }

  get segments(): Array<ResponseMessageMixedAudioSegment_>|null {
    return (
        (this.Serializable$has('segments')) ?
            (this.Serializable$get('segments')) :
            (null));
  }

  /**
   * Segments this audio response is composed of.
   */
  set segments(value: Array<ResponseMessageMixedAudioSegment_>|null) {
    this.Serializable$set('segments', value);
  }

  getConstructor(): SerializableCtor<ResponseMessageMixedAudio_> {
    return ResponseMessageMixedAudio_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'segments': ResponseMessageMixedAudioSegment_},
      keys: ['segments']
    };
  }
}

export interface ResponseMessageTelephonyTransferCall_Parameters {
  phoneNumber?: string|null;
  sipUri?: string|null;
}
export class ResponseMessageTelephonyTransferCall_ extends Serializable {
  constructor(
      parameters: ResponseMessageTelephonyTransferCall_Parameters = {}) {
    super();
    this.Serializable$set(
        'phoneNumber',
        (parameters.phoneNumber == null) ? (null) : (parameters.phoneNumber));
    this.Serializable$set(
        'sipUri', (parameters.sipUri == null) ? (null) : (parameters.sipUri));
  }

  get phoneNumber(): string|null {
    return (
        (this.Serializable$has('phoneNumber')) ?
            (this.Serializable$get('phoneNumber')) :
            (null));
  }

  /**
   * Transfer the call to a phone number in [E.164
   * format](https://en.wikipedia.org/wiki/E.164).
   */
  set phoneNumber(value: string|null) {
    this.Serializable$set('phoneNumber', value);
  }

  get sipUri(): string|null {
    return (
        (this.Serializable$has('sipUri')) ? (this.Serializable$get('sipUri')) :
                                            (null));
  }

  /**
   * Transfer the call to a SIP endpoint.
   */
  set sipUri(value: string|null) {
    this.Serializable$set('sipUri', value);
  }

  getConstructor(): SerializableCtor<ResponseMessageTelephonyTransferCall_> {
    return ResponseMessageTelephonyTransferCall_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['phoneNumber', 'sipUri']};
  }
}

export interface ResponseMessageText_Parameters {
  text?: Array<string>|null;
}
export class ResponseMessageText_ extends Serializable {
  constructor(parameters: ResponseMessageText_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
  }

  get text(): Array<string>|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * A collection of text responses.
   */
  set text(value: Array<string>|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<ResponseMessageText_> {
    return ResponseMessageText_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['text']};
  }
}

export interface ResponseMessage_Parameters {
  text?: ResponseMessageText_|null;
  payload?: ApiClientObjectMap<any>|null;
  liveAgentHandoff?: ResponseMessageLiveAgentHandoff_|null;
  endInteraction?: ResponseMessageEndInteraction_|null;
  mixedAudio?: ResponseMessageMixedAudio_|null;
  telephonyTransferCall?: ResponseMessageTelephonyTransferCall_|null;
}
export class ResponseMessage_ extends Serializable {
  constructor(parameters: ResponseMessage_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'liveAgentHandoff',
        (parameters.liveAgentHandoff == null) ? (null) :
                                                (parameters.liveAgentHandoff));
    this.Serializable$set(
        'endInteraction',
        (parameters.endInteraction == null) ? (null) :
                                              (parameters.endInteraction));
    this.Serializable$set(
        'mixedAudio',
        (parameters.mixedAudio == null) ? (null) : (parameters.mixedAudio));
    this.Serializable$set(
        'telephonyTransferCall',
        (parameters.telephonyTransferCall == null) ?
            (null) :
            (parameters.telephonyTransferCall));
  }

  get endInteraction(): ResponseMessageEndInteraction_|null {
    return (
        (this.Serializable$has('endInteraction')) ?
            (this.Serializable$get('endInteraction')) :
            (null));
  }

  /**
   * A signal that indicates the interaction with the Dialogflow agent has
   * ended.
   */
  set endInteraction(value: ResponseMessageEndInteraction_|null) {
    this.Serializable$set('endInteraction', value);
  }

  get liveAgentHandoff(): ResponseMessageLiveAgentHandoff_|null {
    return (
        (this.Serializable$has('liveAgentHandoff')) ?
            (this.Serializable$get('liveAgentHandoff')) :
            (null));
  }

  /**
   * Hands off conversation to a live agent.
   */
  set liveAgentHandoff(value: ResponseMessageLiveAgentHandoff_|null) {
    this.Serializable$set('liveAgentHandoff', value);
  }

  get mixedAudio(): ResponseMessageMixedAudio_|null {
    return (
        (this.Serializable$has('mixedAudio')) ?
            (this.Serializable$get('mixedAudio')) :
            (null));
  }

  /**
   * An audio response message composed of both the synthesized Dialogflow agent
   * responses and the audios hosted in places known to the client.
   */
  set mixedAudio(value: ResponseMessageMixedAudio_|null) {
    this.Serializable$set('mixedAudio', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Returns a response containing a custom, platform-specific payload.
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get telephonyTransferCall(): ResponseMessageTelephonyTransferCall_|null {
    return (
        (this.Serializable$has('telephonyTransferCall')) ?
            (this.Serializable$get('telephonyTransferCall')) :
            (null));
  }

  /**
   * A signal that the client should transfer the phone call connected to this
   * agent to a third-party endpoint.
   */
  set telephonyTransferCall(value: ResponseMessageTelephonyTransferCall_|null) {
    this.Serializable$set('telephonyTransferCall', value);
  }

  get text(): ResponseMessageText_|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Returns a text response.
   */
  set text(value: ResponseMessageText_|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<ResponseMessage_> {
    return ResponseMessage_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'endInteraction', 'liveAgentHandoff', 'mixedAudio', 'payload',
        'telephonyTransferCall', 'text'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'endInteraction': ResponseMessageEndInteraction_,
        'liveAgentHandoff': ResponseMessageLiveAgentHandoff_,
        'mixedAudio': ResponseMessageMixedAudio_,
        'telephonyTransferCall': ResponseMessageTelephonyTransferCall_,
        'text': ResponseMessageText_
      }
    };
  }
}

export interface RestoreAgentRequest_Parameters {
  agentUri?: string|null;
  agentContent?: string|null;
}
export class RestoreAgentRequest_ extends Serializable {
  constructor(parameters: RestoreAgentRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'agentUri',
        (parameters.agentUri == null) ? (null) : (parameters.agentUri));
    this.Serializable$set(
        'agentContent',
        (parameters.agentContent == null) ? (null) : (parameters.agentContent));
  }

  get agentContent(): string|null {
    return (
        (this.Serializable$has('agentContent')) ?
            (this.Serializable$get('agentContent')) :
            (null));
  }

  /**
   * Zip compressed raw byte content for agent.
   */
  set agentContent(value: string|null) {
    this.Serializable$set('agentContent', value);
  }

  get agentUri(): string|null {
    return (
        (this.Serializable$has('agentUri')) ?
            (this.Serializable$get('agentUri')) :
            (null));
  }

  /**
   * The URI to a Google Cloud Storage file containing the agent to restore.
   * Note: The URI must start with \"gs://\". Dialogflow performs a read
   * operation for the Cloud Storage object on the caller's behalf, so your
   * request authentication must have read permissions for the object. For more
   * information, see [Dialogflow access
   * control](https://cloud.google.com/dialogflow/cx/docs/concept/access-control#storage).
   */
  set agentUri(value: string|null) {
    this.Serializable$set('agentUri', value);
  }

  getConstructor(): SerializableCtor<RestoreAgentRequest_> {
    return RestoreAgentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agentContent', 'agentUri']};
  }
}

export interface RevokeIntegrationRequest_Parameters {}
export class RevokeIntegrationRequest_ extends Serializable {
  constructor(parameters: RevokeIntegrationRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<RevokeIntegrationRequest_> {
    return RevokeIntegrationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface RevokeIntegrationResponse_Parameters {
  redirectUri?: string|null;
  integration?: Integration_|null;
}
export class RevokeIntegrationResponse_ extends Serializable {
  constructor(parameters: RevokeIntegrationResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'redirectUri',
        (parameters.redirectUri == null) ? (null) : (parameters.redirectUri));
    this.Serializable$set(
        'integration',
        (parameters.integration == null) ? (null) : (parameters.integration));
  }

  get integration(): Integration_|null {
    return (
        (this.Serializable$has('integration')) ?
            (this.Serializable$get('integration')) :
            (null));
  }

  /**
   * Changed integration request.
   */
  set integration(value: Integration_|null) {
    this.Serializable$set('integration', value);
  }

  get redirectUri(): string|null {
    return (
        (this.Serializable$has('redirectUri')) ?
            (this.Serializable$get('redirectUri')) :
            (null));
  }

  /**
   * The URI to direct to for the revoke flow.
   */
  set redirectUri(value: string|null) {
    this.Serializable$set('redirectUri', value);
  }

  getConstructor(): SerializableCtor<RevokeIntegrationResponse_> {
    return RevokeIntegrationResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['integration', 'redirectUri'],
      objects: {'integration': Integration_}
    };
  }
}

export interface SearchAgentsResponse_Parameters {
  agents?: Array<Agent_>|null;
  nextPageToken?: string|null;
}
export class SearchAgentsResponse_ extends Serializable {
  constructor(parameters: SearchAgentsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'agents', (parameters.agents == null) ? (null) : (parameters.agents));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get agents(): Array<Agent_>|null {
    return (
        (this.Serializable$has('agents')) ? (this.Serializable$get('agents')) :
                                            (null));
  }

  /**
   * The list of agents. There will be a maximum number of items returned based
   * on the page_size field in the request.
   */
  set agents(value: Array<Agent_>|null) {
    this.Serializable$set('agents', value);
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  getConstructor(): SerializableCtor<SearchAgentsResponse_> {
    return SearchAgentsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'agents': Agent_}, keys: ['agents', 'nextPageToken']};
  }
}

export interface SearchArticleAnswerSnippetAnswer_Parameters {
  position?: number|null;
  content?: string|null;
  previousContent?: string|null;
  nextContent?: string|null;
  score?: number|null;
  answerRecord?: string|null;
}
export class SearchArticleAnswerSnippetAnswer_ extends Serializable {
  constructor(parameters: SearchArticleAnswerSnippetAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'position',
        (parameters.position == null) ? (null) : (parameters.position));
    this.Serializable$set(
        'content',
        (parameters.content == null) ? (null) : (parameters.content));
    this.Serializable$set(
        'previousContent',
        (parameters.previousContent == null) ? (null) :
                                               (parameters.previousContent));
    this.Serializable$set(
        'nextContent',
        (parameters.nextContent == null) ? (null) : (parameters.nextContent));
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\".
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get content(): string|null {
    return (
        (this.Serializable$has('content')) ?
            (this.Serializable$get('content')) :
            (null));
  }

  /**
   * Snippet content.
   */
  set content(value: string|null) {
    this.Serializable$set('content', value);
  }

  get nextContent(): string|null {
    return (
        (this.Serializable$has('nextContent')) ?
            (this.Serializable$get('nextContent')) :
            (null));
  }

  /**
   * Next content for context.
   */
  set nextContent(value: string|null) {
    this.Serializable$set('nextContent', value);
  }

  get position(): number|null {
    return (
        (this.Serializable$has('position')) ?
            (this.Serializable$get('position')) :
            (null));
  }

  /**
   * Snippet position within the article.
   */
  set position(value: number|null) {
    this.Serializable$set('position', value);
  }

  get previousContent(): string|null {
    return (
        (this.Serializable$has('previousContent')) ?
            (this.Serializable$get('previousContent')) :
            (null));
  }

  /**
   * Previous content for context.
   */
  set previousContent(value: string|null) {
    this.Serializable$set('previousContent', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Snippet score. The score is technically unbounded and could be any valid
   * float value.
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<SearchArticleAnswerSnippetAnswer_> {
    return SearchArticleAnswerSnippetAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'answerRecord', 'content', 'nextContent', 'position', 'previousContent',
        'score'
      ]
    };
  }
}

export interface SearchArticleAnswer_Parameters {
  title?: string|null;
  uri?: string|null;
  snippets?: Array<SearchArticleAnswerSnippetAnswer_>|null;
  score?: number|null;
  metadata?: ApiClientObjectMap<string>|null;
  answerRecord?: string|null;
}
export class SearchArticleAnswer_ extends Serializable {
  constructor(parameters: SearchArticleAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'snippets',
        (parameters.snippets == null) ? (null) : (parameters.snippets));
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\".
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get metadata(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * A map that contains metadata about the answer and the document from which
   * it originates.
   */
  set metadata(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('metadata', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Article match score. The score is technically unbounded and could be any
   * valid float value. This is a relative measure of how well the Article
   * snippets match the given query. The score only guarantees ranking for the
   * results currently and is not globally consistent.
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  get snippets(): Array<SearchArticleAnswerSnippetAnswer_>|null {
    return (
        (this.Serializable$has('snippets')) ?
            (this.Serializable$get('snippets')) :
            (null));
  }

  /**
   * Matching (most relevant) article snippets, ordered by their position in the
   * original document.
   */
  set snippets(value: Array<SearchArticleAnswerSnippetAnswer_>|null) {
    this.Serializable$set('snippets', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * The article title.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * The article URI.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<SearchArticleAnswer_> {
    return SearchArticleAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'snippets': SearchArticleAnswerSnippetAnswer_},
      keys: ['answerRecord', 'metadata', 'score', 'snippets', 'title', 'uri'],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface SearchArticlesResponse_Parameters {
  articleAnswers?: Array<SearchArticleAnswer_>|null;
}
export class SearchArticlesResponse_ extends Serializable {
  constructor(parameters: SearchArticlesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'articleAnswers',
        (parameters.articleAnswers == null) ? (null) :
                                              (parameters.articleAnswers));
  }

  get articleAnswers(): Array<SearchArticleAnswer_>|null {
    return (
        (this.Serializable$has('articleAnswers')) ?
            (this.Serializable$get('articleAnswers')) :
            (null));
  }

  /**
   * Output only. Articles ordered by score in descending order.
   */
  set articleAnswers(value: Array<SearchArticleAnswer_>|null) {
    this.Serializable$set('articleAnswers', value);
  }

  getConstructor(): SerializableCtor<SearchArticlesResponse_> {
    return SearchArticlesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'articleAnswers': SearchArticleAnswer_},
      keys: ['articleAnswers']
    };
  }
}

export interface SearchSessionConversationsResponse_Parameters {
  sessionConversations?: Array<SessionConversation_>|null;
  nextPageToken?: string|null;
}
export class SearchSessionConversationsResponse_ extends Serializable {
  constructor(parameters: SearchSessionConversationsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'sessionConversations',
        (parameters.sessionConversations == null) ?
            (null) :
            (parameters.sessionConversations));
    this.Serializable$set(
        'nextPageToken',
        (parameters.nextPageToken == null) ? (null) :
                                             (parameters.nextPageToken));
  }

  get nextPageToken(): string|null {
    return (
        (this.Serializable$has('nextPageToken')) ?
            (this.Serializable$get('nextPageToken')) :
            (null));
  }

  /**
   * The token for fetching the next page of results.
   */
  set nextPageToken(value: string|null) {
    this.Serializable$set('nextPageToken', value);
  }

  get sessionConversations(): Array<SessionConversation_>|null {
    return (
        (this.Serializable$has('sessionConversations')) ?
            (this.Serializable$get('sessionConversations')) :
            (null));
  }

  /**
   * The session conversations matching the search conditions.
   */
  set sessionConversations(value: Array<SessionConversation_>|null) {
    this.Serializable$set('sessionConversations', value);
  }

  getConstructor(): SerializableCtor<SearchSessionConversationsResponse_> {
    return SearchSessionConversationsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'sessionConversations': SessionConversation_},
      keys: ['nextPageToken', 'sessionConversations']
    };
  }
}

export interface SecuritySettingsAudioExportSettings_Parameters {
  gcsBucket?: string|null;
  audioExportPattern?: string|null;
  enableAudioRedaction?: boolean|null;
  audioFormat?: SecuritySettingsAudioExportSettings_AudioFormat|null;
}
export class SecuritySettingsAudioExportSettings_ extends Serializable {
  constructor(parameters: SecuritySettingsAudioExportSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'gcsBucket',
        (parameters.gcsBucket == null) ? (null) : (parameters.gcsBucket));
    this.Serializable$set(
        'audioExportPattern',
        (parameters.audioExportPattern == null) ?
            (null) :
            (parameters.audioExportPattern));
    this.Serializable$set(
        'enableAudioRedaction',
        (parameters.enableAudioRedaction == null) ?
            (null) :
            (parameters.enableAudioRedaction));
    this.Serializable$set(
        'audioFormat',
        (parameters.audioFormat == null) ? (null) : (parameters.audioFormat));
  }

  static get AudioFormat():
      ISecuritySettingsAudioExportSettings_AudioFormatEnum {
    return SecuritySettingsAudioExportSettings_AudioFormatEnum;
  }

  get audioExportPattern(): string|null {
    return (
        (this.Serializable$has('audioExportPattern')) ?
            (this.Serializable$get('audioExportPattern')) :
            (null));
  }

  /**
   * Filename pattern for exported audio.
   */
  set audioExportPattern(value: string|null) {
    this.Serializable$set('audioExportPattern', value);
  }

  get audioFormat(): SecuritySettingsAudioExportSettings_AudioFormat|null {
    return (
        (this.Serializable$has('audioFormat')) ?
            (this.Serializable$get('audioFormat')) :
            (null));
  }

  /**
   * File format for exported audio file. Currently only in telephony
   * recordings.
   */
  set audioFormat(value: SecuritySettingsAudioExportSettings_AudioFormat|null) {
    this.Serializable$set('audioFormat', value);
  }

  get enableAudioRedaction(): boolean|null {
    return (
        (this.Serializable$has('enableAudioRedaction')) ?
            (this.Serializable$get('enableAudioRedaction')) :
            (null));
  }

  /**
   * Enable audio redaction if it is true.
   */
  set enableAudioRedaction(value: boolean|null) {
    this.Serializable$set('enableAudioRedaction', value);
  }

  get gcsBucket(): string|null {
    return (
        (this.Serializable$has('gcsBucket')) ?
            (this.Serializable$get('gcsBucket')) :
            (null));
  }

  /**
   * Cloud Storage bucket to export audio record to. Settings this field would
   * grant the Storage Object Creator role to the Dialogflow Service Agent. API
   * caller that tries to modify this field should have the permission of
   * storage.buckets.setIamPolicy.
   */
  set gcsBucket(value: string|null) {
    this.Serializable$set('gcsBucket', value);
  }

  getConstructor(): SerializableCtor<SecuritySettingsAudioExportSettings_> {
    return SecuritySettingsAudioExportSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums:
          {'audioFormat': SecuritySettingsAudioExportSettings_AudioFormatEnum},
      keys: [
        'audioExportPattern', 'audioFormat', 'enableAudioRedaction', 'gcsBucket'
      ]
    };
  }
}

export interface SecuritySettingsConversationDatasetExportSettings_Parameters {
  enableConversationDatasetExport?: boolean|null;
  conversationDataset?: string|null;
}
export class SecuritySettingsConversationDatasetExportSettings_ extends
    Serializable {
  constructor(
      parameters:
          SecuritySettingsConversationDatasetExportSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'enableConversationDatasetExport',
        (parameters.enableConversationDatasetExport == null) ?
            (null) :
            (parameters.enableConversationDatasetExport));
    this.Serializable$set(
        'conversationDataset',
        (parameters.conversationDataset == null) ?
            (null) :
            (parameters.conversationDataset));
  }

  get conversationDataset(): string|null {
    return (
        (this.Serializable$has('conversationDataset')) ?
            (this.Serializable$get('conversationDataset')) :
            (null));
  }

  /**
   * Output only. The conversation dataset to export to.
   */
  set conversationDataset(value: string|null) {
    this.Serializable$set('conversationDataset', value);
  }

  get enableConversationDatasetExport(): boolean|null {
    return (
        (this.Serializable$has('enableConversationDatasetExport')) ?
            (this.Serializable$get('enableConversationDatasetExport')) :
            (null));
  }

  /**
   * If enabled, we will automatically exports conversations to
   * ConversationDataset
   */
  set enableConversationDatasetExport(value: boolean|null) {
    this.Serializable$set('enableConversationDatasetExport', value);
  }

  getConstructor():
      SerializableCtor<SecuritySettingsConversationDatasetExportSettings_> {
    return SecuritySettingsConversationDatasetExportSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationDataset', 'enableConversationDatasetExport']};
  }
}

export interface SecuritySettingsInsightsExportSettings_Parameters {
  enableInsightsExport?: boolean|null;
}
export class SecuritySettingsInsightsExportSettings_ extends Serializable {
  constructor(
      parameters: SecuritySettingsInsightsExportSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'enableInsightsExport',
        (parameters.enableInsightsExport == null) ?
            (null) :
            (parameters.enableInsightsExport));
  }

  get enableInsightsExport(): boolean|null {
    return (
        (this.Serializable$has('enableInsightsExport')) ?
            (this.Serializable$get('enableInsightsExport')) :
            (null));
  }

  /**
   * If enabled, we will automatically exports conversations to Insights and
   * Insights runs its analyzers.
   */
  set enableInsightsExport(value: boolean|null) {
    this.Serializable$set('enableInsightsExport', value);
  }

  getConstructor(): SerializableCtor<SecuritySettingsInsightsExportSettings_> {
    return SecuritySettingsInsightsExportSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['enableInsightsExport']};
  }
}

export interface SecuritySettings_Parameters {
  name?: string|null;
  redactionStrategy?: SecuritySettings_RedactionStrategy|null;
  redactionScope?: SecuritySettings_RedactionScope|null;
  redactionInfoTypes?: Array<SecuritySettings_RedactionInfoTypes>|null;
  inspectTemplate?: string|null;
  deidentifyTemplate?: string|null;
  retentionWindowDays?: number|null;
  retentionStrategy?: SecuritySettings_RetentionStrategy|null;
  purgeDataTypes?: Array<SecuritySettings_PurgeDataTypes>|null;
  disablePostConversationLogging?: boolean|null;
  audioExportSettings?: SecuritySettingsAudioExportSettings_|null;
  insightsExportSettings?: SecuritySettingsInsightsExportSettings_|null;
  conversationDatasetExportSettings?:
      SecuritySettingsConversationDatasetExportSettings_|null;
}
export class SecuritySettings_ extends Serializable {
  constructor(parameters: SecuritySettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'redactionStrategy',
        (parameters.redactionStrategy == null) ?
            (null) :
            (parameters.redactionStrategy));
    this.Serializable$set(
        'redactionScope',
        (parameters.redactionScope == null) ? (null) :
                                              (parameters.redactionScope));
    this.Serializable$set(
        'redactionInfoTypes',
        (parameters.redactionInfoTypes == null) ?
            (null) :
            (parameters.redactionInfoTypes));
    this.Serializable$set(
        'inspectTemplate',
        (parameters.inspectTemplate == null) ? (null) :
                                               (parameters.inspectTemplate));
    this.Serializable$set(
        'deidentifyTemplate',
        (parameters.deidentifyTemplate == null) ?
            (null) :
            (parameters.deidentifyTemplate));
    this.Serializable$set(
        'retentionWindowDays',
        (parameters.retentionWindowDays == null) ?
            (null) :
            (parameters.retentionWindowDays));
    this.Serializable$set(
        'retentionStrategy',
        (parameters.retentionStrategy == null) ?
            (null) :
            (parameters.retentionStrategy));
    this.Serializable$set(
        'purgeDataTypes',
        (parameters.purgeDataTypes == null) ? (null) :
                                              (parameters.purgeDataTypes));
    this.Serializable$set(
        'disablePostConversationLogging',
        (parameters.disablePostConversationLogging == null) ?
            (null) :
            (parameters.disablePostConversationLogging));
    this.Serializable$set(
        'audioExportSettings',
        (parameters.audioExportSettings == null) ?
            (null) :
            (parameters.audioExportSettings));
    this.Serializable$set(
        'insightsExportSettings',
        (parameters.insightsExportSettings == null) ?
            (null) :
            (parameters.insightsExportSettings));
    this.Serializable$set(
        'conversationDatasetExportSettings',
        (parameters.conversationDatasetExportSettings == null) ?
            (null) :
            (parameters.conversationDatasetExportSettings));
  }

  static get PurgeDataTypes(): ISecuritySettings_PurgeDataTypesEnum {
    return SecuritySettings_PurgeDataTypesEnum;
  }

  static get RedactionInfoTypes(): ISecuritySettings_RedactionInfoTypesEnum {
    return SecuritySettings_RedactionInfoTypesEnum;
  }

  static get RedactionScope(): ISecuritySettings_RedactionScopeEnum {
    return SecuritySettings_RedactionScopeEnum;
  }

  static get RedactionStrategy(): ISecuritySettings_RedactionStrategyEnum {
    return SecuritySettings_RedactionStrategyEnum;
  }

  static get RetentionStrategy(): ISecuritySettings_RetentionStrategyEnum {
    return SecuritySettings_RetentionStrategyEnum;
  }

  get audioExportSettings(): SecuritySettingsAudioExportSettings_|null {
    return (
        (this.Serializable$has('audioExportSettings')) ?
            (this.Serializable$get('audioExportSettings')) :
            (null));
  }

  /**
   * Optional. Controls audio export settings for post-conversation analytics
   * when ingesting audio to conversations via Participants.AnalyzeContent or
   * Participants.StreamingAnalyzeContent. If retention_strategy is set to
   * REMOVE_AFTER_CONVERSATION or audio_export_settings.gcs_bucket is empty,
   * audio export is disabled. If audio export is enabled, audio is recorded and
   * saved to audio_export_settings.gcs_bucket, subject to retention policy of
   * audio_export_settings.gcs_bucket. This setting won't effect audio input for
   * implicit sessions via Sessions.DetectIntent or
   * Sessions.StreamingDetectIntent.
   */
  set audioExportSettings(value: SecuritySettingsAudioExportSettings_|null) {
    this.Serializable$set('audioExportSettings', value);
  }

  get conversationDatasetExportSettings():
      SecuritySettingsConversationDatasetExportSettings_|null {
    return (
        (this.Serializable$has('conversationDatasetExportSettings')) ?
            (this.Serializable$get('conversationDatasetExportSettings')) :
            (null));
  }

  /**
   * Optional. Controls conversation exporting settings to ConversationDataset
   * after conversation is completed. If retention_strategy is set to
   * REMOVE_AFTER_CONVERSATION, ConversationDataset export is disabled no matter
   * what you configure here.
   */
  set conversationDatasetExportSettings(
      value: SecuritySettingsConversationDatasetExportSettings_|null) {
    this.Serializable$set('conversationDatasetExportSettings', value);
  }

  get deidentifyTemplate(): string|null {
    return (
        (this.Serializable$has('deidentifyTemplate')) ?
            (this.Serializable$get('deidentifyTemplate')) :
            (null));
  }

  /**
   * Optional. [DLP](https://cloud.google.com/dlp/docs) deidentify template
   * name. Use this template to define de-identification configuration for the
   * content. If empty, Dialogflow replaces sensitive info with `[redacted]`
   * text. The template name will have one of the following formats:
   * `projects//locations//deidentifyTemplates/` OR
   * `organizations//locations//deidentifyTemplates/` Note:
   * `deidentify_template` must be located in the same region as the
   * `SecuritySettings`.
   */
  set deidentifyTemplate(value: string|null) {
    this.Serializable$set('deidentifyTemplate', value);
  }

  get disablePostConversationLogging(): boolean|null {
    return (
        (this.Serializable$has('disablePostConversationLogging')) ?
            (this.Serializable$get('disablePostConversationLogging')) :
            (null));
  }

  /**
   * Optional. Controls whether to purge logged conversations after they have
   * ended or to keep logging them. If retention_strategy is set to
   * REMOVE_AFTER_CONVERSATION or disable_post_conversation_logging is true,
   * then conversations will always be removed after they have completed. If
   * disable_post_conversation_logging is false, conversations will be kept
   * after the conversation is completed, subject to the retention_strategy. If
   * you change this setting, this change only applies to future conversations.
   * It won't be applied retroactively. This setting only applies to explicit
   * conversations created via [Conversations.CreateConversation] and filled
   * with the Participants.AnalyzeContent and
   * Participants.StreamingAnalyzeContent methods. It will not affect logging
   * for sessions defined implicitly with calls to Sessions.DetectIntent and
   * Sessions.StreamingDetectIntent..
   */
  set disablePostConversationLogging(value: boolean|null) {
    this.Serializable$set('disablePostConversationLogging', value);
  }

  get insightsExportSettings(): SecuritySettingsInsightsExportSettings_|null {
    return (
        (this.Serializable$has('insightsExportSettings')) ?
            (this.Serializable$get('insightsExportSettings')) :
            (null));
  }

  /**
   * Optional. Controls conversation exporting settings to Insights after
   * conversation is completed. If retention_strategy is set to
   * REMOVE_AFTER_CONVERSATION, Insights export is disabled no matter what you
   * configure here.
   */
  set insightsExportSettings(value: SecuritySettingsInsightsExportSettings_|
                             null) {
    this.Serializable$set('insightsExportSettings', value);
  }

  get inspectTemplate(): string|null {
    return (
        (this.Serializable$has('inspectTemplate')) ?
            (this.Serializable$get('inspectTemplate')) :
            (null));
  }

  /**
   * Optional. [DLP](https://cloud.google.com/dlp/docs) inspect template name.
   * Use this template to define inspect base settings. If empty, we use the
   * default DLP inspect config. The template name will have one of the
   * following formats: `projects//locations//inspectTemplates/` OR
   * `organizations//locations//inspectTemplates/` Note: `inspect_template` must
   * be located in the same region as the `SecuritySettings`.
   */
  set inspectTemplate(value: string|null) {
    this.Serializable$set('inspectTemplate', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. Resource name of the settings. Format:
   * `projects//securitySettings`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get purgeDataTypes(): Array<SecuritySettings_PurgeDataTypes>|null {
    return (
        (this.Serializable$has('purgeDataTypes')) ?
            (this.Serializable$get('purgeDataTypes')) :
            (null));
  }

  /**
   * Optional. List of types of data to remove when retention settings triggers
   * purge.
   */
  set purgeDataTypes(value: Array<SecuritySettings_PurgeDataTypes>|null) {
    this.Serializable$set('purgeDataTypes', value);
  }

  get redactionInfoTypes(): Array<SecuritySettings_RedactionInfoTypes>|null {
    return (
        (this.Serializable$has('redactionInfoTypes')) ?
            (this.Serializable$get('redactionInfoTypes')) :
            (null));
  }

  /**
   * Optional. List of types of info to be redacted.
   */
  set redactionInfoTypes(value: Array<SecuritySettings_RedactionInfoTypes>|
                         null) {
    this.Serializable$set('redactionInfoTypes', value);
  }

  get redactionScope(): SecuritySettings_RedactionScope|null {
    return (
        (this.Serializable$has('redactionScope')) ?
            (this.Serializable$get('redactionScope')) :
            (null));
  }

  /**
   * Optional. Defines on what data we apply redaction. Note that we don't
   * redact data to which we don't have access, e.g., Stackdriver logs.
   */
  set redactionScope(value: SecuritySettings_RedactionScope|null) {
    this.Serializable$set('redactionScope', value);
  }

  get redactionStrategy(): SecuritySettings_RedactionStrategy|null {
    return (
        (this.Serializable$has('redactionStrategy')) ?
            (this.Serializable$get('redactionStrategy')) :
            (null));
  }

  /**
   * Optional. Strategy that defines how we do redaction.
   */
  set redactionStrategy(value: SecuritySettings_RedactionStrategy|null) {
    this.Serializable$set('redactionStrategy', value);
  }

  get retentionStrategy(): SecuritySettings_RetentionStrategy|null {
    return (
        (this.Serializable$has('retentionStrategy')) ?
            (this.Serializable$get('retentionStrategy')) :
            (null));
  }

  /**
   * Specifies the retention behavior defined by [RetentionStrategy].
   */
  set retentionStrategy(value: SecuritySettings_RetentionStrategy|null) {
    this.Serializable$set('retentionStrategy', value);
  }

  get retentionWindowDays(): number|null {
    return (
        (this.Serializable$has('retentionWindowDays')) ?
            (this.Serializable$get('retentionWindowDays')) :
            (null));
  }

  /**
   * Retains the data for the specified number of days. User must set a value
   * lower than Dialogflow's default 365d TTL (30 days for Agent Assist
   * traffic), higher value will be ignored and use default. Setting a value
   * higher than that has no effect. A missing value or setting to 0 also means
   * we use default TTL.
   */
  set retentionWindowDays(value: number|null) {
    this.Serializable$set('retentionWindowDays', value);
  }

  getConstructor(): SerializableCtor<SecuritySettings_> {
    return SecuritySettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'purgeDataTypes': SecuritySettings_PurgeDataTypesEnum,
        'redactionInfoTypes': SecuritySettings_RedactionInfoTypesEnum,
        'redactionScope': SecuritySettings_RedactionScopeEnum,
        'redactionStrategy': SecuritySettings_RedactionStrategyEnum,
        'retentionStrategy': SecuritySettings_RetentionStrategyEnum
      },
      keys: [
        'audioExportSettings', 'conversationDatasetExportSettings',
        'deidentifyTemplate', 'disablePostConversationLogging',
        'insightsExportSettings', 'inspectTemplate', 'name', 'purgeDataTypes',
        'redactionInfoTypes', 'redactionScope', 'redactionStrategy',
        'retentionStrategy', 'retentionWindowDays'
      ],
      objects: {
        'audioExportSettings': SecuritySettingsAudioExportSettings_,
        'conversationDatasetExportSettings':
            SecuritySettingsConversationDatasetExportSettings_,
        'insightsExportSettings': SecuritySettingsInsightsExportSettings_
      }
    };
  }
}

export interface SemanticMatchContext_Parameters {
  languageCode?: string|null;
  minChars?: number|null;
  servomaticBatchRequestSize?: number|null;
}
export class SemanticMatchContext_ extends Serializable {
  constructor(parameters: SemanticMatchContext_Parameters = {}) {
    super();
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'minChars',
        (parameters.minChars == null) ? (null) : (parameters.minChars));
    this.Serializable$set(
        'servomaticBatchRequestSize',
        (parameters.servomaticBatchRequestSize == null) ?
            (null) :
            (parameters.servomaticBatchRequestSize));
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The BCP-47 language code of the queries and contents.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get minChars(): number|null {
    return (
        (this.Serializable$has('minChars')) ?
            (this.Serializable$get('minChars')) :
            (null));
  }

  /**
   * Filter out sentences with total character count less than min size.
   */
  set minChars(value: number|null) {
    this.Serializable$set('minChars', value);
  }

  get servomaticBatchRequestSize(): number|null {
    return (
        (this.Serializable$has('servomaticBatchRequestSize')) ?
            (this.Serializable$get('servomaticBatchRequestSize')) :
            (null));
  }

  /**
   * The count of the examples in a servo batch request. Default value is 1.
   */
  set servomaticBatchRequestSize(value: number|null) {
    this.Serializable$set('servomaticBatchRequestSize', value);
  }

  getConstructor(): SerializableCtor<SemanticMatchContext_> {
    return SemanticMatchContext_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['languageCode', 'minChars', 'servomaticBatchRequestSize']};
  }
}

export interface SentimentAnalysisRequestConfig_Parameters {
  analyzeQueryTextSentiment?: boolean|null;
  analyzeConversationTextSentiment?: boolean|null;
}
export class SentimentAnalysisRequestConfig_ extends Serializable {
  constructor(parameters: SentimentAnalysisRequestConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'analyzeQueryTextSentiment',
        (parameters.analyzeQueryTextSentiment == null) ?
            (null) :
            (parameters.analyzeQueryTextSentiment));
    this.Serializable$set(
        'analyzeConversationTextSentiment',
        (parameters.analyzeConversationTextSentiment == null) ?
            (null) :
            (parameters.analyzeConversationTextSentiment));
  }

  get analyzeConversationTextSentiment(): boolean|null {
    return (
        (this.Serializable$has('analyzeConversationTextSentiment')) ?
            (this.Serializable$get('analyzeConversationTextSentiment')) :
            (null));
  }

  /**
   * Instructs the service to perform sentiment analysis on the conversation. If
   * not provided, sentiment analysis is not performed on the conversation. The
   * conversation is the history of queries for the current session, except that
   * if more than 10 minutes have elapsed between queries, the older queries are
   * discarded.
   */
  set analyzeConversationTextSentiment(value: boolean|null) {
    this.Serializable$set('analyzeConversationTextSentiment', value);
  }

  get analyzeQueryTextSentiment(): boolean|null {
    return (
        (this.Serializable$has('analyzeQueryTextSentiment')) ?
            (this.Serializable$get('analyzeQueryTextSentiment')) :
            (null));
  }

  /**
   * Instructs the service to perform sentiment analysis on `query_text`. If not
   * provided, sentiment analysis is not performed on `query_text`.
   */
  set analyzeQueryTextSentiment(value: boolean|null) {
    this.Serializable$set('analyzeQueryTextSentiment', value);
  }

  getConstructor(): SerializableCtor<SentimentAnalysisRequestConfig_> {
    return SentimentAnalysisRequestConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['analyzeConversationTextSentiment', 'analyzeQueryTextSentiment']
    };
  }
}

export interface SentimentAnalysisResult_Parameters {
  queryTextSentiment?: Sentiment_|null;
  conversationTextSentiment?: Sentiment_|null;
}
export class SentimentAnalysisResult_ extends Serializable {
  constructor(parameters: SentimentAnalysisResult_Parameters = {}) {
    super();
    this.Serializable$set(
        'queryTextSentiment',
        (parameters.queryTextSentiment == null) ?
            (null) :
            (parameters.queryTextSentiment));
    this.Serializable$set(
        'conversationTextSentiment',
        (parameters.conversationTextSentiment == null) ?
            (null) :
            (parameters.conversationTextSentiment));
  }

  get conversationTextSentiment(): Sentiment_|null {
    return (
        (this.Serializable$has('conversationTextSentiment')) ?
            (this.Serializable$get('conversationTextSentiment')) :
            (null));
  }

  /**
   * The sentiment analysis result for the conversation. See the documentation
   * for `analyze_conversation_text_sentiment` for the definition of
   * conversation.
   */
  set conversationTextSentiment(value: Sentiment_|null) {
    this.Serializable$set('conversationTextSentiment', value);
  }

  get queryTextSentiment(): Sentiment_|null {
    return (
        (this.Serializable$has('queryTextSentiment')) ?
            (this.Serializable$get('queryTextSentiment')) :
            (null));
  }

  /**
   * The sentiment analysis result for `query_text`.
   */
  set queryTextSentiment(value: Sentiment_|null) {
    this.Serializable$set('queryTextSentiment', value);
  }

  getConstructor(): SerializableCtor<SentimentAnalysisResult_> {
    return SentimentAnalysisResult_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['conversationTextSentiment', 'queryTextSentiment'],
      objects: {
        'conversationTextSentiment': Sentiment_,
        'queryTextSentiment': Sentiment_
      }
    };
  }
}

export interface SentimentData_Parameters {
  magnitude?: number|null;
  score?: number|null;
}
export class SentimentData_ extends Serializable {
  constructor(parameters: SentimentData_Parameters = {}) {
    super();
    this.Serializable$set(
        'magnitude',
        (parameters.magnitude == null) ? (null) : (parameters.magnitude));
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
  }

  get magnitude(): number|null {
    return (
        (this.Serializable$has('magnitude')) ?
            (this.Serializable$get('magnitude')) :
            (null));
  }

  /**
   * A non-negative number from 0 to infinity which represents the abolute
   * magnitude of sentiment regardless of score.
   */
  set magnitude(value: number|null) {
    this.Serializable$set('magnitude', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * The sentiment score between -1.0 (negative) and 1.0 (positive).
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<SentimentData_> {
    return SentimentData_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['magnitude', 'score']};
  }
}

export interface Sentiment_Parameters {
  score?: number|null;
  magnitude?: number|null;
}
export class Sentiment_ extends Serializable {
  constructor(parameters: Sentiment_Parameters = {}) {
    super();
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'magnitude',
        (parameters.magnitude == null) ? (null) : (parameters.magnitude));
  }

  get magnitude(): number|null {
    return (
        (this.Serializable$has('magnitude')) ?
            (this.Serializable$get('magnitude')) :
            (null));
  }

  /**
   * A non-negative number in the [0, +inf) range, which represents the absolute
   * magnitude of sentiment, regardless of score (positive or negative).
   */
  set magnitude(value: number|null) {
    this.Serializable$set('magnitude', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Sentiment score between -1.0 (negative sentiment) and 1.0 (positive
   * sentiment).
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  getConstructor(): SerializableCtor<Sentiment_> {
    return Sentiment_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['magnitude', 'score']};
  }
}

export interface SessionConversation_Parameters {
  name?: string|null;
  startTime?: string|null;
  endTime?: string|null;
  hasWebhookFailure?: boolean|null;
  hasNomatchInteraction?: boolean|null;
  interactions?: Array<Interaction_>|null;
  nextInteractionsPageToken?: string|null;
  languageCode?: string|null;
}
export class SessionConversation_ extends Serializable {
  constructor(parameters: SessionConversation_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'startTime',
        (parameters.startTime == null) ? (null) : (parameters.startTime));
    this.Serializable$set(
        'endTime',
        (parameters.endTime == null) ? (null) : (parameters.endTime));
    this.Serializable$set(
        'hasWebhookFailure',
        (parameters.hasWebhookFailure == null) ?
            (null) :
            (parameters.hasWebhookFailure));
    this.Serializable$set(
        'hasNomatchInteraction',
        (parameters.hasNomatchInteraction == null) ?
            (null) :
            (parameters.hasNomatchInteraction));
    this.Serializable$set(
        'interactions',
        (parameters.interactions == null) ? (null) : (parameters.interactions));
    this.Serializable$set(
        'nextInteractionsPageToken',
        (parameters.nextInteractionsPageToken == null) ?
            (null) :
            (parameters.nextInteractionsPageToken));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get endTime(): string|null {
    return (
        (this.Serializable$has('endTime')) ?
            (this.Serializable$get('endTime')) :
            (null));
  }

  /**
   * The timestamp of the last interaction in this session conversation.
   */
  set endTime(value: string|null) {
    this.Serializable$set('endTime', value);
  }

  get hasNomatchInteraction(): boolean|null {
    return (
        (this.Serializable$has('hasNomatchInteraction')) ?
            (this.Serializable$get('hasNomatchInteraction')) :
            (null));
  }

  /**
   * True if there is an interaction that matches a fallback intent or doesn't
   * match any intent.
   */
  set hasNomatchInteraction(value: boolean|null) {
    this.Serializable$set('hasNomatchInteraction', value);
  }

  get hasWebhookFailure(): boolean|null {
    return (
        (this.Serializable$has('hasWebhookFailure')) ?
            (this.Serializable$get('hasWebhookFailure')) :
            (null));
  }

  /**
   * True if there is an interaction with webhook failure.
   */
  set hasWebhookFailure(value: boolean|null) {
    this.Serializable$set('hasWebhookFailure', value);
  }

  get interactions(): Array<Interaction_>|null {
    return (
        (this.Serializable$has('interactions')) ?
            (this.Serializable$get('interactions')) :
            (null));
  }

  /**
   * The first page of interactions for this session conversation. Subsequent
   * pages can be fetched via ListInteractions using
   * `next_interactions_page_token` for the first call.
   */
  set interactions(value: Array<Interaction_>|null) {
    this.Serializable$set('interactions', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Optional. If set, indicates the language of this conversation.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The resource name of the session conversation. Format:
   * `projects//agent/environments//\ sessions//conversations/`. Note that the
   * environment is explicitly specified as `Session Conversation ID` is not
   * unique across environments.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get nextInteractionsPageToken(): string|null {
    return (
        (this.Serializable$has('nextInteractionsPageToken')) ?
            (this.Serializable$get('nextInteractionsPageToken')) :
            (null));
  }

  /**
   * Page token to retrieve the next page of interactions (beyond what the
   * `interactions` field already contains) for this session conversation, via
   * ListInteractions.
   */
  set nextInteractionsPageToken(value: string|null) {
    this.Serializable$set('nextInteractionsPageToken', value);
  }

  get startTime(): string|null {
    return (
        (this.Serializable$has('startTime')) ?
            (this.Serializable$get('startTime')) :
            (null));
  }

  /**
   * The timestamp of the first interaction in this session conversation.
   */
  set startTime(value: string|null) {
    this.Serializable$set('startTime', value);
  }

  getConstructor(): SerializableCtor<SessionConversation_> {
    return SessionConversation_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'interactions': Interaction_},
      keys: [
        'endTime', 'hasNomatchInteraction', 'hasWebhookFailure', 'interactions',
        'languageCode', 'name', 'nextInteractionsPageToken', 'startTime'
      ]
    };
  }
}

export interface SessionEntityType_Parameters {
  name?: string|null;
  entityOverrideMode?: SessionEntityType_EntityOverrideMode|null;
  entities?: Array<EntityTypeEntity_>|null;
}
export class SessionEntityType_ extends Serializable {
  constructor(parameters: SessionEntityType_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'entityOverrideMode',
        (parameters.entityOverrideMode == null) ?
            (null) :
            (parameters.entityOverrideMode));
    this.Serializable$set(
        'entities',
        (parameters.entities == null) ? (null) : (parameters.entities));
  }

  static get EntityOverrideMode(): ISessionEntityType_EntityOverrideModeEnum {
    return SessionEntityType_EntityOverrideModeEnum;
  }

  get entities(): Array<EntityTypeEntity_>|null {
    return (
        (this.Serializable$has('entities')) ?
            (this.Serializable$get('entities')) :
            (null));
  }

  /**
   * Required. The collection of entities associated with this session entity
   * type.
   */
  set entities(value: Array<EntityTypeEntity_>|null) {
    this.Serializable$set('entities', value);
  }

  get entityOverrideMode(): SessionEntityType_EntityOverrideMode|null {
    return (
        (this.Serializable$has('entityOverrideMode')) ?
            (this.Serializable$get('entityOverrideMode')) :
            (null));
  }

  /**
   * Required. Indicates whether the additional data should override or
   * supplement the custom entity type definition.
   */
  set entityOverrideMode(value: SessionEntityType_EntityOverrideMode|null) {
    this.Serializable$set('entityOverrideMode', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Required. The unique identifier of this session entity type. Supported
   * formats: - `projects//agent/sessions//entityTypes/` -
   * `projects//locations//agent/sessions//entityTypes/` -
   * `projects//agent/environments//users//sessions//entityTypes/` -
   * `projects//locations//agent/environments/ /users//sessions//entityTypes/`
   * If `Location ID` is not specified we assume default 'us' location. If
   * `Environment ID` is not specified, we assume default 'draft' environment.
   * If `User ID` is not specified, we assume default '-' user. `` must be the
   * display name of an existing entity type in the same agent that will be
   * overridden or supplemented.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<SessionEntityType_> {
    return SessionEntityType_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'entities': EntityTypeEntity_},
      enums: {'entityOverrideMode': SessionEntityType_EntityOverrideModeEnum},
      keys: ['entities', 'entityOverrideMode', 'name']
    };
  }
}

export interface SetAllContextsRequest_Parameters {
  contexts?: Array<Context_>|null;
}
export class SetAllContextsRequest_ extends Serializable {
  constructor(parameters: SetAllContextsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'contexts',
        (parameters.contexts == null) ? (null) : (parameters.contexts));
  }

  get contexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('contexts')) ?
            (this.Serializable$get('contexts')) :
            (null));
  }

  /**
   * Required. The list of contexts to set for the specified session.
   */
  set contexts(value: Array<Context_>|null) {
    this.Serializable$set('contexts', value);
  }

  getConstructor(): SerializableCtor<SetAllContextsRequest_> {
    return SetAllContextsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'contexts': Context_}, keys: ['contexts']};
  }
}

export interface SetAllContextsResponse_Parameters {
  contexts?: Array<Context_>|null;
}
export class SetAllContextsResponse_ extends Serializable {
  constructor(parameters: SetAllContextsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'contexts',
        (parameters.contexts == null) ? (null) : (parameters.contexts));
  }

  get contexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('contexts')) ?
            (this.Serializable$get('contexts')) :
            (null));
  }

  /**
   * The list of contexts.
   */
  set contexts(value: Array<Context_>|null) {
    this.Serializable$set('contexts', value);
  }

  getConstructor(): SerializableCtor<SetAllContextsResponse_> {
    return SetAllContextsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'contexts': Context_}, keys: ['contexts']};
  }
}

export interface SetSuggestionFeatureConfigOperationMetadata_Parameters {
  conversationProfile?: string|null;
  participantRole?: SetSuggestionFeatureConfigOperationMetadata_ParticipantRole|
      null;
  suggestionFeatureType?:
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType|null;
  createTime?: string|null;
}
export class SetSuggestionFeatureConfigOperationMetadata_ extends Serializable {
  constructor(
      parameters: SetSuggestionFeatureConfigOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationProfile',
        (parameters.conversationProfile == null) ?
            (null) :
            (parameters.conversationProfile));
    this.Serializable$set(
        'participantRole',
        (parameters.participantRole == null) ? (null) :
                                               (parameters.participantRole));
    this.Serializable$set(
        'suggestionFeatureType',
        (parameters.suggestionFeatureType == null) ?
            (null) :
            (parameters.suggestionFeatureType));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  static get ParticipantRole():
      ISetSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum {
    return SetSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum;
  }

  static get SuggestionFeatureType():
      ISetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum {
    return SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum;
  }

  get conversationProfile(): string|null {
    return (
        (this.Serializable$has('conversationProfile')) ?
            (this.Serializable$get('conversationProfile')) :
            (null));
  }

  /**
   * The resource name of the conversation profile. Format:
   * `projects//locations//conversationProfiles/`
   */
  set conversationProfile(value: string|null) {
    this.Serializable$set('conversationProfile', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp whe the request was created. The time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get participantRole():
      SetSuggestionFeatureConfigOperationMetadata_ParticipantRole|null {
    return (
        (this.Serializable$has('participantRole')) ?
            (this.Serializable$get('participantRole')) :
            (null));
  }

  /**
   * Required. The participant role to add or update the suggestion feature
   * config. Only HUMAN_AGENT or END_USER can be used.
   */
  set participantRole(
      value: SetSuggestionFeatureConfigOperationMetadata_ParticipantRole|null) {
    this.Serializable$set('participantRole', value);
  }

  get suggestionFeatureType():
      SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType|null {
    return (
        (this.Serializable$has('suggestionFeatureType')) ?
            (this.Serializable$get('suggestionFeatureType')) :
            (null));
  }

  /**
   * Required. The type of the suggestion feature to add or update.
   */
  set suggestionFeatureType(
      value: SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureType|
      null) {
    this.Serializable$set('suggestionFeatureType', value);
  }

  getConstructor():
      SerializableCtor<SetSuggestionFeatureConfigOperationMetadata_> {
    return SetSuggestionFeatureConfigOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'participantRole':
            SetSuggestionFeatureConfigOperationMetadata_ParticipantRoleEnum,
        'suggestionFeatureType':
            SetSuggestionFeatureConfigOperationMetadata_SuggestionFeatureTypeEnum
      },
      keys: [
        'conversationProfile', 'createTime', 'participantRole',
        'suggestionFeatureType'
      ]
    };
  }
}

export interface SetSuggestionFeatureConfigRequest_Parameters {
  participantRole?: SetSuggestionFeatureConfigRequest_ParticipantRole|null;
  suggestionFeatureConfig?: HumanAgentAssistantConfigSuggestionFeatureConfig_|
      null;
}
export class SetSuggestionFeatureConfigRequest_ extends Serializable {
  constructor(parameters: SetSuggestionFeatureConfigRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'participantRole',
        (parameters.participantRole == null) ? (null) :
                                               (parameters.participantRole));
    this.Serializable$set(
        'suggestionFeatureConfig',
        (parameters.suggestionFeatureConfig == null) ?
            (null) :
            (parameters.suggestionFeatureConfig));
  }

  static get ParticipantRole():
      ISetSuggestionFeatureConfigRequest_ParticipantRoleEnum {
    return SetSuggestionFeatureConfigRequest_ParticipantRoleEnum;
  }

  get participantRole(): SetSuggestionFeatureConfigRequest_ParticipantRole
      |null {
    return (
        (this.Serializable$has('participantRole')) ?
            (this.Serializable$get('participantRole')) :
            (null));
  }

  /**
   * Required. The participant role to add or update the suggestion feature
   * config. Only HUMAN_AGENT or END_USER can be used.
   */
  set participantRole(value: SetSuggestionFeatureConfigRequest_ParticipantRole|
                      null) {
    this.Serializable$set('participantRole', value);
  }

  get suggestionFeatureConfig():
      HumanAgentAssistantConfigSuggestionFeatureConfig_|null {
    return (
        (this.Serializable$has('suggestionFeatureConfig')) ?
            (this.Serializable$get('suggestionFeatureConfig')) :
            (null));
  }

  /**
   * Required. The suggestion feature config to add or update.
   */
  set suggestionFeatureConfig(
      value: HumanAgentAssistantConfigSuggestionFeatureConfig_|null) {
    this.Serializable$set('suggestionFeatureConfig', value);
  }

  getConstructor(): SerializableCtor<SetSuggestionFeatureConfigRequest_> {
    return SetSuggestionFeatureConfigRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'participantRole': SetSuggestionFeatureConfigRequest_ParticipantRoleEnum
      },
      keys: ['participantRole', 'suggestionFeatureConfig'],
      objects: {
        'suggestionFeatureConfig':
            HumanAgentAssistantConfigSuggestionFeatureConfig_
      }
    };
  }
}

export interface SignupIntegrationRequest_Parameters {
  partner?: SignupIntegrationRequest_Partner|null;
  languageCode?: string|null;
  displayName?: string|null;
  environment?: string|null;
}
export class SignupIntegrationRequest_ extends Serializable {
  constructor(parameters: SignupIntegrationRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'partner',
        (parameters.partner == null) ? (null) : (parameters.partner));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
  }

  static get Partner(): ISignupIntegrationRequest_PartnerEnum {
    return SignupIntegrationRequest_PartnerEnum;
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. Customer provided display name for this integration
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get environment(): string|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * The name of the environment to use for integration. If not specified, use
   * `draft` environment. Format: - `projects//agent/environments/` -
   * `projects//locations//agent/environments/`
   */
  set environment(value: string|null) {
    this.Serializable$set('environment', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. Which language to use for this integration.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get partner(): SignupIntegrationRequest_Partner|null {
    return (
        (this.Serializable$has('partner')) ?
            (this.Serializable$get('partner')) :
            (null));
  }

  /**
   * Required. Which partner to start a signup flow for.
   */
  set partner(value: SignupIntegrationRequest_Partner|null) {
    this.Serializable$set('partner', value);
  }

  getConstructor(): SerializableCtor<SignupIntegrationRequest_> {
    return SignupIntegrationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'partner': SignupIntegrationRequest_PartnerEnum},
      keys: ['displayName', 'environment', 'languageCode', 'partner']
    };
  }
}

export interface SignupIntegrationResponse_Parameters {
  redirectUri?: string|null;
  integration?: Integration_|null;
}
export class SignupIntegrationResponse_ extends Serializable {
  constructor(parameters: SignupIntegrationResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'redirectUri',
        (parameters.redirectUri == null) ? (null) : (parameters.redirectUri));
    this.Serializable$set(
        'integration',
        (parameters.integration == null) ? (null) : (parameters.integration));
  }

  get integration(): Integration_|null {
    return (
        (this.Serializable$has('integration')) ?
            (this.Serializable$get('integration')) :
            (null));
  }

  /**
   * Changed integration request.
   */
  set integration(value: Integration_|null) {
    this.Serializable$set('integration', value);
  }

  get redirectUri(): string|null {
    return (
        (this.Serializable$has('redirectUri')) ?
            (this.Serializable$get('redirectUri')) :
            (null));
  }

  /**
   * The URI to direct to for the signup flow.
   */
  set redirectUri(value: string|null) {
    this.Serializable$set('redirectUri', value);
  }

  getConstructor(): SerializableCtor<SignupIntegrationResponse_> {
    return SignupIntegrationResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['integration', 'redirectUri'],
      objects: {'integration': Integration_}
    };
  }
}

export interface SimilaritiesByChunk_Parameters {
  similaritiesPerChunk?: ApiClientObjectMap<number>|null;
}
export class SimilaritiesByChunk_ extends Serializable {
  constructor(parameters: SimilaritiesByChunk_Parameters = {}) {
    super();
    this.Serializable$set(
        'similaritiesPerChunk',
        (parameters.similaritiesPerChunk == null) ?
            (null) :
            (parameters.similaritiesPerChunk));
  }

  get similaritiesPerChunk(): ApiClientObjectMap<number>|null {
    return (
        (this.Serializable$has('similaritiesPerChunk')) ?
            (this.Serializable$get('similaritiesPerChunk')) :
            (null));
  }

  /**
   * Key-value map from transcript chunk id to calculated similarity.
   */
  set similaritiesPerChunk(value: ApiClientObjectMap<number>|null) {
    this.Serializable$set('similaritiesPerChunk', value);
  }

  getConstructor(): SerializableCtor<SimilaritiesByChunk_> {
    return SimilaritiesByChunk_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['similaritiesPerChunk'],
      objectMaps: {
        'similaritiesPerChunk': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface SipConfig_Parameters {
  createConversationOnTheFly?: boolean|null;
  useMultiSdpPorts?: boolean|null;
  inactiveStart?: boolean|null;
  maxAudioRecordingDuration?: string|null;
  allowVirtualAgentInteraction?: boolean|null;
}
export class SipConfig_ extends Serializable {
  constructor(parameters: SipConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'createConversationOnTheFly',
        (parameters.createConversationOnTheFly == null) ?
            (null) :
            (parameters.createConversationOnTheFly));
    this.Serializable$set(
        'useMultiSdpPorts',
        (parameters.useMultiSdpPorts == null) ? (null) :
                                                (parameters.useMultiSdpPorts));
    this.Serializable$set(
        'inactiveStart',
        (parameters.inactiveStart == null) ? (null) :
                                             (parameters.inactiveStart));
    this.Serializable$set(
        'maxAudioRecordingDuration',
        (parameters.maxAudioRecordingDuration == null) ?
            (null) :
            (parameters.maxAudioRecordingDuration));
    this.Serializable$set(
        'allowVirtualAgentInteraction',
        (parameters.allowVirtualAgentInteraction == null) ?
            (null) :
            (parameters.allowVirtualAgentInteraction));
  }

  get allowVirtualAgentInteraction(): boolean|null {
    return (
        (this.Serializable$has('allowVirtualAgentInteraction')) ?
            (this.Serializable$get('allowVirtualAgentInteraction')) :
            (null));
  }

  /**
   * Allows interactions with a Dialogflow virtual agent even if the call is
   * connected for SIPREC purposes.
   */
  set allowVirtualAgentInteraction(value: boolean|null) {
    this.Serializable$set('allowVirtualAgentInteraction', value);
  }

  get createConversationOnTheFly(): boolean|null {
    return (
        (this.Serializable$has('createConversationOnTheFly')) ?
            (this.Serializable$get('createConversationOnTheFly')) :
            (null));
  }

  /**
   * Asks Dialogflow Telephony to create the conversation provided in the SIP
   * header on the fly when the call comes in.
   */
  set createConversationOnTheFly(value: boolean|null) {
    this.Serializable$set('createConversationOnTheFly', value);
  }

  get inactiveStart(): boolean|null {
    return (
        (this.Serializable$has('inactiveStart')) ?
            (this.Serializable$get('inactiveStart')) :
            (null));
  }

  /**
   * Starts the conversation with inactive SDP directives
   */
  set inactiveStart(value: boolean|null) {
    this.Serializable$set('inactiveStart', value);
  }

  get maxAudioRecordingDuration(): string|null {
    return (
        (this.Serializable$has('maxAudioRecordingDuration')) ?
            (this.Serializable$get('maxAudioRecordingDuration')) :
            (null));
  }

  /**
   * Max duration for audio recording. Overrides the default value of 15 min.
   * Max value is 8 hours.
   */
  set maxAudioRecordingDuration(value: string|null) {
    this.Serializable$set('maxAudioRecordingDuration', value);
  }

  get useMultiSdpPorts(): boolean|null {
    return (
        (this.Serializable$has('useMultiSdpPorts')) ?
            (this.Serializable$get('useMultiSdpPorts')) :
            (null));
  }

  /**
   * Uses multiple ports, each for one SDP media connection.
   */
  set useMultiSdpPorts(value: boolean|null) {
    this.Serializable$set('useMultiSdpPorts', value);
  }

  getConstructor(): SerializableCtor<SipConfig_> {
    return SipConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'allowVirtualAgentInteraction', 'createConversationOnTheFly',
        'inactiveStart', 'maxAudioRecordingDuration', 'useMultiSdpPorts'
      ]
    };
  }
}

export interface SipEndpoint_Parameters {
  uri?: string|null;
}
export class SipEndpoint_ extends Serializable {
  constructor(parameters: SipEndpoint_Parameters = {}) {
    super();
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * URI for the SIP. Format: https://en.wikipedia.org/wiki/SIP_URI_scheme.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<SipEndpoint_> {
    return SipEndpoint_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['uri']};
  }
}

export interface SmallTalkConfigCustomizedAnswer_Parameters {
  action?: string|null;
  answers?: Array<string>|null;
  parameters?: Array<string>|null;
}
export class SmallTalkConfigCustomizedAnswer_ extends Serializable {
  constructor(parameters: SmallTalkConfigCustomizedAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'answers',
        (parameters.answers == null) ? (null) : (parameters.answers));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
  }

  get action(): string|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * Required. Action name of matched intent to identify response.
   */
  set action(value: string|null) {
    this.Serializable$set('action', value);
  }

  get answers(): Array<string>|null {
    return (
        (this.Serializable$has('answers')) ?
            (this.Serializable$get('answers')) :
            (null));
  }

  /**
   * Required. List of answers.
   */
  set answers(value: Array<string>|null) {
    this.Serializable$set('answers', value);
  }

  get parameters(): Array<string>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * Output only. Display names of parameters in matched SmallTalk intent to
   * help identify the response.
   */
  set parameters(value: Array<string>|null) {
    this.Serializable$set('parameters', value);
  }

  getConstructor(): SerializableCtor<SmallTalkConfigCustomizedAnswer_> {
    return SmallTalkConfigCustomizedAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['action', 'answers', 'parameters']};
  }
}

export interface SmallTalkConfig_Parameters {
  customizedAnswers?: Array<SmallTalkConfigCustomizedAnswer_>|null;
}
export class SmallTalkConfig_ extends Serializable {
  constructor(parameters: SmallTalkConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'customizedAnswers',
        (parameters.customizedAnswers == null) ?
            (null) :
            (parameters.customizedAnswers));
  }

  get customizedAnswers(): Array<SmallTalkConfigCustomizedAnswer_>|null {
    return (
        (this.Serializable$has('customizedAnswers')) ?
            (this.Serializable$get('customizedAnswers')) :
            (null));
  }

  /**
   * List of customized answers.
   */
  set customizedAnswers(value: Array<SmallTalkConfigCustomizedAnswer_>|null) {
    this.Serializable$set('customizedAnswers', value);
  }

  getConstructor(): SerializableCtor<SmallTalkConfig_> {
    return SmallTalkConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'customizedAnswers': SmallTalkConfigCustomizedAnswer_},
      keys: ['customizedAnswers']
    };
  }
}

export interface SmartComposeAnswer_Parameters {
  reply?: string|null;
  confidence?: number|null;
  answerRecord?: string|null;
}
export class SmartComposeAnswer_ extends Serializable {
  constructor(parameters: SmartComposeAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'reply', (parameters.reply == null) ? (null) : (parameters.reply));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * Smart compose confidence. The system's confidence score that this reply is
   * a good match for this conversation, as a value from 0.0 (completely
   * uncertain) to 1.0 (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get reply(): string|null {
    return (
        (this.Serializable$has('reply')) ? (this.Serializable$get('reply')) :
                                           (null));
  }

  /**
   * The content of the reply.
   */
  set reply(value: string|null) {
    this.Serializable$set('reply', value);
  }

  getConstructor(): SerializableCtor<SmartComposeAnswer_> {
    return SmartComposeAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['answerRecord', 'confidence', 'reply']};
  }
}

export interface SmartComposeConfig_Parameters {
  model?: string|null;
  candidateDocument?: string|null;
  maxResultCount?: number|null;
}
export class SmartComposeConfig_ extends Serializable {
  constructor(parameters: SmartComposeConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'candidateDocument',
        (parameters.candidateDocument == null) ?
            (null) :
            (parameters.candidateDocument));
    this.Serializable$set(
        'maxResultCount',
        (parameters.maxResultCount == null) ? (null) :
                                              (parameters.maxResultCount));
  }

  get candidateDocument(): string|null {
    return (
        (this.Serializable$has('candidateDocument')) ?
            (this.Serializable$get('candidateDocument')) :
            (null));
  }

  /**
   * Required. Knowledge document with candidate smart compose suggestions.
   * Format: `projects//locations//knowledgeBases//documents/`.
   */
  set candidateDocument(value: string|null) {
    this.Serializable$set('candidateDocument', value);
  }

  get maxResultCount(): number|null {
    return (
        (this.Serializable$has('maxResultCount')) ?
            (this.Serializable$get('maxResultCount')) :
            (null));
  }

  /**
   * Optional. Maximum number of smart compose suggestions to return. If
   * unspecified or zero, defaults to 1.
   */
  set maxResultCount(value: number|null) {
    this.Serializable$set('maxResultCount', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Required. Smart compose model resource name. Format:
   * `projects//conversationModels/`.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  getConstructor(): SerializableCtor<SmartComposeConfig_> {
    return SmartComposeConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['candidateDocument', 'maxResultCount', 'model']};
  }
}

export interface SmartComposeMetricsRecommendedMetrics_Parameters {
  confidenceThreshold?: number|null;
  n?: number|null;
  triggerRate?: number|null;
  recall?: number|null;
}
export class SmartComposeMetricsRecommendedMetrics_ extends Serializable {
  constructor(
      parameters: SmartComposeMetricsRecommendedMetrics_Parameters = {}) {
    super();
    this.Serializable$set(
        'confidenceThreshold',
        (parameters.confidenceThreshold == null) ?
            (null) :
            (parameters.confidenceThreshold));
    this.Serializable$set(
        'n', (parameters.n == null) ? (null) : (parameters.n));
    this.Serializable$set(
        'triggerRate',
        (parameters.triggerRate == null) ? (null) : (parameters.triggerRate));
    this.Serializable$set(
        'recall', (parameters.recall == null) ? (null) : (parameters.recall));
  }

  get confidenceThreshold(): number|null {
    return (
        (this.Serializable$has('confidenceThreshold')) ?
            (this.Serializable$get('confidenceThreshold')) :
            (null));
  }

  /**
   * Confidence threshold of query result.
   */
  set confidenceThreshold(value: number|null) {
    this.Serializable$set('confidenceThreshold', value);
  }

  get n(): number|null {
    return (
        (this.Serializable$has('n')) ? (this.Serializable$get('n')) : (null));
  }

  /**
   * Number of retrieved smart compose answers. For example, when `n` is 3, this
   * evaluation contains metrics for when Dialogflow retrieves 3 smart compose
   * answers with the model.
   */
  set n(value: number|null) {
    this.Serializable$set('n', value);
  }

  get recall(): number|null {
    return (
        (this.Serializable$has('recall')) ? (this.Serializable$get('recall')) :
                                            (null));
  }

  /**
   * Defined as `number of queries whose top n smart compose answers have at
   * least one exact match as the real reply's prefix` divided by `number of
   * queries with at least one smart compose answer`. Value ranges from 0.0
   * to 1.0 inclusive.
   */
  set recall(value: number|null) {
    this.Serializable$set('recall', value);
  }

  get triggerRate(): number|null {
    return (
        (this.Serializable$has('triggerRate')) ?
            (this.Serializable$get('triggerRate')) :
            (null));
  }

  /**
   * Trigger rate. Defined as `number of responses which contains non-empty
   * suggestions(the score of the suggestion is higher than threshold)` divided
   * by the number of requests.
   */
  set triggerRate(value: number|null) {
    this.Serializable$set('triggerRate', value);
  }

  getConstructor(): SerializableCtor<SmartComposeMetricsRecommendedMetrics_> {
    return SmartComposeMetricsRecommendedMetrics_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['confidenceThreshold', 'n', 'recall', 'triggerRate']};
  }
}

export interface SmartComposeMetricsTopNMetrics_Parameters {
  n?: number|null;
  recall?: number|null;
  confidenceThreshold?: number|null;
}
export class SmartComposeMetricsTopNMetrics_ extends Serializable {
  constructor(parameters: SmartComposeMetricsTopNMetrics_Parameters = {}) {
    super();
    this.Serializable$set(
        'n', (parameters.n == null) ? (null) : (parameters.n));
    this.Serializable$set(
        'recall', (parameters.recall == null) ? (null) : (parameters.recall));
    this.Serializable$set(
        'confidenceThreshold',
        (parameters.confidenceThreshold == null) ?
            (null) :
            (parameters.confidenceThreshold));
  }

  get confidenceThreshold(): number|null {
    return (
        (this.Serializable$has('confidenceThreshold')) ?
            (this.Serializable$get('confidenceThreshold')) :
            (null));
  }

  /**
   * Confidence threshold of query result.
   */
  set confidenceThreshold(value: number|null) {
    this.Serializable$set('confidenceThreshold', value);
  }

  get n(): number|null {
    return (
        (this.Serializable$has('n')) ? (this.Serializable$get('n')) : (null));
  }

  /**
   * Number of retrieved smart compose answers. For example, when `n` is 3, this
   * evaluation contains metrics for when Dialogflow retrieves 3 smart compose
   * answers with the model.
   */
  set n(value: number|null) {
    this.Serializable$set('n', value);
  }

  get recall(): number|null {
    return (
        (this.Serializable$has('recall')) ? (this.Serializable$get('recall')) :
                                            (null));
  }

  /**
   * Defined as `number of queries whose top n smart compose answers have at
   * least one exact match as the real reply's prefix` divided by `number of
   * queries with at least one smart compose answer`. Value ranges from 0.0
   * to 1.0 inclusive.
   */
  set recall(value: number|null) {
    this.Serializable$set('recall', value);
  }

  getConstructor(): SerializableCtor<SmartComposeMetricsTopNMetrics_> {
    return SmartComposeMetricsTopNMetrics_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['confidenceThreshold', 'n', 'recall']};
  }
}

export interface SmartComposeMetricsTriggerRate_Parameters {
  confidenceThreshold?: number|null;
  triggerRate?: number|null;
}
export class SmartComposeMetricsTriggerRate_ extends Serializable {
  constructor(parameters: SmartComposeMetricsTriggerRate_Parameters = {}) {
    super();
    this.Serializable$set(
        'confidenceThreshold',
        (parameters.confidenceThreshold == null) ?
            (null) :
            (parameters.confidenceThreshold));
    this.Serializable$set(
        'triggerRate',
        (parameters.triggerRate == null) ? (null) : (parameters.triggerRate));
  }

  get confidenceThreshold(): number|null {
    return (
        (this.Serializable$has('confidenceThreshold')) ?
            (this.Serializable$get('confidenceThreshold')) :
            (null));
  }

  /**
   * Confidence threshold of query result.
   */
  set confidenceThreshold(value: number|null) {
    this.Serializable$set('confidenceThreshold', value);
  }

  get triggerRate(): number|null {
    return (
        (this.Serializable$has('triggerRate')) ?
            (this.Serializable$get('triggerRate')) :
            (null));
  }

  /**
   * Trigger rate. Defined as `number of responses which contains non-empty
   * suggestions(the score of the suggestion is higher than threshold)` divided
   * by the number of requests.
   */
  set triggerRate(value: number|null) {
    this.Serializable$set('triggerRate', value);
  }

  getConstructor(): SerializableCtor<SmartComposeMetricsTriggerRate_> {
    return SmartComposeMetricsTriggerRate_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['confidenceThreshold', 'triggerRate']};
  }
}

export interface SmartComposeMetrics_Parameters {
  topNMetrics?: Array<SmartComposeMetricsTopNMetrics_>|null;
  conversationCount?: string|null;
  triggerRates?: Array<SmartComposeMetricsTriggerRate_>|null;
  recommendedMetrics?: SmartComposeMetricsRecommendedMetrics_|null;
}
export class SmartComposeMetrics_ extends Serializable {
  constructor(parameters: SmartComposeMetrics_Parameters = {}) {
    super();
    this.Serializable$set(
        'topNMetrics',
        (parameters.topNMetrics == null) ? (null) : (parameters.topNMetrics));
    this.Serializable$set(
        'conversationCount',
        (parameters.conversationCount == null) ?
            (null) :
            (parameters.conversationCount));
    this.Serializable$set(
        'triggerRates',
        (parameters.triggerRates == null) ? (null) : (parameters.triggerRates));
    this.Serializable$set(
        'recommendedMetrics',
        (parameters.recommendedMetrics == null) ?
            (null) :
            (parameters.recommendedMetrics));
  }

  get conversationCount(): string|null {
    return (
        (this.Serializable$has('conversationCount')) ?
            (this.Serializable$get('conversationCount')) :
            (null));
  }

  /**
   * Total number of conversations used to generate this metric.
   */
  set conversationCount(value: string|null) {
    this.Serializable$set('conversationCount', value);
  }

  get recommendedMetrics(): SmartComposeMetricsRecommendedMetrics_|null {
    return (
        (this.Serializable$has('recommendedMetrics')) ?
            (this.Serializable$get('recommendedMetrics')) :
            (null));
  }

  /**
   * Recommended confidence threshold and its corresponding metrics.
   */
  set recommendedMetrics(value: SmartComposeMetricsRecommendedMetrics_|null) {
    this.Serializable$set('recommendedMetrics', value);
  }

  get topNMetrics(): Array<SmartComposeMetricsTopNMetrics_>|null {
    return (
        (this.Serializable$has('topNMetrics')) ?
            (this.Serializable$get('topNMetrics')) :
            (null));
  }

  /**
   * Metrics of top n smart compose answers, sorted by TopNMetric.n.
   */
  set topNMetrics(value: Array<SmartComposeMetricsTopNMetrics_>|null) {
    this.Serializable$set('topNMetrics', value);
  }

  get triggerRates(): Array<SmartComposeMetricsTriggerRate_>|null {
    return (
        (this.Serializable$has('triggerRates')) ?
            (this.Serializable$get('triggerRates')) :
            (null));
  }

  /**
   * Trigger rates with different confidence thresholds.
   */
  set triggerRates(value: Array<SmartComposeMetricsTriggerRate_>|null) {
    this.Serializable$set('triggerRates', value);
  }

  getConstructor(): SerializableCtor<SmartComposeMetrics_> {
    return SmartComposeMetrics_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'topNMetrics': SmartComposeMetricsTopNMetrics_,
        'triggerRates': SmartComposeMetricsTriggerRate_
      },
      keys: [
        'conversationCount', 'recommendedMetrics', 'topNMetrics', 'triggerRates'
      ],
      objects: {'recommendedMetrics': SmartComposeMetricsRecommendedMetrics_}
    };
  }
}

export interface SmartComposeModelMetadata_Parameters {
  modelType?: string|null;
  internalTrainingFlags?: string|null;
  targetRole?: SmartComposeModelMetadata_TargetRole|null;
  associatedAllowlistInfo?: AssociateAllowlistInfo_|null;
  trainingModelType?: SmartComposeModelMetadata_TrainingModelType|null;
}
export class SmartComposeModelMetadata_ extends Serializable {
  constructor(parameters: SmartComposeModelMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'modelType',
        (parameters.modelType == null) ? (null) : (parameters.modelType));
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
    this.Serializable$set(
        'targetRole',
        (parameters.targetRole == null) ? (null) : (parameters.targetRole));
    this.Serializable$set(
        'associatedAllowlistInfo',
        (parameters.associatedAllowlistInfo == null) ?
            (null) :
            (parameters.associatedAllowlistInfo));
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
  }

  static get TargetRole(): ISmartComposeModelMetadata_TargetRoleEnum {
    return SmartComposeModelMetadata_TargetRoleEnum;
  }

  static get TrainingModelType():
      ISmartComposeModelMetadata_TrainingModelTypeEnum {
    return SmartComposeModelMetadata_TrainingModelTypeEnum;
  }

  get associatedAllowlistInfo(): AssociateAllowlistInfo_|null {
    return (
        (this.Serializable$has('associatedAllowlistInfo')) ?
            (this.Serializable$get('associatedAllowlistInfo')) :
            (null));
  }

  /**
   * Optional. The information of the allowlist associated with the conversation
   * model. This field is internal and only to be accessed by the web console.
   */
  set associatedAllowlistInfo(value: AssociateAllowlistInfo_|null) {
    this.Serializable$set('associatedAllowlistInfo', value);
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  /**
   * Optional. Internal testing only. The text format proto of
   * InternalTrainingFlags defined in
   * cloud/ai/contactcenter/knowledge/proto/conversation_model.proto.
   */
  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get modelType(): string|null {
    return (
        (this.Serializable$has('modelType')) ?
            (this.Serializable$get('modelType')) :
            (null));
  }

  /**
   * Optional. DEPRECATED: Prefer defining and accessing the model type using
   * training_model_type. Type of the smart compose model. The available values
   * are: * `smart-compose-dual-encoder-model-1` - (default) Smart Compose Dual
   * Encoder Model.
   */
  set modelType(value: string|null) {
    this.Serializable$set('modelType', value);
  }

  get targetRole(): SmartComposeModelMetadata_TargetRole|null {
    return (
        (this.Serializable$has('targetRole')) ?
            (this.Serializable$get('targetRole')) :
            (null));
  }

  /**
   * Optional. The role of the targeted user that the smart compose language
   * model is trained for. If not provided, 'HUMAN_AGENT' is used.
   */
  set targetRole(value: SmartComposeModelMetadata_TargetRole|null) {
    this.Serializable$set('targetRole', value);
  }

  get trainingModelType(): SmartComposeModelMetadata_TrainingModelType|null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Optional. Type of the smart compose model. If not provided, model_type is
   * used.
   */
  set trainingModelType(value: SmartComposeModelMetadata_TrainingModelType|
                        null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor(): SerializableCtor<SmartComposeModelMetadata_> {
    return SmartComposeModelMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'targetRole': SmartComposeModelMetadata_TargetRoleEnum,
        'trainingModelType': SmartComposeModelMetadata_TrainingModelTypeEnum
      },
      keys: [
        'associatedAllowlistInfo', 'internalTrainingFlags', 'modelType',
        'targetRole', 'trainingModelType'
      ],
      objects: {'associatedAllowlistInfo': AssociateAllowlistInfo_}
    };
  }
}

export interface SmartMessagingEntryInfoClusterInfo_Parameters {
  id?: string|null;
  messageCount?: number|null;
  isRepresentative?: boolean|null;
}
export class SmartMessagingEntryInfoClusterInfo_ extends Serializable {
  constructor(parameters: SmartMessagingEntryInfoClusterInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'messageCount',
        (parameters.messageCount == null) ? (null) : (parameters.messageCount));
    this.Serializable$set(
        'isRepresentative',
        (parameters.isRepresentative == null) ? (null) :
                                                (parameters.isRepresentative));
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Id of the smart messaging candidate cluster.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get isRepresentative(): boolean|null {
    return (
        (this.Serializable$has('isRepresentative')) ?
            (this.Serializable$get('isRepresentative')) :
            (null));
  }

  /**
   * Whether the current message is representative of the cluster.
   */
  set isRepresentative(value: boolean|null) {
    this.Serializable$set('isRepresentative', value);
  }

  get messageCount(): number|null {
    return (
        (this.Serializable$has('messageCount')) ?
            (this.Serializable$get('messageCount')) :
            (null));
  }

  /**
   * Size of the candidate cluster in messages.
   */
  set messageCount(value: number|null) {
    this.Serializable$set('messageCount', value);
  }

  getConstructor(): SerializableCtor<SmartMessagingEntryInfoClusterInfo_> {
    return SmartMessagingEntryInfoClusterInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['id', 'isRepresentative', 'messageCount']};
  }
}

export interface SmartMessagingEntryInfo_Parameters {
  occurrenceCount?: number|null;
  creationMethod?: SmartMessagingEntryInfo_CreationMethod|null;
  clusterInfo?: SmartMessagingEntryInfoClusterInfo_|null;
  subphraseMessages?: Array<string>|null;
}
export class SmartMessagingEntryInfo_ extends Serializable {
  constructor(parameters: SmartMessagingEntryInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'occurrenceCount',
        (parameters.occurrenceCount == null) ? (null) :
                                               (parameters.occurrenceCount));
    this.Serializable$set(
        'creationMethod',
        (parameters.creationMethod == null) ? (null) :
                                              (parameters.creationMethod));
    this.Serializable$set(
        'clusterInfo',
        (parameters.clusterInfo == null) ? (null) : (parameters.clusterInfo));
    this.Serializable$set(
        'subphraseMessages',
        (parameters.subphraseMessages == null) ?
            (null) :
            (parameters.subphraseMessages));
  }

  static get CreationMethod(): ISmartMessagingEntryInfo_CreationMethodEnum {
    return SmartMessagingEntryInfo_CreationMethodEnum;
  }

  get clusterInfo(): SmartMessagingEntryInfoClusterInfo_|null {
    return (
        (this.Serializable$has('clusterInfo')) ?
            (this.Serializable$get('clusterInfo')) :
            (null));
  }

  /**
   * Output only. Information about the cluster the smart messaging entry is
   * assigned to.
   */
  set clusterInfo(value: SmartMessagingEntryInfoClusterInfo_|null) {
    this.Serializable$set('clusterInfo', value);
  }

  get creationMethod(): SmartMessagingEntryInfo_CreationMethod|null {
    return (
        (this.Serializable$has('creationMethod')) ?
            (this.Serializable$get('creationMethod')) :
            (null));
  }

  /**
   * Output only. Method of how the smart messaging entry was created. When the
   * smart messaging entry was generated from GenerateDocument, the value is
   * AUTOMATIC; when the entry was manually added through
   * CreateSmartMessagingEntry, the value is MANUAL.
   */
  set creationMethod(value: SmartMessagingEntryInfo_CreationMethod|null) {
    this.Serializable$set('creationMethod', value);
  }

  get occurrenceCount(): number|null {
    return (
        (this.Serializable$has('occurrenceCount')) ?
            (this.Serializable$get('occurrenceCount')) :
            (null));
  }

  /**
   * The number of times an entry's message text has been uttered
   */
  set occurrenceCount(value: number|null) {
    this.Serializable$set('occurrenceCount', value);
  }

  get subphraseMessages(): Array<string>|null {
    return (
        (this.Serializable$has('subphraseMessages')) ?
            (this.Serializable$get('subphraseMessages')) :
            (null));
  }

  /**
   * Output only. List of raw texts of smart messages entries which are
   * subphrases of the current entry, they would be automatically approved or
   * blocked for serving when the same operation is applied to the current
   * entry.
   */
  set subphraseMessages(value: Array<string>|null) {
    this.Serializable$set('subphraseMessages', value);
  }

  getConstructor(): SerializableCtor<SmartMessagingEntryInfo_> {
    return SmartMessagingEntryInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'creationMethod': SmartMessagingEntryInfo_CreationMethodEnum},
      keys: [
        'clusterInfo', 'creationMethod', 'occurrenceCount', 'subphraseMessages'
      ],
      objects: {'clusterInfo': SmartMessagingEntryInfoClusterInfo_}
    };
  }
}

export interface SmartMessagingEntry_Parameters {
  name?: string|null;
  rawText?: string|null;
  state?: SmartMessagingEntry_State|null;
  messageInfo?: SmartMessagingEntryInfo_|null;
  customTag?: string|null;
  etag?: string|null;
  createTime?: string|null;
  deleteTime?: string|null;
  updateTime?: string|null;
  uid?: string|null;
  annotations?: ApiClientObjectMap<string>|null;
}
export class SmartMessagingEntry_ extends Serializable {
  constructor(parameters: SmartMessagingEntry_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'rawText',
        (parameters.rawText == null) ? (null) : (parameters.rawText));
    this.Serializable$set(
        'state', (parameters.state == null) ? (null) : (parameters.state));
    this.Serializable$set(
        'messageInfo',
        (parameters.messageInfo == null) ? (null) : (parameters.messageInfo));
    this.Serializable$set(
        'customTag',
        (parameters.customTag == null) ? (null) : (parameters.customTag));
    this.Serializable$set(
        'etag', (parameters.etag == null) ? (null) : (parameters.etag));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'deleteTime',
        (parameters.deleteTime == null) ? (null) : (parameters.deleteTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'uid', (parameters.uid == null) ? (null) : (parameters.uid));
    this.Serializable$set(
        'annotations',
        (parameters.annotations == null) ? (null) : (parameters.annotations));
  }

  static get State(): ISmartMessagingEntry_StateEnum {
    return SmartMessagingEntry_StateEnum;
  }

  get annotations(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('annotations')) ?
            (this.Serializable$get('annotations')) :
            (null));
  }

  /**
   * User annotations. Allows users to store small amounts of arbitrary data.
   * See https://google.aip.dev/128#annotations
   */
  set annotations(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('annotations', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. Timestamp when Smart Messaging Entry was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get customTag(): string|null {
    return (
        (this.Serializable$has('customTag')) ?
            (this.Serializable$get('customTag')) :
            (null));
  }

  /**
   * User provided tag/label for the Smart Messaging Entry.
   */
  set customTag(value: string|null) {
    this.Serializable$set('customTag', value);
  }

  get deleteTime(): string|null {
    return (
        (this.Serializable$has('deleteTime')) ?
            (this.Serializable$get('deleteTime')) :
            (null));
  }

  /**
   * Output only. Timestamp when Smart Messaging entry was deleted.
   */
  set deleteTime(value: string|null) {
    this.Serializable$set('deleteTime', value);
  }

  get etag(): string|null {
    return (
        (this.Serializable$has('etag')) ? (this.Serializable$get('etag')) :
                                          (null));
  }

  set etag(value: string|null) {
    this.Serializable$set('etag', value);
  }

  get messageInfo(): SmartMessagingEntryInfo_|null {
    return (
        (this.Serializable$has('messageInfo')) ?
            (this.Serializable$get('messageInfo')) :
            (null));
  }

  /**
   * Output only. Metadata of the message entry
   */
  set messageInfo(value: SmartMessagingEntryInfo_|null) {
    this.Serializable$set('messageInfo', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * The unique identifier of this message entry. Required for
   * [Documents.GetSmartMessagingEntry], [Documents.CreateSmartMessagingEntry],
   * [Documents.UpdateSmartMessagingEntry], and
   * [Documents.DeleteSmartMessagingEntry]. Format:
   * `projects//knowledgeBases//documents//smartMessagingEntries/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get rawText(): string|null {
    return (
        (this.Serializable$has('rawText')) ?
            (this.Serializable$get('rawText')) :
            (null));
  }

  /**
   * Required. The raw text of the message.
   */
  set rawText(value: string|null) {
    this.Serializable$set('rawText', value);
  }

  get state(): SmartMessagingEntry_State|null {
    return (
        (this.Serializable$has('state')) ? (this.Serializable$get('state')) :
                                           (null));
  }

  /**
   * Required. Smart Messaging Entry's enabled/disabled state.
   */
  set state(value: SmartMessagingEntry_State|null) {
    this.Serializable$set('state', value);
  }

  get uid(): string|null {
    return (
        (this.Serializable$has('uid')) ? (this.Serializable$get('uid')) :
                                         (null));
  }

  /**
   * Output only. System-generated unique identifier for the resource.
   */
  set uid(value: string|null) {
    this.Serializable$set('uid', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. Timestamp when Smart Messaging entry was last updated.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  getConstructor(): SerializableCtor<SmartMessagingEntry_> {
    return SmartMessagingEntry_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'state': SmartMessagingEntry_StateEnum},
      keys: [
        'annotations', 'createTime', 'customTag', 'deleteTime', 'etag',
        'messageInfo', 'name', 'rawText', 'state', 'uid', 'updateTime'
      ],
      objectMaps: {
        'annotations': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'messageInfo': SmartMessagingEntryInfo_}
    };
  }
}

export interface SmartReplyAnswer_Parameters {
  reply?: string|null;
  confidence?: number|null;
  personalized?: boolean|null;
  answerRecord?: string|null;
}
export class SmartReplyAnswer_ extends Serializable {
  constructor(parameters: SmartReplyAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'reply', (parameters.reply == null) ? (null) : (parameters.reply));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'personalized',
        (parameters.personalized == null) ? (null) : (parameters.personalized));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * Smart reply confidence. The system's confidence score that this reply is a
   * good match for this conversation, as a value from 0.0 (completely
   * uncertain) to 1.0 (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get personalized(): boolean|null {
    return (
        (this.Serializable$has('personalized')) ?
            (this.Serializable$get('personalized')) :
            (null));
  }

  /**
   * Whether the ranking of the smart reply answer is boosted by personalization
   * using the external agent id.
   */
  set personalized(value: boolean|null) {
    this.Serializable$set('personalized', value);
  }

  get reply(): string|null {
    return (
        (this.Serializable$has('reply')) ? (this.Serializable$get('reply')) :
                                           (null));
  }

  /**
   * The content of the reply.
   */
  set reply(value: string|null) {
    this.Serializable$set('reply', value);
  }

  getConstructor(): SerializableCtor<SmartReplyAnswer_> {
    return SmartReplyAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['answerRecord', 'confidence', 'personalized', 'reply']};
  }
}

export interface SmartReplyConfig_Parameters {
  model?: string|null;
  candidateDocument?: string|null;
  maxReplies?: number|null;
}
export class SmartReplyConfig_ extends Serializable {
  constructor(parameters: SmartReplyConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'candidateDocument',
        (parameters.candidateDocument == null) ?
            (null) :
            (parameters.candidateDocument));
    this.Serializable$set(
        'maxReplies',
        (parameters.maxReplies == null) ? (null) : (parameters.maxReplies));
  }

  get candidateDocument(): string|null {
    return (
        (this.Serializable$has('candidateDocument')) ?
            (this.Serializable$get('candidateDocument')) :
            (null));
  }

  /**
   * Required. Knowledge document with candidate reply responses. Format:
   * `projects//locations//knowledgeBases//documents/`.
   */
  set candidateDocument(value: string|null) {
    this.Serializable$set('candidateDocument', value);
  }

  get maxReplies(): number|null {
    return (
        (this.Serializable$has('maxReplies')) ?
            (this.Serializable$get('maxReplies')) :
            (null));
  }

  /**
   * Optional. Maximum number of smart reply options to return. If unspecified
   * or zero, defaults to 3.
   */
  set maxReplies(value: number|null) {
    this.Serializable$set('maxReplies', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Required. Smart reply model resource name. Format:
   * `projects//conversationModels/`.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  getConstructor(): SerializableCtor<SmartReplyConfig_> {
    return SmartReplyConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['candidateDocument', 'maxReplies', 'model']};
  }
}

export interface SmartReplyMetricsTopNMetrics_Parameters {
  n?: number|null;
  recall?: number|null;
}
export class SmartReplyMetricsTopNMetrics_ extends Serializable {
  constructor(parameters: SmartReplyMetricsTopNMetrics_Parameters = {}) {
    super();
    this.Serializable$set(
        'n', (parameters.n == null) ? (null) : (parameters.n));
    this.Serializable$set(
        'recall', (parameters.recall == null) ? (null) : (parameters.recall));
  }

  get n(): number|null {
    return (
        (this.Serializable$has('n')) ? (this.Serializable$get('n')) : (null));
  }

  /**
   * Number of retrieved smart replies. For example, when `n` is 3, this
   * evaluation contains metrics for when Dialogflow retrieves 3 smart replies
   * with the model.
   */
  set n(value: number|null) {
    this.Serializable$set('n', value);
  }

  get recall(): number|null {
    return (
        (this.Serializable$has('recall')) ? (this.Serializable$get('recall')) :
                                            (null));
  }

  /**
   * Defined as `number of queries whose top n smart replies have at least one
   * similar (token match similarity above the defined threshold) reply as the
   * real reply` divided by `number of queries with at least one smart reply`.
   * Value ranges from 0.0 to 1.0 inclusive.
   */
  set recall(value: number|null) {
    this.Serializable$set('recall', value);
  }

  getConstructor(): SerializableCtor<SmartReplyMetricsTopNMetrics_> {
    return SmartReplyMetricsTopNMetrics_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['n', 'recall']};
  }
}

export interface SmartReplyMetrics_Parameters {
  allowlistCoverage?: number|null;
  topNMetrics?: Array<SmartReplyMetricsTopNMetrics_>|null;
  conversationCount?: string|null;
}
export class SmartReplyMetrics_ extends Serializable {
  constructor(parameters: SmartReplyMetrics_Parameters = {}) {
    super();
    this.Serializable$set(
        'allowlistCoverage',
        (parameters.allowlistCoverage == null) ?
            (null) :
            (parameters.allowlistCoverage));
    this.Serializable$set(
        'topNMetrics',
        (parameters.topNMetrics == null) ? (null) : (parameters.topNMetrics));
    this.Serializable$set(
        'conversationCount',
        (parameters.conversationCount == null) ?
            (null) :
            (parameters.conversationCount));
  }

  get allowlistCoverage(): number|null {
    return (
        (this.Serializable$has('allowlistCoverage')) ?
            (this.Serializable$get('allowlistCoverage')) :
            (null));
  }

  /**
   * Percentage of target participant messages in the evaluation dataset for
   * which similar messages have appeared at least once in the allowlist. Should
   * be [0, 1].
   */
  set allowlistCoverage(value: number|null) {
    this.Serializable$set('allowlistCoverage', value);
  }

  get conversationCount(): string|null {
    return (
        (this.Serializable$has('conversationCount')) ?
            (this.Serializable$get('conversationCount')) :
            (null));
  }

  /**
   * Total number of conversations used to generate this metric.
   */
  set conversationCount(value: string|null) {
    this.Serializable$set('conversationCount', value);
  }

  get topNMetrics(): Array<SmartReplyMetricsTopNMetrics_>|null {
    return (
        (this.Serializable$has('topNMetrics')) ?
            (this.Serializable$get('topNMetrics')) :
            (null));
  }

  /**
   * Metrics of top n smart replies, sorted by TopNMetric.n.
   */
  set topNMetrics(value: Array<SmartReplyMetricsTopNMetrics_>|null) {
    this.Serializable$set('topNMetrics', value);
  }

  getConstructor(): SerializableCtor<SmartReplyMetrics_> {
    return SmartReplyMetrics_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'topNMetrics': SmartReplyMetricsTopNMetrics_},
      keys: ['allowlistCoverage', 'conversationCount', 'topNMetrics']
    };
  }
}

export interface SmartReplyModelMetadata_Parameters {
  modelType?: string|null;
  internalTrainingFlags?: string|null;
  targetRole?: SmartReplyModelMetadata_TargetRole|null;
  associatedAllowlistInfo?: AssociateAllowlistInfo_|null;
  trainingModelType?: SmartReplyModelMetadata_TrainingModelType|null;
}
export class SmartReplyModelMetadata_ extends Serializable {
  constructor(parameters: SmartReplyModelMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'modelType',
        (parameters.modelType == null) ? (null) : (parameters.modelType));
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
    this.Serializable$set(
        'targetRole',
        (parameters.targetRole == null) ? (null) : (parameters.targetRole));
    this.Serializable$set(
        'associatedAllowlistInfo',
        (parameters.associatedAllowlistInfo == null) ?
            (null) :
            (parameters.associatedAllowlistInfo));
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
  }

  static get TargetRole(): ISmartReplyModelMetadata_TargetRoleEnum {
    return SmartReplyModelMetadata_TargetRoleEnum;
  }

  static get TrainingModelType():
      ISmartReplyModelMetadata_TrainingModelTypeEnum {
    return SmartReplyModelMetadata_TrainingModelTypeEnum;
  }

  get associatedAllowlistInfo(): AssociateAllowlistInfo_|null {
    return (
        (this.Serializable$has('associatedAllowlistInfo')) ?
            (this.Serializable$get('associatedAllowlistInfo')) :
            (null));
  }

  /**
   * Optional. The information of the allowlist associated with the conversation
   * model. This field is internal and only to be accessed by the web console.
   */
  set associatedAllowlistInfo(value: AssociateAllowlistInfo_|null) {
    this.Serializable$set('associatedAllowlistInfo', value);
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  /**
   * Internal testing only. The text format proto of InternalTrainingFlags
   * defined in cloud/ai/contactcenter/knowledge/proto/conversation_model.proto.
   */
  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get modelType(): string|null {
    return (
        (this.Serializable$has('modelType')) ?
            (this.Serializable$get('modelType')) :
            (null));
  }

  /**
   * DEPRECATED: Prefer defining and accessing the model type using
   * training_model_type. Type of the smart reply model. The available values
   * are: * `smart-reply-dual-encoder-model-1` - (default) Smart Reply Dual
   * Encoder Model.
   */
  set modelType(value: string|null) {
    this.Serializable$set('modelType', value);
  }

  get targetRole(): SmartReplyModelMetadata_TargetRole|null {
    return (
        (this.Serializable$has('targetRole')) ?
            (this.Serializable$get('targetRole')) :
            (null));
  }

  /**
   * The role of the targeted user that the smart reply language model is
   * trained for. If not provided, 'HUMAN_AGENT' is used.
   */
  set targetRole(value: SmartReplyModelMetadata_TargetRole|null) {
    this.Serializable$set('targetRole', value);
  }

  get trainingModelType(): SmartReplyModelMetadata_TrainingModelType|null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Optional. Type of the smart reply model. If not provided, model_type is
   * used.
   */
  set trainingModelType(value: SmartReplyModelMetadata_TrainingModelType|null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor(): SerializableCtor<SmartReplyModelMetadata_> {
    return SmartReplyModelMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'targetRole': SmartReplyModelMetadata_TargetRoleEnum,
        'trainingModelType': SmartReplyModelMetadata_TrainingModelTypeEnum
      },
      keys: [
        'associatedAllowlistInfo', 'internalTrainingFlags', 'modelType',
        'targetRole', 'trainingModelType'
      ],
      objects: {'associatedAllowlistInfo': AssociateAllowlistInfo_}
    };
  }
}

export interface SpecialistPool_Parameters {
  name?: string|null;
  displayName?: string|null;
  specialists?: Array<Specialist_>|null;
}
export class SpecialistPool_ extends Serializable {
  constructor(parameters: SpecialistPool_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'specialists',
        (parameters.specialists == null) ? (null) : (parameters.specialists));
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Required. Human readable name for this SpecialistPool. Maximum of 64
   * characters.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. SpecialistPool resource name. Format:
   * `projects//locations//specialistPools/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get specialists(): Array<Specialist_>|null {
    return (
        (this.Serializable$has('specialists')) ?
            (this.Serializable$get('specialists')) :
            (null));
  }

  /**
   * Output only. List of specialists in the SpecialistPool.
   */
  set specialists(value: Array<Specialist_>|null) {
    this.Serializable$set('specialists', value);
  }

  getConstructor(): SerializableCtor<SpecialistPool_> {
    return SpecialistPool_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'specialists': Specialist_},
      keys: ['displayName', 'name', 'specialists']
    };
  }
}

export interface Specialist_Parameters {
  emailAddress?: string|null;
  role?: Specialist_Role|null;
}
export class Specialist_ extends Serializable {
  constructor(parameters: Specialist_Parameters = {}) {
    super();
    this.Serializable$set(
        'emailAddress',
        (parameters.emailAddress == null) ? (null) : (parameters.emailAddress));
    this.Serializable$set(
        'role', (parameters.role == null) ? (null) : (parameters.role));
  }

  static get Role(): ISpecialist_RoleEnum {
    return Specialist_RoleEnum;
  }

  get emailAddress(): string|null {
    return (
        (this.Serializable$has('emailAddress')) ?
            (this.Serializable$get('emailAddress')) :
            (null));
  }

  /**
   * Required. Email account that the specialist is expected to use when
   * labeling data.
   */
  set emailAddress(value: string|null) {
    this.Serializable$set('emailAddress', value);
  }

  get role(): Specialist_Role|null {
    return (
        (this.Serializable$has('role')) ? (this.Serializable$get('role')) :
                                          (null));
  }

  /**
   * Required. Role of the user - can be either WORKER or MANAGER. If you want
   * to create a specialist with both roles, please use same email address to
   * create two Specialist objects in the request, one with WORKER role, and the
   * other with MANAGER role. A specialist must have at least one role.
   */
  set role(value: Specialist_Role|null) {
    this.Serializable$set('role', value);
  }

  getConstructor(): SerializableCtor<Specialist_> {
    return Specialist_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'role': Specialist_RoleEnum},
      keys: ['emailAddress', 'role']
    };
  }
}

export interface SpeechContext_Parameters {
  phrases?: Array<string>|null;
  boost?: number|null;
}
export class SpeechContext_ extends Serializable {
  constructor(parameters: SpeechContext_Parameters = {}) {
    super();
    this.Serializable$set(
        'phrases',
        (parameters.phrases == null) ? (null) : (parameters.phrases));
    this.Serializable$set(
        'boost', (parameters.boost == null) ? (null) : (parameters.boost));
  }

  get boost(): number|null {
    return (
        (this.Serializable$has('boost')) ? (this.Serializable$get('boost')) :
                                           (null));
  }

  /**
   * Optional. Boost for this context compared to other contexts: * If the boost
   * is positive, Dialogflow will increase the probability that the phrases in
   * this context are recognized over similar sounding phrases. * If the boost
   * is unspecified or non-positive, Dialogflow will not apply any boost.
   * Dialogflow recommends that you use boosts in the range (0, 20] and that you
   * find a value that fits your use case with binary search.
   */
  set boost(value: number|null) {
    this.Serializable$set('boost', value);
  }

  get phrases(): Array<string>|null {
    return (
        (this.Serializable$has('phrases')) ?
            (this.Serializable$get('phrases')) :
            (null));
  }

  /**
   * Optional. A list of strings containing words and phrases that the speech
   * recognizer should recognize with higher likelihood. This list can be used
   * to: * improve accuracy for words and phrases you expect the user to say,
   * e.g. typical commands for your Dialogflow agent * add additional words to
   * the speech recognizer vocabulary * ... See the [Cloud Speech
   * documentation](https://cloud.google.com/speech-to-text/quotas) for usage
   * limits.
   */
  set phrases(value: Array<string>|null) {
    this.Serializable$set('phrases', value);
  }

  getConstructor(): SerializableCtor<SpeechContext_> {
    return SpeechContext_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['boost', 'phrases']};
  }
}

export interface SpeechToTextConfig_Parameters {
  speechModelVariant?: SpeechToTextConfig_SpeechModelVariant|null;
  model?: string|null;
  alternativeLanguageCodes?: Array<string>|null;
  phraseSets?: Array<string>|null;
  useLongFormModel?: boolean|null;
}
export class SpeechToTextConfig_ extends Serializable {
  constructor(parameters: SpeechToTextConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'speechModelVariant',
        (parameters.speechModelVariant == null) ?
            (null) :
            (parameters.speechModelVariant));
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'alternativeLanguageCodes',
        (parameters.alternativeLanguageCodes == null) ?
            (null) :
            (parameters.alternativeLanguageCodes));
    this.Serializable$set(
        'phraseSets',
        (parameters.phraseSets == null) ? (null) : (parameters.phraseSets));
    this.Serializable$set(
        'useLongFormModel',
        (parameters.useLongFormModel == null) ? (null) :
                                                (parameters.useLongFormModel));
  }

  static get SpeechModelVariant(): ISpeechToTextConfig_SpeechModelVariantEnum {
    return SpeechToTextConfig_SpeechModelVariantEnum;
  }

  get alternativeLanguageCodes(): Array<string>|null {
    return (
        (this.Serializable$has('alternativeLanguageCodes')) ?
            (this.Serializable$get('alternativeLanguageCodes')) :
            (null));
  }

  /**
   * Defines the list of other language codes in addition to the one provided by
   * the conversation profile that may be detected as the language code for the
   * utterances over the conversation. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes.
   */
  set alternativeLanguageCodes(value: Array<string>|null) {
    this.Serializable$set('alternativeLanguageCodes', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Which Speech model to select. Select the model best suited to your domain
   * to get best results. If a model is not explicitly specified, then a default
   * model is used. Refer to [Cloud Speech API
   * documentation](https://cloud.google.com/speech-to-text/docs/basics#select-model)
   * for more details.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  get phraseSets(): Array<string>|null {
    return (
        (this.Serializable$has('phraseSets')) ?
            (this.Serializable$get('phraseSets')) :
            (null));
  }

  /**
   * List of names of Cloud Speech phrase sets that are used for transcription.
   */
  set phraseSets(value: Array<string>|null) {
    this.Serializable$set('phraseSets', value);
  }

  get speechModelVariant(): SpeechToTextConfig_SpeechModelVariant|null {
    return (
        (this.Serializable$has('speechModelVariant')) ?
            (this.Serializable$get('speechModelVariant')) :
            (null));
  }

  /**
   * The speech model used in speech to text.
   * `SPEECH_MODEL_VARIANT_UNSPECIFIED`, `USE_BEST_AVAILABLE` will be treated as
   * `USE_ENHANCED`. It can be overridden in AnalyzeContentRequest and
   * StreamingAnalyzeContentRequest request. If enhanced model variant is
   * specified and an enhanced version of the specified model for the language
   * does not exist, then it would emit an error.
   */
  set speechModelVariant(value: SpeechToTextConfig_SpeechModelVariant|null) {
    this.Serializable$set('speechModelVariant', value);
  }

  get useLongFormModel(): boolean|null {
    return (
        (this.Serializable$has('useLongFormModel')) ?
            (this.Serializable$get('useLongFormModel')) :
            (null));
  }

  /**
   * Use long-form speech model, which disables single utterance option in STT
   * and process each detected utterance individually.
   */
  set useLongFormModel(value: boolean|null) {
    this.Serializable$set('useLongFormModel', value);
  }

  getConstructor(): SerializableCtor<SpeechToTextConfig_> {
    return SpeechToTextConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'speechModelVariant': SpeechToTextConfig_SpeechModelVariantEnum},
      keys: [
        'alternativeLanguageCodes', 'model', 'phraseSets', 'speechModelVariant',
        'useLongFormModel'
      ]
    };
  }
}

export interface SpeechWordInfo_Parameters {
  word?: string|null;
  startOffset?: string|null;
  endOffset?: string|null;
  confidence?: number|null;
}
export class SpeechWordInfo_ extends Serializable {
  constructor(parameters: SpeechWordInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'word', (parameters.word == null) ? (null) : (parameters.word));
    this.Serializable$set(
        'startOffset',
        (parameters.startOffset == null) ? (null) : (parameters.startOffset));
    this.Serializable$set(
        'endOffset',
        (parameters.endOffset == null) ? (null) : (parameters.endOffset));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The Speech confidence between 0.0 and 1.0 for this word. A higher number
   * indicates an estimated greater likelihood that the recognized word is
   * correct. The default of 0.0 is a sentinel value indicating that confidence
   * was not set. This field is not guaranteed to be fully stable over time for
   * the same audio input. Users should also not rely on it to always be
   * provided.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get endOffset(): string|null {
    return (
        (this.Serializable$has('endOffset')) ?
            (this.Serializable$get('endOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio that corresponds to the
   * end of the spoken word. This is an experimental feature and the accuracy of
   * the time offset can vary.
   */
  set endOffset(value: string|null) {
    this.Serializable$set('endOffset', value);
  }

  get startOffset(): string|null {
    return (
        (this.Serializable$has('startOffset')) ?
            (this.Serializable$get('startOffset')) :
            (null));
  }

  /**
   * Time offset relative to the beginning of the audio that corresponds to the
   * start of the spoken word. This is an experimental feature and the accuracy
   * of the time offset can vary.
   */
  set startOffset(value: string|null) {
    this.Serializable$set('startOffset', value);
  }

  get word(): string|null {
    return (
        (this.Serializable$has('word')) ? (this.Serializable$get('word')) :
                                          (null));
  }

  /**
   * The word this info is for.
   */
  set word(value: string|null) {
    this.Serializable$set('word', value);
  }

  getConstructor(): SerializableCtor<SpeechWordInfo_> {
    return SpeechWordInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['confidence', 'endOffset', 'startOffset', 'word']};
  }
}

export interface SpellGrammarCorrectionAnswer_Parameters {
  suggestion?: string|null;
  confidence?: number|null;
  answerRecord?: string|null;
  charStart?: number|null;
  charLength?: number|null;
  type?: SpellGrammarCorrectionAnswer_Type|null;
}
export class SpellGrammarCorrectionAnswer_ extends Serializable {
  constructor(parameters: SpellGrammarCorrectionAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'suggestion',
        (parameters.suggestion == null) ? (null) : (parameters.suggestion));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
    this.Serializable$set(
        'charStart',
        (parameters.charStart == null) ? (null) : (parameters.charStart));
    this.Serializable$set(
        'charLength',
        (parameters.charLength == null) ? (null) : (parameters.charLength));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
  }

  static get Type(): ISpellGrammarCorrectionAnswer_TypeEnum {
    return SpellGrammarCorrectionAnswer_TypeEnum;
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of \"projects//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get charLength(): number|null {
    return (
        (this.Serializable$has('charLength')) ?
            (this.Serializable$get('charLength')) :
            (null));
  }

  /**
   * UTF-8 character length of the substring that the current spell grammar
   * correction suggestion applies to. This can be zero if the suggestion is for
   * insertion.
   */
  set charLength(value: number|null) {
    this.Serializable$set('charLength', value);
  }

  get charStart(): number|null {
    return (
        (this.Serializable$has('charStart')) ?
            (this.Serializable$get('charStart')) :
            (null));
  }

  /**
   * Zero-based starting UTF-8 character position of the current spell grammar
   * correction applies.
   */
  set charStart(value: number|null) {
    this.Serializable$set('charStart', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * Spell & grammar correction confidence. The system's confidence score that
   * this reply is a good match for this conversation, as a value from 0.0
   * (completely uncertain) to 1.0 (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get suggestion(): string|null {
    return (
        (this.Serializable$has('suggestion')) ?
            (this.Serializable$get('suggestion')) :
            (null));
  }

  /**
   * The content of the spell grammar correction.
   */
  set suggestion(value: string|null) {
    this.Serializable$set('suggestion', value);
  }

  get type(): SpellGrammarCorrectionAnswer_Type|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * The type of the grammar spell correction suggestion.
   */
  set type(value: SpellGrammarCorrectionAnswer_Type|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<SpellGrammarCorrectionAnswer_> {
    return SpellGrammarCorrectionAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'type': SpellGrammarCorrectionAnswer_TypeEnum},
      keys: [
        'answerRecord', 'charLength', 'charStart', 'confidence', 'suggestion',
        'type'
      ]
    };
  }
}

export interface StartIntegrationRequest_Parameters {
  integration?: Integration_|null;
  agentId?: string|null;
  updateMask?: string|null;
}
export class StartIntegrationRequest_ extends Serializable {
  constructor(parameters: StartIntegrationRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'integration',
        (parameters.integration == null) ? (null) : (parameters.integration));
    this.Serializable$set(
        'agentId',
        (parameters.agentId == null) ? (null) : (parameters.agentId));
    this.Serializable$set(
        'updateMask',
        (parameters.updateMask == null) ? (null) : (parameters.updateMask));
  }

  get agentId(): string|null {
    return (
        (this.Serializable$has('agentId')) ?
            (this.Serializable$get('agentId')) :
            (null));
  }

  /**
   * Legacy internal field for Dialogflow Console to pass into and Bots
   * controller service to look up data in Dialogflow Spanner.
   */
  set agentId(value: string|null) {
    this.Serializable$set('agentId', value);
  }

  get integration(): Integration_|null {
    return (
        (this.Serializable$has('integration')) ?
            (this.Serializable$get('integration')) :
            (null));
  }

  /**
   * Required. Integration to start.
   */
  set integration(value: Integration_|null) {
    this.Serializable$set('integration', value);
  }

  get updateMask(): string|null {
    return (
        (this.Serializable$has('updateMask')) ?
            (this.Serializable$get('updateMask')) :
            (null));
  }

  /**
   * Optional. The mask to control which fields get updated.
   */
  set updateMask(value: string|null) {
    this.Serializable$set('updateMask', value);
  }

  getConstructor(): SerializableCtor<StartIntegrationRequest_> {
    return StartIntegrationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['agentId', 'integration', 'updateMask'],
      objects: {'integration': Integration_}
    };
  }
}

export interface StartIntegrationResponseWorkplacePayload_Parameters {
  redirectUri?: string|null;
}
export class StartIntegrationResponseWorkplacePayload_ extends Serializable {
  constructor(
      parameters: StartIntegrationResponseWorkplacePayload_Parameters = {}) {
    super();
    this.Serializable$set(
        'redirectUri',
        (parameters.redirectUri == null) ? (null) : (parameters.redirectUri));
  }

  get redirectUri(): string|null {
    return (
        (this.Serializable$has('redirectUri')) ?
            (this.Serializable$get('redirectUri')) :
            (null));
  }

  /**
   * The URI that the user will be redirected to.
   */
  set redirectUri(value: string|null) {
    this.Serializable$set('redirectUri', value);
  }

  getConstructor():
      SerializableCtor<StartIntegrationResponseWorkplacePayload_> {
    return StartIntegrationResponseWorkplacePayload_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['redirectUri']};
  }
}

export interface StartIntegrationResponse_Parameters {
  workplacePayload?: StartIntegrationResponseWorkplacePayload_|null;
}
export class StartIntegrationResponse_ extends Serializable {
  constructor(parameters: StartIntegrationResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'workplacePayload',
        (parameters.workplacePayload == null) ? (null) :
                                                (parameters.workplacePayload));
  }

  get workplacePayload(): StartIntegrationResponseWorkplacePayload_|null {
    return (
        (this.Serializable$has('workplacePayload')) ?
            (this.Serializable$get('workplacePayload')) :
            (null));
  }

  /**
   * Optional. The response payload for the Workplace integration.
   */
  set workplacePayload(value: StartIntegrationResponseWorkplacePayload_|null) {
    this.Serializable$set('workplacePayload', value);
  }

  getConstructor(): SerializableCtor<StartIntegrationResponse_> {
    return StartIntegrationResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['workplacePayload'],
      objects: {'workplacePayload': StartIntegrationResponseWorkplacePayload_}
    };
  }
}

export interface StopIntegrationRequest_Parameters {
  agentId?: string|null;
}
export class StopIntegrationRequest_ extends Serializable {
  constructor(parameters: StopIntegrationRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'agentId',
        (parameters.agentId == null) ? (null) : (parameters.agentId));
  }

  get agentId(): string|null {
    return (
        (this.Serializable$has('agentId')) ?
            (this.Serializable$get('agentId')) :
            (null));
  }

  /**
   * Legacy internal field for Dialogflow Console to pass into and Bots
   * controller service to look up data in Dialogflow Spanner.
   */
  set agentId(value: string|null) {
    this.Serializable$set('agentId', value);
  }

  getConstructor(): SerializableCtor<StopIntegrationRequest_> {
    return StopIntegrationRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['agentId']};
  }
}

export interface StopIntegrationResponse_Parameters {
  redirectUri?: string|null;
}
export class StopIntegrationResponse_ extends Serializable {
  constructor(parameters: StopIntegrationResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'redirectUri',
        (parameters.redirectUri == null) ? (null) : (parameters.redirectUri));
  }

  get redirectUri(): string|null {
    return (
        (this.Serializable$has('redirectUri')) ?
            (this.Serializable$get('redirectUri')) :
            (null));
  }

  /**
   * The URI that the user will be redirected to.
   */
  set redirectUri(value: string|null) {
    this.Serializable$set('redirectUri', value);
  }

  getConstructor(): SerializableCtor<StopIntegrationResponse_> {
    return StopIntegrationResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['redirectUri']};
  }
}

export interface StreamingListUpcomingCallCompanionEventsResponse_Parameters {
  callCompanionConversationEvent?: CallCompanionConversationEvent_|null;
}
export class StreamingListUpcomingCallCompanionEventsResponse_ extends
    Serializable {
  constructor(
      parameters:
          StreamingListUpcomingCallCompanionEventsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'callCompanionConversationEvent',
        (parameters.callCompanionConversationEvent == null) ?
            (null) :
            (parameters.callCompanionConversationEvent));
  }

  get callCompanionConversationEvent(): CallCompanionConversationEvent_|null {
    return (
        (this.Serializable$has('callCompanionConversationEvent')) ?
            (this.Serializable$get('callCompanionConversationEvent')) :
            (null));
  }

  /**
   * Incoming Call Companion conversation event.
   */
  set callCompanionConversationEvent(value: CallCompanionConversationEvent_|
                                     null) {
    this.Serializable$set('callCompanionConversationEvent', value);
  }

  getConstructor():
      SerializableCtor<StreamingListUpcomingCallCompanionEventsResponse_> {
    return StreamingListUpcomingCallCompanionEventsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['callCompanionConversationEvent'],
      objects:
          {'callCompanionConversationEvent': CallCompanionConversationEvent_}
    };
  }
}

export interface SubAgent_Parameters {
  project?: string|null;
  displayName?: string|null;
  environment?: string|null;
  knowledgeConnectorOption?: SubAgent_KnowledgeConnectorOption|null;
  priority?: number|null;
}
export class SubAgent_ extends Serializable {
  constructor(parameters: SubAgent_Parameters = {}) {
    super();
    this.Serializable$set(
        'project',
        (parameters.project == null) ? (null) : (parameters.project));
    this.Serializable$set(
        'displayName',
        (parameters.displayName == null) ? (null) : (parameters.displayName));
    this.Serializable$set(
        'environment',
        (parameters.environment == null) ? (null) : (parameters.environment));
    this.Serializable$set(
        'knowledgeConnectorOption',
        (parameters.knowledgeConnectorOption == null) ?
            (null) :
            (parameters.knowledgeConnectorOption));
    this.Serializable$set(
        'priority',
        (parameters.priority == null) ? (null) : (parameters.priority));
  }

  static get KnowledgeConnectorOption():
      ISubAgent_KnowledgeConnectorOptionEnum {
    return SubAgent_KnowledgeConnectorOptionEnum;
  }

  get displayName(): string|null {
    return (
        (this.Serializable$has('displayName')) ?
            (this.Serializable$get('displayName')) :
            (null));
  }

  /**
   * Output only. The display name of this agent, needed by Dialogflow console.
   */
  set displayName(value: string|null) {
    this.Serializable$set('displayName', value);
  }

  get environment(): string|null {
    return (
        (this.Serializable$has('environment')) ?
            (this.Serializable$get('environment')) :
            (null));
  }

  /**
   * Optional. The unique identifier (`environment name` in dialogflow console)
   * of this sub-agent environment. Assumes draft environment if `environment`
   * is not set.
   */
  set environment(value: string|null) {
    this.Serializable$set('environment', value);
  }

  get knowledgeConnectorOption(): SubAgent_KnowledgeConnectorOption|null {
    return (
        (this.Serializable$has('knowledgeConnectorOption')) ?
            (this.Serializable$get('knowledgeConnectorOption')) :
            (null));
  }

  /**
   * Optional. Indicates whether the Knowledge connector of this sub agent (if
   * enabled) should be considered when querying the mega agent. If not set,
   * defaults to INCLUDE_KNOWLEDGE_CONNECTOR.
   */
  set knowledgeConnectorOption(value: SubAgent_KnowledgeConnectorOption|null) {
    this.Serializable$set('knowledgeConnectorOption', value);
  }

  get priority(): number|null {
    return (
        (this.Serializable$has('priority')) ?
            (this.Serializable$get('priority')) :
            (null));
  }

  /**
   * Optional. Priority of the sub-agent, used to determine which intent to
   * return if multiple intents from different sub-agents get selected. Intents
   * from a sub-agent of a higher priority will more likely be returned.
   */
  set priority(value: number|null) {
    this.Serializable$set('priority', value);
  }

  get project(): string|null {
    return (
        (this.Serializable$has('project')) ?
            (this.Serializable$get('project')) :
            (null));
  }

  /**
   * Required. The project of this agent. Format: `projects/` or
   * `projects//locations/`.
   */
  set project(value: string|null) {
    this.Serializable$set('project', value);
  }

  getConstructor(): SerializableCtor<SubAgent_> {
    return SubAgent_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums:
          {'knowledgeConnectorOption': SubAgent_KnowledgeConnectorOptionEnum},
      keys: [
        'displayName', 'environment', 'knowledgeConnectorOption', 'priority',
        'project'
      ]
    };
  }
}

export interface SuggestArticlesRequest_Parameters {
  latestMessage?: string|null;
  contextSize?: number|null;
  assistQueryParams?: AssistQueryParameters_|null;
}
export class SuggestArticlesRequest_ extends Serializable {
  constructor(parameters: SuggestArticlesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
    this.Serializable$set(
        'assistQueryParams',
        (parameters.assistQueryParams == null) ?
            (null) :
            (parameters.assistQueryParams));
  }

  get assistQueryParams(): AssistQueryParameters_|null {
    return (
        (this.Serializable$has('assistQueryParams')) ?
            (this.Serializable$get('assistQueryParams')) :
            (null));
  }

  /**
   * Optional. Parameters for a human assist query.
   */
  set assistQueryParams(value: AssistQueryParameters_|null) {
    this.Serializable$set('assistQueryParams', value);
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Optional. Max number of messages prior to and including latest_message to
   * use as context when compiling the suggestion. By default 20 and at most 50.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * Optional. The name of the latest conversation message to compile suggestion
   * for. If empty, it will be the latest message of the conversation. Format:
   * `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestArticlesRequest_> {
    return SuggestArticlesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['assistQueryParams', 'contextSize', 'latestMessage'],
      objects: {'assistQueryParams': AssistQueryParameters_}
    };
  }
}

export interface SuggestArticlesResponse_Parameters {
  articleAnswers?: Array<ArticleAnswer_>|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestArticlesResponse_ extends Serializable {
  constructor(parameters: SuggestArticlesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'articleAnswers',
        (parameters.articleAnswers == null) ? (null) :
                                              (parameters.articleAnswers));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get articleAnswers(): Array<ArticleAnswer_>|null {
    return (
        (this.Serializable$has('articleAnswers')) ?
            (this.Serializable$get('articleAnswers')) :
            (null));
  }

  /**
   * Output only. Articles ordered by score in descending order.
   */
  set articleAnswers(value: Array<ArticleAnswer_>|null) {
    this.Serializable$set('articleAnswers', value);
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including latest_message to compile the
   * suggestion. It may be smaller than the SuggestArticlesResponse.context_size
   * field in the request if there aren't that many messages in the
   * conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used to compile suggestion for.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestArticlesResponse_> {
    return SuggestArticlesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'articleAnswers': ArticleAnswer_},
      keys: ['articleAnswers', 'contextSize', 'latestMessage']
    };
  }
}

export interface SuggestConversationGuidancesRequest_Parameters {
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestConversationGuidancesRequest_ extends Serializable {
  constructor(parameters: SuggestConversationGuidancesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Optional. Max number of messages prior to and including [latest_message] to
   * use as context when compiling the suggestion. By default 5 and at most 50.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * Optional. The name of the latest conversation message to compile suggestion
   * for. If empty, it will be the latest message of the conversation. Format:
   * `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestConversationGuidancesRequest_> {
    return SuggestConversationGuidancesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['contextSize', 'latestMessage']};
  }
}

export interface SuggestConversationGuidancesResponse_Parameters {
  conversationGuidanceAnswers?: Array<ConversationGuidanceAnswer_>|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestConversationGuidancesResponse_ extends Serializable {
  constructor(
      parameters: SuggestConversationGuidancesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationGuidanceAnswers',
        (parameters.conversationGuidanceAnswers == null) ?
            (null) :
            (parameters.conversationGuidanceAnswers));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including latest_message to compile the
   * suggestion. It may be smaller than the
   * SuggestConversationGuidancesRequest.context_size field in the request if
   * there aren't that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get conversationGuidanceAnswers(): Array<ConversationGuidanceAnswer_>|null {
    return (
        (this.Serializable$has('conversationGuidanceAnswers')) ?
            (this.Serializable$get('conversationGuidanceAnswers')) :
            (null));
  }

  /**
   * Multiple answers provided by conversation guidance service. The order is
   * based on the rank of the model.
   */
  set conversationGuidanceAnswers(value: Array<ConversationGuidanceAnswer_>|
                                  null) {
    this.Serializable$set('conversationGuidanceAnswers', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used to compile suggestion for.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestConversationGuidancesResponse_> {
    return SuggestConversationGuidancesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'conversationGuidanceAnswers': ConversationGuidanceAnswer_},
      keys: ['contextSize', 'conversationGuidanceAnswers', 'latestMessage']
    };
  }
}

export interface SuggestConversationKeyMomentsRequest_Parameters {
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestConversationKeyMomentsRequest_ extends Serializable {
  constructor(
      parameters: SuggestConversationKeyMomentsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Max number of messages prior to and including [latest_message] to use as
   * context when compiling the suggestion. By default 500 and at most 1000.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used as context for compiling
   * suggestions. If empty, the latest message of the conversation will be used.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestConversationKeyMomentsRequest_> {
    return SuggestConversationKeyMomentsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['contextSize', 'latestMessage']};
  }
}

export interface SuggestConversationKeyMomentsResponseKeyMoment_Parameters {
  message?: string|null;
  confidence?: number|null;
  answerRecord?: string|null;
}
export class SuggestConversationKeyMomentsResponseKeyMoment_ extends
    Serializable {
  constructor(
      parameters:
          SuggestConversationKeyMomentsResponseKeyMoment_Parameters = {}) {
    super();
    this.Serializable$set(
        'message',
        (parameters.message == null) ? (null) : (parameters.message));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of answer record, in the format of \"projects//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * Confidence of the key moment.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get message(): string|null {
    return (
        (this.Serializable$has('message')) ?
            (this.Serializable$get('message')) :
            (null));
  }

  /**
   * Required. Message id of the key moment.
   */
  set message(value: string|null) {
    this.Serializable$set('message', value);
  }

  getConstructor():
      SerializableCtor<SuggestConversationKeyMomentsResponseKeyMoment_> {
    return SuggestConversationKeyMomentsResponseKeyMoment_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['answerRecord', 'confidence', 'message']};
  }
}

export interface SuggestConversationKeyMomentsResponse_Parameters {
  keyMoments?: Array<SuggestConversationKeyMomentsResponseKeyMoment_>|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestConversationKeyMomentsResponse_ extends Serializable {
  constructor(
      parameters: SuggestConversationKeyMomentsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'keyMoments',
        (parameters.keyMoments == null) ? (null) : (parameters.keyMoments));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including last_conversation_message used to
   * compile the suggestions. It may be smaller than the
   * CompileSuggestionRequest.context_messages_count field in the request if
   * there weren't that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get keyMoments():
      Array<SuggestConversationKeyMomentsResponseKeyMoment_>|null {
    return (
        (this.Serializable$has('keyMoments')) ?
            (this.Serializable$get('keyMoments')) :
            (null));
  }

  /**
   * Selected key moments.
   */
  set keyMoments(value: Array<SuggestConversationKeyMomentsResponseKeyMoment_>|
                 null) {
    this.Serializable$set('keyMoments', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used as context for compiling
   * suggestions. Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestConversationKeyMomentsResponse_> {
    return SuggestConversationKeyMomentsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'keyMoments': SuggestConversationKeyMomentsResponseKeyMoment_},
      keys: ['contextSize', 'keyMoments', 'latestMessage']
    };
  }
}

export interface SuggestConversationSummaryRequest_Parameters {
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestConversationSummaryRequest_ extends Serializable {
  constructor(parameters: SuggestConversationSummaryRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Max number of messages prior to and including [latest_message] to use as
   * context when compiling the suggestion. By default 500 and at most 1000.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used as context for compiling
   * suggestion. If empty, the latest message of the conversation will be used.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestConversationSummaryRequest_> {
    return SuggestConversationSummaryRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['contextSize', 'latestMessage']};
  }
}

export interface SuggestConversationSummaryResponseSummary_Parameters {
  text?: string|null;
  textSections?: ApiClientObjectMap<string>|null;
  confidence?: number|null;
  answerRecord?: string|null;
}
export class SuggestConversationSummaryResponseSummary_ extends Serializable {
  constructor(
      parameters: SuggestConversationSummaryResponseSummary_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'textSections',
        (parameters.textSections == null) ? (null) : (parameters.textSections));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * The name of the answer record. Format: \"projects//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The confidence score of the summary.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The summary content that is concatenated into one string.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  get textSections(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('textSections')) ?
            (this.Serializable$get('textSections')) :
            (null));
  }

  /**
   * The summary content that is divided into sections. The key is the section's
   * name and the value is the section's content. There is no specific format
   * for the key or value.
   */
  set textSections(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('textSections', value);
  }

  getConstructor():
      SerializableCtor<SuggestConversationSummaryResponseSummary_> {
    return SuggestConversationSummaryResponseSummary_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['answerRecord', 'confidence', 'text', 'textSections'],
      objectMaps: {
        'textSections': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface SuggestConversationSummaryResponse_Parameters {
  summary?: SuggestConversationSummaryResponseSummary_|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestConversationSummaryResponse_ extends Serializable {
  constructor(parameters: SuggestConversationSummaryResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'summary',
        (parameters.summary == null) ? (null) : (parameters.summary));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including last_conversation_message used to
   * compile the suggestion. It may be smaller than the
   * SuggestSummaryRequest.context_size field in the request if there weren't
   * that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used as context for compiling
   * suggestion. Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  get summary(): SuggestConversationSummaryResponseSummary_|null {
    return (
        (this.Serializable$has('summary')) ?
            (this.Serializable$get('summary')) :
            (null));
  }

  /**
   * Generated summary.
   */
  set summary(value: SuggestConversationSummaryResponseSummary_|null) {
    this.Serializable$set('summary', value);
  }

  getConstructor(): SerializableCtor<SuggestConversationSummaryResponse_> {
    return SuggestConversationSummaryResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['contextSize', 'latestMessage', 'summary'],
      objects: {'summary': SuggestConversationSummaryResponseSummary_}
    };
  }
}

export interface SuggestDialogflowAssistsRequest_Parameters {
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestDialogflowAssistsRequest_ extends Serializable {
  constructor(parameters: SuggestDialogflowAssistsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Optional. Max number of messages prior to and including [latest_message] to
   * use as context when compiling the suggestion. By default 20 and at most 50.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * Optional. The name of the latest conversation message to compile suggestion
   * for. If empty, it will be the latest message of the conversation. Format:
   * `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestDialogflowAssistsRequest_> {
    return SuggestDialogflowAssistsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['contextSize', 'latestMessage']};
  }
}

export interface SuggestDialogflowAssistsResponsePartialFailures_Parameters {
  parameterFailures?: ApiClientObjectMap<string>|null;
}
export class SuggestDialogflowAssistsResponsePartialFailures_ extends
    Serializable {
  constructor(
      parameters:
          SuggestDialogflowAssistsResponsePartialFailures_Parameters = {}) {
    super();
    this.Serializable$set(
        'parameterFailures',
        (parameters.parameterFailures == null) ?
            (null) :
            (parameters.parameterFailures));
  }

  get parameterFailures(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('parameterFailures')) ?
            (this.Serializable$get('parameterFailures')) :
            (null));
  }

  /**
   * Invalid parameter values from parameters[]. The key is the parameter name;
   * The value is the failure message.
   */
  set parameterFailures(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('parameterFailures', value);
  }

  getConstructor():
      SerializableCtor<SuggestDialogflowAssistsResponsePartialFailures_> {
    return SuggestDialogflowAssistsResponsePartialFailures_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['parameterFailures'],
      objectMaps: {
        'parameterFailures': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface SuggestDialogflowAssistsResponse_Parameters {
  dialogflowAssistAnswers?: Array<DialogflowAssistAnswer_>|null;
  parameterSuggestions?: ParameterSuggestions_|null;
  latestMessage?: string|null;
  contextSize?: number|null;
  partialFailures?: Array<SuggestDialogflowAssistsResponsePartialFailures_>|
      null;
}
export class SuggestDialogflowAssistsResponse_ extends Serializable {
  constructor(parameters: SuggestDialogflowAssistsResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'dialogflowAssistAnswers',
        (parameters.dialogflowAssistAnswers == null) ?
            (null) :
            (parameters.dialogflowAssistAnswers));
    this.Serializable$set(
        'parameterSuggestions',
        (parameters.parameterSuggestions == null) ?
            (null) :
            (parameters.parameterSuggestions));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
    this.Serializable$set(
        'partialFailures',
        (parameters.partialFailures == null) ? (null) :
                                               (parameters.partialFailures));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including latest_message to compile the
   * suggestion. It may be smaller than the
   * SuggestDialogflowAssistsRequest.context_size field in the request if there
   * aren't that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get dialogflowAssistAnswers(): Array<DialogflowAssistAnswer_>|null {
    return (
        (this.Serializable$has('dialogflowAssistAnswers')) ?
            (this.Serializable$get('dialogflowAssistAnswers')) :
            (null));
  }

  /**
   * Output only. Multiple reply options provided by Dialogflow assist service.
   * The order is based on the rank of the model prediction.
   */
  set dialogflowAssistAnswers(value: Array<DialogflowAssistAnswer_>|null) {
    this.Serializable$set('dialogflowAssistAnswers', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used to suggest answer. Format:
   * `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  get parameterSuggestions(): ParameterSuggestions_|null {
    return (
        (this.Serializable$has('parameterSuggestions')) ?
            (this.Serializable$get('parameterSuggestions')) :
            (null));
  }

  /**
   * Parameter suggestions generated from conversation.
   */
  set parameterSuggestions(value: ParameterSuggestions_|null) {
    this.Serializable$set('parameterSuggestions', value);
  }

  get partialFailures():
      Array<SuggestDialogflowAssistsResponsePartialFailures_>|null {
    return (
        (this.Serializable$has('partialFailures')) ?
            (this.Serializable$get('partialFailures')) :
            (null));
  }

  /**
   * Partial failures are failures that don't fail the whole
   * SuggestDialogflowAssistsResponse, e.g. some input parameter values are
   * invalid.
   */
  set partialFailures(
      value: Array<SuggestDialogflowAssistsResponsePartialFailures_>|null) {
    this.Serializable$set('partialFailures', value);
  }

  getConstructor(): SerializableCtor<SuggestDialogflowAssistsResponse_> {
    return SuggestDialogflowAssistsResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'dialogflowAssistAnswers': DialogflowAssistAnswer_,
        'partialFailures': SuggestDialogflowAssistsResponsePartialFailures_
      },
      keys: [
        'contextSize', 'dialogflowAssistAnswers', 'latestMessage',
        'parameterSuggestions', 'partialFailures'
      ],
      objects: {'parameterSuggestions': ParameterSuggestions_}
    };
  }
}

export interface SuggestFaqAnswersRequest_Parameters {
  latestMessage?: string|null;
  contextSize?: number|null;
  assistQueryParams?: AssistQueryParameters_|null;
}
export class SuggestFaqAnswersRequest_ extends Serializable {
  constructor(parameters: SuggestFaqAnswersRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
    this.Serializable$set(
        'assistQueryParams',
        (parameters.assistQueryParams == null) ?
            (null) :
            (parameters.assistQueryParams));
  }

  get assistQueryParams(): AssistQueryParameters_|null {
    return (
        (this.Serializable$has('assistQueryParams')) ?
            (this.Serializable$get('assistQueryParams')) :
            (null));
  }

  /**
   * Optional. Parameters for a human assist query.
   */
  set assistQueryParams(value: AssistQueryParameters_|null) {
    this.Serializable$set('assistQueryParams', value);
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Optional. Max number of messages prior to and including [latest_message] to
   * use as context when compiling the suggestion. By default 20 and at most 50.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * Optional. The name of the latest conversation message to compile suggestion
   * for. If empty, it will be the latest message of the conversation. Format:
   * `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestFaqAnswersRequest_> {
    return SuggestFaqAnswersRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['assistQueryParams', 'contextSize', 'latestMessage'],
      objects: {'assistQueryParams': AssistQueryParameters_}
    };
  }
}

export interface SuggestFaqAnswersResponse_Parameters {
  faqAnswers?: Array<FaqAnswer_>|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestFaqAnswersResponse_ extends Serializable {
  constructor(parameters: SuggestFaqAnswersResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'faqAnswers',
        (parameters.faqAnswers == null) ? (null) : (parameters.faqAnswers));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including latest_message to compile the
   * suggestion. It may be smaller than the
   * SuggestFaqAnswersRequest.context_size field in the request if there aren't
   * that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get faqAnswers(): Array<FaqAnswer_>|null {
    return (
        (this.Serializable$has('faqAnswers')) ?
            (this.Serializable$get('faqAnswers')) :
            (null));
  }

  /**
   * Output only. Answers extracted from FAQ documents.
   */
  set faqAnswers(value: Array<FaqAnswer_>|null) {
    this.Serializable$set('faqAnswers', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used to compile suggestion for.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestFaqAnswersResponse_> {
    return SuggestFaqAnswersResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'faqAnswers': FaqAnswer_},
      keys: ['contextSize', 'faqAnswers', 'latestMessage']
    };
  }
}

export interface SuggestIssueSmartComposeAnswersRequest_Parameters {
  conversationProfile?: string|null;
  textInput?: TextInput_|null;
}
export class SuggestIssueSmartComposeAnswersRequest_ extends Serializable {
  constructor(
      parameters: SuggestIssueSmartComposeAnswersRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationProfile',
        (parameters.conversationProfile == null) ?
            (null) :
            (parameters.conversationProfile));
    this.Serializable$set(
        'textInput',
        (parameters.textInput == null) ? (null) : (parameters.textInput));
  }

  get conversationProfile(): string|null {
    return (
        (this.Serializable$has('conversationProfile')) ?
            (this.Serializable$get('conversationProfile')) :
            (null));
  }

  /**
   * Required. The Conversation Profile to be used to configure this suggestion.
   * Format: `projects//locations//conversationProfiles/`.
   */
  set conversationProfile(value: string|null) {
    this.Serializable$set('conversationProfile', value);
  }

  get textInput(): TextInput_|null {
    return (
        (this.Serializable$has('textInput')) ?
            (this.Serializable$get('textInput')) :
            (null));
  }

  /**
   * The current natural language text segment used to compile suggestions.
   */
  set textInput(value: TextInput_|null) {
    this.Serializable$set('textInput', value);
  }

  getConstructor(): SerializableCtor<SuggestIssueSmartComposeAnswersRequest_> {
    return SuggestIssueSmartComposeAnswersRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['conversationProfile', 'textInput'],
      objects: {'textInput': TextInput_}
    };
  }
}

export interface SuggestIssueSmartComposeAnswersResponse_Parameters {
  smartComposeAnswers?: Array<SmartComposeAnswer_>|null;
}
export class SuggestIssueSmartComposeAnswersResponse_ extends Serializable {
  constructor(
      parameters: SuggestIssueSmartComposeAnswersResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'smartComposeAnswers',
        (parameters.smartComposeAnswers == null) ?
            (null) :
            (parameters.smartComposeAnswers));
  }

  get smartComposeAnswers(): Array<SmartComposeAnswer_>|null {
    return (
        (this.Serializable$has('smartComposeAnswers')) ?
            (this.Serializable$get('smartComposeAnswers')) :
            (null));
  }

  /**
   * Multiple reply options provided by smart compose service. The order is
   * based on the rank of the model prediction. The maximum number of the
   * returned replies is set in SmartComposeConfig.
   */
  set smartComposeAnswers(value: Array<SmartComposeAnswer_>|null) {
    this.Serializable$set('smartComposeAnswers', value);
  }

  getConstructor(): SerializableCtor<SuggestIssueSmartComposeAnswersResponse_> {
    return SuggestIssueSmartComposeAnswersResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'smartComposeAnswers': SmartComposeAnswer_},
      keys: ['smartComposeAnswers']
    };
  }
}

export interface SuggestIssuesOperationMetadata_Parameters {
  document?: string|null;
  model?: string|null;
  createTime?: string|null;
}
export class SuggestIssuesOperationMetadata_ extends Serializable {
  constructor(parameters: SuggestIssuesOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'document',
        (parameters.document == null) ? (null) : (parameters.document));
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * The time when the operation was submitted.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get document(): string|null {
    return (
        (this.Serializable$has('document')) ?
            (this.Serializable$get('document')) :
            (null));
  }

  /**
   * Document resource used in the request. Format:
   * projects//knowledgeBases//documents/ .
   */
  set document(value: string|null) {
    this.Serializable$set('document', value);
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Model resource used in the request.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  getConstructor(): SerializableCtor<SuggestIssuesOperationMetadata_> {
    return SuggestIssuesOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['createTime', 'document', 'model']};
  }
}

export interface SuggestIssuesRequest_Parameters {
  transcript?: Transcript_|null;
  queryContext?: QueryContext_|null;
}
export class SuggestIssuesRequest_ extends Serializable {
  constructor(parameters: SuggestIssuesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'transcript',
        (parameters.transcript == null) ? (null) : (parameters.transcript));
    this.Serializable$set(
        'queryContext',
        (parameters.queryContext == null) ? (null) : (parameters.queryContext));
  }

  get queryContext(): QueryContext_|null {
    return (
        (this.Serializable$has('queryContext')) ?
            (this.Serializable$get('queryContext')) :
            (null));
  }

  /**
   * Required. Query specific information such as thresholds, the issue
   * documents etc.
   */
  set queryContext(value: QueryContext_|null) {
    this.Serializable$set('queryContext', value);
  }

  get transcript(): Transcript_|null {
    return (
        (this.Serializable$has('transcript')) ?
            (this.Serializable$get('transcript')) :
            (null));
  }

  /**
   * Required. The input transcript for which we return issues. Contains text
   * and other input specific metadata.
   */
  set transcript(value: Transcript_|null) {
    this.Serializable$set('transcript', value);
  }

  getConstructor(): SerializableCtor<SuggestIssuesRequest_> {
    return SuggestIssuesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['queryContext', 'transcript'],
      objects: {'queryContext': QueryContext_, 'transcript': Transcript_}
    };
  }
}

export interface SuggestIssuesResponse_Parameters {
  issues?: Array<Issue_>|null;
}
export class SuggestIssuesResponse_ extends Serializable {
  constructor(parameters: SuggestIssuesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'issues', (parameters.issues == null) ? (null) : (parameters.issues));
  }

  get issues(): Array<Issue_>|null {
    return (
        (this.Serializable$has('issues')) ? (this.Serializable$get('issues')) :
                                            (null));
  }

  /**
   * The Issues assigned for the data in the request.
   */
  set issues(value: Array<Issue_>|null) {
    this.Serializable$set('issues', value);
  }

  getConstructor(): SerializableCtor<SuggestIssuesResponse_> {
    return SuggestIssuesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {arrays: {'issues': Issue_}, keys: ['issues']};
  }
}

export interface SuggestSmartComposeAnswersRequest_Parameters {
  currentTextInput?: TextInput_|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestSmartComposeAnswersRequest_ extends Serializable {
  constructor(parameters: SuggestSmartComposeAnswersRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'currentTextInput',
        (parameters.currentTextInput == null) ? (null) :
                                                (parameters.currentTextInput));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Optional. Max number of messages prior to and including [latest_message] to
   * use as context when compiling the suggestion. By default 20 and at most 50.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get currentTextInput(): TextInput_|null {
    return (
        (this.Serializable$has('currentTextInput')) ?
            (this.Serializable$get('currentTextInput')) :
            (null));
  }

  /**
   * Required. The current natural language text segment to compile suggestion
   * for.
   */
  set currentTextInput(value: TextInput_|null) {
    this.Serializable$set('currentTextInput', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * Optional. The name of the latest conversation message used as context for
   * compiling suggestion. If empty, it will be the latest message of the
   * conversation. Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestSmartComposeAnswersRequest_> {
    return SuggestSmartComposeAnswersRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['contextSize', 'currentTextInput', 'latestMessage'],
      objects: {'currentTextInput': TextInput_}
    };
  }
}

export interface SuggestSmartComposeAnswersResponse_Parameters {
  smartComposeAnswers?: Array<SmartComposeAnswer_>|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestSmartComposeAnswersResponse_ extends Serializable {
  constructor(parameters: SuggestSmartComposeAnswersResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'smartComposeAnswers',
        (parameters.smartComposeAnswers == null) ?
            (null) :
            (parameters.smartComposeAnswers));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including last_conversation_message to
   * compile the suggestion. It may be smaller than the
   * CompileSuggestionRequest.context_messages_count field in the request if
   * there aren't that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used as context for compiling
   * suggestion. Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  get smartComposeAnswers(): Array<SmartComposeAnswer_>|null {
    return (
        (this.Serializable$has('smartComposeAnswers')) ?
            (this.Serializable$get('smartComposeAnswers')) :
            (null));
  }

  /**
   * Multiple reply options provided by smart compose service. The order is
   * based on the rank of the model prediction. The maximum number of the
   * returned replies is set in SmartComposeConfig.
   */
  set smartComposeAnswers(value: Array<SmartComposeAnswer_>|null) {
    this.Serializable$set('smartComposeAnswers', value);
  }

  getConstructor(): SerializableCtor<SuggestSmartComposeAnswersResponse_> {
    return SuggestSmartComposeAnswersResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'smartComposeAnswers': SmartComposeAnswer_},
      keys: ['contextSize', 'latestMessage', 'smartComposeAnswers']
    };
  }
}

export interface SuggestSmartRepliesRequest_Parameters {
  currentTextInput?: TextInput_|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestSmartRepliesRequest_ extends Serializable {
  constructor(parameters: SuggestSmartRepliesRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'currentTextInput',
        (parameters.currentTextInput == null) ? (null) :
                                                (parameters.currentTextInput));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Optional. Max number of messages prior to and including [latest_message] to
   * use as context when compiling the suggestion. By default 20 and at most 50.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get currentTextInput(): TextInput_|null {
    return (
        (this.Serializable$has('currentTextInput')) ?
            (this.Serializable$get('currentTextInput')) :
            (null));
  }

  /**
   * The current natural language text segment to compile suggestion for. This
   * provides a way for user to get follow up smart reply suggestion after a
   * smart reply selection, without sending a text message.
   */
  set currentTextInput(value: TextInput_|null) {
    this.Serializable$set('currentTextInput', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message to compile suggestion for. If
   * empty, it will be the latest message of the conversation. Format:
   * `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor(): SerializableCtor<SuggestSmartRepliesRequest_> {
    return SuggestSmartRepliesRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['contextSize', 'currentTextInput', 'latestMessage'],
      objects: {'currentTextInput': TextInput_}
    };
  }
}

export interface SuggestSmartRepliesResponse_Parameters {
  smartReplyAnswers?: Array<SmartReplyAnswer_>|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestSmartRepliesResponse_ extends Serializable {
  constructor(parameters: SuggestSmartRepliesResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'smartReplyAnswers',
        (parameters.smartReplyAnswers == null) ?
            (null) :
            (parameters.smartReplyAnswers));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including latest_message to compile the
   * suggestion. It may be smaller than the
   * SuggestSmartRepliesRequest.context_size field in the request if there
   * aren't that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used to compile suggestion for.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  get smartReplyAnswers(): Array<SmartReplyAnswer_>|null {
    return (
        (this.Serializable$has('smartReplyAnswers')) ?
            (this.Serializable$get('smartReplyAnswers')) :
            (null));
  }

  /**
   * Output only. Multiple reply options provided by smart reply service. The
   * order is based on the rank of the model prediction. The maximum number of
   * the returned replies is set in SmartReplyConfig.
   */
  set smartReplyAnswers(value: Array<SmartReplyAnswer_>|null) {
    this.Serializable$set('smartReplyAnswers', value);
  }

  getConstructor(): SerializableCtor<SuggestSmartRepliesResponse_> {
    return SuggestSmartRepliesResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'smartReplyAnswers': SmartReplyAnswer_},
      keys: ['contextSize', 'latestMessage', 'smartReplyAnswers']
    };
  }
}

export interface SuggestSpellGrammarCorrectionAnswersRequest_Parameters {
  currentTextInput?: TextInput_|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestSpellGrammarCorrectionAnswersRequest_ extends Serializable {
  constructor(
      parameters: SuggestSpellGrammarCorrectionAnswersRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'currentTextInput',
        (parameters.currentTextInput == null) ? (null) :
                                                (parameters.currentTextInput));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Optional. Max number of messages prior to and including [latest_message] to
   * use as context when compiling the suggestion. By default 20 and at most 50.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get currentTextInput(): TextInput_|null {
    return (
        (this.Serializable$has('currentTextInput')) ?
            (this.Serializable$get('currentTextInput')) :
            (null));
  }

  /**
   * Required. The current natural language text segment to compile suggestion
   * for.
   */
  set currentTextInput(value: TextInput_|null) {
    this.Serializable$set('currentTextInput', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * Optional. The name of the latest conversation message used as context for
   * compiling suggestion. If empty, it will be the latest message of the
   * conversation. Format: `projects//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  getConstructor():
      SerializableCtor<SuggestSpellGrammarCorrectionAnswersRequest_> {
    return SuggestSpellGrammarCorrectionAnswersRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['contextSize', 'currentTextInput', 'latestMessage'],
      objects: {'currentTextInput': TextInput_}
    };
  }
}

export interface SuggestSpellGrammarCorrectionAnswersResponse_Parameters {
  spellGrammarCorrectionAnswers?: Array<SpellGrammarCorrectionAnswer_>|null;
  latestMessage?: string|null;
  contextSize?: number|null;
}
export class SuggestSpellGrammarCorrectionAnswersResponse_ extends
    Serializable {
  constructor(
      parameters:
          SuggestSpellGrammarCorrectionAnswersResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'spellGrammarCorrectionAnswers',
        (parameters.spellGrammarCorrectionAnswers == null) ?
            (null) :
            (parameters.spellGrammarCorrectionAnswers));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
    this.Serializable$set(
        'contextSize',
        (parameters.contextSize == null) ? (null) : (parameters.contextSize));
  }

  get contextSize(): number|null {
    return (
        (this.Serializable$has('contextSize')) ?
            (this.Serializable$get('contextSize')) :
            (null));
  }

  /**
   * Number of messages prior to and including last_conversation_message to
   * compile the suggestion. It may be smaller than the
   * CompileSuggestionRequest.context_messages_count field in the request if
   * there aren't that many messages in the conversation.
   */
  set contextSize(value: number|null) {
    this.Serializable$set('contextSize', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * The name of the latest conversation message used as context for compiling
   * suggestion. Format: `projects//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  get spellGrammarCorrectionAnswers():
      Array<SpellGrammarCorrectionAnswer_>|null {
    return (
        (this.Serializable$has('spellGrammarCorrectionAnswers')) ?
            (this.Serializable$get('spellGrammarCorrectionAnswers')) :
            (null));
  }

  /**
   * Multiple reply options provided by spell & grammar correction service. The
   * order is based on the rank of the model.
   */
  set spellGrammarCorrectionAnswers(value: Array<SpellGrammarCorrectionAnswer_>|
                                    null) {
    this.Serializable$set('spellGrammarCorrectionAnswers', value);
  }

  getConstructor():
      SerializableCtor<SuggestSpellGrammarCorrectionAnswersResponse_> {
    return SuggestSpellGrammarCorrectionAnswersResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'spellGrammarCorrectionAnswers': SpellGrammarCorrectionAnswer_},
      keys: ['contextSize', 'latestMessage', 'spellGrammarCorrectionAnswers']
    };
  }
}

export interface SuggestionArticle_Parameters {
  title?: string|null;
  uri?: string|null;
  snippets?: Array<string>|null;
  confidence?: number|null;
  metadata?: ApiClientObjectMap<string>|null;
  answerRecord?: string|null;
}
export class SuggestionArticle_ extends Serializable {
  constructor(parameters: SuggestionArticle_Parameters = {}) {
    super();
    this.Serializable$set(
        'title', (parameters.title == null) ? (null) : (parameters.title));
    this.Serializable$set(
        'uri', (parameters.uri == null) ? (null) : (parameters.uri));
    this.Serializable$set(
        'snippets',
        (parameters.snippets == null) ? (null) : (parameters.snippets));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * Output only. The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * Output only. Article match confidence. The system's confidence score that
   * this article is a good match for this conversation, as a value from 0.0
   * (completely uncertain) to 1.0 (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get metadata(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Output only. A map that contains metadata about the answer and the document
   * from which it originates.
   */
  set metadata(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('metadata', value);
  }

  get snippets(): Array<string>|null {
    return (
        (this.Serializable$has('snippets')) ?
            (this.Serializable$get('snippets')) :
            (null));
  }

  /**
   * Output only. Article snippets.
   */
  set snippets(value: Array<string>|null) {
    this.Serializable$set('snippets', value);
  }

  get title(): string|null {
    return (
        (this.Serializable$has('title')) ? (this.Serializable$get('title')) :
                                           (null));
  }

  /**
   * Output only. The article title.
   */
  set title(value: string|null) {
    this.Serializable$set('title', value);
  }

  get uri(): string|null {
    return (
        (this.Serializable$has('uri')) ? (this.Serializable$get('uri')) :
                                         (null));
  }

  /**
   * Output only. The article URI.
   */
  set uri(value: string|null) {
    this.Serializable$set('uri', value);
  }

  getConstructor(): SerializableCtor<SuggestionArticle_> {
    return SuggestionArticle_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'answerRecord', 'confidence', 'metadata', 'snippets', 'title', 'uri'
      ],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface SuggestionFaqAnswer_Parameters {
  answer?: string|null;
  confidence?: number|null;
  question?: string|null;
  source?: string|null;
  metadata?: ApiClientObjectMap<string>|null;
  answerRecord?: string|null;
}
export class SuggestionFaqAnswer_ extends Serializable {
  constructor(parameters: SuggestionFaqAnswer_Parameters = {}) {
    super();
    this.Serializable$set(
        'answer', (parameters.answer == null) ? (null) : (parameters.answer));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'question',
        (parameters.question == null) ? (null) : (parameters.question));
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
  }

  get answer(): string|null {
    return (
        (this.Serializable$has('answer')) ? (this.Serializable$get('answer')) :
                                            (null));
  }

  /**
   * Output only. The piece of text from the `source` knowledge base document.
   */
  set answer(value: string|null) {
    this.Serializable$set('answer', value);
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * Output only. The name of answer record, in the format of
   * \"projects//locations//answerRecords/\"
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * The system's confidence score that this Knowledge answer is a good match
   * for this conversational query, range from 0.0 (completely uncertain) to 1.0
   * (completely certain).
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get metadata(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Output only. A map that contains metadata about the answer and the document
   * from which it originates.
   */
  set metadata(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('metadata', value);
  }

  get question(): string|null {
    return (
        (this.Serializable$has('question')) ?
            (this.Serializable$get('question')) :
            (null));
  }

  /**
   * Output only. The corresponding FAQ question.
   */
  set question(value: string|null) {
    this.Serializable$set('question', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * Output only. Indicates which Knowledge Document this answer was extracted
   * from. Format: `projects//locations//agent/knowledgeBases//documents/`.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  getConstructor(): SerializableCtor<SuggestionFaqAnswer_> {
    return SuggestionFaqAnswer_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'answer', 'answerRecord', 'confidence', 'metadata', 'question', 'source'
      ],
      objectMaps: {
        'metadata': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface SuggestionFeature_Parameters {
  type?: SuggestionFeature_Type|null;
}
export class SuggestionFeature_ extends Serializable {
  constructor(parameters: SuggestionFeature_Parameters = {}) {
    super();
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
  }

  static get Type(): ISuggestionFeature_TypeEnum {
    return SuggestionFeature_TypeEnum;
  }

  get type(): SuggestionFeature_Type|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * Type of Human Agent Assistant API feature to request.
   */
  set type(value: SuggestionFeature_Type|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<SuggestionFeature_> {
    return SuggestionFeature_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {enums: {'type': SuggestionFeature_TypeEnum}, keys: ['type']};
  }
}

export interface SuggestionInput_Parameters {
  answerRecord?: string|null;
  textOverride?: TextInput_|null;
  cancel?: boolean|null;
  parameters?: ApiClientObjectMap<any>|null;
  action?: SuggestionInput_Action|null;
  intentInput?: IntentInput_|null;
}
export class SuggestionInput_ extends Serializable {
  constructor(parameters: SuggestionInput_Parameters = {}) {
    super();
    this.Serializable$set(
        'answerRecord',
        (parameters.answerRecord == null) ? (null) : (parameters.answerRecord));
    this.Serializable$set(
        'textOverride',
        (parameters.textOverride == null) ? (null) : (parameters.textOverride));
    this.Serializable$set(
        'cancel', (parameters.cancel == null) ? (null) : (parameters.cancel));
    this.Serializable$set(
        'parameters',
        (parameters.parameters == null) ? (null) : (parameters.parameters));
    this.Serializable$set(
        'action', (parameters.action == null) ? (null) : (parameters.action));
    this.Serializable$set(
        'intentInput',
        (parameters.intentInput == null) ? (null) : (parameters.intentInput));
  }

  static get Action(): ISuggestionInput_ActionEnum {
    return SuggestionInput_ActionEnum;
  }

  get action(): SuggestionInput_Action|null {
    return (
        (this.Serializable$has('action')) ? (this.Serializable$get('action')) :
                                            (null));
  }

  /**
   * The type of action that the customer had with the provided suggestion.
   */
  set action(value: SuggestionInput_Action|null) {
    this.Serializable$set('action', value);
  }

  get answerRecord(): string|null {
    return (
        (this.Serializable$has('answerRecord')) ?
            (this.Serializable$get('answerRecord')) :
            (null));
  }

  /**
   * Required. The ID of a suggestion selected by the human agent. The
   * suggestion(s) were generated in a previous call to request Dialogflow
   * assist. The format is: `projects//locations//answerRecords/` where is an
   * alphanumeric string.
   */
  set answerRecord(value: string|null) {
    this.Serializable$set('answerRecord', value);
  }

  get cancel(): boolean|null {
    return (
        (this.Serializable$has('cancel')) ? (this.Serializable$get('cancel')) :
                                            (null));
  }

  /**
   * Deprecated - use SuggestionInput.action instead. If set to true, it means
   * to cancel a previously selected suggestion (as identified by answer_record
   * as well as any action triggered by the suggestion. For example, in
   * Dialogflow assist for v3 answer_record may correspond to a head intent.
   * Selecting the head intent would start a workflow as defined by the
   * corresponding Dialogflow v3 bot. After starting a workflow, the user may
   * choose to abort the workflow by sending a SuggestionInput with the same
   * answer_record, except with the cancel flag set to true.
   */
  set cancel(value: boolean|null) {
    this.Serializable$set('cancel', value);
  }

  get intentInput(): IntentInput_|null {
    return (
        (this.Serializable$has('intentInput')) ?
            (this.Serializable$get('intentInput')) :
            (null));
  }

  /**
   * The intent to be triggered on V3 agent.
   */
  set intentInput(value: IntentInput_|null) {
    this.Serializable$set('intentInput', value);
  }

  get parameters(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('parameters')) ?
            (this.Serializable$get('parameters')) :
            (null));
  }

  /**
   * In Dialogflow assist for v3, the user can submit a form by sending a
   * SuggestionInput. The form is uniquely determined by the answer_record
   * field, which identifies a v3 QueryResult containing the current page. The
   * form parameters are specified via the parameters field. Depending on your
   * protocol or client library language, this is a map, associative array,
   * symbol table, dictionary, or JSON object composed of a collection of
   * (MapKey, MapValue) pairs: - MapKey type: string - MapKey value: parameter
   * name - MapValue type: - If parameter's entity type is a composite entity:
   * map - Else: depending on parameter value type, could be one of string,
   * number, boolean, null, list or map - MapValue value: - If parameter's
   * entity type is a composite entity: map from composite entity property names
   * to property values - Else: parameter value
   */
  set parameters(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('parameters', value);
  }

  get textOverride(): TextInput_|null {
    return (
        (this.Serializable$has('textOverride')) ?
            (this.Serializable$get('textOverride')) :
            (null));
  }

  /**
   * Optional. If the customer edited the suggestion before using it, include
   * the revised text here.
   */
  set textOverride(value: TextInput_|null) {
    this.Serializable$set('textOverride', value);
  }

  getConstructor(): SerializableCtor<SuggestionInput_> {
    return SuggestionInput_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'action': SuggestionInput_ActionEnum},
      keys: [
        'action', 'answerRecord', 'cancel', 'intentInput', 'parameters',
        'textOverride'
      ],
      objectMaps: {
        'parameters': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {'intentInput': IntentInput_, 'textOverride': TextInput_}
    };
  }
}

export interface SuggestionResult_Parameters {
  error?: GoogleRpcStatus|null;
  suggestArticlesResponse?: SuggestArticlesResponse_|null;
  suggestFaqAnswersResponse?: SuggestFaqAnswersResponse_|null;
  suggestSmartRepliesResponse?: SuggestSmartRepliesResponse_|null;
  suggestDialogflowAssistsResponse?: SuggestDialogflowAssistsResponse_|null;
  suggestConversationGuidancesResponse?: SuggestConversationGuidancesResponse_|
      null;
}
export class SuggestionResult_ extends Serializable {
  constructor(parameters: SuggestionResult_Parameters = {}) {
    super();
    this.Serializable$set(
        'error', (parameters.error == null) ? (null) : (parameters.error));
    this.Serializable$set(
        'suggestArticlesResponse',
        (parameters.suggestArticlesResponse == null) ?
            (null) :
            (parameters.suggestArticlesResponse));
    this.Serializable$set(
        'suggestFaqAnswersResponse',
        (parameters.suggestFaqAnswersResponse == null) ?
            (null) :
            (parameters.suggestFaqAnswersResponse));
    this.Serializable$set(
        'suggestSmartRepliesResponse',
        (parameters.suggestSmartRepliesResponse == null) ?
            (null) :
            (parameters.suggestSmartRepliesResponse));
    this.Serializable$set(
        'suggestDialogflowAssistsResponse',
        (parameters.suggestDialogflowAssistsResponse == null) ?
            (null) :
            (parameters.suggestDialogflowAssistsResponse));
    this.Serializable$set(
        'suggestConversationGuidancesResponse',
        (parameters.suggestConversationGuidancesResponse == null) ?
            (null) :
            (parameters.suggestConversationGuidancesResponse));
  }

  get error(): GoogleRpcStatus|null {
    return (
        (this.Serializable$has('error')) ? (this.Serializable$get('error')) :
                                           (null));
  }

  /**
   * Error status if the request failed.
   */
  set error(value: GoogleRpcStatus|null) {
    this.Serializable$set('error', value);
  }

  get suggestArticlesResponse(): SuggestArticlesResponse_|null {
    return (
        (this.Serializable$has('suggestArticlesResponse')) ?
            (this.Serializable$get('suggestArticlesResponse')) :
            (null));
  }

  /**
   * SuggestArticlesResponse if request is for ARTICLE_SUGGESTION.
   */
  set suggestArticlesResponse(value: SuggestArticlesResponse_|null) {
    this.Serializable$set('suggestArticlesResponse', value);
  }

  get suggestConversationGuidancesResponse():
      SuggestConversationGuidancesResponse_|null {
    return (
        (this.Serializable$has('suggestConversationGuidancesResponse')) ?
            (this.Serializable$get('suggestConversationGuidancesResponse')) :
            (null));
  }

  /**
   * SuggestConversationGuidancesResponse if request is for
   * CONVERSATION_GUIDANCE.
   */
  set suggestConversationGuidancesResponse(
      value: SuggestConversationGuidancesResponse_|null) {
    this.Serializable$set('suggestConversationGuidancesResponse', value);
  }

  get suggestDialogflowAssistsResponse(): SuggestDialogflowAssistsResponse_
      |null {
    return (
        (this.Serializable$has('suggestDialogflowAssistsResponse')) ?
            (this.Serializable$get('suggestDialogflowAssistsResponse')) :
            (null));
  }

  /**
   * SuggestDialogflowAssistsResponse if request is for DIALOGFLOW_ASSIST.
   */
  set suggestDialogflowAssistsResponse(value: SuggestDialogflowAssistsResponse_|
                                       null) {
    this.Serializable$set('suggestDialogflowAssistsResponse', value);
  }

  get suggestFaqAnswersResponse(): SuggestFaqAnswersResponse_|null {
    return (
        (this.Serializable$has('suggestFaqAnswersResponse')) ?
            (this.Serializable$get('suggestFaqAnswersResponse')) :
            (null));
  }

  /**
   * SuggestFaqAnswersResponse if request is for FAQ_ANSWER.
   */
  set suggestFaqAnswersResponse(value: SuggestFaqAnswersResponse_|null) {
    this.Serializable$set('suggestFaqAnswersResponse', value);
  }

  get suggestSmartRepliesResponse(): SuggestSmartRepliesResponse_|null {
    return (
        (this.Serializable$has('suggestSmartRepliesResponse')) ?
            (this.Serializable$get('suggestSmartRepliesResponse')) :
            (null));
  }

  /**
   * SuggestSmartRepliesResponse if request is for SMART_REPLY.
   */
  set suggestSmartRepliesResponse(value: SuggestSmartRepliesResponse_|null) {
    this.Serializable$set('suggestSmartRepliesResponse', value);
  }

  getConstructor(): SerializableCtor<SuggestionResult_> {
    return SuggestionResult_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'error', 'suggestArticlesResponse',
        'suggestConversationGuidancesResponse',
        'suggestDialogflowAssistsResponse', 'suggestFaqAnswersResponse',
        'suggestSmartRepliesResponse'
      ],
      objects: {
        'error': GoogleRpcStatus,
        'suggestArticlesResponse': SuggestArticlesResponse_,
        'suggestConversationGuidancesResponse':
            SuggestConversationGuidancesResponse_,
        'suggestDialogflowAssistsResponse': SuggestDialogflowAssistsResponse_,
        'suggestFaqAnswersResponse': SuggestFaqAnswersResponse_,
        'suggestSmartRepliesResponse': SuggestSmartRepliesResponse_
      }
    };
  }
}

export interface Suggestion_Parameters {
  name?: string|null;
  articles?: Array<SuggestionArticle_>|null;
  faqAnswers?: Array<SuggestionFaqAnswer_>|null;
  createTime?: string|null;
  latestMessage?: string|null;
}
export class Suggestion_ extends Serializable {
  constructor(parameters: Suggestion_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'articles',
        (parameters.articles == null) ? (null) : (parameters.articles));
    this.Serializable$set(
        'faqAnswers',
        (parameters.faqAnswers == null) ? (null) : (parameters.faqAnswers));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'latestMessage',
        (parameters.latestMessage == null) ? (null) :
                                             (parameters.latestMessage));
  }

  get articles(): Array<SuggestionArticle_>|null {
    return (
        (this.Serializable$has('articles')) ?
            (this.Serializable$get('articles')) :
            (null));
  }

  /**
   * Output only. Articles ordered by score in descending order.
   */
  set articles(value: Array<SuggestionArticle_>|null) {
    this.Serializable$set('articles', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The time the suggestion was created.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get faqAnswers(): Array<SuggestionFaqAnswer_>|null {
    return (
        (this.Serializable$has('faqAnswers')) ?
            (this.Serializable$get('faqAnswers')) :
            (null));
  }

  /**
   * Output only. Answers extracted from FAQ documents.
   */
  set faqAnswers(value: Array<SuggestionFaqAnswer_>|null) {
    this.Serializable$set('faqAnswers', value);
  }

  get latestMessage(): string|null {
    return (
        (this.Serializable$has('latestMessage')) ?
            (this.Serializable$get('latestMessage')) :
            (null));
  }

  /**
   * Output only. Latest message used as context to compile this suggestion.
   * Format: `projects//locations//conversations//messages/`.
   */
  set latestMessage(value: string|null) {
    this.Serializable$set('latestMessage', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The name of this suggestion. Format:
   * `projects//locations//conversations//participants/* /suggestions/`.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  getConstructor(): SerializableCtor<Suggestion_> {
    return Suggestion_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays:
          {'articles': SuggestionArticle_, 'faqAnswers': SuggestionFaqAnswer_},
      keys: ['articles', 'createTime', 'faqAnswers', 'latestMessage', 'name']
    };
  }
}

export interface SummarizationMetrics_Parameters {
  rougel?: number|null;
}
export class SummarizationMetrics_ extends Serializable {
  constructor(parameters: SummarizationMetrics_Parameters = {}) {
    super();
    this.Serializable$set(
        'rougel', (parameters.rougel == null) ? (null) : (parameters.rougel));
  }

  get rougel(): number|null {
    return (
        (this.Serializable$has('rougel')) ? (this.Serializable$get('rougel')) :
                                            (null));
  }

  /**
   * Recall-Oriented Understudy for Gisting Evaluation. The measures count the
   * number of overlapping units such as n-gram, word sequences, and word pairs
   * between the computer-generated summary to be evaluated and the ideal
   * summaries created by humans. https://en.wikipedia.org/wiki/ROUGE_(metric)
   */
  set rougel(value: number|null) {
    this.Serializable$set('rougel', value);
  }

  getConstructor(): SerializableCtor<SummarizationMetrics_> {
    return SummarizationMetrics_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['rougel']};
  }
}

export interface SummarizationModelMetadata_Parameters {
  modelType?: string|null;
  internalTrainingFlags?: string|null;
  trainingModelType?: SummarizationModelMetadata_TrainingModelType|null;
}
export class SummarizationModelMetadata_ extends Serializable {
  constructor(parameters: SummarizationModelMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'modelType',
        (parameters.modelType == null) ? (null) : (parameters.modelType));
    this.Serializable$set(
        'internalTrainingFlags',
        (parameters.internalTrainingFlags == null) ?
            (null) :
            (parameters.internalTrainingFlags));
    this.Serializable$set(
        'trainingModelType',
        (parameters.trainingModelType == null) ?
            (null) :
            (parameters.trainingModelType));
  }

  static get TrainingModelType():
      ISummarizationModelMetadata_TrainingModelTypeEnum {
    return SummarizationModelMetadata_TrainingModelTypeEnum;
  }

  get internalTrainingFlags(): string|null {
    return (
        (this.Serializable$has('internalTrainingFlags')) ?
            (this.Serializable$get('internalTrainingFlags')) :
            (null));
  }

  set internalTrainingFlags(value: string|null) {
    this.Serializable$set('internalTrainingFlags', value);
  }

  get modelType(): string|null {
    return (
        (this.Serializable$has('modelType')) ?
            (this.Serializable$get('modelType')) :
            (null));
  }

  /**
   * Optional. DEPRECATED: Prefer defining and accessing the model type using
   * training_model_type. Type of the summarization model. The available values
   * are: * `summarization-model-1` - (default)
   */
  set modelType(value: string|null) {
    this.Serializable$set('modelType', value);
  }

  get trainingModelType(): SummarizationModelMetadata_TrainingModelType|null {
    return (
        (this.Serializable$has('trainingModelType')) ?
            (this.Serializable$get('trainingModelType')) :
            (null));
  }

  /**
   * Optional. Type of the summarizationsummarization model. If not provided,
   * model_type is used.
   */
  set trainingModelType(value: SummarizationModelMetadata_TrainingModelType|
                        null) {
    this.Serializable$set('trainingModelType', value);
  }

  getConstructor(): SerializableCtor<SummarizationModelMetadata_> {
    return SummarizationModelMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'trainingModelType': SummarizationModelMetadata_TrainingModelTypeEnum
      },
      keys: ['internalTrainingFlags', 'modelType', 'trainingModelType']
    };
  }
}

export interface SummarizationModelTrainingMetadata_Parameters {
  inputTrainingConversationCount?: string|null;
  inputTrainingSummaryCount?: string|null;
  convLengthDebugMessage?: string|null;
  convContentDebugMessage?: string|null;
}
export class SummarizationModelTrainingMetadata_ extends Serializable {
  constructor(parameters: SummarizationModelTrainingMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'inputTrainingConversationCount',
        (parameters.inputTrainingConversationCount == null) ?
            (null) :
            (parameters.inputTrainingConversationCount));
    this.Serializable$set(
        'inputTrainingSummaryCount',
        (parameters.inputTrainingSummaryCount == null) ?
            (null) :
            (parameters.inputTrainingSummaryCount));
    this.Serializable$set(
        'convLengthDebugMessage',
        (parameters.convLengthDebugMessage == null) ?
            (null) :
            (parameters.convLengthDebugMessage));
    this.Serializable$set(
        'convContentDebugMessage',
        (parameters.convContentDebugMessage == null) ?
            (null) :
            (parameters.convContentDebugMessage));
  }

  get convContentDebugMessage(): string|null {
    return (
        (this.Serializable$has('convContentDebugMessage')) ?
            (this.Serializable$get('convContentDebugMessage')) :
            (null));
  }

  /**
   * Debug message for summary content check.
   */
  set convContentDebugMessage(value: string|null) {
    this.Serializable$set('convContentDebugMessage', value);
  }

  get convLengthDebugMessage(): string|null {
    return (
        (this.Serializable$has('convLengthDebugMessage')) ?
            (this.Serializable$get('convLengthDebugMessage')) :
            (null));
  }

  /**
   * Debug message for conversation/summary length check.
   */
  set convLengthDebugMessage(value: string|null) {
    this.Serializable$set('convLengthDebugMessage', value);
  }

  get inputTrainingConversationCount(): string|null {
    return (
        (this.Serializable$has('inputTrainingConversationCount')) ?
            (this.Serializable$get('inputTrainingConversationCount')) :
            (null));
  }

  /**
   * ------- Summarization model training data validation result. Number of
   * training conversation before preprocessing.
   */
  set inputTrainingConversationCount(value: string|null) {
    this.Serializable$set('inputTrainingConversationCount', value);
  }

  get inputTrainingSummaryCount(): string|null {
    return (
        (this.Serializable$has('inputTrainingSummaryCount')) ?
            (this.Serializable$get('inputTrainingSummaryCount')) :
            (null));
  }

  /**
   * Number of training summary before preprocessing.
   */
  set inputTrainingSummaryCount(value: string|null) {
    this.Serializable$set('inputTrainingSummaryCount', value);
  }

  getConstructor(): SerializableCtor<SummarizationModelTrainingMetadata_> {
    return SummarizationModelTrainingMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'convContentDebugMessage', 'convLengthDebugMessage',
        'inputTrainingConversationCount', 'inputTrainingSummaryCount'
      ]
    };
  }
}

export interface SynthesizeSpeechConfig_Parameters {
  speakingRate?: number|null;
  pitch?: number|null;
  volumeGainDb?: number|null;
  effectsProfileId?: Array<string>|null;
  voice?: VoiceSelectionParams_|null;
}
export class SynthesizeSpeechConfig_ extends Serializable {
  constructor(parameters: SynthesizeSpeechConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'speakingRate',
        (parameters.speakingRate == null) ? (null) : (parameters.speakingRate));
    this.Serializable$set(
        'pitch', (parameters.pitch == null) ? (null) : (parameters.pitch));
    this.Serializable$set(
        'volumeGainDb',
        (parameters.volumeGainDb == null) ? (null) : (parameters.volumeGainDb));
    this.Serializable$set(
        'effectsProfileId',
        (parameters.effectsProfileId == null) ? (null) :
                                                (parameters.effectsProfileId));
    this.Serializable$set(
        'voice', (parameters.voice == null) ? (null) : (parameters.voice));
  }

  get effectsProfileId(): Array<string>|null {
    return (
        (this.Serializable$has('effectsProfileId')) ?
            (this.Serializable$get('effectsProfileId')) :
            (null));
  }

  /**
   * Optional. An identifier which selects 'audio effects' profiles that are
   * applied on (post synthesized) text to speech. Effects are applied on top of
   * each other in the order they are given.
   */
  set effectsProfileId(value: Array<string>|null) {
    this.Serializable$set('effectsProfileId', value);
  }

  get pitch(): number|null {
    return (
        (this.Serializable$has('pitch')) ? (this.Serializable$get('pitch')) :
                                           (null));
  }

  /**
   * Optional. Speaking pitch, in the range [-20.0, 20.0]. 20 means increase 20
   * semitones from the original pitch. -20 means decrease 20 semitones from the
   * original pitch.
   */
  set pitch(value: number|null) {
    this.Serializable$set('pitch', value);
  }

  get speakingRate(): number|null {
    return (
        (this.Serializable$has('speakingRate')) ?
            (this.Serializable$get('speakingRate')) :
            (null));
  }

  /**
   * Optional. Speaking rate/speed, in the range [0.25, 4.0]. 1.0 is the normal
   * native speed supported by the specific voice. 2.0 is twice as fast, and 0.5
   * is half as fast. If unset(0.0), defaults to the native 1.0 speed. Any other
   * values < 0.25 or > 4.0 will return an error.
   */
  set speakingRate(value: number|null) {
    this.Serializable$set('speakingRate', value);
  }

  get voice(): VoiceSelectionParams_|null {
    return (
        (this.Serializable$has('voice')) ? (this.Serializable$get('voice')) :
                                           (null));
  }

  /**
   * Optional. The desired voice of the synthesized audio.
   */
  set voice(value: VoiceSelectionParams_|null) {
    this.Serializable$set('voice', value);
  }

  get volumeGainDb(): number|null {
    return (
        (this.Serializable$has('volumeGainDb')) ?
            (this.Serializable$get('volumeGainDb')) :
            (null));
  }

  /**
   * Optional. Volume gain (in dB) of the normal native volume supported by the
   * specific voice, in the range [-96.0, 16.0]. If unset, or set to a value of
   * 0.0 (dB), will play at normal native signal amplitude. A value of -6.0 (dB)
   * will play at approximately half the amplitude of the normal native signal
   * amplitude. A value of +6.0 (dB) will play at approximately twice the
   * amplitude of the normal native signal amplitude. We strongly recommend not
   * to exceed +10 (dB) as there's usually no effective increase in loudness for
   * any value greater than that.
   */
  set volumeGainDb(value: number|null) {
    this.Serializable$set('volumeGainDb', value);
  }

  getConstructor(): SerializableCtor<SynthesizeSpeechConfig_> {
    return SynthesizeSpeechConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'effectsProfileId', 'pitch', 'speakingRate', 'voice', 'volumeGainDb'
      ],
      objects: {'voice': VoiceSelectionParams_}
    };
  }
}

export interface TelephonyDtmfEvents_Parameters {
  dtmfEvents?: Array<TelephonyDtmfEvents_DtmfEvents>|null;
}
export class TelephonyDtmfEvents_ extends Serializable {
  constructor(parameters: TelephonyDtmfEvents_Parameters = {}) {
    super();
    this.Serializable$set(
        'dtmfEvents',
        (parameters.dtmfEvents == null) ? (null) : (parameters.dtmfEvents));
  }

  static get DtmfEvents(): ITelephonyDtmfEvents_DtmfEventsEnum {
    return TelephonyDtmfEvents_DtmfEventsEnum;
  }

  get dtmfEvents(): Array<TelephonyDtmfEvents_DtmfEvents>|null {
    return (
        (this.Serializable$has('dtmfEvents')) ?
            (this.Serializable$get('dtmfEvents')) :
            (null));
  }

  /**
   * A sequence of TelephonyDtmf digits.
   */
  set dtmfEvents(value: Array<TelephonyDtmfEvents_DtmfEvents>|null) {
    this.Serializable$set('dtmfEvents', value);
  }

  getConstructor(): SerializableCtor<TelephonyDtmfEvents_> {
    return TelephonyDtmfEvents_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'dtmfEvents': TelephonyDtmfEvents_DtmfEventsEnum},
      keys: ['dtmfEvents']
    };
  }
}

export interface TelephonySttConfig_Parameters {
  disableTranscriptTruncation?: boolean|null;
  maxSpeechDuration?: string|null;
}
export class TelephonySttConfig_ extends Serializable {
  constructor(parameters: TelephonySttConfig_Parameters = {}) {
    super();
    this.Serializable$set(
        'disableTranscriptTruncation',
        (parameters.disableTranscriptTruncation == null) ?
            (null) :
            (parameters.disableTranscriptTruncation));
    this.Serializable$set(
        'maxSpeechDuration',
        (parameters.maxSpeechDuration == null) ?
            (null) :
            (parameters.maxSpeechDuration));
  }

  get disableTranscriptTruncation(): boolean|null {
    return (
        (this.Serializable$has('disableTranscriptTruncation')) ?
            (this.Serializable$get('disableTranscriptTruncation')) :
            (null));
  }

  /**
   * Disables the behavior that truncates the transcripts. By default, we
   * truncate transcripts longer than 256 characters.
   */
  set disableTranscriptTruncation(value: boolean|null) {
    this.Serializable$set('disableTranscriptTruncation', value);
  }

  get maxSpeechDuration(): string|null {
    return (
        (this.Serializable$has('maxSpeechDuration')) ?
            (this.Serializable$get('maxSpeechDuration')) :
            (null));
  }

  /**
   * Defines the max duration allowed per utterance for speech transcription.
   * Overrides the default value of 15s. Value should not be over 60s or below
   * 15s.
   */
  set maxSpeechDuration(value: string|null) {
    this.Serializable$set('maxSpeechDuration', value);
  }

  getConstructor(): SerializableCtor<TelephonySttConfig_> {
    return TelephonySttConfig_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['disableTranscriptTruncation', 'maxSpeechDuration']};
  }
}

export interface TextInput_Parameters {
  text?: string|null;
  languageCode?: string|null;
  enableSpellingCorrection?: boolean|null;
  enableSplittingText?: boolean|null;
  isAudioInput?: boolean|null;
}
export class TextInput_ extends Serializable {
  constructor(parameters: TextInput_Parameters = {}) {
    super();
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'enableSpellingCorrection',
        (parameters.enableSpellingCorrection == null) ?
            (null) :
            (parameters.enableSpellingCorrection));
    this.Serializable$set(
        'enableSplittingText',
        (parameters.enableSplittingText == null) ?
            (null) :
            (parameters.enableSplittingText));
    this.Serializable$set(
        'isAudioInput',
        (parameters.isAudioInput == null) ? (null) : (parameters.isAudioInput));
  }

  get enableSpellingCorrection(): boolean|null {
    return (
        (this.Serializable$has('enableSpellingCorrection')) ?
            (this.Serializable$get('enableSpellingCorrection')) :
            (null));
  }

  /**
   * Indicates whether typos in the input text should be corrected.
   */
  set enableSpellingCorrection(value: boolean|null) {
    this.Serializable$set('enableSpellingCorrection', value);
  }

  get enableSplittingText(): boolean|null {
    return (
        (this.Serializable$has('enableSplittingText')) ?
            (this.Serializable$get('enableSplittingText')) :
            (null));
  }

  /**
   * Indicates whether to split text into single sentence text entries at
   * serving time. This provides a way for user to input email content as text
   * input.
   */
  set enableSplittingText(value: boolean|null) {
    this.Serializable$set('enableSplittingText', value);
  }

  get isAudioInput(): boolean|null {
    return (
        (this.Serializable$has('isAudioInput')) ?
            (this.Serializable$get('isAudioInput')) :
            (null));
  }

  /**
   * Whether the text is transcribed from audio input.
   */
  set isAudioInput(value: boolean|null) {
    this.Serializable$set('isAudioInput', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. The language of this conversational query. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes. Note that queries in the
   * same session do not necessarily need to specify the same language.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * Required. The UTF-8 encoded natural language text to be processed. Text
   * length must not exceed 256 characters for virtual agent interactions.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  getConstructor(): SerializableCtor<TextInput_> {
    return TextInput_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'enableSpellingCorrection', 'enableSplittingText', 'isAudioInput',
        'languageCode', 'text'
      ]
    };
  }
}

export interface TextToSpeechSettings_Parameters {
  enableTextToSpeech?: boolean|null;
  outputAudioEncoding?: TextToSpeechSettings_OutputAudioEncoding|null;
  sampleRateHertz?: number|null;
  synthesizeSpeechConfigs?: ApiClientObjectMap<SynthesizeSpeechConfig_>|null;
}
export class TextToSpeechSettings_ extends Serializable {
  constructor(parameters: TextToSpeechSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'enableTextToSpeech',
        (parameters.enableTextToSpeech == null) ?
            (null) :
            (parameters.enableTextToSpeech));
    this.Serializable$set(
        'outputAudioEncoding',
        (parameters.outputAudioEncoding == null) ?
            (null) :
            (parameters.outputAudioEncoding));
    this.Serializable$set(
        'sampleRateHertz',
        (parameters.sampleRateHertz == null) ? (null) :
                                               (parameters.sampleRateHertz));
    this.Serializable$set(
        'synthesizeSpeechConfigs',
        (parameters.synthesizeSpeechConfigs == null) ?
            (null) :
            (parameters.synthesizeSpeechConfigs));
  }

  static get OutputAudioEncoding():
      ITextToSpeechSettings_OutputAudioEncodingEnum {
    return TextToSpeechSettings_OutputAudioEncodingEnum;
  }

  get enableTextToSpeech(): boolean|null {
    return (
        (this.Serializable$has('enableTextToSpeech')) ?
            (this.Serializable$get('enableTextToSpeech')) :
            (null));
  }

  /**
   * Optional. Indicates whether text to speech is enabled. Even when this field
   * is false, other settings in this proto are still retained.
   */
  set enableTextToSpeech(value: boolean|null) {
    this.Serializable$set('enableTextToSpeech', value);
  }

  get outputAudioEncoding(): TextToSpeechSettings_OutputAudioEncoding|null {
    return (
        (this.Serializable$has('outputAudioEncoding')) ?
            (this.Serializable$get('outputAudioEncoding')) :
            (null));
  }

  /**
   * Required. Audio encoding of the synthesized audio content.
   */
  set outputAudioEncoding(value: TextToSpeechSettings_OutputAudioEncoding|
                          null) {
    this.Serializable$set('outputAudioEncoding', value);
  }

  get sampleRateHertz(): number|null {
    return (
        (this.Serializable$has('sampleRateHertz')) ?
            (this.Serializable$get('sampleRateHertz')) :
            (null));
  }

  /**
   * Optional. The synthesis sample rate (in hertz) for this audio. If not
   * provided, then the synthesizer will use the default sample rate based on
   * the audio encoding. If this is different from the voice's natural sample
   * rate, then the synthesizer will honor this request by converting to the
   * desired sample rate (which might result in worse audio quality).
   */
  set sampleRateHertz(value: number|null) {
    this.Serializable$set('sampleRateHertz', value);
  }

  get synthesizeSpeechConfigs():
      ApiClientObjectMap<SynthesizeSpeechConfig_>|null {
    return (
        (this.Serializable$has('synthesizeSpeechConfigs')) ?
            (this.Serializable$get('synthesizeSpeechConfigs')) :
            (null));
  }

  /**
   * Optional. Configuration of how speech should be synthesized, mapping from
   * language (https://cloud.google.com/dialogflow/docs/reference/language) to
   * SynthesizeSpeechConfig.
   */
  set synthesizeSpeechConfigs(value:
                                  ApiClientObjectMap<SynthesizeSpeechConfig_>|
                              null) {
    this.Serializable$set('synthesizeSpeechConfigs', value);
  }

  getConstructor(): SerializableCtor<TextToSpeechSettings_> {
    return TextToSpeechSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums:
          {'outputAudioEncoding': TextToSpeechSettings_OutputAudioEncodingEnum},
      keys: [
        'enableTextToSpeech', 'outputAudioEncoding', 'sampleRateHertz',
        'synthesizeSpeechConfigs'
      ],
      objectMaps: {
        'synthesizeSpeechConfigs': {
          ctor: SynthesizeSpeechConfig_,
          isPropertyArray: false,
          isSerializable: true,
          isValueArray: false
        }
      }
    };
  }
}

export interface TopicTopicStats_Parameters {
  rawTriggeringIntentMessageCount?: string|null;
  uniqueTriggeringIntentMessageCount?: string|null;
  conversationCount?: string|null;
}
export class TopicTopicStats_ extends Serializable {
  constructor(parameters: TopicTopicStats_Parameters = {}) {
    super();
    this.Serializable$set(
        'rawTriggeringIntentMessageCount',
        (parameters.rawTriggeringIntentMessageCount == null) ?
            (null) :
            (parameters.rawTriggeringIntentMessageCount));
    this.Serializable$set(
        'uniqueTriggeringIntentMessageCount',
        (parameters.uniqueTriggeringIntentMessageCount == null) ?
            (null) :
            (parameters.uniqueTriggeringIntentMessageCount));
    this.Serializable$set(
        'conversationCount',
        (parameters.conversationCount == null) ?
            (null) :
            (parameters.conversationCount));
  }

  get conversationCount(): string|null {
    return (
        (this.Serializable$has('conversationCount')) ?
            (this.Serializable$get('conversationCount')) :
            (null));
  }

  /**
   * Conversation count associated with the topic.
   */
  set conversationCount(value: string|null) {
    this.Serializable$set('conversationCount', value);
  }

  get rawTriggeringIntentMessageCount(): string|null {
    return (
        (this.Serializable$has('rawTriggeringIntentMessageCount')) ?
            (this.Serializable$get('rawTriggeringIntentMessageCount')) :
            (null));
  }

  /**
   * Raw triggering intent message count associated with the topic.
   */
  set rawTriggeringIntentMessageCount(value: string|null) {
    this.Serializable$set('rawTriggeringIntentMessageCount', value);
  }

  get uniqueTriggeringIntentMessageCount(): string|null {
    return (
        (this.Serializable$has('uniqueTriggeringIntentMessageCount')) ?
            (this.Serializable$get('uniqueTriggeringIntentMessageCount')) :
            (null));
  }

  /**
   * Unique triggering intent message count associated with the topic.
   */
  set uniqueTriggeringIntentMessageCount(value: string|null) {
    this.Serializable$set('uniqueTriggeringIntentMessageCount', value);
  }

  getConstructor(): SerializableCtor<TopicTopicStats_> {
    return TopicTopicStats_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'conversationCount', 'rawTriggeringIntentMessageCount',
        'uniqueTriggeringIntentMessageCount'
      ]
    };
  }
}

export interface Topic_Parameters {
  name?: string|null;
  id?: string|null;
  parentTopicId?: string|null;
  keywords?: Array<WeightedTerm_>|null;
  description?: string|null;
  hasChildren?: boolean|null;
  score?: number|null;
  stats?: TopicTopicStats_|null;
}
export class Topic_ extends Serializable {
  constructor(parameters: Topic_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'id', (parameters.id == null) ? (null) : (parameters.id));
    this.Serializable$set(
        'parentTopicId',
        (parameters.parentTopicId == null) ? (null) :
                                             (parameters.parentTopicId));
    this.Serializable$set(
        'keywords',
        (parameters.keywords == null) ? (null) : (parameters.keywords));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'hasChildren',
        (parameters.hasChildren == null) ? (null) : (parameters.hasChildren));
    this.Serializable$set(
        'score', (parameters.score == null) ? (null) : (parameters.score));
    this.Serializable$set(
        'stats', (parameters.stats == null) ? (null) : (parameters.stats));
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * A human readable sentence/phrase to facilitate human interpretation.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get hasChildren(): boolean|null {
    return (
        (this.Serializable$has('hasChildren')) ?
            (this.Serializable$get('hasChildren')) :
            (null));
  }

  /**
   * Whether this topic has child topics.
   */
  set hasChildren(value: boolean|null) {
    this.Serializable$set('hasChildren', value);
  }

  get id(): string|null {
    return (
        (this.Serializable$has('id')) ? (this.Serializable$get('id')) : (null));
  }

  /**
   * Internal. Id of the topic.
   */
  set id(value: string|null) {
    this.Serializable$set('id', value);
  }

  get keywords(): Array<WeightedTerm_>|null {
    return (
        (this.Serializable$has('keywords')) ?
            (this.Serializable$get('keywords')) :
            (null));
  }

  /**
   * A list of keywords to describe the topic.
   */
  set keywords(value: Array<WeightedTerm_>|null) {
    this.Serializable$set('keywords', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Resource name of the topic.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get parentTopicId(): string|null {
    return (
        (this.Serializable$has('parentTopicId')) ?
            (this.Serializable$get('parentTopicId')) :
            (null));
  }

  /**
   * Internal. Id of the parent topic.
   */
  set parentTopicId(value: string|null) {
    this.Serializable$set('parentTopicId', value);
  }

  get score(): number|null {
    return (
        (this.Serializable$has('score')) ? (this.Serializable$get('score')) :
                                           (null));
  }

  /**
   * Score of this topic for ranking.
   */
  set score(value: number|null) {
    this.Serializable$set('score', value);
  }

  get stats(): TopicTopicStats_|null {
    return (
        (this.Serializable$has('stats')) ? (this.Serializable$get('stats')) :
                                           (null));
  }

  /**
   * Numerical stats of the topic.
   */
  set stats(value: TopicTopicStats_|null) {
    this.Serializable$set('stats', value);
  }

  getConstructor(): SerializableCtor<Topic_> {
    return Topic_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'keywords': WeightedTerm_},
      keys: [
        'description', 'hasChildren', 'id', 'keywords', 'name', 'parentTopicId',
        'score', 'stats'
      ],
      objects: {'stats': TopicTopicStats_}
    };
  }
}

export interface TrainAgentRequest_Parameters {}
export class TrainAgentRequest_ extends Serializable {
  constructor(parameters: TrainAgentRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<TrainAgentRequest_> {
    return TrainAgentRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface TranscriptChunks_Parameters {
  textPerChunk?: ApiClientObjectMap<string>|null;
}
export class TranscriptChunks_ extends Serializable {
  constructor(parameters: TranscriptChunks_Parameters = {}) {
    super();
    this.Serializable$set(
        'textPerChunk',
        (parameters.textPerChunk == null) ? (null) : (parameters.textPerChunk));
  }

  get textPerChunk(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('textPerChunk')) ?
            (this.Serializable$get('textPerChunk')) :
            (null));
  }

  /**
   * Key-value map from transcript chunk id to transcript chunk text.
   */
  set textPerChunk(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('textPerChunk', value);
  }

  getConstructor(): SerializableCtor<TranscriptChunks_> {
    return TranscriptChunks_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['textPerChunk'],
      objectMaps: {
        'textPerChunk': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface TranscriptTranscriptSegmentDialogflowSegmentMetadata_Parameters {
  smartReplyAllowlistCovered?: boolean|null;
}
export class TranscriptTranscriptSegmentDialogflowSegmentMetadata_ extends
    Serializable {
  constructor(
      parameters:
          TranscriptTranscriptSegmentDialogflowSegmentMetadata_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'smartReplyAllowlistCovered',
        (parameters.smartReplyAllowlistCovered == null) ?
            (null) :
            (parameters.smartReplyAllowlistCovered));
  }

  get smartReplyAllowlistCovered(): boolean|null {
    return (
        (this.Serializable$has('smartReplyAllowlistCovered')) ?
            (this.Serializable$get('smartReplyAllowlistCovered')) :
            (null));
  }

  /**
   * Whether the transcript segment was covered under the configured smart reply
   * allowlist in Agent Assist.
   */
  set smartReplyAllowlistCovered(value: boolean|null) {
    this.Serializable$set('smartReplyAllowlistCovered', value);
  }

  getConstructor():
      SerializableCtor<TranscriptTranscriptSegmentDialogflowSegmentMetadata_> {
    return TranscriptTranscriptSegmentDialogflowSegmentMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['smartReplyAllowlistCovered']};
  }
}

export interface TranscriptTranscriptSegmentWordInfo_Parameters {
  startOffset?: string|null;
  endOffset?: string|null;
  word?: string|null;
  confidence?: number|null;
}
export class TranscriptTranscriptSegmentWordInfo_ extends Serializable {
  constructor(parameters: TranscriptTranscriptSegmentWordInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'startOffset',
        (parameters.startOffset == null) ? (null) : (parameters.startOffset));
    this.Serializable$set(
        'endOffset',
        (parameters.endOffset == null) ? (null) : (parameters.endOffset));
    this.Serializable$set(
        'word', (parameters.word == null) ? (null) : (parameters.word));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * A confidence estimate between 0.0 and 1.0 of the fidelity of this word. A
   * default value of 0.0 indicates that the value is unset.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get endOffset(): string|null {
    return (
        (this.Serializable$has('endOffset')) ?
            (this.Serializable$get('endOffset')) :
            (null));
  }

  /**
   * Time offset of the end of this word relative to the beginning of the total
   * conversation.
   */
  set endOffset(value: string|null) {
    this.Serializable$set('endOffset', value);
  }

  get startOffset(): string|null {
    return (
        (this.Serializable$has('startOffset')) ?
            (this.Serializable$get('startOffset')) :
            (null));
  }

  /**
   * Time offset of the start of this word relative to the beginning of the
   * total conversation.
   */
  set startOffset(value: string|null) {
    this.Serializable$set('startOffset', value);
  }

  get word(): string|null {
    return (
        (this.Serializable$has('word')) ? (this.Serializable$get('word')) :
                                          (null));
  }

  /**
   * The word itself. Includes punctuation marks that surround the word.
   */
  set word(value: string|null) {
    this.Serializable$set('word', value);
  }

  getConstructor(): SerializableCtor<TranscriptTranscriptSegmentWordInfo_> {
    return TranscriptTranscriptSegmentWordInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['confidence', 'endOffset', 'startOffset', 'word']};
  }
}

export interface TranscriptTranscriptSegment_Parameters {
  messageTime?: string|null;
  text?: string|null;
  confidence?: number|null;
  words?: Array<TranscriptTranscriptSegmentWordInfo_>|null;
  languageCode?: string|null;
  channelTag?: number|null;
  segmentParticipant?: ConversationParticipant_|null;
  dialogflowSegmentMetadata?:
      TranscriptTranscriptSegmentDialogflowSegmentMetadata_|null;
  sentiment?: SentimentData_|null;
}
export class TranscriptTranscriptSegment_ extends Serializable {
  constructor(parameters: TranscriptTranscriptSegment_Parameters = {}) {
    super();
    this.Serializable$set(
        'messageTime',
        (parameters.messageTime == null) ? (null) : (parameters.messageTime));
    this.Serializable$set(
        'text', (parameters.text == null) ? (null) : (parameters.text));
    this.Serializable$set(
        'confidence',
        (parameters.confidence == null) ? (null) : (parameters.confidence));
    this.Serializable$set(
        'words', (parameters.words == null) ? (null) : (parameters.words));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
    this.Serializable$set(
        'channelTag',
        (parameters.channelTag == null) ? (null) : (parameters.channelTag));
    this.Serializable$set(
        'segmentParticipant',
        (parameters.segmentParticipant == null) ?
            (null) :
            (parameters.segmentParticipant));
    this.Serializable$set(
        'dialogflowSegmentMetadata',
        (parameters.dialogflowSegmentMetadata == null) ?
            (null) :
            (parameters.dialogflowSegmentMetadata));
    this.Serializable$set(
        'sentiment',
        (parameters.sentiment == null) ? (null) : (parameters.sentiment));
  }

  get channelTag(): number|null {
    return (
        (this.Serializable$has('channelTag')) ?
            (this.Serializable$get('channelTag')) :
            (null));
  }

  /**
   * For conversations derived from multi-channel audio, this is the channel
   * number corresponding to the audio from that channel. For audioChannelCount
   * = N, its output values can range from '1' to 'N'. A channel tag of 0
   * indicates that the audio is mono.
   */
  set channelTag(value: number|null) {
    this.Serializable$set('channelTag', value);
  }

  get confidence(): number|null {
    return (
        (this.Serializable$has('confidence')) ?
            (this.Serializable$get('confidence')) :
            (null));
  }

  /**
   * A confidence estimate between 0.0 and 1.0 of the fidelity of this segment.
   * A default value of 0.0 indicates that the value is unset.
   */
  set confidence(value: number|null) {
    this.Serializable$set('confidence', value);
  }

  get dialogflowSegmentMetadata():
      TranscriptTranscriptSegmentDialogflowSegmentMetadata_|null {
    return (
        (this.Serializable$has('dialogflowSegmentMetadata')) ?
            (this.Serializable$get('dialogflowSegmentMetadata')) :
            (null));
  }

  /**
   * CCAI metadata relating to the current transcript segment.
   */
  set dialogflowSegmentMetadata(
      value: TranscriptTranscriptSegmentDialogflowSegmentMetadata_|null) {
    this.Serializable$set('dialogflowSegmentMetadata', value);
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * The language code of this segment as a
   * [BCP-47](https://www.rfc-editor.org/rfc/bcp/bcp47.txt) language tag.
   * Example: \"en-US\".
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get messageTime(): string|null {
    return (
        (this.Serializable$has('messageTime')) ?
            (this.Serializable$get('messageTime')) :
            (null));
  }

  /**
   * The time that the message occurred, if provided.
   */
  set messageTime(value: string|null) {
    this.Serializable$set('messageTime', value);
  }

  get segmentParticipant(): ConversationParticipant_|null {
    return (
        (this.Serializable$has('segmentParticipant')) ?
            (this.Serializable$get('segmentParticipant')) :
            (null));
  }

  /**
   * The participant of this segment.
   */
  set segmentParticipant(value: ConversationParticipant_|null) {
    this.Serializable$set('segmentParticipant', value);
  }

  get sentiment(): SentimentData_|null {
    return (
        (this.Serializable$has('sentiment')) ?
            (this.Serializable$get('sentiment')) :
            (null));
  }

  /**
   * The sentiment for this transcript segment.
   */
  set sentiment(value: SentimentData_|null) {
    this.Serializable$set('sentiment', value);
  }

  get text(): string|null {
    return (
        (this.Serializable$has('text')) ? (this.Serializable$get('text')) :
                                          (null));
  }

  /**
   * The text of this segment.
   */
  set text(value: string|null) {
    this.Serializable$set('text', value);
  }

  get words(): Array<TranscriptTranscriptSegmentWordInfo_>|null {
    return (
        (this.Serializable$has('words')) ? (this.Serializable$get('words')) :
                                           (null));
  }

  /**
   * A list of the word-specific information for each word in the segment.
   */
  set words(value: Array<TranscriptTranscriptSegmentWordInfo_>|null) {
    this.Serializable$set('words', value);
  }

  getConstructor(): SerializableCtor<TranscriptTranscriptSegment_> {
    return TranscriptTranscriptSegment_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'words': TranscriptTranscriptSegmentWordInfo_},
      keys: [
        'channelTag', 'confidence', 'dialogflowSegmentMetadata', 'languageCode',
        'messageTime', 'segmentParticipant', 'sentiment', 'text', 'words'
      ],
      objects: {
        'dialogflowSegmentMetadata':
            TranscriptTranscriptSegmentDialogflowSegmentMetadata_,
        'segmentParticipant': ConversationParticipant_,
        'sentiment': SentimentData_
      }
    };
  }
}

export interface Transcript_Parameters {
  transcriptSegments?: Array<TranscriptTranscriptSegment_>|null;
}
export class Transcript_ extends Serializable {
  constructor(parameters: Transcript_Parameters = {}) {
    super();
    this.Serializable$set(
        'transcriptSegments',
        (parameters.transcriptSegments == null) ?
            (null) :
            (parameters.transcriptSegments));
  }

  get transcriptSegments(): Array<TranscriptTranscriptSegment_>|null {
    return (
        (this.Serializable$has('transcriptSegments')) ?
            (this.Serializable$get('transcriptSegments')) :
            (null));
  }

  /**
   * A list of sequential transcript segments that comprise the conversation.
   */
  set transcriptSegments(value: Array<TranscriptTranscriptSegment_>|null) {
    this.Serializable$set('transcriptSegments', value);
  }

  getConstructor(): SerializableCtor<Transcript_> {
    return Transcript_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'transcriptSegments': TranscriptTranscriptSegment_},
      keys: ['transcriptSegments']
    };
  }
}

export interface UndeletePhoneNumberRequest_Parameters {}
export class UndeletePhoneNumberRequest_ extends Serializable {
  constructor(parameters: UndeletePhoneNumberRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<UndeletePhoneNumberRequest_> {
    return UndeletePhoneNumberRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface UndeployConversationModelOperationMetadata_Parameters {
  conversationModel?: string|null;
  createTime?: string|null;
}
export class UndeployConversationModelOperationMetadata_ extends Serializable {
  constructor(
      parameters: UndeployConversationModelOperationMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'conversationModel',
        (parameters.conversationModel == null) ?
            (null) :
            (parameters.conversationModel));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
  }

  get conversationModel(): string|null {
    return (
        (this.Serializable$has('conversationModel')) ?
            (this.Serializable$get('conversationModel')) :
            (null));
  }

  /**
   * The resource name of the conversation model. Format:
   * `projects//conversationModels/`
   */
  set conversationModel(value: string|null) {
    this.Serializable$set('conversationModel', value);
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Timestamp when the request to undeploy conversation model was submitted.
   * The time is measured on server side.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  getConstructor():
      SerializableCtor<UndeployConversationModelOperationMetadata_> {
    return UndeployConversationModelOperationMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['conversationModel', 'createTime']};
  }
}

export interface UndeployConversationModelRequest_Parameters {}
export class UndeployConversationModelRequest_ extends Serializable {
  constructor(parameters: UndeployConversationModelRequest_Parameters = {}) {
    super();
  }

  getConstructor(): SerializableCtor<UndeployConversationModelRequest_> {
    return UndeployConversationModelRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: []};
  }
}

export interface UpdateAgentSettingsRequest_Parameters {
  agentSettings?: AgentSettings_|null;
  updateMask?: string|null;
}
export class UpdateAgentSettingsRequest_ extends Serializable {
  constructor(parameters: UpdateAgentSettingsRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'agentSettings',
        (parameters.agentSettings == null) ? (null) :
                                             (parameters.agentSettings));
    this.Serializable$set(
        'updateMask',
        (parameters.updateMask == null) ? (null) : (parameters.updateMask));
  }

  get agentSettings(): AgentSettings_|null {
    return (
        (this.Serializable$has('agentSettings')) ?
            (this.Serializable$get('agentSettings')) :
            (null));
  }

  /**
   * Required. AgentSettings to set.
   */
  set agentSettings(value: AgentSettings_|null) {
    this.Serializable$set('agentSettings', value);
  }

  get updateMask(): string|null {
    return (
        (this.Serializable$has('updateMask')) ?
            (this.Serializable$get('updateMask')) :
            (null));
  }

  /**
   * Optional. The mask to control which fields get updated. If omitted, whole
   * object gets updated.
   */
  set updateMask(value: string|null) {
    this.Serializable$set('updateMask', value);
  }

  getConstructor(): SerializableCtor<UpdateAgentSettingsRequest_> {
    return UpdateAgentSettingsRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['agentSettings', 'updateMask'],
      objects: {'agentSettings': AgentSettings_}
    };
  }
}

export interface UpdateSmallTalkConfigRequest_Parameters {
  updateMask?: string|null;
  smallTalkConfig?: SmallTalkConfig_|null;
  languageCode?: string|null;
}
export class UpdateSmallTalkConfigRequest_ extends Serializable {
  constructor(parameters: UpdateSmallTalkConfigRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'updateMask',
        (parameters.updateMask == null) ? (null) : (parameters.updateMask));
    this.Serializable$set(
        'smallTalkConfig',
        (parameters.smallTalkConfig == null) ? (null) :
                                               (parameters.smallTalkConfig));
    this.Serializable$set(
        'languageCode',
        (parameters.languageCode == null) ? (null) : (parameters.languageCode));
  }

  get languageCode(): string|null {
    return (
        (this.Serializable$has('languageCode')) ?
            (this.Serializable$get('languageCode')) :
            (null));
  }

  /**
   * Required. Language code to update SmalltalkConfig for. See [Language
   * Support](https://cloud.google.com/dialogflow/docs/reference/language) for a
   * list of the currently supported language codes.
   */
  set languageCode(value: string|null) {
    this.Serializable$set('languageCode', value);
  }

  get smallTalkConfig(): SmallTalkConfig_|null {
    return (
        (this.Serializable$has('smallTalkConfig')) ?
            (this.Serializable$get('smallTalkConfig')) :
            (null));
  }

  /**
   * Required. Updated SmallTalkConfig object
   */
  set smallTalkConfig(value: SmallTalkConfig_|null) {
    this.Serializable$set('smallTalkConfig', value);
  }

  get updateMask(): string|null {
    return (
        (this.Serializable$has('updateMask')) ?
            (this.Serializable$get('updateMask')) :
            (null));
  }

  /**
   * Optional. The mask to control which fields get updated. If not specified,
   * all fields will be updated.
   */
  set updateMask(value: string|null) {
    this.Serializable$set('updateMask', value);
  }

  getConstructor(): SerializableCtor<UpdateSmallTalkConfigRequest_> {
    return UpdateSmallTalkConfigRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['languageCode', 'smallTalkConfig', 'updateMask'],
      objects: {'smallTalkConfig': SmallTalkConfig_}
    };
  }
}

export interface UpdateSmartMessagingEntryRequest_Parameters {
  smartMessagingEntry?: SmartMessagingEntry_|null;
  updateMask?: string|null;
}
export class UpdateSmartMessagingEntryRequest_ extends Serializable {
  constructor(parameters: UpdateSmartMessagingEntryRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'smartMessagingEntry',
        (parameters.smartMessagingEntry == null) ?
            (null) :
            (parameters.smartMessagingEntry));
    this.Serializable$set(
        'updateMask',
        (parameters.updateMask == null) ? (null) : (parameters.updateMask));
  }

  get smartMessagingEntry(): SmartMessagingEntry_|null {
    return (
        (this.Serializable$has('smartMessagingEntry')) ?
            (this.Serializable$get('smartMessagingEntry')) :
            (null));
  }

  /**
   * Required. The Smart Messaging Entry to update.
   */
  set smartMessagingEntry(value: SmartMessagingEntry_|null) {
    this.Serializable$set('smartMessagingEntry', value);
  }

  get updateMask(): string|null {
    return (
        (this.Serializable$has('updateMask')) ?
            (this.Serializable$get('updateMask')) :
            (null));
  }

  /**
   * The update mask applies to the resource. For the `FieldMask` definition,
   * see
   * https://developers.google.com/protocol-buffers/docs/reference/google.protobuf#fieldmask
   */
  set updateMask(value: string|null) {
    this.Serializable$set('updateMask', value);
  }

  getConstructor(): SerializableCtor<UpdateSmartMessagingEntryRequest_> {
    return UpdateSmartMessagingEntryRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['smartMessagingEntry', 'updateMask'],
      objects: {'smartMessagingEntry': SmartMessagingEntry_}
    };
  }
}

export interface UpgradePhoneNumberLeaseRequest_Parameters {
  phoneNumbers?: Array<string>|null;
}
export class UpgradePhoneNumberLeaseRequest_ extends Serializable {
  constructor(parameters: UpgradePhoneNumberLeaseRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'phoneNumbers',
        (parameters.phoneNumbers == null) ? (null) : (parameters.phoneNumbers));
  }

  get phoneNumbers(): Array<string>|null {
    return (
        (this.Serializable$has('phoneNumbers')) ?
            (this.Serializable$get('phoneNumbers')) :
            (null));
  }

  /**
   * Required. The resource names of the leased numbers to be upgraded to
   * assigned numbers. Format: `projects//phoneNumbers/`. Format:
   * `projects//locations//phoneNumbers/`.
   */
  set phoneNumbers(value: Array<string>|null) {
    this.Serializable$set('phoneNumbers', value);
  }

  getConstructor(): SerializableCtor<UpgradePhoneNumberLeaseRequest_> {
    return UpgradePhoneNumberLeaseRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['phoneNumbers']};
  }
}

export interface UserIntentResource_Parameters {
  intentName?: string|null;
}
export class UserIntentResource_ extends Serializable {
  constructor(parameters: UserIntentResource_Parameters = {}) {
    super();
    this.Serializable$set(
        'intentName',
        (parameters.intentName == null) ? (null) : (parameters.intentName));
  }

  get intentName(): string|null {
    return (
        (this.Serializable$has('intentName')) ?
            (this.Serializable$get('intentName')) :
            (null));
  }

  /**
   * Resource name of the associated intent.
   */
  set intentName(value: string|null) {
    this.Serializable$set('intentName', value);
  }

  getConstructor(): SerializableCtor<UserIntentResource_> {
    return UserIntentResource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['intentName']};
  }
}

export interface UserIntentTrainingPhraseResource_Parameters {
  trainingPhraseName?: string|null;
}
export class UserIntentTrainingPhraseResource_ extends Serializable {
  constructor(parameters: UserIntentTrainingPhraseResource_Parameters = {}) {
    super();
    this.Serializable$set(
        'trainingPhraseName',
        (parameters.trainingPhraseName == null) ?
            (null) :
            (parameters.trainingPhraseName));
  }

  get trainingPhraseName(): string|null {
    return (
        (this.Serializable$has('trainingPhraseName')) ?
            (this.Serializable$get('trainingPhraseName')) :
            (null));
  }

  /**
   * Resource name of the training phrase. Format:
   * `projects//locations//agent/intents//trainingPhrases/`
   */
  set trainingPhraseName(value: string|null) {
    this.Serializable$set('trainingPhraseName', value);
  }

  getConstructor(): SerializableCtor<UserIntentTrainingPhraseResource_> {
    return UserIntentTrainingPhraseResource_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['trainingPhraseName']};
  }
}

export interface ValidationError_Parameters {
  severity?: ValidationError_Severity|null;
  type?: ValidationError_Type|null;
  entries?: Array<string>|null;
  errorMessage?: string|null;
}
export class ValidationError_ extends Serializable {
  constructor(parameters: ValidationError_Parameters = {}) {
    super();
    this.Serializable$set(
        'severity',
        (parameters.severity == null) ? (null) : (parameters.severity));
    this.Serializable$set(
        'type', (parameters.type == null) ? (null) : (parameters.type));
    this.Serializable$set(
        'entries',
        (parameters.entries == null) ? (null) : (parameters.entries));
    this.Serializable$set(
        'errorMessage',
        (parameters.errorMessage == null) ? (null) : (parameters.errorMessage));
  }

  static get Severity(): IValidationError_SeverityEnum {
    return ValidationError_SeverityEnum;
  }

  static get Type(): IValidationError_TypeEnum {
    return ValidationError_TypeEnum;
  }

  get entries(): Array<string>|null {
    return (
        (this.Serializable$has('entries')) ?
            (this.Serializable$get('entries')) :
            (null));
  }

  /**
   * The names of the entries that the error is associated with. Format: -
   * \"projects//agent\", if the error is associated with the entire agent. -
   * \"projects//agent/intents/\", if the error is associated with certain
   * intents. - \"projects//agent/intents//trainingPhrases/\", if the error is
   * associated with certain intent training phrases. -
   * \"projects//agent/intents//parameters/\", if the error is associated with
   * certain intent parameters. - \"projects//agent/entities/\", if the error is
   * associated with certain entities.
   */
  set entries(value: Array<string>|null) {
    this.Serializable$set('entries', value);
  }

  get errorMessage(): string|null {
    return (
        (this.Serializable$has('errorMessage')) ?
            (this.Serializable$get('errorMessage')) :
            (null));
  }

  /**
   * The detailed error message.
   */
  set errorMessage(value: string|null) {
    this.Serializable$set('errorMessage', value);
  }

  get severity(): ValidationError_Severity|null {
    return (
        (this.Serializable$has('severity')) ?
            (this.Serializable$get('severity')) :
            (null));
  }

  /**
   * The severity of the error.
   */
  set severity(value: ValidationError_Severity|null) {
    this.Serializable$set('severity', value);
  }

  get type(): ValidationError_Type|null {
    return (
        (this.Serializable$has('type')) ? (this.Serializable$get('type')) :
                                          (null));
  }

  /**
   * The type of the error.
   */
  set type(value: ValidationError_Type|null) {
    this.Serializable$set('type', value);
  }

  getConstructor(): SerializableCtor<ValidationError_> {
    return ValidationError_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'severity': ValidationError_SeverityEnum,
        'type': ValidationError_TypeEnum
      },
      keys: ['entries', 'errorMessage', 'severity', 'type']
    };
  }
}

export interface ValidationResult_Parameters {
  validationErrors?: Array<ValidationError_>|null;
}
export class ValidationResult_ extends Serializable {
  constructor(parameters: ValidationResult_Parameters = {}) {
    super();
    this.Serializable$set(
        'validationErrors',
        (parameters.validationErrors == null) ? (null) :
                                                (parameters.validationErrors));
  }

  get validationErrors(): Array<ValidationError_>|null {
    return (
        (this.Serializable$has('validationErrors')) ?
            (this.Serializable$get('validationErrors')) :
            (null));
  }

  /**
   * Contains all validation errors.
   */
  set validationErrors(value: Array<ValidationError_>|null) {
    this.Serializable$set('validationErrors', value);
  }

  getConstructor(): SerializableCtor<ValidationResult_> {
    return ValidationResult_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'validationErrors': ValidationError_},
      keys: ['validationErrors']
    };
  }
}

export interface Version_Parameters {
  name?: string|null;
  description?: string|null;
  versionNumber?: number|null;
  createTime?: string|null;
  updateTime?: string|null;
  status?: Version_Status|null;
}
export class Version_ extends Serializable {
  constructor(parameters: Version_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'description',
        (parameters.description == null) ? (null) : (parameters.description));
    this.Serializable$set(
        'versionNumber',
        (parameters.versionNumber == null) ? (null) :
                                             (parameters.versionNumber));
    this.Serializable$set(
        'createTime',
        (parameters.createTime == null) ? (null) : (parameters.createTime));
    this.Serializable$set(
        'updateTime',
        (parameters.updateTime == null) ? (null) : (parameters.updateTime));
    this.Serializable$set(
        'status', (parameters.status == null) ? (null) : (parameters.status));
  }

  static get Status(): IVersion_StatusEnum {
    return Version_StatusEnum;
  }

  get createTime(): string|null {
    return (
        (this.Serializable$has('createTime')) ?
            (this.Serializable$get('createTime')) :
            (null));
  }

  /**
   * Output only. The creation time of this version. This field is read-only,
   * i.e., it cannot be set by create and update methods.
   */
  set createTime(value: string|null) {
    this.Serializable$set('createTime', value);
  }

  get description(): string|null {
    return (
        (this.Serializable$has('description')) ?
            (this.Serializable$get('description')) :
            (null));
  }

  /**
   * Optional. The developer-provided description of this version.
   */
  set description(value: string|null) {
    this.Serializable$set('description', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Output only. The unique identifier of this agent version. Supported
   * formats: - `projects//agent/versions/` -
   * `projects//locations//agent/versions/`
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get status(): Version_Status|null {
    return (
        (this.Serializable$has('status')) ? (this.Serializable$get('status')) :
                                            (null));
  }

  /**
   * Output only. The status of this version. This field is read-only and cannot
   * be set by create and update methods.
   */
  set status(value: Version_Status|null) {
    this.Serializable$set('status', value);
  }

  get updateTime(): string|null {
    return (
        (this.Serializable$has('updateTime')) ?
            (this.Serializable$get('updateTime')) :
            (null));
  }

  /**
   * Output only. Agent snapshot last modified timestamp. This field is
   * read-only, i.e., it cannot be set by create and update methods.
   */
  set updateTime(value: string|null) {
    this.Serializable$set('updateTime', value);
  }

  get versionNumber(): number|null {
    return (
        (this.Serializable$has('versionNumber')) ?
            (this.Serializable$get('versionNumber')) :
            (null));
  }

  /**
   * Output only. The sequential number of this version. This field is read-only
   * which means it cannot be set by create and update methods.
   */
  set versionNumber(value: number|null) {
    this.Serializable$set('versionNumber', value);
  }

  getConstructor(): SerializableCtor<Version_> {
    return Version_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'status': Version_StatusEnum},
      keys: [
        'createTime', 'description', 'name', 'status', 'updateTime',
        'versionNumber'
      ]
    };
  }
}

export interface VoiceSelectionParamsCustomVoiceParams_Parameters {
  model?: string|null;
  reportedUsage?: VoiceSelectionParamsCustomVoiceParams_ReportedUsage|null;
}
export class VoiceSelectionParamsCustomVoiceParams_ extends Serializable {
  constructor(
      parameters: VoiceSelectionParamsCustomVoiceParams_Parameters = {}) {
    super();
    this.Serializable$set(
        'model', (parameters.model == null) ? (null) : (parameters.model));
    this.Serializable$set(
        'reportedUsage',
        (parameters.reportedUsage == null) ? (null) :
                                             (parameters.reportedUsage));
  }

  static get ReportedUsage():
      IVoiceSelectionParamsCustomVoiceParams_ReportedUsageEnum {
    return VoiceSelectionParamsCustomVoiceParams_ReportedUsageEnum;
  }

  get model(): string|null {
    return (
        (this.Serializable$has('model')) ? (this.Serializable$get('model')) :
                                           (null));
  }

  /**
   * Required. The name of the AutoML model that synthesizes the custom voice.
   */
  set model(value: string|null) {
    this.Serializable$set('model', value);
  }

  get reportedUsage(): VoiceSelectionParamsCustomVoiceParams_ReportedUsage
      |null {
    return (
        (this.Serializable$has('reportedUsage')) ?
            (this.Serializable$get('reportedUsage')) :
            (null));
  }

  /**
   * Optional. The usage of the synthesized audio to be reported.
   */
  set reportedUsage(value: VoiceSelectionParamsCustomVoiceParams_ReportedUsage|
                    null) {
    this.Serializable$set('reportedUsage', value);
  }

  getConstructor(): SerializableCtor<VoiceSelectionParamsCustomVoiceParams_> {
    return VoiceSelectionParamsCustomVoiceParams_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {
        'reportedUsage': VoiceSelectionParamsCustomVoiceParams_ReportedUsageEnum
      },
      keys: ['model', 'reportedUsage']
    };
  }
}

export interface VoiceSelectionParams_Parameters {
  name?: string|null;
  ssmlGender?: VoiceSelectionParams_SsmlGender|null;
  customVoice?: VoiceSelectionParamsCustomVoiceParams_|null;
}
export class VoiceSelectionParams_ extends Serializable {
  constructor(parameters: VoiceSelectionParams_Parameters = {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'ssmlGender',
        (parameters.ssmlGender == null) ? (null) : (parameters.ssmlGender));
    this.Serializable$set(
        'customVoice',
        (parameters.customVoice == null) ? (null) : (parameters.customVoice));
  }

  static get SsmlGender(): IVoiceSelectionParams_SsmlGenderEnum {
    return VoiceSelectionParams_SsmlGenderEnum;
  }

  get customVoice(): VoiceSelectionParamsCustomVoiceParams_|null {
    return (
        (this.Serializable$has('customVoice')) ?
            (this.Serializable$get('customVoice')) :
            (null));
  }

  /**
   * Optional. Configuration for a custom synthesis voice. If specified, the
   * service will use this custom voice instead of taking name and ssml_gender
   * into account to select a voice. If the custom voice doesn't exist or isn't
   * accessible, Dialogflow will return an error.
   */
  set customVoice(value: VoiceSelectionParamsCustomVoiceParams_|null) {
    this.Serializable$set('customVoice', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Optional. The name of the voice. If not set, the service will choose a
   * voice based on the other parameters such as language_code and ssml_gender.
   * For the list of available voices, please refer to [Supported voices and
   * languages](https://cloud.google.com/text-to-speech/docs/voices).
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get ssmlGender(): VoiceSelectionParams_SsmlGender|null {
    return (
        (this.Serializable$has('ssmlGender')) ?
            (this.Serializable$get('ssmlGender')) :
            (null));
  }

  /**
   * Optional. The preferred gender of the voice. If not set, the service will
   * choose a voice based on the other parameters such as language_code and
   * name. Note that this is only a preference, not requirement. If a voice of
   * the appropriate gender is not available, the synthesizer should substitute
   * a voice with a different gender rather than failing the request.
   */
  set ssmlGender(value: VoiceSelectionParams_SsmlGender|null) {
    this.Serializable$set('ssmlGender', value);
  }

  getConstructor(): SerializableCtor<VoiceSelectionParams_> {
    return VoiceSelectionParams_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      enums: {'ssmlGender': VoiceSelectionParams_SsmlGenderEnum},
      keys: ['customVoice', 'name', 'ssmlGender'],
      objects: {'customVoice': VoiceSelectionParamsCustomVoiceParams_}
    };
  }
}

export interface WebhookRequest_Parameters {
  session?: string|null;
  responseId?: string|null;
  queryResult?: QueryResult_|null;
  alternativeQueryResults?: Array<QueryResult_>|null;
  originalDetectIntentRequest?: OriginalDetectIntentRequest_|null;
}
export class WebhookRequest_ extends Serializable {
  constructor(parameters: WebhookRequest_Parameters = {}) {
    super();
    this.Serializable$set(
        'session',
        (parameters.session == null) ? (null) : (parameters.session));
    this.Serializable$set(
        'responseId',
        (parameters.responseId == null) ? (null) : (parameters.responseId));
    this.Serializable$set(
        'queryResult',
        (parameters.queryResult == null) ? (null) : (parameters.queryResult));
    this.Serializable$set(
        'alternativeQueryResults',
        (parameters.alternativeQueryResults == null) ?
            (null) :
            (parameters.alternativeQueryResults));
    this.Serializable$set(
        'originalDetectIntentRequest',
        (parameters.originalDetectIntentRequest == null) ?
            (null) :
            (parameters.originalDetectIntentRequest));
  }

  get alternativeQueryResults(): Array<QueryResult_>|null {
    return (
        (this.Serializable$has('alternativeQueryResults')) ?
            (this.Serializable$get('alternativeQueryResults')) :
            (null));
  }

  /**
   * Alternative query results from KnowledgeService.
   */
  set alternativeQueryResults(value: Array<QueryResult_>|null) {
    this.Serializable$set('alternativeQueryResults', value);
  }

  get originalDetectIntentRequest(): OriginalDetectIntentRequest_|null {
    return (
        (this.Serializable$has('originalDetectIntentRequest')) ?
            (this.Serializable$get('originalDetectIntentRequest')) :
            (null));
  }

  /**
   * Optional. The contents of the original request that was passed to
   * `[Streaming]DetectIntent` call.
   */
  set originalDetectIntentRequest(value: OriginalDetectIntentRequest_|null) {
    this.Serializable$set('originalDetectIntentRequest', value);
  }

  get queryResult(): QueryResult_|null {
    return (
        (this.Serializable$has('queryResult')) ?
            (this.Serializable$get('queryResult')) :
            (null));
  }

  /**
   * The result of the conversational query or event processing. Contains the
   * same value as `[Streaming]DetectIntentResponse.query_result`.
   */
  set queryResult(value: QueryResult_|null) {
    this.Serializable$set('queryResult', value);
  }

  get responseId(): string|null {
    return (
        (this.Serializable$has('responseId')) ?
            (this.Serializable$get('responseId')) :
            (null));
  }

  /**
   * The unique identifier of the response. Contains the same value as
   * `[Streaming]DetectIntentResponse.response_id`.
   */
  set responseId(value: string|null) {
    this.Serializable$set('responseId', value);
  }

  get session(): string|null {
    return (
        (this.Serializable$has('session')) ?
            (this.Serializable$get('session')) :
            (null));
  }

  /**
   * The unique identifier of detectIntent request session. Can be used to
   * identify end-user inside webhook implementation. Supported formats: -
   * `projects//agent/sessions/, - `projects//locations//agent/sessions/`, -
   * `projects//agent/environments//users//sessions/`, -
   * `projects//locations//agent/environments//users//sessions/`,
   */
  set session(value: string|null) {
    this.Serializable$set('session', value);
  }

  getConstructor(): SerializableCtor<WebhookRequest_> {
    return WebhookRequest_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {'alternativeQueryResults': QueryResult_},
      keys: [
        'alternativeQueryResults', 'originalDetectIntentRequest', 'queryResult',
        'responseId', 'session'
      ],
      objects: {
        'originalDetectIntentRequest': OriginalDetectIntentRequest_,
        'queryResult': QueryResult_
      }
    };
  }
}

export interface WebhookResponseWebhookResponseMetadataGoogleLibraryInfo_Parameters {
  name?: string|null;
  language?: string|null;
  version?: string|null;
}
export class WebhookResponseWebhookResponseMetadataGoogleLibraryInfo_ extends
    Serializable {
  constructor(
      parameters:
          WebhookResponseWebhookResponseMetadataGoogleLibraryInfo_Parameters =
              {}) {
    super();
    this.Serializable$set(
        'name', (parameters.name == null) ? (null) : (parameters.name));
    this.Serializable$set(
        'language',
        (parameters.language == null) ? (null) : (parameters.language));
    this.Serializable$set(
        'version',
        (parameters.version == null) ? (null) : (parameters.version));
  }

  get language(): string|null {
    return (
        (this.Serializable$has('language')) ?
            (this.Serializable$get('language')) :
            (null));
  }

  /**
   * Programming language of Google library used by developer for fulfillment.
   */
  set language(value: string|null) {
    this.Serializable$set('language', value);
  }

  get name(): string|null {
    return (
        (this.Serializable$has('name')) ? (this.Serializable$get('name')) :
                                          (null));
  }

  /**
   * Name of Google library used by developer for fulfillment. Example values
   * include `dialogflow` for the Dialogflow fulfillment library and `actions`
   * for Actions on Google libraries.
   */
  set name(value: string|null) {
    this.Serializable$set('name', value);
  }

  get version(): string|null {
    return (
        (this.Serializable$has('version')) ?
            (this.Serializable$get('version')) :
            (null));
  }

  /**
   * Version of Google library used by developer for fulfillment.
   */
  set version(value: string|null) {
    this.Serializable$set('version', value);
  }

  getConstructor(): SerializableCtor<
      WebhookResponseWebhookResponseMetadataGoogleLibraryInfo_> {
    return WebhookResponseWebhookResponseMetadataGoogleLibraryInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['language', 'name', 'version']};
  }
}

export interface WebhookResponseWebhookResponseMetadata_Parameters {
  googleLibrary?: WebhookResponseWebhookResponseMetadataGoogleLibraryInfo_|null;
}
export class WebhookResponseWebhookResponseMetadata_ extends Serializable {
  constructor(
      parameters: WebhookResponseWebhookResponseMetadata_Parameters = {}) {
    super();
    this.Serializable$set(
        'googleLibrary',
        (parameters.googleLibrary == null) ? (null) :
                                             (parameters.googleLibrary));
  }

  get googleLibrary(): WebhookResponseWebhookResponseMetadataGoogleLibraryInfo_
      |null {
    return (
        (this.Serializable$has('googleLibrary')) ?
            (this.Serializable$get('googleLibrary')) :
            (null));
  }

  /**
   * Optional. Fulfillment library that was used to generate webhook response.
   */
  set googleLibrary(
      value: WebhookResponseWebhookResponseMetadataGoogleLibraryInfo_|null) {
    this.Serializable$set('googleLibrary', value);
  }

  getConstructor(): SerializableCtor<WebhookResponseWebhookResponseMetadata_> {
    return WebhookResponseWebhookResponseMetadata_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['googleLibrary'],
      objects: {
        'googleLibrary':
            WebhookResponseWebhookResponseMetadataGoogleLibraryInfo_
      }
    };
  }
}

export interface WebhookResponse_Parameters {
  fulfillmentText?: string|null;
  fulfillmentMessages?: Array<IntentMessage_>|null;
  source?: string|null;
  payload?: ApiClientObjectMap<any>|null;
  outputContexts?: Array<Context_>|null;
  followupEventInput?: EventInput_|null;
  liveAgentHandoff?: boolean|null;
  endInteraction?: boolean|null;
  metadata?: WebhookResponseWebhookResponseMetadata_|null;
  sessionEntityTypes?: Array<SessionEntityType_>|null;
}
export class WebhookResponse_ extends Serializable {
  constructor(parameters: WebhookResponse_Parameters = {}) {
    super();
    this.Serializable$set(
        'fulfillmentText',
        (parameters.fulfillmentText == null) ? (null) :
                                               (parameters.fulfillmentText));
    this.Serializable$set(
        'fulfillmentMessages',
        (parameters.fulfillmentMessages == null) ?
            (null) :
            (parameters.fulfillmentMessages));
    this.Serializable$set(
        'source', (parameters.source == null) ? (null) : (parameters.source));
    this.Serializable$set(
        'payload',
        (parameters.payload == null) ? (null) : (parameters.payload));
    this.Serializable$set(
        'outputContexts',
        (parameters.outputContexts == null) ? (null) :
                                              (parameters.outputContexts));
    this.Serializable$set(
        'followupEventInput',
        (parameters.followupEventInput == null) ?
            (null) :
            (parameters.followupEventInput));
    this.Serializable$set(
        'liveAgentHandoff',
        (parameters.liveAgentHandoff == null) ? (null) :
                                                (parameters.liveAgentHandoff));
    this.Serializable$set(
        'endInteraction',
        (parameters.endInteraction == null) ? (null) :
                                              (parameters.endInteraction));
    this.Serializable$set(
        'metadata',
        (parameters.metadata == null) ? (null) : (parameters.metadata));
    this.Serializable$set(
        'sessionEntityTypes',
        (parameters.sessionEntityTypes == null) ?
            (null) :
            (parameters.sessionEntityTypes));
  }

  get endInteraction(): boolean|null {
    return (
        (this.Serializable$has('endInteraction')) ?
            (this.Serializable$get('endInteraction')) :
            (null));
  }

  /**
   * Optional. Indicates that this intent ends an interaction. Some integrations
   * (e.g., Actions on Google or Dialogflow phone gateway) use this information
   * to close interaction with an end user. Default is false.
   */
  set endInteraction(value: boolean|null) {
    this.Serializable$set('endInteraction', value);
  }

  get followupEventInput(): EventInput_|null {
    return (
        (this.Serializable$has('followupEventInput')) ?
            (this.Serializable$get('followupEventInput')) :
            (null));
  }

  /**
   * Optional. Invokes the supplied events. When this field is set, Dialogflow
   * ignores the `fulfillment_text`, `fulfillment_messages`, and `payload`
   * fields.
   */
  set followupEventInput(value: EventInput_|null) {
    this.Serializable$set('followupEventInput', value);
  }

  get fulfillmentMessages(): Array<IntentMessage_>|null {
    return (
        (this.Serializable$has('fulfillmentMessages')) ?
            (this.Serializable$get('fulfillmentMessages')) :
            (null));
  }

  /**
   * Optional. The rich response messages intended for the end-user. When
   * provided, Dialogflow uses this field to populate
   * QueryResult.fulfillment_messages sent to the integration or API caller.
   */
  set fulfillmentMessages(value: Array<IntentMessage_>|null) {
    this.Serializable$set('fulfillmentMessages', value);
  }

  get fulfillmentText(): string|null {
    return (
        (this.Serializable$has('fulfillmentText')) ?
            (this.Serializable$get('fulfillmentText')) :
            (null));
  }

  /**
   * Optional. The text response message intended for the end-user. It is
   * recommended to use `fulfillment_messages.text.text[0]` instead. When
   * provided, Dialogflow uses this field to populate
   * QueryResult.fulfillment_text sent to the integration or API caller.
   */
  set fulfillmentText(value: string|null) {
    this.Serializable$set('fulfillmentText', value);
  }

  get liveAgentHandoff(): boolean|null {
    return (
        (this.Serializable$has('liveAgentHandoff')) ?
            (this.Serializable$get('liveAgentHandoff')) :
            (null));
  }

  /**
   * Indicates that a live agent should be brought in to handle the interaction
   * with the user. In most cases, when you set this flag to true, you would
   * also want to set end_interaction to true as well. Default is false.
   */
  set liveAgentHandoff(value: boolean|null) {
    this.Serializable$set('liveAgentHandoff', value);
  }

  get metadata(): WebhookResponseWebhookResponseMetadata_|null {
    return (
        (this.Serializable$has('metadata')) ?
            (this.Serializable$get('metadata')) :
            (null));
  }

  /**
   * Optional. Extra metadata information about the webhook response.
   */
  set metadata(value: WebhookResponseWebhookResponseMetadata_|null) {
    this.Serializable$set('metadata', value);
  }

  get outputContexts(): Array<Context_>|null {
    return (
        (this.Serializable$has('outputContexts')) ?
            (this.Serializable$get('outputContexts')) :
            (null));
  }

  /**
   * Optional. The collection of output contexts that will overwrite currently
   * active contexts for the session and reset their lifespans. When provided,
   * Dialogflow uses this field to populate QueryResult.output_contexts sent to
   * the integration or API caller.
   */
  set outputContexts(value: Array<Context_>|null) {
    this.Serializable$set('outputContexts', value);
  }

  get payload(): ApiClientObjectMap<any>|null {
    return (
        (this.Serializable$has('payload')) ?
            (this.Serializable$get('payload')) :
            (null));
  }

  /**
   * Optional. This field can be used to pass custom data from your webhook to
   * the integration or API caller. Arbitrary JSON objects are supported. When
   * provided, Dialogflow uses this field to populate
   * QueryResult.webhook_payload sent to the integration or API caller. This
   * field is also used by the [Google Assistant
   * integration](https://cloud.google.com/dialogflow/docs/integrations/aog) for
   * rich response messages. See the format definition at [Google Assistant
   * Dialogflow webhook
   * format](https://developers.google.com/assistant/actions/build/json/dialogflow-webhook-json)
   */
  set payload(value: ApiClientObjectMap<any>|null) {
    this.Serializable$set('payload', value);
  }

  get sessionEntityTypes(): Array<SessionEntityType_>|null {
    return (
        (this.Serializable$has('sessionEntityTypes')) ?
            (this.Serializable$get('sessionEntityTypes')) :
            (null));
  }

  /**
   * Optional. Additional session entity types to replace or extend developer
   * entity types with. The entity synonyms apply to all languages and persist
   * for the session. Setting this data from a webhook overwrites the session
   * entity types that have been set using `detectIntent`,
   * `streamingDetectIntent` or SessionEntityType management methods.
   */
  set sessionEntityTypes(value: Array<SessionEntityType_>|null) {
    this.Serializable$set('sessionEntityTypes', value);
  }

  get source(): string|null {
    return (
        (this.Serializable$has('source')) ? (this.Serializable$get('source')) :
                                            (null));
  }

  /**
   * Optional. A custom field used to identify the webhook source. Arbitrary
   * strings are supported. When provided, Dialogflow uses this field to
   * populate QueryResult.webhook_source sent to the integration or API caller.
   */
  set source(value: string|null) {
    this.Serializable$set('source', value);
  }

  getConstructor(): SerializableCtor<WebhookResponse_> {
    return WebhookResponse_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      arrays: {
        'fulfillmentMessages': IntentMessage_,
        'outputContexts': Context_,
        'sessionEntityTypes': SessionEntityType_
      },
      keys: [
        'endInteraction', 'followupEventInput', 'fulfillmentMessages',
        'fulfillmentText', 'liveAgentHandoff', 'metadata', 'outputContexts',
        'payload', 'sessionEntityTypes', 'source'
      ],
      objectMaps: {
        'payload': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      },
      objects: {
        'followupEventInput': EventInput_,
        'metadata': WebhookResponseWebhookResponseMetadata_
      }
    };
  }
}

export interface WebhookSettings_Parameters {
  url?: string|null;
  username?: string|null;
  password?: string|null;
  basicAuthorizationCode?: string|null;
  headers?: ApiClientObjectMap<string>|null;
  available?: boolean|null;
  useForDomains?: boolean|null;
  cloudFunctionsEnabled?: boolean|null;
  cloudFunctionsInitialized?: boolean|null;
}
export class WebhookSettings_ extends Serializable {
  constructor(parameters: WebhookSettings_Parameters = {}) {
    super();
    this.Serializable$set(
        'url', (parameters.url == null) ? (null) : (parameters.url));
    this.Serializable$set(
        'username',
        (parameters.username == null) ? (null) : (parameters.username));
    this.Serializable$set(
        'password',
        (parameters.password == null) ? (null) : (parameters.password));
    this.Serializable$set(
        'basicAuthorizationCode',
        (parameters.basicAuthorizationCode == null) ?
            (null) :
            (parameters.basicAuthorizationCode));
    this.Serializable$set(
        'headers',
        (parameters.headers == null) ? (null) : (parameters.headers));
    this.Serializable$set(
        'available',
        (parameters.available == null) ? (null) : (parameters.available));
    this.Serializable$set(
        'useForDomains',
        (parameters.useForDomains == null) ? (null) :
                                             (parameters.useForDomains));
    this.Serializable$set(
        'cloudFunctionsEnabled',
        (parameters.cloudFunctionsEnabled == null) ?
            (null) :
            (parameters.cloudFunctionsEnabled));
    this.Serializable$set(
        'cloudFunctionsInitialized',
        (parameters.cloudFunctionsInitialized == null) ?
            (null) :
            (parameters.cloudFunctionsInitialized));
  }

  get available(): boolean|null {
    return (
        (this.Serializable$has('available')) ?
            (this.Serializable$get('available')) :
            (null));
  }

  /**
   * Required. Is webhook available
   */
  set available(value: boolean|null) {
    this.Serializable$set('available', value);
  }

  get basicAuthorizationCode(): string|null {
    return (
        (this.Serializable$has('basicAuthorizationCode')) ?
            (this.Serializable$get('basicAuthorizationCode')) :
            (null));
  }

  /**
   * Optional. Authorization code
   */
  set basicAuthorizationCode(value: string|null) {
    this.Serializable$set('basicAuthorizationCode', value);
  }

  get cloudFunctionsEnabled(): boolean|null {
    return (
        (this.Serializable$has('cloudFunctionsEnabled')) ?
            (this.Serializable$get('cloudFunctionsEnabled')) :
            (null));
  }

  /**
   * Required. Is cloud functions enabled for this webhook
   */
  set cloudFunctionsEnabled(value: boolean|null) {
    this.Serializable$set('cloudFunctionsEnabled', value);
  }

  get cloudFunctionsInitialized(): boolean|null {
    return (
        (this.Serializable$has('cloudFunctionsInitialized')) ?
            (this.Serializable$get('cloudFunctionsInitialized')) :
            (null));
  }

  /**
   * Required. Is cloud function initialized for this webhook
   */
  set cloudFunctionsInitialized(value: boolean|null) {
    this.Serializable$set('cloudFunctionsInitialized', value);
  }

  get headers(): ApiClientObjectMap<string>|null {
    return (
        (this.Serializable$has('headers')) ?
            (this.Serializable$get('headers')) :
            (null));
  }

  /**
   * Optional. List of headers
   */
  set headers(value: ApiClientObjectMap<string>|null) {
    this.Serializable$set('headers', value);
  }

  get password(): string|null {
    return (
        (this.Serializable$has('password')) ?
            (this.Serializable$get('password')) :
            (null));
  }

  /**
   * Optional. Authorization password
   */
  set password(value: string|null) {
    this.Serializable$set('password', value);
  }

  get url(): string|null {
    return (
        (this.Serializable$has('url')) ? (this.Serializable$get('url')) :
                                         (null));
  }

  /**
   * Required. URL to be called
   */
  set url(value: string|null) {
    this.Serializable$set('url', value);
  }

  get useForDomains(): boolean|null {
    return (
        (this.Serializable$has('useForDomains')) ?
            (this.Serializable$get('useForDomains')) :
            (null));
  }

  /**
   * Required. Is webhook used for domains recognition
   */
  set useForDomains(value: boolean|null) {
    this.Serializable$set('useForDomains', value);
  }

  get username(): string|null {
    return (
        (this.Serializable$has('username')) ?
            (this.Serializable$get('username')) :
            (null));
  }

  /**
   * Optional. Authorization username
   */
  set username(value: string|null) {
    this.Serializable$set('username', value);
  }

  getConstructor(): SerializableCtor<WebhookSettings_> {
    return WebhookSettings_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: [
        'available', 'basicAuthorizationCode', 'cloudFunctionsEnabled',
        'cloudFunctionsInitialized', 'headers', 'password', 'url',
        'useForDomains', 'username'
      ],
      objectMaps: {
        'headers': {
          ctor: null,
          isPropertyArray: false,
          isSerializable: false,
          isValueArray: false
        }
      }
    };
  }
}

export interface WeightedTerm_Parameters {
  term?: string|null;
  weight?: number|null;
  occurrence?: number|null;
}
export class WeightedTerm_ extends Serializable {
  constructor(parameters: WeightedTerm_Parameters = {}) {
    super();
    this.Serializable$set(
        'term', (parameters.term == null) ? (null) : (parameters.term));
    this.Serializable$set(
        'weight', (parameters.weight == null) ? (null) : (parameters.weight));
    this.Serializable$set(
        'occurrence',
        (parameters.occurrence == null) ? (null) : (parameters.occurrence));
  }

  get occurrence(): number|null {
    return (
        (this.Serializable$has('occurrence')) ?
            (this.Serializable$get('occurrence')) :
            (null));
  }

  /**
   * The number of occurrences of the term.
   */
  set occurrence(value: number|null) {
    this.Serializable$set('occurrence', value);
  }

  get term(): string|null {
    return (
        (this.Serializable$has('term')) ? (this.Serializable$get('term')) :
                                          (null));
  }

  /**
   * The term.
   */
  set term(value: string|null) {
    this.Serializable$set('term', value);
  }

  get weight(): number|null {
    return (
        (this.Serializable$has('weight')) ? (this.Serializable$get('weight')) :
                                            (null));
  }

  /**
   * Weight of the term.
   */
  set weight(value: number|null) {
    this.Serializable$set('weight', value);
  }

  getConstructor(): SerializableCtor<WeightedTerm_> {
    return WeightedTerm_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['occurrence', 'term', 'weight']};
  }
}

export interface WindowInfoBoundary_Parameters {
  transcriptIndex?: number|null;
  timeOffset?: string|null;
  wordIndex?: number|null;
}
export class WindowInfoBoundary_ extends Serializable {
  constructor(parameters: WindowInfoBoundary_Parameters = {}) {
    super();
    this.Serializable$set(
        'transcriptIndex',
        (parameters.transcriptIndex == null) ? (null) :
                                               (parameters.transcriptIndex));
    this.Serializable$set(
        'timeOffset',
        (parameters.timeOffset == null) ? (null) : (parameters.timeOffset));
    this.Serializable$set(
        'wordIndex',
        (parameters.wordIndex == null) ? (null) : (parameters.wordIndex));
  }

  get timeOffset(): string|null {
    return (
        (this.Serializable$has('timeOffset')) ?
            (this.Serializable$get('timeOffset')) :
            (null));
  }

  /**
   * The time offset of this boundary with respect to the time of the first word
   * in the transcript piece.
   */
  set timeOffset(value: string|null) {
    this.Serializable$set('timeOffset', value);
  }

  get transcriptIndex(): number|null {
    return (
        (this.Serializable$has('transcriptIndex')) ?
            (this.Serializable$get('transcriptIndex')) :
            (null));
  }

  /**
   * The index in the sequence of transcribed pieces of the conversation where
   * the boundary is located.
   */
  set transcriptIndex(value: number|null) {
    this.Serializable$set('transcriptIndex', value);
  }

  get wordIndex(): number|null {
    return (
        (this.Serializable$has('wordIndex')) ?
            (this.Serializable$get('wordIndex')) :
            (null));
  }

  /**
   * The word index of this boundary with respect to the first word in the
   * transcript piece.
   */
  set wordIndex(value: number|null) {
    this.Serializable$set('wordIndex', value);
  }

  getConstructor(): SerializableCtor<WindowInfoBoundary_> {
    return WindowInfoBoundary_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {keys: ['timeOffset', 'transcriptIndex', 'wordIndex']};
  }
}

export interface WindowInfo_Parameters {
  startBoundary?: WindowInfoBoundary_|null;
  endBoundary?: WindowInfoBoundary_|null;
  inferenceScore?: number|null;
}
export class WindowInfo_ extends Serializable {
  constructor(parameters: WindowInfo_Parameters = {}) {
    super();
    this.Serializable$set(
        'startBoundary',
        (parameters.startBoundary == null) ? (null) :
                                             (parameters.startBoundary));
    this.Serializable$set(
        'endBoundary',
        (parameters.endBoundary == null) ? (null) : (parameters.endBoundary));
    this.Serializable$set(
        'inferenceScore',
        (parameters.inferenceScore == null) ? (null) :
                                              (parameters.inferenceScore));
  }

  get endBoundary(): WindowInfoBoundary_|null {
    return (
        (this.Serializable$has('endBoundary')) ?
            (this.Serializable$get('endBoundary')) :
            (null));
  }

  /**
   * The boundary in the call where the annotation ends, inclusive.
   */
  set endBoundary(value: WindowInfoBoundary_|null) {
    this.Serializable$set('endBoundary', value);
  }

  get inferenceScore(): number|null {
    return (
        (this.Serializable$has('inferenceScore')) ?
            (this.Serializable$get('inferenceScore')) :
            (null));
  }

  /**
   * Score for this window.
   */
  set inferenceScore(value: number|null) {
    this.Serializable$set('inferenceScore', value);
  }

  get startBoundary(): WindowInfoBoundary_|null {
    return (
        (this.Serializable$has('startBoundary')) ?
            (this.Serializable$get('startBoundary')) :
            (null));
  }

  /**
   * Start of this text information relative to the text input from the request.
   */
  set startBoundary(value: WindowInfoBoundary_|null) {
    this.Serializable$set('startBoundary', value);
  }

  getConstructor(): SerializableCtor<WindowInfo_> {
    return WindowInfo_;
  }

  getPartialClassMetadata(): Partial<ClassMetadata> {
    return {
      keys: ['endBoundary', 'inferenceScore', 'startBoundary'],
      objects: {
        'endBoundary': WindowInfoBoundary_,
        'startBoundary': WindowInfoBoundary_
      }
    };
  }
}
const PARAM_MAP_0 = {
  $Xgafv: '$.xgafv',
  aamDiscriminants: 'aamDiscriminants',
  aamLabels: 'aamLabels',
  aamUtterances: 'aamUtterances',
  access_token: 'access_token',
  accumulationMode: 'accumulationMode',
  allowLoadToDraftAndDiscardChanges: 'allowLoadToDraftAndDiscardChanges',
  allowMissing: 'allowMissing',
  alt: 'alt',
  analyticsView: 'analyticsView',
  batchLabellingRequestDatasetId: 'batchLabellingRequest.datasetId',
  bypassDeprecation: 'bypassDeprecation',
  callback: 'callback',
  conversationId: 'conversationId',
  creatorGaiaId: 'creatorGaiaId',
  disableCache: 'disableCache',
  discriminant: 'discriminant',
  displayName: 'displayName',
  enableEnvironmentLimit: 'enableEnvironmentLimit',
  enableVersionLimit: 'enableVersionLimit',
  endTime: 'endTime',
  environment: 'environment',
  environmentId: 'environmentId',
  etag: 'etag',
  featureCreationFlowId: 'featureCreationFlowId',
  fields: 'fields',
  filter: 'filter',
  filterClusterId: 'filter.clusterId',
  filterManualLabelLabel: 'filter.manualLabel.label',
  filterManualLabelScore: 'filter.manualLabel.score',
  filterMessageContent: 'filter.messageContent',
  filterMessageReferenceConversationId:
      'filter.messageReference.conversationId',
  filterMessageReferenceTurnId: 'filter.messageReference.turnId',
  filterParentDiscriminant: 'filter.parentDiscriminant',
  filterParentTopicId: 'filter.parentTopicId',
  filterSourceNodeId: 'filter.sourceNodeId',
  filterTargetNodeId: 'filter.targetNodeId',
  filterTopicId: 'filter.topicId',
  filterTriggeringIntentScoreThreshold: 'filter.triggeringIntentScoreThreshold',
  filterTriggeringNodeId: 'filter.triggeringNodeId',
  force: 'force',
  fullUpdate: 'fullUpdate',
  gcsBucket: 'gcsBucket',
  gcsDestinationUri: 'gcsDestination.uri',
  importGcsCustomMetadata: 'importGcsCustomMetadata',
  includeUnrevealedLocations: 'includeUnrevealedLocations',
  integrationView: 'integrationView',
  intent: 'intent',
  intentClusteringRequestEmbeddingsDimension:
      'intentClusteringRequest.embeddingsDimension',
  intentClusteringRequestRunId: 'intentClusteringRequest.runId',
  intentMatchFilter: 'intentMatchFilter',
  intentView: 'intentView',
  interactionsPageSize: 'interactionsPageSize',
  internalAddAgentAssistMetrics: 'internalAddAgentAssistMetrics',
  internalExportAsProtoAddFeedbackLoopMetrics:
      'internalExportAsProtoAddFeedbackLoopMetrics',
  internalIncludeOpenConversations: 'internalIncludeOpenConversations',
  justification: 'justification',
  key: 'key',
  languageCode: 'languageCode',
  listOffset: 'listOffset',
  mutation: 'mutation',
  oauth_token: 'oauth_token',
  orderBy: 'orderBy',
  outcomeClusteringRequestEmbeddingsDimension:
      'outcomeClusteringRequest.embeddingsDimension',
  pageSize: 'pageSize',
  pageToken: 'pageToken',
  parentDiscriminant: 'parentDiscriminant',
  parentDiscriminantId: 'parentDiscriminantId',
  phoneNumber: 'phoneNumber',
  platform: 'platform',
  prettyPrint: 'prettyPrint',
  projectIds: 'projectIds',
  query: 'query',
  queryEnableSpellingCorrection: 'query.enableSpellingCorrection',
  queryEnableSplittingText: 'query.enableSplittingText',
  queryIsAudioInput: 'query.isAudioInput',
  queryLanguageCode: 'query.languageCode',
  querySpecificVersion: 'querySpecificVersion',
  queryText: 'query.text',
  quotaUser: 'quotaUser',
  requestSource: 'requestSource',
  resourceTypes: 'resourceTypes',
  searchBackward: 'searchBackward',
  serviceRequestSource: 'serviceRequestSource',
  shouldReclassify: 'shouldReclassify',
  showDeleted: 'showDeleted',
  startTime: 'startTime',
  transcript: 'transcript',
  type: 'type',
  typeFilter: 'typeFilter',
  types: 'types',
  updateMask: 'updateMask',
  uploadType: 'uploadType',
  upload_protocol: 'upload_protocol',
  userGaiaId: 'userGaiaId',
  validateOnly: 'validateOnly',
  versionId: 'versionId',
  versionNumber: 'versionNumber',
  view: 'view',
  withoutActionsOnGoogle: 'withoutActionsOnGoogle'
};

export type CountryCodesAvailableAreaCodesApiClient$Xgafv = '1'|'2';

export interface ICountryCodesAvailableAreaCodesApiClient$XgafvEnum {
  readonly 1: CountryCodesAvailableAreaCodesApiClient$Xgafv;
  readonly 2: CountryCodesAvailableAreaCodesApiClient$Xgafv;

  values(): Array<CountryCodesAvailableAreaCodesApiClient$Xgafv>;
}

export const CountryCodesAvailableAreaCodesApiClient$XgafvEnum:
    ICountryCodesAvailableAreaCodesApiClient$XgafvEnum = {
      1: <CountryCodesAvailableAreaCodesApiClient$Xgafv>'1',
      2: <CountryCodesAvailableAreaCodesApiClient$Xgafv>'2',
      values(): Array<CountryCodesAvailableAreaCodesApiClient$Xgafv> {
        return [
          CountryCodesAvailableAreaCodesApiClient$XgafvEnum[1],
          CountryCodesAvailableAreaCodesApiClient$XgafvEnum[2]
        ];
      }
    };

export type CountryCodesAvailableAreaCodesApiClientAlt = 'json'|'media'|'proto';

export interface ICountryCodesAvailableAreaCodesApiClientAltEnum {
  readonly JSON: CountryCodesAvailableAreaCodesApiClientAlt;
  readonly MEDIA: CountryCodesAvailableAreaCodesApiClientAlt;
  readonly PROTO: CountryCodesAvailableAreaCodesApiClientAlt;

  values(): Array<CountryCodesAvailableAreaCodesApiClientAlt>;
}

export const CountryCodesAvailableAreaCodesApiClientAltEnum:
    ICountryCodesAvailableAreaCodesApiClientAltEnum = {
      JSON: <CountryCodesAvailableAreaCodesApiClientAlt>'json',
      MEDIA: <CountryCodesAvailableAreaCodesApiClientAlt>'media',
      PROTO: <CountryCodesAvailableAreaCodesApiClientAlt>'proto',
      values(): Array<CountryCodesAvailableAreaCodesApiClientAlt> {
        return [
          CountryCodesAvailableAreaCodesApiClientAltEnum.JSON,
          CountryCodesAvailableAreaCodesApiClientAltEnum.MEDIA,
          CountryCodesAvailableAreaCodesApiClientAltEnum.PROTO
        ];
      }
    };

export declare interface CountryCodesAvailableAreaCodesListNamedParameters {
  access_token?: string;
  alt?: CountryCodesAvailableAreaCodesApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: CountryCodesAvailableAreaCodesApiClient$Xgafv;
}

export class CountryCodesAvailableAreaCodesApiClientImpl implements
    CountryCodesAvailableAreaCodesApiClient {
  private $apiClient: ObservableApiClient;

  constructor(
      private gapiVersion: string, gapiRequestService: ObservableRequestService,
      apiClientHookFactory: ApiClientHookFactory|null = null) {
    this.$apiClient =
        new ObservableApiClient(gapiRequestService, apiClientHookFactory);
  }

  list(
      parent: string,
      namedParameters: CountryCodesAvailableAreaCodesListNamedParameters&
      object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<ListAvailableAreaCodesResponse_> {
    this.$apiClient.$validateParameter(
        parent, new RegExp('^countryCodes/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ListAvailableAreaCodesResponse_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.countryCodes.availableAreaCodes.list',
      path: `/${this.gapiVersion}/${parent}/availableAreaCodes`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ListAvailableAreaCodesResponse_
    });
  }
}

export abstract class CountryCodesAvailableAreaCodesApiClient {
  constructor() {}

  abstract list(
      parent: string,
      namedParameters?: CountryCodesAvailableAreaCodesListNamedParameters&
      object,
      passthroughNamedParameters?: globalThis.Record<string, string>):
      Observable<ListAvailableAreaCodesResponse_>;
}

export type CountryCodesLocationsAvailableAreaCodesApiClient$Xgafv = '1'|'2';

export interface ICountryCodesLocationsAvailableAreaCodesApiClient$XgafvEnum {
  readonly 1: CountryCodesLocationsAvailableAreaCodesApiClient$Xgafv;
  readonly 2: CountryCodesLocationsAvailableAreaCodesApiClient$Xgafv;

  values(): Array<CountryCodesLocationsAvailableAreaCodesApiClient$Xgafv>;
}

export const CountryCodesLocationsAvailableAreaCodesApiClient$XgafvEnum:
    ICountryCodesLocationsAvailableAreaCodesApiClient$XgafvEnum = {
      1: <CountryCodesLocationsAvailableAreaCodesApiClient$Xgafv>'1',
      2: <CountryCodesLocationsAvailableAreaCodesApiClient$Xgafv>'2',
      values(): Array<CountryCodesLocationsAvailableAreaCodesApiClient$Xgafv> {
        return [
          CountryCodesLocationsAvailableAreaCodesApiClient$XgafvEnum[1],
          CountryCodesLocationsAvailableAreaCodesApiClient$XgafvEnum[2]
        ];
      }
    };

export type CountryCodesLocationsAvailableAreaCodesApiClientAlt =
    'json'|'media'|'proto';

export interface ICountryCodesLocationsAvailableAreaCodesApiClientAltEnum {
  readonly JSON: CountryCodesLocationsAvailableAreaCodesApiClientAlt;
  readonly MEDIA: CountryCodesLocationsAvailableAreaCodesApiClientAlt;
  readonly PROTO: CountryCodesLocationsAvailableAreaCodesApiClientAlt;

  values(): Array<CountryCodesLocationsAvailableAreaCodesApiClientAlt>;
}

export const CountryCodesLocationsAvailableAreaCodesApiClientAltEnum:
    ICountryCodesLocationsAvailableAreaCodesApiClientAltEnum = {
      JSON: <CountryCodesLocationsAvailableAreaCodesApiClientAlt>'json',
      MEDIA: <CountryCodesLocationsAvailableAreaCodesApiClientAlt>'media',
      PROTO: <CountryCodesLocationsAvailableAreaCodesApiClientAlt>'proto',
      values(): Array<CountryCodesLocationsAvailableAreaCodesApiClientAlt> {
        return [
          CountryCodesLocationsAvailableAreaCodesApiClientAltEnum.JSON,
          CountryCodesLocationsAvailableAreaCodesApiClientAltEnum.MEDIA,
          CountryCodesLocationsAvailableAreaCodesApiClientAltEnum.PROTO
        ];
      }
    };

export declare interface CountryCodesLocationsAvailableAreaCodesListNamedParameters {
  access_token?: string;
  alt?: CountryCodesLocationsAvailableAreaCodesApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: CountryCodesLocationsAvailableAreaCodesApiClient$Xgafv;
}

export class CountryCodesLocationsAvailableAreaCodesApiClientImpl implements
    CountryCodesLocationsAvailableAreaCodesApiClient {
  private $apiClient: ObservableApiClient;

  constructor(
      private gapiVersion: string, gapiRequestService: ObservableRequestService,
      apiClientHookFactory: ApiClientHookFactory|null = null) {
    this.$apiClient =
        new ObservableApiClient(gapiRequestService, apiClientHookFactory);
  }

  list(
      parent: string,
      namedParameters:
          CountryCodesLocationsAvailableAreaCodesListNamedParameters&
      object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<ListAvailableAreaCodesResponse_> {
    this.$apiClient.$validateParameter(
        parent, new RegExp('^countryCodes/[^/]+/locations/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ListAvailableAreaCodesResponse_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.countryCodes.locations.availableAreaCodes.list',
      path: `/${this.gapiVersion}/${parent}/availableAreaCodes`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ListAvailableAreaCodesResponse_
    });
  }
}

export abstract class CountryCodesLocationsAvailableAreaCodesApiClient {
  constructor() {}

  abstract list(
      parent: string,
      namedParameters?:
          CountryCodesLocationsAvailableAreaCodesListNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>):
      Observable<ListAvailableAreaCodesResponse_>;
}

export type InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv =
    '1'|'2';

export interface IInternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$XgafvEnum {
  readonly 1:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv;
  readonly 2:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv;

  values(): Array<
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv>;
}

export const InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$XgafvEnum:
    IInternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$XgafvEnum = {
      1: <InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv>'1',
      2: <InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv>'2',
      values(): Array<
          InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv> {
        return [
          InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$XgafvEnum
              [1],
          InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$XgafvEnum
              [2]
        ];
      }
    };

export type InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt =
    'json'|'media'|'proto';

export interface IInternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum {
  readonly JSON:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt;
  readonly MEDIA:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt;
  readonly PROTO:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt;

  values(): Array<
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt>;
}

export const InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum:
    IInternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum = {
      JSON: <
          InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt>'json',
      MEDIA: <
          InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt>'media',
      PROTO: <
          InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt>'proto',
      values(): Array<
          InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt> {
        return [
          InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum
              .JSON,
          InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum
              .MEDIA,
          InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum
              .PROTO
        ];
      }
    };

export declare interface InternalProjectsLocationsConversationDatasetsConversationDataItemsGetNamedParameters {
  access_token?: string;
  alt?:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv;
}

export declare interface InternalProjectsLocationsConversationDatasetsConversationDataItemsListNamedParameters {
  access_token?: string;
  alt?:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv;
  pageSize?: number;
  pageToken?: string;
  filter?: string;
}

export class
    InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClientImpl
        implements
            InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient {
  private $apiClient: ObservableApiClient;

  constructor(
      private gapiVersion: string, gapiRequestService: ObservableRequestService,
      apiClientHookFactory: ApiClientHookFactory|null = null) {
    this.$apiClient =
        new ObservableApiClient(gapiRequestService, apiClientHookFactory);
  }

  get(name: string,
      namedParameters:
          InternalProjectsLocationsConversationDatasetsConversationDataItemsGetNamedParameters&
      object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<ConversationDataItem_> {
    this.$apiClient.$validateParameter(
        name,
        new RegExp(
            '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/conversationDataItems/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ConversationDataItem_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId:
          'dialogflow.internal.projects.locations.conversationDatasets.conversationDataItems.get',
      path: `/${this.gapiVersion}/internal/${name}`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ConversationDataItem_
    });
  }

  list(
      parent: string,
      namedParameters:
          InternalProjectsLocationsConversationDatasetsConversationDataItemsListNamedParameters&
      object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<ListConversationDataItemsResponse_> {
    this.$apiClient.$validateParameter(
        parent,
        new RegExp(
            '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ListConversationDataItemsResponse_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId:
          'dialogflow.internal.projects.locations.conversationDatasets.conversationDataItems.list',
      path: `/${this.gapiVersion}/internal/${parent}/conversationDataItems`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ListConversationDataItemsResponse_
    });
  }
}

export abstract class
    InternalProjectsLocationsConversationDatasetsConversationDataItemsApiClient {
  constructor() {}

  abstract get(
      name: string,
      namedParameters?:
          InternalProjectsLocationsConversationDatasetsConversationDataItemsGetNamedParameters&
      object,
      passthroughNamedParameters?: globalThis.Record<string, string>):
      Observable<ConversationDataItem_>;

  abstract list(
      parent: string,
      namedParameters?:
          InternalProjectsLocationsConversationDatasetsConversationDataItemsListNamedParameters&
      object,
      passthroughNamedParameters?: globalThis.Record<string, string>):
      Observable<ListConversationDataItemsResponse_>;
}

export type InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv =
    '1'|'2';

export interface IInternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$XgafvEnum {
  readonly 1:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;
  readonly 2:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;

  values(): Array<
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv>;
}

export const InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$XgafvEnum:
    IInternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$XgafvEnum = {
      1: <InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv>'1',
      2: <InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv>'2',
      values(): Array<
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv> {
        return [
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$XgafvEnum
              [1],
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$XgafvEnum
              [2]
        ];
      }
    };

export type InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt =
    'json'|'media'|'proto';

export interface IInternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum {
  readonly JSON:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
  readonly MEDIA:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
  readonly PROTO:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;

  values(): Array<
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt>;
}

export const InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum:
    IInternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum = {
      JSON: <
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt>'json',
      MEDIA: <
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt>'media',
      PROTO: <
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt>'proto',
      values(): Array<
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt> {
        return [
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum
              .JSON,
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum
              .MEDIA,
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum
              .PROTO
        ];
      }
    };

export declare interface InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsGetNamedParameters {
  access_token?: string;
  alt?:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;
}

export declare interface InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsListNamedParameters {
  access_token?: string;
  alt?:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?:
      InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;
  pageSize?: number;
  pageToken?: string;
  filter?: string;
}

export class
    InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientImpl
        implements
            InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient {
  private $apiClient: ObservableApiClient;

  constructor(
      private gapiVersion: string, gapiRequestService: ObservableRequestService,
      apiClientHookFactory: ApiClientHookFactory|null = null) {
    this.$apiClient =
        new ObservableApiClient(gapiRequestService, apiClientHookFactory);
  }

  get(name: string,
      namedParameters:
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsGetNamedParameters&
      object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<ConversationAnnotation_> {
    this.$apiClient.$validateParameter(
        name,
        new RegExp(
            '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/conversationDataItems/[^/]+/conversationAnnotations/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ConversationAnnotation_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId:
          'dialogflow.internal.projects.locations.conversationDatasets.conversationDataItems.conversationAnnotations.get',
      path: `/${this.gapiVersion}/internal/${name}`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ConversationAnnotation_
    });
  }

  list(
      parent: string,
      namedParameters:
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsListNamedParameters&
      object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<ListConversationAnnotationsResponse_> {
    this.$apiClient.$validateParameter(
        parent,
        new RegExp(
            '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/conversationDataItems/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ListConversationAnnotationsResponse_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId:
          'dialogflow.internal.projects.locations.conversationDatasets.conversationDataItems.conversationAnnotations.list',
      path: `/${this.gapiVersion}/internal/${parent}/conversationAnnotations`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ListConversationAnnotationsResponse_
    });
  }
}

export abstract class
    InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient {
  constructor() {}

  abstract get(
      name: string,
      namedParameters?:
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsGetNamedParameters&
      object,
      passthroughNamedParameters?: globalThis.Record<string, string>):
      Observable<ConversationAnnotation_>;

  abstract list(
      parent: string,
      namedParameters?:
          InternalProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsListNamedParameters&
      object,
      passthroughNamedParameters?: globalThis.Record<string, string>):
      Observable<ListConversationAnnotationsResponse_>;
}

export type ProjectsAgentApiClient$Xgafv = '1'|'2';

export interface IProjectsAgentApiClient$XgafvEnum {
  readonly 1: ProjectsAgentApiClient$Xgafv;
  readonly 2: ProjectsAgentApiClient$Xgafv;

  values(): Array<ProjectsAgentApiClient$Xgafv>;
}

export const ProjectsAgentApiClient$XgafvEnum:
    IProjectsAgentApiClient$XgafvEnum = {
      1: <ProjectsAgentApiClient$Xgafv>'1',
      2: <ProjectsAgentApiClient$Xgafv>'2',
      values(): Array<ProjectsAgentApiClient$Xgafv> {
        return [
          ProjectsAgentApiClient$XgafvEnum[1],
          ProjectsAgentApiClient$XgafvEnum[2]
        ];
      }
    };

export type ProjectsAgentApiClientAlt = 'json'|'media'|'proto';

export interface IProjectsAgentApiClientAltEnum {
  readonly JSON: ProjectsAgentApiClientAlt;
  readonly MEDIA: ProjectsAgentApiClientAlt;
  readonly PROTO: ProjectsAgentApiClientAlt;

  values(): Array<ProjectsAgentApiClientAlt>;
}

export const ProjectsAgentApiClientAltEnum: IProjectsAgentApiClientAltEnum = {
  JSON: <ProjectsAgentApiClientAlt>'json',
  MEDIA: <ProjectsAgentApiClientAlt>'media',
  PROTO: <ProjectsAgentApiClientAlt>'proto',
  values(): Array<ProjectsAgentApiClientAlt> {
    return [
      ProjectsAgentApiClientAltEnum.JSON, ProjectsAgentApiClientAltEnum.MEDIA,
      ProjectsAgentApiClientAltEnum.PROTO
    ];
  }
};

export type ProjectsAgentApiClientRequestSource =
    'SERVICE_REQUEST_SOURCE_UNSPECIFIED'|'DIALOGFLOW_CONSOLE';

export interface IProjectsAgentApiClientRequestSourceEnum {
  readonly SERVICE_REQUEST_SOURCE_UNSPECIFIED:
      ProjectsAgentApiClientRequestSource;
  readonly DIALOGFLOW_CONSOLE: ProjectsAgentApiClientRequestSource;

  values(): Array<ProjectsAgentApiClientRequestSource>;
}

export const ProjectsAgentApiClientRequestSourceEnum:
    IProjectsAgentApiClientRequestSourceEnum = {
      DIALOGFLOW_CONSOLE:
          <ProjectsAgentApiClientRequestSource>'DIALOGFLOW_CONSOLE',
      SERVICE_REQUEST_SOURCE_UNSPECIFIED: <
          ProjectsAgentApiClientRequestSource>'SERVICE_REQUEST_SOURCE_UNSPECIFIED',
      values(): Array<ProjectsAgentApiClientRequestSource> {
        return [
          ProjectsAgentApiClientRequestSourceEnum
              .SERVICE_REQUEST_SOURCE_UNSPECIFIED,
          ProjectsAgentApiClientRequestSourceEnum.DIALOGFLOW_CONSOLE
        ];
      }
    };

export type ProjectsAgentApiClientServiceRequestSource =
    'SERVICE_REQUEST_SOURCE_UNSPECIFIED'|'DIALOGFLOW_CONSOLE';

export interface IProjectsAgentApiClientServiceRequestSourceEnum {
  readonly SERVICE_REQUEST_SOURCE_UNSPECIFIED:
      ProjectsAgentApiClientServiceRequestSource;
  readonly DIALOGFLOW_CONSOLE: ProjectsAgentApiClientServiceRequestSource;

  values(): Array<ProjectsAgentApiClientServiceRequestSource>;
}

export const ProjectsAgentApiClientServiceRequestSourceEnum:
    IProjectsAgentApiClientServiceRequestSourceEnum = {
      DIALOGFLOW_CONSOLE:
          <ProjectsAgentApiClientServiceRequestSource>'DIALOGFLOW_CONSOLE',
      SERVICE_REQUEST_SOURCE_UNSPECIFIED: <
          ProjectsAgentApiClientServiceRequestSource>'SERVICE_REQUEST_SOURCE_UNSPECIFIED',
      values(): Array<ProjectsAgentApiClientServiceRequestSource> {
        return [
          ProjectsAgentApiClientServiceRequestSourceEnum
              .SERVICE_REQUEST_SOURCE_UNSPECIFIED,
          ProjectsAgentApiClientServiceRequestSourceEnum.DIALOGFLOW_CONSOLE
        ];
      }
    };

export declare interface ProjectsAgentCreateModelEvaluationDatasetNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentDetermineAogCompatibilityNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentEvaluateModelNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentExportNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentGetFulfillmentNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentGetModelEvaluationResultNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
  environment?: string;
  languageCode?: string;
}

export declare interface ProjectsAgentGetSettingsNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
  requestSource?: ProjectsAgentApiClientRequestSource;
  serviceRequestSource?: ProjectsAgentApiClientServiceRequestSource;
}

export declare interface ProjectsAgentGetTierNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
  disableCache?: boolean;
}

export declare interface ProjectsAgentGetValidationResultNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
  versionNumber?: number;
  languageCode?: string;
}

export declare interface ProjectsAgentGetVisibilityNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentImportNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentListAogIntentDefinitionsNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
  pageSize?: number;
  pageToken?: string;
}

export declare interface ProjectsAgentPreviewActionPackageNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentPreviewAogAgentNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentRestoreNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentSearchNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
  pageSize?: number;
  pageToken?: string;
  projectIds?: Array<string>;
  requestSource?: ProjectsAgentApiClientRequestSource;
}

export declare interface ProjectsAgentTierNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentTrainNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export declare interface ProjectsAgentUpdateFulfillmentNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
  updateMask?: string;
}

export declare interface ProjectsAgentUpdateSettingsNamedParameters {
  access_token?: string;
  alt?: ProjectsAgentApiClientAlt;
  callback?: string;
  fields?: string;
  key?: string;
  oauth_token?: string;
  prettyPrint?: boolean;
  quotaUser?: string;
  upload_protocol?: string;
  uploadType?: string;
  $Xgafv?: ProjectsAgentApiClient$Xgafv;
}

export class ProjectsAgentApiClientImpl implements ProjectsAgentApiClient {
  private $apiClient: ObservableApiClient;

  constructor(
      private gapiVersion: string, gapiRequestService: ObservableRequestService,
      apiClientHookFactory: ApiClientHookFactory|null = null) {
    this.$apiClient =
        new ObservableApiClient(gapiRequestService, apiClientHookFactory);
  }

  createModelEvaluationDataset(
      parent: string, $requestBody: CreateModelEvaluationDatasetRequest_,
      namedParameters: ProjectsAgentCreateModelEvaluationDatasetNamedParameters&
      object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<ModelEvaluationDataset_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));

    return this.$apiClient.$request<ModelEvaluationDataset_>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.createModelEvaluationDataset',
      path: `/${this.gapiVersion}/${parent}/agent:createModelEvaluationDataset`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ModelEvaluationDataset_
    });
  }

  determineAogCompatibility(
      name: string, $requestBody: AogCompatibilityRequest_,
      namedParameters: ProjectsAgentDetermineAogCompatibilityNamedParameters&
      object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<AogCompatibility_> {
    this.$apiClient.$validateParameter(
        name, new RegExp('^projects/[^/]+/agent$'));

    return this.$apiClient.$request<AogCompatibility_>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.determineAogCompatibility',
      path: `/${this.gapiVersion}/${name}:determineAogCompatibility`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: AogCompatibility_
    });
  }

  evaluateModel(
      parent: string, $requestBody: EvaluateModelRequest_,
      namedParameters: ProjectsAgentEvaluateModelNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.evaluateModel',
      path: `/${this.gapiVersion}/${parent}/agent:evaluateModel`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  export(
      parent: string, $requestBody: ExportAgentRequest_,
      namedParameters: ProjectsAgentExportNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.export',
      path: `/${this.gapiVersion}/${parent}/agent:export`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  getFulfillment(
      name: string,
      namedParameters: ProjectsAgentGetFulfillmentNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<Fulfillment_> {
    this.$apiClient.$validateParameter(
        name, new RegExp('^projects/[^/]+/agent/fulfillment$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<Fulfillment_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.agent.getFulfillment',
      path: `/${this.gapiVersion}/${name}`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: Fulfillment_
    });
  }

  getModelEvaluationResult(
      parent: string,
      namedParameters: ProjectsAgentGetModelEvaluationResultNamedParameters&
      object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<ModelEvaluationResult_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ModelEvaluationResult_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.agent.getModelEvaluationResult',
      path: `/${this.gapiVersion}/${parent}/agent/modelEvaluationResult`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ModelEvaluationResult_
    });
  }

  getSettings(
      parent: string,
      namedParameters: ProjectsAgentGetSettingsNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<AgentSettings_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<AgentSettings_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.agent.getSettings',
      path: `/${this.gapiVersion}/${parent}/agent:getSettings`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: AgentSettings_
    });
  }

  getTier(
      parent: string,
      namedParameters: ProjectsAgentGetTierNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<AgentTier_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<AgentTier_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.agent.getTier',
      path: `/${this.gapiVersion}/${parent}/agent/tier`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: AgentTier_
    });
  }

  getValidationResult(
      parent: string,
      namedParameters: ProjectsAgentGetValidationResultNamedParameters&
      object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<ValidationResult_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ValidationResult_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.agent.getValidationResult',
      path: `/${this.gapiVersion}/${parent}/agent/validationResult`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ValidationResult_
    });
  }

  getVisibility(
      parent: string,
      namedParameters: ProjectsAgentGetVisibilityNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}):
      Observable<AgentVisibility_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<AgentVisibility_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.agent.getVisibility',
      path: `/${this.gapiVersion}/${parent}/agent/visibility`,
      queryParams: buildQueryParams(
          namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: AgentVisibility_
    });
  }

  import(
      parent: string,
      $requestBody: ImportAgentRequest_,
      namedParameters: ProjectsAgentImportNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.import',
      path: `/${this.gapiVersion}/${parent}/agent:import`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  listAogIntentDefinitions(
      name: string,
      namedParameters: ProjectsAgentListAogIntentDefinitionsNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<ListIntentDefinitionsResponse_> {
    this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+/agent$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ListIntentDefinitionsResponse_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.agent.listAogIntentDefinitions',
      path: `/${this.gapiVersion}/${name}:aogIntentDefinitions`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ListIntentDefinitionsResponse_
    });
  }

  previewActionPackage(
      name: string,
      $requestBody: PreviewActionPackageRequest_,
      namedParameters: ProjectsAgentPreviewActionPackageNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<PreviewActionPackageResponse_> {
    this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+/agent$'));

    return this.$apiClient.$request<PreviewActionPackageResponse_>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.previewActionPackage',
      path: `/${this.gapiVersion}/${name}:previewActionPackage`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: PreviewActionPackageResponse_
    });
  }

  previewAogAgent(
      name: string,
      $requestBody: PreviewAogAgentRequest_,
      namedParameters: ProjectsAgentPreviewAogAgentNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<PreviewAogAgentResponse_> {
    this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+/agent$'));

    return this.$apiClient.$request<PreviewAogAgentResponse_>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.previewAogAgent',
      path: `/${this.gapiVersion}/${name}:previewAogAgent`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: PreviewAogAgentResponse_
    });
  }

  restore(
      parent: string,
      $requestBody: RestoreAgentRequest_,
      namedParameters: ProjectsAgentRestoreNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.restore',
      path: `/${this.gapiVersion}/${parent}/agent:restore`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  search(
      parent: string,
      namedParameters: ProjectsAgentSearchNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<SearchAgentsResponse_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<SearchAgentsResponse_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.agent.search',
      path: `/${this.gapiVersion}/${parent}/agent:search`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: SearchAgentsResponse_
    });
  }

  tier(
      parent: string,
      $requestBody: AgentTier_,
      namedParameters: ProjectsAgentTierNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<AgentTier_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));

    return this.$apiClient.$request<AgentTier_>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.tier',
      path: `/${this.gapiVersion}/${parent}/agent/tier`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: AgentTier_
    });
  }

  train(
      parent: string,
      $requestBody: TrainAgentRequest_,
      namedParameters: ProjectsAgentTrainNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.train',
      path: `/${this.gapiVersion}/${parent}/agent:train`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  updateFulfillment(
      name: string,
      $requestBody: Fulfillment_,
      namedParameters: ProjectsAgentUpdateFulfillmentNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<Fulfillment_> {
    this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+/agent/fulfillment$'));

    return this.$apiClient.$request<Fulfillment_>({
      body: $requestBody,
      httpMethod: 'PATCH',
      methodId: 'dialogflow.projects.agent.updateFulfillment',
      path: `/${this.gapiVersion}/${name}`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: Fulfillment_
    });
  }

  updateSettings(
      parent: string,
      $requestBody: UpdateAgentSettingsRequest_,
      namedParameters: ProjectsAgentUpdateSettingsNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<AgentSettings_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+$'));

    return this.$apiClient.$request<AgentSettings_>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.agent.updateSettings',
      path: `/${this.gapiVersion}/${parent}/agent:updateSettings`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: AgentSettings_
    });
  }
}

export abstract class ProjectsAgentApiClient {
  constructor() {
  }

  abstract createModelEvaluationDataset(
      parent: string,
      $requestBody: CreateModelEvaluationDatasetRequest_,
      namedParameters?: ProjectsAgentCreateModelEvaluationDatasetNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<ModelEvaluationDataset_>;

  abstract determineAogCompatibility(
      name: string,
      $requestBody: AogCompatibilityRequest_,
      namedParameters?: ProjectsAgentDetermineAogCompatibilityNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AogCompatibility_>;

  abstract evaluateModel(
      parent: string,
      $requestBody: EvaluateModelRequest_,
      namedParameters?: ProjectsAgentEvaluateModelNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract export(
      parent: string,
      $requestBody: ExportAgentRequest_,
      namedParameters?: ProjectsAgentExportNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract getFulfillment(
      name: string,
      namedParameters?: ProjectsAgentGetFulfillmentNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<Fulfillment_>;

  abstract getModelEvaluationResult(
      parent: string,
      namedParameters?: ProjectsAgentGetModelEvaluationResultNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<ModelEvaluationResult_>;

  abstract getSettings(
      parent: string,
      namedParameters?: ProjectsAgentGetSettingsNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AgentSettings_>;

  abstract getTier(
      parent: string,
      namedParameters?: ProjectsAgentGetTierNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AgentTier_>;

  abstract getValidationResult(
      parent: string,
      namedParameters?: ProjectsAgentGetValidationResultNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<ValidationResult_>;

  abstract getVisibility(
      parent: string,
      namedParameters?: ProjectsAgentGetVisibilityNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AgentVisibility_>;

  abstract import(
      parent: string,
      $requestBody: ImportAgentRequest_,
      namedParameters?: ProjectsAgentImportNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract listAogIntentDefinitions(
      name: string,
      namedParameters?: ProjectsAgentListAogIntentDefinitionsNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<ListIntentDefinitionsResponse_>;

  abstract previewActionPackage(
      name: string,
      $requestBody: PreviewActionPackageRequest_,
      namedParameters?: ProjectsAgentPreviewActionPackageNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<PreviewActionPackageResponse_>;

  abstract previewAogAgent(
      name: string,
      $requestBody: PreviewAogAgentRequest_,
      namedParameters?: ProjectsAgentPreviewAogAgentNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<PreviewAogAgentResponse_>;

  abstract restore(
      parent: string,
      $requestBody: RestoreAgentRequest_,
      namedParameters?: ProjectsAgentRestoreNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract search(
      parent: string,
      namedParameters?: ProjectsAgentSearchNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<SearchAgentsResponse_>;

  abstract tier(
      parent: string,
      $requestBody: AgentTier_,
      namedParameters?: ProjectsAgentTierNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AgentTier_>;

  abstract train(
      parent: string,
      $requestBody: TrainAgentRequest_,
      namedParameters?: ProjectsAgentTrainNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract updateFulfillment(
      name: string,
      $requestBody: Fulfillment_,
      namedParameters?: ProjectsAgentUpdateFulfillmentNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<Fulfillment_>;

  abstract updateSettings(
      parent: string,
      $requestBody: UpdateAgentSettingsRequest_,
      namedParameters?: ProjectsAgentUpdateSettingsNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AgentSettings_>;
}

export type ProjectsAgentEntityTypesApiClient$Xgafv = '1' | '2';

  export interface IProjectsAgentEntityTypesApiClient$XgafvEnum {
    readonly 1: ProjectsAgentEntityTypesApiClient$Xgafv;
    readonly 2: ProjectsAgentEntityTypesApiClient$Xgafv;

    values(): Array<ProjectsAgentEntityTypesApiClient$Xgafv>;
  }

  export const ProjectsAgentEntityTypesApiClient$XgafvEnum:
      IProjectsAgentEntityTypesApiClient$XgafvEnum = {
        1: <ProjectsAgentEntityTypesApiClient$Xgafv>'1',
        2: <ProjectsAgentEntityTypesApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentEntityTypesApiClient$Xgafv> {
              return [
                ProjectsAgentEntityTypesApiClient$XgafvEnum[1],
                ProjectsAgentEntityTypesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentEntityTypesApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsAgentEntityTypesApiClientAltEnum {
    readonly JSON: ProjectsAgentEntityTypesApiClientAlt;
    readonly MEDIA: ProjectsAgentEntityTypesApiClientAlt;
    readonly PROTO: ProjectsAgentEntityTypesApiClientAlt;

    values(): Array<ProjectsAgentEntityTypesApiClientAlt>;
  }

  export const ProjectsAgentEntityTypesApiClientAltEnum:
      IProjectsAgentEntityTypesApiClientAltEnum = {
        JSON: <ProjectsAgentEntityTypesApiClientAlt>'json',
        MEDIA: <ProjectsAgentEntityTypesApiClientAlt>'media',
        PROTO: <ProjectsAgentEntityTypesApiClientAlt>'proto',
        values():
            Array<ProjectsAgentEntityTypesApiClientAlt> {
              return [
                ProjectsAgentEntityTypesApiClientAltEnum.JSON,
                ProjectsAgentEntityTypesApiClientAltEnum.MEDIA,
                ProjectsAgentEntityTypesApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsAgentEntityTypesApiClientView =
      'LIST_ENTITY_TYPES_VIEW_UNSPECIFIED'|'LIST_ENTITY_TYPES_VIEW_SYSTEM';

  export interface IProjectsAgentEntityTypesApiClientViewEnum {
    readonly LIST_ENTITY_TYPES_VIEW_UNSPECIFIED:
        ProjectsAgentEntityTypesApiClientView;
    readonly LIST_ENTITY_TYPES_VIEW_SYSTEM:
        ProjectsAgentEntityTypesApiClientView;

    values(): Array<ProjectsAgentEntityTypesApiClientView>;
  }

  export const ProjectsAgentEntityTypesApiClientViewEnum:
      IProjectsAgentEntityTypesApiClientViewEnum = {
        LIST_ENTITY_TYPES_VIEW_SYSTEM: <
            ProjectsAgentEntityTypesApiClientView>'LIST_ENTITY_TYPES_VIEW_SYSTEM',
        LIST_ENTITY_TYPES_VIEW_UNSPECIFIED: <
            ProjectsAgentEntityTypesApiClientView>'LIST_ENTITY_TYPES_VIEW_UNSPECIFIED',
        values():
            Array<ProjectsAgentEntityTypesApiClientView> {
              return [
                ProjectsAgentEntityTypesApiClientViewEnum
                    .LIST_ENTITY_TYPES_VIEW_UNSPECIFIED,
                ProjectsAgentEntityTypesApiClientViewEnum
                    .LIST_ENTITY_TYPES_VIEW_SYSTEM
              ];
            }
      };

  export declare interface ProjectsAgentEntityTypesBatchDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEntityTypesBatchUpdateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEntityTypesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEntityTypesApiClient$Xgafv;
    languageCode?: string;
  }

  export declare interface ProjectsAgentEntityTypesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEntityTypesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEntityTypesApiClient$Xgafv;
    languageCode?: string;
  }

  export declare interface ProjectsAgentEntityTypesListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEntityTypesApiClient$Xgafv;
    languageCode?: string;
    pageSize?: number;
    pageToken?: string;
    view?: ProjectsAgentEntityTypesApiClientView;
  }

  export declare interface ProjectsAgentEntityTypesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEntityTypesApiClient$Xgafv;
    languageCode?: string;
    updateMask?: string;
  }

  export class ProjectsAgentEntityTypesApiClientImpl implements
      ProjectsAgentEntityTypesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchDelete(
        parent: string, $requestBody: BatchDeleteEntityTypesRequest_,
        namedParameters: ProjectsAgentEntityTypesBatchDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.entityTypes.batchDelete',
        path: `/${this.gapiVersion}/${parent}/entityTypes:batchDelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchUpdate(
        parent: string, $requestBody: BatchUpdateEntityTypesRequest_,
        namedParameters: ProjectsAgentEntityTypesBatchUpdateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.entityTypes.batchUpdate',
        path: `/${this.gapiVersion}/${parent}/entityTypes:batchUpdate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    create(
        parent: string, $requestBody: EntityType_,
        namedParameters: ProjectsAgentEntityTypesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<EntityType_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));

      return this.$apiClient.$request<EntityType_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.entityTypes.create',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: EntityType_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsAgentEntityTypesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.agent.entityTypes.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsAgentEntityTypesGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<EntityType_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<EntityType_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.entityTypes.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: EntityType_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsAgentEntityTypesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListEntityTypesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListEntityTypesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.entityTypes.list',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListEntityTypesResponse_
      });
    }

    patch(
        name: string, $requestBody: EntityType_,
        namedParameters: ProjectsAgentEntityTypesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<EntityType_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/entityTypes/[^/]+$'));

      return this.$apiClient.$request<EntityType_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.agent.entityTypes.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: EntityType_
      });
    }
  }

  export abstract class ProjectsAgentEntityTypesApiClient {
    constructor() {}

    abstract batchDelete(
        parent: string, $requestBody: BatchDeleteEntityTypesRequest_,
        namedParameters?: ProjectsAgentEntityTypesBatchDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchUpdate(
        parent: string, $requestBody: BatchUpdateEntityTypesRequest_,
        namedParameters?: ProjectsAgentEntityTypesBatchUpdateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract create(
        parent: string, $requestBody: EntityType_,
        namedParameters?: ProjectsAgentEntityTypesCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<EntityType_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsAgentEntityTypesDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsAgentEntityTypesGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<EntityType_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsAgentEntityTypesListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListEntityTypesResponse_>;

    abstract patch(
        name: string, $requestBody: EntityType_,
        namedParameters?: ProjectsAgentEntityTypesPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<EntityType_>;
  }

  export type ProjectsAgentEntityTypesEntitiesApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentEntityTypesEntitiesApiClient$XgafvEnum {
    readonly 1: ProjectsAgentEntityTypesEntitiesApiClient$Xgafv;
    readonly 2: ProjectsAgentEntityTypesEntitiesApiClient$Xgafv;

    values(): Array<ProjectsAgentEntityTypesEntitiesApiClient$Xgafv>;
  }

  export const ProjectsAgentEntityTypesEntitiesApiClient$XgafvEnum:
      IProjectsAgentEntityTypesEntitiesApiClient$XgafvEnum = {
        1: <ProjectsAgentEntityTypesEntitiesApiClient$Xgafv>'1',
        2: <ProjectsAgentEntityTypesEntitiesApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentEntityTypesEntitiesApiClient$Xgafv> {
              return [
                ProjectsAgentEntityTypesEntitiesApiClient$XgafvEnum[1],
                ProjectsAgentEntityTypesEntitiesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentEntityTypesEntitiesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentEntityTypesEntitiesApiClientAltEnum {
    readonly JSON: ProjectsAgentEntityTypesEntitiesApiClientAlt;
    readonly MEDIA: ProjectsAgentEntityTypesEntitiesApiClientAlt;
    readonly PROTO: ProjectsAgentEntityTypesEntitiesApiClientAlt;

    values(): Array<ProjectsAgentEntityTypesEntitiesApiClientAlt>;
  }

  export const ProjectsAgentEntityTypesEntitiesApiClientAltEnum:
      IProjectsAgentEntityTypesEntitiesApiClientAltEnum = {
        JSON: <ProjectsAgentEntityTypesEntitiesApiClientAlt>'json',
        MEDIA: <ProjectsAgentEntityTypesEntitiesApiClientAlt>'media',
        PROTO: <ProjectsAgentEntityTypesEntitiesApiClientAlt>'proto',
        values():
            Array<ProjectsAgentEntityTypesEntitiesApiClientAlt> {
              return [
                ProjectsAgentEntityTypesEntitiesApiClientAltEnum.JSON,
                ProjectsAgentEntityTypesEntitiesApiClientAltEnum.MEDIA,
                ProjectsAgentEntityTypesEntitiesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsAgentEntityTypesEntitiesBatchCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEntityTypesEntitiesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEntityTypesEntitiesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEntityTypesEntitiesBatchDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEntityTypesEntitiesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEntityTypesEntitiesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEntityTypesEntitiesBatchUpdateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEntityTypesEntitiesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEntityTypesEntitiesApiClient$Xgafv;
  }

  export class ProjectsAgentEntityTypesEntitiesApiClientImpl implements
      ProjectsAgentEntityTypesEntitiesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchCreate(
        parent: string, $requestBody: BatchCreateEntitiesRequest_,
        namedParameters:
            ProjectsAgentEntityTypesEntitiesBatchCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/entityTypes/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.entityTypes.entities.batchCreate',
        path: `/${this.gapiVersion}/${parent}/entities:batchCreate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchDelete(
        parent: string, $requestBody: BatchDeleteEntitiesRequest_,
        namedParameters:
            ProjectsAgentEntityTypesEntitiesBatchDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/entityTypes/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.entityTypes.entities.batchDelete',
        path: `/${this.gapiVersion}/${parent}/entities:batchDelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchUpdate(
        parent: string, $requestBody: BatchUpdateEntitiesRequest_,
        namedParameters:
            ProjectsAgentEntityTypesEntitiesBatchUpdateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/entityTypes/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.entityTypes.entities.batchUpdate',
        path: `/${this.gapiVersion}/${parent}/entities:batchUpdate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }
  }

  export abstract class ProjectsAgentEntityTypesEntitiesApiClient {
    constructor() {}

    abstract batchCreate(
        parent: string, $requestBody: BatchCreateEntitiesRequest_,
        namedParameters?:
            ProjectsAgentEntityTypesEntitiesBatchCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchDelete(
        parent: string, $requestBody: BatchDeleteEntitiesRequest_,
        namedParameters?:
            ProjectsAgentEntityTypesEntitiesBatchDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchUpdate(
        parent: string, $requestBody: BatchUpdateEntitiesRequest_,
        namedParameters?:
            ProjectsAgentEntityTypesEntitiesBatchUpdateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;
  }

  export type ProjectsAgentEnvironmentsApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentEnvironmentsApiClient$XgafvEnum {
    readonly 1: ProjectsAgentEnvironmentsApiClient$Xgafv;
    readonly 2: ProjectsAgentEnvironmentsApiClient$Xgafv;

    values(): Array<ProjectsAgentEnvironmentsApiClient$Xgafv>;
  }

  export const ProjectsAgentEnvironmentsApiClient$XgafvEnum:
      IProjectsAgentEnvironmentsApiClient$XgafvEnum = {
        1: <ProjectsAgentEnvironmentsApiClient$Xgafv>'1',
        2: <ProjectsAgentEnvironmentsApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentEnvironmentsApiClient$Xgafv> {
              return [
                ProjectsAgentEnvironmentsApiClient$XgafvEnum[1],
                ProjectsAgentEnvironmentsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentEnvironmentsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsAgentEnvironmentsApiClientAltEnum {
    readonly JSON: ProjectsAgentEnvironmentsApiClientAlt;
    readonly MEDIA: ProjectsAgentEnvironmentsApiClientAlt;
    readonly PROTO: ProjectsAgentEnvironmentsApiClientAlt;

    values(): Array<ProjectsAgentEnvironmentsApiClientAlt>;
  }

  export const ProjectsAgentEnvironmentsApiClientAltEnum:
      IProjectsAgentEnvironmentsApiClientAltEnum = {
        JSON: <ProjectsAgentEnvironmentsApiClientAlt>'json',
        MEDIA: <ProjectsAgentEnvironmentsApiClientAlt>'media',
        PROTO: <ProjectsAgentEnvironmentsApiClientAlt>'proto',
        values():
            Array<ProjectsAgentEnvironmentsApiClientAlt> {
              return [
                ProjectsAgentEnvironmentsApiClientAltEnum.JSON,
                ProjectsAgentEnvironmentsApiClientAltEnum.MEDIA,
                ProjectsAgentEnvironmentsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsAgentEnvironmentsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsApiClient$Xgafv;
    environmentId?: string;
    enableEnvironmentLimit?: boolean;
  }

  export declare interface ProjectsAgentEnvironmentsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsGetHistoryNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAgentEnvironmentsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAgentEnvironmentsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsApiClient$Xgafv;
    updateMask?: string;
    allowLoadToDraftAndDiscardChanges?: boolean;
  }

  export class ProjectsAgentEnvironmentsApiClientImpl implements
      ProjectsAgentEnvironmentsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Environment_,
        namedParameters: ProjectsAgentEnvironmentsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Environment_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));

      return this.$apiClient.$request<Environment_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.environments.create',
        path: `/${this.gapiVersion}/${parent}/environments`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Environment_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsAgentEnvironmentsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/environments/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.agent.environments.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsAgentEnvironmentsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Environment_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/environments/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Environment_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.environments.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Environment_
      });
    }

    getHistory(
        parent: string,
        namedParameters: ProjectsAgentEnvironmentsGetHistoryNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<EnvironmentHistory_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/environments/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<EnvironmentHistory_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.environments.getHistory',
        path: `/${this.gapiVersion}/${parent}/history`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: EnvironmentHistory_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsAgentEnvironmentsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListEnvironmentsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListEnvironmentsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.environments.list',
        path: `/${this.gapiVersion}/${parent}/environments`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListEnvironmentsResponse_
      });
    }

    patch(
        name: string, $requestBody: Environment_,
        namedParameters: ProjectsAgentEnvironmentsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Environment_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/environments/[^/]+$'));

      return this.$apiClient.$request<Environment_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.agent.environments.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Environment_
      });
    }
  }

  export abstract class ProjectsAgentEnvironmentsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Environment_,
        namedParameters?: ProjectsAgentEnvironmentsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Environment_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsAgentEnvironmentsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsAgentEnvironmentsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Environment_>;

    abstract getHistory(
        parent: string,
        namedParameters?: ProjectsAgentEnvironmentsGetHistoryNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<EnvironmentHistory_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsAgentEnvironmentsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListEnvironmentsResponse_>;

    abstract patch(
        name: string, $requestBody: Environment_,
        namedParameters?: ProjectsAgentEnvironmentsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Environment_>;
  }

  export type ProjectsAgentEnvironmentsIntentsApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentEnvironmentsIntentsApiClient$XgafvEnum {
    readonly 1: ProjectsAgentEnvironmentsIntentsApiClient$Xgafv;
    readonly 2: ProjectsAgentEnvironmentsIntentsApiClient$Xgafv;

    values(): Array<ProjectsAgentEnvironmentsIntentsApiClient$Xgafv>;
  }

  export const ProjectsAgentEnvironmentsIntentsApiClient$XgafvEnum:
      IProjectsAgentEnvironmentsIntentsApiClient$XgafvEnum = {
        1: <ProjectsAgentEnvironmentsIntentsApiClient$Xgafv>'1',
        2: <ProjectsAgentEnvironmentsIntentsApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentEnvironmentsIntentsApiClient$Xgafv> {
              return [
                ProjectsAgentEnvironmentsIntentsApiClient$XgafvEnum[1],
                ProjectsAgentEnvironmentsIntentsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentEnvironmentsIntentsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentEnvironmentsIntentsApiClientAltEnum {
    readonly JSON: ProjectsAgentEnvironmentsIntentsApiClientAlt;
    readonly MEDIA: ProjectsAgentEnvironmentsIntentsApiClientAlt;
    readonly PROTO: ProjectsAgentEnvironmentsIntentsApiClientAlt;

    values(): Array<ProjectsAgentEnvironmentsIntentsApiClientAlt>;
  }

  export const ProjectsAgentEnvironmentsIntentsApiClientAltEnum:
      IProjectsAgentEnvironmentsIntentsApiClientAltEnum = {
        JSON: <ProjectsAgentEnvironmentsIntentsApiClientAlt>'json',
        MEDIA: <ProjectsAgentEnvironmentsIntentsApiClientAlt>'media',
        PROTO: <ProjectsAgentEnvironmentsIntentsApiClientAlt>'proto',
        values():
            Array<ProjectsAgentEnvironmentsIntentsApiClientAlt> {
              return [
                ProjectsAgentEnvironmentsIntentsApiClientAltEnum.JSON,
                ProjectsAgentEnvironmentsIntentsApiClientAltEnum.MEDIA,
                ProjectsAgentEnvironmentsIntentsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsAgentEnvironmentsIntentsApiClientIntentView =
      'INTENT_VIEW_UNSPECIFIED'|'INTENT_VIEW_FULL';

  export interface IProjectsAgentEnvironmentsIntentsApiClientIntentViewEnum {
    readonly INTENT_VIEW_UNSPECIFIED:
        ProjectsAgentEnvironmentsIntentsApiClientIntentView;
    readonly INTENT_VIEW_FULL:
        ProjectsAgentEnvironmentsIntentsApiClientIntentView;

    values(): Array<ProjectsAgentEnvironmentsIntentsApiClientIntentView>;
  }

  export const ProjectsAgentEnvironmentsIntentsApiClientIntentViewEnum:
      IProjectsAgentEnvironmentsIntentsApiClientIntentViewEnum = {
        INTENT_VIEW_FULL: <
            ProjectsAgentEnvironmentsIntentsApiClientIntentView>'INTENT_VIEW_FULL',
        INTENT_VIEW_UNSPECIFIED: <
            ProjectsAgentEnvironmentsIntentsApiClientIntentView>'INTENT_VIEW_UNSPECIFIED',
        values():
            Array<ProjectsAgentEnvironmentsIntentsApiClientIntentView> {
              return [
                ProjectsAgentEnvironmentsIntentsApiClientIntentViewEnum
                    .INTENT_VIEW_UNSPECIFIED,
                ProjectsAgentEnvironmentsIntentsApiClientIntentViewEnum
                    .INTENT_VIEW_FULL
              ];
            }
      };

  export declare interface ProjectsAgentEnvironmentsIntentsListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsIntentsApiClient$Xgafv;
    languageCode?: string;
    intentView?: ProjectsAgentEnvironmentsIntentsApiClientIntentView;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsAgentEnvironmentsIntentsApiClientImpl implements
      ProjectsAgentEnvironmentsIntentsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters: ProjectsAgentEnvironmentsIntentsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListIntentsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/environments/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListIntentsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.environments.intents.list',
        path: `/${this.gapiVersion}/${parent}/intents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListIntentsResponse_
      });
    }
  }

  export abstract class ProjectsAgentEnvironmentsIntentsApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?: ProjectsAgentEnvironmentsIntentsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListIntentsResponse_>;
  }

  export type ProjectsAgentEnvironmentsSessionsConversationsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsAgentEnvironmentsSessionsConversationsApiClient$XgafvEnum {
    readonly 1: ProjectsAgentEnvironmentsSessionsConversationsApiClient$Xgafv;
    readonly 2: ProjectsAgentEnvironmentsSessionsConversationsApiClient$Xgafv;

    values():
        Array<ProjectsAgentEnvironmentsSessionsConversationsApiClient$Xgafv>;
  }

  export const ProjectsAgentEnvironmentsSessionsConversationsApiClient$XgafvEnum:
      IProjectsAgentEnvironmentsSessionsConversationsApiClient$XgafvEnum = {
        1: <ProjectsAgentEnvironmentsSessionsConversationsApiClient$Xgafv>'1',
        2: <ProjectsAgentEnvironmentsSessionsConversationsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsAgentEnvironmentsSessionsConversationsApiClient$Xgafv> {
              return [
                ProjectsAgentEnvironmentsSessionsConversationsApiClient$XgafvEnum
                    [1],
                ProjectsAgentEnvironmentsSessionsConversationsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentEnvironmentsSessionsConversationsApiClientAltEnum {
    readonly JSON: ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt;
    readonly MEDIA: ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt;
    readonly PROTO: ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt;

    values(): Array<ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt>;
  }

  export const ProjectsAgentEnvironmentsSessionsConversationsApiClientAltEnum:
      IProjectsAgentEnvironmentsSessionsConversationsApiClientAltEnum = {
        JSON:
            <ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt>'json',
        MEDIA:
            <ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt>'media',
        PROTO:
            <ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt>'proto',
        values():
            Array<ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt> {
              return [
                ProjectsAgentEnvironmentsSessionsConversationsApiClientAltEnum
                    .JSON,
                ProjectsAgentEnvironmentsSessionsConversationsApiClientAltEnum
                    .MEDIA,
                ProjectsAgentEnvironmentsSessionsConversationsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsAgentEnvironmentsSessionsConversationsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsSessionsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsSessionsConversationsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsSessionsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsSessionsConversationsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsSessionsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsSessionsConversationsApiClient$Xgafv;
    fullUpdate?: boolean;
  }

  export class ProjectsAgentEnvironmentsSessionsConversationsApiClientImpl
      implements ProjectsAgentEnvironmentsSessionsConversationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: CreateSessionConversationRequest_,
        namedParameters:
            ProjectsAgentEnvironmentsSessionsConversationsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionConversation_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<SessionConversation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.agent.environments.sessions.conversations.create',
        path: `/${this.gapiVersion}/${parent}/conversations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionConversation_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsAgentEnvironmentsSessionsConversationsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/sessions/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.agent.environments.sessions.conversations.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    patch(
        name: string, $requestBody: SessionConversation_,
        namedParameters:
            ProjectsAgentEnvironmentsSessionsConversationsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionConversation_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/sessions/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<SessionConversation_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.agent.environments.sessions.conversations.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionConversation_
      });
    }
  }

  export abstract class
      ProjectsAgentEnvironmentsSessionsConversationsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: CreateSessionConversationRequest_,
        namedParameters?:
            ProjectsAgentEnvironmentsSessionsConversationsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionConversation_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsAgentEnvironmentsSessionsConversationsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract patch(
        name: string, $requestBody: SessionConversation_,
        namedParameters?:
            ProjectsAgentEnvironmentsSessionsConversationsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionConversation_>;
  }

  export type ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$XgafvEnum {
    readonly 1:
        ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv;
    readonly 2:
        ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv;

    values(): Array<
        ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv>;
  }

  export const ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$XgafvEnum:
      IProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$XgafvEnum = {
        1: <ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv>'1',
        2: <ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv> {
              return [
                ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$XgafvEnum
                    [1],
                ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum {
    readonly JSON:
        ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt;
    readonly MEDIA:
        ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt;
    readonly PROTO:
        ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt;

    values(): Array<
        ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt>;
  }

  export const ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum:
      IProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum = {
        JSON: <
            ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt>'json',
        MEDIA: <
            ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt>'media',
        PROTO: <
            ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt>'proto',
        values():
            Array<
                ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt> {
              return [
                ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum
                    .JSON,
                ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum
                    .MEDIA,
                ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsAgentEnvironmentsSessionsConversationsInteractionsListNamedParameters {
    access_token?: string;
    alt?:
        ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    shouldReclassify?: boolean;
  }

  export class
      ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClientImpl
          implements
              ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters:
            ProjectsAgentEnvironmentsSessionsConversationsInteractionsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListInteractionsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/sessions/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListInteractionsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.agent.environments.sessions.conversations.interactions.list',
        path: `/${this.gapiVersion}/${parent}/interactions`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListInteractionsResponse_
      });
    }
  }

  export abstract class
      ProjectsAgentEnvironmentsSessionsConversationsInteractionsApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsAgentEnvironmentsSessionsConversationsInteractionsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListInteractionsResponse_>;
  }

  export type ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentEnvironmentsUsersSessionsApiClient$XgafvEnum {
    readonly 1: ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
    readonly 2: ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv;

    values(): Array<ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv>;
  }

  export const ProjectsAgentEnvironmentsUsersSessionsApiClient$XgafvEnum:
      IProjectsAgentEnvironmentsUsersSessionsApiClient$XgafvEnum = {
        1: <ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv>'1',
        2: <ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv> {
              return [
                ProjectsAgentEnvironmentsUsersSessionsApiClient$XgafvEnum[1],
                ProjectsAgentEnvironmentsUsersSessionsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentEnvironmentsUsersSessionsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentEnvironmentsUsersSessionsApiClientAltEnum {
    readonly JSON: ProjectsAgentEnvironmentsUsersSessionsApiClientAlt;
    readonly MEDIA: ProjectsAgentEnvironmentsUsersSessionsApiClientAlt;
    readonly PROTO: ProjectsAgentEnvironmentsUsersSessionsApiClientAlt;

    values(): Array<ProjectsAgentEnvironmentsUsersSessionsApiClientAlt>;
  }

  export const ProjectsAgentEnvironmentsUsersSessionsApiClientAltEnum:
      IProjectsAgentEnvironmentsUsersSessionsApiClientAltEnum = {
        JSON: <ProjectsAgentEnvironmentsUsersSessionsApiClientAlt>'json',
        MEDIA: <ProjectsAgentEnvironmentsUsersSessionsApiClientAlt>'media',
        PROTO: <ProjectsAgentEnvironmentsUsersSessionsApiClientAlt>'proto',
        values():
            Array<ProjectsAgentEnvironmentsUsersSessionsApiClientAlt> {
              return [
                ProjectsAgentEnvironmentsUsersSessionsApiClientAltEnum.JSON,
                ProjectsAgentEnvironmentsUsersSessionsApiClientAltEnum.MEDIA,
                ProjectsAgentEnvironmentsUsersSessionsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsAgentEnvironmentsUsersSessionsDeleteContextsNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsDetectIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsFulfillIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsMatchIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsPushFulfillmentResultNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsSetAllContextsNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export class ProjectsAgentEnvironmentsUsersSessionsApiClientImpl implements
      ProjectsAgentEnvironmentsUsersSessionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    deleteContexts(
        parent: string,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsDeleteContextsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.deleteContexts',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    detectIntent(
        session: string, $requestBody: DetectIntentRequest_,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsDetectIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<DetectIntentResponse_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<DetectIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.detectIntent',
        path: `/${this.gapiVersion}/${session}:detectIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: DetectIntentResponse_
      });
    }

    fulfillIntent(
        session: string, $requestBody: FulfillIntentRequest_,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsFulfillIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<DetectIntentResponse_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<DetectIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.fulfillIntent',
        path: `/${this.gapiVersion}/${session}:fulfillIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: DetectIntentResponse_
      });
    }

    matchIntent(
        session: string, $requestBody: MatchIntentRequest_,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsMatchIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<MatchIntentResponse_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<MatchIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.matchIntent',
        path: `/${this.gapiVersion}/${session}:matchIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: MatchIntentResponse_
      });
    }

    pushFulfillmentResult(
        session: string, $requestBody: FulfillmentResult_,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsPushFulfillmentResultNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<FulfillmentResult_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<FulfillmentResult_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.pushFulfillmentResult',
        path: `/${this.gapiVersion}/${session}:pushFulfillmentResult`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: FulfillmentResult_
      });
    }

    setAllContexts(
        parent: string, $requestBody: SetAllContextsRequest_,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsSetAllContextsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SetAllContextsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<SetAllContextsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.setAllContexts',
        path: `/${this.gapiVersion}/${parent}:setAllContexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SetAllContextsResponse_
      });
    }
  }

  export abstract class ProjectsAgentEnvironmentsUsersSessionsApiClient {
    constructor() {}

    abstract deleteContexts(
        parent: string,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsDeleteContextsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract detectIntent(
        session: string, $requestBody: DetectIntentRequest_,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsDetectIntentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<DetectIntentResponse_>;

    abstract fulfillIntent(
        session: string, $requestBody: FulfillIntentRequest_,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsFulfillIntentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<DetectIntentResponse_>;

    abstract matchIntent(
        session: string, $requestBody: MatchIntentRequest_,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsMatchIntentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<MatchIntentResponse_>;

    abstract pushFulfillmentResult(
        session: string, $requestBody: FulfillmentResult_,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsPushFulfillmentResultNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<FulfillmentResult_>;

    abstract setAllContexts(
        parent: string, $requestBody: SetAllContextsRequest_,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsSetAllContextsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SetAllContextsResponse_>;
  }

  export type ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsAgentEnvironmentsUsersSessionsContextsApiClient$XgafvEnum {
    readonly 1: ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
    readonly 2: ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;

    values():
        Array<ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv>;
  }

  export const ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$XgafvEnum:
      IProjectsAgentEnvironmentsUsersSessionsContextsApiClient$XgafvEnum = {
        1: <ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv>'1',
        2: <ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv> {
              return [
                ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$XgafvEnum
                    [1],
                ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum {
    readonly JSON: ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    readonly MEDIA: ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    readonly PROTO: ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt;

    values(): Array<ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt>;
  }

  export const ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum:
      IProjectsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum = {
        JSON:
            <ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt>'json',
        MEDIA:
            <ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt>'media',
        PROTO:
            <ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt>'proto',
        values():
            Array<ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt> {
              return [
                ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum
                    .JSON,
                ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum
                    .MEDIA,
                ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsAgentEnvironmentsUsersSessionsContextsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsContextsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsContextsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsContextsListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsContextsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsAgentEnvironmentsUsersSessionsContextsApiClientImpl
      implements ProjectsAgentEnvironmentsUsersSessionsContextsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Context_,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsContextsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.contexts.create',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsContextsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/contexts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.contexts.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsContextsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/contexts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.contexts.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsContextsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListContextsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListContextsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.contexts.list',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListContextsResponse_
      });
    }

    patch(
        name: string, $requestBody: Context_,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsContextsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/contexts/[^/]+$'));

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.contexts.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }
  }

  export abstract class
      ProjectsAgentEnvironmentsUsersSessionsContextsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Context_,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsContextsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsContextsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsContextsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsContextsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListContextsResponse_>;

    abstract patch(
        name: string, $requestBody: Context_,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsContextsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;
  }

  export type ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$XgafvEnum {
    readonly 1:
        ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
    readonly 2:
        ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;

    values():
        Array<ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv>;
  }

  export const ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$XgafvEnum:
      IProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$XgafvEnum = {
        1: <ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv>'1',
        2: <ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv> {
              return [
                ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$XgafvEnum
                    [1],
                ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum {
    readonly JSON:
        ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    readonly MEDIA:
        ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    readonly PROTO:
        ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;

    values():
        Array<ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt>;
  }

  export const ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum:
      IProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum = {
        JSON: <
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt>'json',
        MEDIA: <
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt>'media',
        PROTO: <
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt>'proto',
        values():
            Array<
                ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt> {
              return [
                ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum
                    .JSON,
                ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum
                    .MEDIA,
                ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsAgentEnvironmentsUsersSessionsEntityTypesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsEntityTypesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsEntityTypesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsEntityTypesListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAgentEnvironmentsUsersSessionsEntityTypesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClientImpl
      implements ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: SessionEntityType_,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.entityTypes.create',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.entityTypes.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.entityTypes.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListSessionEntityTypesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListSessionEntityTypesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.entityTypes.list',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListSessionEntityTypesResponse_
      });
    }

    patch(
        name: string, $requestBody: SessionEntityType_,
        namedParameters:
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/entityTypes/[^/]+$'));

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.agent.environments.users.sessions.entityTypes.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }
  }

  export abstract class
      ProjectsAgentEnvironmentsUsersSessionsEntityTypesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: SessionEntityType_,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListSessionEntityTypesResponse_>;

    abstract patch(
        name: string, $requestBody: SessionEntityType_,
        namedParameters?:
            ProjectsAgentEnvironmentsUsersSessionsEntityTypesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;
  }

  export type ProjectsAgentIntentsApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentIntentsApiClient$XgafvEnum {
    readonly 1: ProjectsAgentIntentsApiClient$Xgafv;
    readonly 2: ProjectsAgentIntentsApiClient$Xgafv;

    values(): Array<ProjectsAgentIntentsApiClient$Xgafv>;
  }

  export const ProjectsAgentIntentsApiClient$XgafvEnum:
      IProjectsAgentIntentsApiClient$XgafvEnum = {
        1: <ProjectsAgentIntentsApiClient$Xgafv>'1',
        2: <ProjectsAgentIntentsApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentIntentsApiClient$Xgafv> {
              return [
                ProjectsAgentIntentsApiClient$XgafvEnum[1],
                ProjectsAgentIntentsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentIntentsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsAgentIntentsApiClientAltEnum {
    readonly JSON: ProjectsAgentIntentsApiClientAlt;
    readonly MEDIA: ProjectsAgentIntentsApiClientAlt;
    readonly PROTO: ProjectsAgentIntentsApiClientAlt;

    values(): Array<ProjectsAgentIntentsApiClientAlt>;
  }

  export const ProjectsAgentIntentsApiClientAltEnum:
      IProjectsAgentIntentsApiClientAltEnum = {
        JSON: <ProjectsAgentIntentsApiClientAlt>'json',
        MEDIA: <ProjectsAgentIntentsApiClientAlt>'media',
        PROTO: <ProjectsAgentIntentsApiClientAlt>'proto',
        values():
            Array<ProjectsAgentIntentsApiClientAlt> {
              return [
                ProjectsAgentIntentsApiClientAltEnum.JSON,
                ProjectsAgentIntentsApiClientAltEnum.MEDIA,
                ProjectsAgentIntentsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsAgentIntentsApiClientIntentView =
      'INTENT_VIEW_UNSPECIFIED'|'INTENT_VIEW_FULL';

  export interface IProjectsAgentIntentsApiClientIntentViewEnum {
    readonly INTENT_VIEW_UNSPECIFIED: ProjectsAgentIntentsApiClientIntentView;
    readonly INTENT_VIEW_FULL: ProjectsAgentIntentsApiClientIntentView;

    values(): Array<ProjectsAgentIntentsApiClientIntentView>;
  }

  export const ProjectsAgentIntentsApiClientIntentViewEnum:
      IProjectsAgentIntentsApiClientIntentViewEnum = {
        INTENT_VIEW_FULL:
            <ProjectsAgentIntentsApiClientIntentView>'INTENT_VIEW_FULL',
        INTENT_VIEW_UNSPECIFIED:
            <ProjectsAgentIntentsApiClientIntentView>'INTENT_VIEW_UNSPECIFIED',
        values():
            Array<ProjectsAgentIntentsApiClientIntentView> {
              return [
                ProjectsAgentIntentsApiClientIntentViewEnum
                    .INTENT_VIEW_UNSPECIFIED,
                ProjectsAgentIntentsApiClientIntentViewEnum.INTENT_VIEW_FULL
              ];
            }
      };

  export declare interface ProjectsAgentIntentsBatchDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIntentsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentIntentsBatchUpdateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIntentsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentIntentsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIntentsApiClient$Xgafv;
    languageCode?: string;
    intentView?: ProjectsAgentIntentsApiClientIntentView;
  }

  export declare interface ProjectsAgentIntentsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIntentsApiClient$Xgafv;
    displayName?: string;
  }

  export declare interface ProjectsAgentIntentsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIntentsApiClient$Xgafv;
    languageCode?: string;
    intentView?: ProjectsAgentIntentsApiClientIntentView;
  }

  export declare interface ProjectsAgentIntentsListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIntentsApiClient$Xgafv;
    languageCode?: string;
    intentView?: ProjectsAgentIntentsApiClientIntentView;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAgentIntentsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIntentsApiClient$Xgafv;
    languageCode?: string;
    updateMask?: string;
    intentView?: ProjectsAgentIntentsApiClientIntentView;
  }

  export class ProjectsAgentIntentsApiClientImpl implements
      ProjectsAgentIntentsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchDelete(
        parent: string, $requestBody: BatchDeleteIntentsRequest_,
        namedParameters: ProjectsAgentIntentsBatchDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.intents.batchDelete',
        path: `/${this.gapiVersion}/${parent}/intents:batchDelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchUpdate(
        parent: string, $requestBody: BatchUpdateIntentsRequest_,
        namedParameters: ProjectsAgentIntentsBatchUpdateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.intents.batchUpdate',
        path: `/${this.gapiVersion}/${parent}/intents:batchUpdate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    create(
        parent: string, $requestBody: Intent_,
        namedParameters: ProjectsAgentIntentsCreateNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Intent_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));

      return this.$apiClient.$request<Intent_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.intents.create',
        path: `/${this.gapiVersion}/${parent}/intents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Intent_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsAgentIntentsDeleteNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/intents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.agent.intents.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsAgentIntentsGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Intent_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/intents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Intent_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.intents.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Intent_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsAgentIntentsListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListIntentsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListIntentsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.intents.list',
        path: `/${this.gapiVersion}/${parent}/intents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListIntentsResponse_
      });
    }

    patch(
        name: string, $requestBody: Intent_,
        namedParameters: ProjectsAgentIntentsPatchNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Intent_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/intents/[^/]+$'));

      return this.$apiClient.$request<Intent_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.agent.intents.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Intent_
      });
    }
  }

  export abstract class ProjectsAgentIntentsApiClient {
    constructor() {}

    abstract batchDelete(
        parent: string, $requestBody: BatchDeleteIntentsRequest_,
        namedParameters?: ProjectsAgentIntentsBatchDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchUpdate(
        parent: string, $requestBody: BatchUpdateIntentsRequest_,
        namedParameters?: ProjectsAgentIntentsBatchUpdateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract create(
        parent: string, $requestBody: Intent_,
        namedParameters?: ProjectsAgentIntentsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Intent_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsAgentIntentsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsAgentIntentsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Intent_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsAgentIntentsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListIntentsResponse_>;

    abstract patch(
        name: string, $requestBody: Intent_,
        namedParameters?: ProjectsAgentIntentsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Intent_>;
  }

  export type ProjectsAgentIvrNodesApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentIvrNodesApiClient$XgafvEnum {
    readonly 1: ProjectsAgentIvrNodesApiClient$Xgafv;
    readonly 2: ProjectsAgentIvrNodesApiClient$Xgafv;

    values(): Array<ProjectsAgentIvrNodesApiClient$Xgafv>;
  }

  export const ProjectsAgentIvrNodesApiClient$XgafvEnum:
      IProjectsAgentIvrNodesApiClient$XgafvEnum = {
        1: <ProjectsAgentIvrNodesApiClient$Xgafv>'1',
        2: <ProjectsAgentIvrNodesApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentIvrNodesApiClient$Xgafv> {
              return [
                ProjectsAgentIvrNodesApiClient$XgafvEnum[1],
                ProjectsAgentIvrNodesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentIvrNodesApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsAgentIvrNodesApiClientAltEnum {
    readonly JSON: ProjectsAgentIvrNodesApiClientAlt;
    readonly MEDIA: ProjectsAgentIvrNodesApiClientAlt;
    readonly PROTO: ProjectsAgentIvrNodesApiClientAlt;

    values(): Array<ProjectsAgentIvrNodesApiClientAlt>;
  }

  export const ProjectsAgentIvrNodesApiClientAltEnum:
      IProjectsAgentIvrNodesApiClientAltEnum = {
        JSON: <ProjectsAgentIvrNodesApiClientAlt>'json',
        MEDIA: <ProjectsAgentIvrNodesApiClientAlt>'media',
        PROTO: <ProjectsAgentIvrNodesApiClientAlt>'proto',
        values():
            Array<ProjectsAgentIvrNodesApiClientAlt> {
              return [
                ProjectsAgentIvrNodesApiClientAltEnum.JSON,
                ProjectsAgentIvrNodesApiClientAltEnum.MEDIA,
                ProjectsAgentIvrNodesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsAgentIvrNodesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIvrNodesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIvrNodesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentIvrNodesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIvrNodesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIvrNodesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentIvrNodesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIvrNodesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIvrNodesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentIvrNodesListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIvrNodesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIvrNodesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAgentIvrNodesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentIvrNodesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentIvrNodesApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsAgentIvrNodesApiClientImpl implements
      ProjectsAgentIvrNodesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: IvrNode_,
        namedParameters: ProjectsAgentIvrNodesCreateNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<IvrNode_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));

      return this.$apiClient.$request<IvrNode_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.ivrNodes.create',
        path: `/${this.gapiVersion}/${parent}/ivrNodes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: IvrNode_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsAgentIvrNodesDeleteNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/ivrNodes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.agent.ivrNodes.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsAgentIvrNodesGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<IvrNode_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/ivrNodes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<IvrNode_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.ivrNodes.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: IvrNode_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsAgentIvrNodesListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListIvrNodesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListIvrNodesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.ivrNodes.list',
        path: `/${this.gapiVersion}/${parent}/ivrNodes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListIvrNodesResponse_
      });
    }

    patch(
        name: string, $requestBody: IvrNode_,
        namedParameters: ProjectsAgentIvrNodesPatchNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<IvrNode_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/ivrNodes/[^/]+$'));

      return this.$apiClient.$request<IvrNode_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.agent.ivrNodes.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: IvrNode_
      });
    }
  }

  export abstract class ProjectsAgentIvrNodesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: IvrNode_,
        namedParameters?: ProjectsAgentIvrNodesCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<IvrNode_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsAgentIvrNodesDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsAgentIvrNodesGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<IvrNode_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsAgentIvrNodesListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListIvrNodesResponse_>;

    abstract patch(
        name: string, $requestBody: IvrNode_,
        namedParameters?: ProjectsAgentIvrNodesPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<IvrNode_>;
  }

  export type ProjectsAgentKnowledgeBasesApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentKnowledgeBasesApiClient$XgafvEnum {
    readonly 1: ProjectsAgentKnowledgeBasesApiClient$Xgafv;
    readonly 2: ProjectsAgentKnowledgeBasesApiClient$Xgafv;

    values(): Array<ProjectsAgentKnowledgeBasesApiClient$Xgafv>;
  }

  export const ProjectsAgentKnowledgeBasesApiClient$XgafvEnum:
      IProjectsAgentKnowledgeBasesApiClient$XgafvEnum = {
        1: <ProjectsAgentKnowledgeBasesApiClient$Xgafv>'1',
        2: <ProjectsAgentKnowledgeBasesApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentKnowledgeBasesApiClient$Xgafv> {
              return [
                ProjectsAgentKnowledgeBasesApiClient$XgafvEnum[1],
                ProjectsAgentKnowledgeBasesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentKnowledgeBasesApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsAgentKnowledgeBasesApiClientAltEnum {
    readonly JSON: ProjectsAgentKnowledgeBasesApiClientAlt;
    readonly MEDIA: ProjectsAgentKnowledgeBasesApiClientAlt;
    readonly PROTO: ProjectsAgentKnowledgeBasesApiClientAlt;

    values(): Array<ProjectsAgentKnowledgeBasesApiClientAlt>;
  }

  export const ProjectsAgentKnowledgeBasesApiClientAltEnum:
      IProjectsAgentKnowledgeBasesApiClientAltEnum = {
        JSON: <ProjectsAgentKnowledgeBasesApiClientAlt>'json',
        MEDIA: <ProjectsAgentKnowledgeBasesApiClientAlt>'media',
        PROTO: <ProjectsAgentKnowledgeBasesApiClientAlt>'proto',
        values():
            Array<ProjectsAgentKnowledgeBasesApiClientAlt> {
              return [
                ProjectsAgentKnowledgeBasesApiClientAltEnum.JSON,
                ProjectsAgentKnowledgeBasesApiClientAltEnum.MEDIA,
                ProjectsAgentKnowledgeBasesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsAgentKnowledgeBasesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentKnowledgeBasesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesApiClient$Xgafv;
    force?: boolean;
  }

  export declare interface ProjectsAgentKnowledgeBasesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentKnowledgeBasesListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsAgentKnowledgeBasesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsAgentKnowledgeBasesApiClientImpl implements
      ProjectsAgentKnowledgeBasesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: KnowledgeBase_,
        namedParameters: ProjectsAgentKnowledgeBasesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<KnowledgeBase_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));

      return this.$apiClient.$request<KnowledgeBase_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.knowledgeBases.create',
        path: `/${this.gapiVersion}/${parent}/knowledgeBases`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: KnowledgeBase_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsAgentKnowledgeBasesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/knowledgeBases/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.agent.knowledgeBases.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsAgentKnowledgeBasesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<KnowledgeBase_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/knowledgeBases/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<KnowledgeBase_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.knowledgeBases.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: KnowledgeBase_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsAgentKnowledgeBasesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListKnowledgeBasesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListKnowledgeBasesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.knowledgeBases.list',
        path: `/${this.gapiVersion}/${parent}/knowledgeBases`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListKnowledgeBasesResponse_
      });
    }

    patch(
        name: string, $requestBody: KnowledgeBase_,
        namedParameters: ProjectsAgentKnowledgeBasesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<KnowledgeBase_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/knowledgeBases/[^/]+$'));

      return this.$apiClient.$request<KnowledgeBase_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.agent.knowledgeBases.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: KnowledgeBase_
      });
    }
  }

  export abstract class ProjectsAgentKnowledgeBasesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: KnowledgeBase_,
        namedParameters?: ProjectsAgentKnowledgeBasesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<KnowledgeBase_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsAgentKnowledgeBasesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsAgentKnowledgeBasesGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<KnowledgeBase_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsAgentKnowledgeBasesListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListKnowledgeBasesResponse_>;

    abstract patch(
        name: string, $requestBody: KnowledgeBase_,
        namedParameters?: ProjectsAgentKnowledgeBasesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<KnowledgeBase_>;
  }

  export type ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentKnowledgeBasesDocumentsApiClient$XgafvEnum {
    readonly 1: ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv;
    readonly 2: ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv;

    values(): Array<ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv>;
  }

  export const ProjectsAgentKnowledgeBasesDocumentsApiClient$XgafvEnum:
      IProjectsAgentKnowledgeBasesDocumentsApiClient$XgafvEnum = {
        1: <ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv>'1',
        2: <ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv> {
              return [
                ProjectsAgentKnowledgeBasesDocumentsApiClient$XgafvEnum[1],
                ProjectsAgentKnowledgeBasesDocumentsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentKnowledgeBasesDocumentsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentKnowledgeBasesDocumentsApiClientAltEnum {
    readonly JSON: ProjectsAgentKnowledgeBasesDocumentsApiClientAlt;
    readonly MEDIA: ProjectsAgentKnowledgeBasesDocumentsApiClientAlt;
    readonly PROTO: ProjectsAgentKnowledgeBasesDocumentsApiClientAlt;

    values(): Array<ProjectsAgentKnowledgeBasesDocumentsApiClientAlt>;
  }

  export const ProjectsAgentKnowledgeBasesDocumentsApiClientAltEnum:
      IProjectsAgentKnowledgeBasesDocumentsApiClientAltEnum = {
        JSON: <ProjectsAgentKnowledgeBasesDocumentsApiClientAlt>'json',
        MEDIA: <ProjectsAgentKnowledgeBasesDocumentsApiClientAlt>'media',
        PROTO: <ProjectsAgentKnowledgeBasesDocumentsApiClientAlt>'proto',
        values():
            Array<ProjectsAgentKnowledgeBasesDocumentsApiClientAlt> {
              return [
                ProjectsAgentKnowledgeBasesDocumentsApiClientAltEnum.JSON,
                ProjectsAgentKnowledgeBasesDocumentsApiClientAltEnum.MEDIA,
                ProjectsAgentKnowledgeBasesDocumentsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsAgentKnowledgeBasesDocumentsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv;
    importGcsCustomMetadata?: boolean;
  }

  export declare interface ProjectsAgentKnowledgeBasesDocumentsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentKnowledgeBasesDocumentsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentKnowledgeBasesDocumentsListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsAgentKnowledgeBasesDocumentsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsAgentKnowledgeBasesDocumentsReloadNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export class ProjectsAgentKnowledgeBasesDocumentsApiClientImpl implements
      ProjectsAgentKnowledgeBasesDocumentsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Document_,
        namedParameters:
            ProjectsAgentKnowledgeBasesDocumentsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/knowledgeBases/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.knowledgeBases.documents.create',
        path: `/${this.gapiVersion}/${parent}/documents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsAgentKnowledgeBasesDocumentsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.agent.knowledgeBases.documents.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters: ProjectsAgentKnowledgeBasesDocumentsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Document_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Document_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.knowledgeBases.documents.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Document_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsAgentKnowledgeBasesDocumentsListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListDocumentsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/knowledgeBases/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListDocumentsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.knowledgeBases.documents.list',
        path: `/${this.gapiVersion}/${parent}/documents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListDocumentsResponse_
      });
    }

    patch(
        name: string, $requestBody: Document_,
        namedParameters:
            ProjectsAgentKnowledgeBasesDocumentsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.agent.knowledgeBases.documents.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    reload(
        name: string, $requestBody: ReloadDocumentRequest_,
        namedParameters:
            ProjectsAgentKnowledgeBasesDocumentsReloadNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.knowledgeBases.documents.reload',
        path: `/${this.gapiVersion}/${name}:reload`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }
  }

  export abstract class ProjectsAgentKnowledgeBasesDocumentsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Document_,
        namedParameters?:
            ProjectsAgentKnowledgeBasesDocumentsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsAgentKnowledgeBasesDocumentsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsAgentKnowledgeBasesDocumentsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Document_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsAgentKnowledgeBasesDocumentsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListDocumentsResponse_>;

    abstract patch(
        name: string, $requestBody: Document_,
        namedParameters?:
            ProjectsAgentKnowledgeBasesDocumentsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract reload(
        name: string, $requestBody: ReloadDocumentRequest_,
        namedParameters?:
            ProjectsAgentKnowledgeBasesDocumentsReloadNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;
  }

  export type ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum {
    readonly 1:
        ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
    readonly 2:
        ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;

    values(): Array<
        ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv>;
  }

  export const ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum:
      IProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum = {
        1: <ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv>'1',
        2: <ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv> {
              return [
                ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum
                    [1],
                ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum {
    readonly JSON:
        ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    readonly MEDIA:
        ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    readonly PROTO:
        ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;

    values(): Array<
        ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>;
  }

  export const ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum:
      IProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum = {
        JSON: <
            ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>'json',
        MEDIA: <
            ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>'media',
        PROTO: <
            ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>'proto',
        values():
            Array<
                ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt> {
              return [
                ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum
                    .JSON,
                ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum
                    .MEDIA,
                ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
    updateMask?: string;
  }

  export class
      ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientImpl
          implements
              ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    get(name: string,
        namedParameters:
            ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<QuestionAnswerEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/knowledgeBases/[^/]+/documents/[^/]+/questionAnswerEntries/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<QuestionAnswerEntry_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.agent.knowledgeBases.documents.questionAnswerEntries.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: QuestionAnswerEntry_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListQuestionAnswerEntriesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/agent/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListQuestionAnswerEntriesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.agent.knowledgeBases.documents.questionAnswerEntries.list',
        path: `/${this.gapiVersion}/${parent}/questionAnswerEntries`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListQuestionAnswerEntriesResponse_
      });
    }

    patch(
        name: string, $requestBody: QuestionAnswerEntry_,
        namedParameters:
            ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<QuestionAnswerEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/knowledgeBases/[^/]+/documents/[^/]+/questionAnswerEntries/[^/]+$'));

      return this.$apiClient.$request<QuestionAnswerEntry_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.agent.knowledgeBases.documents.questionAnswerEntries.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: QuestionAnswerEntry_
      });
    }
  }

  export abstract class
      ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient {
    constructor() {}

    abstract get(
        name: string,
        namedParameters?:
            ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<QuestionAnswerEntry_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListQuestionAnswerEntriesResponse_>;

    abstract patch(
        name: string, $requestBody: QuestionAnswerEntry_,
        namedParameters?:
            ProjectsAgentKnowledgeBasesDocumentsQuestionAnswerEntriesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<QuestionAnswerEntry_>;
  }

  export type ProjectsAgentSessionsApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentSessionsApiClient$XgafvEnum {
    readonly 1: ProjectsAgentSessionsApiClient$Xgafv;
    readonly 2: ProjectsAgentSessionsApiClient$Xgafv;

    values(): Array<ProjectsAgentSessionsApiClient$Xgafv>;
  }

  export const ProjectsAgentSessionsApiClient$XgafvEnum:
      IProjectsAgentSessionsApiClient$XgafvEnum = {
        1: <ProjectsAgentSessionsApiClient$Xgafv>'1',
        2: <ProjectsAgentSessionsApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentSessionsApiClient$Xgafv> {
              return [
                ProjectsAgentSessionsApiClient$XgafvEnum[1],
                ProjectsAgentSessionsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentSessionsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsAgentSessionsApiClientAltEnum {
    readonly JSON: ProjectsAgentSessionsApiClientAlt;
    readonly MEDIA: ProjectsAgentSessionsApiClientAlt;
    readonly PROTO: ProjectsAgentSessionsApiClientAlt;

    values(): Array<ProjectsAgentSessionsApiClientAlt>;
  }

  export const ProjectsAgentSessionsApiClientAltEnum:
      IProjectsAgentSessionsApiClientAltEnum = {
        JSON: <ProjectsAgentSessionsApiClientAlt>'json',
        MEDIA: <ProjectsAgentSessionsApiClientAlt>'media',
        PROTO: <ProjectsAgentSessionsApiClientAlt>'proto',
        values():
            Array<ProjectsAgentSessionsApiClientAlt> {
              return [
                ProjectsAgentSessionsApiClientAltEnum.JSON,
                ProjectsAgentSessionsApiClientAltEnum.MEDIA,
                ProjectsAgentSessionsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsAgentSessionsApiClientIntentMatchFilter =
      'INTENT_MATCH_FILTER_UNSPECIFIED'|'ALL_SESSION_CONVERSATIONS'|
      'NO_MATCH_SESSION_CONVERSATIONS';

  export interface IProjectsAgentSessionsApiClientIntentMatchFilterEnum {
    readonly INTENT_MATCH_FILTER_UNSPECIFIED:
        ProjectsAgentSessionsApiClientIntentMatchFilter;
    readonly ALL_SESSION_CONVERSATIONS:
        ProjectsAgentSessionsApiClientIntentMatchFilter;
    readonly NO_MATCH_SESSION_CONVERSATIONS:
        ProjectsAgentSessionsApiClientIntentMatchFilter;

    values(): Array<ProjectsAgentSessionsApiClientIntentMatchFilter>;
  }

  export const ProjectsAgentSessionsApiClientIntentMatchFilterEnum:
      IProjectsAgentSessionsApiClientIntentMatchFilterEnum = {
        ALL_SESSION_CONVERSATIONS: <
            ProjectsAgentSessionsApiClientIntentMatchFilter>'ALL_SESSION_CONVERSATIONS',
        INTENT_MATCH_FILTER_UNSPECIFIED: <
            ProjectsAgentSessionsApiClientIntentMatchFilter>'INTENT_MATCH_FILTER_UNSPECIFIED',
        NO_MATCH_SESSION_CONVERSATIONS: <
            ProjectsAgentSessionsApiClientIntentMatchFilter>'NO_MATCH_SESSION_CONVERSATIONS',
        values():
            Array<ProjectsAgentSessionsApiClientIntentMatchFilter> {
              return [
                ProjectsAgentSessionsApiClientIntentMatchFilterEnum
                    .INTENT_MATCH_FILTER_UNSPECIFIED,
                ProjectsAgentSessionsApiClientIntentMatchFilterEnum
                    .ALL_SESSION_CONVERSATIONS,
                ProjectsAgentSessionsApiClientIntentMatchFilterEnum
                    .NO_MATCH_SESSION_CONVERSATIONS
              ];
            }
      };

  export declare interface ProjectsAgentSessionsDeleteContextsNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsDetectIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsFulfillIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsMatchIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsPushFulfillmentResultNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsSearchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsApiClient$Xgafv;
    languageCode?: string;
    startTime?: string;
    endTime?: string;
    platform?: string;
    intentMatchFilter?: ProjectsAgentSessionsApiClientIntentMatchFilter;
    pageToken?: string;
    pageSize?: number;
    interactionsPageSize?: number;
    searchBackward?: boolean;
  }

  export declare interface ProjectsAgentSessionsSetAllContextsNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsApiClient$Xgafv;
  }

  export class ProjectsAgentSessionsApiClientImpl implements
      ProjectsAgentSessionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    deleteContexts(
        parent: string,
        namedParameters: ProjectsAgentSessionsDeleteContextsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.agent.sessions.deleteContexts',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    detectIntent(
        session: string, $requestBody: DetectIntentRequest_,
        namedParameters: ProjectsAgentSessionsDetectIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<DetectIntentResponse_> {
      this.$apiClient.$validateParameter(
          session, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<DetectIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.sessions.detectIntent',
        path: `/${this.gapiVersion}/${session}:detectIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: DetectIntentResponse_
      });
    }

    fulfillIntent(
        session: string, $requestBody: FulfillIntentRequest_,
        namedParameters: ProjectsAgentSessionsFulfillIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<DetectIntentResponse_> {
      this.$apiClient.$validateParameter(
          session, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<DetectIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.sessions.fulfillIntent',
        path: `/${this.gapiVersion}/${session}:fulfillIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: DetectIntentResponse_
      });
    }

    matchIntent(
        session: string, $requestBody: MatchIntentRequest_,
        namedParameters: ProjectsAgentSessionsMatchIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<MatchIntentResponse_> {
      this.$apiClient.$validateParameter(
          session, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<MatchIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.sessions.matchIntent',
        path: `/${this.gapiVersion}/${session}:matchIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: MatchIntentResponse_
      });
    }

    pushFulfillmentResult(
        session: string, $requestBody: FulfillmentResult_,
        namedParameters:
            ProjectsAgentSessionsPushFulfillmentResultNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<FulfillmentResult_> {
      this.$apiClient.$validateParameter(
          session, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<FulfillmentResult_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.sessions.pushFulfillmentResult',
        path: `/${this.gapiVersion}/${session}:pushFulfillmentResult`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: FulfillmentResult_
      });
    }

    search(
        parent: string,
        namedParameters: ProjectsAgentSessionsSearchNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SearchSessionConversationsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SearchSessionConversationsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.sessions.search',
        path: `/${this.gapiVersion}/${parent}:search`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SearchSessionConversationsResponse_
      });
    }

    setAllContexts(
        parent: string, $requestBody: SetAllContextsRequest_,
        namedParameters: ProjectsAgentSessionsSetAllContextsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SetAllContextsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<SetAllContextsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.sessions.setAllContexts',
        path: `/${this.gapiVersion}/${parent}:setAllContexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SetAllContextsResponse_
      });
    }
  }

  export abstract class ProjectsAgentSessionsApiClient {
    constructor() {}

    abstract deleteContexts(
        parent: string,
        namedParameters?: ProjectsAgentSessionsDeleteContextsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract detectIntent(
        session: string, $requestBody: DetectIntentRequest_,
        namedParameters?: ProjectsAgentSessionsDetectIntentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<DetectIntentResponse_>;

    abstract fulfillIntent(
        session: string, $requestBody: FulfillIntentRequest_,
        namedParameters?: ProjectsAgentSessionsFulfillIntentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<DetectIntentResponse_>;

    abstract matchIntent(
        session: string, $requestBody: MatchIntentRequest_,
        namedParameters?: ProjectsAgentSessionsMatchIntentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<MatchIntentResponse_>;

    abstract pushFulfillmentResult(
        session: string, $requestBody: FulfillmentResult_,
        namedParameters?:
            ProjectsAgentSessionsPushFulfillmentResultNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<FulfillmentResult_>;

    abstract search(
        parent: string,
        namedParameters?: ProjectsAgentSessionsSearchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SearchSessionConversationsResponse_>;

    abstract setAllContexts(
        parent: string, $requestBody: SetAllContextsRequest_,
        namedParameters?: ProjectsAgentSessionsSetAllContextsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SetAllContextsResponse_>;
  }

  export type ProjectsAgentSessionsContextsApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentSessionsContextsApiClient$XgafvEnum {
    readonly 1: ProjectsAgentSessionsContextsApiClient$Xgafv;
    readonly 2: ProjectsAgentSessionsContextsApiClient$Xgafv;

    values(): Array<ProjectsAgentSessionsContextsApiClient$Xgafv>;
  }

  export const ProjectsAgentSessionsContextsApiClient$XgafvEnum:
      IProjectsAgentSessionsContextsApiClient$XgafvEnum = {
        1: <ProjectsAgentSessionsContextsApiClient$Xgafv>'1',
        2: <ProjectsAgentSessionsContextsApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentSessionsContextsApiClient$Xgafv> {
              return [
                ProjectsAgentSessionsContextsApiClient$XgafvEnum[1],
                ProjectsAgentSessionsContextsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentSessionsContextsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentSessionsContextsApiClientAltEnum {
    readonly JSON: ProjectsAgentSessionsContextsApiClientAlt;
    readonly MEDIA: ProjectsAgentSessionsContextsApiClientAlt;
    readonly PROTO: ProjectsAgentSessionsContextsApiClientAlt;

    values(): Array<ProjectsAgentSessionsContextsApiClientAlt>;
  }

  export const ProjectsAgentSessionsContextsApiClientAltEnum:
      IProjectsAgentSessionsContextsApiClientAltEnum = {
        JSON: <ProjectsAgentSessionsContextsApiClientAlt>'json',
        MEDIA: <ProjectsAgentSessionsContextsApiClientAlt>'media',
        PROTO: <ProjectsAgentSessionsContextsApiClientAlt>'proto',
        values():
            Array<ProjectsAgentSessionsContextsApiClientAlt> {
              return [
                ProjectsAgentSessionsContextsApiClientAltEnum.JSON,
                ProjectsAgentSessionsContextsApiClientAltEnum.MEDIA,
                ProjectsAgentSessionsContextsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsAgentSessionsContextsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsContextsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsContextsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsContextsListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsContextsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAgentSessionsContextsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsContextsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsAgentSessionsContextsApiClientImpl implements
      ProjectsAgentSessionsContextsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Context_,
        namedParameters: ProjectsAgentSessionsContextsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.sessions.contexts.create',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsAgentSessionsContextsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/agent/sessions/[^/]+/contexts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.agent.sessions.contexts.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsAgentSessionsContextsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/agent/sessions/[^/]+/contexts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.sessions.contexts.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsAgentSessionsContextsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListContextsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListContextsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.sessions.contexts.list',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListContextsResponse_
      });
    }

    patch(
        name: string, $requestBody: Context_,
        namedParameters: ProjectsAgentSessionsContextsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/agent/sessions/[^/]+/contexts/[^/]+$'));

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.agent.sessions.contexts.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }
  }

  export abstract class ProjectsAgentSessionsContextsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Context_,
        namedParameters?: ProjectsAgentSessionsContextsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsAgentSessionsContextsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsAgentSessionsContextsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsAgentSessionsContextsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListContextsResponse_>;

    abstract patch(
        name: string, $requestBody: Context_,
        namedParameters?: ProjectsAgentSessionsContextsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;
  }

  export type ProjectsAgentSessionsEntityTypesApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentSessionsEntityTypesApiClient$XgafvEnum {
    readonly 1: ProjectsAgentSessionsEntityTypesApiClient$Xgafv;
    readonly 2: ProjectsAgentSessionsEntityTypesApiClient$Xgafv;

    values(): Array<ProjectsAgentSessionsEntityTypesApiClient$Xgafv>;
  }

  export const ProjectsAgentSessionsEntityTypesApiClient$XgafvEnum:
      IProjectsAgentSessionsEntityTypesApiClient$XgafvEnum = {
        1: <ProjectsAgentSessionsEntityTypesApiClient$Xgafv>'1',
        2: <ProjectsAgentSessionsEntityTypesApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentSessionsEntityTypesApiClient$Xgafv> {
              return [
                ProjectsAgentSessionsEntityTypesApiClient$XgafvEnum[1],
                ProjectsAgentSessionsEntityTypesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentSessionsEntityTypesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsAgentSessionsEntityTypesApiClientAltEnum {
    readonly JSON: ProjectsAgentSessionsEntityTypesApiClientAlt;
    readonly MEDIA: ProjectsAgentSessionsEntityTypesApiClientAlt;
    readonly PROTO: ProjectsAgentSessionsEntityTypesApiClientAlt;

    values(): Array<ProjectsAgentSessionsEntityTypesApiClientAlt>;
  }

  export const ProjectsAgentSessionsEntityTypesApiClientAltEnum:
      IProjectsAgentSessionsEntityTypesApiClientAltEnum = {
        JSON: <ProjectsAgentSessionsEntityTypesApiClientAlt>'json',
        MEDIA: <ProjectsAgentSessionsEntityTypesApiClientAlt>'media',
        PROTO: <ProjectsAgentSessionsEntityTypesApiClientAlt>'proto',
        values():
            Array<ProjectsAgentSessionsEntityTypesApiClientAlt> {
              return [
                ProjectsAgentSessionsEntityTypesApiClientAltEnum.JSON,
                ProjectsAgentSessionsEntityTypesApiClientAltEnum.MEDIA,
                ProjectsAgentSessionsEntityTypesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsAgentSessionsEntityTypesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsEntityTypesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsEntityTypesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsAgentSessionsEntityTypesListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsEntityTypesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAgentSessionsEntityTypesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentSessionsEntityTypesApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsAgentSessionsEntityTypesApiClientImpl implements
      ProjectsAgentSessionsEntityTypesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: SessionEntityType_,
        namedParameters: ProjectsAgentSessionsEntityTypesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.sessions.entityTypes.create',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsAgentSessionsEntityTypesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/sessions/[^/]+/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.agent.sessions.entityTypes.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsAgentSessionsEntityTypesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/sessions/[^/]+/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.sessions.entityTypes.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsAgentSessionsEntityTypesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListSessionEntityTypesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListSessionEntityTypesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.sessions.entityTypes.list',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListSessionEntityTypesResponse_
      });
    }

    patch(
        name: string, $requestBody: SessionEntityType_,
        namedParameters: ProjectsAgentSessionsEntityTypesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/agent/sessions/[^/]+/entityTypes/[^/]+$'));

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.agent.sessions.entityTypes.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }
  }

  export abstract class ProjectsAgentSessionsEntityTypesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: SessionEntityType_,
        namedParameters?: ProjectsAgentSessionsEntityTypesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsAgentSessionsEntityTypesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsAgentSessionsEntityTypesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsAgentSessionsEntityTypesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListSessionEntityTypesResponse_>;

    abstract patch(
        name: string, $requestBody: SessionEntityType_,
        namedParameters?: ProjectsAgentSessionsEntityTypesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;
  }

  export type ProjectsAgentVersionsApiClient$Xgafv = '1'|'2';

  export interface IProjectsAgentVersionsApiClient$XgafvEnum {
    readonly 1: ProjectsAgentVersionsApiClient$Xgafv;
    readonly 2: ProjectsAgentVersionsApiClient$Xgafv;

    values(): Array<ProjectsAgentVersionsApiClient$Xgafv>;
  }

  export const ProjectsAgentVersionsApiClient$XgafvEnum:
      IProjectsAgentVersionsApiClient$XgafvEnum = {
        1: <ProjectsAgentVersionsApiClient$Xgafv>'1',
        2: <ProjectsAgentVersionsApiClient$Xgafv>'2',
        values():
            Array<ProjectsAgentVersionsApiClient$Xgafv> {
              return [
                ProjectsAgentVersionsApiClient$XgafvEnum[1],
                ProjectsAgentVersionsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAgentVersionsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsAgentVersionsApiClientAltEnum {
    readonly JSON: ProjectsAgentVersionsApiClientAlt;
    readonly MEDIA: ProjectsAgentVersionsApiClientAlt;
    readonly PROTO: ProjectsAgentVersionsApiClientAlt;

    values(): Array<ProjectsAgentVersionsApiClientAlt>;
  }

  export const ProjectsAgentVersionsApiClientAltEnum:
      IProjectsAgentVersionsApiClientAltEnum = {
        JSON: <ProjectsAgentVersionsApiClientAlt>'json',
        MEDIA: <ProjectsAgentVersionsApiClientAlt>'media',
        PROTO: <ProjectsAgentVersionsApiClientAlt>'proto',
        values():
            Array<ProjectsAgentVersionsApiClientAlt> {
              return [
                ProjectsAgentVersionsApiClientAltEnum.JSON,
                ProjectsAgentVersionsApiClientAltEnum.MEDIA,
                ProjectsAgentVersionsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsAgentVersionsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentVersionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentVersionsApiClient$Xgafv;
    withoutActionsOnGoogle?: boolean;
    enableVersionLimit?: boolean;
  }

  export declare interface ProjectsAgentVersionsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentVersionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentVersionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentVersionsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentVersionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentVersionsApiClient$Xgafv;
  }

  export declare interface ProjectsAgentVersionsListNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentVersionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentVersionsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAgentVersionsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAgentVersionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAgentVersionsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsAgentVersionsApiClientImpl implements
      ProjectsAgentVersionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Version_,
        namedParameters: ProjectsAgentVersionsCreateNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Version_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));

      return this.$apiClient.$request<Version_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.agent.versions.create',
        path: `/${this.gapiVersion}/${parent}/versions`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Version_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsAgentVersionsDeleteNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/versions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.agent.versions.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsAgentVersionsGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Version_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/versions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Version_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.versions.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Version_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsAgentVersionsListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListVersionsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListVersionsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.agent.versions.list',
        path: `/${this.gapiVersion}/${parent}/versions`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListVersionsResponse_
      });
    }

    patch(
        name: string, $requestBody: Version_,
        namedParameters: ProjectsAgentVersionsPatchNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Version_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/agent/versions/[^/]+$'));

      return this.$apiClient.$request<Version_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.agent.versions.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Version_
      });
    }
  }

  export abstract class ProjectsAgentVersionsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Version_,
        namedParameters?: ProjectsAgentVersionsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Version_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsAgentVersionsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsAgentVersionsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Version_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsAgentVersionsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListVersionsResponse_>;

    abstract patch(
        name: string, $requestBody: Version_,
        namedParameters?: ProjectsAgentVersionsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Version_>;
  }

  export type ProjectsAnalyticsApiClient$Xgafv = '1'|'2';

  export interface IProjectsAnalyticsApiClient$XgafvEnum {
    readonly 1: ProjectsAnalyticsApiClient$Xgafv;
    readonly 2: ProjectsAnalyticsApiClient$Xgafv;

    values(): Array<ProjectsAnalyticsApiClient$Xgafv>;
  }

  export const ProjectsAnalyticsApiClient$XgafvEnum:
      IProjectsAnalyticsApiClient$XgafvEnum = {
        1: <ProjectsAnalyticsApiClient$Xgafv>'1',
        2: <ProjectsAnalyticsApiClient$Xgafv>'2',
        values():
            Array<ProjectsAnalyticsApiClient$Xgafv> {
              return [
                ProjectsAnalyticsApiClient$XgafvEnum[1],
                ProjectsAnalyticsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAnalyticsApiClientAccumulationMode =
      'ACCUMULATION_MODE_UNSPECIFIED'|'ACCUMULATE_INTENT_METRICS';

  export interface IProjectsAnalyticsApiClientAccumulationModeEnum {
    readonly ACCUMULATION_MODE_UNSPECIFIED:
        ProjectsAnalyticsApiClientAccumulationMode;
    readonly ACCUMULATE_INTENT_METRICS:
        ProjectsAnalyticsApiClientAccumulationMode;

    values(): Array<ProjectsAnalyticsApiClientAccumulationMode>;
  }

  export const ProjectsAnalyticsApiClientAccumulationModeEnum:
      IProjectsAnalyticsApiClientAccumulationModeEnum = {
        ACCUMULATE_INTENT_METRICS: <
            ProjectsAnalyticsApiClientAccumulationMode>'ACCUMULATE_INTENT_METRICS',
        ACCUMULATION_MODE_UNSPECIFIED: <
            ProjectsAnalyticsApiClientAccumulationMode>'ACCUMULATION_MODE_UNSPECIFIED',
        values():
            Array<ProjectsAnalyticsApiClientAccumulationMode> {
              return [
                ProjectsAnalyticsApiClientAccumulationModeEnum
                    .ACCUMULATION_MODE_UNSPECIFIED,
                ProjectsAnalyticsApiClientAccumulationModeEnum
                    .ACCUMULATE_INTENT_METRICS
              ];
            }
      };

  export type ProjectsAnalyticsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsAnalyticsApiClientAltEnum {
    readonly JSON: ProjectsAnalyticsApiClientAlt;
    readonly MEDIA: ProjectsAnalyticsApiClientAlt;
    readonly PROTO: ProjectsAnalyticsApiClientAlt;

    values(): Array<ProjectsAnalyticsApiClientAlt>;
  }

  export const ProjectsAnalyticsApiClientAltEnum:
      IProjectsAnalyticsApiClientAltEnum = {
        JSON: <ProjectsAnalyticsApiClientAlt>'json',
        MEDIA: <ProjectsAnalyticsApiClientAlt>'media',
        PROTO: <ProjectsAnalyticsApiClientAlt>'proto',
        values():
            Array<ProjectsAnalyticsApiClientAlt> {
              return [
                ProjectsAnalyticsApiClientAltEnum.JSON,
                ProjectsAnalyticsApiClientAltEnum.MEDIA,
                ProjectsAnalyticsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsAnalyticsApiClientAnalyticsView =
      'ANALYTICS_VIEW_UNSPECIFIED'|'ALL'|'CONVERSATION_ONLY';

  export interface IProjectsAnalyticsApiClientAnalyticsViewEnum {
    readonly ANALYTICS_VIEW_UNSPECIFIED:
        ProjectsAnalyticsApiClientAnalyticsView;
    readonly ALL: ProjectsAnalyticsApiClientAnalyticsView;
    readonly CONVERSATION_ONLY: ProjectsAnalyticsApiClientAnalyticsView;

    values(): Array<ProjectsAnalyticsApiClientAnalyticsView>;
  }

  export const ProjectsAnalyticsApiClientAnalyticsViewEnum:
      IProjectsAnalyticsApiClientAnalyticsViewEnum = {
        ALL: <ProjectsAnalyticsApiClientAnalyticsView>'ALL',
        ANALYTICS_VIEW_UNSPECIFIED: <
            ProjectsAnalyticsApiClientAnalyticsView>'ANALYTICS_VIEW_UNSPECIFIED',
        CONVERSATION_ONLY:
            <ProjectsAnalyticsApiClientAnalyticsView>'CONVERSATION_ONLY',
        values():
            Array<ProjectsAnalyticsApiClientAnalyticsView> {
              return [
                ProjectsAnalyticsApiClientAnalyticsViewEnum
                    .ANALYTICS_VIEW_UNSPECIFIED,
                ProjectsAnalyticsApiClientAnalyticsViewEnum.ALL,
                ProjectsAnalyticsApiClientAnalyticsViewEnum.CONVERSATION_ONLY
              ];
            }
      };

  export declare interface ProjectsAnalyticsListNamedParameters {
    access_token?: string;
    alt?: ProjectsAnalyticsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAnalyticsApiClient$Xgafv;
    startTime?: string;
    endTime?: string;
    pageSize?: number;
    pageToken?: string;
    analyticsView?: ProjectsAnalyticsApiClientAnalyticsView;
    querySpecificVersion?: boolean;
    versionId?: string;
    accumulationMode?: ProjectsAnalyticsApiClientAccumulationMode;
  }

  export class ProjectsAnalyticsApiClientImpl implements
      ProjectsAnalyticsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters: ProjectsAnalyticsListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListAnalyticsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListAnalyticsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.analytics.list',
        path: `/${this.gapiVersion}/${parent}/analytics`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListAnalyticsResponse_
      });
    }
  }

  export abstract class ProjectsAnalyticsApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?: ProjectsAnalyticsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListAnalyticsResponse_>;
  }

  export type ProjectsAnswerRecordsApiClient$Xgafv = '1'|'2';

  export interface IProjectsAnswerRecordsApiClient$XgafvEnum {
    readonly 1: ProjectsAnswerRecordsApiClient$Xgafv;
    readonly 2: ProjectsAnswerRecordsApiClient$Xgafv;

    values(): Array<ProjectsAnswerRecordsApiClient$Xgafv>;
  }

  export const ProjectsAnswerRecordsApiClient$XgafvEnum:
      IProjectsAnswerRecordsApiClient$XgafvEnum = {
        1: <ProjectsAnswerRecordsApiClient$Xgafv>'1',
        2: <ProjectsAnswerRecordsApiClient$Xgafv>'2',
        values():
            Array<ProjectsAnswerRecordsApiClient$Xgafv> {
              return [
                ProjectsAnswerRecordsApiClient$XgafvEnum[1],
                ProjectsAnswerRecordsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsAnswerRecordsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsAnswerRecordsApiClientAltEnum {
    readonly JSON: ProjectsAnswerRecordsApiClientAlt;
    readonly MEDIA: ProjectsAnswerRecordsApiClientAlt;
    readonly PROTO: ProjectsAnswerRecordsApiClientAlt;

    values(): Array<ProjectsAnswerRecordsApiClientAlt>;
  }

  export const ProjectsAnswerRecordsApiClientAltEnum:
      IProjectsAnswerRecordsApiClientAltEnum = {
        JSON: <ProjectsAnswerRecordsApiClientAlt>'json',
        MEDIA: <ProjectsAnswerRecordsApiClientAlt>'media',
        PROTO: <ProjectsAnswerRecordsApiClientAlt>'proto',
        values():
            Array<ProjectsAnswerRecordsApiClientAlt> {
              return [
                ProjectsAnswerRecordsApiClientAltEnum.JSON,
                ProjectsAnswerRecordsApiClientAltEnum.MEDIA,
                ProjectsAnswerRecordsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsAnswerRecordsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsAnswerRecordsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAnswerRecordsApiClient$Xgafv;
  }

  export declare interface ProjectsAnswerRecordsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsAnswerRecordsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAnswerRecordsApiClient$Xgafv;
  }

  export declare interface ProjectsAnswerRecordsListNamedParameters {
    access_token?: string;
    alt?: ProjectsAnswerRecordsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAnswerRecordsApiClient$Xgafv;
    filter?: string;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsAnswerRecordsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsAnswerRecordsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsAnswerRecordsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsAnswerRecordsApiClientImpl implements
      ProjectsAnswerRecordsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    delete(
        name: string,
        namedParameters: ProjectsAnswerRecordsDeleteNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/answerRecords/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.answerRecords.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsAnswerRecordsGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AnswerRecord_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/answerRecords/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<AnswerRecord_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.answerRecords.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AnswerRecord_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsAnswerRecordsListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListAnswerRecordsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListAnswerRecordsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.answerRecords.list',
        path: `/${this.gapiVersion}/${parent}/answerRecords`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListAnswerRecordsResponse_
      });
    }

    patch(
        name: string, $requestBody: AnswerRecord_,
        namedParameters: ProjectsAnswerRecordsPatchNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AnswerRecord_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/answerRecords/[^/]+$'));

      return this.$apiClient.$request<AnswerRecord_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.answerRecords.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AnswerRecord_
      });
    }
  }

  export abstract class ProjectsAnswerRecordsApiClient {
    constructor() {}

    abstract delete(
        name: string,
        namedParameters?: ProjectsAnswerRecordsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsAnswerRecordsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<AnswerRecord_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsAnswerRecordsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListAnswerRecordsResponse_>;

    abstract patch(
        name: string, $requestBody: AnswerRecord_,
        namedParameters?: ProjectsAnswerRecordsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<AnswerRecord_>;
  }

  export type ProjectsApiClient$Xgafv = '1'|'2';

  export interface IProjectsApiClient$XgafvEnum {
    readonly 1: ProjectsApiClient$Xgafv;
    readonly 2: ProjectsApiClient$Xgafv;

    values(): Array<ProjectsApiClient$Xgafv>;
  }

  export const ProjectsApiClient$XgafvEnum: IProjectsApiClient$XgafvEnum = {
    1: <ProjectsApiClient$Xgafv>'1',
    2: <ProjectsApiClient$Xgafv>'2',
    values():
        Array<ProjectsApiClient$Xgafv> {
          return [
            ProjectsApiClient$XgafvEnum[1], ProjectsApiClient$XgafvEnum[2]
          ];
        }
  };

  export type ProjectsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsApiClientAltEnum {
    readonly JSON: ProjectsApiClientAlt;
    readonly MEDIA: ProjectsApiClientAlt;
    readonly PROTO: ProjectsApiClientAlt;

    values(): Array<ProjectsApiClientAlt>;
  }

  export const ProjectsApiClientAltEnum: IProjectsApiClientAltEnum = {
    JSON: <ProjectsApiClientAlt>'json',
    MEDIA: <ProjectsApiClientAlt>'media',
    PROTO: <ProjectsApiClientAlt>'proto',
    values():
        Array<ProjectsApiClientAlt> {
          return [
            ProjectsApiClientAltEnum.JSON, ProjectsApiClientAltEnum.MEDIA,
            ProjectsApiClientAltEnum.PROTO
          ];
        }
  };

  export declare interface ProjectsDeleteAgentNamedParameters {
    access_token?: string;
    alt?: ProjectsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsApiClient$Xgafv;
  }

  export declare interface ProjectsGetAgentNamedParameters {
    access_token?: string;
    alt?: ProjectsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsApiClient$Xgafv;
  }

  export declare interface ProjectsGetSecuritySettingsNamedParameters {
    access_token?: string;
    alt?: ProjectsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsApiClient$Xgafv;
  }

  export declare interface ProjectsSetAgentNamedParameters {
    access_token?: string;
    alt?: ProjectsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsUpdateAgentNamedParameters {
    access_token?: string;
    alt?: ProjectsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsUpdateSecuritySettingsNamedParameters {
    access_token?: string;
    alt?: ProjectsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsApiClientImpl implements ProjectsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    deleteAgent(
        parent: string,
        namedParameters: ProjectsDeleteAgentNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.deleteAgent',
        path: `/${this.gapiVersion}/${parent}/agent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    getAgent(
        parent: string,
        namedParameters: ProjectsGetAgentNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Agent_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Agent_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.getAgent',
        path: `/${this.gapiVersion}/${parent}/agent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Agent_
      });
    }

    getSecuritySettings(
        name: string,
        namedParameters: ProjectsGetSecuritySettingsNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SecuritySettings_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/securitySettings$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SecuritySettings_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.getSecuritySettings',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SecuritySettings_
      });
    }

    setAgent(
        parent: string, $requestBody: Agent_,
        namedParameters: ProjectsSetAgentNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Agent_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<Agent_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.setAgent',
        path: `/${this.gapiVersion}/${parent}/agent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Agent_
      });
    }

    updateAgent(
        parent: string, $requestBody: Agent_,
        namedParameters: ProjectsUpdateAgentNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Agent_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<Agent_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.updateAgent',
        path: `/${this.gapiVersion}/${parent}/agent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Agent_
      });
    }

    updateSecuritySettings(
        name: string, $requestBody: SecuritySettings_,
        namedParameters: ProjectsUpdateSecuritySettingsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SecuritySettings_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/securitySettings$'));

      return this.$apiClient.$request<SecuritySettings_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.updateSecuritySettings',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SecuritySettings_
      });
    }
  }

  export abstract class ProjectsApiClient {
    constructor() {}

    abstract deleteAgent(
        parent: string,
        namedParameters?: ProjectsDeleteAgentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract getAgent(
        parent: string,
        namedParameters?: ProjectsGetAgentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Agent_>;

    abstract getSecuritySettings(
        name: string,
        namedParameters?: ProjectsGetSecuritySettingsNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SecuritySettings_>;

    abstract setAgent(
        parent: string, $requestBody: Agent_,
        namedParameters?: ProjectsSetAgentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Agent_>;

    abstract updateAgent(
        parent: string, $requestBody: Agent_,
        namedParameters?: ProjectsUpdateAgentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Agent_>;

    abstract updateSecuritySettings(
        name: string, $requestBody: SecuritySettings_,
        namedParameters?: ProjectsUpdateSecuritySettingsNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SecuritySettings_>;
  }

  export type ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$XgafvEnum {
    readonly 1:
        ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv;
    readonly 2:
        ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv;

    values(): Array<
        ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv>;
  }

  export const ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$XgafvEnum:
      IProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$XgafvEnum = {
        1: <ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv>'1',
        2: <ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv> {
              return [
                ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$XgafvEnum
                    [1],
                ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum {
    readonly JSON:
        ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;
    readonly MEDIA:
        ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;
    readonly PROTO:
        ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;

    values(): Array<
        ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt>;
  }

  export const ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum:
      IProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum = {
        JSON: <
            ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt>'json',
        MEDIA: <
            ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt>'media',
        PROTO: <
            ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt>'proto',
        values():
            Array<
                ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt> {
              return [
                ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum
                    .JSON,
                ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum
                    .MEDIA,
                ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsConversationDatasetsAnnotatedConversationDatasetsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationDatasetsAnnotatedConversationDatasetsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationDatasetsAnnotatedConversationDatasetsListNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class
      ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClientImpl
          implements
              ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    delete(
        name: string,
        namedParameters:
            ProjectsConversationDatasetsAnnotatedConversationDatasetsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/conversationDatasets/[^/]+/annotatedConversationDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.conversationDatasets.annotatedConversationDatasets.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsConversationDatasetsAnnotatedConversationDatasetsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AnnotatedConversationDataset_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/conversationDatasets/[^/]+/annotatedConversationDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<AnnotatedConversationDataset_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.conversationDatasets.annotatedConversationDatasets.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AnnotatedConversationDataset_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsConversationDatasetsAnnotatedConversationDatasetsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListAnnotatedConversationDatasetsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversationDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          ListAnnotatedConversationDatasetsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.conversationDatasets.annotatedConversationDatasets.list',
        path: `/${this.gapiVersion}/${parent}/annotatedConversationDatasets`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListAnnotatedConversationDatasetsResponse_
      });
    }
  }

  export abstract class
      ProjectsConversationDatasetsAnnotatedConversationDatasetsApiClient {
    constructor() {}

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsConversationDatasetsAnnotatedConversationDatasetsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsConversationDatasetsAnnotatedConversationDatasetsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<AnnotatedConversationDataset_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsConversationDatasetsAnnotatedConversationDatasetsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListAnnotatedConversationDatasetsResponse_>;
  }

  export type ProjectsConversationDatasetsApiClient$Xgafv = '1'|'2';

  export interface IProjectsConversationDatasetsApiClient$XgafvEnum {
    readonly 1: ProjectsConversationDatasetsApiClient$Xgafv;
    readonly 2: ProjectsConversationDatasetsApiClient$Xgafv;

    values(): Array<ProjectsConversationDatasetsApiClient$Xgafv>;
  }

  export const ProjectsConversationDatasetsApiClient$XgafvEnum:
      IProjectsConversationDatasetsApiClient$XgafvEnum = {
        1: <ProjectsConversationDatasetsApiClient$Xgafv>'1',
        2: <ProjectsConversationDatasetsApiClient$Xgafv>'2',
        values():
            Array<ProjectsConversationDatasetsApiClient$Xgafv> {
              return [
                ProjectsConversationDatasetsApiClient$XgafvEnum[1],
                ProjectsConversationDatasetsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsConversationDatasetsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsConversationDatasetsApiClientAltEnum {
    readonly JSON: ProjectsConversationDatasetsApiClientAlt;
    readonly MEDIA: ProjectsConversationDatasetsApiClientAlt;
    readonly PROTO: ProjectsConversationDatasetsApiClientAlt;

    values(): Array<ProjectsConversationDatasetsApiClientAlt>;
  }

  export const ProjectsConversationDatasetsApiClientAltEnum:
      IProjectsConversationDatasetsApiClientAltEnum = {
        JSON: <ProjectsConversationDatasetsApiClientAlt>'json',
        MEDIA: <ProjectsConversationDatasetsApiClientAlt>'media',
        PROTO: <ProjectsConversationDatasetsApiClientAlt>'proto',
        values():
            Array<ProjectsConversationDatasetsApiClientAlt> {
              return [
                ProjectsConversationDatasetsApiClientAltEnum.JSON,
                ProjectsConversationDatasetsApiClientAltEnum.MEDIA,
                ProjectsConversationDatasetsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsConversationDatasetsCreateLegacyConversationDatasetNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationDatasetsDeleteLegacyConversationDatasetNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationDatasetsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationDatasetsImportConversationDataNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationDatasetsLabelConversationNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationDatasetsListNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationDatasetsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsConversationDatasetsApiClientImpl implements
      ProjectsConversationDatasetsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    createLegacyConversationDataset(
        parent: string, $requestBody: ConversationDataset_,
        namedParameters:
            ProjectsConversationDatasetsCreateLegacyConversationDatasetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationDataset_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<ConversationDataset_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversationDatasets.createLegacyConversationDataset',
        path: `/${this.gapiVersion}/${
            parent}/conversationDatasets:createLegacyConversationDataset`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationDataset_
      });
    }

    deleteLegacyConversationDataset(
        name: string, $requestBody: DeleteLegacyConversationDatasetRequest_,
        namedParameters:
            ProjectsConversationDatasetsDeleteLegacyConversationDatasetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversationDatasets/[^/]+$'));

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversationDatasets.deleteLegacyConversationDataset',
        path: `/${this.gapiVersion}/${name}:deleteLegacyConversationDataset`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsConversationDatasetsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationDataset_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversationDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationDataset_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversationDatasets.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationDataset_
      });
    }

    importConversationData(
        name: string, $requestBody: ImportConversationDataRequest_,
        namedParameters:
            ProjectsConversationDatasetsImportConversationDataNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversationDatasets/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversationDatasets.importConversationData',
        path: `/${this.gapiVersion}/${name}:importConversationData`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    labelConversation(
        parent: string, $requestBody: LabelConversationRequest_,
        namedParameters:
            ProjectsConversationDatasetsLabelConversationNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversationDatasets/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversationDatasets.labelConversation',
        path: `/${this.gapiVersion}/${parent}:labelConversation`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsConversationDatasetsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationDatasetsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListConversationDatasetsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversationDatasets.list',
        path: `/${this.gapiVersion}/${parent}/conversationDatasets`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationDatasetsResponse_
      });
    }
  }

  export abstract class ProjectsConversationDatasetsApiClient {
    constructor() {}

    abstract createLegacyConversationDataset(
        parent: string, $requestBody: ConversationDataset_,
        namedParameters?:
            ProjectsConversationDatasetsCreateLegacyConversationDatasetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationDataset_>;

    abstract deleteLegacyConversationDataset(
        name: string, $requestBody: DeleteLegacyConversationDatasetRequest_,
        namedParameters?:
            ProjectsConversationDatasetsDeleteLegacyConversationDatasetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsConversationDatasetsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationDataset_>;

    abstract importConversationData(
        name: string, $requestBody: ImportConversationDataRequest_,
        namedParameters?:
            ProjectsConversationDatasetsImportConversationDataNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract labelConversation(
        parent: string, $requestBody: LabelConversationRequest_,
        namedParameters?:
            ProjectsConversationDatasetsLabelConversationNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsConversationDatasetsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationDatasetsResponse_>;
  }

  export type ProjectsConversationModelsApiClient$Xgafv = '1'|'2';

  export interface IProjectsConversationModelsApiClient$XgafvEnum {
    readonly 1: ProjectsConversationModelsApiClient$Xgafv;
    readonly 2: ProjectsConversationModelsApiClient$Xgafv;

    values(): Array<ProjectsConversationModelsApiClient$Xgafv>;
  }

  export const ProjectsConversationModelsApiClient$XgafvEnum:
      IProjectsConversationModelsApiClient$XgafvEnum = {
        1: <ProjectsConversationModelsApiClient$Xgafv>'1',
        2: <ProjectsConversationModelsApiClient$Xgafv>'2',
        values():
            Array<ProjectsConversationModelsApiClient$Xgafv> {
              return [
                ProjectsConversationModelsApiClient$XgafvEnum[1],
                ProjectsConversationModelsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsConversationModelsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsConversationModelsApiClientAltEnum {
    readonly JSON: ProjectsConversationModelsApiClientAlt;
    readonly MEDIA: ProjectsConversationModelsApiClientAlt;
    readonly PROTO: ProjectsConversationModelsApiClientAlt;

    values(): Array<ProjectsConversationModelsApiClientAlt>;
  }

  export const ProjectsConversationModelsApiClientAltEnum:
      IProjectsConversationModelsApiClientAltEnum = {
        JSON: <ProjectsConversationModelsApiClientAlt>'json',
        MEDIA: <ProjectsConversationModelsApiClientAlt>'media',
        PROTO: <ProjectsConversationModelsApiClientAlt>'proto',
        values():
            Array<ProjectsConversationModelsApiClientAlt> {
              return [
                ProjectsConversationModelsApiClientAltEnum.JSON,
                ProjectsConversationModelsApiClientAltEnum.MEDIA,
                ProjectsConversationModelsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsConversationModelsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationModelsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationModelsDeployNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationModelsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationModelsListNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationModelsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsConversationModelsUndeployNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationModelsApiClient$Xgafv;
  }

  export class ProjectsConversationModelsApiClientImpl implements
      ProjectsConversationModelsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: ConversationModel_,
        namedParameters: ProjectsConversationModelsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversationModels.create',
        path: `/${this.gapiVersion}/${parent}/conversationModels`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsConversationModelsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversationModels/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.conversationModels.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    deploy(
        name: string, $requestBody: DeployConversationModelRequest_,
        namedParameters: ProjectsConversationModelsDeployNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversationModels.deploy',
        path: `/${this.gapiVersion}/${name}:deploy`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters: ProjectsConversationModelsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationModel_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversationModels/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationModel_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversationModels.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationModel_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsConversationModelsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationModelsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListConversationModelsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversationModels.list',
        path: `/${this.gapiVersion}/${parent}/conversationModels`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationModelsResponse_
      });
    }

    undeploy(
        name: string, $requestBody: UndeployConversationModelRequest_,
        namedParameters: ProjectsConversationModelsUndeployNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversationModels.undeploy',
        path: `/${this.gapiVersion}/${name}:undeploy`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }
  }

  export abstract class ProjectsConversationModelsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: ConversationModel_,
        namedParameters?: ProjectsConversationModelsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsConversationModelsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract deploy(
        name: string, $requestBody: DeployConversationModelRequest_,
        namedParameters?: ProjectsConversationModelsDeployNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract get(
        name: string,
        namedParameters?: ProjectsConversationModelsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationModel_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsConversationModelsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationModelsResponse_>;

    abstract undeploy(
        name: string, $requestBody: UndeployConversationModelRequest_,
        namedParameters?: ProjectsConversationModelsUndeployNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;
  }

  export type ProjectsConversationModelsEvaluationsApiClient$Xgafv = '1'|'2';

  export interface IProjectsConversationModelsEvaluationsApiClient$XgafvEnum {
    readonly 1: ProjectsConversationModelsEvaluationsApiClient$Xgafv;
    readonly 2: ProjectsConversationModelsEvaluationsApiClient$Xgafv;

    values(): Array<ProjectsConversationModelsEvaluationsApiClient$Xgafv>;
  }

  export const ProjectsConversationModelsEvaluationsApiClient$XgafvEnum:
      IProjectsConversationModelsEvaluationsApiClient$XgafvEnum = {
        1: <ProjectsConversationModelsEvaluationsApiClient$Xgafv>'1',
        2: <ProjectsConversationModelsEvaluationsApiClient$Xgafv>'2',
        values():
            Array<ProjectsConversationModelsEvaluationsApiClient$Xgafv> {
              return [
                ProjectsConversationModelsEvaluationsApiClient$XgafvEnum[1],
                ProjectsConversationModelsEvaluationsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsConversationModelsEvaluationsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsConversationModelsEvaluationsApiClientAltEnum {
    readonly JSON: ProjectsConversationModelsEvaluationsApiClientAlt;
    readonly MEDIA: ProjectsConversationModelsEvaluationsApiClientAlt;
    readonly PROTO: ProjectsConversationModelsEvaluationsApiClientAlt;

    values(): Array<ProjectsConversationModelsEvaluationsApiClientAlt>;
  }

  export const ProjectsConversationModelsEvaluationsApiClientAltEnum:
      IProjectsConversationModelsEvaluationsApiClientAltEnum = {
        JSON: <ProjectsConversationModelsEvaluationsApiClientAlt>'json',
        MEDIA: <ProjectsConversationModelsEvaluationsApiClientAlt>'media',
        PROTO: <ProjectsConversationModelsEvaluationsApiClientAlt>'proto',
        values():
            Array<ProjectsConversationModelsEvaluationsApiClientAlt> {
              return [
                ProjectsConversationModelsEvaluationsApiClientAltEnum.JSON,
                ProjectsConversationModelsEvaluationsApiClientAltEnum.MEDIA,
                ProjectsConversationModelsEvaluationsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsConversationModelsEvaluationsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationModelsEvaluationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationModelsEvaluationsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationModelsEvaluationsListNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationModelsEvaluationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationModelsEvaluationsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsConversationModelsEvaluationsApiClientImpl implements
      ProjectsConversationModelsEvaluationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    get(name: string,
        namedParameters:
            ProjectsConversationModelsEvaluationsGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationModelEvaluation_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/conversationModels/[^/]+/evaluations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationModelEvaluation_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversationModels.evaluations.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationModelEvaluation_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsConversationModelsEvaluationsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationModelEvaluationsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversationModels/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<ListConversationModelEvaluationsResponse_>({
            body: $requestBody,
            httpMethod: 'GET',
            methodId: 'dialogflow.projects.conversationModels.evaluations.list',
            path: `/${this.gapiVersion}/${parent}/evaluations`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: ListConversationModelEvaluationsResponse_
          });
    }
  }

  export abstract class ProjectsConversationModelsEvaluationsApiClient {
    constructor() {}

    abstract get(
        name: string,
        namedParameters?:
            ProjectsConversationModelsEvaluationsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationModelEvaluation_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsConversationModelsEvaluationsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationModelEvaluationsResponse_>;
  }

  export type ProjectsConversationProfilesApiClient$Xgafv = '1'|'2';

  export interface IProjectsConversationProfilesApiClient$XgafvEnum {
    readonly 1: ProjectsConversationProfilesApiClient$Xgafv;
    readonly 2: ProjectsConversationProfilesApiClient$Xgafv;

    values(): Array<ProjectsConversationProfilesApiClient$Xgafv>;
  }

  export const ProjectsConversationProfilesApiClient$XgafvEnum:
      IProjectsConversationProfilesApiClient$XgafvEnum = {
        1: <ProjectsConversationProfilesApiClient$Xgafv>'1',
        2: <ProjectsConversationProfilesApiClient$Xgafv>'2',
        values():
            Array<ProjectsConversationProfilesApiClient$Xgafv> {
              return [
                ProjectsConversationProfilesApiClient$XgafvEnum[1],
                ProjectsConversationProfilesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsConversationProfilesApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsConversationProfilesApiClientAltEnum {
    readonly JSON: ProjectsConversationProfilesApiClientAlt;
    readonly MEDIA: ProjectsConversationProfilesApiClientAlt;
    readonly PROTO: ProjectsConversationProfilesApiClientAlt;

    values(): Array<ProjectsConversationProfilesApiClientAlt>;
  }

  export const ProjectsConversationProfilesApiClientAltEnum:
      IProjectsConversationProfilesApiClientAltEnum = {
        JSON: <ProjectsConversationProfilesApiClientAlt>'json',
        MEDIA: <ProjectsConversationProfilesApiClientAlt>'media',
        PROTO: <ProjectsConversationProfilesApiClientAlt>'proto',
        values():
            Array<ProjectsConversationProfilesApiClientAlt> {
              return [
                ProjectsConversationProfilesApiClientAltEnum.JSON,
                ProjectsConversationProfilesApiClientAltEnum.MEDIA,
                ProjectsConversationProfilesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsConversationProfilesClearSuggestionFeatureConfigNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationProfilesApiClient$Xgafv;
  }

  export declare interface ProjectsConversationProfilesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationProfilesApiClient$Xgafv;
  }

  export declare interface ProjectsConversationProfilesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationProfilesApiClient$Xgafv;
  }

  export declare interface ProjectsConversationProfilesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationProfilesApiClient$Xgafv;
  }

  export declare interface ProjectsConversationProfilesListNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationProfilesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsConversationProfilesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationProfilesApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsConversationProfilesSetSuggestionFeatureConfigNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationProfilesApiClient$Xgafv;
  }

  export class ProjectsConversationProfilesApiClientImpl implements
      ProjectsConversationProfilesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    clearSuggestionFeatureConfig(
        conversationProfile: string,
        $requestBody: ClearSuggestionFeatureConfigRequest_,
        namedParameters:
            ProjectsConversationProfilesClearSuggestionFeatureConfigNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          conversationProfile,
          new RegExp('^projects/[^/]+/conversationProfiles/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversationProfiles.clearSuggestionFeatureConfig',
        path: `/${this.gapiVersion}/${
            conversationProfile}:clearSuggestionFeatureConfig`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    create(
        parent: string, $requestBody: ConversationProfile_,
        namedParameters: ProjectsConversationProfilesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationProfile_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<ConversationProfile_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversationProfiles.create',
        path: `/${this.gapiVersion}/${parent}/conversationProfiles`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationProfile_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsConversationProfilesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversationProfiles/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.conversationProfiles.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsConversationProfilesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationProfile_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversationProfiles/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationProfile_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversationProfiles.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationProfile_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsConversationProfilesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationProfilesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListConversationProfilesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversationProfiles.list',
        path: `/${this.gapiVersion}/${parent}/conversationProfiles`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationProfilesResponse_
      });
    }

    patch(
        name: string, $requestBody: ConversationProfile_,
        namedParameters: ProjectsConversationProfilesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationProfile_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversationProfiles/[^/]+$'));

      return this.$apiClient.$request<ConversationProfile_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.conversationProfiles.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationProfile_
      });
    }

    setSuggestionFeatureConfig(
        conversationProfile: string,
        $requestBody: SetSuggestionFeatureConfigRequest_,
        namedParameters:
            ProjectsConversationProfilesSetSuggestionFeatureConfigNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          conversationProfile,
          new RegExp('^projects/[^/]+/conversationProfiles/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversationProfiles.setSuggestionFeatureConfig',
        path: `/${this.gapiVersion}/${
            conversationProfile}:setSuggestionFeatureConfig`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }
  }

  export abstract class ProjectsConversationProfilesApiClient {
    constructor() {}

    abstract clearSuggestionFeatureConfig(
        conversationProfile: string,
        $requestBody: ClearSuggestionFeatureConfigRequest_,
        namedParameters?:
            ProjectsConversationProfilesClearSuggestionFeatureConfigNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract create(
        parent: string, $requestBody: ConversationProfile_,
        namedParameters?: ProjectsConversationProfilesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationProfile_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsConversationProfilesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsConversationProfilesGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationProfile_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsConversationProfilesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationProfilesResponse_>;

    abstract patch(
        name: string, $requestBody: ConversationProfile_,
        namedParameters?: ProjectsConversationProfilesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationProfile_>;

    abstract setSuggestionFeatureConfig(
        conversationProfile: string,
        $requestBody: SetSuggestionFeatureConfigRequest_,
        namedParameters?:
            ProjectsConversationProfilesSetSuggestionFeatureConfigNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;
  }

  export type ProjectsConversationsApiClient$Xgafv = '1'|'2';

  export interface IProjectsConversationsApiClient$XgafvEnum {
    readonly 1: ProjectsConversationsApiClient$Xgafv;
    readonly 2: ProjectsConversationsApiClient$Xgafv;

    values(): Array<ProjectsConversationsApiClient$Xgafv>;
  }

  export const ProjectsConversationsApiClient$XgafvEnum:
      IProjectsConversationsApiClient$XgafvEnum = {
        1: <ProjectsConversationsApiClient$Xgafv>'1',
        2: <ProjectsConversationsApiClient$Xgafv>'2',
        values():
            Array<ProjectsConversationsApiClient$Xgafv> {
              return [
                ProjectsConversationsApiClient$XgafvEnum[1],
                ProjectsConversationsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsConversationsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsConversationsApiClientAltEnum {
    readonly JSON: ProjectsConversationsApiClientAlt;
    readonly MEDIA: ProjectsConversationsApiClientAlt;
    readonly PROTO: ProjectsConversationsApiClientAlt;

    values(): Array<ProjectsConversationsApiClientAlt>;
  }

  export const ProjectsConversationsApiClientAltEnum:
      IProjectsConversationsApiClientAltEnum = {
        JSON: <ProjectsConversationsApiClientAlt>'json',
        MEDIA: <ProjectsConversationsApiClientAlt>'media',
        PROTO: <ProjectsConversationsApiClientAlt>'proto',
        values():
            Array<ProjectsConversationsApiClientAlt> {
              return [
                ProjectsConversationsApiClientAltEnum.JSON,
                ProjectsConversationsApiClientAltEnum.MEDIA,
                ProjectsConversationsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsConversationsActivateConversationNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsAddConversationPhoneNumberNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsCompleteNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsApiClient$Xgafv;
    conversationId?: string;
  }

  export declare interface ProjectsConversationsDeactivateConversationNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsExportMessagesNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsApiClient$Xgafv;
    filter?: string;
    gcsDestinationUri?: string;
    internalExportAsProtoAddFeedbackLoopMetrics?: boolean;
    internalAddAgentAssistMetrics?: boolean;
    internalIncludeOpenConversations?: boolean;
  }

  export declare interface ProjectsConversationsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsListNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsConversationsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsConversationsApiClientImpl implements
      ProjectsConversationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    activateConversation(
        conversation: string, $requestBody: ActivateConversationRequest_,
        namedParameters:
            ProjectsConversationsActivateConversationNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          conversation, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversations.activateConversation',
        path: `/${this.gapiVersion}/${conversation}:activateConversation`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    addConversationPhoneNumber(
        name: string, $requestBody: AddConversationPhoneNumberRequest_,
        namedParameters:
            ProjectsConversationsAddConversationPhoneNumberNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationPhoneNumber_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<ConversationPhoneNumber_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.addConversationPhoneNumber',
        path: `/${this.gapiVersion}/${name}:addConversationPhoneNumber`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationPhoneNumber_
      });
    }

    complete(
        name: string, $requestBody: CompleteConversationRequest_,
        namedParameters: ProjectsConversationsCompleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversations.complete',
        path: `/${this.gapiVersion}/${name}:complete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    create(
        parent: string, $requestBody: Conversation_,
        namedParameters: ProjectsConversationsCreateNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversations.create',
        path: `/${this.gapiVersion}/${parent}/conversations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    deactivateConversation(
        conversation: string, $requestBody: DeactivateConversationRequest_,
        namedParameters:
            ProjectsConversationsDeactivateConversationNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          conversation, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversations.deactivateConversation',
        path: `/${this.gapiVersion}/${conversation}:deactivateConversation`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    exportMessages(
        parent: string,
        namedParameters: ProjectsConversationsExportMessagesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversations.exportMessages',
        path: `/${this.gapiVersion}/${parent}:exportMessages`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters: ProjectsConversationsGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversations.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsConversationsListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListConversationsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversations.list',
        path: `/${this.gapiVersion}/${parent}/conversations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationsResponse_
      });
    }

    patch(
        name: string, $requestBody: Conversation_,
        namedParameters: ProjectsConversationsPatchNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.conversations.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }
  }

  export abstract class ProjectsConversationsApiClient {
    constructor() {}

    abstract activateConversation(
        conversation: string, $requestBody: ActivateConversationRequest_,
        namedParameters?:
            ProjectsConversationsActivateConversationNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract addConversationPhoneNumber(
        name: string, $requestBody: AddConversationPhoneNumberRequest_,
        namedParameters?:
            ProjectsConversationsAddConversationPhoneNumberNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationPhoneNumber_>;

    abstract complete(
        name: string, $requestBody: CompleteConversationRequest_,
        namedParameters?: ProjectsConversationsCompleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract create(
        parent: string, $requestBody: Conversation_,
        namedParameters?: ProjectsConversationsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract deactivateConversation(
        conversation: string, $requestBody: DeactivateConversationRequest_,
        namedParameters?:
            ProjectsConversationsDeactivateConversationNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract exportMessages(
        parent: string,
        namedParameters?: ProjectsConversationsExportMessagesNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract get(
        name: string,
        namedParameters?: ProjectsConversationsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsConversationsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationsResponse_>;

    abstract patch(
        name: string, $requestBody: Conversation_,
        namedParameters?: ProjectsConversationsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;
  }

  export type ProjectsConversationsCallMatchersApiClient$Xgafv = '1'|'2';

  export interface IProjectsConversationsCallMatchersApiClient$XgafvEnum {
    readonly 1: ProjectsConversationsCallMatchersApiClient$Xgafv;
    readonly 2: ProjectsConversationsCallMatchersApiClient$Xgafv;

    values(): Array<ProjectsConversationsCallMatchersApiClient$Xgafv>;
  }

  export const ProjectsConversationsCallMatchersApiClient$XgafvEnum:
      IProjectsConversationsCallMatchersApiClient$XgafvEnum = {
        1: <ProjectsConversationsCallMatchersApiClient$Xgafv>'1',
        2: <ProjectsConversationsCallMatchersApiClient$Xgafv>'2',
        values():
            Array<ProjectsConversationsCallMatchersApiClient$Xgafv> {
              return [
                ProjectsConversationsCallMatchersApiClient$XgafvEnum[1],
                ProjectsConversationsCallMatchersApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsConversationsCallMatchersApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsConversationsCallMatchersApiClientAltEnum {
    readonly JSON: ProjectsConversationsCallMatchersApiClientAlt;
    readonly MEDIA: ProjectsConversationsCallMatchersApiClientAlt;
    readonly PROTO: ProjectsConversationsCallMatchersApiClientAlt;

    values(): Array<ProjectsConversationsCallMatchersApiClientAlt>;
  }

  export const ProjectsConversationsCallMatchersApiClientAltEnum:
      IProjectsConversationsCallMatchersApiClientAltEnum = {
        JSON: <ProjectsConversationsCallMatchersApiClientAlt>'json',
        MEDIA: <ProjectsConversationsCallMatchersApiClientAlt>'media',
        PROTO: <ProjectsConversationsCallMatchersApiClientAlt>'proto',
        values():
            Array<ProjectsConversationsCallMatchersApiClientAlt> {
              return [
                ProjectsConversationsCallMatchersApiClientAltEnum.JSON,
                ProjectsConversationsCallMatchersApiClientAltEnum.MEDIA,
                ProjectsConversationsCallMatchersApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsConversationsCallMatchersCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsCallMatchersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsCallMatchersApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsCallMatchersDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsCallMatchersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsCallMatchersApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsCallMatchersListNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsCallMatchersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsCallMatchersApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsConversationsCallMatchersApiClientImpl implements
      ProjectsConversationsCallMatchersApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: CallMatcher_,
        namedParameters: ProjectsConversationsCallMatchersCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<CallMatcher_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<CallMatcher_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversations.callMatchers.create',
        path: `/${this.gapiVersion}/${parent}/callMatchers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: CallMatcher_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsConversationsCallMatchersDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/callMatchers/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.conversations.callMatchers.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsConversationsCallMatchersListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListCallMatchersResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListCallMatchersResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversations.callMatchers.list',
        path: `/${this.gapiVersion}/${parent}/callMatchers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListCallMatchersResponse_
      });
    }
  }

  export abstract class ProjectsConversationsCallMatchersApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: CallMatcher_,
        namedParameters?:
            ProjectsConversationsCallMatchersCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<CallMatcher_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsConversationsCallMatchersDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsConversationsCallMatchersListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListCallMatchersResponse_>;
  }

  export type ProjectsConversationsMessagesApiClient$Xgafv = '1'|'2';

  export interface IProjectsConversationsMessagesApiClient$XgafvEnum {
    readonly 1: ProjectsConversationsMessagesApiClient$Xgafv;
    readonly 2: ProjectsConversationsMessagesApiClient$Xgafv;

    values(): Array<ProjectsConversationsMessagesApiClient$Xgafv>;
  }

  export const ProjectsConversationsMessagesApiClient$XgafvEnum:
      IProjectsConversationsMessagesApiClient$XgafvEnum = {
        1: <ProjectsConversationsMessagesApiClient$Xgafv>'1',
        2: <ProjectsConversationsMessagesApiClient$Xgafv>'2',
        values():
            Array<ProjectsConversationsMessagesApiClient$Xgafv> {
              return [
                ProjectsConversationsMessagesApiClient$XgafvEnum[1],
                ProjectsConversationsMessagesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsConversationsMessagesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsConversationsMessagesApiClientAltEnum {
    readonly JSON: ProjectsConversationsMessagesApiClientAlt;
    readonly MEDIA: ProjectsConversationsMessagesApiClientAlt;
    readonly PROTO: ProjectsConversationsMessagesApiClientAlt;

    values(): Array<ProjectsConversationsMessagesApiClientAlt>;
  }

  export const ProjectsConversationsMessagesApiClientAltEnum:
      IProjectsConversationsMessagesApiClientAltEnum = {
        JSON: <ProjectsConversationsMessagesApiClientAlt>'json',
        MEDIA: <ProjectsConversationsMessagesApiClientAlt>'media',
        PROTO: <ProjectsConversationsMessagesApiClientAlt>'proto',
        values():
            Array<ProjectsConversationsMessagesApiClientAlt> {
              return [
                ProjectsConversationsMessagesApiClientAltEnum.JSON,
                ProjectsConversationsMessagesApiClientAltEnum.MEDIA,
                ProjectsConversationsMessagesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsConversationsMessagesBatchCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsMessagesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsMessagesApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsMessagesListNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsMessagesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsMessagesApiClient$Xgafv;
    filter?: string;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsConversationsMessagesApiClientImpl implements
      ProjectsConversationsMessagesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchCreate(
        parent: string, $requestBody: BatchCreateMessagesRequest_,
        namedParameters:
            ProjectsConversationsMessagesBatchCreateNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<BatchCreateMessagesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<BatchCreateMessagesResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversations.messages.batchCreate',
        path: `/${this.gapiVersion}/${parent}/messages:batchCreate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: BatchCreateMessagesResponse_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsConversationsMessagesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListMessagesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListMessagesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversations.messages.list',
        path: `/${this.gapiVersion}/${parent}/messages`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListMessagesResponse_
      });
    }
  }

  export abstract class ProjectsConversationsMessagesApiClient {
    constructor() {}

    abstract batchCreate(
        parent: string, $requestBody: BatchCreateMessagesRequest_,
        namedParameters?:
            ProjectsConversationsMessagesBatchCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<BatchCreateMessagesResponse_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsConversationsMessagesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListMessagesResponse_>;
  }

  export type ProjectsConversationsParticipantsApiClient$Xgafv = '1'|'2';

  export interface IProjectsConversationsParticipantsApiClient$XgafvEnum {
    readonly 1: ProjectsConversationsParticipantsApiClient$Xgafv;
    readonly 2: ProjectsConversationsParticipantsApiClient$Xgafv;

    values(): Array<ProjectsConversationsParticipantsApiClient$Xgafv>;
  }

  export const ProjectsConversationsParticipantsApiClient$XgafvEnum:
      IProjectsConversationsParticipantsApiClient$XgafvEnum = {
        1: <ProjectsConversationsParticipantsApiClient$Xgafv>'1',
        2: <ProjectsConversationsParticipantsApiClient$Xgafv>'2',
        values():
            Array<ProjectsConversationsParticipantsApiClient$Xgafv> {
              return [
                ProjectsConversationsParticipantsApiClient$XgafvEnum[1],
                ProjectsConversationsParticipantsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsConversationsParticipantsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsConversationsParticipantsApiClientAltEnum {
    readonly JSON: ProjectsConversationsParticipantsApiClientAlt;
    readonly MEDIA: ProjectsConversationsParticipantsApiClientAlt;
    readonly PROTO: ProjectsConversationsParticipantsApiClientAlt;

    values(): Array<ProjectsConversationsParticipantsApiClientAlt>;
  }

  export const ProjectsConversationsParticipantsApiClientAltEnum:
      IProjectsConversationsParticipantsApiClientAltEnum = {
        JSON: <ProjectsConversationsParticipantsApiClientAlt>'json',
        MEDIA: <ProjectsConversationsParticipantsApiClientAlt>'media',
        PROTO: <ProjectsConversationsParticipantsApiClientAlt>'proto',
        values():
            Array<ProjectsConversationsParticipantsApiClientAlt> {
              return [
                ProjectsConversationsParticipantsApiClientAltEnum.JSON,
                ProjectsConversationsParticipantsApiClientAltEnum.MEDIA,
                ProjectsConversationsParticipantsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsConversationsParticipantsAnalyzeContentNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsBatchCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsListNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsConversationsParticipantsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsConversationsParticipantsApiClientImpl implements
      ProjectsConversationsParticipantsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    analyzeContent(
        participant: string, $requestBody: AnalyzeContentRequest_,
        namedParameters:
            ProjectsConversationsParticipantsAnalyzeContentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AnalyzeContentResponse_> {
      this.$apiClient.$validateParameter(
          participant,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<AnalyzeContentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.participants.analyzeContent',
        path: `/${this.gapiVersion}/${participant}:analyzeContent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AnalyzeContentResponse_
      });
    }

    batchCreate(
        parent: string, $requestBody: BatchCreateParticipantsRequest_,
        namedParameters:
            ProjectsConversationsParticipantsBatchCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<BatchCreateParticipantsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<BatchCreateParticipantsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversations.participants.batchCreate',
        path: `/${this.gapiVersion}/${parent}/participants:batchCreate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: BatchCreateParticipantsResponse_
      });
    }

    create(
        parent: string, $requestBody: Participant_,
        namedParameters: ProjectsConversationsParticipantsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Participant_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Participant_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.conversations.participants.create',
        path: `/${this.gapiVersion}/${parent}/participants`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Participant_
      });
    }

    get(name: string,
        namedParameters: ProjectsConversationsParticipantsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Participant_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Participant_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversations.participants.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Participant_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsConversationsParticipantsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListParticipantsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListParticipantsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.conversations.participants.list',
        path: `/${this.gapiVersion}/${parent}/participants`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListParticipantsResponse_
      });
    }

    patch(
        name: string, $requestBody: Participant_,
        namedParameters: ProjectsConversationsParticipantsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Participant_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<Participant_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.conversations.participants.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Participant_
      });
    }
  }

  export abstract class ProjectsConversationsParticipantsApiClient {
    constructor() {}

    abstract analyzeContent(
        participant: string, $requestBody: AnalyzeContentRequest_,
        namedParameters?:
            ProjectsConversationsParticipantsAnalyzeContentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<AnalyzeContentResponse_>;

    abstract batchCreate(
        parent: string, $requestBody: BatchCreateParticipantsRequest_,
        namedParameters?:
            ProjectsConversationsParticipantsBatchCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<BatchCreateParticipantsResponse_>;

    abstract create(
        parent: string, $requestBody: Participant_,
        namedParameters?:
            ProjectsConversationsParticipantsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Participant_>;

    abstract get(
        name: string,
        namedParameters?: ProjectsConversationsParticipantsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Participant_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsConversationsParticipantsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListParticipantsResponse_>;

    abstract patch(
        name: string, $requestBody: Participant_,
        namedParameters?: ProjectsConversationsParticipantsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Participant_>;
  }

  export type ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsConversationsParticipantsSuggestionsApiClient$XgafvEnum {
    readonly 1: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;
    readonly 2: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;

    values():
        Array<ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv>;
  }

  export const ProjectsConversationsParticipantsSuggestionsApiClient$XgafvEnum:
      IProjectsConversationsParticipantsSuggestionsApiClient$XgafvEnum = {
        1: <ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv>'1',
        2: <ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv>'2',
        values():
            Array<ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv> {
              return [
                ProjectsConversationsParticipantsSuggestionsApiClient$XgafvEnum
                    [1],
                ProjectsConversationsParticipantsSuggestionsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsConversationsParticipantsSuggestionsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsConversationsParticipantsSuggestionsApiClientAltEnum {
    readonly JSON: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    readonly MEDIA: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    readonly PROTO: ProjectsConversationsParticipantsSuggestionsApiClientAlt;

    values(): Array<ProjectsConversationsParticipantsSuggestionsApiClientAlt>;
  }

  export const ProjectsConversationsParticipantsSuggestionsApiClientAltEnum:
      IProjectsConversationsParticipantsSuggestionsApiClientAltEnum = {
        JSON: <ProjectsConversationsParticipantsSuggestionsApiClientAlt>'json',
        MEDIA:
            <ProjectsConversationsParticipantsSuggestionsApiClientAlt>'media',
        PROTO:
            <ProjectsConversationsParticipantsSuggestionsApiClientAlt>'proto',
        values():
            Array<ProjectsConversationsParticipantsSuggestionsApiClientAlt> {
              return [
                ProjectsConversationsParticipantsSuggestionsApiClientAltEnum
                    .JSON,
                ProjectsConversationsParticipantsSuggestionsApiClientAltEnum
                    .MEDIA,
                ProjectsConversationsParticipantsSuggestionsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsConversationsParticipantsSuggestionsCompileNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsSuggestionsListNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsConversationsParticipantsSuggestionsSuggestArticlesNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsSuggestionsSuggestConversationGuidancesNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsSuggestionsSuggestDialogflowAssistsNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsSuggestionsSuggestFaqAnswersNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsSuggestionsSuggestSmartComposeAnswersNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsSuggestionsSuggestSmartRepliesNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsParticipantsSuggestionsSuggestSpellGrammarCorrectionAnswersNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export class ProjectsConversationsParticipantsSuggestionsApiClientImpl
      implements ProjectsConversationsParticipantsSuggestionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    compile(
        parent: string, $requestBody: CompileSuggestionRequest_,
        namedParameters:
            ProjectsConversationsParticipantsSuggestionsCompileNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<CompileSuggestionResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<CompileSuggestionResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.participants.suggestions.compile',
        path: `/${this.gapiVersion}/${parent}/suggestions:compile`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: CompileSuggestionResponse_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsConversationsParticipantsSuggestionsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListSuggestionsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListSuggestionsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.conversations.participants.suggestions.list',
        path: `/${this.gapiVersion}/${parent}/suggestions`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListSuggestionsResponse_
      });
    }

    suggestArticles(
        parent: string, $requestBody: SuggestArticlesRequest_,
        namedParameters:
            ProjectsConversationsParticipantsSuggestionsSuggestArticlesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestArticlesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestArticlesResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.participants.suggestions.suggestArticles',
        path: `/${this.gapiVersion}/${parent}/suggestions:suggestArticles`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestArticlesResponse_
      });
    }

    suggestConversationGuidances(
        parent: string, $requestBody: SuggestConversationGuidancesRequest_,
        namedParameters:
            ProjectsConversationsParticipantsSuggestionsSuggestConversationGuidancesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestConversationGuidancesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestConversationGuidancesResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.participants.suggestions.suggestConversationGuidances',
        path: `/${this.gapiVersion}/${
            parent}/suggestions:suggestConversationGuidances`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestConversationGuidancesResponse_
      });
    }

    suggestDialogflowAssists(
        parent: string, $requestBody: SuggestDialogflowAssistsRequest_,
        namedParameters:
            ProjectsConversationsParticipantsSuggestionsSuggestDialogflowAssistsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestDialogflowAssistsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestDialogflowAssistsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.participants.suggestions.suggestDialogflowAssists',
        path: `/${this.gapiVersion}/${
            parent}/suggestions:suggestDialogflowAssists`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestDialogflowAssistsResponse_
      });
    }

    suggestFaqAnswers(
        parent: string, $requestBody: SuggestFaqAnswersRequest_,
        namedParameters:
            ProjectsConversationsParticipantsSuggestionsSuggestFaqAnswersNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestFaqAnswersResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestFaqAnswersResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.participants.suggestions.suggestFaqAnswers',
        path: `/${this.gapiVersion}/${parent}/suggestions:suggestFaqAnswers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestFaqAnswersResponse_
      });
    }

    suggestSmartComposeAnswers(
        parent: string, $requestBody: SuggestSmartComposeAnswersRequest_,
        namedParameters:
            ProjectsConversationsParticipantsSuggestionsSuggestSmartComposeAnswersNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestSmartComposeAnswersResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestSmartComposeAnswersResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.participants.suggestions.suggestSmartComposeAnswers',
        path: `/${this.gapiVersion}/${
            parent}/suggestions:suggestSmartComposeAnswers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestSmartComposeAnswersResponse_
      });
    }

    suggestSmartReplies(
        parent: string, $requestBody: SuggestSmartRepliesRequest_,
        namedParameters:
            ProjectsConversationsParticipantsSuggestionsSuggestSmartRepliesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestSmartRepliesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestSmartRepliesResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.participants.suggestions.suggestSmartReplies',
        path: `/${this.gapiVersion}/${parent}/suggestions:suggestSmartReplies`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestSmartRepliesResponse_
      });
    }

    suggestSpellGrammarCorrectionAnswers(
        parent: string,
        $requestBody: SuggestSpellGrammarCorrectionAnswersRequest_,
        namedParameters:
            ProjectsConversationsParticipantsSuggestionsSuggestSpellGrammarCorrectionAnswersNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestSpellGrammarCorrectionAnswersResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<
          SuggestSpellGrammarCorrectionAnswersResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.participants.suggestions.suggestSpellGrammarCorrectionAnswers',
        path: `/${this.gapiVersion}/${
            parent}/suggestions:suggestSpellGrammarCorrectionAnswers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestSpellGrammarCorrectionAnswersResponse_
      });
    }
  }

  export abstract class ProjectsConversationsParticipantsSuggestionsApiClient {
    constructor() {}

    abstract compile(
        parent: string, $requestBody: CompileSuggestionRequest_,
        namedParameters?:
            ProjectsConversationsParticipantsSuggestionsCompileNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<CompileSuggestionResponse_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsConversationsParticipantsSuggestionsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListSuggestionsResponse_>;

    abstract suggestArticles(
        parent: string, $requestBody: SuggestArticlesRequest_,
        namedParameters?:
            ProjectsConversationsParticipantsSuggestionsSuggestArticlesNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestArticlesResponse_>;

    abstract suggestConversationGuidances(
        parent: string, $requestBody: SuggestConversationGuidancesRequest_,
        namedParameters?:
            ProjectsConversationsParticipantsSuggestionsSuggestConversationGuidancesNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestConversationGuidancesResponse_>;

    abstract suggestDialogflowAssists(
        parent: string, $requestBody: SuggestDialogflowAssistsRequest_,
        namedParameters?:
            ProjectsConversationsParticipantsSuggestionsSuggestDialogflowAssistsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestDialogflowAssistsResponse_>;

    abstract suggestFaqAnswers(
        parent: string, $requestBody: SuggestFaqAnswersRequest_,
        namedParameters?:
            ProjectsConversationsParticipantsSuggestionsSuggestFaqAnswersNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestFaqAnswersResponse_>;

    abstract suggestSmartComposeAnswers(
        parent: string, $requestBody: SuggestSmartComposeAnswersRequest_,
        namedParameters?:
            ProjectsConversationsParticipantsSuggestionsSuggestSmartComposeAnswersNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestSmartComposeAnswersResponse_>;

    abstract suggestSmartReplies(
        parent: string, $requestBody: SuggestSmartRepliesRequest_,
        namedParameters?:
            ProjectsConversationsParticipantsSuggestionsSuggestSmartRepliesNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestSmartRepliesResponse_>;

    abstract suggestSpellGrammarCorrectionAnswers(
        parent: string,
        $requestBody: SuggestSpellGrammarCorrectionAnswersRequest_,
        namedParameters?:
            ProjectsConversationsParticipantsSuggestionsSuggestSpellGrammarCorrectionAnswersNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestSpellGrammarCorrectionAnswersResponse_>;
  }

  export type ProjectsConversationsSuggestionsApiClient$Xgafv = '1'|'2';

  export interface IProjectsConversationsSuggestionsApiClient$XgafvEnum {
    readonly 1: ProjectsConversationsSuggestionsApiClient$Xgafv;
    readonly 2: ProjectsConversationsSuggestionsApiClient$Xgafv;

    values(): Array<ProjectsConversationsSuggestionsApiClient$Xgafv>;
  }

  export const ProjectsConversationsSuggestionsApiClient$XgafvEnum:
      IProjectsConversationsSuggestionsApiClient$XgafvEnum = {
        1: <ProjectsConversationsSuggestionsApiClient$Xgafv>'1',
        2: <ProjectsConversationsSuggestionsApiClient$Xgafv>'2',
        values():
            Array<ProjectsConversationsSuggestionsApiClient$Xgafv> {
              return [
                ProjectsConversationsSuggestionsApiClient$XgafvEnum[1],
                ProjectsConversationsSuggestionsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsConversationsSuggestionsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsConversationsSuggestionsApiClientAltEnum {
    readonly JSON: ProjectsConversationsSuggestionsApiClientAlt;
    readonly MEDIA: ProjectsConversationsSuggestionsApiClientAlt;
    readonly PROTO: ProjectsConversationsSuggestionsApiClientAlt;

    values(): Array<ProjectsConversationsSuggestionsApiClientAlt>;
  }

  export const ProjectsConversationsSuggestionsApiClientAltEnum:
      IProjectsConversationsSuggestionsApiClientAltEnum = {
        JSON: <ProjectsConversationsSuggestionsApiClientAlt>'json',
        MEDIA: <ProjectsConversationsSuggestionsApiClientAlt>'media',
        PROTO: <ProjectsConversationsSuggestionsApiClientAlt>'proto',
        values():
            Array<ProjectsConversationsSuggestionsApiClientAlt> {
              return [
                ProjectsConversationsSuggestionsApiClientAltEnum.JSON,
                ProjectsConversationsSuggestionsApiClientAltEnum.MEDIA,
                ProjectsConversationsSuggestionsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsConversationsSuggestionsSuggestConversationKeyMomentsNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsConversationsSuggestionsSuggestConversationSummaryNamedParameters {
    access_token?: string;
    alt?: ProjectsConversationsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsConversationsSuggestionsApiClient$Xgafv;
  }

  export class ProjectsConversationsSuggestionsApiClientImpl implements
      ProjectsConversationsSuggestionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    suggestConversationKeyMoments(
        conversation: string,
        $requestBody: SuggestConversationKeyMomentsRequest_,
        namedParameters:
            ProjectsConversationsSuggestionsSuggestConversationKeyMomentsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestConversationKeyMomentsResponse_> {
      this.$apiClient.$validateParameter(
          conversation, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<SuggestConversationKeyMomentsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.suggestions.suggestConversationKeyMoments',
        path: `/${this.gapiVersion}/${
            conversation}/suggestions:suggestConversationKeyMoments`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestConversationKeyMomentsResponse_
      });
    }

    suggestConversationSummary(
        conversation: string, $requestBody: SuggestConversationSummaryRequest_,
        namedParameters:
            ProjectsConversationsSuggestionsSuggestConversationSummaryNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestConversationSummaryResponse_> {
      this.$apiClient.$validateParameter(
          conversation, new RegExp('^projects/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<SuggestConversationSummaryResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.conversations.suggestions.suggestConversationSummary',
        path: `/${this.gapiVersion}/${
            conversation}/suggestions:suggestConversationSummary`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestConversationSummaryResponse_
      });
    }
  }

  export abstract class ProjectsConversationsSuggestionsApiClient {
    constructor() {}

    abstract suggestConversationKeyMoments(
        conversation: string,
        $requestBody: SuggestConversationKeyMomentsRequest_,
        namedParameters?:
            ProjectsConversationsSuggestionsSuggestConversationKeyMomentsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestConversationKeyMomentsResponse_>;

    abstract suggestConversationSummary(
        conversation: string, $requestBody: SuggestConversationSummaryRequest_,
        namedParameters?:
            ProjectsConversationsSuggestionsSuggestConversationSummaryNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestConversationSummaryResponse_>;
  }

  export type ProjectsEnvironmentsUsersConversationsApiClient$Xgafv = '1'|'2';

  export interface IProjectsEnvironmentsUsersConversationsApiClient$XgafvEnum {
    readonly 1: ProjectsEnvironmentsUsersConversationsApiClient$Xgafv;
    readonly 2: ProjectsEnvironmentsUsersConversationsApiClient$Xgafv;

    values(): Array<ProjectsEnvironmentsUsersConversationsApiClient$Xgafv>;
  }

  export const ProjectsEnvironmentsUsersConversationsApiClient$XgafvEnum:
      IProjectsEnvironmentsUsersConversationsApiClient$XgafvEnum = {
        1: <ProjectsEnvironmentsUsersConversationsApiClient$Xgafv>'1',
        2: <ProjectsEnvironmentsUsersConversationsApiClient$Xgafv>'2',
        values():
            Array<ProjectsEnvironmentsUsersConversationsApiClient$Xgafv> {
              return [
                ProjectsEnvironmentsUsersConversationsApiClient$XgafvEnum[1],
                ProjectsEnvironmentsUsersConversationsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsEnvironmentsUsersConversationsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsEnvironmentsUsersConversationsApiClientAltEnum {
    readonly JSON: ProjectsEnvironmentsUsersConversationsApiClientAlt;
    readonly MEDIA: ProjectsEnvironmentsUsersConversationsApiClientAlt;
    readonly PROTO: ProjectsEnvironmentsUsersConversationsApiClientAlt;

    values(): Array<ProjectsEnvironmentsUsersConversationsApiClientAlt>;
  }

  export const ProjectsEnvironmentsUsersConversationsApiClientAltEnum:
      IProjectsEnvironmentsUsersConversationsApiClientAltEnum = {
        JSON: <ProjectsEnvironmentsUsersConversationsApiClientAlt>'json',
        MEDIA: <ProjectsEnvironmentsUsersConversationsApiClientAlt>'media',
        PROTO: <ProjectsEnvironmentsUsersConversationsApiClientAlt>'proto',
        values():
            Array<ProjectsEnvironmentsUsersConversationsApiClientAlt> {
              return [
                ProjectsEnvironmentsUsersConversationsApiClientAltEnum.JSON,
                ProjectsEnvironmentsUsersConversationsApiClientAltEnum.MEDIA,
                ProjectsEnvironmentsUsersConversationsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsEnvironmentsUsersConversationsDeleteContextsNamedParameters {
    access_token?: string;
    alt?: ProjectsEnvironmentsUsersConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsEnvironmentsUsersConversationsApiClient$Xgafv;
  }

  export class ProjectsEnvironmentsUsersConversationsApiClientImpl implements
      ProjectsEnvironmentsUsersConversationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    deleteContexts(
        parent: string,
        namedParameters:
            ProjectsEnvironmentsUsersConversationsDeleteContextsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/environments/[^/]+/users/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.environments.users.conversations.deleteContexts',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }
  }

  export abstract class ProjectsEnvironmentsUsersConversationsApiClient {
    constructor() {}

    abstract deleteContexts(
        parent: string,
        namedParameters?:
            ProjectsEnvironmentsUsersConversationsDeleteContextsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;
  }

  export type ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsEnvironmentsUsersConversationsContextsApiClient$XgafvEnum {
    readonly 1: ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv;
    readonly 2: ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv;

    values():
        Array<ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv>;
  }

  export const ProjectsEnvironmentsUsersConversationsContextsApiClient$XgafvEnum:
      IProjectsEnvironmentsUsersConversationsContextsApiClient$XgafvEnum = {
        1: <ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv>'1',
        2: <ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv> {
              return [
                ProjectsEnvironmentsUsersConversationsContextsApiClient$XgafvEnum
                    [1],
                ProjectsEnvironmentsUsersConversationsContextsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsEnvironmentsUsersConversationsContextsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsEnvironmentsUsersConversationsContextsApiClientAltEnum {
    readonly JSON: ProjectsEnvironmentsUsersConversationsContextsApiClientAlt;
    readonly MEDIA: ProjectsEnvironmentsUsersConversationsContextsApiClientAlt;
    readonly PROTO: ProjectsEnvironmentsUsersConversationsContextsApiClientAlt;

    values(): Array<ProjectsEnvironmentsUsersConversationsContextsApiClientAlt>;
  }

  export const ProjectsEnvironmentsUsersConversationsContextsApiClientAltEnum:
      IProjectsEnvironmentsUsersConversationsContextsApiClientAltEnum = {
        JSON:
            <ProjectsEnvironmentsUsersConversationsContextsApiClientAlt>'json',
        MEDIA:
            <ProjectsEnvironmentsUsersConversationsContextsApiClientAlt>'media',
        PROTO:
            <ProjectsEnvironmentsUsersConversationsContextsApiClientAlt>'proto',
        values():
            Array<ProjectsEnvironmentsUsersConversationsContextsApiClientAlt> {
              return [
                ProjectsEnvironmentsUsersConversationsContextsApiClientAltEnum
                    .JSON,
                ProjectsEnvironmentsUsersConversationsContextsApiClientAltEnum
                    .MEDIA,
                ProjectsEnvironmentsUsersConversationsContextsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsEnvironmentsUsersConversationsContextsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsEnvironmentsUsersConversationsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsEnvironmentsUsersConversationsContextsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsEnvironmentsUsersConversationsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsEnvironmentsUsersConversationsContextsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsEnvironmentsUsersConversationsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsEnvironmentsUsersConversationsContextsListNamedParameters {
    access_token?: string;
    alt?: ProjectsEnvironmentsUsersConversationsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsEnvironmentsUsersConversationsContextsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsEnvironmentsUsersConversationsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsEnvironmentsUsersConversationsContextsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsEnvironmentsUsersConversationsContextsApiClientImpl
      implements ProjectsEnvironmentsUsersConversationsContextsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Context_,
        namedParameters:
            ProjectsEnvironmentsUsersConversationsContextsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/environments/[^/]+/users/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.environments.users.conversations.contexts.create',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsEnvironmentsUsersConversationsContextsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/environments/[^/]+/users/[^/]+/conversations/[^/]+/contexts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.environments.users.conversations.contexts.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsEnvironmentsUsersConversationsContextsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/environments/[^/]+/users/[^/]+/conversations/[^/]+/contexts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.environments.users.conversations.contexts.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsEnvironmentsUsersConversationsContextsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListContextsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/environments/[^/]+/users/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListContextsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.environments.users.conversations.contexts.list',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListContextsResponse_
      });
    }

    patch(
        name: string, $requestBody: Context_,
        namedParameters:
            ProjectsEnvironmentsUsersConversationsContextsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/environments/[^/]+/users/[^/]+/conversations/[^/]+/contexts/[^/]+$'));

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.environments.users.conversations.contexts.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }
  }

  export abstract class
      ProjectsEnvironmentsUsersConversationsContextsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Context_,
        namedParameters?:
            ProjectsEnvironmentsUsersConversationsContextsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsEnvironmentsUsersConversationsContextsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsEnvironmentsUsersConversationsContextsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsEnvironmentsUsersConversationsContextsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListContextsResponse_>;

    abstract patch(
        name: string, $requestBody: Context_,
        namedParameters?:
            ProjectsEnvironmentsUsersConversationsContextsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;
  }

  export type ProjectsHumanAgentAssistantsApiClient$Xgafv = '1'|'2';

  export interface IProjectsHumanAgentAssistantsApiClient$XgafvEnum {
    readonly 1: ProjectsHumanAgentAssistantsApiClient$Xgafv;
    readonly 2: ProjectsHumanAgentAssistantsApiClient$Xgafv;

    values(): Array<ProjectsHumanAgentAssistantsApiClient$Xgafv>;
  }

  export const ProjectsHumanAgentAssistantsApiClient$XgafvEnum:
      IProjectsHumanAgentAssistantsApiClient$XgafvEnum = {
        1: <ProjectsHumanAgentAssistantsApiClient$Xgafv>'1',
        2: <ProjectsHumanAgentAssistantsApiClient$Xgafv>'2',
        values():
            Array<ProjectsHumanAgentAssistantsApiClient$Xgafv> {
              return [
                ProjectsHumanAgentAssistantsApiClient$XgafvEnum[1],
                ProjectsHumanAgentAssistantsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsHumanAgentAssistantsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsHumanAgentAssistantsApiClientAltEnum {
    readonly JSON: ProjectsHumanAgentAssistantsApiClientAlt;
    readonly MEDIA: ProjectsHumanAgentAssistantsApiClientAlt;
    readonly PROTO: ProjectsHumanAgentAssistantsApiClientAlt;

    values(): Array<ProjectsHumanAgentAssistantsApiClientAlt>;
  }

  export const ProjectsHumanAgentAssistantsApiClientAltEnum:
      IProjectsHumanAgentAssistantsApiClientAltEnum = {
        JSON: <ProjectsHumanAgentAssistantsApiClientAlt>'json',
        MEDIA: <ProjectsHumanAgentAssistantsApiClientAlt>'media',
        PROTO: <ProjectsHumanAgentAssistantsApiClientAlt>'proto',
        values():
            Array<ProjectsHumanAgentAssistantsApiClientAlt> {
              return [
                ProjectsHumanAgentAssistantsApiClientAltEnum.JSON,
                ProjectsHumanAgentAssistantsApiClientAltEnum.MEDIA,
                ProjectsHumanAgentAssistantsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsHumanAgentAssistantsCompileSuggestionsNamedParameters {
    access_token?: string;
    alt?: ProjectsHumanAgentAssistantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsHumanAgentAssistantsApiClient$Xgafv;
  }

  export declare interface ProjectsHumanAgentAssistantsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsHumanAgentAssistantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsHumanAgentAssistantsApiClient$Xgafv;
    bypassDeprecation?: boolean;
  }

  export declare interface ProjectsHumanAgentAssistantsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsHumanAgentAssistantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsHumanAgentAssistantsApiClient$Xgafv;
  }

  export declare interface ProjectsHumanAgentAssistantsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsHumanAgentAssistantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsHumanAgentAssistantsApiClient$Xgafv;
  }

  export declare interface ProjectsHumanAgentAssistantsListNamedParameters {
    access_token?: string;
    alt?: ProjectsHumanAgentAssistantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsHumanAgentAssistantsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsHumanAgentAssistantsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsHumanAgentAssistantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsHumanAgentAssistantsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsHumanAgentAssistantsApiClientImpl implements
      ProjectsHumanAgentAssistantsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    compileSuggestions(
        name: string, $requestBody: CompileSuggestionsRequest_,
        namedParameters:
            ProjectsHumanAgentAssistantsCompileSuggestionsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<CompileSuggestionsResponse_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/humanAgentAssistants/[^/]+$'));

      return this.$apiClient.$request<CompileSuggestionsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.humanAgentAssistants.compileSuggestions',
        path: `/${this.gapiVersion}/${name}:compileSuggestions`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: CompileSuggestionsResponse_
      });
    }

    create(
        parent: string, $requestBody: HumanAgentAssistant_,
        namedParameters: ProjectsHumanAgentAssistantsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<HumanAgentAssistant_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<HumanAgentAssistant_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.humanAgentAssistants.create',
        path: `/${this.gapiVersion}/${parent}/humanAgentAssistants`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: HumanAgentAssistant_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsHumanAgentAssistantsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/humanAgentAssistants/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.humanAgentAssistants.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsHumanAgentAssistantsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<HumanAgentAssistant_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/humanAgentAssistants/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<HumanAgentAssistant_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.humanAgentAssistants.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: HumanAgentAssistant_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsHumanAgentAssistantsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListHumanAgentAssistantsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListHumanAgentAssistantsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.humanAgentAssistants.list',
        path: `/${this.gapiVersion}/${parent}/humanAgentAssistants`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListHumanAgentAssistantsResponse_
      });
    }

    patch(
        name: string, $requestBody: HumanAgentAssistant_,
        namedParameters: ProjectsHumanAgentAssistantsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<HumanAgentAssistant_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/humanAgentAssistants/[^/]+$'));

      return this.$apiClient.$request<HumanAgentAssistant_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.humanAgentAssistants.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: HumanAgentAssistant_
      });
    }
  }

  export abstract class ProjectsHumanAgentAssistantsApiClient {
    constructor() {}

    abstract compileSuggestions(
        name: string, $requestBody: CompileSuggestionsRequest_,
        namedParameters?:
            ProjectsHumanAgentAssistantsCompileSuggestionsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<CompileSuggestionsResponse_>;

    abstract create(
        parent: string, $requestBody: HumanAgentAssistant_,
        namedParameters?: ProjectsHumanAgentAssistantsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<HumanAgentAssistant_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsHumanAgentAssistantsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsHumanAgentAssistantsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<HumanAgentAssistant_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsHumanAgentAssistantsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListHumanAgentAssistantsResponse_>;

    abstract patch(
        name: string, $requestBody: HumanAgentAssistant_,
        namedParameters?: ProjectsHumanAgentAssistantsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<HumanAgentAssistant_>;
  }

  export type ProjectsKnowledgeBasesApiClient$Xgafv = '1'|'2';

  export interface IProjectsKnowledgeBasesApiClient$XgafvEnum {
    readonly 1: ProjectsKnowledgeBasesApiClient$Xgafv;
    readonly 2: ProjectsKnowledgeBasesApiClient$Xgafv;

    values(): Array<ProjectsKnowledgeBasesApiClient$Xgafv>;
  }

  export const ProjectsKnowledgeBasesApiClient$XgafvEnum:
      IProjectsKnowledgeBasesApiClient$XgafvEnum = {
        1: <ProjectsKnowledgeBasesApiClient$Xgafv>'1',
        2: <ProjectsKnowledgeBasesApiClient$Xgafv>'2',
        values():
            Array<ProjectsKnowledgeBasesApiClient$Xgafv> {
              return [
                ProjectsKnowledgeBasesApiClient$XgafvEnum[1],
                ProjectsKnowledgeBasesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsKnowledgeBasesApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsKnowledgeBasesApiClientAltEnum {
    readonly JSON: ProjectsKnowledgeBasesApiClientAlt;
    readonly MEDIA: ProjectsKnowledgeBasesApiClientAlt;
    readonly PROTO: ProjectsKnowledgeBasesApiClientAlt;

    values(): Array<ProjectsKnowledgeBasesApiClientAlt>;
  }

  export const ProjectsKnowledgeBasesApiClientAltEnum:
      IProjectsKnowledgeBasesApiClientAltEnum = {
        JSON: <ProjectsKnowledgeBasesApiClientAlt>'json',
        MEDIA: <ProjectsKnowledgeBasesApiClientAlt>'media',
        PROTO: <ProjectsKnowledgeBasesApiClientAlt>'proto',
        values():
            Array<ProjectsKnowledgeBasesApiClientAlt> {
              return [
                ProjectsKnowledgeBasesApiClientAltEnum.JSON,
                ProjectsKnowledgeBasesApiClientAltEnum.MEDIA,
                ProjectsKnowledgeBasesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsKnowledgeBasesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesApiClient$Xgafv;
    force?: boolean;
  }

  export declare interface ProjectsKnowledgeBasesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesListNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsKnowledgeBasesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsKnowledgeBasesApiClientImpl implements
      ProjectsKnowledgeBasesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: KnowledgeBase_,
        namedParameters: ProjectsKnowledgeBasesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<KnowledgeBase_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<KnowledgeBase_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.knowledgeBases.create',
        path: `/${this.gapiVersion}/${parent}/knowledgeBases`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: KnowledgeBase_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsKnowledgeBasesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/knowledgeBases/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.knowledgeBases.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsKnowledgeBasesGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<KnowledgeBase_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/knowledgeBases/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<KnowledgeBase_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.knowledgeBases.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: KnowledgeBase_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsKnowledgeBasesListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListKnowledgeBasesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListKnowledgeBasesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.knowledgeBases.list',
        path: `/${this.gapiVersion}/${parent}/knowledgeBases`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListKnowledgeBasesResponse_
      });
    }

    patch(
        name: string, $requestBody: KnowledgeBase_,
        namedParameters: ProjectsKnowledgeBasesPatchNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<KnowledgeBase_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/knowledgeBases/[^/]+$'));

      return this.$apiClient.$request<KnowledgeBase_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.knowledgeBases.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: KnowledgeBase_
      });
    }
  }

  export abstract class ProjectsKnowledgeBasesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: KnowledgeBase_,
        namedParameters?: ProjectsKnowledgeBasesCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<KnowledgeBase_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsKnowledgeBasesDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsKnowledgeBasesGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<KnowledgeBase_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsKnowledgeBasesListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListKnowledgeBasesResponse_>;

    abstract patch(
        name: string, $requestBody: KnowledgeBase_,
        namedParameters?: ProjectsKnowledgeBasesPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<KnowledgeBase_>;
  }

  export type ProjectsKnowledgeBasesDocumentsApiClient$Xgafv = '1'|'2';

  export interface IProjectsKnowledgeBasesDocumentsApiClient$XgafvEnum {
    readonly 1: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;
    readonly 2: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;

    values(): Array<ProjectsKnowledgeBasesDocumentsApiClient$Xgafv>;
  }

  export const ProjectsKnowledgeBasesDocumentsApiClient$XgafvEnum:
      IProjectsKnowledgeBasesDocumentsApiClient$XgafvEnum = {
        1: <ProjectsKnowledgeBasesDocumentsApiClient$Xgafv>'1',
        2: <ProjectsKnowledgeBasesDocumentsApiClient$Xgafv>'2',
        values():
            Array<ProjectsKnowledgeBasesDocumentsApiClient$Xgafv> {
              return [
                ProjectsKnowledgeBasesDocumentsApiClient$XgafvEnum[1],
                ProjectsKnowledgeBasesDocumentsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsKnowledgeBasesDocumentsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsKnowledgeBasesDocumentsApiClientAltEnum {
    readonly JSON: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    readonly MEDIA: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    readonly PROTO: ProjectsKnowledgeBasesDocumentsApiClientAlt;

    values(): Array<ProjectsKnowledgeBasesDocumentsApiClientAlt>;
  }

  export const ProjectsKnowledgeBasesDocumentsApiClientAltEnum:
      IProjectsKnowledgeBasesDocumentsApiClientAltEnum = {
        JSON: <ProjectsKnowledgeBasesDocumentsApiClientAlt>'json',
        MEDIA: <ProjectsKnowledgeBasesDocumentsApiClientAlt>'media',
        PROTO: <ProjectsKnowledgeBasesDocumentsApiClientAlt>'proto',
        values():
            Array<ProjectsKnowledgeBasesDocumentsApiClientAlt> {
              return [
                ProjectsKnowledgeBasesDocumentsApiClientAltEnum.JSON,
                ProjectsKnowledgeBasesDocumentsApiClientAltEnum.MEDIA,
                ProjectsKnowledgeBasesDocumentsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsKnowledgeBasesDocumentsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;
    importGcsCustomMetadata?: boolean;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsExportNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsGenerateNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsImportNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsListNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsReloadNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export class ProjectsKnowledgeBasesDocumentsApiClientImpl implements
      ProjectsKnowledgeBasesDocumentsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Document_,
        namedParameters: ProjectsKnowledgeBasesDocumentsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/knowledgeBases/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.knowledgeBases.documents.create',
        path: `/${this.gapiVersion}/${parent}/documents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsKnowledgeBasesDocumentsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.knowledgeBases.documents.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    export(
        name: string, $requestBody: ExportDocumentRequest_,
        namedParameters: ProjectsKnowledgeBasesDocumentsExportNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.knowledgeBases.documents.export',
        path: `/${this.gapiVersion}/${name}:export`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    generate(
        parent: string, $requestBody: GenerateDocumentRequest_,
        namedParameters: ProjectsKnowledgeBasesDocumentsGenerateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/knowledgeBases/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.knowledgeBases.documents.generate',
        path: `/${this.gapiVersion}/${parent}/documents:generate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters: ProjectsKnowledgeBasesDocumentsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Document_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Document_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.knowledgeBases.documents.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Document_
      });
    }

  import(
      parent: string,
      $requestBody: ImportDocumentsRequest_,
      namedParameters: ProjectsKnowledgeBasesDocumentsImportNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/knowledgeBases/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.knowledgeBases.documents.import',
      path: `/${this.gapiVersion}/${parent}/documents:import`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  list(
      parent: string,
      namedParameters: ProjectsKnowledgeBasesDocumentsListNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<ListDocumentsResponse_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/knowledgeBases/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ListDocumentsResponse_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.knowledgeBases.documents.list',
      path: `/${this.gapiVersion}/${parent}/documents`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ListDocumentsResponse_
    });
  }

  patch(
      name: string,
      $requestBody: Document_,
      namedParameters: ProjectsKnowledgeBasesDocumentsPatchNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'PATCH',
      methodId: 'dialogflow.projects.knowledgeBases.documents.patch',
      path: `/${this.gapiVersion}/${name}`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  reload(
      name: string,
      $requestBody: ReloadDocumentRequest_,
      namedParameters: ProjectsKnowledgeBasesDocumentsReloadNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.knowledgeBases.documents.reload',
      path: `/${this.gapiVersion}/${name}:reload`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }
}

export abstract class ProjectsKnowledgeBasesDocumentsApiClient {
  constructor() {
  }

  abstract create(
      parent: string,
      $requestBody: Document_,
      namedParameters?: ProjectsKnowledgeBasesDocumentsCreateNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract delete(
      name: string,
      namedParameters?: ProjectsKnowledgeBasesDocumentsDeleteNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract export(
      name: string,
      $requestBody: ExportDocumentRequest_,
      namedParameters?: ProjectsKnowledgeBasesDocumentsExportNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract generate(
      parent: string,
      $requestBody: GenerateDocumentRequest_,
      namedParameters?: ProjectsKnowledgeBasesDocumentsGenerateNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract get(
      name: string,
      namedParameters?: ProjectsKnowledgeBasesDocumentsGetNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<Document_>;

  abstract import(
      parent: string,
      $requestBody: ImportDocumentsRequest_,
      namedParameters?: ProjectsKnowledgeBasesDocumentsImportNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract list(
      parent: string,
      namedParameters?: ProjectsKnowledgeBasesDocumentsListNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<ListDocumentsResponse_>;

  abstract patch(
      name: string,
      $requestBody: Document_,
      namedParameters?: ProjectsKnowledgeBasesDocumentsPatchNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract reload(
      name: string,
      $requestBody: ReloadDocumentRequest_,
      namedParameters?: ProjectsKnowledgeBasesDocumentsReloadNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;
}

export type ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv = '1' | '2';

  export interface IProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$XgafvEnum {
    readonly 1:
        ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv;
    readonly 2:
        ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv;

    values(): Array<
        ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv>;
  }

  export const ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$XgafvEnum:
      IProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$XgafvEnum = {
        1: <ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv>'1',
        2: <ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv> {
              return [
                ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$XgafvEnum
                    [1],
                ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum {
    readonly JSON:
        ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt;
    readonly MEDIA:
        ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt;
    readonly PROTO:
        ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt;

    values():
        Array<ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt>;
  }

  export const ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum:
      IProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum = {
        JSON: <
            ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt>'json',
        MEDIA: <
            ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt>'media',
        PROTO: <
            ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt>'proto',
        values():
            Array<
                ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt> {
              return [
                ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum
                    .JSON,
                ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum
                    .MEDIA,
                ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsKnowledgeBasesDocumentsIssueModelingEntriesListNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export class ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClientImpl
      implements ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsIssueModelingEntriesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListIssueModelingEntriesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListIssueModelingEntriesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.issueModelingEntries.list',
        path: `/${this.gapiVersion}/${parent}/issueModelingEntries`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListIssueModelingEntriesResponse_
      });
    }
  }

  export abstract class
      ProjectsKnowledgeBasesDocumentsIssueModelingEntriesApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsIssueModelingEntriesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListIssueModelingEntriesResponse_>;
  }

  export type ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum {
    readonly 1:
        ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
    readonly 2:
        ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;

    values(): Array<
        ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv>;
  }

  export const ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum:
      IProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum = {
        1: <ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv>'1',
        2: <ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv> {
              return [
                ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum
                    [1],
                ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum {
    readonly JSON:
        ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    readonly MEDIA:
        ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    readonly PROTO:
        ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;

    values():
        Array<ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>;
  }

  export const ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum:
      IProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum = {
        JSON: <
            ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>'json',
        MEDIA: <
            ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>'media',
        PROTO: <
            ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>'proto',
        values():
            Array<
                ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt> {
              return [
                ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum
                    .JSON,
                ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum
                    .MEDIA,
                ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesListNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientImpl
      implements ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    get(name: string,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<QuestionAnswerEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/questionAnswerEntries/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<QuestionAnswerEntry_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.questionAnswerEntries.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: QuestionAnswerEntry_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListQuestionAnswerEntriesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListQuestionAnswerEntriesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.questionAnswerEntries.list',
        path: `/${this.gapiVersion}/${parent}/questionAnswerEntries`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListQuestionAnswerEntriesResponse_
      });
    }

    patch(
        name: string, $requestBody: QuestionAnswerEntry_,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<QuestionAnswerEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/questionAnswerEntries/[^/]+$'));

      return this.$apiClient.$request<QuestionAnswerEntry_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.questionAnswerEntries.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: QuestionAnswerEntry_
      });
    }
  }

  export abstract class
      ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient {
    constructor() {}

    abstract get(
        name: string,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<QuestionAnswerEntry_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListQuestionAnswerEntriesResponse_>;

    abstract patch(
        name: string, $requestBody: QuestionAnswerEntry_,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsQuestionAnswerEntriesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<QuestionAnswerEntry_>;
  }

  export type ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$XgafvEnum {
    readonly 1:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
    readonly 2:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;

    values(): Array<
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv>;
  }

  export const ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$XgafvEnum:
      IProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$XgafvEnum = {
        1: <ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv>'1',
        2: <ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv> {
              return [
                ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$XgafvEnum
                    [1],
                ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum {
    readonly JSON:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    readonly MEDIA:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    readonly PROTO:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;

    values():
        Array<ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt>;
  }

  export const ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum:
      IProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum = {
        JSON: <
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt>'json',
        MEDIA: <
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt>'media',
        PROTO: <
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt>'proto',
        values():
            Array<
                ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt> {
              return [
                ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum
                    .JSON,
                ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum
                    .MEDIA,
                ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesAutoApproveNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesBatchDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesBatchUpdateNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesClusterNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesDisableNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesEnableNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesListNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    listOffset?: number;
    filter?: string;
  }

  export declare interface ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientImpl
      implements ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    autoApprove(
        parent: string, $requestBody: AutoApproveSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesAutoApproveNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.autoApprove',
        path:
            `/${this.gapiVersion}/${parent}/smartMessagingEntries:autoApprove`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchDelete(
        parent: string, $requestBody: BatchDeleteSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesBatchDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.batchDelete',
        path:
            `/${this.gapiVersion}/${parent}/smartMessagingEntries:batchDelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchUpdate(
        parent: string, $requestBody: BatchUpdateSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesBatchUpdateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.batchUpdate',
        path:
            `/${this.gapiVersion}/${parent}/smartMessagingEntries:batchUpdate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    cluster(
        parent: string, $requestBody: ClusterSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesClusterNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.cluster',
        path: `/${this.gapiVersion}/${parent}/smartMessagingEntries:cluster`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    create(
        parent: string, $requestBody: SmartMessagingEntry_,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SmartMessagingEntry_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<SmartMessagingEntry_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.create',
        path: `/${this.gapiVersion}/${parent}/smartMessagingEntries`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SmartMessagingEntry_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/smartMessagingEntries/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    disable(
        parent: string, $requestBody: DisableSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesDisableNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.disable',
        path: `/${this.gapiVersion}/${parent}/smartMessagingEntries:disable`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    enable(
        parent: string, $requestBody: EnableSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesEnableNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.enable',
        path: `/${this.gapiVersion}/${parent}/smartMessagingEntries:enable`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SmartMessagingEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/smartMessagingEntries/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SmartMessagingEntry_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SmartMessagingEntry_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListSmartMessagingEntriesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListSmartMessagingEntriesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.list',
        path: `/${this.gapiVersion}/${parent}/smartMessagingEntries`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListSmartMessagingEntriesResponse_
      });
    }

    patch(
        name: string, $requestBody: SmartMessagingEntry_,
        namedParameters:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SmartMessagingEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/smartMessagingEntries/[^/]+$'));

      return this.$apiClient.$request<SmartMessagingEntry_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.knowledgeBases.documents.smartMessagingEntries.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SmartMessagingEntry_
      });
    }
  }

  export abstract class
      ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient {
    constructor() {}

    abstract autoApprove(
        parent: string, $requestBody: AutoApproveSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesAutoApproveNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchDelete(
        parent: string, $requestBody: BatchDeleteSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesBatchDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchUpdate(
        parent: string, $requestBody: BatchUpdateSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesBatchUpdateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract cluster(
        parent: string, $requestBody: ClusterSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesClusterNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract create(
        parent: string, $requestBody: SmartMessagingEntry_,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SmartMessagingEntry_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract disable(
        parent: string, $requestBody: DisableSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesDisableNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract enable(
        parent: string, $requestBody: EnableSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesEnableNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SmartMessagingEntry_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListSmartMessagingEntriesResponse_>;

    abstract patch(
        name: string, $requestBody: SmartMessagingEntry_,
        namedParameters?:
            ProjectsKnowledgeBasesDocumentsSmartMessagingEntriesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SmartMessagingEntry_>;
  }

  export type ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$XgafvEnum:
      IProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv> {
              return [
                ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$XgafvEnum
                    [1],
                ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt;
    readonly PROTO:
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt;

    values(): Array<
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt>;
  }

  export const ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAltEnum:
      IProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAltEnum = {
        JSON: <
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt> {
              return [
                ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAltEnum
                    .JSON,
                ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient$Xgafv;
    updateMask?: string;
  }

  export class
      ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClientImpl
          implements
              ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AamUtterance,
        namedParameters:
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamUtterance> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+/aamTranscripts/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1AamUtterance>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.aamDatasets.aamTranscripts.aamUtterances.create',
        path: `/${this.gapiVersion}/${parent}/aamUtterances`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleCloudDialogflowAamV2beta1AamUtterance
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+/aamTranscripts/[^/]+/aamUtterances/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.aamDatasets.aamTranscripts.aamUtterances.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamUtterance> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+/aamTranscripts/[^/]+/aamUtterances/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1AamUtterance>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.aamDatasets.aamTranscripts.aamUtterances.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleCloudDialogflowAamV2beta1AamUtterance
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamUtterancesResponse> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+/aamTranscripts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ListAamUtterancesResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.aamDatasets.aamTranscripts.aamUtterances.list',
        path: `/${this.gapiVersion}/${parent}/aamUtterances`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleCloudDialogflowAamV2beta1ListAamUtterancesResponse
      });
    }

    patch(
        name: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamUtterance,
        namedParameters:
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamUtterance> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+/aamTranscripts/[^/]+/aamUtterances/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1AamUtterance>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.locations.aamDatasets.aamTranscripts.aamUtterances.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleCloudDialogflowAamV2beta1AamUtterance
      });
    }
  }

  export abstract class
      ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesApiClient {
    constructor() {}

    abstract create(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AamUtterance,
        namedParameters?:
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamUtterance>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamUtterance>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamUtterancesResponse>;

    abstract patch(
        name: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamUtterance,
        namedParameters?:
            ProjectsLocationsAamDatasetsAamTranscriptsAamUtterancesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamUtterance>;
  }

  export type ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsAamDatasetsAamTranscriptsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamDatasetsAamTranscriptsApiClient$XgafvEnum:
      IProjectsLocationsAamDatasetsAamTranscriptsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv> {
              return [
                ProjectsLocationsAamDatasetsAamTranscriptsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsAamDatasetsAamTranscriptsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAamDatasetsAamTranscriptsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt;
    readonly PROTO: ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt;

    values(): Array<ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt>;
  }

  export const ProjectsLocationsAamDatasetsAamTranscriptsApiClientAltEnum:
      IProjectsLocationsAamDatasetsAamTranscriptsApiClientAltEnum = {
        JSON: <ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt> {
              return [
                ProjectsLocationsAamDatasetsAamTranscriptsApiClientAltEnum.JSON,
                ProjectsLocationsAamDatasetsAamTranscriptsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsAamDatasetsAamTranscriptsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAamDatasetsAamTranscriptsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDatasetsAamTranscriptsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDatasetsAamTranscriptsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDatasetsAamTranscriptsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAamDatasetsAamTranscriptsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsAamTranscriptsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsAamTranscriptsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsLocationsAamDatasetsAamTranscriptsApiClientImpl
      implements ProjectsLocationsAamDatasetsAamTranscriptsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AamTranscript,
        namedParameters:
            ProjectsLocationsAamDatasetsAamTranscriptsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamTranscript> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1AamTranscript>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.aamDatasets.aamTranscripts.create',
        path: `/${this.gapiVersion}/${parent}/aamTranscripts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleCloudDialogflowAamV2beta1AamTranscript
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsAamDatasetsAamTranscriptsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+/aamTranscripts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.aamDatasets.aamTranscripts.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsAamDatasetsAamTranscriptsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamTranscript> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+/aamTranscripts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1AamTranscript>({
            body: $requestBody,
            httpMethod: 'GET',
            methodId:
                'dialogflow.projects.locations.aamDatasets.aamTranscripts.get',
            path: `/${this.gapiVersion}/${name}`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1AamTranscript
          });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsAamDatasetsAamTranscriptsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamTranscriptsResponse> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1ListAamTranscriptsResponse>({
            body: $requestBody,
            httpMethod: 'GET',
            methodId:
                'dialogflow.projects.locations.aamDatasets.aamTranscripts.list',
            path: `/${this.gapiVersion}/${parent}/aamTranscripts`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor:
                GoogleCloudDialogflowAamV2beta1ListAamTranscriptsResponse
          });
    }

    patch(
        name: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AamTranscript,
        namedParameters:
            ProjectsLocationsAamDatasetsAamTranscriptsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamTranscript> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+/aamTranscripts/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1AamTranscript>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.locations.aamDatasets.aamTranscripts.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleCloudDialogflowAamV2beta1AamTranscript
      });
    }
  }

  export abstract class ProjectsLocationsAamDatasetsAamTranscriptsApiClient {
    constructor() {}

    abstract create(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AamTranscript,
        namedParameters?:
            ProjectsLocationsAamDatasetsAamTranscriptsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamTranscript>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAamDatasetsAamTranscriptsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsAamDatasetsAamTranscriptsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamTranscript>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsAamDatasetsAamTranscriptsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamTranscriptsResponse>;

    abstract patch(
        name: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AamTranscript,
        namedParameters?:
            ProjectsLocationsAamDatasetsAamTranscriptsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamTranscript>;
  }

  export type ProjectsLocationsAamDatasetsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAamDatasetsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAamDatasetsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAamDatasetsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAamDatasetsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamDatasetsApiClient$XgafvEnum:
      IProjectsLocationsAamDatasetsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamDatasetsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamDatasetsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAamDatasetsApiClient$Xgafv> {
              return [
                ProjectsLocationsAamDatasetsApiClient$XgafvEnum[1],
                ProjectsLocationsAamDatasetsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAamDatasetsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsAamDatasetsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAamDatasetsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAamDatasetsApiClientAlt;
    readonly PROTO: ProjectsLocationsAamDatasetsApiClientAlt;

    values(): Array<ProjectsLocationsAamDatasetsApiClientAlt>;
  }

  export const ProjectsLocationsAamDatasetsApiClientAltEnum:
      IProjectsLocationsAamDatasetsApiClientAltEnum = {
        JSON: <ProjectsLocationsAamDatasetsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAamDatasetsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAamDatasetsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAamDatasetsApiClientAlt> {
              return [
                ProjectsLocationsAamDatasetsApiClientAltEnum.JSON,
                ProjectsLocationsAamDatasetsApiClientAltEnum.MEDIA,
                ProjectsLocationsAamDatasetsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsAamDatasetsApiClientResourceTypes =
      'SEARCH_RESOURCE_TYPE_UNSPECIFIED'|'SEARCH_RESOURCE_TYPE_DISPLAY_NAME'|
      'SEARCH_RESOURCE_TYPE_DESCRIPTION'|
      'SEARCH_RESOURCE_TYPE_CANONICAL_UTTERANCE'|'SEARCH_RESOURCE_TYPE_LABEL'|
      'SEARCH_RESOURCE_TYPE_TRANSCRIPT';

  export interface IProjectsLocationsAamDatasetsApiClientResourceTypesEnum {
    readonly SEARCH_RESOURCE_TYPE_UNSPECIFIED:
        ProjectsLocationsAamDatasetsApiClientResourceTypes;
    readonly SEARCH_RESOURCE_TYPE_DISPLAY_NAME:
        ProjectsLocationsAamDatasetsApiClientResourceTypes;
    readonly SEARCH_RESOURCE_TYPE_DESCRIPTION:
        ProjectsLocationsAamDatasetsApiClientResourceTypes;
    readonly SEARCH_RESOURCE_TYPE_CANONICAL_UTTERANCE:
        ProjectsLocationsAamDatasetsApiClientResourceTypes;
    readonly SEARCH_RESOURCE_TYPE_LABEL:
        ProjectsLocationsAamDatasetsApiClientResourceTypes;
    readonly SEARCH_RESOURCE_TYPE_TRANSCRIPT:
        ProjectsLocationsAamDatasetsApiClientResourceTypes;

    values(): Array<ProjectsLocationsAamDatasetsApiClientResourceTypes>;
  }

  export const ProjectsLocationsAamDatasetsApiClientResourceTypesEnum:
      IProjectsLocationsAamDatasetsApiClientResourceTypesEnum = {
        SEARCH_RESOURCE_TYPE_CANONICAL_UTTERANCE: <
            ProjectsLocationsAamDatasetsApiClientResourceTypes>'SEARCH_RESOURCE_TYPE_CANONICAL_UTTERANCE',
        SEARCH_RESOURCE_TYPE_DESCRIPTION: <
            ProjectsLocationsAamDatasetsApiClientResourceTypes>'SEARCH_RESOURCE_TYPE_DESCRIPTION',
        SEARCH_RESOURCE_TYPE_DISPLAY_NAME: <
            ProjectsLocationsAamDatasetsApiClientResourceTypes>'SEARCH_RESOURCE_TYPE_DISPLAY_NAME',
        SEARCH_RESOURCE_TYPE_LABEL: <
            ProjectsLocationsAamDatasetsApiClientResourceTypes>'SEARCH_RESOURCE_TYPE_LABEL',
        SEARCH_RESOURCE_TYPE_TRANSCRIPT: <
            ProjectsLocationsAamDatasetsApiClientResourceTypes>'SEARCH_RESOURCE_TYPE_TRANSCRIPT',
        SEARCH_RESOURCE_TYPE_UNSPECIFIED: <
            ProjectsLocationsAamDatasetsApiClientResourceTypes>'SEARCH_RESOURCE_TYPE_UNSPECIFIED',
        values():
            Array<ProjectsLocationsAamDatasetsApiClientResourceTypes> {
              return [
                ProjectsLocationsAamDatasetsApiClientResourceTypesEnum
                    .SEARCH_RESOURCE_TYPE_UNSPECIFIED,
                ProjectsLocationsAamDatasetsApiClientResourceTypesEnum
                    .SEARCH_RESOURCE_TYPE_DISPLAY_NAME,
                ProjectsLocationsAamDatasetsApiClientResourceTypesEnum
                    .SEARCH_RESOURCE_TYPE_DESCRIPTION,
                ProjectsLocationsAamDatasetsApiClientResourceTypesEnum
                    .SEARCH_RESOURCE_TYPE_CANONICAL_UTTERANCE,
                ProjectsLocationsAamDatasetsApiClientResourceTypesEnum
                    .SEARCH_RESOURCE_TYPE_LABEL,
                ProjectsLocationsAamDatasetsApiClientResourceTypesEnum
                    .SEARCH_RESOURCE_TYPE_TRANSCRIPT
              ];
            }
      };

  export declare interface ProjectsLocationsAamDatasetsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDatasetsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDatasetsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDatasetsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAamDatasetsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsLocationsAamDatasetsSearchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDatasetsApiClient$Xgafv;
    resourceTypes?: Array<ProjectsLocationsAamDatasetsApiClientResourceTypes>;
    query?: string;
    filterParentDiscriminant?: string;
  }

  export class ProjectsLocationsAamDatasetsApiClientImpl implements
      ProjectsLocationsAamDatasetsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamDataset,
        namedParameters: ProjectsLocationsAamDatasetsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamDatasets.create',
        path: `/${this.gapiVersion}/${parent}/aamDatasets`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsLocationsAamDatasetsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.aamDatasets.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsAamDatasetsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamDataset> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1AamDataset>({
            body: $requestBody,
            httpMethod: 'GET',
            methodId: 'dialogflow.projects.locations.aamDatasets.get',
            path: `/${this.gapiVersion}/${name}`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1AamDataset
          });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAamDatasetsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamDatasetsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1ListAamDatasetsResponse>({
            body: $requestBody,
            httpMethod: 'GET',
            methodId: 'dialogflow.projects.locations.aamDatasets.list',
            path: `/${this.gapiVersion}/${parent}/aamDatasets`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1ListAamDatasetsResponse
          });
    }

    patch(
        name: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamDataset,
        namedParameters: ProjectsLocationsAamDatasetsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamDataset> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1AamDataset>({
            body: $requestBody,
            httpMethod: 'PATCH',
            methodId: 'dialogflow.projects.locations.aamDatasets.patch',
            path: `/${this.gapiVersion}/${name}`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1AamDataset
          });
    }

    search(
        name: string,
        namedParameters: ProjectsLocationsAamDatasetsSearchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponse> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/aamDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponse>({
            body: $requestBody,
            httpMethod: 'GET',
            methodId: 'dialogflow.projects.locations.aamDatasets.search',
            path: `/${this.gapiVersion}/${name}:search`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor:
                GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponse
          });
    }
  }

  export abstract class ProjectsLocationsAamDatasetsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamDataset,
        namedParameters?: ProjectsLocationsAamDatasetsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsLocationsAamDatasetsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsAamDatasetsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamDataset>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAamDatasetsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamDatasetsResponse>;

    abstract patch(
        name: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamDataset,
        namedParameters?: ProjectsLocationsAamDatasetsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamDataset>;

    abstract search(
        name: string,
        namedParameters?: ProjectsLocationsAamDatasetsSearchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1SearchAamDatasetResponse>;
  }

  export type ProjectsLocationsAamDiscriminantChangesApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAamDiscriminantChangesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAamDiscriminantChangesApiClient$Xgafv;
    readonly 2: ProjectsLocationsAamDiscriminantChangesApiClient$Xgafv;

    values(): Array<ProjectsLocationsAamDiscriminantChangesApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamDiscriminantChangesApiClient$XgafvEnum:
      IProjectsLocationsAamDiscriminantChangesApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamDiscriminantChangesApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamDiscriminantChangesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAamDiscriminantChangesApiClient$Xgafv> {
              return [
                ProjectsLocationsAamDiscriminantChangesApiClient$XgafvEnum[1],
                ProjectsLocationsAamDiscriminantChangesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAamDiscriminantChangesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAamDiscriminantChangesApiClientAltEnum {
    readonly JSON: ProjectsLocationsAamDiscriminantChangesApiClientAlt;
    readonly MEDIA: ProjectsLocationsAamDiscriminantChangesApiClientAlt;
    readonly PROTO: ProjectsLocationsAamDiscriminantChangesApiClientAlt;

    values(): Array<ProjectsLocationsAamDiscriminantChangesApiClientAlt>;
  }

  export const ProjectsLocationsAamDiscriminantChangesApiClientAltEnum:
      IProjectsLocationsAamDiscriminantChangesApiClientAltEnum = {
        JSON: <ProjectsLocationsAamDiscriminantChangesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAamDiscriminantChangesApiClientAlt>'media',
        PROTO: <ProjectsLocationsAamDiscriminantChangesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAamDiscriminantChangesApiClientAlt> {
              return [
                ProjectsLocationsAamDiscriminantChangesApiClientAltEnum.JSON,
                ProjectsLocationsAamDiscriminantChangesApiClientAltEnum.MEDIA,
                ProjectsLocationsAamDiscriminantChangesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAamDiscriminantChangesApproveNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantChangesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantChangesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDiscriminantChangesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantChangesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantChangesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    parentDiscriminantId?: string;
  }

  export declare interface ProjectsLocationsAamDiscriminantChangesRejectNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantChangesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantChangesApiClient$Xgafv;
  }

  export class ProjectsLocationsAamDiscriminantChangesApiClientImpl implements
      ProjectsLocationsAamDiscriminantChangesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    approve(
        name: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeRequest,
        namedParameters:
            ProjectsLocationsAamDiscriminantChangesApproveNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeResponse> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDiscriminantChanges/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.aamDiscriminantChanges.approve',
        path: `/${this.gapiVersion}/${name}:approve`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeResponse
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsAamDiscriminantChangesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1ListAamDiscriminantChangesResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ListAamDiscriminantChangesResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.aamDiscriminantChanges.list',
        path: `/${this.gapiVersion}/${parent}/aamDiscriminantChanges`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1ListAamDiscriminantChangesResponse
      });
    }

    reject(
        name: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeRequest,
        namedParameters:
            ProjectsLocationsAamDiscriminantChangesRejectNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeResponse> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDiscriminantChanges/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamDiscriminantChanges.reject',
        path: `/${this.gapiVersion}/${name}:reject`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeResponse
      });
    }
  }

  export abstract class ProjectsLocationsAamDiscriminantChangesApiClient {
    constructor() {}

    abstract approve(
        name: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeRequest,
        namedParameters?:
            ProjectsLocationsAamDiscriminantChangesApproveNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1ApproveAamDiscriminantChangeResponse>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsAamDiscriminantChangesListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1ListAamDiscriminantChangesResponse>;

    abstract reject(
        name: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeRequest,
        namedParameters?:
            ProjectsLocationsAamDiscriminantChangesRejectNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1RejectAamDiscriminantChangeResponse>;
  }

  export type ProjectsLocationsAamDiscriminantsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAamDiscriminantsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAamDiscriminantsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamDiscriminantsApiClient$XgafvEnum:
      IProjectsLocationsAamDiscriminantsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamDiscriminantsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamDiscriminantsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAamDiscriminantsApiClient$Xgafv> {
              return [
                ProjectsLocationsAamDiscriminantsApiClient$XgafvEnum[1],
                ProjectsLocationsAamDiscriminantsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAamDiscriminantsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAamDiscriminantsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAamDiscriminantsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAamDiscriminantsApiClientAlt;
    readonly PROTO: ProjectsLocationsAamDiscriminantsApiClientAlt;

    values(): Array<ProjectsLocationsAamDiscriminantsApiClientAlt>;
  }

  export const ProjectsLocationsAamDiscriminantsApiClientAltEnum:
      IProjectsLocationsAamDiscriminantsApiClientAltEnum = {
        JSON: <ProjectsLocationsAamDiscriminantsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAamDiscriminantsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAamDiscriminantsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAamDiscriminantsApiClientAlt> {
              return [
                ProjectsLocationsAamDiscriminantsApiClientAltEnum.JSON,
                ProjectsLocationsAamDiscriminantsApiClientAltEnum.MEDIA,
                ProjectsLocationsAamDiscriminantsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsAamDiscriminantsApiClientType =
      'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
      'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|
      'PARAMETER'|'INTENT_RESOLUTION';

  export interface IProjectsLocationsAamDiscriminantsApiClientTypeEnum {
    readonly DISCRIMINANT_TYPE_UNSPECIFIED:
        ProjectsLocationsAamDiscriminantsApiClientType;
    readonly DRIVER: ProjectsLocationsAamDiscriminantsApiClientType;
    readonly INTENT: ProjectsLocationsAamDiscriminantsApiClientType;
    readonly CONTEXTUAL_INTENT: ProjectsLocationsAamDiscriminantsApiClientType;
    readonly AGENT_QUESTION: ProjectsLocationsAamDiscriminantsApiClientType;
    readonly AGENT_ANSWER: ProjectsLocationsAamDiscriminantsApiClientType;
    readonly AGENT_ACTION: ProjectsLocationsAamDiscriminantsApiClientType;
    readonly AGENT_COMMAND: ProjectsLocationsAamDiscriminantsApiClientType;
    readonly PARAMETER: ProjectsLocationsAamDiscriminantsApiClientType;
    readonly INTENT_RESOLUTION: ProjectsLocationsAamDiscriminantsApiClientType;

    values(): Array<ProjectsLocationsAamDiscriminantsApiClientType>;
  }

  export const ProjectsLocationsAamDiscriminantsApiClientTypeEnum:
      IProjectsLocationsAamDiscriminantsApiClientTypeEnum = {
        AGENT_ACTION:
            <ProjectsLocationsAamDiscriminantsApiClientType>'AGENT_ACTION',
        AGENT_ANSWER:
            <ProjectsLocationsAamDiscriminantsApiClientType>'AGENT_ANSWER',
        AGENT_COMMAND:
            <ProjectsLocationsAamDiscriminantsApiClientType>'AGENT_COMMAND',
        AGENT_QUESTION:
            <ProjectsLocationsAamDiscriminantsApiClientType>'AGENT_QUESTION',
        CONTEXTUAL_INTENT:
            <ProjectsLocationsAamDiscriminantsApiClientType>'CONTEXTUAL_INTENT',
        DISCRIMINANT_TYPE_UNSPECIFIED: <
            ProjectsLocationsAamDiscriminantsApiClientType>'DISCRIMINANT_TYPE_UNSPECIFIED',
        DRIVER: <ProjectsLocationsAamDiscriminantsApiClientType>'DRIVER',
        INTENT: <ProjectsLocationsAamDiscriminantsApiClientType>'INTENT',
        INTENT_RESOLUTION:
            <ProjectsLocationsAamDiscriminantsApiClientType>'INTENT_RESOLUTION',
        PARAMETER: <ProjectsLocationsAamDiscriminantsApiClientType>'PARAMETER',
        values():
            Array<ProjectsLocationsAamDiscriminantsApiClientType> {
              return [
                ProjectsLocationsAamDiscriminantsApiClientTypeEnum
                    .DISCRIMINANT_TYPE_UNSPECIFIED,
                ProjectsLocationsAamDiscriminantsApiClientTypeEnum.DRIVER,
                ProjectsLocationsAamDiscriminantsApiClientTypeEnum.INTENT,
                ProjectsLocationsAamDiscriminantsApiClientTypeEnum
                    .CONTEXTUAL_INTENT,
                ProjectsLocationsAamDiscriminantsApiClientTypeEnum
                    .AGENT_QUESTION,
                ProjectsLocationsAamDiscriminantsApiClientTypeEnum.AGENT_ANSWER,
                ProjectsLocationsAamDiscriminantsApiClientTypeEnum.AGENT_ACTION,
                ProjectsLocationsAamDiscriminantsApiClientTypeEnum
                    .AGENT_COMMAND,
                ProjectsLocationsAamDiscriminantsApiClientTypeEnum.PARAMETER,
                ProjectsLocationsAamDiscriminantsApiClientTypeEnum
                    .INTENT_RESOLUTION
              ];
            }
      };

  export type ProjectsLocationsAamDiscriminantsApiClientTypes =
      'DISCRIMINANT_TYPE_UNSPECIFIED'|'DRIVER'|'INTENT'|'CONTEXTUAL_INTENT'|
      'AGENT_QUESTION'|'AGENT_ANSWER'|'AGENT_ACTION'|'AGENT_COMMAND'|
      'PARAMETER'|'INTENT_RESOLUTION';

  export interface IProjectsLocationsAamDiscriminantsApiClientTypesEnum {
    readonly DISCRIMINANT_TYPE_UNSPECIFIED:
        ProjectsLocationsAamDiscriminantsApiClientTypes;
    readonly DRIVER: ProjectsLocationsAamDiscriminantsApiClientTypes;
    readonly INTENT: ProjectsLocationsAamDiscriminantsApiClientTypes;
    readonly CONTEXTUAL_INTENT: ProjectsLocationsAamDiscriminantsApiClientTypes;
    readonly AGENT_QUESTION: ProjectsLocationsAamDiscriminantsApiClientTypes;
    readonly AGENT_ANSWER: ProjectsLocationsAamDiscriminantsApiClientTypes;
    readonly AGENT_ACTION: ProjectsLocationsAamDiscriminantsApiClientTypes;
    readonly AGENT_COMMAND: ProjectsLocationsAamDiscriminantsApiClientTypes;
    readonly PARAMETER: ProjectsLocationsAamDiscriminantsApiClientTypes;
    readonly INTENT_RESOLUTION: ProjectsLocationsAamDiscriminantsApiClientTypes;

    values(): Array<ProjectsLocationsAamDiscriminantsApiClientTypes>;
  }

  export const ProjectsLocationsAamDiscriminantsApiClientTypesEnum:
      IProjectsLocationsAamDiscriminantsApiClientTypesEnum = {
        AGENT_ACTION:
            <ProjectsLocationsAamDiscriminantsApiClientTypes>'AGENT_ACTION',
        AGENT_ANSWER:
            <ProjectsLocationsAamDiscriminantsApiClientTypes>'AGENT_ANSWER',
        AGENT_COMMAND:
            <ProjectsLocationsAamDiscriminantsApiClientTypes>'AGENT_COMMAND',
        AGENT_QUESTION:
            <ProjectsLocationsAamDiscriminantsApiClientTypes>'AGENT_QUESTION',
        CONTEXTUAL_INTENT: <
            ProjectsLocationsAamDiscriminantsApiClientTypes>'CONTEXTUAL_INTENT',
        DISCRIMINANT_TYPE_UNSPECIFIED: <
            ProjectsLocationsAamDiscriminantsApiClientTypes>'DISCRIMINANT_TYPE_UNSPECIFIED',
        DRIVER: <ProjectsLocationsAamDiscriminantsApiClientTypes>'DRIVER',
        INTENT: <ProjectsLocationsAamDiscriminantsApiClientTypes>'INTENT',
        INTENT_RESOLUTION: <
            ProjectsLocationsAamDiscriminantsApiClientTypes>'INTENT_RESOLUTION',
        PARAMETER: <ProjectsLocationsAamDiscriminantsApiClientTypes>'PARAMETER',
        values():
            Array<ProjectsLocationsAamDiscriminantsApiClientTypes> {
              return [
                ProjectsLocationsAamDiscriminantsApiClientTypesEnum
                    .DISCRIMINANT_TYPE_UNSPECIFIED,
                ProjectsLocationsAamDiscriminantsApiClientTypesEnum.DRIVER,
                ProjectsLocationsAamDiscriminantsApiClientTypesEnum.INTENT,
                ProjectsLocationsAamDiscriminantsApiClientTypesEnum
                    .CONTEXTUAL_INTENT,
                ProjectsLocationsAamDiscriminantsApiClientTypesEnum
                    .AGENT_QUESTION,
                ProjectsLocationsAamDiscriminantsApiClientTypesEnum
                    .AGENT_ANSWER,
                ProjectsLocationsAamDiscriminantsApiClientTypesEnum
                    .AGENT_ACTION,
                ProjectsLocationsAamDiscriminantsApiClientTypesEnum
                    .AGENT_COMMAND,
                ProjectsLocationsAamDiscriminantsApiClientTypesEnum.PARAMETER,
                ProjectsLocationsAamDiscriminantsApiClientTypesEnum
                    .INTENT_RESOLUTION
              ];
            }
      };

  export declare interface ProjectsLocationsAamDiscriminantsBatchCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDiscriminantsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
    userGaiaId?: string;
  }

  export declare interface ProjectsLocationsAamDiscriminantsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
    userGaiaId?: string;
  }

  export declare interface ProjectsLocationsAamDiscriminantsDisplayTaxonomyProgressNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDiscriminantsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDiscriminantsListCompositeNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    types?: Array<ProjectsLocationsAamDiscriminantsApiClientTypes>;
    parentDiscriminant?: string;
  }

  export declare interface ProjectsLocationsAamDiscriminantsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    type?: ProjectsLocationsAamDiscriminantsApiClientType;
    types?: Array<ProjectsLocationsAamDiscriminantsApiClientTypes>;
    parentDiscriminantId?: string;
    parentDiscriminant?: string;
  }

  export declare interface ProjectsLocationsAamDiscriminantsMergeNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamDiscriminantsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsLocationsAamDiscriminantsSplitNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamDiscriminantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamDiscriminantsApiClient$Xgafv;
  }

  export class ProjectsLocationsAamDiscriminantsApiClientImpl implements
      ProjectsLocationsAamDiscriminantsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchCreate(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsRequest,
        namedParameters:
            ProjectsLocationsAamDiscriminantsBatchCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamDiscriminants.batchCreate',
        path: `/${this.gapiVersion}/${parent}/aamDiscriminants:batchCreate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsResponse
      });
    }

    create(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AamDiscriminant,
        namedParameters: ProjectsLocationsAamDiscriminantsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamDiscriminant> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1AamDiscriminant>({
            body: $requestBody,
            httpMethod: 'POST',
            methodId: 'dialogflow.projects.locations.aamDiscriminants.create',
            path: `/${this.gapiVersion}/${parent}/aamDiscriminants`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1AamDiscriminant
          });
    }

    delete(
        name: string,
        namedParameters: ProjectsLocationsAamDiscriminantsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamDiscriminant> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDiscriminants/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1AamDiscriminant>({
            body: $requestBody,
            httpMethod: 'DELETE',
            methodId: 'dialogflow.projects.locations.aamDiscriminants.delete',
            path: `/${this.gapiVersion}/${name}`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1AamDiscriminant
          });
    }

    displayTaxonomyProgress(
        parent: string,
        namedParameters:
            ProjectsLocationsAamDiscriminantsDisplayTaxonomyProgressNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1DisplayTaxonomyProgressResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1DisplayTaxonomyProgressResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.aamDiscriminants.displayTaxonomyProgress',
        path: `/${this.gapiVersion}/${
            parent}/aamDiscriminants:displayTaxonomyProgress`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1DisplayTaxonomyProgressResponse
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsAamDiscriminantsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamDiscriminant> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDiscriminants/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1AamDiscriminant>({
            body: $requestBody,
            httpMethod: 'GET',
            methodId: 'dialogflow.projects.locations.aamDiscriminants.get',
            path: `/${this.gapiVersion}/${name}`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1AamDiscriminant
          });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAamDiscriminantsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1ListAamDiscriminantsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ListAamDiscriminantsResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.aamDiscriminants.list',
        path: `/${this.gapiVersion}/${parent}/aamDiscriminants`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1ListAamDiscriminantsResponse
      });
    }

    listComposite(
        parent: string,
        namedParameters:
            ProjectsLocationsAamDiscriminantsListCompositeNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.aamDiscriminants.listComposite',
        path: `/${this.gapiVersion}/${parent}/aamDiscriminants:listComposite`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponse
      });
    }

    merge(
        targetDiscriminant: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantRequest,
        namedParameters: ProjectsLocationsAamDiscriminantsMergeNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantResponse> {
      this.$apiClient.$validateParameter(
          targetDiscriminant,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDiscriminants/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamDiscriminants.merge',
        path: `/${this.gapiVersion}/${targetDiscriminant}:merge`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantResponse
      });
    }

    patch(
        name: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AamDiscriminant,
        namedParameters: ProjectsLocationsAamDiscriminantsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamDiscriminant> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDiscriminants/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1AamDiscriminant>({
            body: $requestBody,
            httpMethod: 'PATCH',
            methodId: 'dialogflow.projects.locations.aamDiscriminants.patch',
            path: `/${this.gapiVersion}/${name}`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1AamDiscriminant
          });
    }

    split(
        name: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantRequest,
        namedParameters: ProjectsLocationsAamDiscriminantsSplitNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantResponse> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamDiscriminants/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamDiscriminants.split',
        path: `/${this.gapiVersion}/${name}:split`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantResponse
      });
    }
  }

  export abstract class ProjectsLocationsAamDiscriminantsApiClient {
    constructor() {}

    abstract batchCreate(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsRequest,
        namedParameters?:
            ProjectsLocationsAamDiscriminantsBatchCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1BatchCreateAamDiscriminantsResponse>;

    abstract create(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AamDiscriminant,
        namedParameters?:
            ProjectsLocationsAamDiscriminantsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamDiscriminant>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAamDiscriminantsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamDiscriminant>;

    abstract displayTaxonomyProgress(
        parent: string,
        namedParameters?:
            ProjectsLocationsAamDiscriminantsDisplayTaxonomyProgressNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1DisplayTaxonomyProgressResponse>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsAamDiscriminantsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamDiscriminant>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAamDiscriminantsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamDiscriminantsResponse>;

    abstract listComposite(
        parent: string,
        namedParameters?:
            ProjectsLocationsAamDiscriminantsListCompositeNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1ListCompositeAamDiscriminantsResponse>;

    abstract merge(
        targetDiscriminant: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantRequest,
        namedParameters?: ProjectsLocationsAamDiscriminantsMergeNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1MergeAamDiscriminantResponse>;

    abstract patch(
        name: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AamDiscriminant,
        namedParameters?: ProjectsLocationsAamDiscriminantsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamDiscriminant>;

    abstract split(
        name: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantRequest,
        namedParameters?: ProjectsLocationsAamDiscriminantsSplitNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1SplitAamDiscriminantResponse>;
  }

  export type ProjectsLocationsAamLabeledTranscriptsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAamLabeledTranscriptsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAamLabeledTranscriptsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAamLabeledTranscriptsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAamLabeledTranscriptsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamLabeledTranscriptsApiClient$XgafvEnum:
      IProjectsLocationsAamLabeledTranscriptsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamLabeledTranscriptsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamLabeledTranscriptsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAamLabeledTranscriptsApiClient$Xgafv> {
              return [
                ProjectsLocationsAamLabeledTranscriptsApiClient$XgafvEnum[1],
                ProjectsLocationsAamLabeledTranscriptsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAamLabeledTranscriptsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAamLabeledTranscriptsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAamLabeledTranscriptsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAamLabeledTranscriptsApiClientAlt;
    readonly PROTO: ProjectsLocationsAamLabeledTranscriptsApiClientAlt;

    values(): Array<ProjectsLocationsAamLabeledTranscriptsApiClientAlt>;
  }

  export const ProjectsLocationsAamLabeledTranscriptsApiClientAltEnum:
      IProjectsLocationsAamLabeledTranscriptsApiClientAltEnum = {
        JSON: <ProjectsLocationsAamLabeledTranscriptsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAamLabeledTranscriptsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAamLabeledTranscriptsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAamLabeledTranscriptsApiClientAlt> {
              return [
                ProjectsLocationsAamLabeledTranscriptsApiClientAltEnum.JSON,
                ProjectsLocationsAamLabeledTranscriptsApiClientAltEnum.MEDIA,
                ProjectsLocationsAamLabeledTranscriptsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAamLabeledTranscriptsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamLabeledTranscriptsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamLabeledTranscriptsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    intent?: string;
  }

  export class ProjectsLocationsAamLabeledTranscriptsApiClientImpl implements
      ProjectsLocationsAamLabeledTranscriptsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsAamLabeledTranscriptsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1ListFlowLabeledAamTranscriptsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ListFlowLabeledAamTranscriptsResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.aamLabeledTranscripts.list',
        path: `/${this.gapiVersion}/${parent}/aamLabeledTranscripts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1ListFlowLabeledAamTranscriptsResponse
      });
    }
  }

  export abstract class ProjectsLocationsAamLabeledTranscriptsApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsAamLabeledTranscriptsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1ListFlowLabeledAamTranscriptsResponse>;
  }

  export type ProjectsLocationsAamLabelsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAamLabelsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAamLabelsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAamLabelsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAamLabelsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamLabelsApiClient$XgafvEnum:
      IProjectsLocationsAamLabelsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamLabelsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamLabelsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAamLabelsApiClient$Xgafv> {
              return [
                ProjectsLocationsAamLabelsApiClient$XgafvEnum[1],
                ProjectsLocationsAamLabelsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAamLabelsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsAamLabelsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAamLabelsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAamLabelsApiClientAlt;
    readonly PROTO: ProjectsLocationsAamLabelsApiClientAlt;

    values(): Array<ProjectsLocationsAamLabelsApiClientAlt>;
  }

  export const ProjectsLocationsAamLabelsApiClientAltEnum:
      IProjectsLocationsAamLabelsApiClientAltEnum = {
        JSON: <ProjectsLocationsAamLabelsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAamLabelsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAamLabelsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAamLabelsApiClientAlt> {
              return [
                ProjectsLocationsAamLabelsApiClientAltEnum.JSON,
                ProjectsLocationsAamLabelsApiClientAltEnum.MEDIA,
                ProjectsLocationsAamLabelsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAamLabelsBatchDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamLabelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamLabelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamLabelsBatchGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamLabelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamLabelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamLabelsBatchUpsertNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamLabelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamLabelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamLabelsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamLabelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamLabelsApiClient$Xgafv;
    transcript?: string;
    discriminant?: string;
  }

  export declare interface ProjectsLocationsAamLabelsMarkLabelsReviewedNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamLabelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamLabelsApiClient$Xgafv;
  }

  export class ProjectsLocationsAamLabelsApiClientImpl implements
      ProjectsLocationsAamLabelsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchDelete(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1BatchDeleteAamLabelsRequest,
        namedParameters: ProjectsLocationsAamLabelsBatchDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamLabels.batchDelete',
        path: `/${this.gapiVersion}/${parent}/aamLabels:batchDelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    batchGet(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsRequest,
        namedParameters: ProjectsLocationsAamLabelsBatchGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsResponse>({
            body: $requestBody,
            httpMethod: 'POST',
            methodId: 'dialogflow.projects.locations.aamLabels.batchGet',
            path: `/${this.gapiVersion}/${parent}/aamLabels:batchGet`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor:
                GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsResponse
          });
    }

    batchUpsert(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsRequest,
        namedParameters: ProjectsLocationsAamLabelsBatchUpsertNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamLabels.batchUpsert',
        path: `/${this.gapiVersion}/${parent}/aamLabels:batchUpsert`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAamLabelsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamLabelsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1ListAamLabelsResponse>({
            body: $requestBody,
            httpMethod: 'GET',
            methodId: 'dialogflow.projects.locations.aamLabels.list',
            path: `/${this.gapiVersion}/${parent}/aamLabels`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1ListAamLabelsResponse
          });
    }

    markLabelsReviewed(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedRequest,
        namedParameters:
            ProjectsLocationsAamLabelsMarkLabelsReviewedNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedResponse>({
            body: $requestBody,
            httpMethod: 'POST',
            methodId:
                'dialogflow.projects.locations.aamLabels.markLabelsReviewed',
            path: `/${this.gapiVersion}/${parent}/aamLabels:markLabelsReviewed`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor:
                GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedResponse
          });
    }
  }

  export abstract class ProjectsLocationsAamLabelsApiClient {
    constructor() {}

    abstract batchDelete(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1BatchDeleteAamLabelsRequest,
        namedParameters?: ProjectsLocationsAamLabelsBatchDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract batchGet(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsRequest,
        namedParameters?: ProjectsLocationsAamLabelsBatchGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1BatchGetAamLabelsResponse>;

    abstract batchUpsert(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1BatchUpsertAamLabelsRequest,
        namedParameters?: ProjectsLocationsAamLabelsBatchUpsertNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAamLabelsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamLabelsResponse>;

    abstract markLabelsReviewed(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedRequest,
        namedParameters?:
            ProjectsLocationsAamLabelsMarkLabelsReviewedNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1MarkLabelsReviewedResponse>;
  }

  export type ProjectsLocationsAamNoticesApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAamNoticesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAamNoticesApiClient$Xgafv;
    readonly 2: ProjectsLocationsAamNoticesApiClient$Xgafv;

    values(): Array<ProjectsLocationsAamNoticesApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamNoticesApiClient$XgafvEnum:
      IProjectsLocationsAamNoticesApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamNoticesApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamNoticesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAamNoticesApiClient$Xgafv> {
              return [
                ProjectsLocationsAamNoticesApiClient$XgafvEnum[1],
                ProjectsLocationsAamNoticesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAamNoticesApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsAamNoticesApiClientAltEnum {
    readonly JSON: ProjectsLocationsAamNoticesApiClientAlt;
    readonly MEDIA: ProjectsLocationsAamNoticesApiClientAlt;
    readonly PROTO: ProjectsLocationsAamNoticesApiClientAlt;

    values(): Array<ProjectsLocationsAamNoticesApiClientAlt>;
  }

  export const ProjectsLocationsAamNoticesApiClientAltEnum:
      IProjectsLocationsAamNoticesApiClientAltEnum = {
        JSON: <ProjectsLocationsAamNoticesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAamNoticesApiClientAlt>'media',
        PROTO: <ProjectsLocationsAamNoticesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAamNoticesApiClientAlt> {
              return [
                ProjectsLocationsAamNoticesApiClientAltEnum.JSON,
                ProjectsLocationsAamNoticesApiClientAltEnum.MEDIA,
                ProjectsLocationsAamNoticesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAamNoticesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamNoticesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamNoticesApiClient$Xgafv;
    aamDiscriminants?: Array<string>;
    aamLabels?: Array<string>;
    aamUtterances?: Array<string>;
    creatorGaiaId?: string;
  }

  export declare interface ProjectsLocationsAamNoticesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamNoticesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamNoticesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    aamDiscriminants?: Array<string>;
    aamLabels?: Array<string>;
    aamUtterances?: Array<string>;
  }

  export declare interface ProjectsLocationsAamNoticesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamNoticesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamNoticesApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsLocationsAamNoticesApiClientImpl implements
      ProjectsLocationsAamNoticesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamNotice,
        namedParameters: ProjectsLocationsAamNoticesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamNotice> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleCloudDialogflowAamV2beta1AamNotice>(
          {
            body: $requestBody,
            httpMethod: 'POST',
            methodId: 'dialogflow.projects.locations.aamNotices.create',
            path: `/${this.gapiVersion}/${parent}/aamNotices`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1AamNotice
          });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAamNoticesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamNoticesResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1ListAamNoticesResponse>({
            body: $requestBody,
            httpMethod: 'GET',
            methodId: 'dialogflow.projects.locations.aamNotices.list',
            path: `/${this.gapiVersion}/${parent}/aamNotices`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1ListAamNoticesResponse
          });
    }

    patch(
        name: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamNotice,
        namedParameters: ProjectsLocationsAamNoticesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AamNotice> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/aamNotices/[^/]+$'));

      return this.$apiClient.$request<GoogleCloudDialogflowAamV2beta1AamNotice>(
          {
            body: $requestBody,
            httpMethod: 'PATCH',
            methodId: 'dialogflow.projects.locations.aamNotices.patch',
            path: `/${this.gapiVersion}/${name}`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1AamNotice
          });
    }
  }

  export abstract class ProjectsLocationsAamNoticesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamNotice,
        namedParameters?: ProjectsLocationsAamNoticesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamNotice>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAamNoticesListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamNoticesResponse>;

    abstract patch(
        name: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamNotice,
        namedParameters?: ProjectsLocationsAamNoticesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AamNotice>;
  }

  export type ProjectsLocationsAamProjectsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAamProjectsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAamProjectsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAamProjectsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAamProjectsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamProjectsApiClient$XgafvEnum:
      IProjectsLocationsAamProjectsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamProjectsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamProjectsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAamProjectsApiClient$Xgafv> {
              return [
                ProjectsLocationsAamProjectsApiClient$XgafvEnum[1],
                ProjectsLocationsAamProjectsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAamProjectsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsAamProjectsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAamProjectsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAamProjectsApiClientAlt;
    readonly PROTO: ProjectsLocationsAamProjectsApiClientAlt;

    values(): Array<ProjectsLocationsAamProjectsApiClientAlt>;
  }

  export const ProjectsLocationsAamProjectsApiClientAltEnum:
      IProjectsLocationsAamProjectsApiClientAltEnum = {
        JSON: <ProjectsLocationsAamProjectsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAamProjectsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAamProjectsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAamProjectsApiClientAlt> {
              return [
                ProjectsLocationsAamProjectsApiClientAltEnum.JSON,
                ProjectsLocationsAamProjectsApiClientAltEnum.MEDIA,
                ProjectsLocationsAamProjectsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAamProjectsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamProjectsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamProjectsApiClient$Xgafv;
  }

  export class ProjectsLocationsAamProjectsApiClientImpl implements
      ProjectsLocationsAamProjectsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamProject,
        namedParameters: ProjectsLocationsAamProjectsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamProjects.create',
        path: `/${this.gapiVersion}/${parent}/aamProjects`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }
  }

  export abstract class ProjectsLocationsAamProjectsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: GoogleCloudDialogflowAamV2beta1AamProject,
        namedParameters?: ProjectsLocationsAamProjectsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;
  }

  export type ProjectsLocationsAamTasksApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAamTasksApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAamTasksApiClient$Xgafv;
    readonly 2: ProjectsLocationsAamTasksApiClient$Xgafv;

    values(): Array<ProjectsLocationsAamTasksApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamTasksApiClient$XgafvEnum:
      IProjectsLocationsAamTasksApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamTasksApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamTasksApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAamTasksApiClient$Xgafv> {
              return [
                ProjectsLocationsAamTasksApiClient$XgafvEnum[1],
                ProjectsLocationsAamTasksApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAamTasksApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsAamTasksApiClientAltEnum {
    readonly JSON: ProjectsLocationsAamTasksApiClientAlt;
    readonly MEDIA: ProjectsLocationsAamTasksApiClientAlt;
    readonly PROTO: ProjectsLocationsAamTasksApiClientAlt;

    values(): Array<ProjectsLocationsAamTasksApiClientAlt>;
  }

  export const ProjectsLocationsAamTasksApiClientAltEnum:
      IProjectsLocationsAamTasksApiClientAltEnum = {
        JSON: <ProjectsLocationsAamTasksApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAamTasksApiClientAlt>'media',
        PROTO: <ProjectsLocationsAamTasksApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAamTasksApiClientAlt> {
              return [
                ProjectsLocationsAamTasksApiClientAltEnum.JSON,
                ProjectsLocationsAamTasksApiClientAltEnum.MEDIA,
                ProjectsLocationsAamTasksApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAamTasksCompleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamTasksApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamTasksApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamTasksGenerateFlowNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamTasksApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamTasksApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamTasksGenerateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamTasksApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamTasksApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamTasksGenerateReviewNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamTasksApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamTasksApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamTasksGenerateTranscriptNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamTasksApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamTasksApiClient$Xgafv;
  }

  export class ProjectsLocationsAamTasksApiClientImpl implements
      ProjectsLocationsAamTasksApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    complete(
        name: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1CompleteAamTaskRequest,
        namedParameters: ProjectsLocationsAamTasksCompleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/locations/[^/]+/aamTasks/[^/]+$'));

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamTasks.complete',
        path: `/${this.gapiVersion}/${name}:complete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    generate(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequest,
        namedParameters: ProjectsLocationsAamTasksGenerateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1GenerateAamTaskResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1GenerateAamTaskResponse>({
            body: $requestBody,
            httpMethod: 'POST',
            methodId: 'dialogflow.projects.locations.aamTasks.generate',
            path: `/${this.gapiVersion}/${parent}/aamTasks:generate`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1GenerateAamTaskResponse
          });
    }

    generateFlow(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskRequest,
        namedParameters: ProjectsLocationsAamTasksGenerateFlowNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskResponse>(
              {
                body: $requestBody,
                httpMethod: 'POST',
                methodId: 'dialogflow.projects.locations.aamTasks.generateFlow',
                path: `/${this.gapiVersion}/${parent}/aamTasks:generateFlow`,
                queryParams: buildQueryParams(
                    namedParameters, PARAM_MAP_0, passthroughNamedParameters),
                responseCtor:
                    GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskResponse
              });
    }

    generateReview(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskRequest,
        namedParameters: ProjectsLocationsAamTasksGenerateReviewNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamTasks.generateReview',
        path: `/${this.gapiVersion}/${parent}/aamTasks:generateReview`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskResponse
      });
    }

    generateTranscript(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskRequest,
        namedParameters:
            ProjectsLocationsAamTasksGenerateTranscriptNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.aamTasks.generateTranscript',
        path: `/${this.gapiVersion}/${parent}/aamTasks:generateTranscript`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskResponse
      });
    }
  }

  export abstract class ProjectsLocationsAamTasksApiClient {
    constructor() {}

    abstract complete(
        name: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1CompleteAamTaskRequest,
        namedParameters?: ProjectsLocationsAamTasksCompleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract generate(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1GenerateAamTaskRequest,
        namedParameters?: ProjectsLocationsAamTasksGenerateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1GenerateAamTaskResponse>;

    abstract generateFlow(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskRequest,
        namedParameters?: ProjectsLocationsAamTasksGenerateFlowNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1GenerateAamFlowTaskResponse>;

    abstract generateReview(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskRequest,
        namedParameters?:
            ProjectsLocationsAamTasksGenerateReviewNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1GenerateAamReviewTaskResponse>;

    abstract generateTranscript(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskRequest,
        namedParameters?:
            ProjectsLocationsAamTasksGenerateTranscriptNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1GenerateAamTranscriptTaskResponse>;
  }

  export type ProjectsLocationsAamTopicClustersApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAamTopicClustersApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAamTopicClustersApiClient$Xgafv;
    readonly 2: ProjectsLocationsAamTopicClustersApiClient$Xgafv;

    values(): Array<ProjectsLocationsAamTopicClustersApiClient$Xgafv>;
  }

  export const ProjectsLocationsAamTopicClustersApiClient$XgafvEnum:
      IProjectsLocationsAamTopicClustersApiClient$XgafvEnum = {
        1: <ProjectsLocationsAamTopicClustersApiClient$Xgafv>'1',
        2: <ProjectsLocationsAamTopicClustersApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAamTopicClustersApiClient$Xgafv> {
              return [
                ProjectsLocationsAamTopicClustersApiClient$XgafvEnum[1],
                ProjectsLocationsAamTopicClustersApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAamTopicClustersApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAamTopicClustersApiClientAltEnum {
    readonly JSON: ProjectsLocationsAamTopicClustersApiClientAlt;
    readonly MEDIA: ProjectsLocationsAamTopicClustersApiClientAlt;
    readonly PROTO: ProjectsLocationsAamTopicClustersApiClientAlt;

    values(): Array<ProjectsLocationsAamTopicClustersApiClientAlt>;
  }

  export const ProjectsLocationsAamTopicClustersApiClientAltEnum:
      IProjectsLocationsAamTopicClustersApiClientAltEnum = {
        JSON: <ProjectsLocationsAamTopicClustersApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAamTopicClustersApiClientAlt>'media',
        PROTO: <ProjectsLocationsAamTopicClustersApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAamTopicClustersApiClientAlt> {
              return [
                ProjectsLocationsAamTopicClustersApiClientAltEnum.JSON,
                ProjectsLocationsAamTopicClustersApiClientAltEnum.MEDIA,
                ProjectsLocationsAamTopicClustersApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAamTopicClustersClusterLabelsUpdateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamTopicClustersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamTopicClustersApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAamTopicClustersFetchClusterLabelsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamTopicClustersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamTopicClustersApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAamTopicClustersFetchTopicClusterDetailsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamTopicClustersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamTopicClustersApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    orderBy?: string;
    filter?: string;
  }

  export declare interface ProjectsLocationsAamTopicClustersListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAamTopicClustersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAamTopicClustersApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    orderBy?: string;
    filter?: string;
  }

  export class ProjectsLocationsAamTopicClustersApiClientImpl implements
      ProjectsLocationsAamTopicClustersApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    clusterLabelsUpdate(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateRequest,
        namedParameters:
            ProjectsLocationsAamTopicClustersClusterLabelsUpdateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateResponse> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamTopicClusters/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.aamTopicClusters.clusterLabelsUpdate',
        path: `/${this.gapiVersion}/${parent}:clusterLabelsUpdate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateResponse
      });
    }

    fetchClusterLabels(
        topicCluster: string,
        namedParameters:
            ProjectsLocationsAamTopicClustersFetchClusterLabelsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1FetchClusterLabelsResponse> {
      this.$apiClient.$validateParameter(
          topicCluster,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamTopicClusters/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1FetchClusterLabelsResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.aamTopicClusters.fetchClusterLabels',
        path: `/${this.gapiVersion}/${topicCluster}:fetchClusterLabels`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleCloudDialogflowAamV2beta1FetchClusterLabelsResponse
      });
    }

    fetchTopicClusterDetails(
        parent: string,
        namedParameters:
            ProjectsLocationsAamTopicClustersFetchTopicClusterDetailsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1FetchTopicClusterDetailsResponse> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/aamTopicClusters/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1FetchTopicClusterDetailsResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.aamTopicClusters.fetchTopicClusterDetails',
        path: `/${this.gapiVersion}/${parent}:fetchTopicClusterDetails`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1FetchTopicClusterDetailsResponse
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAamTopicClustersListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1ListAamTopicClustersResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ListAamTopicClustersResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.aamTopicClusters.list',
        path: `/${this.gapiVersion}/${parent}/aamTopicClusters`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1ListAamTopicClustersResponse
      });
    }
  }

  export abstract class ProjectsLocationsAamTopicClustersApiClient {
    constructor() {}

    abstract clusterLabelsUpdate(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateRequest,
        namedParameters?:
            ProjectsLocationsAamTopicClustersClusterLabelsUpdateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ClusterLabelsUpdateResponse>;

    abstract fetchClusterLabels(
        topicCluster: string,
        namedParameters?:
            ProjectsLocationsAamTopicClustersFetchClusterLabelsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1FetchClusterLabelsResponse>;

    abstract fetchTopicClusterDetails(
        parent: string,
        namedParameters?:
            ProjectsLocationsAamTopicClustersFetchTopicClusterDetailsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1FetchTopicClusterDetailsResponse>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAamTopicClustersListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamTopicClustersResponse>;
  }

  export type ProjectsLocationsAdminToolMetricsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAdminToolMetricsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAdminToolMetricsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAdminToolMetricsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAdminToolMetricsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAdminToolMetricsApiClient$XgafvEnum:
      IProjectsLocationsAdminToolMetricsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAdminToolMetricsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAdminToolMetricsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAdminToolMetricsApiClient$Xgafv> {
              return [
                ProjectsLocationsAdminToolMetricsApiClient$XgafvEnum[1],
                ProjectsLocationsAdminToolMetricsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAdminToolMetricsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAdminToolMetricsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAdminToolMetricsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAdminToolMetricsApiClientAlt;
    readonly PROTO: ProjectsLocationsAdminToolMetricsApiClientAlt;

    values(): Array<ProjectsLocationsAdminToolMetricsApiClientAlt>;
  }

  export const ProjectsLocationsAdminToolMetricsApiClientAltEnum:
      IProjectsLocationsAdminToolMetricsApiClientAltEnum = {
        JSON: <ProjectsLocationsAdminToolMetricsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAdminToolMetricsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAdminToolMetricsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAdminToolMetricsApiClientAlt> {
              return [
                ProjectsLocationsAdminToolMetricsApiClientAltEnum.JSON,
                ProjectsLocationsAdminToolMetricsApiClientAltEnum.MEDIA,
                ProjectsLocationsAdminToolMetricsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAdminToolMetricsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdminToolMetricsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdminToolMetricsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsAdminToolMetricsApiClientImpl implements
      ProjectsLocationsAdminToolMetricsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAdminToolMetricsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.adminToolMetrics.list',
        path: `/${this.gapiVersion}/${parent}/adminToolMetrics`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponse
      });
    }
  }

  export abstract class ProjectsLocationsAdminToolMetricsApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAdminToolMetricsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ListAdminToolMetricsResponse>;
  }

  export type ProjectsLocationsAdmintoolApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAdmintoolApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAdmintoolApiClient$Xgafv;
    readonly 2: ProjectsLocationsAdmintoolApiClient$Xgafv;

    values(): Array<ProjectsLocationsAdmintoolApiClient$Xgafv>;
  }

  export const ProjectsLocationsAdmintoolApiClient$XgafvEnum:
      IProjectsLocationsAdmintoolApiClient$XgafvEnum = {
        1: <ProjectsLocationsAdmintoolApiClient$Xgafv>'1',
        2: <ProjectsLocationsAdmintoolApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAdmintoolApiClient$Xgafv> {
              return [
                ProjectsLocationsAdmintoolApiClient$XgafvEnum[1],
                ProjectsLocationsAdmintoolApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAdmintoolApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsAdmintoolApiClientAltEnum {
    readonly JSON: ProjectsLocationsAdmintoolApiClientAlt;
    readonly MEDIA: ProjectsLocationsAdmintoolApiClientAlt;
    readonly PROTO: ProjectsLocationsAdmintoolApiClientAlt;

    values(): Array<ProjectsLocationsAdmintoolApiClientAlt>;
  }

  export const ProjectsLocationsAdmintoolApiClientAltEnum:
      IProjectsLocationsAdmintoolApiClientAltEnum = {
        JSON: <ProjectsLocationsAdmintoolApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAdmintoolApiClientAlt>'media',
        PROTO: <ProjectsLocationsAdmintoolApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAdmintoolApiClientAlt> {
              return [
                ProjectsLocationsAdmintoolApiClientAltEnum.JSON,
                ProjectsLocationsAdmintoolApiClientAltEnum.MEDIA,
                ProjectsLocationsAdmintoolApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsAdmintoolApiClientTypeFilter =
      'PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED'|'CUSTOM'|
      'TELECOMMUNICATIONS'|'FINANCIAL_SERVICES';

  export interface IProjectsLocationsAdmintoolApiClientTypeFilterEnum {
    readonly PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED:
        ProjectsLocationsAdmintoolApiClientTypeFilter;
    readonly CUSTOM: ProjectsLocationsAdmintoolApiClientTypeFilter;
    readonly TELECOMMUNICATIONS: ProjectsLocationsAdmintoolApiClientTypeFilter;
    readonly FINANCIAL_SERVICES: ProjectsLocationsAdmintoolApiClientTypeFilter;

    values(): Array<ProjectsLocationsAdmintoolApiClientTypeFilter>;
  }

  export const ProjectsLocationsAdmintoolApiClientTypeFilterEnum:
      IProjectsLocationsAdmintoolApiClientTypeFilterEnum = {
        CUSTOM: <ProjectsLocationsAdmintoolApiClientTypeFilter>'CUSTOM',
        FINANCIAL_SERVICES:
            <ProjectsLocationsAdmintoolApiClientTypeFilter>'FINANCIAL_SERVICES',
        PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED: <
            ProjectsLocationsAdmintoolApiClientTypeFilter>'PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED',
        TELECOMMUNICATIONS:
            <ProjectsLocationsAdmintoolApiClientTypeFilter>'TELECOMMUNICATIONS',
        values():
            Array<ProjectsLocationsAdmintoolApiClientTypeFilter> {
              return [
                ProjectsLocationsAdmintoolApiClientTypeFilterEnum
                    .PREBUILT_TAXONOMY_VERTICAL_TYPE_UNSPECIFIED,
                ProjectsLocationsAdmintoolApiClientTypeFilterEnum.CUSTOM,
                ProjectsLocationsAdmintoolApiClientTypeFilterEnum
                    .TELECOMMUNICATIONS,
                ProjectsLocationsAdmintoolApiClientTypeFilterEnum
                    .FINANCIAL_SERVICES
              ];
            }
      };

  export declare interface ProjectsLocationsAdmintoolAddUserNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAdmintoolBatchexportNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAdmintoolCompileAgentDefinitionNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAdmintoolDisplayPrebuiltTaxonomyImportCardsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAdmintoolDisplayStatsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAdmintoolDisplayUsersNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAdmintoolExportCsvReportNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
    userGaiaId?: string;
    justification?: string;
  }

  export declare interface ProjectsLocationsAdmintoolFetchUserNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAdmintoolGenerateAgentDefinitionNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAdmintoolImportPrebuiltTaxonomyNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAdmintoolPreviewPrebuiltTaxonomyNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
    typeFilter?: ProjectsLocationsAdmintoolApiClientTypeFilter;
  }

  export declare interface ProjectsLocationsAdmintoolRemoveUserNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAdmintoolApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAdmintoolApiClient$Xgafv;
  }

  export class ProjectsLocationsAdmintoolApiClientImpl implements
      ProjectsLocationsAdmintoolApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    addUser(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AddUserRequest,
        namedParameters: ProjectsLocationsAdmintoolAddUserNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1AddUserResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1AddUserResponse>({
            body: $requestBody,
            httpMethod: 'POST',
            methodId: 'dialogflow.projects.locations.admintool.addUser',
            path: `/${this.gapiVersion}/${parent}/admintool:addUser`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1AddUserResponse
          });
    }

    batchexport(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1BatchExportIntentsRequest,
        namedParameters: ProjectsLocationsAdmintoolBatchexportNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.admintool.batchexport',
        path: `/${this.gapiVersion}/${parent}/admintool:batchexport`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    compileAgentDefinition(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequest,
        namedParameters:
            ProjectsLocationsAdmintoolCompileAgentDefinitionNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.admintool.compileAgentDefinition',
        path: `/${this.gapiVersion}/${parent}/admintool:compileAgentDefinition`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponse
      });
    }

    displayPrebuiltTaxonomyImportCards(
        parent: string,
        namedParameters:
            ProjectsLocationsAdmintoolDisplayPrebuiltTaxonomyImportCardsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1DisplayPrebuiltTaxonomyImportCardsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1DisplayPrebuiltTaxonomyImportCardsResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.admintool.displayPrebuiltTaxonomyImportCards',
        path: `/${this.gapiVersion}/${
            parent}/admintool:displayPrebuiltTaxonomyImportCards`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1DisplayPrebuiltTaxonomyImportCardsResponse
      });
    }

    displayStats(
        parent: string,
        namedParameters: ProjectsLocationsAdmintoolDisplayStatsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1DisplayStatsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1DisplayStatsResponse>({
            body: $requestBody,
            httpMethod: 'GET',
            methodId: 'dialogflow.projects.locations.admintool.displayStats',
            path: `/${this.gapiVersion}/${parent}/admintool:displayStats`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1DisplayStatsResponse
          });
    }

    displayUsers(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1DisplayUsersRequest,
        namedParameters: ProjectsLocationsAdmintoolDisplayUsersNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1DisplayUsersResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1DisplayUsersResponse>({
            body: $requestBody,
            httpMethod: 'POST',
            methodId: 'dialogflow.projects.locations.admintool.displayUsers',
            path: `/${this.gapiVersion}/${parent}/admintool:displayUsers`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1DisplayUsersResponse
          });
    }

    exportCsvReport(
        parent: string,
        namedParameters:
            ProjectsLocationsAdmintoolExportCsvReportNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1ExportAdminToolMetricsCsvReportResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ExportAdminToolMetricsCsvReportResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.admintool.exportCsvReport',
        path: `/${this.gapiVersion}/${parent}/admintool:exportCsvReport`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1ExportAdminToolMetricsCsvReportResponse
      });
    }

    fetchUser(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1FetchUserRequest,
        namedParameters: ProjectsLocationsAdmintoolFetchUserNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1UserInfo> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleCloudDialogflowAamV2beta1UserInfo>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.admintool.fetchUser',
        path: `/${this.gapiVersion}/${parent}/admintool:fetchUser`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleCloudDialogflowAamV2beta1UserInfo
      });
    }

    generateAgentDefinition(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionRequest,
        namedParameters:
            ProjectsLocationsAdmintoolGenerateAgentDefinitionNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.admintool.generateAgentDefinition',
        path:
            `/${this.gapiVersion}/${parent}/admintool:generateAgentDefinition`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionResponse
      });
    }

    importPrebuiltTaxonomy(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequest,
        namedParameters:
            ProjectsLocationsAdmintoolImportPrebuiltTaxonomyNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.admintool.importPrebuiltTaxonomy',
        path: `/${this.gapiVersion}/${parent}/admintool:importPrebuiltTaxonomy`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyResponse
      });
    }

    previewPrebuiltTaxonomy(
        parent: string,
        namedParameters:
            ProjectsLocationsAdmintoolPreviewPrebuiltTaxonomyNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1PreviewPrebuiltTaxonomyResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1PreviewPrebuiltTaxonomyResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.admintool.previewPrebuiltTaxonomy',
        path:
            `/${this.gapiVersion}/${parent}/admintool:previewPrebuiltTaxonomy`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1PreviewPrebuiltTaxonomyResponse
      });
    }

    removeUser(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1RemoveUserRequest,
        namedParameters: ProjectsLocationsAdmintoolRemoveUserNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1RemoveUserResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1RemoveUserResponse>({
            body: $requestBody,
            httpMethod: 'POST',
            methodId: 'dialogflow.projects.locations.admintool.removeUser',
            path: `/${this.gapiVersion}/${parent}/admintool:removeUser`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1RemoveUserResponse
          });
    }
  }

  export abstract class ProjectsLocationsAdmintoolApiClient {
    constructor() {}

    abstract addUser(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1AddUserRequest,
        namedParameters?: ProjectsLocationsAdmintoolAddUserNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1AddUserResponse>;

    abstract batchexport(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1BatchExportIntentsRequest,
        namedParameters?: ProjectsLocationsAdmintoolBatchexportNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract compileAgentDefinition(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionRequest,
        namedParameters?:
            ProjectsLocationsAdmintoolCompileAgentDefinitionNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1CompileAgentDefinitionResponse>;

    abstract displayPrebuiltTaxonomyImportCards(
        parent: string,
        namedParameters?:
            ProjectsLocationsAdmintoolDisplayPrebuiltTaxonomyImportCardsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1DisplayPrebuiltTaxonomyImportCardsResponse>;

    abstract displayStats(
        parent: string,
        namedParameters?: ProjectsLocationsAdmintoolDisplayStatsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1DisplayStatsResponse>;

    abstract displayUsers(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1DisplayUsersRequest,
        namedParameters?: ProjectsLocationsAdmintoolDisplayUsersNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1DisplayUsersResponse>;

    abstract exportCsvReport(
        parent: string,
        namedParameters?:
            ProjectsLocationsAdmintoolExportCsvReportNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1ExportAdminToolMetricsCsvReportResponse>;

    abstract fetchUser(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1FetchUserRequest,
        namedParameters?: ProjectsLocationsAdmintoolFetchUserNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1UserInfo>;

    abstract generateAgentDefinition(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionRequest,
        namedParameters?:
            ProjectsLocationsAdmintoolGenerateAgentDefinitionNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1GenerateAgentDefinitionResponse>;

    abstract importPrebuiltTaxonomy(
        parent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyRequest,
        namedParameters?:
            ProjectsLocationsAdmintoolImportPrebuiltTaxonomyNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1ImportPrebuiltTaxonomyResponse>;

    abstract previewPrebuiltTaxonomy(
        parent: string,
        namedParameters?:
            ProjectsLocationsAdmintoolPreviewPrebuiltTaxonomyNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1PreviewPrebuiltTaxonomyResponse>;

    abstract removeUser(
        parent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1RemoveUserRequest,
        namedParameters?: ProjectsLocationsAdmintoolRemoveUserNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1RemoveUserResponse>;
  }

  export type ProjectsLocationsAgentApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAgentApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentApiClient$XgafvEnum:
      IProjectsLocationsAgentApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentApiClient$XgafvEnum[1],
                ProjectsLocationsAgentApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsAgentApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentApiClientAlt;

    values(): Array<ProjectsLocationsAgentApiClientAlt>;
  }

  export const ProjectsLocationsAgentApiClientAltEnum:
      IProjectsLocationsAgentApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentApiClientAlt> {
              return [
                ProjectsLocationsAgentApiClientAltEnum.JSON,
                ProjectsLocationsAgentApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsAgentApiClientRequestSource =
      'SERVICE_REQUEST_SOURCE_UNSPECIFIED'|'DIALOGFLOW_CONSOLE';

  export interface IProjectsLocationsAgentApiClientRequestSourceEnum {
    readonly SERVICE_REQUEST_SOURCE_UNSPECIFIED:
        ProjectsLocationsAgentApiClientRequestSource;
    readonly DIALOGFLOW_CONSOLE: ProjectsLocationsAgentApiClientRequestSource;

    values(): Array<ProjectsLocationsAgentApiClientRequestSource>;
  }

  export const ProjectsLocationsAgentApiClientRequestSourceEnum:
      IProjectsLocationsAgentApiClientRequestSourceEnum = {
        DIALOGFLOW_CONSOLE:
            <ProjectsLocationsAgentApiClientRequestSource>'DIALOGFLOW_CONSOLE',
        SERVICE_REQUEST_SOURCE_UNSPECIFIED: <
            ProjectsLocationsAgentApiClientRequestSource>'SERVICE_REQUEST_SOURCE_UNSPECIFIED',
        values():
            Array<ProjectsLocationsAgentApiClientRequestSource> {
              return [
                ProjectsLocationsAgentApiClientRequestSourceEnum
                    .SERVICE_REQUEST_SOURCE_UNSPECIFIED,
                ProjectsLocationsAgentApiClientRequestSourceEnum
                    .DIALOGFLOW_CONSOLE
              ];
            }
      };

  export type ProjectsLocationsAgentApiClientServiceRequestSource =
      'SERVICE_REQUEST_SOURCE_UNSPECIFIED'|'DIALOGFLOW_CONSOLE';

  export interface IProjectsLocationsAgentApiClientServiceRequestSourceEnum {
    readonly SERVICE_REQUEST_SOURCE_UNSPECIFIED:
        ProjectsLocationsAgentApiClientServiceRequestSource;
    readonly DIALOGFLOW_CONSOLE:
        ProjectsLocationsAgentApiClientServiceRequestSource;

    values(): Array<ProjectsLocationsAgentApiClientServiceRequestSource>;
  }

  export const ProjectsLocationsAgentApiClientServiceRequestSourceEnum:
      IProjectsLocationsAgentApiClientServiceRequestSourceEnum = {
        DIALOGFLOW_CONSOLE: <
            ProjectsLocationsAgentApiClientServiceRequestSource>'DIALOGFLOW_CONSOLE',
        SERVICE_REQUEST_SOURCE_UNSPECIFIED: <
            ProjectsLocationsAgentApiClientServiceRequestSource>'SERVICE_REQUEST_SOURCE_UNSPECIFIED',
        values():
            Array<ProjectsLocationsAgentApiClientServiceRequestSource> {
              return [
                ProjectsLocationsAgentApiClientServiceRequestSourceEnum
                    .SERVICE_REQUEST_SOURCE_UNSPECIFIED,
                ProjectsLocationsAgentApiClientServiceRequestSourceEnum
                    .DIALOGFLOW_CONSOLE
              ];
            }
      };

  export declare interface ProjectsLocationsAgentExportNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentGetFulfillmentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentGetSettingsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
    requestSource?: ProjectsLocationsAgentApiClientRequestSource;
    serviceRequestSource?: ProjectsLocationsAgentApiClientServiceRequestSource;
  }

  export declare interface ProjectsLocationsAgentGetSmallTalkConfigNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
    languageCode?: string;
  }

  export declare interface ProjectsLocationsAgentGetSupportedLanguagesNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentGetTierNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
    disableCache?: boolean;
  }

  export declare interface ProjectsLocationsAgentGetUnassignedProjectsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
    filter?: string;
  }

  export declare interface ProjectsLocationsAgentGetValidationResultNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
    versionNumber?: number;
    languageCode?: string;
  }

  export declare interface ProjectsLocationsAgentGetVisibilityNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentImportNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentRestoreNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSearchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    projectIds?: Array<string>;
    requestSource?: ProjectsLocationsAgentApiClientRequestSource;
  }

  export declare interface ProjectsLocationsAgentTierNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentTrainNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentUpdateFulfillmentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsLocationsAgentUpdateSettingsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentUpdateSmallTalkConfigNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentApiClient$Xgafv;
  }

  export class ProjectsLocationsAgentApiClientImpl implements
      ProjectsLocationsAgentApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    export(
        parent: string, $requestBody: ExportAgentRequest_,
        namedParameters: ProjectsLocationsAgentExportNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.export',
        path: `/${this.gapiVersion}/${parent}/agent:export`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    getFulfillment(
        name: string,
        namedParameters: ProjectsLocationsAgentGetFulfillmentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Fulfillment_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/fulfillment$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Fulfillment_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.getFulfillment',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Fulfillment_
      });
    }

    getSettings(
        parent: string,
        namedParameters: ProjectsLocationsAgentGetSettingsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AgentSettings_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<AgentSettings_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.getSettings',
        path: `/${this.gapiVersion}/${parent}/agent:getSettings`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AgentSettings_
      });
    }

    getSmallTalkConfig(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentGetSmallTalkConfigNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SmallTalkConfig_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SmallTalkConfig_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.getSmallTalkConfig',
        path: `/${this.gapiVersion}/${parent}/smallTalkConfig`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SmallTalkConfig_
      });
    }

    getSupportedLanguages(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentGetSupportedLanguagesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GetSupportedLanguagesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GetSupportedLanguagesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.getSupportedLanguages',
        path: `/${this.gapiVersion}/${parent}/agent:getSupportedLanguages`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GetSupportedLanguagesResponse_
      });
    }

    getTier(
        parent: string,
        namedParameters: ProjectsLocationsAgentGetTierNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AgentTier_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<AgentTier_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.getTier',
        path: `/${this.gapiVersion}/${parent}/agent/tier`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AgentTier_
      });
    }

    getUnassignedProjects(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentGetUnassignedProjectsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GetUnassignedProjectsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GetUnassignedProjectsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.getUnassignedProjects',
        path: `/${this.gapiVersion}/${parent}/agent:getUnassignedProjects`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GetUnassignedProjectsResponse_
      });
    }

    getValidationResult(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentGetValidationResultNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ValidationResult_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ValidationResult_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.getValidationResult',
        path: `/${this.gapiVersion}/${parent}/agent/validationResult`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ValidationResult_
      });
    }

    getVisibility(
        parent: string,
        namedParameters: ProjectsLocationsAgentGetVisibilityNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AgentVisibility_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<AgentVisibility_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.getVisibility',
        path: `/${this.gapiVersion}/${parent}/agent/visibility`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AgentVisibility_
      });
    }

  import(
      parent: string,
      $requestBody: ImportAgentRequest_,
      namedParameters: ProjectsLocationsAgentImportNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.locations.agent.import',
      path: `/${this.gapiVersion}/${parent}/agent:import`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  restore(
      parent: string,
      $requestBody: RestoreAgentRequest_,
      namedParameters: ProjectsLocationsAgentRestoreNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.locations.agent.restore',
      path: `/${this.gapiVersion}/${parent}/agent:restore`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  search(
      parent: string,
      namedParameters: ProjectsLocationsAgentSearchNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<SearchAgentsResponse_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<SearchAgentsResponse_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.locations.agent.search',
      path: `/${this.gapiVersion}/${parent}/agent:search`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: SearchAgentsResponse_
    });
  }

  tier(
      parent: string,
      $requestBody: AgentTier_,
      namedParameters: ProjectsLocationsAgentTierNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<AgentTier_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

    return this.$apiClient.$request<AgentTier_>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.locations.agent.tier',
      path: `/${this.gapiVersion}/${parent}/agent/tier`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: AgentTier_
    });
  }

  train(
      parent: string,
      $requestBody: TrainAgentRequest_,
      namedParameters: ProjectsLocationsAgentTrainNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.locations.agent.train',
      path: `/${this.gapiVersion}/${parent}/agent:train`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  updateFulfillment(
      name: string,
      $requestBody: Fulfillment_,
      namedParameters: ProjectsLocationsAgentUpdateFulfillmentNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<Fulfillment_> {
    this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+/locations/[^/]+/agent/fulfillment$'));

    return this.$apiClient.$request<Fulfillment_>({
      body: $requestBody,
      httpMethod: 'PATCH',
      methodId: 'dialogflow.projects.locations.agent.updateFulfillment',
      path: `/${this.gapiVersion}/${name}`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: Fulfillment_
    });
  }

  updateSettings(
      parent: string,
      $requestBody: UpdateAgentSettingsRequest_,
      namedParameters: ProjectsLocationsAgentUpdateSettingsNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<AgentSettings_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

    return this.$apiClient.$request<AgentSettings_>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.locations.agent.updateSettings',
      path: `/${this.gapiVersion}/${parent}/agent:updateSettings`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: AgentSettings_
    });
  }

  updateSmallTalkConfig(
      parent: string,
      $requestBody: UpdateSmallTalkConfigRequest_,
      namedParameters: ProjectsLocationsAgentUpdateSmallTalkConfigNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<SmallTalkConfig_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

    return this.$apiClient.$request<SmallTalkConfig_>({
      body: $requestBody,
      httpMethod: 'PATCH',
      methodId: 'dialogflow.projects.locations.agent.updateSmallTalkConfig',
      path: `/${this.gapiVersion}/${parent}/smallTalkConfig`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: SmallTalkConfig_
    });
  }
}

export abstract class ProjectsLocationsAgentApiClient {
  constructor() {
  }

  abstract export(
      parent: string,
      $requestBody: ExportAgentRequest_,
      namedParameters?: ProjectsLocationsAgentExportNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract getFulfillment(
      name: string,
      namedParameters?: ProjectsLocationsAgentGetFulfillmentNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<Fulfillment_>;

  abstract getSettings(
      parent: string,
      namedParameters?: ProjectsLocationsAgentGetSettingsNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AgentSettings_>;

  abstract getSmallTalkConfig(
      parent: string,
      namedParameters?: ProjectsLocationsAgentGetSmallTalkConfigNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<SmallTalkConfig_>;

  abstract getSupportedLanguages(
      parent: string,
      namedParameters?: ProjectsLocationsAgentGetSupportedLanguagesNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GetSupportedLanguagesResponse_>;

  abstract getTier(
      parent: string,
      namedParameters?: ProjectsLocationsAgentGetTierNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AgentTier_>;

  abstract getUnassignedProjects(
      parent: string,
      namedParameters?: ProjectsLocationsAgentGetUnassignedProjectsNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GetUnassignedProjectsResponse_>;

  abstract getValidationResult(
      parent: string,
      namedParameters?: ProjectsLocationsAgentGetValidationResultNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<ValidationResult_>;

  abstract getVisibility(
      parent: string,
      namedParameters?: ProjectsLocationsAgentGetVisibilityNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AgentVisibility_>;

  abstract import(
      parent: string,
      $requestBody: ImportAgentRequest_,
      namedParameters?: ProjectsLocationsAgentImportNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract restore(
      parent: string,
      $requestBody: RestoreAgentRequest_,
      namedParameters?: ProjectsLocationsAgentRestoreNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract search(
      parent: string,
      namedParameters?: ProjectsLocationsAgentSearchNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<SearchAgentsResponse_>;

  abstract tier(
      parent: string,
      $requestBody: AgentTier_,
      namedParameters?: ProjectsLocationsAgentTierNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AgentTier_>;

  abstract train(
      parent: string,
      $requestBody: TrainAgentRequest_,
      namedParameters?: ProjectsLocationsAgentTrainNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract updateFulfillment(
      name: string,
      $requestBody: Fulfillment_,
      namedParameters?: ProjectsLocationsAgentUpdateFulfillmentNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<Fulfillment_>;

  abstract updateSettings(
      parent: string,
      $requestBody: UpdateAgentSettingsRequest_,
      namedParameters?: ProjectsLocationsAgentUpdateSettingsNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<AgentSettings_>;

  abstract updateSmallTalkConfig(
      parent: string,
      $requestBody: UpdateSmallTalkConfigRequest_,
      namedParameters?: ProjectsLocationsAgentUpdateSmallTalkConfigNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<SmallTalkConfig_>;
}

export type ProjectsLocationsAgentEntityTypesApiClient$Xgafv = '1' | '2';

  export interface IProjectsLocationsAgentEntityTypesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentEntityTypesApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentEntityTypesApiClient$XgafvEnum:
      IProjectsLocationsAgentEntityTypesApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentEntityTypesApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentEntityTypesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentEntityTypesApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentEntityTypesApiClient$XgafvEnum[1],
                ProjectsLocationsAgentEntityTypesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentEntityTypesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentEntityTypesApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentEntityTypesApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentEntityTypesApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentEntityTypesApiClientAlt;

    values(): Array<ProjectsLocationsAgentEntityTypesApiClientAlt>;
  }

  export const ProjectsLocationsAgentEntityTypesApiClientAltEnum:
      IProjectsLocationsAgentEntityTypesApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentEntityTypesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentEntityTypesApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentEntityTypesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentEntityTypesApiClientAlt> {
              return [
                ProjectsLocationsAgentEntityTypesApiClientAltEnum.JSON,
                ProjectsLocationsAgentEntityTypesApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentEntityTypesApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsAgentEntityTypesApiClientView =
      'LIST_ENTITY_TYPES_VIEW_UNSPECIFIED'|'LIST_ENTITY_TYPES_VIEW_SYSTEM';

  export interface IProjectsLocationsAgentEntityTypesApiClientViewEnum {
    readonly LIST_ENTITY_TYPES_VIEW_UNSPECIFIED:
        ProjectsLocationsAgentEntityTypesApiClientView;
    readonly LIST_ENTITY_TYPES_VIEW_SYSTEM:
        ProjectsLocationsAgentEntityTypesApiClientView;

    values(): Array<ProjectsLocationsAgentEntityTypesApiClientView>;
  }

  export const ProjectsLocationsAgentEntityTypesApiClientViewEnum:
      IProjectsLocationsAgentEntityTypesApiClientViewEnum = {
        LIST_ENTITY_TYPES_VIEW_SYSTEM: <
            ProjectsLocationsAgentEntityTypesApiClientView>'LIST_ENTITY_TYPES_VIEW_SYSTEM',
        LIST_ENTITY_TYPES_VIEW_UNSPECIFIED: <
            ProjectsLocationsAgentEntityTypesApiClientView>'LIST_ENTITY_TYPES_VIEW_UNSPECIFIED',
        values():
            Array<ProjectsLocationsAgentEntityTypesApiClientView> {
              return [
                ProjectsLocationsAgentEntityTypesApiClientViewEnum
                    .LIST_ENTITY_TYPES_VIEW_UNSPECIFIED,
                ProjectsLocationsAgentEntityTypesApiClientViewEnum
                    .LIST_ENTITY_TYPES_VIEW_SYSTEM
              ];
            }
      };

  export declare interface ProjectsLocationsAgentEntityTypesBatchCopyNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEntityTypesBatchDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEntityTypesBatchMoveNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEntityTypesBatchUpdateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEntityTypesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
    languageCode?: string;
  }

  export declare interface ProjectsLocationsAgentEntityTypesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEntityTypesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
    languageCode?: string;
  }

  export declare interface ProjectsLocationsAgentEntityTypesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
    languageCode?: string;
    pageSize?: number;
    pageToken?: string;
    view?: ProjectsLocationsAgentEntityTypesApiClientView;
  }

  export declare interface ProjectsLocationsAgentEntityTypesMatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEntityTypesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesApiClient$Xgafv;
    languageCode?: string;
    updateMask?: string;
  }

  export class ProjectsLocationsAgentEntityTypesApiClientImpl implements
      ProjectsLocationsAgentEntityTypesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchCopy(
        parent: string, $requestBody: BatchCopyEntityTypesRequest_,
        namedParameters:
            ProjectsLocationsAgentEntityTypesBatchCopyNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.entityTypes.batchCopy',
        path: `/${this.gapiVersion}/${parent}/entityTypes:batchCopy`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchDelete(
        parent: string, $requestBody: BatchDeleteEntityTypesRequest_,
        namedParameters:
            ProjectsLocationsAgentEntityTypesBatchDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.entityTypes.batchDelete',
        path: `/${this.gapiVersion}/${parent}/entityTypes:batchDelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchMove(
        parent: string, $requestBody: BatchMoveEntityTypesRequest_,
        namedParameters:
            ProjectsLocationsAgentEntityTypesBatchMoveNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.entityTypes.batchMove',
        path: `/${this.gapiVersion}/${parent}/entityTypes:batchMove`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchUpdate(
        parent: string, $requestBody: BatchUpdateEntityTypesRequest_,
        namedParameters:
            ProjectsLocationsAgentEntityTypesBatchUpdateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.entityTypes.batchUpdate',
        path: `/${this.gapiVersion}/${parent}/entityTypes:batchUpdate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    create(
        parent: string, $requestBody: EntityType_,
        namedParameters: ProjectsLocationsAgentEntityTypesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<EntityType_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<EntityType_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.entityTypes.create',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: EntityType_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsLocationsAgentEntityTypesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.agent.entityTypes.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsAgentEntityTypesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<EntityType_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<EntityType_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.entityTypes.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: EntityType_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAgentEntityTypesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListEntityTypesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListEntityTypesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.entityTypes.list',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListEntityTypesResponse_
      });
    }

    match(
        name: string, $requestBody: MatchEntityTypeRequest_,
        namedParameters: ProjectsLocationsAgentEntityTypesMatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<MatchEntityTypeResponse_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/entityTypes/[^/]+$'));

      return this.$apiClient.$request<MatchEntityTypeResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.entityTypes.match',
        path: `/${this.gapiVersion}/${name}:match`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: MatchEntityTypeResponse_
      });
    }

    patch(
        name: string, $requestBody: EntityType_,
        namedParameters: ProjectsLocationsAgentEntityTypesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<EntityType_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/entityTypes/[^/]+$'));

      return this.$apiClient.$request<EntityType_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.agent.entityTypes.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: EntityType_
      });
    }
  }

  export abstract class ProjectsLocationsAgentEntityTypesApiClient {
    constructor() {}

    abstract batchCopy(
        parent: string, $requestBody: BatchCopyEntityTypesRequest_,
        namedParameters?:
            ProjectsLocationsAgentEntityTypesBatchCopyNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchDelete(
        parent: string, $requestBody: BatchDeleteEntityTypesRequest_,
        namedParameters?:
            ProjectsLocationsAgentEntityTypesBatchDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchMove(
        parent: string, $requestBody: BatchMoveEntityTypesRequest_,
        namedParameters?:
            ProjectsLocationsAgentEntityTypesBatchMoveNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchUpdate(
        parent: string, $requestBody: BatchUpdateEntityTypesRequest_,
        namedParameters?:
            ProjectsLocationsAgentEntityTypesBatchUpdateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract create(
        parent: string, $requestBody: EntityType_,
        namedParameters?:
            ProjectsLocationsAgentEntityTypesCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<EntityType_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentEntityTypesDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsAgentEntityTypesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<EntityType_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAgentEntityTypesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListEntityTypesResponse_>;

    abstract match(
        name: string, $requestBody: MatchEntityTypeRequest_,
        namedParameters?: ProjectsLocationsAgentEntityTypesMatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<MatchEntityTypeResponse_>;

    abstract patch(
        name: string, $requestBody: EntityType_,
        namedParameters?: ProjectsLocationsAgentEntityTypesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<EntityType_>;
  }

  export type ProjectsLocationsAgentEntityTypesEntitiesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsAgentEntityTypesEntitiesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentEntityTypesEntitiesApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentEntityTypesEntitiesApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentEntityTypesEntitiesApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentEntityTypesEntitiesApiClient$XgafvEnum:
      IProjectsLocationsAgentEntityTypesEntitiesApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentEntityTypesEntitiesApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentEntityTypesEntitiesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentEntityTypesEntitiesApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentEntityTypesEntitiesApiClient$XgafvEnum[1],
                ProjectsLocationsAgentEntityTypesEntitiesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentEntityTypesEntitiesApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt;

    values(): Array<ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt>;
  }

  export const ProjectsLocationsAgentEntityTypesEntitiesApiClientAltEnum:
      IProjectsLocationsAgentEntityTypesEntitiesApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt> {
              return [
                ProjectsLocationsAgentEntityTypesEntitiesApiClientAltEnum.JSON,
                ProjectsLocationsAgentEntityTypesEntitiesApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentEntityTypesEntitiesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentEntityTypesEntitiesBatchCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesEntitiesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEntityTypesEntitiesBatchDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesEntitiesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEntityTypesEntitiesBatchUpdateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEntityTypesEntitiesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEntityTypesEntitiesApiClient$Xgafv;
  }

  export class ProjectsLocationsAgentEntityTypesEntitiesApiClientImpl implements
      ProjectsLocationsAgentEntityTypesEntitiesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchCreate(
        parent: string, $requestBody: BatchCreateEntitiesRequest_,
        namedParameters:
            ProjectsLocationsAgentEntityTypesEntitiesBatchCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/entityTypes/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.entityTypes.entities.batchCreate',
        path: `/${this.gapiVersion}/${parent}/entities:batchCreate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchDelete(
        parent: string, $requestBody: BatchDeleteEntitiesRequest_,
        namedParameters:
            ProjectsLocationsAgentEntityTypesEntitiesBatchDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/entityTypes/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.entityTypes.entities.batchDelete',
        path: `/${this.gapiVersion}/${parent}/entities:batchDelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchUpdate(
        parent: string, $requestBody: BatchUpdateEntitiesRequest_,
        namedParameters:
            ProjectsLocationsAgentEntityTypesEntitiesBatchUpdateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/entityTypes/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.entityTypes.entities.batchUpdate',
        path: `/${this.gapiVersion}/${parent}/entities:batchUpdate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }
  }

  export abstract class ProjectsLocationsAgentEntityTypesEntitiesApiClient {
    constructor() {}

    abstract batchCreate(
        parent: string, $requestBody: BatchCreateEntitiesRequest_,
        namedParameters?:
            ProjectsLocationsAgentEntityTypesEntitiesBatchCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchDelete(
        parent: string, $requestBody: BatchDeleteEntitiesRequest_,
        namedParameters?:
            ProjectsLocationsAgentEntityTypesEntitiesBatchDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchUpdate(
        parent: string, $requestBody: BatchUpdateEntitiesRequest_,
        namedParameters?:
            ProjectsLocationsAgentEntityTypesEntitiesBatchUpdateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;
  }

  export type ProjectsLocationsAgentEnvironmentsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAgentEnvironmentsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentEnvironmentsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentEnvironmentsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentEnvironmentsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentEnvironmentsApiClient$XgafvEnum:
      IProjectsLocationsAgentEnvironmentsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentEnvironmentsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentEnvironmentsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentEnvironmentsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentEnvironmentsApiClient$XgafvEnum[1],
                ProjectsLocationsAgentEnvironmentsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentEnvironmentsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentEnvironmentsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentEnvironmentsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentEnvironmentsApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentEnvironmentsApiClientAlt;

    values(): Array<ProjectsLocationsAgentEnvironmentsApiClientAlt>;
  }

  export const ProjectsLocationsAgentEnvironmentsApiClientAltEnum:
      IProjectsLocationsAgentEnvironmentsApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentEnvironmentsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentEnvironmentsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentEnvironmentsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentEnvironmentsApiClientAlt> {
              return [
                ProjectsLocationsAgentEnvironmentsApiClientAltEnum.JSON,
                ProjectsLocationsAgentEnvironmentsApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentEnvironmentsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentEnvironmentsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsApiClient$Xgafv;
    environmentId?: string;
    enableEnvironmentLimit?: boolean;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsGetHistoryNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsApiClient$Xgafv;
    updateMask?: string;
    allowLoadToDraftAndDiscardChanges?: boolean;
  }

  export class ProjectsLocationsAgentEnvironmentsApiClientImpl implements
      ProjectsLocationsAgentEnvironmentsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Environment_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsCreateNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Environment_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<Environment_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.environments.create',
        path: `/${this.gapiVersion}/${parent}/environments`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Environment_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsDeleteNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.agent.environments.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsAgentEnvironmentsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Environment_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Environment_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.environments.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Environment_
      });
    }

    getHistory(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsGetHistoryNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<EnvironmentHistory_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<EnvironmentHistory_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.environments.getHistory',
        path: `/${this.gapiVersion}/${parent}/history`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: EnvironmentHistory_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAgentEnvironmentsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListEnvironmentsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListEnvironmentsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.environments.list',
        path: `/${this.gapiVersion}/${parent}/environments`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListEnvironmentsResponse_
      });
    }

    patch(
        name: string, $requestBody: Environment_,
        namedParameters: ProjectsLocationsAgentEnvironmentsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Environment_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+$'));

      return this.$apiClient.$request<Environment_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.agent.environments.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Environment_
      });
    }
  }

  export abstract class ProjectsLocationsAgentEnvironmentsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Environment_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Environment_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsAgentEnvironmentsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Environment_>;

    abstract getHistory(
        parent: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsGetHistoryNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<EnvironmentHistory_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAgentEnvironmentsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListEnvironmentsResponse_>;

    abstract patch(
        name: string, $requestBody: Environment_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Environment_>;
  }

  export type ProjectsLocationsAgentEnvironmentsIntentsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsAgentEnvironmentsIntentsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentEnvironmentsIntentsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentEnvironmentsIntentsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentEnvironmentsIntentsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentEnvironmentsIntentsApiClient$XgafvEnum:
      IProjectsLocationsAgentEnvironmentsIntentsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentEnvironmentsIntentsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentEnvironmentsIntentsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentEnvironmentsIntentsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentEnvironmentsIntentsApiClient$XgafvEnum[1],
                ProjectsLocationsAgentEnvironmentsIntentsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentEnvironmentsIntentsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentEnvironmentsIntentsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentEnvironmentsIntentsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentEnvironmentsIntentsApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentEnvironmentsIntentsApiClientAlt;

    values(): Array<ProjectsLocationsAgentEnvironmentsIntentsApiClientAlt>;
  }

  export const ProjectsLocationsAgentEnvironmentsIntentsApiClientAltEnum:
      IProjectsLocationsAgentEnvironmentsIntentsApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentEnvironmentsIntentsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentEnvironmentsIntentsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentEnvironmentsIntentsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentEnvironmentsIntentsApiClientAlt> {
              return [
                ProjectsLocationsAgentEnvironmentsIntentsApiClientAltEnum.JSON,
                ProjectsLocationsAgentEnvironmentsIntentsApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentEnvironmentsIntentsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentView =
      'INTENT_VIEW_UNSPECIFIED'|'INTENT_VIEW_FULL';

  export interface IProjectsLocationsAgentEnvironmentsIntentsApiClientIntentViewEnum {
    readonly INTENT_VIEW_UNSPECIFIED:
        ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentView;
    readonly INTENT_VIEW_FULL:
        ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentView;

    values():
        Array<ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentView>;
  }

  export const ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentViewEnum:
      IProjectsLocationsAgentEnvironmentsIntentsApiClientIntentViewEnum = {
        INTENT_VIEW_FULL: <
            ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentView>'INTENT_VIEW_FULL',
        INTENT_VIEW_UNSPECIFIED: <
            ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentView>'INTENT_VIEW_UNSPECIFIED',
        values():
            Array<
                ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentView> {
              return [
                ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentViewEnum
                    .INTENT_VIEW_UNSPECIFIED,
                ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentViewEnum
                    .INTENT_VIEW_FULL
              ];
            }
      };

  export declare interface ProjectsLocationsAgentEnvironmentsIntentsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsIntentsApiClient$Xgafv;
    languageCode?: string;
    intentView?: ProjectsLocationsAgentEnvironmentsIntentsApiClientIntentView;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsAgentEnvironmentsIntentsApiClientImpl implements
      ProjectsLocationsAgentEnvironmentsIntentsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsIntentsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListIntentsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListIntentsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.agent.environments.intents.list',
        path: `/${this.gapiVersion}/${parent}/intents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListIntentsResponse_
      });
    }
  }

  export abstract class ProjectsLocationsAgentEnvironmentsIntentsApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsIntentsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListIntentsResponse_>;
  }

  export type ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$XgafvEnum:
      IProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt;
    readonly PROTO:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt;

    values(): Array<
        ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt>;
  }

  export const ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAltEnum:
      IProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAltEnum = {
        JSON: <
            ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt> {
              return [
                ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAltEnum
                    .JSON,
                ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentEnvironmentsSessionsConversationsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsSessionsConversationsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsSessionsConversationsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient$Xgafv;
    fullUpdate?: boolean;
  }

  export class
      ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClientImpl
          implements
              ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: CreateSessionConversationRequest_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsSessionsConversationsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionConversation_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<SessionConversation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.environments.sessions.conversations.create',
        path: `/${this.gapiVersion}/${parent}/conversations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionConversation_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsSessionsConversationsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/sessions/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.agent.environments.sessions.conversations.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    patch(
        name: string, $requestBody: SessionConversation_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsSessionsConversationsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionConversation_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/sessions/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<SessionConversation_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.locations.agent.environments.sessions.conversations.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionConversation_
      });
    }
  }

  export abstract class
      ProjectsLocationsAgentEnvironmentsSessionsConversationsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: CreateSessionConversationRequest_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsSessionsConversationsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionConversation_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsSessionsConversationsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract patch(
        name: string, $requestBody: SessionConversation_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsSessionsConversationsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionConversation_>;
  }

  export type ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$XgafvEnum:
      IProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt;
    readonly PROTO:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt;

    values(): Array<
        ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt>;
  }

  export const ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum:
      IProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum = {
        JSON: <
            ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt> {
              return [
                ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum
                    .JSON,
                ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsListNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    shouldReclassify?: boolean;
  }

  export class
      ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClientImpl
          implements
              ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListInteractionsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/sessions/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListInteractionsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.agent.environments.sessions.conversations.interactions.list',
        path: `/${this.gapiVersion}/${parent}/interactions`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListInteractionsResponse_
      });
    }
  }

  export abstract class
      ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsSessionsConversationsInteractionsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListInteractionsResponse_>;
  }

  export type ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv;

    values():
        Array<ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$XgafvEnum:
      IProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt;

    values():
        Array<ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt>;
  }

  export const ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAltEnum:
      IProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAltEnum = {
        JSON:
            <ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt> {
              return [
                ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAltEnum
                    .JSON,
                ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsDeleteContextsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsDetectIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsFulfillIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsMatchIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsPushFulfillmentResultNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsSetAllContextsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient$Xgafv;
  }

  export class ProjectsLocationsAgentEnvironmentsUsersSessionsApiClientImpl
      implements ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    deleteContexts(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsDeleteContextsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.deleteContexts',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    detectIntent(
        session: string, $requestBody: DetectIntentRequest_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsDetectIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<DetectIntentResponse_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<DetectIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.detectIntent',
        path: `/${this.gapiVersion}/${session}:detectIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: DetectIntentResponse_
      });
    }

    fulfillIntent(
        session: string, $requestBody: FulfillIntentRequest_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsFulfillIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<DetectIntentResponse_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<DetectIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.fulfillIntent',
        path: `/${this.gapiVersion}/${session}:fulfillIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: DetectIntentResponse_
      });
    }

    matchIntent(
        session: string, $requestBody: MatchIntentRequest_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsMatchIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<MatchIntentResponse_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<MatchIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.matchIntent',
        path: `/${this.gapiVersion}/${session}:matchIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: MatchIntentResponse_
      });
    }

    pushFulfillmentResult(
        session: string, $requestBody: FulfillmentResult_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsPushFulfillmentResultNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<FulfillmentResult_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<FulfillmentResult_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.pushFulfillmentResult',
        path: `/${this.gapiVersion}/${session}:pushFulfillmentResult`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: FulfillmentResult_
      });
    }

    setAllContexts(
        parent: string, $requestBody: SetAllContextsRequest_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsSetAllContextsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SetAllContextsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<SetAllContextsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.setAllContexts',
        path: `/${this.gapiVersion}/${parent}:setAllContexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SetAllContextsResponse_
      });
    }
  }

  export abstract class
      ProjectsLocationsAgentEnvironmentsUsersSessionsApiClient {
    constructor() {}

    abstract deleteContexts(
        parent: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsDeleteContextsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract detectIntent(
        session: string, $requestBody: DetectIntentRequest_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsDetectIntentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<DetectIntentResponse_>;

    abstract fulfillIntent(
        session: string, $requestBody: FulfillIntentRequest_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsFulfillIntentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<DetectIntentResponse_>;

    abstract matchIntent(
        session: string, $requestBody: MatchIntentRequest_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsMatchIntentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<MatchIntentResponse_>;

    abstract pushFulfillmentResult(
        session: string, $requestBody: FulfillmentResult_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsPushFulfillmentResultNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<FulfillmentResult_>;

    abstract setAllContexts(
        parent: string, $requestBody: SetAllContextsRequest_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsSetAllContextsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SetAllContextsResponse_>;
  }

  export type ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$XgafvEnum:
      IProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    readonly PROTO:
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt;

    values(): Array<
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt>;
  }

  export const ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum:
      IProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum = {
        JSON: <
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt> {
              return [
                ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum
                    .JSON,
                ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsContextsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsContextsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsContextsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsContextsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsContextsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient$Xgafv;
    updateMask?: string;
  }

  export class
      ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClientImpl
          implements
              ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Context_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.contexts.create',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/contexts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.contexts.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/contexts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.contexts.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListContextsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListContextsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.contexts.list',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListContextsResponse_
      });
    }

    patch(
        name: string, $requestBody: Context_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/contexts/[^/]+$'));

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.contexts.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }
  }

  export abstract class
      ProjectsLocationsAgentEnvironmentsUsersSessionsContextsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Context_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListContextsResponse_>;

    abstract patch(
        name: string, $requestBody: Context_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsContextsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;
  }

  export type ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$XgafvEnum:
      IProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$XgafvEnum
                    [1],
                ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    readonly PROTO:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;

    values(): Array<
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt>;
  }

  export const ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum:
      IProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum = {
        JSON: <
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt> {
              return [
                ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum
                    .JSON,
                ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesCreateNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesDeleteNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesGetNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesListNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesPatchNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient$Xgafv;
    updateMask?: string;
  }

  export class
      ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClientImpl
          implements
              ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: SessionEntityType_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.entityTypes.create',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.entityTypes.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.entityTypes.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListSessionEntityTypesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListSessionEntityTypesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.entityTypes.list',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListSessionEntityTypesResponse_
      });
    }

    patch(
        name: string, $requestBody: SessionEntityType_,
        namedParameters:
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/environments/[^/]+/users/[^/]+/sessions/[^/]+/entityTypes/[^/]+$'));

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.locations.agent.environments.users.sessions.entityTypes.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }
  }

  export abstract class
      ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: SessionEntityType_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListSessionEntityTypesResponse_>;

    abstract patch(
        name: string, $requestBody: SessionEntityType_,
        namedParameters?:
            ProjectsLocationsAgentEnvironmentsUsersSessionsEntityTypesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;
  }

  export type ProjectsLocationsAgentIntegrationsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAgentIntegrationsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentIntegrationsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentIntegrationsApiClient$XgafvEnum:
      IProjectsLocationsAgentIntegrationsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentIntegrationsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentIntegrationsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentIntegrationsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentIntegrationsApiClient$XgafvEnum[1],
                ProjectsLocationsAgentIntegrationsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentIntegrationsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentIntegrationsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentIntegrationsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentIntegrationsApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentIntegrationsApiClientAlt;

    values(): Array<ProjectsLocationsAgentIntegrationsApiClientAlt>;
  }

  export const ProjectsLocationsAgentIntegrationsApiClientAltEnum:
      IProjectsLocationsAgentIntegrationsApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentIntegrationsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentIntegrationsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentIntegrationsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentIntegrationsApiClientAlt> {
              return [
                ProjectsLocationsAgentIntegrationsApiClientAltEnum.JSON,
                ProjectsLocationsAgentIntegrationsApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentIntegrationsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsAgentIntegrationsApiClientIntegrationView =
      'INTEGRATION_VIEW_UNSPECIFIED'|'INTEGRATION_VIEW_FULL'|
      'INTEGRATION_VIEW_BASIC';

  export interface IProjectsLocationsAgentIntegrationsApiClientIntegrationViewEnum {
    readonly INTEGRATION_VIEW_UNSPECIFIED:
        ProjectsLocationsAgentIntegrationsApiClientIntegrationView;
    readonly INTEGRATION_VIEW_FULL:
        ProjectsLocationsAgentIntegrationsApiClientIntegrationView;
    readonly INTEGRATION_VIEW_BASIC:
        ProjectsLocationsAgentIntegrationsApiClientIntegrationView;

    values(): Array<ProjectsLocationsAgentIntegrationsApiClientIntegrationView>;
  }

  export const ProjectsLocationsAgentIntegrationsApiClientIntegrationViewEnum:
      IProjectsLocationsAgentIntegrationsApiClientIntegrationViewEnum = {
        INTEGRATION_VIEW_BASIC: <
            ProjectsLocationsAgentIntegrationsApiClientIntegrationView>'INTEGRATION_VIEW_BASIC',
        INTEGRATION_VIEW_FULL: <
            ProjectsLocationsAgentIntegrationsApiClientIntegrationView>'INTEGRATION_VIEW_FULL',
        INTEGRATION_VIEW_UNSPECIFIED: <
            ProjectsLocationsAgentIntegrationsApiClientIntegrationView>'INTEGRATION_VIEW_UNSPECIFIED',
        values():
            Array<ProjectsLocationsAgentIntegrationsApiClientIntegrationView> {
              return [
                ProjectsLocationsAgentIntegrationsApiClientIntegrationViewEnum
                    .INTEGRATION_VIEW_UNSPECIFIED,
                ProjectsLocationsAgentIntegrationsApiClientIntegrationViewEnum
                    .INTEGRATION_VIEW_FULL,
                ProjectsLocationsAgentIntegrationsApiClientIntegrationViewEnum
                    .INTEGRATION_VIEW_BASIC
              ];
            }
      };

  export declare interface ProjectsLocationsAgentIntegrationsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntegrationsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntegrationsFinalizeNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntegrationsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntegrationsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
    integrationView?:
        ProjectsLocationsAgentIntegrationsApiClientIntegrationView;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAgentIntegrationsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsLocationsAgentIntegrationsRemoveNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntegrationsRevokeNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntegrationsSignupNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntegrationsStartNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntegrationsStopNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntegrationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntegrationsApiClient$Xgafv;
  }

  export class ProjectsLocationsAgentIntegrationsApiClientImpl implements
      ProjectsLocationsAgentIntegrationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Integration_,
        namedParameters:
            ProjectsLocationsAgentIntegrationsCreateNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Integration_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<Integration_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.integrations.create',
        path: `/${this.gapiVersion}/${parent}/integrations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Integration_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsAgentIntegrationsDeleteNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/integrations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.agent.integrations.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    finalize(
        parent: string, $requestBody: FinalizeIntegrationRequest_,
        namedParameters:
            ProjectsLocationsAgentIntegrationsFinalizeNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Integration_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<Integration_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.integrations.finalize',
        path: `/${this.gapiVersion}/${parent}/integrations:finalize`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Integration_
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsAgentIntegrationsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Integration_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/integrations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Integration_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.integrations.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Integration_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAgentIntegrationsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListIntegrationsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListIntegrationsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.integrations.list',
        path: `/${this.gapiVersion}/${parent}/integrations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListIntegrationsResponse_
      });
    }

    patch(
        name: string, $requestBody: Integration_,
        namedParameters: ProjectsLocationsAgentIntegrationsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Integration_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/integrations/[^/]+$'));

      return this.$apiClient.$request<Integration_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.agent.integrations.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Integration_
      });
    }

    remove(
        parent: string, $requestBody: RemoveIntegrationRequest_,
        namedParameters:
            ProjectsLocationsAgentIntegrationsRemoveNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.integrations.remove',
        path: `/${this.gapiVersion}/${parent}/integrations:remove`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    revoke(
        name: string, $requestBody: RevokeIntegrationRequest_,
        namedParameters:
            ProjectsLocationsAgentIntegrationsRevokeNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<RevokeIntegrationResponse_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/integrations/[^/]+$'));

      return this.$apiClient.$request<RevokeIntegrationResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.integrations.revoke',
        path: `/${this.gapiVersion}/${name}:revoke`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: RevokeIntegrationResponse_
      });
    }

    signup(
        parent: string, $requestBody: SignupIntegrationRequest_,
        namedParameters:
            ProjectsLocationsAgentIntegrationsSignupNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SignupIntegrationResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<SignupIntegrationResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.integrations.signup',
        path: `/${this.gapiVersion}/${parent}/integrations:signup`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SignupIntegrationResponse_
      });
    }

    start(
        name: string, $requestBody: StartIntegrationRequest_,
        namedParameters: ProjectsLocationsAgentIntegrationsStartNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<StartIntegrationResponse_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/integrations/[^/]+$'));

      return this.$apiClient.$request<StartIntegrationResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.integrations.start',
        path: `/${this.gapiVersion}/${name}:start`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: StartIntegrationResponse_
      });
    }

    stop(
        name: string, $requestBody: StopIntegrationRequest_,
        namedParameters: ProjectsLocationsAgentIntegrationsStopNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<StopIntegrationResponse_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/integrations/[^/]+$'));

      return this.$apiClient.$request<StopIntegrationResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.integrations.stop',
        path: `/${this.gapiVersion}/${name}:stop`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: StopIntegrationResponse_
      });
    }
  }

  export abstract class ProjectsLocationsAgentIntegrationsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Integration_,
        namedParameters?:
            ProjectsLocationsAgentIntegrationsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Integration_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentIntegrationsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract finalize(
        parent: string, $requestBody: FinalizeIntegrationRequest_,
        namedParameters?:
            ProjectsLocationsAgentIntegrationsFinalizeNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Integration_>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsAgentIntegrationsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Integration_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAgentIntegrationsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListIntegrationsResponse_>;

    abstract patch(
        name: string, $requestBody: Integration_,
        namedParameters?:
            ProjectsLocationsAgentIntegrationsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Integration_>;

    abstract remove(
        parent: string, $requestBody: RemoveIntegrationRequest_,
        namedParameters?:
            ProjectsLocationsAgentIntegrationsRemoveNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract revoke(
        name: string, $requestBody: RevokeIntegrationRequest_,
        namedParameters?:
            ProjectsLocationsAgentIntegrationsRevokeNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<RevokeIntegrationResponse_>;

    abstract signup(
        parent: string, $requestBody: SignupIntegrationRequest_,
        namedParameters?:
            ProjectsLocationsAgentIntegrationsSignupNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SignupIntegrationResponse_>;

    abstract start(
        name: string, $requestBody: StartIntegrationRequest_,
        namedParameters?:
            ProjectsLocationsAgentIntegrationsStartNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<StartIntegrationResponse_>;

    abstract stop(
        name: string, $requestBody: StopIntegrationRequest_,
        namedParameters?: ProjectsLocationsAgentIntegrationsStopNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<StopIntegrationResponse_>;
  }

  export type ProjectsLocationsAgentIntentsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAgentIntentsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentIntentsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentIntentsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentIntentsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentIntentsApiClient$XgafvEnum:
      IProjectsLocationsAgentIntentsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentIntentsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentIntentsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentIntentsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentIntentsApiClient$XgafvEnum[1],
                ProjectsLocationsAgentIntentsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentIntentsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentIntentsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentIntentsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentIntentsApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentIntentsApiClientAlt;

    values(): Array<ProjectsLocationsAgentIntentsApiClientAlt>;
  }

  export const ProjectsLocationsAgentIntentsApiClientAltEnum:
      IProjectsLocationsAgentIntentsApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentIntentsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentIntentsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentIntentsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentIntentsApiClientAlt> {
              return [
                ProjectsLocationsAgentIntentsApiClientAltEnum.JSON,
                ProjectsLocationsAgentIntentsApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentIntentsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsAgentIntentsApiClientIntentView =
      'INTENT_VIEW_UNSPECIFIED'|'INTENT_VIEW_FULL';

  export interface IProjectsLocationsAgentIntentsApiClientIntentViewEnum {
    readonly INTENT_VIEW_UNSPECIFIED:
        ProjectsLocationsAgentIntentsApiClientIntentView;
    readonly INTENT_VIEW_FULL: ProjectsLocationsAgentIntentsApiClientIntentView;

    values(): Array<ProjectsLocationsAgentIntentsApiClientIntentView>;
  }

  export const ProjectsLocationsAgentIntentsApiClientIntentViewEnum:
      IProjectsLocationsAgentIntentsApiClientIntentViewEnum = {
        INTENT_VIEW_FULL: <
            ProjectsLocationsAgentIntentsApiClientIntentView>'INTENT_VIEW_FULL',
        INTENT_VIEW_UNSPECIFIED: <
            ProjectsLocationsAgentIntentsApiClientIntentView>'INTENT_VIEW_UNSPECIFIED',
        values():
            Array<ProjectsLocationsAgentIntentsApiClientIntentView> {
              return [
                ProjectsLocationsAgentIntentsApiClientIntentViewEnum
                    .INTENT_VIEW_UNSPECIFIED,
                ProjectsLocationsAgentIntentsApiClientIntentViewEnum
                    .INTENT_VIEW_FULL
              ];
            }
      };

  export declare interface ProjectsLocationsAgentIntentsAnnotateTrainingPhraseNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntentsBatchCopyNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntentsBatchDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntentsBatchMoveNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntentsBatchUpdateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentIntentsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntentsApiClient$Xgafv;
    languageCode?: string;
    intentView?: ProjectsLocationsAgentIntentsApiClientIntentView;
  }

  export declare interface ProjectsLocationsAgentIntentsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntentsApiClient$Xgafv;
    displayName?: string;
  }

  export declare interface ProjectsLocationsAgentIntentsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntentsApiClient$Xgafv;
    languageCode?: string;
    intentView?: ProjectsLocationsAgentIntentsApiClientIntentView;
  }

  export declare interface ProjectsLocationsAgentIntentsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntentsApiClient$Xgafv;
    languageCode?: string;
    intentView?: ProjectsLocationsAgentIntentsApiClientIntentView;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAgentIntentsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentIntentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentIntentsApiClient$Xgafv;
    languageCode?: string;
    updateMask?: string;
    intentView?: ProjectsLocationsAgentIntentsApiClientIntentView;
  }

  export class ProjectsLocationsAgentIntentsApiClientImpl implements
      ProjectsLocationsAgentIntentsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    annotateTrainingPhrase(
        name: string, $requestBody: AnnotateTrainingPhraseRequest_,
        namedParameters:
            ProjectsLocationsAgentIntentsAnnotateTrainingPhraseNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AnnotateTrainingPhraseResponse_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/intents/[^/]+$'));

      return this.$apiClient.$request<AnnotateTrainingPhraseResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.intents.annotateTrainingPhrase',
        path: `/${this.gapiVersion}/${name}:annotateTrainingPhrase`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AnnotateTrainingPhraseResponse_
      });
    }

    batchCopy(
        parent: string, $requestBody: BatchCopyIntentsRequest_,
        namedParameters: ProjectsLocationsAgentIntentsBatchCopyNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.intents.batchCopy',
        path: `/${this.gapiVersion}/${parent}/intents:batchCopy`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchDelete(
        parent: string, $requestBody: BatchDeleteIntentsRequest_,
        namedParameters:
            ProjectsLocationsAgentIntentsBatchDeleteNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.intents.batchDelete',
        path: `/${this.gapiVersion}/${parent}/intents:batchDelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchMove(
        parent: string, $requestBody: BatchMoveIntentsRequest_,
        namedParameters: ProjectsLocationsAgentIntentsBatchMoveNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.intents.batchMove',
        path: `/${this.gapiVersion}/${parent}/intents:batchMove`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchUpdate(
        parent: string, $requestBody: BatchUpdateIntentsRequest_,
        namedParameters:
            ProjectsLocationsAgentIntentsBatchUpdateNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.intents.batchUpdate',
        path: `/${this.gapiVersion}/${parent}/intents:batchUpdate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    create(
        parent: string, $requestBody: Intent_,
        namedParameters: ProjectsLocationsAgentIntentsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Intent_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<Intent_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.intents.create',
        path: `/${this.gapiVersion}/${parent}/intents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Intent_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsLocationsAgentIntentsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/intents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.agent.intents.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsAgentIntentsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Intent_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/intents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Intent_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.intents.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Intent_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAgentIntentsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListIntentsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListIntentsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.intents.list',
        path: `/${this.gapiVersion}/${parent}/intents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListIntentsResponse_
      });
    }

    patch(
        name: string, $requestBody: Intent_,
        namedParameters: ProjectsLocationsAgentIntentsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Intent_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/intents/[^/]+$'));

      return this.$apiClient.$request<Intent_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.agent.intents.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Intent_
      });
    }
  }

  export abstract class ProjectsLocationsAgentIntentsApiClient {
    constructor() {}

    abstract annotateTrainingPhrase(
        name: string, $requestBody: AnnotateTrainingPhraseRequest_,
        namedParameters?:
            ProjectsLocationsAgentIntentsAnnotateTrainingPhraseNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<AnnotateTrainingPhraseResponse_>;

    abstract batchCopy(
        parent: string, $requestBody: BatchCopyIntentsRequest_,
        namedParameters?: ProjectsLocationsAgentIntentsBatchCopyNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchDelete(
        parent: string, $requestBody: BatchDeleteIntentsRequest_,
        namedParameters?:
            ProjectsLocationsAgentIntentsBatchDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchMove(
        parent: string, $requestBody: BatchMoveIntentsRequest_,
        namedParameters?: ProjectsLocationsAgentIntentsBatchMoveNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchUpdate(
        parent: string, $requestBody: BatchUpdateIntentsRequest_,
        namedParameters?:
            ProjectsLocationsAgentIntentsBatchUpdateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract create(
        parent: string, $requestBody: Intent_,
        namedParameters?: ProjectsLocationsAgentIntentsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Intent_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsLocationsAgentIntentsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsAgentIntentsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Intent_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAgentIntentsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListIntentsResponse_>;

    abstract patch(
        name: string, $requestBody: Intent_,
        namedParameters?: ProjectsLocationsAgentIntentsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Intent_>;
  }

  export type ProjectsLocationsAgentSessionsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAgentSessionsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentSessionsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentSessionsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentSessionsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentSessionsApiClient$XgafvEnum:
      IProjectsLocationsAgentSessionsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentSessionsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentSessionsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentSessionsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentSessionsApiClient$XgafvEnum[1],
                ProjectsLocationsAgentSessionsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentSessionsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentSessionsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentSessionsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentSessionsApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentSessionsApiClientAlt;

    values(): Array<ProjectsLocationsAgentSessionsApiClientAlt>;
  }

  export const ProjectsLocationsAgentSessionsApiClientAltEnum:
      IProjectsLocationsAgentSessionsApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentSessionsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentSessionsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentSessionsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentSessionsApiClientAlt> {
              return [
                ProjectsLocationsAgentSessionsApiClientAltEnum.JSON,
                ProjectsLocationsAgentSessionsApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentSessionsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsAgentSessionsApiClientIntentMatchFilter =
      'INTENT_MATCH_FILTER_UNSPECIFIED'|'ALL_SESSION_CONVERSATIONS'|
      'NO_MATCH_SESSION_CONVERSATIONS';

  export interface IProjectsLocationsAgentSessionsApiClientIntentMatchFilterEnum {
    readonly INTENT_MATCH_FILTER_UNSPECIFIED:
        ProjectsLocationsAgentSessionsApiClientIntentMatchFilter;
    readonly ALL_SESSION_CONVERSATIONS:
        ProjectsLocationsAgentSessionsApiClientIntentMatchFilter;
    readonly NO_MATCH_SESSION_CONVERSATIONS:
        ProjectsLocationsAgentSessionsApiClientIntentMatchFilter;

    values(): Array<ProjectsLocationsAgentSessionsApiClientIntentMatchFilter>;
  }

  export const ProjectsLocationsAgentSessionsApiClientIntentMatchFilterEnum:
      IProjectsLocationsAgentSessionsApiClientIntentMatchFilterEnum = {
        ALL_SESSION_CONVERSATIONS: <
            ProjectsLocationsAgentSessionsApiClientIntentMatchFilter>'ALL_SESSION_CONVERSATIONS',
        INTENT_MATCH_FILTER_UNSPECIFIED: <
            ProjectsLocationsAgentSessionsApiClientIntentMatchFilter>'INTENT_MATCH_FILTER_UNSPECIFIED',
        NO_MATCH_SESSION_CONVERSATIONS: <
            ProjectsLocationsAgentSessionsApiClientIntentMatchFilter>'NO_MATCH_SESSION_CONVERSATIONS',
        values():
            Array<ProjectsLocationsAgentSessionsApiClientIntentMatchFilter> {
              return [
                ProjectsLocationsAgentSessionsApiClientIntentMatchFilterEnum
                    .INTENT_MATCH_FILTER_UNSPECIFIED,
                ProjectsLocationsAgentSessionsApiClientIntentMatchFilterEnum
                    .ALL_SESSION_CONVERSATIONS,
                ProjectsLocationsAgentSessionsApiClientIntentMatchFilterEnum
                    .NO_MATCH_SESSION_CONVERSATIONS
              ];
            }
      };

  export declare interface ProjectsLocationsAgentSessionsDeleteContextsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsDetectIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsFulfillIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsMatchIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsPushFulfillmentResultNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsSearchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsApiClient$Xgafv;
    languageCode?: string;
    startTime?: string;
    endTime?: string;
    platform?: string;
    intentMatchFilter?:
        ProjectsLocationsAgentSessionsApiClientIntentMatchFilter;
    pageToken?: string;
    pageSize?: number;
    interactionsPageSize?: number;
    searchBackward?: boolean;
  }

  export declare interface ProjectsLocationsAgentSessionsSetAllContextsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsApiClient$Xgafv;
  }

  export class ProjectsLocationsAgentSessionsApiClientImpl implements
      ProjectsLocationsAgentSessionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    deleteContexts(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentSessionsDeleteContextsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.agent.sessions.deleteContexts',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    detectIntent(
        session: string, $requestBody: DetectIntentRequest_,
        namedParameters:
            ProjectsLocationsAgentSessionsDetectIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<DetectIntentResponse_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<DetectIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.sessions.detectIntent',
        path: `/${this.gapiVersion}/${session}:detectIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: DetectIntentResponse_
      });
    }

    fulfillIntent(
        session: string, $requestBody: FulfillIntentRequest_,
        namedParameters:
            ProjectsLocationsAgentSessionsFulfillIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<DetectIntentResponse_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<DetectIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.sessions.fulfillIntent',
        path: `/${this.gapiVersion}/${session}:fulfillIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: DetectIntentResponse_
      });
    }

    matchIntent(
        session: string, $requestBody: MatchIntentRequest_,
        namedParameters:
            ProjectsLocationsAgentSessionsMatchIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<MatchIntentResponse_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<MatchIntentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.sessions.matchIntent',
        path: `/${this.gapiVersion}/${session}:matchIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: MatchIntentResponse_
      });
    }

    pushFulfillmentResult(
        session: string, $requestBody: FulfillmentResult_,
        namedParameters:
            ProjectsLocationsAgentSessionsPushFulfillmentResultNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<FulfillmentResult_> {
      this.$apiClient.$validateParameter(
          session,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<FulfillmentResult_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.sessions.pushFulfillmentResult',
        path: `/${this.gapiVersion}/${session}:pushFulfillmentResult`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: FulfillmentResult_
      });
    }

    search(
        parent: string,
        namedParameters: ProjectsLocationsAgentSessionsSearchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SearchSessionConversationsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SearchSessionConversationsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.sessions.search',
        path: `/${this.gapiVersion}/${parent}:search`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SearchSessionConversationsResponse_
      });
    }

    setAllContexts(
        parent: string, $requestBody: SetAllContextsRequest_,
        namedParameters:
            ProjectsLocationsAgentSessionsSetAllContextsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SetAllContextsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<SetAllContextsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.sessions.setAllContexts',
        path: `/${this.gapiVersion}/${parent}:setAllContexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SetAllContextsResponse_
      });
    }
  }

  export abstract class ProjectsLocationsAgentSessionsApiClient {
    constructor() {}

    abstract deleteContexts(
        parent: string,
        namedParameters?:
            ProjectsLocationsAgentSessionsDeleteContextsNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract detectIntent(
        session: string, $requestBody: DetectIntentRequest_,
        namedParameters?:
            ProjectsLocationsAgentSessionsDetectIntentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<DetectIntentResponse_>;

    abstract fulfillIntent(
        session: string, $requestBody: FulfillIntentRequest_,
        namedParameters?:
            ProjectsLocationsAgentSessionsFulfillIntentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<DetectIntentResponse_>;

    abstract matchIntent(
        session: string, $requestBody: MatchIntentRequest_,
        namedParameters?:
            ProjectsLocationsAgentSessionsMatchIntentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<MatchIntentResponse_>;

    abstract pushFulfillmentResult(
        session: string, $requestBody: FulfillmentResult_,
        namedParameters?:
            ProjectsLocationsAgentSessionsPushFulfillmentResultNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<FulfillmentResult_>;

    abstract search(
        parent: string,
        namedParameters?: ProjectsLocationsAgentSessionsSearchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SearchSessionConversationsResponse_>;

    abstract setAllContexts(
        parent: string, $requestBody: SetAllContextsRequest_,
        namedParameters?:
            ProjectsLocationsAgentSessionsSetAllContextsNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SetAllContextsResponse_>;
  }

  export type ProjectsLocationsAgentSessionsContextsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAgentSessionsContextsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentSessionsContextsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentSessionsContextsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentSessionsContextsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentSessionsContextsApiClient$XgafvEnum:
      IProjectsLocationsAgentSessionsContextsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentSessionsContextsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentSessionsContextsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentSessionsContextsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentSessionsContextsApiClient$XgafvEnum[1],
                ProjectsLocationsAgentSessionsContextsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentSessionsContextsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentSessionsContextsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentSessionsContextsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentSessionsContextsApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentSessionsContextsApiClientAlt;

    values(): Array<ProjectsLocationsAgentSessionsContextsApiClientAlt>;
  }

  export const ProjectsLocationsAgentSessionsContextsApiClientAltEnum:
      IProjectsLocationsAgentSessionsContextsApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentSessionsContextsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentSessionsContextsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentSessionsContextsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentSessionsContextsApiClientAlt> {
              return [
                ProjectsLocationsAgentSessionsContextsApiClientAltEnum.JSON,
                ProjectsLocationsAgentSessionsContextsApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentSessionsContextsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentSessionsContextsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsContextsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsContextsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsContextsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsContextsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsContextsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAgentSessionsContextsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsContextsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsContextsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsLocationsAgentSessionsContextsApiClientImpl implements
      ProjectsLocationsAgentSessionsContextsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Context_,
        namedParameters:
            ProjectsLocationsAgentSessionsContextsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.sessions.contexts.create',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsAgentSessionsContextsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+/contexts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.agent.sessions.contexts.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsAgentSessionsContextsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+/contexts/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.sessions.contexts.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentSessionsContextsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListContextsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListContextsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.sessions.contexts.list',
        path: `/${this.gapiVersion}/${parent}/contexts`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListContextsResponse_
      });
    }

    patch(
        name: string, $requestBody: Context_,
        namedParameters:
            ProjectsLocationsAgentSessionsContextsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Context_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+/contexts/[^/]+$'));

      return this.$apiClient.$request<Context_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.agent.sessions.contexts.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Context_
      });
    }
  }

  export abstract class ProjectsLocationsAgentSessionsContextsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Context_,
        namedParameters?:
            ProjectsLocationsAgentSessionsContextsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentSessionsContextsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentSessionsContextsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsAgentSessionsContextsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListContextsResponse_>;

    abstract patch(
        name: string, $requestBody: Context_,
        namedParameters?:
            ProjectsLocationsAgentSessionsContextsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Context_>;
  }

  export type ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsAgentSessionsEntityTypesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentSessionsEntityTypesApiClient$XgafvEnum:
      IProjectsLocationsAgentSessionsEntityTypesApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentSessionsEntityTypesApiClient$XgafvEnum[1],
                ProjectsLocationsAgentSessionsEntityTypesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentSessionsEntityTypesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentSessionsEntityTypesApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentSessionsEntityTypesApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentSessionsEntityTypesApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentSessionsEntityTypesApiClientAlt;

    values(): Array<ProjectsLocationsAgentSessionsEntityTypesApiClientAlt>;
  }

  export const ProjectsLocationsAgentSessionsEntityTypesApiClientAltEnum:
      IProjectsLocationsAgentSessionsEntityTypesApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentSessionsEntityTypesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentSessionsEntityTypesApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentSessionsEntityTypesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentSessionsEntityTypesApiClientAlt> {
              return [
                ProjectsLocationsAgentSessionsEntityTypesApiClientAltEnum.JSON,
                ProjectsLocationsAgentSessionsEntityTypesApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentSessionsEntityTypesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentSessionsEntityTypesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsEntityTypesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsEntityTypesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentSessionsEntityTypesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAgentSessionsEntityTypesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentSessionsEntityTypesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentSessionsEntityTypesApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsLocationsAgentSessionsEntityTypesApiClientImpl implements
      ProjectsLocationsAgentSessionsEntityTypesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: SessionEntityType_,
        namedParameters:
            ProjectsLocationsAgentSessionsEntityTypesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.agent.sessions.entityTypes.create',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsAgentSessionsEntityTypesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.agent.sessions.entityTypes.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsAgentSessionsEntityTypesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+/entityTypes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.agent.sessions.entityTypes.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsAgentSessionsEntityTypesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListSessionEntityTypesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListSessionEntityTypesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.agent.sessions.entityTypes.list',
        path: `/${this.gapiVersion}/${parent}/entityTypes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListSessionEntityTypesResponse_
      });
    }

    patch(
        name: string, $requestBody: SessionEntityType_,
        namedParameters:
            ProjectsLocationsAgentSessionsEntityTypesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SessionEntityType_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/agent/sessions/[^/]+/entityTypes/[^/]+$'));

      return this.$apiClient.$request<SessionEntityType_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.locations.agent.sessions.entityTypes.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SessionEntityType_
      });
    }
  }

  export abstract class ProjectsLocationsAgentSessionsEntityTypesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: SessionEntityType_,
        namedParameters?:
            ProjectsLocationsAgentSessionsEntityTypesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentSessionsEntityTypesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsAgentSessionsEntityTypesGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsAgentSessionsEntityTypesListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListSessionEntityTypesResponse_>;

    abstract patch(
        name: string, $requestBody: SessionEntityType_,
        namedParameters?:
            ProjectsLocationsAgentSessionsEntityTypesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SessionEntityType_>;
  }

  export type ProjectsLocationsAgentVersionsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAgentVersionsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentVersionsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentVersionsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentVersionsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentVersionsApiClient$XgafvEnum:
      IProjectsLocationsAgentVersionsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentVersionsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentVersionsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentVersionsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentVersionsApiClient$XgafvEnum[1],
                ProjectsLocationsAgentVersionsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentVersionsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAgentVersionsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentVersionsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentVersionsApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentVersionsApiClientAlt;

    values(): Array<ProjectsLocationsAgentVersionsApiClientAlt>;
  }

  export const ProjectsLocationsAgentVersionsApiClientAltEnum:
      IProjectsLocationsAgentVersionsApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentVersionsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentVersionsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentVersionsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentVersionsApiClientAlt> {
              return [
                ProjectsLocationsAgentVersionsApiClientAltEnum.JSON,
                ProjectsLocationsAgentVersionsApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentVersionsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentVersionsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentVersionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentVersionsApiClient$Xgafv;
    withoutActionsOnGoogle?: boolean;
    enableVersionLimit?: boolean;
  }

  export declare interface ProjectsLocationsAgentVersionsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentVersionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentVersionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentVersionsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentVersionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentVersionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentVersionsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentVersionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentVersionsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAgentVersionsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentVersionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentVersionsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsLocationsAgentVersionsApiClientImpl implements
      ProjectsLocationsAgentVersionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Version_,
        namedParameters: ProjectsLocationsAgentVersionsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Version_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));

      return this.$apiClient.$request<Version_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agent.versions.create',
        path: `/${this.gapiVersion}/${parent}/versions`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Version_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsLocationsAgentVersionsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/versions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.agent.versions.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsAgentVersionsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Version_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/versions/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Version_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.versions.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Version_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAgentVersionsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListVersionsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+/agent$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListVersionsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.agent.versions.list',
        path: `/${this.gapiVersion}/${parent}/versions`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListVersionsResponse_
      });
    }

    patch(
        name: string, $requestBody: Version_,
        namedParameters: ProjectsLocationsAgentVersionsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Version_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/agent/versions/[^/]+$'));

      return this.$apiClient.$request<Version_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.agent.versions.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Version_
      });
    }
  }

  export abstract class ProjectsLocationsAgentVersionsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Version_,
        namedParameters?: ProjectsLocationsAgentVersionsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Version_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsLocationsAgentVersionsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsAgentVersionsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Version_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAgentVersionsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListVersionsResponse_>;

    abstract patch(
        name: string, $requestBody: Version_,
        namedParameters?: ProjectsLocationsAgentVersionsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Version_>;
  }

  export type ProjectsLocationsAgentsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAgentsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAgentsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAgentsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAgentsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAgentsApiClient$XgafvEnum:
      IProjectsLocationsAgentsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAgentsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAgentsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAgentsApiClient$Xgafv> {
              return [
                ProjectsLocationsAgentsApiClient$XgafvEnum[1],
                ProjectsLocationsAgentsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAgentsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsAgentsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAgentsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAgentsApiClientAlt;
    readonly PROTO: ProjectsLocationsAgentsApiClientAlt;

    values(): Array<ProjectsLocationsAgentsApiClientAlt>;
  }

  export const ProjectsLocationsAgentsApiClientAltEnum:
      IProjectsLocationsAgentsApiClientAltEnum = {
        JSON: <ProjectsLocationsAgentsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAgentsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAgentsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAgentsApiClientAlt> {
              return [
                ProjectsLocationsAgentsApiClientAltEnum.JSON,
                ProjectsLocationsAgentsApiClientAltEnum.MEDIA,
                ProjectsLocationsAgentsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAgentsExportNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentsExportSadlCompilationNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAgentsExportUseCaseNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAgentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAgentsApiClient$Xgafv;
  }

  export class ProjectsLocationsAgentsApiClientImpl implements
      ProjectsLocationsAgentsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    export(
        agent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1ExportIntentRequest,
        namedParameters: ProjectsLocationsAgentsExportNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1ExportIntentResponse> {
      this.$apiClient.$validateParameter(
          agent, new RegExp('^projects/[^/]+/locations/[^/]+/agents/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1ExportIntentResponse>({
            body: $requestBody,
            httpMethod: 'POST',
            methodId: 'dialogflow.projects.locations.agents.export',
            path: `/${this.gapiVersion}/${agent}:export`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1ExportIntentResponse
          });
    }

    exportSadlCompilation(
        agent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1ExportSadlCompilationRequest,
        namedParameters:
            ProjectsLocationsAgentsExportSadlCompilationNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<
            GoogleCloudDialogflowAamV2beta1ExportSadlCompilationResponse> {
      this.$apiClient.$validateParameter(
          agent, new RegExp('^projects/[^/]+/locations/[^/]+/agents/[^/]+$'));

      return this.$apiClient.$request<
          GoogleCloudDialogflowAamV2beta1ExportSadlCompilationResponse>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.agents.exportSadlCompilation',
        path: `/${this.gapiVersion}/${agent}:exportSadlCompilation`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor:
            GoogleCloudDialogflowAamV2beta1ExportSadlCompilationResponse
      });
    }

    exportUseCase(
        agent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1ExportUseCaseRequest,
        namedParameters: ProjectsLocationsAgentsExportUseCaseNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1ExportUseCaseResponse> {
      this.$apiClient.$validateParameter(
          agent, new RegExp('^projects/[^/]+/locations/[^/]+/agents/[^/]+$'));

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1ExportUseCaseResponse>({
            body: $requestBody,
            httpMethod: 'POST',
            methodId: 'dialogflow.projects.locations.agents.exportUseCase',
            path: `/${this.gapiVersion}/${agent}:exportUseCase`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudDialogflowAamV2beta1ExportUseCaseResponse
          });
    }
  }

  export abstract class ProjectsLocationsAgentsApiClient {
    constructor() {}

    abstract export(
        agent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1ExportIntentRequest,
        namedParameters?: ProjectsLocationsAgentsExportNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ExportIntentResponse>;

    abstract exportSadlCompilation(
        agent: string,
        $requestBody:
            GoogleCloudDialogflowAamV2beta1ExportSadlCompilationRequest,
        namedParameters?:
            ProjectsLocationsAgentsExportSadlCompilationNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<
            GoogleCloudDialogflowAamV2beta1ExportSadlCompilationResponse>;

    abstract exportUseCase(
        agent: string,
        $requestBody: GoogleCloudDialogflowAamV2beta1ExportUseCaseRequest,
        namedParameters?: ProjectsLocationsAgentsExportUseCaseNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ExportUseCaseResponse>;
  }

  export type ProjectsLocationsAmmTrainingRunsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAmmTrainingRunsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAmmTrainingRunsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAmmTrainingRunsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAmmTrainingRunsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAmmTrainingRunsApiClient$XgafvEnum:
      IProjectsLocationsAmmTrainingRunsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAmmTrainingRunsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAmmTrainingRunsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAmmTrainingRunsApiClient$Xgafv> {
              return [
                ProjectsLocationsAmmTrainingRunsApiClient$XgafvEnum[1],
                ProjectsLocationsAmmTrainingRunsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAmmTrainingRunsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAmmTrainingRunsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAmmTrainingRunsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAmmTrainingRunsApiClientAlt;
    readonly PROTO: ProjectsLocationsAmmTrainingRunsApiClientAlt;

    values(): Array<ProjectsLocationsAmmTrainingRunsApiClientAlt>;
  }

  export const ProjectsLocationsAmmTrainingRunsApiClientAltEnum:
      IProjectsLocationsAmmTrainingRunsApiClientAltEnum = {
        JSON: <ProjectsLocationsAmmTrainingRunsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAmmTrainingRunsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAmmTrainingRunsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAmmTrainingRunsApiClientAlt> {
              return [
                ProjectsLocationsAmmTrainingRunsApiClientAltEnum.JSON,
                ProjectsLocationsAmmTrainingRunsApiClientAltEnum.MEDIA,
                ProjectsLocationsAmmTrainingRunsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAmmTrainingRunsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAmmTrainingRunsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAmmTrainingRunsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsAmmTrainingRunsApiClientImpl implements
      ProjectsLocationsAmmTrainingRunsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAmmTrainingRunsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamTrainingRunsResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1ListAamTrainingRunsResponse>(
              {
                body: $requestBody,
                httpMethod: 'GET',
                methodId: 'dialogflow.projects.locations.ammTrainingRuns.list',
                path: `/${this.gapiVersion}/${parent}/ammTrainingRuns`,
                queryParams: buildQueryParams(
                    namedParameters, PARAM_MAP_0, passthroughNamedParameters),
                responseCtor:
                    GoogleCloudDialogflowAamV2beta1ListAamTrainingRunsResponse
              });
    }
  }

  export abstract class ProjectsLocationsAmmTrainingRunsApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAmmTrainingRunsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1ListAamTrainingRunsResponse>;
  }

  export type ProjectsLocationsAnalyticsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAnalyticsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAnalyticsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAnalyticsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAnalyticsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAnalyticsApiClient$XgafvEnum:
      IProjectsLocationsAnalyticsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAnalyticsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAnalyticsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAnalyticsApiClient$Xgafv> {
              return [
                ProjectsLocationsAnalyticsApiClient$XgafvEnum[1],
                ProjectsLocationsAnalyticsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAnalyticsApiClientAccumulationMode =
      'ACCUMULATION_MODE_UNSPECIFIED'|'ACCUMULATE_INTENT_METRICS';

  export interface IProjectsLocationsAnalyticsApiClientAccumulationModeEnum {
    readonly ACCUMULATION_MODE_UNSPECIFIED:
        ProjectsLocationsAnalyticsApiClientAccumulationMode;
    readonly ACCUMULATE_INTENT_METRICS:
        ProjectsLocationsAnalyticsApiClientAccumulationMode;

    values(): Array<ProjectsLocationsAnalyticsApiClientAccumulationMode>;
  }

  export const ProjectsLocationsAnalyticsApiClientAccumulationModeEnum:
      IProjectsLocationsAnalyticsApiClientAccumulationModeEnum = {
        ACCUMULATE_INTENT_METRICS: <
            ProjectsLocationsAnalyticsApiClientAccumulationMode>'ACCUMULATE_INTENT_METRICS',
        ACCUMULATION_MODE_UNSPECIFIED: <
            ProjectsLocationsAnalyticsApiClientAccumulationMode>'ACCUMULATION_MODE_UNSPECIFIED',
        values():
            Array<ProjectsLocationsAnalyticsApiClientAccumulationMode> {
              return [
                ProjectsLocationsAnalyticsApiClientAccumulationModeEnum
                    .ACCUMULATION_MODE_UNSPECIFIED,
                ProjectsLocationsAnalyticsApiClientAccumulationModeEnum
                    .ACCUMULATE_INTENT_METRICS
              ];
            }
      };

  export type ProjectsLocationsAnalyticsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsAnalyticsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAnalyticsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAnalyticsApiClientAlt;
    readonly PROTO: ProjectsLocationsAnalyticsApiClientAlt;

    values(): Array<ProjectsLocationsAnalyticsApiClientAlt>;
  }

  export const ProjectsLocationsAnalyticsApiClientAltEnum:
      IProjectsLocationsAnalyticsApiClientAltEnum = {
        JSON: <ProjectsLocationsAnalyticsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAnalyticsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAnalyticsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAnalyticsApiClientAlt> {
              return [
                ProjectsLocationsAnalyticsApiClientAltEnum.JSON,
                ProjectsLocationsAnalyticsApiClientAltEnum.MEDIA,
                ProjectsLocationsAnalyticsApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsAnalyticsApiClientAnalyticsView =
      'ANALYTICS_VIEW_UNSPECIFIED'|'ALL'|'CONVERSATION_ONLY';

  export interface IProjectsLocationsAnalyticsApiClientAnalyticsViewEnum {
    readonly ANALYTICS_VIEW_UNSPECIFIED:
        ProjectsLocationsAnalyticsApiClientAnalyticsView;
    readonly ALL: ProjectsLocationsAnalyticsApiClientAnalyticsView;
    readonly CONVERSATION_ONLY:
        ProjectsLocationsAnalyticsApiClientAnalyticsView;

    values(): Array<ProjectsLocationsAnalyticsApiClientAnalyticsView>;
  }

  export const ProjectsLocationsAnalyticsApiClientAnalyticsViewEnum:
      IProjectsLocationsAnalyticsApiClientAnalyticsViewEnum = {
        ALL: <ProjectsLocationsAnalyticsApiClientAnalyticsView>'ALL',
        ANALYTICS_VIEW_UNSPECIFIED: <
            ProjectsLocationsAnalyticsApiClientAnalyticsView>'ANALYTICS_VIEW_UNSPECIFIED',
        CONVERSATION_ONLY: <
            ProjectsLocationsAnalyticsApiClientAnalyticsView>'CONVERSATION_ONLY',
        values():
            Array<ProjectsLocationsAnalyticsApiClientAnalyticsView> {
              return [
                ProjectsLocationsAnalyticsApiClientAnalyticsViewEnum
                    .ANALYTICS_VIEW_UNSPECIFIED,
                ProjectsLocationsAnalyticsApiClientAnalyticsViewEnum.ALL,
                ProjectsLocationsAnalyticsApiClientAnalyticsViewEnum
                    .CONVERSATION_ONLY
              ];
            }
      };

  export declare interface ProjectsLocationsAnalyticsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAnalyticsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAnalyticsApiClient$Xgafv;
    startTime?: string;
    endTime?: string;
    pageSize?: number;
    pageToken?: string;
    analyticsView?: ProjectsLocationsAnalyticsApiClientAnalyticsView;
    querySpecificVersion?: boolean;
    versionId?: string;
    accumulationMode?: ProjectsLocationsAnalyticsApiClientAccumulationMode;
  }

  export class ProjectsLocationsAnalyticsApiClientImpl implements
      ProjectsLocationsAnalyticsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAnalyticsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListAnalyticsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListAnalyticsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.analytics.list',
        path: `/${this.gapiVersion}/${parent}/analytics`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListAnalyticsResponse_
      });
    }
  }

  export abstract class ProjectsLocationsAnalyticsApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAnalyticsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListAnalyticsResponse_>;
  }

  export type ProjectsLocationsAnswerRecordsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsAnswerRecordsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsAnswerRecordsApiClient$Xgafv;
    readonly 2: ProjectsLocationsAnswerRecordsApiClient$Xgafv;

    values(): Array<ProjectsLocationsAnswerRecordsApiClient$Xgafv>;
  }

  export const ProjectsLocationsAnswerRecordsApiClient$XgafvEnum:
      IProjectsLocationsAnswerRecordsApiClient$XgafvEnum = {
        1: <ProjectsLocationsAnswerRecordsApiClient$Xgafv>'1',
        2: <ProjectsLocationsAnswerRecordsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsAnswerRecordsApiClient$Xgafv> {
              return [
                ProjectsLocationsAnswerRecordsApiClient$XgafvEnum[1],
                ProjectsLocationsAnswerRecordsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsAnswerRecordsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsAnswerRecordsApiClientAltEnum {
    readonly JSON: ProjectsLocationsAnswerRecordsApiClientAlt;
    readonly MEDIA: ProjectsLocationsAnswerRecordsApiClientAlt;
    readonly PROTO: ProjectsLocationsAnswerRecordsApiClientAlt;

    values(): Array<ProjectsLocationsAnswerRecordsApiClientAlt>;
  }

  export const ProjectsLocationsAnswerRecordsApiClientAltEnum:
      IProjectsLocationsAnswerRecordsApiClientAltEnum = {
        JSON: <ProjectsLocationsAnswerRecordsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsAnswerRecordsApiClientAlt>'media',
        PROTO: <ProjectsLocationsAnswerRecordsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsAnswerRecordsApiClientAlt> {
              return [
                ProjectsLocationsAnswerRecordsApiClientAltEnum.JSON,
                ProjectsLocationsAnswerRecordsApiClientAltEnum.MEDIA,
                ProjectsLocationsAnswerRecordsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsAnswerRecordsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAnswerRecordsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAnswerRecordsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAnswerRecordsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAnswerRecordsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAnswerRecordsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsAnswerRecordsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAnswerRecordsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAnswerRecordsApiClient$Xgafv;
    filter?: string;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsAnswerRecordsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsAnswerRecordsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsAnswerRecordsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsLocationsAnswerRecordsApiClientImpl implements
      ProjectsLocationsAnswerRecordsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    delete(
        name: string,
        namedParameters: ProjectsLocationsAnswerRecordsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/answerRecords/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.answerRecords.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsAnswerRecordsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AnswerRecord_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/answerRecords/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<AnswerRecord_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.answerRecords.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AnswerRecord_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsAnswerRecordsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListAnswerRecordsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListAnswerRecordsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.answerRecords.list',
        path: `/${this.gapiVersion}/${parent}/answerRecords`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListAnswerRecordsResponse_
      });
    }

    patch(
        name: string, $requestBody: AnswerRecord_,
        namedParameters: ProjectsLocationsAnswerRecordsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AnswerRecord_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/answerRecords/[^/]+$'));

      return this.$apiClient.$request<AnswerRecord_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.answerRecords.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AnswerRecord_
      });
    }
  }

  export abstract class ProjectsLocationsAnswerRecordsApiClient {
    constructor() {}

    abstract delete(
        name: string,
        namedParameters?: ProjectsLocationsAnswerRecordsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsAnswerRecordsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<AnswerRecord_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsAnswerRecordsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListAnswerRecordsResponse_>;

    abstract patch(
        name: string, $requestBody: AnswerRecord_,
        namedParameters?: ProjectsLocationsAnswerRecordsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<AnswerRecord_>;
  }

  export type ProjectsLocationsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsApiClient$Xgafv;
    readonly 2: ProjectsLocationsApiClient$Xgafv;

    values(): Array<ProjectsLocationsApiClient$Xgafv>;
  }

  export const ProjectsLocationsApiClient$XgafvEnum:
      IProjectsLocationsApiClient$XgafvEnum = {
        1: <ProjectsLocationsApiClient$Xgafv>'1',
        2: <ProjectsLocationsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsApiClient$Xgafv> {
              return [
                ProjectsLocationsApiClient$XgafvEnum[1],
                ProjectsLocationsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsApiClientAltEnum {
    readonly JSON: ProjectsLocationsApiClientAlt;
    readonly MEDIA: ProjectsLocationsApiClientAlt;
    readonly PROTO: ProjectsLocationsApiClientAlt;

    values(): Array<ProjectsLocationsApiClientAlt>;
  }

  export const ProjectsLocationsApiClientAltEnum:
      IProjectsLocationsApiClientAltEnum = {
        JSON: <ProjectsLocationsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsApiClientAlt>'media',
        PROTO: <ProjectsLocationsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsApiClientAlt> {
              return [
                ProjectsLocationsApiClientAltEnum.JSON,
                ProjectsLocationsApiClientAltEnum.MEDIA,
                ProjectsLocationsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsDeleteAgentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsGetAgentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsApiClient$Xgafv;
    filter?: string;
    includeUnrevealedLocations?: boolean;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsSetAgentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsLocationsStartClusteringJobNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsApiClient$Xgafv;
    gcsBucket?: string;
    intentClusteringRequestRunId?: string;
    intentClusteringRequestEmbeddingsDimension?: number;
    outcomeClusteringRequestEmbeddingsDimension?: number;
    batchLabellingRequestDatasetId?: string;
  }

  export declare interface ProjectsLocationsUpdateAgentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsLocationsApiClientImpl implements
      ProjectsLocationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    deleteAgent(
        parent: string,
        namedParameters: ProjectsLocationsDeleteAgentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.deleteAgent',
        path: `/${this.gapiVersion}/${parent}/agent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudLocationLocation> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleCloudLocationLocation>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleCloudLocationLocation
      });
    }

    getAgent(
        parent: string,
        namedParameters: ProjectsLocationsGetAgentNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Agent_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Agent_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.getAgent',
        path: `/${this.gapiVersion}/${parent}/agent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Agent_
      });
    }

    list(
        name: string,
        namedParameters: ProjectsLocationsListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudLocationListLocationsResponse> {
      this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleCloudLocationListLocationsResponse>(
          {
            body: $requestBody,
            httpMethod: 'GET',
            methodId: 'dialogflow.projects.locations.list',
            path: `/${this.gapiVersion}/${name}/locations`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor: GoogleCloudLocationListLocationsResponse
          });
    }

    setAgent(
        parent: string, $requestBody: Agent_,
        namedParameters: ProjectsLocationsSetAgentNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Agent_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<Agent_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.setAgent',
        path: `/${this.gapiVersion}/${parent}/agent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Agent_
      });
    }

    startClusteringJob(
        parent: string,
        namedParameters: ProjectsLocationsStartClusteringJobNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleCloudDialogflowAamV2beta1StartClusteringJobResponse> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient
          .$request<GoogleCloudDialogflowAamV2beta1StartClusteringJobResponse>({
            body: $requestBody,
            httpMethod: 'POST',
            methodId: 'dialogflow.projects.locations.startClusteringJob',
            path: `/${this.gapiVersion}/${parent}:startClusteringJob`,
            queryParams: buildQueryParams(
                namedParameters, PARAM_MAP_0, passthroughNamedParameters),
            responseCtor:
                GoogleCloudDialogflowAamV2beta1StartClusteringJobResponse
          });
    }

    updateAgent(
        parent: string, $requestBody: Agent_,
        namedParameters: ProjectsLocationsUpdateAgentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Agent_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<Agent_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.updateAgent',
        path: `/${this.gapiVersion}/${parent}/agent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Agent_
      });
    }
  }

  export abstract class ProjectsLocationsApiClient {
    constructor() {}

    abstract deleteAgent(
        parent: string,
        namedParameters?: ProjectsLocationsDeleteAgentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudLocationLocation>;

    abstract getAgent(
        parent: string,
        namedParameters?: ProjectsLocationsGetAgentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Agent_>;

    abstract list(
        name: string,
        namedParameters?: ProjectsLocationsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudLocationListLocationsResponse>;

    abstract setAgent(
        parent: string, $requestBody: Agent_,
        namedParameters?: ProjectsLocationsSetAgentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Agent_>;

    abstract startClusteringJob(
        parent: string,
        namedParameters?: ProjectsLocationsStartClusteringJobNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleCloudDialogflowAamV2beta1StartClusteringJobResponse>;

    abstract updateAgent(
        parent: string, $requestBody: Agent_,
        namedParameters?: ProjectsLocationsUpdateAgentNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Agent_>;
  }

  export type ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$XgafvEnum:
      IProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;
    readonly PROTO:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;

    values(): Array<
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt>;
  }

  export const ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum:
      IProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum = {
        JSON: <
            ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt> {
              return [
                ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum
                    .JSON,
                ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsDeleteNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsGetNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsListNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class
      ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClientImpl
          implements
              ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/annotatedConversationDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.annotatedConversationDatasets.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AnnotatedConversationDataset_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/annotatedConversationDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<AnnotatedConversationDataset_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.annotatedConversationDatasets.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AnnotatedConversationDataset_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListAnnotatedConversationDatasetsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          ListAnnotatedConversationDatasetsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.annotatedConversationDatasets.list',
        path: `/${this.gapiVersion}/${parent}/annotatedConversationDatasets`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListAnnotatedConversationDatasetsResponse_
      });
    }
  }

  export abstract class
      ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsApiClient {
    constructor() {}

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<AnnotatedConversationDataset_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsAnnotatedConversationDatasetsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListAnnotatedConversationDatasetsResponse_>;
  }

  export type ProjectsLocationsConversationDatasetsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsConversationDatasetsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsConversationDatasetsApiClient$Xgafv;
    readonly 2: ProjectsLocationsConversationDatasetsApiClient$Xgafv;

    values(): Array<ProjectsLocationsConversationDatasetsApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationDatasetsApiClient$XgafvEnum:
      IProjectsLocationsConversationDatasetsApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationDatasetsApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationDatasetsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsConversationDatasetsApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationDatasetsApiClient$XgafvEnum[1],
                ProjectsLocationsConversationDatasetsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsConversationDatasetsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationDatasetsApiClientAltEnum {
    readonly JSON: ProjectsLocationsConversationDatasetsApiClientAlt;
    readonly MEDIA: ProjectsLocationsConversationDatasetsApiClientAlt;
    readonly PROTO: ProjectsLocationsConversationDatasetsApiClientAlt;

    values(): Array<ProjectsLocationsConversationDatasetsApiClientAlt>;
  }

  export const ProjectsLocationsConversationDatasetsApiClientAltEnum:
      IProjectsLocationsConversationDatasetsApiClientAltEnum = {
        JSON: <ProjectsLocationsConversationDatasetsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsConversationDatasetsApiClientAlt>'media',
        PROTO: <ProjectsLocationsConversationDatasetsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsConversationDatasetsApiClientAlt> {
              return [
                ProjectsLocationsConversationDatasetsApiClientAltEnum.JSON,
                ProjectsLocationsConversationDatasetsApiClientAltEnum.MEDIA,
                ProjectsLocationsConversationDatasetsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationDatasetsCreateLegacyConversationDatasetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsDeleteLegacyConversationDatasetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsImportConversationDataNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsLabelConversationNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationDatasetsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsConversationDatasetsApiClientImpl implements
      ProjectsLocationsConversationDatasetsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: ConversationDataset_,
        namedParameters:
            ProjectsLocationsConversationDatasetsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.conversationDatasets.create',
        path: `/${this.gapiVersion}/${parent}/conversationDatasets`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    createLegacyConversationDataset(
        parent: string, $requestBody: ConversationDataset_,
        namedParameters:
            ProjectsLocationsConversationDatasetsCreateLegacyConversationDatasetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationDataset_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<ConversationDataset_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.createLegacyConversationDataset',
        path: `/${this.gapiVersion}/${
            parent}/conversationDatasets:createLegacyConversationDataset`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationDataset_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.conversationDatasets.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    deleteLegacyConversationDataset(
        name: string, $requestBody: DeleteLegacyConversationDatasetRequest_,
        namedParameters:
            ProjectsLocationsConversationDatasetsDeleteLegacyConversationDatasetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+$'));

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.deleteLegacyConversationDataset',
        path: `/${this.gapiVersion}/${name}:deleteLegacyConversationDataset`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationDataset_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationDataset_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.conversationDatasets.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationDataset_
      });
    }

    importConversationData(
        name: string, $requestBody: ImportConversationDataRequest_,
        namedParameters:
            ProjectsLocationsConversationDatasetsImportConversationDataNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.importConversationData',
        path: `/${this.gapiVersion}/${name}:importConversationData`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    labelConversation(
        parent: string, $requestBody: LabelConversationRequest_,
        namedParameters:
            ProjectsLocationsConversationDatasetsLabelConversationNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.labelConversation',
        path: `/${this.gapiVersion}/${parent}:labelConversation`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationDatasetsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListConversationDatasetsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.conversationDatasets.list',
        path: `/${this.gapiVersion}/${parent}/conversationDatasets`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationDatasetsResponse_
      });
    }
  }

  export abstract class ProjectsLocationsConversationDatasetsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: ConversationDataset_,
        namedParameters?:
            ProjectsLocationsConversationDatasetsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract createLegacyConversationDataset(
        parent: string, $requestBody: ConversationDataset_,
        namedParameters?:
            ProjectsLocationsConversationDatasetsCreateLegacyConversationDatasetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationDataset_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract deleteLegacyConversationDataset(
        name: string, $requestBody: DeleteLegacyConversationDatasetRequest_,
        namedParameters?:
            ProjectsLocationsConversationDatasetsDeleteLegacyConversationDatasetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationDataset_>;

    abstract importConversationData(
        name: string, $requestBody: ImportConversationDataRequest_,
        namedParameters?:
            ProjectsLocationsConversationDatasetsImportConversationDataNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract labelConversation(
        parent: string, $requestBody: LabelConversationRequest_,
        namedParameters?:
            ProjectsLocationsConversationDatasetsLabelConversationNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationDatasetsResponse_>;
  }

  export type ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsConversationDatasetsConversationDataItemsApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$XgafvEnum:
      IProjectsLocationsConversationDatasetsConversationDataItemsApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt;
    readonly PROTO:
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt;

    values(): Array<
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt>;
  }

  export const ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum:
      IProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum = {
        JSON: <
            ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt> {
              return [
                ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum
                    .JSON,
                ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationDatasetsConversationDataItemsGetNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsConversationDataItemsListNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationDatasetsConversationDataItemsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export class
      ProjectsLocationsConversationDatasetsConversationDataItemsApiClientImpl
          implements
              ProjectsLocationsConversationDatasetsConversationDataItemsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsConversationDataItemsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationDataItem_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/conversationDataItems/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationDataItem_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.conversationDataItems.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationDataItem_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsConversationDataItemsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationDataItemsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListConversationDataItemsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.conversationDataItems.list',
        path: `/${this.gapiVersion}/${parent}/conversationDataItems`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationDataItemsResponse_
      });
    }
  }

  export abstract class
      ProjectsLocationsConversationDatasetsConversationDataItemsApiClient {
    constructor() {}

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsConversationDataItemsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationDataItem_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsConversationDataItemsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationDataItemsResponse_>;
  }

  export type ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$XgafvEnum:
      IProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
    readonly PROTO:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;

    values(): Array<
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt>;
  }

  export const ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum:
      IProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum = {
        JSON: <
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt> {
              return [
                ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum
                    .JSON,
                ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsCreateNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsDeleteNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsGetNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsListNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsPatchNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient$Xgafv;
    updateMask?: string;
  }

  export class
      ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClientImpl
          implements
              ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: ConversationAnnotation_,
        namedParameters:
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationAnnotation_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/conversationDataItems/[^/]+$'));

      return this.$apiClient.$request<ConversationAnnotation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.conversationDataItems.conversationAnnotations.create',
        path: `/${this.gapiVersion}/${parent}/conversationAnnotations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationAnnotation_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/conversationDataItems/[^/]+/conversationAnnotations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.conversationDataItems.conversationAnnotations.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationAnnotation_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/conversationDataItems/[^/]+/conversationAnnotations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationAnnotation_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.conversationDataItems.conversationAnnotations.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationAnnotation_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationAnnotationsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/conversationDataItems/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListConversationAnnotationsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.conversationDataItems.conversationAnnotations.list',
        path: `/${this.gapiVersion}/${parent}/conversationAnnotations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationAnnotationsResponse_
      });
    }

    patch(
        name: string, $requestBody: ConversationAnnotation_,
        namedParameters:
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationAnnotation_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationDatasets/[^/]+/conversationDataItems/[^/]+/conversationAnnotations/[^/]+$'));

      return this.$apiClient.$request<ConversationAnnotation_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.locations.conversationDatasets.conversationDataItems.conversationAnnotations.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationAnnotation_
      });
    }
  }

  export abstract class
      ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: ConversationAnnotation_,
        namedParameters?:
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationAnnotation_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationAnnotation_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationAnnotationsResponse_>;

    abstract patch(
        name: string, $requestBody: ConversationAnnotation_,
        namedParameters?:
            ProjectsLocationsConversationDatasetsConversationDataItemsConversationAnnotationsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationAnnotation_>;
  }

  export type ProjectsLocationsConversationModelsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsConversationModelsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsConversationModelsApiClient$Xgafv;
    readonly 2: ProjectsLocationsConversationModelsApiClient$Xgafv;

    values(): Array<ProjectsLocationsConversationModelsApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationModelsApiClient$XgafvEnum:
      IProjectsLocationsConversationModelsApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationModelsApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationModelsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsConversationModelsApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationModelsApiClient$XgafvEnum[1],
                ProjectsLocationsConversationModelsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsConversationModelsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationModelsApiClientAltEnum {
    readonly JSON: ProjectsLocationsConversationModelsApiClientAlt;
    readonly MEDIA: ProjectsLocationsConversationModelsApiClientAlt;
    readonly PROTO: ProjectsLocationsConversationModelsApiClientAlt;

    values(): Array<ProjectsLocationsConversationModelsApiClientAlt>;
  }

  export const ProjectsLocationsConversationModelsApiClientAltEnum:
      IProjectsLocationsConversationModelsApiClientAltEnum = {
        JSON: <ProjectsLocationsConversationModelsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsConversationModelsApiClientAlt>'media',
        PROTO: <ProjectsLocationsConversationModelsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsConversationModelsApiClientAlt> {
              return [
                ProjectsLocationsConversationModelsApiClientAltEnum.JSON,
                ProjectsLocationsConversationModelsApiClientAltEnum.MEDIA,
                ProjectsLocationsConversationModelsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationModelsCalculateSemanticMatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsCheckSuggestionListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsCopyNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsDeployNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsEncodeSuggestionListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsGenerateConversationSummaryNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsConversationModelsSuggestIssuesNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsSuggestIssuesSyncNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsUndeployNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsApiClient$Xgafv;
  }

  export class ProjectsLocationsConversationModelsApiClientImpl implements
      ProjectsLocationsConversationModelsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    calculateSemanticMatch(
        model: string, $requestBody: CalculateSemanticMatchRequest_,
        namedParameters:
            ProjectsLocationsConversationModelsCalculateSemanticMatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<CalculateSemanticMatchResponse_> {
      this.$apiClient.$validateParameter(
          model,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<CalculateSemanticMatchResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationModels.calculateSemanticMatch',
        path: `/${this.gapiVersion}/${model}:calculateSemanticMatch`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: CalculateSemanticMatchResponse_
      });
    }

    checkSuggestionList(
        model: string,
        $requestBody: CheckConversationModelSuggestionListRequest_,
        namedParameters:
            ProjectsLocationsConversationModelsCheckSuggestionListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationModelSuggestionList_> {
      this.$apiClient.$validateParameter(
          model,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<ConversationModelSuggestionList_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationModels.checkSuggestionList',
        path: `/${this.gapiVersion}/${model}:checkSuggestionList`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationModelSuggestionList_
      });
    }

    copy(
        parent: string, $requestBody: CopyConversationModelRequest_,
        namedParameters: ProjectsLocationsConversationModelsCopyNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.conversationModels.copy',
        path: `/${this.gapiVersion}/${parent}/conversationModels:copy`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    create(
        parent: string, $requestBody: ConversationModel_,
        namedParameters:
            ProjectsLocationsConversationModelsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.conversationModels.create',
        path: `/${this.gapiVersion}/${parent}/conversationModels`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsConversationModelsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.conversationModels.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    deploy(
        name: string, $requestBody: DeployConversationModelRequest_,
        namedParameters:
            ProjectsLocationsConversationModelsDeployNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.conversationModels.deploy',
        path: `/${this.gapiVersion}/${name}:deploy`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    encodeSuggestionList(
        model: string,
        $requestBody: EncodeSuggestionListToConversationModelRequest_,
        namedParameters:
            ProjectsLocationsConversationModelsEncodeSuggestionListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationModelSuggestionList_> {
      this.$apiClient.$validateParameter(
          model,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<ConversationModelSuggestionList_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationModels.encodeSuggestionList',
        path: `/${this.gapiVersion}/${model}:encodeSuggestionList`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationModelSuggestionList_
      });
    }

    generateConversationSummary(
        conversationModel: string,
        $requestBody: GenerateConversationSummaryRequest_,
        namedParameters:
            ProjectsLocationsConversationModelsGenerateConversationSummaryNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GenerateConversationSummaryResponse_> {
      this.$apiClient.$validateParameter(
          conversationModel,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<GenerateConversationSummaryResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationModels.generateConversationSummary',
        path: `/${this.gapiVersion}/${
            conversationModel}:generateConversationSummary`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GenerateConversationSummaryResponse_
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsConversationModelsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationModel_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationModel_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.conversationModels.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationModel_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsConversationModelsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationModelsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListConversationModelsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.conversationModels.list',
        path: `/${this.gapiVersion}/${parent}/conversationModels`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationModelsResponse_
      });
    }

    suggestIssues(
        model: string, $requestBody: SuggestIssuesRequest_,
        namedParameters:
            ProjectsLocationsConversationModelsSuggestIssuesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          model,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationModels.suggestIssues',
        path: `/${this.gapiVersion}/${model}:suggestIssues`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    suggestIssuesSync(
        model: string, $requestBody: SuggestIssuesRequest_,
        namedParameters:
            ProjectsLocationsConversationModelsSuggestIssuesSyncNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestIssuesResponse_> {
      this.$apiClient.$validateParameter(
          model,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<SuggestIssuesResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationModels.suggestIssuesSync',
        path: `/${this.gapiVersion}/${model}:suggestIssuesSync`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestIssuesResponse_
      });
    }

    undeploy(
        name: string, $requestBody: UndeployConversationModelRequest_,
        namedParameters:
            ProjectsLocationsConversationModelsUndeployNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.conversationModels.undeploy',
        path: `/${this.gapiVersion}/${name}:undeploy`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }
  }

  export abstract class ProjectsLocationsConversationModelsApiClient {
    constructor() {}

    abstract calculateSemanticMatch(
        model: string, $requestBody: CalculateSemanticMatchRequest_,
        namedParameters?:
            ProjectsLocationsConversationModelsCalculateSemanticMatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<CalculateSemanticMatchResponse_>;

    abstract checkSuggestionList(
        model: string,
        $requestBody: CheckConversationModelSuggestionListRequest_,
        namedParameters?:
            ProjectsLocationsConversationModelsCheckSuggestionListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationModelSuggestionList_>;

    abstract copy(
        parent: string, $requestBody: CopyConversationModelRequest_,
        namedParameters?:
            ProjectsLocationsConversationModelsCopyNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract create(
        parent: string, $requestBody: ConversationModel_,
        namedParameters?:
            ProjectsLocationsConversationModelsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationModelsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract deploy(
        name: string, $requestBody: DeployConversationModelRequest_,
        namedParameters?:
            ProjectsLocationsConversationModelsDeployNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract encodeSuggestionList(
        model: string,
        $requestBody: EncodeSuggestionListToConversationModelRequest_,
        namedParameters?:
            ProjectsLocationsConversationModelsEncodeSuggestionListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationModelSuggestionList_>;

    abstract generateConversationSummary(
        conversationModel: string,
        $requestBody: GenerateConversationSummaryRequest_,
        namedParameters?:
            ProjectsLocationsConversationModelsGenerateConversationSummaryNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GenerateConversationSummaryResponse_>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsConversationModelsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationModel_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationModelsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationModelsResponse_>;

    abstract suggestIssues(
        model: string, $requestBody: SuggestIssuesRequest_,
        namedParameters?:
            ProjectsLocationsConversationModelsSuggestIssuesNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract suggestIssuesSync(
        model: string, $requestBody: SuggestIssuesRequest_,
        namedParameters?:
            ProjectsLocationsConversationModelsSuggestIssuesSyncNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestIssuesResponse_>;

    abstract undeploy(
        name: string, $requestBody: UndeployConversationModelRequest_,
        namedParameters?:
            ProjectsLocationsConversationModelsUndeployNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;
  }

  export type ProjectsLocationsConversationModelsEvaluationsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsConversationModelsEvaluationsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsConversationModelsEvaluationsApiClient$Xgafv;
    readonly 2: ProjectsLocationsConversationModelsEvaluationsApiClient$Xgafv;

    values():
        Array<ProjectsLocationsConversationModelsEvaluationsApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationModelsEvaluationsApiClient$XgafvEnum:
      IProjectsLocationsConversationModelsEvaluationsApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationModelsEvaluationsApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationModelsEvaluationsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsConversationModelsEvaluationsApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationModelsEvaluationsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsConversationModelsEvaluationsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsConversationModelsEvaluationsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationModelsEvaluationsApiClientAltEnum {
    readonly JSON: ProjectsLocationsConversationModelsEvaluationsApiClientAlt;
    readonly MEDIA: ProjectsLocationsConversationModelsEvaluationsApiClientAlt;
    readonly PROTO: ProjectsLocationsConversationModelsEvaluationsApiClientAlt;

    values(): Array<ProjectsLocationsConversationModelsEvaluationsApiClientAlt>;
  }

  export const ProjectsLocationsConversationModelsEvaluationsApiClientAltEnum:
      IProjectsLocationsConversationModelsEvaluationsApiClientAltEnum = {
        JSON:
            <ProjectsLocationsConversationModelsEvaluationsApiClientAlt>'json',
        MEDIA:
            <ProjectsLocationsConversationModelsEvaluationsApiClientAlt>'media',
        PROTO:
            <ProjectsLocationsConversationModelsEvaluationsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsConversationModelsEvaluationsApiClientAlt> {
              return [
                ProjectsLocationsConversationModelsEvaluationsApiClientAltEnum
                    .JSON,
                ProjectsLocationsConversationModelsEvaluationsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsConversationModelsEvaluationsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationModelsEvaluationsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsEvaluationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsEvaluationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsEvaluationsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsEvaluationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsEvaluationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationModelsEvaluationsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationModelsEvaluationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationModelsEvaluationsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsConversationModelsEvaluationsApiClientImpl
      implements ProjectsLocationsConversationModelsEvaluationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: CreateConversationModelEvaluationRequest_,
        namedParameters:
            ProjectsLocationsConversationModelsEvaluationsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationModels.evaluations.create',
        path: `/${this.gapiVersion}/${parent}/evaluations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsConversationModelsEvaluationsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationModelEvaluation_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+/evaluations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationModelEvaluation_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversationModels.evaluations.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationModelEvaluation_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsConversationModelsEvaluationsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationModelEvaluationsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationModels/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          ListConversationModelEvaluationsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversationModels.evaluations.list',
        path: `/${this.gapiVersion}/${parent}/evaluations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationModelEvaluationsResponse_
      });
    }
  }

  export abstract class
      ProjectsLocationsConversationModelsEvaluationsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: CreateConversationModelEvaluationRequest_,
        namedParameters?:
            ProjectsLocationsConversationModelsEvaluationsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationModelsEvaluationsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationModelEvaluation_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationModelsEvaluationsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationModelEvaluationsResponse_>;
  }

  export type ProjectsLocationsConversationProfilesApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsConversationProfilesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsConversationProfilesApiClient$Xgafv;
    readonly 2: ProjectsLocationsConversationProfilesApiClient$Xgafv;

    values(): Array<ProjectsLocationsConversationProfilesApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationProfilesApiClient$XgafvEnum:
      IProjectsLocationsConversationProfilesApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationProfilesApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationProfilesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsConversationProfilesApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationProfilesApiClient$XgafvEnum[1],
                ProjectsLocationsConversationProfilesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsConversationProfilesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationProfilesApiClientAltEnum {
    readonly JSON: ProjectsLocationsConversationProfilesApiClientAlt;
    readonly MEDIA: ProjectsLocationsConversationProfilesApiClientAlt;
    readonly PROTO: ProjectsLocationsConversationProfilesApiClientAlt;

    values(): Array<ProjectsLocationsConversationProfilesApiClientAlt>;
  }

  export const ProjectsLocationsConversationProfilesApiClientAltEnum:
      IProjectsLocationsConversationProfilesApiClientAltEnum = {
        JSON: <ProjectsLocationsConversationProfilesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsConversationProfilesApiClientAlt>'media',
        PROTO: <ProjectsLocationsConversationProfilesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsConversationProfilesApiClientAlt> {
              return [
                ProjectsLocationsConversationProfilesApiClientAltEnum.JSON,
                ProjectsLocationsConversationProfilesApiClientAltEnum.MEDIA,
                ProjectsLocationsConversationProfilesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationProfilesClearSuggestionFeatureConfigNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationProfilesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationProfilesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationProfilesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationProfilesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationProfilesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationProfilesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationProfilesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationProfilesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationProfilesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsConversationProfilesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationProfilesApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsLocationsConversationProfilesSetSuggestionFeatureConfigNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationProfilesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationProfilesApiClient$Xgafv;
  }

  export class ProjectsLocationsConversationProfilesApiClientImpl implements
      ProjectsLocationsConversationProfilesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    clearSuggestionFeatureConfig(
        conversationProfile: string,
        $requestBody: ClearSuggestionFeatureConfigRequest_,
        namedParameters:
            ProjectsLocationsConversationProfilesClearSuggestionFeatureConfigNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          conversationProfile,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationProfiles/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationProfiles.clearSuggestionFeatureConfig',
        path: `/${this.gapiVersion}/${
            conversationProfile}:clearSuggestionFeatureConfig`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    create(
        parent: string, $requestBody: ConversationProfile_,
        namedParameters:
            ProjectsLocationsConversationProfilesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationProfile_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<ConversationProfile_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.conversationProfiles.create',
        path: `/${this.gapiVersion}/${parent}/conversationProfiles`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationProfile_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsConversationProfilesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationProfiles/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.conversationProfiles.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsConversationProfilesGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationProfile_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationProfiles/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationProfile_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.conversationProfiles.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationProfile_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsConversationProfilesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationProfilesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListConversationProfilesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.conversationProfiles.list',
        path: `/${this.gapiVersion}/${parent}/conversationProfiles`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationProfilesResponse_
      });
    }

    patch(
        name: string, $requestBody: ConversationProfile_,
        namedParameters:
            ProjectsLocationsConversationProfilesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationProfile_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationProfiles/[^/]+$'));

      return this.$apiClient.$request<ConversationProfile_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.conversationProfiles.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationProfile_
      });
    }

    setSuggestionFeatureConfig(
        conversationProfile: string,
        $requestBody: SetSuggestionFeatureConfigRequest_,
        namedParameters:
            ProjectsLocationsConversationProfilesSetSuggestionFeatureConfigNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          conversationProfile,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversationProfiles/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversationProfiles.setSuggestionFeatureConfig',
        path: `/${this.gapiVersion}/${
            conversationProfile}:setSuggestionFeatureConfig`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }
  }

  export abstract class ProjectsLocationsConversationProfilesApiClient {
    constructor() {}

    abstract clearSuggestionFeatureConfig(
        conversationProfile: string,
        $requestBody: ClearSuggestionFeatureConfigRequest_,
        namedParameters?:
            ProjectsLocationsConversationProfilesClearSuggestionFeatureConfigNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract create(
        parent: string, $requestBody: ConversationProfile_,
        namedParameters?:
            ProjectsLocationsConversationProfilesCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationProfile_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationProfilesDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationProfilesGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationProfile_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationProfilesListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationProfilesResponse_>;

    abstract patch(
        name: string, $requestBody: ConversationProfile_,
        namedParameters?:
            ProjectsLocationsConversationProfilesPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationProfile_>;

    abstract setSuggestionFeatureConfig(
        conversationProfile: string,
        $requestBody: SetSuggestionFeatureConfigRequest_,
        namedParameters?:
            ProjectsLocationsConversationProfilesSetSuggestionFeatureConfigNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;
  }

  export type ProjectsLocationsConversationsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsConversationsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsConversationsApiClient$Xgafv;
    readonly 2: ProjectsLocationsConversationsApiClient$Xgafv;

    values(): Array<ProjectsLocationsConversationsApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationsApiClient$XgafvEnum:
      IProjectsLocationsConversationsApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationsApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsConversationsApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationsApiClient$XgafvEnum[1],
                ProjectsLocationsConversationsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsConversationsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationsApiClientAltEnum {
    readonly JSON: ProjectsLocationsConversationsApiClientAlt;
    readonly MEDIA: ProjectsLocationsConversationsApiClientAlt;
    readonly PROTO: ProjectsLocationsConversationsApiClientAlt;

    values(): Array<ProjectsLocationsConversationsApiClientAlt>;
  }

  export const ProjectsLocationsConversationsApiClientAltEnum:
      IProjectsLocationsConversationsApiClientAltEnum = {
        JSON: <ProjectsLocationsConversationsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsConversationsApiClientAlt>'media',
        PROTO: <ProjectsLocationsConversationsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsConversationsApiClientAlt> {
              return [
                ProjectsLocationsConversationsApiClientAltEnum.JSON,
                ProjectsLocationsConversationsApiClientAltEnum.MEDIA,
                ProjectsLocationsConversationsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationsActivateConversationNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsAddConversationPhoneNumberNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsCompleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
    conversationId?: string;
  }

  export declare interface ProjectsLocationsConversationsDeactivateConversationNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsExportMessagesNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
    filter?: string;
    gcsDestinationUri?: string;
    internalExportAsProtoAddFeedbackLoopMetrics?: boolean;
    internalAddAgentAssistMetrics?: boolean;
    internalIncludeOpenConversations?: boolean;
  }

  export declare interface ProjectsLocationsConversationsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsInjectCallCompanionUserInputNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsLocationsConversationsListPastCallCompanionEventsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsConversationsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsLocationsConversationsStreamingListUpcomingCallCompanionEventsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsApiClient$Xgafv;
  }

  export class ProjectsLocationsConversationsApiClientImpl implements
      ProjectsLocationsConversationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    activateConversation(
        conversation: string, $requestBody: ActivateConversationRequest_,
        namedParameters:
            ProjectsLocationsConversationsActivateConversationNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          conversation,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.activateConversation',
        path: `/${this.gapiVersion}/${conversation}:activateConversation`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    addConversationPhoneNumber(
        name: string, $requestBody: AddConversationPhoneNumberRequest_,
        namedParameters:
            ProjectsLocationsConversationsAddConversationPhoneNumberNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationPhoneNumber_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<ConversationPhoneNumber_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.addConversationPhoneNumber',
        path: `/${this.gapiVersion}/${name}:addConversationPhoneNumber`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationPhoneNumber_
      });
    }

    complete(
        name: string, $requestBody: CompleteConversationRequest_,
        namedParameters: ProjectsLocationsConversationsCompleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.conversations.complete',
        path: `/${this.gapiVersion}/${name}:complete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    create(
        parent: string, $requestBody: Conversation_,
        namedParameters: ProjectsLocationsConversationsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.conversations.create',
        path: `/${this.gapiVersion}/${parent}/conversations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    deactivateConversation(
        conversation: string, $requestBody: DeactivateConversationRequest_,
        namedParameters:
            ProjectsLocationsConversationsDeactivateConversationNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          conversation,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.deactivateConversation',
        path: `/${this.gapiVersion}/${conversation}:deactivateConversation`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    exportMessages(
        parent: string,
        namedParameters:
            ProjectsLocationsConversationsExportMessagesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.conversations.exportMessages',
        path: `/${this.gapiVersion}/${parent}:exportMessages`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsConversationsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.conversations.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    injectCallCompanionUserInput(
        conversation: string,
        $requestBody: InjectCallCompanionUserInputRequest_,
        namedParameters:
            ProjectsLocationsConversationsInjectCallCompanionUserInputNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<InjectCallCompanionUserInputResponse_> {
      this.$apiClient.$validateParameter(
          conversation,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<InjectCallCompanionUserInputResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.injectCallCompanionUserInput',
        path:
            `/${this.gapiVersion}/${conversation}:injectCallCompanionUserInput`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: InjectCallCompanionUserInputResponse_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsConversationsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListConversationsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListConversationsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.conversations.list',
        path: `/${this.gapiVersion}/${parent}/conversations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListConversationsResponse_
      });
    }

    listPastCallCompanionEvents(
        conversation: string,
        namedParameters:
            ProjectsLocationsConversationsListPastCallCompanionEventsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListPastCallCompanionEventsResponse_> {
      this.$apiClient.$validateParameter(
          conversation,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListPastCallCompanionEventsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversations.listPastCallCompanionEvents',
        path:
            `/${this.gapiVersion}/${conversation}:listPastCallCompanionEvents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListPastCallCompanionEventsResponse_
      });
    }

    patch(
        name: string, $requestBody: Conversation_,
        namedParameters: ProjectsLocationsConversationsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Conversation_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Conversation_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.conversations.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Conversation_
      });
    }

    streamingListUpcomingCallCompanionEvents(
        conversation: string,
        namedParameters:
            ProjectsLocationsConversationsStreamingListUpcomingCallCompanionEventsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<StreamingListUpcomingCallCompanionEventsResponse_> {
      this.$apiClient.$validateParameter(
          conversation,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<
          StreamingListUpcomingCallCompanionEventsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversations.streamingListUpcomingCallCompanionEvents',
        path: `/${this.gapiVersion}/${
            conversation}:streamingListUpcomingCallCompanionEvents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: StreamingListUpcomingCallCompanionEventsResponse_
      });
    }
  }

  export abstract class ProjectsLocationsConversationsApiClient {
    constructor() {}

    abstract activateConversation(
        conversation: string, $requestBody: ActivateConversationRequest_,
        namedParameters?:
            ProjectsLocationsConversationsActivateConversationNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract addConversationPhoneNumber(
        name: string, $requestBody: AddConversationPhoneNumberRequest_,
        namedParameters?:
            ProjectsLocationsConversationsAddConversationPhoneNumberNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationPhoneNumber_>;

    abstract complete(
        name: string, $requestBody: CompleteConversationRequest_,
        namedParameters?: ProjectsLocationsConversationsCompleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract create(
        parent: string, $requestBody: Conversation_,
        namedParameters?: ProjectsLocationsConversationsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract deactivateConversation(
        conversation: string, $requestBody: DeactivateConversationRequest_,
        namedParameters?:
            ProjectsLocationsConversationsDeactivateConversationNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract exportMessages(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationsExportMessagesNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsConversationsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract injectCallCompanionUserInput(
        conversation: string,
        $requestBody: InjectCallCompanionUserInputRequest_,
        namedParameters?:
            ProjectsLocationsConversationsInjectCallCompanionUserInputNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<InjectCallCompanionUserInputResponse_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsConversationsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListConversationsResponse_>;

    abstract listPastCallCompanionEvents(
        conversation: string,
        namedParameters?:
            ProjectsLocationsConversationsListPastCallCompanionEventsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListPastCallCompanionEventsResponse_>;

    abstract patch(
        name: string, $requestBody: Conversation_,
        namedParameters?: ProjectsLocationsConversationsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Conversation_>;

    abstract streamingListUpcomingCallCompanionEvents(
        conversation: string,
        namedParameters?:
            ProjectsLocationsConversationsStreamingListUpcomingCallCompanionEventsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<StreamingListUpcomingCallCompanionEventsResponse_>;
  }

  export type ProjectsLocationsConversationsCallMatchersApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsConversationsCallMatchersApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsConversationsCallMatchersApiClient$Xgafv;
    readonly 2: ProjectsLocationsConversationsCallMatchersApiClient$Xgafv;

    values(): Array<ProjectsLocationsConversationsCallMatchersApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationsCallMatchersApiClient$XgafvEnum:
      IProjectsLocationsConversationsCallMatchersApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationsCallMatchersApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationsCallMatchersApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsConversationsCallMatchersApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationsCallMatchersApiClient$XgafvEnum
                    [1],
                ProjectsLocationsConversationsCallMatchersApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsConversationsCallMatchersApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationsCallMatchersApiClientAltEnum {
    readonly JSON: ProjectsLocationsConversationsCallMatchersApiClientAlt;
    readonly MEDIA: ProjectsLocationsConversationsCallMatchersApiClientAlt;
    readonly PROTO: ProjectsLocationsConversationsCallMatchersApiClientAlt;

    values(): Array<ProjectsLocationsConversationsCallMatchersApiClientAlt>;
  }

  export const ProjectsLocationsConversationsCallMatchersApiClientAltEnum:
      IProjectsLocationsConversationsCallMatchersApiClientAltEnum = {
        JSON: <ProjectsLocationsConversationsCallMatchersApiClientAlt>'json',
        MEDIA: <ProjectsLocationsConversationsCallMatchersApiClientAlt>'media',
        PROTO: <ProjectsLocationsConversationsCallMatchersApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsConversationsCallMatchersApiClientAlt> {
              return [
                ProjectsLocationsConversationsCallMatchersApiClientAltEnum.JSON,
                ProjectsLocationsConversationsCallMatchersApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsConversationsCallMatchersApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationsCallMatchersCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsCallMatchersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsCallMatchersApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsCallMatchersDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsCallMatchersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsCallMatchersApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsCallMatchersListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsCallMatchersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsCallMatchersApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsConversationsCallMatchersApiClientImpl
      implements ProjectsLocationsConversationsCallMatchersApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: CreateCallMatcherRequest_,
        namedParameters:
            ProjectsLocationsConversationsCallMatchersCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<CallMatcher_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<CallMatcher_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.callMatchers.create',
        path: `/${this.gapiVersion}/${parent}/callMatchers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: CallMatcher_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsConversationsCallMatchersDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/callMatchers/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.conversations.callMatchers.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsConversationsCallMatchersListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListCallMatchersResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListCallMatchersResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversations.callMatchers.list',
        path: `/${this.gapiVersion}/${parent}/callMatchers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListCallMatchersResponse_
      });
    }
  }

  export abstract class ProjectsLocationsConversationsCallMatchersApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: CreateCallMatcherRequest_,
        namedParameters?:
            ProjectsLocationsConversationsCallMatchersCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<CallMatcher_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationsCallMatchersDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationsCallMatchersListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListCallMatchersResponse_>;
  }

  export type ProjectsLocationsConversationsMessagesApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsConversationsMessagesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsConversationsMessagesApiClient$Xgafv;
    readonly 2: ProjectsLocationsConversationsMessagesApiClient$Xgafv;

    values(): Array<ProjectsLocationsConversationsMessagesApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationsMessagesApiClient$XgafvEnum:
      IProjectsLocationsConversationsMessagesApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationsMessagesApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationsMessagesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsConversationsMessagesApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationsMessagesApiClient$XgafvEnum[1],
                ProjectsLocationsConversationsMessagesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsConversationsMessagesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationsMessagesApiClientAltEnum {
    readonly JSON: ProjectsLocationsConversationsMessagesApiClientAlt;
    readonly MEDIA: ProjectsLocationsConversationsMessagesApiClientAlt;
    readonly PROTO: ProjectsLocationsConversationsMessagesApiClientAlt;

    values(): Array<ProjectsLocationsConversationsMessagesApiClientAlt>;
  }

  export const ProjectsLocationsConversationsMessagesApiClientAltEnum:
      IProjectsLocationsConversationsMessagesApiClientAltEnum = {
        JSON: <ProjectsLocationsConversationsMessagesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsConversationsMessagesApiClientAlt>'media',
        PROTO: <ProjectsLocationsConversationsMessagesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsConversationsMessagesApiClientAlt> {
              return [
                ProjectsLocationsConversationsMessagesApiClientAltEnum.JSON,
                ProjectsLocationsConversationsMessagesApiClientAltEnum.MEDIA,
                ProjectsLocationsConversationsMessagesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationsMessagesBatchCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsMessagesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsMessagesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsMessagesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsMessagesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsMessagesApiClient$Xgafv;
    filter?: string;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsConversationsMessagesApiClientImpl implements
      ProjectsLocationsConversationsMessagesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchCreate(
        parent: string, $requestBody: BatchCreateMessagesRequest_,
        namedParameters:
            ProjectsLocationsConversationsMessagesBatchCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<BatchCreateMessagesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<BatchCreateMessagesResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.messages.batchCreate',
        path: `/${this.gapiVersion}/${parent}/messages:batchCreate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: BatchCreateMessagesResponse_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsConversationsMessagesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListMessagesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListMessagesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.conversations.messages.list',
        path: `/${this.gapiVersion}/${parent}/messages`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListMessagesResponse_
      });
    }
  }

  export abstract class ProjectsLocationsConversationsMessagesApiClient {
    constructor() {}

    abstract batchCreate(
        parent: string, $requestBody: BatchCreateMessagesRequest_,
        namedParameters?:
            ProjectsLocationsConversationsMessagesBatchCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<BatchCreateMessagesResponse_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationsMessagesListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListMessagesResponse_>;
  }

  export type ProjectsLocationsConversationsParticipantsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsConversationsParticipantsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsConversationsParticipantsApiClient$Xgafv;
    readonly 2: ProjectsLocationsConversationsParticipantsApiClient$Xgafv;

    values(): Array<ProjectsLocationsConversationsParticipantsApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationsParticipantsApiClient$XgafvEnum:
      IProjectsLocationsConversationsParticipantsApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationsParticipantsApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationsParticipantsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsConversationsParticipantsApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationsParticipantsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsConversationsParticipantsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsConversationsParticipantsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationsParticipantsApiClientAltEnum {
    readonly JSON: ProjectsLocationsConversationsParticipantsApiClientAlt;
    readonly MEDIA: ProjectsLocationsConversationsParticipantsApiClientAlt;
    readonly PROTO: ProjectsLocationsConversationsParticipantsApiClientAlt;

    values(): Array<ProjectsLocationsConversationsParticipantsApiClientAlt>;
  }

  export const ProjectsLocationsConversationsParticipantsApiClientAltEnum:
      IProjectsLocationsConversationsParticipantsApiClientAltEnum = {
        JSON: <ProjectsLocationsConversationsParticipantsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsConversationsParticipantsApiClientAlt>'media',
        PROTO: <ProjectsLocationsConversationsParticipantsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsConversationsParticipantsApiClientAlt> {
              return [
                ProjectsLocationsConversationsParticipantsApiClientAltEnum.JSON,
                ProjectsLocationsConversationsParticipantsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsConversationsParticipantsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationsParticipantsAnalyzeContentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsParticipantsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsParticipantsBatchCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsParticipantsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsParticipantsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsParticipantsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsParticipantsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsParticipantsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsParticipantsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsParticipantsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsLocationsConversationsParticipantsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsParticipantsApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsLocationsConversationsParticipantsApiClientImpl
      implements ProjectsLocationsConversationsParticipantsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    analyzeContent(
        participant: string, $requestBody: AnalyzeContentRequest_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsAnalyzeContentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<AnalyzeContentResponse_> {
      this.$apiClient.$validateParameter(
          participant,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<AnalyzeContentResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.participants.analyzeContent',
        path: `/${this.gapiVersion}/${participant}:analyzeContent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: AnalyzeContentResponse_
      });
    }

    batchCreate(
        parent: string, $requestBody: BatchCreateParticipantsRequest_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsBatchCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<BatchCreateParticipantsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<BatchCreateParticipantsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.participants.batchCreate',
        path: `/${this.gapiVersion}/${parent}/participants:batchCreate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: BatchCreateParticipantsResponse_
      });
    }

    create(
        parent: string, $requestBody: Participant_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Participant_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<Participant_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.participants.create',
        path: `/${this.gapiVersion}/${parent}/participants`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Participant_
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsConversationsParticipantsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Participant_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/participants/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Participant_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversations.participants.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Participant_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsConversationsParticipantsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListParticipantsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListParticipantsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversations.participants.list',
        path: `/${this.gapiVersion}/${parent}/participants`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListParticipantsResponse_
      });
    }

    patch(
        name: string, $requestBody: Participant_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Participant_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<Participant_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.locations.conversations.participants.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Participant_
      });
    }
  }

  export abstract class ProjectsLocationsConversationsParticipantsApiClient {
    constructor() {}

    abstract analyzeContent(
        participant: string, $requestBody: AnalyzeContentRequest_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsAnalyzeContentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<AnalyzeContentResponse_>;

    abstract batchCreate(
        parent: string, $requestBody: BatchCreateParticipantsRequest_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsBatchCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<BatchCreateParticipantsResponse_>;

    abstract create(
        parent: string, $requestBody: Participant_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Participant_>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Participant_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListParticipantsResponse_>;

    abstract patch(
        name: string, $requestBody: Participant_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Participant_>;
  }

  export type ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsConversationsParticipantsSuggestionsApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationsParticipantsSuggestionsApiClient$XgafvEnum:
      IProjectsLocationsConversationsParticipantsSuggestionsApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationsParticipantsSuggestionsApiClient$XgafvEnum
                    [1],
                ProjectsLocationsConversationsParticipantsSuggestionsApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationsParticipantsSuggestionsApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt;
    readonly PROTO:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt;

    values(): Array<
        ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt>;
  }

  export const ProjectsLocationsConversationsParticipantsSuggestionsApiClientAltEnum:
      IProjectsLocationsConversationsParticipantsSuggestionsApiClientAltEnum = {
        JSON: <
            ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt> {
              return [
                ProjectsLocationsConversationsParticipantsSuggestionsApiClientAltEnum
                    .JSON,
                ProjectsLocationsConversationsParticipantsSuggestionsApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsConversationsParticipantsSuggestionsApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationsParticipantsSuggestionsSuggestArticlesNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsParticipantsSuggestionsSuggestConversationGuidancesNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsParticipantsSuggestionsSuggestDialogflowAssistsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsParticipantsSuggestionsSuggestFaqAnswersNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsParticipantsSuggestionsSuggestSmartComposeAnswersNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsParticipantsSuggestionsSuggestSmartRepliesNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsParticipantsSuggestionsSuggestSpellGrammarCorrectionAnswersNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsParticipantsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsConversationsParticipantsSuggestionsApiClient$Xgafv;
  }

  export class
      ProjectsLocationsConversationsParticipantsSuggestionsApiClientImpl
          implements
              ProjectsLocationsConversationsParticipantsSuggestionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    suggestArticles(
        parent: string, $requestBody: SuggestArticlesRequest_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestArticlesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestArticlesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestArticlesResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.participants.suggestions.suggestArticles',
        path: `/${this.gapiVersion}/${parent}/suggestions:suggestArticles`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestArticlesResponse_
      });
    }

    suggestConversationGuidances(
        parent: string, $requestBody: SuggestConversationGuidancesRequest_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestConversationGuidancesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestConversationGuidancesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestConversationGuidancesResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.participants.suggestions.suggestConversationGuidances',
        path: `/${this.gapiVersion}/${
            parent}/suggestions:suggestConversationGuidances`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestConversationGuidancesResponse_
      });
    }

    suggestDialogflowAssists(
        parent: string, $requestBody: SuggestDialogflowAssistsRequest_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestDialogflowAssistsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestDialogflowAssistsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestDialogflowAssistsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.participants.suggestions.suggestDialogflowAssists',
        path: `/${this.gapiVersion}/${
            parent}/suggestions:suggestDialogflowAssists`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestDialogflowAssistsResponse_
      });
    }

    suggestFaqAnswers(
        parent: string, $requestBody: SuggestFaqAnswersRequest_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestFaqAnswersNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestFaqAnswersResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestFaqAnswersResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.participants.suggestions.suggestFaqAnswers',
        path: `/${this.gapiVersion}/${parent}/suggestions:suggestFaqAnswers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestFaqAnswersResponse_
      });
    }

    suggestSmartComposeAnswers(
        parent: string, $requestBody: SuggestSmartComposeAnswersRequest_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestSmartComposeAnswersNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestSmartComposeAnswersResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestSmartComposeAnswersResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.participants.suggestions.suggestSmartComposeAnswers',
        path: `/${this.gapiVersion}/${
            parent}/suggestions:suggestSmartComposeAnswers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestSmartComposeAnswersResponse_
      });
    }

    suggestSmartReplies(
        parent: string, $requestBody: SuggestSmartRepliesRequest_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestSmartRepliesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestSmartRepliesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<SuggestSmartRepliesResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.participants.suggestions.suggestSmartReplies',
        path: `/${this.gapiVersion}/${parent}/suggestions:suggestSmartReplies`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestSmartRepliesResponse_
      });
    }

    suggestSpellGrammarCorrectionAnswers(
        parent: string,
        $requestBody: SuggestSpellGrammarCorrectionAnswersRequest_,
        namedParameters:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestSpellGrammarCorrectionAnswersNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestSpellGrammarCorrectionAnswersResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/conversations/[^/]+/participants/[^/]+$'));

      return this.$apiClient.$request<
          SuggestSpellGrammarCorrectionAnswersResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.participants.suggestions.suggestSpellGrammarCorrectionAnswers',
        path: `/${this.gapiVersion}/${
            parent}/suggestions:suggestSpellGrammarCorrectionAnswers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestSpellGrammarCorrectionAnswersResponse_
      });
    }
  }

  export abstract class
      ProjectsLocationsConversationsParticipantsSuggestionsApiClient {
    constructor() {}

    abstract suggestArticles(
        parent: string, $requestBody: SuggestArticlesRequest_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestArticlesNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestArticlesResponse_>;

    abstract suggestConversationGuidances(
        parent: string, $requestBody: SuggestConversationGuidancesRequest_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestConversationGuidancesNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestConversationGuidancesResponse_>;

    abstract suggestDialogflowAssists(
        parent: string, $requestBody: SuggestDialogflowAssistsRequest_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestDialogflowAssistsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestDialogflowAssistsResponse_>;

    abstract suggestFaqAnswers(
        parent: string, $requestBody: SuggestFaqAnswersRequest_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestFaqAnswersNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestFaqAnswersResponse_>;

    abstract suggestSmartComposeAnswers(
        parent: string, $requestBody: SuggestSmartComposeAnswersRequest_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestSmartComposeAnswersNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestSmartComposeAnswersResponse_>;

    abstract suggestSmartReplies(
        parent: string, $requestBody: SuggestSmartRepliesRequest_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestSmartRepliesNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestSmartRepliesResponse_>;

    abstract suggestSpellGrammarCorrectionAnswers(
        parent: string,
        $requestBody: SuggestSpellGrammarCorrectionAnswersRequest_,
        namedParameters?:
            ProjectsLocationsConversationsParticipantsSuggestionsSuggestSpellGrammarCorrectionAnswersNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestSpellGrammarCorrectionAnswersResponse_>;
  }

  export type ProjectsLocationsConversationsSuggestionsApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsConversationsSuggestionsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsConversationsSuggestionsApiClient$Xgafv;
    readonly 2: ProjectsLocationsConversationsSuggestionsApiClient$Xgafv;

    values(): Array<ProjectsLocationsConversationsSuggestionsApiClient$Xgafv>;
  }

  export const ProjectsLocationsConversationsSuggestionsApiClient$XgafvEnum:
      IProjectsLocationsConversationsSuggestionsApiClient$XgafvEnum = {
        1: <ProjectsLocationsConversationsSuggestionsApiClient$Xgafv>'1',
        2: <ProjectsLocationsConversationsSuggestionsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsConversationsSuggestionsApiClient$Xgafv> {
              return [
                ProjectsLocationsConversationsSuggestionsApiClient$XgafvEnum[1],
                ProjectsLocationsConversationsSuggestionsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsConversationsSuggestionsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsConversationsSuggestionsApiClientAltEnum {
    readonly JSON: ProjectsLocationsConversationsSuggestionsApiClientAlt;
    readonly MEDIA: ProjectsLocationsConversationsSuggestionsApiClientAlt;
    readonly PROTO: ProjectsLocationsConversationsSuggestionsApiClientAlt;

    values(): Array<ProjectsLocationsConversationsSuggestionsApiClientAlt>;
  }

  export const ProjectsLocationsConversationsSuggestionsApiClientAltEnum:
      IProjectsLocationsConversationsSuggestionsApiClientAltEnum = {
        JSON: <ProjectsLocationsConversationsSuggestionsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsConversationsSuggestionsApiClientAlt>'media',
        PROTO: <ProjectsLocationsConversationsSuggestionsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsConversationsSuggestionsApiClientAlt> {
              return [
                ProjectsLocationsConversationsSuggestionsApiClientAltEnum.JSON,
                ProjectsLocationsConversationsSuggestionsApiClientAltEnum.MEDIA,
                ProjectsLocationsConversationsSuggestionsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsConversationsSuggestionsSearchArticlesNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsSuggestionsApiClient$Xgafv;
    queryText?: string;
    queryLanguageCode?: string;
    queryEnableSpellingCorrection?: boolean;
    queryEnableSplittingText?: boolean;
    queryIsAudioInput?: boolean;
  }

  export declare interface ProjectsLocationsConversationsSuggestionsSuggestConversationKeyMomentsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsSuggestionsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsConversationsSuggestionsSuggestConversationSummaryNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsConversationsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsConversationsSuggestionsApiClient$Xgafv;
  }

  export class ProjectsLocationsConversationsSuggestionsApiClientImpl implements
      ProjectsLocationsConversationsSuggestionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    searchArticles(
        conversation: string,
        namedParameters:
            ProjectsLocationsConversationsSuggestionsSearchArticlesNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SearchArticlesResponse_> {
      this.$apiClient.$validateParameter(
          conversation,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SearchArticlesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.conversations.suggestions.searchArticles',
        path: `/${this.gapiVersion}/${conversation}/suggestions:searchArticles`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SearchArticlesResponse_
      });
    }

    suggestConversationKeyMoments(
        conversation: string,
        $requestBody: SuggestConversationKeyMomentsRequest_,
        namedParameters:
            ProjectsLocationsConversationsSuggestionsSuggestConversationKeyMomentsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestConversationKeyMomentsResponse_> {
      this.$apiClient.$validateParameter(
          conversation,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<SuggestConversationKeyMomentsResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.suggestions.suggestConversationKeyMoments',
        path: `/${this.gapiVersion}/${
            conversation}/suggestions:suggestConversationKeyMoments`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestConversationKeyMomentsResponse_
      });
    }

    suggestConversationSummary(
        conversation: string, $requestBody: SuggestConversationSummaryRequest_,
        namedParameters:
            ProjectsLocationsConversationsSuggestionsSuggestConversationSummaryNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestConversationSummaryResponse_> {
      this.$apiClient.$validateParameter(
          conversation,
          new RegExp('^projects/[^/]+/locations/[^/]+/conversations/[^/]+$'));

      return this.$apiClient.$request<SuggestConversationSummaryResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.conversations.suggestions.suggestConversationSummary',
        path: `/${this.gapiVersion}/${
            conversation}/suggestions:suggestConversationSummary`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestConversationSummaryResponse_
      });
    }
  }

  export abstract class ProjectsLocationsConversationsSuggestionsApiClient {
    constructor() {}

    abstract searchArticles(
        conversation: string,
        namedParameters?:
            ProjectsLocationsConversationsSuggestionsSearchArticlesNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SearchArticlesResponse_>;

    abstract suggestConversationKeyMoments(
        conversation: string,
        $requestBody: SuggestConversationKeyMomentsRequest_,
        namedParameters?:
            ProjectsLocationsConversationsSuggestionsSuggestConversationKeyMomentsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestConversationKeyMomentsResponse_>;

    abstract suggestConversationSummary(
        conversation: string, $requestBody: SuggestConversationSummaryRequest_,
        namedParameters?:
            ProjectsLocationsConversationsSuggestionsSuggestConversationSummaryNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestConversationSummaryResponse_>;
  }

  export type ProjectsLocationsDatasetsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsDatasetsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsDatasetsApiClient$Xgafv;
    readonly 2: ProjectsLocationsDatasetsApiClient$Xgafv;

    values(): Array<ProjectsLocationsDatasetsApiClient$Xgafv>;
  }

  export const ProjectsLocationsDatasetsApiClient$XgafvEnum:
      IProjectsLocationsDatasetsApiClient$XgafvEnum = {
        1: <ProjectsLocationsDatasetsApiClient$Xgafv>'1',
        2: <ProjectsLocationsDatasetsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsDatasetsApiClient$Xgafv> {
              return [
                ProjectsLocationsDatasetsApiClient$XgafvEnum[1],
                ProjectsLocationsDatasetsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsDatasetsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsDatasetsApiClientAltEnum {
    readonly JSON: ProjectsLocationsDatasetsApiClientAlt;
    readonly MEDIA: ProjectsLocationsDatasetsApiClientAlt;
    readonly PROTO: ProjectsLocationsDatasetsApiClientAlt;

    values(): Array<ProjectsLocationsDatasetsApiClientAlt>;
  }

  export const ProjectsLocationsDatasetsApiClientAltEnum:
      IProjectsLocationsDatasetsApiClientAltEnum = {
        JSON: <ProjectsLocationsDatasetsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsDatasetsApiClientAlt>'media',
        PROTO: <ProjectsLocationsDatasetsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsDatasetsApiClientAlt> {
              return [
                ProjectsLocationsDatasetsApiClientAltEnum.JSON,
                ProjectsLocationsDatasetsApiClientAltEnum.MEDIA,
                ProjectsLocationsDatasetsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsDatasetsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsDatasetsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsDatasetsApiClientImpl implements
      ProjectsLocationsDatasetsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Dataset_,
        namedParameters: ProjectsLocationsDatasetsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Dataset_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<Dataset_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.datasets.create',
        path: `/${this.gapiVersion}/${parent}/datasets`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Dataset_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsDatasetsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListDatasetsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListDatasetsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.datasets.list',
        path: `/${this.gapiVersion}/${parent}/datasets`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListDatasetsResponse_
      });
    }
  }

  export abstract class ProjectsLocationsDatasetsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: Dataset_,
        namedParameters?: ProjectsLocationsDatasetsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Dataset_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsDatasetsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListDatasetsResponse_>;
  }

  export type ProjectsLocationsDatasetsClustersApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsDatasetsClustersApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsDatasetsClustersApiClient$Xgafv;
    readonly 2: ProjectsLocationsDatasetsClustersApiClient$Xgafv;

    values(): Array<ProjectsLocationsDatasetsClustersApiClient$Xgafv>;
  }

  export const ProjectsLocationsDatasetsClustersApiClient$XgafvEnum:
      IProjectsLocationsDatasetsClustersApiClient$XgafvEnum = {
        1: <ProjectsLocationsDatasetsClustersApiClient$Xgafv>'1',
        2: <ProjectsLocationsDatasetsClustersApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsDatasetsClustersApiClient$Xgafv> {
              return [
                ProjectsLocationsDatasetsClustersApiClient$XgafvEnum[1],
                ProjectsLocationsDatasetsClustersApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsDatasetsClustersApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsDatasetsClustersApiClientAltEnum {
    readonly JSON: ProjectsLocationsDatasetsClustersApiClientAlt;
    readonly MEDIA: ProjectsLocationsDatasetsClustersApiClientAlt;
    readonly PROTO: ProjectsLocationsDatasetsClustersApiClientAlt;

    values(): Array<ProjectsLocationsDatasetsClustersApiClientAlt>;
  }

  export const ProjectsLocationsDatasetsClustersApiClientAltEnum:
      IProjectsLocationsDatasetsClustersApiClientAltEnum = {
        JSON: <ProjectsLocationsDatasetsClustersApiClientAlt>'json',
        MEDIA: <ProjectsLocationsDatasetsClustersApiClientAlt>'media',
        PROTO: <ProjectsLocationsDatasetsClustersApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsDatasetsClustersApiClientAlt> {
              return [
                ProjectsLocationsDatasetsClustersApiClientAltEnum.JSON,
                ProjectsLocationsDatasetsClustersApiClientAltEnum.MEDIA,
                ProjectsLocationsDatasetsClustersApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsDatasetsClustersListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsClustersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsClustersApiClient$Xgafv;
    filterTopicId?: string;
    filterClusterId?: string;
    filterMessageContent?: string;
    filterTriggeringIntentScoreThreshold?: number;
    filterMessageReferenceConversationId?: string;
    filterMessageReferenceTurnId?: number;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsDatasetsClustersApiClientImpl implements
      ProjectsLocationsDatasetsClustersApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsDatasetsClustersListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListMessageClustersResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/datasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListMessageClustersResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.datasets.clusters.list',
        path: `/${this.gapiVersion}/${parent}/clusters`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListMessageClustersResponse_
      });
    }
  }

  export abstract class ProjectsLocationsDatasetsClustersApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsDatasetsClustersListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListMessageClustersResponse_>;
  }

  export type ProjectsLocationsDatasetsClustersMessagesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsDatasetsClustersMessagesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsDatasetsClustersMessagesApiClient$Xgafv;
    readonly 2: ProjectsLocationsDatasetsClustersMessagesApiClient$Xgafv;

    values(): Array<ProjectsLocationsDatasetsClustersMessagesApiClient$Xgafv>;
  }

  export const ProjectsLocationsDatasetsClustersMessagesApiClient$XgafvEnum:
      IProjectsLocationsDatasetsClustersMessagesApiClient$XgafvEnum = {
        1: <ProjectsLocationsDatasetsClustersMessagesApiClient$Xgafv>'1',
        2: <ProjectsLocationsDatasetsClustersMessagesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsDatasetsClustersMessagesApiClient$Xgafv> {
              return [
                ProjectsLocationsDatasetsClustersMessagesApiClient$XgafvEnum[1],
                ProjectsLocationsDatasetsClustersMessagesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsDatasetsClustersMessagesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsDatasetsClustersMessagesApiClientAltEnum {
    readonly JSON: ProjectsLocationsDatasetsClustersMessagesApiClientAlt;
    readonly MEDIA: ProjectsLocationsDatasetsClustersMessagesApiClientAlt;
    readonly PROTO: ProjectsLocationsDatasetsClustersMessagesApiClientAlt;

    values(): Array<ProjectsLocationsDatasetsClustersMessagesApiClientAlt>;
  }

  export const ProjectsLocationsDatasetsClustersMessagesApiClientAltEnum:
      IProjectsLocationsDatasetsClustersMessagesApiClientAltEnum = {
        JSON: <ProjectsLocationsDatasetsClustersMessagesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsDatasetsClustersMessagesApiClientAlt>'media',
        PROTO: <ProjectsLocationsDatasetsClustersMessagesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsDatasetsClustersMessagesApiClientAlt> {
              return [
                ProjectsLocationsDatasetsClustersMessagesApiClientAltEnum.JSON,
                ProjectsLocationsDatasetsClustersMessagesApiClientAltEnum.MEDIA,
                ProjectsLocationsDatasetsClustersMessagesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsDatasetsClustersMessagesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsClustersMessagesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsClustersMessagesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsDatasetsClustersMessagesApiClientImpl implements
      ProjectsLocationsDatasetsClustersMessagesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsDatasetsClustersMessagesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListClusteredMessagesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/datasets/[^/]+/clusters/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListClusteredMessagesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.datasets.clusters.messages.list',
        path: `/${this.gapiVersion}/${parent}/messages`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListClusteredMessagesResponse_
      });
    }
  }

  export abstract class ProjectsLocationsDatasetsClustersMessagesApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsDatasetsClustersMessagesListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListClusteredMessagesResponse_>;
  }

  export type ProjectsLocationsDatasetsConversationsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsDatasetsConversationsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsDatasetsConversationsApiClient$Xgafv;
    readonly 2: ProjectsLocationsDatasetsConversationsApiClient$Xgafv;

    values(): Array<ProjectsLocationsDatasetsConversationsApiClient$Xgafv>;
  }

  export const ProjectsLocationsDatasetsConversationsApiClient$XgafvEnum:
      IProjectsLocationsDatasetsConversationsApiClient$XgafvEnum = {
        1: <ProjectsLocationsDatasetsConversationsApiClient$Xgafv>'1',
        2: <ProjectsLocationsDatasetsConversationsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsDatasetsConversationsApiClient$Xgafv> {
              return [
                ProjectsLocationsDatasetsConversationsApiClient$XgafvEnum[1],
                ProjectsLocationsDatasetsConversationsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsDatasetsConversationsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsDatasetsConversationsApiClientAltEnum {
    readonly JSON: ProjectsLocationsDatasetsConversationsApiClientAlt;
    readonly MEDIA: ProjectsLocationsDatasetsConversationsApiClientAlt;
    readonly PROTO: ProjectsLocationsDatasetsConversationsApiClientAlt;

    values(): Array<ProjectsLocationsDatasetsConversationsApiClientAlt>;
  }

  export const ProjectsLocationsDatasetsConversationsApiClientAltEnum:
      IProjectsLocationsDatasetsConversationsApiClientAltEnum = {
        JSON: <ProjectsLocationsDatasetsConversationsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsDatasetsConversationsApiClientAlt>'media',
        PROTO: <ProjectsLocationsDatasetsConversationsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsDatasetsConversationsApiClientAlt> {
              return [
                ProjectsLocationsDatasetsConversationsApiClientAltEnum.JSON,
                ProjectsLocationsDatasetsConversationsApiClientAltEnum.MEDIA,
                ProjectsLocationsDatasetsConversationsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsDatasetsConversationsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsConversationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsConversationsApiClient$Xgafv;
  }

  export class ProjectsLocationsDatasetsConversationsApiClientImpl implements
      ProjectsLocationsDatasetsConversationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsDatasetsConversationsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationData_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/datasets/[^/]+/conversations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationData_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.datasets.conversations.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationData_
      });
    }
  }

  export abstract class ProjectsLocationsDatasetsConversationsApiClient {
    constructor() {}

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsDatasetsConversationsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationData_>;
  }

  export type ProjectsLocationsDatasetsEdgesApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsDatasetsEdgesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsDatasetsEdgesApiClient$Xgafv;
    readonly 2: ProjectsLocationsDatasetsEdgesApiClient$Xgafv;

    values(): Array<ProjectsLocationsDatasetsEdgesApiClient$Xgafv>;
  }

  export const ProjectsLocationsDatasetsEdgesApiClient$XgafvEnum:
      IProjectsLocationsDatasetsEdgesApiClient$XgafvEnum = {
        1: <ProjectsLocationsDatasetsEdgesApiClient$Xgafv>'1',
        2: <ProjectsLocationsDatasetsEdgesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsDatasetsEdgesApiClient$Xgafv> {
              return [
                ProjectsLocationsDatasetsEdgesApiClient$XgafvEnum[1],
                ProjectsLocationsDatasetsEdgesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsDatasetsEdgesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsDatasetsEdgesApiClientAltEnum {
    readonly JSON: ProjectsLocationsDatasetsEdgesApiClientAlt;
    readonly MEDIA: ProjectsLocationsDatasetsEdgesApiClientAlt;
    readonly PROTO: ProjectsLocationsDatasetsEdgesApiClientAlt;

    values(): Array<ProjectsLocationsDatasetsEdgesApiClientAlt>;
  }

  export const ProjectsLocationsDatasetsEdgesApiClientAltEnum:
      IProjectsLocationsDatasetsEdgesApiClientAltEnum = {
        JSON: <ProjectsLocationsDatasetsEdgesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsDatasetsEdgesApiClientAlt>'media',
        PROTO: <ProjectsLocationsDatasetsEdgesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsDatasetsEdgesApiClientAlt> {
              return [
                ProjectsLocationsDatasetsEdgesApiClientAltEnum.JSON,
                ProjectsLocationsDatasetsEdgesApiClientAltEnum.MEDIA,
                ProjectsLocationsDatasetsEdgesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsDatasetsEdgesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsEdgesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsEdgesApiClient$Xgafv;
    filterTriggeringNodeId?: string;
    filterSourceNodeId?: string;
    filterTargetNodeId?: string;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsDatasetsEdgesApiClientImpl implements
      ProjectsLocationsDatasetsEdgesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsDatasetsEdgesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListMessageEdgesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/datasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListMessageEdgesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.datasets.edges.list',
        path: `/${this.gapiVersion}/${parent}/edges`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListMessageEdgesResponse_
      });
    }
  }

  export abstract class ProjectsLocationsDatasetsEdgesApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsDatasetsEdgesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListMessageEdgesResponse_>;
  }

  export type ProjectsLocationsDatasetsNodesApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsDatasetsNodesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsDatasetsNodesApiClient$Xgafv;
    readonly 2: ProjectsLocationsDatasetsNodesApiClient$Xgafv;

    values(): Array<ProjectsLocationsDatasetsNodesApiClient$Xgafv>;
  }

  export const ProjectsLocationsDatasetsNodesApiClient$XgafvEnum:
      IProjectsLocationsDatasetsNodesApiClient$XgafvEnum = {
        1: <ProjectsLocationsDatasetsNodesApiClient$Xgafv>'1',
        2: <ProjectsLocationsDatasetsNodesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsDatasetsNodesApiClient$Xgafv> {
              return [
                ProjectsLocationsDatasetsNodesApiClient$XgafvEnum[1],
                ProjectsLocationsDatasetsNodesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsDatasetsNodesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsDatasetsNodesApiClientAltEnum {
    readonly JSON: ProjectsLocationsDatasetsNodesApiClientAlt;
    readonly MEDIA: ProjectsLocationsDatasetsNodesApiClientAlt;
    readonly PROTO: ProjectsLocationsDatasetsNodesApiClientAlt;

    values(): Array<ProjectsLocationsDatasetsNodesApiClientAlt>;
  }

  export const ProjectsLocationsDatasetsNodesApiClientAltEnum:
      IProjectsLocationsDatasetsNodesApiClientAltEnum = {
        JSON: <ProjectsLocationsDatasetsNodesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsDatasetsNodesApiClientAlt>'media',
        PROTO: <ProjectsLocationsDatasetsNodesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsDatasetsNodesApiClientAlt> {
              return [
                ProjectsLocationsDatasetsNodesApiClientAltEnum.JSON,
                ProjectsLocationsDatasetsNodesApiClientAltEnum.MEDIA,
                ProjectsLocationsDatasetsNodesApiClientAltEnum.PROTO
              ];
            }
      };

  export type ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel =
      'LABEL_UNSPECIFIED'|'AGENT_INTERNAL_RESPONSE'|'AGENT_LEAF_RESPONSE'|
      'AGENT_SLOT_FILLING_QUESTION'|'AGENT_FALLBACK_RESPONSE'|
      'USER_TRIGGERING_INTENT'|'USER_FOLLOWUP_INTENT'|
      'USER_SLOT_FILLING_ANSWER'|'AGENT_OTHER'|'USER_OTHER'|
      'SOCRATES_KEY_SENTENCE'|'SOCRATES_GREETING'|'HOBBES_POLITE'|
      'ENTROPY_SPAM'|'SUPPORT_UNLABELED'|'SUPPORT_INTRODUCTION'|
      'SUPPORT_WAIT_REQUEST'|'SUPPORT_GENERIC_MESSAGE'|
      'SUPPORT_DIAGNOSTIC_QUESTION'|'SUPPORT_RESOLUTION'|'SUPPORT_CLOSURE'|
      'SUPPORT_NONSENSE';

  export interface IProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum {
    readonly LABEL_UNSPECIFIED:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly AGENT_INTERNAL_RESPONSE:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly AGENT_LEAF_RESPONSE:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly AGENT_SLOT_FILLING_QUESTION:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly AGENT_FALLBACK_RESPONSE:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly USER_TRIGGERING_INTENT:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly USER_FOLLOWUP_INTENT:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly USER_SLOT_FILLING_ANSWER:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly AGENT_OTHER:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly USER_OTHER:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly SOCRATES_KEY_SENTENCE:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly SOCRATES_GREETING:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly HOBBES_POLITE:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly ENTROPY_SPAM:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly SUPPORT_UNLABELED:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly SUPPORT_INTRODUCTION:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly SUPPORT_WAIT_REQUEST:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly SUPPORT_GENERIC_MESSAGE:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly SUPPORT_DIAGNOSTIC_QUESTION:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly SUPPORT_RESOLUTION:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly SUPPORT_CLOSURE:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    readonly SUPPORT_NONSENSE:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;

    values():
        Array<ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>;
  }

  export const ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum:
      IProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum = {
        AGENT_FALLBACK_RESPONSE: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'AGENT_FALLBACK_RESPONSE',
        AGENT_INTERNAL_RESPONSE: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'AGENT_INTERNAL_RESPONSE',
        AGENT_LEAF_RESPONSE: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'AGENT_LEAF_RESPONSE',
        AGENT_OTHER: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'AGENT_OTHER',
        AGENT_SLOT_FILLING_QUESTION: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'AGENT_SLOT_FILLING_QUESTION',
        ENTROPY_SPAM: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'ENTROPY_SPAM',
        HOBBES_POLITE: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'HOBBES_POLITE',
        LABEL_UNSPECIFIED: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'LABEL_UNSPECIFIED',
        SOCRATES_GREETING: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'SOCRATES_GREETING',
        SOCRATES_KEY_SENTENCE: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'SOCRATES_KEY_SENTENCE',
        SUPPORT_CLOSURE: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'SUPPORT_CLOSURE',
        SUPPORT_DIAGNOSTIC_QUESTION: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'SUPPORT_DIAGNOSTIC_QUESTION',
        SUPPORT_GENERIC_MESSAGE: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'SUPPORT_GENERIC_MESSAGE',
        SUPPORT_INTRODUCTION: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'SUPPORT_INTRODUCTION',
        SUPPORT_NONSENSE: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'SUPPORT_NONSENSE',
        SUPPORT_RESOLUTION: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'SUPPORT_RESOLUTION',
        SUPPORT_UNLABELED: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'SUPPORT_UNLABELED',
        SUPPORT_WAIT_REQUEST: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'SUPPORT_WAIT_REQUEST',
        USER_FOLLOWUP_INTENT: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'USER_FOLLOWUP_INTENT',
        USER_OTHER: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'USER_OTHER',
        USER_SLOT_FILLING_ANSWER: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'USER_SLOT_FILLING_ANSWER',
        USER_TRIGGERING_INTENT: <
            ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel>'USER_TRIGGERING_INTENT',
        values():
            Array<
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel> {
              return [
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .LABEL_UNSPECIFIED,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .AGENT_INTERNAL_RESPONSE,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .AGENT_LEAF_RESPONSE,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .AGENT_SLOT_FILLING_QUESTION,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .AGENT_FALLBACK_RESPONSE,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .USER_TRIGGERING_INTENT,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .USER_FOLLOWUP_INTENT,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .USER_SLOT_FILLING_ANSWER,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .AGENT_OTHER,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .USER_OTHER,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .SOCRATES_KEY_SENTENCE,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .SOCRATES_GREETING,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .HOBBES_POLITE,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .ENTROPY_SPAM,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .SUPPORT_UNLABELED,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .SUPPORT_INTRODUCTION,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .SUPPORT_WAIT_REQUEST,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .SUPPORT_GENERIC_MESSAGE,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .SUPPORT_DIAGNOSTIC_QUESTION,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .SUPPORT_RESOLUTION,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .SUPPORT_CLOSURE,
                ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabelEnum
                    .SUPPORT_NONSENSE
              ];
            }
      };

  export type ProjectsLocationsDatasetsNodesApiClientMutation =
      'MESSAGES_MUTATION_UNSPECIFIED'|'APPEND'|'REMOVE';

  export interface IProjectsLocationsDatasetsNodesApiClientMutationEnum {
    readonly MESSAGES_MUTATION_UNSPECIFIED:
        ProjectsLocationsDatasetsNodesApiClientMutation;
    readonly APPEND: ProjectsLocationsDatasetsNodesApiClientMutation;
    readonly REMOVE: ProjectsLocationsDatasetsNodesApiClientMutation;

    values(): Array<ProjectsLocationsDatasetsNodesApiClientMutation>;
  }

  export const ProjectsLocationsDatasetsNodesApiClientMutationEnum:
      IProjectsLocationsDatasetsNodesApiClientMutationEnum = {
        APPEND: <ProjectsLocationsDatasetsNodesApiClientMutation>'APPEND',
        MESSAGES_MUTATION_UNSPECIFIED: <
            ProjectsLocationsDatasetsNodesApiClientMutation>'MESSAGES_MUTATION_UNSPECIFIED',
        REMOVE: <ProjectsLocationsDatasetsNodesApiClientMutation>'REMOVE',
        values():
            Array<ProjectsLocationsDatasetsNodesApiClientMutation> {
              return [
                ProjectsLocationsDatasetsNodesApiClientMutationEnum
                    .MESSAGES_MUTATION_UNSPECIFIED,
                ProjectsLocationsDatasetsNodesApiClientMutationEnum.APPEND,
                ProjectsLocationsDatasetsNodesApiClientMutationEnum.REMOVE
              ];
            }
      };

  export declare interface ProjectsLocationsDatasetsNodesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsNodesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsNodesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsDatasetsNodesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsNodesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsNodesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsDatasetsNodesExportNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsNodesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsNodesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsDatasetsNodesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsNodesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsNodesApiClient$Xgafv;
    filterManualLabelLabel?:
        ProjectsLocationsDatasetsNodesApiClientFilterManualLabelLabel;
    filterManualLabelScore?: number;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsDatasetsNodesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsNodesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsNodesApiClient$Xgafv;
    mutation?: ProjectsLocationsDatasetsNodesApiClientMutation;
    updateMask?: string;
  }

  export class ProjectsLocationsDatasetsNodesApiClientImpl implements
      ProjectsLocationsDatasetsNodesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: CreateMessageNodeRequest_,
        namedParameters: ProjectsLocationsDatasetsNodesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<MessageNode_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/datasets/[^/]+$'));

      return this.$apiClient.$request<MessageNode_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.datasets.nodes.create',
        path: `/${this.gapiVersion}/${parent}/nodes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: MessageNode_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsLocationsDatasetsNodesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/datasets/[^/]+/nodes/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.datasets.nodes.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    export(
        name: string, $requestBody: ExportMessageNodeRequest_,
        namedParameters: ProjectsLocationsDatasetsNodesExportNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ExportMessageNodeResponse_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/datasets/[^/]+/nodes/[^/]+$'));

      return this.$apiClient.$request<ExportMessageNodeResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.datasets.nodes.export',
        path: `/${this.gapiVersion}/${name}:export`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ExportMessageNodeResponse_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsDatasetsNodesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListMessageNodesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/datasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListMessageNodesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.datasets.nodes.list',
        path: `/${this.gapiVersion}/${parent}/nodes`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListMessageNodesResponse_
      });
    }

    patch(
        name: string, $requestBody: MessageNode_,
        namedParameters: ProjectsLocationsDatasetsNodesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<MessageNode_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/datasets/[^/]+/nodes/[^/]+$'));

      return this.$apiClient.$request<MessageNode_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.datasets.nodes.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: MessageNode_
      });
    }
  }

  export abstract class ProjectsLocationsDatasetsNodesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: CreateMessageNodeRequest_,
        namedParameters?: ProjectsLocationsDatasetsNodesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<MessageNode_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsLocationsDatasetsNodesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract export(
        name: string, $requestBody: ExportMessageNodeRequest_,
        namedParameters?: ProjectsLocationsDatasetsNodesExportNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ExportMessageNodeResponse_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsDatasetsNodesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListMessageNodesResponse_>;

    abstract patch(
        name: string, $requestBody: MessageNode_,
        namedParameters?: ProjectsLocationsDatasetsNodesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<MessageNode_>;
  }

  export type ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsDatasetsNodesGraphsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv;
    readonly 2: ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv;

    values(): Array<ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv>;
  }

  export const ProjectsLocationsDatasetsNodesGraphsApiClient$XgafvEnum:
      IProjectsLocationsDatasetsNodesGraphsApiClient$XgafvEnum = {
        1: <ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv>'1',
        2: <ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv> {
              return [
                ProjectsLocationsDatasetsNodesGraphsApiClient$XgafvEnum[1],
                ProjectsLocationsDatasetsNodesGraphsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsDatasetsNodesGraphsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsDatasetsNodesGraphsApiClientAltEnum {
    readonly JSON: ProjectsLocationsDatasetsNodesGraphsApiClientAlt;
    readonly MEDIA: ProjectsLocationsDatasetsNodesGraphsApiClientAlt;
    readonly PROTO: ProjectsLocationsDatasetsNodesGraphsApiClientAlt;

    values(): Array<ProjectsLocationsDatasetsNodesGraphsApiClientAlt>;
  }

  export const ProjectsLocationsDatasetsNodesGraphsApiClientAltEnum:
      IProjectsLocationsDatasetsNodesGraphsApiClientAltEnum = {
        JSON: <ProjectsLocationsDatasetsNodesGraphsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsDatasetsNodesGraphsApiClientAlt>'media',
        PROTO: <ProjectsLocationsDatasetsNodesGraphsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsDatasetsNodesGraphsApiClientAlt> {
              return [
                ProjectsLocationsDatasetsNodesGraphsApiClientAltEnum.JSON,
                ProjectsLocationsDatasetsNodesGraphsApiClientAltEnum.MEDIA,
                ProjectsLocationsDatasetsNodesGraphsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsDatasetsNodesGraphsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsNodesGraphsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsDatasetsNodesGraphsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsNodesGraphsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsDatasetsNodesGraphsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsNodesGraphsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsDatasetsNodesGraphsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsNodesGraphsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsNodesGraphsApiClient$Xgafv;
  }

  export class ProjectsLocationsDatasetsNodesGraphsApiClientImpl implements
      ProjectsLocationsDatasetsNodesGraphsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: ConversationGraphGenerationConfig_,
        namedParameters:
            ProjectsLocationsDatasetsNodesGraphsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationGraph_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/datasets/[^/]+/nodes/[^/]+$'));

      return this.$apiClient.$request<ConversationGraph_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.datasets.nodes.graphs.create',
        path: `/${this.gapiVersion}/${parent}/graphs`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationGraph_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsDatasetsNodesGraphsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/datasets/[^/]+/nodes/[^/]+/graphs/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.datasets.nodes.graphs.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsDatasetsNodesGraphsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationGraph_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/datasets/[^/]+/nodes/[^/]+/graphs/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ConversationGraph_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.datasets.nodes.graphs.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationGraph_
      });
    }

    patch(
        name: string, $requestBody: ConversationGraph_,
        namedParameters:
            ProjectsLocationsDatasetsNodesGraphsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ConversationGraph_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/datasets/[^/]+/nodes/[^/]+/graphs/[^/]+$'));

      return this.$apiClient.$request<ConversationGraph_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.datasets.nodes.graphs.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ConversationGraph_
      });
    }
  }

  export abstract class ProjectsLocationsDatasetsNodesGraphsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: ConversationGraphGenerationConfig_,
        namedParameters?:
            ProjectsLocationsDatasetsNodesGraphsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationGraph_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsDatasetsNodesGraphsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsDatasetsNodesGraphsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationGraph_>;

    abstract patch(
        name: string, $requestBody: ConversationGraph_,
        namedParameters?:
            ProjectsLocationsDatasetsNodesGraphsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ConversationGraph_>;
  }

  export type ProjectsLocationsDatasetsTopicsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsDatasetsTopicsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsDatasetsTopicsApiClient$Xgafv;
    readonly 2: ProjectsLocationsDatasetsTopicsApiClient$Xgafv;

    values(): Array<ProjectsLocationsDatasetsTopicsApiClient$Xgafv>;
  }

  export const ProjectsLocationsDatasetsTopicsApiClient$XgafvEnum:
      IProjectsLocationsDatasetsTopicsApiClient$XgafvEnum = {
        1: <ProjectsLocationsDatasetsTopicsApiClient$Xgafv>'1',
        2: <ProjectsLocationsDatasetsTopicsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsDatasetsTopicsApiClient$Xgafv> {
              return [
                ProjectsLocationsDatasetsTopicsApiClient$XgafvEnum[1],
                ProjectsLocationsDatasetsTopicsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsDatasetsTopicsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsDatasetsTopicsApiClientAltEnum {
    readonly JSON: ProjectsLocationsDatasetsTopicsApiClientAlt;
    readonly MEDIA: ProjectsLocationsDatasetsTopicsApiClientAlt;
    readonly PROTO: ProjectsLocationsDatasetsTopicsApiClientAlt;

    values(): Array<ProjectsLocationsDatasetsTopicsApiClientAlt>;
  }

  export const ProjectsLocationsDatasetsTopicsApiClientAltEnum:
      IProjectsLocationsDatasetsTopicsApiClientAltEnum = {
        JSON: <ProjectsLocationsDatasetsTopicsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsDatasetsTopicsApiClientAlt>'media',
        PROTO: <ProjectsLocationsDatasetsTopicsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsDatasetsTopicsApiClientAlt> {
              return [
                ProjectsLocationsDatasetsTopicsApiClientAltEnum.JSON,
                ProjectsLocationsDatasetsTopicsApiClientAltEnum.MEDIA,
                ProjectsLocationsDatasetsTopicsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsDatasetsTopicsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsDatasetsTopicsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsDatasetsTopicsApiClient$Xgafv;
    filterParentTopicId?: string;
  }

  export class ProjectsLocationsDatasetsTopicsApiClientImpl implements
      ProjectsLocationsDatasetsTopicsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsDatasetsTopicsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListTopicsResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/datasets/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListTopicsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.datasets.topics.list',
        path: `/${this.gapiVersion}/${parent}/topics`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListTopicsResponse_
      });
    }
  }

  export abstract class ProjectsLocationsDatasetsTopicsApiClient {
    constructor() {}

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsDatasetsTopicsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListTopicsResponse_>;
  }

  export type ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsFeatureCreationFlowsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv;
    readonly 2: ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv;

    values(): Array<ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv>;
  }

  export const ProjectsLocationsFeatureCreationFlowsApiClient$XgafvEnum:
      IProjectsLocationsFeatureCreationFlowsApiClient$XgafvEnum = {
        1: <ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv>'1',
        2: <ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv> {
              return [
                ProjectsLocationsFeatureCreationFlowsApiClient$XgafvEnum[1],
                ProjectsLocationsFeatureCreationFlowsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsFeatureCreationFlowsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsFeatureCreationFlowsApiClientAltEnum {
    readonly JSON: ProjectsLocationsFeatureCreationFlowsApiClientAlt;
    readonly MEDIA: ProjectsLocationsFeatureCreationFlowsApiClientAlt;
    readonly PROTO: ProjectsLocationsFeatureCreationFlowsApiClientAlt;

    values(): Array<ProjectsLocationsFeatureCreationFlowsApiClientAlt>;
  }

  export const ProjectsLocationsFeatureCreationFlowsApiClientAltEnum:
      IProjectsLocationsFeatureCreationFlowsApiClientAltEnum = {
        JSON: <ProjectsLocationsFeatureCreationFlowsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsFeatureCreationFlowsApiClientAlt>'media',
        PROTO: <ProjectsLocationsFeatureCreationFlowsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsFeatureCreationFlowsApiClientAlt> {
              return [
                ProjectsLocationsFeatureCreationFlowsApiClientAltEnum.JSON,
                ProjectsLocationsFeatureCreationFlowsApiClientAltEnum.MEDIA,
                ProjectsLocationsFeatureCreationFlowsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsFeatureCreationFlowsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsFeatureCreationFlowsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv;
    featureCreationFlowId?: string;
    validateOnly?: boolean;
  }

  export declare interface ProjectsLocationsFeatureCreationFlowsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsFeatureCreationFlowsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv;
    etag?: string;
    validateOnly?: boolean;
  }

  export declare interface ProjectsLocationsFeatureCreationFlowsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsFeatureCreationFlowsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsFeatureCreationFlowsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsFeatureCreationFlowsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsFeatureCreationFlowsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsFeatureCreationFlowsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsFeatureCreationFlowsApiClient$Xgafv;
    updateMask?: string;
    allowMissing?: boolean;
    validateOnly?: boolean;
  }

  export class ProjectsLocationsFeatureCreationFlowsApiClientImpl implements
      ProjectsLocationsFeatureCreationFlowsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: FeatureCreationFlow_,
        namedParameters:
            ProjectsLocationsFeatureCreationFlowsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<FeatureCreationFlow_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<FeatureCreationFlow_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.featureCreationFlows.create',
        path: `/${this.gapiVersion}/${parent}/featureCreationFlows`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: FeatureCreationFlow_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsFeatureCreationFlowsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/featureCreationFlows/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.featureCreationFlows.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsFeatureCreationFlowsGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<FeatureCreationFlow_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/featureCreationFlows/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<FeatureCreationFlow_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.featureCreationFlows.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: FeatureCreationFlow_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsFeatureCreationFlowsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListFeatureCreationFlowsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListFeatureCreationFlowsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.featureCreationFlows.list',
        path: `/${this.gapiVersion}/${parent}/featureCreationFlows`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListFeatureCreationFlowsResponse_
      });
    }

    patch(
        name: string, $requestBody: FeatureCreationFlow_,
        namedParameters:
            ProjectsLocationsFeatureCreationFlowsPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<FeatureCreationFlow_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/featureCreationFlows/[^/]+$'));

      return this.$apiClient.$request<FeatureCreationFlow_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.featureCreationFlows.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: FeatureCreationFlow_
      });
    }
  }

  export abstract class ProjectsLocationsFeatureCreationFlowsApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: FeatureCreationFlow_,
        namedParameters?:
            ProjectsLocationsFeatureCreationFlowsCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<FeatureCreationFlow_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsFeatureCreationFlowsDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsFeatureCreationFlowsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<FeatureCreationFlow_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsFeatureCreationFlowsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListFeatureCreationFlowsResponse_>;

    abstract patch(
        name: string, $requestBody: FeatureCreationFlow_,
        namedParameters?:
            ProjectsLocationsFeatureCreationFlowsPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<FeatureCreationFlow_>;
  }

  export type ProjectsLocationsKnowledgeBasesApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsKnowledgeBasesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsKnowledgeBasesApiClient$Xgafv;
    readonly 2: ProjectsLocationsKnowledgeBasesApiClient$Xgafv;

    values(): Array<ProjectsLocationsKnowledgeBasesApiClient$Xgafv>;
  }

  export const ProjectsLocationsKnowledgeBasesApiClient$XgafvEnum:
      IProjectsLocationsKnowledgeBasesApiClient$XgafvEnum = {
        1: <ProjectsLocationsKnowledgeBasesApiClient$Xgafv>'1',
        2: <ProjectsLocationsKnowledgeBasesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsKnowledgeBasesApiClient$Xgafv> {
              return [
                ProjectsLocationsKnowledgeBasesApiClient$XgafvEnum[1],
                ProjectsLocationsKnowledgeBasesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsKnowledgeBasesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsKnowledgeBasesApiClientAltEnum {
    readonly JSON: ProjectsLocationsKnowledgeBasesApiClientAlt;
    readonly MEDIA: ProjectsLocationsKnowledgeBasesApiClientAlt;
    readonly PROTO: ProjectsLocationsKnowledgeBasesApiClientAlt;

    values(): Array<ProjectsLocationsKnowledgeBasesApiClientAlt>;
  }

  export const ProjectsLocationsKnowledgeBasesApiClientAltEnum:
      IProjectsLocationsKnowledgeBasesApiClientAltEnum = {
        JSON: <ProjectsLocationsKnowledgeBasesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsKnowledgeBasesApiClientAlt>'media',
        PROTO: <ProjectsLocationsKnowledgeBasesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsKnowledgeBasesApiClientAlt> {
              return [
                ProjectsLocationsKnowledgeBasesApiClientAltEnum.JSON,
                ProjectsLocationsKnowledgeBasesApiClientAltEnum.MEDIA,
                ProjectsLocationsKnowledgeBasesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsKnowledgeBasesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesApiClient$Xgafv;
    force?: boolean;
  }

  export declare interface ProjectsLocationsKnowledgeBasesGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsLocationsKnowledgeBasesPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsLocationsKnowledgeBasesSearchKnowledgeBaseIntentNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesApiClient$Xgafv;
    languageCode?: string;
  }

  export class ProjectsLocationsKnowledgeBasesApiClientImpl implements
      ProjectsLocationsKnowledgeBasesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: KnowledgeBase_,
        namedParameters: ProjectsLocationsKnowledgeBasesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<KnowledgeBase_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<KnowledgeBase_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.knowledgeBases.create',
        path: `/${this.gapiVersion}/${parent}/knowledgeBases`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: KnowledgeBase_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsLocationsKnowledgeBasesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.knowledgeBases.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsKnowledgeBasesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<KnowledgeBase_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<KnowledgeBase_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.knowledgeBases.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: KnowledgeBase_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsKnowledgeBasesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListKnowledgeBasesResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListKnowledgeBasesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.knowledgeBases.list',
        path: `/${this.gapiVersion}/${parent}/knowledgeBases`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListKnowledgeBasesResponse_
      });
    }

    patch(
        name: string, $requestBody: KnowledgeBase_,
        namedParameters: ProjectsLocationsKnowledgeBasesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<KnowledgeBase_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+$'));

      return this.$apiClient.$request<KnowledgeBase_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.knowledgeBases.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: KnowledgeBase_
      });
    }

    searchKnowledgeBaseIntent(
        knowledgeBase: string,
        namedParameters:
            ProjectsLocationsKnowledgeBasesSearchKnowledgeBaseIntentNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Intent_> {
      this.$apiClient.$validateParameter(
          knowledgeBase,
          new RegExp('^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Intent_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.searchKnowledgeBaseIntent',
        path: `/${this.gapiVersion}/${knowledgeBase}:searchKnowledgeBaseIntent`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Intent_
      });
    }
  }

  export abstract class ProjectsLocationsKnowledgeBasesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: KnowledgeBase_,
        namedParameters?: ProjectsLocationsKnowledgeBasesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<KnowledgeBase_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsLocationsKnowledgeBasesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsKnowledgeBasesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<KnowledgeBase_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsKnowledgeBasesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListKnowledgeBasesResponse_>;

    abstract patch(
        name: string, $requestBody: KnowledgeBase_,
        namedParameters?: ProjectsLocationsKnowledgeBasesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<KnowledgeBase_>;

    abstract searchKnowledgeBaseIntent(
        knowledgeBase: string,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesSearchKnowledgeBaseIntentNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<Intent_>;
  }

  export type ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsKnowledgeBasesDocumentsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;
    readonly 2: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;

    values(): Array<ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv>;
  }

  export const ProjectsLocationsKnowledgeBasesDocumentsApiClient$XgafvEnum:
      IProjectsLocationsKnowledgeBasesDocumentsApiClient$XgafvEnum = {
        1: <ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv>'1',
        2: <ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv> {
              return [
                ProjectsLocationsKnowledgeBasesDocumentsApiClient$XgafvEnum[1],
                ProjectsLocationsKnowledgeBasesDocumentsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsKnowledgeBasesDocumentsApiClientAltEnum {
    readonly JSON: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    readonly MEDIA: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    readonly PROTO: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;

    values(): Array<ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt>;
  }

  export const ProjectsLocationsKnowledgeBasesDocumentsApiClientAltEnum:
      IProjectsLocationsKnowledgeBasesDocumentsApiClientAltEnum = {
        JSON: <ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt>'media',
        PROTO: <ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt> {
              return [
                ProjectsLocationsKnowledgeBasesDocumentsApiClientAltEnum.JSON,
                ProjectsLocationsKnowledgeBasesDocumentsApiClientAltEnum.MEDIA,
                ProjectsLocationsKnowledgeBasesDocumentsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;
    importGcsCustomMetadata?: boolean;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsExportNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsGenerateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsImportNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsReloadNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsKnowledgeBasesDocumentsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsKnowledgeBasesDocumentsApiClient$Xgafv;
  }

  export class ProjectsLocationsKnowledgeBasesDocumentsApiClientImpl implements
      ProjectsLocationsKnowledgeBasesDocumentsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: Document_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.create',
        path: `/${this.gapiVersion}/${parent}/documents`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    export(
        name: string, $requestBody: ExportDocumentRequest_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsExportNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.export',
        path: `/${this.gapiVersion}/${name}:export`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    generate(
        parent: string, $requestBody: GenerateDocumentRequest_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsGenerateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.generate',
        path: `/${this.gapiVersion}/${parent}/documents:generate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<Document_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<Document_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.knowledgeBases.documents.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: Document_
      });
    }

  import(
      parent: string,
      $requestBody: ImportDocumentsRequest_,
      namedParameters: ProjectsLocationsKnowledgeBasesDocumentsImportNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.locations.knowledgeBases.documents.import',
      path: `/${this.gapiVersion}/${parent}/documents:import`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  list(
      parent: string,
      namedParameters: ProjectsLocationsKnowledgeBasesDocumentsListNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<ListDocumentsResponse_> {
    this.$apiClient.$validateParameter(parent, new RegExp('^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+$'));
    let $requestBody = <Serializable|null>null;

    return this.$apiClient.$request<ListDocumentsResponse_>({
      body: $requestBody,
      httpMethod: 'GET',
      methodId: 'dialogflow.projects.locations.knowledgeBases.documents.list',
      path: `/${this.gapiVersion}/${parent}/documents`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: ListDocumentsResponse_
    });
  }

  patch(
      name: string,
      $requestBody: Document_,
      namedParameters: ProjectsLocationsKnowledgeBasesDocumentsPatchNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'PATCH',
      methodId: 'dialogflow.projects.locations.knowledgeBases.documents.patch',
      path: `/${this.gapiVersion}/${name}`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }

  reload(
      name: string,
      $requestBody: ReloadDocumentRequest_,
      namedParameters: ProjectsLocationsKnowledgeBasesDocumentsReloadNamedParameters&object = {},
      passthroughNamedParameters: globalThis.Record<string, string> = {}): Observable<GoogleLongrunningOperation> {
    this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

    return this.$apiClient.$request<GoogleLongrunningOperation>({
      body: $requestBody,
      httpMethod: 'POST',
      methodId: 'dialogflow.projects.locations.knowledgeBases.documents.reload',
      path: `/${this.gapiVersion}/${name}:reload`,
      queryParams: buildQueryParams(namedParameters, PARAM_MAP_0, passthroughNamedParameters),
      responseCtor: GoogleLongrunningOperation
    });
  }
}

export abstract class ProjectsLocationsKnowledgeBasesDocumentsApiClient {
  constructor() {
  }

  abstract create(
      parent: string,
      $requestBody: Document_,
      namedParameters?: ProjectsLocationsKnowledgeBasesDocumentsCreateNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract delete(
      name: string,
      namedParameters?: ProjectsLocationsKnowledgeBasesDocumentsDeleteNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract export(
      name: string,
      $requestBody: ExportDocumentRequest_,
      namedParameters?: ProjectsLocationsKnowledgeBasesDocumentsExportNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract generate(
      parent: string,
      $requestBody: GenerateDocumentRequest_,
      namedParameters?: ProjectsLocationsKnowledgeBasesDocumentsGenerateNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract get(
      name: string,
      namedParameters?: ProjectsLocationsKnowledgeBasesDocumentsGetNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<Document_>;

  abstract import(
      parent: string,
      $requestBody: ImportDocumentsRequest_,
      namedParameters?: ProjectsLocationsKnowledgeBasesDocumentsImportNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract list(
      parent: string,
      namedParameters?: ProjectsLocationsKnowledgeBasesDocumentsListNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<ListDocumentsResponse_>;

  abstract patch(
      name: string,
      $requestBody: Document_,
      namedParameters?: ProjectsLocationsKnowledgeBasesDocumentsPatchNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;

  abstract reload(
      name: string,
      $requestBody: ReloadDocumentRequest_,
      namedParameters?: ProjectsLocationsKnowledgeBasesDocumentsReloadNamedParameters&object,
      passthroughNamedParameters?: globalThis.Record<string, string>): Observable<GoogleLongrunningOperation>;
}

export type ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv = '1' | '2';

  export interface IProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv>;
  }

  export const ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$XgafvEnum:
      IProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$XgafvEnum = {
        1: <ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv>'1',
        2: <ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv> {
              return [
                ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$XgafvEnum
                    [1],
                ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt;
    readonly PROTO:
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt;

    values(): Array<
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt>;
  }

  export const ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum:
      IProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum = {
        JSON: <
            ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt> {
              return [
                ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum
                    .JSON,
                ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesBatchDeleteNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesGetNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient$Xgafv;
  }

  export class
      ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClientImpl
          implements
              ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchDelete(
        parent: string, $requestBody: BatchDeleteIssueModelingEntriesRequest_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesBatchDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.issueModelingEntries.batchDelete',
        path: `/${this.gapiVersion}/${parent}/issueModelingEntries:batchDelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<IssueModelingEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/issueModelingEntries/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<IssueModelingEntry_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.issueModelingEntries.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: IssueModelingEntry_
      });
    }
  }

  export abstract class
      ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesApiClient {
    constructor() {}

    abstract batchDelete(
        parent: string, $requestBody: BatchDeleteIssueModelingEntriesRequest_,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesBatchDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsIssueModelingEntriesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<IssueModelingEntry_>;
  }

  export type ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv>;
  }

  export const ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum:
      IProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum = {
        1: <ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv>'1',
        2: <ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv> {
              return [
                ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum
                    [1],
                ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    readonly PROTO:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;

    values(): Array<
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>;
  }

  export const ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum:
      IProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum = {
        JSON: <
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt> {
              return [
                ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum
                    .JSON,
                ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesBatchUpdateNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesGetNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesListNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesPatchNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient$Xgafv;
    updateMask?: string;
  }

  export class
      ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClientImpl
          implements
              ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    batchUpdate(
        parent: string,
        $requestBody:
            GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesRequest,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesBatchUpdateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.questionAnswerEntries.batchUpdate',
        path:
            `/${this.gapiVersion}/${parent}/questionAnswerEntries:batchUpdate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<QuestionAnswerEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/questionAnswerEntries/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<QuestionAnswerEntry_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.questionAnswerEntries.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: QuestionAnswerEntry_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListQuestionAnswerEntriesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListQuestionAnswerEntriesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.questionAnswerEntries.list',
        path: `/${this.gapiVersion}/${parent}/questionAnswerEntries`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListQuestionAnswerEntriesResponse_
      });
    }

    patch(
        name: string, $requestBody: QuestionAnswerEntry_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<QuestionAnswerEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/questionAnswerEntries/[^/]+$'));

      return this.$apiClient.$request<QuestionAnswerEntry_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.questionAnswerEntries.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: QuestionAnswerEntry_
      });
    }
  }

  export abstract class
      ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesApiClient {
    constructor() {}

    abstract batchUpdate(
        parent: string,
        $requestBody:
            GoogleCloudKnowledgeV1alpha1BatchUpdateQuestionAnswerEntriesRequest,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesBatchUpdateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<QuestionAnswerEntry_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListQuestionAnswerEntriesResponse_>;

    abstract patch(
        name: string, $requestBody: QuestionAnswerEntry_,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsQuestionAnswerEntriesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<QuestionAnswerEntry_>;
  }

  export type ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv =
      '1'|'2';

  export interface IProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$XgafvEnum {
    readonly 1:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
    readonly 2:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;

    values(): Array<
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv>;
  }

  export const ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$XgafvEnum:
      IProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$XgafvEnum = {
        1: <ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv>'1',
        2: <ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv>'2',
        values():
            Array<
                ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv> {
              return [
                ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$XgafvEnum
                    [1],
                ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$XgafvEnum
                    [2]
              ];
            }
      };

  export type ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum {
    readonly JSON:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    readonly MEDIA:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    readonly PROTO:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;

    values(): Array<
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt>;
  }

  export const ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum:
      IProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum = {
        JSON: <
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt>'json',
        MEDIA: <
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt>'media',
        PROTO: <
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt>'proto',
        values():
            Array<
                ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt> {
              return [
                ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum
                    .JSON,
                ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum
                    .MEDIA,
                ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAltEnum
                    .PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesAutoApproveNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesBatchDeleteNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesBatchUpdateNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesClusterNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesCreateNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesDeleteNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesDisableNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesEnableNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesGetNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesListNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    listOffset?: number;
    filter?: string;
  }

  export declare interface ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesPatchNamedParameters {
    access_token?: string;
    alt?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?:
        ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient$Xgafv;
    updateMask?: string;
  }

  export class
      ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClientImpl
          implements
              ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    autoApprove(
        parent: string, $requestBody: AutoApproveSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesAutoApproveNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.autoApprove',
        path:
            `/${this.gapiVersion}/${parent}/smartMessagingEntries:autoApprove`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchDelete(
        parent: string, $requestBody: BatchDeleteSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesBatchDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.batchDelete',
        path:
            `/${this.gapiVersion}/${parent}/smartMessagingEntries:batchDelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    batchUpdate(
        parent: string, $requestBody: BatchUpdateSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesBatchUpdateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.batchUpdate',
        path:
            `/${this.gapiVersion}/${parent}/smartMessagingEntries:batchUpdate`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    cluster(
        parent: string, $requestBody: ClusterSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesClusterNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.cluster',
        path: `/${this.gapiVersion}/${parent}/smartMessagingEntries:cluster`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    create(
        parent: string, $requestBody: SmartMessagingEntry_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SmartMessagingEntry_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<SmartMessagingEntry_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.create',
        path: `/${this.gapiVersion}/${parent}/smartMessagingEntries`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SmartMessagingEntry_
      });
    }

    delete(
        name: string,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/smartMessagingEntries/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    disable(
        parent: string, $requestBody: DisableSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesDisableNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.disable',
        path: `/${this.gapiVersion}/${parent}/smartMessagingEntries:disable`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    enable(
        parent: string, $requestBody: EnableSmartMessagingEntriesRequest_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesEnableNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.enable',
        path: `/${this.gapiVersion}/${parent}/smartMessagingEntries:enable`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    get(name: string,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SmartMessagingEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/smartMessagingEntries/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SmartMessagingEntry_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SmartMessagingEntry_
      });
    }

    list(
        parent: string,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListSmartMessagingEntriesResponse_> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListSmartMessagingEntriesResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.list',
        path: `/${this.gapiVersion}/${parent}/smartMessagingEntries`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListSmartMessagingEntriesResponse_
      });
    }

    patch(
        name: string, $requestBody: SmartMessagingEntry_,
        namedParameters:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SmartMessagingEntry_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/knowledgeBases/[^/]+/documents/[^/]+/smartMessagingEntries/[^/]+$'));

      return this.$apiClient.$request<SmartMessagingEntry_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId:
            'dialogflow.projects.locations.knowledgeBases.documents.smartMessagingEntries.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SmartMessagingEntry_
      });
    }
  }

  export abstract class
      ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesApiClient {
    constructor() {}

    abstract autoApprove(
        parent: string, $requestBody: AutoApproveSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesAutoApproveNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchDelete(
        parent: string, $requestBody: BatchDeleteSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesBatchDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract batchUpdate(
        parent: string, $requestBody: BatchUpdateSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesBatchUpdateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract cluster(
        parent: string, $requestBody: ClusterSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesClusterNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract create(
        parent: string, $requestBody: SmartMessagingEntry_,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SmartMessagingEntry_>;

    abstract delete(
        name: string,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract disable(
        parent: string, $requestBody: DisableSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesDisableNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract enable(
        parent: string, $requestBody: EnableSmartMessagingEntriesRequest_,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesEnableNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract get(
        name: string,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SmartMessagingEntry_>;

    abstract list(
        parent: string,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListSmartMessagingEntriesResponse_>;

    abstract patch(
        name: string, $requestBody: SmartMessagingEntry_,
        namedParameters?:
            ProjectsLocationsKnowledgeBasesDocumentsSmartMessagingEntriesPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SmartMessagingEntry_>;
  }

  export type ProjectsLocationsOperationsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsOperationsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsOperationsApiClient$Xgafv;
    readonly 2: ProjectsLocationsOperationsApiClient$Xgafv;

    values(): Array<ProjectsLocationsOperationsApiClient$Xgafv>;
  }

  export const ProjectsLocationsOperationsApiClient$XgafvEnum:
      IProjectsLocationsOperationsApiClient$XgafvEnum = {
        1: <ProjectsLocationsOperationsApiClient$Xgafv>'1',
        2: <ProjectsLocationsOperationsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsOperationsApiClient$Xgafv> {
              return [
                ProjectsLocationsOperationsApiClient$XgafvEnum[1],
                ProjectsLocationsOperationsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsOperationsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsOperationsApiClientAltEnum {
    readonly JSON: ProjectsLocationsOperationsApiClientAlt;
    readonly MEDIA: ProjectsLocationsOperationsApiClientAlt;
    readonly PROTO: ProjectsLocationsOperationsApiClientAlt;

    values(): Array<ProjectsLocationsOperationsApiClientAlt>;
  }

  export const ProjectsLocationsOperationsApiClientAltEnum:
      IProjectsLocationsOperationsApiClientAltEnum = {
        JSON: <ProjectsLocationsOperationsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsOperationsApiClientAlt>'media',
        PROTO: <ProjectsLocationsOperationsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsOperationsApiClientAlt> {
              return [
                ProjectsLocationsOperationsApiClientAltEnum.JSON,
                ProjectsLocationsOperationsApiClientAltEnum.MEDIA,
                ProjectsLocationsOperationsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsOperationsCancelNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsOperationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsOperationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsOperationsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsOperationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsOperationsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsOperationsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsOperationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsOperationsApiClient$Xgafv;
    filter?: string;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsLocationsOperationsApiClientImpl implements
      ProjectsLocationsOperationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    cancel(
        name: string,
        namedParameters: ProjectsLocationsOperationsCancelNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/operations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.operations.cancel',
        path: `/${this.gapiVersion}/${name}:cancel`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsOperationsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/operations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.operations.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    list(
        name: string,
        namedParameters: ProjectsLocationsOperationsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningListOperationsResponse> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningListOperationsResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.operations.list',
        path: `/${this.gapiVersion}/${name}/operations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningListOperationsResponse
      });
    }
  }

  export abstract class ProjectsLocationsOperationsApiClient {
    constructor() {}

    abstract cancel(
        name: string,
        namedParameters?: ProjectsLocationsOperationsCancelNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsOperationsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract list(
        name: string,
        namedParameters?: ProjectsLocationsOperationsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningListOperationsResponse>;
  }

  export type ProjectsLocationsPhoneNumberLeasesApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsPhoneNumberLeasesApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsPhoneNumberLeasesApiClient$Xgafv;
    readonly 2: ProjectsLocationsPhoneNumberLeasesApiClient$Xgafv;

    values(): Array<ProjectsLocationsPhoneNumberLeasesApiClient$Xgafv>;
  }

  export const ProjectsLocationsPhoneNumberLeasesApiClient$XgafvEnum:
      IProjectsLocationsPhoneNumberLeasesApiClient$XgafvEnum = {
        1: <ProjectsLocationsPhoneNumberLeasesApiClient$Xgafv>'1',
        2: <ProjectsLocationsPhoneNumberLeasesApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsPhoneNumberLeasesApiClient$Xgafv> {
              return [
                ProjectsLocationsPhoneNumberLeasesApiClient$XgafvEnum[1],
                ProjectsLocationsPhoneNumberLeasesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsPhoneNumberLeasesApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsPhoneNumberLeasesApiClientAltEnum {
    readonly JSON: ProjectsLocationsPhoneNumberLeasesApiClientAlt;
    readonly MEDIA: ProjectsLocationsPhoneNumberLeasesApiClientAlt;
    readonly PROTO: ProjectsLocationsPhoneNumberLeasesApiClientAlt;

    values(): Array<ProjectsLocationsPhoneNumberLeasesApiClientAlt>;
  }

  export const ProjectsLocationsPhoneNumberLeasesApiClientAltEnum:
      IProjectsLocationsPhoneNumberLeasesApiClientAltEnum = {
        JSON: <ProjectsLocationsPhoneNumberLeasesApiClientAlt>'json',
        MEDIA: <ProjectsLocationsPhoneNumberLeasesApiClientAlt>'media',
        PROTO: <ProjectsLocationsPhoneNumberLeasesApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsPhoneNumberLeasesApiClientAlt> {
              return [
                ProjectsLocationsPhoneNumberLeasesApiClientAltEnum.JSON,
                ProjectsLocationsPhoneNumberLeasesApiClientAltEnum.MEDIA,
                ProjectsLocationsPhoneNumberLeasesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsPhoneNumberLeasesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumberLeasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumberLeasesApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsPhoneNumberLeasesUpgradeNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumberLeasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumberLeasesApiClient$Xgafv;
  }

  export class ProjectsLocationsPhoneNumberLeasesApiClientImpl implements
      ProjectsLocationsPhoneNumberLeasesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: CreatePhoneNumberLeaseRequest_,
        namedParameters:
            ProjectsLocationsPhoneNumberLeasesCreateNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumberLease_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<PhoneNumberLease_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.phoneNumberLeases.create',
        path: `/${this.gapiVersion}/${parent}/phoneNumberLeases`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumberLease_
      });
    }

    upgrade(
        name: string, $requestBody: UpgradePhoneNumberLeaseRequest_,
        namedParameters:
            ProjectsLocationsPhoneNumberLeasesUpgradeNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumberOrder_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/phoneNumberLeases/[^/]+$'));

      return this.$apiClient.$request<PhoneNumberOrder_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.phoneNumberLeases.upgrade',
        path: `/${this.gapiVersion}/${name}:upgrade`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumberOrder_
      });
    }
  }

  export abstract class ProjectsLocationsPhoneNumberLeasesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: CreatePhoneNumberLeaseRequest_,
        namedParameters?:
            ProjectsLocationsPhoneNumberLeasesCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumberLease_>;

    abstract upgrade(
        name: string, $requestBody: UpgradePhoneNumberLeaseRequest_,
        namedParameters?:
            ProjectsLocationsPhoneNumberLeasesUpgradeNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumberOrder_>;
  }

  export type ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsPhoneNumberOrdersApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv;
    readonly 2: ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv;

    values(): Array<ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv>;
  }

  export const ProjectsLocationsPhoneNumberOrdersApiClient$XgafvEnum:
      IProjectsLocationsPhoneNumberOrdersApiClient$XgafvEnum = {
        1: <ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv>'1',
        2: <ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv> {
              return [
                ProjectsLocationsPhoneNumberOrdersApiClient$XgafvEnum[1],
                ProjectsLocationsPhoneNumberOrdersApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsPhoneNumberOrdersApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsPhoneNumberOrdersApiClientAltEnum {
    readonly JSON: ProjectsLocationsPhoneNumberOrdersApiClientAlt;
    readonly MEDIA: ProjectsLocationsPhoneNumberOrdersApiClientAlt;
    readonly PROTO: ProjectsLocationsPhoneNumberOrdersApiClientAlt;

    values(): Array<ProjectsLocationsPhoneNumberOrdersApiClientAlt>;
  }

  export const ProjectsLocationsPhoneNumberOrdersApiClientAltEnum:
      IProjectsLocationsPhoneNumberOrdersApiClientAltEnum = {
        JSON: <ProjectsLocationsPhoneNumberOrdersApiClientAlt>'json',
        MEDIA: <ProjectsLocationsPhoneNumberOrdersApiClientAlt>'media',
        PROTO: <ProjectsLocationsPhoneNumberOrdersApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsPhoneNumberOrdersApiClientAlt> {
              return [
                ProjectsLocationsPhoneNumberOrdersApiClientAltEnum.JSON,
                ProjectsLocationsPhoneNumberOrdersApiClientAltEnum.MEDIA,
                ProjectsLocationsPhoneNumberOrdersApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsPhoneNumberOrdersCancelNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumberOrdersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsPhoneNumberOrdersCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumberOrdersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsPhoneNumberOrdersGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumberOrdersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsPhoneNumberOrdersListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumberOrdersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsPhoneNumberOrdersPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumberOrdersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumberOrdersApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsLocationsPhoneNumberOrdersApiClientImpl implements
      ProjectsLocationsPhoneNumberOrdersApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    cancel(
        name: string, $requestBody: CancelPhoneNumberOrderRequest_,
        namedParameters:
            ProjectsLocationsPhoneNumberOrdersCancelNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/phoneNumberOrders/[^/]+$'));

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.phoneNumberOrders.cancel',
        path: `/${this.gapiVersion}/${name}:cancel`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    create(
        parent: string, $requestBody: PhoneNumberOrder_,
        namedParameters:
            ProjectsLocationsPhoneNumberOrdersCreateNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumberOrder_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<PhoneNumberOrder_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.phoneNumberOrders.create',
        path: `/${this.gapiVersion}/${parent}/phoneNumberOrders`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumberOrder_
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsPhoneNumberOrdersGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumberOrder_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/phoneNumberOrders/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<PhoneNumberOrder_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.phoneNumberOrders.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumberOrder_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsPhoneNumberOrdersListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListPhoneNumberOrdersResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListPhoneNumberOrdersResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.phoneNumberOrders.list',
        path: `/${this.gapiVersion}/${parent}/phoneNumberOrders`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListPhoneNumberOrdersResponse_
      });
    }

    patch(
        name: string, $requestBody: PhoneNumberOrder_,
        namedParameters: ProjectsLocationsPhoneNumberOrdersPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumberOrder_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp(
              '^projects/[^/]+/locations/[^/]+/phoneNumberOrders/[^/]+$'));

      return this.$apiClient.$request<PhoneNumberOrder_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.phoneNumberOrders.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumberOrder_
      });
    }
  }

  export abstract class ProjectsLocationsPhoneNumberOrdersApiClient {
    constructor() {}

    abstract cancel(
        name: string, $requestBody: CancelPhoneNumberOrderRequest_,
        namedParameters?:
            ProjectsLocationsPhoneNumberOrdersCancelNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract create(
        parent: string, $requestBody: PhoneNumberOrder_,
        namedParameters?:
            ProjectsLocationsPhoneNumberOrdersCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumberOrder_>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsPhoneNumberOrdersGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumberOrder_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsPhoneNumberOrdersListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListPhoneNumberOrdersResponse_>;

    abstract patch(
        name: string, $requestBody: PhoneNumberOrder_,
        namedParameters?:
            ProjectsLocationsPhoneNumberOrdersPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumberOrder_>;
  }

  export type ProjectsLocationsPhoneNumbersApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsPhoneNumbersApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsPhoneNumbersApiClient$Xgafv;
    readonly 2: ProjectsLocationsPhoneNumbersApiClient$Xgafv;

    values(): Array<ProjectsLocationsPhoneNumbersApiClient$Xgafv>;
  }

  export const ProjectsLocationsPhoneNumbersApiClient$XgafvEnum:
      IProjectsLocationsPhoneNumbersApiClient$XgafvEnum = {
        1: <ProjectsLocationsPhoneNumbersApiClient$Xgafv>'1',
        2: <ProjectsLocationsPhoneNumbersApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsPhoneNumbersApiClient$Xgafv> {
              return [
                ProjectsLocationsPhoneNumbersApiClient$XgafvEnum[1],
                ProjectsLocationsPhoneNumbersApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsPhoneNumbersApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsPhoneNumbersApiClientAltEnum {
    readonly JSON: ProjectsLocationsPhoneNumbersApiClientAlt;
    readonly MEDIA: ProjectsLocationsPhoneNumbersApiClientAlt;
    readonly PROTO: ProjectsLocationsPhoneNumbersApiClientAlt;

    values(): Array<ProjectsLocationsPhoneNumbersApiClientAlt>;
  }

  export const ProjectsLocationsPhoneNumbersApiClientAltEnum:
      IProjectsLocationsPhoneNumbersApiClientAltEnum = {
        JSON: <ProjectsLocationsPhoneNumbersApiClientAlt>'json',
        MEDIA: <ProjectsLocationsPhoneNumbersApiClientAlt>'media',
        PROTO: <ProjectsLocationsPhoneNumbersApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsPhoneNumbersApiClientAlt> {
              return [
                ProjectsLocationsPhoneNumbersApiClientAltEnum.JSON,
                ProjectsLocationsPhoneNumbersApiClientAltEnum.MEDIA,
                ProjectsLocationsPhoneNumbersApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsPhoneNumbersDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumbersApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsPhoneNumbersGetPhoneNumberByE164NamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumbersApiClient$Xgafv;
    phoneNumber?: string;
  }

  export declare interface ProjectsLocationsPhoneNumbersInitiateConversationNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumbersApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsPhoneNumbersListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumbersApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    showDeleted?: boolean;
  }

  export declare interface ProjectsLocationsPhoneNumbersPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumbersApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsLocationsPhoneNumbersUndeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsPhoneNumbersApiClient$Xgafv;
  }

  export class ProjectsLocationsPhoneNumbersApiClientImpl implements
      ProjectsLocationsPhoneNumbersApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    delete(
        name: string,
        namedParameters: ProjectsLocationsPhoneNumbersDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumber_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/phoneNumbers/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<PhoneNumber_>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.phoneNumbers.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumber_
      });
    }

    getPhoneNumberByE164(
        parent: string,
        namedParameters:
            ProjectsLocationsPhoneNumbersGetPhoneNumberByE164NamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumber_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<PhoneNumber_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId:
            'dialogflow.projects.locations.phoneNumbers.getPhoneNumberByE164',
        path:
            `/${this.gapiVersion}/${parent}/phoneNumbers:getPhoneNumberByE164`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumber_
      });
    }

    initiateConversation(
        phoneNumber: string, $requestBody: InitiateConversationRequest_,
        namedParameters:
            ProjectsLocationsPhoneNumbersInitiateConversationNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          phoneNumber,
          new RegExp('^projects/[^/]+/locations/[^/]+/phoneNumbers/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.phoneNumbers.initiateConversation',
        path: `/${this.gapiVersion}/${phoneNumber}:initiateConversation`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsPhoneNumbersListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListPhoneNumbersResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListPhoneNumbersResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.phoneNumbers.list',
        path: `/${this.gapiVersion}/${parent}/phoneNumbers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListPhoneNumbersResponse_
      });
    }

    patch(
        name: string, $requestBody: PhoneNumber_,
        namedParameters: ProjectsLocationsPhoneNumbersPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumber_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/phoneNumbers/[^/]+$'));

      return this.$apiClient.$request<PhoneNumber_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.locations.phoneNumbers.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumber_
      });
    }

    undelete(
        name: string, $requestBody: UndeletePhoneNumberRequest_,
        namedParameters: ProjectsLocationsPhoneNumbersUndeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumber_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/phoneNumbers/[^/]+$'));

      return this.$apiClient.$request<PhoneNumber_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.phoneNumbers.undelete',
        path: `/${this.gapiVersion}/${name}:undelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumber_
      });
    }
  }

  export abstract class ProjectsLocationsPhoneNumbersApiClient {
    constructor() {}

    abstract delete(
        name: string,
        namedParameters?: ProjectsLocationsPhoneNumbersDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumber_>;

    abstract getPhoneNumberByE164(
        parent: string,
        namedParameters?:
            ProjectsLocationsPhoneNumbersGetPhoneNumberByE164NamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumber_>;

    abstract initiateConversation(
        phoneNumber: string, $requestBody: InitiateConversationRequest_,
        namedParameters?:
            ProjectsLocationsPhoneNumbersInitiateConversationNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsPhoneNumbersListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListPhoneNumbersResponse_>;

    abstract patch(
        name: string, $requestBody: PhoneNumber_,
        namedParameters?: ProjectsLocationsPhoneNumbersPatchNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumber_>;

    abstract undelete(
        name: string, $requestBody: UndeletePhoneNumberRequest_,
        namedParameters?: ProjectsLocationsPhoneNumbersUndeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumber_>;
  }

  export type ProjectsLocationsSpecialistPoolsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsSpecialistPoolsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsSpecialistPoolsApiClient$Xgafv;
    readonly 2: ProjectsLocationsSpecialistPoolsApiClient$Xgafv;

    values(): Array<ProjectsLocationsSpecialistPoolsApiClient$Xgafv>;
  }

  export const ProjectsLocationsSpecialistPoolsApiClient$XgafvEnum:
      IProjectsLocationsSpecialistPoolsApiClient$XgafvEnum = {
        1: <ProjectsLocationsSpecialistPoolsApiClient$Xgafv>'1',
        2: <ProjectsLocationsSpecialistPoolsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsSpecialistPoolsApiClient$Xgafv> {
              return [
                ProjectsLocationsSpecialistPoolsApiClient$XgafvEnum[1],
                ProjectsLocationsSpecialistPoolsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsSpecialistPoolsApiClientAlt =
      'json'|'media'|'proto';

  export interface IProjectsLocationsSpecialistPoolsApiClientAltEnum {
    readonly JSON: ProjectsLocationsSpecialistPoolsApiClientAlt;
    readonly MEDIA: ProjectsLocationsSpecialistPoolsApiClientAlt;
    readonly PROTO: ProjectsLocationsSpecialistPoolsApiClientAlt;

    values(): Array<ProjectsLocationsSpecialistPoolsApiClientAlt>;
  }

  export const ProjectsLocationsSpecialistPoolsApiClientAltEnum:
      IProjectsLocationsSpecialistPoolsApiClientAltEnum = {
        JSON: <ProjectsLocationsSpecialistPoolsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsSpecialistPoolsApiClientAlt>'media',
        PROTO: <ProjectsLocationsSpecialistPoolsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsSpecialistPoolsApiClientAlt> {
              return [
                ProjectsLocationsSpecialistPoolsApiClientAltEnum.JSON,
                ProjectsLocationsSpecialistPoolsApiClientAltEnum.MEDIA,
                ProjectsLocationsSpecialistPoolsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsSpecialistPoolsAddSpecialistsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsSpecialistPoolsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsSpecialistPoolsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsSpecialistPoolsCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsSpecialistPoolsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsSpecialistPoolsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsSpecialistPoolsDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsSpecialistPoolsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsSpecialistPoolsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsSpecialistPoolsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsSpecialistPoolsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsSpecialistPoolsApiClient$Xgafv;
  }

  export declare interface ProjectsLocationsSpecialistPoolsListNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsSpecialistPoolsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsSpecialistPoolsApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsLocationsSpecialistPoolsRemoveSpecialistsNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsSpecialistPoolsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsSpecialistPoolsApiClient$Xgafv;
  }

  export class ProjectsLocationsSpecialistPoolsApiClientImpl implements
      ProjectsLocationsSpecialistPoolsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    addSpecialists(
        parent: string, $requestBody: AddSpecialistsToPoolRequest_,
        namedParameters:
            ProjectsLocationsSpecialistPoolsAddSpecialistsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/specialistPools/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.specialistPools.addSpecialists',
        path: `/${this.gapiVersion}/${parent}:addSpecialists`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    create(
        parent: string, $requestBody: SpecialistPool_,
        namedParameters: ProjectsLocationsSpecialistPoolsCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SpecialistPool_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<SpecialistPool_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.locations.specialistPools.create',
        path: `/${this.gapiVersion}/${parent}/specialistPools`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SpecialistPool_
      });
    }

    delete(
        name: string,
        namedParameters: ProjectsLocationsSpecialistPoolsDeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/specialistPools/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.locations.specialistPools.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsLocationsSpecialistPoolsGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SpecialistPool_> {
      this.$apiClient.$validateParameter(
          name,
          new RegExp('^projects/[^/]+/locations/[^/]+/specialistPools/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<SpecialistPool_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.specialistPools.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SpecialistPool_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsLocationsSpecialistPoolsListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListSpecialistPoolsResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+/locations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListSpecialistPoolsResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.locations.specialistPools.list',
        path: `/${this.gapiVersion}/${parent}/specialistPools`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListSpecialistPoolsResponse_
      });
    }

    removeSpecialists(
        parent: string, $requestBody: RemoveSpecialistsFromPoolRequest_,
        namedParameters:
            ProjectsLocationsSpecialistPoolsRemoveSpecialistsNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          parent,
          new RegExp('^projects/[^/]+/locations/[^/]+/specialistPools/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.specialistPools.removeSpecialists',
        path: `/${this.gapiVersion}/${parent}:removeSpecialists`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }
  }

  export abstract class ProjectsLocationsSpecialistPoolsApiClient {
    constructor() {}

    abstract addSpecialists(
        parent: string, $requestBody: AddSpecialistsToPoolRequest_,
        namedParameters?:
            ProjectsLocationsSpecialistPoolsAddSpecialistsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract create(
        parent: string, $requestBody: SpecialistPool_,
        namedParameters?: ProjectsLocationsSpecialistPoolsCreateNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SpecialistPool_>;

    abstract delete(
        name: string,
        namedParameters?: ProjectsLocationsSpecialistPoolsDeleteNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsLocationsSpecialistPoolsGetNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SpecialistPool_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsLocationsSpecialistPoolsListNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListSpecialistPoolsResponse_>;

    abstract removeSpecialists(
        parent: string, $requestBody: RemoveSpecialistsFromPoolRequest_,
        namedParameters?:
            ProjectsLocationsSpecialistPoolsRemoveSpecialistsNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;
  }

  export type ProjectsLocationsSuggestionsApiClient$Xgafv = '1'|'2';

  export interface IProjectsLocationsSuggestionsApiClient$XgafvEnum {
    readonly 1: ProjectsLocationsSuggestionsApiClient$Xgafv;
    readonly 2: ProjectsLocationsSuggestionsApiClient$Xgafv;

    values(): Array<ProjectsLocationsSuggestionsApiClient$Xgafv>;
  }

  export const ProjectsLocationsSuggestionsApiClient$XgafvEnum:
      IProjectsLocationsSuggestionsApiClient$XgafvEnum = {
        1: <ProjectsLocationsSuggestionsApiClient$Xgafv>'1',
        2: <ProjectsLocationsSuggestionsApiClient$Xgafv>'2',
        values():
            Array<ProjectsLocationsSuggestionsApiClient$Xgafv> {
              return [
                ProjectsLocationsSuggestionsApiClient$XgafvEnum[1],
                ProjectsLocationsSuggestionsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsLocationsSuggestionsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsLocationsSuggestionsApiClientAltEnum {
    readonly JSON: ProjectsLocationsSuggestionsApiClientAlt;
    readonly MEDIA: ProjectsLocationsSuggestionsApiClientAlt;
    readonly PROTO: ProjectsLocationsSuggestionsApiClientAlt;

    values(): Array<ProjectsLocationsSuggestionsApiClientAlt>;
  }

  export const ProjectsLocationsSuggestionsApiClientAltEnum:
      IProjectsLocationsSuggestionsApiClientAltEnum = {
        JSON: <ProjectsLocationsSuggestionsApiClientAlt>'json',
        MEDIA: <ProjectsLocationsSuggestionsApiClientAlt>'media',
        PROTO: <ProjectsLocationsSuggestionsApiClientAlt>'proto',
        values():
            Array<ProjectsLocationsSuggestionsApiClientAlt> {
              return [
                ProjectsLocationsSuggestionsApiClientAltEnum.JSON,
                ProjectsLocationsSuggestionsApiClientAltEnum.MEDIA,
                ProjectsLocationsSuggestionsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsLocationsSuggestionsSuggestIssueSmartComposeAnswersNamedParameters {
    access_token?: string;
    alt?: ProjectsLocationsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsLocationsSuggestionsApiClient$Xgafv;
  }

  export class ProjectsLocationsSuggestionsApiClientImpl implements
      ProjectsLocationsSuggestionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    suggestIssueSmartComposeAnswers(
        project: string, $requestBody: SuggestIssueSmartComposeAnswersRequest_,
        namedParameters:
            ProjectsLocationsSuggestionsSuggestIssueSmartComposeAnswersNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestIssueSmartComposeAnswersResponse_> {
      this.$apiClient.$validateParameter(
          project, new RegExp('^projects/[^/]+/locations/[^/]+$'));

      return this.$apiClient.$request<
          SuggestIssueSmartComposeAnswersResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.locations.suggestions.suggestIssueSmartComposeAnswers',
        path: `/${this.gapiVersion}/${
            project}/suggestions:suggestIssueSmartComposeAnswers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestIssueSmartComposeAnswersResponse_
      });
    }
  }

  export abstract class ProjectsLocationsSuggestionsApiClient {
    constructor() {}

    abstract suggestIssueSmartComposeAnswers(
        project: string, $requestBody: SuggestIssueSmartComposeAnswersRequest_,
        namedParameters?:
            ProjectsLocationsSuggestionsSuggestIssueSmartComposeAnswersNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestIssueSmartComposeAnswersResponse_>;
  }

  export type ProjectsOperationsApiClient$Xgafv = '1'|'2';

  export interface IProjectsOperationsApiClient$XgafvEnum {
    readonly 1: ProjectsOperationsApiClient$Xgafv;
    readonly 2: ProjectsOperationsApiClient$Xgafv;

    values(): Array<ProjectsOperationsApiClient$Xgafv>;
  }

  export const ProjectsOperationsApiClient$XgafvEnum:
      IProjectsOperationsApiClient$XgafvEnum = {
        1: <ProjectsOperationsApiClient$Xgafv>'1',
        2: <ProjectsOperationsApiClient$Xgafv>'2',
        values():
            Array<ProjectsOperationsApiClient$Xgafv> {
              return [
                ProjectsOperationsApiClient$XgafvEnum[1],
                ProjectsOperationsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsOperationsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsOperationsApiClientAltEnum {
    readonly JSON: ProjectsOperationsApiClientAlt;
    readonly MEDIA: ProjectsOperationsApiClientAlt;
    readonly PROTO: ProjectsOperationsApiClientAlt;

    values(): Array<ProjectsOperationsApiClientAlt>;
  }

  export const ProjectsOperationsApiClientAltEnum:
      IProjectsOperationsApiClientAltEnum = {
        JSON: <ProjectsOperationsApiClientAlt>'json',
        MEDIA: <ProjectsOperationsApiClientAlt>'media',
        PROTO: <ProjectsOperationsApiClientAlt>'proto',
        values():
            Array<ProjectsOperationsApiClientAlt> {
              return [
                ProjectsOperationsApiClientAltEnum.JSON,
                ProjectsOperationsApiClientAltEnum.MEDIA,
                ProjectsOperationsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsOperationsCancelNamedParameters {
    access_token?: string;
    alt?: ProjectsOperationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsOperationsApiClient$Xgafv;
  }

  export declare interface ProjectsOperationsGetNamedParameters {
    access_token?: string;
    alt?: ProjectsOperationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsOperationsApiClient$Xgafv;
  }

  export declare interface ProjectsOperationsListNamedParameters {
    access_token?: string;
    alt?: ProjectsOperationsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsOperationsApiClient$Xgafv;
    filter?: string;
    pageSize?: number;
    pageToken?: string;
  }

  export class ProjectsOperationsApiClientImpl implements
      ProjectsOperationsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    cancel(
        name: string,
        namedParameters: ProjectsOperationsCancelNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/operations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.operations.cancel',
        path: `/${this.gapiVersion}/${name}:cancel`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    get(name: string,
        namedParameters: ProjectsOperationsGetNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/operations/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.operations.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    list(
        name: string,
        namedParameters: ProjectsOperationsListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningListOperationsResponse> {
      this.$apiClient.$validateParameter(name, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<GoogleLongrunningListOperationsResponse>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.operations.list',
        path: `/${this.gapiVersion}/${name}/operations`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningListOperationsResponse
      });
    }
  }

  export abstract class ProjectsOperationsApiClient {
    constructor() {}

    abstract cancel(
        name: string,
        namedParameters?: ProjectsOperationsCancelNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract get(
        name: string,
        namedParameters?: ProjectsOperationsGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract list(
        name: string,
        namedParameters?: ProjectsOperationsListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningListOperationsResponse>;
  }

  export type ProjectsPhoneNumberLeasesApiClient$Xgafv = '1'|'2';

  export interface IProjectsPhoneNumberLeasesApiClient$XgafvEnum {
    readonly 1: ProjectsPhoneNumberLeasesApiClient$Xgafv;
    readonly 2: ProjectsPhoneNumberLeasesApiClient$Xgafv;

    values(): Array<ProjectsPhoneNumberLeasesApiClient$Xgafv>;
  }

  export const ProjectsPhoneNumberLeasesApiClient$XgafvEnum:
      IProjectsPhoneNumberLeasesApiClient$XgafvEnum = {
        1: <ProjectsPhoneNumberLeasesApiClient$Xgafv>'1',
        2: <ProjectsPhoneNumberLeasesApiClient$Xgafv>'2',
        values():
            Array<ProjectsPhoneNumberLeasesApiClient$Xgafv> {
              return [
                ProjectsPhoneNumberLeasesApiClient$XgafvEnum[1],
                ProjectsPhoneNumberLeasesApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsPhoneNumberLeasesApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsPhoneNumberLeasesApiClientAltEnum {
    readonly JSON: ProjectsPhoneNumberLeasesApiClientAlt;
    readonly MEDIA: ProjectsPhoneNumberLeasesApiClientAlt;
    readonly PROTO: ProjectsPhoneNumberLeasesApiClientAlt;

    values(): Array<ProjectsPhoneNumberLeasesApiClientAlt>;
  }

  export const ProjectsPhoneNumberLeasesApiClientAltEnum:
      IProjectsPhoneNumberLeasesApiClientAltEnum = {
        JSON: <ProjectsPhoneNumberLeasesApiClientAlt>'json',
        MEDIA: <ProjectsPhoneNumberLeasesApiClientAlt>'media',
        PROTO: <ProjectsPhoneNumberLeasesApiClientAlt>'proto',
        values():
            Array<ProjectsPhoneNumberLeasesApiClientAlt> {
              return [
                ProjectsPhoneNumberLeasesApiClientAltEnum.JSON,
                ProjectsPhoneNumberLeasesApiClientAltEnum.MEDIA,
                ProjectsPhoneNumberLeasesApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsPhoneNumberLeasesCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumberLeasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumberLeasesApiClient$Xgafv;
  }

  export declare interface ProjectsPhoneNumberLeasesUpgradeNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumberLeasesApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumberLeasesApiClient$Xgafv;
  }

  export class ProjectsPhoneNumberLeasesApiClientImpl implements
      ProjectsPhoneNumberLeasesApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    create(
        parent: string, $requestBody: CreatePhoneNumberLeaseRequest_,
        namedParameters: ProjectsPhoneNumberLeasesCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumberLease_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<PhoneNumberLease_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.phoneNumberLeases.create',
        path: `/${this.gapiVersion}/${parent}/phoneNumberLeases`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumberLease_
      });
    }

    upgrade(
        name: string, $requestBody: UpgradePhoneNumberLeaseRequest_,
        namedParameters: ProjectsPhoneNumberLeasesUpgradeNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumberOrder_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/phoneNumberLeases/[^/]+$'));

      return this.$apiClient.$request<PhoneNumberOrder_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.phoneNumberLeases.upgrade',
        path: `/${this.gapiVersion}/${name}:upgrade`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumberOrder_
      });
    }
  }

  export abstract class ProjectsPhoneNumberLeasesApiClient {
    constructor() {}

    abstract create(
        parent: string, $requestBody: CreatePhoneNumberLeaseRequest_,
        namedParameters?: ProjectsPhoneNumberLeasesCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumberLease_>;

    abstract upgrade(
        name: string, $requestBody: UpgradePhoneNumberLeaseRequest_,
        namedParameters?: ProjectsPhoneNumberLeasesUpgradeNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumberOrder_>;
  }

  export type ProjectsPhoneNumberOrdersApiClient$Xgafv = '1'|'2';

  export interface IProjectsPhoneNumberOrdersApiClient$XgafvEnum {
    readonly 1: ProjectsPhoneNumberOrdersApiClient$Xgafv;
    readonly 2: ProjectsPhoneNumberOrdersApiClient$Xgafv;

    values(): Array<ProjectsPhoneNumberOrdersApiClient$Xgafv>;
  }

  export const ProjectsPhoneNumberOrdersApiClient$XgafvEnum:
      IProjectsPhoneNumberOrdersApiClient$XgafvEnum = {
        1: <ProjectsPhoneNumberOrdersApiClient$Xgafv>'1',
        2: <ProjectsPhoneNumberOrdersApiClient$Xgafv>'2',
        values():
            Array<ProjectsPhoneNumberOrdersApiClient$Xgafv> {
              return [
                ProjectsPhoneNumberOrdersApiClient$XgafvEnum[1],
                ProjectsPhoneNumberOrdersApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsPhoneNumberOrdersApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsPhoneNumberOrdersApiClientAltEnum {
    readonly JSON: ProjectsPhoneNumberOrdersApiClientAlt;
    readonly MEDIA: ProjectsPhoneNumberOrdersApiClientAlt;
    readonly PROTO: ProjectsPhoneNumberOrdersApiClientAlt;

    values(): Array<ProjectsPhoneNumberOrdersApiClientAlt>;
  }

  export const ProjectsPhoneNumberOrdersApiClientAltEnum:
      IProjectsPhoneNumberOrdersApiClientAltEnum = {
        JSON: <ProjectsPhoneNumberOrdersApiClientAlt>'json',
        MEDIA: <ProjectsPhoneNumberOrdersApiClientAlt>'media',
        PROTO: <ProjectsPhoneNumberOrdersApiClientAlt>'proto',
        values():
            Array<ProjectsPhoneNumberOrdersApiClientAlt> {
              return [
                ProjectsPhoneNumberOrdersApiClientAltEnum.JSON,
                ProjectsPhoneNumberOrdersApiClientAltEnum.MEDIA,
                ProjectsPhoneNumberOrdersApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsPhoneNumberOrdersCancelNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumberOrdersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumberOrdersApiClient$Xgafv;
  }

  export declare interface ProjectsPhoneNumberOrdersCreateNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumberOrdersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumberOrdersApiClient$Xgafv;
  }

  export declare interface ProjectsPhoneNumberOrdersGetNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumberOrdersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumberOrdersApiClient$Xgafv;
  }

  export declare interface ProjectsPhoneNumberOrdersListNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumberOrdersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumberOrdersApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
  }

  export declare interface ProjectsPhoneNumberOrdersPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumberOrdersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumberOrdersApiClient$Xgafv;
    updateMask?: string;
  }

  export class ProjectsPhoneNumberOrdersApiClientImpl implements
      ProjectsPhoneNumberOrdersApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    cancel(
        name: string, $requestBody: CancelPhoneNumberOrderRequest_,
        namedParameters: ProjectsPhoneNumberOrdersCancelNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleProtobufEmpty> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/phoneNumberOrders/[^/]+$'));

      return this.$apiClient.$request<GoogleProtobufEmpty>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.phoneNumberOrders.cancel',
        path: `/${this.gapiVersion}/${name}:cancel`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleProtobufEmpty
      });
    }

    create(
        parent: string, $requestBody: PhoneNumberOrder_,
        namedParameters: ProjectsPhoneNumberOrdersCreateNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumberOrder_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<PhoneNumberOrder_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.phoneNumberOrders.create',
        path: `/${this.gapiVersion}/${parent}/phoneNumberOrders`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumberOrder_
      });
    }

    get(name: string,
        namedParameters: ProjectsPhoneNumberOrdersGetNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumberOrder_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/phoneNumberOrders/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<PhoneNumberOrder_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.phoneNumberOrders.get',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumberOrder_
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsPhoneNumberOrdersListNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListPhoneNumberOrdersResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListPhoneNumberOrdersResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.phoneNumberOrders.list',
        path: `/${this.gapiVersion}/${parent}/phoneNumberOrders`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListPhoneNumberOrdersResponse_
      });
    }

    patch(
        name: string, $requestBody: PhoneNumberOrder_,
        namedParameters: ProjectsPhoneNumberOrdersPatchNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumberOrder_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/phoneNumberOrders/[^/]+$'));

      return this.$apiClient.$request<PhoneNumberOrder_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.phoneNumberOrders.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumberOrder_
      });
    }
  }

  export abstract class ProjectsPhoneNumberOrdersApiClient {
    constructor() {}

    abstract cancel(
        name: string, $requestBody: CancelPhoneNumberOrderRequest_,
        namedParameters?: ProjectsPhoneNumberOrdersCancelNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleProtobufEmpty>;

    abstract create(
        parent: string, $requestBody: PhoneNumberOrder_,
        namedParameters?: ProjectsPhoneNumberOrdersCreateNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumberOrder_>;

    abstract get(
        name: string,
        namedParameters?: ProjectsPhoneNumberOrdersGetNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumberOrder_>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsPhoneNumberOrdersListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListPhoneNumberOrdersResponse_>;

    abstract patch(
        name: string, $requestBody: PhoneNumberOrder_,
        namedParameters?: ProjectsPhoneNumberOrdersPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumberOrder_>;
  }

  export type ProjectsPhoneNumbersApiClient$Xgafv = '1'|'2';

  export interface IProjectsPhoneNumbersApiClient$XgafvEnum {
    readonly 1: ProjectsPhoneNumbersApiClient$Xgafv;
    readonly 2: ProjectsPhoneNumbersApiClient$Xgafv;

    values(): Array<ProjectsPhoneNumbersApiClient$Xgafv>;
  }

  export const ProjectsPhoneNumbersApiClient$XgafvEnum:
      IProjectsPhoneNumbersApiClient$XgafvEnum = {
        1: <ProjectsPhoneNumbersApiClient$Xgafv>'1',
        2: <ProjectsPhoneNumbersApiClient$Xgafv>'2',
        values():
            Array<ProjectsPhoneNumbersApiClient$Xgafv> {
              return [
                ProjectsPhoneNumbersApiClient$XgafvEnum[1],
                ProjectsPhoneNumbersApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsPhoneNumbersApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsPhoneNumbersApiClientAltEnum {
    readonly JSON: ProjectsPhoneNumbersApiClientAlt;
    readonly MEDIA: ProjectsPhoneNumbersApiClientAlt;
    readonly PROTO: ProjectsPhoneNumbersApiClientAlt;

    values(): Array<ProjectsPhoneNumbersApiClientAlt>;
  }

  export const ProjectsPhoneNumbersApiClientAltEnum:
      IProjectsPhoneNumbersApiClientAltEnum = {
        JSON: <ProjectsPhoneNumbersApiClientAlt>'json',
        MEDIA: <ProjectsPhoneNumbersApiClientAlt>'media',
        PROTO: <ProjectsPhoneNumbersApiClientAlt>'proto',
        values():
            Array<ProjectsPhoneNumbersApiClientAlt> {
              return [
                ProjectsPhoneNumbersApiClientAltEnum.JSON,
                ProjectsPhoneNumbersApiClientAltEnum.MEDIA,
                ProjectsPhoneNumbersApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsPhoneNumbersDeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumbersApiClient$Xgafv;
  }

  export declare interface ProjectsPhoneNumbersGetPhoneNumberByE164NamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumbersApiClient$Xgafv;
    phoneNumber?: string;
  }

  export declare interface ProjectsPhoneNumbersInitiateConversationNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumbersApiClient$Xgafv;
  }

  export declare interface ProjectsPhoneNumbersListNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumbersApiClient$Xgafv;
    pageSize?: number;
    pageToken?: string;
    showDeleted?: boolean;
  }

  export declare interface ProjectsPhoneNumbersPatchNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumbersApiClient$Xgafv;
    updateMask?: string;
  }

  export declare interface ProjectsPhoneNumbersUndeleteNamedParameters {
    access_token?: string;
    alt?: ProjectsPhoneNumbersApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsPhoneNumbersApiClient$Xgafv;
  }

  export class ProjectsPhoneNumbersApiClientImpl implements
      ProjectsPhoneNumbersApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    delete(
        name: string,
        namedParameters: ProjectsPhoneNumbersDeleteNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumber_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/phoneNumbers/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<PhoneNumber_>({
        body: $requestBody,
        httpMethod: 'DELETE',
        methodId: 'dialogflow.projects.phoneNumbers.delete',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumber_
      });
    }

    getPhoneNumberByE164(
        parent: string,
        namedParameters:
            ProjectsPhoneNumbersGetPhoneNumberByE164NamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumber_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<PhoneNumber_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.phoneNumbers.getPhoneNumberByE164',
        path:
            `/${this.gapiVersion}/${parent}/phoneNumbers:getPhoneNumberByE164`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumber_
      });
    }

    initiateConversation(
        phoneNumber: string, $requestBody: InitiateConversationRequest_,
        namedParameters:
            ProjectsPhoneNumbersInitiateConversationNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<GoogleLongrunningOperation> {
      this.$apiClient.$validateParameter(
          phoneNumber, new RegExp('^projects/[^/]+/phoneNumbers/[^/]+$'));

      return this.$apiClient.$request<GoogleLongrunningOperation>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.phoneNumbers.initiateConversation',
        path: `/${this.gapiVersion}/${phoneNumber}:initiateConversation`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: GoogleLongrunningOperation
      });
    }

    list(
        parent: string,
        namedParameters: ProjectsPhoneNumbersListNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<ListPhoneNumbersResponse_> {
      this.$apiClient.$validateParameter(
          parent, new RegExp('^projects/[^/]+$'));
      let $requestBody = <Serializable|null>null;

      return this.$apiClient.$request<ListPhoneNumbersResponse_>({
        body: $requestBody,
        httpMethod: 'GET',
        methodId: 'dialogflow.projects.phoneNumbers.list',
        path: `/${this.gapiVersion}/${parent}/phoneNumbers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: ListPhoneNumbersResponse_
      });
    }

    patch(
        name: string, $requestBody: PhoneNumber_,
        namedParameters: ProjectsPhoneNumbersPatchNamedParameters&object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumber_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/phoneNumbers/[^/]+$'));

      return this.$apiClient.$request<PhoneNumber_>({
        body: $requestBody,
        httpMethod: 'PATCH',
        methodId: 'dialogflow.projects.phoneNumbers.patch',
        path: `/${this.gapiVersion}/${name}`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumber_
      });
    }

    undelete(
        name: string, $requestBody: UndeletePhoneNumberRequest_,
        namedParameters: ProjectsPhoneNumbersUndeleteNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<PhoneNumber_> {
      this.$apiClient.$validateParameter(
          name, new RegExp('^projects/[^/]+/phoneNumbers/[^/]+$'));

      return this.$apiClient.$request<PhoneNumber_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId: 'dialogflow.projects.phoneNumbers.undelete',
        path: `/${this.gapiVersion}/${name}:undelete`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: PhoneNumber_
      });
    }
  }

  export abstract class ProjectsPhoneNumbersApiClient {
    constructor() {}

    abstract delete(
        name: string,
        namedParameters?: ProjectsPhoneNumbersDeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumber_>;

    abstract getPhoneNumberByE164(
        parent: string,
        namedParameters?:
            ProjectsPhoneNumbersGetPhoneNumberByE164NamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumber_>;

    abstract initiateConversation(
        phoneNumber: string, $requestBody: InitiateConversationRequest_,
        namedParameters?:
            ProjectsPhoneNumbersInitiateConversationNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<GoogleLongrunningOperation>;

    abstract list(
        parent: string,
        namedParameters?: ProjectsPhoneNumbersListNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<ListPhoneNumbersResponse_>;

    abstract patch(
        name: string, $requestBody: PhoneNumber_,
        namedParameters?: ProjectsPhoneNumbersPatchNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumber_>;

    abstract undelete(
        name: string, $requestBody: UndeletePhoneNumberRequest_,
        namedParameters?: ProjectsPhoneNumbersUndeleteNamedParameters&object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<PhoneNumber_>;
  }

  export type ProjectsSuggestionsApiClient$Xgafv = '1'|'2';

  export interface IProjectsSuggestionsApiClient$XgafvEnum {
    readonly 1: ProjectsSuggestionsApiClient$Xgafv;
    readonly 2: ProjectsSuggestionsApiClient$Xgafv;

    values(): Array<ProjectsSuggestionsApiClient$Xgafv>;
  }

  export const ProjectsSuggestionsApiClient$XgafvEnum:
      IProjectsSuggestionsApiClient$XgafvEnum = {
        1: <ProjectsSuggestionsApiClient$Xgafv>'1',
        2: <ProjectsSuggestionsApiClient$Xgafv>'2',
        values():
            Array<ProjectsSuggestionsApiClient$Xgafv> {
              return [
                ProjectsSuggestionsApiClient$XgafvEnum[1],
                ProjectsSuggestionsApiClient$XgafvEnum[2]
              ];
            }
      };

  export type ProjectsSuggestionsApiClientAlt = 'json'|'media'|'proto';

  export interface IProjectsSuggestionsApiClientAltEnum {
    readonly JSON: ProjectsSuggestionsApiClientAlt;
    readonly MEDIA: ProjectsSuggestionsApiClientAlt;
    readonly PROTO: ProjectsSuggestionsApiClientAlt;

    values(): Array<ProjectsSuggestionsApiClientAlt>;
  }

  export const ProjectsSuggestionsApiClientAltEnum:
      IProjectsSuggestionsApiClientAltEnum = {
        JSON: <ProjectsSuggestionsApiClientAlt>'json',
        MEDIA: <ProjectsSuggestionsApiClientAlt>'media',
        PROTO: <ProjectsSuggestionsApiClientAlt>'proto',
        values():
            Array<ProjectsSuggestionsApiClientAlt> {
              return [
                ProjectsSuggestionsApiClientAltEnum.JSON,
                ProjectsSuggestionsApiClientAltEnum.MEDIA,
                ProjectsSuggestionsApiClientAltEnum.PROTO
              ];
            }
      };

  export declare interface ProjectsSuggestionsSuggestIssueSmartComposeAnswersNamedParameters {
    access_token?: string;
    alt?: ProjectsSuggestionsApiClientAlt;
    callback?: string;
    fields?: string;
    key?: string;
    oauth_token?: string;
    prettyPrint?: boolean;
    quotaUser?: string;
    upload_protocol?: string;
    uploadType?: string;
    $Xgafv?: ProjectsSuggestionsApiClient$Xgafv;
  }

  export class ProjectsSuggestionsApiClientImpl implements
      ProjectsSuggestionsApiClient {
    private $apiClient: ObservableApiClient;

    constructor(
        private gapiVersion: string,
        gapiRequestService: ObservableRequestService,
        apiClientHookFactory: ApiClientHookFactory|null = null) {
      this.$apiClient =
          new ObservableApiClient(gapiRequestService, apiClientHookFactory);
    }

    suggestIssueSmartComposeAnswers(
        project: string, $requestBody: SuggestIssueSmartComposeAnswersRequest_,
        namedParameters:
            ProjectsSuggestionsSuggestIssueSmartComposeAnswersNamedParameters&
        object = {},
        passthroughNamedParameters: globalThis.Record<string, string> = {}):
        Observable<SuggestIssueSmartComposeAnswersResponse_> {
      this.$apiClient.$validateParameter(
          project, new RegExp('^projects/[^/]+$'));

      return this.$apiClient.$request<
          SuggestIssueSmartComposeAnswersResponse_>({
        body: $requestBody,
        httpMethod: 'POST',
        methodId:
            'dialogflow.projects.suggestions.suggestIssueSmartComposeAnswers',
        path: `/${this.gapiVersion}/${
            project}/suggestions:suggestIssueSmartComposeAnswers`,
        queryParams: buildQueryParams(
            namedParameters, PARAM_MAP_0, passthroughNamedParameters),
        responseCtor: SuggestIssueSmartComposeAnswersResponse_
      });
    }
  }

  export abstract class ProjectsSuggestionsApiClient {
    constructor() {}

    abstract suggestIssueSmartComposeAnswers(
        project: string, $requestBody: SuggestIssueSmartComposeAnswersRequest_,
        namedParameters?:
            ProjectsSuggestionsSuggestIssueSmartComposeAnswersNamedParameters&
        object,
        passthroughNamedParameters?: globalThis.Record<string, string>):
        Observable<SuggestIssueSmartComposeAnswersResponse_>;
  }
