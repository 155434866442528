import {NgModule} from '@angular/core';
import {MAT_FAB_DEFAULT_OPTIONS, MatButtonModule} from '@angular/material/mdc-button';

import {GmatButton, GmatFab} from './button';
import {GmatFabIcon} from './fab_icon';

/**
 * Module that provides the MatButtonModule.
 */
@NgModule({
  declarations: [
    GmatButton,
    GmatFabIcon,
    GmatFab,
  ],
  exports: [
    GmatButton,
    MatButtonModule,
    GmatFabIcon,
    GmatFab,
  ],
  providers: [
    {provide: MAT_FAB_DEFAULT_OPTIONS, useValue: {color: 'primary'}}
  ],
})
export class GmatButtonModule {
}
