import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogflowAssistHeadIntentAndFormParameters} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/dialogflow_assist_types';
import {assertBoolean, assertInstanceof} from 'google3/javascript/typescript/contrib/assert';
import {Observable} from 'google3/third_party/javascript/rxjs/src';

/**
 * Head intents list for use with Dialogflow Assist section of Agent Assist
 * simulator.
 */
@Component({
  selector: 'head-intents',
  templateUrl: './head_intents.ng.html',
  styleUrls: ['./head_intents.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadIntents implements OnInit {
  @Input('headIntents')
  readonly headIntents$!:
      Observable<Array<DialogflowAssistHeadIntentAndFormParameters|undefined>>;
  @Input() readonly disabled!: boolean;
  @Input() readonly debugMode!: boolean;

  @Output()
  readonly onChooseHeadIntent =
      new EventEmitter<DialogflowAssistHeadIntentAndFormParameters>();

  handleChooseHeadIntent(dialogflowAssistHeadIntentAndFormParameters:
                             DialogflowAssistHeadIntentAndFormParameters) {
    this.onChooseHeadIntent.emit(dialogflowAssistHeadIntentAndFormParameters);
  }

  trackBy(
      index: number,
      dialogflowAssistHeadIntentAndFormParameters:
          DialogflowAssistHeadIntentAndFormParameters) {
    return dialogflowAssistHeadIntentAndFormParameters;
  }

  ngOnInit() {
    assertInstanceof(this.headIntents$, Observable);
    assertBoolean(this.disabled);
    assertBoolean(
        this.debugMode,
        'Please pass a valid debug mode input to the <head-intents> component.');
  }
}
