import {InjectionToken} from '@angular/core';

/** Token to inject Window */
export const WINDOW_INJECTION_TOKEN =
    new InjectionToken<Window>('Window injection token');

/** Window factory */
export function windowProvider(): Window {
  return window;
}
