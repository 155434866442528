import {ApiErrorResponse} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/api_error';

/**
 * Default error response for non-descript API errors.
 */
export const DEFAULT_API_ERROR_RESPONSE: ApiErrorResponse = {
  error: {
    code: 500,
    message:
        'An error has occurred. No additional details are available at this moment.'
  }
};
