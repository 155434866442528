<mat-spinner
  *ngIf="elementTemplateSet[0].elementType === 'LOADING_SPINNER'; else cardForm"
></mat-spinner>

<ng-template #cardForm>
  <card-form appearance="outline">
    <card-form-header *ngIf="elementTemplateSet[0].elementType === 'TITLE'">
      <card-form-title role="heading" aria-level="4"
        >{{elementTemplateSet[0].title}}</card-form-title
      >
    </card-form-header>

    <section class="workflow-entry">
      <form>
        <ng-container
          *ngFor="let elementTemplate of elementTemplateSet; trackBy: trackBy;"
        >
          <div
            *ngIf="elementTemplate.elementType === 'FORM_PARAMETER_LABEL'"
            class="workflow-entry__form-parameter-label"
          >
            <span
              class="gmat-headline-6"
              role="heading"
              [attr.aria-level]="elementTemplateSet[0].elementType === 'TITLE' ? '5' : '4'"
            >
              {{elementTemplate.formParameterLabel}}
            </span>
          </div>

          <parameter-form-field
            *ngIf="elementTemplate.elementType === 'FORM_PARAMETER'"
            (keyup.enter)="handleSubmitActivePage(elementTemplate.answerRecordName, humanAgentParticipantName)"
            (onInput)="handleFormParameterInput($event)"
            [disabled]="elementTemplate.disabled"
            [parameters]="elementTemplate.parameters"
            [formParameter]="elementTemplate.formParameter"
            [loadStatus]="endUserParticipantLoadStatus"
            [parameterSuggestions]="elementTemplate.parameterSuggestions"
          ></parameter-form-field>

          <response-message
            *ngIf="['ENTRY_PROMPT', 'HANDLER_PROMPT', 'FORM_PARAMETER_PROMPT', 'SUPPLEMENTAL_RESPONSE'].includes(elementTemplate.elementType)"
            (onChooseResponseMessage)="handleChooseResponseMessage(elementTemplate.answerRecordName, elementTemplate.responseMessage)"
            [debugMode]="debugMode"
            [elementTemplate]="elementTemplate"
          ></response-message>

          <mat-chip-listbox
            *ngIf="elementTemplate.elementType === 'INTENT_SUGGESTIONS'"
          >
            <ng-container
              *ngFor="let intentTemplate of elementTemplate.intents; trackBy: trackBy;"
            >
              <mat-chip-option
                appearance="hairline-assistive"
                (click)="handleIntentSuggestion(intentTemplate.answerRecordName, intentTemplate.intent, humanAgentParticipantName)"
                (keydown.enter)="handleIntentSuggestion(intentTemplate.answerRecordName, intentTemplate.intent, humanAgentParticipantName)"
                (keydown.space)="handleIntentSuggestion(intentTemplate.answerRecordName, intentTemplate.intent, humanAgentParticipantName)"
                [disabled]="intentTemplate.disabled"
                [attr.aria-label]="intentTemplate.intent?.displayName"
                [matTooltip]="intentTemplate.intent?.displayName"
                [matTooltipDisabled]="intentTemplate.intent?.displayName?.length < 40"
                data-test-id="intent-suggestion-chip"
              >
                <span class="gmat-body-2">
                  {{intentTemplate.intent?.displayName}}
                </span>
              </mat-chip-option>
            </ng-container>
          </mat-chip-listbox>

          <button
            *ngIf="elementTemplate.elementType === 'CONFIRM_HEAD_INTENT_BUTTON'"
            (click)="handleConfirmNominatedHeadIntent(elementTemplate.answerRecordName, elementTemplate.intent, elementTemplate.parameters, humanAgentParticipantName)"
            aria-label="Submit parameter"
            i18n-aria-label="ARIA label for a button that confirms the suggested head intent"
            class="gmat-button"
            color="primary"
            data-test-id="confirm-head-intent-button"
            mat-flat-button
            type="button"
            i18n="Button that confirms the suggested head intent"
          >
            Next
          </button>

          <button
            *ngIf="elementTemplate.elementType === 'NEXT_BUTTON'"
            (click)="handleSubmitActivePage(elementTemplate.answerRecordName, humanAgentParticipantName)"
            [disabled]="formIsInvalid || elementTemplate.disabled"
            aria-label="Submit parameter"
            i18n-aria-label="ARIA label for a button that moves the form to the next page"
            class="gmat-button"
            color="primary"
            data-test-id="next-button"
            mat-flat-button
            type="button"
            i18n="Button that moves the form to the next page"
          >
            Next
          </button>

          <button
            *ngIf="elementTemplate.elementType === 'DISMISS_SUPPLEMENTAL'"
            (click)="handleDismissSupplementalQuestion(elementTemplate.answerRecordName, elementTemplate.responseMessages)"
            [disabled]="elementTemplate.disabled"
            aria-label="Dismiss supplemental question"
            i18n-aria-label="ARIA label for a button that dismisses a supplemental question"
            class="gmat-button"
            color="primary"
            data-test-id="dismiss-supplemental"
            mat-button
            type="button"
            i18n="Button that dismisses a supplemental question"
          >
            Done
          </button>

          <button
            *ngIf="elementTemplate.elementType === 'SUBMIT_BUTTON'"
            (click)="handleResetWorkflow(humanAgentParticipantName)"
            [disabled]="elementTemplate.disabled"
            aria-label="Submit form"
            i18n-aria-label="ARIA label for a button that submits the form"
            class="gmat-button"
            color="primary"
            data-test-id="submit-button"
            mat-flat-button
            type="button"
            i18n="Button that submits the form"
          >
            Submit form
          </button>

          <div
            *ngIf="elementTemplate.elementType === 'SUPPLEMENTAL_QUESTION'"
            class="workflow-entry__supplemental-question"
          >
            <span
              class="gmat-headline-6"
              role="heading"
              [attr.aria-level]="elementTemplateSet[0].elementType === 'TITLE' ? '6' : '5'"
              i18n="Label for a supplemental question"
              >Supplemental question</span
            >

            <p class="gmat-body-1">
              {{elementTemplate.intent.description ||
              elementTemplate.intent.displayName}}
            </p>
          </div>
        </ng-container>
      </form>
    </section>
  </card-form>
</ng-template>
