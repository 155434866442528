
import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MESSAGE_BAR_ITEM_TYPES, MessageBarItemType, MessageBarLevel} from 'google3/java/com/google/dialogflow/console/web/common/message_bar/message_bar_constants';
import {assert, assertString} from 'google3/javascript/typescript/contrib/assert';

import {MessageBar} from './message_bar';



/**
 * Base message bar item class that is extended by the message bar button and
 * link components.
 *
 * Abstracts common functionality, such as shared inputs, properties, and
 * validation logic.
 */
@Directive()
export abstract class MessageBarItem implements OnInit {
  /**
   * Type for the  message bar item. Determines the style and visual placement
   * of the element.
   */
  @Input() readonly type!: MessageBarItemType;

  /** Aria label for the message bar item. */
  @Input('aria-label') readonly ariaLabel?: string;

  @Output() readonly click = new EventEmitter<Event>();

  get level$() {
    return this.messageBar!.level$;
  }

  constructor(private readonly messageBar: MessageBar|null) {
    if (!messageBar) {
      throw new Error(
          'Message bar button component should only be used as a content child of a message bar component.');
    }
  }

  getIsError(level: MessageBarLevel) {
    return level === 'danger' || level === 'danger-soft';
  }

  getSecondaryButtonClass(level: MessageBarLevel) {
    return {[`message-bar__secondary-button-${level}`]: true};
  }

  ngOnInit() {
    assert(
        MESSAGE_BAR_ITEM_TYPES.includes(this.type),
        'Please pass a valid type to the Message Bar button or link.');

    if (this.ariaLabel) {
      assertString(
          this.ariaLabel,
          'Please pass a valid ARIA label to the Message Bar button or link');
    }
  }
}
