import {COMMON_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/icons';
import {dedupeIconList} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/icon_helpers';

/**
 * Material icons used by the Virtual Agent Assist UI module.
 *
 * Icons names and versions can be found at
 * https://icons.corp.google.com/?icon.set=Google%20Material
 */
export const VIRTUAL_AGENT_ASSIST_ICONS = dedupeIconList([
  ...COMMON_ICONS,
  {name: 'chevron_right', version: 16},
  {name: 'close', version: 18},
  {name: 'input', version: 13},
]);
