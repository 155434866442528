import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FocusInitialModule} from 'google3/java/com/google/dialogflow/console/web/common/a11y/focus_initial/focus_initial_module';
import {LetDirectiveModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/let/let_directive_module';
import {SetClassOnScrollModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/set_class_on_scroll/set_class_on_scroll_module';
import {SystemMessagingModule} from 'google3/java/com/google/dialogflow/console/web/common/system_messaging_service/system_messaging_module';
import {XapClipboardModule} from 'google3/javascript/angular2/components/clipboard/clipboard_module';

import {ConversationSummarization} from './conversation_summarization';



@NgModule({
  declarations: [
    ConversationSummarization,
  ],
  imports: [
    CommonModule,
    FocusInitialModule,
    LetDirectiveModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    SetClassOnScrollModule,
    SystemMessagingModule,
    XapClipboardModule,
  ],
  exports: [
    ConversationSummarization,
  ],
})
export class ConversationSummarizationModule {
}
