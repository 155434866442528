import {AllowlistUrlPath} from 'google3/java/com/google/dialogflow/console/web/ccai/models/types/allowlist_url_path';

const LOCATION_ID_REGEX = /^projects\/[^/]+\/locations\/([^/]+)/;
const PROJECT_ID_REGEX = /^projects\/([^/]+)/;
const ALLOWLIST_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?knowledgeBases\/[^/]+\/documents\/([^/]+)/;
const CONVERSATION_PROFILE_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?conversationProfiles\/([^/]+)/;
const KNOWLEDGE_BASE_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?knowledgeBases\/([^/]+)/;
const FEATURE_CREATION_FLOW_ID_REGEX =
    /^projects\/[^/]+\/locations\/[^\/]+\/featureCreationFlows\/([^/]+)/;
const DOCUMENT_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?knowledgeBases\/[^/]+\/documents\/([^/]+)/;
const CONVERSATION_MODEL_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?conversationModels\/([^/]+)/;
const CONVERSATION_MODEL_EVALUATION_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?conversationModels\/[^/]+\/evaluations\/([^/]+)/;
const SMART_MESSAGING_REVIEW_STATE_REGEX =
    /^\/projects\/[^/]+\/(?:locations\/[^/]+\/)?models\/[^/]+\/messages\/([^/]+)/;
const SERVICE_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?services\/([^/]+)/;
const BIGQUERY_DATASET_ID_REGEX = /^projects\/[^/]+\/datasets\/([^/]+)/;
const AGENT_ENVIRONMENT_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?agent\/environments\/([^/]+)/;
const CONVERSATION_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?conversations\/([^/]+)/;
const PARTICIPANT_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?conversations\/[^/]+\/participants\/([^/]+)/;
const ANSWER_RECORD_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?answerRecords\/([^/]+)/;
const SECURITY_SETTINGS_ID_REGEX =
    /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?securitySettings\/([^/]+)/;
const DATA_ITEM_ID_REGEX =
    /^projects\/[^/]+\/locations\/[^/]+\/conversationDatasets\/[^/]+\/conversationDataItems\/([^/]+)/;
const ANNOTATION_ID_REGEX =
    /^projects\/[^/]+\/locations\/[^/]+\/conversationDatasets\/[^/]+\/conversationDataItems\/[^/]+\/conversationAnnotations\/([^/]+)/;

const getId = (url: string, regex: RegExp) => {
  const match = url.match(regex) || [];

  return match[1];
};

/**
 * Get project Id from project name
 *
 * @example
 * // returns 'foo'
 * getProjectIdFromProjectName('projects/foo');
 */
export const getProjectIdFromName = (projectName: string) =>
    getId(projectName, PROJECT_ID_REGEX);

/**
 * Get location from resource name
 *
 * @example
 * // returns 'bar'
 * getLocationFromName('projects/foo/locations/bar/datasets/baz');
 */
export const getLocationFromName = (name: string) =>
    getId(name, LOCATION_ID_REGEX);

/**
 * Get smart messaging allowlist Id from smart messaging allowlist name
 *
 * @example
 * // returns 'qux'
 * getSmartMessagingAllowlistIdFromName('projects/foo/knowledgeBases/bar/documents/qux');
 */
export const getSmartMessagingAllowlistIdFromName = (allowlistName: string) =>
    getId(allowlistName, ALLOWLIST_ID_REGEX);

/**
 * Get conversation profile Id from conversation profile name
 *
 * @example
 * // returns 'qux'
 * getConversationProfileIdFromName('projects/foo/conversationProfiles/qux');
 */
export const getConversationProfileIdFromName =
    (conversationProfileName: string) =>
        getId(conversationProfileName, CONVERSATION_PROFILE_ID_REGEX);

/**
 * Get knowledge base Id from knowledge base name
 *
 * @example
 * // returns 'qux'
 * getKnowledgeBaseIdFromName('projects/foo/knowledgeBases/qux');
 */
export const getKnowledgeBaseIdFromName = (knowledgeBaseName: string) =>
    getId(knowledgeBaseName, KNOWLEDGE_BASE_ID_REGEX);

/**
 * Gets the feature creation flow ID from a feature creation flow name.
 *
 * @example
 * // returns 'qux'
 * getFeatureCreationFlowIdFromName('projects/foo/locations/global/featureCreationFlows/qux');
 */
export const getFeatureCreationFlowIdFromName =
    (featureCreationFlowName: string) =>
        getId(featureCreationFlowName, FEATURE_CREATION_FLOW_ID_REGEX);

/**
 * Get document Id from knowledge base name
 *
 * @example
 * // returns 'qux'
 * getKnowledgeBaseIdFromName('projects/foo/knowledgeBases/bar/documents/qux');
 */
export const getDocumentIdFromName = (documentName: string) =>
    getId(documentName, DOCUMENT_ID_REGEX);

/**
 * Get conversation model Id from conversation model name
 *
 * @example
 * // returns 'qux'
 * getConversationModelIdFromName('projects/foo/conversationModels/qux');
 */
export const getConversationModelIdFromName = (conversationModelName: string) =>
    getId(conversationModelName, CONVERSATION_MODEL_ID_REGEX);

/**
 * Get conversation model evaluation ID from conversation model name.
 *
 * @example
 * // returns 'qux'
 * getConversationModelEvaluationIdFromName('projects/foo/conversationModels/bar/evaluations/qux');
 */
export const getConversationModelEvaluationIdFromName =
    (conversationModelEvaluationName: string) => getId(
        conversationModelEvaluationName,
        CONVERSATION_MODEL_EVALUATION_ID_REGEX);

/**
 * Get Smart Messaging Entry review state from current URL.
 *
 * @example
 * // returns 'reviewed'
 * getConversationModelIdFromName('projects/foo/models/qux/messages/reviewed');
 */
export const getSmartMessagingReviewStateFromUrl = (url = '') =>
    getId(url, SMART_MESSAGING_REVIEW_STATE_REGEX) as AllowlistUrlPath;

/**
 * Get service ID from service name.
 *
 * @example
 * // returns 'qux'
 * getServiceIdFromName('projects/foo/services/qux');
 */
export const getServiceIdFromName = (serviceName: string) =>
    getId(serviceName, SERVICE_ID_REGEX);

/**
 * Get BigQuery dataset ID from full name.
 *
 * @example
 * // returns 'qux'
 * getBigqueryDatasetIdFromName('projects/foo/datasets/qux');
 */
export const getBigqueryDatasetIdFromName = (bigqueryTableName: string) =>
    getId(bigqueryTableName, BIGQUERY_DATASET_ID_REGEX);


/**
 * Get agent environment ID from full name.
 *
 * @example
 * // returns 'qux'
 * getAgentEnvironmentIdFromName('projects/foo/agent/environments/qux');
 */
export const getAgentEnvironmentIdFromName = (agentEnvironmentName: string) =>
    getId(agentEnvironmentName, AGENT_ENVIRONMENT_ID_REGEX);

/**
 * Get conversation ID from full name.
 *
 * @example
 * // returns 'qux'
 * getAgentEnvironmentIdFromName('projects/foo/conversations/qux');
 */
export const getConversationIdFromName = (name: string) =>
    getId(name, CONVERSATION_ID_REGEX);

/**
 * Get participant ID from full name.
 *
 * @example
 * // returns 'qux'
 * getAgentEnvironmentIdFromName('projects/foo/conversations/bar/participants/qux');
 */
export const getParticipantIdFromName = (name: string) =>
    getId(name, PARTICIPANT_ID_REGEX);

/**
 * Get answer record ID from full name.
 *
 * @example
 * // returns 'qux'
 * getAnswerRecordIdFromName('projects/foo/answerRecords/qux');
 */
export const getAnswerRecordIdFromName = (name: string) =>
    getId(name, ANSWER_RECORD_ID_REGEX);

/**
 * Get security settings ID from full name.
 *
 * @example
 * // returns 'qux'
 * getAnswerRecordIdFromName('projects/foo/locations/bar/securitySettings/qux');
 */
export const getSecuritySettingsIdFromName = (name: string) =>
    getId(name, SECURITY_SETTINGS_ID_REGEX);

/**
 * Get data item ID from full name.
 *
 * @example
 * // returns 'qux'
 * getDataItemIdFromName('projects/foo/locations/foo/conversationDatasets/foo/conversationDataItems/qux');
 */
export const getDataItemIdFromName = (name: string) =>
    getId(name, DATA_ITEM_ID_REGEX);

/**
 * Get annotation ID from full name.
 *
 * @example
 * // returns 'qux'
 * getAnnotationIdFromName('projects/foo/locations/foo/conversationDatasets/foo/conversationDataItems/foo/conversationAnnotations/qux');
 */
export const getAnnotationIdFromName = (name: string) =>
    getId(name, ANNOTATION_ID_REGEX);
