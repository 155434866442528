import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DoBootstrap, NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/mdc-button';
import {MatProgressSpinnerModule} from '@angular/material/mdc-progress-spinner';
import {MatSnackBarModule} from '@angular/material/mdc-snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotificationModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_module';
import {ConversationGuidanceModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/conversation_guidance/conversation_guidance_module';

import {Guidance} from './guidance';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    ConversationGuidanceModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    NotificationModule,
  ],
  declarations: [
    Guidance,
  ],
  exports: [
    Guidance,
  ],
  entryComponents: [
    Guidance,
  ],
})
export class GuidanceModule implements DoBootstrap {
  ngDoBootstrap() {}
}
