import {Injectable} from '@angular/core';
import {SuggestionFeatureStore} from 'google3/java/com/google/dialogflow/console/web/ccai/services/suggestion_feature_store/suggestion_feature_store';

/**
 * Component-level service for Agent Assist suggestion features.
 *
 * Used for abstracting common tasks, such as storing and retrieving the
 * timestamps at Agent Assist suggestions were first displayed.
 */
@Injectable()
export class SuggestionFeatureService<T extends object = {}> {
  constructor(readonly store: SuggestionFeatureStore<T>) {}

  private readonly displayTimes = new Map<string, string>();

  /**
   * Gets the time that an Agent Assist suggestion was first displayed to the
   * user.
   */
  getSuggestionDisplayTime(answerRecord: string|null|undefined) {
    return this.displayTimes.get(answerRecord!);
  }

  /**
   * Saves the display times of one or more Agent Assist suggestions.
   */
  updateSuggestionDisplayTimes(answerRecordNames: string[]|undefined) {
    if (!answerRecordNames) return;

    for (const answerRecordName of answerRecordNames) {
      if (!this.displayTimes.has(answerRecordName)) {
        this.displayTimes.set(answerRecordName, new Date().toISOString());
      }
    }

    for (const answerRecordName of this.displayTimes.keys()) {
      if (!answerRecordNames.includes(answerRecordName)) {
        this.displayTimes.delete(answerRecordName);
      }
    }
  }
}
