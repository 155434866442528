<ng-container *ngIf="level$ | async as messageBarLevel">
  <div
    [@fadeOut]="(dismissed$ | async) ? 'collapsed' : 'expanded'"
    [ngClass]="getWrapperClass(messageBarLevel)"
    class="message-bar__wrapper"
  >
    <div class="message-bar__icon-wrapper">
      <mat-icon
        [svgIcon]="getSvgIcon(messageBarLevel)"
        [attr.aria-label]="'Message bar ' + messageBarLevel + ' icon'"
        class="message-bar__icon"
      ></mat-icon>
    </div>
    <span class="message-bar__message gmat-body-2">{{message}}</span>

    <div class="message-bar__buttons-wrapper">
      <ng-content
        select="message-bar-button[type='secondary'], message-bar-link[type='secondary']"
      ></ng-content>
      <ng-content
        select="message-bar-button[type='primary'], message-bar-link[type='primary']"
      ></ng-content>
    </div>
  </div>
</ng-container>
