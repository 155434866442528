import {generateGcsFileLinkFromUri} from 'google3/java/com/google/dialogflow/console/web/common/helpers/gcs_helpers';
import {ArticleAnswer_, FaqAnswer_, SearchArticleAnswer_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';

import {ArticleOrFaqAnswer, ArticleOrFaqAnswerWithCreateTime, KnowledgeAssistFeature} from './document_and_faq_types';

/**
 * Type guard for ArticleAnswer_ objects.
 */
export function isArticleAnswer(answer: ArticleOrFaqAnswer|null|
                                undefined): answer is ArticleAnswer_ {
  if (!answer) return false;

  const maybeArticleAnswer = answer as ArticleAnswer_;

  return Boolean(
      maybeArticleAnswer.uri || maybeArticleAnswer.title ||
      maybeArticleAnswer.snippets);
}

/**
 * Type guard for FaqAnswer_ objects.
 */
export function isFaqAnswer(answer: ArticleOrFaqAnswer|null|
                            undefined): answer is FaqAnswer_ {
  if (!answer) return false;

  const maybeFaqAnswer = answer as FaqAnswer_;

  return Boolean(maybeFaqAnswer.question && maybeFaqAnswer.answer);
}

/**
 * Dedupes Article and FAQ answers, so that the same suggestion does not
 * appear twice in a row.
 */
export function dedupeDocumentAndFaqSuggestions(
    suggestions: ArticleOrFaqAnswerWithCreateTime[]) {
  return suggestions.reduce(
      (acc: ArticleOrFaqAnswerWithCreateTime[], currentSuggestion) => {
        const latestDedupedSuggestion = acc[acc.length - 1];

        if (!latestDedupedSuggestion) {
          acc.push(currentSuggestion);
          return acc;
        }

        const [secondLatestAnswer, latestAnswer] =
            [latestDedupedSuggestion.answer, currentSuggestion.answer];

        const matchingArticleAnswers = isArticleAnswer(secondLatestAnswer) &&
            isArticleAnswer(latestAnswer) &&
            secondLatestAnswer.title === latestAnswer.title &&
            secondLatestAnswer.snippets?.[0] === latestAnswer.snippets?.[0];

        const matchingFaqAnswers = isFaqAnswer(secondLatestAnswer) &&
            isFaqAnswer(latestAnswer) &&
            secondLatestAnswer.question === latestAnswer.question &&
            secondLatestAnswer.answer === latestAnswer.answer;

        if (!matchingArticleAnswers && !matchingFaqAnswers) {
          acc.push(currentSuggestion);
        }

        return acc;
      },
      []);
}

/** Generates the file link for a Knowledge Base document. */
export function generateFileLink(uri: string) {
  try {
    return generateGcsFileLinkFromUri(uri);
  } catch {
    return uri;
  }
}

/**
 * Gets the correct link for an ArticleAnswer or SearchArticleAnswer response.
 */
export function getArticleAnswerLink(
    answer: ArticleAnswer_|SearchArticleAnswer_,
    linkMetadataKey: string|undefined) {
  return (answer.metadata && linkMetadataKey) ?
      answer.metadata[linkMetadataKey] :
      generateFileLink(answer.uri!);
}

/**
 * Normalize article content by replacing HTML entities with human-readable
 * text.
 */
export function normalizeArticleContent(content: string|null) {
  return content?.replaceAll('&nbsp;', ' ');
}

/**
 * Checks whether the given set of Knowledge Assist features contains Article
 * Suggestion.
 */
export function hasArticleSuggestionEnabled(
    features: KnowledgeAssistFeature[]) {
  return features.includes('ARTICLE_SUGGESTION');
}

/**
 * Checks whether the given set of Knowledge Assist features contains FAQ.
 */
export function hasFaqEnabled(features: KnowledgeAssistFeature[]) {
  return features.includes('FAQ');
}

/**
 * Checks whether the given set of Knowledge Assist features contains Article
 * Search.
 */
export function hasArticleSearchEnabled(features: KnowledgeAssistFeature[]) {
  return features.includes('ARTICLE_SEARCH');
}

/**
 * Checks whether the given set of Knowledge Assist features contains knowledge
 * assist suggestion features (Article Suggestion or FAQ).
 */
export function hasKnowledgeAssistSuggestionEnabled(
    features: KnowledgeAssistFeature[]) {
  return hasArticleSuggestionEnabled(features) || hasFaqEnabled(features);
}
