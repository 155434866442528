import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DoBootstrap, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {KnowledgeAssist} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/knowledge_assist/knowledge_assist';
import {NotificationModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_module';
import {DocumentAndFaqModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/document_and_faq_module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DocumentAndFaqModule,
    HttpClientModule,
    NotificationModule,
  ],
  declarations: [
    KnowledgeAssist,
  ],
  exports: [
    KnowledgeAssist,
  ],
})
export class KnowledgeAssistModule implements DoBootstrap {
  ngDoBootstrap() {}
}
