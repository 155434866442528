import {GUIDANCE_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/guidance/guidance_constants';
import {KNOWLEDGE_ASSIST_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/knowledge_assist/knowledge_assist_constants';
import {SMART_REPLY_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/smart_reply/smart_reply_constants';
import {SUMMARIZATION_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/summarization/summarization_constants';
import {VIRTUAL_AGENT_ASSIST_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/virtual_agent_assist/virtual_agent_assist_constants';
import {dedupeIconList} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/icon_helpers';
import {IconList} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/material_icon';
import {SuggestionFeature_Type} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';



/** UI Module features. */
export type UiModuleFeature = Extract<
    SuggestionFeature_Type,
    'SMART_REPLY'|'CONVERSATION_SUMMARIZATION'|'DIALOGFLOW_ASSIST'|
    'CONVERSATION_GUIDANCE'>|'KNOWLEDGE_ASSIST';

/** Array of UI Module features. */
export const UI_MODULE_FEATURES: UiModuleFeature[] = [
  'CONVERSATION_GUIDANCE',
  'CONVERSATION_SUMMARIZATION',
  'DIALOGFLOW_ASSIST',
  'KNOWLEDGE_ASSIST',
  'SMART_REPLY',
];

/** The currently supported suggestion features. */
export const SUPPORTED_SUGGESTION_FEATURES: SuggestionFeature_Type[] = [
  'CONVERSATION_GUIDANCE',
  'ARTICLE_SUGGESTION',
  'CONVERSATION_SUMMARIZATION',
  'DIALOGFLOW_ASSIST',
  'FAQ',
  'SMART_REPLY',
];

// tslint:disable:enforce-comments-on-exported-symbols
export const CONTAINER_MARGIN_PX = 16;
export const DRAG_CONTAINER_MARGIN_PX = 16;
// tslint:enable:enforce-comments-on-exported-symbols

/**
 * Material icons used by the Container UI module (does not include icons for
 * child modules).
 *
 * Icons names and versions can be found at
 * https://icons.corp.google.com/?icon.set=Google%20Material
 */
export const CONTAINER_ICONS: IconList = [
  {name: 'close', version: 18},
  {name: 'drag_handle', version: 13},
  {name: 'error_filled_alt', version: 11},
];

/** List of Material icons used by all Agent Assist UI modules. */
export const ICON_LIST = dedupeIconList([
  ...SUMMARIZATION_ICONS,
  ...KNOWLEDGE_ASSIST_ICONS,
  ...SMART_REPLY_ICONS,
  ...CONTAINER_ICONS,
  ...VIRTUAL_AGENT_ASSIST_ICONS,
  ...GUIDANCE_ICONS,
]);
