import {LoadingState} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/loading_state';

/**
 * Checks whether a loadingState is 'LOADING'.
 */
export const isLoading = (loadingState: LoadingState|undefined) =>
    loadingState === 'LOADING';

/**
 * Checks whether a loadingState is 'LOADED'.
 */
export const isLoaded = (loadingState: LoadingState|undefined) =>
    loadingState === 'LOADED';

/**
 * Checks whether a loadingState has resolved (either successful, or errored
 * out).
 */
export const isResolved = (loadingState: LoadingState|undefined) =>
    isLoading(loadingState) || isLoaded(loadingState);
