<ng-container
  *ngLet="{
    filter: filter$ | async,
    searchActive: searchActive$ | async,
    searchResultsResolved: searchResultsResolved$ | async
  } as data"
>
  <!-- Search bar -->
  <mat-form-field
    class="article-search__filter-form-field"
    color="accent"
    data-test-id="article-search-input-form-field"
    floatLabel="never"
  >
    <mat-label i18n="Form field label for an input that is used to search for articles"
      >Search all articles</mat-label
    >

    <div matPrefix>
      <button
        *ngIf="data.searchResultsResolved; else searchNotResolved"
        (click)="handleGoBack()"
        data-test-id="back-to-suggestions"
        aria-label="Back to suggestions"
        i18n-aria-label="ARIA label on a back button that returns users back to the list of suggestions"
        matTooltip="Back to suggestions"
        i18n-matTooltip="Tooltip for a back button that returns users back to the list of suggestions"
        mat-icon-button
      >
        <mat-icon svgIcon="arrow_back"></mat-icon>
      </button>
      <ng-template #searchNotResolved>
        <mat-icon svgIcon="search"></mat-icon>
      </ng-template>
    </div>

    <input
      #filterInput
      (keydown.enter)="handleEnterKeypress()"
      [formControl]="filterString"
      aria-label="Search all articles"
      i18n-aria-label="ARIA label on an input field that is used to search for articles"
      autocomplete="off"
      data-test-id="article-search-input"
      type="search"
      matInput
    />

    <button
      *ngIf="filterInput.value"
      matSuffix
      (click)="clearFilter()"
      aria-label="Clear search"
      i18n-aria-label="ARIA label on a button that clears the search input field"
      class="article-search__filter-clear-button"
      data-test-id="article-search-clear-search"
      matTooltip="Clear search"
      i18n-matTooltip="Tooltip for a button that clears the search input field"
      mat-icon-button
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </mat-form-field>

  <div
    *ngIf="data.searchActive"
    class="article-search__body"
    [class.article-search__body--filled]="data.searchResultsResolved"
  >
    <!-- Spinner (if articles are loading) -->
    <mat-spinner
      *ngIf="isLoading"
      aria-label="Articles loading"
      i18n-aria-label="ARIA label on a spinner that indicates that articles are loading"
    ></mat-spinner>

    <!-- Article results -->
    <div *ngIf="data.searchResultsResolved" class="article-search__results">
      <h3 class="article-search__results-header">
        <!-- prettier-ignore -->
        <ng-container *ngIf="data.filter.length <= 10"
          >{answers.length // i18n(ph = "number of answers"), plural,
          =1 {1 search result for {{ data.filter // i18n(ph = "search query") }}}
          other {{{ answers.length // i18n(ph = "number of answers") }} search results for {{
            data.filter // i18n(ph = "search query") }}}}</ng-container>

        <!-- prettier-ignore -->
        <ng-container *ngIf="data.filter.length > 10"
          >{answers.length // i18n(ph = "number of answers"), plural,
          =1 {1 result for {{ data.filter // i18n(ph = "search query") }}}
          other {{{ answers.length // i18n(ph = "number of answers") }} results for {{
            data.filter // i18n(ph = "search query") }}}}</ng-container>
      </h3>
      <article-search-answer
        *ngFor="let answer of answers"
        [answer]="answer"
        [answerRecord]="answerRecords[answer.answerRecord]"
        [articleLinkConfig]="articleLinkConfig"
        (onSelectArticle)="onSelectArticle.emit($event)"
        (onProvideFeedback)="onProvideFeedback.emit($event)"
      ></article-search-answer>
    </div>
  </div>
</ng-container>
