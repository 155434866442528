/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */

import * as internals from 'google3/third_party/javascript/closure/html/internals';
import {SafeUrl} from 'google3/third_party/javascript/closure/html/safeurl';

export {SafeUrl} from 'google3/third_party/javascript/closure/html/safeurl';


/**
 * Builds a new `SafeUrl` from the given string, without enforcing safety
 * guarantees. This shouldn't be exposed to application developers, and must
 * only be used as a step towards safe builders or safe constants.
 */
export function createUrl(url: string): SafeUrl {
  return internals.createSafeUrl(url);
}

/** A SafeUrl containing 'about:blank'. */
export const ABOUT_BLANK: SafeUrl = SafeUrl.ABOUT_BLANK;

/**
 * A SafeUrl containing an inert URL, used as an inert return value when
 * an unsafe input was sanitized.
 */
export const INNOCUOUS_URL: SafeUrl = SafeUrl.INNOCUOUS_URL;

/**
 * Checks if the given value is a `SafeUrl` instance.
 */
export function isUrl(value: unknown): value is SafeUrl {
  return value instanceof SafeUrl;
}

/**
 * Returns the string value of the passed `SafeUrl` object while ensuring it
 * has the correct type.
 */
export function unwrapUrl(value: SafeUrl): string {
  return SafeUrl.unwrap(value);
}
