import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {RichTooltipRef} from './rich_tooltip_ref';

/**
 * Overlay for RichTooltip
 */
@Component({
  selector: 'rich-tooltip-overlay',
  styleUrls: ['./rich_tooltip_overlay.css'],
  templateUrl: './rich_tooltip_overlay.ng.html',
  host: {
    '[attr.role]':
        'richTooltipRef.isRichTooltipInteractive ? "dialog" : "tooltip"',
    '[attr.aria-label]': 'richTooltipRef.richTooltipAriaLabel',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTooltipOverlay implements OnDestroy {
  /** Triggered when this component is destroyed */
  private readonly destroyed = new ReplaySubject<void>(1);

  // @Injected values come from the parent TooltipManager singleton service.
  constructor(
      @Inject('richTooltipRef') readonly richTooltipRef: RichTooltipRef,
      @Inject('triggerElement') readonly triggerElement: HTMLElement,
      changeDetector: ChangeDetectorRef,
  ) {
    this.richTooltipRef.onChange.pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          changeDetector.markForCheck();
        });
  }

  /** Clean up subscriptions when component is destroyed. */
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
