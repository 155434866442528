<div
  *ngIf="internalShowHeader && (initializationResolved$ | async)"
  class="header-wrapper"
>
  <h1 class="gmat-subhead-1" i18n="Top-level header for the Agent Assist page">
    Agent Assist suggestions
  </h1>
</div>

<message-bar
  *ngIf="error$ | async as error"
  level="danger-soft"
  message="An error has occurred. If it persists, please contact your administrator."
  i18n-message="Warning that indicates that an error has occurred, and the user should contact their administrator."
>
  <message-bar-button type="primary" [xapCopyToClipboard]="error | json">
    <ng-container i18n="Text for a button that copies the error details"
      >Copy error details</ng-container
    >
  </message-bar-button>
</message-bar>

<div
  *ngIf="(initializing$ | async) && (initializationCancelled$ | async) === false"
  class="initialization-spinner-wrapper"
>
  <mat-spinner></mat-spinner>
</div>

<div
  [style.display]="(initialized$ | async) ? 'block' : 'none'"
  class="modules-container"
  [cdkDropListData]="orderedFeatures"
  (cdkDropListDropped)="drop($event)"
  cdkDropListLockAxis="y"
  cdkDropList
>
  <div *ngFor="let feature of orderedFeatures" class="drag-container" cdkDrag>
    <div class="drag-handle" cdkDragHandle>
      <mat-icon *ngIf="modulesCount > 1" svgIcon="drag_handle"></mat-icon>
    </div>
    <module-wrapper
      *ngIf="feature === 'CONVERSATION_GUIDANCE'"
      header="Conversation Guidance"
      i18n-header="Header for the Conversation Guidance component"
    >
      <agent-assist-conversation-guidance></agent-assist-conversation-guidance>
    </module-wrapper>

    <module-wrapper
      *ngIf="feature === 'KNOWLEDGE_ASSIST'"
      header="Knowledge Assist"
      i18n-header="Header for the Knowledge Assist component"
    >
      <agent-assist-knowledge-assist
        #documentFaqAssist
        [features]="knowledgeAssistFeatures"
        [config]="config?.knowledgeAssistConfig"
      ></agent-assist-knowledge-assist>
    </module-wrapper>

    <module-wrapper
      *ngIf="feature === 'SMART_REPLY'"
      header="Smart Reply"
      i18n-header="Header for the Smart Reply component"
    >
      <agent-assist-smart-reply></agent-assist-smart-reply>
    </module-wrapper>

    <module-wrapper
      *ngIf="feature === 'DIALOGFLOW_ASSIST'"
      header="Virtual Agent Assist"
      i18n-header="Header for the Virtual Agent Assist component"
    >
      <agent-assist-virtual-agent-assist
        [channel]="channel"
      ></agent-assist-virtual-agent-assist>
    </module-wrapper>

    <module-wrapper
      *ngIf="feature === 'CONVERSATION_SUMMARIZATION'"
      header="Conversation Summary"
      i18n-header="Header for the Conversation Summarization component"
    >
      <agent-assist-summarization
        [onSaveSummary]="onSaveSummary"
        [show-generate-summary-button]="showGenerateSummaryButton"
      ></agent-assist-summarization>
    </module-wrapper>
  </div>
</div>
