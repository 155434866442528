/**
 * Represents the sets of keys pressed to trigger a shortcut.
 */
export class KeyCommand {
  readonly key: string;
  readonly altKey: boolean;
  readonly ctrlKey: boolean;
  readonly metaKey: boolean;
  readonly shiftKey: boolean;

  constructor(init: {key: string}&Partial<KeyCommand>) {
    this.key = init.key;
    this.altKey = !!init.altKey;
    this.ctrlKey = !!init.ctrlKey;
    this.metaKey = !!init.metaKey;
    this.shiftKey = !!init.shiftKey;
  }

  equals(keyCommand: KeyCommand) {
    return this.key === keyCommand.key && this.altKey === keyCommand.altKey &&
        this.ctrlKey === keyCommand.ctrlKey &&
        this.metaKey === keyCommand.metaKey &&
        this.shiftKey === keyCommand.shiftKey;
  }
}
