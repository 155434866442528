<ng-container *ngIf="level$ | async as level">
  <button
    *ngIf="type === 'secondary'; else primaryButton"
    (click)="$event.stopPropagation(); click.emit($event)"
    [attr.aria-label]="ariaLabel"
    [disabled]="disabled"
    class="message-bar__secondary-button"
    type="button"
    mat-button
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>

  <ng-template #primaryButton>
    <ng-container *xapLet="getIsError(level) as isError">
      <button
        *ngIf="!isError"
        (click)="$event.stopPropagation(); click.emit($event)"
        [attr.aria-label]="ariaLabel"
        [disabled]="disabled"
        class="message-bar__primary-button"
        type="button"
        mat-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
      <button
        *ngIf="isError"
        (click)="$event.stopPropagation(); click.emit($event)"
        [attr.aria-label]="ariaLabel"
        [disabled]="disabled"
        class="message-bar__primary-button"
        type="button"
        mat-raised-button
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
