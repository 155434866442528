import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {NonNullableFormBuilder, Validators} from '@angular/forms';
import {convertToPrimitiveParameter} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/dialogflow_assist_shared_helpers';
import {FormParameter} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_v3_ts_api_client_interfaces_only';
import {LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';
import {Dictionary} from 'google3/java/com/google/dialogflow/console/web/common/types/common_types';
import {assert, assertArray, assertObject} from 'google3/third_party/javascript/closure/asserts/asserts';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ParameterFormFieldInputEvent} from './parameter_form_field_types';



const entityTypeMap = {
  'projects/-/locations/-/agents/-/entityTypes/sys.date': 'date',
  'projects/-/locations/-/agents/-/entityTypes/sys.date-time': 'date',
  'projects/-/locations/-/agents/-/entityTypes/sys.email': 'email',
  'projects/-/locations/-/agents/-/entityTypes/sys.number': 'number',
  'projects/-/locations/-/agents/-/entityTypes/sys.phone-number': 'tel',
  'projects/-/locations/-/agents/-/entityTypes/sys.time': 'time',
  'projects/-/locations/-/agents/-/entityTypes/sys.url': 'url',
};

/** Dialogflow Assist parameter form field component. */
@Component({
  selector: 'parameter-form-field',
  templateUrl: './parameter_form_field.ng.html',
  styleUrls: ['./parameter_form_field.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParameterFormField implements AfterViewInit, OnDestroy {
  @Input() readonly loadStatus!: LoadingState;
  @Input()
  get formParameter() {
    return this.internalFormParameter;
  }
  set formParameter(formParameter: FormParameter) {
    this.internalFormParameter = formParameter;
    this.updateParameterFormValue();
  }
  @Input()
  set disabled(isDisabled: boolean) {
    isDisabled ? this.form.disable({onlySelf: true, emitEvent: false}) :
                 this.form.enable({onlySelf: true, emitEvent: false});
  }
  @Input()
  get parameterSuggestions() {
    // Filter out suggestion if it is currently being used in the form.
    return Array.from(this.parameterSuggestionAutoCompletions)
        .filter(
            parameterSuggestionAutoCompletion =>
                parameterSuggestionAutoCompletion !== this.form.value);
  }
  set parameterSuggestions(suggestions: Array<string|number>|undefined) {
    if (suggestions?.length) {
      if (!this.form.value) {
        this.form.setValue(suggestions[0].toString());
      }

      for (const suggestion of suggestions) {
        this.parameterSuggestionAutoCompletions.add(suggestion.toString());
      }
    }
  }
  @Input()
  get parameters() {
    return this.internalParameters;
  }
  set parameters(parameters: Dictionary|undefined) {
    this.internalParameters = parameters;
    this.updateParameterFormValue();
  }

  @Output() readonly onInput = new EventEmitter<ParameterFormFieldInputEvent>();

  readonly form = this.fb.control('');

  // Using a set ensures that there will be no duplicate suggestions.
  readonly parameterSuggestionAutoCompletions = new Set<string>();

  private readonly destroyed$ = new ReplaySubject(1);

  private internalFormParameter: FormParameter = {};
  private internalParameters: Dictionary|undefined = {};

  get inputType() {
    const entityType = this.formParameter.entityType!;

    return entityType in entityTypeMap ?
        entityTypeMap[entityType as keyof typeof entityTypeMap] :
        'text';
  }

  get enabled() {
    return this.form.enabled;
  }

  constructor(
      private readonly fb: NonNullableFormBuilder,
  ) {}

  ngAfterViewInit() {
    assert(this.loadStatus);
    assertObject(this.formParameter);
    assertArray(this.parameterSuggestions);

    this.form.valueChanges.pipe(takeUntil(this.destroyed$))
        .subscribe((inputValue?: string|number) => {
          if (this.formParameter.displayName && inputValue) {
            this.onInput.emit({
              [this.formParameter.displayName]: inputValue.toString(),
            });
          }
        });

    if (this.formParameter.required) {
      this.form.setValidators(Validators.required);
    }

    setTimeout(() => {
      this.form.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private updateParameterFormValue() {
    const parameter = convertToPrimitiveParameter(
        this.parameters?.[this.formParameter?.displayName!]);

    if (parameter) {
      this.parameterSuggestionAutoCompletions.add(parameter);

      if (!this.form.value) {
        this.form.setValue(parameter);
      }
    }
  }
}
