/**
 * Class name to apply to an application-level parent element to indicate that
 * dark mode is set.
 */
export const DARK_MODE_PARENT_CLASS = 'dark-mode';

/**
 * Class name to apply to the dark mode stylesheet that defines the background
 * color.
 */
export const DARK_MODE_STYLESHEET_CLASS = 'dark-mode-stylesheet';

/**
 * Class name to apply to elements whose background should match the dark mode
 * theme.
 */
export const DARK_MODE_BACKGROUND_ELEMENT_CLASS = 'app-background';
