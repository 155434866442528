import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {DialogflowAssistService} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/dialogflow_assist_service';
import {DialogflowAssistElementTemplate, SubmitDialogflowAssistWorkflowPayload} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/dialogflow_assist_types';
import {ParameterFormField} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/parameter_form_field/parameter_form_field';
import {ParameterFormFieldInputEvent} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/parameter_form_field/parameter_form_field_types';
import {IntentSuggestion_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {ResponseMessage} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_v3_ts_api_client_interfaces_only';
import {LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';
import {Dictionary} from 'google3/java/com/google/dialogflow/console/web/common/types/common_types';
import {assertArray, assertString} from 'google3/javascript/typescript/contrib/assert';



/**
 * Workflow entry for use with Dialogflow Assist section of Agent Assist
 * simulator.
 */
@Component({
  selector: 'workflow-entry',
  templateUrl: './workflow_entry.ng.html',
  styleUrls: ['./workflow_entry.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowEntry implements OnInit {
  @ViewChildren(ParameterFormField)
  readonly parameterFormFields?: QueryList<ParameterFormField>;

  @Input() debugMode = false;
  @Input() elementTemplateSet!: DialogflowAssistElementTemplate[];
  @Input() humanAgentParticipantName!: string;
  @Input() endUserParticipantLoadStatus = LoadingState.NOT_LOADING;

  @Output()
  readonly onConfirmNominatedHeadIntent =
      new EventEmitter<SubmitDialogflowAssistWorkflowPayload>();

  @Output()
  readonly onSubmitDialogflowAssistWorkflow =
      new EventEmitter<SubmitDialogflowAssistWorkflowPayload>();

  readonly selectConversationName$ =
      this.dialogflowAssistService.conversationName$;

  form: Dictionary<string> = {};

  constructor(
      private readonly dialogflowAssistService: DialogflowAssistService,
  ) {}

  get formIsEnabled() {
    return this.parameterFormFields?.some(
        parameterFormField => parameterFormField.form.enabled);
  }

  get formIsInvalid() {
    if (this.parameterFormFields?.length) {
      return this.parameterFormFields.some(
          parameterFormFieldComponent =>
              parameterFormFieldComponent.form.invalid);
    }

    return true;
  }

  handleConfirmNominatedHeadIntent(
      answerRecordName: string, intentSuggestion: IntentSuggestion_,
      parameters: Dictionary<string>, participantName: string) {
    this.onConfirmNominatedHeadIntent.emit({
      answerRecordName,
      name: participantName,
      intentSuggestion: intentSuggestion.intentV3!,
      parameters: {
        ...parameters,
        ...this.form,
      }
    });
  }

  handleDismissSupplementalQuestion(
      answerRecordName: string, responseMessages: ResponseMessage[]) {
    for (const responseMessage of responseMessages) {
      this.dialogflowAssistService.dismissResponseMessage(
          answerRecordName, responseMessage);
    }
  }

  handleFormParameterInput(parameterFormFieldInput:
                               ParameterFormFieldInputEvent) {
    this.form = {...this.form, ...parameterFormFieldInput};
  }

  handleIntentSuggestion(
      answerRecordName: string, intentSuggestion: IntentSuggestion_,
      participantName: string) {
    this.onSubmitDialogflowAssistWorkflow.emit({
      answerRecordName,
      name: participantName,
      intentSuggestion: intentSuggestion?.intentV3!
    });
  }

  handleSubmitActivePage(answerRecordName: string, participantName: string) {
    this.onSubmitDialogflowAssistWorkflow.emit(
        {answerRecordName, name: participantName, parameters: this.form});

    if (this.parameterFormFields) {
      for (const parameterFormField of this.parameterFormFields) {
        parameterFormField.form.clearValidators();
      }
    }
  }

  handleResetWorkflow() {
    this.dialogflowAssistService.resetActiveWorkflow();
  }

  handleChooseResponseMessage(
      answerRecordName: string, responseMessage: ResponseMessage) {
    this.dialogflowAssistService.useResponseMessage(
        answerRecordName, responseMessage);
  }

  trackBy(
      index: number,
      dialogflowAssistElementTemplate: DialogflowAssistElementTemplate) {
    return `${index}${dialogflowAssistElementTemplate.answerRecordName}`;
  }

  ngOnInit() {
    assertArray(this.elementTemplateSet);
    assertString(this.humanAgentParticipantName);
  }
}
