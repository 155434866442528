import {animate, state, style, transition, trigger} from '@angular/animations';

/** Fade out animation for dismissing the message bar component. */
export const messageBarFadeOutAnimation = trigger('fadeOut', [
  state(
      'collapsed, void',
      style({height: '0', minHeight: '0', overflow: 'hidden', opacity: 0})),
  state('expanded', style({height: '*', overflow: 'visible', opacity: 1})),
  transition(
      'expanded => collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2,1)')),
  transition(
      'expanded => void', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);
