import {URL_REGEX_MAP} from 'google3/java/com/google/dialogflow/console/web/common/constants/url_regex_map';
import {getProjectIdFromName} from 'google3/java/com/google/dialogflow/console/web/common/helpers/proto_name_id_extractors';

/**
 * Converts a resource name to a location-based one, using `locations/global`
 * as the default location.
 *
 * Example: `projects/foo/datasets/bar` gets converted to
 * `projects/foo/locations/global/datasets/bar`.
 */
export const convertToLocationBasedName =
    (name: string|null|undefined, location = 'global') => {
      if (!name) {
        return '';
      }

      const hasLocation = URL_REGEX_MAP.locations.test(name);

      if (hasLocation) {
        return name;
      }

      return name.replace(
          URL_REGEX_MAP.project,
          `projects/${getProjectIdFromName(name)}/locations/${location}`);
    };

/**
 * Converts a location-based resource name to one without a location.
 *
 * Example: `projects/foo/locations/global/datasets/bar` gets converted to
 * `projects/foo/datasets/bar`.
 */
export const convertToNonLocationBasedName = (name: string|null|undefined) =>
    name ? name.replace(/^(projects\/[^\/]+)\/locations\/[^\/]+/, '$1') : '';
