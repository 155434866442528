import {ArticleOrFaqAnswerWithCreateTime} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/document_and_faq_types';
import {filterTruthy} from 'google3/java/com/google/dialogflow/console/web/common/helpers/array_helpers';
import {AnalyzeContentResponse_, Participant_Role, SmartReplyAnswer_, SuggestionResult_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';

/**
 * Extract suggestion results from analyze content response.
 */
export const getSuggestionsForAnalyzeContentResponse = <T, R = null>(
    analyzeContentResponse: AnalyzeContentResponse_|undefined,
    dataAccessor:
        (suggestionResult: SuggestionResult_,
         analyzeContentResponse?: AnalyzeContentResponse_) =>
            Array<T|R>| null | undefined,
    role: Participant_Role) => {
  const suggestionResults = role === 'HUMAN_AGENT' ?
      analyzeContentResponse?.humanAgentSuggestionResults :
      analyzeContentResponse?.endUserSuggestionResults;

  return suggestionResults?.reduce(
             (suggestions: Array<T|R|undefined>, suggestion) => {
               const answers = dataAccessor(suggestion, analyzeContentResponse);

               return answers ? [...suggestions, ...answers] : suggestions;
             },
             []) ||
      [];
};

/**
 * Gets the Smart Reply suggestions for a given AnalyzeContentResponse.
 *
 * If response is an empty array, no smart reply suggestions were provided. If
 * response is null, AnalyzeContent was called with another suggestion feature,
 * so previous smart reply suggestions still apply.
 */
export const getSmartReplySuggestionsFromAnalyzeContentResponse =
    (analyzeContentResponse: AnalyzeContentResponse_|undefined,
     role: Participant_Role) => {
      return getSuggestionsForAnalyzeContentResponse(
          analyzeContentResponse,
          suggestionResult =>
              suggestionResult.suggestSmartRepliesResponse?.smartReplyAnswers,
          role);
    };

/**
 * De-dupes Smart Reply suggestions, so that duplicate suggestions are not
 * rendered from follow-on suggestions.
 */
export const dedupeSmartReplySuggestions =
    (smartReplyAnswers: SmartReplyAnswer_[]|undefined,
     smartReplyFollowUpAnswers: SmartReplyAnswer_[]|undefined) => {
      const uniqueSmartReplyAnswers =
          [...(smartReplyAnswers || []), ...(smartReplyFollowUpAnswers || [])]
              .reduceRight(
                  (smartReplyAnswersMap: {
                    [smartReplyAnswerReply: string]: SmartReplyAnswer_|
                    undefined;
                  },
                   smartReplyAnswer) => ({
                    ...smartReplyAnswersMap,
                    [smartReplyAnswer.reply!]: smartReplyAnswer,
                  }),
                  {});

      return filterTruthy(Object.values(uniqueSmartReplyAnswers)).reverse();
    };

/**
 * Extracts Article suggestions with the corresponding create time from an
 * AnalyzeContent response.
 */
export const getArticleSuggestionsWithCreateTimeFromAnalyzeContentResponse =
    (analyzeContentResponse: AnalyzeContentResponse_|
     undefined): ArticleOrFaqAnswerWithCreateTime[] => {
      if (!analyzeContentResponse) {
        return [];
      }

      const suggestions =
          getSuggestionsForAnalyzeContentResponse(
              analyzeContentResponse,
              (humanAgentSuggestionResult, analyzeContentResponse) =>
                  humanAgentSuggestionResult.suggestArticlesResponse
                      ?.articleAnswers?.map(
                          articleAnswer =>
                              ({
                                analyzeContentResponse,
                                answer: articleAnswer,
                                createTime:
                                    analyzeContentResponse?.message?.createTime,
                              }) as ArticleOrFaqAnswerWithCreateTime) ||
                  [],
              'HUMAN_AGENT') ||
          [];

      const filteredSuggestions = suggestions.filter(
          (suggestion): suggestion is ArticleOrFaqAnswerWithCreateTime =>
              !!suggestion);

      return filteredSuggestions;
    };

/**
 * Extracts FAQ suggestions with the corresponding create time from an
 * AnalyzeContent response.
 */
export const getFaqSuggestionsWithCreateTimeFromAnalyzeContentResponse =
    (analyzeContentResponse: AnalyzeContentResponse_|
     undefined): ArticleOrFaqAnswerWithCreateTime[] => {
      if (!analyzeContentResponse) {
        return [];
      }

      const suggestions =
          getSuggestionsForAnalyzeContentResponse(
              analyzeContentResponse,
              (humanAgentSuggestionResult, analyzeContentResponse) =>
                  humanAgentSuggestionResult.suggestFaqAnswersResponse
                      ?.faqAnswers?.map(
                          faqAnswer =>
                              ({
                                analyzeContentResponse,
                                answer: faqAnswer,
                                createTime:
                                    analyzeContentResponse?.message?.createTime,
                              }) as ArticleOrFaqAnswerWithCreateTime) ||
                  [],
              'HUMAN_AGENT') ||
          [];

      const filteredSuggestions = suggestions.filter(
          (suggestion): suggestion is ArticleOrFaqAnswerWithCreateTime =>
              !!suggestion);

      return filteredSuggestions;
    };
