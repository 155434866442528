<mat-form-field appearance="outline" data-test-id="parameter-form-field">
  <input
    [formControl]="form"
    [matAutocomplete]="auto"
    [placeholder]="formParameter?.displayName"
    [required]="formParameter.required"
    [type]="inputType"
    aria-label="Parameter value"
    i18n-aria-label="ARIA label for an input field for setting a parameter value"
    data-test-id="parameter-input"
    matInput
  />

  <mat-error
    *ngIf="form.hasError('required')"
    i18n="Error message shown if a required parameter is missing"
  >
    Parameter is <strong>required</strong>
  </mat-error>

  <mat-autocomplete
    #auto="matAutocomplete"
    data-test-id="parameter-autocomplete"
  >
    <mat-option *ngFor="let option of parameterSuggestions" [value]="option">
      {{option}}
    </mat-option>
  </mat-autocomplete>

  <mat-progress-bar
    *ngIf="form.enabled && loadStatus === 'LOADING'"
    color="primary"
    data-test-id="parameter-progress-bar"
    mode="indeterminate"
  ></mat-progress-bar>
</mat-form-field>
