import {ApiErrorResponse} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/api_error';
import {GoogleRpcStatus} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';

/** Determines if an API error is authentication-related. */
export const isAuthError = (error: GoogleRpcStatus|null) => error?.code === 401;

/** Checks whether a thrown error is an API error response. */
export const isApiErrorResponse =
    (error: unknown): error is ApiErrorResponse => {
      return typeof error === 'object' && error !== null && 'error' in error;
    };
