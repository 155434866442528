import {Injectable} from '@angular/core';
import {fromUiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {UiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {SuggestionFeatureStore} from 'google3/java/com/google/dialogflow/console/web/ccai/services/suggestion_feature_store/suggestion_feature_store';
import {takeUntil, withLatestFrom} from 'rxjs/operators';

/**
 * UI module-specific store that is identical to the SuggestionFeatureStore but
 * automatically sets the active conversation whenever the
 * ACTIVE_CONVERSATION_SELECTED event is dispatched.
 */
@Injectable()
export class UiModuleStore<T extends object> extends SuggestionFeatureStore<T> {
  constructor() {
    super();

    fromUiModuleEvent(UiModuleEvent.ACTIVE_CONVERSATION_SELECTED)
        .pipe(takeUntil(this.innerStateService.destroy$))
        .subscribe(({conversationName}) => {
          this.setActiveConversation(conversationName);
        });

    fromUiModuleEvent(UiModuleEvent.CONVERSATION_INITIALIZED)
        .pipe(
            withLatestFrom(this.activeConversationName$),
            takeUntil(this.innerStateService.destroy$))
        .subscribe(([{conversation}, activeConversationName]) => {
          if (!activeConversationName) {
            this.setActiveConversation(conversation.name!);
          }
        });
  }
}
