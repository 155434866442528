/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Provides functions to enforce the SafeUrl contract at the sink
 * level.
 */

import {getLogger, warning} from 'google3/third_party/javascript/closure/log/log';  // LINE-INTERNAL

import {DEV_MODE} from '../environment/dev';
import {SafeUrl, unwrapUrl} from '../internals/url_impl';  // LINE-INTERNAL

// BEGIN-INTERNAL
/**
 * @define
 */
const ASSUME_IMPLEMENTS_URL_API = goog.define(
    'ASSUME_IMPLEMENTS_URL_API',
    // TODO(b/154845327) narrow this down if earlier featureset years allow,
    // if they get defined. FY2020 does NOT include Edge (EdgeHTML), which is
    // good as workarounds are needed for spec compliance and a searchParams
    // polyfill.
    goog.FEATURESET_YEAR >= 2020);

// Tests for URL browser API support. e.g. IE doesn't support it.
const supportsURLAPI = {
  // TODO(b/155106210) Does this work without JSCompiler?
  valueOf() {
    if (ASSUME_IMPLEMENTS_URL_API) {
      return true;
    }
    try {
      new URL('s://g');
      return true;
    } catch (e) {
      return false;
    }
  }
}.valueOf();

function legacyExtractScheme(url: string): string|undefined {
  const aTag = document.createElement('a');
  try {
    // We don't use the safe wrapper here because we don't want to sanitize the
    // URL (which would lead to a dependency loop anyway). This is safe because
    // this node is NEVER attached to the DOM.
    aTag.href = url;
  } catch (e) {
    return undefined;
  }
  // Chrome and Firefox resolve relative scheme to https directly,
  // while IE keeps a ':' or empty string protocol.
  const protocol = aTag.protocol;
  return (protocol === ':' || protocol === '') ? 'https:' : protocol;
}

type JavaScriptUrlSanitizationCallback = (url: string) => void;
// END-INTERNAL

/**
 * Extracts the scheme from the given URL. If the URL is relative, https: is
 * assumed.
 * @param url The URL to extract the scheme from.
 * @return the URL scheme.
 */
export function extractScheme(url: string): string|undefined {
  // BEGIN-INTERNAL
  // We defer to the browser URL parsing as much as possible to detect
  // javascript: schemes. However, old browsers like IE don't support it.
  if (!supportsURLAPI) {
    return legacyExtractScheme(url);
  }
  // END-INTERNAL
  let parsedUrl;
  try {
    parsedUrl = new URL(url);
  } catch (e) {
    // According to https://url.spec.whatwg.org/#constructors, the URL
    // constructor with one parameter throws if `url` is not absolute. In this
    // case, we are sure that no explicit scheme (javascript: ) is set.
    // This can also be a URL parsing error, but in this case the URL won't be
    // run anyway.
    return 'https:';
  }
  return parsedUrl.protocol;
}

// We can't use an ES6 Set here because gws somehow depends on this code and
// doesn't want to pay the cost of a polyfill.
const ALLOWED_SCHEMES = ['data:', 'http:', 'https:', 'mailto:', 'ftp:'];

/**
 * Checks that the URL scheme is not javascript.
 * The URL parsing relies on the URL API in browsers that support it.
 * @param url The URL to sanitize for a SafeUrl sink.
 * @return undefined if url has a javascript: scheme, the original URL
 *     otherwise.
 */
export function sanitizeJavascriptUrl(url: string): string|undefined {
  const parsedScheme = extractScheme(url);
  if (parsedScheme === 'javascript:') {
    // BEGIN-EXTERNAL
    // if (DEV_MODE) {
    //   console.error(`A URL with content '${url}' was sanitized away.`);
    // }
    // END-EXTERNAL
    triggerCallbacks(url);  // LINE-INTERNAL
    return undefined;
  }
  return url;
}

// BEGIN-INTERNAL
/**
 * Type alias for URLs passed to DOM sink wrappers.
 */
export type Url = string|SafeUrl;
// END-INTERNAL
// BEGIN-EXTERNAL
// /**
//  * Type alias for URLs passed to DOM sink wrappers.
//  */
// export type Url = string;
// END-EXTERNAL

/**
 * Adapter to support string and SafeUrl in DOM sink wrappers. // LINE-INTERNAL
// LINE-EXTERNAL * Adapter to sanitize string URLs in DOM sink wrappers.
 * @return undefined if the URL was sanitized.
 */
export function unwrapUrlOrSanitize(url: Url): string|undefined {
  // BEGIN-INTERNAL
  return url instanceof SafeUrl ? unwrapUrl(url) : sanitizeJavascriptUrl(url);
  // END-INTERNAL
  // LINE-EXTERNAL return sanitizeJavascriptUrl(url);
}

/**
 * Sanitizes a URL restrictively.
 * This sanitizer protects against XSS and potentially other uncommon and
 * undesirable schemes that an attacker could use for e.g. phishing (tel:,
 * callto: ssh: etc schemes). This sanitizer is primarily meant to be used by
 * the HTML sanitizer.
 */
export function restrictivelySanitizeUrl(url: string): string {
  const parsedScheme = extractScheme(url);
  if (parsedScheme !== undefined &&
      ALLOWED_SCHEMES.indexOf(parsedScheme.toLowerCase()) !== -1) {
    return url;
  }
  // BEGIN-INTERNAL
  // The sanitizer used to sanitize URLs with SafeUrl's sanitizeUrl which
  // returns this innocuous URL. We need to keep this behavior here because some
  // golden tests still expect this value.
  // TODO(b/238861489): return a short innocuous URL
  // END-INTERNAL
  return 'about:invalid#zClosurez';
}

// BEGIN-INTERNAL
// We need to avoid using ES6 Sets here to avoid the expenssive ES5 polyfill.
// This is acceptable anyway because this array should have only few elements.
const sanitizationCallbacks: JavaScriptUrlSanitizationCallback[] = [];

// This callback is re-defined when a new callback is registered/removed.
// When no callback is registered, there are no references to the
// sanitizationCallbacks array, which make it possible for the compiler to
// optimize it away.
let triggerCallbacks = (url: string) => {};
if (DEV_MODE) {
  addJavaScriptUrlSanitizationCallback((url: string) => {
    warning(
        getLogger('safevalues'),
        `A URL with content '${url}' was sanitized away.`);
  });
}

/**
 * Registers a sanitization callback that is called whenever a javascript: URL
 * is sanitized away.
 */
export function addJavaScriptUrlSanitizationCallback(
    callback: JavaScriptUrlSanitizationCallback): void {
  if (sanitizationCallbacks.indexOf(callback) === -1) {
    sanitizationCallbacks.push(callback);
  }
  triggerCallbacks = (url: string) => {
    sanitizationCallbacks.forEach(callback => {
      callback(url);
    });
  };
}

/**
 * Unregister the JavaScript URL sanitization callback.
 */
export function removeJavaScriptUrlSanitizationCallback(
    callback: JavaScriptUrlSanitizationCallback): void {
  const callbackIndex = sanitizationCallbacks.indexOf(callback);
  if (callbackIndex !== -1) {
    sanitizationCallbacks.splice(callbackIndex, 1);
  }
}
// END-INTERNAL
