import {getRegionalDialogflowApiEndpoint} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/api_helpers';
import {isApiErrorResponse} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/error_helpers';
import {DialogflowHttpService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/dialogflow_http_service';
import {ConnectorConfig} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/connector_types';
import {HttpOptions} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/http_options';
import {convertToLocationBasedName} from 'google3/java/com/google/dialogflow/console/web/common/helpers/location_helpers';
import {getAnswerRecordIdFromName, getConversationIdFromName, getConversationModelIdFromName, getConversationProfileIdFromName, getLocationFromName, getParticipantIdFromName, getProjectIdFromName} from 'google3/java/com/google/dialogflow/console/web/common/helpers/proto_name_id_extractors';
import {AnalyzeContentRequest_, AnalyzeContentResponse_, AnswerRecord_, Conversation_, ConversationModel_, ConversationProfile_, ListMessagesResponse_, ListParticipantsResponse_, Participant_, Participant_Role, SearchArticlesResponse_, SuggestConversationSummaryRequest_, SuggestConversationSummaryResponse_, SuggestSmartRepliesRequest_, SuggestSmartRepliesResponse_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

/**
 * Dialogflow API Service.
 *
 * Used to interact with all Dialogflow and Agent Assist-related endpoints.
 */
export class DialogflowApiService {
  readonly http = new DialogflowHttpService(this.config.apiConfig);

  constructor(private readonly config: ConnectorConfig) {}

  /**
   * Gets an existing Dialogflow conversation.
   *
   * @param conversationName Dialogflow conversation name to use.
   */
  getConversation(conversationName: string) {
    const conversationId = getConversationIdFromName(conversationName);
    const getConversationEndpoint =
        this.getApiEndpoint(`conversations/${conversationId}`);


    return (this.http.get(getConversationEndpoint) as Observable<Conversation_>)
        .pipe(catchError((err: unknown) => {
          if (isApiErrorResponse(err) && err.error?.code === 404) {
            // Return null if no conversation found with the given ID.
            return of(null);
          }

          return throwError(err);
        }));
  }

  /**
   * Gets an existing Dialogflow conversation profile.
   *
   * @param conversationProfileName Dialogflow conversation profile name to use.
   */
  getConversationProfile(conversationProfileName: string) {
    const conversationProfileId =
        getConversationProfileIdFromName(conversationProfileName);
    const getConversationProfileEndpoint =
        this.getApiEndpoint(`conversationProfiles/${conversationProfileId}`);

    return this.http.get(getConversationProfileEndpoint) as
        Observable<ConversationProfile_>;
  }

  /**
   * Gets an existing Dialogflow conversation model.
   *
   * @param conversationModelName Dialogflow conversation model name to use.
   */
  getConversationModel(conversationModelName: string) {
    const conversationModelId =
        getConversationModelIdFromName(conversationModelName);
    const getConversationModelEndpoint =
        this.getApiEndpoint(`conversationModels/${conversationModelId}`);

    return this.http.get(getConversationModelEndpoint) as
        Observable<ConversationModel_>;
  }

  /**
   * Creates a new Dialogflow conversation.
   *
   * @param conversationId Conversation ID to use.
   */
  createConversation(conversationId: string) {
    const createConversationEndpoint =
        this.getApiEndpoint('conversations', {params: {conversationId}});

    const conversation: Conversation_ = {
      'conversationProfile': this.config.conversationProfileName,
    };

    return this.http.post(createConversationEndpoint, conversation) as
        Observable<Conversation_>;
  }

  /**
   * Creates a new participant for a given Dialogflow conversation.
   *
   * @param conversationId Dialogflow conversation ID to use.
   * @param role Participant role to create.
   */
  createParticipant(conversationName: string, role: Participant_Role) {
    const conversationId = getConversationIdFromName(conversationName);
    const createParticipantEndpoint =
        this.getApiEndpoint(`conversations/${conversationId}/participants`);

    const participant: Participant_ = {'role': role};

    return this.http.post(createParticipantEndpoint, participant) as
        Observable<Participant_>;
  }

  /**
   * Lists participants for a given Dialogflow conversation.
   *
   * @param conversationName Dialogflow conversation name to use.
   */
  listParticipants(conversationName: string) {
    const conversationId = getConversationIdFromName(conversationName);

    const listParticipantsEndpoint =
        this.getApiEndpoint(`conversations/${conversationId}/participants`);

    return this.http.get(listParticipantsEndpoint) as
        Observable<ListParticipantsResponse_>;
  }

  /**
   * @export
   * Lists messages for a given Dialogflow conversation.
   *
   * @param conversationName Dialogflow conversation name to use.
   */
  listMessages(conversationName: string) {
    const conversationId = getConversationIdFromName(conversationName);

    const listMessagesEndpoint =
        this.getApiEndpoint(`conversations/${conversationId}/messages`);

    return this.http.get(listMessagesEndpoint) as
        Observable<ListMessagesResponse_>;
  }

  /**
   * Returns Agent Assist suggestions for a given conversational event and
   * participant.
   *
   * @param participantName Participant to return suggestions for.
   * @param request AnalyzeContent request to send.
   */
  analyzeContent(participantName: string, request: AnalyzeContentRequest_) {
    const conversationId = getConversationIdFromName(participantName);
    const participantId = getParticipantIdFromName(participantName);

    const analyzeContentEndpoint = this.getApiEndpoint(`conversations/${
        conversationId}/participants/${participantId}:analyzeContent`);

    return this.http.post(analyzeContentEndpoint, request) as
        Observable<AnalyzeContentResponse_>;
  }

  /**
   * Gets Smart Reply follow-up answers for a given Smart Reply suggestion.
   *
   * @param participantName Participant name to use.
   * @param text Message to fetch follow-up answers for.
   */
  suggestSmartReplies(participantName: string, text: string) {
    const conversationId = getConversationIdFromName(participantName);
    const participantId = getParticipantIdFromName(participantName);
    const suggestSmartRepliesEndpoint =
        this.getApiEndpoint(`conversations/${conversationId}/participants/${
            participantId}/suggestions:suggestSmartReplies`);

    const request: SuggestSmartRepliesRequest_ = {
      'currentTextInput': {'text': text, 'languageCode': 'en'}
    };

    return this.http.post(suggestSmartRepliesEndpoint, request) as
        Observable<SuggestSmartRepliesResponse_>;
  }

  /**
   * Suggests a conversation summary for a given conversation.
   *
   * @param conversationName Dialogflow conversation name to use.
   */
  suggestConversationSummary(conversationName: string) {
    const conversationId = getConversationIdFromName(conversationName);
    const suggestConversationSummaryEndpoint =
        this.getApiEndpoint(`conversations/${
            conversationId}/suggestions:suggestConversationSummary`);

    const request: SuggestConversationSummaryRequest_ = {};

    return this.http.post(suggestConversationSummaryEndpoint, request) as
        Observable<SuggestConversationSummaryResponse_>;
  }

  searchArticles(conversationName: string, filterQuery: string) {
    const conversationId = getConversationIdFromName(conversationName);
    const searchArticlesEndpoint = this.getApiEndpoint(
        `conversations/${conversationId}/suggestions:searchArticles`,
        {params: {'query.text': filterQuery}, defaultLocation: 'global'});

    return (this.http.get(searchArticlesEndpoint) as
            Observable<SearchArticlesResponse_>)
        // Todo (b/226681705): Remove once article answers return the correct
        // location-based answer record names.
        .pipe(
            map(response => {
              const locationBasedConversation =
                  !!getLocationFromName(conversationName);

              if (locationBasedConversation && response.articleAnswers) {
                for (const articleAnswer of response.articleAnswers) {
                  articleAnswer.answerRecord =
                      convertToLocationBasedName(articleAnswer.answerRecord);
                }
              }
              return response;
            }),
        );
  }

  /**
   * Patches an existing Answer Record.
   *
   * @param answerRecord The updated answer record to patch.
   */
  patchAnswerRecord(answerRecord: AnswerRecord_, {updateMask}: {
    updateMask?: string
  } = {}) {
    // tslint:disable-next-line:no-dict-access-on-struct-type
    const answerRecordId = getAnswerRecordIdFromName(answerRecord['name']!);
    const endpoint = this.getApiEndpoint(
        `answerRecords/${answerRecordId}`, {params: {updateMask}});

    return this.http.patch(endpoint, answerRecord) as Observable<AnswerRecord_>;
  }

  /**
   * Gets an Answer Record.
   * http://google3/google/cloud/dialogflow/v2beta1/answer_record.proto;l=32
   *
   * @param answerRecord The updated answer record to patch.
   */
  getAnswerRecord(answerRecordName: string) {
    const answerRecordId = getAnswerRecordIdFromName(answerRecordName);
    const endpoint = this.getApiEndpoint(`answerRecords/${answerRecordId}`);

    return this.http.get(endpoint) as Observable<AnswerRecord_>;
  }

  private getApiEndpoint(path: string, options?: HttpOptions&{
    defaultLocation?: string,
  }) {
    const {apiConfig: {apiKey, customApiEndpoint}, conversationProfileName} =
        this.config;
    const projectId = getProjectIdFromName(conversationProfileName);
    const location = getLocationFromName(conversationProfileName) ||
        options?.defaultLocation;
    const dfApiEndpoint = getRegionalDialogflowApiEndpoint(location);

    const url =
        new URL(`${customApiEndpoint || dfApiEndpoint}/v2beta1/projects/${
            projectId}${location ? `/locations/${location}` : ''}/${path}`);

    // Append for Dialogflow API only.
    if (!customApiEndpoint) {
      url.searchParams.append('alt', 'json');
    }

    if (apiKey) {
      url.searchParams.append('key', apiKey);
    }

    if (options?.params) {
      for (const [key, value] of Object.entries(options.params)) {
        if (key && value) {
          url.searchParams.append(key, value);
        }
      }
    }

    return url.href;
  }
}
