/**
 * @fileoverview Gmat chips injection token to trigger mat-chip to be rendered
 * like gmat-chip.
 *
 * The chips injection token is in a separate file from chips to allow one
 * to inject it in their root module without loading mat-chips and gmat-chips.
 */

import {InjectionToken} from '@angular/core';

/** Interface for the injectable gmat options. */
export interface GmatChipsOptions {
  enabled: boolean;
}

/** Injectable token allowing gmat-chips to optionally apply to mat-chips. */
export const GMAT_CHIPS_OPTION =
    new InjectionToken<GmatChipsOptions>('GMAT_CHIPS_OPTION');
