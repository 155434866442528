import {ConnectorApiConfig} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/connector_types';
import {HttpOptions} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/http_options';
import {from} from 'rxjs';

/**
 * Base service for making HTTP requests.
 */
export class HttpService {
  constructor(protected readonly apiConfig:
                  Pick<ConnectorApiConfig, 'authToken'>) {}

  /**
   * Makes an HTTP GET request.
   *
   * @param endpoint Endpoint to make the request to.
   * @param options Additional request options.
   */
  get(endpoint: string, options?: HttpOptions) {
    return this.makeRequest({method: 'GET', endpoint, options});
  }

  /**
   * Makes an HTTP POST request.
   *
   * @param endpoint Endpoint to make the request to.
   * @param body Request body.
   * @param options Additional request options.
   */
  post<T extends object>(endpoint: string, body: T, options?: HttpOptions) {
    return this.makeRequest({method: 'POST', endpoint, body, options});
  }

  /**
   * Makes an HTTP PATCH request.
   *
   * @param endpoint Endpoint to make the request to.
   * @param body Request body.
   * @param options Additional request options.
   */
  patch<T extends object>(endpoint: string, body: T, options?: HttpOptions) {
    return this.makeRequest({method: 'PATCH', endpoint, body, options});
  }

  protected makeRequest<T extends object>({method, endpoint, body, options}: {
    method: 'GET'|'POST'|'PATCH',
    endpoint: string,
    options?: HttpOptions,
    body?: T
  }) {
    const headers: Array<[string, string]> = (options?.headers || []);

    if (this.apiConfig.authToken) {
      headers.push(['Authorization', this.apiConfig.authToken]);
    }

    const request: RequestInit = {method, headers};

    if (body) {
      request.body = JSON.stringify(body);
    }

    return from(fetch(endpoint, request).then(response => {
      return response.json();
    }));
  }
}
