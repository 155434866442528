import {inject, Injectable, InjectFlags, OnDestroy} from '@angular/core';
import {PARENT_CONTAINER_CONTEXT} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/injection_tokens';
import {fromUiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {UiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {SystemMessagingService} from 'google3/java/com/google/dialogflow/console/web/common/system_messaging_service/system_messaging_service';
import {ReplaySubject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

/**
 * Initializes listener to display a Material notification snackbar when
 * requested by a module.
 */
@Injectable()
export class NotificationService implements OnDestroy {
  private readonly parentContainerContext =
      inject(PARENT_CONTAINER_CONTEXT, InjectFlags.Optional);
  private readonly destroyed$ = new ReplaySubject(1);

  constructor(
      private readonly systemMessagingService: SystemMessagingService,
  ) {}

  /** Initializes the notification service for individual UI modules. */
  initModule() {
    if (!this.parentContainerContext) {
      this.init();
    }
  }

  /** Initializes the notification service for the parent Container module. */
  initContainer() {
    this.init();
  }

  private init() {
    fromUiModuleEvent(UiModuleEvent.SNACKBAR_NOTIFICATION_REQUESTED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({message, actionMessage, actionHandler}) => {
          const snackbarRef = this.systemMessagingService.showInfoSnackBar(
              message, actionMessage);
          if (actionHandler) {
            snackbarRef.onAction().pipe(take(1)).subscribe(() => {
              actionHandler();
            });
          }
        });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
