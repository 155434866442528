import {AriaDescriber, ConfigurableFocusTrapFactory} from '@angular/cdk/a11y';
import {ESCAPE} from '@angular/cdk/keycodes';
import {ConnectedPosition, Overlay, OverlayConfig} from '@angular/cdk/overlay';
import {DOCUMENT} from '@angular/common';
import {Directive, ElementRef, Inject, Injector, Input, NgZone, OnInit, ViewContainerRef} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {audit, debounceTime, filter, takeUntil, throttle} from 'rxjs/operators';

import {XapInlineDialogBase} from './inline_dialog_base';

// NOTE: the 500ms delay comes from the Reach spec. But it is longer than
// components with similar functionalities (e.g. `xap-bubble`). The issue is
// being discussed with Reach UX, and we decided to align with current spec
// before we hear from them.
// Hopper link of the issue: https://hopper.googleplex.com/issue/138995562
/**
 * Default delay in milliseconds of showing the dialog with mouse interactions.
 */
export const DEFAULT_MOUSE_DELAY_MS = 500;

/**
 * An attribute directive that shows a popup dialog when hovered on the element
 * it is placed on.
 */
@Directive({
  selector: '[xapInlineDialog]',
  exportAs: 'xapInlineDialog',
  host: {'class': 'xap-inline-dialog'},
  inputs: ['dialog: xapInlineDialog', 'disabled: xapInlineDialogDisabled'],
})
export class XapInlineDialog extends XapInlineDialogBase implements OnInit {
  /** Controls hover delay in milliseconds after mouseenter from mouse rest. */
  @Input() hoverDelayMs: number = DEFAULT_MOUSE_DELAY_MS;

  private readonly activity = new Subject<void>();

  constructor(
      ngZone: NgZone,
      overlay: Overlay,
      elementRef: ElementRef,
      viewContainerRef: ViewContainerRef,
      @Inject(DOCUMENT) document: Document,
      focusTrapFactory: ConfigurableFocusTrapFactory,
      ariaDescriber: AriaDescriber,
      injector: Injector,
  ) {
    super(
        ngZone, overlay, elementRef, viewContainerRef, document,
        focusTrapFactory, ariaDescriber, injector);
    this.attachMouseEventListeners(elementRef.nativeElement);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.listenForOpenEvents(this.hoverDelayMs);
  }

  private listenForOpenEvents(hoverDelayMs: number): void {
    // Delay opening/closing until mouse has stopped moving and a short amount
    // of time (can be configured via `hoverDelayMs` input field) has passed in
    // order to ignore accidental movements.
    const debouncedStatusChange = this.openStatusChange.pipe(
        audit(() => this.activity.pipe(debounceTime(hoverDelayMs))),
    );

    debouncedStatusChange.pipe(takeUntil(this.destroyed))
        .subscribe((opened) => {
          opened ? this.openDialog() : this.closeDialog();
        });

    // Emit the mouse triggered beforeOpened event when the first
    // open-triggering event happens.
    this.openings
        .pipe(
            filter(
                opening => opening && this.beforeOpened.observers.length > 0),
            throttle(
                () => debouncedStatusChange.pipe(filter(opened => !opened))),
            takeUntil(this.destroyed),
            )
        .subscribe(() => {
          this.ngZone.run(() => {
            this.beforeOpened.emit();
          });
        });
  }

  attachMouseEventListeners(element: HTMLElement): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(element, 'mouseenter')
          .pipe(takeUntil(this.destroyed))
          .subscribe(() => {
            this.openingDialog();
          });

      // Need to listen to click events for NVDA users
      // as keyboard events are not triggered in browse mode - b/161314292
      fromEvent(element, 'click')
          .pipe(takeUntil(this.destroyed))
          .subscribe(() => {
            this.openingDialog();
            this.openDialog();
          });

      fromEvent(element, 'mouseleave')
          .pipe(takeUntil(this.destroyed))
          .subscribe(() => {
            this.closingDialog();
          });

      fromEvent(element, 'mousemove')
          .pipe(takeUntil(this.destroyed))
          .subscribe(() => {
            this.activity.next();
          });
    });
  }

  attachKeyboardCloseEventListeners(element: HTMLElement): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent<KeyboardEvent>(element, 'keydown')
          .pipe(takeUntil(this.destroyed))
          .subscribe((event: KeyboardEvent) => {
            const keyCode = event.keyCode;
            switch (keyCode) {
              case ESCAPE:
                // Prevents escape keydown event propagation if dialog is open.
                if (this.overlayRef?.hasAttached()) {
                  event.stopPropagation();
                }
                this.closeDialog();
                return;
              default:
                return;
            }
          });
    });
  }

  /**
   * Open the dialog after cursor stops moving for the period of time set in
   * `hoverDelayMs` and if it's in the triggering area.
   */
  private openingDialog(): void {
    if (this.disabled) return;
    this.openStatusChange.next(true);
    this.activity.next();
  }

  /**
   * Close the dialog after cursor stops moving for the period of time set in
   * `hoverDelayMs` and if it's not in the triggering area.
   */
  private closingDialog(): void {
    this.openStatusChange.next(false);
    this.activity.next();
  }

  createOverlayConfig(positions: ConnectedPosition[]): OverlayConfig {
    return new OverlayConfig({
      ...this.overlayDimensions,
      positionStrategy: super.createPositionStrategy(positions),
      scrollStrategy: this.overlay.scrollStrategies.close(),
      panelClass: this.panelClassInternal,
    });
  }
}
