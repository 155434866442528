/**
 * Enum for identifying the loading state.
 * We have string representation of enums here to see strings instead of
 * numbers in redux dev tools.
 */
export enum LoadingState {
  ERROR = 'ERROR',
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  NOT_LOADING = 'NOT_LOADING',
}

/**
 * Checks whether a given loading state has resolved (completed successfully,
 * or resulted in an error.)
 */
export const isResolved = (loadingState: LoadingState) =>
    loadingState === LoadingState.LOADED || loadingState === LoadingState.ERROR;
