import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

import {ArticleSearchAnswer} from './article_search_answer';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  declarations: [
    ArticleSearchAnswer,
  ],
  exports: [
    ArticleSearchAnswer,
  ]
})
export class ArticleSearchAnswerModule {
}
