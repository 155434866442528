import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';

import {CardForm, CardFormActions, CardFormContent, CardFormContentDescription, CardFormHeader, CardFormRawContent, CardFormSection, CardFormSectionSubtitle, CardFormSectionTitle, CardFormSubitle, CardFormTitle} from './card_form';

@NgModule({
  declarations: [
    CardForm,
    CardFormHeader,
    CardFormSection,
    CardFormActions,
    CardFormTitle,
    CardFormSubitle,
    CardFormContent,
    CardFormSectionTitle,
    CardFormSectionSubtitle,
    CardFormContentDescription,
    CardFormRawContent,
  ],
  exports: [
    CardForm,
    CardFormHeader,
    CardFormSection,
    CardFormActions,
    CardFormTitle,
    CardFormSubitle,
    CardFormContent,
    CardFormSectionTitle,
    CardFormSectionSubtitle,
    CardFormContentDescription,
    CardFormRawContent,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
  ],
})
export class CardFormModule {
}
