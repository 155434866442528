import {DialogflowAssistAnswer_, IntentSuggestion_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {GoogleCloudDialogflowV2beta1ParameterSuggestionsCandidate, ResponseMessage} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_v3_ts_api_client_interfaces_only';

import {convertToPrimitiveParameter, getQueryResultV3FromDialogflowAssistAnswer} from './dialogflow_assist_shared_helpers';
import {DialogflowAssistElementTemplate} from './dialogflow_assist_types';



/** DialogflowAssistElementTemplate generator function. */
export const
    generateDialogflowAssistElementTemplateCreatorWithAnswerRecordName =
        <T extends {answerRecordName: string} =
                       DialogflowAssistElementTemplate>(
            answerRecordName: string) => (restOfDialogflowAssistElementTemplate:
                                              Omit<T, 'answerRecordName'>): T =>
            ({
              answerRecordName,
              ...restOfDialogflowAssistElementTemplate,
            } as T);

/**
 * Gets the first Dialogflow Assist answer that contains the newest page seen in
 * the workflow.
 */
export const getDialogflowAssistAnswerContext = (activeWorkflow: Array<
                                                 DialogflowAssistAnswer_|
                                                 undefined>) => {
  if (!activeWorkflow.length) return;

  return activeWorkflow.reduce(
      (dialogflowAssistAnswerWithFirstInstanceOfNewestPage,
       dialogflowAssistAnswer) => {
        const dialogflowAssistAnswerWithFirstInstanceOfNewestPagePageName =
            getQueryResultV3FromDialogflowAssistAnswer(
                dialogflowAssistAnswerWithFirstInstanceOfNewestPage!)
                ?.currentPage?.name;
        const dialogflowAssistAnswerCurrentPageName =
            getQueryResultV3FromDialogflowAssistAnswer(dialogflowAssistAnswer!)
                ?.currentPage?.name;

        if (!dialogflowAssistAnswerCurrentPageName) {
          return dialogflowAssistAnswerWithFirstInstanceOfNewestPage;
        }

        return dialogflowAssistAnswerWithFirstInstanceOfNewestPagePageName !==
                dialogflowAssistAnswerCurrentPageName ?
            dialogflowAssistAnswer :
            dialogflowAssistAnswerWithFirstInstanceOfNewestPage;
      });
};

/**
 * Gets the entry fulfillment response messages from a Dialogflow Assist
 * answer.
 */
export const getEntryFulfillmentResponseMessagesFromDialogflowAssistAnswer =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_|null) => {
      const queryResultV3 =
          getQueryResultV3FromDialogflowAssistAnswer(dialogflowAssistAnswer);

      return getTextAndCustomResponseMessages(
                 queryResultV3?.responseMessages?.filter(
                     responseMessage =>
                         responseMessage.responseType === 'ENTRY_PROMPT'))
          ?.reduce((entryFulfillments: ResponseMessage[], entryFulfillment) => {
            if (entryFulfillment.payload) {
              entryFulfillments.push(entryFulfillment);
            }

            if (entryFulfillment.text?.text?.length) {
              entryFulfillments.push(...entryFulfillment.text?.text?.map(
                  (text): ResponseMessage => ({'text': {'text': [text]}})));
            }

            return entryFulfillments;
          }, []);
    };

/**
 * Gets the handler prompt response messages from a Dialogflow Assist
 * answer.
 */
export const getHandlerPromptResponseMessagesFromDialogflowAssistAnswer =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_|null) => {
      const queryResultV3 =
          getQueryResultV3FromDialogflowAssistAnswer(dialogflowAssistAnswer);

      return getTextAndCustomResponseMessages(
                 queryResultV3?.responseMessages?.filter(
                     responseMessage =>
                         responseMessage.responseType === 'HANDLER_PROMPT'))
          ?.reduce((handlerPrompts: ResponseMessage[], handlerPrompt) => {
            if (handlerPrompt.payload) {
              handlerPrompts.push(handlerPrompt);
            }

            if (handlerPrompt.text?.text?.length) {
              handlerPrompts.push(...handlerPrompt.text?.text?.map(
                  (text): ResponseMessage => ({'text': {'text': [text]}})));
            }

            return handlerPrompts;
          }, []);
    };

/** Gets the form parameters from a Dialogflow Assist answer. */
export const getFormParametersFromDialogflowAssistAnswer =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_) =>
        getQueryResultV3FromDialogflowAssistAnswer(dialogflowAssistAnswer)
            ?.currentPage?.form?.parameters;

/** Gets the current page's display name from a Dialogflow Assist answer. */
export const getCurrentPageDisplayNameFromDialogflowAssistAnswer =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_) =>
        getQueryResultV3FromDialogflowAssistAnswer(dialogflowAssistAnswer)
            ?.currentPage?.displayName;

/**
 * Gets the parameter prompt response messages from a Dialogflow Assist form
 * parameter.
 */
export const getParameterPromptResponseMessagesFromFormParameter =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_|undefined,
     formParameterName: string|undefined) => {
      const queryResultV3 =
          getQueryResultV3FromDialogflowAssistAnswer(dialogflowAssistAnswer);

      return getTextAndCustomResponseMessages(
          queryResultV3?.responseMessages?.filter(
              responseMessage =>
                  responseMessage.responseType === 'PARAMETER_PROMPT' &&
                  responseMessage.parameter === formParameterName));
    };

/** Gets the parameter suggestions for a provided parameter name. */
export const getParameterSuggestionsForParameterName =
    (parameterSuggestions: {
      readonly [parameterName: string]: Array<
          GoogleCloudDialogflowV2beta1ParameterSuggestionsCandidate|undefined>;
    },
     parameterName: string) => parameterSuggestions[parameterName] ?
    [...new Set(parameterSuggestions[parameterName].map(suggestion => {
      return convertToPrimitiveParameter(suggestion?.value);
    }))] :
    [];

/**
 * Determines if an intent suggestion for a given Dialogflow Assist answer has
 * already been used.
 */
export const hasIntentSuggestionBeenUsed =
    (intentSuggestion: IntentSuggestion_, usedIntentSuggestions?: string[]) =>
        !!(usedIntentSuggestions &&
           ~usedIntentSuggestions.indexOf(intentSuggestion?.intentV3 || ''));

/**
 * Determines if a set of response messages for a given Dialogflow Assist answer
 * have already been used.
 */
export const haveResponseMessagesBeenUsed =
    (responseMessages?: ResponseMessage[],
     usedResponseMessages?: ResponseMessage[]) =>
        !!usedResponseMessages?.some(
            usedResponseMessage => responseMessages?.some(
                responseMessage => usedResponseMessage === responseMessage));

/**
 * Determines if a response message for a given Dialogflow Assist answer has
 * already been used.
 */
export const hasResponseMessageBeenUsed =
    (responseMessage: ResponseMessage,
     usedResponseMessages?: ResponseMessage[]) =>
        !!usedResponseMessages?.some(
            usedResponseMessage => usedResponseMessage.text?.text?.[0] ===
                responseMessage.text?.text?.[0]);

/**
 * Determines if a Dialogflow Assist answer contains the last page in the flow.
 */
export const isDialogflowAssistAnswerEndPage =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_) =>
        getQueryResultV3FromDialogflowAssistAnswer(dialogflowAssistAnswer)
            ?.currentPage?.name?.endsWith('/pages/END_SESSION') ||
    false;

/**
 * Determines if a Dialogflow Assist answer has come earlier in the workflow
 * than the context answer.
 */
export const isDialogflowAssistAnswerOlderThanContext =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_,
     dialogflowAssistAnswerContext: DialogflowAssistAnswer_,
     dialogflowAssistWorkflow: Array<DialogflowAssistAnswer_|undefined>) => {
      const dialogflowAssistAnswerWorkflowIndex =
          dialogflowAssistWorkflow.indexOf(dialogflowAssistAnswer);
      const dialogflowAssistAnswerContextIndex =
          dialogflowAssistWorkflow.indexOf(dialogflowAssistAnswerContext);

      return dialogflowAssistAnswerWorkflowIndex <
          dialogflowAssistAnswerContextIndex;
    };

/**
 * Filter response messages for those that contain text or a custom payload.
 */
export const getTextAndCustomResponseMessages =
    (responseMessages?: ResponseMessage[]|null) => responseMessages?.filter(
        responseMessage =>
            responseMessage.payload || responseMessage.text?.text);
