import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Optional, Output} from '@angular/core';
import {MessageBar} from 'google3/java/com/google/dialogflow/console/web/common/message_bar/message_bar';
import {MessageBarItem} from './message_bar_item';

/**
 * Message Bar button component. Should be used for message bar actions that
 * require programmatic handling, i.e. dispatching a NgRx action.
 *
 * NOTE - Should only be used as a content child of a <message-bar> component,
 * otherwise an error will be thrown.
 */
@Component({
  selector: 'message-bar-button',
  templateUrl: './message_bar_button.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBarButton extends MessageBarItem {
  @HostBinding('style.pointer-events')
  get pointerEvents() {
    return this.disabled ? 'none' : 'auto';
  }

  /** Whether to disable the message bar button. */
  @Input() disabled: boolean = false;

  @Output() override click = new EventEmitter<Event>();

  constructor(@Optional() messageBar: MessageBar|null) {
    super(messageBar);
  }
}
