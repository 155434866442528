import {UI_MODULE_WINDOW} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/window';
import {ErrorSource, UiModuleError} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/api_error';
import {UiModuleEvent, UiModuleEventPayload} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {fromEvent} from 'rxjs';
import {filter, map} from 'rxjs/operators';

/** Creates an observable from a UiModule event emission. */
export const fromUiModuleEvent = <T extends UiModuleEvent>(eventName: T) =>
    fromEvent<CustomEvent<UiModuleEventPayload[T]>>(
        UI_MODULE_WINDOW._uiModuleEventTarget, eventName)
        .pipe(map(event => event.detail));

/** Creates an observable from a UiModule error event emission. */
export const fromUiModuleErrorEvent = (source: ErrorSource) =>
    fromUiModuleEvent(UiModuleEvent.DIALOGFLOW_API_ERROR)
        .pipe(
            filter((error): error is UiModuleError => error?.source === source),
            map(error => error));


/** Dispatches a new UiModule event in a type-safe manner. */
export const dispatchUiModuleEvent = <T extends UiModuleEvent>(
    eventName: T, {detail}: {detail?: UiModuleEventPayload[T]} = {}) => {
  if (UI_MODULE_WINDOW._uiModuleFlags?.debug) {
    console.table({eventName});
    console.log(detail);
  }

  UI_MODULE_WINDOW._uiModuleEventTarget.dispatchEvent(
      new CustomEvent<UiModuleEventPayload[T]>(
          eventName, detail ? {detail} : undefined));
};

const addUiModuleEventListener =
    <T extends UiModuleEvent>(event: T, cb: (payload: Event) => void) => {
      UI_MODULE_WINDOW._uiModuleEventTarget.addEventListener(event, cb);
    };

/**
 * Initializes UI modules event dispatching functionality. Creates a target
 * element to dispatch events to and from, and defines helper functions to help
 * interact with those events.
 */
export function initUiModuleEvents() {
  UI_MODULE_WINDOW._uiModuleEventTarget ||= document.createElement('div');
  UI_MODULE_WINDOW.dispatchAgentAssistEvent ||= dispatchUiModuleEvent;
  UI_MODULE_WINDOW.addAgentAssistEventListener ||= addUiModuleEventListener;
}
