<ng-container *ngIf="answer">
  <div *ngIf="!hasNegativeFeedback" class="article-search-answer__wrapper">
    <a
      *ngIf="answer.uri; else noURITitle"
      (click)="handleSelectSearchArticle($event)"
      [href]="articleLinkConfig.target === 'popup' ? undefined : getArticleAnswerLink(answer, articleLinkConfig?.linkMetadataKey)"
      [target]="articleLinkConfig?.target"
      rel="noopener noreferrer"
      class="article-search-answer__suggestion-title-link gmat-subhead-2"
    >
      {{handleNormalizeArticleContent(answer.title)}}
    </a>
    <ng-template #noURITitle>
      <p class="article-search-answer__suggestion-title gmat-subhead-2">
        {{answer.title}}
      </p>
    </ng-template>

    <p class="article-search-answer__suggestion-body gmat-caption">
      <span *ngIf="snippets[0]?.content as content">{{content}}</span>
    </p>

    <div class="article-search-answer__suggestion-feedback">
      <button
        mat-icon-button
        class="article-search-answer__suggestion-feedback-button"
        aria-label="Helpful suggestion"
        i18n-aria-label="ARIA label on a button that marks a suggestion as helpful"
        matTooltip="Helpful suggestion"
        i18n-matTooltip="Tooltip for a button that marks a suggestion as helpful"
        data-test-id="positive-feedback"
        (click)="hasPositiveFeedback ? handleProvideNeutralFeedback() : handleProvidePositiveFeedback()"
      >
        <mat-icon
          [svgIcon]="hasPositiveFeedback ? 'thumb_up_filled' : 'thumb_up'"
        ></mat-icon>
      </button>
      <button
        mat-icon-button
        class="article-search-answer__suggestion-feedback-button"
        aria-label="Not helpful suggestion"
        i18n-aria-label="ARIA label on a button that marks a suggestion as not helpful"
        matTooltip="Not helpful suggestion"
        i18n-matTooltip="Tooltip for a button that marks a suggestion as not helpful"
        data-test-id="negative-feedback"
        (click)="handleProvideNegativeFeedback()"
      >
        <mat-icon svgIcon="thumb_down"></mat-icon>
      </button>
    </div>
  </div>
</ng-container>
