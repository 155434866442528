import {ConnectorConstructor} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/connector_types';
import {Environment} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/environment';

/** UI Module flags. */
export declare interface UiModuleFlags {
  /**
   * Enables debug mode for the UI modules. Prints all UI Module events to the
   * console.
   */
  debug?: boolean;
  environment?: Environment;
}

/** Global window object with the proper keys included. */
export declare interface UiModuleWindow extends Window {
  UiModulesConnector: ConnectorConstructor;
  LivePersonConnector: ConnectorConstructor;
  GenesysConnector: undefined;
  SalesForceConnector: undefined;
  SocketIoConnector: ConnectorConstructor;
  _uiModuleFlags?: UiModuleFlags;
  _uiModuleEventTarget: HTMLElement;
  dispatchAgentAssistEvent(event: string, payload: unknown): void;
  addAgentAssistEventListener(event: string, cb: (payload: unknown) => void):
      void;
}

/** Ui Module window object. */
export const UI_MODULE_WINDOW = window as {} as UiModuleWindow;

/** Current UI Module environment. */
export const ENVIRONMENT = UI_MODULE_WINDOW._uiModuleFlags?.environment;
