import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {UI_MODULE_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/injection_tokens';
import {dispatchUiModuleEvent, fromUiModuleErrorEvent, fromUiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {UiModuleIconService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/icon/ui_module_icon_service';
import {NotificationService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_service';
import {UiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {LoadingState} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/loading_state';
import {getModelNameForSuggestionFeature} from 'google3/java/com/google/dialogflow/console/web/ccai/conversation_profiles/conversation_profiles_helpers';
import {AnalyzeContentResponse_, Conversation_, ConversationModel_, ConversationProfile_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {ConversationProfile_ as ConversationProfileResponse} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_ts_api_client';
import {strictDeserialize} from 'google3/javascript/typescript/contrib/apiclient/core/domain_object';
import {BehaviorSubject, combineLatest, ReplaySubject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {GUIDANCE_ICONS} from './guidance_constants';



/** Guidance element selector. */
export const GUIDANCE_ELEMENT_SELECTOR = 'agent-assist-conversation-guidance';

/** Conversation Guidance UI Module component. */
@Component({
  selector: GUIDANCE_ELEMENT_SELECTOR,
  templateUrl: './guidance.ng.html',
  styleUrls: ['./guidance.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: UI_MODULE_ICONS, useValue: GUIDANCE_ICONS}, UiModuleIconService
  ],
})
export class Guidance implements OnInit, OnDestroy {
  readonly conversation$ = new BehaviorSubject<Conversation_|null>(null);
  readonly conversationLoadingState$ =
      new BehaviorSubject<LoadingState>('NOT_LOADING');
  readonly conversationProfile$ =
      new BehaviorSubject<ConversationProfile_|null>(null);
  readonly conversationProfileLoadingState$ =
      new BehaviorSubject<LoadingState>('NOT_LOADING');
  readonly conversationModel$ =
      new BehaviorSubject<ConversationModel_|null>(null);
  readonly conversationModelLoadingState$ =
      new BehaviorSubject<LoadingState>('NOT_LOADING');
  readonly loadingState$ =
      combineLatest([
        this.conversationLoadingState$, this.conversationProfileLoadingState$,
        this.conversationModelLoadingState$
      ]).pipe<LoadingState>(map((loadingStates) => {
        if (loadingStates.includes('ERROR')) {
          return 'ERROR';
        } else if (loadingStates.includes('LOADING')) {
          // Something is loading and there are no errors
          return 'LOADING';
        } else if (loadingStates.includes('NOT_LOADING')) {
          // Nothing is loading, something is not_loading
          // and there are no errors
          return 'NOT_LOADING';
        }
        // Nothing is loading/not_loading and there are no errors
        return 'LOADED';
      }));
  readonly analyzeContentResponse$ =
      new BehaviorSubject<AnalyzeContentResponse_|null>(null);

  readonly conversationModelAndContentResponse$ =
      combineLatest([
        this.conversation$, this.conversationModel$,
        this.analyzeContentResponse$
      ]).pipe(map(([conversation, conversationModel,
                    analyzeContentResponse]) => ({
                    conversationName: conversation?.name,
                    conversationModel,
                    analyzeContentResponse
                  })));

  private readonly destroyed$ = new ReplaySubject();

  constructor(
      notificationService: NotificationService,
      uiModuleIconService: UiModuleIconService,
  ) {
    uiModuleIconService.initModule();
    notificationService.initModule();
  }

  ngOnInit() {
    fromUiModuleEvent(UiModuleEvent.CONVERSATION_INITIALIZATION_REQUESTED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.conversation$.next(null);
          this.conversationLoadingState$.next('LOADING');
        });
    fromUiModuleEvent(UiModuleEvent.CONVERSATION_INITIALIZED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversation}) => {
          this.conversation$.next(conversation);
          this.conversationLoadingState$.next('LOADED');
        });
    fromUiModuleErrorEvent('INITIALIZATION')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.conversationLoadingState$.next('ERROR');
        });

    fromUiModuleEvent(UiModuleEvent.CONVERSATION_PROFILE_REQUESTED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.conversationProfile$.next(null);
          this.conversationProfileLoadingState$.next('LOADING');
        });
    fromUiModuleEvent(UiModuleEvent.CONVERSATION_PROFILE_RECEIVED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((conversationProfile) => {
          this.conversationProfile$.next(conversationProfile);
          this.conversationProfileLoadingState$.next('LOADED');
          if (!conversationProfile) return;

          const modelName = getModelNameForSuggestionFeature(
              strictDeserialize(
                  ConversationProfileResponse, conversationProfile),
              'CONVERSATION_GUIDANCE');

          if (!modelName) return;

          dispatchUiModuleEvent(
              UiModuleEvent.CONVERSATION_MODEL_REQUESTED,
              {detail: {modelName}});
        });
    fromUiModuleErrorEvent('GET_CONVERSATION_PROFILE')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.conversationProfileLoadingState$.next('ERROR');
        });

    fromUiModuleEvent(UiModuleEvent.CONVERSATION_MODEL_REQUESTED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.conversationModel$.next(null);
          this.conversationModelLoadingState$.next('LOADING');
        });
    fromUiModuleEvent(UiModuleEvent.CONVERSATION_MODEL_RECEIVED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((conversationModel) => {
          this.conversationModel$.next(conversationModel);
          this.conversationModelLoadingState$.next('LOADED');
        });
    fromUiModuleErrorEvent('GET_CONVERSATION_MODEL')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.conversationModelLoadingState$.next('ERROR');
        });

    fromUiModuleEvent(UiModuleEvent.ANALYZE_CONTENT_RESPONSE_RECEIVED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({payload: {response}}) => {
          this.analyzeContentResponse$.next(response);
        });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
