import {Component, Input, OnInit} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {UI_MODULE_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/injection_tokens';
import {dispatchUiModuleEvent, fromUiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {UiModuleIconService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/icon/ui_module_icon_service';
import {NotificationService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_service';
import {CommunicationChannel} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/connector_types';
import {UiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {LoadingState} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/loading_state';
import {ChooseResponseMessagePayload} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/dialogflow_assist_types';
import {getConversationIdFromName} from 'google3/java/com/google/dialogflow/console/web/common/helpers/proto_name_id_extractors';
import {AnalyzeContentRequest_, AnalyzeContentResponse_, Conversation_, Participant_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {VIRTUAL_AGENT_ASSIST_ICONS} from './virtual_agent_assist_constants';



/** Element selector for Virtual Agent Assist web component. */
export const VIRTUAL_AGENT_ASSIST_ELEMENT_SELECTOR =
    'agent-assist-virtual-agent-assist';

interface State {
  conversation: Conversation_|null;
  humanAgentParticipant: Participant_|null;
  analyzeContentResponses: AnalyzeContentResponse_[];
  loadingState: LoadingState;
  endUserParticipantLoadStatus: LoadingState;
}

const initialState: State = {
  conversation: null,
  humanAgentParticipant: null,
  analyzeContentResponses: [],
  loadingState: 'NOT_LOADING',
  endUserParticipantLoadStatus: 'NOT_LOADING',
};

/** Virtual Agent Assist component. */
@Component({
  selector: VIRTUAL_AGENT_ASSIST_ELEMENT_SELECTOR,
  templateUrl: './virtual_agent_assist.ng.html',
  styleUrls: ['./virtual_agent_assist.scss'],
  providers: [
    {provide: UI_MODULE_ICONS, useValue: VIRTUAL_AGENT_ASSIST_ICONS},
    UiModuleIconService,
  ],
})
export class VirtualAgentAssist extends ComponentStore<State> implements
    OnInit {
  @Input() channel: CommunicationChannel = 'chat';

  readonly conversation$ = this.select(state => state.conversation);
  readonly humanAgentParticipant$ =
      this.select(state => state.humanAgentParticipant);
  readonly analyzeContentResponses$ =
      this.select(state => state.analyzeContentResponses);
  readonly loadingState$ = this.select(state => state.loadingState);
  readonly endUserParticipantLoadStatus$ =
      this.select(state => state.endUserParticipantLoadStatus);

  readonly setEndUserParticipantLoadStatus = this.updater(
      (state, endUserParticipantLoadStatus: LoadingState) =>
          ({...state, endUserParticipantLoadStatus}));

  readonly setLoadingState = this.updater(
      (state, loadingState: LoadingState) => ({...state, loadingState}));

  readonly cancelWorkflow$ = new Subject();

  activeConversationName?: string;

  get showUseSuggestionButton() {
    return this.channel === 'chat';
  }

  constructor(
      notificationService: NotificationService,
      uiModuleIconService: UiModuleIconService,
  ) {
    super(initialState);
    uiModuleIconService.initModule();
    notificationService.initModule();
  }

  /**
   * Dispatches event to populate the agent's input box with the chosen
   * response message.
   */
  handleChooseResponseMessage({responseMessage}: ChooseResponseMessagePayload) {
    dispatchUiModuleEvent(
        UiModuleEvent.VIRTUAL_AGENT_ASSIST_RESPONSE_MESSAGE_SELECTED,
        {detail: {responseMessage: responseMessage.text?.text?.[0]!}});
  }

  handleAnalyzeContent(request: AnalyzeContentRequest_) {
    if (!this.activeConversationName) return;

    this.setLoadingState('LOADING');

    dispatchUiModuleEvent(UiModuleEvent.ANALYZE_CONTENT_REQUESTED, {
      detail: {
        conversationId: getConversationIdFromName(this.activeConversationName),
        participantRole: 'HUMAN_AGENT',
        type: 'DIALOGFLOW_ASSIST',
        request
      }
    });
  }

  ngOnInit() {
    fromUiModuleEvent(UiModuleEvent.ACTIVE_CONVERSATION_SELECTED)
        .pipe(takeUntil(this.destroy$))
        .subscribe(({conversationName}) => {
          this.activeConversationName = conversationName;
        });

    fromUiModuleEvent(UiModuleEvent.ANALYZE_CONTENT_REQUESTED)
        .pipe(takeUntil(this.destroy$))
        .subscribe(({participantRole}) => {
          if (participantRole === 'END_USER') {
            this.setEndUserParticipantLoadStatus('LOADING');
          }
        });

    fromUiModuleEvent(UiModuleEvent.ANALYZE_CONTENT_RESPONSE_RECEIVED)
        .pipe(takeUntil(this.destroy$))
        .subscribe(({payload: {response, type, request, participant}}) => {
          if (type === 'DIALOGFLOW_ASSIST') {
            this.setLoadingState('LOADED');

            // Active workflow has been cancelled.
            if (request?.suggestionInput?.cancel) {
              this.cancelWorkflow$.next();
            }
          }

          if (participant?.role === 'END_USER') {
            this.setEndUserParticipantLoadStatus('LOADED');
          }

          this.setState(state => ({
                          ...state,
                          analyzeContentResponses:
                              [...state.analyzeContentResponses, response]
                        }));
        });

    fromUiModuleEvent(UiModuleEvent.CONVERSATION_INITIALIZED)
        .pipe(takeUntil(this.destroy$))
        .subscribe(({conversation, participants}) => {
          this.setState(state => ({
                          ...state,
                          conversation,
                          loadingState: 'LOADED',
                          humanAgentParticipant: participants['HUMAN_AGENT']!,
                        }));
        });

    // Checks if an AnalyzeContent request for the end user participant has
    // thrown an error.
    fromUiModuleEvent(UiModuleEvent.DIALOGFLOW_API_ERROR)
        .pipe(takeUntil(this.destroy$))
        .subscribe(error => {
          if (error?.source === 'ANALYZE_CONTENT' &&
              (error?.data?.['participant'] as Participant_ | undefined)
                      ?.role === 'END_USER') {
            this.setEndUserParticipantLoadStatus('ERROR');
          }
        });
  }
}
