import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ArticleSearchAnswerModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/article_search/article_search_answer/article_search_answer_module';
import {LetDirectiveModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/let/let_directive_module';
import {SystemMessagingModule} from 'google3/java/com/google/dialogflow/console/web/common/system_messaging_service/system_messaging_module';

import {ArticleSearch} from './article_search';



@NgModule({
  imports: [
    ArticleSearchAnswerModule,
    CommonModule,
    LetDirectiveModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ReactiveFormsModule,
    SystemMessagingModule,
  ],
  declarations: [
    ArticleSearch,
  ],
  exports: [
    ArticleSearch,
  ]
})
export class ArticleSearchModule {
}
