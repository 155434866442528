import {OverlayContainer, OverlayRef} from '@angular/cdk/overlay';
import {Injectable} from '@angular/core';

/** Data type for keeping track of overlays and their hidden siblings */
class OverlayContext {
  /**
   * A record of the initial aria-hidden state of each overlay host's siblings
   * at the time the overlay was attached.
   */
  readonly ariaHiddenSiblings = new Map<Element, boolean>();

  constructor(public overlayRef: OverlayRef) {}
}

/**
 * Singleton class that makes a modal overlay accessible by hiding sibling
 * elements via aria-hidden.
 */
@Injectable({providedIn: 'root'})
export class AccessibleModalOverlayManager {
  /**
   * If you add this class to an HTMLElement, the service will not apply
   * aria-hidden=true
   */
  static readonly ACCESSIBLE_MODAL_OVERLAY_DO_NOT_HIDE =
      'accessible-modal-overlay-do-not-hide';

  /**
   * A record of the initial aria-hidden state of each overlay host's siblings
   * at the time the overlay is attached. These initial states will be restored
   * in First-In-Last-Out order as matching overlays are detached.
   */
  private readonly overlayContextStack: OverlayContext[] = [];

  constructor(private readonly overlayContainer: OverlayContainer) {}

  /**
   * Pushes an overlay to the top of the overlay stack and hides its siblings
   * from assistive technology.
   */
  pushOverlay(overlayRef: OverlayRef) {
    const overlayContext = new OverlayContext(overlayRef);
    this.applyAriaHiddenToContext(overlayContext);
    this.overlayContextStack.push(overlayContext);
  }

  /**
   * Pops an overlay off the top of the overlay stack, and pops off the next
   * overlays if they were removed out of order.
   */
  popOverlay(overlayRef: OverlayRef) {
    // Do not pop overlays out of order.
    let nextContext =
        this.overlayContextStack[this.overlayContextStack.length - 1];
    if (!nextContext || overlayRef !== nextContext.overlayRef) {
      return;
    }

    do {
      this.restoreAriaHiddenToContext(this.overlayContextStack.pop()!);

      // Pop any overlays from the top of the stack that have been removed
      nextContext =
          this.overlayContextStack[this.overlayContextStack.length - 1];
    } while (nextContext && !nextContext.overlayRef.hasAttached());
  }

  /**
   * When the page is put into fullscreen mode, a specific element is specified.
   * Only that element and its children are visible when in fullscreen mode.
   */
  private getFullscreenElement(): Element {
    // tslint:disable-next-line:no-any Access all possible fullscreenElement.
    const docEl = document as any;

    return docEl.fullscreenElement || docEl.webkitFullscreenElement ||
        docEl.mozFullScreenElement || docEl.msFullscreenElement || null;
  }

  /**
   * Hides any element other than the overlay itself from assistive technology
   * by applying the aria-hidden tag to it. This specifically hides the siblings
   * of the overlay container and overlay host.
   */
  private applyAriaHiddenToContext(overlayContext: OverlayContext): void {
    const overlayHost = overlayContext.overlayRef.hostElement;
    const overlayContainer = this.overlayContainer.getContainerElement();

    // Concat the overlay siblings and overlay container siblings.
    const siblings: Element[] = [
      ...Array.from(overlayContainer.parentElement?.children || []),
      ...Array.from(overlayHost.parentElement?.children || []),
    ];

    const fullScreenEl = this.getFullscreenElement();
    if (fullScreenEl) {
      siblings.push(...Array.from(fullScreenEl.children));
    }

    siblings.forEach((sibling) => {
      // Skip non-displayable nodes and the overlay's root node
      if (sibling === overlayContainer || sibling.nodeName === 'SCRIPT' ||
          sibling.classList.contains(
              AccessibleModalOverlayManager
                  .ACCESSIBLE_MODAL_OVERLAY_DO_NOT_HIDE) ||
          sibling.nodeName === 'STYLE' || sibling.hasAttribute('aria-live')) {
        return;
      }

      const isAriaHidden = sibling.getAttribute('aria-hidden');
      overlayContext.ariaHiddenSiblings.set(
          sibling, isAriaHidden === 'true' ? true : false);

      if (sibling === overlayHost) {
        sibling.removeAttribute('aria-hidden');
      } else {
        sibling.setAttribute('aria-hidden', 'true');
      }
    });
  }

  /**
   * Restores the previous aria-hidden state of the overlay's siblings,
   * allowing them to become visible to assistive technology again.
   */
  private restoreAriaHiddenToContext(overlayContext: OverlayContext): void {
    overlayContext.ariaHiddenSiblings.forEach((isAriaHidden, sibling) => {
      if (isAriaHidden) {
        sibling.setAttribute('aria-hidden', 'true');
      } else {
        sibling.removeAttribute('aria-hidden');
      }
    });
  }
}
