import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {NonNullableFormBuilder} from '@angular/forms';
import {Icon} from 'google3/java/com/google/dialogflow/console/web/ccai/common/constants';
import {getIntentDescription, getIntentDisplayName} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/dialogflow_assist_shared_helpers';
import {DialogflowAssistHeadIntentAndFormParameters} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/dialogflow_assist_types';
import {revealExpand} from 'google3/java/com/google/dialogflow/console/web/common/animations/reveal_expand';
import {CustomValidators} from 'google3/java/com/google/dialogflow/console/web/common/reactive_forms/custom_validators';
import {Dictionary} from 'google3/java/com/google/dialogflow/console/web/common/types/common_types';
import {JsonValidationError} from 'google3/java/com/google/dialogflow/console/web/common/types/validation_error_types';

/**
 * Head intent for use with Dialogflow Assist section of Agent Assist simulator.
 */
@Component({
  selector: 'head-intent',
  templateUrl: './head_intent.ng.html',
  styleUrls: ['./head_intent.css'],
  animations: [revealExpand],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadIntent {
  @Input() debugMode!: boolean;
  @Input() disabled!: boolean;
  @Input()
  dialogflowAssistHeadIntent?: DialogflowAssistHeadIntentAndFormParameters|
      undefined;
  @Input() index!: number;

  @Output()
  readonly onChoose =
      new EventEmitter<DialogflowAssistHeadIntentAndFormParameters>();

  readonly overriddenSessionParameters =
      this.fb.control('', CustomValidators.jsonObject({flat: true}));

  readonly getIntentDisplayName = getIntentDisplayName;
  readonly getIntentDescription = getIntentDescription;

  optionsExpanded = false;

  get overriddenSessionParametersErrorMessage() {
    const errors =
        this.overriddenSessionParameters.errors as JsonValidationError | null;

    return errors?.invalidJson;
  }

  get optionsToggleIcon(): Icon {
    return this.optionsExpanded ? 'expand_less' : 'expand_more';
  }

  get modifiedHeadIntent(): DialogflowAssistHeadIntentAndFormParameters {
    return {
      ...this.dialogflowAssistHeadIntent!,
      parameters: this.overriddenSessionParameters.value ?
          JSON.parse(this.overriddenSessionParameters.value) as Dictionary :
          undefined,
    };
  }

  get headIntentDisabled() {
    const overriddenSessionParametersValid = this.debugMode ?
        (this.overriddenSessionParameters.touched ?
             this.overriddenSessionParameters.valid :
             true) :
        true;
    return this.disabled || !overriddenSessionParametersValid;
  }

  constructor(
      private readonly fb: NonNullableFormBuilder,
  ) {}

  handleChooseHeadIntent() {
    this.overriddenSessionParameters.updateValueAndValidity();
    if (this.overriddenSessionParameters.valid) {
      this.onChoose.emit(this.modifiedHeadIntent);
    }
  }

  handleExpandOptions() {
    this.optionsExpanded = !this.optionsExpanded;
  }
}
