import {AnalyzeContentRequestDetails, AnalyzeContentResponseDetails} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/analyze_content';
import {PatchAnswerRecordPayload} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/answer_record_types';
import {UiModuleError} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/api_error';
import {ConversationDetails} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/conversation_details';
import {PatchPayload} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/http_options';
import {SnackbarNotificationPayload} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/snackbar_notification_types';
import {SmartReplySelection} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/smart_reply/smart_reply_types';
import {PayloadWithConversationName} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/suggestion_feature_types';
import {AnswerRecord_, ConversationModel_, ConversationProfile_, ListMessagesResponse_, Message_, SearchArticlesResponse_, SmartReplyAnswer_, SuggestConversationSummaryResponse_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';

import {ConversationInitializedPayload} from './conversation_initialized_payload';



// LINT.IfChange
/**
 * Collection of custom events that can be dispatched by the UI modules and
 * their associated services.
 */
export enum UiModuleEvent {
  /** Dispatched when a conversation is selected. */
  ACTIVE_CONVERSATION_SELECTED = 'active-conversation-selected',

  /** Dispatched when a Smart Reply chip is selected. */
  SMART_REPLY_SELECTED = 'smart-reply-selected',

  /** Dispatched when Smart Reply follow-up suggestions have been received. */
  SMART_REPLY_FOLLOW_UP_SUGGESTIONS_RECEIVED =
      'smart-reply-follow-up-suggestions-received',

  /**
   * Dispatched when conversation details are received from the SDK (including
   * agent and customer info).
   */
  CONVERSATION_DETAILS_RECEIVED = 'conversation-details-received',

  /** Dispatched when the Dialogflow conversation should be initialized. */
  CONVERSATION_INITIALIZATION_REQUESTED =
      'conversation-initialization-requested',

  /** Dispatched when the Dialogflow conversation has been initialized. */
  CONVERSATION_INITIALIZED = 'conversation-initialized',

  /** Dispatched when the Dialogflow conversation has started. */
  CONVERSATION_STARTED = 'conversation-started',

  /** Dispatched when the Dialogflow conversation has completed. */
  CONVERSATION_COMPLETED = 'conversation-completed',

  /** Dispatched when the Dialogflow conversation profile is being fetched */
  CONVERSATION_PROFILE_REQUESTED = 'conversation-profile-requested',

  /** Dispatched when the Dialogflow conversation profile has been received */
  CONVERSATION_PROFILE_RECEIVED = 'conversation-profile-received',

  /** Dispatched when the Dialogflow conversation model is being fetched */
  CONVERSATION_MODEL_REQUESTED = 'conversation-model-requested',

  /** Dispatched when the Dialogflow conversation model has been received */
  CONVERSATION_MODEL_RECEIVED = 'conversation-model-received',

  /** Dispatched when a new transcript message has been received. */
  NEW_MESSAGE_RECEIVED = 'new-message-received',

  /** Dispatched when an AnalyzeContent request should be made. */
  ANALYZE_CONTENT_REQUESTED = 'analyze-content-requested',

  /** Dispatched when a new AnalyzeContentResponse has been received. */
  ANALYZE_CONTENT_RESPONSE_RECEIVED = 'analyze-content-response-received',

  /** Conversation summarization requested. */
  CONVERSATION_SUMMARIZATION_REQUESTED = 'conversation-summarization-requested',

  /** Conversation summarization received. */
  CONVERSATION_SUMMARIZATION_RECEIVED = 'conversation-summarization-received',

  /** Dialogflow API error encountered. */
  DIALOGFLOW_API_ERROR = 'dialogflow-api-error',

  /** Dialogflow API authentication (401) error encountered. */
  DIALOGFLOW_API_AUTHENTICATION_ERROR = 'dialogflow-api-authentication-error',

  /** Answer record requested. */
  ANSWER_RECORD_REQUESTED = 'answer-record-requested',

  /** Answer record received. */
  ANSWER_RECORD_RECEIVED = 'answer-record-received',

  /** Patch answer record requested. */
  PATCH_ANSWER_RECORD_REQUESTED = 'patch-answer-record-requested',

  /** Patch answer record request received. */
  PATCH_ANSWER_RECORD_RECEIVED = 'patch-answer-record-received',

  /** Article search requested. */
  ARTICLE_SEARCH_REQUESTED = 'article-search-requested',

  /** Article search results received. */
  ARTICLE_SEARCH_RESPONSE_RECEIVED = 'article-search-response-received',

  /** Dark mode toggled in Agent desktop. */
  DARK_MODE_TOGGLED = 'dark-mode-toggled',

  /** Snackbar notification requested. */
  SNACKBAR_NOTIFICATION_REQUESTED = 'snackbar-notification-requested',

  /** LivePerson connector initialized. */
  LIVE_PERSON_CONNECTOR_INITIALIZED = 'live-person-connector-initialized',

  /** API connector initialized. */
  API_CONNECTOR_INITIALIZED = 'api-connector-initialized',

  /** Event-based connector initialized. */
  EVENT_BASED_CONNECTOR_INITIALIZED = 'event-based-connector-initialized',

  /** LivePerson connector initialization failed. */
  LIVE_PERSON_CONNECTOR_INITIALIZATION_FAILED =
      'live-person-connector-initialization-failed',

  /** API connector initialization failed. */
  API_CONNECTOR_INITIALIZATION_FAILED = 'api-connector-initialization-failed',

  /** Event-based connector initialization failed. */
  EVENT_BASED_CONNECTOR_INITIALIZATION_FAILED =
      'event-based-connector-initialization-failed',

  /** Dispatched when Websocket connection is established. */
  EVENT_BASED_CONNECTION_ESTABLISHED = 'event-based-connection-established',

  /** Request to list messages for a given conversation. */
  LIST_MESSAGES_REQUESTED = 'list-messages-requested',

  /** Dispatched when a list messages response is received. */
  LIST_MESSAGES_RESPONSE_RECEIVED = 'list-messages-response-received',

  /**
   * Dispatched when a Virtual Agent Assist response message is selected. This
   * will be used to populate the agent input box with the selected message.
   */
  VIRTUAL_AGENT_ASSIST_RESPONSE_MESSAGE_SELECTED =
      'virtual-agent-assist-response-message-selected',
}
// LINT.ThenChange(:ui_module_payload_events)

// LINT.IfChange(ui_module_payload_events)
/** Payload types for UiModule events.  */
export interface UiModuleEventPayload {
  [UiModuleEvent.ACTIVE_CONVERSATION_SELECTED]:
      ActiveConversationSelectedPayload;

  [UiModuleEvent.ANALYZE_CONTENT_REQUESTED]: AnalyzeContentRequestDetails;

  [UiModuleEvent.ANALYZE_CONTENT_RESPONSE_RECEIVED]:
      PayloadWithConversationName<AnalyzeContentResponseDetails>;

  [UiModuleEvent.ARTICLE_SEARCH_REQUESTED]: ArticleSearchRequestedPayload;

  [UiModuleEvent.ARTICLE_SEARCH_RESPONSE_RECEIVED]:
      PayloadWithConversationName<SearchArticlesResponse_>;

  [UiModuleEvent.CONVERSATION_DETAILS_RECEIVED]:
      PayloadWithConversationName<ConversationDetails>;

  [UiModuleEvent.CONVERSATION_INITIALIZATION_REQUESTED]:
      ConversationInitializationRequestedPayload;

  [UiModuleEvent.CONVERSATION_INITIALIZED]: ConversationInitializedPayload;

  [UiModuleEvent.CONVERSATION_PROFILE_REQUESTED]:
      ConversationProfileRequestedPayload;

  [UiModuleEvent.CONVERSATION_PROFILE_RECEIVED]: ConversationProfile_|null;

  [UiModuleEvent.CONVERSATION_MODEL_REQUESTED]:
      ConversationModelRequestedPayload;

  [UiModuleEvent.CONVERSATION_MODEL_RECEIVED]: ConversationModel_|null;

  [UiModuleEvent.CONVERSATION_SUMMARIZATION_RECEIVED]:
      PayloadWithConversationName<SuggestConversationSummaryResponse_>;

  [UiModuleEvent.CONVERSATION_SUMMARIZATION_REQUESTED]: void;

  [UiModuleEvent.DARK_MODE_TOGGLED]: DarkModeToggledPayload;

  [UiModuleEvent.DIALOGFLOW_API_ERROR]: UiModuleError|null;

  [UiModuleEvent.DIALOGFLOW_API_AUTHENTICATION_ERROR]: void;

  [UiModuleEvent.SMART_REPLY_SELECTED]: SmartReplySelection;

  [UiModuleEvent.SMART_REPLY_FOLLOW_UP_SUGGESTIONS_RECEIVED]:
      PayloadWithConversationName<SmartReplyAnswer_[]>;

  [UiModuleEvent.PATCH_ANSWER_RECORD_REQUESTED]:
      PatchPayload<PatchAnswerRecordPayload>;

  [UiModuleEvent.PATCH_ANSWER_RECORD_RECEIVED]:
      PayloadWithConversationName<AnswerRecord_>;

  [UiModuleEvent.SNACKBAR_NOTIFICATION_REQUESTED]: SnackbarNotificationPayload;

  [UiModuleEvent.ANSWER_RECORD_REQUESTED]: AnswerRecordRequestedPayload;

  [UiModuleEvent.ANSWER_RECORD_RECEIVED]:
      PayloadWithConversationName<AnswerRecord_>;

  [UiModuleEvent.LIVE_PERSON_CONNECTOR_INITIALIZED]: void;

  [UiModuleEvent.API_CONNECTOR_INITIALIZED]: void;

  [UiModuleEvent.EVENT_BASED_CONNECTOR_INITIALIZED]: void;

  [UiModuleEvent.LIVE_PERSON_CONNECTOR_INITIALIZATION_FAILED]: void;

  [UiModuleEvent.API_CONNECTOR_INITIALIZATION_FAILED]: void;

  [UiModuleEvent.EVENT_BASED_CONNECTOR_INITIALIZATION_FAILED]: void;

  [UiModuleEvent.CONVERSATION_STARTED]: ConversationStartedPayload;

  [UiModuleEvent.CONVERSATION_COMPLETED]: ConversationCompletedPayload;

  [UiModuleEvent.NEW_MESSAGE_RECEIVED]: Message_;

  [UiModuleEvent.EVENT_BASED_CONNECTION_ESTABLISHED]: void;

  [UiModuleEvent.LIST_MESSAGES_REQUESTED]: ListMessagesRequestedPayload;

  [UiModuleEvent.LIST_MESSAGES_RESPONSE_RECEIVED]:
      PayloadWithConversationName<ListMessagesResponse_>;

  [UiModuleEvent.VIRTUAL_AGENT_ASSIST_RESPONSE_MESSAGE_SELECTED]:
      VirtualAgentAssistResponseMessageSelectedPayload;
}
// LINT.ThenChange()


/** Active conversation selected payload. */
export declare interface ActiveConversationSelectedPayload {
  conversationName: string;
}

/** Article search requested payload. */
export declare interface ArticleSearchRequestedPayload {
  queryText: string;
}

/** Conversation initialization requested payload. */
export declare interface ConversationInitializationRequestedPayload {
  conversationName: string;
}

/** Conversation started payload. */
export declare interface ConversationStartedPayload {
  conversationName: string;
}

/** Conversation completed payload. */
export declare interface ConversationCompletedPayload {
  conversationName: string;
}

/** Conversation model requested payload. */
export declare interface ConversationModelRequestedPayload {
  modelName: string|null;
}

/** Conversation profile requested payload. */
export declare interface ConversationProfileRequestedPayload {
  conversationProfileName: string;
}

/** Dark mode toggled payload. */
export declare interface DarkModeToggledPayload {
  on: boolean;
}

/** Answer record requested payload. */
export declare interface AnswerRecordRequestedPayload {
  answerRecordName: string;
}

/** List messages requested payload. */
export declare interface ListMessagesRequestedPayload {
  conversationName: string;
}

/** Virtual agent assist response message selected payload. */
export declare interface VirtualAgentAssistResponseMessageSelectedPayload {
  responseMessage: string;
}
