import {Environment} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/environment';
import {TrustedResourceUrl, trustedResourceUrl} from 'safevalues';

/** LivePerson SDK source. */
export const LIVE_PERSON_SDK =
    trustedResourceUrl`https://lpcdn.lpsnmedia.net/webagent/client-SDK.min.js`;

/** LivePerson connector sources. */
const LIVE_PERSON_CONNECTOR_SOURCES: {
  [key in Environment]: TrustedResourceUrl
} = {
  'local': trustedResourceUrl`/live_person_connector.js`,
  'staging':
      trustedResourceUrl`https://www.gstatic.com/agent-assist-ui-modules/staging/live_person_connector.js`,
  'prod':
      trustedResourceUrl`https://www.gstatic.com/agent-assist-ui-modules/live_person_connector.js`
};

/**
 * Gets the right LivePerson connector source based on the current environment.
 */
export function getLivePersonConnectorSource(
    environment: Environment|undefined = 'prod') {
  return LIVE_PERSON_CONNECTOR_SOURCES[environment];
}

/** Socket IO connector sources. */
const SOCKET_IO_CONNECTOR_SOURCES: {[key in Environment]:
                                        TrustedResourceUrl} = {
  'local': trustedResourceUrl`/socket_io_connector.js`,
  'staging':
      trustedResourceUrl`https://www.gstatic.com/agent-assist-ui-modules/staging/socket_io_connector.js`,
  'prod':
      trustedResourceUrl`https://www.gstatic.com/agent-assist-ui-modules/socket_io_connector.js`
};

/**
 * Gets the right Socket IO connector source based on the current environment.
 */
export function getSocketIoConnectorSource(
    environment: Environment|undefined = 'prod') {
  return SOCKET_IO_CONNECTOR_SOURCES[environment];
}
