import {COMMON_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/icons';
import {dedupeIconList} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/icon_helpers';

/**
 * Material icons used by the Summarization UI module.
 *
 * Icons names and versions can be found at
 * https://icons.corp.google.com/?icon.set=Google%20Material
 */
export const SUMMARIZATION_ICONS = dedupeIconList([
  ...COMMON_ICONS,
  {name: 'content_copy', version: 16},
  {name: 'done', version: 17},
  {name: 'edit', version: 11},
  {name: 'restart_alt', version: 9},
  {name: 'save', version: 11},
]);
