import {ShortcutAction} from './shortcut_action';


const COBWEB_CONVERSATION_UP: ShortcutAction = {
  key: 'COBWEB_CONVERSATION_UP',
  description: 'Selects previous turn in a conversation',
  component: 'COBWEB_CONVERSATION',
};

const COBWEB_CONVERSATION_DOWN: ShortcutAction = {
  key: 'COBWEB_CONVERSATION_DOWN',
  description: 'Selects next turn in a conversation',
  component: 'COBWEB_CONVERSATION',
};

const COBWEB_CONVERSATION_NEXT: ShortcutAction = {
  key: 'COBWEB_CONVERSATION_NEXT',
  description: 'Navigates to a next conversation',
  component: 'COBWEB_CONVERSATION',
};

const COBWEB_CONVERSATION_PREVIOUS: ShortcutAction = {
  key: 'COBWEB_CONVERSATION_PREVIOUS',
  description: 'Navigates to a previous conversation',
  component: 'COBWEB_CONVERSATION',
};

const COBWEB_CONVERSATION_SET_LABEL: ShortcutAction = {
  key: 'COBWEB_CONVERSATION_SET_LABEL',
  description: 'Sets focus on label dropdown and opens it',
  component: 'COBWEB_CONVERSATION',
};

const SNACK_BAR_FOCUS: ShortcutAction = {
  key: 'SNACK_BAR_FOCUS',
  description: 'Moves focus to and from active snack bar',
  component: 'SIMPLE_SNACK_BAR'
};

// Mac platform specific SNACK_BAR_FOCUS short cut
const SNACK_BAR_FOCUS_MAC: ShortcutAction = {
  ...SNACK_BAR_FOCUS,
  key: 'SNACK_BAR_FOCUS_MAC'
};

const TEST_CASE_LIST_CONTROLS: ShortcutAction = {
  key: 'TEST_CASE_LIST_CONTROLS',
  description: 'Moves focus to test case list selection controls',
  component: 'TEST_CASE_LIST',
};

const VISUAL_BUILDER_EDGES: ShortcutAction = {
  key: 'VISUAL_BUILDER_EDGES',
  description: 'View edge information for current node',
  component: 'GRAPH_NODE'
};

/**
 * Available shortcut actions.
 */
export const SHORTCUT_ACTIONS = {
  COBWEB_CONVERSATION_UP,
  COBWEB_CONVERSATION_DOWN,
  COBWEB_CONVERSATION_NEXT,
  COBWEB_CONVERSATION_PREVIOUS,
  COBWEB_CONVERSATION_SET_LABEL,
  SNACK_BAR_FOCUS,
  SNACK_BAR_FOCUS_MAC,
  TEST_CASE_LIST_CONTROLS,
  VISUAL_BUILDER_EDGES,
};
