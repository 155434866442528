// taze: ResizeObserver from //third_party/javascript/typings/resize_observer_browser
import {ChangeDetectorRef, Component, ContentChildren, Directive, ElementRef, NgZone, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {coalesceUpdatesWithinMicrotask} from 'google3/javascript/angular2/components/table_state_manager/pipable_operators';
import {fromEvent, fromEventPattern, Observable, of, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';

/** The position of the dialog's content scroll offset. */
enum ContentScrollPosition {
  /** Scrollbar is at the top of the content container. */
  TOP = 'top',
  /** Scrollbar is at the bottom of the content container. */
  BOTTOM = 'bottom',
  /** Scrollbar is somewhere in the center of the content container. */
  CENTER = 'center',
}

/** Subtitle for the content dialog layout. */
@Directive({
  selector: 'xap-dialog-layout-subtitle',
})
export class XapDialogLayoutSubtitle {
}

/** Content area for the dialog layout. */
@Directive({
  selector: `xap-dialog-layout-content`,
  host: {
    'class': 'xap-dialog-layout-content',
    '[class.xap-dialog-layout-content-scrollable]': 'scrollable',
    '[class.xap-dialog-layout-bottom-scroll-stroke]':
        'scrollPosition !== "bottom"',
    '[class.xap-dialog-layout-top-scroll-stroke]': 'scrollPosition !== "top"',
  }
})
export class XapDialogLayoutContent implements OnInit, OnDestroy {
  private readonly destroyed = new ReplaySubject<void>();

  /** Whether the content is scrollable. */
  get scrollable() {
    return this.internalScrollable;
  }
  private internalScrollable = false;

  /** The position of the scroll offset. */
  get scrollPosition(): ContentScrollPosition {
    return this.internalScrollPosition;
  }
  private internalScrollPosition = ContentScrollPosition.BOTTOM;

  constructor(
      private readonly el: ElementRef<HTMLElement>,
      private readonly cdRef: ChangeDetectorRef,
      private readonly ngZone: NgZone) {}

  ngOnInit() {
    this.internalScrollPosition = this.getScrollPosition(this.el.nativeElement);
    this.ngZone.runOutsideAngular(() => {
      this.initScrollableObserver().subscribe(scrollable => {
        this.ngZone.run(() => {
          this.internalScrollable = scrollable;
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();
        });
      });

      this.initScrollListener().subscribe(position => {
        this.ngZone.run(() => {
          this.internalScrollPosition = position;
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();
        });
      });
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /**
   * Creates an observable that emits a boolean indicating whether the content
   * viewport is scrollable.
   *
   * TODO(b/197957993) Use a shared library to derive border states.
   */
  private initScrollableObserver(): Observable<boolean> {
    if (goog.FEATURESET_YEAR < 2020 && !window.ResizeObserver) {
      return of(this.checkElementScrollable(this.el.nativeElement));
    }
    let resizeObserver: ResizeObserver;
    return fromEventPattern<boolean>(
               (handler) => {
                 resizeObserver = new ResizeObserver(() => {
                   handler(this.checkElementScrollable(this.el.nativeElement));
                 });
                 resizeObserver.observe(this.el.nativeElement);
               },
               () => {
                 resizeObserver.disconnect();
               })
        .pipe(distinctUntilChanged(), takeUntil(this.destroyed));
  }

  /**
   * Creates an observable that emits when the dialog's content viewport is
   * scrolled.
   */
  private initScrollListener(): Observable<ContentScrollPosition> {
    return fromEvent(this.el.nativeElement, 'scroll')
        .pipe(
            coalesceUpdatesWithinMicrotask(),
            map(() => this.getScrollPosition(this.el.nativeElement)),
            distinctUntilChanged(), takeUntil(this.destroyed));
  }

  private checkElementScrollable(el: HTMLElement): boolean {
    return (el.offsetHeight + el.scrollTop) <= el.scrollHeight;
  }

  private getScrollPosition(el: HTMLElement): ContentScrollPosition {
    if (el.scrollTop === 0) {
      return ContentScrollPosition.TOP;
    } else if ((el.offsetHeight + el.scrollTop) >= el.scrollHeight) {
      return ContentScrollPosition.BOTTOM;
    }
    return ContentScrollPosition.CENTER;
  }
}

/**
 * A layout component that styles dialog based on Reach Card Tooltip specs:
 * https://carbon.googleplex.com/reach-ux/pages/tooltips/spec#a9c0b1b6-bf55-4dab-b00b-b8b2cbe70d67
 *
 * Example usage:
 *
 * ```
 * <xap-dialog-layout>
 *   <xap-dialog-layout-title>
 *     Some Title
 *   </xap-dialog-layout-title>
 *   <xap-dialog-layout-subtitle>
 *     Some Subtitle
 *   </xap-dialog-layout-subtitle>
 *   <xap-dialog-layout-content>
 *     <p>
 *       This will redirect you to google.com
 *     </p>
 *   </xap-dialog-layout-content>
 *   <xap-dialog-layout-actions>
 *     <a mat-button
 *        color="primary"
 *        href="https://www.google.com/">
 *       Click me
 *     </a>
 *   </xap-dialog-layout-actions>
 * </xap-dialog-layout>
 * ```
 */
@Component({
  selector: 'xap-dialog-layout',
  templateUrl: './dialog_layout.ng.html',
  styleUrls: ['./dialog_layout.css'],
  host: {
    'class': 'xap-dialog-layout',
    '[class.xap-dialog-layout-bordered-header]': 'subtitle',
  },
  encapsulation: ViewEncapsulation.None,
})
export class XapDialogLayout {
  @ContentChildren(XapDialogLayoutSubtitle) subtitle?: XapDialogLayoutSubtitle;
}

/** The content section of xap-dialog-layout. */
@Directive({
  selector: `xap-dialog-layout-title, xap-dialog-layout-actions`,
})
export class XapDialogLayoutSection {
}
