<button
  *ngIf="richTooltipRef.isRichTooltipInteractive"
  mat-icon-button
  class="mat-icon-button rich-tooltip-close-button"
  aria-label="Close rich tooltip"
>
  <mat-icon svgIcon="close"></mat-icon>
</button>
<div class="rich-tooltip-content">
  <ng-container
    *ngTemplateOutlet="richTooltipRef.content; context: richTooltipRef.richTooltipTemplateData"
  >
  </ng-container>
</div>
