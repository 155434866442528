import {AnalyzeContentResponse_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {FormParameter} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_v3_ts_api_client_interfaces_only';
import {Dictionary} from 'google3/java/com/google/dialogflow/console/web/common/types/common_types';

import {getDialogflowAssistAnswers, getQueryResultV3FromDialogflowAssistAnswer, isHeadIntent, isSupplementalIntent} from './dialogflow_assist_shared_helpers';
import {DialogflowAssistState, ParameterSuggestions} from './dialogflow_assist_types';

/**
 * Drills into an analyze content response and returns the list of Dialogflow
 * Assist answers that contain head intent suggestions.
 */
export const getDialogflowAssistAnswersWithHeadIntentSuggestions =
    (analyzeContentResponse: AnalyzeContentResponse_) =>
        getDialogflowAssistAnswers(analyzeContentResponse)
            .filter(
                ({intentSuggestion}) =>
                    intentSuggestion && isHeadIntent(intentSuggestion));

/**
 * Drills into an analyze content response and returns the list of Dialogflow
 * Assist answers that contain forms.
 */
export const getDialogflowAssistAnswersWithQueryResultV3 =
    (analyzeContentResponse: AnalyzeContentResponse_) =>
        getDialogflowAssistAnswers(analyzeContentResponse)
            .filter(
                dialogflowAssistAnswer =>
                    getQueryResultV3FromDialogflowAssistAnswer(
                        dialogflowAssistAnswer));

/**
 * Drills into an analyze content response and returns the list of Dialogflow
 * Assist answers that contain intent suggestions.
 */
export const getDialogflowAssistAnswersWithIntentSuggestions =
    (analyzeContentResponse: AnalyzeContentResponse_) =>
        getDialogflowAssistAnswers(analyzeContentResponse)
            .filter(
                ({intentSuggestion}) => intentSuggestion &&
                    !isSupplementalIntent(intentSuggestion) &&
                    !isHeadIntent(intentSuggestion));

/** Converts parameter suggestions to form parameters. */
export const convertParameterSuggestionsToFormParameters =
    (parameterSuggestions?: ParameterSuggestions): FormParameter[] =>
        parameterSuggestions ?
    Object.keys(parameterSuggestions).map(displayName => ({
                                            displayName,
                                            required: false,
                                          })) :
    [];

/**
 * Gets a collection of parameter suggestion keys mapped to array of parameter
 * values.
 */
export const getParameterSuggestions =
    (analyzeContentResponse: AnalyzeContentResponse_) =>
        analyzeContentResponse.humanAgentSuggestionResults?.reduce(
            (dialogflowAssistParameterSuggestions: ParameterSuggestions,
             humanAgentSuggestionResult) => {
              const parameterCandidates =
                  humanAgentSuggestionResult.suggestDialogflowAssistsResponse
                      ?.parameterSuggestions?.parameterCandidates;

              if (parameterCandidates) {
                return {
                  ...dialogflowAssistParameterSuggestions,
                  ...Object.entries(parameterCandidates)
                      .reduce(
                          (parameterCandidatesCollection,
                           [parameterKey, parameter]) => parameter.candidates ?
                              ({
                                ...parameterCandidatesCollection,
                                [parameterKey]: parameter.candidates
                              }) :
                              parameterCandidatesCollection,
                          {}),
                };
              }

              return dialogflowAssistParameterSuggestions;
            },
            {});

/** Gets the full set of detected parameters from an AnalyzeContent response. */
export function getParameters(analyzeContentResponse: AnalyzeContentResponse_) {
  const dialogflowAssistAnswers =
      getDialogflowAssistAnswers(analyzeContentResponse);

  return dialogflowAssistAnswers.reduce(
      (parameters, dialogflowAssistAnswer) => {
        const answerParameters =
            getQueryResultV3FromDialogflowAssistAnswer(dialogflowAssistAnswer)
                ?.parameters;

        return {...parameters, ...answerParameters};
      },
      {} as Dictionary);
}

/**
 * Merges the head intent from an analyze response with existing head intents
 * in state.
 */
export const mergeHeadIntentsWithState =
    (state: DialogflowAssistState,
     analyzeContentResponse:
         AnalyzeContentResponse_): DialogflowAssistState => {
      const dialogflowAssistAnswersWithHeadIntentSuggestions =
          getDialogflowAssistAnswersWithHeadIntentSuggestions(
              analyzeContentResponse);

      if (dialogflowAssistAnswersWithHeadIntentSuggestions.length) {
        const parameterSuggestions =
            getParameterSuggestions(analyzeContentResponse);

        return {
          ...state,
          headIntents: dialogflowAssistAnswersWithHeadIntentSuggestions.reduce(
              (updatedState, answer) => ({
                ...updatedState,
                [answer.intentSuggestion!.intentV3!]: {
                  dialogflowAssistAnswer: answer,
                  formParameters: convertParameterSuggestionsToFormParameters(
                      parameterSuggestions),
                },
              }),
              state.headIntents),
        };
      }

      return state;
    };

/**
 * Merges the intent from an analyze response with existing intents in state.
 */
export const mergeIntentsWithState =
    (state: DialogflowAssistState,
     analyzeContentResponse: AnalyzeContentResponse_):
        DialogflowAssistState => {
          const dialogflowAssistAnswersWithIntentSuggestions =
              getDialogflowAssistAnswersWithIntentSuggestions(
                  analyzeContentResponse);

          if (dialogflowAssistAnswersWithIntentSuggestions.length) {
            return {
              ...state,
              activeWorkflow: [
                ...state.activeWorkflow,
                ...dialogflowAssistAnswersWithIntentSuggestions,
              ],
            };
          }

          return state;
        };

/**
 * Deep merges new parameter suggestions with existing parameter suggestions in
 * state.
 */
export const mergeParameterSuggestionsWithState =
    (state: DialogflowAssistState,
     analyzeContentResponse:
         AnalyzeContentResponse_): DialogflowAssistState => {
      const parameterSuggestions =
          getParameterSuggestions(analyzeContentResponse);

      return parameterSuggestions ? ({
        ...state,
        parameterSuggestions:
            Object.entries(parameterSuggestions)
                .reduce(
                    (paramSuggestions, [parameterDisplayName, paramValue]) =>
                        parameterDisplayName in paramSuggestions ?
                        ({
                          ...paramSuggestions,
                          [parameterDisplayName]: [
                            ...paramSuggestions[parameterDisplayName],
                            ...paramValue
                          ],
                        }) :
                        ({
                          ...paramSuggestions,
                          [parameterDisplayName]: paramValue
                        }),
                    state.parameterSuggestions)
      }) :
                                    state;
    };

/** Merges new parameters with existing parameters in state. */
export function mergeParametersWithState(
    state: DialogflowAssistState,
    analyzeContentResponse: AnalyzeContentResponse_): DialogflowAssistState {
  const parameters = getParameters(analyzeContentResponse);

  return {
    ...state,
    parameters: {
      ...state.parameters,
      ...parameters,
    },
  };
}
