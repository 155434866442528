<section
  #body
  [class.guidance--loading]="loadingState === 'LOADING'"
  class="guidance__content-wrapper"
>
  <div class="guidance__content" #guidanceContent>
    <mat-spinner
      *ngIf="loadingState === 'LOADING'"
      aria-label="Guidance loading"
      i18n-aria-label="ARIA label for spinner to indicate that the conversation guidance feature is loading"
    ></mat-spinner>

    <ng-container *ngIf="loadingState === 'LOADED'">
      <div class="breadcrumb">
        <ng-container *ngFor="let group of groups">
          <button
            [name]="group.displayName"
            *xapLet="{
              isActiveGroup: group.displayName === activeGroupName,
              isSkippedGroup: group.displayName && !!skippedGroupMap[group.displayName],
              isVisitedGroup: group.displayName && !!visitedGroupMap[group.displayName],
              isSelectedGroup: group.displayName === selectedGroupName,
              precedesSelectedGroup:
                group.displayName &&
                selectedGroupName &&
                groupMap[group.displayName]?.index === (groupMap[selectedGroupName]?.index ?? 0) - 1
            } as data"
            [class.active-breadcrumb]="data.isActiveGroup"
            [class.skipped-breadcrumb]="data.isSkippedGroup"
            [class.visited-breadcrumb]="!data.isActiveGroup && data.isVisitedGroup"
            [class.selected-breadcrumb]="!data.isActiveGroup && data.isSelectedGroup"
            [class.precedes-selected-breadcrumb]="data.precedesSelectedGroup"
            (click)="setSelectedGroupName(group.displayName)"
          >
            {{ group.displayName }}
          </button>
        </ng-container>
      </div>

      <div
        @fadeIn
        class="notification__container"
        *ngIf="!hasUnacknowledgedCongratsNotification && hasUnacknowledgedSkippedStage"
      >
        <mat-icon svgIcon="warning"></mat-icon>
        <!-- prettier-ignore -->
        <div class="notification__content">{skippedStageCount // i18n(ph = "number of skipped stages"), plural,
          =1 {It looks like you might have skipped a step}
          other {It looks like you might have skipped {{skippedStageCount // i18n(ph = "number of skipped stages"}} steps}}
        </div>
        <button
          data-test-id="dismiss-skipped-warning-button"
          (click)="acknowledgeSkippedStep()"
          color="primary"
          i18n="Button for users to acknowledge the skipped stages"
          mat-button
        >
          GOT IT
        </button>
      </div>
      <div @fadeIn class="notification__container" *ngIf="hasUnacknowledgedCongratsNotification">
        <mat-icon svgIcon="check_circle"></mat-icon>
        <div
          class="notification__content"
          i18n="Congratulatory message when a new conversation stage is entered"
        >
          Great work getting into the {{ activeStageName // i18n(ph = "The current conversation
          stage") }} stage
        </div>
        <button
          data-test-id="dismiss-congrats-button"
          (click)="acknowledgeCongratsNotification()"
          color="primary"
          i18n="Button for users to acknowledge and close the congratulatory message"
          mat-button
        >
          CLOSE
        </button>
      </div>

      <ng-container *ngIf="selectedGroup">
        <div class="stage-guidance-container" [@flyIn]="transitionDirection">
          <ng-container *ngFor="let stage of selectedGroup?.stages; first as isFirstStage">
            <mat-expansion-panel
              hideToggle
              *xapLet="{
                selectedGuidance:
                  stage.displayName && stageMap[stage.displayName]?.selectedGuidance,
                isActiveGroup: selectedGroupName === activeGroupName,
                isActiveStage: stage.displayName === activeStageName,
                isVisitedStage: stage.displayName && visitedStageMap[stage.displayName]
              } as data"
              [expanded]="data.isActiveStage || (!data.isActiveGroup && isFirstStage)"
            >
              <mat-expansion-panel-header
                [class.active-guidance]="data.isActiveStage"
                [class.visited-guidance]="!data.isActiveStage && data.isVisitedStage"
              >
                <mat-panel-title>
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Active stage"
                    i18n-aria-label="ARIA label for an icon indicating the active conversation stage"
                    svgIcon="arrow_forward"
                    *ngIf="data.isActiveStage"
                  ></mat-icon>
                  {{ stage.displayName }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>{{ data.selectedGuidance }}</p>
              <div
                @fadeIn
                class="stage-transition-hint"
                *ngIf="data.isActiveStage && stage.stageTransitionHint"
              >
                <mat-icon svgIcon="lightbulb"></mat-icon>
                <p i18n="Hint on how to enter the next conversation stage">
                  <span class="bold">Next:</span> {{ stage.stageTransitionHint }}
                </p>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</section>
