import {AriaDescriber, ConfigurableFocusTrapFactory} from '@angular/cdk/a11y';
import {ENTER, ESCAPE, SPACE} from '@angular/cdk/keycodes';
import {ConnectedPosition, Overlay, OverlayConfig} from '@angular/cdk/overlay';
import {DOCUMENT} from '@angular/common';
import {Directive, ElementRef, Inject, Injector, NgZone, OnInit, ViewContainerRef} from '@angular/core';
import {fromEvent} from 'rxjs';
import {filter, takeUntil, throttle} from 'rxjs/operators';

import {XapInlineDialogBase} from './inline_dialog_base';

/**
 * An attribute directive that shows a popup dialog when the element it is
 * placed on is clicked.
 */
@Directive({
  selector: '[xapInlineDialogClick]',
  exportAs: 'xapInlineDialogClick',
  host: {'class': 'xap-inline-dialog-click'},
  inputs: ['dialog: xapInlineDialogClick', 'disabled: xapInlineDialogDisabled'],
})
export class XapInlineDialogClick extends XapInlineDialogBase implements
    OnInit {
  constructor(
      ngZone: NgZone,
      overlay: Overlay,
      elementRef: ElementRef,
      viewContainerRef: ViewContainerRef,
      @Inject(DOCUMENT) document: Document,
      focusTrapFactory: ConfigurableFocusTrapFactory,
      ariaDescriber: AriaDescriber,
      injector: Injector,
  ) {
    super(
        ngZone, overlay, elementRef, viewContainerRef, document,
        focusTrapFactory, ariaDescriber, injector);
    this.attachMouseEventListeners(elementRef.nativeElement);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.listenForOpenEvents();
  }

  listenForOpenEvents(): void {
    this.openStatusChange.pipe(takeUntil(this.destroyed))
        .subscribe((opened) => {
          opened ? this.openDialog() : this.closeDialog();
        });

    // Emit the mouse triggered beforeOpened event when the first
    // open-triggering event happens.
    this.openings.pipe(
        filter(opening => opening && this.beforeOpened.observers.length > 0),
        throttle(() => this.openStatusChange.pipe(filter(opened => !opened))),
    );
  }

  attachMouseEventListeners(element: HTMLElement): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(element, 'mouseenter')
          .pipe(takeUntil(this.destroyed))
          .subscribe(() => {
            this.ngZone.run(() => {
              this.beforeOpened.emit();
            });
          });

      fromEvent(element, 'click')
          .pipe(takeUntil(this.destroyed))
          .subscribe((event) => {
            const element = event.target as HTMLElement;
            if (element.closest('[xapInlineDialogClose]') &&
                this.openStatusChange.value) {
              this.closingDialog();
            } else {
              this.openingDialog();
              event.stopPropagation();
            }
          });

      this.overlayRef?.backdropClick()
          .pipe(takeUntil(this.destroyed))
          .subscribe(() => {
            if (this.openStatusChange.value) {
              this.closingDialog();
            }
          });
    });
  }

  attachKeyboardCloseEventListeners(element: HTMLElement): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent<KeyboardEvent>(element, 'keyup')
          .pipe(takeUntil(this.destroyed))
          .subscribe((event: KeyboardEvent) => {
            const keyCode = event.keyCode;
            const element = event.target as HTMLElement;
            switch (keyCode) {
              case ESCAPE:
                this.closeDialog();
                return;
              case ENTER:
              case SPACE:
                if (element.closest('[xapInlineDialogClose]')) {
                  this.closeDialog();
                }
                return;
              default:
                return;
            }
          });
    });
  }

  private openingDialog(): void {
    if (this.disabled) return;
    this.openStatusChange.next(true);
  }

  private closingDialog(): void {
    this.openStatusChange.next(false);
  }

  createOverlayConfig(positions: ConnectedPosition[]): OverlayConfig {
    return new OverlayConfig({
      ...this.overlayDimensions,
      positionStrategy: super.createPositionStrategy(positions),
      scrollStrategy: this.overlay.scrollStrategies.close(),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: this.panelClassInternal,
    });
  }
}
