import {createAction, props} from '@ngrx/store';
import {GoogleRpcStatus} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_ts_api_client';
import {Project, RecentProjects} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_v3_ts_api_client';

/** UserSettings API error */
const apiError = createAction(
    '[USER_SETTINGS] API_ERROR', props<{error: GoogleRpcStatus}>());

/** Get recent projects start action */
const getRecentProjectsStart = createAction(
    '[USER_SETTINGS] GET_RECENT_PROJECTS_START', props<{project: string}>());

/** Get recent projects success action */
const getRecentProjectsSuccess =
    createAction('[USER_SETTINGS] GET_RECENT_PROJECTS_SUCCESS', props<{
                   recentProjects: RecentProjects,
                   validProjects: Project[],
                   projectNames: Set<string>,
                 }>());

/** Patch recent projects start action */
const patchRecentProjectsStart = createAction(
    '[USER_SETTINGS] PATCH_RECENT_PROJECTS_START',
    props<{name: string, recentProjects: RecentProjects, params?: object}>());

/** Patch recent projects success action */
const patchRecentProjectsSuccess =
    createAction('[USER_SETTINGS] PATCH_RECENT_PROJECTS_SUCCESS', props<{
                   recentProjects: RecentProjects,
                   validProjects: Project[],
                   projectNames: Set<string>
                 }>());

/** Fetch projects action */
const fetchProjects = createAction('[USER_SETTINGS] FETCH_PROJECTS', props<{
                                     recentProjects: RecentProjects,
                                     projectIds: string[],
                                     isUpdate: boolean
                                   }>());

/** Collection of all user settings actions */
export const actions = {
  apiError,
  getRecentProjectsStart,
  getRecentProjectsSuccess,
  patchRecentProjectsStart,
  patchRecentProjectsSuccess,
  fetchProjects,
};
