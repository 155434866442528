import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

import {DialogService} from './dialog_service/dialog_service';
import {SimpleSnackBar, SnackBarData} from './simple_snack_bar/simple_snack_bar';



/** System-wide snackbar service message type */
export enum SnackBarMessageType {
  ERROR = 'error-snackbar',
  SUCCESS = 'success-snackbar',
  INFO = 'info-snackbar',
}



/** System-wide snackbar service */
export function openMessageSnackBar(
    matSnackBar: MatSnackBar,
    panelClass: SnackBarMessageType,
    message: string,
    actionMessage?: string,
    ): MatSnackBarRef<SimpleSnackBar> {
  const data: SnackBarData = {
    message,
    action: actionMessage,
  };

  return matSnackBar.openFromComponent(SimpleSnackBar, {
    data,
    panelClass,
    // For accessibility, Snackbars should remain open indefinitely until
    // dismissed by users.
    duration: Infinity,
  });
}

/** System-wide messaging service */
@Injectable()
export class SystemMessagingService {
  constructor(
      private readonly matSnackBar: MatSnackBar,
      private readonly dialogService: DialogService) {}

  /**
   * Shows a simple material dialog.
   * @param message Message to show in the dialog body.
   * @param givenTitle Dialog title. Recommended for accessibility.
   * @param restoreFocus Whether to restore keyboard focus to the element that
   * opened the dialog when it is closed.
   * @return MatDialog reference.
   */
  showSimpleDialog(
      message: string, givenTitle?: string, restoreFocus: boolean = true) {
    const title = givenTitle || '';
    return this.dialogService.openSimpleDialog({message, title, restoreFocus});
  }

  /**
   * Shows a confirmation dialog.
   * @param message Message to show in the dialog body.
   * @param givenTitle Dialog title. Recommended for accessibility.
   * @param restoreFocus Whether to restore keyboard focus to the element that
   * opened the dialog when it is closed.
   * @return MatDialog reference.
   */
  showConfirmationDialog(
      message: string, givenTitle?: string, restoreFocus: boolean = true) {
    const title = givenTitle || '';
    return this.dialogService.openSimpleDialog(
        {message, title, confirm: true, restoreFocus});
  }

  /**
   * Shows an error snackbar.
   * @param message Snackbar message
   * @param actionMessage Message for the action button in the snackbar.
   * An alternate method of performing any non-dismiss actions should be
   * provided for screen reader users.
   */
  showErrorSnackBar(
      message: string,
      actionMessage?: string,
  ) {
    return openMessageSnackBar(
        this.matSnackBar, SnackBarMessageType.ERROR,
        SystemMessagingService.parseErrorMessage(message), actionMessage);
  }

  /**
   * Shows a success snackbar.
   * @param message Snackbar message
   * @param actionMessage Message for the action button in the snackbar.
   * An alternate method of performing any non-dismiss actions should be
   * provided for screen reader users.
   */
  showSuccessSnackBar(
      message: string,
      actionMessage?: string,
  ) {
    return openMessageSnackBar(
        this.matSnackBar, SnackBarMessageType.SUCCESS, message, actionMessage);
  }

  /**
   * Shows an info snackbar.
   * @param message Snackbar message
   * @param actionMessage Message for the action button in the snackbar.
   * An alternate method of performing any non-dismiss actions should be
   * provided for screen reader users.
   */
  showInfoSnackBar(
      message: string,
      actionMessage?: string,
  ) {
    return openMessageSnackBar(
        this.matSnackBar, SnackBarMessageType.INFO, message, actionMessage);
  }

  /**
   * Not recommended. Automatically dismissing a snackbar is against
   * accessibility guidelines. Shows a snackbar with given duration.
   * @param message Snackbar message
   * @param actionMessage Message for the action button in the snackbar.
   * An alternate method of performing any non-dismiss actions should be
   * provided for screen reader users.
   */
  showInfoSnackBarWithDuration(message: string, actionMessage?: string) {
    this.matSnackBar.open(
        message, actionMessage || 'Dismiss', {duration: 3000});
  }

  static parseErrorMessage(message: string): string {
    const exceptionIndex = message.indexOf('Exception:');
    if (exceptionIndex >= 0) {
      message = message.substring(10 + exceptionIndex);
    }

    return message;
  }
}
