import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {FocusInitial} from './focus_initial_directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FocusInitial],
  exports: [FocusInitial]
})
export class FocusInitialModule {
}
