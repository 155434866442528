import {EventEmitter, Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {AnalyzeContentRequest_, AnalyzeContentResponse_, Conversation_, Participant_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {ResponseMessage} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_v3_ts_api_client_interfaces_only';
import {LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {getDialogflowAssistAnswersWithQueryResultV3, mergeHeadIntentsWithState, mergeIntentsWithState, mergeParameterSuggestionsWithState, mergeParametersWithState} from './dialogflow_assist_service_helpers';
import {generateDialogflowAssistElementTemplate} from './dialogflow_assist_template_generator';
import {ChooseResponseMessagePayload, DialogflowAssistHeadIntentAndFormParameters, DialogflowAssistState} from './dialogflow_assist_types';

const newConversationState: Omit<DialogflowAssistState, 'loadingState'> = {
  activeWorkflow: [],
  activeHeadIntentsOriginalAnswerRecordName: null,
  conversation: null,
  headIntents: {},
  humanAgentParticipant: null,
  nominatedHeadIntent: null,
  parameters: {},
  parameterSuggestions: {},
  showUseSuggestionButton: true,
  usedIntentSuggestions: {},
  usedResponseMessages: {},
};

const initialState: DialogflowAssistState = {
  ...newConversationState,
  loadingState: LoadingState.NOT_LOADING,
};

/** Dialogflow Assist service. */
@Injectable()
export class DialogflowAssistService extends
    ComponentStore<DialogflowAssistState> {
  readonly cancelWorkflow$ = new EventEmitter<AnalyzeContentRequest_>();
  readonly selectHeadIntent$ = new EventEmitter<AnalyzeContentRequest_>();
  readonly selectResponseMessage$ =
      new EventEmitter<ChooseResponseMessagePayload>();
  readonly submitWorkflowEntry$ = new EventEmitter<AnalyzeContentRequest_>();
  readonly confirmNominatedHeadIntent$ =
      new EventEmitter<AnalyzeContentRequest_>();

  readonly stateUpdate$ = new Subject<Partial<DialogflowAssistState>|(
      (state: DialogflowAssistState) => DialogflowAssistState)>();

  readonly activeWorkflowHeadIntent$ =
      this.select(state => state.activeWorkflow?.[0]);
  readonly activeHeadIntentsOriginalAnswerRecordName$ =
      this.select(state => state.activeHeadIntentsOriginalAnswerRecordName);
  readonly conversationCompleted$ =
      this.select(state => state.conversation?.lifecycleState === 'COMPLETED');
  readonly conversationName$ = this.select(state => state.conversation?.name);
  readonly elementTemplate$ =
      this.select(generateDialogflowAssistElementTemplate);
  readonly headIntents$ =
      this.select(state => Object.values(state.headIntents));
  readonly humanAgentParticipantName$ =
      this.select(state => state.humanAgentParticipant?.name);
  readonly loadingState$ = this.select(state => state.loadingState);
  readonly nominatedHeadIntent$ =
      this.select(state => state.nominatedHeadIntent);

  constructor() {
    super(initialState);

    this.stateUpdate$.pipe(takeUntil(this.destroy$))
        .subscribe(partialStateOrFn => {
          if (typeof partialStateOrFn === 'function') {
            this.setState(partialStateOrFn);
          } else {
            this.setState(state => ({...state, ...partialStateOrFn}));
          }
        });
  }

  setAnalyzeContentResponse(analyzeContentResponse: AnalyzeContentResponse_|
                            undefined) {
    if (!analyzeContentResponse) {
      return;
    }

    this.stateUpdate$.next(state => {
      let newState = state;
      newState =
          mergeParameterSuggestionsWithState(newState, analyzeContentResponse);
      newState = mergeParametersWithState(newState, analyzeContentResponse);
      newState = mergeHeadIntentsWithState(newState, analyzeContentResponse);
      newState = mergeIntentsWithState(newState, analyzeContentResponse);

      const dialogflowAssistAnswersWithQueryResultV3 =
          getDialogflowAssistAnswersWithQueryResultV3(analyzeContentResponse);

      if (dialogflowAssistAnswersWithQueryResultV3.length) {
        newState = {
          ...newState,
          activeWorkflow: [
            ...state.activeWorkflow,
            ...dialogflowAssistAnswersWithQueryResultV3,
          ],
        };
      }

      return newState;
    });
  }

  setConversation(conversation: Conversation_) {
    this.stateUpdate$.next(state => {
      const isNewConversation = state.conversation?.name &&
          state.conversation.name !== conversation.name;

      return {
        ...state,
        ...(isNewConversation ? newConversationState : {}),
        conversation
      };
    });
  }

  setLoadingState(loadingState: LoadingState) {
    this.stateUpdate$.next({loadingState});
  }

  setActiveHeadIntentsOriginalAnswerRecordName(
      activeHeadIntentsOriginalAnswerRecordName: string|null|undefined) {
    this.stateUpdate$.next({activeHeadIntentsOriginalAnswerRecordName});
  }
  setNominatedHeadIntent(nominatedHeadIntent:
                             DialogflowAssistHeadIntentAndFormParameters) {
    this.stateUpdate$.next({nominatedHeadIntent});
  }

  setHumanAgentParticipant(humanAgentParticipant: Participant_) {
    this.stateUpdate$.next({humanAgentParticipant});
  }

  setShowUseSuggestionButton(showUseSuggestionButton: boolean) {
    this.stateUpdate$.next({showUseSuggestionButton});
  }

  /** Called when choosing a head intent. */
  chooseHeadIntent(headIntentAndFormParameters:
                       DialogflowAssistHeadIntentAndFormParameters) {
    const {dialogflowAssistAnswer: {answerRecord}, formParameters, parameters} =
        headIntentAndFormParameters;

    this.setActiveHeadIntentsOriginalAnswerRecordName(answerRecord);

    if (formParameters?.length) {
      // Nominate the head intent directly (no need to make another
      // AnalyzeContent call to fetch parameters).
      this.setNominatedHeadIntent(headIntentAndFormParameters);
      return;
    }

    const analyzeContentRequest: AnalyzeContentRequest_ = {
      'suggestionInput': {
        'answerRecord': answerRecord,
        'parameters': parameters,
      }
    };

    this.selectHeadIntent$.emit(analyzeContentRequest);
  }

  // reset parameter suggestions?
  resetActiveWorkflow() {
    this.stateUpdate$.next(state => ({
                             ...state,
                             activeWorkflow: [],
                             activeHeadIntentsOriginalAnswerRecordName: null,
                             nominatedHeadIntent: null,
                             usedIntentSuggestions: {},
                             usedResponseMessages: {},
                             parameterSuggestions: {},
                             parameters: {},
                           }));
  }

  /** Called when submitting Dialogflow Assist workflow. */
  useIntentSuggestion(
      answerRecordName: string, intentSuggestion: string|null|undefined) {
    if (intentSuggestion) {
      this.stateUpdate$.next(
          state => ({
            ...state,
            usedIntentSuggestions: {
              ...state.usedIntentSuggestions,
              [answerRecordName]: [
                ...(state.usedIntentSuggestions[answerRecordName] || []),
                intentSuggestion,
              ],
            },
          }));
    }
  }

  /**
   * Called when dismissing a response message (marking it as used, but not
   * populating the agent's input box with the message).
   */
  dismissResponseMessage(
      answerRecordName: string, responseMessage: ResponseMessage) {
    this.stateUpdate$.next(
        state => ({
          ...state,
          usedResponseMessages: {
            ...state.usedResponseMessages,
            [answerRecordName]: [
              ...(state.usedResponseMessages[answerRecordName] || []),
              responseMessage,
            ],
          },
        }));
  }

  /** Called when using a response message. */
  useResponseMessage(
      answerRecordName: string, responseMessage: ResponseMessage) {
    this.dismissResponseMessage(answerRecordName, responseMessage);
    this.selectResponseMessage$.emit({answerRecordName, responseMessage});
  }
}
