import {ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AnalyzeContentResponse_, Conversation_, DialogflowAssistAnswer_, Participant_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';
import {assertBoolean, assertInstanceof} from 'google3/javascript/typescript/contrib/assert';
import {Observable, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {DialogflowAssistService} from './dialogflow_assist_service';
import {getIntentDescription, getIntentDisplayName} from './dialogflow_assist_shared_helpers';
import {DialogflowAssistElementTemplate, SubmitDialogflowAssistWorkflowPayload} from './dialogflow_assist_types';

/** Dialogflow Assist shared suggestion feature component. */
@Component({
  selector: 'dialogflow-assist',
  templateUrl: './dialogflow_assist.ng.html',
  styleUrls: ['./dialogflow_assist.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogflowAssistService]
})
export class DialogflowAssist implements OnInit, OnDestroy {
  @ViewChild('body', {static: true}) readonly body?: ElementRef;

  @Input() debugMode!: boolean;

  @Input()
  set showUseSuggestionButton(showUseSuggestionButton: boolean) {
    this.dialogflowAssistService.setShowUseSuggestionButton(
        showUseSuggestionButton);
  }

  @Input()
  set analyzeContentResponses(analyzeContentResponses:
                                  AnalyzeContentResponse_[]) {
    if (analyzeContentResponses.length) {
      this.dialogflowAssistService.setAnalyzeContentResponse(
          analyzeContentResponses[analyzeContentResponses.length - 1]);
    }
  }
  @Input()
  set conversation(conversation: Conversation_) {
    this.dialogflowAssistService.setConversation(conversation);
  }
  @Input()
  set loadingState(loadingState: LoadingState) {
    this.dialogflowAssistService.setLoadingState(loadingState);
  }
  @Input()
  set humanAgentParticipant(humanAgentParticipant: Participant_) {
    this.dialogflowAssistService.setHumanAgentParticipant(
        humanAgentParticipant);
  }
  @Input() endUserParticipantLoadStatus = LoadingState.NOT_LOADING;

  @Input('cancelWorkflow') cancelWorkflow$!: Observable<unknown>;

  @Output()
  readonly onCancelWorkflow = this.dialogflowAssistService.cancelWorkflow$;
  @Output()
  readonly onSelectHeadIntent = this.dialogflowAssistService.selectHeadIntent$;
  @Output()
  readonly onSelectResponseMessage =
      this.dialogflowAssistService.selectResponseMessage$;
  @Output()
  readonly onSubmitWorkflowEntry =
      this.dialogflowAssistService.submitWorkflowEntry$;
  @Output()
  readonly onConfirmNominatedHeadIntent =
      this.dialogflowAssistService.confirmNominatedHeadIntent$;

  readonly activeWorkflowHeadIntent$ =
      this.dialogflowAssistService.activeWorkflowHeadIntent$;

  readonly conversationCompleted$ =
      this.dialogflowAssistService.conversationCompleted$;

  readonly activeHeadIntentsOriginalAnswerRecordName$ =
      this.dialogflowAssistService.activeHeadIntentsOriginalAnswerRecordName$;

  readonly conversationName$ = this.dialogflowAssistService.conversationName$;

  readonly elementTemplates$ = this.dialogflowAssistService.elementTemplate$;

  readonly headIntents$ = this.dialogflowAssistService.headIntents$;

  readonly humanAgentParticipantName$ =
      this.dialogflowAssistService.humanAgentParticipantName$;

  readonly loadingState$ = this.dialogflowAssistService.loadingState$;

  readonly nominatedHeadIntent$ =
      this.dialogflowAssistService.nominatedHeadIntent$;

  readonly handleChooseHeadIntent =
      this.dialogflowAssistService.chooseHeadIntent.bind(
          this.dialogflowAssistService);

  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
      private readonly dialogflowAssistService: DialogflowAssistService,
  ) {}

  handleGetHeadIntentDisplayName(dialogflowAssistAnswer:
                                     DialogflowAssistAnswer_|null) {
    return getIntentDescription(dialogflowAssistAnswer) ||
        getIntentDisplayName(dialogflowAssistAnswer);
  }

  handleCancelActiveHeadIntent(answerRecordName: string) {
    if (answerRecordName) {
      this.onCancelWorkflow.emit({
        'suggestionInput': {'answerRecord': answerRecordName, 'cancel': true}
      });
    } else {
      this.dialogflowAssistService.resetActiveWorkflow();
    }
  }

  handleConfirmNominatedHeadIntent({answerRecordName, parameters}:
                                       SubmitDialogflowAssistWorkflowPayload) {
    this.onConfirmNominatedHeadIntent.emit({
      'suggestionInput': {
        'answerRecord': answerRecordName,
        'parameters': parameters,
      },
    });
  }

  handleSubmitDialogflowAssistWorkflow(
      {answerRecordName, parameters, intentSuggestion}:
          SubmitDialogflowAssistWorkflowPayload) {
    if (intentSuggestion) {
      this.dialogflowAssistService.useIntentSuggestion(
          answerRecordName, intentSuggestion);
    }

    this.onSubmitWorkflowEntry.emit({
      'suggestionInput': {
        'answerRecord': answerRecordName,
        'parameters': parameters,
        'intentInput': {
          'intent': intentSuggestion,
        },
      },
    });
  }

  resetActiveWorkflow() {
    this.dialogflowAssistService.resetActiveWorkflow();
  }

  trackBy(
      index: number,
      dialogflowAssistElementTemplate: DialogflowAssistElementTemplate[]) {
    return JSON.stringify(dialogflowAssistElementTemplate);
  }

  ngOnInit() {
    assertInstanceof(this.cancelWorkflow$, Observable);
    assertBoolean(
        this.debugMode,
        'Please pass a valid debug mode input to the <dialogflow-assist> component.');

    // Reset the active workflow when the workflow is cancelled.
    this.cancelWorkflow$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.resetActiveWorkflow();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
