import {ConnectedPosition} from '@angular/cdk/overlay';

import {RichTooltipPosition} from './rich_tooltip_position';

const START_CENTER_FIRST: ConnectedPosition[] = [
  {originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center'},
  {originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top'},
  {originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom'},
];

const END_CENTER_FIRST: ConnectedPosition[] = [
  {originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center'},
  {originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top'},
  {originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom'},
];

const TOP_CENTER_FIRST: ConnectedPosition[] = [
  {originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom'},
  {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
  {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'},
];

const TOP_START_FIRST: ConnectedPosition[] = [
  {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
  {originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom'},
  {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'},
];

const BOTTOM_CENTER_FIRST: ConnectedPosition[] = [
  {originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top'},
  {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
  {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
];

const BOTTOM_START_FIRST: ConnectedPosition[] = [
  {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
  {originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top'},
  {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
];

const END_CENTER_TOP_FIRST: ConnectedPosition[] = [
  {originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'bottom'},
  {originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center'},
  {originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top'},
];

const START_CENTER_TOP_FIRST: ConnectedPosition[] = [
  {originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'bottom'},
  {originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center'},
  {originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top'},
];

const START_CENTER_BOTTOM_FIRST: ConnectedPosition[] = [
  {originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'top'},
  {originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center'},
  {originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'bottom'},
];

const END_CENTER_BOTTOM_FIRST: ConnectedPosition[] = [
  {originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'top'},
  {originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center'},
  {originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'bottom'},
];

const FALLBACKS: {[key in RichTooltipPosition]: ConnectedPosition[]} = {
  'top': [
    ...TOP_CENTER_FIRST,
    ...BOTTOM_CENTER_FIRST,
    ...START_CENTER_FIRST,
    ...END_CENTER_FIRST,
  ],
  'topleft': [
    ...TOP_START_FIRST,
    ...BOTTOM_START_FIRST,
    ...START_CENTER_FIRST,
    ...END_CENTER_FIRST,
  ],
  'bottom': [
    ...BOTTOM_CENTER_FIRST,
    ...TOP_CENTER_FIRST,
    ...END_CENTER_FIRST,
    ...START_CENTER_FIRST,
  ],
  'bottomleft': [
    ...BOTTOM_START_FIRST,
    ...TOP_START_FIRST,
    ...END_CENTER_FIRST,
    ...START_CENTER_FIRST,
  ],
  'left': [
    ...START_CENTER_FIRST,
    ...END_CENTER_FIRST,
    ...TOP_CENTER_FIRST,
    ...BOTTOM_CENTER_FIRST,
  ],
  'right': [
    ...END_CENTER_FIRST,
    ...START_CENTER_FIRST,
    ...BOTTOM_CENTER_FIRST,
    ...TOP_CENTER_FIRST,
  ],
  'righttop': [
    ...END_CENTER_TOP_FIRST,
    ...START_CENTER_FIRST,
    ...BOTTOM_CENTER_FIRST,
    ...TOP_CENTER_FIRST,
  ],
  'lefttop': [
    ...START_CENTER_TOP_FIRST,
    ...START_CENTER_FIRST,
    ...BOTTOM_CENTER_FIRST,
    ...END_CENTER_FIRST,
  ],
  'leftbottom': [
    ...START_CENTER_BOTTOM_FIRST,
    ...BOTTOM_CENTER_FIRST,
    ...END_CENTER_BOTTOM_FIRST,
    ...TOP_CENTER_FIRST,
  ],
  'rightbottom': [
    ...END_CENTER_BOTTOM_FIRST,
    ...BOTTOM_CENTER_FIRST,
    ...START_CENTER_FIRST,
    ...TOP_CENTER_FIRST,
  ],
};

/**
 * Returns the corresponding overlay position config and fallbacks for the given
 * side of the host element.
 */
export function getFallbackPositions(position: RichTooltipPosition) {
  return FALLBACKS[position];
}
