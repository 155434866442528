import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SimpleDialog} from './simple_dialog';

/** Dialog system messaging sub-service to show dialogs */
@Injectable()
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}
  openSimpleDialog(data: {
    title: string,
    message: string,
    confirm?: boolean, restoreFocus: boolean,
  }) {
    const autoFocus = data?.title ? '.simple-dialog-title' : 'first-tabbable';
    return this.dialog.open(
        SimpleDialog, {data, autoFocus, restoreFocus: data?.restoreFocus});
  }
}
