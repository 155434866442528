import {ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UI_MODULE_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/injection_tokens';
import {dispatchUiModuleEvent, fromUiModuleErrorEvent, fromUiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {UiModuleIconService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/icon/ui_module_icon_service';
import {NotificationService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_service';
import {UiModuleStore} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/store/ui_module_store';
import {UiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {LoadingState} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/loading_state';
import {KnowledgeAssistConfig} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/ui_module_container_config';
import {DEFAULT_ARTICLE_LINK_CONFIG} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/document_and_faq_constants';
import {dedupeDocumentAndFaqSuggestions} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/document_and_faq_helpers';
import {ArticleOrFaqAnswers, ArticleOrFaqAnswerWithCreateTime, ArticleSelection, DocumentOrFaqFeedback} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/document_and_faq_types';
import {getArticleSuggestionsWithCreateTimeFromAnalyzeContentResponse, getFaqSuggestionsWithCreateTimeFromAnalyzeContentResponse} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/helpers/participants_helpers';
import {AnswerRecord_, Conversation_, SearchArticleAnswer_, SuggestionFeature_Type} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {ReplaySubject} from 'google3/third_party/javascript/rxjs/src';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {KNOWLEDGE_ASSIST_ICONS} from './knowledge_assist_constants';



/** Element selector for Knowledge Assist web component. */
export const KNOWLEDGE_ASSIST_ELEMENT_SELECTOR =
    'agent-assist-knowledge-assist';

interface State {
  conversation: Conversation_|null;
  suggestions: ArticleOrFaqAnswerWithCreateTime[];
  answerRecords: {readonly [answerRecordName: string]: AnswerRecord_;};
  articleSearchResults: SearchArticleAnswer_[]|undefined;
  articleSearchLoadingState: LoadingState;
}

const initialState: State = {
  conversation: null,
  suggestions: [],
  answerRecords: {},
  articleSearchResults: [],
  articleSearchLoadingState: 'NOT_LOADING',
};

/** Knowledge Assist component. */
@Component({
  selector: KNOWLEDGE_ASSIST_ELEMENT_SELECTOR,
  templateUrl: './knowledge_assist.ng.html',
  styleUrls: ['./knowledge_assist.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: UI_MODULE_ICONS, useValue: KNOWLEDGE_ASSIST_ICONS},
    UiModuleIconService,
    UiModuleStore,
  ],
})
export class KnowledgeAssist implements OnInit, OnChanges, OnDestroy {
  /**
   * Comma-separated list of Knowledge Assist features that should be rendered
   * (ARTICLE_SUGGESTION, FAQ, and/or ARTICLE_SEARCH).
   */
  @Input() features = '';

  /** Optional configurations for Knowledge Assist module. */
  @Input() config?: KnowledgeAssistConfig;

  @HostBinding('class.agent-assist-document-faq-assist--search-active')
  searchActive = false;

  readonly conversation$ =
      this.store.selectForActiveConversation(state => state.conversation);
  readonly suggestions$: Observable<ArticleOrFaqAnswers> =
      this.store.selectForActiveConversation(state => {
        return {
          conversationName: state.conversation?.name!,
          payload: state.suggestions
        };
      });
  readonly answerRecords$ =
      this.store.selectForActiveConversation(state => state.answerRecords);
  readonly articleSearchResults$ = this.store.selectForActiveConversation(
      state => state.articleSearchResults);
  readonly articleSearchLoadingState$ = this.store.selectForActiveConversation(
      state => state.articleSearchLoadingState);

  private readonly destroyed$ = new ReplaySubject<void>(1);

  /** Configuration for how articles should open in the user's browser. */
  get articleLinkConfig() {
    return this.config?.articleLinkConfig || DEFAULT_ARTICLE_LINK_CONFIG;
  }

  get featuresArray() {
    return this.features.split(/\s*\,\s*/) as SuggestionFeature_Type[];
  }

  constructor(
      notificationService: NotificationService,
      uiModuleIconService: UiModuleIconService,
      private readonly store: UiModuleStore<State>,
  ) {
    this.store.init(initialState);
    uiModuleIconService.initModule();
    notificationService.initModule();
  }

  ngOnInit() {
    fromUiModuleEvent(UiModuleEvent.ANALYZE_CONTENT_RESPONSE_RECEIVED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName, payload: {response}}) => {
          const articleSuggestions =
              getArticleSuggestionsWithCreateTimeFromAnalyzeContentResponse(
                  response);
          const faqSuggestions =
              getFaqSuggestionsWithCreateTimeFromAnalyzeContentResponse(
                  response);

          this.store.setStateForConversation(
              conversationName, state => ({
                                  ...state,
                                  suggestions: dedupeDocumentAndFaqSuggestions([
                                    ...state.suggestions,
                                    ...articleSuggestions,
                                    ...faqSuggestions,
                                  ])
                                }));
        });

    fromUiModuleEvent(UiModuleEvent.CONVERSATION_INITIALIZED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversation}) => {
          this.store.setStateForConversation(
              conversation.name!, state => ({...state, conversation}));
        });

    fromUiModuleEvent(UiModuleEvent.PATCH_ANSWER_RECORD_RECEIVED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName, payload: answerRecord}) => {
          this.store.setStateForConversation(
              conversationName, state => ({
                                  ...state,
                                  answerRecords: {
                                    ...state.answerRecords,
                                    [answerRecord.name!]: answerRecord
                                  },
                                }));
        });

    fromUiModuleEvent(UiModuleEvent.ARTICLE_SEARCH_REQUESTED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.store.setStateForActiveConversation(
              state => ({...state, articleSearchLoadingState: 'LOADING'}));
        });

    fromUiModuleEvent(UiModuleEvent.ARTICLE_SEARCH_RESPONSE_RECEIVED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName, payload: response}) => {
          this.store.setStateForConversation(
              conversationName, state => ({
                                  ...state,
                                  articleSearchResults: response.articleAnswers,
                                  articleSearchLoadingState: 'LOADED'
                                }));
        });

    fromUiModuleErrorEvent('ARTICLE_SEARCH')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName}) => {
          this.store.setStateForConversation(
              conversationName,
              state => ({...state, articleSearchLoadingState: 'ERROR'}));
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    const features =
        changes['features']?.currentValue as SuggestionFeature_Type[] |
        undefined;

    if (features) {
      this.searchActive = features.includes('ARTICLE_SEARCH');
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleRegisterAgentFeedback({answerRecord, previousAnswerRecord, updateMask}:
                                  DocumentOrFaqFeedback) {
    dispatchUiModuleEvent(UiModuleEvent.PATCH_ANSWER_RECORD_REQUESTED, {
      detail:
          {payload: {answerRecord, previousAnswerRecord}, options: {updateMask}}
    });
  }

  handleSelectArticle({answer, clickTime, displayTime}: ArticleSelection) {
    const answerRecord: AnswerRecord_ = {
      'name': answer.answerRecord,
      'answerFeedback': {
        'clicked': true,
        'clickTime': clickTime,
        'displayed': true,
        'displayTime': displayTime,
      }
    };

    dispatchUiModuleEvent(UiModuleEvent.PATCH_ANSWER_RECORD_REQUESTED, {
      detail: {
        payload: {answerRecord},
        options: {
          updateMask:
              'answerFeedback.clicked,answerFeedback.clickTime,answerFeedback.displayed,answerFeedback.displayTime'
        }
      }
    });
  }

  handleSearchArticles(queryText: string) {
    dispatchUiModuleEvent(
        UiModuleEvent.ARTICLE_SEARCH_REQUESTED, {detail: {queryText}});
  }
}
