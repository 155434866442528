import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Directive, ElementRef, Inject, Input, OnInit, Optional, Self} from '@angular/core';
import {MatFabButton} from '@angular/material/mdc-button';
import {assert} from 'google3/third_party/javascript/closure/asserts/asserts';

import {GMAT_BUTTON_OPTIONS, GmatButtonOptions} from './button_options';

type GmatFabAppearance = 'outline'|'fill'|'branded';

/** Directive that applies Google Material styles to the MDC MatButton. */
@Directive({
  selector: `
      button[mat-button], a[mat-button],
      button[mat-raised-button], a[mat-raised-button],
      button[mat-flat-button], a[mat-flat-button],
      button[mat-stroked-button], a[mat-stroked-button],
      button[mat-icon-button], a[mat-icon-button],
  `,
  host: {
    '[class.gmat-mdc-button]': '!options.disableGmatStyle',
  },
})
export class GmatButton implements OnInit {
  constructor(
      private readonly elementRef: ElementRef,
      @Inject(GMAT_BUTTON_OPTIONS) readonly options: GmatButtonOptions) {}

  ngOnInit() {
    // Google Material theme has extra padding for buttons without icons
    if (this.elementRef.nativeElement.querySelector(
            '.mat-icon, .material-icons')) {
      this.elementRef.nativeElement.classList.add(
          'gmat-mdc-button-with-prefix');
    }
  }
}

/**
 * Directive that applies Google Material styles to the MDC MatButton FABs.
 */
@Directive({
  selector: `
      button[mat-fab], a[mat-fab],
      button[mat-mini-fab], a[mat-mini-fab]
  `,
  host: {
    '[class.gmat-mdc-button]': '!options.disableGmatStyle',
    '[class.gmat-mdc-fab-fill]': 'appearance == "fill"',
    '[class.gmat-mdc-fab-outline]': 'appearance == "outline"',
    '[class.gmat-mdc-fab-branded]': 'appearance == "branded"',
    '[class.gmat-mdc-fab-lowered]': 'lowered'
  },
})
export class GmatFab implements OnInit {
  constructor(
      @Inject(GMAT_BUTTON_OPTIONS) readonly options: GmatButtonOptions,
      @Optional() @Self() private readonly button?: MatFabButton) {}

  ngOnInit() {
    assert(
        !(this.button?.extended && this.lowered),
        'Lowered extended FABs are not supported');
  }

  /** The FAB appearance style. */
  @Input() appearance: GmatFabAppearance = 'outline';

  /** Whether the FAB's elevation should be lowered. */
  @Input()
  get lowered(): boolean {
    return this.loweredInternal;
  }
  set lowered(value: boolean) {
    this.loweredInternal = coerceBooleanProperty(value);
  }

  private loweredInternal = false;
}
