import {from} from 'rxjs';
import {debounce} from 'rxjs/operators';

/**
 * Debounce state updates within the same VM turn. This prevents multiple
 * requests from being triggered by separate updates to UI state that happen in
 * immediate succession. Debounced actions will trigger before the next change
 * detection cycle (rather than triggering an additional one).
 */
export function coalesceUpdatesWithinMicrotask<T>() {
  return debounce<T>(() => from(Promise.resolve(undefined)));
}
