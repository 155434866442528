import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {LetDirectiveModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/let/let_directive_module';
import {RichTooltipModule} from 'google3/java/com/google/dialogflow/console/web/common/rich_tooltip/rich_tooltip_module';
import {XapDialogLayoutModule} from 'google3/javascript/angular2/components/dialog_layout/dialog_layout_module';
import {GmatChipsModule} from 'google3/javascript/angular2/components/gmat/mdc_chips/chips_module';
import {XapInlineDialogModule} from 'google3/javascript/angular2/components/inline_dialog/inline_dialog_module';

import {SmartReply} from './smart_reply';

@NgModule({
  imports: [
    CommonModule,
    GmatChipsModule,
    LetDirectiveModule,
    MatTooltipModule,
    RichTooltipModule,
    XapDialogLayoutModule,
    XapInlineDialogModule,
  ],
  declarations: [
    SmartReply,
  ],
  exports: [
    SmartReply,
  ],
})
export class SmartReplyModule {
}
