<div
  *ngIf="dialogflowAssistHeadIntent"
  [class.head-intent--disabled]="headIntentDisabled"
  class="head-intent"
  role="button"
  tabindex="{{index}}"
>
  <button
    (click)="!headIntentDisabled && handleChooseHeadIntent()"
    class="head-intent__details-wrapper"
  >
    <div class="head-intent__details">
      <h4 class="gmat-headline-6 head-intent__display-name">
        {{getIntentDisplayName(dialogflowAssistHeadIntent.dialogflowAssistAnswer)}}
      </h4>

      <p class="gmat-body-2 head-intent__description">
        {{getIntentDescription(dialogflowAssistHeadIntent.dialogflowAssistAnswer)}}
      </p>
    </div>

    <div class="head-intent__right-arrow">
      <mat-icon svgIcon="chevron_right"></mat-icon>
    </div>
  </button>
  <div *ngIf="debugMode" class="head-intent__options">
    <button
      (click)="handleExpandOptions()"
      aria-labelledby="describe-options"
      data-test-id="options-link"
      class="gmat-body-2"
    >
      <span id="describe-options">Options</span>

      <mat-icon [svgIcon]="optionsToggleIcon"></mat-icon>
    </button>

    <div [@revealExpand]="optionsExpanded ? 'expanded' : 'collapsed'">
      <mat-form-field
        appearance="outline"
        data-test-id="override-session-parameters-form-field"
      >
        <mat-label>Override session parameters</mat-label>

        <textarea
          [formControl]="overriddenSessionParameters"
          aria-label="Override session parameters"
          data-test-id="override-session-parameters-input"
          matInput
          cdkTextareaAutosize
        ></textarea>

        <mat-hint
          >JSON object containing the session parameters to override</mat-hint
        >
        <mat-error>{{overriddenSessionParametersErrorMessage}}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
