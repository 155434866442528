
/**
 * Map of regular expressions used to extract data from URLs.
 */
export const URL_REGEX_MAP = {
  agent: /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?agent/,
  answerRecords:
      /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?answerRecords\/[^/]+/,
  bigqueryDatasets: /^projects\/[^/]+\/datasets\/[^/]+/,
  bigqueryKmsKeys:
      /(^projects\/[^\/]+\/locations\/[^\/]+\/keyRings\/[^\/]+\/cryptoKeys\/[^\/]+\/cryptoKeyVersions\/[^\/]+$)|(^projects\/[^\/]+\/locations\/[^\/]+\/keyRings\/[^\/]+\/cryptoKeys\/[^\/]+$)/,
  annotations:
      /^projects\/[^/]+\/locations\/[^/]+\/conversationDatasets\/[^/]+\/conversationDataItems\/[^/]+\/conversationAnnotations\/[^/]+/,
  conversationProfiles:
      /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?conversationProfiles\/[^/]+/,
  conversations:
      /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?conversations\/[^/]+/,
  datasets:
      /^projects\/[^\/]+\/(?:locations\/[^\/]+\/)?conversationDatasets\/[^\/]+/,
  dataItems:
      /^projects\/[^/]+\/locations\/[^/]+\/conversationDatasets\/[^/]+\/conversationDataItems\/[^/]+/,
  documents:
      /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?knowledgeBases\/[^/]+\/documents\/[^/]+/,
  evaluations:
      /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?conversationModels\/[^/]+\/evaluations\/[^/]+/,
  featureCreationFlows:
      /^projects\/[^\/]+\/locations\/[^\/]+\/featureCreationFlows\/[^\/]+/,
  knowledgeBases:
      /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?knowledgeBases\/[^/]+/,
  locations: /^projects\/[^/]+\/locations/,
  models:
      /^projects\/[^\/]+\/(?:locations\/[^\/]+\/)?conversationModels\/[^\/]+/,
  participants:
      /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?conversations\/[^/]+\/participants\/[^/]+/,
  project: /^projects\/[^/]+/,
  projectLocation: /^projects\/[^/]+\/locations\/[^/]+/,
  routePrefix: /^\/projects\/[^\/]+(\/locations\/[^\/]+)?/,
  services: /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?services\/[^/]+/,
  securitySettings:
      /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?securitySettings\/[^/]+/,
  smartMessagingEntry:
      /^projects\/[^/]+\/(?:locations\/[^\/]+\/)?knowledgeBases\/[^/]+\/documents\/[^/]+\/smartMessagingEntries\/[^/]+/,
  v2AndV3agent:
      /^projects\/[^/]+\/(?:locations\/[^/]+\/)?(agents\/[^/]+|agent)/,
  v2SecuritySettings: /^projects\/[^/]+\/securitySettings/,
};
