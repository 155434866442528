import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/mdc-button';
import {MatProgressSpinnerModule} from '@angular/material/mdc-progress-spinner';
import {SetClassOnScrollModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/set_class_on_scroll/set_class_on_scroll_module';
import {XapClipboardModule} from 'google3/javascript/angular2/components/clipboard/clipboard_module';
import {XapLetModule} from 'google3/javascript/angular2/components/let/xap_let_module';

import {ConversationGuidance} from './conversation_guidance';

@NgModule({
  declarations: [
    ConversationGuidance,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    SetClassOnScrollModule,
    XapClipboardModule,
    XapLetModule,
  ],
  exports: [
    ConversationGuidance,
  ],
})
export class ConversationGuidanceModule {
}
