import {ConfigurableFocusTrap, ConfigurableFocusTrapFactory} from '@angular/cdk/a11y';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable, Injector, NgZone, OnDestroy} from '@angular/core';
import {AccessibleModalOverlayManager} from 'google3/java/com/google/dialogflow/console/web/common/rich_tooltip/helpers/accessible_modal_overlay_manager';
import {ConnectedOverlayHelper} from 'google3/java/com/google/dialogflow/console/web/common/rich_tooltip/helpers/connected_overlay_helper';
import {RICH_TOOLTIP_DISABLED_CSS_CLASS} from 'google3/java/com/google/dialogflow/console/web/common/rich_tooltip/rich_tooltip_constants';
import {RichTooltipOverlay} from 'google3/java/com/google/dialogflow/console/web/common/rich_tooltip/rich_tooltip_overlay';
import {RichTooltipPosition} from 'google3/java/com/google/dialogflow/console/web/common/rich_tooltip/rich_tooltip_position';
import {getFallbackPositions} from 'google3/java/com/google/dialogflow/console/web/common/rich_tooltip/rich_tooltip_position_utils';
import {RichTooltipRef} from 'google3/java/com/google/dialogflow/console/web/common/rich_tooltip/rich_tooltip_ref';
import {assert, assertExists} from 'google3/javascript/typescript/contrib/assert';
import {contains, getAncestor, getAncestorByClass} from 'google3/third_party/javascript/closure/dom/dom';
import {Keys} from 'google3/third_party/javascript/closure/events/keys';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

type DisableableElement =
    HTMLInputElement|HTMLButtonElement|HTMLSelectElement|HTMLTextAreaElement|
    HTMLOptGroupElement|HTMLOptionElement|HTMLFieldSetElement;

/**
 * Number of milliseconds to wait before hiding the tooltip on mouseleave.
 *
 * This prevents flickering when a user moves the mouse from the host to the
 * tooltip or vice versa.
 */
export const RICH_TOOLTIP_HIDE_DELAY_MS = 100;

interface TooltipTuple {
  /** Tooltip to show when the host element is enabled. */
  enabledTooltip?: RichTooltipRef;

  /** Tooltip to show when the host element is disabled. */
  disabledTooltip?: RichTooltipRef;

  /** Subscription to disabled state changes on the host element. */
  disableChangeSubscription?: Subscription;
}

/**
 * Singleton service that listens to document level events to show tooltips.
 * By not registering event handlers per element, this singleton service
 * increases performance of the page when page contains many tooltips. This
 * service is simply a port of jfk TooltipManager:
 * http://google3/javascript/jfk/tooltip/tooltipmanager.js?l=539&rcl=189629924
 */
@Injectable({providedIn: 'root'})
export class RichTooltipManager implements OnDestroy, EventListenerObject {
  /**
   * The ConfigurableFocusTrap instance that prevents mouse and keyboard focus
   * outside of the tooltip overlay.
   */
  private focusTrap: ConfigurableFocusTrap|undefined = undefined;

  /**
   * A variable that maps DOM elements to RichTooltipRef objects. This allows us
   * quickly check if a certain DOM node has a tooltip attached.
   */
  private readonly tooltips = new Map<Node, TooltipTuple>();

  /**
   * Reference to the overlay. When a user hovers out of an element with tooltip
   * we use this reference to detach and dispose the overlay.
   */
  private overlayRef: OverlayRef|undefined = undefined;

  /**
   * This is used to prevent flickering when a user moves the mouse from the
   * element that triggered the tooltip to the tooltip or vice versa.
   */
  private tooltipHideTimeoutId = 0;

  /**
   * This is used to prevent multiple renders of tooltips when render debounces
   * are applied.
   */
  private tooltipRenderTimeoutId = 0;

  /**
   * This should reference the tooltip that is currently visible. If undefined,
   * there are no tooltips that are visible.
   */
  private visibleTooltipRef: RichTooltipRef|undefined = undefined;

  /** Triggered when this component is destroyed */
  private readonly destroyed = new ReplaySubject<void>(1);

  /** A backdrop element created by this tooltip */
  private backdrop: HTMLElement|undefined = undefined;

  /** Document-level events to listen to. */
  private readonly eventsToListen: string[] =
      ['mouseover', 'mouseout', 'focusin', 'focusout', 'click', 'keydown'];

  /**
   * When this singleton service is first constructed, start listening to mouse
   * events to show/hide tooltips
   */
  constructor(
      private readonly overlay: Overlay,
      private readonly connectedOverlayHelper: ConnectedOverlayHelper,
      private readonly accessibleModalManager: AccessibleModalOverlayManager,
      private readonly focusTrapFactory: ConfigurableFocusTrapFactory,
      private readonly ngZone: NgZone,
  ) {
    ngZone.runOutsideAngular(() => {
      for (const eventType of this.eventsToListen) {
        document.addEventListener(eventType, this);
      }
    });
  }

  /**
   * Associates a tooltip with an html element in its enabled state.
   *
   * This should be called when tooltip @Directive that carries the inputs is
   * constructed. By registering the reference to the internal map, we can
   * handle document level events and show tooltips when necessary.
   */
  registerTooltipForEnabledState(ref: RichTooltipRef): void {
    const node = ref.elementRef.nativeElement;
    const tuple: TooltipTuple = this.tooltips.get(node) || {};
    tuple.enabledTooltip = ref;
    this.tooltips.set(node, tuple);
  }

  /**
   * Associates a tooltip with an html element in its disabled state.
   *
   * This should be called when tooltip @Directive that carries the inputs is
   * constructed. By registering the reference to the internal map, we can
   * handle document level events and show tooltips when necessary.
   */
  registerTooltipForDisabledState(
      ref: RichTooltipRef, isDisabledChange: Observable<boolean>): void {
    const node = ref.elementRef.nativeElement;
    const tuple: TooltipTuple = this.tooltips.get(node) || {};
    tuple.disabledTooltip = ref;
    tuple.disableChangeSubscription =
        isDisabledChange.subscribe((isDisabled: boolean) => {
          // Handles the case where the tooltip host becomes enabled but the
          // disabledTooltip is still visible.
          if (!isDisabled && this.visibleTooltipRef === ref) {
            this.hideTooltip();
          }
        });
    this.tooltips.set(node, tuple);
  }

  /**
   * Deregisters a tooltip associated with an html element in its enabled state.
   * This should be called when tooltip @Directive that carries the inputs is
   * destroyed so that we can stop trying to show a tooltip for this element.
   */
  deregisterTooltipForEnabledState(ref: RichTooltipRef): void {
    this.deregisterTooltip(ref, /* isDisabledState */ false);
  }

  /**
   * Deregisters a tooltip associated with an html element in its disabled
   * state. This should be called when tooltip @Directive that carries the
   * inputs is destroyed so that we can stop trying to show a tooltip for this
   * element.
   */
  deregisterTooltipForDisabledState(ref: RichTooltipRef): void {
    this.deregisterTooltip(ref, /* isDisabledState */ true);
  }

  /** When service is destroyed, unbind the document level event listeners. */
  ngOnDestroy(): void {
    for (const tuple of this.tooltips.values()) {
      if (!!tuple.disabledTooltip) {
        const disableChangeSubscription =
            assertExists(tuple.disableChangeSubscription);
        disableChangeSubscription.unsubscribe();
      }
    }

    for (const eventType of this.eventsToListen) {
      document.removeEventListener(eventType, this);
    }

    this.destroyFocusTrap();

    this.destroyed.next();
    this.destroyed.complete();
  }

  private deregisterTooltip(ref: RichTooltipRef, isDisabledState: boolean):
      void {
    if (this.visibleTooltipRef === ref) {
      this.hideTooltip();
    }

    const el = ref.elementRef?.nativeElement;
    if (el == null) return;

    if (this.tooltips.has(el)) {
      const tuple = this.tooltips.get(el)!;
      if (isDisabledState) {
        const disableChangeSubscription =
            assertExists(tuple.disableChangeSubscription);
        disableChangeSubscription.unsubscribe();
        tuple.disabledTooltip = undefined;
      } else {
        tuple.enabledTooltip = undefined;
      }

      if (!tuple.disabledTooltip && !tuple.enabledTooltip) {
        this.tooltips.delete(el);
      } else {
        this.tooltips.set(el, tuple);
      }
    }
  }

  /**
   * Given an event, this function determines the tooltip associated with the
   * event target. If none found, returns undefined.
   */
  private getListenerFor(event: Event): RichTooltipRef|undefined {
    const node = getAncestor(event.target as Node, (node: Node|null) => {
      if (!node) return false;
      const isDisabled = this.isDisabled(node);
      if (this.tooltips.has(node)) {
        const tuple = this.tooltips.get(node)!;
        return (!isDisabled && tuple.enabledTooltip !== undefined) ||
            (isDisabled && tuple.disabledTooltip !== undefined);
      }
      return false;
    }, true);
    if (!node) {
      return undefined;
    }

    const tuple = this.tooltips.get(node)!;
    return this.isDisabled(node) ? tuple.disabledTooltip : tuple.enabledTooltip;
  }

  private isDisabled(node: Node): boolean {
    return (node as DisableableElement).disabled ||
        (node instanceof Element &&
         ((node.getAttribute('aria-disabled') === 'true') ||
          (node.classList.contains(RICH_TOOLTIP_DISABLED_CSS_CLASS))));
  }

  /** Event handler for all registered events. */
  handleEvent(event: Event): void {
    // If user clicks on backdrop, we hide the tooltip.
    if (this.visibleTooltipRef && this.visibleTooltipRef.modal &&
        event.type === 'click' &&
        (event.target as HTMLElement)
            .classList.contains('cdk-overlay-backdrop')) {
      this.ngZone.run(() => {
        this.hideTooltip();
      });
      return;
    }

    // If the event target is within a currently visible tooltip, call the
    // handler for that.
    if (this.isElementWithinTooltip(event.target!)) {
      this.ngZone.run(() => {
        this.handleTooltipOverlayEvent(event);
      });
      return;
    }

    // Is the user hovering over an element that has a tooltip?
    const richTooltipRef: RichTooltipRef|undefined = this.getListenerFor(event);
    if (richTooltipRef) {
      this.ngZone.run(() => {
        this.handleTooltipRefEvent(event, richTooltipRef);
      });
      return;
    }

    if (this.visibleTooltipRef && event.type === 'click' &&
        !this.visibleTooltipRef.modal) {
      // At this point in execution, we know the click event was not triggered
      // on tooltip overlay or an element that contains a tooltip. And we are
      // displaying a tooltip. Normally when clicking outside, the element will
      // lose focus, or there will be mouseout event, both of which will dismiss
      // the tooltip. But for some touch devices, this does not trigger
      // focus/blur events on button. Therefore, we need to handle document
      // click to dismiss the tooltip.
      this.ngZone.run(() => {
        this.hideTooltip();
      });
      return;
    }
  }

  /**
   * This should be called for events that were triggered on an element that
   * contains a tooltip.
   */
  private handleTooltipRefEvent(event: Event, richTooltipRef: RichTooltipRef):
      void {
    if (event.type === 'mouseout') {
      this.handleMouseoutOnTooltipRef(richTooltipRef);
    } else if (event.type === 'mouseover' || event.type === 'focusin') {
      this.showTooltip(richTooltipRef, event);
    } else if (event.type === 'focusout') {
      this.handleFocusOutOnTooltipRef(event as FocusEvent, richTooltipRef);
    } else if (
        richTooltipRef.isRichTooltipInteractive && event.type === 'click' ||
        (event.type === 'keydown' &&
         (event as KeyboardEvent).key === Keys.ENTER)) {
      this.handleClickOnTooltipRef(richTooltipRef, event);
    }
  }

  /**
   * Handles click events on interactive tooltips. When user clicks on an
   * interactive tooltip, the tooltip should become modal.
   */
  private handleClickOnTooltipRef(richTooltipRef: RichTooltipRef, event: Event):
      void {
    if (!this.canShowTooltip(richTooltipRef)) {
      return;
    }

    if (!this.visibleTooltipRef) {
      // The user might have just closed this interactive tooltip. In that case
      // if the user clicks on the trigger element, the tooltip will not be open
      // Just re-open the tooltip for that case.
      this.showTooltip(richTooltipRef, event);
    }

    this.makeTooltipModal();
  }

  /** Makes the existing Tooltip overlay modal */
  private makeTooltipModal(): void {
    const visibleTooltipRef: RichTooltipRef =
        assert(this.visibleTooltipRef, 'Expected a visible tooltip!');
    const overlayRef = assert(this.overlayRef);

    // TODO(b/188553865): Currently this method is called twice when the tooltip
    // is dismissed with an "Enter" press. Once with a keyboard keydown event
    // (expected) and once with a mouse click event (unexpected). This means
    // this handler is called twice and we make the tooltip modal twice. This is
    // unnecessary and means we are pushing the overlay onto the modalManager
    // twice (and only popping it once) which is problematic. For now just bail
    // if we've already executed this but in the future we should figure out
    // what is causing this mouse click event.
    if (visibleTooltipRef.modal) {
      return;
    }

    visibleTooltipRef.modal = true;
    visibleTooltipRef.onChange.next();

    this.accessibleModalManager.pushOverlay(overlayRef);
    this.insertOverlayBackdrop();

    // There should never be a FocusTrap at this point, because
    // makeTooltipModal should only be called once per tooltip. But if there is,
    // we need to destroy it before creating another, so that FocusTrapManager's
    // stack is in the correct state.
    this.destroyFocusTrap();
    this.focusTrap = this.focusTrapFactory.create(overlayRef.hostElement);

    // In case the tooltip was just constructed, we need to wait a cycle before
    // we can find the first tabbable element.
    setTimeout(() => {
      this.focusTrap?.focusFirstTabbableElementWhenReady();
    });
  }

  /**
   * Handles mouseout events on the tooltip trigger. Note that we hide the
   * tooltip immediately
   */
  private handleMouseoutOnTooltipRef(richTooltipRef: RichTooltipRef): void {
    // Modal tooltips can not be closed by mouseout events.
    if (richTooltipRef.modal) {
      return;
    }

    this.startHiding();
  }

  /**
   * Given an event target, this function returns true if the element is within
   * tooltip overlay component.
   */
  private isElementWithinTooltip(element: EventTarget): boolean {
    return !!this.overlayRef &&
        contains(this.overlayRef.hostElement, element as Node);
  }

  /**
   * This should be called for 'focusout' events that were triggered on an
   * element that contains a tooltip.
   */
  private handleFocusOutOnTooltipRef(
      event: FocusEvent, richTooltipRef: RichTooltipRef): void {
    // If the focus is moving from the element that contains the tooltip to an
    // element within tooltip, don't hide the currently visible tooltip.
    if (!this.isElementWithinTooltip(event.relatedTarget!)) {
      this.hideTooltip();
    }
  }

  /**
   * This should be called for events that were triggered on visible tooltip
   * overlay.
   */
  private handleTooltipOverlayEvent(event: Event): void {
    // If the user is hovering over the tooltip, make sure to not close the
    // tooltip.
    if (event.type === 'mouseover') {
      clearTimeout(this.tooltipHideTimeoutId);
    } else if (
        event.type === 'mouseout' &&
        !(this.visibleTooltipRef && this.visibleTooltipRef.modal)) {
      this.startHiding();
    } else if (
        event.type === 'click' ||
        (event.type === 'keydown' &&
         (event as KeyboardEvent).key === Keys.ENTER)) {
      this.handleTooltipOverlayInteraction(event);
    }
  }

  /**
   * If the user has interacted with the close button within the tooltip
   * overlay, then hide the tooltip immediately.
   */
  private handleTooltipOverlayInteraction(event: Event): void {
    const target = event.target as HTMLElement;

    if (getAncestorByClass(target, 'rich-tooltip-close-button')) {
      // Prevents tooltip from reopening as otherwise it causes richTooltipRef
      // to fire a click event in some cases.
      event.preventDefault();
      event.stopPropagation();

      this.hideTooltip();
    }
  }

  /**
   * We allow 100ms tolerance for "hide" requests. Note that user can move
   * mouse from tooltip trigger to overlay. In this case, we get "mouseout"
   * event for tooltip trigger, but it doesn't mean we should destroy the
   * tooltip, because next event we receive will be a "mouseover" event on the
   * tooltip overlay.
   */
  private startHiding(): void {
    clearTimeout(this.tooltipHideTimeoutId);
    this.tooltipHideTimeoutId = setTimeout(() => {
      this.hideTooltip();
    }, RICH_TOOLTIP_HIDE_DELAY_MS);
  }


  private canShowTooltip(richTooltipRef: RichTooltipRef): boolean {
    return !richTooltipRef.richTooltipDisabled && !!richTooltipRef.content;
  }

  /** Shows the tooltip with a given ref. */
  private showTooltip(richTooltipRef: RichTooltipRef, event: Event): void {
    if (!this.canShowTooltip(richTooltipRef)) {
      return;
    }

    // Don't try to hide stuff while we want to show something.
    clearTimeout(this.tooltipHideTimeoutId);

    // If we are trying to show a tooltip that is already visible, return.
    if (this.overlayRef && this.visibleTooltipRef === richTooltipRef) {
      return;
    } else if (this.overlayRef) {
      // Above if statement makes sure at this point in the execution we have
      // a stale tooltip.
      this.hideTooltip();
    }

    if (richTooltipRef.richTooltipRenderDebounceMs > 0) {
      clearTimeout(this.tooltipRenderTimeoutId);
      this.tooltipRenderTimeoutId = setTimeout(() => {
        this.renderTooltip(richTooltipRef, event);
      }, richTooltipRef.richTooltipRenderDebounceMs);
    } else {
      this.renderTooltip(richTooltipRef, event);
    }
  }

  private renderTooltip(richTooltipRef: RichTooltipRef, event: Event): void {
    // Let our tooltip know that it was opened.
    richTooltipRef.onShow.next();

    // This will try rendering the tooltip starting with the specified side and
    // falling back to the opposite side and then to the remaining two sides.
    const currentPosition: RichTooltipPosition =
        richTooltipRef.richTooltipPosition;
    const fallbackPositions = getFallbackPositions(currentPosition);
    const config = this.connectedOverlayHelper.createFlexibleOverlayConfig(
        richTooltipRef.elementRef, fallbackPositions);

    this.overlayRef = this.overlay.create(config);

    // These values will be injected to TooltipOverlay component.
    // TooltipOverlay component will use these values to render the tooltip.
    const injector: Injector = Injector.create({
      providers: [
        {provide: 'richTooltipRef', useValue: richTooltipRef},
        {provide: 'triggerElement', useValue: event.target as HTMLElement},
      ]
    });

    this.overlayRef.attach(new ComponentPortal(
        RichTooltipOverlay, undefined /** ViewContainerRef */, injector));

    this.overlayRef.keydownEvents()
        .pipe(takeUntil(this.destroyed))
        .subscribe((event: KeyboardEvent) => {
          if (event.key !== Keys.ESCAPE) return;
          this.hideTooltip();
        });

    this.visibleTooltipRef = richTooltipRef;
  }

  /** Inserts a backdrop for interactive modal tooltips. */
  private insertOverlayBackdrop(): void {
    const backdropDiv = document.createElement('div');
    backdropDiv.classList.add('cdk-overlay-backdrop');
    backdropDiv.classList.add('cdk-overlay-dark-backdrop');
    backdropDiv.classList.add('cdk-overlay-backdrop-showing');
    const hostEl = this.overlayRef!.hostElement;
    hostEl.insertBefore(backdropDiv, hostEl.firstChild);
    this.backdrop = backdropDiv;
  }

  /** Removes the overlay backdrop that was inserted for a modal tooltip. */
  private removeOverlayBackdrop(): void {
    if (this.backdrop && this.backdrop.parentNode) {
      this.backdrop.parentNode.removeChild(this.backdrop);
      this.backdrop = undefined;
    }
  }

  /** Destroys the tooltip by disposing overlay reference. */
  private hideTooltip(): void {
    if (!this.overlayRef) {
      assert(
          !this.visibleTooltipRef,
          'Expected visibleTooltipRef to be undefined since overlayRef ' +
              'was undefined');
      return;
    }

    const richTooltipRef = this.visibleTooltipRef;

    // If we are closing a modal tooltip, we need to do some more cleanup. Note
    // that modal tooltips have backdrop.
    if (richTooltipRef && richTooltipRef.modal) {
      this.removeOverlayBackdrop();

      // Should only become modal after user clicks on it. Not by default.
      richTooltipRef.modal = false;
      this.accessibleModalManager.popOverlay(this.overlayRef);

      this.destroyFocusTrap();

      // Once the hiding is complete, bring the focus back to trigger. This is
      // needed to comply with modal dialog a11y spec.
      if (richTooltipRef.focusableElementRef) {
        // Restore focus to the specified element if it is provided.
        richTooltipRef.focusableElementRef.nativeElement.focus();
      } else {
        richTooltipRef.elementRef.nativeElement.focus();
      }
    }

    // Let our tooltip know that it was disposed.
    if (richTooltipRef) {
      richTooltipRef.onHide.next();
    }

    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }

    this.overlayRef = undefined;
    this.visibleTooltipRef = undefined;
  }

  private destroyFocusTrap(): void {
    if (this.focusTrap) {
      this.focusTrap.destroy();
      this.focusTrap = undefined;
    }
  }
}
