<span class="simple-snackbar-message">{{data.message}}</span>
<div *ngIf="hasAction" class="mat-simple-snackbar-action">
  <button mat-button (click)="action()">{{data.action}}</button>
</div>
<div class="simple-snackbar-close">
  <button mat-icon-button aria-label="close" (click)="dismiss()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<span class="cdk-visually-hidden">
  Use {{keyName}} + F6 to jump to and from this notification
</span>
