import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/mdc-button';

import {RichTooltip} from './rich_tooltip';
import {RichTooltipOverlay} from './rich_tooltip_overlay';

@NgModule({
  entryComponents: [RichTooltipOverlay],
  declarations: [
    RichTooltip,
    RichTooltipOverlay,
  ],
  exports: [
    RichTooltip,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    OverlayModule,
  ],
})
export class RichTooltipModule {
}
