import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {SimpleSnackBar} from 'google3/java/com/google/dialogflow/console/web/common/system_messaging_service/simple_snack_bar/simple_snack_bar';

@NgModule({
  imports: [
    MatButtonModule,
    MatIconModule,
    CommonModule,
  ],
  declarations: [SimpleSnackBar],
  exports: [SimpleSnackBar]
})
export class SimpleSnackBarModule {
}
