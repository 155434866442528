import {AfterViewInit, ChangeDetectionStrategy, Component, Directive, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';

type CardFormAppearance = 'legacy'|'outline'|undefined;

/** Base card form component. Used to apply the same styles to multple forms. */
@Component({
  selector: 'card-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.card-form-appearance-legacy]': 'appearance === "legacy"',
    '[class.card-form-appearance-outline]': 'appearance === "outline"',
  },
  styleUrls: ['./card_form.css'],
  templateUrl: './card_form.ng.html',
})
export class CardForm implements AfterViewInit {
  @ViewChild('cardFormWrapper')
  readonly cardFormWrapper!: ElementRef<HTMLDivElement>;
  @Input() readonly alignCenter?: boolean;
  @Input()
  get appearance() {
    return this.cardFormAppearance;
  }
  set appearance(value: CardFormAppearance) {
    this.cardFormAppearance = value || 'legacy';
  }

  private cardFormAppearance?: CardFormAppearance;

  constructor(private readonly renderer: Renderer2) {}

  ngAfterViewInit() {
    if (this.alignCenter !== undefined && this.alignCenter !== false) {
      this.renderer.addClass(
          this.cardFormWrapper.nativeElement,
          'card-form__wrapper--align-center');
    }
  }
}

/** Card form header component. */
@Component({
  selector: 'card-form-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {'class': 'card-form__header'},
  templateUrl: 'card_form_header.ng.html',
})
export class CardFormHeader {
}

/** Card form section component. */
@Component({
  selector: 'card-form-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'card_form_section.ng.html',
})
export class CardFormSection implements OnInit {
  @HostBinding('class') classList = 'card-form__section';
  @Input() readonly divider?: boolean;
  @Input()
  set fullWidthContent(fullWidth: boolean) {
    if (fullWidth !== undefined && fullWidth !== false) {
      this.fullWidth = true;
    }
  }

  fullWidth = false;

  ngOnInit() {
    if (this.divider !== undefined && this.divider !== false) {
      this.classList += ' card-form__section-divider';
    }
  }
}

/** Card form actions component. */
@Component({
  selector: 'card-form-actions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {'class': 'card-form__actions'},
  templateUrl: 'card_form_actions.ng.html',
})
export class CardFormActions {
}

/** Card form title component. */
@Component({
  selector: 'card-form-title',
  host: {'class': 'card-form__title gmat-headline-2'},
  template: '<ng-content></ng-content>',
})
export class CardFormTitle {
}

/** Card form subtitle component. */
@Component({
  selector: 'card-form-subtitle',
  host: {'class': 'card-form__subtitle gmat-subhead-1'},
  template: '<ng-content></ng-content>',
})
export class CardFormSubitle {
}

/** Card form section title component. */
@Component({
  selector: 'card-form-section-title',
  host: {'class': 'card-form-section-title gmat-headline-6'},
  template: '<ng-content></ng-content>',
})
export class CardFormSectionTitle {
}

/** Card form section subtitle component. */
@Component({
  selector: 'card-form-section-subtitle',
  host: {'class': 'card-form-section-subtitle gmat-subtitle-2'},
  template: '<ng-content></ng-content>',
})
export class CardFormSectionSubtitle {
}

/** Card form section content description component. */
@Component({
  selector: 'card-form-content-description',
  host: {'class': 'card-form__card-form-content-description gmat-body-2'},
  template: '<ng-content></ng-content>',
})
export class CardFormContentDescription {
}

/** Card form section raw content component. */
@Component({
  selector: 'card-form-raw-content',
  host: {'class': 'card-form__raw-content'},
  template: '<ng-content></ng-content>',
})
export class CardFormRawContent {
}

/** Card form section content directive. */
@Directive({
  selector: 'card-form-content [cardFormContent]',
  host: {'class': 'card-form-content'},
})
export class CardFormContent {
}
