import {ModuleWithProviders, NgModule, Sanitizer} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser-unsafe';
import {DomSanitizerWithSafeValuesImpl} from 'google3/javascript/angular2/safehtml/safevalues_compatibility';

// tslint:disable-next-line:class-as-namespace it's an NgModule
@NgModule({
  exports: [
    BrowserModule,
  ],
  providers: [
    {provide: Sanitizer, useExisting: DomSanitizer},
    {provide: DomSanitizer, useClass: DomSanitizerWithSafeValuesImpl},
  ]
})
export class Google3BrowserModule {
  static withServerTransition(params: {appId: string}):
      ModuleWithProviders<BrowserModule> {
    return BrowserModule.withServerTransition(params);
  }
}

export * from '@angular/platform-browser-unsafe';
export {EVENT_MANAGER_PLUGINS, EventManagerPlugin} from 'google3/third_party/javascript/angular2/rc/packages/platform-browser/src/dom/events/event_manager';
export {Google3BrowserModule as BrowserModule};
