import {animate, AnimationTriggerMetadata, state, style, transition, trigger,} from '@angular/animations';
import {AnimationCurves} from '@angular/material/mdc-core';

/** Transition time of xapInlineDialog animations. */
export const ANIMATION_TRANSITION_TIME_MS = 200;

const HIDDEN_STYLE = style({opacity: 0});
const VISIBLE_STYLE = style({opacity: 1});

const ENTERING_TIMING =
    `${ANIMATION_TRANSITION_TIME_MS}ms ${AnimationCurves.DECELERATION_CURVE}`;
const LEAVING_TIMING =
    `${ANIMATION_TRANSITION_TIME_MS}ms ${AnimationCurves.STANDARD_CURVE}`;

/** Animations used by xapInlineDialog. */
export const XAP_INLINE_DIALOG_ANIMATIONS: {
  readonly dialogContainer: AnimationTriggerMetadata
} = {
  /** Animation that is applied on the dialog container by default. */
  dialogContainer: trigger(
      'dialogContainer',
      [
        state('void, hidden', HIDDEN_STYLE),
        state('visible', VISIBLE_STYLE),
        transition(
            'void => *, * => visible', animate(ENTERING_TIMING, VISIBLE_STYLE)),
        transition(
            '* => void, * => hidden', animate(LEAVING_TIMING, HIDDEN_STYLE)),
      ])
};
