import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {MatChipsDefaultOptions} from '@angular/material/chips';
import {MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBarConfig} from '@angular/material/snack-bar';
import {MatTooltipDefaultOptions} from '@angular/material/tooltip';
import {SupportedAgentAssistFeatures} from 'google3/java/com/google/dialogflow/console/web/ccai/common/types';
import {getFeatureVisibilityLabels} from 'google3/java/com/google/dialogflow/console/web/ccai/common/visibilities/visibilities';
import {AppConfig, COMMON_REGISTERED_ICONS, ConsoleApi, ConsoleNames, ConsoleRegion, DATA_LABELING_API_ENDPOINTS, DIALOGFLOW_API_ENDPOINTS, LocationId, Onboarding, ROOT_GSTATIC_URL, VisibilityLabels} from 'google3/java/com/google/dialogflow/console/web/common/constants/constants';
import {ExperimentFlagName} from 'google3/java/com/google/dialogflow/console/web/common/experiment_service/experiment_service';
import {LanguageCode} from 'google3/java/com/google/dialogflow/console/web/common/types/language_code_types';

export {MatDialogConfig};

/** Root Agent Assist GStatic URL. */
export const ROOT_AGENT_ASSIST_GSTATIC_URL = `${ROOT_GSTATIC_URL}/agent-assist`;

/** Agent Assist base documentation page. */
export const AGENT_ASSIST_DOCUMENTATION_PAGE =
    'https://cloud.google.com/solutions/contact-center';

/** Agent Assist Feedback product ID. Used in the Google Feedback console. */
export const AGENT_ASSIST_FEEDBACK_PRODUCT_ID = '5194549';

/** Trigger ID for the Agent Assist HaTs survey. */
export const AGENT_ASSIST_HATS_TRIGGER_ID = 'cZmJy41rU0sutSswYb10QN8VNd9S';

/** Agent Assist required API endpoints. */
export const CCAI_REQUIRED_APIS: ConsoleApi[] = [
  DIALOGFLOW_API_ENDPOINTS,
  DATA_LABELING_API_ENDPOINTS,
];

/** Number of previously fired actions to keep in DevTool. */
export const STORE_DEVTOOLS_MODULE = 25;

/** Default table filter debounce time (in milliseconds). */
export const TABLE_FILTER_DEBOUNCE_TIME = 1000;

/**
 * Navigation item.
 *
 * Used to render the main page links in the left sidenav.
 */
export interface NavigationItem {
  /** Display name of the navigation item. */
  name: string;
  /** MAT icon to display next to the item. */
  icon: string;
  /** Page link for the item. */
  link: string;
  /** Visibility labels that guard the visibility of the item. */
  visibility: VisibilityLabels[];
  /** Experiment flag that guards the visibility of the item. */
  experimentFlag?: ExperimentFlagName;
}

/** Left side navigation configuration. */
export const NAVIGATION_LIST: NavigationItem[] = [
  {
    name: 'Conversation profiles',
    icon: 'manage_accounts',
    link: 'conversation-profiles',
    visibility: [],
  },
  {
    name: 'AAM experiments',
    icon: 'science',
    link: 'aam/experiments',
    visibility: [],
    experimentFlag: ExperimentFlagName.AAM_EXPERIMENTS_ENABLED,
  },
  {
    name: 'Models',
    icon: 'article',
    link: 'models',
    visibility: getFeatureVisibilityLabels('MODELS'),
  },
  {
    name: 'Datasets',
    icon: 'list',
    link: 'data/datasets',
    visibility: [],
  },
  {
    name: 'Knowledge bases',
    icon: 'tips_and_updates',
    link: 'data/knowledge-bases',
    visibility: [],
  },
];

/** Icon list. */
export const ICON_LIST = [
  ...COMMON_REGISTERED_ICONS,
  'add',                  // create buttons
  'apps',                 // select project
  'article',              // models list page
  'auto_fix_high',        // simulator - dialogflow assist
  'auto_timer',           // feature creation flow - skipped stage
  'arrow_back',           // breadcrumb back arrow
  'arrow_forward',        // agent guidance - currently active
  'article',              // article search in simulator
  'badge',                // conversation profile basics - overview page
  'block',                // models list - undeploy
  'bug_report',           // create conversation profile - debug form
  'cached',               // models list - retry deploy
  'chat',                 // chat (open AAM transcript)
  'check_circle_filled',  // models details view - deployed
  'check_circle',         // models list - deploy
  'chevron_left',         // collapse sidebar
  'chevron_right',        // choose head intent
  'close',                // remove chip
  'computer',             // agent desktop basics - overview page
  'content_copy',         // conversation profile copy name
  'conversion_path',      // adding model to conversation profile tutorial -
                          // overview page
  'data_table',           // creating a dataset tutorial - overview page
  'delete',               // delete item
  'download',             // download knowledge base document
  'done',                 // create conversation profile stepper
  'donut_large',          // feature creation flow - not started stage
  'edit',                 // input unlock
  'error_filled_alt',     // Model failed
  'expand_less',          // status widget
  'expand_more',          // expand more
  'feedback',             // feedback button (OneGoogle bar)
  'file_copy',            // conversation profiles list 'duplicate' button
  'filter_list',          // table filter
  'folder',               // directory rendering in gcs selector table
  'forum',                // simulator chat log upload
  'fullscreen',           // gcs selector
  'fullscreen_exit',      // gcs selector
  'group',                // participants basics - overview page
  'headset_mic',          // human agent icon for chat input label
  'help',                 // generic tooltip
  'help_outline',         // models list page
  'home',                 // overview - side navigation
  'hourglass_empty',      // operations button in product control
  'hourglass_full',       // operations button in product control
  'info',                 // smart compose allowlist
  'input',                // send dialogflow assist response to chat input
  'keyboard_arrow_down',  // knowledge assist suggestion feature
  'lightbulb',            // agent guidance - stage transition hint
  'list',                 // datasets
  'magic_button',         // Smart Compose icon - overview page
  'message',              // transcripts count
  'model_training',       // model list - training
  'more_horiz',           // simulator - summarization collapsed messages
  'more_vert',            // models list - context menu
  'navigate_next',        // knowledge base breadcrumb '>' icon
  'open_in_new',          // open link in new tab
  'pentagon_filled',      // bullet point for Learn Agent Assist in side nav
  'person',               // end user icon for chat input label
  'phone',                // simulator - voice compatible conversation profile
  'picture_as_pdf',       // simulator - article suggestion icon
  'playlist_add',         // import
  'poll',                 // models
  'progress_activity',    // feature creation flow - in progress stage
  'question_answer',      // link to simulator
  'redo',                 // return to previous stage in feature creation flow
  'refresh',              // reload documents
  'restart_alt',          // restart feature creation flow
  'save',                 // simulator - save conversation summary
  'science',              // AAM experiments icon
  'search',               // table filter
  'search_off',           // no allowlist found
  'segment',              // smart compose allowlists
  'send',                 // simulator - chat input send button
  'settings',             // OneGooglebar - settings
  'smart_button',         // Smart Reply icon - overview page
  'smart_toy',            // simulator - virtual agent response
  'star',                 // model evaluation - recommended confidence threshold
  'support_agent',        // virtual agent assist icon - overview page
  'summarize',            // simulator - summarization button
  'test_clone',           // testing a conversation profile tutorial -
                          // overview page
  'tips_and_updates',     // knowledge bases in side navigation
  'thumb_down',           // Feedback in the simulator
  'thumb_up',             // Feedback in the simulator
  'thumb_up_filled',      // Feedback in the simulator
  'warning',              // models details view - undeployed
] as const;

/**
 * List of Agent Assist icons located in gstatic.
 * See:
 * http://google3/googledata/html/external_content/gstatic/dialogflow-console/common/assets/agent-assist/icons/
 */
export const CUSTOM_ICONS = [
  'dialogflow_cx_shaded',
  'storage_bucket',
];

/** String enum of icon types. */
export type Icon = typeof ICON_LIST[number];

/** Default pagination size */
export const DEFAULT_PAGE_SIZE = 10;

/** Material tooltip options */
export const MAT_TOOLTIP_OPTIONS: MatTooltipDefaultOptions = {
  showDelay: 50,
  hideDelay: 50,
  touchendHideDelay: 500,
  position: 'below',
};

/** Material chips options */
export const MAT_CHIPS_OPTIONS: MatChipsDefaultOptions = {
  separatorKeyCodes: [ENTER, COMMA, SPACE]
};

/** Material snackbar options */
export const MAT_SNACKBAR_OPTIONS: MatSnackBarConfig = {
  duration: Infinity,
  panelClass: 'notification-snack-bar'
};

/** Default Mat-Dialog configuration for Agent Assist. */
export const DEFAULT_CCAI_DIALOG_CONFIG: MatDialogConfig = {
  minWidth: '296px',
  maxWidth: '415px',
  panelClass: 'mat-elevation-z1',
};

/**
 * Default error message, if none is present on an API response (or when an
 * error is thrown in the app internally.)
 */
export const DEFAULT_ERROR_MESSAGE =
    `We're sorry, an error has occurred. Please refresh the page and try again.`;

/** App Config */
export const AGENT_ASSIST_CONFIG: AppConfig = {
  requiredApis: CCAI_REQUIRED_APIS,
  consoleName: ConsoleNames.AGENT_ASSIST,
};

/** Onboarding */
export const AGENT_ASSIST_ONBOARDING: Onboarding = {
  title: 'Empower human agents with continuous support.',
  link: 'https://cloud.google.com/agent-assist/docs',
  subtitle: 'Quick access to useful resources',
  background:
      'https://www.gstatic.com/dialogflow-console/common/assets/ccai_svg_headers/agent_assist.svg',
  resources: [
    {
      name: 'Documentation',
      description: 'See Agent Assist tutorials, guides, and resources.',
      avatar: 'insert_drive_file',
      link: 'https://cloud.google.com/agent-assist/docs',
      customActionText: 'Learn more about Agent Assist',
    },
  ],
};

/** Console default region */
export const AGENT_ASSIST_DEFAULT_REGION: ConsoleRegion = {
  regionFullName: 'United States',
  regionShortName: 'US',
  regionLocationId: LocationId.US,
};

/** Default language code for resource creation */
export const DEFAULT_LANGUAGE_CODE: LanguageCode = 'en-US';

/** Supported language codes for resource creation */
export const SUPPORTED_LANGUAGE_CODES: readonly LanguageCode[] = [
  'en-US',
  'en',
];

/** Supported language codes for Smart Reply only */
export const SMART_REPLY_SUPPORTED_LANGUAGE_CODES: readonly LanguageCode[] = [
  ...SUPPORTED_LANGUAGE_CODES,
  'fr-CA',
];

/** Unsupported (private) language codes for resource creation */
export const UNSUPPORTED_LANGUAGE_CODES: readonly LanguageCode[] = [
  'es-ES',
  'es',
  'fr-CA',
  'fr-FR',
  'fr',
  'de-DE',
  'it-IT',
];

/** Description for model evaluation recall metric */
export const RECALL_DESCRIPTION =
    'The percentage of agent messages in the test dataset that are contained ' +
    'in the allowlist and appear in the top 3 suggestions surfaced by the ' +
    'model.';

/** Description for model evaluation confidence threshold metric */
export const CONFIDENCE_THRESHOLD_DESCRIPTION =
    'The confidence threshold of the query result.';

/** Description for model evaluation message coverage metric */
export const MESSAGE_COVERAGE_DESCRIPTION =
    'The percentage of agent messages in the test dataset that are covered ' +
    'by the allowlist.';

/** Description for model evaluation trigger rate metric */
export const TRIGGER_RATE_DESCRIPTION =
    'The number of responses which contain non-empty suggestions divided by ' +
    'the number of requests.';

/** Description for model evaluation ROUGE-L metric */
export const ROUGE_L_DESCRIPTION =
    'Recall-Oriented Understudy for Gisting Evaluation. The measures count ' +
    'the number of overlapping units such as n-gram, word sequences, and ' +
    'word pairs between the computer-generated summary to be evaluated and ' +
    'the ideal summaries created by humans.';

/** Tooltip to display on form submit button when form is invalid. */
export const FORM_ERROR_MESSAGE =
    'Make sure all fields are correct to continue';

/** RegEx pattern for GCS URI */
export const GCS_URI_PATTERN = /^gs:\/\/[a-z\d-_.]{3,222}\/[^\r\n]{1,1024}/;

/** Knowledge Assist suggestion features. */
export const KNOWLEDGE_ASSIST_FEATURES: SupportedAgentAssistFeatures[] =
    ['ARTICLE_SUGGESTION', 'FAQ', 'ARTICLE_SEARCH'];

/** Agent assist feature names. */
export const AGENT_ASSIST_FEATURE_NAMES:
    {[key in SupportedAgentAssistFeatures]: string} = {
      'ARTICLE_SEARCH': 'Article search',
      'ARTICLE_SUGGESTION': 'Article suggestion',
      'ARTICLE_SUGGESTION_VOICE': 'Article suggestion Voice',
      'CONVERSATION_GUIDANCE': 'Conversation guidance',
      'CONVERSATION_SUMMARIZATION': 'Conversation summarization',
      'CONVERSATION_SUMMARIZATION_VOICE': 'Conversation summarization Voice',
      'DIALOGFLOW_ASSIST': 'Virtual agent assist',
      'FAQ': 'FAQ',
      'ISSUE_SMART_COMPOSE': 'Issue smart compose',
      'KEY_MOMENT': 'Key moment',
      'SENTIMENT_ANALYSIS': 'Sentiment analysis',
      'SMART_COMPOSE': 'Smart compose',
      'SMART_REPLY': 'Smart reply',
      'SPELL_GRAMMAR_CORRECTION': 'Spelling and grammar correction',
      'TYPE_UNSPECIFIED': 'Unknown',
    };

/**
 * Shortened names for agent assist features. These are used when the
 * available space is limited, such as in table cells.
 */
export const SHORTENED_AGENT_ASSIST_FEATURE_NAMES:
    {[key in SupportedAgentAssistFeatures]: string} = {
      'ARTICLE_SEARCH': 'Article search',
      'ARTICLE_SUGGESTION': 'Article suggestion',
      'ARTICLE_SUGGESTION_VOICE': 'Article suggestion Voice',
      'CONVERSATION_GUIDANCE': 'Conversation guidance',
      'CONVERSATION_SUMMARIZATION': 'Summarization',
      'CONVERSATION_SUMMARIZATION_VOICE': 'Summarization Voice',
      'DIALOGFLOW_ASSIST': 'Virtual agent assist',
      'FAQ': 'FAQ',
      'ISSUE_SMART_COMPOSE': 'Smart compose',
      'KEY_MOMENT': 'Key moment',
      'SENTIMENT_ANALYSIS': 'Sentiment analysis',
      'SMART_COMPOSE': 'Smart compose',
      'SMART_REPLY': 'Smart reply',
      'SPELL_GRAMMAR_CORRECTION': 'Spelling & grammar',
      'TYPE_UNSPECIFIED': 'Unknown',
    };
