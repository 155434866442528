import {VisibilityLabels} from 'google3/java/com/google/dialogflow/console/web/common/constants/constants';
import {ExperimentFlagName} from 'google3/java/com/google/dialogflow/console/web/common/experiment_service/experiment_service';
import {assert, assertArray, assertObject, assertString} from 'google3/third_party/javascript/closure/asserts/asserts';

/** Assertion error. */
export class AssertionError extends Error {
  constructor(message?: string) {
    const errorMessage = message ? `Assertion failed: ${message}` : '';

    super(errorMessage);
    this.name = 'AssertionError';
  }
}

/** Assert list of visibility labels are valid */
export const assertVisibilityLabels =
    (visibilityLabels: VisibilityLabels[] = []) => {
      visibilityLabels.forEach(visibilityLabel => {
        assert(
            VisibilityLabels.hasOwnProperty(visibilityLabel),
            'Invalid visibility label %s', visibilityLabel);
      });
    };

/** Assert visibility label is valid */
export const assertVisibilityLabel = (visibilityLabel: VisibilityLabels) =>
    assert(
        VisibilityLabels.hasOwnProperty(visibilityLabel),
        'Invalid visibility label %s', visibilityLabel);

/** Assert list of experiment flags are valid */
export const assertExperimentFlags =
    (experimentFlags: ExperimentFlagName[] = []) => {
      experimentFlags.forEach(experimentFlag => {
        assert(
            ExperimentFlagName.hasOwnProperty(
                (experimentFlag as string).toUpperCase()),
            'Invalid experiment flag %s', experimentFlag);
      });
    };

/** Assert experiment flag is valid */
export const assertExperimentFlag = (experimentFlag: ExperimentFlagName) =>
    assert(
        ExperimentFlagName.hasOwnProperty(
            (experimentFlag as string).toUpperCase()),
        'Invalid experiment flag %s', experimentFlag);

/** Aserts that only one of the passed-in arguments is truthy. */
export const assertEither =
    (item1: unknown, item2: unknown, message?: string) => {
      const numExisting = [item1, item2].filter(Boolean).length;

      assert(numExisting === 1, message);
    };


/** Asserts a non-empty string. */
export const assertNonEmptyString =
    (maybeString: unknown, message?: string) => {
      assert(maybeString, message);
      assertString(maybeString, message);
    };

/**
 * Asserts that the given value can be parsed into a valid number, either from
 * a string or a number format.
 *
 * i.e., 10 or '10' are both valid, but ' ' is not.
 * Cannot rely on Number() coercision alone, since all of the above cases would
 * be valid.
 */
export const assertValidParseableNumber = (val: unknown, message: string) => {
  if (typeof val === 'string') {
    assert(val.trim() && !isNaN(Number(val)), message);
  } else if (typeof val !== 'number') {
    throw new AssertionError(message);
  }
};

/** Asserts that the given value is an array of objects. */
export const assertArrayOfObjects = (val: unknown, message: string) => {
  assertArray(val, message);

  for (const entry of (val as unknown[])) {
    assertObject(entry, message);
  }
};
