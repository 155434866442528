<section
  #summary
  [class.summary--loading]="loadingState === 'LOADING'"
  class="summary__content-wrapper"
>
  <div class="summary__content" #summaryContent>
    <mat-spinner
      *ngIf="loadingState === 'LOADING'"
      aria-label="Summary content loading"
      i18n-aria-label="ARIA label for a spinner that indicates that the summary is loading"
      data-test-id="summary-loading-spinner"
    ></mat-spinner>

    <ng-container *ngIf="loadingState === 'LOADED'">
      <p
        class="summary__content-details gmat-body-2"
        i18n="Shows additional details for the conversation, including the date, time, and duration"
      >
        <b>Date:</b> {{currentDate // i18n(ph = 'current date')}}<br />
        <b>Time:</b> {{currentTime // i18n(ph = 'current time')}}<br />
        <b>Duration:</b> {{conversationDuration // i18n(ph = 'duration of the conversation')}}<br />
      </p>
      <div *ngIf="!editingSummary" class="summary__content-summary">
        <pre class="gmat-body-2">{{form.value}}</pre>
      </div>
      <mat-form-field *ngIf="editingSummary" data-test-id="edit-summary-form-field">
        <textarea
          [formControl]="form"
          aria-label="Summary input"
          i18n-aria-label="ARIA label for an input field that edits a summary"
          matInput
          cdkTextareaAutosize
          focusInitial
        ></textarea>
      </mat-form-field>
    </ng-container>
  </div>
</section>

<section
  *ngIf="loadingState === 'LOADED'"
  [scrollingElement]="summaryContent"
  class="summary__title-bar"
  [class.summary__title-bar--editing]="editingSummary"
  setClassOnScroll="summary__title-bar--scrolled"
>
  <div class="summary__title-bar-wrapper">
    <ng-container *ngIf="editingSummary">
      <button
        (click)="handleFinishEditingSummary()"
        color="primary"
        data-test-id="confirm-edit-summary-button"
        mat-stroked-button
        i18n="Button that confirms a summary edit"
      >
        <mat-icon svgIcon="done"></mat-icon>
        Confirm
      </button>
      <button
        (click)="handleCancelEditSummary()"
        color="primary"
        data-test-id="cancel-edit-summary-button"
        mat-stroked-button
        i18n="Button that cancels an edit"
      >
        Cancel
      </button>
    </ng-container>

    <ng-container *ngIf="!editingSummary">
      <button
        (click)="handleEditSummary()"
        color="primary"
        data-test-id="edit-summary-button"
        mat-stroked-button
        i18n="Button that edits a summary"
      >
        <mat-icon svgIcon="edit"></mat-icon>
        Edit
      </button>
      <button
        *ngIf="hasSaveHandler"
        (click)="handleSaveSummary()"
        color="primary"
        data-test-id="save-summary-button"
        mat-stroked-button
        i18n="Button that saves a summary"
      >
        <mat-spinner
          *ngIf="saveLoadingState === 'LOADING'; else saveIcon"
          [diameter]="20"
          aria-label="Saving summary"
          i18n-aria-label="ARIA label for a spinner that indicates that a summary is being saved"
          data-test-id="save-summary-spinner"
        ></mat-spinner>
        <ng-template #saveIcon>
          <mat-icon svgIcon="save"></mat-icon>
        </ng-template>
        Save
      </button>
      <button
        [xapCopyToClipboard]="copiedSummaryContent"
        color="primary"
        data-test-id="copy-summary-button"
        mat-stroked-button
        i18n="Button that copies a summary"
      >
        <mat-icon svgIcon="content_copy"></mat-icon>
        Copy
      </button>
    </ng-container>
  </div>
</section>
