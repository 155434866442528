import {SMART_MESSAGING_KB_ID} from 'google3/java/com/google/dialogflow/console/web/common/constants/smart_messaging_kb_id';

/** Generate project name for a specified project ID. */
export const generateProjectName = (projectId: string) => {
  if (!projectId) throw new Error('Invalid project ID');

  return `projects/${projectId}`;
};

/**
 * Generates a project and location name for a specified project ID and
 * location.
 */
export const generateProjectLocationName =
    (projectId: string, location = 'global') => {
      if (!projectId) {
        throw new Error(
            'Please provide a project ID to generateProjectAndLocationName.');
      }

      const projectName = generateProjectName(projectId);

      return `${projectName}/locations/${location}`;
    };

/** Generate agent name for a specified project ID. */
export const generateAgentName = (projectName: string) => {
  if (!projectName) throw new Error('Invalid project ID');

  return `${projectName}/agent`;
};

/** Generate CX agent name for a specified project ID. */
export const generateCxAgentName =
    (projectName: string, cxAgentId: string, location = 'global') => {
      if (!projectName) throw new Error('Invalid project ID');
      if (!cxAgentId) throw new Error('Invalid CX agent ID');

      return `${projectName}/locations/${location}/agents/${cxAgentId}`;
    };

/** Generate answer records name. */
export const generateAnswerRecordsName = (
    projectName: string,
    answerRecordId: string,
    ) => {
  if (!answerRecordId) throw new Error('Invalid answer record name');

  return `${projectName}/answerRecords/${answerRecordId}`;
};

/** Generate dataset name. */
export const generateDatasetName = (
    projectName: string,
    datasetId: string,
    ) => {
  if (!datasetId) throw new Error('Invalid dataset ID');

  return `${projectName}/conversationDatasets/${datasetId}`;
};

/** Generate data item name. */
export const generateDataItemName = (
    datasetName: string,
    dataItemId: string,
    ) => {
  if (!datasetName) {
    throw new Error(
        'Please provide a valid dataset name to generateDataItemName');
  }

  if (!dataItemId) {
    throw new Error(
        'Please provide a valid data item ID to generateDataItemName');
  }

  return `${datasetName}/conversationDataItems/${dataItemId}`;
};

/** Generate annotation name. */
export const generateAnnotationName = (
    dataItemName: string,
    annotationId: string,
    ) => {
  if (!dataItemName) {
    throw new Error(
        'Please provide a valid data item name to generateAnnotationName');
  }

  if (!annotationId) {
    throw new Error(
        'Please provide a valid annotation ID to generateAnnotationName');
  }

  return `${dataItemName}/conversationAnnotations/${annotationId}`;
};

/** Generate conversation name. */
export const generateConversationName = (
    projectName: string,
    conversationId: string,
    ) => {
  if (!conversationId) {
    throw new Error('Invalid conversation ID');
  }

  return `${projectName}/conversations/${conversationId}`;
};

/** Generates a feature creation flow name. */
export const generateFeatureCreationFlowName = (
    projectLocationName: string,
    featureCreationFlowId: string,
    ) => {
  if (!projectLocationName) {
    throw new Error('No project location name provided');
  }

  if (!featureCreationFlowId) {
    throw new Error('No feature creation flow ID provided');
  }

  return `${projectLocationName}/featureCreationFlows/${featureCreationFlowId}`;
};

/** Generate participant name. */
export const generateParticipantName = (
    conversationName: string,
    participantId: string,
    ) => {
  if (!participantId) {
    throw new Error('Invalid participant ID');
  }

  return `${conversationName}/participants/${participantId}`;
};

/** Generate conversation profile name. */
export const generateConversationProfileName = (
    projectName: string,
    conversationProfileId: string,
    ) => {
  if (!conversationProfileId) {
    throw new Error('Invalid conversation profile ID');
  }

  return `${projectName}/conversationProfiles/${conversationProfileId}`;
};

/** Generate conversation model name. */
export const generateConversationModelName = (
    projectName: string,
    conversationModelId: string,
    ) => {
  if (!conversationModelId) {
    throw new Error('Invalid conversation model ID');
  }

  return `${projectName}/conversationModels/${conversationModelId}`;
};

/** Generate conversation model evaluation name. */
export const generateConversationModelEvaluationName =
    (projectName: string, conversationModelId: string,
     evaluationId: string) => {
      if (!projectName) {
        throw new Error('No project name provided');
      }

      if (!conversationModelId) {
        throw new Error('No conversation model ID provided');
      }

      if (!evaluationId) {
        throw new Error('No evaluation ID provided');
      }

      return `${projectName}/conversationModels/${
          conversationModelId}/evaluations/${evaluationId}`;
    };

/** Generate allowlist name. */
export const generateAllowlistName =
    (projectName: string, documentId: string) => {
      if (!projectName) {
        throw new Error('Empty project name');
      }
      if (!documentId) {
        throw new Error('Empty document ID');
      }

      return `${
          generateSmartMessagingKnowledgeBaseName(
              projectName)}/documents/${documentId}`;
    };

/** Generate projects agent environment name. */
export const generateProjectsAgentEnvironmentName = (
    projectName: string,
    projectsAgentEnvironmentId: string,
    ) => {
  if (!projectsAgentEnvironmentId) {
    throw new Error('Invalid conversation profile ID');
  }

  return `${projectName}/agent/environments/${projectsAgentEnvironmentId}`;
};

/** Generate models name. */
export const generateModelsName = (
    projectName: string,
    modelId: string,
    ) => {
  if (!modelId) throw new Error('Invalid model ID');

  return `${projectName}/conversationModels/${modelId}`;
};

/** Generate knowledge base name. */
export const generateKnowledgeBaseName = (
    projectName: string,
    knowledgeBaseId: string,
    ) => {
  if (!knowledgeBaseId) throw new Error('Invalid knowledge base ID');

  return `${projectName}/knowledgeBases/${knowledgeBaseId}`;
};

/** Generates the Smart Messaging knowledge base name for a given project. */
export const generateSmartMessagingKnowledgeBaseName =
    (projectName: string) => {
      if (!projectName) throw new Error('Empty project name');

      return generateKnowledgeBaseName(projectName, SMART_MESSAGING_KB_ID);
    };


/** Generate document name. */
export const generateDocumentName = (
    knowledgeBaseName: string,
    documentId: string,
    ) => {
  if (!documentId) throw new Error('Invalid document ID');

  return `${knowledgeBaseName}/documents/${documentId}`;
};

/**
 * Generates a Smart Messaging entry name from a given project name, document
 * ID, and entry ID.
 */
export const generateSmartMessagingEntryName =
    (projectName: string, documentId: string, entryId: string) => {
      if (!projectName) {
        throw new Error(
            'Please pass a project name to generateSmartMessagingEntryName');
      }

      if (!documentId) {
        throw new Error(
            'Please pass a document ID to generateSmartMessagingEntryName');
      }

      if (!entryId) {
        throw new Error(
            'Please pass a Smart Messaging entry ID to generateSmartMessagingEntryName');
      }

      const allowlistName = generateAllowlistName(projectName, documentId);

      return `${allowlistName}/smartMessagingEntries/${entryId}`;
    };

/** Remove locale based name portion from resource name. */
export const generateNonLocaleBasedName = (name: string) =>
    name.replace(/\/locations\/[^/]+/, '');

/** Generate serialized service name for ServiceUsage v1 API. */
export const generateServiceUsageServiceName =
    (projectName: string, serviceName: string): string => {
      if (!projectName) {
        throw new Error('Missing project name');
      }

      if (!serviceName) {
        throw new Error('Missing service name');
      }

      return `${projectName}/services/${serviceName}`;
    };

/** Generate v3 security settings name. */
export const generateV3SecuritySettingsName =
    (projectName: string, securitySettingsId: string, location = 'global') => {
      if (!projectName) {
        throw new Error('Missing project name');
      }

      if (!securitySettingsId) {
        throw new Error('Missing security settings ID');
      }

      return `${projectName}/locations/${location}/securitySettings/${
          securitySettingsId}`;
    };

/** Generate CCaaS name. */
export const generateContactCenterName =
    (projectName: string, ccaas: string, location = 'global') => {
      if (!projectName) {
        throw new Error('Missing project name');
      }

      if (!ccaas) {
        throw new Error('Missing contact center name');
      }

      return `${projectName}/locations/${location}/contactCenters/${ccaas}`;
    };

/** Generate message name. */
export function generateMessageName(
    conversationName: string, messageId: string) {
  if (!conversationName) {
    throw new Error('Missing conversation name');
  }

  if (!messageId) {
    throw new Error('Missing message ID');
  }

  return `${conversationName}/messages/${messageId}`;
}

/** Generate long-running operation name. */
export function generateOperationName(
    projectLocationName: string, operationId: string) {
  if (!projectLocationName) {
    throw new Error('Missing project location name');
  }

  if (!operationId) {
    throw new Error('Missing operation ID');
  }

  return `${projectLocationName}/operations/${operationId}`;
}
