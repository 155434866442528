import {Action, createReducer, on} from '@ngrx/store';
import {Project} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_v3_ts_api_client';
import {LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';
import {actions as userSettingsActions} from 'google3/java/com/google/dialogflow/console/web/common/store/user_settings/user_settings_actions';

import {actions} from './projects_actions';

/** Projects state interface */
export interface State {
  projectsList: Project[];
  projectsLoadingState: LoadingState;
  searchString: string;
  filter: string;
  nextPageToken: string;
  selectedProject: Project|null;
}

/** Projects initial state */
export const initialState: State = {
  projectsList: [],
  projectsLoadingState: LoadingState.NOT_LOADING,
  searchString: '',
  filter: '',
  nextPageToken: '',
  selectedProject: null,
};

/** Projects reducer */
export function reducer(state: State|undefined, action: Action) {
  return projectsReducer(state, action);
}

const projectsReducer = createReducer(
    initialState,

    on(actions.filterUpdate,
       (state, {filter}) =>
           ({...state, projectsList: [], searchString: filter})),

    on(actions.list,
       (state, {params}) => {
         const filter = params?.filter || '';

         return {...state, projectsLoadingState: LoadingState.LOADING, filter};
       }),

    on(actions.listNext,
       (state) => ({...state, projectsLoadingState: LoadingState.LOADING})),

    // Add projects from the recent projects call to the projectsList.
    // (Only include the ones that are not already in the list).
    on(userSettingsActions.getRecentProjectsSuccess,
       (state, {validProjects}) => {
         let projectsList = state.projectsList;
         if (validProjects) {
           projectsList = projectsList.concat(validProjects.filter(
               recentProject => !state.projectsList.find(
                   project => project.name === recentProject.name)));
         }
         return {
           ...state,
           projectsList,
         };
       }),

    on(actions.listSuccess,
       (state, {response}) => {
         const {projects, nextPageToken} = response;
         return {
           ...state,
           projectsList: state.projectsList.concat(projects || []),
           projectsLoadingState: LoadingState.LOADED,
           nextPageToken: nextPageToken || '',
         };
       }),

    on(actions.startSelectProject, (state, {select}) => ({
                                     ...state,
                                     searchString: select,
                                     projectsLoadingState: LoadingState.LOADING
                                   })),

    on(actions.selectProjectSuccess,
       (state, {project}) => {
         const projectIndex =
             state.projectsList.findIndex(p => p.name === project.name);
         if (projectIndex >= 0) {
           state.projectsList.splice(projectIndex, 1, project);
         } else {
           state.projectsList.push(project);
         }

         return {
           ...state,
           projectsList: Array.from(state.projectsList),
           projectsLoadingState: LoadingState.LOADED,
           selectedProject: project,
         };
       }),

    on(actions.selectProjectError, actions.apiError,
       (state) => ({
         ...state,
         projectsLoadingState: LoadingState.NOT_LOADING,
       })),
);
