import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SUMMARIZATION_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/summarization/summarization_constants';
import {UI_MODULE_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/injection_tokens';
import {dispatchUiModuleEvent, fromUiModuleErrorEvent, fromUiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {UiModuleIconService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/icon/ui_module_icon_service';
import {NotificationService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_service';
import {UiModuleStore} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/store/ui_module_store';
import {BooleanString} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/common_types';
import {ConversationDetails} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/conversation_details';
import {UiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {LoadingState} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/loading_state';
import {SaveSummaryCallbacks, SummaryDetails} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/summarization';
import {AnswerRecord_, Conversation_, GoogleRpcStatus, SuggestConversationSummaryResponse_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/** Summarization element selector. */
export const SUMMARIZATION_ELEMENT_SELECTOR = 'agent-assist-summarization';

interface State {
  readonly loadingState: LoadingState;
  readonly error: GoogleRpcStatus|null;
  readonly summary: SuggestConversationSummaryResponse_|null;
  readonly conversation: Conversation_|null;
  readonly conversationDetails?: ConversationDetails;
  readonly saveLoadingState: LoadingState;
  readonly saveSuccessMessage: string;
  readonly saveErrorMessage: string;
}

const initialState: State = {
  loadingState: 'NOT_LOADING',
  error: null,
  summary: null,
  conversation: null,
  saveLoadingState: 'NOT_LOADING',
  saveSuccessMessage: '',
  saveErrorMessage: '',
};

/** Conversation Summarization UI Module component. */
@Component({
  selector: SUMMARIZATION_ELEMENT_SELECTOR,
  templateUrl: './summarization.ng.html',
  styleUrls: ['./summarization.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: UI_MODULE_ICONS, useValue: SUMMARIZATION_ICONS},
    UiModuleIconService,
    UiModuleStore,
  ],
})
export class Summarization implements OnInit, OnDestroy {
  private internalShowGenerateSummaryButton: BooleanString = 'true';

  /** Whether to show the 'Generate Summary' button. */
  @Input('show-generate-summary-button')
  get showGenerateSummaryButton() {
    return this.internalShowGenerateSummaryButton;
  }
  set showGenerateSummaryButton(showGenerateSummaryButton: BooleanString|
                                undefined) {
    if (showGenerateSummaryButton) {
      this.internalShowGenerateSummaryButton = showGenerateSummaryButton;
    }
  }

  /**
   * Callback to invoke when summary is saved. Used by customers to send summary
   * details to CRM.
   */
  @Input()
  onSaveSummary?:
      (summary: SummaryDetails, saveCallbacks: SaveSummaryCallbacks) => void;

  readonly loadingState$ =
      this.store.selectForActiveConversation(state => state.loadingState);
  readonly error$ =
      this.store.selectForActiveConversation(state => state.error);
  readonly summary$ =
      this.store.selectForActiveConversation(state => state.summary);
  readonly conversation$ =
      this.store.selectForActiveConversation(state => state.conversation);
  readonly saveLoadingState$ =
      this.store.selectForActiveConversation(state => state.saveLoadingState);
  readonly saveSuccessMessage$ =
      this.store.selectForActiveConversation(state => state.saveSuccessMessage);
  readonly saveErrorMessage$ =
      this.store.selectForActiveConversation(state => state.saveErrorMessage);
  readonly conversationDetails$ = this.store.selectForActiveConversation(
      state => state.conversationDetails);
  readonly activeConversationName$ = this.store.activeConversationName$;

  readonly setConversationDetails = this.store.updater(
      (state, conversationDetails: ConversationDetails) =>
          ({...state, conversationDetails}));

  private readonly destroyed$ = new Subject();

  constructor(
      notificationService: NotificationService,
      uiModuleIconService: UiModuleIconService,
      private readonly store: UiModuleStore<State>,
  ) {
    store.init(initialState);
    uiModuleIconService.initModule();
    notificationService.initModule();
  }

  ngOnInit() {
    fromUiModuleEvent(UiModuleEvent.CONVERSATION_SUMMARIZATION_REQUESTED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.store.setStateForActiveConversation(
              state => ({...state, error: null, loadingState: 'LOADING'}));
        });

    fromUiModuleEvent(UiModuleEvent.CONVERSATION_SUMMARIZATION_RECEIVED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName, payload: summary}) => {
          this.store.setStateForConversation(
              conversationName,
              state =>
                  ({...state, loadingState: 'LOADED', summary, error: null}));
        });

    fromUiModuleErrorEvent('CONVERSATION_SUMMARIZATION')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName, error}) => {
          this.store.setStateForConversation(
              conversationName,
              state => ({...state, loadingState: 'ERROR', error}));
        });

    fromUiModuleEvent(UiModuleEvent.CONVERSATION_DETAILS_RECEIVED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName, payload: conversationDetails}) => {
          this.setConversationDetails(conversationDetails, conversationName);
        });

    fromUiModuleEvent(UiModuleEvent.CONVERSATION_INITIALIZED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversation}) => {
          this.store.setStateForConversation(
              conversation.name!, state => ({...state, conversation}));
        });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleEditSummary(answerRecord: AnswerRecord_) {
    dispatchUiModuleEvent(UiModuleEvent.PATCH_ANSWER_RECORD_REQUESTED, {
      detail: {
        payload: {answerRecord},
        options: {
          updateMask:
              'answerFeedback.agentAssistantDetailFeedback.summarizationFeedback'
        }
      }
    });
  }

  handleSaveSummary(
      summary: string, conversationDetails: ConversationDetails,
      activeConversation: string) {
    const summaryDetails: SummaryDetails = {
      conversationDetails,
      summary,
    };

    this.onSaveSummary?.(summaryDetails, {
      setLoading: () => {
        this.onSaveLoading(activeConversation);
      },
      setSuccess: (message: string) => {
        this.onSaveSuccess(activeConversation, message);
      },
      setError: (message: string) => {
        this.onSaveError(activeConversation, message);
      }
    });
  }

  handleGenerateSummary() {
    dispatchUiModuleEvent(UiModuleEvent.CONVERSATION_SUMMARIZATION_REQUESTED);
  }

  private readonly onSaveLoading = (conversationName: string) => {
    this.store.setStateForConversation(
        conversationName, state => ({
                            ...state,
                            saveLoadingState: 'LOADING',
                          }));
  };

  private readonly onSaveSuccess =
      (conversationName: string, message: string) => {
        this.store.setStateForConversation(
            conversationName, state => ({
                                ...state,
                                saveLoadingState: 'LOADED',
                                saveSuccessMessage: message,
                              }));
      };

  private readonly onSaveError =
      (conversationName: string, message: string) => {
        this.store.setStateForConversation(
            conversationName, state => ({
                                ...state,
                                saveLoadingState: 'ERROR',
                                saveErrorMessage: message,
                              }));
      };
}
