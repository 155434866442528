/**
 * Returns the intersection of one or more arrays.
 *
 * Example:
 * getArrayIntersection([1,2], [2,3]) // [2]
 */
export function getArrayIntersection<T>(...arrays: T[][]) {
  let intersection: T[]|null = null;

  for (const array of arrays) {
    intersection = intersection ?
        intersection.filter(value => array.includes(value)) :
        array;
  }

  return intersection || [];
}
