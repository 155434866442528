import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SuggestionFeatureStore} from 'google3/java/com/google/dialogflow/console/web/ccai/services/suggestion_feature_store/suggestion_feature_store';
import {SuggestionFeatureService} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/common/suggestion_feature_service/suggestion_feature_service';
import {SmartReplyAnswer_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {Observable, ReplaySubject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {SmartReplyAnswersWithConversationName, SmartReplySelection} from './smart_reply_types';



interface State {
  smartReplyAnswers: SmartReplyAnswer_[]|undefined;
  usedSmartReplyAnswers: SmartReplyAnswer_[];
}

const initialState: State = {
  smartReplyAnswers: undefined,
  usedSmartReplyAnswers: [],
};

/**
 * @desc ARIA label for a dialog showing the details of a Smart Reply
 * suggestion.
 */
const MSG_SUGGESTION_DETAILS_DIALOG_ARIA_LABEL =
    goog.getMsg('Smart reply suggestion details');

/**
 * Base component that renders Smart Reply suggestion chips from an
 * AnalyzeContentResponse, and emits the users selections to the host
 * component.
 */
@Component({
  selector: 'smart-reply',
  templateUrl: './smart_reply.ng.html',
  styleUrls: ['./smart_reply.css'],
  providers: [
    SuggestionFeatureService,
    SuggestionFeatureStore,
  ]
})
export class SmartReply implements OnInit, OnDestroy {
  @Input() loading = false;
  @Input() showConfidence = true;
  @Input() simpleTooltip = false;
  @Input() richTooltipEnabled = false;

  /**
   * Used to send a signal to the Smart Reply component to clear used
   * suggestions.
   */
  @Input('clearUsedSuggestions') clearUsedSuggestions$?: Observable<string>;

  @Input()
  set activeConversationName(activeConversationName: string|null) {
    if (activeConversationName) {
      this.suggestionFeatureService.store.setActiveConversation(
          activeConversationName);
    }
  }

  @Input()
  set smartReplyAnswers(smartReplyAnswersWithConversationName:
                            SmartReplyAnswersWithConversationName|null) {
    if (smartReplyAnswersWithConversationName) {
      const {conversationName, payload: answers} =
          smartReplyAnswersWithConversationName;
      this.setSmartReplyAnswers(answers, conversationName);
      this.updateDisplayTimes(answers);
    }
  }

  @Output()
  readonly onSelectSuggestion = new EventEmitter<SmartReplySelection>();

  readonly smartReplyAnswers$ =
      this.suggestionFeatureService.store.selectForActiveConversation(
          state => state.smartReplyAnswers);

  readonly MSG_SUGGESTION_DETAILS_DIALOG_ARIA_LABEL =
      MSG_SUGGESTION_DETAILS_DIALOG_ARIA_LABEL;

  private readonly usedSmartReplyAnswers$ =
      this.suggestionFeatureService.store.selectForActiveConversation(
          state => state.usedSmartReplyAnswers);

  private readonly setSmartReplyAnswers =
      this.suggestionFeatureService.store.updater(
          (state, smartReplyAnswers: SmartReplyAnswer_[]|undefined) =>
              ({...state, smartReplyAnswers}));

  private readonly setUsedSmartReplyAnswer =
      this.suggestionFeatureService.store.updater(
          (state, answer: SmartReplyAnswer_) => ({
            ...state,
            usedSmartReplyAnswers: [...state.usedSmartReplyAnswers, answer]
          }));

  private readonly resetUsedSmartReplyAnswers = (conversationName: string) => {
    this.suggestionFeatureService.store.setStateForConversation(
        conversationName, state => ({...state, usedSmartReplyAnswers: []}));
  };

  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
      private readonly suggestionFeatureService:
          SuggestionFeatureService<State>,
  ) {
    suggestionFeatureService.store.init(initialState);
  }

  handleChooseSmartReplyAnswerSuggestion(smartReplyAnswer: SmartReplyAnswer_) {
    this.onSelectSuggestion.emit({
      displayTime: this.suggestionFeatureService.getSuggestionDisplayTime(
          smartReplyAnswer.answerRecord!)!,
      clickTime: new Date().toISOString(),
      answer: smartReplyAnswer,
    });
  }

  handleHasSmartReplyAnswerSuggestionBeenUsed$(smartReplyAnswer:
                                                   SmartReplyAnswer_) {
    return this.usedSmartReplyAnswers$.pipe(
        map(answers => answers.includes(smartReplyAnswer)));
  }

  ngOnInit() {
    this.onSelectSuggestion.pipe(takeUntil(this.destroyed$))
        .subscribe(suggestion => {
          this.setUsedSmartReplyAnswer(suggestion.answer);
        });

    this.clearUsedSuggestions$?.pipe(takeUntil(this.destroyed$))
        .subscribe(conversationName => {
          this.resetUsedSmartReplyAnswers(conversationName);
        });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private updateDisplayTimes(answers: SmartReplyAnswer_[]|undefined) {
    const answerRecords =
        answers?.map(answer => answer.answerRecord!).filter(Boolean);
    this.suggestionFeatureService.updateSuggestionDisplayTimes(answerRecords);
  }
}
