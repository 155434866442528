import {createAction, props} from '@ngrx/store';
import {ListProjectsParams} from 'google3/java/com/google/dialogflow/console/web/common/persistence/projects_persistence';
import {GoogleRpcStatus} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_ts_api_client';
import {ListProjectsResponse, Project} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_v3_ts_api_client';

/** Projects list action */
const filterUpdate =
    createAction('[PROJECT ACTIONS] FILTER UPDATE', props<{filter: string}>());

/** Projects list action */
const list = createAction(
    '[PROJECT ACTIONS] LIST', props<{params?: ListProjectsParams}>());

/** Projects list next action */
const listNext = createAction('[PROJECT ACTIONS] LIST_NEXT');

/** Action containing list of all projects available to user */
const listSuccess = createAction(
    '[PROJECT ACTIONS] LIST_SUCCESS',
    props<{response: ListProjectsResponse}>());

/** Start select project action */
const startSelectProject = createAction(
    '[PROJECT ACTIONS] START_SELECT_PROJECT', props<{select: string}>());

/** Select project action */
const selectProject = createAction(
    '[PROJECT ACTIONS] SELECT_PROJECT', props<{project: Project}>());

/** Select project success action */
const selectProjectSuccess = createAction(
    '[PROJECT ACTIONS] SELECT_PROJECT_SUCCESS', props<{project: Project}>());

/** Select project error action */
const selectProjectError = createAction(
    '[PROJECT ACTIONS] SELECT_PROJECT_ERROR', props<{error: string}>());

/** Projects API error action */
const apiError = createAction(
    '[PROJECT ACTIONS] API_ERROR', props<{error: GoogleRpcStatus}>());

/** Collection of all projects agent actions */
export const actions = {
  filterUpdate,
  list,
  listNext,
  listSuccess,
  apiError,
  startSelectProject,
  selectProject,
  selectProjectSuccess,
  selectProjectError,
};
