/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Defines build level visibility restricted unwrappers that
 * support both Closure and TS safe types.
 */

import {SafeHtml as GoogSafeHtml} from 'google3/third_party/javascript/closure/html/safehtml';
import {SafeScript as GoogSafeScript} from 'google3/third_party/javascript/closure/html/safescript';
import {SafeStyle as GoogSafeStyle} from 'google3/third_party/javascript/closure/html/safestyle';
import {SafeStyleSheet as GoogSafeStyleSheet} from 'google3/third_party/javascript/closure/html/safestylesheet';
import {SafeUrl as GoogSafeUrl} from 'google3/third_party/javascript/closure/html/safeurl';
import {TrustedResourceUrl as GoogTrustedResourceUrl} from 'google3/third_party/javascript/closure/html/trustedresourceurl';
import * as googUnchecked from 'google3/third_party/javascript/closure/html/uncheckedconversions';
import {Const} from 'google3/third_party/javascript/closure/string/const';

import {createHtml, SafeHtml as TSSafeHtml, unwrapHtml} from '../internals/html_impl';
import {createResourceUrl, TrustedResourceUrl as TSTrustedResourceUrl, unwrapResourceUrl} from '../internals/resource_url_impl';
import {createScript, SafeScript as TSSafeScript, unwrapScript} from '../internals/script_impl';
import {createStyle, SafeStyle as TSSafeStyle, unwrapStyle} from '../internals/style_impl';
import {createStyleSheet, SafeStyleSheet as TSSafeStyleSheet, unwrapStyleSheet} from '../internals/style_sheet_impl';
import {createUrl, SafeUrl as TSSafeUrl, unwrapUrl} from '../internals/url_impl';

/** Compat type for Closure and TS SafeTypes. */
export type SafeHtml = GoogSafeHtml|TSSafeHtml;
/** Compat type for Closure and TS SafeTypes. */
export type SafeScript = GoogSafeScript|TSSafeScript;
/** Compat type for Closure and TS SafeTypes. */
export type SafeStyle = GoogSafeStyle|TSSafeStyle;
/** Compat type for Closure and TS SafeTypes. */
export type SafeStyleSheet = GoogSafeStyleSheet|TSSafeStyleSheet;
/** Compat type for Closure and TS SafeTypes. */
export type SafeUrl = GoogSafeUrl|TSSafeUrl;
/** Compat type for Closure and TS SafeTypes. */
export type TrustedResourceUrl = GoogTrustedResourceUrl|TSTrustedResourceUrl;

/** Guard function that works for boths Closure and TS SafeTypes. */
export function isSafeHtml(u: unknown): u is SafeHtml {
  return u instanceof GoogSafeHtml || u instanceof TSSafeHtml;
}

/** Guard function that works for boths Closure and TS SafeTypes. */
export function isSafeScript(u: unknown): u is SafeScript {
  return u instanceof GoogSafeScript || u instanceof TSSafeScript;
}

/** Guard function that works for boths Closure and TS SafeTypes. */
export function isSafeStyle(u: unknown): u is SafeStyle {
  return u instanceof GoogSafeStyle || u instanceof TSSafeStyle;
}

/** Guard function that works for boths Closure and TS SafeTypes. */
export function isSafeStyleSheet(u: unknown): u is SafeStyleSheet {
  return u instanceof GoogSafeStyleSheet || u instanceof TSSafeStyleSheet;
}

/** Guard function that works for boths Closure and TS SafeTypes. */
export function isSafeUrl(u: unknown): u is SafeUrl {
  return u instanceof GoogSafeUrl || u instanceof TSSafeUrl;
}

/** Guard function that works for boths Closure and TS SafeTypes. */
export function isTrustedResourceUrl(u: unknown): u is TrustedResourceUrl {
  return u instanceof GoogTrustedResourceUrl ||
      u instanceof TSTrustedResourceUrl;
}

/** Safe unwrapper that support both Closure and TS safe types. */
export function unwrapTrustedHTML(html: SafeHtml): string {
  if (html instanceof TSSafeHtml) {
    return unwrapHtml(html) as string;
  }
  return GoogSafeHtml.unwrapTrustedHTML(html) as string;
}

/** Safe unwrapper that support both Closure and TS safe types. */
export function unwrapTrustedScriptURL(url: TrustedResourceUrl): string {
  if (url instanceof TSTrustedResourceUrl) {
    return unwrapResourceUrl(url) as string;
  }
  return GoogTrustedResourceUrl.unwrapTrustedScriptURL(url) as string;
}

/** Safe unwrapper that support both Closure and TS safe types. */
export function unwrapSafeStyle(style: SafeStyle): string {
  if (style instanceof TSSafeStyle) {
    return unwrapStyle(style);
  }
  return GoogSafeStyle.unwrap(style);
}

/**
 * Safe unwrapper that support both Closure and TS safe types.
 * @noinline due to b/182875660. This prevents a Closure optimization that
 * breaks IE11 with the location object.
 */
export function unwrapSafeUrl(url: SafeUrl): string {
  if (url instanceof TSSafeUrl) {
    return unwrapUrl(url);
  }
  return GoogSafeUrl.unwrap(url);
}

/** Safe unwrapper that support both Closure and TS safe types. */
export function unwrapTrustedResourceUrl(url: TrustedResourceUrl) {
  if (url instanceof TSTrustedResourceUrl) {
    return unwrapResourceUrl(url).toString();
  }
  return GoogTrustedResourceUrl.unwrap(url);
}

/** Safe unwrapper that support both Closure and TS safe types. */
export function unwrapTrustedScript(script: SafeScript): string {
  if (script instanceof TSSafeScript) {
    return unwrapScript(script) as string;
  }
  return GoogSafeScript.unwrapTrustedScript(script) as string;
}

/** Safe unwrapper that support both Closure and TS safe types. */
export function unwrapSafeStyleSheet(styleSheet: SafeStyleSheet): string {
  if (styleSheet instanceof TSSafeStyleSheet) {
    return unwrapStyleSheet(styleSheet);
  }
  return GoogSafeStyleSheet.unwrap(styleSheet);
}

/** Converts a goog.html.SafeHtml into a TypeScript SafeHtml */
export function toTsSafeHtml(html: SafeHtml): TSSafeHtml {
  return createHtml(unwrapTrustedHTML(html).toString());
}

/** Converts a TypeScript SafeHtml into a goog.html.SafeHtml */
export function fromTsSafeHtml(html: SafeHtml): GoogSafeHtml {
  return googUnchecked.safeHtmlFromStringKnownToSatisfyTypeContract(
      Const.from('TS-Closure conversions of the same types'),
      unwrapTrustedHTML(html).toString());
}

/** Converts a goog.html.SafeScript into a TypeScript SafeScript */
export function toTsSafeScript(script: SafeScript): TSSafeScript {
  return createScript(unwrapTrustedScript(script).toString());
}

/** Converts a TypeScript SafeScript into a goog.html.SafeScript */
export function fromTsSafeScript(script: SafeScript): GoogSafeScript {
  return googUnchecked.safeScriptFromStringKnownToSatisfyTypeContract(
      Const.from('TS-Closure conversions of the same types'),
      unwrapTrustedScript(script).toString());
}

/** Converts a goog.html.SafeUrl into a TypeScript SafeUrl */
export function toTsSafeUrl(url: SafeUrl): TSSafeUrl {
  return createUrl(unwrapSafeUrl(url));
}

/** Converts a TypeScript SafeUrl into a goog.html.SafeUrl */
export function fromTsSafeUrl(url: SafeUrl): GoogSafeUrl {
  return googUnchecked.safeUrlFromStringKnownToSatisfyTypeContract(
      Const.from('TS-Closure conversions of the same types'),
      unwrapSafeUrl(url));
}

/**
 * Converts a goog.html.TrustedResourceUrl into a TypeScript TrustedResourceUrl
 */
export function toTsTrustedResourceUrl(url: TrustedResourceUrl):
    TSTrustedResourceUrl {
  return createResourceUrl(unwrapTrustedScriptURL(url).toString());
}

/**
 * Converts a TypeScript TrustedResourceUrl into a goog.html.TrustedResourceUrl
 */
export function fromTsTrustedResourceUrl(url: TrustedResourceUrl):
    GoogTrustedResourceUrl {
  return googUnchecked.trustedResourceUrlFromStringKnownToSatisfyTypeContract(
      Const.from('TS-Closure conversions of the same types'),
      unwrapTrustedScriptURL(url).toString());
}

/** Converts a goog.html.SafeStyle into a TypeScript SafeStyle */
export function toTsSafeStyle(style: SafeStyle): TSSafeStyle {
  return createStyle(unwrapSafeStyle(style));
}

/** Converts a TypeScript SafeStyle into a goog.html.SafeStyle */
export function fromTsSafeStyle(style: SafeStyle): GoogSafeStyle {
  return googUnchecked.safeStyleFromStringKnownToSatisfyTypeContract(
      Const.from('TS-Closure conversions of the same types'),
      unwrapSafeStyle(style));
}

/** Converts a goog.html.SafeStyleSheet into a TypeScript SafeStyleSheet */
export function toTsSafeStyleSheet(sheet: SafeStyleSheet): TSSafeStyleSheet {
  return createStyleSheet(unwrapSafeStyleSheet(sheet));
}

/** Converts a TypeScript SafeStyleSheet into a goog.html.SafeStyleSheet */
export function fromTsSafeStyleSheet(sheet: SafeStyleSheet):
    GoogSafeStyleSheet {
  return googUnchecked.safeStyleSheetFromStringKnownToSatisfyTypeContract(
      Const.from('TS-Closure conversions of the same types'),
      unwrapSafeStyleSheet(sheet));
}
