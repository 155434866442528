import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DoBootstrap, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotificationModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_module';
import {DialogflowAssistModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/dialogflow_assist_module';

import {VirtualAgentAssist} from './virtual_agent_assist';



@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DialogflowAssistModule,
    HttpClientModule,
    NotificationModule,
  ],
  declarations: [
    VirtualAgentAssist,
  ],
  exports: [
    VirtualAgentAssist,
  ],
  entryComponents: [
    VirtualAgentAssist,
  ]
})
export class VirtualAgentAssistModule implements DoBootstrap {
  ngDoBootstrap() {}
}
