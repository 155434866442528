import {AnalyzeContentResponse_, Conversation_, DialogflowAssistAnswer_, IntentSuggestion_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {Intent, QueryResult} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_v3_ts_api_client_interfaces_only';

/**
 * Drills into the analyze content response and returns the list of Dialogflow
 * Assist answers.
 */
export const getDialogflowAssistAnswers =
    (analyzeContentResponse: AnalyzeContentResponse_) =>
        analyzeContentResponse.humanAgentSuggestionResults?.reduce(
            (dialogflowAssistAnswers: DialogflowAssistAnswer_[],
             humanAgentSuggestionResult) =>
                humanAgentSuggestionResult.suggestDialogflowAssistsResponse
                    ?.dialogflowAssistAnswers ?
                [
                  ...dialogflowAssistAnswers,
                  ...humanAgentSuggestionResult.suggestDialogflowAssistsResponse
                      .dialogflowAssistAnswers,
                ] :
                dialogflowAssistAnswers,
            []) ||
    [];

/**
 * Gets the queryResultV3 field from a Dialogflow Assist answer. Overrides the
 * default ApiClientObjectMap type with correct QueryResult type.
 */
export const getQueryResultV3FromDialogflowAssistAnswer =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_|null|undefined) =>
        (dialogflowAssistAnswer?.queryResultV3 as QueryResult | null);

const getIntentForDialogflowAssistAnswer =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_|null) =>
        getQueryResultV3FromDialogflowAssistAnswer(dialogflowAssistAnswer)
            ?.intent ||
    dialogflowAssistAnswer?.intentSuggestion;

/** Gets the intent display name for v2 or v3 intents. */
export const getIntentDisplayName =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_|null) =>
        getIntentForDialogflowAssistAnswer(dialogflowAssistAnswer)?.displayName;

/** Gets the intent description for v2 or v3 intents. */
export const getIntentDescription =
    (dialogflowAssistAnswer: DialogflowAssistAnswer_|null) =>
        getIntentForDialogflowAssistAnswer(dialogflowAssistAnswer)?.description;

/** Determines if the conversation lifecycle state is 'COMPLETED'. */
export const isConversationCompleted = (conversation: Conversation_|null) =>
    conversation?.lifecycleState === 'COMPLETED';

/** Determines if an intent is a head intent. */
// sys-head was removed - why?
export const isHeadIntent = (intentSuggestion?: IntentSuggestion_|null) =>
    Boolean(
        intentSuggestion?.labels?.['sys-agent_assist-head'] ||
        intentSuggestion?.labels?.['sys-head']);

/** Determines if an intent is supplemental. */
export const isSupplementalIntent =
    (intentSuggestion?: Intent|IntentSuggestion_|null) =>
        !!intentSuggestion?.labels?.['sys-contextual'] ||
    /^supplemental/i.test(intentSuggestion?.displayName || '');

/**
 * Converts a parameter to it's serialized primitive version.
 *
 * i.e. {year: 2022, month: 4, day: 1} would be converted to '2022-04-01'
 */
// tslint:disable-next-line:no-any
export const convertToPrimitiveParameter = (parameter: any) => {
  if (parameter && typeof parameter === 'object' && parameter.year &&
      parameter.month && parameter.day) {
    return `${parameter.year.toString().padStart(4, '20')}-${
        parameter.month.toString().padStart(
            2, '0')}-${parameter.day.toString().padStart(2, '0')}`;
  }

  return parameter;
};
