import {ChangeDetectionStrategy, Component, ElementRef, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

import {SHORTCUT_ACTIONS} from '../../shortcut/shortcut_actions';
import {ShortcutHandlersService} from '../../shortcut/shortcut_handlers_service';



const SNACKBAR_CONTAINER_TAG = 'snack-bar-container';



/** SnackBar Data for the SimpleSnackBar component. */
export interface SnackBarData {
  /** Message to render in the snackbar. */
  message: string;
  /** Text for the custom snackbar action. */
  action: string|undefined;
}


/**
 * A simple snackbar component based on material snackbar.
 * Adds keyboard shortcut functionality for a11y.
 */
@Component({
  selector: 'simple-snack-bar',
  templateUrl: './simple_snack_bar.ng.html',
  styleUrls: ['./simple_snack_bar.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'mat-simple-snackbar',
  },
})
export class SimpleSnackBar {
  private prevFocusedPageEl: HTMLElement|null = null;
  private prevFocusedSnackbarEl: HTMLElement|null = null;
  keyName: string;

  // The mat-snack-bar container.
  private containerEl: HTMLElement|null = null;

  /** If the action button should be shown. */
  get hasAction() {
    return !!this.data.action;
  }

  constructor(
      public snackBarRef: MatSnackBarRef<SimpleSnackBar>,
      @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
      private readonly elementRef: ElementRef<HTMLElement>,
      private readonly shortcutHandlersService: ShortcutHandlersService) {
    this.keyName = this.shortcutHandlersService.getControlMetaButtonName();
    this.shortcutHandlersService.registerHandler(
        SHORTCUT_ACTIONS.SNACK_BAR_FOCUS, () => {
          this.handleShortcut();
        });
  }

  ngAfterViewInit() {
    this.containerEl = this.findContainer();

    if (this.containerEl) {
      this.containerEl.addEventListener('focusin', this.onSnackBarFocus);
    }
  }

  ngOnDestroy() {
    this.shortcutHandlersService.removeHandler(
        SHORTCUT_ACTIONS.SNACK_BAR_FOCUS);
  }

  onSnackBarFocus(event: FocusEvent) {
    // Track the previously focused element on the page.
    if (event.relatedTarget instanceof HTMLElement &&
        !this.containerEl?.contains(event.relatedTarget)) {
      this.prevFocusedPageEl = event.relatedTarget;
    }

    // Track the latest element focused inside the snackbar
    if (event.target instanceof HTMLElement) {
      this.prevFocusedSnackbarEl = event.target;
    }
  }

  /** Performs the action on the snack bar. */
  action(): void {
    this.snackBarRef.dismissWithAction();
  }

  /** Dismisses the snackbar. */
  dismiss() {
    this.snackBarRef.dismiss();
  }

  /** Finds the mat snackbar's container element. */
  private findContainer(): HTMLElement|null {
    let nativeElement = this.elementRef.nativeElement;

    // Limit the search depth to 5 ancestors.
    let ancestors = 0;

    while (nativeElement.parentElement && ancestors < 5) {
      nativeElement = nativeElement.parentElement;
      if (nativeElement.tagName.toLowerCase() === SNACKBAR_CONTAINER_TAG) {
        return nativeElement;
      }
      ancestors++;
    }

    return null;
  }

  /**
   * Handles a keyboard shortcut of either command + f6 (Mac) or
   * control + f6 (windows). This is the same shortcut used in Pantheon
   * for jumping to/from snackbars.
   */
  handleShortcut() {
    const focusedEl = document.activeElement as HTMLElement;

    if (this.containerEl && this.containerEl.contains(focusedEl)) {
      this.prevFocusedSnackbarEl = focusedEl;
      this.prevFocusedPageEl?.focus();
    } else {
      this.prevFocusedPageEl = focusedEl;
      this.focusSnackbar();
    }
  }

  /** Focuses the snackbar container element. */
  focusSnackbar() {
    if (this.prevFocusedSnackbarEl) {
      this.prevFocusedSnackbarEl.focus();
    } else if (this.containerEl) {
      this.containerEl.setAttribute('tabindex', '-1');
      this.containerEl.focus();
    }
  }
}
