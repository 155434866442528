<ng-container
  *xapLet="{
    suggestions: suggestions$ | async,
    searchActive: searchActive$ | async
  } as data"
>
  <ng-container *ngIf="data.suggestions">
    <section class="document-and-faq-assist__body">
      <article-search
        *ngIf="hasArticleSearchEnabled(features)"
        [conversation]="conversation"
        [answers]="searchAnswers"
        [answerRecords]="answerRecords"
        [articleLinkConfig]="articleLinkConfig"
        [additionalSuggestionsArrivedDuringSearch$]="additionalSuggestionsArrivedDuringSearch$"
        [features]="features"
        [filterString]="filterString"
        [loadingState]="searchLoadingState"
        [searchActive$]="searchActive$"
        (onCloseSearch)="searchActive$.next(false)"
        (onSearchArticles)="handleSearchArticles($event)"
        (onSelectArticle)="handleSelectArticle($event)"
        (onProvideFeedback)="handleProvideFeedback($event)"
      ></article-search>

      <div
        *ngIf="!articleSearch || !data.searchActive"
        class="document-and-faq-assist__suggestions"
        [scrollOnEmit]="suggestions$"
        (scroll)="handleScrollChange()"
      >
        <h3
          *ngIf="hasKnowledgeAssistSuggestionEnabled(features) && data.suggestions.payload.length"
          class="document-and-faq-assist__suggestions-header"
          i18n="Header for the list of suggested answers"
        >
          Suggested answers
        </h3>
        <ng-container *ngFor="let suggestion of data.suggestions.payload">
          <ng-container *xapLet="handleGetSuggestionType(suggestion.answer) as suggestionType">
            <ng-container
              *ngIf="(suggestionType === 'FAQ' && hasFaqEnabled(features)) || (suggestionType === 'ARTICLE_SUGGESTION' && hasArticleSuggestionEnabled(features))"
            >
              <div
                *ngIf="!handleHasNegativeFeedback(suggestion.answer?.answerRecord)"
                [class.document-and-faq-assist__suggestion--selected]="chosenSuggestion?.answer === suggestion.answer"
                [class.document-and-faq-assist__response--selected]="chosenSuggestion?.analyzeContentResponse === suggestion.analyzeContentResponse"
                [class.document-assist__suggestion]="suggestionType === 'ARTICLE_SUGGESTION'"
                [class.faq-assist__suggestion]="suggestionType === 'FAQ'"
                (click)="handleChooseSuggestion(suggestion.analyzeContentResponse, suggestion.answer);"
                class="document-and-faq-assist__suggestion"
              >
                <mat-icon
                  [svgIcon]="handleGetSuggestionIconName(suggestion.answer)"
                  class="document-and-faq-assist__suggestion-icon"
                ></mat-icon>
                <h4
                  [class.document-and-faq-assist__suggestion-type--faq]="suggestionType === 'FAQ'"
                  [class.document-and-faq-assist__suggestion-type--article]="suggestionType === 'ARTICLE_SUGGESTION'"
                  class="document-and-faq-assist__suggestion-type gmat-headline-5"
                  [matTooltip]="handleGetConfidenceScoreTooltipText(suggestion.answer?.confidence?.toString())"
                  [matTooltipDisabled]="!showConfidence || !suggestion.answer?.confidence"
                >
                  <ng-container
                    *ngIf="suggestionType === 'FAQ'"
                    i18n="Tag to indicate an FAQ suggestion"
                    >FAQ</ng-container
                  >
                  <ng-container
                    *ngIf="suggestionType === 'ARTICLE_SUGGESTION'"
                    i18n="Tag to indicate an article suggestion"
                    >Article</ng-container
                  >
                </h4>
                <ng-container *ngIf="suggestionType === 'ARTICLE_SUGGESTION'">
                  <a
                    *ngIf="suggestion.answer?.uri; else noURITitle"
                    (click)="handleSelectArticleSuggestion($event, suggestion.answer)"
                    [href]="articleLinkConfig.target === 'popup' ? undefined : getArticleAnswerLink(suggestion.answer, articleLinkConfig?.linkMetadataKey)"
                    [target]="articleLinkConfig?.target"
                    rel="noopener noreferrer"
                    class="document-and-faq-assist__suggestion-title-link gmat-subhead-2"
                  >
                    {{suggestion.answer?.title}}
                  </a>
                  <ng-template #noURITitle>
                    <p class="document-and-faq-assist__suggestion-title gmat-subhead-2">
                      {{suggestion.answer?.title}}
                    </p>
                  </ng-template>

                  <p class="document-and-faq-assist__suggestion-body gmat-caption">
                    <span *ngIf="(suggestion.answer?.snippets || [])[0] as snippet"
                      >{{snippet}}</span
                    >
                  </p>
                </ng-container>

                <ng-container *ngIf="suggestionType === 'FAQ'">
                  <p class="document-and-faq-assist__suggestion-title gmat-subhead-2">
                    {{suggestion.answer?.question}}
                  </p>

                  <p class="document-and-faq-assist__suggestion-body gmat-caption">
                    <span>{{suggestion.answer?.answer}}</span>
                    <br />
                    <button
                      *ngIf="suggestion.answer?.answer"
                      [xapCopyToClipboard]="suggestion.answer!.answer"
                      class="gmat-button document-and-faq-assist__copy-faq-answer-button"
                      color="primary"
                      mat-stroked-button
                      aria-label="Copy suggestion"
                      i18n-aria-label="ARIA label on a button to copy a suggestion"
                      i18n="Button to copy a suggestion"
                    >
                      <mat-icon svgIcon="content_copy"></mat-icon>
                      Copy
                    </button>
                  </p>
                </ng-container>

                <span class="document-and-faq-assist__suggestion-timestamp gmat-caption">
                  {{handleGetPrimaryConversationInitTimeOffset(suggestion.createTime)}}
                </span>
                <div
                  *xapLet="suggestion.answer?.answerRecord as answerRecord"
                  class="document-and-faq-assist__suggestion-feedback"
                >
                  <button
                    mat-icon-button
                    class="document-and-faq-assist__suggestion-feedback-button"
                    aria-label="Helpful suggestion"
                    i18n-aria-label="ARIA label on a button that marks a suggestion as helpful"
                    data-test-id="positive-feedback"
                    matTooltip="Helpful suggestion"
                    i18n-matTooltip="Tooltip for a button that marks a suggestion as helpful"
                    (click)="handleHasPositiveFeedback(answerRecord) ? handleProvideNeutralFeedback(answerRecord) : handleProvidePositiveFeedback(suggestion.answer.answerRecord)"
                  >
                    <mat-icon
                      [svgIcon]="handleHasPositiveFeedback(answerRecord) ? 'thumb_up_filled' : 'thumb_up'"
                    ></mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    class="document-and-faq-assist__suggestion-feedback-button"
                    aria-label="Not helpful suggestion"
                    i18n-aria-label="ARIA label on a button that marks a suggestion as not helpful"
                    data-test-id="negative-feedback"
                    matTooltip="Not helpful suggestion"
                    i18n-matTooltip="Tooltip for a button that marks a suggestion as not helpful"
                    (click)="handleProvideNegativeFeedback(answerRecord)"
                  >
                    <mat-icon svgIcon="thumb_down"></mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <button
          *ngIf="showNewSuggestionsChip$ | async"
          @fadeIn
          class="document-and-faq-assist__new-suggestions-chip"
          data-test-id="new-suggestions-chip"
          aria-label="View new suggestions"
          i18n-aria-label="ARIA label on a button that scrolls new suggestions into view"
          i18n="Button that scrolls new suggestions into view"
          (click)="handleAutoScroll()"
        >
          New suggestions
          <mat-icon svgIcon="keyboard_arrow_down"></mat-icon>
        </button>

        <span
          @fadeIn
          *ngIf="data.suggestions.payload.length === 0"
          class="gmat-body-2 document-and-faq-assist__suggestions-empty"
          [style.margin-top]="hasKnowledgeAssistSuggestionEnabled(features) ? '16px' : '0px'"
        >
          <ng-container
            *ngIf="hasKnowledgeAssistSuggestionEnabled(features); else noKnowledgeAssistSuggestionsEnabled"
            i18n="Text to indicate that no knowledge assist suggestions are available"
          >
            No knowledge assist suggestions
          </ng-container>
          <ng-template #noKnowledgeAssistSuggestionsEnabled>
            <ng-container i18n="Text to indicate that no Smart Search results are available"
              >No smart search results</ng-container
            >
          </ng-template>
        </span>
      </div>
    </section>
  </ng-container>
</ng-container>
