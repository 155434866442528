import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {assertValidParseableNumber} from 'google3/java/com/google/dialogflow/console/web/common/utils/asserts';
import {assertInstanceof} from 'google3/javascript/typescript/contrib/assert';
import {fromEvent, Observable, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/**
 * Directive that automatically scrolls element when input observable emits.
 * If user initiates scroll automated scrolling will be disabled until the
 * user scrolls to the bottom of the host element.
 *
 * @Param {alwaysAutoScroll} Auto scroll even if user has scrolled the host
 * element.
 *
 * @Example
 * <div [scrollOnEmit]="observable$"></div>
 *
 * When observable "observable$" emits, the div will scroll down to the
 * bottom.
 */
@Directive({
  selector: '[scrollOnEmit]',
})
export class ScrollOnEmit implements OnInit, OnDestroy {
  @Input()
  get alwaysAutoScroll() {
    return this.alwaysAutoScrollBoolean;
  }
  set alwaysAutoScroll(alwaysAutoScroll: boolean) {
    this.alwaysAutoScrollBoolean = coerceBooleanProperty(alwaysAutoScroll);
  }
  @Input() readonly scrollOnEmit!: Observable<unknown>;

  /**
   * Allowed scroll distance (pixels) from the bottom of the host element to
   * still trigger auto scrolling.
   */
  @Input() readonly tolerance = 0;

  isAutoScrolling = false;

  private autoScroll = true;

  private alwaysAutoScrollBoolean = false;

  private readonly destroyed$ = new ReplaySubject<void>(1);

  private readonly onScroll = () => {
    if (this.isScrolledToBottom) {
      this.autoScroll = true;
      this.isAutoScrolling = false;
    }
  };

  private readonly onKeyup = (e: KeyboardEvent) => {
    if (/^ArrowUp|PageUp|Home$/.test(e.code)) {
      this.onUserScrollUp();
    }

    if (/^ArrowDown|PageDown|End$/.test(e.code)) {
      this.onUserScrollDown();
    }
  };

  private readonly onUserScrollDown =
      () => {
        this.autoScroll = this.isScrolledToBottom;
      }

  private readonly onUserScrollUp = () => {
    this.autoScroll = false;
  };

  private readonly onWheel = (e: WheelEvent) => {
    if (e.deltaY > 0) {
      this.onUserScrollDown();
    } else {
      this.onUserScrollUp();
    }
  };

  /**
   * Checks if host element is scrolled to within BOTTOM_OFFSET_TOLERANCE of the
   * bottom.
   */
  get isScrolledToBottom() {
    return this.nativeElement.scrollHeight - this.nativeElement.scrollTop -
        this.nativeElement.clientHeight <
        (this.tolerance || 1);
  }

  get nativeElement() {
    return this.hostElementRef.nativeElement;
  }

  constructor(private readonly hostElementRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
    assertInstanceof(
        this.scrollOnEmit, Observable,
        'Observable input must be provided to determine when to scroll host element.');
    assertValidParseableNumber(
        this.tolerance, 'Tolerance must be a number. (e.g. 10 for "10px")');

    this.scrollOnEmit.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (this.autoScroll || this.alwaysAutoScroll) {
        /**
         * Set timeout required to avoid conflicting with browser built-in
         * scroll to functionality for input boxes.
         */
        requestAnimationFrame(() => {
          this.isAutoScrolling = true;
          this.nativeElement.scrollTo({
            'behavior': 'smooth',
            'left': 0,
            'top': this.nativeElement.scrollHeight,
          });
        });
      }
    });

    fromEvent<KeyboardEvent>(this.nativeElement, 'keyup', {passive: true})
        .pipe(takeUntil(this.destroyed$))
        .subscribe(this.onKeyup);
    fromEvent(this.nativeElement, 'scroll', {passive: true})
        .pipe(takeUntil(this.destroyed$))
        .subscribe(this.onScroll);
    fromEvent<WheelEvent>(this.nativeElement, 'wheel', {passive: true})
        .pipe(takeUntil(this.destroyed$))
        .subscribe(this.onWheel);

    /** Remove focus outline caused by making element tabbable. */
    this.nativeElement.style.outline = 'none';
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
