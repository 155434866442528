import {getProjectLocationNameFromExtendedName, getProjectNameFromExtendedName} from 'google3/java/com/google/dialogflow/console/web/common/helpers/proto_name_extractors';
import {generateConversationName} from 'google3/java/com/google/dialogflow/console/web/common/helpers/proto_name_generators';

/**
 * Gets the conversation name using a conversation ID and the project/location
 * from the conversation profile name.
 */
export function getConversationName(
    conversationId: string, conversationProfileName: string) {
  const projectLocationName =
      getProjectLocationNameFromExtendedName(conversationProfileName) ||
      getProjectNameFromExtendedName(conversationProfileName);

  return generateConversationName(projectLocationName, conversationId);
}
