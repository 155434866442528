import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';

import {DialogService} from './dialog_service';
import {SimpleDialog} from './simple_dialog';

/**
 * Module that contains dialog-service related components that can't directly
 * be injected to SystemMessaging module because of AOT compilation requirements
 */
@NgModule({
  imports: [MatDialogModule, MatButtonModule, CommonModule],
  declarations: [SimpleDialog],
  providers: [DialogService]
})
export class DialogServiceModule {
}
