import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

/**
 * Let directive.
 *
 * Allows you to bind to observables and declare local template variables
 * without having to wrap it in an object or couple with a conditional operator.
 *
 * <div *ngLet="loading$ | async as loading">
 *    <p>Page loading: {{loading}}</p>
 * </div>
 */
@Directive({
  selector: '[ngLet]',
})
export class LetDirective<T> {
  @Input() ngLet!: T;

  constructor(
      private readonly viewContainerRef: ViewContainerRef,
      private readonly templateRef: TemplateRef<{readonly ngLet: T}>,
  ) {
    const directiveInstance = this;

    this.viewContainerRef.createEmbeddedView(this.templateRef, {
      get ngLet() {
        return directiveInstance.ngLet;
      }
    });
  }
}
