import {Injectable} from '@angular/core';
import {MAC} from 'google3/third_party/javascript/closure/useragent/useragent';

/**
 * Service to provide platform information(Mac or PC).
 */
@Injectable({providedIn: 'root'})
export class PlatformService {
  get isMac(): boolean {
    return MAC;
  }
}
