<ng-container
  *ngIf="(conversation$ | async) && (humanAgentParticipant$ | async)"
>
  <dialogflow-assist
    (onCancelWorkflow)="handleAnalyzeContent($event)"
    (onSelectHeadIntent)="handleAnalyzeContent($event)"
    (onSubmitWorkflowEntry)="handleAnalyzeContent($event)"
    (onConfirmNominatedHeadIntent)="handleAnalyzeContent($event)"
    (onSelectResponseMessage)="handleChooseResponseMessage($event)"
    [cancelWorkflow]="cancelWorkflow$"
    [debugMode]="false"
    [analyzeContentResponses]="analyzeContentResponses$ | async"
    [conversation]="conversation$ | async"
    [loadingState]="loadingState$ | async"
    [humanAgentParticipant]="humanAgentParticipant$ | async"
    [endUserParticipantLoadStatus]="endUserParticipantLoadStatus$ | async"
    [showUseSuggestionButton]="showUseSuggestionButton"
    class="dialogflow-assist"
  ></dialogflow-assist>
</ng-container>
