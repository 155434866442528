import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DoBootstrap, NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/mdc-snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotificationModule} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_module';
import {ConversationSummarizationModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/conversation_summarization/conversation_summarization_module';

import {Summarization} from './summarization';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    ConversationSummarizationModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    NotificationModule,
  ],
  declarations: [
    Summarization,
  ],
  exports: [
    Summarization,
  ],
  entryComponents: [
    Summarization,
  ],
})
export class SummarizationModule implements DoBootstrap {
  ngDoBootstrap() {}
}
