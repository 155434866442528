<card-form appearance="outline">
  <card-form-header>
    <card-form-title
      role="heading"
      aria-level="3"
      i18n="Header for choosing a head intent"
    >
      Choose a head intent
    </card-form-title>
  </card-form-header>

  <section *ngLet="(headIntents$ | async) as headIntents">
    <div class="head-intents__head-intent-count">
      <h4
        class="gmat-headline-6"
        i18n="Header to indicate the number of head intents matched"
      >
        {{headIntents.length // i18n(ph = "Number of head intents")}} matches
        found
      </h4>
    </div>

    <head-intent
      *ngFor="let dialogflowAssistHeadIntent of headIntents; let i = index; trackBy: trackBy;"
      (onChoose)="handleChooseHeadIntent($event)"
      [dialogflowAssistHeadIntent]="dialogflowAssistHeadIntent"
      [disabled]="disabled"
      [debugMode]="debugMode"
      [index]="i"
    ></head-intent>
  </section>
</card-form>
