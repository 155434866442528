import {Inject, Injectable, OnDestroy} from '@angular/core';

import {WINDOW_INJECTION_TOKEN} from '../window_provider/window_provider';

import {KeyCommand} from './key_command';
import {PlatformService} from './platform_service';
import {ShortcutAction} from './shortcut_action';
import {ShortcutRegistryService} from './shortcut_registry_service';

/**
 * Shortcut handler function.
 */
export type ShortcutHandler = () => void;

/**
 * Service to register to register shortcut handlers.
 */
@Injectable({providedIn: 'root'})
export class ShortcutHandlersService implements OnDestroy {
  /** Map of registered handlers. */
  private readonly handlers = new Map<string, ShortcutHandler>();
  private readonly boundedOnKeyDownListener = this.onKeyDown.bind(this);

  constructor(
      @Inject(WINDOW_INJECTION_TOKEN) private readonly window: Window,
      private readonly platformService: PlatformService,
      private readonly registry: ShortcutRegistryService) {
    this.window.addEventListener('keydown', this.boundedOnKeyDownListener);
  }

  onKeyDown(e: KeyboardEvent) {
    const keyCommand = new KeyCommand({
      key: e.key,
      altKey: e.altKey,
      ctrlKey: e.ctrlKey,
      metaKey: e.metaKey,
      shiftKey: e.shiftKey,
    });
    const actions = this.registry.matchActions(keyCommand);
    if (!actions.length) {
      return;
    }

    for (const action of actions) {
      if (this.handlers.has(action.key)) {
        this.handlers.get(action.key)!();
      }
    }
  }

  ngOnDestroy() {
    this.window.removeEventListener('keydown', this.boundedOnKeyDownListener);
  }

  registerHandler(action: ShortcutAction, handler: ShortcutHandler) {
    this.handlers.set(action.key, handler);
  }

  removeHandler(action: ShortcutAction) {
    this.handlers.delete(action.key);
  }

  getControlMetaButtonName(): string {
    return this.platformService.isMac ? 'Command' : 'Control';
  }
}
