import {COMMON_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/icons';
import {dedupeIconList} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/icon_helpers';

/**
 * Material icons used by the Knowledge Assist UI module.
 *
 * Icons names and versions can be found at
 * https://icons.corp.google.com/?icon.set=Google%20Material
 */
export const KNOWLEDGE_ASSIST_ICONS = dedupeIconList([
  ...COMMON_ICONS,
  {name: 'arrow_back', version: 17},
  {name: 'arrow_left', version: 12},
  {name: 'article', version: 13},
  {name: 'close', version: 18},
  {name: 'keyboard_arrow_down', version: 15},
  {name: 'picture_as_pdf', version: 11},
  {name: 'question_answer', version: 12},
  {name: 'search', version: 16},
  {name: 'thumb_down', version: 17},
  {name: 'thumb_up', version: 17},
  {name: 'thumb_up_filled', version: 11},
]);
