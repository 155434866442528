import {Component, Input, ViewEncapsulation} from '@angular/core';

/**
 * A component to be used around text so the triggering area is properly styled
 * according to the Reach spec:
 * https://carbon.googleplex.com/reach-ux/pages/tooltips/spec#b22b8cba-646c-4be0-8649-942437b3fe80
 *
 * Example usage with xapInlineDialog:
 * ```
 * <xap-text-trigger [xapInlineDialog]="myAwesomeTemplate">
 *   Hover to see a dialog
 * </xap-text-trigger>
 *
 * <ng-template #myAwesomeTemplate>
 * ...
 * </ng-template>
 * ```
 *
 * Note you can also mix this trigger with xap-bubble just to get the styles on
 * the triggering area:
 * ```
 * <xap-bubble [panelTemplate]="myBubbleTemplate">
 *   <xap-text-trigger>Backwards compatible with xapBubble!</xap-text-trigger>
 * </xap-bubble>
 *
 * <ng-template #myBubbleTemplate>
 * ...
 * </ng-template>
 * ```
 */
@Component({
  selector: 'xap-text-trigger',
  template: '<ng-content></ng-content>',
  styleUrls: ['./inline_dialog_trigger.css'],
  host: {
    '[class.xap-text-trigger]': '!this.disabled',
    '[attr.tabindex]': 'this.disabled? -1 : 0',
    'role': 'button',
  },
  encapsulation: ViewEncapsulation.None,
})
export class XapTextTrigger {
  @Input('xapInlineDialogDisabled') disabled: boolean = false;
}

/**
 * A component to be used around a mat-icon so the triggering area is properly
 * treated with accessibility features.
 *
 * @deprecated Use mat-icon-button as the trigger element instead.
 * Example mat-icon-button usage:
 * ```
 * <button mat-icon-button [xapInlineDialog]="myAwesomeTemplate">
 *   <mat-icon>help_outline</mat-icon>
 * </button>
 *
 * <ng-template #myAwesomeTemplate>
 * ...
 * </ng-template>
 * ```
 */
@Component({
  selector: 'xap-icon-trigger',
  template: '<ng-content></ng-content>',
  styleUrls: ['./inline_dialog_trigger.css'],
  host: {
    // TODO(sqwang): hide the icon when xapInlineDialogDisabled is set to true
    'class': 'xap-icon-trigger',
    'role': 'button',
    'tabindex': '0',
  },
  encapsulation: ViewEncapsulation.None,
})
export class XapIconTrigger {
  @Input('xapInlineDialogDisabled') disabled: boolean = false;
}
