import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Observable} from 'rxjs';

/**
 * Component that wraps each individual UI module in a re-arrangable card
 * component with a title.
 */
@Component({
  selector: 'module-wrapper',
  templateUrl: './module_wrapper.ng.html',
  styleUrls: ['./module_wrapper.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleWrapper {
  @Input() header = '';

  /**
   * An observable that, if it emits, will cause the content-wrapper element to
   * scroll to it's most recently added child.
   */
  @Input() scrollStream?: Observable<unknown>;
}
