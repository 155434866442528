import {NgModule} from '@angular/core';

import {XapLet} from './xap_let';

@NgModule({
  declarations: [XapLet],
  exports: [XapLet],
})
export class XapLetModule {
}
