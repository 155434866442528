import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, Renderer2, SimpleChanges, ViewChildren} from '@angular/core';
import {ModuleWrapper} from 'google3/cloud/ai/contactcenter/apps/ui_modules/components/container/module_wrapper/module_wrapper';
import {UiModulesBaseConnector} from 'google3/cloud/ai/contactcenter/apps/ui_modules/connectors/ui_modules/ui_modules_connector';
import {AGENT_DESKTOPS_WITHOUT_NATIVE_HEADER} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/agent_desktop_constants';
import {DARK_MODE_BACKGROUND_ELEMENT_CLASS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/dark_mode_constants';
import {UI_MODULE_ICONS} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/injection_tokens';
import {fromUiModuleErrorEvent, fromUiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {getDefaultDarkModeBackground} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/dark_mode_helpers';
import {isLoaded, isLoading, isResolved} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/loading_state_helpers';
import {DarkModeService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/dark_mode/dark_mode_service';
import {UiModuleIconService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/icon/ui_module_icon_service';
import {NotificationService} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/notification/notification_service';
import {UiModuleStore} from 'google3/cloud/ai/contactcenter/apps/ui_modules/services/store/ui_module_store';
import {AgentDesktop} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/agent_desktop';
import {CommunicationChannel, ConnectorConfig, EventBasedLibrary, EventBasedTransport} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/connector_types';
import {UiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {LoadingState} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/loading_state';
import {UiModuleContainerConfig} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/ui_module_container_config';
import {KNOWLEDGE_ASSIST_FEATURES} from 'google3/java/com/google/dialogflow/console/web/ccai/common/constants';
import {GoogleRpcStatus, SuggestionFeature_Type} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';
import {getArrayIntersection} from 'google3/java/com/google/dialogflow/console/web/common/utils/get_array_intersection';
import {combineLatest, merge, ReplaySubject} from 'rxjs';
import {map, startWith, take, takeUntil} from 'rxjs/operators';

import {CONTAINER_MARGIN_PX, DRAG_CONTAINER_MARGIN_PX, ICON_LIST, SUPPORTED_SUGGESTION_FEATURES, UI_MODULE_FEATURES, UiModuleFeature} from './container_constants';



/** Element selector for Agent Assist UI Modules container component. */
export const UI_MODULES_CONTAINER_ELEMENT_SELECTOR = 'agent-assist-ui-modules';

interface State {
  readonly initializationLoadingState: LoadingState;
  readonly initializationCancelled: boolean;
  readonly error: GoogleRpcStatus|string|null;
}

const initialState: State = {
  initializationLoadingState: 'LOADING',
  initializationCancelled: false,
  error: null,
};

/** Main container component that will house all UI modules. */
@Component({
  selector: UI_MODULES_CONTAINER_ELEMENT_SELECTOR,
  templateUrl: './container.ng.html',
  styleUrls: ['./container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {'class': DARK_MODE_BACKGROUND_ELEMENT_CLASS},
  providers: [
    {provide: UI_MODULE_ICONS, useValue: ICON_LIST},
    UiModuleIconService,
    UiModuleStore,
  ],
})
export class Container implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  /**
   * Comma-separated list of Agent Assist suggestion features to render.
   * Example: "SMART_REPLY, CONVERSATION_SUMMARIZATION"
   */
  @Input() features = '';

  /**
   * Additional headers to include in Dialogflow API calls.
   * Example: "Content-Type:application/json, Accept:application/json"
   */
  @Input('api-headers') apiHeaders = '';

  /** Name of the conversation profile to use. */
  @Input('conversation-profile') readonly conversationProfile!: string;

  /** Whether to use a custom conversation ID. */
  @Input('use-custom-conversation-id')
  readonly useCustomConversationId: boolean = true;

  /** Agent desktop software to integrate with. */
  @Input('agent-desktop') readonly agentDesktop!: AgentDesktop;

  /** Authentication token to use for API calls. */
  @Input('auth-token') readonly authToken!: string;

  /** Optional API key to use for API calls. */
  @Input('api-key') readonly apiKey?: string;

  /**
   * Communication channel used for this application (chat, voice, or
   * omnichannel).
   */
  @Input() readonly channel: CommunicationChannel = 'chat';

  /**
   * Optional custom API endpoint to use (if UI modules are configured with a
   * proxy server).
   */
  @Input('custom-api-endpoint') readonly customApiEndpoint?: string;

  /** Color theme to use. */
  @Input() readonly theme: 'dark'|'light' = 'dark';

  /** Whether to show the 'Agent Assist suggestions' header. */
  @Input('show-header') readonly showHeader?: boolean;

  /**
   * Background color to use for dark mode.
   *
   * If none is specified, defaults are provided for the primary supported agent
   * desktops.
   */
  @Input('dark-mode-background') darkModeBackground?: string;

  /** Notifier server endpoint to use for event-based conversations. */
  @Input('notifier-server-endpoint') notifierServerEndpoint?: string;

  /** Transport protocol to use for event-based conversations. */
  @Input('event-based-transport') eventBasedTransport?: EventBasedTransport;

  /** Library to use for event-based conversations. */
  @Input('event-based-library') eventBasedLibrary?: EventBasedLibrary;

  /** Configuration object to define module-specific configurations. */
  @Input() readonly config?: UiModuleContainerConfig;

  @ViewChildren(ModuleWrapper, {read: ElementRef})
  readonly moduleWrappers!: QueryList<ElementRef>;

  readonly initializationLoadingState$ = this.store.selectForActiveConversation(
      state => state.initializationLoadingState);
  readonly initializationCancelled$ = this.store.selectForActiveConversation(
      state => state.initializationCancelled);
  readonly error$ =
      this.store.selectForActiveConversation(state => state.error);

  readonly initializing$ =
      this.initializationLoadingState$.pipe(map(isLoading));

  readonly initialized$ = this.initializationLoadingState$.pipe(map(isLoaded));

  readonly initializationResolved$ =
      this.initializationLoadingState$.pipe(map(isResolved));

  readonly setInitializationLoadingState = this.store.updater(
      (state,
       initializationLoadingState: State['initializationLoadingState']) =>
          ({...state, initializationLoadingState}));


  readonly setError =
      this.store.updater((state, error: State['error']) => ({...state, error}));

  readonly setInitializationCancelled = this.store.updater(
      (state, initializationCancelled: State['initializationCancelled']) =>
          ({...state, initializationCancelled}));

  /** The number of UI modules rendered on the page. */
  modulesCount = 0;

  /** Knowledge Assist suggestion features specified by the user. */
  knowledgeAssistFeatures = '';

  /** List of ordered UI Module features to render. */
  orderedFeatures: UiModuleFeature[] = [];

  /**
   * Global errors that should be surfaced in the top UI module error
   * component.
   */
  private readonly globalErrors$ = merge(
      fromUiModuleErrorEvent('ANALYZE_CONTENT'),
      fromUiModuleErrorEvent('INITIALIZATION'),
      fromUiModuleErrorEvent('AUTHORIZATION'),
  );

  private readonly destroyed$ = new ReplaySubject(1);

  /**
   * Determines the dark mode background color to apply based on the
   * user-specified value, or if it is not set, the agent desktop.
   */
  get internalDarkModeBackground() {
    return this.darkModeBackground ||
        getDefaultDarkModeBackground(this.agentDesktop);
  }

  /**
   * Whether to show the 'Agent Assist suggestions' header in the template.
   * Determined based on the user input, or if no input is provided, from the
   * agent desktop specified.
   */
  get internalShowHeader() {
    if (this.showHeader !== undefined) {
      return this.showHeader;
    }

    return AGENT_DESKTOPS_WITHOUT_NATIVE_HEADER.includes(this.agentDesktop);
  }

  get onSaveSummary() {
    return this.config?.summarizationConfig?.onSaveSummary;
  }

  get showGenerateSummaryButton() {
    return this.config?.summarizationConfig?.showGenerateSummaryButton;
  }

  private get moduleMaxHeight() {
    const extraSpacePerModule =
        (CONTAINER_MARGIN_PX / this.modulesCount) + DRAG_CONTAINER_MARGIN_PX;

    return `calc(${Math.floor(100 / this.modulesCount)}vh - ${
        extraSpacePerModule}px)`;
  }

  constructor(
      private readonly cdr: ChangeDetectorRef,
      private readonly darkMode: DarkModeService,
      private readonly notificationService: NotificationService,
      private readonly renderer: Renderer2,
      private readonly uiModulesBaseConnector: UiModulesBaseConnector,
      private readonly store: UiModuleStore<State>,
      uiModuleIconService: UiModuleIconService,
  ) {
    uiModuleIconService.registerIcons();
    store.init(initialState);
  }

  async ngOnInit() {
    this.validateInputs();

    this.notificationService.initContainer();

    this.darkMode.initStyles(
        {backgroundColor: this.internalDarkModeBackground});

    fromUiModuleEvent(UiModuleEvent.DARK_MODE_TOGGLED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({on}) => {
          if (on) {
            this.darkMode.set();
          } else {
            this.darkMode.unset();
          }
        });

    fromUiModuleEvent(UiModuleEvent.CONVERSATION_INITIALIZED)
        .pipe(take(1))
        .subscribe(({conversation}) => {
          this.store.setStateForConversation(
              conversation.name!,
              state => ({...state, initializationLoadingState: 'LOADED'}));
        });

    // Subscribe to voice conversation messages.
    if (this.channel === 'voice' || this.channel === 'omnichannel') {
      combineLatest([
        fromUiModuleEvent(UiModuleEvent.CONVERSATION_INITIALIZED),
        fromUiModuleEvent(UiModuleEvent.EVENT_BASED_CONNECTOR_INITIALIZED),
      ])
          .pipe(
              takeUntil(this.destroyed$),
              take(1),
              )
          .subscribe(([{conversation}]) => {
            this.uiModulesBaseConnector.subscribeToEventBasedConversation(
                conversation.name!);
          });
    }

    // Remove error notification if AnalyzeContent call succeeds. The response
    // may contain feature-specific errors, however these should be handled in
    // the respective feature modules.
    fromUiModuleEvent(UiModuleEvent.ANALYZE_CONTENT_RESPONSE_RECEIVED)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName}) => {
          this.setError(null, conversationName);
        });

    fromUiModuleErrorEvent('INITIALIZATION')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName}) => {
          this.setInitializationLoadingState('ERROR', conversationName);
        });

    this.globalErrors$.pipe(takeUntil(this.destroyed$))
        .subscribe(({conversationName, error}) => {
          this.setError(error, conversationName);
        });

    await this.initializeConnectors();
  }

  ngAfterViewInit() {
    this.moduleWrappers.changes
        .pipe(
            startWith(this.moduleWrappers),
            takeUntil(this.destroyed$),
            )
        .subscribe((moduleWrappers: QueryList<ElementRef>) => {
          this.modulesCount = moduleWrappers.length;

          for (const wrapper of moduleWrappers) {
            this.renderer.setStyle(
                wrapper.nativeElement, 'max-height', this.moduleMaxHeight);
          }

          this.cdr.detectChanges();
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    const token = changes['authToken']?.currentValue;
    const features = changes['features']?.currentValue;

    if (token) {
      this.uiModulesBaseConnector.setAuthToken(token);
    }

    if (features) {
      const featuresArray =
          this.features.split(',').filter(Boolean) as SuggestionFeature_Type[];

      const parsedFeatures = featuresArray.reduce((acc, feature) => {
        if (KNOWLEDGE_ASSIST_FEATURES.includes(feature)) {
          acc.add('KNOWLEDGE_ASSIST');
        } else if (SUPPORTED_SUGGESTION_FEATURES.includes(feature)) {
          acc.add(feature as UiModuleFeature);
        }
        return acc;
      }, new Set<UiModuleFeature>());

      this.orderedFeatures =
          UI_MODULE_FEATURES.filter(feature => parsedFeatures.has(feature));

      this.knowledgeAssistFeatures =
          getArrayIntersection(featuresArray, KNOWLEDGE_ASSIST_FEATURES)
              .join(',');
    }

    // In order to respond to input changes outside of Angular, explicitly mark
    // the view as changed.
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  drop(event: CdkDragDrop<SuggestionFeature_Type[]>) {
    moveItemInArray(
        event.container.data, event.previousIndex, event.currentIndex);
    this.cdr.detectChanges();
  }

  private async initializeConnectors() {
    const config: ConnectorConfig = {
      agentDesktop: this.agentDesktop,
      channel: this.channel,
      conversationProfileName: this.conversationProfile,
      useCustomConversationId: this.useCustomConversationId,
      apiConfig: {
        authToken: this.authToken,
        apiKey: this.apiKey,
        customApiEndpoint: this.customApiEndpoint,
        headers: this.parseHeaders(this.apiHeaders),
      },
    };

    if (this.channel === 'voice' || this.channel === 'omnichannel') {
      if (!this.notifierServerEndpoint) {
        this.setInitializationCancelled(true);
        this.setError(
            'Configuration error: Notifier server endpoint must be specified if the voice communication channel is used.');
        return;
      }

      config.eventBasedConfig = {
        library: this.eventBasedLibrary,
        transport: this.eventBasedTransport,
        notifierServerEndpoint: this.notifierServerEndpoint
      };
    }

    await this.uiModulesBaseConnector.init(config);
  }

  private parseHeaders(headers: string) {
    return headers.split(',').filter(Boolean).map(pair => {
      const [header, value] = pair.split(':');
      return [header.trim(), value.trim()] as const;
    });
  }

  private validateInputs() {
    if (!this.features) {
      throw new Error(
          `Please pass a list of comma-separated suggestion features to <${
              UI_MODULES_CONTAINER_ELEMENT_SELECTOR}>.`);
    }

    if (!this.conversationProfile) {
      throw new Error(`Please pass a conversation profile name to <${
          UI_MODULES_CONTAINER_ELEMENT_SELECTOR}>.`);
    }

    if (!this.agentDesktop) {
      throw new Error(`Please pass an agent desktop name to <${
          UI_MODULES_CONTAINER_ELEMENT_SELECTOR}>.`);
    }
  }
}
