import {dispatchUiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {UiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {HttpOptions} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/http_options';
import {from} from 'rxjs';

import {HttpService} from './http_service';

/**
 * Dialogflow-specific HTTP service. Handle error responses appropriately,
 * including dispatching an auth error action on token expiration.
 */
export class DialogflowHttpService extends HttpService {
  protected override makeRequest<T extends object>(
      {method, endpoint, body, options}: {
        method: 'GET'|'POST'|'PATCH',
        endpoint: string,
        options?: HttpOptions,
        body?: T
      }) {
    const headers: Array<[string, string]> = [
      ['Content-Type', 'application/json'],
      ['Accept', 'application/json'],
      ...(options?.headers || []),
    ];

    if (this.apiConfig.authToken) {
      headers.push(['Authorization', this.apiConfig.authToken]);
    }

    const request: RequestInit = {method, headers};

    if (body) {
      request.body = JSON.stringify(body);
    }

    return from(fetch(endpoint, request).then(response => {
      if (response.status === 401) {
        dispatchUiModuleEvent(
            UiModuleEvent.DIALOGFLOW_API_AUTHENTICATION_ERROR);
      }

      if (!response.ok) {
        return response.json().then(body => {
          throw body;
        });
      }

      return response.json();
    }));
  }
}
