import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeHtml as NgSafeHtml, SafeResourceUrl as NgSafeResourceUrl, SafeUrl as NgSafeUrl, SafeValue, ɵDomSanitizerImpl as DefaultDomSanitizerImpl} from '@angular/platform-browser-unsafe';
import {SafeHtml, SafeUrl, TrustedResourceUrl, unwrapUrl} from 'safevalues';

const ERR_MSG =
    'bypassSecurityTrust* functions are prohibited in the google3 version of Angular. Use LegacyDomSanitizer if you need to disable auto sanitization and get a security from ise-hardening-reviews.';
/**
 * A DomSanitizer decorating the stock DomSanitizerImpl, which understands
 * the TS safe types (additionnaly to what the DomSanitizerImpl supports).
 */
@Injectable()
export class DomSanitizerWithSafeValuesImpl extends DomSanitizer {
  private decorated: DefaultDomSanitizerImpl;

  // Workaround for https://github.com/angular/angular/issues/12631
  // tslint:disable-next-line:no-any
  constructor(@Inject(DOCUMENT) doc: any) {
    super();
    this.decorated = new DefaultDomSanitizerImpl(doc);
  }

  /** Turns value into something safe for context ctx. */
  sanitize(ctx: SecurityContext, value: {}): string|null {
    if (value == null) return null;
    if (value instanceof TrustedResourceUrl || value instanceof SafeUrl ||
        value instanceof SafeHtml) {
      if (this.isTsSafeValueSatisfyingContext(value, ctx)) {
        // This is a safe TS type. We convert it to the equivalent stock
        // Angular safe type, and send it to the original DomSanitizer to ensure
        // it behaves as if it understood the type.
        return this.decorated.sanitize(
            ctx, this.genericConversionToNgType(value));
      } else {
        // We see a Closure type, but not the expected one for that sink.
        throw new Error(
            `Required a safe value for ${SecurityContext[ctx]}, got ${value} ` +
            `(see http://g.co/ng/security#xss)`);
      }
    }
    // Otherwise, we didn't recognize a safe TS type. Let the decorated
    // DomSanitizer handle it.
    return this.decorated.sanitize(ctx, value);
  }

  /**
   * @deprecated bypassSecurityTrust* methods are deprecated in google3 version
   * of Angular. Use LegacyDomSanitizer#bypassSecurityTrustHtml instead and get
   * a security review from ise-hardening-reviews.
   */
  bypassSecurityTrustHtml(value: string): never {
    throw new Error(ERR_MSG);
  }

  /**
   * @deprecated bypassSecurityTrust* methods are deprecated in google3 version
   * of Angular. Use LegacyDomSanitizer#bypassSecurityTrustStyle instead and get
   * a security review from ise-hardening-reviews.
   */
  bypassSecurityTrustStyle(value: string): never {
    throw new Error(ERR_MSG);
  }

  /**
   * @deprecated bypassSecurityTrust* methods are deprecated in google3 version
   * of Angular. Use LegacyDomSanitizer#bypassSecurityTrustScript instead and
   * get a security review from ise-hardening-reviews.
   */
  bypassSecurityTrustScript(value: string): never {
    throw new Error(ERR_MSG);
  }

  /**
   * @deprecated bypassSecurityTrust* methods are deprecated in google3 version
   * of Angular. Use LegacyDomSanitizer#bypassSecurityTrustUrl instead and get a
   * security review from ise-hardening-reviews.
   */
  bypassSecurityTrustUrl(value: string): never {
    throw new Error(ERR_MSG);
  }

  /**
   * @deprecated bypassSecurityTrust* methods are deprecated in google3 version
   * of Angular. Use LegacyDomSanitizer#bypassSecurityTrustResourceUrl instead
   * and get a security review from ise-hardening-reviews.
   */
  bypassSecurityTrustResourceUrl(value: string): never {
    throw new Error(ERR_MSG);
  }

  /**
   * Generic conversion from Ts safe types to the corresponding Angular
   * type, which returns null in other cases. This is a convenience function: if
   * you know which type is expected, use the specific function because its
   * return value will be typed correctly.
   */
  private genericConversionToNgType(value: TrustedResourceUrl|SafeUrl|
                                    SafeHtml): SafeValue|null {
    if (value == null) {
      return null;
    } else if (value instanceof SafeHtml) {
      return this.toNgSafeHtml(value);
    } else if (value instanceof SafeUrl) {
      return this.toNgSafeUrl(value);
    } else if (value instanceof TrustedResourceUrl) {
      return this.toNgSafeResourceUrl(value);
    }
    // Safescript is disabled for now
    return null;
  }

  /**
   * Check whether the given value is a safe type adequate for the given
   * context. This will return false for anything else than ts safe types.
   */
  private isTsSafeValueSatisfyingContext(value: {}, context: SecurityContext) {
    switch (context) {
      case SecurityContext.HTML:
        return value instanceof SafeHtml;
      case SecurityContext.URL:
        return (
            value instanceof SafeUrl || value instanceof TrustedResourceUrl);
      case SecurityContext.RESOURCE_URL:
        return value instanceof TrustedResourceUrl;
      case SecurityContext.SCRIPT:
        return false;  // disabled for now
      default:
        return false;
    }
  }

  /** Converts SafeHtml to an Angular-trusted HTML. */
  private toNgSafeHtml(tsValue: SafeHtml): NgSafeHtml {
    return this.decorated.bypassSecurityTrustHtml(String(tsValue));
  }

  /** Convert SafeUrl to an Angular-trusted URL. */
  private toNgSafeUrl(tsValue: SafeUrl): NgSafeUrl {
    return this.decorated.bypassSecurityTrustUrl(unwrapUrl(tsValue));
  }

  /** Convert TrustedResourceUrl to an Angular-trusted resource URL. */
  private toNgSafeResourceUrl(tsValue: TrustedResourceUrl): NgSafeResourceUrl {
    return this.decorated.bypassSecurityTrustResourceUrl(String(tsValue));
  }
}

/**
 * Add this to the providers array of your Angular app to replace its
 * DomSanitizer with one that understands TS safe types.
 */
export const PROVIDE_SAFEVALUES_COMPAT = [
  {
    provide: DomSanitizerWithSafeValuesImpl,
    useClass: DomSanitizerWithSafeValuesImpl
  },
  {provide: DomSanitizer, useExisting: DomSanitizerWithSafeValuesImpl}
];
