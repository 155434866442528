/**
 * Filters an array for truthy values, and correctly types the filtered array to
 * omit falsy types.
 *
 * i.e.,
 * const array = ['a', null, 'b'];   // typed as (string|null)[]
 * array.filter(Boolean)   // returns ['a', 'b'], but still typed as
 * (string|null)[]
 * filterTruthy(array);   // returns ['a', 'b'], now correctly typed as string[]
 */
export function filterTruthy<T>(array: ReadonlyArray<T>) {
  return array.filter((v): v is NonNullable<typeof v> => Boolean(v));
}
