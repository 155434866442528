import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ArticleSearchModule} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/document_and_faq/article_search/article_search_module';
import {LetDirectiveModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/let/let_directive_module';
import {ScrollOnEmitModule} from 'google3/java/com/google/dialogflow/console/web/common/directives/scroll_on_emit/scroll_on_emit_module';
import {XapClipboardModule} from 'google3/javascript/angular2/components/clipboard/clipboard_module';
import {XapLetModule} from 'google3/javascript/angular2/components/let/xap_let_module';

import {DocumentAndFaq} from './document_and_faq';

@NgModule({
  imports: [
    ArticleSearchModule,
    CommonModule,
    LetDirectiveModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ScrollOnEmitModule,
    XapClipboardModule,
    XapLetModule,
  ],
  declarations: [
    DocumentAndFaq,
  ],
  exports: [
    DocumentAndFaq,
  ]
})
export class DocumentAndFaqModule {
}
