/**
 * @fileoverview This file contains constants and helper functions related to
 * the visibility of features in the Agent Assist console. Please see
 * //depot/google3/cloud/ml/api/conversation/g3doc/development/visibility.md for
 * more details.
 */

import {AgentAssistConsoleFeature, VisibilityLabelType} from 'google3/java/com/google/dialogflow/console/web/ccai/common/types';
import {VisibilityLabels} from 'google3/java/com/google/dialogflow/console/web/common/constants/constants';


/**
 * Visibility labels for features currently under construction.
 * Having any of the labels in the array will allow you to see 'Under
 * Construction' features.
 */
const UNDER_CONSTRUCTION_VISIBILITY_LABELS: VisibilityLabels[] = [
  VisibilityLabels.DIALOGFLOW_UNDER_CONSTRUCTION,
];

/**
 * Visibility labels for alpha features.
 * Having any of the labels in the array will allow you to see 'Alpha' features.
 */
const ALPHA_VISIBILITY_LABELS: VisibilityLabels[] = [
  VisibilityLabels.CCAI_ALPHA,
  VisibilityLabels.DIALOGFLOW_ALPHA,
  ...UNDER_CONSTRUCTION_VISIBILITY_LABELS,
];

/**
 * Visibility labels for beta features.
 * Having any of the labels in the array will allow you to see 'Beta' features.
 */
const BETA_VISIBILITY_LABELS: VisibilityLabels[] = [
  VisibilityLabels.CCAI_BETA,
  ...ALPHA_VISIBILITY_LABELS,
];

/**
 * Visibility labels for GA features.
 * Having any of the labels in the array will allow you to see 'GA' features.
 */
const GA_VISIBILITY_LABELS: VisibilityLabels[] = [
  VisibilityLabels.CCAI_GA,
  ...BETA_VISIBILITY_LABELS,
];

/**
 * Visibility labels for AAM customers.
 * Having any of the labels in the array will allow you to see AAM features.
 */
const AAM_VISIBILITY_LABELS: VisibilityLabels[] = [
  VisibilityLabels.DIALOGFLOW_AAM,
];

/** Map of visibility labels types to array of visibility labels. */
const VISIBILITY_LABELS = new Map<VisibilityLabelType, VisibilityLabels[]>([
  ['ALPHA', ALPHA_VISIBILITY_LABELS],
  ['BETA', BETA_VISIBILITY_LABELS],
  ['GA', GA_VISIBILITY_LABELS],
  ['UNDER_CONSTRUCTION', UNDER_CONSTRUCTION_VISIBILITY_LABELS],
  ['AAM', AAM_VISIBILITY_LABELS],
  ['NONE', []],
]);

/** Map of visibility labels for each Agent Assist console feature. */
// LINT.IfChange
const FEATURE_VISIBILITY_LABELS =
    new Map<AgentAssistConsoleFeature, VisibilityLabelType>([
      // Smart compose suggestion feature.
      ['SMART_COMPOSE', 'ALPHA'],
      // Smart reply suggestion feature.
      ['SMART_REPLY', 'NONE'],
      // Conversation profile sentiment analysis.
      ['SENTIMENT_ANALYSIS', 'NONE'],
      // Conversation datasets list view.
      ['CONVERSATION_DATASETS', 'NONE'],
      // Knowledge bases list view.
      ['KNOWLEDGE_BASES', 'NONE'],
      // Models list view.
      ['MODELS', 'NONE'],
      // Article suggestion model creation and dataset objective.
      ['ARTICLE_SUGGESTION', 'NONE'],
      // Article suggestion suggestion feature (conversation profiles).
      ['ARTICLE_SUGGESTION_CUSTOM_MODEL', 'BETA'],
      // Article suggestion knowledge base documents.
      ['ARTICLE_SUGGESTION_DOCUMENT', 'NONE'],
      // Dialogflow Assist suggestion feature.
      ['DIALOGFLOW_ASSIST', 'ALPHA'],
      // Conversation summarization baseline model feature.
      ['CONVERSATION_SUMMARIZATION_BASELINE', 'GA'],
      // Conversation summarization custom voice model training.
      ['CONVERSATION_SUMMARIZATION_CUSTOM_VOICE_MODEL_TRAINING', 'ALPHA'],
      // Conversation summarization custom chat v2 model training.
      ['CONVERSATION_SUMMARIZATION_CUSTOM_CHAT_V2_MODEL_TRAINING', 'ALPHA'],
      // Conversation guidance feature.
      ['CONVERSATION_GUIDANCE', 'UNDER_CONSTRUCTION'],
      // Conversation key moments feature.
      ['KEY_MOMENT', 'ALPHA'],
      // AAM experiments feature.
      ['AAM_EXPERIMENTS', 'NONE'],
      // Gcs Selector for creation of data sets.
      ['GCS_SELECTOR', 'UNDER_CONSTRUCTION'],
      // Article Search suggestion feature.
      ['ARTICLE_SEARCH', 'UNDER_CONSTRUCTION'],
      // Languages not publicly available for resource configuration.
      ['UNSUPPORTED_LANGUAGES', 'UNDER_CONSTRUCTION'],
      // Security settings specification for creation of datasets
      ['DATASET_SECURITY_SETTINGS', 'UNDER_CONSTRUCTION'],
    ]);
// LINT.ThenChange(//depot/google3/java/com/google/dialogflow/console/web/ccai/overview/key_features/key_features.ts)

/** Get visibility labels for a certain visibility label type. */
export function getVisibilityLabels(labelType: VisibilityLabelType) {
  if (!VISIBILITY_LABELS.has(labelType)) {
    throw new Error(
        `Please update VISIBILITY_LABELS map to include ${labelType} labels.`);
  }

  return VISIBILITY_LABELS.get(labelType)!;
}

/** Get visibility labels for a certain visibility label type. */
export function getFeatureVisibilityLabels(feature: AgentAssistConsoleFeature) {
  if (!FEATURE_VISIBILITY_LABELS.has(feature)) {
    throw new Error(
        `Please update FEATURE_VISIBILITY_LABELS map to include the ${
            feature} feature.`);
  }

  const featureLabels = FEATURE_VISIBILITY_LABELS.get(feature)!;

  return getVisibilityLabels(featureLabels);
}
