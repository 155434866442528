import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {MAT_TOOLTIP_OPTIONS} from 'google3/java/com/google/dialogflow/console/web/ccai/common/constants';
import {DialogflowAssistElementTemplate, DialogflowAssistElementType} from 'google3/java/com/google/dialogflow/console/web/ccai/suggestion_features/dialogflow_assist/dialogflow_assist_types';
import {assertObject} from 'google3/javascript/typescript/contrib/assert';

type ResponseMessageTypes = Extract<
    DialogflowAssistElementType,
    'ENTRY_PROMPT'|'FORM_PARAMETER_PROMPT'|'SUPPLEMENTAL_RESPONSE'>;

/**
 * @desc Tooltip text for a response message of type 'ENTRY_PROMPT'. Shows the
 * response message's type along with its intended purpose.
 */
const MSG_ENTRY_PROMPT_TOOLTIP_MESSAGE = goog.getMsg(
    'Entry - Suggestion may not solicit a customer response that moves the form to the next step.');

/**
 * @desc Tooltip text for a response message of type 'FORM_PARAMETER_PROMPT'.
 * Shows the response message's type along with its intended purpose.
 */
const MSG_FORM_PARAMETER_PROMPT_TOOLTIP_MESSAGE = goog.getMsg(
    'Primary - Suggestion is intended to solicit a response that moves the form to the next step.');

/**
 * @desc Tooltip text for a response message of type 'FORM_PARAMETER_PROMPT'.
 * Shows the response message's type along with its intended purpose.
 */
const MSG_SUPPLEMENTAL_RESPONSE_TOOLTIP_MESSAGE = goog.getMsg(
    'Supplemental - Suggestion may not solicit a customer response that moves the form to the next step.');

const TOOLTIP_TEXT: {[K in ResponseMessageTypes]: string;} = {
  'ENTRY_PROMPT': MSG_ENTRY_PROMPT_TOOLTIP_MESSAGE,
  'FORM_PARAMETER_PROMPT': MSG_FORM_PARAMETER_PROMPT_TOOLTIP_MESSAGE,
  'SUPPLEMENTAL_RESPONSE': MSG_SUPPLEMENTAL_RESPONSE_TOOLTIP_MESSAGE,
};

/**
 * Response message for use with Dialogflow Assist section of Agent Assist
 * simulator.
 */
@Component({
  selector: 'response-message',
  templateUrl: './response_message.ng.html',
  styleUrls: ['./response_message.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        ...MAT_TOOLTIP_OPTIONS,
        position: 'above',
      },
    },
  ]
})
export class ResponseMessage implements OnInit {
  @Input() debugMode = false;
  @Input() readonly elementTemplate!: DialogflowAssistElementTemplate;

  @Output() readonly onChooseResponseMessage = new EventEmitter();

  get textResponseMessage() {
    return this.elementTemplate.responseMessage?.text?.text?.[0];
  }

  get customResponseMessage() {
    return this.elementTemplate.responseMessage?.payload;
  }

  get tooltipText() {
    return TOOLTIP_TEXT[this.elementTemplate.elementType as ResponseMessageTypes];
  }

  handleChooseResponseMessage() {
    this.onChooseResponseMessage.emit();
  }

  ngOnInit() {
    assertObject(this.elementTemplate);
  }
}
