import {Injectable} from '@angular/core';
import {ApiConnector} from 'google3/cloud/ai/contactcenter/apps/ui_modules/connectors/api/api_connector';
import {getLivePersonConnectorSource, getSocketIoConnectorSource} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/scripts';
import {ENVIRONMENT, UI_MODULE_WINDOW} from 'google3/cloud/ai/contactcenter/apps/ui_modules/constants/window';
import {dispatchUiModuleEvent, initUiModuleEvents} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/custom_event_helpers';
import {loadScript} from 'google3/cloud/ai/contactcenter/apps/ui_modules/helpers/script_load_helpers';
import {ConnectorConfig, ConnectorType, UiModuleConnector, UiModuleEventBasedConnector} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/connector_types';
import {UiModuleEvent} from 'google3/cloud/ai/contactcenter/apps/ui_modules/types/custom_events';
import {TrustedResourceUrl} from 'safevalues';

/**
 * Relevant connector details, including the connector source URL and success
 * and error actions for the loading of the connector.
 */
interface ConnectorDetails {
  source?: TrustedResourceUrl;
  initSuccessAction: UiModuleEvent;
  initErrorAction: UiModuleEvent;
}

/**
 * Base UI Modules connector that will load and initialize all necessary
 * connectors.
 */
@Injectable({providedIn: 'root'})
export class UiModulesBaseConnector implements UiModuleConnector {
  /**
   * Deployed connector source URLs. Used to load connectors asynchronously in
   * a <script> tag.
   */
  private static readonly connectorDetails =
      new Map<ConnectorType, ConnectorDetails>([
        [
          'LivePerson', {
            source: getLivePersonConnectorSource(ENVIRONMENT),
            initSuccessAction: UiModuleEvent.LIVE_PERSON_CONNECTOR_INITIALIZED,
            initErrorAction: UiModuleEvent
                               .LIVE_PERSON_CONNECTOR_INITIALIZATION_FAILED,
          }
        ],
        [
          'SocketIo', {
            source: getSocketIoConnectorSource(ENVIRONMENT),
            initSuccessAction: UiModuleEvent.EVENT_BASED_CONNECTOR_INITIALIZED,
            initErrorAction: UiModuleEvent
                               .EVENT_BASED_CONNECTOR_INITIALIZATION_FAILED,
          }
        ],
        [
          'Api', {
            initSuccessAction: UiModuleEvent.API_CONNECTOR_INITIALIZED,
            initErrorAction: UiModuleEvent.API_CONNECTOR_INITIALIZATION_FAILED,
          }
        ]
      ]);

  private readonly connectors = new Map<ConnectorType, UiModuleConnector>();

  private config?: ConnectorConfig;

  constructor() {
    initUiModuleEvents();
  }

  /** @export */
  async init(config: ConnectorConfig) {
    this.config = config;

    this.connectors.set('Api', new ApiConnector(config));

    const {channel} = this.config;

    await this.loadAgentDesktopConnector();

    if (channel === 'omnichannel' || channel === 'voice') {
      await this.loadEventBasedConnector();
    }

    for (const [connectorType, connector] of this.connectors) {
      const {initSuccessAction, initErrorAction} =
          UiModulesBaseConnector.connectorDetails.get(connectorType)!;

      try {
        await connector.init();
        dispatchUiModuleEvent(initSuccessAction);
      } catch {
        dispatchUiModuleEvent(initErrorAction);
      }
    }
  }

  /**
   * @export
   * Sets the auth token for an agent desktop connector.
   */
  setAuthToken(authToken: string) {
    for (const connector of this.connectors.values()) {
      connector.setAuthToken(authToken);
    }
  }

  /** @export */
  disconnect() {
    for (const connector of this.connectors.values()) {
      connector.disconnect();
    }
  }

  /** @export */
  subscribeToEventBasedConversation(conversationName: string) {
    const eventBasedLibrary = this.config?.eventBasedConfig?.library!;

    const eventBasedConnector =
        this.connectors.get(eventBasedLibrary) as UiModuleEventBasedConnector |
        undefined;

    if (!eventBasedConnector) {
      throw new Error(
          'Cannot subscribe to conversation. No event-based connector has been loaded.');
    }

    eventBasedConnector.subscribeToConversation(conversationName);
  }

  private async loadEventBasedConnector() {
    const library = this.config?.eventBasedConfig?.library!;
    await this.loadConnector(library);
  }

  private async loadAgentDesktopConnector() {
    if (this.config?.agentDesktop !== 'Custom') {
      await this.loadConnector(this.config!.agentDesktop);
    }
  }

  /** Loads and stores the specified UI module connector. */
  private async loadConnector(connectorType:
                                  Exclude<ConnectorType, 'Api'|'Custom'>) {
    const sourceUrl =
        UiModulesBaseConnector.connectorDetails.get(connectorType)?.source;

    if (!sourceUrl) {
      throw new Error(`${connectorType} connector is not currently supported.`);
    }

    await loadScript(sourceUrl);

    // Type assertion is not actually unnecessary - Needed to index
    // UI_MODULE_WINDOW.
    // tslint:disable-next-line:no-unnecessary-type-assertion
    const connectorKey = `${connectorType}Connector` as const;

    const connector = connectorKey in UI_MODULE_WINDOW ?
        new UI_MODULE_WINDOW[connectorKey]!(this.config!) :
        undefined;

    if (connector) {
      this.connectors.set(connectorType, connector);
    }
  }
}

// tslint:disable-next-line:no-any
UI_MODULE_WINDOW.UiModulesConnector = UiModulesBaseConnector;
