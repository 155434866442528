import {AnalyzeContentResponse_} from 'google3/java/com/google/dialogflow/console/web/common/store/dialogflow_interfaces_only_ts_api_client';

/** Gets the Smart Reply answers from an AnalyzeContentResponse object. */
export const getSmartReplyAnswers =
    (response: AnalyzeContentResponse_|undefined) =>
        response?.humanAgentSuggestionResults
            ?.find(
                humanAgentSuggestionResult =>
                    humanAgentSuggestionResult.suggestSmartRepliesResponse
                        ?.smartReplyAnswers)
            ?.suggestSmartRepliesResponse?.smartReplyAnswers;
