import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import {XapCopyButton} from './copy_button';
import {XapCopyToClipboard} from './copy_to_clipboard';

/**
 * @deprecated This component is using legacy Angular material components.
 * Prefer XapClipboardModule from "clipboard_module_mdc.ts" for newer version of
 * the components. See components info in go/angular-mdc.
 */
@NgModule({
  declarations: [
    XapCopyButton,
    XapCopyToClipboard,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
  ],
  exports: [
    XapCopyButton,
    XapCopyToClipboard,
  ]
})
export class XapClipboardModule {
}
