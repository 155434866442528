import {InjectionToken} from '@angular/core';

import {RichTooltipPosition} from './rich_tooltip_position';

/** The default rich tooltip position. */
export const DEFAULT_RICH_TOOLTIP_POSITION = 'bottom';

/** The default rich tooltip render debounce. */
export const DEFAULT_RICH_TOOLTIP_RENDER_DEBOUNCE_MS = 0;

/**
 * If `RichTooltipManager` encounters a node with this class, it will identify
 * it as disabled.
 *
 * When determining whether to render an enabled or disabled rich tooltip (or
 * none at all) for a particular `event.target`, `RichTooltipManager` expects an
 * ancestor to be disabled. For most components, an ancestor will have
 * `disabled` or `aria-disabled` attributes, and thus be identified as disabled.
 *
 * However, for some components with weird and complicated templates (e.g.
 * `MatCheckbox`), no such ancestor will exist. Consequently, we use this CSS
 * class to inform the `RichTooltipManager` that the node should be treated as
 * disabled.
 */
export const RICH_TOOLTIP_DISABLED_CSS_CLASS = 'rich-tooltip-disabled';

/**
 * Optional injected position of the rich tooltip which should be used as the
 * default position if unspecified for the component. This allows placement of
 * the rich tooltip so that it does not block other interactive elements.
 */
export const RICH_TOOLTIP_DEFAULT_POSITION =
    new InjectionToken<RichTooltipPosition>('RICH_TOOLTIP_POSITION');

/**
 * Optional injected number that indicates how long a rich tooltip should wait
 * until rendering.
 */
export const RICH_TOOLTIP_RENDER_DEBOUNCE_MS =
    new InjectionToken<number>('RICH_TOOLTIP_RENDER_DEBOUNCE_MS');
