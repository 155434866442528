import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {getProjectIdFromName} from 'google3/java/com/google/dialogflow/console/web/common/helpers/proto_name_id_extractors';
import {LoadingState} from 'google3/java/com/google/dialogflow/console/web/common/store/loading_state';

import {initialState, State} from './projects_reducer';


/** Base projects selector */
export const selectFeatureState: MemoizedSelector<{}, State> =
    createFeatureSelector<State>('projects');

/** Select loading state */
export const getIsProjectsLoading = createSelector(
    (state: {}) => selectFeatureState(state),
    (state: State = initialState) =>
        state.projectsLoadingState === LoadingState.LOADING,
);

/** Select loaded state */
export const getIsProjectsLoaded = createSelector(
    (state: {}) => selectFeatureState(state),
    (state: State = initialState) =>
        state.projectsLoadingState === LoadingState.LOADED,
);

/** Select not loading state */
export const getIsProjectsNotLoading = createSelector(
    (state: {}) => selectFeatureState(state),
    (state: State = initialState) =>
        state.projectsLoadingState === LoadingState.NOT_LOADING,
);

/** Select the collection of all projects by id */
export const getAllProjects = createSelector(
    (state: {}) => selectFeatureState(state),
    (state: State = initialState) => state.projectsList || [],
);

/** Select next page token came with last response */
export const getProjectsNextPageToken = createSelector(
    (state: {}) => selectFeatureState(state),
    (state: State = initialState) => state.nextPageToken,
);

/** Select typed search string */
export const getSearchString = createSelector(
    (state: {}) => selectFeatureState(state),
    (state: State = initialState) => state.searchString,
);

/** Select current filter */
export const getProjectsFilter = createSelector(
    (state: {}) => selectFeatureState(state),
    (state: State = initialState) => state.filter,
);

/** Get selected project selector */
export const getSelectedProject = createSelector(
    selectFeatureState,
    (state: State = initialState) => state.selectedProject,
);

/** Get selected project ID */
export const getSelectedProjectId = createSelector(
    selectFeatureState,
    (state: State = initialState) => state.selectedProject &&
        getProjectIdFromName(state.selectedProject.name || ''));

/** Get selected project name */
export const getSelectedProjectName = createSelector(
    selectFeatureState,
    (state: State = initialState) =>
        state.selectedProject && state.selectedProject.name);

/** Get selected project display name */
export const getSelectedProjectDisplayName = createSelector(
    selectFeatureState,
    (state: State = initialState) =>
        state.selectedProject && state.selectedProject.displayName,
);

/** Get the selected project's project number. */
export const getSelectedProjectNumber =
    createSelector(getSelectedProject, project => project?.projectNumber);

/** All projects selectors collection */
export const selectors = {
  getAllProjects,
  getIsProjectsLoading,
  getProjectsFilter,
  getProjectsNextPageToken,
  getSearchString,
  getSelectedProject,
  getSelectedProjectId,
  getSelectedProjectName,
  getSelectedProjectNumber,
  getSelectedProjectDisplayName,
};
